import { Control, useController } from 'react-hook-form';
import RadioCardGroup from './RadioCardGroup';
import React from 'react';

interface RadioCardGroupControllerProps extends React.ComponentProps<typeof RadioCardGroup> {
    control: Control<any>;
    name: string;
}

export const RadioCardGroupController = (props: RadioCardGroupControllerProps) => {
    const { control, name, onChange, onBlur, children, ...rest } = props;
    const { field, fieldState } = useController({
        name,
        control,
    });

    return (
        <RadioCardGroup
            onChange={(value) => {
                onChange?.(value);
                field.onChange(value);
            }}
            name={field.name}
            value={field.value}
            errorMessage={fieldState.error?.message ?? ''}
            {...rest}
        >
            {children}
        </RadioCardGroup>
    );
};
