import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Text from '@ui/atoms/Text';
import Card from '@/features/onboarding/components/Onboarding/Card';
import useShowsQuery from '@queries/show/useShowsQuery.hook';
import useSubscriptionQuery from '@queries/subscription/useSubscriptionQuery.hook';
import HostedShowsQuotaModal from '@/shared/components/HostedShowsQuotaModal';
import FreemiumModal from '@/features/onboarding/components/Onboarding/FreemiumModal';
import useHasAccessToYoutubeSync from '@/shared/hooks/useHasAccessToYoutubeSync.hook';

const Choices = () => {
    const { data: shows } = useShowsQuery();
    const { data: subscription } = useSubscriptionQuery();
    const isFreemium = subscription?.isFreemium;
    const [isHostedShowsQuotaModalOpen, setIsHostedShowsQuotaModalOpen] = useState(false);
    const [isFreemiumModalOpen, setIsFreemiumModalOpen] = useState(false);
    const hasShow = (shows?.shows?.length || 0) > 0;
    const activeShowAllowance = (subscription?.showsMax || 0) + (subscription?.extraShows || 0);
    const currentActiveShows =
        shows?.shows?.filter((show: any) => !show.archived && show.isOwner).length || 0;
    const hasAccessToYoutubeSync = useHasAccessToYoutubeSync();

    return (
        <Wrapper>
            <SubHeader>
                <Text variant="headingL" fontWeight="--fw-bold">
                    {hasShow ? (
                        <FormattedMessage defaultMessage="Besoin d'ajouter une nouvelle émission ?" />
                    ) : (
                        <FormattedMessage defaultMessage="Par quoi souhaitez-vous commencer ?" />
                    )}
                </Text>
                <Text color="--neutral500">
                    <FormattedMessage defaultMessage="Créez un nouveau podcast ou continuez avec un podcast existant hébergé ailleurs." />
                </Text>
            </SubHeader>
            <Cards>
                <Card
                    image={<CreateIcon icon={icon({ name: 'plus', style: 'solid' })} />}
                    title={<FormattedMessage defaultMessage="Je lance un nouveau podcast" />}
                    description={
                        <FormattedMessage defaultMessage="Créez votre podcast en toute simplicité grâce à un hébergement illimité et des outils marketing puissants !" />
                    }
                    to="/app/onboarding/create"
                    onClick={(e) => {
                        if (currentActiveShows >= activeShowAllowance) {
                            e.preventDefault();
                            isFreemium
                                ? setIsFreemiumModalOpen(true)
                                : setIsHostedShowsQuotaModalOpen(true);
                        }
                    }}
                />
                <Card
                    image={<CreateIcon icon={icon({ name: 'podcast', style: 'solid' })} />}
                    title={<FormattedMessage defaultMessage="J'ai déjà un podcast" />}
                    description={
                        <FormattedMessage defaultMessage="Poursuivez avec votre podcast actuel et boostez sa croissance grâce à des fonctionnalités avancées, avec ou sans hébergement." />
                    }
                    to="/app/onboarding/import"
                />
                {hasAccessToYoutubeSync && (
                    <Card
                        image={<CreateIcon icon={icon({ name: 'youtube', style: 'brands' })} />}
                        title={<FormattedMessage defaultMessage="J'ai un podcast YouTube" />}
                        description={
                            <FormattedMessage defaultMessage="Importez vos vidéos afin de générer un flux RSS permettant la diffusion sur les plateformes audio et faites passez votre podcast à la vitesse supérieure !" />
                        }
                        to="/app/onboarding/youtube"
                    />
                )}
            </Cards>
            <HostedShowsQuotaModal
                isOpen={isHostedShowsQuotaModalOpen}
                onOpenChange={setIsHostedShowsQuotaModalOpen}
            />
            <FreemiumModal isOpen={isFreemiumModalOpen} onOpenChange={setIsFreemiumModalOpen} />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
`;
const SubHeader = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;
const Cards = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;
const CreateIcon = styled(FontAwesomeIcon)`
    width: 1.5rem;
    height: 1.5rem;
`;

export default Choices;
