import styled from 'styled-components';
import { useRadioGroupState } from 'react-stately';
import { useRadioGroup } from 'react-aria';
import { AnimateSharedLayout } from 'framer-motion';
import PropTypes from 'prop-types';
import RadioTabContext from './RadioTabContext';

const Container = styled.div`
    align-items: stretch;
    background: var(--neutral100);
    border-radius: var(--r-xs);
    display: inline-flex;
    flex-direction: ${(props) => {
        switch (props.orientation) {
            case 'horizontal':
                return 'row';
            case 'vertical':
                return 'column';
            default:
                return null;
        }
    }};
    opacity: ${(props) => (props.isDisabled ? 0.5 : null)};
    padding: 0.125rem;
    position: relative;
`;

const RadioTabGroup = (props) => {
    const { children, orientation } = props;
    const radioGroupState = useRadioGroupState(props);
    const { radioGroupProps } = useRadioGroup(props, radioGroupState);

    const { isDisabled } = radioGroupState;

    return (
        <Container {...radioGroupProps} orientation={orientation} isDisabled={isDisabled}>
            <RadioTabContext.Provider value={radioGroupState}>
                <AnimateSharedLayout>{children}</AnimateSharedLayout>
            </RadioTabContext.Provider>
        </Container>
    );
};

RadioTabGroup.propTypes = {
    orientation: PropTypes.oneOf(['horizontal', 'vertical']),
    name: PropTypes.string,
    value: PropTypes.string,
    defaultValue: PropTypes.string,
    onChange: PropTypes.func,
    isDisabled: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    validationState: PropTypes.oneOf(['valid', 'invalid']),
    isRequired: PropTypes.bool,
    id: PropTypes.string,
    'aria-label': PropTypes.string,
    'aria-labelledby': PropTypes.string,
    'aria-describedby': PropTypes.string,
    'aria-details': PropTypes.string,
    'aria-errormessage': PropTypes.string,
    children: PropTypes.node.isRequired,
};

RadioTabGroup.defaultProps = {
    orientation: 'horizontal',
    name: undefined,
    value: undefined,
    defaultValue: undefined,
    onChange: undefined,
    isDisabled: undefined,
    isReadOnly: undefined,
    validationState: undefined,
    isRequired: undefined,
    id: undefined,
    'aria-label': undefined,
    'aria-labelledby': undefined,
    'aria-describedby': undefined,
    'aria-details': undefined,
    'aria-errormessage': undefined,
};

export default RadioTabGroup;
