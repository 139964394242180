import Model from './Model';
import { date } from './resolvers';

class PostClipModel extends Model {
    static default = () => ({
        previewUrl: '',
        formatId: '',
    });

    static attributes = {
        previewUrl: 'preview_url',
        formatId: 'clip_format_id',
        createdAt: date('created_at'),
        format: 'format',
    };
}

export default PostClipModel;
