import { useGuard } from '@hooks/useGuard.hook';
import styled from 'styled-components';
import UiKitTooltip from '@ui/atoms/UiKitTooltip/UiKitTooltip';
import CommunicationTabItem from '../CommunicationTabItem';

const Tooltip = styled(UiKitTooltip)`
    height: 2.5rem;
`;

const CommunicationTabItemAccess = ({
    tooltip,
    disallowedUserRoles,
    disallowAccess,
    requiredUserOptions,
    tooltipPosition,
    ...props
}) => {
    const { allow, reason } = useGuard({
        disallowedUserRoles,
        requiredUserOptions,
        disallowAccess,
    });
    const tooltipIsDisplayed = !allow && !!tooltip?.[reason];

    return (
        <Tooltip
            passthrough={!tooltipIsDisplayed}
            position={tooltipPosition || 'top'}
            content={tooltip?.[reason]}
        >
            <CommunicationTabItem {...props} disabled={props.disabled || !allow} />
        </Tooltip>
    );
};

export default CommunicationTabItemAccess;
