import styled from 'styled-components';
import DeprecatedText from '../../atoms/DeprecatedText';
import IconButton from '../../atoms/IconButton';
import IconClose from '../../icons/IconClose';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 0 5px;
    border-radius: var(--r-xs);
    height: 25px;
    margin-right: 10px;
    margin-bottom: 5px;
    margin-left: -5px;
    background: ${(p) => (p.isTooLong ? 'var(--alert100)' : 'var(--neutral50)')};
`;

const SmallerIconButton = styled(IconButton)`
    padding: 0;
`;

function Tag({ children, onClose, noClose }) {
    return (
        <Wrapper isTooLong={children.length > 150}>
            <DeprecatedText w="fit-content" as="span" ellipsis style={{ maxWidth: 300 }}>
                {children}
            </DeprecatedText>
            {!noClose && (
                <SmallerIconButton
                    variant="ghost"
                    color="--neutral500"
                    icon={<IconClose />}
                    aria-label="Delete tag"
                    onClick={onClose}
                />
            )}
        </Wrapper>
    );
}

export default Tag;
