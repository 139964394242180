import Text from '@/components/ui/atoms/Text';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import ShowEdit from '../ShowEdit';
import Stack from '@/components/ui/layout/Stack';

const ShowEditPage = () => {
    return (
        <Container>
            <Stack $gap="0.25rem">
                <Text variant="title" fontWeight="--fw-bold">
                    <FormattedMessage defaultMessage="Informations" />
                </Text>
                <Text color="--neutral500">
                    <FormattedMessage defaultMessage="Configurez les informations et paramètres principaux de votre émission." />
                </Text>
            </Stack>
            <ShowEdit />
        </Container>
    );
};

const Container = styled.div`
    padding: var(--container-padding-mobile);
    background-color: var(--white);
    border-radius: var(--r-xs);
    display: flex;
    flex-direction: column;
    row-gap: var(--sp-xl);

    ${(props) => props.theme.mediaQueries.desktopAndUp} {
        padding: var(--container-padding);
    }
`;

export default ShowEditPage;
