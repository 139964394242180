import { createApi } from '../callApi';
const contentApi = createApi('CONTENT');

export const loginAs = (body) => {
    return contentApi({ method: 'POST', target: '/loginas', body });
};
export const register = (body) => {
    return contentApi({ method: 'POST', target: '/register', body });
};
export const login = (body) => {
    return contentApi({ method: 'POST', target: '/login', body });
};
