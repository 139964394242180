import { useRef } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { FocusScope, useDialog, useModal, useOverlay } from 'react-aria';
import styled, { css } from 'styled-components';
import { useIntl } from 'react-intl';
import IconClose from '@ui/icons/IconClose';
import IconButton from '@ui/atoms/IconButton';
import Text from '@ui/atoms/Text';
import { motion, AnimatePresence } from 'framer-motion';
import usePreventBodyScroll from '@/shared/hooks/usePreventBodyScroll';

const animationProps = {
    initial: { opacity: 0, translateY: 100 },
    animate: { opacity: 1, translateY: 0 },
    exit: { opacity: 0, translateY: 100 },
    transition: {
        type: 'spring',
        stiffness: 256,
        damping: 24,
        duration: 0.5,
        delay: 0,
    },
};

const Modal = (props) => {
    const intl = useIntl();
    let { title, description, size, action, isOpen, children } = props;
    // Handle interacting outside the dialog and pressing
    // the Escape key to close the modal.
    let ref = useRef();
    let { overlayProps, underlayProps } = useOverlay(props, ref);

    // Prevent scrolling while the modal is open, and hide content
    // outside the modal from screen readers.
    usePreventBodyScroll(isOpen);
    let { modalProps } = useModal();

    // Get props for the dialog and its title
    let { dialogProps, titleProps } = useDialog(props, ref);

    return createPortal(
        <ModalBackground {...underlayProps} size={size} fullpagePadding={props.fullpagePadding}>
            <FocusScope contain restoreFocus autoFocus={!props.disableAutoFocus}>
                <AnimatePresence>
                    {isOpen && (
                        <ModalInner
                            ref={ref}
                            {...overlayProps}
                            {...dialogProps}
                            {...modalProps}
                            size={size}
                            fullpagePadding={props.fullpagePadding}
                            {...animationProps}
                        >
                            <ModalHeader>
                                {title || description ? (
                                    <ModalTitleWrapper size={size}>
                                        <div>
                                            <ModalTitle {...titleProps}>{title}</ModalTitle>
                                            <ModalDescription>{description}</ModalDescription>
                                        </div>
                                    </ModalTitleWrapper>
                                ) : (
                                    <FlexSpacer />
                                )}
                                <ModalCloseWrapper size={size}>
                                    {props.isDismissable && (
                                        <IconButton
                                            variant="ghost"
                                            icon={<IconClose />}
                                            color="--neutral500"
                                            aria-label={intl.formatMessage({
                                                defaultMessage: 'Fermer',
                                            })}
                                            onPress={() => props.onClose()}
                                        />
                                    )}
                                </ModalCloseWrapper>
                            </ModalHeader>
                            <ModalContentWrapper hasAction={!!action} size={size}>
                                <ModalContentInner disableScroll={props.disableScroll} size={size}>
                                    {children}
                                </ModalContentInner>
                            </ModalContentWrapper>
                            {action && <ModalActionWrapper>{action}</ModalActionWrapper>}
                        </ModalInner>
                    )}
                </AnimatePresence>
            </FocusScope>
        </ModalBackground>,
        props.portalRootEl,
    );
};

const ModalBackground = styled.div`
    position: fixed;
    z-index: 100;
    inset: 0;
    background: hsl(0 0% 0% / 0.4);
    display: flex;
    align-items: center;
    justify-content: center;

    ${(p) => p.theme.mediaQueries.mobile} {
        align-items: flex-end;
    }
`;

const sizeMatch = {
    xs: '400px',
    small: '600px',
    medium: '800px',
    big: '1200px',
    full: '100%',
};

const ModalInner = styled(motion.div)`
    background: white;
    color: black;
    border-radius: var(--r-l);
    width: 100%;
    max-width: ${(p) => sizeMatch[p.size]};
    display: flex;
    flex-direction: column;

    ${(p) =>
        p.size === 'full' &&
        p.fullpagePadding === false &&
        css`
            border-radius: var(--r-none);
        `}

    ${(p) => p.theme.mediaQueries.tabletAndUp} {
        justify-content: space-between;
        ${(p) =>
            p.size === 'full' &&
            css`
                height: 100%;
            `}
    }

    ${(p) => p.theme.mediaQueries.mobile} {
        ${(p) =>
            p.size === 'small' &&
            css`
                border-radius: var(--r-l) var(--r-l) 0 0;
            `}

        ${(p) =>
            ['medium', 'big', 'full'].includes(p.size) &&
            css`
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            `}
    }
`;

const ModalHeader = styled.header`
    z-index: 1;
    width: 100%;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
`;

const ModalTitleWrapper = styled.div`
    flex-grow: 1;
    padding: 1.5rem;
    padding-right: 0.5rem;

    ${(p) => p.theme.mediaQueries.mobile} {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;

        ${(p) =>
            p.size === 'small' &&
            css`
                align-items: center;
                & > * {
                    justify-content: center;
                    text-align: center;
                }
            `}
    }
`;

const FlexSpacer = styled.div`
    flex-grow: 1;
`;

const ModalTitle = styled.h1`
    font-size: 1rem;
    font-weight: bold;
`;

const ModalDescription = styled(Text)`
    color: var(--neutral500);
    text-align: left;
`;

const ModalCloseWrapper = styled.div`
    flex-shrink: 0;
    padding: 1.5rem;
    padding-left: 0.5rem;

    & > button {
        padding: 0;
    }

    ${(p) => p.theme.mediaQueries.mobile} {
        display: ${(p) => (p.size === 'small' ? 'none' : 'block')};
    }
`;

const ModalContentWrapper = styled.div`
    z-index: 2;
    padding-inline: 1.5rem;
    padding-block-end: 1rem;
    ${(p) =>
        !p.hasAction &&
        css`
            padding-block-end: 1.5rem;
        `};
    display: flex;
    column-gap: 1rem;
    flex-grow: 10;
    flex-shrink: 0;

    ${(p) => p.theme.mediaQueries.mobile} {
        ${(p) =>
            ['medium', 'big', 'full'].includes(p.size) &&
            css`
                height: 0px;
            `}
    }

    ${(p) => p.theme.mediaQueries.tabletAndUp} {
        max-height: 80vh;
    }
`;

const ModalActionWrapper = styled.div`
    z-index: 1;
    width: 100%;
    padding: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 0.5rem;
    flex-grow: 0;
    flex-shrink: 0;

    ${(p) => p.theme.mediaQueries.mobile} {
        flex-direction: column-reverse;
        row-gap: 0.5rem;
        & button {
            align-self: stretch;
        }
    }
`;

const ModalContentInner = styled.div`
    width: 100%;
    flex: 1 1 auto;
    ${(p) =>
        !p.disableScroll &&
        css`
            overflow-y: auto;
        `};
    ${(p) =>
        p.size === 'small' &&
        css`
            min-height: 10rem;
        `}
`;

Modal.propTypes = {
    size: PropTypes.oneOf(['xs', 'small', 'medium', 'big', 'full']),
    title: PropTypes.node,
    description: PropTypes.node,
    illustration: PropTypes.node,
    action: PropTypes.node,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    isDismissable: PropTypes.bool,
    fullpagePadding: PropTypes.bool,
    portalRootEl: PropTypes.instanceOf(Element),
    disableScroll: PropTypes.bool,
};

Modal.defaultProps = {
    size: 'xs',
    isOpen: false,
    onClose: () => {},
    isDismissable: true,
    portalRootEl: document.getElementById('modal-root'),
    fullpagePadding: true,
    disableScroll: false,
};

export default Modal;
