import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchCampaigns } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import campaignKeys from './campaignKeys';
import { z } from 'zod';

export const PaginationSchema = z.object({
    currentPage: z.number(),
    totalPages: z.number(),
    total: z.number(),
    perPage: z.number(),
    count: z.number(),
});

export const CampaignSchema = z.object({
    audioUrl: z.string().url().nullable(),
    autoOnNewPodcasts: z.boolean(),
    createdAt: z.string(),
    downloadsCount: z.number().int().nonnegative(),
    endsAt: z.string(),
    id: z.number().int(),
    maxListens: z.number().int().nonnegative(),
    name: z.string(),
    showId: z.number().int(),
    startsAt: z.string(),
    state: z.string(), // TODO: use enum
    type: z.union([z.literal('preroll'), z.literal('postroll'), z.literal('hostread')]),
    updatedAt: z.string(),
});

export const CampaignsResponseSchema = z.object({
    campaigns: z.array(CampaignSchema),
    pagination: PaginationSchema,
});

export type Campaign = z.infer<typeof CampaignSchema>;
type Key = QueryFunctionContext<ReturnType<typeof campaignKeys.listByShowId>>;

const queryFn = async ({ queryKey: [{ showId, q, page, perPage }] }: Key) => {
    const { data, meta }: { data: any[]; meta: any } = await fetchCampaigns(
        showId,
        q,
        page,
        perPage,
    );

    const formattedData = {
        campaigns: data,
        pagination: {
            currentPage: meta?.pagination?.current_page || 0,
            totalPages: meta?.pagination?.total_pages || 0,
            total: meta?.pagination?.total || 0,
            perPage: meta?.pagination?.per_page || 0,
            count: meta?.pagination?.count || 0,
        },
    };

    return CampaignsResponseSchema.parse(camelcaseKeys(formattedData, { deep: true }));
};

interface InputProps {
    showId: string;
    query?: string;
    pagination?: {
        page: number;
        perPage: number;
    };
}

const useCampaignsQuery = ({
    showId,
    query,
    pagination = { page: 1, perPage: 20 },
}: InputProps) => {
    const { page, perPage } = pagination;

    return useQuery({
        queryKey: campaignKeys.listByShowId(showId, { q: query, page, perPage }),
        queryFn,
        enabled: !!showId,
    });
};

export default useCampaignsQuery;
