import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateCampaign } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import { decamelizeKeys } from 'humps';
import { Campaign } from './useCampaignsQuery';
import { CampaignFormSchema } from '@/features/Campaigns/components/CampaignsPage/CampaignEditPage/Form/useFormSchema';
import campaignKeys from './campaignKeys';

interface MutationFnProps {
    campaignId: string | number;
    campaign: {
        state?: 'draft' | 'active' | 'paused';
        type?: CampaignFormSchema['general']['type'];
        name?: CampaignFormSchema['general']['name'];
        startsAt?: CampaignFormSchema['duration']['startsAt'];
        endsAt?: CampaignFormSchema['duration']['endsAt'];
        maxListens?: CampaignFormSchema['duration']['maxListens'];
        autoOnNewPodcasts?: CampaignFormSchema['episodes']['autoOnNewPodcasts'];
        podcasts?: CampaignFormSchema['episodes']['episodes'];
    };
}

const mutationFn = async ({ campaignId, campaign }: MutationFnProps): Promise<Campaign> => {
    const { data } = await updateCampaign(campaignId, decamelizeKeys(campaign));

    return camelcaseKeys(data as any, { deep: true });
};

const updateCampaignMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: (_, { campaignId }) => {
            queryClient.invalidateQueries({ queryKey: campaignKeys.allLists() });
            queryClient.invalidateQueries({ queryKey: campaignKeys.detailById(campaignId) });
        },
    });
};

export default updateCampaignMutation;
