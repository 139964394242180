import styled, { css, keyframes } from 'styled-components';

const PersonalAccessTokenTableSkeleton = () => {
    return (
        <ScrollableWrapper>
            <Table>
                <Thead>
                    <Tr>
                        <Th>
                            <HeaderSkeleton />
                        </Th>
                        <Th>
                            <HeaderSkeleton />
                        </Th>
                        <Th>
                            <HeaderSkeleton />
                        </Th>
                        <Th $maxWidth="10rem"></Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {Array(5)
                        .fill(null)
                        .map((_, index) => (
                            <Tr key={index}>
                                <Td>
                                    <Skeleton width="10rem" />
                                </Td>
                                <Td>
                                    <Skeleton width="8rem" />
                                </Td>
                                <Td>
                                    <Skeleton width="8rem" />
                                </Td>
                                <Td $maxWidth="10rem">
                                    <ButtonSkeleton />
                                </Td>
                            </Tr>
                        ))}
                </Tbody>
            </Table>
        </ScrollableWrapper>
    );
};

const shimmer = keyframes`
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
`;

const shimmering = css`
    background: var(--neutral50);
    background-image: linear-gradient(
        to right,
        var(--neutral50) 0%,
        #edeef1 20%,
        var(--neutral50) 40%,
        var(--neutral50) 100%
    );
    background-repeat: no-repeat;
    background-size: 50rem 6.5rem;
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${shimmer};
    animation-timing-function: linear;
`;

const ScrollableWrapper = styled.div`
    max-height: 27.5rem;
    overflow-y: auto;
`;

const Table = styled.table`
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
`;

const Td = styled.td<{ $maxWidth?: string }>`
    height: 3.5rem;
    padding-inline: 1rem;
    ${({ $maxWidth }) => $maxWidth && `max-width: ${$maxWidth};`}
`;

const Tr = styled.tr``;

const Th = styled.th<{ $maxWidth?: string }>`
    padding-inline: 1rem;
    text-align: left;
    ${({ $maxWidth }) => $maxWidth && `max-width: ${$maxWidth};`}
    width: ${({ $maxWidth }) => $maxWidth || 'auto'};

    &:first-of-type {
        border-top-left-radius: var(--r-m);
        border-bottom-left-radius: var(--r-m);
    }

    &:last-of-type {
        border-top-right-radius: var(--r-m);
        border-bottom-right-radius: var(--r-m);
    }
`;

const Tbody = styled.tbody``;

const Thead = styled.thead`
    position: sticky;
    top: 0;
    width: 100%;
    background-color: var(--neutral50);
    height: 3rem;
    z-index: 1;
    border-radius: var(--r-m);
`;

const Skeleton = styled.div<{ width?: string }>`
    border-radius: var(--r-full);
    background-color: var(--neutral100);
    width: ${({ width }) => width || '100%'};
    height: 0.75rem;
    ${shimmering};
`;

const HeaderSkeleton = styled.div`
    border-radius: var(--r-s);
    background-color: var(--neutral100);
    height: 1.5rem;
    ${shimmering}
`;

const ButtonSkeleton = styled(Skeleton)`
    height: 2.5rem;
    border-radius: var(--r-s);
`;

export default PersonalAccessTokenTableSkeleton;
