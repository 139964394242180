import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Nav from './Nav';

interface LayoutProps {
    userHasWriteAccess: boolean;
    children: React.ReactNode;
}

const Layout = ({ userHasWriteAccess, children }: LayoutProps) => {
    return (
        <LayoutWrapper>
            <InnerWrapper>
                <Sidebar>
                    <TitleAndSubtitle>
                        <Title>
                            <FormattedMessage defaultMessage="Référencement" />
                        </Title>
                        <Subtitle>PSO Control Panel</Subtitle>
                    </TitleAndSubtitle>
                    <Nav userHasWriteAccess={userHasWriteAccess} />
                </Sidebar>
                <ContentWrapper>{children}</ContentWrapper>
            </InnerWrapper>
        </LayoutWrapper>
    );
};
const LayoutWrapper = styled.div`
    display: none;

    ${({ theme }) => theme.mediaQueries.desktopXLAndUp} {
        padding-inline: var(--container-padding);
        display: flex;
        justify-content: center;
        width: 100%;
        padding-inline: var(--container-padding-mobile);
        margin-block: 2rem;
    }
`;
const InnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    max-width: var(--container-width);
    flex-grow: 1;

    ${({ theme }) => theme.mediaQueries.desktopXLAndUp} {
        flex-direction: row;
    }
`;
const ContentWrapper = styled.div`
    display: flex;
    height: max-content;
    flex-direction: column;
    gap: 1rem;
    flex: 1;
`;
const Sidebar = styled.aside`
    display: flex;
    flex-direction: column;
    width: 15rem;
    height: 20rem;
    position: sticky;
    top: 8.5rem;
    left: 0;
    gap: 2rem;
    flex-shrink: 0;
`;
const TitleAndSubtitle = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;
const Title = styled.h1`
    font-weight: var(--fw-bold);
    font-size: var(--fs-heading-l);
    line-height: 1.4rem;
`;
const Subtitle = styled.h2`
    font-weight: var(--fw-semibold);
    font-size: var(--fs-body-l);
    color: var(--neutral);
    line-height: 1rem;
`;

export default Layout;
