import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteChapterImage } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import chapterKeys from '@queries/chapter/chapterKeys';

export interface MutationFnProps {
    chapterId: string | number;
    episodeId?: string;
}

const mutationFn = async ({ chapterId }: MutationFnProps) => {
    const { data } = await deleteChapterImage(chapterId);

    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const useDeleteChapterImageMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: (_, { chapterId, episodeId }) => {
            queryClient.invalidateQueries({
                queryKey: chapterKeys.detailById(chapterId),
            });
            // If we have an episode ID, we invalidate the full list of chapters for that episode
            if (episodeId) {
                queryClient.invalidateQueries({
                    queryKey: chapterKeys.listByEpisodeId(episodeId),
                });
            }
        },
    });
};

export default useDeleteChapterImageMutation;
