import { useState, useEffect } from 'react';
import { useCycle } from 'framer-motion';
import Uploader from '@ui/smarties/Uploader';
import { commonMessages } from '@/shared/services/commonMessageService';
import Button from '@/components/Button';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

function BackgroundUploader({
    field,
    message: CustomMessage,
    formatPage,
    getFileBackground,
    value,
    name,
    onChange,
    ...props
}) {
    const [src, setSrc] = useState(field?.value || value);
    const [isOpen, onOpen] = useCycle(false, true);
    // Updates the image with the one coming from the API
    useEffect(() => {
        if (typeof field?.value === 'string' || typeof value === 'string') {
            setSrc(field?.value || value);
        }
    }, [field?.value, value]);

    function handleChange(file) {
        onChange && onChange(file);
        const reader = new FileReader();
        reader.onload = (e) => setSrc(e.target.result);
        reader.readAsDataURL(file);
        field.setValue(Object.assign(file, { formatName: formatPage }));
        getFileBackground(file);
    }

    function deleteFile() {
        setSrc(undefined);
        field && field.setValue(null);
        onChange ? onChange(null) : getFileBackground({ formatName: formatPage, action: 'DELETE' });
    }

    return (
        <ResponsiveWrapper>
            {!isOpen && src && <Img src={src} style={{ objectFit: props.objectFit }} />}
            <Actions>
                {!src ? (
                    <Uploader
                        accept="image/*"
                        onChange={handleChange}
                        onClick={() => onOpen}
                        name={name}
                    >
                        <Button
                            variant="tertiary"
                            startIcon={
                                <CameraIcon icon={icon({ name: 'camera', style: 'solid' })} />
                            }
                        >
                            {commonMessages('idealSizeImg', 'uploadImage')}
                        </Button>
                    </Uploader>
                ) : (
                    <>
                        <Button isDisabled={!src} onPress={deleteFile} variant="link-danger">
                            {commonMessages('idealSizeImg', 'deletedImage')}
                        </Button>
                        {CustomMessage}
                    </>
                )}
            </Actions>
        </ResponsiveWrapper>
    );
}

const CameraIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const ResponsiveWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
    }
`;
const Img = styled.img`
    width: 100%;
    max-width: 480px;
    max-height: 200px;
    object-fit: cover;
`;
const Actions = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export default BackgroundUploader;
