import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import {
    buttonDisabledStyles,
    buttonFocusVisibleStyles,
    buttonFontStyles,
    buttonHoveredStyles,
    buttonLayoutStyles,
    buttonLoadingStyles,
    buttonPressedStyles,
    buttonResetStyles,
    buttonTransitionStyles,
} from '../base';
import { useMemo } from 'react';

export const MagicButtonParticlesWrapper = styled.div`
    position: absolute;
    inset: 0;
    opacity: 0.7;
`;
export const MagicButtonParticles = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
`;
export const MagicButtonParticle = styled(motion.div)`
    position: absolute;
    background-color: var(--white);
    border-radius: var(--r-full);
    pointer-events: none;
`;

export const magicButtonStyles = css`
    ${buttonResetStyles}
    ${buttonFontStyles}
    ${buttonTransitionStyles}
    ${buttonLoadingStyles}
    ${buttonDisabledStyles}
    ${buttonHoveredStyles}
    ${buttonPressedStyles}
    ${buttonFocusVisibleStyles}
    ${buttonLayoutStyles}
    
    color: white;
    background: var(--gradient-nebula);
    box-shadow: -8px 8px 24px -8px rgba(153, 133, 255, 0.3),
        8px 8px 24px -8px rgba(251, 125, 255, 0.3);
`;

interface ButtonParticlesProps {
    isHovered: boolean;
    nbParticules: number;
}

export const useButtonParticles = ({ isHovered, nbParticules }: ButtonParticlesProps) => {
    const particles = useMemo(
        () =>
            new Array(nbParticules).fill(0).map((_, index) => {
                const size = Math.random() * 0.5 + 1.25;
                const randomDirection = () => (Math.random() - 0.5) * 50;
                const initialY = Math.random() * 100;
                const initialX = Math.random() * 100;

                return {
                    id: index,
                    initial: {
                        top: `${initialY}%`,
                        left: `${initialX}%`,
                    },
                    animate: isHovered
                        ? {
                              left: `${initialX + randomDirection()}%`,
                              top: `${initialY + randomDirection()}%`,
                          }
                        : {},
                    transition: {
                        duration: Math.random() * 2 + 5,
                        ease: 'linear',
                        repeat: Infinity,
                        delay: 0,
                    },
                    style: {
                        width: `${size}px`,
                        height: `${size}px`,
                        opacity: Math.random() * 0.5 + 0.5,
                    },
                };
            }),
        [isHovered],
    );

    return particles;
};
