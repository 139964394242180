import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { FORM_VALIDATION_MESSAGE } from '@/utils/validationMessage';
import { checkImgDimensions, checkImgFormat, checkImgSize } from '@/helpers';

const useProfileFormSchema = ({ languages, countries, timezones, locale }) => {
    const intl = useIntl();
    const nameRegex = /^[a-zA-Z0-9-À-ÿ]+$/;
    const acceptedMimes = ['jpg', 'png'];
    const IMG_DIMENSIONS = { minWidth: 400, minHeight: 400 };

    return yup.object({
        firstname: yup
            .string()
            .matches(nameRegex, intl.formatMessage(FORM_VALIDATION_MESSAGE.noSpecialCharacters))
            .required(intl.formatMessage(FORM_VALIDATION_MESSAGE.required)),
        lastname: yup
            .string()
            .matches(nameRegex, intl.formatMessage(FORM_VALIDATION_MESSAGE.noSpecialCharacters))
            .required(intl.formatMessage(FORM_VALIDATION_MESSAGE.required)),
        language: yup
            .string()
            .oneOf(languages.map((language) => language.code))
            .required(intl.formatMessage(FORM_VALIDATION_MESSAGE.required)),
        country: yup
            .string()
            .oneOf(countries.map((country) => country.code))
            .required(intl.formatMessage(FORM_VALIDATION_MESSAGE.required)),
        timezone: yup
            .string()
            .oneOf(timezones.map((timezone) => timezone.id))
            .required(intl.formatMessage(FORM_VALIDATION_MESSAGE.required)),
        avatar: yup
            .mixed()
            .nullable()
            .test(
                'avatar format',
                intl.formatMessage(FORM_VALIDATION_MESSAGE.acceptedMimes, { acceptedMimes }),
                (file) => {
                    if (typeof file === 'string' || file === null) return true;
                    return checkImgFormat(file);
                },
            )
            .test(
                'avatar size',
                intl.formatMessage(FORM_VALIDATION_MESSAGE.imgMaxSize, { max: 10 }),
                (file) => {
                    if (typeof file === 'string' || file === null) return true;
                    return checkImgSize(file, locale, 10);
                },
            )
            .test(
                'avatar dimensions',
                intl.formatMessage(FORM_VALIDATION_MESSAGE.imgMinDimensions, IMG_DIMENSIONS),
                async (file) => {
                    if (typeof file === 'string' || file === null) return true;
                    const img = new Image();
                    img.src = URL.createObjectURL(new File([file], 'pic'));
                    return new Promise((resolve) => {
                        img.onload = () => {
                            const isValid = checkImgDimensions(img, IMG_DIMENSIONS);
                            resolve(isValid);
                        };
                    });
                },
            ),
    });
};

export default useProfileFormSchema;
