import { ReactNode } from 'react';
import styled from 'styled-components';
import Text from '@ui/atoms/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Link, LinkProps } from 'react-router-dom';

interface CardProps extends LinkProps {
    image: ReactNode;
    title: ReactNode;
    description: ReactNode;
}

const Card = ({ image, title, description, ...props }: CardProps) => (
    <CardLink {...props}>
        <Image>{image}</Image>
        <TextWrapper>
            <Text variant="bodyL" fontWeight="--fw-semibold" color="--black">
                {title}
            </Text>
            <Text color="--neutral500">{description}</Text>
        </TextWrapper>
        <ArrowIcon icon={icon({ name: 'arrow-right', style: 'solid' })} />
    </CardLink>
);

const ArrowIcon = styled(FontAwesomeIcon)`
    color: var(--primary);
    visibility: hidden;
    width: 1rem;
    height: 1rem;
    transform: translateX(-16px);
    transition: transform 250ms ease-out, visibility 0ms linear 250ms;
`;
const Image = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 4rem;
    height: 4rem;
    color: var(--primary);
    background-color: var(--primary50);
    border-radius: var(--r-m);
`;
const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
const CardLink = styled(Link)`
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 100%;
    padding: 1rem;
    background-color: var(--white);
    border-radius: var(--r-m);
    cursor: pointer;
    text-decoration: none;
    box-shadow: 0 8px 64px rgba(0, 0, 0, 0);
    transition: box-shadow 250ms ease-out;

    &:hover {
        box-shadow: 0 8px 64px rgba(0, 0, 0, 0.08);
        ${ArrowIcon} {
            visibility: visible;
            transform: translateX(0);
            transition: transform 250ms ease-out, visibility 0ms;
        }
    }
`;

export default Card;
