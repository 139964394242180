import statsKeys from '@queries/stats/statsKeys';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { fetchGendersStats } from '@/api';
import { z } from 'zod';
import camelcaseKeys from 'camelcase-keys';

interface GendersQueryProps {
    start: string | null;
    end: string | null;
}

interface GendersQueryParams {
    showId: string;
    query: GendersQueryProps;
}

const GenderSchema = z.object({
    gender: z.string(),
    count: z.number(),
    percentage: z.number(),
});

const GendersSchema = z.array(GenderSchema);
export type Genders = z.infer<typeof GendersSchema>;

type Key = QueryFunctionContext<ReturnType<typeof statsKeys.genders>>;

const queryFn = async ({ queryKey: [{ showId, query }] }: Key) => {
    const { data } = await fetchGendersStats(showId, query);

    return GendersSchema.parse(camelcaseKeys(data, { deep: true }));
};

const useGendersQuery = ({ showId, query }: GendersQueryParams) => {
    return useQuery({
        queryKey: statsKeys.genders(showId, query),
        queryFn,
        enabled: !!showId,
    });
};

export default useGendersQuery;
