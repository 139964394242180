import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import camelcaseKeys from 'camelcase-keys';
import { psoCheckerTargetKeyword } from '@/api';
import { z } from 'zod';
import psoCheckerKeys from './psoCheckerKeys';

export interface useTargetKeywordQueryProps {
    episodeId: string;
}

export const TargetKeywordSchema = z.object({
    keyword: z.string(),
    searchVolume: z.number().nullable(),
    difficultyScore: z.number().nullable(),
});

export type TargetKeyword = z.infer<typeof TargetKeywordSchema>;

type Key = QueryFunctionContext<ReturnType<typeof psoCheckerKeys.targetKeyword>>;

const queryFn = async ({ queryKey: [{ episodeId }] }: Key) => {
    const { data } = await psoCheckerTargetKeyword(episodeId);

    // TODO: Do camelcase transformation in Axios interceptor
    return TargetKeywordSchema.parse(camelcaseKeys(data, { deep: true }));
};

const useTargetKeywordQuery = ({ episodeId }: useTargetKeywordQueryProps) => {
    return useQuery({
        queryKey: psoCheckerKeys.targetKeyword(episodeId),
        queryFn,
        enabled: !!episodeId,
    });
};

export default useTargetKeywordQuery;
