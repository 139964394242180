import { Children, cloneElement, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import StepperContext from './StepperContext';
import StepConnector from '../../atoms/StepConnector';

const DEFAULT_CONNECTOR = <StepConnector />;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
`;

const Stepper = (props) => {
    const { activeStep = 0, children, connector = DEFAULT_CONNECTOR, ...other } = props;

    const childrenArray = Children.toArray(children);
    const steps = childrenArray.map((step, index) =>
        cloneElement(step, {
            index,
            last: index + 1 === childrenArray.length,
            ...step.props,
        }),
    );
    // Disable ESLint to prevent an ugly formatted hook 😉
    /* eslint-disable */
    const contextValue = useMemo(() => ({ activeStep, connector }), [activeStep, connector]);
    /* eslint-enable */

    return (
        <StepperContext.Provider value={contextValue}>
            <Container {...other}>{steps}</Container>
        </StepperContext.Provider>
    );
};

Stepper.propTypes = {
    /**
     * Set the active step (zero based index).
     * Set to -1 to disable all the steps.
     */
    activeStep: PropTypes.number,
    /**
     * Two or more <Step /> components.
     */
    children: PropTypes.node,
    /**
     * An element to be placed between each step.
     */
    connector: PropTypes.element,
};

export default Stepper;
