import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { motion } from 'framer-motion';
import ShortListFallback from '../../fallbacks/ShortListFallback';
import SubscriberNewsletterLine from './SubscriberNewsletterLine';
import Text from '@ui/atoms/Text';

interface Subscriber {
    email: string;
}

interface PropsTypes {
    subscribers: Subscriber[];
    loading: boolean;
}

const spring = {
    type: 'spring',
    damping: 20,
    stiffness: 300,
};

const NewsletterList = ({ subscribers, loading }: PropsTypes) => {
    if (loading) {
        return (
            <FallbackWrapper>
                <ShortListFallback />
            </FallbackWrapper>
        );
    }

    return (
        <>
            <TableColumnName>
                <FormattedMessage defaultMessage="Adresse e-mail" />
            </TableColumnName>
            {subscribers?.map((subscriber: Subscriber) => (
                <SubscriberLine key={subscriber?.email} layout transition={spring}>
                    <SubscriberNewsletterLine
                        key={subscriber?.email}
                        subscriberEmail={subscriber?.email}
                    />
                </SubscriberLine>
            ))}
        </>
    );
};

const FallbackWrapper = styled.div`
    padding: 3rem;
    margin-top: 1.5rem;
    margin-bottom: 2rem;
`;

const TableColumnName = styled(Text)`
    padding-inline: 1rem;
    padding-block: 1.5rem;
    color: var(--neutral500);
`;

const SubscriberLine = styled(motion.li)`
    list-style: none;
`;

export default NewsletterList;
