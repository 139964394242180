import { useFormikContext } from 'formik';
import Center from '../../../../ui/layout/Center';
import Grid from '../../../../ui/atoms/Grid';
import WithoutTranscriptOption from './WithoutTranscriptOption';
import WithTranscriptOption from './WithTranscriptOption';

const TranscriptionSelection = (props) => {
    const { values, setFieldValue } = useFormikContext();

    return (
        <div {...props}>
            <Center max="34rem">
                <Grid idealCellWidth="16rem" gap="2rem">
                    <WithoutTranscriptOption
                        selected={values.transcript === false}
                        onClick={() => setFieldValue('transcript', false)}
                    />
                    <WithTranscriptOption
                        selected={values.transcript === true}
                        values={values}
                        setFieldValue={setFieldValue}
                    />
                </Grid>
            </Center>
        </div>
    );
};

export default TranscriptionSelection;
