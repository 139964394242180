import ContentLoader from 'react-content-loader';
import createFallback from './createFallback';

const Rect = (props) => <rect rx={1} ry={1} {...props} />;

function LineCheckBoxFallback({ theme, h = '15px', w = '100%', m = '10px 0', ...props }) {
    return (
        <ContentLoader width={w} height={h} style={{ height: h, width: w, margin: m }} {...props}>
            <Rect x={0} y="0" height={15} width={15} />
            <Rect x={30} y="0" height={15} width={w} />
        </ContentLoader>
    );
}

export default createFallback(LineCheckBoxFallback);
