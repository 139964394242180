import { action } from 'mobx';
import SubscriptionStore from './SubscriptionStore';
import PodcastStore from './PodcastStore';
import ClipStore from './ClipStore';
import AutoSharingStore from './AutoSharingStore';
import ListenersStatsStore from './statistics/ListenersStatsStore';
import SourcesStatsStore from './statistics/SoucesStatsStore';
import ApplePodcastsStatsStore from './statistics/ApplePodcastsStatsStore';

class RootStore {
    podcastStore = new PodcastStore(this);
    subscriptionStore = new SubscriptionStore(this);
    autoSharingStore = new AutoSharingStore(this);
    clipStore = new ClipStore(this);
    listenersStatsStore = new ListenersStatsStore(this);
    sourcesStatsStore = new SourcesStatsStore(this);
    applePodcastsStatsStore = new ApplePodcastsStatsStore(this);

    @action
    reset() {
        Object.values(this).forEach((store) => store.reset && store.reset());
    }
}

export default new RootStore();
