import styled, { css, keyframes } from 'styled-components';

const PsoRankingCardSkeleton = () => {
    return (
        <RankingCardSkeletonWrapper>
            <Left>
                <Icon />
                <Bars>
                    <TopBar />
                    <BottomBar />
                </Bars>
            </Left>
            <Right />
        </RankingCardSkeletonWrapper>
    );
};

const shimmer = keyframes`
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
`;
const shimmering = css`
    background: var(--neutral50);
    background-image: linear-gradient(
        to right,
        var(--neutral50) 0%,
        #edeef1 20%,
        var(--neutral50) 40%,
        var(--neutral50) 100%
    );
    background-repeat: no-repeat;
    background-size: 800px 104px;
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${shimmer};
    animation-timing-function: linear;
`;
const RankingCardSkeletonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
`;
const Left = styled.div`
    display: flex;
    align-items: center;
    gap: 0.75rem;
`;
const Icon = styled.div`
    width: 2.5rem;
    height: 2.5rem;
    background-color: var(--neutral100);
    border-radius: var(--r-s);
    ${shimmering};
`;
const Bars = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    & > * {
        border-radius: var(--r-full);
        height: 0.5rem;
        background-color: var(--neutral100);
    }
`;
const TopBar = styled.div`
    width: 7.5rem;
    ${shimmering};
`;
const BottomBar = styled.div`
    width: 2.5rem;
    background-color: var(--neutral50);
    ${shimmering};
`;
const Right = styled.div`
    width: 5rem;
    height: 2.5rem;
    background-color: var(--neutral100);
    border-radius: var(--r-s);
    ${shimmering};
`;

export default PsoRankingCardSkeleton;
