import { FormattedMessage } from 'react-intl';
import Modal from '@ui/molecules/Modal';
import ApplePodcastsReviewsNotificationForm from './ApplePodcastsReviewsNotificationForm';

function ApplePodcastsReviewsNotificationModal({ isOpen, onClose }) {
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={<FormattedMessage defaultMessage="Paramètres des alertes" />}
        >
            <ApplePodcastsReviewsNotificationForm onAfterSubmit={onClose} />
        </Modal>
    );
}

export default ApplePodcastsReviewsNotificationModal;
