import InputText, { InputTextController } from '@ui/atoms/InputText';
import { ToggleController } from '@ui/atoms/Toggle';
import Text from '@ui/atoms/Text';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import UnsavedFormModal from '@/components/unorganized/UnsavedFormModal';
import useHostingSettingsFormSchema, { HostingSettingsFormSchema } from './useFormSchema';
import { zodResolver } from '@hookform/resolvers/zod';
import Button from '@/components/Button';
import { RssSettings } from '@/queries/rssSettings/useRssSettingsQuery.hook';
import { TooltipTrigger } from 'react-aria-components';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Show } from '@/queries/show/useShowQuery.hook';
import ExternalLink from '@/components/Link/ExternalLink';

interface FormProps {
    rssSettings: RssSettings;
    rssLink: Show['rssLink'];
    onSubmit: (data: HostingSettingsFormSchema) => void;
    isLoading: boolean;
}

const Form = ({ rssSettings, rssLink, onSubmit, isLoading }: FormProps) => {
    const intl = useIntl();
    const schema = useHostingSettingsFormSchema();
    const { control, handleSubmit, formState } = useForm<HostingSettingsFormSchema>({
        mode: 'onBlur',
        values: {
            subtitle: rssSettings.subtitle,
            website: rssSettings.website,
            copyright: rssSettings.copyright,
            ownerName: rssSettings.ownerName,
            ownerEmail: rssSettings.ownerEmail,
            isExplicit: !!rssSettings.isExplicit,
            hasTracking: !!rssSettings.podtrac,
            isLocked: rssSettings.isLocked || false,
        },
        resolver: zodResolver(schema),
    });

    const { isDirty } = formState;

    return (
        <>
            <UnsavedFormModal shouldBlockNavigation={isDirty} />
            <FormStyled onSubmit={handleSubmit(onSubmit)}>
                <RssLinkWrapper>
                    <InputText
                        value={rssLink}
                        label={<FormattedMessage defaultMessage="Lien du flux RSS de l'émission" />}
                        isReadOnly
                        copyToClipboardEnabled
                    />
                    <ExternalLink
                        startIcon={<EyeIcon icon={icon({ name: 'eye', style: 'solid' })} />}
                        variant="button-tertiary"
                        href={rssLink}
                        target="_blank"
                    >
                        <FormattedMessage defaultMessage="Aperçu" />
                    </ExternalLink>
                </RssLinkWrapper>
                <InputTextController
                    control={control}
                    name="subtitle"
                    label={<FormattedMessage defaultMessage="Sous-titre" />}
                    placeholder={intl.formatMessage({
                        defaultMessage: `Saisissez le sous-titre de votre émission`,
                    })}
                    maxLength={255}
                />
                <InputTextController
                    control={control}
                    name="website"
                    label={<FormattedMessage defaultMessage="Site web" />}
                    placeholder="https://ausha.co/"
                />
                <InputTextController
                    control={control}
                    name="copyright"
                    label={<FormattedMessage defaultMessage="Copyright" />}
                    placeholder={intl.formatMessage({
                        defaultMessage: `Saisissez le copyright de votre émission`,
                    })}
                />
                <InputTextController
                    control={control}
                    name="ownerName"
                    label={<FormattedMessage defaultMessage="Nom du propriétaire" />}
                    placeholder={intl.formatMessage({
                        defaultMessage: `Thomas Pesquet`,
                    })}
                />
                <InputTextController
                    control={control}
                    name="ownerEmail"
                    label={<FormattedMessage defaultMessage="Adresse e-mail du propriétaire" />}
                    placeholder={intl.formatMessage({
                        defaultMessage: 'thomas.pesquet@esa.com',
                    })}
                    tooltip={
                        <TooltipTrigger delay={0} closeDelay={0}>
                            <TooltipTriggerWrapper>
                                <TooltipIcon icon={icon({ name: 'circle-info', style: 'solid' })} />
                            </TooltipTriggerWrapper>
                            <Tooltip placement="top">
                                <FormattedMessage defaultMessage="Cette information ne sera pas affichée publiquement sur les plateformes d'écoute." />
                            </Tooltip>
                        </TooltipTrigger>
                    }
                />
                <ToggleWrapper>
                    <Text fontWeight="--fw-semibold">
                        <FormattedMessage defaultMessage="Contenu explicite" />
                    </Text>
                    <ToggleController control={control} name="isExplicit">
                        <FormattedMessage defaultMessage="Votre émission contient du contenu explicite (sexe, drogue & rock'n'roll)" />
                    </ToggleController>
                </ToggleWrapper>
                <ToggleWrapper>
                    <Text fontWeight="--fw-semibold">
                        <FormattedMessage defaultMessage="Activer Podtrac" />
                    </Text>
                    <ToggleController control={control} name="hasTracking">
                        <FormattedMessage defaultMessage="Si vous utilisez Podtrac, vous pouvez activer cette option pour prendre en compte leur redirection." />
                    </ToggleController>
                    <Text color="--neutral500" variant="footnote">
                        <FormattedMessage defaultMessage="Attention, n’activez pas cette option si vous n’utilisez pas Podtrac, sinon vos fichiers deviendront inaccessibles." />
                    </Text>
                </ToggleWrapper>
                <ToggleWrapper>
                    <Text fontWeight="--fw-semibold">
                        <FormattedMessage defaultMessage="Verrouiller le flux RSS" />
                    </Text>
                    <ToggleController control={control} name="isLocked">
                        <FormattedMessage defaultMessage="Empêchez les autres hébergeurs de podcasts d'importer votre émission. " />
                    </ToggleController>
                </ToggleWrapper>
                <Button
                    startIcon={<SaveIcon icon={icon({ name: 'check', style: 'solid' })} />}
                    type="submit"
                    isLoading={isLoading}
                    isDisabled={!formState.isValid || !formState.isDirty}
                >
                    <FormattedMessage defaultMessage="Enregistrer" />
                </Button>
            </FormStyled>
        </>
    );
};

const FormStyled = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    width: 100%;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        & > button[type='submit'] {
            align-self: flex-start;
        }
    }
`;
const ToggleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
`;
const TooltipIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: var(--neutral500);
`;
const EyeIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const RssLinkWrapper = styled.div`
    display: flex;
    align-items: flex-end;
    column-gap: 0.5rem;

    & > :first-child {
        flex-grow: 1;
    }

    & > :last-child {
        flex-shrink: 0;
    }
`;
const SaveIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;

export default Form;
