import { FormattedMessage, useIntl } from 'react-intl';
import DeprecatedText from '../../ui/atoms/DeprecatedText';
import FetcherPeakTimePopularHours from '../fetchers/stats/FetcherPeakTimePopularHours';
import { connect } from '../decorators/connect';
import ShortListFallback from '../fallbacks/ShortListFallback';
import PeakHourBarGraph, { STATS_NAME } from '../../ui/molecules/PeakHourBarGraph';
import { useResponsive } from '../../../utils/hooks/useResponsive';
import styled, { css } from 'styled-components';

const enhance = connect(({ peakTimeStatsStore }) => ({
    popularHours: peakTimeStatsStore.popularHours,
    daysRangePeakTime: peakTimeStatsStore.daysRangePeakTime,
}));

const PeakTimePopularHours = ({
    popularHours,
    daysRangePeakTime,
    messageLaunchTime,
    match,
    isNoData,
}) => {
    const intl = useIntl();
    const { isMobileOrTablet } = useResponsive();
    return (
        <>
            <DeprecatedText size="veryBig" weight="bold" mb={5}>
                <FormattedMessage defaultMessage="Heures les plus populaires" />
            </DeprecatedText>
            <DeprecatedText>
                {daysRangePeakTime === 'always' ? (
                    <FormattedMessage defaultMessage="Vos écoutes selon les heures depuis le début" />
                ) : (
                    <FormattedMessage
                        defaultMessage="Vos écoutes selon les heures sur les {messageLaunchTime}"
                        values={{ messageLaunchTime }}
                    />
                )}
            </DeprecatedText>
            <PeakTimePopularHourWrapper isFlex={isNoData}>
                <FetcherPeakTimePopularHours
                    showId={match.params.showId}
                    days={daysRangePeakTime}
                    fallback={ShortListFallback}
                >
                    {(isLoading) =>
                        !isLoading && (
                            <>
                                <PeakHourBarGraph
                                    options={popularHours.dayHour}
                                    series={popularHours.downloads.map((download, index) => ({
                                        x: index + 1,
                                        y: download,
                                        tooltipMessage: intl.formatMessage(
                                            {
                                                defaultMessage:
                                                    '{count, plural, zero {# écoute} one {# écoute} other {# écoutes}}',
                                            },
                                            { count: download },
                                        ),
                                    }))}
                                    columnWidth={isMobileOrTablet ? '50%' : '70%'}
                                    fontSize={isMobileOrTablet && 8}
                                    isNoData={isNoData}
                                    statName={STATS_NAME.LISTENERS}
                                    capitalize
                                />
                            </>
                        )
                    }
                </FetcherPeakTimePopularHours>
            </PeakTimePopularHourWrapper>
        </>
    );
};

const PeakTimePopularHourWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding);
    margin-block: 2rem;
    gap: 1rem ${({ theme }) => theme.mediaQueries.mobile} {
        padding: var(--container-padding-mobile);
    }

    ${({ isFlex }) =>
        isFlex &&
        css`
            display: flex;
            align-items: center;
            justify-content: center;
        `}
`;

export default enhance(PeakTimePopularHours);
