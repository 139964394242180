/**
 * Pushes a 'login' event to Google Tag Manager's dataLayer.
 */
export const pushLoginEventToGTM = (): void => {
    // Check if 'window.dataLayer' exists before attempting to push data
    if ('dataLayer' in window) {
        (window as any).dataLayer.push({
            event: 'login',
        });
    } else {
        console.warn('GTM dataLayer is not initialized.');
    }
};

/**
 * Pushes a 'signup' event to Google Tag Manager's dataLayer.
 */
export const pushSignupEventToGTM = (): void => {
    // Check if 'window.dataLayer' exists before attempting to push data
    if ('dataLayer' in window) {
        (window as any).dataLayer.push({
            event: 'signup',
        });
    } else {
        console.warn('GTM dataLayer is not initialized.');
    }
};
