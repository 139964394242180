import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Text from '@ui/atoms/Text';
import Button from '@ui/atoms/Button';
import styled from 'styled-components';
import { UI_VARIANTS } from '@/shared/config/constants';
import DialogAlert from '@ui/atoms/DialogAlert';

const EpisodeMoveToDraftModal = ({ isOpen, onOpenChange, isLoading, handleMoveToDraft }) => {
    return (
        <DialogAlert
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            action={
                <>
                    <Button isLoading={isLoading} onPress={handleMoveToDraft}>
                        <FormattedMessage defaultMessage="Déplacer vers brouillons" />
                    </Button>
                    <CancelButton onPress={() => onOpenChange(false)} variant="ghost">
                        <FormattedMessage defaultMessage="Annuler" />
                    </CancelButton>
                </>
            }
            variant={UI_VARIANTS.WARNING}
            title={
                <FormattedMessage defaultMessage="Souhaitez-vous déplacer l’épisode dans vos brouillons ?" />
            }
        >
            <Text variant="bodyM" color="--neutral500" textAlign="center">
                <FormattedMessage defaultMessage="Celui-ci ne sera plus en ligne et pourra être éditable à tout moment." />
            </Text>
        </DialogAlert>
    );
};

const CancelButton = styled(Button)`
    color: var(--neutral500);
    padding: 0;
`;

EpisodeMoveToDraftModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onOpenChange: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    handleMoveToDraft: PropTypes.func,
};

EpisodeMoveToDraftModal.defaultProps = {
    isOpen: false,
    isLoading: false,
    onOpenChange: () => {},
    handleMoveToDraft: () => {},
};

export default EpisodeMoveToDraftModal;
