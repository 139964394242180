import ChannelGuests from '@/components/ChannelGuests';
import ChannelGuestsEmptyState from '@/components/ChannelGuests/ChannelGuestsEmptyState';
import ChannelGuestsSkeleton from '@/components/ChannelGuests/ChannelGuestsSkeleton';
import useChannelQuery from '@/queries/channel/useChannelQuery.hook';
import useChannelGuestInvitationsQuery from '@/queries/guest/useChannelGuestInvitationsQuery.hook';
import useChannelGuestPermissionsQuery from '@/queries/guest/useChannelGuestPermissionsQuery.hook';

const ChannelGuestsPage = () => {
    const channel = useChannelQuery();
    // TODO: update channel when API is ready
    const invitations = useChannelGuestInvitationsQuery(channel.data?.id, {
        enabled: !!channel.data?.id,
    });
    // TODO: update channel when API is ready
    const permissions = useChannelGuestPermissionsQuery(channel.data?.id, {
        enabled: !!channel.data?.id,
    });

    const isLoading = channel.isLoading || invitations.isLoading || permissions.isLoading;
    const isError = channel.isError || invitations.isError || permissions.isError;
    const hasNoUsers = !invitations.data?.length && !permissions.data?.length;

    // TODO: Handle error state
    if (isError) {
        return <div>Error</div>;
    }

    if (isLoading) {
        return <ChannelGuestsSkeleton />;
    }

    if (hasNoUsers) {
        return <ChannelGuestsEmptyState />;
    }

    return <ChannelGuests permissions={permissions.data} invitations={invitations.data} />;
};

export default ChannelGuestsPage;
