import { useEpisodeBeingEdited } from '@/context/EpisodeFormContext';
import useShowNewsletterTemplateQuery from '@queries/newsletter/useShowNewsletterTemplateQuery.hook';
import EpisodeEditFormNewsletterEditor from '@/components/unorganized/EpisodeEditForm/EpisodeEditFormNewsletter/EpisodeEditFormNewsletterEditor';
import { useFormContext } from 'react-hook-form';
import { EPISODE_PRIVACY } from '@/shared/config/constants';
import NewsletterDisabled from '@/components/unorganized/EpisodeEditForm/EpisodeEditFormNewsletter/NewsletterDisabled';
import NewsletterNotAllowed from '@/components/unorganized/EpisodeEditForm/EpisodeEditFormNewsletter/NewsletterNotAllowed';
import styled from 'styled-components';
import Spinner from '@ui/atoms/Spinner';
import EpisodeEditFormNewsletterError from '@/components/unorganized/EpisodeEditForm/EpisodeEditFormNewsletter/EpisodeEditFormNewsletterError';
import { AxiosError } from 'axios';

const EpisodeEditFormNewsletter = () => {
    const episode = useEpisodeBeingEdited();
    const { watch } = useFormContext();

    const privacy = watch('privacy.privacy');
    const isEpisodePublic = privacy === EPISODE_PRIVACY.PUBLIC;

    const showNewsletterTemplateQuery = useShowNewsletterTemplateQuery(`${episode?.show?.id}`);

    if (showNewsletterTemplateQuery.isLoading) {
        return (
            <LoadingWrapper>
                <Spinner size={32} />
            </LoadingWrapper>
        );
    }

    if (showNewsletterTemplateQuery.error) {
        // Current show does not have newsletter settings yet
        const noNewsletterSettings =
            (showNewsletterTemplateQuery.error as AxiosError).response?.status === 404;

        if (noNewsletterSettings) {
            return <NewsletterDisabled />;
        } else {
            return <EpisodeEditFormNewsletterError />;
        }
    }

    const { data: showNewsletterTemplate } = showNewsletterTemplateQuery;

    // Newsletter is not enabled for the show
    if (!showNewsletterTemplate?.activated) {
        return <NewsletterDisabled />;
    }

    if (!isEpisodePublic) {
        return <NewsletterNotAllowed />;
    }

    return <EpisodeEditFormNewsletterEditor />;
};

const LoadingWrapper = styled.div`
    height: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default EpisodeEditFormNewsletter;
