import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Center from '@ui/layout/Center';
import WithoutTranscript from '@public/images/clip-edit-without-transcript.svg';
import Option from './Option';
import Stack from '@ui/layout/Stack';
import { resolveColor } from '@/shared/utils/cssVariable';
import Text from '@ui/atoms/Text';

const WithoutTranscriptOption = ({ selected, onClick }) => (
    <Stack $gap="1rem">
        <Block
            selected={selected}
            onClick={onClick}
            backgroundColor={resolveColor('--info50')}
            borderColor={resolveColor('--info500')}
        >
            <Stack $gap="1rem">
                <Center>
                    <IllustrationWithoutTranscript />
                </Center>
                <Stack $gap="0.5rem">
                    <Text variant="subheading" fontWeight="--fw-bold" textAlign="center">
                        <FormattedMessage defaultMessage="Sans transcription" />
                    </Text>
                    <Text textAlign="center">
                        <FormattedMessage defaultMessage="Le clip vidéo classique avec deux titres personnalisables." />
                    </Text>
                </Stack>
            </Stack>
        </Block>
    </Stack>
);

const Block = styled(Option)`
    padding: 2rem 1rem;
`;

const IllustrationWithoutTranscript = styled(WithoutTranscript)`
    width: 12rem;
`;

export default WithoutTranscriptOption;
