import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import ExternalLink from '@/components/Link/ExternalLink';
import styled from 'styled-components';
interface HeaderProps {
    children: React.ReactNode;
}

const Header = ({ children }: HeaderProps) => {
    const intl = useIntl();
    return (
        <HeaderWrapper>
            <HeaderInnerWrapper>
                <TitleAndDescription>
                    <TitleWrapper>
                        <TitleIcon icon={icon({ name: 'users', style: 'solid' })} />
                        <Title>
                            <FormattedMessage defaultMessage="Compétiteurs" />
                        </Title>
                    </TitleWrapper>
                    <Description>
                        <FormattedMessage
                            defaultMessage="Découvrez quels podcasts sont les mieux positionnés sur vos mots-clés. Identifiez vos concurrents, comparez vos différences et améliorez ainsi votre stratégie. <link>En savoir plus.</link>"
                            values={{
                                link: (chunks: string) => (
                                    <DescriptionLink
                                        href={intl.formatMessage({
                                            defaultMessage: 'http://www.ismycomputeron.com/',
                                        })}
                                        target="_blank"
                                    >
                                        {chunks}
                                    </DescriptionLink>
                                ),
                            }}
                        />
                    </Description>
                </TitleAndDescription>
            </HeaderInnerWrapper>
            {children}
        </HeaderWrapper>
    );
};

const HeaderWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding);
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;
const HeaderInnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    gap: 1rem;
`;
const TitleAndDescription = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    flex: 1;
`;
const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.75rem;
`;
const TitleIcon = styled(FontAwesomeIcon)`
    width: 1.25rem;
    height: 1.25rem;
`;
const Title = styled.h1`
    font-size: var(--fs-heading-m);
    font-weight: var(--fw-bold);
`;
const Description = styled.p`
    color: var(--neutral);
`;
const DescriptionLink = styled(ExternalLink)`
    color: inherit;
    text-decoration: underline;
    font-weight: inherit;

    &[data-hovered='true'] {
        color: inherit;
    }
`;

export default Header;
