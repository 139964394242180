import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchClipFormat } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import clipFormatKeys from './clipFormatKeys';
import { ClipFormatRaw } from './types';
import { ClipFormatSchema } from './ClipFormat.schema';

type Key = QueryFunctionContext<ReturnType<typeof clipFormatKeys.detailById>>;

const queryFn = async ({ queryKey: [{ clipFormatId }] }: Key) => {
    const { data } = await fetchClipFormat(clipFormatId);
    const { podcast_video_clip, images, ...rest } = data as ClipFormatRaw;
    const { podcast_id, ...clip } = podcast_video_clip.data;
    const formattedData = {
        ...rest,
        images: images.data,
        clip: {
            ...clip,
            episodeId: podcast_id,
        },
    };

    // TODO: Do camelcase transformation in Axios interceptor
    return ClipFormatSchema.parse(camelcaseKeys(formattedData, { deep: true }));
};

const useShowClipFormatQuery = (clipFormatId: string, options: any) => {
    return useQuery({
        queryKey: clipFormatKeys.detailById(clipFormatId),
        queryFn,
        enabled: !!clipFormatId,
        ...options,
    });
};

export default useShowClipFormatQuery;
