import { useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { InputTextController } from '@ui/atoms/InputText';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import Button from '@ui/atoms/Button';
import PropTypes from 'prop-types';

const LinkForm = ({ onSubmit, onCancel, link }) => {
    const { control, handleSubmit } = useForm({
        defaultValues: {
            link,
        },
    });
    const ref = useRef(null);
    const intl = useIntl();

    const onSubmitHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
        handleSubmit(onSubmit)();
    };

    return (
        <Form onSubmit={onSubmitHandler}>
            <InputTextController
                ref={ref}
                control={control}
                name="link"
                placeholder={intl.formatMessage({ defaultMessage: 'https://www.example.com' })}
                autoFocus
            />
            <ButtonsWrapper>
                <Button type="submit">
                    <FormattedMessage defaultMessage="Enregistrer" />
                </Button>
                <Button variant="secondary" onPress={onCancel}>
                    <FormattedMessage defaultMessage="Annuler" />
                </Button>
            </ButtonsWrapper>
        </Form>
    );
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    height: 100%;
`;
const ButtonsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    margin-top: auto;

    & > :first-child,
    > :last-child {
        align-self: stretch;
    }

    ${(p) => p.theme.mediaQueries.tabletAndUp} {
        flex-direction: row;
        justify-content: flex-end;
        column-gap: 0.5rem;
        margin-top: 0.5rem;

        & > :first-child,
        > :last-child {
            align-self: initial;
        }

        & > :first-child {
            order: 1;
        }
    }
`;

LinkForm.propTypes = {
    link: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};
LinkForm.defaultProps = {
    link: '',
    onSubmit: () => {},
    onCancel: () => {},
};

export default LinkForm;
