import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Stack from '@ui/layout/Stack';
import Text from '@ui/atoms/Text';
import Cluster from '@ui/layout/Cluster';
import {
    HOSTING_PLATFORMS,
    HOSTING_PLATFORMS_HELP_LINKS,
    HOSTING_PLATFORMS_NAMES,
    RSS_FEED_REDIRECTION_TUTORIAL_URL_EN,
    RSS_FEED_REDIRECTION_TUTORIAL_URL_FR,
} from '@/shared/config/constants';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import { useParams } from 'react-router';
import useRssFeedQuery from '@/queries/rss/useRssFeedQuery.hook';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import styled from 'styled-components';
import Modal from '@/components/ui/atoms/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@/components/IconButton';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Alert from '@/components/ui/atoms/Alert';
import { sendAmplitudeLogEvent } from '@/shared/utils/amplitude';
import InputText from '@/components/ui/atoms/InputText';
import Button from '@/components/Button';
import ExternalLink from '@/components/Link/ExternalLink';

interface FeedRedirectionModalProps {
    isOpen: boolean;
    onOpenChange: (isOpen: boolean) => void;
}

const FeedRedirectionModal = ({ isOpen, onOpenChange }: FeedRedirectionModalProps) => {
    const { showId } = useParams<{ showId: string }>();
    const intl = useIntl();
    const { data: show } = useShowQuery(showId);
    const { data: feed } = useRssFeedQuery({ showId });
    const { data: user } = useUserQuery();

    const handleClose = () => {
        onOpenChange(false);
    };

    const handleCopyRssLink = () => {
        if (!show || !feed) return;
        sendAmplitudeLogEvent('RSS Feed Link Copied', {
            show_id: show.id,
            show_name: show.name,
            show_category: show.category.data.name,
            number_episodes: show.podcastsCount,
            previous_hoster: feed.originalPlatform,
        });
    };

    const tutorialLink = useMemo(() => {
        if (
            feed?.originalPlatform &&
            HOSTING_PLATFORMS_HELP_LINKS[
                feed.originalPlatform as keyof typeof HOSTING_PLATFORMS_HELP_LINKS
            ]
        ) {
            return intl.formatMessage(
                HOSTING_PLATFORMS_HELP_LINKS[
                    feed.originalPlatform as keyof typeof HOSTING_PLATFORMS_HELP_LINKS
                ],
            );
        }

        return user?.language === 'fr'
            ? RSS_FEED_REDIRECTION_TUTORIAL_URL_FR
            : RSS_FEED_REDIRECTION_TUTORIAL_URL_EN;
    }, [feed, user]);

    return (
        <ModalStyled isOpen={isOpen} onOpenChange={onOpenChange} size="large">
            <Header>
                <Text variant="headingM" fontWeight="--fw-bold">
                    <FormattedMessage defaultMessage="Redirigez votre flux RSS" />
                </Text>
                <IconButton
                    icon={<CloseIcon icon={icon({ name: 'xmark-large', style: 'solid' })} />}
                    onPress={handleClose}
                    variant="ghost"
                    aria-label="Close modal"
                    style={{ padding: 0 }}
                />
            </Header>
            <Stack $gap="2.5rem">
                <Stack $gap="1rem">
                    <Text>
                        <FormattedMessage defaultMessage="Copiez le lien de votre nouveau flux RSS ci-dessous :" />
                    </Text>
                    <InputText
                        value={show?.rssLink}
                        isReadOnly
                        copyToClipboardEnabled
                        onCopyToClipboard={handleCopyRssLink}
                    />
                    <Text>
                        {feed?.originalPlatform &&
                        HOSTING_PLATFORMS.includes(
                            feed.originalPlatform as keyof typeof HOSTING_PLATFORMS_NAMES,
                        ) ? (
                            <FormattedMessage
                                defaultMessage="Il semblerait que votre émission provienne de {platform}. Suivez notre tutoriel pour la rediriger vers Ausha."
                                values={{
                                    platform: (
                                        <Text fontWeight="--fw-bold" as="span">
                                            {
                                                HOSTING_PLATFORMS_NAMES[
                                                    feed.originalPlatform as keyof typeof HOSTING_PLATFORMS_NAMES
                                                ]
                                            }
                                        </Text>
                                    ),
                                }}
                            />
                        ) : (
                            <FormattedMessage defaultMessage="Suivez ensuite notre tutoriel pour le rediriger vers Ausha." />
                        )}
                    </Text>
                    <Alert variant="info" defaultIcon={true}>
                        <Text fontWeight="--fw-semibold">
                            <FormattedMessage defaultMessage="Cette étape est requise pour finaliser l’import de votre émission sur Ausha." />
                        </Text>
                    </Alert>
                </Stack>
                <Cluster $gap="0.5rem" $justifyContent="space-between" $align="center">
                    <Button variant="link-secondary" onPress={handleClose}>
                        <FormattedMessage defaultMessage="Annuler" />
                    </Button>
                    <ExternalLink
                        startIcon={
                            <ExternalLinkIcon
                                icon={icon({ name: 'arrow-up-right-from-square', style: 'solid' })}
                            />
                        }
                        variant="button-primary"
                        href={tutorialLink}
                        target="_blank"
                    >
                        <FormattedMessage defaultMessage="Suivre le tutoriel" />
                    </ExternalLink>
                </Cluster>
            </Stack>
        </ModalStyled>
    );
};

const ModalStyled = styled(Modal)`
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 2rem;
    overflow-y: auto;
`;
const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 1rem;
`;
const CloseIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--neutral500);
`;
const ExternalLinkIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;

export default FeedRedirectionModal;
