import { useMemo } from 'react';
import styled from 'styled-components';
import CheckContext from './CheckContext';

interface CheckProps {
    isCompleted?: boolean;
    isLoading?: boolean;
    children: React.ReactNode;
}

const Check = ({ isCompleted, isLoading, children, ...otherProps }: CheckProps) => {
    const contextValue = useMemo(() => ({ isCompleted, isLoading }), [isCompleted, isLoading]);

    return (
        <CheckContext.Provider value={contextValue}>
            <Container {...otherProps}>{children}</Container>
        </CheckContext.Provider>
    );
};

const Container = styled.div`
    flex: 1;
    position: relative;
`;

export default Check;
