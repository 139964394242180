import { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { useResponsive } from '@/shared/hooks/useResponsive';
import Stack from '@ui/layout/Stack';
import { PRICING_NAME } from '@/shared/config/pricing';
import { getSavingOnYearlyBilling, isSamePricingThan } from '@/shared/services/pricingService';
import PricingContext from '../../PricingContext';
import PricingSelectOfferButton from '../PricingSelectOfferButton';
import PricingCancelSubscriptionButton from '../PricingCancelSubscriptionButton';
import Desktop from '@ui/molecules/Desktop';
import MobileOrTablet from '@ui/molecules/MobileOrTablet';
import { extractCssVariable } from '@/shared/utils/cssVariable';
import Text from '@ui/atoms/Text';
import type { Offer, Currency } from '@/queries/price/usePricesQuery.hook';

const CurrentOfferLabel = () => (
    <OfferLabel backgroundColor={extractCssVariable('--primary')}>
        <Text fontWeight="--fw-semibold" color="white" variant="footnote">
            <FormattedMessage defaultMessage="Offre actuelle" />
        </Text>
    </OfferLabel>
);
const HighlightedOfferLabel = () => (
    <OfferLabel backgroundColor={extractCssVariable('--primary')}>
        <Text fontWeight="--fw-semibold" color="white" variant="footnote">
            <FormattedMessage defaultMessage="Coup de 🤍 de nos podcasteurs" />
        </Text>
    </OfferLabel>
);

interface OfferPriceProps {
    price: number;
    currency: Currency | null;
}

const OfferPrice = ({ price, currency }: OfferPriceProps) => {
    const { isYearlyPeriodicitySelected } = useContext(PricingContext);

    return (
        // @ts-ignore
        <PriceInnerWrapper>
            <Price>
                <FormattedMessage
                    defaultMessage="{price} / mois"
                    values={{
                        price: (
                            <Text fontWeight="--fw-bold" variant="largeTitle">
                                <FormattedNumber
                                    value={price}
                                    style="currency"
                                    currency={currency ? currency : ''}
                                    currencyDisplay="narrowSymbol"
                                    maximumFractionDigits={Number.isInteger(price) ? 0 : 2}
                                />
                            </Text>
                        ),
                    }}
                />
            </Price>
            <BilledAnnuallyLabel
                isYearlyPeriodicitySelected={isYearlyPeriodicitySelected}
                fontWeight="--fw-semibold"
                color="--neutral500"
            >
                <FormattedMessage defaultMessage="facturé annuellement" />
            </BilledAnnuallyLabel>
        </PriceInnerWrapper>
    );
};

interface PricingOfferWrapperProps {
    children: React.ReactNode;
}

const PricingOfferWrapper = ({ children }: PricingOfferWrapperProps) => {
    const { isDesktop } = useResponsive();

    if (isDesktop) {
        // On desktop screen, cancel button is inside PricingOffer container and uses absolute position
        // to minimize layout changes
        return <>{children}</>;
    }

    // Cancel button is outside PricingOffer container and take its place in layout flow
    return <Stack $gap="1rem">{children}</Stack>;
};

export interface PricingOfferProps {
    offer: Offer;
    offerDescription: React.ReactNode | string;
    offerImage: string;
    offerPrice: number;
    isCurrentOffer: boolean;
    isHighlighted?: boolean;
    children: React.ReactNode;
}

const PricingOffer = ({
    offer,
    offerDescription,
    offerImage,
    offerPrice,
    isCurrentOffer,
    isHighlighted,
    children,
}: PricingOfferProps) => {
    const { isDesktop } = useResponsive();
    const {
        isCurrentSubscriptionActive,
        pricesOfCurrentSubscriptionPricing,
        isCurrentSubscriptionBilledYearly,
        currentSubscriptionPricing,
        selectedCurrency,
    } = useContext(PricingContext);

    // If the current subscription is billed monthly, a label with the savings
    // made by switching to yearly billing must be displayed.
    const isSavingOnYearlyBillingLabelDisplayed = useMemo(
        () =>
            isCurrentSubscriptionActive &&
            isSamePricingThan(offer, currentSubscriptionPricing) &&
            !isCurrentSubscriptionBilledYearly,
        [
            isCurrentSubscriptionActive,
            offer,
            currentSubscriptionPricing,
            isCurrentSubscriptionBilledYearly,
        ],
    );
    const savingOnYearlyBilling = useMemo(() => {
        const currentSubPricing = pricesOfCurrentSubscriptionPricing();
        if (!currentSubPricing) {
            return 0;
        }
        return getSavingOnYearlyBilling(
            currentSubPricing.monthly.base,
            currentSubPricing.yearly.base,
        );
    }, [pricesOfCurrentSubscriptionPricing]);

    let borderColor = '--white';

    if (isHighlighted) {
        borderColor = '--primary';
    }
    if (isCurrentOffer) {
        borderColor = '--primary';
    }

    return (
        <PricingOfferWrapper>
            <Container $borderColor={borderColor}>
                {isCurrentOffer && <CurrentOfferLabel />}
                {isHighlighted && !isCurrentOffer && <HighlightedOfferLabel />}
                <Stack
                    $gap={
                        isSavingOnYearlyBillingLabelDisplayed
                            ? '1rem'
                            : isDesktop
                            ? '2rem'
                            : '1.5rem'
                    }
                >
                    <Stack $gap="1rem">
                        <OfferImage src={offerImage} alt="" />
                        <PricingOfferTitleWrapper>
                            <PriceOuterWrapper>
                                <PriceWrapper>
                                    <Text fontWeight="--fw-bold" variant="title">
                                        {PRICING_NAME[offer]}
                                    </Text>
                                    <Text color="--neutral500">{offerDescription}</Text>
                                </PriceWrapper>
                                <OfferPrice price={offerPrice} currency={selectedCurrency} />
                            </PriceOuterWrapper>
                        </PricingOfferTitleWrapper>
                        <PricingSelectOfferButton offer={offer} />
                    </Stack>
                    {isSavingOnYearlyBillingLabelDisplayed && (
                        <SavingOnYearlyBillingLabel fontWeight="--fw-semibold">
                            <FormattedMessage
                                defaultMessage="Économisez {saving}"
                                values={{
                                    saving: (
                                        <FormattedNumber
                                            value={savingOnYearlyBilling}
                                            style="currency"
                                            currency={selectedCurrency ? selectedCurrency : ''}
                                            currencyDisplay="narrowSymbol"
                                            maximumFractionDigits={
                                                Number.isInteger(savingOnYearlyBilling) ? 0 : 2
                                            }
                                        />
                                    ),
                                }}
                            />
                        </SavingOnYearlyBillingLabel>
                    )}
                    {children}
                </Stack>
                {isCurrentOffer && (
                    <Desktop>
                        <PricingCancelSubscriptionButton />
                    </Desktop>
                )}
            </Container>
            {isCurrentOffer && (
                <MobileOrTablet>
                    <PricingCancelSubscriptionButton />
                </MobileOrTablet>
            )}
        </PricingOfferWrapper>
    );
};

const OfferLabel = styled.div<{ backgroundColor: string }>`
    background-color: ${(props) => props.backgroundColor};
    border-bottom-left-radius: var(--r-s);
    border-top-right-radius: var(--r-s);
    padding: 0.25rem 0.5rem;
    position: absolute;
    right: -1px;
    top: -1px;
`;
const PricingOfferTitleWrapper = styled.div`
    ${(props) => props.theme.mediaQueries.desktopAndUp} {
        min-height: 4.75rem;
    }
`;
const Container = styled.div<{ $borderColor: string }>`
    background-color: var(--white);
    padding: 1.5rem;
    border-radius: var(--r-s);
    position: relative;
    border: 2px solid;
    border-color: ${(props) => `var(${props.$borderColor})`};
`;
const OfferImage = styled.img`
    isolation: isolate;
    height: 7.5rem;
    align-self: flex-start;
    margin-top: calc(-1.5rem - (8rem / 2));

    ${(p) => p.theme.mediaQueries.desktopAndUp} {
        margin-top: calc(-1.5rem - (5rem / 2));
    }
`;
const BilledAnnuallyLabel = styled(Text)<{ isYearlyPeriodicitySelected: boolean }>`
    line-height: 1.5em;
    font-size: 0.625rem;
    visibility: ${(props) => (props.isYearlyPeriodicitySelected ? 'visible' : 'hidden')};

    ${(props) => props.theme.mediaQueries.desktopAndUp} {
        font-size: 0.75rem;
    }
`;
const SavingOnYearlyBillingLabel = styled(Text)`
    color: var(--success);
    line-height: 1.5em;
    text-align: center;
`;
const PriceOuterWrapper = styled.div`
    display: flex;

    @media (max-width: 1190px) {
        flex-direction: column;
    }

    @media (max-width: 730px) {
        justify-content: space-between;
        flex-direction: row;
    }

    @media (max-width: 405px) {
        flex-direction: column;
    }
`;
const PriceWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
const PriceInnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media (min-width: 1190px) {
        flex: 1 0 6rem;
    }
`;
const Price = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
    white-space: nowrap;
`;

export default PricingOffer;
