const psoCheckerKeys = {
    all: () => [{ entity: 'psoChecker' }],
    allDetails: () => [{ ...psoCheckerKeys.all()[0], scope: 'detail' }],
    detail: () => [{ ...psoCheckerKeys.allDetails()[0] }],
    targetKeyword: (episodeId: string) => [
        { ...psoCheckerKeys.all()[0], context: 'targetKeyword', episodeId },
    ],
    aiKeywords: (episodeId: string) => [
        { ...psoCheckerKeys.all()[0], context: 'aiKeywords', episodeId },
    ],
    aiTargetKeywords: (episodeId: string) => [
        { ...psoCheckerKeys.all()[0], context: 'aiTargetKeywords', episodeId },
    ],
};

export default psoCheckerKeys;
