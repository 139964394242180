import { Checkbox as AriaCheckbox } from 'react-aria-components';
import type { CheckboxProps as AriaCheckboxProps } from 'react-aria-components';
import type { ControllerFieldState } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon as iconFA } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled, { css } from 'styled-components';

export interface CheckboxProps extends AriaCheckboxProps {
    inputState?: ControllerFieldState;
    errorMessage?: string | React.ReactNode;
    icon?: React.ReactNode;
}

const Checkbox = ({ children, icon, ...props }: CheckboxProps) => {
    return (
        <CheckBoxStyled {...props} key={Math.random()}>
            {({ isSelected, isDisabled, isReadOnly, isInvalid }) => (
                <CheckboxWrapper
                    isSelected={isSelected}
                    isDisabled={isDisabled}
                    isReadOnly={isReadOnly}
                    isInvalid={isInvalid}
                >
                    <CheckboxInnerWrapper isSelected={isSelected} isInvalid={isInvalid}>
                        {isSelected &&
                            (icon || (
                                <CheckboxIcon
                                    icon={iconFA({ name: 'check', style: 'solid' })}
                                    fixedWidth
                                    size="xs"
                                />
                            ))}
                    </CheckboxInnerWrapper>
                    {children}
                </CheckboxWrapper>
            )}
        </CheckBoxStyled>
    );
};
const CheckBoxStyled = styled(AriaCheckbox)`
    line-height: 0;
`;
const CheckboxInnerWrapper = styled.div<{ isSelected: boolean; isInvalid: boolean }>`
    position: relative;
    width: 1rem;
    height: 1rem;
    border-radius: var(--r-xs);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    ${({ isSelected }) =>
        isSelected
            ? css`
                  border: 1px solid var(--primary);
                  background-color: var(--primary);
              `
            : css`
                  border: 1px solid var(--neutral200);
                  background-color: var(--white);
              `}

    ${({ isInvalid }) =>
        isInvalid &&
        css`
            border: 1px solid var(--alert);
        `};

    ${({ isInvalid, isSelected }) =>
        isInvalid &&
        isSelected &&
        css`
            border: 1px solid var(--alert);
            background-color: var(--alert);
        `};
`;
const CheckboxWrapper = styled.label<{
    isSelected: boolean;
    isDisabled: boolean;
    isReadOnly: boolean;
    isInvalid: boolean;
}>`
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;

    ${({ isDisabled }) =>
        isDisabled &&
        css`
            opacity: 0.5;
            cursor: not-allowed;
        `}

    ${({ isReadOnly }) =>
        isReadOnly &&
        css`
            cursor: not-allowed;
        `}

    ${({ isSelected, isReadOnly, isInvalid }) =>
        !isSelected &&
        !isReadOnly &&
        !isInvalid &&
        css`
            &:hover {
                ${CheckboxInnerWrapper} {
                    border-color: var(--neutral300);
                }
            }
        `}

    ${({ isInvalid }) =>
        isInvalid &&
        css`
            color: var(--alert);
        `}
`;
const CheckboxIcon = styled(FontAwesomeIcon)`
    color: var(--white);
`;

export default Checkbox;
