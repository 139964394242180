import { defineMessages, useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import useFetchYoutubeCategories from './useFetchYoutubeCategories.hook';
import useFetchYoutubeBroadcastSettings from './useFetchYoutubeBroadcastSettings.hook';
import useUpdateYoutubeBroadcastSettings from './useUpdateYoutubeBroadcastSettings.hook';
import ListFormFallback from '@app/fallbacks/ListFormFallback';
import YoutubeBroadcastSettingsForm from './YoutubeBroadcastSettingsForm';
import { useModalToastQueue } from '@/utils/hooks/useModalToastQueue.hook';

const categoriesLabelsTranslations = defineMessages({
    'Action/Adventure': { defaultMessage: 'Action/aventure' },
    'Anime/Animation': { defaultMessage: "Dessins animés/films d'animation" },
    'Auto & Vehicles': { defaultMessage: 'Auto/Moto' },
    Classics: { defaultMessage: 'Classique' },
    Comedy: { defaultMessage: 'Humour' },
    Documentary: { defaultMessage: 'Documentaire' },
    Drama: { defaultMessage: 'Drame' },
    Education: { defaultMessage: 'Éducation' },
    Entertainment: { defaultMessage: 'Divertissement' },
    Family: { defaultMessage: 'Famille' },
    'Film & Animation': { defaultMessage: 'Films et animations' },
    Foreign: { defaultMessage: 'Étranger' },
    Gaming: { defaultMessage: 'Jeux vidéo' },
    Horror: { defaultMessage: 'Horreur' },
    'Howto & Style': { defaultMessage: 'Vie pratique et style' },
    Movies: { defaultMessage: 'Films' },
    Music: { defaultMessage: 'Musique' },
    'News & Politics': { defaultMessage: 'Actualités et politique' },
    'People & Blogs': { defaultMessage: 'People et blogs' },
    'Pets and Animals': { defaultMessage: 'Animaux' },
    'Sci-Fi/Fantasy': { defaultMessage: 'Science-fiction/fantastique' },
    'Science & Technology': { defaultMessage: 'Science et technologie' },
    'Short Movies': { defaultMessage: 'Courts métrages' },
    Shorts: { defaultMessage: 'Court métrage' },
    Shows: { defaultMessage: 'Émissions' },
    Sports: { defaultMessage: 'Sport' },
    Thriller: { defaultMessage: 'Thriller' },
    Trailers: { defaultMessage: 'Bandes-annonces' },
    'Travel & Events': { defaultMessage: 'Voyages et événements' },
    Videoblogging: { defaultMessage: 'Blogs vidéo' },
});

const YoutubeBroadcastSettings = () => {
    const intl = useIntl();
    const { showId } = useParams();
    const settings = useFetchYoutubeBroadcastSettings(showId);
    const categories = useFetchYoutubeCategories();
    const updateSettings = useUpdateYoutubeBroadcastSettings(showId);
    const formatedCategories = categories?.data?.map((category) => ({
        id: category.id,
        label: intl.formatMessage(categoriesLabelsTranslations[category.name]),
    }));
    const toast = useModalToastQueue();

    const onSubmit = async (formData, reset) => {
        updateSettings.mutate(formData, {
            onSuccess: () => {
                toast.success();
                reset(formData);
            },
            onError: () => toast.alert(),
        });
    };

    if (settings.isLoading || !formatedCategories) return <ListFormFallback />;

    return (
        <YoutubeBroadcastSettingsForm
            settings={settings.data}
            categoryOptions={formatedCategories}
            onSubmit={onSubmit}
            isLoading={updateSettings.isLoading}
        />
    );
};

export default observer(YoutubeBroadcastSettings);
