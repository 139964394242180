import showKeys from '@/queries/show/showKeys';
import { useQuery } from '@tanstack/react-query';
import { fetchShows } from '@/api';
import camelcaseKeys from 'camelcase-keys';

const queryFn = async ({ queryKey: [{ channelId }] }) => {
    const { data } = await fetchShows(channelId);
    const transformedData = camelcaseKeys(data, { deep: true });

    // TODO: Do camelcase transformation in Axios interceptor
    return transformedData.filter((show) => show.archived);
};

const useArchivedShowsQuery = (channelId) => {
    return useQuery({
        queryKey: showKeys.listArchived(channelId),
        queryFn,
        enabled: !!channelId,
    });
};

export default useArchivedShowsQuery;
