import { FormattedMessage, useIntl } from 'react-intl';
import { InputTextController } from '@ui/atoms/InputText';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useModalToastQueue } from '@/shared/hooks/useModalToastQueue.hook';
import { zodResolver } from '@hookform/resolvers/zod';
import Button from '@/components/Button';
import useUserQuery from '@queries/user/useUserQuery.hook';
import updateEmailMutation from '@queries/user/updateEmailMutation';
import { EmailFormSchema, useEmailFormSchema } from './schema';
import { AxiosError } from 'axios';

interface EmailFormProps {
    onAfterSubmit: () => void;
}

const EmailForm = ({ onAfterSubmit }: EmailFormProps) => {
    const intl = useIntl();
    const userQuery = useUserQuery();
    const schema = useEmailFormSchema();
    const form = useForm<EmailFormSchema>({
        mode: 'onBlur',
        defaultValues: {
            email: userQuery.data?.email || '',
            password: '',
        },
        resolver: zodResolver(schema),
    });
    const toast = useModalToastQueue();
    const { isValid, isDirty, errors } = form.formState;
    const updateEmail = updateEmailMutation();

    const onSubmit = (formData: EmailFormSchema) => {
        updateEmail.mutate(formData, {
            onSuccess: () => {
                toast.success(
                    <FormattedMessage defaultMessage="Votre e-mail a bien été modifié 👌" />,
                );
                onAfterSubmit();
            },
            onError: (error) => {
                const err = error as AxiosError;
                const isEmailTaken =
                    err.response?.data?.errors?.email?.[0] === 'The email has already been taken.';

                if (err.response?.status === 422 && isEmailTaken) {
                    toast.alert(
                        <FormattedMessage defaultMessage="Oups, cet e-mail est déjà utilisé ! 🤷‍♀️" />,
                    );
                    return;
                }
                toast.alert(
                    <FormattedMessage defaultMessage="Oups, il semblerait qu'une erreur soit survenue lors de la modification de votre e-mail" />,
                );
            },
        });
    };

    return (
        <Form onSubmit={form.handleSubmit(onSubmit)}>
            <InputTextController
                control={form.control}
                name="email"
                label={<FormattedMessage defaultMessage="Adresse e-mail" />}
                placeholder={intl.formatMessage({ defaultMessage: 'thomas.pesquet@esa.com' })}
                errorMessage={errors.email?.message}
                onBlur={() => form.clearErrors('email')}
                isRequired
                autoFocus
            />
            <InputTextController
                control={form.control}
                name="password"
                type="password"
                label={<FormattedMessage defaultMessage="Mot de passe" />}
                placeholder={intl.formatMessage({ defaultMessage: '8 caractères minimum' })}
                errorMessage={errors.password?.message}
                onBlur={() => form.clearErrors('password')}
                isRequired
            />
            <ButtonsWrapper>
                <Button
                    type="submit"
                    isLoading={updateEmail.isLoading}
                    isDisabled={!isValid || !isDirty}
                >
                    <FormattedMessage defaultMessage="Enregistrer" />
                </Button>
                <Button variant="tertiary" onPress={onAfterSubmit}>
                    <FormattedMessage defaultMessage="Annuler" />
                </Button>
            </ButtonsWrapper>
        </Form>
    );
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    height: 100%;
`;
const ButtonsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    margin-top: auto;

    & > :first-child,
    > :last-child {
        align-self: stretch;
    }

    ${(p) => p.theme.mediaQueries.tabletAndUp} {
        flex-direction: row;
        justify-content: flex-end;
        column-gap: 0.5rem;
        margin-top: 0.5rem;

        & > :first-child,
        > :last-child {
            align-self: initial;
        }

        & > :first-child {
            order: 1;
        }
    }
`;

export default EmailForm;
