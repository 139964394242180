import Stack from '@/components/ui/layout/Stack';
import styled from 'styled-components';

const TranscriptionQuotaSkeleton = () => {
    return (
        <SkeletonWrapper>
            <Stack $gap="0.865rem">
                <Line $width="7.5rem" $height="0.5rem" $color="--primary100" />
                <Line $width="2.5rem" $height="0.75rem" $color="--primary200" />
            </Stack>
            <Circle />
        </SkeletonWrapper>
    );
};

const SkeletonWrapper = styled.div`
    background-color: var(--primary50);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border-radius: var(--r-m);
    height: 5rem;
`;
const Line = styled.div`
    width: ${({ $width }) => $width};
    height: ${({ $height }) => $height};
    background-color: var(--primary);
    border-radius: var(--r-full);
    background-color: ${({ $color }) => `var(${$color})`};
`;
const Circle = styled.div`
    width: 3rem;
    height: 3rem;
    background: none;
    border: 0.75rem solid var(--primary100);
    border-radius: var(--r-full);
`;

export default TranscriptionQuotaSkeleton;
