import { FormattedMessage } from 'react-intl';
import DeprecatedText from '../../ui/atoms/DeprecatedText';
import { useAmplitudeLogEvent } from '@hooks/useAmplitude';
import ProviderConnector from '../organisms/ProviderConnector';
import { USER_OPTIONS } from '@/utils/userOptions';
import HideFor from '../atoms/HideFor';
import styled from 'styled-components';

function AutoSharing() {
    useAmplitudeLogEvent('Communication Settings Accessed');
    useAmplitudeLogEvent('show autosharing');

    return (
        <AutoSharingWrapper>
            <AutoSharingInnerWrapper>
                <DeprecatedText color="--neutral500" mb={16} maxW={500}>
                    <FormattedMessage defaultMessage="Partagez automatiquement vos épisodes sur les réseaux sociaux." />
                </DeprecatedText>

                {/* HideFor is used here to change providers order based on user options */}
                {/* Instagram (if authorized) / Facebook / LinkedIn (if authorized) / X / Instagram (if NOT authorized) / LinkedIn (if NOT authorized) */}
                <HideFor userOptions={USER_OPTIONS.AUTOSHARING_INSTAGRAM}>
                    <ProviderConnector provider="instagram" />
                </HideFor>
                <ProviderConnector provider="facebook" />
                <HideFor userOptions={USER_OPTIONS.AUTOSHARING_LINKEDIN}>
                    <ProviderConnector provider="linkedin" />
                </HideFor>
                <ProviderConnector provider="twitter" />
                <HideFor
                    userOptions={USER_OPTIONS.AUTOSHARING_INSTAGRAM}
                    lockedMessage={<ProviderConnector provider="instagram" />}
                />
                <HideFor
                    userOptions={USER_OPTIONS.AUTOSHARING_LINKEDIN}
                    lockedMessage={<ProviderConnector provider="linkedin" />}
                />
            </AutoSharingInnerWrapper>
        </AutoSharingWrapper>
    );
}

const AutoSharingWrapper = styled.div`
    width: 100%;
    max-width: var(--container-width);
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding);

    ${({ theme }) => theme.mediaQueries.mobile} {
        padding: var(--container-padding-mobile);
    }
`;
const AutoSharingInnerWrapper = styled.div`
    max-width: 680px;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;

export default AutoSharing;
