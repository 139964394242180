import Text from '@/components/ui/atoms/Text';
import Stack from '@/components/ui/layout/Stack';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, TooltipTrigger } from 'react-aria-components';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import SparklesIcon from '@public/icons/sparkles-nebula.svg';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';

interface EpisodeAISummaryLoadingProps {
    dataTestId: string;
}

const EpisodeAISummaryLoading = ({ dataTestId }: EpisodeAISummaryLoadingProps) => {
    return (
        <Wrapper data-testid={dataTestId}>
            <Header>
                <TitleWrapper>
                    <Sparkles />
                    <Text variant="bodyL" fontWeight="--fw-semibold">
                        Ausha Intelligence
                    </Text>
                    <TooltipTrigger delay={0} closeDelay={0}>
                        <TooltipTriggerWrapper>
                            <TooltipIcon icon={icon({ name: 'circle-info', style: 'solid' })} />
                        </TooltipTriggerWrapper>
                        <Tooltip placement="top">
                            <FormattedMessage defaultMessage="Ausha Intelligence utilise votre transcription pour générer automatiquement votre titre, description, tags, chapitres et newsletter. Toute modification apportée à votre transcription sera mise à jour jusqu'à trois fois." />
                        </Tooltip>
                    </TooltipTrigger>
                </TitleWrapper>
                <Text color="--neutral500">
                    <FormattedMessage defaultMessage="Laissez la magie de AI suggérer le contenu de votre podcast." />
                </Text>
            </Header>
            <LoaderContainer>
                <SpinnerIcon icon={icon({ name: 'spinner-third', style: 'solid' })} spin />
                <Stack $gap="0.25rem" $align="center">
                    <Text fontWeight="--fw-semibold" textAlign="center">
                        <FormattedMessage defaultMessage="AI est entrain de générer" />
                        ...
                    </Text>
                    <Text color="--neutral500" textAlign="center">
                        <FormattedMessage defaultMessage="…nos meilleures suggestions pour votre épisode sont en cours, cela ne devrait plus être très long. ⚡" />
                    </Text>
                </Stack>
            </LoaderContainer>
        </Wrapper>
    );
};

const Wrapper = styled(Dialog)`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    padding-block: 1.5rem;
    padding-inline: 1rem;
    background: var(--gradient-aurora);
    border-radius: var(--r-l);
`;
const Header = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
`;
const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
`;
const Sparkles = styled(SparklesIcon)`
    width: 1rem;
    height: 1rem;
`;
const TooltipIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: var(--neutral500);
`;
const LoaderContainer = styled.div`
    display: flex;
    padding: 1.5rem;
    flex-direction: column;
    align-items: center;
    row-gap: 0.75rem;
    align-self: stretch;
    border-radius: var(--r-m);
    background: var(--white300);
`;
const SpinnerIcon = styled(FontAwesomeIcon)`
    width: 2rem;
    height: 2rem;
    color: var(--primary-magic);
`;

export default EpisodeAISummaryLoading;
