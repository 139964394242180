import styled from 'styled-components';
import Button from '@/components/Button';
import { FormattedMessage } from 'react-intl';
import Text from '@ui/atoms/Text';
import ShiningDiamond from '@ui/atoms/ShiningDiamond';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import ZebraIllustration from '@ui/atoms/ZebraIllustration';
import Modal from '@ui/atoms/Modal';
import RouterLink from '@/components/Link/RouterLink';

interface FreemiumModalProps {
    isOpen: boolean;
    onOpenChange: (isOpen: boolean) => void;
}

const FreemiumModal = ({ isOpen, onOpenChange }: FreemiumModalProps) => {
    return (
        <ModalStyled isOpen={isOpen} onOpenChange={onOpenChange} size="small">
            <Wrapper>
                <CloseWrapper>
                    <CloseButton
                        variant="ghost"
                        color="--neutral500"
                        onPress={() => onOpenChange(false)}
                    >
                        <CloseButtonIcon icon={icon({ name: 'xmark', style: 'solid' })} />
                    </CloseButton>
                </CloseWrapper>
                <ZebraIllustration>
                    <ShiningDiamond />
                </ZebraIllustration>
                <ContentWrapper>
                    <Text variant="headingM" fontWeight="--fw-bold">
                        <FormattedMessage defaultMessage="Vous avez atteint le maximum d'émissions pour votre offre" />
                    </Text>
                    <Text color="--neutral500">
                        <FormattedMessage defaultMessage="Si vous voulez créer plus d'émission, veuillez mettre à jour votre offre." />
                    </Text>
                </ContentWrapper>
                <ActionWrapper>
                    <RouterLink
                        variant="button-primary"
                        startIcon={
                            <FontAwesomeIcon
                                icon={icon({ name: 'rocket-launch', style: 'solid' })}
                            />
                        }
                        to="/app/subscription/offers"
                    >
                        <FormattedMessage defaultMessage="Mettre à jour mon offre" />
                    </RouterLink>
                    <Button onPress={() => onOpenChange(false)} variant="link-secondary">
                        <FormattedMessage defaultMessage="Non, merci" />
                    </Button>
                </ActionWrapper>
            </Wrapper>
        </ModalStyled>
    );
};

const ModalStyled = styled(Modal)`
    min-width: 370px;
`;
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
`;
const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;

    & > * {
        text-align: center;
    }
`;
const ActionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    align-items: stretch;

    & > :last-child {
        align-self: center;
    }
`;
const CloseWrapper = styled.div`
    flex-shrink: 0;
    align-self: flex-end;
`;
const CloseButton = styled(Button)`
    background-color: transparent;
    padding: 0.25rem;
    height: fit-content;
`;
const CloseButtonIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
    height: 1rem;
    width: 1rem;
`;
export default FreemiumModal;
