import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Text from '@/components/ui/atoms/Text';
import { TooltipTrigger } from 'react-aria-components';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';

interface EpisodeEditTooltipProps {
    children: React.ReactNode;
}

const EpisodeEditTooltip = ({ children }: EpisodeEditTooltipProps) => {
    return (
        <TooltipTrigger delay={0} closeDelay={0}>
            <TooltipTriggerWrapper>
                <TooltipInfoIcon icon={icon({ name: 'circle-info', style: 'solid' })} />
            </TooltipTriggerWrapper>
            <Tooltip placement="bottom">
                <Text fontWeight="--fw-semibold">{children}</Text>
            </Tooltip>
        </TooltipTrigger>
    );
};

const TooltipInfoIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
    width: 0.75rem;
    height: 0.75rem;
`;

EpisodeEditTooltip.propTypes = {
    children: PropTypes.node.isRequired,
};

export default EpisodeEditTooltip;
