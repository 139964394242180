import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Stack from '@ui/layout/Stack';
import Cluster from '@ui/layout/Cluster';
import Spinner from '@ui/atoms/Spinner';
import Text from '@ui/atoms/Text';
import { ClipFormat } from '@/queries/clipFormat/types';
import { Episode } from '@/queries/episode/useEpisodeQuery.hook';

interface ClipFormatLoadingProps {
    clipFormat: ClipFormat;
    episode: Episode;
}

const ClipFormatLoading = ({ clipFormat, episode }: ClipFormatLoadingProps) => (
    <Stack $gap="0.75rem">
        <LoaderWrapper>
            <Stack $gap="0.5rem" $align="center">
                <Spinner color="--neutral500" size={24} />
                <Text color="--neutral500" textAlign="center">
                    <FormattedMessage defaultMessage="En cours de génération…" />
                </Text>
            </Stack>
        </LoaderWrapper>
        <Stack $gap="0.25rem">
            <Text fontWeight="--fw-semibold">
                <Text numberOfLines={1} fontWeight="--fw-semibold">
                    {clipFormat.clip.adminTitle || clipFormat.clip.title}
                </Text>
            </Text>
            {episode && (
                <Cluster $gap="0.5rem" $align="center" $wrap="nowrap">
                    {episode.imageUrl && <EpisodeCover src={episode.imageUrl} />}
                    <Text color="--neutral500" numberOfLines={1}>
                        {episode.name}
                    </Text>
                </Cluster>
            )}
        </Stack>
    </Stack>
);

const EpisodeCover = styled.img.attrs({
    loading: 'lazy',
})`
    display: block;
    max-height: 1.5rem;
    max-width: 1.5rem;
    border-radius: var(--r-xs);
`;
const LoaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--r-xs);
    padding: 1rem;
    background-color: var(--neutral50);
    min-height: 152px;
`;

export default ClipFormatLoading;
