import styled, { css } from 'styled-components';

const ListSkeleton = () => {
    return (
        <ListSkeletonWrapper>
            {Array.from({ length: 10 }).map((_, index) => (
                <ListSkeletonItem key={index}>
                    <Left>
                        <Platform />
                        <Image />
                        <Content>
                            <TopRow />
                            <MidRow />
                            <BotRow />
                        </Content>
                    </Left>
                    <Right>
                        <Insight />
                        <Status />
                        <Action />
                    </Right>
                </ListSkeletonItem>
            ))}
        </ListSkeletonWrapper>
    );
};

const ListSkeletonWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;
const ListSkeletonItem = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem;
    border-radius: var(--r-m);
    background-color: var(--white);
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.05);
`;
const Left = styled.div`
    display: flex;
    align-items: center;
    gap: 0.75rem;
`;
const Platform = styled.div`
    width: 2rem;
    height: 5rem;
    background-color: var(--neutral100);
    border-radius: var(--r-s);
`;
const Image = styled.div`
    width: 5rem;
    height: 5rem;
    background-color: var(--neutral50);
    border-radius: var(--r-s);
`;
const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
`;
const RowBase = css`
    height: 0.75rem;
    background-color: var(--neutral50);
    border-radius: var(--r-full);
`;
const TopRow = styled.div`
    ${RowBase};
    width: 5rem;
`;
const MidRow = styled.div`
    ${RowBase};
    background-color: var(--neutral100);
    width: 10rem;
`;
const BotRow = styled.div`
    ${RowBase};
    width: 2.5rem;
`;
const Right = styled.div`
    display: flex;
    align-items: center;
    gap: 3.5rem;
    margin-right: 0.75rem;
`;
const Insight = styled.div`
    ${RowBase};
    width: 2.5rem;
`;
const Status = styled.div`
    width: 5rem;
    height: 2rem;
    background-color: var(--neutral100);
    border-radius: var(--r-s);
`;
const Action = styled.div`
    width: 1rem;
    height: 1rem;
    background-color: var(--neutral50);
    border-radius: var(--r-full);
`;

export default ListSkeleton;
