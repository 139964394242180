import useEpisodeNewsletterTemplateQuery from '@queries/newsletter/useEpisodeNewsletterTemplateQuery.hook';
import EpisodeEditFormNewsletterForm from '@app/organisms/EpisodeEditForm/EpisodeEditFormNewsletter/EpisodeEditFormNewsletterForm';
import { useEpisodeBeingEdited } from '@app/organisms/EpisodeEditForm/EpisodeEditFormContext';

const EpisodeEditFormNewsletterEditor = () => {
    const episode = useEpisodeBeingEdited();
    const episodeNewsletterTemplateQuery = useEpisodeNewsletterTemplateQuery(episode?.id);

    if (episodeNewsletterTemplateQuery.isLoading) {
        // TODO Handle loading state
        return <span>LOADING</span>;
    }

    if (episodeNewsletterTemplateQuery.error) {
        // TODO Handle error state
        return <span>ERROR</span>;
    }

    const { data: episodeNewsletterTemplate } = episodeNewsletterTemplateQuery;

    return <EpisodeEditFormNewsletterForm template={episodeNewsletterTemplate} />;
};

export default EpisodeEditFormNewsletterEditor;
