import { QueryFunctionContext, useQuery, UseQueryResult } from '@tanstack/react-query';
import { fetchTranscriptQuotaPrice } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import { QUOTA_TYPES } from '@/shared/config/constants';
import quotaKeys from './quotaKeys';

interface TranscriptQuotaPrice {
    cost: string;
    total: string;
}

const transform = (data: TranscriptQuotaPrice) => {
    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const queryFn = async ({
    queryKey: [{ quantity }],
}: QueryFunctionContext<ReturnType<typeof quotaKeys['priceByType']>>) => {
    const quantitySeconds = quantity * 60;
    const { data } = await fetchTranscriptQuotaPrice(quantitySeconds);

    return transform(data);
};

const useTranscriptQuotaPriceQuery = (quantity: number): UseQueryResult<TranscriptQuotaPrice> => {
    return useQuery({
        queryKey: quotaKeys.priceByType(
            QUOTA_TYPES.TRANSCRIPT as keyof typeof QUOTA_TYPES,
            quantity,
        ),
        queryFn,
        enabled: quantity > 0,
    });
};

export default useTranscriptQuotaPriceQuery;
