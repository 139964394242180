import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import usePsoCompetitorsQuery, { CompetitorsRows } from '@queries/pso/usePsoCompetitorsQuery.hook';
import type { Platform, Mode } from '@/api/pso/types';
import { getCountryISO3 } from '@/shared/services/countryService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Datatable from './Datatable';
import styled from 'styled-components';

interface ResultsProps {
    searchParams: {
        platform: Platform;
        store: string;
        mode: Mode;
    };
}

const Results = ({ searchParams }: ResultsProps) => {
    const { platform, store, mode } = searchParams;
    const { showId } = useParams<{
        showId: string;
    }>();

    const enabled = !!showId && !!platform && !!store && !!mode;
    const {
        data: competitorsResults,
        isLoading,
        isError,
    } = usePsoCompetitorsQuery(
        {
            showId,
            platform,
            store: getCountryISO3(store),
            mode,
        },
        { enabled },
    );

    if (enabled && isLoading) {
        return (
            <ResultsWrapper>
                <LoaderWrapper>
                    <FontAwesomeIcon icon={icon({ name: 'spinner-third', style: 'solid' })} spin />
                </LoaderWrapper>
            </ResultsWrapper>
        );
    }

    if (isError) {
        return (
            <ResultsWrapper>
                <DefaultStateTitleWrapper>
                    <DefaultStateTitle>
                        <FormattedMessage defaultMessage="Un problème est survenu" />
                    </DefaultStateTitle>
                    <DefaultStateTitleSubtitle>
                        <FormattedMessage defaultMessage="Nous ne sommes pas parvenus à récupérer les informations, veuillez réessayer plus tard." />
                    </DefaultStateTitleSubtitle>
                </DefaultStateTitleWrapper>
            </ResultsWrapper>
        );
    }

    if (competitorsResults && competitorsResults.length === 0) {
        return (
            <ResultsWrapper>
                <DefaultStateWrapper>
                    <SearchIcon icon={icon({ name: 'magnifying-glass', style: 'solid' })} />
                    <DefaultStateTitleWrapper>
                        <DefaultStateTitle>
                            <FormattedMessage defaultMessage="Aucun résultat" />
                        </DefaultStateTitle>
                        <DefaultStateTitleSubtitle>
                            <FormattedMessage defaultMessage="Veuillez réessayer avec des critères de recherche différents." />
                        </DefaultStateTitleSubtitle>
                    </DefaultStateTitleWrapper>
                </DefaultStateWrapper>
            </ResultsWrapper>
        );
    }

    if (competitorsResults) {
        return (
            <ResultsWrapper>
                <Datatable rows={competitorsResults as CompetitorsRows} />
            </ResultsWrapper>
        );
    }

    return null;
};

const ResultsWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding);
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;
const LoaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-block: 2rem;
    color: var(--primary);
`;
const DefaultStateWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    color: var(--neutral500);
    width: 100%;
    max-width: 24rem;
    margin-inline: auto;
    padding-block: 2rem;
`;
const SearchIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
    font-size: 1.5rem;
`;
const DefaultStateTitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.25rem;
`;
const DefaultStateTitle = styled.h2`
    font-weight: var(--fw-semibold);
    font-size: var(--fs-body-s);
`;
const DefaultStateTitleSubtitle = styled.h3`
    font-weight: var(--fw-normal);
    font-size: var(--fs-body-s);
    text-align: center;
`;

export default Results;
