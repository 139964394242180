import { FieldValues } from 'react-hook-form';
import { NonHostingEpisodeFormSchema } from '../useNonHostingEpisodeFormSchema.hook';

/**
 * In a React Hook Form, use formState.dirtyFields and all inputs data to compute what are the chapters
 * that need image deletion, in other words find chapter IDs with imageUrl at true in dirtyFields
 */
export const getChaptersIdsRequiringImageDeletion = (
    dirtyFields: FieldValues,
    chapters: NonHostingEpisodeFormSchema['chapters'],
) => {
    // Early return if chapters are not dirty
    if (!dirtyFields.chapters || !Array.isArray(dirtyFields.chapters)) {
        return [];
    }

    const dirtyChaptersEntries = Array.from(dirtyFields.chapters.entries());

    // Keep only dirty chapters that have dirty imageUrl
    const dirtyImageChaptersEntries = dirtyChaptersEntries.filter(([_, dirtyChapter]) => {
        // return chapter && chapter.imageUrl;
        return dirtyChapter?.imageUrl;
    });

    // Using kept dirty chapters, find matching chapters IDs
    const chaptersIdsRequiringImageDeletion = dirtyImageChaptersEntries.map(([index, _]) => {
        const chapter = chapters[index];
        return chapter?.imageUrl === null ? chapter.id : null;
    });

    // Filter one last time to keep only IDs, not null value
    return chaptersIdsRequiringImageDeletion.filter((id): id is number => !!id);
};
