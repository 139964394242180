import { useMemo } from 'react';
import Text from '@tiptap/extension-text';
import History from '@tiptap/extension-history';
import CustomDocument from '@/components/unorganized/TranscriptionEditor/CustomExtensions/CustomDocument';
import CustomParagraph from '@/components/unorganized/TranscriptionEditor/CustomExtensions/CustomParagraph';
import CustomPhrase from '@/components/unorganized/TranscriptionEditor/CustomExtensions/CustomPhrase';
import CustomWord from '@/components/unorganized/TranscriptionEditor/CustomExtensions/CustomWord';
import { TextSelection } from '@tiptap/pm/state';
import ConfidenceUpdateExtension from '@/components/unorganized/TranscriptionEditor/CustomExtensions/ConfidenceUpdateExtension';
import KaraokeExtension from '@/components/unorganized/TranscriptionEditor/CustomExtensions/KaraokeExtension';
import { convertTranscriptToTiptapContent } from '@/components/unorganized/TranscriptionEditor/transcriptConverter';
import { useEditor } from '@tiptap/react';

const useTranscriptionEditor = ({ transcription }) => {
    const selectionHasWordsFromSameParagraph = (editor, selection) => {
        const { from, to } = selection;
        return editor.resolve(from).node(1) === editor.resolve(to).node(1);
    };

    const content = useMemo(() => {
        return convertTranscriptToTiptapContent(transcription);
    }, [transcription]);

    return useEditor(
        {
            extensions: [
                CustomDocument,
                CustomParagraph,
                CustomPhrase,
                CustomWord,
                Text,
                History,
                ConfidenceUpdateExtension,
                KaraokeExtension,
            ],
            content,
            parseOptions: {
                preserveWhitespace: 'full', // \n in text value should be preserved as linebreaks
            },
            editorProps: {
                attributes: {
                    spellcheck: 'false', // With spellcheck on, the browser explodes if the transcription is too large. Keep it off ;)
                },
                handleDOMEvents: {
                    keydown: (view, event) => {
                        const { selection } = view.state;
                        if (
                            selection &&
                            !selection.empty &&
                            !selectionHasWordsFromSameParagraph(view.state.doc, selection)
                        ) {
                            event.preventDefault();
                            return;
                        }
                        if (
                            (event.ctrlKey || event.metaKey) &&
                            ['a'].includes(event.key.toLowerCase())
                        ) {
                            event.preventDefault();
                            return;
                        }
                        if (event.key === 'Enter') {
                            event.preventDefault();
                            const { from, to } = selection;
                            const transaction = view.state.tr.insertText('\n', from, to);
                            view.dispatch(transaction);
                            return;
                        }
                        return false;
                    },
                    paste: (view, event) => {
                        event.preventDefault();
                        const text = event.clipboardData.getData('text/plain');

                        if (text) {
                            view.dispatch(view.state.tr.insertText(text));
                        }

                        return false;
                    },
                },
            },
            onSelectionUpdate: ({ editor }) => {
                const { selection } = editor.state;
                if (!selection.empty) {
                    const { from } = selection;
                    if (!selectionHasWordsFromSameParagraph(editor.state.doc, selection)) {
                        const tr = editor.state.tr.setSelection(
                            TextSelection.create(
                                editor.state.doc,
                                from,
                                editor.state.doc.resolve(from).end(1),
                            ),
                        );
                        editor.view.dispatch(tr);
                    }
                }
            },
        },
        [],
    );
};

export default useTranscriptionEditor;
