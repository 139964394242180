import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    SortDirection,
    useReactTable,
} from '@tanstack/react-table';
import RankCell from './RankCell';
import KeywordCell from './KeywordCell';
import CompetitorsCell from './CompetitorsCell';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import type {
    Competitor,
    CompetitorsRows,
    CompetitorsRow,
} from '@queries/pso/usePsoCompetitorsQuery.hook';
import styled, { css } from 'styled-components';
import { TooltipTrigger } from 'react-aria-components';
import Tooltip, { TooltipTriggerWrapper } from '@ui/Tooltip';

interface PSOCompetitorsResult {
    keyword: string;
    isLoading: boolean;
    ranking: number;
    competitors: Competitor[];
}

interface DatatableProps {
    rows: CompetitorsRows;
}

const Datatable = ({ rows }: DatatableProps) => {
    const [sorting, setSorting] = useState([{ id: 'keyword', desc: false }]);

    const headerTooltipContentLookup = {
        keyword: <FormattedMessage defaultMessage="Liste de vos mots-clés." />,
        ranking: (
            <FormattedMessage defaultMessage="Position de votre podcast sur ces mots-clés ce jour." />
        ),
        competitors: (
            <FormattedMessage defaultMessage="Découvrez les 10 premiers podcasts positionnés sur vos mots-clés." />
        ),
    };

    const columnHelper = createColumnHelper<PSOCompetitorsResult>();
    const columns = [
        columnHelper.accessor('keyword', {
            cell: (info) => {
                return (
                    <KeywordCell
                        keyword={info.getValue()}
                        isLoading={info.row.original.isLoading}
                    />
                );
            },
            header: () => <FormattedMessage defaultMessage="Mot-clé" />,
            size: 750,
        }),
        columnHelper.accessor('ranking', {
            cell: (info) => <RankCell rank={info.getValue()} />,
            header: () => <FormattedMessage defaultMessage="Classement" />,
            size: 100,
        }),
        columnHelper.accessor('competitors', {
            cell: (info) => {
                return (
                    <CompetitorsCell
                        keyword={info.row.original.keyword}
                        competitors={info.getValue()}
                    />
                );
            },
            header: () => <FormattedMessage defaultMessage="Compétiteurs" />,
            enableSorting: false,
            size: 325,
        }),
    ];

    const table = useReactTable({
        data: rows.map((row: CompetitorsRow) => ({
            keyword: row.keyword,
            isLoading: row.isLoading,
            ranking: row.ranking,
            competitors: row.competitors,
        })),
        columns,
        state: {
            sorting,
        },
        enableSortingRemoval: false,
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        getCoreRowModel: getCoreRowModel(),
    });

    const sortIconRender = (isSorted: false | SortDirection) => {
        return isSorted === 'asc' ? (
            <SortIcon icon={icon({ name: 'arrow-up', style: 'solid' })} size="lg" />
        ) : (
            <SortIcon icon={icon({ name: 'arrow-down', style: 'solid' })} size="lg" />
        );
    };

    return (
        <Table>
            <Thead>
                {table.getHeaderGroups().map((headerGroup) => (
                    <Tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) =>
                            header.isPlaceholder ? null : (
                                <Th
                                    key={header.id}
                                    sortable={header.column.getCanSort()}
                                    isSorted={header.column.getIsSorted() !== false}
                                    onClick={header.column.getToggleSortingHandler()}
                                >
                                    <TooltipTrigger delay={0} closeDelay={0}>
                                        <TableHeaderTooltipTriggerWrapper>
                                            <ThContent
                                                sortable={header.column.getCanSort()}
                                                onClick={header.column.getToggleSortingHandler()}
                                            >
                                                {flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext(),
                                                )}

                                                {sortIconRender(header.column.getIsSorted())}
                                            </ThContent>
                                        </TableHeaderTooltipTriggerWrapper>
                                        <Tooltip placement="top">
                                            {headerTooltipContentLookup[
                                                header.id as keyof typeof headerTooltipContentLookup
                                            ] || null}
                                        </Tooltip>
                                    </TooltipTrigger>
                                </Th>
                            ),
                        )}
                    </Tr>
                ))}
            </Thead>
            <Tbody>
                {table.getRowModel().rows.map((row) => (
                    <Tr key={row.id}>
                        {row.getVisibleCells().map((cell) => (
                            <Td
                                key={cell.id}
                                style={{
                                    width: `${cell.column.getSize()}px`,
                                    maxWidth: '100%',
                                }}
                            >
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </Td>
                        ))}
                    </Tr>
                ))}
            </Tbody>
        </Table>
    );
};

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
`;
const Thead = styled.thead``;
const Td = styled.td`
    &:not(:last-of-type) {
        padding: 1rem;
    }
`;
const Tr = styled.tr`
    width: 100%;
    & > ${Td}:not(:last-of-type) {
        border-right: 1px solid var(--neutral100);
    }
`;
const Th = styled.th<{ sortable: boolean; isSorted: boolean }>`
    background-color: var(--neutral50);
    padding: 1rem;
    text-align: left;

    ${(props) =>
        props.sortable &&
        css`
            cursor: pointer;
        `};

    svg {
        visibility: ${(props) => (props.isSorted ? 'visible' : 'hidden')};
    }

    &:hover svg {
        visibility: ${(props) => (props.sortable ? 'visible' : 'hidden')};
    }

    @media (min-width: 768px) {
        white-space: nowrap;
    }

    &:first-of-type {
        border-top-left-radius: var(--r-m);
        border-bottom-left-radius: var(--r-m);
    }
    &:last-of-type {
        border-top-right-radius: var(--r-m);
        border-bottom-right-radius: var(--r-m);
    }
`;
const ThContent = styled.div<{ sortable: boolean }>`
    display: inline-flex;
    align-items: center;
    column-gap: 0.5rem;
    transition-duration: 0.2s;
    width: 100%;
    align-self: stretch;
    font-weight: var(--fw-semibold);

    ${(props) =>
        props.sortable &&
        css`
            cursor: pointer;
        `}
`;
const TableHeaderTooltipTriggerWrapper = styled(TooltipTriggerWrapper)`
    color: var(--neutral500);
`;
const Tbody = styled.tbody`
    & > ${Tr}:not(:last-of-type) {
        border-bottom: 1px solid var(--neutral100);
    }
`;
const SortIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
    font-size: 0.75rem;
`;

export default Datatable;
