import { useQuery } from '@tanstack/react-query';
import { fetchGeoStats } from '@/api';
import dayjs from 'dayjs';

export const useRegionGeoStats = ({
    showId: rawShowId,
    start,
    end,
    episodes,
    campaign,
    country,
    currentLayerLevel,
}) => {
    const showId = Number(rawShowId);

    return useQuery({
        queryKey: ['stats', 'geostats', 'regions', showId, start, end, episodes, campaign, country],
        queryFn: async () => {
            const data = await fetchGeoStats(showId, {
                /**
                 * Test values
                 * start: dayjs('12-25-2020', 'MM-DD-YYYY').format('YYYY-MM-DD'),
                 * end: dayjs('03-29-2023', 'MM-DD-YYYY').format('YYYY-MM-DD'),
                 */
                start: dayjs(start).format('YYYY-MM-DD'),
                end: dayjs(end).format('YYYY-MM-DD'),
                podcasts: episodes,
                campaign,
                group_by: 'region',
                country,
            });

            if (!data?.data) return [];

            const totalDownloads = data.data.reduce(
                (acc, region) => acc + parseInt(region.downloads, 10),
                0,
            );

            return data.data.map((region) => ({
                ...region,
                parentCountry: data.parentCountry,
                weight: region.weight || 0,
                percentage: ((parseInt(region.downloads, 10) / totalDownloads) * 100).toFixed(2),
            }));
        },
        enabled: !!country && currentLayerLevel !== 'countries',
    });
};
