import { useState } from 'react';
import ContentLoader from 'react-content-loader';
import { FormattedMessage } from 'react-intl';
import ApplePodcastsReviewsNotificationModal from './ApplePodcastsReviewsNotificationModal';
import bellIcon from '@public/icons/bell.svg';
import DeprecatedButton from '@ui/atoms/DeprecatedButton';
import createFallback from '@/components/unorganized/createFallback';
// import FetcherApplePodcastsReviews from './FetcherApplePodcastsReviews';
import DeprecatedIcon from '@ui/atoms/DeprecatedIcon';
import ServerPagination from '@/components/ui/ServerPagination';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import ReviewsList from './ReviewsList';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import { useOverlayTriggerState } from 'react-stately';
import { useParams } from 'react-router';
import useAppleReviewsQuery from '@/queries/stats/useAppleReviewsQuery.hook';

const ReviewsCountFallback = createFallback((props) => (
    <ContentLoader width={100} height={20} style={{ width: 100, height: 20 }} {...props}>
        <rect x="0" y="0" rx="5" ry="5" width="100" height="14" />
    </ContentLoader>
));

function ApplePodcastsReviews() {
    const { showId } = useParams();
    let ApplePodcastsReviewsNotificationModalState = useOverlayTriggerState({});

    const [page, setPage] = useState(1);

    const { data: reviews, isLoading } = useAppleReviewsQuery({
        showId,
        page,
    });

    return (
        <>
            {ApplePodcastsReviewsNotificationModalState.isOpen && (
                <ApplePodcastsReviewsNotificationModal
                    isOpen={ApplePodcastsReviewsNotificationModalState.isOpen}
                    onClose={ApplePodcastsReviewsNotificationModalState.close}
                />
            )}

            <DeprecatedPaper flex align="center" wrap="wrap" mt={15} mb={20}>
                <DeprecatedPaper flex column auto w="auto" mt={20} mr={20}>
                    <DeprecatedText mb={5} size="veryBig" weight="bold">
                        <FormattedMessage defaultMessage="Avis des utilisateurs" />
                    </DeprecatedText>
                    {isLoading ? (
                        <ReviewsCountFallback />
                    ) : (
                        <DeprecatedText>
                            <FormattedMessage
                                defaultMessage={`{total, plural, =0 {Aucun avis} one {# avis} other {# avis}}`}
                                values={{ total: reviews?.meta?.pagination?.total || 0 }}
                            />
                        </DeprecatedText>
                    )}
                </DeprecatedPaper>
                <DeprecatedButton
                    onClick={ApplePodcastsReviewsNotificationModalState.open}
                    mt={20}
                    background="secondary"
                >
                    <DeprecatedIcon
                        as={bellIcon}
                        height={13}
                        width={15}
                        style={{ flexShrink: 0 }}
                    />
                    <FormattedMessage defaultMessage="Paramètres des alertes" />
                </DeprecatedButton>
            </DeprecatedPaper>

            <ReviewsList reviews={reviews?.data} isLoading={isLoading} />

            {!isLoading && reviews?.meta?.pagination?.totalPages > 1 && (
                <ServerPagination
                    pagination={{
                        count: reviews?.meta?.pagination?.total,
                        total: reviews?.meta?.pagination?.total,
                        currentPage: reviews?.meta?.pagination?.currentPage,
                        perPage: 10,
                        totalPages: reviews?.meta?.pagination?.totalPages,
                    }}
                    options={{
                        showPageSelector: false,
                    }}
                    onGoToPage={(page) => setPage(page)}
                    onGoToFirstPage={() => setPage(1)}
                    onGoToLastPage={() => setPage(reviews?.meta?.pagination?.totalPages)}
                    onGoToPreviousPage={() => setPage(reviews?.meta?.pagination?.currentPage - 1)}
                    onGoToNextPage={() => setPage(reviews?.meta?.pagination?.currentPage + 1)}
                />
            )}
        </>
    );
}

export default ApplePodcastsReviews;
