import { useMutation } from '@tanstack/react-query';
import { updateShowTemplate } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import { useQueryClient } from '@tanstack/react-query';
import { decamelizeKeys } from 'humps';
import showTemplateKeys from './showTemplateKeys';

const transform = (data) => {
    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const mutationFn = async ({ showId, provider, payload }) => {
    const { data } = await updateShowTemplate(showId, provider, decamelizeKeys(payload));
    return transform(data);
};

const useUpdateShowTemplateMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({
                queryKey: showTemplateKeys.detailByProvider(variables.showId, variables.provider),
            });
        },
    });
};

export default useUpdateShowTemplateMutation;
