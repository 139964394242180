import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteAccount } from '@/api';
import useAuthStore from '@/utils/hooks/useAuthStore';

const useDeleteAccount = () => {
    const { logout } = useAuthStore();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (data) => {
            return deleteAccount(data);
        },
        onSuccess: () => {
            logout(queryClient);
        },
    });
};

export default useDeleteAccount;
