import { FormattedMessage } from 'react-intl';
import Text from '@ui/atoms/Text';
import Button from '@ui/atoms/Button';
import styled from 'styled-components';
import { UI_VARIANTS } from '@/shared/config/constants';
import DialogAlert from '@ui/atoms/DialogAlert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

interface EpisodeEditDeleteAudioFileModalProps {
    isOpen: boolean;
    onOpenChange: (isOpen: boolean) => void;
    isLoading?: boolean;
    handleDelete: () => void;
}

const EpisodeEditDeleteAudioFileModal = ({
    isOpen,
    onOpenChange,
    isLoading,
    handleDelete,
}: EpisodeEditDeleteAudioFileModalProps) => {
    return (
        // @ts-ignore
        <DialogAlert
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            action={
                <>
                    {/* @ts-ignore */}
                    <DeleteButton isLoading={isLoading} onPress={handleDelete}>
                        <FormattedMessage defaultMessage="Supprimer" />
                    </DeleteButton>
                    {/* @ts-ignore */}
                    <CancelButton onPress={() => onOpenChange(false)} variant="ghost">
                        <FormattedMessage defaultMessage="Annuler" />
                    </CancelButton>
                </>
            }
            variant={UI_VARIANTS.ALERT}
            icon={<TrashIcon icon={icon({ name: 'trash', style: 'solid' })} />}
            title={<FormattedMessage defaultMessage="Supprimer le fichier audio" />}
        >
            <Text variant="bodyM" color="--neutral500" textAlign="center">
                <FormattedMessage defaultMessage="Êtes-vous sûr de vouloir supprimer ce fichier audio ? Cette action est irréversible." />
            </Text>
        </DialogAlert>
    );
};

const CancelButton = styled(Button)`
    color: var(--neutral500);
    padding: 0;
`;
const DeleteButton = styled(Button)`
    color: var(--alert);
    background-color: var(--alert50);
    box-shadow: none;
`;
const TrashIcon = styled(FontAwesomeIcon)`
    color: var(--alert);
    width: 1rem;
    height: 1rem;
`;

export default EpisodeEditDeleteAudioFileModal;
