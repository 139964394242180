import { FormattedMessage } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

interface EpisodesNonHostingLayoutProps {
    children: React.ReactNode;
}

const EpisodesNonHostingLayout = ({ children }: EpisodesNonHostingLayoutProps) => {
    const match = useRouteMatch();

    const routeLookup = {
        '/app/show/:showId/workspace': {
            title: <FormattedMessage defaultMessage="Espace de travail" />,
            description: (
                <FormattedMessage defaultMessage="Créez, optimisez et préparez vos épisodes dans votre espace de travail. Utilisez Ausha Intelligence pour affiner les métadonnées de vos épisodes et améliorez le score de votre PSO Checker pour maximiser leur impact." />
            ),
        },
        '/app/show/:showId/feed': {
            title: <FormattedMessage defaultMessage="Flux" />,
            description: (
                <FormattedMessage defaultMessage="Consultez vos épisodes publiés dans votre Flux RSS. Pour optimiser un épisode déjà en ligne, copiez-le dans votre espace de travail et travaillez dessus selon vos besoins." />
            ),
        },
    };

    const title = routeLookup[match.path as keyof typeof routeLookup]?.title;
    const description = routeLookup[match.path as keyof typeof routeLookup]?.description;

    return (
        <EpisodesLayoutWrapper>
            <TitleAndDescriptionWrapper>
                {title && <Title>{title}</Title>}
                {description && <Description>{description}</Description>}
            </TitleAndDescriptionWrapper>
            <ContentWrapper>{children}</ContentWrapper>
        </EpisodesLayoutWrapper>
    );
};
const EpisodesLayoutWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: var(--container-width);
    margin-inline: auto;
    padding-inline: var(--container-padding);
    gap: 2rem;
    margin-block: 2rem;
    justify-content: center;
`;
const ContentWrapper = styled.div`
    display: flex;
    height: max-content;
    flex-direction: column;
    gap: 1rem;
    flex: 1;
`;
const TitleAndDescriptionWrapper = styled.div``;
const Title = styled.div`
    font-weight: var(--fw-bold);
    font-size: var(--fs-heading-l);
`;
const Description = styled.p`
    color: var(--neutral);
    max-width: 40rem;
`;

export default EpisodesNonHostingLayout;
