import { useMemo } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import { useStatsDateSelectorContext } from '@/context/StatsDateSelectorContext';
import RingChartWithLegend from '@/components/charts/RingChartWithLegend';
import { useParams } from 'react-router';
import useQuery from '@/shared/hooks/useQuery';
import useListenersTopRankingQuery from '@/queries/stats/useListenersTopRankingQuery.hook';
import useCampaignListenersTopRankingQuery from '@/queries/stats/useCampaignListenersTopRankingQuery.hook';
import { getLocalTimeZone } from '@internationalized/date';
import useUserQuery from '@/queries/user/useUserQuery.hook';

const messages = defineMessages({
    'other.episodes': { defaultMessage: 'Autres épisodes' },
});

function ListenersPodcastRanking() {
    const intl = useIntl();
    const { data: user } = useUserQuery();
    const timezone = useMemo(() => user?.timezone || getLocalTimeZone(), [user]);

    const { showId } = useParams();
    const query = useQuery();
    const episodeQuery = query.get('episode');
    const campaignId = query.get('campaignId');
    const { startDate, endDate } = useStatsDateSelectorContext();

    const { data: topRankingsShow, isLoading: topRankingsShowAreLoading } =
        useListenersTopRankingQuery({
            showId,
            timezone,
            episodes: episodeQuery && episodeQuery !== 'all' ? episodeQuery.split('_') : [],
            start: startDate,
            end: endDate,
            options: {
                enabled: !campaignId,
            },
        });

    const { data: topRankingsCampaign, isLoading: topRankingsCampaignAreLoading } =
        useCampaignListenersTopRankingQuery({
            campaignId,
            timezone,
            episodes: episodeQuery && episodeQuery !== 'all' ? episodeQuery.split('_') : [],
            start: startDate,
            end: endDate,
            options: {
                enabled: !!campaignId,
            },
        });

    const topRankings = campaignId ? topRankingsCampaign : topRankingsShow;
    const isLoading = topRankingsShowAreLoading || topRankingsCampaignAreLoading;

    return (
        <>
            <DeprecatedPaper mt={30} mb={20}>
                <DeprecatedText weight="bold" size="veryBig" mb={5}>
                    <FormattedMessage defaultMessage="Classement des épisodes" />
                </DeprecatedText>
                <DeprecatedText>
                    <FormattedMessage defaultMessage="Quels épisodes ont été les plus écoutés ?" />
                </DeprecatedText>
            </DeprecatedPaper>
            {topRankings && (
                <RingChartWithLegend
                    isLoading={isLoading}
                    source={topRankings.map((rank, i) => ({
                        value: rank.downloads,
                        percent: rank.percentage,
                        key: i,
                        name: rank.name,
                        label:
                            rank.name === 'others'
                                ? intl.formatMessage(messages['other.episodes'])
                                : rank.name,
                    }))}
                    py={50}
                    px={30}
                />
            )}
        </>
    );
}

export default ListenersPodcastRanking;
