const newsletterKeys = {
    all: () => [{ entity: 'newsletter' }],
    allDetails: () => [{ ...newsletterKeys.all()[0], scope: 'detail' }],
    detailByShowId: (rawShowId: string) => [
        { ...newsletterKeys.allDetails()[0], showId: Number(rawShowId) },
    ],
    detailByEpisodeId: (rawEpisodeId: string) => [
        { ...newsletterKeys.allDetails()[0], episodeId: Number(rawEpisodeId) },
    ],
    subscribersByShowId: (showId: string, page?: number, perPage?: number) => [
        { ...newsletterKeys.allDetails()[0], showId: Number(showId), page, perPage },
    ],
    exportSubscribersByShowId: (showId: string) => [
        { ...newsletterKeys.allDetails()[0], showId: Number(showId), scope: 'export' },
    ],
    templateByShowId: (showId: string) => [
        { ...newsletterKeys.allDetails()[0], showId: Number(showId), scope: 'template' },
    ],
};

export default newsletterKeys;
