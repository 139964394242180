import { useMemo, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import LeftIcon from '@public/icons/chevronleft.svg';
import RightIcon from '@public/icons/chevronright.svg';
import { reaction } from 'mobx';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import { useResponsive } from '@/shared/hooks/useResponsive';
import DeprecatedIcon from '../../atoms/DeprecatedIcon';
import DeprecatedWhitePaper from '../../atoms/DeprecatedWhitePaper';
import PaperGrid from '../../atoms/PaperGrid';
import Column from '../../atoms/Column';
import DeprecatedPaper from '../../atoms/DeprecatedPaper';
import OutsideClickHandler from 'react-outside-click-handler';

const Page = styled(DeprecatedWhitePaper)`
    display: flex;
    cursor: pointer;
    margin: 0.15rem;
    border-radius: var(--r-xs);
    align-items: center;
    justify-content: center;
    font-size: 12px;
    padding: 0 5px;
    ${({ active }) =>
        active
            ? css`
                  box-shadow: initial;
                  color: var(--white);
                  background: var(--primary);
              `
            : css`
                  &:hover {
                      background: var(--neutral50);
                  }
              `}
`;

const DropdownPageWrapper = styled.div`
    position: relative;
`;

const DropDown = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(calc(-100% - 0.25rem));
    display: flex;
    flex-direction: column-reverse;
    max-height: 16rem;
    overflow-y: auto;
    background: white;
`;

const responsiveData = (responsive) => {
    if (responsive.isMobileOrTablet)
        return {
            minW: 24,
            maxH: 24,
            minH: 24,
            w: 'initial',
            h: 24,
        };
    return {
        minW: 21,
        maxH: 21,
        minH: 21,
        w: 'initial',
        h: 21,
    };
};

const MorePages = ({ position, pagination, resp }) => {
    const [showDropdownPageSelect, setShowDropdownPageSelect] = useState({
        start: false,
        end: false,
    });

    const getDropdownPages = (position) => {
        const startPageMatch = {
            start: 2,
            end: pagination?.currentPage + 4,
        };
        const endPageMatch = {
            start: pagination?.currentPage - 4,
            end: pagination?.totalPages - 1,
        };

        const dropdownPages = [];

        for (let i = startPageMatch[position]; i <= endPageMatch[position]; i++) {
            dropdownPages.push(i);
        }

        return dropdownPages;
    };

    return (
        <OutsideClickHandler
            onOutsideClick={() => {
                setShowDropdownPageSelect({ start: false, end: false });
            }}
        >
            <DropdownPageWrapper>
                <Page
                    {...resp}
                    onClick={() =>
                        setShowDropdownPageSelect({
                            ...showDropdownPageSelect,
                            [position]: !showDropdownPageSelect[position],
                        })
                    }
                >
                    ...
                </Page>

                {showDropdownPageSelect[position] && (
                    <DropdownPageSelect
                        pagesToDisplay={getDropdownPages(position)}
                        pagination={pagination}
                        resp={resp}
                    />
                )}
            </DropdownPageWrapper>
        </OutsideClickHandler>
    );
};

const DropdownPageSelect = ({ pagesToDisplay, pagination, resp }) => {
    return (
        <DropDown>
            {pagesToDisplay.map((page, index) => (
                <Page key={index} {...resp} onClick={() => pagination?.to(page)}>
                    {page}
                </Page>
            ))}
        </DropDown>
    );
};

function Pagination({
    pagination,
    onChange,
    singular,
    plural,
    children,
    isLoading = false,
    ...props
}) {
    const { isMobileOrTablet, isDesktop } = useResponsive();
    const resp = responsiveData({ isMobileOrTablet });

    useEffect(() => {
        let dispose = null;
        if (onChange) {
            dispose = reaction(() => pagination?.currentPage, onChange);
        }
        return () => {
            if (dispose) dispose();
        };
    }, []);

    useMemo(() => responsiveData({ isMobileOrTablet }), [isMobileOrTablet]);

    if (!pagination?.totalPages || pagination?.totalPages === 1) {
        return <DeprecatedPaper pb={20}>{children}</DeprecatedPaper>;
    }

    const pages = [];
    for (
        let i = Math.max(pagination?.currentPage - 3, 1);
        i <= Math.min(pagination?.currentPage + 3, pagination?.totalPages);
        i += 1
    ) {
        pages.push(i);
    }

    return (
        <PaperGrid pb={35} column={isMobileOrTablet ? 1 : 3} {...props}>
            {isDesktop && (
                <Column startColumn={1} endColumn={2} color="--neutral500" size="small">
                    <FormattedMessage
                        defaultMessage="Page {current} sur {total} - {count} {count, plural, one {{singular}} other {{plural}}}"
                        values={{
                            current: pagination?.currentPage,
                            total: pagination?.totalPages,
                            count: pagination?.total,
                            singular,
                            plural,
                        }}
                    />
                </Column>
            )}
            <Column
                startColumn={isDesktop && 2}
                endColumn={isDesktop && 2}
                justify={isDesktop && 'center'}
            >
                <DeprecatedIcon
                    as={LeftIcon}
                    mr={10}
                    color="--neutral500"
                    onClick={pagination?.currentPage > 1 ? pagination?.previous : null}
                />
                {!pages.includes(1) && (
                    <>
                        <Page
                            active={pagination?.currentPage === 1}
                            onClick={() => {
                                if (isLoading) return;
                                pagination?.to(1);
                            }}
                            {...resp}
                        >
                            1
                        </Page>
                        {/* Add an "..." interval if the 2nd page is not in the displayed pages */}
                        {!pages.includes(2) && (
                            <MorePages position="start" pagination={pagination} resp={resp} />
                        )}
                    </>
                )}
                {pages.map((p) => (
                    <Page
                        key={p}
                        active={p === pagination?.currentPage}
                        onClick={() => {
                            if (isLoading) return;
                            pagination?.to(p);
                        }}
                        {...resp}
                    >
                        {p}
                    </Page>
                ))}
                {!pages.includes(pagination?.totalPages) && (
                    <>
                        {/* Add an "..." interval if the penultimate page is not in the displayed pages */}
                        {!pages.includes(pagination?.totalPages - 1) && (
                            <MorePages position="end" pagination={pagination} resp={resp} />
                        )}
                        <Page
                            active={pagination?.currentPage === pagination?.totalPages}
                            onClick={() => {
                                if (isLoading) return;
                                pagination?.to(pagination?.totalPages);
                            }}
                            {...resp}
                        >
                            {pagination?.totalPages}
                        </Page>
                    </>
                )}
                <DeprecatedIcon
                    as={RightIcon}
                    ml={5}
                    color="--neutral500"
                    onClick={
                        pagination?.currentPage < pagination?.totalPages ? pagination?.next : null
                    }
                />
            </Column>
            {children}
        </PaperGrid>
    );
}

export default observer(Pagination);
