const showListeningLinkKeys = {
    all: () => [{ entity: 'showListeningLink' }],
    allLists: () => [{ ...showListeningLinkKeys.all()[0], scope: 'list' }],
    listByShowId: (rawShowId) => [
        { ...showListeningLinkKeys.allLists()[0], showId: Number(rawShowId) },
    ],
    allDetails: () => [{ ...showListeningLinkKeys.all()[0], scope: 'detail' }],
    detailByShowId: (rawShowId) => [
        { ...showListeningLinkKeys.allDetails()[0], showId: Number(rawShowId) },
    ],
};

export default showListeningLinkKeys;
