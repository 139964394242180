import { useMutation } from '@tanstack/react-query';
import { experimentalUploadEpisodeImage } from '@/api';
import camelcaseKeys from 'camelcase-keys';

export interface MutationFnProps {
    episodeId: string;
    image: File;
}

const mutationFn = async ({ episodeId, image }: MutationFnProps) => {
    const { data } = await experimentalUploadEpisodeImage(episodeId, image);

    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const useAddEpisodeImageMutation = () => {
    return useMutation({
        mutationFn,
        onSuccess: () => {
            // Invalidation of episode query is handled in useSaveEpisodeFormMutation
        },
    });
};

export default useAddEpisodeImageMutation;
