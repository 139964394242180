import { useState } from 'react';
import Button from '@/components/Button';
import IconButton from '@/components/IconButton';
import Modal from '@/components/ui/atoms/Modal';
import Text from '@/components/ui/atoms/Text';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Stack from '@/components/ui/layout/Stack';
import { LinkedinAccount } from '@/queries/provider/useLinkedinProviderQuery';
import Alert from '@/components/ui/atoms/Alert';
import useUnlinkProviderMutation from '@/queries/provider/useUnlinkProviderMutation.hook';
import { useParams } from 'react-router';
import { IMG_PLACEHOLDER, LINKEDIN } from '@/shared/config/constants';
import RadioGroup from '@/components/ui/molecules/RadioGroup';
import InputRadio from '@/components/ui/atoms/InputRadio';
import Img from '@/components/ui/atoms/Img';

interface AccountsModalProps {
    isOpen: boolean;
    onOpenChange: (isOpen: boolean) => void;
    accounts: LinkedinAccount[];
    onSubmit: (accountId: string) => void;
}
const AccountsModal = ({ isOpen, onOpenChange, accounts, onSubmit }: AccountsModalProps) => {
    const { showId } = useParams<{ showId: string }>();
    const unlinkProvider = useUnlinkProviderMutation();
    const [selectedAccountId, setSelectedAccountId] = useState('');

    const handleClose = () => {
        unlinkProvider.mutate(
            { showId, provider: LINKEDIN },
            {
                onSuccess: () => {
                    onOpenChange(false);
                },
            },
        );
    };

    const handleSubmit = () => {
        onSubmit(selectedAccountId);
    };

    return (
        <ModalStyled isOpen={isOpen} onOpenChange={onOpenChange} size="medium">
            <Header>
                <Text variant="headingM" fontWeight="--fw-bold">
                    <FormattedMessage defaultMessage="À quelle page LinkedIn souhaitez-vous vous connecter ?" />
                </Text>
                <IconButton
                    icon={<CloseIcon icon={icon({ name: 'xmark-large', style: 'solid' })} />}
                    onPress={handleClose}
                    variant="ghost"
                    aria-label="Close modal"
                    style={{ padding: 0 }}
                />
            </Header>
            <Stack $gap="2rem">
                <Stack $gap="1.5rem">
                    <AccountRadioGroup onChange={setSelectedAccountId}>
                        {accounts.map((account) => (
                            <InputRadio key={account.id} value={account.id}>
                                <InputRadioInner>
                                    <Img
                                        src={account.profilePictureUrl ?? IMG_PLACEHOLDER}
                                        radius={4}
                                        size={40}
                                    />
                                    <Stack>
                                        <Text fontWeight="--fw-bold">{account.name}</Text>
                                        {account.type === 'personal' && (
                                            <Text color="--neutral500">
                                                <FormattedMessage defaultMessage="Page personnelle" />
                                            </Text>
                                        )}
                                        {account.type === 'professional' && (
                                            <Text color="--neutral500">
                                                <FormattedMessage defaultMessage="Page professionnelle" />
                                            </Text>
                                        )}
                                    </Stack>
                                </InputRadioInner>
                            </InputRadio>
                        ))}
                    </AccountRadioGroup>
                    <Alert variant="info" defaultIcon>
                        <Text fontWeight="--fw-semibold">
                            <FormattedMessage defaultMessage="Les statistiques de vos posts sont uniquement accessibles pour les pages professionnelles dont vous êtes administrateur." />
                        </Text>
                    </Alert>
                </Stack>
                <Actions>
                    <CancelButton variant="ghost" onPress={handleClose}>
                        <FormattedMessage defaultMessage="Annuler" />
                    </CancelButton>
                    <LoginButton onPress={handleSubmit} isDisabled={!selectedAccountId}>
                        <FormattedMessage defaultMessage="Se connecter à LinkedIn" />
                    </LoginButton>
                </Actions>
            </Stack>
        </ModalStyled>
    );
};

const ModalStyled = styled(Modal)`
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 2rem;
    overflow-y: auto;
`;
const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 1rem;
`;
const CloseIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--neutral500);
`;
const Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 1.5rem;
    border-top: 1px solid var(--neutral100);
`;
const CancelButton = styled(Button)`
    padding: 0;
    color: var(--neutral500);
`;
const AccountRadioGroup = styled(RadioGroup)`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    max-height: 10rem;
    overflow-y: auto;
`;
const InputRadioInner = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
`;
const LoginButton = styled(Button)`
    background-color: var(--linkedin);
    box-shadow: none;
`;

export default AccountsModal;
