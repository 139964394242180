import { z } from 'zod';
import { useIntl } from 'react-intl';
import { FORM_VALIDATION_MESSAGE } from '@/shared/services/validationMessageService';

const useTranscriptionSpeakerFormSchema = (speakersNames: string[]) => {
    const intl = useIntl();

    return z
        .object({
            name: z.string().min(1, intl.formatMessage(FORM_VALIDATION_MESSAGE.required)),
        })
        .superRefine((schema, ctx) => {
            const { name } = schema;

            if (speakersNames.includes(name)) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: intl.formatMessage({
                        defaultMessage: "Oups, ce nom existe déjà dans votre liste d'orateurs.",
                    }),
                    path: ['name'],
                });
            }
            return true;
        });
};

export type TranscriptionSpeakerFormSchema = z.infer<
    ReturnType<typeof useTranscriptionSpeakerFormSchema>
>;

export default useTranscriptionSpeakerFormSchema;
