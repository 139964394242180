import { createApi } from '../callApi';
import { UpdateShowGuestPermissionMutationFnProps } from '@/queries/guest/updateShowGuestPermissionMutation';
import { ShowGuestsInvitationFormSchema } from '@/components/ShowGuests/ShowGuestsInvitationForm/useShowGuestsInvitationFormSchema.hook';
import { UpdateChannelGuestMainRoleMutationFnProps } from '@/queries/guest/updateChannelGuestMainRoleMutation';
import { UpdateChannelGuestShowRolesMutationFnProps } from '@/queries/guest/updateChannelGuestShowRolesMutation';
import { AddChannelGuestInvitationMutationFnProps } from '@/queries/guest/addChannelGuestInvitationMutation';
import { DeleteChannelGuestPermissionMutationFnProps } from '@/queries/guest/deleteChannelGuestPermissionMutation';
import { DeleteChannelGuestInvitationMutationFnProps } from '@/queries/guest/deleteChannelGuestInvitationMutation';
const contentApi = createApi('CONTENT');

/*  Show */

export const fetchShowGuestsInvitations = (showId: string) => {
    return contentApi({ method: 'GET', target: `/v1/shows/${showId}/invitations` });
};
export const fetchShowGuestPermissions = (showId: string) => {
    return contentApi({ method: 'GET', target: `/v1/shows/${showId}/permissions` });
};
export const addShowGuestInvitation = (
    showId: string,
    invitation: ShowGuestsInvitationFormSchema,
) => {
    return contentApi({
        method: 'POST',
        target: `/v1/shows/${showId}/invitations`,
        body: invitation,
    });
};
export const updateShowGuestsPermission = (
    permissionId: number,
    permission: Omit<UpdateShowGuestPermissionMutationFnProps, 'permissionId'>,
) => {
    return contentApi({
        method: 'PUT',
        target: `/v1/permissions/${permissionId}`,
        body: permission,
    });
};
export const deleteShowGuestPermission = (permissionId: number) => {
    return contentApi({ method: 'DELETE', target: `/v1/permissions/${permissionId}` });
};
export const deleteShowGuestInvitation = (invitationId: number) => {
    return contentApi({ method: 'DELETE', target: `/v1/invitations/${invitationId}` });
};
export const exportShowGuests = (showId: string) => {
    return contentApi({
        method: 'GET',
        target: `/v1/shows/${showId}/permissions_extract`,
        headers: { Accept: 'text/csv' },
    });
};

/* Channel */

export const fetchChannelGuestInvitations = (channelId: string) => {
    return contentApi({ method: 'GET', target: `/v1/channels/${channelId}/invitations` });
};
export const fetchChannelGuestPermissions = (channelId: string) => {
    return contentApi({ method: 'GET', target: `/v1/channels/${channelId}/permissions` });
};
export const deleteChannelGuestPermissions = (channelId: string, userId: number) => {
    return contentApi({
        method: 'DELETE',
        target: `/v1/channels/${channelId}/permissions/user/${userId}`,
    });
};
export const exportChannelGuests = (channelId: string) => {
    return contentApi({
        method: 'GET',
        target: `/v1/channels/${channelId}/permissions_extract`,
        headers: { Accept: 'text/csv' },
    });
};
export const fetchChannelGuestUserPermissionsSummary = (channelId: string, userId: string) => {
    return contentApi({
        method: 'GET',
        target: `/v1/channels/${channelId}/permissions/user/${userId}`,
    });
};
export const updateChannelGuestMainRole = ({
    channelId,
    userId,
    role,
}: UpdateChannelGuestMainRoleMutationFnProps) => {
    return contentApi({
        method: 'POST',
        target: `/v1/channels/${channelId}/permissions/user/${userId}/main_role`,
        body: { role },
    });
};
export const updateChannelGuestShowRoles = ({
    channelId,
    userId,
    shows,
}: UpdateChannelGuestShowRolesMutationFnProps) => {
    return contentApi({
        method: 'POST',
        target: `/v1/channels/${channelId}/permissions/user/${userId}`,
        body: { shows },
    });
};
export const addChannelGuestInvitation = ({
    channelId,
    invitation,
}: AddChannelGuestInvitationMutationFnProps) => {
    return contentApi({
        method: 'POST',
        target: `/v1/channels/${channelId}/invitations`,
        body: invitation,
    });
};
export const deleteChannelGuestPermission = ({
    channelId,
    userId,
}: DeleteChannelGuestPermissionMutationFnProps) => {
    return contentApi({
        method: 'DELETE',
        target: `/v1/channels/${channelId}/permissions/user/${userId}`,
    });
};
export const deleteChannelGuestInvitation = ({
    channelId,
    email,
}: DeleteChannelGuestInvitationMutationFnProps) => {
    return contentApi({
        method: 'DELETE',
        target: `/v1/channels/${channelId}/invitations/${email}`,
    });
};
