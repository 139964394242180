import styled from 'styled-components';
import PlaylistHeader from './PlaylistHeader';
import PlaylistList from './PlaylistList';
import PlaylistEpisodes from './PlaylistEpisodes';

const Playlist = () => {
    return (
        <Wrapper>
            <PlaylistHeader />
            <InnerWrapper>
                <PlaylistList />
                <PlaylistEpisodes />
            </InnerWrapper>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
`;
const InnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2.5rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: flex;
        flex-direction: row;
        column-gap: 1.5rem;

        & > :first-child {
            max-width: 275px;
            flex-shrink: 0;
        }

        & > :last-child {
            flex-grow: 1;
        }
    }
`;

export default Playlist;
