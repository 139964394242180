import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateShow } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import { Show } from './useShowQuery.hook';
import showKeys from './showKeys';
import { decamelizeKeys } from 'humps';

interface ShowInput {
    author?: string;
    categoryId?: number;
    secondaryCategoryId?: number | null;
    htmlDescription?: string;
    languageId?: number;
    publicCounts?: boolean;
    slug?: string;
    name?: string;
    tags?: string[];
    type?: 'episodic' | 'serial';
    whiteLabel?: boolean;
    archived?: boolean;
}

export interface UpdateShowMutationFnProps {
    showId: string;
    show?: ShowInput;
}

const mutationFn = async ({ showId, show }: UpdateShowMutationFnProps): Promise<Show> => {
    const { data } = await updateShow(showId, decamelizeKeys(show));

    return camelcaseKeys(data as any, { deep: true });
};

const updateShowMutation = () => {
    const queryclient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: (_) => {
            queryclient.invalidateQueries({
                queryKey: showKeys.all(),
            });
        },
    });
};

export default updateShowMutation;
