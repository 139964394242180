import { useResponsive } from '@/shared/hooks/useResponsive';
import { useFormContext } from 'react-hook-form';
import CardSelect, { CARD_SELECT_VARIANTS } from '@ui/atoms/CardSelect';
import Stack from '@ui/layout/Stack';
import Text from '@ui/atoms/Text';
import { FormattedMessage } from 'react-intl';
import { EPISODE_TYPE } from '@/shared/config/constants';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';
import { CardSelectListController } from '@ui/molecules/CardSelectList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StyledCardSelectListController = styled(CardSelectListController)`
    flex-direction: column;

    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        flex-direction: row;
    }
`;

const CardSelectIcon = styled(FontAwesomeIcon)`
    color: var(--primary);
    width: 1.5rem;
    height: 1.5rem;
`;

const EpisodeEditFormOptionsTypeField = () => {
    const { isDesktopLarge } = useResponsive();
    const { control } = useFormContext();

    const cardSelectVariant = isDesktopLarge
        ? CARD_SELECT_VARIANTS.LARGE
        : CARD_SELECT_VARIANTS.SMALL;

    return (
        <Stack $gap="0.5rem">
            <Text variant="bodyM" fontWeight="--fw-semibold">
                <FormattedMessage defaultMessage="Type d'épisode" />
            </Text>
            <StyledCardSelectListController name="options.type" control={control}>
                <CardSelect
                    value={EPISODE_TYPE.FULL}
                    variant={cardSelectVariant}
                    title={<FormattedMessage defaultMessage="Normal" />}
                    description={
                        <FormattedMessage defaultMessage="Épisode à la suite de votre liste d’épisode" />
                    }
                    icon={
                        <CardSelectIcon
                            icon={icon({ name: 'rectangle-history', style: 'duotone' })}
                        />
                    }
                />
                <CardSelect
                    value={EPISODE_TYPE.TRAILER}
                    variant={cardSelectVariant}
                    title={<FormattedMessage defaultMessage="Bande-annonce" />}
                    description={
                        <FormattedMessage defaultMessage="Épisode faisant la promotion du podcast ou de la saison" />
                    }
                    icon={<CardSelectIcon icon={icon({ name: 'stars', style: 'duotone' })} />}
                />
                <CardSelect
                    value={EPISODE_TYPE.BONUS}
                    variant={cardSelectVariant}
                    title={<FormattedMessage defaultMessage="Bonus" />}
                    description={<FormattedMessage defaultMessage="Épisode spécial" />}
                    icon={<CardSelectIcon icon={icon({ name: 'bolt', style: 'duotone' })} />}
                />
            </StyledCardSelectListController>
        </Stack>
    );
};

export default EpisodeEditFormOptionsTypeField;
