import { runInAction } from 'mobx';
import Store from './Store';
import { apiCall, apiFetch } from '@/components/legacy/api';
import { deleteClipFormat, fetchClipFormat, fetchClipFormatsOfShow, updateClipFormat } from '@/api';
import PaginationModel from '../models/PaginationModel';
import ClipFormatModel from '../models/ClipFormatModel';
import { FormattedMessage } from 'react-intl';
import { modalToastQueue } from '@/components/ui/molecules/GlobalToastRegions';
import { UI_VARIANTS } from '@/shared/config/constants';

class ClipFormatStore extends Store {
    static observables = {
        clipFormats: [],
        pagination: {},
    };

    @apiFetch
    async fetchClipFormat(clipFormatId) {
        const { data } = await fetchClipFormat(clipFormatId);
        const existingClipFormat = this.clipFormats.find((clipFormat) => clipFormat.id === data.id);
        if (!existingClipFormat) {
            this.clipFormats.push(new ClipFormatModel(this, data));
        } else {
            existingClipFormat.updateData(data);
            existingClipFormat.succeeded &&
                modalToastQueue.add(
                    <FormattedMessage defaultMessage="Le clip a bien été généré" />,
                    {
                        variant: UI_VARIANTS.SUCCESS,
                        timeout: 5000,
                    },
                );
        }
    }

    @apiFetch
    async fetchClipFormatsOfShow(showId, page = 1, perPage = 16) {
        const { data, meta } = await fetchClipFormatsOfShow(showId, page, perPage);
        runInAction(() => {
            this.clipFormats = data.map((json) => new ClipFormatModel(this, json));
            this.pagination = new PaginationModel(this, meta.pagination);
        });
    }

    @apiCall
    async toggleClipFormatFullCover(clipFormatId, fullCover) {
        const { data } = await updateClipFormat(clipFormatId, { full_cover: fullCover });
        return data ? data.full_cover : false;
    }

    @apiCall
    async deleteClipFormat(clipFormat) {
        await deleteClipFormat(clipFormat.id);
        runInAction(() => {
            this.clipFormats.remove(clipFormat);
        });
    }
}

export default ClipFormatStore;
