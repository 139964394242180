import { FormattedMessage, useIntl } from 'react-intl';
import { useResponsive } from '@/shared/hooks/useResponsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Button from '@/components/Button';
import styled, { css } from 'styled-components';

const MAX_PAGES_DISPLAYED = 5;

interface Options {
    goToFirstAndLastPageButtons?: boolean;
    showPaginationInfo?: boolean;
    showPageSelector?: boolean;
}

export interface PaginationProps {
    pagination: {
        count?: number;
        total?: number;
        currentPage: number;
        perPage: number;
        totalPages: number;
    };
    options?: Options;
    onGoToPage: (page: number) => void;
    onGoToFirstPage: () => void;
    onGoToLastPage: () => void;
    onGoToPreviousPage: () => void;
    onGoToNextPage: () => void;
    onChangePerPage?: (perPage: number) => void;
    children?: React.ReactNode;
}

const defaultOptions: Options = {
    goToFirstAndLastPageButtons: false,
    showPaginationInfo: true,
    showPageSelector: true,
};

const ServerPagination = ({
    pagination,
    options,
    onGoToPage,
    onGoToFirstPage,
    onGoToLastPage,
    onGoToPreviousPage,
    onGoToNextPage,
    onChangePerPage,
    children,
}: PaginationProps) => {
    const intl = useIntl();
    const { isMobileOrTablet } = useResponsive();

    const pagesArray = Array.from(Array(pagination.totalPages).keys());
    const pagesDisplayStart = Math.min(
        pagination.totalPages,
        Math.max(0, pagination.currentPage - Math.floor(MAX_PAGES_DISPLAYED / 2) - 1),
    );
    const pagesDisplayEnd = pagesDisplayStart + MAX_PAGES_DISPLAYED;
    const finalOptions = { ...defaultOptions, ...options };

    return (
        <PaginationWrapper>
            <Pages>
                {finalOptions.showPaginationInfo !== false && pagination.total && (
                    <PageInfo>
                        <FormattedMessage
                            defaultMessage="Résultats {start} - {end} sur {totalLignes}"
                            values={{
                                start:
                                    pagination.currentPage * pagination.perPage -
                                    pagination.perPage +
                                    1,
                                end: Math.min(
                                    pagination.currentPage * pagination.perPage,
                                    pagination.total,
                                ),
                                totalLignes: pagination.total,
                            }}
                        />
                    </PageInfo>
                )}
                {finalOptions.showPageSelector !== false && (
                    <PageNbSelector
                        value={pagination.perPage}
                        onChange={(e) => {
                            onChangePerPage && onChangePerPage(Number(e.target.value));
                        }}
                    >
                        {[15, 30, 40, 50].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                {intl.formatMessage({ defaultMessage: 'Montrer' })} {pageSize}
                            </option>
                        ))}
                    </PageNbSelector>
                )}
            </Pages>
            <PaginationButtonsWrapper>
                {finalOptions?.goToFirstAndLastPageButtons !== false && (
                    <PaginationButton
                        onPress={onGoToFirstPage}
                        variant="ghost"
                        isDisabled={pagination.currentPage === 1}
                    >
                        {isMobileOrTablet ? (
                            <FormattedMessage defaultMessage="Première" />
                        ) : (
                            <FormattedMessage defaultMessage="Première page" />
                        )}
                    </PaginationButton>
                )}
                <PaginationButton
                    onPress={onGoToPreviousPage}
                    variant="ghost"
                    isDisabled={pagination.currentPage === 1}
                >
                    <FontAwesomeIcon icon={icon({ name: 'chevron-left', style: 'solid' })} />
                </PaginationButton>
                {pagesArray.length > MAX_PAGES_DISPLAYED ? (
                    <>
                        {pagesDisplayStart > 0 && (
                            <>
                                <SmallPaginationButton
                                    variant="ghost"
                                    $isActive={false}
                                    onPress={() => onGoToPage(1)}
                                >
                                    1
                                </SmallPaginationButton>
                                <Ellipsis icon={icon({ name: 'ellipsis', style: 'solid' })} />
                            </>
                        )}
                        {pagesArray.slice(pagesDisplayStart, pagesDisplayEnd).map((item) => (
                            <SmallPaginationButton
                                variant={
                                    pagination.currentPage === item + 1 ? 'secondary' : 'ghost'
                                }
                                $isActive={pagination.currentPage === item + 1}
                                onPress={() => onGoToPage(item + 1)}
                                key={item}
                            >
                                {item + 1}
                            </SmallPaginationButton>
                        ))}
                        {pagesDisplayEnd < pagesArray.length && (
                            <>
                                <Ellipsis icon={icon({ name: 'ellipsis', style: 'solid' })} />
                                <SmallPaginationButton
                                    variant="ghost"
                                    $isActive={false}
                                    onPress={() => onGoToPage(pagesArray.slice(-1)[0] + 1)}
                                >
                                    {pagesArray.slice(-1)[0] + 1}
                                </SmallPaginationButton>
                            </>
                        )}
                    </>
                ) : (
                    pagesArray.map((item) => (
                        <SmallPaginationButton
                            variant={pagination.currentPage === item + 1 ? 'secondary' : 'ghost'}
                            $isActive={pagination.currentPage === item + 1}
                            onPress={() => onGoToPage(item + 1)}
                            key={item}
                        >
                            {item + 1}
                        </SmallPaginationButton>
                    ))
                )}
                <PaginationButton
                    onPress={onGoToNextPage}
                    variant="ghost"
                    isDisabled={pagination.currentPage === pagination.totalPages}
                >
                    <FontAwesomeIcon icon={icon({ name: 'chevron-right', style: 'solid' })} />
                </PaginationButton>
                {finalOptions.goToFirstAndLastPageButtons !== false && (
                    <PaginationButton
                        onPress={onGoToLastPage}
                        variant="ghost"
                        isDisabled={pagination.currentPage === pagination.totalPages}
                    >
                        {isMobileOrTablet ? (
                            <FormattedMessage defaultMessage="Dernière" />
                        ) : (
                            <FormattedMessage defaultMessage="Dernière page" />
                        )}
                    </PaginationButton>
                )}
            </PaginationButtonsWrapper>
            {children ? <ChildrenWrapper>{children}</ChildrenWrapper> : <FlexDecoy />}
        </PaginationWrapper>
    );
};

const PaginationWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    ${(props) => props.theme.mediaQueries.mobile} {
        flex-wrap: wrap;
        flex-direction: column-reverse;
        row-gap: 1rem;
        & > * {
            justify-content: center;
        }
    }
`;
const Pages = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    flex-basis: 14rem;

    ${(props) => props.theme.mediaQueries.mobile} {
        flex-basis: auto;
    }
`;
const PageInfo = styled.span`
    color: var(--neutral500);
`;
const PageNbSelector = styled.select`
    background: none;
    border-radius: var(--r-xs);
    border: 1px solid var(--neutral500);
    color: var(--neutral500);
    padding-inline: 0.5rem;
`;
const PaginationButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 0.25rem;
    width: 100%;

    ${(props) => props.theme.mediaQueries.mobile} {
        justify-content: center;
    }
`;
const PaginationButton = styled(Button)`
    padding: 0.5rem;
    color: var(--black);
    font-weight: var(--fw-semibold);
`;
const SmallPaginationButton = styled(Button)<{ $isActive?: boolean }>`
    width: 2rem;
    height: 2rem;
    color: var(--black);

    & span {
        text-wrap: nowrap;
    }

    ${({ $isActive }) =>
        $isActive &&
        css`
            color: var(--primary);
        `};
`;
const ChildrenWrapper = styled.div`
    margin-left: 2rem;
    flex-shrink: 0;
`;
const Ellipsis = styled(FontAwesomeIcon)`
    color: var(--neutral300);
    margin-inline: 0.5rem;
`;
const FlexDecoy = styled.div`
    flex-basis: 14rem;

    ${(props) => props.theme.mediaQueries.mobile} {
        flex-basis: auto;
    }
`;

export default ServerPagination;
