import { useEffect, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { useParams } from 'react-router-dom';
import { FormattedMessage, FormattedNumber, FormattedDate } from 'react-intl';
import dayjs from 'dayjs';
import { withTheme } from 'styled-components';
import { motion } from 'framer-motion';
import Link from '@ui/atoms/Link';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import { compose } from '@/shared/utils/compose';
import { connect } from '@/components/legacy/connect';
import { useResponsive } from '@/shared/hooks/useResponsive';
import ListenersVariationGraphPlotLine from '@/components/unorganized/ListenersVariationGraphPlotLine';
import HorizontalTabSlide from '@ui/molecules/HorizontalTabSlide';
import { PRICING, PRICING_NAME } from '@/shared/config/pricing';
import { USER_OPTIONS } from '@/shared/config/userOptions';
import { extractCssVariable } from '@/shared/utils/cssVariable';
import IconInfo from '@ui/icons/IconInfo';
import Text from '@ui/atoms/Text';
import Alert from '@ui/atoms/Alert';
import { useStatsDateSelectorContext } from '@/context/StatsDateSelectorContext';
import { sendAmplitudeLogEvent } from '@/shared/utils/amplitude';
import { renderDates } from '@/shared/utils/renderDates';

const TABS_NAME = {
    LISTENING: 'listening',
    SINGLE_LISTENERS: 'singleListener',
};

const TAB_TITLES_GRAPH = {
    [TABS_NAME.LISTENING]: <FormattedMessage defaultMessage="Écoutes" />,
    [TABS_NAME.SINGLE_LISTENERS]: <FormattedMessage defaultMessage="Auditeurs uniques" />,
};

const TotalListenersMessage = ({ totalListening, tabValue, isLoading }) => {
    if (tabValue === TABS_NAME.SINGLE_LISTENERS) {
        return (
            <DeprecatedText size="veryBig" weight="semibold" mr={10}>
                -
            </DeprecatedText>
        );
    }

    return isLoading ? (
        <ContentLoader width={100} height={20} style={{ width: 100, height: 20 }}>
            <rect x="0" y="0" rx="5" ry="5" width="100" height="14" />
        </ContentLoader>
    ) : (
        <DeprecatedText size="veryBig" weight="semibold" mr={10}>
            {isLoading ? (
                <ContentLoader width={100} height={20} style={{ width: 100, height: 20 }}>
                    <rect x="0" y="0" rx="5" ry="5" width="100" height="14" />
                </ContentLoader>
            ) : (
                <>
                    <FormattedNumber value={totalListening} />{' '}
                    <FormattedMessage
                        defaultMessage="{totalListening, plural, =0 {écoute} one{écoute} other {écoutes}}"
                        values={{
                            totalListening,
                        }}
                    />
                </>
            )}
        </DeprecatedText>
    );
};

const TooltipHoverAccessDenied = ({ offerLink }) => (
    <DeprecatedText>
        <FormattedMessage
            defaultMessage="Les auditeurs uniques sont disponibles à partir de {offerLink}"
            values={{
                offerLink: (
                    <DeprecatedText as={Link} to={offerLink} color="-primary">
                        <FormattedMessage
                            defaultMessage="l'offre {offerName}"
                            values={{ offerName: PRICING_NAME[PRICING.BOOST] }}
                        />
                    </DeprecatedText>
                ),
            }}
        />
    </DeprecatedText>
);

const AlertOnSingleListener = ({ active }) => (
    <motion.div
        key="showTooltip"
        initial="close"
        animate={active ? 'open' : 'close'}
        exit="close"
        transition={{ duration: 0.25 }}
        variants={{
            open: { opacity: 1, height: 'auto', y: 0 },
            close: { opacity: 0, height: 0, y: -15 },
        }}
    >
        <Alert variant="info" icon={<IconInfo />}>
            <Text as="span" variant="footnote">
                <FormattedMessage
                    defaultMessage="Nous recueillons les données d'auditeurs uniques que depuis le {untilDate}."
                    values={{
                        untilDate: (
                            <FormattedDate
                                day="2-digit"
                                month="long"
                                year="numeric"
                                value="2021/04/16"
                            />
                        ),
                    }}
                />
            </Text>
        </Alert>
    </motion.div>
);

const enhance = compose(
    withTheme,
    connect(({ listenersStatsStore, routerStore }) => ({
        totalListening: listenersStatsStore.totalListening,
        offerLink: routerStore.link('user.offers'),
        isAccessToSingleListener:
            !routerStore.query.episode && routerStore.params.itemType !== 'campaign',
    })),
);

const ListenerGraph = ({
    totalListening,
    isLoading,
    isCalendarGraphRange = true,
    getGraphValues,
    isAccessToSingleListener,
    isActiveTabSingleListener = true,
    offerLink,
}) => {
    const { itemType } = useParams();

    const { startDate, endDate } = useStatsDateSelectorContext();

    const isSameDate = dayjs(dayjs(startDate).format('YYYY-MM-DD')).isSame(
        dayjs(endDate).format('YYYY-MM-DD'),
    );
    const isAlertDisplayed = dayjs(startDate).isBefore(dayjs('2021-04-16', 'YYYY-MM-DD'));
    const [step, setStep] = useState('days');
    const { isMobileOrTablet } = useResponsive();

    const getStepValue = (value) => {
        if (!isCalendarGraphRange) return;
        getGraphValues.getStep(value);
        setStep(value);
    };

    const handleScrollToValue = (value) => {
        if (!isCalendarGraphRange) return;
        getGraphValues.getScroll(value);
    };

    useEffect(() => {
        if (getGraphValues.tabValue === 'singleListener' && step === 'years') getStepValue('days');
    }, [getGraphValues.tabValue]);

    return (
        <>
            <DeprecatedPaper flex justify="space-between" column={isMobileOrTablet}>
                <DeprecatedPaper flex={!isMobileOrTablet} align={isLoading ? 'center' : 'baseline'}>
                    <TotalListenersMessage
                        totalListening={totalListening}
                        tabValue={getGraphValues.tabValue}
                        isLoading={isLoading}
                        isMobileOrTablet={isMobileOrTablet}
                    />
                    <DeprecatedText
                        color="--neutral500"
                        as="span"
                        nowrap
                        minH={39}
                        flex
                        align="center"
                    >
                        {isSameDate ? (
                            <FormattedMessage
                                defaultMessage="Le {date}"
                                values={{
                                    date: (
                                        <FormattedDate
                                            value={startDate}
                                            year="numeric"
                                            month="long"
                                            day="numeric"
                                        />
                                    ),
                                }}
                            />
                        ) : (
                            <FormattedMessage
                                defaultMessage="Du {from} au {to}"
                                values={{
                                    from: (
                                        <FormattedDate
                                            value={startDate}
                                            year="numeric"
                                            month="long"
                                            day="numeric"
                                        />
                                    ),
                                    to: (
                                        <FormattedDate
                                            value={endDate}
                                            year="numeric"
                                            month="long"
                                            day="numeric"
                                        />
                                    ),
                                }}
                            />
                        )}
                    </DeprecatedText>
                </DeprecatedPaper>
                <HorizontalTabSlide
                    tabsTitles={TAB_TITLES_GRAPH}
                    getTabValue={getGraphValues.getTabValue}
                    active={isAccessToSingleListener && isActiveTabSingleListener}
                    tabsLockedTooltip={{
                        [TABS_NAME.SINGLE_LISTENERS]: (
                            <TooltipHoverAccessDenied offerLink={offerLink} />
                        ),
                    }}
                    userOptions={[USER_OPTIONS.UNIQUE_LISTENERS]}
                    simpleSwitch
                    buttonResetStyles
                    justify="flex-end"
                    tooltipArrow="top"
                    forceDesktop={true}
                    onChange={(tab) => {
                        if (tab !== TABS_NAME.SINGLE_LISTENERS) return;
                        sendAmplitudeLogEvent('Stats Unique Listeners Selected');
                    }}
                />
            </DeprecatedPaper>
            {isAlertDisplayed && (
                <AlertOnSingleListener
                    active={getGraphValues.tabValue === 'singleListener' && itemType !== 'campaign'}
                    isMobileOrTablet={isMobileOrTablet}
                />
            )}

            <DeprecatedPaper flex align="center">
                <DeprecatedPaper
                    h={10}
                    w={10}
                    minH={10}
                    minW={10}
                    mr={10}
                    rounded
                    background={extractCssVariable('--primary')}
                />
                <DeprecatedText auto nowrap>
                    <FormattedMessage defaultMessage="Nombre d'écoutes" />
                </DeprecatedText>
                {isCalendarGraphRange && (
                    <>
                        <DeprecatedText
                            color={step === 'days' ? '--black' : '--neutral500'}
                            onClick={() => getStepValue('days')}
                            as="span"
                            mr={25}
                        >
                            {renderDates('range', 'days')}
                        </DeprecatedText>
                        <DeprecatedText
                            color={step === 'weeks' ? '--black' : '--neutral500'}
                            onClick={() => getStepValue('weeks')}
                            mr={25}
                            as="span"
                        >
                            {renderDates('range', 'weeks')}
                        </DeprecatedText>
                        <DeprecatedText
                            color={step === 'months' ? '--black' : '--neutral500'}
                            onClick={() => getStepValue('months')}
                            mr={25}
                            as="span"
                        >
                            {renderDates('range', 'months')}
                        </DeprecatedText>
                        {getGraphValues.tabValue === 'listening' && (
                            <DeprecatedText
                                color={step === 'years' ? '--black' : '--neutral500'}
                                onClick={() => getStepValue('years')}
                                as="span"
                            >
                                {renderDates('range', 'years')}
                            </DeprecatedText>
                        )}
                    </>
                )}
            </DeprecatedPaper>
            <ListenersVariationGraphPlotLine
                onHover={handleScrollToValue}
                step={step}
                isLoading={isLoading}
            />
        </>
    );
};

export default enhance(ListenerGraph);
