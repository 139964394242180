import { FormattedMessage } from 'react-intl';
import Text from '@ui/atoms/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Tooltip, { TooltipTriggerWrapper } from '@ui/Tooltip';
import { TooltipTrigger } from 'react-aria-components';
import styled, { css } from 'styled-components';

export interface SuggestionProps {
    label: string;
    onClick: () => void;
    isDisabled?: boolean;
    isIndexed?: boolean;
}

const Suggestion = ({ label, onClick, isDisabled = false, isIndexed }: SuggestionProps) => {
    return (
        <SuggestionWrapper onClick={onClick} isDisabled={isDisabled}>
            <LeftWrapper>
                {/* @ts-ignore */}
                <Text fontWeight="--fw-semibold">{label}</Text>
                {isIndexed && (
                    <TooltipTrigger delay={0} closeDelay={0}>
                        <TooltipTriggerWrapper>
                            <CheckIcon icon={icon({ name: 'circle-check', style: 'solid' })} />
                        </TooltipTriggerWrapper>
                        <Tooltip placement="top">
                            <FormattedMessage defaultMessage="Votre podcast est référencé sur ce mot-clé." />
                        </Tooltip>
                    </TooltipTrigger>
                )}
            </LeftWrapper>
            <PlusButton disabled={isDisabled}>
                <FontAwesomeIcon
                    icon={icon({ name: 'plus', style: 'solid' })}
                    color="white"
                    size="2x"
                />
            </PlusButton>
        </SuggestionWrapper>
    );
};

const SuggestionWrapper = styled.div<{ isDisabled: boolean }>`
    transition-duration: 0.2s;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem;
    border-radius: var(--r-m);
    background-color: white;
    box-shadow: var(--s-xs);
    cursor: pointer;

    ${({ isDisabled }) =>
        isDisabled &&
        css`
            cursor: default;
            color: var(--neutral300);
        `};

    &:hover {
        transition-duration: 0.2s;
        box-shadow: var(--s-s);
    }
`;
const LeftWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
`;
const CheckIcon = styled(FontAwesomeIcon)`
    color: var(--info500);
`;
const PlusButton = styled.button`
    transition-duration: 0.2s;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: var(--r-full);
    background-color: var(--primary500);
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    transform-origin: center center;
    cursor: pointer;

    &:disabled {
        background-color: var(--neutral300);
    }
`;

export default Suggestion;
