import ProgressBar from '@ui/ProgressBar';
import styled from 'styled-components';

interface PsoSearchVolumeCellProps {
    score: number;
    isLoading: boolean;
}

const PsoSearchVolumeCell = ({ score, isLoading }: PsoSearchVolumeCellProps) => {
    if (isLoading) return <Dash />;
    return (
        <SearchVolumeWrapper>
            <span>{score}</span>
            <ProgressBar score={Math.min(100, Math.max(5, score))} thresholds={[0, 21, 61]} />
        </SearchVolumeWrapper>
    );
};

const SearchVolumeWrapper = styled.div`
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    & > :nth-child(1) {
        flex: 1;
    }
`;
const Dash = styled.div`
    width: 0.75rem;
    height: 2px;
    background-color: var(--neutral300);
    border-radius: var(--r-full);
`;

export default PsoSearchVolumeCell;
