import { FORM_VALIDATION_MESSAGE } from '@/utils/validationMessage';
import { useIntl } from 'react-intl';
import { z } from 'zod';
import { CalendarDateTime } from '@internationalized/date';

const usePersonalAccessTokenFormSchema = () => {
    const intl = useIntl();

    return z.object({
        name: z.string().min(1, intl.formatMessage(FORM_VALIDATION_MESSAGE.required)),
        expiresAt: z.custom<CalendarDateTime>().or(z.string()),
    });
};

export type PersonalAccessTokenSchema = z.infer<
    ReturnType<typeof usePersonalAccessTokenFormSchema>
>;

export default usePersonalAccessTokenFormSchema;
