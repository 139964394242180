import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { PRICING } from '@/utils/pricing';
import Text from '@ui/atoms/Text';
import { YOUTUBE } from '@/utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useParams } from 'react-router';
import useYoutubeProviderQuery from '@/queries/provider/useYoutubeProviderQuery.hook';
import RouterLink from '@/components/Link/RouterLink';
import BroadcastButton from '../../BroadcastButton';
import BroadcastYoutubeLoginModal from '../../BroadcastYoutubeLoginModal';
import { USER_OPTIONS } from '@/utils/userOptions';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import styled from 'styled-components';

const YoutubeBroadcastButton = () => {
    const { showId } = useParams<{ showId: string }>();
    const { data: show } = useShowQuery(showId);
    const {
        data: youtubeAccount,
        isError,
        isLoading: youtubeAccountIsLoading,
    } = useYoutubeProviderQuery({ showId });
    const [showYoutubeLoginModal, setShowYoutubeLoginModal] = useState(false);
    const hasAYoutubeAccount = youtubeAccount && !isError;
    const broadcastUrl = hasAYoutubeAccount ? youtubeAccount.channelUrl : undefined;
    const hasYoutubeUserOption = show?.userOptions.includes(USER_OPTIONS.BROADCAST_YOUTUBE);

    /**
     * We found a broadcast URL associated with the user's youtube account.
     * We display a link to the configuration page, the broadcast has already been set up.
     */
    if (broadcastUrl) {
        return (
            <RouterLink
                to={`/app/show/${showId}/settings/broadcast/platform/${YOUTUBE}`}
                variant="button-tertiary"
                startIcon={<YoutubeIcon icon={icon({ name: 'youtube', style: 'brands' })} />}
            >
                <FormattedMessage defaultMessage="Configurer" /> YouTube
            </RouterLink>
        );
    }

    /**
     * No URL found, we display a "connect" button.
     */
    return (
        <>
            <BroadcastButton
                platformKey={YOUTUBE}
                handleBroadcastSetting={() => setShowYoutubeLoginModal(true)}
                isLoading={youtubeAccountIsLoading}
                customLabel={<FormattedMessage defaultMessage="Se connecter à Youtube" />}
                isDisabled={!hasYoutubeUserOption}
                tooltip={
                    <FormattedMessage
                        defaultMessage="Pour diffuser votre podcast sur YouTube, vous devez disposer d’une offre {offerIntermediate} ou {offerPremium}."
                        values={{
                            offerIntermediate: (
                                <Text as="span" textTransform="capitalize">
                                    {PRICING.BOOST}
                                </Text>
                            ),
                            offerPremium: (
                                <Text as="span" textTransform="capitalize">
                                    {PRICING.SUPERSONIC}
                                </Text>
                            ),
                        }}
                    />
                }
            />
            <BroadcastYoutubeLoginModal
                isOpen={showYoutubeLoginModal}
                onClose={() => setShowYoutubeLoginModal(false)}
                onSuccess={() => {
                    setShowYoutubeLoginModal(false);
                    window.location.reload();
                }}
            />
        </>
    );
};

const YoutubeIcon = styled(FontAwesomeIcon)`
    color: var(--youtube);
    font-size: 1.25rem;
`;

export default YoutubeBroadcastButton;
