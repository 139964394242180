import { FormattedMessage } from 'react-intl';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import { connect } from '@/components/legacy/connect';
import styled from 'styled-components';

const enhance = connect(({ subscriptionStore }) => ({
    onResume: subscriptionStore.subscription.resume,
}));

function TopbarWarningBand({ onResume }) {
    return (
        <TopbarWarningBandWrapper>
            <DeprecatedText color="white">
                <FormattedMessage defaultMessage="Votre abonnement est résilié. Pour profiter à nouveau des services d’Ausha, veuillez le réactiver." />
            </DeprecatedText>
            <DeprecatedText
                disabled={onResume.pending}
                ml={20}
                weight="semibold"
                as="span"
                color="white"
                onClick={onResume}
            >
                <FormattedMessage defaultMessage="Réactiver mon abonnement" />
            </DeprecatedText>
        </TopbarWarningBandWrapper>
    );
}

const TopbarWarningBandWrapper = styled.div`
    height: 2rem;
    background: var(--info);
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default enhance(TopbarWarningBand);
