import { computed, runInAction } from 'mobx';
import Store from '../Store';
import { apiCall, apiFetch } from '@/components/legacy/api';
import {
    fetchCampaignSourceDevices,
    fetchSourceDevices,
    fetchCampaignSourcesCsv,
    fetchShowSourcesCsv,
    fetchCampaignSourcePlatforms,
    fetchShowSourcePlatforms,
    fetchShowSourcePlatformsLeaderboard,
    fetchCampaignSourceOS,
    fetchShowSourceOS,
    fetchShowSourceBrowsersLeaderboard,
    fetchCampaignSourceBrowsersLeaderboard,
    fetchCampaignSourceBrowsers,
    fetchShowSourceBrowsers,
    fetchBrowsersExportCsv,
} from '@/api';
import DeviceModel from '../../models/DeviceModel';
import { download, parseIntArray } from '@/helpers';
import SourceCategoryModel from '../../models/SourceCategoryModel';
import PaginationModel from '../../models/PaginationModel';
import SystemModel from '../../models/SystemModel';
import BrowserModel from '../../models/BrowserModel';

class SourcesStatsStore extends Store {
    static observables = {
        // DEVICES AVERAGES
        devices: [],
        // OPERATING SYSTEMS
        browsers: [],
        browsersPagination: [],
        browsersTop: [],
        operatingSystems: [],
        // PIE GRAPH
        platforms: [],
        // RANKING
        platformsTop: [],
        platformsPagination: {},
    };

    // HEADER DEVICES
    @apiFetch
    async fetchSourceDevices(showId, itemType, itemId, query) {
        const { podcasts, ...rest } = query;

        let response;
        switch (itemType) {
            case 'campaign':
                response = await fetchCampaignSourceDevices(itemId, {
                    ...query,
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                });
                break;
            default:
                response = await fetchSourceDevices(showId, {
                    ...rest,
                    podcasts: parseIntArray(podcasts),
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                });
        }
        const { data } = response;
        runInAction(() => {
            this.devices = data.map((device) => new DeviceModel(this, device));
        });
    }

    // PIE GRAPHICS
    @apiFetch
    async fetchSourcePlatforms(showId, itemType, itemId, query) {
        const { podcasts, ...rest } = query;

        let response;
        switch (itemType) {
            case 'campaign':
                response = await fetchCampaignSourcePlatforms(itemId, {
                    ...query,
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                });
                break;
            default:
                response = await fetchShowSourcePlatforms(showId, {
                    ...rest,
                    podcasts: parseIntArray(podcasts),
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                });
        }

        const { data, pagination } = response;
        runInAction(() => {
            this.platforms = data.map((category) => new SourceCategoryModel(this, category));
            this.platformsPagination = new PaginationModel(this, pagination);
        });
    }

    // GETTER

    @computed
    get sourceStatsHasData() {
        return {
            operatingSystemsHasData: this.operatingSystems.length > 0,
        };
    }

    // OPERATING SYSTEMS
    // FETCH
    @apiFetch
    async fetchSystems(showId, itemType, itemId, query) {
        const response =
            itemType === 'campaign'
                ? await fetchCampaignSourceOS(itemId, { ...query })
                : await fetchShowSourceOS(showId, { ...query });

        const { data } = response;
        try {
            runInAction(() => {
                this.operatingSystems = data.map((system) => new SystemModel(this, system));
            });
        } catch (e) {
            this.operatingSystems = [];
        }
    }

    @apiFetch
    async fetchBrowsersLeaderboard(showId, itemType, itemId, query) {
        const response =
            itemType === 'campaign'
                ? await fetchCampaignSourceBrowsersLeaderboard(itemId, { ...query })
                : await fetchShowSourceBrowsersLeaderboard(showId, { ...query });

        try {
            const { data } = response;
            runInAction(() => {
                this.browsersTop = data.map((browser) => new BrowserModel(this, browser));
            });
        } catch (e) {
            runInAction(() => {
                this.browsersTop = [];
            });
        }
    }

    @apiFetch
    async fetchBrowsers(showId, itemType, itemId, query) {
        const response =
            itemType === 'campaign'
                ? await fetchCampaignSourceBrowsers(itemId, { ...query })
                : await fetchShowSourceBrowsers(showId, { ...query });

        try {
            const { data, pagination } = response;
            runInAction(() => {
                this.browsers = data.map((browser) => new BrowserModel(this, browser));
                this.browsersPagination = new PaginationModel(this, pagination);
            });
        } catch (e) {
            runInAction(() => {
                this.browsers = [];
            });
        }
    }

    @apiFetch
    async fetchBrowsersExportCsv(showId, start, end) {
        const { axios, ...data } = await fetchBrowsersExportCsv(showId, { start, end });
        const filename = axios.headers['content-disposition'].match(/filename="(.+)"/)[1];
        download(Object.values(data).join(''), filename, 'txt');
    }

    // RANKING TABLE
    @apiFetch
    async fetchSourcePlatformsLeaderboard(showId, itemType, itemId, query) {
        const { podcasts, ...rest } = query;

        let response;
        switch (itemType) {
            case 'campaign':
                response = null;
                break;
            default:
                response = await fetchShowSourcePlatformsLeaderboard(showId, {
                    ...rest,
                    podcasts: parseIntArray(podcasts),
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                });
        }

        const { data } = response;
        runInAction(() => {
            this.platformsTop = data.map((category) => new SourceCategoryModel(this, category));
        });
    }

    @apiCall
    async downloadSourcesCsv(showId, itemType, itemId, query) {
        const { podcasts, ...rest } = query;

        let response;
        switch (itemType) {
            case 'campaign':
                response = await fetchCampaignSourcesCsv(itemId, { ...query });
                break;
            default:
                response = await fetchShowSourcesCsv(showId, {
                    ...rest,
                    podcasts: parseIntArray(podcasts),
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                });
        }

        const { axios, ...data } = response;
        const filename = axios.headers['content-disposition'].match(/filename="(.+)"/)[1];
        download(Object.values(data).join(''), filename, 'txt');
    }
}

export default SourcesStatsStore;
