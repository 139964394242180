import { useMutation } from '@tanstack/react-query';
import { addCampaign } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import { decamelizeKeys } from 'humps';
import { Campaign } from './useCampaignsQuery';

interface MutationFnProps {
    showId: string | number;
    campaign: any; // TODO: use CampaignFormSchema
}

const mutationFn = async ({ showId, campaign }: MutationFnProps): Promise<Campaign> => {
    const { data } = await addCampaign(showId, decamelizeKeys(campaign));

    return camelcaseKeys(data as any, { deep: true });
};

const addCampaignMutation = () => {
    return useMutation({
        mutationFn,
        onSuccess: () => {
            // Query invalidation is done in the saveCampaignMutation
        },
    });
};

export default addCampaignMutation;
