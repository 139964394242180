import Model from './Model';

class PeakTimePopularDaysModel extends Model {
    static attributes = {
        weekDay: 'week_day',
        downloads: 'downloads',
    };
}

export default PeakTimePopularDaysModel;
