import { useState } from 'react';
import Button from '@/components/Button';
import Text from '@/components/ui/atoms/Text';
import Stack from '@/components/ui/layout/Stack';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import styled from 'styled-components';
import BenefitsModal from './BenefitsModal';
import useSubscriptionQuery from '@/queries/subscription/useSubscriptionQuery.hook';
import { PRICING } from '@/shared/config/pricing';
import ExternalLink from '@/components/Link/ExternalLink';
import SubscribeToPsoModal from './SubscribeToPsoModal';

const NotActive = () => {
    const subscription = useSubscriptionQuery();
    const [isBenefitsModalOpen, setIsBenefitsModalOpen] = useState(false);
    const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);
    // TODO: Replace with dynamic prices
    const yearlyPriceInt = 10;
    const monthlyPriceInt = 12;
    // TODO: Use currency from context when available
    const currency = 'usd';
    const isPro = subscription.data?.pricing === PRICING.ENTERPRISE;

    return (
        <>
            <Wrapper>
                <Left>
                    <TitleWrapper>
                        <Icon icon={icon({ name: 'magnifying-glass', style: 'duotone' })} />
                        <Text variant="headingM" fontWeight="--fw-bold">
                            <FormattedMessage defaultMessage="PSO Control Panel" />
                        </Text>
                    </TitleWrapper>
                    <BulletList>
                        <Bullet>
                            <PlusIcon icon={icon({ name: 'plus', style: 'solid' })} />
                            <Text>
                                <FormattedMessage
                                    defaultMessage="<b>Boostez votre visibilité sur Apple Podcasts & Spotify</b> – Optimisez vos titres, descriptions et tags pour être facilement trouvé par des auditeurs."
                                    values={{
                                        b: (chunks: string) => (
                                            <Text as="span" fontWeight="--fw-semibold">
                                                {chunks}
                                            </Text>
                                        ),
                                    }}
                                />
                            </Text>
                        </Bullet>
                        <Bullet>
                            <PlusIcon icon={icon({ name: 'plus', style: 'solid' })} />
                            <Text>
                                <FormattedMessage
                                    defaultMessage="<b>Identifiez et benchmarkez votre position</b> : – Suivez vos classements en temps réel et comparez-vous à la concurrence."
                                    values={{
                                        b: (chunks: string) => (
                                            <Text as="span" fontWeight="--fw-semibold">
                                                {chunks}
                                            </Text>
                                        ),
                                    }}
                                />
                            </Text>
                        </Bullet>
                        <Bullet>
                            <PlusIcon icon={icon({ name: 'plus', style: 'solid' })} />
                            <Text>
                                <FormattedMessage
                                    defaultMessage="<b>Décelez de nouvelles opportunités de croissance</b> – L’IA vous aide à identifier de nouveaux mot-clés et accédez aux volumes de recherche pour maximiser votre visibilité."
                                    values={{
                                        b: (chunks: string) => (
                                            <Text as="span" fontWeight="--fw-semibold">
                                                {chunks}
                                            </Text>
                                        ),
                                    }}
                                />
                            </Text>
                        </Bullet>
                        <MoreButton
                            variant="link-primary"
                            onPress={() => setIsBenefitsModalOpen(true)}
                        >
                            <FormattedMessage defaultMessage="Et plus encore" />
                        </MoreButton>
                    </BulletList>
                </Left>
                <Right>
                    <Stack $gap="0.25rem">
                        <Text variant="headingL" fontWeight="--fw-bold">
                            <FormattedMessage
                                defaultMessage="{amount} /mois"
                                values={{
                                    amount: (
                                        <FormattedNumber
                                            value={yearlyPriceInt}
                                            style="currency"
                                            currency={currency}
                                            currencyDisplay="narrowSymbol"
                                            maximumFractionDigits={yearlyPriceInt % 1 === 0 ? 0 : 2}
                                        />
                                    ),
                                }}
                            />
                        </Text>
                        <Text color="--neutral500">
                            <FormattedMessage
                                defaultMessage="Facturation annuelle ou {amount} par mois."
                                values={{
                                    amount: (
                                        <FormattedNumber
                                            value={monthlyPriceInt}
                                            style="currency"
                                            currency={currency}
                                            currencyDisplay="narrowSymbol"
                                            maximumFractionDigits={
                                                monthlyPriceInt % 1 === 0 ? 0 : 2
                                            }
                                        />
                                    ),
                                }}
                            />
                        </Text>
                    </Stack>
                    {isPro ? (
                        <ExternalLink
                            href="mailto:sales@ausha.co"
                            variant="button-primary"
                            startIcon={
                                <ButtonIcon icon={icon({ name: 'envelope', style: 'solid' })} />
                            }
                        >
                            <FormattedMessage defaultMessage="Nous contacter" />
                        </ExternalLink>
                    ) : (
                        <Button
                            onPress={() => setIsSubscriptionModalOpen(true)}
                            variant="primary"
                            startIcon={
                                <ButtonIcon
                                    icon={icon({ name: 'rocket-launch', style: 'solid' })}
                                />
                            }
                        >
                            <FormattedMessage defaultMessage="Souscrire au PSO" />
                        </Button>
                    )}
                </Right>
            </Wrapper>
            <BenefitsModal isOpen={isBenefitsModalOpen} onOpenChange={setIsBenefitsModalOpen} />
            <SubscribeToPsoModal
                isOpen={isSubscriptionModalOpen}
                onOpenChange={setIsSubscriptionModalOpen}
            />
        </>
    );
};

const Icon = styled(FontAwesomeIcon)`
    width: 1.25rem;
    height: 1.25rem;
    color: var(--primary);
`;
const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.75rem;
`;
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
    }
`;
const Left = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-grow: 1;
    }
`;
const Right = styled.div`
    padding: 1.5rem;
    border-radius: var(--r-m);
    background-color: var(--primary50);
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    min-width: 20rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-shrink: 0;
    }
`;
const PlusIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    padding-top: 0.25rem;
`;
const BulletList = styled.ul`
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
`;
const Bullet = styled.li`
    display: flex;
    align-items: flex-start;
    column-gap: 0.5rem;
`;
const MoreButton = styled(Button)`
    text-decoration: underline;
    padding-inline: 1.25rem 0;
    padding-block: 0 0;
    align-self: flex-start;
    font-weight: var(--fw-semibold);
`;
const ButtonIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;

export default NotActive;
