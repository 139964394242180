import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateEmailSettingsNotification } from '@/api';
import { decamelizeKeys } from 'humps';
import camelcaseKeys from 'camelcase-keys';
import emailNotificationSettingsKeys from './emailNotificationSettingsKeys';

export interface mutationFnProps {
    scheduledPodcast: boolean;
    youtubeGenerated: boolean;
}

const mutationFn = async (formData: mutationFnProps) => {
    const { data } = await updateEmailSettingsNotification(decamelizeKeys(formData));

    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const useUpdateEmailNotificationSettingsMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn,
        onSuccess: () => {
            queryClient.invalidateQueries(emailNotificationSettingsKeys.detail());
        },
    });
};

export default useUpdateEmailNotificationSettingsMutation;
