import { useRef, useState } from 'react';
import Cluster from '@ui/layout/Cluster';
import { FormattedMessage } from 'react-intl';
import { useButton } from 'react-aria';
import Text from '@ui/atoms/Text';
import styled from 'styled-components';

const HashtagButton = styled.button`
    padding: 0.0625rem 0.4375rem;
    border-radius: var(--r-xs);
    background: ${(props) => (props.isPressed ? 'var(--white50)' : 'var(--white)')};
    border: 1px solid transparent;
    transition: background 0.25s ease-in-out, border-color 0.25s ease-in-out;
    cursor: pointer;

    &:focus,
    &:hover {
        border-color: var(--neutral400);
    }
`;

const Hashtag = (props) => {
    const ref = useRef();
    const { buttonProps, isPressed } = useButton(props, ref);
    const { children } = props;

    return (
        <HashtagButton {...buttonProps} isPressed={isPressed}>
            <Text as="span">{children}</Text>
        </HashtagButton>
    );
};

const HashtagsBarContainer = styled.div`
    background: var(--neutral50);
    border-radius: var(--r-xs);
    padding: 0.5rem 0.75rem;
`;

const HashtagsBar = ({ editor, availableHashtags = [] }) => {
    const [hashtags, setHashtags] = useState(availableHashtags);

    if (!editor) {
        return null;
    }

    const addHashtag = (newHashtag) => {
        editor.chain().focus().insertContent(`${newHashtag} `).run();
        setHashtags((previousHashtags) =>
            previousHashtags.filter((hashtag) => hashtag !== newHashtag),
        );
    };

    return (
        <HashtagsBarContainer>
            <Cluster $gap="1rem" $rowGap="0.5rem" $align="center">
                <Text color="--neutral500" fontWeight="--fw-semibold">
                    <FormattedMessage defaultMessage="Hashtags suggérés" />
                </Text>
                <Cluster $gap="0.25rem">
                    {/* Display only 5 hashtags at a time */}
                    {hashtags.slice(0, 5).map((hashtag) => (
                        <Hashtag
                            key={hashtag}
                            onPress={() => addHashtag(hashtag)}
                            isDisabled={!editor.isEditable}
                        >
                            {hashtag}
                        </Hashtag>
                    ))}
                </Cluster>
            </Cluster>
        </HashtagsBarContainer>
    );
};

export default HashtagsBar;
