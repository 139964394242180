import PropTypes from 'prop-types';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import DeprecatedText from '../../ui/atoms/DeprecatedText';

function Ranking({ value, variation, min, max, weight, size, ...props }) {
    const inRanking = min <= value && value <= max;

    if (!inRanking) {
        return <DeprecatedText size={size} weight={weight} {...props}>{`${max}+`}</DeprecatedText>;
    }

    return (
        <DeprecatedText size={size} weight={weight} {...props}>
            <FormattedNumber value={value} />
            <DeprecatedText
                as={size === 'veryBig' ? 'sup' : 'span'}
                weight={size === 'veryBig' ? 'normal' : weight}
            >
                <FormattedMessage
                    defaultMessage={`{value, plural, one {er} other {ème}}`}
                    values={{ value }}
                />
            </DeprecatedText>
            {!!variation && (
                <DeprecatedText
                    as="span"
                    ml={5}
                    color={variation > 0 ? 'online' : 'error'}
                    weight={weight}
                >
                    {`${variation > 0 ? '+' : ''}${variation}`}
                </DeprecatedText>
            )}
        </DeprecatedText>
    );
}

Ranking.propTypes = {
    value: PropTypes.number.isRequired,
    variation: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    weight: PropTypes.string,
    size: PropTypes.string,
};

Ranking.defaultProps = {
    variation: 0,
    min: 1,
    max: 200,
    weight: 'semibold',
    size: 'veryBig',
};

export default Ranking;
