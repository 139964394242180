import { useState } from 'react';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import OptionButton from './OptionButton';
import { DialogTrigger, Popover } from 'react-aria-components';

const EmojiPickerButton = ({ editor, isDisabled }) => {
    const [isActive, setIsActive] = useState(false);
    const onSelectEmoji = (emoji) => {
        editor.chain().focus().insertContent(emoji.native).run();
    };

    return (
        <DialogTrigger isDismissable type="popover" onOpenChange={setIsActive}>
            <OptionButton
                variant="ghost"
                icon={
                    <OptionIcon
                        icon={icon({
                            name: 'face-grin',
                            style: 'regular',
                        })}
                    />
                }
                $isActive={isActive}
                isDisabled={isDisabled || !editor.isEditable}
            />
            <Popover placement="end top" isDismissable>
                <Picker set="apple" onSelect={onSelectEmoji} perLine={7} emoji="rocket" title="" />
            </Popover>
        </DialogTrigger>
    );
};

const OptionIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;

export default EmojiPickerButton;
