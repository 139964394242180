import { useState } from 'react';
import PropTypes from 'prop-types';
import TunnelStepper from '../TunnelStepper';
import PsoLanding from '@public/images/empty-state/illustration/pso-landing.png';
import Stores from './Stores';
import useSaveStoresMutation from '@queries/pso/useSaveStoresMutation.hook';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { useModalToastQueue } from '@/utils/hooks/useModalToastQueue.hook';

interface StoreConfigurationProps {
    onStoresSubmitted: () => void;
}

const StoreConfiguration = ({ onStoresSubmitted }: StoreConfigurationProps) => {
    const { showId }: { showId: string } = useParams();
    const saveStores = useSaveStoresMutation();
    const [stores, setStores] = useState([]);
    const [submitIsLoading, setSubmitIsLoading] = useState(false);
    const toast = useModalToastQueue();

    const submitStores = () => {
        saveStores.mutate(
            {
                showId: parseInt(showId),
                stores: stores.filter((s) => ![null, undefined].includes(s)),
            },
            {
                onSuccess: () => {
                    onStoresSubmitted();
                },
                onError: () => {
                    toast.alert(
                        <FormattedMessage defaultMessage="Une erreur s'est produite lors de l'enregistrement des pays, veuillez réessayer ultérieurement." />,
                    );
                },
                onSettled: () => {
                    setSubmitIsLoading(false);
                },
            },
        );
    };

    return (
        <StoreConfigurationWrapper>
            <Left>
                <TunnelStepper currentStep={2} stepCount={3} />
                <Stores
                    stores={stores}
                    setStores={setStores}
                    submitStores={submitStores}
                    submitIsLoading={submitIsLoading}
                    setSubmitIsLoading={(v) => setSubmitIsLoading(v)}
                />
            </Left>
            <IllustrationBlock>
                <IllustrationImage src={PsoLanding} />
            </IllustrationBlock>
        </StoreConfigurationWrapper>
    );
};

const StoreConfigurationWrapper = styled.div`
    min-height: 27rem;
    display: flex;
    gap: 2rem;
    & > * {
        flex: 1;
        align-self: stretch;
    }
`;
const Left = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1rem;
`;
const IllustrationBlock = styled.div`
    border-radius: var(--r-l);
    display: flex;
    max-width: 560px;
    min-width: 50%;
    margin-inline: auto;
    background: var(--gradient-dusk);
    padding-block: 2rem;
    padding-left: 2rem;
    align-items: center;
    justify-content: flex-end;
`;
const IllustrationImage = styled.img`
    border-radius: var(--r-l) 0 0 var(--r-l);
    width: calc(100% - 2rem);
`;

StoreConfiguration.propTypes = {
    isEditMode: PropTypes.bool,
    onStoresSubmitted: PropTypes.func.isRequired,
};

export default StoreConfiguration;
