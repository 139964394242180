import { useState } from 'react';
import Button from '@/components/Button';
import IconButton from '@/components/IconButton';
import Modal from '@/components/ui/atoms/Modal';
import Text from '@/components/ui/atoms/Text';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Stack from '@/components/ui/layout/Stack';
import Alert from '@/components/ui/atoms/Alert';
import SelectEpisode from './SelectEpisode';
import type { ListBoxItem } from '@/components/ui/atoms/ListBoxItem';

interface NewClipModalProps {
    isOpen: boolean;
    onOpenChange: (open: boolean) => void;
    onSubmit: (episodeId: number) => void;
    isLoading: boolean;
}

const NewClipModal = ({ isOpen, onOpenChange, onSubmit, isLoading }: NewClipModalProps) => {
    const [selectedEpisode, setSelectedEpisode] = useState<ListBoxItem | null>(null);

    const handleSubmit = () => {
        if (!selectedEpisode || !selectedEpisode.hasAudioFile) return;
        onSubmit(Number(selectedEpisode.id));
        setSelectedEpisode(null);
    };

    const handleChange = (episode: ListBoxItem) => {
        setSelectedEpisode(episode);
    };
    const handleClose = () => onOpenChange(false);

    return (
        <ModalStyled isOpen={isOpen} onOpenChange={onOpenChange} size="medium">
            <Header>
                <Text variant="headingM" fontWeight="--fw-bold">
                    <FormattedMessage defaultMessage="Nouveau clip" />
                </Text>
                <IconButton
                    icon={<CloseIcon icon={icon({ name: 'xmark-large', style: 'solid' })} />}
                    onPress={handleClose}
                    variant="ghost"
                    aria-label="Close modal"
                    style={{ padding: 0 }}
                />
            </Header>
            <Stack $gap="1rem">
                <SelectEpisode onChange={handleChange} selectedEpisode={selectedEpisode} />
                {selectedEpisode && !selectedEpisode.hasAudioFile && (
                    <Alert
                        variant="warning"
                        defaultIcon
                        description={
                            <Text color="--neutral500">
                                <FormattedMessage defaultMessage="Ajoutez un fichier audio à votre épisode pour pouvoir lui associer un clip vidéo" />
                            </Text>
                        }
                    >
                        <Text fontWeight="--fw-semibold">
                            <FormattedMessage defaultMessage="Un fichier audio est requis pour la création d'un clip vidéo" />
                        </Text>
                    </Alert>
                )}
            </Stack>
            <ActionWrapper>
                <Button
                    isDisabled={!selectedEpisode || !selectedEpisode.hasAudioFile}
                    onPress={handleSubmit}
                    isLoading={isLoading}
                >
                    <FormattedMessage defaultMessage="Continuer" />
                </Button>
                <CancelButton variant="ghost" onPress={handleClose}>
                    <FormattedMessage defaultMessage="Annuler" />
                </CancelButton>
            </ActionWrapper>
        </ModalStyled>
    );
};

const ModalStyled = styled(Modal)`
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 2rem;
`;
const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 1rem;
`;
const CloseIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--neutral500);
`;
const ActionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 1rem;

    ${({ theme }) => theme.mediaQueries.tabletAndUp} {
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        & > :first-child {
            order: 1;
        }
    }
`;
const CancelButton = styled(Button)`
    padding: 0;
    color: var(--neutral500);
`;

export default NewClipModal;
