import styled, { css, keyframes } from 'styled-components';

const Skeleton = () => {
    return (
        <Wrapper>
            <Title />
            <Providers>
                <Block />
                <Block />
                <Block />
                <Block />
            </Providers>
        </Wrapper>
    );
};
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    width: 100%;
`;
const Providers = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    width: 100%;
`;
const shimmer = keyframes`
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
`;
const shimmering = css`
    background: var(--neutral50);
    background-image: linear-gradient(
        to right,
        var(--neutral50) 0%,
        #edeef1 20%,
        var(--neutral50) 40%,
        var(--neutral50) 100%
    );
    background-repeat: no-repeat;
    background-size: 50rem 6.5rem;
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${shimmer};
    animation-timing-function: linear;
`;
const Block = styled.div`
    border-radius: var(--r-s);
    background-color: var(--neutral50);
    width: 100%;
    height: 6.25rem;
    ${shimmering};
`;
const Title = styled.div`
    border-radius: var(--r-full);
    background-color: var(--neutral100);
    width: 75%;
    height: 1rem;
    ${shimmering};
`;

export default Skeleton;
