import { observer } from 'mobx-react';
import useUserStore from '@/utils/hooks/useUserStore';
import useShowStore from '@/utils/hooks/useShowStore';
import useSubscriptionStore from '@/utils/hooks/useSubscriptionStore';
import { useParams } from 'react-router-dom';

const HideFor = ({
    roles = [],
    pricing = [],
    blockedSubscription,
    userOptions = [],
    showStatus = [],
    condition = true,
    lockedMessage,
    children,
}) => {
    const { showId } = useParams();
    const show = showId && useShowStore().byId(showId);
    const { subscription } = useSubscriptionStore();
    const { user } = useUserStore();

    const allowedByUserOptions = show
        ? show.allows(userOptions)
        : user
        ? user.can(userOptions)
        : undefined;

    const hidden =
        (show && roles.includes(show.userRole)) ||
        (user && subscription && pricing.includes(subscription.pricing)) ||
        (userOptions.length > 0 && !allowedByUserOptions) ||
        (show && showStatus.includes(show.showStatus)) ||
        (blockedSubscription && subscription && subscription.isBlockedForShow(showId)) ||
        !condition;

    if (hidden && lockedMessage) return lockedMessage;
    if (hidden) return null;
    if (children) return children;
    return null;
};

export default observer(HideFor);
