import { useParams } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { MenuTrigger, Popover } from 'react-aria-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import { USER_ROLES } from '@/shared/config/constants';
import { STATUS_SHOWS } from '@/shared/config/statusShows';
import type { Episode } from '@/queries/episode/useEpisodesQuery.hook';
import useDuplicateEpisodeMutation from '@/queries/episode/useDuplicateEpisodeMutation.hook';
import useDeleteEpisodeMutation from '@/queries/episode/useDeleteEpisodeMutation.hook';
import MenuDropdown from '@/components/ui/atoms/MenuDropdown';
import MenuDropdownItem from '@/components/ui/atoms/MenuDropdown/MenuDropdownItem';
import IconButton from '@/components/ui/atoms/IconButton';
import DeleteEpisodeModal from './DeleteEpisodeModal';
import { useState } from 'react';
import { useBodyToastQueue } from '@/shared/hooks/useBodyToastQueue.hook';

interface ContextualMenuProps {
    episode: Episode;
}

const ContextualMenu = ({ episode }: ContextualMenuProps) => {
    const { showId } = useParams<{ showId: string }>();
    const { data: show } = useShowQuery(showId);
    const duplicateEpisode = useDuplicateEpisodeMutation();
    const deleteEpisode = useDeleteEpisodeMutation();
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const toast = useBodyToastQueue();
    const userHasWriteAccess =
        show &&
        ![USER_ROLES.VIEWER, USER_ROLES.EDITOR].includes(show.userRole as 'viewer' | 'editor');
    const notViewerNotArchived =
        show &&
        !['viewer'].includes(show.userRole) &&
        ![STATUS_SHOWS.ARCHIVED_SHOWS].includes(
            show.showStatus as typeof STATUS_SHOWS.ARCHIVED_SHOWS,
        );

    const handleDeleteEpisode = () => {
        if (!episode?.id) return;
        deleteEpisode.mutate(
            { episodeId: `${episode.id}` },
            {
                onSuccess: () => {
                    setDeleteModalOpen(false);
                },
                onError: () => {
                    toast.alert();
                },
            },
        );
    };

    return (
        <>
            <MenuTrigger>
                <TriggerButton
                    aria-label="Menu"
                    variant="ghost"
                    icon={<EllipsisIcon icon={icon({ name: 'ellipsis', style: 'solid' })} />}
                />
                <PopoverStyled placement="bottom end">
                    <MenuDropdownStyled>
                        {notViewerNotArchived && (
                            <MenuDropdownItem
                                onAction={() =>
                                    episode?.id &&
                                    duplicateEpisode.mutate({ episodeId: `${episode.id}` })
                                }
                            >
                                <MenuItemIcon icon={icon({ name: 'copy', style: 'solid' })} />
                                <FormattedMessage defaultMessage="Dupliquer" />
                            </MenuDropdownItem>
                        )}
                        {userHasWriteAccess && (
                            <MenuDropdownItem
                                onAction={() => setDeleteModalOpen(true)}
                                color="--alert"
                            >
                                <MenuItemIcon icon={icon({ name: 'trash', style: 'solid' })} />
                                <FormattedMessage defaultMessage="Supprimer" />
                            </MenuDropdownItem>
                        )}
                    </MenuDropdownStyled>
                </PopoverStyled>
            </MenuTrigger>
            <DeleteEpisodeModal
                isOpen={isDeleteModalOpen}
                onOpenChange={setDeleteModalOpen}
                isLoading={deleteEpisode.isLoading}
                onDelete={handleDeleteEpisode}
            />
        </>
    );
};

const TriggerButton = styled(IconButton)`
    padding: 0;
    transition-duration: 0.2s;
    color: var(--neutral500);

    &:hover {
        color: var(--neutral600);
    }
`;
const EllipsisIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
`;
const MenuItemIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const PopoverStyled = styled(Popover)`
    width: 100%;
    max-width: fit-content;
    min-width: 10rem;
    z-index: 1 !important;
`;
const MenuDropdownStyled = styled(MenuDropdown)`
    max-width: fit-content;
    min-width: 10rem;
`;

export default ContextualMenu;
