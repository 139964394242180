import { useEffect, useState } from 'react';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import EpisodeAIPanelField from './EpisodeAIPanelField';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import {
    EPISODE_AI_LOADING_ACTIONS,
    useEpisodeAILoading,
} from '@/components/NonHostingEpisodeForm/contexts/NonHostingEpisodeAILoadingContext';
import { useEpisodeAISettings } from '@/components/NonHostingEpisodeForm/contexts/NonHostingEpisodeAISettingsContext';
import useAISuggestionsError from '../utils/useAISuggestionsError.hook';
import EpisodeAIOverwriteWarningModal from '../EpisodeAIOverwriteWarningModal';
import { useFormContext } from 'react-hook-form';
import { NonHostingEpisodeFormSchema } from '../../useNonHostingEpisodeFormSchema.hook';
import useAINewsletterTitleSuggestionsQuery from '@/queries/ai/useAINewsletterTitleSuggestionQuery.hook';
import useAINewsletterMessageSuggestionsQuery from '@/queries/ai/useAINewsletterMessageSuggestionQuery.hook';

interface EpisodeAINewsletterFieldProps {
    generateButtonDataTestId: string;
}

const EpisodeAINewsletterField = ({ generateButtonDataTestId }: EpisodeAINewsletterFieldProps) => {
    const { episodeId } = useParams<{ episodeId: string }>();
    const { watch } = useFormContext<NonHostingEpisodeFormSchema>();
    const [settings] = useEpisodeAISettings();
    const newsletterSettings = settings.get('newsletter') || {
        customPrompt: '',
        saveAsDefault: false,
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const newsletterTitleSuggestionQuery = useAINewsletterTitleSuggestionsQuery({
        episodeId,
        settings: newsletterSettings,
        delay: 2000,
    });
    const newsletterMessageSuggestionQuery = useAINewsletterMessageSuggestionsQuery({
        episodeId,
        settings: newsletterSettings,
        delay: 2000,
    });
    const [isGenerated, setIsGenerated] = useState(
        localStorage.getItem(`episode-ai-${episodeId}-generated-newsletter`) === 'true',
    );
    const [state, dispatch] = useEpisodeAILoading();
    const isLoading = state.get('newsletter-title') || state.get('newsletter-message') || false;
    const message = watch('newsletter.message');
    const isHtmlMessageEmpty = message && /^(<p>(<br>)?<\/p>)+$/.test(message);

    useAISuggestionsError({
        isError: newsletterTitleSuggestionQuery.isError,
        episodeId,
        type: 'newsletter-title',
    });
    useAISuggestionsError({
        isError: newsletterMessageSuggestionQuery.isError,
        episodeId,
        type: 'newsletter-message',
    });

    // Once the newsletter suggestions are fetched, set the generated flag to true
    useEffect(() => {
        if (
            newsletterTitleSuggestionQuery.data &&
            newsletterMessageSuggestionQuery.data &&
            !newsletterTitleSuggestionQuery.isFetching &&
            !newsletterMessageSuggestionQuery.isFetching
        ) {
            localStorage.setItem(`episode-ai-${episodeId}-generated-newsletter`, 'true');
            setIsGenerated(true);
        }
    }, [newsletterTitleSuggestionQuery.data, newsletterTitleSuggestionQuery.isFetching]);

    // Once the newsletter title suggestions are fetched, set the loading state to false
    // Newsletter message loading state is handled in EpisodeAINewsletterMessageSuggestionPanel
    useEffect(() => {
        if (newsletterTitleSuggestionQuery.data && !newsletterTitleSuggestionQuery.isFetching) {
            dispatch({
                type: EPISODE_AI_LOADING_ACTIONS.STOP,
                payload: 'newsletter-title',
            });
        }
    }, [newsletterTitleSuggestionQuery.data, newsletterTitleSuggestionQuery.isFetching]);

    const handleOpenModal = () => {
        if (message && !isHtmlMessageEmpty && message?.length > 0) {
            setIsModalOpen(true);
            return;
        }
        handleGenerate();
    };

    const handleGenerate = () => {
        setIsModalOpen(false);
        dispatch({
            type: EPISODE_AI_LOADING_ACTIONS.START,
            payload: 'newsletter-title',
        });
        newsletterTitleSuggestionQuery.refetch();
        dispatch({
            type: EPISODE_AI_LOADING_ACTIONS.START,
            payload: 'newsletter-message',
        });
        newsletterMessageSuggestionQuery.refetch();
    };

    return (
        <>
            <EpisodeAIPanelField
                data-testid={generateButtonDataTestId}
                icon={<Icon icon={icon({ name: 'envelope-open-text', style: 'solid' })} />}
                type="newsletter"
                title={<FormattedMessage defaultMessage="Newsletter" />}
                description={
                    <FormattedMessage defaultMessage="Un e-mail prêt à envoyer pour notifier votre audience" />
                }
                onAction={handleOpenModal}
                isLoading={isLoading}
                isGenerated={isGenerated && !isLoading}
            />
            <EpisodeAIOverwriteWarningModal
                isOpen={isModalOpen}
                onOpenChange={setIsModalOpen}
                onAction={handleGenerate}
                actionTestId="episode-ai-newsletter-overwrite-modal-button"
                message={
                    <FormattedMessage defaultMessage="La génération AI écrasera le contenu actuel" />
                }
            />
        </>
    );
};

const Icon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--primary-magic);
`;

export default EpisodeAINewsletterField;
