import { FormattedMessage } from 'react-intl';
import Button from '@/components/Button';
import Tooltip from '@ui/Tooltip';
import { TooltipTrigger } from 'react-aria-components';
import { useParams } from 'react-router-dom';
import useShowQuery from '@queries/show/useShowQuery.hook';
import type { ListeningPlatform } from '@/shared/config/constants';
import styled from 'styled-components';

interface BroadcastButtonProps {
    platformKey: ListeningPlatform;
    handleBroadcastSetting: () => void;
    tooltip?: React.ReactNode | string;
    isLoading?: boolean;
    customLabel?: React.ReactNode | string;
    isDisabled?: boolean;
}

const BroadcastButton = ({
    platformKey,
    tooltip,
    isLoading,
    handleBroadcastSetting,
    customLabel,
    isDisabled,
}: BroadcastButtonProps) => {
    const { showId } = useParams<{ showId: string }>();
    const { data: show } = useShowQuery(showId);
    const isShowPmp = show?.hostedOnAusha === false;

    return (
        <TooltipTrigger delay={0} closeDelay={100} isDisabled={!tooltip || !isDisabled}>
            <PlatformColoredButton
                $color={`--${platformKey}`}
                isLoading={isLoading}
                // isDisabled={isDisabled} - React Aria does not allow a tooltip to be displayed on a disabled button, so we have to fake the "isDisabled" state of the button.
                $isDisabled={isDisabled}
                onPress={() => {
                    if (isDisabled) return;
                    handleBroadcastSetting();
                }}
            >
                {isShowPmp ? (
                    <FormattedMessage defaultMessage={'Ajouter'} />
                ) : (
                    customLabel ?? <FormattedMessage defaultMessage={'Diffuser'} />
                )}
            </PlatformColoredButton>
            <Tooltip placement="top">{tooltip}</Tooltip>
        </TooltipTrigger>
    );
};

const PlatformColoredButton = styled(Button)<{ $color: string; $isDisabled?: boolean }>`
    transition-duration: 0.2s;
    background-color: ${({ $color }) => `var(${$color})`};
    color: var(--white);
    box-shadow: none;
    &:hover {
        background-color: ${({ $color }) => `var(${$color})`};
        opacity: 0.9;
    }

    &:disabled {
        opacity: 0.4;
    }

    ${({ $isDisabled }) =>
        $isDisabled &&
        `
        opacity: 0.4;
        cursor: not-allowed;

        &:hover {
            opacity: 0.4;
        }
    `}
`;

export default BroadcastButton;
