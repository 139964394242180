import { useState, useEffect } from 'react';
import { useParams, useHistory, Route, Switch, Redirect } from 'react-router';
import PsoLayout from './PsoLayout';
import PsoKeywordTracking from './PsoKeywordTracking';
import PsoOverview from './PsoOverview';
import PsoConfigurationTunnel from './PsoConfigurationTunnel';
import PsoMobileBlockScreen from './PsoMobileBlockScreen';
import usePsoQuery from '@queries/pso/usePsoQuery.hook';
import PsoKeywords from './PsoKeywords';
import { ENABLE_PSO, APPLE_PODCASTS, SPOTIFY } from '@/utils/constants';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import useSubscriptionQuery from '@/queries/subscription/useSubscriptionQuery.hook';
import PsoBlockingScreen from './PsoBlockingScreen';
import PsoLiveSearch from './PsoLiveSearch';
import PsoCompetitors from './PsoCompetitors';
import { STATUS_SHOWS } from '@/utils/statusShows';
import { PRICING } from '@/utils/pricing';
import { USER_ROLES } from '@/utils/constants';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import { useQueryClient } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import dayjs from 'dayjs';
import psoKeys from '@/queries/pso/psoKeys';
import { ENABLE_PSO_LIVE_SEARCH, ENABLE_PSO_COMPETITORS } from '@/utils/constants';
import styled from 'styled-components';

const PsoPage = () => {
    const { showId }: { showId: string } = useParams();
    const history = useHistory();
    const queryClient = useQueryClient();
    const subscription = useSubscriptionQuery();
    const show = useShowQuery(showId);
    const user = useUserQuery();
    const psoConfiguration = usePsoQuery({ showId, enabled: ENABLE_PSO });
    const [currentStore, setCurrentStore] = useState('');
    const [configurationEndLoading, setConfigurationEndLoading] = useState(false);

    useEffect(() => {
        if (psoConfiguration.data) {
            setCurrentStore(
                psoConfiguration.data.stores.data.length > 0 &&
                    psoConfiguration.data.stores.data[0].alpha2Code,
            );
        }
    }, [psoConfiguration?.data]);

    if (
        !ENABLE_PSO ||
        psoConfiguration.isLoading ||
        show.isLoading ||
        show.isError ||
        subscription.isLoading ||
        subscription.isError ||
        (psoConfiguration.isError &&
            (psoConfiguration.error as AxiosError)?.response?.status !== 403 &&
            (psoConfiguration.error as AxiosError)?.response?.data?.message !==
                'Missing user option pso')
    ) {
        return null;
    }

    const hasSpotifyData =
        show.data.listeningLinks.data.findIndex((link) => link.key === SPOTIFY) > -1;
    const hasAppleData =
        show.data.listeningLinks.data.findIndex((link) => link.key === APPLE_PODCASTS) > -1;

    const isGuestShow = show.data.showStatus === STATUS_SHOWS.GUEST_SHOWS;
    const isFreemium = subscription.data.isFreemium;
    const isLaunch = subscription.data.pricing === PRICING.LAUNCH;
    const hasNoSubscription = subscription.data.endsAt && dayjs().isAfter(subscription.data.endsAt);
    const isEnterpriseWithoutPsoOption =
        subscription.data.pricing === PRICING.ENTERPRISE && !user?.data?.options.includes('pso');
    const unsufficientSubscription =
        isFreemium || isLaunch || hasNoSubscription || isEnterpriseWithoutPsoOption;

    /**
     * If we're owner of a show and don't have sufficient subscription, we redirect the user to the blocking screen
     */
    if (!isGuestShow && unsufficientSubscription) {
        return <PsoBlockingScreen />;
    }

    const ownerIsLaunch = show.data.ownerPricing === PRICING.LAUNCH;
    const ownerIsEnterpriseWithoutPsoOption =
        show.data.ownerPricing === PRICING.ENTERPRISE && !show.data.userOptions.includes('pso');
    const ownerHasNoPsoOption = ownerIsLaunch || ownerIsEnterpriseWithoutPsoOption;

    /**
     * If we're guest of a show and owner does not have sufficient subscription, we redirect the user to the blocking screen
     */
    if (isGuestShow && ownerHasNoPsoOption) {
        return <PsoBlockingScreen />;
    }

    const userIsAdminGuest = show?.data?.userRole === USER_ROLES.ADMIN && isGuestShow;
    const userIsOwner = show?.data?.userRole === USER_ROLES.OWNER;
    const userHasWriteAccess = userIsOwner || userIsAdminGuest;

    if (psoConfiguration.isLoading) {
        return <span>loading</span>;
    }

    return (
        <>
            <MobileWrapper>
                <PsoMobileBlockScreen />
            </MobileWrapper>
            <Switch>
                <Route path="/app/show/:showId/pso" exact>
                    <Redirect
                        to={
                            psoConfiguration.data?.fullyConfigured === false ||
                            configurationEndLoading
                                ? `/app/show/${showId}/pso/setup`
                                : `/app/show/${showId}/pso/overview`
                        }
                    />
                </Route>
                <Route path="/app/show/:showId/pso/setup">
                    <PsoConfigurationTunnel
                        keywordsSaved={psoConfiguration.data.keywordsSaved}
                        keywords={psoConfiguration.data.keywords}
                        onRedirectToOverview={() => {
                            queryClient.invalidateQueries({
                                queryKey: psoKeys.globalConfiguration(showId),
                            });
                            history.push(`/app/show/${showId}/pso/overview`);
                        }}
                        setConfigurationEndLoading={(v) => setConfigurationEndLoading(v)}
                        userHasWriteAccess={userHasWriteAccess}
                        currentStore={currentStore}
                        isFullyConfigured={psoConfiguration.data.fullyConfigured}
                    />
                </Route>
                <Route path="/app/show/:showId/pso/overview">
                    <PsoLayout userHasWriteAccess={userHasWriteAccess}>
                        <PsoOverview
                            globalConfiguration={psoConfiguration.data}
                            currentStore={currentStore}
                            setCurrentStore={setCurrentStore}
                            hasSpotifyData={hasSpotifyData}
                            hasAppleData={hasAppleData}
                            userHasWriteAccess={userHasWriteAccess}
                            stores={psoConfiguration.data.stores.data}
                        />
                    </PsoLayout>
                </Route>
                <Route path="/app/show/:showId/pso/details">
                    <PsoLayout userHasWriteAccess={userHasWriteAccess}>
                        <PsoKeywordTracking
                            currentStore={currentStore}
                            setCurrentStore={setCurrentStore}
                            hasSpotifyData={hasSpotifyData}
                            hasAppleData={hasAppleData}
                            userHasWriteAccess={userHasWriteAccess}
                            stores={psoConfiguration.data.stores.data}
                        />
                    </PsoLayout>
                </Route>
                {ENABLE_PSO_COMPETITORS && (
                    <Route path="/app/show/:showId/pso/competitors">
                        <PsoLayout userHasWriteAccess={userHasWriteAccess}>
                            <PsoCompetitors />
                        </PsoLayout>
                    </Route>
                )}
                {ENABLE_PSO_LIVE_SEARCH && (
                    <Route path="/app/show/:showId/pso/livesearch">
                        <PsoLayout userHasWriteAccess={userHasWriteAccess}>
                            <PsoLiveSearch />
                        </PsoLayout>
                    </Route>
                )}
                <Route path="/app/show/:showId/pso/keywords">
                    <PsoLayout userHasWriteAccess={userHasWriteAccess}>
                        <PsoKeywords
                            currentStore={currentStore}
                            existingKeywords={psoConfiguration.data.keywords}
                        />
                    </PsoLayout>
                </Route>
            </Switch>
        </>
    );
};

const MobileWrapper = styled.div`
    display: block;
    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        display: none;
    }
`;

export default PsoPage;
