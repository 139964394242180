const webhookKeys = {
    all: () => [{ entity: 'webhook' }],
    allLists: () => [{ ...webhookKeys.all()[0], scope: 'list' }],
    listByShowId: (showId: string) => [
        {
            ...webhookKeys.allLists()[0],
            showId: Number(showId),
        },
    ],
};

export default webhookKeys;
