import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteNewsletterSubscribers } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import newsletterKeys from '@queries/newsletter/newsletterKeys';

export interface MutationFnProps {
    showId: string;
    subscriberEmail: string;
}

const mutationFn = async ({ showId, subscriberEmail }: MutationFnProps) => {
    const { data } = await deleteNewsletterSubscribers(showId, { email: subscriberEmail });

    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

export default function useDeleteNewsletterSubscriberMutation() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: newsletterKeys.all(),
            });
        },
    });
}
