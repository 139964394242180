import { useAmplitudeLogEvent } from '@/shared/hooks/useAmplitude';
import EpisodesLayout from './EpisodesLayout';
import Playlists from '@/components/Playlists';

const PlaylistsPage = () => {
    useAmplitudeLogEvent('Episodes Playlists Tab Viewed');

    return (
        <EpisodesLayout>
            <Playlists />
        </EpisodesLayout>
    );
};
export default PlaylistsPage;
