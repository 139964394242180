import { FormattedMessage } from 'react-intl';
import { CLIP_FORMATS } from '@/utils/constants';
import Format from './Format';
import Stack from '@ui/layout/Stack';
import Text from '@ui/atoms/Text';
import IconCheckedCircle from '@ui/icons/IconCheckedCircle';

const LandscapeFormat = ({ selected, onSelect, selectedFormatsCount, disabled }) => {
    const formatEnabledProps = !disabled ? { selected, onSelect } : {};

    return (
        <Format type={CLIP_FORMATS.LANDSCAPE} width={572} {...formatEnabledProps}>
            <Stack $gap="0.5rem" $align="center">
                <Text fontWeight="--fw-semibold">
                    <FormattedMessage defaultMessage="Paysage (1.9:1)" />
                </Text>
                {selected ? (
                    <IconCheckedCircle color="--primary" width={24} height={24} />
                ) : (
                    <div style={{ height: '24px' }}>
                        {selectedFormatsCount > 0 && (
                            <Text color="--primary">
                                <FormattedMessage defaultMessage="1 crédit supplémentaire" />
                            </Text>
                        )}
                    </div>
                )}
            </Stack>
        </Format>
    );
};

export default LandscapeFormat;
