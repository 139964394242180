import { computed } from 'mobx';
import Model from './Model';
import { apiCall } from '../components/app/decorators/api';

const linkResolve = (key) => ({
    fromApi: { key, resolve: (x) => x },
});

class ShowLinkModel extends Model {
    static attributes = {
        showId: {
            fromApi: { key: 'show_id', resolve: (x) => x },
            toApi: { key: null },
        },
        site: linkResolve('site'),
        facebook: linkResolve('facebook'),
        twitter: linkResolve('twitter'),
        youtube: linkResolve('youtube'),
        instagram: linkResolve('instagram'),
        linkedin: linkResolve('linkedin'),
        tiktok: linkResolve('tiktok'),
        discord: linkResolve('discord'),
        twitch: linkResolve('twitch'),
        monetization: linkResolve('monetization'),

        itunes: linkResolve('itunes'),
        spotify: linkResolve('spotify'),
        deezer: linkResolve('deezer'),
        tuneIn: linkResolve('tunein'),
        podcastAddict: linkResolve('podcast_addict'),
        pocketCasts: linkResolve('pocket_casts'),
        castbox: linkResolve('castbox'),
        overcast: linkResolve('overcast'),
        castro: linkResolve('castro'),
        amazon: linkResolve('amazon'),
        podchaser: linkResolve('podchaser'),
        listenNotes: linkResolve('listen_notes'),
        podcastIndex: linkResolve('podcast_index'),
        iHeartRadio: linkResolve('i_heart_radio'),
        pandora: linkResolve('pandora'),
        playerFm: linkResolve('player_fm'),
        jioSaavn: linkResolve('jio_saavn'),
        gaana: linkResolve('gaana'),
        soundcloud: linkResolve('soundcloud'),
        radioPublic: linkResolve('radio_public'),
    };

    @computed
    get show() {
        return this.store.byId(this.showId);
    }

    @apiCall
    update(formData) {
        return this.store.updateListeningLinks(this.show, formData);
    }
}

export default ShowLinkModel;
