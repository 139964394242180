import { useRef } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import useQuery from '@/shared/hooks/useQuery';
import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { SelectController } from '@ui/atoms/Select/Select.controller';
import { ComboBoxController } from '@ui/atoms/ComboBox/ComboBox.controller';
import ListBoxItem from '@ui/atoms/ListBoxItem';
import useCompetitorsFormSchema, { CompetitorsForm } from './useCompetitorsFormSchema.hook';
// @ts-ignore
import Spotify from '@public/images/platforms/Spotify.svg?url';
// @ts-ignore
import ApplePodcast from '@public/images/platforms/ApplePodcasts.svg?url';
import useAvailableStoresQuery from '@queries/pso/useAvailableStoresQuery.hook';
import type { Mode, Platform } from '@/api/pso/types';
import ReactCountryFlag from 'react-country-flag';
import usePsoQuery from '@/queries/pso/usePsoQuery.hook';
import { APPLE_PODCASTS } from '@/shared/config/constants';
import styled from 'styled-components';
import { Key } from 'react-aria';

type Store = {
    name: string;
    alpha2Code: string;
};

type StoreWithImage = { id: string; name: string; image: React.ReactNode };

const Form = () => {
    const formRef = useRef<HTMLFormElement>(null);
    const { showId } = useParams<{ showId: string }>();
    const history = useHistory();
    const location = useLocation();
    const query = useQuery();
    const [storeSearch, setStoreSearch] = useState('');

    const intl = useIntl();
    const schema = useCompetitorsFormSchema();
    const availableStores = useAvailableStoresQuery();
    const psoQuery = usePsoQuery({ showId, enabled: true });

    const defaultPlatform: Platform = query.get('platform') as Platform;
    const defaultMode: Mode = query.get('mode') as Mode;
    const defaultStore: string = query.get('store') as string;

    const psoStores = psoQuery?.data?.stores?.data.map(
        (store: { alpha2Code: string }) => store.alpha2Code,
    );

    useEffect(() => {
        history.push(
            `${location.pathname}?platform=${defaultPlatform ?? APPLE_PODCASTS}&mode=${
                defaultMode ?? 'popular'
            }&store=${defaultStore ?? psoStores[0] ?? 'US'}`,
        );
    }, []);

    const { control, handleSubmit, watch, setValue } = useForm<CompetitorsForm>({
        mode: 'onChange',
        resolver: zodResolver(schema),
        defaultValues: {
            platform: defaultPlatform ?? APPLE_PODCASTS,
            mode: defaultMode ?? 'popular',
            store: defaultStore ?? psoStores[0] ?? 'US',
        },
    });
    const [modes, setModes] = useState([
        {
            id: 'popular',
            name: intl.formatMessage({ defaultMessage: 'Les plus populaires' }),
        },
        {
            id: 'show',
            name: intl.formatMessage({ defaultMessage: 'Emissions' }),
        },
        {
            id: 'episode',
            name: intl.formatMessage({ defaultMessage: 'Episodes' }),
        },
    ]);

    const platform = watch('platform');
    const mode = watch('mode');

    useEffect(() => {
        if (platform === 'spotify') {
            if (mode === 'popular') {
                setValue('mode', 'show');
            }
            setModes([
                {
                    id: 'show',
                    name: intl.formatMessage({ defaultMessage: 'Emissions' }),
                },
                {
                    id: 'episode',
                    name: intl.formatMessage({ defaultMessage: 'Episodes' }),
                },
            ]);
        } else {
            setModes([
                {
                    id: 'popular',
                    name: intl.formatMessage({ defaultMessage: 'Les plus populaires' }),
                },
                {
                    id: 'show',
                    name: intl.formatMessage({ defaultMessage: 'Emissions' }),
                },
                {
                    id: 'episode',
                    name: intl.formatMessage({ defaultMessage: 'Episodes' }),
                },
            ]);
        }
    }, [platform, mode]);

    const onSubmit = (formData: CompetitorsForm) => {
        let mode = formData.mode;

        /**
         * "Popular" mode is not available for Spotify, so we need to switch to "Show" mode
         * when the user selects Spotify and had "Popular" mode selected.
         */
        if (formData.platform === 'spotify' && formData.mode === 'popular') {
            mode = 'show';
        }

        history.push(
            `${location.pathname}?platform=${formData.platform}&mode=${mode}&store=${formData.store}`,
        );
    };

    return (
        <FormElement onSubmit={handleSubmit(onSubmit)} ref={formRef}>
            <FormSection>
                <FormItemWrapper>
                    <SelectController
                        name="platform"
                        // @ts-ignore
                        control={control}
                        items={[
                            {
                                id: 'itunes',
                                name: 'Apple Podcasts',
                                image: <PlatformIcon src={ApplePodcast} alt="ApplePodcast" />,
                            },
                            {
                                id: 'spotify',
                                name: 'Spotify',
                                image: <PlatformIcon src={Spotify} alt="Spotify" />,
                            },
                        ]}
                        onSelectionChange={(value: Key) => {
                            setValue('platform', value as Platform);
                            formRef.current?.requestSubmit();
                        }}
                    >
                        {(item: { id: string; name: string; image: React.ReactNode }) => (
                            <ListBoxItem {...item} aria-label={item.name} />
                        )}
                    </SelectController>
                </FormItemWrapper>
                <FormItemWrapper>
                    {/* @ts-ignore */}
                    <SelectController
                        name="mode"
                        // @ts-ignore
                        control={control}
                        items={modes}
                        onSelectionChange={(value: Key) => {
                            setValue('mode', value as Mode);
                            formRef.current?.requestSubmit();
                        }}
                    >
                        {(item: { id: string; name: string }) => (
                            <ListBoxItem {...item} aria-label={item.name} />
                        )}
                    </SelectController>
                </FormItemWrapper>
                <FormItemWrapper>
                    <ComboBoxController
                        name="store"
                        control={control}
                        onInputChange={(value: string) => setStoreSearch(value)}
                        onSelectionChange={(value: Key | null) => {
                            setValue('store', value as string);
                            formRef.current?.requestSubmit();
                        }}
                        items={availableStores?.data
                            ?.map((store: Store) => ({
                                id: store.alpha2Code,
                                name: store.name,
                                image: <ReactCountryFlag countryCode={store.alpha2Code} svg />,
                            }))
                            ?.sort((a: StoreWithImage, b: StoreWithImage) => {
                                const isAFavorite = psoStores.includes(a.id);
                                const isBFavorite = psoStores.includes(b.id);
                                if (isAFavorite && isBFavorite) {
                                    return a.name.localeCompare(b.name);
                                }
                                if (!isAFavorite && !isBFavorite) {
                                    return a.name.localeCompare(b.name);
                                }

                                return isAFavorite ? -1 : 1;
                            })
                            .filter((store: StoreWithImage) => {
                                if (
                                    availableStores?.data?.find((s: Store) => {
                                        return s.alpha2Code === watch('store');
                                    })
                                ) {
                                    return true;
                                }
                                return store.name.toLowerCase().includes(storeSearch.toLowerCase());
                            })}
                    >
                        {(item: StoreWithImage) => <ListBoxItem aria-label={item.name} {...item} />}
                    </ComboBoxController>
                </FormItemWrapper>
            </FormSection>
        </FormElement>
    );
};

const FormElement = styled.form`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;
const FormSection = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
`;
const FormItemWrapper = styled.div`
    flex-basis: 15rem;
    display: flex;
    gap: 1rem;
`;
const PlatformIcon = styled.img`
    width: 1rem;
    height: 1rem;
`;

export default Form;
