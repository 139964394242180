import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { resolveColor } from '@/utils/cssVariables';

const sizes = {
    medium: css`
        padding: 0.625rem 1rem;
    `,
    mediumIcon: css`
        padding: 0.625rem;
    `,
    small: css`
        padding: 0.375rem 0.75rem;
    `,
    smallIcon: css`
        padding: 0.375rem;
    `,
};

const PrimaryButton = styled.button`
    display: inline-flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;

    position: relative;
    overflow: hidden;

    cursor: pointer;
    text-decoration: none;
    border: none;
    border-radius: var(--r-s);

    transition-duration: 200ms;
    transition-property: opacity, outline, background, color, box-shadow;

    background: ${(props) => resolveColor(props.$background)};
    color: ${(props) => resolveColor(props.$color)};
    box-shadow: 0px 4px 16px 0px rgba(117, 28, 191, 0.3);

    ${(props) => sizes[props.$size]}

    ${(props) =>
        props.$isRound &&
        css`
            border-radius: var(--r-full);
        `}

    ${(props) =>
        props.$isDisabled &&
        css`
            opacity: 0.5;
            cursor: not-allowed;
            // By default, disabled button prevent mouse event to fire.
            // pointer-events disable this behaviour and allow parent to catch mouseenter and mouseleave events.
            // https://github.com/whatwg/html/issues/2368
            pointer-events: none;
        `}

    ${(props) =>
        props.$isFocusVisible &&
        css`
            outline: 2px solid var(--primary);
        `}

    ${(props) =>
        props.$isHovered &&
        css`
            background: ${(props) => resolveColor(`${props.$background}400`)};
            box-shadow: none;
        `}

    ${(props) =>
        props.$isPressed &&
        css`
            background: ${(props) => resolveColor(`${props.$background}`)};
            box-shadow: none;
        `}

    ${(props) =>
        props.$isLoading &&
        css`
            & > :not(.loading-container) {
                opacity: 0;
            }
        `}
  
    & > svg {
        // Prevent icons to shrink
        flex-shrink: 0;
    }

    & > .button-text {
        font-weight: var(--fw-bold);
    }
`;

PrimaryButton.propTypes = {
    $size: PropTypes.oneOf(['medium', 'mediumIcon', 'small', 'smallIcon']),
    $background: PropTypes.string,
    $color: PropTypes.string,
    $isRound: PropTypes.bool,
    $isDisabled: PropTypes.bool,
    $isFocusVisible: PropTypes.bool,
    $isHovered: PropTypes.bool,
    $isPressed: PropTypes.bool,
    $isLoading: PropTypes.bool,
};

PrimaryButton.defaultProps = {
    $background: '--primary',
    $color: '--white',
};

export default PrimaryButton;
