import Modal from '@ui/atoms/Modal';
import Text from '@ui/atoms/Text';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import IconButton from '@ui/atoms/IconButton';
import Cluster from '@ui/layout/Cluster';
import Stack from '@ui/layout/Stack';
import Alert from '@ui/atoms/Alert';
import EpisodeEditTranscriptionQuotaForm from './TranscriptionEditQuotaForm';
import useTranscriptQuotaQuery from '@queries/quota/useTranscriptQuotaQuery.hook';

const EpisodeEditTranscriptionQuotaModal = ({ isOpen, onOpenChange, onConfirm }) => {
    const handleClose = () => onOpenChange(false);
    const { data: quota } = useTranscriptQuotaQuery();

    return (
        <ModalStyled size="medium" isOpen={isOpen} onOpenChange={onOpenChange}>
            <Stack $gap="2rem">
                <Stack $gap="0.25rem">
                    <Cluster $gap="2rem" $justify="space-between" $wrap="nowrap">
                        <Text variant="headingM" fontWeight="--fw-bold">
                            <FormattedMessage defaultMessage="Gérer mon temps de transcription" />
                        </Text>
                        <CloseButton
                            icon={<CloseIcon icon={icon({ name: 'xmark-large' })} />}
                            onPress={handleClose}
                            variant="ghost"
                            size="small"
                        />
                    </Cluster>
                    <Text color="--neutral500">
                        <FormattedMessage defaultMessage="En plus du temps inclus dans votre abonnement, personnalisez votre limite mensuelle de transcription pour l’adapter à vos besoins 🚀" />
                    </Text>
                </Stack>
                <Alert variant="neutral" icon={<InfoIcon icon={icon({ name: 'info-circle' })} />}>
                    <Text fontWeight="--fw-semibold">
                        <FormattedMessage
                            defaultMessage="Vous disposez actuellement de {quota} min / mois."
                            values={{
                                quota: quota?.maximum,
                            }}
                        />
                    </Text>
                </Alert>
                <EpisodeEditTranscriptionQuotaForm
                    quota={quota}
                    onClose={handleClose}
                    onConfirm={onConfirm}
                />
            </Stack>
        </ModalStyled>
    );
};

const CloseIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
    width: 1rem;
    height: 1rem;
`;
const CloseButton = styled(IconButton)`
    padding: 0;
`;
const ModalStyled = styled(Modal)`
    padding: 1.5rem;
`;
const InfoIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
    width: 1rem;
    height: 1rem;
`;

EpisodeEditTranscriptionQuotaModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onOpenChange: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
};
EpisodeEditTranscriptionQuotaModal.defaultProps = {
    isOpen: false,
    onOpenChange: () => {},
    onConfirm: () => {},
};

export default EpisodeEditTranscriptionQuotaModal;
