import { useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Text from '@ui/atoms/Text';
import { FormattedMessage } from 'react-intl';
import { useResponsive } from '@/shared/hooks/useResponsive';
import { connect } from '@/components/legacy/connect';
import NavButtonAccess from '@/components/unorganized/NavButtonAccess';
import ShowsMenuDropdown from '@/components/unorganized/ShowsMenuDropdown';
import { STATUS_SHOWS } from '@/shared/config/statusShows';
import AushaClubButton from '@/components/unorganized/AushaClubButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
    GUARD_DISALLOW_REASONS,
    USER_ROLES,
    ENABLE_NON_HOSTING_EP_MGT,
} from '@/shared/config/constants';
import { CurrentShow } from '@/context/CurrentShowContext';
import useInternalRouteMapping from '@/shared/hooks/useInternalRouteMapping.hook';
import AushaClubModal from '@/components/unorganized/AushaClubModal';
import styled from 'styled-components';
import { useOverlayTriggerState } from 'react-stately';
import useSubscriptionQuery from '@/queries/subscription/useSubscriptionQuery.hook';
import useShowQuery from '@/queries/show/useShowQuery.hook';

const ArchivedBanner = ({ active, children }) => {
    if (!active) return null;
    return children;
};

const enhance = connect(({ routerStore, subscriptionStore }) => ({
    activeTab: routerStore.params.tabMenu,
    link: routerStore.link,
    blocked:
        subscriptionStore.subscription &&
        subscriptionStore.subscription.isBlockedForShow(routerStore.params.showId),
}));

const NavigationMenu = ({ link, activeTab, blocked }) => {
    const match = useRouteMatch();
    const internalRouteMapping = useInternalRouteMapping();
    const currentShow = useContext(CurrentShow);

    const { isMobileOrTablet, isDesktop } = useResponsive();
    const aushaClubModalState = useOverlayTriggerState({});
    const { data: subscription } = useSubscriptionQuery();
    const { data: show } = useShowQuery(currentShow.showId);
    const isFrench = show?.languageId === 47;
    const isSubscriptionActive = !!(show?.ownerPricing || subscription?.activated);
    const showStatus = show?.showStatus;

    const handleOpenAushaClubModal = () => {
        if (isSubscriptionActive) return;
        aushaClubModalState.open();
    };

    const noShow = !currentShow.showId;
    const showHasHostingAccess = show?.hostedOnAusha === true;

    if (subscription && show) {
        return (
            <>
                <Nav>
                    <ShowsMenuDropdown showId={currentShow.showId} />
                    <MobileSeparator />
                    <Menu>
                        {!showHasHostingAccess && ENABLE_NON_HOSTING_EP_MGT && (
                            <MenuItem>
                                <NavButtonAccess
                                    disabled={noShow}
                                    active={match.params.tabMenu === 'workspace'}
                                    to={`/app/show/${currentShow.showId}/workspace`}
                                    responsive={isMobileOrTablet}
                                    tooltip={{
                                        [GUARD_DISALLOW_REASONS.GENERIC]: (
                                            <Text>
                                                <FormattedMessage defaultMessage="Vous ne pouvez pas accéder à la page." />
                                            </Text>
                                        ),
                                    }}
                                >
                                    <MenuItemIcon
                                        icon={icon({ name: 'rectangle-history', style: 'duotone' })}
                                        fixedWidth
                                    />
                                    <Text fontWeight="--fw-semibold">
                                        <FormattedMessage defaultMessage="Espace de travail" />
                                    </Text>
                                </NavButtonAccess>
                            </MenuItem>
                        )}

                        {!showHasHostingAccess && ENABLE_NON_HOSTING_EP_MGT && (
                            <MenuItem>
                                <NavButtonAccess
                                    disabled={noShow}
                                    active={match.params.tabMenu === 'feed'}
                                    to={`/app/show/${currentShow.showId}/feed`}
                                    responsive={isMobileOrTablet}
                                    tooltip={{
                                        [GUARD_DISALLOW_REASONS.GENERIC]: (
                                            <Text>
                                                <FormattedMessage defaultMessage="Vous ne pouvez pas accéder à la page." />
                                            </Text>
                                        ),
                                    }}
                                >
                                    <MenuItemIcon
                                        icon={icon({ name: 'rss', style: 'duotone' })}
                                        fixedWidth
                                    />
                                    <Text fontWeight="--fw-semibold">
                                        <FormattedMessage defaultMessage="Flux" />
                                    </Text>
                                </NavButtonAccess>
                            </MenuItem>
                        )}

                        {showHasHostingAccess && (
                            <MenuItem>
                                <NavButtonAccess
                                    disabled={noShow}
                                    active={activeTab === 'episodes' || activeTab === 'playlists'}
                                    to={internalRouteMapping.link('menu.episodes', {
                                        showId: currentShow.showId,
                                    })}
                                    responsive={isMobileOrTablet}
                                    tooltip={{
                                        [GUARD_DISALLOW_REASONS.GENERIC]: (
                                            <Text>
                                                <FormattedMessage defaultMessage="Vous ne pouvez pas accéder à la page épisodes." />
                                            </Text>
                                        ),
                                    }}
                                >
                                    <MenuItemIcon
                                        icon={icon({ name: 'rectangle-history', style: 'duotone' })}
                                        fixedWidth
                                    />
                                    <Text fontWeight="--fw-semibold">
                                        <FormattedMessage defaultMessage="Épisodes" />
                                    </Text>
                                </NavButtonAccess>
                            </MenuItem>
                        )}

                        {showHasHostingAccess && (
                            <MenuItem>
                                <NavButtonAccess
                                    disallowedUserRoles={[USER_ROLES.VIEWER]}
                                    disabled={noShow}
                                    noShow={noShow}
                                    active={activeTab === 'communication'}
                                    to={internalRouteMapping.link('communication', {
                                        showId: currentShow.showId,
                                        tab: 'social-media',
                                    })}
                                    responsive={isMobileOrTablet}
                                    tooltip={{
                                        [GUARD_DISALLOW_REASONS.UNAUTHORIZED_SUBSCRIPTION]: (
                                            <Text>
                                                <FormattedMessage defaultMessage="Vous ne pouvez pas accéder à la page de communication." />
                                            </Text>
                                        ),
                                        [GUARD_DISALLOW_REASONS.INSUFFICIENT_ROLE]: (
                                            <Text>
                                                <FormattedMessage defaultMessage="Vous ne pouvez pas accéder à la page de communication en tant que lecteur." />
                                            </Text>
                                        ),
                                        [GUARD_DISALLOW_REASONS.GENERIC]: (
                                            <Text>
                                                <FormattedMessage defaultMessage="Vous ne pouvez pas accéder à la page de communication." />
                                            </Text>
                                        ),
                                    }}
                                    data-testid="nav-communication-button"
                                >
                                    <MenuItemIcon
                                        icon={icon({ name: 'megaphone', style: 'duotone' })}
                                        fixedWidth
                                    />
                                    <Text fontWeight="--fw-semibold">
                                        <FormattedMessage defaultMessage="Communication" />
                                    </Text>
                                </NavButtonAccess>
                            </MenuItem>
                        )}

                        {showHasHostingAccess && (
                            <MenuItem>
                                <NavButtonAccess
                                    tooltip={{
                                        [GUARD_DISALLOW_REASONS.UNAUTHORIZED_SUBSCRIPTION]: (
                                            <Text>
                                                <FormattedMessage defaultMessage="Vous ne pouvez pas accéder à la page de statistiques." />
                                            </Text>
                                        ),
                                        [GUARD_DISALLOW_REASONS.GENERIC]: (
                                            <Text>
                                                <FormattedMessage defaultMessage="Vous ne pouvez pas accéder à la page de statistiques." />
                                            </Text>
                                        ),
                                    }}
                                    disabled={noShow}
                                    noShow={noShow}
                                    active={activeTab === 'stats'}
                                    to={internalRouteMapping.link('stats.show', {
                                        showId: currentShow.showId,
                                        tab: 'listeners',
                                    })}
                                    responsive={isMobileOrTablet}
                                    data-testid="nav-statistics-button"
                                >
                                    <MenuItemIcon
                                        icon={icon({ name: 'chart-simple', style: 'duotone' })}
                                        fixedWidth
                                    />
                                    <Text fontWeight="--fw-semibold">
                                        <FormattedMessage defaultMessage="Statistiques" />
                                    </Text>
                                </NavButtonAccess>
                            </MenuItem>
                        )}

                        <MenuItem>
                            <NavButtonAccess
                                tooltip={{
                                    [GUARD_DISALLOW_REASONS.UNAUTHORIZED_SUBSCRIPTION]: (
                                        <Text>
                                            <FormattedMessage defaultMessage="Vous ne pouvez pas accéder à la page référencement." />
                                        </Text>
                                    ),
                                    [GUARD_DISALLOW_REASONS.GENERIC]: (
                                        <Text>
                                            <FormattedMessage defaultMessage="Vous ne pouvez pas accéder à la page référencement." />
                                        </Text>
                                    ),
                                }}
                                disabled={noShow}
                                active={activeTab === 'pso'}
                                to={link('menu.pso', { showId: currentShow.showId })}
                                responsive={isMobileOrTablet}
                            >
                                <MenuItemIcon
                                    icon={icon({ name: 'magnifying-glass', style: 'duotone' })}
                                    fixedWidth
                                />
                                <Text fontWeight="--fw-semibold">
                                    <FormattedMessage defaultMessage="Référencement" />
                                </Text>
                            </NavButtonAccess>
                        </MenuItem>

                        {showHasHostingAccess && (
                            <MenuItem>
                                <NavButtonAccess
                                    disallowedUserRoles={[USER_ROLES.VIEWER]}
                                    showStatus={[STATUS_SHOWS.ARCHIVED_SHOWS]}
                                    disabled={noShow || show?.archived}
                                    noShow={noShow}
                                    active={activeTab === 'monetize'}
                                    to={
                                        isFrench
                                            ? internalRouteMapping.link('menu.monetize.auto', {
                                                  showId: currentShow.showId,
                                              })
                                            : internalRouteMapping.link(
                                                  'menu.monetize.sponsorship',
                                                  {
                                                      showId: currentShow.showId,
                                                  },
                                              )
                                    }
                                    tooltip={{
                                        [GUARD_DISALLOW_REASONS.UNAUTHORIZED_SUBSCRIPTION]: (
                                            <Text>
                                                <FormattedMessage defaultMessage="Vous ne pouvez pas accéder à la page de monétisation." />
                                            </Text>
                                        ),
                                        [GUARD_DISALLOW_REASONS.INSUFFICIENT_ROLE]: (
                                            <Text>
                                                <FormattedMessage defaultMessage="Vous ne pouvez pas accéder à la page de monétisation en tant que lecteur." />
                                            </Text>
                                        ),
                                        [GUARD_DISALLOW_REASONS.GENERIC]: (
                                            <Text>
                                                <FormattedMessage defaultMessage="Vous ne pouvez pas accéder à la page de monétisation." />
                                            </Text>
                                        ),
                                    }}
                                    responsive={isMobileOrTablet}
                                >
                                    <MenuItemIcon
                                        icon={icon({ name: 'sack-dollar', style: 'duotone' })}
                                        fixedWidth
                                    />
                                    <Text fontWeight="--fw-semibold">
                                        <FormattedMessage defaultMessage="Monétisation" />
                                    </Text>
                                </NavButtonAccess>
                            </MenuItem>
                        )}

                        <MenuItem>
                            <NavButtonAccess
                                disallowedUserRoles={[USER_ROLES.VIEWER, USER_ROLES.EDITOR]}
                                disabled={noShow}
                                noShow={noShow}
                                active={activeTab === 'settings'}
                                to={
                                    blocked || showStatus === STATUS_SHOWS.ARCHIVED_SHOWS
                                        ? internalRouteMapping.link('menu.settings.tab', {
                                              showId: currentShow.showId,
                                              tab: 'show-delete',
                                          })
                                        : internalRouteMapping.link('menu.settings', {
                                              showId: currentShow.showId,
                                          })
                                }
                                blockedSubscriptionAuthorized
                                tooltip={{
                                    [GUARD_DISALLOW_REASONS.INSUFFICIENT_ROLE]: (
                                        <Text>
                                            <FormattedMessage defaultMessage="Vous devez être administrateur de l'émission pour accéder à ses paramètres." />
                                        </Text>
                                    ),
                                }}
                                responsive={isMobileOrTablet}
                                data-testid="nav-settings-button"
                            >
                                <MenuItemIcon
                                    icon={icon({ name: 'sliders', style: 'duotone' })}
                                    fixedWidth
                                />
                                <Text fontWeight="--fw-semibold">
                                    <FormattedMessage defaultMessage="Paramètres" />
                                </Text>
                            </NavButtonAccess>
                        </MenuItem>
                    </Menu>
                    {isDesktop && showHasHostingAccess ? (
                        <AushaClubWrapper>
                            <AushaClubButton onClick={handleOpenAushaClubModal} />
                        </AushaClubWrapper>
                    ) : (
                        <FlexDecoy />
                    )}
                </Nav>
                <ArchivedBanner active={showStatus === STATUS_SHOWS.ARCHIVED_SHOWS}>
                    <AnimatePresence>
                        {showStatus === STATUS_SHOWS.ARCHIVED_SHOWS && (
                            <ArchivedBannerInner>
                                <MenuItemIcon
                                    icon={icon({ name: 'box-archive', style: 'duotone' })}
                                    fixedWidth
                                />
                                <Text color="inherit">
                                    <FormattedMessage defaultMessage="Votre émission est archivée." />
                                </Text>
                            </ArchivedBannerInner>
                        )}
                    </AnimatePresence>
                </ArchivedBanner>
                {aushaClubModalState.isOpen && (
                    <AushaClubModal
                        isOpen={aushaClubModalState.isOpen}
                        onClose={aushaClubModalState.close}
                    />
                )}
            </>
        );
    }

    return (
        <Nav>
            <ShowsMenuDropdown showId={currentShow.showId} />
        </Nav>
    );
};

const Nav = styled.nav`
    height: 4rem;
    display: flex;
    justify-content: space-between;
    background: white;
    display: flex;
    align-items: center;
    gap: 2rem;

    ${({ theme }) => theme.mediaQueries.mobile} {
        gap: 0.5rem;
    }
`;
const MobileSeparator = styled.div`
    height: 1.5rem;
    width: 2px;
    background: var(--neutral100);
    border-radius: var(--r-full);
    margin-right: 0.5rem;

    display: none;

    ${({ theme }) => theme.mediaQueries.mobile} {
        display: block;
    }
`;
const ArchivedBannerInner = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    height: 35px;
    backdrop-filter: blur(10px);
    width: 100%;
    background: var(--info100);
    color: var(--info);
`;
const Menu = styled.ul`
    display: flex;
    width: inherit;
    justify-content: center;
    flex: 1;
    align-self: stretch;
    padding: 0;
    list-style: none;
    gap: 2rem;

    ${({ theme }) => theme.mediaQueries.mobile} {
        width: calc(100% - 60px);
        justify-content: flex-start;
        gap: 1.5rem;
    }
`;
const MenuItem = styled.li`
    max-height: 4rem;
`;
const MenuItemIcon = styled(FontAwesomeIcon)`
    color: inherit;
    font-size: 1.15rem;
    max-width: 1.5rem;
`;
const AushaClubWrapper = styled.div`
    flex: 1;
    align-self: stretch;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-inline: 1rem;
    max-width: 280px;
    padding-block: 0.75rem;
`;
const FlexDecoy = styled.div`
    width: 16rem;
    height: 100%;
`;

export default enhance(NavigationMenu);
