import Tabs from '@/components/ui/Tabs';
import { FormattedMessage } from 'react-intl';
import useClipTranscriptionQuery from '@/queries/clip/useClipTranscriptionQuery';
import { useParams } from 'react-router';
import { Episode } from '@/queries/episode/useEpisodeQuery.hook';
import SquarePreview from './SquarePreview';
import StoryPreview from './StoryPreview';
import WidePreview from './WidePreview';
import { Clip } from '@/queries/clip/useClipQuery';

interface FormatsPreviewProps {
    episode: Episode;
    clip: Clip;
}

const FormatsPreview = ({ episode, clip }: FormatsPreviewProps) => {
    const { clipId } = useParams<{ clipId: string }>();
    const { data: transcription } = useClipTranscriptionQuery(clipId);

    const hasSquareformat = clip.formats?.some((format) => format.format === 'square');
    const hasStoryFormat = clip.formats?.some((format) => format.format === 'story');
    const hasWideFormat = clip.formats?.some((format) => format.format === 'wide');
    const hasOnlyOneFormat = clip.formats?.length === 1;

    if (hasOnlyOneFormat) {
        if (hasSquareformat) {
            return (
                <SquarePreview
                    transcription={transcription}
                    episodeImage={episode.imageUrl}
                    clipFormatId={clip.formats.find((format) => format.format === 'square')!.id}
                />
            );
        }
        if (hasStoryFormat) {
            return (
                <StoryPreview
                    transcription={transcription}
                    episodeImage={episode.imageUrl}
                    clipFormatId={clip.formats?.find((format) => format.format === 'story')!.id}
                />
            );
        }
        return (
            <WidePreview
                transcription={transcription}
                episodeImage={episode.imageUrl}
                clipFormatId={clip.formats?.find((format) => format.format === 'wide')!.id}
            />
        );
    }

    const tabs = [];

    if (hasSquareformat) {
        tabs.push({
            id: 'square',
            title: <FormattedMessage defaultMessage="Carré (1:1)" />,
            content: (
                <SquarePreview
                    transcription={transcription}
                    episodeImage={episode.imageUrl}
                    clipFormatId={clip.formats?.find((format) => format.format === 'square')!.id}
                />
            ),
        });
    }

    if (hasStoryFormat) {
        tabs.push({
            id: 'story',
            title: <FormattedMessage defaultMessage="Story (9:16)" />,
            content: (
                <StoryPreview
                    transcription={transcription}
                    episodeImage={episode.imageUrl}
                    clipFormatId={clip.formats?.find((format) => format.format === 'story')!.id}
                />
            ),
        });
    }

    if (hasWideFormat) {
        tabs.push({
            id: 'wide',
            title: <FormattedMessage defaultMessage="Paysage (16:9)" />,
            content: (
                <WidePreview
                    transcription={transcription}
                    episodeImage={episode.imageUrl}
                    clipFormatId={clip.formats?.find((format) => format.format === 'wide')!.id}
                />
            ),
        });
    }

    return (
        <div>
            <Tabs tabs={tabs} />
        </div>
    );
};

export default FormatsPreview;
