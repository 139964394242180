import { FormattedMessage } from 'react-intl';
import FormFactory from './factories/FormFactory';

export default FormFactory((email) => ({
    options: { onlyDiff: false, warnIfNotSaved: false, checkValidity: false },
    fields: {
        email: {
            defaultError: (
                <FormattedMessage defaultMessage="Les identifiants que vous avez rentrés sont incorrects" />
            ),
            type: 'text',
            default: email,
        },
        password: {
            type: 'password',
        },
    },
}));
