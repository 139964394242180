import { FormattedDate, FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';

interface EpisodeCellProps {
    title: string;
    publishedAt: string;
}

const EpisodeCell = ({ title, publishedAt }: EpisodeCellProps) => {
    return (
        <EpisodeCellOuterWrapper>
            <EpisodeCellWrapper>
                <EpisodeCellInnerWrapper>
                    <Thumbnail
                        src="https://image.ausha.co/default_cover.png"
                        alt="default show cover"
                    />
                    <TitleAndDateWrapper>
                        <Title>{title}</Title>
                        <Date>
                            <FontAwesomeIcon
                                icon={icon({ name: 'calendar-days', style: 'regular' })}
                            />
                            <FormattedMessage
                                defaultMessage="Publié le {publishedAt}"
                                values={{
                                    publishedAt: <FormattedDate value={publishedAt} />,
                                }}
                            />
                        </Date>
                    </TitleAndDateWrapper>
                </EpisodeCellInnerWrapper>
            </EpisodeCellWrapper>
        </EpisodeCellOuterWrapper>
    );
};

const EpisodeCellOuterWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.75rem;
`;
const EpisodeCellWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
const EpisodeCellInnerWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.75rem;
    width: 100%;
`;
const Thumbnail = styled.img`
    width: 2.5rem;
    height: 2.5rem;
    border-radius: var(--r-s);
`;
const TitleAndDateWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
`;
const Title = styled.span`
    font-weight: var(--fw-semibold);
    max-width: 350px;
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
const Date = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--neutral);
`;

export default EpisodeCell;
