import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { fetchShowTemplate } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import newsletterKeys from '@queries/newsletter/newsletterKeys';
import { z } from 'zod';

const ShowNewsletterTemplateSchema = z.object({
    activated: z.boolean(),
    messageTpl: z.string().nullable().optional(),
    provider: z.string().nullable().optional(),
});

type Key = QueryFunctionContext<ReturnType<typeof newsletterKeys.templateByShowId>>;

const queryFn = async ({ queryKey: [{ showId }] }: Key) => {
    const { data } = await fetchShowTemplate(showId, 'newsletter');

    // TODO: Do camelcase transformation in Axios interceptor
    return ShowNewsletterTemplateSchema.parse(camelcaseKeys(data, { deep: true }));
};

const useShowNewsletterTemplateQuery = (showId: string) => {
    return useQuery({
        queryKey: newsletterKeys.templateByShowId(showId),
        queryFn,
        enabled: !!showId,
    });
};

export default useShowNewsletterTemplateQuery;
