import Button, { ButtonProps } from '../Button';

export interface IconButtonProps extends Omit<ButtonProps, 'startIcon' | 'endIcon' | 'children'> {
    icon: ButtonProps['startIcon'];
    'aria-label': string;
}

const IconButton = ({ icon, ...props }: IconButtonProps) => {
    return <Button {...props} startIcon={icon} />;
};

export default IconButton;
