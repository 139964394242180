import { FormattedMessage } from 'react-intl';
import DeprecatedIcon from '@ui/atoms/DeprecatedIcon';
import Spinner from '@ui/atoms/Spinner';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import Desktop from '@ui/molecules/Desktop';
import exportIcon from '@public/icons/external-link.svg';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import { useStatsDateSelectorContext } from '@/context/StatsDateSelectorContext';
import { useParams } from 'react-router';
import useQuery from '@/shared/hooks/useQuery';
import { download } from '@/shared/utils/file';
import { useEffect, useState } from 'react';
import useSourcesExportQuery from '@/queries/stats/useSourcesExportQuery.hook';
import useCampaignSourcesExportQuery from '@/queries/stats/useCampaignSourcesExportQuery.hook';

function SourcesExportButton() {
    const { showId } = useParams();
    const query = useQuery();
    const episodes = query.get('episode');
    const campaignId = query.get('campaignId');
    const { startDate, endDate } = useStatsDateSelectorContext();
    const [exportString, setExportString] = useState('');

    const { data: showExportString, isLoading: showIsLoading } = useSourcesExportQuery({
        showId,
        episodes: episodes ? episodes.split('_') : [],
        start: startDate,
        end: endDate,
        options: {
            enabled: !campaignId,
        },
    });
    const { data: campaignExportString, isLoading: campaignIsLoading } =
        useCampaignSourcesExportQuery({
            campaignId,
            episodes: episodes ? episodes.split('_') : [],
            start: startDate,
            end: endDate,
            options: {
                enabled: !!campaignId,
            },
        });

    useEffect(() => {
        if (showExportString || campaignExportString) {
            setExportString(campaignId ? campaignExportString : showExportString);
        }
    }, [showExportString, campaignExportString]);

    const downloadCsv = async () => {
        const filename = `category_from_${startDate}_to_${endDate}.csv`;
        download(exportString, filename, 'txt');
    };

    const isLoading = showIsLoading || campaignIsLoading;

    return (
        <Desktop>
            <DeprecatedPaper flex justify="flex-end">
                <DeprecatedText
                    color="--primary"
                    weight="semibold"
                    onClick={downloadCsv}
                    disabled={isLoading}
                    flex
                    align="center"
                >
                    {isLoading ? (
                        <Spinner color="--primary100" mr={10} />
                    ) : (
                        <DeprecatedIcon as={exportIcon} color="--primary" mr={10} />
                    )}
                    <FormattedMessage defaultMessage="Exporter en .csv" />
                </DeprecatedText>
            </DeprecatedPaper>
        </Desktop>
    );
}

export default SourcesExportButton;
