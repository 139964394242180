import styled from 'styled-components';
import Menu from '@ui/atoms/Menu';
import MenuItem from '@ui/atoms/MenuItem';
import { FormattedMessage } from 'react-intl';
import IconEdit from '@ui/icons/IconEdit';
import IconDelete from '@ui/icons/IconDelete';

const MenuStyled = styled(Menu)`
    left: auto;
    right: 6px;
    top: 50px;
    bottom: auto;
    width: auto;
    min-width: 10rem;
`;
const MenuItemStyled = styled(MenuItem)`
    height: auto;
    padding: 0.75rem;

    & svg {
        height: 1.25rem;
        width: 1.25rem;
        fill: var(--neutral500);
    }
`;

const ClipFormatMenu = ({ isOpen, onOpenClipEditTitleModal, onOpenDeleteModal, ref }) => {
    return (
        <MenuStyled opened={isOpen} ref={ref} overflowHide>
            <MenuItemStyled onClick={onOpenClipEditTitleModal}>
                <IconEdit />
                <FormattedMessage defaultMessage="Renommer" />
            </MenuItemStyled>
            <MenuItemStyled onClick={onOpenDeleteModal}>
                <IconDelete />
                <FormattedMessage defaultMessage="Supprimer" />
            </MenuItemStyled>
        </MenuStyled>
    );
};

export default ClipFormatMenu;
