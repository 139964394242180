import { useMutation } from '@tanstack/react-query';
import { switchSubscriptionPlan } from '@/api';
import camelcaseKeys from 'camelcase-keys';

export interface MutationFnProps {
    yearly: boolean;
    price: string;
}

const mutationFn = async ({ yearly, price }: MutationFnProps) => {
    const { data } = await switchSubscriptionPlan({ yearly, price });

    return camelcaseKeys(data, { deep: true });
};

const useSwitchSubscriptionMutation = () => {
    return useMutation({
        mutationFn,
    });
};

export default useSwitchSubscriptionMutation;
