import { request } from '../api';

const basePath = process.env.API_CONTENT;

export const fetchCampaign = (campaignId) => {
    return request({ method: 'GET', basePath, target: `/v1/campaigns/${campaignId}` });
};
export const fetchCampaigns = (show, q, page, perPage) => {
    return request({
        method: 'GET',
        basePath,
        target: `/v1/shows/${show}/campaigns`,
        query: { q, page, per_page: perPage },
    });
};
export const fetchCampaignMonetization = (show) => {
    return request({
        method: 'GET',
        basePath,
        target: `/v1/shows/${show}/campaigns/monetization_platform`,
    });
};
export const fetchShowsWithTargetspotEnabled = (channel) => {
    return request({ method: 'GET', basePath, target: `/v1/channels/${channel}/targetspot_shows` });
};
export const addCampaign = (show, body) => {
    return request({ method: 'POST', basePath, target: `/v1/shows/${show}/campaigns`, body });
};
export const addTargetspotOnShow = (show) => {
    return request({
        method: 'POST',
        basePath,
        target: `/v1/shows/${show}/targetspot_monetization`,
    });
};
export const addPodcornOnShow = (show) => {
    return request({ method: 'POST', basePath, target: `/v1/shows/${show}/podcorn_monetization` });
};
export const uploadCampaignAudioFile = (campaignId, file) => {
    const body = new FormData();
    body.append('file', file);

    return request({
        method: 'POST',
        basePath,
        target: `/v1/campaigns/${campaignId}/file`,
        body,
    });
};
export const deleteTargetspotMonetization = (show) => {
    return request({
        method: 'DELETE',
        basePath,
        target: `/v1/shows/${show}/targetspot_monetization`,
    });
};
export const deletePodcornMonetization = (show) => {
    return request({
        method: 'DELETE',
        basePath,
        target: `/v1/shows/${show}/podcorn_monetization`,
    });
};
export const deleteCampaign = (campaign) => {
    return request({ method: 'DELETE', basePath, target: `/v1/campaigns/${campaign}` });
};
export const updateCampaign = (campaign, body) => {
    return request({ method: 'PUT', basePath, target: `/v1/campaigns/${campaign}`, body });
};
