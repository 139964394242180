import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from '@/components/ui/atoms/Button';
import ViewSelector from './ViewSelector';
import AddPostModal from './AddPostModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useEditedPostContext } from '@app/pages/SocialMediaPage/useEditedPostContext.hook';
import styled from 'styled-components';

const SocialMediaHeader = ({
    prevButtonProps,
    nextButtonProps,
    title,
    currentView,
    setCurrentView,
    postNb,
    triggerCreatePost,
    postCreateTriggered,
}) => {
    const [showAddPostModal, setShowAddPostModal] = useState(triggerCreatePost);
    const editPostContext = useEditedPostContext();

    useEffect(() => {
        if (editPostContext?.editedPost) {
            setShowAddPostModal(true);
        }
    }, [editPostContext?.editedPost]);

    useEffect(() => {
        postCreateTriggered();
    }, []);

    return (
        <>
            <SocialMediaHeaderWrapper>
                {currentView === 'calendar' ? (
                    <MonthUpdateWrapper>
                        <MonthUpdateButton {...prevButtonProps}>
                            <MonthUpdateIcon
                                icon={icon({ name: 'chevron-left', style: 'solid' })}
                            />
                        </MonthUpdateButton>
                        <MonthUpdateButton {...nextButtonProps}>
                            <MonthUpdateIcon
                                icon={icon({ name: 'chevron-right', style: 'solid' })}
                            />
                        </MonthUpdateButton>
                        <Title>{title}</Title>
                    </MonthUpdateWrapper>
                ) : (
                    <Title>
                        <FormattedMessage defaultMessage="Tous les posts" />
                        <PostNb>{postNb}</PostNb>
                    </Title>
                )}
                <ActionWrapper>
                    <ViewSelector currentView={currentView} setCurrentView={setCurrentView} />
                    <AddPostButton
                        startIcon={
                            <FontAwesomeIcon
                                icon={icon({ name: 'plus', style: 'solid' })}
                                color="inherit"
                                size="sm"
                            />
                        }
                        onPress={() => {
                            editPostContext.setEditedPost(null);
                            setShowAddPostModal(true);
                        }}
                    >
                        <FormattedMessage defaultMessage="Créer un post" />
                    </AddPostButton>
                </ActionWrapper>
            </SocialMediaHeaderWrapper>
            <AddPostModal
                isOpen={showAddPostModal}
                editedPost={editPostContext.editedPost}
                onClose={() => setShowAddPostModal(false)}
            />
        </>
    );
};

const SocialMediaHeaderWrapper = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const MonthUpdateWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    color: var(--black);
    font-weight: var(--fw-bold);
    font-size: var(--fs-heading-s);
`;
const MonthUpdateButton = styled(Button)`
    background: none;
    border: none;
    box-shadow: none;
    padding: 0;
    color: inherit;
`;
const MonthUpdateIcon = styled(FontAwesomeIcon)`
    color: inherit;
    width: 1rem;
    height: 1rem;
`;
const ActionWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 2rem;
`;
const AddPostButton = styled(Button)`
    width: auto;
    ${(p) => p.theme.mediaQueries.desktopLargeAndUp} {
        width: 17rem;
    }
`;
const Title = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: var(--fs-heading-s);
    font-weight: var(--fw-bold);
    color: var(--black);
`;
const PostNb = styled.span`
    color: var(--neutral500);
    font-weight: var(--fw-normal);
`;

SocialMediaHeader.propTypes = {
    prevButtonProps: PropTypes.object,
    nextButtonProps: PropTypes.object,
    title: PropTypes.string,
    currentView: PropTypes.oneOf(['calendar', 'list']),
    setCurrentView: PropTypes.func,
    postNb: PropTypes.number,
    triggerCreatePost: PropTypes.bool,
    postCreateTriggered: PropTypes.func,
};

export default SocialMediaHeader;
