import Badge from '@/components/ui/atoms/Badge';
import Text from '@/components/ui/atoms/Text';
import { EPISODE_PRIVACY, EPISODE_STATUS } from '@/utils/constants';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';
import Button from '@/components/ui/atoms/Button';
import { useOverlayTriggerState } from 'react-stately';
import EpisodeEditPublicationDateModal from '@app/molecules/EpisodeEditPublicationDateModal';
import useUpdateEpisodeMutation from '@/queries/episode/useUpdateEpisodeMutation.hook';
import { useEpisodeBeingEdited } from '@app/organisms/EpisodeEditForm/EpisodeEditFormContext';
import { useFormContext } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import episodeKeys from '@queries/episode/episodeKeys';

const onlinePrivacyLookup = {
    [EPISODE_PRIVACY.PRIVATE]: {
        children: <FormattedMessage defaultMessage="Privé" />,
        variant: 'alert',
        icon: <FontAwesomeIcon icon={icon({ name: 'eye-slash', style: 'solid' })} />,
    },
    [EPISODE_PRIVACY.UNLISTED]: {
        children: <FormattedMessage defaultMessage="Non-listé" />,
        variant: 'warning',
        icon: <FontAwesomeIcon icon={icon({ name: 'link-slash', style: 'solid' })} />,
    },
    [EPISODE_PRIVACY.PUBLIC]: {
        children: <FormattedMessage defaultMessage="Public" />,
        variant: 'success',
        icon: <FontAwesomeIcon icon={icon({ name: 'eye', style: 'solid' })} />,
    },
};

const statusToBadgeLookup = {
    [EPISODE_STATUS.DRAFT]: {
        children: <FormattedMessage defaultMessage="Brouillon" />,
        variant: 'neutral',
        icon: <FontAwesomeIcon icon={icon({ name: 'pen-line', style: 'solid' })} />,
    },
    [EPISODE_STATUS.SCHEDULED]: {
        children: <FormattedMessage defaultMessage="Planifié" />,
        variant: 'info',
        icon: <FontAwesomeIcon icon={icon({ name: 'clock', style: 'solid' })} />,
    },
    [EPISODE_STATUS.ONLINE]: (privacy) => onlinePrivacyLookup[privacy],
};

const EpisodeEditResumeInfo = () => {
    const queryClient = useQueryClient();
    const { watch } = useFormContext();
    const editScheludingModalState = useOverlayTriggerState({});
    const episodeBeingEdited = useEpisodeBeingEdited();
    const episodeName = watch('content.name');
    const updateEpisode = useUpdateEpisodeMutation();
    const status = episodeBeingEdited?.state;
    const privacy = episodeBeingEdited?.privacy;
    const badgeProps =
        EPISODE_STATUS.ONLINE === status
            ? statusToBadgeLookup[status](privacy)
            : statusToBadgeLookup[status];
    const isScheduled = status === EPISODE_STATUS.SCHEDULED;
    const isOnline = status === EPISODE_STATUS.ONLINE;
    const publishedAt = new Date(episodeBeingEdited?.publishedAt);

    const handleEditPublicationDate = (formData) => {
        const episodeId = episodeBeingEdited?.id;
        updateEpisode.mutate(
            { episodeId, episode: formData },
            {
                onSuccess: () => {
                    queryClient.invalidateQueries({
                        queryKey: episodeKeys.detailById(episodeId),
                    });
                    editScheludingModalState.close();
                },
            },
        );
    };

    return (
        <>
            <Wrapper status={status}>
                <Badge {...badgeProps} />
                <TitleWrapper status={status}>
                    <Text variant="bodyL" fontWeight="--fw-bold">
                        {episodeName || episodeBeingEdited.name}
                    </Text>
                    {isScheduled ? (
                        <ScheduledWrapper>
                            <ScheduledTextWrapper>
                                <CalendarIcon icon={icon({ name: 'calendar', style: 'regular' })} />
                                <Text variant="bodyM" color="--neutral500">
                                    <FormattedMessage
                                        defaultMessage="Planifié pour le {date} à {time}"
                                        values={{
                                            date: (
                                                <FormattedDate
                                                    value={episodeBeingEdited?.publishedAt}
                                                />
                                            ),
                                            time: (
                                                <FormattedTime
                                                    value={episodeBeingEdited?.publishedAt}
                                                />
                                            ),
                                        }}
                                    />
                                </Text>
                            </ScheduledTextWrapper>
                            <ScheduledEditButton
                                onPress={editScheludingModalState.open}
                                variant="ghost"
                            >
                                <FormattedMessage defaultMessage="Modifier" />
                            </ScheduledEditButton>
                        </ScheduledWrapper>
                    ) : (
                        isOnline && (
                            <ScheduledWrapper>
                                <Text variant="bodyM" color="--neutral500">
                                    <FormattedMessage
                                        defaultMessage="Publié le {date}"
                                        values={{
                                            date: (
                                                <FormattedDate
                                                    value={episodeBeingEdited?.publishedAt}
                                                />
                                            ),
                                        }}
                                    />
                                </Text>
                                <ScheduledEditButton
                                    onPress={editScheludingModalState.open}
                                    variant="ghost"
                                >
                                    <FormattedMessage defaultMessage="Modifier" />
                                </ScheduledEditButton>
                            </ScheduledWrapper>
                        )
                    )}
                </TitleWrapper>
            </Wrapper>
            {editScheludingModalState.isOpen && (
                <EpisodeEditPublicationDateModal
                    isOpen={editScheludingModalState.isOpen}
                    onClose={editScheludingModalState.close}
                    isOnline={isOnline}
                    handleEditPublicationDate={handleEditPublicationDate}
                    isLoading={updateEpisode.isLoading}
                    publishedAt={publishedAt}
                />
            )}
        </>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    align-items: start;
`;
const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;
`;
const ScheduledTextWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.25rem;
`;
const CalendarIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: var(--neutral500);
`;
const ScheduledWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
`;
const ScheduledEditButton = styled(Button)`
    color: var(--primary);
    padding: 0;
`;
export default EpisodeEditResumeInfo;
