import { z } from 'zod';

const useClipCustomizationFormSchema = () => {
    return z.object({
        showWaveform: z.boolean(),
        color: z.string(),
        showTitles: z.boolean(),
        titleColor: z.string(),
        title: z.string(),
        caption: z.string(),
    });
};

export type ClipCustomizationFormSchema = z.infer<
    ReturnType<typeof useClipCustomizationFormSchema>
>;

export default useClipCustomizationFormSchema;
