import { InputTextController } from '@/components/ui/atoms/InputText';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { CampaignFormSchema } from '../../useFormSchema';
import { RadioCardGroupController } from '@/shared/components/RadioCard';
import RadioCard from '@/shared/components/RadioCard/RadioCard';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Stack from '@/components/ui/layout/Stack';
import Text from '@/components/ui/atoms/Text';
import { TooltipTrigger } from 'react-aria-components';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';
import { useParams } from 'react-router';
import useCampaignQuery from '@/queries/campaign/useCampaignQuery';
import { CAMPAIGN_STATUS } from '@/shared/config/constants';

const General = () => {
    const { campaignId } = useParams<{ campaignId: string }>();
    const { data: campaign } = useCampaignQuery(campaignId);
    const { control } = useFormContext<CampaignFormSchema>();
    const intl = useIntl();
    const isPublished =
        campaign?.state === CAMPAIGN_STATUS.ACTIVE ||
        campaign?.state === CAMPAIGN_STATUS.PAUSED ||
        campaign?.state === CAMPAIGN_STATUS.PLANIFIED;

    return (
        <Stack $gap="2rem">
            <InputTextController
                name="general.name"
                control={control}
                label={<FormattedMessage defaultMessage="Titre" />}
                placeholder={intl.formatMessage({
                    defaultMessage: 'Le super titre de votre campagne',
                })}
                isRequired
            />
            <RadioCardGroupController control={control} name="general.type">
                <TooltipTrigger delay={0} closeDelay={0} isDisabled={!isPublished}>
                    <TooltipTriggerWrapperStyled>
                        <RadioCard value="preroll" isDisabled={isPublished}>
                            <RadioIcon icon={icon({ name: 'backward', style: 'solid' })} />
                            <Stack $gap="0.25rem">
                                <Text fontWeight="--fw-semibold">
                                    <FormattedMessage defaultMessage="Pré-roll" />
                                </Text>
                                <Text color="--neutral500">
                                    <FormattedMessage defaultMessage="La campagne sera diffusée juste avant l’épisode." />
                                </Text>
                            </Stack>
                        </RadioCard>
                    </TooltipTriggerWrapperStyled>
                    <Tooltip placement="top">
                        <FormattedMessage defaultMessage="Il n'est pas possible de modifier le type d'une campagne lorsque celle-ci est publiée." />
                    </Tooltip>
                </TooltipTrigger>
                <TooltipTrigger delay={0} closeDelay={0} isDisabled={!isPublished}>
                    <TooltipTriggerWrapperStyled>
                        <RadioCard value="postroll" isDisabled={isPublished}>
                            <RadioIcon icon={icon({ name: 'forward', style: 'solid' })} />
                            <Stack $gap="0.25rem">
                                <Text fontWeight="--fw-semibold">
                                    <FormattedMessage defaultMessage="Post-roll" />
                                </Text>
                                <Text color="--neutral500">
                                    <FormattedMessage defaultMessage="La campagne sera diffusée à la fin de l’épisode." />
                                </Text>
                            </Stack>
                        </RadioCard>
                    </TooltipTriggerWrapperStyled>
                    <Tooltip placement="top">
                        <FormattedMessage defaultMessage="Il n'est pas possible de modifier le type d'une campagne lorsque celle-ci est publiée." />
                    </Tooltip>
                </TooltipTrigger>
                <TooltipTrigger delay={0} closeDelay={0} isDisabled={!isPublished}>
                    <TooltipTriggerWrapperStyled>
                        <RadioCard value="hostread" isDisabled={isPublished}>
                            <RadioIcon icon={icon({ name: 'circle', style: 'solid' })} />
                            <Stack $gap="0.25rem">
                                <Text fontWeight="--fw-semibold">
                                    <FormattedMessage defaultMessage="Host-read" />
                                </Text>
                                <Text color="--neutral500">
                                    <FormattedMessage defaultMessage="Vous faites votre publicité directement dans l’épisode." />
                                </Text>
                            </Stack>
                        </RadioCard>
                    </TooltipTriggerWrapperStyled>
                    <Tooltip placement="top">
                        <FormattedMessage defaultMessage="Il n'est pas possible de modifier le type d'une campagne lorsque celle-ci est publiée." />
                    </Tooltip>
                </TooltipTrigger>
            </RadioCardGroupController>
        </Stack>
    );
};

const RadioIcon = styled(FontAwesomeIcon)`
    width: 1.5rem;
    height: 1.5rem;
    color: var(--primary500);
`;
const TooltipTriggerWrapperStyled = styled(TooltipTriggerWrapper)`
    flex: 1;

    & > * {
        height: 100%;
    }
`;

export default General;
