import { computed } from 'mobx';
import dayjs from 'dayjs';
import Model from './Model';
import { dateUTC, nested } from './resolvers';
import { apiCall } from '@/components/legacy/api';

class SubscriptionModel extends Model {
    static attributes = {
        id: 'id',
        subscribed: 'activated',
        yearly: 'yearly',
        quantity: 'quantity',
        pastDue: 'past_due',
        subscriptionPricing: 'pricing',
        discount: 'discount',
        incomplete: 'incomplete',
        showsMax: 'shows_max',
        endsAt: dateUTC('ends_at'),
        periodEnd: dateUTC('current_period_end'),
        priceBase: nested('prices', 'base'),
        priceExtra: nested('prices', 'extra'),
        intentSecret: 'intent_secret',
        extraItems: 'extra_items',
        extraShows: 'extra_shows',
        archivedShowsMax: 'archived_shows_max',
        currency: 'currency',
        isFreemium: 'is_freemium',
        hasUnpaidInvoices: 'has_unpaid_invoices',
    };

    @computed
    get ended() {
        return !!this.endsAt && !isFuture(this.endsAt);
    }

    @computed
    get onGrace() {
        return this.endsAt && isFuture(this.endsAt);
    }

    @computed
    get blocked() {
        return this.ended;
    }

    isBlockedForShow() {
        return this.ended;
    }

    @apiCall
    resume() {
        return this.store.resume(this);
    }

    @apiCall
    reactivate(stripe) {
        return this.store.reactivate(this, stripe);
    }
}

function isFuture(dataJS) {
    if (dataJS === null) {
        return false;
    }
    return dayjs(dataJS).isAfter(dayjs());
}

export default SubscriptionModel;
