import { request } from '../api';
import { uploadImage } from '../uploadImage';

const basePath = process.env.API_CONTENT;

export const fetchClipFormat = (clipFormatId) => {
    return request({ method: 'GET', basePath, target: `/v1/clip_formats/${clipFormatId}` });
};
export const updateClipFormat = (clipFormatId, clipFormat) => {
    return request({
        method: 'PUT',
        basePath,
        target: `/v1/clip_formats/${clipFormatId}`,
        body: clipFormat,
    });
};
export const deleteClipFormat = (clipFormatId) => {
    return request({
        method: 'DELETE',
        basePath,
        target: `/v1/clip_formats/${clipFormatId}`,
    });
};
export const uploadClipFormatImage = (clipFormatId, image) => {
    return uploadImage(`/v1/clip_formats/${clipFormatId}/image`, image);
};
export const deleteClipFormatImage = (clipFormatId) => {
    return request({
        method: 'DELETE',
        basePath,
        target: `/v1/clip_formats/${clipFormatId}/image`,
    });
};
export const fetchClip = (clipId) => {
    return request({ method: 'GET', basePath, target: `/v1/clips/${clipId}` });
};
export const updateClip = (clipId, clip) => {
    return request({ method: 'PUT', basePath, target: `/v1/clips/${clipId}`, body: clip });
};
export const deleteClip = (clipId) => {
    return request({ method: 'DELETE', basePath, target: `/v1/clips/${clipId}` });
};
export const generateClip = (clipId) => {
    return request({ method: 'POST', basePath, target: `/v1/clips/${clipId}/generate` });
};
export const updateClipTranscription = (clipId, transcription) => {
    return request({
        method: 'POST',
        basePath,
        target: `/v1/clips/${clipId}/transcription`,
        body: transcription,
    });
};

export const fetchClipTranscription = (clipId) => {
    return request({ method: 'GET', basePath, target: `/v1/clips/${clipId}/transcription` });
};
export const fetchClipTranscriptionProgress = (clipId) => {
    return request({ method: 'GET', basePath, target: `/v1/clips/${clipId}/transcription/status` });
};
export const createClip = (episodeId, clip) => {
    return request({
        method: 'POST',
        basePath,
        target: `/v1/podcasts/${episodeId}/clips`,
        body: clip,
    });
};
export const fetchClipsOfEpisode = (episodeId) => {
    return request({ method: 'GET', basePath, target: `/v1/podcasts/${episodeId}/clips` });
};
export const fetchClipFormatsOfShow = (showId, page, perPage) => {
    return request({
        method: 'GET',
        basePath,
        target: `/v1/shows/${showId}/clip_formats`,
        query: { page, per_page: perPage },
    });
};
export const fetchClipFormatsOfPodcast = (episodeId) => {
    return request({
        method: 'GET',
        basePath,
        target: `/v1/podcasts/${episodeId}/clip_formats`,
    });
};
export const fetchClipsOfShow = (showId, page, perPage) => {
    return request({
        method: 'GET',
        basePath,
        target: `/v1/shows/${showId}/clips`,
        query: { page, per_page: perPage },
    });
};
export const fetchClipSupportedLanguages = () => {
    return request({
        method: 'GET',
        basePath,
        target: '/v1/podcasts/videos/transcription/languages',
    });
};
