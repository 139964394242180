import { useState } from 'react';
import { useParams } from 'react-router';
import { FormattedMessage } from 'react-intl';
import Modal from '@ui/atoms/Modal';
import Button from '@/components/Button';
import useShowsQuery from '@queries/show/useShowsQuery.hook';
import styled from 'styled-components';

interface MoveEpisodesModalProps {
    onClose: () => void;
    onMove: (showId: string) => void | Promise<void>;
    moving: boolean;
    count: number;
    isOpened: boolean;
}

const MoveEpisodesModal = ({
    onClose,
    onMove,
    moving,
    count,
    isOpened,
}: MoveEpisodesModalProps) => {
    const { showId } = useParams<{ showId: string }>();
    const { data: shows } = useShowsQuery();
    const candidatesShows =
        shows?.shows
            ?.filter((show) => show.id !== parseInt(showId, 10))
            ?.filter((show) => show.archived === false && show.isOwner) || [];
    const [targetShowId, setTargetShowId] = useState('');

    const submitMove = async () => {
        await onMove(targetShowId);
        onClose();
    };

    return (
        <ModalStyled isOpen={isOpened} onOpenChange={onClose} size="small">
            <Title>
                <FormattedMessage
                    defaultMessage="Où voulez-vous déplacer {count, plural, one {l'épisode} other {les épisodes}} ?"
                    values={{ count }}
                />
            </Title>
            <Description>
                <FormattedMessage
                    defaultMessage="Sélectionnez l'émission vers laquelle vous souhaitez déplacer {count, plural, one {votre épisode} other {vos épisodes}}."
                    values={{ count }}
                />
            </Description>

            <ShowCandidates>
                {candidatesShows.map((show) => (
                    <ShowCandidate
                        key={show.id}
                        $isActive={`${show.id}` === targetShowId}
                        onClick={() => setTargetShowId(`${show.id}`)}
                    >
                        {show?.imageUrl ? (
                            <CandidateCover src={show.imageUrl} alt={show.name} />
                        ) : (
                            <CandidateCover
                                src="https://image.ausha.co/default_cover.png"
                                alt={show.name}
                            />
                        )}

                        <CandidateTitle>{show.name}</CandidateTitle>
                    </ShowCandidate>
                ))}
            </ShowCandidates>

            <Actions>
                <Button variant="primary" onPress={submitMove} isLoading={moving}>
                    <FormattedMessage
                        defaultMessage="Déplacer {count, plural, one {l'épisode} other {les épisodes}}"
                        values={{ count }}
                    />
                </Button>
                <Button variant="link-secondary" onPress={onClose}>
                    <FormattedMessage defaultMessage="Annuler" />
                </Button>
            </Actions>
        </ModalStyled>
    );
};

const ModalStyled = styled(Modal)`
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1.5rem;
`;
const Actions = styled.div`
    align-self: stretch;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    & > :last-child {
        align-self: center;
    }
`;
const Title = styled.h1``;
const Description = styled.p``;
const ShowCandidates = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    list-style: none;
    max-height: 20rem;
    overflow-y: auto;
    padding: 0;
    margin: 0;
    padding-right: 1rem;
`;
const ShowCandidate = styled.li<{ $isActive: boolean }>`
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    gap: 0.5rem;
    border-radius: var(--r-m);
    &:hover {
        background-color: var(--neutral50);
    }

    ${({ $isActive }) =>
        $isActive &&
        `
        background-color: var(--primary50);
        font-weight: var(--fw-semibold);
    `}
`;
const CandidateCover = styled.img`
    width: 2rem;
    height: 2rem;
    border-radius: var(--r-s);
`;
const CandidateTitle = styled.span``;

export default MoveEpisodesModal;
