import { useMemo } from 'react';
import minMax from 'dayjs/plugin/minMax';
import styled from 'styled-components';
import {
    Area,
    AreaChart,
    CartesianGrid,
    ReferenceArea,
    ResponsiveContainer,
    Text as RechartsText,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { defineMessages, FormattedNumber, FormattedMessage, useIntl } from 'react-intl';
import { scaleLinear } from 'd3-scale';
import DeprecatedIcon from '@ui/atoms/DeprecatedIcon';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import statsIcon from '@public/icons/statistics.svg';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import { extractCssVariable } from '@/shared/utils/cssVariable';
import NoDataChartOverlay from '@/components/charts/NoData/NoDataChartOverlay';
import useAppleRankingOverallQuery from '@/queries/stats/useAppleRankingOverallQuery.hook';
import { useStatsDateSelectorContext } from '@/context/StatsDateSelectorContext';
import { useParams } from 'react-router';
import { useApplePodcastsStatsContext } from '@/context/ApplePodcastsStatsContext';
import useAppleRankingQuery from '@/queries/stats/useAppleRankingQuery.hook';
import { getLocalTimeZone } from '@internationalized/date';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import { APPLE_PODCASTS } from '@/shared/config/constants';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';

dayjs.extend(minMax);
dayjs.extend(isoWeek);

// Maximum ranking value
const MAX = 200;
// Minimum ranking value
const MIN = 1;
// Before this date, no stats data is available for "All categories" ranking
const NO_HISTORY_DATE = new Date('2019-08-21');

const messages = defineMessages({
    noHistory: { defaultMessage: "Pas d'historique pour cette période" },
});

const Wrapper = styled.div`
    font-weight: var(--fw-normal);
    font-size: var(--fs-body);
    position: relative;
    // The padding added to YAxis add a gap between the top of the graph and the top horizontal line of CartesianGrid
    // The top line of CartesianGrid can be hidden
    // Yeah, the top bar is the last child. Weird.
    .recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child {
        stroke-opacity: 0;
    }

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        padding: var(--container-padding);
    }
`;

const rankingTooltip = ({ active, payload, formatMatchingSelectedStep }) => {
    if (active && payload && payload?.length) {
        const data = payload[0].payload;

        if (data.ranking > MAX) {
            return null;
        }

        let variationColor;
        switch (Math.sign(data.variation)) {
            case -1:
                variationColor = 'error';
                break;
            case 0:
                variationColor = 'blocked';
                break;
            case 1:
                variationColor = 'online';
                break;
            default:
                variationColor = 'black';
        }

        return (
            <DeprecatedPaper background="white" raise="normal" overflowHide rounded>
                <DeprecatedPaper px={15} py={10} background="--neutral50">
                    <DeprecatedText align="center" color="--neutral500" weight="semibold">
                        {data[formatMatchingSelectedStep]}
                    </DeprecatedText>
                </DeprecatedPaper>
                <DeprecatedPaper flex align="center" justify="center" p={10}>
                    <DeprecatedIcon as={statsIcon} size={14} mr={5} color="black" />
                    <DeprecatedText weight="semibold">
                        <FormattedNumber value={data.ranking} />
                    </DeprecatedText>
                    {data.variation !== null && (
                        <DeprecatedText as="span" ml={5} color={variationColor}>
                            {`${data.variation >= 0 ? '+' : ''}${data.variation}`}
                        </DeprecatedText>
                    )}
                </DeprecatedPaper>
            </DeprecatedPaper>
        );
    }

    return null;
};

function RankingVariationGraphPlotLine({ categoryId, onHover }) {
    const { showId } = useParams();
    const { startDate, endDate } = useStatsDateSelectorContext();
    const { category, precision } = useApplePodcastsStatsContext();

    const intl = useIntl();
    // Use a D3 linear scale to have ticks uniformly spaced with human-readable
    // values (such as multiples of powers of 10).
    const scale = scaleLinear();
    // With the D3 linear scale, sometimes Recharts doesn't display a tick for the top of the graph.
    // The ticks function needs to be overridden to add this tick if necessary.
    const ticks = scale.ticks;
    scale.ticks = (...args) => {
        // domainStart is the top of the chart (tend towards 1).
        const domainStart = scale.domain()[0];
        // Ticks computed for a given ranking dataset
        const computedTicks = ticks(args);

        // If the domain start (top of the chart) is not included in the computed ticks, this tick needs
        // to be added manually.
        if (!computedTicks.includes(domainStart)) {
            return [domainStart, ...computedTicks];
        }

        return computedTicks;
    };

    const { data: user } = useUserQuery();
    const timezone = useMemo(() => user?.timezone || getLocalTimeZone(), [user]);

    const { data: overall } = useAppleRankingOverallQuery({
        showId,
        categoryId: category,
        timezone,
    });

    const noBestRankingData = !overall?.rankingDetails?.bestPosition?.bestPos;
    const bestPositionNeverInRanking = !overall?.rankingDetails?.bestPosition?.bestPos;
    const neverInRanking = !!(noBestRankingData || bestPositionNeverInRanking);

    const { data: show } = useShowQuery(showId);
    const hasApplePodcastsListeningLink = show?.listeningLinks?.data?.some(
        (link) => link.key === APPLE_PODCASTS,
    );

    const { data: stats, isLoading } = useAppleRankingQuery({
        showId,
        categoryId: category,
        start: startDate,
        end: endDate,
        precision,
        timezone,
        options: {
            enabled: hasApplePodcastsListeningLink,
        },
    });

    const data =
        stats?.data.map((current, index) => {
            const date = dayjs.unix(current.at);
            return {
                ...current,
                date: new Date(current.at * 1000),
                dateShortFormat:
                    user.language === 'fr' ? date.format('DD/MM/YYYY') : date.format('M/D/YY'),
                dateLongFormat: date.format('ddd ll'),
                weekFormat: (
                    <FormattedMessage
                        defaultMessage={`Semaine {week}`}
                        values={{ week: date.isoWeek() }}
                    />
                ),
                monthShortFormat: date.format('MM/YYYY'),
                monthLongFormat: date.format('MMMM YYYY'),
                yearFormat: date.format('YYYY'),
                id: index,
                // Virtually set out of ranking values to ranking 400, to deliberately display a peak below the axis line of the graph
                ranking: current.position ? current.position : 400,
            };
        }) || [];

    let xAxisDataKey;
    let formatMatchingSelectedStep;
    switch (precision) {
        case 'days':
            xAxisDataKey = 'dateShortFormat';
            formatMatchingSelectedStep = 'dateLongFormat';
            break;
        case 'weeks':
            xAxisDataKey = 'dateShortFormat';
            formatMatchingSelectedStep = 'weekFormat';
            break;
        case 'months':
            xAxisDataKey = 'monthShortFormat';
            formatMatchingSelectedStep = 'monthLongFormat';
            break;
        case 'years':
            xAxisDataKey = 'yearFormat';
            formatMatchingSelectedStep = 'yearFormat';
            break;
        default:
            xAxisDataKey = 'dateShortFormat';
            formatMatchingSelectedStep = 'dateLongFormat';
    }

    if (isLoading) {
        return (
            <Wrapper>
                <ResponsiveContainer height={350} width="100%">
                    <AreaChart opacity={0.5} margin={{ top: 35, right: 0, bottom: 0, left: 0 }}>
                        <CartesianGrid
                            stroke={extractCssVariable('--neutral100')}
                            vertical={false}
                        />
                        <XAxis height={30} />
                        <YAxis
                            axisLine={false}
                            domain={[MIN, MAX]}
                            reversed
                            tickLine={false}
                            ticks={[1, 50, 100, 150, 200]}
                            width={35}
                        />
                    </AreaChart>
                </ResponsiveContainer>
            </Wrapper>
        );
    }

    if (neverInRanking) {
        return (
            <Wrapper>
                <NoDataChartOverlay />
                <ResponsiveContainer height={350} width="100%">
                    <AreaChart margin={{ top: 35, right: 0, bottom: 0, left: 0 }}>
                        <CartesianGrid
                            stroke={extractCssVariable('--neutral100')}
                            vertical={false}
                        />
                        <XAxis height={30} />
                        <YAxis
                            axisLine={false}
                            domain={[MIN, MAX]}
                            reversed
                            tickLine={false}
                            ticks={[1, 50, 100, 150, 200]}
                            width={35}
                        />
                    </AreaChart>
                </ResponsiveContainer>
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            <ResponsiveContainer height={350} width="100%">
                <AreaChart
                    data={data}
                    baseValue="dataMax"
                    margin={{ top: 35, right: 0, bottom: 0, left: 0 }}
                    onMouseMove={({ activePayload }) =>
                        onHover(activePayload && activePayload[0] && activePayload[0].payload)
                    }
                >
                    <CartesianGrid stroke={extractCssVariable('--neutral100')} vertical={false} />
                    <XAxis
                        dataKey={xAxisDataKey}
                        height={30}
                        minTickGap={80}
                        padding={{ left: 20, right: 20 }}
                        tickMargin={5}
                        tickSize={5}
                    />
                    <YAxis
                        allowDataOverflow
                        allowDecimals={false}
                        axisLine={false}
                        dataKey="ranking"
                        domain={[
                            // Test if dataMin is an incoherent value
                            // If true, use top ranking value
                            // If false, use max value between
                            //     Top ranking value : ranking is close to top ranking, so plot line can reach the very top of the graph
                            //     A ranking value slightly better than dataMin : ranking is not close to top ranking, so leave a visible
                            //     progression gap between plot line and the very top of the graph
                            (dataMin) =>
                                dataMin > MAX
                                    ? MIN
                                    : Math.max(MIN, Math.floor((dataMin - 5) / 10) * 10),
                            // Test if dataMax is an incoherent value
                            // If true, use bottom ranking value
                            // If false, use min value between
                            //     Bottom ranking value : ranking is close to bottom ranking, so plot line can reach the very bottom of the graph
                            //     Test if dataMax is very close to top ranking
                            //         If true, use 8 to let D3 linear scale display gracefully a tick for each value between top ranking and 8
                            //         If false, use a ranking value slightly worse than dataMax : ranking is not close to bottom ranking, so leave a visible
                            //         decrease gap between plot line and the very bottom of the graph
                            (dataMax) =>
                                dataMax < MIN
                                    ? MAX
                                    : Math.min(
                                          MAX,
                                          dataMax <= 5 ? 8 : Math.ceil((dataMax + 5) / 10) * 10,
                                      ),
                        ]}
                        interval="preserveStart"
                        // allowDataOverflow property crop part of the stroke 2px width when ranking is 1
                        // Padding is required to have a full width stroke at the top of the chart
                        padding={{ top: 1 }}
                        reversed
                        scale={scale}
                        tickLine={false}
                        width={35}
                    />
                    <Tooltip
                        content={rankingTooltip}
                        cursor={false}
                        formatMatchingSelectedStep={formatMatchingSelectedStep}
                    />
                    <Area
                        activeDot={{ strokeWidth: 0, r: 5 }}
                        dataKey="ranking"
                        // Display dot if graph data contains only one value
                        dot={data.length === 1}
                        fill={extractCssVariable('--primary200')}
                        stroke={extractCssVariable('--primary')}
                        strokeWidth={2}
                    />
                    {/* For "All categories" ranking, add a panel on the graph to inform that there is no history data before a given date */}
                    {!categoryId && (
                        <ReferenceArea
                            // The panel should display on all the graph if NO_HISTORY_DATE is after the current date interval
                            // The panel should display through NO_HISTORY_DATE if NO_HISTORY_DATE is in the current date interval
                            x2={dayjs
                                .min(
                                    dayjs(NO_HISTORY_DATE),
                                    Array.isArray(data) && data.length
                                        ? dayjs(data[data.length - 1].date)
                                        : dayjs(),
                                )
                                .format('DD/MM/YY')}
                            fill="#E5E5E5"
                            label={({ viewBox }) => {
                                const { x, y, width, height } = viewBox;

                                // Doesn't display text label if not enough space available
                                if (width < 80) {
                                    return null;
                                }

                                return (
                                    <RechartsText
                                        x={x + width / 2}
                                        y={y + height / 2}
                                        fill="#666" // 🤘 THE NUMBER OF THE BEAAAAAST, SAAAAAACRIFICE, IS GOING OOONNN TONIIIIIIIGHT 🤘
                                        width={width - 10} // Padding of 10px
                                        textAnchor="middle"
                                        verticalAnchor="middle"
                                    >
                                        {intl.formatMessage(messages.noHistory)}
                                    </RechartsText>
                                );
                            }}
                        />
                    )}
                </AreaChart>
            </ResponsiveContainer>
        </Wrapper>
    );
}

export default RankingVariationGraphPlotLine;
