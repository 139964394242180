import { css } from 'styled-components';
import PropTypes from 'prop-types';
import { computeCssValue } from '../css';

/**
 * See https://chakra-ui.com/docs/features/style-props
 */
const flexMixin = css`
    gap: ${(props) => computeCssValue(props.$gap)};
    row-gap: ${(props) => computeCssValue(props.$rowGap)};
    column-gap: ${(props) => computeCssValue(props.$columnGap)};
    align-items: ${(props) => props.$alignItems ?? props.$align};
    align-content: ${(props) => props.$alignContent};
    justify-items: ${(props) => props.$justifyItems};
    justify-content: ${(props) => props.$justifyContent ?? props.$justify};
    flex-wrap: ${(props) => props.$flexWrap ?? props.$wrap};
    flex-direction: ${(props) => props.$flexDirection ?? props.$flexDir};
    flex: ${(props) => props.$flex};
    flex-flow: ${(props) => props.$flexFlow};
    flex-grow: ${(props) => props.$flexGrow};
    flex-shrink: ${(props) => props.$flexShrink};
    flex-basis: ${(props) => computeCssValue(props.$flexBasis)};
    justify-self: ${(props) => props.$justifySelf};
    align-self: ${(props) => props.$alignSelf};
    order: ${(props) => props.$order};
    place-items: ${(props) => props.$placeItems};
    place-content: ${(props) => props.$placeContent};
    place-self: ${(props) => props.$placeSelf};
`;

export const flexPropTypes = {
    $gap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $rowGap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $columnGap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $alignItems: PropTypes.string,
    $align: PropTypes.string, // Shortcut for alignItems
    $alignContent: PropTypes.string,
    $justifyItems: PropTypes.string,
    $justifyContent: PropTypes.string,
    $justify: PropTypes.string, // Shortcut for justifyContent
    $flexWrap: PropTypes.string,
    $wrap: PropTypes.string, // Shortcut for flexWrap
    $flexDirection: PropTypes.string,
    $flexDir: PropTypes.string, // Shortcut for flexDirection
    $flex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $flexFlow: PropTypes.string,
    $flexGrow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $flexShrink: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $flexBasis: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $justifySelf: PropTypes.string,
    $alignSelf: PropTypes.string,
    $order: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $placeItems: PropTypes.string,
    $placeContent: PropTypes.string,
    $placeSelf: PropTypes.string,
};

export default flexMixin;
