import { useQuery } from '@tanstack/react-query';
import { fetchShowListeningLinks } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import showListeningLinkKeys from '@/queries/showListeningLink/showListeningLinkKeys';

const transform = (data) => {
    // TODO: Do camelcase transformation in Axios interceptor
    const transformedData = camelcaseKeys(data, { deep: true });

    return transformedData;
};

const queryFn = async ({ queryKey: [{ showId }] }) => {
    const { data } = await fetchShowListeningLinks(showId);

    return transform(data);
};

const useShowListeningLinksQuery = (showId) => {
    return useQuery({
        queryKey: showListeningLinkKeys.listByShowId(showId),
        queryFn,
        enabled: !!showId,
    });
};

export default useShowListeningLinksQuery;
