import { FormattedMessage } from 'react-intl';
import { EPISODE_JOB_NAME } from '@/shared/config/constants';
import AudioFileJobsProgressTracker from '@ui/molecules/AudioFileJobsProgressTracker';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { PROGRESS_STATUSES } from '@/shared/config/constants';
import { AudioFileJobs } from '@/queries/episode/useEpisodeAudioFileJobsQuery';

const iconColor = {
    [PROGRESS_STATUSES.QUEUED]: '--neutral500',
    [PROGRESS_STATUSES.FAILED]: '--alert500',
    [PROGRESS_STATUSES.FINISHED]: '--primary',
    [PROGRESS_STATUSES.EXECUTING]: '--primary',
};

interface ClipProgressProps {
    progress: AudioFileJobs;
}

const ClipProgress = ({ progress }: ClipProgressProps) => {
    const isConvertToMp3Finished = progress?.[EPISODE_JOB_NAME.CONVERT_TO_MP3]?.isFinished;

    return (
        <Wrapper>
            <ResponsiveWrapper>
                <AudioFileJobsProgressTracker
                    progress={progress?.[EPISODE_JOB_NAME.CONVERT_TO_MP3]}
                    label={<FormattedMessage defaultMessage="Conversion du fichier audio" />}
                >
                    <ProgressIcon
                        icon={icon({
                            name: 'file-music',
                            style: 'solid',
                        })}
                        $status={progress?.[EPISODE_JOB_NAME.CONVERT_TO_MP3]?.status ?? ''}
                    />
                </AudioFileJobsProgressTracker>
                <Divider $isCompleted={isConvertToMp3Finished ?? false} />
                <AudioFileJobsProgressTracker
                    progress={progress?.[EPISODE_JOB_NAME.GENERATE_WAVEFORM]}
                    label={<FormattedMessage defaultMessage="Génération du chronographe" />}
                >
                    <ProgressIcon
                        icon={icon({
                            name: 'waveform-lines',
                            style: 'solid',
                        })}
                        $status={progress?.[EPISODE_JOB_NAME.GENERATE_WAVEFORM]?.status ?? ''}
                    />
                </AudioFileJobsProgressTracker>
            </ResponsiveWrapper>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`;
const ResponsiveWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    width: 100%;
    max-width: 40rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
        column-gap: 0.5rem;
        padding: 2rem;
    }
`;
const Divider = styled.div<{ $isCompleted: boolean }>`
    width: 0.25rem;
    height: 4rem;
    border-radius: var(--r-full);
    background-color: var(--neutral100);
    margin-left: 1.38rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex: 1;
        height: 0.25rem;
        margin-top: 1rem;
        margin-left: 0;
    }

    ${({ $isCompleted }) =>
        $isCompleted &&
        `
        background-color: var(--primary);
    `}
`;
const ProgressIcon = styled(FontAwesomeIcon)<{ $status: string }>`
    width: 1rem;
    height: 1rem;
    color: var(--neutral500);

    ${({ $status }) => iconColor[$status] && `color: var(${iconColor[$status]});`}
`;

export default ClipProgress;
