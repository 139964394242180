import { FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputTextController } from '@ui/atoms/InputText';
import Button from '@ui/atoms/Button';
import styled from 'styled-components';
import useTrackersFormSchema from '@/components/unorganized/Trackers/useTrackersFormSchema.hook';
import UiKitTooltip from '@ui/atoms/UiKitTooltip';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import {
    TRACKERS_FACEBOOK_PIXEL,
    TRACKERS_GOOGLE_ANALYTICS,
    TRACKERS_LINKEDIN_PIXEL,
} from '@/shared/config/constants';

const TrackersForm = ({ trackers, onSubmit, isMutating }) => {
    const trackersDefaultValues = {
        [TRACKERS_GOOGLE_ANALYTICS]: trackers.find(
            (tracker) => 'google_analytics' === tracker.platform,
        )?.tracker,
        [TRACKERS_FACEBOOK_PIXEL]: trackers.find((tracker) => 'facebook_pixel' === tracker.platform)
            ?.tracker,
        [TRACKERS_LINKEDIN_PIXEL]: trackers.find((tracker) => 'linkedin_pixel' === tracker.platform)
            ?.tracker,
    };
    const trackersFormData = {
        [TRACKERS_GOOGLE_ANALYTICS]: {
            label: <FormattedMessage defaultMessage="ID de suivi Google Analytics" />,
            placeholder: 'UA-123456789-1',
            tooltipContent: (
                <FormattedMessage
                    defaultMessage={
                        'Pour analyser l\'audience de votre Site Web, renseignez ici votre ID de suivi qui se trouve dans "Administration" puis "Informations de suivi" puis "Code de suivi."'
                    }
                />
            ),
            maxLength: 15,
        },
        [TRACKERS_FACEBOOK_PIXEL]: {
            label: <FormattedMessage defaultMessage="Facebook Pixel" />,
            placeholder: '1234567890123456',
            tooltipContent: (
                <FormattedMessage defaultMessage="Pour générer votre pixel Facebook, rendez-vous dans votre compte publicitaire sur Facebook, rubrique “Mesure et rapport” puis “Pixels.”" />
            ),
            maxLength: 16,
        },
        [TRACKERS_LINKEDIN_PIXEL]: {
            label: <FormattedMessage defaultMessage="LinkedIn Pixel" />,
            placeholder: '341278569125634',
            tooltipContent: (
                <FormattedMessage defaultMessage="Pour générer votre pixel LinkedIn, rendez-vous sur votre compte Linkedin Ads, cliquez ensuite sur l’onglet « Données démographiques du site web » puis sur « Configurer votre insight tag »." />
            ),
            maxLength: 15,
        },
    };
    const schema = useTrackersFormSchema();
    const {
        control,
        handleSubmit,
        formState: { isDirty },
    } = useForm({
        mode: 'onBlur',
        defaultValues: { ...trackersDefaultValues },
        resolver: yupResolver(schema),
    });

    return (
        <FormElement onSubmit={handleSubmit(onSubmit)}>
            {Object.entries(trackersFormData).map(([key, value]) => (
                <InputTextController
                    control={control}
                    key={key}
                    name={key}
                    placeholder={value.placeholder}
                    label={value.label}
                    maxLength={value.maxLength}
                    tooltip={
                        <UiKitTooltip variant="dark" position="top" content={value.tooltipContent}>
                            <TooltipIcon
                                icon={icon({ name: 'circle-question', style: 'solid' })}
                                size="sm"
                            />
                        </UiKitTooltip>
                    }
                />
            ))}
            <Button type="submit" isDisabled={!isDirty} isLoading={isMutating}>
                <FormattedMessage defaultMessage="Enregistrer" />
            </Button>
        </FormElement>
    );
};

const FormElement = styled.form`
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    align-items: center;
`;

const TooltipIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
`;

TrackersForm.propTypes = {
    trackers: PropTypes.objectOf(PropTypes.string).isRequired,
    onSubmit: PropTypes.func.isRequired,
    isMutating: PropTypes.bool,
};

export default TrackersForm;
