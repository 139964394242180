import useSubscriptionQuery from '@queries/subscription/useSubscriptionQuery.hook';
import { PRICING } from '@/utils/pricing';

const maxSelectableLookup: { [key: string]: number } = {
    [PRICING.LAUNCH]: 3,
    [PRICING.BOOST]: 5,
    [PRICING.SUPERSONIC]: 7,
    [PRICING.ENTERPRISE]: Infinity,
};

const useStatsSelectableEpisodes = () => {
    const subscription = useSubscriptionQuery();
    if (!subscription.data) return { numberOfEpisodesSelectable: 0 };
    const pricing = subscription?.data?.pricing;
    return maxSelectableLookup[pricing] || 0;
};

export default useStatsSelectableEpisodes;
