import Model from './Model';

class SystemModel extends Model {
    static attributes = {
        system: 'os_name',
        count: 'count',
        percentage: 'percentage',
    };
}

export default SystemModel;
