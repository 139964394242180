import { useState } from 'react';
import PropTypes from 'prop-types';
import Text from '@ui/atoms/Text';
import Uploader from '@ui/smarties/Uploader';
import styled from 'styled-components';
import Button from '@ui/atoms/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Cluster from '@ui/layout/Cluster';
import Img from '@ui/atoms/Img';
import { IMG_TYPES } from '@/utils/constants';
import { useEffect } from 'react';
import { experimentalCheckImgDimensions, checkImgFormat, checkImgSize } from '@/helpers';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import { FORM_VALIDATION_MESSAGE } from '@/utils/validationMessage';

const InputImgUploader = ({
    label,
    description,
    objectFit,
    name,
    tooltip,
    value,
    errorMessage,
    onChange,
    onDeleteFile,
    className,
    accept,
    dimensions,
}) => {
    const [src, setSrc] = useState(value || null);
    const [error, setError] = useState(null);
    const hasErrors = !!error || !!errorMessage;
    const hasSrc = !!src;
    const intl = useIntl();
    const { data: user } = useUserQuery();
    const locale = user?.language?.toLowerCase();
    const IMG_DIMENSIONS = dimensions || { minWidth: 400, minHeight: 400 };

    const handleChange = (file) => {
        if (!file) {
            setSrc(null);
            return;
        }
        if (typeof file === 'string') {
            setSrc(file);
            onChange && onChange(file);
            return;
        }
        handleFile(file);
        onChange && onChange(file);
    };

    const handleFile = async (file) => {
        const reader = new FileReader();
        const img = new Image();
        const imageHasGoodFormat = checkImgFormat(file);

        if (!imageHasGoodFormat) {
            setError(
                intl.formatMessage(FORM_VALIDATION_MESSAGE.acceptedMimes, {
                    acceptedMimes: accept,
                }),
            );
            return;
        }

        const imageHasGoodSize = checkImgSize(file, locale, 10);

        if (!imageHasGoodSize) {
            setError(intl.formatMessage(FORM_VALIDATION_MESSAGE.imgMaxSize, { max: 10 }));
            return;
        }
        const imageHasGoodDimensions = await experimentalCheckImgDimensions(file, IMG_DIMENSIONS);

        if (!imageHasGoodDimensions) {
            console.log(IMG_DIMENSIONS);
            setError(intl.formatMessage(FORM_VALIDATION_MESSAGE.imgMinDimensions, IMG_DIMENSIONS));
            return;
        }
        setError(null);

        reader.onload = () => {
            if (src === reader.result) return;
            setSrc(reader.result);
            img.src = reader.result;
        };
        reader.readAsDataURL(file);
    };

    const handleDeleteFile = () => {
        onChange && onChange(null);
        setSrc(null);
        onDeleteFile && onDeleteFile();
    };

    useEffect(() => {
        handleChange(value);
    }, [value]);

    return (
        <Wrapper className={className}>
            <Cluster $gap="1rem">
                <ImgPreview src={src} objectFit={objectFit} />
                <UploaderWrapper>
                    <LabelOuterWrapper>
                        <LabelWrapper>
                            <Text as="label" fontWeight="--fw-semibold">
                                {label}
                            </Text>
                            {tooltip}
                        </LabelWrapper>
                        {description}
                    </LabelOuterWrapper>
                    <ButtonsWrapper>
                        <Uploader accept={accept} name={name} onChange={handleChange}>
                            <Button
                                startIcon={
                                    hasSrc ? (
                                        <EditIcon
                                            icon={icon({
                                                name: 'pen-line',
                                                style: 'solid',
                                            })}
                                        />
                                    ) : (
                                        <EditIcon
                                            icon={icon({
                                                name: 'arrow-up-from-line',
                                                style: 'solid',
                                            })}
                                        />
                                    )
                                }
                                variant="secondary"
                            >
                                {hasSrc ? (
                                    <FormattedMessage defaultMessage="Modifier" />
                                ) : (
                                    <FormattedMessage defaultMessage="Ajouter" />
                                )}
                            </Button>
                        </Uploader>
                        {hasSrc && (
                            <DeleteButton
                                onPress={handleDeleteFile}
                                variant="ghost"
                                startIcon={
                                    <TrashIcon
                                        icon={icon({
                                            name: 'trash',
                                            style: 'solid',
                                        })}
                                    />
                                }
                            >
                                <FormattedMessage defaultMessage="Supprimer" />
                            </DeleteButton>
                        )}
                    </ButtonsWrapper>
                </UploaderWrapper>
            </Cluster>
            {hasErrors && <Text color="--alert500">{error || errorMessage}</Text>}
        </Wrapper>
    );
};

const ImgPreview = styled(Img)`
    border-radius: 0.75rem;
    width: 6rem;
    height: 6rem;

    ${({ theme }) => theme.mediaQueries.tabletAndUp} {
        width: 10rem;
        height: 10rem;
    }
`;
const UploaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    flex: 1;
`;
const LabelWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.25rem;
`;
const LabelOuterWrapper = styled.label`
    display: flex;
    flex-direction: column;
`;
const DeleteButton = styled(Button)`
    color: var(--alert500);
    padding: 0;
`;
const ButtonIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const TrashIcon = styled(ButtonIcon)`
    color: var(--alert500);
`;
const EditIcon = styled(ButtonIcon)`
    color: var(--black);
`;
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
`;
const ButtonsWrapper = styled.div`
    display: flex;
    column-gap: 0.5rem;

    ${({ theme }) => theme.mediaQueries.tabletAndUp} {
        column-gap: 1rem;
    }
`;

InputImgUploader.propTypes = {
    label: PropTypes.node,
    description: PropTypes.node,
    objectFit: PropTypes.string,
    name: PropTypes.string,
    accept: PropTypes.string,
    tooltip: PropTypes.node,
    value: PropTypes.string,
    className: PropTypes.string,
    errorMessage: PropTypes.string,
    onChange: PropTypes.func,
    onDeleteFile: PropTypes.func,
    dimensions: PropTypes.shape({
        minWidth: PropTypes.number,
        minHeight: PropTypes.number,
    }),
};

InputImgUploader.defaultProps = {
    objectFit: 'cover',
    accept: IMG_TYPES.join(','),
    name: '',
    tooltip: null,
    onChange: () => {},
    onDeleteFile: () => {},
};

export default InputImgUploader;
