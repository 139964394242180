import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import BaseButton from '../BaseButton';

const Button = forwardRef((props, forwardedRef) => <BaseButton {...props} ref={forwardedRef} />);

Button.propTypes = {
    variant: PropTypes.oneOf(['primary', 'secondary', 'ghost']),
    size: PropTypes.oneOf(['medium', 'small']),
    background: PropTypes.string,
    color: PropTypes.string,
    startIcon: PropTypes.element,
    endIcon: PropTypes.element,
    isRound: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    loadingIcon: PropTypes.element,
    className: PropTypes.string,
    type: PropTypes.oneOf(['button', 'submit']),
    children: PropTypes.node,
};

Button.defaultProps = {
    size: 'medium',
    type: 'button',
};

Button.displayName = 'Button';

export default Button;
