import { ChannelGuestUserPermissionsSummary } from '@/queries/guest/useChannelGuestUserPermissionsSummary.hook';
import styled from 'styled-components';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import useChannelGuestsEditPermissionFormSchema, {
    ChannelGuestsEditPermissionFormSchema,
} from './useChannelGuestsEditPermissionFormSchema';
import { zodResolver } from '@hookform/resolvers/zod';
import ChannelGuestsEditPermissionHeader from './ChannelGuestsEditPermissionHeader/ChannelGuestsEditPermissionHeader';
import ChannelGuestsEditPermissionTable from './ChannelGuestsEditPermissionTable';
import Stack from '@/components/ui/layout/Stack';
import Button from '@/components/Button';
import { FormattedMessage } from 'react-intl';
import { useEffect } from 'react';
import { ChannelGuestUserRole } from '@/shared/config/constants';

interface ChannelGuestsEditPermissionFormProps {
    onSubmit: (data: ChannelGuestsEditPermissionFormSchema) => void;
    onClose: () => void;
    values: ChannelGuestUserPermissionsSummary;
}

const ChannelGuestsEditPermissionForm = ({
    onSubmit,
    onClose,
    values,
}: ChannelGuestsEditPermissionFormProps) => {
    const { user, ...v } = values;
    const schema = useChannelGuestsEditPermissionFormSchema();
    const methods = useForm<ChannelGuestsEditPermissionFormSchema>({
        mode: 'onChange',
        values: v,
        resolver: zodResolver(schema),
    });
    const { setValue, getValues, watch, handleSubmit, formState } = methods;
    const { isDirty, isValid } = formState;
    const { fields, update } = useFieldArray({
        control: methods.control,
        name: 'shows',
        keyName: 'key',
    });

    /* Update mainRole on show role change */
    useEffect(() => {
        const subscription = watch((_, { type, name }) => {
            /* Avoid trigger effect on first load */
            if (type !== 'change' || name === 'mainRole') return;
            const shows = getValues('shows');

            /* If there is no show with a role, do nothing */
            if (shows.length === 0) return;

            const mainRoleShouldBeMixed = !shows.every((show) => show.role === shows[0].role);

            /* Show has at least one different role, mainRole should be set to mixed */
            if (mainRoleShouldBeMixed) {
                setValue('mainRole', 'mixed', { shouldDirty: true, shouldValidate: true });
                return;
            }

            /* Every show has the same role, mainRole should be set to that role */
            setValue('mainRole', shows[0].role as ChannelGuestUserRole, {
                shouldDirty: true,
                shouldValidate: true,
            });
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    /* Update all shows with the same role on mainRole change */
    useEffect(() => {
        const subscription = watch(({ mainRole, shows }, { name, type }) => {
            /* Avoid trigger effect on first load */
            if (type !== 'change' || name !== 'mainRole') return;

            /* If mainRole is not mixed, update all shows with the same role */
            if (!shows || !mainRole || mainRole === 'mixed') return;

            shows.forEach((_, index) => {
                update(index, { ...fields[index], role: mainRole });
            });
        });

        return () => subscription.unsubscribe();
    }, [watch, fields, update]);

    return (
        <FormProvider {...methods}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <ChannelGuestsEditPermissionHeader user={user} />
                <Stack>
                    <ChannelGuestsEditPermissionTable shows={fields} />
                    <Divider />
                    <Actions>
                        <CancelButton variant="ghost" onPress={onClose}>
                            <FormattedMessage defaultMessage="Annuler" />
                        </CancelButton>
                        <MobileButton type="submit" isDisabled={!isDirty || !isValid}>
                            <FormattedMessage defaultMessage="Enregistrer" />
                        </MobileButton>
                        <DesktopButton type="submit" isDisabled={!isDirty || !isValid}>
                            <FormattedMessage defaultMessage="Enregistrer les modifications" />
                        </DesktopButton>
                    </Actions>
                </Stack>
            </Form>
        </FormProvider>
    );
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`;
const Divider = styled.div`
    height: 1px;
    background-color: var(--neutral100);
    margin-bottom: 1.5rem;
`;
const Actions = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 1rem;
`;
const CancelButton = styled(Button)`
    color: var(--neutral500);
`;
const MobileButton = styled(Button)`
    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: none;
    }
`;
const DesktopButton = styled(Button)`
    display: none;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: initial;
    }
`;

export default ChannelGuestsEditPermissionForm;
