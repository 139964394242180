import PsoGraphSkeleton from '@public/images/pso-graph-skeleton.svg?url';
import styled, { css, keyframes } from 'styled-components';

const PsoVisibilityScoreHistorySkeleton = () => {
    return (
        <PsoVisibilityScoreHistorySkeletonWrapper>
            <Bar />
            <Graph src={PsoGraphSkeleton} />
        </PsoVisibilityScoreHistorySkeletonWrapper>
    );
};

const shimmer = keyframes`
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
`;
const shimmering = css`
    background: var(--neutral50);
    background-image: linear-gradient(
        to right,
        var(--neutral50) 0%,
        #edeef1 20%,
        var(--neutral50) 40%,
        var(--neutral50) 100%
    );
    background-repeat: no-repeat;
    background-size: 800px 104px;
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${shimmer};
    animation-timing-function: linear;
`;
const PsoVisibilityScoreHistorySkeletonWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1.5rem;
`;
const Bar = styled.div`
    width: 10rem;
    height: 0.75rem;
    background-color: var(--neutral100);
    border-radius: var(--r-full);
    ${shimmering};
`;
const Graph = styled.img`
    width: 100%;
    flex-grow: 1;
`;

export default PsoVisibilityScoreHistorySkeleton;
