import { useController } from 'react-hook-form';
import ColorPicker from './ColorPicker';
import styled from 'styled-components';
import Stack from '@ui/layout/Stack';

const ErrorMessage = styled.div`
    color: var(--alert);
    font-size: 12px;
    font-weight: var(--fw-semibold);
`;

export const ColorPickerController = (props) => {
    const { control, name, onChange, onBlur, children, isDisabled, ...rest } = props;
    const { field } = useController({
        name,
        control,
    });

    return (
        <Stack $gap="0.5rem">
            <ColorPicker
                onChange={(value) => {
                    const forwardedValue = !value ? null : value;
                    onChange && onChange(forwardedValue);
                    field.onChange(forwardedValue);
                }}
                onBlur={(...v) => {
                    onBlur && onBlur(...v);
                    field.onBlur(...v);
                }}
                value={field.value}
                name={field.name}
                disabled={isDisabled}
                {...rest}
            >
                {children}
            </ColorPicker>
            {field.error && <ErrorMessage>{field.error}</ErrorMessage>}
        </Stack>
    );
};
