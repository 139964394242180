import { useState, useMemo, useEffect } from 'react';
import { useRouteMatch, Link } from 'react-router-dom';
import { AnimateSharedLayout, useCycle } from 'framer-motion';
import { FormattedMessage, injectIntl } from 'react-intl';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import { RouteHorizontalTabSlideContext } from '@ui/molecules/HorizontalTabSlide';
import { DeprecatedIcon } from '@ui/atoms/DeprecatedIcon';
import { DeprecatedText } from '@ui/atoms/DeprecatedText';
import arrow from '@public/icons/chevrondown.svg';
import Portal from '@ui/molecules/Portal';
import DeprecatedWhitePaper from '@ui/atoms/DeprecatedWhitePaper';
import closeBack from '@public/icons/close.svg';
import MobileTabsItem from '@ui/atoms/MobileTabsItem/MobileTabsItem';
import Desktop from '@ui/molecules/Desktop';
import MobileOrTablet from '@ui/molecules/MobileOrTablet';
import Text from '@ui/atoms/Text';
import { GUARD_DISALLOW_REASONS } from '@/shared/config/constants';
import CommunicationTabItemAccess from '@/components/unorganized/CommunicationTabItemAccess';
import styled, { css } from 'styled-components';

const TabTitle = styled(Text)`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: var(--fw-semibold);
    &:hover {
        color: var(--primary500);
    }

    ${({ isActive }) =>
        isActive &&
        css`
            color: var(--primary500);
        `};
`;

const CommunicationTabsWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 2rem;
    position: relative;
    height: 2.5rem;
`;

const DesktopTabs = ({
    tabs,
    pricing,
    userOptions,
    selected,
    pageUrl,
    setterTab,
    simpleSwitch,
    getTabValue,
    archived,
}) => {
    const handleSwitchTab = (item) => {
        setterTab(item);
        if (simpleSwitch && getTabValue) getTabValue(item);
    };

    return (
        <Desktop>
            <AnimateSharedLayout>
                <CommunicationTabsWrapper>
                    {Object.keys(tabs).map((item) => (
                        <CommunicationTabItemAccess
                            key={tabs[item]}
                            isSelected={selected === item}
                            onClick={() => handleSwitchTab(item)}
                            as={Link}
                            to={selected !== item ? `${pageUrl}/${item}` : undefined}
                            simpleSwitch={simpleSwitch}
                            pricing={pricing}
                            requiredUserOptions={userOptions}
                            disallowedUserRoles={tabs[item].disabledFor}
                            tooltip={{
                                [GUARD_DISALLOW_REASONS.INSUFFICIENT_ROLE]: (
                                    <Text>
                                        <FormattedMessage defaultMessage="Vous devez être administrateur de l'émission pour accéder à cette fonctionnalité." />
                                    </Text>
                                ),
                            }}
                            disallowAccess={archived && tabs[item].disableOnArchived}
                        >
                            <TabTitle isActive={selected === item}>
                                {tabs[item].icon}
                                {tabs[item].title}
                            </TabTitle>
                        </CommunicationTabItemAccess>
                    ))}
                </CommunicationTabsWrapper>
            </AnimateSharedLayout>
        </Desktop>
    );
};

const MobileTabs = ({ tabs, selected, archived, showRole, pageUrl }) => {
    const [open, opened] = useCycle(false, true);
    return (
        <MobileOrTablet>
            <DeprecatedPaper
                flex
                x="initial"
                xR={20}
                y={70}
                key="title"
                w="max-content"
                align="center"
                onClick={() => opened(1)}
                justify="flex-end"
                z={3}
            >
                <DeprecatedText size="big" color="--primary" weight="semibold" flex>
                    {tabs[selected].title}
                    <DeprecatedIcon ml={5} as={arrow} color="--primary" />
                </DeprecatedText>
            </DeprecatedPaper>
            {open && (
                <Portal id="mobileTabs" key="mobileTabs">
                    <DeprecatedWhitePaper
                        bb={1}
                        rounded={false}
                        w="100%"
                        pos="fixed"
                        y={0}
                        z={1001}
                        px={30}
                        py={20}
                        flex
                        align="center"
                    >
                        <DeprecatedIcon as={closeBack} onClick={() => opened(0)} color="black" />
                    </DeprecatedWhitePaper>
                    <DeprecatedPaper
                        key={0}
                        pos="fixed"
                        y={51}
                        x={0}
                        w="100vw"
                        h="100vh"
                        p={30}
                        z={1001}
                        background="white"
                        raise="normal"
                        overflowY="scroll"
                        pb={200}
                        css="list-style: none;"
                        as="ul"
                    >
                        {Object.keys(tabs).map((item) => (
                            <MobileTabsItem
                                isSelected={selected === item}
                                onClick={() => opened(0)}
                                key={item}
                                to={selected !== item ? `${pageUrl}/${item}` : undefined}
                            >
                                {tabs[item].icon}
                                <DeprecatedText
                                    weight="semibold"
                                    disabled={
                                        (archived && tabs[item].disableOnArchived) ||
                                        tabs[item].disabledFor.includes(showRole)
                                    }
                                    colorHover="--primary"
                                >
                                    {tabs[item].title}
                                </DeprecatedText>
                            </MobileTabsItem>
                        ))}
                    </DeprecatedPaper>
                </Portal>
            )}
        </MobileOrTablet>
    );
};

const CommunicationTabs = injectIntl(
    ({
        tabs,
        pageUrl,
        simpleSwitch,
        getTabValue,
        active = true,
        pricing,
        userOptions,
        archived,
        showRole,
    }) => {
        const { params } = useRouteMatch();
        const [selectedRoute, setSelectedRoute] = useState(params.tab);
        const [selectedTab, setSelectedTab] = useState(Object.keys(tabs)[0]);
        useMemo(() => setSelectedRoute(params.tab), [params.tab]);
        useEffect(() => {
            if (simpleSwitch && getTabValue) getTabValue(selectedTab);
        }, []);

        if (!active) return null;

        const getSelected = () => {
            const tabState = simpleSwitch ? 'switch' : 'routeLink';
            const tabSelector = {
                routeLink: { setterTab: (item) => setSelectedRoute(item), selected: selectedRoute },
                switch: { setterTab: (item) => setSelectedTab(item), selected: selectedTab },
            };
            return tabSelector[tabState];
        };

        const { selected, setterTab } = getSelected();

        return (
            <RouteHorizontalTabSlideContext.Provider value={tabs}>
                <DeprecatedPaper flex w="max-content" minH={40}>
                    <DesktopTabs
                        tabs={tabs}
                        pricing={pricing}
                        userOptions={userOptions}
                        selected={selected}
                        pageUrl={pageUrl}
                        setterTab={setterTab}
                        simpleSwitch={simpleSwitch}
                        getTabValue={getTabValue}
                        archived={archived}
                        showRole={showRole}
                    />
                    <MobileTabs
                        tabs={tabs}
                        selected={selected}
                        archived={archived}
                        showRole={showRole}
                        pageUrl={pageUrl}
                    />
                </DeprecatedPaper>
            </RouteHorizontalTabSlideContext.Provider>
        );
    },
);

export default CommunicationTabs;
