import { FormattedDate, FormattedMessage } from 'react-intl';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import { CopyLinkButton, OpenLinkButton } from './UsefulLinkButtons';
import RouterLink from '@/components/Link/RouterLink';
import Cluster from '@ui/layout/Cluster';
import Text from '@ui/atoms/Text';
import styled from 'styled-components';
import useEpisodeQuery from '@queries/episode/useEpisodeQuery.hook';
import { useParams } from 'react-router-dom';

const LinksWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border-radius: var(--r-xs);
    background-color: var(--white);

    & > ${Cluster} {
        flex-grow: 1;
        flex-basis: 100%;
    }

    ${(p) => p.theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
        align-items: center;
    }
`;

const PublishedDate = ({ publishedAt }) => (
    <DeprecatedText color="--neutral500" flex align="center" nowrap mr={15}>
        <FormattedMessage defaultMessage="En ligne le" />
        <DeprecatedText color="--neutral500" as="span" ml={10}>
            <FormattedDate day="2-digit" month="2-digit" year="numeric" value={publishedAt} />
        </DeprecatedText>
    </DeprecatedText>
);

const EpisodeUsefulLinksEnable = ({ usefulLinksList }) => {
    const { showId, ...params } = useParams();
    const { data: episode } = useEpisodeQuery({ episodeId: params?.episodeId || params?.podId });

    return usefulLinksList.map((usefulLink, i) => {
        if (usefulLink.link === 'customUrl' && !episode?.useCustomUrl) return null;

        return (
            <LinksWrapper key={i}>
                <Cluster $align="center" $gap="0.5rem">
                    {usefulLink.icon}
                    <Text>{usefulLink.label}</Text>
                </Cluster>

                {(usefulLink.isNavigationLink || usefulLink.isOpenLink || usefulLink.isCopyURL) && (
                    <DeprecatedPaper flex justify="flex-end" gap={10}>
                        {usefulLink.isNavigationLink && (
                            <RouterLink
                                to={`/app/show/${showId}/communication/player/episodes/${episode?.publicId}`}
                                isDisabled={!usefulLink.isNavigationLink}
                                variant="button-tertiary"
                            >
                                {usefulLink.buttonLabel}
                            </RouterLink>
                        )}
                        {episode?.status === 'planned' && !!usefulLink.isPublishedDate ? (
                            <PublishedDate publishedAt={new Date(episode?.publishedAt)} />
                        ) : (
                            <OpenLinkButton
                                link={episode?.[usefulLink.link]}
                                isActive={!!usefulLink.isOpenLink}
                            />
                        )}
                        <CopyLinkButton
                            link={episode?.[usefulLink.link]}
                            isActive={!!usefulLink.isCopyURL}
                        />
                    </DeprecatedPaper>
                )}
            </LinksWrapper>
        );
    });
};

export default EpisodeUsefulLinksEnable;
