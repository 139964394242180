import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateTrackers } from '@/api';
import trackersKeys from '@app/tabs/Trackers/trackersKeys';

export function useUpdateTrackers() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (data) => {
            return updateTrackers(data.showId, data.trackers);
        },
        onSuccess: (data, { showId }) => {
            return queryClient.invalidateQueries({ queryKey: trackersKeys.listByShowId(showId) });
        },
    });
}
