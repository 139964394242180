import { useForm } from 'react-hook-form';
import useShowEditFormFormSchema, { ShowEditFormSchema } from './useShowEditFormSchema.hook';
import { zodResolver } from '@hookform/resolvers/zod';
import styled from 'styled-components';
import { InputTextController } from '@ui/atoms/InputText';
import { FormattedMessage, useIntl } from 'react-intl';
import { InputRichTextController } from '@ui/atoms/InputRichText';
import { ComboBoxController } from '@ui/atoms/ComboBox';
import ListBoxItem, { ListBoxItem as IListBoxItem } from '@ui/atoms/ListBoxItem';
import Stack from '@ui/layout/Stack';
import { InputImgUploaderController } from '@/components/ui/molecules/InputImgUploader';
import Text from '@ui/atoms/Text';
import { InputTagController } from '@ui/atoms/InputTag/InputTag.controller';
import ProtectedInputText from '@ui/atoms/ProtectedInputText';
import { Collection, Section, TooltipTrigger } from 'react-aria-components';
import Tooltip, { TooltipTriggerWrapper } from '@ui/Tooltip';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CardSelectListController } from '@ui/molecules/CardSelectList';
import CardSelect from '@ui/atoms/CardSelect';
import { ToggleController } from '@ui/atoms/Toggle';
import UnsavedFormModal from '../../UnsavedFormModal';
import Button from '@/components/Button';
import { useResponsive } from '@/shared/hooks/useResponsive';
import saveShowEditMutation from '../saveShowEditMutation.hook';
import { useBodyToastQueue } from '@/shared/hooks/useBodyToastQueue.hook';
import { useParams } from 'react-router';
import { AxiosError } from 'axios';
import useSubscriptionQuery from '@queries/subscription/useSubscriptionQuery.hook';
import useShowQuery from '@queries/show/useShowQuery.hook';
import { PRICING } from '@/shared/config/pricing';

const FormTooltip = ({ children }: { children: React.ReactNode }) => (
    <TooltipTrigger delay={0} closeDelay={0}>
        <TooltipTriggerWrapper>
            <HelpIcon icon={icon({ name: 'info-circle', style: 'solid' })} />
        </TooltipTriggerWrapper>
        <Tooltip placement="top">{children}</Tooltip>
    </TooltipTrigger>
);

interface ShowEditFormProps {
    languages: { id: number; name: string }[];
    categories: IListBoxItem[];
    values: ShowEditFormSchema;
    isShowPmp: boolean;
    userLocale: string;
}

const ShowEditForm = ({
    languages,
    categories,
    values,
    isShowPmp,
    userLocale,
}: ShowEditFormProps) => {
    const { showId } = useParams<{ showId: string }>();
    const { data: show } = useShowQuery(showId);
    const { data: subscription } = useSubscriptionQuery();
    const intl = useIntl();
    const { isDesktop } = useResponsive();
    const schema = useShowEditFormFormSchema(userLocale);
    const { control, watch, formState, handleSubmit, setValue, setError } =
        useForm<ShowEditFormSchema>({
            mode: 'onBlur',
            resolver: zodResolver(schema),
            values,
        });
    const categoryId = watch('categoryId');
    const updateShow = saveShowEditMutation();
    const toast = useBodyToastQueue();

    const onSubmit = (data: ShowEditFormSchema) => {
        updateShow.mutate(
            {
                showId,
                data,
            },
            {
                onSuccess: () => {
                    toast.success();
                },
                onError: (error) => {
                    toast.alert();
                    if ((error as AxiosError).response?.status === 422) {
                        setError('slug', {
                            type: 'manual',
                            message: intl.formatMessage({
                                defaultMessage: 'Ce permalien est déjà utilisé',
                            }),
                        });
                        return;
                    }
                },
            },
        );
    };

    return (
        <>
            <UnsavedFormModal shouldBlockNavigation={formState.isDirty} />
            <Form onSubmit={handleSubmit(onSubmit)}>
                <InputTextController
                    name="name"
                    label={<FormattedMessage defaultMessage="Nom de votre émission" />}
                    control={control}
                    maxLength={140}
                />
                <InputRichTextController
                    name="htmlDescription"
                    control={control}
                    label={<FormattedMessage defaultMessage="Description" />}
                    charactersLimit={3900}
                    htmlLinksDisabled={
                        show?.ownerPricing
                            ? show?.ownerPricing === PRICING.FREEMIUM
                            : subscription?.isFreemium
                    }
                    htmlLinksDisabledReason={
                        <FormattedMessage defaultMessage="L'ajout de liens n'est disponible que pour les offres payantes. 🙏 " />
                    }
                />
                {isShowPmp && (
                    <InputRichTextController
                        name="descriptionFooter"
                        control={control}
                        label={<FormattedMessage defaultMessage="Signature de la description" />}
                        charactersLimit={3900}
                        tooltip={
                            <FormTooltip>
                                <FormattedMessage defaultMessage="Renseignez une fin de description qui sera ajoutée automatiquement à tous vos futurs épisodes. Indiquez des crédits récurrents, des liens vers vos réseaux sociaux ou tout autre contenu que vous souhaitez intégrer au bas de chaque description." />
                            </FormTooltip>
                        }
                    />
                )}
                <InputImgUploaderController
                    name="imageUrl"
                    control={control}
                    label={<FormattedMessage defaultMessage="Pochette d'émission" />}
                    description={
                        <Text color="--neutral500">
                            <FormattedMessage
                                defaultMessage="Taille idéale: 1400x1400px{break}Taille min: 400x400px{break}Format: JPG ou PNG{break}Poids max: 10Mo"
                                values={{ break: <br /> }}
                            />
                        </Text>
                    }
                    accept=".png, .jpg, .jpeg"
                />
                <InputTextController
                    name="author"
                    label={<FormattedMessage defaultMessage="Auteur" />}
                    placeholder={intl.formatMessage({
                        defaultMessage: 'Thomas Pesquet',
                    })}
                    control={control}
                    tooltip={
                        <FormTooltip>
                            <FormattedMessage defaultMessage="Nom du créateur affiché publiquement sur les plateformes d'écoute comme Apple Podcasts, Spotify, etc" />
                        </FormTooltip>
                    }
                />
                <FullWidthComboBox
                    name="languageId"
                    control={control}
                    defaultItems={languages}
                    label={<FormattedMessage defaultMessage="Langue" />}
                >
                    {(item) => <ListBoxItem {...item} />}
                </FullWidthComboBox>

                {!isShowPmp && (
                    <>
                        <FullWidthComboBox
                            name="categoryId"
                            id="category"
                            control={control}
                            defaultItems={categories}
                            label={<FormattedMessage defaultMessage="Catégorie" />}
                        >
                            {({ children, ...section }) => (
                                <Section id={section.name}>
                                    <ListBoxItem {...section} />
                                    <Collection items={children}>
                                        {(item) => <ListBoxItem {...item} />}
                                    </Collection>
                                </Section>
                            )}
                        </FullWidthComboBox>
                        <FullWidthComboBox
                            name="secondaryCategoryId"
                            id="secondary-category"
                            control={control}
                            defaultItems={categories}
                            disabledKeys={[categoryId]}
                            label={<FormattedMessage defaultMessage="Catégorie secondaire" />}
                            tooltip={
                                <FormTooltip>
                                    <FormattedMessage defaultMessage="Cette catégorie facultative est prise en compte par certaines plateformes telles que Apple Podcasts ou Spotify." />
                                </FormTooltip>
                            }
                        >
                            {({ children, ...section }) => (
                                <Section id={section.name}>
                                    <ListBoxItem {...section} />
                                    <Collection items={children}>
                                        {(item) => <ListBoxItem {...item} />}
                                    </Collection>
                                </Section>
                            )}
                        </FullWidthComboBox>
                        <ProtectedInputText
                            name="slug"
                            value={watch('slug')}
                            onSave={(slug: string) =>
                                setValue('slug', slug, {
                                    shouldValidate: true,
                                    shouldDirty: true,
                                })
                            }
                            label={
                                <FormattedMessage defaultMessage="Permalien de votre Site Web" />
                            }
                            prefix={process.env.URL_PODCAST_AUSHA}
                            errorMessage={formState.errors.slug?.message}
                        />
                        <InputRichTextController
                            name="descriptionFooter"
                            control={control}
                            label={
                                <FormattedMessage defaultMessage="Signature de la description" />
                            }
                            charactersLimit={3900}
                            tooltip={
                                <FormTooltip>
                                    <FormattedMessage defaultMessage="Renseignez une fin de description qui sera ajoutée automatiquement à tous vos futurs épisodes. Indiquez des crédits récurrents, des liens vers vos réseaux sociaux ou tout autre contenu que vous souhaitez intégrer au bas de chaque description." />
                                </FormTooltip>
                            }
                        />
                        <InputTagController
                            name="tags"
                            control={control}
                            label={<FormattedMessage defaultMessage="Tags prédéfinis" />}
                            maxTags={20}
                            tooltip={
                                <FormTooltip>
                                    <FormattedMessage defaultMessage="Lorsque vous ajoutez un épisode, ces tags seront déjà saisis. Vous pourrez toujours les modifier par la suite." />
                                </FormTooltip>
                            }
                        />
                        <Stack $gap="0.5rem">
                            <Text fontWeight="--fw-semibold">
                                <FormattedMessage defaultMessage="Type" />
                            </Text>
                            <TypeCardSelect
                                name="type"
                                control={control}
                                variant={isDesktop ? 'large' : 'small'}
                            >
                                {/* @ts-ignore */}
                                <CardSelect
                                    value="episodic"
                                    title={<FormattedMessage defaultMessage="Épisodique" />}
                                    description={
                                        <FormattedMessage defaultMessage="Les nouveaux épisodes apparaissent en premier, et il n’y a pas d’ordre d’écoute." />
                                    }
                                    icon={
                                        <CardSelectIcon
                                            icon={icon({ name: 'random', style: 'duotone' })}
                                        />
                                    }
                                />
                                {/* @ts-ignore */}
                                <CardSelect
                                    value="serial"
                                    title={<FormattedMessage defaultMessage="En série" />}
                                    description={
                                        <FormattedMessage defaultMessage="Les épisodes doivent être écoutés dans l’ordre." />
                                    }
                                    icon={
                                        <CardSelectIcon
                                            icon={icon({
                                                name: 'list-ol',
                                                style: 'duotone',
                                            })}
                                        />
                                    }
                                />
                            </TypeCardSelect>
                        </Stack>
                        <Stack $gap="0.5rem">
                            <Text fontWeight="--fw-semibold">
                                <FormattedMessage defaultMessage="Nombre d'écoutes" />
                            </Text>
                            <ToggleController name="publicCounts" control={control}>
                                <FormattedMessage defaultMessage="Afficher le nombre d’écoutes sur votre Siteweb Ausha, votre Smartlink ainsi que votre Smartplayer." />
                            </ToggleController>
                        </Stack>
                    </>
                )}
                <SubmitButton type="submit" isDisabled={!formState.isDirty || !formState.isValid}>
                    <FormattedMessage defaultMessage="Enregistrer" />
                </SubmitButton>
            </Form>
        </>
    );
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`;
const FullWidthComboBox = styled(ComboBoxController)`
    max-width: 100%;
`;
const HelpIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
`;
const CardSelectIcon = styled(FontAwesomeIcon)`
    width: 1.5rem;
    height: 1.5rem;
    color: var(--primary);
`;
const SubmitButton = styled(Button)`
    margin-top: var(--sp-m);
    align-self: center;
    margin-top: 1rem;
`;
const TypeCardSelect = styled(CardSelectListController)`
    flex-direction: column;
    gap: 0.75rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
    }
`;

export default ShowEditForm;
