import { FormattedMessage } from 'react-intl';
import { createElement } from 'react';
import DeprecatedText from '../../ui/atoms/DeprecatedText';
import styled from 'styled-components';

const makeRenderStatsData = (Component) => {
    const makeRenderStatsDataInner = (props) => {
        const {
            makeRenderStatsDataHasData,
            makeRenderStatsDataNull = false,
            ...propsRestComponent
        } = props;
        if (makeRenderStatsDataHasData) return createElement(Component, { ...propsRestComponent });
        if (!makeRenderStatsDataHasData && makeRenderStatsDataNull) return null;

        return (
            <EmptyWrapper>
                <DeprecatedText weight="semibold" size="big">
                    <FormattedMessage defaultMessage="Aucune donnée disponible" />
                </DeprecatedText>
            </EmptyWrapper>
        );
    };
    return makeRenderStatsDataInner;
};

const EmptyWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    ${({ theme }) => theme.mediaQueries.mobile} {
        padding: var(--container-padding-mobile);
    }
`;

export default makeRenderStatsData;
