import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from '@/components/legacy/connect';

function Fetcher({ fetch, cache, input, fallback = () => {}, isLoading, children }) {
    useEffect(() => {
        if (!cache) fetch();
    }, input || []);

    let component = null;
    if (typeof children === 'function') {
        component = children(isLoading);
    } else if (!isLoading || cache) {
        component = children;
    }

    return (
        <>
            {fallback(isLoading)}
            {component}
        </>
    );
}

Fetcher.propTypes = {
    fetch: PropTypes.func.isRequired,
    cache: PropTypes.any,
    fallback: PropTypes.func,
    isLoading: PropTypes.bool.isRequired,
    input: PropTypes.array,
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
};

export const createFetcher = (injector) => connect(injector)(Fetcher);

export default Fetcher;
