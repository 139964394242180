import messages from './validationMessages';

export const messageParsers = {
    Required: messages.isRequired,
    Exists: messages.exists,
    Url: messages.isUrl,
    Unique: messages.isUnique,
    Different: messages.differentFrom,
    Max: messages.maxLength,
    RsslinkRule: messages.invalidRssLink,
    CurrentPassword: messages.invalidPassword,
    Confirmed: messages.passwordsNotMatching,
    Dimensions: (minW, minH) => messages.minDimension(minW.split('=')[1], minH.split('=')[1]),
    VatNumber: messages.invalidVat,
    AlphaDash: messages.alphaDash,
    Email: messages.isEmail,
    Indisposable: messages.disposableEmailBlocked,
    Mimes: (acceptedMimes) => messages.requiredMime(acceptedMimes),
    ImageRatioRule: messages.ratioRule,
    Image: messages.fileMustBeImage,
};

const recommendedDimensions = {
    instagram: '1080*1080px',
    facebook: '1200*630px',
    twitter: '1600*1900px',
    linkedin: '1200*627px',
};

export const messagesImgUploadError = {
    Max: (provider, currentValues, maxWeight) =>
        messages.maxWeightImgUpload(provider, currentValues.weight, maxWeight),
    Dimensions: (provider, currentValues, minHeight, minWidth) =>
        messages.minDimensionsImgUpload(
            provider,
            currentValues.width,
            currentValues.height,
            minHeight.split('=')[1],
            minWidth.split('=')[1],
            recommendedDimensions[provider],
        ),
    Mimes: (provider, currentValues) =>
        messages.acceptedMimesImgUpload(provider, currentValues.format),
    ImageRatioRule: messages.ratioRule,
    Image: messages.fileMustBeImage,
};
