import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Text from '@ui/atoms/Text';
import StripeBadge from '@public/images/stripe-badge.svg?url';
import ChargebeeBadge from '@public/images/chargebee-badge.svg?url';
import useSubscriptionQuery from '@queries/subscription/useSubscriptionQuery.hook';
import { PRICING } from '@/shared/config/pricing';
import { getStripeCustomerPortalSession } from '@/api';
import { useModalToastQueue } from '@/shared/hooks/useModalToastQueue.hook';
import styled from 'styled-components';
import ExternalLink from '@/components/Link/ExternalLink';
import Button from '@/components/Button';

const BILLING_PROVIDERS = {
    STRIPE: 'stripe',
    CHARGEBEE: 'chargebee',
};

const Billing = () => {
    const toast = useModalToastQueue();
    const { data } = useSubscriptionQuery();
    const provider =
        data?.pricing === PRICING.ENTERPRISE
            ? BILLING_PROVIDERS.CHARGEBEE
            : BILLING_PROVIDERS.STRIPE;

    const createStripePortalSession = async () => {
        try {
            const stripeCustomerPortalSession = await getStripeCustomerPortalSession();
            const { url } = stripeCustomerPortalSession;
            window.location.href = url;
        } catch (error) {
            toast.alert(
                <FormattedMessage defaultMessage="Une erreur inattendue est survenue. Merci de réessayer plus tard." />,
            );
        }
    };

    return (
        <Section>
            <SectionTitle>
                <FormattedMessage defaultMessage="Facturation" />
            </SectionTitle>
            <BillingWrapper>
                <MainContent>
                    <BoxTitleWrapper>
                        <Card icon={icon({ name: 'credit-card', style: 'solid' })} />
                        <BoxTitle>
                            <FormattedMessage defaultMessage="Paiement et facturation" />
                        </BoxTitle>
                    </BoxTitleWrapper>
                    <TextContent>
                        <FormattedMessage defaultMessage="Retrouvez l'historique de vos factures, modifiez vos informations de facturation (adresse, numéro de TVA, etc) et mettez à jour votre moyen de paiement depuis votre espace de paiement Stripe" />
                    </TextContent>
                </MainContent>
                <ActionWrapper>
                    {provider === BILLING_PROVIDERS.CHARGEBEE ? (
                        <ExternalLink
                            variant="button-primary"
                            href="https://ausha.chargebeeportal.com/portal/v2/login?forward=portal_main"
                            target="_blank"
                            endIcon={
                                <FontAwesomeIcon
                                    icon={icon({ name: 'arrow-up-right', style: 'solid' })}
                                />
                            }
                        >
                            <FormattedMessage defaultMessage="Mon espace de facturation" />
                        </ExternalLink>
                    ) : (
                        <Button
                            onPress={createStripePortalSession}
                            endIcon={
                                <FontAwesomeIcon
                                    icon={icon({ name: 'arrow-up-right', style: 'solid' })}
                                />
                            }
                        >
                            <FormattedMessage defaultMessage="Mon espace de facturation" />
                        </Button>
                    )}
                    <img
                        src={
                            provider === BILLING_PROVIDERS.CHARGEBEE ? ChargebeeBadge : StripeBadge
                        }
                    />
                </ActionWrapper>
            </BillingWrapper>
        </Section>
    );
};

const Section = styled.section`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;
const SectionTitle = styled.div`
    color: var(--neutral500);
    font-weight: var(--fw-semibold);
    font-size: var(--fs-body-m);
`;
const BillingWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding-mobile);
    display: flex;
    flex-direction: column;
    row-gap: 2rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        padding: var(--container-padding);
    }
`;
const MainContent = styled.div`
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
`;
const BoxTitleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;
const Card = styled(FontAwesomeIcon)`
    font-size: 1rem;
    color: var(--primary);
`;
const BoxTitle = styled.div`
    font-weight: var(--fw-semibold);
`;
const TextContent = styled(Text)`
    color: var(--neutral500);
`;
const ActionWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1134px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
    }
`;

export default Billing;
