import { useParams, useLocation } from 'react-router';
import { FormattedMessage } from 'react-intl';
import RouterLink from '@/components/Link/RouterLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';
import Sidebar from '@/shared/components/Sidebar';
import SidebarItem from '@/shared/components/Sidebar/Item/Item';

const EpisodesNav = () => {
    const { showId }: { showId: string } = useParams();
    const location = useLocation();

    return (
        <Sidebar>
            <SidebarItem isActive={location.pathname.includes('episodes')}>
                <NavItemIcon icon={icon({ name: 'microphone-lines', style: 'solid' })} />
                <NavRouterLink to={`/app/show/${showId}/episodes`}>
                    <FormattedMessage defaultMessage="Épisodes" />
                </NavRouterLink>
            </SidebarItem>
            <SidebarItem isActive={location.pathname.includes('playlists')}>
                <NavItemIcon icon={icon({ name: 'album-collection', style: 'solid' })} />
                <NavRouterLink to={`/app/show/${showId}/playlists`}>
                    <FormattedMessage defaultMessage="Playlists" />
                </NavRouterLink>
            </SidebarItem>
        </Sidebar>
    );
};

const NavRouterLink = styled(RouterLink)`
    color: inherit;
`;
const NavItemIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
`;

export default EpisodesNav;
