import { FormattedMessage } from 'react-intl';
// @ts-ignore
import EpisodeIllustration from '@public/images/empty-state/episodes.svg?url';
import Button from '@/components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';
import useRefreshRssFeedMutation from '@/queries/rss/useRefreshRssFeedMutation.hook';
import { useParams } from 'react-router';
import { useBodyToastQueue } from '@/shared/hooks/useBodyToastQueue.hook';

const EmptyState = () => {
    const { showId } = useParams<{ showId: string }>();
    const refreshFeed = useRefreshRssFeedMutation();
    const toast = useBodyToastQueue();

    const onRefreshRssFeed = () => {
        refreshFeed.mutate(
            { showId },
            {
                onError: () => {
                    toast.alert();
                },
            },
        );
    };

    return (
        <EmptyStateWrapper>
            <Head>
                <Illustration src={EpisodeIllustration} />
                <TextWrapper>
                    <Title>
                        <FormattedMessage defaultMessage="Votre flux RSS est vide" />
                    </Title>
                    <Subtitle>
                        <FormattedMessage defaultMessage="Il semblerait que vous n'ayez aucun épisode. Merci de vérifier votre flux RSS et de rafraichir." />
                    </Subtitle>
                </TextWrapper>
            </Head>
            <Button
                onPress={onRefreshRssFeed}
                startIcon={
                    <FontAwesomeIcon icon={icon({ name: 'arrows-rotate', style: 'solid' })} />
                }
            >
                <FormattedMessage defaultMessage="Rafraichir" />
            </Button>
        </EmptyStateWrapper>
    );
};

const EmptyStateWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 2rem;
    background-color: var(--white);
    border-radius: var(--r-l);
`;
const Head = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
`;
const Illustration = styled.img`
    width: 16rem;
    height: auto;
`;
const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
`;
const Title = styled.h2`
    font-size: var(--fs-heading-s);
    font-weight: var(--fw-bold);
`;
const Subtitle = styled.span`
    color: var(--neutral500);
`;

export default EmptyState;
