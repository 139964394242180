import { useMutation } from '@tanstack/react-query';
import { uploadShowImage } from '@/api';
import camelcaseKeys from 'camelcase-keys';

interface MutationFnProps {
    showId: number;
    image: File;
}

const transform = (data: unknown) => {
    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data as any, { deep: true });
};

const mutationFn = async ({ showId, image }: MutationFnProps) => {
    const { data } = await uploadShowImage(showId, image);
    return transform(data);
};

const useUploadShowImageMutation = () => {
    return useMutation({
        mutationFn,
    });
};

export default useUploadShowImageMutation;
