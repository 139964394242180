import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import styled from 'styled-components';
import Stack from '@/components/ui/layout/Stack';
import Text from '@/components/ui/atoms/Text';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Button from '@/components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from '@/components/ui/atoms/Modal';

interface PublishModalProps {
    isOpen: boolean;
    onOpenChange: (isOpen: boolean) => void;
    isLoading: boolean;
    onPublish: () => void;
    startsAt: string | null;
    endsAt: string | null;
}

const PublishModal = ({
    isOpen,
    onOpenChange,
    onPublish,
    isLoading,
    startsAt,
    endsAt,
}: PublishModalProps) => {
    const handleClose = () => onOpenChange(false);

    if (!startsAt || !endsAt) return null;

    return (
        <ModalStyled isOpen={isOpen} onOpenChange={onOpenChange} size="small">
            <IconWrapper>
                <RocketIcon icon={icon({ name: 'rocket', style: 'solid' })} />
            </IconWrapper>
            <Stack $gap="0.25rem">
                <Text variant="headingS" fontWeight="--fw-bold" textAlign="center">
                    <FormattedMessage defaultMessage="3, 2, 1... Prêt à publier votre campagne ?" />{' '}
                </Text>
                <Text color="--neutral500" textAlign="center">
                    <FormattedMessage defaultMessage="Attention, certains éléments de votre campagne (comme le fichier audio et les dates) ne seront pas modifiables une fois celle-ci publiée." />
                </Text>
            </Stack>
            <Summary>
                <Stack $gap="0.25rem">
                    <Text fontWeight="--fw-semibold">
                        <FormattedMessage defaultMessage="Commence le" />
                    </Text>
                    <Text color="--neutral500">
                        <FormattedMessage
                            defaultMessage="{date} à {time}"
                            values={{
                                date: <FormattedDate value={startsAt} />,
                                time: <FormattedTime value={startsAt} />,
                            }}
                        />
                    </Text>
                </Stack>
                <FontAwesomeIcon icon={icon({ name: 'arrow-right', style: 'solid' })} />
                <Stack $gap="0.25rem">
                    <Text fontWeight="--fw-semibold">
                        <FormattedMessage defaultMessage="Termine le" />
                    </Text>
                    <Text color="--neutral500">
                        <FormattedMessage
                            defaultMessage="{date} à {time}"
                            values={{
                                date: <FormattedDate value={endsAt} />,
                                time: <FormattedTime value={endsAt} />,
                            }}
                        />
                    </Text>
                </Stack>
            </Summary>
            <Actions>
                <Button onPress={onPublish} isLoading={isLoading}>
                    <FormattedMessage defaultMessage="Publier la campagne" />
                </Button>
                <Button variant="link-secondary" onPress={handleClose}>
                    <FormattedMessage defaultMessage="Annuler" />
                </Button>
            </Actions>
        </ModalStyled>
    );
};

const ModalStyled = styled(Modal)`
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1.5rem;
`;
const RocketIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
`;
const IconWrapper = styled.div`
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary50);
    color: var(--primary500);
    border-radius: var(--r-s);
`;
const Actions = styled.div`
    align-self: stretch;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    & > :last-child {
        align-self: center;
    }
`;
const Summary = styled.div`
    padding: 1rem;
    background-color: var(--neutral50);
    border-radius: var(--r-m);
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 1rem;
    width: 100%;
`;

export default PublishModal;
