import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchSharingProvider } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import providerKeys from './providerKeys';
import { z } from 'zod';
import { FACEBOOK } from '@/shared/config/constants';

export const FacebookAccountSchema = z.object({
    name: z.string().optional().nullable(),
});
export const FacebookPageSchema = z.object({
    id: z.number(),
    name: z.string(),
});
export const FacebookProviderSchema = z.object({
    account: FacebookAccountSchema,
    pages: z.array(FacebookPageSchema),
});

export type FacebookAccount = z.infer<typeof FacebookAccountSchema>;
export type FacebookPage = z.infer<typeof FacebookPageSchema>;
export type FacebookProvider = z.infer<typeof FacebookProviderSchema>;

type Key = QueryFunctionContext<ReturnType<typeof providerKeys.detailByProvider>>;

const queryFn = async ({ queryKey: [{ showId, provider }] }: Key) => {
    const data = await fetchSharingProvider(showId, provider);
    return FacebookProviderSchema.parse(camelcaseKeys(data, { deep: true }));
};

const useFacebookProviderQuery = (showId: string | number) => {
    return useQuery({
        queryKey: providerKeys.detailByProvider(showId, FACEBOOK),
        queryFn,
    });
};

export default useFacebookProviderQuery;
