import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { FORM_VALIDATION_MESSAGE } from '@/utils/validationMessage';

const useRssImportFormSchema = () => {
    const intl = useIntl();

    return yup.object({
        url: yup
            .string()
            .url(intl.formatMessage(FORM_VALIDATION_MESSAGE.isUrl))
            .required(intl.formatMessage(FORM_VALIDATION_MESSAGE.required)),
    });
};

export default useRssImportFormSchema;
