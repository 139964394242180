import RouterLink from '@/components/Link/RouterLink';
import Sidebar from '@/shared/components/Sidebar';
import SidebarItem from '@/shared/components/Sidebar/Item/Item';
import SidebarSection from '@/shared/components/Sidebar/Section/Section';
import SectionTitle from '@/shared/components/Sidebar/Section/Title/Title';
import { FormattedMessage } from 'react-intl';
import { useLocation, useParams } from 'react-router';
import styled from 'styled-components';
import { PRICING } from '@/shared/config/pricing';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import useHasAccessToPsoKeywordsRanking from '@/shared/hooks/useHasAccessToPsoKeywordsRanking.hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import useHasAccessToEpisodeAI from '@/shared/hooks/useHasAccessToEpisodeAI.hook';
import { useGuard } from '@/shared/hooks/useGuard.hook';
import { USER_OPTIONS } from '@/shared/config/userOptions';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import useHasAccessToAushaForAll from '@/shared/hooks/useHasAccessToAushaForAll.hook';

const Nav = () => {
    const { showId } = useParams<{ showId: string }>();
    const location = useLocation();
    const currentSegment = location.pathname.split('/').pop();
    const basePath = `/app/show/${showId}/settings`;

    const { data: show } = useShowQuery(showId);
    const { data: user } = useUserQuery();

    const hasAccessToPsoKeywordsRanking = useHasAccessToPsoKeywordsRanking({ showId });
    const hasAccessToAI = useHasAccessToEpisodeAI({ showId });
    const showHasHostingAccess = show?.hostedOnAusha === true;
    const hasAccessToWhiteLabel = show?.userOptions.includes(USER_OPTIONS.WHITE_LABEL);
    const hasAccessToCustomLogo = show?.userOptions.includes(USER_OPTIONS.CUSTOMIZABLE_LOGO);
    const hasAccessToAushaProSettings = hasAccessToWhiteLabel || hasAccessToCustomLogo;
    const hasAccessToUsersManagement =
        !user?.options?.includes(USER_OPTIONS.MULTI_USERS_MANAGEMENT) || user?.guest;
    const hasAccessToAushaForAll = useHasAccessToAushaForAll();

    const pricingGuard = useGuard({
        disallowedPlans: [PRICING.FREEMIUM, PRICING.LAUNCH],
    });

    return (
        <Sidebar>
            <SidebarSection>
                <SectionTitle>
                    <FormattedMessage defaultMessage="Général" />
                </SectionTitle>
                <SidebarItem isActive={currentSegment === 'info'}>
                    <NavItemIcon icon={icon({ name: 'align-left', style: 'solid' })} />
                    <NavRouterLink to={`${basePath}/info`}>
                        <FormattedMessage defaultMessage="Informations" />
                    </NavRouterLink>
                </SidebarItem>
                <SidebarItem isActive={location.pathname.includes('/broadcast')}>
                    <NavItemIcon icon={icon({ name: 'podcast', style: 'solid' })} />
                    <NavRouterLink
                        to={
                            showHasHostingAccess
                                ? `${basePath}/broadcast`
                                : `${basePath}/broadcast/non-hosting`
                        }
                    >
                        {showHasHostingAccess ? (
                            <FormattedMessage defaultMessage="Diffusion" />
                        ) : (
                            <FormattedMessage
                                id="settings.podcastApps"
                                defaultMessage="Plateformes d'écoute"
                            />
                        )}
                    </NavRouterLink>
                </SidebarItem>
                {showHasHostingAccess && (
                    <SidebarItem isActive={currentSegment === 'seasons'}>
                        <NavItemIcon icon={icon({ name: 'album-collection', style: 'solid' })} />
                        <NavRouterLink to={`${basePath}/seasons`}>
                            <FormattedMessage defaultMessage="Saisons" />
                        </NavRouterLink>
                    </SidebarItem>
                )}
                {hasAccessToUsersManagement && (
                    <SidebarItem isActive={currentSegment === 'users'}>
                        <NavItemIcon icon={icon({ name: 'user-group', style: 'solid' })} />
                        <NavRouterLink to={`${basePath}/users`}>
                            <FormattedMessage defaultMessage="Utilisateurs" />
                        </NavRouterLink>
                        {!pricingGuard.allow && (
                            <DiamondIcon icon={icon({ name: 'gem', style: 'duotone' })} />
                        )}
                    </SidebarItem>
                )}
            </SidebarSection>
            <SidebarSection>
                <SectionTitle>
                    <FormattedMessage defaultMessage="Options" />
                </SectionTitle>
                {hasAccessToAushaProSettings && (
                    <SidebarItem isActive={currentSegment === 'pro'}>
                        <NavItemIcon icon={icon({ name: 'rectangle-pro', style: 'solid' })} />
                        <NavRouterLink to={`${basePath}/pro`}>
                            <FormattedMessage defaultMessage="Ausha Pro" />
                        </NavRouterLink>
                        {!hasAccessToPsoKeywordsRanking && (
                            <DiamondIcon icon={icon({ name: 'gem', style: 'duotone' })} />
                        )}
                    </SidebarItem>
                )}
                <SidebarItem isActive={currentSegment === 'ai'}>
                    <NavItemIcon icon={icon({ name: 'sparkles', style: 'solid' })} />
                    <NavRouterLink to={`${basePath}/ai`}>Ausha Intelligence</NavRouterLink>
                    {!hasAccessToAI && (
                        <DiamondIcon icon={icon({ name: 'gem', style: 'duotone' })} />
                    )}
                </SidebarItem>
            </SidebarSection>
            <SidebarSection>
                <SectionTitle>
                    <FormattedMessage defaultMessage="Avancés" />
                </SectionTitle>
                {(hasAccessToAushaForAll || showHasHostingAccess) && (
                    <SidebarItem isActive={currentSegment === 'rss-export'}>
                        <NavItemIcon icon={icon({ name: 'cloud', style: 'solid' })} />
                        <NavRouterLink to={`${basePath}/rss-export`}>
                            {hasAccessToAushaForAll ? (
                                <FormattedMessage defaultMessage="Hébergement" />
                            ) : (
                                'Export RSS'
                            )}
                        </NavRouterLink>
                    </SidebarItem>
                )}
                {showHasHostingAccess && (
                    <SidebarItem isActive={currentSegment === 'rss-import'}>
                        <NavItemIcon icon={icon({ name: 'arrow-down-to-line', style: 'solid' })} />
                        <NavRouterLink to={`${basePath}/rss-import`}>
                            <FormattedMessage defaultMessage="Import RSS" />
                        </NavRouterLink>
                    </SidebarItem>
                )}
                <SidebarItem isActive={currentSegment === 'show-delete'}>
                    <NavItemIcon icon={icon({ name: 'ban', style: 'solid' })} />
                    <NavRouterLink to={`${basePath}/show-delete`}>
                        <FormattedMessage defaultMessage="Archiver ou Supprimer" />
                    </NavRouterLink>
                </SidebarItem>
            </SidebarSection>
        </Sidebar>
    );
};

const NavRouterLink = styled(RouterLink)`
    color: inherit;
`;
const NavItemIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
`;
const DiamondIcon = styled(NavItemIcon)`
    color: var(--primary);
`;

export default Nav;
