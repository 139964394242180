import styled from 'styled-components';
import PropTypes from 'prop-types';
import { computeCssValue } from '@/shared/utils/css';

/**
 * SimpleGrid is a responsive grid that adjusts automatically based on an ideal child width.
 *
 * References:
 * https://every-layout.dev/layouts/grid/
 * https://chakra-ui.com/docs/layout/simple-grid
 */
const SimpleGrid = styled.div`
    display: grid;
    gap: ${(p) => computeCssValue(p.$gap)};
    grid-gap: ${(p) => computeCssValue(p.$gap)}; /* Backward compatibility */
    row-gap: ${(p) => computeCssValue(p.$rowGap)};
    grid-row-gap: ${(p) => computeCssValue(p.$rowGap)}; /* Backward compatibility */
    column-gap: ${(p) => computeCssValue(p.$columnGap)};
    grid-column-gap: ${(p) => computeCssValue(p.$columnGap)}; /* Backward compatibility */

    // Test for min() support (https://caniuse.com/css-math-functions).
    // If not available, SimpleGrid fallback to a single column grid.
    @supports (width: min(${(props) => computeCssValue(props.$idealChildWidth)}, 100%)) {
        grid-template-columns: repeat(
            auto-fill,
            minmax(min(${(props) => computeCssValue(props.$idealChildWidth)}, 100%), 1fr)
        );
    }
`;

SimpleGrid.propTypes = {
    $gap: PropTypes.string, // Gaps between rows and columns
    $rowGap: PropTypes.string, // Gaps between rows
    $columnGap: PropTypes.string, // Gaps between columns
    $idealChildWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // Ideal child width
};

export default SimpleGrid;
