const quotaKeys = {
    all: () => [{ entity: 'rss' }],
    feed: (showId: string) => [{ ...quotaKeys.all()[0], scope: 'feed', showId }],
    episodes: (
        showId: string,
        state?: 'pending' | 'processing' | 'done' | 'error',
        page = 1,
        perPage = 20,
    ) => [{ ...quotaKeys.all()[0], scope: 'episodes', showId, state, page, perPage }],
    redirectStatus: (showId: string) => [
        { ...quotaKeys.all()[0], scope: 'redirectStatus', showId },
    ],
    rssInfo: (payload: Record<string, string>) => [
        { ...quotaKeys.all()[0], scope: 'redirectStatus', payload },
    ],
    searchApplePodcasts: (searchQuery: string) => [
        { ...quotaKeys.all()[0], scope: 'searchApplePodcasts', searchQuery },
    ],
};

export default quotaKeys;
