import { action, computed, runInAction } from 'mobx';
import Store from './Store';
import { apiFetch } from '../components/app/decorators/api';
import { fetchCallToActionButtons, updateCallToActionButtonsPositions } from '@/api';
import CallToActionModel from '../models/CallToActionModel';
import { modalToastQueue } from '@/components/ui/molecules/GlobalToastRegions';
import { UI_VARIANTS } from '@/utils/constants';
import { LINKS_NAMES } from '@/components/CallToAction';

const { PAGE_PODCAST, SMARTLINK, PLAYER, NEWSLETTER, CUSTOM } = LINKS_NAMES;

class CallToActionStore extends Store {
    static observables = {
        callToActionButtons: [],
        currentDragAndDropData: {
            hasChanged: false,
            newList: [],
        },
    };

    @apiFetch
    async fetchCallToActionButtons(showId, location) {
        const { data } = await fetchCallToActionButtons(showId, location);
        runInAction(() => {
            this.callToActionButtons = data.map(
                (ctaButton) => new CallToActionModel(this, ctaButton),
            );
        });
    }

    async updateCallToActionButtonsPositions(showId, query) {
        if (!this.currentDragAndDropData.hasChanged) return;
        const { location } = query;
        await updateCallToActionButtonsPositions(showId, {
            location,
            ids: this.currentDragAndDropData.newList.map((cta) => cta.id),
        });
        try {
            modalToastQueue.add(null, {
                variant: UI_VARIANTS.SUCCESS,
                timeout: 5000,
            });
            runInAction(() => this.resetDragAndDropData());
        } catch (e) {
            modalToastQueue.add(null, { variant: UI_VARIANTS.ALERT, timeout: 5000 });
        }
    }

    @action
    setCurrentDragAndDropData(dragAndDropData) {
        this.currentDragAndDropData = dragAndDropData;
    }

    @action
    resetDragAndDropData() {
        this.currentDragAndDropData = { hasChanged: false, newList: [] };
    }

    @computed
    get getLocationButtons() {
        const getArrayLocationType = (locationType) =>
            this.callToActionButtons.filter((cta) => cta.locations.includes(locationType) && cta);

        return {
            [PAGE_PODCAST]: getArrayLocationType(PAGE_PODCAST),
            [PLAYER]: getArrayLocationType(PLAYER),
            [SMARTLINK]: getArrayLocationType(SMARTLINK),
            [NEWSLETTER]: getArrayLocationType(NEWSLETTER),
            [CUSTOM]: getArrayLocationType(LINKS_NAMES.CUSTOM),
        };
    }
}

export default CallToActionStore;
