import Stack from '@ui/layout/Stack';
import { USER_OPTIONS } from '@/shared/config/userOptions';
import EpisodeEditFormPrivacyPrivacyField from '@/components/unorganized/EpisodeEditForm/EpisodeEditFormPrivacy/EpisodeEditFormPrivacyPrivacyField';
import EpisodeEditFormPrivacyDownloadField from '@/components/unorganized/EpisodeEditForm/EpisodeEditFormPrivacy/EpisodeEditFormPrivacyDownloadField';
import { useEpisodeBeingEdited } from '@/context/EpisodeFormContext';
import NextStepButton from '@/components/unorganized/EpisodeEditForm/NextStepButton';

const EpisodeEditFormPrivacy = () => {
    const episode = useEpisodeBeingEdited();

    return (
        <Stack $gap="2rem">
            <EpisodeEditFormPrivacyPrivacyField
                isUnlistedAllowed={episode.show.userOptions.includes(
                    USER_OPTIONS.PODCASTS_UNLISTED,
                )}
            />
            <EpisodeEditFormPrivacyDownloadField />
            <NextStepButton />
        </Stack>
    );
};

export default EpisodeEditFormPrivacy;
