import { useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Text from '@ui/atoms/Text';
import ResignSubscriptionModal from './ResignSubscriptionModal';
import UnsubscribeSurveyModal from './UnsubscribeSurveyModal';

const CancelButton = styled.button`
    background: transparent;
    border: none;
    cursor: pointer;
    width: 100%;
    color: var(--neutral500);
    transition: color 0.25s ease-in-out;

    &:hover {
        color: var(--black);
    }

    ${(props) => props.theme.mediaQueries.desktopAndUp} {
        position: absolute;
        left: 0;
        bottom: -2.5rem;
    }
`;

const PricingCancelSubscriptionButton = () => {
    const [isResignSubscriptionModalOpen, setIsResignSubscriptionModalOpen] = useState(false);
    const [isUnsubscribeSurveyModalOpen, setIsUnsubscribeSurveyModalOpen] = useState(false);

    const startSubscriptionResignation = () => {
        setIsUnsubscribeSurveyModalOpen(false);
        setIsResignSubscriptionModalOpen(true);
    };

    return (
        <>
            <CancelButton onClick={() => setIsUnsubscribeSurveyModalOpen(true)}>
                <Text color="inherit" fontWeight="--fw-semibold">
                    <FormattedMessage defaultMessage="Résilier mon abonnement" />
                </Text>
            </CancelButton>
            <ResignSubscriptionModal
                isOpen={isResignSubscriptionModalOpen}
                onOpenChange={setIsResignSubscriptionModalOpen}
            />

            <UnsubscribeSurveyModal
                onAfterSubmit={startSubscriptionResignation}
                isOpen={isUnsubscribeSurveyModalOpen}
                onOpenChange={setIsUnsubscribeSurveyModalOpen}
            />
        </>
    );
};

export default PricingCancelSubscriptionButton;
