import GoogleIcon from '@public/images/platforms/google.svg';
import styled from 'styled-components';
import Button from '../Button';
import { isLoginProviderEnabled } from '@/shared/services/providerService';
import { LOGIN_PROVIDER } from '@/shared/config/constants';
import { FormattedMessage } from 'react-intl';
import UiKitTooltip from '@ui/atoms/UiKitTooltip';

const CustomButton = styled(Button)`
    width: 100%;
    color: var(--black);
    background: var(--white);
    box-shadow: none;
    transition: all 350ms;
    border: 1px solid var(--neutral200);

    &:hover {
        background: var(--neutral100);
    }

    & svg {
        width: 1rem;
        height: 1rem;
    }
`;

const ButtonInner = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
`;

const Tooltip = styled(UiKitTooltip)`
    width: auto;
`;

function GoogleButton({ label, onPress }) {
    return (
        <Tooltip
            variant="dark"
            passthrough={isLoginProviderEnabled(LOGIN_PROVIDER.GOOGLE)}
            position="top"
            content={
                <FormattedMessage defaultMessage="Oups, la connexion via Google est actuellement indisponible. Nos équipes font le nécessaire pour réparer cela au plus vite. 💪" />
            }
        >
            <CustomButton
                isDisabled={!isLoginProviderEnabled(LOGIN_PROVIDER.GOOGLE)}
                onPress={() => onPress()}
            >
                <ButtonInner>
                    <GoogleIcon />
                    {label || 'Google'}
                </ButtonInner>
            </CustomButton>
        </Tooltip>
    );
}

export default GoogleButton;
