import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled, { css } from 'styled-components';

export interface KeywordProps {
    label: string;
    onDelete: () => void;
    hasError?: boolean;
}

const Keyword = ({ label, onDelete, hasError }: KeywordProps) => {
    return (
        <KeywordWrapper hasError={hasError}>
            <LabelWrapper>{label}</LabelWrapper>
            <CloseButton onClick={onDelete} hasError={hasError}>
                <FontAwesomeIcon icon={icon({ name: 'xmark', style: 'solid' })} size="1x" />
            </CloseButton>
        </KeywordWrapper>
    );
};

const KeywordWrapper = styled.div<{ hasError?: boolean }>`
    display: inline-flex;
    align-items: center;
    padding-block: 0.25rem;
    padding-inline: 0.5rem;
    background: var(--primary50);
    color: var(--primary);
    font-weight: var(--fw-semibold);
    font-size: var(--fs-body-m);
    border-radius: var(--r-s);
    gap: 0.25rem;

    ${({ hasError }) =>
        hasError &&
        css`
            background: var(--alert50);
            color: var(--alert);
        `}
`;
const LabelWrapper = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
    max-width: 40rem;
`;
const CloseButton = styled.button<{ hasError?: boolean }>`
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.25rem;
    height: 1.25rem;
    background: none;
    border: none;
    color: var(--primary);
    border-radius: var(--r-s);
    padding: 0.25rem;
    aspect-ratio: 1/1;
    cursor: pointer;
    &:hover {
        background: var(--primary100);
    }

    ${({ hasError }) =>
        hasError &&
        css`
            color: var(--alert);
            &:hover {
                background: var(--alert100);
            }
        `}
`;

export default Keyword;
