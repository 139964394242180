import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchClipTranscription } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import clipKeys from './clipKeys';
import { z } from 'zod';

const SubtitleSchema = z.object({
    start: z.number(),
    end: z.number(),
    subtitle: z.string(),
});
export const ClipTranscriptionSchema = z.object({
    end: z.number(),
    start: z.number(),
    subtitles: z.array(SubtitleSchema),
});

export type ClipTranscription = z.infer<typeof ClipTranscriptionSchema>;
type Key = QueryFunctionContext<ReturnType<typeof clipKeys.transcriptionByClipId>>;

const queryFn = async ({ queryKey: [{ clipId }] }: Key) => {
    const { data } = await fetchClipTranscription(clipId);
    const formattedData = {
        ...data,
        subtitles: data.subtitles.data,
    };
    return ClipTranscriptionSchema.parse(camelcaseKeys(formattedData, { deep: true }));
};

const useClipTranscriptionQuery = (clipId: string | number) => {
    return useQuery({
        queryKey: clipKeys.transcriptionByClipId(clipId),
        queryFn,
    });
};

export default useClipTranscriptionQuery;
