import { FormattedMessage, useIntl } from 'react-intl';
import { useFormContext } from 'react-hook-form';
import { InputTagController } from '@ui/atoms/InputTag/InputTag.controller';
import { EPISODE_MAX_TAGS } from '@/utils/constants';
import EpisodeEditTooltip from '@app/molecules/EpisodeEditTooltip';
import Stack from '@/components/ui/layout/Stack';
import EpisodeEditFormContentTagsAlert from './EpisodeEditFormContentTagsAlert';
import EpisodeAITagsSuggestionsPanel from '@/components/EpisodeAI/EpisodeAITagsSuggestionsPanel';

const EpisodeEditFormContentTagsField = () => {
    const intl = useIntl();
    const { control } = useFormContext();

    return (
        <Stack $gap="0.75rem">
            <Stack>
                <InputTagController
                    name="content.tags"
                    control={control}
                    label={<FormattedMessage defaultMessage="Tags" />}
                    placeholder={intl.formatMessage({
                        defaultMessage: 'Saisissez vos mots-clés séparés par la touche « Entrée »',
                    })}
                    tooltip={
                        <EpisodeEditTooltip>
                            <FormattedMessage defaultMessage="Les tags permettent à votre contenu d'être trouvé plus facilement en étant mieux référencé sur certains mots clés. Pour choisir vos tags, imaginez quels mots taperaient vos auditeurs types depuis la barre de recherche de leurs applications de podcast ! 🎧 (Pas la peine d'ajouter un #)" />
                        </EpisodeEditTooltip>
                    }
                    maxTags={EPISODE_MAX_TAGS}
                    data-testid="new-episode-tags-input"
                />
                <EpisodeAITagsSuggestionsPanel maxTags={EPISODE_MAX_TAGS} />
            </Stack>
            <EpisodeEditFormContentTagsAlert />
        </Stack>
    );
};

export default EpisodeEditFormContentTagsField;
