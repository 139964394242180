import ContentLoader from 'react-content-loader';
import styled from 'styled-components';
import createFallback from '../fallbacks/createFallback';
import DeprecatedPaper from '../../ui/atoms/DeprecatedPaper';
import DeprecatedText from '../../ui/atoms/DeprecatedText';

const PaperStyled = styled(DeprecatedPaper)`
    &:last-child {
        border-right: 0;
    }
`;

const CellFallback = createFallback((props) => (
    <ContentLoader
        height={14}
        width={60}
        style={{ height: 14, width: 60, margin: 'auto' }}
        {...props}
    >
        <rect x="0" y="0" rx="5" ry="5" height="14" width="60" />
    </ContentLoader>
));

const RankingVariationGraphTableColumnFallback = ({ style }) => (
    <PaperStyled w={115} br={1} border="--neutral100" style={{ ...style }}>
        <DeprecatedPaper
            flex
            justify="center"
            height={49}
            py={15}
            border="--neutral100"
            background="--neutral50"
        >
            <CellFallback />
        </DeprecatedPaper>
        <DeprecatedPaper
            flex
            justify="center"
            height={50}
            py={15}
            bt={1}
            border="--neutral100"
            background="white"
        >
            <CellFallback />
        </DeprecatedPaper>
        <DeprecatedPaper
            flex
            justify="center"
            height={50}
            py={15}
            bt={1}
            bb={1}
            border="--neutral100"
            background="white"
        >
            <CellFallback />
        </DeprecatedPaper>
    </PaperStyled>
);

function RankingVariationGraphTableColumn({
    hovered,
    onHover,
    data,
    formatMatchingSelectedStep,
    style,
}) {
    let variationColor;
    switch (Math.sign(data && data.variation)) {
        case -1:
            variationColor = 'error';
            break;
        case 0:
            variationColor = 'blocked';
            break;
        case 1:
            variationColor = 'online';
            break;
        default:
            variationColor = 'black';
    }

    return (
        <PaperStyled
            border="--neutral100"
            br={1}
            onMouseEnter={() => onHover(data)}
            onMouseLeave={() => onHover(null)}
            style={{ ...style }}
            w={115}
        >
            <DeprecatedPaper
                flex
                justify="center"
                height={49}
                py={15}
                border="--neutral100"
                background={hovered ? '--neutral50' : '--neutral50'}
            >
                <DeprecatedText align="center" color="--neutral500">
                    {data[formatMatchingSelectedStep]}
                </DeprecatedText>
            </DeprecatedPaper>
            <DeprecatedPaper
                flex
                justify="center"
                height={50}
                py={15}
                bt={1}
                border="--neutral100"
                background={hovered ? '--neutral50' : 'white'}
            >
                <DeprecatedText align="center">
                    {data.ranking <= 200 ? data.ranking : '-'}
                </DeprecatedText>
            </DeprecatedPaper>
            <DeprecatedPaper
                background={hovered ? '--neutral50' : 'white'}
                bb={1}
                border="--neutral100"
                bt={1}
                flex
                height={50}
                justify="center"
                py={15}
            >
                <>
                    {data.variation !== null ? (
                        <DeprecatedText align="center" color={variationColor}>
                            {`${data.variation >= 0 ? '+' : ''}${data.variation}`}
                        </DeprecatedText>
                    ) : (
                        '-'
                    )}
                </>
            </DeprecatedPaper>
        </PaperStyled>
    );
}

export { RankingVariationGraphTableColumn as default, RankingVariationGraphTableColumnFallback };
