import { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { FormattedMessage } from 'react-intl';
import NewsletterList from './NewsletterList';
import NewsletterSubscriberExportButton from './NewsletterSubscriberExportButton';
import ServerPagination from '@ui/ServerPagination';
import Text from '@ui/atoms/Text';
import styled from 'styled-components';
import useNewsletterSubscribersQueryHook from '@app/molecules/NewsletterPreview/useNewsletterSubscribersQuery.hook';
import Stack from '@ui/layout/Stack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useParams } from 'react-router-dom';
import useShowNewsletterTemplateQuery from '@queries/newsletter/useShowNewsletterTemplateQuery.hook';

const spring = {
    type: 'spring',
    damping: 20,
    stiffness: 300,
    duration: 0.5,
};

const animateNewsletterContent = {
    hidden: { opacity: 0, height: 'max-content' },
    visible: { opacity: 1 },
};

const animateIsNotActivated = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
};

const NewsletterPreview = () => {
    const { showId } = useParams<{ showId: string }>();
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(15);
    const { data: template } = useShowNewsletterTemplateQuery(showId);
    const { data, isLoading } = useNewsletterSubscribersQueryHook({
        showId,
        page,
        perPage,
    });
    const numberSubscribers = data?.pagination?.total;

    return (
        <NewsletterPreviewWrapper>
            <Text variant="title" fontWeight="--fw-bold">
                <FormattedMessage defaultMessage="Abonnés à votre newsletter" />
            </Text>
            <Text color="--neutral500">
                <FormattedMessage
                    defaultMessage="{numberSubscribers, plural, =0 {Aucun abonné} one {# abonné} other {# abonnés}}"
                    values={{ numberSubscribers }}
                />
            </Text>
            <AnimatePresence>
                {template?.activated && data && (
                    <motion.div
                        initial="hidden"
                        animate={template?.activated ? 'visible' : 'hidden'}
                        exit="hidden"
                        variants={animateNewsletterContent}
                        transition={spring}
                    >
                        <NewsletterList loading={isLoading} subscribers={data.subscribers || []} />
                        <ServerPagination
                            pagination={data?.pagination}
                            onGoToPage={(page: number) => setPage(page)}
                            onGoToFirstPage={() => setPage(1)}
                            onGoToLastPage={() => setPage(data.pagination.totalPages)}
                            onGoToPreviousPage={() => setPage(data.pagination.currentPage - 1)}
                            onGoToNextPage={() => setPage(data.pagination.currentPage + 1)}
                            onChangePerPage={(perPage: number) => setPerPage(perPage)}
                            options={{
                                goToFirstAndLastPageButtons: true,
                                showPaginationInfo: true,
                                showPageSelector: false,
                            }}
                        >
                            <NewsletterSubscriberExportButton />
                        </ServerPagination>
                    </motion.div>
                )}
            </AnimatePresence>

            <AnimatePresence>
                {!template?.activated && (
                    <motion.div
                        initial="hidden"
                        animate={!template?.activated ? 'visible' : 'hidden'}
                        exit="hidden"
                        variants={animateIsNotActivated}
                        transition={spring}
                    >
                        <NewsletterDisabled>
                            <Stack $gap="0.5rem" $align="center" $justify="center">
                                <NewspaperIcon
                                    icon={icon({
                                        name: 'newspaper',
                                        style: 'regular',
                                    })}
                                />
                                <Text variant="subheading" fontWeight="--fw-semibold">
                                    <FormattedMessage defaultMessage="Veuillez activer l’abonnement à votre newsletter pour consulter la liste de vos abonnés." />
                                </Text>
                            </Stack>
                        </NewsletterDisabled>
                    </motion.div>
                )}
            </AnimatePresence>
        </NewsletterPreviewWrapper>
    );
};

const NewsletterPreviewWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding);

    ${({ theme }) => theme.mediaQueries.mobile} {
        padding: var(--container-padding-mobile);
    }
`;

const NewsletterDisabled = styled.div`
    background: var(--white);
    padding: 2rem;
`;

const NewspaperIcon = styled(FontAwesomeIcon)`
    width: 2rem;
    height: 2rem;
    color: var(--neutral500);
`;

export default NewsletterPreview;
