import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import Chart from 'react-apexcharts';
import { useIntl } from 'react-intl';
import { extractCssVariable } from '@/utils/cssVariables';
import TooltipContent from './TooltipContent';
import dayjs from 'dayjs';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import { SPOTIFY, APPLE_PODCASTS } from '@/utils/constants';
import styled from 'styled-components';

const PsoAverageRankingGraph = ({
    dates,
    dataSpotify,
    dataApple,
    daysRemaining,
    globalAverage,
    hasSpotifyData,
    hasAppleData,
}) => {
    const intl = useIntl();
    const user = useUserQuery();

    const series = [
        {
            name: 'Average',
            data: globalAverage.map((item) => Math.round(item.ranking)),
        },
        ...(hasSpotifyData
            ? [
                  {
                      name: 'Spotify',
                      data: dataSpotify.map((rank) => Math.round(rank.ranking)),
                  },
              ]
            : []),
        ...(hasAppleData
            ? [
                  {
                      name: 'Apple Podcasts',
                      data: dataApple.map((rank) => Math.round(rank.ranking)),
                  },
              ]
            : []),
    ];

    const colors = [
        'transparent',
        ...(hasSpotifyData ? [extractCssVariable(`--${SPOTIFY}`)] : []),
        ...(hasAppleData ? [extractCssVariable(`--${APPLE_PODCASTS}`)] : []),
    ];

    const chartOptions = {
        options: {
            chart: {
                id: 'basic-bar',
                toolbar: { show: false },
                zoom: { enabled: false },
            },
            xaxis: {
                categories: dates.map((date) =>
                    dayjs(date)
                        .locale(user?.data?.language || 'en')
                        .format('D MMM'),
                ),
                labels: {
                    style: {
                        colors: extractCssVariable('--neutral500'),
                        fontWeight: extractCssVariable('--fw-semibold'),
                        fontSize: extractCssVariable('--fs-body-s'),
                        fontFamily: 'ProximaNova, sans-serif',
                    },
                },
                tickAmount: 10,
                tooltip: {
                    enabled: false,
                },
                crosshairs: {
                    show: false,
                },
            },
            yaxis: {
                min: 1,
                max: 100,
                reversed: true,
                labels: {
                    style: {
                        colors: extractCssVariable('--neutral500'),
                        fontWeight: extractCssVariable('--fw-semibold'),
                        fontSize: extractCssVariable('--fs-body-s'),
                        fontFamily: 'ProximaNova, sans-serif',
                    },
                },
            },
            stroke: {
                curve: 'smooth',
                lineCap: 'round',
                width: 3,
            },
            legend: {
                show: false,
            },
            noData: {
                text: intl.formatMessage(
                    {
                        defaultMessage:
                            'L’historique de vos données sera disponible dans {daysRemaining} jours',
                    },
                    { daysRemaining },
                ),
                align: 'center',
                verticalAlign: 'middle',
                offsetX: 0,
                offsetY: 0,
                style: {
                    color: extractCssVariable('--neutral500'),
                    fontWeight: extractCssVariable('--fw-semibold'),
                    fontSize: extractCssVariable('--fs-body-m'),
                    fontFamily: 'ProximaNova, sans-serif',
                },
            },
            tooltip: {
                theme: 'dark',
                custom: (props) => {
                    const tooltipNode = document.createElement('div');
                    ReactDOM.render(
                        <TooltipContent
                            {...props}
                            averageTranslatedString={intl.formatMessage({
                                defaultMessage: 'Moy.globale',
                            })}
                        />,
                        tooltipNode,
                    );
                    return tooltipNode.innerHTML;
                },
            },
            colors,
        },
        series,
    };
    return (
        <AverageRankingChart
            options={chartOptions.options}
            series={chartOptions.series}
            type="line"
            height={270}
        />
    );
};

const AverageRankingChart = styled(Chart)`
    width: 100%;
`;

PsoAverageRankingGraph.propTypes = {
    dates: PropTypes.arrayOf(PropTypes.string),
    dataSpotify: PropTypes.arrayOf(PropTypes.number),
    dataApple: PropTypes.arrayOf(PropTypes.number),
    daysRemaining: PropTypes.number,
    globalAverage: PropTypes.arrayOf({
        date: PropTypes.string,
        ranking: PropTypes.number,
    }),
    hasSpotifyData: PropTypes.bool,
    hasAppleData: PropTypes.bool,
};

export default PsoAverageRankingGraph;
