import styled, { css, keyframes } from 'styled-components';
import GridSkeleton from './Grid/Skeleton';

const Skeleton = () => {
    return (
        <>
            <Header>
                <Line w="8rem" h="0.75rem" />
                <HeaderRight>
                    <Line w="12rem" h="0.75rem" />
                    <Action />
                </HeaderRight>
            </Header>
            <GridSkeleton />
        </>
    );
};

const shimmer = keyframes`
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
`;
const shimmering = css`
    background: var(--neutral50);
    background-image: linear-gradient(
        to right,
        var(--neutral50) 0%,
        #edeef1 20%,
        var(--neutral50) 40%,
        var(--neutral50) 100%
    );
    background-repeat: no-repeat;
    background-size: 50rem 6.5rem;
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${shimmer};
    animation-timing-function: linear;
`;
const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const Line = styled.div`
    border-radius: var(--r-full);
    background-color: var(--neutral100);
    ${({ w }) => w && `width: ${w};`}
    ${({ h }) => h && `height: ${h};`}
    ${shimmering};
`;
const Action = styled.div`
    width: 10rem;
    height: 2.5rem;
    border-radius: var(--r-s);
    ${shimmering}
`;
const HeaderRight = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

export default Skeleton;
