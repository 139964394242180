import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Text from '@/components/ui/atoms/Text';
import Stack from '@/components/ui/layout/Stack';
import styled from 'styled-components';
import CallToActionCard from './CallToActionCard';
import { CallToAction } from '@/queries/callToAction/useCallToActionButtonsQuery.hook';
import DeleteCallToActionModal from '../DeleteCallToActionModal';
import useDeleteCallToActionButtonMutation from '@/queries/callToAction/useDeleteCallToActionButtonMutation.hook';
import DeleteCallToActionLocationModal from '../DeleteCallToActionLocationModal';
import useUpdateCallToActionButtonMutation from '@/queries/callToAction/useUpdateCallToActionButtonMutation.hook';
import EditCallToActionModal from '../EditCallToActionModal';

interface CallToActionListProps {
    callToActions: CallToAction[];
}

const CallToActionList = ({ callToActions }: CallToActionListProps) => {
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isDeleteLocationModalOpen, setIsDeleteLocationModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState<CallToAction | null>(null);
    const [selectedLocation, setSelectedLocation] = useState<
        CallToAction['locations'][number] | null
    >(null);

    const deleteCallToAction = useDeleteCallToActionButtonMutation();
    const updateCallToAction = useUpdateCallToActionButtonMutation();

    const handleOpenDeleteModal = (cta: CallToAction) => {
        setSelectedItem(cta);
        setIsDeleteModalOpen(true);
    };

    const handleOpenDeleteLocationModal = (
        cta: CallToAction,
        location: CallToAction['locations'][number],
    ) => {
        setSelectedLocation(location);
        setSelectedItem(cta);
        setIsDeleteLocationModalOpen(true);
    };

    const handleOpenEditModal = (cta: CallToAction) => {
        setSelectedItem(cta);
        setIsEditModalOpen(true);
    };

    const handleDelete = () => {
        if (!selectedItem) return;
        deleteCallToAction.mutate(selectedItem.id, {
            onSuccess: () => {
                setIsDeleteModalOpen(false);
                setSelectedItem(null);
            },
        });
    };

    const handleDeleteLocation = () => {
        if (!selectedItem || !selectedLocation) return;
        const newLocations = selectedItem.locations.filter(
            (location) => location !== selectedLocation,
        );
        const newCallToAction = {
            ...selectedItem,
            locations: newLocations,
        };
        updateCallToAction.mutate(
            {
                ctaId: selectedItem.id,
                payload: newCallToAction,
            },
            {
                onSuccess: () => {
                    setIsDeleteLocationModalOpen(false);
                    setSelectedLocation(null);
                    setSelectedItem(null);
                },
            },
        );
    };

    return (
        <>
            <Stack $gap="1.5rem">
                <Text color="--neutral500">
                    <FormattedMessage defaultMessage="Créez, personnalisez et gérez vos propres boutons pour enrichir vos outils de communication." />
                </Text>
                <List>
                    {callToActions.map((cta) => (
                        <CallToActionCard
                            key={cta.id}
                            data={cta}
                            onDelete={handleOpenDeleteModal}
                            onDeleteLocation={handleOpenDeleteLocationModal}
                            onEdit={handleOpenEditModal}
                        />
                    ))}
                </List>
            </Stack>
            <DeleteCallToActionModal
                isOpen={isDeleteModalOpen}
                onOpenChange={setIsDeleteModalOpen}
                onDelete={handleDelete}
                isLoading={deleteCallToAction.isLoading}
            />
            <DeleteCallToActionLocationModal
                isOpen={isDeleteLocationModalOpen}
                onOpenChange={setIsDeleteLocationModalOpen}
                onDelete={handleDeleteLocation}
                isLoading={deleteCallToAction.isLoading}
                location={selectedLocation}
            />
            <EditCallToActionModal
                isOpen={isEditModalOpen}
                onOpenChange={setIsEditModalOpen}
                cta={selectedItem}
            />
        </>
    );
};

const List = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
`;

export default CallToActionList;
