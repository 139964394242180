export function buildQuery(query) {
    return query && Object.entries(query).length
        ? `?${Object.entries(query)
              .filter(([, q]) => !!q)
              .map(([key, val]) => {
                  if (val.map) {
                      return val.map((v) => `${key}[]=${v}`).join('&');
                  }
                  return [key, val].map(encodeURIComponent).join('=');
              })
              .filter((x) => x) // Remove falsy values
              .join('&')}`
        : '';
}

export function parseQuery(queryString) {
    return (
        queryString
            .substr(1)
            .split('&')
            .reduce((acc, keyVal) => {
                const [key, val] = keyVal
                    .split('=')
                    .map((e) => decodeURIComponent(e).replace(/\+/g, ' '));
                return { ...acc, [key]: val };
            }, {}) || {}
    );
}
