import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateClipTranscription } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import clipKeys from './clipKeys';
import { ClipTranscription } from './useClipTranscriptionQuery';

export interface MutationFnProps {
    clipId: string | number;
    transcription: ClipTranscription;
}

const mutationFn = async ({ clipId, transcription }: MutationFnProps) => {
    const { data } = await updateClipTranscription(clipId, transcription);
    return camelcaseKeys(data, { deep: true });
};

const updateClipTranscriptionMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: (_, { clipId }) => {
            queryClient.invalidateQueries({
                queryKey: clipKeys.transcriptionByClipId(clipId),
            });
        },
    });
};

export default updateClipTranscriptionMutation;
