import { createApi } from '../callApi';
const contentApi = createApi('CONTENT');

export const updateSmartlinkSettings = (show, body) => {
    return contentApi({ method: 'PUT', target: `/v1/shows/${show}/smartlink`, body });
};
export const fetchSmartlinkSettings = (show) => {
    return contentApi({ method: 'GET', target: `/v1/shows/${show}/smartlink` });
};
export const fetchSmartlinkDominantColor = (show) => {
    return contentApi({ method: 'GET', target: `/v1/shows/${show}/colors` });
};
