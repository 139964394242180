import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { ImgUploaderController } from '@ui/molecules/ImgUploader';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { useResponsive } from '@/shared/hooks/useResponsive';
import { InputTextController } from '@ui/atoms/InputText';
import { RadioGroupController } from '@ui/molecules/RadioGroup';
import InputRadio from '@ui/atoms/InputRadio/InputRadio';
import Stack from '@ui/layout/Stack';
import Cluster from '@ui/layout/Cluster';
import usePlaylistFormSchema, { PlaylistFormSchema } from './usePlaylistFormSchema.hook';
import UpgradeBadge from '@ui/atoms/UpgradeBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { PLAYLIST_PRIVACY } from '@/shared/config/constants';
import { InputRichTextController } from '@ui/atoms/InputRichText';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import { zodResolver } from '@hookform/resolvers/zod';
import Button from '@/components/Button';
import { Playlist } from '@/queries/playlist/usePlaylistsQuery.hook';
import { TooltipTrigger } from 'react-aria-components';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';
import { useEffect } from 'react';

interface PlaylistFormProps {
    mode?: 'create' | 'edit';
    onSubmit: (data: PlaylistFormSchema) => void;
    onClose: () => void;
    playlistLink?: string;
    playlist?: Playlist;
    isLoading: boolean;
    hasAccessToPlaylistConfidentiality: boolean;
    slugError?: string;
}

const PlaylistForm = ({
    mode = 'create',
    onSubmit,
    onClose,
    playlist,
    playlistLink,
    isLoading,
    hasAccessToPlaylistConfidentiality,
    slugError,
}: PlaylistFormProps) => {
    const intl = useIntl();
    const user = useUserQuery();
    const schema = usePlaylistFormSchema({
        locale: user.data?.language || 'en',
        isPrivacyOptionEnabled: hasAccessToPlaylistConfidentiality,
        mode,
    });
    const { control, handleSubmit, formState, setValue, watch, setError } =
        useForm<PlaylistFormSchema>({
            mode: 'onBlur',
            defaultValues: {
                password:
                    hasAccessToPlaylistConfidentiality && playlist?.password
                        ? playlist.password
                        : '',
                privacy: playlist?.privacy || 'public',
                image: playlist?.imageUrl || null,
                name: playlist?.name || '',
                html_description: playlist?.htmlDescription || '',
                slug: playlist?.slug,
            },
            resolver: zodResolver(schema),
        });
    const { isDesktop } = useResponsive();
    const { isDirty, isValid } = formState;
    const privacy = watch('privacy');
    const isPrivate = hasAccessToPlaylistConfidentiality && privacy === PLAYLIST_PRIVACY.PRIVATE;

    useEffect(() => {
        if (!slugError) return;
        setError('slug', {
            message: intl.formatMessage({
                defaultMessage: 'Ce permalien est déjà utilisé',
            }),
        });
    }, [slugError]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <InnerWrapper>
                <ImgUploaderController
                    control={control}
                    name="image"
                    horizontal={!isDesktop}
                    handleDeleteFile={() => setValue('image', null, { shouldDirty: true })}
                    w={160}
                />
                <FieldsWrapper>
                    <InputTextController
                        control={control}
                        name="name"
                        placeholder={intl.formatMessage({
                            defaultMessage: 'Veuillez saisir le titre de la playlist',
                        })}
                        label={<FormattedMessage defaultMessage="Titre de la playlist" />}
                        isRequired
                    />
                    {mode === 'edit' && (
                        <InputTextController
                            control={control}
                            name="slug"
                            label={<FormattedMessage defaultMessage="Permalien" />}
                            prefix={playlistLink}
                            isRequired
                        />
                    )}
                    <InputRichTextController
                        name="html_description"
                        control={control}
                        label={<FormattedMessage defaultMessage="Description" />}
                        charactersLimit={4000}
                    />
                    <RadioGroupController
                        control={control}
                        name="privacy"
                        label={<FormattedMessage defaultMessage="Confidentialité" />}
                        gap="1.25rem"
                    >
                        <InputRadio value="public">
                            <FormattedMessage defaultMessage="Publique (visible par tout le monde)" />
                        </InputRadio>
                        <Cluster $gap="0.5rem" $align="center">
                            <InputRadio
                                isDisabled={!hasAccessToPlaylistConfidentiality}
                                value="unlisted"
                            >
                                <FormattedMessage defaultMessage="Non-listée (non visible sur le Site Web)" />
                            </InputRadio>
                            {!hasAccessToPlaylistConfidentiality && (
                                <UpgradeBadge pricing="supersonic" />
                            )}
                        </Cluster>
                        <Stack $gap="0.5rem">
                            <Cluster $gap="0.5rem" $align="center">
                                <InputRadio
                                    isDisabled={!hasAccessToPlaylistConfidentiality}
                                    value="private"
                                >
                                    <FormattedMessage defaultMessage="Privée (protégée par un mot de passe)" />
                                    {hasAccessToPlaylistConfidentiality && (
                                        <TooltipTrigger delay={0} closeDelay={0}>
                                            <TooltipTriggerWrapper>
                                                <TooltipIcon
                                                    icon={icon({
                                                        name: 'circle-info',
                                                        style: 'solid',
                                                    })}
                                                />
                                            </TooltipTriggerWrapper>
                                            <Tooltip placement="top">
                                                <FormattedMessage defaultMessage="Votre playlist n’apparaitra pas sur votre Site Web, et sera protégée par un mot de passe que vous définissez." />
                                            </Tooltip>
                                        </TooltipTrigger>
                                    )}
                                </InputRadio>
                                {!hasAccessToPlaylistConfidentiality && (
                                    <UpgradeBadge pricing="supersonic" />
                                )}
                            </Cluster>
                            {isPrivate && (
                                <InputTextController
                                    control={control}
                                    name="password"
                                    placeholder={intl.formatMessage({
                                        defaultMessage: 'Saisir un mot de passe',
                                    })}
                                />
                            )}
                        </Stack>
                    </RadioGroupController>
                </FieldsWrapper>
            </InnerWrapper>
            <ButtonsWrapper>
                <Button type="submit" isDisabled={!isValid || !isDirty} isLoading={isLoading}>
                    {mode === 'edit' ? (
                        <FormattedMessage defaultMessage="Modifier la playlist" />
                    ) : (
                        <FormattedMessage defaultMessage="Créer la playlist" />
                    )}
                </Button>
                <Button variant="tertiary" onPress={onClose}>
                    <FormattedMessage defaultMessage="Annuler" />
                </Button>
            </ButtonsWrapper>
        </Form>
    );
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`;
const InnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
        column-gap: 2rem;
    }
`;
const FieldsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-grow: 1;
    }
`;
const ButtonsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    margin-top: auto;

    & > :first-child,
    > :last-child {
        align-self: stretch;
    }

    ${(p) => p.theme.mediaQueries.tabletAndUp} {
        flex-direction: row;
        justify-content: flex-end;
        column-gap: 0.5rem;
        margin-top: 0.5rem;

        & > :first-child,
        > :last-child {
            align-self: initial;
        }

        & > :first-child {
            order: 1;
        }
    }
`;
const TooltipIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: var(--neutral500);
`;

PlaylistForm.propTypes = {
    isNew: PropTypes.bool,
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
    playlistLink: PropTypes.string,
    playlist: PropTypes.object,
    isLoading: PropTypes.bool,
    hasAccessToPlaylistConfidentiality: PropTypes.bool,
};

export default PlaylistForm;
