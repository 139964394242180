import { useQuery } from '@tanstack/react-query';
import { psoAnalytics } from '@/api';
import camelcaseKeys from 'camelcase-keys';

const queryFn = async ({ queryKey: [{ showId, currentStore }] }) => {
    const { data } = await psoAnalytics(showId, currentStore);

    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const useAnalyticsQuery = ({ showId, currentStore }) => {
    return useQuery({
        queryKey: [{ entity: 'pso-stats-analytics', showId, currentStore }],
        queryFn,
    });
};

export default useAnalyticsQuery;
