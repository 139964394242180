import PropTypes from 'prop-types';
import styled from 'styled-components';
import { reflex } from '@ui/reflex';
import { computeCssValue } from '@/utils/css';
import { IMG_PLACEHOLDER } from '@/utils/constants';

const Img = styled.img
    .withConfig({
        shouldForwardProp: (p, defaultValidatorFn) =>
            !['radius', 'size'].includes(p) && defaultValidatorFn(p),
    })
    .attrs(({ src }) => ({ src: src || IMG_PLACEHOLDER }))`
    object-fit: cover;
    vertical-align: bottom;
    border-radius: ${(p) => computeCssValue(p.radius)};
    border: ${(p) => p.border};
    height: ${(p) => computeCssValue(p.size || p.h || p.height)};
    min-width: ${(p) => computeCssValue(p.size || p.w || p.width)};
    ${(p) => p.bordered && 'border-radius: var(--r-xs)'};
    ${(p) => p.circle && 'border-radius: var(--r-full)'};
    ${(p) => p.disabled && 'opacity: 0.5'};
    ${(p) => p.withShadow && 'box-shadow: var(--s-light)'};
`;

Img.propTypes = {
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    circle: PropTypes.bool,
    bordered: PropTypes.bool,
    border: PropTypes.string,
    radius: PropTypes.number,
    z: PropTypes.number,
    h: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    w: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    withShadow: PropTypes.bool,
    disabled: PropTypes.bool,
};

Img.defaultProps = {
    size: undefined,
    bordered: false,
    border: undefined,
    radius: undefined,
    circle: false,
    z: undefined,
    h: undefined,
    height: undefined,
    w: undefined,
    width: undefined,
    withShadow: false,
    disabled: undefined,
};

export default reflex(Img);
