import { useParams } from 'react-router';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import useSeasonsQuery from '@queries/season/useSeasonsQuery.hook';
import useAddSeasonMutation from '@queries/season/useAddSeasonMutation.hook';
import { useQueryClient } from '@tanstack/react-query';
import seasonKeys from '@queries/season/seasonKeys';
import Select from '../ui/atoms/Select';
import ListBoxItem from '../ui/atoms/ListBoxItem';
import { Key } from 'react-aria';
import Button from '../Button';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

const messages = defineMessages({
    season: { defaultMessage: 'Saison {season}' },
    noSeason: { defaultMessage: 'Non classé' },
});

export interface SelectSeasonsProps {
    onChange: (value: Key) => void;
    value: Key;
}

const SelectSeasons = ({ onChange, value }: SelectSeasonsProps) => {
    const queryClient = useQueryClient();
    const intl = useIntl();
    const { showId }: { showId: string } = useParams();
    const { data: seasons, isLoading, refetch } = useSeasonsQuery({ showId });
    const addSeason = useAddSeasonMutation();

    const setSeasonId = onChange;
    const seasonId = value;

    const handleAddSeason = () => {
        addSeason.mutate(
            { showId },
            {
                onSuccess: async (season) => {
                    queryClient.invalidateQueries({
                        queryKey: seasonKeys.detailById(showId),
                    });
                    await refetch();
                    setSeasonId(season.id);
                },
            },
        );
    };

    const items = seasons
        ? [
              { name: intl.formatMessage(messages.noSeason), id: 'none' },
              ...seasons.map((s) => ({
                  name: intl.formatMessage(messages.season, { season: s.seasonNumber }),
                  id: s.id,
              })),
          ]
        : [];

    return (
        <Select
            isLoading={isLoading}
            label={<FormattedMessage defaultMessage="Saison" />}
            items={items}
            selectedKey={seasonId}
            onSelectionChange={setSeasonId}
            customAction={
                <AddSeasonButton
                    variant="link-primary"
                    startIcon={<AddIcon icon={icon({ name: 'add', style: 'solid' })} />}
                    onPress={handleAddSeason}
                    isLoading={addSeason.isLoading}
                >
                    <FormattedMessage defaultMessage="Créer une saison" />
                </AddSeasonButton>
            }
            placeholder={intl.formatMessage({
                defaultMessage: 'Choisissez la saison des épisodes',
            })}
        >
            {(item) => <ListBoxItem {...item} />}
        </Select>
    );
};

const AddIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const AddSeasonButton = styled(Button)`
    padding: 0.5rem;
`;

export default SelectSeasons;
