import { useState, useEffect } from 'react';
import { useParams, useHistory, Route, Switch, Redirect } from 'react-router';
import PsoLayout from './PsoLayout';
import PsoKeywordTracking from './PsoKeywordTracking';
import PsoOverview from './PsoOverview';
import PsoConfigurationTunnel from './PsoConfigurationTunnel';
import PsoMobileBlockScreen from './PsoMobileBlockScreen';
import usePsoQuery from '@queries/pso/usePsoQuery.hook';
import PsoKeywords from './PsoKeywords';
import {
    APPLE_PODCASTS,
    SPOTIFY,
    USER_ROLES,
    ENABLE_PSO_COMPETITORS,
    ENABLE_PSO_LIVE_SEARCH,
} from '@/shared/config/constants';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import useSubscriptionQuery from '@/queries/subscription/useSubscriptionQuery.hook';
import PsoBlockingScreen from './PsoBlockingScreen';
import PsoLiveSearch from './PsoLiveSearch';
import PsoCompetitors from './PsoCompetitors';
import { STATUS_SHOWS } from '@/shared/config/statusShows';
import { useQueryClient } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import psoKeys from '@/queries/pso/psoKeys';
import useHasAccessToPso from '@/shared/hooks/useHasAccessToPso.hook';
import styled from 'styled-components';
import PsoKeywordsRanking from '@app/pages/PsoPage/PsoKeywordsRanking';
import useHasAccessToPsoKeywordsRanking from '@/shared/hooks/useHasAccessToPsoKeywordsRanking.hook';

const PsoPage = () => {
    const { showId }: { showId: string } = useParams();
    const history = useHistory();
    const queryClient = useQueryClient();
    const subscription = useSubscriptionQuery();
    const show = useShowQuery(showId);
    const psoConfiguration = usePsoQuery({ showId, enabled: true });
    const hasAccessToPso = useHasAccessToPso({ showId });
    const hasAccessToPsoKeywordsRanking = useHasAccessToPsoKeywordsRanking({ showId });
    const [currentStore, setCurrentStore] = useState('');
    const [configurationEndLoading, setConfigurationEndLoading] = useState(false);

    useEffect(() => {
        if (psoConfiguration.data) {
            setCurrentStore(
                psoConfiguration.data.stores.data.length > 0 &&
                    psoConfiguration.data.stores.data[0].alpha2Code,
            );
        }
    }, [psoConfiguration?.data]);

    if (
        psoConfiguration.isLoading ||
        show.isLoading ||
        show.isError ||
        subscription.isLoading ||
        subscription.isError ||
        (psoConfiguration.isError &&
            (psoConfiguration.error as AxiosError)?.response?.status !== 403 &&
            (psoConfiguration.error as AxiosError)?.response?.data?.message !==
                'Missing user option pso')
    ) {
        return null;
    }

    const hasSpotifyData =
        show.data.listeningLinks.data.findIndex((link) => link.key === SPOTIFY) > -1;
    const hasAppleData =
        show.data.listeningLinks.data.findIndex((link) => link.key === APPLE_PODCASTS) > -1;

    const isGuestShow = show.data && show.data.showStatus === STATUS_SHOWS.GUEST_SHOWS;
    const userIsAdminGuest = show?.data?.userRole === USER_ROLES.ADMIN && isGuestShow;
    const userIsOwner = show?.data?.userRole === USER_ROLES.OWNER;
    const userHasWriteAccess = userIsOwner || userIsAdminGuest;

    if (psoConfiguration.isLoading) {
        return <span>loading</span>;
    }

    if (!hasAccessToPso) {
        return <PsoBlockingScreen />;
    }

    return (
        <>
            <MobileWrapper>
                <PsoMobileBlockScreen />
            </MobileWrapper>
            <Switch>
                <Route path="/app/show/:showId/pso" exact>
                    <Redirect
                        to={
                            psoConfiguration.data?.fullyConfigured === false ||
                            configurationEndLoading
                                ? `/app/show/${showId}/pso/setup`
                                : `/app/show/${showId}/pso/overview`
                        }
                    />
                </Route>
                <Route path="/app/show/:showId/pso/setup">
                    <PsoConfigurationTunnel
                        keywordsSaved={psoConfiguration.data.keywordsSaved}
                        keywords={psoConfiguration.data.keywords}
                        onRedirectToOverview={() => {
                            queryClient.invalidateQueries({
                                queryKey: psoKeys.globalConfiguration(showId),
                            });
                            history.push(`/app/show/${showId}/pso/overview`);
                        }}
                        setConfigurationEndLoading={(v) => setConfigurationEndLoading(v)}
                        userHasWriteAccess={userHasWriteAccess}
                        currentStore={currentStore}
                        isFullyConfigured={psoConfiguration.data.fullyConfigured}
                    />
                </Route>
                <Route path="/app/show/:showId/pso/overview">
                    <PsoLayout userHasWriteAccess={userHasWriteAccess}>
                        <PsoOverview
                            globalConfiguration={psoConfiguration.data}
                            currentStore={currentStore}
                            setCurrentStore={setCurrentStore}
                            hasSpotifyData={hasSpotifyData}
                            hasAppleData={hasAppleData}
                            userHasWriteAccess={userHasWriteAccess}
                            stores={psoConfiguration.data.stores.data}
                        />
                    </PsoLayout>
                </Route>
                <Route path="/app/show/:showId/pso/details">
                    <PsoLayout userHasWriteAccess={userHasWriteAccess}>
                        <PsoKeywordTracking
                            currentStore={currentStore}
                            setCurrentStore={setCurrentStore}
                            hasSpotifyData={hasSpotifyData}
                            hasAppleData={hasAppleData}
                            userHasWriteAccess={userHasWriteAccess}
                            stores={psoConfiguration.data.stores.data}
                        />
                    </PsoLayout>
                </Route>
                {hasAccessToPsoKeywordsRanking && (
                    <Route path="/app/show/:showId/pso/ranking">
                        <PsoLayout userHasWriteAccess={userHasWriteAccess}>
                            <PsoKeywordsRanking />
                        </PsoLayout>
                    </Route>
                )}
                {ENABLE_PSO_COMPETITORS && (
                    <Route path="/app/show/:showId/pso/competitors">
                        <PsoLayout userHasWriteAccess={userHasWriteAccess}>
                            <PsoCompetitors />
                        </PsoLayout>
                    </Route>
                )}
                {ENABLE_PSO_LIVE_SEARCH && (
                    <Route path="/app/show/:showId/pso/livesearch">
                        <PsoLayout userHasWriteAccess={userHasWriteAccess}>
                            <PsoLiveSearch />
                        </PsoLayout>
                    </Route>
                )}
                <Route path="/app/show/:showId/pso/keywords">
                    <PsoLayout userHasWriteAccess={userHasWriteAccess}>
                        <PsoKeywords
                            currentStore={currentStore}
                            existingKeywords={psoConfiguration.data.keywords}
                        />
                    </PsoLayout>
                </Route>
            </Switch>
        </>
    );
};

const MobileWrapper = styled.div`
    display: block;
    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        display: none;
    }
`;

export default PsoPage;
