import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';

interface StatusProps {
    status: 'draft' | 'blocked' | 'planified' | 'active' | 'paused' | 'finished';
}

const Status = ({ status }: StatusProps) => (
    <StatusWrapper $color={statusLookup[status as keyof typeof statusLookup].color}>
        {statusLookup[status as keyof typeof statusLookup].icon}
        {statusLookup[status as keyof typeof statusLookup].message}
    </StatusWrapper>
);

const StatusWrapper = styled.div<{ $color: string }>`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(${({ $color }) => $color});
    background-color: var(${({ $color }) => `${$color}50`});
    padding: 0.5rem;
    border-radius: var(--r-s);
    font-weight: var(--fw-semibold);
`;
const StatusIcon = styled(FontAwesomeIcon)`
    font-size: 0.75rem;
`;

const statusLookup = {
    paused: {
        icon: <StatusIcon icon={icon({ name: 'pause', style: 'solid' })} />,
        message: <FormattedMessage defaultMessage="En pause" />,
        hoverMessage: <FormattedMessage defaultMessage="Campagne en pause" />,
        color: '--warning',
    },
    draft: {
        icon: <StatusIcon icon={icon({ name: 'pen-ruler', style: 'solid' })} />,
        message: <FormattedMessage defaultMessage="Brouillon" />,
        hoverMessage: <FormattedMessage defaultMessage="Campagne en brouillon" />,
        color: '--neutral',
    },
    blocked: {
        icon: <StatusIcon icon={icon({ name: 'circle-exclamation', style: 'solid' })} />,
        message: <FormattedMessage defaultMessage="Bloquée" />,
        hoverMessage: <FormattedMessage defaultMessage="Campagne bloquée" />,
        color: '--alert',
    },
    active: {
        icon: <StatusIcon icon={icon({ name: 'circle-check', style: 'solid' })} />,
        message: <FormattedMessage defaultMessage="En ligne" />,
        hoverMessage: <FormattedMessage defaultMessage="Campagne en ligne" />,
        color: '--success',
    },
    planified: {
        icon: <StatusIcon icon={icon({ name: 'clock', style: 'regular' })} />,
        message: <FormattedMessage defaultMessage="Planifiée" />,
        hoverMessage: <FormattedMessage defaultMessage="Campagne planifiée" />,
        color: '--info',
    },
    finished: {
        icon: <StatusIcon icon={icon({ name: 'circle-check', style: 'solid' })} />,
        message: <FormattedMessage defaultMessage="Terminée" />,
        hoverMessage: <FormattedMessage defaultMessage="Campagne terminée" />,
        color: '--primary',
    },
};

export default Status;
