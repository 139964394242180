import { FormattedNumber, FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { roundToNearestHalf } from '@/shared/utils/number';
// @ts-ignore
import ApplePodcastsIcon from '@public/images/platforms/ApplePodcasts.svg?url';
import Button from '@/components/Button';
import ProgressBar from '@/components/ui/ProgressBar';
import styled from 'styled-components';

interface ReviewSummaryProps {
    average: number;
    reviews: Record<number, number>;
    hasSeeAllReviewsButton?: boolean;
    handleSeeAllReviews?: () => void;
    slottedEmptyState?: React.ReactNode;
}

const ReviewSummary = ({
    average,
    reviews,
    hasSeeAllReviewsButton,
    handleSeeAllReviews,
    slottedEmptyState,
}: ReviewSummaryProps) => {
    const totalReviews = Object.values(reviews).reduce((acc, curr) => acc + curr, 0);
    const roundedRating = roundToNearestHalf(average);
    return (
        <ReviewSummaryWrapper>
            <AverageAndViewAll>
                <AverageAndTotalReviews>
                    <Average>
                        <FormattedNumber value={average} />
                    </Average>
                    <StarsAverage>
                        {[...Array(5)].map((_, index) => {
                            const starNumber = index + 1;
                            const starType =
                                roundedRating >= starNumber
                                    ? 'full'
                                    : roundedRating >= starNumber - 0.5
                                    ? 'half'
                                    : 'empty';

                            if (starType === 'empty') {
                                return <EmptyStar key={index} icon={icon({ name: 'star' })} />;
                            }

                            if (starType === 'half') {
                                return (
                                    <HalfStarWrapper key={index}>
                                        <HalfStar icon={icon({ name: 'star-half' })} />
                                        <StarBackground icon={icon({ name: 'star' })} />
                                    </HalfStarWrapper>
                                );
                            }

                            return <Star key={index} icon={icon({ name: 'star' })} />;
                        })}
                    </StarsAverage>
                    <TotalReviewsWrapper>
                        <TotalReviews>
                            <FormattedMessage
                                defaultMessage="{count, plural, zero {# note} one {# note} other {# notes}}"
                                values={{
                                    count: totalReviews,
                                }}
                            />
                        </TotalReviews>
                        <ApplePodcasts src={ApplePodcastsIcon} />
                    </TotalReviewsWrapper>
                </AverageAndTotalReviews>
                {hasSeeAllReviewsButton && (
                    <Button onPress={handleSeeAllReviews} variant="secondary">
                        <FormattedMessage
                            defaultMessage="Voir toutes les notes
"
                        />
                    </Button>
                )}
            </AverageAndViewAll>
            <Separator />

            {slottedEmptyState ? (
                <EmptyStateWrapper>{slottedEmptyState}</EmptyStateWrapper>
            ) : (
                <Grades>
                    {[...Array(5)].map((_, index) => {
                        const grade = 5 - index;
                        const nbReviews = reviews[grade] || 0;
                        const percentage = nbReviews > 0 ? (nbReviews / totalReviews) * 100 : 0;
                        return (
                            <Grade key={index}>
                                <GradeLabel>{grade}</GradeLabel>
                                <ProgressBar
                                    score={percentage}
                                    staticColor={'--decorativeD'}
                                    preciseRender={true}
                                />
                                <Total>
                                    <FormattedNumber value={nbReviews} />
                                </Total>
                            </Grade>
                        );
                    })}
                </Grades>
            )}
        </ReviewSummaryWrapper>
    );
};

const ReviewSummaryWrapper = styled.div`
    display: flex;
    flex-direction: column;

    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        flex-direction: row;
        align-items: center;
        height: 10rem;
    }
`;
const AverageAndTotalReviews = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;

    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        align-items: flex-start;
    }
`;
const Average = styled.span`
    font-weight: var(--fw-bold);
    font-size: var(--fs-heading-xl);
    line-height: 3.2rem;
`;
const StarsAverage = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: -0.2rem;
`;
const EmptyStar = styled(FontAwesomeIcon)`
    color: #ffe8b5;
`;
const Star = styled(FontAwesomeIcon)`
    color: #ffc747;
`;
const HalfStarWrapper = styled.div`
    position: relative;
    width: 14px;
    height: 14px;
`;
const HalfStar = styled(FontAwesomeIcon)`
    position: absolute;
    top: 0;
    left: 0;
    color: #ffc747;
    z-index: 2;
`;
const StarBackground = styled(FontAwesomeIcon)`
    position: absolute;
    top: 0;
    left: 0;
    color: #ffe8b5;
`;
const TotalReviewsWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
`;
const TotalReviews = styled.span`
    color: var(--neutral);
    font-size: var(--fs-body-m);
`;
const ApplePodcasts = styled.img`
    width: 0.75rem;
`;
const AverageAndViewAll = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
`;
const Separator = styled.div`
    height: 1px;
    width: 100%;
    background-color: var(--neutral100);
    margin-block: 2rem;

    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        width: 1px;
        height: 100%;
        margin-inline: 2rem;
        margin-block: 0;
    }
`;
const Grades = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0;

    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        flex: 1;
    }
`;
const Grade = styled.li`
    display: flex;
    align-items: center;
    gap: 0.75rem;
    width: 100%;
`;
const GradeLabel = styled.span`
    font-weight: var(--fw-semibold);
    font-size: var(--fs-body-m);
`;
const Total = styled.span`
    color: var(--neutral);
    font-size: var(--fs-body-m);
    flex-basis: 2rem;
    text-align: right;
`;
const EmptyStateWrapper = styled.div`
    flex: 1;
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default ReviewSummary;
