import IconButton from '@/components/IconButton';
import Modal from '@/components/ui/atoms/Modal';
import Text from '@/components/ui/atoms/Text';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import EditPlaylist from '../EditPlaylist';
import { Playlist } from '@/queries/playlist/usePlaylistsQuery.hook';

interface EditPlaylistModalProps {
    isOpen: boolean;
    onOpenChange: (open: boolean) => void;
    playlist: Playlist | null;
}

const EditPlaylistModal = ({ isOpen, onOpenChange, playlist }: EditPlaylistModalProps) => {
    if (!playlist) return null;

    const handleClose = () => onOpenChange(false);

    return (
        <ModalStyled isOpen={isOpen} onOpenChange={onOpenChange} size="large">
            <Header>
                <Text variant="headingM" fontWeight="--fw-bold">
                    <FormattedMessage defaultMessage="Modifier la playlist" />
                </Text>
                <IconButton
                    icon={<CloseIcon icon={icon({ name: 'xmark-large', style: 'solid' })} />}
                    onPress={handleClose}
                    variant="ghost"
                    aria-label="Close modal"
                    style={{ padding: 0 }}
                />
            </Header>
            <ScrollableWrapper>
                <EditPlaylist onClose={handleClose} playlist={playlist} />
            </ScrollableWrapper>
        </ModalStyled>
    );
};

const ModalStyled = styled(Modal)`
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 2rem;
`;
const Header = styled.div`
    display: flex;
    justify-content: space-between;
`;
const CloseIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--neutral500);
`;
const ScrollableWrapper = styled.div`
    width: 100%;
    flex: 1;
    min-height: 0;
    overflow-y: auto;
`;

export default EditPlaylistModal;
