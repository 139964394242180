import { FormattedMessage } from 'react-intl';
import { useSwitch } from '@/shared/hooks/useSwitch';
import Filters from '../../Filters';
import styled from 'styled-components';

const ApplePodcastsDateSelector = () => {
    const [opened] = useSwitch();

    return (
        <ApplePodcastsDateSelectorWrapper opened={opened}>
            <Title>
                <FormattedMessage defaultMessage="Votre classement en détails" />
            </Title>
            <Filters episodeAndCampaignDisabled={true} />
        </ApplePodcastsDateSelectorWrapper>
    );
};

const ApplePodcastsDateSelectorWrapper = styled.div`
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: ${({ opened }) => (opened ? 2 : 0)};

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;
const Title = styled.h2`
    font-size: var(--fs-heading-l);
`;

export default ApplePodcastsDateSelector;
