import { createContext, Dispatch, useContext, useReducer } from 'react';

type LoadingMapItem =
    | 'title'
    | 'description'
    | 'tags'
    | 'chapters'
    | 'newsletter-title'
    | 'newsletter-message';
type LoadingMap = Map<LoadingMapItem, boolean>;

export const EPISODE_AI_LOADING_ACTIONS = {
    START: 'START',
    STOP: 'STOP',
} as const;

type LoadingActionType = keyof typeof EPISODE_AI_LOADING_ACTIONS;

interface LoadingAction {
    type: LoadingActionType;
    payload: LoadingMapItem;
}

type IEpisodeAILoadingContext = [LoadingMap, Dispatch<LoadingAction>];

const initialLoadingState: LoadingMap = new Map([
    ['title', false],
    ['description', false],
    ['tags', false],
    ['chapters', false],
    ['newsletter-title', false],
    ['newsletter-message', false],
]);

const EpisodeAILoadingContext = createContext<IEpisodeAILoadingContext>([
    initialLoadingState,
    () => {},
]);

const loadingReducer = (state: LoadingMap, action: LoadingAction): LoadingMap => {
    const nextState: LoadingMap = new Map(state);

    switch (action.type) {
        case 'START': {
            nextState.set(action.payload, true);
            break;
        }
        case 'STOP': {
            nextState.set(action.payload, false);
            break;
        }
        default:
            return state;
    }

    return nextState;
};

interface EpisodeAILoadingContextProviderProps {
    children: React.ReactNode;
}

const EpisodeAILoadingContextProvider = ({ children }: EpisodeAILoadingContextProviderProps) => {
    const reducer = useReducer(loadingReducer, initialLoadingState);

    return (
        <EpisodeAILoadingContext.Provider value={reducer}>
            {children}
        </EpisodeAILoadingContext.Provider>
    );
};

export const useEpisodeAILoading = (): IEpisodeAILoadingContext => {
    const context = useContext(EpisodeAILoadingContext);
    if (!context) {
        throw new Error('useAILoading must be used within a AILoadingProvider');
    }
    return context;
};

export default EpisodeAILoadingContextProvider;
