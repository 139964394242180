import { useMutation, useQueryClient } from '@tanstack/react-query';
import { uploadClipFormatImage } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import clipFormatKeys from './clipFormatKeys';
import { ClipFormat } from './types';

export interface MutationFnProps {
    clipFormatId: string | number;
    file: File;
}

const mutationFn = async ({ clipFormatId, file }: MutationFnProps): Promise<ClipFormat> => {
    const { data } = await uploadClipFormatImage(clipFormatId, file);
    return camelcaseKeys(data as any, { deep: true });
};

const uploadClipFormatImageMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn,
        onSuccess: (_, { clipFormatId }) => {
            queryClient.invalidateQueries(clipFormatKeys.detailById(clipFormatId));
        },
    });
};

export default uploadClipFormatImageMutation;
