import { createFetcher } from '../Fetcher';

export default createFetcher(
    ({ sourcesStatsStore }, { showId, itemType, itemId, start, end, episodes }) => ({
        fetch: () =>
            sourcesStatsStore.fetchSourcePlatformsLeaderboard(showId, itemType, itemId, {
                start,
                end,
                podcasts: episodes,
            }),
        isLoading: sourcesStatsStore.fetchSourcePlatformsLeaderboard.pending,
        input: [showId, itemType, itemId, start, end, episodes],
    }),
);
