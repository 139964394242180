const showKeys = {
    all: () => [{ entity: 'show' }],
    allLists: () => [{ ...showKeys.all()[0], scope: 'list' }],
    list: ({ page = 1, perPage = 20, sort = null, transformer = null } = {}) => [
        { ...showKeys.allLists()[0], page, perPage, sort, transformer },
    ],
    listByChannelId: (
        rawChannelId: string,
        { page = 1, perPage = 20, sort = null, transformer = null } = {},
    ) => [
        {
            ...showKeys.allLists()[0],
            channelId: Number(rawChannelId),
            page,
            perPage,
            sort,
            transformer,
        },
    ],
    listArchived: (channelId: string) => [
        { ...showKeys.listByChannelId(channelId)[0], status: 'archived' },
    ],
    allDetails: () => [{ ...showKeys.all()[0], scope: 'detail' }],
    detailById: (rawShowId: string) => [{ ...showKeys.allDetails()[0], showId: Number(rawShowId) }],
    detailByPublicId: (rawShowPublicId: string) => [
        { ...showKeys.allDetails()[0], showPublicId: Number(rawShowPublicId) },
    ],
};

export default showKeys;
