import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled, { css } from 'styled-components';

const Tag = ({ label, onDelete, isDisabled, isReadonly, ...props }) => {
    return (
        <TagWrapper {...props}>
            {label}
            <CloseButton isDisabled={isDisabled} isReadonly={isReadonly} onClick={onDelete}>
                <FontAwesomeIcon icon={icon({ name: 'xmark', style: 'solid' })} size="1x" />
            </CloseButton>
        </TagWrapper>
    );
};

const TagWrapper = styled.li`
    display: inline-flex;
    align-items: center;
    padding-block: 0.25rem;
    padding-inline: 0.5rem;
    background: var(--primary50);
    color: var(--primary);
    font-weight: var(--fw-semibold);
    border-radius: var(--r-s);
    gap: 0.25rem;

    ${({ isAnimated }) =>
        isAnimated &&
        css`
            @keyframes bounce {
                0% {
                    transform: scale(1.1);
                }
                50% {
                    transform: scale(0.9);
                }
                100% {
                    transform: scale(1);
                }
            }

            animation: bounce 0.3s ease-in-out;
        `}
`;
const CloseButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.25rem;
    height: 1.25rem;
    background: none;
    border: none;
    color: var(--primary);
    border-radius: var(--r-s);
    padding: 0.25rem;
    aspect-ratio: 1/1;
    cursor: pointer;

    ${({ isDisabled, isReadonly }) =>
        isDisabled || isReadonly
            ? 'cursor: not-allowed;'
            : css`
                  &:hover {
                      background: var(--primary100);
                  }
              `}
`;

Tag.propTypes = {
    label: PropTypes.string,
    onDelete: PropTypes.func,
    isDisabled: PropTypes.bool,
    isReadonly: PropTypes.bool,
};

export default Tag;
