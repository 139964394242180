import { Editor } from 'draft-js';
import { FormattedMessage } from 'react-intl';
import { useTemplate } from '@/shared/hooks/useTemplate';
import DraftContent from '@ui/atoms/DraftContent';
import DeprecatedInput from '@ui/atoms/DeprecatedInput';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';

function TemplateEditor({ field }) {
    const [editorState, onEditorChange, addTemplate, editorRef] = useTemplate(field);
    return (
        <>
            <DeprecatedText weight="semibold" mt={12} mb={4}>
                <FormattedMessage defaultMessage="Texte prédéfini" />
            </DeprecatedText>
            <DeprecatedText color="--neutral500" mb={8}>
                <FormattedMessage defaultMessage="Lorsque vous configurerez un nouveau post, ce texte sera déjà saisi. Vous pourrez toujours le modifier par la suite." />
            </DeprecatedText>
            <DeprecatedInput as="div" mt={5} h="max-content" style={{ background: 'white' }}>
                {(inputProps) => (
                    <DraftContent>
                        <Editor
                            ref={editorRef}
                            editorState={editorState}
                            onChange={onEditorChange}
                            {...inputProps}
                        />
                    </DraftContent>
                )}
            </DeprecatedInput>
            <DeprecatedPaper flex mt={5} align="center">
                <DeprecatedText mr={10} size="small" weight="semibold" color="--neutral500" nowrap>
                    <FormattedMessage defaultMessage="Insérer :" />
                </DeprecatedText>
                <DeprecatedPaper flex gap={16}>
                    <DeprecatedText
                        onClick={() => addTemplate('episode.name')}
                        size="small"
                        as="span"
                        weight="semibold"
                        color="--primary"
                    >
                        <FormattedMessage defaultMessage="< Titre de l'épisode >" />
                    </DeprecatedText>

                    <DeprecatedText
                        onClick={() => addTemplate('show.name')}
                        size="small"
                        as="span"
                        weight="semibold"
                        color="--primary"
                    >
                        <FormattedMessage defaultMessage="< Nom de l'émission >" />
                    </DeprecatedText>

                    <DeprecatedText
                        onClick={() => addTemplate('episode.tags')}
                        size="small"
                        as="span"
                        weight="semibold"
                        color="--primary"
                    >
                        <FormattedMessage defaultMessage="< Liste tags >" />
                    </DeprecatedText>
                </DeprecatedPaper>
            </DeprecatedPaper>
        </>
    );
}

export default TemplateEditor;
