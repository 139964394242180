import { useState, useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';

// If scrollHeight of the <textarea> is taller than MULTILINE_THRESHOLD (in px),
// the textarea is considered as using multiple lines
const MULTILINE_THRESHOLD = 40;
// Offset added to the height of <textarea> when multiline to match visual padding of single-line textarea
const MULTILINE_OFFSET = 10;

const Textarea = styled.textarea`
    background: var(--white);
    border: 1px solid ${(props) => (props.active ? 'var(--primary)' : 'var(--neutral200)')};
    border-radius: var(--r-xs);
    transition: border-color 0.5s;
    padding: 0.5rem 0.75rem;
    color: var(--black);
    font-size: var(--fs-body);
    resize: none;

    :hover {
        border-color: ${(props) => (props.active ? 'var(--primary)' : 'var(--neutral500)')};
    }

    :active,
    :focus {
        border-color: var(--primary);
    }
`;

/**
 * See https://medium.com/@lucasalgus/creating-a-custom-auto-resize-textarea-component-for-your-react-web-application-6959c0ad68bc
 */
const AutoTextarea = ({ children, onChange, ...other }) => {
    const textAreaRef = useRef(null);
    const [text, setText] = useState('');
    const [textAreaHeight, setTextAreaHeight] = useState('auto');

    // Use useLayoutEffect to prevent a flash with textarea incorrect size
    useLayoutEffect(() => {
        if (textAreaRef.current) {
            const scrollHeight = textAreaRef.current.scrollHeight;
            setTextAreaHeight(
                `${scrollHeight + (scrollHeight >= MULTILINE_THRESHOLD ? MULTILINE_OFFSET : 0)}px`,
            );
        }
    }, [text]);

    const onChangeHandler = (event) => {
        setTextAreaHeight('auto');
        setText(event.target.value);

        if (onChange) {
            onChange(event.target.value);
        }
    };

    return (
        <Textarea
            ref={textAreaRef}
            rows={1}
            onChange={onChangeHandler}
            style={{ height: textAreaHeight }}
            {...other}
        >
            {children}
        </Textarea>
    );
};

export default AutoTextarea;
