import { Control, useController } from 'react-hook-form';
import InputRichPlainText from './InputRichPlainText';

interface InputRichPlainTextControllerProps
    extends Omit<React.ComponentProps<typeof InputRichPlainText>, 'value'> {
    control: Control<any>;
    name: string;
    onChange?: (value: string) => void;
}

export const InputRichPlainTextController = (props: InputRichPlainTextControllerProps) => {
    const { control, name, onChange, onBlur, ...rest } = props;
    const { field, fieldState } = useController({
        name,
        control,
    });

    return (
        <InputRichPlainText
            {...rest}
            value={field.value}
            onChange={(value) => {
                onChange && onChange(value);
                field.onChange(value);
            }}
            onBlur={(e) => {
                onBlur && onBlur(e);
                field.onBlur();
            }}
            isInvalid={!!fieldState.error}
            errorMessage={fieldState.error?.message}
        />
    );
};
