import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import DeprecatedWhitePaper from '@ui/atoms/DeprecatedWhitePaper';
import { DeprecatedIcon } from '@ui/atoms/DeprecatedIcon';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import DeprecatedButton from '@ui/atoms/DeprecatedButton';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import Cluster from '@ui/layout/Cluster';
import clipIcon from '@public/icons/clip.svg';
import linkIcon from '@public/icons/link.svg';
import smartlinkIcon from '@public/icons/rocket.svg';
import trashIcon from '@public/icons/trash.svg';
import { connect } from '@app/decorators/connect';
import Img from '@ui/atoms/Img';
import { useResponsive } from '@/utils/hooks/useResponsive';
import ShareButtonClip from './ShareButtonClip';
import ShareButtonImage from './ShareButtonImage';

import FetcherPageSettings from '@app/fetchers/FetcherPageSettings';
import IconImage from '@ui/icons/IconImage';
import Text from '@ui/atoms/Text';
import useSmartlinkSettingsQuery from '@/queries/smartlinkSetting/useSmartlinkSettingsQuery.hook';
import styled from 'styled-components';
import Stack from '@/components/ui/layout/Stack';
import Alert from '@/components/ui/atoms/Alert';
import RouterLink from '@/components/Link/RouterLink';
import useEpisodeClipFormatsQuery from '@/queries/clipFormat/useEpisodeClipFormatsQuery.hook';

// TODO: Need to refactor this component

const ShareButtonDefault = ({ type, onSelectContent }) => {
    const { isMobileOrTablet } = useResponsive();

    return (
        <DeprecatedButton
            h={40}
            accent="rounded"
            style={{ borderRadius: 4, width: isMobileOrTablet && '100%' }}
            disabled={type.disabled}
            onClick={() => onSelectContent(type)}
            data-testid={`social-media-button-${type.name}`}
        >
            <DeprecatedIcon as={type.icon} size={20} />
            <DeprecatedText weight="semibold" ml={8}>
                {type.label}
            </DeprecatedText>
        </DeprecatedButton>
    );
};

const WrapperContent = ({ children, isMobile }) => (
    <>
        {isMobile ? (
            children
        ) : (
            <Cluster $align="center" $flexWrap="no-wrap">
                {children}
            </Cluster>
        )}
    </>
);

const enhance = connect(({ routerStore, pageSettingsStore, autoSharingStore }) => ({
    showId: routerStore.params.showId,
    settings: pageSettingsStore.settings,
    deleteUploadedMessageImage: autoSharingStore.deleteUploadedMessageImage,
}));

const SocialMediaContentTypeButtons = ({
    showId,
    episode,
    post,
    provider,
    settings,
    onChangeType,
    onChangeContent,
    deleteUploadedMessageImage,
}) => {
    const intl = useIntl();
    const { data: smartlinkSettings } = useSmartlinkSettingsQuery(showId);
    const clipFormats = useEpisodeClipFormatsQuery({
        episodeId: episode.id,
        options: {
            staleTime: 0,
        },
    });
    const formats = clipFormats.data?.filter(
        (clip) =>
            clip.state === 'done' && (provider === 'instagram' ? clip.format !== 'story' : true),
    );
    const hasCloseNoClipAlert = localStorage.getItem(`episode-${episode.id}-closed-no-clip-alert`);
    const [isNoClipAlertVisible, setIsNoClipAlertVisible] = useState(!hasCloseNoClipAlert);
    const contentTypes = {
        clip: {
            name: 'clip',
            disabled: formats?.length < 1,
            label: <FormattedMessage defaultMessage="Clip vidéo" />,
            icon: clipIcon,
            renderComponent: ShareButtonClip,
            renderWhenSelected: (format) => {
                // If format is an instance of ClipModel check it has a clip property
                const title =
                    'clip' in format
                        ? format?.clip?.adminTitle || format?.clip?.title
                        : format?.adminTitle || format?.title;

                return {
                    image: <Img src={format?.previewUrl} maxH={48} maxW={48} radius={4} />,
                    title: format && <Text fontWeight="--fw-semibold">{title}</Text>,
                    type: <FormattedMessage defaultMessage="Clip vidéo" />,
                };
            },
        },
        image: {
            name: 'image',
            disabled: false,
            label: <FormattedMessage defaultMessage="Image" />,
            icon: <IconImage />,
            renderComponent: ShareButtonImage,
            renderWhenSelected: (image) => ({
                image: <Img src={image.url} maxH={48} maxW={48} radius={4} />,
                title: image.name,
                type: <FormattedMessage defaultMessage="Image" />,
            }),
        },
        link: {
            name: 'link',
            disabled: !settings?.isPublished || (!episode?.customUrl && !episode?.siteUrl),
            label: <FormattedMessage defaultMessage="Lien de l'épisode" />,
            icon: linkIcon,
            renderComponent: ShareButtonDefault,
            renderWhenSelected: (url) => ({
                image: <DeprecatedIcon as={linkIcon} size={24} />,
                title: url,
                type: <FormattedMessage defaultMessage="Lien d'écoute" />,
            }),
        },
        smartlink: {
            name: 'smartlink',
            disabled: !smartlinkSettings?.isPublished || !episode?.smartlinkUrl,
            label: <FormattedMessage defaultMessage="Lien du smartlink" />,
            icon: smartlinkIcon,
            renderComponent: ShareButtonDefault,
            renderWhenSelected: (url) => ({
                image: <DeprecatedIcon as={smartlinkIcon} size={24} />,
                title: url,
                type: <FormattedMessage defaultMessage="Lien du smartlink" />,
            }),
        },
    };

    const [selected, setSelected] = useState(post?.type || '');

    /**
     * Different content-type shared need different entry parameter.
     * This function selects what data goes to the render-component for the selected type.
     * @param {*} type
     * @param {*} format
     * @param {*} image
     * @param {*} sharedUrl
     * @returns
     */
    const selectedContentParameterProvider = (type, format, image, sharedUrl) => {
        if (type === 'clip') return format;
        if (['image', 'podcast_cover'].includes(type)) return image;
        return sharedUrl;
    };

    const formattedDefaultPost = (post) => {
        let isCover = false;
        let type = post?.type || 'link';

        if (type === 'podcast_cover') {
            isCover = true;
            type = 'image';
        }

        return {
            type: post.type,
            render: contentTypes[type].renderWhenSelected(
                selectedContentParameterProvider(
                    type,
                    post.clip,
                    {
                        url: post.imageUrl,
                        name: isCover
                            ? intl.formatMessage({ defaultMessage: "Couverture de l'épisode" })
                            : '',
                        isCover,
                    },
                    post.sharedUrl,
                ),
            ),
        };
    };

    const [selectedContent, setSelectedContent] = useState(post ? formattedDefaultPost(post) : {});
    const { isMobileOrTablet } = useResponsive();

    const onSelectContent = (type, format = null, image = null) => {
        setSelected(type);
        const formattedType =
            type === 'image' && image.isCover
                ? 'podcast_cover'
                : type === 'image' && !image.isCover
                ? 'image'
                : type;
        onChangeType(formattedType);

        if (type === 'clip') {
            onChangeContent(format.id);
        }

        setSelectedContent({
            type: formattedType,
            render: contentTypes[type].renderWhenSelected(
                selectedContentParameterProvider(
                    type,
                    format,
                    image,
                    type === 'link'
                        ? episode?.customUrl || episode?.getSiteUrl || episode?.siteUrl
                        : episode?.smartlinkUrl,
                ),
            ),
        });
    };

    return (
        <FetcherPageSettings showId={showId}>
            {selected ? (
                <DeprecatedWhitePaper bb={1} border="--neutral100" mt={8} pb={16}>
                    <Cluster $justify="space-between" $align="center">
                        <WrapperContent isMobile={isMobileOrTablet}>
                            <DeprecatedPaper
                                flex
                                align="center"
                                justify="center"
                                background="--neutral50"
                                radius={4}
                                h={56}
                                w={88}
                                mr={12}
                                mb={isMobileOrTablet && 12}
                            >
                                {selectedContent.render.image}
                            </DeprecatedPaper>
                            <DeprecatedPaper flex column gap={4} maxW="85%">
                                <DeprecatedText ellipsis>
                                    {selectedContent.render.title}
                                </DeprecatedText>
                                <DeprecatedText color="--neutral500" size="small">
                                    {selectedContent.render.type}
                                </DeprecatedText>
                            </DeprecatedPaper>
                        </WrapperContent>
                        <DeprecatedButton
                            accent="rounded"
                            icon
                            onClick={() => {
                                if (selectedContent.type === 'image') {
                                    deleteUploadedMessageImage(post.id);
                                }
                                setSelected('');
                                onChangeType(null);
                            }}
                        >
                            <DeprecatedIcon as={trashIcon} />
                        </DeprecatedButton>
                    </Cluster>
                </DeprecatedWhitePaper>
            ) : (
                <RelativeWrapper>
                    <Stack $gap="0.5rem">
                        <Cluster $gap="0.5rem">
                            {Object.values(contentTypes).map((type) => {
                                if (type.name === 'clip' && !clipFormats.data) return null;
                                return (
                                    <type.renderComponent
                                        key={type.name}
                                        type={type}
                                        onSelectContent={(type, format = null, image = null) =>
                                            onSelectContent(type.name, format, image)
                                        }
                                        provider={provider}
                                        formats={formats}
                                        post={post}
                                        cover={episode.imageUrl}
                                    />
                                );
                            })}
                        </Cluster>
                        {clipFormats.data && formats?.length < 1 && isNoClipAlertVisible && (
                            <ArrowAlert>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 16 8"
                                    fill="none"
                                >
                                    <path d="M6.58579 1.41421C7.36684 0.633164 8.63316 0.633165 9.41421 1.41421L16 8H0L6.58579 1.41421Z" />
                                </svg>
                                <Alert
                                    variant="primary"
                                    description={
                                        <Text>
                                            <FormattedMessage defaultMessage="Boostez la visibilité de votre épisode grâce à un extrait vidéo de votre épisode. 🚀" />
                                        </Text>
                                    }
                                    action={
                                        <ClipLink
                                            to={`/app/show/${showId}/communication/clip`}
                                            variant="button-primary"
                                            size="small"
                                            target="_blank"
                                        >
                                            <FormattedMessage defaultMessage="Créer un clip vidéo" />
                                        </ClipLink>
                                    }
                                    closable={true}
                                    onClose={() => {
                                        setIsNoClipAlertVisible(false);
                                        localStorage.setItem(
                                            `episode-${episode.id}-closed-no-clip-alert`,
                                            true,
                                        );
                                    }}
                                >
                                    <Text fontWeight="--fw-semibold">
                                        <FormattedMessage defaultMessage="Créez dès maintenant un clip vidéo pour promouvoir votre épisode !" />
                                    </Text>
                                </Alert>
                            </ArrowAlert>
                        )}
                    </Stack>
                </RelativeWrapper>
            )}
        </FetcherPageSettings>
    );
};

const RelativeWrapper = styled.div`
    position: relative;
`;
const ClipLink = styled(RouterLink)`
    width: 100%;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        width: fit-content;
    }
`;
const ArrowAlert = styled.div`
    display: flex;
    flex-direction: column;

    & > svg {
        display: none;

        ${({ theme }) => theme.mediaQueries.desktopAndUp} {
            display: block;
            width: 1rem;
            height: 0.5rem;
            margin-left: 1rem;
            fill: var(--primary50);
        }
    }
`;

export default enhance(SocialMediaContentTypeButtons);
