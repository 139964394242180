import PropTypes from 'prop-types';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import { FormattedMessage } from 'react-intl';
import Link from '@ui/atoms/Link';
import ShowPlaceholderImage from '@public/images/default_cover.png';
import { STATUS_SHOWS } from '@/utils/statusShows';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';

const status = {
    [STATUS_SHOWS.ACTIVE_SHOWS]: <FormattedMessage defaultMessage="Émission active" />,
    [STATUS_SHOWS.ARCHIVED_SHOWS]: <FormattedMessage defaultMessage="Émission archivée" />,
    [STATUS_SHOWS.GUEST_SHOWS]: <FormattedMessage defaultMessage="Émission invité" />,
};

const ImgMenuDropdown = ({ show }) => {
    return (
        <ShowMenu as={Link} to={`/app/show/all/${show.id}`}>
            <ShowCoverWrapper>
                <ShowCover src={show?.imageUrl || ShowPlaceholderImage} />
            </ShowCoverWrapper>
            <Right>
                <TextWrapper>
                    <ShowName>{show.name}</ShowName>
                    <ShowStatus>{status[show.showStatus]}</ShowStatus>
                </TextWrapper>

                <DownIcon icon={icon({ name: 'chevron-down', style: 'solid' })} size="xs" />
            </Right>
        </ShowMenu>
    );
};

const ShowMenu = styled(DeprecatedPaper)`
    display: flex;
    align-items: center;
    align-self: stretch;
    z-index: 1;
    max-width: 280px;
    cursor: pointer;
    width: initial;

    &:hover {
        background: var(--neutral50);
    }
`;
const ShowCoverWrapper = styled.div`
    align-self: stretch;
    padding-inline: 1rem;
    padding-block: 0.5rem;
    flex-shrink: 0;
`;
const ShowCover = styled.img`
    height: 100%;
    width: auto;
    max-width: 3rem;
    aspect-ratio: 1/1;
    border-radius: var(--r-m);
`;
const Right = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    max-width: 200px;
    padding-right: 0.5rem;
    ${({ theme }) => theme.mediaQueries.mobile} {
        display: none;
    }
`;
const TextWrapper = styled.div`
    & > * {
        font-size: var(--fs-body-m);
        font-weight: var(--fw-semibold);
    }
`;
const ShowName = styled.div`
    color: var(--black);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: calc(200px - 2rem);
`;
const ShowStatus = styled.div`
    color: var(--neutral500);
`;
const DownIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
`;

ImgMenuDropdown.propTypes = {
    show: PropTypes.object.isRequired,
};

export default ImgMenuDropdown;
