import Stack from '@/components/ui/layout/Stack';
import { useFormContext } from 'react-hook-form';
import CardSelect from '@/components/ui/atoms/CardSelect';
import { FormattedMessage, useIntl } from 'react-intl';
import automaticIcon from '@public/icons/smartplayer-format-auto.svg';
import horizontalIcon from '@public/icons/smartplayer-format-horizontal.svg';
import verticalIcon from '@public/icons/smartplayer-format-vertical.svg';
import Text from '@/components/ui/atoms/Text';
import styled from 'styled-components';
import { CardSelectListController } from '@/components/ui/molecules/CardSelectList';
import { InputTextController } from '@/components/ui/atoms/InputText';
import Cluster from '@/components/ui/layout/Cluster';
import { PlayerFormSchema } from '../../usePlayerFormSchema.hook';
import LightTheme from '@public/icons/smartplayer-theme-light.svg';
import DarkTheme from '@public/icons/smartplayer-theme-dark.svg';
import Label from '@/components/ui/atoms/Label';
import { SelectController } from '@/components/ui/atoms/Select';
import ListBoxItem from '@/components/ui/atoms/ListBoxItem';
import Icon from '@/components/ui/atoms/Icon';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';
import { TooltipTrigger } from 'react-aria-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PlayerFormColor from './PlayerFormColor';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

interface PlayerFormStyleProps {
    noPlaylists: boolean;
}

const PlayerFormStyle = ({ noPlaylists }: PlayerFormStyleProps) => {
    const intl = useIntl();
    const { control, watch, setValue } = useFormContext<PlayerFormSchema>();

    const format = watch('format');
    const sizeUnit = watch('sizeUnit');

    const sizeUnitItems = [
        { id: 'pixel', name: intl.formatMessage({ defaultMessage: 'Pixels (px)' }) },
        { id: 'percent', name: intl.formatMessage({ defaultMessage: 'Pourcentage (%)' }) },
    ];

    return (
        <Stack $gap="2rem">
            <Stack $gap="0.5rem">
                <Cluster $gap="0.25rem" $align="center">
                    <Text fontWeight="--fw-semibold">
                        <FormattedMessage defaultMessage="Mode d'affichage" />
                    </Text>
                    <TooltipTrigger delay={0} closeDelay={0}>
                        <TooltipTriggerWrapper>
                            <TooltipIcon icon={icon({ name: 'circle-info', style: 'solid' })} />
                        </TooltipTriggerWrapper>
                        <Tooltip placement="top">
                            <Text fontWeight="--fw-semibold">
                                <FormattedMessage defaultMessage="En choisissant l’affichage automatique, le player adaptera sa largeur automatiquement pour basculer entre le format vertical et horizontal selon la taille de l’écran." />
                            </Text>
                        </Tooltip>
                    </TooltipTrigger>
                </Cluster>
                <SelectFormatOption
                    name="format"
                    control={control}
                    onChange={(value: PlayerFormSchema['format']) => {
                        if (value !== 'auto') return;
                        setValue('width', 100);
                        setValue('sizeUnit', 'percent');
                    }}
                    isDisabled={noPlaylists}
                >
                    {/* @ts-ignore */}
                    <CardSelect
                        value="auto"
                        title={<FormattedMessage defaultMessage="Automatique" />}
                        // @ts-ignore
                        icon={<Icon as={automaticIcon} size="1.5rem" />}
                    />
                    {/* @ts-ignore */}
                    <CardSelect
                        value="horizontal"
                        title={<FormattedMessage defaultMessage="Horizontal" />}
                        // @ts-ignore
                        icon={<Icon as={horizontalIcon} size="1.5rem" />}
                    />
                    {/* @ts-ignore */}
                    <CardSelect
                        value="vertical"
                        title={<FormattedMessage defaultMessage="Vertical" />}
                        // @ts-ignore
                        icon={<Icon as={verticalIcon} size="1.5rem" />}
                    />
                </SelectFormatOption>
            </Stack>
            {format !== 'auto' && (
                <>
                    <Stack $gap="0.5rem">
                        {/* @ts-ignore */}
                        <Label>
                            <FormattedMessage defaultMessage="Largeur du player" />
                        </Label>
                        <Cluster $gap="0.5rem" $align="start" $wrap="nowrap">
                            <InputTextController
                                control={control}
                                name="width"
                                type="number"
                                min={sizeUnit === 'pixel' ? 250 : 0}
                                max={sizeUnit === 'pixel' ? undefined : 100}
                                isDisabled={noPlaylists}
                            />
                            <SelectSizeUnit
                                name="sizeUnit"
                                control={control}
                                isDisabled={noPlaylists}
                                onSelectionChange={(id) => {
                                    if (id === 'pixel') {
                                        setValue('width', 600);
                                        return;
                                    }
                                    setValue('width', 100);
                                }}
                                items={sizeUnitItems}
                            >
                                {(item) => <ListBoxItem {...item} />}
                            </SelectSizeUnit>
                        </Cluster>
                        <Text color="--neutral500">
                            {sizeUnit === 'pixel' ? (
                                <FormattedMessage defaultMessage="Largeur minimum : 250px." />
                            ) : (
                                <FormattedMessage defaultMessage="Largeur maximum : 100%." />
                            )}
                        </Text>
                    </Stack>
                </>
            )}
            <Stack $gap="1rem">
                <Stack $gap="0.5rem">
                    {/* @ts-ignore */}
                    <Label>
                        <FormattedMessage defaultMessage="Thème" />
                    </Label>
                    <CardSelectThemeList
                        name="darkMode"
                        control={control}
                        isDisabled={noPlaylists}
                        transform={(value: string) => (value === 'dark' ? true : false)} // cast to boolean to match player settings darkMode type
                    >
                        {/* @ts-ignore */}
                        <CardSelect
                            value="light"
                            title={<FormattedMessage defaultMessage="Clair" />}
                            icon={<LightThemeIcon />}
                            variant="small"
                        />
                        {/* @ts-ignore */}
                        <CardSelect
                            value="dark"
                            title={<FormattedMessage defaultMessage="Sombre" />}
                            icon={<DarkThemeIcon />}
                            variant="small"
                        />
                    </CardSelectThemeList>
                </Stack>
            </Stack>
            <PlayerFormColor isDisabled={noPlaylists} />
        </Stack>
    );
};

const SelectFormatOption = styled(CardSelectListController)`
    column-gap: 0.75rem;
`;
const CardSelectThemeList = styled(CardSelectListController)`
    column-gap: 0.75rem;
`;
const LightThemeIcon = styled(LightTheme)`
    width: 1.5rem;
    height: 1.5rem;
`;
const DarkThemeIcon = styled(DarkTheme)`
    width: 1.5rem;
    height: 1.5rem;
`;
const SelectSizeUnit = styled(SelectController)`
    max-width: 10rem;
`;
const TooltipIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: var(--neutral500);
`;

export default PlayerFormStyle;
