import Text from '@/components/ui/atoms/Text';
import { EPISODE_STATUS } from '@/shared/config/constants';
import { FormattedDate, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { useEpisodeBeingEdited } from '@/context/EpisodeFormContext';
import { useFormContext } from 'react-hook-form';

const EpisodeResume = () => {
    const { watch } = useFormContext();
    const episodeBeingEdited = useEpisodeBeingEdited();
    const episodeName = watch('content.name');
    const isOnline = episodeBeingEdited?.state === EPISODE_STATUS.ONLINE;

    return (
        <Wrapper>
            <Text variant="bodyL" fontWeight="--fw-bold">
                {episodeName || episodeBeingEdited?.name}
            </Text>
            {isOnline && episodeBeingEdited?.publishedAt && (
                <Text variant="bodyM" color="--neutral500">
                    <FormattedMessage
                        defaultMessage="Publié le {date}"
                        values={{
                            date: <FormattedDate value={episodeBeingEdited?.publishedAt} />,
                        }}
                    />
                </Text>
            )}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;
`;

export default EpisodeResume;
