import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { InputRichTextController } from '@ui/atoms/InputRichText';
import Stack from '@/components/ui/layout/Stack';
import { useAccordionItem } from '@/components/ui/atoms/AccordionItem/useAccordionItem.hook';
import {
    EPISODE_AI_LOADING_ACTIONS,
    useEpisodeAILoading,
} from '../contexts/NonHostingEpisodeAILoadingContext';
import { NonHostingEpisodeFormSchema } from '../useNonHostingEpisodeFormSchema.hook';
import EpisodeAINewsletterMessageSuggestionPanel from '../NonHostingEpisodeAI/EpisodeAINewsletterMessageSuggestionPanel';

const NewsletterMessageField = () => {
    const { control, setValue } = useFormContext<NonHostingEpisodeFormSchema>();
    const intl = useIntl();
    const [loadingContent, setLoadingContent] = useState('');
    const { isExpanded } = useAccordionItem();
    const [state, dispatch] = useEpisodeAILoading();
    const isLoading = state.get('newsletter-message') || false;

    // Stop wysiwyg loading animation when the accordion is closed
    useEffect(() => {
        if (!isExpanded) {
            handleStopLoading();
        }
    }, [isExpanded]);

    const handleStopLoading = () => {
        dispatch({
            type: EPISODE_AI_LOADING_ACTIONS.STOP,
            payload: 'newsletter-message',
        });
        setLoadingContent('');
    };

    return (
        <Stack>
            <InputRichTextController
                name="newsletter.message"
                control={control}
                label={<FormattedMessage defaultMessage="Contenu de la newsletter" />}
                charactersLimit={4000}
                placeholder={intl.formatMessage({
                    defaultMessage: 'Saisissez le contenu de votre newsletter',
                })}
                data-testid="new-episode-newsletter-message-input"
                isLoading={isLoading}
                onLoadingEnd={handleStopLoading}
                loadingContent={loadingContent}
                onChangePlainText={(value: string) => {
                    setValue('_meta.plainTextNewsletterMessage', value);
                }}
            />
            <EpisodeAINewsletterMessageSuggestionPanel setLoadingContent={setLoadingContent} />
        </Stack>
    );
};

export default NewsletterMessageField;
