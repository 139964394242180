import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchEpisodesRankingFromCampaign } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import statsKeys from './statsKeys';
import { z } from 'zod';

export const ListenersEpisodesRankingSchema = z.object({
    downloads: z.number(),
    name: z.string(),
    podcastId: z.number(),
    averageListeningTime: z.string(),
    averageCompletionRate: z.string(),
});
export const ListenersEpisodesRankingsSchema = z.array(ListenersEpisodesRankingSchema);
export type ListenersEpisodesRanking = z.infer<typeof ListenersEpisodesRankingSchema>;
const ListenersEpisodesRankingPaginationSchema = z.object({
    currentPage: z.number(),
    totalPages: z.number(),
    totalItems: z.number().optional(),
    itemsPerPage: z.number().optional(),
});
const ListenersEpisodesRankingResponseSchema = z.object({
    data: ListenersEpisodesRankingsSchema,
    pagination: ListenersEpisodesRankingPaginationSchema,
});
type Key = QueryFunctionContext<ReturnType<typeof statsKeys.campaignListenersEpisodesRanking>>;
type InputProps = {
    campaignId: string;
    start: string;
    end: string;
    order: 'asc' | 'desc';
    page: number;
    perPage: number;
    episodes: string[];
    timezone: string;
    options?: {
        enabled?: boolean;
    };
};

const queryFn = async ({
    queryKey: [{ campaignId, start, end, order, page, perPage, episodes, timezone }],
}: Key) => {
    const response = await fetchEpisodesRankingFromCampaign(campaignId, {
        start,
        end,
        order,
        page,
        perPage,
        podcasts: episodes,
        timezone,
    });
    const fullData = response.data.map((item: any) => {
        return {
            ...item,
            averageListeningTime: item.averageListeningTime || '-',
            averageCompletionRate: item.averageCompletionRate || '-',
        };
    });
    return ListenersEpisodesRankingResponseSchema.parse(
        camelcaseKeys({ data: fullData, pagination: response.pagination }, { deep: true }),
    );
};

const useCampaignListenersEpisodesRankingQuery = ({
    campaignId,
    start,
    end,
    order,
    page,
    perPage,
    episodes,
    timezone,
    options,
}: InputProps) => {
    return useQuery({
        queryKey: statsKeys.campaignListenersEpisodesRanking(
            campaignId,
            start,
            end,
            order,
            page,
            perPage,
            episodes,
            timezone,
        ),
        queryFn,
        enabled: options?.enabled || true,
    });
};

export default useCampaignListenersEpisodesRankingQuery;
