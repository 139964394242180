import { Children, cloneElement, useState } from 'react';
import styled from 'styled-components';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { defineMessages, FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { connect } from '@app/decorators/connect';
import AudioSampleSelection from './AudioSampleSelection';
import TranscriptionSelection from './TranscriptionSelection';
import Customization from './Customization';
import FormatSelection from './FormatSelection';
import Transcription from './Transcription';
import Stepper from '@ui/organisms/Stepper';
import Step from '@ui/molecules/Step';
import StepLabel from '@ui/atoms/StepLabel';
import FetcherClipTranscriptionSupportedLanguages from '@app/fetchers/FetcherClipTranscriptionSupportedLanguages';
import ClipEditWizardFooter from './ClipEditWizardFooter';
import Text from '@ui/atoms/Text';
import Cluster from '@ui/layout/Cluster';
import { useOverlayTriggerState } from 'react-stately';
import ClipTitleEditModal from '@app/molecules/ClipTitleEditModal';
import IconEdit from '@ui/icons/IconEdit';
import Stack from '@ui/layout/Stack';

export const STEP_AUDIO_SAMPLE_SELECTION_LABEL = 'audio_sample_selection';
export const STEP_TRANSCRIPTION_SELECTION_LABEL = 'transcription_selection';
export const STEP_TRANSCRIPTION_LABEL = 'transcription';
export const STEP_FORMAT_SELECTION_LABEL = 'format_selection';
export const STEP_CUSTOMIZATION_LABEL = 'customization';

const VISUAL_STEPS_MESSAGES = defineMessages({
    [STEP_AUDIO_SAMPLE_SELECTION_LABEL]: { defaultMessage: 'Sélection de l’extrait' },
    with_transcription: { defaultMessage: 'Transcription' },
    without_transcription: { defaultMessage: 'Pas de transcription' },
    [STEP_FORMAT_SELECTION_LABEL]: { defaultMessage: 'Format' },
    [STEP_CUSTOMIZATION_LABEL]: { defaultMessage: 'Personnalisation' },
});

const getVisualSteps = (values, intl) => [
    intl.formatMessage(VISUAL_STEPS_MESSAGES[STEP_AUDIO_SAMPLE_SELECTION_LABEL]),
    values.transcript
        ? intl.formatMessage(VISUAL_STEPS_MESSAGES.with_transcription)
        : intl.formatMessage(VISUAL_STEPS_MESSAGES.without_transcription),
    intl.formatMessage(VISUAL_STEPS_MESSAGES[STEP_FORMAT_SELECTION_LABEL]),
    intl.formatMessage(VISUAL_STEPS_MESSAGES[STEP_CUSTOMIZATION_LABEL]),
];

export const ClipEditTitle = ({ clip }) => {
    const intl = useIntl();
    const defaultClipTitle = intl.formatMessage(
        { defaultMessage: 'Clip du {date} à {time}' },
        {
            date: intl.formatDate(new Date(), {
                day: '2-digit',
                month: '2-digit',
            }),
            time: intl.formatTime(new Date()),
        },
    );
    let clipEditTitleModalState = useOverlayTriggerState({});

    !clip.adminTitle && clip.setAdminTitle(defaultClipTitle);

    const onEditTitle = (title) => {
        clip.setAdminTitle(title);
        clipEditTitleModalState.close();
    };

    return (
        <>
            <Cluster $align="center" $gap="0.625rem">
                <Text variant="heading" fontWeight="--fw-bold">
                    {clip?.adminTitle}
                </Text>
                <IconEdit cursor="pointer" size="0.75rem" onClick={clipEditTitleModalState.open} />
            </Cluster>
            {clipEditTitleModalState.isOpen && (
                <ClipTitleEditModal
                    isOpen={clipEditTitleModalState.isOpen}
                    onClose={() => clipEditTitleModalState.close()}
                    value={clip?.adminTitle}
                    onEdit={onEditTitle}
                />
            )}
        </>
    );
};

const Header = injectIntl(({ currentStep, formik, intl }) => {
    const visualSteps = getVisualSteps(formik.values, intl);

    return (
        <HeaderContent>
            <Stepper activeStep={currentStep.props.visualStepIndex}>
                {visualSteps.map((step) => (
                    <Step key={step}>
                        <StepLabel>{step}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </HeaderContent>
    );
});

const Wizard = ({ children, initialValues, onSubmit, formRef, onClose }) => {
    const [currentStepLabel, setCurrentStepLabel] = useState(
        initialValues.step || STEP_AUDIO_SAMPLE_SELECTION_LABEL,
    );

    const steps = Children.toArray(children);
    const currentStep = steps.find((step) => step.props.label === currentStepLabel) || steps[0];
    const isLastStep = currentStepLabel === STEP_CUSTOMIZATION_LABEL;

    const next = (values) => {
        if (currentStep.props.nextStep) {
            if (typeof currentStep.props.nextStep === 'function') {
                setCurrentStepLabel(currentStep.props.nextStep(values));
            } else {
                setCurrentStepLabel(currentStep.props.nextStep);
            }
        }
    };

    const previous = (values) => {
        if (currentStep.props.previousStep) {
            if (typeof currentStep.props.previousStep === 'function') {
                setCurrentStepLabel(currentStep.props.previousStep(values));
            } else {
                setCurrentStepLabel(currentStep.props.previousStep);
            }
        }
    };

    const handleContinueLater = async (formik) => {
        if (formik.isSubmitting || !formik.isValid || currentStep.props.onSubmit) {
            let values = {};
            for (const value in formik.values) {
                if (currentStep.props.fields.includes(value)) {
                    values = { [value]: formik.values[value], ...values };
                }
            }
            await currentStep.props.onSubmit({ ...values, step: currentStepLabel });
            next(formik.values);
            onClose();
        }
    };

    const handleSubmit = async (values, bag) => {
        if (currentStep.props.onSubmit) {
            await currentStep.props.onSubmit(
                {
                    ...values,
                    // Add current step to form data
                    step: currentStepLabel,
                },
                bag,
            );
        }
        if (isLastStep) {
            return onSubmit(values, bag);
        }

        bag.setErrors({});
        bag.setTouched({});
        next(values);
    };

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={currentStep.props.validationSchema}
            innerRef={formRef}
        >
            {(formik) => (
                <Form style={{ height: '100%' }}>
                    <ModalContent $gap="2.5rem">
                        <Header currentStep={currentStep} formik={formik} />
                        <Content>{currentStep}</Content>
                        <ClipEditWizardFooter
                            currentStep={currentStep}
                            formik={formik}
                            previous={previous}
                            handleContinueLater={handleContinueLater}
                        />
                    </ModalContent>
                </Form>
            )}
        </Formik>
    );
};

const WizardStep = ({ children, className }) => cloneElement(children, { className });

const enhance = connect(({ clipStore }) => {
    const transcription = clipStore.transcription;
    const updateTranscription = clipStore.updateTranscription;
    return { transcription, updateTranscription };
});

const ClipEditWizard = ({
    episode,
    clip,
    transcription,
    updateTranscription,
    onClose,
    onClipGenerate,
    formRef,
}) => {
    const initialValues = {
        ...clip.apiObject,
        transcription: transcription
            ? {
                  ...transcription.apiObject,
                  subtitles: transcription.subtitles.map((subtitle) => subtitle.apiObject),
              }
            : null,
    };

    return (
        <FetcherClipTranscriptionSupportedLanguages>
            {() => (
                <Wizard
                    initialValues={initialValues}
                    onSubmit={async () => {
                        await clip.generate();
                        onClose();
                        onClipGenerate();
                    }}
                    formRef={formRef}
                    onClose={onClose}
                >
                    <WizardStep
                        label={STEP_AUDIO_SAMPLE_SELECTION_LABEL}
                        previousLabel={null}
                        nextStep={STEP_TRANSCRIPTION_SELECTION_LABEL}
                        nextLabel={<FormattedMessage defaultMessage="Suivant" />}
                        visualStepIndex={0}
                        fields={['start', 'end', 'admin_title']}
                        onSubmit={({ step, start, end, admin_title }) =>
                            clip.updateOrCreate({ step, start, end, admin_title })
                        }
                        validationSchema={Yup.object({
                            start: Yup.number().required().min(0).max(episode.duration),
                            end: Yup.number()
                                .required()
                                .min(0)
                                .max(episode.duration + 0.01) // API takes only two decimales instead of three so we need to put the max higher than the episode duration
                                .moreThan(Yup.ref('start')),
                        })}
                    >
                        <AudioSampleSelection episode={episode} />
                    </WizardStep>
                    <WizardStep
                        label={STEP_TRANSCRIPTION_SELECTION_LABEL}
                        previousStep={STEP_AUDIO_SAMPLE_SELECTION_LABEL}
                        previousLabel={<FormattedMessage defaultMessage="Précédent" />}
                        nextStep={(values) =>
                            values.transcript
                                ? STEP_TRANSCRIPTION_LABEL
                                : STEP_FORMAT_SELECTION_LABEL
                        }
                        nextLabel={<FormattedMessage defaultMessage="Suivant" />}
                        visualStepIndex={1}
                        fields={['transcript', 'lang', 'admin_title']}
                        onSubmit={({ step, transcript, lang, admin_title }) =>
                            clip.updateOrCreate({ step, transcript, lang, admin_title })
                        }
                        validationSchema={Yup.object({
                            transcript: Yup.boolean().required(),
                            lang: Yup.string().required(),
                        })}
                    >
                        <TranscriptionSelection />
                    </WizardStep>
                    <WizardStep
                        label={STEP_TRANSCRIPTION_LABEL}
                        previousStep={STEP_TRANSCRIPTION_SELECTION_LABEL}
                        previousLabel={<FormattedMessage defaultMessage="Précédent" />}
                        nextStep={STEP_FORMAT_SELECTION_LABEL}
                        nextLabel={<FormattedMessage defaultMessage="Valider la transcription" />}
                        visualStepIndex={1}
                        fields={['transcription', 'admin_title']}
                        onSubmit={async ({ step, transcription, admin_title }) => {
                            // In this case, order is important.
                            // Transcription is updated then form reinitialize with response from API.
                            // /!\ Due to reinitialization, any change on clip is lost. /!\
                            // I don't care right now because clip is not updated during this step, but you need to find another way
                            // to save data if you have this new requirement (maybe with only one MobX action to prevent form reinitialization
                            await updateTranscription(clip.id, transcription);
                            await clip.updateOrCreate({ step, admin_title });
                        }}
                    >
                        <Transcription episode={episode} />
                    </WizardStep>
                    <WizardStep
                        label={STEP_FORMAT_SELECTION_LABEL}
                        previousStep={(values) =>
                            values.transcript
                                ? STEP_TRANSCRIPTION_LABEL
                                : STEP_TRANSCRIPTION_SELECTION_LABEL
                        }
                        previousLabel={<FormattedMessage defaultMessage="Précédent" />}
                        nextStep={STEP_CUSTOMIZATION_LABEL}
                        nextLabel={<FormattedMessage defaultMessage="Suivant" />}
                        visualStepIndex={2}
                        fields={['formats', 'admin_title']}
                        onSubmit={async ({ step, formats, admin_title }) => {
                            // If not modified, formats contains clip formats objects, not accepted by PUT request
                            // Array must be transformed to only keep formats strings
                            const computedFormats = formats.map((format) =>
                                format.format ? format.format : format,
                            );
                            await clip.updateOrCreate({
                                step,
                                formats: computedFormats,
                                admin_title,
                            });
                            // Awful hack used to trigger a reset of Formik form and restore values.formats with an array
                            // of clip format objects, not just strings used to choose formats
                            // Changing clip duration is """safe""" (but awful) because duration is not passed to API calls afterwards
                            // Duration update will trigger a new compute of clip.apiObject, used to generate initialValues passed to Formik
                            // If initialValues changes, Formik reset form due to enableReinitialize prop
                            clip.duration += 0.001;
                        }}
                        validationSchema={Yup.object({
                            formats: Yup.array().required().min(1),
                        })}
                    >
                        <FormatSelection />
                    </WizardStep>
                    <WizardStep
                        label={STEP_CUSTOMIZATION_LABEL}
                        previousStep={STEP_FORMAT_SELECTION_LABEL}
                        previousLabel={<FormattedMessage defaultMessage="Précédent" />}
                        nextLabel={<FormattedMessage defaultMessage="Générer le clip" />}
                        visualStepIndex={3}
                        fields={[
                            'title',
                            'caption',
                            'color',
                            'title_color',
                            'show_titles',
                            'show_waveform',
                            'admin_title',
                        ]}
                        onSubmit={({
                            step,
                            admin_title,
                            title,
                            caption,
                            color,
                            title_color,
                            show_titles,
                            show_waveform,
                        }) =>
                            clip.updateOrCreate({
                                step,
                                admin_title,
                                title,
                                caption,
                                color,
                                title_color,
                                show_titles,
                                show_waveform,
                            })
                        }
                        validationSchema={Yup.object({
                            title: Yup.string().nullable(),
                            caption: Yup.string().nullable(),
                            color: Yup.string().required(),
                            title_color: Yup.string().required(),
                            show_titles: Yup.boolean().required(),
                            show_waveform: Yup.boolean().required(),
                        })}
                    >
                        <Customization episode={episode} />
                    </WizardStep>
                </Wizard>
            )}
        </FetcherClipTranscriptionSupportedLanguages>
    );
};

const ModalContent = styled(Stack)`
    height: 100%;
`;

const HeaderContent = styled.div`
    width: 45rem;
    margin: auto;
`;

const Content = styled.div`
    margin: 0 140px;
    height: 100%;
    flex-grow: 1;
`;

export default enhance(ClipEditWizard);
