import RouterLink from '@/components/Link/RouterLink';
import Sidebar from '@/shared/components/Sidebar';
import SidebarItem from '@/shared/components/Sidebar/Item/Item';
import SidebarSection from '@/shared/components/Sidebar/Section/Section';
import SectionTitle from '@/shared/components/Sidebar/Section/Title/Title';
import { FormattedMessage } from 'react-intl';
import { useLocation, useParams } from 'react-router';
import styled from 'styled-components';
import { PRICING } from '@/shared/config/pricing';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useGuard } from '@/shared/hooks/useGuard.hook';
import useShowQuery from '@/queries/show/useShowQuery.hook';

const Nav = () => {
    const { showId } = useParams<{ showId: string }>();
    const location = useLocation();
    const currentSegment = location.pathname.split('/').pop();
    const basePath = `/app/show/${showId}/communication`;
    const { data: show } = useShowQuery(showId);

    const pricingGuard = useGuard({
        disallowedPlans: [PRICING.FREEMIUM, PRICING.LAUNCH, PRICING.BOOST],
    });

    return (
        <Sidebar>
            <SidebarSection>
                <SectionTitle>
                    <FormattedMessage defaultMessage="Général" />
                </SectionTitle>
                <SidebarItem
                    isActive={currentSegment === 'social-media'}
                    isDisabled={show?.archived}
                >
                    <NavItemIcon icon={icon({ name: 'message-heart', style: 'solid' })} />
                    <NavRouterLink to={`${basePath}/social-media`}>
                        <FormattedMessage
                            id="communication.socialMedia"
                            defaultMessage="Réseaux sociaux"
                        />
                    </NavRouterLink>
                </SidebarItem>
                <SidebarItem
                    isActive={location.pathname.includes('/clip')}
                    isDisabled={show?.archived}
                >
                    <NavItemIcon icon={icon({ name: 'clapperboard', style: 'solid' })} />
                    <NavRouterLink to={`${basePath}/clip`}>
                        <FormattedMessage defaultMessage="Clips vidéo" />
                    </NavRouterLink>
                </SidebarItem>
                <SidebarItem isActive={currentSegment === 'player'}>
                    <NavItemIcon icon={icon({ name: 'waveform-lines', style: 'solid' })} />
                    <NavRouterLink to={`${basePath}/player`}>
                        <FormattedMessage defaultMessage="Smartplayer" />
                    </NavRouterLink>
                </SidebarItem>
                <SidebarItem isActive={currentSegment === 'page'} isDisabled={show?.archived}>
                    <NavItemIcon icon={icon({ name: 'globe', style: 'regular' })} />
                    <NavRouterLink to={`${basePath}/page`}>
                        <FormattedMessage defaultMessage="Site Web" />
                    </NavRouterLink>
                </SidebarItem>
                <SidebarItem isActive={currentSegment === 'smartlink'} isDisabled={show?.archived}>
                    <NavItemIcon icon={icon({ name: 'link', style: 'solid' })} />
                    <NavRouterLink to={`${basePath}/smartlink`}>
                        <FormattedMessage defaultMessage="Smartlink" />
                    </NavRouterLink>
                </SidebarItem>
                <SidebarItem isActive={currentSegment === 'newsletter'} isDisabled={show?.archived}>
                    <NavItemIcon icon={icon({ name: 'envelope-open-text', style: 'solid' })} />
                    <NavRouterLink to={`${basePath}/newsletter`}>
                        <FormattedMessage defaultMessage="Newsletter" />
                    </NavRouterLink>
                </SidebarItem>
            </SidebarSection>
            <SidebarSection>
                <SectionTitle>
                    <FormattedMessage defaultMessage="Avancés" />
                </SectionTitle>
                <SidebarItem isActive={currentSegment === 'accounts'} isDisabled={show?.archived}>
                    <NavItemIcon icon={icon({ name: 'share-nodes', style: 'solid' })} />
                    <NavRouterLink to={`${basePath}/settings/accounts`}>
                        <FormattedMessage defaultMessage="Comptes liés" />
                    </NavRouterLink>
                </SidebarItem>
                <SidebarItem
                    isActive={currentSegment === 'external-links'}
                    isDisabled={show?.archived}
                >
                    <NavItemIcon icon={icon({ name: 'at', style: 'solid' })} />
                    <NavRouterLink to={`${basePath}/settings/external-links`}>
                        <FormattedMessage defaultMessage="Profils & Liens" />
                    </NavRouterLink>
                </SidebarItem>
                <SidebarItem
                    isActive={currentSegment === 'call-to-action'}
                    isDisabled={show?.archived}
                >
                    <NavItemIcon icon={icon({ name: 'bullseye-pointer', style: 'solid' })} />
                    <NavRouterLink to={`${basePath}/settings/call-to-action`}>
                        <FormattedMessage
                            id="communication.callToAction"
                            defaultMessage="Boutons personnalisés"
                        />
                    </NavRouterLink>
                    {!pricingGuard.allow && (
                        <DiamondIcon icon={icon({ name: 'gem', style: 'duotone' })} />
                    )}
                </SidebarItem>
                <SidebarItem isActive={currentSegment === 'tracker'} isDisabled={show?.archived}>
                    <NavItemIcon icon={icon({ name: 'brackets-curly', style: 'solid' })} />
                    <NavRouterLink to={`${basePath}/settings/tracker`}>
                        <FormattedMessage defaultMessage="Outils de suivi" />
                    </NavRouterLink>
                </SidebarItem>
                <SidebarItem isActive={currentSegment === 'webhook'} isDisabled={show?.archived}>
                    <NavItemIcon icon={icon({ name: 'webhook', style: 'solid' })} />
                    <NavRouterLink to={`${basePath}/settings/webhook`}>
                        <FormattedMessage defaultMessage="Webhooks" />
                    </NavRouterLink>
                    {!pricingGuard.allow && (
                        <DiamondIcon icon={icon({ name: 'gem', style: 'duotone' })} />
                    )}
                </SidebarItem>
            </SidebarSection>
        </Sidebar>
    );
};

const NavRouterLink = styled(RouterLink)`
    color: inherit;
`;
const NavItemIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
`;
const DiamondIcon = styled(NavItemIcon)`
    color: var(--primary);
`;

export default Nav;
