import { useParams } from 'react-router';
import useQuery from '@/shared/hooks/useQuery';
import type { Platform, Mode } from '@/api/pso/types';
import Header from './Header';
import Form from './Form';
import Results from './Results';
import Kpis from './Kpis';
import { useAmplitudeLogEvent } from '@/shared/hooks/useAmplitude';
import EmptyState from '../EmptyState';
import styled from 'styled-components';
import useHasAccessToPsoLiveSearch from '@/shared/hooks/useHasAccessToPsoLiveSearch.hook';

const PsoLiveSearch = () => {
    useAmplitudeLogEvent('PSO Live Search Viewed');

    const { showId }: { showId: string } = useParams();
    const query = useQuery();
    const keyword: string = query.get('keyword') || '';
    const platform: Platform = query.get('platform') as Platform;
    const store: string = query.get('store') || '';
    const mode: Mode = query.get('mode') as Mode;

    const hasAccessToPsoLiveSearch = useHasAccessToPsoLiveSearch({ showId });

    if (!hasAccessToPsoLiveSearch) {
        return <EmptyState />;
    }

    return (
        <LiveSearchWrapper>
            <Header
                searchParams={{
                    keyword,
                    platform,
                    store,
                    mode,
                }}
            >
                <Form />
            </Header>

            {keyword && (
                <Kpis
                    searchParams={{
                        keyword,
                        platform,
                        store,
                        mode,
                    }}
                />
            )}
            <Results
                searchParams={{
                    keyword,
                    platform,
                    store,
                    mode,
                }}
            />
        </LiveSearchWrapper>
    );
};

const LiveSearchWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

export default PsoLiveSearch;
