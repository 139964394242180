import { useState } from 'react';
import Button from '@/components/Button';
import Text from '@/components/ui/atoms/Text';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import styled from 'styled-components';
import ReactivateHostingModal from './ReactivateHostingModal';

const Inactive = () => {
    const { showId } = useParams<{ showId: string }>();
    const { data: show } = useShowQuery(showId);
    const [isReactivateModalOpen, setIsReactivateModalOpen] = useState(false);

    if (!show) return null;

    const handleReactivate = () => {
        //TODO: handle reactivation
    };

    return (
        <>
            <HostedShow>
                <Show>
                    <CloudIconWrapper>
                        <CloudIcon icon={icon({ name: 'cloud-slash', style: 'solid' })} />
                    </CloudIconWrapper>
                    <Text fontWeight="--fw-semibold">
                        <FormattedMessage
                            defaultMessage="Votre émission « {showName} » n'est plus hébergée sur Ausha."
                            values={{
                                showName: show.name,
                            }}
                        />
                    </Text>
                </Show>
                <Button onPress={() => setIsReactivateModalOpen(true)} variant="secondary">
                    <FormattedMessage defaultMessage="Réactiver" />
                </Button>
            </HostedShow>
            <ReactivateHostingModal
                isOpen={isReactivateModalOpen}
                onOpenChange={setIsReactivateModalOpen}
                isLoading={false}
                onSubmit={handleReactivate}
            />
        </>
    );
};

const HostedShow = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem;
`;
const Show = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.75rem;
`;
const CloudIconWrapper = styled.div`
    color: var(--neutral500);
    background-color: var(--neutral50);
    border-radius: var(--r-s);
    width: 2.5rem;
    height: 2.5rem;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const CloudIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
`;

export default Inactive;
