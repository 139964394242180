import { z } from 'zod';

const useInstagramTemplateFormSchema = () => {
    return z.object({
        messageTpl: z.string(),
    });
};

export type InstagramTemplateFormSchema = z.infer<
    ReturnType<typeof useInstagramTemplateFormSchema>
>;

export default useInstagramTemplateFormSchema;
