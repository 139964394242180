import { createFetcher } from '../Fetcher';

export default createFetcher(
    (
        { listenersStatsStore },
        { showId, itemType, itemId, start, end, step, episodes, optionsType },
    ) => ({
        fetch: () =>
            listenersStatsStore.fetchStats(showId, itemType, itemId, optionsType, {
                start,
                end,
                step,
                podcasts: episodes,
            }),
        isLoading: listenersStatsStore.fetchStats.pending,
        input: [showId, itemType, itemId, start, end, step, episodes, optionsType],
    }),
);
