import { ListeningPlatform } from '@/shared/config/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import RouterLink from '@/components/Link/RouterLink';
import styled from 'styled-components';
import { useParams } from 'react-router';
import InputText from '@ui/atoms/InputText';
interface ConnectedProps {
    platformKey: ListeningPlatform;
    name: string;
    logo: string;
    url: string;
}

const Connected = ({ platformKey, name, logo, url }: ConnectedProps) => {
    const { showId } = useParams<{ showId: string }>();

    return (
        <CardWrapper $color={`--${platformKey}`}>
            <LogoWrapper>
                <Logo src={logo} />
            </LogoWrapper>
            <SettingsButton
                to={`/app/show/${showId}/settings/broadcast/platform/${platformKey}`}
                startIcon={<FontAwesomeIcon icon={icon({ name: 'gear', style: 'solid' })} />}
            />

            <ContentWrapper>
                <PlatformName>{name}</PlatformName>
                <UrlInputText value={url} isReadOnly copyToClipboardEnabled />
            </ContentWrapper>
        </CardWrapper>
    );
};

const CardWrapper = styled.div<{ $color: string }>`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    border-radius: var(--r-s);
    background: linear-gradient(to right top, hsla(0 0% 0% / 0.2), hsla(0 0% 100% / 0.3));
    background-color: ${({ $color }) => `var(${$color})`};
`;
const LogoWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-full);
    border: 1px solid var(--neutral50);
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -2.5rem;
`;
const Logo = styled.img`
    width: 1.5rem;
    height: 1.5rem;
`;
const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: 100%;
`;
const PlatformName = styled.span`
    font-weight: var(--fw-semibold);
    color: var(--white);
`;
const SettingsButton = styled(RouterLink)`
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    transition-duration: 0.25s;
    color: var(--white);
    padding: 0.5rem;

    &:hover {
        color: var(--white);
        background-color: var(--white50);
    }
`;
const UrlInputText = styled(InputText)`
    & input {
        padding-right: 3rem;
    }
`;

export default Connected;
