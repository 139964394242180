import { FormattedMessage } from 'react-intl';
import CongratulateEpisodeSummaryActive from './CongratulateEpisodeSummaryActive';
import CongratulateEpisodeSummaryScheduled from './CongratulateEpisodeSummaryScheduled';
import styled from 'styled-components';
import useEpisodeQuery from '@queries/episode/useEpisodeQuery.hook';
import { useParams } from 'react-router-dom';
import useInternalRouteMapping from '@/shared/hooks/useInternalRouteMapping.hook';
import { EPISODE_STATUS } from '@/shared/config/constants';
import RouterLink from '@/components/Link/RouterLink';

const CongratulateEpisodeSummaryWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
`;

function CongratulateEpisodeSummary() {
    const { episodeId } = useParams<{ episodeId: string }>();
    const { data: episode } = useEpisodeQuery({ episodeId });
    const { link } = useInternalRouteMapping();
    const isScheduled = episode?.state === EPISODE_STATUS.SCHEDULED;

    return (
        <CongratulateEpisodeSummaryWrapper>
            {isScheduled ? (
                <CongratulateEpisodeSummaryScheduled />
            ) : (
                <CongratulateEpisodeSummaryActive />
            )}
            <RouterLink variant="button-tertiary" to={link('menu.episodes')}>
                <FormattedMessage defaultMessage="Retour à la liste d’épisodes" />
            </RouterLink>
        </CongratulateEpisodeSummaryWrapper>
    );
}

export default CongratulateEpisodeSummary;
