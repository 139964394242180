import { useParams } from 'react-router';
import updateAushaProShowSettingsMutation from './updateAushaProShowSettingsMutation.hook';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import AushaProShowSettingsForm from './AushaProShowSettingsForm';
import { AushaProShowSettingsSchema } from './AushaProShowSettingsForm/useAushaProShowSettingsSchema.hook';
import ShortListFallback from '../unorganized/ShortListFallback';
import { useBodyToastQueue } from '@/shared/hooks/useBodyToastQueue.hook';

const AushaProShowSettings = () => {
    const { showId } = useParams<{ showId: string }>();
    const { data: show, isLoading } = useShowQuery(showId);
    const updateAushaProShowSettings = updateAushaProShowSettingsMutation();
    const toast = useBodyToastQueue();
    const defaultSettings = {
        darkLogo: show?.darkLogoUrl,
        lightLogo: show?.lightLogoUrl,
        favicon: show?.faviconUrl,
        whiteLabel: show?.whiteLabel,
        hasCustomLogo: !!show?.darkLogoUrl || !!show?.lightLogoUrl || !!show?.faviconUrl,
        logoLink: show?.logoLink || '',
    };

    const handleSubmit = ({ hasCustomLogo, ...data }: AushaProShowSettingsSchema) => {
        updateAushaProShowSettings.mutate(
            {
                showId,
                data,
            },
            {
                onSuccess: () => {
                    toast.success();
                },
                onError: () => {
                    toast.alert();
                },
            },
        );
    };

    if (isLoading) return <ShortListFallback />;

    return (
        <AushaProShowSettingsForm
            onSubmit={handleSubmit}
            isLoading={updateAushaProShowSettings.isLoading}
            defaultValues={defaultSettings}
        />
    );
};

export default AushaProShowSettings;
