import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const useRouteGuard = ({ shouldBlockNavigation, onOpenModal, onCloseModal }) => {
    const history = useHistory();
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);
    const [nextLocation, setNextLocation] = useState(null);

    const handleBlockedNavigation = (location) => {
        if (!confirmedNavigation && shouldBlockNavigation) {
            onOpenModal();
            setNextLocation(location);
            return false;
        }
        return true;
    };

    const handleConfirmNavigation = () => {
        onCloseModal();
        setConfirmedNavigation(true);
        if (!nextLocation) return;
        history.push(nextLocation.pathname);
    };

    useEffect(() => {
        if (!confirmedNavigation || !nextLocation) return;
        history.push(nextLocation.pathname);
    }, [confirmedNavigation, nextLocation, history]);

    return {
        handleConfirmNavigation,
        handleBlockedNavigation,
    };
};

export default useRouteGuard;
