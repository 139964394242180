import { useState } from 'react';
import { useParams } from 'react-router';
import { useIntl, FormattedMessage } from 'react-intl';
import { SearchField, Input, TooltipTrigger } from 'react-aria-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useEpisodeFiltersContext } from '@/context/EpisodeFiltersContext';
import { useDebounce } from '@/shared/hooks/useDebounceNew';
import styled from 'styled-components';
import RouterLink from '@/components/Link/RouterLink';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import { USER_ROLES } from '@/shared/config/constants';
import { sendAmplitudeLogEvent } from '@/helpers';

const PageHeader = () => {
    const intl = useIntl();
    const { showId } = useParams<{ showId: string }>();
    const showQuery = useShowQuery(showId);
    const userHasWriteAccess = showQuery.data?.userRole !== USER_ROLES.VIEWER;
    const { setSearch, search } = useEpisodeFiltersContext();
    const [searchValue, setSearchValue] = useState(search);

    const onChange = () => {
        setSearch(searchValue);
    };

    const debouncedOnChange = useDebounce(onChange);

    return (
        <EpisodesHeader>
            <StyledSearchField
                value={searchValue}
                onChange={(value: string) => {
                    debouncedOnChange();
                    setSearchValue(value);
                }}
            >
                <SearchIconWrapper>
                    <FontAwesomeIcon icon={icon({ name: 'magnifying-glass', style: 'solid' })} />
                </SearchIconWrapper>
                <StyledInput
                    placeholder={intl.formatMessage({
                        defaultMessage: 'Rechercher un épisode...',
                    })}
                />
            </StyledSearchField>
            <TooltipTrigger delay={0} closeDelay={0} isDisabled={userHasWriteAccess}>
                <TooltipTriggerWrapper>
                    <RouterLink
                        onClick={() => {
                            sendAmplitudeLogEvent('Work on New Episode');
                        }}
                        isDisabled={!userHasWriteAccess}
                        to={`/app/show/${showId}/workspace/episodes/upload`}
                        startIcon={<Icon icon={icon({ name: 'pen-line', style: 'solid' })} />}
                        variant="button-primary"
                    >
                        <FormattedMessage defaultMessage="Travailler sur un nouvel épisode" />
                    </RouterLink>
                </TooltipTriggerWrapper>
                <Tooltip placement="top">
                    <FormattedMessage defaultMessage="L'ajout ou la modification des épisodes n'est pas disponible en tant qu'invité Lecteur sur l'émission." />
                </Tooltip>
            </TooltipTrigger>
        </EpisodesHeader>
    );
};

const EpisodesHeader = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 0.5rem;
`;
const StyledSearchField = styled(SearchField)`
    flex-grow: 1;
    display: flex;
    align-items: center;
    background-color: var(--neutral100);
    border-radius: var(--r-s);
    padding-inline: 0.5rem;

    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        flex-grow: 0;
    }
`;
const StyledInput = styled(Input)`
    border: 0;
    height: 100%;
    padding-block: 0.75rem;
    background-color: transparent;
    min-width: 12rem;
`;
const SearchIconWrapper = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    padding-inline: 0.75rem;
    color: var(--neutral500);
`;
const Icon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;

export default PageHeader;
