import { FormattedMessage } from 'react-intl';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import ListenersStatsOveralls from '../ListenersStatsOveralls';

const ListenersStatsSetOfOveralls = () => (
    <>
        <DeprecatedPaper my={20}>
            <DeprecatedText weight="bold" size="veryBig" mb={5}>
                <FormattedMessage defaultMessage="Aperçu de vos écoutes" />
            </DeprecatedText>
            <DeprecatedText>
                <FormattedMessage defaultMessage="Voici une vue d’ensemble de votre émission." />
            </DeprecatedText>
        </DeprecatedPaper>
        <ListenersStatsOveralls />
    </>
);

export default ListenersStatsSetOfOveralls;
