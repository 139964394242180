import { useParams } from 'react-router';
import { FormattedMessage } from 'react-intl';
import copyIcon from '@public/icons/duplicate.svg';
import codeIcon from '@public/icons/code.svg';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import DeprecatedTextInput from '@ui/molecules/DeprecatedTextInput';
import DeprecatedButton from '@ui/atoms/DeprecatedButton';
import DeprecatedIcon from '@ui/atoms/DeprecatedIcon';
import { useCopy } from '@/shared/hooks/useCopy';
import useShowQuery from '@queries/show/useShowQuery.hook';
import styled from 'styled-components';
import { sendAmplitudeLogEvent } from '@/shared/utils/amplitude';
import Text from '../ui/atoms/Text';

const RssExportBlock = () => {
    const { showId } = useParams();
    const { data: show } = useShowQuery(showId);
    const [copied, copy] = useCopy();

    const handleCopyLink = () => {
        sendAmplitudeLogEvent('RSS Feed Export Clicked');
        copy(show?.rssLink || '');
    };

    if (!show) {
        return null;
    }

    return (
        <>
            <Text variant="headingM" fontWeight="--fw-bold">
                <FormattedMessage defaultMessage="Export RSS" />
            </Text>
            <DeprecatedText color="--neutral500" mb={20} maxW={500}>
                <FormattedMessage defaultMessage="Votre lien RSS est utile lorsque vous souhaitez publier votre émission sur d’autres plateformes." />
            </DeprecatedText>
            <RssExportWrapper>
                <DeprecatedTextInput
                    mb={15}
                    value={show?.rssLink || ''}
                    label={<FormattedMessage defaultMessage="Lien RSS de votre émission" />}
                />
                <ActionWrapper>
                    <DeprecatedButton
                        accent="roundedSmall"
                        onClick={handleCopyLink}
                        disabled={copied}
                        mr={10}
                    >
                        <DeprecatedIcon as={copyIcon} />
                        {copied ? (
                            <FormattedMessage defaultMessage="Lien copié" />
                        ) : (
                            <FormattedMessage defaultMessage="Copier le lien" />
                        )}
                    </DeprecatedButton>
                    <DeprecatedButton
                        accent="roundedSmall"
                        as="a"
                        href={show?.rssLink || ''}
                        target="_blank"
                    >
                        <DeprecatedIcon as={codeIcon} />
                        <FormattedMessage defaultMessage="Aperçu" />
                    </DeprecatedButton>
                </ActionWrapper>
            </RssExportWrapper>
        </>
    );
};

const RssExportWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding);

    ${({ theme }) => theme.mediaQueries.mobile} {
        padding: var(--container-padding-mobile);
    }
`;
const ActionWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export default RssExportBlock;
