import { FormattedMessage } from 'react-intl';
import DateRangeSelector from '@/components/unorganized/DateRangeSelector';
import styled from 'styled-components';

interface DownloadsComparisonHeaderProps {
    daysAfterPublication: 7 | 15 | 30 | 90;
    setDaysAfterPublication: (value: 7 | 15 | 30 | 90) => void;
    isActive: boolean;
}

const DownloadsComparisonHeader = ({
    daysAfterPublication,
    setDaysAfterPublication,
    isActive,
}: DownloadsComparisonHeaderProps) => (
    <DownloadsComparisonHeaderWrapper>
        <TitleAndDescription>
            <Title>
                <FormattedMessage defaultMessage="Comparaison de vos épisodes" />
            </Title>
            <Description>
                <FormattedMessage defaultMessage="Nombre d’écoutes par rapport au premier jour de publication, qui performe le mieux ?" />
            </Description>
        </TitleAndDescription>
        <SelectorWrapper>
            <DateRangeSelector
                daysRange={daysAfterPublication}
                setDaysRange={setDaysAfterPublication}
                hideDaysRange={['always']}
                active={isActive}
                firstDays
            />
        </SelectorWrapper>
    </DownloadsComparisonHeaderWrapper>
);

const DownloadsComparisonHeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    justify-content: space-between;

    @media (max-width: 800px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;
const TitleAndDescription = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    @media (max-width: 800px) {
        flex-basis: 100%;
        align-self: stretch;
    }
`;
const Title = styled.h2`
    font-weight: var(--fw-bold);
    font-size: var(--fs-heading-l);
`;
const Description = styled.p``;
/* This is ulgy as sin, but I don't have time to refactor the whole fucking DateRangeSelector.js for now, so it'll have to do. */
const SelectorWrapper = styled.div`
    flex-basis: 8.25rem;
    flex-shrink: 0;
    flex-grow: 0;

    @media (max-width: 800px) {
        flex-basis: 100%;
        flex-grow: 1;
        align-self: stretch;

        & > *,
        & > * > *,
        & button {
            width: 100%;
        }
    }
`;

export default DownloadsComparisonHeader;
