import { useState, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import CircularProgress from '@ui/atoms/CircularProgress';
import Button from '@ui/atoms/Button';
import Text from '@ui/atoms/Text';
import UiKitTooltip from '@ui/atoms/UiKitTooltip';
import Keyword from './Keyword';
import { resolveColor } from '@/utils/cssVariables';
import { useModalToastQueue } from '@/utils/hooks/useModalToastQueue.hook';
import styled from 'styled-components';

export interface KeywordInputProps {
    keywordMaxLength?: number;
    keywords?: string[];
    setKeywords?: (keywords: string[]) => void;
    onKeywordCreate?: (keywords: string[]) => void;
    onKeywordDelete?: (keyword: string) => void;
    onKeywordDeleteAll?: () => void;
    onSubmit?: (keywords: string[]) => void;
    submitIsLoading?: boolean;
    setSubmitIsLoading?: (isLoading: boolean) => void;
    keywordQuota?: {
        current: number;
        maximum: number;
        reached: boolean;
    };
    keywordsCount?: number;
    pristine?: boolean;
    isEditMode?: boolean;
}

const KeywordInput = ({
    keywordMaxLength = 100,
    keywords = [],
    setKeywords = () => {},
    onKeywordCreate = () => {},
    onKeywordDelete = () => {},
    onKeywordDeleteAll = () => {},
    onSubmit = () => {},
    submitIsLoading = false,
    setSubmitIsLoading = () => {},
    keywordQuota = { current: 0, maximum: 0, reached: true },
    keywordsCount = 0,
    pristine = true,
    isEditMode = false,
}: KeywordInputProps) => {
    const intl = useIntl();
    const toast = useModalToastQueue();
    const newKeywordInput = useRef<HTMLInputElement>(null);
    const [newKeyword, setNewKeyword] = useState('');
    const [showKeywordError, setShowKeywordError] = useState(false);

    const keywordsProgress = Math.round((keywordsCount / keywordQuota.maximum) * 100);
    const progressColor =
        keywordsProgress < 65 ? '--success' : keywordsProgress <= 85 ? '--warning' : '--alert';
    const progressBackgroundColor = `${progressColor}100`;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if ([','].includes(event.target.value)) return;
        setNewKeyword(event.target.value);
    };

    const resetInputState = () => {
        setNewKeyword('');
        if (newKeywordInput?.current?.value) newKeywordInput.current.value = '';
    };

    const handleKeydown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (!['Enter', ','].includes(event.key)) return;
        if (keywordsCount >= keywordQuota.maximum) return;
        event.preventDefault();
        // @ts-ignore
        const newKeyword = event.target.value.trim();
        if (newKeyword.length < 2 && isNaN(newKeyword)) return;
        const existingKeywordIndex = keywords.findIndex(
            (keyword) => keyword === newKeyword.toLowerCase(),
        );
        if (existingKeywordIndex > -1) {
            setKeywords([
                ...keywords.filter((k) => k !== newKeyword.toLowerCase()),
                newKeyword.toLowerCase(),
            ]);
            resetInputState();
            return;
        }
        onKeywordCreate([newKeyword.toLowerCase()]);
        resetInputState();
    };

    const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
        event.preventDefault();
        const remainingKeywords = keywordQuota.maximum - keywordsCount;
        const pastedString = event.clipboardData.getData('text');
        const pastedKeywords = pastedString
            .split(',')
            .map((keyword) => keyword.trim().toLowerCase())
            .filter((keyword) => keyword.length > 1 && keyword.length <= keywordMaxLength);
        const dedupedPastedKeywords = [...new Set(pastedKeywords)];

        onKeywordCreate(
            dedupedPastedKeywords
                .filter((keyword) => !keywords.find((k) => k === keyword.toLowerCase()))
                .slice(0, remainingKeywords)
                .map((keyword) => keyword.trim().toLowerCase()),
        );
    };

    return (
        <KeywordInputWrapper>
            <ProgressWrapper>
                <CircularProgress
                    size={70}
                    strokeWidth={10}
                    percentage={keywordsProgress}
                    progressColor={progressColor}
                    backgroundColor={progressBackgroundColor}
                >
                    <ProgressLabel color={progressColor}>{keywordsCount}</ProgressLabel>
                </CircularProgress>
                <ProgressText>
                    <ProgressTextTitle>
                        {/* @ts-ignore */}
                        <Text fontWeight="--fw-bold" variant="subheading">
                            <FormattedMessage
                                defaultMessage="Sélectionnez jusqu'à {maxKeywords} mots-clés"
                                values={{
                                    maxKeywords: keywordQuota.maximum,
                                }}
                            />
                        </Text>
                        {/* @ts-ignore */}
                        <UiKitTooltip
                            variant="dark"
                            position="top"
                            content={
                                <FormattedMessage
                                    defaultMessage="Votre offre vous permet de choisir jusqu’à {maxKeywords} mots-clés pour l’ensemble des émissions actives de votre compte"
                                    values={{
                                        maxKeywords: keywordQuota.maximum,
                                    }}
                                />
                            }
                        >
                            <FontAwesomeIcon
                                icon={icon({ name: 'circle-info', style: 'solid' })}
                                color={resolveColor('--neutral500')}
                                size="1x"
                            />
                        </UiKitTooltip>
                    </ProgressTextTitle>
                    {/* @ts-ignore */}
                    <Text color="--neutral500">
                        <FormattedMessage defaultMessage="Saisissez des mots-clés séparés par une virgule. Rassurez-vous, vous pourrez toujours modifier votre sélection plus tard." />
                    </Text>
                </ProgressText>
            </ProgressWrapper>
            <InputWrapper onClick={() => newKeywordInput?.current?.focus()}>
                <KeywordList>
                    {keywords.map((keyword, index) => (
                        <Keyword
                            key={index}
                            label={keyword}
                            onDelete={() => onKeywordDelete(keyword)}
                            hasError={showKeywordError && keyword.length > keywordMaxLength}
                        />
                    ))}
                    {keywordsCount < keywordQuota.maximum && (
                        <Input
                            ref={newKeywordInput}
                            type="text"
                            placeholder={intl.formatMessage({
                                defaultMessage: 'Saisissez un mot-clé...',
                            })}
                            maxLength={keywordMaxLength}
                            value={newKeyword}
                            onChange={handleChange}
                            onKeyDown={handleKeydown}
                            onPaste={handlePaste}
                        />
                    )}
                </KeywordList>
            </InputWrapper>
            <ActionWrapper>
                {keywordsCount > 0 ? (
                    // @ts-ignore
                    <Button variant="ghost" color="--alert" onClick={onKeywordDeleteAll}>
                        <FormattedMessage defaultMessage="Supprimer tout" />
                    </Button>
                ) : (
                    <Decoy />
                )}
                {/* @ts-ignore */}
                <Button
                    isDisabled={
                        keywordsCount <= 0 || keywordsCount > keywordQuota.maximum || pristine
                    }
                    onClick={() => {
                        const hasKeywordOverMaxLength = keywords.some(
                            (keyword) => keyword.length > keywordMaxLength,
                        );
                        if (hasKeywordOverMaxLength) {
                            toast.alert(
                                <FormattedMessage defaultMessage="Oups, un ou plusieurs des mots-clés saisis font plus de 40 caractères. Veuillez réessayer en saisissant des mots-clés valides. 🙏" />,
                            );
                            setShowKeywordError(true);
                            return;
                        }
                        setSubmitIsLoading(true);
                        onSubmit(keywords);
                    }}
                    isLoading={submitIsLoading}
                >
                    {isEditMode ? (
                        <FormattedMessage
                            defaultMessage="{nb, plural, one {Mettre à jour # mot-clé} other {Mettre à jour # mots-clés}}"
                            values={{
                                nb: keywords.length,
                            }}
                        />
                    ) : (
                        <FormattedMessage
                            defaultMessage="{nb, plural, one {Ajouter # mot-clé} other {Ajouter # mots-clés}}"
                            values={{
                                nb: keywords.length,
                            }}
                        />
                    )}
                </Button>
            </ActionWrapper>
        </KeywordInputWrapper>
    );
};

const KeywordInputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;
const ProgressWrapper = styled.div`
    display: flex;
    gap: 1rem;
`;
const ProgressLabel = styled.span`
    font-size: var(--fs-subheading);
    font-weight: var(--fw-bold);
    color: ${({ color }) => `var(${color})`};
`;
const ProgressText = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
`;
const ProgressTextTitle = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
`;
const InputWrapper = styled.div`
    border: 1px solid var(--neutral200);
    border-radius: var(--r-s);
    width: 100%;
    min-height: 240px;
    padding: 0.5rem;
`;
const KeywordList = styled.ul`
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
`;
const Input = styled.input`
    border: none;
    background: none;
    padding-block: 0.25rem;
    font-size: var(--fs-body-m);
    &::placeholder {
        color: var(--neutral500);
    }
`;
const ActionWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const Decoy = styled.div``;

export default KeywordInput;
