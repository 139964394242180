import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateCallToActionButtonsPositions } from '@/api';
import callToActionKeys from './callToActionKeys';
import camelcaseKeys from 'camelcase-keys';

interface MutationFnProps {
    showId: string;
    location: string;
    callToActionButtons: { id: string }[];
}

const transform = (data: unknown) => {
    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data as any, { deep: true });
};

const mutationFn = async ({ showId, location, callToActionButtons }: MutationFnProps) => {
    const { data } = await updateCallToActionButtonsPositions(showId, {
        location,
        ids: callToActionButtons.map((cta) => cta.id),
    });

    return transform(data);
};

export const useUpdateCallToActionButtonsPositionMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn,
        onSuccess: (_, { showId }) => {
            queryClient.invalidateQueries({
                queryKey: callToActionKeys.detailByShowId(showId),
            });
        },
    });
};

export default useUpdateCallToActionButtonsPositionMutation;
