import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ClipFormSchema } from '../../schemas/useFormSchema';
import { FormattedMessage } from 'react-intl';
import { InputTextController } from '@/components/ui/atoms/InputText';
import { secondsToDuration } from '@/shared/utils/duration';
import styled from 'styled-components';
import Alert from '@/components/ui/atoms/Alert';
import Text from '@/components/ui/atoms/Text';
import AudioWaveform from './Waveform';
import { addQueryParam } from '@/shared/utils/url';
import { useAuthContext } from '@/context/AuthContext';
import { Episode } from '@/queries/episode/useEpisodeQuery.hook';
import IconButton from '@/components/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { throttle } from 'lodash';
import { ClipTranscription } from '@/queries/clip/useClipTranscriptionQuery';

interface FormProps {
    episode: Episode;
    transcription: ClipTranscription;
}

const Form = ({ episode, transcription }: FormProps) => {
    const form = useFormContext<ClipFormSchema>();
    const { start, end, subtitles } = transcription;
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentPlayTime, setCurrentPlayTime] = useState(start);
    const [audioEditor, setAudioEditor] = useState<any>(null);
    const { accessToken } = useAuthContext();
    const inputRefs = useRef<(HTMLDivElement | null)[]>([]);
    const activeIndex = subtitles.findIndex(
        (s) => currentPlayTime >= s.start && currentPlayTime <= s.end,
    );

    useEffect(() => {
        if (activeIndex !== -1) {
            inputRefs.current[activeIndex]?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    }, [activeIndex]);

    const handlePlayPause = () => {
        if (audioEditor) {
            audioEditor.player.playOrPauseSegment();
            setIsPlaying((isPlaying) => !isPlaying);
        }
    };

    const handleTimeUpdate = (time: number) => {
        setCurrentPlayTime(time);
    };

    const handleTimeUpdateThrottled = throttle(handleTimeUpdate, 250);

    return (
        <Wrapper>
            <InnerWrapper>
                <WaveformWrapper>
                    <PlayPauseButton
                        aria-label={isPlaying ? 'Pause' : 'Play'}
                        icon={
                            isPlaying ? (
                                <PlayPauseIcon icon={icon({ name: 'pause', style: 'solid' })} />
                            ) : (
                                <PlayPauseIcon icon={icon({ name: 'play', style: 'solid' })} />
                            )
                        }
                        onPress={handlePlayPause}
                    />
                    <AudioWaveform
                        audioUrl={addQueryParam(episode.audioUrlInternal, 'token', accessToken)}
                        waveformDataUrl={episode.waveformUrl}
                        start={start}
                        end={end}
                        onInit={(newAudioEditor: any) => setAudioEditor(newAudioEditor)}
                        onPlay={() => setIsPlaying(true)}
                        onPause={() => setIsPlaying(false)}
                        onTimeUpdate={handleTimeUpdateThrottled}
                    />
                </WaveformWrapper>
                <Alert variant="info" defaultIcon>
                    <Text fontWeight="--fw-semibold">
                        <FormattedMessage defaultMessage="Chaque champ de texte est modifiable pour vous permettre d'effectuer d'éventuelles corrections. Nous recommandons de ne pas ajouter ou supprimer des mots car les minutages ne sont pas modifiables." />
                    </Text>
                </Alert>
                <InputsWrapper>
                    {subtitles.map((subtitle, index) => (
                        <div ref={(el) => (inputRefs.current[index] = el)} key={index}>
                            <TranscriptionInputTextController
                                name={`transcription.subtitles[${index}].subtitle`}
                                label={
                                    <FormattedMessage
                                        defaultMessage="{start} à {end}"
                                        values={{
                                            start: secondsToDuration(subtitle.start),
                                            end: secondsToDuration(subtitle.end),
                                        }}
                                    />
                                }
                                control={form.control}
                                maxLength={65}
                                characterOverflowBehavior="warning"
                                characterCountTooltip={
                                    <FormattedMessage defaultMessage="Nombre de caractères recommandé pour un affichage optimal des sous-titres." />
                                }
                                $isActive={
                                    currentPlayTime >= subtitle.start &&
                                    currentPlayTime <= subtitle.end
                                }
                            />
                        </div>
                    ))}
                </InputsWrapper>
            </InnerWrapper>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
`;
const InnerWrapper = styled.div`
    max-width: 40rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    height: 100%;
`;
const InputsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    flex-grow: 1;
    min-height: 0;
    overflow-y: auto;
    scroll-behavior: smooth;
`;
const PlayPauseButton = styled(IconButton)`
    border-radius: var(--r-full);
    padding: 0.625rem;
    width: 2.5rem;
    height: 2.5rem;
`;
const PlayPauseIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const WaveformWrapper = styled.div`
    display: flex;
    align-items: flex-end;
    column-gap: 1rem;

    & > :first-child {
        flex-shrink: 0;
    }

    & > :last-child {
        flex-grow: 1;
    }
`;
const TranscriptionInputTextController = styled(InputTextController)<{ $isActive: boolean }>`
    input {
        border-color: ${({ $isActive }) => ($isActive ? 'var(--primary)' : 'var(--neutral100)')};
    }
`;

export default Form;
