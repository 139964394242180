import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteShowGuestInvitation } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import guestKeys from './guestKeys';
import showKeys from '../show/showKeys';

const mutationFn = async (invitationId: number) => {
    const { data } = await deleteShowGuestInvitation(invitationId);

    return camelcaseKeys(data as any, { deep: true });
};

const deleteShowGuestInvitationMutation = () => {
    const queryclient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: () => {
            queryclient.invalidateQueries({
                queryKey: guestKeys.invitationList(),
            });
            // Invalidate show detail query to update the channelCanInvite field
            queryclient.invalidateQueries({
                queryKey: showKeys.allDetails(),
            });
        },
    });
};

export default deleteShowGuestInvitationMutation;
