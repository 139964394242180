import { createApi } from '../callApi';
import { uploadImage } from '../uploadImage';
const contentApi = createApi('CONTENT');

export const fetchClipFormat = (clipFormatId) => {
    return contentApi({ method: 'GET', target: `/v1/clip_formats/${clipFormatId}` });
};
export const updateClipFormat = (clipFormatId, clipFormat) => {
    return contentApi({
        method: 'PUT',
        target: `/v1/clip_formats/${clipFormatId}`,
        body: clipFormat,
    });
};
export const deleteClipFormat = (clipFormatId) => {
    return contentApi({
        method: 'DELETE',
        target: `/v1/clip_formats/${clipFormatId}`,
    });
};
export const uploadClipFormatImage = (clipFormatId, image) => {
    return uploadImage(`/v1/clip_formats/${clipFormatId}/image`, image);
};
export const deleteClipFormatImage = (clipFormatId) => {
    return contentApi({
        method: 'DELETE',
        target: `/v1/clip_formats/${clipFormatId}/image`,
    });
};
export const fetchClip = (clipId) => {
    return contentApi({ method: 'GET', target: `/v1/clips/${clipId}` });
};
export const updateClip = (clipId, clip) => {
    return contentApi({ method: 'PUT', target: `/v1/clips/${clipId}`, body: clip });
};
export const deleteClip = (clipId) => {
    return contentApi({ method: 'DELETE', target: `/v1/clips/${clipId}` });
};
export const generateClip = (clipId) => {
    return contentApi({ method: 'POST', target: `/v1/clips/${clipId}/generate` });
};
export const updateClipTranscription = (clipId, transcription) => {
    return contentApi({
        method: 'POST',
        target: `/v1/clips/${clipId}/transcription`,
        body: transcription,
    });
};
export const fetchClipTranscription = (clipId) => {
    return contentApi({ method: 'GET', target: `/v1/clips/${clipId}/transcription` });
};
export const fetchClipTranscriptionProgress = (clipId) => {
    return contentApi({ method: 'GET', target: `/v1/clips/${clipId}/transcription/status` });
};
export const createClip = (episodeId, clip) => {
    return contentApi({
        method: 'POST',
        target: `/v1/podcasts/${episodeId}/clips`,
        body: clip,
    });
};
export const fetchClipsOfEpisode = (episodeId) => {
    return contentApi({ method: 'GET', target: `/v1/podcasts/${episodeId}/clips` });
};
export const fetchClipFormatsOfShow = (showId, page, perPage) => {
    return contentApi({
        method: 'GET',
        target: `/v1/shows/${showId}/clip_formats`,
        query: { page, per_page: perPage },
    });
};
export const fetchClipFormatsOfPodcast = (episodeId) => {
    return contentApi({
        method: 'GET',
        target: `/v1/podcasts/${episodeId}/clip_formats`,
    });
};
export const fetchClipsOfShow = (showId, page, perPage) => {
    return contentApi({
        method: 'GET',
        target: `/v1/shows/${showId}/clips`,
        query: { page, per_page: perPage },
    });
};
export const fetchClipSupportedLanguages = () => {
    return contentApi({ method: 'GET', target: '/v1/podcasts/videos/transcription/languages' });
};
