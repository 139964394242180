import AushaHeader from './AushaHeader';
import TopbarPastDueWarningBand from './TopbarPastDueWarningBand';
import TopbarEnterpriseAccountDeactivatedBand from './TopbarEnterpriseAccountDeactivatedBand';
import TopbarUnpaidInvoiceWarningBand from './TopbarUnpaidInvoiceWarningBand';
import TopbarEndedBand from '@/components/Topbar/TopbarEndedBand';
import TopbarWarningBand from '@/components/Topbar/TopbarWarningBand';
import styled from 'styled-components';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import useSubscriptionQuery from '@/queries/subscription/useSubscriptionQuery.hook';
import { dateIsInTheFuture } from '@/shared/utils/date';

interface TopbarProps {
    noBackground?: boolean;
}

const Topbar = ({ noBackground = false }: TopbarProps) => {
    const { data: user } = useUserQuery();
    const { data: subscription } = useSubscriptionQuery();

    const enterpriseAccountDeactivated =
        user?.pricing === 'enterprise' && user?.activated === false;

    const subscriptionIsWithinGracePeriod =
        !!subscription?.endsAt && dateIsInTheFuture(subscription?.endsAt);
    const subscriptionHasEnded = !!subscription?.endsAt && !dateIsInTheFuture(subscription?.endsAt);

    if (!user) return null;

    return (
        <TopbarWrapper>
            {subscription && subscriptionHasEnded && <TopbarEndedBand key={0} />}
            {subscription && subscriptionIsWithinGracePeriod && <TopbarWarningBand key={0} />}
            {subscription && enterpriseAccountDeactivated && (
                <TopbarEnterpriseAccountDeactivatedBand />
            )}
            {subscription && subscription?.pastDue && <TopbarPastDueWarningBand key={0} />}
            {subscription && subscription?.hasUnpaidInvoices && (
                <TopbarUnpaidInvoiceWarningBand key={0} />
            )}
            <AushaHeader noBackground={noBackground || false} />
        </TopbarWrapper>
    );
};

const TopbarWrapper = styled.div`
    display: flex;
    flex-direction: column;
    z-index: 1;
    position: relative;
`;

export default Topbar;
