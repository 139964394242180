import { useEffect } from 'react';
import { connect } from '../../legacy/connect';
import { parseQuery } from '@/api/query';
import AushaClubLoginPage from './AushaClubLoginPage';
import { requestAuthorizationCode } from '@/api';

const enhance = connect(({ authStore }) => ({
    isLoggedIn: authStore.isLoggedIn,
}));

const AushaClubCallback = ({ isLoggedIn, location }) => {
    // Query params sent by Circle
    const {
        client_id: clientId,
        redirect_uri: redirectUri,
        response_type: responseType,
        scope,
    } = parseQuery(location.search);

    const requestCodeAndRedirect = async () => {
        // Request API to retrieve a validation code associated to this authentication
        const res = await requestAuthorizationCode({
            client_id: clientId,
            redirect_uri: redirectUri,
            response_type: responseType,
            scope,
        });

        if (res?.data?.code) {
            // Redirect to Circle with given code
            window.location = `${redirectUri}?code=${res.data.code}`;
        }
    };

    useEffect(() => {
        // Proceed authentication if user is already logged in or after login form submit
        if (isLoggedIn) {
            requestCodeAndRedirect();
        }
    }, [isLoggedIn]);

    if (isLoggedIn) {
        return null;
    }

    return <AushaClubLoginPage />;
};

export default enhance(AushaClubCallback);
