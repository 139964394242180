import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FormattedMessage } from 'react-intl';
import ApplePodcastsLogo from '@public/images/platforms/ApplePodcasts.svg?url';
import SpotifyLogo from '@public/images/platforms/Spotify.svg?url';
import { SPOTIFY, APPLE_PODCASTS } from '@/utils/constants';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';
import { TooltipTrigger } from 'react-aria-components';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import PsoRankingCardSkeleton from '../PsoRankingCardSkeleton';
import styled from 'styled-components';

const PsoBestPosition = ({ keyword, platforms, rank, isLoading }) => {
    const { data: user } = useUserQuery();

    const platformLookup = {
        [SPOTIFY]: SpotifyLogo,
        apple_podcasts: ApplePodcastsLogo,
    };
    if (isLoading) return <PsoRankingCardSkeleton />;
    if (!user) return null;
    return (
        <BestPositionWrapper>
            <Left>
                <BlockIconWrapper>
                    <FontAwesomeIcon icon={icon({ name: 'medal', style: 'solid' })} />
                </BlockIconWrapper>

                <KeywordWrapper>
                    <Title>
                        <FormattedMessage defaultMessage="Meilleure position" />
                        <TooltipTrigger delay={0} closeDelay={0}>
                            <TooltipTriggerWrapper>
                                <InfoIcon icon={icon({ name: 'circle-info', style: 'solid' })} />
                            </TooltipTriggerWrapper>
                            <Tooltip placement="top">
                                <FormattedMessage defaultMessage="Le mot-clé sur lequel votre podcast est le mieux référencé sur les 7 derniers jours" />
                            </Tooltip>
                        </TooltipTrigger>
                    </Title>
                    {keyword ? (
                        <Keyword>
                            <KeywordLabel>{keyword}</KeywordLabel>
                            <Platforms>
                                {platforms.map((platform) => (
                                    <PlatformIcon key={platform} src={platformLookup[platform]} />
                                ))}
                            </Platforms>
                        </Keyword>
                    ) : (
                        <EmptyState />
                    )}
                </KeywordWrapper>
            </Left>
            {keyword && (
                <RankWrapper>
                    <MedalWrapper>
                        <MedalGlow />
                        <MedalIcon icon={icon({ name: 'award', style: 'solid' })} rank={rank} />
                    </MedalWrapper>
                    <Rank>
                        <FormattedMessage
                            defaultMessage="{rank, selectordinal, one {#<particle>er</particle>} other{#<particle>e</particle>}}"
                            values={{
                                rank,
                                particle: (chunks) => <RankParticle>{chunks}</RankParticle>,
                            }}
                        />
                    </Rank>
                </RankWrapper>
            )}
        </BestPositionWrapper>
    );
};

const BestPositionWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    gap: 1rem;
`;
const Left = styled.div`
    display: flex;
    align-items: center;
    gap: 0.75rem;
    flex: 1;
    width: calc(100% - 3rem);
`;
const BlockIconWrapper = styled.div`
    background: var(--gradient-sunny);
    color: var(--decorativeD);
    font-size: 1rem;
    border-radius: var(--r-s);
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
`;
const KeywordWrapper = styled.div`
    width: calc(100% - 2.5rem);
`;
const Title = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
    color: var(--neutral500);
    font-size: var(--fs-body-m);

    & > *:last-child {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;
const InfoIcon = styled(FontAwesomeIcon)`
    font-size: 0.75rem;
    color: var(--neutral500);
`;
const Keyword = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-weight: var(--fw-bold);
    width: 100%;
`;
const KeywordLabel = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
`;
const Platforms = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
`;
const EmptyState = styled.div`
    width: 0.75rem;
    height: 0.125rem;
    background-color: var(--neutral500);
    border-radius: var(--r-full);
    margin-block: 0.5rem;
`;
const PlatformIcon = styled.img`
    width: 0.75rem;
    height: auto;
`;
const RankWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
    flex-shrink: 0;
`;
const MedalWrapper = styled.div`
    position: relative;
`;
const MedalGlow = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgb(255, 255, 255);
    background: linear-gradient(
        135deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.8) 30%,
        rgba(255, 255, 255, 0) 100%
    );
`;
const MedalIcon = styled(FontAwesomeIcon)`
    font-size: 1rem;
    ${({ rank }) => rank === 1 && `color: var(--decorativeD);`}
    ${({ rank }) => rank === 2 && `color: var(--neutral300);`}
    ${({ rank }) => rank > 2 && `color: var(--decorativeC);`}
`;
const Rank = styled.div`
    display: flex;
    font-size: var(--fs-heading-s);
    font-weight: var(--fw-bold);
`;
const RankParticle = styled.div`
    color: var(--neutral500);
    font-size: var(--fs-body-s);
    font-weight: var(--fw-semibold);
`;

PsoBestPosition.propTypes = {
    keyword: PropTypes.string,
    platforms: PropTypes.arrayOf(PropTypes.oneOf([SPOTIFY, APPLE_PODCASTS])),
    rank: PropTypes.number,
    isLoading: PropTypes.bool,
};

PsoBestPosition.defaultProps = {
    isLoading: false,
};

export default PsoBestPosition;
