import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { FORM_VALIDATION_MESSAGE } from '@/utils/validationMessage';
import { checkImgDimensions, checkImgFormat, checkImgSize } from '@/helpers';
import { EPISODE_PRIVACY } from '@/utils/constants';

const usePlaylistFormSchema = ({ locale, isPrivacyOptionEnabled, isNew }) => {
    const intl = useIntl();
    const acceptedMimes = ['jpg', 'png'];
    const IMG_DIMENSIONS = { minWidth: 400, minHeight: 400 };
    const slugRegex = /^[a-zA-Z0-9-À-ÿ]+$/;

    return yup.object({
        name: yup.string().required(intl.formatMessage(FORM_VALIDATION_MESSAGE.required)),
        privacy: yup.string().when([], {
            is: () => isPrivacyOptionEnabled === true,
            then: () =>
                yup
                    .string()
                    .oneOf(Object.values(EPISODE_PRIVACY))
                    .required(intl.formatMessage(FORM_VALIDATION_MESSAGE.required)),
            otherwise: () => yup.string().notRequired(),
        }),
        password: yup.string().when('privacy', {
            is: (privacy) => isPrivacyOptionEnabled && privacy === 'private',
            then: () => yup.string().required(intl.formatMessage(FORM_VALIDATION_MESSAGE.required)),
            otherwise: () => yup.string().notRequired(),
        }),
        html_description: yup
            .string()
            .max(4000, intl.formatMessage(FORM_VALIDATION_MESSAGE.maxLength, { max: 4000 }))
            .notRequired(),
        slug: yup.string().when([], {
            is: () => isNew,
            then: () => yup.string().notRequired(),
            otherwise: () =>
                yup
                    .string()
                    .matches(
                        slugRegex,
                        intl.formatMessage(FORM_VALIDATION_MESSAGE.noSpecialCharacters),
                    )
                    .required(intl.formatMessage(FORM_VALIDATION_MESSAGE.required)),
        }),
        file: yup
            .mixed()
            .nullable()
            .test(
                'avatar format',
                intl.formatMessage(FORM_VALIDATION_MESSAGE.acceptedMimes, { acceptedMimes }),
                (file) => {
                    if (typeof file === 'string' || file === null) return true;
                    return checkImgFormat(file);
                },
            )
            .test(
                'avatar size',
                intl.formatMessage(FORM_VALIDATION_MESSAGE.imgMaxSize, { max: 10 }),
                (file) => {
                    if (typeof file === 'string' || file === null) return true;
                    return checkImgSize(file, locale, 10);
                },
            )
            .test(
                'avatar dimensions',
                intl.formatMessage(FORM_VALIDATION_MESSAGE.imgMinDimensions, IMG_DIMENSIONS),
                async (file) => {
                    if (typeof file === 'string' || file === null) return true;
                    const img = new Image();
                    img.src = URL.createObjectURL(new File([file], 'pic'));
                    return new Promise((resolve) => {
                        img.onload = () => {
                            const isValid = checkImgDimensions(img, IMG_DIMENSIONS);
                            resolve(isValid);
                        };
                    });
                },
            ),
    });
};

export default usePlaylistFormSchema;
