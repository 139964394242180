import { useAmplitudeLogEvent } from '@/shared/hooks/useAmplitude';
import styled from 'styled-components';
import Player from '@/components/unorganized/Player';

function PlayerPage() {
    useAmplitudeLogEvent('Smartplayer Tab Viewed');

    return (
        <PlayerPageWrapper>
            <Player />
        </PlayerPageWrapper>
    );
}

const PlayerPageWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding-mobile);

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        padding: var(--container-padding);
    }
`;

export default PlayerPage;
