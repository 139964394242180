import { FormattedMessage } from 'react-intl';
import Button from '@ui/atoms/Button';
import Text from '@ui/atoms/Text';
import Modal from '@ui/molecules/Modal';

const DeleteSeasonModal = ({ isOpened, onClose, season, onDelete }) => {
    return (
        <Modal
            title={<FormattedMessage defaultMessage="Supprimer une saison" />}
            isOpen={isOpened}
            onClose={onClose}
            action={
                <>
                    <Button variant="secondary" onPress={onClose}>
                        <FormattedMessage defaultMessage="Annuler" />
                    </Button>
                    <Button onPress={onDelete}>
                        <FormattedMessage defaultMessage="Supprimer la saison" />
                    </Button>
                </>
            }
        >
            <Text>
                <FormattedMessage defaultMessage="Souhaitez-vous supprimer cette saison ?" />
            </Text>
            {season.podcastsCount > 0 && (
                <Text>
                    <FormattedMessage defaultMessage="Les épisodes de cette saison se retrouveront non classés." />
                </Text>
            )}
        </Modal>
    );
};

export default DeleteSeasonModal;
