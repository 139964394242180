import { useMutation, useQueryClient } from '@tanstack/react-query';
import { removePodcastFromPlaylist } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import playlistKeys from './playlistKeys';

interface RemovePlaylistImageMutationParams {
    playlistId: string;
    episodeId: number;
}

const mutationFn = async ({ playlistId, episodeId }: RemovePlaylistImageMutationParams) => {
    const { data } = await removePodcastFromPlaylist(playlistId, episodeId);

    return camelcaseKeys(data as any, { deep: true });
};

const removePlaylistEpisodeMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: (_, { playlistId }) => {
            queryClient.invalidateQueries(playlistKeys.detailById(playlistId));
        },
    });
};

export default removePlaylistEpisodeMutation;
