import {
    useEpisodeEditAudioFileMutation,
    useEpisodeEditAudioFileState,
} from '@/context/EpisodeEditContext';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import EpisodeEditResumeAudioFileInput from '../EpisodeEditResumeAudioFileInput';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';
import useEpisodeAudioFileSchema from '@/shared/hooks/useEpisodeAudioFileSchema.hook';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import { useQueryClient } from '@tanstack/react-query';
import episodeKeys from '@/queries/episode/episodeKeys';
import { useBodyToastQueue } from '@/shared/hooks/useBodyToastQueue.hook';

const EpisodeEditResumeAudioFile = () => {
    const { episodeId } = useParams();
    const episodeEditAudioFileMutation = useEpisodeEditAudioFileMutation();
    const [_, setEpisodeEditAudioFile] = useEpisodeEditAudioFileState();
    const { data: user } = useUserQuery();
    const schema = useEpisodeAudioFileSchema(user?.language);
    const queryClient = useQueryClient();
    const { control, watch, handleSubmit } = useForm({
        mode: 'onChange',
        resolver: yupResolver(schema),
        defaultValues: {
            audioFile: null,
        },
    });
    const toast = useBodyToastQueue();

    useEffect(() => {
        const subscription = watch(() => {
            handleSubmit(onSubmit)();
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    const onSubmit = ({ audioFile }) => {
        episodeEditAudioFileMutation.mutate(
            { episodeId: episodeId, audioFile },
            {
                onSuccess: () => {
                    localStorage.setItem(`episode-${episodeId}-audio-file-uploaded`, 'true');
                    queryClient.invalidateQueries({
                        queryKey: episodeKeys.audioFileJobsByEpisodeId(episodeId),
                    });
                },
                onError: () => {
                    setEpisodeEditAudioFile(null);
                    toast.alert(
                        <FormattedMessage defaultMessage="Oups, un problème a été détecté avec votre fichier audio. Veuillez vérifier qu'il est bien valide et réessayer. 🙏" />,
                    );
                },
            },
        );
        // Keep audioFile in a state to display audioFile data in
        // episode form during audioFile upload
        setEpisodeEditAudioFile(audioFile);
    };

    return (
        <Controller
            control={control}
            name="audioFile"
            render={({
                field: { value, ...field },
                formState: { errors },
                fieldState: { error },
            }) => (
                <EpisodeEditResumeAudioFileInput
                    {...field}
                    error={error ?? errors?.root?.serverError}
                />
            )}
        />
    );
};

export default EpisodeEditResumeAudioFile;
