import { action } from 'mobx';
import ApplePodcastsStatsStore from './statistics/ApplePodcastsStatsStore';

class RootStore {
    applePodcastsStatsStore = new ApplePodcastsStatsStore(this);

    @action
    reset() {
        Object.values(this).forEach((store) => store.reset && store.reset());
    }
}

export default new RootStore();
