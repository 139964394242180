import { useContext } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import Link from '@ui/atoms/Link';
import Text from '@ui/atoms/Text';
import useAuthStore from '@/shared/hooks/useAuthStore';
import { useResponsive } from '@/shared/hooks/useResponsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled, { css, keyframes } from 'styled-components';
import HideFor from '@/components/unorganized/HideFor';
import { useQueryClient } from '@tanstack/react-query';
import Cluster from '@ui/layout/Cluster';
import Stack from '@ui/layout/Stack';
import Meter from '@ui/atoms/Meter';
import useSubscriptionQuery from '@queries/subscription/useSubscriptionQuery.hook';
import useUserQuery from '@queries/user/useUserQuery.hook';
import { extractCssVariable } from '@/shared/utils/cssVariable';
import Img from '@ui/atoms/Img';
import RocketImage from '@public/images/rocket-tilted-right.png';
import { CurrentShow } from '@/context/CurrentShowContext';

const UserDropdownMenu = ({ visible, onClose, onOpenFeedback }) => {
    const { logout } = useAuthStore();
    const intl = useIntl();
    const { isMobileOrTablet } = useResponsive();
    const { data: user, isError } = useUserQuery();
    const { data: subscription } = useSubscriptionQuery();
    const queryClient = useQueryClient();
    const mobilePortalTarget = document.getElementById('modal-root');
    const currentShow = useContext(CurrentShow);

    const onLogout = async () => {
        await logout(queryClient);
        // Remove this show from the current show context
        currentShow.setShowId('');
    };

    if (isError) return null;

    const content = (
        <UserDropdownMenuWrapper>
            <MobileHandle onClick={onClose} />
            <IdentityWrapper>
                <Cluster $gap="0.5rem" $wrap="nowrap">
                    {user ? <Avatar src={user?.avatar} /> : <AvatarSkeleton />}
                    <IdentityTextWrapper>
                        {user ? (
                            <Text variant="subheading" fontWeight="--fw-semibold" numberOfLines={1}>
                                {user?.firstname} {user?.lastname}
                            </Text>
                        ) : (
                            <TextSkeleton />
                        )}
                        {user ? (
                            <Text color="--neutral500" numberOfLines={1}>
                                {user?.email}
                            </Text>
                        ) : (
                            <TextSkeleton />
                        )}
                    </IdentityTextWrapper>
                </Cluster>
                {subscription?.isFreemium && (
                    <>
                        <Stack $gap="0.75rem">
                            <Text
                                color={
                                    user?.trialEpisodes >= user?.maximumTrialEpisodes
                                        ? '--alert'
                                        : '--neutral500'
                                }
                            >
                                <FormattedMessage
                                    defaultMessage="{publishedEpisodes, plural, =0 {# / {maxEpisodes} épisode publié} one {# / {maxEpisodes} épisode publié} other {# / {maxEpisodes} épisodes publiés}}"
                                    values={{
                                        publishedEpisodes: user?.trialEpisodes,
                                        maxEpisodes: user?.maximumTrialEpisodes,
                                    }}
                                />
                            </Text>
                            <Meter
                                value={user?.trialEpisodes}
                                maxValue={user?.maximumTrialEpisodes}
                            />
                        </Stack>
                        <FreemiumIncentive to="/app/subscription/offers">
                            <FreemiumText>
                                <Text variant="bodyM" color="--primary">
                                    <FormattedMessage
                                        defaultMessage="Vous utilisez une <strong>offre Free</strong>"
                                        values={{
                                            strong: (chunk) => <strong>{chunk}</strong>,
                                        }}
                                    />
                                </Text>
                                <Cluster $gap="0.25rem" $align="center">
                                    <Text
                                        variant="bodyS"
                                        color="--primary"
                                        fontWeight="--fw-semibold"
                                    >
                                        <FormattedMessage defaultMessage="Passer en illimité" />
                                    </Text>
                                    <FontAwesomeIcon
                                        icon={icon({ name: 'arrow-right', style: 'solid' })}
                                        color={extractCssVariable('--primary')}
                                        size="xs"
                                    />
                                </Cluster>
                            </FreemiumText>
                            <FreemiumIllustration src={RocketImage} />
                        </FreemiumIncentive>
                    </>
                )}
            </IdentityWrapper>
            <Separator />
            <Menu>
                <MenuItem onClick={onClose}>
                    <Link to="/app/user/profile" data-testid="dropdown-user-account-link">
                        <FontAwesomeIcon
                            icon={icon({ name: 'user', style: 'regular' })}
                            color="inherit"
                            size="xs"
                        />
                        <FormattedMessage defaultMessage="Compte" />
                    </Link>
                </MenuItem>
                <MenuItem onClick={onClose}>
                    <button onClick={onOpenFeedback}>
                        <FontAwesomeIcon
                            icon={icon({ name: 'star', style: 'regular' })}
                            color="inherit"
                            size="xs"
                        />
                        <FormattedMessage defaultMessage="Feedback" />
                    </button>
                </MenuItem>
                <MenuItem onClick={onClose}>
                    <a
                        href={intl.formatMessage({ defaultMessage: 'https://aide.ausha.co/' })}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <FontAwesomeIcon
                            icon={icon({ name: 'question-circle', style: 'regular' })}
                            color="inherit"
                            size="xs"
                        />
                        <FormattedMessage defaultMessage="Aide et support" />
                    </a>
                </MenuItem>
                <MenuSeparator />
                {isMobileOrTablet && (
                    <>
                        <MenuItem onClick={onClose}>
                            <Link to="/app/song-library">
                                <FontAwesomeIcon
                                    icon={icon({ name: 'list-music', style: 'regular' })}
                                    color="inherit"
                                    size="xs"
                                />
                                <FormattedMessage defaultMessage="Bibliothèque musicale" />
                            </Link>
                        </MenuItem>
                        <HideFor condition={subscription?.activated}>
                            <MenuItem onClick={onClose}>
                                <Link to="/app/sponsorship">
                                    <FontAwesomeIcon
                                        icon={icon({ name: 'gift', style: 'regular' })}
                                        color="inherit"
                                        size="xs"
                                    />
                                    <FormattedMessage defaultMessage="Parrainage" />
                                </Link>
                            </MenuItem>
                        </HideFor>
                        <MenuItem onClick={onClose}>
                            <a href="https://updates.ausha.co/" target="_blank" rel="noreferrer">
                                <FontAwesomeIcon
                                    icon={icon({ name: 'rocket-launch', style: 'regular' })}
                                    color="inherit"
                                    size="xs"
                                />
                                <FormattedMessage defaultMessage="Nouveautés" />
                            </a>
                        </MenuItem>
                        <MenuSeparator />
                    </>
                )}
                <MenuItem onClick={onClose}>
                    <LogoutButton onClick={onLogout} data-testid="logout-button">
                        <FontAwesomeIcon
                            icon={icon({ name: 'arrow-right-from-bracket', style: 'regular' })}
                            color="inherit"
                            size="xs"
                        />
                        <FormattedMessage defaultMessage="Se déconnecter" />
                    </LogoutButton>
                </MenuItem>
            </Menu>
        </UserDropdownMenuWrapper>
    );

    return (
        <>
            {isMobileOrTablet ? (
                createPortal(
                    <OuterWrapper visible={visible}>
                        <MobileOuterWrapper>
                            <MobileWrapper className={visible && 'visible'}>
                                {content}
                            </MobileWrapper>
                        </MobileOuterWrapper>
                    </OuterWrapper>,
                    mobilePortalTarget,
                )
            ) : (
                <OuterWrapper visible={visible}>
                    <Dropper data-testid="dropdown-user-menu" className={visible && 'visible'}>
                        {content}
                    </Dropper>
                </OuterWrapper>
            )}
        </>
    );
};

const OuterWrapper = styled.div`
    display: ${({ visible }) => (visible ? 'block' : 'none')};
`;
const UserDropdownMenuWrapper = styled.div`
    padding-block: 1rem;
    display: flex;
    flex-direction: column;
`;
const MobileHandle = styled.div`
    width: 4rem;
    height: 0.25rem;
    background-color: var(--neutral100);
    border-radius: var(--r-full);
    margin-inline: auto;
    margin-bottom: 1.5rem;
    margin-top: 0.5rem;

    ${({ theme }) => theme.mediaQueries.tabletAndUp} {
        display: none;
    }
`;
const IdentityWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-inline: 1rem;
`;
const AvatarBase = css`
    width: 2.5rem;
    height: 2.5rem;
    border-radius: var(--r-full);
`;
const AvatarSkeleton = styled.div`
    ${AvatarBase};
    background: var(--neutral100);
`;
const Avatar = styled.img`
    ${AvatarBase};
`;
const IdentityTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 0;
`;
const TextSkeleton = styled.div`
    width: 8rem;
    height: 0.75rem;
    background: var(--neutral100);
    border-radius: var(--r-full);
    margin-block: 0.25rem;
`;
const Menu = styled.ul`
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    list-style: none;
    padding-inline: 0.75rem;
`;
const SeparatorBase = css`
    width: 100%;
    height: 1px;
    background: var(--neutral100);
    margin-block: 1rem;
`;
const Separator = styled.div`
    ${SeparatorBase};
    width: calc(100% - 2rem);
    margin-inline: auto;
`;
const MenuSeparator = styled.li`
    ${SeparatorBase};
`;
const LogoutButton = styled.button``;
const MenuItem = styled.li`
    transition-duration: 0.2s;
    font-weight: var(--fw-semibold);
    padding-block: 0.5rem;
    padding-inline: 0.5rem;
    border-radius: var(--r-s);

    ${({ theme }) => theme.mediaQueries.tabletAndUp} {
        &:hover {
            transition-duration: 0.2s;
            background: var(--neutral50);
        }
        &:hover:last-child {
            transition-duration: 0.2s;
            background: var(--alert50);
        }
    }

    & > * {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        gap: 0.5rem;
        cursor: pointer;
    }
    & > a {
        color: inherit;
        text-decoration: none;
    }
    & > button {
        color: inherit;
        background: none;
        border: none;
        padding: 0;
    }

    & > ${LogoutButton} {
        color: var(--alert500);
    }
`;
const showUp = keyframes`
    from {
        opacity: 0;
        transform: translate(-0.5rem, calc(100%));
    }
    to {
        opacity: 1;
        transform: translate(-0.5rem, calc(100% + 0.5rem));
    }
`;
const Dropper = styled.div`
    width: 240px;
    position: absolute;
    bottom: 0;
    right: 0;
    background: var(--white);
    transform: translate(-0.5rem, calc(100% + 0.5rem));
    border-radius: var(--r-l);
    z-index: 1000;
    box-shadow: var(--s-m);

    &.visible {
        animation: ${showUp} 0.2s ease-in-out alternate;
    }
`;
const MobileOuterWrapper = styled.div`
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: 100%;
    padding-inline: 1rem;
    padding-block: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    background-color: var(--black500);
`;
const showUpMobile = keyframes`
    from {
        opacity: 0;
        transform: translateY(0.5rem);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;
const MobileWrapper = styled.div`
    background: var(--white);
    border-radius: var(--r-l);
    width: 100%;

    @media (orientation: landscape) {
        overflow-y: scroll;
    }

    &.visible {
        animation: ${showUpMobile} 0.2s ease-in-out alternate;
    }
`;

const FreemiumIncentive = styled(Link)`
    position: relative;
    height: 5rem;
    padding: 0.5rem;
    border-radius: var(--r-s);
    background: var(--gradient-dusk);
    text-decoration: none;
`;

const FreemiumText = styled(Stack)`
    max-width: 8rem;
    gap: 0.5rem;
`;

const FreemiumIllustration = styled(Img)`
    position: absolute;
    top: 0;
    right: 0;
    height: 4.75rem;
`;

UserDropdownMenu.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onOpenFeedback: PropTypes.func.isRequired,
};

export default UserDropdownMenu;
