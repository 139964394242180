import { memo, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from '../../decorators/connect';
import { useResponsive } from '@hooks/useResponsive';
import MessageProviderLogin from '../MessageProviderLogin';
import { INSTAGRAM, LINKEDIN, SOCIAL_NETWORK_NAME, YOUTUBE, SOUNDCLOUD } from '@/utils/constants';
import { USER_OPTIONS } from '@/utils/userOptions';
import IconRocket from '@ui/icons/IconRocket';
import Button from '@ui/atoms/Button';
import Link from '@ui/atoms/Link';
import Stack from '@ui/layout/Stack';
import Cluster from '@ui/layout/Cluster';
import Text from '@ui/atoms/Text';
import { isProviderEnabled } from '@/helpers/providers';
import TooltipHover from '@ui/smarties/TooltipHover';
import { useWindowPopupSocialMediaManager } from '@/utils/hooks/useWindowPopupSocialMediaManager';
import useLoginOnMediaPlatformMutation from '@queries/socialNetworkPlatform/useLoginOnMediaPlatformMutation.hook';
import useFetchSocialRedirectUrlMutation from '@/queries/socialNetworkPlatform/useFetchSocialRedirectUrlMutation.hook';
import { SOCIAL_NETWORK } from '@/utils/constants';
import { useQueryClient } from '@tanstack/react-query';
import useUpdateShowTemplateMutation from '@/queries/showTemplate/useUpdateShowTemplateMutation.hook';
import providerKeys from '@/queries/provider/providerKeys';
import useUserQuery from '@queries/user/useUserQuery.hook';
import ProviderLoginLinkedinAccountModal from './ProviderLoginLinkedinAccountModal';
import { useBodyToastQueue } from '@/utils/hooks/useBodyToastQueue.hook';
import InstagramAccountSelectModal from './InstagramAccountSelectModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import useShowQuery from '@/queries/show/useShowQuery.hook';

const DisabledProviderButton = styled(Button)`
    flex-grow: 1;
`;

const enhance = connect(({ routerStore }) => ({
    upgradeLink: routerStore.link('user.edit.offers'),
}));

const ProviderLogin = ({ provider, headerProvider, upgradeLink }) => {
    const { showId } = useParams();
    const { data: show } = useShowQuery(showId);
    const toast = useBodyToastQueue();
    const intl = useIntl();
    const userQuery = useUserQuery();
    const { isMobileOrTablet } = useResponsive();
    const isInstagramOrLinkedInBlocked =
        (provider === INSTAGRAM &&
            !show?.userOptions.includes(USER_OPTIONS.AUTOSHARING_INSTAGRAM)) ||
        (provider === LINKEDIN && !show?.userOptions.includes(USER_OPTIONS.AUTOSHARING_LINKEDIN));

    const platformId = provider;
    const isProviderDisabled = !isProviderEnabled(provider, userQuery.data);
    const queryClient = useQueryClient();
    const fetchProviderRedirectUrl = useFetchSocialRedirectUrlMutation();
    const loginOnMediaPlatform = useLoginOnMediaPlatformMutation();
    const updateShowTemplate = useUpdateShowTemplateMutation();
    const [isLinkedinAccountModalOpen, setLinkedinAccountModalOpen] = useState(false);
    const [linkedinAccounts, setLinkedinAccounts] = useState(null);
    const [showInstagramAccountSelectModal, setShowInstagramAccountSelectModal] = useState(false);
    const [instagramAccounts, setInstagramAccounts] = useState(null);
    const platformNames = {
        ...SOCIAL_NETWORK_NAME,
        [YOUTUBE]: 'YouTube',
        [SOUNDCLOUD]: 'SoundCloud',
    };

    // eslint-disable-next-line no-unused-vars
    const [_, openSocialNetworkOauthLogin] = useWindowPopupSocialMediaManager((e) => {
        if (e.data.provider !== platformId) return;
        loginOnMediaPlatform.mutate(
            { showId, platform: platformId, callbackData: e.data },
            {
                onSuccess: handleLoginSuccess,
                onError: handleLoginError,
            },
        );
    });

    const handleUpdateInstagramShowTemplate = (userId) => {
        if (!userId) return;
        updateShowTemplate.mutate({
            showId,
            provider: SOCIAL_NETWORK.INSTAGRAM,
            payload: {
                params: {
                    user_id: userId,
                },
            },
        });

        toast.success(
            <FormattedMessage
                defaultMessage="Votre compte {platform} est maintenant lié à Ausha. 🥳"
                values={{
                    platform: platformNames[SOCIAL_NETWORK.INSTAGRAM],
                }}
            />,
        );
    };

    const handleUpdateLinkedinShowTemplate = (accountId) => {
        if (!accountId) return;

        updateShowTemplate.mutate(
            {
                showId,
                provider: SOCIAL_NETWORK.LINKEDIN,
                payload: {
                    params: {
                        account_id: accountId,
                    },
                },
            },
            {
                onSuccess: () => {
                    setLinkedinAccountModalOpen(false);
                    toast.success(
                        <FormattedMessage
                            defaultMessage="Votre compte {platform} est maintenant lié à Ausha. 🥳"
                            values={{
                                platform: platformNames[SOCIAL_NETWORK.LINKEDIN],
                            }}
                        />,
                    );
                },
            },
        );
    };

    const handleLoginSuccess = (data) => {
        /**
         * Update the show template
         */
        if (platformId === SOCIAL_NETWORK.LINKEDIN) {
            if (data.length === 1) {
                handleUpdateLinkedinShowTemplate(data[0].id);
                return;
            }
            setLinkedinAccounts(data);
            setLinkedinAccountModalOpen(true);

            return;
        }
        if (platformId === SOCIAL_NETWORK.INSTAGRAM && data.length === 1) {
            handleUpdateInstagramShowTemplate(data[0].id);
            return;
        }
        if (platformId === SOCIAL_NETWORK.INSTAGRAM && data.length > 1) {
            setShowInstagramAccountSelectModal(true);
            setInstagramAccounts(data);
            return;
        }

        toast.success(
            <FormattedMessage
                defaultMessage="Votre compte {platform} est maintenant lié à Ausha. 🥳"
                values={{
                    platform: platformNames[platformId],
                }}
            />,
        );

        queryClient.invalidateQueries({
            queryKey: providerKeys.listByShowId(showId),
        });
    };

    const handleLoginError = (error) => {
        if (error?.response?.status === 400 && platformId === SOCIAL_NETWORK.INSTAGRAM) {
            toast.alert(
                <FormattedMessage
                    defaultMessage="Oups, le compte Instagram doit être un <strong>compte professionnel</strong> pour être lié à votre compte Ausha. <link>En savoir plus</link>."
                    values={{
                        strong: (chunks) => <strong>{chunks}</strong>,
                        link: (chunks) => (
                            <Text
                                as="a"
                                href={intl.formatMessage({
                                    defaultMessage:
                                        'https://help.ausha.co/fr/articles/5963614-comment-lier-mes-reseaux-sociaux-a-ausha#h_a0643053e2',
                                })}
                                target="_blank"
                            >
                                {chunks}
                            </Text>
                        ),
                    }}
                />,
            );
        }
    };

    const closeInstagramAccountSelectModal = () => {
        if (showInstagramAccountSelectModal) {
            setInstagramAccounts(null);
        }
        setShowInstagramAccountSelectModal(!showInstagramAccountSelectModal);
    };

    if (
        loginOnMediaPlatform.isLoading ||
        loginOnMediaPlatform.isFetching ||
        updateShowTemplate.isLoading ||
        updateShowTemplate.isFetching
    ) {
        return (
            <LoadingWrapper>
                <FontAwesomeIcon icon={icon({ name: 'spinner-third', style: 'solid' })} spin />
            </LoadingWrapper>
        );
    }

    return (
        <>
            <InstagramAccountSelectModal
                isOpen={showInstagramAccountSelectModal}
                onOpenChange={closeInstagramAccountSelectModal}
                instagramAccounts={instagramAccounts}
                onAccountSelect={(id) => {
                    handleUpdateInstagramShowTemplate(id);
                    closeInstagramAccountSelectModal();
                }}
            />
            <ProviderLoginLinkedinAccountModal
                isOpen={isLinkedinAccountModalOpen && linkedinAccounts?.length > 0}
                onSubmit={handleUpdateLinkedinShowTemplate}
                accounts={linkedinAccounts}
                onOpenChange={setLinkedinAccountModalOpen}
            />
            <Wrapper>
                <ProviderDescription>
                    {headerProvider}
                    <MessageProviderLogin provider={provider} />
                </ProviderDescription>
                <ProviderButton isMobile={isMobileOrTablet}>
                    {isProviderDisabled ? (
                        <TooltipHover
                            target={
                                <DisabledProviderButton isDisabled background={`--${provider}`}>
                                    <FormattedMessage
                                        defaultMessage="Se connecter à {provider}"
                                        values={{
                                            provider: (
                                                <Text
                                                    as="span"
                                                    color="white"
                                                    fontWeight="--fw-bold"
                                                >
                                                    {SOCIAL_NETWORK_NAME[provider]}
                                                </Text>
                                            ),
                                        }}
                                    />
                                </DisabledProviderButton>
                            }
                            arrow="bottom"
                        >
                            <Text>
                                <FormattedMessage
                                    defaultMessage="Oups, le partage via {providerName} est actuellement indisponible. Nos équipes font le nécessaire pour réparer cela au plus vite. 💪 Merci de réessayer plus tard. 💜"
                                    values={{
                                        providerName: SOCIAL_NETWORK_NAME[provider],
                                    }}
                                />
                            </Text>
                        </TooltipHover>
                    ) : isInstagramOrLinkedInBlocked ? (
                        <Button as={Link} to={upgradeLink} startIcon={<IconRocket />}>
                            <FormattedMessage defaultMessage="Passez en offre Boost" />
                        </Button>
                    ) : (
                        <Button
                            onPress={() => {
                                fetchProviderRedirectUrl.mutate(
                                    { platformId: provider },
                                    {
                                        onSuccess: (data) => {
                                            if (!data.loginPageUrl) return;
                                            openSocialNetworkOauthLogin(data.loginPageUrl);
                                        },
                                    },
                                );
                            }}
                            background={`--${provider}`}
                        >
                            <FormattedMessage
                                defaultMessage="Se connecter à {provider}"
                                values={{
                                    provider: (
                                        <Text
                                            as="span"
                                            color="white"
                                            fontWeight="--fw-bold"
                                            textTransform="capitalize"
                                        >
                                            {SOCIAL_NETWORK_NAME[provider]}
                                        </Text>
                                    ),
                                }}
                            />
                        </Button>
                    )}
                </ProviderButton>
            </Wrapper>
        </>
    );
};

const Wrapper = ({ children }) => {
    const { isMobile } = useResponsive();
    return isMobile ? (
        <Stack $gap="0.5rem">{children}</Stack>
    ) : (
        <Cluster $align="center" $wrap="nowrap" css="width: 100%">
            {children}
        </Cluster>
    );
};

const ProviderDescription = styled.div`
    width: 100%;
`;
const ProviderButton = styled.div`
    width: ${(p) => (p.isMobile ? '100%' : null)};
    min-width: ${(p) => (p.isMobile ? null : '12.75rem')};

    & > * {
        width: 100%;
    }
`;
const LoadingWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-block: 1rem;
    color: var(--primary);
`;

export default memo(enhance(ProviderLogin));
