import { useRef, useState } from 'react';
import {
    Button,
    DateInput,
    DateSegment,
    DialogTrigger,
    Popover,
    TimeField,
    TimeFieldProps,
    TimeValue,
} from 'react-aria-components';
import Label from '../Label';
import styled from 'styled-components';
import ErrorMessage from '../ErrorMessage';
import { Time } from '@internationalized/date';
import TimePickerList from './TimePickerList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

/* 
    cf. react-aria TimeField: https://react-spectrum.adobe.com/react-aria/TimeField.html#timefield-1
*/
interface TimePickerProps extends TimeFieldProps<TimeValue> {
    label?: string;
    errorMessage?: string;
    tooltip?: string | React.ReactNode;
    isOptional?: boolean;
    isRequired?: boolean;
    description?: string;
}

const TimePicker = ({
    label,
    errorMessage,
    tooltip,
    isOptional,
    isRequired,
    description,
    onChange,
    value,
    ...props
}: TimePickerProps) => {
    const triggerRef = useRef(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleChangeTime = (time: TimeValue) => {
        onChange?.(time);
        setIsDropdownOpen(false);
    };

    return (
        <TimePickerWrapper
            {...props}
            shouldForceLeadingZeros
            value={value ?? new Time()}
            onChange={handleChangeTime}
            data-disabled={props.isDisabled}
        >
            {label && (
                <Label
                    isOptional={isOptional}
                    isRequired={isRequired}
                    tooltip={tooltip}
                    description={description}
                >
                    {label}
                </Label>
            )}
            <DialogTrigger>
                <TimePickerInnerWrapper ref={triggerRef}>
                    <IconButton
                        isDisabled={props.isDisabled}
                        onPress={() => setIsDropdownOpen(true)}
                    >
                        <ClockIcon icon={icon({ name: 'clock', style: 'regular' })} />
                    </IconButton>
                    <InputDate>{(segment) => <InputDateSegment segment={segment} />}</InputDate>
                </TimePickerInnerWrapper>
                <TimePickerPopover
                    offset={4}
                    placement="bottom left"
                    triggerRef={triggerRef}
                    isOpen={isDropdownOpen}
                    onOpenChange={setIsDropdownOpen}
                >
                    <TimePickerList />
                </TimePickerPopover>
            </DialogTrigger>
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </TimePickerWrapper>
    );
};

const TimePickerWrapper = styled(TimeField)`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    max-width: fit-content;
    position: relative;
    z-index: 2;

    &[data-disabled='true'] {
        opacity: 0.5;
        cursor: default;
    }
`;
const InputDate = styled(DateInput)`
    display: flex;
    align-items: center;
    column-gap: 0.25rem;
`;
const TimePickerInnerWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    border: solid 1px var(--neutral200);
    border-radius: var(--r-s);
    background-color: var(--white);
    padding: 0.625rem 0.75rem;

    &:focus-within {
        outline: 2px solid var(--primary50);
        border: solid 1px var(--primary);
    }
`;
const TimePickerPopover = styled(Popover)`
    background-color: var(--white);
    height: 15rem;
    border-radius: var(--r-m);
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.05);

    ${({ isOpen }) => isOpen && `display: block;`}
`;
const ClockIcon = styled(FontAwesomeIcon)`
    width: 100%;
    height: 100%;
    color: var(--black);
`;
const IconButton = styled(Button)`
    padding: 0;
    border: none;
    background: none;
    width: 1rem;
    height: 1rem;
    cursor: pointer;
`;
const InputDateSegment = styled(DateSegment)`
    &:not([data-type='literal']) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.125rem;
    }
`;

export default TimePicker;
