import Modal from '@/components/ui/molecules/Modal';
import { FormattedMessage } from 'react-intl';
import EpisodePublicationDateForm from '../EpisodePublicationDateForm';

interface EpisodeEditPublicationDateModalProps {
    isOpen: boolean;
    onClose: () => void;
    isOnline: boolean;
    handleEditPublicationDate: (formData?: any) => void;
    isLoading: boolean;
    publishedAt: Date;
}

const EpisodeEditPublicationDateModal = ({
    isOpen,
    onClose,
    isOnline,
    handleEditPublicationDate,
    isLoading,
    publishedAt,
}: EpisodeEditPublicationDateModalProps) => {
    const date = publishedAt || new Date();

    return (
        // @ts-ignore
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={
                isOnline ? (
                    <FormattedMessage defaultMessage="Modifier la date et l'heure de publication de l'épisode" />
                ) : (
                    <FormattedMessage defaultMessage="Planifier la publication de l’épisode" />
                )
            }
            description={
                <FormattedMessage defaultMessage="L’épisode sera publié automatiquement à la date et à l’heure choisies." />
            }
            size="small"
            disableScroll
        >
            <EpisodePublicationDateForm
                handleEditPublicationDate={handleEditPublicationDate}
                isLoading={isLoading}
                isOnline={isOnline}
                // @ts-ignore
                publishedAt={date}
                onCloseModal={onClose}
            />
        </Modal>
    );
};

export default EpisodeEditPublicationDateModal;
