import { useEffect, useContext } from 'react';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import { EpisodeFiltersContextProvider } from '@/context/EpisodeFiltersContext';
import EpisodesPage from './EpisodesPage';
import PlaylistsPage from './PlaylistsPage';
import Stats from '@/features/stats';
import Topbar from '../../unorganized/Topbar';
import Pso from '@/features/pso';
import Charts from '@/features/charts';
import { ValidSubscriptionRoute } from '../../legacy/authorisationRoutes';
import { useAmplitudeSyncUserProperties } from '@/shared/hooks/useAmplitude';
import EpisodePreview from './EpisodePreview';
import { CurrentShow } from '@/context/CurrentShowContext';
import SummaryEpisodePage from './SummaryEpisodePage';
import NonHostingPublishedEpisodes from './NonHostingPublishedEpisodes';
import NonHostingDraftEpisodes from './NonHostingDraftEpisodes';
import Footer from '@/components/Footer';
import { ENABLE_NON_HOSTING_EP_MGT } from '@/shared/config/constants';
import NonHostingEpisodePreviewPage from './NonHostingEpisodePreviewPage';
import NavigationMenu from '@/components/NavigationMenu';
import NonHostingEpisodeFormPages from './NonHostingEpisodeFormPages';
import EpisodeEditPage from './EpisodeEditPage';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import MainLayout from '../layout/Main/Main.layout';
import MainLayoutSkeleton from '../layout/Main/Skeleton';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import Campaigns from '@/features/Campaigns';
import CampaignEditPage from '@/features/Campaigns/components/CampaignsPage/CampaignEditPage';
import Settings from '@/features/settings';
import useHasAccessToAushaForAll from '@/shared/hooks/useHasAccessToAushaForAll.hook';
import Communication from '@/features/communication';
import Clip from '@/features/communication/components/Communication/Clips/Clip';

const ApplicationPage = () => {
    useAmplitudeSyncUserProperties();
    const { showId } = useParams<{ showId: string }>();
    const currentShow = useContext(CurrentShow);
    const show = useShowQuery(showId);
    const { data: user } = useUserQuery();
    const userHasAccessToAushaForAll = useHasAccessToAushaForAll();

    const chartsEnabled = user?.options?.includes('enable_charts'); // TODO: Delete this when charts is released

    useEffect(() => {
        /**
         * Upon navigating towards a "show" page, we snatch the showId from the URL and set it in the context.
         * That way, non-"show" pages can still access the current showId. (e.g. UserPage)
         *
         * The check for !!Number(showId) ensures no accidental overwrite of the showId
         * by _something_ named showId that's really a UserPage's tab identifer or something.
         */
        if (location.pathname.startsWith('/app/show/') && showId && !!Number(showId)) {
            currentShow?.setShowId(showId);
        }
    }, [location.pathname]);

    if (show.error) {
        return <Redirect to="/app/user/profile" />;
    }

    if (show.data) {
        const showHasHostingAccess = show.data.hostedOnAusha === true;
        const defaultOnboardingRoute = userHasAccessToAushaForAll
            ? `/app/onboarding`
            : `/app/show/new`;
        const hostingDefaultRoute =
            show.data.podcastsCount > 0 ? `/app/show/${showId}/episodes` : defaultOnboardingRoute;
        const nonHostingDefaultRoute = ENABLE_NON_HOSTING_EP_MGT
            ? `/app/show/${showId}/workspace`
            : `/app/show/${showId}/pso`;
        const defaultRoute = showHasHostingAccess ? hostingDefaultRoute : nonHostingDefaultRoute;

        return (
            <Switch>
                {ENABLE_NON_HOSTING_EP_MGT && !showHasHostingAccess && (
                    <Route path="/app/show/:showId/workspace/episodes">
                        <NonHostingEpisodeFormPages />
                    </Route>
                )}
                {showHasHostingAccess && (
                    <Route path="/app/show/:showId/episodes/edit">
                        <EpisodeEditPage />
                    </Route>
                )}
                <Route path="/app/show/:showId/monetize/manual/:campaignId">
                    <CampaignEditPage />
                </Route>
                <Route exact path="/app/show/:showId/communication/clip/:clipId">
                    {show.data.archived ? (
                        <Redirect to={`/app/show/${showId}/communication/player`} />
                    ) : (
                        <Clip />
                    )}
                </Route>
                <MainLayout
                    header={
                        <>
                            <Topbar />
                            <NavigationMenu />
                        </>
                    }
                    footer={<Footer />}
                >
                    <Route path="/app/show/:showId(\d+)/:tab?">
                        <Switch>
                            {/* TODO: Delete this condition when charts is released */}
                            {chartsEnabled && (
                                <Route path="/app/show/:showId/charts/:tab?" component={Charts} />
                            )}
                            <Route path="/app/show/:showId/pso" component={Pso} />
                            <Route path="/app/show/:showId/settings" component={Settings} />
                            {ENABLE_NON_HOSTING_EP_MGT && !showHasHostingAccess && (
                                <Route exact path="/app/show/:showId/feed/:episodeId">
                                    <NonHostingEpisodePreviewPage />
                                </Route>
                            )}
                            {ENABLE_NON_HOSTING_EP_MGT && !showHasHostingAccess && (
                                <Route exact path="/app/show/:showId/feed">
                                    <EpisodeFiltersContextProvider>
                                        <NonHostingPublishedEpisodes />
                                    </EpisodeFiltersContextProvider>
                                </Route>
                            )}
                            {ENABLE_NON_HOSTING_EP_MGT && !showHasHostingAccess && (
                                <Route exact path="/app/show/:showId/workspace">
                                    <EpisodeFiltersContextProvider>
                                        <NonHostingDraftEpisodes />
                                    </EpisodeFiltersContextProvider>
                                </Route>
                            )}
                            {showHasHostingAccess && (
                                <Route exact path="/app/show/:showId/episodes/preview/:episodeId">
                                    <EpisodePreview />
                                </Route>
                            )}
                            {showHasHostingAccess && (
                                <Route exact path="/app/show/:showId/episodes/recap/:episodeId">
                                    <SummaryEpisodePage />
                                </Route>
                            )}
                            {showHasHostingAccess && (
                                <Route exact path="/app/show/:showId/episodes">
                                    <EpisodeFiltersContextProvider>
                                        <EpisodesPage />
                                    </EpisodeFiltersContextProvider>
                                </Route>
                            )}
                            {showHasHostingAccess && (
                                <ValidSubscriptionRoute
                                    strict
                                    path="/app/show/:showId/playlists/:playlistId?"
                                >
                                    <PlaylistsPage />
                                </ValidSubscriptionRoute>
                            )}
                            {showHasHostingAccess && (
                                <ValidSubscriptionRoute
                                    path="/app/show/:showId/communication"
                                    component={Communication}
                                />
                            )}
                            {showHasHostingAccess && (
                                <ValidSubscriptionRoute
                                    path="/app/show/:showId/stats"
                                    component={Stats}
                                />
                            )}
                            {showHasHostingAccess && (
                                <Route path="/app/show/:showId/monetize" component={Campaigns} />
                            )}
                            <Route>
                                <Redirect to={defaultRoute} />
                            </Route>
                        </Switch>
                    </Route>
                </MainLayout>
            </Switch>
        );
    }

    return <MainLayoutSkeleton />;
};

export default ApplicationPage;
