import Text from '@ui/atoms/Text';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import Stack from '@ui/layout/Stack';
import { InputNumberController } from '@ui/atoms/InputNumber';
import Button from '@/components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Cluster from '@ui/layout/Cluster';
import useSubscriptionQuery, {
    Subscription,
} from '@queries/subscription/useSubscriptionQuery.hook';
import {
    PRICING_CURRENCY_SYMBOLS,
    TRANSCRIPTION_CURRENCY_MONTH_PRICES,
    TRANSCRIPTION_CURRENCY_YEAR_PRICES,
} from '@/shared/config/constants';
import { Quota } from '@queries/quota/useNewsletterQuotaQuery.hook';
import Tooltip, { TooltipTriggerWrapper } from '@ui/Tooltip';
import { TooltipTrigger } from 'react-aria-components';
import styled from 'styled-components';

interface getQuotaPriceMessageProps {
    subscription?: Subscription;
    additionalQuota: number;
    intl: any;
}

const getQuotaPriceMessage = ({
    subscription,
    additionalQuota,
    intl,
}: getQuotaPriceMessageProps) => {
    if (!subscription) return;
    const price = getQuotaPrice(subscription, additionalQuota);
    return `${price}${PRICING_CURRENCY_SYMBOLS?.[subscription?.currency]} / ${
        subscription?.yearly
            ? intl.formatMessage({ defaultMessage: 'an' })
            : intl.formatMessage({ defaultMessage: 'mois' })
    }`;
};

const getQuotaPrice = (subscription: Subscription, additionalQuota: number) => {
    /* If error display nothing */
    if (!subscription) return;
    /* If no additional minutes added display 0 */
    if (!additionalQuota) return 0;

    const price = subscription.yearly
        ? TRANSCRIPTION_CURRENCY_YEAR_PRICES[subscription.currency]
        : TRANSCRIPTION_CURRENCY_MONTH_PRICES[subscription.currency];
    return price * additionalQuota;
};

interface TranscriptionEditQuotaFormProps {
    quota: Quota;
    onClose: () => void;
    onConfirm: (quantity: number) => void;
}

const TranscriptionEditQuotaForm = ({
    quota,
    onClose,
    onConfirm,
}: TranscriptionEditQuotaFormProps) => {
    const { control, handleSubmit, watch, formState } = useForm({
        defaultValues: {
            quota: quota?.maximum,
        },
    });
    const { isDirty } = formState;
    const quotaValue = watch('quota');
    const intl = useIntl();
    const { data: subscription } = useSubscriptionQuery();
    const additionalQuota = (quotaValue - quota?.maximum) / 60;
    const priceMessage = getQuotaPriceMessage({ subscription, additionalQuota, intl });

    const onSubmit = ({ quota }: { quota: number }) => {
        onConfirm(quota);
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <InnerWrapper>
                <Stack $gap="0.25rem">
                    <Text fontWeight="--fw-semibold">
                        <FormattedMessage defaultMessage="Minutes additionnelles" />
                    </Text>
                    <Text color="--neutral500" textTransform="lowercase">
                        {priceMessage}
                    </Text>
                </Stack>
                <InputNumberController
                    control={control}
                    step={quota?.step}
                    minValue={quota?.minimum}
                    name="quota"
                />
            </InnerWrapper>
            <FooterWrapper>
                <Divider />
                <Cluster $gap="0.25rem" $align="center">
                    <Text>
                        <FormattedMessage
                            defaultMessage="Votre nouvelle limite mensuelle de transcription sera de <quota>minutes.</quota>"
                            values={{
                                quota: (chunks: React.ReactNode) => (
                                    <Text as="span" fontWeight="--fw-semibold">
                                        {quotaValue} {chunks}
                                    </Text>
                                ),
                            }}
                        />
                    </Text>
                    <TooltipTrigger delay={0} closeDelay={0}>
                        <TooltipTriggerWrapper>
                            <HelpIcon icon={icon({ name: 'info-circle', style: 'solid' })} />
                        </TooltipTriggerWrapper>
                        <Tooltip placement="top">
                            <Text fontWeight="--fw-semibold">
                                <FormattedMessage
                                    defaultMessage="{additionalQuota, plural,
                                        one {# minute}
                                        other {# minutes}
                                    } de temps additionnel s’ajouteront aux {maxQuota} minutes incluses dans votre forfait."
                                    values={{
                                        maxQuota: quota?.maximum,
                                        additionalQuota: quotaValue - quota?.maximum,
                                    }}
                                />
                            </Text>
                        </Tooltip>
                    </TooltipTrigger>
                </Cluster>
                <ActionWrapper>
                    <Button isDisabled={!isDirty} variant="primary" type="submit">
                        <FormattedMessage defaultMessage="Modifier la limite" />
                    </Button>
                    <CancelButton onPress={onClose} variant="ghost">
                        <FormattedMessage defaultMessage="Annuler" />
                    </CancelButton>
                </ActionWrapper>
            </FooterWrapper>
        </Form>
    );
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    height: 100%;
`;
const InnerWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 1rem;
`;
const ActionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 1rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        column-gap: 1rem;

        & > :first-child {
            order: 1;
        }
    }
`;
const CancelButton = styled(Button)`
    padding: 0;
    color: var(--neutral500);
`;
const Divider = styled.div`
    height: 0.0625rem;
    background-color: var(--neutral100);
`;
const FooterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    align-items: stretch;
`;
const HelpIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
    width: 0.75rem;
    height: 0.75rem;
`;

TranscriptionEditQuotaForm.propTypes = {
    quota: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
};

TranscriptionEditQuotaForm.defaultProps = {
    onClose: () => {},
    onConfirm: () => {},
};

export default TranscriptionEditQuotaForm;
