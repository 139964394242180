import styled from 'styled-components';
import Form from './Form';
import FormatsPreview from './FormatsPreview';
import { Episode } from '@/queries/episode/useEpisodeQuery.hook';
import { Clip } from '@/queries/clip/useClipQuery';

interface CustomizationProps {
    episode: Episode;
    clip: Clip;
}

const Customization = ({ episode, clip }: CustomizationProps) => {
    return (
        <Wrapper>
            <InnerWrapper>
                <PreviewWrapper>
                    <FormatsPreview episode={episode} clip={clip} />
                </PreviewWrapper>
                <FormWrapper>
                    <Form />
                </FormWrapper>
            </InnerWrapper>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;
const InnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    max-width: 75rem;

    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        flex-direction: row;
        gap: 2rem;
        align-items: flex-start;

        & > :last-child {
            order: -1;
        }

        & > * {
            flex: 1;
        }
    }
`;
const FormWrapper = styled.div`
    padding: 1rem;
    background-color: var(--white);
    border-radius: var(--r-l);

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        padding: 1.5rem;
    }
`;
const PreviewWrapper = styled.div`
    ${({ theme }) => theme.mediaQueries.tabletAndUp} {
        min-width: 568px;
    }
`;

export default Customization;
