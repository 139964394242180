import { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table';
import { useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { APPLE_PODCASTS, SPOTIFY } from '@/utils/constants';
import useScrollPosition from '@/utils/hooks/useScrollPosition.hook';
import PlatformIcon from '@app/atoms/PlatformIcon';
import useOverviewQuery from '@queries/pso/useOverviewQuery.hook';
import PsoEvolutionCell from './PsoEvolutionCell';
import PsoSearchVolumeCell from './PsoSearchVolumeCell';
import PsoKeywordCell from './PsoKeywordCell';
import PositionCell from './PositionCell';
import DifficultyScoreCell from './DifficultyScoreCell';
import CellLoading from './CellLoading';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';
import { TooltipTrigger } from 'react-aria-components';
import DatatableSkeleton from './DatatableSkeleton';
import styled, { css } from 'styled-components';
import PsoMetadataCell from './PsoMetadataCell';
import useHasAccessToPsoChecker from '@/utils/hooks/useHasAccessToPsoChecker.hook';

const PsoDatatable = ({ currentStore }) => {
    const { showId } = useParams();
    const overview = useOverviewQuery({ showId, currentStore });
    const intl = useIntl();
    const scrollPosition = useScrollPosition();
    const [sorting, setSorting] = useState([{ id: 'currentPositionApple', desc: false }]);
    const [platformMinified, setPlatformMinified] = useState(false);
    const hasPsoChecker = useHasAccessToPsoChecker({ showId, mode: 'read' });

    // const previousResultsDate = overview?.data?.[0]?.previousResultsDate;

    const headerTooltipContentLookup = {
        keyword: <FormattedMessage defaultMessage="Liste de vos mots-clés." />,
        previousPositionApple: (
            <FormattedMessage defaultMessage="Position de votre podcast sur ces mots-clés la semaine dernière." />
        ),
        currentPositionApple: (
            <FormattedMessage defaultMessage="Position de votre podcast sur ces mots-clés ce jour." />
        ),
        trendApple: (
            <FormattedMessage defaultMessage="Évolution de la position de votre podcast entre la semaine dernière et aujourd’hui." />
        ),
        previousPositionSpotify: (
            <FormattedMessage defaultMessage="Position de votre podcast sur ces mots-clés la semaine dernière." />
        ),
        currentPositionSpotify: (
            <FormattedMessage defaultMessage="Position de votre podcast sur ces mots-clés ce jour." />
        ),
        trendSpotify: (
            <FormattedMessage defaultMessage="Évolution de la position de votre podcast entre la semaine dernière et aujourd’hui." />
        ),
        searchVolume: (
            <FormattedMessage defaultMessage="Le volume de recherche est un indicateur compris entre 5 et 100 qui montre la popularité d'un mot-clé en termes de recherches. Plus le nombre est élevé, plus le mot-clé est populaire." />
        ),
        occurrences: (
            <FormattedMessage defaultMessage="Visualisez le nombre de fois où le mot-clé est utilisé dans l'ensemble de vos métadonnées." />
        ),
        difficultyScore: (
            <FormattedMessage defaultMessage="Le score de difficulté est un indicateur entre 0 et 100 qui indique le niveau de concurrence d'un mot-clé. Plus le chiffre est élevé, plus la concurrence est forte." />
        ),
    };

    /**
     * Because we want to collapse the header at a certain scroll threshold,
     * the scroll position is actually lowered enough during the collapse process to make it
     * wiggle back and forth between it's two states undefinitely when the page is scrolled to a precise point.
     * To prevent this behavior, we apply a dead zone of 20px on each side of the designed threshold.
     * That way, the collapsed header won't trigger the scroll threshold immediately after.
     */
    useEffect(() => {
        // If scrollPosition goes under 60 - half the deadzone and was true - set to false
        if (scrollPosition < 60 - 20 && platformMinified) setPlatformMinified(false);
        // If scrollPosition goes over 60 + half the deadzone and was false - set to true
        if (scrollPosition > 60 + 20 && !platformMinified) setPlatformMinified(true);
    }, [scrollPosition]);

    const columnHelper = createColumnHelper();

    const appleColumns = useMemo(() =>
        columnHelper.group({
            id: 'platform-apple',
            header: (
                <PlatformHeader platform={APPLE_PODCASTS} shouldCollapse={platformMinified}>
                    {!platformMinified && (
                        <>
                            <SmallerPlatformIcon platform={APPLE_PODCASTS} />
                            <span>Apple Podcasts</span>
                        </>
                    )}
                </PlatformHeader>
            ),
            columns: [
                // columnHelper.accessor('previousPositionApple', {
                //     cell: (info) =>
                //         overview.isLoading || info.row.original.isLoading ? (
                //             <CellLoading />
                //         ) : (
                //             <PositionCell rank={info.getValue()} />
                //         ),
                //     header: intl.formatMessage(
                //         {
                //             defaultMessage: 'Pos. {shortDate}',
                //         },
                //         { shortDate: dayjs(previousResultsDate).format('D MMM') },
                //     ),
                //     size: 100,
                //     enableResizing: false,
                // }),
                columnHelper.accessor('currentPositionApple', {
                    cell: (info) =>
                        overview.isLoading || info.row.original.isLoading ? (
                            <CellLoading />
                        ) : (
                            <PositionCell rank={info.getValue()} />
                        ),
                    header: intl.formatMessage({
                        defaultMessage: 'Classement',
                    }),
                    size: 100,
                    enableResizing: false,
                    sortingFn: (rowA, rowB) => {
                        if (rowA.original.currentPositionApple === 0) {
                            return 1; // If the current position is 0, it should be at the bottom
                        }
                        if (rowB.original.currentPositionApple === 0) {
                            return -1; // If the current position is 0, it should be at the bottom
                        }

                        return (
                            rowA.original.currentPositionApple - rowB.original.currentPositionApple
                        );
                    },
                }),
                columnHelper.accessor('trendApple', {
                    cell: (info) =>
                        overview.isLoading || info.row.original.isLoading ? (
                            <CellLoading />
                        ) : (
                            <PsoEvolutionCell
                                trend={info.getValue().trend}
                                rankDiff={info.getValue().rankDiff}
                                missingData={info.getValue().missingData}
                            />
                        ),
                    header: intl.formatMessage({ defaultMessage: 'Évolution' }),
                    size: 100,
                    enableResizing: false,
                }),
            ],
        }),
    );

    const spotifyColumns = useMemo(() =>
        columnHelper.group({
            id: 'platform-spotify',
            header: (
                <PlatformHeader platform={SPOTIFY} shouldCollapse={platformMinified}>
                    {!platformMinified && (
                        <>
                            <SmallerPlatformIcon platform={SPOTIFY} />
                            <span>Spotify</span>
                        </>
                    )}
                </PlatformHeader>
            ),
            columns: [
                // columnHelper.accessor('previousPositionSpotify', {
                //     cell: (info) =>
                //         overview.isLoading || info.row.original.isLoading ? (
                //             <CellLoading />
                //         ) : (
                //             <PositionCell rank={info.getValue()} />
                //         ),
                //     header: intl.formatMessage(
                //         {
                //             defaultMessage: 'Pos. {shortDate}',
                //         },
                //         { shortDate: dayjs(previousResultsDate).format('D MMM') },
                //     ),
                //     size: 100,
                //     enableResizing: false,
                // }),
                columnHelper.accessor('currentPositionSpotify', {
                    cell: (info) =>
                        overview.isLoading || info.row.original.isLoading ? (
                            <CellLoading />
                        ) : (
                            <PositionCell rank={info.getValue()} />
                        ),
                    header: intl.formatMessage({
                        defaultMessage: 'Classement',
                    }),
                    size: 100,
                }),
                columnHelper.accessor('trendSpotify', {
                    cell: (info) =>
                        overview.isLoading || info.row.original.isLoading ? (
                            <CellLoading />
                        ) : (
                            <PsoEvolutionCell
                                trend={info.getValue().trend}
                                rankDiff={info.getValue().rankDiff}
                                missingData={info.getValue().missingData}
                            />
                        ),
                    header: intl.formatMessage({ defaultMessage: 'Évolution' }),
                    size: 100,
                }),
            ],
        }),
    );

    const psoCheckerColumns = useMemo(() => [
        columnHelper.accessor('difficultyScore', {
            cell: (info) => {
                const score = info.getValue();
                return overview.isLoading || info.row.original.isLoading || score === null ? (
                    <CellLoading />
                ) : (
                    <DifficultyScoreCell score={score} isLoading={info.row.original.isLoading} />
                );
            },
            header: intl.formatMessage({ defaultMessage: 'Difficulté' }),
        }),
    ]);

    const columns = useMemo(() => [
        columnHelper.accessor('keyword', {
            cell: (info) => {
                return (
                    <PsoKeywordCell
                        keyword={info.getValue().keyword}
                        isLoading={info.row.original.isLoading}
                    />
                );
            },
            header: intl.formatMessage({ defaultMessage: 'Mot-clé' }),
            size: 480,
            minSize: 150,
            enableResizing: true,
        }),
        appleColumns,
        spotifyColumns,
        columnHelper.accessor('searchVolume', {
            cell: (info) => {
                const score = info.getValue();
                return overview.isLoading || info.row.original.isLoading || score === null ? (
                    <CellLoading />
                ) : (
                    <PsoSearchVolumeCell score={score} />
                );
            },
            header: intl.formatMessage({ defaultMessage: 'Vol. de Recherche' }),
            size: 140,
            enableResizing: false,
        }),
        ...(hasPsoChecker ? psoCheckerColumns : []),
        columnHelper.accessor('occurrences', {
            cell: (info) => {
                return overview.isLoading || info.row.original.isLoading ? (
                    <CellLoading />
                ) : (
                    <PsoMetadataCell
                        occurrences={info.getValue()}
                        keyword={info.row.original.keyword.keyword}
                    />
                );
            },
            header: intl.formatMessage({ defaultMessage: 'Métadonnées' }),
            sortingFn: (rowA, rowB, columnId, desc) => {
                // Calculate the sum of occurrences for rowA and rowB
                const sumOccurrencesA = Object.values(rowA.original.occurrences || {}).reduce(
                    (sum, current) => sum + current,
                    0,
                );
                const sumOccurrencesB = Object.values(rowB.original.occurrences || {}).reduce(
                    (sum, current) => sum + current,
                    0,
                );

                // Compare the sums to determine the order
                if (sumOccurrencesA > sumOccurrencesB) return desc ? -1 : 1;
                if (sumOccurrencesA < sumOccurrencesB) return desc ? 1 : -1;
                return 0; // Equal values
            },
            size: 120,
            enableResizing: false,
        }),
    ]);

    const table = useReactTable({
        data:
            overview?.data?.map((row) => ({
                ...row,
                keyword: { keyword: row.keyword },
                trendApple: {
                    trend: row.trendApple,
                    rankDiff: row.rankDiffApple,
                    missingData: !row.previousPositionApple || !row.currentPositionApple,
                },
                trendSpotify: {
                    trend: row.trendSpotify,
                    rankDiff: row.rankDiffSpotify,
                    missingData: !row.previousPositionSpotify || !row.currentPositionSpotify,
                },
            })) || [],
        columns,
        state: {
            sorting,
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: false,
        columnResizeMode: 'onChange',
    });

    const sortIconRender = (isSorted) => {
        return !isSorted ? null : isSorted === 'asc' ? (
            <FontAwesomeIcon icon={icon({ name: 'caret-up', style: 'solid' })} size="lg" />
        ) : (
            <FontAwesomeIcon icon={icon({ name: 'caret-down', style: 'solid' })} size="lg" />
        );
    };

    const shouldDisplayRightBorder = (key) => {
        return [
            'keyword',
            'trendApple',
            'trendSpotify',
            'searchVolume',
            'difficultyScore',
        ].includes(key);
    };

    if (overview.isLoading || overview.isError) return <DatatableSkeleton />;

    return (
        <TableWrapper>
            <Table>
                <Thead platformShouldCollapse={platformMinified}>
                    {table.getHeaderGroups().map((headerGroup, rowIndex) => (
                        <Tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <Th
                                        key={header.id}
                                        colSpan={header.colSpan}
                                        style={{
                                            width: header.getSize(),
                                            maxWidth: header.id === 'keyword' ? '100px' : '100%',
                                        }}
                                        rightBordered={shouldDisplayRightBorder(header.column.id)}
                                        platformShouldCollapse={platformMinified}
                                        isFirstRow={rowIndex === 0}
                                    >
                                        <TooltipTrigger
                                            delay={0}
                                            closeDelay={0}
                                            isDisabled={rowIndex === 0}
                                        >
                                            <TableHeaderTooltipTriggerWrapper>
                                                {header.isPlaceholder ? null : (
                                                    <ThContent
                                                        onClick={header.column.getToggleSortingHandler()}
                                                        sortable={header.column.getCanSort()}
                                                        sorted={header.column.getIsSorted()}
                                                    >
                                                        {flexRender(
                                                            header.column.columnDef.header,
                                                            header.getContext(),
                                                        )}

                                                        {sortIconRender(
                                                            header.column.getIsSorted(),
                                                        )}
                                                    </ThContent>
                                                )}
                                            </TableHeaderTooltipTriggerWrapper>
                                            <Tooltip placement="top">
                                                {headerTooltipContentLookup[header.id] || null}
                                            </Tooltip>
                                        </TooltipTrigger>
                                    </Th>
                                );
                            })}
                        </Tr>
                    ))}
                </Thead>
                <Tbody>
                    {table.getRowModel().rows.map((row) => {
                        return (
                            <Tr key={row.id}>
                                {row.getVisibleCells().map((cell) => {
                                    return (
                                        <Td
                                            key={cell.id}
                                            style={{
                                                width: cell.column.getSize(),
                                                maxWidth: cell.id.includes('keyword')
                                                    ? '100px'
                                                    : '100%',
                                            }}
                                            rightBordered={shouldDisplayRightBorder(
                                                cell.column.columnDef.accessorKey,
                                            )}
                                        >
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext(),
                                            )}
                                        </Td>
                                    );
                                })}
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>
        </TableWrapper>
    );
};

const TableWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    font-size: var(--fs-body-m);
`;
const Table = styled.table`
    border-collapse: collapse;
    background-color: var(--white);
    border-radius: var(--r-xs);
    table-layout: fixed;
`;
const Tr = styled.tr`
    &:not(:last-of-type) {
        border-bottom: 1px solid var(--neutral100);
    }
`;
const Th = styled.th`
    text-align: left;
    color: var(--neutral500);
    font-weight: var(--fw-semibold);
    padding-block: 0.5rem;
    padding-inline: 0.75rem;
    height: 3.5rem;

    ${({ platformShouldCollapse, isFirstRow }) =>
        isFirstRow &&
        platformShouldCollapse &&
        css`
            height: auto;
            padding-block: 0.5rem;
        `}

    ${({ rightBordered, platformShouldCollapse, isFirstRow }) =>
        !isFirstRow &&
        !platformShouldCollapse &&
        rightBordered &&
        `border-right: 1px solid var(--neutral100);`}
`;
const Td = styled.td`
    padding: 1rem;
    padding-right: 2rem;
    color: var(--black);
    font-weight: var(--fw-normal);
    height: 3.5rem;
    overflow: hidden;

    ${({ rightBordered }) => rightBordered && `border-right: 1px solid var(--neutral100);`}
`;
const Thead = styled.thead`
    border-bottom: 1px solid var(--neutral100);
    position: sticky;
    top: calc(40px + 64px + 0.5rem);

    ${({ platformShouldCollapse }) =>
        platformShouldCollapse &&
        css`
            background-color: var(--white);
            border-radius: var(--r-s);
            box-shadow: var(--s-medium);
            border-collapse: collapse;
        `}

    & > ${Tr} {
        border-bottom: 0;

        &:first-of-type {
            border-radius: var(--r-s) var(--r-s) 0 0;

            & > ${Th}:first-of-type {
                border-radius: var(--r-s) 0 0 0;
            }

            & > ${Th}:last-of-type {
                border-radius: 0 var(--r-s) 0 0;
            }
        }

        &:last-of-type {
            border-radius: 0 0 var(--r-s) var(--r-s);

            & > ${Th}:first-of-type {
                border-radius: 0 0 0 var(--r-s);
            }

            & > ${Th}:last-of-type {
                border-radius: 0 0 var(--r-s) 0;
            }
        }
    }
`;
const ThContent = styled.div`
    display: inline-flex;
    align-items: center;
    column-gap: 0.5rem;
    transition-duration: 0.2s;
    width: 100%;
    align-self: stretch;

    ${(props) =>
        props.sortable &&
        css`
            cursor: pointer;
        `}
`;
const PlatformHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    height: 2.5rem;
    border-radius: var(--r-s);
    font-weight: var(--fw-semibold);
    width: 100%;

    ${({ platform }) =>
        platform === 'itunes' &&
        css`
            background-color: var(--${APPLE_PODCASTS}50);
            color: var(--${APPLE_PODCASTS});
        `}

    ${({ platform }) =>
        platform === 'spotify' &&
        css`
            background-color: var(--${SPOTIFY}50);
            color: var(--${SPOTIFY});
        `}

    ${({ shouldCollapse }) =>
        shouldCollapse &&
        css`
            height: 0.5rem;
        `}

    ${({ platform, shouldCollapse }) =>
        shouldCollapse &&
        platform === 'itunes' &&
        css`
            background-color: var(--${APPLE_PODCASTS}300);
        `}

    ${({ platform, shouldCollapse }) =>
        shouldCollapse &&
        platform === 'spotify' &&
        css`
            background-color: var(--${SPOTIFY}300);
        `}
`;
const SmallerPlatformIcon = styled(PlatformIcon)`
    width: 1rem;
`;
const Tbody = styled.tbody``;
const TableHeaderTooltipTriggerWrapper = styled(TooltipTriggerWrapper)`
    width: 100%;
    color: var(--neutral500);
`;

PsoDatatable.propTypes = {
    hasAppleData: PropTypes.bool,
    hasSpotifyData: PropTypes.bool,
    currentStore: PropTypes.string,
};

PsoDatatable.defaultProps = {
    hasAppleData: false,
    hasSpotifyData: false,
};

export default PsoDatatable;
