import styled from 'styled-components';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Fragment, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface Step {
    state: 'inactive' | 'active' | 'completed';
    action?: () => void;
    label?: string;
}

interface StepperProps {
    steps: Step[];
    currentStep?: number;
    completeStepAutomatically?: boolean;
    className?: string;
}

const FormStepper = ({
    steps,
    currentStep,
    completeStepAutomatically,
    className,
}: StepperProps) => {
    if (steps.length === 0) return null;

    const [activeSteps, setActiveSteps] = useState<Step[]>([]);
    useEffect(() => {
        setActiveSteps(
            completeStepAutomatically && currentStep
                ? steps.filter((step, index) => step.state === 'active' || index <= currentStep)
                : steps.filter((step) => step.state === 'active'),
        );
    }, [steps, completeStepAutomatically]);

    const [completedSteps, setCompletedSteps] = useState<Step[]>([]);
    useEffect(() => {
        setCompletedSteps(
            completeStepAutomatically && currentStep
                ? steps.filter((step, index) => step.state === 'completed' || index < currentStep)
                : steps.filter((step) => step.state === 'completed'),
        );
    }, [steps, completeStepAutomatically]);

    return (
        <Wrapper className={className}>
            {steps.map((step, index) => (
                <Fragment key={index}>
                    {index > 0 && (
                        <BarLink
                            $isActive={
                                (completeStepAutomatically &&
                                    currentStep &&
                                    index <= currentStep) ||
                                (step.state !== 'inactive' && steps[index - 1].state !== 'inactive')
                            }
                        />
                    )}
                    <Step
                        $isActive={
                            activeSteps.some((activeStep) => steps.indexOf(activeStep) === index) ||
                            completedSteps.some(
                                (completedStep) => steps.indexOf(completedStep) === index,
                            )
                        }
                        onClick={step.action || undefined}
                    >
                        {completedSteps.some(
                            (completedStep) => steps.indexOf(completedStep) === index,
                        ) ? (
                            <StepIcon icon={icon({ name: 'circle-check', style: 'solid' })} />
                        ) : (
                            <StepIcon icon={icon({ name: 'circle', style: 'regular' })} />
                        )}
                        {step.label && <Label>{step.label}</Label>}
                    </Step>
                </Fragment>
            ))}
        </Wrapper>
    );
};

const Wrapper = styled.ul`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    list-style: none;
    gap: 0.5rem;
`;
const Step = styled.li<{ $isActive: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 0.5rem;
    color: ${({ $isActive }) => ($isActive ? 'var(--primary)' : 'var(--neutral200)')};
`;
const StepIcon = styled(FontAwesomeIcon)`
    font-size: 1rem;
`;
const BarLink = styled.div<{ $isActive: boolean }>`
    margin-top: 0.5rem;
    align-self: flex-start;
    flex-grow: 1;
    height: 0.125rem;
    width: 2rem;
    background-color: ${({ $isActive }) => ($isActive ? 'var(--primary)' : 'var(--neutral200)')};
    border-radius: var(--r-full);
`;
const Label = styled.span`
    font-size: var(--fs-body-m);
    font-weight: var(--fw-semibold);
`;

FormStepper.defaultProps = {
    completeStepAutomatically: false,
};

export default FormStepper;
