import { AICustomPromptFormSchema } from '@/components/EpisodeAI/EpisodeAICustomPrompt/EpisodeAICustomPromptForm/useAICustomPromptFormSchema.hook';
import { SocialNetwork } from '@/shared/config/constants';
import { AISocialNetwork } from './useAISocialPostsSuggestionsQuery';

const aiKeys = {
    all: () => [{ entity: 'ai' }],
    allDetails: () => [{ ...aiKeys.all()[0], scope: 'detail' }],
    title: () => [{ ...aiKeys.all()[0], subscope: 'title' }],
    titleDetails: (rawEpisodeId: string, settings: AICustomPromptFormSchema) => [
        { ...aiKeys.title()[0], episodeId: Number(rawEpisodeId), settings },
    ],
    description: () => [{ ...aiKeys.all()[0], subscope: 'description' }],
    descriptionDetails: (rawEpisodeId: string, settings: AICustomPromptFormSchema) => [
        {
            ...aiKeys.description()[0],
            episodeId: Number(rawEpisodeId),
            settings,
        },
    ],
    tags: () => [{ ...aiKeys.all()[0], subscope: 'tags' }],
    tagsDetails: (rawEpisodeId: string, settings: AICustomPromptFormSchema) => [
        { ...aiKeys.tags()[0], episodeId: Number(rawEpisodeId), settings },
    ],
    newsletterTitle: () => [{ ...aiKeys.all()[0], subscope: 'newsletter-title' }],
    newsletterMessage: () => [{ ...aiKeys.all()[0], subscope: 'newsletter-message' }],
    newsletterTitleDetails: (rawEpisodeId: string, settings: AICustomPromptFormSchema) => [
        {
            ...aiKeys.newsletterTitle()[0],
            episodeId: Number(rawEpisodeId),
            settings,
        },
    ],
    newsletterMessageDetails: (rawEpisodeId: string, settings: AICustomPromptFormSchema) => [
        {
            ...aiKeys.newsletterMessage()[0],
            episodeId: Number(rawEpisodeId),
            settings,
        },
    ],
    chapters: () => [{ ...aiKeys.all()[0], subscope: 'chapters' }],
    chaptersDetails: (rawEpisodeId: string) => [
        {
            ...aiKeys.chapters()[0],
            episodeId: Number(rawEpisodeId),
        },
    ],
    socialPost: () => [{ ...aiKeys.all()[0], subscope: 'socialPost' }],
    socialPostDetails: (
        rawEpisodeId: string,
        provider: SocialNetwork,
        settings: AICustomPromptFormSchema,
    ) => [
        {
            ...aiKeys.socialPost()[0],
            episodeId: Number(rawEpisodeId),
            provider,
            settings,
        },
    ],
    socialPosts: () => [{ ...aiKeys.all()[0], subscope: 'socialPosts' }],
    socialPostsDetails: (
        rawEpisodeId: string,
        settings: AICustomPromptFormSchema,
        socialNetworks: AISocialNetwork[],
    ) => [
        { ...aiKeys.socialPosts()[0], episodeId: Number(rawEpisodeId), settings, socialNetworks },
    ],
    summaryDetails: (rawEpisodeId: string) => [
        { ...aiKeys.allDetails()[0], episodeId: Number(rawEpisodeId), subscope: 'summary' },
    ],
    customPrompts: (rawShowId: string) => [
        { ...aiKeys.all()[0], showId: Number(rawShowId), subscope: 'customPrompts' },
    ],
};

export default aiKeys;
