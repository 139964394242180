import styled from 'styled-components';

const SidebarSection = styled.li`
    display: flex;
    gap: 1rem;
    flex-shrink: 0;

    &:not(:last-child) {
        margin-bottom: 1rem;
    }

    ${({ theme }) => theme.mediaQueries.desktopXLAndUp} {
        flex-direction: column;
    }
`;

export default SidebarSection;
