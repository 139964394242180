import Link from '@ui/atoms/Link';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Button from '@ui/atoms/Button';
import useInternalRouteMapping from '@/shared/hooks/useInternalRouteMapping.hook';

const TransparentButton = styled(Button)`
    background: transparent;
`;

const CancelButton = () => {
    const { link } = useInternalRouteMapping();

    return (
        <TransparentButton forwardedAs={Link} to={link('menu.episodes')} variant="secondary">
            <FormattedMessage defaultMessage="Annuler" />
        </TransparentButton>
    );
};

export default CancelButton;
