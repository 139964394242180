import { useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { useParams } from 'react-router-dom';
import Center from '@ui/layout/Center';
import { CLIP_FORMATS } from '@/shared/config/constants';
import Cluster from '@ui/layout/Cluster';
import SquareFormat from './SquareFormat';
import StoryFormat from './StoryFormat';
import LandscapeFormat from './LandscapeFormat';
import Stack from '@ui/layout/Stack';
import { connect } from '@/components/legacy/connect';
import Text from '@ui/atoms/Text';

const enhance = connect(({ clipStore }) => ({
    fetchClipQuota: clipStore.fetchClipQuota,
    clipQuota: clipStore.quota,
}));

const FormatSelection = ({ fetchClipQuota, clipQuota, ...otherProps }) => {
    const { showId } = useParams();
    const { values, setFieldValue } = useFormikContext();

    useEffect(() => {
        fetchClipQuota(showId);
    }, [showId]);

    const selectedFormats = useMemo(
        () => values.formats.map((format) => (format.format ? format.format : format)),
        [values.formats],
    );

    const selectedFormatsCount = useMemo(() => selectedFormats.length, [selectedFormats]);

    // Fallback to 0 if quota is missing or computation from quota is weird
    const remainingClipsCount = useMemo(
        () => (clipQuota ? Math.max(Math.floor(clipQuota.maximum - clipQuota.total), 0) : 0),
        [clipQuota],
    );

    const currentRemainingClipsCount = useMemo(
        () => remainingClipsCount - selectedFormatsCount,
        [remainingClipsCount, selectedFormatsCount],
    );

    const handleFormatSelection = (format) => {
        const formats = selectedFormats.includes(format)
            ? selectedFormats.filter((item) => item !== format)
            : [...selectedFormats, format];
        setFieldValue('formats', formats);
    };

    return (
        <Center max="max-content" {...otherProps}>
            <Stack $gap="1rem" $alignItems="center">
                <Stack $align="center" $gap="0.25rem">
                    <Text>
                        <FormattedMessage
                            defaultMessage="Crédit(s) clip restant(s) ce mois-ci : {clipsCount}"
                            values={{
                                clipsCount: (
                                    <Text
                                        as="strong"
                                        fontWeight="--fw-bold"
                                        color={currentRemainingClipsCount > 0 ? 'black' : 'error'}
                                    >
                                        <FormattedNumber value={currentRemainingClipsCount} />
                                    </Text>
                                ),
                            }}
                        />
                    </Text>
                    <Text color="--neutral500" variant="footnote">
                        <FormattedMessage defaultMessage="Pas de panique, vous récupérez tous vos crédits clip chaque 1er du mois 😇" />
                    </Text>
                </Stack>
                <Cluster $gap="2rem" $wrap="nowrap">
                    <SquareFormat
                        selected={selectedFormats.includes(CLIP_FORMATS.SQUARE)}
                        onSelect={handleFormatSelection}
                        selectedFormatsCount={selectedFormatsCount}
                        disabled={
                            !selectedFormats.includes(CLIP_FORMATS.SQUARE) &&
                            currentRemainingClipsCount <= 0
                        }
                    />
                    <StoryFormat
                        selected={selectedFormats.includes(CLIP_FORMATS.STORY)}
                        onSelect={handleFormatSelection}
                        selectedFormatsCount={selectedFormatsCount}
                        disabled={
                            !selectedFormats.includes(CLIP_FORMATS.STORY) &&
                            currentRemainingClipsCount <= 0
                        }
                    />
                    <LandscapeFormat
                        selected={selectedFormats.includes(CLIP_FORMATS.LANDSCAPE)}
                        onSelect={handleFormatSelection}
                        selectedFormatsCount={selectedFormatsCount}
                        disabled={
                            !selectedFormats.includes(CLIP_FORMATS.LANDSCAPE) &&
                            currentRemainingClipsCount <= 0
                        }
                    />
                </Cluster>
            </Stack>
        </Center>
    );
};

export default enhance(FormatSelection);
