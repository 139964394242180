import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Button from '@ui/atoms/Button';
import styled from 'styled-components';

export interface RuleProps {
    label: string | React.ReactNode;
    completion: number;
    thresholds?: [number, number, number]; // Enforcing the array to have exactly 3 elements;
    requiresTargetKeyword?: boolean;
    missingTargetKeyword?: boolean;
}

const UNFULFILLED = 'UNFULFILLED';
const PARTIAL = 'PARTIAL';
const FULFILLED = 'FULFILLED';

const Rule = ({
    label,
    completion,
    thresholds,
    requiresTargetKeyword,
    missingTargetKeyword,
}: RuleProps) => {
    const thresholdsOrDefault = thresholds ?? [0, 50, 100];

    const completionMap: {
        [key: number]: {
            state: 'UNFULFILLED' | 'PARTIAL' | 'FULFILLED';
            icon: React.ReactNode;
            color: string;
        };
    } = {
        0: {
            state: UNFULFILLED,
            icon: <FontAwesomeIcon icon={icon({ name: 'circle-dashed', style: 'solid' })} />,
            color: '--alert',
        },
        1: {
            state: PARTIAL,
            icon: <FontAwesomeIcon icon={icon({ name: 'circle-dashed', style: 'solid' })} />,
            color: '--warning',
        },
        2: {
            state: FULFILLED,
            icon: <FontAwesomeIcon icon={icon({ name: 'check-circle', style: 'solid' })} />,
            color: '--success',
        },
    };

    let completionIndex = 0;
    for (let i = 0; i < thresholdsOrDefault.length; i++) {
        if (thresholdsOrDefault[i] <= completion) {
            completionIndex = i;
        } else {
            break;
        }
    }

    const completionState = completionMap[completionIndex];

    return (
        <Wrapper>
            <RuleWrapper color={completionState.color}>
                {completionState.icon}
                {label}
            </RuleWrapper>

            {requiresTargetKeyword && missingTargetKeyword && (
                <MissingTargetKeywordWrapper>
                    <FontAwesomeIcon icon={icon({ name: 'bullseye-arrow', style: 'solid' })} />
                    <span>
                        <FormattedMessage
                            defaultMessage="Vous n’avez pas encore défini de mot-clé principal, <link>ajoutez en un dès maintenant</link>."
                            values={{
                                link: (chunks: string) => (
                                    // @ts-ignore
                                    <AddTargetKeywordButton
                                        variant="ghost"
                                        color="--primary"
                                        onPress={() => {
                                            document
                                                .querySelector('#target-keyword')
                                                ?.scrollIntoView({
                                                    behavior: 'smooth',
                                                });
                                        }}
                                    >
                                        {chunks}
                                    </AddTargetKeywordButton>
                                ),
                            }}
                        />
                    </span>
                </MissingTargetKeywordWrapper>
            )}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.125rem;
`;
const RuleWrapper = styled.div<{ color: string }>`
    display: inline-flex;
    gap: 0.5rem;
    color: var(${(props) => props.color});
    font-size: var(--fs-body-m);

    & svg {
        margin-top: 0.2rem;
    }
`;
const MissingTargetKeywordWrapper = styled.div`
    width: calc(100% - 1.25rem);
    margin-left: 1.25rem;
    background-color: var(--neutral50);
    border-radius: 4px 12px 12px 12px;
    color: var(--neutral500);
    display: flex;
    gap: 0.5rem;
    padding-block: 0.5rem;
    padding-inline: 1rem;

    & svg {
        margin-top: 0.2rem;
    }
`;
const AddTargetKeywordButton = styled(Button)`
    padding: 0;
`;

export default Rule;
