import Spinner from '@/components/ui/atoms/Spinner';
import Text from '@/components/ui/atoms/Text';
import Cluster from '@/components/ui/layout/Cluster';
import Stack from '@/components/ui/layout/Stack';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import styled from 'styled-components';

const EpisodeEditResumeAudioFileUploading = ({ file }) => {
    return (
        <Cluster $gap="1rem" $align="center">
            <Spinner size={32} />
            <Stack>
                <Title variant="bodyM" numberOfLines={1}>
                    {file.name}
                </Title>
                <Cluster $gap="0.5rem" $align="center">
                    <Text variant="bodyM" color="--neutral500" numberOfLines={1}>
                        <FormattedNumber
                            value={file.size}
                            style="unit"
                            unit="byte"
                            unitDisplay="narrow"
                            notation="compact"
                        />
                    </Text>
                    <BulletIcon icon={icon({ name: 'circle', style: 'solid' })} />
                    <Text variant="bodyM" color="--neutral500" numberOfLines={1}>
                        <FormattedMessage defaultMessage="En cours de téléchargement" />
                    </Text>
                </Cluster>
            </Stack>
        </Cluster>
    );
};

const BulletIcon = styled(FontAwesomeIcon)`
    width: 0.25rem;
    height: 0.25rem;
    color: var(--neutral200);
    flex: none;
`;
const Title = styled(Text)`
    font-weight: var(--fw-semibold);
    max-width: 250px;

    ${({ theme }) => theme.mediaQueries.mobile} {
        max-width: 200px;
    }
`;
export default EpisodeEditResumeAudioFileUploading;
