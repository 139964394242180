import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateShowGuestsPermission } from '@/api';
import { GuestUserRole } from '@/utils/constants';
import camelcaseKeys from 'camelcase-keys';
import guestKeys from './guestKeys';
import { ShowGuestPermission } from './useShowGuestPermissionsQuery.hook';

export interface UpdateShowGuestPermissionMutationFnProps {
    permissionId: number;
    role: GuestUserRole;
}

const mutationFn = async ({
    permissionId,
    role,
}: UpdateShowGuestPermissionMutationFnProps): Promise<ShowGuestPermission> => {
    const { data } = await updateShowGuestsPermission(permissionId, { role });

    return camelcaseKeys(data as any, { deep: true });
};

const updateShowGuestPermissionMutation = () => {
    const queryclient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: ({ showId }) => {
            queryclient.invalidateQueries({
                queryKey: guestKeys.permissionListByShowId(showId),
            });
        },
    });
};

export default updateShowGuestPermissionMutation;
