import { useContext } from 'react';
import Responsive from '@/context/responsive.context';

const Desktop = ({ children }) => {
    const { isDesktop } = useContext(Responsive);
    if (!isDesktop) return null;
    return <>{children}</>;
};

export default Desktop;
