import { createFetcher } from './Fetcher';

const FetcherBrowsers = createFetcher(
    ({ sourcesStatsStore }, { showId, start, end, itemType, itemId, page }) => ({
        fetch: () =>
            sourcesStatsStore.fetchBrowsers(showId, itemType, itemId, { start, end, page }),
        isLoading: sourcesStatsStore.fetchBrowsers.pending,
        input: [showId, start, end, itemType, itemId, page],
    }),
);

FetcherBrowsers.defaultProps = {
    page: 1,
};

export default FetcherBrowsers;
