import ListBoxItem from '@/components/ui/atoms/ListBoxItem';
import SelectDropdown from '@/components/ui/atoms/Select/SelectDropdown';
import Text from '@/components/ui/atoms/Text';
import useSubscriptionQuery from '@/queries/subscription/useSubscriptionQuery.hook';
import { PRICING } from '@/shared/config/pricing';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ListBox, Select, SelectValue } from 'react-aria-components';
import styled from 'styled-components';
import useIpLocationQuery from '@/queries/external/useIpLocationQuery.hook';
import useCurrencyByCountryCode from '@/shared/hooks/useCurrencyByGeographicArea';
import { useEffect, useState } from 'react';

const CURRENCY_DISPLAY_VALUES = {
    EUR: 'EUR (€)',
    USD: 'USD ($)',
    GBP: 'GBP (£)',
};

// TODO: Handle currency change
const Currency = () => {
    const { data: subscription } = useSubscriptionQuery();
    const { data: ipLocation } = useIpLocationQuery();
    const locationCurrency = useCurrencyByCountryCode(ipLocation?.countryCode);
    const [selectedCurrency, setSelectedCurrency] = useState<'eur' | 'usd' | 'gbp'>(
        locationCurrency ?? 'usd',
    );

    /* If the user has no active subscription, set the currency based on the his location */
    useEffect(() => {
        if (
            subscription?.isFreemium &&
            subscription?.pricing === PRICING.LAUNCH &&
            locationCurrency
        ) {
            setSelectedCurrency(locationCurrency as 'eur' | 'usd' | 'gbp');
        }
    }, [subscription, locationCurrency]);

    if (!subscription) return null;

    const subscriptionCurrency = subscription?.currency;
    const isFreemium = subscription.isFreemium && subscription.pricing === PRICING.LAUNCH;

    const CURRENCIES = [
        {
            id: 'eur',
            name: 'EUR',
            icon: <CurrencyIcon icon={icon({ name: 'euro-sign', style: 'solid' })} />,
        },
        {
            id: 'usd',
            name: 'USD',
            icon: <CurrencyIcon icon={icon({ name: 'dollar-sign', style: 'solid' })} />,
        },
        {
            id: 'gbp',
            name: 'GBP',
            icon: <CurrencyIcon icon={icon({ name: 'pound-sign', style: 'solid' })} />,
        },
    ];

    /* Subscription is active, the user cannot change the currency */
    if (!isFreemium) {
        return (
            <Text fontWeight="--fw-semibold" color="--neutral500">
                {
                    CURRENCY_DISPLAY_VALUES[
                        subscriptionCurrency?.toUpperCase() as keyof typeof CURRENCY_DISPLAY_VALUES
                    ]
                }
            </Text>
        );
    }

    return (
        <Select
            selectedKey={selectedCurrency}
            onSelectionChange={(key) => {
                setSelectedCurrency(key as 'eur' | 'usd' | 'gbp');
                localStorage.setItem('currency', key as string);
            }}
        >
            <SelectButton>
                <SelectValue>
                    {({ defaultChildren, isPlaceholder, selectedText }) => {
                        if (isPlaceholder) {
                            return defaultChildren;
                        }

                        return selectedText
                            ? CURRENCY_DISPLAY_VALUES[
                                  selectedText as keyof typeof CURRENCY_DISPLAY_VALUES
                              ]
                            : defaultChildren;
                    }}
                </SelectValue>
                <ChevronIcon icon={icon({ name: 'chevron-down', style: 'solid' })} />
            </SelectButton>
            <Dropdown shouldFlip={false} placement="bottom" offset={4}>
                <ListBox>
                    {CURRENCIES.map((currency) => (
                        <ListBoxItem key={currency.id} {...currency} />
                    ))}
                </ListBox>
            </Dropdown>
        </Select>
    );
};

const SelectButton = styled(Button)`
    background: none;
    border: none;
    padding: 0;
    color: var(--neutral500);
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    font-weight: var(--fw-semibold);

    [slot='label'] {
        color: var(--neutral500);
        font-weight: var(--fw-semibold);
    }
`;
const ChevronIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const CurrencyIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const Dropdown = styled(SelectDropdown)`
    max-width: fit-content;
`;

export default Currency;
