import Badge from '@/components/ui/atoms/Badge';
import { CAMPAIGN_STATUS } from '@/shared/config/constants';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';

const statusToBadgeLookup = {
    [CAMPAIGN_STATUS.ACTIVE]: {
        children: <FormattedMessage defaultMessage="Campagne en ligne" />,
        variant: 'success',
        icon: <FontAwesomeIcon icon={icon({ name: 'pen-line', style: 'solid' })} />,
    },
    [CAMPAIGN_STATUS.PLANIFIED]: {
        children: <FormattedMessage defaultMessage="Campagne planifiée" />,
        variant: 'info',
        icon: <FontAwesomeIcon icon={icon({ name: 'clock', style: 'solid' })} />,
    },
    [CAMPAIGN_STATUS.DRAFT]: {
        children: <FormattedMessage defaultMessage="Campagne en brouillon" />,
        variant: 'neutral',
        icon: <FontAwesomeIcon icon={icon({ name: 'pen-ruler', style: 'solid' })} />,
    },
    [CAMPAIGN_STATUS.BLOCKED]: {
        children: <FormattedMessage defaultMessage="Campagne bloquée" />,
        variant: 'alert',
        icon: <FontAwesomeIcon icon={icon({ name: 'lock', style: 'solid' })} />,
    },
    [CAMPAIGN_STATUS.FINISHED]: {
        children: <FormattedMessage defaultMessage="Campagne terminée" />,
        variant: 'primary',
        icon: <FontAwesomeIcon icon={icon({ name: 'check-circle', style: 'solid' })} />,
    },
    [CAMPAIGN_STATUS.PAUSED]: {
        children: <FormattedMessage defaultMessage="Campagne en pause" />,
        variant: 'warning',
        icon: <FontAwesomeIcon icon={icon({ name: 'pause', style: 'solid' })} />,
    },
};

interface StatusProps {
    status: string;
}

const Status = ({ status }: StatusProps) => {
    if (!status) return null;
    return <Badge {...statusToBadgeLookup[status]} />;
};

export default Status;
