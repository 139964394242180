import { useState } from 'react';
import Button from '@/components/Button';
import InputText from '@/components/ui/atoms/InputText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import Stack from '@/components/ui/layout/Stack';
import Alert from '@/components/ui/atoms/Alert';
import Text from '@/components/ui/atoms/Text';

interface PersonalAccessTokenPreviewProps {
    token: string;
}

const PersonalAccessTokenPreview = ({ token }: PersonalAccessTokenPreviewProps) => {
    const [hasCopiedToClipboard, setHasCopiedToClipboard] = useState(false);

    const handleCopyToClipboard = async () => {
        await navigator.clipboard.writeText(token);
        setHasCopiedToClipboard(true);
    };

    return (
        <Wrapper>
            <Stack $gap="0.75rem">
                <InputText
                    label={<FormattedMessage defaultMessage="Votre nouveau jeton d’accès" />}
                    value={token}
                    isReadOnly={true}
                />
                <CopyButton
                    variant="tertiary"
                    size="small"
                    startIcon={<CopyIcon icon={icon({ name: 'copy', style: 'solid' })} />}
                    onPress={handleCopyToClipboard}
                >
                    <AnimatePresence>
                        {hasCopiedToClipboard ? (
                            <motion.span
                                initial={{ opacity: 1 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ delay: 1 }}
                                onAnimationComplete={() => setHasCopiedToClipboard(false)}
                            >
                                <FormattedMessage defaultMessage="Copié" />
                            </motion.span>
                        ) : (
                            <FormattedMessage defaultMessage="Copier" />
                        )}
                    </AnimatePresence>
                </CopyButton>
            </Stack>
            <Alert variant="info" defaultIcon>
                <Text fontWeight="--fw-semibold">
                    <FormattedMessage defaultMessage="Veuillez copier le jeton maintenant. Il n’apparaîtra plus ensuite." />
                </Text>
            </Alert>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    padding-left: 1.5rem;
    border-left: solid 1px var(--neutral200);
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
`;
const CopyIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
`;
const CopyButton = styled(Button)`
    background: transparent;
    align-self: end;
`;

export default PersonalAccessTokenPreview;
