import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import DeprecatedButton from '@ui/atoms/DeprecatedButton';
import DeleteAccountModal from '@/components/unorganized/DeleteAccountModal';
import { useAmplitudeLogEvent } from '@/shared/hooks/useAmplitude';
import UnsubscribeSurveyModal from '@/components/unorganized/UnsubscribeSurveyModal';
import { ENABLE_UNSUBSCRIBE_SURVEY } from '@/shared/config/constants';
import Link from '@ui/atoms/Link';
import IconInfo from '@ui/icons/IconInfo';
import Text from '@ui/atoms/Text';
import Alert from '@ui/atoms/Alert';
import styled from 'styled-components';
import { useOverlayTriggerState } from 'react-stately';
import { CurrentShow } from '@/context/CurrentShowContext';
import useSubscriptionQuery from '@queries/subscription/useSubscriptionQuery.hook';

const DeleteAccount = () => {
    useAmplitudeLogEvent('show delete account');
    const show = useContext(CurrentShow);
    const { data: subscription } = useSubscriptionQuery();
    const subscriptionIsActive = !!(show?.ownerPricing || subscription?.activated);

    // User have an active subscription and should cancel it to be able to delete his account
    const subscriptionCancellationRequired = subscriptionIsActive && !subscription?.endsAt;
    // User does not have a subscription to cancel and unsubscribe survey should be displayed at account deletion
    const answerSurveyBeforeDeletion = ENABLE_UNSUBSCRIBE_SURVEY && subscription?.isFreemium;

    let unsuscribeSurveyModalState = useOverlayTriggerState({});
    let deleteUserModalState = useOverlayTriggerState({});

    const startUserDeletion = () => {
        unsuscribeSurveyModalState.close();
        deleteUserModalState.open();
    };

    return (
        <>
            <DeleteAccountWrapper>
                <DeprecatedText weight="semibold" mb={5}>
                    <FormattedMessage defaultMessage="Supprimer votre compte" />
                </DeprecatedText>
                <DeprecatedText>
                    <FormattedMessage defaultMessage="Cette action supprimera votre compte utilisateur ainsi que l’ensemble des émissions et des épisodes de votre compte." />
                </DeprecatedText>
                <DeprecatedText>
                    <FormattedMessage defaultMessage="Ceci est immédiat et irréversible." />
                </DeprecatedText>
                {subscriptionCancellationRequired && (
                    <>
                        <Spacer />
                        <Alert variant="info" icon={<IconInfo />}>
                            <Text as="span" variant="footnote">
                                <FormattedMessage
                                    defaultMessage="Pour supprimer votre compte, vous devez d’abord résilier votre {subscription}."
                                    values={{
                                        subscription: (
                                            <Text
                                                as={Link}
                                                to={`/app/subscription/offers`}
                                                variant="footnote"
                                                color="--info"
                                            >
                                                <FormattedMessage defaultMessage="abonnement" />
                                            </Text>
                                        ),
                                    }}
                                />
                            </Text>
                        </Alert>
                    </>
                )}
                <DeprecatedButton
                    accent="secondary"
                    onClick={
                        answerSurveyBeforeDeletion
                            ? unsuscribeSurveyModalState.open
                            : deleteUserModalState.open
                    }
                    mt={15}
                    disabled={subscriptionCancellationRequired}
                >
                    <FormattedMessage defaultMessage="Supprimer mon compte" />
                </DeprecatedButton>
            </DeleteAccountWrapper>
            {deleteUserModalState.isOpen && (
                <DeleteAccountModal
                    isOpen={deleteUserModalState.isOpen}
                    onClose={deleteUserModalState.close}
                />
            )}
            {unsuscribeSurveyModalState.isOpen && (
                <UnsubscribeSurveyModal
                    onAfterSubmit={startUserDeletion}
                    onClose={unsuscribeSurveyModalState.close}
                    isOpen={unsuscribeSurveyModalState.isOpen}
                />
            )}
        </>
    );
};

const Spacer = styled.div`
    height: 1rem;
    width: 100%;
`;
const DeleteAccountWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding);

    ${({ theme }) => theme.mediaQueries.mobile} {
        padding: var(--container-padding-mobile);
    }
`;

export default DeleteAccount;
