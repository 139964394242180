import { createRef, useState } from 'react';
import { FormattedDate, FormattedNumber } from 'react-intl';
import styled from 'styled-components';
import DeprecatedWhitePaper from '@ui/atoms/DeprecatedWhitePaper';
import DeprecatedText from '@ui/atoms/DeprecatedText';

const PaperStyled = styled(DeprecatedWhitePaper)`
    &:last-of-type {
        border-right: 0;
    }
    &:first-of-type {
        border-left: 0;
    }
`;

function ListenersVariationGraphTableColumn({
    isActive,
    onHover,
    style,
    step,
    variationsFormatted,
}) {
    const ref = createRef();
    const [_, setHover] = useState(false);

    let variationColor;
    if (variationsFormatted.variation === '-') {
        variationColor = '--black';
    } else if (variationsFormatted.variation === 0) {
        variationColor = '--neutral500';
    } else if (variationsFormatted.variation > 0) {
        variationColor = '--success';
    } else {
        variationColor = '--alert';
    }

    let variation;
    if (variationsFormatted.variation > 0) {
        variation = `+${Math.round(variationsFormatted.variation)}%`;
    } else if (variationsFormatted.variation === '-') {
        variation = '-';
    } else if (variationsFormatted.variation === 0) {
        variation = `0%`;
    } else {
        variation = `${Math.round(variationsFormatted.variation)}%`;
    }

    const formatDataVariation = {
        days: (
            <FormattedDate
                value={variationsFormatted.timestamp}
                day="2-digit"
                month="2-digit"
                year="numeric"
            />
        ),
        weeks: variationsFormatted.weeks,
        months: <FormattedDate value={variationsFormatted.timestamp} month="long" year="numeric" />,
        years: <FormattedDate value={variationsFormatted.timestamp} year="numeric" />,
    };

    return (
        <PaperStyled
            ref={ref}
            onMouseEnter={() => {
                onHover(variationsFormatted);
                setHover(true);
            }}
            onMouseLeave={() => {
                onHover(null);
                setHover(false);
            }}
            w={115}
            rounded={false}
            bl={1}
            style={{ ...style, display: 'inline-block' }}
            css="border-style: solid;"
        >
            <DeprecatedWhitePaper
                bb={1}
                rounded={false}
                py={15}
                border="--neutral100"
                background={isActive ? '--neutral100' : '--neutral50'}
                height="49px"
                css="border-style: solid;"
            >
                <DeprecatedText color="--neutral500" align="center">
                    {formatDataVariation[step]}
                </DeprecatedText>
            </DeprecatedWhitePaper>
            <DeprecatedWhitePaper
                bb={1}
                rounded={false}
                py={15}
                border="--neutral100"
                background={isActive ? '--neutral50' : 'white'}
                height="50px"
                css="border-style: solid;"
            >
                <DeprecatedText align="center">
                    <FormattedNumber value={variationsFormatted.downloads} />
                </DeprecatedText>
            </DeprecatedWhitePaper>
            <DeprecatedWhitePaper
                bb={1}
                rounded={false}
                py={15}
                border="--neutral100"
                background={isActive ? '--neutral50' : 'white'}
                height="50px"
                css="border-style: solid;"
            >
                <DeprecatedText align="center" color={variationColor}>
                    {variation}
                </DeprecatedText>
            </DeprecatedWhitePaper>
        </PaperStyled>
    );
}

export default ListenersVariationGraphTableColumn;
