import { useMutation, useQueryClient } from '@tanstack/react-query';
import aiKeys from '@queries/ai/aiKeys';
import camelcaseKeys from 'camelcase-keys';
import { updateAICustomPrompts } from '@/api';
import { FieldPath } from 'react-hook-form';
import { AISettingsSchema } from '@/components/AISettings/AISettingsForm/useAISettingsSchema.hook';

export interface CustomPrompt {
    field: FieldPath<AISettingsSchema> | 'tone_of_voice';
    prompt: string;
}

interface useUpdateAICustomPromptsMutationFnProps {
    showId: string;
    customPrompts: CustomPrompt[];
}

const mutationFn = async ({ showId, customPrompts }: useUpdateAICustomPromptsMutationFnProps) => {
    const { data } = await updateAICustomPrompts(Number(showId), customPrompts);
    return camelcaseKeys(data, { deep: true });
};

const useUpdateAICustomPromptsMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: (_, { showId }) => {
            queryClient.invalidateQueries({
                queryKey: aiKeys.customPrompts(showId),
            });
        },
    });
};

export default useUpdateAICustomPromptsMutation;
