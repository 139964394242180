import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { connect } from '@app/decorators/connect';
import player from '@public/icons/player-active.svg';
import linkIcon from '@public/icons/link-symbol.svg';
import more from '@public/icons/dotted.svg';
import trash from '@public/icons/trash.svg';
import edit from '@public/icons/edit.svg';
import Link from '../../ui/atoms/Link';
import DeprecatedPaper from '../../ui/atoms/DeprecatedPaper';
import DeprecatedIcon from '../../ui/atoms/DeprecatedIcon';
import useDroppable from '../../../utils/hooks/useDroppable';
import Menu from '../../ui/atoms/Menu';
import MenuItem from '../../ui/atoms/MenuItem';
import { useSwitch } from '@hooks/useSwitch';
import PlaylistDeleteModal from './PlaylistDeleteModal';
import EditPlaylistModal from '../organisms/EditPlaylistModal';
import HideFor from '../atoms/HideFor';
import { STATUS_SHOWS } from '../../../utils/statusShows';
import { useOverlayTriggerState } from 'react-stately';
import useShowQuery from '@/queries/show/useShowQuery.hook';

const enhance = connect(({ routerStore }, { playlist }) => {
    const show = routerStore.resolve('showId');
    return {
        playerLink: routerStore.link('menu.player', { type: 'playlists', id: playlist.slug }),
        playlistLink: show && `${playlist.url}`,
    };
});

function PlaylistLineMenu({ playlist, iconColor, playerLink, playlistLink, ...props }) {
    const { showId } = useParams();
    const { data: show } = useShowQuery(showId);

    const [ref, opened, onOpen] = useDroppable();
    const [isOpenedDelete, onOpenDelete, onCloseDelete] = useSwitch(false);
    const editPlaylistModal = useOverlayTriggerState({});

    return (
        <DeprecatedPaper pos="relative" w={null} {...props}>
            <DeprecatedIcon as={more} size={14} color={iconColor} onClick={onOpen} />
            <Menu ref={ref} key={0} opened={opened}>
                <HideFor showStatus={[STATUS_SHOWS.ARCHIVED_SHOWS]}>
                    {show && show.userRole !== 'viewer' && (
                        <MenuItem onClick={editPlaylistModal.open}>
                            <DeprecatedIcon as={edit} size={13} />
                            <FormattedMessage defaultMessage="Modifier" />
                        </MenuItem>
                    )}
                </HideFor>
                <MenuItem href={playlistLink} as="a" target="_blank">
                    <DeprecatedIcon as={linkIcon} size={13} />
                    <FormattedMessage defaultMessage="Site Web" />
                </MenuItem>
                <MenuItem as={Link} to={playerLink}>
                    <DeprecatedIcon as={player} size={13} />
                    <FormattedMessage defaultMessage="Smartplayer" />
                </MenuItem>
                <HideFor showStatus={[STATUS_SHOWS.ARCHIVED_SHOWS]}>
                    {show && show.userRole !== 'viewer' && (
                        <>
                            <hr />
                            <MenuItem onClick={onOpenDelete}>
                                <DeprecatedIcon as={trash} size={13} />
                                <FormattedMessage defaultMessage="Supprimer" />
                            </MenuItem>
                        </>
                    )}
                </HideFor>
            </Menu>
            <PlaylistDeleteModal
                playlist={playlist}
                onClose={onCloseDelete}
                isOpened={isOpenedDelete}
            />
            {editPlaylistModal.isOpen && (
                <EditPlaylistModal
                    playlistId={playlist.id}
                    onClose={editPlaylistModal.close}
                    isOpen={editPlaylistModal.isOpen}
                />
            )}
        </DeprecatedPaper>
    );
}

export default enhance(PlaylistLineMenu);
