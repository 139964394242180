import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addSeason } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import seasonKeys from '@/queries/season/seasonKeys';

export interface MutationFnProps {
    showId: string;
}

const mutationFn = async ({ showId }: MutationFnProps) => {
    const { data } = await addSeason(showId);
    const { podcasts_count, ...rest } = data;
    return camelcaseKeys(
        {
            ...rest,
            episodesCount: podcasts_count,
        },
        { deep: true },
    );
};

const useAddSeasonMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: seasonKeys.allLists() });
        },
    });
};

export default useAddSeasonMutation;
