import { FormattedMessage } from 'react-intl';
import Link from '@ui/atoms/Link';
import DeprecatedButton from '@ui/atoms/DeprecatedButton';
import CongratulateEpisodeSummaryActive from './CongratulateEpisodeSummaryActive';
import CongratulateEpisodeSummaryScheduled from './CongratulateEpisodeSummaryScheduled';
import styled from 'styled-components';
import useEpisodeQuery from '@/queries/episode/useEpisodeQuery.hook';
import { useParams } from 'react-router-dom';
import useInternalRouteMapping from '@/utils/hooks/useInternalRouteMapping.hook';
import { EPISODE_STATUS } from '@/utils/constants';

const CongratulateEpisodeSummaryWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
`;

const congratsStatusComponent = {
    [EPISODE_STATUS.ONLINE]: <CongratulateEpisodeSummaryActive />,
    [EPISODE_STATUS.SCHEDULED]: <CongratulateEpisodeSummaryScheduled />,
};

function CongratulateEpisodeSummary() {
    const { episodeId } = useParams();
    const { data: episode } = useEpisodeQuery({ episodeId });
    const { link } = useInternalRouteMapping();

    return (
        <CongratulateEpisodeSummaryWrapper>
            {congratsStatusComponent[episode?.state]}
            <DeprecatedButton m="auto" accent="secondary" as={Link} to={link('menu.episodes')}>
                <FormattedMessage defaultMessage="Retour à la liste d’épisodes" />
            </DeprecatedButton>
        </CongratulateEpisodeSummaryWrapper>
    );
}

export default CongratulateEpisodeSummary;
