import { Redirect, Route, useParams } from 'react-router';
import { useAuthContext } from '@/context/AuthContext';
import useSubscriptionQuery from '@/queries/subscription/useSubscriptionQuery.hook';
import dayjs from 'dayjs';

export const AuthRoute = (props) => {
    const { isAuthenticated } = useAuthContext();
    if (!isAuthenticated) {
        return <Redirect to="/login" />;
    }
    return <Route {...props} />;
};

export const ValidSubscriptionRoute = (props) => {
    const { showId } = useParams();
    const { isAuthenticated } = useAuthContext();
    const { data: subscription } = useSubscriptionQuery();
    const subscriptionIsBlockedForShow =
        !!subscription?.endsAt && dayjs(subscription?.endsAt).isBefore(dayjs(), 'day');

    if (isAuthenticated && subscriptionIsBlockedForShow) {
        return <Redirect to={showId ? `/app/show/${showId}/episodes` : '/app'} />;
    }

    return <Route {...props} />;
};
