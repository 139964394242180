import Text from '@/components/ui/atoms/Text';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import { IMG_PLACEHOLDER, USER_ROLES } from '@/shared/config/constants';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router';
import styled from 'styled-components';
import ShowButtonSkeleton from './ShowButtonSkeleton';
import { Button as RACButton } from 'react-aria-components';
import { defineMessages, FormattedMessage } from 'react-intl';
import { useCurrentShow } from '@/context/CurrentShowContext';

const ROLE_TRANSLATIONS = defineMessages({
    [USER_ROLES.VIEWER]: {
        defaultMessage: 'Lecteur',
    },
    [USER_ROLES.EDITOR]: {
        defaultMessage: 'Éditeur',
    },
    [USER_ROLES.ADMIN]: {
        defaultMessage: 'Administrateur',
    },
});

interface ShowButtonProps {
    onDropdownOpenChange: React.Dispatch<React.SetStateAction<boolean>>;
    triggerRef: React.RefObject<HTMLButtonElement>;
}

const ShowButton = ({ onDropdownOpenChange, triggerRef }: ShowButtonProps) => {
    const { showId } = useParams<{ showId: string }>();
    const { showId: storedShowId } = useCurrentShow();
    const { data: show, isLoading } = useShowQuery(showId || storedShowId);

    if (!!showId && isLoading) {
        return <ShowButtonSkeleton />;
    }

    if (show) {
        return (
            <Wrapper onPress={() => onDropdownOpenChange((prev) => !prev)} ref={triggerRef}>
                <CoverWrapper>
                    <Cover src={show?.imageUrl ?? IMG_PLACEHOLDER} />
                </CoverWrapper>
                <ShowInfo>
                    <Semibold>{show?.name}</Semibold>
                    <UserRole>
                        {show?.userRole === USER_ROLES.OWNER ? (
                            <FormattedMessage defaultMessage="Propriétaire" />
                        ) : (
                            <>
                                <FormattedMessage defaultMessage="Invité" />
                                <Circle />
                                <FormattedMessage
                                    {...ROLE_TRANSLATIONS[
                                        show?.userRole as keyof typeof ROLE_TRANSLATIONS
                                    ]}
                                />
                            </>
                        )}
                    </UserRole>
                </ShowInfo>
                <Icon icon={icon({ name: 'angles-up-down', style: 'solid' })} />
            </Wrapper>
        );
    }

    return (
        <Wrapper onPress={() => onDropdownOpenChange((prev) => !prev)} ref={triggerRef}>
            <CoverWrapper>
                <Cover src={IMG_PLACEHOLDER} />
            </CoverWrapper>
            <Placeholder data-testid="shows-menu-trigger-button">
                <FormattedMessage defaultMessage="Sélectionner une émission" />
            </Placeholder>
            <Icon icon={icon({ name: 'angles-up-down', style: 'solid' })} />
        </Wrapper>
    );
};

const Wrapper = styled(RACButton)`
    max-width: 17.5rem;
    display: flex;
    column-gap: 0.75rem;
    cursor: pointer;
    padding: 0.75rem;
    background: none;
    border: none;
    flex-grow: 1;
    flex-shrink: 0;
    height: 100%;

    & > :nth-child(2) {
        width: fit-content;
        max-width: 11.25rem;
    }

    ${({ theme }) => theme.mediaQueries.mobile} {
        max-width: fit-content;
    }
`;
const CoverWrapper = styled.div`
    width: 2.5rem;
    height: 2.5rem;
    position: relative;

    &::before {
        content: '';
        display: block;
        width: 2rem;
        height: 2rem;
        border-radius: var(--r-xs);
        background-color: var(--neutral100);
        position: absolute;
        bottom: -0.25rem;
        left: 50%;
        transform: translateX(-50%);
        z-index: 0;
    }
`;
const Cover = styled.img`
    width: 100%;
    height: 100%;
    border-radius: var(--r-s);
    flex-shrink: 0;
    z-index: 1;
    position: relative;
`;
const ShowInfo = styled.div`
    display: none;

    ${({ theme }) => theme.mediaQueries.tabletAndUp} {
        display: flex;
        flex-direction: column;
        text-align: left;
        min-width: 0;

        & > :first-child {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
`;
const Placeholder = styled(Text)`
    align-self: center;
    text-align: left;
    font-weight: var(--fw-semibold);
    color: var(--neutral500);
`;
const Icon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: var(--neutral500);
    align-self: center;
`;
const Circle = styled.div`
    width: 0.25rem;
    height: 0.25rem;
    border-radius: var(--r-full);
    background-color: var(--neutral200);
`;
const UserRole = styled(Text)`
    color: var(--neutral500);
    display: flex;
    align-items: center;
    column-gap: 0.25rem;
`;
const Semibold = styled.span`
    font-weight: var(--fw-semibold);
`;

export default ShowButton;
