import { useMutation } from '@tanstack/react-query';
import { deletePlaylistImage } from '@/api';
import camelcaseKeys from 'camelcase-keys';

const mutationFn = async (playlistId: string) => {
    const { data } = await deletePlaylistImage(playlistId);

    return camelcaseKeys(data as any, { deep: true });
};

const deletePlaylistImageMutation = () => {
    return useMutation({
        mutationFn,
    });
};

export default deletePlaylistImageMutation;
