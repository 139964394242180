import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updatePodcastNewsletterTemplate } from '@/api';
import { decamelizeKeys } from 'humps';
import camelcaseKeys from 'camelcase-keys';
import newsletterKeys from '@queries/newsletter/newsletterKeys';

const transform = (data) => {
    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const mutationFn = async ({ episodeId, template }) => {
    const { activated, title, ...restTemplate } = template;
    const templateData = { ...restTemplate, params: { activated, title } };

    const { data } = await updatePodcastNewsletterTemplate(episodeId, decamelizeKeys(templateData));

    return transform(data);
};

export default function useUpdateEpisodeNewsletterTemplateMutation() {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn,
        onSuccess: (data, { episodeId }) => {
            return queryClient.invalidateQueries({
                queryKey: newsletterKeys.detailByEpisodeId(episodeId),
            });
        },
    });
}
