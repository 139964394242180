import { FC } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Text from '@ui/atoms/Text';

interface NoDataChartProps {
    title?: string | React.ReactNode;
    message?: string | React.ReactNode;
}

const NoDataChart: FC<NoDataChartProps> = ({ title, message }) => (
    <Container>
        <Icon icon={icon({ name: 'zzz', style: 'solid' })} />
        <WordingContainer>
            <Text textAlign="center" fontWeight="--fw-semibold">
                {title ?? <FormattedMessage defaultMessage="Vous n’avez pas encore d’écoute" />}
            </Text>
            <Text textAlign="center">
                {message ?? (
                    <FormattedMessage defaultMessage="Revenez consulter vos statistiques plus tard le temps que de nouveaux auditeurs découvrent votre podcast." />
                )}
            </Text>
        </WordingContainer>
    </Container>
);

const Icon = styled(FontAwesomeIcon)`
    height: 1.5rem;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--sp-m);
    color: var(--neutral500);
`;

const WordingContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--sp-xxs);
    max-width: 25rem;
`;

export default NoDataChart;
