import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';

const ViewSelector = ({ currentView, setCurrentView }) => {
    return (
        <ViewSelectorWrapper>
            <ViewSelectorButton
                onClick={() => setCurrentView('calendar')}
                isActive={currentView === 'calendar'}
            >
                <FontAwesomeIcon
                    icon={icon({ name: 'calendar', style: 'regular' })}
                    color="inherit"
                />
            </ViewSelectorButton>
            <ViewSelectorButton
                onClick={() => setCurrentView('list')}
                isActive={currentView === 'list'}
            >
                <FontAwesomeIcon icon={icon({ name: 'list-ul', style: 'solid' })} color="inherit" />
            </ViewSelectorButton>
        </ViewSelectorWrapper>
    );
};

const ViewSelectorWrapper = styled.div`
    display: none;
    align-items: center;
    gap: 0.5rem;
    border-radius: var(--r-l);
    background-color: var(--neutral50);
    padding: 0.2rem;

    ${(p) => p.theme.mediaQueries.desktopLargeAndUp} {
        display: flex;
    }
`;
const ViewSelectorButton = styled.button`
    width: 2rem;
    height: 2rem;
    border-radius: var(--r-m);
    background-color: ${({ isActive }) => (isActive ? 'var(--white)' : 'transparent')};
    color: ${({ isActive }) => (isActive ? 'var(--black)' : 'var(--neutral500)')};
    border: none;
    cursor: pointer;

    &:hover {
        background-color: ${({ isActive }) => (isActive ? 'var(--white)' : 'var(--neutral100)')};
        color: ${({ isActive }) => (isActive ? 'var(--black)' : 'var(--neutral600)')};
    }
`;

ViewSelector.propTypes = {
    currentView: PropTypes.oneOf(['calendar', 'list']),
    setCurrentView: PropTypes.func,
};

export default ViewSelector;
