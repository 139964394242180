import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { Step } from '@/shared/components/FormStepper';
import { useLocation } from 'react-router-dom';

const LOOKUP_PATH_STEPS: Record<string, Step[]> = {
    '/app/onboarding': [{ state: 'active' }, { state: 'inactive' }, { state: 'inactive' }],
    '/app/onboarding/create': [{ state: 'completed' }, { state: 'active' }, { state: 'inactive' }],
    '/app/onboarding/create/success': [
        { state: 'completed' },
        { state: 'completed' },
        { state: 'completed' },
    ],
    '/app/onboarding/youtube': [
        { state: 'completed' },
        { state: 'completed' },
        { state: 'active' },
    ],
    '/app/onboarding/youtube/success': [
        { state: 'completed' },
        { state: 'completed' },
        { state: 'completed' },
    ],
    '/app/onboarding/import': [{ state: 'completed' }, { state: 'active' }, { state: 'inactive' }],
    '/app/onboarding/import/hosting': [
        { state: 'completed' },
        { state: 'completed' },
        { state: 'active' },
    ],
    '/app/onboarding/import/hosting/success': [
        { state: 'completed' },
        { state: 'completed' },
        { state: 'completed' },
    ],
    '/app/onboarding/import/nonHosting': [
        { state: 'completed' },
        { state: 'completed' },
        { state: 'active' },
    ],
    '/app/onboarding/import/nonHosting/success': [
        { state: 'completed' },
        { state: 'completed' },
        { state: 'completed' },
    ],
};

export interface StepperStateContextType {
    steps: Step[];
}

interface StepperStateProviderProps {
    children: ReactNode;
}

export const StepperState = createContext<StepperStateContextType | null>(null);

export const StepperStateContext = ({ children }: StepperStateProviderProps) => {
    const { pathname } = useLocation();
    const defaultSteps: Step[] = LOOKUP_PATH_STEPS[pathname];
    const [steps, setSteps] = useState<Step[]>(defaultSteps);

    useEffect(() => {
        setSteps(LOOKUP_PATH_STEPS[pathname]);
    }, [pathname]);

    return (
        <StepperState.Provider
            value={{
                steps: steps,
            }}
        >
            {children}
        </StepperState.Provider>
    );
};

export const useStepperState = () => {
    const context = useContext(StepperState);
    if (!context) {
        throw new Error('useStepperStateContext must be used within a StepperStateProvider');
    }
    return context;
};
