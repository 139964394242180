import { useEffect, useState } from 'react';
import { EditorContent, ReactRenderer, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import styled, { css, keyframes } from 'styled-components';
import { CharacterCount } from '@tiptap/extension-character-count';
import { Mention } from '@tiptap/extension-mention';
import tippy from 'tippy.js';
import {
    CHARACTERS_LIMIT,
    EPISODE_TRANSCRIPT_STATUS,
    FACEBOOK,
    INSTAGRAM,
    LINKEDIN,
    TWITTER,
} from '@/shared/config/constants';
import 'emoji-mart/css/emoji-mart.css';
import MaxLength from '@ui/molecules/MaxLength';
import HashtagsBar from './HashtagsBar';
import MentionsList from './MentionsList';
import LoadingExtension from './LoadingExtension';
import { fetchTwitterUsers } from '@/api';
import EmojiPickerButton from '@/components/ui/atoms/InputRichText/EmojiPickerButton';
import ErrorMessage from '@/components/ui/atoms/ErrorMessage';
import { FormattedMessage } from 'react-intl';
import Label from '@/components/ui/atoms/Label';
import EpisodeAISuggestionsPanel from '@/components/EpisodeAI/EpisodeAISuggestionsPanel';
import { useModalToastQueue } from '@/shared/hooks/useModalToastQueue.hook';
import { TooltipTrigger } from 'react-aria-components';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';
import Button from '@/components/Button';
import NebulaSparklesIcon from '@public/icons/sparkles-nebula.svg';
import NebulaSpinnerIcon from '@public/icons/spinner-nebula.svg';
import Text from '@/components/ui/atoms/Text';
import useHasAccessToEpisodeAI from '@/shared/hooks/useHasAccessToEpisodeAI.hook';
import { useParams } from 'react-router';
import AIPreferencesIcon from '@/components/EpisodeAI/AIPreferencesIcon';
import IconButton from '@/components/IconButton';
import EpisodeAISubscriptionInfoModal from '@/components/EpisodeAI/EpisodeAISubscriptionInfoModal';
import useAISocialPostSuggestionsQuery from '@/queries/ai/useAISocialPostSuggestionQuery.hook';
import useAICustomPromptsQuery from '@/queries/ai/useAICustomPromptsQuery.hook';
import useEpisodeTranscriptStatusQuery from '@/queries/episodeTranscript/useEpisodeTranscriptStatusQuery.hook';
import useAISummaryQuery from '@/queries/ai/useAISummaryQuery.hook';
import useEpisodeQuery from '@/queries/episode/useEpisodeQuery.hook';
import SocialMediaAICustomPromptModal from './SocialMediaAICustomPromptModal';
import { sendAmplitudeLogEvent } from '@/shared/utils/amplitude';
import { useIntercom } from 'react-use-intercom';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
`;
const InputWrapper = styled.div`
    display: flex;
    padding: 0.75rem;
    flex-direction: column;
    row-gap: 0.75rem;
    border-radius: var(--r-s);
    border: 1px solid var(--neutral200);
    background-color: var(--white);

    &:focus-within {
        border: 1px solid var(--primary);
        box-shadow: 0px 0px 0px 4px rgba(117, 28, 191, 0.1);
    }

    ${({ $isInvalid }) =>
        $isInvalid &&
        css`
            border-color: var(--alert);

            &:focus-within {
                box-shadow: 0px 0px 0px 4px rgba(255, 38, 74, 0.1);
                border-color: var(--alert);
            }
        `}

    ${({ $isDisabled }) =>
        $isDisabled &&
        css`
            opacity: 0.5;
            pointer-events: none;
        `}
`;
const InputHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 0.5rem;
`;
const StyledEditorContent = styled(EditorContent)`
    .ProseMirror {
        min-height: 7.5rem;
        max-height: 19.75rem;
        overflow-y: auto;
        font-size: var(--fs-body);
        line-height: var(--lh-body);
    }

    .ProseMirror:hover {
        border-color: var(--neutral500);
    }

    .ProseMirror:focus,
    .ProseMirror:focus-within,
    .ProseMirror-focused {
        border-color: var(--primary);
    }

    .ProseMirror p.is-editor-empty:first-child::before {
        color: var(--neutral500);
        content: attr(data-placeholder);
        float: left;
        height: 0;
        pointer-events: none;
    }

    .social-media-editor {
        height: 7.5rem;
        overflow-y: auto;
    }

    .social-media-editor.${FACEBOOK} a {
        color: var(--facebook);
    }

    .social-media-editor.${TWITTER} a,
    .social-media-editor.${TWITTER} .mention {
        color: var(--twitter);
    }

    .social-media-editor.${INSTAGRAM} a {
        color: var(--instagram);
    }

    .social-media-editor.${LINKEDIN} a {
        color: var(--linkedin);
    }
`;
const Divider = styled.div`
    height: 1px;
    background-color: var(--neutral200);
    align-self: stretch;
    border-radius: var(--r-full);
`;
const LabelWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const AIButton = styled(Button)`
    padding: 0;
    background: var(--gradient-nebula);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;
const spin = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;
const SpinnerIcon = styled(NebulaSpinnerIcon)`
    width: 1rem;
    height: 1rem;
    animation: ${spin} 2s linear infinite;
`;
const SparklesIcon = styled(NebulaSparklesIcon)`
    width: 1rem;
    height: 1rem;
`;
const PostSuggestionsPanel = styled(EpisodeAISuggestionsPanel)`
    margin-top: -1rem;
`;
const AIPreferencesButton = styled(IconButton)`
    padding: 0;
`;

const SocialMediaRichTextEditor = ({
    provider,
    episodeId,
    placeholder,
    value,
    onChange,
    setHasPassedCharacterLimit,
    hasPassedCharacterLimit,
    availableHashtags = [],
    isPostingSmartlinkOnTwitter,
    errorMessage,
    isDisabled,
    postId,
}) => {
    const { showId } = useParams();
    const toast = useModalToastQueue();
    const episode = useEpisodeQuery(episodeId);
    const transcriptionStatus = useEpisodeTranscriptStatusQuery(episodeId);
    const customPromptSettings = useAICustomPromptsQuery({
        showId,
    });
    const socialPostSettings = {
        customPrompt: customPromptSettings.data?.find((s) => s.field === 'smm')?.prompt || '',
        saveAsDefault: false,
    };
    const aiSessionSettings = sessionStorage.getItem(`ai-social-post-settings`);
    const defaultAISettings = aiSessionSettings
        ? JSON.parse(aiSessionSettings)
        : socialPostSettings;
    const aiPostSuggestion = useAISocialPostSuggestionsQuery({
        episodeId,
        provider,
        settings: defaultAISettings,
    });
    const [isWritingSocialPost, setIsWritingSocialPost] = useState(false);
    // const [socialPostWords, setSocialPostWords] = useState([]);
    const [loadingSentences, setLoadingSentences] = useState(false);
    const hasAccessToEpisodeAI = useHasAccessToEpisodeAI({ showId });
    const hasTranscription = transcriptionStatus.data?.status === EPISODE_TRANSCRIPT_STATUS.DONE;
    const summary = useAISummaryQuery({
        episodeId,
        options: {
            enabled:
                !!episodeId &&
                (hasAccessToEpisodeAI || episode.data?.aiFreeTrialEnabled) &&
                hasTranscription,
        },
    });
    const isEpisodeAIAvailable = !summary.isLoading && !summary.isFetching && hasTranscription;
    const hasGeneratedPost = localStorage.getItem(`ai-generated-post-${postId}`) === 'true';
    const isGeneratingSocialPost = aiPostSuggestion.isFetching || isWritingSocialPost;
    const smartlinkExcess = isPostingSmartlinkOnTwitter ? 24 : 0;
    const [isSuggestionsPanelOpen, setIsSuggestionsPanelOpen] = useState(false);
    const [aiSubscriptionModalOpen, setAiSubscriptionModalOpen] = useState(false);
    const [isAiCustomPromptModalOpen, setIsAiCustomPromptModalOpen] = useState(false);
    const intercom = useIntercom();

    let extensions = [
        // Disable unnecessary extensions
        StarterKit.configure({
            blockquote: false,
            bulletList: false,
            codeBlock: false,
            heading: false,
            horizontalRule: false,
            orderedList: false,
            bold: false,
            code: false,
            italic: false,
            strike: false,
        }),
        Link.configure({
            openOnClick: false, // Prevent links to be clickable in text editor
        }),
        LoadingExtension,
    ];

    if (placeholder) {
        extensions = [
            ...extensions,
            Placeholder.configure({
                placeholder,
            }),
        ];
    }

    if (CHARACTERS_LIMIT[provider]) {
        extensions = [
            ...extensions,
            CharacterCount.configure({
                limit: null,
            }),
        ];
    }

    if (provider === TWITTER) {
        extensions = [
            ...extensions,
            Mention.configure({
                HTMLAttributes: {
                    class: 'mention',
                },
                suggestion: {
                    items: async ({ query }) => {
                        try {
                            const queryRegex = /[A-Za-z0-9_]/;
                            const isInvalid =
                                query.length < 3 || query.length > 15 || !queryRegex.test(query);

                            // Prevent calling API until query is at least 3 characters long
                            if (isInvalid) {
                                return [];
                            }

                            const { data: twitterUser } = await fetchTwitterUsers(episodeId, query);
                            if (!twitterUser || twitterUser?.length < 1) return [];
                            return [{ id: twitterUser.mention_name, ...twitterUser }];
                        } catch (error) {
                            return [];
                        }
                    },
                    render: () => {
                        let reactRenderer;
                        let popup;

                        return {
                            onStart: (props) => {
                                reactRenderer = new ReactRenderer(MentionsList, {
                                    props,
                                    editor: props.editor,
                                });

                                // Create a popup to display mentions list
                                popup = tippy('body', {
                                    getReferenceClientRect: props.clientRect,
                                    appendTo: () => document.body,
                                    content: reactRenderer.element,
                                    showOnCreate: true,
                                    interactive: true,
                                    trigger: 'manual',
                                    placement: 'bottom-start',
                                });
                            },
                            onUpdate(props) {
                                reactRenderer.updateProps(props);

                                popup[0].setProps({
                                    getReferenceClientRect: props.clientRect,
                                });
                            },
                            onExit() {
                                popup[0].destroy();
                                reactRenderer.destroy();
                            },
                        };
                    },
                },
            }),
        ];
    }

    const content = value?.replace(/\n/g, '<br/>');

    const editor = useEditor({
        extensions,
        content,
        editorProps: {
            attributes: {
                class: `social-media-editor ${provider}`,
            },
        },
        onUpdate: ({ editor }) => {
            if (!onChange || isWritingSocialPost) return;
            // Tiptap add two line breaks between nodes by default, only one is sufficient
            const plainText = editor.getText({ blockSeparator: '\n' });
            onChange(plainText);
        },
        parseOptions: {
            preserveWhitespace: 'full', // \n in text value should be preserved as linebreaks
        },
    });

    useEffect(() => {
        setHasPassedCharacterLimit(
            (editor?.storage?.characterCount?.characters() + smartlinkExcess || 0) >
                CHARACTERS_LIMIT[provider],
        );
    }, [editor?.storage?.characterCount?.characters(), isPostingSmartlinkOnTwitter]);

    useEffect(() => {
        if (!editor) return;
        // When social post is generating, editor should be read-only
        editor.setEditable(!isGeneratingSocialPost);
    }, [isGeneratingSocialPost]);

    useEffect(() => {
        if (!editor) return;
        if (loadingSentences.length > 0) {
            let sentences = loadingSentences.match(/[^.!?]+[.!?]*/g) || [];
            editor.commands.clearContent();
            if (isWritingSocialPost) {
                const interval = setInterval(() => {
                    if (sentences.length > 0) {
                        const [nextSentence, ...rest] = sentences;
                        editor
                            .chain()
                            .clearContentLoading()
                            .focus('end')
                            .insertContent(`${nextSentence} `)
                            .insertContentLoading()
                            .run();
                        sentences = rest;
                    } else {
                        setIsWritingSocialPost(false);
                        clearInterval(interval);
                    }
                }, 300);
                return () => clearInterval(interval);
            } else {
                editor.commands.clearContent();
                editor.chain().clearContentLoading().insertContent(loadingSentences).run();
            }
        }
        // All sentences are displayed and writing of the message is complete
        editor.chain().clearContentLoading().run();
    }, [editor, isWritingSocialPost, loadingSentences]);

    // [AMPLITUDE] AI generation event
    useEffect(() => {
        if (aiPostSuggestion.data && !aiPostSuggestion.isFetching) {
            const isRegenerated = localStorage.getItem(`ai-regenerated-post-${postId}`) === 'true';
            if (isRegenerated) return;
            sendAmplitudeLogEvent('AI Generation', {
                field: 'Social Post',
                suggestions: aiPostSuggestion.data.suggestion,
                prompt: defaultAISettings?.customPrompt || '',
            });
        }
    }, [aiPostSuggestion.data, aiPostSuggestion.isFetching]);

    // [AMPLITUDE] AI regeneration event
    useEffect(() => {
        if (aiPostSuggestion.data) {
            const isRegenerated = localStorage.getItem(`ai-regenerated-post-${postId}`) === 'true';
            if (!isRegenerated) return;
            sendAmplitudeLogEvent('AI Regeneration', {
                field: 'Social Post',
                suggestions: aiPostSuggestion.data.suggestion,
                prompt: defaultAISettings?.customPrompt || '',
            });
        }
    }, [aiPostSuggestion.data]);

    const onGeneratePostWithAi = async () => {
        if (!hasAccessToEpisodeAI) {
            setAiSubscriptionModalOpen(true);
            return;
        }
        try {
            editor.chain().setContentLoading().run();

            setIsSuggestionsPanelOpen(true);
            localStorage.setItem(`ai-generated-post-${postId}`, 'true');
            const { data } = await aiPostSuggestion.refetch({ throwOnError: true });

            // Store all sentences of the AI generated post in  queue and trigger writing sentence by sentence
            setLoadingSentences(data.suggestion.replace(/\n/g, '<br/>'));
            setIsWritingSocialPost(true);
        } catch (error) {
            if (error?.response?.status) {
                switch (error.response.status) {
                    case 403:
                        switch (error.response.data?.message) {
                            case 'Missing user option ai': // Missing user option
                                toast.alert(
                                    <FormattedMessage
                                        defaultMessage="Oups, il semblerait que vous n’ayez pas accès à cette fonctionnalité. N’hésitez pas à <intercom>nous contacter</intercom> pour en savoir plus."
                                        values={{
                                            intercom: (chunks) => (
                                                <Text as="span" onClick={() => intercom.show()}>
                                                    {chunks}
                                                </Text>
                                            ),
                                        }}
                                    />,
                                );
                                break;
                            default:
                                toast.alert(
                                    <FormattedMessage defaultMessage="Oups, l’IA semble rencontrer un problème. Merci de réessayer plus tard ✨" />,
                                );
                                break;
                        }
                        break;
                    case 422: // Invalid social network
                    case 429: // Too many requests indicated by Open AI servers, try again later
                    default:
                        toast.alert(
                            <FormattedMessage defaultMessage="Oups, l’IA semble rencontrer un problème. Merci de réessayer plus tard ✨" />,
                        );
                        break;
                }
            }
            localStorage.removeItem(`ai-generated-post-${postId}`);
            setIsSuggestionsPanelOpen(false);
            setIsWritingSocialPost(false);
            editor.chain().clearContentLoading().run();
        }
    };

    const handleCloseSuggestionPanel = () => {
        setIsSuggestionsPanelOpen(false);
    };

    const handleSubmitAICustomPrompt = (data) => {
        sessionStorage.setItem(`ai-social-post-settings`, JSON.stringify(data));
        localStorage.setItem(`ai-regenerated-post-${postId}`, 'true');
        setIsAiCustomPromptModalOpen(false);
        onGeneratePostWithAi();
    };

    const handleDislike = () => {
        sendAmplitudeLogEvent('AI Dislike', {
            field: 'Social Post',
            custom_prompt: defaultAISettings?.customPrompt || '',
            suggestions: aiPostSuggestion.data?.suggestion,
        });
    };

    if (!editor) {
        return null;
    }

    return (
        <>
            <Wrapper>
                <LabelWrapper>
                    <Label
                        isRequired={false}
                        description={
                            provider === TWITTER ? (
                                <FormattedMessage defaultMessage="Vous pouvez saisir des hashtags (#) et des mentions (@)." />
                            ) : (
                                <FormattedMessage defaultMessage="Vous pouvez saisir des hashtags (#)." />
                            )
                        }
                    >
                        <FormattedMessage defaultMessage="Texte" />
                    </Label>
                    {CHARACTERS_LIMIT[provider] && (
                        <MaxLength
                            currentValue={
                                editor.storage.characterCount.characters() + smartlinkExcess
                            }
                            max={CHARACTERS_LIMIT[provider]}
                        />
                    )}
                </LabelWrapper>
                <InputWrapper $isInvalid={hasPassedCharacterLimit} $isDisabled={isDisabled}>
                    <InputHeader>
                        <EmojiPickerButton editor={editor} isDisabled={isDisabled} />
                        {hasGeneratedPost && !isGeneratingSocialPost ? (
                            <AIPreferencesButton
                                variant="ghost"
                                icon={<AIPreferencesIcon />}
                                aria-label="AI Preferences"
                                onPress={() => setIsAiCustomPromptModalOpen(true)}
                                isDisabled={!isEpisodeAIAvailable}
                                isLoading={isGeneratingSocialPost}
                            />
                        ) : (
                            <TooltipTrigger delay={0} closeDelay={0}>
                                <TooltipTriggerWrapper>
                                    <AIButton
                                        variant="ghost"
                                        startIcon={<SparklesIcon />}
                                        size="small"
                                        onPress={onGeneratePostWithAi}
                                        isDisabled={!isEpisodeAIAvailable}
                                        isLoading={isGeneratingSocialPost}
                                        loadingIcon={<SpinnerIcon />}
                                    >
                                        AI
                                    </AIButton>
                                </TooltipTriggerWrapper>
                                <Tooltip placement="start">
                                    <Text fontWeight="--fw-semibold">
                                        {!isEpisodeAIAvailable ? (
                                            <FormattedMessage defaultMessage="L'IA nécessite d'avoir transcrit l'épisode au préalable." />
                                        ) : (
                                            <FormattedMessage defaultMessage="Laissez la magie de AI suggérer le contenu de votre podcast." />
                                        )}
                                    </Text>
                                </Tooltip>
                            </TooltipTrigger>
                        )}
                    </InputHeader>
                    <Divider />
                    <StyledEditorContent
                        editor={editor}
                        data-testid="social-media-post-text-rich-editor"
                    />
                </InputWrapper>
                {isSuggestionsPanelOpen && (
                    <PostSuggestionsPanel
                        title={<FormattedMessage defaultMessage="Votre contenu est prêt !" />}
                        isLoading={isGeneratingSocialPost}
                        onClose={handleCloseSuggestionPanel}
                        data-testid="smm-ai-post-suggestions-panel"
                        onPreferencesPress={() => setIsAiCustomPromptModalOpen(true)}
                        onDislike={handleDislike}
                    />
                )}
                {availableHashtags?.length > 0 && (
                    <HashtagsBar editor={editor} availableHashtags={availableHashtags} />
                )}
                {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            </Wrapper>
            <EpisodeAISubscriptionInfoModal
                isOpen={aiSubscriptionModalOpen}
                onOpenChange={setAiSubscriptionModalOpen}
            />
            <SocialMediaAICustomPromptModal
                isOpen={isAiCustomPromptModalOpen}
                onOpenChange={setIsAiCustomPromptModalOpen}
                settings={defaultAISettings}
                onSubmit={handleSubmitAICustomPrompt}
            />
        </>
    );
};

export default SocialMediaRichTextEditor;
