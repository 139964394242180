import { useState } from 'react';
import Button from '@/components/Button';
import Text from '@/components/ui/atoms/Text';
import { FormattedMessage } from 'react-intl';
import GuestRolesDetailsModal from '@/components/GuestRolesDetailsModal';
import ChannelGuestsAddButton from '../ChannelGuestsAddButton';
import styled from 'styled-components';
import ChannelGuestsQuota from './ChannelGuestsQuota';

const ChannelGuestsHeader = () => {
    const [isRoleDetailsModalOpen, setIsRoleDetailsModalOpen] = useState(false);
    return (
        <>
            <Header>
                <HeaderLeftBlock>
                    <Text variant="headingM" fontWeight="--fw-bold">
                        <FormattedMessage defaultMessage="Utilisateurs" />
                    </Text>
                    <Text color="--neutral500">
                        <FormattedMessage
                            defaultMessage="Les autres utilisateurs peuvent, selon leur rôle, consulter ou gérer certaines données de l’émission telles que les épisodes, statistiques, etc. <button>Détail des rôles</button>"
                            values={{
                                button: (chunks: string) => (
                                    <Button
                                        variant="link-primary"
                                        onPress={() => setIsRoleDetailsModalOpen(true)}
                                    >
                                        {chunks}
                                    </Button>
                                ),
                            }}
                        />
                    </Text>
                </HeaderLeftBlock>
                <HeaderRightBlock>
                    <ChannelGuestsQuota />
                    <AddButton>
                        <FormattedMessage defaultMessage="Ajouter un utilisateur" />
                    </AddButton>
                </HeaderRightBlock>
            </Header>
            <GuestRolesDetailsModal
                isOpen={isRoleDetailsModalOpen}
                onOpenChange={setIsRoleDetailsModalOpen}
            />
        </>
    );
};

const Header = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
        justify-content: space-between;
        align-items: start;
        column-gap: 1.5rem;
    }
`;
const HeaderLeftBlock = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    flex-grow: 1;
`;
const HeaderRightBlock = styled.div`
    display: flex;
    column-gap: 1rem;
    flex-shrink: 0;
`;
const AddButton = styled(ChannelGuestsAddButton)`
    flex-grow: 1;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-grow: 0;
    }
`;

export default ChannelGuestsHeader;
