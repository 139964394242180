import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Text from '@ui/atoms/Text';
import Alert from '@ui/atoms/Alert';
import Spinner from '@ui/atoms/Spinner';
import Tooltip, { TooltipTriggerWrapper } from '@ui/Tooltip';
import { TooltipTrigger } from 'react-aria-components';
import { resolveColor } from '@/utils/cssVariables';
import Suggestion from './Suggestion';
import SuggestionsSkeleton from './SuggestionsSkeleton';
import { trim } from '@/utils/string';
import styled from 'styled-components';

interface Suggestion {
    id: string;
    label: string;
    rank: number;
}

export interface KeywordSuggestionsProps {
    keywords: string[];
    aiSuggestions: Suggestion[];
    indexedSuggestions: Suggestion[];
    onSelectKeyword: (keyword: { id: string; label: string; rank: number }) => void;
    isLoading: boolean;
    isError: boolean;
    hasReachedMaxKeywords: boolean;
}

const KeywordSuggestions = ({
    keywords,
    aiSuggestions,
    indexedSuggestions,
    onSelectKeyword,
    isLoading,
    isError,
    hasReachedMaxKeywords,
}: KeywordSuggestionsProps) => {
    const filteredAiSuggestions =
        aiSuggestions
            ?.filter((suggestion) => !keywords.includes(suggestion.label.toLowerCase()))
            .map((suggestion) => ({
                ...suggestion,
                label: trim(suggestion.label, 40),
                id: crypto.randomUUID(),
            })) || [];

    const filteredIndexedSuggestions =
        indexedSuggestions
            ?.filter((suggestion) => !keywords.includes(suggestion.label.toLowerCase()))
            .map((suggestion) => ({
                ...suggestion,
                label: trim(suggestion.label, 40),
                id: crypto.randomUUID(),
            })) || [];

    return (
        <KeywordSuggestionsWrapper>
            <TitleOuterWrapper>
                <TitleWrapper>
                    <Left>
                        {/* @ts-ignore */}
                        <Text variant="subheading" fontWeight="--fw-bold">
                            <FormattedMessage defaultMessage="Assistant IA de recherche de mots-clés" />
                        </Text>
                        <TooltipTrigger delay={0} closeDelay={0}>
                            <TooltipTriggerWrapper>
                                <FontAwesomeIcon
                                    icon={icon({ name: 'circle-info', style: 'solid' })}
                                    color={resolveColor('--neutral500')}
                                    size="1x"
                                />
                            </TooltipTriggerWrapper>
                            <Tooltip placement="top">
                                <FormattedMessage defaultMessage="Cette suggestion se base sur le titre et la description de votre podcast" />
                            </Tooltip>
                        </TooltipTrigger>
                    </Left>
                    {isLoading && <Spinner />}
                </TitleWrapper>
                {!isLoading && !isError && (
                    <Alert
                        variant="robot"
                        description={
                            // @ts-ignore
                            <Text color="--neutral500">
                                <FormattedMessage defaultMessage="Mon algorithme a analysé les données de votre podcast afin de vous partager les suggestions les plus pertinentes." />
                            </Text>
                        }
                    >
                        {/* @ts-ignore */}
                        <Text fontWeight="--fw-semibold">
                            <FormattedMessage defaultMessage="Après analyse de votre podcast, voici des suggestions de mots-clés" />
                        </Text>
                    </Alert>
                )}
            </TitleOuterWrapper>
            {isLoading ? (
                <SuggestionsSkeleton />
            ) : isError ? (
                <ErrorWrapper>
                    <FontAwesomeIcon
                        icon={icon({ name: 'cloud-exclamation', style: 'solid' })}
                        size="lg"
                    />
                    {/* @ts-ignore */}
                    <Text fontWeight="--fw-semibold">
                        <FormattedMessage
                            defaultMessage="Oups, nous n'avons pas de suggestion à proposer pour votre podcast.
Merci de réessayer plus tard."
                        />
                    </Text>
                </ErrorWrapper>
            ) : filteredAiSuggestions.length === 0 && filteredIndexedSuggestions.length === 0 ? (
                <NoSuggestions>
                    {/* @ts-ignore */}
                    <Text fontWeight="--fw-bold" color="--neutral300">
                        <FormattedMessage defaultMessage="Aucune suggestion" />
                    </Text>
                </NoSuggestions>
            ) : (
                <SuggestionList>
                    {filteredIndexedSuggestions.map((suggestion) => (
                        <Suggestion
                            key={suggestion.id}
                            label={suggestion.label}
                            onClick={() => {
                                if (hasReachedMaxKeywords) return;
                                onSelectKeyword(suggestion);
                            }}
                            isDisabled={hasReachedMaxKeywords}
                            isIndexed={true}
                        />
                    ))}
                    {filteredAiSuggestions.map((suggestion) => (
                        <Suggestion
                            key={suggestion.id}
                            label={suggestion.label}
                            onClick={() => {
                                if (hasReachedMaxKeywords) return;
                                onSelectKeyword(suggestion);
                            }}
                            isDisabled={hasReachedMaxKeywords}
                        />
                    ))}
                </SuggestionList>
            )}
        </KeywordSuggestionsWrapper>
    );
};

const KeywordSuggestionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem;
    background-color: var(--neutral50);
    border-radius: var(--r-s);
    height: 100%;
`;
const TitleOuterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;
const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const Left = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
`;
const SuggestionList = styled.ul`
    padding-inline: 0.5rem;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    height: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 1rem;
    }

    &::-webkit-scrollbar-thumb {
        border: 0.25rem solid transparent;
        background-clip: padding-box;
        border-radius: var(--r-full);
        background-color: var(--neutral200);
    }
`;
const NoSuggestions = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`;
const ErrorWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    color: var(--neutral500);
    padding-inline: 2rem;

    & > * {
        text-align: center;
        text-wrap: balance;
    }
`;

KeywordSuggestions.propTypes = {
    keywords: PropTypes.arrayOf(PropTypes.string),
    suggestions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            label: PropTypes.string.isRequired,
            rank: PropTypes.number.isRequired,
        }),
    ),
    onSelectKeyword: PropTypes.func,
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    hasReachedMaxKeywords: PropTypes.bool,
};

KeywordSuggestions.defaultProps = {
    keywords: [],
    onSelectKeyword: () => {},
};

export default KeywordSuggestions;
