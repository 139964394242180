import { useMutation } from '@tanstack/react-query';
import { unlinkSharing } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import providerKeys from '../provider/providerKeys';
import { useQueryClient } from '@tanstack/react-query';

const transform = (data) => {
    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const mutationFn = async ({ showId, provider }) => {
    const { data } = await unlinkSharing(showId, provider);
    return transform(data);
};

const useDeleteShowTemplateMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({
                queryKey: providerKeys.listByShowId(variables.showId),
            });
        },
    });
};

export default useDeleteShowTemplateMutation;
