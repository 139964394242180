import styled, { css, keyframes } from 'styled-components';

const CallToActionSkeleton = () => {
    return (
        <Wrapper>
            {/* First CTA Skeleton */}
            <CTAContainer>
                <HeaderSkeleton>
                    <IconSkeleton />
                    <TextSkeleton $w="10rem" />
                </HeaderSkeleton>
                <UrlSkeleton $w="15rem" />

                <Subsection>
                    <TextSkeleton $w="6rem" />
                    <PresentOnSkeleton>
                        <PresentItemSkeleton />
                        <PresentItemSkeleton />
                        <PresentItemSkeleton />
                    </PresentOnSkeleton>
                </Subsection>
            </CTAContainer>

            {/* Second CTA Skeleton */}
            <CTAContainer>
                <HeaderSkeleton>
                    <IconSkeleton />
                    <TextSkeleton $w="8rem" />
                </HeaderSkeleton>
                <UrlSkeleton $w="12rem" />

                <Subsection>
                    <TextSkeleton $w="6rem" />
                    <PresentOnSkeleton>
                        <PresentItemSkeleton />
                        <PresentItemSkeleton />
                    </PresentOnSkeleton>
                </Subsection>
            </CTAContainer>

            {/* Create custom button */}
            <CreateButtonSkeleton />
        </Wrapper>
    );
};

const shimmer = keyframes`
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
`;

const shimmering = css`
    background: var(--neutral50);
    background-image: linear-gradient(
        to right,
        var(--neutral50) 0%,
        #edeef1 20%,
        var(--neutral50) 40%,
        var(--neutral50) 100%
    );
    background-repeat: no-repeat;
    background-size: 50rem 6.5rem;
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${shimmer};
    animation-timing-function: linear;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    width: 100%;
`;

const CTAContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background: var(--neutral50);
    border-radius: var(--r-s);
    gap: 1rem;
`;

const HeaderSkeleton = styled.div`
    display: flex;
    align-items: center;
    gap: 0.75rem;
`;

const IconSkeleton = styled.div`
    width: 1.5rem;
    height: 1.5rem;
    border-radius: var(--r-full);
    background-color: var(--neutral100);
    ${shimmering};
`;

const TextSkeleton = styled.div<{ $w?: string }>`
    height: 1rem;
    border-radius: var(--r-full);
    background-color: var(--neutral100);
    ${({ $w }) => $w && `width: ${$w};`}
    ${shimmering};
`;

const UrlSkeleton = styled.div<{ $w?: string }>`
    height: 1rem;
    border-radius: var(--r-full);
    background-color: var(--neutral100);
    ${({ $w }) => $w && `width: ${$w};`}
    ${shimmering};
`;

const Subsection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
`;

const PresentOnSkeleton = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;

const PresentItemSkeleton = styled.div`
    height: 2.5rem;
    border-radius: var(--r-s);
    background-color: var(--neutral100);
    ${shimmering};
`;

const CreateButtonSkeleton = styled.div`
    width: 12rem;
    height: 3rem;
    border-radius: var(--r-sm);
    background-color: var(--neutral100);
    align-self: center;
    margin-top: 1.5rem;
    ${shimmering};

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        align-self: start;
    }
`;

export default CallToActionSkeleton;
