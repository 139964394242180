import { useQuery } from '@tanstack/react-query';
import { fetchShowTemplate } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import socialNetworkPostKeys from './socialNetworkPostKeys';

const queryFn = async ({ queryKey: [{ showId, platformId }] }) => {
    const res = await fetchShowTemplate(showId, platformId);
    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(res.data, { deep: true });
};

const useSocialNetworkPostTemplateQuery = ({ showId, platformId }) => {
    return useQuery({
        queryKey: socialNetworkPostKeys.postTemplate({ showId, platformId }),
        queryFn,
        enabled: !!showId && !!platformId,
    });
};

export default useSocialNetworkPostTemplateQuery;
