import { CallToAction } from '@/queries/callToAction/useCallToActionButtonsQuery.hook';
import styled from 'styled-components';
import { ICON_LIST_CTA } from '../CallToAction';
import Text from '@/components/ui/atoms/Text';
import IconButton from '@/components/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FormattedMessage } from 'react-intl';
import Stack from '@/components/ui/layout/Stack';

export const LOCATION_LABELS = {
    podcast_page: <FormattedMessage defaultMessage="Site Web" />,
    smartlink: <FormattedMessage defaultMessage="Smartlink" />,
    newsletter: <FormattedMessage defaultMessage="Newsletter" />,
    player: <FormattedMessage defaultMessage="Smartplayer" />,
};

interface CallToActionCardProps {
    data: CallToAction;
    onDelete: (cta: CallToAction) => void;
    onDeleteLocation: (cta: CallToAction, location: CallToAction['locations'][number]) => void;
    onEdit: (cta: CallToAction) => void;
}

const CallToActionCard = ({ data, onDelete, onDeleteLocation, onEdit }: CallToActionCardProps) => {
    if (!data || !data.url) return null;

    return (
        <Card>
            <CardHeader>
                {ICON_LIST_CTA[data.type]}
                <CardHeaderInner>
                    <Text fontWeight="--fw-semibold">{data.text}</Text>
                    <Url>{data.url}</Url>
                </CardHeaderInner>
                <Actions>
                    <ActionButton
                        icon={<Icon icon={icon({ name: 'pen', style: 'solid' })} />}
                        aria-label="Edit"
                        variant="tertiary"
                        onPress={() => onEdit(data)}
                    />
                    <ActionButton
                        icon={<Icon icon={icon({ name: 'trash', style: 'solid' })} />}
                        aria-label="Delete"
                        variant="tertiary"
                        onPress={() => onDelete(data)}
                    />
                </Actions>
            </CardHeader>
            <Stack $gap="0.5rem">
                <Text color="--neutral500" fontWeight="--fw-semibold">
                    <FormattedMessage defaultMessage="Présent sur" />
                </Text>
                <LocationList>
                    {data.locations.map((location) => (
                        <Location key={location}>
                            <Text>{LOCATION_LABELS[location]}</Text>
                            <GhostIconButton
                                icon={
                                    <LocationActionIcon
                                        icon={icon({ name: 'trash', style: 'solid' })}
                                    />
                                }
                                aria-label="Delete location"
                                variant="ghost"
                                onPress={() => onDeleteLocation(data, location)}
                            />
                        </Location>
                    ))}
                </LocationList>
            </Stack>
        </Card>
    );
};

const Card = styled.li`
    background-color: var(--neutral50);
    border-radius: var(--r-s);
    padding: 0.75rem;
    display: flex;
    flex-direction: column;
    row-gap: 0.75rem;
`;
const CardHeader = styled.div`
    display: flex;
    column-gap: 0.5rem;

    & > svg {
        margin-top: 0.25rem;
        color: var(--black);
    }
`;
const CardHeaderInner = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 0;
    flex-grow: 1;
`;
const Url = styled(Text)`
    flex-grow: 1;
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--neutral500);
    font-weight: var(--fw-semibold);
`;
const Actions = styled.div`
    flex-shrink: 0;
    display: flex;
    align-items: center;
    column-gap: 0.25rem;
`;
const ActionButton = styled(IconButton)`
    border-radius: var(--r-full);
    width: 2rem;
    height: 2rem;
    padding: 0;
    background-color: var(--white);
    color: var(--black);
`;
const Icon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const LocationList = styled.ul`
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;
    list-style: none;
    padding: 0;
`;
const Location = styled.li`
    background-color: var(--white);
    border-radius: var(--r-s);
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const GhostIconButton = styled(IconButton)`
    padding: 0;
`;
const LocationActionIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
    width: 0.75rem;
    height: 0.75rem;
`;

export default CallToActionCard;
