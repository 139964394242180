import { useMutation, useQueryClient } from '@tanstack/react-query';
import { PlaylistFormSchema } from '../PlaylistForm/usePlaylistFormSchema.hook';
import updatePlaylistImageMutation from '@/queries/playlist/updatePlaylistImageMutation';
import addPlaylistMutation from '@/queries/playlist/addPlaylistMutation';
import playlistKeys from '@/queries/playlist/playlistKeys';
import { AxiosError } from 'axios';

interface saveNewPlaylistMutationProps {
    showId: string;
    data: PlaylistFormSchema;
}

const saveNewPlaylistMutation = () => {
    const queryClient = useQueryClient();
    const addPlaylist = addPlaylistMutation();
    const updateImage = updatePlaylistImageMutation();

    return useMutation({
        mutationFn: async ({ showId, data }: saveNewPlaylistMutationProps) => {
            const { image, ...playlist } = data;

            const newPlaylist = await addPlaylist.mutateAsync({
                showId,
                playlist,
            });

            if (image && image instanceof File) {
                await updateImage.mutateAsync({
                    playlistId: String(newPlaylist.id),
                    image,
                });
            }

            return newPlaylist;
        },
        onSuccess: (_, { showId }) => {
            queryClient.invalidateQueries({ queryKey: playlistKeys.listByShowId(showId) });
        },
        onError: (error, { showId }) => {
            const err = error as AxiosError;
            const hasFileError = !!err?.response?.data?.errors?.file;
            // If the error is due to the file but playlist is created, invalidate the query to refresh the list
            if (err?.response?.status === 422 && hasFileError) {
                queryClient.invalidateQueries({ queryKey: playlistKeys.listByShowId(showId) });
            }
        },
    });
};

export default saveNewPlaylistMutation;
