import { useState } from 'react';
import Text from '@ui/atoms/Text';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import ShortListFallback from '@app/fallbacks/ShortListFallback';
import { useParams } from 'react-router';
import useAICustomPromptsQuery from '@queries/ai/useAICustomPromptsQuery.hook';
import useUpdateAICustomPromptsMutation, {
    CustomPrompt,
} from '@queries/ai/useUpdateAICustomPromptsMutation.hook';
import AIPreferencesIcon from '@/components/EpisodeAI/AIPreferencesIcon';
import { decamelize } from 'humps';
import useHasAccessToEpisodeAI from '@hooks/useHasAccessToEpisodeAI.hook';
import emptyStateAIVideo from '@public/videos/ai.mp4';
import EmptyState from '@ui/molecules/EmptyState';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Button from '@/components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ShiningDiamond from '@ui/atoms/ShiningDiamond';
import ZebraIllustration from '@ui/atoms/ZebraIllustration';
import { FieldPath } from 'react-hook-form';
import { AISettingsSchema } from '@/components/AISettings/AISettingsForm/useAISettingsSchema.hook';
import { useBodyToastQueue } from '@/utils/hooks/useBodyToastQueue.hook';
import AISettingsForm from './AISettingsForm';
import Stack from '../ui/layout/Stack';
import AIPresetsForm from './AIPresetsForm';
// @ts-ignore
import illustration from '@public/images/ai-settings.svg?url';
import EpisodeAISubscriptionInfoModal from '../EpisodeAI/EpisodeAISubscriptionInfoModal';
import { AIPresetsSchema } from './AIPresetsForm/useAIPresetsFormSchema.hook';
import AushaIntelligenceLogo from '@public/logos/ausha-intelligence.svg';

const AISettings = () => {
    const { showId } = useParams<{ showId: string }>();
    const hasAccessToEpisodeAI = useHasAccessToEpisodeAI({ showId });
    const { data: customPromptSettings, isError } = useAICustomPromptsQuery({
        showId,
    });
    const toast = useBodyToastQueue();
    const [aiSubscriptionModalOpen, setAiSubscriptionModalOpen] = useState(false);
    const updateCustomPromptsSettings = useUpdateAICustomPromptsMutation();
    const defaultValues = {
        title: customPromptSettings?.find((s) => s.field === 'title')?.prompt || '',
        description: customPromptSettings?.find((s) => s.field === 'description')?.prompt || '',
        tags: customPromptSettings?.find((s) => s.field === 'tags')?.prompt || '',
        newsletter: customPromptSettings?.find((s) => s.field === 'newsletter')?.prompt || '',
        toneOfVoice: customPromptSettings?.find((s) => s.field === 'tone_of_voice')?.prompt || '',
        smm: customPromptSettings?.find((s) => s.field === 'smm')?.prompt || '',
    };

    const handleSubmitPresets = (data: AIPresetsSchema) => {
        const settings = (customPromptSettings?.filter((s) => s.field !== 'tone_of_voice') ||
            []) as CustomPrompt[];

        const toneOfVoiceSettings = {
            field: 'tone_of_voice',
            prompt: data.toneOfVoice,
        } as CustomPrompt;

        updateCustomPromptsSettings.mutate(
            {
                showId,
                customPrompts: [...settings, toneOfVoiceSettings],
            },
            {
                onSuccess: () => {
                    toast.success();
                },
                onError: () => {
                    toast.alert();
                },
            },
        );
    };

    const handleSubmitSettings = (data: AISettingsSchema) => {
        const toneOfVoice = customPromptSettings?.find(
            (s) => s.field === 'tone_of_voice',
        ) as CustomPrompt;

        const formattedData = Object.fromEntries(
            Object.entries(data).filter(([_, value]) => value.trim() !== ''),
        );

        const customPrompts = Object.keys(formattedData).map((key) => {
            return {
                field: decamelize(key) as FieldPath<AISettingsSchema>,
                prompt: formattedData[key],
            };
        });
        updateCustomPromptsSettings.mutate(
            {
                showId,
                customPrompts: [...(toneOfVoice ? [toneOfVoice] : []), ...customPrompts],
            },
            {
                onSuccess: () => {
                    toast.success();
                },
                onError: () => {
                    toast.alert();
                },
            },
        );
    };

    if (!hasAccessToEpisodeAI) {
        return (
            <EmptyState
                illustration={emptyStateAIVideo}
                illustrationType="video"
                illustrationBackground="--gradient-aurora"
                illustrationAlign="right"
            >
                <EmptyStateContent>
                    <ZebraIllustration>
                        <ShiningDiamond />
                    </ZebraIllustration>
                    <EmptyStateMessage>
                        <Text variant="headingS" fontWeight="--fw-bold" textAlign="center">
                            <FormattedMessage defaultMessage="Optimisez votre podcast sans effort grâce à Ausha Intelligence" />
                        </Text>
                        <Text color="--neutral500" textAlign="center">
                            <FormattedMessage defaultMessage="Ajoutez dès maintenant Ausha Intelligence à n'importe quelle offre payante pour profiter des fonctionnalités d'AI + 60 minutes additionnelles de transcription par mois !" />
                        </Text>
                    </EmptyStateMessage>
                    <UnlockButton
                        variant="magic"
                        startIcon={
                            <SparklesIcon icon={icon({ name: 'sparkles', style: 'duotone' })} />
                        }
                        onPress={() => setAiSubscriptionModalOpen(true)}
                    >
                        <FormattedMessage defaultMessage="Débloquer la puissance de AI" />
                    </UnlockButton>
                </EmptyStateContent>
                <EpisodeAISubscriptionInfoModal
                    isOpen={aiSubscriptionModalOpen}
                    onOpenChange={setAiSubscriptionModalOpen}
                />
            </EmptyState>
        );
    }

    if (customPromptSettings) {
        return (
            <Stack $gap="2.5rem">
                <Header>
                    <HeaderInfoWrapper>
                        <AILogo />
                        <Text variant="bodyL" color="--neutral500" fontWeight="--fw-semibold">
                            <FormattedMessage defaultMessage="Laissez la magie de AI suggérer le contenu de votre podcast." />
                        </Text>
                    </HeaderInfoWrapper>
                    <HeaderIllustration src={illustration} alt="Ausha Intelligence" />
                </Header>
                <Stack $gap="4rem">
                    <Section>
                        <Text color="--neutral500">
                            <FormattedMessage defaultMessage="Réglages" />
                        </Text>
                        <Container>
                            <SectionHeader>
                                <Title>
                                    <AIPreferencesIcon
                                        primaryColor="primary"
                                        secondaryColor="primary400"
                                    />
                                    <Text variant="bodyL" fontWeight="--fw-bold">
                                        <FormattedMessage defaultMessage="Style narratif" />
                                    </Text>
                                </Title>
                                <Text color="--neutral500">
                                    <FormattedMessage defaultMessage="Ajustez la tonalité de AI pour l'adapter à vos besoins ! Cela influencera le style d'écriture de l'ensemble du contenu généré." />
                                </Text>
                            </SectionHeader>
                            <AIPresetsForm
                                onSubmit={handleSubmitPresets}
                                isLoading={updateCustomPromptsSettings.isLoading}
                                defaultValues={defaultValues}
                            />
                        </Container>
                    </Section>
                    <Section>
                        <Text color="--neutral500">
                            <FormattedMessage defaultMessage="Préférences" />
                        </Text>
                        <Container>
                            <SectionHeader>
                                <Title>
                                    <AIPreferencesIcon
                                        primaryColor="primary"
                                        secondaryColor="primary400"
                                    />
                                    <Text variant="bodyL" fontWeight="--fw-bold">
                                        <FormattedMessage defaultMessage="AI préférences" />
                                    </Text>
                                </Title>
                                <Text color="--neutral500">
                                    <FormattedMessage defaultMessage="Personnalisez la façon dont AI génère le contenu de vos épisodes en lui donnant des instructions spécifiques." />
                                </Text>
                            </SectionHeader>
                            <AISettingsForm
                                onSubmit={handleSubmitSettings}
                                isLoading={updateCustomPromptsSettings.isLoading}
                                defaultValues={defaultValues}
                            />
                        </Container>
                    </Section>
                </Stack>
            </Stack>
        );
    }

    if (isError) return <div>Error</div>;

    return <ShortListFallback />;
};

const Header = styled.div`
    display: flex;
    padding-left: 2rem;
    padding-right: 2rem;
    column-gap: 2rem;
    border-radius: var(--r-l);
    background: var(--gradient-aurora);

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        padding-right: 0;
    }
`;
const HeaderInfoWrapper = styled.div`
    padding-block: 2rem;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
`;
const HeaderIllustration = styled.img`
    display: none;
    align-self: flex-end;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: block;
    }
`;
const Section = styled.section`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    padding: var(--container-padding-mobile);
    background-color: var(--white);
    border-radius: var(--r-l);

    ${(props) => props.theme.mediaQueries.desktopAndUp} {
        padding: 1.5rem;
    }
`;
const SectionHeader = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--sp-xs);
`;
const Title = styled.div`
    display: flex;
    align-items: center;
    gap: var(--sp-xs);
`;
const EmptyStateContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--sp-xl);
`;
const EmptyStateMessage = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--sp-xs);
`;
const UnlockButton = styled(Button)`
    width: fit-content;
`;
const SparklesIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: var(--white);
`;
const AILogo = styled(AushaIntelligenceLogo)`
    width: 100%;
    max-width: 308px;
    height: 2rem;
`;

export default AISettings;
