import { useState, useMemo, useEffect } from 'react';
import Confetti from 'react-dom-confetti';
import CongratulateEpisodeSummary from '../organisms/CongratulateEpisodeSummary';
import Cluster from '@ui/layout/Cluster';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import useEpisodeQuery from '@/queries/episode/useEpisodeQuery.hook';
import { EPISODE_STATUS } from '@/utils/constants';
import useInternalRouteMapping from '@/utils/hooks/useInternalRouteMapping.hook';
import SummaryEpisode from '../organisms/SummaryEpisode';

const SummaryEpisodeWrapper = styled.div`
    max-width: 960px;
    padding: ${(p) => (p.theme.mediaQueries.mobile ? '1.5rem 1rem' : '2rem')};
    margin: auto;
`;

const Base = styled.div`
    background: white;
    border-radius: var(--r-xs);
`;

const CongratulateWrapper = styled(Base)`
    padding: ${(p) => (p.theme.mediaQueries.mobile ? '1.5rem 1rem' : '2rem')};
    margin-bottom: 25px;
`;

const SummaryWrapper = styled(Base)`
    padding-block: 75px;
    padding-inline: ${(p) => (p.theme.mediaQueries.mobile ? '1.5rem 1rem' : '2rem')};
`;

const config = {
    angle: '90',
    spread: '50',
    startVelocity: '42',
    elementCount: '50',
    dragFriction: '0.07',
    duration: '2500',
    stagger: '0',
    width: '12px',
    height: '5px',
    colors: ['#5352ED', '#2ED573', '#FF4757'],
};

const SummaryEpisodePage = () => {
    const { episodeId } = useParams();
    const { data: episode } = useEpisodeQuery({ episodeId });
    const { push } = useInternalRouteMapping();
    const [confetti, setConfetti] = useState(false);

    // UseMemo is use to check podcast status and don't try to render this component
    useMemo(() => {
        if (episode?.state === EPISODE_STATUS.DRAFT) {
            push('menu.episodes');
        }
    }, [episode]);

    useEffect(() => {
        setTimeout(() => setConfetti(true), 1000);
        return () => setConfetti(false);
    }, []);

    return (
        <SummaryEpisodeWrapper>
            <CongratulateWrapper>
                <CongratulateEpisodeSummary />
            </CongratulateWrapper>
            <Cluster $align="center" $justify="center">
                <Confetti active={confetti} config={config} />
            </Cluster>
            <SummaryWrapper>
                <SummaryEpisode />
            </SummaryWrapper>
        </SummaryEpisodeWrapper>
    );
};

export default SummaryEpisodePage;
