import { createApi } from '../callApi';
const contentApi = createApi('CONTENT');

export const fetchEpisodeTranscript = (episodeId) => {
    return contentApi({ target: `/v1/podcasts/${episodeId}/transcript` });
};
export const fetchEpisodeTranscriptStatus = (episodeId) => {
    return contentApi({ target: `/v1/podcasts/${episodeId}/transcript/status` });
};
export const generateEpisodeTranscript = (episodeId) => {
    return contentApi({ target: `/v1/podcasts/${episodeId}/transcript`, method: 'POST' });
};
export const updateEpisodeTranscript = (episodeId, transcript) => {
    return contentApi({
        target: `/v1/podcasts/${episodeId}/transcript`,
        method: 'PUT',
        body: transcript,
    });
};
export const deleteEpisodeTranscript = (episodeId) => {
    return contentApi({ target: `/v1/podcasts/${episodeId}/transcript`, method: 'DELETE' });
};
