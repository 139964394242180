import { z } from 'zod';

const useApplePodcastsTokenFormSchema = () => {
    return z.object({
        token: z.string().nullable(),
    });
};

export type ApplePodcastsTokenFormSchema = z.infer<
    ReturnType<typeof useApplePodcastsTokenFormSchema>
>;

export default useApplePodcastsTokenFormSchema;
