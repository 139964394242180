import { useToastQueue } from '@react-stately/toast';
import { UI_VARIANTS } from '../constants';

export const useCustomToastQueue = (toastQueue) => {
    const state = useToastQueue(toastQueue);
    const add = (content, options) => {
        state.add(content, { timeout: 5000, ...options });
    };
    return {
        ...state,
        add,
        info: (content, options) => add(content, { ...options, variant: UI_VARIANTS.INFO }),
        success: (content, options) => add(content, { ...options, variant: UI_VARIANTS.SUCCESS }),
        alert: (content, options) => add(content, { ...options, variant: UI_VARIANTS.ALERT }),
        warning: (content, options) => add(content, { ...options, variant: UI_VARIANTS.WARNING }),
        neutral: (content, options) => add(content, { ...options, variant: UI_VARIANTS.NEUTRAL }),
    };
};
