import { action, extendObservable } from 'mobx';

class Store {
    constructor(store) {
        this.state = store;
        extendObservable(this, this.constructor.observables || {});
    }

    @action
    reset() {
        Object.entries(this.constructor.observables || {}).forEach(([key, val]) => {
            this[key] = val;
        });
    }
}

export default Store;
