import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled, { css, keyframes } from 'styled-components';

const SuggestionsSkeleton = () => {
    return (
        <SuggestionsSkeletonWrapper>
            <Suggestions>
                {Array.from({ length: 5 }).map((_, index) => (
                    <Suggestion key={index}>
                        <Bar />
                        <IconWrapper>
                            <FontAwesomeIcon
                                icon={icon({ name: 'plus', style: 'solid' })}
                                color="white"
                            />
                        </IconWrapper>
                    </Suggestion>
                ))}
            </Suggestions>
        </SuggestionsSkeletonWrapper>
    );
};

const shimmer = keyframes`
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
`;
const shimmering = css`
    background: var(--neutral50);
    background-image: linear-gradient(
        to right,
        var(--neutral50) 0%,
        #edeef1 20%,
        var(--neutral50) 40%,
        var(--neutral50) 100%
    );
    background-repeat: no-repeat;
    background-size: 800px 104px;
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${shimmer};
    animation-timing-function: linear;
`;
const SuggestionsSkeletonWrapper = styled.div``;
const Suggestions = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    list-style: none;
    margin: 0;
    padding: 0;
`;
const Suggestion = styled.li`
    width: 100%;
    height: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem;
    border-radius: var(--r-m);
    background-color: white;
    box-shadow: var(--s-xs);
`;
const Bar = styled.div`
    width: 7.5rem;
    height: 0.5rem;
    border-radius: var(--r-full);
    background-color: var(--neutral100);
    ${shimmering};
`;
const IconWrapper = styled.div`
    width: 1.5rem;
    height: 1.5rem;
    border-radius: var(--r-full);
    background-color: var(--neutral100);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    ${shimmering};
`;

export default SuggestionsSkeleton;
