import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deletePost } from '@/api';
import camelcaseKeys from 'camelcase-keys';

const transform = (data) => {
    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const mutationFn = async ({ postId }) => {
    const { data } = await deletePost(postId);
    return transform(data);
};

const useDeleteSocialNetworkPostMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: () => {
            queryClient.invalidateQueries();
        },
    });
};

export default useDeleteSocialNetworkPostMutation;
