import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import DaysRangeSelect from './DaysRangeSelect';
interface DownloadsComparisonHeaderProps {
    daysAfterPublication: 7 | 15 | 30 | 90;
    setDaysAfterPublication: (value: 7 | 15 | 30 | 90) => void;
    isActive: boolean;
}

const DownloadsComparisonHeader = ({
    daysAfterPublication,
    setDaysAfterPublication,
    isActive,
}: DownloadsComparisonHeaderProps) => (
    <DownloadsComparisonHeaderWrapper>
        <TitleAndDescription>
            <Title>
                <FormattedMessage defaultMessage="Comparaison de vos épisodes" />
            </Title>
            <Description>
                <FormattedMessage defaultMessage="Nombre d’écoutes par rapport au premier jour de publication, qui performe le mieux ?" />
            </Description>
        </TitleAndDescription>
        <DaysRangeSelect
            value={daysAfterPublication}
            onChange={setDaysAfterPublication}
            isDisabled={!isActive}
        />
    </DownloadsComparisonHeaderWrapper>
);

const DownloadsComparisonHeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    justify-content: space-between;

    @media (max-width: 800px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;
const TitleAndDescription = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    @media (max-width: 800px) {
        flex-basis: 100%;
        align-self: stretch;
    }
`;
const Title = styled.h2`
    font-weight: var(--fw-bold);
    font-size: var(--fs-heading-l);
`;
const Description = styled.p``;

export default DownloadsComparisonHeader;
