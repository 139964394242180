import { withTheme } from 'styled-components';

const createFallback = (Component) => {
    const createFallbackInner = (props) => {
        const Loader = withTheme((propsTheme) => {
            return props && <Component speed={1} {...props} {...propsTheme} />;
        });
        return <Loader />;
    };
    return createFallbackInner;
};

export default createFallback;
