import { useQuery } from '@tanstack/react-query';
import { fetchEpisodeTranscript } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import episodeTranscriptKeys from './episodeTranscriptKeys';

const transform = (data) => {
    // TODO: Do camelcase transformation in Axios interceptor
    const transformedData = camelcaseKeys(data, { deep: true });

    return transformedData;
};

const queryFn = async ({ queryKey: [{ episodeId }] }) => {
    const { data } = await fetchEpisodeTranscript(episodeId);

    return transform(data);
};

const useEpisodeTranscriptQuery = (episodeId) => {
    return useQuery({
        queryKey: episodeTranscriptKeys.detailByEpisodeId(episodeId),
        queryFn,
        enabled: !!episodeId,
        retry: false,
        refetchOnWindowFocus: false,
    });
};

export default useEpisodeTranscriptQuery;
