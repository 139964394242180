import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { ImportedEpisode } from '@/queries/rss/useImportedEpisodesQuery.hook';
import useCheckFeedIsRedirectedQuery from '@/queries/rss/useCheckFeedIsRedirectedQuery.hook';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import {
    ACCEPTED_DISTRIBUTION_PLATFORMS_TO_COMPLETE_RSS_IMPORT,
    AcceptedDistributionPlatformToCompleteRssImport,
} from '@/shared/config/constants';
import RedirectFeed from './RedirectFeed';
import SetupDistribution from './SetupDistribution/SetupDistribution';
import Episodes from './Episodes';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { PRICING } from '@/shared/config/pricing';
import useSubscriptionQuery from '@/queries/subscription/useSubscriptionQuery.hook';

interface RssProps {
    importedEpisodes: ImportedEpisode[];
    counters: {
        pending: number;
        processing: number;
        done: number;
        error: number;
        total: number;
    };
    onComplete: () => void;
}

const Rss = ({ importedEpisodes, counters, onComplete }: RssProps) => {
    const { showId } = useParams<{ showId: string }>();
    const { data: subscription } = useSubscriptionQuery();
    const [currentStep, setCurrentStep] = useState(1);
    const [isProcessing, setIsProcessing] = useState(false);
    const { data: show } = useShowQuery(showId);
    const { data: feedRedirectStatus } = useCheckFeedIsRedirectedQuery({ showId });
    const isFreemium = show?.ownerPricing === PRICING.FREEMIUM || subscription?.isFreemium;

    useEffect(() => {
        if (!counters) return;
        const processing = counters.processing > 0 || counters.pending > 0;

        if (!processing) {
            setCurrentStep(2);
        }
        setIsProcessing(processing);
    }, [counters]);

    useEffect(() => {
        if (feedRedirectStatus?.redirected) {
            setCurrentStep(3);
        }
    }, [feedRedirectStatus]);

    useEffect(() => {
        if (!show?.listeningLinks) return;

        const platformsWithLink = show?.listeningLinks.data.filter((link) => link.url);
        const isDistributionUpdated = platformsWithLink.some((link) =>
            ACCEPTED_DISTRIBUTION_PLATFORMS_TO_COMPLETE_RSS_IMPORT.includes(
                link.key as AcceptedDistributionPlatformToCompleteRssImport,
            ),
        );

        if (isDistributionUpdated) {
            setCurrentStep(4);
            onComplete();
        }
    }, [show?.listeningLinks]);

    const importedEpisodesWithErrorCount = counters.error;
    const hasErrors = importedEpisodesWithErrorCount > 0;

    return (
        <Steps>
            <Step $color={'--primary'}>
                {isProcessing ? (
                    <SpinnerIcon icon={icon({ name: 'spinner-third', style: 'solid' })} spin />
                ) : hasErrors ? (
                    <ErrorIcon icon={icon({ name: 'exclamation-circle', style: 'solid' })} />
                ) : 1 < currentStep ? (
                    <StepIcon icon={icon({ name: 'circle-check', style: 'solid' })} />
                ) : (
                    <StepIcon icon={icon({ name: 'circle', style: 'regular' })} />
                )}
                <Episodes
                    isProcessing={isProcessing}
                    importedEpisodes={importedEpisodes}
                    counters={counters}
                />
            </Step>
            <BarLink $color={currentStep < 2 ? '--neutral200' : '--primary'} />
            <Step $color={currentStep < 2 ? '--neutral200' : '--primary'}>
                {isFreemium ? (
                    <GemIcon icon={icon({ name: 'gem', style: 'duotone' })} />
                ) : 2 < currentStep ? (
                    <StepIcon icon={icon({ name: 'circle-check', style: 'solid' })} />
                ) : (
                    <StepIcon icon={icon({ name: 'circle', style: 'regular' })} />
                )}
                <RedirectFeed
                    isDisabled={!isFreemium && currentStep < 2}
                    isDone={currentStep > 2}
                />
            </Step>
            <BarLink $color={isFreemium || currentStep < 3 ? '--neutral200' : '--primary'} />
            <Step $color={currentStep < 3 ? '--neutral200' : '--primary'}>
                {isFreemium ? (
                    <GemIcon icon={icon({ name: 'gem', style: 'duotone' })} />
                ) : 3 < currentStep ? (
                    <StepIcon icon={icon({ name: 'circle-check', style: 'solid' })} />
                ) : (
                    <StepIcon icon={icon({ name: 'circle', style: 'regular' })} />
                )}
                <SetupDistribution
                    isDone={currentStep > 3}
                    isDisabled={!isFreemium && currentStep < 3}
                />
            </Step>
        </Steps>
    );
};

const Step = styled.li<{ $color: string }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    color: ${({ $color }) => `var(${$color})`};
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 12.5rem;
    max-width: 12.5rem;
    scroll-snap-align: start;

    @media (min-width: 1096px) {
        flex: 1;
        flex-basis: 16rem;
        max-width: 16rem;
    }
`;
const BarLink = styled.div<{ $color: string }>`
    height: 0.125rem;
    background-color: ${({ $color }) => `var(${$color})`};
    border-radius: var(--r-full);

    @media (min-width: 1096px) {
        flex-grow: 1;
    }

    @media (max-width: 1095px) {
        flex-shrink: 0;
        width: 2rem;
    }
`;
const StepIcon = styled(FontAwesomeIcon)`
    font-size: 1.5rem;
`;
const Steps = styled.ul`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    margin: 0;
    padding: 1.5rem;
    list-style: none;
    overflow-y: auto;
    overscroll-behavior-y: contain;
    scroll-snap-type: x mandatory;
    scroll-snap-points-y: repeat(20.125rem);
    scrollbar-width: none; /* Hide scrollbar for Firefox */
    -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */

    .scroll-container::-webkit-scrollbar {
        display: none; /* Hide scrollbar for Chrome/Safari */
    }
`;
const SpinnerIcon = styled(FontAwesomeIcon)`
    width: 1.5rem;
    height: 1.5rem;
    color: var(--primary500);
`;
const GemIcon = styled(FontAwesomeIcon)`
    width: 1.5rem;
    height: 1.5rem;
    color: var(--primary500);
`;
const ErrorIcon = styled(FontAwesomeIcon)`
    width: 1.5rem;
    height: 1.5rem;
    color: var(--alert500);
`;

export default Rss;
