import IconButton from '@/components/ui/atoms/IconButton';
import Modal from '@/components/ui/atoms/Modal';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ZebraIllustration from '@/components/ui/atoms/ZebraIllustration';
import ShiningDiamond from '@/components/ui/atoms/ShiningDiamond';
import Text from '@/components/ui/atoms/Text';
import { FormattedMessage } from 'react-intl';
import Stack from '@/components/ui/layout/Stack';
import Button from '@/components/Button';
import { PRICING } from '@/shared/config/pricing';
import useSubscriptionQuery from '@/queries/subscription/useSubscriptionQuery.hook';
import RouterLink from '@/components/Link/RouterLink';
import ExternalLink from '@/components/Link/ExternalLink';
import styled from 'styled-components';
import { useIntercom } from 'react-use-intercom';

const Feature = ({ children }: { children: React.ReactNode }) => (
    <FeatureWrapper>
        <FeatureCheckIcon icon={icon({ name: 'circle-check', style: 'solid' })} />
        <Text>{children}</Text>
    </FeatureWrapper>
);

const BoostContent = () => {
    return (
        <>
            <UnlockFeatures>
                <Text variant="headingS" fontWeight="--fw-semibold">
                    <FormattedMessage defaultMessage="Vous débloquerez..." />
                </Text>
                <Features>
                    <FeatureList>
                        <Feature>
                            <FormattedMessage defaultMessage="Playlists privées avec mot de passe" />
                        </Feature>
                        <Feature>
                            <FormattedMessage defaultMessage="Statistiques démographiques" />
                        </Feature>
                        <Feature>
                            <FormattedMessage defaultMessage="Nombre d'écoutes par ville" />
                        </Feature>
                        <Feature>
                            <FormattedMessage defaultMessage="Mesures comparatives de l'audience par épisode (jusqu'à 7)" />
                        </Feature>
                        <Feature>
                            <FormattedMessage defaultMessage="Gestion de boutons personnalisés" />
                        </Feature>
                    </FeatureList>
                    <FeatureList>
                        <Feature>
                            <FormattedMessage defaultMessage="API / Webhooks" />
                        </Feature>
                        <Feature>
                            <FormattedMessage defaultMessage="24 clips vidéo/mois" />
                        </Feature>
                        <Feature>
                            <FormattedMessage defaultMessage="5 émissions archivées" />
                        </Feature>
                        <Feature>
                            <FormattedMessage defaultMessage="5 utilisateurs" />
                        </Feature>
                    </FeatureList>
                </Features>
            </UnlockFeatures>
            <RouterLink to="/app/subscription/offers" variant="button-primary">
                <RocketIcon icon={icon({ name: 'rocket-launch', style: 'solid' })} />
                <FormattedMessage defaultMessage="Mettre à niveau mon offre" />
            </RouterLink>
        </>
    );
};

const SupersonicContent = () => {
    const intercom = useIntercom();

    return (
        <Button
            onPress={() => intercom.show()}
            startIcon={<ContactIcon icon={icon({ name: 'paper-plane', style: 'solid' })} />}
        >
            <FormattedMessage defaultMessage="Nous contacter" />
        </Button>
    );
};
const EnterpriseContent = () => (
    <ExternalLink href="mailto:sales@ausha.co" variant="button-primary">
        <ContactIcon icon={icon({ name: 'paper-plane', style: 'solid' })} />
        <FormattedMessage defaultMessage="Nous contacter" />
    </ExternalLink>
);

const pricingContent = {
    [PRICING.BOOST]: <BoostContent />,
    [PRICING.SUPERSONIC]: <SupersonicContent />,
    [PRICING.ENTERPRISE]: <EnterpriseContent />,
};

const pricingDescription = {
    [PRICING.BOOST]: <FormattedMessage defaultMessage="abonnez-vous à l’offre Supersonic 🚀" />,
    [PRICING.SUPERSONIC]: <FormattedMessage defaultMessage="contactez-nous 🚀" />,
    [PRICING.ENTERPRISE]: <FormattedMessage defaultMessage="contactez-nous 🚀" />,
};

interface GuestsQuotaReachedModalProps {
    isOpen: boolean;
    onOpenChange: (isOpen: boolean) => void;
}

const GuestsQuotaReachedModal = ({ isOpen, onOpenChange }: GuestsQuotaReachedModalProps) => {
    const { data: subscription } = useSubscriptionQuery();

    return (
        <ModalStyled isOpen={isOpen} onOpenChange={onOpenChange} size="medium">
            <CloseButton
                icon={<CloseIcon icon={icon({ name: 'xmark-large', style: 'solid' })} />}
                onPress={() => onOpenChange(false)}
                variant="ghost"
                size="small"
            />
            <ZebraIllustration>
                <ShiningDiamond />
            </ZebraIllustration>
            <Stack $gap="0.25rem" $align="center">
                <Text variant="headingM" fontWeight="--fw-bold" textAlign="center">
                    <FormattedMessage defaultMessage="Vous avez atteint la limite d’utilisateurs de votre offre" />
                </Text>
                <Text color="--neutral500" textAlign="center">
                    <FormattedMessage
                        defaultMessage="Pour ajouter d’avantage d’utilisateurs, {description}"
                        values={{
                            description: (
                                <Text as="span" fontWeight="--fw-semibold">
                                    {subscription && pricingDescription[subscription?.pricing]}
                                </Text>
                            ),
                        }}
                    />
                </Text>
            </Stack>
            {subscription && pricingContent[subscription?.pricing]}
        </ModalStyled>
    );
};

const ModalStyled = styled(Modal)`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1.5rem;
    padding: 1.5rem;
`;
const CloseIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
    width: 1rem;
    height: 1rem;
`;
const RocketIcon = styled(FontAwesomeIcon)`
    color: var(--white);
    width: 1rem;
    height: 1rem;
`;
const FeatureCheckIcon = styled(FontAwesomeIcon)`
    color: var(--primary);
    width: 0.75rem;
    height: 0.75rem;
`;
const CloseButton = styled(IconButton)`
    padding: 0;
    align-self: flex-end;
`;
const UnlockFeatures = styled.div`
    padding: 1.5rem;
    background-color: var(--primary50);
    border-radius: var(--r-l);
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
`;
const Features = styled.div`
    display: flex;
    column-gap: 1rem;
`;
const FeatureList = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    flex: 1;
`;
const FeatureWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
`;
const ContactIcon = styled(FontAwesomeIcon)`
    color: var(--white);
    width: 1rem;
    height: 1rem;
`;

export default GuestsQuotaReachedModal;
