import { useEffect, useRef } from 'react';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import TableBody from './TableBody';
import TableHead from './TableHead';
import styled from 'styled-components';

export interface VariationRow {
    id: string;
    name: string;
    data: string[];
}

interface TableProps {
    data: VariationRow[];
    highlightedColumnIndex?: number;
}

const Table = ({ data, highlightedColumnIndex }: TableProps) => {
    const tableRef = useRef<HTMLTableElement | null>(null);
    const columnHelper = createColumnHelper<VariationRow>();

    const defaultColumns = [
        columnHelper.accessor('name', {
            cell: (info) => (
                <EpisodeNameCell>
                    <strong>{info.renderValue()}</strong>
                </EpisodeNameCell>
            ),
            header: () => null,
            minSize: 200,
        }),
        ...data[0].data.map((_, index) => {
            return columnHelper.accessor((row) => row.data[index], {
                id: `downloads-${index}`,
                cell: (info) => {
                    const value = info.getValue();
                    if (info.row.original.id === 'dates') {
                        return <DateCell>{value}</DateCell>;
                    }
                    if (info.row.original.id === 'variations') {
                        return (
                            <VariationCell
                                $color={
                                    parseInt(value) === 0
                                        ? '--black'
                                        : parseInt(value) > 0
                                        ? '--success'
                                        : '--alert'
                                }
                            >
                                {parseInt(value) > 0 ? '+' : ''}
                                {parseInt(value) === 0 ? '-' : `${value}%`}
                            </VariationCell>
                        );
                    }
                    return <span>{value}</span>;
                },
                header: () => null,
                size: 100,
                minSize: 100,
            });
        }),
    ];

    const table = useReactTable({
        data,
        columns: defaultColumns,
        getCoreRowModel: getCoreRowModel(),
        enableColumnResizing: false,
        initialState: {
            columnPinning: {
                left: ['name'],
            },
        },
    });

    useEffect(() => {
        if (highlightedColumnIndex === -1) {
            return;
        }

        if (tableRef.current) {
            const columnElement = tableRef.current.querySelector(
                `th[data-column-id='downloads-${highlightedColumnIndex}']`,
            );
            if (columnElement) {
                columnElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }
        }
    }, [highlightedColumnIndex]);

    return (
        <Container>
            <TableElement ref={tableRef}>
                <TableHead table={table} />
                <TableBody table={table} highlightedColumnIndex={highlightedColumnIndex} />
            </TableElement>
        </Container>
    );
};

const Container = styled.div`
    width: 100%;
    overflow-x: scroll;
    position: relative;
    border-radius: var(--r-s);
`;
const TableElement = styled.table`
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;

    th,
    td {
        border: 1px solid var(--neutral100);
    }

    tr:first-child th {
        border-top: none;
    }
    tr:last-child td {
        border-bottom: none;
    }
    tr th:first-child,
    tr td:first-child {
        border-left: none;
    }
    tr th:last-child,
    tr td:last-child {
        border-right: none;
    }
`;
const EpisodeNameCell = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.75rem;
    align-items: center;
`;
const DateCell = styled.div`
    color: var(--neutral);
`;
const VariationCell = styled.div<{ $color?: string }>`
    color: ${({ $color }) => `var(${$color})` ?? 'var(--black)'};
`;

export default Table;
