import { FormattedMessage } from 'react-intl';
import linkIcon from '@public/icons/link-symbol.svg';
import smartLinksIcon from '@public/icons/smartlink-icon.svg';
import playerIcon from '@public/icons/player-active.svg';
import musical from '@public/icons/musical-note.svg';
import eyeOff from '@public/icons/eye-off.svg';
import DeprecatedIcon from '@ui/atoms/DeprecatedIcon';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import EpisodeUsefulLinksEnable from './EpisodeUsefulLinksEnable';
import { commonMessages, statusValue } from '@/helpers';
import { useParams } from 'react-router-dom';
import useEpisodeQuery from '@/queries/episode/useEpisodeQuery.hook';
import { EPISODE_PRIVACY } from '@/utils/constants';

const PODCAST_PAGE = 'podcast-page';
const CUSTOM_LINK = 'custom-link';
const SMART_LINK = 'smartlink';
const PLAYER = 'player';
const AUDIO_FILE = 'audio-file-url';
const ALL_USEFUL_LINKS = [PODCAST_PAGE, CUSTOM_LINK, SMART_LINK, PLAYER, AUDIO_FILE];

const USEFUL_LINKS = {
    [PODCAST_PAGE]: {
        link: 'siteUrl',
        label: <FormattedMessage defaultMessage="Site Web" />,
        icon: <DeprecatedIcon accent="secondary" as={linkIcon} size={14} />,
        isOpenLink: true,
        isPublishedDate: true,
    },
    [CUSTOM_LINK]: {
        link: 'customUrl',
        label: <FormattedMessage defaultMessage="Lien personnalisé" />,
        icon: <DeprecatedIcon accent="secondary" as={linkIcon} size={14} />,
        isOpenLink: true,
        isPublishedDate: true,
    },
    [SMART_LINK]: {
        link: 'smartlinkUrl',
        label: <FormattedMessage defaultMessage="Smartlink" />,
        icon: <DeprecatedIcon accent="secondary" as={smartLinksIcon} size={14} />,
        isOpenLink: true,
        isPublishedDate: true,
    },
    [PLAYER]: {
        label: <FormattedMessage defaultMessage="Smartplayer" />,
        buttonLabel: <FormattedMessage defaultMessage="Voir le Smartplayer" />,
        icon: <DeprecatedIcon accent="secondary" as={playerIcon} size={16} p={7} />,
        isNavigationLink: true,
        isOpenLink: true,
    },
    [AUDIO_FILE]: {
        link: 'audioUrl',
        label: <FormattedMessage defaultMessage="Fichier mp3" />,
        icon: <DeprecatedIcon accent="secondary" as={musical} size={14} />,
        isCopyURL: true,
    },
    [EPISODE_PRIVACY.PRIVATE]: {
        label: (
            <FormattedMessage
                defaultMessage="Cet épisode est{privacy}. Le Site Web, le Smartplayer et le Smartlink ne sont donc pas disponibles."
                values={{
                    privacy: (
                        <DeprecatedText as="span" pl={5} textTransform="lowercase">
                            {commonMessages('privacy', 'private')}
                        </DeprecatedText>
                    ),
                }}
            />
        ),
        icon: <DeprecatedIcon as={eyeOff} size={14} color="--neutral500" mr={22} />,
    },
};

const getUtilsLinks = (linksName) => linksName.map((link) => USEFUL_LINKS[link]);

const UsefulLinksPrivacy = ({ episode, border }) => {
    switch (statusValue({ status: episode?.state, privacy: episode?.privacy })) {
        case EPISODE_PRIVACY.PRIVATE:
            return (
                <EpisodeUsefulLinksEnable
                    usefulLinksList={getUtilsLinks([EPISODE_PRIVACY.PRIVATE])}
                    border={border}
                />
            );
        case EPISODE_PRIVACY.UNLISTED:
            return (
                <EpisodeUsefulLinksEnable
                    usefulLinksList={getUtilsLinks([PODCAST_PAGE, CUSTOM_LINK, PLAYER, AUDIO_FILE])}
                    border={border}
                />
            );
        default:
            return (
                <EpisodeUsefulLinksEnable
                    usefulLinksList={getUtilsLinks(ALL_USEFUL_LINKS)}
                    border={border}
                />
            );
    }
};

const EpisodeUsefulLinks = ({ title, border }) => {
    const params = useParams();
    const { data: episode } = useEpisodeQuery({ episodeId: params?.episodeId || params?.podId });

    return (
        <>
            {episode?.privacy !== EPISODE_PRIVACY.PRIVATE && title && (
                <DeprecatedText weight="bold" size="veryBig" mt={35} mb={20}>
                    {title}
                </DeprecatedText>
            )}
            <UsefulLinksPrivacy episode={episode} border={border} />
        </>
    );
};

export default EpisodeUsefulLinks;
