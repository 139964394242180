import { useRef } from 'react';
import {
    Button as AriaButton,
    Select as AriaSelect,
    ListBox as AriaListBox,
    SelectValue,
} from 'react-aria-components';
import type { SelectProps as AriaSelectProps } from 'react-aria-components';
import Label from '../Label';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon as iconFA } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled, { css } from 'styled-components';
import Spinner from '../Spinner';
import SelectDropdown from './SelectDropdown';
import { FormattedMessage } from 'react-intl';
import { ListBoxItem } from '../ListBoxItem';

export interface SelectProps extends AriaSelectProps<ListBoxItem> {
    value?: any;
    isOptional?: boolean;
    isRequired?: boolean;
    tooltip?: string | React.ReactNode;
    description?: string;
    label?: string | React.ReactNode;
    icon?: React.ReactNode;
    isValid?: boolean;
    isLoading?: boolean;
    onLoadMore?: () => void;
    loadMoreEnabled?: boolean;
    inputHasImage?: boolean;
    items: ListBoxItem[];
    customAction?: React.ReactNode;
}

const Select = ({
    isOptional = false,
    isRequired = false,
    tooltip,
    description,
    label,
    children,
    icon = null,
    isValid = false,
    isLoading = false,
    onLoadMore,
    loadMoreEnabled = false,
    inputHasImage = false,
    items,
    customAction,
    ...props
}: SelectProps) => {
    const triggerRef = useRef<HTMLDivElement>(null);

    return (
        <SelectWrapper {...props} $isValid={isValid} ref={triggerRef}>
            <Label
                isOptional={isOptional}
                isRequired={isRequired}
                tooltip={tooltip}
                description={description}
            >
                {label}
            </Label>
            <InputButton $hasImage={inputHasImage ?? false}>
                {icon}
                <InputSelectValue />
                {isLoading ? (
                    <Spinner size={12} />
                ) : (
                    <ChevronIcon icon={iconFA({ name: 'chevron-down', style: 'solid' })} />
                )}
            </InputButton>
            <SelectDropdown
                shouldFlip={false}
                placement="bottom"
                offset={4}
                triggerRef={triggerRef}
            >
                <ListBox items={items}>{children}</ListBox>
                {customAction}
                {loadMoreEnabled && (
                    <FetchMoreButton onClick={onLoadMore}>
                        <FormattedMessage defaultMessage="Charger plus" />
                    </FetchMoreButton>
                )}
            </SelectDropdown>
        </SelectWrapper>
    );
};

const InputButton = styled(AriaButton)<{ $hasImage: boolean }>`
    border-radius: var(--r-s);
    border: 1px solid var(--neutral200);
    background-color: var(--white);
    padding: 0.625rem 0.75rem;
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    height: 2.5rem;

    &:hover {
        border-color: var(--neutral300);
    }

    &:focus-within {
        outline: 0.125rem var(--primary50) solid;
    }

    ${({ $hasImage }) =>
        $hasImage &&
        css`
            padding: 0.75rem;
            height: 4rem;
        `}
`;
const InputSelectValue = styled(SelectValue)`
    flex: 1;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    .ListBoxItem__inner {
        padding: 0;
    }
`;
const SelectWrapper = styled(AriaSelect)<{ $isValid: boolean }>`
    width: 100%;
    max-width: 320px;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;

    &[data-disabled] > ${InputButton} {
        opacity: 0.5;
        cursor: not-allowed;
    }

    &[data-invalid] > ${InputButton} {
        border-color: var(--alert);

        &:focus-within {
            outline: 0.125rem var(--alert50) solid;
        }
    }

    ${({ $isValid }) =>
        $isValid &&
        css`
            & > ${InputButton} {
                border-color: var(--success);

                &:focus-within {
                    outline: 0.125rem var(--success50) solid;
                }
            }
        `}
`;
const ChevronIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: var(--neutral500);
`;
const ListBox = styled(AriaListBox)`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;
const FetchMoreButton = styled.button`
    border: none;
    background: none;
    color: var(--primary);
    font-size: var(--fs-body-m);
    line-height: var(--lh-body-m);
    font-weight: var(--fw-semibold);
    padding: 0.5rem 0.75rem;
    cursor: pointer;
`;

export default Select;
