import { useParams } from 'react-router';
import updateAushaProShowSettingsMutation from './updateAushaProMutation.hook';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import AushaProShowSettingsForm from './Form';
import { AushaProFormSchema } from './Form/useFormSchema.hook';
import { useBodyToastQueue } from '@/shared/hooks/useBodyToastQueue.hook';
import styled from 'styled-components';
import Text from '@/components/ui/atoms/Text';
import { FormattedMessage } from 'react-intl';
import ShortListFallback from '@/components/unorganized/ShortListFallback';

const AushaProShowSettings = () => {
    const { showId } = useParams<{ showId: string }>();
    const { data: show, isLoading } = useShowQuery(showId);
    const updateAushaProShowSettings = updateAushaProShowSettingsMutation();
    const toast = useBodyToastQueue();
    const defaultSettings = {
        darkLogo: show?.darkLogoUrl,
        lightLogo: show?.lightLogoUrl,
        favicon: show?.faviconUrl,
        whiteLabel: show?.whiteLabel,
        hasCustomLogo: !!show?.darkLogoUrl || !!show?.lightLogoUrl || !!show?.faviconUrl,
        logoLink: show?.logoLink || '',
    };

    const handleSubmit = ({ hasCustomLogo, ...data }: AushaProFormSchema) => {
        updateAushaProShowSettings.mutate(
            {
                showId,
                data,
            },
            {
                onSuccess: () => {
                    toast.success();
                },
                onError: () => {
                    toast.alert();
                },
            },
        );
    };

    if (isLoading)
        return (
            <Container>
                <Text variant="title" fontWeight="--fw-bold">
                    <FormattedMessage defaultMessage="Ausha Pro" />
                </Text>
                <ShortListFallback />
            </Container>
        );

    return (
        <Container>
            <Text variant="title" fontWeight="--fw-bold">
                <FormattedMessage defaultMessage="Ausha Pro" />
            </Text>
            <AushaProShowSettingsForm
                onSubmit={handleSubmit}
                isLoading={updateAushaProShowSettings.isLoading}
                defaultValues={defaultSettings}
            />
        </Container>
    );
};

const Container = styled.div`
    padding: var(--container-padding-mobile);
    background-color: var(--white);
    border-radius: var(--r-xs);
    display: flex;
    flex-direction: column;
    row-gap: var(--sp-xl);

    ${(props) => props.theme.mediaQueries.desktopAndUp} {
        padding: var(--container-padding);
    }
`;

export default AushaProShowSettings;
