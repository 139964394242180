import { UseQueryResult, useQuery } from '@tanstack/react-query';
import camelcaseKeys from 'camelcase-keys';
import { fetchCategories } from '@/api';
import { z } from 'zod';
import categoryKeys from './categoryKeys';

interface ApiCategory {
    apple_id: number;
    id: number;
    name: string;
    parent_id: number | null;
    slug: string;
    subcategories: { data: Omit<ApiCategory, 'subcategories'>[] };
}

const CategorySchema = z.object({
    appleId: z.number(),
    id: z.number(),
    name: z.string(),
    parentId: z.number().nullable(),
    slug: z.string(),
    children: z.array(
        z.object({
            appleId: z.number(),
            id: z.number(),
            name: z.string(),
            parentId: z.number().nullable(),
            slug: z.string(),
        }),
    ),
});
export type Category = z.infer<typeof CategorySchema>;

const CategoriesSchema = z.array(CategorySchema);
export type Categories = z.infer<typeof CategoriesSchema>;

const queryFn = async () => {
    const { data } = await fetchCategories();
    const formattedData = data.map((item: ApiCategory) => {
        {
            const { subcategories, ...category } = item;
            return {
                ...category,
                children: subcategories.data, // Rename subcategories.data to children for better compatibility with RAC
            };
        }
    });

    // TODO: Do camelcase transformation in Axios interceptor
    return CategoriesSchema.parse(camelcaseKeys(formattedData, { deep: true }));
};

const useCategoriesQuery = (): UseQueryResult<Categories> => {
    return useQuery({
        queryKey: categoryKeys.all(),
        queryFn,
    });
};

export default useCategoriesQuery;
