import { useFormContext } from 'react-hook-form';
import Preview from '../Preview';
import { ClipFormSchema } from '../../../schemas/useFormSchema';
import { ClipTranscription } from '@/queries/clip/useClipTranscriptionQuery';
import styled from 'styled-components';
import { useResponsive } from '@/shared/hooks/useResponsive';
import Toggle from '@/components/ui/atoms/Toggle';
import { FormattedMessage } from 'react-intl';
import updateClipFormatMutation from '@/queries/clipFormat/updateClipFormatMutation';
import useClipFormatQuery from '@/queries/clipFormat/useClipFormatQuery';
import Text from '@/components/ui/atoms/Text';
import { FileTrigger } from 'react-aria-components';
import Button from '@/components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import uploadClipFormatImageMutation from '@/queries/clipFormat/uploadClipFormatImageMutation';
import { useBodyToastQueue } from '@/shared/hooks/useBodyToastQueue.hook';
import deleteClipFormatImageMutation from '@/queries/clipFormat/deleteClipFormatImageMutation';

interface SquarePreviewProps {
    transcription?: ClipTranscription | null;
    episodeImage?: string | null;
    clipFormatId: number;
}

const SquarePreview = ({ transcription, episodeImage, clipFormatId }: SquarePreviewProps) => {
    const { watch } = useFormContext<ClipFormSchema>();
    const {
        transcriptionSelection: { transcript },
        customization,
    } = watch();
    const { isMobile } = useResponsive();
    const updateClipFormat = updateClipFormatMutation();
    const uploadImage = uploadClipFormatImageMutation();
    const deleteImage = deleteClipFormatImageMutation();
    const { data: clipFormat } = useClipFormatQuery(clipFormatId);
    const toast = useBodyToastQueue();
    const hasImage = clipFormat?.images?.length > 0;

    if (!clipFormat) return null;

    const handleFullCoverToggle = () => {
        updateClipFormat.mutate({
            clipFormatId: clipFormatId,
            clipFormat: { fullCover: !clipFormat.fullCover },
        });
    };

    const handleUploadImage = (files: FileList | null) => {
        if (!files || files.length === 0) return;
        uploadImage.mutate(
            {
                clipFormatId,
                file: files[0],
            },
            {
                onError: () => {
                    toast.alert();
                },
            },
        );
    };

    const handleDeleteImage = () => {
        deleteImage.mutate(clipFormatId, {
            onError: () => {
                toast.alert();
            },
        });
    };

    return (
        <Wrapper>
            {/* @ts-ignore */}
            <Preview
                height={isMobile ? '12rem' : '20rem'}
                clip={{ ...customization, transcript }}
                format="square"
                transcription={transcription}
                episodeImageUrl={episodeImage}
                isLoading={uploadImage.isLoading || deleteImage.isLoading}
                clipFormat={clipFormat}
            />
            <Toggle isSelected={clipFormat.fullCover} onChange={handleFullCoverToggle}>
                <FormattedMessage defaultMessage="L’image s’ajuste à tout l’espace du clip." />
            </Toggle>
            <Actions>
                <FileTrigger
                    acceptedFileTypes={['image/png', 'image/jpg', 'image/jpeg']}
                    onSelect={handleUploadImage}
                    allowsMultiple={false}
                >
                    <Button
                        startIcon={
                            <ButtonIcon
                                icon={icon({
                                    name: 'arrow-up-from-line',
                                    style: 'solid',
                                })}
                            />
                        }
                        variant="tertiary"
                    >
                        <FormattedMessage defaultMessage="Modifier l’image de ce format" />
                    </Button>
                </FileTrigger>
                {hasImage && (
                    <DeleteButton
                        onPress={handleDeleteImage}
                        startIcon={
                            <ButtonIcon
                                icon={icon({
                                    name: 'trash',
                                    style: 'solid',
                                })}
                            />
                        }
                        variant="tertiary"
                    >
                        <FormattedMessage defaultMessage="Supprimer" />
                    </DeleteButton>
                )}
            </Actions>
            <FormatIndications>
                <Text color="--neutral500" variant="footnote">
                    <FormattedMessage defaultMessage="Taille idéale : 720*720px" />
                </Text>
                <Text color="--neutral500" variant="footnote">
                    <FormattedMessage defaultMessage="Format : JPG ou PNG" />{' '}
                </Text>
            </FormatIndications>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
`;
const FormatIndications = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
`;
const ButtonIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const DeleteButton = styled(Button)`
    color: var(--alert500);
`;
const Actions = styled.div`
    display: flex;
    column-gap: 0.5rem;
`;

export default SquarePreview;
