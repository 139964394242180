import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchChannelGuestUserPermissionsSummary } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import guestKeys from './guestKeys';
import { z } from 'zod';
import { ChannelGuestRoleSchema } from './useChannelGuestPermissionsQuery.hook';
import { GUEST_USER_ROLES } from '@/shared/config/constants';

export const ChannelGuestShowRoleSchema = z.enum([
    GUEST_USER_ROLES.ADMIN,
    GUEST_USER_ROLES.EDITOR,
    GUEST_USER_ROLES.VIEWER,
    'none',
] as const);

export type ChannelGuestShowRole = z.infer<typeof ChannelGuestShowRoleSchema>;

export const ChannelGuestUserPermissionsSummarySchema = z.object({
    mainRole: ChannelGuestRoleSchema,
    user: z.object({
        id: z.number(),
        name: z.string(),
        email: z.string(),
        avatar: z.string().nullable(),
    }),
    shows: z.array(
        z.object({
            id: z.number(),
            name: z.string(),
            image: z.string().nullable(),
            role: ChannelGuestShowRoleSchema,
        }),
    ),
});

type Key = QueryFunctionContext<ReturnType<typeof guestKeys.userPermissionList>>;

export type ChannelGuestUserPermissionsSummary = z.infer<
    typeof ChannelGuestUserPermissionsSummarySchema
>;

const queryFn = async ({ queryKey: [{ channelId, userId }] }: Key) => {
    const { data } = await fetchChannelGuestUserPermissionsSummary(channelId, userId);
    return ChannelGuestUserPermissionsSummarySchema.parse(camelcaseKeys(data, { deep: true }));
};

interface ChannelGuestUserPermissionsSummaryQueryProps {
    channelId: number | undefined;
    userId: number;
    options?: {
        enabled: boolean;
    };
}

const useChannelGuestUserPermissionsSummaryQuery = ({
    channelId,
    userId,
    options,
}: ChannelGuestUserPermissionsSummaryQueryProps) => {
    return useQuery({
        queryKey: guestKeys.userPermissionList(channelId, userId),
        queryFn,
        ...options,
    });
};

export default useChannelGuestUserPermissionsSummaryQuery;
