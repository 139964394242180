import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchShowSourcePlatforms } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import statsKeys from './statsKeys';
import { z } from 'zod';

export const PlatformSchema = z.object({
    count: z.number(),
    category: z.string(),
    percentage: z.number(),
});
export const PlatformsSchema = z.array(PlatformSchema);
const PlatformPaginationSchema = z.object({
    currentPage: z.number(),
    totalPages: z.number(),
    total: z.number().optional(),
    perPage: z.number().optional(),
});
const PlatformResponseSchema = z.object({
    data: PlatformsSchema,
    pagination: PlatformPaginationSchema,
});
export type Device = z.infer<typeof PlatformSchema>;
type Key = QueryFunctionContext<ReturnType<typeof statsKeys.sourcePlatforms>>;
type InputProps = {
    showId: string;
    episodes: string[];
    start: string;
    end: string;
    order: 'asc' | 'desc';
    page: number;
    timezone: string;
    options?: {
        enabled?: boolean;
    };
};

const queryFn = async ({
    queryKey: [{ showId, episodes, start, end, order, page, timezone }],
}: Key) => {
    const { data, pagination } = await fetchShowSourcePlatforms(showId, {
        podcasts: episodes,
        timezone,
        start,
        end,
        order,
        page,
    });
    return PlatformResponseSchema.parse(
        camelcaseKeys(
            {
                data,
                pagination: {
                    currentPage: pagination.current_page ?? 0,
                    totalPages: pagination.total_pages ?? 0,
                    total: pagination.total ?? 0,
                    perPage: pagination.per_page ?? 0,
                },
            },
            { deep: true },
        ),
    );
};

const useSourcePlatformsQuery = ({
    showId,
    episodes,
    start,
    end,
    order,
    page,
    timezone,
    options,
}: InputProps) => {
    return useQuery({
        queryKey: statsKeys.sourcePlatforms(showId, episodes, start, end, order, page, timezone),
        queryFn,
        enabled: options?.enabled || true,
    });
};

export default useSourcePlatformsQuery;
