import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import ExternalLink from '@/components/Link/ExternalLink';
import styled from 'styled-components';

interface LinkCellProps {
    to: string;
}

const LinkCell = ({ to }: LinkCellProps) => {
    if (!to) {
        return (
            <LinkCellWrapper>
                <NoLink>
                    <FontAwesomeIcon icon={icon({ name: 'arrow-up-right', style: 'solid' })} />
                </NoLink>
            </LinkCellWrapper>
        );
    }

    return (
        <LinkCellWrapper>
            <ExternalLink href={to} target="_blank">
                <ArrowIcon icon={icon({ name: 'arrow-up-right', style: 'solid' })} />
            </ExternalLink>
        </LinkCellWrapper>
    );
};

const ArrowIcon = styled(FontAwesomeIcon)`
    transition: transform 0.2s;
`;
const LinkCellWrapper = styled.div`
    height: 100%;
    self-align: stretch;

    & > * {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        self-align: stretch;
        padding: 1rem;
    }

    &:hover ${ArrowIcon} {
        transform: translate(0.15rem, -0.15rem);
    }
`;
const NoLink = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    self-align: stretch;
    padding: 1rem;
    color: var(--neutral500);
    cursor: not-allowed;
`;

export default LinkCell;
