import { FormattedMessage } from 'react-intl';
import ApplePodcastsDateSelector from './ApplePodcastsDateSelector';
import ApplePodcastsHeader from './ApplePodcastsHeader';
import ApplePodcastsRanking from './ApplePodcastsRanking';
import ApplePodcastsReviews from './ApplePodcastsReviews';
import ApplePodcastsSummary from './ApplePodcastsSummary';
import { useAmplitudeLogEvent } from '@/shared/hooks/useAmplitude';
import { USER_OPTIONS } from '@/shared/config/userOptions';
import Text from '@ui/atoms/Text';
import EmptyState from '@ui/molecules/EmptyState';
import noApplePodcastsIllustration from '@public/images/empty-state/illustration/reviews.mp4';
import ZebraIllustration from '@ui/atoms/ZebraIllustration';
import ShiningDiamond from '@ui/atoms/ShiningDiamond';
import IconRocket from '@ui/icons/IconRocket';
import RouterLink from '@/components/Link/RouterLink';
import { useParams } from 'react-router';
import useShowQuery from '@queries/show/useShowQuery.hook';
import { ApplePodcastsStatsContextProvider } from '@/context/ApplePodcastsStatsContext';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import styled from 'styled-components';

const ApplePodcasts = () => {
    const { showId } = useParams<{ showId: string }>();
    const { data: show } = useShowQuery(showId);
    const { data: user } = useUserQuery();
    useAmplitudeLogEvent('Stats Apple Tab Viewed');

    if (!show) {
        return null;
    }

    const isAuthorized = show
        ? show?.userOptions?.includes(USER_OPTIONS.APPLE_STATISTICS)
        : user
        ? user?.options?.includes(USER_OPTIONS.APPLE_STATISTICS)
        : undefined;

    return (
        <ApplePodcastsStatsContextProvider>
            <ApplePodcastsStatsWrapper>
                <Text variant="title" fontWeight="--fw-bold">
                    <FormattedMessage defaultMessage="Apple Podcasts" />
                </Text>
                {isAuthorized ? (
                    <>
                        <ApplePodcastsHeader />
                        <ApplePodcastsSummary />
                        <ApplePodcastsDateSelector />
                        <ApplePodcastsRanking />
                    </>
                ) : (
                    <EmptyStateOuterWrapper>
                        <EmptyState
                            illustration={noApplePodcastsIllustration}
                            illustrationType="video"
                            illustrationAlign="right"
                            illustrationBackground="--gradient-dusk"
                        >
                            <HeadIllustrationWrapper>
                                <ZebraIllustration>
                                    <ShiningDiamond />
                                </ZebraIllustration>
                            </HeadIllustrationWrapper>
                            <LabelWrapper>
                                <Text variant="heading" fontWeight="--fw-bold">
                                    <FormattedMessage defaultMessage="Passez à l'offre supérieure !" />
                                </Text>
                                <Text variant="body" fontWeight="--fw-normal" color="--neutral500">
                                    <FormattedMessage defaultMessage="Pour débloquer vos statistiques et vos avis Apple Podcasts, abonnez-vous à l'offre Boost ou Supersonic 🚀" />
                                </Text>
                            </LabelWrapper>
                            <RouterLink
                                to="/app/subscription/offers"
                                variant="button-primary"
                                startIcon={<IconRocket />}
                            >
                                <FormattedMessage defaultMessage="Mettre à niveau mon offre" />
                            </RouterLink>
                        </EmptyState>
                    </EmptyStateOuterWrapper>
                )}
                <ApplePodcastsReviews />
            </ApplePodcastsStatsWrapper>
        </ApplePodcastsStatsContextProvider>
    );
};

const ApplePodcastsStatsWrapper = styled.div``;
const EmptyStateOuterWrapper = styled.div`
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;
const HeadIllustrationWrapper = styled.div`
    width: 200px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    & > * {
        text-align: center;
    }
`;

export default ApplePodcasts;
