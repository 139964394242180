import PropTypes from 'prop-types';
import styled from 'styled-components';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import Img from '@ui/atoms/Img';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import DeprecatedIcon from '@ui/atoms/DeprecatedIcon';
import CheckIcon from '@public/icons/check.svg';

const Item = styled(DeprecatedPaper)`
    cursor: pointer;
    &:hover {
        background: ${(p) => (p.highLight ? 'var(--primary200)' : 'var(--neutral50)')};
        section {
            background: transparent;
        }
    }
`;

function ImgMenuItem({ img, icon, label, active, highLight, ...props }) {
    return (
        <Item
            height={50}
            pl={20}
            pr={15}
            width="100%"
            flex
            align="center"
            highLight={highLight}
            gap={8}
            {...props}
        >
            {icon ||
                (img !== undefined && (
                    <Img
                        mr={10}
                        size={35}
                        src={img}
                        bordered
                        style={{ opacity: active ? 1 : 0.5 }}
                    />
                ))}
            <DeprecatedText
                auto
                color={
                    (highLight && '--primary') ||
                    (active && '--black') ||
                    (img === undefined ? '--black' : '--neutral500')
                }
                weight={active || highLight ? 'semibold' : 'normal'}
                ellipsis
                maxW={350}
                as="span"
            >
                {label}
            </DeprecatedText>
            {active && <DeprecatedIcon as={CheckIcon} size={16} color="--primary" />}
        </Item>
    );
}

ImgMenuItem.propTypes = {
    icon: PropTypes.node,
    img: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    active: PropTypes.bool,
    highLight: PropTypes.bool,
};

ImgMenuItem.defaultProps = {
    icon: undefined,
    img: undefined,
    active: false,
    highLight: false,
};

export default ImgMenuItem;
