import { css } from 'styled-components';
import { ButtonProps } from '../../Button';
import { BaseLinkProps } from '@/components/Link/types';

export const mediumButtonStyles = css`
    padding: 0.625rem 1rem;
    column-gap: 0.5rem;
    font-size: var(--fs-body-m);
    line-height: var(--lh-body-m);

    ${({ $variant }: { $variant: ButtonProps['variant'] | BaseLinkProps['variant'] }) =>
        $variant === 'tertiary' &&
        css`
            padding: calc(0.625rem - 1px) calc(1rem - 1px);
        `}
`;
