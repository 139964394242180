import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Text from '@ui/atoms/Text';
import QuotaCard from '../QuotaCard';
import useSubscriptionQuery from '@queries/subscription/useSubscriptionQuery.hook';
import ExternalLink from '@/components/Link/ExternalLink';
import styled from 'styled-components';
import useArchivedShowsQuery from '@queries/show/useArchivedShowsQuery.hook';
import { useHistory } from 'react-router';

const ArchivedShowsQuota = () => {
    const history = useHistory();
    const intl = useIntl();
    const { data: shows } = useArchivedShowsQuery();
    const subscription = useSubscriptionQuery();

    const archivedShowAllowance = subscription?.data?.archivedShowsMax;
    const currentArchivedShows = shows?.shows?.length;
    return (
        <QuotaCard
            titleIcon={<FontAwesomeIcon icon={icon({ name: 'box-archive', style: 'solid' })} />}
            title={<FormattedMessage defaultMessage="Émissions archivées" />}
            currentValue={currentArchivedShows || 0}
            maxValue={archivedShowAllowance || 0}
            valueUnit={
                <FormattedMessage
                    defaultMessage="{count, plural, =0 {émission} one {émission} other {émissions}}"
                    values={{ count: archivedShowAllowance }}
                />
            }
            actionLabel={<FormattedMessage defaultMessage="Changer d'offre" />}
            onActionTriggered={() => history.push('/app/subscription/offers')}
            infoTooltipContent={
                <Text>
                    <FormattedMessage
                        defaultMessage="Vous avez {count, plural, =0 {émission archivée inclue} one {# émission archivée inclue} other {# émissions archivées inclues}} dans votre offre. <link>En savoir plus sur les émissions archivées</link>"
                        values={{
                            count: archivedShowAllowance,
                            link: (chunks: string) => (
                                <StyledExternalLink
                                    href={intl.formatMessage({
                                        defaultMessage:
                                            'https://help.ausha.co/fr/articles/7146553-quelle-est-la-difference-entre-une-emission-active-et-une-emission-archivee',
                                    })}
                                    target="_blank"
                                >
                                    {chunks}
                                </StyledExternalLink>
                            ),
                        }}
                    />
                </Text>
            }
        />
    );
};

const StyledExternalLink = styled(ExternalLink)`
    color: inherit;
    font-size: inherit;
    text-decoration: underline;
    font-weight: var(--fw-semibold);

    &:hover {
        color: inherit;
    }
    &:active {
        color: inherit;
    }
    &:visited {
        color: inherit;
    }
`;

export default ArchivedShowsQuota;
