import Text from '@/components/ui/atoms/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PsoMetadataTooltip from './PsoMetadataTooltip';
import { TooltipTrigger, Button as AriaButton } from 'react-aria-components';

const PsoMetadataCell = ({ occurrences, keyword }) => {
    const total = Object.values(occurrences)?.reduce(
        (total, currentValue) => total + currentValue,
        0,
    );
    return (
        <TooltipTrigger delay={0} closeDelay={0}>
            <MetadataWrapper>
                <Metadata>{total}</Metadata>
                {total > 0 ? (
                    <Icon icon={icon({ name: 'magnifying-glass', type: 'solid' })} />
                ) : (
                    <WarningIcon icon={icon({ name: 'triangle-exclamation', type: 'solid' })} />
                )}
            </MetadataWrapper>
            <PsoMetadataTooltip occurrences={occurrences} total={total} keyword={keyword} />
        </TooltipTrigger>
    );
};

const MetadataWrapper = styled(AriaButton)`
    display: inline-flex;
    align-items: center;
    justify-content: start;
    column-gap: 0.25rem;
    padding: 0;
    height: 100%;
    width: 100%;
    background: none;
    border: none;
`;
const Icon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
    width: 0.75rem;
    height: 0.75rem;
`;
const WarningIcon = styled(Icon)`
    color: var(--warning500);
`;
const Metadata = styled(Text)`
    min-width: 1rem;
`;

PsoMetadataCell.propTypes = {
    occurrences: PropTypes.shape({
        podcastsDescriptions: PropTypes.number,
        podcastsTitles: PropTypes.number,
        showDescription: PropTypes.number,
        showTitle: PropTypes.number,
    }),
    keyword: PropTypes.string,
};

export default PsoMetadataCell;
