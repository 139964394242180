import { useParams } from 'react-router-dom';
import EmptyState from '@ui/molecules/EmptyState';
import PsoIllustration from '@public/images/empty-state/illustration/pso.mp4';
import Button from '@/components/Button';
import RouterLink from '@/components/Link/RouterLink';
import ExternalLink from '@/components/Link/ExternalLink';
import Text from '@/components/ui/atoms/Text';
import { FormattedMessage, useIntl } from 'react-intl';
import ZebraIllustration from '@/components/ui/atoms/ZebraIllustration';
import ShiningDiamond from '@/components/ui/atoms/ShiningDiamond';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { PRICING } from '@/shared/config/pricing';
import { STATUS_SHOWS } from '@/shared/config/statusShows';
import useSubscriptionQuery from '@/queries/subscription/useSubscriptionQuery.hook';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import LockImage from '@public/images/lock.webp';
import { USER_OPTIONS } from '@/shared/config/userOptions';
import { sendAmplitudeLogEvent } from '@/shared/utils/amplitude';
import styled from 'styled-components';

const PsoBlockingScreen = () => {
    const intl = useIntl();
    const { showId }: { showId: string } = useParams();
    const { data: show } = useShowQuery(showId);
    const isGuestShow = show?.showStatus === STATUS_SHOWS.GUEST_SHOWS;
    const subscription = useSubscriptionQuery();
    const isEnterprise = isGuestShow
        ? show?.ownerPricing === PRICING.ENTERPRISE
        : subscription?.data?.pricing === PRICING.ENTERPRISE;

    return (
        <BlockingScreenWrapper>
            <TitleWrapper>
                <Title>
                    <FormattedMessage defaultMessage="Référencement" />
                </Title>
                <SubTitle>PSO Control Panel</SubTitle>
                {show?.userOptions.includes(USER_OPTIONS.PSO_BETA) && (
                    <BetaTag>
                        <FontAwesomeIcon icon={icon({ name: 'flask', style: 'solid' })} size="lg" />
                        <FormattedMessage defaultMessage="Bêta publique" />
                    </BetaTag>
                )}
            </TitleWrapper>

            <EmptyState
                illustration={PsoIllustration}
                illustrationType="video"
                illustrationAlign="right"
                illustrationBackground="--gradient-dusk"
            >
                <HeadIllustrationWrapper>
                    {/* @ts-ignore */}
                    <ZebraIllustration>
                        {isEnterprise ? <Lock src={LockImage} /> : <ShiningDiamond />}
                    </ZebraIllustration>
                </HeadIllustrationWrapper>
                <LabelWrapper>
                    {/* @ts-ignore */}
                    <Text variant="heading" fontWeight="--fw-bold">
                        {isEnterprise ? (
                            <FormattedMessage defaultMessage="Boostez votre visibilité sur les plateformes d'écoute" />
                        ) : (
                            <FormattedMessage defaultMessage="Passez à l'offre supérieure !" />
                        )}
                    </Text>
                    {/* @ts-ignore */}
                    <Text variant="body" fontWeight="--fw-normal" color="--neutral500">
                        {isEnterprise ? (
                            <FormattedMessage defaultMessage="Découvrez le PSO Control Panel d’Ausha, un outil complet pour analyser les performances de votre podcast dans les résultats de recherche sur les mots-clés de votre choix." />
                        ) : (
                            <FormattedMessage defaultMessage="Pour débloquer l'optimisation du référencement de votre podcast, abonnez-vous à l'offre Boost ou Supersonic 🚀" />
                        )}
                    </Text>
                </LabelWrapper>
                <ActionWrapper>
                    {isEnterprise ? (
                        <ExternalLink
                            variant="button-primary"
                            href="mailto:sales@ausha.co"
                            target="_blank"
                            startIcon={
                                <FontAwesomeIcon
                                    icon={icon({ name: 'paper-plane', style: 'solid' })}
                                    size="sm"
                                />
                            }
                        >
                            <FormattedMessage defaultMessage="Contacter l'équipe Commerciale" />
                        </ExternalLink>
                    ) : (
                        <RouterLink
                            variant="button-primary"
                            to="/app/user/subscription/offers"
                            startIcon={
                                <FontAwesomeIcon
                                    icon={icon({ name: 'rocket-launch', style: 'solid' })}
                                    size="sm"
                                />
                            }
                        >
                            <FormattedMessage defaultMessage="Mettre à niveau mon offre" />
                        </RouterLink>
                    )}
                    <Button
                        variant="secondary"
                        onPress={() => {
                            sendAmplitudeLogEvent('Discover PSO');
                            const youtubeVideo = intl.formatMessage({
                                defaultMessage: 'https://www.youtube.com/watch?v=oNfs8uSlzgw',
                            });
                            window.open(youtubeVideo, '_blank');
                        }}
                        endIcon={
                            <FontAwesomeIcon
                                icon={icon({ name: 'arrow-up-right', style: 'solid' })}
                                size="sm"
                            />
                        }
                    >
                        <FormattedMessage defaultMessage="Découvrir PSO" />
                    </Button>
                </ActionWrapper>
            </EmptyState>
        </BlockingScreenWrapper>
    );
};

const BlockingScreenWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    max-width: var(--container-width);
    padding: var(--container-padding);
`;
const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;
const Title = styled.div`
    font-weight: var(--fw-bold);
    font-size: var(--fs-heading-m);
`;
const SubTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: var(--fw-semibold);
    font-size: var(--fs-body-s);
    background-color: var(--primary50);
    color: var(--primary);
    padding-block: 0.5rem;
    padding-inline: 0.75rem;
    border-radius: var(--r-s);
`;
const BetaTag = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    font-weight: var(--fw-semibold);
    font-size: var(--fs-body-s);
    background-color: var(--warning50);
    color: var(--warning);
    padding-block: 0.5rem;
    padding-inline: 0.75rem;
    border-radius: var(--r-s);
`;
const HeadIllustrationWrapper = styled.div`
    width: 200px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    & > * {
        text-align: center;
    }
`;
const ActionWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
`;
const Lock = styled.img`
    width: 2.5rem;
    height: auto;
    max-width: none;
`;

export default PsoBlockingScreen;
