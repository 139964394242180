import { useMutation, useQueryClient } from '@tanstack/react-query';
import uploadUserAvatarMutation from '@queries/user/uploadUserAvatarMutation';
import deleteUserAvatarMutation from '@queries/user/deleteUserAvatarMutation';
import userKeys from '@queries/user/userKeys';
import { ProfileFormSchema } from './ProfileForm/schema';
import updateUserMutation from '@queries/user/updateUserMutation';

const saveProfileMutation = () => {
    const queryClient = useQueryClient();
    const updateUser = updateUserMutation();
    const uploadAvatar = uploadUserAvatarMutation();
    const deleteAvatar = deleteUserAvatarMutation();

    return useMutation({
        mutationFn: (data: ProfileFormSchema) => {
            const { avatar, ...user } = data;
            const promises = [];

            if (avatar && avatar instanceof File) {
                const avatarPromise = uploadAvatar.mutateAsync(avatar);
                promises.push(avatarPromise);
            }

            if (avatar === null) {
                const avatarPromise = deleteAvatar.mutateAsync();
                promises.push(avatarPromise);
            }

            if (Object.keys(user).length > 0) {
                const playerSettingsPromise = updateUser.mutateAsync(user);
                promises.push(playerSettingsPromise);
            }

            // Waiting for all promises to be fulfilled or rejected
            return Promise.all(promises);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: userKeys.detail() });
        },
    });
};

export default saveProfileMutation;
