import styled from 'styled-components';
import ChannelGuestsRoleSelect from '../ChannelGuestsRoleSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { CHANNEL_GUEST_USER_ROLES } from '@/shared/config/constants';
import { ChannelGuestUserPermissionsSummary } from '@/queries/guest/useChannelGuestUserPermissionsSummary.hook';
import Stack from '@/components/ui/layout/Stack';
import Text from '@/components/ui/atoms/Text';
import Cluster from '@/components/ui/layout/Cluster';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';
import { useFormContext } from 'react-hook-form';
import { ChannelGuestsEditPermissionFormSchema } from '../useChannelGuestsEditPermissionFormSchema';
import { TooltipTrigger } from 'react-aria-components';

const messages = defineMessages({
    admin: { defaultMessage: 'Admin' },
    editor: { defaultMessage: 'Éditeur' },
    viewer: { defaultMessage: 'Lecteur' },
    mixed: { defaultMessage: 'Mixte' },
});

interface ChannelGuestsEditPermissionHeaderProps {
    user: ChannelGuestUserPermissionsSummary['user'];
}

const ChannelGuestsEditPermissionHeader = ({ user }: ChannelGuestsEditPermissionHeaderProps) => {
    const intl = useIntl();
    const { control } = useFormContext<ChannelGuestsEditPermissionFormSchema>();

    const mainRoleItems = Object.values(CHANNEL_GUEST_USER_ROLES).map((role) => ({
        id: role,
        name: intl.formatMessage(messages[role]),
    }));

    return (
        <Header>
            <UserWrapper>
                {user.avatar ? (
                    <UserAvatar src={user.avatar} alt={user?.name ?? 'avatar'} />
                ) : (
                    <AvatarPlaceholder>
                        <AvatarPlaceholderIcon
                            icon={icon({ name: 'user-astronaut', style: 'solid' })}
                        />
                    </AvatarPlaceholder>
                )}
                <Stack>
                    <Text fontWeight="--fw-semibold">{user?.name}</Text>
                    <Text color="--neutral500">{user?.email}</Text>
                </Stack>
            </UserWrapper>
            <Stack>
                <Cluster $gap="0.5rem">
                    <Text color="--neutral500">
                        <FormattedMessage defaultMessage="Rôle global" />
                    </Text>
                    <TooltipTrigger delay={0} closeDelay={0}>
                        <TooltipTriggerWrapper>
                            <HelpIcon icon={icon({ name: 'info-circle', style: 'solid' })} />
                        </TooltipTriggerWrapper>
                        <Tooltip placement="top">
                            <FormattedMessage defaultMessage="Rôle par défaut pour toutes les émissions" />
                        </Tooltip>
                    </TooltipTrigger>
                </Cluster>
                <MainRoleSelect
                    name="mainRole"
                    items={mainRoleItems}
                    disabledKeys={[CHANNEL_GUEST_USER_ROLES.MIXED]}
                    control={control}
                />
            </Stack>
        </Header>
    );
};

const UserAvatar = styled.img`
    width: 2.5rem;
    height: 2.5rem;
    border-radius: var(--r-full);
    object-fit: cover;
    flex-shrink: 0;
`;
const UserWrapper = styled.div`
    display: flex;
    column-gap: 0.75rem;
    flex-grow: 1;
`;
const AvatarPlaceholder = styled.div`
    display: none;
    width: 2rem;
    height: 2rem;
    border-radius: var(--r-full);
    background-color: var(--neutral50);

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: flex;
        align-items: end;
        justify-content: center;
        overflow: hidden;
        flex-shrink: 0;
    }
`;
const AvatarPlaceholderIcon = styled(FontAwesomeIcon)`
    width: 1.5rem;
    height: 1.5rem;
    color: var(--neutral500);
`;
const Header = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
        column-gap: 0.75rem;
        padding-right: 2rem;
    }
`;
const MainRoleSelect = styled(ChannelGuestsRoleSelect)`
    font-weight: var(--fw-semibold);
    max-width: fit-content;
`;
const HelpIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
    width: 0.75rem;
    height: 0.75rem;
`;

export default ChannelGuestsEditPermissionHeader;
