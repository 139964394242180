import styled from 'styled-components';

const CalendarSkeleton = () => {
    return (
        <CalendarSkeletonWrapper>
            <Header>
                <HeaderDot />
                <HeaderDot />
                <HeaderDot />
                <HeaderDot />
                <HeaderDot />
                <HeaderDot />
                <HeaderDot />
            </Header>
            <Calendar>
                <tbody>
                    <Row>
                        <Cell>
                            <CellInner>
                                <Dot />
                            </CellInner>
                        </Cell>
                        <Cell>
                            <CellInner>
                                <Dot />
                            </CellInner>
                        </Cell>
                        <Cell>
                            <CellInner>
                                <Dot />
                            </CellInner>
                        </Cell>
                        <Cell>
                            <CellInner>
                                <Dot />
                            </CellInner>
                        </Cell>
                        <Cell>
                            <CellInner>
                                <Dot />
                            </CellInner>
                        </Cell>
                        <Cell>
                            <CellInner>
                                <Dot />
                            </CellInner>
                        </Cell>
                        <Cell>
                            <CellInner>
                                <Dot />
                            </CellInner>
                        </Cell>
                    </Row>
                    <Row>
                        <Cell>
                            <CellInner>
                                <Dot />
                            </CellInner>
                        </Cell>
                        <Cell>
                            <CellInner>
                                <Dot />
                            </CellInner>
                        </Cell>
                        <Cell>
                            <CellInner>
                                <Dot />
                            </CellInner>
                        </Cell>
                        <Cell>
                            <CellInner>
                                <Dot />
                            </CellInner>
                        </Cell>
                        <Cell>
                            <CellInner>
                                <Dot />
                            </CellInner>
                        </Cell>
                        <Cell>
                            <CellInner>
                                <Dot />
                            </CellInner>
                        </Cell>
                        <Cell>
                            <CellInner>
                                <Dot />
                            </CellInner>
                        </Cell>
                    </Row>
                    <Row>
                        <Cell>
                            <CellInner>
                                <Dot />
                            </CellInner>
                        </Cell>
                        <Cell>
                            <CellInner>
                                <Dot />
                            </CellInner>
                        </Cell>
                        <Cell>
                            <CellInner>
                                <Dot />
                            </CellInner>
                        </Cell>
                        <Cell>
                            <CellInner>
                                <Dot />
                            </CellInner>
                        </Cell>
                        <Cell>
                            <CellInner>
                                <Dot />
                            </CellInner>
                        </Cell>
                        <Cell>
                            <CellInner>
                                <Dot />
                            </CellInner>
                        </Cell>
                        <Cell>
                            <CellInner>
                                <Dot />
                            </CellInner>
                        </Cell>
                    </Row>
                    <Row>
                        <Cell>
                            <CellInner>
                                <Dot />
                            </CellInner>
                        </Cell>
                        <Cell>
                            <CellInner>
                                <Dot />
                            </CellInner>
                        </Cell>
                        <Cell>
                            <CellInner>
                                <Dot />
                            </CellInner>
                        </Cell>
                        <Cell>
                            <CellInner>
                                <Dot />
                            </CellInner>
                        </Cell>
                        <Cell>
                            <CellInner>
                                <Dot />
                            </CellInner>
                        </Cell>
                        <Cell>
                            <CellInner>
                                <Dot />
                            </CellInner>
                        </Cell>
                        <Cell>
                            <CellInner>
                                <Dot />
                            </CellInner>
                        </Cell>
                    </Row>
                    <Row>
                        <Cell>
                            <CellInner>
                                <Dot />
                            </CellInner>
                        </Cell>
                        <Cell>
                            <CellInner>
                                <Dot />
                            </CellInner>
                        </Cell>
                        <Cell>
                            <CellInner>
                                <Dot />
                            </CellInner>
                        </Cell>
                        <Cell>
                            <CellInner>
                                <Dot />
                            </CellInner>
                        </Cell>
                        <Cell>
                            <CellInner>
                                <Dot />
                            </CellInner>
                        </Cell>
                        <Cell>
                            <CellInner>
                                <Dot />
                            </CellInner>
                        </Cell>
                        <Cell>
                            <CellInner>
                                <Dot />
                            </CellInner>
                        </Cell>
                    </Row>
                </tbody>
            </Calendar>
        </CalendarSkeletonWrapper>
    );
};

const CalendarSkeletonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    width: 100%;
`;
const Header = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
`;
const HeaderDot = styled.div`
    width: 2.5rem;
    height: 0.5rem;
    background-color: var(--neutral50);
    border-radius: var(--r-full);
`;
const Calendar = styled.table`
    table-layout: fixed;
    width: 100%;
    width: 100%;
    border-collapse: collapse;
    border-radius: var(--r-m);

    & tbody {
        outline: 1px solid var(--neutral100);
        border-radius: var(--r-m);
    }
    & tr:nth-of-type(0n + 1) {
        border-radius: var(--r-m) var(--r-m) 0 0;
    }
    & tr:nth-of-type(0n + 1) > td:first-of-type,
    & tr:nth-of-type(0n + 1) > td:first-of-type > * {
        border-top-left-radius: var(--r-m);
    }
    & tr:nth-of-type(0n + 1) > td:last-of-type,
    & tr:nth-of-type(0n + 1) > td:last-of-type > * {
        border-top-right-radius: var(--r-m);
    }
    & tr:last-of-type > td:first-of-type,
    & tr:last-of-type > td:first-of-type > * {
        border-bottom-left-radius: var(--r-m);
    }
    & tr:last-of-type > td:last-of-type,
    & tr:last-of-type > td:last-of-type > * {
        border-bottom-right-radius: var(--r-m);
    }
`;
const Row = styled.tr`
    border-radius: var(--r-m);
`;
const Cell = styled.td`
    height: 7.5rem;
`;
const CellInner = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem;
    outline: 0.5px solid var(--neutral100);
`;
const Dot = styled.div`
    width: 2.5rem;
    height: 1.5rem;
    background-color: var(--neutral100);
    border-radius: var(--r-full);
`;

export default CalendarSkeleton;
