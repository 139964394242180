import { forwardRef } from 'react';
import TooltipHover from '@ui/smarties/TooltipHover';
import { useParams } from 'react-router';
import useShowQuery from '@queries/show/useShowQuery.hook';
import useSubscriptionQuery from '@queries/subscription/useSubscriptionQuery.hook';
import useUserQuery from '@queries/user/useUserQuery.hook';
import { dateIsInTheFuture } from '@/shared/utils/date';

function makeAccess(Component) {
    const ComponentAccess = forwardRef(
        // reason and props.disabled are already connected to the store. The other props shall be provided by the user
        (
            {
                tooltip,
                tooltipSwitch = {},
                tooltipArrow = 'top',
                roles = [],
                offers = [],
                pricing = [],
                disabled = false,
                userOptions = [],
                showStatus = [],
                authorizedForBlockedSubscription = false,
                reason,
                ...rest
            },
            ref,
        ) => {
            const { showId } = useParams();
            const { data: show } = useShowQuery(showId);
            const { data: subscription } = useSubscriptionQuery();
            const { data: user } = useUserQuery();

            const meetUserOptions = (options) => {
                return options.every((option) => user?.options?.includes(option));
            };

            const meetShowOptions = (options) => {
                return options.every((option) => show?.userOptions?.includes(option));
            };

            const referencePricing = show?.ownerPricing || subscription?.pricing;

            const hasSubscriptionEnded =
                !!subscription?.endsAt && !dateIsInTheFuture(subscription.endsAt);

            let accessProps = { disabled: false };

            // The reason prop is the key used in the tooltipSwitch (the reason why the component is disabled)
            if (show && roles?.includes(show.userRole)) {
                accessProps = { disabled: true, reason: 'role' };
            }
            if (show && showStatus?.includes(show.showStatus)) {
                accessProps = { disabled: true, reason: 'showStatus' };
            }
            if (user && user.pricing === 'enterprise' && user.activated === false) {
                accessProps = { disabled: true, reason: 'subscription' };
            }
            if (!authorizedForBlockedSubscription && subscription && hasSubscriptionEnded) {
                accessProps = { disabled: true, reason: 'subscription' };
            }
            if (
                subscription &&
                (offers?.includes(referencePricing) || pricing?.includes(referencePricing))
            ) {
                accessProps = { disabled: true, reason: 'pricing' };
            }
            if (
                user &&
                userOptions.length > 0 &&
                !(meetUserOptions(userOptions) || meetShowOptions(userOptions))
            ) {
                accessProps = { disabled: true, reason: 'userOptions' };
            }
            if (disabled) {
                accessProps = { disabled: true, reason: 'default' };
            }

            const Tooltip = tooltip || tooltipSwitch[reason];
            if (Tooltip && disabled) {
                return (
                    <TooltipHover
                        arrow={tooltipArrow}
                        target={<Component ref={ref} {...accessProps} {...rest} />}
                    >
                        {Tooltip}
                    </TooltipHover>
                );
            }
            return <Component ref={ref} {...accessProps} {...rest} />;
        },
    );

    ComponentAccess.displayName = `Access(${Component.displayName || Component.name})`;

    return ComponentAccess;
}

export default makeAccess;
