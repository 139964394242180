import styled from 'styled-components';
import Box from './Box';

/**
 * Flex is Box with display: flex.
 * It renders a div element.
 *
 * References:
 * https://chakra-ui.com/docs/layout/flex
 */
const Flex = styled(Box).attrs({
    $display: 'flex',
})``;

Flex.propTypes = Box.propTypes;

export default Flex;
