import styled from 'styled-components';

interface AccordionItemProps {
    title: React.ReactNode;
    description: React.ReactNode;
    children: React.ReactNode;
}

const AccordionItem = ({ title, description, children }: AccordionItemProps) => (
    <AccordionContent>
        <AccordionHeader>
            <Title>{title}</Title>
            <Description>{description}</Description>
        </AccordionHeader>
        {children}
    </AccordionContent>
);

const Accordion = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3rem;
`;
const AccordionContent = styled(Accordion)``;
const AccordionHeader = styled.div`
    display: flex;
    flex-direction: column;
`;
const Title = styled.h2`
    font-weight: var(--fw-bold);
`;
const Description = styled.span`
    color: var(--neutral);
`;

export default AccordionItem;
