import showKeys from '@/queries/show/showKeys';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { fetchShows } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import { Show } from './useShowQuery.hook';

interface Shows {
    shows: Show[];
    pagination: {
        total: number;
        count: number;
        perPage: number;
        currentPage: number;
        totalPages: number;
    };
}

const queryFn = async () => {
    const { data, meta } = await fetchShows();

    const result = {
        shows: data.filter((show: Show) => show.archived === false),
        pagination: meta.pagination,
    };

    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(result, { deep: true });
};

const useActiveShowsQuery = (): UseQueryResult<Shows> => {
    return useQuery({
        queryKey: showKeys.list(),
        queryFn,
    });
};

export default useActiveShowsQuery;
