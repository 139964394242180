import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Currency from './Currency';
import Hosting from './Hosting';
import Pso from './Pso';
import AI from './AI';

const Offers = () => {
    return (
        <Section>
            <SectionHeader>
                <SectionTitle>
                    <FormattedMessage defaultMessage="Offres" />
                </SectionTitle>
                <Currency />
            </SectionHeader>
            <Offer>
                <Hosting />
            </Offer>
            <Offer>
                <Pso />
            </Offer>
            <Offer>
                <AI />
            </Offer>
        </Section>
    );
};

const Section = styled.section`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
`;
const SectionTitle = styled.div`
    color: var(--neutral500);
    font-weight: var(--fw-semibold);
    font-size: var(--fs-body-m);
`;
const SectionHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const Offer = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding-mobile);

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        padding: var(--container-padding);
    }
`;

export default Offers;
