import { createFetcher } from '../Fetcher';

const FetcherSourcePlatforms = createFetcher(
    (
        { sourcesStatsStore },
        { showId, itemType, itemId, start, end, order, page, perPage, episodes },
    ) => ({
        fetch: () =>
            sourcesStatsStore.fetchSourcePlatforms(showId, itemType, itemId, {
                start,
                end,
                order,
                page,
                perPage,
                podcasts: episodes,
            }),
        isLoading: sourcesStatsStore.fetchSourcePlatforms.pending,
        input: [showId, itemType, itemId, start, end, order, page, perPage, episodes],
    }),
);

FetcherSourcePlatforms.defaultProps = {
    page: 1,
};

export default FetcherSourcePlatforms;
