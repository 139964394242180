import { FormattedMessage } from 'react-intl';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import { connect } from '@/components/legacy/connect';
import Link from '@ui/atoms/Link';
import styled from 'styled-components';

const enhance = connect(({ routerStore }) => ({
    link: routerStore.link('user.edit.tab', { tab: 'subscription' }),
}));

function TopbarPastDueWarningBand({ link }) {
    return (
        <TopbarPastDueWarningBandWrapper>
            <DeprecatedText color="white">
                <FormattedMessage defaultMessage="Vous avez actuellement une facture impayée." />
            </DeprecatedText>
            <DeprecatedText ml={20} weight="semibold" as={Link} to={link} color="white">
                <FormattedMessage defaultMessage="Consulter mes factures" />
            </DeprecatedText>
        </TopbarPastDueWarningBandWrapper>
    );
}

const TopbarPastDueWarningBandWrapper = styled.div`
    height: 2rem;
    background: var(--info);
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default enhance(TopbarPastDueWarningBand);
