import { useState } from 'react';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import DateRangePicker from '@ui/atoms/DateRangePicker';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import { connect } from '@/components/legacy/connect';
import { useSwitch } from '@/shared/hooks/useSwitch';
import { useStatsDateSelectorContext } from '@/context/StatsDateSelectorContext';
import { parseDate } from '@internationalized/date';
import { getLocalTimeZone, today } from '@internationalized/date';
import useUserQuery from '@queries/user/useUserQuery.hook';
import { useParams } from 'react-router';
import useShowQuery from '@queries/show/useShowQuery.hook';
import styled from 'styled-components';

const enhance = connect(({ applePodcastsStatsStore }) => {
    return {
        oldestRankingDate:
            applePodcastsStatsStore.applePodcastsSummary &&
            applePodcastsStatsStore.applePodcastsSummary.oldestRankingDate,
    };
});

const ApplePodcastsDateSelector = ({ oldestRankingDate }) => {
    const { showId } = useParams();
    const { data: show } = useShowQuery(showId);

    const showStartDate = show && new Date(show.createdAt);

    const { startDate, setStartDate, endDate, setEndDate } = useStatsDateSelectorContext();
    const [opened] = useSwitch();
    const { data: user } = useUserQuery();
    const timezone = user?.timezone || getLocalTimeZone();
    const now = today(timezone);
    const [hasError, setHasError] = useState(false);

    const onDatesChange = (startDate, endDate) => {
        const isLaterThanToday = parseDate(endDate).compare(now) > 0;

        if (isLaterThanToday) {
            setHasError(true);
        } else setHasError(false);
        setStartDate(startDate);
        setEndDate(endDate);
    };

    const oldestStartDate = oldestRankingDate
        ? dayjs(showStartDate).isBefore(dayjs(oldestRankingDate))
            ? showStartDate
            : oldestRankingDate
        : showStartDate;

    return (
        <ApplePodcastsDateSelectorWrapper opened={opened}>
            <DeprecatedText auto mt={10} size="veryBig" weight="bold">
                <FormattedMessage defaultMessage="Votre classement en détails" />
            </DeprecatedText>
            <DateRangePickerWrapper>
                <DateRangePicker
                    value={{
                        start: parseDate(startDate),
                        end: parseDate(endDate),
                    }}
                    onChange={(dates) => onDatesChange(dates.start, dates.end)}
                    startDate={parseDate(dayjs(oldestStartDate).format('YYYY-MM-DD'))}
                    hideCustomTimezone
                    maxValue={now}
                    isInvalid={hasError}
                />
            </DateRangePickerWrapper>
        </ApplePodcastsDateSelectorWrapper>
    );
};

const ApplePodcastsDateSelectorWrapper = styled.div`
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    z-index: ${({ opened }) => (opened ? 2 : 0)};

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;
const DateRangePickerWrapper = styled.div`
    flex-basis: 8.25rem;

    @media (max-width: 768px) {
        flex-basis: 0;
    }
`;

export default enhance(ApplePodcastsDateSelector);
