import { useRef } from 'react';
import PropTypes from 'prop-types';
import { useCalendarCell } from 'react-aria';
import Posts from './Posts';
import dayjs from 'dayjs';
import styled, { css } from 'styled-components';

const CalendarCell = ({
    state,
    date,
    posts,
    showSeeMoreAt,
    setShowSeeMoreAt,
    postDetailsShown,
    setPostDetailsShown,
    isLastRow,
    startDate,
    endDate,
}) => {
    let ref = useRef(null);
    let { cellProps, isOutsideVisibleRange, formattedDate } = useCalendarCell({ date }, state, ref);

    const isToday = dayjs(date).isSame(dayjs(), 'day');

    return (
        <CalendarCellWrapper {...cellProps}>
            <CellInner ref={ref} hidden={isOutsideVisibleRange}>
                <CellHeader isOutsideMonth={isOutsideVisibleRange} isToday={isToday}>
                    {isToday && <TodayMarker />}
                    {formattedDate}
                </CellHeader>
                <Posts
                    date={date}
                    posts={posts}
                    showSeeMoreAt={showSeeMoreAt}
                    setShowSeeMoreAt={(date) => setShowSeeMoreAt(date)}
                    postDetailsShown={postDetailsShown}
                    setPostDetailsShown={(postId) => {
                        setPostDetailsShown(postId);
                    }}
                    isLastRow={isLastRow}
                    startDate={startDate}
                    endDate={endDate}
                />
            </CellInner>
        </CalendarCellWrapper>
    );
};

const CalendarCellWrapper = styled.td`
    outline: 0.5px solid var(--neutral100);
`;
const CellInner = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    height: 7.5rem;
`;
const CellHeader = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
    padding: 0.5rem;
    font-weight: var(--fw-semibold);
    color: ${({ isOutsideMonth }) => (isOutsideMonth ? 'var(--neutral500)' : 'var(--black)')};
    font-size: var(--fs-body-l);

    ${({ isToday }) =>
        isToday &&
        css`
            color: var(--primary);
        `};
`;
const TodayMarker = styled.div`
    height: 0.25rem;
    width: 100%;
    border-radius: var(--r-full);
    background-color: var(--primary50);
`;

CalendarCell.propTypes = {
    state: PropTypes.object.isRequired,
    date: PropTypes.instanceOf(Date).isRequired,
    posts: PropTypes.arrayOf(PropTypes.object),
    showSeeMoreAt: PropTypes.string,
    setShowSeeMoreAt: PropTypes.func,
    postDetailsShown: PropTypes.string,
    setPostDetailsShown: PropTypes.func,
    isLastRow: PropTypes.bool,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
};

export default CalendarCell;
