import { useState } from 'react';
import { useIntl, FormattedMessage, FormattedNumber } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import { useHistory, useParams } from 'react-router';
import { Button as AriaButton, SearchField, Input } from 'react-aria-components';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';
import { TooltipTrigger } from 'react-aria-components';
import { USER_ROLES } from '@/shared/config/constants';
import { useDebounce } from '@/shared/hooks/useDebounceNew';
import styled from 'styled-components';
import addCampaignMutation from '@/queries/campaign/addCampaignMutation';
import dayjs from 'dayjs';
import Button from '@/components/Button';
import { useQueryClient } from '@tanstack/react-query';
import campaignKeys from '@/queries/campaign/campaignKeys';

interface ManualCampaignsPageHeaderProps {
    search: string;
    campaignsCount: number;
    onSearchChange: (value: string) => void;
}

const ManualCampaignsPageHeader = ({
    search,
    onSearchChange,
    campaignsCount,
}: ManualCampaignsPageHeaderProps) => {
    const history = useHistory();
    const intl = useIntl();
    const { showId } = useParams<{ showId: string }>();
    const { data: show } = useShowQuery(showId);
    const [searchValue, setSearchValue] = useState(search);
    const addCampaign = addCampaignMutation();
    const userHasWriteAccess = show?.userRole !== USER_ROLES.VIEWER;
    const queryClient = useQueryClient();

    const onChange = () => {
        onSearchChange(searchValue);
    };

    const onAddCampaign = () => {
        addCampaign.mutate(
            {
                showId,
                campaign: {
                    showId,
                    type: 'preroll',
                    name: intl.formatMessage({ defaultMessage: 'Nouvelle campagne' }),
                    startsAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
                    endsAt: dayjs().add(1, 'd').format('YYYY-MM-DD HH:mm:ss'),
                },
            },
            {
                onSuccess: (campaign) => {
                    history.push(`/app/show/${showId}/monetize/manual/${campaign.id}`);
                    queryClient.invalidateQueries({ queryKey: campaignKeys.allLists() });
                },
            },
        );
    };

    const debouncedOnChange = useDebounce(onChange);

    return (
        <EpisodesHeader>
            <Left>
                <StyledSearchField
                    value={searchValue}
                    onChange={(value: string) => {
                        debouncedOnChange();
                        setSearchValue(value);
                    }}
                    aria-label="Rechercher une campagne"
                >
                    <SearchIconWrapper>
                        <FontAwesomeIcon
                            icon={icon({ name: 'magnifying-glass', style: 'solid' })}
                        />
                    </SearchIconWrapper>
                    <StyledInput
                        placeholder={intl.formatMessage({
                            defaultMessage: 'Rechercher une campagne...',
                        })}
                        autoComplete="off"
                    />
                    {searchValue.length > 0 && (
                        <ResetSearchButton>
                            <FontAwesomeIcon icon={icon({ name: 'xmark', style: 'solid' })} />
                        </ResetSearchButton>
                    )}
                </StyledSearchField>
                <Stats>
                    {campaignsCount && (
                        <Stat>
                            <FontAwesomeIcon
                                icon={icon({ name: 'rectangle-history', style: 'solid' })}
                            />
                            <StatValue>
                                <FormattedMessage
                                    defaultMessage="{count, plural, one {# campagne} other {# campagnes}}"
                                    values={{
                                        count: campaignsCount,
                                    }}
                                />
                            </StatValue>
                        </Stat>
                    )}
                </Stats>
            </Left>
            {!show?.archived && (
                <NewEpisodeWrapper>
                    <TooltipTrigger delay={0} closeDelay={0} isDisabled={userHasWriteAccess}>
                        <TooltipTriggerWrapper>
                            <Button
                                startIcon={
                                    <FontAwesomeIcon
                                        icon={icon({ name: 'plus', style: 'solid' })}
                                    />
                                }
                                isDisabled={!userHasWriteAccess}
                                isLoading={addCampaign.isLoading}
                                onPress={onAddCampaign}
                            >
                                <FormattedMessage defaultMessage="Ajouter une campagne" />
                            </Button>
                        </TooltipTriggerWrapper>
                        <Tooltip placement="bottom">
                            <FormattedMessage defaultMessage="Vous devez être éditeur ou administrateur de l'émission pour ajouter un nouvel épisode." />
                        </Tooltip>
                    </TooltipTrigger>
                </NewEpisodeWrapper>
            )}
        </EpisodesHeader>
    );
};

const EpisodesHeader = styled.header`
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @media (min-width: 1215px) {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
`;
const Left = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex: 1;

    @media (min-width: 1215px) {
        flex-direction: row;
        align-items: center;
    }
`;
const StyledInput = styled(Input)`
    border: 0;
    height: 100%;
    padding-block: 0.75rem;
    background-color: transparent;
    min-width: 12rem;
    width: 100%;
`;
const StyledSearchField = styled(SearchField)`
    transition-duration: 0.2s;
    flex-grow: 1;
    display: flex;
    align-items: center;
    background-color: var(--neutral100);
    border-radius: var(--r-s);
    padding-inline: 0.5rem;
    border: 1px solid transparent;

    &:focus-within {
        border: 1px solid var(--primary);
        outline: 2px solid var(--primary50);
        background-color: var(--white);
    }

    @media (min-width: 1215px) {
        flex-grow: 0;
    }
`;

const SearchIconWrapper = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    padding-inline: 0.75rem;
    color: var(--neutral500);
`;
const Stats = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
`;
const Stat = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--neutral500);
    font-weight: var(--fw-semibold);
`;
const StatValue = styled.div`
    display: inline-flex;
    align-items: center;
    gap: 0.25rem;
`;
const NewEpisodeWrapper = styled.div`
    width: 100%;

    & button {
        width: 100%;
    }

    @media (min-width: 1215px) {
        width: auto;
    }
`;
const ResetSearchButton = styled(AriaButton)`
    transition-duration: 0.2s;
    color: var(--neutral500);
    border: 0;
    border-radius: var(--r-s);
    height: 1.75rem;
    width: 1.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem;
    cursor: pointer;

    &:hover {
        background-color: var(--neutral200);
        color: var(--black);
    }
`;

export default ManualCampaignsPageHeader;
