import Modal from '@/components/ui/atoms/Modal';
import Text from '@/components/ui/atoms/Text';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import IconButton from '@/components/ui/atoms/IconButton';
import Cluster from '@/components/ui/layout/Cluster';
import Stack from '@/components/ui/layout/Stack';
import Alert from '@/components/ui/atoms/Alert';
import ActiveShowsQuotaForm from './ActiveShowsQuotaForm';

interface ActiveShowsQuotaModalProps {
    isOpen: boolean;
    onOpenChange: (isOpen: boolean) => void;
    activeShowAllowance?: number;
    currentActiveShows?: number;
    onSubmit: ({ quota }: { quota: number }) => void;
}

const ActiveShowsQuotaModal = ({
    isOpen,
    onOpenChange,
    activeShowAllowance,
    currentActiveShows,
    onSubmit,
}: ActiveShowsQuotaModalProps) => {
    const handleClose = () => onOpenChange(false);
    return (
        <ModalStyled size="medium" isOpen={isOpen} onOpenChange={onOpenChange}>
            <Stack $gap="2rem">
                <Stack $gap="0.25rem">
                    <Cluster $gap="2rem" $justify="space-between" $wrap="nowrap">
                        <Text variant="headingM" fontWeight="--fw-bold">
                            <FormattedMessage defaultMessage="Gérer mon nombre d'émissions actives" />
                        </Text>
                        <CloseButton
                            icon={<CloseIcon icon={icon({ name: 'xmark-large' })} />}
                            onPress={handleClose}
                            variant="ghost"
                            size="small"
                        />
                    </Cluster>
                    <Text color="--neutral500">
                        <FormattedMessage defaultMessage="En plus de l'émission active incluse dans votre abonnement, créez de nouveaux podcasts en fonction de vos besoins 🚀" />
                    </Text>
                </Stack>
                <Alert variant="neutral" icon={<InfoIcon icon={icon({ name: 'info-circle' })} />}>
                    <Text fontWeight="--fw-semibold">
                        <FormattedMessage
                            defaultMessage="{quota, plural, one {Vous disposez actuellement de # émission active.} other {Vous disposez actuellement de # émissions actives.}}"
                            values={{
                                quota: activeShowAllowance,
                            }}
                        />
                    </Text>
                </Alert>
                <ActiveShowsQuotaForm
                    activeShowAllowance={activeShowAllowance}
                    currentActiveShows={currentActiveShows}
                    onClose={handleClose}
                    onSubmit={onSubmit}
                />
            </Stack>
        </ModalStyled>
    );
};

const CloseIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
    width: 1rem;
    height: 1rem;
`;
const CloseButton = styled(IconButton)`
    padding: 0;
`;
const ModalStyled = styled(Modal)`
    padding: 1.5rem;
`;
const InfoIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
    width: 1rem;
    height: 1rem;
`;

export default ActiveShowsQuotaModal;
