import { useParams } from 'react-router';
import { useAuthContext } from '@/context/AuthContext';
import { createAuthorizer } from './createAuthorizer';
import useQuery from '@/shared/hooks/useQuery';
import useSubscriptionQuery from '@/queries/subscription/useSubscriptionQuery.hook';
import dayjs from 'dayjs';

export const GuestRoute = createAuthorizer(() => {
    const { isAuthenticated } = useAuthContext();
    const params = useQuery();
    const token = params.get('token');
    return {
        isBlocked: isAuthenticated && !token,
        defaultRedirect: '/app',
    };
});

export const AuthRoute = createAuthorizer(() => {
    const { isAuthenticated } = useAuthContext();
    return {
        isBlocked: !isAuthenticated,
        defaultRedirect: '/login',
    };
});

export const ValidSubscriptionRoute = createAuthorizer(() => {
    const { showId } = useParams();
    const { isAuthenticated } = useAuthContext();
    const { data: subscription } = useSubscriptionQuery();
    const subscriptionIsBlockedForShow =
        !!subscription?.endsAt && dayjs(subscription?.endsAt).isBefore(dayjs(), 'day');

    return {
        isBlocked: isAuthenticated && subscriptionIsBlockedForShow,
        defaultRedirect: showId ? `/app/show/${showId}/episodes` : '/app',
    };
});
