import { connect } from '@app/decorators/connect';
import { FormattedMessage } from 'react-intl';
import DeprecatedModal from '../../ui/molecules/DeprecatedModal';
import DeprecatedText from '../../ui/atoms/DeprecatedText';
import DeprecatedButton from '../../ui/atoms/DeprecatedButton';
import Spinner from '../../ui/atoms/Spinner';
import styled from 'styled-components';

const enhance = connect(({ routerStore }) => ({
    redirect: () => routerStore.push('playlists'),
}));

function PlaylistDeleteModal({ playlist, isOpened, onClose, redirect }) {
    return (
        <DeprecatedModal
            isOpened={isOpened}
            onClose={onClose}
            title={
                <FormattedMessage defaultMessage="Voulez-vous réellement supprimer la playlist ?" />
            }
        >
            <ModalContentWrapper>
                <DeprecatedText>
                    <FormattedMessage
                        defaultMessage="La playlist {name} va être définitivement supprimée de la plateforme."
                        values={{
                            name: (
                                <DeprecatedText as="strong" weight="semibold">
                                    {playlist.name}
                                </DeprecatedText>
                            ),
                        }}
                    />
                </DeprecatedText>
                <ActionWrapper>
                    <DeprecatedButton accent="secondary" mr={10} onClick={onClose}>
                        <FormattedMessage defaultMessage="Annuler" />
                    </DeprecatedButton>
                    <DeprecatedButton
                        onClick={() => playlist.delete().then(redirect)}
                        disabled={playlist.delete.pending}
                    >
                        {playlist.delete.pending && <Spinner />}
                        <FormattedMessage defaultMessage="Supprimer la playlist" />
                    </DeprecatedButton>
                </ActionWrapper>
            </ModalContentWrapper>
        </DeprecatedModal>
    );
}

const ModalContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
`;
const ActionWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export default enhance(PlaylistDeleteModal);
