import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams, Redirect } from 'react-router-dom';
import AppleBroadcast from '../Platform/AppleBroadcast';
import YoutubeBroadcast from '../Platform/YoutubeBroadcast';
import DeezerBroadcast from '../Platform/DeezerBroadcast';
import TuneinBroadcast from '../Platform/TuneinBroadcast';
import PocketcastBroadcast from '../Platform/PocketcastBroadcast';
import CastboxBroadcast from '../Platform/CastboxBroadcast';
import OvercastBroadcast from '../Platform/OvercastBroadcast';
import CastroBroadcast from '../Platform/CastroBroadcast';
import SpotifyBroadcast from '../Platform/SpotifyBroadcast';
import PodcastAddictBroadcast from '../Platform/PodcastAddictBroadcast';
import SoundcloudBroadcast from '../Platform/SoundcloudBroadcast';
import AmazonBroadcast from '../Platform/AmazonBroadcast';
import PodchaserBroadcast from '../Platform/PodchaserBroadcast';
import ListenNotesBroadcast from '../Platform/ListenNotesBroadcast';
import PodcastIndexBroadcast from '../Platform/PodcastIndexBroadcast';
import IHeartradioBroadcast from '../Platform/IHeartradioBroadcast';
import PandoraBroadcast from '../Platform/PandoraBroadcast';
import PlayerFmBroadcast from '../Platform/PlayerFmBroadcast';
import JioSaavnBroadcast from '../Platform/JioSaavnBroadcast';
import GaanaBroadcast from '../Platform/GaanaBroadcast';
import BroadcastRssImport from './BroadcastRssImport';
import { TUNEIN } from '@/shared/config/constants';
import { Language } from '@queries/language/useLanguagesQuery.hook';
import AccordionItem from './AccordionItem';
import useShowQuery from '@queries/show/useShowQuery.hook';
import useUserQuery from '@queries/user/useUserQuery.hook';
import useRssFeedQuery from '@queries/rss/useRssFeedQuery.hook';
import BroadcastMultiplePlatformsModal from '../BroadcastMultiplePlatformsModal';
import styled from 'styled-components';

interface USABroadcastCardsProps {
    platforms?: string[];
    showLanguageCode?: Language['code'];
}

const EssentialBroadcastCards = () => (
    <CardGrid>
        <AppleBroadcast />
        <SpotifyBroadcast />
        <DeezerBroadcast />
        <PodcastAddictBroadcast />
        <YoutubeBroadcast />
        <SoundcloudBroadcast />
        <AmazonBroadcast />
    </CardGrid>
);

const USABroadcastCards = ({ showLanguageCode, platforms }: USABroadcastCardsProps) => (
    <CardGrid>
        {platforms?.includes(TUNEIN) && <TuneinBroadcast />}
        {showLanguageCode === 'en' && (
            <>
                <IHeartradioBroadcast />
                <PlayerFmBroadcast />
                <JioSaavnBroadcast />
                <GaanaBroadcast />
                <PandoraBroadcast />
                <PodchaserBroadcast />
                <ListenNotesBroadcast />
                <PodcastIndexBroadcast />
            </>
        )}
    </CardGrid>
);

const AutomaticBroadcastCards = () => (
    <CardGrid>
        <PocketcastBroadcast />
        <CastboxBroadcast />
        <OvercastBroadcast />
        <CastroBroadcast />
    </CardGrid>
);

const HostingBroadcastCards = () => {
    const { showId } = useParams<{ showId: string }>();
    const { data: show, isLoading: showIsLoading } = useShowQuery(showId);
    const { data: rssFeed } = useRssFeedQuery({ showId });
    const { data: user } = useUserQuery();

    const hasDescription = !!show?.description && show.description !== '\n\n';
    const hasCover = !!show?.imageUrl;
    const hasPublishedEpisode = show?.hasPublicActivePodcasts;
    const canBroadcast = show && hasDescription && hasCover && hasPublishedEpisode;

    const currentShowIsImported = !!rssFeed;
    const hasListeningLinks = (show?.listeningLinks?.data?.length || 0) > 0;

    const hasDisplayedMultiplePlatformsModalBefore = !!sessionStorage.getItem(
        `hasDisplayedBroadcastMultiplePlatformsModal-${showId}`,
    );

    const [isBroadcastMultiplePlatformsModalOpen, setBroadcastMultiplePlatformsModalIsOpen] =
        useState(
            !hasDisplayedMultiplePlatformsModalBefore &&
                !hasListeningLinks &&
                currentShowIsImported,
        );

    useEffect(() => {
        setBroadcastMultiplePlatformsModalIsOpen(
            !hasDisplayedMultiplePlatformsModalBefore &&
                !hasListeningLinks &&
                currentShowIsImported,
        );
    }, [hasListeningLinks, currentShowIsImported]);

    const platforms = show?.listeningLinks.data.map((link) => link.key);
    const showLanguageCode = show?.language?.data?.code;

    if (showIsLoading) {
        return null;
    }

    if (!user?.emailVerifiedAt) {
        return <Redirect to={`/app/show/${showId}/settings/broadcast/email-verification`} />;
    }

    if (!canBroadcast) {
        return <Redirect to={`/app/show/${showId}/settings/broadcast/setup`} />;
    }

    return (
        <>
            <BroadcastCardsWrapper>
                <BroadcastRssImport />
                <Accordion>
                    <AccordionItem
                        title={<FormattedMessage defaultMessage="Incontournables" />}
                        description={
                            <FormattedMessage defaultMessage="Ces plateformes sont essentielles pour développer l’audience de votre podcast." />
                        }
                    >
                        <EssentialBroadcastCards />
                    </AccordionItem>

                    {(showLanguageCode === 'en' || platforms?.includes(TUNEIN)) && (
                        <AccordionItem
                            title={<FormattedMessage defaultMessage="Populaires aux USA" />}
                            description={
                                <FormattedMessage defaultMessage="Ces plateformes sont également très utilisées aux Etats-Unis." />
                            }
                        >
                            <USABroadcastCards
                                showLanguageCode={showLanguageCode}
                                platforms={platforms}
                            />
                        </AccordionItem>
                    )}

                    <AccordionItem
                        title={<FormattedMessage defaultMessage="Diffusion automatique" />}
                        description={
                            <FormattedMessage defaultMessage="Votre émission sera automatiquement visible sur ces plateformes suite à la diffusion sur Apple Podcasts." />
                        }
                    >
                        <AutomaticBroadcastCards />
                    </AccordionItem>
                </Accordion>
            </BroadcastCardsWrapper>
            <BroadcastMultiplePlatformsModal
                isOpen={isBroadcastMultiplePlatformsModalOpen}
                onClose={() => setBroadcastMultiplePlatformsModalIsOpen(false)}
            />
        </>
    );
};

const BroadcastCardsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3rem;
`;
// In preparation for a future refactoring, the next section is named Accordion, even though it isn't yet an Accordion.
const Accordion = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3rem;
`;
const CardGrid = styled.div`
    display: grid;
    grid-gap: 1.5rem;
    row-gap: 3rem;
    max-width: 49rem;

    ${({ theme }) => theme.mediaQueries.tabletAndUp} {
        grid-template-columns: repeat(2, 1fr);
    }

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        grid-template-columns: repeat(3, 1fr);
    }
`;

export default HostingBroadcastCards;
