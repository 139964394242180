const EUR_COUNTRIES = [
    'AL',
    'AD',
    'AM',
    'AT',
    'AZ',
    'BY',
    'BE',
    'BA',
    'BG',
    'HR',
    'CY',
    'CZ',
    'DK',
    'EE',
    'FI',
    'FR',
    'GE',
    'DE',
    'GR',
    'HU',
    'IS',
    'IE',
    'IT',
    'KZ',
    'XK',
    'LV',
    'LI',
    'LT',
    'LU',
    'MT',
    'MD',
    'MC',
    'ME',
    'NL',
    'MK',
    'NO',
    'PL',
    'PT',
    'RO',
    'RU',
    'SM',
    'RS',
    'SK',
    'SI',
    'ES',
    'SE',
    'CH',
    'TR',
    'UA',
    'VA',
];

const useCurrencyByCountryCode = (countryCode?: string) => {
    const EUR_CURRENCY = 'eur';
    const USD_CURRENCY = 'usd';
    const GBP_CURRENCY = 'gbp';

    if (!countryCode) {
        return null;
    }

    if (EUR_COUNTRIES.includes(countryCode)) {
        return EUR_CURRENCY;
    }
    if (countryCode === 'GB') {
        return GBP_CURRENCY;
    }

    return USD_CURRENCY;
};

export default useCurrencyByCountryCode;
