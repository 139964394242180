import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Button from '@ui/atoms/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DeleteChapterButton = styled(Button)`
    color: var(--alert500);
    padding: 0;
    width: fit-content;
`;

const DeleteChapterButtonIcon = styled(FontAwesomeIcon)`
    color: inherit;
    font-size: 0.75rem;
`;

const SelectedChapterDeleteButton = ({ onPress }) => {
    return (
        <DeleteChapterButton
            startIcon={
                <DeleteChapterButtonIcon
                    icon={icon({
                        name: 'trash',
                        style: 'solid',
                    })}
                />
            }
            variant="ghost"
            onPress={onPress}
        >
            <FormattedMessage defaultMessage="Supprimer le chapitre" />
        </DeleteChapterButton>
    );
};

export default SelectedChapterDeleteButton;
