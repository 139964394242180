import { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { ShowGuestPermission } from '@/queries/guest/useShowGuestPermissionsQuery.hook';
import { GUEST_USER_ROLES, GuestUserRole, IMG_PLACEHOLDER } from '@/shared/config/constants';
import styled from 'styled-components';
import ShowGuestsPermissionDeleteModal from './ShowGuestsPermissionDeleteModal';
import Select from '@/components/ui/atoms/Select';
import ListBoxItem from '@/components/ui/atoms/ListBoxItem';
import updateShowGuestPermissionMutation from '@/queries/guest/updateShowGuestPermissionMutation';
import { Key } from 'react-aria';
import IconButton from '@/components/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Text from '@/components/ui/atoms/Text';

const messages = defineMessages({
    viewer: { defaultMessage: 'Lecteur' },
    editor: { defaultMessage: 'Éditeur' },
    admin: { defaultMessage: 'Admin' },
});

interface ShowGuestsPermissionProps {
    permission: ShowGuestPermission;
}

const ShowGuestsPermission = ({ permission }: ShowGuestsPermissionProps) => {
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const intl = useIntl();
    const roleItems = Object.values(GUEST_USER_ROLES).map((role) => ({
        id: role,
        name: intl.formatMessage(messages[role]),
    }));
    const updatePermission = updateShowGuestPermissionMutation();

    const handleUpdate = (key: Key) => {
        const role = key as GuestUserRole;
        updatePermission.mutate({ permissionId: permission.id, role });
    };

    return (
        <>
            <Wrapper>
                <Img src={permission.userAvatar ?? IMG_PLACEHOLDER} />
                <UserInfo>
                    <UserName>{permission.userName}</UserName>
                    <UserEmail>{permission.userEmail}</UserEmail>
                </UserInfo>
                <RoleSelect
                    defaultSelectedKey={permission.role}
                    onSelectionChange={handleUpdate}
                    items={roleItems}
                >
                    {(item) => <ListBoxItem {...item} />}
                </RoleSelect>
                <DeleteButton
                    variant="danger"
                    icon={<ButtonIcon icon={icon({ name: 'trash', style: 'solid' })} />}
                    onPress={() => setIsDeleteModalOpen(true)}
                    aria-label={intl.formatMessage({ defaultMessage: 'Supprimer' })}
                />
            </Wrapper>
            <ShowGuestsPermissionDeleteModal
                isOpen={isDeleteModalOpen}
                onOpenChange={setIsDeleteModalOpen}
                permission={permission}
            />
        </>
    );
};

const Wrapper = styled.div`
    height: 4.75rem;
    border-bottom: 1px solid var(--neutral100);
    display: flex;
    align-items: center;
    column-gap: 1rem;
`;

const Img = styled.img`
    width: 2.5rem;
    height: 2.5rem;
    border-radius: var(--r-xs);
    flex-shrink: 0;
`;

const UserInfo = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 0;
`;

const UserName = styled(Text)`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: var(--fs-body-l);
    font-weight: var(--fw-semibold);
`;

const UserEmail = styled(Text)`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: var(--neutral500);
`;

const RoleSelect = styled(Select)`
    max-width: 105px;
`;

const DeleteButton = styled(IconButton)`
    padding: 1rem;
    flex-shrink: 0;
`;

const ButtonIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;

export default ShowGuestsPermission;
