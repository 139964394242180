import { FormattedMessage } from 'react-intl';
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
} from '@tanstack/react-table';
import type { ImportedEpisode } from '@/queries/rss/useImportedEpisodesQuery.hook';
import EpisodeCell from './EpisodeCell';
import StatusCell from './StatusCell';
import styled, { css } from 'styled-components';

interface ImportedEpisodeRow {
    episode: {
        id: number;
        title: string;
        publishedAt: string;
    };
    status: Pick<ImportedEpisode, 'state' | 'id'>;
}

interface DatatableProps {
    rows: ImportedEpisode[];
    triggerRefetch: () => void;
}

const Datatable = ({ rows, triggerRefetch }: DatatableProps) => {
    const columnHelper = createColumnHelper<ImportedEpisodeRow>();
    const columns = [
        columnHelper.accessor('episode', {
            cell: (info) => <EpisodeCell {...info.getValue()} />,
            header: () => <FormattedMessage defaultMessage="Titre / Date de publication" />,
        }),
        columnHelper.accessor('status', {
            cell: (info) => <StatusCell {...info.getValue()} triggerRefetch={triggerRefetch} />,
            header: () => <FormattedMessage defaultMessage="Statut" />,
            size: 80,
        }),
    ];

    const table = useReactTable({
        data: rows.map((row) => ({
            episode: { id: row.id, title: row.title, publishedAt: row.publishedAt },
            status: { id: row.id, state: row.state },
        })),
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    return (
        <Table>
            <Thead>
                {table.getHeaderGroups().map((headerGroup) => (
                    <Tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                            <Th key={header.id} style={{ width: `${header.getSize()}px` }}>
                                {header.isPlaceholder ? null : (
                                    <ThContent
                                        onClick={header.column.getToggleSortingHandler()}
                                        sortable={header.column.getCanSort()}
                                    >
                                        {flexRender(
                                            header.column.columnDef.header,
                                            header.getContext(),
                                        )}
                                    </ThContent>
                                )}
                            </Th>
                        ))}
                    </Tr>
                ))}
            </Thead>
            <Tbody>
                {table.getRowModel().rows.map((row) => (
                    <Tr key={row.id}>
                        {row.getVisibleCells().map((cell) => (
                            <Td key={cell.id}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </Td>
                        ))}
                    </Tr>
                ))}
            </Tbody>
        </Table>
    );
};

const Table = styled.table`
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
`;
const Thead = styled.thead``;
const Td = styled.td`
    padding: 1rem;
    &:not(:last-of-type) {
        padding: 1rem;
    }
`;
const Tr = styled.tr`
    & > ${Td}:not(:last-of-type) {
        border-right: 1px solid var(--neutral100);
    }
`;
const Th = styled.th`
    color: var(--neutral);
    text-align: left;
    padding: 1rem;

    @media (min-width: 768px) {
        white-space: nowrap;
    }
`;
const ThContent = styled.div<{ sortable: boolean }>`
    display: inline-flex;
    align-items: center;
    column-gap: 0.5rem;
    transition-duration: 0.2s;
    width: 100%;
    align-self: stretch;
    font-weight: var(--fw-semibold);

    ${(props) =>
        props.sortable &&
        css`
            cursor: pointer;
        `}
`;
const Tbody = styled.tbody`
    & > ${Tr}:not(:last-of-type) {
        border-bottom: 1px solid var(--neutral100);
    }
`;

export default Datatable;
