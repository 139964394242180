import { FORM_VALIDATION_MESSAGE } from '@/shared/services/validationMessageService';
import { useIntl } from 'react-intl';
import { z } from 'zod';

const useAIPresetsFormSchema = () => {
    const intl = useIntl();

    return z.object({
        toneOfVoice: z.string().max(
            200,
            intl.formatMessage(FORM_VALIDATION_MESSAGE.maxLength, {
                max: 200,
            }),
        ),
    });
};

export type AIPresetsSchema = z.infer<ReturnType<typeof useAIPresetsFormSchema>>;

export default useAIPresetsFormSchema;
