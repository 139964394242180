import MessageSummary from './MessageSummary';
import TitleSummary from './TitleSummary';

const NewsletterSummary = () => {
    return (
        <>
            <TitleSummary />
            <MessageSummary />
        </>
    );
};

export default NewsletterSummary;
