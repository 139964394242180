import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchCampaignSourceBrowsers } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import statsKeys from './statsKeys';
import { z } from 'zod';

export const BrowserSchema = z.object({
    count: z.number(),
    clientName: z.string(),
    percentage: z.number(),
});
export const BrowsersSchema = z.array(BrowserSchema);
export type Browser = z.infer<typeof BrowserSchema>;
const BrowserPaginationSchema = z.object({
    currentPage: z.number(),
    totalPages: z.number(),
    total: z.number().optional(),
    perPage: z.number().optional(),
});
const BrowserResponseSchema = z.object({
    data: BrowsersSchema,
    pagination: BrowserPaginationSchema,
});
type Key = QueryFunctionContext<ReturnType<typeof statsKeys.campaignSourceBrowsers>>;
type InputProps = {
    campaignId: string;
    start: string;
    end: string;
    page: number;
    options?: {
        enabled: boolean;
    };
};

const queryFn = async ({ queryKey: [{ campaignId, start, end, page }] }: Key) => {
    const { data, pagination } = await fetchCampaignSourceBrowsers(campaignId, {
        start,
        end,
        page,
    });
    return BrowserResponseSchema.parse(
        camelcaseKeys(
            {
                data,
                pagination: {
                    currentPage: pagination.current_page ?? 0,
                    totalPages: pagination.total_pages ?? 0,
                    total: pagination.total ?? 0,
                    perPage: pagination.per_page ?? 0,
                },
            },
            { deep: true },
        ),
    );
};

const useCampaignSourceBrowsersQuery = ({ campaignId, start, end, page, options }: InputProps) => {
    return useQuery({
        queryKey: statsKeys.campaignSourceBrowsers(campaignId, start, end, page),
        queryFn,
        enabled: options?.enabled || true,
    });
};

export default useCampaignSourceBrowsersQuery;
