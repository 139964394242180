import { ENABLE_PSO_COMPETITORS } from '../config/constants';
import useHasPsoFullUserOption from './useHasPsoFullUserOption.hook';

interface useHasAccessToPsoCompetitorsProps {
    showId: string;
}

const useHasAccessToPsoCompetitors = ({ showId }: useHasAccessToPsoCompetitorsProps): boolean => {
    const hasPsoFull = useHasPsoFullUserOption({ showId });
    return (ENABLE_PSO_COMPETITORS && hasPsoFull) || false;
};

export default useHasAccessToPsoCompetitors;
