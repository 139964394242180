export const PRICING = {
    FREEMIUM: 'freemium',
    LAUNCH: 'launch',
    BOOST: 'boost',
    ENTERPRISE: 'enterprise',
    SUPERSONIC: 'supersonic',
    BASIC: ['launch'],
    INTERMEDIATE: ['boost'],
    PREMIUM: ['enterprise', 'supersonic'],
};

const { LAUNCH, BOOST, ENTERPRISE, SUPERSONIC } = PRICING;

export const PRICING_BASIC = [LAUNCH];
export const PRICING_INTERMEDIATE = [...PRICING_BASIC, BOOST];
export const PRICING_PREMIUM = [...PRICING_INTERMEDIATE, ENTERPRISE, SUPERSONIC];

export const PRICING_NAME = {
    [PRICING.FREEMIUM]: 'Free',
    [PRICING.LAUNCH]: 'Launch',
    [PRICING.BOOST]: 'Boost',
    [PRICING.ENTERPRISE]: 'Enterprise',
    [PRICING.SUPERSONIC]: 'Supersonic',
};

export const PRICING_COMPARISON_URL_FR = 'https://www.ausha.co/fr/tarifs/#tableautarifs';
export const PRICING_COMPARISON_URL_EN = 'https://www.ausha.co/pricing/#tableautarifs';

export const PRICING_CURRENCY = {
    EUR: 'eur',
    USD: 'usd',
    GBP: 'gbp',
};

/**
 * Get the higher pricing of a given pricing if it exists, or null
 * Examples:
 * getHigherPricing('launch') -> 'boost'
 * getHigherPricing('supersonic') -> null
 */
export const getHigherPricing = (currentPricing) => {
    if (PRICING_BASIC.includes(currentPricing)) {
        return PRICING.BOOST;
    }
    if (PRICING_INTERMEDIATE.includes(currentPricing)) {
        return PRICING.SUPERSONIC;
    }
    if (PRICING_PREMIUM.includes(currentPricing)) {
        return null;
    }
    return null;
};

/**
 * Returns true if pricing1 is strictly higher than pricing2, else false.
 * pricing1 and pricing2 must be two valid pricing.
 *
 * @example
 * isHigherPricingThan('supersonic', 'launch') -> true
 * isHigherPricingThan('boost', 'boost') -> false
 * isHigherPricingThan('supersonic', 'test') -> false
 */
export const isHigherPricingThan = (pricing1, pricing2) => {
    if (PRICING.BASIC.includes(pricing1)) {
        return false;
    }
    if (PRICING.INTERMEDIATE.includes(pricing1)) {
        return PRICING.BASIC.includes(pricing2);
    }
    if (PRICING.PREMIUM.includes(pricing1)) {
        return PRICING.BASIC.includes(pricing2) || PRICING.INTERMEDIATE.includes(pricing2);
    }
    return false;
};

/**
 * Returns true if pricing1 is strictly lower than pricing2, else false.
 * pricing1 and pricing2 must be two valid pricing.
 *
 * @example
 * isLowerPricingThan('launch', 'supersonic') -> true
 * isLowerPricingThan('boost', 'boost') -> false
 * isLowerPricingThan('launch', 'test') -> false
 */
export const isLowerPricingThan = (pricing1, pricing2) => {
    if (PRICING.BASIC.includes(pricing1)) {
        return PRICING.INTERMEDIATE.includes(pricing2) || PRICING.PREMIUM.includes(pricing2);
    }
    if (PRICING.INTERMEDIATE.includes(pricing1)) {
        return PRICING.PREMIUM.includes(pricing2);
    }
    if (PRICING.PREMIUM.includes(pricing1)) {
        return false;
    }
    return false;
};

/**
 * Returns true if pricing1 and pricing2 are in the same pricing tier, else false.
 * pricing1 and pricing2 must be two valid pricing.
 *
 * @example
 * isSamePricingThan('boost', 'boost') -> true
 * isSamePricingThan('supersonic', 'test') -> false
 */
export const isSamePricingThan = (pricing1, pricing2) => {
    if (PRICING.BASIC.includes(pricing1)) {
        return PRICING.BASIC.includes(pricing2);
    }
    if (PRICING.INTERMEDIATE.includes(pricing1)) {
        return PRICING.INTERMEDIATE.includes(pricing2);
    }
    if (PRICING.PREMIUM.includes(pricing1)) {
        return PRICING.PREMIUM.includes(pricing2);
    }
    return false;
};

/**
 * Returns price as a number for a given number or string with currency.
 * Returns 0 if given string is not a valid number.
 *
 * @example
 * getPriceFromNumberOrString(10) -> 10
 * getPriceFromNumberOrString(10.99) -> 10.99
 * getPriceFromNumberOrString('10 €') -> 10
 * getPriceFromNumberOrString('$10') -> 10
 * getPriceFromNumberOrString('test') -> 0
 */
export const getPriceFromNumberOrString = (price) => {
    let priceNumber = price ?? 0;

    if (typeof price === 'string') {
        // Remove non-numbers characters
        priceNumber = parseFloat(price.replaceAll(/\D/g, ''));
    }

    if (Number.isNaN(priceNumber)) {
        return 0;
    }

    return priceNumber;
};

/**
 * Returns saving value between monthly price billed over 12 months and yearly price.
 * monthlyPrice and yearlyPrice can be numbers or string with currency.
 * Returns 0 if saving can't be calculated.
 *
 * @example
 * getSavingOnYearlyBilling(100, 1000) -> 200
 * getSavingOnYearlyBilling(17.99, 90) -> 125.88
 * getSavingOnYearlyBilling('100 €', 1000) -> 200
 * getSavingOnYearlyBilling('100 €', '1000 €') -> 200
 * getSavingOnYearlyBilling('100 €', 'test') -> 0
 */
export const getSavingOnYearlyBilling = (monthlyPrice, yearlyPrice) => {
    const monthlyPriceNumber = getPriceFromNumberOrString(monthlyPrice);
    const yearlyPriceNumber = getPriceFromNumberOrString(yearlyPrice);

    if (monthlyPriceNumber === 0 || yearlyPriceNumber === 0) {
        return 0;
    }

    return 12 * monthlyPriceNumber - yearlyPriceNumber;
};

/**
 * Returns monthly price for a given yearly billing price.
 * yearlyPrice can be number or string with currency.
 * Returns 0 if monthly price can't be calculated.
 *
 * @example
 * getYearlyBillingPriceForOneMonth(120) -> 10
 * getYearlyBillingPriceForOneMonth(148.80) -> 12.4
 * getYearlyBillingPriceForOneMonth('120 €') -> 10
 * getYearlyBillingPriceForOneMonth('test') -> 0
 */
export const getYearlyBillingPriceForOneMonth = (yearlyPrice) => {
    const yearlyPriceNumber = getPriceFromNumberOrString(yearlyPrice);

    return yearlyPriceNumber / 12;
};
