import Form from '@/components/unorganized/Form';
import CampaignEditHeader from '@/components/unorganized/CampaignEditHeader';
import CampaignNewHeader from '@/components/unorganized/CampaignNewHeader';
import Stack from '@ui/layout/Stack';
import DeprecatedAccordion from '@ui/smarties/DeprecatedAccordion';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import CampaignGeneral from '../CampaignGeneral';
import CampaignAudio from '../CampaignAudio';
import CampaignDuration from '../CampaignDuration';
import CampaignEpisodes from '../CampaignEpisodes';
import CampaignForm from '@/forms/CampaignEditForm';
import { FormattedMessage } from 'react-intl';
import DeprecatedButton from '@ui/atoms/DeprecatedButton';
import { CAMPAIGN_STATUS } from '@/shared/config/constants';

const CampaignEditWrapper = styled(Stack)`
    position: fixed;
    width: 100%;
    overflow-y: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background: var(--neutral50);
`;

const AccordionWrapper = styled.div`
    width: 100%;
    max-width: 960px;
    margin-inline: auto;
    padding: 1rem;
`;

const CollapseButton = ({ index, setIndex }) => {
    return (
        <DeprecatedButton
            background="white"
            color="--primary"
            type="button"
            onClick={() => setIndex(index + 1)}
        >
            <FormattedMessage defaultMessage="Terminé" />
        </DeprecatedButton>
    );
};

const CampaignEditForm = ({ campaign, onSubmit, onAfterSubmit, isNew }) => {
    const form = () => CampaignForm(campaign, { autosave: isNew });
    const [index, setIndex] = useState(0);
    useEffect(() => (campaign ? undefined : setIndex(0)), [campaign]);
    const isImmutable =
        campaign.status === CAMPAIGN_STATUS.PAUSED || campaign.status === CAMPAIGN_STATUS.ACTIVE;
    const button = <CollapseButton index={index} setIndex={setIndex} />;

    return (
        <Form form={form} onSubmit={onSubmit} onAfterSubmit={onAfterSubmit} flex>
            {({ fields, hasChanged, isValid, submit }) => (
                <CampaignEditWrapper>
                    {isNew ? (
                        <CampaignNewHeader
                            isLoading={hasChanged}
                            submit={submit}
                            hasError={!isValid}
                        />
                    ) : (
                        <CampaignEditHeader campaign={campaign} hasChanged={hasChanged} />
                    )}
                    <AccordionWrapper>
                        <DeprecatedAccordion
                            index={index}
                            number={isNew}
                            onChange={setIndex}
                            mx="auto"
                            mt={35 + 80}
                            maxW={960}
                            align="center"
                            column
                            flex
                            progress
                            px={20}
                        >
                            <CampaignGeneral
                                isImmutable={isImmutable}
                                button={button}
                                fields={fields}
                                mb={15}
                            />
                            <CampaignAudio
                                isImmutable={isImmutable}
                                button={button}
                                fields={fields}
                                mb={15}
                            />
                            <CampaignDuration
                                button={button}
                                fields={fields}
                                mb={15}
                                style={{ zIndex: 2 }}
                                isImmutable={isImmutable}
                            />
                            <CampaignEpisodes button={button} fields={fields} />
                        </DeprecatedAccordion>
                    </AccordionWrapper>
                </CampaignEditWrapper>
            )}
        </Form>
    );
};

export default CampaignEditForm;
