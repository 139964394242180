import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { MAX_AUDIO_FILE_SIZE } from '@/shared/config/constants';
import { checkAudioFileFormat, checkAudioFileSize } from '@/helpers';

// NOTE: copy `useNonHostingEpisodeAudioFileSchema` if you want to use typescript
const useEpisodeAudioFileSchema = (locale = 'en') => {
    const intl = useIntl();
    return yup.object({
        audioFile: yup
            .mixed()
            .required(
                intl.formatMessage({
                    defaultMessage: 'Un fichier audio est requis',
                }),
            )
            .test(
                'fileFormat',
                intl.formatMessage({
                    defaultMessage:
                        'Votre fichier doit être au format .MP3, .WAV, .M4A, .OGG ou .FLAC',
                }),
                (audioFile) =>
                    checkAudioFileFormat(audioFile.length > 0 ? audioFile?.[0] : audioFile),
            )
            .test(
                'fileSize',
                intl.formatMessage(
                    { defaultMessage: 'Votre fichier doit peser au maximum {max}Mo' },
                    { max: MAX_AUDIO_FILE_SIZE / 1000000 },
                ),
                (audioFile) => {
                    if (Array.isArray(audioFile)) {
                        return checkAudioFileSize(audioFile?.[0], locale);
                    }
                    return checkAudioFileSize(audioFile, locale);
                },
            ),
    });
};

export default useEpisodeAudioFileSchema;
