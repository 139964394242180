import { useFormContext } from 'react-hook-form';
import SelectSeason from '@/components/unorganized/SelectSeason';

const EpisodeEditFormOptionsSeasonField = () => {
    const { control } = useFormContext();

    return <SelectSeason name="options.seasonId" control={control} />;
};

export default EpisodeEditFormOptionsSeasonField;
