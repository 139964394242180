import { FormattedMessage } from 'react-intl';
import DeprecatedModal from '../../ui/molecules/DeprecatedModal';
import DeprecatedText from '../../ui/atoms/DeprecatedText';
import Spinner from '../../ui/atoms/Spinner';
import DeprecatedButton from '../../ui/atoms/DeprecatedButton';
import styled from 'styled-components';

function DeleteCampaignModal({ isOpened, onClose, count, isLoading, onSubmit }) {
    return (
        <DeprecatedModal
            isOpened={isOpened}
            onClose={onClose}
            title={
                <FormattedMessage
                    defaultMessage="Supprimer {count, plural, one {une campagne} other {des campagnes}}"
                    values={{ count }}
                />
            }
        >
            <ModalContentWrapper>
                <DeprecatedText>
                    <FormattedMessage
                        defaultMessage="Êtes-vous sûr de supprimer {count, plural, one {une campagne} other {# campagnes}} ? Cette action est irréversible."
                        values={{ count }}
                    />
                </DeprecatedText>
                <ActionWrapper>
                    <DeprecatedButton accent="secondary" mr={10} onClick={onClose}>
                        <FormattedMessage defaultMessage="Annuler" />
                    </DeprecatedButton>
                    <DeprecatedButton onClick={() => onSubmit().then(onClose)} disabled={isLoading}>
                        {isLoading && <Spinner />}
                        <FormattedMessage
                            defaultMessage="Supprimer {count, plural, one {la campagne} other {les campagnes}}"
                            values={{ count }}
                        />
                    </DeprecatedButton>
                </ActionWrapper>
            </ModalContentWrapper>
        </DeprecatedModal>
    );
}

const ModalContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
`;
const ActionWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export default DeleteCampaignModal;
