import { useState } from 'react';
import Button from '@/components/Button';
import Text from '@/components/ui/atoms/Text';
import Stack from '@/components/ui/layout/Stack';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import styled from 'styled-components';
import useSubscriptionQuery from '@/queries/subscription/useSubscriptionQuery.hook';
import { PRICING } from '@/shared/config/pricing';
import ExternalLink from '@/components/Link/ExternalLink';
import AushaIntelligenceLogo from '@public/logos/ausha-intelligence.svg';
import NebulaStar from '@public/icons/star-nebula.svg';
import SubscribeToAIModal from './SubscribeToAIModal';
import GiftNebulaIcon from '@public/icons/gift-nebula.svg';

const NotActive = () => {
    const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);
    const subscription = useSubscriptionQuery();
    // TODO: Replace with dynamic prices
    const yearlyPriceInt = 12.5;
    const monthlyPriceInt = 15;
    // TODO: Use currency from context when available
    const currency = 'usd';
    const isPro = subscription.data?.pricing === PRICING.ENTERPRISE;

    return (
        <>
            <Wrapper>
                <Left>
                    <AILogo />
                    <BulletList>
                        <Bullet>
                            <StarIcon />
                            <Text>
                                <FormattedMessage
                                    defaultMessage="Génération automatique de <b>titres d'épisodes, de descriptions, de tags, de chapitres et de newsletters</b>."
                                    values={{
                                        b: (chunks: string) => (
                                            <Text as="span" fontWeight="--fw-semibold">
                                                {chunks}
                                            </Text>
                                        ),
                                    }}
                                />
                            </Text>
                        </Bullet>
                        <Bullet>
                            <StarIcon />
                            <Text>
                                <FormattedMessage
                                    defaultMessage="Génération automatique de <b>posts pour les réseaux sociaux</b>."
                                    values={{
                                        b: (chunks: string) => (
                                            <Text as="span" fontWeight="--fw-semibold">
                                                {chunks}
                                            </Text>
                                        ),
                                    }}
                                />
                            </Text>
                        </Bullet>
                        <Bullet>
                            <StarIcon />
                            <Text>
                                <FormattedMessage
                                    defaultMessage="<b>Tonalité et personnalisation</b> des prompts d'Ausha Intelligence."
                                    values={{
                                        b: (chunks: string) => (
                                            <Text as="span" fontWeight="--fw-semibold">
                                                {chunks}
                                            </Text>
                                        ),
                                    }}
                                />
                            </Text>
                        </Bullet>
                        <Bullet>
                            <GiftIcon />
                            <Text>
                                <FormattedMessage
                                    defaultMessage="<b>+60 minutes</b> de transcription incluses par mois."
                                    values={{
                                        b: (chunks: string) => (
                                            <Text as="span" fontWeight="--fw-semibold">
                                                {chunks}
                                            </Text>
                                        ),
                                    }}
                                />
                            </Text>
                        </Bullet>
                    </BulletList>
                </Left>
                <Right>
                    <Stack $gap="0.25rem">
                        <Text variant="headingL" fontWeight="--fw-bold">
                            <FormattedMessage
                                defaultMessage="{amount} /mois"
                                values={{
                                    amount: (
                                        <FormattedNumber
                                            value={yearlyPriceInt}
                                            style="currency"
                                            currency={currency}
                                            currencyDisplay="narrowSymbol"
                                            maximumFractionDigits={2}
                                        />
                                    ),
                                }}
                            />
                        </Text>
                        <Text color="--neutral500">
                            <FormattedMessage
                                defaultMessage="Facturation annuelle ou {amount} par mois."
                                values={{
                                    amount: (
                                        <FormattedNumber
                                            value={monthlyPriceInt}
                                            style="currency"
                                            currency={currency}
                                            currencyDisplay="narrowSymbol"
                                            maximumFractionDigits={0}
                                        />
                                    ),
                                }}
                            />
                        </Text>
                    </Stack>
                    {isPro ? (
                        <ExternalLink
                            href="mailto:sales@ausha.co"
                            variant="button-magic"
                            startIcon={
                                <ButtonIcon icon={icon({ name: 'envelope', style: 'solid' })} />
                            }
                        >
                            <FormattedMessage defaultMessage="Nous contacter" />
                        </ExternalLink>
                    ) : (
                        <Button
                            onPress={() => setIsSubscriptionModalOpen(true)}
                            variant="magic"
                            startIcon={
                                <ButtonIcon icon={icon({ name: 'sparkles', style: 'duotone' })} />
                            }
                        >
                            <FormattedMessage defaultMessage="Souscrire à AI" />
                        </Button>
                    )}
                </Right>
            </Wrapper>
            <SubscribeToAIModal
                isOpen={isSubscriptionModalOpen}
                onOpenChange={setIsSubscriptionModalOpen}
            />
        </>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
    }
`;
const Left = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-grow: 1;
    }
`;
const Right = styled.div`
    padding: 1.5rem;
    border-radius: var(--r-m);
    background: var(--gradient-aurora);
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    min-width: 20rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-shrink: 0;
    }
`;
const StarIcon = styled(NebulaStar)`
    width: 0.75rem;
    height: 0.75rem;
    margin-top: 0.25rem;
    flex-shrink: 0;
`;
const BulletList = styled.ul`
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
`;
const Bullet = styled.li`
    display: flex;
    align-items: flex-start;
    column-gap: 0.5rem;
`;
const ButtonIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const AILogo = styled(AushaIntelligenceLogo)`
    width: 100%;
    max-width: 19.2rem;
    height: 2rem;
`;
const GiftIcon = styled(GiftNebulaIcon)`
    width: 0.75rem;
    height: 0.75rem;
    margin-top: 0.25rem;
    flex-shrink: 0;
`;

export default NotActive;
