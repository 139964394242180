import { useRef, useState } from 'react';
import Button from '@/components/Button';
import IconButton from '@/components/IconButton';
import Modal from '@/components/ui/atoms/Modal';
import Text from '@/components/ui/atoms/Text';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import InputText from '@ui/atoms/InputText';
import styled from 'styled-components';

interface EditTitleModalProps {
    isOpen: boolean;
    onOpenChange: (open: boolean) => void;
    onEdit: (title: string) => void;
    isLoading: boolean;
    value: string;
}

const EditTitleModal = ({
    isOpen,
    onOpenChange,
    onEdit,
    isLoading,
    value,
}: EditTitleModalProps) => {
    const [title, setTitle] = useState(value);
    const ref = useRef<HTMLInputElement>(null);

    const handleClose = () => onOpenChange(false);

    return (
        <ModalStyled isOpen={isOpen} onOpenChange={onOpenChange} size="medium">
            <Header>
                <Text variant="headingM" fontWeight="--fw-bold">
                    <FormattedMessage defaultMessage="Renommer le clip" />
                </Text>
                <IconButton
                    icon={<CloseIcon icon={icon({ name: 'xmark-large', style: 'solid' })} />}
                    onPress={handleClose}
                    variant="ghost"
                    aria-label="Close modal"
                    style={{ padding: 0 }}
                />
            </Header>
            <InputText
                ref={ref}
                autoFocus
                label={<FormattedMessage defaultMessage="Nom du clip" />}
                value={title}
                onChange={(value) => setTitle(value)}
                maxLength={100}
                characterOverflowBehavior="block"
                onKeyDown={(event) => {
                    if (event.target.value.length === 0) return;
                    event.key === 'Enter' && onEdit(title);
                }}
            />
            <ActionWrapper>
                <Button onPress={() => onEdit(title)} isLoading={isLoading}>
                    <FormattedMessage defaultMessage="Enregistrer" />
                </Button>
                <CancelButton variant="ghost" onPress={handleClose}>
                    <FormattedMessage defaultMessage="Annuler" />
                </CancelButton>
            </ActionWrapper>
        </ModalStyled>
    );
};

const ModalStyled = styled(Modal)`
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 2rem;
    overflow-y: auto;
`;
const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 1rem;
`;
const CloseIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--neutral500);
`;
const ActionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 1rem;

    ${({ theme }) => theme.mediaQueries.tabletAndUp} {
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        & > :first-child {
            order: 1;
        }
    }
`;
const CancelButton = styled(Button)`
    padding: 0;
    color: var(--neutral500);
`;

export default EditTitleModal;
