import { Component } from 'react';
import * as Sentry from '@sentry/react';
import ErrorMessage from '../../ui/molecules/ErrorMessage';

export default class FormErrorBoundary extends Component {
    state = { hasError: false };

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // Don't pass server errors
        if (error.response) {
            return;
        }

        if (process.env.NODE_ENV === 'development') {
            console.log(error);
        }

        Sentry.withScope((scope) => {
            scope.setExtras(errorInfo);
            Sentry.captureException(error);
        });
    }

    render() {
        if (this.state.hasError) {
            return <ErrorMessage />;
        }
        return this.props.children;
    }
}
