import { computed } from 'mobx';
import Model from './Model';

/**
 * This model can possibly be removed and replaced by the podcast rank model
 */
class PodcastRankPercentModel extends Model {
    static attributes = {
        downloads: 'downloads',
        name: 'name',
        percentage: 'percentage',
        id: 'podcast_id',
    };

    @computed
    get podcast() {
        return this.state.podcastStore.byId(this.id);
    }
}

export default PodcastRankPercentModel;
