import { FormattedMessage } from 'react-intl';
import Dialog from '@/components/ui/atoms/Dialog';
import Button from '@/components/ui/atoms/Button';
import Text from '@/components/ui/atoms/Text';
import { ShowGuestInvitation } from '@/queries/guest/useShowGuestInvitationsQuery.hook';
import deleteShowGuestInvitationMutation from '@/queries/guest/deleteShowGuestInvitationMutation';

interface ShowGuestsInvitationDeleteModalProps {
    isOpen: boolean;
    onOpenChange: (open: boolean) => void;
    invitation: ShowGuestInvitation;
}

const ShowGuestsInvitationDeleteModal = ({
    isOpen,
    onOpenChange,
    invitation,
}: ShowGuestsInvitationDeleteModalProps) => {
    const deleteInvitation = deleteShowGuestInvitationMutation();

    const handleDelete = () => {
        deleteInvitation.mutate(invitation.id, {
            onSuccess: () => {
                onOpenChange(false);
            },
        });
    };

    return (
        <Dialog
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            title={<FormattedMessage defaultMessage="Supprimer un utilisateur" />}
            size="medium"
            action={
                <>
                    <Button variant="secondary" onPress={() => onOpenChange(false)}>
                        <FormattedMessage defaultMessage="Annuler" />
                    </Button>
                    <Button onPress={handleDelete} isLoading={deleteInvitation.isLoading}>
                        <FormattedMessage defaultMessage="Supprimer l'utilisateur" />
                    </Button>
                </>
            }
        >
            <Text>
                <FormattedMessage
                    defaultMessage="Souhaitez-vous supprimer {name} de la liste des utilisateurs ?"
                    values={{
                        name: (
                            <Text as="span" fontWeight="--fw-semibold">
                                {invitation.email}
                            </Text>
                        ),
                    }}
                />
            </Text>
        </Dialog>
    );
};

export default ShowGuestsInvitationDeleteModal;
