import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'mobx-react';
import stores from '@/stores/RootStore';
import App from '@/components/App';
import { mediaQueries } from '@/styles/theme';
import IntlProvider from './unorganized/IntlProvider';
import Intercom from './unorganized/Intercom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { CurrentShowContext } from '@/context/CurrentShowContext';
import GlobalToastRegions from '@ui/molecules/GlobalToastRegions';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import * as Sentry from '@sentry/react';
import { Theme } from '@/styles/theme';
import ErrorBoundaryFallback from './ui/ErrorBoundaryFallback';

function getUserConfirmation(dialogKey, callback) {
    // use "message" as Symbol-based key
    const dialogTrigger = window[Symbol.for(dialogKey)];
    if (dialogTrigger) {
        // pass the callback to delegate
        // to the invoked dialog
        return dialogTrigger(callback);
    }
    // Fallback to allowing navigation
    return callback(true);
}

const stripePromise = loadStripe(process.env.STRIPE_KEY);

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 1000 /* ms */ * 60 /* sec */ * 60 /* min */, // 60 min
            retry: (failureCount, error) => {
                /**
                 * Error is unexpected (5xx-range), setup default retry count of 3 retries.
                 */
                if (error.response?.status >= 500 && error.response?.status <= 599) {
                    return failureCount < 3;
                }
                /**
                 * Error code matches known and predictable error (typically 4xx-range),
                 * don't bother retrying, it'll just yield more of the same error.
                 */
                return false;
            },
        },
    },
});

function Root() {
    return (
        <Elements stripe={stripePromise}>
            <Provider stores={stores}>
                <BrowserRouter getUserConfirmation={getUserConfirmation}>
                    <QueryClientProvider client={queryClient}>
                        <IntlProvider>
                            <ThemeProvider theme={mediaQueries}>
                                <Theme />
                                <CurrentShowContext>
                                    <Sentry.ErrorBoundary fallback={<ErrorBoundaryFallback />}>
                                        <App />
                                    </Sentry.ErrorBoundary>
                                    <GlobalToastRegions />
                                    <Intercom />
                                    <ReactQueryDevtools initialIsOpen={false} />
                                </CurrentShowContext>
                            </ThemeProvider>
                        </IntlProvider>
                    </QueryClientProvider>
                </BrowserRouter>
            </Provider>
        </Elements>
    );
}

export default Root;
