import { useMutation } from '@tanstack/react-query';
import { exportChannelGuests } from '@/api';

export interface AccountShowGuestsExport {
    filename: string;
    guests: string[];
}

export interface MutationFnProps {
    channelId: string;
}

const mutationFn = async ({ channelId }: MutationFnProps): Promise<AccountShowGuestsExport> => {
    const response = await exportChannelGuests(channelId);

    const filename = response.axios.headers['content-disposition']
        .split('filename="')[1]
        .split('"')[0];

    const formattedResponse = Object.values(response) as string[];
    return { filename, guests: formattedResponse.slice(0, formattedResponse.length - 1) };
};

const exportChannelGuestsMutation = () => {
    return useMutation({
        mutationFn,
    });
};

export default exportChannelGuestsMutation;
