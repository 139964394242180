import { createContext, useContext } from 'react';
import { useParams } from 'react-router-dom';
import useEpisodeTranscriptQuery from '@/queries/episodeTranscript/useEpisodeTranscriptQuery.hook';
import useEpisodeTranscriptStatusQuery from '@/queries/episodeTranscript/useEpisodeTranscriptStatusQuery.hook';
import { EPISODE_TRANSCRIPT_STATUS } from '@/shared/config/constants';
import { useQueryClient } from '@tanstack/react-query';
import episodeTranscriptKeys from '@/queries/episodeTranscript/episodeTranscriptKeys';
import PropTypes from 'prop-types';
import useHasAccessToTranscription from '@/shared/hooks/useHasAccessToTranscription.hook';

// TODO: Type this context and the transcription feature

const NonHostingEpisodeTranscriptionContext = createContext();
const NonHostingEpisodeTranscriptionStatusContext = createContext();
const NonHostingEpisodeTranscriptionFilesContext = createContext();

export const TranscriptionContextProvider = (props) => {
    const { children } = props;
    const { showId } = useParams();
    const hasAccessToTranscription = useHasAccessToTranscription({ showId });

    if (!hasAccessToTranscription) return children;

    return <Provider {...props} />;
};

const Provider = ({ children }) => {
    const { episodeId } = useParams();
    const queryClient = useQueryClient();
    const episodeTranscript = useEpisodeTranscriptQuery(episodeId);
    const { data: episodeTranscriptStatus } = useEpisodeTranscriptStatusQuery(episodeId);

    /*
        If the episode transcript status is DONE or UPDATED, invalidate the episode transcript query
        so that the transcript is fetched again.
    */
    if (
        !episodeTranscript.data &&
        (episodeTranscriptStatus?.status === EPISODE_TRANSCRIPT_STATUS.DONE ||
            episodeTranscriptStatus?.status === EPISODE_TRANSCRIPT_STATUS.UPDATED)
    ) {
        queryClient.invalidateQueries({
            queryKey: episodeTranscriptKeys.detailByEpisodeId(episodeId),
        });
    }

    return (
        <NonHostingEpisodeTranscriptionContext.Provider value={episodeTranscript}>
            <NonHostingEpisodeTranscriptionStatusContext.Provider value={episodeTranscriptStatus}>
                <NonHostingEpisodeTranscriptionFilesContext.Provider
                    value={episodeTranscriptStatus?.files}
                >
                    {children}
                </NonHostingEpisodeTranscriptionFilesContext.Provider>
            </NonHostingEpisodeTranscriptionStatusContext.Provider>
        </NonHostingEpisodeTranscriptionContext.Provider>
    );
};

export const useNonHostingEpisodeTranscription = () => {
    return useContext(NonHostingEpisodeTranscriptionContext);
};

export const useNonHostingEpisodeTranscriptionStatus = () => {
    return useContext(NonHostingEpisodeTranscriptionStatusContext);
};

export const useNonHostingEpisodeTranscriptionFiles = () => {
    return useContext(NonHostingEpisodeTranscriptionFilesContext);
};

TranscriptionContextProvider.propTypes = {
    children: PropTypes.node,
};
