import { FormattedMessage } from 'react-intl';
import ListenerGraph from '../organisms/ListenersGraph';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import { TabLinkButton } from '../molecules/UsefulLinkButtons';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import useRouterStore from '@hooks/useRouterStore';
import usePodcastStore from '@hooks/usePodcastStore';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import styled from 'styled-components';

const EpisodePreviewListenerGraph = ({ isLoading, getGraphValues }) => {
    const { link } = useRouterStore();
    const { podId } = useParams();
    const podcast = usePodcastStore().byId(podId);

    return (
        <>
            <DeprecatedPaper flex align="center" mt={35} mb={20}>
                <DeprecatedText weight="bold" size="veryBig">
                    <FormattedMessage defaultMessage="Auditeurs" />
                </DeprecatedText>
                <DeprecatedPaper flex justify="flex-end">
                    <TabLinkButton
                        message={<FormattedMessage defaultMessage="Voir toutes les statistiques" />}
                        to={link('stats.query', {
                            tab: 'listeners',
                            queryKeys: 'episode',
                            queryValues: podcast?.id,
                        })}
                    />
                </DeprecatedPaper>
            </DeprecatedPaper>
            <ListenerGraphWrapper>
                <ListenerGraph
                    isLoading={isLoading}
                    isCalendarGraphRange={false}
                    getGraphValues={getGraphValues}
                    isActiveTabSingleListener={false}
                />
            </ListenerGraphWrapper>
        </>
    );
};

const ListenerGraphWrapper = styled.div`
    width: 100%;
    padding: var(--container-padding);
    border-radius: var(--r-xs);
    background-color: var(--white);
`;

export default observer(EpisodePreviewListenerGraph);
