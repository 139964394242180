import { FormattedMessage } from 'react-intl';
import { EPISODE_JOB_NAME } from '@/utils/constants';
import AudioFileJobsProgressTracker from '@/components/ui/molecules/AudioFileJobsProgressTracker';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { STATUSES } from '@/models/Progress';

const iconColor = {
    [STATUSES.QUEUED]: '--neutral500',
    [STATUSES.FAILED]: '--alert500',
    [STATUSES.FINISHED]: '--primary',
    [STATUSES.EXECUTING]: '--primary',
};

const ChaptersProgressTracker = ({ progress }) => {
    const isReadChaptersFinished = progress?.[EPISODE_JOB_NAME.READ_CHAPTERS]?.isFinished;
    const isConvertToMp3Finished = progress?.[EPISODE_JOB_NAME.CONVERT_TO_MP3]?.isFinished;

    return (
        <ResponsiveWrapper>
            <AudioFileJobsProgressTracker
                progress={progress?.[EPISODE_JOB_NAME.READ_CHAPTERS]}
                label={<FormattedMessage defaultMessage="Récupération des chapitres" />}
            >
                <ProgressIcon
                    icon={icon({
                        name: 'bookmark',
                        style: 'solid',
                    })}
                    status={progress?.[EPISODE_JOB_NAME.READ_CHAPTERS]?.status}
                />
            </AudioFileJobsProgressTracker>
            <Divider isCompleted={isReadChaptersFinished} />
            <AudioFileJobsProgressTracker
                progress={progress?.[EPISODE_JOB_NAME.CONVERT_TO_MP3]}
                label={<FormattedMessage defaultMessage="Conversion du fichier audio" />}
            >
                <ProgressIcon
                    icon={icon({
                        name: 'file-music',
                        style: 'solid',
                    })}
                    status={progress?.[EPISODE_JOB_NAME.CONVERT_TO_MP3]?.status}
                />
            </AudioFileJobsProgressTracker>
            <Divider isCompleted={isConvertToMp3Finished} />
            <AudioFileJobsProgressTracker
                progress={progress?.[EPISODE_JOB_NAME.GENERATE_WAVEFORM]}
                label={<FormattedMessage defaultMessage="Génération du chronographe" />}
            >
                <ProgressIcon
                    icon={icon({
                        name: 'waveform-lines',
                        style: 'solid',
                    })}
                    status={progress?.[EPISODE_JOB_NAME.GENERATE_WAVEFORM]?.status}
                />
            </AudioFileJobsProgressTracker>
        </ResponsiveWrapper>
    );
};

const ResponsiveWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        width: 100%;
        flex-direction: row;
        column-gap: 0.5rem;
        padding: 2rem;
    }
`;
const Divider = styled.div`
    width: 0.25rem;
    height: 4rem;
    border-radius: var(--r-full);
    background-color: var(--neutral100);
    margin-left: 1.38rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex: 1;
        height: 0.25rem;
        margin-top: 1rem;
        margin-left: 0;
    }

    ${({ isCompleted }) =>
        isCompleted &&
        `
        background-color: var(--primary);
    `}
`;
const ProgressIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--neutral500);

    ${({ status }) => iconColor[status] && `color: var(${iconColor[status]});`}
`;

export default ChaptersProgressTracker;
