import styled from 'styled-components';
import EpisodeEditFormAccordion from '@/components/unorganized/EpisodeEditForm/EpisodeEditFormAccordion';
import EpisodeEditSidebar from './EpisodeEditSidebar';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem 2rem;
    width: 100%;

    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        flex-direction: row;
        max-width: calc(var(--container-width) - 4rem);
    }
`;

const Content = styled.div`
    flex-grow: 1;
    min-width: 0;
`;

const EpisodeEdit = () => {
    return (
        <Container>
            <EpisodeEditSidebar />
            <Content>
                <EpisodeEditFormAccordion />
            </Content>
        </Container>
    );
};

export default EpisodeEdit;
