import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import ListFormFallback from '../fallbacks/ListFormFallback';
import ListenersExportButton from '../molecules/ListenersExportButton';
import { connect } from '../decorators/connect';
import { useResponsive } from '../../../utils/hooks/useResponsive';
import FetcherStats from '../fetchers/stats/FetcherStats';
import ListenersVariationGraphTable from '../molecules/ListenersVariationGraphTable';
import ListenersGraph from './ListenersGraph';
import { TABS_STATS_NAME } from '../pages/StatsPage';
import StatsDataSetSelector from './StatsDataSetSelector';
import { useStatsDateSelectorContext } from '@/context/StatsDateSelectorContext';
import styled from 'styled-components';

const enhance = connect(({ listenersStatsStore, routerStore }) => ({
    resetVariations: listenersStatsStore.resetVariations,
    episodeQuery: routerStore.query.episode,
}));

const ListenersVariationGraph = ({ resetVariations, episodeQuery }) => {
    const { startDate, endDate } = useStatsDateSelectorContext();
    const { isMobileOrTablet } = useResponsive();
    const { showId, itemType, itemId } = useParams();
    const [step, setStep] = useState('days');
    const [hoveredValue, setHoveredValue] = useState(null);
    const [scrollToValue, setScrollToValue] = useState(null);
    const [tabValue, setTabValue] = useState('listening');

    const getGraphValues = {
        getStep: setStep,
        getScroll: (scrollValue) => {
            setScrollToValue(scrollValue);
            setHoveredValue(scrollValue);
        },
        getTabValue: setTabValue,
        tabValue,
    };

    useEffect(
        () => () => {
            resetVariations();
        },
        [step],
    );

    if (!startDate) return <ListFormFallback />;

    return (
        <FetcherStats
            showId={showId}
            itemType={itemType}
            itemId={itemId}
            optionsType={tabValue}
            step={isMobileOrTablet ? 'auto' : step}
            start={startDate}
            end={endDate}
            episodes={episodeQuery}
        >
            {(isLoading) => (
                <>
                    <StatsDataSetSelector
                        tabTitle={<FormattedMessage defaultMessage="Détails de vos écoutes" />}
                        subTitle={
                            <FormattedMessage defaultMessage="Nombre d’écoutes cumulées et auditeurs uniques par épisode." />
                        }
                        tabId={TABS_STATS_NAME.LISTENERS}
                        getGraphValues={getGraphValues}
                    />
                    <ListenersOuterWrapper>
                        <ListenerGraphWrapper>
                            <ListenersGraph isLoading={isLoading} getGraphValues={getGraphValues} />
                        </ListenerGraphWrapper>
                        <ListenersVariationGraphTable
                            isLoading={isLoading}
                            hoveredValue={hoveredValue}
                            scrollToValue={scrollToValue}
                            onHover={setHoveredValue}
                        />
                    </ListenersOuterWrapper>
                    <ListenersExportButton step={step} optionsType={getGraphValues.tabValue} />
                </>
            )}
        </FetcherStats>
    );
};

const ListenersOuterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;
const ListenerGraphWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding);
    display: flex;
    flex-direction: column;
    gap: 1rem;

    ${({ theme }) => theme.mediaQueries.mobile} {
        padding: var(--container-padding-mobile);
    }
`;

export default enhance(ListenersVariationGraph);
