import Model from './Model';

class CallToActionModel extends Model {
    static default = () => ({
        text: '',
        url: '',
        locations: [],
    });

    static attributes = {
        id: 'id',
        showId: 'show_id',
        type: 'type',
        text: 'text',
        url: 'url',
        locations: 'locations',
    };
}

export default CallToActionModel;
