import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deletePodcastImage } from '@/api';
import episodeKeys from '@/queries/episode/episodeKeys';
import camelcaseKeys from 'camelcase-keys';

export interface MutationFnProps {
    episodeId: string;
}

const mutationFn = async ({ episodeId }: MutationFnProps) => {
    const { data } = await deletePodcastImage(episodeId);

    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const useDeleteEpisodeImageMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: (_, { episodeId }) => {
            queryClient.invalidateQueries({
                queryKey: episodeKeys.detailById(episodeId),
            });
        },
    });
};

export default useDeleteEpisodeImageMutation;
