import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const SemiCircularProgress = ({ progressColor, backgroundColor, size, percentage, children }) => {
    const percentageNormalized = percentage < 0 ? 0 : percentage > 100 ? 100 : percentage;
    const dashOffset = 126 - (percentageNormalized / 100) * 126;

    return (
        <SemiCircularProgressWrapper size={size}>
            <svg viewBox="0 0 100 60">
                <Cap
                    x="4"
                    y="47"
                    width="12"
                    height="9"
                    rx="3"
                    covered={percentageNormalized > 0}
                    backgroundColor={backgroundColor}
                    progressColor={progressColor}
                />
                <Cap
                    x="84"
                    y="47"
                    width="12"
                    height="9"
                    rx="3"
                    covered={percentageNormalized > 99}
                    backgroundColor={backgroundColor}
                    progressColor={progressColor}
                />

                <Track d="M10,50 A20,20 0 1,1 90,50" fill="none" color={backgroundColor} />
                <Progress
                    fill="none"
                    d="M10,50 A20,20 0 1,1 90,50"
                    strokeDashoffset={dashOffset}
                    color={progressColor}
                />
            </svg>
            <CenterPartWrapper>{children}</CenterPartWrapper>
        </SemiCircularProgressWrapper>
    );
};

const SemiCircularProgressWrapper = styled.div`
    position: relative;
    width: ${({ size }) => `${size}px`};
    height: auto;

    & > svg {
        height: 100%;
        margin: auto;
        display: block;
    }
`;
const Cap = styled.rect`
    fill: ${(p) => `var(${p.covered ? p.progressColor : p.backgroundColor})`};
`;
const BasePath = css`
    stroke-linecap: round;
    stroke-width: 12;
`;
const Track = styled.path`
    ${BasePath};
    stroke: ${({ color }) => `var(${color})`};
`;
const Progress = styled.path`
    ${BasePath};
    stroke: ${({ color }) => `var(${color})`};
    stroke-dasharray: 126;
    transition: stroke-dashoffset 0.3s ease-out;
`;
const CenterPartWrapper = styled.div`
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
`;

SemiCircularProgress.propTypes = {
    progressColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    size: PropTypes.number,
    percentage: PropTypes.number,
    children: PropTypes.node,
};

SemiCircularProgress.defaultProps = {
    progressColor: '--primary',
    backgroundColor: '--neutral100',
    size: 400,
    percentage: 75,
};

export default SemiCircularProgress;
