import { useMutation, useQueryClient } from '@tanstack/react-query';
import { retryImport } from '@/api';
import rssKeys from './rssKeys';

export interface MutationFnProps {
    episodeIds?: number[];
    showId: string;
}

const mutationFn = async ({ episodeIds }: MutationFnProps) => {
    if (!episodeIds) return;
    const promises = episodeIds.map((id) => {
        return retryImport(id);
    });

    return await Promise.all(promises);
};

const useRetryAllImportMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({
                queryKey: rssKeys.episodes(variables.showId, 'error'),
            });
            queryClient.invalidateQueries({
                queryKey: rssKeys.episodes(variables.showId, 'error'),
            });
        },
    });
};

export default useRetryAllImportMutation;
