import { useParams } from 'react-router-dom';
import Store from './Store';
import Button from '@/components/Button';
import { FormattedMessage } from 'react-intl';
import { PRICING } from '@/shared/config/pricing';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';
import { TooltipTrigger } from 'react-aria-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Text from '@/components/ui/atoms/Text';
import Alert from '@/components/ui/atoms/Alert';
import useAvailableStoresQuery from '@queries/pso/useAvailableStoresQuery.hook';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import { USER_ROLES } from '@/shared/config/constants';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import styled, { css, keyframes } from 'styled-components';

interface StoresProps {
    stores: string[];
    setStores: (stores: string[]) => void;
    submitStores: () => void;
    editMode: boolean;
    submitIsLoading: boolean;
    setSubmitIsLoading: (loading: boolean) => void;
}

const Stores = ({
    stores,
    setStores,
    submitStores,
    editMode,
    submitIsLoading,
    setSubmitIsLoading,
}: StoresProps) => {
    const { showId }: { showId: string } = useParams();
    const { data: user } = useUserQuery();
    const { data: show } = useShowQuery(showId);
    const availableStores = useAvailableStoresQuery();

    const filteredAvailableStores = (
        rank: number,
        stores: string[],
        availableStores: { alpha2Code: string; name: string }[],
    ) => {
        const otherValues = stores.filter((_, index) => index !== rank - 1);
        return availableStores
            .filter((availableStore) => !otherValues.includes(availableStore.alpha2Code))
            .map((availableStore) => ({
                id: availableStore.alpha2Code,
                name: availableStore.name,
            }))
            .sort((a, _) => {
                if (['US', 'FR'].includes(a.id)) {
                    return -1;
                }
                return 1;
            });
    };

    const referencePricing =
        show?.userRole === USER_ROLES.OWNER ? user?.pricing : show?.ownerPricing;

    return (
        <Content>
            <TitleWrapper>
                <TitleInnerWrapper>
                    <Title>
                        <FormattedMessage defaultMessage="Choisissez dans quel pays votre émission doit être analysée" />
                    </Title>
                    {!editMode && (
                        <TooltipTrigger delay={0} closeDelay={0}>
                            <TooltipTriggerWrapper>
                                <HelpIcon icon={icon({ name: 'info-circle', style: 'solid' })} />
                            </TooltipTriggerWrapper>
                            <Tooltip placement="top">
                                <FormattedMessage defaultMessage="Vos données de recherches seront issues des pays sélectionnés." />
                            </Tooltip>
                        </TooltipTrigger>
                    )}
                </TitleInnerWrapper>
                {!editMode && (
                    <Subtitle>
                        <FormattedMessage defaultMessage="Rassurez-vous, vous pourrez toujours modifier votre sélection plus tard." />
                    </Subtitle>
                )}
            </TitleWrapper>
            {availableStores.data ? (
                <StoresList>
                    <Store
                        rank={1}
                        enabled={true}
                        stores={stores}
                        onSelectStore={(newStore) => setStores([newStore, stores[1], stores[2]])}
                        availableStores={filteredAvailableStores(1, stores, availableStores.data)}
                    />
                    <Store
                        rank={2}
                        enabled={[PRICING.BOOST, PRICING.SUPERSONIC, PRICING.ENTERPRISE].includes(
                            referencePricing || '',
                        )}
                        stores={stores}
                        onSelectStore={(newStore) => setStores([stores[0], newStore, stores[2]])}
                        availableStores={filteredAvailableStores(2, stores, availableStores.data)}
                    />
                    <Store
                        rank={3}
                        enabled={[PRICING.SUPERSONIC, PRICING.ENTERPRISE].includes(
                            referencePricing || '',
                        )}
                        stores={stores}
                        onSelectStore={(newStore) => setStores([stores[0], stores[1], newStore])}
                        availableStores={filteredAvailableStores(3, stores, availableStores.data)}
                    />
                </StoresList>
            ) : (
                <StoresList>
                    <StoreSkeleton />
                    <StoreSkeleton />
                    <StoreSkeleton />
                </StoresList>
            )}
            {editMode && (
                <Alert
                    variant="warning"
                    icon={
                        <WarningIcon
                            icon={icon({ name: 'triangle-exclamation', style: 'solid' })}
                        />
                    }
                >
                    <Text fontWeight="--fw-semibold">
                        <FormattedMessage defaultMessage="L'historique des pays préalablement configurés sera réinitialisé. La récupération des nouvelles données peut prendre plusieurs jours." />
                    </Text>
                </Alert>
            )}
            <ActionWrapper>
                <Button
                    isDisabled={stores.filter((s) => s !== null && s !== undefined).length === 0}
                    isLoading={submitIsLoading}
                    onPress={() => {
                        setSubmitIsLoading(true);
                        submitStores();
                    }}
                >
                    <FormattedMessage defaultMessage="Confirmer" />
                </Button>
            </ActionWrapper>
        </Content>
    );
};

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
`;
const TitleInnerWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
`;
const Title = styled(Text)`
    font-size: var(--fs-body-l);
    font-weight: var(--fw-bold);
`;
const Subtitle = styled(Text)`
    color: var(--neutral500);
`;
const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    gap: 1rem;
`;
const StoresList = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;
const ActionWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;
const WarningIcon = styled(FontAwesomeIcon)`
    color: var(--warning500);
    font-size: 1.5rem;
`;
const shimmer = keyframes`
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
`;
const shimmering = css`
    background: var(--neutral50);
    background-image: linear-gradient(
        to right,
        var(--neutral50) 0%,
        #edeef1 20%,
        var(--neutral50) 40%,
        var(--neutral50) 100%
    );
    background-repeat: no-repeat;
    background-size: 800px 104px;
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${shimmer};
    animation-timing-function: linear;
`;
const StoreSkeleton = styled.li`
    width: 100%;
    height: 4rem;
    background: var(--neutral50);
    border-radius: var(--r-xs);
    ${shimmering};
`;
const HelpIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
`;

export default Stores;
