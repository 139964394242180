import Stack from '@ui/layout/Stack';
import { USER_OPTIONS } from '@/shared/config/userOptions';
import EpisodeEditFormContentNameField from '@/components/unorganized/EpisodeEditForm/EpisodeEditFormContent/EpisodeEditFormContentNameField';
import EpisodeEditFormContentSlugField from '@/components/unorganized/EpisodeEditForm/EpisodeEditFormContent/EpisodeEditFormContentSlugField';
import EpisodeEditFormContentCustomUrlField from '@/components/unorganized/EpisodeEditForm/EpisodeEditFormContent/EpisodeEditFormContentCustomUrlField';
import EpisodeEditFormContentDescriptionField from '@/components/unorganized/EpisodeEditForm/EpisodeEditFormContent/EpisodeEditFormContentDescriptionField';
import EpisodeEditFormContentTagsField from '@/components/unorganized/EpisodeEditForm/EpisodeEditFormContent/EpisodeEditFormContentTagsField';
import EpisodeEditFormContentImageField from '@/components/unorganized/EpisodeEditForm/EpisodeEditFormContent/EpisodeEditFormContentImageField';
import { useEpisodeBeingEdited } from '@/context/EpisodeFormContext';
import NextStepButton from '@/components/unorganized/EpisodeEditForm/NextStepButton';

const ExperimentalEpisodeEditFormContent = () => {
    const episode = useEpisodeBeingEdited();

    return (
        <Stack $gap="2rem">
            <EpisodeEditFormContentNameField />
            <EpisodeEditFormContentSlugField prefix={episode?.show?.siteUrl || ''} />
            <EpisodeEditFormContentCustomUrlField
                isAllowed={episode?.show?.userOptions.includes(USER_OPTIONS.PODCASTS_CUSTOM_URL)}
            />
            <EpisodeEditFormContentDescriptionField />
            <EpisodeEditFormContentTagsField />
            <EpisodeEditFormContentImageField />
            <NextStepButton />
        </Stack>
    );
};

export default ExperimentalEpisodeEditFormContent;
