import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteChannelGuestInvitation } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import guestKeys from './guestKeys';

export interface DeleteChannelGuestInvitationMutationFnProps {
    channelId: number;
    email: string;
}

const mutationFn = async ({ channelId, email }: DeleteChannelGuestInvitationMutationFnProps) => {
    const { data } = await deleteChannelGuestInvitation({ channelId, email });

    return camelcaseKeys(data as any, { deep: true });
};

const deleteChannelGuestInvitationMutation = () => {
    const queryclient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: () => {
            queryclient.invalidateQueries({
                queryKey: guestKeys.invitationList(),
            });
        },
    });
};

export default deleteChannelGuestInvitationMutation;
