import * as yup from 'yup';
import { EPISODE_MAX_TAGS, EPISODE_PRIVACY, EPISODE_TYPE } from '@/shared/config/constants';
import { getPlainText } from '@/helpers';
import { FORM_VALIDATION_MESSAGE } from '@/shared/services/validationMessageService';

const slugRegex = /^[a-zA-Z0-9-À-ÿ]+$/;
const descriptionMaxCharacters = 3900;

const getEpisodeEditFormSchema = (intl) => {
    return yup.object({
        content: yup.object({
            name: yup
                .string()
                .max(140, intl.formatMessage(FORM_VALIDATION_MESSAGE.maxLength, { max: 140 }))
                .required(intl.formatMessage(FORM_VALIDATION_MESSAGE.required)),
            slug: yup
                .string()
                .matches(slugRegex, intl.formatMessage(FORM_VALIDATION_MESSAGE.noSpecialCharacters))
                .required(intl.formatMessage(FORM_VALIDATION_MESSAGE.required)),
            useCustomUrl: yup.boolean(),
            customUrl: yup
                .string()
                .when('useCustomUrl', {
                    is: true,
                    then: (schema) => schema.url(intl.formatMessage(FORM_VALIDATION_MESSAGE.isUrl)),
                    otherwise: (schema) => schema.notRequired(),
                })
                .nullable(),
            htmlDescription: yup
                .string()
                .test({
                    name: 'max-characters',
                    message: intl.formatMessage(FORM_VALIDATION_MESSAGE.maxLength, {
                        max: descriptionMaxCharacters,
                    }),
                    skipAbsent: true,
                    test: (value) => getPlainText(value).length <= descriptionMaxCharacters,
                })
                .notRequired(),
            tags: yup.array().of(yup.string()).max(EPISODE_MAX_TAGS),
            imageUrl: yup.mixed().nullable(),
        }),
        options: yup.object({
            seasonId: yup.number().nullable(),
            type: yup
                .string()
                .oneOf(
                    Object.values(EPISODE_TYPE),
                    intl.formatMessage(FORM_VALIDATION_MESSAGE.invalid),
                ),
            isExplicit: yup.boolean(),
            publishYoutube: yup.boolean().nullable(),
            youtubeVideoTitle: yup.string().when('publishYoutube', {
                is: true,
                then: (schema) =>
                    schema
                        .required(intl.formatMessage(FORM_VALIDATION_MESSAGE.required))
                        .max(
                            100,
                            intl.formatMessage(FORM_VALIDATION_MESSAGE.maxLength, { max: 100 }),
                        ),
                otherwise: (schema) => schema.nullable(),
            }),

            soundcloudPublish: yup.boolean().nullable(),
        }),
        privacy: yup.object({
            privacy: yup
                .string()
                .oneOf(
                    Object.values(EPISODE_PRIVACY),
                    intl.formatMessage(FORM_VALIDATION_MESSAGE.invalid),
                ),
            canDownload: yup.boolean(),
        }),
        chapters: yup.array().of(
            yup.object({
                title: yup
                    .string()
                    .max(140, intl.formatMessage(FORM_VALIDATION_MESSAGE.maxLength, { max: 140 }))
                    .required(intl.formatMessage(FORM_VALIDATION_MESSAGE.required)),
                startTime: yup.number(intl.formatMessage(FORM_VALIDATION_MESSAGE.isNumber)),
                imageUrl: yup.mixed().nullable(),
                url: yup.string().url(intl.formatMessage(FORM_VALIDATION_MESSAGE.isUrl)).nullable(),
            }),
        ),
    });
};

export default getEpisodeEditFormSchema;
