import { FormattedMessage } from 'react-intl';
import { connect } from '@app/decorators/connect';
import FetcherShowTemplate from '@app/fetchers/FetcherShowTemplate';
import ShortListFallback from '@app/fallbacks/ShortListFallback';
import Form from '@app/Form';
import ShowSharingTemplateForm from '@/forms/ShowSharingTemplateForm';
import ToggleField from '@ui/atoms/ToggleField';
import NewsletterContentSettings from '@app/molecules/NewsletterContentSettings';
import FetcherPageSettings from '@app/fetchers/FetcherPageSettings';
import FetcherCallToActionButtons from '@app/fetchers/FetcherCallToActionButtons';
import CTADragAndDropContent from './CTADragAndDropContent';
import styled from 'styled-components';
import Text from '@ui/atoms/Text';
import Button from '@ui/atoms/Button';
import Stack from '@ui/layout/Stack';
import { sendAmplitudeLogEvent } from '@/helpers';
import { LINKS_NAMES } from '@/components/CallToAction';
import { useParams } from 'react-router';

const SectionWrapper = styled.section`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`;
const SectionTitle = styled(Text)`
    width: 100%;
    padding-bottom: 0.25rem;
    border-bottom: solid 1px var(--neutral100);
    font-weight: var(--fw-bold);
`;
const SubmitButton = styled(Button)`
    margin-top: 1rem;
    align-self: center;
`;
const FormInnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;

    ${(p) => p.theme.mediaQueries.tabletAndUp} {
        row-gap: 2.5rem;
    }
`;
const ToggleFieldWrapper = styled.div`
    display: flex;
    column-gap: 0.5rem;
    flex-wrap: no-wrap;
    align-items: start;

    ${(p) => p.theme.mediaQueries.desktopAndUp} {
        align-items: center;
    }
`;
const NewsletterSettingsWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding);

    ${({ theme }) => theme.mediaQueries.mobile} {
        padding: var(--container-padding-mobile);
    }
`;
const NewsletterSettingsInnerWrapper = styled.div`
    max-width: 680px;
    margin-inline: auto;
`;

const enhance = connect(({ autoSharingStore, callToActionStore }) => {
    const template = autoSharingStore.newsletterShowTemplate;
    return {
        template,
        onSubmit: async (showId, formData) => {
            await callToActionStore.updateCallToActionButtonsPositions(showId, {
                location: LINKS_NAMES.NEWSLETTER,
            });
            await template.updateShowNewsletterTemplate(showId, formData);
        },
        isLoading: template && template.updateShowNewsletterTemplate.pending,
        dragAndDropHasChanged: callToActionStore.currentDragAndDropData.hasChanged,
    };
});

const NewsletterSettings = ({ template, onSubmit, isLoading, dragAndDropHasChanged }) => {
    const { showId } = useParams();
    return (
        <NewsletterSettingsWrapper>
            <NewsletterSettingsInnerWrapper>
                <FetcherCallToActionButtons showId={showId} ctaLocation={LINKS_NAMES.NEWSLETTER}>
                    <FetcherShowTemplate
                        fallback={ShortListFallback}
                        provider="newsletter"
                        showId={showId}
                    >
                        <FetcherPageSettings showId={showId}>
                            {(isFetching) =>
                                !isFetching &&
                                template && (
                                    <Form
                                        form={() => ShowSharingTemplateForm(template)}
                                        onSubmit={(formData) => onSubmit(showId, formData)}
                                        forceCanSubmit={dragAndDropHasChanged}
                                    >
                                        {({ fields, hasChanged }) => (
                                            <FormInnerWrapper>
                                                <SectionWrapper>
                                                    <SectionTitle variant="heading">
                                                        <FormattedMessage defaultMessage="Paramètres" />
                                                    </SectionTitle>
                                                    <Stack $gap="0.5rem">
                                                        <Text fontWeight="--fw-semibold">
                                                            <FormattedMessage defaultMessage="Abonnement à la newsletter" />
                                                        </Text>
                                                        <ToggleFieldWrapper as="label">
                                                            <ToggleField
                                                                field={fields.activated}
                                                                onChange={(isChecked) => {
                                                                    const eventMessage = isChecked
                                                                        ? 'Newsletter Activated'
                                                                        : 'Newsletter Deactivated';
                                                                    sendAmplitudeLogEvent(
                                                                        eventMessage,
                                                                    );
                                                                }}
                                                            />
                                                            <FormattedMessage defaultMessage="Permettre à vos auditeurs de s'abonner à votre newsletter." />
                                                        </ToggleFieldWrapper>
                                                    </Stack>
                                                    <NewsletterContentSettings />
                                                </SectionWrapper>
                                                <SectionWrapper>
                                                    <SectionTitle variant="heading">
                                                        <FormattedMessage defaultMessage="Apparence" />
                                                    </SectionTitle>
                                                    <CTADragAndDropContent
                                                        CTALocation={LINKS_NAMES.NEWSLETTER}
                                                    />
                                                </SectionWrapper>
                                                <SubmitButton
                                                    type="submit"
                                                    isDisabled={
                                                        !dragAndDropHasChanged &&
                                                        !hasChanged &&
                                                        !isLoading
                                                    }
                                                    isLoading={isLoading}
                                                >
                                                    <FormattedMessage defaultMessage="Enregistrer" />
                                                </SubmitButton>
                                            </FormInnerWrapper>
                                        )}
                                    </Form>
                                )
                            }
                        </FetcherPageSettings>
                    </FetcherShowTemplate>
                </FetcherCallToActionButtons>
            </NewsletterSettingsInnerWrapper>
        </NewsletterSettingsWrapper>
    );
};

export default enhance(NewsletterSettings);
