import Text from '@/components/ui/atoms/Text';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import aiLight from '@public/videos/ai-light.mp4';
import { TooltipTrigger } from 'react-aria-components';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';
import Alert from '@/components/ui/atoms/Alert';
import SparklesIcon from '@public/icons/sparkles-nebula.svg';

interface EpisodeAITranscriptionMissingStateProps {
    testId: string;
}

const EpisodeAITranscriptionMissingState = ({
    testId,
}: EpisodeAITranscriptionMissingStateProps) => {
    return (
        <Wrapper>
            <DesktopAlert
                variant="info"
                icon={<InfoIcon icon={icon({ name: 'circle-info', style: 'solid' })} />}
                data-testid={`episode-ai-${testId}-desktop-alert`}
            >
                <Text fontWeight="--fw-semibold">
                    <FormattedMessage defaultMessage="L'IA nécessite d'avoir transcrit l'épisode au préalable." />
                </Text>
            </DesktopAlert>
            <Container>
                <Header>
                    <TitleWrapper>
                        <Sparkles />
                        <Text fontWeight="--fw-semibold">Ausha Intelligence </Text>
                        <TooltipTrigger delay={0} closeDelay={0}>
                            <TooltipTriggerWrapper>
                                <TooltipIcon icon={icon({ name: 'circle-info', style: 'solid' })} />
                            </TooltipTriggerWrapper>
                            <Tooltip placement="top">
                                <FormattedMessage defaultMessage="Générez automatiquement le titre, la description, les tags, les chapitres et le contenu de la newsletter de votre podcast." />
                            </Tooltip>
                        </TooltipTrigger>
                    </TitleWrapper>
                    <Text color="--neutral500">
                        <FormattedMessage defaultMessage="Laissez la magie de l’IA opérer et vous suggérer les contenus de votre podcast." />
                    </Text>
                </Header>
                <Video muted autoPlay loop playsInline>
                    <source src={aiLight} type="video/mp4" />
                </Video>
                <MobileAlert
                    variant="info"
                    icon={<InfoIcon icon={icon({ name: 'circle-info', style: 'solid' })} />}
                    data-testid={`episode-ai-${testId}-mobile-alert`}
                >
                    <Text fontWeight="--fw-semibold">
                        <FormattedMessage defaultMessage="L'IA nécessite d'avoir transcrit l'épisode au préalable." />
                    </Text>
                </MobileAlert>
            </Container>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
`;
const Container = styled.div`
    background: var(--gradient-aurora);
    border-radius: var(--r-l);
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        row-gap: 2rem;
    }
`;
const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
`;
const Sparkles = styled(SparklesIcon)`
    width: 1rem;
    height: 1rem;
`;
const InfoIcon = styled(FontAwesomeIcon)`
    color: var(--info500);
    width: 0.75rem;
    height: 0.75rem;
`;
const Header = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
`;
const Video = styled.video`
    display: none;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: block;
        border-radius: var(--r-m);
        width: 100%;
        height: 10rem;
        object-fit: cover;
    }
`;
const DesktopAlert = styled(Alert)`
    display: none;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: flex;
    }
`;
const MobileAlert = styled(Alert)`
    background: none;
    color: var(--info500);
    padding: 0;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: none;
    }
`;
const TooltipIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
    width: 0.75rem;
    height: 0.75rem;
`;

export default EpisodeAITranscriptionMissingState;
