import { FormattedMessage } from 'react-intl';
import { useAmplitudeLogEvent } from '@/shared/hooks/useAmplitude';
import { USER_OPTIONS } from '@/shared/config/userOptions';
import Text from '@ui/atoms/Text';
import EmptyState from '@ui/molecules/EmptyState';
import noPeaktimeIllustration from '@public/images/empty-state/illustration/peaktime.mp4';
import ZebraIllustration from '@ui/atoms/ZebraIllustration';
import ShiningDiamond from '@ui/atoms/ShiningDiamond';
import IconRocket from '@ui/icons/IconRocket';
import styled from 'styled-components';
import { useParams } from 'react-router';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import RouterLink from '@/components/Link/RouterLink';
import PeakTime from '@/components/PeakTime';
import Stack from '@/components/ui/layout/Stack';
import Spinner from '@/components/ui/atoms/Spinner';

const PeakTimePage = () => {
    useAmplitudeLogEvent('Stats PeakTime Tab Viewed');
    const { showId } = useParams<{ showId: string }>();
    const showQuery = useShowQuery(showId);
    const hasAccess = showQuery.data?.userOptions?.includes(USER_OPTIONS.PEAKTIME);

    if (showQuery.data && !hasAccess) {
        return (
            <Stack $gap="1rem">
                <Text variant="title" fontWeight="--fw-bold">
                    <FormattedMessage defaultMessage="PeakTime" />
                </Text>
                <EmptyStateOuterWrapper>
                    <EmptyState
                        illustration={noPeaktimeIllustration}
                        illustrationType="video"
                        illustrationAlign="right"
                        illustrationBackground="--gradient-dusk"
                    >
                        <HeadIllustrationWrapper>
                            <ZebraIllustration>
                                <ShiningDiamond />
                            </ZebraIllustration>
                        </HeadIllustrationWrapper>
                        <LabelWrapper>
                            <Text variant="heading" fontWeight="--fw-bold">
                                <FormattedMessage defaultMessage="Passez à l'offre supérieure !" />
                            </Text>
                            <Text variant="body" fontWeight="--fw-normal" color="--neutral500">
                                <FormattedMessage defaultMessage="Pour débloquer le Peaktime et en savoir plus sur les meilleurs moments pour publier vos épisodes, abonnez-vous à l'offre Boost ou Supersonic 🚀" />
                            </Text>
                        </LabelWrapper>
                        <RouterLink
                            variant="button-primary"
                            startIcon={<IconRocket />}
                            to="/app/subscription/offers"
                        >
                            <FormattedMessage defaultMessage="Mettre à niveau mon offre" />
                        </RouterLink>
                    </EmptyState>
                </EmptyStateOuterWrapper>
            </Stack>
        );
    }

    if (showQuery.data && hasAccess) {
        return <PeakTime />;
    }

    return <Spinner />; // TODO: Skeleton ?
};

const EmptyStateOuterWrapper = styled.div`
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;
const HeadIllustrationWrapper = styled.div`
    width: 200px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    & > * {
        text-align: center;
    }
`;

export default PeakTimePage;
