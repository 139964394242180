import { useEffect } from 'react';
import { connect } from '../../legacy/connect';

const enhance = connect(({ routerStore }) => ({
    login() {
        window.opener.postMessage(
            { code: routerStore.query.code, provider: routerStore.params.provider },
            window.location.origin,
        );
        window.close();
    },
}));

function LoginWithProviderPage({ login }) {
    useEffect(() => {
        login();
    }, []);
    return null;
}

export default enhance(LoginWithProviderPage);
