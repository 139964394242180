import { useState } from 'react';
import { FormattedMessage, FormattedDate, FormattedNumber } from 'react-intl';
import type { Episode as EpisodeT } from '@queries/episode/useEpisodesQuery.hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Checkbox from '@/components/ui/Checkbox';
import { useParams } from 'react-router';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import { EPISODE_STATUS, EPISODE_PRIVACY, USER_ROLES } from '@/shared/config/constants';
import RouterLink from '@/components/Link/RouterLink';
import useSeasonQuery from '@/queries/season/useSeasonQuery.hook';
import ContextualMenu from './ContextualMenu';
import Status from './Status';
import { useEpisodeSelectContext } from '@/context/EpisodeSelectContext';
import Button from '@/components/Button';
import { useAudioPlayerContext } from '@/context/AudioPlayerContext';
import { mediaQueries } from '@/styles/theme';
import styled from 'styled-components';
import { sendAmplitudeLogEvent } from '@/shared/utils/amplitude';
import { secondsToDuration } from '@/shared/utils/duration';
import PsoScore from './PsoScore';
import useHasPsoFullUserOption from '@/shared/hooks/useHasPsoFullUserOption.hook';

interface EpisodeProps {
    episode: EpisodeT;
}

const isDesktop = window.matchMedia(
    mediaQueries.mediaQueries.desktopLargeAndUp.split('@media ')[1],
).matches;

const Episode = ({ episode }: EpisodeProps) => {
    const { showId } = useParams<{ showId: string }>();
    const { data: show } = useShowQuery(showId);
    const { data: season } = useSeasonQuery({ showId, seasonId: `${episode?.seasonId}` });
    const { allSelected, allSelectedOnPage, isSelected, toggleSelection } =
        useEpisodeSelectContext();
    const [hovered, setHovered] = useState(false);
    const episodeIsDraft = episode.state === EPISODE_STATUS.DRAFT;
    const episodeIsScheduled = episode.state === EPISODE_STATUS.SCHEDULED;
    const episodeIsPrivate = episode.privacy === EPISODE_PRIVACY.PRIVATE;
    const episodeIsOnline = episode.state === EPISODE_STATUS.ONLINE;
    const downloadCounterHidden = episodeIsDraft || episodeIsScheduled || episodeIsPrivate;
    const hasAccessToPso = useHasPsoFullUserOption({ showId });
    const userHasWriteAccess = show?.userRole !== USER_ROLES.VIEWER;

    const { currentlyPlaying, setCurrentlyPlaying, audioIsPlaying, play, pause } =
        useAudioPlayerContext();

    const MediaButton = (
        <PlayControlOverlay>
            {audioIsPlaying && currentlyPlaying?.url === episode.audioUrl ? (
                <PlayButton onPress={pause} variant="ghost">
                    <FontAwesomeIcon icon={icon({ name: 'pause', style: 'solid' })} />
                </PlayButton>
            ) : (
                <PlayButton
                    onPress={() => {
                        if (!episode?.audioUrl) return;

                        const media = {
                            name: episode.name,
                            url: episode.audioUrl,
                        };

                        setCurrentlyPlaying(media);
                        play(media);
                    }}
                    variant="ghost"
                >
                    <FontAwesomeIcon icon={icon({ name: 'play', style: 'solid' })} />
                </PlayButton>
            )}
        </PlayControlOverlay>
    );

    const CoverContent = episode.imageUrl ? (
        <Cover src={episode.imageUrl} alt={episode.name} />
    ) : show?.imageUrl ? (
        <Cover src={show.imageUrl} alt={show.name} />
    ) : (
        <Cover src="https://image.ausha.co/default_cover.png" alt="Default cover" />
    );

    const LeftTextWrapperContent = (
        <LeftTextWrapper>
            <EpisodeTitle>{episode.name}</EpisodeTitle>
            <EpisodeMetaData>
                {!episodeIsDraft && (
                    <MetaData>
                        <MetaDataIcon icon={icon({ name: 'calendar', style: 'regular' })} />
                        <FormattedDate value={episode?.publishedAt || ''} />
                    </MetaData>
                )}

                {isDesktop && !episodeIsDraft && episode?.duration && (
                    <DotIcon icon={icon({ name: 'circle', style: 'solid' })} />
                )}
                {episode?.duration ? (
                    <ResponsiveMetadata>
                        <MetaDataIcon icon={icon({ name: 'timer', style: 'solid' })} />
                        {secondsToDuration(episode.duration)}
                    </ResponsiveMetadata>
                ) : (
                    <ResponsiveMetadata>
                        <FormattedMessage defaultMessage="Aucun fichier audio" />
                    </ResponsiveMetadata>
                )}
                {!isDesktop && episode?.duration && !episodeIsDraft && !downloadCounterHidden && (
                    <DotIcon icon={icon({ name: 'circle', style: 'solid' })} />
                )}
                {!episodeIsDraft && !downloadCounterHidden && (
                    <MobileDownloadsMetaData>
                        <MetaDataIcon
                            icon={icon({
                                name: 'headphones',
                                style: 'solid',
                            })}
                        />
                        <FormattedNumber value={episode.downloadsCount} />
                    </MobileDownloadsMetaData>
                )}
                {isDesktop && season && season?.seasonNumber && (
                    <DotIcon icon={icon({ name: 'circle', style: 'solid' })} />
                )}
                {season && season?.seasonNumber && (
                    <ResponsiveMetadata>
                        <MetaDataIcon
                            icon={icon({
                                name: 'album-collection',
                                style: 'solid',
                            })}
                        />
                        <FormattedMessage
                            defaultMessage="Saison {season}"
                            values={{ season: season.seasonNumber }}
                        />
                    </ResponsiveMetadata>
                )}
            </EpisodeMetaData>
        </LeftTextWrapper>
    );

    return (
        <EpisodeWrapper onMouseOver={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
            <Checkbox
                isSelected={allSelected || allSelectedOnPage || isSelected(`${episode.id}`)}
                onChange={() => episode?.id && toggleSelection(`${episode.id}`)}
            />
            <EpisodeInnerWrapper>
                <Left>
                    <CoverWrapper>
                        {CoverContent}
                        {episode.audioUrl &&
                            (currentlyPlaying?.url === episode.audioUrl || hovered) &&
                            MediaButton}
                    </CoverWrapper>

                    {userHasWriteAccess ? (
                        <InvisibleRouterLink
                            to={
                                episodeIsOnline
                                    ? `/app/show/${showId}/episodes/preview/${episode.id}`
                                    : `/app/show/${showId}/episodes/edit/${episode.id}`
                            }
                            onClick={() => {
                                if (!episodeIsOnline) {
                                    sendAmplitudeLogEvent('Edit Form Episode', {
                                        is_existing_episode: episodeIsDraft,
                                    });
                                }
                            }}
                        >
                            {LeftTextWrapperContent}
                        </InvisibleRouterLink>
                    ) : (
                        LeftTextWrapperContent
                    )}
                </Left>
                <Right>
                    {!downloadCounterHidden && (
                        <DesktopMetadata>
                            <FontAwesomeIcon
                                icon={icon({
                                    name: 'headphones',
                                    style: 'solid',
                                })}
                            />
                            <FormattedNumber value={episode.downloadsCount} />
                        </DesktopMetadata>
                    )}
                    {hasAccessToPso && episode.psoScore?.data?.score && (
                        <ResponsiveRightWrapper>
                            <PsoScore score={episode.psoScore.data.score} />
                        </ResponsiveRightWrapper>
                    )}
                    <ResponsiveRightWrapper>
                        <Status status={episode.state} privacy={episode.privacy} />
                    </ResponsiveRightWrapper>
                    {userHasWriteAccess && !show?.archived && (
                        <EditRouterLink
                            to={`/app/show/${showId}/episodes/edit/${episode.id}`}
                            onClick={() => {
                                sendAmplitudeLogEvent('Edit Form Episode', {
                                    is_existing_episode: episodeIsDraft,
                                });
                            }}
                        >
                            <FontAwesomeIcon
                                icon={icon({ name: 'pen-line', style: 'solid' })}
                                fixedWidth
                            />
                        </EditRouterLink>
                    )}
                    <ContextualMenu episode={episode} />
                </Right>
            </EpisodeInnerWrapper>
        </EpisodeWrapper>
    );
};

const EpisodeWrapper = styled.li`
    transition-duration: 0.2s;
    height: 4.5rem;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    border-radius: var(--r-m);
    background-color: var(--white);
    padding-block: 0.75rem;
    padding-inline: 1rem;
    min-width: 0;

    &:not(:last-child) {
        border-bottom: 1px solid var(--neutral100);
    }

    &:hover {
        z-index: 2;
        box-shadow: var(--s-whatever);
    }

    &:not(:last-child):hover {
        border-bottom: 1px solid transparent;
    }
`;
const EpisodeInnerWrapper = styled.div`
    align-self: stretch;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    min-width: 0;
`;
const CoverWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    flex-shrink: 0;
`;
const Cover = styled.img`
    position: absolute;
    left: 0;
    top: 0;
    border-radius: var(--r-s);
`;
const Left = styled.div`
    display: flex;
    align-items: center;
    gap: 0.75rem;
    flex: 1;
    min-width: 0;
`;
const EpisodeTitle = styled.div`
    font-weight: var(--fw-semibold);
    font-size: var(--fs-body-l);
    flex: 1;

    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: 1450px) {
        max-width: 600px;
    }

    &:hover {
        text-decoration: underline;
    }
`;
const LeftTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    flex: 1;
    min-width: 0;

    &:hover ${EpisodeTitle} {
        text-decoration: underline;
    }
`;
const InvisibleRouterLink = styled(RouterLink)`
    color: inherit;
    text-decoration: none;
    font-weight: inherit;
    min-width: 0;
`;
const EpisodeMetaData = styled.div`
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-size: var(--fs-body-m);
`;
const MetaData = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
    color: var(--neutral500);
`;
const ResponsiveMetadata = styled(MetaData)`
    display: none;

    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        display: flex;
    }
`;

const DesktopMetadata = styled(MetaData)`
    display: none;

    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        display: flex;
        justify-content: center;
        width: 7.5rem;
    }
`;
const MobileDownloadsMetaData = styled(MetaData)`
    display: flex;
    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        display: none;
    }
`;
const Right = styled.div`
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    gap: 0.75rem;
    align-items: center;
`;
const EditRouterLink = styled(RouterLink)`
    display: none;

    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        display: initial;
        transition-duration: 0.2s;
        color: var(--neutral500);
        font-size: 1rem;
        padding: 0;

        &:hover {
            color: var(--neutral600);
        }
    }
`;
const PlayControlOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--r-s);
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
`;
const PlayButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
    color: var(--white);
`;
const MetaDataIcon = styled(FontAwesomeIcon)`
    font-size: 0.75rem;
`;
const DotIcon = styled(FontAwesomeIcon)`
    font-size: 0.25rem;
    color: var(--neutral200);
`;
const ResponsiveRightWrapper = styled.div`
    display: none;

    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 7.5rem;
    }
`;

export default Episode;
