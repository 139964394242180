import Desktop from '@ui/molecules/Desktop';
import MobileOrTablet from '@ui/molecules/MobileOrTablet';
import DeprecatedModal from '@ui/molecules/DeprecatedModal';
import Portal from '@ui/molecules/Portal';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import close from '@public/icons/close.svg';
import DeprecatedIcon from '@ui/atoms/DeprecatedIcon';
import styled from 'styled-components';

const FullScreenModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    overflow-y: auto;
    z-index: 1001;
`;

const FullScreenModalTitleWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--neutral50);
    height: 2.5rem;
    padding-inline: 1.5rem;
`;

const FullScreenModalContentWrapper = styled.div`
    background: white;
    overflow-y: scroll;
    height: calc(100vh - 2.5rem);
    width: 100%;
`;

const WrapperModal = ({ title, opened, onClose, children, ...props }) => (
    <>
        <Desktop>
            <DeprecatedModal isOpened={opened} onClose={onClose} title={title} {...props}>
                {children}
            </DeprecatedModal>
        </Desktop>
        <MobileOrTablet>
            {opened && (
                <Portal id="mobileModal" key="mobileModal">
                    <FullScreenModalWrapper>
                        <FullScreenModalTitleWrapper>
                            <DeprecatedText size="small" color="--neutral500">
                                {title}
                            </DeprecatedText>
                            <DeprecatedIcon as={close} onClick={onClose} color="--neutral500" />
                        </FullScreenModalTitleWrapper>
                        <FullScreenModalContentWrapper>{children}</FullScreenModalContentWrapper>
                    </FullScreenModalWrapper>
                </Portal>
            )}
        </MobileOrTablet>
    </>
);

export default WrapperModal;
