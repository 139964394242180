import Model from './Model';

class DeviceModel extends Model {
    static attributes = {
        name: 'device',
        count: 'count',
        percent: 'percentage',
    };
}

export default DeviceModel;
