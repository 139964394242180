import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchCampaignSourceBrowsersLeaderboard } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import statsKeys from './statsKeys';
import { z } from 'zod';

export const BrowserSchema = z.object({
    count: z.number(),
    osName: z.string(),
    percentage: z.number(),
});
export const BrowsersSchema = z.array(BrowserSchema);
export type Browser = z.infer<typeof BrowserSchema>;
type Key = QueryFunctionContext<ReturnType<typeof statsKeys.campaignSourceBrowsersLeaderboard>>;
type InputProps = {
    campaignId: string;
    start: string;
    end: string;
    options?: {
        enabled: boolean;
    };
};

const queryFn = async ({ queryKey: [{ campaignId, start, end }] }: Key) => {
    const { data } = await fetchCampaignSourceBrowsersLeaderboard(campaignId, { start, end });
    return BrowsersSchema.parse(camelcaseKeys(data, { deep: true }));
};

const useCampaignSourceBrowsersLeaderboardQuery = ({
    campaignId,
    start,
    end,
    options,
}: InputProps) => {
    return useQuery({
        queryKey: statsKeys.campaignSourceBrowsersLeaderboard(campaignId, start, end),
        queryFn,
        enabled: options?.enabled || true,
    });
};

export default useCampaignSourceBrowsersLeaderboardQuery;
