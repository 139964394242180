import { useMutation, useQueryClient } from '@tanstack/react-query';
import useUpdateEpisodeMutation from './useUpdateEpisodeMutation.hook';
import episodeKeys from '@/queries/episode/episodeKeys';
import showKeys from '../show/showKeys';

export interface MutationFnProps {
    episodeIds: string[];
    newSeasonId: string | null;
}

const useAssignMultipleEpisodesToSeasonMutation = () => {
    const queryClient = useQueryClient();
    const updateEpisode = useUpdateEpisodeMutation();

    return useMutation({
        mutationFn: ({ episodeIds, newSeasonId }: MutationFnProps) => {
            const promises: Promise<any>[] = [];

            episodeIds.forEach((episodeId) => {
                promises.push(
                    updateEpisode.mutateAsync({ episodeId, episode: { seasonId: newSeasonId } }),
                );
            });

            return Promise.allSettled(promises);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: episodeKeys.allLists(),
            });

            queryClient.invalidateQueries({
                queryKey: showKeys.all(),
            });
        },
    });
};

export default useAssignMultipleEpisodesToSeasonMutation;
