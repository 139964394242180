import { FormattedMessage } from 'react-intl';
import DeprecatedText from '../../ui/atoms/DeprecatedText';
import { connect } from '../decorators/connect';
import ShortListFallback from '../fallbacks/ShortListFallback';
import HeatmapGraph from '../../ui/molecules/HeatMapGraph';
import { useResponsive } from '../../../utils/hooks/useResponsive';
import LegendHeatmap from './LegendHeatmap';
import FetcherPeakTime from '../fetchers/stats/FetcherPeakTime';
import styled, { css } from 'styled-components';

const enhance = connect(({ peakTimeStatsStore }) => ({
    currentMoments: peakTimeStatsStore.currentMoments,
    daysRangePeakTime: peakTimeStatsStore.daysRangePeakTime,
}));

const PeakTimeCurrentMoment = ({
    currentMoments,
    daysRangePeakTime,
    messageLaunchTime,
    match,
    isNoData,
}) => {
    const { isMobileOrTablet } = useResponsive();
    return (
        <>
            <DeprecatedText size={isMobileOrTablet ? 'big' : 'veryBig'} weight="bold" mb={5}>
                <FormattedMessage defaultMessage="Votre audience selon les moments" />
            </DeprecatedText>
            <DeprecatedText size={isMobileOrTablet ? 'small' : 'normal'}>
                {daysRangePeakTime === 'always' ? (
                    <FormattedMessage defaultMessage="Vos écoutes selon les jours et heures depuis le début" />
                ) : (
                    <FormattedMessage
                        defaultMessage="Vos écoutes selon les jours et heures sur les {messageLaunchTime}"
                        values={{ messageLaunchTime }}
                    />
                )}
            </DeprecatedText>
            <PeakTimeCurrentMomentWrapper isFlex={isNoData}>
                <FetcherPeakTime
                    showId={match.params.showId}
                    days={daysRangePeakTime}
                    fallback={ShortListFallback}
                >
                    {(isLoading) =>
                        !isLoading && (
                            <>
                                <HeatmapGraph
                                    series={currentMoments.mapData}
                                    options={currentMoments.categories}
                                    maxDownloads={currentMoments.maxDownloads}
                                    w={isMobileOrTablet ? '110%' : '100%'}
                                    isNoData={isNoData}
                                />
                                <LegendHeatmap
                                    currentMoments={currentMoments}
                                    isNoData={isNoData}
                                />
                            </>
                        )
                    }
                </FetcherPeakTime>
            </PeakTimeCurrentMomentWrapper>
        </>
    );
};

const PeakTimeCurrentMomentWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding);
    margin-block: 2rem;
    min-height: 150px;
    gap: 1rem ${({ theme }) => theme.mediaQueries.mobile} {
        padding: var(--container-padding-mobile);
    }

    ${({ isFlex }) =>
        isFlex &&
        css`
            display: flex;
            align-items: center;
            justify-content: center;
        `}
`;

export default enhance(PeakTimeCurrentMoment);
