const clipKeys = {
    all: () => [{ entity: 'clip' }],
    allLists: () => [{ ...clipKeys.all()[0], scope: 'list' }],
    listByShowId: (rawShowId: string) => [
        {
            ...clipKeys.allLists()[0],
            showId: Number(rawShowId),
        },
    ],
    listByEpisodeId: (rawEpisodeId: string) => [
        {
            ...clipKeys.allLists()[0],
            episodeId: Number(rawEpisodeId),
        },
    ],
    allDetails: () => [{ ...clipKeys.all()[0], scope: 'detail' }],
    detailById: (rawClipId: string | number) => [
        { ...clipKeys.allDetails()[0], clipId: Number(rawClipId) },
    ],
    quotaByShowId: (rawShowId: string | number) => [
        { ...clipKeys.allDetails()[0], subscope: 'quota', showId: Number(rawShowId) },
    ],
    transcriptionSupportedLanguages: () => [
        { ...clipKeys.all()[0], subscope: 'transcriptionSupportedLanguages' },
    ],
    transcriptionByClipId: (rawClipId: string | number) => [
        { ...clipKeys.all()[0], subscope: 'transcription', clipId: Number(rawClipId) },
    ],
    transcriptionProgressByClipId: (rawClipId: string | number) => [
        { ...clipKeys.all()[0], subscope: 'transcriptionProgress', clipId: Number(rawClipId) },
    ],
};

export default clipKeys;
