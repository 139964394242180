import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import UiKitTooltip from '@ui/atoms/UiKitTooltip/UiKitTooltip';
import Button from '@/components/Button';
import Text from '@ui/atoms/Text';
import CircularProgress from '@ui/atoms/CircularProgress';
import styled from 'styled-components';

interface QuotaCardProps {
    titleIcon: React.ReactNode;
    title: React.ReactNode;
    currentValue: number;
    maxValue: number;
    valueUnit: React.ReactNode;
    actionLabel: React.ReactNode;
    onActionTriggered: () => void;
    infoTooltipContent: React.ReactNode;
    disableAction?: boolean;
    withBorder?: boolean;
}

const QuotaCard = ({
    titleIcon,
    title,
    currentValue,
    maxValue,
    valueUnit,
    actionLabel,
    onActionTriggered,
    infoTooltipContent,
    disableAction,
    withBorder,
}: QuotaCardProps) => {
    const percentage = maxValue > 0 ? Math.round((currentValue / maxValue) * 100) : 0;

    let color = '--success';
    if (percentage > 75) {
        color = '--alert';
    } else if (percentage > 50) {
        color = '--warning';
    }

    return (
        <QuotaCardWrapper withBorder={withBorder}>
            <MainContent>
                <MainContentInner>
                    <QuotaCardTitleWrapper>
                        <QuotaTitleIcon>{titleIcon}</QuotaTitleIcon>
                        {title}
                    </QuotaCardTitleWrapper>
                    <QuotaCardNumericValueWrapper>
                        <QuotaCardValue>{currentValue}</QuotaCardValue>
                        <QuotaCardLimit>/ {maxValue}</QuotaCardLimit>
                        <QuotaCardValueUnit>{valueUnit}</QuotaCardValueUnit>
                        <Tooltip variant="dark" content={infoTooltipContent} position="top">
                            <TooltipInfoIcon icon={icon({ name: 'circle-info', style: 'solid' })} />
                        </Tooltip>
                    </QuotaCardNumericValueWrapper>
                </MainContentInner>
                {!disableAction && (
                    <QuotaCardActionWrapper>
                        <QuotaActionButton onPress={() => onActionTriggered()}>
                            {actionLabel}
                        </QuotaActionButton>
                    </QuotaCardActionWrapper>
                )}
            </MainContent>
            <ProgressWrapper>
                <CircularProgress
                    size={140}
                    strokeWidth={16}
                    percentage={percentage}
                    backgroundColor={`${color}50`}
                    progressColor={`${color}`}
                >
                    <QuotaProgressNumber>{percentage}%</QuotaProgressNumber>
                </CircularProgress>
            </ProgressWrapper>
        </QuotaCardWrapper>
    );
};

const QuotaCardWrapper = styled.div<{ withBorder: boolean }>`
    display: flex;
    border-radius: var(--r-l);
    background-color: var(--white);
    padding: 1.5rem;
    & > * {
        min-width: 0;
    }
    ${({ withBorder }) =>
        withBorder &&
        `
        border: 1px solid var(--neutral100);
    `}
`;
const MainContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    flex-grow: 1;
`;
const MainContentInner = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;
const QuotaCardTitleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: var(--fw-semibold);
`;
const QuotaTitleIcon = styled.div`
    color: var(--primary);
    font-size: 1rem;
`;
const QuotaCardNumericValueWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;
const QuotaCardValue = styled(Text)`
    font-size: 1.75rem;
    font-weight: var(--fw-bold);
`;
const QuotaCardLimit = styled(Text)`
    color: var(--neutral500);
    font-size: var(--fs-heading-s);
`;
const QuotaCardValueUnit = styled(Text)`
    color: var(--neutral500);
    font-size: var(--fs-heading-s);
`;
const Tooltip = styled(UiKitTooltip)`
    z-index: 2;
`;
const TooltipInfoIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
`;
const QuotaCardActionWrapper = styled.div``;
const QuotaActionButton = styled(Button)`
    background-color: var(--primary50);
    color: var(--primary);
    box-shadow: none;

    &:hover {
        background-color: var(--primary100);
    }
`;
const ProgressWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 460px) {
        display: none;
    }
`;
const QuotaProgressNumber = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: var(--fw-bold);
    font-size: var(--fs-heading-s);
`;

export default QuotaCard;
