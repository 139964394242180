import { useMutation, useQueryClient } from '@tanstack/react-query';
import { importShow } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import rssKeys from './rssKeys';
import showKeys from '../show/showKeys';
import userKeys from '../user/userKeys';

export interface MutationFnProps {
    channelId: string;
    payload: Record<string, any>;
}

const mutationFn = async ({ channelId, payload }: MutationFnProps) => {
    const { data } = await importShow(channelId, payload);
    return camelcaseKeys(data, { deep: true });
};

const useImportRssShowMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: (_) => {
            queryClient.invalidateQueries({
                queryKey: rssKeys.all(),
            });
            queryClient.invalidateQueries({
                queryKey: userKeys.all(),
            });
            queryClient.invalidateQueries({
                queryKey: showKeys.all(),
            });
        },
    });
};

export default useImportRssShowMutation;
