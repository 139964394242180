import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchNewsletterQuotaPrice } from '@/api';
import quotaKeys from './quotaKeys';
import { z } from 'zod';

export const QuotaSchema = z.object({
    cost: z.string(),
    total: z.string(),
});
type InputProps = { quantity: number };
type Key = QueryFunctionContext<ReturnType<typeof quotaKeys.newsletterPricePreview>>;
export type Quota = z.infer<typeof QuotaSchema>;

const queryFn = async ({ queryKey: [{ quantity }] }: Key) => {
    const { data } = await fetchNewsletterQuotaPrice({ quantity });
    return QuotaSchema.parse(data);
};

const useNewsletterPricePreviewQuery = ({ quantity }: InputProps) => {
    return useQuery({
        queryKey: quotaKeys.newsletterPricePreview({ quantity }),
        queryFn,
    });
};

export default useNewsletterPricePreviewQuery;
