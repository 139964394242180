import { ListeningPlatform } from '@/shared/config/constants';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import RouterLink from '@/components/Link/RouterLink';
import styled from 'styled-components';
import { useParams } from 'react-router';
interface ConnectionRequestedProps {
    platformKey: ListeningPlatform;
    name: string;
    logo: string;
}

const ConnectionRequested = ({ platformKey, name, logo }: ConnectionRequestedProps) => {
    const { showId } = useParams<{ showId: string }>();

    return (
        <CardWrapper>
            <LogoWrapper>
                <Logo src={logo} />
            </LogoWrapper>
            <SettingsButton
                to={`/app/show/${showId}/settings/broadcast/platform/${platformKey}`}
                startIcon={<FontAwesomeIcon icon={icon({ name: 'gear', style: 'solid' })} />}
            />

            <ContentWrapper>
                <PlatformName>{name}</PlatformName>
                <PendingTextWrapper>
                    <PendingTitle>
                        <FontAwesomeIcon icon={icon({ name: 'check', style: 'solid' })} />
                        <FormattedMessage defaultMessage="Demande envoyée" />
                    </PendingTitle>

                    <PendingDescription>
                        <FormattedMessage defaultMessage="Votre lien d’écoute apparaîtra ici d’ici quelques heures" />
                    </PendingDescription>
                </PendingTextWrapper>
            </ContentWrapper>
        </CardWrapper>
    );
};

const CardWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--white);
    gap: 1rem;
    padding: 1rem;
    border-radius: var(--r-s);
`;
const LogoWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-full);
    border: 1px solid var(--neutral50);
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -2.5rem;
`;
const Logo = styled.img`
    width: 1.5rem;
    height: 1.5rem;
`;
const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
`;
const PlatformName = styled.span`
    font-weight: var(--fw-semibold);
`;
const SettingsButton = styled(RouterLink)`
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    transition-duration: 0.25s;
    color: var(--neutral);
    padding: 0.5rem;

    &:hover {
        color: var(--black);
        background-color: var(--neutral50);
    }
`;
const PendingTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: var(--fs-body-s);
`;
const PendingTitle = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: var(--fw-semibold);
`;
const PendingDescription = styled.p`
    color: var(--neutral);
    text-align: center;
`;

export default ConnectionRequested;
