import styled, { css, keyframes } from 'styled-components';

const PsoVisibilityScoreSkeleton = () => {
    return (
        <VisibilityScoreSkeletonWrapper>
            <TitleBar />

            <SemiCircleWrapper>
                <svg viewBox="0 0 100 60">
                    <Cap x="4" y="47" width="12" height="9" rx="3" />
                    <Cap x="84" y="47" width="12" height="9" rx="3" />
                    <Track d="M10,50 A20,20 0 1,1 90,50" fill="none" />
                </svg>
                <Score />
            </SemiCircleWrapper>

            <TrendWrapper>
                <TrendIcon />
                <Bars>
                    <TopBar />
                    <BottomBar />
                </Bars>
            </TrendWrapper>
        </VisibilityScoreSkeletonWrapper>
    );
};

const shimmer = keyframes`
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
`;
const shimmering = css`
    background: var(--neutral50);
    background-image: linear-gradient(
        to right,
        var(--neutral50) 0%,
        #edeef1 20%,
        var(--neutral50) 40%,
        var(--neutral50) 100%
    );
    background-repeat: no-repeat;
    background-size: 800px 104px;
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${shimmer};
    animation-timing-function: linear;
`;
const VisibilityScoreSkeletonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem;
`;
const TitleBar = styled.div`
    width: 10rem;
    height: 0.75rem;
    background-color: var(--neutral100);
    border-radius: var(--r-full);
    ${shimmering};
`;
const SemiCircleWrapper = styled.div`
    position: relative;
    height: 9.5rem;
    width: 100%;
    & > svg {
        height: 100%;
        margin: auto;
        display: block;
    }
`;
const Cap = styled.rect`
    fill: var(--neutral100);
`;
const Track = styled.path`
    stroke-linecap: round;
    stroke-width: 12;
    stroke: var(--neutral100);
`;
const Score = styled.div`
    position: absolute;
    bottom: 20%;
    left: 50%;
    transform: translateX(-50%);
    height: 0.5rem;
    width: 1.75rem;
    background-color: var(--neutral100);
    border-radius: var(--r-full);
    ${shimmering};
`;
const TrendWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.75rem;
`;
const TrendIcon = styled.div`
    height: 2.5rem;
    width: 2.5rem;
    background-color: var(--neutral100);
    border-radius: var(--r-s);
    ${shimmering};
`;
const Bars = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    & > * {
        height: 0.5rem;
        border-radius: var(--r-full);
    }
`;
const TopBar = styled.div`
    width: 16.75rem;
    background-color: var(--neutral100);
    ${shimmering};
`;
const BottomBar = styled.div`
    width: 5rem;
    background-color: var(--neutral50);
    ${shimmering};
`;

export default PsoVisibilityScoreSkeleton;
