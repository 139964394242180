import close from '@public/icons/close.svg';
import { FormattedMessage } from 'react-intl';
import check from '@public/icons/check.svg';
import rocket from '@public/icons/rocket.svg';
import DeprecatedIcon from '@ui/atoms/DeprecatedIcon';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import { connect } from '@/components/legacy/connect';
import Spinner from '@ui/atoms/Spinner';
import CampaignPublicationModal from './CampaignPublicationModal';
import ButtonAccess from './ButtonAccess';
import DeprecatedButton from '@ui/atoms/DeprecatedButton';
import Cluster from '@ui/layout/Cluster';

import styled from 'styled-components';
import { useState } from 'react';

const CampaignNewHeaderOuterWrapper = styled.header`
    flex-shrink: 0;
    height: 5rem;
    width: 100%;
    background: var(--white);
`;

const CampaignNewHeaderInnerWrapper = styled(Cluster)`
    width: 100%;
    margin: auto;
    padding: 1rem;
    max-width: 960px;

    ${(p) => p.theme.mediaQueries.tabletAndUp} {
        max-width: none;
    }
`;

const enhance = connect(({ routerStore, campaignStore }, { isLoading, submit }) => {
    const campaign = routerStore.resolve('campaignId') || {};
    return {
        publishDisabled:
            campaign.type !== 'hostread' && (!campaign.audioFile || campaign.audioFile.isUploading),
        isLoading: isLoading || campaignStore.updateCampaign.pending,
        submitFormAndClose: async () => {
            await submit();
            await routerStore.push('menu.monetize.manual');
        },
    };
});

function CampaignNewHeader({ publishDisabled, hasError, isLoading, submitFormAndClose }) {
    const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
    let textToShow;
    if (hasError) {
        textToShow = (
            <DeprecatedText color="--alert" size="small" underline mr={15}>
                <DeprecatedIcon as={close} mr={5} />
                <FormattedMessage defaultMessage="Le formulaire contient des erreurs" />
            </DeprecatedText>
        );
    } else if (isLoading) {
        textToShow = (
            <DeprecatedText color="--neutral500" size="small" mr={15} flex align="center">
                <Spinner mr={5} color="--neutral500" />
                <FormattedMessage defaultMessage="En cours de sauvegarde" />
            </DeprecatedText>
        );
    } else {
        textToShow = (
            <DeprecatedText color="--neutral500" size="small" underline mr={15} flex align="center">
                <DeprecatedIcon as={check} mr={5} />
                <FormattedMessage defaultMessage="Sauvegardé" />
            </DeprecatedText>
        );
    }

    return (
        <CampaignNewHeaderOuterWrapper $justify="center" $align="center">
            <CampaignNewHeaderInnerWrapper
                $justify="space-between"
                $align="center"
                $gap="1rem"
                $wrap="no-wrap"
            >
                <CampaignPublicationModal
                    isOpen={isPublishModalOpen}
                    onOpenChange={setIsPublishModalOpen}
                />
                <Cluster $align="center">
                    <DeprecatedText weight="semibold" size="big" ellipsis w="fit-content" auto>
                        <FormattedMessage defaultMessage="Créer une nouvelle campagne" />
                    </DeprecatedText>
                </Cluster>
                <Cluster $align="center" $gap="1rem">
                    {textToShow}
                    <ButtonAccess
                        tooltip={
                            <DeprecatedText>
                                <FormattedMessage defaultMessage="Un fichier audio est requis pour publier la campagne" />
                            </DeprecatedText>
                        }
                        disabled={publishDisabled}
                        onClick={() => setIsPublishModalOpen(true)}
                    >
                        <DeprecatedIcon as={rocket} />
                        <FormattedMessage defaultMessage="Publier la campagne" />
                    </ButtonAccess>
                    <DeprecatedButton accent="rounded" icon onClick={submitFormAndClose}>
                        <DeprecatedIcon
                            as={close}
                            color="black"
                            title={<FormattedMessage defaultMessage="Continuer plus tard" />}
                        />
                    </DeprecatedButton>
                </Cluster>
            </CampaignNewHeaderInnerWrapper>
        </CampaignNewHeaderOuterWrapper>
    );
}

export default enhance(CampaignNewHeader);
