import { useController } from 'react-hook-form';
import type { ControllerFieldState } from 'react-hook-form';
import { default as Checkbox } from '.';

export interface CheckboxControllerProps {
    control: any;
    name: string;
    onChange?: (value: boolean) => void;
    onBlur?: () => void;
    label: React.ReactNode;
    [key: string]: any;
}

export const CheckboxController = (props: CheckboxControllerProps) => {
    const { control, name, onChange, onBlur, label, ...rest } = props;
    const { field, fieldState } = useController({
        name,
        control,
    });

    return (
        <Checkbox
            onChange={(value) => {
                onChange && onChange(value);
                field.onChange(value);
            }}
            isSelected={field.value}
            name={field.name}
            inputState={(fieldState.error ? 'error' : fieldState) as ControllerFieldState}
            errorMessage={fieldState.error?.message}
            {...rest}
        >
            {label}
        </Checkbox>
    );
};
