import { computed } from 'mobx';
import Model from './Model';
import { date } from './resolvers';

class ShrinkedEpisodeModel extends Model {
    static attributes = {
        id: 'id',
        name: 'name',
        status: 'state',
        imageUrlPrivate: 'image_url',
        audioUrl: 'audio_url',
        publishedAt: date('published_at'),
        createdAt: date('created_at'),
    };

    @computed
    get imageUrl() {
        return this.imageUrlPrivate || (this.show && this.show.imageUrl);
    }
}

export default ShrinkedEpisodeModel;
