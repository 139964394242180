import Stack from '@ui/layout/Stack';
import EpisodeEditFormOptionsSeasonField from '@/components/unorganized/EpisodeEditForm/EpisodeEditFormOptions/EpisodeEditFormOptionsSeasonField';
import EpisodeEditFormOptionsTypeField from '@/components/unorganized/EpisodeEditForm/EpisodeEditFormOptions/EpisodeEditFormOptionsTypeField';
import EpisodeEditFormOptionsExplicitContentField from '@/components/unorganized/EpisodeEditForm/EpisodeEditFormOptions/EpisodeEditFormOptionsExplicitContentField';
import EpisodeEditFormOptionsPublishOnYoutubeField from '@/components/unorganized/EpisodeEditForm/EpisodeEditFormOptions/EpisodeEditFormOptionsPublishOnYoutubeField';
import EpisodeEditFormOptionsYoutubeTitleField from './EpisodeEditFormOptionsYoutubeTitleField';
import EpisodeEditFormOptionsYoutubeThumbnailField from './EpisodeEditFormOptionsYoutubeThumbnailField';
import { USER_OPTIONS } from '@/shared/config/userOptions';
import EpisodeEditFormOptionsPublishOnSoundcloudField from '@/components/unorganized/EpisodeEditForm/EpisodeEditFormOptions/EpisodeEditFormOptionsPublishOnSoundcloudField';
import { useEpisodeBeingEdited } from '@/context/EpisodeFormContext';
import NextStepButton from '@/components/unorganized/EpisodeEditForm/NextStepButton';
import { useFormContext } from 'react-hook-form';

const EpisodeEditFormOptions = () => {
    const episode = useEpisodeBeingEdited();
    const { watch } = useFormContext();

    return (
        <Stack $gap="2rem">
            <EpisodeEditFormOptionsSeasonField />
            <EpisodeEditFormOptionsTypeField />
            <EpisodeEditFormOptionsExplicitContentField />
            <EpisodeEditFormOptionsPublishOnYoutubeField
                isAllowed={episode.show.userOptions.includes(USER_OPTIONS.BROADCAST_YOUTUBE)}
            />
            {watch('options.publishYoutube') && (
                <>
                    <EpisodeEditFormOptionsYoutubeTitleField />
                    <EpisodeEditFormOptionsYoutubeThumbnailField />
                </>
            )}
            <EpisodeEditFormOptionsPublishOnSoundcloudField
                isAllowed={episode.show.userOptions.includes(USER_OPTIONS.SOUNDCLOUD)}
            />
            <NextStepButton />
        </Stack>
    );
};

export default EpisodeEditFormOptions;
