import { useQuery, QueryFunctionContext, UseQueryResult } from '@tanstack/react-query';
import { fetchPsoKeywordSuggestions } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import psoKeys from './psoKeys';

export interface KeywordSuggestionsQueryProps {
    showId: string;
}

export interface KeywordSuggestion {
    id: string;
    label: string;
    rank: number;
}

const queryFn = async ({
    queryKey: [{ showId }],
}: QueryFunctionContext<ReturnType<typeof psoKeys.keywordSuggestions>>) => {
    const { data } = await fetchPsoKeywordSuggestions(showId);

    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data.items, { deep: true });
};

const useKeywordSuggestionsQuery = ({
    showId,
}: KeywordSuggestionsQueryProps): UseQueryResult<KeywordSuggestion[]> => {
    return useQuery({
        queryKey: psoKeys.keywordSuggestions(showId),
        queryFn,
        enabled: !!showId,
    });
};

export default useKeywordSuggestionsQuery;
