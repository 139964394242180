import { useQuery } from '@tanstack/react-query';
import { fetchSmartlinkSettings } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import smartlinkSettingKeys from '@/queries/smartlinkSetting/smartlinkSettingKeys';

const queryFn = async ({ queryKey: [{ showId }] }) => {
    const { data } = await fetchSmartlinkSettings(showId);

    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const useSmartlinkSettingsQuery = (showId) => {
    return useQuery({
        queryKey: smartlinkSettingKeys.detailByShowId(showId),
        queryFn,
        enabled: !!showId,
    });
};

export default useSmartlinkSettingsQuery;
