import ListenersVariationGraph from '../organisms/ListenersVariationGraph';
import ListenersPodcastRanking from '../organisms/ListenersPodcastRanking';
import { useAmplitudeLogEvent } from '@hooks/useAmplitude';
import ListenersStatsSetOfOveralls from '../organisms/ListenersStatsSetOfOveralls';
import DownloadsComparison from '../organisms/DownloadsComparison';
import ListenersPodcastRankingTable from '../organisms/ListenersPodcastRankingTable';

const StatsListeners = () => {
    useAmplitudeLogEvent('Stats Downloads Tab Viewed');

    return (
        <>
            <ListenersStatsSetOfOveralls />
            <ListenersVariationGraph />
            <DownloadsComparison />
            <ListenersPodcastRanking />
            <ListenersPodcastRankingTable />
        </>
    );
};

export default StatsListeners;
