import { useIntl } from 'react-intl';
import * as yup from 'yup';

const useExternalLinksFormSchema = () => {
    const intl = useIntl();
    const errorMessage = intl.formatMessage({
        defaultMessage: 'Le format attendu de ce lien est : https://votrelien.x',
    });

    return yup.object({
        site: yup.string().url(errorMessage).nullable(),
        facebook: yup.string().url(errorMessage).nullable(),
        twitter: yup.string().url(errorMessage).nullable(),
        youtube: yup.string().url(errorMessage).nullable(),
        instagram: yup.string().url(errorMessage).nullable(),
        linkedin: yup.string().url(errorMessage).nullable(),
        tiktok: yup.string().url(errorMessage).nullable(),
        twitch: yup.string().url(errorMessage).nullable(),
        discord: yup.string().url(errorMessage).nullable(),
        monetization: yup.string().url(errorMessage).nullable(),
    });
};

export default useExternalLinksFormSchema;
