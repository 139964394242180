import { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { EditorContent } from '@tiptap/react';

const TranscriptionEditor = ({ editor, currentTime }) => {
    useEffect(() => {
        if (!editor) {
            return null;
        }

        const transaction = editor.state.tr;
        transaction.setMeta('currentTime', currentTime);
        editor.view.dispatch(transaction);

        const currentPlayingWord = document.querySelector('.transcription-current-playing-node');
        if (currentPlayingWord) {
            currentPlayingWord.scrollIntoView({ block: 'center' });
        }
    }, [currentTime, editor]);

    if (!editor) {
        return null;
    }

    return <Editor editor={editor} />;
};

const Editor = styled(EditorContent)`
    font-family: 'Roboto Mono', monospace;
    line-height: var(--lh-heading-m);

    .paragraph:not(:last-of-type) {
        margin-block-end: 2em;
    }
`;

TranscriptionEditor.propTypes = {
    currentTime: PropTypes.number,
    editor: PropTypes.object,
};

export default TranscriptionEditor;
