import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteChannelGuestPermission } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import guestKeys from './guestKeys';

export interface DeleteChannelGuestPermissionMutationFnProps {
    channelId: number;
    userId: number;
}

const mutationFn = async ({ channelId, userId }: DeleteChannelGuestPermissionMutationFnProps) => {
    const { data } = await deleteChannelGuestPermission({ channelId, userId });

    return camelcaseKeys(data as any, { deep: true });
};

const deleteChannelGuestPermissionMutation = () => {
    const queryclient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: () => {
            queryclient.invalidateQueries({
                queryKey: guestKeys.permissionList(),
            });
        },
    });
};

export default deleteChannelGuestPermissionMutation;
