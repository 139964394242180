import {
    useEpisodeEditAudioFileMutation,
    useEpisodeEditAudioFileState,
} from '@/context/EpisodeEditContext';
import styled from 'styled-components';
import { useEpisodeBeingEdited } from '@app/organisms/EpisodeEditForm/EpisodeEditFormContext';
import EpisodeEditResumeAudioFileUploading from '../EpisodeEditResumeAudioFileUploading';
import EpisodeEditResumeNoAudioFile from '../EpisodeEditResumeNoAudioFile';
import EpisodeEditResumeAudioFileUploaded from '../EpisodeEditResumeAudioFileUploaded';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    width: 100%;
`;

const EpisodeEditResumeAudioFile = () => {
    const episodeEditAudioFileMutation = useEpisodeEditAudioFileMutation();
    const [uploadingAudioFile] = useEpisodeEditAudioFileState();
    const episodeBeingEdited = useEpisodeBeingEdited();
    const uploadedAudioFile = episodeBeingEdited?.files?.[0];

    return (
        <Container>
            {episodeEditAudioFileMutation.isLoading || uploadingAudioFile ? (
                <EpisodeEditResumeAudioFileUploading file={uploadingAudioFile} />
            ) : uploadedAudioFile ? (
                <EpisodeEditResumeAudioFileUploaded
                    file={uploadedAudioFile}
                    audioUrl={episodeBeingEdited.audioUrl}
                />
            ) : (
                <EpisodeEditResumeNoAudioFile />
            )}
        </Container>
    );
};

export default EpisodeEditResumeAudioFile;
