import { Redirect, Route, Switch } from 'react-router-dom';
import { StepperStateContext } from '@/features/onboarding/contexts/StepperStateContext';
import useHasAccessToAushaForAll from '@/shared/hooks/useHasAccessToAushaForAll.hook';
import Layout from '@/features/onboarding/components/Onboarding/Layout';
import Choices from '@/features/onboarding/components/Onboarding/Choices';
import CreateNewShow from '@/features/onboarding/components/Onboarding/CreateNewShow';
import Success from './Success';
import ImportShow from '@/features/onboarding/components/Onboarding/ImportShow';
import ImportShowSelector from '@/features/onboarding/components/Onboarding/ImportShow/ImportShowSelector';
import Youtube from './Youtube';
import useHasAccessToYoutubeSync from '@/shared/hooks/useHasAccessToYoutubeSync.hook';

const Onboarding = () => {
    const hasAccessToAushaForAll = useHasAccessToAushaForAll();
    const hasAccessToYoutubeSync = useHasAccessToYoutubeSync();

    if (!hasAccessToAushaForAll) {
        return null;
    }

    return (
        <StepperStateContext>
            <Layout>
                <Switch>
                    <Route exact path="/app/onboarding">
                        <Choices />
                    </Route>
                    <Route exact path="/app/onboarding/create">
                        <CreateNewShow />
                    </Route>
                    <Route path="/app/onboarding/create/success">
                        <Success variant="create" />
                    </Route>
                    <Route exact path="/app/onboarding/import">
                        <ImportShow />
                    </Route>
                    <Route exact path="/app/onboarding/import/hosting">
                        <ImportShowSelector variant="hosting" />
                    </Route>
                    <Route path="/app/onboarding/import/hosting/success">
                        <Success variant="import" />
                    </Route>
                    <Route exact path="/app/onboarding/import/nonHosting">
                        <ImportShowSelector variant="nonHosting" />
                    </Route>
                    <Route path="/app/onboarding/import/nonHosting/success">
                        <Success variant="connect" />
                    </Route>
                    {hasAccessToYoutubeSync && (
                        <Route exact path="/app/onboarding/youtube">
                            <Youtube />
                        </Route>
                    )}
                    {hasAccessToYoutubeSync && (
                        <Route exact path="/app/onboarding/youtube/success">
                            <Success variant="youtube" />
                        </Route>
                    )}
                    <Route path="*">
                        <Redirect to="/app/onboarding" />
                    </Route>
                </Switch>
            </Layout>
        </StepperStateContext>
    );
};

export default Onboarding;
