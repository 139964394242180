import { Redirect, useParams } from 'react-router';
import Form from './Form';
import useClipQuery from '@/queries/clip/useClipQuery';
import useEpisodeQuery from '@/queries/episode/useEpisodeQuery.hook';
import Skeleton from './Skeleton';
import { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';

const Clip = () => {
    const { showId, clipId } = useParams<{ clipId: string; showId: string }>();
    const {
        data: clip,
        isError: isClipError,
        isFetchedAfterMount: isClipFetchedAfterMount,
    } = useClipQuery(clipId, { staleTime: 0 });
    const {
        data: episode,
        isError: isEpisodeError,
        isFetchedAfterMount: isEpisodeFetchedAfterMount,
    } = useEpisodeQuery({
        episodeId: String(clip?.podcastId),
        config: {
            enabled: !!clip?.podcastId,
            staleTime: 0,
        },
    });
    const isError = isClipError || isEpisodeError;
    const intercom = useIntercom();

    useEffect(() => {
        intercom.update({
            hideDefaultLauncher: true,
        });

        return () => {
            intercom.update({
                hideDefaultLauncher: false,
            });
        };
    }, []);

    if (isError) {
        return <Redirect to={`/app/shows/${showId}/communication/clip`} />;
    }

    if (clip && isClipFetchedAfterMount && episode && isEpisodeFetchedAfterMount) {
        return <Form clip={clip} episode={episode} />;
    }

    return <Skeleton />;
};

export default Clip;
