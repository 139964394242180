import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { fetchProviders } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import providerKeys from '@/queries/provider/providerKeys';
import { FacebookProviderSchema } from './useFacebookProviderQuery';
import { z } from 'zod';

export const ProvidersSchema = z.object({
    facebook: FacebookProviderSchema.nullable(),
    instagram: z.any().nullable(), // TODO: Add schema
    linkedin: z.any().nullable(), // TODO: Add schema
    twitter: z.any().nullable(), // TODO: Add schema
});

export type Providers = z.infer<typeof ProvidersSchema>;
type Key = QueryFunctionContext<ReturnType<typeof providerKeys.listByShowId>>;

const queryFn = async ({ queryKey: [{ showId }] }: Key) => {
    const { axios, ...data } = await fetchProviders(showId);
    return ProvidersSchema.parse(camelcaseKeys(data, { deep: true }));
};

const useProvidersQuery = (showId: string | number) => {
    return useQuery({
        queryKey: providerKeys.listByShowId(showId),
        queryFn,
        enabled: !!showId,
    });
};

export default useProvidersQuery;
