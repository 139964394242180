import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchEpisodeMessages } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import { z } from 'zod';
import episodeKeys from './episodeKeys';

/*
 * Messages include social media posts for Facebook, LinkedIn, Instagram, and Twitter,
 * as well as a `newsletter` object containing a `title` and `message` content.
 * Each social network has its own text field for the post, and the `newsletter` field is structured with a dedicated schema.
 */

const NewsletterSchema = z.object({
    title: z.string().nullable(),
    message: z.string().nullable(),
});
const MessagesSchema = z.object({
    facebook: z.string().nullable(),
    instagram: z.string().nullable(),
    twitter: z.string().nullable(),
    linkedin: z.string().nullable(),
    newsletter: NewsletterSchema,
});

type Key = QueryFunctionContext<ReturnType<typeof episodeKeys.messagesByEpisodeId>>;
export type EpisodeMesssages = z.infer<typeof MessagesSchema>;

const queryFn = async ({ queryKey: [{ episodeId }] }: Key) => {
    const { data } = await fetchEpisodeMessages(episodeId);
    return MessagesSchema.parse(camelcaseKeys(data, { deep: true }));
};

const useEpisodeMessagesQuery = (episodeId: string) => {
    return useQuery({
        queryKey: episodeKeys.messagesByEpisodeId(episodeId),
        queryFn,
        enabled: !!episodeId,
    });
};

export default useEpisodeMessagesQuery;
