import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updatePodcast } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import episodeKeys from './episodeKeys';

export interface MutationFnProps {
    episodeId: string;
    newShowId: string;
}

const mutationFn = async ({ episodeId, newShowId }: MutationFnProps) => {
    const { data } = await updatePodcast(episodeId, { show_id: newShowId });
    return camelcaseKeys(data, { deep: true });
};

const useMoveEpisodeMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: episodeKeys.allLists(),
            });
        },
    });
};

export default useMoveEpisodeMutation;
