import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchCampaignSourcesCsv } from '@/api';
import statsKeys from './statsKeys';
import { z } from 'zod';

export const ExportSchema = z.string();
export type Export = z.infer<typeof ExportSchema>;
type Key = QueryFunctionContext<ReturnType<typeof statsKeys.campaignSourceExport>>;
type InputProps = {
    campaignId: string;
    start: string;
    end: string;
    episodes: string[];
    timezone: string;
    options?: {
        enabled?: boolean;
    };
};

const queryFn = async ({ queryKey: [{ campaignId, start, end, episodes, timezone }] }: Key) => {
    const { data } = await fetchCampaignSourcesCsv(campaignId, {
        start,
        end,
        episodes,
        timezone,
    });
    return ExportSchema.parse(Object.values(data).join(''));
};

const useCampaignSourcesExportQuery = ({
    campaignId,
    start,
    end,
    episodes,
    timezone,
    options,
}: InputProps) => {
    return useQuery({
        queryKey: statsKeys.campaignSourceExport(campaignId, start, end, episodes, timezone),
        queryFn,
        enabled: options?.enabled || true,
    });
};

export default useCampaignSourcesExportQuery;
