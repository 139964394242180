import { useState } from 'react';
import { useIntl, FormattedMessage, FormattedNumber } from 'react-intl';
import { SearchField, Input } from 'react-aria-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import { formatDurationParts } from '@/helpers';
import { useParams } from 'react-router';
import { Button as AriaButton, Dialog, DialogTrigger, Popover } from 'react-aria-components';
import EpisodesFilter from './EpisodesFilter';
import { useEpisodeFiltersContext } from '@/context/EpisodeFiltersContext';
import RouterLink from '@/components/Link/RouterLink';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';
import { TooltipTrigger } from 'react-aria-components';
import { useDebounce } from '@/utils/hooks/useDebounceNew';
import { USER_ROLES } from '@/utils/constants';
import styled, { css } from 'styled-components';

const EpisodesPageHeader = () => {
    const intl = useIntl();
    const { showId } = useParams<{ showId: string }>();
    const { data: show } = useShowQuery(showId);
    const { setSearch, search, filters } = useEpisodeFiltersContext();
    const [searchValue, setSearchValue] = useState(search);
    const filtersCount = filters.seasons.length + filters.statuses.length;

    const userHasWriteAccess = show?.userRole !== USER_ROLES.VIEWER;

    const onChange = () => {
        setSearch(searchValue);
    };
    const durationParts = formatDurationParts(show?.duration);

    const debouncedOnChange = useDebounce(onChange);

    return (
        <EpisodesHeader>
            <Left>
                <SearchAndFilters>
                    <StyledSearchField
                        value={searchValue}
                        onChange={(value: string) => {
                            debouncedOnChange();
                            setSearchValue(value);
                        }}
                        aria-label="Rechercher un épisode"
                    >
                        <SearchIconWrapper>
                            <FontAwesomeIcon
                                icon={icon({ name: 'magnifying-glass', style: 'solid' })}
                            />
                        </SearchIconWrapper>
                        <StyledInput
                            placeholder={intl.formatMessage({
                                defaultMessage: 'Rechercher un épisode...',
                            })}
                            autoComplete="off"
                        />
                        {searchValue.length > 0 && (
                            <ResetSearchButton>
                                <FontAwesomeIcon icon={icon({ name: 'xmark', style: 'solid' })} />
                            </ResetSearchButton>
                        )}
                    </StyledSearchField>
                    <DialogTrigger>
                        <StyledAriaButton $hasActiveFilters={filtersCount > 0}>
                            <FontAwesomeIcon icon={icon({ name: 'bars-filter', style: 'solid' })} />
                            {filtersCount > 0 && (
                                <FormattedMessage
                                    defaultMessage="{filtersCount, plural, one {# appliqué} other {# appliqués}}"
                                    values={{
                                        filtersCount,
                                    }}
                                />
                            )}
                        </StyledAriaButton>
                        <Popover>
                            <StyledDialog>
                                <EpisodesFilter />
                            </StyledDialog>
                        </Popover>
                    </DialogTrigger>
                    <Sep />
                </SearchAndFilters>
                <Stats>
                    {show?.podcastsCount && (
                        <Stat>
                            <FontAwesomeIcon
                                icon={icon({ name: 'rectangle-history', style: 'solid' })}
                            />
                            <StatValue>
                                <FormattedMessage
                                    defaultMessage="{count} {count, plural, one {épisode} other {épisodes}}"
                                    values={{
                                        count: <FormattedNumber value={show.podcastsCount} />,
                                    }}
                                />
                            </StatValue>
                        </Stat>
                    )}
                    {(show?.downloadsCount || 0) > 0 && (
                        <Stat>
                            <FontAwesomeIcon icon={icon({ name: 'headphones', style: 'solid' })} />
                            <StatValue>
                                <FormattedNumber value={show?.downloadsCount ?? 0} />
                                <DownloadsKPILabel>
                                    <FormattedMessage defaultMessage="téléchargements" />
                                </DownloadsKPILabel>
                            </StatValue>
                        </Stat>
                    )}
                    {(show?.duration || 0) > 0 && (
                        <Stat>
                            <FontAwesomeIcon icon={icon({ name: 'timer', style: 'solid' })} />
                            <StatValue>
                                {durationParts.map((part, index) => (
                                    <span key={index}>
                                        <FormattedNumber
                                            value={part.value}
                                            minimumIntegerDigits={2}
                                        />
                                        {part.shortunit}
                                    </span>
                                ))}
                            </StatValue>
                        </Stat>
                    )}
                </Stats>
            </Left>
            {!show?.archived && (
                <NewEpisodeWrapper>
                    <TooltipTrigger delay={0} closeDelay={0}>
                        <TooltipTriggerWrapper>
                            <NewEpisodeLink
                                to={`/app/show/${showId}/episodes/edit/upload`}
                                variant="button-primary"
                                startIcon={
                                    <FontAwesomeIcon
                                        icon={icon({ name: 'plus', style: 'solid' })}
                                    />
                                }
                                isDisabled={!userHasWriteAccess}
                            >
                                <FormattedMessage defaultMessage="Ajouter un épisode" />
                            </NewEpisodeLink>
                        </TooltipTriggerWrapper>
                        <Tooltip placement="bottom" passthrough={userHasWriteAccess}>
                            <FormattedMessage defaultMessage="Vous devez être éditeur ou administrateur de l'émission pour ajouter un nouvel épisode." />
                        </Tooltip>
                    </TooltipTrigger>
                </NewEpisodeWrapper>
            )}
        </EpisodesHeader>
    );
};

const EpisodesHeader = styled.header`
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @media (min-width: 1215px) {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
`;
const Left = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex: 1;

    @media (min-width: 1215px) {
        flex-direction: row;
        align-items: center;
    }
`;
const SearchAndFilters = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-grow: 1;

    @media (min-width: 1215px) {
        flex-grow: 0;
    }
`;
const StyledSearchField = styled(SearchField)`
    transition-duration: 0.2s;
    flex-grow: 1;
    display: flex;
    align-items: center;
    background-color: var(--neutral100);
    border-radius: var(--r-s);
    padding-inline: 0.5rem;
    border: 1px solid transparent;

    &:focus-within {
        border: 1px solid var(--primary);
        outline: 2px solid var(--primary50);
        background-color: var(--white);
    }

    @media (min-width: 1215px) {
        flex-grow: 0;
    }
`;
const StyledInput = styled(Input)`
    border: 0;
    height: 100%;
    padding-block: 0.75rem;
    background-color: transparent;
    min-width: 12rem;
    width: 100%;
`;
const SearchIconWrapper = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    padding-inline: 0.75rem;
    color: var(--neutral500);
`;
const StyledAriaButton = styled(AriaButton)<{ $hasActiveFilters: boolean }>`
    transition-duration: 0.2s;
    color: var(--neutral500);
    padding: 0;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    &:hover,
    &[aria-expanded='true'] {
        color: var(--primary);
    }

    ${({ $hasActiveFilters }) =>
        $hasActiveFilters &&
        css`
            background-color: var(--primary50);
            border-radius: var(--r-s);
            padding-inline: 0.75rem;
            padding-block: 0.5rem;
            color: var(--primary);
            font-weight: var(--fw-semibold);
        `}
`;
const Sep = styled.div`
    width: 2px;
    height: 1.5rem;
    background-color: var(--neutral200);
    border-radius: var(--r-full);

    display: none;

    @media (min-width: 1215px) {
        display: block;
    }
`;
const Stats = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
`;
const Stat = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--neutral500);
    font-weight: var(--fw-semibold);
`;
const StatValue = styled.div`
    display: inline-flex;
    align-items: center;
    gap: 0.25rem;
`;
const StyledDialog = styled(Dialog)`
    background-color: var(--white);
    border-radius: var(--r-s);
    padding: 2rem;
    box-shadow: var(--s-m);
    max-width: calc(100vw - 2rem);
`;
const NewEpisodeLink = styled(RouterLink)``;
const NewEpisodeWrapper = styled.div`
    width: 100%;

    & ${NewEpisodeLink}, & button {
        width: 100%;
    }

    @media (min-width: 1215px) {
        width: auto;
    }
`;
const DownloadsKPILabel = styled.span`
    display: none;
    @media (min-width: 1215px) {
        display: inline;
    }
`;
const ResetSearchButton = styled(AriaButton)`
    transition-duration: 0.2s;
    color: var(--neutral500);
    border: 0;
    border-radius: var(--r-s);
    height: 1.75rem;
    width: 1.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem;
    cursor: pointer;

    &:hover {
        background-color: var(--neutral200);
        color: var(--black);
    }
`;

export default EpisodesPageHeader;
