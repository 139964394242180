import { FormattedMessage } from 'react-intl';
import Stack from '@ui/layout/Stack';
import useUserStore from '@/utils/hooks/useUserStore';
import { PRICING_COMPARISON_URL_EN, PRICING_COMPARISON_URL_FR } from '@/utils/pricing';
import Text from '@ui/atoms/Text';
import IconEmojiNerdFace from '@ui/icons/IconEmojiNerdFace';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';

const PricingCompare = () => {
    const { user } = useUserStore();

    return (
        <Stack $gap="0.25rem" $align="center">
            <Text variant="heading" fontWeight="--fw-bold" textAlign="center">
                <FormattedMessage
                    defaultMessage="Vous souhaitez en savoir plus sur nos offres ? {emoji}"
                    values={{
                        emoji: (
                            // @ts-ignore
                            <IconEmojiNerdFace style={{ verticalAlign: 'middle' }} size="1.5rem" />
                        ),
                    }}
                />
            </Text>
            <OfferDetailsLink
                href={
                    user?.languageCode === 'fr'
                        ? PRICING_COMPARISON_URL_FR
                        : PRICING_COMPARISON_URL_EN
                }
                target="_blank"
                rel="noopener"
            >
                <FormattedMessage
                    defaultMessage="{emoji} Consulter nos offres en détails"
                    values={{
                        emoji: (
                            <FontAwesomeIcon
                                icon={icon({ name: 'arrow-up-right-from-square', style: 'solid' })}
                            />
                        ),
                    }}
                />
            </OfferDetailsLink>
        </Stack>
    );
};

const OfferDetailsLink = styled.a`
    color: var(--primary);
    font-weight: var(--fw-semibold);
    text-align: center;
`;

export default PricingCompare;
