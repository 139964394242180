import styled, { css } from 'styled-components';
import DeprecatedText from '../DeprecatedText';

const Tab = styled(DeprecatedText).attrs((p) => ({
    color: (p.active && '--primary') || (p.disabled && '--neutral200') || '--black',
}))`
    position: relative;
    font-weight: var(--fw-semibold);

    ${({ row, active, responsive }) =>
        row
            ? css`
                  margin-left: ${responsive ? 0 : 25}px;
                  margin-right: ${responsive ? 0 : 25}px;
                  &:before {
                      bottom: -5px;
                      height: 2px;
                      width: 100%;
                  }
              `
            : css`
                  margin-bottom: ${responsive ? 0 : 20}px;
                  &:before {
                      left: -12px;
                      top: 2px;
                      width: ${active ? 2 : 0}px;
                      height: 16px;
                  }
              `}

    &:before {
        content: '';
        position: absolute;
        transform: scaleY(${(p) => (p.active ? 1 : 0)});
        background: var(--primary);
        transition: all 0.25s;
    }

    &:hover {
        color: var(--primary);
        text-decoration: none;
    }
`;

export default Tab;
