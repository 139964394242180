import PropTypes from 'prop-types';
import { FormattedDate, FormattedTime, FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import useSocialNetworkCalendarPostDetailsQuery from '@/queries/socialNetworkPost/useSocialNetworkCalendarPostDetailsQuery.hook';
import Text from '@/components/ui/atoms/Text';
import PostDetailsEmptyState from './PostDetailsEmptyState';
import Insight from './Insight';
import { SOCIAL_NETWORK_POST_STATUS, SOCIAL_NETWORK_POST_TYPE } from '@/utils/constants';
import useDeleteSocialNetworkPostMutation from '@/queries/socialNetworkPost/useDeleteSocialNetworkPostMutation.hook';
import { useEditedPostContext } from '@app/pages/SocialMediaPage/useEditedPostContext.hook';
import styled, { css } from 'styled-components';
import smartlinkIllustration from '@public/images/post_smartlink.png';
import {
    Separator as AriaSeparator,
    Button as AriaButton,
    DialogTrigger,
    Popover,
} from 'react-aria-components';
import MenuDropdown from '../ui/atoms/MenuDropdown';
import { SOCIAL_NETWORK_NAME } from '@/utils/constants';

const PostDetails = ({ id, platformLookup, onHide }) => {
    const postDetails = useSocialNetworkCalendarPostDetailsQuery(id);
    const deletePost = useDeleteSocialNetworkPostMutation();
    const editPostContext = useEditedPostContext();
    const isSmartlink = postDetails.data?.type === SOCIAL_NETWORK_POST_TYPE.SMARTLINK;
    const postDetailsImage = isSmartlink
        ? smartlinkIllustration
        : postDetails.data?.imageUrl || postDetails.data?.podcast?.imageUrl;

    const postIsPodcastCover = postDetails?.data?.type === SOCIAL_NETWORK_POST_TYPE.PODCAST_COVER;
    const postIsLink = postDetails?.data?.type === SOCIAL_NETWORK_POST_TYPE.LINK;
    const postIsSquareClip =
        postDetails?.data?.type === SOCIAL_NETWORK_POST_TYPE.CLIP &&
        postDetails?.data?.clip?.format === 'square';
    const illustrationShouldBeSquare = postIsPodcastCover || postIsLink || postIsSquareClip;

    const dropdownItems = [
        {
            id: 'edit',
            icon: <MenuItemIcon icon={icon({ name: 'pen-line', style: 'solid' })} />,
            name: <FormattedMessage defaultMessage="Modifier" />,
        },
        {
            id: 'duplicate',
            icon: <MenuItemIcon icon={icon({ name: 'clone', style: 'solid' })} />,
            name: <FormattedMessage defaultMessage="Dupliquer" />,
        },
        {
            id: 'delete',
            icon: <MenuItemIcon icon={icon({ name: 'trash', style: 'solid' })} />,
            name: <FormattedMessage defaultMessage="Supprimer" />,
            color: '--alert',
        },
    ];
    const publishedDropdownItems = [
        {
            id: 'duplicate',
            icon: <MenuItemIcon icon={icon({ name: 'clone', style: 'solid' })} />,
            name: <FormattedMessage defaultMessage="Dupliquer" />,
        },
        {
            id: 'link',
            icon: <MenuItemIcon icon={icon({ name: 'arrow-up-right', style: 'solid' })} />,
            name: (
                <MenuItemLink href={postDetails.data?.externalUrl} target="_blank" rel="noreferrer">
                    <FormattedMessage
                        defaultMessage="Voir sur {provider}"
                        values={{ provider: SOCIAL_NETWORK_NAME[postDetails.data?.provider] }}
                    />
                </MenuItemLink>
            ),
        },
    ];

    const menuItems =
        postDetails.data?.state === SOCIAL_NETWORK_POST_STATUS.DONE
            ? publishedDropdownItems
            : dropdownItems;

    const menuItemsHandler = (action) => {
        switch (action) {
            case 'edit':
                if (postDetails?.data) {
                    editPostContext.setEditedPost({ post: postDetails.data });
                    onHide();
                }
                break;
            case 'duplicate':
                editPostContext.setEditedPost({ post: postDetails.data, duplicate: true });
                onHide();
                break;
            case 'delete':
                deletePost.mutate({ postId: id });
                onHide();
                break;
            default:
                break;
        }
    };

    if (postDetails.isError) {
        onHide();
        return null;
    }

    const statusLookup = {
        [SOCIAL_NETWORK_POST_STATUS.DONE]: {
            icon: (
                <FontAwesomeIcon
                    icon={icon({ name: 'circle-check', style: 'solid' })}
                    color="inherit"
                    size="xs"
                />
            ),
            name: <FormattedMessage defaultMessage="Publié" />,
            color: '--success',
        },
        [SOCIAL_NETWORK_POST_STATUS.PUBLISH_AT]: {
            icon: (
                <FontAwesomeIcon
                    icon={icon({ name: 'clock', style: 'solid' })}
                    color="inherit"
                    size="xs"
                />
            ),
            name: <FormattedMessage defaultMessage="Planifié" />,
            color: '--info',
        },
        [SOCIAL_NETWORK_POST_STATUS.PUBLISH_NOW]: {
            icon: (
                <FontAwesomeIcon
                    icon={icon({ name: 'clock', style: 'solid' })}
                    color="inherit"
                    size="xs"
                />
            ),
            name: <FormattedMessage defaultMessage="Planifié" />,
            color: '--info',
        },
        [SOCIAL_NETWORK_POST_STATUS.DRAFT]: {
            icon: (
                <FontAwesomeIcon
                    icon={icon({ name: 'pen-line', style: 'solid' })}
                    color="inherit"
                    size="xs"
                />
            ),
            name: <FormattedMessage defaultMessage="Brouillon" />,
            color: '--neutral',
        },
        [SOCIAL_NETWORK_POST_STATUS.ERROR]: {
            icon: (
                <FontAwesomeIcon
                    icon={icon({ name: 'circle-exclamation', style: 'solid' })}
                    color="inherit"
                    size="xs"
                />
            ),
            name: <FormattedMessage defaultMessage="En erreur" />,
            color: '--alert',
        },
        [SOCIAL_NETWORK_POST_STATUS.PROCESSING]: {
            icon: (
                <FontAwesomeIcon
                    icon={icon({ name: 'gears', style: 'solid' })}
                    color="inherit"
                    size="xs"
                />
            ),
            name: <FormattedMessage defaultMessage="En cours" />,
            color: '--warning',
        },
    };

    const typeLookup = {
        [SOCIAL_NETWORK_POST_TYPE.CLIP]: {
            icon: <MediaTypeIcon icon={icon({ name: 'film', style: 'regular' })} color="inherit" />,
            text: <FormattedMessage defaultMessage="Clip vidéo" />,
        },
        [SOCIAL_NETWORK_POST_TYPE.IMAGE]: {
            icon: (
                <MediaTypeIcon icon={icon({ name: 'image', style: 'regular' })} color="inherit" />
            ),
            text: <FormattedMessage defaultMessage="Image" />,
        },
        [SOCIAL_NETWORK_POST_TYPE.PODCAST_COVER]: {
            icon: (
                <MediaTypeIcon icon={icon({ name: 'image', style: 'regular' })} color="inherit" />
            ),
            text: <FormattedMessage defaultMessage="Couverture de l'épisode" />,
        },
        [SOCIAL_NETWORK_POST_TYPE.LINK]: {
            icon: (
                <MediaTypeIcon icon={icon({ name: 'podcast', style: 'regular' })} color="inherit" />
            ),
            text: <FormattedMessage defaultMessage="Lien de l'épisode" />,
        },
        [SOCIAL_NETWORK_POST_TYPE.SMARTLINK]: {
            icon: <MediaTypeIcon icon={icon({ name: 'link', style: 'regular' })} color="inherit" />,
            text: <FormattedMessage defaultMessage="Smartlink" />,
        },
    };

    const isLoading = postDetails.isLoading;
    return (
        <PostDetailsWrapper>
            <PostDetailsHeader>
                {isLoading ? (
                    <HeaderSkeletonWrapper>
                        <HeaderSkeletonElementSmall />
                        <HeaderSkeletonElementBig />
                    </HeaderSkeletonWrapper>
                ) : (
                    <Status color={statusLookup[postDetails.data.state].color}>
                        {statusLookup[postDetails.data.state].icon}
                        {statusLookup[postDetails.data.state].name}
                    </Status>
                )}
                <HeaderActionWrapper>
                    <DialogTrigger>
                        <HeaderIconButton aria-label="Menu">
                            <HeaderIcon icon={icon({ name: 'ellipsis', style: 'solid' })} />
                        </HeaderIconButton>
                        <Popover placement="bottom end">
                            <MenuDropdown items={menuItems} onAction={menuItemsHandler} />
                        </Popover>
                    </DialogTrigger>
                    <HeaderIconButton onPress={() => onHide()}>
                        <HeaderIcon
                            icon={icon({ name: 'xmark', style: 'solid' })}
                            color="inherit"
                        />
                    </HeaderIconButton>
                </HeaderActionWrapper>
            </PostDetailsHeader>
            <HeaderSeparator />
            <PostDetailsInnerWrapper>
                {isLoading ? (
                    <PostDetailsEmptyState />
                ) : (
                    <>
                        <EpisodeInfoWrapper>
                            <PodcastCover src={postDetails.data.podcast.imageUrl} />
                            <PodcastTitle>{postDetails.data.podcast.name}</PodcastTitle>
                        </EpisodeInfoWrapper>
                        <PublishAndPlatform>
                            {postDetails.data.publishAt && (
                                <PublishAt>
                                    <FontAwesomeIcon
                                        icon={icon({ name: 'calendar', style: 'regular' })}
                                        color="inherit"
                                    />
                                    <PublishAtInner>
                                        <Text>
                                            <FormattedDate value={postDetails.data.publishAt} />
                                        </Text>
                                        <Text>
                                            <FormattedMessage defaultMessage="à" />
                                        </Text>
                                        <Text>
                                            <FormattedTime value={postDetails.data.publishAt} />
                                        </Text>
                                    </PublishAtInner>
                                </PublishAt>
                            )}
                            <Platform color={platformLookup[postDetails.data.provider].color}>
                                {platformLookup[postDetails.data.provider].icon}
                                {platformLookup[postDetails.data.provider].name}
                            </Platform>
                        </PublishAndPlatform>
                        <Insights>
                            <Insight
                                name={<FormattedMessage defaultMessage="impressions" />}
                                stat={postDetails.data?.insights?.impression}
                            />
                            <Insight
                                name={<FormattedMessage defaultMessage="vues" />}
                                stat={postDetails.data?.insights?.reach}
                            />
                            <Insight
                                name={<FormattedMessage defaultMessage="interactions" />}
                                stat={postDetails.data?.insights?.engagement}
                            />
                            <Insight
                                name={<FormattedMessage defaultMessage="ratio" />}
                                stat={postDetails.data?.insights?.engagementReach}
                            />
                        </Insights>
                        {postDetails.data.type && (
                            <>
                                <Separator />
                                <PostContent>
                                    <ContentIllustrationWrapper $isSmartlink={isSmartlink}>
                                        <Illustration
                                            imageSrc={postDetailsImage}
                                            $isSmartlink={isSmartlink}
                                            $isSquare={illustrationShouldBeSquare}
                                        />
                                    </ContentIllustrationWrapper>

                                    <MediaSection>
                                        <SectionTitle>
                                            <FormattedMessage defaultMessage="Média" />
                                        </SectionTitle>
                                        <MediaSectionIconWrapper>
                                            {typeLookup[postDetails.data.type].icon}
                                            {typeLookup[postDetails.data.type].text}
                                        </MediaSectionIconWrapper>
                                    </MediaSection>

                                    <PostSection>
                                        <SectionTitle>
                                            <FormattedMessage defaultMessage="Message" />
                                        </SectionTitle>
                                        <Content
                                            dangerouslySetInnerHTML={{
                                                __html: postDetails.data.messageTpl,
                                            }}
                                        />
                                    </PostSection>
                                </PostContent>
                            </>
                        )}
                    </>
                )}
            </PostDetailsInnerWrapper>
        </PostDetailsWrapper>
    );
};

const PostDetailsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 40rem;
`;
const PostDetailsInnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    width: 100%;
    align-self: stretch;
    min-height: 0;
    overflow-y: auto;
    padding-inline: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 1.5rem;
`;
const PostDetailsHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 1.5rem;
    padding-bottom: 1rem;
    padding-inline: 1.5rem;
`;
const Status = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
    color: ${({ color }) => color && `var(${color})`};
    font-weight: var(--fw-semibold);
`;
const HeaderSkeletonWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;
const HeaderSkeletonElementSmall = styled.div`
    width: 0.75rem;
    height: 0.75rem;
    background-color: var(--neutral50);
    border-radius: var(--r-full);
`;
const HeaderSkeletonElementBig = styled.div`
    width: 2.5rem;
    height: 0.5rem;
    background-color: var(--neutral100);
    border-radius: var(--r-full);
`;
const HeaderActionWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
`;
const HeaderIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--neutral500);
`;
const HeaderIconButton = styled(AriaButton)`
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
`;
const Separator = styled(AriaSeparator)`
    width: 100%;
    border: solid 0.0625rem var(--neutral100);
    align-self: center;
`;
const HeaderSeparator = styled(Separator)`
    width: calc(100% - 3rem);
`;
const EpisodeInfoWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.75rem;
`;
const PodcastCover = styled.img`
    width: 2.5rem;
    height: 2.5rem;
    border-radius: var(--r-s);
`;
const PodcastTitle = styled(Text)`
    font-weight: var(--fw-bold);
    font-size: var(--fs-body-m);
`;
const PublishAndPlatform = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
`;
const PublishAt = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--neutral500);
    font-size: var(--fs-body-m);
`;
const PublishAtInner = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
`;
const Platform = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: ${({ color }) => color && `var(${color})`};
    font-size: var(--fs-body-m);
`;
const Insights = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
`;
const PostContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;
const ContentIllustrationWrapper = styled.div`
    height: 7.5rem;
    border-radius: var(--r-m);

    ${({ $isSmartlink }) =>
        $isSmartlink &&
        css`
            background-color: var(--neutral50);
            padding-inline: 1rem;
            padding-top: 1rem;
        `}
`;
const Illustration = styled.div`
    height: 100%;
    background-image: ${({ imageSrc }) => `url(${imageSrc})`};
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: var(--r-m);
    aspect-ratio: auto;
    margin-inline: auto;

    ${({ $isSquare }) => $isSquare && `aspect-ratio: 1/1;`}

    ${({ $isSmartlink }) =>
        $isSmartlink &&
        css`
            border-radius: var(--r-m) var(--r-m) 0 0;
        `}
`;
const MediaSection = styled.div``;
const PostSection = styled.div``;
const SectionTitle = styled(Text)`
    font-size: var(--fs-body-m);
    font-weight: var(--fw-bold);
`;
const MediaSectionIconWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    font-size: var(--fs-body-m);
    color: var(--neutral700);
`;
const Content = styled.div`
    color: var(--neutral700);
    font-size: var(--fs-body-m);
    width: 100%;
`;
const MediaTypeIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const MenuItemIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: inherit;
`;
const MenuItemLink = styled.a`
    color: inherit;
    text-decoration: none;
`;

PostDetails.propTypes = {
    id: PropTypes.string,
    onHide: PropTypes.func,
    isLastRow: PropTypes.bool,
    isModal: PropTypes.bool,
    platformLookup: PropTypes.object,
};

export default PostDetails;
