import { useIntl } from 'react-intl';
import { z } from 'zod';
import { FORM_VALIDATION_MESSAGE } from '@/utils/validationMessage';

const useWebhooksFormSchema = () => {
    const intl = useIntl();
    return z.object({
        urls: z.array(
            z.object({
                id: z.number().or(z.string()).optional(),
                url: z.string().url(intl.formatMessage(FORM_VALIDATION_MESSAGE.isUrl)),
            }),
        ),
    });
};

export type Webhook = z.infer<ReturnType<typeof useWebhooksFormSchema>>;
export default useWebhooksFormSchema;
