import { useQuery } from '@tanstack/react-query';
import { fetchCalendarCommunicationEventScheduled } from '@/api';
import socialNetworkPostKeys from './socialNetworkPostKeys';
import camelcaseKeys from 'camelcase-keys';

const queryFn = async ({ queryKey: [{ showId }] }) => {
    const { data } = await fetchCalendarCommunicationEventScheduled(showId);

    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const useSocialNetworkCalendarPostScheduledQuery = ({ showId }) => {
    return useQuery({
        queryKey: socialNetworkPostKeys.listScheduled({ showId }),
        queryFn,
        enabled: !!showId,
    });
};

export default useSocialNetworkCalendarPostScheduledQuery;
