import { useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Modal from '@/components/ui/molecules/Modal';
import Stores from '../../../PsoConfigurationTunnel/StoreConfiguration/Stores';
import useSaveStoresMutation from '@queries/pso/useSaveStoresMutation.hook';
import { sendAmplitudeLogEvent } from '@/helpers';
import { FormattedMessage } from 'react-intl';
import { useModalToastQueue } from '@/utils/hooks/useModalToastQueue.hook';

interface PsoStoreEditModalProps {
    isOpen: boolean;
    onClose: () => void;
    existingStores: { name: string; alpha2Code: string }[];
}

const PsoStoreEditModal = ({ isOpen, onClose, existingStores }: PsoStoreEditModalProps) => {
    const { showId }: { showId: string } = useParams();
    const saveStores = useSaveStoresMutation();
    const [stores, setStores] = useState(existingStores.map((store) => store.alpha2Code) || []);
    const [submitIsLoading, setSubmitIsLoading] = useState(false);
    const toast = useModalToastQueue();

    const submitStores = () => {
        saveStores.mutate(
            {
                showId: parseInt(showId),
                stores: stores.filter((s) => s !== null && s !== undefined),
            },
            {
                onSuccess: () => {
                    sendAmplitudeLogEvent('PSO Countries Edited');
                    onClose();
                },
                onError: () => {
                    toast.alert(
                        <FormattedMessage defaultMessage="Une erreur s'est produite lors de l'enregistrement des pays, veuillez réessayer ultérieurement." />,
                    );
                },
            },
        );
    };

    return (
        // @ts-ignore
        <Modal size="small" isOpen={isOpen} onClose={onClose} isDismissable={true}>
            <Stores
                stores={stores}
                setStores={setStores}
                submitStores={submitStores}
                editMode={true}
                submitIsLoading={submitIsLoading}
                setSubmitIsLoading={(v) => setSubmitIsLoading(v)}
            />
        </Modal>
    );
};

PsoStoreEditModal.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    existingStores: PropTypes.array,
};

export default PsoStoreEditModal;
