import { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import StepContext from '../../molecules/Step/StepContext';
import StepIcon from '../StepIcon';

const getLabelColor = ({ active, completed }) => {
    if (completed) return '#2ED573';
    if (active) return '#5352ED';
    return '#414141';
};

const Container = styled.span`
    display: flex;
    align-items: center;
    flex-direction: column;
`;

const Label = styled.span`
    display: block;
    color: ${(props) => getLabelColor(props)};
    font-size: var(--fs-body);
    text-align: center;
    margin-top: 0.5rem;
`;

const StepLabel = (props) => {
    const { children, icon: iconProp, ...other } = props;

    // Retrieve context from the parent Step
    const { active, completed, icon: iconContext } = useContext(StepContext);
    const icon = iconProp || iconContext;

    return (
        <Container {...other}>
            {icon ? <StepIcon active={active} completed={completed} icon={icon} /> : null}
            <span>
                {children ? (
                    <Label active={active} completed={completed}>
                        {children}
                    </Label>
                ) : null}
            </span>
        </Container>
    );
};

StepLabel.propTypes = {
    /**
     * In most cases will simply be a string containing a title for the label.
     */
    children: PropTypes.node,
    /**
     * Override the default label of the step icon.
     */
    icon: PropTypes.node,
};

export default StepLabel;
