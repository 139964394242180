import RouterLink from '@/components/Link/RouterLink';
import Text from '@/components/ui/atoms/Text';
import Stack from '@/components/ui/layout/Stack';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import useSubscriptionQuery from '@/queries/subscription/useSubscriptionQuery.hook';
import { PRICING } from '@/shared/config/pricing';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import styled from 'styled-components';

interface SetupDistributionProps {
    isDisabled: boolean;
    isDone: boolean;
}

const SetupDistribution = ({ isDisabled, isDone }: SetupDistributionProps) => {
    const { showId } = useParams<{ showId: string }>();
    const { data: subscription } = useSubscriptionQuery();
    const { data: show } = useShowQuery(showId);
    const isFreemium = show?.ownerPricing === PRICING.FREEMIUM || subscription?.isFreemium;

    return (
        <StepWrapper>
            <Stack $gap="0.25rem" $align="center">
                <Text
                    color={isFreemium || isDisabled ? '--neutral500' : '--black'}
                    fontWeight="--fw-semibold"
                >
                    <FormattedMessage defaultMessage="Configurez votre diffusion" />
                </Text>
                <Text color="--neutral500">
                    <FormattedMessage defaultMessage="Diffusez votre voix partout ! Configurez la diffusion de votre émission dès maintenant." />
                </Text>
            </Stack>
            {isFreemium ? (
                <RouterLink
                    startIcon={
                        <ButtonIcon icon={icon({ name: 'rocket-launch', style: 'solid' })} />
                    }
                    size="small"
                    variant="button-primary"
                    to={`/app/user/subscription/offers`}
                >
                    <FormattedMessage defaultMessage="S'abonner" />
                </RouterLink>
            ) : isDone ? (
                <DoneWrapper>
                    <CheckIcon icon={icon({ name: 'check', style: 'solid' })} />
                    <Text fontWeight="--fw-semibold">
                        <FormattedMessage defaultMessage="Fait" />
                    </Text>
                </DoneWrapper>
            ) : (
                <RouterLink
                    isDisabled={isDisabled}
                    startIcon={<ButtonIcon icon={icon({ name: 'gear', style: 'solid' })} />}
                    size="small"
                    variant="button-primary"
                    to={`/app/show/${showId}/settings/broadcast`}
                >
                    <FormattedMessage defaultMessage="Commencer la configuration" />
                </RouterLink>
            )}
        </StepWrapper>
    );
};

const StepWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 1rem;
    text-align: center;
    color: var(--black);
`;
const ButtonIcon = styled(FontAwesomeIcon)`
    width: 0.5rem;
    height: 0.5rem;
`;
const DoneWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.25rem;
    color: var(--primary500);
`;
const CheckIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;

export default SetupDistribution;
