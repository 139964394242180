import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from '@app/decorators/connect';
import SearchBar from '@ui/molecules/SearchBar';
import VirtualList from 'react-tiny-virtual-list';
import ShortListFallback from '@app/fallbacks/ShortListFallback';
import Button from '@ui/atoms/Button';
import IconLoader from '@ui/icons/IconLoader';
import styled from 'styled-components';
import SelectableCampaignEpisode from './SelectableCampaignEpisode';

const enhance = connect(({ routerStore, podcastStore }) => ({
    showId: routerStore.params.showId,
    fetchShrinkedEpisodes: podcastStore.fetchShrinkedEpisodes,
}));

const AddPlaylistEpisodeList = ({ showId, fetchShrinkedEpisodes, selection, toggleSelection }) => {
    const [episodes, setEpisodes] = useState([]);
    const [currentPagination, setCurrentPagination] = useState({ perPage: 20, currentPage: 1 });
    const [fetchIsLoading, setFetchIsLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const searchEpisodes = async (query, currentPage, isLoadMore = false) => {
        setFetchIsLoading(true);
        const { episodes: newEpisodes, pagination } = await fetchShrinkedEpisodes(
            showId,
            query,
            currentPage,
            currentPagination.perPage,
        );

        setCurrentPagination(pagination);
        isLoadMore ? setEpisodes([...episodes, ...newEpisodes]) : setEpisodes([...newEpisodes]);
        setFetchIsLoading(false);
    };

    useEffect(() => {
        searchEpisodes('', currentPagination.currentPage);
    }, [showId]);

    const fetchMoreEpisodes = () => {
        if (currentPagination.currentPage === currentPagination.totalPages) return;
        searchEpisodes('', currentPagination.currentPage + 1, true);
    };

    return (
        <EpisodeListWrapper>
            <SearchWrapper>
                <FormattedMessage defaultMessage="Rechercher un épisode...">
                    {(placeholder) => (
                        <SearchBar
                            value={searchQuery}
                            onChange={(query) => {
                                setSearchQuery(query);
                                setEpisodes([]);
                                searchEpisodes(query, 1);
                            }}
                            placeholder={placeholder.join('')}
                        />
                    )}
                </FormattedMessage>
            </SearchWrapper>
            {fetchIsLoading ? (
                <ShortListFallback />
            ) : (
                <VirtualList
                    width="100%"
                    height={500}
                    itemCount={episodes.length}
                    itemSize={85}
                    renderItem={({ index, style }) => (
                        <div key={index} style={style}>
                            <SelectableCampaignEpisode
                                episode={episodes[index]}
                                isSelected={selection.array.includes(episodes[index].id)}
                                toggleSelection={() => toggleSelection(episodes[index].id)}
                            />
                        </div>
                    )}
                />
            )}

            {currentPagination.currentPage < currentPagination.totalPages && (
                <LoadMoreWrapper>
                    <Button
                        variant="ghost"
                        color="primary"
                        onPress={() => !fetchIsLoading && fetchMoreEpisodes()}
                    >
                        {fetchIsLoading ? (
                            <IconLoader />
                        ) : (
                            <FormattedMessage defaultMessage="Charger plus d'épisodes" />
                        )}
                    </Button>
                </LoadMoreWrapper>
            )}
        </EpisodeListWrapper>
    );
};

const EpisodeListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
`;

const SearchWrapper = styled.div`
    display: flex;
`;

const LoadMoreWrapper = styled.div`
    padding-block: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export default enhance(AddPlaylistEpisodeList);
