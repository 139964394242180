import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deletePodcastListeningLink } from '@/api';
import episodeListeningLinkKeys from '@/queries/episodeListeningLink/episodeListeningLinkKeys';
import camelcaseKeys from 'camelcase-keys';

export interface MutationFnProps {
    episodeId: string;
    key: string;
}

const mutationFn = async ({ episodeId, key }: MutationFnProps) => {
    const { data } = await deletePodcastListeningLink(episodeId, key);

    return camelcaseKeys(data, { deep: true });
};

const useDeleteEpisodeListeningLinkMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: (_, { episodeId }) => {
            queryClient.invalidateQueries({
                queryKey: episodeListeningLinkKeys.listByEpisodeId(episodeId),
            });
        },
    });
};

export default useDeleteEpisodeListeningLinkMutation;
