import { lazy, Suspense } from 'react';
import { PoseGroup } from 'react-pose';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useWindowDimensions } from '@hooks/useWindowDimensions';
import { AuthRoute, GuestRoute } from './app/routes/authorisationRoutes';
import DefaultRoute from './app/routes/DefaultRoute';
import FetcherUser from './app/fetchers/FetcherUser';
import Loader from './ui/atoms/Loader';
import LoginAsPage from './app/pages/LoginAsPage';
import LoginWithProviderPage from './app/pages/LoginWithProviderPage';
import LoginWithSharingProviderPage from './app/pages/LoginWithSharingProviderPage';
import DiscordPage from './app/pages/DiscordPage';
import Unsubscribe from './app/pages/Unsubscribe';
import Subscribe from './app/pages/Subscribe';
import OffersPage from './app/pages/OffersPage';
import AushaClubCallback from './app/pages/AushaClubCallback';
import EmailConfirmationPage from './app/pages/EmailConfirmationPage';
import StripeCheckoutSuccessPage from './app/pages/StripeCheckoutSuccessPage';
import Topbar from './app/organisms/Topbar';
import FullPageLayout from './app/layout/Fullpage.layout';
import SponsorshipPage from './app/pages/SponsorshipPage';
import { OverlayProvider } from 'react-aria';
import { Theme } from '@/styles/theme';
import PricingSuccessPage from '@app/pages/PricingSuccessPage';
import EpisodeEditPage from './app/pages/EpisodeEditPage';
import { StatsDateSelectorProvider } from '@/context/StatsDateSelectorContext';
import { USER_OPTIONS } from '@/utils/userOptions';
import { AudioPlayerContextProvider } from '@/context/AudioPlayerContext';
import ApplicationPage from './app/pages/ApplicationPage';

const LoginPage = lazy(() => import('./app/pages/LoginPage'));
const NewShowPage = lazy(() => import('./app/pages/NewShowPage'));
const SongLibraryPage = lazy(() => import('./app/pages/SongLibraryPage'));
const ChannelPage = lazy(() => import('./app/pages/ChannelPage'));
const ShowsPage = lazy(() => import('./app/pages/ShowsPage'));

import Responsive from '@/context/responsive.context';
import useAmplitudeLogLocationChangeEvent from '@/utils/hooks/useAmplitudeLogLocationChangeEvent.hook';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import ErrorPage from './app/pages/ErrorPage';
import MaintenancePage from './app/pages/MaintenancePage';

require('dayjs/locale/fr');

function App() {
    const location = useLocation();
    const isLoginAsMode = location.pathname === '/loginas';

    useAmplitudeLogLocationChangeEvent();
    const { media, width } = useWindowDimensions();

    const { data: user } = useUserQuery({ enabled: !isLoginAsMode });
    const userIsPmp = user?.canHostOnAusha === false;
    const hasAccessToChannelPage = user?.options.includes(USER_OPTIONS.CHANNEL_PAGE);

    return (
        <Responsive.Provider value={{ ...media, width }}>
            <OverlayProvider style={{ height: '100%' }}>
                <AudioPlayerContextProvider>
                    <StatsDateSelectorProvider>
                        <Theme />
                        <Switch>
                            <Route path="/error" exact component={ErrorPage} />
                            <Route path="/error/maintenance" component={MaintenancePage} />
                            <Route path="/loginas" component={LoginAsPage} />
                            <Route path="/ausha-club-callback">
                                {userIsPmp ? (
                                    <Redirect to="/app/show/all" />
                                ) : (
                                    <AushaClubCallback />
                                )}
                            </Route>
                            <Route
                                render={() => (
                                    <FetcherUser
                                        fallback={(isLoading) => (
                                            <PoseGroup animateOnMount>
                                                {isLoading && <Loader key={0} />}
                                            </PoseGroup>
                                        )}
                                    >
                                        <Suspense fallback={null}>
                                            <Switch>
                                                <AuthRoute
                                                    path="/checkout-success"
                                                    component={StripeCheckoutSuccessPage}
                                                />
                                                <AuthRoute
                                                    path="/app/subscription/offers"
                                                    component={OffersPage}
                                                />
                                                <AuthRoute
                                                    path="/email-confirmation"
                                                    component={EmailConfirmationPage}
                                                />
                                                <AuthRoute
                                                    path="/discord"
                                                    component={DiscordPage}
                                                />
                                                <AuthRoute path="/app/social/:provider">
                                                    {userIsPmp ? (
                                                        <Redirect to="/app/show/all" />
                                                    ) : (
                                                        <LoginWithSharingProviderPage />
                                                    )}
                                                </AuthRoute>
                                                <AuthRoute
                                                    path="/app/user"
                                                    component={ApplicationPage}
                                                />
                                                <AuthRoute path="/app/sponsorship">
                                                    {userIsPmp ? (
                                                        <Redirect to="/app/show/all" />
                                                    ) : (
                                                        <FullPageLayout header={<Topbar />}>
                                                            <SponsorshipPage />
                                                        </FullPageLayout>
                                                    )}
                                                </AuthRoute>
                                                <AuthRoute path="/app/show/new">
                                                    <FullPageLayout header={<Topbar />}>
                                                        <NewShowPage />
                                                    </FullPageLayout>
                                                </AuthRoute>
                                                <AuthRoute path="/app/show/all/:showId?">
                                                    <FullPageLayout header={<Topbar />}>
                                                        <ShowsPage />
                                                    </FullPageLayout>
                                                </AuthRoute>
                                                <AuthRoute path="/app/show/:showId/episodes/edit">
                                                    {userIsPmp ? (
                                                        <Redirect to="/app/show/all" />
                                                    ) : (
                                                        <EpisodeEditPage />
                                                    )}
                                                </AuthRoute>
                                                <AuthRoute
                                                    path="/app/show/:showId/:tabMenu?"
                                                    component={ApplicationPage}
                                                />
                                                <AuthRoute path="/app/song-library">
                                                    {userIsPmp ? (
                                                        <Redirect to="/app/show/all" />
                                                    ) : (
                                                        <FullPageLayout header={<Topbar />}>
                                                            <SongLibraryPage />
                                                        </FullPageLayout>
                                                    )}
                                                </AuthRoute>
                                                <AuthRoute path="/app/channel/settings">
                                                    {userIsPmp || !hasAccessToChannelPage ? (
                                                        <Redirect to="/app/show/all" />
                                                    ) : (
                                                        <FullPageLayout header={<Topbar />}>
                                                            <ChannelPage />
                                                        </FullPageLayout>
                                                    )}
                                                </AuthRoute>
                                                <AuthRoute
                                                    path="/app/pricing/success"
                                                    component={PricingSuccessPage}
                                                />
                                                <AuthRoute path="/app">
                                                    <Redirect to="/app/show/all" />
                                                </AuthRoute>
                                                <GuestRoute
                                                    path="/login/:provider(google|facebook)"
                                                    component={LoginWithProviderPage}
                                                />
                                                <Route
                                                    path="/(login|password)"
                                                    component={LoginPage}
                                                />
                                                <Route
                                                    path="/unsubscribe"
                                                    component={Unsubscribe}
                                                />
                                                <Route path="/subscribe" component={Subscribe} />
                                                <DefaultRoute to="/app" />
                                            </Switch>
                                        </Suspense>
                                    </FetcherUser>
                                )}
                            />
                        </Switch>
                    </StatsDateSelectorProvider>
                </AudioPlayerContextProvider>
            </OverlayProvider>
        </Responsive.Provider>
    );
}

export default App;
