import { FormattedMessage, FormattedDate, FormattedNumber } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Campaign as CampaignT } from '@/queries/campaign/useCampaignsQuery';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Checkbox from '@/components/ui/Checkbox';
import { useParams } from 'react-router';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import { USER_ROLES, CAMPAIGN_STATUS } from '@/shared/config/constants';
import RouterLink from '@/components/Link/RouterLink';
import styled from 'styled-components';
import Status from './Status';
import ContextualMenu from './ContextualMenu';
import { useSelectionContext } from '@/features/Campaigns/services/SelectionContext';

interface CampaignProps {
    campaign: CampaignT;
}

const Campaign = ({ campaign }: CampaignProps) => {
    const { showId } = useParams<{ showId: string }>();
    const { data: show } = useShowQuery(showId);
    const { allSelected, allSelectedOnPage, isSelected, toggleSelection } = useSelectionContext();
    const campaignIsDraft = campaign.state === CAMPAIGN_STATUS.DRAFT;
    const campaignIsScheduled = campaign.state === CAMPAIGN_STATUS.PLANIFIED;
    const campaignIsFinished = campaign.state === CAMPAIGN_STATUS.FINISHED;
    const downloadCounterHidden = campaignIsDraft || campaignIsScheduled;

    const userHasWriteAccess = show?.userRole !== USER_ROLES.VIEWER;

    const LeftTextWrapperContent = (
        <LeftTextWrapper>
            <CampaignTitle>{campaign.name}</CampaignTitle>
            <CampaignMetaData>
                <MetaData>
                    <MetaDataIcon icon={icon({ name: 'calendar', style: 'regular' })} />
                    {campaign.startsAt && campaign.endsAt && (
                        <FormattedMessage
                            defaultMessage="Du {from} au {to}"
                            values={{
                                from: <FormattedDate value={campaign.startsAt} />,
                                to: <FormattedDate value={campaign.endsAt} />,
                            }}
                        />
                    )}
                    {campaign.startsAt && !campaign.endsAt && (
                        <FormattedMessage
                            defaultMessage="Commence le {from}"
                            values={{
                                from: <FormattedDate value={campaign.startsAt} />,
                            }}
                        />
                    )}
                    {!campaign.startsAt && campaign.endsAt && (
                        <FormattedMessage
                            defaultMessage="Se termine le {to}"
                            values={{
                                to: <FormattedDate value={campaign.endsAt} />,
                            }}
                        />
                    )}
                </MetaData>
                {!campaignIsDraft && !downloadCounterHidden && (
                    <MobileDownloadsMetaData>
                        <DotIcon icon={icon({ name: 'circle', style: 'solid' })} />
                        <MobileDownloadsMetaData>
                            <MetaDataIcon
                                icon={icon({
                                    name: 'headphones',
                                    style: 'solid',
                                })}
                            />
                            <FormattedNumber value={campaign.downloadsCount} />
                        </MobileDownloadsMetaData>
                    </MobileDownloadsMetaData>
                )}
            </CampaignMetaData>
        </LeftTextWrapper>
    );

    return (
        <CampaignWrapper>
            <Checkbox
                isSelected={allSelected || allSelectedOnPage || isSelected(`${campaign.id}`)}
                onChange={() => campaign?.id && toggleSelection(`${campaign.id}`)}
            />
            <CampaignInnerWrapper>
                <Left>
                    {userHasWriteAccess && !show?.archived && !campaignIsFinished ? (
                        <InvisibleRouterLink
                            to={`/app/show/${showId}/monetize/manual/${campaign.id}`}
                        >
                            {LeftTextWrapperContent}
                        </InvisibleRouterLink>
                    ) : (
                        LeftTextWrapperContent
                    )}
                </Left>
                <Right>
                    {!downloadCounterHidden && (
                        <ResponsiveMetadata>
                            <FontAwesomeIcon
                                icon={icon({
                                    name: 'headphones',
                                    style: 'solid',
                                })}
                            />
                            <FormattedNumber value={campaign.downloadsCount} />
                        </ResponsiveMetadata>
                    )}
                    <StatusAndActionWrapper>
                        <StatusWrapper>
                            <Status status={campaign.state} />
                        </StatusWrapper>
                        <ActionsWrapper>
                            {userHasWriteAccess && !show?.archived && !campaignIsFinished ? (
                                <EditRouterLink
                                    to={`/app/show/${showId}/monetize/manual/${campaign.id}`}
                                >
                                    <FontAwesomeIcon
                                        icon={icon({ name: 'pen-line', style: 'solid' })}
                                        fixedWidth
                                    />
                                </EditRouterLink>
                            ) : (
                                <Decoy />
                            )}
                            <ContextualMenu campaign={campaign} />
                        </ActionsWrapper>
                    </StatusAndActionWrapper>
                </Right>
            </CampaignInnerWrapper>
        </CampaignWrapper>
    );
};

const CampaignWrapper = styled.li`
    transition-duration: 0.2s;
    height: 4.5rem;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    border-radius: var(--r-m);
    background-color: var(--white);
    padding-block: 0.75rem;
    padding-inline: 1rem;
    min-width: 0;

    &:not(:last-child) {
        border-bottom: 1px solid var(--neutral100);
    }

    &:hover {
        z-index: 2;
        box-shadow: var(--s-whatever);
    }

    &:not(:last-child):hover {
        border-bottom: 1px solid transparent;
    }
`;
const CampaignInnerWrapper = styled.div`
    align-self: stretch;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    min-width: 0;
`;
const Left = styled.div`
    display: flex;
    align-items: center;
    gap: 0.75rem;
    flex: 1;
    min-width: 0;
`;
const CampaignTitle = styled.div`
    font-weight: var(--fw-semibold);
    font-size: var(--fs-body-l);
    flex: 1;

    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: 1450px) {
        max-width: 600px;
    }

    &:hover {
        text-decoration: underline;
    }
`;
const LeftTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    flex: 1;
    min-width: 0;

    &:hover ${CampaignTitle} {
        text-decoration: underline;
    }
`;
const InvisibleRouterLink = styled(RouterLink)`
    color: inherit;
    text-decoration: none;
    font-weight: inherit;
    min-width: 0;
`;
const CampaignMetaData = styled.div`
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-size: var(--fs-body-m);
`;
const MetaData = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
    color: var(--neutral500);
`;
const ResponsiveMetadata = styled(MetaData)`
    display: none;
    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        display: flex;
    }
`;
const MobileDownloadsMetaData = styled(MetaData)`
    display: flex;
    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        display: none;
    }
`;
const Right = styled.div`
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    gap: 5rem;
    align-items: center;
`;
const EditRouterLink = styled(RouterLink)`
    display: none;

    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        display: flex;
        transition-duration: 0.2s;
        color: var(--neutral500);
        font-size: 1rem;
        padding: 0;

        &:hover {
            color: var(--neutral600);
        }
    }
`;
const ActionsWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 1.5rem;
`;
const StatusAndActionWrapper = styled.div`
    display: flex;
`;
const MetaDataIcon = styled(FontAwesomeIcon)`
    font-size: 0.75rem;
`;
const DotIcon = styled(FontAwesomeIcon)`
    font-size: 0.25rem;
    color: var(--neutral200);
`;
const StatusWrapper = styled.div`
    display: none;

    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 7.5rem;
    }
`;
const Decoy = styled.div`
    display: none;

    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        display: block;
        width: 1.25rem;
    }
`;

export default Campaign;
