import { FormattedMessage } from 'react-intl';
import StatsDataSetSelector from '@app/organisms/StatsDataSetSelector';
import DevicesFallback from '@app/fallbacks/DevicesFallback';
import { TABS_STATS_NAME } from '@app/pages/StatsPage';
import styled from 'styled-components';
import { useStatsDateSelectorContext } from '@/context/StatsDateSelectorContext';
import useGendersQuery from '@queries/stats/useGendersQuery.hook';
import { useParams } from 'react-router';
import Tooltip, { TooltipTriggerWrapper } from '@ui/Tooltip';
import Text from '@ui/atoms/Text';
import { TooltipTrigger } from 'react-aria-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Gender, { GENDER_TYPES } from '@app/pages/StatsAudiencePage/GendersGraph/Gender';

const GendersGraph = () => {
    const { showId } = useParams<{ showId: string }>();
    const { startDate, endDate } = useStatsDateSelectorContext();
    const { data: genders, isLoading } = useGendersQuery({
        showId,
        query: { start: startDate, end: endDate },
    });
    return (
        <>
            <StatsDataSetSelector
                tabTitle={<FormattedMessage defaultMessage="Audience" />}
                subTitle={
                    <FormattedMessage defaultMessage="Quelle est la répartition de votre audience par genre (Deezer uniquement) ?" />
                }
                titleTooltip={
                    <TooltipTrigger delay={0} closeDelay={0}>
                        <TooltipButton>
                            <TooltipIcon icon={icon({ name: 'circle-question', style: 'solid' })} />
                        </TooltipButton>
                        <Tooltip placement="left">
                            <Text fontWeight="--fw-semibold">
                                <FormattedMessage
                                    defaultMessage="L'audience concerne uniquement la{linebreak}plateforme Deezer pour le moment."
                                    values={{ linebreak: <br /> }}
                                />
                            </Text>
                        </Tooltip>
                    </TooltipTrigger>
                }
                tabId={TABS_STATS_NAME.AUDIENCE}
                noPodcastSelector
            />
            {isLoading ? (
                <DevicesFallback count={3} />
            ) : (
                <GenderGraphWrapper>
                    <Gender
                        type={GENDER_TYPES.FEMININE}
                        percent={
                            genders?.find((value) => value.gender === GENDER_TYPES.FEMININE)
                                ?.percentage
                        }
                    />
                    <Gender
                        type={GENDER_TYPES.MASCULINE}
                        percent={
                            genders?.find((value) => value.gender === GENDER_TYPES.MASCULINE)
                                ?.percentage
                        }
                    />
                    <Gender
                        type={GENDER_TYPES.OTHER}
                        percent={
                            genders?.find((value) => value.gender === GENDER_TYPES.OTHER)
                                ?.percentage
                        }
                    />
                </GenderGraphWrapper>
            )}
        </>
    );
};

const TooltipButton = styled(TooltipTriggerWrapper)`
    margin-left: 0.25rem;
    display: flex;
    align-items: center;
`;

const TooltipIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: var(--neutral500);
`;

const GenderGraphWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: var(--container-padding);
    background-color: var(--white);
    gap: 1rem;
    border-radius: var(--r-l);
    overflow-x: auto;

    ${({ theme }) => theme.mediaQueries.mobile} {
        justify-content: flex-start;
        padding: var(--container-padding-mobile);
    }
`;

export default GendersGraph;
