import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import DeprecatedModal from '@ui/molecules/DeprecatedModal';

const FeedbackModal = ({ isOpened, onClose }) => {
    const intl = useIntl();
    return (
        <DeprecatedModal
            contentProps={{ p: 0 }}
            isOpened={isOpened}
            onClose={onClose}
            title={<FormattedMessage defaultMessage="Feedback" />}
            w={760}
        >
            <iframe
                src={intl.formatMessage({
                    defaultMessage:
                        'https://feedback.userreport.com/3b9d5527-427b-41f3-b5e6-26c3ab996e1b#ideas/popular',
                })}
                style={{ border: 0, width: '100%' }}
                title="Feedback"
                height={620}
                width={760}
            />
        </DeprecatedModal>
    );
};

FeedbackModal.propTypes = {
    isOpened: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default FeedbackModal;
