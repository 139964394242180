import axios from 'axios';
import { getAxiosInstances } from '@/api/callApi';
import Cookies from 'js-cookie';

/**
 * Sets the Authorization header with a Bearer token for the global axios instance and all custom axios instances.
 */
export const setAxiosAuthorizationHeader = (accessToken: string): void => {
    const authorizationHeaderValue = `Bearer ${accessToken}`;

    // Update the global Axios default header to include the Authorization header.
    axios.defaults.headers.common.Authorization = authorizationHeaderValue;

    try {
        // Retrieve custom Axios instances, if any, and update their Authorization headers.
        const axiosInstances = getAxiosInstances();
        Object.values(axiosInstances).forEach((axiosInstance) => {
            axiosInstance.defaults.headers.common.Authorization = authorizationHeaderValue;
        });
    } catch (error) {
        // Log an error if updating the Axios instances fails.
        console.error('Failed to update Axios authorization header:', error);
    }
};

/**
 * Sets authorization cookies for accessToken and refreshToken
 */
export const setAuthorizationCookies = ({
    accessToken,
    refreshToken,
}: {
    accessToken: string;
    refreshToken: string;
}): void => {
    const authorizationCookies = Cookies.withAttributes({
        expires: 30, // Days until expiration
        secure: true, // Only send the cookie over HTTPS
        sameSite: 'lax', // Strict CSRF protection
    });
    authorizationCookies.set('token', accessToken);
    authorizationCookies.set('refresh', refreshToken);
};

/**
 * Removes authorization cookies
 */
export const removeAuthorizationCookies = (): void => {
    Cookies.remove('token');
    Cookies.remove('refresh');
};
