import { FormattedMessage, useIntl } from 'react-intl';
import ServerPagination from '@ui/ServerPagination';
import DeprecatedWhitePaper from '@ui/atoms/DeprecatedWhitePaper';
import PaperGrid from '@ui/atoms/PaperGrid';
import Column from '@ui/atoms/Column';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import { round } from '@/shared/utils/number';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import DeprecatedIcon from '@ui/atoms/DeprecatedIcon';
import exportIcon from '@public/icons/external-link.svg';
import { useStatsDateSelectorContext } from '@/context/StatsDateSelectorContext';
import NoDataChart from '@/components/charts/NoData/NoDataChart';
import styled from 'styled-components';
import { useParams } from 'react-router';
import useQuery from '@/shared/hooks/useQuery';
import { useState } from 'react';
import useSourceBrowsersQuery from '@/queries/stats/useSourceBrowsersQuery.hook';
import useCampaignSourceBrowsersQuery from '@/queries/stats/useCampaignSourceBrowsersQuery.hook';
import { fetchBrowsersExportCsv } from '@/api';
import { download } from '@/shared/utils/file';

const ExportCsv = ({ exportCsv }) => (
    <DeprecatedPaper flex justify="flex-end" mt={15}>
        <DeprecatedText flex align="center" color="--primary" weight="semibold" onClick={exportCsv}>
            <DeprecatedIcon as={exportIcon} color="--primary" mr={5} />
            <FormattedMessage defaultMessage="Exporter en .csv" />
        </DeprecatedText>
    </DeprecatedPaper>
);

const SourcesBrowsersTable = () => {
    const intl = useIntl();
    const { showId } = useParams();
    const query = useQuery();
    const campaignId = query.get('campaignId');
    const { startDate, endDate } = useStatsDateSelectorContext();
    const [page, setPage] = useState(1);

    const { data: showBrowsers } = useSourceBrowsersQuery({
        showId,
        start: startDate,
        end: endDate,
        page,
        options: {
            enabled: !campaignId,
        },
    });

    const { data: campaignBrowsers } = useCampaignSourceBrowsersQuery({
        campaignId,
        start: startDate,
        end: endDate,
        page,
        options: {
            enabled: !!campaignId,
        },
    });

    const browsers = campaignId ? campaignBrowsers : showBrowsers;

    const exportCsv = async () => {
        const { axios, ...data } = await fetchBrowsersExportCsv(showId, {
            start: startDate,
            end: endDate,
        });
        const filename = axios.headers['content-disposition'].match(/filename="(.+)"/)[1];
        download(Object.values(data).join(''), filename, 'txt');
    };

    return (
        <>
            <DeprecatedWhitePaper my={25}>
                <PaperGrid column={4} py={8} px={20}>
                    <Column startColumn={1} endColumn={3} color="--neutral500">
                        <FormattedMessage defaultMessage="Navigateur" />
                    </Column>
                    <Column startColumn={3} endColumn={4} justify="flex-end" color="--neutral500">
                        <FormattedMessage defaultMessage="Écoutes" />
                    </Column>
                    <Column startColumn={4} endColumn={5} color="--neutral500" justify="flex-end">
                        <FormattedMessage defaultMessage="Pourcentage" />
                    </Column>
                </PaperGrid>
                {browsers?.data?.length > 0 ? (
                    browsers?.data?.map((browser) => (
                        <PaperGrid
                            column={4}
                            py={15}
                            px={20}
                            key={browser.name}
                            bt={1}
                            border="--neutral100"
                        >
                            <Column startColumn={1} endColumn={3} ellipsis>
                                {browser.clientName === 'other'
                                    ? intl.formatMessage({ defaultMessage: 'Autres' })
                                    : browser.clientName}
                            </Column>
                            <Column startColumn={3} endColumn={4} justify="flex-end">
                                {browser.count}
                            </Column>
                            <Column startColumn={4} endColumn={5} justify="flex-end">
                                {`${round(browser.percentage)}%`}
                            </Column>
                        </PaperGrid>
                    ))
                ) : (
                    <NoDataChartWrapper>
                        <NoDataChart />
                    </NoDataChartWrapper>
                )}
            </DeprecatedWhitePaper>
            {browsers?.pagination && browsers?.pagination?.totalPages >= 2 && (
                <ServerPagination
                    pagination={browsers.pagination}
                    onGoToPage={(page) => setPage(page)}
                    onGoToFirstPage={() => setPage(1)}
                    onGoToLastPage={() => setPage(browsers.pagination.totalPages)}
                    onGoToPreviousPage={() => setPage(browsers.pagination.currentPage - 1)}
                    onGoToNextPage={() => setPage(browsers.pagination.currentPage + 1)}
                    options={{
                        goToFirstAndLastPageButtons: false,
                        showPaginationInfo: true,
                        showPageSelector: false,
                    }}
                >
                    <ExportCsv exportCsv={() => exportCsv(showId, startDate, endDate)} />
                </ServerPagination>
            )}
        </>
    );
};

const NoDataChartWrapper = styled.div`
    padding: 1rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        padding: 4rem;
    }
`;

export default SourcesBrowsersTable;
