import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Img from '@ui/atoms/Img';
import { useParams } from 'react-router';
import useUnlinkProviderMutation from '@queries/provider/useUnlinkProviderMutation.hook';
import { SOCIAL_NETWORK } from '@/shared/config/constants';
import Text from '@ui/atoms/Text';
import Button from '@ui/atoms/Button';
import styled from 'styled-components';
import Alert from '@ui/atoms/Alert';
import Stack from '@ui/layout/Stack';
import InputRadio from '@ui/atoms/InputRadio';
import RadioGroup from '@ui/molecules/RadioGroup';
import Dialog from '@ui/atoms/Dialog';

const ProviderLoginLinkedinAccountModal = ({ isOpen, accounts, onSubmit, onOpenChange }) => {
    const { showId } = useParams();
    const unlinkProvider = useUnlinkProviderMutation();
    const [selectedAccountId, setSelectedAccountId] = useState('');

    const handleClose = () => {
        unlinkProvider.mutate(
            { showId, provider: SOCIAL_NETWORK.LINKEDIN },
            {
                onSuccess: () => {
                    onOpenChange(false);
                },
            },
        );
    };

    const handleSubmit = () => {
        onSubmit(selectedAccountId);
    };

    if (!accounts) return null;

    return (
        <Dialog
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            title={
                <FormattedMessage defaultMessage="À quelle page LinkedIn souhaitez-vous vous connecter ?" />
            }
            onClose={handleClose}
            size="medium"
        >
            <Stack $gap="2rem">
                <Stack $gap="1.5rem">
                    <AccountRadioGroup onChange={setSelectedAccountId}>
                        {accounts.map((account) => (
                            <InputRadio key={account.id} value={account.id}>
                                <InputRadioInner>
                                    {/* TODO: Add profile image */}
                                    <Img src={account.profilePictureUrl} radius={4} size={40} />
                                    <Stack>
                                        <Text fontWeight="--fw-bold">{account.name}</Text>
                                        {account.type === 'personal' && (
                                            <Text color="--neutral500">
                                                <FormattedMessage defaultMessage="Page personnelle" />
                                            </Text>
                                        )}
                                        {account.type === 'professional' && (
                                            <Text color="--neutral500">
                                                <FormattedMessage defaultMessage="Page professionnelle" />
                                            </Text>
                                        )}
                                    </Stack>
                                </InputRadioInner>
                            </InputRadio>
                        ))}
                    </AccountRadioGroup>
                    <Alert variant="info" defaultIcon>
                        <Text fontWeight="--fw-semibold">
                            <FormattedMessage defaultMessage="Les statistiques de vos posts sont uniquement accessibles pour les pages professionnelles dont vous êtes administrateur." />
                        </Text>
                    </Alert>
                </Stack>
                <Actions>
                    <CancelButton variant="ghost" onPress={handleClose}>
                        <FormattedMessage defaultMessage="Annuler" />
                    </CancelButton>
                    <LoginButton onPress={handleSubmit} isDisabled={!selectedAccountId}>
                        <FormattedMessage defaultMessage="Se connecter à LinkedIn" />
                    </LoginButton>
                </Actions>
            </Stack>
        </Dialog>
    );
};

const Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 1.5rem;
    border-top: 1px solid var(--neutral100);
`;
const CancelButton = styled(Button)`
    padding: 0;
    color: var(--neutral500);
`;
const AccountRadioGroup = styled(RadioGroup)`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    max-height: 10rem;
    overflow-y: auto;
`;
const InputRadioInner = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
`;
const LoginButton = styled(Button)`
    background-color: var(--linkedin);
    box-shadow: none;
`;

export default ProviderLoginLinkedinAccountModal;
