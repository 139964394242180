import { useMutation, useQueryClient } from '@tanstack/react-query';
import camelcaseKeys from 'camelcase-keys';
import { Episode } from '../episode/useEpisodeQuery.hook';
import { enableAIFreeTrial } from '@/api';
import channelKeys from '../channel/channelKeys';

const mutationFn = async (episodeId: string): Promise<Episode> => {
    const { data } = await enableAIFreeTrial(Number(episodeId));

    return camelcaseKeys(data as any, { deep: true });
};

const enableAIFreeTrialMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: channelKeys.allDetails() });
        },
    });
};

export default enableAIFreeTrialMutation;
