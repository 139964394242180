import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateChannelGuestMainRole } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import guestKeys from './guestKeys';
import { ChannelGuestUserPermissionsSummary } from './useChannelGuestUserPermissionsSummary.hook';
import { ChannelGuestUserRole } from '@/utils/constants';

export interface UpdateChannelGuestMainRoleMutationFnProps {
    channelId: number;
    userId: number;
    role: ChannelGuestUserRole;
}

const mutationFn = async ({
    channelId,
    userId,
    role,
}: UpdateChannelGuestMainRoleMutationFnProps): Promise<ChannelGuestUserPermissionsSummary> => {
    const { data } = await updateChannelGuestMainRole({
        channelId: channelId,
        userId: userId,
        role,
    });

    return camelcaseKeys(data as any, { deep: true });
};

const updateChannelGuestMainRoleMutation = () => {
    const queryclient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: (_, { channelId, userId }) => {
            queryclient.invalidateQueries({
                queryKey: guestKeys.userPermissionList(channelId, userId),
            });
            queryclient.invalidateQueries({
                queryKey: guestKeys.permissionListByChannelId(channelId),
            });
        },
    });
};

export default updateChannelGuestMainRoleMutation;
