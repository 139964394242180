import { useState, useEffect } from 'react';
import Text from '@ui/atoms/Text';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import Stack from '@ui/layout/Stack';
import { InputNumberController } from '@ui/atoms/InputNumber';
import Button from '@/components/Button';
import Alert from '@ui/atoms/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Cluster from '@ui/layout/Cluster';
import Tooltip, { TooltipTriggerWrapper } from '@ui/Tooltip';
import { TooltipTrigger } from 'react-aria-components';
import { priceValueToInt } from '@/helpers';
import usePricesQuery, { Currency, Offer } from '@queries/price/usePricesQuery.hook';
import useSubscriptionQuery from '@queries/subscription/useSubscriptionQuery.hook';
import { Quota } from '@queries/quota/useNewsletterQuotaQuery.hook';
import useUserQuery from '@queries/user/useUserQuery.hook';

interface NewsletterQuotaFormProps {
    newsletterQuota?: Quota;
    onClose: () => void;
    onSubmit: ({ quota }: { quota: number }) => void;
}

const NewsletterQuotaForm = ({ newsletterQuota, onClose, onSubmit }: NewsletterQuotaFormProps) => {
    const [overflowingEmails, setOverflowingEmails] = useState(0);

    const { data: subscription } = useSubscriptionQuery();
    const { data: prices } = usePricesQuery();
    const { data: user } = useUserQuery();

    const { control, handleSubmit, watch, formState } = useForm<{ quota: number }>({
        defaultValues: {
            quota: newsletterQuota?.maximum,
        },
    });
    const { isDirty } = formState;
    const quotaValue = watch('quota');

    const subscriptionPeriodicity = subscription?.yearly ? 'yearly' : 'monthly';
    const pricesForCurrency = prices?.[user?.currency as Currency];
    const pricesForCurrentSubscription = pricesForCurrency?.[subscription?.pricing as Offer];
    const pricesForCurrentSubscriptionPeriodicity =
        pricesForCurrentSubscription?.[subscriptionPeriodicity];
    const priceForExtra = pricesForCurrentSubscriptionPeriodicity?.extra;

    const price =
        priceForExtra && quotaValue && newsletterQuota?.maximum
            ? (priceValueToInt(priceForExtra) * (quotaValue - newsletterQuota?.maximum)) / 1000
            : 0;

    useEffect(() => {
        if (!newsletterQuota?.maximum) return;
        setOverflowingEmails(newsletterQuota.maximum - quotaValue);
    }, [newsletterQuota?.maximum, quotaValue]);

    return (
        <Form
            onSubmit={(e) => {
                // Prevent the form to submit outer form (NewsletterForm)
                e.stopPropagation();
                handleSubmit(onSubmit)(e);
            }}
        >
            <InnerWrapper>
                <Stack $gap="0.25rem">
                    <Text fontWeight="--fw-semibold">
                        <FormattedMessage defaultMessage="Envois d’emails additionnels" />
                    </Text>
                    <Text color="--neutral500" textTransform="lowercase">
                        <FormattedMessage
                            defaultMessage="{price} / {currentSubscription}"
                            values={{
                                price: (
                                    <FormattedNumber
                                        value={price}
                                        style="currency"
                                        currency={user?.currency}
                                        currencyDisplay="narrowSymbol"
                                        maximumFractionDigits={Number.isInteger(price) ? 0 : 2}
                                    />
                                ),
                                currentSubscription: subscription?.yearly ? (
                                    <FormattedMessage defaultMessage="an" />
                                ) : (
                                    <FormattedMessage defaultMessage="mois" />
                                ),
                            }}
                        />
                    </Text>
                </Stack>
                <InputNumberController control={control} step={1000} minValue={1000} name="quota" />
            </InnerWrapper>
            <FooterWrapper>
                <Divider />
                <Cluster $gap="0.25rem" $align="center">
                    <Text>
                        <FormattedMessage
                            defaultMessage="Votre nouvelle limite mensuelle d’envois sera de <bold>{quotaValue}</bold> emails."
                            values={{
                                quotaValue,
                                bold: (chunks: React.ReactNode) => (
                                    <Text as="span" fontWeight="--fw-semibold">
                                        {chunks}
                                    </Text>
                                ),
                            }}
                        />
                    </Text>
                    <TooltipTrigger delay={0} closeDelay={0}>
                        <TooltipTriggerWrapper>
                            <HelpIcon
                                icon={icon({
                                    name: 'circle-info',
                                    style: 'solid',
                                })}
                            />
                        </TooltipTriggerWrapper>
                        <Tooltip placement="top">
                            <Text fontWeight="--fw-semibold">
                                <FormattedMessage
                                    defaultMessage="Aux 1 000 envois d’emails inclus dans votre forfait s’ajouteront {quotaValue} emails additionnels."
                                    values={{
                                        quotaValue,
                                    }}
                                />
                            </Text>
                        </Tooltip>
                    </TooltipTrigger>
                </Cluster>

                {overflowingEmails > 0 && (
                    <Alert
                        variant="warning"
                        icon={<FontAwesomeIcon icon={icon({ name: 'triangle-exclamation' })} />}
                    >
                        <FormattedMessage defaultMessage="En diminuant votre quota mensuel, votre newsletter risque de ne pas être envoyée à tous vos abonnés." />
                    </Alert>
                )}
                <ActionWrapper>
                    <Button isDisabled={!isDirty} variant="primary" type="submit">
                        <FormattedMessage defaultMessage="Modifier la limite" />
                    </Button>
                    <CancelButton onPress={onClose} variant="ghost">
                        <FormattedMessage defaultMessage="Annuler" />
                    </CancelButton>
                </ActionWrapper>
            </FooterWrapper>
        </Form>
    );
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    height: 100%;
`;
const InnerWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 1rem;
`;
const ActionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 1rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        column-gap: 1rem;

        & > :first-child {
            order: 1;
        }
    }
`;
const CancelButton = styled(Button)`
    padding: 0;
    color: var(--neutral500);
`;
const Divider = styled.div`
    height: 0.0625rem;
    background-color: var(--neutral100);
`;
const FooterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    align-items: stretch;
`;
const HelpIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
    width: 0.75rem;
    height: 0.75rem;
`;

export default NewsletterQuotaForm;
