import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Stack from '@ui/layout/Stack';
import Text from '@ui/atoms/Text';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Button from '@/components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from '@ui/atoms/Modal';

interface UnarchiveModalProps {
    isOpen: boolean;
    onOpenChange: (isOpen: boolean) => void;
    isLoading: boolean;
    onActivate: () => void;
}

const UnarchiveModal = ({ isOpen, onOpenChange, onActivate, isLoading }: UnarchiveModalProps) => {
    const handleClose = () => onOpenChange(false);

    return (
        <ModalStyled isOpen={isOpen} onOpenChange={onOpenChange} size="small">
            <IconWrapper>
                <Icon icon={icon({ name: 'arrows-rotate', style: 'solid' })} />
            </IconWrapper>
            <Stack $gap="0.25rem">
                <Text variant="headingS" fontWeight="--fw-bold" textAlign="center">
                    <FormattedMessage defaultMessage="Souhaitez-vous activer l’émission ?" />
                </Text>
                <Text color="--neutral500" textAlign="center">
                    <FormattedMessage defaultMessage="Vous ne pourrez plus archiver l'émission pendant 30 jours après la dernière modification." />
                </Text>
            </Stack>
            <Actions>
                <Button onPress={onActivate} isLoading={isLoading}>
                    <FormattedMessage defaultMessage="Activer l'émission" />
                </Button>
                <Button variant="link-secondary" onPress={handleClose}>
                    <FormattedMessage defaultMessage="Annuler" />
                </Button>
            </Actions>
        </ModalStyled>
    );
};

const ModalStyled = styled(Modal)`
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1.5rem;
`;
const Icon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
`;
const IconWrapper = styled.div`
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary50);
    color: var(--primary500);
    border-radius: var(--r-s);
`;
const Actions = styled.div`
    align-self: stretch;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    & > :last-child {
        align-self: center;
    }
`;

export default UnarchiveModal;
