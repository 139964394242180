import { FormattedMessage } from 'react-intl';
import Modal from '@/components/ui/molecules/Modal';
import Text from '@/components/ui/atoms/Text';
import Button from '@/components/ui/atoms/Button';
import Stack from '@/components/ui/layout/Stack';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';

const DeleteChapterModal = ({ chapter, isOpen, onClose, handleDeleteChapter, isLoading }) => {
    const { watch } = useFormContext();

    if (!chapter) {
        return null;
    }

    const chapters = watch('chapters', []);
    const sortedChapters = chapters
        .toSorted((chapter1, chapter2) => chapter1.startTime - chapter2.startTime)
        .map((chapter, index) => ({ ...chapter, number: index + 1 }));

    const chapterNumber = sortedChapters.find(
        (sortedChapter) => sortedChapter.id === chapter.id,
    )?.number;

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={<FormattedMessage defaultMessage="Supprimer le chapitre" />}
        >
            <Stack $gap="2rem">
                <Text>
                    <FormattedMessage
                        defaultMessage="Êtes-vous sûr de vouloir supprimer le {label} ?"
                        values={{
                            label: (
                                <Text as="span" fontWeight="--fw-semibold">
                                    <FormattedMessage
                                        defaultMessage="chapitre {chapterNumber}"
                                        values={{ chapterNumber: '#' + chapterNumber }}
                                    />
                                </Text>
                            ),
                        }}
                    />
                </Text>
                <ButtonsWrapper>
                    <Button variant="secondary" onPress={onClose}>
                        <FormattedMessage defaultMessage="Annuler" />
                    </Button>
                    <Button onPress={handleDeleteChapter} isLoading={isLoading}>
                        <FormattedMessage defaultMessage="Supprimer le chapitre" />
                    </Button>
                </ButtonsWrapper>
            </Stack>
        </Modal>
    );
};

const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-self: stretch;
    align-items: stretch;
    row-gap: 0.5rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        column-gap: 0.5rem;
    }
`;

export default DeleteChapterModal;
