import { useController } from 'react-hook-form';
import { default as Input } from '.';

export const InputTextController = (props) => {
    const { control, name, onChange, onBlur, ...rest } = props;
    const { field, fieldState } = useController({
        name,
        control,
    });

    return (
        <Input
            onChange={(value) => {
                const forwardedValue = !value
                    ? ''
                    : props.onlyLowerCase
                    ? value.toLowerCase()
                    : value;
                const filteredValue = forwardedValue.replace(
                    props.forbiddenCharactersRegex || '',
                    '',
                );
                onChange && onChange(filteredValue);
                field.onChange(filteredValue);
            }}
            onBlur={(...v) => {
                onBlur && onBlur(...v);
                field.onBlur(...v);
            }}
            value={props.value || field.value}
            defaultValue={props.defaultValue || field.value}
            name={field.name}
            inputState={fieldState.error ? 'errors' : fieldState}
            errorMessage={fieldState.error?.message}
            {...rest}
        />
    );
};
