import { useState, useRef, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { SelectController } from '@ui/atoms/Select/Select.controller';
import { ComboBoxController } from '@ui/atoms/ComboBox/ComboBox.controller';
import ListBoxItem from '@ui/atoms/ListBoxItem';
import useAvailableStoresQuery from '@queries/pso/useAvailableStoresQuery.hook';
import useKeywordsRankingFormSchema, {
    KeywordsRankingForm,
} from './useKeywordsRankingFormSchema.hook';
import type { Platform } from '@/api/pso/types';
import ReactCountryFlag from 'react-country-flag';
import usePsoQuery from '@/queries/pso/usePsoQuery.hook';
import styled from 'styled-components';
import { Key } from 'react-aria';
// @ts-ignore
import Spotify from '@public/images/platforms/Spotify.svg?url';
// @ts-ignore
import ApplePodcast from '@public/images/platforms/ApplePodcasts.svg?url';
import useQuery from '@/shared/hooks/useQuery';
import { APPLE_PODCASTS, SPOTIFY } from '@/shared/config/constants';
import useShowQuery from '@/queries/show/useShowQuery.hook';

type Store = {
    name: string;
    alpha2Code: string;
};

type StoreWithImage = { id: string; name: string; image: React.ReactNode };

const Form = () => {
    const formRef = useRef<HTMLFormElement>(null);
    const { showId } = useParams<{ showId: string }>();
    const history = useHistory();
    const location = useLocation();
    const query = useQuery();
    const [storeSearch, setStoreSearch] = useState('');

    const show = useShowQuery(showId);
    const hasSpotifyData =
        (show.data?.listeningLinks?.data?.findIndex((link) => link.key === SPOTIFY) ?? -1) > -1;
    const hasAppleData =
        (show.data?.listeningLinks?.data?.findIndex((link) => link.key === APPLE_PODCASTS) ?? -1) >
        -1;

    const schema = useKeywordsRankingFormSchema();
    const availableStores = useAvailableStoresQuery();
    const psoQuery = usePsoQuery({ showId, enabled: true });

    const defaultPlatform: Platform =
        (hasSpotifyData && !hasAppleData && SPOTIFY) ||
        (!hasSpotifyData && hasAppleData && APPLE_PODCASTS) ||
        (query.get('platform') as Platform) ||
        APPLE_PODCASTS;

    const defaultStore: string | null = query.get('store');

    const psoStores = psoQuery?.data?.stores?.data.map(
        (store: { alpha2Code: string }) => store.alpha2Code,
    );

    useEffect(() => {
        history.push(
            `${location.pathname}?platform=${defaultPlatform ?? APPLE_PODCASTS}&store=${
                defaultStore ?? psoStores[0] ?? 'US'
            }`,
        );
    }, []);

    const { control, handleSubmit, watch, setValue } = useForm<KeywordsRankingForm>({
        mode: 'onChange',
        resolver: zodResolver(schema),
        defaultValues: {
            platform: defaultPlatform ?? APPLE_PODCASTS,
            store: defaultStore ?? psoStores[0] ?? 'US',
        },
    });

    const onSubmit = (formData: KeywordsRankingForm) => {
        history.push(`${location.pathname}?platform=${formData.platform}&store=${formData.store}`);
    };

    return (
        <FormElement onSubmit={handleSubmit(onSubmit)} ref={formRef}>
            <FormSection>
                <FormItemWrapper>
                    <SelectController
                        name="platform"
                        // @ts-ignore
                        control={control}
                        items={[
                            {
                                id: 'itunes',
                                name: 'Apple Podcasts',
                                image: <PlatformIcon src={ApplePodcast} alt="ApplePodcast" />,
                            },
                            {
                                id: 'spotify',
                                name: 'Spotify',
                                image: <PlatformIcon src={Spotify} alt="Spotify" />,
                            },
                        ]}
                        onSelectionChange={(value: Key) => {
                            setValue('platform', value as Platform);
                            formRef.current?.requestSubmit();
                        }}
                    >
                        {(item: { id: string; name: string; image: React.ReactNode }) => (
                            <ListBoxItem {...item} aria-label={item.name} />
                        )}
                    </SelectController>
                </FormItemWrapper>
                <FormItemWrapper>
                    <ComboBoxController
                        name="store"
                        control={control}
                        onInputChange={(value: string) => setStoreSearch(value)}
                        onSelectionChange={(value: Key | null) => {
                            setValue('store', value as string);
                            formRef.current?.requestSubmit();
                        }}
                        items={availableStores?.data
                            ?.map((store: Store) => ({
                                id: store.alpha2Code,
                                name: store.name,
                                image: <ReactCountryFlag countryCode={store.alpha2Code} svg />,
                            }))
                            ?.sort((a: StoreWithImage, b: StoreWithImage) => {
                                const isAFavorite = psoStores.includes(a.id);
                                const isBFavorite = psoStores.includes(b.id);
                                if (isAFavorite && isBFavorite) {
                                    return a.name.localeCompare(b.name);
                                }
                                if (!isAFavorite && !isBFavorite) {
                                    return a.name.localeCompare(b.name);
                                }

                                return isAFavorite ? -1 : 1;
                            })
                            .filter((store: StoreWithImage) => {
                                if (
                                    availableStores?.data?.find((s: Store) => {
                                        return s.alpha2Code === watch('store');
                                    })
                                ) {
                                    return true;
                                }
                                return store.name.toLowerCase().includes(storeSearch.toLowerCase());
                            })}
                    >
                        {(item: StoreWithImage) => <ListBoxItem aria-label={item.name} {...item} />}
                    </ComboBoxController>
                </FormItemWrapper>
            </FormSection>
        </FormElement>
    );
};

const FormElement = styled.form`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;
const FormSection = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
`;
const FormItemWrapper = styled.div`
    flex-basis: 15rem;
    display: flex;
    gap: 1rem;
`;
const PlatformIcon = styled.img`
    width: 1rem;
    height: 1rem;
`;

export default Form;
