import styled from 'styled-components';
import { useAmplitudeLogEvent } from '@hooks/useAmplitude';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Text from '@ui/atoms/Text';
import TrackersForm from '@app/tabs/Trackers/TrackersForm';
import { useUpdateTrackers } from '@app/tabs/Trackers/useUpdateTrackers.hook';
import Spinner from '@ui/atoms/Spinner';
import { useModalToastQueue } from '@/utils/hooks/useModalToastQueue.hook';
import useTrackersQuery from '@app/tabs/Trackers/useTrackersQuery.hook';
import { decamelize } from 'humps';

const Trackers = () => {
    const { showId } = useParams();
    const { data, error, isLoading } = useTrackersQuery(showId);
    const updateTrackers = useUpdateTrackers();
    useAmplitudeLogEvent('show trackers');
    const toast = useModalToastQueue();

    if (isLoading || error) return <Spinner />;

    const handleSubmit = (values) => {
        const transformedValues = Object.keys(values)
            .filter((key) => values[key])
            .map((key) => {
                return {
                    platform: decamelize(key),
                    tracker: values[key],
                };
            });

        updateTrackers.mutate(
            {
                showId,
                trackers: transformedValues,
            },
            {
                onError: () =>
                    toast.alert(
                        <FormattedMessage defaultMessage="Oups, les modifications n’ont pas été enregistrées. Veuillez réessayer." />,
                    ),
                onSuccess: () =>
                    toast.success(
                        <FormattedMessage defaultMessage="Les modifications ont bien été enregistrées." />,
                    ),
            },
        );
    };

    return (
        <TrackersWrapper>
            <TrackersInnerWrapper>
                <Text color="--neutral500">
                    <FormattedMessage defaultMessage="Mesurez l’audience et le trafic générés par vos pages podcast et smartlink." />
                </Text>
                <TrackersForm
                    trackers={data}
                    onSubmit={handleSubmit}
                    isMutating={isLoading || updateTrackers.isLoading}
                />
            </TrackersInnerWrapper>
        </TrackersWrapper>
    );
};

const TrackersWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding);

    ${({ theme }) => theme.mediaQueries.mobile} {
        padding: var(--container-padding-mobile);
    }
`;
const TrackersInnerWrapper = styled.div`
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 2rem;

    ${(props) => props.theme.mediaQueries.tabletAndUp} {
        width: 100%;
        max-width: 42.5rem;
        padding: 2rem;
    }
`;

export default Trackers;
