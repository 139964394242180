import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Cluster from '@ui/layout/Cluster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import useNewsletterExportSubscribersCsvQuery from './useNewsletterExportSubscribersCsvQuery.hook';
import { useParams } from 'react-router-dom';
import { download } from '@/helpers';
import Button from '@/components/Button';

const NewsletterSubscriberExportButton = () => {
    const { showId } = useParams<{ showId: string }>();
    const { data } = useNewsletterExportSubscribersCsvQuery(showId);

    const exportSubscribers = () => {
        download(data?.subscribers, data?.filename, 'txt');
    };

    return (
        <Cluster $gap="0.25rem" $align="center" $justify="flex-end">
            <ExportIcon
                icon={icon({
                    name: 'arrow-up-right-from-square',
                    style: 'solid',
                })}
            />
            <Button variant="link-primary" onPress={exportSubscribers}>
                <FormattedMessage defaultMessage="Exporter en .csv" />
            </Button>
        </Cluster>
    );
};

const ExportIcon = styled(FontAwesomeIcon)`
    color: var(--primary);
`;

export default NewsletterSubscriberExportButton;
