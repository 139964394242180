import styled, { keyframes } from 'styled-components';
import Loader from '@public/icons/loader.svg';
import Icon from '../atoms/Icon';

const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

const AnimatedLoader = styled(Loader)`
    animation: ${rotate} 1300ms linear infinite;
`;

const IconLoader = (props) => <Icon as={AnimatedLoader} {...props} />;

IconLoader.propTypes = Icon.propTypes;

export default IconLoader;
