import SimpleGrid from '@ui/layout/SimpleGrid';
import { makeArray } from '@/shared/utils/array';
import ClipFormatSkeleton from './ClipFormat/Skeleton';

const GridSkeleton = () => {
    return (
        <SimpleGrid $idealChildWidth={250} $rowGap={32} $columnGap={36}>
            {makeArray(16).map((_, index) => (
                <ClipFormatSkeleton key={`fallback-${index}`} />
            ))}
        </SimpleGrid>
    );
};

export default GridSkeleton;
