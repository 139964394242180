import { useQuery } from '@tanstack/react-query';
import { fetchCalendarCommunicationEventDetails } from '@/api';
import socialNetworkPostKeys from './socialNetworkPostKeys';
import camelcaseKeys from 'camelcase-keys';

const queryFn = async ({ queryKey: [{ messageId }] }) => {
    const { data } = await fetchCalendarCommunicationEventDetails(messageId);

    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const useSocialNetworkCalendarPostDetailsQuery = (messageId) => {
    return useQuery({
        queryKey: socialNetworkPostKeys.detailById(messageId),
        queryFn,
        enabled: !!messageId,
    });
};

export default useSocialNetworkCalendarPostDetailsQuery;
