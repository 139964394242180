import ShowClips from '../organisms/ShowClips';
import styled from 'styled-components';

const ClipsPage = () => {
    return (
        // TODO: Check this Box when ClipsPage will be in communication page
        <ClipsPageWrapper>
            <ShowClips />
        </ClipsPageWrapper>
    );
};

const ClipsPageWrapper = styled.div`
    flex-grow: 1;
    width: 100%;
    height: max-content;
    max-width: var(--container-width);
    margin-inline: auto;
    margin-block: 2rem;
    padding-inline: var(--container-padding);

    ${({ theme }) => theme.mediaQueries.mobile} {
        margin-block: 1rem;
        padding-inline: var(--container-padding-mobile);
    }
`;

export default ClipsPage;
