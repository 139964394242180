import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import DeprecatedIcon from '@ui/atoms/DeprecatedIcon';
import scheduledIcon from '@public/icons/confirm-scheduled.svg';
import { useResponsive } from '@/utils/hooks/useResponsive';
import useEpisodeQuery from '@/queries/episode/useEpisodeQuery.hook';
import { useParams } from 'react-router-dom';

const CongratulateEpisodeSummaryScheduled = () => {
    const { episodeId } = useParams();
    const { data: episode } = useEpisodeQuery({ episodeId });
    const { isMobileOrTablet } = useResponsive();
    const publishedAt = episode && new Date(episode.publishedAt);

    return (
        <>
            <DeprecatedIcon as={scheduledIcon} size={isMobileOrTablet ? 64 : 180} />
            <DeprecatedText size="veryBig" weight="bold" align="center">
                <FormattedMessage defaultMessage="Félicitations !" />
            </DeprecatedText>
            <DeprecatedText size="big" align="center" mt={15} mb={25}>
                <FormattedMessage defaultMessage="Votre épisode a bien été planifié 📅" />
            </DeprecatedText>
            <DeprecatedText size="big" align="center" mt={15} mb={5}>
                <FormattedMessage defaultMessage=" Il sera publié le :" />
            </DeprecatedText>
            <DeprecatedText weight="bold" size="big" align="center" mb={50}>
                <FormattedDate day="2-digit" month="long" year="numeric" value={publishedAt} />
                <DeprecatedText weight="bold" size="big" align="center" as="span" px={5}>
                    <FormattedMessage defaultMessage="à" />
                </DeprecatedText>
                <FormattedTime value={publishedAt} />
            </DeprecatedText>
        </>
    );
};

export default CongratulateEpisodeSummaryScheduled;
