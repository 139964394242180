import usePlaylistQuery from '@/queries/playlist/usePlaylistQuery.hook';
import { useParams } from 'react-router';
import PlaylistEpisodesEmptyState from './PlaylistEpisodesEmptyState';
import styled from 'styled-components';
import PlaylistEpisode from './PlaylistEpisode';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import updatePlaylistEpisodePositionMutation from '@/queries/playlist/updatePlaylistEpisodePositionMutation';
import PlaylistEpisodesSkeleton from './PlaylistEpisodesSkeleton';

const PlaylistEpisodes = () => {
    const { playlistId } = useParams<{ playlistId: string }>();
    const playlist = usePlaylistQuery(playlistId);
    const updateEpisodePosition = updatePlaylistEpisodePositionMutation();

    if (playlist.isSuccess) {
        if (playlist.data.episodes.length === 0) {
            return <PlaylistEpisodesEmptyState playlistName={playlist.data.name} />;
        }

        const onDragEnd = ({ source, destination }: DropResult) => {
            if (!destination) {
                return;
            }

            if (
                destination.droppableId === source.droppableId &&
                destination.index === source.index
            ) {
                return;
            }
            updateEpisodePosition.mutate({
                playlistId: playlistId,
                episodeId: playlist.data.episodes[source.index].playlistPodcastId,
                newPosition: destination.index,
            });
        };

        return (
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="playlist">
                    {(provided) => (
                        <EpisodesList ref={provided.innerRef} {...provided.droppableProps}>
                            {playlist.data.episodes.map((episode, index) => (
                                <PlaylistEpisode key={episode.id} episode={episode} index={index} />
                            ))}
                        </EpisodesList>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }

    return <PlaylistEpisodesSkeleton />;
};

const EpisodesList = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 0;
`;

export default PlaylistEpisodes;
