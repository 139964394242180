import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { FORM_VALIDATION_MESSAGE } from '@/utils/validationMessage';

const useApplePodcastsReviewsNotificationFormSchema = () => {
    const intl = useIntl();

    return yup.object({
        notifications_subscribed: yup
            .bool()
            .required(intl.formatMessage(FORM_VALIDATION_MESSAGE.required)),
        email_notified: yup.string().when('notifications_subscribed', {
            is: true,
            then: () =>
                yup
                    .string()
                    .email(intl.formatMessage(FORM_VALIDATION_MESSAGE.isEmail))
                    .required(intl.formatMessage(FORM_VALIDATION_MESSAGE.required)),
            otherwise: () => yup.string().notRequired(),
        }),
    });
};

export default useApplePodcastsReviewsNotificationFormSchema;
