import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateTranscriptQuota } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import { QUOTA_TYPES } from '@/shared/config/constants';
import quotaKeys from './quotaKeys';
import { TranscriptQuota } from './useTranscriptQuotaQuery.hook';

const transform = (data: TranscriptQuota) => {
    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const mutationFn = async (quantity: number) => {
    const quantitySeconds = quantity * 60;
    const { data } = await updateTranscriptQuota(quantitySeconds);

    return transform(data);
};

const useUpdateTranscriptQuotaMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn,
        onSuccess: () => {
            return queryClient.invalidateQueries({
                queryKey: quotaKeys.detailByType(
                    QUOTA_TYPES.TRANSCRIPT as keyof typeof QUOTA_TYPES,
                ),
            });
        },
    });
};

export default useUpdateTranscriptQuotaMutation;
