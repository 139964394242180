import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import Dialog from '@ui/atoms/Dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import FacebookPostSkeleton from '@public/images/social/facebook-post-skeleton.svg?url';
import LinkedinPostSkeleton from '@public/images/social/linkedin-post-skeleton.svg?url';
import InstagramPostSkeleton from '@public/images/social/instagram-post-skeleton.svg?url';
import XPostSkeleton from '@public/images/social/x-post-skeleton.svg?url';
import PlatformCard from './PlatformCard';
import Button from '@/components/ui/atoms/Button';
import {
    FACEBOOK,
    TWITTER,
    INSTAGRAM,
    LINKEDIN,
    SOCIAL_NETWORK_NAME,
    SOCIAL_NETWORK_POST_STATUS,
} from '@/utils/constants';
import SelectEpisode from './SelectEpisode';
import CreateOrUpdatePost from './CreateOrUpdatePost';
import { isProviderEnabled } from '@/helpers/providers';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import { PRICING } from '@/utils/pricing';
import useProvidersQuery from '@/queries/provider/useProvidersQuery.hook';
import useSocialNetworkPostTemplateQuery from '@queries/socialNetworkPost/useSocialNetworkPostTemplateQuery.hook';
import useCreateSocialNetworkPostMutation from '@queries/socialNetworkPost/useCreateSocialNetworkPostMutation.hook';
import { useEditedPostContext } from '@app/pages/SocialMediaPage/useEditedPostContext.hook';
import styled from 'styled-components';
import { useResponsive } from '@/utils/hooks/useResponsive';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import { USER_ROLES } from '@/utils/constants';

const STEPS = {
    SELECT_PLATFORM: 'SELECT_PLATFORM',
    SELECT_EPISODE: 'SELECT_EPISODE',
    CREATE_POST: 'CREATE_POST',
};

const AddPostModal = ({ isOpen, onClose, editedPost }) => {
    const { showId } = useParams();
    const user = useUserQuery();
    const show = useShowQuery(showId);
    const editedPostContext = useEditedPostContext();
    const linkedPlatforms = useProvidersQuery(showId);
    const [currentStep, setCurrentStep] = useState(STEPS.SELECT_PLATFORM);
    const [platform, setPlatform] = useState(editedPost?.post?.provider || null);
    const [episode, setEpisode] = useState(editedPost?.post?.podcast?.id || null);
    const [post, setPost] = useState(editedPost?.post);
    const [duplicationMode, setDuplicationMode] = useState(editedPost?.duplicate);
    const postTemplate = useSocialNetworkPostTemplateQuery({
        showId,
        platformId: platform?.platformId,
    });
    const { isTabletOrDesktop } = useResponsive();

    const createPost = useCreateSocialNetworkPostMutation();

    useEffect(() => {
        if (editedPost) {
            setPost(editedPost?.post);
            setDuplicationMode(editedPost?.duplicate);
            setCurrentStep(editedPost?.duplicate ? STEPS.SELECT_PLATFORM : STEPS.CREATE_POST);
        }
    }, [editedPost]);

    /**
     * Reset the state when the modal is closed
     */
    const handleClose = () => {
        onClose();
        setPost(null);
        setDuplicationMode(false);
        setCurrentStep(STEPS.SELECT_PLATFORM);
        setEpisode(null);
        setPlatform(null);
        editedPostContext.setEditedPost(null);
    };

    const createDummyPost = (episodeId, platformId) => {
        /**
         * We create the post right now
         * because we need to get back the post ID from the API
         * to attach the *potential* uploaded image to it in the next step.
         *
         * This is imho approching the problem wrongly, but back-end is not ready to handle this differently atm.
         * In a ideal world, this *should* be temporary. I write this 2023-12-6, let me know :D
         */
        const payload = {
            messageTpl: editedPost?.post?.messageTpl
                ? editedPost?.post?.messageTpl
                : !postTemplate.isError
                ? postTemplate?.data?.messageTpl
                : '',
            publishAt: editedPost?.post?.publishAt || null,
            state: SOCIAL_NETWORK_POST_STATUS.DRAFT,
            type: editedPost?.post?.type || null,
            clipFormatId: editedPost?.post?.clipFormatId || null,
        };

        createPost.mutate(
            {
                episodeId,
                platformId,
                payload,
            },
            {
                onSuccess: (post) => {
                    setPost(post);
                    setCurrentStep(STEPS.CREATE_POST);
                },
            },
        );
    };

    const referencePricing =
        show?.data?.userRole === USER_ROLES.OWNER ? user?.data?.pricing : show?.data?.ownerPricing;

    const cards = [
        {
            id: FACEBOOK,
            platformId: FACEBOOK,
            platformName: SOCIAL_NETWORK_NAME[FACEBOOK],
            platformIcon: <FontAwesomeIcon icon={icon({ name: 'facebook', style: 'brands' })} />,
            platformSkeleton: FacebookPostSkeleton,
            color: '--facebook',
            disabled: !isProviderEnabled(FACEBOOK, user.data),
            unauthorized: false,
            isConnected: !!linkedPlatforms?.data?.[FACEBOOK],
        },
        {
            id: TWITTER,
            platformId: TWITTER,
            platformName: SOCIAL_NETWORK_NAME[TWITTER],
            platformIcon: <FontAwesomeIcon icon={icon({ name: 'x-twitter', style: 'brands' })} />,
            platformSkeleton: XPostSkeleton,
            color: '--twitter',
            disabled: !isProviderEnabled(TWITTER, user.data),
            unauthorized: false,
            isConnected: !!linkedPlatforms?.data?.[TWITTER],
        },
        {
            id: INSTAGRAM,
            platformId: INSTAGRAM,
            platformName: SOCIAL_NETWORK_NAME[INSTAGRAM],
            platformIcon: <FontAwesomeIcon icon={icon({ name: 'instagram', style: 'brands' })} />,
            platformSkeleton: InstagramPostSkeleton,
            color: '--instagram',
            disabled: !isProviderEnabled(INSTAGRAM, user.data),
            unauthorized: referencePricing === PRICING.LAUNCH,
            isConnected: !!linkedPlatforms?.data?.[INSTAGRAM],
        },
        {
            id: LINKEDIN,
            platformId: LINKEDIN,
            platformName: SOCIAL_NETWORK_NAME[LINKEDIN],
            platformIcon: <FontAwesomeIcon icon={icon({ name: 'linkedin', style: 'brands' })} />,
            platformSkeleton: LinkedinPostSkeleton,
            color: '--linkedin',
            disabled: !isProviderEnabled(LINKEDIN, user.data),
            unauthorized: referencePricing === PRICING.LAUNCH,
            isConnected: !!linkedPlatforms?.data?.[LINKEDIN],
        },
    ];

    const stepsLookup = {
        [STEPS.SELECT_PLATFORM]: {
            title: duplicationMode ? (
                <FormattedMessage defaultMessage="Dupliquer le post sur" />
            ) : (
                <FormattedMessage defaultMessage="Créer un post" />
            ),
            content: (
                <PlatformCards>
                    {cards.map((card) => (
                        <PlatformCard
                            key={card.id}
                            {...card}
                            onSelect={() => {
                                setPlatform(card);
                                if (duplicationMode) {
                                    createDummyPost(editedPost.post.podcast.id, card.platformId);
                                    return;
                                }
                                setCurrentStep(STEPS.SELECT_EPISODE);
                            }}
                        />
                    ))}
                </PlatformCards>
            ),
        },
        [STEPS.SELECT_EPISODE]: {
            title: <FormattedMessage defaultMessage="Choisir l'épisode" />,
            content: (
                <SelectEpisodeWrapper>
                    <SelectEpisode
                        label={<FormattedMessage defaultMessage="Épisode à partager" />}
                        setSelectedEpisode={setEpisode}
                        selectedEpisode={episode}
                    />
                    <ActionWrapper>
                        <Button
                            isDisabled={!episode}
                            onPress={() => createDummyPost(episode, platform?.platformId)}
                        >
                            <FormattedMessage defaultMessage="Continuer" />
                        </Button>
                    </ActionWrapper>
                </SelectEpisodeWrapper>
            ),
        },
        [STEPS.CREATE_POST]: {
            title: (
                <FormattedMessage
                    defaultMessage="Nouveau post sur {platform}"
                    values={{ platform: platform?.platformName }}
                />
            ),
            content: (
                <CreateOrUpdatePost
                    post={post}
                    platformId={editedPost?.post?.provider || platform?.platformId}
                    episodeId={editedPost?.post?.podcast?.id || episode}
                    close={() => handleClose()}
                />
            ),
        },
    };

    if (linkedPlatforms.isLoading) {
        return null;
    }

    return (
        <Dialog
            title={stepsLookup[currentStep].title}
            // Allows isDismissable only on first step. If isDismissable active for SELECT_EPISODE step, selection in dropdown closes dialog.
            // If isDismissable active for CREATE_POST step, selection in X mentions list closes dialog.
            isDismissable={currentStep === STEPS.SELECT_PLATFORM}
            size={currentStep === STEPS.CREATE_POST ? 'large' : 'medium'}
            isOpen={isOpen}
            onOpenChange={handleClose}
            disableScroll={
                currentStep === STEPS.SELECT_EPISODE ||
                (isTabletOrDesktop && currentStep === STEPS.SELECT_PLATFORM)
            }
        >
            {stepsLookup[currentStep].content}
        </Dialog>
    );
};

const PlatformCards = styled.div`
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 9.75rem);
    width: 100%;

    ${(p) => p.theme.mediaQueries.desktopLargeAndUp} {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 9.75rem);
    }
`;
const SelectEpisodeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
    max-width: 40rem;
`;
const ActionWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    width: 100%;
`;

AddPostModal.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    editedPost: PropTypes.object,
};

export default AddPostModal;
