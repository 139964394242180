import Model from './Model';

class InstagramAccountModel extends Model {
    static attributes = {
        id: 'id',
        username: 'username',
        profilePictureUrl: 'profile_picture_url',
    };
}

export default InstagramAccountModel;
