import { useState } from 'react';
import { useResponsive } from '@hooks/useResponsive';
import PlatformIcon from '@app/atoms/PlatformIcon';
import InputText from '@ui/atoms/InputText';
import Button from '@ui/atoms/Button';
import Text from '@ui/atoms/Text';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import useDeleteEpisodeListeningLinkMutation from '@/queries/episodeListeningLink/useDeleteEpisodeListeningLinkMutation.hook';
import useUpdateEpisodeListeningLinkMutation from '@/queries/episodeListeningLink/useUpdateEpisodeListeningLinkMutation.hook';
import PropTypes from 'prop-types';
import Alert from '@/components/ui/atoms/Alert';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconButton from '@/components/ui/atoms/IconButton';
import OutsideClickHandler from 'react-outside-click-handler/build/OutsideClickHandler';
import Spinner from '@/components/ui/atoms/Spinner';

const ListeningLink = ({
    link,
    episodeId,
    currentlyEditedLink,
    setCurrentlyEditedLink,
    updatedUrl,
    setUpdatedUrl,
    onChange,
}) => {
    const { isMobileOrTablet } = useResponsive();
    const updateLink = useUpdateEpisodeListeningLinkMutation();
    const deleteLink = useDeleteEpisodeListeningLinkMutation();

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [urlInputState, setUrlInputState] = useState(false);

    const isBeingFetched = !link.url;
    const isCurrentlyEdited = currentlyEditedLink === link.key;

    const checkUrl = (value) => {
        const expression =
            /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
        const urlRegex = new RegExp(expression);
        setUrlInputState(urlRegex.test(value) ? 'valid' : 'errors');
    };
    const triggerUpdateLink = () => {
        setCurrentlyEditedLink(link.key);
        setUpdatedUrl(link.url);
    };
    const handleToggleMenu = () => setIsMenuOpen((isOpen) => !isOpen);
    const handleCloseMenu = () => setIsMenuOpen(false);
    const handleDeleteLink = () => {
        deleteLink.mutate(
            {
                episodeId,
                key: currentlyEditedLink,
            },
            {
                onSettled: () => {
                    onChange();
                },
            },
        );
        handleCloseMenu();
    };

    return (
        <ListeningLinkWrapper currentlyEdited={isCurrentlyEdited}>
            <PlatformIconWrapper>
                <PlatformIcon platform={link.key} />
            </PlatformIconWrapper>
            <Left currentlyEdited={isCurrentlyEdited}>
                <TextContent>
                    <TitleAndMenuButtonWrapper>
                        <Text fontWeight="--fw-semibold">{link.label}</Text>
                        {!isBeingFetched && isCurrentlyEdited && (
                            /* TODO: Use new Menu component */
                            <OutsideClickHandler onOutsideClick={handleCloseMenu}>
                                <MenuWrapper>
                                    <MenuButton
                                        onPress={handleToggleMenu}
                                        variant="ghost"
                                        icon={
                                            <EllipsisIcon
                                                icon={icon({ name: 'ellipsis', style: 'solid' })}
                                            />
                                        }
                                        aria-label="Episode audio file options"
                                    />
                                    {isMenuOpen && (
                                        <Menu>
                                            <MenuItem onClick={handleDeleteLink}>
                                                <AlertIcon
                                                    icon={icon({ name: 'trash', style: 'solid' })}
                                                />
                                                <Text color="--alert500">
                                                    <FormattedMessage defaultMessage="Supprimer" />
                                                </Text>
                                            </MenuItem>
                                        </Menu>
                                    )}
                                </MenuWrapper>
                            </OutsideClickHandler>
                        )}
                    </TitleAndMenuButtonWrapper>
                    {!isBeingFetched && isCurrentlyEdited ? (
                        <InputWrapper>
                            <InputText
                                value={updatedUrl}
                                onChange={(value) => {
                                    setUpdatedUrl(value);
                                    checkUrl(value);
                                }}
                                inputState={urlInputState}
                                errorMessage={
                                    urlInputState === 'errors' && (
                                        <FormattedMessage defaultMessage="Votre saisie ne correspond pas à une URL valide" />
                                    )
                                }
                                onKeyUp={(event) => event.key === 'Enter' && triggerUpdateLink()}
                            />
                            <CustomButton
                                variant="primary"
                                isDisabled={urlInputState === 'errors'}
                                onPress={() =>
                                    updateLink.mutate(
                                        {
                                            episodeId,
                                            key: currentlyEditedLink,
                                            url: updatedUrl,
                                        },
                                        {
                                            onSettled: () => {
                                                setUpdatedUrl(null);
                                                setCurrentlyEditedLink(null);
                                                onChange();
                                            },
                                        },
                                    )
                                }
                            >
                                <FormattedMessage defaultMessage="Enregistrer" />
                            </CustomButton>
                        </InputWrapper>
                    ) : !isMobileOrTablet && isBeingFetched ? (
                        <CustomText color="--neutral500">
                            <FormattedMessage defaultMessage="En cours de récupération" />
                        </CustomText>
                    ) : (
                        <CustomText color="--neutral500">{link.url}</CustomText>
                    )}
                    {isCurrentlyEdited && (
                        <WarningAlert
                            variant="warning"
                            icon={
                                <WarningIcon
                                    icon={icon({ name: 'triangle-exclamation', style: 'solid' })}
                                />
                            }
                        >
                            <Text fontWeight="--fw-semibold">
                                <FormattedMessage defaultMessage="Cette modification peut rendre inaccessible votre épisode sur les outils Ausha, n’éditez le lien que s’il y a une erreur." />
                            </Text>
                        </WarningAlert>
                    )}
                </TextContent>
            </Left>
            {isBeingFetched ? (
                <Spinner />
            ) : (
                currentlyEditedLink !== link.key && (
                    <EditButton variant="ghost" onPress={() => triggerUpdateLink()}>
                        <FormattedMessage defaultMessage="Éditer" />
                    </EditButton>
                )
            )}
        </ListeningLinkWrapper>
    );
};

const ListeningLinkWrapper = styled.li`
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 1rem;
    background: var(--neutral50);
    border-radius: var(--r-m);
    padding-inline: 1.5rem;
    padding-block: 1rem;

    ${(p) =>
        p.currentlyEdited &&
        css`
            background: white;
            border: 1px solid var(--neutral200);
            align-items: flex-start;

            & ${TextContent} {
                row-gap: 0.5rem;
            }
        `}
`;
const TitleAndMenuButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const TextContent = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
`;
const WarningIcon = styled(FontAwesomeIcon)`
    width: 1.5rem;
    height: 1.5rem;
    color: var(--warning500);
`;
const Left = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    column-gap: 1rem;
    flex-grow: 1;

    ${(p) => p.theme.mediaQueries.mobile} {
        ${(p) =>
            p.currentlyEdited &&
            css`
                flex-direction: column;
            `}
    }
`;
const PlatformIconWrapper = styled.div`
    & img {
        display: block;
    }
`;
const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;

    ${(p) => p.theme.mediaQueries.tabletAndUp} {
        flex-direction: row;
        align-items: center;
        column-gap: 0.5rem;
        row-gap: 0;
    }
`;
const MenuItemIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const AlertIcon = styled(MenuItemIcon)`
    color: var(--alert500);
`;
const CustomText = styled(Text)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;

    ${(p) => p.theme.mediaQueries.tabletAndUp} {
        max-width: 34rem;
    }
`;
const CustomButton = styled(Button)`
    box-shadow: var(--s-primary);
    ${(p) => p.theme.mediaQueries.mobile} {
        width: 100%;
    }
`;
const EllipsisIcon = styled(FontAwesomeIcon)`
    width: 100%;
    height: 100%;
    color: var(--neutral500);
`;
const MenuButton = styled(IconButton)`
    padding: 0;
    width: 1rem;
    height: 1rem;
`;
const WarningAlert = styled(Alert)`
    margin-top: 0.5rem;
`;
const Menu = styled.div`
    width: 10rem;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 2rem;
    right: 0;
    padding: 0.5rem;
    background-color: var(--white);
    border-radius: var(--r-s);
    box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.1);
    z-index: 1;
`;
const MenuWrapper = styled.div`
    position: relative;
`;
const MenuItem = styled.button`
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    padding: 0.5rem;
    border-radius: var(--r-s);
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: var(--black);
    text-decoration: none;

    &:hover {
        background-color: var(--neutral50);
    }
`;
const EditButton = styled(Button)`
    padding: 0;
    color: var(--primary);
`;

ListeningLink.propTypes = {
    link: PropTypes.shape({
        key: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        url: PropTypes.string,
    }),
    episodeId: PropTypes.string.isRequired,
    currentlyEditedLink: PropTypes.string,
    setCurrentlyEditedLink: PropTypes.func.isRequired,
    updatedUrl: PropTypes.string,
    setUpdatedUrl: PropTypes.func.isRequired,
    onChange: PropTypes.func,
};

export default ListeningLink;
