import { InputTextController } from '@/components/ui/atoms/InputText';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import useApplePodcastsTokenFormSchema, { ApplePodcastsTokenFormSchema } from './useFormSchema';
import { zodResolver } from '@hookform/resolvers/zod';
import styled from 'styled-components';
import Button from '@/components/Button';
import { TooltipTrigger } from 'react-aria-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';

interface ApplePodcastsTokenFormProps {
    token: string | null;
    onSubmit: (formData: ApplePodcastsTokenFormSchema) => void;
    isLoading: boolean;
}

const ApplePodcastsTokenForm = ({ token, onSubmit, isLoading }: ApplePodcastsTokenFormProps) => {
    const schema = useApplePodcastsTokenFormSchema();
    const { control, formState, handleSubmit } = useForm<ApplePodcastsTokenFormSchema>({
        mode: 'onBlur',
        resolver: zodResolver(schema),
        values: { token },
    });
    const intl = useIntl();

    const { isDirty, isValid } = formState;

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <InputTextController
                name="token"
                control={control}
                label={<FormattedMessage defaultMessage="Jeton Apple Podcasts" />}
                placeholder="ABC-DEF-123"
                tooltip={
                    <TooltipTrigger delay={0} closeDelay={0}>
                        <TooltipTriggerWrapper>
                            <TooltipInfoIcon icon={icon({ name: 'circle-info', style: 'solid' })} />
                        </TooltipTriggerWrapper>
                        <Tooltip placement="top">
                            <FormattedMessage
                                defaultMessage="Le jeton Apple Podcasts vous permet de revendiquer votre podcast et ainsi d’accéder à toutes les statistiques disponibles dans votre tableau de bord Apple Podcasts Connect. <link>Où trouver votre jeton ?</link>"
                                values={{
                                    link: (chunks: string) => (
                                        <ApplePodcastsLink
                                            href={intl.formatMessage({
                                                defaultMessage:
                                                    'https://help.ausha.co/fr/articles/10521111-comment-revendiquer-la-propriete-de-mon-podcast-et-acceder-a-ses-donnees-sur-l-interface-apple-podcasts-connect',
                                            })}
                                            target="_blank"
                                        >
                                            {chunks}
                                        </ApplePodcastsLink>
                                    ),
                                }}
                            />
                        </Tooltip>
                    </TooltipTrigger>
                }
            />
            <SubmitButton type="submit" isDisabled={!isDirty || !isValid} isLoading={isLoading}>
                <FormattedMessage defaultMessage="Enregistrer" />
            </SubmitButton>
        </Form>
    );
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
`;
const TooltipInfoIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
`;
const ApplePodcastsLink = styled.a`
    color: var(--white);
    text-decoration: underline;
`;
const SubmitButton = styled(Button)`
    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        align-self: flex-start;
    }
`;

export default ApplePodcastsTokenForm;
