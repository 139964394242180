import { FC, useState, createContext, useContext, ReactNode } from 'react';

interface TApplePodcastsStatsContext {
    category: string;
    setCategory: (category: string) => void;
    precision: string;
    setPrecision: (precision: string) => void;
}

const ApplePodcastsStatsContext = createContext<TApplePodcastsStatsContext | undefined>(undefined);

interface ApplePodcastsStatsContextProviderProps {
    children: ReactNode;
}

export const ApplePodcastsStatsContextProvider: FC<ApplePodcastsStatsContextProviderProps> = ({
    children,
}) => {
    const [category, setCategory] = useState('');
    const [precision, setPrecision] = useState('days');

    const contextValue = {
        category,
        setCategory,
        precision,
        setPrecision,
    };

    return (
        <ApplePodcastsStatsContext.Provider value={contextValue}>
            {children}
        </ApplePodcastsStatsContext.Provider>
    );
};

export const useApplePodcastsStatsContext = () => {
    const context = useContext(ApplePodcastsStatsContext);
    if (context === undefined) {
        throw new Error(
            'useApplePodcastsStatsContext must be used within a ApplePodcastsStatsContextProvider',
        );
    }
    return context;
};
