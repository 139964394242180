import dayjs from 'dayjs';
import Model from './Model';
class PeakTimeCurrentMomentsModel extends Model {
    static attributes = {
        categories: {
            fromApi: {
                key: 'categories',
                resolve(categories) {
                    const data = categories.map((c) => ({
                        from: c.from,
                        to: c.to,
                    }));
                    return data;
                },
            },
        },
        mapData: {
            fromApi: {
                key: 'map_data',
                resolve(mapData) {
                    const data = mapData.map((c) => {
                        return {
                            downloads: c.downloads,
                            dayHour: dayjs(dayjs().hour(c.day_hour)),
                            weekdDay: dayjs(dayjs().isoWeekday(c.week_day)).format('ddd'),
                        };
                    });
                    return data;
                },
            },
        },
        maxDownloads: 'max_downloads',
    };
}

export default PeakTimeCurrentMomentsModel;
