import { connect } from '@app/decorators/connect';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import PlaylistEpisodeLine from '@app/molecules/PlaylistEpisodeLine';
import ShortListFallback from '@app/fallbacks/ShortListFallback';
import DeprecatedWhitePaper from '@ui/atoms/DeprecatedWhitePaper';
import PlaylistEpisodesEmptyState from './PlaylistEpisodesEmptyState';

const enhance = connect(({ playlistStore, routerStore }) => {
    let playlist = routerStore.resolve('playlistId');
    if (!playlist && playlistStore.playlists.length > 0) {
        playlist = playlistStore.playlists[0];
    }
    return {
        episodes: playlistStore.podcasts,
        playlistName: playlist && playlist.name,
        onDragEnd: ({ source, destination }) =>
            playlist && playlist.moveEpisode(source, destination),
    };
});

function PlaylistEpisodes({ episodes, isLoading, playlistName, onDragEnd }) {
    if (isLoading) {
        return <ShortListFallback />;
    }

    if (episodes.length === 0) {
        return <PlaylistEpisodesEmptyState playlistName={playlistName} />;
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="playlist">
                {(provided) => (
                    <DeprecatedPaper pb={50}>
                        <DeprecatedWhitePaper
                            overflowHide
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {episodes.map((episode, index) => (
                                <PlaylistEpisodeLine
                                    key={episode.playlistPodcastId}
                                    podcast={episode}
                                    index={index}
                                />
                            ))}
                        </DeprecatedWhitePaper>
                    </DeprecatedPaper>
                )}
            </Droppable>
        </DragDropContext>
    );
}

export default enhance(PlaylistEpisodes);
