import styled from 'styled-components';
import posed, { PoseGroup } from 'react-pose';
import { useDroppable } from '@/utils/hooks/useDroppable';
import Portal from '@ui/molecules/Portal';
import Desktop from '@ui/molecules/Desktop';
import MobileOrTablet from '@ui/molecules/MobileOrTablet';
import closeBack from '@public/icons/close.svg';
import DeprecatedPaper from '../DeprecatedPaper';
import DeprecatedWhitePaper from '../DeprecatedWhitePaper';
import DeprecatedIcon from '../DeprecatedIcon';

const Li = styled(DeprecatedPaper)`
    > * {
        font-size: var(--fs-subheading);
        display: flex;
        margin: 0;
        padding: 10px 0;
        > * {
            font-size: var(--fs-subheading);
        }
        &:before {
            content: '';
            display: none;
        }
    }
`.withComponent('li');

const transition = { duration: 300 };

const Container = styled(DeprecatedPaper)`
    right: 0;
    overflow-y: auto;
    max-height: 300px;
    border-bottom-left-radius: var(--r-xs);
    border-bottom-right-radius: var(--r-xs);
`;

const PosedPaper = posed(Container)({
    enter: { opacity: 1, height: 'auto', y: 1, transition },
    exit: { opacity: 0.6, height: 0, y: -5, transition },
});

function VerticalDropContainer({ isOpened, children, useVerticalDropMobile = true, ...props }) {
    const [ref, opened, open] = useDroppable();
    const VerticalDropDown = () => {
        if (!useVerticalDropMobile) return null;
        return (
            <PoseGroup>
                <DeprecatedPaper
                    pos="absolute"
                    x="initial"
                    xR={20}
                    y={39}
                    key="title"
                    w="max-content"
                    align="center"
                    onClick={open}
                    justify="flex-end"
                    {...props}
                >
                    {props.currentTab}
                </DeprecatedPaper>
                {opened && (
                    <Portal id="verticalDropDown" key="verticalDropDown">
                        <DeprecatedWhitePaper
                            bb={1}
                            rounded={false}
                            w="100%"
                            pos="fixed"
                            y={0}
                            z={1001}
                            px={30}
                            py={20}
                            flex
                            align="center"
                        >
                            <DeprecatedIcon as={closeBack} color="--black" />
                        </DeprecatedWhitePaper>
                        <DeprecatedPaper
                            key={0}
                            ref={ref}
                            pos="fixed"
                            y={51}
                            x={0}
                            w="100vw"
                            h="100vh"
                            p={30}
                            z={1001}
                            background="white"
                            raise="normal"
                            overflowY="scroll"
                            as="ul"
                            css="list-style: none;"
                        >
                            {children.map((p) => (
                                <Li key={p} onClick={open}>
                                    {p}
                                </Li>
                            ))}
                        </DeprecatedPaper>
                    </Portal>
                )}
            </PoseGroup>
        );
    };

    return (
        <>
            <MobileOrTablet>
                <VerticalDropDown />
            </MobileOrTablet>
            <Desktop>
                <PoseGroup>
                    {isOpened && (
                        <PosedPaper
                            key={0}
                            position="absolute"
                            y="calc(100% - 1px)"
                            py={10}
                            x={0}
                            w={280}
                            z={2}
                            background="white"
                            raise="smooth"
                            {...props}
                        >
                            {children}
                        </PosedPaper>
                    )}
                </PoseGroup>
            </Desktop>
        </>
    );
}

export default VerticalDropContainer;
