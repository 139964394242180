import { FormattedMessage } from 'react-intl';
import DeprecatedText from '../../ui/atoms/DeprecatedText';
import DeprecatedButton from '../../ui/atoms/DeprecatedButton';
import { connect } from '../decorators/connect';
import FetcherShowTemplate from '../fetchers/FetcherShowTemplate';
import ShortListFallback from '../fallbacks/ShortListFallback';
import Form from '../Form';
import Spinner from '../../ui/atoms/Spinner';
import TemplateEditor from './TemplateEditor';
import ShowSharingTemplateForm from '../../../forms/ShowSharingTemplateForm';
import DeprecatedPaper from '../../ui/atoms/DeprecatedPaper';
import useDeleteShowTemplateMutation from '@/queries/showTemplate/useDeleteShowTemplateMutation.hook';

const enhance = connect(({ routerStore, autoSharingStore }) => {
    const template = autoSharingStore.linkedinShowTemplate;
    return {
        showId: routerStore.params.showId,
        template,
        onSubmit: (formData) =>
            template.updateShowLinkedinTemplate(routerStore.params.showId, formData),
        isLoading: template && template.updateShowLinkedinTemplate.pending,
    };
});

function LinkedInTemplateShow({ onSubmit, isLoading, template, headerProvider, showId, account }) {
    const deleteShowTemplate = useDeleteShowTemplateMutation();
    return (
        <FetcherShowTemplate fallback={ShortListFallback} provider="linkedin" showId={showId}>
            {(isFetching) =>
                !isFetching &&
                template && (
                    <Form form={() => ShowSharingTemplateForm(template)} onSubmit={onSubmit}>
                        {({ fields }) => (
                            <>
                                <DeprecatedPaper flex justify="space-between">
                                    {headerProvider}
                                    <DeprecatedPaper flex align="flex-end" column>
                                        {account && (
                                            <DeprecatedText weight="semibold">
                                                @{account.name}
                                            </DeprecatedText>
                                        )}
                                        <DeprecatedText
                                            weight="semibold"
                                            color="--primary"
                                            onClick={() => {
                                                deleteShowTemplate.mutate({
                                                    showId,
                                                    provider: 'linkedin',
                                                });
                                            }}
                                            disabled={template.unlinkLinkedin.pending}
                                        >
                                            <FormattedMessage defaultMessage="Délier le compte" />
                                        </DeprecatedText>
                                    </DeprecatedPaper>
                                </DeprecatedPaper>
                                <TemplateEditor field={fields.text} />
                                <DeprecatedButton
                                    mt={30}
                                    mx="auto"
                                    type="submit"
                                    disabled={isLoading}
                                    background="linkedin"
                                >
                                    {isLoading && <Spinner />}
                                    <FormattedMessage defaultMessage="Enregistrer" />
                                </DeprecatedButton>
                            </>
                        )}
                    </Form>
                )
            }
        </FetcherShowTemplate>
    );
}

export default enhance(LinkedInTemplateShow);
