import Text from '@/components/ui/atoms/Text';
import useChannelQuery from '@/queries/channel/useChannelQuery.hook';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const ChannelGuestsQuota = () => {
    const { data: channel } = useChannelQuery();
    const hasUnlimitedQuota = channel?.availableInvitationsNumberMax === null;
    const currentGuestsNumber =
        (channel?.availableInvitationsNumberMax ?? 0) - (channel?.availableInvitationsNumber ?? 0);
    const isQuotaReached = channel?.availableInvitationsNumber === 0;

    // TODO: Display specific message when quota is unlimited ???
    if (hasUnlimitedQuota) return null;

    return (
        <Quota $isReached={isQuotaReached}>
            <UsersIcon icon={icon({ name: 'user-group', style: 'solid' })} />
            <Text fontWeight="--fw-semibold" color="inherit">
                {currentGuestsNumber}/{channel?.availableInvitationsNumberMax ?? 0}
            </Text>
        </Quota>
    );
};

const Quota = styled.div<{ $isReached: boolean }>`
    height: 2.5rem;
    padding-inline: 0.75rem;
    border-radius: var(--r-s);
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    background-color: var(--neutral50);
    color: var(--neutral500);

    ${({ $isReached }) =>
        $isReached &&
        `
        background-color: var(--alert50);
        color: var(--alert500);
    `}
`;
const UsersIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;

export default ChannelGuestsQuota;
