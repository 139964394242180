import ListBoxItem from '@/components/ui/atoms/ListBoxItem';
import { SelectController } from '@/components/ui/atoms/Select';
import { FacebookPage } from '@/queries/provider/useFacebookProviderQuery';
import { FacebookShowTemplate } from '@/queries/showTemplate/useFacebookShowTemplateQuery';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import useFacebookTemplateFormSchema, { FacebookTemplateFormSchema } from './useFormSchema';
import { zodResolver } from '@hookform/resolvers/zod';
import TemplateEditor from '../../TemplateEditor';
import Button from '@/components/Button';

interface TemplateFormProps {
    pages: FacebookPage[];
    template?: FacebookShowTemplate;
    isLoading: boolean;
    onSubmit: (formData: FacebookTemplateFormSchema) => void;
}

const TemplateForm = ({ pages, template, onSubmit, isLoading }: TemplateFormProps) => {
    const schema = useFacebookTemplateFormSchema();
    const { control, watch, setValue, handleSubmit } = useForm<FacebookTemplateFormSchema>({
        mode: 'onBlur',
        resolver: zodResolver(schema),
        values: {
            pageId: template?.pageId ?? pages[0].id,
            messageTpl: template?.messageTpl ?? '',
        },
    });
    const messageTpl = watch('messageTpl');

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <FullWidthSelectController
                name="pageId"
                control={control}
                items={pages}
                label={<FormattedMessage defaultMessage="Publier sur la page" />}
            >
                {(item) => <ListBoxItem {...item} />}
            </FullWidthSelectController>
            <TemplateEditor
                label={<FormattedMessage defaultMessage="Texte prédéfini" />}
                description={
                    <FormattedMessage defaultMessage="Lorsque vous configurerez un nouveau post, ce texte sera déjà saisi. Vous pourrez toujours le modifier par la suite." />
                }
                value={messageTpl}
                onChange={(value) =>
                    setValue('messageTpl', value, { shouldValidate: true, shouldDirty: true })
                }
            />
            <SubmitButton type="submit" isLoading={isLoading}>
                <FormattedMessage defaultMessage="Enregistrer" />
            </SubmitButton>
        </Form>
    );
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
`;
const FullWidthSelectController = styled(SelectController)`
    max-width: 100%;
`;
const SubmitButton = styled(Button)`
    margin-top: 1rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        align-self: center;
    }
`;

export default TemplateForm;
