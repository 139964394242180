import Stack from '@ui/layout/Stack';
import { FormattedMessage, useIntl } from 'react-intl';
import { InputTextController } from '@ui/atoms/InputText';
import useLoginFormSchema, { LoginForm } from './useLoginFormSchema.hook';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import Button from '@/components/Button';
import useClubLoginMutation from '@queries/auth/useClubLoginMutation.hook';
import styled from 'styled-components';

interface FormProps {
    onAuthenticate: () => void;
}

const Form = ({ onAuthenticate }: FormProps) => {
    const intl = useIntl();
    const schema = useLoginFormSchema();
    const clubLogin = useClubLoginMutation();

    const { control, handleSubmit, formState } = useForm({
        defaultValues: {
            email: '',
            password: '',
        },
        resolver: zodResolver(schema),
    });

    const onSubmit = (payload: LoginForm) => {
        clubLogin.mutate(
            {
                formData: payload,
            },
            {
                onSuccess: () => {
                    onAuthenticate();
                },
            },
        );
    };

    return (
        <FormElement onSubmit={handleSubmit(onSubmit)}>
            <Stack $gap="2rem">
                <InputTextController
                    control={control}
                    name="email"
                    label={<FormattedMessage defaultMessage="E-mail de votre compte Ausha" />}
                    placeholder={intl.formatMessage({
                        defaultMessage: 'thomas.pesquet@esa.com',
                    })}
                    errorMessage={formState.errors.email?.message}
                    data-testid="club-login-email-input"
                    autoFocus
                />
                <InputTextController
                    control={control}
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    label={<FormattedMessage defaultMessage="Mot de passe de votre compte Ausha" />}
                    placeholder={intl.formatMessage({ defaultMessage: '8 caractères minimum' })}
                    errorMessage={formState.errors.password?.message}
                    data-testid="club-login-password-input"
                />
                <Button
                    type="submit"
                    isLoading={clubLogin.isLoading}
                    data-testid="club-login-submit"
                >
                    <FormattedMessage defaultMessage="Se connecter" />
                </Button>
            </Stack>
        </FormElement>
    );
};

const FormElement = styled.form`
    display: flex;
    flex-direction: column;
`;

export default Form;
