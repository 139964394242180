import Text from '@ui/atoms/Text';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Button from '@/components/Button';
import { useAccordion } from '@ui/atoms/Accordion/useAccordion.hook';

const PRIVACY_INDEX = 2;

const NewsletterNotAllowed = () => {
    const { goToIndex } = useAccordion();

    const openPrivacySection = () => {
        goToIndex(PRIVACY_INDEX);
    };

    return (
        <Container>
            <Text variant="bodyL" fontWeight="--fw-semibold">
                <FormattedMessage
                    defaultMessage="Pour configurer et envoyer la newsletter de cet épisode, votre épisode doit être <strong>public</strong>. Pour cela, rendez-vous dans l’onglet <openSection>Confidentialité</openSection> ci-dessus."
                    values={{
                        strong: (chunks: React.ReactNode) => <strong>{chunks}</strong>,
                        openSection: (chunks: React.ReactNode) => (
                            <OpenPrivacySectionButton onPress={openPrivacySection}>
                                {chunks}
                            </OpenPrivacySectionButton>
                        ),
                    }}
                />
            </Text>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    align-items: start;
    padding: 1.5rem;
    background-color: var(--primary50);
    border-radius: var(--r-m);
    position: relative;
    overflow: hidden;

    & > p {
        max-width: 37.5rem;
        z-index: 1;
    }
`;

const OpenPrivacySectionButton = styled(Button)`
    padding: 0;
    background: none;
    border: none;
    box-shadow: none;
    color: inherit;

    .button-text {
        font-size: inherit;
        line-height: inherit;
    }
`;

export default NewsletterNotAllowed;
