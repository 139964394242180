import * as yup from 'yup';
import { useIntl } from 'react-intl';

const useRssExportFormSchema = () => {
    const intl = useIntl();
    return yup.object({
        subtitle: yup.string().max(255).nullable(),
        website: yup
            .string()
            .url(
                intl.formatMessage({
                    defaultMessage: 'Ce champ doit contenir une url valide',
                }),
            )
            .nullable(),
        copyright: yup.string().nullable(),
        ownerName: yup.string().nullable(),
        ownerEmail: yup
            .string()
            .email(
                intl.formatMessage({
                    defaultMessage: 'Ce champ doit être une adresse e-mail valide',
                }),
            )
            .nullable(),
        isExplicit: yup.boolean(),
        hasTracking: yup.boolean(),
    });
};

export default useRssExportFormSchema;
