import styled from 'styled-components';
import { useRef } from 'react';
import { useNumberFormatter, useSlider, useSliderThumb, VisuallyHidden } from 'react-aria';
import { useSliderState } from 'react-stately';
import PropTypes from 'prop-types';

const StyledThumb = styled.div`
    width: 0.5rem;
    height: 1rem;
    border-radius: var(--r-full);
    background: var(--primary);
    top: 50%;
    position: relative; // Use this because of VisuallyHidden styles causing issues with the input position
`;

const Thumb = (props) => {
    const { state, trackRef, index } = props;
    const inputRef = useRef(null);
    const { thumbProps, inputProps } = useSliderThumb(
        {
            index,
            trackRef,
            inputRef,
        },
        state,
    );

    return (
        <StyledThumb {...thumbProps}>
            <VisuallyHidden>
                <input ref={inputRef} {...inputProps} />
            </VisuallyHidden>
        </StyledThumb>
    );
};

const Track = styled.div`
    height: 1rem;
    opacity: ${(props) => (props.isDisabled ? 0.5 : null)};

    &:before {
        content: attr(x);
        display: block;
        position: absolute;
        background: var(--primary100);
        height: 0.25rem;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
        border-radius: var(--r-full);
    }
`;

const Slider = (props) => {
    const trackRef = useRef(null);
    const numberFormatter = useNumberFormatter(props.formatOptions);
    const state = useSliderState({ ...props, numberFormatter });
    const { trackProps } = useSlider(props, state, trackRef);

    return (
        <Track {...trackProps} ref={trackRef} isDisabled={state.isDisabled}>
            <Thumb index={0} state={state} trackRef={trackRef} />
        </Track>
    );
};

Slider.propTypes = {
    isDisabled: PropTypes.bool, // Whether the whole Slider is disabled.
    onChange: PropTypes.func, // Handler that is called when the value changes.
    onChangeEnd: PropTypes.func, // Fired when the slider stops moving, due to being let go.
    minValue: PropTypes.number, // The slider's minimum value.
    maxValue: PropTypes.number, // The slider's maximum value.
    step: PropTypes.number, // The slider's step value.
    value: PropTypes.number, // The current value (controlled).
    defaultValue: PropTypes.number, // The default value (uncontrolled).
    formatOptions: PropTypes.object, // Options for formatting the value as a string.
};

export default Slider;
