import { IntlProvider as ReactIntlProvider } from 'react-intl';
import { observer } from 'mobx-react';
import useUserStore from '../../../utils/hooks/useUserStore';
import messagesFr from '../../../../lang/fr.json';
import messagesEn from '../../../../lang/en.json';
import { I18nProvider } from 'react-aria';

const loadLocaleData = (locale) => {
    // TODO: Improve using dynamic imports
    switch (locale) {
        case 'en':
            return messagesEn;
        default:
            return messagesFr;
    }
};

const IntlProvider = observer(({ children }) => {
    const { locale } = useUserStore();
    const messages = loadLocaleData(locale);

    return (
        <ReactIntlProvider locale={locale} defaultLocale="fr" messages={messages}>
            <I18nProvider locale={locale}>{children}</I18nProvider>
        </ReactIntlProvider>
    );
});

export default IntlProvider;
