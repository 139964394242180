import useShowNewsletterTemplateQuery from '@/queries/newsletter/useShowNewsletterTemplateQuery.hook';
import useSmartlinkSettingsQuery from '@/queries/smartlinkSetting/useSmartlinkSettingsQuery.hook';
import useWebsiteSettingsQuery from '@/queries/websiteSettings/useWebsiteSettingsQuery.hook';
import { useParams } from 'react-router';
import CallToActionForm from '../CallToActionForm';
import { useBodyToastQueue } from '@/shared/hooks/useBodyToastQueue.hook';
import useAddCallToActionButtonMutation from '@/queries/callToAction/useAddCallToActionButtonMutation.hook';
import { CallToActionFormSchema } from '../CallToActionForm/useCallToActionFormSchema.hook';
import CallToActionFormSkeleton from '../CallToActionForm/CallToActionFormSkeleton';

interface AddCallToActionProps {
    onClose: () => void;
    callToAction: CallToActionFormSchema;
}

const AddCallToAction = ({ onClose, callToAction }: AddCallToActionProps) => {
    const { showId } = useParams<{ showId: string }>();
    const websiteSettings = useWebsiteSettingsQuery({ showId });
    const smartlinkSettings = useSmartlinkSettingsQuery(showId);
    const newsletterSettings = useShowNewsletterTemplateQuery(showId);
    const toast = useBodyToastQueue();
    const addCallToAction = useAddCallToActionButtonMutation();
    const isError =
        websiteSettings.isError || smartlinkSettings.isError || newsletterSettings.isError;
    const hasData = websiteSettings.data && smartlinkSettings.data && newsletterSettings.data;

    if (isError) {
        return <div>ERROR</div>; // TODO: Handle error state
    }

    if (hasData) {
        const isWebsiteEnabled = websiteSettings.data?.isPublished;
        const isSmartlinkEnabled = smartlinkSettings.data?.isPublished;
        const isNewsletterEnabled = newsletterSettings.data?.activated;
        const defaultValues = {
            text: callToAction?.text || '',
            url: callToAction?.url || '',
            locations: callToAction?.locations || [],
            type: callToAction?.type || 'custom',
        };

        const handleAddCallToAction = (data: CallToActionFormSchema) => {
            addCallToAction.mutate(
                {
                    showId,
                    payload: data,
                },
                {
                    onSuccess: () => {
                        toast.success();
                        onClose();
                    },
                    onError: () => {
                        toast.alert();
                    },
                },
            );
        };

        return (
            <CallToActionForm
                onClose={onClose}
                defaultValues={defaultValues}
                onSubmit={handleAddCallToAction}
                isLoading={addCallToAction.isLoading}
                isWebsiteEnabled={isWebsiteEnabled}
                isSmartlinkEnabled={isSmartlinkEnabled}
                isNewsletterEnabled={isNewsletterEnabled}
            />
        );
    }

    return <CallToActionFormSkeleton />;
};

export default AddCallToAction;
