import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';
import usePsoQuery from '@queries/pso/usePsoQuery.hook';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { usePsoCheckerContext } from '@/context/PsoCheckerContext';
import { useFormContext } from 'react-hook-form';
import Rule from '@/components/unorganized/EpisodeEdit/EpisodeEditSidebar/PsoCheckerTab/Rules/Rule';

interface TitleRulesProps {
    setScore: (score: number) => void;
}

const TitleRules = ({ setScore }: TitleRulesProps) => {
    const { showId }: { showId: string } = useParams();
    const psoConfiguration = usePsoQuery({ showId, enabled: true });
    const { targetKeyword, setPsoCheckerScore } = usePsoCheckerContext();
    const { watch } = useFormContext();
    const title = watch('content.name');

    if (psoConfiguration.isLoading || psoConfiguration.isError) {
        return null;
    }

    const getPonderatedCompletion = (score: number, ponderatedThresholds: number[]): number => {
        if (score > ponderatedThresholds.length - 1) {
            return ponderatedThresholds[ponderatedThresholds.length - 1];
        }
        if (score < 0) {
            return ponderatedThresholds[0];
        }
        return ponderatedThresholds[score];
    };

    const targetKeywordRegex = new RegExp(`\\b${targetKeyword}\\b`, 'i');

    const targetKeywordScore = () => {
        const cleanedupTitle = title.replace(/[^\w\s-_]/gi, '');
        return targetKeywordRegex.test(cleanedupTitle) ? 100 : 0;
    };

    const psoKeywordsScore = () => {
        const numberOfKeywordsFound = psoConfiguration.data.keywords.filter((k: string) =>
            title.toLowerCase().includes(k.toLowerCase()),
        ).length;
        return numberOfKeywordsFound > 0 ? 100 : 0;
    };

    const charactersScore = () => {
        const pointsMap = [0, 75, 100];
        const lengthMap = [0, 30, 50];

        let lengthIndex = 0;
        for (let i = 0; i < lengthMap.length; i++) {
            if (lengthMap[i] <= title.length) {
                lengthIndex = i;
            } else {
                break;
            }
        }

        return pointsMap[lengthIndex];
    };

    const wordsScore = () => {
        // A word is at least one character with possibly numbers or special characters
        // Two words separate by - is considered as a unique word
        // A word prefixed by ' and a determinant is considered as two separated words
        const numberOfWords = title
            .trim()
            .split(/[\s']/)
            .filter((word: string) => /\b[a-zA-Z0-9-]*[a-zA-Z]+[a-zA-Z0-9-]*\b/g.test(word)).length;

        return getPonderatedCompletion(numberOfWords, [0, 0, 0, 0, 50, 50, 50, 75, 75, 75, 100]);
    };

    const containEpisodeNumberScore = () => {
        return /(?:ep\.|#|episode|épisode|S\d+E\d+)\s*\d+|\d+\.\s+/i.test(title.toLowerCase())
            ? 0
            : 100;
    };

    const containEmojiScore = () => {
        return /\p{Extended_Pictographic}/u.test(title) ? 0 : 100;
    };

    const targetKeywordRuleCompletion = targetKeywordScore();
    const atLeastOnePSOKeywordsRuleCompletion = psoKeywordsScore();
    const mustContainsAtLeast50CharactersRuleCompletion = charactersScore();
    const atLeastTenWordsRuleCompletion = wordsScore();
    const dontContainNumberOfEpisodeRuleCompletion = containEpisodeNumberScore();
    const dontContainEmojiRuleCompletion = containEmojiScore();

    useEffect(() => {
        setScore(
            (targetKeywordRuleCompletion +
                atLeastOnePSOKeywordsRuleCompletion +
                mustContainsAtLeast50CharactersRuleCompletion +
                atLeastTenWordsRuleCompletion +
                dontContainNumberOfEpisodeRuleCompletion +
                dontContainEmojiRuleCompletion) /
                6,
        );
    }, [
        setPsoCheckerScore,
        targetKeywordRuleCompletion,
        atLeastOnePSOKeywordsRuleCompletion,
        mustContainsAtLeast50CharactersRuleCompletion,
        atLeastTenWordsRuleCompletion,
        dontContainNumberOfEpisodeRuleCompletion,
        dontContainEmojiRuleCompletion,
    ]);

    return (
        <Wrapper>
            <TitleWrapper>
                <FontAwesomeIcon icon={icon({ name: 'text', style: 'solid' })} />
                <FormattedMessage defaultMessage="Titre" />
            </TitleWrapper>
            <RulesWrapper>
                <Rule
                    label={<FormattedMessage defaultMessage="Inclure le mot-clé principal" />}
                    requiresTargetKeyword={true}
                    missingTargetKeyword={!targetKeyword}
                    completion={targetKeywordRuleCompletion}
                />
                <Rule
                    label={
                        <FormattedMessage defaultMessage="Inclure au moins 1 mot-clé de votre PSO Control Panel" />
                    }
                    completion={atLeastOnePSOKeywordsRuleCompletion}
                />
                <Rule
                    label={
                        <FormattedMessage defaultMessage="Le titre doit contenir au moins 50 caractères" />
                    }
                    completion={mustContainsAtLeast50CharactersRuleCompletion}
                />
                <Rule
                    label={
                        <FormattedMessage defaultMessage="Le titre doit contenir au moins 10 mots" />
                    }
                    completion={atLeastTenWordsRuleCompletion}
                />
                <Rule
                    label={
                        <FormattedMessage defaultMessage="Éviter d’indiquer le numéro de l’épisode" />
                    }
                    completion={dontContainNumberOfEpisodeRuleCompletion}
                />
                <Rule
                    label={<FormattedMessage defaultMessage="Éviter d’utiliser des emojis" />}
                    completion={dontContainEmojiRuleCompletion}
                />
            </RulesWrapper>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: var(--fs-m);
    font-weight: var(--fw-semibold);
    color: var(--black);
`;

const RulesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;

export default TitleRules;
