import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { psoLiveSearch } from '@/api';
import psoKeys from './psoKeys';
import { z } from 'zod';
import type { Platform, Mode } from '@/api/pso/types';

const LiveSearchItemsSchema = z.array(
    z.object({
        type: z.enum(['EPISODE', 'SHOW']),
        episode: z
            .object({
                id: z.string(),
                title: z.string(),
            })
            .nullable(),
        show: z.object({
            id: z.string(),
            title: z.string(),
            thumbnail: z.string(),
        }),
        ownedByShow: z.boolean(),
        author: z.string(),
        rating: z.number(),
        reviewCount: z.number(),
        link: z.string(),
    }),
);

export const LiveSearchSchema = z.object({
    kpis: z.object({
        resultCount: z.number(),
        searchVolume: z.number(),
        difficultyScore: z.number(),
    }),
    items: LiveSearchItemsSchema,
});

type InputProps = {
    showId: string;
    keyword: string;
    platform: Platform;
    store: string;
    mode: Mode;
    format?: string;
};
type Key = QueryFunctionContext<ReturnType<typeof psoKeys.liveSearch>>;
export type LiveSearch = z.infer<typeof LiveSearchSchema>;
export type LiveSearchItems = z.infer<typeof LiveSearchItemsSchema>;

const queryFn = async ({ queryKey: [{ showId, keyword, platform, store, mode, format }] }: Key) => {
    const response = await psoLiveSearch(showId, keyword, platform, store, mode, format);

    if (format === 'text/csv') {
        return response;
    }
    return LiveSearchSchema.parse(response);
};

const usePsoLiveSearchQuery = (
    { showId, keyword, platform, store, mode, format }: InputProps,
    { enabled } = { enabled: true },
) => {
    return useQuery({
        queryKey: psoKeys.liveSearch(showId, keyword, platform, store, mode, format),
        queryFn,
        enabled,
    });
};

export default usePsoLiveSearchQuery;
