import { ReactNode, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { DEBUG_EPISODE_EDIT_FORM_SAVE, IMG_TYPES } from '@/shared/config/constants';
import Stack from '@ui/layout/Stack';
import Text from '@ui/atoms/Text';
import styled from 'styled-components';
import useAddEpisodeYoutubeThumbnailMutation from '@queries/episode/useAddEpisodeYoutubeThumbnailMutation.hook';
import useDeleteYoutubeThumbnailImageMutation from '@queries/episode/useDeleteYoutubeThumbnailMutation.hook';
import { useParams } from 'react-router';
import DropImgUploader from '@/components/DropUploader/DropImgUploader';
import { checkImgDimensions, checkImgFormat, checkImgSize } from '@/helpers';
import useUserQuery from '@queries/user/useUserQuery.hook';
import { FORM_VALIDATION_MESSAGE } from '@/shared/services/validationMessageService';
import { useFormContext } from 'react-hook-form';
import { useEpisodeBeingEdited } from '@/context/EpisodeFormContext';

const debug = (...args) => {
    if (DEBUG_EPISODE_EDIT_FORM_SAVE) {
        console.log(...args);
    }
};

const EpisodeEditFormOptionsYoutubeThumbnailField = () => {
    const { episodeId } = useParams<{ episodeId: string }>();
    const episode = useEpisodeBeingEdited();
    const user = useUserQuery();
    const locale = user.data?.language?.toLowerCase();
    const addEpisodeYoutubeThumbnailMutation = useAddEpisodeYoutubeThumbnailMutation();
    const deleteEpisodeYoutubeThumbnailMutation = useDeleteYoutubeThumbnailImageMutation();
    const [error, setError] = useState<ReactNode | undefined>();
    const YOUTUBE_THUMBNAIL_DIMENSIONS = { minWidth: 640, minHeight: 360 };
    const youtubeThumbnailAcceptedMimes = ['jpg', 'png', 'gif'];
    const intl = useIntl();
    const [youtubeThumbnail, setYoutubeThumbnail] = useState(
        episode?.youtubeVideo?.data?.thumbnail?.data?.url,
    );
    const { getValues, setValue } = useFormContext();

    const handleValidation = async (image: FileList | null) => {
        if (typeof image === 'string' || image === null) return true;

        const validations = [
            {
                check: () => checkImgFormat(image[0]),
                errorMessage: intl.formatMessage(FORM_VALIDATION_MESSAGE.acceptedMimes, {
                    youtubeThumbnailAcceptedMimes,
                }),
            },
            {
                check: () => checkImgSize(image[0], locale, 2),
                errorMessage: intl.formatMessage(FORM_VALIDATION_MESSAGE.imgMaxSize, { max: 2 }),
            },
            {
                check: () =>
                    new Promise((resolve) => {
                        const img = new Image();
                        img.src = URL.createObjectURL(new File([image?.[0]], 'pic'));
                        img.onload = () =>
                            resolve(checkImgDimensions(img, YOUTUBE_THUMBNAIL_DIMENSIONS));
                    }),
                errorMessage: intl.formatMessage(
                    FORM_VALIDATION_MESSAGE.imgMinDimensions,
                    YOUTUBE_THUMBNAIL_DIMENSIONS,
                ),
            },
        ];

        for (const { check, errorMessage } of validations) {
            const isValid = await check();
            if (!isValid) {
                setError(errorMessage);
                return false;
            }
        }

        return true;
    };

    const handleUploadThumbnail = async (image: FileList | null) => {
        setError(undefined);
        const isValid = await handleValidation(image);

        if (!isValid) return;

        setYoutubeThumbnail(image);

        // When thumbnail upload succeed, api return default value of Youtube Video Title
        // If this field isn't dirty, the value is erased by the api default value
        // We want to prevent it by setting the field to dirty (form keep dirty values)
        setValue('options.youtubeVideoTitle', getValues('options.youtubeVideoTitle'), {
            shouldDirty: true,
        });

        if (image && typeof image !== 'string') {
            debug(`[Episode form] Saving youtube thumbnail for episode ${episodeId}…`);

            addEpisodeYoutubeThumbnailMutation.mutate(
                {
                    episodeId,
                    image: image[0],
                },
                {
                    onSuccess: (data, variables, context) => {
                        debug(
                            `[Episode form] Youtube thumbnail for episode ${episodeId} saved with success!`,
                            { data, variables, context },
                        );
                    },
                    onError: (error, variables, context) => {
                        debug(
                            `[Episode form] Failed to save youtube thumbnail for episode ${episodeId}`,
                            {
                                error,
                                variables,
                                context,
                            },
                        );
                    },
                },
            );
        }

        if (image === null) {
            debug(`[Episode form] Deleting youtube thumbnail for episode ${episodeId}…`);

            deleteEpisodeYoutubeThumbnailMutation.mutate(
                { episodeId },
                {
                    onSuccess: (data, variables, context) => {
                        debug(
                            `[Episode form] Youtube thumbnail for episode ${episodeId} deleted with success!`,
                            { data, variables, context },
                        );
                    },
                    onError: (error, variables, context) => {
                        debug(
                            `[Episode form] Failed to delete youtube thumbnail for episode ${episodeId}`,
                            { error, variables, context },
                        );
                    },
                },
            );
        }
    };

    return (
        <Stack $gap="0.5rem">
            <Text fontWeight="--fw-semibold">
                <FormattedMessage defaultMessage="Miniature de YouTube" />
            </Text>
            <ImgUploader
                value={youtubeThumbnail}
                onChange={handleUploadThumbnail}
                onDrop={() => setError(undefined)}
                hasError={!!error}
                errorMessage={error}
                acceptedFileTypes={[...IMG_TYPES, 'image/gif']}
            >
                <Stack $gap="1rem" $align="center">
                    <DropImgIcon icon={icon({ name: 'image', style: 'duotone' })} />
                    <Stack $gap="0.5rem" $align="center">
                        <Text fontWeight="--fw-semibold" textAlign="center">
                            <FormattedMessage
                                defaultMessage="Glissez-déposez votre image, ou <label>Parcourir</label>"
                                values={{
                                    label: (chunks: React.ReactNode) => (
                                        <Text
                                            as="span"
                                            color="--primary"
                                            fontWeight="--fw-semibold"
                                        >
                                            {chunks}
                                        </Text>
                                    ),
                                }}
                            />
                        </Text>
                        <Text color="--neutral500" textAlign="center">
                            <FormattedMessage
                                defaultMessage="
.JPG .PNG ou .GIF, 640 x 360px min. (1280 x 720px recommandé), jusqu’à {max} Mo"
                                values={{ max: 2 }}
                            />
                        </Text>
                    </Stack>
                </Stack>
            </ImgUploader>
        </Stack>
    );
};

const ImgUploader = styled(DropImgUploader)`
    height: 15rem;
    position: relative; // To fix some RAC's position absolute div behind the hood breaking the episode form layout
`;
const DropImgIcon = styled(FontAwesomeIcon)`
    width: 1.5rem;
    height: 1.5rem;
    color: var(--primary);
`;

export default EpisodeEditFormOptionsYoutubeThumbnailField;
