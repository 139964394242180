import { useParams } from 'react-router';
import Form from './Form';
import useRssSettingsQuery from '@/queries/rssSettings/useRssSettingsQuery.hook';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import useUpdateRssSettingsMutation from '@/queries/rssSettings/useUpdateRssSettingsMutation.hook';
import { useModalToastQueue } from '@/shared/hooks/useModalToastQueue.hook';
import { HostingSettingsFormSchema } from './Form/useFormSchema';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

const Settings = () => {
    const { showId } = useParams<{ showId: string }>();
    const { data: rssSettings, isError } = useRssSettingsQuery(showId);
    const { data: show } = useShowQuery(showId);
    const updateRssSettings = useUpdateRssSettingsMutation();
    const toast = useModalToastQueue();

    if (isError) return <span>Error</span>;

    if (rssSettings && show) {
        const handleSubmit = (data: HostingSettingsFormSchema) => {
            const payload = {
                ...data,
                podtrac: data.hasTracking,
            };
            updateRssSettings.mutate(
                { showId, payload },
                {
                    onSuccess: () => {
                        toast.success(
                            <FormattedMessage defaultMessage="Les paramètres ont été mis à jour" />,
                        );
                    },
                },
            );
        };
        return (
            <Form
                rssSettings={rssSettings}
                rssLink={show.rssLink}
                onSubmit={handleSubmit}
                isLoading={updateRssSettings.isLoading}
            />
        );
    }

    return (
        <SpinnerWrapper>
            <Spinner icon={icon({ name: 'spinner-third', style: 'solid' })} spin />;
        </SpinnerWrapper>
    );
};

const SpinnerWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--primary);
`;
const Spinner = styled(FontAwesomeIcon)`
    width: 1.5rem;
    height: 1.5rem;
`;

export default Settings;
