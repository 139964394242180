import { FormattedMessage } from 'react-intl';
import { connect } from '@app/decorators/connect';
import DeprecatedModal from '../../ui/molecules/DeprecatedModal';
import DeprecatedText from '../../ui/atoms/DeprecatedText';
import DeprecatedPaper from '../../ui/atoms/DeprecatedPaper';
import DeprecatedButton from '../../ui/atoms/DeprecatedButton';
import AddPlaylistEpisodeList from '../molecules/AddPlaylistEpisodeList';
import { useArray } from '../../../utils/hooks/useArray';
import Spinner from '../../ui/atoms/Spinner';
import { useOnClose } from '../../../utils/hooks/useOnClose';
import styled from 'styled-components';

const enhance = connect(({ routerStore }, { onClose }) => {
    const playlist = routerStore.resolve('playlistId');
    return {
        addEpisodesToPlaylist: (selection) =>
            playlist && playlist.addEpisodes(selection).then(onClose),
        isLoading: playlist && playlist.addEpisodes.pending,
    };
});

function AddEpisodeToPlaylistModal({ isOpened, onClose, addEpisodesToPlaylist, isLoading }) {
    const selection = useArray([]);
    useOnClose(selection.reset, isOpened);

    const toggleSelection = (episodeId) => {
        if (selection.array.includes(episodeId)) {
            selection.remove(episodeId);
        } else {
            selection.push(episodeId);
        }
    };

    return (
        <DeprecatedModal
            isOpened={isOpened}
            onClose={onClose}
            w={800}
            title={<FormattedMessage defaultMessage="Ajouter des épisodes à la playlist" />}
        >
            <ModalContentInnerWrapper>
                <DeprecatedPaper>
                    <DeprecatedText size="small" color="--neutral500" mb={10}>
                        <DeprecatedText
                            weight="semibold"
                            size="small"
                            color="--neutral500"
                            as="strong"
                        >
                            <FormattedMessage defaultMessage="Remarque :" />
                        </DeprecatedText>{' '}
                        <FormattedMessage defaultMessage="Attention à la confidentialité de vos épisodes, ils pourraient ne pas apparaître dans la playlist." />
                    </DeprecatedText>
                    <AddPlaylistEpisodeList
                        selection={selection}
                        toggleSelection={(element) => toggleSelection(element)}
                    />
                </DeprecatedPaper>
            </ModalContentInnerWrapper>
            <ActionWrapper>
                <DeprecatedText weight="semibold" align="center" auto>
                    {selection.array.length !== 0 && (
                        <FormattedMessage
                            defaultMessage="Vous allez ajouter {count, plural, one {# épisode} other {# épisodes}} à la playlist."
                            values={{ count: selection.array.length }}
                        />
                    )}
                </DeprecatedText>
                <DeprecatedButton accent="secondary" mr={10} onClick={onClose}>
                    <FormattedMessage defaultMessage="Annuler" />
                </DeprecatedButton>
                <DeprecatedButton
                    onClick={() => addEpisodesToPlaylist(selection.array)}
                    disabled={isLoading}
                >
                    {isLoading && <Spinner />}
                    <FormattedMessage defaultMessage="Ajouter à la playlist" />
                </DeprecatedButton>
            </ActionWrapper>
        </DeprecatedModal>
    );
}

const ModalContentInnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const ActionWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export default enhance(AddEpisodeToPlaylistModal);
