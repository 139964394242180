import { useParams, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import RouterLink from '@/components/Link/RouterLink';
import Sidebar from '@/shared/components/Sidebar';
import SidebarItem from '@/shared/components/Sidebar/Item/Item';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';

const Nav = () => {
    const location = useLocation();
    const currentSegment = location.pathname.split('/').pop();
    const { showId } = useParams<{ showId: string }>();
    const basePath = `/app/show/${showId}/charts`;

    return (
        <Sidebar>
            <CustomSidebarItem isActive={currentSegment === 'overview'}>
                <NavRouterLink to={`${basePath}/overview`}>
                    <FontAwesomeIcon icon={icon({ name: 'grid-2', style: 'solid' })} />
                    <FormattedMessage defaultMessage="Vue d'ensemble" />
                </NavRouterLink>
            </CustomSidebarItem>
            <CustomSidebarItem isActive={currentSegment === 'explore'}>
                <NavRouterLink to={`${basePath}/explore`}>
                    <FontAwesomeIcon icon={icon({ name: 'compass', style: 'solid' })} />
                    <FormattedMessage defaultMessage="Explorer" />
                </NavRouterLink>
            </CustomSidebarItem>
            <CustomSidebarItem isActive={currentSegment === 'reviews'}>
                <NavRouterLink to={`${basePath}/reviews`}>
                    <FontAwesomeIcon icon={icon({ name: 'comments', style: 'solid' })} />
                    <FormattedMessage id="reviewsCharts" defaultMessage="Notes" />
                </NavRouterLink>
            </CustomSidebarItem>
            <CustomSidebarItem isActive={currentSegment === 'reports'}>
                <NavRouterLink to={`${basePath}/reports`}>
                    <FontAwesomeIcon icon={icon({ name: 'bell', style: 'solid' })} />
                    <FormattedMessage defaultMessage="Rapports" />
                </NavRouterLink>
            </CustomSidebarItem>
        </Sidebar>
    );
};

const NavRouterLink = styled(RouterLink)`
    color: inherit;
`;
const CustomSidebarItem = styled(SidebarItem)`
    &:not([data-active='true']) svg {
        color: var(--neutral);
    }
    &:hover svg {
        color: var(--primary);
    }
`;

export default Nav;
