import styled from 'styled-components';
import { Provider } from 'mobx-react';
import stores from '@/stores/RootStore';
import { FormattedMessage } from 'react-intl';
import IntlProvider from '@/components/unorganized/IntlProvider';
import { queryClient } from '@/components/Root';
import { QueryClientProvider } from '@tanstack/react-query';
import { AuthContextProvider } from '@/context/AuthContext';
import { BrowserRouter } from 'react-router-dom';

const PopupContent = ({ label, downloads, weight }) => {
    return (
        <Provider stores={stores}>
            <BrowserRouter>
                <QueryClientProvider client={queryClient}>
                    <AuthContextProvider>
                        <IntlProvider>
                            <PolygonName>{label}</PolygonName>
                            {downloads ? (
                                <>
                                    <p>
                                        <FormattedMessage
                                            defaultMessage="{downloads, plural, one {# écoute} other {# écoutes}}"
                                            values={{ downloads }}
                                        />
                                    </p>
                                    {!!weight && (
                                        <p>
                                            <FormattedMessage
                                                defaultMessage="{weight, number}% des écoutes"
                                                values={{ weight }}
                                            />
                                        </p>
                                    )}
                                </>
                            ) : (
                                <NoDownloads>
                                    <FormattedMessage defaultMessage="aucune écoute" />
                                </NoDownloads>
                            )}
                        </IntlProvider>
                    </AuthContextProvider>
                </QueryClientProvider>
            </BrowserRouter>
        </Provider>
    );
};

const PolygonName = styled.p`
    color: var(--primary);
    font-weight: bold;
`;

const NoDownloads = styled.p`
    color: var(--neutral500);
`;

PopupContent.defaultProps = {
    weight: null,
};

export default PopupContent;
