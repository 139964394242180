import IconButton from '@/components/IconButton';
import styled, { css } from 'styled-components';

export const OptionButton = styled(IconButton)`
    padding: 0.25rem;
    color: var(--black);
    border-radius: var(--r-xs);

    ${(p) =>
        p.$isActive &&
        css`
            background-color: var(--primary50);
            color: var(--primary);
        `}

    &[data-disabled] {
        background: none;
        color: var(--black);
    }

    &[data-hovered] {
        background-color: var(--neutral50);
    }
`;

export default OptionButton;
