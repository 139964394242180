import { css } from 'styled-components';
import { ButtonProps } from '../../Button';
import { BaseLinkProps } from '@/components/Link/types';

export const largeButtonStyles = css`
    padding: 0.75rem 1.5rem;
    column-gap: 0.75rem;
    font-size: var(--fs-body-l);
    line-height: var(--lh-body-l);

    ${({ $variant }: { $variant: ButtonProps['variant'] | BaseLinkProps['variant'] }) =>
        $variant === 'tertiary' &&
        css`
            padding: calc(0.75rem - 1px) calc(1.5rem - 1px);
        `}
`;
