import styled, { css, keyframes } from 'styled-components';
import { PlayerFormSchema } from '../usePlayerFormSchema.hook';

interface PlayerFormPreviewSkeletonProps {
    type: PlayerFormSchema['type'];
    format: PlayerFormSchema['format'];
    episodeList?: PlayerFormSchema['episodeList'];
}

const PlayerFormPreviewSkeleton = (props: PlayerFormPreviewSkeletonProps) => {
    if (props.format === 'vertical' && (props.type === 'playlist' || props.episodeList)) {
        return <VerticalWithEpisodesSkeleton />;
    }

    if (props.format === 'vertical') {
        return <VerticalSkeleton />;
    }

    if (props.type === 'playlist' || props.episodeList) {
        return <HorizontalWithEpisodesSkeleton />;
    }

    return <HorizontalSkeleton />;
};

const shimmer = keyframes`
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
`;
const shimmering = css`
    background: var(--neutral50);
    background-image: linear-gradient(
        to right,
        var(--neutral50) 0%,
        #edeef1 20%,
        var(--neutral50) 40%,
        var(--neutral50) 100%
    );
    background-repeat: no-repeat;
    background-size: 800px 100%;
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${shimmer};
    animation-timing-function: linear;
`;
const Skeleton = styled.div`
    border-radius: var(--r-s);
    background-color: var(--neutral100);
    ${shimmering};
`;
const VerticalSkeleton = styled(Skeleton)`
    height: 500px;
    width: 100%;
    max-width: 320px;
`;
const HorizontalSkeleton = styled(Skeleton)`
    height: 220px;
    width: 100%;
    max-width: 800px;
`;
const VerticalWithEpisodesSkeleton = styled(Skeleton)`
    height: 700px;
    width: 100%;
    max-width: 320px;
`;
const HorizontalWithEpisodesSkeleton = styled(Skeleton)`
    height: 420px;
    width: 100%;
    max-width: 800px;
`;

export default PlayerFormPreviewSkeleton;
