import { useFormContext } from 'react-hook-form';
import Stack from '@ui/layout/Stack';
import Text from '@ui/atoms/Text';
import { FormattedMessage } from 'react-intl';
import { ToggleController } from '@ui/atoms/Toggle';

const EpisodeEditFormPrivacyDownloadField = () => {
    const { control } = useFormContext();

    return (
        <Stack $gap="1rem">
            <Text variant="bodyM" fontWeight="--fw-semibold">
                <FormattedMessage defaultMessage="Téléchargements" />
            </Text>
            <ToggleController name="privacy.canDownload" control={control}>
                <FormattedMessage defaultMessage="Permettre le téléchargement de votre épisode (Smartplayer et pages publiques)." />
            </ToggleController>
        </Stack>
    );
};

export default EpisodeEditFormPrivacyDownloadField;
