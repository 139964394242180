import { useEffect } from 'react';
import { useBodyToastQueue } from '@/shared/hooks/useBodyToastQueue.hook';
import {
    AILoadingMapItem,
    EPISODE_AI_LOADING_ACTIONS,
    useEpisodeAILoading,
} from '../../contexts/NonHostingEpisodeAILoadingContext';
import { useIntl } from 'react-intl';

interface useAISuggestionsErrorProps {
    isError: boolean;
    episodeId: string;
    type: AILoadingMapItem;
}

const useAISuggestionsError = ({ isError, episodeId, type }: useAISuggestionsErrorProps) => {
    const toast = useBodyToastQueue();
    const [_, dispatch] = useEpisodeAILoading();
    const intl = useIntl();

    useEffect(() => {
        const hasError = localStorage.getItem(`episode-ai-${episodeId}-generated-${type}-error`);

        if (isError && !hasError) {
            localStorage.setItem(`episode-ai-${episodeId}-generated-${type}-error`, 'true');
            dispatch({
                type: EPISODE_AI_LOADING_ACTIONS.STOP,
                payload: type,
            });
            toast.alert(
                intl.formatMessage({
                    defaultMessage:
                        'Oups, il semblerait que nous rencontrons un soucis. Merci de réessayer plus tard. 🙏',
                }),
            );
            return;
        }

        localStorage.removeItem(`episode-ai-${episodeId}-generated-${type}-error`);
    }, [isError]);
};

export default useAISuggestionsError;
