import { action } from 'mobx';
import Model from './Model';

class PaginationModel extends Model {
    static attributes = {
        count: 'count',
        currentPage: 'current_page',
        perPage: 'per_page',
        totalPages: 'total_pages',
        total: 'total',
    };

    @action.bound
    next() {
        this.currentPage = this.currentPage + 1;
    }

    @action.bound
    previous() {
        this.currentPage = this.currentPage - 1;
    }

    @action.bound
    to(page) {
        this.currentPage = page;
    }
}

export default PaginationModel;
