import { useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';
import Center from '@ui/layout/Center';
import Cluster from '@ui/layout/Cluster';
import ClipPreview from './ClipPreview';
import UpdateImageButton from './UpdateImageButton';
import DeleteImageButton from './DeleteImageButton';
import Stack from '@ui/layout/Stack';
import ClipOptions from './ClipOptions';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import DeprecatedToggle from '@ui/atoms/DeprecatedToggle';
import HorizontalTabSlide from '@ui/molecules/HorizontalTabSlide';
import ClipCoverMaxSizeIndications from './ClipCoverMaxSizeIndications';
import { CLIP_FORMATS } from '@/shared/config/constants';
import { connect } from '@/components/legacy/connect';
import styled from 'styled-components';

const getFirstFormat = (values) => {
    if (values.formats.find((item) => item.format === CLIP_FORMATS.SQUARE)) {
        return CLIP_FORMATS.SQUARE;
    }
    if (values.formats.find((item) => item.format === CLIP_FORMATS.STORY)) {
        return CLIP_FORMATS.STORY;
    }
    if (values.formats.find((item) => item.format === CLIP_FORMATS.LANDSCAPE)) {
        return CLIP_FORMATS.LANDSCAPE;
    }
    return CLIP_FORMATS.SQUARE;
};

const getFormatTabTitles = (values) => {
    const titles = {};

    if (values.formats.find((item) => item.format === CLIP_FORMATS.SQUARE)) {
        titles.square = <FormattedMessage defaultMessage="Carré (1:1)" />;
    }
    if (values.formats.find((item) => item.format === CLIP_FORMATS.STORY)) {
        titles.story = <FormattedMessage defaultMessage="Story (9:16)" />;
    }
    if (values.formats.find((item) => item.format === CLIP_FORMATS.LANDSCAPE)) {
        titles.wide = <FormattedMessage defaultMessage="Paysage (1.9:1)" />;
    }

    return titles;
};

const enhance = connect(({ clipFormatStore }) => ({
    toggleClipFormatFullCover: clipFormatStore.toggleClipFormatFullCover,
}));

const Customization = ({ episode, toggleClipFormatFullCover, ...other }) => {
    const { values, setFieldValue } = useFormikContext();
    const [currentFormat, setCurrentFormat] = useState(getFirstFormat(values));

    const [isUpdatingClipFormat, setIsUpdatingClipFormat] = useState({
        [CLIP_FORMATS.SQUARE]: false,
        [CLIP_FORMATS.STORY]: false,
        [CLIP_FORMATS.LANDSCAPE]: false,
    });
    const [formatImages, setFormatImages] = useState({
        [CLIP_FORMATS.SQUARE]:
            values.formats.find((item) => item.format === CLIP_FORMATS.SQUARE)?.images ?? null,
        [CLIP_FORMATS.STORY]:
            values.formats.find((item) => item.format === CLIP_FORMATS.STORY)?.images ?? null,
        [CLIP_FORMATS.LANDSCAPE]:
            values.formats.find((item) => item.format === CLIP_FORMATS.LANDSCAPE)?.images ?? null,
    });
    const [isFormatImageFullCover, setIsFormatImageFullCover] = useState({
        [CLIP_FORMATS.SQUARE]:
            values.formats.find((item) => item.format === CLIP_FORMATS.SQUARE)?.full_cover ?? false,
        [CLIP_FORMATS.STORY]:
            values.formats.find((item) => item.format === CLIP_FORMATS.STORY)?.full_cover ?? false,
        [CLIP_FORMATS.LANDSCAPE]:
            values.formats.find((item) => item.format === CLIP_FORMATS.LANDSCAPE)?.full_cover ??
            false,
    });

    const valuesWithUpToDateFormats = useMemo(() => {
        const squareFormat = values.formats.find((item) => item.format === CLIP_FORMATS.SQUARE);
        const storyFormat = values.formats.find((item) => item.format === CLIP_FORMATS.STORY);
        const landscapeFormat = values.formats.find(
            (item) => item.format === CLIP_FORMATS.LANDSCAPE,
        );

        const computedFormats = [];
        if (squareFormat) {
            computedFormats.push({
                ...squareFormat,
                fullCover: isFormatImageFullCover[CLIP_FORMATS.SQUARE],
                images: formatImages[CLIP_FORMATS.SQUARE],
            });
        }
        if (storyFormat) {
            computedFormats.push({
                ...storyFormat,
                fullCover: isFormatImageFullCover[CLIP_FORMATS.STORY],
                images: formatImages[CLIP_FORMATS.STORY],
            });
        }
        if (landscapeFormat) {
            computedFormats.push({
                ...landscapeFormat,
                fullCover: isFormatImageFullCover[CLIP_FORMATS.LANDSCAPE],
                images: formatImages[CLIP_FORMATS.LANDSCAPE],
            });
        }

        return {
            ...values,
            formats: computedFormats,
        };
    }, [values, isFormatImageFullCover, formatImages]);

    const hasMultipleFormats = useMemo(
        () => valuesWithUpToDateFormats.formats.length > 1,
        [valuesWithUpToDateFormats],
    );

    const handleFullCoverToggle = async () => {
        const format = currentFormat;
        const clipFormat = values.formats.find((item) => item.format === format);
        const checked = await toggleClipFormatFullCover(
            clipFormat.id,
            !isFormatImageFullCover[format],
        );
        setIsFormatImageFullCover((previousState) => ({ ...previousState, [format]: checked }));
    };

    const handleUpdateFormatImage = (format, images) => {
        setFormatImages((previousState) => ({ ...previousState, [format]: images }));
    };

    const handleDeleteFormatImage = (format) => {
        setFormatImages((previousState) => ({ ...previousState, [format]: null }));
    };

    const handleIsUploadingFormatImage = (format, isLoading) => {
        setIsUpdatingClipFormat((previousState) => ({ ...previousState, [format]: isLoading }));
    };

    return (
        <div {...other}>
            <Center>
                <Cluster $wrap="nowrap">
                    <LeftBlock>
                        <Stack $align="center" $gap="1rem">
                            <Stack $align="center" $gap="1.5rem">
                                {hasMultipleFormats && (
                                    <HorizontalTabSlide
                                        tabsTitles={getFormatTabTitles(values)}
                                        getTabValue={setCurrentFormat}
                                        active
                                        simpleSwitch
                                        buttonResetStyles
                                    />
                                )}
                                <ClipPreview
                                    height="20rem"
                                    clip={valuesWithUpToDateFormats}
                                    format={currentFormat}
                                    transcription={values.transcription}
                                    episodeImageUrl={episode.imageUrl}
                                    isLoading={isUpdatingClipFormat[currentFormat]}
                                />
                                <DeprecatedText as="label" weight="semibold">
                                    <DeprecatedToggle
                                        checked={isFormatImageFullCover[currentFormat]}
                                        onChange={handleFullCoverToggle}
                                    />
                                    {hasMultipleFormats ? (
                                        <FormattedMessage defaultMessage="L’image de ce format prend toute la place disponible" />
                                    ) : (
                                        <FormattedMessage defaultMessage="L’image prend toute la place disponible" />
                                    )}
                                </DeprecatedText>
                            </Stack>
                            <Cluster $wrap="nowrap" $gap="0.5rem">
                                <UpdateImageButton
                                    values={valuesWithUpToDateFormats}
                                    format={currentFormat}
                                    multiFormats={hasMultipleFormats}
                                    onUpdateImage={handleUpdateFormatImage}
                                    setIsUploadingImage={handleIsUploadingFormatImage}
                                />
                                {valuesWithUpToDateFormats.formats.find(
                                    (item) => item.format === currentFormat,
                                )?.images?.length > 0 && (
                                    <DeleteImageButton
                                        values={valuesWithUpToDateFormats}
                                        format={currentFormat}
                                        multiFormats={hasMultipleFormats}
                                        onDeleteImage={handleDeleteFormatImage}
                                        setIsUploadingImage={handleIsUploadingFormatImage}
                                    />
                                )}
                            </Cluster>
                            <ClipCoverMaxSizeIndications
                                isFullCover={isFormatImageFullCover[currentFormat]}
                                currentFormat={currentFormat}
                            />
                        </Stack>
                    </LeftBlock>
                    <RightBlock>
                        <ClipOptions
                            values={values}
                            multiFormats={hasMultipleFormats}
                            setFieldValue={setFieldValue}
                        />
                    </RightBlock>
                </Cluster>
            </Center>
        </div>
    );
};

const LeftBlock = styled.div`
    min-width: 634px;
    padding-right: 1.5rem;
`;
const RightBlock = styled.div`
    padding-left: 1.5rem;
    border-left: 1px solid var(--neutral100);
    flex-grow: 1;
`;

export default enhance(Customization);
