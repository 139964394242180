import { Popover } from 'react-aria-components';
import styled, { keyframes } from 'styled-components';
import type { PopoverProps } from 'react-aria-components';

const SelectDropdown = (props: PopoverProps) => <Dropdown {...props} />;

const mobileAnimationIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(40px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;
const mobileAnimationOut = keyframes`
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(40px);
    }
`;
const desktopAnimationIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(-5px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;
const desktopAnimationOut = keyframes`
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(-5px);
    }
`;
const Dropdown = styled(Popover)`
    border-top-left-radius: var(--r-l);
    border-top-right-radius: var(--r-l);
    padding-block: 0.5rem;
    background-color: var(--white);
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
    height: fit-content;
    overflow-y: auto;
    width: 100%;

    border-top-left-radius: var(--r-l);
    border-top-right-radius: var(--r-l);
    padding: 0.5rem;
    background-color: var(--white);
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
    height: fit-content;
    overflow-y: auto;
    width: 100%;

    /* Override Popover styles which are calculated based on the trigger's position */
    ${({ theme }) => theme.mediaQueries.mobileAndTablet} {
        position: fixed !important;
        bottom: 0 !important;
        left: 0 !important;
        top: unset !important;
        max-height: 90vh !important;

        &[data-entering] {
            animation: ${mobileAnimationIn} 0.25s ease-in-out;
        }
        &[data-exiting] {
            animation: ${mobileAnimationOut} 0.25s ease-in-out;
        }
    }

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        padding-block: 0.25rem;
        max-width: var(--trigger-width);
        border-radius: var(--r-xs);
        max-height: 12rem !important;

        &[data-entering] {
            animation: ${desktopAnimationIn} 0.25s ease-in-out;
        }
        &[data-exiting] {
            animation: ${desktopAnimationOut} 0.25s ease-in-out;
        }
    }
`;

export default SelectDropdown;
