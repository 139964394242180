import useQuery from '@/shared/hooks/useQuery';
import { useEffect, useState } from 'react';
import useUserQuery from '@queries/user/useUserQuery.hook';
import useShowQuery from '@queries/show/useShowQuery.hook';
import Text from '@ui/atoms/Text';
import { defineMessages, FormattedMessage } from 'react-intl';
import Confetti from 'react-dom-confetti';
import RouterLink from '@/components/Link/RouterLink';
import IconRocket from '@ui/icons/IconRocket';
import { sendAmplitudeLogEvent } from '@/shared/utils/amplitude';
import styled from 'styled-components';
import useShowsQuery from '@queries/show/useShowsQuery.hook';

const configLeft = {
    angle: '45',
    spread: '100',
    startVelocity: '50',
    elementCount: '100',
    dragFriction: '0.1',
    duration: '3000',
    stagger: '30',
    width: '20px',
    height: '10px',
    colors: ['#751CBF', '#F52FFB', '#3A25A5'],
};

const configRight = {
    angle: '135',
    spread: '100',
    startVelocity: '50',
    elementCount: '100',
    dragFriction: '0.1',
    duration: '3000',
    stagger: '30',
    width: '20px',
    height: '10px',
    colors: ['#751CBF', '#F52FFB', '#3A25A5'],
};

interface SuccessProps {
    variant: 'create' | 'import' | 'connect' | 'youtube';
}

const Success = ({ variant }: SuccessProps) => {
    const query = useQuery();
    const showId: string = query.get('showId') || '';
    const { data: shows } = useShowsQuery();
    const { data: show } = useShowQuery(showId);
    const { data: user } = useUserQuery();
    const userIsPmp = user?.canHostOnAusha === false;
    const [confetti, setConfetti] = useState(false);
    const hasShow = (shows?.shows?.length || 0) > 0;

    useEffect(() => {
        setTimeout(() => setConfetti(true), 1000);
        return () => setConfetti(false);
    }, []);

    const defaultRedirectUrl = userIsPmp
        ? `/app/show/${showId}/settings/broadcast/non-hosting`
        : `/app/show/${showId}/episodes`;

    if (!showId || !show) return null;

    const TITLES = defineMessages({
        create: {
            defaultMessage: 'Votre émission est prête',
        },
        import: {
            defaultMessage: "Nous sommes entrain d'importer votre podcast",
        },
        connect: {
            defaultMessage: 'Nous sommes entrain de connecter votre podcast à Ausha',
        },
        youtube: {
            defaultMessage: "Nous sommes entrain d'importer votre podcast YouTube",
        },
    });

    const SUBTITLES = defineMessages({
        create: {
            defaultMessage: 'Félicitations ! Votre émission a bien été créée.',
        },
        import: {
            defaultMessage:
                "Félicitations ! Vous pouvez maintenant accéder à Ausha et explorer toutes les nouvelles fonctionnalités pendant que nous finissons d'importer vos épisodes.",
        },
        connect: {
            defaultMessage:
                'Félicitations ! Vous pouvez maintenant accéder à Ausha et découvrir toutes les nouvelles fonctionnalités pendant que nous terminons la synchronisation de votre podcast.',
        },
        youtube: {
            defaultMessage:
                "Félicitations ! Vous pouvez dès maintenant accéder à Ausha et explorer toutes les nouvelles fonctionnalités pendant que nous finissons d'importer vos épisodes.",
        },
    });

    const SUBTITLESHASSHOW = defineMessages({
        create: {
            defaultMessage: 'Félicitations ! Votre émission a bien été créée.',
        },
        import: {
            defaultMessage:
                "Tout est prêt ! Accédez à votre espace Ausha dès maintenant pendant que nous terminons l'importation de vos épisodes.",
        },
        connect: {
            defaultMessage:
                'Tout est prêt ! Accédez à votre espace Ausha dès maintenant pendant que nous finalisons la connexion de votre podcast.',
        },
        youtube: {
            defaultMessage:
                "Félicitations ! Vous pouvez dès maintenant accéder à Ausha et explorer toutes les nouvelles fonctionnalités pendant que nous finissons d'importer vos épisodes.",
        },
    });

    return (
        <>
            <ConfettiLeftWrapper>
                {/* @ts-ignore */}
                <Confetti active={confetti} config={configLeft} />
            </ConfettiLeftWrapper>
            <ConfettiRightWrapper>
                {/* @ts-ignore */}
                <Confetti active={confetti} config={configRight} />
            </ConfettiRightWrapper>
            <Wrapper>
                <SubHeader>
                    <Text variant="headingL" fontWeight="--fw-bold" textAlign="center">
                        <FormattedMessage {...TITLES[variant]} />
                    </Text>
                    <Text textAlign="center" color="--neutral500">
                        {hasShow ? (
                            <FormattedMessage {...SUBTITLES[variant]} />
                        ) : (
                            <FormattedMessage {...SUBTITLESHASSHOW[variant]} />
                        )}
                    </Text>
                </SubHeader>
                <InnerWrapper>
                    <Cover src={show.imageUrl || 'https://image.ausha.co/default_cover.png'} />
                    <CongratulationText>
                        <Text variant="headingM" fontWeight="--fw-bold">
                            {show.name}
                        </Text>
                        <Text fontWeight="--fw-semibold" color="--primary">
                            {show.author}
                        </Text>
                    </CongratulationText>
                    <RouterLink
                        variant="button-primary"
                        size="large"
                        startIcon={<IconRocket />}
                        to={defaultRedirectUrl}
                        onClick={() => userIsPmp && sendAmplitudeLogEvent('Setting Show Completed')}
                        replace={true}
                    >
                        <FormattedMessage defaultMessage="C'est parti !" />
                    </RouterLink>
                </InnerWrapper>
            </Wrapper>
        </>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    overflow: hidden;
    width: 100%;
`;
const SubHeader = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;
const InnerWrapper = styled.div`
    position: relative;
    width: 100%;
    padding: 2rem;
    background: var(--white);
    border-radius: var(--r-l);
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    align-items: center;

    & > :last-child {
        margin-top: 1rem;
    }
`;
const CongratulationText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 0.25rem;
`;
const ConfettiLeftWrapper = styled.div`
    position: fixed;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
`;
const ConfettiRightWrapper = styled.div`
    position: fixed;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
`;
const Cover = styled.img`
    width: 12.5rem;
    height: 12.5rem;
    border-radius: var(--r-l);
    object-fit: cover;

    ${(props) => props.theme.mediaQueries.desktopAndUp} {
        border-radius: var(--r-xl);
    }
`;

export default Success;
