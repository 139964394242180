import { FormattedMessage } from 'react-intl';
import Desktop from '@ui/molecules/Desktop';
import PaperGrid from '@ui/atoms/PaperGrid';
import Column from '@ui/atoms/Column';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import MobileOrTablet from '@ui/molecules/MobileOrTablet';
import { useResponsive } from '@/utils/hooks/useResponsive';

const LegendHeatmap = ({ currentMoments, colors, isNoData }) => {
    const { isMobileOrTablet } = useResponsive();
    const currentMomentsLength = currentMoments.categories?.length - 1;
    // IF YOU NEED TO CUSTOMIZE THESE COLORS
    // ADD PROPS COLORS ON LENGENDHEATMAP COMPONENT
    // ex: colors={['#f2f2f2', '#e5e5e5', '#F1E8F9', '#C8A4E5', '#751CBF']}
    const defaultColorCategories = ['#F9F7FA', '#F0EDF2', '#F1E8F9', '#C8A4E5', '#751CBF'];

    if (isNoData) {
        return null;
    }

    return (
        <>
            <DeprecatedText weight="bold" mb={15}>
                <FormattedMessage defaultMessage="Nombre d'écoutes" />
            </DeprecatedText>
            <Desktop>
                <PaperGrid column={isMobileOrTablet ? 3 : 5} mt={20}>
                    {currentMoments.categories?.map((c, i) => {
                        const start = i + 1;
                        const end = i + 2;
                        return (
                            <Column
                                key={c.from}
                                startColumn={start}
                                endColumn={end}
                                column
                                align="flex-start"
                            >
                                <DeprecatedPaper
                                    w={35}
                                    h={35}
                                    mb={10}
                                    background={colors ? colors[i] : defaultColorCategories[i]}
                                    rounded
                                />
                                <DeprecatedText>
                                    {i !== currentMomentsLength ? (
                                        <FormattedMessage
                                            defaultMessage="De {from} à {to}"
                                            values={{ from: c.from, to: c.to }}
                                        />
                                    ) : (
                                        <FormattedMessage
                                            defaultMessage="Plus de {from}"
                                            values={{ from: c.from }}
                                        />
                                    )}
                                </DeprecatedText>
                            </Column>
                        );
                    })}
                </PaperGrid>
            </Desktop>
            <MobileOrTablet>
                <DeprecatedPaper flex style={{ flexWrap: 'wrap' }}>
                    {currentMoments.categories?.map((c, i) => (
                        <DeprecatedPaper
                            key={c.from}
                            maxW="calc(100% / 3)"
                            mb={i < 2 && 20}
                            pr={10}
                        >
                            <DeprecatedPaper
                                w={10}
                                h={38}
                                mb={10}
                                background={colors ? colors[i] : defaultColorCategories[i]}
                                rounded
                            />
                            <DeprecatedText size="small">
                                {i !== currentMomentsLength ? (
                                    <FormattedMessage
                                        defaultMessage="De {from} à {to}"
                                        values={{ from: c.from, to: c.to }}
                                    />
                                ) : (
                                    <FormattedMessage
                                        defaultMessage="Plus de {from}"
                                        values={{ from: c.from }}
                                    />
                                )}
                            </DeprecatedText>
                        </DeprecatedPaper>
                    ))}
                </DeprecatedPaper>
            </MobileOrTablet>
        </>
    );
};

export default LegendHeatmap;
