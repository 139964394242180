import { createContext, useState, ReactNode } from 'react';

export interface CurrentShowContextType {
    showId: string;
    setShowId: (id: string) => void;
}

interface CurrentShowProviderProps {
    children: ReactNode;
}

export const CurrentShow = createContext<CurrentShowContextType | null>(null);

export const CurrentShowContext = ({ children }: CurrentShowProviderProps) => {
    const [showId, setShowId] = useState<string>('');

    return <CurrentShow.Provider value={{ showId, setShowId }}>{children}</CurrentShow.Provider>;
};
