import posed from 'react-pose';
import arrowDown from '@public/icons/chevrondown.svg';
import DeprecatedIcon from '../DeprecatedIcon';

const transition = { duration: 350 };

const Arrow = posed.span({
    desc: { scaleY: 1, transition },
    asc: { scaleY: -1, transition },
});

function TableArrow({ order, ...props }) {
    return (
        <Arrow pose={order}>
            <DeprecatedIcon as={arrowDown} color="--neutral500" {...props} />
        </Arrow>
    );
}

export default TableArrow;
