import { Children, cloneElement } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: ${(props) => props.height};
    min-height: ${(props) => props.minHeight};

    > * {
        margin-top: ${(props) => props.space};
        margin-bottom: ${(props) => props.space};
    }

    > :first-child:not(.cover-content) {
        margin-top: 0;
    }

    > :last-child:not(.cover-content) {
        margin-bottom: 0;
    }

    > .cover-content {
        margin-top: auto;
        margin-bottom: auto;
    }
`;

const Cover = (props) => {
    const { children, header, footer, ...other } = props;
    // Verifies that children has only one child
    const child = Children.only(children);

    return (
        <Container {...other}>
            {header}
            {cloneElement(child, {
                className: child.props.className
                    ? `${child.props.className} cover-content`
                    : 'cover-content',
            })}
            {footer}
        </Container>
    );
};

Cover.propTypes = {
    header: PropTypes.node,
    footer: PropTypes.node,
    space: PropTypes.string, // The minimum space between and around all of the child elements
    height: PropTypes.string, // The height for the Cover
    minHeight: PropTypes.string, // The minimum height for the Cover
    children: PropTypes.node.isRequired,
};

Cover.defaultProps = {
    header: null,
    footer: null,
    space: '1rem',
    height: 'auto',
    minHeight: 'auto',
};

export default Cover;
