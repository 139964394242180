import { useState, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import type { Key } from 'react-aria-components';
import useQuery from '@/shared/hooks/useQuery';
import { getLocalTimeZone } from '@internationalized/date';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import useListenersStatsQuery from '@/queries/stats/useListenersStatsQuery.hook';
import type { Stats } from '@queries/stats/useListenersStatsQuery.hook';
import useListenersStatsUniqueQuery from '@/queries/stats/useListenersStatsUniqueQuery.hook';
import useCampaignListenersStatsQuery from '@/queries/stats/useCampaignListenersStatsQuery.hook';
import { useStatsDateSelectorContext } from '@/context/StatsDateSelectorContext';
import { Tabs, TabList, Tab } from 'react-aria-components';
import Filters from '../../Filters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { USER_OPTIONS } from '@/shared/config/userOptions';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';
import { TooltipTrigger } from 'react-aria-components';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import GraphHeader from './GraphHeader';
import Graph from './Graph';
import { extractCssVariable } from '@/shared/utils/cssVariable';
import { Episode } from '@/queries/episode/useEpisodesMinimalQuery.hook';
import useEpisodesMinimalQuery from '@/queries/episode/useEpisodesMinimalQuery.hook';
import Table from './Table';
import Export from './Export';
import styled from 'styled-components';
import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import NoDataChart from '@/components/charts/NoData/NoDataChart';

dayjs.extend(isBetween);
dayjs.extend(weekOfYear);

type Precision = 'days' | 'weeks' | 'months' | 'years';

interface Item {
    x: string;
    y: number;
}
export interface VariationsData {
    name: string;
    data: Item[];
}

const ListenersGraphAndTable = () => {
    const intl = useIntl();
    const { showId } = useParams<{ showId: string }>();
    const query = useQuery();
    const episodeQuery = query.get('episode');
    const campaignId = query.get('campaignId');
    const { startDate, endDate } = useStatsDateSelectorContext();
    const { data: user } = useUserQuery();
    const { data: show } = useShowQuery(showId);
    const timezone = useMemo(() => user?.timezone || getLocalTimeZone(), [user]);
    const languageCode = user?.language || 'en';

    const episodesQuery = query.get('episode');
    const episodesFromQuery =
        episodesQuery && episodesQuery !== 'all' ? episodesQuery.split('_') : null;

    const { data: episodes } = useEpisodesMinimalQuery({
        showId,
        perPage: 500,
    });

    const [tabValue, setTabValue] = useState<Key>('downloads');
    const [precision, setPrecision] = useState<'days' | 'weeks' | 'months' | 'years'>('days');
    const [highlightedColumnIndex, setHighlightedColumnIndex] = useState<number | undefined>();

    const { data: showStats, isLoading: statsAreLoading } = useListenersStatsQuery({
        showId,
        start: startDate || '',
        end: endDate || '',
        precision,
        episodes: episodeQuery && episodeQuery !== 'all' ? episodeQuery.split('_') : [],
        timezone,
        options: {
            enabled: !!campaignId && tabValue === 'downloads',
        },
    });

    const episodesPublishedWithinDateRange =
        episodes?.episodes
            ?.filter((episode) => {
                if (!episode.publishedAt) return false;
                return dayjs(episode.publishedAt).isBetween(
                    dayjs(startDate),
                    dayjs(endDate).add(1, 'day'),
                );
            })
            .reduce(
                (acc, episode) => {
                    const publishedAt = dayjs(episode.publishedAt).format('YYYY-MM-DD');
                    const existingEntry = acc.find((item) => item.x === publishedAt);

                    if (existingEntry) {
                        existingEntry.episodeNames.push(episode.name);
                    } else {
                        const parsed = dayjs(episode.publishedAt);
                        const iso = parsed.toISOString();
                        const displayDate = intl.formatDate(iso, {
                            month: 'numeric',
                            day: 'numeric',
                            year: 'numeric',
                        });
                        const longDate = intl.formatDate(iso, {
                            month: 'long',
                            day: 'numeric',
                            year: 'numeric',
                        });

                        acc.push({
                            x: publishedAt,
                            y: 1,
                            color: '#f56642',
                            displayDate,
                            longDate,
                            publishedEpisodeText: intl.formatMessage({
                                defaultMessage: 'Episode publié',
                            }),
                            publishedEpisodeTextPlural: intl.formatMessage({
                                defaultMessage: 'Episodes publiés',
                            }),
                            episodeNames: [episode.name],
                        });
                    }

                    return acc;
                },
                [] as Array<{
                    x: string;
                    y: number;
                    color: string;
                    displayDate: string;
                    longDate: string;
                    publishedEpisodeText: string;
                    publishedEpisodeTextPlural: string;
                    episodeNames: string[];
                }>,
            ) || [];

    const { data: showStatsUnique, isLoading: statsUniqueAreLoading } =
        useListenersStatsUniqueQuery({
            showId,
            start: startDate || '',
            end: endDate || '',
            precision,
            episodes: episodeQuery && episodeQuery !== 'all' ? episodeQuery.split('_') : [],
            timezone,
            options: {
                enabled: !!campaignId && tabValue === 'uniqueDownloads',
            },
        });

    const { data: campaignStats, isLoading: campaignStatsAreLoading } =
        useCampaignListenersStatsQuery({
            campaignId: campaignId || '',
            start: startDate || '',
            end: endDate || '',
            precision,
            episodes: episodeQuery && episodeQuery !== 'all' ? episodeQuery.split('_') : [],
            timezone,
            options: {
                enabled: !campaignId,
            },
        });

    const stats = campaignId
        ? campaignStats
        : tabValue === 'downloads'
        ? showStats
        : showStatsUnique;

    const isLoading = statsAreLoading || statsUniqueAreLoading || campaignStatsAreLoading;

    const uniqueListenersDisabled =
        !show?.userOptions?.includes(USER_OPTIONS.UNIQUE_LISTENERS) ?? true;

    const chartPrimary = extractCssVariable('--primary');

    const translateRangeDays = {
        fr: 'Semaine du',
        en: 'Week of',
    };

    const episodeFormatVariationStep = (episode: Episode, precision: Precision) => {
        const format = {
            days: dayjs(episode.publishedAt).format('DD/MM/YYYY'),
            weeks: `${
                translateRangeDays[(user?.language as keyof typeof translateRangeDays) || 'en']
            } ${dayjs(episode.publishedAt).week()}`,
            months: dayjs(episode.publishedAt).format('MMMM YYYY'),
            years: dayjs(episode.publishedAt).format('YYYY'),
        };
        return format[precision];
    };

    const episodesList = episodes?.episodes?.filter((episode) =>
        episodesFromQuery?.includes(`${episode.id}`),
    );

    const tooltipPublishedContent = (data: {
        at: number;
        downloads: number;
        variation: number | null;
    }) => {
        const date = dayjs(data.at);

        const formatWeeklyDate = (date: Dayjs) => {
            if (languageCode === 'fr') {
                return date.startOf('week').format('DD/MM');
            }

            return date.startOf('week').format('M/D');
        };

        const augmentedData = {
            ...data,
            days: languageCode === 'fr' ? date.format('DD/MM/YYYY') : date.format('M/D/YY'),
            weeks: `${
                translateRangeDays[languageCode as keyof typeof translateRangeDays]
            } ${formatWeeklyDate(date)}`,
            months: date.format('MMMM YYYY'),
            years: date.format('YYYY'),
        };

        const tooltipContentMessage = {
            days: intl.formatDate(data.at, {
                month: '2-digit',
                day: 'numeric',
                year: 'numeric',
            }),
            weeks: augmentedData.weeks,
            months: intl.formatDate(data.at, {
                month: 'long',
                year: 'numeric',
            }),
            years: intl.formatDate(data.at, {
                year: 'numeric',
            }),
        };

        const tooltipPublishedAt =
            (episodesList &&
                episodesList
                    .filter((episode) => {
                        return (
                            episode?.publishedAt &&
                            dayjs(episode.publishedAt).isBetween(dayjs(startDate), dayjs(endDate))
                        );
                    })
                    .filter(
                        (episode) =>
                            episodeFormatVariationStep(episode, precision) ===
                            augmentedData[precision],
                    )) ??
            [];

        const toolTipPublishedContent = () => {
            if (tooltipPublishedAt.length < 6) {
                return tooltipPublishedAt.map((episodes, index) => (
                    <span key={index}>• {episodes.name}</span>
                ));
            }
            return intl.formatMessage(
                {
                    defaultMessage: '{episodeLength} épisodes',
                },
                {
                    episodeLength: tooltipPublishedAt.length,
                },
            );
        };

        return {
            shouldDisplayPublishedContent: tooltipPublishedAt.length > 0,
            publishedAt:
                precision === 'days' ? intl.formatMessage({ defaultMessage: 'Publié le' }) : '',
            message: tooltipContentMessage[precision],
            content: toolTipPublishedContent(),
        };
    };

    const handleChartMouseMove = useCallback(({ dataPointIndex }) => {
        setHighlightedColumnIndex(dataPointIndex >= 0 ? dataPointIndex : undefined);
    }, []);

    const getSeries = (v: { at: number; downloads: number; variation: number | null }) => {
        const parsed = dayjs.unix(v.at);
        let displayDate = '';
        let longDate = '';
        if (parsed.isValid()) {
            const iso = parsed.toISOString();
            displayDate = intl.formatDate(iso, {
                month: 'numeric',
                day: 'numeric',
                year: 'numeric',
            });
            longDate = intl.formatDate(iso, {
                month: 'long',
                day: 'numeric',
                year: 'numeric',
            });
        }
        return {
            x: parsed.format('YYYY-MM-DD'),
            y: v.downloads,
            variation: v.variation,
            displayDate,
            longDate,
            publishedData: tooltipPublishedContent(v),
        };
    };

    return (
        <GraphOuterWrapper>
            <OuterHeader>
                <TitleAndDescription>
                    <Title>
                        <FormattedMessage defaultMessage="Détails de vos écoutes" />
                    </Title>
                    <Description>
                        <FormattedMessage defaultMessage="Nombre d'écoutes cumulées et auditeurs uniques par épisode." />
                    </Description>
                </TitleAndDescription>
                <Filters />
            </OuterHeader>
            <GraphAndTableOuterWrapper>
                {stats?.data?.mapData.reduce((sum, item) => sum + item.downloads, 0) === 0 ? (
                    <EmptyStateWrapper>
                        <NoDataChart />
                    </EmptyStateWrapper>
                ) : (
                    <>
                        <GraphAndTableWrapper>
                            <GraphWrapper>
                                <GraphHeader
                                    tab={tabValue as string}
                                    isLoading={isLoading}
                                    stats={stats?.data as Stats}
                                    tabs={
                                        <Tabs
                                            selectedKey={tabValue}
                                            onSelectionChange={(t) => {
                                                if (
                                                    t === 'uniqueDownloads' &&
                                                    precision === 'years'
                                                ) {
                                                    setPrecision('days');
                                                }
                                                setTabValue(t);
                                            }}
                                        >
                                            <StyledTabList aria-label="Data type">
                                                <StyledTab id="downloads">
                                                    <FormattedMessage defaultMessage="Écoutes" />
                                                </StyledTab>
                                                <StyledTab
                                                    id="uniqueDownloads"
                                                    isDisabled={uniqueListenersDisabled}
                                                >
                                                    {uniqueListenersDisabled ? (
                                                        <TooltipTrigger delay={0} closeDelay={0}>
                                                            <TooltipTriggerWrapper>
                                                                <FormattedMessage defaultMessage="Auditeurs uniques" />
                                                            </TooltipTriggerWrapper>
                                                            <Tooltip placement="left">
                                                                <FormattedMessage defaultMessage="Les statistiques de vos auditeurs uniques sont réservées à une offre supérieure. Abonnez-vous à l'offre Boost ou Supersonic 🚀" />
                                                            </Tooltip>
                                                        </TooltipTrigger>
                                                    ) : (
                                                        <FormattedMessage defaultMessage="Auditeurs uniques" />
                                                    )}
                                                </StyledTab>
                                            </StyledTabList>
                                        </Tabs>
                                    }
                                    precision={precision}
                                    setPrecision={(precision) => setPrecision(precision)}
                                />
                                {isLoading || !stats?.data ? (
                                    <LoadingWrapper>
                                        <FontAwesomeIcon
                                            icon={icon({ name: 'spinner-third', style: 'solid' })}
                                            spin
                                        />
                                    </LoadingWrapper>
                                ) : (
                                    <Graph
                                        series={[
                                            {
                                                name: intl.formatMessage({
                                                    defaultMessage: 'Écoutes',
                                                }),
                                                data: stats?.data?.mapData.map((v) => getSeries(v)),
                                                color: chartPrimary,
                                            },
                                            {
                                                name: intl.formatMessage({
                                                    defaultMessage: 'Episodes publiés',
                                                }),
                                                data: episodesPublishedWithinDateRange,
                                                color: chartPrimary,
                                            },
                                        ]}
                                        onMouseMove={handleChartMouseMove}
                                    />
                                )}
                            </GraphWrapper>
                            {!isLoading && stats?.data && (
                                <TableWrapper>
                                    <Table
                                        data={[
                                            {
                                                id: 'dates',
                                                name: '',
                                                data: stats?.data.mapData.map((v) => {
                                                    const parsed = dayjs.unix(v.at);
                                                    let xValue = '';
                                                    if (parsed.isValid()) {
                                                        const iso = parsed.toISOString();
                                                        xValue = intl.formatDate(iso, {
                                                            month: 'numeric',
                                                            day: 'numeric',
                                                            year: 'numeric',
                                                        });
                                                    }

                                                    return xValue;
                                                }),
                                            },
                                            {
                                                id: 'downloads',
                                                name: intl.formatMessage({
                                                    defaultMessage: 'Écoutes',
                                                }),
                                                data: stats?.data.mapData.map(
                                                    (v) => `${v.downloads}`,
                                                ),
                                            },
                                            {
                                                id: 'variations',
                                                name: intl.formatMessage({
                                                    defaultMessage: 'Variations',
                                                }),
                                                data: stats?.data.mapData.map(
                                                    (v) => `${Math.round(v.variation ?? 0)}`,
                                                ),
                                            },
                                        ]}
                                        highlightedColumnIndex={highlightedColumnIndex}
                                    />
                                </TableWrapper>
                            )}
                        </GraphAndTableWrapper>
                        <Export
                            isUniqueDownloads={tabValue === 'uniqueDownloads'}
                            precision={precision}
                        />
                    </>
                )}
            </GraphAndTableOuterWrapper>
        </GraphOuterWrapper>
    );
};

const GraphOuterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;
const OuterHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @media (max-width: 1150px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 1rem;
    }
`;
const TitleAndDescription = styled.div``;
const Title = styled.h2`
    font-size: var(--fs-heading-m);
`;
const Description = styled.p``;
const GraphAndTableOuterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;
const GraphAndTableWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;
const GraphWrapper = styled.div`
    background-color: var(--white);
    padding: 1rem;
    border-radius: var(--r-m);
`;
const TableWrapper = styled.div`
    background-color: var(--white);
    padding: 1rem;
    border-radius: var(--r-m);
`;
const StyledTabList = styled(TabList)`
    display: flex;
    align-items: center;
    background-color: var(--neutral100);
    border-radius: var(--r-s);
    padding: 0.25rem;
`;
const StyledTab = styled(Tab)`
    padding-block: 0.25rem;
    padding-inline: 0.75rem;
    border-radius: var(--r-s);
    font-weight: var(--fw-semibold);

    &:not([data-selected]) {
        cursor: pointer;
    }
    &[data-selected] {
        background-color: var(--white);
    }
    &[data-disabled] {
        & * {
            color: var(--neutral300);
            cursor: not-allowed;
        }
    }
`;
const LoadingWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 350px;
`;
const EmptyStateWrapper = styled.div`
    background-color: var(--white);
    padding: 1rem;
    border-radius: var(--r-m);
    padding-block: 4rem;
`;

export default ListenersGraphAndTable;
