import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { FORM_VALIDATION_MESSAGE } from '@/utils/validationMessage';

const useEditPasswordFormSchema = () => {
    const intl = useIntl();

    return yup.object({
        current_password: yup
            .string()
            .min(8, intl.formatMessage(FORM_VALIDATION_MESSAGE.minLength, { min: 8 }))
            .required(intl.formatMessage(FORM_VALIDATION_MESSAGE.required)),
        new_password: yup
            .string()
            .min(8, intl.formatMessage(FORM_VALIDATION_MESSAGE.minLength, { min: 8 }))
            .notOneOf(
                [yup.ref('current_password'), null],
                intl.formatMessage(FORM_VALIDATION_MESSAGE.differentValue),
            )
            .required(intl.formatMessage(FORM_VALIDATION_MESSAGE.required)),
        new_password_confirmation: yup
            .string()
            .oneOf(
                [yup.ref('new_password'), null],
                intl.formatMessage(FORM_VALIDATION_MESSAGE.valuesDontMatch),
            )
            .required(intl.formatMessage(FORM_VALIDATION_MESSAGE.required)),
    });
};

export default useEditPasswordFormSchema;
