import { Control, useController } from 'react-hook-form';
import { default as Input } from '.';

interface InputDurationControllerProps
    extends Omit<React.ComponentProps<typeof Input>, 'value' | 'onChange'> {
    name: string;
    control: Control<any>;
    onChange?: (seconds: number) => void;
}

export const InputDurationController = (props: InputDurationControllerProps) => {
    const { control, name, onChange, onBlur, ...rest } = props;
    const { field, fieldState } = useController({
        name,
        control,
    });

    return (
        <Input
            {...rest}
            onChange={(value) => {
                field.onChange(value);
                onChange && onChange(value);
            }}
            onBlur={(event) => {
                onBlur && onBlur(event);
                field.onBlur();
            }}
            errorMessage={fieldState.error?.message}
            value={field.value}
        />
    );
};
