import { css } from 'styled-components';
import PropTypes from 'prop-types';
import { computeCssValue } from '../../../../shared/utils/css';

/**
 * See https://chakra-ui.com/docs/features/style-props
 */
const marginMixin = css`
    margin: ${(props) => computeCssValue(props.$margin ?? props.$m)};
    ${(props) =>
        props.$mx !== null &&
        props.$mx !== undefined &&
        css`
            margin-inline-start: ${computeCssValue(props.$mx)};
            margin-inline-end: ${computeCssValue(props.$mx)};
        `};
    ${(props) =>
        props.$my !== null &&
        props.$my !== undefined &&
        css`
            margin-top: ${computeCssValue(props.$my)};
            margin-bottom: ${computeCssValue(props.$my)};
        `};
    margin-top: ${(props) => computeCssValue(props.$marginTop ?? props.$mt)};
    margin-right: ${(props) => computeCssValue(props.$marginRight ?? props.$mr)};
    margin-inline-end: ${(props) => computeCssValue(props.$marginEnd ?? props.$me)};
    margin-bottom: ${(props) => computeCssValue(props.$marginBottom ?? props.$mb)};
    margin-left: ${(props) => computeCssValue(props.$marginLeft ?? props.$ml)};
    margin-inline-start: ${(props) => computeCssValue(props.$marginStart ?? props.$ms)};
`;

export const marginPropTypes = {
    $margin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $m: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Shortcut for margin
    $mx: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $my: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $marginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $mt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Shortcut for marginTop
    $marginRight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $mr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Shortcut for marginRight
    $marginEnd: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $me: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Shortcut for marginEnd
    $marginBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $mb: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Shortcut for marginBottom
    $marginLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $ml: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Shortcut for marginLeft
    $marginStart: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $ms: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Shortcut for marginStart
};

export default marginMixin;
