import { computed } from 'mobx';
import Model from './Model';

class StatsVariationModel extends Model {
    static attributes = {
        sumDownloads: 'sum_downloads',
        mapData: {
            fromApi: {
                key: 'map_data',
                resolve(mapData) {
                    const data = mapData.map((c) => ({
                        downloads: c.downloads,
                        variation: c.variation,
                        timestamp: new Date(c.at * 1000),
                    }));
                    return data;
                },
            },
        },
    };

    @computed
    get date() {
        return new Date(this.timestamp * 1000);
    }
}

export default StatsVariationModel;
