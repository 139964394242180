import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { useParams, useLocation } from 'react-router-dom';
import { connect } from '@app/decorators/connect';
import { useResponsive } from '@/utils/hooks/useResponsive';
import arrow from '@public/icons/chevrondown.svg';
import closeIcon from '@public/icons/close.svg';
import searchIcon from '@public/icons/search.svg';
import useDroppable from '../../../utils/hooks/useDroppable';
import DeprecatedPaper from '../../ui/atoms/DeprecatedPaper';
import DeprecatedIcon from '../../ui/atoms/DeprecatedIcon';
import DeprecatedButton from '../../ui/atoms/DeprecatedButton';
import DeprecatedText from '../../ui/atoms/DeprecatedText';
import VerticalDropContainer from '../../ui/atoms/VerticalDropContainer';
import HideFor from '../atoms/HideFor';
import { parseIntArray } from '../../../helpers';
import PortalContent from '../../ui/molecules/PortalContent';
import ItemListDropDownEpisodesCheckbox from '../../ui/molecules/ItemListDropDownEpisodesCheckbox';
import ItemListDropDownCampaigns from '../../ui/molecules/ItemListDropDownCampaign';
import { useSwitch } from '@hooks/useSwitch';
import { parseQuery } from '../../../api/query';
import { USER_OPTIONS } from '../../../utils/userOptions';

const SearchInput = styled.input`
    width: 100%;
    height: 35px;
    background: var(--neutral50);
    padding-left: 35px;
    border: 0;
    color: var(--black);
    font-size: var(--fs-body);
    ::placeholder {
        color: var(--neutral500);
    }
`;

const ItemTypeTab = styled(DeprecatedText).attrs((p) => ({
    color: (p.active && '--primary') || (p.disabled && '--neutral200') || '--neutral500',
    weight: 'normal',
}))`
    position: relative;
    height: 44px;
    display: flex;
    align-items: center;

    margin-left: 15px;
    &:before {
        height: 2px;
        width: 100%;
        top: 0;
    }

    &:before {
        content: '';
        position: absolute;
        transform: scaleY(${(p) => (p.active ? 1 : 0)});
        background: var(--primary);
        transition: all 0.25s;
    }

    &:hover {
        color: var(--primary);
        text-decoration: none;
    }
`;

const Search = ({ placeholder, value, setValue }) => (
    <DeprecatedPaper position="sticky" y={44} z={1}>
        <DeprecatedIcon
            as={searchIcon}
            pos="absolute"
            color="--neutral500"
            x={15}
            y={12}
            size={10}
        />
        <SearchInput
            type="text"
            value={value}
            onChange={(event) => setValue(event.target.value)}
            placeholder={placeholder}
        />
        <DeprecatedIcon
            as={closeIcon}
            pos="absolute"
            color="--neutral500"
            left={null}
            top={8}
            right={12}
            onClick={() => setValue('')}
        />
    </DeprecatedPaper>
);

const CurrentItemSelected = ({
    itemsListSelected,
    episodesList,
    campaignsList,
    itemType,
    itemId,
}) => {
    if (itemType === 'campaign')
        return (
            campaignsList.find((campaignItem) => campaignItem.id.toString() === itemId)?.name || ''
        );

    if (!Array.isArray(itemsListSelected))
        return <FormattedMessage defaultMessage="Tous les épisodes" />;
    if (itemsListSelected.length === 1) {
        const item = episodesList.find((episode) => episode.id === itemsListSelected[0]);
        return item?.name || '';
    }
    return (
        <FormattedMessage
            defaultMessage="{nbEpisodes} épisodes"
            values={{ nbEpisodes: itemsListSelected.length }}
        />
    );
};

const ContentDropDown = ({
    itemSelected,
    setItemSelected,
    hasCampaigns,
    isOpened,
    onClose,
    isMobileOrTablet,
    disabledOptions,
}) => {
    const content = {
        episode: (
            <ItemListDropDownEpisodesCheckbox
                searchBar={Search}
                onClose={onClose}
                disabledOptions={disabledOptions.episodeTab}
            />
        ),
        campaign: (
            <HideFor userOptions={USER_OPTIONS.MANUAL_CAMPAIGNS}>
                <ItemListDropDownCampaigns searchBar={Search} onClose={onClose} />
            </HideFor>
        ),
    };

    const ItemsSelectorTab = () => (
        <DeprecatedPaper flex position="sticky" z={1} background="white" y={0}>
            <ItemTypeTab
                active={itemSelected === 'episode'}
                onClick={() => setItemSelected('episode')}
            >
                <FormattedMessage defaultMessage="Épisodes" />
            </ItemTypeTab>
            {hasCampaigns && (
                <ItemTypeTab
                    active={itemSelected === 'campaign'}
                    onClick={() => setItemSelected('campaign')}
                >
                    <FormattedMessage defaultMessage="Campagnes" />
                </ItemTypeTab>
            )}
        </DeprecatedPaper>
    );

    if (isMobileOrTablet)
        return (
            <PortalContent isOpened={isOpened} onClose={onClose}>
                <ItemsSelectorTab />
                {content[itemSelected]}
            </PortalContent>
        );

    return (
        <VerticalDropContainer
            isOpened={isOpened}
            onClose={onClose}
            x={null}
            xR={0}
            py={0}
            y={39}
            maxH={400}
            rounded
            pos={null}
            w={400}
            useVerticalDropMobile={false}
        >
            <ItemsSelectorTab />
            {content[itemSelected]}
        </VerticalDropContainer>
    );
};

const enhance = connect(({ podcastStore, campaignStore, routerStore }, { getGraphValues }) => ({
    showId: routerStore.params.showId,
    campaigns: campaignStore.campaigns,
    fetchCampaigns: campaignStore.fetchCampaigns,
    fetchEpisodes: podcastStore.fetchShrinkedEpisodes,
    podcastsShrinked: podcastStore.podcastsShrinked.filter(
        (episodes) => episodes.status === 'active' && episodes,
    ),
    tabSwitchSingleListenerValue: getGraphValues?.tabValue,
}));

const StatsDataSetEpisodesDropdown = ({
    active = true,
    campaigns,
    showId,
    fetchCampaigns,
    fetchEpisodes,
    podcastsShrinked,
    tabSwitchSingleListenerValue,
}) => {
    if (!active) return null;

    const { itemType, itemId } = useParams();
    const { search } = useLocation();

    const [ref, opened, open, close] = useDroppable(false);
    const [openedPortal, onOpenPortal, onClosePortal] = useSwitch(false);

    const { isMobileOrTablet } = useResponsive();

    const [selectedItemType, setSelectedItemType] = useState('episode');
    const changeSelector = useCallback(
        (item) => {
            setSelectedItemType(item);
        },
        [setSelectedItemType],
    );
    const { episode } = parseQuery(search);

    useEffect(() => {
        fetchEpisodes(showId);
    }, [selectedItemType === 'episode']);

    useEffect(() => {
        fetchCampaigns(showId);
    }, [selectedItemType === 'campaign']);

    const handleToggleDropDown = () => {
        if (isMobileOrTablet) return openedPortal ? onClosePortal() : onOpenPortal();
        return opened ? close() : open();
    };

    const isOpenDropDown = isMobileOrTablet ? openedPortal : opened;
    const onCloseDropDown = isMobileOrTablet ? onClosePortal : close;

    return (
        <DeprecatedPaper ref={ref} position="relative">
            <DeprecatedButton
                accent="secondary"
                active={opened}
                onClick={handleToggleDropDown}
                fullWidth={isMobileOrTablet}
            >
                <DeprecatedText ellipsis maxW="170px" auto>
                    <CurrentItemSelected
                        itemsListSelected={parseIntArray(episode)}
                        episodesList={podcastsShrinked}
                        campaignsList={campaigns}
                        itemType={itemType}
                        itemId={itemId}
                    />
                </DeprecatedText>
                <DeprecatedIcon as={arrow} ml={10} mr={0} />
            </DeprecatedButton>

            <ContentDropDown
                itemSelected={selectedItemType}
                isOpened={isOpenDropDown}
                onClose={onCloseDropDown}
                hasCampaigns={campaigns.length > 0}
                setItemSelected={changeSelector}
                isMobileOrTablet={isMobileOrTablet}
                disabledOptions={{
                    episodeTab:
                        tabSwitchSingleListenerValue === 'singleListener' &&
                        itemType !== 'campaign',
                }}
            />
        </DeprecatedPaper>
    );
};

export default enhance(StatsDataSetEpisodesDropdown);
