import { InputTextController } from '@ui/atoms/InputText';
import { ToggleController } from '@ui/atoms/Toggle';
import Button from '@ui/atoms/Button';
import Text from '@ui/atoms/Text';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useRssExportFormSchema from './useRssExportFormSchema.hook';
import { useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import TooltipHover from '@ui/smarties/TooltipHover';
import IconHelp from '@ui/icons/IconHelp';
import styled from 'styled-components';
import UnsavedFormModal from '@/components/unorganized/UnsavedFormModal';
import useUpdateRssSettingsMutation from '@queries/rssSettings/useUpdateRssSettingsMutation.hook';
import { useModalToastQueue } from '@/shared/hooks/useModalToastQueue.hook';

const RssExportConfigurationForm = ({ rssSettings }) => {
    const intl = useIntl();
    const { showId } = useParams();
    const updateRssSettings = useUpdateRssSettingsMutation();
    const schema = useRssExportFormSchema();
    const { control, handleSubmit, formState, clearErrors, reset } = useForm({
        mode: 'onChange',
        defaultValues: {
            subtitle: rssSettings.subtitle,
            website: rssSettings.website,
            copyright: rssSettings.copyright,
            ownerName: rssSettings.ownerName,
            ownerEmail: rssSettings.ownerEmail,
            isExplicit: !!rssSettings.isExplicit,
            hasTracking: !!rssSettings.podtrac,
            isLocked: rssSettings.isLocked || false,
        },
        resolver: yupResolver(schema),
    });

    const toast = useModalToastQueue();

    const onSubmit = async (data) => {
        const payload = {
            ...data,
            podtrac: data.hasTracking,
        };
        updateRssSettings.mutate(
            { showId, payload },
            {
                onSuccess: () => {
                    toast.success(
                        <FormattedMessage defaultMessage="Les paramètres ont été mis à jour" />,
                    );
                },
            },
        );
    };

    return (
        <>
            <UnsavedFormModal shouldBlockNavigation={formState.isDirty} />

            <Form onSubmit={handleSubmit(onSubmit)}>
                <InputTextController
                    control={control}
                    name="subtitle"
                    label={<FormattedMessage defaultMessage="Sous-titre" />}
                    placeholder={intl.formatMessage({
                        defaultMessage: `Saisir le sous-titre de l'émission`,
                    })}
                    maxLength={255}
                />
                <InputTextController
                    control={control}
                    name="website"
                    label={<FormattedMessage defaultMessage="Site web" />}
                    placeholder="https://ausha.co/"
                />
                <InputTextController
                    control={control}
                    name="copyright"
                    label={<FormattedMessage defaultMessage="Copyright" />}
                    placeholder={intl.formatMessage({
                        defaultMessage: `Saisir le copyright de l'émission`,
                    })}
                />
                <InputTextController
                    control={control}
                    name="ownerName"
                    label={<FormattedMessage defaultMessage="Nom du propriétaire" />}
                    placeholder="Neil Armstrong"
                />
                <InputTextController
                    control={control}
                    name="ownerEmail"
                    label={<FormattedMessage defaultMessage="Adresse e-mail du propriétaire" />}
                    placeholder="neil.armstrong@nasa.com"
                    tooltip={
                        <TooltipHover arrow="left" target={<IconHelp color="--primary" />}>
                            <Text>
                                <FormattedMessage defaultMessage="Cette information ne sera pas affichée publiquement sur Apple Podcasts / iTunes." />
                            </Text>
                        </TooltipHover>
                    }
                />
                <ToggleWrapper>
                    <Text fontWeight="--fw-semibold">
                        <FormattedMessage defaultMessage="Contenu explicite" />
                    </Text>
                    <ToggleController control={control} name="isExplicit">
                        <FormattedMessage defaultMessage="Votre émission contient du contenu explicite (sexe, drogue & rock'n'roll)" />
                    </ToggleController>
                </ToggleWrapper>
                <ToggleWrapper>
                    <Text fontWeight="--fw-semibold">
                        <FormattedMessage defaultMessage="Activer Podtrac" />
                    </Text>
                    <ToggleController control={control} name="hasTracking">
                        <FormattedMessage defaultMessage="Si vous utilisez Podtrac, vous pouvez activer cette option pour prendre en compte leur redirection." />
                    </ToggleController>
                    <Text color="--neutral500" variant="footnote">
                        <FormattedMessage defaultMessage="Attention, n’activez pas cette option si vous n’utilisez pas Podtrac, sinon vos fichiers deviendront inaccessibles." />
                    </Text>
                </ToggleWrapper>
                <ToggleWrapper>
                    <Text fontWeight="--fw-semibold">
                        <FormattedMessage defaultMessage="Verrouiller le flux RSS" />
                    </Text>
                    <ToggleController control={control} name="isLocked">
                        <FormattedMessage defaultMessage="Empêchez les autres hébergeurs de podcasts d'importer votre émission. " />
                    </ToggleController>
                </ToggleWrapper>
                <ActionWrapper>
                    <Button
                        type="submit"
                        isLoading={updateRssSettings.isLoading}
                        isDisabled={!formState.isValid && !formState.isDirty}
                    >
                        <FormattedMessage defaultMessage="Enregistrer" />
                    </Button>
                    <Button
                        variant="ghost"
                        color="--primary"
                        type="button"
                        onClick={() => {
                            reset({
                                subtitle: '',
                                website: '',
                                copyright: '',
                                ownerName: '',
                                ownerEmail: '',
                                isExplicit: false,
                                hasTracking: false,
                            });
                            clearErrors();
                        }}
                    >
                        <FormattedMessage defaultMessage="Réinitialiser la configuration" />
                    </Button>
                </ActionWrapper>
            </Form>
        </>
    );
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    width: 100%;
`;

const ToggleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
`;

const ActionWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
`;

export default RssExportConfigurationForm;
