export const makeBackgroundUnresponsive = () => {
    const root = document.querySelector('#root');
    root.setAttribute('inert', true);
    root.style.overflow = 'hidden';
};

export const restoreBackgroundResponsiveness = () => {
    const root = document.querySelector('#root');
    root.removeAttribute('inert', true);
    root.style.overflow = 'auto';
};
