import Text from '@/components/ui/atoms/Text';
import Cluster from '@/components/ui/layout/Cluster';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import IconAudioFile from '@/components/ui/icons/IconAudioFile';
import { zodResolver } from '@hookform/resolvers/zod';
import FileMenu from './FileMenu';
import { useQueryClient } from '@tanstack/react-query';
import { formatSecondsDuration } from '@/shared/utils/duration';
import useCampaignQuery, { CampaignFile } from '@/queries/campaign/useCampaignQuery';
import useCampaignAudioFileFormSchema, {
    CampaignAudioFileFormSchema,
} from './useAudioFileFormSchema';
import uploadCampaignAudioFileMutation from '@/queries/campaign/uploadCampaignAudioFileMutation';
import campaignKeys from '@/queries/campaign/campaignKeys';
import { CAMPAIGN_STATUS } from '@/shared/config/constants';
import { TooltipTrigger } from 'react-aria-components';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';

interface FileUploadedProps {
    file: CampaignFile;
    audioUrl: string | null | undefined;
    onUpload: ReturnType<typeof uploadCampaignAudioFileMutation>['mutate'];
}

const FileUploaded = ({ file, audioUrl, onUpload }: FileUploadedProps) => {
    const intl = useIntl();
    const { campaignId } = useParams<{ campaignId: string }>();
    const { data: campaign } = useCampaignQuery(campaignId);
    const schema = useCampaignAudioFileFormSchema();
    const { watch, handleSubmit, formState, setValue, setError } =
        useForm<CampaignAudioFileFormSchema>({
            mode: 'onChange',
            resolver: zodResolver(schema),
        });
    const { errors } = formState;
    const fileDuration = formatSecondsDuration(file?.duration);
    const queryClient = useQueryClient();
    const isActive =
        campaign?.state === CAMPAIGN_STATUS.PAUSED ||
        campaign?.state === CAMPAIGN_STATUS.ACTIVE ||
        campaign?.state === CAMPAIGN_STATUS.PLANIFIED;

    useEffect(() => {
        const subscription = watch(() => {
            handleSubmit(onSubmit)();
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    const onSubmit = ({ audioFile }: CampaignAudioFileFormSchema) => {
        if (!audioFile || isActive) return;
        onUpload(
            { campaignId, file: audioFile[0] },
            {
                onSuccess: () => {
                    queryClient.invalidateQueries({
                        queryKey: campaignKeys.detailById(campaignId),
                    });
                },
                onError: () =>
                    setError('root.serverError', {
                        type: '400',
                        message: intl.formatMessage({ defaultMessage: 'Une erreur est survenue' }),
                    }),
            },
        );
    };

    const handleEditFile = (file: File[]) => {
        setValue('audioFile', file, { shouldValidate: true, shouldDirty: true });
    };

    if (!file) return null;

    return (
        <>
            <Wrapper>
                <InnerWrapper>
                    <Cluster $gap="0.5rem" $align="center" $justify="space-between" $wrap="nowrap">
                        <IconAudioFile size="2rem" />
                        <FileInfoWrapper>
                            <Text
                                variant="bodyM"
                                fontWeight="--fw-semibold"
                                numberOfLines={1}
                                data-testid="episode-form-file-uploaded-name"
                            >
                                {file.key}
                            </Text>
                            <Text variant="bodyM" color="--neutral500" numberOfLines={1}>
                                {fileDuration}
                            </Text>
                        </FileInfoWrapper>
                    </Cluster>
                    {isActive ? (
                        <TooltipTrigger delay={0} closeDelay={0}>
                            <TooltipTriggerWrapper>
                                <TooltipIcon icon={icon({ name: 'info-circle', style: 'solid' })} />
                            </TooltipTriggerWrapper>
                            <Tooltip placement="top">
                                <FormattedMessage defaultMessage="Il n'est pas possible de modifier le fichier audio d'une campagne lorsque celle-ci est publiée." />
                            </Tooltip>
                        </TooltipTrigger>
                    ) : (
                        <FileMenu handleEdit={handleEditFile} audioUrl={audioUrl} />
                    )}
                </InnerWrapper>

                {errors?.audioFile && (
                    <ErrorMessage>
                        <AlertIcon icon={icon({ name: 'exclamation-circle', style: 'solid' })} />
                        <Text color="--alert500">
                            {errors?.audioFile?.message ?? errors?.root?.serverError}
                        </Text>
                    </ErrorMessage>
                )}
            </Wrapper>
        </>
    );
};
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    width: 100%;
`;
const InnerWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 0.5rem;
    width: 100%;
`;
const FileInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 250px;

    ${({ theme }) => theme.mediaQueries.mobile} {
        max-width: 200px;
    }
`;
const MenuItemIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const AlertIcon = styled(MenuItemIcon)`
    color: var(--alert500);
`;
const TooltipIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: var(--neutral500);
`;
const ErrorMessage = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    width: 100%;
`;

export default FileUploaded;
