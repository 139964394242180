import { computed, observable, action } from 'mobx';
import validationMessages from '../validators/validationMessages';
import { messageParsers } from '../validators/apiValidation';

export default class Field {
    @observable value;
    @observable default;
    @observable hasChanged = false;
    @observable error;
    @observable validators = [];

    constructor(name, field, form) {
        this.form = form;
        this.name = name;
        this._type = field.type;
        this.resolver = field.resolver;
        this.mapping = field.mapping || name;
        this.defaultError = field.defaultError;
        this.value = field.default;
        this.default = field.default;
        this._validators = field.validators || this.validators;

        // Validators must be computed early to add the correct attributes to DOM elements (type, required, etc)
        this.computeValidators();
    }

    @action.bound
    computeValidators() {
        if (typeof this._validators === 'function') {
            this.validators = this._validators(this.form);
        } else {
            this.validators = this._validators;
        }
    }

    @action.bound
    reset() {
        this.value = this.default;
        this.hasChanged = false;
    }

    @action.bound
    setDefaultValue(value) {
        this.default = value || this.value;
    }

    @action.bound
    makeEmpty() {
        this.value = undefined;
        this.hasChanged = true;
    }

    @action.bound
    setValue(value) {
        if (!this.hasChanged) {
            this.hasChanged = true;
        }
        this.value = value;
        this.validate();
    }

    @action.bound
    errorFromApi(fails) {
        if (this.value && this.value.map && this.value.length > 0 && this.value[0].errorFromApi) {
            this.value.map((f) => f.errorFromApi(fails));
            return;
        }

        if (!(this.mapping in fails)) {
            return;
        }

        const error = fails[this.mapping];
        const [key, values] = Object.entries(error)[0];
        if (messageParsers[key]) {
            this.error = messageParsers[key](...values, this.mapping);
        } else {
            this.error = validationMessages.default;
        }
    }

    @action.bound
    validate() {
        this.error = null;
        this.computeValidators();
        for (let i = 0; i < this.validators.length && !this.error; i += 1) {
            this.error = this.validators[i].validate(this.value, this.form);
        }
    }

    @computed
    get maxLength() {
        const validator = this.validators.find((f) => f.name === 'maxLength');
        if (!validator) {
            return undefined;
        }
        return validator.value;
    }

    @computed
    get min() {
        const validator = this.validators.find((f) => f.name === 'minNumber');
        if (!validator) {
            return undefined;
        }
        return validator.value.toString();
    }

    @computed
    get max() {
        const validator = this.validators.find((f) => f.name === 'maxNumber');
        if (!validator) {
            return undefined;
        }
        return validator.value.toString();
    }

    @computed
    get type() {
        if (this._type) {
            return this._type;
        }

        let validator = this.validators.find((f) => f.name === 'isEmail');
        if (validator) {
            return 'email';
        }
        validator = this.validators.find((f) => f.name === 'isUrl');
        if (validator) {
            return 'url';
        }
        return undefined;
    }

    @computed
    get isRequired() {
        const validator = this.validators.find((f) => f.name === 'isRequired');
        return !!validator;
    }

    @computed.struct
    get json() {
        if (this.resolver) {
            return { [this.mapping]: this.resolver(this.value) };
        }
        return { [this.mapping]: this.value };
    }
}
