import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import statsKeys from '@queries/stats/statsKeys';
import { fetchAgesExportCsv } from '@/api';
import { z } from 'zod';

interface AgesExportQueryProps {
    start: string | null;
    end: string | null;
}

interface AgesExportQueryParams {
    showId: string;
    query: AgesExportQueryProps;
}

const AgesExportSchema = z.object({
    filename: z.string(),
    ages: z.string(),
});

export type AgesExport = z.infer<typeof AgesExportSchema>;

type Key = QueryFunctionContext<ReturnType<typeof statsKeys.agesExport>>;

const queryFn = async ({ queryKey: [{ showId, query }] }: Key) => {
    const { axios, ...data } = await fetchAgesExportCsv(showId, query);
    const filename = axios.headers['content-disposition'].match(/filename="(.+)"/)[1];

    const formattedData = {
        filename,
        ages: Object.values(data).join(''),
    };

    return AgesExportSchema.parse(formattedData);
};

const useAgesExportQuery = ({ showId, query }: AgesExportQueryParams) => {
    return useQuery({
        queryKey: statsKeys.agesExport(showId, query),
        queryFn,
        enabled: !!showId,
    });
};

export default useAgesExportQuery;
