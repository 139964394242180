import { useMutation, useQueryClient } from '@tanstack/react-query';
import useUpdateCallToActionButtonsPositionMutation from '@/queries/callToAction/useUpdateCallToActionButtonsPositionMutation.hook';
import { NewsletterFormSchema } from '../NewsletterForm/useNewsletterFormSchema';
import newsletterKeys from '@/queries/newsletter/newsletterKeys';
import updateShowNewsletterTemplateMutation from '@/queries/newsletter/updateShowNewsletterTemplateMutation';

const useSaveNewsletterSettingsMutation = () => {
    const queryClient = useQueryClient();
    const updateShowNewsletterTemplate = updateShowNewsletterTemplateMutation();
    const updateCallToActionButtonsPosition = useUpdateCallToActionButtonsPositionMutation();

    return useMutation({
        mutationFn: ({
            showId,
            settings: data,
        }: {
            showId: string;
            settings: NewsletterFormSchema;
        }) => {
            const { callToActions, ...template } = data;
            const promises = [];

            if (template && Object.keys(template).length > 0) {
                const newsletterSettingsPromise = updateShowNewsletterTemplate.mutateAsync({
                    showId,
                    template,
                });
                promises.push(newsletterSettingsPromise);
            }

            if (callToActions && callToActions.length > 0) {
                const updateCallToActionsPromise = updateCallToActionButtonsPosition.mutateAsync({
                    showId,
                    location: 'newsletter',
                    callToActionButtons: callToActions,
                });
                promises.push(updateCallToActionsPromise);
            }

            // Waiting for all promises to be fulfilled or rejected
            return Promise.allSettled(promises);
        },
        onSuccess: (_, { showId }) => {
            queryClient.invalidateQueries({
                queryKey: newsletterKeys.templateByShowId(showId),
            });
        },
    });
};

export default useSaveNewsletterSettingsMutation;
