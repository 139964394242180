import { FormattedMessage } from 'react-intl';
import DeprecatedModal from '../../ui/molecules/DeprecatedModal';
import DeprecatedText from '../../ui/atoms/DeprecatedText';
import DeprecatedPaper from '../../ui/atoms/DeprecatedPaper';
import Tooltip from '../../ui/atoms/Tooltip';
import DeprecatedIcon from '../../ui/atoms/DeprecatedIcon';
import info from '@public/icons/info-full.svg';
import DeprecatedButton from '../../ui/atoms/DeprecatedButton';
import { connect } from '../decorators/connect';
import Spinner from '../../ui/atoms/Spinner';
import { isNegativePrice } from '../../../helpers';
import { useQueryClient } from '@tanstack/react-query';
import subscriptionKeys from '@/queries/subscription/subscriptionKeys';
import priceKeys from '@/queries/price/priceKeys';
import quotaKeys from '@/queries/quota/quotaKeys';
import { QUOTA_TYPES } from '@/utils/constants';

const enhance = connect(({ userStore, subscriptionStore, quotaStore }) => {
    const user = userStore.user;

    return {
        currentMethodPayment: user
            ? subscriptionStore.getSubscription.currentMethodPayment
            : 'null',
        updateQuota: (type, quota) => quotaStore.updateQuota(type, quota),
        isLoading: quotaStore.updateQuota.pending,
        newQuota: quotaStore.newQuota,
        pricesQuota: quotaStore.pricesQuota,
        isNegative: isNegativePrice(quotaStore.pricesQuota.cost),
    };
});

const QuotaConfirmModal = ({
    isOpened,
    onClose,
    updateQuota,
    newQuota,
    currentMethodPayment,
    pricesQuota,
    isNegative,
    isLoading,
    type,
}) => {
    const queryClient = useQueryClient();
    const submitQuota = async () => {
        await updateQuota(type, newQuota);

        /**
         * Invalidate quota related queries
         */
        if (type === QUOTA_TYPES.NEWSLETTER) {
            queryClient.invalidateQueries(quotaKeys.detailByType(QUOTA_TYPES.NEWSLETTER));
        }
        if (type === QUOTA_TYPES.TRANSCRIPT) {
            queryClient.invalidateQueries(quotaKeys.detailByType(QUOTA_TYPES.TRANSCRIPT));
        }
        queryClient.invalidateQueries(subscriptionKeys.all());
        queryClient.invalidateQueries(priceKeys.all());
        onClose();
    };

    return (
        <DeprecatedModal
            maxW={400}
            w={400}
            title={<FormattedMessage defaultMessage="Confirmer mon nouveau quota" />}
            onClose={onClose}
            isOpened={isOpened}
        >
            <DeprecatedText>
                <FormattedMessage defaultMessage="En confirmant, votre abonnement sera de" />
            </DeprecatedText>
            <DeprecatedPaper flex mb={20}>
                <DeprecatedText as="span" weight="semibold" size="big" mr={5}>
                    {pricesQuota.total}
                </DeprecatedText>
                <DeprecatedText as="span" weight="semibold" size="big">
                    <FormattedMessage
                        defaultMessage="{symbol} {currentMethodPayment}"
                        values={{
                            symbol: '/',
                            currentMethodPayment,
                        }}
                    />
                </DeprecatedText>
            </DeprecatedPaper>
            {!isNegative && (
                <Tooltip type="info" alignItems="center">
                    <DeprecatedIcon as={info} color="--neutral500" mr={10} />
                    <DeprecatedText>
                        <FormattedMessage
                            defaultMessage="Vous serez immédiatement prélevé de {cost} en raison de ce changement."
                            values={{ cost: pricesQuota.cost }}
                        />
                    </DeprecatedText>
                </Tooltip>
            )}
            <DeprecatedPaper flex justify="flex-end" mt={30}>
                <DeprecatedButton type="button" mr={10} accent="secondary" onClick={onClose}>
                    <FormattedMessage defaultMessage="Annuler" />
                </DeprecatedButton>
                <DeprecatedButton onClick={() => submitQuota()} disabled={isLoading}>
                    {isLoading && <Spinner />}
                    <FormattedMessage defaultMessage="Modifier mon quota" />
                </DeprecatedButton>
            </DeprecatedPaper>
        </DeprecatedModal>
    );
};

export default enhance(QuotaConfirmModal);
