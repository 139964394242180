import { useContext } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import info from '@public/icons/info.svg';
import { RouteSettingsPageContext } from '@app/pages/SettingsPage';
import { useResponsive } from '@/utils/hooks/useResponsive';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import Img from '@ui/atoms/Img';
import DeprecatedButton from '@ui/atoms/DeprecatedButton';
import ShowDeleteModal from '@app/molecules/ShowDeleteModal';
import DeprecatedIcon from '@ui/atoms/DeprecatedIcon';
import { connect } from '@app/decorators/connect';
import { useSwitch } from '@hooks/useSwitch';
import { useAmplitudeLogEvent } from '@hooks/useAmplitude';
import styled from 'styled-components';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import { useParams } from 'react-router';
import useUserQuery from '@/queries/user/useUserQuery.hook';

const enhance = connect(({ routerStore }) => ({
    show: routerStore.resolve('showId'),
}));

function ShowDelete() {
    useAmplitudeLogEvent('show delete show');

    const { showId } = useParams();
    const show = useShowQuery(showId);

    const { data: user } = useUserQuery();
    const userIsPmp = user?.canHostOnAusha === false;

    const [opened, open, close] = useSwitch(false);
    const { isMobileOrTablet } = useResponsive();
    const { mobile, 'show-delete': showDelete } = useContext(RouteSettingsPageContext);

    if (show.isLoading || show.isError) return null;

    return (
        <>
            <ShowDeleteModal show={show.data} isOpened={opened} onClose={close} />
            <DeprecatedText weight="bold" size="veryBig" mb={5}>
                {isMobileOrTablet ? mobile : showDelete}
            </DeprecatedText>
            <DeprecatedText color="--neutral500" mb={30}>
                <FormattedMessage defaultMessage="Vous n’allez quand même pas nous quitter..." />
            </DeprecatedText>
            <ShowDeleteWrapper>
                <Img
                    bordered
                    src={show.data.imageUrl}
                    size={160}
                    ml={isMobileOrTablet ? 'auto' : undefined}
                    mr={isMobileOrTablet ? 'auto' : 25}
                />
                <DeprecatedPaper
                    flex
                    column
                    align={isMobileOrTablet && 'center'}
                    mt={isMobileOrTablet && 10}
                >
                    <DeprecatedText weight="semibold" size="big" mb={5}>
                        {show.data.name}
                    </DeprecatedText>
                    <DeprecatedText color="--neutral500" mb={10}>
                        <FormattedMessage
                            defaultMessage="Créée le {date}"
                            values={{
                                date: <FormattedDate value={show.data.createdAt} />,
                            }}
                        />
                    </DeprecatedText>
                    {!userIsPmp && show.data.podcastsCount > 0 ? (
                        <DeprecatedPaper
                            p={10}
                            rounded
                            background="--alert50"
                            align="center"
                            maxW={485}
                            flex
                        >
                            <DeprecatedIcon as={info} color="--alert" mr={10} size="13px" />
                            <DeprecatedText
                                w="fit-content"
                                size="small"
                                weight="semibold"
                                color="--alert"
                            >
                                <FormattedMessage
                                    defaultMessage="Votre émission contient {count, plural, one {# épisode} other {# épisodes}}. Vous devez {count, plural, one {le} other {les}} supprimer ou {count, plural, one {le} other {les}} déplacer avant de pouvoir supprimer votre émission."
                                    values={{ count: show.data.podcastsCount }}
                                />
                            </DeprecatedText>
                        </DeprecatedPaper>
                    ) : (
                        <div>
                            <DeprecatedButton
                                accent="secondary"
                                onClick={open}
                                w={isMobileOrTablet && '100%'}
                            >
                                <FormattedMessage defaultMessage="Supprimer l'émission" />
                            </DeprecatedButton>
                        </div>
                    )}
                </DeprecatedPaper>
            </ShowDeleteWrapper>
        </>
    );
}

const ShowDeleteWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding);
    display: flex;

    ${({ theme }) => theme.mediaQueries.mobile} {
        padding: var(--container-padding-mobile);
        flex-direction: column;
    }
`;
export default enhance(ShowDelete);
