import { createContext, useContext, useEffect, useState } from 'react';
import { AICustomPromptFormSchema } from '@/components/EpisodeAI/EpisodeAICustomPrompt/EpisodeAICustomPromptForm/useAICustomPromptFormSchema.hook';
import { useParams } from 'react-router-dom';
import useAITitleSuggestionsQuery from '@queries/ai/useAITitleSuggestionsQuery.hook';
import useAIDescriptionSuggestionsQuery from '@queries/ai/useAIDescriptionSuggestionQuery.hook';
import useAITagsSuggestionsQuery from '@queries/ai/useAITagsSuggestionsQuery.hook';
import {
    EPISODE_AI_LOADING_ACTIONS,
    useEpisodeAILoading,
} from '@/components/EpisodeAI/contexts/EpisodeAILoadingContext';
import { useAIRegenerationLogger } from '../utils/useAIRegenarationLogger.hook';
import { useAIGenerationLogger } from '../utils/useAIGenerationLogger.hook';
import useAIChaptersSuggestionsQuery from '@/queries/ai/useAIChaptersSuggestionsQuery.hook';
import useAICustomPromptsQuery from '@/queries/ai/useAICustomPromptsQuery.hook';
import useAINewsletterTitleSuggestionsQuery from '@/queries/ai/useAINewsletterTitleSuggestionQuery.hook';
import useAINewsletterMessageSuggestionsQuery from '@/queries/ai/useAINewsletterMessageSuggestionQuery.hook';

export type AISettingsMapItem =
    | 'title'
    | 'description'
    | 'tags'
    | 'newsletter'
    | 'newsletter-title'
    | 'newsletter-message';
type SettingsMap = Map<AISettingsMapItem, AICustomPromptFormSchema>;

type IEpisodeAISettingsContext = [
    SettingsMap,
    (type: AISettingsMapItem, data: AICustomPromptFormSchema) => void,
];

const defaultSettings = {
    customPrompt: '',
    saveAsDefault: false,
};
const initialSettingsState: SettingsMap = new Map([
    ['title', defaultSettings],
    ['description', defaultSettings],
    ['tags', defaultSettings],
    ['newsletter', defaultSettings],
]);

const EpisodeAISettingsContext = createContext<IEpisodeAISettingsContext>([
    initialSettingsState,
    () => {},
]);

const getSessionSettings = (episodeId: string) => {
    const settingsMap = new Map();
    initialSettingsState.forEach((value, key) => {
        const sessionSettings = sessionStorage.getItem(`episode-${episodeId}-ai-${key}-settings`);
        const defaultSettings = sessionSettings ? JSON.parse(sessionSettings) : value;
        settingsMap.set(key, defaultSettings);
    });
    return settingsMap;
};

interface EpisodeAISettingsContextProviderProps {
    children: React.ReactNode;
}

const EpisodeAISettingsContextProvider = ({ children }: EpisodeAISettingsContextProviderProps) => {
    const { showId, episodeId } = useParams<{ showId: string; episodeId: string }>();
    const [_, dispatch] = useEpisodeAILoading();
    const [settings, setSettings] = useState(getSessionSettings(episodeId));
    const [settingsToRefetch, setSettingsToRefetch] = useState<AISettingsMapItem | null>(null);
    const defaultSettings = {
        customPrompt: '',
        saveAsDefault: false,
    };
    const titleSettings = settings.get('title') || defaultSettings;
    const descriptionSettings = settings.get('description') || defaultSettings;
    const tagsSettings = settings.get('tags') || defaultSettings;
    const newsletterSettings = settings.get('newsletter') || defaultSettings;
    const customPromptSettings = useAICustomPromptsQuery({
        showId,
    });

    const titleSuggestionsQuery = useAITitleSuggestionsQuery({
        episodeId,
        settings: titleSettings,
        delay: 2000,
    });
    const descriptionSuggestionsQuery = useAIDescriptionSuggestionsQuery({
        episodeId,
        settings: descriptionSettings,
        delay: 2000,
    });
    const tagsSuggestionsQuery = useAITagsSuggestionsQuery({
        episodeId,
        settings: tagsSettings,
        delay: 2000,
    });
    const newsletterTitleSuggestionsQuery = useAINewsletterTitleSuggestionsQuery({
        episodeId,
        settings: newsletterSettings,
        delay: 2000,
    });
    const newsletterMessageSuggestionsQuery = useAINewsletterMessageSuggestionsQuery({
        episodeId,
        settings: newsletterSettings,
        delay: 2000,
    });
    const chaptersSuggestionsQuery = useAIChaptersSuggestionsQuery({
        episodeId,
    });

    /*
     * The following useEffect hook is used to update the custom prompt settings
     * for each field (title, description, tags, newsletter) based on the data
     * fetched from the customPromptSettings query.
     * Note: Settings should be updated only if the custom prompt is not already set.
     */
    useEffect(() => {
        if (!customPromptSettings.data) return;
        const sessionSettings = getSessionSettings(episodeId);
        const newSettings = new Map(settings);
        const titlePrompt =
            customPromptSettings.data.find((s) => s.field === 'title')?.prompt || '';
        const descriptionPrompt =
            customPromptSettings.data.find((s) => s.field === 'description')?.prompt || '';
        const tagsPrompt = customPromptSettings.data.find((s) => s.field === 'tags')?.prompt || '';
        const newsletterPrompt =
            customPromptSettings.data.find((s) => s.field === 'newsletter')?.prompt || '';

        if (!sessionSettings.get('title').customPrompt && titlePrompt.length > 0) {
            newSettings.set('title', {
                ...titleSettings,
                customPrompt: titlePrompt,
            });
        }

        if (!sessionSettings.get('description').customPrompt && descriptionPrompt.length > 0) {
            newSettings.set('description', {
                ...descriptionSettings,
                customPrompt: descriptionPrompt,
            });
        }

        if (!sessionSettings.get('tags').customPrompt && tagsPrompt.length > 0) {
            newSettings.set('tags', {
                ...tagsSettings,
                customPrompt: tagsPrompt,
            });
        }

        if (!sessionSettings.get('newsletter').customPrompt && newsletterPrompt.length > 0) {
            newSettings.set('newsletter', {
                ...newsletterSettings,
                customPrompt: newsletterPrompt,
            });
        }

        setSettings(newSettings);
    }, [customPromptSettings.data]);

    useEffect(() => {
        if (!settingsToRefetch) return;
        switch (settingsToRefetch) {
            case 'title':
                titleSuggestionsQuery.refetch();
                localStorage.setItem(`episode-ai-${episodeId}-regenerated-title`, 'true');
                dispatch({
                    type: EPISODE_AI_LOADING_ACTIONS.START,
                    payload: 'title',
                });
                break;
            case 'description':
                descriptionSuggestionsQuery.refetch();
                localStorage.setItem(`episode-ai-${episodeId}-regenerated-description`, 'true');
                dispatch({
                    type: EPISODE_AI_LOADING_ACTIONS.START,
                    payload: 'description',
                });
                break;
            case 'tags':
                tagsSuggestionsQuery.refetch();
                localStorage.setItem(`episode-ai-${episodeId}-regenerated-tags`, 'true');
                dispatch({
                    type: EPISODE_AI_LOADING_ACTIONS.START,
                    payload: 'tags',
                });
                break;
            case 'newsletter':
                newsletterTitleSuggestionsQuery.refetch();
                localStorage.setItem(
                    `episode-ai-${episodeId}-regenerated-newsletter-title`,
                    'true',
                );
                dispatch({
                    type: EPISODE_AI_LOADING_ACTIONS.START,
                    payload: 'newsletter-title',
                });
                newsletterMessageSuggestionsQuery.refetch();
                localStorage.setItem(
                    `episode-ai-${episodeId}-regenerated-newsletter-message`,
                    'true',
                );
                dispatch({
                    type: EPISODE_AI_LOADING_ACTIONS.START,
                    payload: 'newsletter-message',
                });
                break;
            case 'newsletter-title':
                newsletterTitleSuggestionsQuery.refetch();
                localStorage.setItem(
                    `episode-ai-${episodeId}-regenerated-newsletter-title`,
                    'true',
                );
                dispatch({
                    type: EPISODE_AI_LOADING_ACTIONS.START,
                    payload: 'newsletter-title',
                });
                break;
            case 'newsletter-message':
                newsletterMessageSuggestionsQuery.refetch();
                localStorage.setItem(
                    `episode-ai-${episodeId}-regenerated-newsletter-message`,
                    'true',
                );
                dispatch({
                    type: EPISODE_AI_LOADING_ACTIONS.START,
                    payload: 'newsletter-message',
                });
                break;
            default:
                break;
        }
        setSettingsToRefetch(null);
    }, [settingsToRefetch]);

    useAIGenerationLogger({
        field: 'title',
        suggestions: titleSuggestionsQuery.data?.suggestions,
        episodeId,
        prompt: settings.get('title')?.customPrompt,
        isFetching: titleSuggestionsQuery.isFetching,
    });
    useAIGenerationLogger({
        field: 'description',
        suggestions: descriptionSuggestionsQuery.data?.suggestion,
        episodeId,
        prompt: settings.get('description')?.customPrompt,
        isFetching: descriptionSuggestionsQuery.isFetching,
    });
    useAIGenerationLogger({
        field: 'tags',
        suggestions: tagsSuggestionsQuery.data?.suggestions,
        episodeId,
        prompt: settings.get('tags')?.customPrompt,
        isFetching: tagsSuggestionsQuery.isFetching,
    });
    useAIGenerationLogger({
        field: 'chapters',
        suggestions: chaptersSuggestionsQuery.data?.suggestions,
        episodeId,
        isFetching: chaptersSuggestionsQuery.isFetching,
    });
    useAIGenerationLogger({
        field: 'newsletter-title',
        suggestions: newsletterTitleSuggestionsQuery.data,
        episodeId,
        prompt: settings.get('newsletter')?.customPrompt,
        isFetching: newsletterTitleSuggestionsQuery.isFetching,
    });
    useAIGenerationLogger({
        field: 'newsletter-message',
        suggestions: newsletterMessageSuggestionsQuery.data,
        episodeId,
        prompt: settings.get('newsletter')?.customPrompt,
        isFetching: newsletterMessageSuggestionsQuery.isFetching,
    });

    useAIRegenerationLogger({
        field: 'title',
        suggestions: titleSuggestionsQuery.data?.suggestions,
        episodeId,
        prompt: settings.get('title')?.customPrompt,
    });
    useAIRegenerationLogger({
        field: 'description',
        suggestions: descriptionSuggestionsQuery.data?.suggestion,
        episodeId,
        prompt: settings.get('description')?.customPrompt,
    });
    useAIRegenerationLogger({
        field: 'tags',
        suggestions: tagsSuggestionsQuery.data?.suggestions,
        episodeId,
        prompt: settings.get('tags')?.customPrompt,
    });
    useAIRegenerationLogger({
        field: 'newsletter-title',
        suggestions: newsletterTitleSuggestionsQuery.data,
        episodeId,
        prompt: settings.get('newsletter')?.customPrompt,
    });
    useAIRegenerationLogger({
        field: 'newsletter-message',
        suggestions: newsletterMessageSuggestionsQuery.data,
        episodeId,
        prompt: settings.get('newsletter')?.customPrompt,
    });

    const handleChangeSettings = (type: AISettingsMapItem, data: AICustomPromptFormSchema) => {
        // Keep type as 'newsletter' for newsletter-title and newsletter-message settings
        const forwardedType =
            type === 'newsletter-title' || type === 'newsletter-message' ? 'newsletter' : type;
        if (!settings.has(forwardedType)) return;
        const newSettings: SettingsMap = new Map(settings);
        newSettings.set(forwardedType, data);
        setSettings(newSettings);
        // type can be 'newsletter-title' or 'newsletter-message'
        setSettingsToRefetch(type);
    };

    return (
        <EpisodeAISettingsContext.Provider value={[settings, handleChangeSettings]}>
            {children}
        </EpisodeAISettingsContext.Provider>
    );
};

export const useEpisodeAISettings = (): IEpisodeAISettingsContext => {
    const context = useContext(EpisodeAISettingsContext);
    if (!context) {
        throw new Error(
            'useEpisodeAISettings must be used within a EpisodeAISettingsContextProvider',
        );
    }
    return context;
};

export default EpisodeAISettingsContextProvider;
