import Text from '@/components/ui/atoms/Text';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import aiLight from '@public/videos/ai-light.mp4';
import Stack from '@/components/ui/layout/Stack';
import UpgradeBadge from '@/components/ui/atoms/UpgradeBadge';
import { TooltipTrigger } from 'react-aria-components';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';
import EpisodeAIUnauthorizedStateAction from './EpisodeAIUnauthorizedStateAction';

interface EpisodeAIUnauthorizedStateProps {
    testId: string;
}

const EpisodeAIUnauthorizedState = ({ testId }: EpisodeAIUnauthorizedStateProps) => {
    return (
        <Container>
            <Header>
                <HeaderInner>
                    <TitleWrapper>
                        <TitleIcon icon={icon({ name: 'sparkles', style: 'duotone' })} />
                        <Text fontWeight="--fw-semibold">Ausha Intelligence </Text>
                        <TooltipTrigger delay={0} closeDelay={0}>
                            <TooltipTriggerWrapper>
                                <InfoIcon icon={icon({ name: 'circle-info', style: 'solid' })} />
                            </TooltipTriggerWrapper>
                            <Tooltip placement="top">
                                <FormattedMessage defaultMessage="Générez automatiquement le titre, la description, les tags, les chapitres et le contenu de la newsletter de votre podcast." />
                            </Tooltip>
                        </TooltipTrigger>
                    </TitleWrapper>
                    <UpgradeBadge variant="nebula">Add-on</UpgradeBadge>
                </HeaderInner>
                <Text color="--neutral500">
                    <FormattedMessage defaultMessage="Laissez la magie de l’IA opérer et vous suggérer les contenus de votre podcast." />
                </Text>
            </Header>
            <Video muted autoPlay loop playsInline>
                <source src={aiLight} type="video/mp4" />
            </Video>
            <Stack $gap="1rem">
                <EpisodeAIUnauthorizedStateAction testId={testId} />
                <TranscriptionMessage>
                    <TranscriptionMessageIcon
                        icon={icon({ name: 'circle-info', style: 'solid' })}
                    />
                    <Text>
                        <FormattedMessage defaultMessage="AI nécessite la génération de transcriptions." />
                    </Text>
                </TranscriptionMessage>
            </Stack>
        </Container>
    );
};

const Container = styled.div`
    background: var(--gradient-aurora);
    border-radius: var(--r-l);
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`;
const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
`;
const TitleIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--primary);
`;
const InfoIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
    width: 0.75rem;
    height: 0.75rem;
`;
const Header = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
`;
const Video = styled.video`
    display: none;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: block;
        border-radius: var(--r-m);
        width: 100%;
        height: 10rem;
        object-fit: cover;
    }
`;
const TranscriptionMessage = styled.div`
    align-self: center;
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    color: var(--info);
`;
const TranscriptionMessageIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const HeaderInner = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 0.5rem;
`;

export default EpisodeAIUnauthorizedState;
