import { useParams } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { Menu, MenuItem, MenuTrigger, Popover } from 'react-aria-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Button from '@/components/Button';
import styled from 'styled-components';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import { USER_ROLES } from '@/shared/config/constants';
import type { ImportedEpisode } from '@queries/rss/useImportedEpisodesQuery.hook';
import useRetryImportMutation from '@/queries/rss/useRetryImportMutation.hook';

interface ContextualMenuProps {
    episode: ImportedEpisode;
}

const ContextualMenu = ({ episode }: ContextualMenuProps) => {
    const { showId } = useParams<{ showId: string }>();
    const { data: show } = useShowQuery(showId);
    const retryImport = useRetryImportMutation();

    const userHasWriteAccess =
        show &&
        ![USER_ROLES.VIEWER, USER_ROLES.EDITOR].includes(show.userRole as 'viewer' | 'editor');

    return (
        <MenuTrigger>
            <MenuButton
                aria-label="Menu"
                variant="ghost"
                startIcon={
                    <FontAwesomeIcon icon={icon({ name: 'ellipsis', style: 'solid' })} fixedWidth />
                }
            />
            <StyledPopover>
                <StyledMenu>
                    {userHasWriteAccess && (
                        <StyledMenuItem
                            onAction={() => {
                                retryImport.mutate({
                                    episodeId: `${episode?.id}`,
                                    showId: `${showId}`,
                                });
                            }}
                        >
                            <FontAwesomeIcon
                                icon={icon({ name: 'rotate-right', style: 'solid' })}
                            />
                            <FormattedMessage defaultMessage="Réessayer" />
                        </StyledMenuItem>
                    )}
                </StyledMenu>
            </StyledPopover>
        </MenuTrigger>
    );
};

const MenuButton = styled(Button)`
    transition-duration: 0.2s;
    color: var(--neutral500);
    font-size: 1rem;
    padding: 0;

    &:hover {
        color: var(--neutral600);
    }
`;
const StyledPopover = styled(Popover)`
    background-color: var(--white);
    border-radius: var(--r-xs);
    box-shadow: var(--s-light);
    padding: 0.5rem;
`;
const StyledMenu = styled(Menu)`
    min-width: 10rem;
`;
const StyledMenuItem = styled(MenuItem)`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding-block: 0.5rem;
    padding-inline: 0.75rem;
    border-radius: var(--r-xs);
    cursor: pointer;
    color: var(--black);
    text-decoration: none;
    &:hover {
        background-color: var(--neutral50);
    }
    &[data-disabled='true']:hover {
        background-color: var(--white);
    }
    &[data-disabled='true'] {
        color: var(--neutral200);
        cursor: not-allowed;
    }
`;

export default ContextualMenu;
