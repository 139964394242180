import { useEffect } from 'react';
import { motion, Transition, usePresence } from 'framer-motion';

interface MountTransitionProps {
    children: React.ReactNode;
    slide?: number;
    slideUp?: number;
    scaleUp?: number;
    transition?: Transition;
    className?: string;
}

const MountTransition = ({
    children,
    slide = 0,
    slideUp = 0,
    scaleUp,
    transition,
    className,
}: MountTransitionProps) => {
    const [isPresent, safeToRemove] = usePresence();

    useEffect(() => {
        !isPresent && setTimeout(safeToRemove, 100);
    }, [isPresent]);

    if (!isPresent) return null;
    return (
        <motion.div
            initial={{ opacity: 0, x: slide, y: slideUp, scale: scaleUp }}
            animate={{ opacity: 1, x: 0, y: 0, scale: 1 }}
            exit={{ opacity: 0, x: slide, y: slideUp, scale: scaleUp }}
            transition={transition}
            className={className}
        >
            {children}
        </motion.div>
    );
};

export default MountTransition;
