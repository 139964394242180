import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateRssSettings } from '@/api';
import rssSettingsKeys from '@/queries/rssSettings/rssSettingsKeys';
import camelcaseKeys from 'camelcase-keys';
import { decamelizeKeys } from 'humps';

const transform = (data) => {
    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const mutationFn = async ({ showId, payload }) => {
    const { data } = await updateRssSettings(showId, decamelizeKeys(payload));

    return transform(data);
};

const useUpdateRssSettingsMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: (data, { showId }) => {
            queryClient.invalidateQueries({
                queryKey: rssSettingsKeys.allDetails(showId),
            });
        },
    });
};

export default useUpdateRssSettingsMutation;
