import { useParams } from 'react-router-dom';
import ShortListFallback from '@/components/unorganized/ShortListFallback';
import BroadcastCard from '../../BroadcastCard';
import amazon from '@public/images/platforms/AmazonMusic.png';
import { LISTENING_PLATFORMS_NAMES, AMAZON_MUSIC } from '@/shared/config/constants';
import useShowQuery from '@queries/show/useShowQuery.hook';

const AmazonBroadcast = () => {
    const { showId } = useParams<{ showId: string }>();
    const { data: show, isLoading } = useShowQuery(showId);
    const url = show?.listeningLinks?.data.find((link) => link.key === AMAZON_MUSIC)?.url;

    if (isLoading) return <ShortListFallback />;

    return (
        <BroadcastCard
            platformKey={AMAZON_MUSIC}
            name={LISTENING_PLATFORMS_NAMES[AMAZON_MUSIC]}
            logo={amazon}
            url={url}
        />
    );
};

export default AmazonBroadcast;
