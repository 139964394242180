import styled, { css } from 'styled-components';
import { EditorContent, mergeAttributes, useEditor, Mark } from '@tiptap/react';
import Text from '@ui/atoms/Text';
import Label from '@/components/ui/atoms/Label';
import Link from '@tiptap/extension-link';
import StarterKit from '@tiptap/starter-kit';
import Button from '@/components/Button';
import { FormattedMessage } from 'react-intl';
import { useEffect } from 'react';

interface TemplateEditorProps {
    value: string;
    label?: string | React.ReactNode;
    description?: string | React.ReactNode;
    tooltip?: string;
    isDisabled?: boolean;
    onChange?: (value: string) => void;
    errorMessage?: string;
    onBlur?: (event: React.FocusEvent<HTMLDivElement>) => void;
    isInvalid?: boolean;
}

const TemplateEditor = ({
    value,
    label,
    description,
    tooltip,
    isDisabled,
    onChange,
    errorMessage,
    onBlur,
    isInvalid,
}: TemplateEditorProps) => {
    const extensions = [
        StarterKit.configure({
            blockquote: false,
            bulletList: false,
            codeBlock: false,
            heading: false,
            horizontalRule: false,
            orderedList: false,
            bold: false,
            code: false,
            italic: false,
            strike: false,
        }),
        Link.configure({
            openOnClick: false, // Prevent links to be clickable in text editor
        }),
        Mark.create({
            name: 'highlightPlaceholder',

            addAttributes() {
                return {
                    color: {
                        default: 'var(--primary)',
                    },
                };
            },

            parseHTML() {
                return [
                    {
                        tag: 'span[data-placeholder]',
                    },
                ];
            },

            renderHTML({ HTMLAttributes }) {
                return [
                    'span',
                    mergeAttributes(HTMLAttributes, {
                        'data-placeholder': true,
                        style: `color: ${HTMLAttributes.color};`,
                    }),
                    0,
                ];
            },
        }),
    ];

    const formatContent = value.replace(/{{(.*?)}}/g, `<span data-placeholder>{{$1}}</span> `);

    const editor = useEditor({
        extensions,
        content: formatContent,
        onUpdate: ({ editor }) => {
            onChange?.(editor.getText());
        },
        parseOptions: {
            preserveWhitespace: true, // Preserve whitespace, but normalize newlines to spaces
        },
    });

    useEffect(() => {
        if (!editor) return;
        editor.commands.setContent(formatContent);
        editor.commands.focus('end');
    }, [formatContent]);

    if (!editor) {
        return null;
    }

    if (isDisabled) {
        editor.setEditable(false);
    }

    const handleInsertEpisodeName = () => {
        editor
            .chain()
            .focus()
            .insertContent('<span data-placeholder>{{episode.name}}</span> ')
            .run();
    };

    const handleInsertShowName = () => {
        editor.chain().focus().insertContent('<span data-placeholder>{{show.name}}</span> ').run();
    };

    const handleInsertEpisodeTags = () => {
        editor
            .chain()
            .focus()
            .insertContent('<span data-placeholder>{{episode.tags}}</span> ')
            .run();
    };
    return (
        <Wrapper>
            {label && (
                <Label isRequired={false} description={description} tooltip={tooltip}>
                    {label}
                </Label>
            )}
            <InputWrapper $isInvalid={isInvalid} $isDisabled={isDisabled}>
                <StyledEditorContent editor={editor} onBlur={onBlur} role="textbox" />
            </InputWrapper>
            <Presets>
                <Text color="--neutral500" fontWeight="--fw-semibold">
                    <FormattedMessage defaultMessage="Insérer :" />
                </Text>
                <Button variant="link-primary" onPress={handleInsertEpisodeName}>
                    <FormattedMessage defaultMessage="< Titre de l'épisode >" />
                </Button>
                <Button variant="link-primary" onPress={handleInsertShowName}>
                    <FormattedMessage defaultMessage="< Nom de l'émission >" />
                </Button>
                <Button variant="link-primary" onPress={handleInsertEpisodeTags}>
                    <FormattedMessage defaultMessage="< Liste tags >" />
                </Button>
            </Presets>
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
`;
const InputWrapper = styled.div<{ $isInvalid?: boolean; $isDisabled?: boolean }>`
    display: flex;
    padding: 0.75rem;
    flex-direction: column;
    row-gap: 0.75rem;
    border-radius: var(--r-s);
    border: 1px solid var(--neutral200);
    background-color: var(--white);

    &:focus-within {
        border: 1px solid var(--primary);
        box-shadow: 0px 0px 0px 4px rgba(117, 28, 191, 0.1);
    }

    ${({ $isInvalid }) =>
        $isInvalid &&
        css`
            border-color: var(--alert);

            &:focus-within {
                box-shadow: 0px 0px 0px 4px rgba(255, 38, 74, 0.1);
                border-color: var(--alert);
            }
        `}

    ${({ $isDisabled }) =>
        $isDisabled &&
        css`
            opacity: 0.5;
            pointer-events: none;
        `}
`;
const StyledEditorContent = styled(EditorContent)`
    .ProseMirror {
        min-height: 7.5rem;
        max-height: 19.75rem;
        overflow-y: auto;
        font-size: var(--fs-body);
        line-height: var(--lh-body);
    }

    .ProseMirror:hover {
        border-color: var(--neutral500);
    }

    .ProseMirror:focus,
    .ProseMirror:focus-within,
    .ProseMirror-focused {
        border-color: var(--primary);
    }

    .ProseMirror p.is-editor-empty:first-child::before {
        color: var(--neutral500);
        content: attr(data-placeholder);
        float: left;
        height: 0;
        pointer-events: none;
    }
`;
const ErrorMessage = styled(Text)`
    color: var(--alert);
    font-size: var(--fs-body-s);
    font-weight: var(--fw-semibold);
`;
const Presets = styled.div`
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
`;

export default TemplateEditor;
