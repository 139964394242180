import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { InputTextController } from '@ui/atoms/InputText';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import IconWeb from '@ui/icons/IconWeb';
import IconX from '@ui/icons/IconX';
import IconFacebook from '@ui/icons/IconFacebook';
import IconInstagram from '@ui/icons/IconInstagram';
import IconLinkedin from '@ui/icons/IconLinkedin';
import IconCrowdFunding from '@ui/icons/IconCrowdFunding';
import IconTiktok from '@ui/icons/IconTiktok';
import IconTwitch from '@ui/icons/IconTwitch';
import IconDiscord from '@ui/icons/IconDiscord';
import Button from '@ui/atoms/Button';
import styled from 'styled-components';
import useExternalLinksFormSchema from './useExternalLinksFormSchema.hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { extractCssVariable } from '@/shared/utils/cssVariable';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

const ExternalLinksForm = ({ externalLinks, onSubmit, isMutating }) => {
    const intl = useIntl();
    const schema = useExternalLinksFormSchema();
    const {
        control,
        handleSubmit,
        formState: { isDirty },
    } = useForm({
        mode: 'onChange',
        defaultValues: { ...externalLinks },
        resolver: yupResolver(schema),
    });

    const socialLinksLookup = {
        site: {
            title: intl.formatMessage({ defaultMessage: 'Saisir le lien de votre site web' }),
            label: <FormattedMessage defaultMessage="Site web" />,
            icon: <IconWeb />,
        },
        facebook: {
            title: intl.formatMessage({ defaultMessage: 'Saisir le lien de votre page Facebook' }),
            label: <FormattedMessage defaultMessage="Page Facebook" />,
            icon: <IconFacebook color="--facebook" />,
        },
        twitter: {
            title: intl.formatMessage({
                defaultMessage: 'Saisir le lien de votre compte X (Twitter)',
            }),
            label: <FormattedMessage defaultMessage="Compte X (Twitter)" />,
            icon: <IconX color="--twitter" />,
        },
        youtube: {
            title: intl.formatMessage({ defaultMessage: 'Saisir le lien de votre chaîne YouTube' }),
            label: <FormattedMessage defaultMessage="Chaîne YouTube" />,
            icon: (
                <FontAwesomeIcon
                    icon={icon({ name: 'youtube', style: 'brands' })}
                    color={extractCssVariable('--youtube')}
                    size="xl"
                />
            ),
        },
        instagram: {
            title: intl.formatMessage({
                defaultMessage: 'Saisir le lien de votre compte Instagram',
            }),
            label: <FormattedMessage defaultMessage="Compte Instagram" />,
            icon: <IconInstagram color="--instagram" />,
        },
        linkedin: {
            title: intl.formatMessage({
                defaultMessage: 'Saisir le lien de votre compte LinkedIn',
            }),
            label: <FormattedMessage defaultMessage="Compte LinkedIn" />,
            icon: <IconLinkedin color="--linkedin" />,
        },
        tiktok: {
            title: intl.formatMessage({ defaultMessage: 'Saisir le lien de votre compte TikTok' }),
            label: <FormattedMessage defaultMessage="Compte TikTok" />,
            icon: <IconTiktok color="--tiktok" />,
        },
        twitch: {
            title: intl.formatMessage({ defaultMessage: 'Saisir le lien de votre chaîne Twitch' }),
            label: <FormattedMessage defaultMessage="Chaîne Twitch" />,
            icon: <IconTwitch color="--twitch" />,
        },
        discord: {
            title: intl.formatMessage({
                defaultMessage: 'Saisir le lien de votre serveur Discord',
            }),
            label: <FormattedMessage defaultMessage="Serveur Discord" />,
            icon: <IconDiscord color="--discord" />,
        },
        monetization: {
            title: intl.formatMessage({
                defaultMessage: 'Saisir le lien de votre plateforme de crowdfunding',
            }),
            label: (
                <FormattedMessage defaultMessage="Plateforme de crowdfunding (Patreon, Kickstarter, Ulule ou autre)" />
            ),
            icon: <IconCrowdFunding />,
        },
    };

    return (
        <FormElement onSubmit={handleSubmit(onSubmit)}>
            <FormInnerContent>
                <LinksWrapper>
                    {Object.keys(socialLinksLookup).map((link) => (
                        <InputWrapper key={link}>
                            <InputTextController
                                control={control}
                                name={link}
                                placeholder={socialLinksLookup[link]?.title}
                                label={socialLinksLookup[link]?.label}
                                inputIcon={socialLinksLookup[link]?.icon}
                                inputIconBackgroundColor="transparent"
                            />
                        </InputWrapper>
                    ))}
                </LinksWrapper>
                <Button type="submit" isDisabled={!isDirty} isLoading={isMutating}>
                    <FormattedMessage defaultMessage="Enregistrer" />
                </Button>
            </FormInnerContent>
        </FormElement>
    );
};

ExternalLinksForm.propTypes = {
    externalLinks: PropTypes.objectOf(PropTypes.string).isRequired,
    onSubmit: PropTypes.func.isRequired,
    isMutating: PropTypes.bool,
};

const FormElement = styled.form`
    width: 100%;
`;

const FormInnerContent = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    align-items: center;
`;

const LinksWrapper = styled.ul`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
`;

const InputWrapper = styled.li`
    display: flex;
    align-items: flex-start;
    column-gap: 0.5rem;
`;

export default ExternalLinksForm;
