import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import PsoTops from './PsoTop';
import PsoGuide from './PsoGuide';
import PsoCountdown from './PsoCountdown';
import PsoVisibilityScoreHistory from './PsoVisibilityScoreHistory';
import PsoVisibilityScore from './PsoVisibilityScore';
import PsoAverageRanking from './PsoAverageRanking';
import PsoBestPosition from './PsoBestPosition';
import PsoKeywordTrend from './PsoKeywordTrend';
import useAnalyticsQuery from './useAnalyticsQuery.hook';
import useRouterStore from '@/shared/hooks/useRouterStore';
import Link from '@ui/atoms/Link';
import { INVALID_LISTENING_LINK } from '@/shared/config/errorCodes';
import dayjs from 'dayjs';
import { UI_VARIANTS } from '@/shared/config/constants';
import { modalToastQueue } from '@/components/ui/molecules/GlobalToastRegions';
import { useAmplitudeLogEvent } from '@/shared/hooks/useAmplitude';
import styled, { css } from 'styled-components';
import Header from './Header';

const PsoOverview = ({
    globalConfiguration,
    currentStore,
    hasSpotifyData,
    hasAppleData,
    setCurrentStore,
    userHasWriteAccess,
    stores,
}) => {
    useAmplitudeLogEvent('PSO Dashboard Viewed');
    const { showId } = useParams();
    const { link } = useRouterStore();
    const analytics = useAnalyticsQuery({ showId, currentStore });
    const managerLocalConfiguration = JSON.parse(
        localStorage.getItem('managerLocalConfiguration') || '{}',
    );
    const [showGuide, setShowGuide] = useState(!('hidePsoGuideBlock' in managerLocalConfiguration));
    const daysRemaining = globalConfiguration?.countdownEndsAt
        ? dayjs(globalConfiguration.countdownEndsAt).diff(dayjs(), 'day')
        : 0;

    if (analytics.isError && analytics?.error?.response?.data?.code === INVALID_LISTENING_LINK) {
        modalToastQueue.add(
            <FormattedMessage
                defaultMessage="Oups, vos liens d'écoute Apple Podcasts ou Spotify ne semblent pas valides. Rendez-vous dans les <link>Paramètres de diffusion</link> pour les renseigner à nouveau."
                values={{
                    link: (chunks) => (
                        <CustomLink
                            as={Link}
                            to={link('menu.settings.tab', { showId, tab: 'broadcast' })}
                        >
                            {chunks}
                        </CustomLink>
                    ),
                }}
            />,
            {
                variant: UI_VARIANTS.ALERT,
                timeout: 5000,
            },
        );
    }

    const top3data = analytics.data?.keywordsInTop.find((item) => item.top === 3);
    const top20data = analytics.data?.keywordsInTop.find((item) => item.top === 20);
    const top100data = analytics.data?.keywordsInTop.find((item) => item.top === 100);

    const upTrend = analytics.data?.bestTrends?.find((item) => item.trend === 'up');
    const downTrend = analytics.data?.bestTrends?.find((item) => item.trend === 'down');

    return (
        <OverviewWrapper>
            <Header
                currentStore={currentStore}
                userHasWriteAccess={userHasWriteAccess}
                stores={stores}
                setCurrentStore={setCurrentStore}
            />
            <OverviewInnerWrapper>
                {showGuide && (
                    <MediumCard>
                        <PsoGuide
                            onClosedGuide={() => {
                                localStorage.setItem(
                                    'managerLocalConfiguration',
                                    JSON.stringify({
                                        ...managerLocalConfiguration,
                                        hidePsoGuideBlock: true,
                                    }),
                                );
                                setShowGuide(false);
                            }}
                        />
                    </MediumCard>
                )}
                <CardWrapper column={showGuide}>
                    <Card>
                        <PsoBestPosition
                            keyword={analytics.data?.bestPosition?.keyword || null}
                            platforms={analytics.data?.bestPosition?.platforms || []}
                            rank={analytics.data?.bestPosition?.rank || 0}
                            isLoading={analytics.isLoading || analytics.isError}
                        />
                    </Card>
                    <Card>
                        <PsoKeywordTrend
                            keyword={upTrend?.keyword || null}
                            platform={upTrend?.platform || null}
                            trend="up"
                            points={upTrend?.rankDiff || 0}
                            chartData={upTrend?.history?.map((item) => item.score) || []}
                            isLoading={analytics.isLoading || analytics.isError}
                        />
                    </Card>
                    <Card>
                        <PsoKeywordTrend
                            keyword={downTrend?.keyword || null}
                            platform={downTrend?.platform || null}
                            trend="down"
                            points={downTrend?.rankDiff || 0}
                            chartData={downTrend?.history?.map((item) => item.score) || []}
                            isLoading={analytics.isLoading || analytics.isError}
                        />
                    </Card>
                </CardWrapper>
                {!globalConfiguration.countdownDone ? (
                    <>
                        <Card>
                            <PsoVisibilityScore
                                score={analytics.data?.visibility?.score}
                                trend={analytics.data?.visibility?.trend}
                                rankDiff={analytics.data?.visibility?.rankDiff}
                                isLoading={analytics.isLoading || analytics.isError}
                            />
                        </Card>
                        <Card>
                            <PsoVisibilityScoreHistory
                                isLoading={analytics.isLoading || analytics.isError}
                                data={analytics.data?.visibilityHistory}
                            />
                        </Card>
                        <Card>
                            <PsoCountdown targetDate={globalConfiguration.countdownEndsAt} />
                        </Card>
                    </>
                ) : (
                    <>
                        <Card>
                            <PsoVisibilityScore
                                score={analytics.data?.visibility?.score}
                                trend={analytics.data?.visibility?.trend}
                                rankDiff={analytics.data?.visibility?.rankDiff}
                                isLoading={analytics.isLoading || analytics.isError}
                            />
                        </Card>
                        <MediumCard>
                            <PsoVisibilityScoreHistory
                                isLoading={analytics.isLoading || analytics.isError}
                                data={analytics.data?.visibilityHistory}
                            />
                        </MediumCard>
                    </>
                )}
                <Card>
                    <PsoTops
                        top={3}
                        nbKeywordsInTop={top3data?.keywordsCount}
                        totalKeywords={globalConfiguration?.keywords?.length}
                        trend={top3data?.trend}
                        rankDiff={top3data?.rankDiff}
                        history={top3data?.history}
                        isLoading={analytics.isLoading || analytics.isError}
                    />
                </Card>
                <Card>
                    <PsoTops
                        top={20}
                        nbKeywordsInTop={top20data?.keywordsCount}
                        totalKeywords={globalConfiguration?.keywords?.length}
                        trend={top20data?.trend}
                        rankDiff={top20data?.rankDiff}
                        history={top20data?.history}
                        isLoading={analytics.isLoading || analytics.isError}
                    />
                </Card>
                <Card>
                    <PsoTops
                        top={100}
                        nbKeywordsInTop={top100data?.keywordsCount}
                        totalKeywords={globalConfiguration?.keywords?.length}
                        trend={top100data?.trend}
                        rankDiff={top100data?.rankDiff}
                        history={top100data?.history}
                        isLoading={analytics.isLoading || analytics.isError}
                    />
                </Card>
                <BigCard>
                    <PsoAverageRanking
                        daysRemaining={daysRemaining}
                        currentStore={currentStore}
                        hasSpotifyData={hasSpotifyData}
                        hasAppleData={hasAppleData}
                    />
                </BigCard>
            </OverviewInnerWrapper>
        </OverviewWrapper>
    );
};

const OverviewWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
`;
const OverviewInnerWrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-template-rows: minmax(4.5rem, auto) 20rem 9rem 24.5rem;
    gap: 1rem;
    min-height: 0;
    min-width: 0;
`;
const CardWrapper = styled.div`
    grid-column: span 3;
    display: flex;
    gap: 1rem;
    height: 4.5rem;

    & > * {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(33% - 0.4rem);
    }

    ${({ column }) =>
        column &&
        css`
            height: calc((3 * 4.5rem) + (2 * 1rem));
            flex-direction: column;
            grid-column: span 1;

            & > * {
                flex-basis: 4.5rem;
                flex-grow: 0;
                flex-shrink: 0;
            }
        `}
`;
const Card = styled.div`
    display: flex;
    background-color: var(--white);
    border-radius: var(--r-m);
    min-height: 4.5rem;
    min-width: 0;

    & > * {
        width: 100%;
        align-self: stretch;
        border-radius: var(--r-m);
    }
`;
const MediumCard = styled(Card)`
    grid-column: span 2;
`;
const BigCard = styled(Card)`
    grid-column: span 3;
`;
const CustomLink = styled.span`
    color: var(--black);
    text-decoration: underline;
    font-weight: var(--fw-bold);
`;

PsoOverview.propTypes = {
    globalConfiguration: PropTypes.object.isRequired,
    currentStore: PropTypes.string,
    hasSpotifyData: PropTypes.bool,
    hasAppleData: PropTypes.bool,
    setCurrentStore: PropTypes.func,
    userHasWriteAccess: PropTypes.bool,
    stores: PropTypes.array,
};

export default PsoOverview;
