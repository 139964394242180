import RssExportBlock from './RssExportBlock';
import RssExportConfiguration from './RssExportConfiguration';
import { useAmplitudeLogEvent } from '@/shared/hooks/useAmplitude';

function RssExport() {
    useAmplitudeLogEvent('Export Settings Viewed');

    return (
        <>
            <RssExportBlock />
            <RssExportConfiguration />
        </>
    );
}

export default RssExport;
