import { useMutation } from '@tanstack/react-query';
import { addEpisodeMessages } from '@/api';
import { decamelizeKeys } from 'humps';
import camelcaseKeys from 'camelcase-keys';
import { EpisodeMesssages } from './useEpisodeMessagesQuery';

export interface MutationFnProps {
    episodeId: string;
    messages: EpisodeMesssages;
}

const mutationFn = async ({ episodeId, messages }: MutationFnProps) => {
    const { data } = await addEpisodeMessages(episodeId, decamelizeKeys(messages));

    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const addEpisodeMessagesMutation = () => {
    return useMutation({
        mutationFn,
        onSuccess: () => {
            // Invalidation of episode query is handled in useSaveNonHostingEpisodeFormMutation
        },
    });
};

export default addEpisodeMessagesMutation;
