import { Table } from '@tanstack/react-table';
import { DownloadsComparisonData } from '@app/molecules/DownloadsComparisonContent';
import styled from 'styled-components';

interface DownloadsComparisonTableBodyLoadingProps {
    table: Table<DownloadsComparisonData>;
}

const DownloadsComparisonTableBodyLoading = ({
    table,
}: DownloadsComparisonTableBodyLoadingProps) => {
    return (
        <Tbody>
            {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                        return <td key={cell.id} />;
                    })}
                </tr>
            ))}
        </Tbody>
    );
};

const Tbody = styled.tbody`
    background: var(--white);
    height: 14.5rem;

    tr td {
        border: none;
    }
`;

export default DownloadsComparisonTableBodyLoading;
