import { deleteClipFormatImage } from '@/api';
import TrashIcon from '@public/icons/trash.svg';
import DeprecatedButton from '@ui/atoms/DeprecatedButton';

const DeleteImageButton = ({ values, format, onDeleteImage, setIsUploadingImage }) => {
    const handleDeleteImage = async () => {
        setIsUploadingImage(format, true);
        try {
            const clipFormat = values.formats.find((item) => item.format === format);
            await deleteClipFormatImage(clipFormat.id);
            onDeleteImage(format);
        } catch (error) {
            // Error
        } finally {
            setIsUploadingImage(format, false);
        }
    };

    return (
        <DeprecatedButton accent="secondary" icon onClick={handleDeleteImage}>
            <TrashIcon />
        </DeprecatedButton>
    );
};

export default DeleteImageButton;
