import { useMutation } from '@tanstack/react-query';
import { updatePlayerSettings } from '@/api';
import { decamelizeKeys } from 'humps';
import camelcaseKeys from 'camelcase-keys';
import { PlayerFormSchema } from '@/components/unorganized/PlayerForm/usePlayerFormSchema.hook';

const transform = (data: unknown) => {
    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data as any, { deep: true });
};

const mutationFn = async ({
    showId,
    settings,
}: {
    showId: string;
    settings: Omit<PlayerFormSchema, 'callToActionButtons' | 'type'>;
}) => {
    const { data } = await updatePlayerSettings(showId, decamelizeKeys(settings));

    return transform(data);
};

export const useUpdatePlayerSettingsMutation = () => {
    return useMutation({
        mutationFn,
    });
};

export default useUpdatePlayerSettingsMutation;
