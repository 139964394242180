import Text from '@/components/ui/atoms/Text';
import Stack from '@/components/ui/layout/Stack';
import DesktopLogo from '@public/images/stats_desktop.svg';
import MobileLogo from '@public/images/stats_mobile.svg';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const NoData = () => {
    return (
        <Wrapper>
            <Device>
                <MobileIllustration />
                <Stack>
                    <Text variant="title" fontWeight="--fw-semibold">
                        0
                    </Text>
                    <Text color="--neutral500">
                        <FormattedMessage defaultMessage="mobile" />
                    </Text>
                </Stack>
            </Device>
            <Device>
                <DesktopIllustration />
                <Stack>
                    <Text variant="title" fontWeight="--fw-semibold">
                        0
                    </Text>
                    <Text color="--neutral500">
                        <FormattedMessage defaultMessage="bureau" />
                    </Text>
                </Stack>
            </Device>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    column-gap: 4rem;
`;
const DesktopIllustration = styled(DesktopLogo)`
    width: 4rem;
    height: 4rem;
`;
const MobileIllustration = styled(MobileLogo)`
    width: 4rem;
    height: 4rem;
`;
const Device = styled.div`
    display: flex;
    column-gap: 0.5rem;
`;
export default NoData;
