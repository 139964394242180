import { useState } from 'react';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Button from '../Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import GuestsQuotaReachedModal from '../GuestsQuotaReachedModal';
import ChannelGuestsInvitationModal from './ChannelGuestsInvitationModal';
import useChannelQuery from '@/queries/channel/useChannelQuery.hook';

const ChannelGuestsAddButton = (props: React.ComponentProps<typeof Button>) => {
    const { data: channel } = useChannelQuery();
    const [isInvitationModalOpen, setInvitationModalOpen] = useState(false);
    const [isQuotaReachedModalOpen, setQuotaReachedModalOpen] = useState(false);

    const handleOpenInvitationModal = () => {
        if (channel?.availableInvitationsNumber === 0) {
            setQuotaReachedModalOpen(true);
            return;
        }
        setInvitationModalOpen(true);
    };

    const handlePermissionError = () => {
        setInvitationModalOpen(false);
        setQuotaReachedModalOpen(true);
    };

    return (
        <>
            <Button
                onPress={handleOpenInvitationModal}
                startIcon={<AddIcon icon={icon({ name: 'plus', style: 'solid' })} />}
                {...props}
            />
            <GuestsQuotaReachedModal
                isOpen={isQuotaReachedModalOpen}
                onOpenChange={setQuotaReachedModalOpen}
            />
            <ChannelGuestsInvitationModal
                isOpen={isInvitationModalOpen}
                onOpenChange={setInvitationModalOpen}
                onPermissionError={handlePermissionError}
            />
        </>
    );
};

const AddIcon = styled(FontAwesomeIcon)`
    color: var(--white);
    width: 1rem;
    height: 1rem;
`;

export default ChannelGuestsAddButton;
