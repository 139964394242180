import { useParams } from 'react-router-dom';
import { PRICING } from '@/utils/pricing';
import { GUARD_DISALLOW_REASONS, USER_ROLES } from '@/utils/constants';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import useSubscriptionQuery from '@/queries/subscription/useSubscriptionQuery.hook';
import dayjs from 'dayjs';

export const useGuard = ({
    disallowedShowStatuses = [],
    disallowedUserRoles = [],
    disallowedPlans = [],
    requiredUserOptions = [],
    blockedSubscriptionAuthorized = false,
    disallowAccess = false,
} = {}) => {
    const { showId } = useParams();
    const { data: show } = useShowQuery(showId);
    const { data: user } = useUserQuery();
    const { data: subscription } = useSubscriptionQuery();
    const pricing =
        show?.userRole === USER_ROLES.OWNER
            ? subscription?.isFreemium
                ? PRICING.FREEMIUM
                : user?.pricing
            : show?.ownerPricing;
    const isBlockedForShow =
        !!subscription?.endsAt && dayjs(subscription?.endsAt).isBefore(dayjs(), 'day');

    /**
     * 🌊🪵🦫 Disallow access if:
     * - the show can't be found
     * - the user's role is not in the allowed roles for that show
     */
    if (show && disallowedUserRoles.includes(show?.userRole)) {
        return { allow: false, reason: GUARD_DISALLOW_REASONS.INSUFFICIENT_ROLE };
    }

    /**
     * 🌊🪵🦫 Disallow access if:
     * - the show can't be found
     * - the show's status (active | archived | guest) is not in the user's allowed show statuses
     */
    if (show && disallowedShowStatuses.includes(show?.showStatus)) {
        return { allow: false, reason: GUARD_DISALLOW_REASONS.INSUFFICIENT_SHOW_STATUS };
    }

    /**
     * 🌊🪵🦫 Disallow access if:
     * - the user can't be found
     * - the user's pricing is enterprise
     * - the user's account is not activated
     */
    if (user && user.pricing === PRICING.ENTERPRISE && user.activated === false) {
        return { allow: false, reason: GUARD_DISALLOW_REASONS.UNAUTHORIZED_SUBSCRIPTION };
    }

    /**
     * 🌊🪵🦫 Disallow access if:
     * - the subscription bypass has not been enabled (blockedSubscriptionAuthorized)
     * - the subscription can't be found
     * - the subscription is blocked from accessing the current show
     */
    if (!blockedSubscriptionAuthorized && subscription && isBlockedForShow) {
        return { allow: false, reason: GUARD_DISALLOW_REASONS.UNAUTHORIZED_SUBSCRIPTION };
    }

    /**
     * 🌊🪵🦫 Disallow access if:
     * - the subscription can't be found
     * - the user's plan is below the required plan
     */
    if (subscription && disallowedPlans.includes(pricing)) {
        return { allow: false, reason: GUARD_DISALLOW_REASONS.UNSUFFICIENT_PLAN };
    }

    /**
     * 🌊🪵🦫 Disallow access if:
     * - the user can't be found
     * - the required options are not met by the current user's options
     * - the required options are not met by the current show users options
     */
    if (
        user &&
        show &&
        requiredUserOptions.length > 0 &&
        (requiredUserOptions.some((option) => !user.options.includes(option)) ||
            requiredUserOptions.some((option) => !show.userOptions.includes(option)))
    ) {
        return { allow: false, reason: GUARD_DISALLOW_REASONS.UNMET_USER_OPTIONS };
    }

    /**
     * 🌊🪵🦫 Disallow access if:
     * - the disallowAccess prop is set to true
     */
    if (disallowAccess) {
        return { allow: false, reason: GUARD_DISALLOW_REASONS.GENERIC };
    }

    /**
     * ✅ Allow access
     */
    return { allow: true, reason: null };
};
