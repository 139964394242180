import { useMemo } from 'react';
import Desktop from '@public/images/stats_desktop.svg';
import Mobile from '@public/images/stats_mobile.svg';
import Tablet from '@public/images/stats_tablette.svg';
import Watch from '@public/images/stats_watch.svg';
import TV from '@public/images/stats_tv.svg';
import Speaker from '@public/images/stats_enceinte.svg';
import { useResponsive } from '@/shared/hooks/useResponsive';
import { FormattedMessage } from 'react-intl';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import DeprecatedIcon from '@ui/atoms/DeprecatedIcon';
import PaperGrid from '@ui/atoms/PaperGrid';
import Column from '@ui/atoms/Column';
import { round } from '@/shared/utils/number';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import { useStatsDateSelectorContext } from '@/context/StatsDateSelectorContext';
import styled from 'styled-components';
import NoData from './NoData';
import { useParams } from 'react-router';
import useQuery from '@/shared/hooks/useQuery';
import useSourceDevicesQuery from '@/queries/stats/useSourceDevicesQuery.hook';
import useCampaignSourceDevicesQuery from '@/queries/stats/useCampaignSourceDevicesQuery.hook';
import { getLocalTimeZone } from '@internationalized/date';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import Filters from '../../Filters';

const logos = {
    tablet: Tablet,
    desktop: Desktop,
    tv: TV,
    speaker: Speaker,
    smartphone: Mobile,
    'portable media player': Mobile,
    car_browser: Mobile,
    console: Mobile,
    camera: Mobile,
    watch: Watch,
};

const messages = {
    tablet: <FormattedMessage defaultMessage="tablette" />,
    desktop: <FormattedMessage defaultMessage="bureau" />,
    tv: <FormattedMessage defaultMessage="télévision" />,
    speaker: <FormattedMessage defaultMessage="enceinte" />,
    smartphone: <FormattedMessage defaultMessage="mobile" />,
    'portable media player': <FormattedMessage defaultMessage="baladeur" />,
    car_browser: <FormattedMessage defaultMessage="voiture" />,
    console: <FormattedMessage defaultMessage="console" />,
    camera: <FormattedMessage defaultMessage="camera" />,
    watch: <FormattedMessage defaultMessage="montre" />,
};

const responsiveData = (responsive) => {
    if (responsive.isMobile)
        return {
            deviceSize: 50,
            rowSize: '45vw',
            column: 5,
            text: 20,
            scrollSnapType: 'x mandatory',
            scrollSnapAlign: 'center',
        };

    if (responsive.isTablet)
        return {
            deviceSize: 50,
            rowSize: 'initial',
            column: 3,
            text: 'veryBig',
        };
    return {
        deviceSize: 65,
        rowSize: 'initial',
        column: 5,
        text: 'veryBig',
    };
};
function SourcesDevices() {
    const { showId } = useParams();
    const query = useQuery();
    const episodes = query.get('episode');
    const campaignId = query.get('campaignId');
    const { startDate, endDate } = useStatsDateSelectorContext();
    const { isMobileOrTablet, isTabletOrDesktop, isMobile, isTablet } = useResponsive();
    const { data: user } = useUserQuery();
    const timezone = useMemo(() => user?.timezone || getLocalTimeZone(), [user]);

    const { data: showDevices } = useSourceDevicesQuery(
        {
            showId,
            start: startDate,
            end: endDate,
            episodes: episodes && episodes !== 'all' ? episodes.split('_') : [],
            timezone,
        },
        { enabled: !campaignId },
    );
    const { data: campaignDevices } = useCampaignSourceDevicesQuery(
        {
            campaignId,
            start: startDate,
            end: endDate,
            timezone,
        },
        { enabled: !!campaignId },
    );

    const devices = campaignId ? campaignDevices : showDevices;

    const resp = responsiveData({ isMobileOrTablet, isTabletOrDesktop, isMobile, isTablet });

    useMemo(
        () => responsiveData({ isMobileOrTablet, isTabletOrDesktop, isMobile, isTablet }),
        [isMobileOrTablet, isTabletOrDesktop, isMobile, devices],
    );

    return (
        <>
            <OuterHeader>
                <TitleAndDescription>
                    <Title>
                        <FormattedMessage defaultMessage="Sources" />
                    </Title>
                    <Description>
                        <FormattedMessage defaultMessage="Voici un aperçu des appareils sur lesquels on vous écoute." />
                    </Description>
                </TitleAndDescription>
                <Filters />
            </OuterHeader>
            <SourcesDevicesWrapper>
                {!devices || devices.length === 0 ? (
                    <NoData />
                ) : (
                    <PaperGrid
                        column={devices.length}
                        rowGap={isMobileOrTablet && 20}
                        px={isMobile ? 0 : 35}
                        py={isMobileOrTablet && 20}
                        w="100%"
                        gap={isMobile ? 0 : 10}
                        overflowX="auto"
                        scrollSnapType={resp.scrollSnapType}
                    >
                        {devices.map((device) => (
                            <Column
                                key={device.device}
                                justify={isMobileOrTablet && 'center'}
                                w={resp.rowSize}
                                scrollSnapAlign={resp.scrollSnapAlign}
                                justifySelf="center"
                            >
                                <DeprecatedIcon size={resp.deviceSize} as={logos[device.device]} />
                                <DeprecatedPaper
                                    flex
                                    justify="center"
                                    ml={10}
                                    column
                                    w="max-content"
                                >
                                    <DeprecatedText mb={-5} weight="semibold" fontSize={resp.text}>
                                        {device.percentage < 1
                                            ? '< 1%'
                                            : `${round(device.percentage)}%`}
                                    </DeprecatedText>
                                    {isTabletOrDesktop && messages[device.device]}
                                </DeprecatedPaper>
                            </Column>
                        ))}
                    </PaperGrid>
                )}
            </SourcesDevicesWrapper>
        </>
    );
}

const SourcesDevicesWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding);
    min-height: 6rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;

    ${({ theme }) => theme.mediaQueries.mobile} {
        padding: var(--container-padding-mobile);
    }
`;
const OuterHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;
const TitleAndDescription = styled.div``;
const Title = styled.h2`
    font-size: var(--fs-heading-m);
`;
const Description = styled.p``;

export default SourcesDevices;
