import Text from '@/components/ui/atoms/Text';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListBoxItem } from 'react-aria-components';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';

interface NewShowActionProps {
    layout: 'stack' | 'grid';
}

const NewShowAction = ({ layout }: NewShowActionProps) => {
    return (
        <Item $layout={layout} id="new-show" data-testid="new-show-menu-action">
            <IconWrapper $layout={layout}>
                <Icon $layout={layout} icon={icon({ name: 'plus', style: 'solid' })} />
            </IconWrapper>
            {layout === 'stack' && (
                <Text fontWeight="--fw-semibold" color="--primary">
                    <FormattedMessage defaultMessage="Nouvelle émission" />
                </Text>
            )}
        </Item>
    );
};

const IconWrapper = styled.div<{ $layout: 'stack' | 'grid' }>`
    width: 2rem;
    height: 2rem;
    border-radius: var(--r-s);
    flex-shrink: 0;
    background-color: var(--primary50);
    display: flex;
    justify-content: center;
    align-items: center;

    ${({ $layout }) =>
        $layout === 'grid' &&
        css`
            width: 100%;
            height: 100%;
        `}
`;
const Icon = styled(FontAwesomeIcon)<{ $layout: 'stack' | 'grid' }>`
    width: 0.75rem;
    height: 0.75rem;
    color: var(--primary500);

    ${({ $layout }) => $layout === 'grid' && `width: 1.5rem; height: 1.5rem;`}
`;
const Item = styled(ListBoxItem)<{ $layout: 'stack' | 'grid' }>`
    border-radius: var(--r-s);
    cursor: pointer;

    ${({ $layout }) =>
        $layout === 'stack' &&
        css`
            display: flex;
            align-items: center;
            column-gap: 0.5rem;
            padding-block: 0.25rem;
            padding-inline: 0.5rem;
            transition: background-color ease-out 250ms;

            &[data-hovered] {
                background-color: var(--neutral50);
            }
        `}

    ${({ $layout }) =>
        $layout === 'grid' &&
        css`
            transition: background-color ease-out 250ms;

            &[data-hovered] > ${IconWrapper} {
                background-color: var(--primary100);
            }
        `}

        &[data-focused] {
        outline: solid 2px var(--primary50);
    }
`;

export default NewShowAction;
