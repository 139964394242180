import { createContext } from 'react';
import type { Currency, Offer, Price } from '@/queries/price/usePricesQuery.hook';
import type { SubscriptionAction } from '@/shared/config/constants';

export interface offerUpdatePayload {
    offer: Offer;
    isBilledYearly: boolean;
    action: SubscriptionAction;
}

export interface errorPayload {
    offer: Offer;
    isBilledYearly: boolean;
    error: any;
}

interface PricingCtx {
    getPricesOfCurrencyAndProduct: (currency: Currency, product: Offer) => Price | null;
    pricesOfCurrentSubscriptionPricing: () => Price | null;
    isCurrentSubscriptionActive: boolean;
    isCurrentSubscriptionBilledYearly: boolean;
    currentSubscriptionCurrency: Currency | null;
    currentSubscriptionPricing: Offer | null;
    isYearlyPeriodicitySelected: boolean;
    setYearlyPeriodicitySelected: (value: boolean) => void;
    selectedCurrency: Currency | null;
    setSelectedCurrency: (value: Currency) => void;
    offerUpdatePayload: offerUpdatePayload | null;
    setOfferUpdatePayload: (value: offerUpdatePayload) => void;
    errorPayload: errorPayload | null;
    setErrorPayload: (value: errorPayload) => void;
}

/**
 * Context used to store pricing page state
 */
const PricingContext = createContext<PricingCtx>({
    getPricesOfCurrencyAndProduct: () => null,
    pricesOfCurrentSubscriptionPricing: () => null,
    isCurrentSubscriptionActive: false,
    isCurrentSubscriptionBilledYearly: false,
    currentSubscriptionCurrency: null,
    currentSubscriptionPricing: null,
    isYearlyPeriodicitySelected: false,
    setYearlyPeriodicitySelected: () => {},
    selectedCurrency: null,
    setSelectedCurrency: () => {},
    offerUpdatePayload: null,
    setOfferUpdatePayload: () => {},
    errorPayload: null,
    setErrorPayload: () => {},
});

export default PricingContext;
