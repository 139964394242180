import { Button } from 'react-aria-components';

export interface TooltipTriggerWrapperProps {
    children: React.ReactNode;
    className?: string;
}

export const TooltipTriggerWrapper = ({ children, className }: TooltipTriggerWrapperProps) => {
    return (
        <Button style={{ background: 'none', border: 'none', padding: 0 }} className={className}>
            {children}
        </Button>
    );
};
