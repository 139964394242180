import Modal from '@/components/ui/atoms/Modal';
import styled from 'styled-components';
import { SpeakerProvider } from '@/components/unorganized/TranscriptionEditor/TranscriptionSpeakersProvider';
import Transcription from '@/components/unorganized/Transcription';
import { useNonHostingEpisodeTranscription } from '@/components/NonHostingEpisodeForm/contexts/NonHostingTranscriptionContext';

interface TranscriptionModalProps {
    episodeId: number;
    audioUrl: string;
    waveformDataUrl: string;
    isOpen: boolean;
    onOpenChange: (isOpen: boolean) => void;
    onClose: () => void;
}

const TranscriptionModal = ({
    episodeId,
    audioUrl,
    waveformDataUrl,
    isOpen,
    onOpenChange,
}: TranscriptionModalProps) => {
    const transcription = useNonHostingEpisodeTranscription();

    return (
        <FullPageModal isOpen={isOpen} onOpenChange={onOpenChange}>
            <SpeakerProvider initialSpeakers={transcription.data.speakers}>
                <Transcription
                    transcription={transcription.data}
                    episodeId={episodeId}
                    audioUrl={audioUrl}
                    waveformDataUrl={waveformDataUrl}
                    onClose={() => onOpenChange(false)}
                />
            </SpeakerProvider>
        </FullPageModal>
    );
};

const FullPageModal = styled(Modal)`
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    background: white;
    max-width: 100%;
    border-radius: 0;
`;

export default TranscriptionModal;
