import { z } from 'zod';
import { useIntl } from 'react-intl';
import { FORM_VALIDATION_MESSAGE } from '@/shared/services/validationMessageService';
import { CLIP_MAX_LENGTH } from '@/shared/config/constants';

const useClipAudioSampleFormSchema = (episodeDuration: number) => {
    const intl = useIntl();

    return z
        .object({
            start: z.number().min(0),
            end: z
                .number()
                .max(
                    episodeDuration,
                    intl.formatMessage(FORM_VALIDATION_MESSAGE.maxLength, { max: episodeDuration }),
                ),
            duration: z
                .number()
                .min(0)
                .max(
                    CLIP_MAX_LENGTH + 0.001,
                    intl.formatMessage({
                        defaultMessage:
                            "La durée ne peut pas être supérieure à la durée de l'épisode",
                    }),
                ),
        })
        .superRefine((schema, ctx) => {
            const { start, end } = schema;

            if (start >= end) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: intl.formatMessage({
                        defaultMessage: 'La fin doit être supérieure au début',
                    }),
                    path: ['end'],
                });
            }

            return true;
        });
};

export type ClipAudioSampleFormSchema = z.infer<ReturnType<typeof useClipAudioSampleFormSchema>>;

export default useClipAudioSampleFormSchema;
