import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Stack from '@ui/layout/Stack';
import Cluster from '@ui/layout/Cluster';
import Text from '@ui/atoms/Text';
import IconEdit from '@ui/icons/IconEdit';
import { useParams } from 'react-router';
import Menu from './Menu';
import { ClipFormat } from '@/queries/clipFormat/types';
import { Episode } from '@/queries/episode/useEpisodeQuery.hook';
import { Link } from 'react-router-dom';

interface ClipFormatPendingProps {
    clipFormat: ClipFormat;
    episode: Episode;
}

const ClipFormatPending = ({ clipFormat, episode }: ClipFormatPendingProps) => {
    const { showId } = useParams<{ showId: string }>();

    return (
        <Stack $gap="0.75rem">
            <CardWrapper>
                <CardInner to={`/app/show/${showId}/communication/clip/${clipFormat.clip.id}`}>
                    <IconEdit size="1.5rem" color="--neutral500" />
                    <Text color="--neutral500" textAlign="center" fontWeight="--fw-semibold">
                        <FormattedMessage defaultMessage="Continuer l’édition" />
                    </Text>
                </CardInner>
                <MenuStyled clipFormat={clipFormat} />
            </CardWrapper>
            <Stack $gap="0.25rem">
                <Text numberOfLines={1} fontWeight="--fw-semibold">
                    {clipFormat.clip.adminTitle || clipFormat.clip.title}
                </Text>
                {episode && (
                    <Cluster $gap="0.5rem" $align="center" $wrap="nowrap">
                        {episode.imageUrl && <EpisodeCover src={episode.imageUrl} />}
                        <Text color="--neutral500" numberOfLines={1}>
                            {episode.name}
                        </Text>
                    </Cluster>
                )}
            </Stack>
        </Stack>
    );
};

const EpisodeCover = styled.img.attrs({
    loading: 'lazy',
})`
    display: block;
    max-height: 1.5rem;
    max-width: 1.5rem;
    border-radius: var(--r-xs);
`;
const MenuStyled = styled(Menu)`
    opacity: 0;
    transition: opacity 250ms;
    pointer-events: none;
    color: var(--neutral500);
`;
const CardWrapper = styled.button`
    position: relative;
    min-height: 152px;
    border-radius: var(--r-xs);
    background-color: var(--neutral50);
    border: none;
    overflow: hidden;

    &:hover {
        background-color: var(--neutral100);

        & > ${MenuStyled} {
            opacity: 1;
            pointer-events: all;
        }
    }
`;
const CardInner = styled(Link)`
    padding-block: 0.625rem;
    padding-inline: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 0.5rem;
    cursor: pointer;
    text-decoration: none;
`;

export default ClipFormatPending;
