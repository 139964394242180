const campaignKeys = {
    all: () => [{ entity: 'campaign' }],
    allLists: () => [{ ...campaignKeys.all()[0], scope: 'list' }],
    listByShowId: (rawShowId: string | number, { q = '', page = 1, perPage = 15 } = {}) => [
        { ...campaignKeys.allLists()[0], showId: Number(rawShowId), q, page, perPage },
    ],
    listByShowIdInfinite: (rawShowId: string | number, { q = '', page = 1, perPage = 15 } = {}) => [
        {
            ...campaignKeys.allLists()[0],
            mod: 'infinite',
            showId: Number(rawShowId),
            q,
            page,
            perPage,
        },
    ],
    allDetails: () => [{ ...campaignKeys.all()[0], scope: 'detail' }],
    detailById: (rawCampaignId: string | number) => [
        { ...campaignKeys.allDetails()[0], campaignId: Number(rawCampaignId) },
    ],
};

export default campaignKeys;
