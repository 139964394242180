import { useQuery } from '@tanstack/react-query';
import { fetchEpisodeJobsProgress } from '@/api';
import { EPISODE_JOB_STATUS } from '@/shared/config/constants';

export default function useFetchEpisodeJobsProgress({ episodeId: rawEpisodeId, enabled = true }) {
    const episodeId = Number(rawEpisodeId);

    return useQuery({
        queryKey: [{ entity: 'episodes.jobs', episodeId }],
        queryFn: async () => {
            const { data } = await fetchEpisodeJobsProgress(episodeId);
            return data;
        },
        select: (data) => {
            let transformedData = Object.entries(data).reduce(
                (jobs, [job, progress]) => ({
                    ...jobs,
                    [job]: {
                        status: progress.status,
                        percentage: progress.progress_percentage,
                        result: progress.result,
                        isQueued: progress.status === EPISODE_JOB_STATUS.QUEUED,
                        isExecuting: progress.status === EPISODE_JOB_STATUS.EXECUTING,
                        isFinished: progress.status === EPISODE_JOB_STATUS.FINISHED,
                        isFailed: progress.status === EPISODE_JOB_STATUS.FAILED,
                    },
                }),
                {},
            );

            transformedData = {
                ...transformedData,
                isFinished:
                    Object.keys(transformedData).length > 0 &&
                    Object.values(transformedData).every(({ isFinished }) => isFinished),
                isFailed:
                    Object.keys(transformedData).length > 0 &&
                    Object.values(transformedData).some(({ isFailed }) => isFailed),
            };

            return transformedData;
        },
        refetchInterval: (data) => {
            if (data?.isFinished || data?.isFailed) {
                return false;
            }

            return 2000;
        },
        staleTime: Infinity,
        enabled,
    });
}
