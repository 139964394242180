import { createFetcher } from '../Fetcher';

export default createFetcher(
    ({ applePodcastsStatsStore }, { showId, categoryId, start, end, step }) => ({
        fetch: () =>
            applePodcastsStatsStore.fetchApplePodcastsRankingStats(showId, categoryId, {
                start,
                end,
                step,
            }),
        isLoading: applePodcastsStatsStore.fetchApplePodcastsRankingStats.pending,
        cache: applePodcastsStatsStore.waitApplePodcastsRankingOverallFetching,
        input: [showId, categoryId, start, end, step],
    }),
);
