import { useQuery } from '@tanstack/react-query';
import { fetchYoutubeBroadcastSettings } from '@/api';

const useFetchYoutubeBroadcastSettings = (showId) => {
    return useQuery({
        queryKey: ['shows.youtube.show', showId],
        queryFn: async () => {
            const { data } = await fetchYoutubeBroadcastSettings(showId);
            return data;
        },
        retry: false,
        staleTime: 1000 * 60 * 20,
        refetchOnWindowFocus: false,
        enabled: !!showId,
    });
};

export default useFetchYoutubeBroadcastSettings;
