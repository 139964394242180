import { useParams } from 'react-router';
import { SOUNDCLOUD } from '../config/constants';
import { useQueryClient } from '@tanstack/react-query';
import useFetchSocialRedirectUrlMutation from '@queries/socialNetworkPlatform/useFetchSocialRedirectUrlMutation.hook';
import useLoginOnMediaPlatformMutation from '@queries/socialNetworkPlatform/useLoginOnMediaPlatformMutation.hook';
import { useWindowPopupSocialMediaManager } from './useWindowPopupSocialMediaManager';
import providerKeys from '@queries/provider/providerKeys';

interface SoundcloudLoginProps {
    onSuccess?: () => void;
    onError?: (error: unknown) => void;
}

const useSoundcloudLogin = ({ onSuccess, onError }: SoundcloudLoginProps) => {
    const { showId } = useParams<{ showId: string }>();
    const platformId = SOUNDCLOUD;
    const queryClient = useQueryClient();
    const fetchProviderRedirectUrl = useFetchSocialRedirectUrlMutation();
    const loginOnMediaPlatform = useLoginOnMediaPlatformMutation();

    // eslint-disable-next-line no-unused-vars
    const [_, openSocialNetworkOauthLogin] = useWindowPopupSocialMediaManager((e) => {
        if (e.data.provider !== platformId) return;
        loginOnMediaPlatform.mutate(
            { showId, platform: platformId, callbackData: e.data },
            {
                onSuccess: () => {
                    queryClient.invalidateQueries({
                        queryKey: providerKeys.listByShowId(showId),
                    });
                    onSuccess?.();
                },
                onError: (error) => {
                    onError?.(error);
                },
            },
        );
    });

    const handleLogin = () => {
        fetchProviderRedirectUrl.mutate(
            { platformId },
            {
                onSuccess: (data) => {
                    if (!data.loginPageUrl) return;
                    openSocialNetworkOauthLogin?.(data.loginPageUrl);
                },
            },
        );
    };

    return handleLogin;
};

export default useSoundcloudLogin;
