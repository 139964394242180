import { useFormContext } from 'react-hook-form';
import PublishOnSoundcloudToggle from './PublishOnSoundcloudToggle';

const EpisodeEditFormOptionsPublishOnSoundcloudField = ({ isAllowed = false }) => {
    const { control } = useFormContext();

    if (!isAllowed) {
        return null;
    }

    return <PublishOnSoundcloudToggle name="options.soundcloudPublish" control={control} />;
};

export default EpisodeEditFormOptionsPublishOnSoundcloudField;
