import ActiveShowsQuotaModal from '@/components/unorganized/ActiveShowsQuotaModal';
import ActiveShowsQuotaConfirmationModal from '@/components/unorganized/ActiveShowsQuotaConfirmationModal';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';
import useUpdateShowQuotaMutation from '@queries/quota/useUpdateShowQuotaMutation.hook';
import { useBodyToastQueue } from '@/shared/hooks/useBodyToastQueue.hook';
import useShowsQuery from '@queries/show/useShowsQuery.hook';
import useSubscriptionQuery from '@queries/subscription/useSubscriptionQuery.hook';

interface HostedShowsQuotaModalProps {
    isOpen: boolean;
    onOpenChange: (isOpen: boolean) => void;
}

const HostedShowsQuotaModal = ({ isOpen, onOpenChange }: HostedShowsQuotaModalProps) => {
    const toast = useBodyToastQueue();
    const { data: shows } = useShowsQuery();
    const { data: subscription } = useSubscriptionQuery();
    const [quota, setQuota] = useState<null | number>(null);
    const [isQuotaConfirmationModalOpen, setIsQuotaConfirmationModalOpen] = useState(false);
    const updateShowQuota = useUpdateShowQuotaMutation();

    const activeShowAllowance = subscription?.showsMax || 0;
    const currentActiveShows =
        shows?.shows?.filter((show: any) => !show.archived && show.isOwner).length || 0;

    const onSubmit = (quota: number) => {
        if (!quota) return;
        updateShowQuota.mutate(
            { quantity: quota },
            {
                onSuccess: () => {
                    toast.success(
                        <FormattedMessage defaultMessage="Votre quota d'émissions a été mis à jour" />,
                    );
                },
                onError: () => {
                    toast.alert(
                        <FormattedMessage defaultMessage="Une erreur est survenue et votre quota n'a pas été modifié, veuillez réessayer plus tard" />,
                    );
                },
                onSettled: () => {
                    setIsQuotaConfirmationModalOpen(false);
                },
            },
        );
    };

    return (
        <>
            <ActiveShowsQuotaModal
                isOpen={isOpen}
                onOpenChange={onOpenChange}
                currentActiveShows={currentActiveShows}
                activeShowAllowance={activeShowAllowance}
                onSubmit={({ quota }: { quota: number }) => {
                    setQuota(quota);
                    onOpenChange(false);
                    setIsQuotaConfirmationModalOpen(true);
                }}
            />
            {quota && (
                <ActiveShowsQuotaConfirmationModal
                    isOpen={isQuotaConfirmationModalOpen}
                    onOpenChange={setIsQuotaConfirmationModalOpen}
                    isLoading={updateShowQuota.isLoading}
                    onSubmit={onSubmit}
                    quantity={quota}
                />
            )}
        </>
    );
};

export default HostedShowsQuotaModal;
