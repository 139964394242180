import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';

interface CustomTooltipProps {
    seriesIndex: number;
    dataPointIndex: number;
    w: {
        config: any;
        globals: {
            categoryLabels: string[];
            seriesColors: string[];
            seriesNames: string[];
        };
    };
}

const CustomTooltip = ({ seriesIndex, dataPointIndex, w }: CustomTooltipProps) => {
    const data = w.config.series[seriesIndex].data[dataPointIndex];

    let variationPercent;
    if (data.variation > 0) {
        variationPercent = `+${Math.round(data.variation)}%`;
    } else if (data.variation === 0) {
        variationPercent = `0%`;
    } else if (isNaN(data.variation)) {
        variationPercent = '';
    } else {
        variationPercent = `${Math.round(data.variation)}%`;
    }

    return (
        <>
            <Header>
                <Title>{data.longDate}</Title>
            </Header>

            <Content>
                <PlayIcon icon={icon({ name: 'play', style: 'solid' })} />
                <Downloads>{data.y}</Downloads>
                <Variation $color={data.variation > 0 ? '--success' : '--alert'}>
                    {variationPercent}
                </Variation>
            </Content>
        </>
    );
};

const Header = styled.div`
    background-color: var(--neutral50);
    padding: 0.625rem;
`;
const Title = styled.span`
    display: inline-block;
    width: 100%;
    color: var(--neutral);
    text-align: center;
`;
const Content = styled.div`
    padding: 1rem;
    display: flex;
    align-items: center;
    gap: 0.25rem;
`;
const PlayIcon = styled(FontAwesomeIcon)`
    font-size: 0.75rem;
`;
const Downloads = styled.span``;
const Variation = styled.span<{ $color?: string }>`
    color: var(${(props) => props.$color ?? '--black'});
`;

export default CustomTooltip;
