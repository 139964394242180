import statsKeys from '@queries/stats/statsKeys';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { fetchShowSourceOS } from '@/api';
import { z } from 'zod';
import camelcaseKeys from 'camelcase-keys';

interface Query {
    start: string | null;
    end: string | null;
}

interface QueryProps {
    showId: string;
    query: Query;
    options?: {
        enabled?: boolean;
    };
}

const OperatingSystemSchema = z.object({
    count: z.number(),
    osName: z.string(),
    percentage: z.number(),
});

const OperatingSystemsSchema = z.array(OperatingSystemSchema);
export type OperatingSystem = z.infer<typeof OperatingSystemSchema>;

type Key = QueryFunctionContext<ReturnType<typeof statsKeys.operatingSystemsByShowId>>;

const queryFn = async ({ queryKey: [{ showId, query }] }: Key) => {
    const { data } = await fetchShowSourceOS(showId, query);

    return OperatingSystemsSchema.parse(camelcaseKeys(data, { deep: true }));
};

const useShowOperatingSystemsQuery = ({ showId, query, options }: QueryProps) => {
    return useQuery({
        queryKey: statsKeys.operatingSystemsByShowId(showId, query),
        queryFn,
        ...options,
    });
};

export default useShowOperatingSystemsQuery;
