import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ComboBoxController } from '@ui/atoms/ComboBox';
import ListBoxItem from '@ui/atoms/ListBoxItem';
import usePlaylistsQuery from '@/queries/playlist/usePlaylistsQuery.hook';
import { Control, useFormContext } from 'react-hook-form';
import { PlayerFormSchema } from '../../usePlayerFormSchema.hook';

interface PlayerFormSelectPlaylistProps {
    control: Control<PlayerFormSchema>;
    name: string;
    label: string | JSX.Element;
    isDisabled: boolean;
}

const PlayerFormSelectPlaylist = (props: PlayerFormSelectPlaylistProps) => {
    const { setValue, watch } = useFormContext<PlayerFormSchema>();
    const { showId } = useParams<{ showId: string }>();
    const playlists = usePlaylistsQuery(showId);
    const items = useMemo(
        () =>
            playlists.data?.map((playlist) => ({
                id: playlist.slug,
                name: playlist.name,
            })) || [],
        [playlists.data],
    );
    const formSelectedPlaylist = watch('playlistSlug');

    useEffect(() => {
        if (formSelectedPlaylist || items.length === 0) return;
        setValue('playlistSlug', items?.[0]?.id);
    }, [items, formSelectedPlaylist]);

    return (
        <ComboBox {...props} defaultItems={items} isLoading={playlists.isLoading}>
            {/* @ts-ignore */}
            {(item) => <ListBoxItem {...item} />}
        </ComboBox>
    );
};

const ComboBox = styled(ComboBoxController)`
    max-width: 100%;
`;

export default PlayerFormSelectPlaylist;
