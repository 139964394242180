import { createApi } from '../callApi';
const authApi = createApi('AUTH');

export const logout = () => {
    return authApi({ method: 'POST', target: '/logout' });
};
export const loginWith = ({ code, provider }) => {
    return authApi({
        method: 'GET',
        target: `/auth/${provider}/callback`,
        query: { code },
    });
};
export const requestAuthorizationCode = (query) => {
    return authApi({ method: 'POST', target: '/oauth/code', query });
};
export const fetchTimezones = () => {
    return authApi({ method: 'GET', target: '/timezones' });
};
export const fetchCountries = () => {
    return authApi({ method: 'GET', target: '/countries' });
};
export const updateProfile = (body) => {
    return authApi({ method: 'PUT', target: '/user', body });
};
export const updatePassword = (body) => {
    return authApi({ method: 'POST', target: '/password', body });
};
export const setResetPassword = (body) => {
    return authApi({ method: 'POST', target: '/password/email', body });
};
export const resetPassword = (body) => {
    return authApi({ method: 'POST', target: '/password/reset', body });
};
export const updateEmail = (body) => {
    return authApi({ method: 'POST', target: '/email', body });
};
export const deleteUserAvatar = () => {
    return authApi({ method: 'DELETE', target: '/user/avatar' });
};
export function uploadUserAvatar(image) {
    const body = new FormData();
    body.append('file', image);
    return authApi({ method: 'POST', target: '/user/avatar', body });
}
export const resendVerificationEmail = () => {
    return authApi({ method: 'POST', target: '/email/resend' });
};
