import deezer from '@public/images/platforms/Deezer.png';
import ShortListFallback from '@app/fallbacks/ShortListFallback';
import BroadcastCard from '../../BroadcastCard';
import { DEEZER, LISTENING_PLATFORMS_NAMES } from '@/utils/constants';
import { useParams } from 'react-router-dom';
import useShowQuery from '@/queries/show/useShowQuery.hook';

const DeezerBroadcast = () => {
    const { showId } = useParams<{ showId: string }>();
    const { data: show, isLoading } = useShowQuery(showId);
    const url = show?.listeningLinks?.data.find((link) => link.key === DEEZER)?.url;

    if (isLoading) return <ShortListFallback />;

    return (
        <BroadcastCard
            platformKey={DEEZER}
            name={LISTENING_PLATFORMS_NAMES[DEEZER]}
            logo={deezer}
            url={url}
        />
    );
};

export default DeezerBroadcast;
