import { QueryFunctionContext, UseQueryResult, useQuery } from '@tanstack/react-query';
import { fetchListenablePodcasts } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import episodeKeys from './episodeKeys';

export interface ListenableEpisode {
    audioUrl: string;
    createdAt: string;
    id: number;
    imageUrl: string;
    name: string;
    publicId: string;
    publishedAt: string;
    state: string;
}

const transform = (data: unknown) => {
    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data as any, { deep: true });
};

const queryFn = async ({
    queryKey: [{ showId }],
}: QueryFunctionContext<ReturnType<typeof episodeKeys.listListenableByShowId>>) => {
    const { data } = await fetchListenablePodcasts(showId, { transformer: 'minimal' });

    return transform(data);
};

const useListnableEpisodesQuery = (showId: string): UseQueryResult<ListenableEpisode[]> => {
    return useQuery({
        queryKey: episodeKeys.listListenableByShowId(showId),
        queryFn,
        enabled: !!showId,
    });
};

export default useListnableEpisodesQuery;
