import styled from 'styled-components';
import DeprecatedText from '../DeprecatedText';
import { resolveColor } from '@/utils/cssVariables';

const Column = styled(DeprecatedText)`
    grid-column: ${(p) =>
        p.gridColumn || (p.startColumn && p.endColumn && `${p.startColumn} / ${p.endColumn}`)};
    display: flex;
    align-items: ${(p) => p.align};
    background: ${(p) => resolveColor(p.background)};
    border: ${(p) => (p.borderColor ? p.borderColor : resolveColor(p.border))}
        ${(p) => (p.b || p.bt || p.bb || p.br || p.bl) && 'solid'};
    border-width: ${(p) =>
        p.b || p.bt || p.br || p.bb || p.bl
            ? `${p.bt || p.b}px ${p.br || p.b}px ${p.bb || p.b}px ${p.bl || p.b}px`
            : '1px'};
`;

Column.defaultProps = {
    ...DeprecatedText.defaultProps,
    align: 'center',
    startColumn: undefined,
    endColumn: undefined,
    background: undefined,
    border: '--neutral100',
    as: 'span',
    bt: 0,
    br: 0,
    bb: 0,
    bl: 0,
    b: 0,
};

export default Column;
