import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchShowTemplate } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import showTemplateKeys from './showTemplateKeys';
import { z } from 'zod';
import { TWITTER } from '@/shared/config/constants';

export const XShowTemplateSchema = z.object({
    messageTpl: z.string().nullable(),
    provider: z.literal(TWITTER),
});

export type XShowTemplate = z.infer<typeof XShowTemplateSchema>;

type Key = QueryFunctionContext<ReturnType<typeof showTemplateKeys.detailByProvider>>;

const queryFn = async ({ queryKey: [{ showId, provider }] }: Key) => {
    const { data } = await fetchShowTemplate(showId, provider);
    return XShowTemplateSchema.parse(camelcaseKeys(data, { deep: true }));
};

const useXShowTemplateQuery = (showId: string | number) => {
    return useQuery({
        queryKey: showTemplateKeys.detailByProvider(showId, TWITTER),
        queryFn,
    });
};

export default useXShowTemplateQuery;
