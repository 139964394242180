import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Spinner from '@ui/atoms/Spinner';
import TunnelStepper from '../TunnelStepper';
import Text from '@ui/atoms/Text';
import PsoLanding from '@public/images/empty-state/illustration/pso-landing.png';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

interface ConfigurationEndLoaderProps {
    onLoadingEnd: () => void;
}

const ConfigurationEndLoader = ({ onLoadingEnd }: ConfigurationEndLoaderProps) => {
    useEffect(() => {
        const redirectAfter = setTimeout(() => {
            onLoadingEnd();
        }, 4000);

        return () => {
            clearTimeout(redirectAfter);
        };
    }, []);
    return (
        <ConfigurationEndWrapper>
            <Left>
                <TunnelStepper currentStep={3} stepCount={3} />
                <Content>
                    <Spinner size={128} strokeWidth={8} />
                    <TitleWrapper>
                        <Title>
                            <FormattedMessage defaultMessage="Analyse en cours..." />
                        </Title>
                        <SubTitle>
                            <FormattedMessage defaultMessage="Votre tableau de bord sera prêt d’ici quelques instants ⏳" />
                        </SubTitle>
                    </TitleWrapper>
                </Content>
            </Left>
            <IllustrationBlock>
                <IllustrationImage src={PsoLanding} />
            </IllustrationBlock>
        </ConfigurationEndWrapper>
    );
};

const ConfigurationEndWrapper = styled.div`
    height: 27rem;
    display: flex;
    gap: 3rem;
    & > * {
        flex: 1;
        align-self: stretch;
    }
`;
const Left = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5rem;
`;
const IllustrationBlock = styled.div`
    border-radius: var(--r-l);
    display: flex;
    max-width: 560px;
    min-width: 50%;
    margin-inline: auto;
    background: var(--gradient-dusk);
    padding-block: 2rem;
    padding-left: 2rem;
    align-items: center;
    justify-content: flex-end;
`;
const IllustrationImage = styled.img`
    border-radius: var(--r-l) 0 0 var(--r-l);
    width: calc(100% - 2rem);
`;
const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
`;
const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
`;
const Title = styled(Text)`
    font-size: var(--fs-heading-s);
    font-weight: var(--fw-bold);
`;
const SubTitle = styled(Text)`
    color: var(--neutral500);
`;

ConfigurationEndLoader.propTypes = {
    onLoadingEnd: PropTypes.func.isRequired,
};

export default ConfigurationEndLoader;
