import { useEffect } from 'react';

interface UseLoadContentProps {
    editor: any; // TODO: Replace with Editor from '@tiptap/react';
    isLoading: boolean;
    loadingContent: string | undefined;
    onLoadingEnd?: () => void;
}

export const useLoadContent = ({
    editor,
    isLoading,
    loadingContent,
    onLoadingEnd,
}: UseLoadContentProps) => {
    useEffect(() => {
        if (!editor) return;

        if (loadingContent && loadingContent.length > 0) {
            let sentences: string[] = loadingContent.match(/[^.!?]+[.!?]*/g) || [];

            // Clear the content of the editor before starting to write the message
            editor.commands.clearContent();

            if (isLoading) {
                const interval = setInterval(() => {
                    if (sentences.length > 0) {
                        // If there are still sentences to display, pop the next sentence at the end of the text input and move loading cursor
                        const [nextSentence, ...rest] = sentences;
                        editor
                            .chain()
                            .clearContentLoading()
                            .focus('end')
                            .insertContent(`${nextSentence} `)
                            .insertContentLoading()
                            .run();
                        sentences = rest;
                    } else {
                        onLoadingEnd?.();
                    }
                }, 300);
                return () => clearInterval(interval);
            } else {
                editor.commands.clearContent();
                editor.chain().clearContentLoading().insertContent(loadingContent).run();
            }
        }
        // All sentences are displayed and writing of the message is complete
        editor.chain().clearContentLoading().run();
    }, [editor, isLoading, loadingContent]);
};
