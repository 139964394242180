import { createContext } from 'react';
import { Route, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import VerticalTab from '../../ui/molecules/VerticalTab';
import RssImport from '../../unorganized/RssImport';
import RssExport from '../../unorganized/RssExport';
import Seasons from '../../unorganized/Seasons';
import Broadcast from '../../unorganized/Broadcast';
import HideFor from '../../unorganized/HideFor';
import VerticalTabAccess from '../../unorganized/VerticalTabAccess';
import TemplateTabsFixed from '../../ui/Templates/TemplateTabsFixed';
import RouteTransition from '../../ui/organisms/RouteTransition';
import { STATUS_SHOWS } from '@/shared/config/statusShows';
import styled from 'styled-components';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import ShowGuests from '@/components/ShowGuests';
import AushaProShowSettingsPage from './AushaProShowSettingsPage';
import { USER_OPTIONS } from '@/shared/config/userOptions';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useGuard } from '@/shared/hooks/useGuard.hook';
import { PRICING } from '@/shared/config/pricing';
import ShowEditPage from './ShowEditPage';
import AISettings from '@/components/AISettings/AISettings';
import { ENABLE_EPISODE_AI } from '@/shared/config/constants';
import useHasAccessToEpisodeAI from '@/shared/hooks/useHasAccessToEpisodeAI.hook';
import DeleteShow from '../../unorganized/DeleteShow';

const { ARCHIVED_SHOWS } = STATUS_SHOWS;

export const RouteSettingsPageContext = createContext(null);

function SettingsPage({ match }) {
    const { showId } = useParams();
    const { data: show } = useShowQuery(showId);
    const { data: user } = useUserQuery();
    const SLIDE = 20;

    const showHasHostingAccess = show?.hostedOnAusha === true;
    const hasAccessToWhiteLabel = show?.userOptions.includes(USER_OPTIONS.WHITE_LABEL);
    const hasAccessToCustomLogo = show?.userOptions.includes(USER_OPTIONS.CUSTOMIZABLE_LOGO);
    const hasAccessToAushaProSettings = hasAccessToWhiteLabel || hasAccessToCustomLogo;
    const hasAccessToUsersManagement =
        !user?.options?.includes(USER_OPTIONS.MULTI_USERS_MANAGEMENT) || user?.guest;
    const hasAccessToAI = useHasAccessToEpisodeAI({ showId });
    const guard = useGuard({
        disallowedPlans: [PRICING.FREEMIUM, PRICING.LAUNCH],
    });

    const TAB_TITLES_SETTINGS = {
        info: <FormattedMessage defaultMessage="Informations" />,
        pro: <FormattedMessage defaultMessage="Ausha Pro" />,
        broadcast: <FormattedMessage defaultMessage="Diffusion" />,
        'broadcast/non-hosting': <FormattedMessage defaultMessage="Configuration" />,
        'rss-import': <FormattedMessage defaultMessage="Import RSS" />,
        'rss-export': <FormattedMessage defaultMessage="Export RSS" />,
        seasons: <FormattedMessage defaultMessage="Saisons" />,
        users: (
            <TitleWrapper>
                <FormattedMessage defaultMessage="Utilisateurs" />
                {!guard.allow && <DiamondIcon icon={icon({ name: 'gem', style: 'duotone' })} />}
            </TitleWrapper>
        ),
        'show-delete': <FormattedMessage defaultMessage="Supprimer" />,
        mobile: <FormattedMessage defaultMessage="Paramètres" />,
        ai: (
            <TitleWrapper>
                Ausha Intelligence
                {!hasAccessToAI && <DiamondIcon icon={icon({ name: 'gem', style: 'duotone' })} />}
            </TitleWrapper>
        ),
    };

    return (
        <RouteSettingsPageContext.Provider value={TAB_TITLES_SETTINGS}>
            <SettingsPageContentWrapper>
                <Route
                    path="/app/show/:showId/settings/:tab"
                    render={() => (
                        <TemplateTabsFixed
                            sectionTitle={<FormattedMessage defaultMessage="Paramètres" />}
                            routeTabs="menu.settings.tab"
                            tabsTitle={TAB_TITLES_SETTINGS}
                            tabs={
                                <>
                                    <VerticalTabAccess showStatus={[ARCHIVED_SHOWS]} tab="info" />
                                    {hasAccessToAushaProSettings && (
                                        <VerticalTabAccess
                                            showStatus={[ARCHIVED_SHOWS]}
                                            tab="pro"
                                        />
                                    )}
                                    <VerticalTabAccess
                                        showStatus={[ARCHIVED_SHOWS]}
                                        tab={
                                            showHasHostingAccess
                                                ? 'broadcast'
                                                : 'broadcast/non-hosting'
                                        }
                                    />
                                    {showHasHostingAccess && (
                                        <>
                                            <VerticalTabAccess
                                                showStatus={[ARCHIVED_SHOWS]}
                                                tab="rss-import"
                                            />
                                            <VerticalTabAccess
                                                showStatus={[ARCHIVED_SHOWS]}
                                                tab="rss-export"
                                            />
                                            <VerticalTabAccess
                                                showStatus={[ARCHIVED_SHOWS]}
                                                tab="seasons"
                                            />
                                        </>
                                    )}
                                    {hasAccessToUsersManagement && (
                                        <VerticalTabAccess tab="users" />
                                    )}
                                    {ENABLE_EPISODE_AI && <VerticalTabAccess tab="ai" />}
                                    <HideFor roles={['admin']}>
                                        <VerticalTab tab="show-delete" />
                                    </HideFor>
                                </>
                            }
                        >
                            <RouteTransition path={`${match.path}/info`} slide={SLIDE}>
                                <ShowEditPage />
                            </RouteTransition>
                            {hasAccessToAushaProSettings && (
                                <RouteTransition path={`${match.path}/pro`} slide={SLIDE}>
                                    <AushaProShowSettingsPage />
                                </RouteTransition>
                            )}
                            <RouteTransition path={`${match.path}/broadcast`} slide={SLIDE}>
                                <Broadcast />
                            </RouteTransition>
                            {showHasHostingAccess && (
                                <>
                                    <RouteTransition
                                        path={`${match.path}/rss-import`}
                                        slide={SLIDE}
                                    >
                                        <RssImport />
                                    </RouteTransition>
                                    <RouteTransition
                                        path={`${match.path}/rss-export`}
                                        slide={SLIDE}
                                    >
                                        <RssExport />
                                    </RouteTransition>
                                    <RouteTransition path={`${match.path}/seasons`} slide={SLIDE}>
                                        <Seasons />
                                    </RouteTransition>
                                </>
                            )}
                            {hasAccessToUsersManagement && (
                                <RouteTransition path={`${match.path}/users`} slide={SLIDE}>
                                    <ShowGuests />
                                </RouteTransition>
                            )}
                            {ENABLE_EPISODE_AI && (
                                <RouteTransition path={`${match.path}/ai`} slide={SLIDE}>
                                    <AISettings />
                                </RouteTransition>
                            )}
                            <RouteTransition path={`${match.path}/show-delete`} slide={SLIDE}>
                                <DeleteShow />
                            </RouteTransition>
                        </TemplateTabsFixed>
                    )}
                />
            </SettingsPageContentWrapper>
        </RouteSettingsPageContext.Provider>
    );
}

const SettingsPageContentWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 2rem;
    padding-top: 4rem;
    padding-inline: 1.5rem;
    margin-inline: auto;
    width: 100%;
    max-width: var(--small-container-width);

    ${({ theme }) => theme.mediaQueries.mobile} {
        padding-inline: 0.75rem;
    }
`;
const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;
const DiamondIcon = styled(FontAwesomeIcon)`
    color: var(--primary);
`;

export default SettingsPage;
