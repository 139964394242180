import { createContext, useContext } from 'react';
import type { Episode } from '@/queries/episode/useEpisodeQuery.hook';

export const EpisodeBeingEditedContext = createContext<Episode | null>(null);

export const useEpisodeBeingEdited = () => {
    return useContext(EpisodeBeingEditedContext);
};

interface EpisodeEditFormContextProps {
    episode: Episode;
    children: React.ReactNode;
}

const EpisodeEditFormContext = ({ episode, children }: EpisodeEditFormContextProps) => {
    return (
        <EpisodeBeingEditedContext.Provider value={episode}>
            {children}
        </EpisodeBeingEditedContext.Provider>
    );
};

export default EpisodeEditFormContext;
