import { useState, useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDebounce } from '@/shared/hooks/useDebounce';
import useEpisodesInfiniteQuery, { Episode } from '@/queries/episode/useEpisodesInfiniteQuery.hook';
import Button from '@/components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { SearchField, Input, Button as RAButton, ListBox } from 'react-aria-components';
import Checkbox from '@/components/ui/Checkbox';
import useQuery from '@/shared/hooks/useQuery';
import { PRICING } from '@/shared/config/pricing';
import usePricing from '@/shared/hooks/usePricing.hook';
import styled from 'styled-components';
import ListBoxItem from '@/components/ui/atoms/ListBoxItem';

const maxSelectableForPlan = {
    [PRICING.FREEMIUM]: 3,
    [PRICING.LAUNCH]: 3,
    [PRICING.BOOST]: 5,
    [PRICING.SUPERSONIC]: 7,
    [PRICING.ENTERPRISE]: Infinity,
};

interface EpisodesListProps {
    onClose: () => void;
}

const EpisodesList = ({ onClose }: EpisodesListProps) => {
    const intl = useIntl();
    const location = useLocation();
    const history = useHistory();
    const { showId } = useParams<{ showId: string }>();
    const query = useQuery();
    const episode = query.get('episode');
    const episodesQuery = new Set(
        episode && episode !== 'all' ? episode.split('_').map((e) => parseInt(e)) : [],
    );

    const [selectedEpisodes, setSelectedEpisodes] = useState(episodesQuery);
    const [allSelected, setAllSelected] = useState(episode === 'all');

    const [episodeSearchValue, setEpisodeSearchValue] = useState('');
    const debouncedEpisodeSearchValue = useDebounce(episodeSearchValue, 500);

    const episodes = useEpisodesInfiniteQuery({
        showId,
        query: debouncedEpisodeSearchValue,
        perPage: 20,
        status: ['active'],
    });

    const plan = usePricing(showId);
    const maxSelectable = maxSelectableForPlan[plan];

    const episodeInfiniteList = useMemo(
        () =>
            episodes?.data?.pages
                ?.reduce((acc, page) => acc.concat(page.episodes as Episode[]), [] as Episode[])
                ?.map((episode) => ({
                    id: episode.id,
                    name: episode.name,
                })),
        [episodes?.data?.pages],
    );

    const hasMoreItems =
        (episodes?.data?.pages[episodes?.data?.pages.length - 1].pagination?.totalPages ?? 0) >
        (episodes?.data?.pages[episodes?.data?.pages.length - 1].pagination?.currentPage ?? 0);

    const handleLoadMore = () => {
        if (!episodes?.hasNextPage || episodes?.isFetchingNextPage || episodes?.isFetching) {
            return;
        }
        episodes?.fetchNextPage();
    };

    return (
        <EpisodesListWrapper>
            <EpisodeSearchField>
                {episodes.isLoading ? (
                    <SearchIcon icon={icon({ name: 'spinner-third', style: 'solid' })} spin />
                ) : (
                    <SearchIcon icon={icon({ name: 'magnifying-glass', style: 'solid' })} />
                )}
                <SearchInput
                    placeholder={intl.formatMessage({ defaultMessage: 'Rechercher un épisode' })}
                    onChange={(e) => setEpisodeSearchValue(e?.target?.value ?? '')}
                />
                {episodeSearchValue && (
                    <EraseSearchButton>
                        <FontAwesomeIcon icon={icon({ name: 'circle-xmark', style: 'solid' })} />
                    </EraseSearchButton>
                )}
            </EpisodeSearchField>
            {episodes.isLoading && episodeInfiniteList && episodeInfiniteList?.length > 0 ? (
                <LoadingWrapper>
                    <FontAwesomeIcon icon={icon({ name: 'spinner-third', style: 'solid' })} spin />
                </LoadingWrapper>
            ) : (
                <ListWrapper>
                    <CheckboxWrapper>
                        <Checkbox
                            isSelected={allSelected}
                            onChange={() => setAllSelected(!allSelected)}
                        >
                            <FormattedMessage defaultMessage="Tous les épisodes" />
                        </Checkbox>
                    </CheckboxWrapper>
                    <ScrollableListBox
                        aria-label="Episodes"
                        selectionMode="multiple"
                        selectedKeys={allSelected ? 'all' : selectedEpisodes}
                        onSelectionChange={(keys) => {
                            if (allSelected) return;
                            if ((keys as Set<number>).size > maxSelectable) return;
                            setSelectedEpisodes(keys as Set<number>);
                        }}
                        items={episodeInfiniteList}
                        disabledKeys={episodeInfiniteList
                            ?.filter(
                                (item) =>
                                    !allSelected &&
                                    selectedEpisodes.size >= maxSelectable &&
                                    !selectedEpisodes.has(item.id),
                            )
                            .map((item) => item.id)}
                    >
                        {(item) => <ListBoxItem {...item} />}
                    </ScrollableListBox>
                </ListWrapper>
            )}
            {hasMoreItems && (
                <LoadMoreButton variant="ghost" onPress={handleLoadMore}>
                    <FormattedMessage defaultMessage="Charger plus" />
                </LoadMoreButton>
            )}

            <ActionWrapper>
                <CloseButton variant="ghost" onPress={onClose}>
                    <FormattedMessage defaultMessage="Annuler" />
                </CloseButton>
                <Button
                    onPress={() => {
                        const path =
                            Array.isArray(selectedEpisodes) && selectedEpisodes.length === 0
                                ? location.pathname
                                : `${location.pathname}?episode=${
                                      allSelected ? 'all' : Array.from(selectedEpisodes).join('_')
                                  }`;

                        history.push(path);
                        onClose();
                    }}
                >
                    <FormattedMessage defaultMessage="Appliquer" />
                </Button>
            </ActionWrapper>
        </EpisodesListWrapper>
    );
};

const EpisodesListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-height: 20rem;
    overflow: hidden;
`;
const EpisodeSearchField = styled(SearchField)`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background-color: var(--neutral50);
    padding-block: 0.25rem;
    padding-inline: 0.5rem;
    border-radius: var(--r-s);
`;
const SearchIcon = styled(FontAwesomeIcon)`
    font-size: 0.75rem;
    color: var(--neutral500);
`;
const SearchInput = styled(Input)`
    flex: 1;
    border: none;
    background: none;
`;
const EraseSearchButton = styled(RAButton)`
    border: none;
    background: none;
    color: var(--neutral500);
`;
const ListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    flex: 1;
    min-height: 0;
    overflow: hidden;
`;
const CheckboxWrapper = styled.div`
    padding-left: 0.75rem;
`;
const ScrollableListBox = styled(ListBox)`
    overflow-y: auto;
    padding-right: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    flex: 1;
    min-height: 0;

    &::-webkit-scrollbar {
        width: 0.25rem;
    }

    &::-webkit-scrollbar-track {
        background: var(--neutral50);
    }

    &::-webkit-scrollbar-thumb {
        background: var(--neutral300);
        border-radius: var(--r-xs);
    }
`;
const LoadMoreButton = styled(Button)`
    &:hover {
        background-color: var(--primary50);
    }
`;
const LoadingWrapper = styled.div`
    width: 100%;
    height: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--neutral);
`;
const ActionWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;

    & > * {
        flex: 1;
    }
`;
const CloseButton = styled(Button)`
    color: var(--neutral);
`;

export default EpisodesList;
