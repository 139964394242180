import { createPortal } from 'react-dom';
import { AnimatePresence, motion } from 'framer-motion';
import DeprecatedIcon from '../atoms/DeprecatedIcon';
import CloseBackIcon from '@public/icons/close.svg';
import styled from 'styled-components';

const PortalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 9999;
    background: white;
`;

const PortalInnerWrapper = styled.div`
    border-bottom: 1px solid var(--neutral100);
    width: 100%;
    padding: 1.5rem 2rem;
    display: flex;
    align-items: center;
`;

const PortalContent = ({ onClose, isOpened, children }) => {
    const modalRoot = document.getElementById('modal-root');

    return createPortal(
        <AnimatePresence initial={false}>
            {isOpened && (
                <motion.div
                    animate="open"
                    exit="close"
                    variants={{
                        open: { opacity: 1, height: '100vh' },
                        close: { opacity: 0, height: 0 },
                    }}
                    transition={{ duration: 0.5 }}
                >
                    <PortalWrapper>
                        <PortalInnerWrapper>
                            <DeprecatedIcon
                                as={CloseBackIcon}
                                color="black"
                                size={10}
                                onClick={onClose}
                                cursor="pointer"
                            />
                        </PortalInnerWrapper>
                        {children}
                    </PortalWrapper>
                </motion.div>
            )}
        </AnimatePresence>,
        modalRoot,
    );
};

export default PortalContent;
