import DesktopOnlyIllustration from '@public/images/pso-desktop-only.png';
import laptop from '@public/images/emoji/laptop.png';
import ZebraIllustration from '@ui/atoms/ZebraIllustration';
import { FormattedMessage } from 'react-intl';
import Text from '@ui/atoms/Text';
import styled from 'styled-components';

const PsoMobileBlockScreen = () => {
    return (
        <MobileBlockScreenOuterWrapper>
            <TitleWrapper>
                <Title>
                    <FormattedMessage defaultMessage="Référencement" />
                </Title>
                <SubTitle>PSO Control Panel</SubTitle>
            </TitleWrapper>
            <MobileBlockScreenWrapper>
                <HeadIllustrationWrapper>
                    <ZebraIllustration foreground={laptop} />
                </HeadIllustrationWrapper>
                <TextWrapper>
                    <Main>
                        <FormattedMessage defaultMessage="Cette fonctionnalité n’est pas disponible sur petit écran." />
                    </Main>
                    <Sub>
                        <FormattedMessage defaultMessage="Pour une expérience optimale, merci d'utiliser un plus grand écran pour accéder à cette page." />
                    </Sub>
                </TextWrapper>
                <Illustration src={DesktopOnlyIllustration} />
            </MobileBlockScreenWrapper>
        </MobileBlockScreenOuterWrapper>
    );
};
const MobileBlockScreenOuterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem;
`;
const MobileBlockScreenWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: 2rem;
    padding-bottom: 0;
`;
const HeadIllustrationWrapper = styled.div`
    width: 200px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;
const Title = styled.div`
    font-weight: var(--fw-bold);
    font-size: var(--fs-heading-m);
`;
const SubTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: var(--fw-semibold);
    font-size: var(--fs-body-s);
    background-color: var(--primary50);
    color: var(--primary);
    padding-block: 0.5rem;
    padding-inline: 0.75rem;
    border-radius: var(--r-s);
`;
const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
`;
const Main = styled(Text)`
    font-size: var(--fs-heading-s);
    font-weight: var(--fw-bold);
`;
const Sub = styled(Text)`
    font-size: var(--fs-body-m);
    color: var(--neutral500);
`;
const Illustration = styled.img`
    width: 100%;
    max-width: 36rem;
`;

export default PsoMobileBlockScreen;
