import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchSharingProvider } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import providerKeys from './providerKeys';
import { z } from 'zod';

export const soundcloudAccountSchema = z.object({
    id: z.string().optional().nullable(),
    url: z.string().optional().nullable(),
    avatar: z.string().optional().nullable(),
    name: z.string().optional().nullable(),
});
export type SoundcloudAccount = z.infer<typeof soundcloudAccountSchema>;
type Key = QueryFunctionContext<ReturnType<typeof providerKeys.detailForSoundcloud>>;
type InputProps = {
    showId: string;
};

const queryFn = async ({ queryKey: [{ showId, provider }] }: Key) => {
    const { account } = await fetchSharingProvider(showId, provider);
    return soundcloudAccountSchema.parse(camelcaseKeys(account, { deep: true }));
};

const useSoundcloudProviderQuery = ({ showId }: InputProps) => {
    return useQuery({
        queryKey: providerKeys.detailForSoundcloud(showId),
        queryFn,
    });
};

export default useSoundcloudProviderQuery;
