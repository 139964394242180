import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import Chart from 'react-apexcharts';
import { useIntl } from 'react-intl';
import { extractCssVariable } from '@/utils/cssVariables';
import TooltipContent from './TooltipContent';
import dayjs from 'dayjs';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import styled from 'styled-components';
require('dayjs/locale/fr');

const PsoVisibilityScoreHistoryGraph = ({ dates, scores }) => {
    const intl = useIntl();
    const user = useUserQuery();
    const chartOptions = {
        options: {
            chart: {
                id: 'basic-bar',
                type: 'area',
                toolbar: { show: false },
                zoom: { enabled: false },
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'light',
                    type: 'vertical',
                    shadeIntensity: 0.4,
                    opacityFrom: 0.4,
                    opacityTo: 0,
                },
            },
            dataLabels: {
                enabled: false,
            },
            xaxis: {
                categories: dates.map((date) =>
                    dayjs(date)
                        .locale(user?.data?.language || 'en')
                        .format('D MMM'),
                ),
                labels: {
                    style: {
                        colors: extractCssVariable('--neutral500'),
                        fontWeight: extractCssVariable('--fw-semibold'),
                        fontSize: extractCssVariable('--fs-body-s'),
                        fontFamily: 'ProximaNova, sans-serif',
                    },
                },
                tickAmount: 10,
                tooltip: {
                    enabled: false,
                },
                crosshairs: {
                    show: false,
                },
            },
            yaxis: {
                labels: {
                    style: {
                        colors: extractCssVariable('--neutral500'),
                        fontWeight: extractCssVariable('--fw-semibold'),
                        fontSize: extractCssVariable('--fs-body-s'),
                        fontFamily: 'ProximaNova, sans-serif',
                    },
                },
            },
            stroke: {
                curve: 'smooth',
                lineCap: 'round',
                width: 3,
            },
            legend: {
                show: false,
            },
            noData: {
                text: intl.formatMessage({
                    defaultMessage: 'Aucun historique de données',
                }),
                align: 'center',
                verticalAlign: 'middle',
                offsetX: 0,
                offsetY: 0,
                style: {
                    color: extractCssVariable('--neutral500'),
                    fontWeight: extractCssVariable('--fw-semibold'),
                    fontSize: extractCssVariable('--fs-body-m'),
                    fontFamily: 'ProximaNova, sans-serif',
                },
            },
            tooltip: {
                theme: 'dark',
                custom: (props) => {
                    const tooltipNode = document.createElement('div');
                    ReactDOM.render(
                        <TooltipContent
                            {...props}
                            averageTranslatedString={intl.formatMessage({
                                defaultMessage: 'Moy.globale',
                            })}
                        />,
                        tooltipNode,
                    );
                    return tooltipNode.innerHTML;
                },
            },
            colors: [extractCssVariable('--primary500')],
        },
        series: [
            {
                name: 'scores',
                data: scores,
            },
        ],
    };

    return (
        <VisibilityScoreChart
            options={chartOptions.options}
            series={chartOptions.series}
            type="area"
            height={240}
        />
    );
};

const VisibilityScoreChart = styled(Chart)`
    width: 100%;
`;

PsoVisibilityScoreHistoryGraph.propTypes = {
    dates: PropTypes.arrayOf(PropTypes.string),
    scores: PropTypes.arrayOf(PropTypes.number),
};

export default PsoVisibilityScoreHistoryGraph;
