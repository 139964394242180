import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Text from '@ui/atoms/Text';
import Button from '@/components/Button';

interface AgesExportProps {
    exportCsv: () => void;
}

const AgesExport = ({ exportCsv }: AgesExportProps) => (
    <Export
        variant="ghost"
        startIcon={
            <FontAwesomeIcon icon={icon({ name: 'arrow-up-right-from-square', style: 'solid' })} />
        }
        onPress={exportCsv}
    >
        <Text fontWeight="--fw-semibold">
            <FormattedMessage defaultMessage="Exporter en .csv" />
        </Text>
    </Export>
);

const Export = styled(Button)`
    display: flex;
    margin-left: auto;
    padding-top: 1rem;
    padding-bottom: 2rem;
`;

export default AgesExport;
