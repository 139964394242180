import Text from '@ui/atoms/Text';
import Cluster from '@ui/layout/Cluster';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import {
    useEpisodeTranscription,
    useEpisodeTranscriptionStatus,
} from '../EpisodeEditTranscription/EpisodeEditTranscriptionProvider';
import { EPISODE_TRANSCRIPT_STATUS } from '@/shared/config/constants';
import { useParams } from 'react-router';
import useHasAccessToTranscription from '@/shared/hooks/useHasAccessToTranscription.hook';

const TranscriptionSummary = () => {
    const { showId } = useParams<{ showId: string }>();
    const hasAccessToTranscription = useHasAccessToTranscription({ showId });
    const transcription = useEpisodeTranscription();
    const transcriptionStatus = useEpisodeTranscriptionStatus();
    const isTranscribed =
        transcription?.data &&
        (transcriptionStatus?.status === EPISODE_TRANSCRIPT_STATUS.UPDATED ||
            transcriptionStatus?.status === EPISODE_TRANSCRIPT_STATUS.DONE);

    if (!hasAccessToTranscription) return null;

    return (
        <Summary $isTranscribed={isTranscribed}>
            <Text color="--black" fontWeight="--fw-semibold">
                <FormattedMessage defaultMessage="Transcription" />
            </Text>
            <Cluster $gap="0.5rem" $align="center">
                {isTranscribed && (
                    <CheckIcon
                        icon={icon({
                            name: 'check-circle',
                            style: 'solid',
                        })}
                    />
                )}
                <Text fontWeight="--fw-semibold">
                    {isTranscribed ? (
                        <FormattedMessage defaultMessage="Transcrit" />
                    ) : (
                        <FormattedMessage defaultMessage="Non transcrit" />
                    )}
                </Text>
            </Cluster>
        </Summary>
    );
};

const Summary = styled.div<{ $isTranscribed: boolean }>`
    display: flex;
    align-items: center;
    column-gap: 1rem;
    justify-content: space-between;
    background-color: var(--neutral50);
    border-radius: 0.75rem;
    padding: 1rem;
    color: var(--neutral500);

    ${({ $isTranscribed }) =>
        $isTranscribed &&
        css`
            background-color: var(--success50);
            color: var(--success500);
        `}
`;
const CheckIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;

export default TranscriptionSummary;
