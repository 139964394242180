import Text from '@/components/ui/atoms/Text';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Status from './Status';
import Settings from './Settings';
import Stack from '@/components/ui/layout/Stack';
import ZebraIllustration from '@/components/ui/atoms/ZebraIllustration';
import RouterLink from '@/components/Link/RouterLink';
import EmptyState from '@/components/ui/molecules/EmptyState';
import Earth from '@public/images/earth.svg';
import { useParams } from 'react-router';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import satelliteIllustration from '@public/images/satellite.webp';

const Hosting = () => {
    const { showId } = useParams<{ showId: string }>();
    const show = useShowQuery(showId);
    const isShowHosted = show.data && show.data.hostedOnAusha;

    // TODO: Check for hosting subscription when available
    if (!isShowHosted) {
        return (
            <EmptyStateStyled
                illustration={satelliteIllustration}
                illustrationType="image"
                illustrationAlign="center"
                illustrationBackground="--gradient-dawn"
            >
                <HeadIllustrationWrapper>
                    <ZebraIllustration>
                        <EarthIllustration />
                    </ZebraIllustration>
                </HeadIllustrationWrapper>
                <LabelWrapper>
                    <Text variant="headingS" fontWeight="--fw-bold">
                        <FormattedMessage defaultMessage="Hébergez votre podcast sur Ausha" />
                    </Text>
                    <Text color="--neutral500">
                        <FormattedMessage defaultMessage="Profitez d'un hébergement illimité, d'une publication sans effort et d'un contrôle total pour développer votre podcast en toute simplicité." />
                    </Text>
                </LabelWrapper>
                <RouterLink
                    variant="button-primary"
                    startIcon={
                        <EmptyStateButtonIcon
                            icon={icon({ name: 'rocket-launch', style: 'solid' })}
                        />
                    }
                    to="/app/subscription/offers"
                >
                    <FormattedMessage defaultMessage="Souscrire à l'hébergement" />
                </RouterLink>
            </EmptyStateStyled>
        );
    }

    return (
        <Page>
            <Header>
                <TitleAndSubtitle>
                    <TitleWrapper>
                        <TitleIcon icon={icon({ name: 'cloud', style: 'solid' })} />
                        <Text variant="headingM" fontWeight="--fw-bold">
                            <FormattedMessage defaultMessage="Hébergement" />
                        </Text>
                    </TitleWrapper>
                    <Text color="--neutral500">
                        <FormattedMessage defaultMessage="Gestion du flux RSS de votre émission et de l'activation de l'hébergement sur Ausha ou non." />
                    </Text>
                </TitleAndSubtitle>
                <Divider />
                <Status />
            </Header>
            <Stack $gap="1rem">
                <Text fontWeight="--fw-semibold" color="--neutral500">
                    <FormattedMessage defaultMessage="Paramètres du flux RSS" />
                </Text>
                <Container>
                    <Settings />
                </Container>
            </Stack>
        </Page>
    );
};

const Container = styled.div`
    padding: var(--container-padding-mobile);
    background-color: var(--white);
    border-radius: var(--r-l);

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        padding: var(--container-padding);
    }
`;
const Header = styled(Container)`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`;
const TitleIcon = styled(FontAwesomeIcon)`
    width: 1.25rem;
    height: 1.25rem;
`;
const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
`;
const Divider = styled.div`
    height: 1px;
    background-color: var(--neutral100);
    border-radius: var(--r-full);
`;
const TitleAndSubtitle = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.75rem;
`;
const Page = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`;
const EmptyStateButtonIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const HeadIllustrationWrapper = styled.div`
    width: 200px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    & > * {
        text-align: center;
    }
`;
const EarthIllustration = styled(Earth)`
    max-width: max-content;
`;
const EmptyStateStyled = styled(EmptyState)`
    .emptyState__illustration {
        width: 9.92rem;
    }
`;

export default Hosting;
