import { useQuery } from '@tanstack/react-query';
import { fetchGeoStats } from '@/api';
import dayjs from 'dayjs';
import TARGET_COUNTRIES_LOOKUP from '../../../../../public/geojson/countries.json';

export const useCountryGeoStats = ({
    showId,
    start,
    end,
    episodes,
    campaign,
    currentLayerLevel,
}) => {
    return useQuery({
        queryKey: ['stats', 'geostats', 'countries', showId, start, end, episodes, campaign],
        queryFn: async () => {
            const { data } = await fetchGeoStats(showId, {
                /**
                 * Test values
                 * start: dayjs('12-25-2020', 'MM-DD-YYYY').format('YYYY-MM-DD'),
                 * end: dayjs('03-29-2023', 'MM-DD-YYYY').format('YYYY-MM-DD'),
                 */
                start: dayjs(start).format('YYYY-MM-DD'),
                end: dayjs(end).format('YYYY-MM-DD'),
                podcasts: episodes,
                campaign,
                group_by: 'country',
            });

            if (!data) return [];

            const totalDownloads =
                data.reduce((acc, country) => acc + parseInt(country.downloads, 10), 0) || 0;

            return data.map((country) => ({
                ...country,
                weight: country.weight || 0,
                percentage: ((country.downloads / totalDownloads) * 100).toFixed(2),
                entity: {
                    id: country.code,
                    label: TARGET_COUNTRIES_LOOKUP[country.code]?.label || 'Unknown',
                },
                coordinates: [
                    TARGET_COUNTRIES_LOOKUP[country.code]?.lng || 0,
                    TARGET_COUNTRIES_LOOKUP[country.code]?.lat || 0,
                ],
            }));
        },
        enabled: currentLayerLevel === 'countries',
    });
};
