import { useController } from 'react-hook-form';
import Toggle from './Toggle';

export const ToggleController = (props) => {
    const { control, name, onChange, onBlur, children, ...rest } = props;
    const { field, fieldState } = useController({
        name,
        control,
    });

    return (
        <Toggle
            onChange={(isSelected) => {
                onChange && onChange(isSelected);
                field.onChange(isSelected);
            }}
            onBlur={(...v) => {
                onBlur && onBlur(...v);
                field.onBlur(...v);
            }}
            isSelected={field.value}
            name={field.name}
            inputState={fieldState}
            // ref={field.ref}
            {...rest}
        >
            {children}
        </Toggle>
    );
};
