import { FormattedMessage } from 'react-intl';
import Text from '@ui/atoms/Text';
import Button from '@/components/Button';
import IconInfo from '@ui/icons/IconInfo';
import Alert from '@ui/atoms/Alert';
import YoutubeBroadcastSettings from './YoutubeBroadcastSettings';
import useYoutubeQuotaQuery from '@queries/quota/useYoutubeQuotaQuery.hook';
import useYoutubeProviderQuery from '@queries/provider/useYoutubeProviderQuery.hook';
import { useParams, useHistory } from 'react-router';
import useUnlinkProviderMutation from '@queries/provider/useUnlinkProviderMutation.hook';
import { YOUTUBE } from '@/shared/config/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import RouterLink from '@/components/Link/RouterLink';
import InputText from '@ui/atoms/InputText';
import ExternalLink from '@/components/Link/ExternalLink';
import styled from 'styled-components';
import useShowQuery from '@queries/show/useShowQuery.hook';

interface YoutubeBroadcastSettingProps {
    logo: string | null;
}

const YoutubeBroadcastSetting = ({ logo }: YoutubeBroadcastSettingProps) => {
    const history = useHistory();
    const { showId } = useParams<{ showId: string }>();
    const { data: show } = useShowQuery(showId);
    const { data: youtubeQuota } = useYoutubeQuotaQuery();
    const { data: youtubeAccount } = useYoutubeProviderQuery({
        showId,
    });
    const unlinkYoutubeAccount = useUnlinkProviderMutation();
    const isShowPmp = show?.hostedOnAusha === false;

    if (!youtubeAccount) {
        return null;
    }

    return (
        <YoutubeBroadcastSettingsWrapper>
            <Header>
                <BackLink
                    to={
                        isShowPmp
                            ? `/app/show/${showId}/settings/broadcast/non-hosting`
                            : `/app/show/${showId}/settings/broadcast`
                    }
                    variant="button-tertiary"
                >
                    <FontAwesomeIcon icon={icon({ name: 'arrow-left', style: 'solid' })} />
                </BackLink>
                <Title>YouTube</Title>
            </Header>
            <SettingsWrapper>
                <PlatformWrapper>
                    {logo && <PlatformIcon src={logo} alt="YouTube" />}
                    <PlatformName>YouTube</PlatformName>
                </PlatformWrapper>

                <AccountStatusWrapper>
                    <FormattedMessage defaultMessage="Connecté en tant que" />
                    <AccountInfo>
                        {youtubeAccount.avatar && <Avatar src={youtubeAccount.avatar} />}
                        {youtubeAccount.name}
                        <UnlinkAccountButton
                            variant="ghost"
                            onPress={() => {
                                unlinkYoutubeAccount.mutate(
                                    {
                                        showId,
                                        provider: YOUTUBE,
                                    },
                                    {
                                        onSuccess: () => {
                                            isShowPmp
                                                ? history.push(
                                                      `/app/show/${showId}/settings/broadcast/non-hosting`,
                                                  )
                                                : history.push(
                                                      `/app/show/${showId}/settings/broadcast`,
                                                  );
                                        },
                                    },
                                );
                            }}
                        >
                            <FormattedMessage defaultMessage="Délier le compte" />
                        </UnlinkAccountButton>
                    </AccountInfo>
                </AccountStatusWrapper>

                <ChannelLinkInputWrapper>
                    <InputText
                        value={youtubeAccount.channelUrl}
                        label={<FormattedMessage defaultMessage="Lien de votre chaîne YouTube" />}
                        isReadOnly
                        copyToClipboardEnabled
                    />
                    <ChannelLinkActions>
                        {youtubeAccount.channelUrl && (
                            <ExternalLink
                                href={youtubeAccount.channelUrl}
                                target="_blank"
                                variant="button-secondary"
                                startIcon={
                                    <FontAwesomeIcon
                                        icon={icon({
                                            name: 'up-right-from-square',
                                            style: 'solid',
                                        })}
                                    />
                                }
                            >
                                <FormattedMessage defaultMessage="Ouvrir le lien" />
                            </ExternalLink>
                        )}
                    </ChannelLinkActions>
                </ChannelLinkInputWrapper>
                {youtubeQuota?.maximum && (
                    <Alert
                        variant="info"
                        icon={<IconInfo />}
                        description={
                            <Text color="var(--neutral500)" variant="footnote">
                                <FormattedMessage
                                    defaultMessage="Vos épisodes sont convertis en vidéos mp4 diffusées sur YouTube. La diffusion sur YouTube s’active via le formulaire d’ajout d’épisode, dans la limite de {maximum} par jour. Pour les épisodes de plus de 15 min, {activate}."
                                    values={{
                                        maximum: youtubeQuota.maximum,
                                        activate: (
                                            <AlertExternalLink
                                                href="https://www.youtube.com/verify"
                                                target="_blank"
                                            >
                                                <FormattedMessage defaultMessage="veuillez activer votre compte YouTube" />
                                            </AlertExternalLink>
                                        ),
                                    }}
                                />
                            </Text>
                        }
                    >
                        <Text fontWeight="--fw-semibold">
                            <FormattedMessage defaultMessage="À propos de la diffusion sur Youtube" />
                        </Text>
                    </Alert>
                )}
                <YoutubeBroadcastSettings />
            </SettingsWrapper>
        </YoutubeBroadcastSettingsWrapper>
    );
};

const YoutubeBroadcastSettingsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;
const Header = styled.div`
    display: flex;
    align-items: center;
    gap: 0.75rem;
`;
const BackLink = styled(RouterLink)`
    border-radius: var(--r-full);
    background-color: var(--white);
`;
const Title = styled.h1``;
const SettingsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: 2rem;
`;
const AccountStatusWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
`;
const AccountInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;
const Avatar = styled.img`
    width: 1.5rem;
    border-radius: var(--r-full);
`;
const UnlinkAccountButton = styled(Button)`
    padding: 0;
`;
const PlatformWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.75rem;
`;
const PlatformIcon = styled.img`
    width: 1.5rem;
    height: 1.5rem;
`;
const PlatformName = styled.h2`
    font-weight: var(--fw-semibold);
    font-size: var(--fs-body-l);
`;
const ChannelLinkInputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;
const ChannelLinkActions = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
`;
const AlertExternalLink = styled(ExternalLink)`
    font-size: var(--fs-body-s);
    text-decoration: underline;
    font-weight: inherit;
    color: inherit;

    &:hover {
        color: inherit;
    }
`;

export default YoutubeBroadcastSetting;
