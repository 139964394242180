import { request } from '../api';

const basePath = process.env.API_CONTENT;

/* -- stats (campaign) -- */
export const fetchCampaignStats = (campaign, query) => {
    return request({ method: 'GET', basePath, target: `/v1/stats/campaigns/${campaign}`, query });
};
export const fetchCampaignStatsCsv = (campaign, query) => {
    return request({
        method: 'GET',
        basePath,
        target: `/v1/stats/campaigns/${campaign}/toCSV`,
        query,
    });
};
export const fetchCampaignSourcesCsv = (campaign, query) => {
    return request({
        method: 'GET',
        basePath,
        target: `/v1/stats/campaigns/${campaign}/sources/categories/toCSV`,
        query,
    });
};
export const fetchCampaignSourcePlatforms = (campaign, query) => {
    return request({
        method: 'GET',
        basePath,
        target: `/v1/stats/campaigns/${campaign}/sources/categories`,
        query,
    });
};
export const fetchCampaignSourceDevices = (campaign, query) => {
    return request({
        method: 'GET',
        basePath,
        target: `/v1/stats/campaigns/${campaign}/sources/devices`,
        query,
    });
};
export const fetchCampaignSourceOS = (campaign, query) => {
    return request({
        method: 'GET',
        basePath,
        target: `/v1/stats/campaigns/${campaign}/sources/os`,
        query,
    });
};
export const fetchCampaignSourceBrowsersLeaderboard = (show, query) => {
    return request({
        method: 'GET',
        basePath,
        target: `/v1/stats/shows/${show}/sources/browsers/top`,
        query,
    });
};
export const fetchCampaignSourceBrowsers = (show, query) => {
    return request({
        method: 'GET',
        basePath,
        target: `/v1/stats/shows/${show}/sources/browsers`,
        query,
    });
};
export const fetchEpisodesRankingFromCampaign = (campaign, query) => {
    return request({
        method: 'GET',
        basePath,
        target: `/v1/stats/campaigns/${campaign}/podcastsFromCampaign`,
        query,
    });
};
export const fetchEpisodesTopRankingFromCampaign = (campaign, query) => {
    return request({
        method: 'GET',
        basePath,
        target: `/v1/stats/campaigns/${campaign}/podcastsTopFromCampaign`,
        query,
    });
};

/* -- stats (podcast) -- */
export const fetchEpisodesRankingFromShow = (show, query) => {
    return request({
        method: 'GET',
        basePath,
        target: `/v1/stats/shows/${show}/podcastsFromShow`,
        query,
    });
};
export const fetchEpisodesTopRankingFromShow = (show, query) => {
    return request({
        method: 'GET',
        basePath,
        target: `/v1/stats/shows/${show}/podcastsTopFromShow`,
        query,
    });
};
export const fetchSourceDevices = (show, query) => {
    return request({
        method: 'GET',
        basePath,
        target: `/v1/stats/shows/${show}/sources/devices`,
        query,
    });
};
export const fetchEpisodesCompare = (show, query) => {
    return request({ method: 'GET', basePath, target: `/v1/stats/shows/${show}/compare`, query });
};

/* -- stats (show) -- */
export const fetchShowSourcePlatformsLeaderboard = (show, query) => {
    return request({
        method: 'GET',
        basePath,
        target: `/v1/stats/shows/${show}/sources/categories/top`,
        query,
    });
};
export const fetchShowStats = (show, query) => {
    return request({ method: 'GET', basePath, target: `/v1/stats/shows/${show}`, query });
};
export const fetchShowOverallStats = (show, query) => {
    return request({ method: 'GET', basePath, target: `/v1/stats/shows/${show}/overall`, query });
};
export const fetchShowStatsCsv = (show, query) => {
    return request({ method: 'GET', basePath, target: `/v1/stats/shows/${show}/toCSV`, query });
};
export const fetchShowSourcePlatforms = (show, query) => {
    return request({
        method: 'GET',
        basePath,
        target: `/v1/stats/shows/${show}/sources/categories`,
        query,
    });
};
export const fetchShowSourcesCsv = (show, query) => {
    return request({
        method: 'GET',
        basePath,
        target: `/v1/stats/shows/${show}/sources/categories/toCSV`,
        query,
    });
};
export const fetchShowSourceOS = (show, query) => {
    return request({
        method: 'GET',
        basePath,
        target: `/v1/stats/shows/${show}/sources/os`,
        query,
    });
};
export const fetchShowSourceBrowsersLeaderboard = (show, query) => {
    return request({
        method: 'GET',
        basePath,
        target: `/v1/stats/shows/${show}/sources/browsers/top`,
        query,
    });
};
export const fetchShowSourceBrowsers = (show, query) => {
    return request({
        method: 'GET',
        basePath,
        target: `/v1/stats/shows/${show}/sources/browsers`,
        query,
    });
};
export const fetchBrowsersExportCsv = (show, query) => {
    return request({
        method: 'GET',
        basePath,
        target: `/v1/stats/shows/${show}/sources/browsers/toCSV`,
        query,
    });
};
export const fetchPeakTime = (show, query) => {
    return request({
        method: 'GET',
        basePath,
        target: `/v1/stats/shows/${show}/launchtime`,
        query,
    });
};
export const fetchPeakTimeBest = (show, query) => {
    return request({
        method: 'GET',
        basePath,
        target: `/v1/stats/shows/${show}/launchtime/best`,
        query,
    });
};
export const fetchPeakTimePopularDays = (show, query) => {
    return request({
        method: 'GET',
        basePath,
        target: `/v1/stats/shows/${show}/launchtime/perDay`,
        query,
    });
};
export const fetchPeakTimePopularHours = (show, query) => {
    return request({
        method: 'GET',
        basePath,
        target: `/v1/stats/shows/${show}/launchtime/perHour`,
        query,
    });
};
export const fetchShowsSingleListener = (show, query) => {
    return request({ method: 'GET', basePath, target: `/v1/stats/shows/${show}/listeners`, query });
};
export const fetchShowsSingleListenerCsv = (show, query) => {
    return request({
        method: 'GET',
        basePath,
        target: `/v1/stats/shows/${show}/listeners/toCSV`,
        query,
    });
};

/* -- stats (apple podcasts) -- */
export const fetchApplePodcastsAvailableCategories = (show) => {
    return request({ method: 'GET', basePath, target: `/v1/stats/shows/${show}/apple/categories` });
};
export const fetchApplePodcastsRankingOverall = (show, category, timezone) => {
    return request({
        method: 'GET',
        basePath,
        target: `/v1/stats/shows/${show}/apple/overall`,
        query: { category_id: category, timezone },
    });
};
export const fetchApplePodcastsRankingStats = (show, category, query) => {
    return request({
        method: 'GET',
        basePath,
        target: `/v1/stats/shows/${show}/apple`,
        query: { category_id: category, ...query },
    });
};
export const fetchApplePodcastsReviews = (show, page) => {
    return request({
        method: 'GET',
        basePath,
        target: `/v1/stats/shows/${show}/apple/reviews`,
        query: { page },
    });
};
export const fetchApplePodcastsReviewsNotification = (show) => {
    return request({
        method: 'GET',
        basePath,
        target: `/v1/stats/shows/${show}/apple/reviews/notifications`,
    });
};
export const updateApplePodcastsReviewsNotification = (show, body) => {
    return request({
        method: 'PUT',
        basePath,
        target: `/v1/stats/shows/${show}/apple/reviews/notifications`,
        body,
    });
};
export const fetchApplePodcastsStatsCsv = (show, category, query) => {
    return request({
        method: 'GET',
        basePath,
        target: `/v1/stats/shows/${show}/apple/toCSV`,
        query: { category_id: category, ...query },
    });
};
export const fetchGendersStats = (show, query) => {
    return request({ method: 'GET', basePath, target: `/v1/stats/shows/${show}/genders`, query });
};
export const fetchAgesStats = (show, query) => {
    return request({ method: 'GET', basePath, target: `/v1/stats/shows/${show}/ages`, query });
};
export const fetchAgesExportCsv = (show, query) => {
    return request({
        method: 'GET',
        basePath,
        target: `/v1/stats/shows/${show}/ages/toCSV`,
        query,
    });
};

/* -- geostats -- */
export const fetchGeoStats = (show, query) => {
    return request({
        method: 'GET',
        basePath,
        target: `/v1/stats/shows/${show}/geo_stats`,
        query: { ...query },
    });
};
