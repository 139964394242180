import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { withTheme } from 'styled-components';
import { useResponsive } from '@/shared/hooks/useResponsive';
import ApplePodcastsRankingExportButton from './ApplePodcastsRankingExportButton';
import Desktop from '@ui/molecules/Desktop';
import FetcherApplePodcastsRankingStats from './FetcherApplePodcastsRankingStats';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import RankingVariationGraphHeader from './RankingVariationGraphHeader';
import RankingVariationGraphPlotLine from '@/components/unorganized/RankingVariationGraphPlotLine';
import RankingVariationGraphTable from './RankingVariationGraphTable';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import DeprecatedWhitePaper from '@ui/atoms/DeprecatedWhitePaper';
import { compose } from '@/shared/utils/compose';
import { connect } from '@/components/legacy/connect';
import { extractCssVariable } from '@/shared/utils/cssVariable';
import styled from 'styled-components';
import { useStatsDateSelectorContext } from '@/context/StatsDateSelectorContext';
import { useParams } from 'react-router';

const RankingVariationGraphLegend = ({ step, setStep }) => (
    <DeprecatedPaper flex align="center" justify="space-around" wrap="wrap" px={35}>
        <DeprecatedPaper flex align="center" auto w="auto" mt={35}>
            <DeprecatedPaper
                background={extractCssVariable('--primary')}
                height={10}
                mr={10}
                rounded
                w={10}
                minW={10}
            />
            <DeprecatedText auto>
                <FormattedMessage defaultMessage="Classement moyen" />
            </DeprecatedText>
        </DeprecatedPaper>
        <Desktop>
            <DeprecatedPaper flex w="auto" mt={35}>
                <DeprecatedText
                    onClick={() => setStep('days')}
                    color={step === 'days' ? '--black' : '--neutral500'}
                    mr={25}
                >
                    <FormattedMessage defaultMessage="Jour" />
                </DeprecatedText>
                <DeprecatedText
                    onClick={() => setStep('weeks')}
                    color={step === 'weeks' ? '--black' : '--neutral500'}
                    mr={25}
                >
                    <FormattedMessage defaultMessage="Semaine" />
                </DeprecatedText>
                <DeprecatedText
                    onClick={() => setStep('months')}
                    color={step === 'months' ? '--black' : '--neutral500'}
                    mr={25}
                >
                    <FormattedMessage defaultMessage="Mois" />
                </DeprecatedText>
                <DeprecatedText
                    onClick={() => setStep('years')}
                    color={step === 'years' ? '--black' : '--neutral500'}
                >
                    <FormattedMessage defaultMessage="Année" />
                </DeprecatedText>
            </DeprecatedPaper>
        </Desktop>
    </DeprecatedPaper>
);

const enhance = compose(
    withTheme,
    connect(({ applePodcastsStatsStore }) => ({
        categoryId: applePodcastsStatsStore.applePodcastsCategoryId,
        overall: applePodcastsStatsStore.applePodcastsRankingOverall,
    })),
);

function ApplePodcastsRanking({ isLoading, categoryId, overall, theme }) {
    const { showId } = useParams();
    const { startDate, endDate } = useStatsDateSelectorContext();
    const { isDesktop } = useResponsive();
    const [step, setStep] = useState('days');
    const [hoveredValue, setHoveredValue] = useState(null);
    const [scrollToValue, setScrollToValue] = useState(null);
    // Determine if the show have already been in the ranking of the category
    const noBestRankingData = overall && overall.bestRanking === null;
    const bestPositionNeverInRanking =
        overall && overall.bestRanking && overall.bestRanking.ranking === null;
    const neverInRanking = !!(noBestRankingData || bestPositionNeverInRanking);

    function scroll(value) {
        setScrollToValue(value);
        setHoveredValue(value);
    }

    if (isLoading) {
        return (
            <>
                <DeprecatedWhitePaper flex column pb={35} mb={30}>
                    <RankingVariationGraphHeader isLoading={isLoading} />
                    <RankingVariationGraphLegend step={step} setStep={setStep} theme={theme} />
                    <RankingVariationGraphPlotLine isLoading />
                </DeprecatedWhitePaper>
                <RankingVariationGraphTable isLoading />
            </>
        );
    }

    if (categoryId === undefined) {
        return null;
    }

    return (
        <FetcherApplePodcastsRankingStats
            categoryId={categoryId}
            end={endDate}
            showId={showId}
            start={startDate}
            step={isDesktop ? step : 'auto'}
        >
            {(isLoadingRankingStats) => (
                <>
                    <ApplePodcastsRankingWrapper>
                        <RankingVariationGraphHeader />
                        {!neverInRanking && (
                            <RankingVariationGraphLegend
                                step={step}
                                setStep={setStep}
                                theme={theme}
                            />
                        )}
                        <RankingVariationGraphPlotLine
                            categoryId={categoryId}
                            onHover={scroll}
                            isLoading={isLoadingRankingStats}
                        />
                    </ApplePodcastsRankingWrapper>
                    <Desktop>
                        <RankingVariationGraphTable
                            hoveredValue={hoveredValue}
                            isLoading={isLoadingRankingStats}
                            onHover={(data) => setHoveredValue(data)}
                            scrollToValue={scrollToValue}
                        />
                        <ApplePodcastsRankingExportButton step={step} />
                    </Desktop>
                </>
            )}
        </FetcherApplePodcastsRankingStats>
    );
}

const ApplePodcastsRankingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding);
    margin-block: 2rem;
    gap: 1rem ${({ theme }) => theme.mediaQueries.mobile} {
        padding: var(--container-padding-mobile);
    }
`;

ApplePodcastsRanking.defaultProps = {
    isLoading: false,
};

export default enhance(ApplePodcastsRanking);
