import { useMutation, useQueryClient } from '@tanstack/react-query';
import { resignSubscription } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import subscriptionKeys from './subscriptionKeys';

export interface MutationFnProps {
    payload: {
        password: string;
    };
}

const mutationFn = async ({ payload }: MutationFnProps) => {
    const { data } = await resignSubscription(payload);
    return camelcaseKeys(data, { deep: true });
};

const useResignSubscription = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: (_) => {
            queryClient.invalidateQueries({
                queryKey: subscriptionKeys.all(),
            });
        },
    });
};

export default useResignSubscription;
