import type { Platform, Mode } from '@/api/pso/types';
import { Geolocalization } from '../user/useUserGeolocalizationQuery.hook';

const psoKeys = {
    all: () => [{ entity: 'pso' }],
    allDetails: () => [{ ...psoKeys.all()[0], scope: 'detail' }],
    detail: () => [{ ...psoKeys.allDetails()[0] }],
    globalConfiguration: (showId: string) => [
        { ...psoKeys.all()[0], context: 'globalConfiguration', showId },
    ],
    statsOverview: (showId: string, currentStore: string, doesExport: boolean) => [
        { ...psoKeys.all()[0], context: 'statsOverview', showId, currentStore, doesExport },
    ],
    keywordQuota: (showId: string) => [{ ...psoKeys.all()[0], context: 'keywordQuota', showId }],
    list: () => [{ ...psoKeys.all()[0], scope: 'list' }],
    indexedKeywordsList: (showId: string) => [
        { ...psoKeys.list()[0], context: 'indexedKeywords', showId },
    ],
    keywordSuggestions: (showId: string) => [
        { ...psoKeys.list()[0], context: 'keywordSuggestions', showId },
    ],
    liveSearch: (
        showId: string,
        keyword: string,
        platform: Platform,
        store: string,
        mode: Mode,
        format?: string,
    ) => [
        {
            ...psoKeys.list()[0],
            context: 'liveSearch',
            showId,
            keyword,
            platform,
            store,
            mode,
            format,
        },
    ],
    liveSearchKeywordSuggestions: (
        showId: string,
        search: string,
        store: string,
        userGeolocalization?: Geolocalization,
    ) => [
        {
            ...psoKeys.list()[0],
            context: 'liveSearchKeywordSuggestions',
            showId,
            search,
            store,
            userGeolocalization,
        },
    ],
    availableStores: () => [{ ...psoKeys.all()[0], scope: 'availableStores' }],
};

export default psoKeys;
