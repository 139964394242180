import ContentLoader from 'react-content-loader';
import { FormattedMessage } from 'react-intl';
import ApplePodcastsCategorySelector from './ApplePodcastsCategorySelector';
import createFallback from '@/components/unorganized/createFallback';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import { useParams } from 'react-router';
import useShowQuery from '@queries/show/useShowQuery.hook';
import { APPLE_PODCASTS } from '@/shared/config/constants';
import styled from 'styled-components';
const ApplePodcastsCategorySelectorFallback = createFallback((props) => (
    <DeprecatedPaper flex mt={5}>
        <ContentLoader width={100} height={20} style={{ width: 100, height: 20 }} {...props}>
            <rect x="0" y="0" rx="5" ry="5" width="100" height="14" />
        </ContentLoader>
    </DeprecatedPaper>
));

const ApplePodcastsHeader = () => {
    const { showId } = useParams();
    const { data: show } = useShowQuery(showId);
    const name = show && show.name;

    const hasApplePodcastsListeningLink = show?.listeningLinks?.data?.some(
        (link) => link.key === APPLE_PODCASTS && link.url,
    );

    return (
        <>
            {!name ? (
                <ApplePodcastsCategorySelectorFallback />
            ) : (
                <NameAndCategorySelector>
                    <span>{name}</span>
                    {hasApplePodcastsListeningLink && (
                        <>
                            <span>-</span>
                            <ApplePodcastsCategorySelector />
                        </>
                    )}
                </NameAndCategorySelector>
            )}
            <DeprecatedText color="--neutral500" mt={10} mb={25}>
                <FormattedMessage defaultMessage="Les statistiques iTunes concernent uniquement les podcasts présents au moins une fois dans le classement Apple Podcasts, qui est limité à 200 podcasts par catégorie." />
            </DeprecatedText>
        </>
    );
};

const NameAndCategorySelector = styled.div`
    display: flex;
    align-items: flex-end;
    gap: 0.5rem;
`;

export default ApplePodcastsHeader;
