import { USER_OPTIONS } from '@/shared/config/userOptions';
import noCTAMainIllustration from '@public/images/empty-state/illustration/calls_to_action.png';
import Text from '@ui/atoms/Text';
import ZebraIllustration from '@ui/atoms/ZebraIllustration';
import { FormattedMessage } from 'react-intl';
import EmptyState from '@ui/molecules/EmptyState';
import ShiningDiamond from '@ui/atoms/ShiningDiamond';
import IconRocket from '@ui/icons/IconRocket';
import styled from 'styled-components';
import { useAmplitudeLogEvent } from '@/shared/hooks/useAmplitude';
import RouterLink from '@/components/Link/RouterLink';
import useUserQuery from '@queries/user/useUserQuery.hook';
import CallToAction from '@/components/CallToAction';

const CallToActionPage = () => {
    useAmplitudeLogEvent('Communication Settings Accessed');
    const user = useUserQuery();
    const hasAccessToCTA = user.data?.options?.includes(USER_OPTIONS.CTA_BUTTONS);

    if (!hasAccessToCTA) {
        return (
            <EmptyState
                illustration={noCTAMainIllustration}
                illustrationType="image"
                illustrationAlign="right"
            >
                <HeadIllustrationWrapper>
                    <ZebraIllustration>
                        <ShiningDiamond />
                    </ZebraIllustration>
                </HeadIllustrationWrapper>
                <LabelWrapper>
                    <Text variant="heading" fontWeight="--fw-bold">
                        <FormattedMessage defaultMessage="Passez à l'offre supérieure !" />
                    </Text>
                    <Text variant="body" fontWeight="--fw-normal" color="--neutral500">
                        <FormattedMessage defaultMessage="Pour débloquer la création de boutons personnalisés, abonnez-vous à l'offre Supersonic 🚀" />
                    </Text>
                </LabelWrapper>
                <RouterLink
                    startIcon={<IconRocket />}
                    variant="button-primary"
                    to="/app/subscription/offers"
                >
                    <FormattedMessage defaultMessage="Mettre à niveau mon offre" />
                </RouterLink>
            </EmptyState>
        );
    }

    return <CallToAction />;
};

const HeadIllustrationWrapper = styled.div`
    width: 200px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    & > * {
        text-align: center;
    }
`;

export default CallToActionPage;
