import { cloneElement } from 'react';
import PropTypes from 'prop-types';

const DeprecatedAccordion = ({ index, onChange, children, progress, number, isStartTutorial }) => {
    const onClick = (i, ...args) => {
        const onClick = children[i].props.onClick;
        if (onClick) onClick(...args);
        if (index !== i) onChange(i);
    };

    return children.map((child, i, childrenArray) => {
        const childIsActive = childrenArray[i + 1]?.props?.activeTab === undefined;
        const tabIsActive = child?.props?.activeTab === undefined;

        let propsToInject = {
            ...child.props,
            onClick: () => onClick(i),
            active: child?.props?.alwaysOpen || index === i,
            activeTab: tabIsActive || child?.props?.activeTab,
            activeButton: childIsActive || childrenArray[i + 1]?.props?.activeTab,
            zIndex: index === i || (child?.props?.alwaysOpen && index === i) ? 2 : 0,
            isStartTutorial,
            activeIndex: index,
            index: i,
        };

        if (progress) propsToInject = { ...propsToInject, checked: index > i };
        if (number) propsToInject = { ...propsToInject, number: i + 1 };

        if (!child) return null;
        return cloneElement(child, propsToInject, [child]);
    });
};

DeprecatedAccordion.PropTypes = {
    index: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    children: PropTypes.arrayOf(PropTypes.element).isRequired,
    progress: PropTypes.bool,
    number: PropTypes.bool,
    isStartTutorial: PropTypes.bool,
};

DeprecatedAccordion.defaultProps = {
    index: 0,
    progress: false,
    number: false,
};

export default DeprecatedAccordion;
