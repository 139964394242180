import { motion } from 'framer-motion';
import styled from 'styled-components';
import DeprecatedText from '../../atoms/DeprecatedText';

const TabStyled = styled(DeprecatedText)`
    &:hover {
        text-decoration: none;
    }
`;

const spring = {
    type: 'spring',
    stiffness: 500,
    damping: 30,
};

const WrapperTab = ({ as, to, isSelected, onClick, children, disabled }) => (
    <TabStyled
        justify="center"
        onClick={onClick}
        pos="relative"
        weight="semibold"
        z={1}
        h="100%"
        flex
        align="center"
        px={12}
        w="max-content"
        as={as}
        to={to}
        disabled={disabled}
    >
        {children}
        {isSelected && (
            <motion.div
                style={{
                    borderRadius: 3,
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: -1,
                    position: 'absolute',
                    height: '100%',
                    background: '#ffffff',
                }}
                layoutId="itemTab"
                initial
                transition={spring}
            />
        )}
    </TabStyled>
);

const AdvancedTab = (props) => {
    const { simpleSwitch, as, to, isSelected, onClick, children, disabled } = props;
    if (simpleSwitch)
        return (
            <WrapperTab isSelected={isSelected} onClick={onClick} disabled={disabled}>
                {children}
            </WrapperTab>
        );
    return (
        <WrapperTab as={as} to={to} isSelected={isSelected} onClick={onClick} disabled={disabled}>
            {children}
        </WrapperTab>
    );
};

const Tab = AdvancedTab;

const TabItem = ({
    children,
    as,
    to,
    onClick,
    isSelected,
    simpleSwitch = false,
    disabled,
    tabsLocked,
}) => (
    <Tab
        as={as}
        to={to}
        isSelected={isSelected}
        onClick={onClick}
        simpleSwitch={simpleSwitch}
        disabled={tabsLocked && disabled}
    >
        {children}
    </Tab>
);
export default TabItem;
