import styled from 'styled-components';

const PostDetailsEmptyState = () => {
    return (
        <PostDetailsEmptyStateWrapper>
            <TitleWrapper>
                <Cover />
                <TitleRight>
                    <Title />
                    <SubTitle />
                </TitleRight>
            </TitleWrapper>
            <DataPoint>
                <SmallLine />
                <MediumLine />
            </DataPoint>
            <DataPoint>
                <SmallLine />
                <BigLine />
            </DataPoint>
            <Stats>
                <Stat>
                    <Figure />
                    <Legend />
                </Stat>
                <Stat>
                    <Figure />
                    <Legend />
                </Stat>
                <Stat>
                    <Figure />
                    <Legend />
                </Stat>
                <Stat>
                    <Figure />
                    <Legend />
                </Stat>
            </Stats>
            <Separator />
            <Image />
            <Section>
                <MediumLine />
                <SectionContent>
                    <SmallLine />
                    <BigLine />
                </SectionContent>
            </Section>
            <Section>
                <MediumLine />
                <BigContentRow />
                <BigContentRow />
                <MediumContentRow />
            </Section>
        </PostDetailsEmptyStateWrapper>
    );
};

const PostDetailsEmptyStateWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;

    & * {
        border-radius: var(--r-full);
    }
`;
const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.75rem;
    width: 100%;
`;
const Cover = styled.div`
    width: 2.5rem;
    height: 2.5rem;
    background-color: var(--neutral100);
    border-radius: var(--r-m);
`;
const TitleRight = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;
const Title = styled.div`
    width: 100%;
    height: 0.75rem;
    background-color: var(--neutral100);
`;
const SubTitle = styled.div`
    width: 5rem;
    height: 0.75rem;
    background-color: var(--neutral50);
`;
const DataPoint = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;
const SmallLine = styled.div`
    width: 0.75rem;
    height: 0.75rem;
    background-color: var(--neutral50);
`;
const MediumLine = styled.div`
    width: 2.5rem;
    height: 0.5rem;
    background-color: var(--neutral100);
`;
const BigLine = styled.div`
    width: 5rem;
    height: 0.5rem;
    background-color: var(--neutral100);
`;
const Stats = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
`;
const Stat = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
`;
const Figure = styled.div`
    width: 1rem;
    height: 2px;
    background-color: var(--neutral100);
`;
const Legend = styled.div`
    width: 2.5rem;
    height: 0.5rem;
    background-color: var(--neutral50);
`;
const Separator = styled.div`
    width: 100%;
    height: 1px;
    background: var(--neutral100);
`;
const Image = styled.div`
    width: 100%;
    height: 7.5rem;
    background: var(--neutral50);
    border-radius: var(--r-m);
`;
const Section = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;
const SectionContent = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;
const BigContentRow = styled.div`
    width: 100%;
    height: 0.5rem;
    background-color: var(--neutral50);
`;
const MediumContentRow = styled.div`
    width: 5rem;
    height: 0.5rem;
    background-color: var(--neutral50);
`;

export default PostDetailsEmptyState;
