import { useEffect } from 'react';
import useClipTranscriptionProgressQuery from '@/queries/clip/useClipTranscriptionProgressQuery';
import useClipTranscriptionQuery from '@/queries/clip/useClipTranscriptionQuery';
import { useParams } from 'react-router';
import { useQueryClient } from '@tanstack/react-query';
import clipKeys from '@/queries/clip/clipKeys';
import Progress from './Progress';
import Form from './Form';
import Error from './Error';
import { Episode } from '@/queries/episode/useEpisodeQuery.hook';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

interface TranscriptionProps {
    episode: Episode;
}

const Transcription = ({ episode }: TranscriptionProps) => {
    const { clipId } = useParams<{ clipId: string }>();
    const { data: transcription } = useClipTranscriptionQuery(clipId);
    const { data: transcriptionProgress } = useClipTranscriptionProgressQuery({
        clipId: clipId,
        enabled: !!clipId,
    });
    const queryClient = useQueryClient();

    const isTranscriptionInProgress =
        transcriptionProgress?.isQueued || transcriptionProgress?.isExecuting;
    const isTranscriptionFailed = transcriptionProgress?.isFailed;

    useEffect(() => {
        if (transcriptionProgress?.isFinished) {
            queryClient.invalidateQueries({ queryKey: clipKeys.transcriptionByClipId(clipId) });
        }
    }, [transcriptionProgress]);

    if (isTranscriptionFailed) {
        return <Error />;
    }

    if (isTranscriptionInProgress) {
        return <Progress />;
    }

    if (transcription && transcriptionProgress?.isFinished) {
        return <Form episode={episode} transcription={transcription} />;
    }

    return (
        <SpinnerWrapper>
            <SpinnerIcon icon={icon({ name: 'spinner-third', style: 'solid' })} spin />
        </SpinnerWrapper>
    );
};

const SpinnerWrapper = styled.div`
    padding: var(--container-padding);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    inset: 0;
`;
const SpinnerIcon = styled(FontAwesomeIcon)`
    width: 4rem;
    height: 4rem;
    color: var(--neutral100);
`;

export default Transcription;
