import useEpisodeQuery from '@/queries/episode/useEpisodeQuery.hook';
import useClipFormatQuery from '@/queries/clipFormat/useClipFormatQuery';
import { CLIP_STATUS } from '@/shared/config/constants';
import type { ClipFormat as IClipFormat } from '@/queries/clipFormat/types';
import Skeleton from './Skeleton';
import Loading from './Loading';
import Pending from './Pending';
import Success from './Success';
import Error from './Error';

interface ClipFormatProps {
    clipFormat: IClipFormat;
}

const ClipFormat = ({ clipFormat }: ClipFormatProps) => {
    const episodeQuery = useEpisodeQuery({ episodeId: clipFormat.clip.episodeId });
    const clipFormatQuery = useClipFormatQuery(clipFormat.id, {
        enabled: clipFormat.state === CLIP_STATUS.LOADING,
        refetchInterval: (data) => {
            if (!data) return false;
            if (data.state !== CLIP_STATUS.LOADING) return false;
            return 5000;
        },
    });
    const state = clipFormatQuery.data?.state || clipFormat.state;
    const clipFormatData = clipFormatQuery.data || clipFormat;

    if (episodeQuery.isError) {
        return <Error clipFormat={clipFormatData} />;
    }

    if (episodeQuery.data) {
        if (state === CLIP_STATUS.LOADING) {
            return <Loading clipFormat={clipFormatData} episode={episodeQuery.data} />;
        }
        if (state === CLIP_STATUS.PENDING) {
            return <Pending clipFormat={clipFormatData} episode={episodeQuery.data} />;
        }
        if (state === CLIP_STATUS.DONE) {
            return <Success clipFormat={clipFormatData} episode={episodeQuery.data} />;
        }
        if (state === CLIP_STATUS.ERROR) {
            return <Error clipFormat={clipFormatData} episode={episodeQuery.data} />;
        }
    }

    return <Skeleton />;
};

export default ClipFormat;
