import { useIntl } from 'react-intl';
import { z } from 'zod';
import { FORM_VALIDATION_MESSAGE } from '@/shared/services/validationMessageService';
import { checkImgSize, experimentalCheckImgDimensions } from '@/helpers';

export const useProfileFormSchema = (locale = 'en') => {
    const intl = useIntl();
    const nameRegex = /^[a-zA-Z0-9-À-ÿ]+$/;
    const acceptedMimes = ['image/jpg', 'image/jpeg', 'image/png'];

    return z.object({
        firstname: z
            .string()
            .min(1, { message: intl.formatMessage(FORM_VALIDATION_MESSAGE.required) })
            .regex(nameRegex, {
                message: intl.formatMessage(FORM_VALIDATION_MESSAGE.noSpecialCharacters),
            }),
        lastname: z
            .string()
            .min(1, { message: intl.formatMessage(FORM_VALIDATION_MESSAGE.required) })
            .regex(nameRegex, {
                message: intl.formatMessage(FORM_VALIDATION_MESSAGE.noSpecialCharacters),
            }),
        language: z.string().min(1, intl.formatMessage(FORM_VALIDATION_MESSAGE.required)),
        country: z.string().min(1, intl.formatMessage(FORM_VALIDATION_MESSAGE.required)),
        timezone: z.string().min(1, intl.formatMessage(FORM_VALIDATION_MESSAGE.required)),
        avatar: z
            .union([
                z
                    .instanceof(File)
                    .refine((file: File) => acceptedMimes.includes(file.type), {
                        message: intl.formatMessage(FORM_VALIDATION_MESSAGE.acceptedMimes, {
                            acceptedMimes,
                        }) as string,
                    })
                    .refine((file: File) => checkImgSize(file, locale, 10), {
                        message: intl.formatMessage(FORM_VALIDATION_MESSAGE.imgMaxSize, {
                            max: 10,
                        }) as string,
                    })
                    .refine(async (file: File) => await experimentalCheckImgDimensions(file), {
                        message: intl.formatMessage(FORM_VALIDATION_MESSAGE.imgMinDimensions, {
                            minWidth: 400,
                            minHeight: 400,
                        }) as string,
                    }),
                z.string(),
            ])
            .optional()
            .nullable(),
    });
};

export type ProfileFormSchema = z.infer<ReturnType<typeof useProfileFormSchema>>;
