import Button from '@/components/Button';
import Modal from '@/components/ui/atoms/Modal';
import Text from '@/components/ui/atoms/Text';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

interface EpisodeAIOverwriteWarningModalProps {
    isOpen: boolean;
    onOpenChange: (open: boolean) => void;
    onAction: () => void;
    actionTestId: string;
    message: React.ReactNode;
}

const EpisodeAIOverwriteWarningModal = ({
    isOpen,
    onOpenChange,
    onAction,
    actionTestId,
    message,
}: EpisodeAIOverwriteWarningModalProps) => {
    const handleClose = () => onOpenChange(false);

    return (
        <ModalStyled
            data-testid="episode-ai-overwrite-modal"
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            size="small"
        >
            <IconWrapper>
                <WarningIcon icon={icon({ name: 'triangle-exclamation', style: 'solid' })} />
            </IconWrapper>
            <Text variant="headingS" fontWeight="--fw-bold" textAlign="center">
                {message}
            </Text>
            <Actions>
                <Button data-testid={actionTestId} onPress={onAction}>
                    <FormattedMessage defaultMessage="Générer" />
                </Button>
                <Button
                    data-testid="episode-ai-overwrite-close-button"
                    variant="link-secondary"
                    onPress={handleClose}
                >
                    <FormattedMessage defaultMessage="Annuler" />
                </Button>
            </Actions>
        </ModalStyled>
    );
};

const ModalStyled = styled(Modal)`
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1.5rem;
`;
const WarningIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
`;
const IconWrapper = styled.div`
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--warning50);
    color: var(--warning500);
    border-radius: var(--r-s);
`;
const Actions = styled.div`
    align-self: stretch;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    & > :last-child {
        align-self: center;
    }
`;

export default EpisodeAIOverwriteWarningModal;
