import { FormattedMessage } from 'react-intl';
import DeprecatedButton from '../../atoms/DeprecatedButton';
import DeprecatedText from '../../atoms/DeprecatedText';
import Img from '../../atoms/Img';
import styled from 'styled-components';

function ErrorMessage() {
    return (
        <ErrorMessageWrapper>
            <Img w={300} mb={30} src="https://media.giphy.com/media/Yv66XRlbWCuQw/giphy.gif" />
            <DeprecatedText mb={10}>
                <FormattedMessage defaultMessage="Une erreur est survenue..." />
            </DeprecatedText>
            <DeprecatedButton onClick={() => document.location.reload()}>
                <FormattedMessage defaultMessage="Rafraîchir la page" />
            </DeprecatedButton>
        </ErrorMessageWrapper>
    );
}

const ErrorMessageWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export default ErrorMessage;
