import { Table } from '@tanstack/react-table';
import styled from 'styled-components';
interface TableHeadProps {
    table: Table<any>;
}

const TableHead = ({ table }: TableHeadProps) => {
    return (
        <thead>
            {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                        const { column } = header;
                        return <Th key={header.id} data-column-id={column.id} />;
                    })}
                </tr>
            ))}
        </thead>
    );
};

const Th = styled.th`
    height: 0;
    padding: 0;
    background-color: red;
    width: 120px;
`;

export default TableHead;
