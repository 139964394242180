import styled from 'styled-components';
import { Link as ReactRouterLink } from 'react-router-dom';

// Wrapper around react-router's Link used to prevent passing undesired props to DOM
const Link = styled(ReactRouterLink).withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) =>
        ['replace'].includes(prop) || defaultValidatorFn(prop),
})``;

export default Link;
