import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';
import { TooltipTrigger } from 'react-aria-components';
import styled from 'styled-components';

interface KeywordCellProps {
    keyword: string;
    isLoading: boolean;
}

const KeywordCell = ({ keyword, isLoading }: KeywordCellProps) => {
    return (
        <TooltipTrigger delay={0} closeDelay={0}>
            <StyledTooltipTriggerWrapper>
                <KeywordCellWrapper>
                    {isLoading ? (
                        <Loading icon={icon({ name: 'spinner-third', style: 'solid' })} spin />
                    ) : null}
                    <Keyword>{keyword}</Keyword>
                </KeywordCellWrapper>
            </StyledTooltipTriggerWrapper>
            <Tooltip placement="top" passthrough={!isLoading}>
                <FormattedMessage defaultMessage="Les nouvelles données seront disponibles dans les prochaines 24h." />
            </Tooltip>
        </TooltipTrigger>
    );
};

const KeywordCellWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
`;
const Loading = styled(FontAwesomeIcon)`
    color: var(--neutral500);
    font-size: 0.75rem;
`;
const Keyword = styled.span`
    font-weight: var(--fw-semibold);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
    max-width: 100%;
`;
const StyledTooltipTriggerWrapper = styled(TooltipTriggerWrapper)`
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
`;

export default KeywordCell;
