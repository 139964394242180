import useShowQuery from '@/queries/show/useShowQuery.hook';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import {
    LISTENING_PLATFORMS_NAMES,
    BROADCAST_PLATFORMS,
    BROADCAST_PLATFORMS_EN,
    PMP_BROADCAST_PLATFORMS,
    ListeningPlatform,
} from '@/utils/constants';
import Button from '@/components/Button';
import PlatformIcon from '@app/atoms/PlatformIcon';
import styled, { css } from 'styled-components';

interface SelectBroadcastPlatformsStepProps {
    selectedPlatforms: ListeningPlatform[];
    setSelectedPlatforms: (platforms: ListeningPlatform[]) => void;
    onNextStep: () => void;
    onClose: () => void;
}

const SelectBroadcastPlatformsStep = ({
    selectedPlatforms,
    setSelectedPlatforms,
    onNextStep,
    onClose,
}: SelectBroadcastPlatformsStepProps) => {
    const { showId } = useParams<{ showId: string }>();
    const { data: show } = useShowQuery(showId);
    const isEnglish = show?.languageId === 40;
    const isShowPmp = show?.hostedOnAusha === false;

    const platforms = isShowPmp
        ? Array.from(new Set(PMP_BROADCAST_PLATFORMS))
        : isEnglish
        ? Array.from(new Set([...BROADCAST_PLATFORMS, ...BROADCAST_PLATFORMS_EN]))
        : Array.from(new Set(BROADCAST_PLATFORMS));

    const onSelectPlatform = (platform: ListeningPlatform) => {
        if (selectedPlatforms.includes(platform)) {
            setSelectedPlatforms(selectedPlatforms.filter((p) => p !== platform));
        } else {
            setSelectedPlatforms([...selectedPlatforms, platform]);
        }
    };

    const handleNextStep = () => selectedPlatforms.length > 0 && onNextStep();

    return (
        <>
            <SelectPlatforms>
                {platforms.map((platform, i) => (
                    <PlatformButton
                        onPress={() => onSelectPlatform(platform)}
                        $isSelected={selectedPlatforms.includes(platform)}
                        $color={`--${platform}`}
                        variant="secondary"
                        startIcon={<PlatformIcon platform={platform} />}
                        key={i}
                    >
                        {LISTENING_PLATFORMS_NAMES[platform]}
                    </PlatformButton>
                ))}
            </SelectPlatforms>
            <Actions>
                <Button isDisabled={selectedPlatforms.length === 0} onPress={handleNextStep}>
                    <FormattedMessage defaultMessage="Continuer" />
                </Button>
                <Button onPress={onClose} variant="link-secondary">
                    <FormattedMessage defaultMessage="Passer cette étape" />
                </Button>
            </Actions>
        </>
    );
};

const SelectPlatforms = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
`;
const PlatformButton = styled(Button)<{ $isSelected: boolean; $color: string }>`
    padding: 0.75rem 1rem;
    border: solid 1px;
    background-color: ${({ $color }) => `var(${$color}50)`};
    color: var(--black);
    border-color: var(--neutral50);
    font-weight: var(--fw-normal);

    &:hover {
        background-color: ${({ $color }) => `var(${$color}100)`};
    }

    ${({ $isSelected }) =>
        $isSelected &&
        css`
            background-color: var(--primary50);
            color: var(--primary500);
            border-color: var(--primary500);
            font-weight: var(--fw-semibold);
        `}

    & > img {
        width: 1.25rem;
        height: 1.25rem;
    }
`;
const Actions = styled.div`
    align-self: stretch;
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 1rem;

    & > :first-child {
        align-self: stretch;
    }
`;

export default SelectBroadcastPlatformsStep;
