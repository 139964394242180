import { useContext, useState } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { useResponsive } from '@/shared/hooks/useResponsive';
import Img from '@ui/atoms/Img';
import { useAmplitudeLogEvent } from '@/shared/hooks/useAmplitude';
import styled from 'styled-components';
import useShowQuery from '@queries/show/useShowQuery.hook';
import { useHistory, useParams } from 'react-router';
import useUserQuery from '@queries/user/useUserQuery.hook';
import useDeleteShowMutation from '@queries/show/useDeleteShowMutation.hook';
import { CurrentShow } from '@/context/CurrentShowContext';
import DeleteShowModal from './DeleteShowModal';
import Text from '@ui/atoms/Text';
import Button from '@/components/Button';
import Stack from '@ui/layout/Stack';
import Alert from '@ui/atoms/Alert';

const DeleteShow = () => {
    useAmplitudeLogEvent('show delete show');
    const history = useHistory();
    const { showId } = useParams<{ showId: string }>();
    const show = useShowQuery(showId);
    const deleteShow = useDeleteShowMutation();
    const currentShow = useContext(CurrentShow);
    const { data: user } = useUserQuery();
    const userIsPmp = user?.canHostOnAusha === false;

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const { isMobileOrTablet } = useResponsive();

    if (show.isError) {
        return <span>ERROR</span>;
    }

    if (show.data) {
        const handleDeleteShow = async () => {
            deleteShow.mutate(
                { showId: `${show.data.id}` },
                {
                    onSuccess: () => {
                        // Remove this show from the current show context
                        currentShow?.setShowId && currentShow.setShowId('');
                        // Redirect to shows list
                        history.push('/app/show/all');
                    },
                },
            );
        };

        return (
            <>
                <DeleteShowModal
                    isOpen={isDeleteModalOpen}
                    onOpenChange={setIsDeleteModalOpen}
                    onDelete={handleDeleteShow}
                    isLoading={deleteShow.isLoading}
                />

                <DeleteShowWrapper>
                    <Stack>
                        <Text fontWeight="--fw-bold" variant="headingL">
                            <FormattedMessage defaultMessage="Supprimer" />
                        </Text>
                        <Text color="--neutral500">
                            <FormattedMessage defaultMessage="Vous n’allez quand même pas nous quitter..." />
                        </Text>
                    </Stack>
                    <InnerWrapper>
                        <Img
                            bordered
                            src={show.data.imageUrl}
                            size={160}
                            ml={isMobileOrTablet ? 'auto' : undefined}
                            mr={isMobileOrTablet ? 'auto' : 25}
                        />
                        <RightBlock>
                            <ShowInfo>
                                <Text fontWeight="--fw-semibold" variant="bodyL">
                                    {show.data.name}
                                </Text>
                                <Text color="--neutral500">
                                    <FormattedMessage
                                        defaultMessage="Créée le {date}"
                                        values={{
                                            date: <FormattedDate value={show.data.createdAt} />,
                                        }}
                                    />
                                </Text>
                            </ShowInfo>
                            {!userIsPmp && show.data.podcastsCount > 0 ? (
                                <Alert variant="alert" defaultIcon>
                                    <Text fontWeight="--fw-semibold" color="--alert">
                                        <FormattedMessage
                                            defaultMessage="Votre émission contient {count, plural, one {# épisode} other {# épisodes}}. Vous devez {count, plural, one {le} other {les}} supprimer ou {count, plural, one {le} other {les}} déplacer avant de pouvoir supprimer votre émission."
                                            values={{ count: show.data.podcastsCount }}
                                        />
                                    </Text>
                                </Alert>
                            ) : (
                                <Button
                                    variant="tertiary"
                                    onPress={() => setIsDeleteModalOpen(true)}
                                >
                                    <FormattedMessage defaultMessage="Supprimer l'émission" />
                                </Button>
                            )}
                        </RightBlock>
                    </InnerWrapper>
                </DeleteShowWrapper>
            </>
        );
    }

    return null;
};

const DeleteShowWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding-mobile);
    display: flex;
    flex-direction: column;
    row-gap: 2rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        padding: var(--container-padding);
    }
`;
const InnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
        align-items: flex-start;
    }
`;
const ShowInfo = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    row-gap: 0.5rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        align-items: flex-start;
    }
`;
const RightBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        align-items: flex-start;
    }
`;

export default DeleteShow;
