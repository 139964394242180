import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SizeMe } from 'react-sizeme';
import Img from '@ui/atoms/Img';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import WavesSvg from '@public/images/youtube_preview_waves.svg';
import { reflex } from '@ui/reflex';
import AushaLogo from '@ui/atoms/AushaLogo';
import { resolveColor } from '@/utils/cssVariables';
import { IMG_PLACEHOLDER } from '@/utils/constants';
import { FormattedMessage } from 'react-intl';

const COMPONENT_MAX_SIZE = 440;

const Container = styled(DeprecatedPaper)`
    max-width: ${COMPONENT_MAX_SIZE}px;
    overflow: hidden;
`;
const StyledLogo = styled(AushaLogo)`
    position: absolute;
    width: 14.0909%; // 62px when youtube preview width is 440px
    bottom: 6.0484%; // 15px when youtube preview height is 248px
    left: 3.4091%; // 15px when youtube preview width is 440px
    fill: var(--white);
    opacity: ${(p) => p.opacity};
`;

const Waves = reflex(WavesSvg);

const BackgroundCover = styled(DeprecatedPaper)`
    background: no-repeat center/cover url(${(p) => p.imageUrl});
    filter: blur(${(p) => `${p.blur}px`});
`;

function YoutubePreview({
    aushaLogoColor,
    aushaLogoOpacity,
    backgroundBlur,
    backgroundColor,
    backgroundImageUrl,
    backgroundOpacity,
    customLogoOpacity,
    customLogoUrl,
    displayAushaLogo,
    podcastName,
    showImageUrl,
    showName,
    textColor,
    waveformColor,
    ...props
}) {
    let logo;

    if (displayAushaLogo) {
        logo = <StyledLogo isComplete opacity={aushaLogoOpacity} />;
    } else if (customLogoUrl) {
        logo = (
            <Img
                pos="absolute"
                yR="6.0484%" // 15px when youtube preview height is 248px
                x="3.4091%" // 15px when youtube preview width is 440px
                height="7.2581%" // 18px when youtube preview height is 248px
                maxW="14.0909%" // 62px when youtube preview width is 440px
                src={customLogoUrl}
                opacity={customLogoOpacity}
                style={{ objectFit: 'contain' }}
            />
        );
    }

    return (
        <Container {...props}>
            {/* Use padding to create a responsive container with 16:9 ratio */}
            {/* See https://stackoverflow.com/a/10441480 */}
            <DeprecatedPaper pt="56.25%" position="relative">
                <SizeMe>
                    {({ size }) => (
                        <>
                            <BackgroundCover
                                blur={backgroundBlur}
                                height="100%"
                                imageUrl={backgroundImageUrl}
                                pos="absolute"
                                w="100%"
                                x={0}
                                y={0}
                            />
                            {/* Transparent layer to darken the background image */}
                            <DeprecatedPaper
                                background={backgroundColor}
                                height="100%"
                                pos="absolute"
                                style={{ opacity: backgroundOpacity }}
                                w="100%"
                                x={0}
                                y={0}
                            />
                            {/* Audio waveform */}
                            <Waves
                                cssAnim
                                fill={resolveColor(waveformColor)}
                                stroke={resolveColor(waveformColor)}
                                pos="absolute"
                                x={0}
                                yR={0}
                            />
                            <Img
                                pos="absolute"
                                src={showImageUrl}
                                w="22.7273%" // 100px when youtube preview width is 440px
                                x="4.5455%" // 20px when youtube preview width is 440px
                                y="8.0645%" // 20px when youtube preview height is 248px
                            />
                            <DeprecatedPaper
                                pos="absolute"
                                w="63.6364%" // 280px when youtube preview width is 440px
                                x="30.4545%" // 134px when youtube preview width is 440px
                                y="14.5161%" // 36px when youtube preview height is 248px
                            >
                                <DeprecatedText
                                    color={textColor}
                                    ellipsis
                                    mb="2.0161%"
                                    style={{
                                        // The font-size need to adjust following the size of the component
                                        fontSize: `calc(${size.width} * (var(--fs-body) / ${COMPONENT_MAX_SIZE}))`,
                                    }}
                                >
                                    {showName}
                                </DeprecatedText>
                                <DeprecatedText
                                    color={textColor}
                                    height="19.3548%"
                                    overflow="hidden"
                                    overflowX="hidden"
                                    overflowY="hidden"
                                    style={{
                                        // The font-size need to adjust following the size of the component
                                        fontSize: `calc(${size.width} * (var(--fs-subheading) / ${COMPONENT_MAX_SIZE}))`,
                                        hyphens: 'auto',
                                        wordBreak: 'break-word',
                                    }}
                                    weight="bold"
                                >
                                    {podcastName}
                                </DeprecatedText>
                            </DeprecatedPaper>
                            {logo}
                        </>
                    )}
                </SizeMe>
            </DeprecatedPaper>
        </Container>
    );
}

YoutubePreview.propTypes = {
    aushaLogoColor: PropTypes.string,
    aushaLogoOpacity: PropTypes.number,
    backgroundBlur: PropTypes.number,
    backgroundColor: PropTypes.string,
    backgroundImageUrl: PropTypes.string,
    backgroundOpacity: PropTypes.number,
    customLogoOpacity: PropTypes.number,
    customLogoUrl: PropTypes.string,
    displayAushaLogo: PropTypes.bool,
    podcastName: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    showImageUrl: PropTypes.string,
    showName: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    textColor: PropTypes.string,
    waveformColor: PropTypes.string,
};

YoutubePreview.defaultProps = {
    aushaLogoColor: 'white',
    aushaLogoOpacity: 0.5,
    backgroundBlur: 15,
    backgroundColor: '#000000',
    backgroundImageUrl: IMG_PLACEHOLDER,
    backgroundOpacity: 0.6,
    customLogoOpacity: 0.5,
    customLogoUrl: null,
    displayAushaLogo: true,
    podcastName: (
        <FormattedMessage defaultMessage="Titre de votre épisode qui peut être sur deux lignes" />
    ),
    showImageUrl: IMG_PLACEHOLDER,
    showName: <FormattedMessage defaultMessage="Nom du podcast" />,
    textColor: 'white',
    waveformColor: '--primary',
};

export default YoutubePreview;
