import { createContext } from 'react';
import { useRadioGroup } from 'react-aria';
import { RadioGroupState, useRadioGroupState } from 'react-stately';
import Text from '@ui/atoms/Text';
import Stack from '@ui/layout/Stack';
import styled from 'styled-components';

export const RadioContext = createContext<RadioGroupState>({} as RadioGroupState);
const ORIENTATIONS = {
    HORIZONTAL: 'horizontal',
    VERTICAL: 'vertical',
};
const directionMatch = {
    [ORIENTATIONS.HORIZONTAL]: 'row',
    [ORIENTATIONS.VERTICAL]: 'column',
};

type Orientation = Lowercase<keyof typeof ORIENTATIONS>;

interface RadioGroupProps {
    children: React.ReactNode;
    label?: React.ReactNode;
    description?: React.ReactNode;
    errorMessage?: React.ReactNode;
    validationState?: any;
    defaultValue?: any;
    isReadOnly?: boolean;
    isDisabled?: boolean;
    className?: string;
    onChange?: (value: any) => void;
    orientation?: Orientation;
    gap?: string;
    value?: string;
}

const RadioGroup = ({ ...props }: RadioGroupProps) => {
    const { children, label, description, errorMessage, validationState, orientation, gap } = props;
    const state = useRadioGroupState(props);
    const { radioGroupProps, labelProps, descriptionProps, errorMessageProps } = useRadioGroup(
        props,
        state,
    );

    return (
        <Stack $gap="0.5rem" {...radioGroupProps}>
            {label && (
                <Text fontWeight="--fw-semibold" {...labelProps}>
                    {label}
                </Text>
            )}
            <InputsWrapper
                gap={gap || '0'}
                orientation={(orientation as Orientation) || ORIENTATIONS.VERTICAL}
            >
                <RadioContext.Provider value={state}>{children}</RadioContext.Provider>
            </InputsWrapper>
            {description && (
                <div {...descriptionProps} style={{ fontSize: 12 }}>
                    {description}
                </div>
            )}
            {errorMessage && validationState === 'invalid' && (
                <div {...errorMessageProps} style={{ color: 'red', fontSize: 12 }}>
                    {errorMessage}
                </div>
            )}
        </Stack>
    );
};

const InputsWrapper = styled.div<{ gap: string; orientation: Orientation }>`
    display: flex;
    flex-direction: ${(p) => directionMatch[p.orientation]};
    gap: ${(p) => p.gap || '0.5rem'};
    align-items: flex-start;
    flex-wrap: wrap;
`;

export default RadioGroup;
