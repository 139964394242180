import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { reflex } from '@ui/reflex';
import { computeCssValue } from '@/shared/utils/css';
import { resolveColor } from '@/shared/utils/cssVariable';

const accents = {
    secondary: css`
        padding: 8px;
        border-radius: var(--r-full);
        background: var(--primary100);
        box-sizing: content-box;
        fill: var(--primary);
        min-width: 14px;
        min-height: 14px;
        overflow: visible;
    `,
};

export const DeprecatedIcon = styled.svg.withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) =>
        !['color', 'size', 'height', 'width'].includes(prop) && defaultValidatorFn(prop),
})`
    display: inline-block;
    ${(p) => p.onClick && 'cursor: pointer'};
    fill: ${(props) => resolveColor(props.color)};
    width: ${(p) => (p.width || p.w ? computeCssValue(p.width || p.w) : computeCssValue(p.size))};
    height: ${(p) =>
        p.height || p.h ? computeCssValue(p.height || p.h) : computeCssValue(p.size)};
    min-width: ${(p) =>
        p.width || p.h ? computeCssValue(p.width || p.w) : computeCssValue(p.size)};
    min-height: ${(p) =>
        p.height || p.h ? computeCssValue(p.height || p.h) : computeCssValue(p.size)};
    ${(p) => accents[p.accent]};
    g {
        stroke: ${(props) => resolveColor(props.color)};
    }
    vertical-align: ${(p) => p.verticalAlign};
`;

DeprecatedIcon.propTypes = {
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    width: PropTypes.number,
    w: PropTypes.number,
    height: PropTypes.number,
    h: PropTypes.number,
    /**
     * One of the colors from the theme (or a custom one)
     */
    color: PropTypes.string,
};

DeprecatedIcon.defaultProps = {
    size: '20px',
    width: undefined,
    w: undefined,
    height: undefined,
    h: undefined,
    color: undefined,
};

/**
 * @component
 */
export default reflex(DeprecatedIcon);
