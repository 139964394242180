import { Children, createContext, useState, isValidElement } from 'react';
import Stack from '@ui/layout/Stack';

interface Accordion {
    openIndex: number | number[] | null;
    goToIndex: (index: number) => void;
    handleToggle: (index: number) => void;
    goToNextIndex: () => void;
    disabledIndexes: number[];
}

export const AccordionContext = createContext<Accordion>({} as Accordion);

interface AccordionProps {
    children: React.ReactNode[] | React.ReactNode;
    defaultIndex?: number | number[];
    onToggle?: (index: number) => void;
    allowMultiple?: boolean;
    className?: string;
}

const Accordion = ({
    children,
    defaultIndex,
    onToggle,
    allowMultiple,
    className,
}: AccordionProps) => {
    const [openIndex, setOpenIndex] = useState<null | number | number[]>(
        defaultIndex ?? (allowMultiple ? [] : null),
    );
    const [prevIndex, setPrevIndex] = useState<null | number | number[]>(
        defaultIndex ?? (allowMultiple ? [] : null),
    );
    const [disabledIndexes, setDisabledIndexes] = useState<number[]>([]);

    const handleToggle = (index: number) => {
        if (disabledIndexes.includes(index)) return;

        onToggle && onToggle(index);

        if (!allowMultiple) {
            setOpenIndex((i) => {
                setPrevIndex(i);
                if (i === index) return null;
                return index;
            });
            return;
        }

        if (Array.isArray(openIndex) && openIndex.includes(index)) {
            setOpenIndex((indexes) => {
                setPrevIndex(indexes);
                if (!Array.isArray(indexes)) return [];
                return indexes.filter((i) => i !== index);
            });
            return;
        }

        setOpenIndex((indexes) => {
            setPrevIndex(indexes);
            if (!Array.isArray(indexes)) return [index];
            return [...indexes, index];
        });
    };

    const handleDisabledIndexes = (index: number, isDisabled: boolean) => {
        if (isDisabled === false && disabledIndexes.includes(index)) {
            setDisabledIndexes((indexes) => indexes.filter((i) => i !== index));
            return;
        } else if (isDisabled === true) {
            setDisabledIndexes((indexes) => [...indexes, index]);
            setOpenIndex((indexes) => {
                if (allowMultiple && Array.isArray(indexes)) {
                    return indexes.filter((i) => i !== index);
                }
                return indexes === index ? null : indexes;
            });
        }
    };

    // TODO: Find a way to get current focus to handle nextIndex method for multiple accordions
    const goToNextIndex = () => {
        if (Array.isArray(openIndex)) return;
        const i = openIndex || 0;
        const isNextIndexDisabled = disabledIndexes.includes(i + 1);
        let nextIndex = isNextIndexDisabled ? i + 2 : i + 1;

        if (allowMultiple || nextIndex > Children.count(children)) return;

        handleToggle(nextIndex);
    };

    const goToIndex = (index: number) => {
        if (!index || index > Children.count(children)) return;
        if (allowMultiple) {
            if (Array.isArray(openIndex) && openIndex.includes(index)) {
                setOpenIndex((indexes) => {
                    if (!Array.isArray(indexes)) return [];
                    return indexes.filter((i) => i === index);
                });
            } else {
                setOpenIndex([index]);
            }
            return;
        }
        setOpenIndex(index);
    };

    return (
        <AccordionContext.Provider
            value={{ openIndex, goToIndex, goToNextIndex, handleToggle, disabledIndexes }}
        >
            <Stack className={className} $gap="1rem">
                {Children.map(children, (item, i) => {
                    if (!item || !isValidElement(item)) return null;
                    const Item = item.type;
                    return (
                        <Item
                            {...item.props}
                            isExpanded={
                                allowMultiple
                                    ? Array.isArray(openIndex) && openIndex.includes(i)
                                    : openIndex === i
                            }
                            onToggle={() => handleToggle(i)}
                            index={i}
                            openIndex={openIndex}
                            prevIndex={prevIndex}
                            onDisabled={handleDisabledIndexes}
                        />
                    );
                })}
            </Stack>
        </AccordionContext.Provider>
    );
};

export default Accordion;
