import { useQuery } from '@tanstack/react-query';
import { fetchRssSettings } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import rssSettingsKeys from './rssSettingsKeys';

const queryFn = async ({ queryKey: [{ showId }] }) => {
    const { data } = await fetchRssSettings(showId);

    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const useRssSettingsQuery = (showId) => {
    return useQuery({
        queryKey: rssSettingsKeys.allDetails(showId),
        queryFn,
        enabled: !!showId,
    });
};

export default useRssSettingsQuery;
