import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';

interface PositionCellProps {
    rank: number;
    isLoading: boolean;
}

const PositionCell = ({ rank = 0, isLoading }: PositionCellProps) => {
    if (rank === 0 || isLoading) return <Dash />;
    if (rank < 4) {
        return (
            <RankWrapper>
                <MedalIcon icon={icon({ name: 'award', style: 'solid' })} rank={rank} />
                {rank}
            </RankWrapper>
        );
    }
    return <span>{rank}</span>;
};

const Dash = styled.div`
    width: 0.75rem;
    height: 2px;
    background-color: var(--neutral300);
    border-radius: var(--r-full);
`;
const RankWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
    color: var(--black);
`;
const MedalIcon = styled(FontAwesomeIcon)<{ rank: number }>`
    font-size: 0.75rem;
    ${({ rank }) => rank === 1 && `color: var(--decorativeD);`}
    ${({ rank }) => rank === 2 && `color: var(--neutral300);`}
    ${({ rank }) => rank > 2 && `color: var(--decorativeC);`}
`;

export default PositionCell;
