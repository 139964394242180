import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect } from '../decorators/connect';
import ShortListFallback from '../fallbacks/ShortListFallback';
import { useResponsive } from '@hooks/useResponsive';
import TwitterTemplateShow from '../molecules/TwitterTemplateShow';
import ProviderLogin from '../molecules/ProviderLogin/ProviderLogin';
import FacebookTemplateShow from '../molecules/FacebookTemplateShow';
import LinkedInTemplateShow from '../molecules/LinkedInTemplateShow';
import InstagramTemplateShow from '../molecules/InstagramTemplateShow';
import { INSTAGRAM, LINKEDIN, SOCIAL_NETWORK_NAME } from '@/utils/constants';
import { USER_OPTIONS } from '@/utils/userOptions';
import { PRICING, PRICING_NAME } from '@/utils/pricing';
import useRouterStore from '@/utils/hooks/useRouterStore';
import Text from '@ui/atoms/Text';
import Stack from '@ui/layout/Stack';
import Cluster from '@ui/layout/Cluster';
import IconFacebook from '@ui/icons/IconFacebook';
import IconLinkedin from '@ui/icons/IconLinkedin';
import IconInstagram from '@ui/icons/IconInstagram';
import IconX from '@ui/icons/IconX';
import useProvidersQuery from '@/queries/provider/useProvidersQuery.hook';

// For add a new PROVIDER
// 1 - Add this one in AutoSharingStore, on static Observables
// 2 - Add update method, unlink show and episode provider in SharingTemplateModel
// 3 - Add field object shareClip[PROVIDER] in EpisodeEditForm
// 4 - Add this new PROVIDER in SHARE_CLIP_PROVIDERS_LIST into PodcastStore
// That All !!! Now you can create your new Provider Template ;)

const Badge = styled.div`
    padding: 0 4px;
    width: fit-content;
    background-color: var(--primary50);
    color: var(--primary);
    border-radius: var(--r-xs);
`;

const PROVIDERS_AUTO_SHARING_NAME = {
    FACEBOOK: 'facebook',
    TWITTER: 'twitter',
    LINKEDIN: 'linkedin',
    INSTAGRAM: 'instagram',
};

const HeaderProvider = ({ provider }) => {
    const show = useRouterStore().resolve('showId');

    const isInstagramOrLinkedInBlocked =
        (provider === INSTAGRAM && !show?.allows(USER_OPTIONS.AUTOSHARING_INSTAGRAM)) ||
        (provider === LINKEDIN && !show?.allows(USER_OPTIONS.AUTOSHARING_LINKEDIN));

    const icons = {
        [PROVIDERS_AUTO_SHARING_NAME.FACEBOOK]: <IconFacebook color="--facebook" size="2rem" />,
        [PROVIDERS_AUTO_SHARING_NAME.TWITTER]: <IconX color="--twitter" size="2rem" />,
        [PROVIDERS_AUTO_SHARING_NAME.LINKEDIN]: <IconLinkedin color="--linkedin" size="2rem" />,
        [PROVIDERS_AUTO_SHARING_NAME.INSTAGRAM]: <IconInstagram color="--instagram" size="2rem" />,
    };

    return (
        <Cluster $align="center" $gap="0.5rem">
            {icons[provider]}
            <Text fontWeight="--fw-semibold" textTransform="capitalize">
                {SOCIAL_NETWORK_NAME[provider]}
            </Text>
            {isInstagramOrLinkedInBlocked && (
                <Badge>
                    <Text variant="footnote" fontWeight="--fw-bold">
                        {PRICING_NAME[PRICING.BOOST]}
                    </Text>
                </Badge>
            )}
        </Cluster>
    );
};

const enhance = connect(({ autoSharingStore, routerStore }) => ({
    showId: routerStore.params.showId,
    fetchSharingProvider: autoSharingStore.fetchSharingProvider,
    linkOffer: routerStore.link('user.offers'),
}));

const ProviderConnector = ({ fetchSharingProvider, provider, showId }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [loggedIn, setLoggedIn] = useState(false);
    const providers = useProvidersQuery(showId);

    useEffect(() => {
        if (!providers?.data) return;
        setLoggedIn(!!providers.data[provider]);
    }, [providers?.data]);

    useEffect(() => {
        if (showId && provider) {
            const fetchSharingProviderData = async () => {
                setIsLoading(true);
                await fetchSharingProvider(showId, provider);
                setIsLoading(false);
            };

            fetchSharingProviderData();
        }
    }, [showId, provider]);

    const PROVIDER_CONTENT = {
        [PROVIDERS_AUTO_SHARING_NAME.FACEBOOK]: (
            <FacebookTemplateShow
                headerProvider={<HeaderProvider provider={provider} />}
                account={providers?.data?.[provider]?.account}
            />
        ),
        [PROVIDERS_AUTO_SHARING_NAME.TWITTER]: (
            <TwitterTemplateShow
                headerProvider={<HeaderProvider provider={provider} />}
                account={providers?.data?.[provider]?.account}
            />
        ),
        [PROVIDERS_AUTO_SHARING_NAME.LINKEDIN]: (
            <LinkedInTemplateShow
                headerProvider={<HeaderProvider provider={provider} />}
                account={providers?.data?.[provider]?.account}
            />
        ),
        [PROVIDERS_AUTO_SHARING_NAME.INSTAGRAM]: (
            <InstagramTemplateShow
                headerProvider={<HeaderProvider provider={provider} />}
                account={providers?.data?.[provider]?.account}
            />
        ),
    };

    let content;

    if (isLoading || providers.isLoading) content = <ShortListFallback />;
    else if (loggedIn) content = PROVIDER_CONTENT[provider];
    else
        content = (
            <ProviderLogin
                provider={provider}
                showId={showId}
                headerProvider={<HeaderProvider provider={provider} />}
            />
        );

    return (
        <ProviderBlock>
            <Wrapper>{content}</Wrapper>
        </ProviderBlock>
    );
};

const Wrapper = ({ children }) => {
    const { isMobile } = useResponsive();
    return isMobile ? (
        <Stack $gap="0.5rem">{children}</Stack>
    ) : (
        <Cluster $align="center" $justify="space-between">
            {children}
        </Cluster>
    );
};

const ProviderBlock = styled.div`
    background: var(--neutral50);
    border-radius: var(--r-s);
    padding: 1.5rem;
`;

export default enhance(ProviderConnector);
