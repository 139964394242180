import ShiningDiamond from '@/components/ui/atoms/ShiningDiamond';
import Text from '@/components/ui/atoms/Text';
import ZebraIllustration from '@/components/ui/atoms/ZebraIllustration';
import EmptyState from '@/components/ui/molecules/EmptyState';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import emptyStateMainIllustration from '@public/images/empty-state/illustration/api.png';
import RouterLink from '@/components/Link/RouterLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import { USER_OPTIONS } from '@/shared/config/userOptions';
import PersonalAccessToken from '@/components/PersonalAccessToken';

// TODO: Add Amplitude event
const PublicApiPage = () => {
    const user = useUserQuery();
    const hasAccessToPublicApi = user.data?.options.includes(USER_OPTIONS.PUBLIC_API);

    if (!hasAccessToPublicApi) {
        return (
            <EmptyStateOuterWrapper>
                <EmptyState
                    illustration={emptyStateMainIllustration}
                    illustrationType="image"
                    illustrationAlign="right"
                    illustrationBackground="--gradient-night"
                >
                    <HeadIllustrationWrapper>
                        <ZebraIllustration>
                            <ShiningDiamond />
                        </ZebraIllustration>
                    </HeadIllustrationWrapper>
                    <LabelWrapper>
                        <Text variant="heading" fontWeight="--fw-bold">
                            <FormattedMessage defaultMessage="Passez à l'offre supérieure !" />
                        </Text>
                        <Text variant="body" fontWeight="--fw-normal" color="--neutral500">
                            <FormattedMessage defaultMessage="Pour pouvoir générer des jetons d'accès personnels pour chaque application ayant besoin d'accéder à Ausha, abonnez-vous à l’offre Supersonic 🚀" />
                        </Text>
                    </LabelWrapper>
                    <RouterLink
                        variant="button-primary"
                        startIcon={
                            <EmptyStateButtonIcon
                                icon={icon({ name: 'rocket-launch', style: 'solid' })}
                            />
                        }
                        to="/app/subscription/offers"
                    >
                        <FormattedMessage defaultMessage="Mettre à niveau mon offre" />
                    </RouterLink>
                </EmptyState>
            </EmptyStateOuterWrapper>
        );
    }

    return <PersonalAccessToken />;
};

const EmptyStateOuterWrapper = styled.div`
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;
const HeadIllustrationWrapper = styled.div`
    width: 200px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    & > * {
        text-align: center;
    }
`;
const EmptyStateButtonIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;

export default PublicApiPage;
