import Model from './Model';

class PricesQuotaModel extends Model {
    static attributes = {
        cost: 'cost',
        total: 'total',
    };
}

export default PricesQuotaModel;
