import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import RouterLink from '@/components/Link/RouterLink';
import styled from 'styled-components';
import { useParams } from 'react-router';

const ReviewSummaryNoApplePodcasts = () => {
    const { showId } = useParams<{ showId: string }>();
    return (
        <EmptyStateWrapper>
            <Icon icon={icon({ name: 'signal-stream-slash', style: 'solid' })} />
            <TitleAndSubtitle>
                <Title>
                    <FormattedMessage
                        defaultMessage="Votre lien {platform} est manquant."
                        values={{ platform: 'Apple Podcasts' }}
                    />
                </Title>
                <Subtitle>
                    <FormattedMessage
                        defaultMessage="<link>Ajoutez votre lien d'écoute dans vos paramètres</link> pour accéder aux notes de votre audience."
                        values={{
                            link: (chunks: string) => (
                                <RouterLink to={`/app/show/${showId}/settings/broadcast`}>
                                    {chunks}
                                </RouterLink>
                            ),
                        }}
                    />
                </Subtitle>
            </TitleAndSubtitle>
        </EmptyStateWrapper>
    );
};

const EmptyStateWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;
const TitleAndSubtitle = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
`;
const Title = styled.span`
    color: var(--neutral);
    font-size: var(--fs-body-l);
    font-weight: var(--fw-bold);
    text-align: center;
`;
const Subtitle = styled.p`
    color: var(--neutral);
    font-size: var(--fs-body-m);
    font-weight: var(--fw-semibold);
    text-align: center;
`;
const Icon = styled(FontAwesomeIcon)`
    color: var(--neutral);
    font-size: 1.5rem;
`;

export default ReviewSummaryNoApplePodcasts;
