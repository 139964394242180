import { FormattedMessage } from 'react-intl';
import Collapse from '@ui/molecules/Collapse';
import DeprecatedTextInput from '@ui/molecules/DeprecatedTextInput';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import CardSelectList from '@ui/molecules/CardSelectList';
import CardSelect from '@ui/atoms/CardSelect';
import styled from 'styled-components';
import IconFastBackward from '@ui/icons/IconFastBackward';
import IconFastForward from '@ui/icons/IconFastForward';
import IconHostRead from '@ui/icons/IconHostRead';
import UiKitTooltip from '@ui/atoms/UiKitTooltip/UiKitTooltip';

const CardsList = styled(CardSelectList)`
    column-gap: 1.5rem;
    align-items: stretch;
`;
const Tooltip = styled(UiKitTooltip)`
    width: 100%;
    height: unset;
    display: flex;
`;

function CampaignGeneral({ fields, status, isImmutable, ...props }) {
    return (
        <Collapse title={<FormattedMessage defaultMessage="Général" />} {...props}>
            <DeprecatedTextInput
                label={<FormattedMessage defaultMessage="Titre de la campagne" />}
                field={fields.name}
                required
                mb={30}
            />
            <DeprecatedText weight="semibold" mb={10}>
                <FormattedMessage defaultMessage="Type" />
                <DeprecatedText as="span" color="--primary" ml={5}>
                    *
                </DeprecatedText>
            </DeprecatedText>
            <CardsList
                name="type"
                value={fields.type.value}
                onChange={(value) => fields.type.setValue(value)}
                isDisabled={isImmutable}
                tooltip={
                    <Tooltip
                        passthrough={!isImmutable}
                        variant="dark"
                        position="top"
                        content={
                            <FormattedMessage defaultMessage="Il n'est pas possible de modifier le type d'une campagne lorsque celle-ci est publiée." />
                        }
                    />
                }
            >
                <CardSelect
                    value="preroll"
                    title={<FormattedMessage defaultMessage="Pré-roll" />}
                    description={
                        <FormattedMessage defaultMessage="La campagne sera diffusée juste avant l’épisode." />
                    }
                    icon={<IconFastBackward />}
                />
                <CardSelect
                    value="postroll"
                    title={<FormattedMessage defaultMessage="Post-roll" />}
                    description={
                        <FormattedMessage defaultMessage="La campagne sera diffusée à la fin de l’épisode." />
                    }
                    icon={<IconFastForward />}
                />
                <CardSelect
                    value="hostread"
                    title={<FormattedMessage defaultMessage="Host-read" />}
                    description={
                        <FormattedMessage defaultMessage="Vous faites votre publicité directement dans l’épisode." />
                    }
                    icon={<IconHostRead />}
                />
            </CardsList>
        </Collapse>
    );
}

export default CampaignGeneral;
