import { useEffect } from 'react';
import { useEpisodeBeingEdited } from '@/context/EpisodeFormContext';
import FileUploaded from './FileUploaded';
import FileUploading from './FileUploading';
import NoFile from './NoFile';
import {
    useEpisodeEditAudioFileMutation,
    useEpisodeEditAudioFileState,
} from '@/components/NonHostingEpisodeForm/contexts/NonHostingEpisodeFileUploadContext';
import useEpisodeAudioFileImportJobQuery from '@/queries/episode/useEpisodeAudioFileImportJobQuery';
import FileImporting from './FileImporting';
import styled from 'styled-components';
import { useQueryClient } from '@tanstack/react-query';
import episodeKeys from '@/queries/episode/episodeKeys';

const AudioFile = () => {
    const episodeEditAudioFileMutation = useEpisodeEditAudioFileMutation();
    const [uploadingAudioFile] = useEpisodeEditAudioFileState();
    const episodeBeingEdited = useEpisodeBeingEdited();
    const uploadedAudioFile = episodeBeingEdited?.files?.[0];
    const audioFileImportJob = useEpisodeAudioFileImportJobQuery({
        episodeId: episodeBeingEdited?.id,
        options: {
            enabled:
                !!episodeBeingEdited?.id &&
                !uploadedAudioFile &&
                !uploadingAudioFile &&
                !episodeEditAudioFileMutation.isLoading,
        },
    });
    const queryClient = useQueryClient();

    useEffect(() => {
        if (audioFileImportJob.data?.isFinished) {
            queryClient.invalidateQueries({
                queryKey: episodeKeys.detailById(episodeBeingEdited.id),
            });
        }
    }, [audioFileImportJob.data?.isFinished]);

    if (episodeEditAudioFileMutation.isLoading && uploadingAudioFile) {
        return (
            <Container>
                <FileUploading file={uploadingAudioFile} />
            </Container>
        );
    }

    if (uploadedAudioFile) {
        return (
            <Container>
                <FileUploaded file={uploadedAudioFile} audioUrl={episodeBeingEdited.audioUrl} />
            </Container>
        );
    }

    /* TODO: Handle error state (audioFileImportJob.isError || audioFileImportJob.data?.isFailed) */

    if (
        audioFileImportJob.isFetching ||
        audioFileImportJob.data?.isExecuting ||
        audioFileImportJob.data?.isQueued
    ) {
        return <FileImporting />;
    }

    return (
        <Container>
            <NoFile />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    width: 100%;
`;

export default AudioFile;
