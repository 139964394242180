import { createContext, useState } from 'react';

interface CurrentShowContextT {
    showId: string;
    setShowId: (showId: string) => void;
}

export const CurrentShow = createContext<CurrentShowContextT>(
    null as unknown as CurrentShowContextT,
);

interface CurrentShowContextProps {
    children: React.ReactNode;
}

export const CurrentShowContext = ({ children }: CurrentShowContextProps) => {
    const [showId, setShowId] = useState('');

    return <CurrentShow.Provider value={{ showId, setShowId }}>{children}</CurrentShow.Provider>;
};
