import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { debounce } from 'lodash';
import { zodResolver } from '@hookform/resolvers/zod';
import {
    useEpisodeEditAudioFileMutation,
    useEpisodeEditAudioFileState,
} from '@/components/NonHostingEpisodeForm/contexts/NonHostingEpisodeFileUploadContext';
import useNonHostingEpisodeAudioFileSchema, {
    NonHostingEpisodeAudioFileSchema,
} from '@/components/NonHostingEpisodeForm/useNonHostingEpisodeAudioFileSchema.hook';
import AudioFileInput from './AudioFileInput';

const NoFile = () => {
    const intl = useIntl();
    const { episodeId } = useParams<{ episodeId: string }>();
    const episodeEditAudioFileMutation = useEpisodeEditAudioFileMutation();
    const [_, setEpisodeEditAudioFile] = useEpisodeEditAudioFileState();
    const schema = useNonHostingEpisodeAudioFileSchema();

    const { control, watch, handleSubmit, setError } = useForm<NonHostingEpisodeAudioFileSchema>({
        mode: 'onChange',
        resolver: zodResolver(schema),
        defaultValues: {
            audioFile: null,
        },
    });

    useEffect(() => {
        const subscription = watch(() => {
            handleSubmit(onSubmit)();
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    const onSubmit = ({ audioFile }: NonHostingEpisodeAudioFileSchema) => {
        if (!audioFile || typeof audioFile === 'string') return;

        episodeEditAudioFileMutation.mutate(
            { episodeId: episodeId, audioFile: audioFile[0] },
            {
                onSuccess: () => {
                    localStorage.setItem(`episode-${episodeId}-audio-file-uploaded`, 'true');
                    debounce(() => setEpisodeEditAudioFile(null), 300)();
                },
                onError: () => {
                    setEpisodeEditAudioFile(null);
                    setError('root.serverError', {
                        type: '500',
                        message: intl.formatMessage({ defaultMessage: 'Une erreur est survenue' }),
                    });
                },
            },
        );
        // Keep audioFile in a state to display audioFile data in
        // episode form during audioFile upload
        setEpisodeEditAudioFile(audioFile[0]);
    };

    return (
        <Controller
            control={control}
            name="audioFile"
            render={({
                field: { value, ...field },
                formState: { errors },
                fieldState: { error },
            }) => (
                <AudioFileInput
                    {...field}
                    error={error?.message ?? errors?.root?.serverError?.message}
                />
            )}
        />
    );
};

export default NoFile;
