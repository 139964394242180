import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import IconClose from '@ui/icons/IconClose';
import BoostIncentiveAnimation from '@public/images/geolocalization-boost-incentive.gif';
import SupersonicIncentiveAnimation from '@public/images/geolocalization-supersonic-incentive.gif';
import { motion } from 'framer-motion';
import Link from '@ui/atoms/Link';
import { PRICING, PRICING_NAME } from '@/utils/pricing';

const IncentiveModal = ({ plan, onClose }) => {
    const planToUpgradeToLookup = {
        [PRICING.LAUNCH]: {
            label: PRICING_NAME[PRICING.BOOST],
            targetEntity: <FormattedMessage defaultMessage="région / état" />,
            illustration: BoostIncentiveAnimation,
        },
        [PRICING.BOOST]: {
            label: PRICING_NAME[PRICING.SUPERSONIC],
            targetEntity: <FormattedMessage defaultMessage="ville" id="geolocalization.cities" />,
            illustration: SupersonicIncentiveAnimation,
        },
    };

    if (!planToUpgradeToLookup[plan]) {
        return null;
    }

    return (
        <IncentiveModalOuterWrapper
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <IncentiveModalWrapper>
                <IncentiveIllustration src={planToUpgradeToLookup[plan].illustration} />
                <IncentiveModalInnerWrapper>
                    <IncentiveModalContent>
                        <Title>
                            <FormattedMessage
                                defaultMessage="Découvrez la répartition de vos auditeurs par {entity} afin de mieux comprendre votre audience 🚀"
                                values={{ entity: planToUpgradeToLookup[plan].targetEntity }}
                            />
                        </Title>
                        <LinkToPlans to="/app/user/subscription/offers">
                            <FormattedMessage
                                defaultMessage="Passez à l'offre {plan} dès maintenant !"
                                values={{ plan: planToUpgradeToLookup[plan].label }}
                            />
                        </LinkToPlans>
                    </IncentiveModalContent>
                    <Close onClick={() => onClose()} />
                </IncentiveModalInnerWrapper>
            </IncentiveModalWrapper>
        </IncentiveModalOuterWrapper>
    );
};

const IncentiveModalOuterWrapper = styled(motion.div)`
    padding: 1rem;
`;

const IncentiveModalWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-s);
    display: flex;
    width: 100%;
    max-width: 490px;
    min-height: 6rem;
    box-shadow: var(--s-medium);
`;

const IncentiveIllustration = styled.img`
    height: auto;
    width: 6rem;
    border-radius: 12px 0 0 12px;
    object-fit: cover;
`;

const IncentiveModalInnerWrapper = styled.div`
    display: flex;
    padding: 1rem;
    column-gap: 0.5rem;
`;

const IncentiveModalContent = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const Title = styled.div`
    font-weight: var(--fw-semibold);
    line-height: 20px;
`;

const LinkToPlans = styled(Link)`
    font-weight: var(--fw-semibold);
    color: var(--primary);
    font-size: 12px;
    text-decoration: none;
`;

const Close = styled(IconClose)`
    cursor: pointer;
`;

export default IncentiveModal;
