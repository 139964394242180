import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import CurrentPlan from './CurrentPlan';
import FreemiumPlan from './FreemiumPlan';
import Payment from './Payment';
import Quotas from './Quotas';
import OfferDetails from './OfferDetails';
import useSubscriptionQuery from '@queries/subscription/useSubscriptionQuery.hook';
import useUserQuery from '@queries/user/useUserQuery.hook';
import { SURVEY_TYPE } from '@/components/unorganized/SubscribeSurveyModal/SubscribeSurveyForm';
import { PRICING } from '@/shared/config/pricing';
import SubscribeSurveyModal from '@/components/unorganized/SubscribeSurveyModal';
import { useOverlayTriggerState } from 'react-stately';
import useQuery from '@/shared/hooks/useQuery';
import styled from 'styled-components';
import EpisodeAIPricing from '@/components/EpisodeAI/EpisodeAIPricing';
import { ENABLE_EPISODE_AI } from '@/shared/config/constants';

const Subscription = () => {
    const { data: subscription } = useSubscriptionQuery();
    const { data: user } = useUserQuery();
    let subscribeSurveyModalState = useOverlayTriggerState({});
    const query = useQuery();
    const isSubscriptionActive = !!subscription?.activated && !!user?.pricing;
    const displaySurvey =
        query.get('displaySurvey') === 'true' &&
        isSubscriptionActive &&
        subscribeSurveyModalState.isOpen;

    useEffect(() => {
        const subscribeSurveyModalShouldBeOpened =
            user &&
            user.surveys &&
            !user.surveys.includes(SURVEY_TYPE) && // Hide survey if user already answered
            !user.guest && // Hide survey for guest users
            user.pricing !== 'enterprise'; // Hide survey for accounts created for enterprise clients
        if (subscribeSurveyModalShouldBeOpened) {
            subscribeSurveyModalState.open();
        }
    }, [user]);

    if (!subscription) return null;
    return (
        <>
            <SubscriptionWrapper>
                <Section>
                    <SectionTitle>
                        <FormattedMessage defaultMessage="Abonnement et facturation" />
                    </SectionTitle>
                    <PaymentAndCurrentPlanWrapper>
                        {subscription.isFreemium ? (
                            <FreemiumPlan />
                        ) : (
                            <>
                                <CurrentPlan />
                                <Payment />
                            </>
                        )}
                    </PaymentAndCurrentPlanWrapper>
                </Section>
                {ENABLE_EPISODE_AI && (
                    <Section>
                        <EpisodeAIPricing />
                    </Section>
                )}
                {subscription.pricing !== PRICING.ENTERPRISE && (
                    <Section>
                        <SectionTitle>
                            <FormattedMessage defaultMessage="Quotas" />
                        </SectionTitle>
                        <Quotas />
                    </Section>
                )}
                {subscription.pricing !== PRICING.ENTERPRISE && !subscription.isFreemium && (
                    <Section>
                        <SectionTitle>
                            <FormattedMessage defaultMessage="Récapitulatif" />
                        </SectionTitle>
                        <OfferDetails />
                    </Section>
                )}

                <PaymentDetailsWrapper></PaymentDetailsWrapper>
            </SubscriptionWrapper>
            {displaySurvey && (
                <SubscribeSurveyModal
                    isOpened={subscribeSurveyModalState.isOpen}
                    onClose={() => {
                        subscribeSurveyModalState.close();
                        // @ts-ignore
                        window.location = window.location.pathname;
                    }}
                />
            )}
        </>
    );
};

const SubscriptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4rem;
`;
const Section = styled.section`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;
const SectionTitle = styled.div`
    color: var(--neutral500);
    font-weight: var(--fw-semibold);
`;
const PaymentAndCurrentPlanWrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;

    @media (min-width: 900px) {
        grid-template-columns: repeat(auto-fit, minmax(calc(50% - 0.5rem), 1fr));
    }
`;
const PaymentDetailsWrapper = styled.div``;

export default Subscription;
