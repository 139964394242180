import styled, { css } from 'styled-components';
import { Column } from '@tanstack/react-table';
import { DownloadsComparisonData } from '@/components/unorganized/DownloadsComparisonContent';

export const DownloadsComparisonTableTh = styled.th<{
    column: Column<DownloadsComparisonData>;
    isHighlighted: boolean;
}>`
    padding: 1rem;

    left: ${(props) =>
        props.column.getIsPinned() === 'left' ? `${props.column.getStart('left')}px` : undefined};
    position: ${(props) => (props.column.getIsPinned() === 'left' ? 'sticky' : 'relative')};
    width: ${(props) => props.column.getSize()}px;

    scroll-margin-inline-start: 20rem; // TODO: Add documentation

    ${(props) =>
        props.column.getIsPinned() === 'left' &&
        css`
            background: var(--neutral50);
            z-index: 1;
        `}

    ${(props) =>
        props.isHighlighted &&
        css`
            background: var(--neutral100);
        `}
`;

export const DownloadsComparisonTableTd = styled.td<{
    column: Column<DownloadsComparisonData>;
    isHighlighted: boolean;
}>`
    padding: 1rem;
    text-align: center;

    left: ${(props) =>
        props.column.getIsPinned() === 'left' ? `${props.column.getStart('left')}px` : undefined};
    position: ${(props) => (props.column.getIsPinned() === 'left' ? 'sticky' : 'relative')};
    width: ${(props) => props.column.getSize()}px;

    ${(props) =>
        props.column.getIsPinned() === 'left' &&
        css`
            background: var(--white);
            z-index: 1;
        `}

    ${(props) =>
        props.isHighlighted &&
        css`
            background: var(--neutral50);
        `}
`;
