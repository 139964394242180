import { FormattedMessage } from 'react-intl';
import RssImportForm from '../RssImportForm';
import Dialog from '@/components/ui/atoms/Dialog';

interface AddRssImportModalProps {
    isOpen: boolean;
    onClose: () => void;
    isEdit: boolean;
}

const AddRssImportModal = ({ isOpen, onClose, isEdit }: AddRssImportModalProps) => {
    return (
        <Dialog
            isOpen={isOpen}
            onOpenChange={onClose}
            title={
                isEdit ? (
                    <FormattedMessage defaultMessage="Modifier le lien RSS" />
                ) : (
                    <FormattedMessage defaultMessage="Ajouter un lien RSS" />
                )
            }
        >
            <RssImportForm onAfterSubmit={onClose} isEdit={isEdit} />
        </Dialog>
    );
};

export default AddRssImportModal;
