import { useMutation, useQueryClient } from '@tanstack/react-query';
import { moveEpisodesPlaylist } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import { Playlist } from './usePlaylistsQuery.hook';
import playlistKeys from './playlistKeys';

export interface MutationFnProps {
    playlistId: string | number;
    episodeId: string | number;
    newPosition: number;
}

const mutationFn = async ({
    playlistId,
    episodeId,
    newPosition,
}: MutationFnProps): Promise<Playlist> => {
    const { data } = await moveEpisodesPlaylist(playlistId, episodeId, {
        new_position: newPosition,
    });

    return camelcaseKeys(data as any, { deep: true });
};

const updatePlaylistEpisodePositionMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn,
        onSuccess: (_, { playlistId }) => {
            queryClient.invalidateQueries(playlistKeys.detailById(playlistId));
        },
    });
};

export default updatePlaylistEpisodePositionMutation;
