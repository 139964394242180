import { FormattedMessage } from 'react-intl';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import DeprecatedWhitePaper from '@ui/atoms/DeprecatedWhitePaper';
import { useParams } from 'react-router-dom';
import RssExportConfigurationForm from './RssExportConfigurationForm';
import useRssSettingsQuery from '@queries/rssSettings/useRssSettingsQuery.hook';

const RssExportConfiguration = () => {
    const { showId } = useParams();
    const rssSettings = useRssSettingsQuery(showId);

    if (rssSettings.error) return <span>Error</span>;
    if (rssSettings.isLoading) return <span>Loading...</span>;

    return (
        <>
            <DeprecatedText size="veryBig" weight="bold" mb={20} mt={35}>
                <FormattedMessage defaultMessage="Configuration" />
            </DeprecatedText>
            <DeprecatedWhitePaper p={35}>
                {rssSettings.data && (
                    <RssExportConfigurationForm
                        rssSettings={rssSettings.data}
                        key={rssSettings.fetchStatus}
                    />
                )}
            </DeprecatedWhitePaper>
        </>
    );
};

export default RssExportConfiguration;
