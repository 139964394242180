import useHasAccessToEpisodeAI from '@/shared/hooks/useHasAccessToEpisodeAI.hook';
import { useParams } from 'react-router';
import useEpisodeTranscriptStatusQuery from '@/queries/episodeTranscript/useEpisodeTranscriptStatusQuery.hook';
import { EPISODE_TRANSCRIPT_STATUS } from '@/shared/config/constants';
import EpisodeAISummaryLoading from '../EpisodeAISummaryLoading';
import useAISummaryQuery from '@/queries/ai/useAISummaryQuery.hook';
import { useEpisodeBeingEdited } from '@/context/EpisodeFormContext';
import EpisodeAIUnauthorizedState from '../EpisodeAIUnauthorizedState';
import EpisodeAITranscriptionMissingState from '../EpisodeAITranscriptionMissingState';
import EpisodeAIPanel from '../EpisodeAIPanel';

const EpisodeAIBubblePopoverContent = () => {
    const { showId, episodeId } = useParams<{ showId: string; episodeId: string }>();
    const episode = useEpisodeBeingEdited();
    const hasAccessToEpisodeAI = useHasAccessToEpisodeAI({ showId });
    const { data: transcriptionStatus } = useEpisodeTranscriptStatusQuery(episodeId);
    const hasTranscription = transcriptionStatus?.status === EPISODE_TRANSCRIPT_STATUS.DONE;
    const summary = useAISummaryQuery({
        episodeId,
        options: { enabled: !!episodeId && hasAccessToEpisodeAI && hasTranscription },
    });

    if (!hasAccessToEpisodeAI && !episode?.aiFreeTrialEnabled) {
        return <EpisodeAIUnauthorizedState testId="unauthorized-bubble" />;
    }

    if (!hasTranscription) {
        return <EpisodeAITranscriptionMissingState testId="missing-transcription-bubble" />;
    }

    if (summary.isLoading) {
        return <EpisodeAISummaryLoading dataTestId="episode-ai-bubble-summary-loader" />;
    }

    return <EpisodeAIPanel generateButtonDataTestId="episode-ai-bubble-generate-button" />;
};

export default EpisodeAIBubblePopoverContent;
