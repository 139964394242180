import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { resolveColor } from '@/utils/cssVariables';

const sizes = {
    medium: css`
        padding: calc(0.625rem - 1px) calc(1rem - 1px);
    `,
    mediumIcon: css`
        padding: calc(0.625rem - 1px);
    `,
    small: css`
        padding: calc(0.375rem - 1px) calc(0.75rem - 1px);
    `,
    smallIcon: css`
        padding: calc(0.375rem - 1px);
    `,
};

const SecondaryButton = styled.button`
    display: inline-flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;

    position: relative;
    overflow: hidden;

    cursor: pointer;
    text-decoration: none;
    border: 1px solid var(--neutral200);
    border-radius: var(--r-s);

    transition-duration: 200ms;
    transition-property: opacity, background, color, border-color;

    background: var(--white);
    color: ${(props) => resolveColor(props.$color)};

    ${(props) => sizes[props.$size]}

    ${(props) =>
        props.$isRound &&
        css`
            border-radius: var(--r-full);
        `}

    ${(props) =>
        props.$isDisabled &&
        css`
            opacity: 0.5;
            cursor: not-allowed;
            // By default, disabled button prevent mouse event to fire.
            // pointer-events disable this behaviour and allow parent to catch mouseenter and mouseleave events.
            // https://github.com/whatwg/html/issues/2368
            pointer-events: none;
        `}

    ${(props) =>
        props.$isFocusVisible &&
        css`
            border-color: var(--neutral400);
        `}

    ${(props) =>
        props.$isHovered &&
        css`
            background: var(--neutral100);
        `}

    ${(props) =>
        props.$isPressed &&
        css`
            background: var(--neutral100);
            border-color: var(--neutral400);
        `}

    ${(props) =>
        props.$isLoading &&
        css`
            & > :not(.loading-container) {
                opacity: 0;
            }
        `}

    & > svg {
        // Prevent icons to shrink
        flex-shrink: 0;
    }

    & > .button-text {
        font-weight: var(--fw-semibold);
    }
`;

SecondaryButton.propTypes = {
    $size: PropTypes.oneOf(['medium', 'mediumIcon', 'small', 'smallIcon']),
    // Background color is not editable for this variant
    // $background: PropTypes.string,
    $color: PropTypes.string,
    $isRound: PropTypes.bool,
    $isDisabled: PropTypes.bool,
    $isFocusVisible: PropTypes.bool,
    $isHovered: PropTypes.bool,
    $isPressed: PropTypes.bool,
    $isLoading: PropTypes.bool,
};

SecondaryButton.defaultProps = {
    $color: 'black',
};

export default SecondaryButton;
