import { useState, createContext, useContext, ReactNode } from 'react';
import useSubscriptionQuery from '@/queries/subscription/useSubscriptionQuery.hook';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import { PRICING_CURRENCY } from '@/shared/config/pricing';
import usePricesQuery, { Currency, Offer, Price } from '@/queries/price/usePricesQuery.hook';
import type { SubscriptionAction } from '@/shared/config/constants';

export interface offerUpdatePayload {
    offer: Offer;
    isBilledYearly: boolean;
    action: SubscriptionAction;
}

export interface errorPayload {
    offer: Offer;
    isBilledYearly: boolean;
    error: any;
}

interface PricingContext {
    getPricesOfCurrencyAndProduct: (currency: Currency, product: Offer) => Price | null;
    pricesOfCurrentSubscriptionPricing: () => Price | null;
    isCurrentSubscriptionActive: boolean;
    isCurrentSubscriptionBilledYearly: boolean;
    currentSubscriptionCurrency: Currency | null;
    currentSubscriptionPricing: Offer | null;
    isYearlyPeriodicitySelected: boolean;
    setYearlyPeriodicitySelected: (value: boolean) => void;
    selectedCurrency: Currency | null;
    setSelectedCurrency: (value: Currency) => void;
    offerUpdatePayload: offerUpdatePayload | null;
    setOfferUpdatePayload: (value: offerUpdatePayload | null) => void;
    errorPayload: errorPayload | null;
    setErrorPayload: (value: errorPayload | null) => void;
}

const PricingContext = createContext<PricingContext | undefined>(undefined);

interface PricingContextProviderProps {
    children: ReactNode;
}

export const PricingContextProvider = ({ children }: PricingContextProviderProps) => {
    const { data: subscription } = useSubscriptionQuery();
    const { data: user } = useUserQuery();
    const { data: prices } = usePricesQuery();
    const isCurrentSubscriptionActive = !!subscription?.activated;
    const isCurrentSubscriptionBilledYearly = !!subscription?.yearly;
    const currentSubscriptionCurrency: Currency | null =
        (subscription?.currency as Currency) || null;
    const currentSubscriptionPricing: Offer | null = subscription?.pricing || null;

    const [isYearlyPeriodicitySelected, setYearlyPeriodicitySelected] = useState(
        (subscription?.activated && isCurrentSubscriptionBilledYearly) ?? false,
    );

    const calculatedCurrency =
        subscription?.activated && currentSubscriptionCurrency
            ? currentSubscriptionCurrency
            : user?.country === 'FR'
            ? PRICING_CURRENCY.EUR
            : user?.country === 'GB'
            ? PRICING_CURRENCY.GBP
            : PRICING_CURRENCY.USD;

    const [selectedCurrency, setSelectedCurrency] = useState(calculatedCurrency as Currency);

    const [offerUpdatePayload, setOfferUpdatePayload] = useState<offerUpdatePayload | null>(null);
    const [errorPayload, setErrorPayload] = useState<errorPayload | null>(null);

    const getPricesOfCurrencyAndProduct = (currency: Currency, product: Offer) => {
        if (!prices) {
            return null;
        }
        return prices?.[currency]?.[product] ?? null;
    };

    const pricesOfCurrentSubscriptionPricing = () => {
        if (!subscription || !currentSubscriptionCurrency || !currentSubscriptionPricing) {
            return null;
        }
        return getPricesOfCurrencyAndProduct(
            currentSubscriptionCurrency,
            currentSubscriptionPricing,
        );
    };

    const contextValue = {
        getPricesOfCurrencyAndProduct,
        pricesOfCurrentSubscriptionPricing,
        isCurrentSubscriptionActive,
        isCurrentSubscriptionBilledYearly,
        currentSubscriptionCurrency,
        currentSubscriptionPricing,
        isYearlyPeriodicitySelected,
        setYearlyPeriodicitySelected,
        selectedCurrency,
        setSelectedCurrency,
        offerUpdatePayload,
        setOfferUpdatePayload,
        errorPayload,
        setErrorPayload,
    };

    return <PricingContext.Provider value={contextValue}>{children}</PricingContext.Provider>;
};

export const usePricingContext = () => {
    const context = useContext(PricingContext);
    if (context === undefined) {
        throw new Error('usePricingContext must be used within a PricingContextProvider');
    }
    return context;
};
