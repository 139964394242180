import { cloneElement, useRef } from 'react';

const Uploader = ({ children, multiple, disabled, onChange, name, forwardedAs, accept }) => {
    const id = crypto.randomUUID();

    const fileInput = useRef(null);

    const onInputChange = (e) => {
        const file = multiple ? e.target.files : e.target.files[0];
        onChange(file);
        // Erase value after triggering change event to allow user to upload the same file
        e.target.value = null;
    };

    const cloneAsProps = forwardedAs ? { forwardedAs } : { as: 'label' };

    return (
        <>
            {cloneElement(children, {
                ...cloneAsProps,
                htmlFor: id,
                style: { cursor: 'pointer' },
            })}
            <input
                ref={fileInput}
                id={id}
                type="file"
                onChange={onInputChange}
                hidden
                disabled={disabled}
                name={name}
                accept={accept}
            />
        </>
    );
};

export default Uploader;
