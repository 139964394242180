import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateShowSocialLinks } from '@/api';
import showKeys from '@/queries/show/showKeys';

export function useUpdateSocialLinks() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (data) => {
            return updateShowSocialLinks(data.showId, data.externalLinks);
        },
        onSuccess: (data, { showId }) => {
            queryClient.invalidateQueries({ queryKey: showKeys.detailById(showId) });
        },
    });
}
