import { useMutation, useQueryClient } from '@tanstack/react-query';
import { refreshRssFeed } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import rssKeys from './rssKeys';
import episodeKeys from '../episode/episodeKeys';

interface MutationFnProps {
    showId: string;
}

const mutationFn = async ({ showId }: MutationFnProps) => {
    const { data } = await refreshRssFeed(showId);
    return camelcaseKeys(data, { deep: true });
};

const useRefreshRssFeedMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn,
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({
                queryKey: rssKeys.feed(variables.showId),
            });
            queryClient.invalidateQueries({ queryKey: rssKeys.episodes(variables.showId) });
            queryClient.invalidateQueries({ queryKey: episodeKeys.allLists() });
        },
    });
};

export default useRefreshRssFeedMutation;
