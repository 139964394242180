import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import usePsoLiveSearchQuery, { LiveSearchItems } from '@queries/pso/usePsoLiveSearchQuery.hook';
import type { Platform, Mode } from '@/api/pso/types';
import { getCountryISO3 } from '@/utils/country';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Datatable from './Datatable';
import styled from 'styled-components';

interface ResultsProps {
    searchParams: {
        keyword: string;
        platform: Platform;
        store: string;
        mode: Mode;
    };
}

const Results = ({ searchParams }: ResultsProps) => {
    const { keyword, platform, store, mode } = searchParams;
    const { showId } = useParams<{
        showId: string;
    }>();

    const enabled = !!showId && !!platform && !!store && !!mode;
    const {
        data: liveSearchResults,
        isLoading,
        isError,
    } = usePsoLiveSearchQuery(
        {
            showId,
            keyword,
            platform,
            store: getCountryISO3(store),
            mode,
        },
        { enabled },
    );

    if (enabled && isLoading) {
        return (
            <ResultsWrapper>
                <LoaderWrapper>
                    <FontAwesomeIcon icon={icon({ name: 'spinner-third', style: 'solid' })} spin />
                </LoaderWrapper>
            </ResultsWrapper>
        );
    }

    if (isError) {
        return <ResultsWrapper>Error</ResultsWrapper>;
    }

    if (liveSearchResults && liveSearchResults.items.length === 0) {
        return (
            <ResultsWrapper>
                <DefaultStateWrapper>
                    <SearchIcon icon={icon({ name: 'magnifying-glass', style: 'solid' })} />
                    <DefaultStateTitleWrapper>
                        <DefaultStateTitle>
                            <FormattedMessage
                                defaultMessage="Aucun résultat trouvé pour {keyword}"
                                values={{
                                    keyword: `"${keyword}"`,
                                }}
                            />
                        </DefaultStateTitle>
                        <DefaultStateTitleSubtitle>
                            <FormattedMessage defaultMessage="Veuillez réessayer avec un mot-clé différent." />
                        </DefaultStateTitleSubtitle>
                    </DefaultStateTitleWrapper>
                </DefaultStateWrapper>
            </ResultsWrapper>
        );
    }

    if (liveSearchResults) {
        return (
            <ResultsWrapper>
                <Datatable rows={liveSearchResults.items as LiveSearchItems} mode={mode} />
            </ResultsWrapper>
        );
    }

    return (
        <ResultsWrapper>
            <DefaultStateWrapper>
                <SearchIcon icon={icon({ name: 'magnifying-glass', style: 'solid' })} />
                <DefaultStateTitleWrapper>
                    <DefaultStateTitle>
                        <FormattedMessage defaultMessage="Saisissez un mot-clé pour commencer" />
                    </DefaultStateTitle>
                    <DefaultStateTitleSubtitle>
                        <FormattedMessage defaultMessage="Effectuez une recherche ci-dessus pour afficher le classement des résultats par plateforme d'écoute." />
                    </DefaultStateTitleSubtitle>
                </DefaultStateTitleWrapper>
            </DefaultStateWrapper>
        </ResultsWrapper>
    );
};

const ResultsWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding);
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;
const LoaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-block: 2rem;
    color: var(--primary);
`;
const DefaultStateWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    color: var(--neutral500);
    width: 100%;
    max-width: 24rem;
    margin-inline: auto;
    padding-block: 2rem;
`;
const SearchIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
    font-size: 1.5rem;
`;
const DefaultStateTitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.25rem;
`;
const DefaultStateTitle = styled.h2`
    font-weight: var(--fw-semibold);
    font-size: var(--fs-body-s);
`;
const DefaultStateTitleSubtitle = styled.h3`
    font-weight: var(--fw-normal);
    font-size: var(--fs-body-s);
    text-align: center;
`;

export default Results;
