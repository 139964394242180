import { FormattedMessage, useIntl } from 'react-intl';
import Text from '@ui/atoms/Text';
import DialogAlert from '@ui/atoms/DialogAlert';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Button from '@/components/Button';
import useYoutubeLogin from '@/shared/hooks/useYoutubeLogin.hook';
import { useModalToastQueue } from '@/shared/hooks/useModalToastQueue.hook';
import { AxiosError } from 'axios';
import Alert from '@ui/atoms/Alert';
import Stack from '@ui/layout/Stack';
import { extractCssVariable } from '@/shared/utils/cssVariable';
import ExternalLink from '@/components/Link/ExternalLink';

interface BroadcastYoutubeLoginModalProps {
    onSuccess: () => void;
    isOpen: boolean;
    onClose: () => void;
}

const BroadcastYoutubeLoginModal = ({
    onSuccess,
    isOpen,
    onClose,
}: BroadcastYoutubeLoginModalProps) => {
    const toast = useModalToastQueue();
    const intl = useIntl();

    const handleError = (e: unknown) => {
        onClose();
        const error = e as AxiosError;
        if (error.response && error.response.status === 400) {
            toast.alert(
                <FormattedMessage
                    defaultMessage="Vous devez d'abord créer une chaîne YouTube avant de relier votre compte Google à Ausha. {guide}."
                    values={{
                        guide: (
                            <ExternalLink
                                href={intl.formatMessage({
                                    defaultMessage:
                                        'https://support.google.com/youtube/answer/1646861?hl=fr#:~:text=Cr%C3%A9er%20une%20cha%C3%AEne%20personnelle&text=Connectez%2Dvous%20%C3%A0%20YouTube%20depuis,ou%20via%20le%20site%20mobile.&text=Cr%C3%A9er%20une%20cha%C3%AEne.,les%20pour%20cr%C3%A9er%20votre%20cha%C3%AEne',
                                })}
                                target="_blank"
                            >
                                <FormattedMessage defaultMessage="Comment créer une chaîne sur YouTube" />
                            </ExternalLink>
                        ),
                    }}
                />,
            );

            return;
        }
        toast.alert();
    };

    const handleSuccess = () => {
        window.location.reload();
        onSuccess();
    };

    const handleLogin = useYoutubeLogin({ onSuccess: handleSuccess, onError: handleError });

    return (
        <DialogAlert
            isOpen={isOpen}
            onOpenChange={onClose}
            action={
                <>
                    <Button onPress={handleLogin}>
                        <FormattedMessage defaultMessage="Diffuser sur YouTube" />
                    </Button>
                    <CancelButton variant="ghost" onPress={onClose}>
                        <FormattedMessage defaultMessage="Annuler" />
                    </CancelButton>
                </>
            }
            icon={
                <FontAwesomeIcon
                    icon={icon({ name: 'youtube', style: 'brands' })}
                    color={extractCssVariable('--youtube')}
                    size="xl"
                />
            }
            iconBgColor={'var(--youtube-light)'}
            title={
                <FormattedMessage defaultMessage="Souhaitez-vous diffuser votre émission sur YouTube ?" />
            }
        >
            <Stack $gap="1.5rem">
                <Text color="--neutral500" textAlign="center">
                    <FormattedMessage defaultMessage="Vous allez être redirigé vers la page de connexion à votre compte Google." />
                </Text>
                <Alert
                    variant="info"
                    icon={<InfoIcon icon={icon({ name: 'circle-info', style: 'solid' })} />}
                    description={
                        <Text color="--neutral700" textAlign="left">
                            <FormattedMessage
                                defaultMessage="Pour pouvoir diffuser des épisodes de plus de 15 min, YouTube doit effectuer une vérification de votre compte. Si ce n’est pas déjà fait, <link>vérifiez votre compte YouTube.</link>"
                                values={{
                                    link: (chunks: React.ReactNode) => (
                                        <UnderlinedLink
                                            href="https://www.youtube.com/verify"
                                            target="_blank"
                                        >
                                            {chunks}
                                        </UnderlinedLink>
                                    ),
                                }}
                            />
                        </Text>
                    }
                >
                    <Text fontWeight="--fw-semibold" textAlign="left">
                        <FormattedMessage defaultMessage="À propos de la diffusion sur YouTube" />
                    </Text>
                </Alert>
            </Stack>
        </DialogAlert>
    );
};

const UnderlinedLink = styled(ExternalLink)`
    color: var(--neutral700);
    text-decoration: underline;
    font-size: var(--fs-body-m);
`;
const InfoIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
`;
const CancelButton = styled(Button)`
    color: var(--neutral500);
`;

export default BroadcastYoutubeLoginModal;
