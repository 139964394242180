import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const useCountdown = (targetDate) => {
    const [countDown, setCountDown] = useState(dayjs.utc(targetDate).local().diff(dayjs.utc()));

    useEffect(() => {
        const interval = setInterval(() => {
            setCountDown(dayjs.utc(targetDate).local().diff(dayjs.utc()));
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return getCountdownValues(countDown);
};

const getCountdownValues = (countDown) => {
    const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
    const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

    return [days, hours, minutes, seconds];
};

export { useCountdown };
