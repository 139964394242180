import { createContext, useContext } from 'react';
import { useParams } from 'react-router-dom';
import useEpisodeTranscriptQuery from '@queries/episodeTranscript/useEpisodeTranscriptQuery.hook';
import useEpisodeTranscriptStatusQuery from '@queries/episodeTranscript/useEpisodeTranscriptStatusQuery.hook';
import { EPISODE_TRANSCRIPT_STATUS } from '@/shared/config/constants';
import { useQueryClient } from '@tanstack/react-query';
import episodeTranscriptKeys from '@queries/episodeTranscript/episodeTranscriptKeys';
import PropTypes from 'prop-types';
import useHasAccessToTranscription from '@/shared/hooks/useHasAccessToTranscription.hook';

const EpisodeTranscriptionContext = createContext();
const EpisodeTranscriptionStatusContext = createContext();
const EpisodeTranscriptionFilesContext = createContext();

const EpisodeEditTranscriptionProvider = (props) => {
    const { children } = props;
    const { showId } = useParams();
    const hasAccessToTranscription = useHasAccessToTranscription({ showId });

    if (!hasAccessToTranscription) return children;

    return <Provider {...props} />;
};

const Provider = ({ children }) => {
    const { episodeId } = useParams();
    const queryClient = useQueryClient();
    const episodeTranscript = useEpisodeTranscriptQuery(episodeId);
    const { data: episodeTranscriptStatus } = useEpisodeTranscriptStatusQuery(episodeId);

    /*
        If the episode transcript status is DONE or UPDATED, invalidate the episode transcript query
        so that the transcript is fetched again.
    */
    if (
        !episodeTranscript.data &&
        (episodeTranscriptStatus?.status === EPISODE_TRANSCRIPT_STATUS.DONE ||
            episodeTranscriptStatus?.status === EPISODE_TRANSCRIPT_STATUS.UPDATED)
    ) {
        queryClient.invalidateQueries({
            queryKey: episodeTranscriptKeys.detailByEpisodeId(episodeId),
        });
    }

    return (
        <EpisodeTranscriptionContext.Provider value={episodeTranscript}>
            <EpisodeTranscriptionStatusContext.Provider value={episodeTranscriptStatus}>
                <EpisodeTranscriptionFilesContext.Provider value={episodeTranscriptStatus?.files}>
                    {children}
                </EpisodeTranscriptionFilesContext.Provider>
            </EpisodeTranscriptionStatusContext.Provider>
        </EpisodeTranscriptionContext.Provider>
    );
};

export const useEpisodeTranscription = () => {
    return useContext(EpisodeTranscriptionContext);
};

export const useEpisodeTranscriptionStatus = () => {
    return useContext(EpisodeTranscriptionStatusContext);
};

export const useEpisodeTranscriptionFiles = () => {
    return useContext(EpisodeTranscriptionFilesContext);
};

EpisodeEditTranscriptionProvider.propTypes = {
    children: PropTypes.node,
};

export default EpisodeEditTranscriptionProvider;
