import { computed } from 'mobx';
import Model from './Model';
import { noDataReplaceBy } from './resolvers';

class PodcastRankModel extends Model {
    static attributes = {
        downloads: 'downloads',
        name: 'name',
        id: 'podcast_id',
        averageCompletionRate: noDataReplaceBy('avg_completion_rate', '-'),
        averageListeningTime: noDataReplaceBy('avg_listening_time', '-'),
    };

    @computed
    get podcast() {
        return this.state.podcastStore.byId(this.id);
    }
}

export default PodcastRankModel;
