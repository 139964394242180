const episodeListeningLinkKeys = {
    all: () => [{ entity: 'episodeListeningLink' }],
    allLists: () => [{ ...episodeListeningLinkKeys.all()[0], scope: 'list' }],
    listByEpisodeId: (rawEpisodeId: string) => [
        { ...episodeListeningLinkKeys.allLists()[0], episodeId: Number(rawEpisodeId) },
    ],
    allDetails: () => [{ ...episodeListeningLinkKeys.all()[0], scope: 'detail' }],
    detailByEpisodeId: (rawEpisodeId: string) => [
        { ...episodeListeningLinkKeys.allDetails()[0], episodeId: Number(rawEpisodeId) },
    ],
};

export default episodeListeningLinkKeys;
