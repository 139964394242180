import PropTypes from 'prop-types';
import Text from '@ui/atoms/Text';
import Stack from '@ui/layout/Stack';
import { commonMessages } from '@/helpers';

const ClipCoverMaxSizeIndications = ({ isFullCover, currentFormat }) => {
    return (
        <Stack>
            <Text variant="footnote" textAlign="center" color="--neutral500" size="small">
                {commonMessages('clipCover', isFullCover ? currentFormat : 'square')}
            </Text>
            <Text variant="footnote" textAlign="center" color="--neutral500" size="small">
                {commonMessages('clipCover', 'format')}
            </Text>
        </Stack>
    );
};

ClipCoverMaxSizeIndications.propTypes = {
    isFullCover: PropTypes.bool.isRequired,
    currentFormat: PropTypes.oneOf(['square', 'story', 'wide']).isRequired,
};

export default ClipCoverMaxSizeIndications;
