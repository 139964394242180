import CampaignAutoEditPage from '@/components/app/pages/CampaignAutoEditPage';
import CampaignSponsorshipEditPage from '@/components/app/pages/CampaignSponsorshipEditPage';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import { USER_ROLES } from '@/shared/config/constants';
import { STATUS_SHOWS } from '@/shared/config/statusShows';
import { useGuard } from '@/shared/hooks/useGuard.hook';
import { Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router';
import Layout from './Layout';
import ManualCampaignsPage from './ManualCampaignsPage';
import { SelectionContextProvider } from '../../services/SelectionContext';

const CampaignsPage = () => {
    const { path } = useRouteMatch();
    const { showId } = useParams<{ showId: string }>();
    const { data: show } = useShowQuery(showId);
    const isFrench = show?.languageId === 47;
    const hasPermission = useGuard({
        disallowedShowStatuses: [STATUS_SHOWS.ARCHIVED_SHOWS],
        disallowedUserRoles: [USER_ROLES.VIEWER],
    });

    if (!hasPermission.allow) {
        return <Redirect to={`/app/show/${showId}/episodes`} />;
    }

    return (
        <Layout>
            <Switch>
                <Route path={`${path}/auto`}>
                    {isFrench ? <CampaignAutoEditPage /> : <CampaignSponsorshipEditPage />}
                </Route>
                <Route path={`${path}/manual`} exact>
                    <SelectionContextProvider>
                        <ManualCampaignsPage />
                    </SelectionContextProvider>
                </Route>
            </Switch>
        </Layout>
    );
};

export default CampaignsPage;
