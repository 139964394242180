import Nav from './Nav';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

interface LayoutProps {
    children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
    return (
        <LayoutWrapper>
            <InnerWrapper>
                <Sidebar>
                    <Title>
                        <FormattedMessage defaultMessage="Statistiques" />
                    </Title>
                    <Nav />
                </Sidebar>
                <ContentWrapper>{children}</ContentWrapper>
            </InnerWrapper>
        </LayoutWrapper>
    );
};

const LayoutWrapper = styled.div`
    width: 100%;
    padding-inline: var(--container-padding-mobile);
    margin-block: 2rem;
    display: flex;
    justify-content: center;

    ${({ theme }) => theme.mediaQueries.desktopXLAndUp} {
        padding-inline: var(--container-padding);
    }
`;
const InnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    max-width: var(--container-width);
    width: 100%;

    ${({ theme }) => theme.mediaQueries.desktopXLAndUp} {
        flex-direction: row;
    }
`;
const ContentWrapper = styled.div`
    display: flex;
    height: max-content;
    flex-direction: column;
    gap: 1rem;
    flex-grow: 1;
    min-width: 0;
`;
const Sidebar = styled.aside`
    display: flex;
    flex-direction: column;
    height: fit-content;
    gap: 1rem;

    ${({ theme }) => theme.mediaQueries.desktopXLAndUp} {
        width: 15rem;
        gap: 2rem;
        position: sticky;
        top: 8.5rem;
        left: 0;
        flex-shrink: 0;
    }
`;
const Title = styled.h1`
    font-weight: var(--fw-bold);
    font-size: var(--fs-heading-l);
    line-height: 1.4rem;
`;

export default Layout;
