import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import UnsavedFormModal from '../unorganized/UnsavedFormModal';
import useNonHostingEpisodeFormSchema, {
    NonHostingEpisodeFormSchema,
} from './useNonHostingEpisodeFormSchema.hook';

import { useEpisodeBeingEdited } from '@/context/EpisodeFormContext';
import { zodResolver } from '@hookform/resolvers/zod';
import SaveDraftAndCloseButton from './actions/SaveDraftAndCloseButton';
import NonHostingEpisodeFormAccordion from './NonHostingEpisodeFormAccordion';
import NonHostingEpisodeFormSidebar from './NonHostingEpisodeFormSidebar';
import { TranscriptionContextProvider } from './contexts/NonHostingTranscriptionContext';
import EpisodeAILoadingContextProvider from './contexts/NonHostingEpisodeAILoadingContext';
import EpisodeAISettingsContextProvider from './contexts/NonHostingEpisodeAISettingsContext';
import EpisodeAIBubble from './NonHostingEpisodeAI/EpisodeAIBubble';
import SummarizeButton from './actions/SummarizeButton';
import { Chapter } from '@/queries/chapter/useChaptersQuery.hook';
import { EpisodeMesssages } from '@/queries/episode/useEpisodeMessagesQuery';
import { PsoCheckerContextProvider } from './contexts/NonHostingPsoCheckerContext';

interface NonHostingEpisodeFormProps {
    chapters: Chapter[];
    messages: EpisodeMesssages;
}

const NonHostingEpisodeForm = ({ chapters, messages }: NonHostingEpisodeFormProps) => {
    const episode = useEpisodeBeingEdited();
    const schema = useNonHostingEpisodeFormSchema();

    const { newsletter, ...socialPosts } = messages;

    const methods = useForm<NonHostingEpisodeFormSchema>({
        mode: 'onBlur',
        defaultValues: {
            content: {
                name: episode?.name ?? '',
                htmlDescription: episode?.htmlDescription ?? '',
                tags: (episode?.tags?.map((tag) => tag.name) ?? []) as string[],
            },
            chapters,
            newsletter,
            socialPosts,
            _meta: {
                plainTextDescription: episode?.description,
                plainTextNewsletterMessage: newsletter.message,
            },
        },
        resolver: zodResolver(schema),
    });

    const { formState } = methods;
    const { isDirty } = formState;

    return (
        <FormProvider {...methods}>
            <EpisodeAILoadingContextProvider>
                <EpisodeAISettingsContextProvider>
                    <PsoCheckerContextProvider>
                        <TranscriptionContextProvider>
                            <FullPageContainer>
                                <Header>
                                    <SaveDraftAndCloseButton />
                                    <DesktopSummarizeButton />
                                </Header>
                                <Content>
                                    <ContentInner>
                                        <NonHostingEpisodeFormSidebar />
                                        <NonHostingEpisodeFormAccordion />
                                    </ContentInner>
                                </Content>
                                <Footer>
                                    <MobileSummarizeButton />
                                    <EpisodeAIBubble />
                                </Footer>
                            </FullPageContainer>
                            <UnsavedFormModal shouldBlockNavigation={isDirty} />
                        </TranscriptionContextProvider>
                    </PsoCheckerContextProvider>
                </EpisodeAISettingsContextProvider>
            </EpisodeAILoadingContextProvider>
        </FormProvider>
    );
};

const FullPageContainer = styled.form`
    background-color: var(--neutral50);
    display: flex;
    flex-direction: column;
    height: 100%;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        background-color: var(--white);
    }
`;
const Header = styled.header`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;
    padding: 1rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        padding: 2rem;
    }
`;
const Content = styled.div`
    flex-grow: 1;
    min-height: 0;
    overflow-y: auto;
    padding: 0 1rem 1rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: flex;
        justify-content: center;
        background-color: var(--neutral50);
        border-radius: var(--r-l) var(--r-l) 0 0;
        margin-inline: 2rem;
        padding: 2rem;
    }
`;
const ContentInner = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem 2rem;
    width: 100%;

    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        flex-direction: row;
        max-width: calc(var(--container-width) - 4rem);
    }
`;
const Footer = styled.footer`
    position: relative;
    background-color: var(--white);
    border-top: 1px solid var(--neutral100);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5rem 1rem;
    padding: 1rem;

    > * {
        flex-grow: 1;
    }

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: none;
    }
`;
const MobileSummarizeButton = styled(SummarizeButton)`
    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: none;
    }
`;
const DesktopSummarizeButton = styled(SummarizeButton)`
    display: none;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: inline-flex;
    }
`;

export default NonHostingEpisodeForm;
