import { FormattedMessage } from 'react-intl';
import Center from '../../../../ui/layout/Center';
import WithTranscript from '@public/images/clip-edit-with-transcript.svg';
import Option from './Option';
import { connect } from '@app/decorators/connect';
import Stack from '../../../../ui/layout/Stack';
import { resolveColor } from '@/utils/cssVariables';
import styled from 'styled-components';
import Text from '@ui/atoms/Text';
import DeprecatedSelect from '../../../../ui/molecules/DeprecatedSelect';

const enhance = connect(({ clipStore }) => ({
    supportedLanguages: clipStore.transcriptionSupportedLanguages,
    isLoading: clipStore.fetchTranscriptionSupportedLanguages.pending,
}));

const WithTranscriptOption = ({
    selected,
    values,
    setFieldValue,
    supportedLanguages,
    isLoading,
}) => (
    <Stack $gap="1rem">
        <Block
            selected={selected}
            onClick={() => setFieldValue('transcript', true)}
            backgroundColor={resolveColor('--primary50')}
            borderColor={resolveColor('--primary500')}
        >
            <Stack $gap="1rem">
                <Center>
                    <IllustrationWithTranscript />
                </Center>
                <Stack $gap="0.5rem">
                    <Text variant="subheading" fontWeight="--fw-bold" textAlign="center">
                        <FormattedMessage defaultMessage="Avec transcription" />
                    </Text>
                    <Text textAlign="center">
                        <FormattedMessage defaultMessage="Le clip vidéo avec transcription automatique et modifiable." />
                    </Text>
                </Stack>
            </Stack>
        </Block>
        <DeprecatedSelect
            label={<FormattedMessage defaultMessage="Langue" />}
            source={supportedLanguages}
            value={values.lang}
            onChange={(lang) => setFieldValue('lang', lang)}
            disabled={isLoading}
            style={{ visibility: selected ? 'visible' : 'hidden' }}
        />
    </Stack>
);

const Block = styled(Option)`
    padding: 2rem 1rem;
`;

const IllustrationWithTranscript = styled(WithTranscript)`
    width: 12rem;
`;

export default enhance(WithTranscriptOption);
