import { useParams } from 'react-router';
import { FormattedMessage } from 'react-intl';
import AddRssImportModal from '@/components/unorganized/AddRssImportModal';
import { USER_OPTIONS } from '@/shared/config/userOptions';
import { useOverlayTriggerState } from 'react-stately';
import useShowQuery from '@queries/show/useShowQuery.hook';
import type { RssFeed } from '@queries/rss/useRssFeedQuery.hook';
import useUpdateRssFeedMutation from '@queries/rss/useUpdateRssFeedMutation.hook';
import InputText from '@ui/atoms/InputText';
import Button from '@/components/Button';
import Toggle from '@ui/atoms/Toggle';
import Tooltip, { TooltipTriggerWrapper } from '@ui/Tooltip';
import { TooltipTrigger } from 'react-aria-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
// @ts-ignore
import { IntercomAPI } from 'react-intercom';
import styled from 'styled-components';

interface RssImportInfoProps {
    rssFeed: RssFeed;
}

const RssImportInfo = ({ rssFeed }: RssImportInfoProps) => {
    const { showId } = useParams<{ showId: string }>();
    const { data: show } = useShowQuery(showId);

    const canImportRssAuto = show?.userOptions.find(
        (option) => option === USER_OPTIONS.IMPORT_RSS_AUTO,
    );

    const updateRssFeed = useUpdateRssFeedMutation();

    const toggleAutoImport = () => {
        updateRssFeed.mutate({ showId, payload: { auto: !rssFeed.auto } });
    };

    let rssImportModalState = useOverlayTriggerState({});

    return (
        <>
            {rssImportModalState.isOpen && (
                <AddRssImportModal
                    isOpen={rssImportModalState.isOpen}
                    onClose={rssImportModalState.close}
                    isEdit
                />
            )}
            <RssImportInfoWrapper>
                <RssInputWrapper>
                    <InputText
                        value={rssFeed.url}
                        isDisabled={true}
                        label={
                            <FormattedMessage defaultMessage="Lien RSS de l’émission à importer" />
                        }
                    />
                    <Button onPress={rssImportModalState.open} variant="tertiary">
                        <FormattedMessage defaultMessage="Modifier le lien RSS" />
                    </Button>
                </RssInputWrapper>
                {canImportRssAuto ? (
                    <Toggle
                        label={<FormattedMessage defaultMessage="Synchronisation automatique" />}
                        isSelected={rssFeed.auto}
                        onChange={toggleAutoImport}
                    >
                        <FormattedMessage defaultMessage="Synchroniser automatiquement vos épisodes dès qu’ils sont disponibles via le flux RSS." />
                    </Toggle>
                ) : (
                    <Toggle
                        label={
                            <TooltipTrigger delay={0} closeDelay={100}>
                                <TooltipTriggerWrapper>
                                    <LabelInnerWrapper>
                                        <FormattedMessage defaultMessage="Synchronisation automatique" />
                                        <HelpIcon
                                            icon={icon({
                                                name: 'info-circle',
                                                style: 'solid',
                                            })}
                                        />
                                    </LabelInnerWrapper>
                                </TooltipTriggerWrapper>
                                <Tooltip placement="top">
                                    <FormattedMessage
                                        defaultMessage="La synchronisation automatique via le flux RSS est une option payante. Pour en savoir plus, veuillez <intercom>contacter le support</intercom>."
                                        values={{
                                            intercom: (chunks: React.ReactNode) => (
                                                // For some dark reason, you can't put a <Button /> here or it will break the tooltip's placement.
                                                <WhiteLink
                                                    href="javascript:"
                                                    onClick={() => IntercomAPI('show')}
                                                >
                                                    {chunks}
                                                </WhiteLink>
                                            ),
                                        }}
                                    />
                                </Tooltip>
                            </TooltipTrigger>
                        }
                        isSelected={rssFeed.auto}
                        onChange={() => {}}
                        isDisabled
                    >
                        <FormattedMessage defaultMessage="Synchroniser automatiquement vos épisodes dès qu’ils sont disponibles via le flux RSS." />
                    </Toggle>
                )}
            </RssImportInfoWrapper>
        </>
    );
};

const RssImportInfoWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding);

    ${({ theme }) => theme.mediaQueries.mobile} {
        padding: var(--container-padding-mobile);
    }
`;
const RssInputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.5rem;
`;
const HelpIcon = styled(FontAwesomeIcon)`
    color: var(--black);
`;
const LabelInnerWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;
const WhiteLink = styled.a`
    color: inherit;
    font-weight: inherit;
    font-size: inherit;
    text-decoration: underline;
`;

export default RssImportInfo;
