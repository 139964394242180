import Model from './Model';

class SourceCategoryModel extends Model {
    static attributes = {
        category: 'category',
        count: 'count',
        percent: 'percentage',
    };
}

export default SourceCategoryModel;
