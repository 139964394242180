import { FormattedMessage } from 'react-intl';
import { connect } from '@app/decorators/connect';
import Pagination from '../../ui/molecules/Pagination';
import TableArrow from '../../ui/atoms/TableArrow';
import { useToggle } from '@hooks/useToggle';
import DeprecatedWhitePaper from '../../ui/atoms/DeprecatedWhitePaper';
import PaperGrid from '../../ui/atoms/PaperGrid';
import Column from '../../ui/atoms/Column';
import ShortListFallback from '../fallbacks/ShortListFallback';
import { round } from '../../../helpers';
import SourcesExportButton from '../molecules/SourcesExportButton';
import DeprecatedPaper from '../../ui/atoms/DeprecatedPaper';
import FetcherSourcePlatforms from '../fetchers/stats/FetcherSourcePlatforms';
import { useStatsDateSelectorContext } from '@/context/StatsDateSelectorContext';

const enhance = connect(({ sourcesStatsStore, routerStore }) => ({
    platforms: sourcesStatsStore.platforms,
    showId: routerStore.params.showId,
    itemType: routerStore.params.itemType,
    itemId: routerStore.params.itemId,
    pagination: sourcesStatsStore.platformsPagination,
    episodeQuery: routerStore.query.episode,
}));

function SourcesPlatformsTable({ platforms, showId, itemType, itemId, pagination, episodeQuery }) {
    const { startDate, endDate } = useStatsDateSelectorContext();
    const [order, toggleOrder] = useToggle('desc', 'asc');
    return (
        <>
            <DeprecatedWhitePaper my={25}>
                <PaperGrid column={4} py={8} px={20}>
                    <Column startColumn={1} endColumn={3} color="--neutral500">
                        <FormattedMessage defaultMessage="Plateforme" />
                    </Column>
                    <Column
                        startColumn={3}
                        endColumn={4}
                        justify="flex-end"
                        color="--neutral500"
                        onClick={toggleOrder}
                    >
                        <FormattedMessage defaultMessage="Écoutes" />
                        <TableArrow order={order} ml={10} />
                    </Column>
                    <Column startColumn={4} endColumn={5} color="--neutral500" justify="flex-end">
                        <FormattedMessage defaultMessage="Pourcentage" />
                    </Column>
                </PaperGrid>

                <FetcherSourcePlatforms
                    showId={showId}
                    itemType={itemType}
                    itemId={itemId}
                    start={startDate}
                    end={endDate}
                    page={pagination.currentPage}
                    order={order}
                    fallback={ShortListFallback}
                    episodes={episodeQuery}
                >
                    <>
                        {platforms.map((p) => (
                            <PaperGrid
                                column={4}
                                py={15}
                                px={20}
                                key={p.category}
                                bt={1}
                                border="--neutral100"
                            >
                                <Column startColumn={1} endColumn={3} ellipsis>
                                    {p.category}
                                </Column>
                                <Column startColumn={3} endColumn={4} justify="flex-end">
                                    {p.count}
                                </Column>
                                <Column startColumn={4} endColumn={5} justify="flex-end">
                                    {`${round(p.percent)}%`}
                                </Column>
                            </PaperGrid>
                        ))}
                    </>
                </FetcherSourcePlatforms>
            </DeprecatedWhitePaper>
            <DeprecatedPaper position="relative">
                <Pagination
                    mb={25}
                    pagination={pagination}
                    plural={<FormattedMessage defaultMessage="plateformes" />}
                    singular={<FormattedMessage defaultMessage="plateforme" />}
                >
                    <SourcesExportButton />
                </Pagination>
            </DeprecatedPaper>
        </>
    );
}

export default enhance(SourcesPlatformsTable);
