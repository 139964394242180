import { useRef } from 'react';
import ClipEditWizard, { ClipEditTitle } from '../ClipEditWizard';
import { connect } from '@/components/legacy/connect';
import ContinueLaterModal from './ContinueLaterModal';
import { useAmplitudeLogEvent } from '@/shared/hooks/useAmplitude';
import DeprecatedModal from '@ui/molecules/DeprecatedModal';
import { useOverlayTriggerState } from 'react-stately';

const enhance = connect(({ podcastStore }, { episodeId, clip }) => {
    const episode = episodeId ? podcastStore.byId(episodeId) : null;
    if (!episode) {
        return { episode: null, clip: null };
    }

    return {
        episode,
        clip: clip || episode.createClip(),
    };
});

const ClipEditWizardModal = ({ episode, clip, isOpened, onClose, onClipGenerate }) => {
    useAmplitudeLogEvent('show clip', {}, !!isOpened);
    let continueLaterModalState = useOverlayTriggerState({});
    const formRef = useRef();

    const handleOnContinueLater = async () => {
        if (formRef.current) {
            // TODO: Find a way to submit form
            continueLaterModalState.close();
            onClose();
        }
    };

    if (!episode || !clip) {
        return null;
    }

    return (
        <DeprecatedModal
            isOpened={isOpened}
            onClose={continueLaterModalState.open}
            title={<ClipEditTitle clip={clip} />}
            fullscreen
        >
            <ClipEditWizard
                episode={episode}
                clip={clip}
                onClose={onClose}
                onClipGenerate={onClipGenerate}
                formRef={formRef}
            />
            <ContinueLaterModal
                isOpened={continueLaterModalState.isOpen}
                onContinueLater={handleOnContinueLater}
                onClose={continueLaterModalState.close}
            />
        </DeprecatedModal>
    );
};

export default enhance(ClipEditWizardModal);
