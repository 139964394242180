import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchChannelGuestInvitations } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import guestKeys from './guestKeys';
import { z } from 'zod';
import { ChannelGuestRoleSchema } from './useChannelGuestPermissionsQuery.hook';

export const ChannelGuestInvitationSchema = z.object({
    email: z.string(),
    role: ChannelGuestRoleSchema,
});

const ChannelGuestInvitationsSchema = z.array(ChannelGuestInvitationSchema);

type Key = QueryFunctionContext<ReturnType<typeof guestKeys.invitationListByChannelId>>;

export type ChannelGuestInvitation = z.infer<typeof ChannelGuestInvitationSchema>;

const queryFn = async ({ queryKey: [{ channelId }] }: Key) => {
    const { data } = await fetchChannelGuestInvitations(channelId);
    return ChannelGuestInvitationsSchema.parse(camelcaseKeys(data, { deep: true }));
};

interface OptionProps {
    enabled?: boolean;
}

const useChannelGuestInvitationsQuery = (
    channelId: number | undefined,
    options: OptionProps = {},
) => {
    return useQuery({
        queryKey: guestKeys.invitationListByChannelId(channelId),
        queryFn,
        ...options,
    });
};

export default useChannelGuestInvitationsQuery;
