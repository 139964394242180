import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import Cluster from '@ui/layout/Cluster';
import Stack from '@ui/layout/Stack';
import { Marker, Region, WaveForm, WaveSurfer } from 'wavesurfer-react';
import RegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions.min';
import MinimapPlugin from 'wavesurfer.js/src/plugin/minimap';
import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.min';
import CursorPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.cursor.min';
import Slider from '@ui/atoms/Slider';
import { extractCssVariable, resolveColor } from '@/shared/utils/cssVariable';
import { CLIP_MAX_LENGTH, CLIP_MIN_LENGTH } from '@/shared/config/constants';
import IconZoomOut from '@ui/icons/IconZoomOut';
import IconZoomIn from '@ui/icons/IconZoomIn';
import IconButton from '@ui/atoms/IconButton';
import MarkersPlugin from 'wavesurfer.js/src/plugin/markers';
import MarkerImg from '@public/images/waveform_marker.png';
import SelectedMarkerImg from '@public/images/waveform_selected_marker.png';

const ZOOM_MIN_VALUE = 4;
const ZOOM_MAX_VALUE = 128;

const AudioWaveform = ({
    audioUrl,
    waveformDataUrl,
    isPlaying,
    onGoBackToStart,
    duration,
    onDurationChange,
    onCurrentTimeChange,
    cursorPosition,
    cursorPositionHasChanged,
    regionStart,
    regionEnd,
    onRegionChange,
    chapters,
    selectedChapter,
    onChapterSelect,
    onChapterUpdate,
    noRegion,
}) => {
    const wavesurferRef = useRef();
    const plugins = useMemo(() => {
        return [
            {
                plugin: CursorPlugin,
                options: {
                    showTime: true,
                    container: wavesurferRef,
                    opacity: 1,
                    color: resolveColor('--primary200'),
                    customShowTimeStyle: {
                        background: resolveColor('--primary100'),
                        color: resolveColor('--primary'),
                        padding: '0 4px',
                        margin: '4px',
                        top: 0,
                        height: '18px',
                        'border-radius': extractCssVariable('--r-xs'),
                        'font-size': '12px',
                        'text-align': 'center',
                    },
                },
            },
            {
                plugin: RegionsPlugin,
                options: {
                    dragSelection: false,
                },
            },
            {
                plugin: MinimapPlugin,
                options: {
                    container: '#minimap',
                    height: 48,
                    showRegions: true,
                    showOverview: true,
                    overviewBorderSize: 2,
                    overviewBorderColor: resolveColor('--neutral200'),
                },
            },
            {
                plugin: TimelinePlugin,
                options: {
                    container: '#timeline',
                    height: 10,
                    notchPercentHeight: 0,
                    primaryColor: resolveColor('--neutral200'),
                    secondaryColor: resolveColor('--neutral200'),
                    primaryFontColor: resolveColor('--neutral500'),
                    secondaryFontColor: resolveColor('--neutral500'),
                },
            },
            {
                plugin: MarkersPlugin,
            },
        ];
    }, []);

    const normalize = (dataArray) => {
        const maxValue = dataArray.reduce((value1, value2) => {
            return Math.max(value1, value2);
        }, 0);

        return dataArray.map((value) => value / maxValue).map((value) => value * 0.8);
    };

    const handleWSMount = useCallback(async (waveSurfer) => {
        wavesurferRef.current = waveSurfer;

        if (!wavesurferRef.current) {
            return;
        }

        const waveformDataResponse = await fetch(waveformDataUrl);

        if (!waveformDataResponse.ok) {
            throw new Error(`Error while fetching waveform data`);
        }

        const waveformDataBuffer = await waveformDataResponse.arrayBuffer();
        const waveformDataArray = Float64Array.from(new Int16Array(waveformDataBuffer));
        const normalizedDataArray = normalize(waveformDataArray);

        // Wavesurfer may be destroyed the second time user go to episode page, prevent loading in this case
        // and waiting for Wavesurfer to re-init without being destroyed
        if (!wavesurferRef.current.isDestroyed) {
            wavesurferRef.current.load(audioUrl, normalizedDataArray);
        }

        wavesurferRef.current.on('ready', handleAudioFileTotalDuration);
        wavesurferRef.current.on('audioprocess', handleCurrentTime);
        wavesurferRef.current.on('interaction', handleCursorPositionUpdate);
        wavesurferRef.current.on('region-update-end', handleRegionUpdate);
    }, []);

    const [currentZoomLevel, setCurrentZoomLevel] = useState(ZOOM_MIN_VALUE);

    const handleZoomIn = () => {
        const nextValue = currentZoomLevel * 2;
        const newZoomValue = Math.min(nextValue, ZOOM_MAX_VALUE);
        wavesurferRef.current.zoom(newZoomValue);
        setCurrentZoomLevel(newZoomValue);
    };

    const handleZoomOut = () => {
        const nextValue = currentZoomLevel / 2;
        const newZoomValue = Math.max(nextValue, ZOOM_MIN_VALUE);
        wavesurferRef.current.zoom(newZoomValue);
        setCurrentZoomLevel(newZoomValue);
    };

    const handleZoomSlider = (value) => {
        wavesurferRef.current.zoom(value);
        setCurrentZoomLevel(value);
    };

    const handlePlayPause = () => {
        return wavesurferRef.current.playPause();
    };

    const handleGoBackToStart = () => {
        return wavesurferRef.current.seekTo(0);
    };

    const handleCursorPositionUpdated = () => {
        wavesurferRef.current.seekTo(0);
        return wavesurferRef.current.skip(cursorPosition);
    };

    const handleAudioFileTotalDuration = () => {
        return onDurationChange(wavesurferRef.current?.getDuration());
    };

    const handleCurrentTime = () => {
        return onCurrentTimeChange(wavesurferRef.current?.getCurrentTime());
    };

    const handleCursorPositionUpdate = () => {
        // Need this `setTimeout` because this event is trigger before the cursor position is updated
        return setTimeout(() => {
            onCurrentTimeChange(wavesurferRef.current?.getCurrentTime());
        }, 0);
    };

    const handleRegionUpdate = (event) => {
        return onRegionChange(event.start, event.end);
    };

    useEffect(handlePlayPause, [isPlaying]);
    useEffect(handleGoBackToStart, [onGoBackToStart]);
    useEffect(handleCursorPositionUpdated, [cursorPositionHasChanged]);

    return (
        <GlobalContainer $gap="1rem">
            <Zoom>
                <ZoomButton
                    variant="ghost"
                    icon={<IconZoomOut color="--neutral500" size="1rem" />}
                    onPress={handleZoomOut}
                />
                <SliderContainer>
                    <Slider
                        aria-label="zoom"
                        minValue={ZOOM_MIN_VALUE}
                        maxValue={ZOOM_MAX_VALUE}
                        value={currentZoomLevel}
                        onChange={handleZoomSlider}
                    />
                </SliderContainer>
                <ZoomButton
                    variant="ghost"
                    icon={<IconZoomIn color="--neutral500" size="1rem" />}
                    onPress={handleZoomIn}
                />
            </Zoom>
            <MinimapContainer id="minimap" />
            <Stack>
                <div id="timeline" />
                <WaveformContainer>
                    <WaveSurfer plugins={plugins} onMount={handleWSMount} backend="MediaElement">
                        <WaveForm
                            id="waveform"
                            progressColor={resolveColor('--neutral200')}
                            waveColor={resolveColor('--neutral200')}
                            cursorColor={resolveColor('--primary')}
                            cursorWidth={2}
                            hideScrollBar
                            height={160}
                            barWidth={2}
                            barGap={2}
                            barRadius={3}
                        >
                            {!noRegion && (
                                <Region
                                    id="region"
                                    start={regionStart || 0}
                                    end={regionEnd || Math.min(duration, 30)}
                                    minLength={CLIP_MIN_LENGTH}
                                    maxLength={
                                        Math.min(duration, CLIP_MAX_LENGTH) || CLIP_MAX_LENGTH
                                    }
                                    color={`${resolveColor('--primary')}1A`}
                                />
                            )}
                            {chapters?.map((chapter) => {
                                const markerElement = new Image(24, 28);
                                markerElement.src =
                                    selectedChapter === chapter.id ? SelectedMarkerImg : MarkerImg;
                                // TODO: Use chapter.startTime only when ChapterModel is removed
                                const startTime = chapter.time ?? chapter.startTime;
                                return (
                                    <Marker
                                        key={`${chapter.id}${startTime}${chapter.number}${selectedChapter}`}
                                        time={startTime}
                                        label={`#${chapter.number}`}
                                        draggable={true}
                                        onClick={() => onChapterSelect(chapter.id)}
                                        onDrop={(marker) =>
                                            onChapterUpdate(chapter.id, marker.time)
                                        }
                                        markerElement={markerElement}
                                    />
                                );
                            })}
                        </WaveForm>
                    </WaveSurfer>
                </WaveformContainer>
            </Stack>
        </GlobalContainer>
    );
};

const GlobalContainer = styled(Stack)`
    isolation: isolate;

    canvas {
        max-width: initial;
    }

    overview {
        border-radius: var(--r-s);
    }

    .wavesurfer-region {
        border-block: 2px solid var(--primary);
    }

    .wavesurfer-handle {
        background-color: var(--primary) !important;
        width: 16px !important;
        height: calc(100% + 4px) !important;
        margin-top: -2px;
    }

    .wavesurfer-handle-start {
        border-radius: var(--r-s) 0 0 var(--r-s);
    }

    .wavesurfer-handle-end {
        border-radius: 0 var(--r-s) var(--r-s) 0;
    }

    .wavesurfer-handle::before {
        content: '';
        background: var(--primary300);
        border-radius: var(--r-full);
        position: absolute;
        top: calc(50% - 16px);
        left: calc(50% - 2px);
        width: 4px;
        height: 32px;
    }

    .wavesurfer-handle:first-of-type {
        margin-left: -16px;
    }

    .wavesurfer-handle:last-of-type {
        margin-right: -16px;
    }

    .wavesurfer-marker > div:nth-child(1) {
        background: var(--info500) !important;
        width: 2px !important;
        opacity: 1 !important;
        z-index: 4;
    }

    .marker-label {
        z-index: 4;

        img {
            max-inline-size: fit-content;
        }

        span {
            color: var(--info500);
            font-weight: bold;
            position: relative;
            left: -50%;
            top: 3px;
            z-index: 4;
        }
    }
`;

const WaveformContainer = styled.div`
    background: var(--neutral50);
    border-radius: var(--r-l);
`;

const MinimapContainer = styled.div`
    background: var(--neutral50);
    border-radius: var(--r-s);
    width: 100%;

    wave {
        overflow: hidden !important;
    }

    region {
        border-radius: var(--r-s);
    }
`;

const SliderContainer = styled.div`
    width: 7.5rem;
`;

const Zoom = styled(Cluster)`
    justify-content: flex-end;
    align-items: center;
    flex-wrap: nowrap;
    gap: 0.5rem;
`;

const ZoomButton = styled(IconButton)`
    padding: 0;
`;

export default AudioWaveform;
