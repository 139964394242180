import { FormattedMessage } from 'react-intl';
import { TABS_STATS_NAME } from '@app/pages/StatsPage';
import styled from 'styled-components';
import { useStatsDateSelectorContext } from '@/context/StatsDateSelectorContext';
import useGendersQuery from '@queries/stats/useGendersQuery.hook';
import { useParams } from 'react-router';
import Tooltip, { TooltipTriggerWrapper } from '@ui/Tooltip';
import Text from '@ui/atoms/Text';
import { TooltipTrigger } from 'react-aria-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import AudienceGendersChart from './AudienceGendersChart';
import DevicesFallback from '../unorganized/DevicesFallback';
import StatsDataSetSelector from '../unorganized/StatsDataSetSelector';

const AudienceGenders = () => {
    const { showId } = useParams<{ showId: string }>();
    const { startDate, endDate } = useStatsDateSelectorContext();
    const { data: genders } = useGendersQuery({
        showId,
        query: { start: startDate, end: endDate },
    });

    if (genders) {
        return (
            <>
                <StatsDataSetSelector
                    tabTitle={<FormattedMessage defaultMessage="Audience" />}
                    subTitle={
                        <FormattedMessage defaultMessage="Quelle est la répartition de votre audience par genre (Deezer uniquement) ?" />
                    }
                    titleTooltip={
                        <TooltipTrigger delay={0} closeDelay={0}>
                            <TooltipButton>
                                <TooltipIcon
                                    icon={icon({ name: 'circle-question', style: 'solid' })}
                                />
                            </TooltipButton>
                            <Tooltip placement="left">
                                <Text fontWeight="--fw-semibold">
                                    <FormattedMessage
                                        defaultMessage="L'audience concerne uniquement la{linebreak}plateforme Deezer pour le moment."
                                        values={{ linebreak: <br /> }}
                                    />
                                </Text>
                            </Tooltip>
                        </TooltipTrigger>
                    }
                    tabId={TABS_STATS_NAME.AUDIENCE}
                    noPodcastSelector
                />
                <AudienceGendersChart genders={genders} />
            </>
        );
    }

    return <DevicesFallback count={3} />;
};

const TooltipButton = styled(TooltipTriggerWrapper)`
    margin-left: 0.25rem;
    display: flex;
    align-items: center;
`;

const TooltipIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: var(--neutral500);
`;

export default AudienceGenders;
