import { useMutation, useQueryClient } from '@tanstack/react-query';
import camelcaseKeys from 'camelcase-keys';
import { addPersonalAccessToken } from '@/api';
import personalAccessTokenKeys from './personalAccessTokenKeys';
import { PersonalAccessTokenSchema } from '@/components/PersonalAccessToken/PersonalAccessTokenForm/usePersonalAccessTokenFormSchema.hook';

export interface AddPersonalAccessTokenResponse {
    accessToken: string;
    expiresIn: string;
    tokenType: string;
}

const mutationFn = async (
    formData: PersonalAccessTokenSchema,
): Promise<AddPersonalAccessTokenResponse> => {
    const data = await addPersonalAccessToken(formData);

    return camelcaseKeys(data as any, { deep: true });
};

const addPersonalAccessTokenMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: personalAccessTokenKeys.list() });
        },
    });
};

export default addPersonalAccessTokenMutation;
