import Paragraph from '@tiptap/extension-paragraph';
import { NodeViewContent, NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';
import styled from 'styled-components';
import TranscriptionSpeaker from '../TranscriptionSpeaker';
import { FC, useCallback } from 'react';
import deleteSpeakerForAllParagraphs from '@/components/unorganized/TranscriptionEditor/TranscriptionSpeaker/deleteSpeakerForAllParagraphs';
import { NodeViewProps } from '@tiptap/core/src/types';

const StyledNodeViewWrapper = styled(NodeViewWrapper)`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    margin-bottom: 2rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: initial;
        position: relative;
        margin-bottom: 0;
    }
`;

interface CustomParagraphComponentProps extends NodeViewProps {}

const CustomParagraphComponent: FC<CustomParagraphComponentProps> = ({
    editor,
    node,
    updateAttributes,
}) => {
    const { attrs } = node;
    const speaker: number | null = attrs.speaker;

    const setSpeaker = useCallback(
        (newSpeaker) => {
            updateAttributes({
                speaker: newSpeaker,
            });
        },
        [updateAttributes],
    );

    const deleteSpeaker = useCallback(
        (speaker) => {
            deleteSpeakerForAllParagraphs(editor, speaker);
        },
        [editor],
    );

    return (
        <StyledNodeViewWrapper>
            <TranscriptionSpeaker
                speakerId={speaker}
                setSpeaker={setSpeaker}
                deleteSpeakerForAllParagraphs={deleteSpeaker}
            />
            <NodeViewContent />
        </StyledNodeViewWrapper>
    );
};

const CustomParagraph = Paragraph.extend({
    content: 'phrase+',

    addAttributes() {
        return {
            speaker: {
                default: null,
                rendered: false,
            },
            class: {
                default: 'paragraph',
            },
        };
    },

    addNodeView() {
        return ReactNodeViewRenderer(CustomParagraphComponent);
    },
});

export default CustomParagraph;
