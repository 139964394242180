import useShowQuery from '@queries/show/useShowQuery.hook';
import useUserQuery from '@queries/user/useUserQuery.hook';
import { ENABLE_PSO_CHECKER, USER_ROLES, UserRole } from '../config/constants';
import { USER_OPTIONS } from '../config/userOptions';

interface useHasPsoBetaUserOptionProps {
    showId: string;
}

const useHasPsoBetaUserOption = ({ showId }: useHasPsoBetaUserOptionProps) => {
    const { data: show } = useShowQuery(showId);
    const { data: user } = useUserQuery();

    const allGuestRoles: Partial<UserRole>[] = [
        USER_ROLES.VIEWER,
        USER_ROLES.EDITOR,
        USER_ROLES.ADMIN,
    ];

    const isGuest = show?.userRole && allGuestRoles.includes(show.userRole as UserRole);

    const accountHasPsoBetaOption = user?.options?.includes(USER_OPTIONS.PSO_BETA);
    // TODO: Delete this after migration
    const accountHasLegacyPsoOption = user?.options?.includes(USER_OPTIONS.PSO);
    const accountHasPsoAccess = accountHasPsoBetaOption || accountHasLegacyPsoOption; // TODO: Delete this after migration

    const ownerHasPsoBetaOption = show?.userOptions?.includes(USER_OPTIONS.PSO_BETA);
    // TODO: Delete this after migration
    const ownerHasLegacyPsoOption = show?.userOptions?.includes(USER_OPTIONS.PSO);
    const ownerHasPsoAccess = ownerHasPsoBetaOption || ownerHasLegacyPsoOption; // TODO: Delete this after migration

    const guestWithAccess = isGuest && ownerHasPsoAccess;
    const ownerWithAccess = show?.userRole === USER_ROLES.OWNER && accountHasPsoAccess;
    const hasPsoCheckerUserOption = guestWithAccess || ownerWithAccess;

    return ENABLE_PSO_CHECKER && hasPsoCheckerUserOption;
};

export default useHasPsoBetaUserOption;
