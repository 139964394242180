// import useShowQuery from '@/queries/show/useShowQuery.hook';
// import { useParams } from 'react-router';
// import Active from './Active';
import Inactive from './Inactive';

const Status = () => {
    // const { showId } = useParams<{ showId: string }>();
    // const { data: show } = useShowQuery(showId);

    // if (show?.hostedOnAusha) return <Active />;

    return <Inactive />;
};

export default Status;
