import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteSeason } from '@/api';
import seasonKeys from '@/queries/season/seasonKeys';

export interface MutationFnProps {
    seasonId: string;
}

const mutationFn = async ({ seasonId }: MutationFnProps) => {
    await deleteSeason(seasonId);
};

const useDeleteSeasonMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: (_, { seasonId }) => {
            queryClient.removeQueries({ queryKey: seasonKeys.detailById(seasonId) });
            queryClient.invalidateQueries({ queryKey: seasonKeys.allLists() });
        },
    });
};

export default useDeleteSeasonMutation;
