import { FormattedMessage } from 'react-intl';
import ProgressTracker from '@ui/molecules/ProgressTracker';
import Cover from '@ui/layout/Cover';
import { EPISODE_JOB_NAME } from '@/shared/config/constants';
import styled from 'styled-components';
import Cluster from '@ui/layout/Cluster';

const ClipProgressTracker = ({ progress }) => {
    return (
        <Cover minHeight="17rem">
            <InnerWrapper>
                <Cluster $wrap="nowrap">
                    <ProgressTracker
                        progressObj={progress?.[EPISODE_JOB_NAME.CONVERT_TO_MP3]}
                        label={<FormattedMessage defaultMessage="Conversion du fichier audio" />}
                    />
                    <ProgressTracker
                        progressObj={progress?.[EPISODE_JOB_NAME.GENERATE_WAVEFORM]}
                        label={<FormattedMessage defaultMessage="Génération du chronographe" />}
                    />
                </Cluster>
            </InnerWrapper>
        </Cover>
    );
};

const InnerWrapper = styled.div`
    padding-inline: 3rem;
`;

export default ClipProgressTracker;
