import useShowQuery from '@queries/show/useShowQuery.hook';
import Form from './Form';
import { useParams } from 'react-router';
import useLanguagesQuery from '@queries/language/useLanguagesQuery.hook';
import useCategoriesQuery from '@queries/category/useCategoriesQuery.hook';
import useUserQuery from '@queries/user/useUserQuery.hook';
import { useAmplitudeLogEvent } from '@/shared/hooks/useAmplitude';
import useCategoryItems from '@/shared/hooks/useCategoryItems.hook';
import styled from 'styled-components';
import Stack from '@/components/ui/layout/Stack';
import Text from '@/components/ui/atoms/Text';
import { FormattedMessage } from 'react-intl';
import Skeleton from './Skeleton';

const Details = () => {
    useAmplitudeLogEvent('Show Settings Viewed');
    const { showId } = useParams<{ showId: string }>();
    const show = useShowQuery(showId);
    const languages = useLanguagesQuery();
    const categories = useCategoriesQuery();
    const user = useUserQuery();

    const hasData = show.data && languages.data && categories.data && user.data;
    const hasError = show.isError || languages.isError || categories.isError || user.isError;

    if (hasData) {
        const languageItems = languages.data
            .sort((a, b) => (a.isFeatured ? -1 : b.isFeatured ? 1 : 0))
            .map((language) => ({
                id: language.id,
                name: language.nativeName,
            }));
        const categoriesItems = useCategoryItems(categories.data);
        const isShowPmp = show.data.hostedOnAusha === false;
        const values = {
            name: show.data.name,
            author: show.data.author || `${user.data.firstname} ${user.data.lastname}`,
            htmlDescription: show.data.htmlDescription,
            imageUrl: show.data.imageUrl,
            languageId: show.data.languageId,
            categoryId: show.data.categoryId,
            secondaryCategoryId: show.data.secondaryCategoryId,
            slug: show.data.slug,
            descriptionFooter: show.data.descriptionFooter,
            tags: show.data.tags.data.map((tag) => tag.name),
            type: show.data.type,
            publicCounts: show.data.publicCounts,
        };

        return (
            <Container>
                <Stack $gap="0.25rem">
                    <Text variant="title" fontWeight="--fw-bold">
                        <FormattedMessage defaultMessage="Informations" />
                    </Text>
                    <Text color="--neutral500">
                        <FormattedMessage defaultMessage="Configurez les informations et paramètres principaux de votre émission." />
                    </Text>
                </Stack>
                <Form
                    values={values}
                    languages={languageItems}
                    categories={categoriesItems}
                    isShowPmp={isShowPmp}
                    userLocale={user.data.language}
                />
            </Container>
        );
    }

    if (hasError)
        return (
            <Stack $gap="0.25rem">
                <Text variant="title" fontWeight="--fw-bold">
                    <FormattedMessage defaultMessage="Informations" />
                </Text>
                <Text color="--neutral500">
                    <FormattedMessage defaultMessage="Configurez les informations et paramètres principaux de votre émission." />
                </Text>
                <div>ERROR</div>
            </Stack>
        );

    return (
        <Stack $gap="0.25rem">
            <Text variant="title" fontWeight="--fw-bold">
                <FormattedMessage defaultMessage="Informations" />
            </Text>
            <Text color="--neutral500">
                <FormattedMessage defaultMessage="Configurez les informations et paramètres principaux de votre émission." />
            </Text>
            <Skeleton />
        </Stack>
    );
};

const Container = styled.div`
    padding: var(--container-padding-mobile);
    background-color: var(--white);
    border-radius: var(--r-xs);
    display: flex;
    flex-direction: column;
    row-gap: var(--sp-xl);

    ${(props) => props.theme.mediaQueries.desktopAndUp} {
        padding: var(--container-padding);
    }
`;

export default Details;
