import { useMutation } from '@tanstack/react-query';
import { uploadCampaignAudioFile } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import { Campaign } from './useCampaignsQuery';

export interface MutationFnProps {
    campaignId: string | number;
    file: File;
}

const mutationFn = async ({ campaignId, file }: MutationFnProps): Promise<Campaign> => {
    const { data } = await uploadCampaignAudioFile(campaignId, file);
    return camelcaseKeys(data, { deep: true });
};

const uploadCampaignAudioFileMutation = () => {
    return useMutation({
        mutationFn,
        onSuccess: () => {
            // Query invalidation is done by the saveCampaignMutation
        },
    });
};

export default uploadCampaignAudioFileMutation;
