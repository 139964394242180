import Text from '@/components/ui/atoms/Text';
import Stack from '@/components/ui/layout/Stack';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import PlayerFormContent from './PlayerFormContent';
import PlayerFormStyle from './PlayerFormStyle';
import PlayerFormCallToActionButtons from './PlayerFormCallToActionButtons';
import usePlaylistsQuery from '@/queries/playlist/usePlaylistsQuery.hook';
import { useParams } from 'react-router';
import { useFormContext } from 'react-hook-form';
import { PlayerFormSchema } from '../usePlayerFormSchema.hook';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useInternalRouteMapping from '@/utils/hooks/useInternalRouteMapping.hook';
import callToActionVideo from '@public/videos/smartplayer-cta-video.mp4';
import UpgradeBadge from '@/components/ui/atoms/UpgradeBadge';
import { PRICING } from '@/utils/pricing';
import useSubscriptionQuery from '@/queries/subscription/useSubscriptionQuery.hook';
import Cluster from '@/components/ui/layout/Cluster';

const PlayerFormControls = () => {
    const { watch } = useFormContext<PlayerFormSchema>();
    const { showId } = useParams<{ showId: string }>();
    const { link } = useInternalRouteMapping();
    const playlists = usePlaylistsQuery(showId);
    const type = watch('type');
    const noPlaylists = type === 'playlist' && playlists.data?.length === 0;
    const callToActionLink = link('menu.communication.settings', { tab: 'call-to-action' });
    const { data: subscription } = useSubscriptionQuery();
    const hasAccessToCallToAction =
        subscription?.pricing && PRICING.PREMIUM.includes(subscription?.pricing);

    return (
        // @ts-ignore
        <Stack $gap="2rem">
            {/* @ts-ignore */}
            <Stack $gap="2rem">
                <CategoryTitle>
                    <FormattedMessage defaultMessage="Contenu" />
                </CategoryTitle>
                <PlayerFormContent noPlaylists={noPlaylists} />
            </Stack>
            <Divider />
            {/* @ts-ignore */}
            <Stack $gap="2rem">
                <CategoryTitle>
                    <FormattedMessage defaultMessage="Apparence" />
                </CategoryTitle>
                <PlayerFormStyle noPlaylists={noPlaylists} />
            </Stack>
            <Divider />
            {/* @ts-ignore */}
            <Stack $gap="2rem">
                <CallToActionHeadingWrapper>
                    <CallToActionTitleWrapper>
                        <Cluster $gap="0.5rem" $align="center">
                            <CategoryTitle>
                                <FormattedMessage defaultMessage="Boutons personnalisés" />
                            </CategoryTitle>
                            {!hasAccessToCallToAction && <UpgradeBadge pricing="supersonic" />}
                        </Cluster>
                        <CallToActionDescription>
                            <FormattedMessage
                                defaultMessage="Les boutons personnalisés apparaissent lorsque le player est en pause. Vous pouvez les gérer depuis la section <link>Call-to-Action</link>"
                                values={{
                                    link: (chunks: string) => (
                                        // @ts-ignore
                                        <CallToActionLink href={callToActionLink} target="_blank">
                                            {chunks}
                                            <LinkIcon
                                                icon={icon({
                                                    name: 'arrow-up-right',
                                                    style: 'solid',
                                                })}
                                            />
                                        </CallToActionLink>
                                    ),
                                }}
                            />
                        </CallToActionDescription>
                    </CallToActionTitleWrapper>
                    <CallToActionVideoWrapper>
                        <CallToActionVideoInner>
                            <CallToActionVideo muted autoPlay loop>
                                <source src={callToActionVideo} type="video/mp4" />
                            </CallToActionVideo>
                        </CallToActionVideoInner>
                    </CallToActionVideoWrapper>
                </CallToActionHeadingWrapper>
                {hasAccessToCallToAction && (
                    <PlayerFormCallToActionButtons isDisabled={noPlaylists} />
                )}
            </Stack>
        </Stack>
    );
};

const CategoryTitle = styled(Text)`
    font-size: var(--fs-heading-s);
    line-height: var(--lh-heading-s);
    font-weight: var(--fw-bold);
`;
const Divider = styled.hr`
    display: none;
    width: 100%;
    height: 0.0625rem;
    background-color: var(--neutral100);

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: block;
    }
`;
const CallToActionTitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
`;
const CallToActionDescription = styled(Text)`
    color: var(--neutral500);
`;
const CallToActionLink = styled.a`
    color: var(--primary);
    font-weight: var(--fw-semibold);
    display: inline-flex;
    align-items: center;
    column-gap: 0.25rem;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;
const LinkIcon = styled(FontAwesomeIcon)`
    color: var(--primary);
    width: 0.75rem;
    height: 0.75rem;
`;
const CallToActionVideoWrapper = styled.div`
    padding-block: 2.1875rem;
    padding-inline: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    border-radius: var(--r-m);
    background: var(--gradient-dusk);
`;
const CallToActionVideoInner = styled.div`
    position: relative;

    &::before,
    &::after {
        content: '';
        border-radius: 0rem 0rem var(--r-s) var(--r-s);
        background-color: var(--white);
        height: 0.5rem;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
    &::before {
        width: 19rem;
        bottom: -0.5rem;
        opacity: 0.5;
    }
    &::after {
        width: 17rem;
        bottom: -1rem;
        opacity: 0.3;
    }
`;
const CallToActionVideo = styled.video`
    width: 100%;
    height: 100%;
    border-radius: var(--r-s);
`;
const CallToActionHeadingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
`;

export default PlayerFormControls;
