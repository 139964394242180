import Text from '@/components/ui/atoms/Text';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Button from '@/components/Button';
import type { ListBoxItem as IListBoxItem } from '@/components/ui/atoms/ListBoxItem';
import ComboBox from '@/components/ui/atoms/ComboBox';
import ListBoxItem from '@/components/ui/atoms/ListBoxItem';
import Alert from '@/components/ui/atoms/Alert';
import { ReactNode, useEffect, useState } from 'react';
import useSearchApplePodcastsQuery from '@queries/rss/useSearchApplePodcastsQuery.hook';
import { Key } from 'react-aria';
import { IMG_PLACEHOLDER } from '@/shared/config/constants';
import { useHistory } from 'react-router';
import Toggle from '@ui/atoms/Toggle';
import { USER_OPTIONS } from '@/shared/config/userOptions';
import useUserQuery from '@queries/user/useUserQuery.hook';
import { AxiosError } from 'axios';
import useChannelQuery from '@queries/channel/useChannelQuery.hook';
import useImportRssShowMutation from '@queries/rss/useImportRssShowMutation.hook';
import { useBodyToastQueue } from '@/shared/hooks/useBodyToastQueue.hook';
import ExternalLink from '@/components/Link/ExternalLink';
import Tooltip, { TooltipTriggerWrapper } from '@ui/Tooltip';
import { TooltipTrigger } from 'react-aria-components';

interface ImportProps {
    variant: 'hosting' | 'nonHosting';
}

const ImportShowSelector = ({ variant }: ImportProps) => {
    const intl = useIntl();
    const history = useHistory();
    const toast = useBodyToastQueue();
    const { data: user } = useUserQuery();
    const { data: channel } = useChannelQuery();
    const [show, setShow] = useState<IListBoxItem | null>(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [inputValue, setInputValue] = useState('');
    const {
        data: applePodcastResults,
        isFetching: applePodcastsResultsAreLoading,
        isError: applePodcastsResultsAreInError,
    } = useSearchApplePodcastsQuery({ searchQuery });
    const importShow = useImportRssShowMutation();

    const results =
        applePodcastResults?.map((result) => ({
            id: crypto.randomUUID(),
            author: result.author,
            name: result.showTitle,
            image: result.image ?? IMG_PLACEHOLDER,
            description: result.author,
            episodesCount: result.episodeCount,
            url: result.url,
        })) || [];

    const canImportRssAuto = user?.options?.includes(USER_OPTIONS.IMPORT_RSS_AUTO);
    const [synchronized, setSynchronized] = useState(false);

    const handleShowSearch = (query: string) => {
        setSearchQuery(query);
        setInputValue(query);
    };

    const handleShowSelect = (showId: Key | null) => {
        const selectedShow = results.find((result) => result.id === showId);
        if (!selectedShow) return;
        setShow(selectedShow);
        setInputValue(selectedShow.name);
    };

    const handleReset = () => {
        setShow(null);
        setSearchQuery('');
        setInputValue('');
    };

    const handleImport = () => {
        if (!channel || !show) return;
        importShow.mutate(
            {
                channelId: `${channel.id}`,
                payload: {
                    auto: synchronized,
                    url: show.url,
                    hosted_on_ausha: variant === 'hosting',
                },
            },
            {
                onSuccess: (data) => {
                    history.replace(`/app/onboarding/import/${variant}/success?showId=${data.id}`);
                },
                onError: (error) => {
                    const err = error as AxiosError;
                    if (
                        err?.response?.status === 403 &&
                        err?.response?.data?.message === 'Rss feed is locked'
                    ) {
                        toast.alert(
                            <FormattedMessage
                                defaultMessage="Votre flux RSS est pour l'instant verrouillé et ne peut être importé sur Ausha. Veuillez contacter votre hébergeur actuel et demander de déverrouiller la balise {tag} 🔓."
                                values={{
                                    tag: '<podcast:locked>',
                                }}
                            />,
                        );
                        return;
                    }
                    toast.alert(
                        <FormattedMessage defaultMessage="Oups, il semblerait que nous rencontrons un souci avec votre flux RSS. Contactez-nous pour que l'on puisse vous aider ! 💜" />,
                    );
                },
            },
        );
    };

    useEffect(() => {
        if (show) {
            setInputValue(show.name);
        }
    }, [show]);

    const TITLES = {
        hosting: (
            <FormattedMessage defaultMessage="C'est parti pour l'import de votre podcast sur Ausha !" />
        ),
        nonHosting: <FormattedMessage defaultMessage="Connectons votre podcast à Ausha !" />,
    };

    const SUBTITLES = {
        hosting: (
            <FormattedMessage
                defaultMessage="Donnez-nous le nom de votre podcast ou l'URL de votre flux RSS, et nous importerons vos épisodes et les informations relatives à l'émission. <link>Où trouver votre flux RSS ?</link>"
                values={{
                    link: (chunks: ReactNode) => (
                        <ExternalLink
                            href={intl.formatMessage({
                                defaultMessage:
                                    'https://help.ausha.co/fr/articles/2092747-comment-retrouver-le-flux-rss-de-mon-podcast-sur-ausha',
                            })}
                            target="_blank"
                        >
                            {chunks}
                        </ExternalLink>
                    ),
                }}
            />
        ),
        nonHosting: (
            <FormattedMessage
                defaultMessage="Donnez-nous le nom de votre podcast ou l'URL de votre flux RSS, et nous récupérerons vos épisodes et les informations relatives à l'émission. <link>Où trouver votre flux RSS ?</link>"
                values={{
                    link: (chunks: ReactNode) => (
                        <ExternalLink
                            href={intl.formatMessage({
                                defaultMessage:
                                    'https://help.ausha.co/fr/articles/2092747-comment-retrouver-le-flux-rss-de-mon-podcast-sur-ausha',
                            })}
                            target="_blank"
                        >
                            {chunks}
                        </ExternalLink>
                    ),
                }}
            />
        ),
    };

    const BUTTON = {
        hosting: <FormattedMessage defaultMessage="Importez votre podcast" />,
        nonHosting: <FormattedMessage defaultMessage="Connectez votre podcast" />,
    };

    return (
        <Wrapper>
            <Header>
                <Text variant="headingL" fontWeight="--fw-bold">
                    {TITLES[variant]}
                </Text>
                <Text color="--neutral500">{SUBTITLES[variant]}</Text>
            </Header>
            <Container>
                <FullWidthComboBox
                    items={results}
                    label={<FormattedMessage defaultMessage="Votre podcast" />}
                    onInputChange={handleShowSearch}
                    onSelectionChange={handleShowSelect}
                    selectedKey={show?.id ?? null}
                    inputValue={inputValue}
                    isLoading={applePodcastsResultsAreLoading}
                    allowsEmptyCollection={!!searchQuery}
                    hideIconButton
                    showResetButton
                    onReset={handleReset}
                    menuTrigger="input"
                    autoFocus={true}
                    renderEmptyState={() => (
                        <NoResultsWrapper>
                            <FormattedMessage
                                defaultMessage="Aucun résultat trouvé pour «{searchQuery}»"
                                values={{ searchQuery }}
                            />
                        </NoResultsWrapper>
                    )}
                    placeholder={intl.formatMessage({
                        defaultMessage: "Entrez le nom de votre podcast ou l'URL de votre flux RSS",
                    })}
                >
                    {(item: IListBoxItem) => <ListBoxItem {...item} />}
                </FullWidthComboBox>
                {!!show && (
                    <Show>
                        <Cover src={show.image as string} alt={show.name} />
                        <ShowInfos>
                            <ShowNameAndAuthor>
                                <Text variant="bodyL" fontWeight="--fw-bold">
                                    {show.name}
                                </Text>
                                <Text color="--primary500">{show.author}</Text>
                            </ShowNameAndAuthor>
                            <EpisodesCount>
                                <EpisodesCountIcon
                                    icon={icon({ name: 'rectangle-history', style: 'solid' })}
                                />
                                <Text>
                                    <FormattedMessage
                                        defaultMessage="{count, plural, one {# épisode} other {# épisodes}}"
                                        values={{
                                            count: show.episodesCount,
                                        }}
                                    />
                                </Text>
                            </EpisodesCount>
                        </ShowInfos>
                    </Show>
                )}
                {applePodcastsResultsAreInError && (
                    <Text variant="bodyS" color="--alert">
                        <FormattedMessage defaultMessage="Erreur pour importer votre flux : l'adresse du flux RSS est invalide ou il est déjà hébergé sur Ausha." />
                    </Text>
                )}
                <Alert variant="robot">
                    <Text fontWeight="--fw-semibold">
                        <FormattedMessage defaultMessage="Pas de panique, cela n'affectera pas votre podcast existant sur votre hébergeur actuel." />
                    </Text>
                </Alert>
                {show && canImportRssAuto && variant === 'hosting' && (
                    <Toggle
                        isSelected={synchronized}
                        onChange={() => setSynchronized(!synchronized)}
                    >
                        <Text fontWeight="--fw-semibold">
                            <FormattedMessage defaultMessage="Synchroniser automatiquement vos épisodes dès leur publication" />
                        </Text>
                        <TooltipTrigger delay={0} closeDelay={0}>
                            <TooltipTriggerWrapper>
                                <InfoIcon icon={icon({ name: 'circle-info', style: 'solid' })} />
                            </TooltipTriggerWrapper>
                            <Tooltip placement="top">
                                <FormattedMessage defaultMessage="Activez cette option pour récupérer automatiquement les futurs épisodes publiés sur votre plateforme d'hébergement actuelle." />
                            </Tooltip>
                        </TooltipTrigger>
                    </Toggle>
                )}
                {!!show && (
                    <Disclaimer>
                        <FormattedMessage defaultMessage="En validant, vous confirmez que vous possédez les droits de ce podcast." />
                    </Disclaimer>
                )}
            </Container>
            <Actions $hasMany={!!show}>
                <Button
                    variant="link-secondary"
                    startIcon={
                        <ArrowLeftIcon icon={icon({ name: 'arrow-left', style: 'solid' })} />
                    }
                    onPress={() => history.goBack()}
                >
                    <FormattedMessage id="goBack" defaultMessage="Retour" />
                </Button>
                {!!show && (
                    <Button
                        variant="primary"
                        endIcon={
                            <ArrowRightIcon icon={icon({ name: 'arrow-right', style: 'solid' })} />
                        }
                        isDisabled={applePodcastsResultsAreInError}
                        isLoading={importShow.isLoading}
                        onPress={handleImport}
                    >
                        {BUTTON[variant]}
                    </Button>
                )}
            </Actions>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;

    & > :last-child {
        align-self: center;
    }
`;
const Header = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;
const Container = styled.div`
    padding: 2rem;
    background-color: var(--white);
    border-radius: var(--r-l);
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;
const Actions = styled.div<{ $hasMany: boolean }>`
    display: flex;
    align-items: center;
    justify-content: ${({ $hasMany }) => ($hasMany ? 'space-between' : 'center')};
    column-gap: 1rem;
    width: 100%;
`;
const ArrowLeftIcon = styled(FontAwesomeIcon)`
    margin-right: 0.25rem;
`;
const ArrowRightIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const Disclaimer = styled.div`
    color: var(--neutral500);
    font-size: var(--fs-vody-m);
    margin-top: 1rem;
`;
const FullWidthComboBox = styled(ComboBox)`
    max-width: 100%;
`;
const NoResultsWrapper = styled.div`
    padding: 0.5rem;
    color: var(--neutral);
`;
const Show = styled.div`
    padding: 1rem;
    background-color: var(--neutral50);
    border-radius: var(--r-l);
    display: flex;
    align-items: center;
    column-gap: 1rem;
`;
const Cover = styled.img`
    width: 5rem;
    height: 5rem;
    border-radius: var(--r-m);
    flex-shrink: 0;
    object-fit: cover;
`;
const ShowInfos = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
`;
const ShowNameAndAuthor = styled.div`
    display: flex;
    flex-direction: column;
`;
const EpisodesCount = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    color: var(--neutral500);
`;
const EpisodesCountIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const InfoIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
`;

export default ImportShowSelector;
