import styled from 'styled-components';

const SubListSkeleton = () => {
    return (
        <SubListSkeletonWrapper>
            <Title />
            <Cards>
                <Card>
                    <Image />
                    <Bars>
                        <TopBar />
                        <BottomBar />
                    </Bars>
                    <Dot />
                </Card>
                <Card>
                    <Image />
                    <Bars>
                        <TopBar />
                        <BottomBar />
                    </Bars>
                    <Dot />
                </Card>
                <Card>
                    <Image />
                    <Bars>
                        <TopBar />
                        <BottomBar />
                    </Bars>
                    <Dot />
                </Card>
            </Cards>
        </SubListSkeletonWrapper>
    );
};

const SubListSkeletonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
`;
const Title = styled.div`
    width: 5rem;
    height: 0.75rem;
    border-radius: var(--r-full);
    background-color: var(--neutral100);
`;
const Cards = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;
const Card = styled.div`
    width: 100%;
    background: var(--white);
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.03);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.75rem;
    padding: 0.75rem;
`;
const Image = styled.div`
    width: 2.5rem;
    height: 2.5rem;
    border-radius: var(--r-s);
    background-color: var(--neutral100);
`;
const Bars = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;
const TopBar = styled.div`
    width: 7.5rem;
    height: 0.5rem;
    background-color: var(--neutral100);
    border-radius: var(--r-full);
`;
const BottomBar = styled.div`
    width: 2.5rem;
    height: 0.5rem;
    background-color: var(--neutral50);
    border-radius: var(--r-full);
`;
const Dot = styled.div`
    width: 1rem;
    height: 1rem;
    border-radius: var(--r-full);
    background-color: var(--neutral50);
`;

export default SubListSkeleton;
