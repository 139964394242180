import Model from './Model';

class FacebookPageModel extends Model {
    static attributes = {
        id: 'id',
        name: 'name',
    };
}

export default FacebookPageModel;
