import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import Collapse from '@ui/molecules/Collapse';
import DeprecatedTextInput from '@ui/molecules/DeprecatedTextInput';
import Stack from '@ui/layout/Stack';
import Spinner from '@ui/atoms/Spinner';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import useTimezonesQuery from '@/queries/timezone/useTimezonesQuery.hook';
import DateTimePicker from '@/components/DateTimePicker';
import { formatDateForApi } from '@/helpers';

function CampaignDuration({ fields, isImmutable, ...props }) {
    const user = useUserQuery();
    const timezones = useTimezonesQuery();

    return (
        <Collapse title={<FormattedMessage defaultMessage="Durée" />} {...props}>
            {user.isLoading || timezones.isLoading ? (
                <Spinner />
            ) : (
                <Stack $gap="1rem">
                    <DateTimePicker
                        value={fields.startsAt.value}
                        onChange={(date) => {
                            fields.startsAt.setValue(formatDateForApi(date));
                        }}
                        dateProps={{ label: <FormattedMessage defaultMessage="Commence le" /> }}
                        isDisabled={isImmutable}
                    />
                    <DateTimePicker
                        value={fields.endsAt.value}
                        onChange={(date) => fields.endsAt.setValue(formatDateForApi(date))}
                        dateProps={{ label: <FormattedMessage defaultMessage="Termine le" /> }}
                        isDisabled={isImmutable}
                    />
                    <DeprecatedTextInput
                        field={fields.maxListens}
                        type="number"
                        label={<FormattedMessage defaultMessage="Nombre d'écoutes maximum" />}
                        w={150}
                        min={0}
                        optional
                        nowrap
                    />
                </Stack>
            )}
        </Collapse>
    );
}

export default observer(CampaignDuration);
