import { css } from 'styled-components';
import PropTypes from 'prop-types';

/**
 * See https://chakra-ui.com/docs/features/style-props
 */
const transformMixin = css`
    clip-path: ${(props) => props.$clipPath};
    transform: ${(props) => props.$transform};
    transform-origin: ${(props) => props.$transformOrigin};
`;

export const transformPropTypes = {
    $clipPath: PropTypes.string,
    $transform: PropTypes.string,
    $transformOrigin: PropTypes.string,
};

export default transformMixin;
