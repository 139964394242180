import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import UnsubscribeSurveyForm from './UnsubscribeSurveyForm';
import Dialog from '@ui/atoms/Dialog';

const UnsubscribeSurveyModal = ({ isOpen, onOpenChange, onAfterSubmit }) => {
    return (
        <Dialog
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            title={<FormattedMessage defaultMessage="Nous sommes tristes de vous voir partir 😢" />}
            size="medium"
        >
            <UnsubscribeSurveyForm
                onAfterSubmit={onAfterSubmit}
                onClose={() => onOpenChange(false)}
            />
        </Dialog>
    );
};

UnsubscribeSurveyModal.propTypes = {
    isOpen: PropTypes.bool,
    onOpenChange: PropTypes.func,
    onAfterSubmit: PropTypes.func,
};

UnsubscribeSurveyModal.defaultProps = {
    isOpen: false,
    onOpenChange: () => {},
    onAfterSubmit: () => {},
};

export default UnsubscribeSurveyModal;
