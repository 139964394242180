import SimpleGrid from '../../ui/layout/SimpleGrid';
import ClipFormat from '../molecules/ClipFormat';
import FallbackClipFormat from '../molecules/FallbackClipFormat';
import { makeArray } from '../../../helpers';

const ClipFormatsGrid = ({ clipFormats, onEditClipFormat, isLoading = false }) => {
    return (
        <SimpleGrid $idealChildWidth={250} $rowGap={32} $columnGap={36}>
            {isLoading
                ? makeArray(16).map((_, index) => <FallbackClipFormat key={`fallback-${index}`} />)
                : clipFormats.map((clipFormat) => (
                      <ClipFormat
                          key={clipFormat.id}
                          clipFormat={clipFormat}
                          onEdit={onEditClipFormat}
                      />
                  ))}
        </SimpleGrid>
    );
};

export default ClipFormatsGrid;
