import { action, computed, observable } from 'mobx';
import Model from './Model';
import { date, first } from './resolvers';
import { apiCall } from '../components/app/decorators/api';
import FileModel from './FileModel';
import { CAMPAIGN_STATUS } from '@/utils/constants';

class CampaignModel extends Model {
    @observable isSelected = false;

    static default = (state) => ({
        showId: state.routerStore.params.showId,
        type: 'preroll',
        name: 'Nouvelle campagne',
        startsAt: new Date(),
    });

    static attributes = {
        id: 'id',
        showId: 'show_id',
        name: 'name',
        type: 'type',
        downloadsCount: 'downloads_count',
        states: 'state',
        startsAt: date('starts_at'),
        endsAt: date('ends_at'),
        audioFile: first('files', FileModel),
        podcasts: {
            fromApi: {
                key: 'podcasts',
                resolve: (pods) => pods?.data.map((p) => p.id),
            },
        },
        audioUrl: 'audio_url',
        maxListens: {
            fromApi: {
                key: 'max_listens',
                resolve: (x) => x,
            },
            toApi: {
                key: 'max_listens',
                resolve: (x) => (x === '0' ? null : x),
            },
        },
        auto: 'auto_on_new_podcasts',
        createdAt: 'created_at',
        updatedAt: 'updated_at',
        soundcastId: 'soundcast_id',
        uuid: 'uuid',
    };

    @computed
    get show() {
        return this.state.showStore.byId(this.showId);
    }

    @action.bound
    setSelection(selection) {
        this.isSelected = selection;
    }

    @action.bound
    toggleSelection() {
        this.isSelected = !this.isSelected;
    }

    @apiCall
    async publish() {
        return this.store.updateCampaign(this, { state: CAMPAIGN_STATUS.ACTIVE });
    }

    @apiCall
    async pause() {
        return this.store.updateCampaign(this, { state: CAMPAIGN_STATUS.PAUSED });
    }

    @apiCall
    async uploadFile(file) {
        this.audioFile = new FileModel(this.store, {});
        this.audioFile.startUpload(file);
        return this.store.uploadFile(this);
    }

    @apiCall
    delete() {
        return this.store.deleteCampaign(this);
    }

    @apiCall
    update(formData) {
        return this.store.updateCampaign(this, formData);
    }

    @computed
    get status() {
        return this.states;
    }
}

export default CampaignModel;
