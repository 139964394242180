import EmptyStateComponent from '@ui/molecules/EmptyState';
import PsoIllustration from '@public/images/empty-state/illustration/pso.mp4';
import { useParams } from 'react-router-dom';
import ExternalLink from '@/components/Link/ExternalLink';
import { FormattedMessage } from 'react-intl';
import ZebraIllustration from '@/components/ui/atoms/ZebraIllustration';
import ShiningDiamond from '@/components/ui/atoms/ShiningDiamond';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { PRICING } from '@/shared/config/pricing';
import { STATUS_SHOWS } from '@/shared/config/statusShows';
import useSubscriptionQuery from '@/queries/subscription/useSubscriptionQuery.hook';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import Button from '@/components/Button';
import styled from 'styled-components';
import { useIntercom } from 'react-use-intercom';

const EmptyState = () => {
    const { showId }: { showId: string } = useParams();
    const show = useShowQuery(showId);
    const isGuestShow = show?.data?.showStatus === STATUS_SHOWS.GUEST_SHOWS;
    const subscription = useSubscriptionQuery();
    const isEnterprise = isGuestShow
        ? show?.data?.ownerPricing === PRICING.ENTERPRISE
        : subscription?.data?.pricing === PRICING.ENTERPRISE;
    const intercom = useIntercom();

    return (
        <EmptyStateComponent
            illustration={PsoIllustration}
            illustrationType="video"
            illustrationAlign="right"
            illustrationBackground="--gradient-dusk"
        >
            <HeadIllustrationWrapper>
                {/* @ts-ignore */}
                <ZebraIllustration>
                    <ShiningDiamond />
                </ZebraIllustration>
            </HeadIllustrationWrapper>
            <LabelWrapper>
                <Title>
                    <FormattedMessage defaultMessage="Débloquez toute la puissance du PSO Control Panel dès maintenant" />
                </Title>
                <Description>
                    <FormattedMessage defaultMessage="Optimisez le succès de votre podcast avec des outils avancés conçus pour la croissance de votre audience." />
                </Description>
            </LabelWrapper>
            {isEnterprise ? (
                <ExternalLink
                    href="mailto:sales@ausha.co"
                    variant="button-primary"
                    startIcon={
                        <FontAwesomeIcon
                            icon={icon({ name: 'envelope', style: 'solid' })}
                            size="sm"
                        />
                    }
                >
                    <FormattedMessage defaultMessage="Contactez-nous" />
                </ExternalLink>
            ) : (
                <Button
                    onPress={() => intercom.show()}
                    startIcon={
                        <FontAwesomeIcon
                            icon={icon({ name: 'envelope', style: 'solid' })}
                            size="sm"
                        />
                    }
                >
                    <FormattedMessage defaultMessage="Contactez-nous" />
                </Button>
            )}
        </EmptyStateComponent>
    );
};

const HeadIllustrationWrapper = styled.div`
    width: 200px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    & > * {
        text-align: center;
    }
`;
const Title = styled.h1`
    font-weight: var(--fw-bold);
    font-size: var(--fs-heading-s);
`;
const Description = styled.p`
    color: var(--neutral);
`;

export default EmptyState;
