import { FormattedMessage } from 'react-intl';
import EpisodesNav from '../EpisodesNav';
import styled from 'styled-components';
import { useLocation } from 'react-router';

interface EpisodesLayoutProps {
    children: React.ReactNode;
}

const EpisodesLayout = ({ children }: EpisodesLayoutProps) => {
    const location = useLocation();

    return (
        <EpisodesLayoutWrapper>
            <Sidebar>
                <Title>
                    {location.pathname.includes('playlist') ? (
                        <FormattedMessage defaultMessage="Playlists" />
                    ) : (
                        <FormattedMessage defaultMessage="Episodes" />
                    )}
                </Title>
                <EpisodesNav />
            </Sidebar>
            <ContentWrapper>{children}</ContentWrapper>
        </EpisodesLayoutWrapper>
    );
};
const EpisodesLayoutWrapper = styled.div`
    width: 100%;
    padding-inline: var(--container-padding-mobile);
    gap: 2rem;
    margin-block: 2rem;
    justify-content: center;
    max-width: var(--container-width);

    @media (min-width: 75rem) {
        display: flex;

        padding-inline: var(--container-padding);
    }
`;
const ContentWrapper = styled.div`
    display: flex;
    height: max-content;
    flex-direction: column;
    gap: 1rem;
    flex: 1;

    @media (min-width: 75rem) {
        max-width: calc(100% - 17rem); // 15rem sidebar width + 2rem gap
    }
`;
const Sidebar = styled.aside`
    display: flex;
    width: 100%;
    gap: 1rem;
    margin-bottom: 1rem;
    justify-content: space-between;

    @media (min-width: 75rem) {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 0;
        width: 15rem;
        height: 20rem;
        position: sticky;
        top: 8.5rem;
        flex-shrink: 0;
    }
`;
const Title = styled.div`
    font-weight: var(--fw-bold);
    font-size: var(--fs-heading-l);
`;

export default EpisodesLayout;
