import { FormattedMessage } from 'react-intl';
import VirtualList from 'react-tiny-virtual-list';
import { withSize } from 'react-sizeme';
import { useResponsive } from '@/utils/hooks/useResponsive';
import DeprecatedPaper from '../../ui/atoms/DeprecatedPaper';
import DeprecatedText from '../../ui/atoms/DeprecatedText';
import { connect } from '../decorators/connect';
import ListenersVariationGraphTableColumn from './ListenersVariationGraphTableColumn';
import ListFormFallback from '../fallbacks/ListFormFallback';
import DeprecatedWhitePaper from '../../ui/atoms/DeprecatedWhitePaper';

const enhance = connect(({ listenersStatsStore }) => ({
    variationsFormatted: listenersStatsStore.variationsFormatted,
    step: listenersStatsStore.stepMobile,
}));

function ListenersVariationGraphTable({
    isLoading,
    hoveredValue,
    onHover,
    size,
    scrollToValue,
    step,
    variationsFormatted,
}) {
    const { isMobileOrTablet } = useResponsive();

    if (isMobileOrTablet) return null;

    return (
        <DeprecatedWhitePaper flex>
            <DeprecatedWhitePaper
                w={150}
                rounded={false}
                br={1}
                border="--neutral100"
                minW={150}
                height="165px"
                css="border-style: solid;"
            >
                <DeprecatedWhitePaper
                    rounded={false}
                    bb={1}
                    py={15}
                    border="--neutral100"
                    background="--neutral50"
                    height="49px"
                    css="border-style: solid;"
                />
                <DeprecatedWhitePaper
                    rounded={false}
                    py={15}
                    bb={1}
                    border="--neutral100"
                    background="white"
                    height="50px"
                    css="border-style: solid;"
                >
                    <DeprecatedText pl={20} weight="semibold">
                        <FormattedMessage defaultMessage="Écoutes" />
                    </DeprecatedText>
                </DeprecatedWhitePaper>
                <DeprecatedWhitePaper
                    rounded={false}
                    py={15}
                    bb={1}
                    border="--neutral100"
                    background="white"
                    height="50px"
                    css="border-style: solid;"
                >
                    <DeprecatedText pl={20} weight="semibold">
                        <FormattedMessage defaultMessage="Variation" />
                    </DeprecatedText>
                </DeprecatedWhitePaper>
            </DeprecatedWhitePaper>
            {isLoading ? (
                <DeprecatedPaper b={0} height={165} p={15}>
                    <ListFormFallback style={{ height: 150, width: '100%' }} />
                </DeprecatedPaper>
            ) : (
                <DeprecatedPaper b={0} rounded={false}>
                    <VirtualList
                        width={size.width - 150}
                        height={165}
                        itemCount={variationsFormatted.length}
                        scrollDirection="horizontal"
                        scrollToAlignment="center"
                        scrollToIndex={scrollToValue && scrollToValue.id}
                        overscanCount={3}
                        itemSize={Math.max(
                            115,
                            (size.width - 150) / (variationsFormatted.length || 1),
                        )} // Also supports variable heights (array or function getter)
                        renderItem={({ index, style }) => (
                            <ListenersVariationGraphTableColumn
                                key={variationsFormatted[index].id}
                                isActive={
                                    (hoveredValue && hoveredValue.id) ===
                                    variationsFormatted[index].id
                                }
                                onHover={onHover}
                                style={style}
                                step={step}
                                variationsFormatted={variationsFormatted[index]}
                            />
                        )}
                    />
                </DeprecatedPaper>
            )}
        </DeprecatedWhitePaper>
    );
}

export default withSize()(enhance(ListenersVariationGraphTable));
