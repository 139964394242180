import { useMutation } from '@tanstack/react-query';
import { exportShowGuests } from '@/api';

export interface ShowGuestsExport {
    filename: string;
    guests: string[];
}

interface MutationFnProps {
    showId: string;
}

const mutationFn = async ({ showId }: MutationFnProps): Promise<ShowGuestsExport> => {
    const response = await exportShowGuests(showId);

    const filename = response.axios.headers['content-disposition']
        .split('filename="')[1]
        .split('"')[0];

    const formattedResponse = Object.values(response) as string[];
    return { filename, guests: formattedResponse.slice(0, formattedResponse.length - 1) };
};

const exportShowGuestsMutation = () => {
    return useMutation({
        mutationFn,
    });
};

export default exportShowGuestsMutation;
