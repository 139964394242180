import { css } from 'styled-components';
import PropTypes from 'prop-types';
import { computeCssValue } from '../css';

/**
 * See https://chakra-ui.com/docs/features/style-props
 */
const paddingMixin = css`
    padding: ${(props) => computeCssValue(props.$padding ?? props.$p)};
    ${(props) =>
        props.$px !== null &&
        props.$px !== undefined &&
        css`
            padding-inline-start: ${computeCssValue(props.$px)};
            padding-inline-end: ${computeCssValue(props.$px)};
        `};
    ${(props) =>
        props.$py !== null &&
        props.$py !== undefined &&
        css`
            padding-top: ${computeCssValue(props.$py)};
            padding-bottom: ${computeCssValue(props.$py)};
        `};
    padding-top: ${(props) => computeCssValue(props.$paddingTop ?? props.$pt)};
    padding-right: ${(props) => computeCssValue(props.$paddingRight ?? props.$pr)};
    padding-inline-end: ${(props) => computeCssValue(props.$paddingEnd ?? props.$pe)};
    padding-bottom: ${(props) => computeCssValue(props.$paddingBottom ?? props.$pb)};
    padding-left: ${(props) => computeCssValue(props.$paddingLeft ?? props.$pl)};
    padding-inline-start: ${(props) => computeCssValue(props.$paddingStart ?? props.$ps)};
`;

export const paddingPropTypes = {
    $padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $p: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Shortcut for padding
    $px: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $py: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $paddingTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $pt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Shortcut for paddingTop
    $paddingRight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $pr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Shortcut for paddingRight
    $paddingEnd: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $pe: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Shortcut for paddingEnd
    $paddingBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $pb: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Shortcut for paddingBottom
    $paddingLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $pl: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Shortcut for paddingLeft
    $paddingStart: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $ps: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Shortcut for paddingStart
};

export default paddingMixin;
