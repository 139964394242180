import IllustrationClip from '@public/images/illustration-clip.svg';
import IllustrationSocialNetworkStats from '@public/images/illustration-social-network-stats.svg';
import IllustrationBroadcast from '@public/images/illustration-broadcast.svg';
import Button from '@ui/atoms/Button';
import Stack from '@ui/layout/Stack';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import IconClip from '@ui/icons/IconClip';
import IconFire from '@ui/icons/IconFire';
import { useResponsive } from '../../../../utils/hooks/useResponsive';
import useRouterStore from '../../../../utils/hooks/useRouterStore';
import Link from '@ui/atoms/Link';
import IconRocket from '@ui/icons/IconRocket';

const ActionsWrapper = styled.div`
    display: flex;
    column-gap: 0.5rem;
    justify-content: center;
    width: 100%;
    margin: auto;
`;

const Card = styled(Stack)`
    display: flex;
    align-items: center;
    justify-content: center;
    row-gap: 1.5rem;
    flex-basis: 100%;
    flex-grow: 1;
    align-self: stretch;
    max-width: 296px;
    ${(p) => p.theme.mediaQueries.tabletAndUp} {
        padding: 1.5rem 1rem 1rem 1rem;
        border: 1px solid var(--neutral200);
        border-radius: var(--r-xs);
    }
`;

const CardButton = styled(Button)`
    width: 100%;
`;

const PublicNoBroadcastContent = () => {
    const { link } = useRouterStore();
    return (
        <Card>
            <IllustrationBroadcast />
            <CardButton
                startIcon={<IconRocket />}
                forwardedAs={Link}
                to={link('menu.settings.tab', { tab: 'broadcast' })}
            >
                <FormattedMessage defaultMessage="Diffuser sur les plateformes" />
            </CardButton>
        </Card>
    );
};

const PublicBroadcastContent = () => {
    const { isMobile } = useResponsive();
    const { link } = useRouterStore();

    return (
        <>
            {!isMobile && (
                <Card>
                    <IllustrationClip />
                    <CardButton
                        startIcon={<IconClip />}
                        forwardedAs={Link}
                        to={link('communication', { tab: 'clip' })}
                    >
                        <FormattedMessage defaultMessage="Créer un clip vidéo" />
                    </CardButton>
                </Card>
            )}
            <Card>
                <IllustrationSocialNetworkStats />
                <CardButton
                    startIcon={<IconFire />}
                    forwardedAs={Link}
                    to={link('communication', { tab: 'social-media' })}
                >
                    <FormattedMessage defaultMessage="Poster sur les réseaux sociaux" />
                </CardButton>
            </Card>
        </>
    );
};

const AdditionalActions = ({ isBroadcast }) => {
    return (
        <ActionsWrapper>
            {isBroadcast ? <PublicBroadcastContent /> : <PublicNoBroadcastContent />}
        </ActionsWrapper>
    );
};

export default AdditionalActions;
