import { Redirect, useParams } from 'react-router';
import Form from './Form';
import useCampaignQuery from '@/queries/campaign/useCampaignQuery';
import Skeleton from './Skeleton';

const CampaignEditPage = () => {
    const { showId, campaignId } = useParams<{ campaignId: string; showId: string }>();
    const campaignQuery = useCampaignQuery(campaignId);

    if (campaignQuery.isError) {
        return <Redirect to={`/app/shows/${showId}/monetize`} />;
    }

    if (campaignQuery.data) {
        return <Form campaign={campaignQuery.data} />;
    }

    return <Skeleton />;
};

export default CampaignEditPage;
