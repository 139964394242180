import PropTypes from 'prop-types';
import Stack from '@/components/ui/layout/Stack';
import EpisodeChaptersSelectorListItem from './ChaptersSelectorListItem';

const EpisodeChaptersSelectorList = ({
    chapters,
    selectedChapterId,
    onSelectChapter,
    onDeleteChapter,
}) => {
    return (
        <Stack $gap="0.5rem">
            {chapters.map((chapter) => (
                <EpisodeChaptersSelectorListItem
                    chapter={chapter}
                    key={chapter.id}
                    onSelect={onSelectChapter}
                    onDelete={onDeleteChapter}
                    isSelected={selectedChapterId === chapter.id}
                />
            ))}
        </Stack>
    );
};

EpisodeChaptersSelectorList.propTypes = {
    chapters: PropTypes.array.isRequired,
    selectedChapterId: PropTypes.string.isRequired,
    onSelectChapter: PropTypes.func.isRequired,
    onDeleteChapter: PropTypes.func.isRequired,
};

export default EpisodeChaptersSelectorList;
