import { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { InputTextController } from '@ui/atoms/InputText';
import IconButton from '@ui/atoms/IconButton';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@ui/atoms/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import useWebhooksFormSchema from './useWebhooksFormSchema.hooks';
import { zodResolver } from '@hookform/resolvers/zod';

const WebhooksForm = ({ onSubmit, isLoading, webhooks }) => {
    const intl = useIntl();
    const schema = useWebhooksFormSchema();
    const {
        control,
        handleSubmit,
        formState: { isDirty },
    } = useForm({
        mode: 'onBlur',
        resolver: zodResolver(schema),
        defaultValues: {
            urls: webhooks,
        },
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'urls',
    });

    useEffect(() => {
        if (fields.length === 0) {
            append('');
        }
    }, [fields]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <WebhookList>
                {fields.map((item, index) => (
                    <WebhookWrapper key={item.id}>
                        <InputTextController
                            control={control}
                            name={`urls.${index}.url`}
                            defaultValue={item.url}
                            placeholder={intl.formatMessage({
                                defaultMessage: 'Saisir le lien du webhook',
                            })}
                        />
                        <input type="hidden" {...control.register(`urls[${index}].id`)} />
                        <RemoveButton
                            variant="secondary"
                            type="button"
                            onPress={() => remove(index)}
                            icon={
                                <RemoveIcon
                                    icon={icon({
                                        name: 'xmark',
                                        style: 'solid',
                                    })}
                                />
                            }
                        />
                    </WebhookWrapper>
                ))}
            </WebhookList>
            <AddButton
                onPress={() => append('')}
                variant="ghost"
                startIcon={
                    <AddIcon
                        icon={icon({
                            name: 'add',
                            style: 'solid',
                        })}
                    />
                }
            >
                <FormattedMessage defaultMessage="Ajouter un webhook" />
            </AddButton>
            <SubmitButton isDisabled={!isDirty} isLoading={isLoading} type="submit">
                <FormattedMessage defaultMessage="Enregistrer" />
            </SubmitButton>
        </Form>
    );
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`;
const WebhookList = styled.ul`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    list-style: none;
    padding: 0;
`;
const WebhookWrapper = styled.li`
    display: flex;
    align-items: start;
    column-gap: 0.5rem;
`;
const RemoveIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
`;
const AddButton = styled(Button)`
    padding: 0;
    color: var(--primary);
    align-self: flex-start;
`;
const AddIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: inherit;
`;
const RemoveButton = styled(IconButton)`
    width: 2.5rem;
    height: 2.5rem;
`;
const SubmitButton = styled(Button)`
    align-self: center;
    margin-top: 1rem;
`;

export default WebhooksForm;
