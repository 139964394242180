import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchWebhooks } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import webhookKeys from '@/queries/webhook/webhookKeys';
import { z } from 'zod';

export const WebhookSchema = z.object({
    id: z.number(),
    showId: z.number(),
    url: z.string(),
});
export const WebhooksSchema = z.array(WebhookSchema);
type Key = QueryFunctionContext<ReturnType<typeof webhookKeys.listByShowId>>;
export type Webhook = z.infer<typeof WebhookSchema>;

const queryFn = async ({ queryKey: [{ showId }] }: Key) => {
    const { data } = await fetchWebhooks(showId);

    return WebhooksSchema.parse(camelcaseKeys(data, { deep: true }));
};

const useWebhooksQuery = (showId: string) => {
    return useQuery({
        queryKey: webhookKeys.listByShowId(showId),
        queryFn,
        enabled: !!showId,
    });
};

export default useWebhooksQuery;
