const providerKeys = {
    all: () => [{ entity: 'provider' }],
    allLists: () => [{ ...providerKeys.all()[0], scope: 'list' }],
    listByShowId: (rawShowId: string | number) => [
        { ...providerKeys.allLists()[0], showId: Number(rawShowId) },
    ],
    allDetails: () => [{ ...providerKeys.all()[0], scope: 'detail' }],
    detailByProvider: (rawShowId: string | number, provider: string) => [
        { ...providerKeys.allDetails()[0], showId: Number(rawShowId), provider },
    ],
};

export default providerKeys;
