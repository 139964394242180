import { FormattedMessage } from 'react-intl';
import SourcesDevices from '../organisms/SourcesDevices';
import SourcesPlatformsGraph from '../organisms/SourcesPlatformsGraph';
import SourcesPlatformsTable from '../organisms/SourcesPlatformsTable';
import { useAmplitudeLogEvent } from '@hooks/useAmplitude';
import SourcesOperatingSystems from '../organisms/SourcesOperatingSystems';
import SourcesBrowsers from '../organisms/SourcesBrowsers';
import SourcesBrowsersTable from '../organisms/SourcesBrowsersTable';
import { USER_OPTIONS } from '@/utils/userOptions';
import DeprecatedWhitePaper from '@ui/atoms/DeprecatedWhitePaper';
import LockMessage from '../molecules/LockMessage';
import { PRICING } from '@/utils/pricing';
import HideFor from '../atoms/HideFor';
import { DeprecatedText } from '@ui/atoms/DeprecatedText';

function StatsSources() {
    useAmplitudeLogEvent('Stats Sources Tab Viewed');

    return (
        <>
            <SourcesDevices />
            <SourcesPlatformsGraph />
            <SourcesPlatformsTable />
            <HideFor
                userOptions={USER_OPTIONS.SOURCES_OS_BROWSERS}
                lockedMessage={
                    <>
                        <DeprecatedText weight="bold" size="veryBig">
                            <FormattedMessage defaultMessage="Systèmes d'exploitation et navigateurs" />
                        </DeprecatedText>
                        <DeprecatedWhitePaper my={20} w="100%">
                            <LockMessage
                                type={USER_OPTIONS.SOURCES_OS_BROWSERS}
                                offer={PRICING.BOOST}
                            />
                        </DeprecatedWhitePaper>
                    </>
                }
            >
                <SourcesOperatingSystems />
                <SourcesBrowsers />
                <SourcesBrowsersTable />
            </HideFor>
        </>
    );
}

export default StatsSources;
