import { createApi } from '../callApi';
const contentApi = createApi('CONTENT');

export const fetchCampaign = (campaign) => {
    return contentApi({ method: 'GET', target: `/v1/campaigns/${campaign}` });
};
export const fetchCampaigns = (show, q, page) => {
    return contentApi({ method: 'GET', target: `/v1/shows/${show}/campaigns`, query: { q, page } });
};
export const fetchCampaignMonetization = (show) => {
    return contentApi({
        method: 'GET',
        target: `/v1/shows/${show}/campaigns/monetization_platform`,
    });
};
export const fetchShowsWithTargetspotEnabled = (channel) => {
    return contentApi({ method: 'GET', target: `/v1/channels/${channel}/targetspot_shows` });
};
export const addCampaign = (show, body) => {
    return contentApi({ method: 'POST', target: `/v1/shows/${show}/campaigns`, body });
};
export const addTargetspotOnShow = (show) => {
    return contentApi({ method: 'POST', target: `/v1/shows/${show}/targetspot_monetization` });
};
export const addPodcornOnShow = (show) => {
    return contentApi({ method: 'POST', target: `/v1/shows/${show}/podcorn_monetization` });
};
export const uploadCampaignFile = (campaign, fileModel) => {
    return contentApi({
        method: 'POST',
        target: `/v1/campaigns/${campaign}/file`,
        body: fileModel.rawFileData,
        onUploadProgress: fileModel.onProgress,
        cancelToken: fileModel.cancelToken,
    });
};
export const deleteTargetspotMonetization = (show) => {
    return contentApi({ method: 'DELETE', target: `/v1/shows/${show}/targetspot_monetization` });
};
export const deletePodcornMonetization = (show) => {
    return contentApi({ method: 'DELETE', target: `/v1/shows/${show}/podcorn_monetization` });
};
export const deleteCampaign = (campaign) => {
    return contentApi({ method: 'DELETE', target: `/v1/campaigns/${campaign}` });
};
export const updateCampaign = (campaign, body) => {
    return contentApi({ method: 'PUT', target: `/v1/campaigns/${campaign}`, body });
};
