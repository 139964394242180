import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import SoundcloudBroadcastSettings from './SoundcloudBroadcastSettings';
import Button from '@/components/Button';
import useSoundcloudProviderQuery from '@queries/provider/useSoundcloudProviderQuery.hook';
import { useParams, useHistory } from 'react-router';
import useUnlinkProviderMutation from '@queries/provider/useUnlinkProviderMutation.hook';
import { SOUNDCLOUD } from '@/shared/config/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import RouterLink from '@/components/Link/RouterLink';
import InputText from '@ui/atoms/InputText';
import ExternalLink from '@/components/Link/ExternalLink';
import useShowQuery from '@queries/show/useShowQuery.hook';

interface SoundcloudBroadcastSettingsPageProps {
    logo: string | null;
}

const SoundcloudBroadcastSettingsPage = ({ logo }: SoundcloudBroadcastSettingsPageProps) => {
    const history = useHistory();
    const { showId } = useParams<{ showId: string }>();
    const { data: show } = useShowQuery(showId);
    const { data: soundcloudAccount } = useSoundcloudProviderQuery({
        showId,
    });
    const unlinkSoundcloudAccount = useUnlinkProviderMutation();
    const isShowPmp = show?.hostedOnAusha === false;

    if (!soundcloudAccount) {
        return null;
    }

    return (
        <SoundcloudBroadcastSettingsWrapper>
            <Header>
                <BackLink
                    to={
                        isShowPmp
                            ? `/app/show/${showId}/settings/broadcast/non-hosting`
                            : `/app/show/${showId}/settings/broadcast`
                    }
                    variant="button-tertiary"
                >
                    <FontAwesomeIcon icon={icon({ name: 'arrow-left', style: 'solid' })} />
                </BackLink>
                <Title>SoundCloud</Title>
            </Header>
            <SettingsWrapper>
                <PlatformWrapper>
                    {logo && <PlatformIcon src={logo} alt="SoundCloud" />}
                    <PlatformName>SoundCloud</PlatformName>
                </PlatformWrapper>

                <AccountStatusWrapper>
                    <FormattedMessage defaultMessage="Connecté en tant que" />
                    <AccountInfo>
                        {soundcloudAccount.avatar && <Avatar src={soundcloudAccount.avatar} />}
                        {soundcloudAccount.name}
                        <UnlinkAccountButton
                            variant="ghost"
                            onPress={() => {
                                unlinkSoundcloudAccount.mutate(
                                    {
                                        showId,
                                        provider: SOUNDCLOUD,
                                    },
                                    {
                                        onSuccess: () => {
                                            isShowPmp
                                                ? history.push(
                                                      `/app/show/${showId}/settings/broadcast/non-hosting`,
                                                  )
                                                : history.push(
                                                      `/app/show/${showId}/settings/broadcast`,
                                                  );
                                        },
                                    },
                                );
                            }}
                        >
                            <FormattedMessage defaultMessage="Délier le compte" />
                        </UnlinkAccountButton>
                    </AccountInfo>
                </AccountStatusWrapper>

                <ChannelLinkInputWrapper>
                    <InputText
                        value={soundcloudAccount.url}
                        label={<FormattedMessage defaultMessage="Lien de votre chaîne YouTube" />}
                        isReadOnly
                        copyToClipboardEnabled
                    />
                    <ChannelLinkActions>
                        {soundcloudAccount.url && (
                            <ExternalLink
                                href={soundcloudAccount.url}
                                target="_blank"
                                variant="button-secondary"
                                startIcon={
                                    <FontAwesomeIcon
                                        icon={icon({
                                            name: 'up-right-from-square',
                                            style: 'solid',
                                        })}
                                    />
                                }
                            >
                                <FormattedMessage defaultMessage="Ouvrir le lien" />
                            </ExternalLink>
                        )}
                    </ChannelLinkActions>
                </ChannelLinkInputWrapper>
                <SoundcloudBroadcastSettings />
            </SettingsWrapper>
        </SoundcloudBroadcastSettingsWrapper>
    );
};

const SoundcloudBroadcastSettingsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;
const Header = styled.div`
    display: flex;
    align-items: center;
    gap: 0.75rem;
`;
const BackLink = styled(RouterLink)`
    border-radius: var(--r-full);
    background-color: var(--white);
`;
const Title = styled.h1``;
const SettingsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: 2rem;
`;
const AccountStatusWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
`;
const AccountInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;
const Avatar = styled.img`
    width: 1.5rem;
    border-radius: var(--r-full);
`;
const UnlinkAccountButton = styled(Button)`
    padding: 0;
`;
const PlatformWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.75rem;
`;
const PlatformIcon = styled.img`
    width: 1.5rem;
    height: 1.5rem;
`;
const PlatformName = styled.h2`
    font-weight: var(--fw-semibold);
    font-size: var(--fs-body-l);
`;
const ChannelLinkInputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;
const ChannelLinkActions = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
`;

export default SoundcloudBroadcastSettingsPage;
