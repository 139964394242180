import { useMutation, useQueryClient } from '@tanstack/react-query';
import { psoSaveStores } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import psoKeys from './psoKeys';

export interface MutationFnProps {
    showId: number;
    stores: string[];
}

const mutationFn = async ({ showId, stores }: MutationFnProps) => {
    const { data } = await psoSaveStores(showId, { stores });
    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const useSaveStoresMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: (_, { showId }) => {
            queryClient.invalidateQueries(psoKeys.globalConfiguration(`${showId}`));
        },
    });
};

export default useSaveStoresMutation;
