import ListenersGraphAndTable from './ListenersGraphAndTable';
import ListenersPodcastRanking from './ListenersPodcastRanking';
import { useAmplitudeLogEvent } from '@/shared/hooks/useAmplitude';
import ListenersStatsSetOfOveralls from './ListenersStatsSetOfOveralls';
import DownloadsComparison from './DownloadsComparison';
import ListenersPodcastRankingTable from './ListenersPodcastRankingTable';

const StatsListeners = () => {
    useAmplitudeLogEvent('Stats Downloads Tab Viewed');

    return (
        <>
            <ListenersStatsSetOfOveralls />
            <ListenersGraphAndTable />
            <DownloadsComparison />
            <ListenersPodcastRanking />
            <ListenersPodcastRankingTable />
        </>
    );
};

export default StatsListeners;
