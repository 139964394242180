import { computed } from 'mobx';
import Model from './Model';
import { date } from './resolvers';
import { CLIP_STATUS } from '@/shared/config/constants';
import { apiCall } from '@/components/legacy/api';

class ClipFormatModel extends Model {
    static attributes = {
        id: 'id',
        format: 'format',
        status: 'state',
        url: 'url',
        imageUrl: 'image_url',
        previewUrl: 'preview_url',
        fullCover: 'full_cover',
        clip: {
            fromApi: {
                key: 'podcast_video_clip',
                resolve(clip) {
                    return {
                        adminTitle: clip?.data.admin_title,
                        title: clip?.data.title,
                        id: clip?.data?.id,
                        color: clip?.data?.color,
                        duration: clip?.data?.duration,
                        episodeId: clip?.data?.podcast_id,
                    };
                },
            },
        },
        createdAt: date('created_at'),
        updatedAt: date('updated_at'),
    };

    /* ---- States ---- */

    @computed
    get isPending() {
        return this.status === CLIP_STATUS.PENDING;
    }

    @computed
    get isLoading() {
        return this.status === CLIP_STATUS.LOADING;
    }

    @computed
    get succeeded() {
        return this.status === CLIP_STATUS.DONE;
    }

    @computed
    get failed() {
        return this.status === CLIP_STATUS.ERROR;
    }

    /* ---------------- */

    setAdminTitle(title) {
        this.clip.adminTitle = title;
    }

    @apiCall
    refresh() {
        return this.store.fetchClipFormat(this.id);
    }

    @apiCall
    delete() {
        return this.store.deleteClipFormat(this);
    }
}

export default ClipFormatModel;
