import { z } from 'zod';
import { useIntl } from 'react-intl';
import { FORM_VALIDATION_MESSAGE } from '@/shared/services/validationMessageService';

const useNewsletterFormSchema = () => {
    const intl = useIntl();

    return z.object({
        activated: z.boolean({
            required_error: intl.formatMessage(FORM_VALIDATION_MESSAGE.required),
        }),
        callToActions: z.array(z.any()),
    });
};

export type NewsletterFormSchema = z.infer<ReturnType<typeof useNewsletterFormSchema>>;

export default useNewsletterFormSchema;
