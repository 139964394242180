import PropTypes from 'prop-types';
import Text from '@/components/ui/atoms/Text';
import styled from 'styled-components';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Insight = ({ name, stat }) => {
    const parsedStat = stat ? parseFloat(stat) : 0;
    return (
        <InsightWrapper>
            {stat ? (
                <InsightStat>{Math.round(parsedStat * 100) / 100}</InsightStat>
            ) : (
                <EmptyStat
                    icon={icon({
                        name: 'minus',
                        style: 'solid',
                    })}
                />
            )}
            <InsightName>{name}</InsightName>
        </InsightWrapper>
    );
};

const InsightWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    max-width: 5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
const InsightStat = styled(Text)`
    font-size: var(--fs-body-l);
    font-weight: var(--fw-bold);
`;
const EmptyStat = styled(FontAwesomeIcon)`
    height: 1.375rem;
`;
const InsightName = styled(Text)`
    font-size: var(--fs-body-s);
    color: var(--neutral500);
`;

Insight.propTypes = {
    name: PropTypes.string.isRequired,
    stat: PropTypes.number,
};

export default Insight;
