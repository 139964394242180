import { ListBoxItem } from '@/components/ui/atoms/ListBoxItem';
import { Category } from '@/queries/category/useCategoriesQuery.hook';
import { defineMessages, useIntl } from 'react-intl';

const nameTranslations = defineMessages({
    arts: { defaultMessage: 'Arts' },
    books: { defaultMessage: 'Livres' },
    design: { defaultMessage: 'Design' },
    'fashion-beauty': { defaultMessage: 'Mode et beauté' },
    food: { defaultMessage: 'Gastronomie' },
    'performing-arts': { defaultMessage: 'Arts du spectacle' },
    'visual-arts': { defaultMessage: 'Arts visuels' },
    business: { defaultMessage: 'Affaires' },
    careers: { defaultMessage: 'Carrière' },
    entrepreneurship: { defaultMessage: 'Entrepreneuriat' },
    investing: { defaultMessage: 'Investissement' },
    management: { defaultMessage: 'Gestion' },
    marketing: { defaultMessage: 'Marketing' },
    'non-profit': { defaultMessage: 'À but non lucratif' },
    comedy: { defaultMessage: 'Humour' },
    'comedy-interviews': { defaultMessage: 'Comédie: les interviews' },
    improv: { defaultMessage: 'Improvisation' },
    'stand-up': { defaultMessage: 'One-man shows' },
    education: { defaultMessage: 'Éducation' },
    courses: { defaultMessage: 'Cours' },
    'how-to': { defaultMessage: 'Tuto' },
    'language-learning': { defaultMessage: 'Apprentissage des langues' },
    'self-improvement': { defaultMessage: 'Développement personnel' },
    fiction: { defaultMessage: 'Romans et nouvelles' },
    'comedy-fiction': { defaultMessage: 'Comédies' },
    drama: { defaultMessage: 'Drame' },
    'science-fiction': { defaultMessage: 'Science-fiction' },
    government: { defaultMessage: 'Gouvernement' },
    'health-fitness': { defaultMessage: 'Forme et santé' },
    'alternative-health': { defaultMessage: 'Médecine parallèle' },
    fitness: { defaultMessage: 'Fitness' },
    medicine: { defaultMessage: 'Médecine' },
    'mental-health': { defaultMessage: 'Santé mentale' },
    nutrition: { defaultMessage: 'Alimentation' },
    sexuality: { defaultMessage: 'Sexualité' },
    history: { defaultMessage: 'Histoire' },
    'kids-family': { defaultMessage: 'Enfants et parents' },
    'education-for-kids': { defaultMessage: 'Contenu éducatif pour enfants' },
    parenting: { defaultMessage: 'Parentalité' },
    'pets-animals': { defaultMessage: 'Animaux' },
    'stories-for-kids': { defaultMessage: 'Histoires pour enfants' },
    leisure: { defaultMessage: 'Loisirs' },
    'animation-manga': { defaultMessage: 'Animation & Manga' },
    automotive: { defaultMessage: 'Automobile' },
    aviation: { defaultMessage: 'Aviation' },
    crafts: { defaultMessage: 'Travaux manuels' },
    games: { defaultMessage: 'Jeux' },
    hobbies: { defaultMessage: 'Hobbies' },
    'home-garden': { defaultMessage: 'Maison et jardin' },
    'video-games': { defaultMessage: 'Jeux vidéo' },
    music: { defaultMessage: 'Musique' },
    'music-commentary': { defaultMessage: 'Musique : analyses' },
    'music-history': { defaultMessage: 'Histoire de la musique' },
    'music-interviews': { defaultMessage: 'Musique : les interviews' },
    news: { defaultMessage: 'Actualités' },
    'business-news': { defaultMessage: 'Actualité économique' },
    'daily-news': { defaultMessage: 'Actus du jour' },
    'entertainment-news': { defaultMessage: 'Actualité du divertissement' },
    'news-commentary': { defaultMessage: 'Actualité : analyses' },
    politics: { defaultMessage: 'Politique' },
    'sports-news': { defaultMessage: 'Actualités sportives' },
    'tech-news': { defaultMessage: 'Actualités technologiques' },
    'religion-spirituality': { defaultMessage: 'Religion et spiritualité' },
    buddhism: { defaultMessage: 'Bouddhisme' },
    christianity: { defaultMessage: 'Christianisme' },
    hinduism: { defaultMessage: 'Hindouisme' },
    islam: { defaultMessage: 'Islam' },
    judaism: { defaultMessage: 'Judaïsme' },
    religion: { defaultMessage: 'Religion' },
    spirituality: { defaultMessage: 'Spiritualité' },
    science: { defaultMessage: 'Sciences' },
    astronomy: { defaultMessage: 'Astronomie' },
    chemistry: { defaultMessage: 'Chimie' },
    'earth-sciences': { defaultMessage: 'Sciences de la Terre' },
    'life-sciences': { defaultMessage: 'Science de la vie' },
    mathematics: { defaultMessage: 'Mathématiques' },
    'natural-sciences': { defaultMessage: 'Sciences naturelles' },
    nature: { defaultMessage: 'Nature' },
    physics: { defaultMessage: 'Physique' },
    'social-sciences': { defaultMessage: 'Sciences sociales' },
    'society-culture': { defaultMessage: 'Culture et société' },
    documentary: { defaultMessage: 'Documentaire' },
    'personal-journals': { defaultMessage: 'Journaux personnels' },
    philosophy: { defaultMessage: 'Philosophie' },
    'places-travel': { defaultMessage: 'Destinations et voyages' },
    relationships: { defaultMessage: 'Relations' },
    sports: { defaultMessage: 'Sports' },
    baseball: { defaultMessage: 'Baseball' },
    basketball: { defaultMessage: 'Basketball' },
    cricket: { defaultMessage: 'Cricket' },
    'fantasy-sports': { defaultMessage: 'Sports virtuels' },
    football: { defaultMessage: 'Football américain' },
    golf: { defaultMessage: 'Golf' },
    hockey: { defaultMessage: 'Hockey sur glace' },
    rugby: { defaultMessage: 'Rugby' },
    running: { defaultMessage: 'Course à pied' },
    soccer: { defaultMessage: 'Football' },
    swimming: { defaultMessage: 'Natation' },
    tennis: { defaultMessage: 'Tennis' },
    volleyball: { defaultMessage: 'Volley-ball' },
    wilderness: { defaultMessage: 'Nature' },
    wrestling: { defaultMessage: 'Catch' },
    technology: { defaultMessage: 'Technologies' },
    'true-crime': { defaultMessage: 'Criminologie' },
    'tv-film': { defaultMessage: 'Télévision et cinéma' },
    'after-shows': { defaultMessage: 'Dans les coulisses' },
    'film-history': { defaultMessage: 'Histoire du cinéma' },
    'film-interviews': { defaultMessage: 'Cinéma : les interviews' },
    'film-reviews': { defaultMessage: 'Cinéma : les critiques' },
    'tv-reviews': { defaultMessage: 'Séries : les critiques' },
});

type CategoryItem = ListBoxItem & Category;
const useCategoryItems = (categories: Category[] | undefined): CategoryItem[] => {
    const intl = useIntl();

    if (!categories) return [];

    return categories
        .map((category) => ({
            ...category,
            name: intl.formatMessage(
                nameTranslations[category.slug as keyof typeof nameTranslations],
            ),
            isSelectableCategory: !!category.children,
            children: category.children
                .map((child) => ({
                    ...child,
                    isIndented: true,
                    name: intl.formatMessage(
                        nameTranslations[child.slug as keyof typeof nameTranslations],
                    ),
                }))
                .sort((a, b) => a.name.localeCompare(b.name)),
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
};

export default useCategoryItems;
