import { css } from 'styled-components';
import PropTypes from 'prop-types';
import { resolveColor } from '@/shared/utils/cssVariable';

/**
 * See https://chakra-ui.com/docs/features/style-props
 */
const interactivityMixin = css`
    appearance: ${(props) => props.$appearance};
    cursor: ${(props) => props.$cursor};
    resize: ${(props) => props.$resize};
    user-select: ${(props) => props.$userSelect};
    pointer-events: ${(props) => props.$pointerEvents};
    outline: ${(props) => props.$outline};
    outline-offset: ${(props) => props.$outlineOffset};
    outline-color: ${(props) => resolveColor(props.$outlineColor)};
`;

export const interactivityPropTypes = {
    $appearance: PropTypes.string,
    $cursor: PropTypes.string,
    $resize: PropTypes.string,
    $userSelect: PropTypes.string,
    $pointerEvents: PropTypes.string,
    $outline: PropTypes.string,
    $outlineOffset: PropTypes.string,
    $outlineColor: PropTypes.string,
};

export default interactivityMixin;
