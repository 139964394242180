import { useMemo, useState } from 'react';
import IconButton from '@/components/IconButton';
import { useDebounce } from '@/shared/hooks/useDebounce';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input, SearchField } from 'react-aria-components';
import styled from 'styled-components';
import { useParams } from 'react-router';
import useEpisodesInfiniteQuery from '@/queries/episode/useEpisodesInfiniteQuery.hook';
import { FormattedMessage, useIntl } from 'react-intl';
import Text from '@/components/ui/atoms/Text';
import Stack from '@/components/ui/layout/Stack';
import Alert from '@/components/ui/atoms/Alert';
import EpisodesList from './EpisodesList';
import Button from '@/components/Button';
import addPlaylistEpisodeMutation from '@/queries/playlist/addPlaylistEpisodeMutation';
import { useBodyToastQueue } from '@/shared/hooks/useBodyToastQueue.hook';
import { useQueryClient } from '@tanstack/react-query';
import playlistKeys from '@/queries/playlist/playlistKeys';
import { Episode } from '@/queries/episode/useEpisodesQuery.hook';

interface AddEpisodesToPlaylistProps {
    onClose: () => void;
}

const AddEpisodesToPlaylist = ({ onClose }: AddEpisodesToPlaylistProps) => {
    const { showId, playlistId } = useParams<{ showId: string; playlistId: string }>();
    const perPage = 20;
    const [searchQuery, setSearchQuery] = useState('');
    const query = useDebounce(searchQuery, 500);
    const episodes = useEpisodesInfiniteQuery({ showId, query, perPage });
    const intl = useIntl();
    const [selectedEpisodes, setSelectedEpisodes] = useState<number[]>([]);
    const addPlaylistEpisode = addPlaylistEpisodeMutation();
    const toast = useBodyToastQueue();
    const [isLoading, setIsLoading] = useState(false);
    const queryClient = useQueryClient();

    const items = useMemo(
        () =>
            episodes?.data?.pages
                ?.reduce((acc, page) => acc.concat(page.episodes), [])
                ?.map((episode: Episode) => ({
                    id: episode.id,
                    name: episode.name,
                    image: episode.imageUrl,
                })) || [],
        [episodes?.data?.pages],
    );

    const lastPage = episodes?.data?.pages.slice(-1)[0];
    const isLoadMoreEnabled =
        (lastPage?.pagination?.currentPage ?? 0) < (lastPage?.pagination?.totalPages ?? 0);

    const handleFetchMoreEpisodes = () => {
        if (!episodes?.hasNextPage || episodes?.isFetchingNextPage || episodes?.isFetching) {
            return;
        }
        episodes?.fetchNextPage();
    };

    const handleSelectEpisode = (id: number) => {
        setSelectedEpisodes((prev) => {
            if (prev.includes(id)) {
                return prev.filter((item) => item !== id);
            }
            return [...prev, id];
        });
    };

    const handleAddEpisodesToPlaylist = async () => {
        try {
            setIsLoading(true);
            // TODO: backend is not happy when the episodes are sent in parallel
            for (const episodeId of selectedEpisodes) {
                await addPlaylistEpisode.mutateAsync({ playlistId, episodeId });
                setSelectedEpisodes((prev) => prev.filter((id) => id !== episodeId));
            }
            queryClient.invalidateQueries(playlistKeys.detailById(playlistId));
            setIsLoading(false);
            onClose();
        } catch (e) {
            setIsLoading(false);
            // TODO: Error message to inform the user that some episodes could not be added
            toast.alert();
        }
    };

    return (
        <Wrapper>
            <Stack $gap="0.75rem">
                <Alert variant="info" defaultIcon>
                    <Text fontWeight="--fw-semibold">
                        <FormattedMessage defaultMessage="Attention à la confidentialité de vos épisodes, ils pourraient ne pas apparaître dans la playlist." />
                    </Text>
                </Alert>
                <StyledSearchField
                    value={searchQuery}
                    onChange={(value: string) => {
                        setSearchQuery(value);
                    }}
                >
                    <SearchIconWrapper>
                        <FontAwesomeIcon
                            icon={icon({ name: 'magnifying-glass', style: 'solid' })}
                        />
                    </SearchIconWrapper>
                    <StyledInput
                        placeholder={intl.formatMessage({
                            defaultMessage: 'Rechercher un épisode...',
                        })}
                        autoComplete="off"
                    />
                    {searchQuery.length > 0 && (
                        <ResetSearchButton
                            icon={<ClearIcon icon={icon({ name: 'xmark', style: 'solid' })} />}
                            variant="ghost"
                            aria-label="Clear search"
                        />
                    )}
                </StyledSearchField>
            </Stack>
            <EpisodesList
                episodes={items}
                onSelect={handleSelectEpisode}
                selectedEpisodes={selectedEpisodes}
            />
            {isLoadMoreEnabled && (
                <LoadMoreButton onPress={handleFetchMoreEpisodes} variant="ghost">
                    <FormattedMessage defaultMessage="Charger plus" />
                </LoadMoreButton>
            )}
            <Footer>
                {selectedEpisodes.length > 0 && (
                    <Text fontWeight="--fw-semibold">
                        <FormattedMessage
                            defaultMessage="Vous allez ajouter {count, plural, one {# épisode} other {# épisodes}} à la playlist."
                            values={{ count: selectedEpisodes.length }}
                        />
                    </Text>
                )}
                <Actions>
                    <Button variant="tertiary" onPress={onClose}>
                        <FormattedMessage defaultMessage="Annuler" />
                    </Button>
                    <Button onPress={handleAddEpisodesToPlaylist} isLoading={isLoading}>
                        <FormattedMessage defaultMessage="Ajouter à la playlist" />
                    </Button>
                </Actions>
            </Footer>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
`;
const StyledSearchField = styled(SearchField)`
    transition-duration: 0.2s;
    flex-grow: 1;
    display: flex;
    align-items: center;
    background-color: var(--neutral100);
    border-radius: var(--r-s);
    padding-inline: 0.5rem;
    border: 1px solid transparent;

    &:focus-within {
        border: 1px solid var(--primary);
        outline: 2px solid var(--primary50);
        background-color: var(--white);
    }

    @media (min-width: 1215px) {
        flex-grow: 0;
    }
`;
const StyledInput = styled(Input)`
    border: 0;
    height: 100%;
    padding-block: 0.75rem;
    background-color: transparent;
    min-width: 12rem;
    width: 100%;
`;
const SearchIconWrapper = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    padding-inline: 0.75rem;
    color: var(--neutral500);
`;
const ResetSearchButton = styled(IconButton)`
    transition-duration: 0.2s;
    color: var(--neutral500);
    border-radius: var(--r-s);
    height: 1.75rem;
    width: 1.75rem;
    cursor: pointer;
    padding: 0;
    &:hover {
        background-color: var(--neutral200);
        color: var(--black);
    }
`;
const ClearIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: inherit;
`;
const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 1rem;
`;
const Actions = styled.div`
    display: flex;
    column-gap: 0.5rem;
    margin-left: auto;
`;
const LoadMoreButton = styled(Button)`
    padding: 0;
`;

export default AddEpisodesToPlaylist;
