import type { Stats } from '@queries/stats/useListenersStatsQuery.hook';
import { FormattedMessage, FormattedNumber, FormattedDate } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import dayjs from 'dayjs';
import { useStatsDateSelectorContext } from '@/context/StatsDateSelectorContext';
import Alert from '@/components/ui/atoms/Alert';
import Button from '@/components/Button';
import styled from 'styled-components';

interface GraphHeaderProps {
    tab: string;
    isLoading: boolean;
    stats: Stats;
    tabs: React.ReactNode;
    precision: 'days' | 'weeks' | 'months' | 'years';
    setPrecision: (precision: 'days' | 'weeks' | 'months' | 'years') => void;
}

const GraphHeader = ({
    tab,
    isLoading,
    stats,
    tabs,
    precision,
    setPrecision,
}: GraphHeaderProps) => {
    const { startDate, endDate } = useStatsDateSelectorContext();
    const isSameDate = dayjs(dayjs(startDate).format('YYYY-MM-DD')).isSame(
        dayjs(endDate).format('YYYY-MM-DD'),
    );
    const warningUniqueListeners = dayjs(startDate).isBefore(dayjs('2021-04-16', 'YYYY-MM-DD'));

    return (
        <GraphHeaderWrapper>
            <TopSection>
                <TitleAndDate>
                    <Title>
                        {tab === 'uniqueDownloads' ? (
                            <FormattedMessage defaultMessage="Écoutes uniques" />
                        ) : isLoading ? (
                            <FontAwesomeIcon
                                icon={icon({ name: 'spinner-third', style: 'solid' })}
                                spin
                            />
                        ) : (
                            <>
                                <FormattedNumber value={stats?.sumDownloads ?? 0} />{' '}
                                <FormattedMessage
                                    defaultMessage="{totalListening, plural, =0 {écoute} one{écoute} other {écoutes}}"
                                    values={{
                                        totalListening: stats?.sumDownloads ?? 0,
                                    }}
                                />
                            </>
                        )}
                    </Title>
                    <DateLabel>
                        {isSameDate ? (
                            <FormattedMessage
                                defaultMessage="Le {date}"
                                values={{
                                    date: (
                                        <FormattedDate
                                            value={startDate ?? ''}
                                            year="numeric"
                                            month="long"
                                            day="numeric"
                                        />
                                    ),
                                }}
                            />
                        ) : (
                            <FormattedMessage
                                defaultMessage="Du {from} au {to}"
                                values={{
                                    from: (
                                        <FormattedDate
                                            value={startDate ?? ''}
                                            year="numeric"
                                            month="long"
                                            day="numeric"
                                        />
                                    ),
                                    to: (
                                        <FormattedDate
                                            value={endDate ?? ''}
                                            year="numeric"
                                            month="long"
                                            day="numeric"
                                        />
                                    ),
                                }}
                            />
                        )}
                    </DateLabel>
                </TitleAndDate>
                {tabs}
            </TopSection>
            <BottomSection>
                {warningUniqueListeners ? (
                    <Alert variant="info" defaultIcon>
                        <FormattedMessage
                            defaultMessage="Nous recueillons les données d'auditeurs uniques que depuis le {untilDate}."
                            values={{
                                untilDate: (
                                    <FormattedDate
                                        day="2-digit"
                                        month="long"
                                        year="numeric"
                                        value="2021/04/16"
                                    />
                                ),
                            }}
                        />{' '}
                    </Alert>
                ) : (
                    <FlexDecoy />
                )}

                <PrecisionFiltersWrapper>
                    <PrecisionFilterWrapper>
                        <PrecisionFilter
                            variant="ghost"
                            $active={precision === 'days'}
                            onPress={() => setPrecision('days')}
                        >
                            <FormattedMessage defaultMessage="Jour" />
                        </PrecisionFilter>
                    </PrecisionFilterWrapper>
                    <PrecisionFilterWrapper>
                        <PrecisionFilter
                            variant="ghost"
                            $active={precision === 'weeks'}
                            onPress={() => setPrecision('weeks')}
                        >
                            <FormattedMessage defaultMessage="Semaine" />
                        </PrecisionFilter>
                    </PrecisionFilterWrapper>
                    <PrecisionFilterWrapper>
                        <PrecisionFilter
                            variant="ghost"
                            $active={precision === 'months'}
                            onPress={() => setPrecision('months')}
                        >
                            <FormattedMessage defaultMessage="Mois" />
                        </PrecisionFilter>
                    </PrecisionFilterWrapper>
                    {tab === 'downloads' && (
                        <PrecisionFilterWrapper>
                            <PrecisionFilter
                                variant="ghost"
                                $active={precision === 'years'}
                                onPress={() => setPrecision('years')}
                            >
                                <FormattedMessage defaultMessage="Année" />
                            </PrecisionFilter>
                        </PrecisionFilterWrapper>
                    )}
                </PrecisionFiltersWrapper>
            </BottomSection>
            <Legend>
                <Dot />
                <FormattedMessage defaultMessage="Nombre d'écoutes" />
            </Legend>
        </GraphHeaderWrapper>
    );
};

const GraphHeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
`;
const TopSection = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
`;
const TitleAndDate = styled.div`
    display: flex;
    align-items: flex-end;
    gap: 0.5rem;
`;
const DateLabel = styled.p`
    color: var(--neutral);
`;
const Title = styled.div`
    font-size: var(--fs-heading-m);
    font-weight: var(--fw-semibold);
    line-height: 1.5rem;
`;
const BottomSection = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    & > *:first-child {
        width: max-content;
    }
`;
const FlexDecoy = styled.div``;
const PrecisionFiltersWrapper = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
`;
const PrecisionFilterWrapper = styled.li``;
const PrecisionFilter = styled(Button)<{ $active?: boolean }>`
    color: var(--neutral);
    font-weight: var(--fw-normal);

    ${({ $active }) => $active && `color: var(--neutral900);`}
    &:hover {
        text-decoration: underline;
    }
`;
const Legend = styled.div`
    display: inline-flex;
    gap: 0.5rem;
    align-items: center;
    font-weight: var(--fw-semibold);
`;
const Dot = styled.div`
    width: 0.75rem;
    height: 0.75rem;
    background-color: var(--primary);
    border-radius: var(--r-full);
    margin-bottom: 0.15rem;
`;

export default GraphHeader;
