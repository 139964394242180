import {
    findChildren,
    mergeAttributes,
    Node,
    NodeViewWrapper,
    ReactNodeViewRenderer,
} from '@tiptap/react';
import GenerateWithAiLoading from './GenerateWithAiLoading';

const LoadingNodeView = () => {
    return (
        <NodeViewWrapper as="span" className="loading-component">
            <GenerateWithAiLoading />
        </NodeViewWrapper>
    );
};

const LoadingExtension = Node.create({
    name: 'loading',
    group: 'inline',
    inline: true,
    selectable: false,
    atom: true,
    parseHTML() {
        return [
            {
                tag: 'loading',
            },
        ];
    },
    renderHTML({ HTMLAttributes }) {
        return ['loading', mergeAttributes(HTMLAttributes)];
    },
    addCommands() {
        return {
            setContentLoading:
                () =>
                ({ chain }) => {
                    return chain().clearContent().insertContentLoading().run();
                },
            insertContentLoading:
                () =>
                ({ commands }) => {
                    return commands.insertContent('<loading></loading>');
                },
            clearContentLoading:
                () =>
                ({ commands, state }) => {
                    const loadingNodes = findChildren(
                        state.doc,
                        (node) => node.type.name === this.name,
                    );

                    commands.forEach(loadingNodes, (loadingNode, { commands }) => {
                        return commands.deleteRange({
                            from: loadingNode.pos,
                            to: loadingNode.pos + loadingNode.node.nodeSize,
                        });
                    });

                    return true;
                },
        };
    },
    addNodeView() {
        return ReactNodeViewRenderer(LoadingNodeView);
    },
});

export default LoadingExtension;
