import * as yup from 'yup';

const useEditPasswordFormSchema = () => {
    return yup.object({
        schedulePodcast: yup.boolean(),
        youtubeGenerated: yup.boolean(),
    });
};

export default useEditPasswordFormSchema;
