import { useEffect, useState } from 'react';
import Button from '@ui/atoms/Button';
import { FormattedMessage } from 'react-intl';
import Text from '@ui/atoms/Text';
import Stack from '@ui/layout/Stack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';
import Alert from '@ui/atoms/Alert';
import Cluster from '@ui/layout/Cluster';
import IconButton from '@ui/atoms/IconButton';
import { useEpisodeBeingEdited } from '@app/organisms/EpisodeEditForm/EpisodeEditFormContext';
import TranscriptionModal from '../TranscriptionModal';
import { useEpisodeTranscriptionStatus } from '@app/molecules/EpisodeEditTranscription/EpisodeEditTranscriptionProvider';
import { sendAmplitudeLogEvent } from '@/helpers';

const EpisodeEditTranscriptionSuccess = () => {
    const [isTranscriptionModalOpen, setIsTranscriptionModalOpen] = useState(false);
    const episode = useEpisodeBeingEdited();
    const transcriptionStatus = useEpisodeTranscriptionStatus();
    const localStorageEnabledOutdatedMessage =
        window.localStorage.getItem(`episode-form-${episode.id}-transcription-outdated-message`) ===
            'true' ||
        window.localStorage.getItem(`episode-form-${episode.id}-transcription-outdated-message`) ===
            null;

    const successMessageEnabled =
        window.localStorage.getItem(`episode-form-${episode.id}-transcription`) === 'true';
    const [isSuccessMessageEnabled, setIsSuccessMessageEnabled] = useState(successMessageEnabled);

    const [outdatedMessageEnabled, setOutdatedMessageEnabled] = useState(
        transcriptionStatus?.isOutdated && localStorageEnabledOutdatedMessage,
    );

    useEffect(() => {
        setOutdatedMessageEnabled(
            transcriptionStatus?.isOutdated && localStorageEnabledOutdatedMessage,
        );
    }, [transcriptionStatus]);

    useEffect(() => {
        window.addEventListener('beforeunload', handleCloseSuccessMessage);

        return () => {
            handleCloseSuccessMessage();
            window.removeEventListener('beforeunload', handleCloseSuccessMessage);
        };
    }, []);

    const handleCloseSuccessMessage = () => {
        if (!successMessageEnabled) return;
        setIsSuccessMessageEnabled(false);
        window.localStorage.setItem(`episode-form-${episode.id}-transcription`, 'false');
    };

    const handleCloseOutdatedMessage = () => {
        setOutdatedMessageEnabled(false);
        window.localStorage.setItem(
            `episode-form-${episode.id}-transcription-outdated-message`,
            'false',
        );
    };

    return (
        <Stack $gap="1.5rem">
            {isSuccessMessageEnabled && (
                <Alert
                    variant="success"
                    icon={<SuccessIcon icon={icon({ name: 'circle-check', style: 'solid' })} />}
                >
                    <Cluster $gap="0.75rem" $wrap="no-wrap">
                        <Text variant="bodyM" fontWeight="--fw-semibold">
                            <FormattedMessage defaultMessage="Votre transcription a été générée avec succès ! 🎉" />
                        </Text>
                        <CloseAlertButton
                            onPress={handleCloseSuccessMessage}
                            variant="ghost"
                            icon={<CrossIcon icon={icon({ name: 'xmark', style: 'solid' })} />}
                        />
                    </Cluster>
                </Alert>
            )}
            {outdatedMessageEnabled && (
                <Alert
                    variant="warning"
                    icon={
                        <WarningIcon
                            icon={icon({ name: 'triangle-exclamation', style: 'solid' })}
                        />
                    }
                >
                    <Cluster $gap="0.75rem" $wrap="no-wrap">
                        <Stack>
                            <Text variant="bodyM" fontWeight="--fw-semibold">
                                <FormattedMessage defaultMessage="Cette transcription a été générée à partir d'un ancien fichier audio." />
                            </Text>
                            <Text variant="bodyM">
                                <FormattedMessage defaultMessage="Pour mettre à jour la transcription, supprimez la, puis générez en une à nouveau si besoin." />
                            </Text>
                        </Stack>
                        <CloseAlertButton
                            onPress={handleCloseOutdatedMessage}
                            variant="ghost"
                            icon={<CrossIcon icon={icon({ name: 'xmark', style: 'solid' })} />}
                        />
                    </Cluster>
                </Alert>
            )}
            <Button
                startIcon={<EyeIcon icon={icon({ name: 'eye', style: 'solid' })} />}
                onPress={() => {
                    setIsTranscriptionModalOpen(true);
                    sendAmplitudeLogEvent('Transcription Opened');
                }}
            >
                <FormattedMessage defaultMessage="Voir et éditer" />
            </Button>
            {isTranscriptionModalOpen && (
                <TranscriptionModal
                    isOpen={isTranscriptionModalOpen}
                    onOpenChange={setIsTranscriptionModalOpen}
                    episodeId={episode.id}
                    audioUrl={episode.audioUrlInternal}
                    waveformDataUrl={episode.waveformUrl}
                />
            )}
        </Stack>
    );
};

const EyeIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--white);
`;
const SuccessIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--success);
`;
const WarningIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--warning);
`;
const CrossIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--neutral500);
`;
const CloseAlertButton = styled(IconButton)`
    padding: 0;
`;

export default EpisodeEditTranscriptionSuccess;
