import styled from 'styled-components';
import { Provider } from 'mobx-react';
import stores from '@/stores/RootStore';
import { FormattedMessage } from 'react-intl';
import IntlProvider from '@app/atoms/IntlProvider';

const PopupContent = ({ label, downloads, weight }) => {
    return (
        <Provider stores={stores}>
            <IntlProvider>
                <PolygonName>{label}</PolygonName>
                {downloads ? (
                    <>
                        <p>
                            <FormattedMessage
                                defaultMessage="{downloads, plural, one {# écoute} other {# écoutes}}"
                                values={{ downloads }}
                            />
                        </p>
                        {!!weight && (
                            <p>
                                <FormattedMessage
                                    defaultMessage="{weight, number}% des écoutes"
                                    values={{ weight }}
                                />
                            </p>
                        )}
                    </>
                ) : (
                    <NoDownloads>
                        <FormattedMessage defaultMessage="aucune écoute" />
                    </NoDownloads>
                )}
            </IntlProvider>
        </Provider>
    );
};

const PolygonName = styled.p`
    color: var(--primary);
    font-weight: bold;
`;

const NoDownloads = styled.p`
    color: var(--neutral500);
`;

PopupContent.defaultProps = {
    weight: null,
};

export default PopupContent;
