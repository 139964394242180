import { useEffect, useContext } from 'react';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import DefaultRoute from '../routes/DefaultRoute';
import { EpisodeFiltersContextProvider } from '@/context/EpisodeFiltersContext';
import EpisodesPage from './EpisodesPage';
import PlaylistsPage from './PlaylistsPage';
import MonetizePage from './MonetizePage';
import DeprecatedPaper from '../../ui/atoms/DeprecatedPaper';
import PaymentValidationRequiredBand from '../molecules/PaymentValidationRequiredBand';
import SettingsPage from './SettingsPage';
import StatsPage from './StatsPage';
import Topbar from '../organisms/Topbar';
import NavigationMenu from '../organisms/NavigationMenu';
import UserPage from './UserPage';
import PlayerPage from './PlayerPage';
import PsoPage from './PsoPage';
import {
    DeprecatedNoPermissionRoute,
    ValidSubscriptionRoute,
    ValidSubscriptionNoPmPRoute,
} from '../routes/authorisationRoutes';
import { useAmplitudeSyncUserProperties } from '@hooks/useAmplitude';
import EpisodePreview from './EpisodePreview';
import { STATUS_SHOWS } from '@/utils/statusShows';
import CommunicationPage from './CommunicationPage';
import ClipsPage from './ClipsPage';
import MainLayout from '../layout/Main.layout';
import { CurrentShow } from '@/context/CurrentShowContext';
import SummaryEpisodePage from './SummaryEpisodePage';
import NonHostingPublishedEpisodes from './NonHostingPublishedEpisodes';
import NonHostingDraftEpisodes from './NonHostingDraftEpisodes';
import Footer from '@/components/Footer';
import { ENABLE_NON_HOSTING_EP_MGT } from '@/utils/constants';
import styled from 'styled-components';
import useShowsQuery from '@/queries/show/useShowsQuery.hook';
import useUserQuery from '@/queries/user/useUserQuery.hook';

const ApplicationPage = () => {
    useAmplitudeSyncUserProperties();
    const { showId } = useParams();

    const { data: user } = useUserQuery();
    const currentShow = useContext(CurrentShow);
    const { data: shows, isLoading: showsAreLoading } = useShowsQuery();

    const defaultRoute =
        shows?.shows?.length > 0 ? `/app/show/${showId}/episodes` : `/app/show/new`;
    const isShowAllowed = (showId) => {
        return showId && shows?.shows?.some((show) => show.id === showId);
    };

    const pmpUser = user?.canHostOnAusha === false;
    const pmpDefaultRedirectUrl = ENABLE_NON_HOSTING_EP_MGT
        ? `/app/show/${showId}/workspace`
        : `/app/show/${showId}/pso`;

    useEffect(() => {
        /**
         * Upon navigating towards a "show" page, we snatch the showId from the URL and set it in the context.
         * That way, non-"show" pages can still access the current showId. (e.g. UserPage)
         *
         * The check for !!Number(showId) ensures no accidental overwrite of the showId
         * by _something_ named showId that's really a UserPage's tab identifer or something.
         */
        if (location.pathname.startsWith('/app/show/') && showId && !!Number(showId)) {
            currentShow.setShowId(showId);
        }
    }, [location.pathname]);

    return (
        <MainLayout
            header={
                <>
                    <Topbar />
                    {showsAreLoading ? <NavigationLoadingState /> : <NavigationMenu />}
                </>
            }
            footer={<Footer />}
        >
            <Route
                path="/app/(user|show)/:showId(\d+)"
                render={({ match }) =>
                    !isShowAllowed(match.params.showId) && !showsAreLoading ? (
                        <Redirect to={defaultRoute} />
                    ) : null
                }
            />

            <DeprecatedPaper cssAnim position="relative">
                <Switch location={location}>
                    <Route strict path="/app/user/" component={UserPage} />
                    <Route exact path="/app/show/:showId/episodes/preview/:podId">
                        {pmpUser ? <Redirect to={pmpDefaultRedirectUrl} /> : <EpisodePreview />}
                    </Route>
                    <Route exact path="/app/show/:showId/experimental/episodes/recap/:episodeId">
                        {pmpUser ? <Redirect to={pmpDefaultRedirectUrl} /> : <SummaryEpisodePage />}
                    </Route>
                    <Route
                        path="/app/show/:showId(\d+)/:tab?"
                        render={() => (
                            <Switch>
                                <Route exact path="/app/show/:showId/episodes">
                                    {pmpUser ? (
                                        <Redirect to={pmpDefaultRedirectUrl} />
                                    ) : (
                                        <EpisodeFiltersContextProvider>
                                            <EpisodesPage />
                                        </EpisodeFiltersContextProvider>
                                    )}
                                </Route>
                                <ValidSubscriptionRoute strict path="/app/show/:showId/playlists">
                                    {pmpUser ? (
                                        <Redirect to={pmpDefaultRedirectUrl} />
                                    ) : (
                                        <PlaylistsPage />
                                    )}
                                </ValidSubscriptionRoute>
                                <Route exact path="/app/show/:showId/feed">
                                    {ENABLE_NON_HOSTING_EP_MGT ? (
                                        <EpisodeFiltersContextProvider>
                                            <NonHostingPublishedEpisodes />
                                        </EpisodeFiltersContextProvider>
                                    ) : (
                                        <Redirect to={pmpDefaultRedirectUrl} />
                                    )}
                                </Route>
                                <Route exact path="/app/show/:showId/workspace">
                                    {ENABLE_NON_HOSTING_EP_MGT ? (
                                        <EpisodeFiltersContextProvider>
                                            <NonHostingDraftEpisodes />
                                        </EpisodeFiltersContextProvider>
                                    ) : (
                                        <Redirect to={pmpDefaultRedirectUrl} />
                                    )}
                                </Route>
                                <ValidSubscriptionNoPmPRoute
                                    path="/app/show/:showId/communication"
                                    component={CommunicationPage}
                                    redirect={pmpUser ? pmpDefaultRedirectUrl : null}
                                />
                                <Route path="/app/show/:showId/clips">
                                    {pmpUser ? (
                                        <Redirect to={pmpDefaultRedirectUrl} />
                                    ) : (
                                        <ClipsPage />
                                    )}
                                </Route>
                                <ValidSubscriptionNoPmPRoute
                                    path="/app/show/:showId/stats"
                                    component={StatsPage}
                                    redirect={pmpUser ? pmpDefaultRedirectUrl : null}
                                />
                                <DeprecatedNoPermissionRoute
                                    roles={['viewer']}
                                    showStatus={[STATUS_SHOWS.ARCHIVED_SHOWS]}
                                    path="/app/show/:showId/monetize"
                                    component={MonetizePage}
                                />
                                <Route path="/app/show/:showId/pso" component={PsoPage} />
                                <ValidSubscriptionRoute
                                    path="/app/show/:showId/player/:type(episodes|playlists)/:id"
                                    component={PlayerPage}
                                />
                                <DeprecatedNoPermissionRoute
                                    roles={['viewer', 'editor']}
                                    path="/app/show/:showId/settings"
                                    component={SettingsPage}
                                    authorizedForBlockedSubscription
                                />
                                <DefaultRoute to="/app/show/:showId/episodes" />
                            </Switch>
                        )}
                    />
                </Switch>
            </DeprecatedPaper>
            <PaymentValidationRequiredBand />
        </MainLayout>
    );
};

const NavigationLoadingState = styled.div`
    height: 4rem;
    width: 100%;
    background-color: var(--white);
`;

export default ApplicationPage;
