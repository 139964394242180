import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import Text from '@/components/ui/atoms/Text';
import { ChannelGuestsEditPermissionFormSchema } from '../useChannelGuestsEditPermissionFormSchema';
import ChannelGuestsEditPermissionShowCell from './ChannelGuestsEditPermissionShowCell';
import ChannelGuestsEditPermissionRoleCell from './ChannelGuestsEditPermissionRoleCell';

type ChannelGuestsEditPermissionRow = ChannelGuestsEditPermissionFormSchema['shows'][number];

interface ChannelGuestsEditPermissionTableProps {
    shows: ChannelGuestsEditPermissionRow[];
}

const ChannelGuestsEditPermissionTable = ({ shows }: ChannelGuestsEditPermissionTableProps) => {
    const intl = useIntl();
    const columnHelper = createColumnHelper<ChannelGuestsEditPermissionRow>();
    const columns = [
        columnHelper.accessor('name', {
            header: () => (
                <Text fontWeight="--fw-semibold">
                    {intl.formatMessage({
                        defaultMessage: 'Émissions',
                    })}
                </Text>
            ),
            cell: (info) => {
                return (
                    <ChannelGuestsEditPermissionShowCell
                        name={info.row.original.name}
                        image={info.row.original.image}
                    />
                );
            },
        }),
        columnHelper.accessor('role', {
            header: () => (
                <Text fontWeight="--fw-semibold">
                    {intl.formatMessage({
                        defaultMessage: 'Rôles',
                    })}
                </Text>
            ),
            cell: (info) => <ChannelGuestsEditPermissionRoleCell index={info.row.index} />,
            size: 160,
        }),
    ];

    const table = useReactTable({
        data: shows,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        initialState: {
            sorting: [{ id: 'name', desc: false }],
        },
    });

    return (
        <ScrollableWrapper>
            <Table>
                <Thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <Tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                                <Th
                                    key={header.id}
                                    style={{
                                        width:
                                            header.getSize() !== 150
                                                ? `${header.getSize()}px`
                                                : 'auto',
                                    }}
                                >
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                              header.column.columnDef.header,
                                              header.getContext(),
                                          )}
                                </Th>
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody>
                    {table.getRowModel().rows.map((row) => (
                        <Tr key={row.id}>
                            {row.getVisibleCells().map((cell) => (
                                <Td key={cell.id}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </Td>
                            ))}
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </ScrollableWrapper>
    );
};
const ScrollableWrapper = styled.div`
    max-height: 27.5rem;
    overflow-y: auto;
`;
const Table = styled.table`
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
`;
const Td = styled.td`
    height: 3.5rem;
    padding-inline: 1rem;
`;
const Tr = styled.tr``;
const Th = styled.th`
    padding-inline: 1rem;
    text-align: left;
`;
const Tbody = styled.tbody`
    ${Tr}:not(:last-of-type) {
        border-bottom: 1px solid var(--neutral100);
    }
`;
const Thead = styled.thead`
    position: sticky;
    top: 0;
    width: 100%;
    background-color: var(--neutral50);
    height: 3rem;
    z-index: 1;

    ${Tr} > ${Th}:first-of-type {
        border-top-left-radius: var(--r-m);
        border-bottom-left-radius: var(--r-m);
    }
    ${Tr} > ${Th}:last-of-type {
        border-top-right-radius: var(--r-m);
        border-bottom-right-radius: var(--r-m);
    }
`;

export default ChannelGuestsEditPermissionTable;
