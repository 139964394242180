import { FormattedMessage } from 'react-intl';
import Text from '@ui/atoms/Text';
import styled from 'styled-components';

function TopbarEnterpriseAccountDeactivatedBand() {
    return (
        <TopbarEndedBandWrapper>
            <Text color="white">
                <FormattedMessage
                    defaultMessage="Votre compte est désactivé car nous avons rencontré un souci au niveau de votre paiement. Nous vous prions de {contactLink} pour résoudre ce problème au plus vite 🙂"
                    values={{
                        contactLink: (
                            <CustomLink href="mailto:sales@ausha.co">
                                <FormattedMessage defaultMessage="contacter notre équipe" />
                            </CustomLink>
                        ),
                    }}
                />
            </Text>
        </TopbarEndedBandWrapper>
    );
}

const TopbarEndedBandWrapper = styled.div`
    min-height: 2rem;
    background: var(--info);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
`;

const CustomLink = styled.a`
    text-decoration: none;
    font-weight: bold;
    color: inherit;
`;

export default TopbarEnterpriseAccountDeactivatedBand;
