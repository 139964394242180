import Modal from '@/components/ui/atoms/Modal';
import Text from '@/components/ui/atoms/Text';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import IconButton from '@/components/ui/atoms/IconButton';
import Stack from '@/components/ui/layout/Stack';
import ZebraIllustration from '@/components/ui/atoms/ZebraIllustration';
import ShiningDiamond from '@/components/ui/atoms/ShiningDiamond';
import Button from '@/components/ui/atoms/Button';

interface TranscriptionEditQuotaProModalProps {
    isOpen: boolean;
    onOpenChange: (value: boolean) => void;
}

const TranscriptionEditQuotaProModal = ({
    isOpen,
    onOpenChange,
}: TranscriptionEditQuotaProModalProps) => {
    const handleClose = () => onOpenChange(false);

    return (
        <ModalStyled size="small" isOpen={isOpen} onOpenChange={onOpenChange}>
            <Stack $gap="2rem">
                <CloseButton
                    icon={<CloseIcon icon={icon({ name: 'xmark-large' })} />}
                    onPress={handleClose}
                    variant="ghost"
                    size="small"
                />
                <Stack $gap="1.5rem" $align="center">
                    <ZebraIllustration>
                        <ShiningDiamond />
                    </ZebraIllustration>
                    <Stack $gap="0.5rem">
                        <Text variant="headingS" fontWeight="--fw-bold" textAlign="center">
                            <FormattedMessage defaultMessage="Ajouter du temps de transcription" />
                        </Text>
                        <Text color="--neutral500" textAlign="center">
                            <FormattedMessage defaultMessage="Augmentez votre limite mensuelle de transcription pour l’adapter à vos besoins 🚀" />
                        </Text>
                    </Stack>
                </Stack>
                {/* @ts-ignore */}
                <ContactButton
                    forwardedAs="a"
                    href="mailto:sales@ausha.co"
                    startIcon={<PaperPlaneIcon icon={icon({ name: 'paper-plane' })} />}
                >
                    <FormattedMessage defaultMessage="Nous contacter" />
                </ContactButton>
            </Stack>
        </ModalStyled>
    );
};

const CloseIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
    width: 1rem;
    height: 1rem;
`;
const CloseButton = styled(IconButton)`
    padding: 0;
    align-self: flex-end;
`;
const ModalStyled = styled(Modal)`
    padding: 1.5rem;
`;
const PaperPlaneIcon = styled(FontAwesomeIcon)`
    color: var(--white);
    width: 1rem;
    height: 1rem;
`;
const ContactButton = styled(Button)`
    align-self: center;
`;

export default TranscriptionEditQuotaProModal;
