import { useState } from 'react';
import { ListeningPlatform } from '@/utils/constants';
import { useIntl, FormattedMessage } from 'react-intl';
import ExternalLink from '@/components/Link/ExternalLink';
import Button from '@/components/Button';
import ListeningLinkInput from './ListeningLinkInput';
import { useParams } from 'react-router';
import useUpdateShowListeningLinkMutation from '@/queries/show/useUpdateShowListeningLinkMutation.hook';
import styled from 'styled-components';

type ListeningLink = Record<ListeningPlatform, string>;
interface ListeningLinksStepProps {
    selectedPlatforms: ListeningPlatform[];
    onPreviousStep: () => void;
    onClose: () => void;
}

const ListeningLinksStep = ({
    selectedPlatforms,
    onPreviousStep,
    onClose,
}: ListeningLinksStepProps) => {
    const intl = useIntl();
    const updateListeningLinks = useUpdateShowListeningLinkMutation();
    const { showId } = useParams<{ showId: string }>();

    const [listeningLinks, setListeningLinks] = useState<ListeningLink>({} as ListeningLink);

    const checkUrl = (value: string) => {
        const expression =
            /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
        const urlRegex = new RegExp(expression);
        return urlRegex.test(value);
    };
    const checkListeningLinksValidity = () => {
        if (Object.keys(listeningLinks).length < 1) return false;
        return Object.values(listeningLinks).every((link) => checkUrl(link) === true);
    };

    const onSubmit = () => {
        if (!showId && !checkListeningLinksValidity()) return;
        updateListeningLinks.mutate(
            {
                showId,
                payload: listeningLinks,
            },
            {
                onSuccess: () => {
                    onClose();
                },
            },
        );
    };

    return (
        <>
            <Content>
                <ListeningLinksWrapper>
                    {selectedPlatforms.map((platformKey, i) => (
                        <ListeningLinkInput
                            key={i}
                            index={i}
                            platformKey={platformKey}
                            listeningLinks={listeningLinks}
                            setListeningLinks={setListeningLinks}
                        />
                    ))}
                </ListeningLinksWrapper>
                <HelpWrapper>
                    <FormattedMessage
                        defaultMessage="Besoin d’aide ? {guideLink}"
                        values={{
                            guideLink: (
                                <ExternalLink
                                    href={intl.formatMessage({
                                        defaultMessage:
                                            'https://help.ausha.co/fr/collections/2856443-diffuser-mon-emission#trouver-les-liens-d-ecoute-de-mon-emission',
                                    })}
                                    target="_blank"
                                >
                                    <FormattedMessage defaultMessage="Où trouver les liens d’écoute" />
                                </ExternalLink>
                            ),
                        }}
                    />
                </HelpWrapper>
            </Content>
            <Actions>
                <Button onPress={onPreviousStep} variant="link-secondary">
                    <FormattedMessage defaultMessage="Précédent" />
                </Button>
                <Button isDisabled={!checkListeningLinksValidity()} onPress={onSubmit}>
                    <FormattedMessage defaultMessage="Terminé" />
                </Button>
            </Actions>
        </>
    );
};

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1.5rem;
    width: 100%;
`;
const Actions = styled.div`
    align-self: stretch;
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 1rem;

    & > :last-child {
        align-self: stretch;
    }
`;
const ListeningLinksWrapper = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    padding: 0;
    margin: 0;
`;
const HelpWrapper = styled.div``;

export default ListeningLinksStep;
