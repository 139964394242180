import { Subscription } from '@/queries/subscription/useSubscriptionQuery.hook';
import { priceValueToInt } from '@/shared/utils/number';

interface Prices {
    monthly: {
        base: string;
        extra?: string;
    };
    yearly: {
        base: string;
        extra?: string;
    };
}

export const notify = (subscription: Subscription, prices?: Prices): void => {
    if (!subscription) return;
    const priceBaseAmount = priceValueToInt(subscription.prices.base);
    const priceExtraAmount = subscription.prices.extra
        ? priceValueToInt(subscription.prices.extra)
        : 0;
    const priceDiscountAmount = subscription.discount ? priceValueToInt(subscription.discount) : 0;
    const priceTotalAmount = priceBaseAmount + priceExtraAmount - priceDiscountAmount;

    const periodicityPricing = subscription.yearly ? prices?.yearly : prices?.monthly;

    //@ts-expect-error
    if (dataLayer) {
        //@ts-expect-error
        dataLayer.push({
            ecommerce: {
                purchase: {
                    actionField: {
                        id: subscription.id,
                        revenue: `${priceTotalAmount}`,
                    },
                    products: [
                        {
                            name: subscription.pricing,
                            price: prices ? `${priceValueToInt(periodicityPricing?.base)}` : '0',
                        },
                    ],
                },
            },
            event: 'validatedTransaction',
        });
    }
};
