import { FormattedMessage } from 'react-intl';
import Tabs from '@ui/Tabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import InformationsTab from './InformationsTab';
import PsoCheckerTab from './PsoCheckerTab';
import useHasAccessToPso from '@/shared/hooks/useHasAccessToPso.hook';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import useHasPsoFullUserOption from '@/shared/hooks/useHasPsoFullUserOption.hook';
import useHasAccessToEpisodeAI from '@/shared/hooks/useHasAccessToEpisodeAI.hook';
import AITab from './AITab';
import { ENABLE_EPISODE_AI } from '@/shared/config/constants';
import { useResponsive } from '@/shared/hooks/useResponsive';

interface Tab {
    id: string;
    title: React.ReactNode;
    content: React.ReactNode;
    disabled?: boolean;
}

const EpisodeEditSidebar = () => {
    const { isDesktop } = useResponsive();
    const { showId } = useParams<{ showId: string }>();
    const hasAccessToPso = useHasAccessToPso({ showId });
    const hasAccessToPsoChecker = useHasPsoFullUserOption({ showId });
    const hasAccessToEpisodeAI = useHasAccessToEpisodeAI({ showId });

    const tabs: Tab[] = [
        {
            id: 'informations',
            title: <FormattedMessage defaultMessage="Informations" />,
            content: <InformationsTab />,
        },
    ];

    if (ENABLE_EPISODE_AI && isDesktop) {
        tabs.push({
            id: 'ai',
            title: (
                <TabTitleWrapper>
                    AI
                    {!hasAccessToEpisodeAI && (
                        <DiamondIcon icon={icon({ name: 'gem', style: 'duotone' })} />
                    )}
                </TabTitleWrapper>
            ),
            content: <AITab />,
            disabled: !ENABLE_EPISODE_AI,
        });
    }

    if (hasAccessToPsoChecker) {
        tabs.push({
            id: 'pso',
            title: (
                <TabTitleWrapper>
                    <FormattedMessage defaultMessage="PSO Checker" />
                    {!hasAccessToPso && (
                        <DiamondIcon icon={icon({ name: 'gem', style: 'duotone' })} />
                    )}
                </TabTitleWrapper>
            ),
            content: <PsoCheckerTab />,
            // @ts-ignore // Remove this when PSO Checker is enabled for all users
            disabled: !hasAccessToPso,
        });
    }

    return (
        <Sidebar>
            {hasAccessToPsoChecker || ENABLE_EPISODE_AI ? ( // Remove this condition when PSO Checker is enabled for all users
                <Tabs tabs={tabs} />
            ) : (
                <InformationsTab />
            )}
        </Sidebar>
    );
};

const Sidebar = styled.aside`
    display: flex;
    flex-direction: column;
    gap: 1rem;

    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        width: 25rem;
        flex-shrink: 0;
    }
`;
const TabTitleWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
`;
const DiamondIcon = styled(FontAwesomeIcon)`
    color: var(--primary);
`;

export default EpisodeEditSidebar;
