import { useParams, useLocation } from 'react-router';
import { FormattedMessage } from 'react-intl';
import RouterLink from '@/components/Link/RouterLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';
import { TooltipTrigger } from 'react-aria-components';
import useHasAccessToPsoLiveSearch from '@/shared/hooks/useHasAccessToPsoLiveSearch.hook';
import useHasAccessToPsoCompetitors from '@/shared/hooks/useHasAccessToPsoCompetitors.hook';
import useHasAccessToPsoKeywordsRanking from '@/shared/hooks/useHasAccessToPsoKeywordsRanking.hook';
import { ENABLE_PSO_COMPETITORS } from '@/shared/config/constants';
import styled, { css } from 'styled-components';
import useHasPsoBetaUserOption from '@/shared/hooks/useHasPsoBetaUserOption.hook';

interface PsoNavProps {
    userHasWriteAccess: boolean;
}

const PsoNav = ({ userHasWriteAccess }: PsoNavProps) => {
    const { showId }: { showId: string } = useParams();
    const location = useLocation();
    const page = location.pathname.split('/').pop();
    const hasAccessToPsoLiveSearch = useHasAccessToPsoLiveSearch({ showId });
    const hasAccessToPsoCompetitors = useHasAccessToPsoCompetitors({ showId });
    const hasAccessToPsoKeywordsRanking = useHasAccessToPsoKeywordsRanking({ showId });
    const hasAccessToPsoBeta = useHasPsoBetaUserOption({ showId });

    return (
        <NavWrapper>
            <Section>
                <SectionTitle>
                    <FormattedMessage defaultMessage="Analytiques" />
                </SectionTitle>
                <SectionItem isActive={page === 'overview'}>
                    <SectionIcon icon={icon({ name: 'grid-2', style: 'solid' })} />
                    <StyledRouterLink
                        to={`/app/show/${showId}/pso/overview`}
                        isActive={page === 'overview'}
                    >
                        <FormattedMessage defaultMessage="Vue d'ensemble" />
                    </StyledRouterLink>
                </SectionItem>
                <SectionItem isActive={page === 'details'}>
                    <SectionIcon icon={icon({ name: 'arrow-trend-up', style: 'solid' })} />
                    <StyledRouterLink
                        to={`/app/show/${showId}/pso/details`}
                        isActive={page === 'details'}
                    >
                        <FormattedMessage defaultMessage="Suivi de mots-clés" />
                    </StyledRouterLink>
                </SectionItem>
                {hasAccessToPsoKeywordsRanking && (
                    <SectionItem isActive={page === 'ranking'}>
                        <SectionIcon icon={icon({ name: 'check-circle', style: 'solid' })} />
                        <StyledRouterLink
                            to={`/app/show/${showId}/pso/ranking`}
                            isActive={page === 'ranking'}
                        >
                            <FormattedMessage defaultMessage="Classement" />
                        </StyledRouterLink>
                    </SectionItem>
                )}
                {ENABLE_PSO_COMPETITORS && (
                    <SectionItem isActive={page === 'competitors'}>
                        <SectionIcon icon={icon({ name: 'users', style: 'solid' })} />
                        <StyledRouterLink
                            to={`/app/show/${showId}/pso/competitors`}
                            isActive={page === 'competitors'}
                        >
                            <FormattedMessage defaultMessage="Compétiteurs" />
                            {!hasAccessToPsoCompetitors && (
                                <DiamondIcon icon={icon({ name: 'gem', style: 'duotone' })} />
                            )}
                        </StyledRouterLink>
                    </SectionItem>
                )}
                {hasAccessToPsoLiveSearch && !hasAccessToPsoBeta && (
                    <SectionItem isActive={page === 'livesearch'}>
                        <SectionIcon icon={icon({ name: 'magnifying-glass', style: 'solid' })} />
                        <StyledRouterLink
                            to={`/app/show/${showId}/pso/livesearch`}
                            isActive={page === 'livesearch'}
                        >
                            <FormattedMessage defaultMessage="Live Search" />
                            {!hasAccessToPsoLiveSearch && (
                                <DiamondIcon icon={icon({ name: 'gem', style: 'duotone' })} />
                            )}
                        </StyledRouterLink>
                    </SectionItem>
                )}
            </Section>
            <Sep />
            <Section>
                <SectionTitle>
                    <FormattedMessage defaultMessage="Paramètres" />
                </SectionTitle>
                <SectionItem isActive={page === 'keywords'} isDisabled={!userHasWriteAccess}>
                    <SectionIcon icon={icon({ name: 'gear', style: 'solid' })} />
                    <TooltipTrigger delay={0} closeDelay={0}>
                        <TooltipTriggerWrapper>
                            <StyledRouterLink
                                to={`/app/show/${showId}/pso/keywords`}
                                isActive={page === 'keywords'}
                                isDisabled={!userHasWriteAccess}
                            >
                                <FormattedMessage defaultMessage="Gérer les mots-clés" />
                            </StyledRouterLink>
                            <Tooltip placement="end" passthrough={userHasWriteAccess}>
                                <FormattedMessage defaultMessage="Vous devez être propriétaire de l'émission pour pouvoir configurer le PSO Control Panel." />
                            </Tooltip>
                        </TooltipTriggerWrapper>
                    </TooltipTrigger>
                </SectionItem>
            </Section>
        </NavWrapper>
    );
};

const NavWrapper = styled.nav`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
`;
const Section = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;
const SectionTitle = styled.h2`
    font-size: var(--fs-body-m);
    color: var(--neutral500);
    font-weight: var(--fw-normal);
`;
const SectionItem = styled.div<{ isActive: boolean; isDisabled?: boolean }>`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    /* 0.5rem + 0.25rem from indicator */
    padding-left: 0.725rem;

    ${({ isActive }) =>
        isActive &&
        css`
            padding-left: 0;
            color: var(--primary);

            &:before {
                content: '';
                width: 0.25rem;
                height: 1rem;
                border-radius: var(--r-full);
                background-color: var(--primary);
            }
        `};

    ${({ isDisabled }) =>
        isDisabled &&
        css`
            color: var(--neutral300);
        `};
`;
const StyledRouterLink = styled(RouterLink)<{ isActive: boolean }>`
    transition-duration: 0.2s;
    color: var(--black);

    ${({ isActive }) =>
        isActive &&
        css`
            color: var(--primary);
        `};

    &:hover {
        transition-duration: 0.2s;
        opacity: 0.8;
    }
`;
const Sep = styled.div`
    width: 100%;
    height: 1px;
    background-color: var(--neutral100);
`;
const SectionIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
`;
const DiamondIcon = styled(FontAwesomeIcon)`
    color: var(--primary);
`;

export default PsoNav;
