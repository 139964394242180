import { Modal as AriaModal, ModalOverlay } from 'react-aria-components';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';

const animationProps = {
    initial: { opacity: 0, translateY: 100 },
    animate: { opacity: 1, translateY: 0 },
    exit: { opacity: 0, translateY: 100 },
    transition: {
        type: 'spring',
        stiffness: 256,
        damping: 24,
        duration: 0.5,
        delay: 0,
    },
};

const backgroundMatch = {
    default: 'var(--white)',
    ai: 'var(--gradient-nebula-opacity)',
};

type ModalSize = 'small' | 'medium' | 'large';
type ModalVariant = 'default' | 'ai';

interface ModalProps {
    isDismissable?: boolean;
    isKeyboardDismissDisabled?: boolean;
    isOpen?: boolean;
    defaultOpen?: boolean;
    children?: React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
    size?: ModalSize;
    variant?: ModalVariant;
    'data-testid'?: string;
    onOpenChange: (open: boolean) => void;
}

const Modal = ({ className, size, 'data-testid': dataTestId, variant, ...props }: ModalProps) => {
    return (
        <Overlay {...props}>
            <AnimatePresence>
                <ModalStyled
                    {...props}
                    {...animationProps}
                    $size={size || 'large'}
                    $background={backgroundMatch[variant || 'default']}
                    className={className}
                    data-testid={dataTestId}
                />
            </AnimatePresence>
        </Overlay>
    );
};

const sizeMatch = {
    small: '400px',
    medium: '600px',
    large: '800px',
};
const Overlay = styled(ModalOverlay)`
    background: hsla(0, 0%, 0%, 0.5);
    position: fixed;
    z-index: 100;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    width: 100%;
`;
const ModalStyled = styled(motion(AriaModal))<{ $size: ModalSize; $background: string }>`
    border-radius: var(--r-l);
    width: 100%;
    max-width: ${({ $size }) => sizeMatch[$size]};
    max-height: calc(100dvh - 2rem); /* dvh for mobile devices / 2rem = overlay padding */
    background: ${({ $background }) => $background || 'var(--white)'};
`;

export default Modal;
