import Form from '@app/Form';
import { FormattedMessage, useIntl } from 'react-intl';
import Stack from '@ui/layout/Stack';
import DeprecatedTextInput from '@ui/molecules/DeprecatedTextInput';
import ProviderLoginButton from '@app/atoms/ProviderLoginButton';
import FacebookButton from '@ui/atoms/FacebookButton';
import styled from 'styled-components';
import Button from '@ui/atoms/Button';
import LoginForm from '@/forms/LoginForm';
import useAuthStore from '@hooks/useAuthStore';
import { observer } from 'mobx-react';
import GoogleButton from '@ui/atoms/GoogleButton';
import Text from '@ui/atoms/Text';

const StyledForm = styled(Form)`
    width: 100%;
`;
const LoginButton = styled(Button)`
    width: 100%;
`;
const SeparatorWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
`;
const Line = styled.span`
    height: 1px;
    background-color: var(--neutral100);
    flex: 1;
`;

const AushaClubLoginForm = () => {
    const authStore = useAuthStore();
    const intl = useIntl();
    const onSubmit = (formData) =>
        authStore.login(formData, undefined, undefined, { redirect: false });
    const isLoading = authStore.login.pending;

    return (
        <StyledForm
            h="max-content"
            form={LoginForm}
            onSubmit={onSubmit}
            flex
            column
            reactive
            noToast
        >
            {({ fields, refFocusMount }) => (
                <Stack $gap="2rem">
                    <Stack $gap="0.5rem">
                        <ProviderLoginButton
                            component={GoogleButton}
                            label={<FormattedMessage defaultMessage="Se connecter avec Google" />}
                            provider="google"
                            dataLayerEvent="signup"
                        />
                        <ProviderLoginButton
                            rounded
                            component={FacebookButton}
                            label={<FormattedMessage defaultMessage="Se connecter avec Facebook" />}
                            provider="facebook"
                            dataLayerEvent="signup"
                        />
                    </Stack>
                    <SeparatorWrapper>
                        <Line />
                        <Text color="--neutral500">
                            <FormattedMessage defaultMessage="Ou se connecter avec" />
                        </Text>
                        <Line />
                    </SeparatorWrapper>
                    <DeprecatedTextInput
                        field={fields.email}
                        ref={refFocusMount}
                        label={<FormattedMessage defaultMessage="E-mail de votre compte Ausha" />}
                        placeholder={intl.formatMessage({
                            defaultMessage: 'thomas.pesquet@esa.com',
                        })}
                        accent="secondary"
                        name="email"
                        id="email"
                        autoComplete="email"
                    />
                    <DeprecatedTextInput
                        field={fields.password}
                        label={
                            <FormattedMessage defaultMessage="Mot de passe de votre compte Ausha" />
                        }
                        placeholder={intl.formatMessage({ defaultMessage: '8 caractères minimum' })}
                        accent="secondary"
                        name="current-password"
                        id="current-password"
                        autoComplete="current-password"
                    />
                    <LoginButton type="submit" isDisabled={isLoading} isLoading={isLoading}>
                        <FormattedMessage defaultMessage="Se connecter" />
                    </LoginButton>
                </Stack>
            )}
        </StyledForm>
    );
};

export default observer(AushaClubLoginForm);
