import Text from '@/components/ui/atoms/Text';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Facebook from './Facebook';
import useProvidersQuery from '@/queries/provider/useProvidersQuery.hook';
import { useParams } from 'react-router';
import Skeleton from './Skeleton';
import X from './X';
import Instagram from './Instagram';
import Linkedin from './Linkedin';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import { USER_OPTIONS } from '@/shared/config/userOptions';

const Accounts = () => {
    const { showId } = useParams<{ showId: string }>();
    const { data: providers } = useProvidersQuery(showId);
    const { data: show } = useShowQuery(showId);

    if (providers && show) {
        const hasAccessToInstagramAndLinkedin =
            show?.userOptions?.includes(USER_OPTIONS.AUTOSHARING_INSTAGRAM) &&
            show?.userOptions?.includes(USER_OPTIONS.AUTOSHARING_LINKEDIN);
        return (
            <Container>
                <InnerWrapper>
                    <Text color="--neutral500">
                        <FormattedMessage defaultMessage="Partagez automatiquement vos épisodes sur les réseaux sociaux." />
                    </Text>
                    <Providers>
                        {hasAccessToInstagramAndLinkedin ? (
                            <>
                                <Instagram provider={providers.instagram} />
                                <Facebook provider={providers.facebook} />
                                <Linkedin provider={providers.linkedin} />
                                <X provider={providers.twitter} />
                            </>
                        ) : (
                            <>
                                <Facebook provider={providers.facebook} />
                                <X provider={providers.twitter} />
                                <Instagram provider={providers.instagram} />
                                <Linkedin provider={providers.linkedin} />
                            </>
                        )}
                    </Providers>
                </InnerWrapper>
            </Container>
        );
    }

    return (
        <Container>
            <InnerWrapper>
                <Skeleton />
            </InnerWrapper>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    justify-content: center;
    padding: var(--container-padding-mobile);
    background-color: var(--white);
    border-radius: var(--r-l);

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        padding: var(--container-padding);
    }
`;
const InnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    width: 100%;
    max-width: 42.5rem;
`;
const Providers = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
`;

export default Accounts;
