import { useMutation, useQueryClient } from '@tanstack/react-query';
import { resendVerificationEmail } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import userKeys from '../user/userKeys';

const mutationFn = async () => {
    const { data } = await resendVerificationEmail();
    return camelcaseKeys(data, { deep: true });
};

const useResendVerificationEmailMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: (_) => {
            queryClient.invalidateQueries({
                queryKey: userKeys.all(),
            });
        },
    });
};

export default useResendVerificationEmailMutation;
