import { useMutation, useQueryClient } from '@tanstack/react-query';
import { generateEpisodeTranscript } from '@/api';
import camelcaseKeys from 'camelcase-keys';

const transform = (data) => {
    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const mutationFn = async (episodeId) => {
    const { data } = await generateEpisodeTranscript(episodeId);

    return transform(data);
};

const useGenerateEpisodeTranscriptMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: (_, episodeId) => {
            queryClient.invalidateQueries({
                queryKey: [{ entity: 'episodeTranscriptStatus', episodeId: Number(episodeId) }],
            });
        },
    });
};

export default useGenerateEpisodeTranscriptMutation;
