import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchShowTemplate } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import showTemplateKeys from './showTemplateKeys';
import { z } from 'zod';
import { INSTAGRAM } from '@/shared/config/constants';

export const InstagramShowTemplateSchema = z.object({
    messageTpl: z.string().nullable(),
    provider: z.literal(INSTAGRAM),
});

export type InstagramShowTemplate = z.infer<typeof InstagramShowTemplateSchema>;

type Key = QueryFunctionContext<ReturnType<typeof showTemplateKeys.detailByProvider>>;

const queryFn = async ({ queryKey: [{ showId, provider }] }: Key) => {
    const { data } = await fetchShowTemplate(showId, provider);
    return InstagramShowTemplateSchema.parse(camelcaseKeys(data, { deep: true }));
};

const useInstagramShowTemplateQuery = (showId: string | number) => {
    return useQuery({
        queryKey: showTemplateKeys.detailByProvider(showId, INSTAGRAM),
        queryFn,
    });
};

export default useInstagramShowTemplateQuery;
