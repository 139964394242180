import { createContext, useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon as iconFA } from '@fortawesome/fontawesome-svg-core/import.macro';
import { extractCssVariable } from '@/utils/cssVariables';
import styled, { css } from 'styled-components';
import Cluster from '@ui/layout/Cluster';
import { useAccordionItem } from '@/components/ui/atoms/AccordionItem/useAccordionItem.hook';
import AccordionButton from '@/components/ui/atoms/AccordionButton';
import AccordionPanel from '@/components/ui/atoms/AccordionPanel';
import Text from '@/components/ui/atoms/Text';
import { FormattedMessage } from 'react-intl';
import { useEpisodeBeingEdited } from '@app/organisms/EpisodeEditForm/EpisodeEditFormContext';

const EpisodeEditAccordionItemContext = createContext({});

interface EpisodeEditAccordionItemProps {
    children: React.ReactNode;
    label: React.ReactNode;
    icon: React.ReactNode;
    isOptional?: boolean;
    customContent?: React.ReactNode;
    errorsCount?: number;
    localStorageKey: string;
    'data-testid'?: string;
}

const EpisodeEditAccordionItem = ({
    children,
    label,
    icon,
    isOptional,
    customContent,
    errorsCount = 0,
    localStorageKey,
    'data-testid': dataTestId,
}: EpisodeEditAccordionItemProps) => {
    const episode = useEpisodeBeingEdited();
    const { isExpanded, hasClosed, isDisabled } = useAccordionItem();
    const [displayErrors, setDisplayErrors] = useState(errorsCount > 0);
    const [hasBeenViewed, setBeenViewed] = useState(
        localStorage.getItem(`episode-form-${episode?.id || ''}-${localStorageKey}`) === 'true',
    );

    const hasErrors = errorsCount > 0;

    useEffect(() => {
        if (!hasErrors && hasClosed) {
            setBeenViewed(true);
        }
    }, [hasErrors, hasClosed]);

    useEffect(() => {
        if (hasErrors && !isExpanded) {
            setDisplayErrors(true);
        }
    }, [hasErrors, isExpanded]);

    useEffect(() => {
        if (!hasErrors) {
            setDisplayErrors(false);
        }
    }, [hasErrors]);

    useEffect(() => {
        if (hasBeenViewed) {
            localStorage.setItem(`episode-form-${episode?.id || ''}-${localStorageKey}`, 'true');
        }
    }, [hasBeenViewed]);

    return (
        <Wrapper>
            <AccordionButton data-testid={dataTestId}>
                <AccordionHeader>
                    <AccordionHeaderTitle isDisabled={isDisabled || false}>
                        <IconWrapper
                            hasBeenViewed={hasBeenViewed}
                            hasErrors={displayErrors && hasErrors}
                        >
                            {hasBeenViewed && !displayErrors ? (
                                <FontAwesomeIcon
                                    icon={iconFA({ name: 'check-circle', style: 'solid' })}
                                    color={extractCssVariable('--success500')}
                                />
                            ) : (
                                icon
                            )}
                        </IconWrapper>
                        <Cluster $gap="0.5rem" $align="center">
                            <Text variant="headingS" fontWeight="--fw-bold" color="--black">
                                {label}
                            </Text>
                            {isOptional && (
                                <Text variant="headingS" color="--neutral500">
                                    <FormattedMessage defaultMessage="(facultatif)" />
                                </Text>
                            )}
                        </Cluster>
                    </AccordionHeaderTitle>
                    {customContent}
                    {!isDisabled && (
                        <Cluster $gap="1rem" $align="center">
                            {displayErrors && hasErrors && (
                                <ErrorsLabel>
                                    <FontAwesomeIcon
                                        icon={iconFA({
                                            name: 'circle-exclamation',
                                            style: 'solid',
                                        })}
                                        color={extractCssVariable('--alert500')}
                                    />
                                    <Text
                                        color="--alert500"
                                        variant="bodyS"
                                        fontWeight="--fw-semibold"
                                    >
                                        <FormattedMessage
                                            defaultMessage="{nbErrors, plural, one {# erreur} other {# erreurs}}"
                                            values={{ nbErrors: errorsCount }}
                                        />
                                    </Text>
                                </ErrorsLabel>
                            )}
                            <ChevronIcon
                                $isExpanded={isExpanded || false}
                                icon={iconFA({ name: 'chevron-down', style: 'solid' })}
                                color={extractCssVariable('--neutral500')}
                            />
                        </Cluster>
                    )}
                </AccordionHeader>
            </AccordionButton>
            <AccordionPanel>
                <AccordionPanelContent>{children}</AccordionPanelContent>
            </AccordionPanel>
        </Wrapper>
    );
};

const AccordionHeader = styled.div`
    width: 100%;
    padding: 1.5rem;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
`;
const AccordionHeaderTitle = styled.div<{ isDisabled: boolean }>`
    display: flex;
    align-items: center;
    column-gap: 1rem;

    ${({ isDisabled }) =>
        isDisabled &&
        css`
            opacity: 0.5;
        `}
`;
const AccordionPanelContent = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 1.5rem;
    padding-top: 0.5rem;
    height: 100%;
    width: 100%;
`;
const IconWrapper = styled.div<{ hasBeenViewed: boolean; hasErrors: boolean }>`
    width: 2.5rem;
    height: 2.5rem;
    border-radius: var(--r-s);
    background-color: var(--neutral100);
    display: flex;
    align-items: center;
    justify-content: center;

    & > svg {
        width: 1rem;
        height: 1rem;
    }

    ${({ hasBeenViewed }) =>
        hasBeenViewed &&
        css`
            background-color: var(--success50);
        `}

    ${({ hasErrors }) =>
        hasErrors &&
        css`
            background-color: var(--alert50);
            & > svg {
                color: var(--alert500);
            }
        `}
`;
const ChevronIcon = styled(FontAwesomeIcon)<{ $isExpanded: boolean }>`
    width: 1rem;
    height: 1rem;
    transition: transform 0.3s ease;
    border-radius: var(--r-full);
    padding: 0.5rem;

    &:hover {
        background-color: var(--neutral50);
    }

    ${(props) =>
        props.$isExpanded &&
        css`
            transform: rotate(180deg);
        `}
`;
const Wrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
`;
const ErrorsLabel = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.25rem;
    padding: 0.5rem;
    border-radius: var(--r-s);
    background-color: var(--alert50);
`;

export const useEpisodeEditAccordionItem = () => useContext(EpisodeEditAccordionItemContext);

export default EpisodeEditAccordionItem;
