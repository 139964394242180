import styled, { css } from 'styled-components';

interface EmptyStateProps {
    children: React.ReactNode;
    illustration: string;
    illustrationType: 'image' | 'video';
    illustrationAlign?: 'bottom' | 'right' | 'center';
    illustrationBackground?: string;
    className?: string;
}

const EmptyState = ({
    children,
    illustration,
    illustrationType = 'image',
    illustrationAlign = 'bottom',
    illustrationBackground = '--gradient-dusk',
    className,
}: EmptyStateProps) => {
    return (
        <EmptyStateWrapper className={className}>
            <MainContent>{children}</MainContent>
            <IllustrationBlock align={illustrationAlign} background={illustrationBackground}>
                {illustrationType === 'image' ? (
                    <IllustrationImage
                        className="emptyState__illustration"
                        src={illustration}
                        align={illustrationAlign}
                    />
                ) : (
                    <IllustrationVideo muted autoPlay loop playsInline align={illustrationAlign}>
                        <source src={illustration} />
                    </IllustrationVideo>
                )}
            </IllustrationBlock>
        </EmptyStateWrapper>
    );
};

const EmptyStateWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: 1rem;
    display: flex;
    min-height: 25rem;
    flex-direction: column;
    gap: 1rem;

    & > * {
        flex-grow: 1;
        align-self: stretch;
    }

    ${(p) => p.theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
    }
`;
const MainContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding: 1rem;

    ${(p) => p.theme.mediaQueries.tabletAndUp} {
        min-width: 400px;
    }
`;
const IllustrationBlock = styled.div<{ align: 'bottom' | 'right' | 'center'; background: string }>`
    border-radius: var(--r-l);
    display: flex;
    max-width: 560px;
    min-width: 50%;
    background: ${({ background }) => `var(${background})`};

    ${({ align }) =>
        align === 'center' &&
        css`
            padding: 2rem;
            align-items: center;
            justify-content: center;
        `}

    ${({ align }) =>
        align === 'bottom' &&
        css`
            padding-top: 2rem;
            align-items: flex-end;
            justify-content: center;
        `}
    ${({ align }) =>
        align === 'right' &&
        css`
            padding-block: 2rem;
            padding-left: 2rem;
            align-items: center;
            justify-content: flex-end;
        `}
`;

const BaseIllustration = css<{ align: 'bottom' | 'right' | 'center' }>`
    ${({ align }) =>
        align === 'center' &&
        css`
            max-height: 12rem;
        `}
    ${({ align }) =>
        align === 'bottom' &&
        css`
            border-radius: var(--r-l) var(--r-l) 0 0;
            width: calc(100% - 4rem);
        `}
    ${({ align }) =>
        align === 'right' &&
        css`
            border-radius: var(--r-l) 0 0 var(--r-l);
            width: calc(100% - 2rem);

            ${(p) => p.theme.mediaQueries.mobile} {
                width: calc(100% - 1rem);
            }
        `}
`;

const IllustrationImage = styled.img`
    ${BaseIllustration};
`;
const IllustrationVideo = styled.video`
    ${BaseIllustration};
`;

export default EmptyState;
