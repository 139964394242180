import Stack from '@ui/layout/Stack';
import SelectedChapterTitleField from '@/components/unorganized/EpisodeEditForm/EpisodeEditFormChapters/SelectedChapterTitleField';
import SelectedChapterStartTimeField from '@/components/unorganized/EpisodeEditForm/EpisodeEditFormChapters/SelectedChapterStartTimeField';
import SelectedChapterImageField from '@/components/unorganized/EpisodeEditForm/EpisodeEditFormChapters/SelectedChapterImageField';
import SelectedChapterUrlField from '@/components/unorganized/EpisodeEditForm/EpisodeEditFormChapters/SelectedChapterUrlField';
import SelectedChapterDeleteButton from '@/components/unorganized/EpisodeEditForm/EpisodeEditFormChapters/SelectedChapterDeleteButton';

const SelectedChapter = ({
    index,
    onDeleteChapter,
    setAudioPlayerCursorPosition,
    setAudioPlayerCursorPositionHasChanged,
    isAudioPlaying,
    playAudio,
}) => {
    return (
        <Stack $gap="1rem">
            <SelectedChapterTitleField index={index} />
            <SelectedChapterStartTimeField
                index={index}
                setAudioPlayerCursorPosition={setAudioPlayerCursorPosition}
                setAudioPlayerCursorPositionHasChanged={setAudioPlayerCursorPositionHasChanged}
                isAudioPlaying={isAudioPlaying}
                playAudio={playAudio}
            />
            <SelectedChapterImageField index={index} />
            <SelectedChapterUrlField index={index} />
            <SelectedChapterDeleteButton onPress={() => onDeleteChapter(index)} />
        </Stack>
    );
};

export default SelectedChapter;
