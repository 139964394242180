import { useMutation } from '@tanstack/react-query';
import { addPodcastToPlaylist } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import { Playlist } from './usePlaylistsQuery.hook';

export interface MutationFnProps {
    playlistId: string | number;
    episodeId: number;
}

const mutationFn = async ({ playlistId, episodeId }: MutationFnProps): Promise<Playlist> => {
    const { data } = await addPodcastToPlaylist(playlistId, episodeId);

    return camelcaseKeys(data as any, { deep: true });
};

const addPlaylistEpisodeMutation = () => {
    return useMutation({
        mutationFn,
    });
};

export default addPlaylistEpisodeMutation;
