import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import ComboBox from '@ui/atoms/ComboBox';
import ListBoxItem, { ListBoxItem as IListBoxItem, ListBoxItemImage } from '@ui/atoms/ListBoxItem';
import { Episode } from '@/queries/episode/useListenableEpisodesInfiniteQuery.hook';
import { Key } from 'react-aria';
import { FormattedMessage } from 'react-intl';
import { IMG_PLACEHOLDER } from '@/shared/config/constants';
import useEpisodesInfiniteQuery from '@/queries/episode/useEpisodesInfiniteQuery.hook';
import useShowQuery from '@/queries/show/useShowQuery.hook';

interface SelectEpisodeProps {
    onChange: (episode: IListBoxItem) => void;
    selectedEpisode: IListBoxItem | null;
}

const SelectEpisode = ({ onChange, selectedEpisode }: SelectEpisodeProps) => {
    const { showId } = useParams<{ showId: string }>();
    const { data: show } = useShowQuery(showId);
    const [query, setQuery] = useState('');
    const episodes = useEpisodesInfiniteQuery({ showId, perPage: 15, query: '' });
    const lastPage = episodes?.data?.pages.slice(-1)[0];
    const items =
        episodes?.data?.pages
            ?.reduce((acc: any[], page: { episodes: Episode[] }) => acc.concat(page.episodes), [])
            ?.map((episode) => ({
                id: episode.id,
                name: episode.name,
                hasAudioFile: !!episode.audioUrl,
                image: episode.imageUrl ?? show?.imageUrl ?? IMG_PLACEHOLDER,
            }))
            ?.filter((episode) => episode.name.toLowerCase().includes(query.toLowerCase())) || [];

    useEffect(() => {
        if (selectedEpisode || items.length === 0) return;
        const lastEpisode = items[0];
        onChange(lastEpisode);
    }, [items, selectedEpisode]);

    useEffect(() => {
        if (selectedEpisode) {
            setQuery('');
        }
    }, [selectedEpisode]);

    const handleFetchMoreEpisodes = () => {
        if (!episodes?.hasNextPage || episodes?.isFetchingNextPage || episodes?.isFetching) {
            return;
        }
        episodes?.fetchNextPage();
    };

    const handleSelectEpisode = (id: Key | null) => {
        const episode = items.find((item) => item.id === id);
        if (!episode) return;
        onChange(episode);
        setQuery('');
    };

    return (
        <ComboBoxStyled
            label={
                <FormattedMessage defaultMessage="Pour quel épisode souhaitez-vous créer un clip ?" />
            }
            onInputChange={setQuery}
            onSelectionChange={handleSelectEpisode}
            onBlur={() => {
                setQuery('');
            }}
            items={items}
            isLoading={episodes.isLoading}
            onLoadMore={handleFetchMoreEpisodes}
            loadMoreEnabled={
                (lastPage?.pagination?.currentPage ?? 0) < (lastPage?.pagination?.totalPages ?? 0)
            }
            inputHasImage={true}
            icon={<ListBoxItemImage image={selectedEpisode?.image} />}
            selectedKey={selectedEpisode?.id}
        >
            {(item: IListBoxItem) => <ListBoxItem {...item} />}
        </ComboBoxStyled>
    );
};

const ComboBoxStyled = styled(ComboBox)`
    max-width: 100%;
`;

export default SelectEpisode;
