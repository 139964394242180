import { computed } from 'mobx';
import Model from './Model';
import Progress from './Progress';

const resolve = (x, store) => (x ? new Progress(store, x) : undefined);

class ClipTranscriptionProgressModel extends Model {
    static attributes = {
        transcription: {
            fromApi: { key: 'transcriptpodcastclip', resolve },
        },
    };

    @computed
    get isDone() {
        return this.transcription.isDone;
    }

    @computed
    get hasFailed() {
        return this.transcription.hasFailed;
    }
}

export default ClipTranscriptionProgressModel;
