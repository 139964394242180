import { useState, useEffect } from 'react';
import IpGeolocalizationMap from './IpGeolocalizationMap';
import IpGeolocalizationTable from './IpGeolocalizationTable';
import { useAmplitudeLogEvent } from '@hooks/useAmplitude';
import StatsDataSetSelector from '@app/organisms/StatsDataSetSelector';
import { TABS_STATS_NAME } from '@app/pages/StatsPage';
import { PRICING } from '@/utils/pricing';
import Spinner from '@ui/atoms/Spinner';
import { GEO_JSON_BASE_CDN_URI, ALL_REGION_LAYERS } from './StatsGeolocalization.constants';
import GeolocalizationErrorState from './GeolocalizationErrorState';
import { FormattedMessage } from 'react-intl';
import Alert from '@ui/atoms/Alert';
import IconInfo from '@ui/icons/IconInfo';
import dayjs from 'dayjs';
import useUserStore from '@/utils/hooks/useUserStore';
import TARGET_COUNTRIES_LOOKUP from '../../../../../public/geojson/countries.json';
import { useParams } from 'react-router-dom';
import { useStatsDateSelectorContext } from '@/context/StatsDateSelectorContext';
import { useCountryGeoStats } from './useCountryGeoStats.hook';
import { useRegionGeoStats } from './useRegionGeoStats.hook';
import { useCityGeoStats } from './useCityGeoStats.hook';
import useQuery from '@/utils/hooks/useQuery';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import styled from 'styled-components';
import { USER_ROLES } from '@/utils/constants';

function StatsGeolocalization() {
    useAmplitudeLogEvent('Stats Location Tab Viewed');
    const { itemId, itemType, showId } = useParams();
    const query = useQuery();
    const episodeQuery = query.get('episode');
    const show = useShowQuery(showId);
    const { startDate, endDate } = useStatsDateSelectorContext();
    const { user } = useUserStore();
    const [currentFeature, setCurrentFeature] = useState(null);
    const [visibleFeatures, setVisibleFeatures] = useState([]);
    const [currentLayerLevel, setCurrentLayerLevel] = useState('countries');
    const [regionGeoJSON, setRegionGeoJSON] = useState({});
    const [referencePricing, setReferencePricing] = useState(PRICING.LAUNCH);

    const userCountry = TARGET_COUNTRIES_LOOKUP[user.countryCode];
    const userCountryCoordinates = userCountry ? [userCountry.lng, userCountry.lat] : null;

    useEffect(() => {
        setReferencePricing(
            show?.data?.userRole === USER_ROLES.OWNER ? user?.pricing : show?.data?.ownerPricing,
        );
    }, [show?.data, user, setReferencePricing]);

    const countryGeoStats = useCountryGeoStats({
        showId,
        start: startDate,
        end: endDate,
        episodes: episodeQuery?.split('_'),
        campaign: itemType === 'campaign' ? itemId : null,
        currentLayerLevel,
    });

    const regionGeoStats = useRegionGeoStats({
        showId,
        start: startDate,
        end: endDate,
        episodes: episodeQuery?.split('_'),
        campaign: itemType === 'campaign' ? itemId : null,
        country: currentFeature?.parentId,
        currentLayerLevel,
    });

    const cityGeoStats = useCityGeoStats({
        showId,
        start: startDate,
        end: endDate,
        episodes: episodeQuery?.split('_'),
        campaign: itemType === 'campaign' ? itemId : null,
        region: currentFeature?.properties?.id,
        currentLayerLevel,
        plan: referencePricing,
    });

    useEffect(() => {
        if (!referencePricing || referencePricing === PRICING.LAUNCH) return;
        const fetchStats = async () => {
            let regionJSON = {};
            await Promise.all(
                ALL_REGION_LAYERS.map(async (region) => {
                    const res = await fetch(`${GEO_JSON_BASE_CDN_URI}/${region}.json`);
                    const json = await res.json();
                    regionJSON = { ...regionJSON, [region]: json };
                }),
            );

            setRegionGeoJSON(regionJSON);
        };

        fetchStats();
    }, [referencePricing]);

    const mapIsReady =
        referencePricing === PRICING.LAUNCH ||
        Object.keys(regionGeoJSON).length === ALL_REGION_LAYERS.length;

    const geoStatsError =
        (currentLayerLevel === 'countries' && countryGeoStats.isError) ||
        (currentLayerLevel === 'regions' && regionGeoStats.isError) ||
        (currentLayerLevel === 'cities' && cityGeoStats.isError);

    if (countryGeoStats.isLoading || show.isLoading || user.isLoading) {
        return (
            <LoadingWrapper>
                <Spinner />
            </LoadingWrapper>
        );
    }

    if (geoStatsError)
        return <GeolocalizationErrorState onRetry={() => countryGeoStats.refetch()} />;

    const tableData = currentLayerLevel === 'countries' ? countryGeoStats.data : visibleFeatures;

    const regionsOrCitiesGeoStatsLoading =
        (regionGeoStats.isLoading && regionGeoStats.isFetching) ||
        (cityGeoStats.isLoading && cityGeoStats.isFetching);

    return (
        <GeolocalizationWrapper>
            <StatsDataSetSelector
                tabTitle={<FormattedMessage defaultMessage="Localisation" />}
                tabId={TABS_STATS_NAME.GEOLOC}
            />
            {dayjs(startDate).isBefore('2023-01-01') && (
                <Alert variant="info" icon={<IconInfo />}>
                    <FormattedMessage defaultMessage="L’historique du détail par région et par ville n’est comptabilisé que depuis le 1er janvier 2023" />
                </Alert>
            )}
            {mapIsReady && (
                <IpGeolocalizationMap
                    visibleFeatures={visibleFeatures}
                    setVisibleFeatures={setVisibleFeatures}
                    currentLayerLevel={currentLayerLevel}
                    setCurrentLayerLevel={setCurrentLayerLevel}
                    subscriptionPlan={referencePricing || PRICING.LAUNCH}
                    regionGeoJSON={regionGeoJSON}
                    countryGeoStats={countryGeoStats.data}
                    regionGeoStats={regionGeoStats.data}
                    cityGeoStats={cityGeoStats.data}
                    currentFeature={currentFeature}
                    setCurrentFeature={setCurrentFeature}
                    isLoading={regionsOrCitiesGeoStatsLoading}
                    userCountryCoordinates={userCountryCoordinates}
                />
            )}
            <IpGeolocalizationTable
                data={tableData}
                currentLayerLevel={currentLayerLevel}
                isLoading={regionsOrCitiesGeoStatsLoading}
                startDate={startDate}
                endDate={endDate}
            />
        </GeolocalizationWrapper>
    );
}

const LoadingWrapper = styled.div`
    width: 100%;
    height: 700px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--white);
    margin-top: 1rem;
`;

const GeolocalizationWrapper = styled.div`
    padding-bottom: 4rem;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`;

export default StatsGeolocalization;
