interface QueryParams {
    start: string | null;
    end: string | null;
}

const statsKeys = {
    all: () => [{ entity: 'stats' }],
    downloads: (rawShowId: string, query: QueryParams) => [
        {
            ...statsKeys.all()[0],
            scope: 'downloads',
            showId: Number(rawShowId),
            query,
        },
    ],
    downloadsComparison: (
        rawShowId: string,
        daysAfterPublication: 7 | 15 | 30 | 90,
        episodeIds: number[],
    ) => [
        {
            ...statsKeys.all()[0],
            scope: 'comparison',
            showId: Number(rawShowId),
            daysAfterPublication,
            episodeIds,
        },
    ],
    genders: (rawShowId: string, query: QueryParams) => [
        {
            ...statsKeys.all()[0],
            scope: 'genders',
            showId: Number(rawShowId),
            query,
        },
    ],
    ages: (rawShowId: string, query: QueryParams) => [
        {
            ...statsKeys.all()[0],
            scope: 'ages',
            showId: Number(rawShowId),
            query,
        },
    ],
    agesExport: (rawShowId: string, query: QueryParams) => [
        {
            ...statsKeys.all()[0],
            scope: 'agesExport',
            showId: Number(rawShowId),
            query,
        },
    ],
    operatingSystemsByCampaignId: (campaignId: string, query: QueryParams) => [
        {
            ...statsKeys.all()[0],
            scope: 'operatingSystems',
            campaignId: Number(campaignId),
            query,
        },
    ],
    operatingSystemsByShowId: (showId: string, query: QueryParams) => [
        {
            ...statsKeys.all()[0],
            scope: 'operatingSystems',
            showId: Number(showId),
            query,
        },
    ],
    popularDaysByShowId: (showId: string, days: '7' | '15' | '30' | '90' | 'always') => [
        {
            ...statsKeys.all()[0],
            scope: 'popularDays',
            showId: Number(showId),
            days,
        },
    ],
    popularHoursByShowId: (showId: string, days: '7' | '15' | '30' | '90' | 'always') => [
        {
            ...statsKeys.all()[0],
            scope: 'popularHours',
            showId: Number(showId),
            days,
        },
    ],
    bestDayHourByShowId: (showId: string, days: '7' | '15' | '30' | '90' | 'always') => [
        {
            ...statsKeys.all()[0],
            scope: 'bestDayHour',
            showId: Number(showId),
            days,
        },
    ],
    heatmapByShowId: (showId: string, days: '7' | '15' | '30' | '90' | 'always') => [
        {
            ...statsKeys.all()[0],
            scope: 'heatmap',
            showId: Number(showId),
            days,
        },
    ],
    sourceDevices: (
        showId: string,
        episodes: string[],
        start: string,
        end: string,
        timezone: string,
    ) => [
        {
            ...statsKeys.all()[0],
            scope: 'sourceDevices',
            showId: Number(showId),
            episodes,
            start,
            end,
            timezone,
        },
    ],
    campaignSourceDevices: (
        campaignId: string,
        episodes: string[],
        start: string,
        end: string,
        timezone: string,
    ) => [
        {
            ...statsKeys.all()[0],
            scope: 'campaignSourceDevices',
            campaignId: Number(campaignId),
            episodes,
            start,
            end,
            timezone,
        },
    ],
    sourcePlatformsLeaderboard: (
        showId: string,
        episodes: string[],
        start: string,
        end: string,
        timezone: string,
    ) => [
        {
            ...statsKeys.all()[0],
            scope: 'sourcePlatformsLeaderboard',
            showId: Number(showId),
            episodes,
            start,
            end,
            timezone,
        },
    ],
    sourcePlatforms: (
        showId: string,
        episodes: string[],
        start: string,
        end: string,
        order: 'asc' | 'desc',
        page: number,
        timezone: string,
    ) => [
        {
            ...statsKeys.all()[0],
            scope: 'sourcePlatforms',
            showId: Number(showId),
            episodes,
            start,
            end,
            order,
            page,
            timezone,
        },
    ],
    campaignSourcePlatforms: (
        campaignId: string,
        episodes: string[],
        start: string,
        end: string,
        order: 'asc' | 'desc',
        page: number,
        timezone: string,
    ) => [
        {
            ...statsKeys.all()[0],
            scope: 'campaignSourcePlatforms',
            campaignId: Number(campaignId),
            episodes,
            start,
            end,
            order,
            page,
            timezone,
        },
    ],
    sourceBrowsersLeaderboard: (showId: string, start: string, end: string) => [
        {
            ...statsKeys.all()[0],
            scope: 'sourceBrowsersLeaderboard',
            showId: Number(showId),
            start,
            end,
        },
    ],
    campaignSourceBrowsersLeaderboard: (campaignId: string, start: string, end: string) => [
        {
            ...statsKeys.all()[0],
            scope: 'campaignSourceBrowsersLeaderboard',
            campaignId: Number(campaignId),
            start,
            end,
        },
    ],
    sourceBrowsers: (showId: string, start: string, end: string, page: number) => [
        {
            ...statsKeys.all()[0],
            scope: 'sourceBrowsers',
            showId: Number(showId),
            start,
            end,
            page,
        },
    ],
    campaignSourceBrowsers: (campaignId: string, start: string, end: string, page: number) => [
        {
            ...statsKeys.all()[0],
            scope: 'campaignSourceBrowsers',
            campaignId: Number(campaignId),
            start,
            end,
            page,
        },
    ],
    sourceExport: (
        showId: string,
        start: string,
        end: string,
        episodes: string[],
        timezone: string,
    ) => [
        {
            ...statsKeys.all()[0],
            scope: 'sourceExport',
            showId: Number(showId),
            start,
            end,
            episodes,
            timezone,
        },
    ],
    campaignSourceExport: (
        campaignId: string,
        start: string,
        end: string,
        episodes: string[],
        timezone: string,
    ) => [
        {
            ...statsKeys.all()[0],
            scope: 'campaignSourceExport',
            campaignId: Number(campaignId),
            start,
            end,
            episodes,
            timezone,
        },
    ],
    listenersEpisodesRanking: (
        showId: string,
        start: string,
        end: string,
        order: 'asc' | 'desc',
        page: number,
        perPage: number,
        episodes: string[],
        timezone: string,
    ) => [
        {
            ...statsKeys.all()[0],
            scope: 'listenersEpisodesRanking',
            showId: Number(showId),
            start,
            end,
            order,
            page,
            perPage,
            episodes,
            timezone,
        },
    ],
    campaignListenersEpisodesRanking: (
        campaignId: string,
        start: string,
        end: string,
        order: 'asc' | 'desc',
        page: number,
        perPage: number,
        episodes: string[],
        timezone: string,
    ) => [
        {
            ...statsKeys.all()[0],
            scope: 'campaignListenersEpisodesRanking',
            campaignId: Number(campaignId),
            start,
            end,
            order,
            page,
            perPage,
            episodes,
            timezone,
        },
    ],
    listenersOverall: (showId: string, timezone: string) => [
        {
            ...statsKeys.all()[0],
            scope: 'listenersOverall',
            showId: Number(showId),
            timezone,
        },
    ],
    campaignListenersOverall: (campaignId: string, timezone: string) => [
        {
            ...statsKeys.all()[0],
            scope: 'campaignListenersOverall',
            campaignId: Number(campaignId),
            timezone,
        },
    ],
    listenersStats: (
        showId: string,
        timezone: string,
        episodes: string[],
        start: string,
        end: string,
        precision: string,
    ) => [
        {
            ...statsKeys.all()[0],
            scope: 'listenersStats',
            showId: Number(showId),
            timezone,
            episodes,
            start,
            end,
            precision,
        },
    ],
    listenersStatsUnique: (
        showId: string,
        timezone: string,
        episodes: string[],
        start: string,
        end: string,
        precision: string,
    ) => [
        {
            ...statsKeys.all()[0],
            scope: 'listenersStatsUnique',
            showId: Number(showId),
            timezone,
            episodes,
            start,
            end,
            precision,
        },
    ],
    campaignListenersStats: (
        campaignId: string,
        timezone: string,
        episodes: string[],
        start: string,
        end: string,
        precision: string,
    ) => [
        {
            ...statsKeys.all()[0],
            scope: 'campaignListenersStats',
            campaignId: Number(campaignId),
            timezone,
            episodes,
            start,
            end,
            precision,
        },
    ],
    listenersTopRanking: (
        showId: string,
        timezone: string,
        episodes: string[],
        start: string,
        end: string,
    ) => [
        {
            ...statsKeys.all()[0],
            scope: 'listenersTopRanking',
            showId: Number(showId),
            timezone,
            episodes,
            start,
            end,
        },
    ],
    campaignListenersTopRanking: (
        campaignId: string,
        timezone: string,
        episodes: string[],
        start: string,
        end: string,
    ) => [
        {
            ...statsKeys.all()[0],
            scope: 'campaignListenersTopRanking',
            campaignId: Number(campaignId),
            timezone,
            episodes,
            start,
            end,
        },
    ],
    listenersRanking: (
        showId: string,
        timezone: string,
        episodes: string[],
        start: string,
        end: string,
        order: 'asc' | 'desc',
        page: number,
        perPage: number,
    ) => [
        {
            ...statsKeys.all()[0],
            scope: 'listenersRanking',
            showId: Number(showId),
            timezone,
            episodes,
            start,
            end,
            order,
            page,
            perPage,
        },
    ],
    campaignListenersRanking: (
        campaignId: string,
        timezone: string,
        episodes: string[],
        start: string,
        end: string,
        order: 'asc' | 'desc',
        page: number,
        perPage: number,
    ) => [
        {
            ...statsKeys.all()[0],
            scope: 'campaignListenersRanking',
            campaignId: Number(campaignId),
            timezone,
            episodes,
            start,
            end,
            order,
            page,
            perPage,
        },
    ],
};

export default statsKeys;
