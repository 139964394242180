import { FormattedMessage } from 'react-intl';
import { AnimatePresence, motion } from 'framer-motion';
import Link from '@ui/atoms/Link';
import { connect } from '@app/decorators/connect';
import DeprecatedTextInput from '@ui/molecules/DeprecatedTextInput';
import DeprecatedIcon from '@ui/atoms/DeprecatedIcon';
import info from '@public/icons/info-full.svg';
import Tooltip from '@ui/atoms/Tooltip';
import Text from '@ui/atoms/Text';
import { CopyLinkButton, OpenLinkButton } from '../UsefulLinkButtons';
import Quota from './Quota';
import Stack from '@ui/layout/Stack';
import Cluster from '@ui/layout/Cluster';
import { useParams } from 'react-router';
import useShowQuery from '@/queries/show/useShowQuery.hook';

const spring = {
    type: 'spring',
    damping: 20,
    stiffness: 300,
    duration: 0.5,
};

const animate = {
    hidden: { opacity: 0, height: 0, display: 'none' },
    visible: { opacity: 1, height: 'max-content', display: 'block' },
};

const TooltipMessage = ({ isActivated, linkToPodcastPageTab }) => (
    <AnimatePresence>
        {isActivated && (
            <motion.div
                initial="hidden"
                animate={isActivated ? 'visible' : 'hidden'}
                exit="hidden"
                variants={animate}
                transition={spring}
            >
                <Text fontWeight="--fw-semibold">
                    <FormattedMessage defaultMessage="Lien d’abonnement à la newsletter" />
                </Text>
                <Tooltip type="info" w="max-content">
                    <DeprecatedIcon as={info} color="--neutral500" mr={10} />
                    <Text variant="footnote">
                        <FormattedMessage
                            defaultMessage="Pour accéder à votre lien d’abonnement veuillez {howToActivateIt}"
                            values={{
                                howToActivateIt: (
                                    <Text
                                        as={Link}
                                        to={linkToPodcastPageTab}
                                        variant="footnote"
                                        color="--primary"
                                    >
                                        <FormattedMessage defaultMessage="activer votre Site Web." />
                                    </Text>
                                ),
                            }}
                        />
                    </Text>
                </Tooltip>
            </motion.div>
        )}
    </AnimatePresence>
);

const enhance = connect(({ routerStore, userStore, pageSettingsStore, autoSharingStore }) => {
    const template = autoSharingStore.newsletterShowTemplate;
    const user = userStore.user;
    return {
        isPublished: pageSettingsStore.settings?.isPublished,
        isActivated: template?.activated,
        linkToPodcastPageTab: routerStore.link('communication', { tab: 'page' }),
        userIsEnterprise: user.pricing === 'enterprise',
    };
});

const NewsletterContentSettings = ({
    isPublished,
    linkToPodcastPageTab,
    isActivated,
    userIsEnterprise,
}) => {
    const { showId } = useParams();
    const { data: show } = useShowQuery(showId);

    const linkSubscribe = `${show?.siteUrl}?s=1`;

    return isPublished ? (
        <Stack $gap="2rem">
            <Stack $gap="1rem">
                <DeprecatedTextInput
                    value={linkSubscribe}
                    label={<FormattedMessage defaultMessage="Lien d’inscription à la newsletter" />}
                />
                <Cluster $gap="0.5rem" $justify="flex-end">
                    <CopyLinkButton link={linkSubscribe} />
                    <OpenLinkButton link={linkSubscribe} />
                </Cluster>
            </Stack>
            <Quota userIsEnterprise={userIsEnterprise} />
        </Stack>
    ) : (
        <TooltipMessage isActivated={isActivated} linkToPodcastPageTab={linkToPodcastPageTab} />
    );
};

export default enhance(NewsletterContentSettings);
