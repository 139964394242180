import { FormattedMessage } from 'react-intl';
import { PeakTimeHeatmap } from '@/queries/stats/usePeakTimeHeatmapQuery.hook';
import { PEAKTIME_HEATMAP_COLORS_RANGE } from './HeatmapChart';
import Text from '@/components/ui/atoms/Text';
import styled from 'styled-components';

interface HeatmapChartLegendProps {
    categories: PeakTimeHeatmap['categories'];
}

const HeatmapChartLegend = ({ categories }: HeatmapChartLegendProps) => {
    if (!categories) return null;

    const currentMomentsLength = categories.length - 1;

    return (
        <Wrapper>
            <Text fontWeight="--fw-bold">
                <FormattedMessage defaultMessage="Nombre d'écoutes" />
            </Text>
            <Legend>
                {categories.map((category, i) => (
                    <LegendItem key={category.from}>
                        <ColorBlock color={PEAKTIME_HEATMAP_COLORS_RANGE[i]} />
                        <Text>
                            {i !== currentMomentsLength ? (
                                <FormattedMessage
                                    defaultMessage="De {from} à {to}"
                                    values={{ from: category.from, to: category.to }}
                                />
                            ) : (
                                <FormattedMessage
                                    defaultMessage="Plus de {from}"
                                    values={{ from: category.from }}
                                />
                            )}
                        </Text>
                    </LegendItem>
                ))}
            </Legend>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
`;
const Legend = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
`;
const LegendItem = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    flex-shrink: 0;

    ${({ theme }) => theme.mediaQueries.tabletAndUp} {
        flex: 1;
    }
`;
const ColorBlock = styled.div<{ color: string }>`
    width: 2.25rem;
    height: 2.25rem;
    background-color: ${({ color }) => color};
    border-radius: var(--r-xs);
`;

export default HeatmapChartLegend;
