import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchShowGuestPermissions } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import guestKeys from './guestKeys';
import { z } from 'zod';
import { GUEST_USER_ROLES } from '@/shared/config/constants';

export const ShowGuestRoleSchema = z.enum([
    GUEST_USER_ROLES.ADMIN,
    GUEST_USER_ROLES.EDITOR,
    GUEST_USER_ROLES.VIEWER,
] as const);

export const ShowGuestPermissionSchema = z.object({
    id: z.number(),
    role: ShowGuestRoleSchema,
    showId: z.number(),
    userId: z.number(),
    userAvatar: z.string().nullable(),
    userEmail: z.string().nullable(),
    userName: z.string().nullable(),
});

const ShowGuestPermissionsSchema = z.array(ShowGuestPermissionSchema);

type Key = QueryFunctionContext<ReturnType<typeof guestKeys.permissionListByShowId>>;

export type ShowGuestPermission = z.infer<typeof ShowGuestPermissionSchema>;

const queryFn = async ({ queryKey: [{ showId }] }: Key) => {
    const { data } = await fetchShowGuestPermissions(showId);
    return ShowGuestPermissionsSchema.parse(camelcaseKeys(data, { deep: true }));
};

const useShowGuestPermissionsQuery = (showId: string | number) => {
    return useQuery({
        queryKey: guestKeys.permissionListByShowId(showId),
        queryFn,
        enabled: !!showId,
    });
};

export default useShowGuestPermissionsQuery;
