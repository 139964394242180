import { useQuery } from '@tanstack/react-query';
import personalAccessTokenKeys from './personalAccessTokenKeys';
import { fetchPersonalAccessTokens } from '@/api';
import { z } from 'zod';
import camelcaseKeys from 'camelcase-keys';

const PersonalAccessTokenSchema = z.object({
    createdAt: z.string(),
    expiresAt: z.string(),
    id: z.string(),
    name: z.string(),
});
const PersonalAccessTokensSchema = z.array(PersonalAccessTokenSchema);

export type PersonalAccessToken = z.infer<typeof PersonalAccessTokenSchema>;

const queryFn = async () => {
    const { data } = await fetchPersonalAccessTokens();
    return PersonalAccessTokensSchema.parse(camelcaseKeys(data, { deep: true }));
};

const usePersonalAccessTokensQuery = () => {
    return useQuery({
        queryKey: personalAccessTokenKeys.list(),
        queryFn,
    });
};

export default usePersonalAccessTokensQuery;
