import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Tooltip, { TooltipTriggerWrapper } from '@ui/Tooltip';
import { TooltipTrigger } from 'react-aria-components';
import { usePsoCheckerContext } from '@/context/PsoCheckerContext';
import CircularProgress from '@ui/atoms/CircularProgress';
import styled from 'styled-components';

interface ScoreProps {
    confirmModalMode?: boolean;
}

const Score = ({ confirmModalMode }: ScoreProps) => {
    const intl = useIntl();
    const confirmModal = confirmModalMode ?? false;
    const { psoCheckerScore } = usePsoCheckerContext();
    const score = psoCheckerScore ?? 0;
    const scoreThresholds = [
        {
            threshold: 0,
            color: '--alert',
            title: <FormattedMessage defaultMessage="Faible" />,
            description: (
                <FormattedMessage defaultMessage="Commencez à optimiser le référencement de votre épisode en suivant les recommandations. 💪" />
            ),
        },
        {
            threshold: 25,
            color: '--warning',
            title: <FormattedMessage defaultMessage="Moyen" />,
            description: (
                <FormattedMessage defaultMessage="Continuez comme ça ! Afin d’augmenter votre score, suivez les recommandations. 🚀" />
            ),
        },
        {
            threshold: 70,
            color: '--success',
            title: <FormattedMessage defaultMessage="Élevé" />,
            description: (
                <FormattedMessage defaultMessage="Bien joué ! Le référencement de votre épisode est optimal. 🥳" />
            ),
        },
    ];

    let scoreIndex = 0;
    for (let i = 0; i < scoreThresholds.length; i++) {
        if (scoreThresholds[i].threshold <= score) {
            scoreIndex = i;
        } else {
            break;
        }
    }

    const reachedThreshold = scoreThresholds[scoreIndex];

    return (
        <Wrapper>
            <TitleAndDescriptionWrapper>
                <TitleWrapper>
                    <CheckerTitle color={reachedThreshold.color}>
                        {reachedThreshold.title}
                    </CheckerTitle>
                    <TooltipTrigger delay={0} closeDelay={0}>
                        <TooltipTriggerWrapper>
                            <HelpIcon icon={icon({ name: 'info-circle', style: 'solid' })} />
                        </TooltipTriggerWrapper>
                        <Tooltip placement="top">
                            <FormattedMessage
                                defaultMessage="Ce score indique dans quelle mesure le titre, la description et les tags de votre épisode sont optimisés pour la visibilité dans les recherches. <link>En savoir plus sur le PSO Checker</link>."
                                values={{
                                    link: (chunks: string) => (
                                        <TooltipLink
                                            href={intl.formatMessage({
                                                defaultMessage:
                                                    'https://help.ausha.co/fr/articles/10057828-comment-utiliser-le-pso-checker-afin-d-optimiser-le-referencement-de-chacun-de-mes-episodes',
                                            })}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {chunks}
                                        </TooltipLink>
                                    ),
                                }}
                            />
                        </Tooltip>
                    </TooltipTrigger>
                </TitleWrapper>
                <ContentTextWrapper>
                    <CheckerDescription>{reachedThreshold.description}</CheckerDescription>
                </ContentTextWrapper>
            </TitleAndDescriptionWrapper>
            <GraphWrapper>
                <CircularProgress
                    size={96}
                    percentage={score}
                    backgroundColor={
                        confirmModal
                            ? `${reachedThreshold.color}100`
                            : `${reachedThreshold.color}50`
                    }
                    progressColor={reachedThreshold.color}
                    strokeWidth={14}
                >
                    <CheckerText color={reachedThreshold.color}>{score}%</CheckerText>
                </CircularProgress>
            </GraphWrapper>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
`;
const TitleAndDescriptionWrapper = styled.div``;
const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;
const HelpIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
`;
const TooltipLink = styled.a`
    color: inherit;
`;
const ContentTextWrapper = styled.div`
    text-align: left;
`;
const GraphWrapper = styled.div`
    flex-basis: 6rem;
    flex-grow: 1;
    flex-shrink: 0;
`;
const CheckerTitle = styled.h2<{ color: string }>`
    color: ${(props) => `var(${props.color})`};
    font-size: var(--fs-heading-s);
    font-weight: var(--fw-bold);
`;
const CheckerDescription = styled.p`
    color: var(--neutral500);
`;
const CheckerText = styled.span<{ color: string }>`
    font-size: var(--fs-body-l);
    font-weight: var(--fw-bold);
    color: ${(props) => `var(${props.color})`};
`;

export default Score;
