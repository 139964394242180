import { useHistory, useParams } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import DeprecatedButton from '@ui/atoms/DeprecatedButton';
import DeprecatedIcon from '@ui/atoms/DeprecatedIcon';
import SearchBar from '@ui/molecules/SearchBar';
import { useResponsive } from '@/shared/hooks/useResponsive';
import open from '@public/icons/folder.svg';
import add from '@public/icons/add.svg';
import Spinner from '@ui/atoms/Spinner';
import HideFor from './HideFor';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import { USER_OPTIONS } from '@/shared/config/userOptions';
import useCampaignStore from '@/shared/hooks/useCampaignStore';
import useSubscriptionQuery from '@queries/subscription/useSubscriptionQuery.hook';

const MonetizeHeader = ({ search, onSearchChange }) => {
    const history = useHistory();
    const { showId } = useParams();
    const { totalCampaigns, isLoading, addCampaign, createNewCampaign } = useCampaignStore();

    const intl = useIntl();
    const { isMobileOrTablet, isDesktop } = useResponsive();
    const { data: subscription } = useSubscriptionQuery();
    return (
        <>
            <DeprecatedText weight="bold" size="veryBig" mb={5}>
                <FormattedMessage defaultMessage="Monétisation" />
            </DeprecatedText>
            <DeprecatedText flex align="center" mb={30}>
                <DeprecatedIcon color="--neutral500" as={open} mr={10} />
                {totalCampaigns === 0 ? (
                    <FormattedMessage defaultMessage="Aucune campagne" />
                ) : (
                    <FormattedMessage
                        defaultMessage="{count} {count, plural, one {campagne} other {campagnes}}"
                        values={{ count: totalCampaigns }}
                    />
                )}
            </DeprecatedText>
            <HideFor userOptions={USER_OPTIONS.MANUAL_CAMPAIGNS}>
                <DeprecatedPaper flex align="center" justify="space-between" mb={20}>
                    <DeprecatedPaper maxW={250}>
                        <FormattedMessage defaultMessage="Rechercher une campagne...">
                            {(placeholder) => (
                                <SearchBar
                                    value={search}
                                    onChange={onSearchChange}
                                    placeholder={placeholder.join('')}
                                />
                            )}
                        </FormattedMessage>
                    </DeprecatedPaper>
                    <DeprecatedButton
                        onClick={async () => {
                            const campaign = await addCampaign(
                                showId,
                                createNewCampaign(showId, intl),
                            );
                            history.push(`/app/show/${showId}/monetize/manual/${campaign.id}`); // this.state.routerStore.push('menu.monetize.edit', { campaignId: data.id });
                        }}
                        disabled={isLoading}
                        floating={isMobileOrTablet}
                        bottomOffset={subscription?.isFreemium}
                        icon={isMobileOrTablet}
                        rounded={isMobileOrTablet}
                    >
                        {isLoading ? <Spinner /> : <DeprecatedIcon as={add} />}
                        {isDesktop && <FormattedMessage defaultMessage="Ajouter une campagne" />}
                    </DeprecatedButton>
                </DeprecatedPaper>
            </HideFor>
        </>
    );
};

export default MonetizeHeader;
