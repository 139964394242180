import { FormattedMessage } from 'react-intl';
import Img from '@ui/atoms/Img';
import ShortListFallback from '@/components/unorganized/ShortListFallback';
import { useResponsive } from '@/shared/hooks/useResponsive';
import EpisodeUsefulLinks from './EpisodeUsefulLinks';
import { useParams } from 'react-router-dom';
import useEpisodeQuery from '@queries/episode/useEpisodeQuery.hook';
import { formatDuration } from '@/shared/utils/duration';
import styled from 'styled-components';
import Text from '../ui/atoms/Text';
import Stack from '../ui/layout/Stack';
import Alert from '../ui/atoms/Alert';

const statusPodcastMessage = {
    active: <FormattedMessage defaultMessage="Récapitulatif de votre épisode publié." />,
    scheduled: <FormattedMessage defaultMessage="Récapitulatif de votre épisode planifié." />,
};

const typeMessage = {
    full: <FormattedMessage defaultMessage="Normal" />,
    trailer: <FormattedMessage defaultMessage="Bande-annonce" />,
    bonus: <FormattedMessage defaultMessage="Bonus" />,
};
const privacyMessage = {
    public: <FormattedMessage defaultMessage="Public" />,
    unlisted: <FormattedMessage defaultMessage="Non-listé" />,
    private: <FormattedMessage defaultMessage="Privé" />,
};

const youtubeMessage = {
    draft: (
        <FormattedMessage defaultMessage="La vidéo YouTube de votre épisode est en train d’être générée et sera bientôt en ligne." />
    ),
    processing: (
        <FormattedMessage defaultMessage="La vidéo YouTube de votre épisode est en train d’être générée et sera bientôt en ligne." />
    ),
    done: <FormattedMessage defaultMessage="Votre épisode a également été publié sur YouTube." />,
    pending: (
        <FormattedMessage defaultMessage="La vidéo YouTube de votre épisode est en train d’être générée et sera bientôt en ligne." />
    ),
};

const SummaryEpisode = () => {
    const { episodeId } = useParams();
    const { data: episode } = useEpisodeQuery({ episodeId });

    const { isMobileOrTablet } = useResponsive();

    if (episode.isLoading) return <ShortListFallback />;

    return (
        <Container>
            <InnerWrapper>
                <Text fontWeight="--fw-bold" variant="headingM">
                    {statusPodcastMessage[episode.state]}
                </Text>
                <Stack $gap="1.5rem">
                    <Img
                        size={isMobileOrTablet ? 125 : 135}
                        src={episode.imageUrl}
                        bordered
                        mx="auto"
                    />
                    <Text fontWeight="--fw-semibold" variant="bodyL" my={25}>
                        <FormattedMessage
                            defaultMessage="{podcastName}"
                            values={{ linebreak: <br />, podcastName: episode.name }}
                        />
                    </Text>
                    <Stack $gap="1.5rem">
                        <InfoBlocks>
                            <InfoBlock>
                                <Text color="--neutral500">
                                    <FormattedMessage defaultMessage="Durée" />
                                </Text>
                                <Text variant="bodyL">{formatDuration(episode.duration)}</Text>
                            </InfoBlock>
                            <InfoBlock>
                                <Text color="--neutral500">
                                    <FormattedMessage defaultMessage="Type d’épisode" />
                                </Text>
                                <Text variant="bodyL">{typeMessage[episode.type]}</Text>
                            </InfoBlock>
                            {episode.state !== 'scheduled' && (
                                <InfoBlock>
                                    <Text color="--neutral500">
                                        <FormattedMessage defaultMessage="Visibilité" />
                                    </Text>
                                    <Text variant="bodyL">{privacyMessage[episode.privacy]}</Text>
                                </InfoBlock>
                            )}
                        </InfoBlocks>
                        {episode?.youtubeVideo?.data?.state && (
                            <Alert variant="info" defaultIcon>
                                <Text fontWeight="--fw-semibold">
                                    {youtubeMessage[episode?.youtubeVideo?.data?.state]}
                                </Text>
                            </Alert>
                        )}
                        <Stack>
                            <EpisodeUsefulLinks border />
                        </Stack>
                    </Stack>
                </Stack>
            </InnerWrapper>
        </Container>
    );
};

const InfoBlock = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 1rem;
    max-width: 200px;
    border-radius: var(--r-xs);
    border: 1px solid var(--neutral100);
    padding: 1rem;
    flex: 1;
`;
const InfoBlocks = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex-wrap: wrap;
`;
const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--container-padding-mobile);
    background-color: var(--white);
    border-radius: var(--r-m);

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        padding: var(--container-padding);
    }
`;
const InnerWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 3rem;
    max-width: 630px;
    text-align: center;
`;

export default SummaryEpisode;
