import styled from 'styled-components';
import Button from '@/components/Button';
import { FormattedMessage } from 'react-intl';
import { MenuTrigger, Popover } from 'react-aria-components';
import MenuDropdown from '@/components/ui/atoms/MenuDropdown';
import MenuDropdownItem from '@/components/ui/atoms/MenuDropdown/MenuDropdownItem';
import Text from '@/components/ui/atoms/Text';
import { ClipFormat } from '@/queries/clipFormat/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

interface ShareButtonClipProps {
    type: {
        name: string;
        disabled: boolean;
        label: string;
        icon: React.ReactNode;
        renderComponent: React.ReactNode;
        renderWhenSelected: (format: any) => any;
    };
    onSelectContent: (type: any, format: any, image: any) => void;
    formats: ClipFormat[];
}

const ShareButtonClip = ({ type, onSelectContent, formats = [] }: ShareButtonClipProps) => (
    <MenuTrigger>
        <Button
            isDisabled={type.disabled}
            variant="tertiary"
            startIcon={<ClapperboardIcon icon={icon({ name: 'clapperboard', style: 'solid' })} />}
        >
            <FormattedMessage defaultMessage="Clip vidéo" />
        </Button>
        <PopoverStyled placement="bottom left">
            <MenuDropdownStyled>
                {formats.map((format) => (
                    <MenuDropdownItem
                        key={format.id}
                        onAction={() => onSelectContent(type, format, null)}
                    >
                        <ImgWrapper>
                            <Img src={format.previewUrl} />
                        </ImgWrapper>
                        <Text numberOfLines={1} fontWeight="--fw-semibold">
                            {format.clip.adminTitle || format.clip.title}
                        </Text>
                    </MenuDropdownItem>
                ))}
            </MenuDropdownStyled>
        </PopoverStyled>
    </MenuTrigger>
);

const PopoverStyled = styled(Popover)`
    width: 400px;
`;
const MenuDropdownStyled = styled(MenuDropdown)`
    max-width: 100%;
    box-shadow: var(--s-m);
    max-height: 10.5rem;
    height: fit-content;
    overflow-y: auto;
`;
const ImgWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--neutral50);
    border-radius: var(--r-xs);
    width: 2.5rem;
    height: 2.5rem;
    overflow: hidden;
`;
const Img = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;
const ClapperboardIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;

export default ShareButtonClip;
