import { useState } from 'react';
import styled from 'styled-components';
import EditTitleModal from '../../../EditTitleModal';
import updateClipMutation from '@/queries/clip/updateClipMutation';
import { useQueryClient } from '@tanstack/react-query';
import { useBodyToastQueue } from '@/shared/hooks/useBodyToastQueue.hook';
import { useParams } from 'react-router';
import clipFormatKeys from '@/queries/clipFormat/clipFormatKeys';
import { FormattedMessage, useIntl } from 'react-intl';
import { Clip } from '@/queries/clip/useClipQuery';
import Text from '@/components/ui/atoms/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import IconButton from '@/components/IconButton';
import FormStepper from '@/shared/components/FormStepper';
import { CLIP_FORM_STEPS } from '../Form';

interface HeaderProps {
    clip: Clip;
}

const Header = ({ clip }: HeaderProps) => {
    const intl = useIntl();
    const { clipId } = useParams<{ clipId: string }>();
    const [isEditTitleModalOpen, setIsEditTitleModalOpen] = useState(false);
    const updateClip = updateClipMutation();
    const queryClient = useQueryClient();
    const toast = useBodyToastQueue();

    const handleEditClipTitle = (adminTitle: string) => {
        updateClip.mutate(
            {
                clipId,
                clip: { adminTitle },
            },
            {
                onSuccess: () => {
                    queryClient.invalidateQueries({
                        queryKey: clipFormatKeys.allLists(),
                    });
                    setIsEditTitleModalOpen(false);
                },
                onError: () => {
                    toast.alert(
                        <FormattedMessage defaultMessage="Oups, une erreur est survenue. Veuillez réessayer" />,
                    );
                },
            },
        );
    };

    const stepsLabels = {
        [CLIP_FORM_STEPS.AUDIO_SAMPLE_SELECTION]: intl.formatMessage({ defaultMessage: 'Extrait' }),
        [CLIP_FORM_STEPS.TRANSCRIPTION_SELECTION]: intl.formatMessage({
            defaultMessage: 'Transcription',
        }),
        [CLIP_FORM_STEPS.FORMAT_SELECTION]: intl.formatMessage({ defaultMessage: 'Format' }),
        [CLIP_FORM_STEPS.CUSTOMIZATION]: intl.formatMessage({ defaultMessage: 'Personnalisation' }),
    };

    const currentStep = Object.keys(stepsLabels).indexOf(
        clip.step === CLIP_FORM_STEPS.TRANSCRIPTION
            ? CLIP_FORM_STEPS.TRANSCRIPTION_SELECTION
            : clip.step,
    );

    const steps = Object.values(stepsLabels).map((label, index) => {
        const state =
            index < currentStep ? 'completed' : index === currentStep ? 'active' : 'inactive';
        return {
            state,
            label,
        };
    });

    return (
        <>
            <Container>
                <Title>
                    <Text variant="bodyL" fontWeight="--fw-bold">
                        {clip?.adminTitle || clip?.title}
                    </Text>
                    <EditButton
                        onPress={() => setIsEditTitleModalOpen(true)}
                        variant="ghost"
                        aria-label="Edit clip title"
                        icon={<EditIcon icon={icon({ name: 'pen-line', style: 'solid' })} />}
                    />
                </Title>
                <Stepper steps={steps} currentStep={currentStep} />
                <InvisibleBlock />
            </Container>
            <EditTitleModal
                isOpen={isEditTitleModalOpen}
                onOpenChange={setIsEditTitleModalOpen}
                onEdit={handleEditClipTitle}
                isLoading={updateClip.isLoading}
                value={clip?.adminTitle || clip?.title || ''}
            />
        </>
    );
};

const Container = styled.header`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    background-color: var(--white);

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        padding: 2rem;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
`;
const EditIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const EditButton = styled(IconButton)`
    padding: 0;
    color: var(--neutral500);
`;
const Title = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    max-width: 15rem;
    min-width: 0;

    & > :first-child {
        flex-grow: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        min-width: 0;
    }

    & > :last-child {
        flex-shrink: 0;
    }
`;
const Stepper = styled(FormStepper)`
    flex-grow: 1;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        max-width: 40rem;
    }
`;
const InvisibleBlock = styled.div`
    visibility: hidden;
    width: 5rem;
`;

export default Header;
