import { observer } from 'mobx-react';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import Img from '@ui/atoms/Img';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import DeprecatedCheckbox from '@ui/atoms/DeprecatedCheckbox';

function SelectableCampaignEpisode({ episode, isSelected, toggleSelection }) {
    return (
        <DeprecatedPaper
            flex
            align="center"
            height={75}
            w="calc(100% - 12px)"
            mt={6}
            mx={6}
            px={20}
            onClick={toggleSelection}
            background={isSelected ? '--primary100' : 'white'}
            raise={isSelected ? undefined : 'hard'}
            rounded
            cssAnim
            ellipsis
        >
            <DeprecatedCheckbox checked={isSelected} mr={15} />
            <Img src={episode.imageUrl} size={45} mr={15} circle />
            <DeprecatedText ellipsis weight="semibold">
                {episode.name}
            </DeprecatedText>
        </DeprecatedPaper>
    );
}

export default observer(SelectableCampaignEpisode);
