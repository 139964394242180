import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { PRICING, PRICING_NAME } from '@/shared/config/pricing';
import {
    isHigherPricingThan,
    isLowerPricingThan,
    isSamePricingThan,
} from '@/shared/services/pricingService';
import { createStripeCheckoutSession, reactivateStripeCheckoutSession } from '@/api';
import { useStripe } from '@stripe/react-stripe-js';
import Button from '@ui/atoms/Button';
import { SUBSCRIPTION_ACTION } from '@/shared/config/constants';
import type { Currency, Offer } from '@/queries/price/usePricesQuery.hook';
import { sendAmplitudeLogEvent } from '@/shared/utils/amplitude';
import { usePricingContext } from '@/context/PricingContext';
import useSubscriptionQuery from '@queries/subscription/useSubscriptionQuery.hook';
import { dateIsInTheFuture } from '@/shared/utils/date';

const offersClassName = {
    [PRICING.LAUNCH]: 'gtm-click-launch-offer',
    [PRICING.BOOST]: 'gtm-click-boost-offer',
    [PRICING.SUPERSONIC]: 'gtm-click-supersonic-offer',
};

export interface PricingSelectOfferButton {
    offer: Offer;
}

const PricingSelectOfferButton = ({ offer }: PricingSelectOfferButton) => {
    const stripe = useStripe();
    const {
        isCurrentSubscriptionActive,
        isCurrentSubscriptionBilledYearly,
        currentSubscriptionPricing,
        isYearlyPeriodicitySelected,
        selectedCurrency,
        setOfferUpdatePayload,
    } = usePricingContext();

    const { data: subscription } = useSubscriptionQuery();
    const subscriptionIsNotActive =
        !!subscription?.endsAt && !dateIsInTheFuture(subscription?.endsAt);

    const [isLoading, setLoading] = useState(false);

    const handleReactivateOffer = async ({
        isYearly,
        price,
    }: {
        isYearly: boolean;
        price: string;
    }) => {
        try {
            setLoading(true);
            const stripeCheckoutSession = await reactivateStripeCheckoutSession({
                isYearly,
                price,
            });
            if (!stripe) return;
            const { id } = stripeCheckoutSession;
            const result = await stripe.redirectToCheckout({ sessionId: id });
            if (result.error) {
                alert(result.error.message);
            }
        } catch (error) {
            // TODO: Handle errors
        } finally {
            setLoading(false);
        }
    };

    // Offer is not active
    if (subscriptionIsNotActive) {
        return (
            // @ts-ignore
            <Button
                onPress={() => {
                    sendAmplitudeLogEvent('Reactivate subscription');
                    handleReactivateOffer({
                        isYearly: isYearlyPeriodicitySelected,
                        price: offer,
                    });
                }}
                variant="primary"
            >
                {isSamePricingThan(offer, currentSubscriptionPricing) ? (
                    <FormattedMessage defaultMessage="Réactiver l'offre" />
                ) : (
                    <FormattedMessage
                        defaultMessage="Choisir l’offre {offerName}"
                        values={{ offerName: PRICING_NAME[offer] }}
                    />
                )}
            </Button>
        );
    }

    // Offer is lower than current subscription pricing
    if (isCurrentSubscriptionActive && isLowerPricingThan(offer, currentSubscriptionPricing)) {
        return (
            // @ts-ignore
            <Button
                onPress={() => {
                    sendAmplitudeLogEvent('Subscription Plan Selected');
                    setOfferUpdatePayload({
                        offer,
                        isBilledYearly: isYearlyPeriodicitySelected,
                        action: SUBSCRIPTION_ACTION.DOWNGRADE,
                    });
                }}
                variant="primary"
                data-testid={`${offer}-subscribe-button`}
            >
                <FormattedMessage
                    defaultMessage="Choisir l’offre {offerName}"
                    values={{ offerName: PRICING_NAME[offer] }}
                />
            </Button>
        );
    }

    // Offer is higher than current subscription pricing
    if (isCurrentSubscriptionActive && isHigherPricingThan(offer, currentSubscriptionPricing)) {
        return (
            // @ts-ignore
            <Button
                onPress={() => {
                    sendAmplitudeLogEvent('Subscription Plan Selected');
                    setOfferUpdatePayload({
                        offer,
                        isBilledYearly: isYearlyPeriodicitySelected,
                        action: SUBSCRIPTION_ACTION.UPGRADE,
                    });
                }}
                data-testid={`${offer}-subscribe-button`}
            >
                <FormattedMessage
                    defaultMessage="Choisir l’offre {offerName}"
                    values={{ offerName: PRICING_NAME[offer] }}
                />
            </Button>
        );
    }

    // Offer is current subscription pricing
    if (isCurrentSubscriptionActive && isSamePricingThan(offer, currentSubscriptionPricing)) {
        if (isCurrentSubscriptionBilledYearly) {
            return (
                // @ts-ignore
                <Button
                    onPress={() =>
                        setOfferUpdatePayload({
                            offer,
                            isBilledYearly: false,
                            action: SUBSCRIPTION_ACTION.PERIODICITY_UPDATE,
                        })
                    }
                    variant="secondary"
                >
                    <FormattedMessage defaultMessage="Passer en facturation mensuelle" />
                </Button>
            );
        }

        return (
            // @ts-ignore
            <Button
                onPress={() =>
                    setOfferUpdatePayload({
                        offer,
                        isBilledYearly: true,
                        action: SUBSCRIPTION_ACTION.PERIODICITY_UPDATE,
                    })
                }
                variant="primary"
            >
                <FormattedMessage defaultMessage="Passer en facturation annuelle" />
            </Button>
        );
    }

    const createCheckoutSession = async ({
        currency,
        isYearly,
        price,
    }: {
        currency: Currency | null;
        isYearly: boolean;
        price: string;
    }) => {
        try {
            setLoading(true);
            const stripeCheckoutSession = await createStripeCheckoutSession({
                currency,
                isYearly,
                price,
            });
            if (!stripe) return;
            const { id } = stripeCheckoutSession;
            const result = await stripe.redirectToCheckout({ sessionId: id });
            if (result.error) {
                alert(result.error.message);
            }
        } catch (error) {
            // TODO: Handle errors
        } finally {
            setLoading(false);
        }
    };

    return (
        // @ts-ignore
        <Button
            onPress={() => {
                sendAmplitudeLogEvent('Subscription Plan Selected');
                createCheckoutSession({
                    currency: selectedCurrency,
                    isYearly: isYearlyPeriodicitySelected,
                    price: offer,
                });
                sendAmplitudeLogEvent('Payment Page Viewed');
            }}
            variant="primary"
            isLoading={isLoading}
            className={`gtm-click-checkout-initiated ${offersClassName[offer]}`}
        >
            <FormattedMessage
                defaultMessage="Choisir l’offre {offerName}"
                values={{ offerName: PRICING_NAME[offer] }}
            />
        </Button>
    );
};

export default PricingSelectOfferButton;
