import { FC, useState, createContext, useContext, ReactNode } from 'react';

interface TPsoCheckerContext {
    psoCheckerScore: number | null;
    setPsoCheckerScore: (score: number | null) => void;
    targetKeyword: string | null;
    setTargetKeyword: (targetKeyword: string | null) => void;
}

const PsoCheckerContext = createContext<TPsoCheckerContext | undefined>(undefined);

interface PsoCheckerContextProviderProps {
    children: ReactNode;
}

export const PsoCheckerContextProvider: FC<PsoCheckerContextProviderProps> = ({ children }) => {
    const [psoCheckerScore, setPsoCheckerScore] = useState<number | null>(null);
    const [targetKeyword, setTargetKeyword] = useState<string | null>(null);
    const contextValue = {
        psoCheckerScore,
        setPsoCheckerScore,
        targetKeyword,
        setTargetKeyword,
    };

    return <PsoCheckerContext.Provider value={contextValue}>{children}</PsoCheckerContext.Provider>;
};

export const usePsoCheckerContext = () => {
    const context = useContext(PsoCheckerContext);
    if (context === undefined) {
        throw new Error('usePsoCheckerContext must be used within a PsoCheckerContextProvider');
    }
    return context;
};
