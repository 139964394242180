import ReactPropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'mobx-react';
import createFallback from '../fallbacks/createFallback';
import DeprecatedIcon from '../../ui/atoms/DeprecatedIcon';
import rankingUpward from '@public/icons/ranking_upward.svg';
import Review, { ReviewFallback } from '../molecules/Review';
import ReviewModel from '../../../models/ReviewModel';
import DeprecatedText from '../../ui/atoms/DeprecatedText';
import styled, { css } from 'styled-components';

const ReviewsListFallback = createFallback(() => (
    <>
        <ReviewFallback />
        <ReviewFallback />
        <ReviewFallback />
    </>
));

const NoReview = () => (
    <NoReviewWrapper>
        <DeprecatedIcon as={rankingUpward} width={28} height={26} mb={10} color="black" />
        <DeprecatedText size="big" weight="semibold" mb={8} align="center">
            <FormattedMessage defaultMessage="Aucun avis pour le moment" />
        </DeprecatedText>
        <DeprecatedText maxW={340} align="center">
            <FormattedMessage defaultMessage="Votre émission n’a pas encore reçu d’avis." />
        </DeprecatedText>
    </NoReviewWrapper>
);

function ReviewsList({ reviews, isLoading }) {
    let content;

    if (isLoading) {
        content = <ReviewsListFallback />;
    } else if (reviews.length === 0) {
        content = <NoReview />;
    } else {
        content = (
            <ReviewsListWrapper>
                {reviews.map((review, index) => (
                    // Reviews have no ID and the list is never reordered or filtered,
                    // it is safe to disable ESLint no-array-index-key rule here.
                    // eslint-disable-next-line react/no-array-index-key
                    <Review key={index} review={review} />
                ))}
            </ReviewsListWrapper>
        );
    }

    return content;
}

const WrapperBase = css`
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding);
    margin-block: 2rem;
    gap: 1rem ${({ theme }) => theme.mediaQueries.mobile} {
        padding: var(--container-padding-mobile);
    }
`;

const NoReviewWrapper = styled.div`
    ${WrapperBase};
    align-items: center;
    justify-content: center;
    min-height: 405px;
`;

const ReviewsListWrapper = styled.div`
    ${WrapperBase};
`;

ReviewsList.propTypes = {
    reviews: PropTypes.arrayOrObservableArrayOf(ReactPropTypes.instanceOf(ReviewModel)).isRequired,
    isLoading: ReactPropTypes.bool,
};

ReviewsList.defaultProps = {
    isLoading: false,
};

export default ReviewsList;
