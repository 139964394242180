import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import ApplePodcastsDateSelector from '../organisms/ApplePodcastsDateSelector';
import ApplePodcastsHeader from '../organisms/ApplePodcastsHeader';
import ApplePodcastsRanking from '../organisms/ApplePodcastsRanking';
import ApplePodcastsReviews from '../organisms/ApplePodcastsReviews';
import ApplePodcastsSummary from '../organisms/ApplePodcastsSummary';
import FetcherApplePodcastsAvailableCategories from '../fetchers/stats/FetcherApplePodcastsAvailableCategories';
import FetcherApplePodcastsRankingOverall from '../fetchers/stats/FetcherApplePodcastsRankingOverall';
import HideFor from '../atoms/HideFor';
import DeprecatedPaper from '../../ui/atoms/DeprecatedPaper';
import { connect } from '../decorators/connect';
import { useAmplitudeLogEvent } from '@hooks/useAmplitude';
import StatsTabTitle from '../organisms/StatsTabTitle';
import { TABS_STATS_NAME } from '../pages/StatsPage';
import { USER_OPTIONS } from '@/utils/userOptions';
import Text from '@ui/atoms/Text';
import EmptyState from '@ui/molecules/EmptyState';
import noApplePodcastsIllustration from '@public/images/empty-state/illustration/reviews.mp4';
import ZebraIllustration from '@ui/atoms/ZebraIllustration';
import ShiningDiamond from '@ui/atoms/ShiningDiamond';
import Button from '@ui/atoms/Button';
import IconRocket from '@ui/icons/IconRocket';
import Link from '@ui/atoms/Link';
import styled from 'styled-components';
import { useParams } from 'react-router';
import useShowQuery from '@/queries/show/useShowQuery.hook';

const EmptyStateOuterWrapper = styled.div`
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

const HeadIllustrationWrapper = styled.div`
    width: 200px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    & > * {
        text-align: center;
    }
`;

const ForbiddenFeature = ({ upgradeOfferLink }) => (
    <EmptyStateOuterWrapper>
        <EmptyState
            illustration={noApplePodcastsIllustration}
            illustrationType="video"
            illustrationAlign="right"
            illustrationBackground="--gradient-dusk"
        >
            <HeadIllustrationWrapper>
                <ZebraIllustration>
                    <ShiningDiamond />
                </ZebraIllustration>
            </HeadIllustrationWrapper>
            <LabelWrapper>
                <Text variant="heading" fontWeight="--fw-bold">
                    <FormattedMessage defaultMessage="Passez à l'offre supérieure !" />
                </Text>
                <Text variant="body" fontWeight="--fw-normal" color="--neutral500">
                    <FormattedMessage defaultMessage="Pour débloquer vos statistiques et vos avis Apple Podcasts, abonnez-vous à l'offre Boost ou Supersonic 🚀" />
                </Text>
            </LabelWrapper>
            <Button startIcon={<IconRocket />} as={Link} to={upgradeOfferLink}>
                <FormattedMessage defaultMessage="Mettre à niveau mon offre" />
            </Button>
        </EmptyState>
    </EmptyStateOuterWrapper>
);

const enhance = connect(({ routerStore, applePodcastsStatsStore }) => ({
    categoryId: applePodcastsStatsStore.applePodcastsCategoryId,
    setActiveCategory: applePodcastsStatsStore.setApplePodcastsCategoryId,
    upgradeOfferLink: routerStore.link(
        routerStore.params.showId ? 'user.edit.offers' : 'user.offers',
    ),
}));

const StatsApplePodcasts = ({ categoryId, setActiveCategory, upgradeOfferLink }) => {
    const { showId } = useParams();
    const { data: show } = useShowQuery(showId);

    useAmplitudeLogEvent('Stats Apple Tab Viewed');
    // Reset default category when leaving Apple Podcasts stats
    useEffect(() => () => setActiveCategory(undefined), []);

    if (!show) {
        return null;
    }

    return (
        <DeprecatedPaper mb={100}>
            <StatsTabTitle
                tabTitle={<FormattedMessage defaultMessage="Apple Podcasts" />}
                tabId={TABS_STATS_NAME.APPLE_PODCASTS}
            />
            <HideFor
                userOptions={USER_OPTIONS.APPLE_STATISTICS}
                lockedMessage={<ForbiddenFeature upgradeOfferLink={upgradeOfferLink} />}
            >
                <FetcherApplePodcastsAvailableCategories showId={showId}>
                    {(isLoadingAvailableCategories) =>
                        isLoadingAvailableCategories ? (
                            <>
                                <ApplePodcastsHeader isLoading />
                                <ApplePodcastsSummary isLoading />
                                <ApplePodcastsDateSelector />
                                <ApplePodcastsRanking isLoading />
                            </>
                        ) : (
                            <FetcherApplePodcastsRankingOverall
                                showId={showId}
                                categoryId={categoryId}
                            >
                                {(isLoadingRankingOverall) => (
                                    <>
                                        <ApplePodcastsHeader />
                                        <ApplePodcastsSummary isLoading={isLoadingRankingOverall} />
                                        <ApplePodcastsDateSelector />
                                        <ApplePodcastsRanking isLoading={isLoadingRankingOverall} />
                                    </>
                                )}
                            </FetcherApplePodcastsRankingOverall>
                        )
                    }
                </FetcherApplePodcastsAvailableCategories>

                <ApplePodcastsReviews />
            </HideFor>
        </DeprecatedPaper>
    );
};

export default enhance(StatsApplePodcasts);
