import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { CheckboxGroup, Separator, Label, Form } from 'react-aria-components';
import Checkbox from '@ui/Checkbox';
import useSeasonsQuery from '@/queries/season/useSeasonsQuery.hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useParams } from 'react-router';
import Button from '@/components/Button';
import { useEpisodeFiltersContext } from '@/context/EpisodeFiltersContext';
import styled from 'styled-components';

const EpisodesFilter = () => {
    const { showId } = useParams<{ showId: string }>();
    const { filters, setFilters } = useEpisodeFiltersContext();
    const [statuses, setStatuses] = useState<string[]>(filters.statuses);
    const [seasons, setSeasons] = useState<string[]>(filters.seasons);
    const { data: seasonList, isLoading } = useSeasonsQuery({ showId });

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setFilters({ statuses, seasons });
    };

    const resetFilters = () => {
        setFilters({ statuses: [], seasons: [] });
        setStatuses([]);
        setSeasons([]);
    };

    return (
        <Form onSubmit={onSubmit}>
            <FiltersWrapper>
                <FiltersInnerWrapper>
                    <Left>
                        <StyledCheckboxGroup value={statuses} onChange={setStatuses}>
                            <StyledLabel>
                                <FormattedMessage defaultMessage="Statut" />
                            </StyledLabel>
                            <Checkbox value="active">
                                <FormattedMessage defaultMessage="En ligne" />
                            </Checkbox>
                            <Checkbox value="scheduled">
                                <FormattedMessage defaultMessage="Planifié" />
                            </Checkbox>
                            <Checkbox value="draft">
                                <FormattedMessage defaultMessage="Brouillon" />
                            </Checkbox>
                        </StyledCheckboxGroup>

                        {/*
                        So, it turns out, these filters were already there in the previous version of the
                        filters.
                        However... it never worked, the API is not fit to handle this kind of filtering.
                        Leaving it there in hope that one day it will be fixed.
                        */}

                        {/* <StyledCheckboxGroup value={privacy} onChange={setPrivacy}>
                            <StyledLabel>
                                <FormattedMessage defaultMessage="Confidentialité" />
                            </StyledLabel>
                            <Checkbox value="public">
                                <FormattedMessage defaultMessage="Public" />
                            </Checkbox>
                            <Checkbox value="private">
                                <FormattedMessage defaultMessage="Privé" />
                            </Checkbox>
                            <Checkbox value="unlisted">
                                <FormattedMessage defaultMessage="Non-listé" />
                            </Checkbox>
                        </StyledCheckboxGroup> */}
                    </Left>
                    <VerticalSeparator />
                    <Right>
                        <SeasonsCheckboxGroup value={seasons} onChange={setSeasons}>
                            <StyledLabel>
                                <FormattedMessage defaultMessage="Saison" />
                            </StyledLabel>
                            <Checkbox value="unranked">
                                <i>
                                    <FormattedMessage defaultMessage="Non répertorié" />
                                </i>
                            </Checkbox>
                            <Separator />
                            {isLoading ? (
                                <FontAwesomeIcon
                                    icon={icon({ name: 'spinner-third', style: 'solid' })}
                                    spin
                                />
                            ) : (
                                seasonList
                                    ?.sort((a, b) =>
                                        a?.seasonNumber && b?.seasonNumber
                                            ? a.seasonNumber - b.seasonNumber
                                            : 0,
                                    )
                                    .map((s) => (
                                        <Checkbox key={s.id} value={`${s.id}`}>
                                            <FormattedMessage
                                                defaultMessage="Saison {season}"
                                                values={{ season: s.seasonNumber }}
                                            />
                                        </Checkbox>
                                    ))
                            )}
                        </SeasonsCheckboxGroup>
                    </Right>
                </FiltersInnerWrapper>

                <ActionWrapper>
                    <Button
                        type="submit"
                        variant="secondary"
                        startIcon={
                            <FontAwesomeIcon icon={icon({ name: 'bars-filter', style: 'solid' })} />
                        }
                    >
                        <FormattedMessage defaultMessage="Filtrer les épisodes" />
                    </Button>
                    <ResetButton onPress={resetFilters} variant="ghost">
                        <FormattedMessage defaultMessage="Réinitialiser les filtres" />
                    </ResetButton>
                </ActionWrapper>
            </FiltersWrapper>
        </Form>
    );
};

const FiltersWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;

    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        gap: 2rem;
    }
`;
const FiltersInnerWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    flex-wrap: wrap;

    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        gap: 3rem;
    }
`;
const Left = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;
const VerticalSeparator = styled.div`
    width: 1px;
    align-self: stretch;
    background-color: var(--neutral100);

    display: none;

    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        display: block;
    }
`;
const Right = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;
const StyledCheckboxGroup = styled(CheckboxGroup)`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 8rem;
`;
const StyledLabel = styled(Label)`
    font-weight: var(--fw-semibold);
`;
const ActionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: flex-end;
`;
const ResetButton = styled(Button)`
    padding: 0;
    color: var(--neutral500);
`;
const SeasonsCheckboxGroup = styled(StyledCheckboxGroup)`
    max-height: 10rem;
    overflow-y: auto;

    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        max-height: 16.5rem;
    }
`;

export default EpisodesFilter;
