import { FormattedMessage } from 'react-intl';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import { connect } from '@/components/legacy/connect';

const enhance = connect(({ campaignStore }) => ({
    selectionCount: campaignStore.selection.length,
}));

function CampaignEpisodesSelectionCount({ selectionCount }) {
    return (
        <DeprecatedText weight="semibold" align="center" auto>
            {selectionCount > 0 && (
                <FormattedMessage
                    defaultMessage="La campagne s'appliquera à {count, plural, one {# épisode} other {# épisodes}}."
                    values={{ count: selectionCount }}
                />
            )}
        </DeprecatedText>
    );
}

export default enhance(CampaignEpisodesSelectionCount);
