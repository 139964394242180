import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Text from '@ui/atoms/Text';
import RouterLink from '@/components/Link/RouterLink';

const TopbarEndedBand = () => (
    <TopbarEndedBandWrapper>
        <Text color="white">
            <FormattedMessage defaultMessage="Votre abonnement est résilié. Pour profiter à nouveau des services d’Ausha, veuillez le réactiver." />
        </Text>
        <ReactivateLink to="/app/subscription/offers">
            <FormattedMessage defaultMessage="Réactiver mon abonnement" />
        </ReactivateLink>
    </TopbarEndedBandWrapper>
);

const TopbarEndedBandWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    height: 2rem;
    background: var(--info);
`;
const ReactivateLink = styled(RouterLink)`
    color: var(--white);
`;

export default TopbarEndedBand;
