import Text from '@/components/ui/atoms/Text';
import Cluster from '@/components/ui/layout/Cluster';
import Stack from '@/components/ui/layout/Stack';
import { ACCEPTED_AUDIO_TYPES, MAX_AUDIO_FILE_SIZE } from '@/utils/constants';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const EpisodeEditResumeAudioFileInput = ({ onChange, error, ...props }) => {
    const handleChange = (e) => {
        const file = e.target.files[0];
        onChange(file);
        // Erase value after triggering change event to allow user to upload the same file
        e.target.value = null;
    };

    return (
        <InputWrapper>
            <InputLabel data-testid="episode-form-audio-upload-button">
                <HiddenInput
                    type="file"
                    hidden
                    onChange={handleChange}
                    accept={ACCEPTED_AUDIO_TYPES.join(',')}
                    {...props}
                />
                <PlusIcon icon={icon({ name: 'plus-circle', style: 'solid' })} />
                <Stack>
                    <Text variant="bodyM" fontWeight="--fw-semibold" color="--primary">
                        <FormattedMessage defaultMessage="Ajoutez votre fichier audio" />
                    </Text>
                    <Text variant="bodyS" color="--neutral500">
                        <FormattedMessage
                            defaultMessage=".MP3, .WAV, .M4A, .OGG ou .FLAC, jusqu’à {max}Mo"
                            values={{ max: MAX_AUDIO_FILE_SIZE / 1000000 }}
                        />
                    </Text>
                </Stack>
            </InputLabel>
            {!!error && (
                <Cluster $gap="0.25rem" $align="center" $wrap="nowrap">
                    <ErrorIcon icon={icon({ name: 'exclamation-circle', style: 'solid' })} />
                    <Text color="--alert500">{error?.message}</Text>
                </Cluster>
            )}
        </InputWrapper>
    );
};

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    width: 100%;
`;
const InputLabel = styled.label`
    display: flex;
    align-items: center;
    column-gap: 0.75rem;
    width: 100%;
    padding: 1rem;
    border-radius: var(--r-m);
    background-color: var(--primary50);
    cursor: pointer;
`;
const PlusIcon = styled(FontAwesomeIcon)`
    color: var(--primary);
    width: 2rem;
    height: 2rem;
`;
const ErrorIcon = styled(FontAwesomeIcon)`
    color: var(--alert500);
    width: 0.75rem;
    height: 0.75rem;
`;
const HiddenInput = styled.input`
    display: none;
`;

export default EpisodeEditResumeAudioFileInput;
