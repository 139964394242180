import { useQuery } from '@tanstack/react-query';
import { fetchSoundcloudBroadcastSettings } from '@/api';

const useFetchSoundcloudBroadcastSettings = (showId) => {
    return useQuery({
        queryKey: ['shows.soundcloud.show', showId],
        queryFn: async () => {
            const { data } = await fetchSoundcloudBroadcastSettings(showId);
            return data;
        },
        retry: false,
        staleTime: 1000 * 60 * 20,
        refetchOnWindowFocus: false,
        enabled: !!showId,
    });
};

export default useFetchSoundcloudBroadcastSettings;
