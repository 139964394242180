import { useParams, Route, Switch, Redirect } from 'react-router-dom';
import Layout from './Layout';
import Overview from './Overview';
import Explore from './Explore';
import ExploreDetails from './ExploreDetails';
import Reviews from './Reviews';
import Reports from './Reports';

const Charts = () => {
    const { showId } = useParams<{ showId: string }>();
    const basePath = `/app/show/${showId}/charts`;

    return (
        <Layout>
            <Switch>
                <Route exact path={`${basePath}/overview`}>
                    <Overview />
                </Route>
                <Route exact path={`${basePath}/explore`}>
                    <Explore />
                </Route>
                <Route path={`${basePath}/explore/details/:id`}>
                    <ExploreDetails />
                </Route>
                <Route exact path={`${basePath}/reviews`}>
                    <Reviews />
                </Route>
                <Route exact path={`${basePath}/reports`}>
                    <Reports />
                </Route>

                {/* Catch-all + redirect when navigating to /charts */}
                <Redirect to={`${basePath}/overview`} />
            </Switch>
        </Layout>
    );
};

export default Charts;
