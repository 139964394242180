import { useMutation } from '@tanstack/react-query';
import { updatePageSettings, uploadBackgroundCover, deleteShowLogo } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import { decamelizeKeys } from 'humps';
import websiteSettingsKeys from './websiteSettingsKeys';
import { useQueryClient } from '@tanstack/react-query';

interface MutationFnProps {
    showId: string;
    payload: any;
}

const mutationFn = async ({ showId, payload }: MutationFnProps) => {
    if (payload.cover && payload.cover instanceof File) {
        await uploadBackgroundCover(showId, payload.cover, 'cover_page');
    }

    if (payload.cover === null) {
        try {
            await deleteShowLogo(showId, 'cover_page');
        } catch (error) {
            console.log('No image to delete');
        }
    }

    let formatedPayload = null;
    const { featured_episode, ...rest } = decamelizeKeys(payload);
    if (featured_episode === 'none') {
        formatedPayload = { ...rest, featured_episode_type: null };
    } else if (payload.featuredEpisode === 'latest') {
        formatedPayload = { ...rest, featured_episode_type: 'latest' };
    } else {
        formatedPayload = {
            ...rest,
            featured_episode_id: featured_episode,
            featured_episode_type: 'specific',
        };
    }

    formatedPayload = {
        ...formatedPayload,
        recommendedShows: payload.recommendedShows.map(
            (recommandedShow: { value: string }) => recommandedShow.value,
        ),
    };

    const { data } = await updatePageSettings(showId, formatedPayload);
    return camelcaseKeys(data, { deep: true });
};

const useWebsiteSettingsMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: websiteSettingsKeys.all(),
            });
        },
    });
};

export default useWebsiteSettingsMutation;
