import { z } from 'zod';
import useAudioSampleFormSchema from './useAudioSampleSchema';
import useClipTranscriptionFormSchema from './useTranscriptionSelectionSchema';
import useClipTranscriptionSchema from './useTranscriptionSchema';
import useClipFormatsSchema from './useFormatsSchema';
import useClipCustomizationFormSchema from './useCustomizationSchema';

const useClipFormSchema = (episodeDuration: number) => {
    const audioSampleSchema = useAudioSampleFormSchema(episodeDuration);
    const transcriptionSelectionSchema = useClipTranscriptionFormSchema();
    const transriptionSchema = useClipTranscriptionSchema();
    const formatsSchema = useClipFormatsSchema();
    const customizationSchema = useClipCustomizationFormSchema();

    const schema = z.object({
        audioSample: audioSampleSchema,
        transcriptionSelection: transcriptionSelectionSchema,
        transcription: transriptionSchema,
        formats: formatsSchema,
        customization: customizationSchema,
    });

    return schema;
};

export type ClipFormSchema = z.infer<ReturnType<typeof useClipFormSchema>>;

export default useClipFormSchema;
