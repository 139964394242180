import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import Button from '@/components/Button';
import useAIPresetsFormSchema, { AIPresetsSchema } from './useAIPresetsFormSchema.hook';
import { InputTextController } from '@/components/ui/atoms/InputText';
import Stack from '@/components/ui/layout/Stack';
import Text from '@/components/ui/atoms/Text';
import Alert from '@/components/ui/atoms/Alert';

interface AIPresetsFormProps {
    onSubmit: (data: AIPresetsSchema) => void;
    isLoading: boolean;
    defaultValues: AIPresetsSchema;
}

const AIToneOfVoiceForm = ({ onSubmit, isLoading, defaultValues }: AIPresetsFormProps) => {
    const intl = useIntl();
    const schema = useAIPresetsFormSchema();
    const { handleSubmit, control, formState } = useForm<AIPresetsSchema>({
        mode: 'onBlur',
        values: defaultValues,
        resolver: zodResolver(schema),
    });

    const { isDirty, isValid } = formState;

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Stack $gap="0.5rem">
                <InputTextController
                    name="toneOfVoice"
                    elementType="textarea"
                    control={control}
                    label={<FormattedMessage defaultMessage="Définissez votre style narratif" />}
                    placeholder={intl.formatMessage({
                        defaultMessage: 'Saisissez vos instructions personnalisées',
                    })}
                    minHeight="100px"
                    maxLength={200}
                />
                <Alert
                    variant="neutral"
                    defaultIcon
                    description={
                        <AlertList>
                            <AlertListItem>
                                <Text>
                                    <FormattedMessage defaultMessage="Utiliser un ton formel." />
                                </Text>
                            </AlertListItem>
                            <AlertListItem>
                                <Text>
                                    <FormattedMessage defaultMessage="Adopter un style d'écriture amical." />
                                </Text>
                            </AlertListItem>
                            <AlertListItem>
                                <Text>
                                    <FormattedMessage defaultMessage="S'adresser à un public d'experts." />
                                </Text>
                            </AlertListItem>
                        </AlertList>
                    }
                >
                    <Text fontWeight="--fw-semibold">
                        <FormattedMessage defaultMessage="Voici quelques idées pour commencer :" />
                    </Text>
                </Alert>
            </Stack>
            <SubmitButton type="submit" isLoading={isLoading} isDisabled={!isDirty || !isValid}>
                <FormattedMessage defaultMessage="Enregistrer" />
            </SubmitButton>
        </Form>
    );
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
`;
const SubmitButton = styled(Button)`
    width: fit-content;
`;
const AlertList = styled.ul`
    list-style-type: none;
    padding-inline: 0.5rem;
`;
const AlertListItem = styled.li`
    display: list-item;
    list-style-type: '- ';
    color: var(--neutral700);
`;

export default AIToneOfVoiceForm;
