import { FormattedMessage } from 'react-intl';

const EpisodeEditFormActionsTooltipMessage = ({
    isSchemaValid,
    hasAudioFile,
    isFreemiumQuotaReached,
}) => {
    if (isFreemiumQuotaReached === true) {
        return (
            <FormattedMessage defaultMessage="Dans la version gratuite d’Ausha vous ne pouvez publier ou planifier qu’un seul épisode." />
        );
    }

    if (hasAudioFile === false) {
        return (
            <FormattedMessage defaultMessage="Un fichier audio est requis pour publier l'épisode." />
        );
    }

    if (isSchemaValid === false) {
        return <FormattedMessage defaultMessage="Le formulaire contient des erreurs." />;
    }

    return <FormattedMessage defaultMessage="Cette action n'est pas disponible." />;
};

export default EpisodeEditFormActionsTooltipMessage;
