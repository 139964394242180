import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateClipFormat } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import clipFormatKeys from './clipFormatKeys';
import { ClipFormat } from './types';

export interface MutationFnProps {
    clipFormatId: string | number;
    clipFormat: { fullCover: boolean };
}

const mutationFn = async ({ clipFormatId, clipFormat }: MutationFnProps): Promise<ClipFormat> => {
    const { fullCover } = clipFormat;
    const { data } = await updateClipFormat(clipFormatId, { full_cover: fullCover });
    return camelcaseKeys(data as any, { deep: true });
};

const updateClipFormatMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn,
        onSuccess: (_, { clipFormatId }) => {
            queryClient.invalidateQueries(clipFormatKeys.detailById(clipFormatId));
        },
    });
};

export default updateClipFormatMutation;
