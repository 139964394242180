import styled, { css } from 'styled-components';

const Option = styled.div`
    padding: 2.25rem 1.5rem;
    border: 1px solid #d8d8d8;
    border-radius: var(--r-xs);
    cursor: pointer;
    transition: border-color 0.5s;
    position: relative;

    ${(props) =>
        props.selected
            ? css`
                  background-color: ${props.backgroundColor};
                  border-color: ${props.borderColor};
              `
            : css`
                  :hover {
                      border-color: #aca5a5;
                  }
              `};
`;

export default Option;
