import ReactIntercom from 'react-intercom';
import { connect } from '@/components/legacy/connect';

const enhance = connect(({ userStore, authStore }) => {
    const user = userStore.user;
    return {
        appID:
            userStore.locale === 'fr'
                ? process.env.INTERCOM_TOKEN_FR
                : process.env.INTERCOM_TOKEN_EN,
        user_id: user ? user.id : null,
        user_hash: user ? user.intercomToken : null,
        email: user ? user.email : null,
        name: user ? user.fullName : null,
        isLoggedAs: authStore.isLoggedAs,
        isTrackedInIntercom: user ? user.isTrackedInIntercom : true,
    };
});

const Intercom = ({ isLoggedAs, isTrackedInIntercom, ...props }) => {
    if (isLoggedAs || !isTrackedInIntercom) return null;

    return <ReactIntercom {...props} />;
};

export default enhance(Intercom);
