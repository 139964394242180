import NavButton from '@ui/atoms/NavButton';
import { useGuard } from '@/shared/hooks/useGuard.hook';
import UiKitTooltip from '@ui/atoms/UiKitTooltip/UiKitTooltip';
import styled from 'styled-components';

const Tooltip = styled(UiKitTooltip)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 120px;
    height: 100%;

    & > a {
        width: 100%;
    }
`;

const NavButtonAccess = ({
    tooltip,
    disallowedUserRoles,
    blockedSubscriptionAuthorized,
    noShow,
    ...props
}) => {
    const { allow, reason } = useGuard({
        disallowedUserRoles,
        blockedSubscriptionAuthorized,
        disallowAccess: props.disabled,
    });

    let tooltipContent = tooltip?.[reason];

    return (
        <Tooltip
            passthrough={allow || !tooltip || noShow}
            position="bottom"
            variant="dark"
            content={tooltipContent}
        >
            <NavButton {...props} disabled={!allow} />
        </Tooltip>
    );
};

export default NavButtonAccess;
