import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateRssImport } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import rssKeys from './rssKeys';

export interface MutationFnProps {
    showId: string;
    payload: Record<string, unknown>;
}

const mutationFn = async ({ showId, payload }: MutationFnProps) => {
    const { data } = await updateRssImport(showId, payload);
    return camelcaseKeys(data, { deep: true });
};

const useUpdateRssFeedMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({
                queryKey: rssKeys.feed(variables.showId),
            });
        },
    });
};

export default useUpdateRssFeedMutation;
