import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { fetchPlaylist } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import playlistKeys from '@/queries/playlist/playlistKeys';
import { z } from 'zod';
import { PlaylistSchema } from './usePlaylistsQuery.hook';
import { EpisodeSchema } from '../episode/useEpisodeQuery.hook';

const PlaylistEpisodeSchema = EpisodeSchema.extend({
    playlistPodcastId: z.number(),
    tags: z
        .object({
            data: EpisodeSchema.shape.tags,
        })
        .optional(),
    files: z
        .object({
            data: EpisodeSchema.shape.files,
        })
        .optional(),
});
const RawPlaylistEpisodeSchema = z.object({
    playlistPodcastId: z.number(),
    podcast: z.object({
        data: EpisodeSchema,
    }),
});
const PlaylistWithEpisodesSchema = PlaylistSchema.extend({
    episodes: PlaylistEpisodeSchema.array(),
});

export type RawPlaylistEpisode = z.infer<typeof RawPlaylistEpisodeSchema>;
export type PlaylistWithEpisodes = z.infer<typeof PlaylistWithEpisodesSchema>;
type Key = QueryFunctionContext<ReturnType<typeof playlistKeys.detailById>>;

const queryFn = async ({ queryKey: [{ playlistId }] }: Key) => {
    const { data } = await fetchPlaylist(playlistId);
    const formattedData = {
        ...data,
        episodes: data.podcasts.data.map((episode: RawPlaylistEpisode) => ({
            playlistPodcastId: episode.playlistPodcastId,
            ...episode.podcast.data,
        })),
    };
    delete formattedData.podcasts;

    return PlaylistWithEpisodesSchema.parse(camelcaseKeys(formattedData, { deep: true }));
};

const usePlaylistQuery = (playlistId: string) => {
    return useQuery({
        queryKey: playlistKeys.detailById(playlistId),
        queryFn,
        enabled: !!playlistId,
    });
};

export default usePlaylistQuery;
