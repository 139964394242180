import useShowNewsletterTemplateQuery from '@/queries/newsletter/useShowNewsletterTemplateQuery.hook';
import useSmartlinkSettingsQuery from '@/queries/smartlinkSetting/useSmartlinkSettingsQuery.hook';
import useWebsiteSettingsQuery from '@/queries/websiteSettings/useWebsiteSettingsQuery.hook';
import { useParams } from 'react-router';
import CallToActionForm from '../CallToActionForm';
import { useBodyToastQueue } from '@/shared/hooks/useBodyToastQueue.hook';
import { CallToActionFormSchema } from '../CallToActionForm/useCallToActionFormSchema.hook';
import useUpdateCallToActionButtonMutation from '@/queries/callToAction/useUpdateCallToActionButtonMutation.hook';
import { CallToAction } from '@/queries/callToAction/useCallToActionButtonsQuery.hook';
import CallToActionFormSkeleton from '../CallToActionForm/CallToActionFormSkeleton';

interface EditCallToActionProps {
    onClose: () => void;
    callToAction: CallToAction;
}

const EditCallToAction = ({ onClose, callToAction }: EditCallToActionProps) => {
    const { showId } = useParams<{ showId: string }>();
    const websiteSettings = useWebsiteSettingsQuery({ showId });
    const smartlinkSettings = useSmartlinkSettingsQuery(showId);
    const newsletterSettings = useShowNewsletterTemplateQuery(showId);
    const toast = useBodyToastQueue();
    const updateCallToAction = useUpdateCallToActionButtonMutation();
    const isError =
        websiteSettings.isError || smartlinkSettings.isError || newsletterSettings.isError;
    const hasData = websiteSettings.data && smartlinkSettings.data && newsletterSettings.data;

    if (isError) {
        return <div>ERROR</div>; // TODO: Handle error state
    }

    if (hasData) {
        const isWebsiteEnabled = websiteSettings.data?.isPublished;
        const isSmartlinkEnabled = smartlinkSettings.data?.isPublished;
        const isNewsletterEnabled = newsletterSettings.data?.activated;
        const defaultValues = {
            text: callToAction?.text || '',
            url: callToAction?.url || '',
            locations: callToAction?.locations || [],
            type: callToAction?.type || 'custom',
        };

        const handleEditCallToAction = (data: CallToActionFormSchema) => {
            updateCallToAction.mutate(
                {
                    ctaId: callToAction.id,
                    payload: data,
                },
                {
                    onSuccess: () => {
                        toast.success();
                        onClose();
                    },
                    onError: () => {
                        toast.alert();
                    },
                },
            );
        };

        return (
            <CallToActionForm
                onClose={onClose}
                defaultValues={defaultValues}
                onSubmit={handleEditCallToAction}
                isLoading={updateCallToAction.isLoading}
                isWebsiteEnabled={isWebsiteEnabled}
                isSmartlinkEnabled={isSmartlinkEnabled}
                isNewsletterEnabled={isNewsletterEnabled}
                isEdit={true}
            />
        );
    }

    return <CallToActionFormSkeleton />;
};

export default EditCallToAction;
