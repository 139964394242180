import { useFormContext } from 'react-hook-form';
import { InputRichTextController } from '@ui/atoms/InputRichText';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { useAccordionItem } from '@/components/ui/atoms/AccordionItem/useAccordionItem.hook';
import {
    EPISODE_AI_LOADING_ACTIONS,
    useEpisodeAILoading,
} from '@/components/NonHostingEpisodeForm/contexts/NonHostingEpisodeAILoadingContext';
import useSubscriptionQuery from '@/queries/subscription/useSubscriptionQuery.hook';
import { NonHostingEpisodeFormSchema } from '../useNonHostingEpisodeFormSchema.hook';
import EpisodeAIDescriptionSuggestionsPanel from '../NonHostingEpisodeAI/EpisodeAIDescriptionSuggestionsPanel';

const NonHostingEpisodeFormContentDescriptionField = () => {
    const { data: subscription } = useSubscriptionQuery();
    const { control, setValue } = useFormContext<NonHostingEpisodeFormSchema>();
    const intl = useIntl();
    const [loadingContent, setLoadingContent] = useState('');
    const { isExpanded } = useAccordionItem();
    const [state, dispatch] = useEpisodeAILoading();
    const isLoading = state.get('description') || false;

    // Stop wysiwyg loading animation when the accordion is closed
    useEffect(() => {
        if (!isExpanded) {
            handleStopLoading();
        }
    }, [isExpanded]);

    const handleStopLoading = () => {
        dispatch({
            type: EPISODE_AI_LOADING_ACTIONS.STOP,
            payload: 'description',
        });
        setLoadingContent('');
    };

    return (
        <div>
            <InputRichTextController
                name="content.htmlDescription"
                control={control}
                label={<FormattedMessage defaultMessage="Description" />}
                placeholder={intl.formatMessage({
                    defaultMessage: "Suscitez l'envie à vos auditeurs d’écouter votre épisode",
                })}
                charactersLimit={3900}
                data-testid="new-episode-description-input"
                isLoading={isLoading}
                onLoadingEnd={handleStopLoading}
                loadingContent={loadingContent}
                htmlLinksDisabled={subscription?.isFreemium}
                htmlLinksDisabledReason={
                    <FormattedMessage defaultMessage="L'ajout de liens n'est disponible que pour les offres payantes. 🙏 " />
                }
                onChangePlainText={(value: string) => {
                    setValue('_meta.plainTextDescription', value);
                }}
                onCreate={({ editor }: any) => {
                    // htmlDescription is processed by the backend and differ from tiptap content
                    // We need to update the form value with the processed content in order to check
                    // the form validity at mount
                    setValue('content.htmlDescription', editor.getHTML(), {
                        shouldValidate: true,
                        shouldDirty: false,
                    });
                }}
            />
            <EpisodeAIDescriptionSuggestionsPanel setLoadingContent={setLoadingContent} />
        </div>
    );
};

export default NonHostingEpisodeFormContentDescriptionField;
