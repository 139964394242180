import styled from 'styled-components';
import SubscriberNewsletterDeleteConfirmModal from '../../organisms/SubscriberNewsletterDeleteConfirmModal';
import { useSwitch } from '@hooks/useSwitch';
import Cluster from '@ui/layout/Cluster';
import Text from '@ui/atoms/Text';
import IconButton from '@ui/atoms/IconButton';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import useDeleteNewsletterSubscriberMutation from '@app/molecules/NewsletterPreview/useDeleteNewsletterSubscriberMutation.hook';
import useNewsletterSubscribersQueryHook from '@app/molecules/NewsletterPreview/useNewsletterSubscribersQuery.hook';

interface SubscriberNewsletterLineProps {
    subscriberEmail: string;
}

const SubscriberNewsletterLine = ({ subscriberEmail }: SubscriberNewsletterLineProps) => {
    const { showId } = useParams<{ showId: string }>();
    const subscribers = useNewsletterSubscribersQueryHook({ showId });
    const deleteSubscriber = useDeleteNewsletterSubscriberMutation();
    const [openedConfirmDeleteModal, onOpenConfirmDeleteModal, onCloseConfirmDeleteModal] =
        useSwitch();

    const handleDeleteSubscriber = () => {
        deleteSubscriber.mutate(
            { showId, subscriberEmail },
            {
                onSettled: () => {
                    subscribers.refetch();
                    onCloseConfirmDeleteModal();
                },
            },
        );
    };

    return (
        <>
            <SubscriberNewsletterDeleteConfirmModal
                isOpened={openedConfirmDeleteModal}
                onClose={onCloseConfirmDeleteModal}
                onSubmit={handleDeleteSubscriber}
            />
            <SubscriberLine key={subscriberEmail}>
                <SubscriberInformations>
                    <Text>{subscriberEmail}</Text>
                    <IconButton
                        variant="secondary"
                        size="medium"
                        isRound
                        icon={
                            <DeleteIcon
                                icon={icon({
                                    name: 'xmark',
                                    style: 'solid',
                                })}
                            />
                        }
                        onPress={onOpenConfirmDeleteModal}
                        aria-label="delete subscriber"
                    />
                </SubscriberInformations>
            </SubscriberLine>
        </>
    );
};

const SubscriberLine = styled.div`
    border-top: 1px solid;
    border-color: var(--neutral100);
    background: var(--white);

    &:hover {
        background: var(--neutral50);
    }
`;

const SubscriberInformations = styled(Cluster)`
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
`;

const DeleteIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
`;

export default SubscriberNewsletterLine;
