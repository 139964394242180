import { createApi } from '../callApi';
const contentApi = createApi('CONTENT');

export const fetchYoutubeBroadcastSettings = (show) => {
    return contentApi({ method: 'GET', target: `/v1/shows/${show}/youtube` });
};
export const fetchYoutubeCategories = () => {
    return contentApi({ method: 'GET', target: '/v1/youtube/categories' });
};
export const fetchYoutubeVideo = (podcast) => {
    return contentApi({ method: 'GET', target: `/v1/podcasts/${podcast}/youtube` });
};
export const fetchSoundcloudBroadcastSettings = (show) => {
    return contentApi({ method: 'GET', target: `/v1/shows/${show}/soundcloud` });
};
export const updateYoutubeBroadcastSettings = (show, body) => {
    return contentApi({ method: 'PUT', target: `/v1/shows/${show}/youtube`, body });
};
export const updateSoundcloudBroadcastSettings = (show, body) => {
    return contentApi({ method: 'PUT', target: `/v1/shows/${show}/soundcloud`, body });
};
