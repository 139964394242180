import Dialog from '@ui/atoms/Dialog';
import Button from '@/components/Button';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

interface InstagramAccount {
    id: string;
    profilePictureUrl: string;
    username: string;
}

interface InstagramAccountSelectModalProps {
    isOpen: boolean;
    onOpenChange: () => void;
    instagramAccounts: InstagramAccount[];
    onAccountSelect: (id: string) => void;
}

const InstagramAccountSelectModal = ({
    isOpen,
    onOpenChange,
    instagramAccounts,
    onAccountSelect,
}: InstagramAccountSelectModalProps) => {
    return (
        <Dialog
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            title={
                <FormattedMessage defaultMessage="À quel compte Instagram souhaitez-vous vous connecter ?" />
            }
            size="small"
            action={
                <Button onPress={onOpenChange} variant="ghost">
                    <FormattedMessage defaultMessage="Annuler" />
                </Button>
            }
        >
            <Description>
                <FormattedMessage defaultMessage="Seuls les comptes business peuvent être connectés." />
            </Description>
            <InstagramAccounts>
                {instagramAccounts?.map((account: InstagramAccount) => (
                    <InstagramAccount key={account.id} onClick={() => onAccountSelect(account.id)}>
                        <Left>
                            <ProfilePicture
                                src={account.profilePictureUrl}
                                alt={account.username}
                            />
                            <Username>{account.username}</Username>
                        </Left>
                        <Button
                            className="connect-button"
                            variant="secondary"
                            onPress={() => onAccountSelect(account.id)}
                        >
                            <FormattedMessage defaultMessage="Connecter" />
                        </Button>
                    </InstagramAccount>
                ))}
            </InstagramAccounts>
        </Dialog>
    );
};

const Description = styled.p`
    color: var(--neutral500);
`;
const InstagramAccounts = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.75rem;
    margin-block: 1rem;
`;
const InstagramAccount = styled.li`
    animation-duration: 0.25s;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: var(--r-m);
    padding: 0.75rem;

    & > .connect-button {
        visibility: hidden;
    }
    &:hover {
        animation-duration: 0.25s;
        background-color: var(--neutral50);
    }
    &:hover > .connect-button {
        visibility: visible;
    }
`;
const Left = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;
const ProfilePicture = styled.img`
    width: 2rem;
    height: 2rem;
    border-radius: var(--r-full);
`;
const Username = styled.span`
    font-weight: var(--fw-semibold);
`;

export default InstagramAccountSelectModal;
