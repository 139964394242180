import CTADragAndDropItem from '@/components/unorganized/CTADragAndDropItem';
import DragAndDrop from '@/components/unorganized/DragAndDrop';

const ReordableCallToActionButtons = ({ location, callToActionButtons, reordered }) => {
    return (
        <>
            {callToActionButtons.length > 0 && (
                <DragAndDrop
                    id={location}
                    list={callToActionButtons}
                    getData={(data) => {
                        reordered(data.newList);
                    }}
                >
                    {({ item, isDragging }) => (
                        <CTADragAndDropItem item={item} isDragging={isDragging} />
                    )}
                </DragAndDrop>
            )}
        </>
    );
};

export default ReordableCallToActionButtons;
