import { useState, useEffect } from 'react';
import { BREAKPOINTS } from '@/utils/responsive';

// Memo if one day we need to add Queries
// https://www.freecodecamp.org/news/css-media-queries-breakpoints-media-types-standard-resolutions-and-more/#:~:text=320px%20%E2%80%94%20480px%3A%20Mobile%20devices,%E2%80%94%201200px%3A%20Desktops%2C%20large%20screens

export function useWindowDimensions() {
    const [dims, setDims] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
        media: {},
        breakPoints: '',
    });
    const breakPoints = {
        isTablet: BREAKPOINTS.TABLET_MIN,
        isDesktop: BREAKPOINTS.DESKTOP_MIN,
        isDesktopLarge: BREAKPOINTS.DESKTOP_LARGE_MIN,
    };

    function resize() {
        let media;
        if (window.innerWidth < breakPoints.isTablet) {
            media = {
                isMobile: true,
                isTablet: false,
                isDesktop: false,
                isMobileOrTablet: true,
                isMobileOrDesktop: true,
                isTabletOrDesktop: false,
                isDesktopLarge: false,
            };
        } else if (window.innerWidth < breakPoints.isDesktop) {
            media = {
                isMobile: false,
                isTablet: true,
                isDesktop: false,
                isMobileOrTablet: true,
                isMobileOrDesktop: true,
                isTabletOrDesktop: true,
                isDesktopLarge: false,
            };
        } else if (window.innerWidth < breakPoints.isDesktopLarge) {
            media = {
                isMobile: false,
                isTablet: false,
                isDesktop: true,
                isMobileOrTablet: false,
                isMobileOrDesktop: true,
                isTabletOrDesktop: true,
                isDesktopLarge: false,
            };
        } else {
            media = {
                isMobile: false,
                isTablet: false,
                isDesktop: true,
                isMobileOrTablet: false,
                isMobileOrDesktop: false,
                isTabletOrDesktop: true,
                isDesktopLarge: true,
            };
        }
        setDims({ width: window.innerWidth, height: window.innerHeight, media });
    }

    useEffect(() => {
        window.addEventListener('resize', resize);
        // media on Mount
        resize();
        return () => window.removeEventListener('resize', resize);
    }, []);

    return dims;
}
