import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Button, Group, Input as AriaInput, NumberField } from 'react-aria-components';
import styled from 'styled-components';
import Label from '../Label';
import ErrorMessage from '../ErrorMessage';
import { useEffect, useRef, useState } from 'react';

const InputNumber = ({
    onDecrement,
    onIncrement,
    isOptional,
    isRequired,
    tooltip,
    description,
    label,
    errorMessage,
    ...props
}) => {
    const inputRef = useRef(null);
    const [inputWidth, setInputWidth] = useState();

    useEffect(() => {
        handleSetInputWidth(props.value);
    }, []);

    const handleSetInputWidth = (value) => {
        if (!value) return;
        const width = value.toString().length;
        setInputWidth(`${width}ch`);
    };

    const handleChange = (value) => {
        handleSetInputWidth(value);
        props?.onChange?.(value);
    };

    return (
        <NumberFieldStyled {...props} onChange={handleChange}>
            <Label
                isOptional={isOptional}
                isRequired={isRequired}
                tooltip={tooltip}
                description={description}
            >
                {label}
            </Label>
            <InputGroup>
                <InputNumberButton onPress={onDecrement} slot="decrement">
                    <InputNumberIcon icon={icon({ name: 'minus', style: 'solid' })} />
                </InputNumberButton>
                <Input ref={inputRef} $width={inputWidth} />
                <InputNumberButton onPress={onIncrement} slot="increment">
                    <InputNumberIcon icon={icon({ name: 'plus', style: 'solid' })} />
                </InputNumberButton>
            </InputGroup>
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </NumberFieldStyled>
    );
};

const NumberFieldStyled = styled(NumberField)`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
`;
const InputNumberButton = styled(Button)`
    width: 2rem;
    height: 2rem;
    border-radius: var(--r-s);
    background-color: var(--primary50);
    border: none;
    color: var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;
const InputNumberIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: var(--primary);
`;
const InputGroup = styled(Group)`
    display: flex;
    align-items: center;
    column-gap: 1rem;
`;
const Input = styled(AriaInput)`
    padding: 0;
    background: transparent;
    border: none;
    font-size: var(--fs-body-l);
    font-weight: var(--fw-semibold);
    min-width: 1.25rem;
    width: ${({ $width }) => `calc(${$width} + 0.5ch)` || 'auto'};
`;

/* 
    cf. react-aria NumberField: https://react-spectrum.adobe.com/react-aria/NumberField.html#props
*/
InputNumber.propTypes = {
    onChange: PropTypes.func,
    onDecrement: PropTypes.func,
    onIncrement: PropTypes.func,
    isOptional: PropTypes.bool,
    tooltip: PropTypes.string,
    description: PropTypes.string,
    label: PropTypes.string.isRequired,
    errorMessage: PropTypes.string,
    decrementAriaLabel: PropTypes.string,
    incrementAriaLabel: PropTypes.string,
    formatOptions: PropTypes.object,
    isDisabled: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    isRequired: PropTypes.bool,
    isInvalid: PropTypes.bool,
    validate: PropTypes.func,
    autoFocus: PropTypes.bool,
    value: PropTypes.number,
    defaultValue: PropTypes.number,
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    step: PropTypes.number,
    validationBehavior: PropTypes.oneOf(['native', 'aria']),
    name: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

InputNumber.defaultProps = {
    onDecrement: () => {},
    onIncrement: () => {},
};

export default InputNumber;
