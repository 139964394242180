import { createApi } from '../callApi';
const contentApi = createApi('CONTENT');

export const deleteCallToActionButton = (cta) => {
    return contentApi({ method: 'DELETE', target: `/v1/ctas/${cta}` });
};
export const updateCallToActionButtons = (cta, body) => {
    return contentApi({ method: 'PUT', target: `/v1/ctas/${cta}`, body });
};
export const updateCallToActionButtonsPositions = (show, body) => {
    return contentApi({
        method: 'POST',
        target: `/v1/shows/${show}/ctas/positions`,
        body,
    });
};
export const fetchCallToActionButtons = (show, location) => {
    return contentApi({
        method: 'GET',
        target: `/v1/shows/${show}/ctas`,
        query: { location },
    });
};
export const addCallToActionButton = (show, body) => {
    return contentApi({ method: 'POST', target: `/v1/shows/${show}/ctas`, body });
};
