import { useRef, useMemo, useState } from 'react';
import ContentLoader from 'react-content-loader';
import averageRankingIcon from '@public/icons/ranking_primary.svg';
import rankingIcon from '@public/icons/ranking_blue.svg';
import ratingIcon from '@public/icons/rating.svg';
import reviewIcon from '@public/icons/review.svg';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import createFallback from '@/components/unorganized/createFallback';
import DeprecatedIcon from '@ui/atoms/DeprecatedIcon';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import Ranking from '@/components/unorganized/Ranking';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import useMousePosition from '@/shared/hooks/useMousePosition';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { useParams } from 'react-router';
import { useApplePodcastsStatsContext } from '@/context/ApplePodcastsStatsContext';
import useAppleRankingOverallQuery from '@/queries/stats/useAppleRankingOverallQuery.hook';
import { getLocalTimeZone } from '@internationalized/date';
import useUserQuery from '@/queries/user/useUserQuery.hook';

const RatingsTooltip = styled(motion.div)`
    position: absolute;
    width: ${(p) => `${p.width}px`};
    top: 0;
    left: 0;
    background-color: white;
    box-shadow: var(--s-light);
    border-radius: var(--r-xs);
`;

const RATINGS_COUNT_TOOLTIP_WIDTH = 150;

const SummaryItem = ({ icon, label, value, ...props }) => (
    <DeprecatedPaper flex w={150} m={10} style={{ flexShrink: 0 }} {...props}>
        <DeprecatedIcon as={icon} size={60} style={{ flexShrink: 0 }} />
        <DeprecatedPaper flex column justify="center" ml={10}>
            <DeprecatedPaper mb={-5}>{value}</DeprecatedPaper>
            <DeprecatedText color="--neutral500">{label}</DeprecatedText>
        </DeprecatedPaper>
    </DeprecatedPaper>
);

const SummaryItemFallback = createFallback((props) => (
    <ContentLoader
        height={60}
        width={150}
        style={{ height: 60, width: 150, margin: 10 }}
        {...props}
    >
        <circle cx="30" cy="30" r="30" />
        <rect x="70" y="10" rx="5" ry="5" width="80" height="20" />
        <rect x="70" y="36" rx="5" ry="5" width="60" height="14" />
    </ContentLoader>
));

const SummaryFallback = createFallback(() => (
    <>
        <DeprecatedPaper flex align="center" justify="space-around" wrap="wrap" auto w="auto">
            <SummaryItemFallback />
            <SummaryItemFallback />
        </DeprecatedPaper>
        <DeprecatedPaper flex align="center" justify="space-around" wrap="wrap" auto w="auto">
            <SummaryItemFallback />
            <SummaryItemFallback />
        </DeprecatedPaper>
    </>
));

function ApplePodcastsSummary() {
    const ref = useRef(null);
    const { showId } = useParams();
    const { category } = useApplePodcastsStatsContext();
    const [showRatingsTooltip, setShowRatingsTooltip] = useState(false);
    const mousePosition = useMousePosition();

    const { data: user } = useUserQuery();
    const timezone = useMemo(() => user?.timezone || getLocalTimeZone(), [user]);

    const { data: summary, isLoading } = useAppleRankingOverallQuery({
        showId,
        categoryId: category,
        timezone,
    });

    return (
        <ApplePodcastSummaryWrapper>
            {isLoading ? (
                <SummaryFallback />
            ) : (
                <>
                    <DeprecatedPaper
                        flex
                        align="center"
                        justify="space-around"
                        wrap="wrap"
                        auto
                        w="auto"
                        z={3}
                        ref={ref}
                    >
                        {summary?.overallStats?.nbNotes && showRatingsTooltip && (
                            <RatingsTooltip
                                width={RATINGS_COUNT_TOOLTIP_WIDTH}
                                style={{
                                    x: mousePosition.x - RATINGS_COUNT_TOOLTIP_WIDTH / 2,
                                    y: mousePosition.y - 70,
                                }}
                            >
                                <DeprecatedPaper px={15} py={10} background="--neutral50">
                                    <DeprecatedText
                                        align="center"
                                        color="--neutral500"
                                        weight="semibold"
                                    >
                                        <FormattedMessage defaultMessage="Notes" />
                                    </DeprecatedText>
                                </DeprecatedPaper>
                                <DeprecatedPaper flex align="center" justify="center" p={10}>
                                    <DeprecatedText align="center" weight="semibold">
                                        {`${summary?.overallStats?.nbNotes} `}
                                        <FormattedMessage defaultMessage="au total" />
                                    </DeprecatedText>
                                </DeprecatedPaper>
                            </RatingsTooltip>
                        )}
                        <SummaryItem
                            icon={ratingIcon}
                            label={<FormattedMessage defaultMessage="note" />}
                            onMouseEnter={() => setShowRatingsTooltip(true)}
                            onMouseLeave={() => setShowRatingsTooltip(false)}
                            value={
                                <DeprecatedText size="veryBig" weight="semibold">
                                    {summary?.overallStats?.note ? (
                                        <FormattedNumber value={summary?.overallStats?.note} />
                                    ) : (
                                        '-'
                                    )}
                                    <DeprecatedText as="span" ml={5}>
                                        /5
                                    </DeprecatedText>
                                </DeprecatedText>
                            }
                        />
                        <SummaryItem
                            icon={reviewIcon}
                            label={<FormattedMessage defaultMessage="avis" />}
                            value={
                                <DeprecatedText size="veryBig" weight="semibold">
                                    {summary?.overallStats?.reviewsNumber ? (
                                        <FormattedNumber
                                            value={summary?.overallStats?.reviewsNumber}
                                        />
                                    ) : (
                                        '-'
                                    )}
                                </DeprecatedText>
                            }
                        />
                    </DeprecatedPaper>

                    <DeprecatedPaper
                        flex
                        align="center"
                        justify="space-around"
                        wrap="wrap"
                        auto
                        w="auto"
                    >
                        <SummaryItem
                            icon={rankingIcon}
                            label={<FormattedMessage defaultMessage="class. actuel" />}
                            value={
                                summary?.overallStats?.actualPosition ? (
                                    <Ranking value={summary?.overallStats?.actualPosition} />
                                ) : (
                                    <DeprecatedText size="veryBig" weight="semibold">
                                        -
                                    </DeprecatedText>
                                )
                            }
                        />
                        <SummaryItem
                            icon={averageRankingIcon}
                            label={<FormattedMessage defaultMessage="class. moyen" />}
                            value={
                                summary?.overallStats?.averagePosition ? (
                                    <Ranking value={summary?.overallStats?.averagePosition} />
                                ) : (
                                    <DeprecatedText size="veryBig" weight="semibold">
                                        -
                                    </DeprecatedText>
                                )
                            }
                        />
                    </DeprecatedPaper>
                </>
            )}
        </ApplePodcastSummaryWrapper>
    );
}

const ApplePodcastSummaryWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    z-index: 3;
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding);
    margin-block: 2rem;
    gap: 1rem ${({ theme }) => theme.mediaQueries.mobile} {
        padding: var(--container-padding-mobile);
    }
`;

export default ApplePodcastsSummary;
