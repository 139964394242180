import { compose } from '@/helpers';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import RouterStore from '@/stores/RouterStore';

const routeInjector = (mapStateToProps) =>
    compose(
        withRouter,
        inject(({ stores }, { history, match, location, ...props }) => {
            const routerStore = new RouterStore(history, match, location, stores);
            return mapStateToProps(Object.assign(stores, { routerStore }), props);
        }),
    );

export const connect = (mapStateToProps) => compose(routeInjector(mapStateToProps), observer);
