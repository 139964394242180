import { useEffect, useState } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import styled from 'styled-components';
import { useSwitch } from '@hooks/useSwitch';
import Text from '@ui/atoms/Text';
import Stack from '@ui/layout/Stack';
import Cluster from '@ui/layout/Cluster';
import Img from '@ui/atoms/Img';
import imageMonetizationBlocked from '@public/images/illu_monetization_blocked.png';
import { useResponsive } from '@hooks/useResponsive';
import IconCheckedCircle from '@ui/icons/IconCheckedCircle';
import targetspotLogo from '@public/images/targetspot.png';
import Button from '@ui/atoms/Button';
import IconOpenLink from '@ui/icons/IconOpenLink';
import {
    addTargetspotOnShow,
    deleteTargetspotMonetization,
    fetchCampaignMonetization,
    fetchShowOverallStats,
    fetchShowsWithTargetspotEnabled,
} from '@/api';
import useRouterStore from '@/utils/hooks/useRouterStore';
import { TARGETSPOT } from '@/utils/constants';
import useSubscriptionStore from '@/utils/hooks/useSubscriptionStore';
import IlluEmojiLocked from '@public/images/illu_emoji_locked.svg?url';
import IconRocket from '@ui/icons/IconRocket';
import IconInfo from '@ui/icons/IconInfo';
import IconTargetspot from '@ui/icons/IconTargetspot';
import WrapperModal from '../organisms/SocialMediaWrapperModal';
import Link from '@ui/atoms/Link';
import useUserStore from '@/utils/hooks/useUserStore';
import { useParams } from 'react-router-dom';
import useShowStore from '@/utils/hooks/useShowStore';
import Cookie from 'js-cookie';
import IconBeta from '@public/icons/test.svg';
import Alert from '@ui/atoms/Alert';
import emptyStateMainIllustration from '@public/images/piggy.png';
import ZebraIllustration from '@ui/atoms/ZebraIllustration';
import EmptyState from '@ui/molecules/EmptyState';
import ShiningDiamond from '@ui/atoms/ShiningDiamond';
import { connect } from '@app/decorators/connect';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import { sendAmplitudeLogEvent } from '@/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { IntercomAPI } from 'react-intercom';

export const NUMBER_DOWNLOADS_REQUIRED = 10000;

const StyledBox = styled.div`
    display: flex;
    flex: 1 1 0;
    justify-content: center;
    align-items: center;
`;

const StyledLink = styled(Text)`
    cursor: pointer;
    &:hover {
        color: var(--black);
    }
`;

const ContactLink = styled(Text)`
    text-decoration: underline;
    cursor: pointer;
`;

const AlertIcon = styled(FontAwesomeIcon)`
    color: var(--alert500);
    height: 1rem;
    width: 1rem;
`;

const LeftBlock = styled(StyledBox)`
    padding: ${(p) => (p.isMobile ? '1.5rem 1rem 1rem 1rem' : '2rem 2.5rem')};
`;

const RightBlock = styled(StyledBox)`
    padding: 1rem;
`;

const ImgWrapper = styled(StyledBox)`
    padding: 1.5rem 1rem;
    background: var(--gradient-dusk);
    border-radius: var(--r-l);
`;

// Update with new HighlightIcon component and restore required on children Alert props
const BlueAlert = styled(Alert)`
    width: fit-content;
`;

const GreenCircle = styled.div`
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    border-radius: var(--r-full);
    background: var(--success50);
    color: var(--whatsapp);
`;

const BetaLabelStyled = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.375rem;
    padding: 0.5rem 0.75rem;
    background-color: #fff4e9;
    border-radius: var(--r-s);
    color: #ff9326;
`;

const OuterWrapper = styled.div`
    background: var(--white);
    border-radius: var(--r-l);
`;

const EmptyStateOuterWrapper = styled.div`
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

const HeadIllustrationWrapper = styled.div`
    width: 200px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    & > * {
        text-align: center;
    }
`;

const BetaLabel = () => (
    <BetaLabelStyled>
        <IconBeta />
        <Text variant="footnote" fontWeight="--fw-semibold">
            <FormattedMessage defaultMessage="Bêta" />
        </Text>
    </BetaLabelStyled>
);

const TargetspotLogoBeta = () => (
    <Cluster $align="start" $justify="space-between">
        <Img src={targetspotLogo} w={105} h={20} mb="1rem" />
        <BetaLabel />
    </Cluster>
);

const ContactTargetspotMessage = () => {
    return (
        <Text color="--neutral500" variant="footnote">
            <FormattedMessage
                defaultMessage="Si vous avez des questions ou avez besoin d'aide concernant la monétisation automatique, n'hésitez pas à {contactTargetspot}."
                values={{
                    contactTargetspot: (
                        <Text
                            as="a"
                            color="--primary"
                            variant="footnote"
                            href="https://www.targetspot.com/contact-us/"
                            target="_blank"
                        >
                            <FormattedMessage defaultMessage="contacter TargetSpot" />
                        </Text>
                    ),
                }}
            />
        </Text>
    );
};

const Wrapper = ({ isCentered, children, ...props }) => {
    const { isMobile } = useResponsive();
    return isMobile ? (
        <Stack $align={isCentered && 'center'} {...props}>
            {children}
        </Stack>
    ) : (
        <Cluster $justify={isCentered && 'center'} $align="stretch" {...props}>
            {children}
        </Cluster>
    );
};

const TargetspotActivated = ({ disabledTargetspot }) => {
    const { showId } = useParams();
    const { isMobile } = useResponsive();
    const [modalOpened, openModal, closeModal] = useSwitch(false);

    return (
        <>
            <Stack $gap="1.25rem">
                <Stack $gap="0.5rem">
                    <TargetspotLogoBeta />
                    <IconCheckedCircle color="--success" size="2rem" />
                    <Text variant="heading" fontWeight="--fw-semibold">
                        <FormattedMessage defaultMessage="Votre émission est monétisée." />
                    </Text>
                    <Text>
                        <FormattedMessage defaultMessage="Consultez vos statistiques de monétisation en y accédant via Targetspot. " />
                    </Text>
                </Stack>
                <Stack $gap="1.25rem">
                    <Wrapper>
                        <Button
                            as="a"
                            target="_blank"
                            href="https://ausha.targetspot.com/"
                            background="--success"
                            startIcon={<IconOpenLink />}
                        >
                            <FormattedMessage defaultMessage="Accéder à Targetspot" />
                        </Button>
                    </Wrapper>
                    <Stack $gap="2.25rem">
                        <Wrapper isCentered={isMobile}>
                            <StyledLink
                                fontWeight="--fw-semibold"
                                color="--alert"
                                onClick={openModal}
                            >
                                <FormattedMessage defaultMessage="Ne plus monétiser cette émission" />
                            </StyledLink>
                        </Wrapper>
                        <ContactTargetspotMessage />
                    </Stack>
                </Stack>
            </Stack>
            <WrapperModal
                opened={modalOpened}
                onClose={closeModal}
                title={<FormattedMessage defaultMessage="Arrêter la monétisation" />}
                width="25.25rem"
            >
                <Stack $gap="2.5rem">
                    <Text>
                        <FormattedMessage
                            defaultMessage={
                                'Souhaitez-vous arrêter la monétisation ?{linebreak}Aucune autre publicité ne sera jouée sur votre émission.'
                            }
                            values={{
                                linebreak: <br />,
                            }}
                        />
                    </Text>
                    <Wrapper $gap="0.5rem" $justify="flex-end">
                        <Button variant="secondary" onPress={closeModal}>
                            <FormattedMessage defaultMessage="Annuler" />
                        </Button>
                        <Button
                            background="--whatsapp"
                            onPress={() =>
                                deleteTargetspotMonetization(showId).then(() => {
                                    disabledTargetspot();
                                    closeModal();
                                })
                            }
                        >
                            <FormattedMessage defaultMessage="Arrêter la monétisation" />
                        </Button>
                    </Wrapper>
                </Stack>
            </WrapperModal>
        </>
    );
};

const SubscribeMessage = () => {
    const offerLink = useRouterStore().link('user.offers');
    return (
        <Stack $gap="1rem">
            <Stack $gap="0.5rem">
                <Img src={IlluEmojiLocked} size="4rem" />
                <Text variant="subheading" fontWeight="--fw-semibold">
                    <FormattedMessage defaultMessage="Générez des revenus grâce à votre podcast" />
                </Text>
                <Text color="--neutral500">
                    <FormattedMessage
                        defaultMessage="Dès lors que vous atteignez {downloads} écoutes mensuelles, vous pourrez mettre à disposition votre podcast à la programmation de spots publicitaires au début de chaque épisode."
                        values={{
                            downloads: <FormattedNumber value={NUMBER_DOWNLOADS_REQUIRED} />,
                        }}
                    />
                </Text>
            </Stack>
            <Wrapper>
                <Button as={Link} to={offerLink} startIcon={<IconRocket />}>
                    <FormattedMessage defaultMessage="S’abonner à Ausha" />
                </Button>
            </Wrapper>
        </Stack>
    );
};

const AlreadyMonetized = () => (
    <Stack $gap="1rem">
        <BlueAlert variant="info" icon={<IconInfo />} />
        <Stack $gap="0.25rem">
            <Text variant="heading" fontWeight="--fw-semibold">
                <FormattedMessage defaultMessage="Votre émission est déjà monétisée via une régie externe" />
            </Text>
            <Text variants="footnote" color="--neutral500">
                <FormattedMessage defaultMessage="Vous ne pouvez pas monétiser votre émission directement depuis Ausha car celle-ci est déjà connectée à une régie publicitaire externe." />
            </Text>
        </Stack>
    </Stack>
);

const NotEnoughDownloads = () => (
    <Stack $gap="0.5rem">
        <TargetspotLogoBeta />
        <Stack $gap="1rem">
            <Stack $gap="0.25rem">
                <Text variant="heading" fontWeight="--fw-semibold">
                    <FormattedMessage defaultMessage="Monétisez votre émission avec Targetspot." />
                </Text>
                <Text variants="footnote" color="--neutral500">
                    <FormattedMessage defaultMessage="Targetspot est une régie publicitaire spécialisée en audio digital. Grâce à l’intégration entre Ausha et Targetspot, vous pouvez monétiser efficacement et facilement votre podcast." />
                </Text>
            </Stack>
            <Alert variant="info" icon={<IconInfo />}>
                <FormattedMessage
                    defaultMessage="La monétisation automatique est pour le moment réservée aux émissions réalisant au moins {downloads} écoutes sur les 30 derniers jours."
                    values={{
                        downloads: <FormattedNumber value={NUMBER_DOWNLOADS_REQUIRED} />,
                    }}
                />
            </Alert>
        </Stack>
    </Stack>
);

const ListItem = ({ index, message }) => (
    <Cluster $gap="0.5rem" $align="center" $wrap="nowrap">
        <GreenCircle>
            <Text variant="subheading" fontWeight="--fw-bold">
                {index}
            </Text>
        </GreenCircle>
        <Text>{message}</Text>
    </Cluster>
);

const ActivateTargetspot = ({ enableTargetspot }) => {
    const { showId } = useParams();
    const { user } = useUserStore();
    const show = useShowStore().byId(showId);
    const [hasShowsOnTargetspot, setHasShowsOnTargetspot] = useState(0);
    const [modalOpened, openModal, closeModal] = useSwitch(false);
    const token = Cookie.get('token');

    useEffect(async () => {
        if (show) {
            setHasShowsOnTargetspot(
                (await fetchShowsWithTargetspotEnabled(show.channelId)).data.length > 0,
            );
        }
    }, [show]);

    return (
        <>
            <Stack $gap="0.5rem">
                <TargetspotLogoBeta />
                <Stack $gap="1rem">
                    <Stack $gap="0.25rem">
                        <Text variant="heading" fontWeight="--fw-semibold">
                            <FormattedMessage defaultMessage="Monétisez votre émission avec Targetspot." />
                        </Text>
                        <Text variant="footnote" color="--neutral500">
                            <FormattedMessage defaultMessage="Targetspot est une régie publicitaire spécialisée en audio digital. Grâce à l’intégration entre Ausha et Targetspot, vous pouvez monétiser efficacement et facilement votre podcast." />
                        </Text>
                    </Stack>
                    <ListItem
                        index={1}
                        message={
                            <FormattedMessage defaultMessage="Inscrivez-vous sur Targetspot" />
                        }
                    />
                    <ListItem
                        index={2}
                        message={
                            <FormattedMessage defaultMessage="Des campagnes publicitaires seront jouées automatiquement au début et/ou à la fin de votre podcast" />
                        }
                    />
                    <ListItem
                        index={3}
                        message={
                            <FormattedMessage defaultMessage="Vous percevez 50% des revenus publicitaires chaque mois" />
                        }
                    />
                    <Stack $gap="1rem">
                        <Wrapper>
                            <Button
                                background="--whatsapp"
                                startIcon={<IconTargetspot />}
                                isDisabled
                                onPress={() =>
                                    hasShowsOnTargetspot
                                        ? addTargetspotOnShow(show?.id).then(enableTargetspot)
                                        : openModal()
                                }
                            >
                                <FormattedMessage defaultMessage="Se connecter à Targetspot" />
                            </Button>
                        </Wrapper>
                        <Stack $gap="0.5rem">
                            <Alert
                                variant="alert"
                                icon={
                                    <AlertIcon
                                        icon={icon({ name: 'circle-exclamation', style: 'solid' })}
                                    />
                                }
                            >
                                <Text fontWeight="--fw-semibold">
                                    <FormattedMessage defaultMessage="En raison d’un problème technique, le service Targetspot est temporairement indisponible." />
                                </Text>
                                <Text color="--neutral700">
                                    <FormattedMessage
                                        defaultMessage="Besoin d’aide ? <link>Contactez le support</link>."
                                        values={{
                                            link: (chunks) => (
                                                <ContactLink
                                                    as="span"
                                                    onClick={() => IntercomAPI('show')}
                                                >
                                                    {chunks}
                                                </ContactLink>
                                            ),
                                        }}
                                    />
                                </Text>
                            </Alert>
                            <ContactTargetspotMessage />
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
            <WrapperModal
                opened={modalOpened}
                onClose={closeModal}
                title={<FormattedMessage defaultMessage="Se connecter à Targetspot" />}
                width="25.25rem"
            >
                <Stack $gap="2.5rem">
                    <Text>
                        <FormattedMessage defaultMessage="Pour ajouter votre émission à Targetspot, vous devez vous connecter à la plateforme, ou créer un compte si ce n’est pas encore fait." />
                    </Text>
                    <Wrapper $gap="0.5rem" $justify="flex-end">
                        <Button variant="secondary" onPress={closeModal}>
                            <FormattedMessage defaultMessage="Annuler" />
                        </Button>
                        <Button
                            as="a"
                            target="_blank"
                            href={`https://ausha.targetspot.com/signup?externalgroupid=${user?.id}&externalid=${showId}&token=${token}`}
                            background="--whatsapp"
                            startIcon={<IconOpenLink />}
                            onPress={() =>
                                sendAmplitudeLogEvent('Automatic Monetization Setup Started')
                            }
                        >
                            <FormattedMessage defaultMessage="Aller sur Targetspot" />
                        </Button>
                    </Wrapper>
                </Stack>
            </WrapperModal>
        </>
    );
};

const LeftBlockContent = ({
    isTargetspotActivated,
    hasOtherMonetizationPlatform,
    enableTargetspot,
    disabledTargetspot,
    isSubscribe,
    downloads,
}) => {
    if (isTargetspotActivated)
        return <TargetspotActivated disabledTargetspot={disabledTargetspot} />;
    if (!isSubscribe) return <SubscribeMessage />;
    if (hasOtherMonetizationPlatform) return <AlreadyMonetized />;
    if (downloads < 10000) return <NotEnoughDownloads />;
    return <ActivateTargetspot enableTargetspot={enableTargetspot} />;
};

const enhance = connect(({ routerStore }) => ({
    upgradeOfferLink: routerStore.link(
        routerStore.params.showId ? 'user.edit.offers' : 'user.offers',
    ),
}));

const CampaignAutoEditPage = ({ upgradeOfferLink }) => {
    const user = useUserQuery();
    const { isMobile } = useResponsive();
    const { showId } = useParams();
    const [monetizationPlatform, setMonetizationPlatform] = useState(null);
    const isSubscribe = useSubscriptionStore().subscription?.subscribed;
    const [downloads, setDownloads] = useState(0);

    useEffect(async () => {
        if (showId) {
            setMonetizationPlatform(
                (await fetchCampaignMonetization(showId)).data.monetization_platform,
            );
            setDownloads((await fetchShowOverallStats(showId)).data.lastMonth.downloads);
        }
    }, [showId]);

    if (user?.data?.activated === false) {
        return (
            <EmptyStateOuterWrapper>
                <Text fontWeight="--fw-bold" variant="title">
                    <FormattedMessage defaultMessage="Monétisation automatique" />
                </Text>
                <EmptyState
                    illustration={emptyStateMainIllustration}
                    illustrationType="image"
                    illustrationAlign="center"
                    illustrationBackground="--gradient-sunny"
                >
                    <HeadIllustrationWrapper>
                        <ZebraIllustration>
                            <ShiningDiamond />
                        </ZebraIllustration>
                    </HeadIllustrationWrapper>
                    <LabelWrapper>
                        <Text variant="heading" fontWeight="--fw-bold">
                            <FormattedMessage defaultMessage="Abonnez-vous !" />
                        </Text>
                        <Text variant="body" fontWeight="--fw-normal" color="--neutral500">
                            <FormattedMessage defaultMessage="Pour débloquer la monétisation de votre podcast, abonnez-vous 🚀" />
                        </Text>
                    </LabelWrapper>
                    <Button startIcon={<IconRocket />} as={Link} to={upgradeOfferLink}>
                        <FormattedMessage defaultMessage="S'abonner" />
                    </Button>
                </EmptyState>
            </EmptyStateOuterWrapper>
        );
    }

    return (
        <Stack $gap="1.5rem">
            <Text variant="title" fontWeight="--fw-bold">
                <FormattedMessage defaultMessage="Monétisation automatique" />
            </Text>
            <OuterWrapper>
                <Wrapper>
                    <LeftBlock isMobile={isMobile}>
                        <LeftBlockContent
                            isTargetspotActivated={monetizationPlatform === TARGETSPOT}
                            hasOtherMonetizationPlatform={
                                monetizationPlatform && monetizationPlatform !== TARGETSPOT
                            }
                            enableTargetspot={() => setMonetizationPlatform(TARGETSPOT)}
                            disabledTargetspot={() => setMonetizationPlatform(null)}
                            isSubscribe={isSubscribe}
                            downloads={downloads}
                        />
                    </LeftBlock>
                    <RightBlock>
                        <ImgWrapper>
                            <Img
                                src={imageMonetizationBlocked}
                                maxW={isMobile ? 256 : 384}
                                maxH={isMobile ? 256 : 384}
                            />
                        </ImgWrapper>
                    </RightBlock>
                </Wrapper>
            </OuterWrapper>
        </Stack>
    );
};

export default enhance(CampaignAutoEditPage);
