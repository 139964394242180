import { useParams } from 'react-router';
import useKeywordQuotaQuery from '@queries/pso/useKeywordQuotaQuery.hook';
import KeywordConfiguration from '../PsoConfigurationTunnel/KeywordConfiguration';
import styled from 'styled-components';

interface PsoKeywordsProps {
    currentStore: string;
    existingKeywords: string[];
}

const PsoKeywords = ({ currentStore, existingKeywords }: PsoKeywordsProps) => {
    const { showId }: { showId: string } = useParams();
    const keywordQuota = useKeywordQuotaQuery({ showId });

    return (
        <PsoKeywordsWrapper>
            <KeywordConfiguration
                isEditMode={true}
                existingKeywords={existingKeywords}
                keywordQuota={keywordQuota}
                onKeywordsSubmitted={() => {}}
                currentStore={currentStore}
            />
        </PsoKeywordsWrapper>
    );
};

const PsoKeywordsWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding);
    display: flex;
    justify-content: center;
    gap: 1rem;
`;

export default PsoKeywords;
