const callToActionKeys = {
    all: () => [{ entity: 'callToAction' }],
    allDetails: () => [{ ...callToActionKeys.all()[0], scope: 'detail' }],
    detailByShowId: (rawShowId: string) => [
        { ...callToActionKeys.allDetails()[0], showId: Number(rawShowId) },
    ],
    detailByLocation: ({ showId, type }: { showId: string; type?: string }) => [
        { ...callToActionKeys.detailByShowId(showId)[0], type },
    ],
};

export default callToActionKeys;
