import Text from '@/components/ui/atoms/Text';
import Cluster from '@/components/ui/layout/Cluster';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedNumber, useIntl } from 'react-intl';
import styled from 'styled-components';
import { formatSecondsDuration } from '@/helpers';
import { useParams } from 'react-router-dom';
import useDeleteEpisodeAudioFileMutation from '@/queries/episode/useDeleteEpisodeAudioFileMutation.hook';
import { debounce } from 'lodash';
import IconAudioFile from '@/components/ui/icons/IconAudioFile';
import { zodResolver } from '@hookform/resolvers/zod';
import FileMenu from './FileMenu';
import DeleteFileModal from './DeleteFileModal';
import useNonHostingEpisodeAudioFileSchema, {
    NonHostingEpisodeAudioFileSchema,
} from '@/components/NonHostingEpisodeForm/useNonHostingEpisodeAudioFileSchema.hook';
import { EpisodeFile } from '@/queries/episode/useEpisodeQuery.hook';
import {
    useEpisodeEditAudioFileMutation,
    useEpisodeEditAudioFileState,
} from '@/components/NonHostingEpisodeForm/contexts/NonHostingEpisodeFileUploadContext';

interface FileUploadedProps {
    file: EpisodeFile;
    audioUrl: string | null | undefined;
}

const FileUploaded = ({ file, audioUrl }: FileUploadedProps) => {
    const intl = useIntl();
    const { episodeId } = useParams<{ episodeId: string }>();
    const episodeEditAudioFileMutation = useEpisodeEditAudioFileMutation();
    const episodeDeleteAudioFileMutation = useDeleteEpisodeAudioFileMutation();
    const [_, setEpisodeEditAudioFile] = useEpisodeEditAudioFileState();
    const schema = useNonHostingEpisodeAudioFileSchema();
    const { watch, handleSubmit, formState, setValue, setError } =
        useForm<NonHostingEpisodeAudioFileSchema>({
            mode: 'onChange',
            resolver: zodResolver(schema),
        });
    const { errors } = formState;
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const fileDuration = formatSecondsDuration(file?.duration);

    useEffect(() => {
        const subscription = watch(() => {
            handleSubmit(onSubmit)();
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    const handleOpenDeleteModal = () => setIsDeleteModalOpen(true);

    const onSubmit = ({ audioFile }: NonHostingEpisodeAudioFileSchema) => {
        if (!audioFile) return;

        episodeEditAudioFileMutation.mutate(
            { episodeId, audioFile: audioFile[0] },
            {
                onSuccess: () => {
                    debounce(() => setEpisodeEditAudioFile(null), 300)();
                    window.localStorage.setItem(
                        `episode-form-${episodeId}-transcription-outdated-message`,
                        'true',
                    );
                },
                onError: () =>
                    setError('root.serverError', {
                        type: '400',
                        message: intl.formatMessage({ defaultMessage: 'Une erreur est survenue' }),
                    }),
            },
        );
        // Keep audioFile in a state to display audioFile data in
        // episode form during audioFile upload
        setEpisodeEditAudioFile(audioFile[0]);
    };

    const handleDeleteFile = () => {
        episodeDeleteAudioFileMutation.mutate(
            { episodeId },
            {
                onSuccess: () => setIsDeleteModalOpen(false),
            },
        );
    };

    const handleEditFile = (file: File[]) => {
        setValue('audioFile', file, { shouldValidate: true, shouldDirty: true });
    };

    if (!file) return null;

    return (
        <>
            <Wrapper>
                <InnerWrapper>
                    <Cluster $gap="0.5rem" $align="center" $justify="space-between" $wrap="nowrap">
                        <IconAudioFile size="2rem" />
                        <FileInfoWrapper>
                            <Text
                                variant="bodyM"
                                fontWeight="--fw-semibold"
                                numberOfLines={1}
                                data-testid="episode-form-file-uploaded-name"
                            >
                                {file.originalName}
                            </Text>
                            <Cluster $gap="0.5rem" $align="center">
                                <Text
                                    variant="bodyM"
                                    color="--neutral500"
                                    numberOfLines={1}
                                    css="flex: none;"
                                >
                                    <FormattedNumber
                                        value={file.size ?? 0}
                                        style="unit"
                                        unit="byte"
                                        unitDisplay="narrow"
                                        notation="compact"
                                    />
                                </Text>
                                <BulletIcon icon={icon({ name: 'circle', style: 'solid' })} />
                                <Text variant="bodyM" color="--neutral500" numberOfLines={1}>
                                    {fileDuration}
                                </Text>
                            </Cluster>
                        </FileInfoWrapper>
                    </Cluster>
                    <FileMenu
                        handleDelete={handleOpenDeleteModal}
                        handleEdit={handleEditFile}
                        audioUrl={audioUrl}
                    />
                </InnerWrapper>

                {errors?.audioFile && (
                    <ErrorMessage>
                        <AlertIcon icon={icon({ name: 'exclamation-circle', style: 'solid' })} />
                        <Text color="--alert500">
                            {errors?.audioFile?.message ?? errors?.root?.serverError}
                        </Text>
                    </ErrorMessage>
                )}
            </Wrapper>
            <DeleteFileModal
                isOpen={isDeleteModalOpen}
                onOpenChange={setIsDeleteModalOpen}
                handleDelete={handleDeleteFile}
                isLoading={episodeDeleteAudioFileMutation.isLoading}
            />
        </>
    );
};
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    width: 100%;
`;
const InnerWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 0.5rem;
    width: 100%;
`;
const BulletIcon = styled(FontAwesomeIcon)`
    width: 0.25rem;
    height: 0.25rem;
    color: var(--neutral200);
    flex: none;
`;
const FileInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 250px;

    ${({ theme }) => theme.mediaQueries.mobile} {
        max-width: 200px;
    }
`;
const MenuItemIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const AlertIcon = styled(MenuItemIcon)`
    color: var(--alert500);
`;
const ErrorMessage = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    width: 100%;
`;

export default FileUploaded;
