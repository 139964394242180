import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Text from '../Text';
import { FormattedMessage } from 'react-intl';
import { useTextField } from 'react-aria';
import Tag from './Tag';

const InputTag = ({ 'data-testid': dataTestId, ...props }) => {
    let ref = useRef();
    let { labelProps, inputProps, descriptionProps, errorMessageProps } = useTextField(props, ref);
    const [newTag, setNewTag] = useState('');
    const isValid = props.inputState === 'valid';
    const hasErrors = props.inputState === 'errors';
    const placeholder = props.value.length < 1 ? props.placeholder : undefined;
    const hasReachedMaxTags = props.value.length >= props.maxTags;

    const handleChange = (event) => {
        if (hasReachedMaxTags) return;
        setNewTag(event.target.value);
    };

    const handleKeydown = (event) => {
        if (!['Enter'].includes(event.key)) return;
        if (hasReachedMaxTags) return;
        event.preventDefault();
        const tag = event.target.value.trim().toLowerCase();
        if (tag.length === 0) return;
        const existingTagIndex = props.value.findIndex((t) => t === tag);

        if (existingTagIndex > -1) {
            props.onTagChange([...props.value.filter((t) => t !== tag), tag]);
            setNewTag('');
            return;
        }

        props.onTagChange([...props.value, tag]);
        setNewTag('');
    };

    const handleDeleteTag = (tag) => {
        if (props.isDisabled || props.isReadonly) return;
        props.onTagChange(props.value.filter((t) => t !== tag));
    };

    return (
        <InputWrapper className={props.className}>
            <OuterLabelWrapper>
                <LabelWrapper>
                    <LabelTooltipWrapper>
                        <Label isRequired={props.isRequired} {...labelProps}>
                            {props.label}
                            {props.isRequired && (
                                <Text as="span" fontWeight="--fw-semibold" color="--alert500">
                                    *
                                </Text>
                            )}
                            {props.isOptional && (
                                <OptionalLabel as="span">
                                    <FormattedMessage defaultMessage="(facultatif)" />
                                </OptionalLabel>
                            )}
                        </Label>
                        {props.tooltip}
                    </LabelTooltipWrapper>
                    {props.description && (
                        <Description {...descriptionProps}>{props.description}</Description>
                    )}
                </LabelWrapper>
                {props.maxTags && (
                    <TagCount hasOverflowed={props.value?.length > props.maxTags}>
                        {props.value?.length || 0}/{props.maxTags}
                    </TagCount>
                )}
            </OuterLabelWrapper>
            <InputInnerWrapper
                isDisabled={props.isDisabled}
                isReadonly={props.isReadonly}
                isValid={isValid}
                hasErrors={hasErrors}
            >
                <TagList data-testid={dataTestId}>
                    {props.value.map((tag, index) => (
                        <Tag
                            key={index}
                            label={tag}
                            onDelete={(e) => {
                                e.preventDefault();
                                handleDeleteTag(tag);
                            }}
                            isDisabled={props.isDisabled}
                            isAnimated={props.value.length - 1 === index}
                        />
                    ))}
                    <Input
                        {...inputProps}
                        ref={ref}
                        type="text"
                        placeholder={placeholder}
                        maxLength={props.tagMaxLength}
                        value={newTag}
                        onChange={handleChange}
                        onKeyDown={handleKeydown}
                        onFocus={(event) => props.onFocus(event)}
                        onBlur={(event) => props.onBlur(event)}
                    />
                </TagList>
            </InputInnerWrapper>
            {props.errorMessage && (
                <ErrorMessage {...errorMessageProps}>{props.errorMessage}</ErrorMessage>
            )}
        </InputWrapper>
    );
};

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    width: 100%;
`;
const InputInnerWrapper = styled.div`
    transition-duration: 0.2s;
    width: 100%;
    border-radius: var(--r-s);
    border: 1px solid var(--neutral200);
    padding: 0.5rem;

    &:hover {
        transition-duration: 0.2s;
        border-color: var(--neutral400);
    }

    &:focus-within {
        transition-duration: 0.2s;
        border-color: var(--primary);
        box-shadow: var(--s-primary-light);
    }

    & > * > input:disabled {
        cursor: not-allowed;
    }

    ${(p) =>
        p.isReadonly &&
        css`
            background-color: var(--neutral50);
            border-color: var(--neutral200);
        `}
    ${(p) =>
        p.isDisabled &&
        css`
            cursor: not-allowed;
            color: var(--neutral500);
            opacity: 0.5;
        `}
    ${(p) =>
        p.isValid &&
        css`
            padding-right: 2.5rem;
            border-color: var(--success);
        `}

    ${(p) =>
        p.hasErrors &&
        css`
            padding-right: 2.5rem;
            border-color: var(--alert);
        `}
`;
const Input = styled.input`
    flex: 1;
    border: none;
    background: none;
    padding-block: 0.25rem;
    &::placeholder {
        color: var(--neutral500);
    }
`;
const OuterLabelWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;
const TagList = styled.ul`
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;
    max-height: 120px;
    overflow-y: auto;
`;
const TagCount = styled.span`
    font-size: 12px;
    color: ${(p) => (p.hasOverflowed ? 'var(--alert)' : 'var(--neutral500)')};
    font-weight: var(--fw-semibold);
`;

const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const LabelTooltipWrapper = styled.div`
    display: flex;
    column-gap: 0.25rem;
    align-items: center;
    width: 100%;
`;

const Label = styled.label`
    font-size: var(--fs-body);
    font-weight: var(--fw-semibold);
    ${(p) =>
        p.isRequired &&
        css`
            display: flex;
            justify-content: start;
            align-items: start;
            column-gap: 0.25rem;
        `}
`;
const OptionalLabel = styled(Text)`
    padding-left: 0.25rem;
`;
const Description = styled.div`
    font-size: 12px;
    color: var(--neutral500);
`;
const ErrorMessage = styled(Text)`
    color: var(--alert);
    font-size: 0.75rem;
    font-weight: var(--fw-semibold);
`;

InputTag.propTypes = {
    label: PropTypes.node.isRequired,
    description: PropTypes.string,
    tooltip: PropTypes.node,
    isRequired: PropTypes.bool,
    isOptional: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isReadonly: PropTypes.bool,
    maxTags: PropTypes.number,
    inputState: PropTypes.oneOf(['pristine', 'touched', 'valid', 'errors']),
    errorMessage: PropTypes.string,
    value: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
    className: PropTypes.string,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    autoFocus: PropTypes.bool,
    placeholder: PropTypes.string,
    tagMaxLength: PropTypes.number,
    onTagChange: PropTypes.func,
    'data-testid': PropTypes.string,
};

InputTag.defaultProps = {
    isRequired: false,
    isOptional: false,
    isDisabled: false,
    isReadonly: false,
    value: [],
    autoFocus: false,
    tagMaxLength: 100,
    onTagChange: () => {},
    onChange: () => {},
    onFocus: () => {},
    onBlur: () => {},
};

export default InputTag;
