import DeprecatedButton from '../../../../ui/atoms/DeprecatedButton';
import DeprecatedIcon from '../../../../ui/atoms/DeprecatedIcon';
import PauseIcon from '@public/icons/pause.svg';
import PlayIcon from '@public/icons/play.svg';

const PlayPauseButton = ({ isPlaying, onClick, ...other }) => (
    <DeprecatedButton onClick={onClick} size="3rem" background="--primary" icon {...other}>
        <DeprecatedIcon as={isPlaying ? PauseIcon : PlayIcon} size="1.25rem" />
    </DeprecatedButton>
);

export default PlayPauseButton;
