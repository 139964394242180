import useShowQuery from '@/queries/show/useShowQuery.hook';
import { USER_OPTIONS } from '@/shared/config/userOptions';
import { useParams } from 'react-router';
import PlaylistForm from '../PlaylistForm';
import { PlaylistFormSchema } from '../PlaylistForm/usePlaylistFormSchema.hook';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Playlist } from '@/queries/playlist/usePlaylistsQuery.hook';
import savePlaylistMutation from './savePlaylistMutation';
import { useBodyToastQueue } from '@/shared/hooks/useBodyToastQueue.hook';

interface EditPlaylistProps {
    onClose: () => void;
    playlist: Playlist;
}

const EditPlaylist = ({ onClose, playlist }: EditPlaylistProps) => {
    const { showId } = useParams<{ showId: string }>();
    const show = useShowQuery(showId);
    const savePlaylist = savePlaylistMutation(showId);
    const [slugError, setSlugError] = useState<string | undefined>();
    const intl = useIntl();
    const toast = useBodyToastQueue();

    if (show.isSuccess && playlist) {
        const hasAccessToPlaylistConfidentiality =
            show.data?.userOptions?.includes(USER_OPTIONS.PLAYLISTS_CONFIDENTIALITY) ?? false;
        const playlistLink = `${show.data.siteUrl}/playlist/`;

        const onSubmit = ({ password, ...data }: PlaylistFormSchema) => {
            const formattedData = data.privacy === 'private' ? { ...data, password } : data;
            savePlaylist.mutateAsync(
                {
                    playlistId: String(playlist.id),
                    data: formattedData,
                },
                {
                    onSuccess: () => {
                        onClose();
                    },
                    onError: (error) => {
                        const err = error as AxiosError;
                        const slugErr = err?.response?.data?.errors?.slug;
                        if (err?.response?.status === 422 && slugErr) {
                            setSlugError(
                                intl.formatMessage({
                                    defaultMessage: 'Ce permalien est déjà utilisé',
                                }),
                            );
                            return;
                        }
                        toast.alert();
                    },
                },
            );
        };

        return (
            <PlaylistForm
                isLoading={savePlaylist.isLoading}
                hasAccessToPlaylistConfidentiality={hasAccessToPlaylistConfidentiality}
                onClose={onClose}
                onSubmit={onSubmit}
                slugError={slugError}
                playlist={playlist}
                playlistLink={playlistLink}
                mode="edit"
            />
        );
    }

    return <div>Loading...</div>; // TODO: Add loading state
};

export default EditPlaylist;
