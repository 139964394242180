import { FormattedMessage } from 'react-intl';
import Modal from '@ui/molecules/Modal';
import SubscribeSurveyForm from './SubscribeSurveyForm';

const SubscribeSurveyModal = ({ isOpened, onClose }) => {
    return (
        <Modal
            isOpen={isOpened}
            title={<FormattedMessage defaultMessage="Dites-nous en un peu plus sur vous 🤓" />}
            size="medium"
            isDismissable={false}
            disableAutoFocus={true}
            isKeyboardDismissDisabled
            disableScroll={true}
        >
            <SubscribeSurveyForm onAfterSubmit={() => onClose()} />
        </Modal>
    );
};

export default SubscribeSurveyModal;
