import { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import StepperContext from '../../organisms/Stepper/StepperContext';
import StepContext from './StepContext';

const Container = styled.div`
    padding-left: 8px;
    padding-right: 8px;
    flex: 1;
    position: relative;
`;

const Step = (props) => {
    const { active: activeProp, children, completed: completedProp, index, last, ...other } = props;

    const { activeStep, connector } = useContext(StepperContext);

    let [active = false, completed = false] = [activeProp, completedProp];

    if (activeStep === index) {
        active = activeProp !== undefined ? activeProp : true;
    } else if (activeStep > index) {
        completed = completedProp !== undefined ? completedProp : true;
    }

    // Disable ESLint to prevent an ugly formatted hook 😉
    /* eslint-disable */
    const contextValue = useMemo(
        () => ({ index, last, icon: index + 1, active, completed }),
        [index, last, active, completed],
    );
    /* eslint-enable */

    return (
        <StepContext.Provider value={contextValue}>
            <Container {...other}>
                {connector && index !== 0 ? connector : null}
                {children}
            </Container>
        </StepContext.Provider>
    );
};

Step.propTypes = {
    /**
     * Sets the step as active. Is passed to child components.
     */
    active: PropTypes.bool,
    /**
     * Should be Step sub-component such as StepLabel
     */
    children: PropTypes.node,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The position of the step.
     * The prop defaults to the value inherited from the parent Stepper component.
     */
    index: PropTypes.number,
    /**
     * If true, the Step is displayed as rendered last.
     * The prop defaults to the value inherited from the parent Stepper component.
     */
    last: PropTypes.bool,
};

export default Step;
