import { useState } from 'react';

export function useSwitch(initialState) {
    const [isTrue, setState] = useState(initialState);
    function setTrue() {
        setState(true);
    }
    function setFalse() {
        setState(false);
    }
    return [isTrue, setTrue, setFalse, setState];
}
