import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';
import { TooltipTrigger } from 'react-aria-components';
import { useFormContext } from 'react-hook-form';
import SuggestedKeyword from './SuggestedKeyword';
import Button from '@ui/atoms/Button';
import usePsoQuery from '@queries/pso/usePsoQuery.hook';
import useAIKeywords from '@queries/psoChecker/useAIKeywordsQuery.hook';
import styled from 'styled-components';
import { usePsoCheckerContext } from '@/components/NonHostingEpisodeForm/contexts/NonHostingPsoCheckerContext';

const SuggestedKeywords = () => {
    const { showId, episodeId }: { showId: string; episodeId: string } = useParams();
    const psoConfiguration = usePsoQuery({ showId, enabled: true });
    const storeCountry = psoConfiguration.data?.stores?.data?.[0]?.name;
    const { watch } = useFormContext();

    const { targetKeyword } = usePsoCheckerContext();
    const aiKeywords = useAIKeywords({ episodeId, enabled: !!targetKeyword });

    const tags = watch('content.tags');
    const description = watch('content.htmlDescription');
    const title = watch('content.name');

    const checkInUseStatus = (keyword: { id: string; label: string }): boolean => {
        const regex = new RegExp(`\\b${keyword.label}\\b`, 'i');

        const foundInTags = tags.includes(keyword.label);
        const foundInDescription = regex.test(description);
        const foundInTitle = regex.test(title);
        return foundInTags || foundInDescription || foundInTitle;
    };

    const checkIsFollowed = (keyword: { id: string; label: string }): boolean => {
        return psoConfiguration.data.keywords.includes(keyword.label);
    };

    return (
        <Wrapper>
            <TitleAndDescriptionWrapper>
                <TitleWrapper>
                    <TitleIcon icon={icon({ name: 'hashtag', style: 'solid' })} />
                    <Title>
                        <FormattedMessage defaultMessage="Mots-clés suggérés" />
                    </Title>
                    <TooltipTrigger delay={0} closeDelay={0}>
                        <TooltipTriggerWrapper>
                            <HelpIcon icon={icon({ name: 'info-circle', style: 'solid' })} />
                        </TooltipTriggerWrapper>
                        <Tooltip placement="top">
                            <FormattedMessage
                                defaultMessage="Mots-clés générés par l'IA en fonction de votre mot-clé principal, classés par pertinence. Utilisez-les dans votre titre, votre description ou vos tags pour améliorer la visibilité. Les indicateurs des mots-clés sont relatifs au pays : {country}."
                                values={{
                                    country: storeCountry,
                                }}
                            />
                        </Tooltip>
                    </TooltipTrigger>
                </TitleWrapper>
                <Description>
                    <FormattedMessage defaultMessage="Liste de mots-clés générée par l'IA pour vous aider à enrichir les métadonnées de votre épisode avec des mots-clés pertinents." />
                </Description>
            </TitleAndDescriptionWrapper>

            {!targetKeyword ? (
                <MissingTargetKeywordWrapper>
                    <MissingTargetKeywordIcon
                        icon={icon({ name: 'bullseye-arrow', style: 'solid' })}
                    />
                    <span>
                        <FormattedMessage
                            defaultMessage="Vous n’avez pas encore défini de mot-clé principal, <link>ajoutez en un dès maintenant</link>."
                            values={{
                                link: (chunks: string) => (
                                    // @ts-ignore
                                    <AddTargetKeywordButton
                                        variant="ghost"
                                        color="--primary"
                                        onPress={() => {
                                            document
                                                .querySelector('#target-keyword')
                                                ?.scrollIntoView({
                                                    behavior: 'smooth',
                                                });
                                        }}
                                    >
                                        {chunks}
                                    </AddTargetKeywordButton>
                                ),
                            }}
                        />
                    </span>
                </MissingTargetKeywordWrapper>
            ) : aiKeywords.isLoading || aiKeywords.isFetching ? (
                <LoadingWrapper>
                    <FontAwesomeIcon icon={icon({ name: 'spinner-third', style: 'solid' })} spin />
                </LoadingWrapper>
            ) : aiKeywords.isError ? (
                <ErrorWrapper>
                    <FormattedMessage defaultMessage="Erreur lors du chargement des mots-clés suggérés, veuillez réessayer ultérieurement." />
                </ErrorWrapper>
            ) : (
                <SuggestedKeywordList>
                    {(aiKeywords.data ?? []).map((keyword) => (
                        <SuggestedKeyword
                            key={keyword.id}
                            keyword={keyword}
                            isActive={checkInUseStatus(keyword)}
                            isFollowed={checkIsFollowed(keyword)}
                        />
                    ))}
                </SuggestedKeywordList>
            )}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    border-radius: var(--r-m);
    background-color: var(--white);
    gap: 1.5rem;
`;
const TitleAndDescriptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
const Description = styled.p`
    color: var(--neutral500);
`;
const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;
const TitleIcon = styled(FontAwesomeIcon)`
    color: var(--primary);
`;
const Title = styled.div`
    font-size: var(--fs-body-l);
    font-weight: var(--fw-semibold);
`;
const HelpIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
`;
const MissingTargetKeywordWrapper = styled.div`
    background-color: var(--neutral50);
    border-radius: var(--r-m);
    color: var(--neutral500);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    padding: 1.5rem;

    & span {
        text-align: center;
    }
`;
const MissingTargetKeywordIcon = styled(FontAwesomeIcon)`
    font-size: 1.5rem;
`;
const AddTargetKeywordButton = styled(Button)`
    padding: 0;
`;
const SuggestedKeywordList = styled.ul`
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 1rem;
    list-style: none;
    padding: 0;
    margin: 0;
`;
const LoadingWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--primary);
    padding-block: 1rem;
`;
const ErrorWrapper = styled.div`
    color: var(--alert);
    text-align: center;
    padding-block: 1rem;
`;

export default SuggestedKeywords;
