import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { fetchEpisodesCompare } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import statsKeys from '@queries/stats/statsKeys';
import { z } from 'zod';

type Props = {
    showId: string;
    daysAfterPublication: 7 | 15 | 30 | 90;
    selectedEpisodeIds: number[];
};

type Key = QueryFunctionContext<ReturnType<typeof statsKeys.downloadsComparison>>;

const EpisodeDownloadsSchema = z.object({
    episodeId: z.string(),
    episodeName: z.string(),
    downloadsPerDay: z.array(
        z.object({
            day: z.number(),
            downloads: z.number(),
        }),
    ),
});

const EpisodesDownloadsSchema = z.array(EpisodeDownloadsSchema);

export type EpisodeDownloads = z.infer<typeof EpisodeDownloadsSchema>;

const transform = (data: any): EpisodeDownloads[] => {
    const transformedData = camelcaseKeys(data, { deep: true });
    return Object.entries(transformedData).map(([id, { name, data }]) => ({
        episodeId: id,
        episodeName: name,
        downloadsPerDay: data,
    }));
};

const queryFn = async ({ queryKey: [{ showId, daysAfterPublication, episodeIds }] }: Key) => {
    const { data } = await fetchEpisodesCompare(showId, {
        days: daysAfterPublication,
        podcasts: episodeIds,
    });

    return EpisodesDownloadsSchema.parse(transform(data));
};

const useDownloadsComparisonQuery = ({
    showId,
    daysAfterPublication,
    selectedEpisodeIds,
}: Props) => {
    return useQuery({
        queryKey: statsKeys.downloadsComparison(showId, daysAfterPublication, selectedEpisodeIds),
        queryFn,
        enabled: !!showId,
    });
};

export default useDownloadsComparisonQuery;
