import { useController } from 'react-hook-form';
import { CheckboxGroup } from 'react-aria-components';
import styled from 'styled-components';

export interface CheckboxGroupControllerProps {
    control: any;
    name: string;
    children: React.ReactNode;
    className?: string;
}

export const CheckboxGroupController = (props: CheckboxGroupControllerProps) => {
    const { control, name, children, className } = props;
    const { field, fieldState } = useController({
        name,
        control,
    });

    return (
        <Wrapper>
            <StyledCheckboxGroup
                name={field.name}
                value={field.value}
                onChange={(value) => {
                    field.onChange(value);
                }}
                className={className}
            >
                {children}
            </StyledCheckboxGroup>
            {fieldState.error && <ErrorWrapper>{fieldState.error?.message}</ErrorWrapper>}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;
const StyledCheckboxGroup = styled(CheckboxGroup)`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;
const ErrorWrapper = styled.div`
    color: var(--alert);
    font-size: var(--fs-body-s);
    font-weight: var(--fw-semibold);
`;
