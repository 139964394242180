import { useQuery } from '@tanstack/react-query';
import { fetchTimezones } from '@/api';
import timezoneKeys from '@/queries/timezone/timezoneKeys';
import { z } from 'zod';

const TimezonesSchema = z.record(z.string());

export type Timezones = z.infer<typeof TimezonesSchema>;

const queryFn = async () => {
    const { axios, ...data } = await fetchTimezones();

    return TimezonesSchema.parse(data);
};

const useTimezonesQuery = () => {
    return useQuery({
        queryKey: timezoneKeys.list(),
        queryFn,
        staleTime: Infinity,
    });
};

export default useTimezonesQuery;
