import { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Bot from '@public/images/bot.webp';
import DeprecatedSelect from '@ui/molecules/DeprecatedSelect';
import Modal from '@ui/molecules/Modal';
import Button from '@ui/atoms/Button';
import InputText from '@ui/atoms/InputText';
import Text from '@ui/atoms/Text';
import { useResponsive } from '@/shared/hooks/useResponsive';
import useUserStore from '@/shared/hooks/useUserStore';
import styled from 'styled-components';
import useAddEpisodeListeningLinkMutation from '@queries/episodeListeningLink/useAddEpisodeListeningLinkMutation.hook';

const NewLinkModal = ({ modalState, episodeId, eligiblePlatforms, onClose, onLinkAdded }) => {
    const userState = useUserStore();
    const [platformKey, setPlatformKey] = useState(null);
    const [url, setUrl] = useState('');
    const [urlInputState, setUrlInputState] = useState('pristine');
    const addLink = useAddEpisodeListeningLinkMutation();
    const { isMobileOrTablet } = useResponsive();

    const platformExampleUrlMatch = {
        amazon: {
            fr: 'https://music.amazon.fr/podcasts/idXXX/mon-podcast',
            en: 'https://music.amazon.en/podcasts/idXXX/my-podcast',
        },
        castbox: {
            fr: 'https://castbox.fm/episode/mon-episode-idXXX',
            en: 'https://castbox.fm/episode/my-episode-idXXX',
        },
        castro: {
            fr: 'https://castro.fm/itunes/idXXX',
            en: 'https://castro.fm/itunes/idXXX',
        },
        deezer: {
            fr: 'https://deezer.page.link/idXXX',
            en: 'https://deezer.page.link/idXXX',
        },
        gaana: {
            fr: 'https://gaana.com/song/mon-episode ',
            en: 'https://gaana.com/song/my-episode ',
        },
        itunes: {
            fr: 'https://podcasts.apple.com/fr/podcast/mon-episode/idXXX',
            en: 'https://podcasts.apple.com/en/podcast/my-episode/idXXX',
        },
        i_heart_radio: {
            fr: 'https://www.iheart.com/podcast/mon-podcast-idXXX/episode/mon-episode-idXXX',
            en: 'https://www.iheart.com/podcast/my-podcast-idXXX/episode/my-episode-idXXX',
        },
        jio_saavn: {
            fr: 'https://www.jiosaavn.com/shows/mon-podcast/idXXX',
            en: 'https://www.jiosaavn.com/shows/my-podcast/idXXX',
        },
        listen_notes: {
            fr: 'https://www.listennotes.com/podcasts/mon-podcast/mon-episode-idXXX',
            en: 'https://www.listennotes.com/podcasts/my-podcast/my-episode-idXXX',
        },
        overcast: {
            fr: 'https://overcast.fm/idXXX',
            en: 'https://overcast.fm/idXXX',
        },
        pandora: {
            fr: 'https://www.pandora.com/podcast/mon-podcast/idXXX',
            en: 'https://www.pandora.com/podcast/my-podcast/idXXX',
        },
        player_fm: {
            fr: 'https://fr.player.fm/series/mon-podcast/mon-episode',
            en: 'https://player.fm/series/my-podcast/my-episode',
        },
        pocket_casts: {
            fr: 'https://pca.st/episode/idXXX',
            en: 'https://pca.st/episode/idXXX',
        },
        podcast_addict: {
            fr: 'https://podcastaddict.com/episode/idXXX',
            en: 'https://podcastaddict.com/episode/idXXX',
        },
        podcast_index: {
            fr: 'https://podcastindex.org/podcast/idXXX',
            en: 'https://podcastindex.org/podcast/idXXX',
        },
        podchaser: {
            fr: 'https://www.podchaser.com/podcasts/mon-podcast/episodes/mon-episode-idXXX',
            en: 'https://www.podchaser.com/podcasts/my-podcast/episodes/my-episode-idXXX',
        },
        soundcloud: {
            fr: 'https://soundcloud.com/mon-podcast/mon-episode-idXXX',
            en: 'https://soundcloud.com/my-podcast/my-episode-idXXX',
        },
        spotify: {
            fr: 'https://open.spotify.com/show/idXXX',
            en: 'https://open.spotify.com/show/idXXX',
        },
        stitcher: {
            fr: 'https://stitcher.com/show/mon-podcast/episode/mon-episode-idXXX',
            en: 'https://stitcher.com/show/my-podcast/episode/my-episode-idXXX',
        },
        tunein: {
            fr: 'https://tunein.com/podcasts/mon-podcasts/topicId=XXX',
            en: 'https://tunein.com/podcasts/my-podcasts/topicId=XXX',
        },
        youtube: {
            fr: 'https://www.youtube.com/watchidXXX',
            en: 'https://www.youtube.com/watchidXXX',
        },
    };

    const reset = () => {
        setPlatformKey(null);
        setUrl('');
        modalState.close();
    };

    const checkUrl = (value) => {
        const expression =
            /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
        const urlRegex = new RegExp(expression);
        setUrlInputState(urlRegex.test(value) ? 'valid' : 'errors');
    };

    const triggerAddLink = () => {
        addLink.mutate(
            {
                episodeId,
                key: platformKey,
                url,
            },
            {
                onSettled: () => {
                    onLinkAdded();
                    reset();
                },
            },
        );
    };
    return (
        <Modal
            size={isMobileOrTablet ? 'xs' : 'medium'}
            title={<FormattedMessage defaultMessage="Ajouter un lien" />}
            description={
                <FormattedMessage defaultMessage="Pour rappel, le Deeplink est composé du lien spécifique de votre épisode sur chaque plateforme. Si Ausha, ne l'a pas récupéré automatiquement, vous pouvez l’ajouter manuellement." />
            }
            action={
                <>
                    <Button variant="secondary" onClick={() => reset()}>
                        <FormattedMessage defaultMessage="Annuler" />
                    </Button>
                    <SubmitButton
                        variant="primary"
                        isDisabled={!platformKey || !url || urlInputState === 'errors'}
                        onClick={() => triggerAddLink()}
                    >
                        <FormattedMessage defaultMessage="Ajouter" />
                    </SubmitButton>
                </>
            }
            isOpen={modalState.isOpen}
            onClose={() => onClose()}
            isDismissable={false}
            disableScroll
        >
            <ModalInnerWrapper>
                <DeprecatedSelect
                    label={<FormattedMessage defaultMessage="Sélectionnez une plateforme" />}
                    source={eligiblePlatforms}
                    value={platformKey}
                    onChange={(value) => {
                        setPlatformKey(value);
                    }}
                />

                <ListeningLinkWrapper>
                    <InputText
                        label={<FormattedMessage defaultMessage="Lien d'écoute" />}
                        value={url}
                        onChange={(value) => {
                            checkUrl(value);
                            setUrl(value);
                        }}
                        inputState={urlInputState}
                        errorMessage={
                            urlInputState === 'errors' && (
                                <FormattedMessage defaultMessage="Votre saisie ne correspond pas à une URL valide" />
                            )
                        }
                        onKeyUp={(event) => event.key === 'Enter' && triggerAddLink()}
                    />

                    {!isMobileOrTablet && platformKey && (
                        <InfoBox>
                            <IllustrationWrapper>
                                <Illustration
                                    src={Bot}
                                    alt={<FormattedMessage defaultMessage="Petit robot ausha" />}
                                />
                            </IllustrationWrapper>
                            <TextWrapper>
                                <Text fontWeight="--fw-semibold">
                                    <FormattedMessage defaultMessage="Pssst, ton lien d’écoute devrait ressembler à ça : " />
                                </Text>
                                <Text color="--neutral500">
                                    {
                                        platformExampleUrlMatch[platformKey][
                                            userState.user.languageCode || 'en'
                                        ]
                                    }
                                </Text>
                            </TextWrapper>
                        </InfoBox>
                    )}
                </ListeningLinkWrapper>
            </ModalInnerWrapper>
        </Modal>
    );
};

const ModalInnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;

    ${(p) => p.theme.mediaQueries.mobile} {
        row-gap: 1rem;
    }
`;

const ListeningLinkWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.75rem;
`;

const SubmitButton = styled(Button)`
    background: var(--primary);
`;

const InfoBox = styled.div`
    display: flex;
    align-items: center;
    background: var(--primary50);
    border-radius: var(--r-m);
    overflow: hidden;
`;

const IllustrationWrapper = styled.div`
    position: relative;
    height: 5rem;
    width: 4rem;
`;

const Illustration = styled.img`
    position: absolute;
    height: 100%;
    width: auto;
    bottom: -0.5rem;
    left: 0.5rem;
`;

const TextWrapper = styled.div`
    padding-block: 0.75rem;
    padding-inline: 0.5rem;
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;
`;

NewLinkModal.propTypes = {
    modalState: PropTypes.shape.isRequired,
    episodeId: PropTypes.string.isRequired,
    eligiblePlatforms: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
    onLinkAdded: PropTypes.func.isRequired,
};

export default NewLinkModal;
