import { useState } from 'react';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { useSwitch } from '@hooks/useSwitch';
import AudioWaveform from './AudioWaveform';
import Cluster from '../../../../ui/layout/Cluster';
import Stack from '../../../../ui/layout/Stack';
import IconPlay from '@ui/icons/IconPlay';
import IconButton from '@ui/atoms/IconButton';
import IconPause from '@ui/icons/IconPause';
import IconSkipPrevious from '@ui/icons/IconSkipPrevious';
import Text from '@ui/atoms/Text';
import DurationInput from '@ui/molecules/DurationInput';
import { FormattedMessage } from 'react-intl';
import IconTimer from '@ui/icons/IconTimer';
import IconClock from '@ui/icons/IconClock';
import UiKitTooltip from '@ui/atoms/UiKitTooltip';
import IconHelp from '@ui/icons/IconHelp';
import { resolveColor } from '@/utils/cssVariables';
import { CLIP_MAX_LENGTH, CLIP_MIN_LENGTH, EPISODE_JOB_NAME } from '@/utils/constants';
import useEpisodeJobsProgress from '@hooks/useEpisodeJobsProgress.hook';
import Spinner from '@ui/atoms/Spinner';
import ClipProgressTracker from '@app/organisms/EpisodeEditClip/ClipProgressTracker';
import Flex from '@ui/layout/Flex';
import { formatTime } from '@/helpers';

const AudioSampleSelection = ({ episode }) => {
    const isEpisodeJobsFetchingEnabled = !episode.waveformUrl;
    const { data: episodeJobsProgress, isLoading } = useEpisodeJobsProgress({
        episodeId: episode.id,
        enabled: isEpisodeJobsFetchingEnabled,
    });

    const [isPlaying, play, pause] = useSwitch();
    const [goBackToStart, setGoBackToStart] = useState(false);
    const [cursorPosition, setCursorPosition] = useState(false);
    const [cursorPositionHasChanged, setCursorPositionHasChanged] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [audioFileDuration, setAudioFileDuration] = useState();
    const { values, setValues } = useFormikContext();
    const [start, setStart] = useState(values.start);
    const [end, setEnd] = useState(values.end);
    const [clipDuration, setClipDuration] = useState(end - start);

    const handlePlayPause = () => {
        isPlaying ? pause() : play();
    };

    const handleGoBackToStart = () => {
        setGoBackToStart(!goBackToStart);
        if (!isPlaying) play();
    };

    const handlePlayOnTimeCode = (value) => {
        setCursorPosition(value);
        setCursorPositionHasChanged(!cursorPositionHasChanged);
        if (!isPlaying) play();
    };

    const handleUpdateStart = (value) => {
        if (value > audioFileDuration) return;

        setStart(value);

        if (value <= end - CLIP_MIN_LENGTH && value >= end - CLIP_MAX_LENGTH) {
            setClipDuration(checkClipDuration(end - value));
            setValues((currentValues) => ({
                ...currentValues,
                start: value,
            }));
        } else {
            setEnd(Math.min(value + clipDuration, audioFileDuration));
            setValues((currentValues) => ({
                ...currentValues,
                start: value,
                end: Math.min(value + clipDuration, audioFileDuration),
            }));
            if (value + clipDuration > audioFileDuration)
                setClipDuration(audioFileDuration - value);
        }
    };

    const handleUpdateEnd = (value) => {
        if (value > audioFileDuration) return;

        setEnd(value);

        if (value >= start + CLIP_MIN_LENGTH && value <= start + CLIP_MAX_LENGTH) {
            setClipDuration(checkClipDuration(value - start));
            setValues((currentValues) => ({
                ...currentValues,
                end: value,
            }));
        } else {
            setStart(Math.max(value - clipDuration, 0));
            setValues((currentValues) => ({
                ...currentValues,
                start: Math.max(value - clipDuration, 0),
                end: value,
            }));
        }
    };

    const handleRegionUpdate = (newStart, newEnd) => {
        setStart(newStart);
        setEnd(newEnd);
        setClipDuration(checkClipDuration(newEnd - newStart));

        setValues((currentValues) => ({
            ...currentValues,
            start: newStart,
            end: newEnd,
        }));
    };

    const handleUpdateClipDuration = (value) => {
        if (start + value > audioFileDuration) {
            setClipDuration(checkClipDuration(audioFileDuration - start));
            setEnd(audioFileDuration);
            setValues((currentValues) => ({
                ...currentValues,
                end: audioFileDuration,
            }));
        } else {
            const newClipDuration = checkClipDuration(value);
            setClipDuration(newClipDuration);
            setEnd(start + newClipDuration);
            setValues((currentValues) => ({
                ...currentValues,
                end: start + newClipDuration,
            }));
        }
    };

    const checkClipDuration = (value) => {
        if (value < CLIP_MIN_LENGTH) {
            return CLIP_MIN_LENGTH;
        } else if (value > CLIP_MAX_LENGTH) {
            return CLIP_MAX_LENGTH;
        } else {
            return value;
        }
    };

    if (isEpisodeJobsFetchingEnabled && isLoading) {
        return (
            <Flex $height="100%" $align="center" $justify="center">
                <Spinner />
            </Flex>
        );
    }

    if (
        isEpisodeJobsFetchingEnabled &&
        episodeJobsProgress[EPISODE_JOB_NAME.GENERATE_WAVEFORM] &&
        !episodeJobsProgress[EPISODE_JOB_NAME.GENERATE_WAVEFORM].isFinished
    ) {
        return <ClipProgressTracker progress={episodeJobsProgress} />;
    }

    return (
        <Stack $gap="1rem">
            <AudioWaveform
                audioUrl={episode.audioUrlInternal}
                waveformDataUrl={
                    episode.waveformUrl ??
                    episodeJobsProgress[EPISODE_JOB_NAME.GENERATE_WAVEFORM]?.result?.waveform_url
                }
                isPlaying={isPlaying}
                onGoBackToStart={goBackToStart}
                duration={audioFileDuration}
                onDurationChange={setAudioFileDuration}
                onCurrentTimeChange={setCurrentTime}
                cursorPosition={cursorPosition}
                cursorPositionHasChanged={cursorPositionHasChanged}
                regionStart={start}
                regionEnd={end}
                onRegionChange={handleRegionUpdate}
            />
            <Cluster $justify="space-between" $align="center">
                <Cluster $gap="1rem" $align="center">
                    <PlayPauseButton isPlaying={isPlaying} onPlayPause={handlePlayPause} />
                    <GoBackToStartButton onGoBackToStart={handleGoBackToStart} />
                    <Cluster $gap="0.5rem">
                        <TimeCodeText>{formatTime(currentTime, 'milliseconds')}</TimeCodeText>
                        <TimeCodeText color={resolveColor('--neutral500')}>/</TimeCodeText>
                        <TimeCodeText color={resolveColor('--neutral500')}>
                            {formatTime(audioFileDuration, 'milliseconds')}
                        </TimeCodeText>
                    </Cluster>
                </Cluster>
                <Cluster $gap="1rem" $align="center">
                    <TimeCodeInput
                        label={<FormattedMessage defaultMessage="Début" />}
                        value={start}
                        onChange={handleUpdateStart}
                        onPlay={() => handlePlayOnTimeCode(start)}
                        onSet={() => handleUpdateStart(currentTime)}
                    />
                    <TimeCodeInput
                        label={<FormattedMessage defaultMessage="Fin" />}
                        value={Math.min(end, audioFileDuration)}
                        onChange={handleUpdateEnd}
                        onPlay={() => handlePlayOnTimeCode(end)}
                        onSet={() => handleUpdateEnd(currentTime)}
                    />
                    <TotalDurationInput
                        label={<FormattedMessage defaultMessage="Durée totale" />}
                        value={Math.min(clipDuration, audioFileDuration)}
                        onChange={handleUpdateClipDuration}
                    />
                </Cluster>
            </Cluster>
        </Stack>
    );
};

const PlayPauseButton = ({ isPlaying, onPlayPause }) => (
    <IconButton
        aria-label="PlayPauseButton"
        icon={isPlaying ? <IconPause /> : <IconPlay />}
        onClick={onPlayPause}
        isRound
    />
);

const GoBackToStartButton = ({ onGoBackToStart }) => (
    <IconButton
        aria-label="SkipPreviousButton"
        variant="secondary"
        icon={<IconSkipPrevious />}
        onClick={onGoBackToStart}
        isRound
        size="small"
    />
);

const TimeCodeInput = ({ label, value, onChange, onPlay, onSet }) => (
    <Stack $gap="0.5rem">
        <DurationInput
            label={label}
            value={value}
            onChange={onChange}
            showMilliseconds
            leftIcon={<PlayIcon onClick={onPlay} />}
            width="9rem"
        />
        <DefineTimeCode onClick={onSet}>
            <IconTimer color={resolveColor('--primary')} />
            <Text color={resolveColor('--primary')} fontWeight="--fw-semibold">
                <FormattedMessage defaultMessage="Définir" />
            </Text>
        </DefineTimeCode>
    </Stack>
);

const TotalDurationInput = ({ label, value, onChange }) => (
    <div style={{ alignSelf: 'baseline' }}>
        <DurationInput
            label={
                <Cluster $gap="0.25rem">
                    {label}
                    <UiKitTooltip
                        position="top"
                        content={
                            <FormattedMessage defaultMessage="La durée maximum du clip vidéo est de 60 secondes." />
                        }
                    >
                        <IconHelp color={resolveColor('--neutral300')} />
                    </UiKitTooltip>
                </Cluster>
            }
            value={value}
            onChange={onChange}
            showMilliseconds
            leftIcon={<IconClock color={resolveColor('--neutral500')} />}
            width="9rem"
        />
    </div>
);

const TimeCodeText = styled(Text)`
    font-variant-numeric: tabular-nums;
`;

const DefineTimeCode = styled(Cluster)`
    cursor: pointer;
    gap: 0.25rem;
    align-items: center;
    width: fit-content;
`;

const PlayIcon = styled(IconPlay)`
    margin: auto 0;
    cursor: pointer;
    color: var(--primary);
`;

export default AudioSampleSelection;
