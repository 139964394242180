import { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import useOverviewQuery from '@queries/pso/useOverviewQuery.hook';
import PsoEvolutionCell from './PsoEvolutionCell';
import PsoSearchVolumeCell from './PsoSearchVolumeCell';
import PsoKeywordCell from './PsoKeywordCell';
import PositionCell from './PositionCell';
import DifficultyScoreCell from './DifficultyScoreCell';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';
import { TooltipTrigger } from 'react-aria-components';
import DatatableSkeleton from './DatatableSkeleton';
import styled, { css } from 'styled-components';
import PsoMetadataCell from './PsoMetadataCell';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useHasPsoFullUserOption from '@/shared/hooks/useHasPsoFullUserOption.hook';

const PsoDatatable = ({ currentStore, currentPlatform }) => {
    const { showId } = useParams();
    const overview = useOverviewQuery({ showId, currentStore });
    const [sorting, setSorting] = useState([{ id: 'ranking', desc: false }]);
    const hasPsoFull = useHasPsoFullUserOption({ showId });

    const headerTooltipContentLookup = {
        keyword: <FormattedMessage defaultMessage="Liste de vos mots-clés." />,
        ranking: (
            <FormattedMessage defaultMessage="Position de votre podcast sur ces mots-clés ce jour." />
        ),
        change: (
            <FormattedMessage defaultMessage="Évolution de la position de votre podcast entre la semaine dernière et aujourd’hui." />
        ),
        searchVolume: (
            <FormattedMessage defaultMessage="Le volume de recherche est un indicateur compris entre 5 et 100 qui montre la popularité d'un mot-clé en termes de recherches. Plus le nombre est élevé, plus le mot-clé est populaire." />
        ),
        difficultyScore: (
            <FormattedMessage defaultMessage="La difficulté est un indicateur entre 0 et 100 qui indique le niveau de concurrence d'un mot-clé. Plus le chiffre est élevé, plus la concurrence est forte." />
        ),
        occurrences: (
            <FormattedMessage defaultMessage="Visualisez le nombre de fois où le mot-clé est utilisé dans l'ensemble de vos métadonnées." />
        ),
    };

    const columnHelper = createColumnHelper();
    const baseColumns = [
        columnHelper.accessor('keyword', {
            cell: (info) => {
                return (
                    <PsoKeywordCell
                        keyword={info.getValue()}
                        isLoading={info.row.original.isLoading}
                    />
                );
            },
            id: 'keyword',
            header: () => <FormattedMessage defaultMessage="Mot-clé" />,
            size: 480,
            minSize: 150,
            enableResizing: true,
        }),
        columnHelper.accessor('ranking', {
            cell: (info) => <PositionCell rank={info.getValue()} />,
            id: 'ranking',
            header: () => <FormattedMessage defaultMessage="Classement" />,
            sortingFn: (rowA, rowB) => {
                if (rowA.original.ranking === 0) {
                    return 1; // If the current position is 0, it should be at the bottom
                }
                if (rowB.original.ranking === 0) {
                    return -1; // If the current position is 0, it should be at the bottom
                }

                return rowA.original.ranking - rowB.original.ranking;
            },
            size: 120,
        }),
        columnHelper.accessor('change', {
            cell: (info) => (
                <PsoEvolutionCell
                    trend={info.getValue().trend}
                    rankDiff={info.getValue().rankDiff}
                    missingData={info.getValue().missingData}
                />
            ),
            id: 'change',
            header: () => <FormattedMessage defaultMessage="Évolution" />,
            sortingFn: (rowA, rowB) => {
                const trendOrder = {
                    up: 0,
                    new: 1,
                    down: 2,
                    exited: 3,
                    neutral: 4,
                };

                // Row with missing data is 5th in trend order
                if (rowA.original.change.missingData && !rowB.original.change.missingData) {
                    return 1;
                }
                if (!rowA.original.change.missingData && rowB.original.change.missingData) {
                    return -1;
                }

                const rankA = trendOrder[rowA.original.change.trend];
                const rankB = trendOrder[rowB.original.change.trend];

                return rankA - rankB;
            },
            size: 120,
        }),
        columnHelper.accessor('searchVolume', {
            id: 'searchVolume',
            cell: (info) => <PsoSearchVolumeCell score={info.getValue()} />,
            header: () => <FormattedMessage defaultMessage="Vol. de Recherche" />,
            size: 120,
        }),
    ];
    const difficultyScoreColumn = [
        columnHelper.accessor('difficultyScore', {
            id: 'difficultyScore',
            cell: (info) => (
                <DifficultyScoreCell
                    score={info.getValue()}
                    isLoading={info.row.original.isLoading}
                />
            ),
            header: () => <FormattedMessage defaultMessage="Difficulté" />,
            size: 120,
        }),
    ];
    const columns = [
        ...baseColumns,
        ...(hasPsoFull ? difficultyScoreColumn : []),
        columnHelper.accessor('occurrences', {
            id: 'occurrences',
            cell: (info) => (
                <PsoMetadataCell
                    occurrences={info.getValue()}
                    keyword={info.row.original.keyword}
                />
            ),
            header: () => <FormattedMessage defaultMessage="Métadonnées" />,
            sortingFn: (rowA, rowB, columnId, desc) => {
                // Calculate the sum of occurrences for rowA and rowB
                const sumOccurrencesA = Object.values(rowA.original.occurrences || {}).reduce(
                    (sum, current) => sum + current,
                    0,
                );
                const sumOccurrencesB = Object.values(rowB.original.occurrences || {}).reduce(
                    (sum, current) => sum + current,
                    0,
                );

                // Compare the sums to determine the order
                if (sumOccurrencesA > sumOccurrencesB) return desc ? -1 : 1;
                if (sumOccurrencesA < sumOccurrencesB) return desc ? 1 : -1;
                return 0; // Equal values
            },
            size: 120,
        }),
    ];

    const table = useReactTable({
        data: overview?.data?.map((row) => ({
            keyword: row.keyword,
            ranking:
                currentPlatform === 'apple_podcasts'
                    ? row.currentPositionApple
                    : row.currentPositionSpotify,
            change: {
                trend: currentPlatform === 'apple_podcasts' ? row.trendApple : row.trendSpotify,
                rankDiff:
                    currentPlatform === 'apple_podcasts' ? row.rankDiffApple : row.rankDiffSpotify,
                missingData:
                    currentPlatform === 'apple_podcasts'
                        ? !row.previousPositionApple || !row.currentPositionApple
                        : !row.previousPositionSpotify || !row.currentPositionSpotify,
            },
            searchVolume: row.searchVolume,
            difficultyScore: row.difficultyScore,
            occurrences: row.occurrences,
            isLoading: row.isLoading,
        })),
        columns,
        state: {
            sorting,
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: false,
        columnResizeMode: 'onChange',
    });

    const sortIconRender = (isSorted) => {
        return isSorted === 'asc' ? (
            <SortIcon icon={icon({ name: 'arrow-up', style: 'solid' })} size="lg" />
        ) : (
            <SortIcon icon={icon({ name: 'arrow-down', style: 'solid' })} size="lg" />
        );
    };

    if (overview.isLoading || overview.isError) return <DatatableSkeleton />;

    return (
        <Table>
            <Thead>
                {table.getHeaderGroups().map((headerGroup) => (
                    <Tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) =>
                            header.isPlaceholder ? null : (
                                <Th
                                    key={header.id}
                                    sortable={header.column.getCanSort()}
                                    isSorted={header.column.getIsSorted() !== false}
                                    onClick={header.column.getToggleSortingHandler()}
                                >
                                    <TooltipTrigger delay={0} closeDelay={0}>
                                        <TableHeaderTooltipTriggerWrapper>
                                            <ThContent
                                                sortable={header.column.getCanSort()}
                                                onClick={header.column.getToggleSortingHandler()}
                                            >
                                                {flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext(),
                                                )}

                                                {sortIconRender(header.column.getIsSorted())}
                                            </ThContent>
                                        </TableHeaderTooltipTriggerWrapper>
                                        <Tooltip placement="top">
                                            {headerTooltipContentLookup[header.id] || null}
                                        </Tooltip>
                                    </TooltipTrigger>
                                </Th>
                            ),
                        )}
                    </Tr>
                ))}
            </Thead>
            <Tbody>
                {table.getRowModel().rows.map((row) => (
                    <Tr key={row.id}>
                        {row.getVisibleCells().map((cell) => {
                            return (
                                <Td
                                    key={cell.id}
                                    style={{
                                        width: `${cell.column.getSize()}px`,
                                        maxWidth: '100%',
                                    }}
                                >
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </Td>
                            );
                        })}
                    </Tr>
                ))}
            </Tbody>
        </Table>
    );
};
const Table = styled.table`
    border-collapse: collapse;
    table-layout: fixed;
`;
const Td = styled.td`
    padding: 1rem;
    padding-right: 2rem;
    color: var(--black);
    font-weight: var(--fw-normal);
    height: 3.5rem;
    overflow: hidden;
`;
const Tr = styled.tr`
    height: 3.5rem;
    &:not(:last-of-type) {
        border-bottom: 1px solid var(--neutral100);
    }

    & > ${Td}:not(:last-of-type) {
        border-right: 1px solid var(--neutral100);
    }
`;
const Th = styled.th`
    text-align: left;
    background-color: var(--neutral50);
    font-weight: var(--fw-semibold);
    padding: 1rem;

    ${(props) =>
        props.sortable &&
        css`
            cursor: pointer;
        `};

    svg {
        visibility: ${(props) => (props.isSorted ? 'visible' : 'hidden')};
    }

    &:hover svg {
        visibility: ${(props) => (props.sortable ? 'visible' : 'hidden')};
    }

    @media (min-width: 768px) {
        white-space: nowrap;
    }

    &:first-of-type {
        border-top-left-radius: var(--r-m);
        border-bottom-left-radius: var(--r-m);
    }
    &:last-of-type {
        border-top-right-radius: var(--r-m);
        border-bottom-right-radius: var(--r-m);
    }
`;
const Thead = styled.thead``;
const ThContent = styled.div`
    display: inline-flex;
    align-items: center;
    column-gap: 0.5rem;
    transition-duration: 0.2s;
    width: 100%;
    align-self: stretch;

    ${(props) =>
        props.sortable &&
        css`
            cursor: pointer;
        `}
`;
const SortIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
    font-size: 0.75rem;
`;
const Tbody = styled.tbody``;
const TableHeaderTooltipTriggerWrapper = styled(TooltipTriggerWrapper)`
    color: var(--neutral500);
`;

PsoDatatable.propTypes = {
    hasAppleData: PropTypes.bool,
    hasSpotifyData: PropTypes.bool,
    currentStore: PropTypes.string,
    currentPlatform: PropTypes.string,
};

PsoDatatable.defaultProps = {
    hasAppleData: false,
    hasSpotifyData: false,
};

export default PsoDatatable;
