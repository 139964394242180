import Button from '@/components/Button';
import IconButton from '@/components/IconButton';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';
import Text from '@/components/ui/atoms/Text';
import { icon as iconFA } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TooltipTrigger } from 'react-aria-components';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { useState } from 'react';
import AIPreferencesIcon from '../AIPreferencesIcon';
import EpisodeAICustomPromptModal from '../EpisodeAICustomPromptModal';
import { AISettingsMapItem } from '../../contexts/NonHostingEpisodeAISettingsContext';

interface EpisodeAIPanelFieldProps {
    icon: any;
    type: AISettingsMapItem;
    title: string | React.ReactNode;
    description: string | React.ReactNode;
    onAction: () => void;
    isLoading: boolean;
    'data-testid': string;
    isGenerated: boolean;
    tooltip?: string | React.ReactNode;
    isDisabled?: boolean;
}

const EpisodeAIPanelField = ({
    icon,
    type,
    title,
    description,
    isLoading,
    onAction,
    'data-testid': dataTestId,
    isGenerated,
    tooltip,
    isDisabled,
}: EpisodeAIPanelFieldProps) => {
    const [customPromptModalIsOpen, setCustomPromptModalIsOpen] = useState(false);
    return (
        <>
            <Wrapper>
                <IconWrapper>{icon}</IconWrapper>
                <FieldInfo>
                    <Text variant="bodyL" fontWeight="--fw-semibold">
                        {title}
                    </Text>
                    <Text color="--neutral500">{description}</Text>
                </FieldInfo>
                {isGenerated ? (
                    <TooltipTrigger delay={0} closeDelay={0} isDisabled={!isDisabled}>
                        <TooltipTriggerWrapperStyled>
                            <IconButton
                                variant="ghost"
                                icon={<AIPreferencesIcon />}
                                aria-label="AI Preferences"
                                isDisabled={isDisabled}
                                onPress={() => setCustomPromptModalIsOpen(true)}
                            />
                        </TooltipTriggerWrapperStyled>
                        <Tooltip placement="top">{tooltip}</Tooltip>
                    </TooltipTrigger>
                ) : (
                    <TooltipTrigger delay={0} closeDelay={0} isDisabled={!isDisabled}>
                        <TooltipTriggerWrapperStyled>
                            <Button
                                data-testid={dataTestId}
                                isLoading={isLoading}
                                onPress={onAction}
                                isDisabled={isDisabled}
                                size="small"
                                variant="magic"
                                startIcon={
                                    <SparklesIcon
                                        icon={iconFA({ name: 'sparkles', style: 'duotone' })}
                                    />
                                }
                            >
                                <FormattedMessage defaultMessage="Générer" />
                            </Button>
                        </TooltipTriggerWrapperStyled>
                        <Tooltip placement="top">{tooltip}</Tooltip>
                    </TooltipTrigger>
                )}
            </Wrapper>
            <Divider />
            <EpisodeAICustomPromptModal
                isOpen={customPromptModalIsOpen}
                onOpenChange={setCustomPromptModalIsOpen}
                type={type}
            />
        </>
    );
};

const Divider = styled.div`
    height: 1px;
    align-self: stretch;
    background-color: var(--neutral100);
    margin-block: 1rem;

    &:last-child {
        display: none;
    }
`;
const Wrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 1rem;
`;
const IconWrapper = styled.div`
    align-self: flex-start;
    flex-shrink: 0;
    padding-top: 0.25rem;
`;
const FieldInfo = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;
const SparklesIcon = styled(FontAwesomeIcon)`
    width: 0.5rem;
    height: 0.5rem;
    color: var(--white);
`;
const TooltipTriggerWrapperStyled = styled(TooltipTriggerWrapper)`
    flex-shrink: 0;
`;

export default EpisodeAIPanelField;
