import { defineMessages } from 'react-intl';
import { PRICING } from './pricing';
import { STATUS_SHOWS } from '@/utils/statusShows';

import type { MessageDescriptor } from '@formatjs/cli';

export const IMG_PLACEHOLDER = 'https://image.ausha.co/default_cover.png' as const;

export const CAMPAIGN_STATUS = {
    BLOCKED: 'blocked',
    PLANIFIED: 'planified',
    DRAFT: 'draft',
    ACTIVE: 'active',
    FINISHED: 'finished',
    PAUSED: 'paused',
} as const;
export type CampaignStatus = typeof CAMPAIGN_STATUS[keyof typeof CAMPAIGN_STATUS];

export const EPISODE_STATUS = {
    DRAFT: 'draft',
    SCHEDULED: 'scheduled',
    ONLINE: 'active',
    PLANNED: 'scheduled',
} as const;
export type EpisodeStatus = typeof EPISODE_STATUS[keyof typeof EPISODE_STATUS];

export const EPISODE_PRIVACY = {
    PRIVATE: 'private',
    UNLISTED: 'unlisted',
    PUBLIC: 'public',
} as const;
export type EpisodePrivacy = typeof EPISODE_PRIVACY[keyof typeof EPISODE_PRIVACY];

export const EPISODE_TYPE = {
    FULL: 'full',
    TRAILER: 'trailer',
    BONUS: 'bonus',
} as const;
export type EpisodeType = typeof EPISODE_TYPE[keyof typeof EPISODE_TYPE];

export const EPISODE_JOB_STATUS = {
    QUEUED: 'queued',
    EXECUTING: 'executing',
    FINISHED: 'finished',
    FAILED: 'failed',
} as const;
export type EpisodeJobStatus = typeof EPISODE_JOB_STATUS[keyof typeof EPISODE_JOB_STATUS];

export const EPISODE_JOB_NAME = {
    CONVERT_TO_MP3: 'converttomp3',
    GENERATE_WAVEFORM: 'generatewaveform',
    READ_CHAPTERS: 'readchapters',
} as const;
export type EpisodeJobName = typeof EPISODE_JOB_NAME[keyof typeof EPISODE_JOB_NAME];

export const EPISODE_MAX_TAGS = 20 as const;

/* EPISODE TRANSCRIPT */

export const EPISODE_TRANSCRIPT_STATUS = {
    IN_PROGRESS: 'in_progress',
    DONE: 'done',
    DELETED: 'deleted',
    UPDATED: 'updated',
    ERROR: 'error',
} as const;
export type EpisodeTranscriptStatus =
    typeof EPISODE_TRANSCRIPT_STATUS[keyof typeof EPISODE_TRANSCRIPT_STATUS];

/* PLAYLIST */

export const PLAYLIST_PRIVACY = {
    PRIVATE: 'private',
    UNLISTED: 'unlisted',
    PUBLIC: 'public',
} as const;
export type PlaylistPrivacy = typeof PLAYLIST_PRIVACY[keyof typeof PLAYLIST_PRIVACY];

/* SHOW */

export const NUMBER_OF_ARCHIVED_SHOWS = {
    [PRICING.LAUNCH]: 2,
    [PRICING.BOOST]: 3,
    [PRICING.SUPERSONIC]: 5,
} as const;
export type NumberOfArchivedShows =
    typeof NUMBER_OF_ARCHIVED_SHOWS[keyof typeof NUMBER_OF_ARCHIVED_SHOWS];

/* QUOTA */

export const QUOTA_TYPES = {
    TRANSCRIPT: 'transcript_seconds',
    NEWSLETTER: 'newsletter',
    AI_TOKENS: 'ai_tokens',
    YOUTUBE: 'youtube',
} as const;
export type QuotaType = typeof QUOTA_TYPES[keyof typeof QUOTA_TYPES];

export const SHOW_QUOTA_TYPES = {
    ACTIVE_SHOWS: STATUS_SHOWS.ACTIVE_SHOWS,
    ARCHIVED_SHOWS: STATUS_SHOWS.ARCHIVED_SHOWS,
} as const;
export type ShowQuotaType = typeof SHOW_QUOTA_TYPES[keyof typeof SHOW_QUOTA_TYPES];

/* --- CLIPS --- */

export const CLIP_QUOTA = {
    [PRICING.LAUNCH]: 4,
    [PRICING.BOOST]: 12,
    [PRICING.SUPERSONIC]: 24,
} as const;
export type ClipQuota = typeof CLIP_QUOTA[keyof typeof CLIP_QUOTA];

export const CLIP_STATUS = {
    PENDING: 'pending',
    LOADING: 'loading',
    DONE: 'done',
    ERROR: 'error',
} as const;
export type ClipStatus = typeof CLIP_STATUS[keyof typeof CLIP_STATUS];

export const CLIP_FORMATS = {
    SQUARE: 'square',
    STORY: 'story',
    LANDSCAPE: 'wide',
} as const;
export type ClipFormat = typeof CLIP_FORMATS[keyof typeof CLIP_FORMATS];
export const CLIP_TRANSCRIPTION_MAXIMUM_RECOMMENDED_LENGTH = 65 as const;
export const CLIP_MAX_LENGTH = 60 as const;
export const CLIP_MIN_LENGTH = 1 as const;

/* --- TRACKERS ---  */

export const TRACKERS_GOOGLE_ANALYTICS = 'googleAnalytics' as const;
export const TRACKERS_FACEBOOK_PIXEL = 'facebookPixel' as const;
export const TRACKERS_LINKEDIN_PIXEL = 'linkedinPixel' as const;

/* --- YOUTUBE --- */

export const YOUTUBE_VIDEO_STATUS = {
    DRAFT: 'draft',
    PENDING: 'pending',
    PROCESSING: 'processing',
    DONE: 'done',
    ERROR: 'error',
} as const;
export type YoutubeVideoStatus = typeof YOUTUBE_VIDEO_STATUS[keyof typeof YOUTUBE_VIDEO_STATUS];

/* --- FEATURE FLAGS ---  */

export const ENABLE_UNSUBSCRIBE_SURVEY = true as const;
export const ENABLE_AUSHA_CLUB_BUTTON = true as const;
export const ENABLE_PSO = process.env.ENABLE_PSO === ('true' as const);
export const ENABLE_TRANSCRIPTION = process.env.ENABLE_TRANSCRIPTION === 'true' ?? (false as const);
export const ENABLE_PSO_CHECKER = process.env.ENABLE_PSO_SCORE === 'true' ?? (false as const);
export const ENABLE_USER_TOKEN_REFRESH_IN_BACKGROUND =
    process.env.ENABLE_USER_TOKEN_REFRESH_IN_BACKGROUND === 'true' ?? (false as const);
export const ENABLE_GENERATE_TRANSCRIPTION =
    process.env.ENABLE_GENERATE_TRANSCRIPTION === 'true' ?? (false as const);
export const ENABLE_EPISODE_AI = process.env.ENABLE_EPISODE_AI === 'true' ?? (false as const);
export const ENABLE_PSO_LIVE_SEARCH =
    process.env.ENABLE_PSO_LIVE_SEARCH === 'true' ?? (false as const);
export const ENABLE_NON_HOSTING_EP_MGT =
    process.env.ENABLE_NON_HOSTING_EP_MGT === 'true' ?? (false as const);
export const ENABLE_PSO_COMPETITORS =
    process.env.ENABLE_PSO_COMPETITORS === 'true' ?? (false as const);

/* --- AUDIO TYPES --- */

export const ANY_AUDIO_TYPE = 'audio/*' as const;
export const MP3_AUDIO_TYPES = [
    'audio/mpeg', // Official (https://tools.ietf.org/html/rfc3003)
    'audio/mp3', // Historically for Google Chrome (https://bugs.chromium.org/p/chromium/issues/detail?id=227004, fixed in 2020)
    'audio/x-mp3',
    'audio/mpeg3',
] as const;
export type Mp3AudioType = typeof MP3_AUDIO_TYPES[number];
export const M4A_AUDIO_TYPES = ['audio/mp4', 'audio/x-m4a'] as const;
export type M4aAudioType = typeof M4A_AUDIO_TYPES[number];
export const WAV_AUDIO_TYPES = ['audio/wav', 'audio/x-wav', 'audio/wave'] as const;
export type WavAudioType = typeof WAV_AUDIO_TYPES[number];
export const FLAC_AUDIO_TYPES = ['audio/flac'] as const;
export type FlacAudioType = typeof FLAC_AUDIO_TYPES[number];
export const OGG_AUDIO_TYPES = ['audio/ogg', 'audio/x-ogg'] as const;
export type OggAudioType = typeof OGG_AUDIO_TYPES[number];
export const ACCEPTED_AUDIO_TYPES = [
    ANY_AUDIO_TYPE,
    // Provides additional audio types as a fallback
    ...MP3_AUDIO_TYPES,
    ...M4A_AUDIO_TYPES,
    ...WAV_AUDIO_TYPES,
    ...FLAC_AUDIO_TYPES,
    ...OGG_AUDIO_TYPES,
] as const;
export type AcceptedAudioType = typeof ACCEPTED_AUDIO_TYPES[number];

/* -- IMG TYPES -- */

export const IMG_TYPES = ['image/jpeg', 'image/png'] as const;

/* -- USEFUL URLs -- */

export const AUSHA_CLUB_URL = 'https://club.ausha.co/' as const;

/* -- USER -- */

export const USER_ROLES = {
    VIEWER: 'viewer',
    EDITOR: 'editor',
    ADMIN: 'admin',
    OWNER: 'owner',
} as const;
export type UserRole = typeof USER_ROLES[keyof typeof USER_ROLES];

export const GUEST_USER_ROLES = {
    VIEWER: USER_ROLES.VIEWER,
    EDITOR: USER_ROLES.EDITOR,
    ADMIN: USER_ROLES.ADMIN,
} as const;
export type GuestUserRole = typeof GUEST_USER_ROLES[keyof typeof GUEST_USER_ROLES];

export const CHANNEL_GUEST_USER_ROLES = {
    ...GUEST_USER_ROLES,
    MIXED: 'mixed',
} as const;
export type ChannelGuestUserRole =
    typeof CHANNEL_GUEST_USER_ROLES[keyof typeof CHANNEL_GUEST_USER_ROLES];

export const SUBSCRIPTION_ACTION = {
    UPGRADE: 'upgrade',
    DOWNGRADE: 'downgrade',
    PERIODICITY_UPDATE: 'periodicityUpdate',
} as const;
export type SubscriptionAction = typeof SUBSCRIPTION_ACTION[keyof typeof SUBSCRIPTION_ACTION];

/* ---- LOGIN PROVIDERS ---- */

export const LOGIN_PROVIDER = {
    GOOGLE: 'google',
    FACEBOOK: 'facebook',
} as const;
export type LoginProvider = typeof LOGIN_PROVIDER[keyof typeof LOGIN_PROVIDER];

/* ---- SOCIAL NETWORKS ---- */

export const FACEBOOK = 'facebook' as const;
export const TWITTER = 'twitter' as const;
export const INSTAGRAM = 'instagram' as const;
export const LINKEDIN = 'linkedin' as const;

export const SOCIAL_NETWORK = {
    FACEBOOK,
    TWITTER,
    INSTAGRAM,
    LINKEDIN,
} as const;
export type SocialNetwork = typeof SOCIAL_NETWORK[keyof typeof SOCIAL_NETWORK];

export const SOCIAL_NETWORK_NAME = {
    [FACEBOOK]: 'Facebook',
    [TWITTER]: 'X (Twitter)',
    [INSTAGRAM]: 'Instagram',
    [LINKEDIN]: 'LinkedIn',
} as const;
export type SocialNetworkName = typeof SOCIAL_NETWORK_NAME[keyof typeof SOCIAL_NETWORK_NAME];

export const CHARACTERS_LIMIT = {
    [FACEBOOK]: 63000,
    [TWITTER]: 257,
    [INSTAGRAM]: 2200,
    [LINKEDIN]: 3000,
} as const;
export type CharactersLimit = typeof CHARACTERS_LIMIT[keyof typeof CHARACTERS_LIMIT];

export const SOCIAL_NETWORK_POST_STATUS = {
    DONE: 'done',
    DRAFT: 'draft',
    ERROR: 'error',
    PROCESSING: 'processing',
    PUBLISH_AT: 'publish_at',
    PUBLISH_NOW: 'publish_now',
    REMOVED: 'removed',
} as const;
export type SocialNetworkPostStatus =
    typeof SOCIAL_NETWORK_POST_STATUS[keyof typeof SOCIAL_NETWORK_POST_STATUS];

export const SOCIAL_NETWORK_POST_TYPE = {
    CLIP: 'clip',
    IMAGE: 'image',
    LINK: 'link',
    PODCAST_COVER: 'podcast_cover',
    SMARTLINK: 'smartlink',
} as const;
export type SocialNetworkPostType =
    typeof SOCIAL_NETWORK_POST_TYPE[keyof typeof SOCIAL_NETWORK_POST_TYPE];

/* ---- RSS IMPORT ---- */

export const ACCEPTED_DISTRIBUTION_PLATFORMS_TO_COMPLETE_RSS_IMPORT = [
    'amazon',
    'deezer',
    'gaana',
    'iHeartRadio',
    'jioSaavn',
    'listenNotes',
    'pandora',
    'playerFm',
    'podcastIndex',
    'podchaser',
    'radioPublic',
    'spotify',
] as const;
export type AcceptedDistributionPlatformToCompleteRssImport =
    typeof ACCEPTED_DISTRIBUTION_PLATFORMS_TO_COMPLETE_RSS_IMPORT[number];

export const DEPRECATED_ORIGINAL_PLATFORMS = [
    'acast',
    'amazon',
    'anchor',
    'buzzsprout',
    'castro',
    'castbox',
    'deezer',
    'djpod',
    'feedburner',
    'itunes',
    'libsyn',
    'overcast',
    'pippa',
    'podcastAddict',
    'pocketCasts',
    'podbean',
    'podcastics',
    'podcloud',
    'podomatic',
    'rsscom',
    'simplecast',
    'soundcloud',
    'spreaker',
    'spotify',
    'tuneIn',
    'youtube',
] as const;
export type DeprecatedOriginalPlatform = typeof DEPRECATED_ORIGINAL_PLATFORMS[number];

export const RSS_FEED_REDIRECTION_TUTORIAL_URL_FR =
    'https://help.ausha.co/fr/collections/2843155-creer-importer-et-modifier-mon-podcast#importer-une-emission-existante-depuis-un-hebergeur-en-particulier' as const;
export const RSS_FEED_REDIRECTION_TUTORIAL_URL_EN =
    'https://help.ausha.co/en/collections/2843155-create-import-edit-my-show#how-do-i-import-my-show-from-a-specific-host' as const;

/* ---- MONETIZATION PLATFORMS ---- */
export const TARGETSPOT = 'targetspot' as const;
export const PODCORN = 'podcorn' as const;

/* ---- Hosting platforms ---- */

export const ACAST = 'acast' as const;
export const ANCHOR = 'anchor' as const;
export const BUZZSPROUT = 'buzzsprout' as const;
export const LIBSYN = 'libsyn' as const;
export const PODBEAN = 'podbean' as const;
export const PODCASTICS = 'podcastics' as const;
export const PODCLOUD = 'podcloud' as const;
export const PODOMATIC = 'podomatic' as const;
export const SIMPLECAST = 'simplecast' as const;
export const SOUNDCLOUD = 'soundcloud' as const;
export const SPREAKER = 'spreaker' as const;

export const HOSTING_PLATFORMS = [
    ACAST,
    ANCHOR,
    BUZZSPROUT,
    LIBSYN,
    PODBEAN,
    PODCASTICS,
    PODCLOUD,
    PODOMATIC,
    SIMPLECAST,
    SOUNDCLOUD,
    SPREAKER,
] as const;
export type HostingPlatform = typeof HOSTING_PLATFORMS[number];

export const HOSTING_PLATFORMS_NAMES = {
    [ACAST]: 'Acast',
    [ANCHOR]: 'Anchor',
    [BUZZSPROUT]: 'Buzzsprout',
    [LIBSYN]: 'Libsyn',
    [PODBEAN]: 'PodBean',
    [PODCASTICS]: 'Podcastics',
    [PODCLOUD]: 'Podcloud',
    [PODOMATIC]: 'Podomatic',
    [SIMPLECAST]: 'Simplecast',
    [SOUNDCLOUD]: 'SoundCloud',
    [SPREAKER]: 'Spreaker',
} as const;
export type HostingPlatformName =
    typeof HOSTING_PLATFORMS_NAMES[keyof typeof HOSTING_PLATFORMS_NAMES];

export const HOSTING_PLATFORMS_HELP_LINKS = defineMessages({
    [ACAST]: {
        defaultMessage:
            'https://help.ausha.co/fr/articles/2090178-comment-importer-et-rediriger-mon-flux-rss-acast-chez-ausha',
    },
    [ANCHOR]: {
        defaultMessage:
            'https://help.ausha.co/fr/articles/2120462-comment-importer-et-rediriger-mon-flux-rss-anchor-chez-ausha',
    },
    [BUZZSPROUT]: {
        defaultMessage:
            'https://help.ausha.co/fr/articles/2273363-comment-importer-et-rediriger-mon-flux-rss-buzzsprout-chez-ausha',
    },
    [LIBSYN]: {
        defaultMessage:
            'https://help.ausha.co/fr/articles/2298062-comment-importer-et-rediriger-mon-flux-rss-libsyn-chez-ausha',
    },
    [PODBEAN]: {
        defaultMessage:
            'https://help.ausha.co/fr/articles/2152392-comment-importer-et-rediriger-mon-flux-rss-podbean-chez-ausha',
    },
    [PODCASTICS]: {
        defaultMessage:
            'https://help.ausha.co/fr/articles/6232475-comment-importer-et-rediriger-mon-flux-rss-podcastics-chez-ausha',
    },
    [PODCLOUD]: {
        defaultMessage:
            'https://help.ausha.co/fr/articles/2093675-comment-importer-et-rediriger-mon-flux-rss-podcloud-chez-ausha',
    },
    [PODOMATIC]: {
        defaultMessage:
            'https://help.ausha.co/fr/articles/2300837-comment-importer-et-rediriger-mon-flux-rss-podomatic-chez-ausha',
    },
    [SIMPLECAST]: {
        defaultMessage:
            'https://help.ausha.co/fr/articles/2193305-comment-importer-et-rediriger-mon-flux-rss-simplecast-chez-ausha',
    },
    [SOUNDCLOUD]: {
        defaultMessage:
            'https://help.ausha.co/fr/articles/2084813-comment-importer-et-rediriger-mon-flux-rss-soundcloud-chez-ausha',
    },
    [SPREAKER]: {
        defaultMessage:
            'https://help.ausha.co/fr/articles/2093133-comment-importer-et-rediriger-mon-flux-rss-spreaker-chez-ausha',
    },
}) as Record<HostingPlatform, MessageDescriptor>;
export type HostingPlatformHelpLink = typeof HOSTING_PLATFORMS_HELP_LINKS[HostingPlatform];

/* ---- Listening platforms ---- */

export const AMAZON_MUSIC = 'amazon' as const;
export const APPLE_PODCASTS = 'itunes' as const;
export const CASTBOX = 'castbox' as const;
export const CASTRO = 'castro' as const;
export const DEEZER = 'deezer' as const;
export const GAANA = 'gaana' as const;
export const I_HEART_RADIO = 'i_heart_radio' as const;
export const JIO_SAAVN = 'jio_saavn' as const;
export const LISTEN_NOTES = 'listen_notes' as const;
export const OVERCAST = 'overcast' as const;
export const PANDORA = 'pandora' as const;
export const PLAYER_FM = 'player_fm' as const;
export const POCKET_CASTS = 'pocket_casts' as const;
export const PODCAST_ADDICT = 'podcast_addict' as const;
export const PODCAST_INDEX = 'podcast_index' as const;
export const PODCHASER = 'podchaser' as const;
export const RADIO_PUBLIC = 'radio_public' as const;
export const SPOTIFY = 'spotify' as const;
export const TUNEIN = 'tunein' as const;
export const YOUTUBE = 'youtube' as const;

export const LISTENING_PLATFORMS = [
    AMAZON_MUSIC,
    APPLE_PODCASTS,
    CASTBOX,
    CASTRO,
    DEEZER,
    GAANA,
    I_HEART_RADIO,
    JIO_SAAVN,
    LISTEN_NOTES,
    OVERCAST,
    PANDORA,
    PLAYER_FM,
    POCKET_CASTS,
    PODCAST_ADDICT,
    PODCAST_INDEX,
    PODCHASER,
    RADIO_PUBLIC,
    SOUNDCLOUD,
    SPOTIFY,
    TUNEIN,
    YOUTUBE,
] as const;
export type ListeningPlatform = typeof LISTENING_PLATFORMS[number];

export const LISTENING_PLATFORMS_NAMES = {
    [AMAZON_MUSIC]: 'Amazon Music',
    [APPLE_PODCASTS]: 'Apple Podcasts',
    [CASTBOX]: 'Castbox',
    [CASTRO]: 'Castro',
    [DEEZER]: 'Deezer',
    [GAANA]: 'Gaana',
    [I_HEART_RADIO]: 'iHeartRadio',
    [JIO_SAAVN]: 'JioSaavn',
    [LISTEN_NOTES]: 'Listen Notes',
    [OVERCAST]: 'Overcast',
    [PANDORA]: 'Pandora',
    [PLAYER_FM]: 'PlayerFM',
    [POCKET_CASTS]: 'Pocket Casts',
    [PODCAST_ADDICT]: 'Podcast Addict',
    [PODCAST_INDEX]: 'Podcast Index',
    [PODCHASER]: 'Podchaser',
    [RADIO_PUBLIC]: 'RadioPublic',
    [SOUNDCLOUD]: 'SoundCloud',
    [SPOTIFY]: 'Spotify',
    [TUNEIN]: 'Tune In',
    [YOUTUBE]: 'YouTube',
} as const;
export type ListeningPlatformName =
    typeof LISTENING_PLATFORMS_NAMES[keyof typeof LISTENING_PLATFORMS_NAMES];

export const LISTENING_PLATFORMS_LOOKUP = {
    amazon: AMAZON_MUSIC,
    itunes: APPLE_PODCASTS,
    castbox: CASTBOX,
    castro: CASTRO,
    deezer: DEEZER,
    gaana: GAANA,
    iHeartRadio: I_HEART_RADIO,
    jioSaavn: JIO_SAAVN,
    listenNotes: LISTEN_NOTES,
    overcast: OVERCAST,
    pandora: PANDORA,
    playerFm: PLAYER_FM,
    pocketCasts: POCKET_CASTS,
    podcastAddict: PODCAST_ADDICT,
    podcastIndex: PODCAST_INDEX,
    podchaser: PODCHASER,
    soundCloud: SOUNDCLOUD,
    spotify: SPOTIFY,
    tuneIn: TUNEIN,
    youTube: YOUTUBE,
} as const;
export type ListeningPlatformLookup =
    typeof LISTENING_PLATFORMS_LOOKUP[keyof typeof LISTENING_PLATFORMS_LOOKUP];

export const AUTOMATIC_BROADCAST_PLATFORMS = [POCKET_CASTS, CASTBOX, OVERCAST, CASTRO] as const;
export type AutomaticBroadcastPlatform = typeof AUTOMATIC_BROADCAST_PLATFORMS[number];

export const BROADCAST_PLATFORMS = [
    AMAZON_MUSIC,
    APPLE_PODCASTS,
    CASTBOX,
    CASTRO,
    DEEZER,
    OVERCAST,
    POCKET_CASTS,
    PODCAST_ADDICT,
    SPOTIFY,
] as const;
export type BroadcastPlatform = typeof BROADCAST_PLATFORMS[number];

export const BROADCAST_PLATFORMS_EN = [
    TUNEIN,
    I_HEART_RADIO,
    PLAYER_FM,
    JIO_SAAVN,
    GAANA,
    PANDORA,
    PODCAST_INDEX,
    PODCHASER,
    LISTEN_NOTES,
] as const;
export type BroadcastPlatformEn = typeof BROADCAST_PLATFORMS_EN[number];

export const PMP_BROADCAST_PLATFORMS = [APPLE_PODCASTS, SPOTIFY] as const;
export type PmpBroadcastPlatform = typeof PMP_BROADCAST_PLATFORMS[number];

export const BROADCAST_PLATFORMS_GUIDE_LINKS = {
    [DEEZER]: {
        fr: 'http://aide.ausha.co/fr/articles/2983883-ou-trouver-son-lien-d-ecoute-deezer',
        en: 'https://help.ausha.co/en/articles/2983883-where-can-i-find-my-deezer-link',
    },
    [SPOTIFY]: {
        fr: 'http://aide.ausha.co/fr/articles/2983842-ou-trouver-son-lien-d-ecoute-spotify',
        en: 'https://help.ausha.co/en/articles/2983842-where-do-i-find-my-spotify-link',
    },
    [PODCAST_ADDICT]: {
        fr: 'http://aide.ausha.co/fr/articles/2983974-ou-trouver-son-lien-d-ecoute-podcast-addict',
        en: 'https://help.ausha.co/en/articles/2983974-where-can-i-find-my-podcast-addict-link',
    },
    [AMAZON_MUSIC]: {
        fr: 'https://help.ausha.co/fr/articles/5374002-ou-trouver-son-lien-d-ecoute-amazon-music',
    },
    [PODCHASER]: {
        fr: 'https://help.ausha.co/en/articles/5792329-where-can-i-find-my-podchaser-listen-link',
        en: 'https://help.ausha.co/en/articles/5792329-where-can-i-find-my-podchaser-listen-link',
    },
    [LISTEN_NOTES]: {
        fr: 'https://help.ausha.co/en/articles/5792231-where-can-i-find-my-listen-notes-listen-link',
        en: 'https://help.ausha.co/en/articles/5792231-where-can-i-find-my-listen-notes-listen-link',
    },
    [PODCAST_INDEX]: {
        fr: 'https://help.ausha.co/en/articles/5792455-where-can-i-find-my-podcast-index-listen-link',
        en: 'https://help.ausha.co/en/articles/5792455-where-can-i-find-my-podcast-index-listen-link',
    },
    [APPLE_PODCASTS]: {
        fr: 'https://aide.ausha.co/fr/articles/2970733-ou-trouver-son-lien-d-ecoute-apple-podcasts',
        en: 'https://help.ausha.co/en/articles/2970733-where-do-i-find-my-apple-podcast-link',
    },
    [TUNEIN]: {
        fr: 'https://help.ausha.co/fr/articles/2971047-ou-trouver-son-lien-d-ecoute-tunein',
        en: 'https://help.ausha.co/en/articles/2971047-where-can-i-find-my-tunein-link',
    },
    [I_HEART_RADIO]: {
        fr: 'https://help.ausha.co/en/articles/5767657-where-can-i-find-my-iheartradio-listen-link',
        en: 'https://help.ausha.co/en/articles/5767657-where-can-i-find-my-iheartradio-listen-link',
    },
    [PANDORA]: {
        fr: 'https://help.ausha.co/en/articles/5807487-where-can-i-find-my-pandora-listen-link',
        en: 'https://help.ausha.co/en/articles/5807487-where-can-i-find-my-pandora-listen-link',
    },
    [PLAYER_FM]: {
        fr: 'https://help.ausha.co/en/articles/5767334-where-can-i-find-my-player-fm-listen-link',
        en: 'https://help.ausha.co/en/articles/5767334-where-can-i-find-my-player-fm-listen-link',
    },
    [JIO_SAAVN]: {
        fr: 'https://help.ausha.co/en/articles/5767727-where-can-i-find-my-jiosaavn-listen-link',
        en: 'https://help.ausha.co/en/articles/5767727-where-can-i-find-my-jiosaavn-listen-link',
    },
    [GAANA]: {
        fr: 'https://help.ausha.co/en/articles/5767807-where-can-i-find-my-gaana-listen-link',
        en: 'https://help.ausha.co/en/articles/5767807-where-can-i-find-my-gaana-listen-link',
    },
    [RADIO_PUBLIC]: {},
    [POCKET_CASTS]: {
        fr: 'https://help.ausha.co/fr/articles/6220252-comment-diffuser-mon-podcast-sur-pocket-casts-avec-ausha',
        en: 'https://help.ausha.co/en/articles/6220252-how-do-i-broadcast-my-podcast-on-pocket-casts-with-ausha',
    },
    [OVERCAST]: {
        fr: 'https://help.ausha.co/fr/articles/6223830-comment-diffuser-mon-podcast-sur-overcast-avec-ausha',
        en: 'https://help.ausha.co/en/articles/6223830-how-do-i-broadcast-my-podcast-on-overcast-from-ausha',
    },
    [CASTRO]: {
        fr: 'https://help.ausha.co/fr/articles/6224172-comment-diffuser-mon-podcast-sur-castro-avec-ausha',
        en: 'https://help.ausha.co/en/articles/6224172-how-do-i-broadcast-my-podcast-on-castro-with-ausha',
    },
    [CASTBOX]: {
        fr: 'https://help.ausha.co/fr/articles/6221555-comment-diffuser-mon-podcast-sur-castbox-avec-ausha',
        en: 'https://help.ausha.co/en/articles/6221555-how-do-i-broadcast-my-podcast-on-castbox-with-ausha',
    },
} as const;
export type BroadcastPlatformGuideLink = typeof BROADCAST_PLATFORMS_GUIDE_LINKS[BroadcastPlatform];

export const ONE_CLICK_BROADCAST_PLATFORMS = [
    SPOTIFY,
    DEEZER,
    AMAZON_MUSIC,
    PODCAST_ADDICT,
    PODCAST_INDEX,
    PODCHASER,
    LISTEN_NOTES,
] as const;
export type OneClickBroadcastPlatform = typeof ONE_CLICK_BROADCAST_PLATFORMS[number];

export const MAX_AUDIO_FILE_SIZE = 500000000 as const; // 500 MB

export const GUARD_DISALLOW_REASONS = {
    INSUFFICIENT_ROLE: 'insufficient-role',
    INSUFFICIENT_SHOW_STATUS: 'insufficient-show-status',
    UNAUTHORIZED_SUBSCRIPTION: 'unauthorized-subscription',
    UNSUFFICIENT_PLAN: 'unsufficient-plan',
    UNMET_USER_OPTIONS: 'unmet-user-options',
    GENERIC: 'generic',
} as const;
export type GuardDisallowReason =
    typeof GUARD_DISALLOW_REASONS[keyof typeof GUARD_DISALLOW_REASONS];

/* TABS */

export const COMMUNICATION_TABS = {
    SOCIAL_MEDIA: 'social-media',
    CLIP: 'clip',
    PLAYER: 'player',
    PAGE: 'page',
    SMARTLINK: 'smartlink',
    NEWSLETTER: 'newsletter',
    SETTINGS: 'settings',
} as const;
export type CommunicationTab = typeof COMMUNICATION_TABS[keyof typeof COMMUNICATION_TABS];

export const SETTINGS_TABS = {
    AUTO_SHARING: 'auto-sharing',
    EXTERNAL_LINKS: 'external-links',
    CALL_TO_ACTION: 'call-to-action',
    TRACKER: 'tracker',
    WEBHOOK: 'webhook',
} as const;
export type SettingsTab = typeof SETTINGS_TABS[keyof typeof SETTINGS_TABS];

export const SALES_EMAIL = 'sales@ausha.co' as const;

/* UI */

export const UI_VARIANTS = {
    SUCCESS: 'success',
    ALERT: 'alert',
    INFO: 'info',
    WARNING: 'warning',
    NEUTRAL: 'neutral',
    PRIMARY: 'primary',
} as const;
export type UiVariant = typeof UI_VARIANTS[keyof typeof UI_VARIANTS];

/* --- DEBUG --- */

export const DEBUG_EPISODE_EDIT_FORM_SAVE = true as const;
export const DEBUG_EPISODE_EDIT_FORM = false as const;

/* -- STATISTICS -- */
export const GRAPH_COLORS = [
    '#9F40FF',
    '#4053FF',
    '#FF40B3',
    '#FF7940',
    '#84E522',
    '#FFEC40',
    '#40C6FF',
    '#D6D2D9',
];
export type GraphColor = typeof GRAPH_COLORS[number];

export const ENTERPRISE_GRAPH_COLORS = [
    '#9F40FF',
    '#4053FF',
    '#FF40B3',
    '#FF7940',
    '#84E522',
    '#FFEC40',
    '#40C6FF',
    '#50E58C',
    '#FF4040',
    '#50E5E5',
    '#D6D2D9',
];
export type EnterpriseGraphColor = typeof ENTERPRISE_GRAPH_COLORS[number];

export const TRANSCRIPTION_FILES_URL =
    process.env.TRANSCRIPTION_FILES_URL ?? 'https://transcriptfiles-stg.ausha.co/';

/* PRICING */
export const PRICING_CURRENCIES = {
    EUR: 'eur',
    USD: 'usd',
    GBP: 'gbp',
};
export type PRICING_CURRENCY = typeof PRICING_CURRENCIES[keyof typeof PRICING_CURRENCIES];

export const PRICING_CURRENCY_SYMBOLS = {
    [PRICING_CURRENCIES.EUR]: '€',
    [PRICING_CURRENCIES.USD]: '$',
    [PRICING_CURRENCIES.GBP]: '£',
};

export const TRANSCRIPTION_CURRENCY_MONTH_PRICES = {
    [PRICING_CURRENCIES.EUR]: 3,
    [PRICING_CURRENCIES.USD]: 4,
    [PRICING_CURRENCIES.GBP]: 3,
};

export const TRANSCRIPTION_CURRENCY_YEAR_PRICES = {
    [PRICING_CURRENCIES.EUR]: 30,
    [PRICING_CURRENCIES.USD]: 40,
    [PRICING_CURRENCIES.GBP]: 30,
};

export const TRANSCRIPTION_CONFIDENCE_THRESHOLD = 0.3 as const;
