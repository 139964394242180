import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';

interface CustomTooltipProps {
    seriesIndex: number;
    dataPointIndex: number;
    w: {
        config: any;
        globals: {
            categoryLabels: string[];
            seriesColors: string[];
            seriesNames: string[];
        };
    };
}

const CustomTooltip = ({ seriesIndex, dataPointIndex, w }: CustomTooltipProps) => {
    const data = w.config.series[seriesIndex].data[dataPointIndex];

    if (seriesIndex === 0) {
        let variationPercent;
        if (data.variation > 0) {
            variationPercent = `+${Math.round(data.variation)}%`;
        } else if (data.variation === 0) {
            variationPercent = `0%`;
        } else if (isNaN(data.variation)) {
            variationPercent = '';
        } else {
            variationPercent = `${Math.round(data.variation)}%`;
        }
        return (
            <CustomTooltipWrapper>
                <Header>
                    <Title>{data.longDate}</Title>
                </Header>
                {data.publishedData.shouldDisplayPublishedContent ? (
                    <Content>
                        {data.publishedData.publishedAt} - {data.publishedData.message} -{' '}
                        {data.publishedData.content}
                    </Content>
                ) : (
                    <Content>
                        <PlayIcon icon={icon({ name: 'play', style: 'solid' })} />
                        <Downloads>{data.y}</Downloads>
                        <Variation $color={data.variation > 0 ? '--success' : '--alert'}>
                            {variationPercent}
                        </Variation>
                    </Content>
                )}
            </CustomTooltipWrapper>
        );
    }

    return (
        <CustomTooltipWrapper>
            <Header>
                <Title>{data.longDate}</Title>
            </Header>
            <Content>
                <EpisodeNameList>
                    <EpisodeNameHeader>
                        <PaperPlaneIcon icon={icon({ name: 'paper-plane', style: 'solid' })} />
                        {data.episodeNames.length >= 2
                            ? data.publishedEpisodeTextPlural
                            : data.publishedEpisodeText}
                    </EpisodeNameHeader>
                    {data.episodeNames.map((episodeName: string) => (
                        <EpisodeName key={episodeName}>{episodeName}</EpisodeName>
                    ))}
                </EpisodeNameList>
            </Content>
        </CustomTooltipWrapper>
    );
};

const CustomTooltipWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 10rem;
`;
const Header = styled.div`
    padding: 0.5rem;
    color: var(--neutral);
`;
const Title = styled.span`
    display: inline-block;
    width: 100%;
`;
const Content = styled.div`
    padding: 0.5rem;
    padding-top: 0;
    display: flex;
    align-items: center;
    gap: 0.25rem;
`;
const PlayIcon = styled(FontAwesomeIcon)`
    font-size: 0.75rem;
`;
const Downloads = styled.span``;
const Variation = styled.span<{ $color?: string }>`
    color: var(${(props) => props.$color ?? '--black'});
`;
const EpisodeNameList = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    padding: 0;
    list-style: none;
    min-width: 0;
`;
const EpisodeName = styled.li`
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 0;
`;
const EpisodeNameHeader = styled.li`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: var(--fw-semibold);
`;
const PaperPlaneIcon = styled(FontAwesomeIcon)`
    font-size: 0.75rem;
    color: var(--primary);
`;

export default CustomTooltip;
