import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';

interface TargetCellProps {
    episode?: {
        id: string;
        title: string;
    };
    show: {
        id: string;
        title: string;
        thumbnail: string;
    };
    isOwn: boolean;
}

const TargetCell = ({ episode, show, isOwn }: TargetCellProps) => {
    return (
        <TargetCellOuterWrapper>
            <TargetCellWrapper>
                <TargetCellInnerWrapper>
                    <Thumbnail src={show.thumbnail} alt={show.title} />
                    {episode ? (
                        <TitleAndSubtitleWrapper>
                            <Title>{episode.title}</Title>
                            <Subtitle>
                                <FormattedMessage defaultMessage="Podcast" />
                                <DotIcon icon={icon({ name: 'circle', style: 'solid' })} />
                                {show.title}
                            </Subtitle>
                        </TitleAndSubtitleWrapper>
                    ) : (
                        <Title>{show.title}</Title>
                    )}
                </TargetCellInnerWrapper>
            </TargetCellWrapper>
            {isOwn && (
                <YouTag>
                    <FormattedMessage defaultMessage="Vous" />
                </YouTag>
            )}
        </TargetCellOuterWrapper>
    );
};

const TargetCellOuterWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.75rem;
`;
const TargetCellWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
const TargetCellInnerWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.75rem;
    width: 100%;
`;
const Thumbnail = styled.img`
    width: 2.5rem;
    height: 2.5rem;
    border-radius: var(--r-s);
`;
const Title = styled.span`
    font-weight: var(--fw-semibold);
    max-width: 350px;
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
const YouTag = styled.span`
    padding-block: 0.2rem;
    padding-inline: 0.5rem;
    background-color: var(--neutral100);
    color: var(--neutral500);
    border-radius: var(--r-s);
    font-size: var(--fs-s);
    font-weight: var(--fw-semibold);
`;
const Subtitle = styled.div`
    display: flex;
    align-items: center;
    color: var(--neutral500);
    max-width: 350px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
const DotIcon = styled(FontAwesomeIcon)`
    color: var(--neutral200);
    font-size: 0.25rem;
    margin-inline: 0.25rem;
    margin-top: 0.1rem;
`;
const TitleAndSubtitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 350px;
    width: 100%;
`;

export default TargetCell;
