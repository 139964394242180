import { createContext } from 'react';
import ArrowIcon from '@public/icons/chevrondown.svg';
import { connect } from '../../../legacy/connect';
import MobileOrTablet from '../../molecules/MobileOrTablet';
import Desktop from '../../molecules/Desktop';
import DeprecatedText from '../../atoms/DeprecatedText';
import DeprecatedIcon from '../../atoms/DeprecatedIcon';
import VerticalDropContainer from '../../atoms/VerticalDropContainer';
import styled from 'styled-components';

export const RouteContext = createContext(null);

const enhance = connect(({ routerStore }) => ({
    activeTab: routerStore.params.tab,
}));

function Tabs({ sectionTitle, children, route, activeTab, titles }) {
    return (
        <RouteContext.Provider value={{ route, titles }}>
            <MobileOrTablet>
                <VerticalDropContainer
                    currentTab={
                        <DeprecatedText flex color="--primary" weight="semibold">
                            {titles[activeTab]}
                            <DeprecatedIcon color="--primary" as={ArrowIcon} />
                        </DeprecatedText>
                    }
                    flex
                    column
                    rounded
                >
                    {children}
                </VerticalDropContainer>
            </MobileOrTablet>
            <Desktop>
                <TabsOuterWrapper>
                    {sectionTitle && <Title>{sectionTitle}</Title>}
                    <TabsWrapper>{children}</TabsWrapper>
                </TabsOuterWrapper>
            </Desktop>
        </RouteContext.Provider>
    );
}

const TabsOuterWrapper = styled.div`
    position: sticky;
    top: 8rem;
    left: 0;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    min-width: 14rem;
`;
const TabsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;

    & a {
        margin-bottom: 0;
    }
`;
const Title = styled.h1`
    font-weight: var(--fw-bold);
    font-size: var(--fs-heading-m);
`;

export default enhance(Tabs);
