import Button from '@/components/Button';
import useCampaignQuery from '@/queries/campaign/useCampaignQuery';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useHistory, useParams } from 'react-router';
import { CampaignFormSchema } from '../useFormSchema';
import updateCampaignMutation from '@/queries/campaign/updateCampaignMutation';
import { CAMPAIGN_STATUS } from '@/shared/config/constants';
import { useBodyToastQueue } from '@/shared/hooks/useBodyToastQueue.hook';
import { TooltipTrigger } from 'react-aria-components';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';
import styled from 'styled-components';

const SaveButton = () => {
    const { campaignId, showId } = useParams<{ campaignId: string; showId: string }>();
    const { data: campaign } = useCampaignQuery(campaignId);
    const { formState, handleSubmit, watch, reset } = useFormContext<CampaignFormSchema>();
    const { isValid } = formState;
    const updateCampaign = updateCampaignMutation();
    const history = useHistory();
    const toast = useBodyToastQueue();
    const isActive =
        campaign?.state === CAMPAIGN_STATUS.PAUSED ||
        campaign?.state === CAMPAIGN_STATUS.ACTIVE ||
        campaign?.state === CAMPAIGN_STATUS.PLANIFIED;
    const hasAudioFile = !!campaign?.files?.[0];
    const campaignType = watch('general.type');
    const isDisabled = !isValid || (campaignType !== 'hostread' && !hasAudioFile);

    if (!isActive) return null;

    const handleSaveCampaign = handleSubmit((formData: CampaignFormSchema) => {
        const { general, duration, episodes } = formData;
        const { episodes: podcasts, autoOnNewPodcasts } = episodes;
        const data = {
            name: general.name,
            maxListens: duration.maxListens || null,
            podcasts,
            autoOnNewPodcasts,
        };
        updateCampaign.mutate(
            { campaignId, campaign: data },
            {
                onSuccess: () => {
                    reset(formData);
                    history.push(`/app/show/${showId}/monetize/manual`);
                },
                onError: () => {
                    toast.alert();
                },
            },
        );
    });

    return (
        <TooltipTrigger delay={0} closeDelay={0} isDisabled={!isDisabled}>
            <TooltipTriggerWrapperStyled>
                <Button
                    onPress={() => handleSaveCampaign()}
                    isDisabled={isDisabled}
                    isLoading={updateCampaign.isLoading}
                >
                    <FormattedMessage defaultMessage="Enregistrer et fermer" />
                </Button>
            </TooltipTriggerWrapperStyled>
            <Tooltip placement="bottom">
                {!hasAudioFile ? (
                    <FormattedMessage defaultMessage="Un fichier audio est requis pour publier la campagne" />
                ) : (
                    <FormattedMessage defaultMessage="Le formulaire contient des erreurs." />
                )}
            </Tooltip>
        </TooltipTrigger>
    );
};

const TooltipTriggerWrapperStyled = styled(TooltipTriggerWrapper)`
    & > * {
        width: 100%;
    }
`;

export default SaveButton;
