import styled from 'styled-components';
import { computeCssValue } from '../../../utils/css';

interface StackProps {
    $gap?: string | number;
    $alignItems?: 'flex-start' | 'start' | 'center' | 'flex-end' | 'end' | 'stretch' | 'baseline';
    $align?: 'flex-start' | 'start' | 'center' | 'flex-end' | 'end' | 'stretch' | 'baseline';
    $justifyContent?:
        | 'flex-start'
        | 'start'
        | 'center'
        | 'flex-end'
        | 'end'
        | 'space-between'
        | 'space-around'
        | 'space-evenly'
        | 'stretch'
        | 'baseline';
    $justify?:
        | 'flex-start'
        | 'start'
        | 'center'
        | 'flex-end'
        | 'end'
        | 'space-between'
        | 'space-around'
        | 'space-evenly'
        | 'stretch'
        | 'baseline';
    className?: string;
}

/**
 * Stack is a layout component that makes it easy to stack elements together and apply a space between them.
 *
 * Example:
 * <Stack>
 *     <h2>Some text</h2>
 *     <img src="path/to/some/image.svg" />
 *     <p>More text</p>
 * </Stack>
 *
 * References:
 * https://every-layout.dev/layouts/stack/
 * https://chakra-ui.com/docs/layout/stack
 * https://seek-oss.github.io/braid-design-system/components/Stack
 */
const Stack = styled.div<StackProps>`
    display: flex;
    flex-direction: column;
    gap: ${(props) => computeCssValue(props.$gap)};
    align-items: ${(props) => props.$alignItems ?? props.$align};
    justify-content: ${(props) => props.$justifyContent ?? props.$justify};
`;

export default Stack;
