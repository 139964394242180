import styled, { css } from 'styled-components';
import Center from '../../../../ui/layout/Center';
import Square from '@public/images/clip-edit-format-square.svg';
import SquareSelected from '@public/images/clip-edit-format-square-selected.svg';
import Story from '@public/images/clip-edit-format-story.svg';
import StorySelected from '@public/images/clip-edit-format-story-selected.svg';
import Landscape from '@public/images/clip-edit-format-landscape.svg';
import LandscapeSelected from '@public/images/clip-edit-format-landscape-selected.svg';
import { CLIP_FORMATS } from '../../../../../utils/constants';
import Stack from '../../../../ui/layout/Stack';

const getFormatImages = (type) => {
    const formats = {
        [CLIP_FORMATS.SQUARE]: [Square, SquareSelected],
        [CLIP_FORMATS.STORY]: [Story, StorySelected],
        [CLIP_FORMATS.LANDSCAPE]: [Landscape, LandscapeSelected],
    };

    return formats[type];
};

const Format = ({ type, selected, onSelect, width, children }) => {
    const [Image, ImageSelected] = getFormatImages(type);

    const StyledImage = styled(Image)`
        ${(props) =>
            props.onClick &&
            css`
                :hover {
                    border-color: #aca5a5;
                }
            `}
    `;

    const ImageComponent = selected ? ImageSelected : StyledImage;

    const StyledImageComponent = styled(ImageComponent)`
        // Allow images to shrink to half their size to fit available space
        height: auto;
        max-width: 100%;
        min-width: ${(props) => css`calc(${props.width}px / 2)`};

        border: 1px solid transparent;
        border-radius: var(--r-xs);
        transition: border-color 0.5s;
        ${(props) =>
            props.onClick &&
            css`
                cursor: pointer;
            `}
    `;

    return (
        <Stack $gap="1rem">
            <StyledImageComponent
                onClick={onSelect ? () => onSelect(type) : null}
                width={width}
                height={300}
            />
            <Center max="100%" intrinsic>
                {children}
            </Center>
        </Stack>
    );
};

export default Format;
