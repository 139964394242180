import Text from '@/components/ui/atoms/Text';
import useQuery from '@/shared/hooks/useQuery';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const NonHostingEpisodeFormLoader = () => {
    const query = useQuery();
    const name = query.get('name');

    return (
        <Container>
            <Wrapper>
                <SpinnerIcon icon={icon({ name: 'spinner-third', style: 'solid' })} spin />
                <InnerWrapper>
                    <Text variant="headingS" color="--neutral500">
                        <FormattedMessage defaultMessage="Votre épisode sera bientôt prêt à être optimisé dans votre espace de travail." />
                    </Text>
                    <Text variant="headingL" fontWeight="--fw-bold">
                        {name}
                    </Text>
                </InnerWrapper>
            </Wrapper>
        </Container>
    );
};

const Container = styled.section`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
`;
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    align-items: center;
`;
const SpinnerIcon = styled(FontAwesomeIcon)`
    width: 7.5rem;
    height: 7.5rem;
    color: var(--primary);
`;
const InnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    text-align: center;
`;

export default NonHostingEpisodeFormLoader;
