import { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { reflex } from '@ui/reflex';
import CalendarIcon from '@public/icons/calendar.svg';
import CheckIcon from '@public/icons/check.svg';
import EyeIcon from '@public/icons/eye.svg';
import posed, { PoseGroup } from 'react-pose';
import { FormattedMessage } from 'react-intl';
import DeprecatedIcon from '../../atoms/DeprecatedIcon';
import { resolveColor } from '@/shared/utils/cssVariable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon as iconModule } from '@fortawesome/fontawesome-svg-core/import.macro';

const iconStatusStyles = css`
    width: 35px;
    height: 35px;
    border-radius: var(--r-xs);
`;

const StatusBlock = styled.span.withConfig({
    shouldForwardProp: (p, defaultValidatorFn) => !['value'].includes(p) && defaultValidatorFn(p),
})`
    font-weight: var(--fw-normal);
    font-size: var(--fs-body);
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    position: relative;
    color: ${(p) => resolveColor(p.color)};
    background-color: ${(p) => resolveColor(`${p.color}50`)};
    gap: 4px;
    width: max-content;
    min-height: 24px;
    border-radius: var(--r-full);
    padding: 0 8px;
    svg {
        fill: ${(p) => resolveColor(p.color)};
    }
    ${(p) => (p.icon ? iconStatusStyles : undefined)};
`;

const StatusHover = styled(
    posed.span({
        enter: { opacity: 1, transition: { duration: 300 }, height: 30, y: 0 },
        exit: { opacity: 0, transition: { duration: 150 }, height: 0, y: -10 },
    }),
)`
    padding: 5px 10px;
    display: flex;
    background: var(--white);
    position: absolute;
    top: calc(100% + 10px);
    border-radius: var(--r-xs);
    overflow: hidden;
    left: 0;
    &:after {
        content: '';
        background: ${(p) => resolveColor(p.color)};
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
`;

const statusLookup = {
    online: {
        icon: (
            <FontAwesomeIcon
                icon={iconModule({ name: 'eye', style: 'solid' })}
                color="inherit"
                size="xs"
            />
        ),
        hoverMessage: <FormattedMessage defaultMessage="Épisode en ligne" />,
        color: '--success',
    },
    active: {
        icon: <DeprecatedIcon as={EyeIcon} size={16} />,
        hoverMessage: <FormattedMessage defaultMessage="Épisode en ligne" />,
        color: '--success',
    },
    published: {
        icon: (
            <FontAwesomeIcon
                icon={iconModule({ name: 'circle-check', style: 'solid' })}
                color="inherit"
                size="xs"
            />
        ),
        hoverMessage: null,
        color: '--success',
    },
    done: {
        icon: (
            <FontAwesomeIcon
                icon={iconModule({ name: 'circle-check', style: 'solid' })}
                color="inherit"
                size="xs"
            />
        ),
        hoverMessage: null,
        color: '--success',
    },
    publish_at: {
        icon: (
            <FontAwesomeIcon
                icon={iconModule({ name: 'clock', style: 'solid' })}
                color="inherit"
                size="xs"
            />
        ),
        hoverMessage: null,
        color: '--info',
    },
    publish_now: {
        icon: (
            <FontAwesomeIcon
                icon={iconModule({ name: 'clock', style: 'solid' })}
                color="inherit"
                size="xs"
            />
        ),
        hoverMessage: null,
        color: '--info',
    },
    planned: {
        icon: (
            <FontAwesomeIcon
                icon={iconModule({ name: 'clock', style: 'solid' })}
                color="inherit"
                size="xs"
            />
        ),
        hoverMessage: <FormattedMessage defaultMessage="Épisode planifié" />,
        color: '--info',
    },
    planified: {
        icon: <DeprecatedIcon as={CalendarIcon} size={16} />,
        hoverMessage: <FormattedMessage defaultMessage="Épisode planifié" />,
        color: '--info',
    },
    blocked: {
        icon: (
            <FontAwesomeIcon
                icon={iconModule({ name: 'circle-exclamation', style: 'solid' })}
                color="inherit"
                size="xs"
            />
        ),
        hoverMessage: <FormattedMessage defaultMessage="Épisode bloqué" />,
        color: '--alert',
    },
    ended: {
        icon: (
            <FontAwesomeIcon
                icon={iconModule({ name: 'circle-check', style: 'solid' })}
                color="inherit"
                size="xs"
            />
        ),
        hoverMessage: <FormattedMessage defaultMessage="Épisode terminé" />,
        color: '--primary',
    },
    finished: {
        icon: <DeprecatedIcon as={CheckIcon} size={16} />,
        hoverMessage: <FormattedMessage defaultMessage="Épisode terminé" />,
        color: '--primary',
    },
    draft: {
        icon: (
            <FontAwesomeIcon
                icon={iconModule({ name: 'pen-line', style: 'solid' })}
                color="inherit"
                size="xs"
            />
        ),
        hoverMessage: <FormattedMessage defaultMessage="Épisode en brouillon" />,
        color: '--neutral',
    },
    unlisted: {
        icon: (
            <FontAwesomeIcon
                icon={iconModule({ name: 'link', style: 'solid' })}
                color="inherit"
                size="xs"
            />
        ),
        hoverMessage: <FormattedMessage defaultMessage="Épisode non-listé" />,
        color: '--warning',
    },
    error: {
        icon: (
            <FontAwesomeIcon
                icon={iconModule({ name: 'circle-exclamation', style: 'solid' })}
                color="inherit"
                size="xs"
            />
        ),
        hoverMessage: null,
        color: '--alert',
    },
    private: {
        icon: (
            <FontAwesomeIcon
                icon={iconModule({ name: 'eye-slash', style: 'solid' })}
                color="inherit"
                size="xs"
            />
        ),
        hoverMessage: <FormattedMessage defaultMessage="Épisode privé" />,
        color: '--alert',
    },
    public: {
        icon: (
            <FontAwesomeIcon
                icon={iconModule({ name: 'play', style: 'solid' })}
                color="inherit"
                size="xs"
            />
        ),
        hoverMessage: null,
        color: '--success',
    },
    paused: {
        icon: (
            <FontAwesomeIcon
                icon={iconModule({ name: 'pause', style: 'solid' })}
                color="inherit"
                size="xs"
            />
        ),
        hoverMessage: <FormattedMessage defaultMessage="Campagne en pause" />,
        color: '--warning',
    },
};

function Status({ value, icon, children }) {
    const [hovered, onHover] = useState(false);

    return (
        <StatusBlock
            icon={icon}
            color={statusLookup[value]?.color ?? '--primary'}
            onMouseEnter={() => onHover(true)}
            onMouseLeave={() => onHover(false)}
        >
            {statusLookup[value]?.icon ?? null}
            {icon && hovered && (
                <PoseGroup>
                    {hovered && (
                        <StatusHover value={value} key={0}>
                            {statusLookup[value]?.hoverMessage ?? ''}
                        </StatusHover>
                    )}
                </PoseGroup>
            )}
            {!icon && children}
        </StatusBlock>
    );
}

Status.propTypes = {
    value: PropTypes.oneOf([
        'online',
        'published',
        'done',
        'publish_at',
        'planned',
        'blocked',
        'ended',
        'draft',
        'unlisted',
        'error',
        'private',
        'public',
        'finished',
        'active',
        'planified',
        'paused',
    ]).isRequired,
    icon: PropTypes.bool,
    children: PropTypes.node,
};

Status.defaultProps = {
    icon: false,
    children: undefined,
};

export default reflex(Status);
