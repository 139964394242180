import PropTypes from 'prop-types';
import CircularProgress from '@ui/atoms/CircularProgress';
import styled from 'styled-components';

const Spinner = (props) => {
    return (
        <SpinningCircularProgress
            {...props}
            progressColor={props.color}
            backgroundColor="none"
            percentage={33}
            strokeWidth={props.strokeWidth || props.size / 8}
        />
    );
};

const SpinningCircularProgress = styled(CircularProgress)`
    animation-name: spin;
    animation-duration: 750ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease;

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`;

Spinner.propTypes = {
    size: PropTypes.number,
    strokeWidth: PropTypes.number,
    color: PropTypes.string,
};

Spinner.defaultProps = {
    size: 16,
    strokeWidth: 2,
    color: '--primary',
};

export default Spinner;
