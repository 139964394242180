import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Text from '@ui/atoms/Text';
import Button from '@ui/atoms/Button';
import styled from 'styled-components';
import { UI_VARIANTS } from '@/shared/config/constants';
import DialogAlert from '@ui/atoms/DialogAlert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import YoutubeIcon from '@public/images/platforms/YouTube-full.svg';
import SoundcloudIcon from '@public/images/platforms/SoundCloud-full.svg';
import Stack from '@ui/layout/Stack';
import Cluster from '@ui/layout/Cluster';
import { useFormContext } from 'react-hook-form';
import TranscriptionSummary from './TranscriptionSummary';
import PsoCheckerSummary from './PsoCheckerSummary';
import usePsoQuery from '@queries/pso/usePsoQuery.hook';
import { useParams } from 'react-router';
import useHasPsoFullUserOption from '@/shared/hooks/useHasPsoFullUserOption.hook';

const EpisodePublishModal = ({ isOpen, onOpenChange, isLoading, handlePublishEpisode }) => {
    const { showId } = useParams();
    const hasAccessToPsoChecker = useHasPsoFullUserOption({ showId });
    const psoConfiguration = usePsoQuery({ showId, enabled: true });
    const { watch } = useFormContext();
    const psoCheckerEnabled = hasAccessToPsoChecker && psoConfiguration.data?.fullyConfigured;
    const willBePublishedOnYoutube = watch('options.publishYoutube');
    const willBePublishedOnSoundcloud = watch('options.soundcloudPublish');
    const willBePublishedOnPlatform = willBePublishedOnYoutube || willBePublishedOnSoundcloud;

    return (
        <DialogAlert
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            action={
                <>
                    <Button
                        isLoading={isLoading}
                        onPress={handlePublishEpisode}
                        data-testid="episode-form-confirm-dialog-publish-now-button"
                    >
                        <FormattedMessage defaultMessage="Publier l’épisode" />
                    </Button>
                    <CancelButton onPress={() => onOpenChange(false)} variant="ghost">
                        <FormattedMessage defaultMessage="Annuler" />
                    </CancelButton>
                </>
            }
            variant={UI_VARIANTS.PRIMARY}
            title={<FormattedMessage defaultMessage="3, 2, 1... Prêt à publier votre épisode ?" />}
            icon={<FontAwesomeIcon icon={icon({ name: 'rocket-launch', style: 'solid' })} />}
        >
            <Stack $gap="0.5rem">
                <Text variant="bodyM" color="--neutral500" textAlign="center">
                    <FormattedMessage defaultMessage="Vous pourrez toujours y apporter des modifications plus tard." />
                </Text>
                <TranscriptionSummary />
                {psoCheckerEnabled && <PsoCheckerSummary />}
                {willBePublishedOnPlatform && (
                    <PublishOnPlatformAlert>
                        <Text variant="bodyM" fontWeight="--fw-semibold">
                            <FormattedMessage defaultMessage="L’épisode sera également publié sur" />
                        </Text>
                        <Cluster $gap="1rem" $align="center">
                            {willBePublishedOnYoutube && <StyledYoutubeIcon />}
                            {willBePublishedOnSoundcloud && <SoundcloudIcon />}
                        </Cluster>
                    </PublishOnPlatformAlert>
                )}
            </Stack>
        </DialogAlert>
    );
};

const StyledYoutubeIcon = styled(YoutubeIcon)`
    height: 1.25rem;
`;

const CancelButton = styled(Button)`
    color: var(--neutral500);
    padding: 0;
`;
const PublishOnPlatformAlert = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 0.5rem;
    padding: 1rem;
    background-color: var(--neutral50);
    border-radius: var(--r-m);
`;

EpisodePublishModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onOpenChange: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    handlePublishEpisode: PropTypes.func,
};

EpisodePublishModal.defaultProps = {
    isOpen: false,
    isLoading: false,
    onOpenChange: () => {},
    handlePublishEpisode: () => {},
};

export default EpisodePublishModal;
