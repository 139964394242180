import PropTypes from 'prop-types';
import Notebook from '@public/images/notebook.png';
import Button from '@ui/atoms/Button';
import Text from '@ui/atoms/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FormattedMessage } from 'react-intl';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import { sendAmplitudeLogEvent } from '@/helpers';
import styled from 'styled-components';

const guideLinkLookup = {
    fr: 'https://help.ausha.co/fr/collections/6346368-optimiser-le-referencement-de-mon-podcast-podcast-search-optimisation',
    en: 'https://help.ausha.co/en/collections/6346368-boost-my-podcast-s-visibility-podcast-search-optimization',
};

const PsoGuide = ({ onClosedGuide }) => {
    const { data: user } = useUserQuery();
    const guideLink = guideLinkLookup[user?.language || 'en'];

    return (
        <GuideWrapper>
            <InnerWrapper>
                <TitleWrapper>
                    <Title>
                        <FormattedMessage defaultMessage="Comment optimiser le référencement de votre podcast ?" />
                    </Title>
                    <Description>
                        <FormattedMessage
                            defaultMessage="Pour améliorer votre <strong>visibilité</strong>, il est important d’être bien positionné sur les recherches réalisées par vos futurs auditeurs. Avec le <strong>PSO Control Panel</strong> vous pouvez suivre la <strong>position</strong> de votre podcast dans les résultats de recherche des plateformes d’écoute (Apple Podcasts et Spotify)."
                            values={{
                                strong: (chunks) => (
                                    <Text fontWeight="--fw-semibold" as="strong">
                                        {chunks}
                                    </Text>
                                ),
                            }}
                        />
                    </Description>
                </TitleWrapper>
                <FollowTheGuideButton
                    forwardedAs="a"
                    href={guideLink}
                    onClick={() => {
                        sendAmplitudeLogEvent('PSO Guidebook');
                    }}
                    target="_blank"
                >
                    <FormattedMessage defaultMessage="Suivez le guide" />
                </FollowTheGuideButton>
            </InnerWrapper>
            <NotebookIllustration src={Notebook} alt="Notebook" />
            <CloseButton variant="ghost" color="--primary" onPress={() => onClosedGuide()}>
                <FontAwesomeIcon icon={icon({ name: 'xmark', style: 'solid' })} />
            </CloseButton>
        </GuideWrapper>
    );
};

const GuideWrapper = styled.div`
    display: flex;
    background: var(--gradient-dusk);
    position: relative;
    padding: 1.5rem;
`;
const InnerWrapper = styled.div`
    align-self: stretch;
    padding-right: 13rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;
const Title = styled(Text)`
    font-size: var(--fs-heading-s);
    font-weight: var(--fw-bold);
`;
const Description = styled(Text)``;
const FollowTheGuideButton = styled(Button)`
    width: fit-content;
`;
const NotebookIllustration = styled.img`
    position: absolute;
    width: 15rem;
    bottom: 0;
    right: 0;
`;
const CloseButton = styled(Button)`
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    background: none;
    font-size: 1rem;
`;

PsoGuide.propTypes = {
    onClosedGuide: PropTypes.func.isRequired,
};

export default PsoGuide;
