import styled from 'styled-components';

const DownloadsComparisonChartEpisodeMarker = styled.div<{ color: string; size: string }>`
    background-color: ${(props) => props.color};
    border-radius: 50%;
    flex-shrink: 0;
    height: ${(props) => props.size};
    width: ${(props) => props.size};
`;

export default DownloadsComparisonChartEpisodeMarker;
