import { buildQuery } from '../query';
import type { PlayerIframeSettings } from '@/queries/playerIframe/usePlayerIframeQuery.hook';
import axios, { Method } from 'axios';

const basePath = process.env.API_PUBLIC as string;

const publicInstance = axios.create({
    baseURL: basePath,
    timeout: 1000,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json, text/plain',
    },
});

export const fetchPlayerIframeCode = async (query: PlayerIframeSettings) => {
    const config = {
        url: `/iframe${buildQuery(query)}`,
        method: 'GET' as Method,
    };

    const res = await publicInstance.request(config);
    return { ...res.data, axios: res };
};
export const fetchShowBySlug = async (slug: string) => {
    const config = {
        url: `/v1/shows/slug/${slug}`,
        method: 'GET' as Method,
    };

    const res = await publicInstance.request(config);
    return { ...res.data, axios: res };
};
