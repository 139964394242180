import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled, { css } from 'styled-components';

const SemiCircularTrend = ({ figure, trend, rankDiff }) => {
    const trendIconLookup = {
        down: (
            <FontAwesomeIcon
                icon={icon({ name: 'arrow-trend-down', style: 'solid' })}
                color="inherit"
                size="1x"
            />
        ),
        up: (
            <FontAwesomeIcon
                icon={icon({ name: 'arrow-trend-up', style: 'solid' })}
                color="inherit"
                size="1x"
            />
        ),
        neutral: (
            <FontAwesomeIcon
                icon={icon({ name: 'minus', style: 'solid' })}
                color="inherit"
                size="1x"
            />
        ),
    };

    return (
        <SemiCircularTrendWrapper>
            {figure ? (
                <>
                    <Figure>{figure}</Figure>
                    <TrendWrapper trendDirection={trend}>
                        {trendIconLookup[trend]}
                        <TrendText>{rankDiff}</TrendText>
                    </TrendWrapper>
                </>
            ) : (
                <NoData />
            )}
        </SemiCircularTrendWrapper>
    );
};

const SemiCircularTrendWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0.75rem;
`;
const Figure = styled.span`
    font-size: var(--fs-heading-l);
    font-weight: var(--fw-bold);
    line-height: 1.2;
`;
const TrendWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;

    ${({ trendDirection }) => css`
        color: ${trendDirection === 'up'
            ? 'var(--success500)'
            : trendDirection === 'down'
            ? 'var(--alert500)'
            : 'var(--neutral500)'};
    `};
`;
const TrendText = styled.span`
    font-size: var(--fs-body-s);
    font-weight: var(--fw-semibold);
`;
const NoData = styled.div`
    width: 1.25rem;
    height: 0.25rem;
    background-color: var(--neutral500);
    border-radius: var(--r-full);
`;

SemiCircularTrend.propTypes = {
    figure: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    trend: PropTypes.oneOf(['up', 'down', 'neutral']),
    rankDiff: PropTypes.number,
};

SemiCircularTrend.defaultProps = {
    figure: 0,
    trend: 'up',
    rankDiff: 0.0,
};

export default SemiCircularTrend;
