import { css } from 'styled-components';
import { BaseLinkProps } from '../types';

export const linkLayoutStyles = css<{ $size: BaseLinkProps['size'] }>`
    display: inline-flex;
    align-items: center;
    column-gap: ${({ $size }) => ($size === 'large' ? '0.5rem' : '0.25rem')};
    cursor: pointer;
`;

export const linkDisabledStyles = css`
    &[data-disabled='true'] {
        pointer-events: none;
        opacity: 0.5;
        box-shadow: none;
    }
`;

export const linkFocusStyles = css`
    &[data-focus-visible] {
        box-shadow: 0px 0px 0px 4px rgba(117, 28, 191, 0.1);
    }
`;
