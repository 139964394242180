import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import { useResponsive } from '@/shared/hooks/useResponsive';
import AushaLogo from '@ui/atoms/AushaLogo';

const SubscriptionFeedback = ({ children }) => {
    const { isDesktop } = useResponsive();
    return (
        <DeprecatedPaper h="100vh" px={20}>
            <DeprecatedText
                as="a"
                mt={isDesktop ? 50 : 20}
                w={90}
                z={1}
                href={process.env.URL_AUSHA}
                pos="absolute"
            >
                <AushaLogo isComplete isColored />
            </DeprecatedText>
            <DeprecatedPaper
                flex
                column
                maxW={505}
                justify="center"
                align="center"
                h="100%"
                m="auto"
            >
                {children}
            </DeprecatedPaper>
        </DeprecatedPaper>
    );
};

export default SubscriptionFeedback;
