import { useParams } from 'react-router';
import { FormattedMessage } from 'react-intl';
import useShowQuery from '@queries/show/useShowQuery.hook';
import styled from 'styled-components';
import { sendAmplitudeLogEvent } from '@/shared/utils/amplitude';
import Text from '../ui/atoms/Text';
import InputText from '../ui/atoms/InputText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import ExternalLink from '../Link/ExternalLink';

const RssExportBlock = () => {
    const { showId } = useParams();
    const { data: show } = useShowQuery(showId);

    if (!show) {
        return null;
    }

    return (
        <>
            <Text variant="headingM" fontWeight="--fw-bold">
                <FormattedMessage defaultMessage="Export RSS" />
            </Text>
            <Text color="--neutral500">
                <FormattedMessage defaultMessage="Votre lien RSS est utile lorsque vous souhaitez publier votre émission sur d’autres plateformes." />
            </Text>
            <RssExportWrapper>
                <RssLinkWrapper>
                    <InputText
                        value={show?.rssLink || ''}
                        label={<FormattedMessage defaultMessage="Lien du flux RSS de l'émission" />}
                        isReadOnly
                        copyToClipboardEnabled
                        onCopyToClipboard={() => sendAmplitudeLogEvent('RSS Feed Export Clicked')}
                    />
                    <ExternalLink
                        startIcon={<EyeIcon icon={icon({ name: 'eye', style: 'solid' })} />}
                        variant="button-tertiary"
                        href={show?.rssLink || ''}
                        target="_blank"
                    >
                        <FormattedMessage defaultMessage="Aperçu" />
                    </ExternalLink>
                </RssLinkWrapper>
            </RssExportWrapper>
        </>
    );
};

const RssExportWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding);
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    ${({ theme }) => theme.mediaQueries.mobile} {
        padding: var(--container-padding-mobile);
    }
`;
const RssLinkWrapper = styled.div`
    display: flex;
    align-items: flex-end;
    column-gap: 0.5rem;

    & > :first-child {
        flex-grow: 1;
    }

    & > :last-child {
        flex-shrink: 0;
    }
`;
const EyeIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;

export default RssExportBlock;
