import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Text from '@ui/atoms/Text';
import Button from '@ui/atoms/Button';
import useSocialNetworkCalendarPostDraftQuery from '@/queries/socialNetworkPost/useSocialNetworkCalendarPostDraftQuery.hook';
import useSocialNetworkCalendarPostScheduledQuery from '@/queries/socialNetworkPost/useSocialNetworkCalendarPostScheduledQuery.hook';
import SubListSkeleton from './SubListSkeleton';
import SidebarItem from './SidebarItem';
import styled, { css } from 'styled-components';

const DraftPostsEmptyState = () => (
    <DraftPostsEmptyStateBlock>
        <SnoozeIcon icon={icon({ name: 'snooze', style: 'solid' })} />
        <Text fontWeight="--fw-semibold" color="--neutral500">
            <FormattedMessage defaultMessage="Aucun brouillon" />
        </Text>
    </DraftPostsEmptyStateBlock>
);

const CalendarSidebar = () => {
    const { showId } = useParams();
    const [showDrafts, setShowDrafts] = useState(true);
    const [showPlanned, setShowPlanned] = useState(false);
    const [showPostDropdown, setShowPostDropdown] = useState(null);
    const draftPosts = useSocialNetworkCalendarPostDraftQuery({ showId });
    const scheduledPosts = useSocialNetworkCalendarPostScheduledQuery({ showId });

    return (
        <CalendarSidebarWrapper>
            {draftPosts.isLoading || draftPosts.isError ? (
                <SubListSkeleton />
            ) : (
                <PostSection>
                    <ToggleSectionButton onPress={() => setShowDrafts(!showDrafts)}>
                        <SectionHeader>
                            <SectionHeaderContent>
                                <SectionIcon
                                    icon={icon({ name: 'pen-to-square', style: 'solid' })}
                                    color="--warning"
                                />
                                <SectionTitle>
                                    <FormattedMessage defaultMessage="Brouillons" />
                                </SectionTitle>

                                <SectionCount>{draftPosts.data.length}</SectionCount>
                            </SectionHeaderContent>
                            {showDrafts ? (
                                <ChevronIcon icon={icon({ name: 'chevron-up', style: 'solid' })} />
                            ) : (
                                <ChevronIcon
                                    icon={icon({ name: 'chevron-down', style: 'solid' })}
                                />
                            )}
                        </SectionHeader>
                    </ToggleSectionButton>
                    {showDrafts &&
                        (draftPosts.data.length > 0 ? (
                            <ItemList>
                                {draftPosts.data.map((post) => (
                                    <SidebarItem
                                        key={post.id}
                                        post={post}
                                        showPostDropdown={showPostDropdown}
                                        setShowPostDropdown={(s) => setShowPostDropdown(s)}
                                        isDraft={true}
                                    />
                                ))}
                            </ItemList>
                        ) : (
                            <DraftPostsEmptyState />
                        ))}
                </PostSection>
            )}
            {scheduledPosts.isLoading || scheduledPosts.isError ? (
                <SubListSkeleton />
            ) : scheduledPosts.data.length > 0 ? (
                <PostSection>
                    <ToggleSectionButton onPress={() => setShowPlanned(!showPlanned)}>
                        <SectionHeader>
                            <SectionHeaderContent>
                                <SectionIcon
                                    icon={icon({ name: 'clock', style: 'solid' })}
                                    color="--info"
                                />
                                <SectionTitle>
                                    <FormattedMessage defaultMessage="Planifiés" />
                                </SectionTitle>

                                <SectionCount>{scheduledPosts.data.length}</SectionCount>
                            </SectionHeaderContent>
                            {showPlanned ? (
                                <ChevronIcon icon={icon({ name: 'chevron-up', style: 'solid' })} />
                            ) : (
                                <ChevronIcon
                                    icon={icon({ name: 'chevron-down', style: 'solid' })}
                                />
                            )}
                        </SectionHeader>
                    </ToggleSectionButton>
                    {showPlanned && (
                        <ItemList>
                            {scheduledPosts.data.map((post) => (
                                <SidebarItem
                                    key={post.id}
                                    post={post}
                                    showPostDropdown={showPostDropdown}
                                    setShowPostDropdown={(s) => setShowPostDropdown(s)}
                                />
                            ))}
                        </ItemList>
                    )}
                </PostSection>
            ) : null}
        </CalendarSidebarWrapper>
    );
};

const CalendarSidebarWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 2rem;
    padding-right: 0;
    width: 19rem;

    &::-webkit-scrollbar-track {
        background-color: var(--neutral50);
        border-radius: var(--r-full);
    }
    &::-webkit-scrollbar {
        width: 0.25rem;
    }
    &::-webkit-scrollbar-thumb {
        background-color: var(--neutral200);
        border-radius: var(--r-full);
    }
    &::-webkit-scrollbar-thumb:hover {
        background-color: var(--neutral300);
    }
`;
const PostSection = styled.section`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;
const SectionHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
    border-radius: var(--r-s);

    &:hover {
        background-color: var(--neutral50);
    }
`;
const SectionHeaderContent = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;
const SectionIcon = styled(FontAwesomeIcon)`
    color: ${({ color }) => color && `var(${color})`};
    font-size: 1rem;
`;
const SectionTitle = styled(Text)`
    font-weight: var(--fw-semibold);
    color: var(--black);
    font-size: var(--fs-body-l);
`;
const SectionCount = styled(Text)`
    color: var(--neutral500);
`;
const UnstyledButton = css`
    background-color: transparent;
    box-shadow: none;
    border: none;
    padding: 0;
    display: block;
`;
const ToggleSectionButton = styled(Button)`
    ${UnstyledButton};
    width: 100%;
`;
const ChevronIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
    font-size: 1rem;
`;
const ItemList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;
const DraftPostsEmptyStateBlock = styled.div`
    background-color: var(--neutral50);
    border-radius: var(--r-m);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    row-gap: 0.75rem;
    align-items: center;
`;
const SnoozeIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
    width: 1.5rem;
    height: 1.5rem;
`;

export default CalendarSidebar;
