import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import type { Platform, Mode } from '@/api/pso/types';
import usePsoLiveSearchQuery from '@queries/pso/usePsoLiveSearchQuery.hook';
import { getCountryISO3 } from '@/utils/country';
import Button from '@/components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Kpi from './Kpi';
import { useModalToastQueue } from '@/utils/hooks/useModalToastQueue.hook';
import useKeywordQuotaQuery from '@queries/pso/useKeywordQuotaQuery.hook';
import useFollowKeywordMutation from '@queries/psoChecker/useFollowKeywordMutation.hook';
import usePsoQuery from '@queries/pso/usePsoQuery.hook';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';
import { TooltipTrigger } from 'react-aria-components';
import styled from 'styled-components';

interface KpisProps {
    searchParams: {
        keyword: string;
        platform: Platform;
        store: string;
        mode: Mode;
    };
}

const Kpis = ({ searchParams }: KpisProps) => {
    const { showId } = useParams<{
        showId: string;
    }>();
    const psoConfiguration = usePsoQuery({ showId, enabled: true });
    const { keyword, platform, store, mode } = searchParams;
    const { data: keywordQuota } = useKeywordQuotaQuery({ showId });
    const toast = useModalToastQueue();
    const followKeyword = useFollowKeywordMutation();

    const enabled = !!showId && !!platform && !!store && !!mode;
    const { data: liveSearchResults, isLoading } = usePsoLiveSearchQuery(
        {
            showId,
            keyword,
            platform,
            store: getCountryISO3(store),
            mode,
        },
        { enabled },
    );

    const topRankIndex = liveSearchResults?.items?.findIndex((item) => item?.ownedByShow);
    const rank = (topRankIndex ?? -1) !== -1 ? (topRankIndex ?? -1) + 1 : -1;
    const resultCount = liveSearchResults?.kpis?.resultCount || 0;
    const searchVolume = liveSearchResults?.kpis?.searchVolume || 0;
    const difficultyScore = liveSearchResults?.kpis?.difficultyScore || 0;

    const checkIsFollowed = (keyword: string): boolean => {
        return psoConfiguration.data.keywords.includes(keyword);
    };

    const handleFollow = (keyword: string) => {
        if (keywordQuota.reached) {
            toast.alert(
                <FormattedMessage defaultMessage="Vous avez atteint la limite de votre quota de mots-clés. Pour en ajouter d’avantage, libérez de l’espace depuis votre PSO Control Panel." />,
            );
            return;
        }
        followKeyword.mutate(
            { showId, keyword },
            {
                onSuccess: () => {
                    toast.success(
                        <FormattedMessage
                            defaultMessage={`Le mot-clé "{keyword}" a bien été ajouté à votre liste de mots-clés du PSO Control Panel. 👌`}
                            values={{
                                keyword,
                            }}
                        />,
                    );
                },
            },
        );
    };

    return (
        <OuterKpisWrapper>
            <SectionHeader>
                <SectionTitle>
                    <FormattedMessage
                        defaultMessage={`Aperçu pour "{keyword}"`}
                        values={{ keyword }}
                    />
                </SectionTitle>
                <FollowKeywordWrapper>
                    {checkIsFollowed(keyword) ? (
                        <FollowedKeyword>
                            <FontAwesomeIcon icon={icon({ name: 'check', style: 'solid' })} />
                            <FormattedMessage defaultMessage="Vous suivez ce mot-clé" />
                        </FollowedKeyword>
                    ) : (
                        <FollowKeywordButton
                            variant="secondary"
                            onPress={() => handleFollow(keyword)}
                            startIcon={
                                <FontAwesomeIcon icon={icon({ name: 'plus', style: 'solid' })} />
                            }
                            size="small"
                        >
                            <FormattedMessage defaultMessage="Suivre le mot-clé" />
                        </FollowKeywordButton>
                    )}
                </FollowKeywordWrapper>
            </SectionHeader>
            <KpisWrapper>
                <KpiOuter>
                    <TooltipTrigger delay={0} closeDelay={0} isDisabled={rank > -1}>
                        <TooltipTriggerWrapperWithSpacing>
                            <Kpi
                                title={<FormattedMessage defaultMessage="Votre classement" />}
                                isLoading={isLoading}
                            >
                                {rank > 0 && rank <= 3 && (
                                    <AwardIcon
                                        rank={rank}
                                        icon={icon({ name: 'award', style: 'solid' })}
                                    />
                                )}
                                <Value>
                                    {rank === -1 ? (
                                        <UnrankedWrapper>
                                            <FormattedMessage
                                                defaultMessage="Non classé"
                                                id="unranked"
                                            />
                                        </UnrankedWrapper>
                                    ) : (
                                        rank
                                    )}
                                </Value>
                            </Kpi>
                        </TooltipTriggerWrapperWithSpacing>
                        <Tooltip placement="top">
                            <FormattedMessage defaultMessage="Votre podcast n'est actuellement pas classé dans le top 100. Continuez vos efforts pour grimper dans le classement ! 🔥" />
                        </Tooltip>
                    </TooltipTrigger>
                </KpiOuter>
                <KpiOuter>
                    <TooltipTrigger delay={0} closeDelay={0}>
                        <TooltipTriggerWrapperWithSpacing>
                            <Kpi
                                title={<FormattedMessage defaultMessage="Nbr. de résultats" />}
                                isLoading={isLoading}
                            >
                                <Value>{resultCount}</Value>
                                {resultCount >= 60 ? (
                                    <Label color="--success">
                                        <FormattedMessage defaultMessage="Beaucoup" />
                                    </Label>
                                ) : resultCount >= 25 ? (
                                    <Label color="--warning">
                                        <FormattedMessage defaultMessage="Moyen" />
                                    </Label>
                                ) : (
                                    <Label color="--alert">
                                        <FormattedMessage defaultMessage="Faible" />
                                    </Label>
                                )}
                            </Kpi>
                        </TooltipTriggerWrapperWithSpacing>
                        <Tooltip placement="top">
                            <FormattedMessage defaultMessage="Le nombre de résultats indique combien de podcasts apparaissent sur les plateformes d'écoute pour ce mot-clé. Le nombre maximal de résultats affichés est de 100." />
                        </Tooltip>
                    </TooltipTrigger>
                </KpiOuter>
                <KpiOuter>
                    <TooltipTrigger delay={0} closeDelay={0}>
                        <TooltipTriggerWrapperWithSpacing>
                            <Kpi
                                title={<FormattedMessage defaultMessage="Vol. de Recherche" />}
                                isLoading={isLoading}
                            >
                                <Value>{searchVolume}</Value>
                                {searchVolume >= 61 ? (
                                    <Label color="--success">
                                        <FormattedMessage defaultMessage="Beaucoup" />
                                    </Label>
                                ) : searchVolume >= 21 ? (
                                    <Label color="--warning">
                                        <FormattedMessage defaultMessage="Moyen" />
                                    </Label>
                                ) : (
                                    <Label color="--alert">
                                        <FormattedMessage defaultMessage="Faible" />
                                    </Label>
                                )}
                            </Kpi>
                        </TooltipTriggerWrapperWithSpacing>
                        <Tooltip placement="top">
                            <FormattedMessage defaultMessage="Le volume de recherche est un indicateur compris entre 5 et 100 qui montre la popularité d'un mot-clé en termes de recherches. Plus le nombre est élevé, plus le mot-clé est populaire." />
                        </Tooltip>
                    </TooltipTrigger>
                </KpiOuter>
                <KpiOuter>
                    <TooltipTrigger delay={0} closeDelay={0}>
                        <TooltipTriggerWrapperWithSpacing>
                            <Kpi
                                title={<FormattedMessage defaultMessage="Score de difficulté" />}
                                isLoading={isLoading}
                            >
                                <Value>{difficultyScore}</Value>
                                {difficultyScore >= 71 ? (
                                    <Label color="--alert">
                                        <FormattedMessage defaultMessage="Difficile" />
                                    </Label>
                                ) : difficultyScore >= 31 ? (
                                    <Label color="--warning">
                                        <FormattedMessage defaultMessage="Moyen" />
                                    </Label>
                                ) : (
                                    <Label color="--success">
                                        <FormattedMessage defaultMessage="Facile" />
                                    </Label>
                                )}
                            </Kpi>
                        </TooltipTriggerWrapperWithSpacing>
                        <Tooltip placement="top">
                            <FormattedMessage defaultMessage="Le score de difficulté est un indicateur entre 0 et 100 qui indique le niveau de concurrence d'un mot-clé. Plus le chiffre est élevé, plus la concurrence est forte." />
                        </Tooltip>
                    </TooltipTrigger>
                </KpiOuter>
            </KpisWrapper>
        </OuterKpisWrapper>
    );
};

const OuterKpisWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;
const SectionHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 0.75rem;
`;
const SectionTitle = styled.h2``;
const KpisWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding);
    display: flex;
    align-items: center;
    justify-content: space-around;

    & > * {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 25%;
        height: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;

        &:not(:last-child) {
            border-right: 2px solid var(--neutral100);
        }
    }
`;
const FollowKeywordWrapper = styled.div``;
const FollowKeywordButton = styled(Button)``;
const FollowedKeyword = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background-color: var(--neutral100);
    color: var(--neutral500);
    border-radius: var(--r-s);
    padding: 0.5rem;
    font-size: var(--fs-body-s);
    font-weight: var(--fw-semibold);
`;
const Label = styled.div<{ color: string }>`
    background-color: var(${(props) => `${props.color}50`});
    color: var(${(props) => props.color});
    padding-block: 0.25rem;
    padding-inline: 0.5rem;
    border-radius: var(--r-s);
    font-size: var(--fs-body-s);
    font-weight: var(--fw-semibold);
`;
const Value = styled.div`
    font-weight: var(--fw-bold);
    font-size: var(--fs-heading-m);
`;
const AwardIcon = styled(FontAwesomeIcon)<{ rank: number }>`
    ${({ rank }) => rank === 1 && `color: var(--decorativeD);`}
    ${({ rank }) => rank === 2 && `color: var(--neutral300);`}
    ${({ rank }) => rank > 2 && `color: var(--decorativeC);`}
`;
const UnrankedWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;
const TooltipTriggerWrapperWithSpacing = styled(TooltipTriggerWrapper)`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;
const KpiOuter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
`;

export default Kpis;
