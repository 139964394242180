import Model from './Model';

class PeakTimeBestModel extends Model {
    static attributes = {
        bestWeekDay: 'best_week_day',
        bestDayHour: 'best_day_hour',
        downloads: 'downloads',
    };
}

export default PeakTimeBestModel;
