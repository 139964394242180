import { FormattedMessage, useIntl } from 'react-intl';
import { useStatsDateSelectorContext } from '@/context/StatsDateSelectorContext';
import RingChartWithLegend from '@/components/charts/RingChartWithLegend';
import { useParams } from 'react-router';
import useQuery from '@/shared/hooks/useQuery';
import useSourceBrowsersLeaderboardQuery from '@/queries/stats/useSourceBrowsersLeaderboardQuery.hook';
import useCampaignSourceBrowsersLeaderboardQuery from '@/queries/stats/useCampaignSourceBrowsersLeaderboardQuery.hook';
import styled from 'styled-components';

const SourcesBrowsers = () => {
    const intl = useIntl();
    const { showId } = useParams();
    const query = useQuery();
    const campaignId = query.get('campaignId');
    const { startDate, endDate } = useStatsDateSelectorContext();

    const { data: showBrowsers, isLoading: showBrowsersAreLoading } =
        useSourceBrowsersLeaderboardQuery({
            showId,
            start: startDate,
            end: endDate,
            options: {
                enabled: !campaignId,
            },
        });

    const { data: campaignBrowsers, isLoading: campaignBrowsersAreLoading } =
        useCampaignSourceBrowsersLeaderboardQuery({
            campaignId,
            start: startDate,
            end: endDate,
            options: {
                enabled: !!campaignId,
            },
        });

    const browsers = campaignId ? campaignBrowsers : showBrowsers;
    const isLoading = showBrowsersAreLoading || campaignBrowsersAreLoading;

    return (
        <>
            <OuterHeader>
                <TitleAndDescription>
                    <Title>
                        <FormattedMessage defaultMessage="Navigateurs" />
                    </Title>
                    <Description>
                        <FormattedMessage defaultMessage="Voici les navigateurs les plus utilisés pour vous écouter" />
                    </Description>
                </TitleAndDescription>
            </OuterHeader>
            {browsers && (
                <RingChartWithLegend
                    title={
                        <FormattedMessage defaultMessage="Parts d'écoute selon les navigateurs" />
                    }
                    isLoading={isLoading}
                    source={browsers.map((browser) => ({
                        label:
                            browser.clientName === 'other'
                                ? intl.formatMessage({ defaultMessage: 'Autres' })
                                : browser.clientName,
                        key: browser.clientName,
                        name: browser.clientName,
                        value: browser.percentage,
                        percent: browser.percentage,
                    }))}
                    py={50}
                    px={30}
                    mt={25}
                />
            )}
        </>
    );
};

const OuterHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;
const TitleAndDescription = styled.div``;
const Title = styled.h2`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: var(--fs-heading-m);
`;
const Description = styled.p``;

export default SourcesBrowsers;
