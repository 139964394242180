import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchClipQuotaOfShow } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import clipKeys from './clipKeys';
import { z } from 'zod';

export const ClipQuotaSchema = z.object({
    maximum: z.number(),
    minimum: z.number(),
    step: z.number(),
    total: z.number(),
});

export type ClipQuota = z.infer<typeof ClipQuotaSchema>;
type Key = QueryFunctionContext<ReturnType<typeof clipKeys.quotaByShowId>>;

const queryFn = async ({ queryKey: [{ showId }] }: Key) => {
    const { data } = await fetchClipQuotaOfShow(showId);

    return ClipQuotaSchema.parse(camelcaseKeys(data, { deep: true }));
};

const useClipQuotaQuery = (showId: string | number) => {
    return useQuery({
        queryKey: clipKeys.quotaByShowId(showId),
        queryFn,
    });
};

export default useClipQuotaQuery;
