import Button from '@/components/Button';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { CampaignFormSchema } from '../useFormSchema';
import updateCampaignMutation from '@/queries/campaign/updateCampaignMutation';
import { useHistory, useParams } from 'react-router';
import useCampaignQuery from '@/queries/campaign/useCampaignQuery';
import { CAMPAIGN_STATUS } from '@/shared/config/constants';
import { useBodyToastQueue } from '@/shared/hooks/useBodyToastQueue.hook';

const SaveDraftButton = () => {
    const { campaignId, showId } = useParams<{ campaignId: string; showId: string }>();
    const { data: campaign } = useCampaignQuery(campaignId);
    const { getValues, reset } = useFormContext<CampaignFormSchema>();
    const updateCampaign = updateCampaignMutation();
    const history = useHistory();
    const isDraft = campaign?.state === CAMPAIGN_STATUS.DRAFT;
    const toast = useBodyToastQueue();

    if (!isDraft) return null;

    const handleSaveCampaign = () => {
        const formData = getValues();
        const {
            general,
            duration: { maxListens, ...duration },
            episodes,
        } = formData;
        const { episodes: podcasts, autoOnNewPodcasts } = episodes;
        const data = {
            ...general,
            ...duration,
            maxListens: maxListens || null,
            podcasts,
            autoOnNewPodcasts,
        };
        updateCampaign.mutate(
            { campaignId, campaign: data },
            {
                onSuccess: () => {
                    reset(formData);
                    history.push(`/app/show/${showId}/monetize/manual`);
                },
                onError: () => {
                    toast.alert();
                },
            },
        );
    };

    return (
        <Button
            onPress={handleSaveCampaign}
            variant="tertiary"
            isLoading={updateCampaign.isLoading}
        >
            <FormattedMessage defaultMessage="Enregistrer et fermer" />
        </Button>
    );
};

export default SaveDraftButton;
