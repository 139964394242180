import { useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { InputTextController } from '@ui/atoms/InputText';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useModalToastQueue } from '@/shared/hooks/useModalToastQueue.hook';
import Button from '@/components/Button';
import { PasswordFormSchema, usePasswordFormSchema } from './schema';
import { zodResolver } from '@hookform/resolvers/zod';
import updatePasswordMutation from '@queries/user/updatePasswordMutation';

interface EditPasswordFormProps {
    onAfterSubmit: () => void;
}

const EditPasswordForm = ({ onAfterSubmit }: EditPasswordFormProps) => {
    const intl = useIntl();
    const schema = usePasswordFormSchema();
    const form = useForm<PasswordFormSchema>({
        mode: 'onChange',
        resolver: zodResolver(schema),
    });
    const ref = useRef(null);
    const toast = useModalToastQueue();
    const updatePassword = updatePasswordMutation();
    const { isDirty } = form.formState;

    const onSubmit = (formData: PasswordFormSchema) => {
        updatePassword.mutate(formData, {
            onSuccess: () => {
                toast.success(
                    <FormattedMessage defaultMessage="Votre mot de passe a bien été modifié 👌" />,
                );
                onAfterSubmit();
            },
            onError: () => {
                toast.alert(
                    <FormattedMessage defaultMessage="Oups, il semblerait qu'une erreur soit survenue lors de la modification de votre mot de passe" />,
                );
            },
        });
    };

    return (
        <Form onSubmit={form.handleSubmit(onSubmit)}>
            <InputTextController
                ref={ref}
                control={form.control}
                name="currentPassword"
                type="password"
                label={<FormattedMessage defaultMessage="Mot de passe actuel" />}
                placeholder={intl.formatMessage({ defaultMessage: '8 caractères minimum' })}
                isRequired={true}
                autoFocus={true}
            />
            <InputTextController
                control={form.control}
                name="newPassword"
                type="password"
                label={<FormattedMessage defaultMessage="Nouveau mot de passe" />}
                placeholder={intl.formatMessage({ defaultMessage: '8 caractères minimum' })}
                isRequired={true}
            />
            <InputTextController
                control={form.control}
                name="newPasswordConfirmation"
                type="password"
                label={<FormattedMessage defaultMessage="Confirmation du nouveau mot de passe" />}
                placeholder={intl.formatMessage({ defaultMessage: '8 caractères minimum' })}
                isRequired={true}
            />
            <ButtonsWrapper>
                <Button type="submit" isLoading={updatePassword.isLoading} isDisabled={!isDirty}>
                    <FormattedMessage defaultMessage="Enregistrer" />
                </Button>
                <Button variant="tertiary" onPress={onAfterSubmit}>
                    <FormattedMessage defaultMessage="Annuler" />
                </Button>
            </ButtonsWrapper>
        </Form>
    );
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    height: 100%;
`;
const ButtonsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    margin-top: auto;

    & > :first-child,
    > :last-child {
        align-self: stretch;
    }

    ${(p) => p.theme.mediaQueries.tabletAndUp} {
        flex-direction: row;
        justify-content: flex-end;
        column-gap: 0.5rem;
        margin-top: 0.5rem;

        & > :first-child,
        > :last-child {
            align-self: initial;
        }

        & > :first-child {
            order: 1;
        }
    }
`;

export default EditPasswordForm;
