import CTADragAndDropItem from '@app/organisms/CTADragAndDropItem';
import DragAndDrop from '@app/organisms/DragAndDrop';
import { useFormContext } from 'react-hook-form';
import { PlayerFormSchema } from '../../usePlayerFormSchema.hook';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import Text from '@/components/ui/atoms/Text';
import styled, { css } from 'styled-components';
import { LINKS_NAMES } from '@/components/CallToAction';

interface PlayerFormCallToActionButtonsProps {
    isDisabled: boolean;
}

const PlayerFormCallToActionButtons = ({ isDisabled }: PlayerFormCallToActionButtonsProps) => {
    const { watch, setValue } = useFormContext<PlayerFormSchema>();
    const callToActionButtons = watch('callToActionButtons');

    const handleChange = (data: any[]) => {
        setValue('callToActionButtons', data, {
            shouldDirty: true,
            shouldValidate: true,
        });
    };

    if (callToActionButtons.length < 1) {
        return (
            <EmptyState>
                <EmptyStateIcon
                    icon={icon({
                        name: 'snooze',
                        style: 'solid',
                    })}
                />
                <Text fontWeight="--fw-semibold" textAlign="center" color="--neutral500">
                    <FormattedMessage defaultMessage="Vous n’avez pas encore ajouté de bouton personnalisé" />
                </Text>
            </EmptyState>
        );
    }

    return (
        // @ts-ignore
        <DragAndDropWrapper
            $isDisabled={isDisabled}
            id={LINKS_NAMES.PLAYER}
            list={callToActionButtons}
            getData={(data: { hasChange: boolean; newList: any[] }) => {
                handleChange(data.newList);
            }}
        >
            {/* @ts-ignore */}
            {({ item, isDragging }) => <CTADragAndDropItem item={item} isDragging={isDragging} />}
        </DragAndDropWrapper>
    );
};

const DragAndDropWrapper = styled(DragAndDrop)`
    ${({ $isDisabled }: { $isDisabled: boolean }) =>
        $isDisabled &&
        css`
            pointer-events: none;
            opacity: 0.5;
        `}
`;
const EmptyState = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 0.75rem;
    padding: 2rem;
    border-radius: var(--r-m);
    background: var(--neutral50);
`;
const EmptyStateIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
    width: 1.5rem;
    height: 1.5rem;
`;

export default PlayerFormCallToActionButtons;
