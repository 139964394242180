import { useRef } from 'react';
import PropTypes from 'prop-types';
import {
    Button as AriaButton,
    ComboBox as AriaComboBox,
    Input as AriaInput,
    ListBox as AriaListBox,
} from 'react-aria-components';
import Label from '../Label';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon as iconFA } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled, { css } from 'styled-components';
import Spinner from '../Spinner';
import ComboBoxDropdown from './ComboBoxDropdown';
import { FormattedMessage } from 'react-intl';

// TODO: Ready to be typed

const ComboBox = ({
    isOptional,
    isRequired,
    tooltip,
    description,
    label,
    children,
    icon,
    isValid,
    isLoading,
    onLoadMore,
    loadMoreEnabled,
    inputHasImage,
    renderEmptyState,
    hideIconButton = false,
    showResetButton = false,
    onReset,
    ...props
}) => {
    const triggerRef = useRef(null);

    return (
        <Combo menuTrigger="focus" {...props} $isValid={isValid} ref={triggerRef}>
            <Label
                isOptional={isOptional}
                isRequired={isRequired}
                tooltip={tooltip}
                description={description}
            >
                {label}
            </Label>
            <InputWrapper $hasImage={inputHasImage}>
                {icon && <LeftIconWrapper>{icon}</LeftIconWrapper>}
                <InputInnerWrapper>
                    <Input />
                </InputInnerWrapper>
                <RightIconWrapper>
                    {isLoading ? (
                        <Spinner size={12} />
                    ) : !hideIconButton ? (
                        <IconButton>
                            <ChevronIcon icon={iconFA({ name: 'chevron-down', style: 'solid' })} />
                        </IconButton>
                    ) : showResetButton ? (
                        <IconButton onPress={onReset}>
                            <CrossIcon icon={iconFA({ name: 'xmark', style: 'solid' })} />
                        </IconButton>
                    ) : null}
                </RightIconWrapper>
            </InputWrapper>
            <ComboBoxDropdown
                shouldFlip={false}
                placement="bottom"
                offset={4}
                triggerRef={triggerRef}
            >
                <ListBox renderEmptyState={renderEmptyState}>{children}</ListBox>
                {loadMoreEnabled && (
                    <FetchMoreButton onClick={onLoadMore}>
                        <FormattedMessage defaultMessage="Charger plus" />
                    </FetchMoreButton>
                )}
            </ComboBoxDropdown>
        </Combo>
    );
};

const InputWrapper = styled.div`
    border-radius: var(--r-s);
    border: 1px solid var(--neutral200);
    background-color: var(--white);
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    height: 2.5rem;
    padding: 0.625rem 0.75rem;
    min-width: 0;

    &:hover {
        border-color: var(--neutral300);
    }

    &:focus-within {
        outline: 0.125rem var(--primary50) solid;
    }

    ${({ $hasImage }) =>
        $hasImage &&
        css`
            padding: 0.75rem;
            height: 4rem;
        `}
`;
const Combo = styled(AriaComboBox)`
    width: 100%;
    max-width: 320px;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;

    &[data-disabled] > ${InputWrapper} {
        opacity: 0.5;
        cursor: not-allowed;
    }

    &[data-invalid] > ${InputWrapper} {
        border-color: var(--alert);

        &:focus-within {
            outline: 0.125rem var(--alert50) solid;
        }
    }

    ${({ $isValid }) =>
        $isValid &&
        css`
            & > ${InputWrapper} {
                border-color: var(--success);

                &:focus-within {
                    outline: 0.125rem var(--success50) solid;
                }
            }
        `}
`;
const LeftIconWrapper = styled.div`
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 1rem;
`;
const InputInnerWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 0;
`;
const RightIconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 1rem;
`;
const ChevronIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: var(--neutral500);
`;
const CrossIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: var(--neutral500);
`;
const IconButton = styled(AriaButton)`
    padding: 0;
    border: none;
    background: none;
`;
const Input = styled(AriaInput)`
    flex-grow: 1;
    border: none;
    background: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;
const ListBox = styled(AriaListBox)`
    display: flex;
    flex-direction: column;
`;
const FetchMoreButton = styled.button`
    border: none;
    background: none;
    color: var(--primary);
    font-size: var(--fs-body-m);
    line-height: var(--lh-body-m);
    font-weight: var(--fw-semibold);
    padding: 0.5rem 0.75rem;
    cursor: pointer;
`;

/*
     cf. react-aria-components/ComboBox props
     (https://react-spectrum.adobe.com/react-spectrum/ComboBox.html#props)
*/
ComboBox.propTypes = {
    label: PropTypes.oneOf([PropTypes.string, PropTypes.node]).isRequired,
    isOptional: PropTypes.bool,
    isRequired: PropTypes.bool,
    tooltip: PropTypes.oneOf([PropTypes.string, PropTypes.node]),
    description: PropTypes.string,
    children: PropTypes.node,
    icon: PropTypes.func,
    isValid: PropTypes.bool,
    isLoading: PropTypes.bool,
    onLoadMore: PropTypes.func,
    loadMoreEnabled: PropTypes.bool,
    inputHasImage: PropTypes.bool,
    renderEmptyState: PropTypes.func,
    hideIconButton: PropTypes.bool,
    showResetButton: PropTypes.bool,
    onReset: PropTypes.func,
};

ComboBox.defaultProps = {
    isOptional: false,
    isRequired: false,
    isLoading: false,
    loadMoreEnabled: false,
    inputHasImage: false,
    disabledKeys: [],
};

export default ComboBox;
