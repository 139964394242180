import { useQuery } from '@tanstack/react-query';
import { fetchSharingProvider } from '@/api';
import camelcaseKeys from 'camelcase-keys';

const queryFn = async ({ queryKey: [{ showId, platformId }] }) => {
    const data = await fetchSharingProvider(showId, platformId);

    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const useShowSocialPlatformQuery = ({ showId, platformId }) => {
    return useQuery({
        queryKey: [{ entity: 'show-social-platforms', showId, platformId }],
        queryFn,
    });
};

export default useShowSocialPlatformQuery;
