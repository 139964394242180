import { FC, useState, createContext, useContext, ReactNode } from 'react';

interface TSelectionContext {
    allSelectedOnPage: boolean;
    allSelected: boolean;
    selected: string[];
    isSelected: (id: string) => boolean;
    toggleSelection: (id: string) => void;
    toggleAllOnPage: () => void;
    toggleAll: () => void;
    setCampaigns: (campaigns: string[]) => void;
    resetSelection: () => void;
    resetPageSelection: () => void;
}

const SelectionContext = createContext<TSelectionContext | undefined>(undefined);

interface SelectionContextProviderProps {
    children: ReactNode;
}

export const SelectionContextProvider: FC<SelectionContextProviderProps> = ({ children }) => {
    const [campaigns, setCampaigns] = useState<string[]>([]);
    const [selected, setSelected] = useState<string[]>([]);
    const [allSelected, setAllSelected] = useState(false);
    const [allSelectedOnPage, setAllSelectedOnPage] = useState(false);

    const isSelected = (id: string) => selected.includes(id);
    const toggleSelection = (id: string) => {
        if (isSelected(id)) {
            setSelected(selected.filter((selectedId) => selectedId !== id));
            setAllSelectedOnPage(false);
            setAllSelected(false);
        } else {
            setSelected([...selected, id]);
            if (selected.length === campaigns.length - 1) {
                setAllSelectedOnPage(true);
            }
        }
    };
    const toggleAllOnPage = () => {
        if (allSelectedOnPage) {
            setSelected([]);
        } else {
            setSelected(campaigns);
        }
        setAllSelectedOnPage(!allSelectedOnPage);
    };
    const toggleAll = () => {
        setAllSelected(!allSelected);
    };
    const resetSelection = () => {
        setSelected([]);
        setAllSelected(false);
        setAllSelectedOnPage(false);
    };
    const resetPageSelection = () => {
        setSelected([]);
        setAllSelectedOnPage(false);
    };

    const contextValue = {
        allSelectedOnPage,
        allSelected,
        selected,
        isSelected,
        toggleSelection,
        toggleAll,
        toggleAllOnPage,
        setCampaigns,
        resetSelection,
        resetPageSelection,
    };

    return <SelectionContext.Provider value={contextValue}>{children}</SelectionContext.Provider>;
};

export const useSelectionContext = () => {
    const context = useContext(SelectionContext);
    if (context === undefined) {
        throw new Error('useSelectionContext must be used within a SelectionContextProvider');
    }
    return context;
};
