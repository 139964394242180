import styled, { css, keyframes } from 'styled-components';
import Stack from '@ui/layout/Stack';
import Cluster from '@ui/layout/Cluster';

const ChannelGuestsEditPermissionFormSkeleton = () => {
    return (
        <Stack $gap="1rem" $align="end">
            <Header>
                <Cluster $gap="0.75rem" $align="center">
                    <Circle $size="2.5rem" />
                    <Stack $gap="0.5rem">
                        <Line $w="5rem" $h="0.5rem" />
                        <LightLine $w="10rem" $h="0.5rem" />
                    </Stack>
                </Cluster>
                <Stack $gap="0.25rem">
                    <Line $w="5rem" $h="0.5rem" />
                    <LightLine $w="2.5rem" $h="0.5rem" />
                </Stack>
            </Header>
            <Table>
                <TableHeader />
                <TableRow>
                    <TableCell1>
                        <Block $w="2rem" $h="2rem" />
                        <Line $maxW="7.5rem" $h="0.5rem" />
                    </TableCell1>
                    <TableCell2>
                        <Line $maxW="4rem" $h="0.5rem" />
                    </TableCell2>
                </TableRow>
                <TableRow>
                    <TableCell1>
                        <Block $w="2rem" $h="2rem" />
                        <Line $maxW="10rem" $h="0.5rem" />
                    </TableCell1>
                    <TableCell2>
                        <Line $maxW="5rem" $h="0.5rem" />
                    </TableCell2>
                </TableRow>
                <TableRow>
                    <TableCell1>
                        <Block $w="2rem" $h="2rem" />
                        <Line $maxW="5rem" $h="0.5rem" />
                    </TableCell1>
                    <TableCell2>
                        <Line $maxW="2rem" $h="0.5rem" />
                    </TableCell2>
                </TableRow>
                <TableRow>
                    <TableCell1>
                        <Block $w="2rem" $h="2rem" />
                        <Line $maxW="7.5rem" $h="0.5rem" />
                    </TableCell1>
                    <TableCell2>
                        <Line $maxW="4rem" $h="0.5rem" />
                    </TableCell2>
                </TableRow>
                <TableRow>
                    <TableCell1>
                        <Block $w="2rem" $h="2rem" />
                        <Line $maxW="10rem" $h="0.5rem" />
                    </TableCell1>
                    <TableCell2>
                        <Line $maxW="5rem" $h="0.5rem" />
                    </TableCell2>
                </TableRow>
                <TableRow>
                    <TableCell1>
                        <Block $w="2rem" $h="2rem" />
                        <Line $maxW="7.5rem" $h="0.5rem" />
                    </TableCell1>
                    <TableCell2>
                        <Line $maxW="2rem" $h="0.5rem" />
                    </TableCell2>
                </TableRow>
                <TableRow>
                    <TableCell1>
                        <Block $w="2rem" $h="2rem" />
                        <Line $maxW="5rem" $h="0.5rem" />
                    </TableCell1>
                    <TableCell2>
                        <Line $maxW="4rem" $h="0.5rem" />
                    </TableCell2>
                </TableRow>
            </Table>
        </Stack>
    );
};

const shimmer = keyframes`
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
`;
const shimmering = css`
    background: var(--neutral50);
    background-image: linear-gradient(
        to right,
        var(--neutral50) 0%,
        #edeef1 20%,
        var(--neutral50) 40%,
        var(--neutral50) 100%
    );
    background-repeat: no-repeat;
    background-size: 50rem 6.5rem;
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${shimmer};
    animation-timing-function: linear;
`;
const Line = styled.div<{ $w?: string; $h?: string; $maxW?: string }>`
    border-radius: var(--r-full);
    background-color: var(--neutral100);
    ${({ $w }) => $w && `width: ${$w};`}
    ${({ $h }) => $h && `height: ${$h};`}
    ${({ $maxW }) => $maxW && `max-width: ${$maxW};`}
    ${shimmering};
`;
const LightLine = styled(Line)`
    background-color: var(--neutral50);
`;
const Block = styled.div<{ $w?: string; $h?: string }>`
    border-radius: var(--r-s);
    background-color: var(--neutral100);
    ${({ $w }) => $w && `width: ${$w};`}
    ${({ $h }) => $h && `height: ${$h};`}
    ${shimmering};
`;
const LightBlock = styled(Block)`
    background-color: var(--neutral50);
`;
const Circle = styled.div<{ $size: string }>`
    width: ${({ $size }) => $size};
    height: ${({ $size }) => $size};
    background: var(--neutral50);
    border-radius: var(--r-full);
`;
const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: start;
    column-gap: 1.5rem;
    width: 100%;
`;
const TableHeader = styled(LightBlock)`
    border-radius: var(--r-m);
    width: 100%;
    height: 3rem;
`;
const TableRow = styled.div`
    height: 3.5rem;
    display: flex;
    align-items: center;

    &:not(:last-of-type) {
        border-bottom: 1px solid var(--neutral100);
    }
`;
const Table = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;
const TableCell = styled.div`
    padding-inline: 1rem;

    ${Line} {
        flex-grow: 1;
    }
`;
const TableCell1 = styled(TableCell)`
    display: flex;
    align-items: center;
    column-gap: 0.75rem;
    flex-grow: 1;
`;
const TableCell2 = styled(TableCell)`
    min-width: 7.5rem;
    max-width: 10rem;
    flex-grow: 1;
`;

export default ChannelGuestsEditPermissionFormSkeleton;
