import Nav from './Nav';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

interface LayoutProps {
    children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
    return (
        <LayoutWrapper>
            <Sidebar>
                <Title>
                    <FormattedMessage defaultMessage="Statistiques" />
                </Title>
                <Nav breaksAt="64rem" />
            </Sidebar>
            <ContentWrapper>{children}</ContentWrapper>
        </LayoutWrapper>
    );
};

const LayoutWrapper = styled.div`
    width: 100%;
    max-width: var(--container-width);
    gap: 2rem;
    margin-block: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-inline: var(--container-padding-mobile);

    @media screen and (min-width: 64rem) {
        flex-direction: row;
        padding-inline: var(--container-padding);
        gap: 2rem;
    }
`;
const ContentWrapper = styled.div`
    display: flex;
    flex-shrink: 0;
    height: max-content;
    flex-direction: column;
    gap: 1rem;
    flex-grow: 1;
    min-width: 0;
    flex-basis: calc(100% - 12rem);
`;
const Sidebar = styled.aside`
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    height: fit-content;
    gap: 1rem;

    @media screen and (min-width: 64rem) {
        flex-basis: 12rem;
        position: sticky;
        top: 8.5rem;
        left: 0;
        flex-shrink: 0;
    }
`;
const Title = styled.h1`
    font-weight: var(--fw-bold);
    font-size: var(--fs-heading-l);
    line-height: 1.4rem;
`;

export default Layout;
