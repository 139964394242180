import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { FormattedMessage } from 'react-intl';
import useImportedEpisodesQuery, {
    ImportedEpisode,
} from '@/queries/rss/useImportedEpisodesQuery.hook';
import useRssFeedQuery from '@/queries/rss/useRssFeedQuery.hook';
import Stepper from '@ui/Stepper';
import RouterLink from '@/components/Link/RouterLink';
import Button from '@/components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import useUpdateRssFeedMutation from '@/queries/rss/useUpdateRssFeedMutation.hook';
import useCheckFeedIsRedirectedQuery from '@/queries/rss/useCheckFeedIsRedirectedQuery.hook';
import FeedRedirectionModal from './FeedRedirectionModal';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import { useSwitch } from '@hooks/useSwitch';
import {
    ACCEPTED_DISTRIBUTION_PLATFORMS_TO_COMPLETE_RSS_IMPORT,
    AcceptedDistributionPlatformToCompleteRssImport,
} from '@/utils/constants';

import styled, { css } from 'styled-components';

const EPISODE_POLLING_RATE = 5000;

const RssImportChecklist = () => {
    const { showId } = useParams<{ showId: string }>();
    const [currentStep, setCurrentStep] = useState(1);
    const [isProcessing, setIsProcessing] = useState(false);
    const [feedRedirectionModalOpened, openFeedRedirectionModal, closeFeedRedirectionModal] =
        useSwitch(false);

    const { data: show } = useShowQuery(showId);
    const { data: rssFeed } = useRssFeedQuery({ showId });
    const { data: feedRedirectStatus } = useCheckFeedIsRedirectedQuery({ showId });
    const { data: imported } = useImportedEpisodesQuery({
        showId,
        config: {
            refetchInterval: (data: any) =>
                data?.importedEpisodes?.some((importedEpisode: ImportedEpisode) =>
                    ['processing', 'pending'].includes(importedEpisode.state),
                )
                    ? EPISODE_POLLING_RATE
                    : false,
        },
    });

    const hideImportBanner = useUpdateRssFeedMutation();

    useEffect(() => {
        if (!imported) return;
        const processing = imported?.importedEpisodes?.some((episode) =>
            ['processing', 'pending'].includes(episode.state),
        );

        if (!processing) {
            setCurrentStep(2);
        }
        setIsProcessing(processing);
    }, [imported]);

    useEffect(() => {
        if (feedRedirectStatus?.redirected) {
            setCurrentStep(3);
        }
    }, [feedRedirectStatus]);

    useEffect(() => {
        if (!show?.listeningLinks) return;

        const platformsWithLink = Object.keys(
            show?.listeningLinks.data.filter((link) => link.url) ?? {},
        );
        const isDistributionUpdated = platformsWithLink.some((link) =>
            ACCEPTED_DISTRIBUTION_PLATFORMS_TO_COMPLETE_RSS_IMPORT.includes(
                link as AcceptedDistributionPlatformToCompleteRssImport,
            ),
        );

        if (isDistributionUpdated) {
            setCurrentStep(4);
        }
    }, [show?.listeningLinks]);

    const importedEpisodesCount = imported?.importedEpisodes?.length || 0;
    const importedEpisodesWithErrorCount =
        imported?.importedEpisodes?.filter((episode) => episode.state === 'error').length || 0;

    const importedEpisodesStep = (
        <StepWrapper>
            {isProcessing ? (
                <FormattedMessage
                    defaultMessage="Import en cours… ({importedEpisodesCount}/{episodesToImportTotal})"
                    values={{
                        importedEpisodesCount: imported?.importedEpisodes?.filter(
                            (episode) => episode.state === 'done',
                        ).length,
                        episodesToImportTotal: imported?.importedEpisodes?.length,
                    }}
                />
            ) : (
                <FormattedMessage
                    defaultMessage="{importedEpisodesTotalCount, plural, =0 {Aucun épisode importé} one {# épisode importé} other {# épisodes importés}}<errors> dont {importedEpisodesErrorCount, plural, one {# erreur} other {# erreurs}}</errors>"
                    values={{
                        errors: (chunks: React.ReactNode) =>
                            importedEpisodesWithErrorCount > 0 ? (
                                <EpisodesWithErrorText>{chunks}</EpisodesWithErrorText>
                            ) : null,
                        importedEpisodesTotalCount: importedEpisodesCount,
                        importedEpisodesErrorCount: importedEpisodesWithErrorCount,
                    }}
                />
            )}
        </StepWrapper>
    );

    const redirectionStep = (
        <StepWrapper>
            <FormattedMessage defaultMessage="Redirigez votre flux RSS" />

            {currentStep === 2 && (
                <RedirectCheckButton onPress={openFeedRedirectionModal} variant="ghost">
                    <FormattedMessage defaultMessage="C’est parti !" />
                </RedirectCheckButton>
            )}
        </StepWrapper>
    );

    const distributionStep = (
        <StepWrapper>
            <FormattedMessage defaultMessage="Configurez l’onglet Diffusion" />

            {currentStep === 3 && (
                <BroadcastLink to={`/app/show/${showId}/settings/broadcast`}>
                    <FormattedMessage defaultMessage="C’est parti !" />
                </BroadcastLink>
            )}
        </StepWrapper>
    );

    if (!imported || rssFeed?.isBannerHidden) return null;

    return (
        <>
            <ChecklistWrapper>
                <CloseButton
                    onPress={() => {
                        hideImportBanner.mutate({ showId, payload: { is_banner_hidden: true } });
                    }}
                    variant="ghost"
                >
                    <FontAwesomeIcon icon={icon({ name: 'xmark', style: 'solid' })} />
                </CloseButton>
                <StepperWrapper>
                    <Stepper
                        currentStep={currentStep}
                        stepCount={3}
                        color="--success"
                        labels={[importedEpisodesStep, redirectionStep, distributionStep]}
                    />
                </StepperWrapper>
            </ChecklistWrapper>
            <FeedRedirectionModal
                modalOpened={feedRedirectionModalOpened}
                closeModal={closeFeedRedirectionModal}
            />
        </>
    );
};

const ChecklistWrapper = styled.div`
    position: relative;
    background-color: var(--white);
    padding: 1rem;
    border-radius: var(--r-s);
`;
const StepperWrapper = styled.div`
    max-width: 80%;
    margin-inline: auto;

    @media (max-width: 1096px) {
        max-width: 90%;
        margin-inline: 0;
    }
`;
const EpisodesWithErrorText = styled.span`
    color: var(--alert);
`;
const StepWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;

    @media (max-width: 1096px) {
        gap: 0.5rem;
        flex-direction: row;
    }
`;
const paddinglessActionable = css`
    padding: 0;
`;
const RedirectCheckButton = styled(Button)`
    ${paddinglessActionable};
`;
const BroadcastLink = styled(RouterLink)`
    ${paddinglessActionable};
`;
const CloseButton = styled(Button)`
    position: absolute;
    right: 1rem;
    top: 0.75rem;
    padding: 0;
    color: var(--neutral500);
    cursor: pointer;
`;

export default RssImportChecklist;
