import { FormattedMessage } from 'react-intl';
import { connect } from '@app/decorators/connect';
import Link from '@ui/atoms/Link';
import Img from '@ui/atoms/Img';
import PlaylistLineMenu from './PlaylistLineMenu';
import Text from '@/components/ui/atoms/Text';
import styled from 'styled-components';

const enhance = connect(({ routerStore }, { playlist }) => {
    const { playlistId } = routerStore.params;
    return {
        active: playlistId === `${playlist.id}`,
        show: routerStore.resolve('showId'),
        link: routerStore.link,
    };
});

const PlaylistLine = ({ active, playlist, link, show, ...props }) => (
    <PlaylistLineWrapper isActive={active} {...props}>
        <PlaylistLineLink to={link('menu.playlists', { playlistId: playlist.id })}>
            <Img size={50} src={playlist.imageUrl} bordered />
            <PlaylistLineLinkText>
                <Text numberOfLines={2} fontWeight="--fw-semibold">
                    {playlist.name}
                </Text>
                <Text>
                    <FormattedMessage
                        defaultMessage="{count, plural, =0 {Aucun épisode} one {# épisode} other {# épisodes}}"
                        values={{ count: playlist.podcastsCount }}
                    />
                </Text>
            </PlaylistLineLinkText>
        </PlaylistLineLink>
        <PlaylistLineMenu
            show={show}
            playlist={playlist}
            iconColor={active ? '--primary' : '--black'}
        />
    </PlaylistLineWrapper>
);

const PlaylistLineLinkText = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;
    flex: 1;
`;
const PlaylistLineLink = styled(Link)`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    flex: 1;
    text-decoration: none;
    color: var(--black);
`;
const PlaylistLineWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    height: 100%;
    width: 100%;
    padding: 0.75rem;
    background-color: var(--white);

    &:hover {
        ${({ isActive }) => !isActive && `background-color: var(--neutral50);`}
    }

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        ${({ isActive }) => isActive && `background-color: var(--primary100);`}
    }
`;

export default enhance(PlaylistLine);
