import { flexRender, Table } from '@tanstack/react-table';
import { DownloadsComparisonData } from '@app/molecules/DownloadsComparisonContent';
import { DownloadsComparisonTableTh as Th } from '@/components/tables/DownloadsComparisonTable/DownloadsComparisonTableCommon';

interface DownloadsComparisonTableHeadProps {
    table: Table<DownloadsComparisonData>;
    highlightedColumnIndex?: number;
}

const DownloadsComparisonTableHead = ({
    table,
    highlightedColumnIndex,
}: DownloadsComparisonTableHeadProps) => {
    return (
        <thead>
            {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                        const { column } = header;

                        return (
                            <Th
                                key={header.id}
                                column={column}
                                data-column-id={column.id}
                                isHighlighted={column.id === `downloads-${highlightedColumnIndex}`}
                            >
                                {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                          header.column.columnDef.header,
                                          header.getContext(),
                                      )}
                            </Th>
                        );
                    })}
                </tr>
            ))}
        </thead>
    );
};

export default DownloadsComparisonTableHead;
