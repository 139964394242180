import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FormattedMessage } from 'react-intl';
import { PRICING, PRICING_NAME } from '@/utils/pricing';
import Text from '@ui/atoms/Text';
import ComboBox from '@ui/atoms/ComboBox';
import ListBoxItem from '@ui/atoms/ListBoxItem';

import ReactCountryFlag from 'react-country-flag';
import styled, { css } from 'styled-components';

interface StoreProps {
    rank: 1 | 2 | 3;
    enabled: boolean;
    stores: string[];
    onSelectStore: (id: string) => void;
    availableStores: { id: string; name: string }[];
}

const Store = ({ rank, enabled, stores, onSelectStore, availableStores }: StoreProps) => {
    const offerLookup = {
        2: PRICING.BOOST,
        3: PRICING.SUPERSONIC,
    };

    if (enabled) {
        return (
            <ComboBoxWrapper>
                {/* @ts-ignore */}
                <ComboBox
                    isRequired={false}
                    selectedKey={stores[rank - 1]}
                    isLoading={false}
                    icon={
                        stores[rank - 1] ? (
                            <ReactCountryFlag
                                countryCode={stores[rank - 1].toLocaleLowerCase()}
                                svg
                            />
                        ) : (
                            <EmptyIcon icon={icon({ name: 'empty-set', style: 'duotone' })} />
                        )
                    }
                    inputHasImage={true}
                    onSelectionChange={(id: string) => {
                        onSelectStore(id);
                    }}
                >
                    {availableStores.map((availableStore) => (
                        <ListBoxItemWithPadding key={availableStore.id} id={availableStore.id}>
                            {availableStore.name}
                        </ListBoxItemWithPadding>
                    ))}
                </ComboBox>
            </ComboBoxWrapper>
        );
    }

    return (
        <DisabledStoreWrapper>
            <LockIcon icon={icon({ name: 'lock-keyhole', style: 'solid' })} />
            <CountryLabel>
                <FormattedMessage
                    defaultMessage="Pays n°{rank}"
                    values={{
                        rank,
                    }}
                />
            </CountryLabel>
            <MinusIcon icon={icon({ name: 'minus', style: 'solid' })} />
            <FormattedMessage
                defaultMessage="Disponible avec l’offre supérieure {offer}."
                values={{
                    offer: PRICING_NAME[offerLookup[rank as 2 | 3]] || '',
                }}
            />
        </DisabledStoreWrapper>
    );
};

const ComboBoxWrapper = styled.div`
    width: 100%;

    & > * {
        max-width: initial;
    }
`;
const DisabledStoreWrapper = styled.div`
    width: 100%;
    height: 4rem;
    background-color: var(--neutral50);
    border-radius: var(--r-m);
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding-block: 1.5rem;
    padding-inline: 1rem;
`;
const BaseIcon = css`
    color: var(--neutral500);
    font-size: 1rem;
`;
const LockIcon = styled(FontAwesomeIcon)`
    ${BaseIcon};
`;
const MinusIcon = styled(FontAwesomeIcon)`
    ${BaseIcon}
`;
const CountryLabel = styled(Text)`
    color: var(--black);
    font-weight: var(--fw-semibold);
`;
const EmptyIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
`;
const ListBoxItemWithPadding = styled(ListBoxItem)`
    padding-block: 0.5rem;
    padding-inline: 0.25rem;
`;

export default Store;
