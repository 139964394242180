import { useMutation, useQueryClient } from '@tanstack/react-query';
import { generateClip } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import clipKeys from './clipKeys';
import clipFormatKeys from '../clipFormat/clipFormatKeys';

const mutationFn = async (clipId: string | number) => {
    const { data } = await generateClip(clipId);

    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const generateClipMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: (_, clipId) => {
            queryClient.invalidateQueries({
                queryKey: clipKeys.detailById(clipId),
            });
            queryClient.invalidateQueries({
                queryKey: clipFormatKeys.all(),
            });
        },
    });
};

export default generateClipMutation;
