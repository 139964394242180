import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { InputTextController } from '@ui/atoms/InputText';
import { SelectController } from '@ui/molecules/Select';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@ui/atoms/Button';
import { useSubmitSurvey } from '@/shared/hooks/useSubmitSurvey.hook';
import IconRocket from '@ui/icons/IconRocket';
import styled from 'styled-components';
import { useSubscribeSurveySchema } from './useSubscribeSurveySchema.hook';

export const SURVEY_TYPE = 'subscribe';

const PROFILE_OPTIONS_MESSAGES = defineMessages({
    individual: { defaultMessage: 'Particulier' },
    freelance: { defaultMessage: 'Freelance' },
    network: { defaultMessage: 'Réseau ' },
    studio: { defaultMessage: 'Studio de production de podcast' },
    development_agency: { defaultMessage: 'Agence de développement de podcast' },
    communication_agency: { defaultMessage: 'Agence de communication' },
    association: { defaultMessage: 'Association' },
    media: { defaultMessage: 'Média' },
    public_organization: { defaultMessage: 'Organisme public' },
    school: { defaultMessage: 'École / Université' },
    startup: { defaultMessage: 'Startup / Petite entreprise (<50 employés)' },
    enterprise: { defaultMessage: 'Moyenne entreprise (50-500 employés)' },
    business: { defaultMessage: 'Grande entreprise (>500 employés)' },
    other: { defaultMessage: 'Autre' },
});
export const PROFILE_OPTIONS = Object.keys(PROFILE_OPTIONS_MESSAGES);

const FROM_OPTIONS_MESSAGES = defineMessages({
    word_of_mouth: { defaultMessage: 'Bouche-à-oreille' },
    search_engine: { defaultMessage: 'Moteur de recherche (Google, Bing…)' },
    social_networks: { defaultMessage: 'Réseaux sociaux' },
    youtube: { defaultMessage: 'YouTube' },
    ausha_website: { defaultMessage: 'Site web ausha.co (blog, académie…)' },
    ausha_podcast: { defaultMessage: 'Par un podcast Ausha' },
    podcasters_group: {
        defaultMessage: 'Groupe d’entraide de podcasteurs (Facebook, Discord, Slack…)',
    },
    event: { defaultMessage: 'Évènement' },
    podcast_pub: { defaultMessage: 'Entendu en pub sur un podcast' },
    other: { defaultMessage: 'Autre' },
});
export const FROM_OPTIONS = Object.keys(FROM_OPTIONS_MESSAGES);

const SubscribeSurveyForm = ({ onAfterSubmit }) => {
    const intl = useIntl();
    const submitSurvey = useSubmitSurvey();
    const schema = useSubscribeSurveySchema();

    const { control, handleSubmit, formState, clearErrors, watch } = useForm({
        mode: 'onChange',
        defaultValues: {
            profile: '',
            from: '',
            from_other: '',
        },
        resolver: yupResolver(schema),
    });

    const onSubmit = (payload) => {
        submitSurvey.mutate(
            { type: SURVEY_TYPE, answers: payload },
            {
                onSettled: () => onAfterSubmit(),
            },
        );
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <SelectController
                control={control}
                name="profile"
                label={<FormattedMessage defaultMessage="Vous êtes un·e" />}
                errorMessage={formState.errors.profile?.message}
                onBlur={() => clearErrors('profile')}
                options={PROFILE_OPTIONS.map((option) => ({
                    id: option,
                    label: intl.formatMessage(PROFILE_OPTIONS_MESSAGES[option]),
                }))}
            />

            <SelectController
                control={control}
                name="from"
                label={<FormattedMessage defaultMessage="Comment nous avez-vous connu ?" />}
                errorMessage={formState.errors.from?.message}
                onBlur={() => clearErrors('from')}
                options={FROM_OPTIONS.map((option) => ({
                    id: option,
                    label: intl.formatMessage(FROM_OPTIONS_MESSAGES[option]),
                }))}
            />

            {watch('from') === 'other' && (
                <InputTextController
                    control={control}
                    name="from_other"
                    label={<FormattedMessage defaultMessage="Comment nous avez-vous connu ?" />}
                    errorMessage={formState.errors.from_other?.message}
                    onBlur={() => clearErrors('from_other')}
                />
            )}

            <Button
                type="submit"
                isDisabled={!formState.isDirty || !formState.isValid || submitSurvey.isLoading}
                isLoading={submitSurvey.isLoading}
                startIcon={<IconRocket />}
            >
                <FormattedMessage defaultMessage="Continuer" />
            </Button>
        </Form>
    );
};

SubscribeSurveyForm.propTypes = {
    onAfterSubmit: PropTypes.func.isRequired,
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    row-gap: 1rem;
`;

export default SubscribeSurveyForm;
