import { useRef, useEffect, useState, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import DeprecatedPaper from '../../atoms/DeprecatedPaper';
import DeprecatedText from '../../atoms/DeprecatedText';
import { parseQuery } from '../../../../api/query';
import { parseIntArray } from '../../../../helpers';
import LineCheckBoxFallback from '../../../app/fallbacks/LineCheckBoxFallback';
import DeprecatedCheckbox from '../../atoms/DeprecatedCheckbox';
import Button from '@ui/atoms/Button';
import IconLoader from '@ui/icons/IconLoader';
import styled from 'styled-components';

const LoadMoreWrapper = styled.div`
    padding-block: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ItemCheckbox = ({
    episodeItemList,
    episodeListSelected,
    quotaSelectedEpisodes,
    optionsSelect,
    setEpisodesStatsList,
    selectEpisode,
}) => {
    const ref = useRef();

    useEffect(() => {
        if (ref.current) ref.current.checked = episodeListSelected.includes(episodeItemList.id);
        setEpisodesStatsList(episodeListSelected.join('_'));
    });

    const disabledItem =
        episodeListSelected.length >= quotaSelectedEpisodes &&
        !episodeListSelected.includes(episodeItemList.id);

    const isItemOptionsSelect =
        optionsSelect &&
        optionsSelect.find((optionsEpisode) => optionsEpisode.id === episodeItemList.id);

    const styledItemOptionsSelect = isItemOptionsSelect && {
        px: 10,
        mb: 5,
        background: '--neutral50',
        rounded: true,
    };

    return (
        <DeprecatedPaper
            flex
            py={10}
            as="label"
            align="center"
            cursor="pointer"
            key={episodeItemList.id}
            htmlFor={episodeItemList.id}
            disabled={disabledItem}
            {...styledItemOptionsSelect}
        >
            <DeprecatedCheckbox
                ref={ref}
                mr={15}
                id={episodeItemList.id}
                name={episodeItemList.id}
                onChange={(e) => selectEpisode(episodeItemList.id, e.target.checked)}
                disabled={disabledItem}
            />
            <DeprecatedText color={disabledItem && '--neutral500'} ellipsis>
                {episodeItemList.name}
            </DeprecatedText>
        </DeprecatedPaper>
    );
};

const ItemEpisodesCheckBox = ({
    episodesList,
    quotaSelectedEpisodes,
    setEpisodesStatsList,
    isLoading,
    optionsSelect,
    showFetchMore = false,
    fetchIsLoading = false,
    fetchMore = () => {},
}) => {
    if (isLoading)
        return (
            <DeprecatedPaper p={15}>
                {episodesList.map((_, index) => (
                    <LineCheckBoxFallback key={index} />
                ))}
            </DeprecatedPaper>
        );

    const [episodeListSelected, setEpisodesListSelected] = useState([]);
    const [statsShowSelected, setStatsShowSelected] = useState(false);

    const { search } = useLocation();
    const { episode } = parseQuery(search);

    const selectStatsShow = () => {
        setEpisodesListSelected([]);
        setStatsShowSelected(true);
    };

    const addEpisode = (id) => {
        setEpisodesListSelected([...episodeListSelected, id]);
        setStatsShowSelected(false);
    };

    const removeEpisode = (id) => {
        setEpisodesListSelected(episodeListSelected.filter((episodeId) => episodeId !== id));
    };

    const selectEpisode = useCallback(
        (id, checked) => {
            if (checked) addEpisode(id);
            else removeEpisode(id);
        },
        [episodeListSelected],
    );

    const selectAll = useCallback(
        (e) => {
            setStatsShowSelected(e.target.checked);
        },
        [statsShowSelected],
    );

    useEffect(() => {
        if (statsShowSelected) selectStatsShow();
    }, [statsShowSelected]);

    useEffect(() => {
        if (episodeListSelected.length === 0) selectStatsShow();
    }, [episodeListSelected.length === 0]);

    useEffect(() => {
        if (typeof episode === 'string') {
            setEpisodesListSelected(parseIntArray(episode));
            setStatsShowSelected(false);
        }
    }, []);

    return (
        <DeprecatedPaper px={15} py={10} flex column h="calc(100% - 250px)" overflowY="auto">
            <DeprecatedPaper flex as="label" cursor="pointer" align="center">
                <DeprecatedCheckbox
                    checked={statsShowSelected}
                    onChange={(e) => selectAll(e)}
                    mr={15}
                />
                <DeprecatedText ellipsis>
                    <FormattedMessage defaultMessage="Tous les épisodes" />
                </DeprecatedText>
            </DeprecatedPaper>
            {episodesList.length > 0 && (
                <DeprecatedText weight="semibold" color="--neutral500" py={15}>
                    <FormattedMessage defaultMessage="Épisodes spécifiques" />
                </DeprecatedText>
            )}

            {episodesList.map((episodeItemList) => (
                <ItemCheckbox
                    key={episodeItemList.id}
                    episodeItemList={episodeItemList}
                    episodeListSelected={episodeListSelected}
                    quotaSelectedEpisodes={quotaSelectedEpisodes}
                    optionsSelect={optionsSelect}
                    selectEpisode={selectEpisode}
                    statsShowSelected={statsShowSelected}
                    setEpisodesStatsList={setEpisodesStatsList}
                />
            ))}
            {showFetchMore && (
                <LoadMoreWrapper>
                    <Button
                        variant="ghost"
                        color="--primary"
                        onPress={() => !fetchIsLoading && fetchMore()}
                    >
                        {fetchIsLoading ? (
                            <IconLoader />
                        ) : (
                            <FormattedMessage defaultMessage="Charger plus d'épisodes" />
                        )}
                    </Button>
                </LoadMoreWrapper>
            )}
        </DeprecatedPaper>
    );
};

export default ItemEpisodesCheckBox;
