import { useState, useCallback, useMemo } from 'react';
import dayjs from 'dayjs';
import DatePicker from '../ui/atoms/DatePicker';
import TimePicker from '../ui/atoms/TimePicker';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TooltipTrigger } from 'react-aria-components';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FormattedMessage } from 'react-intl';
import Text from '../ui/atoms/Text';
import { getLocalTimeZone, parseDateTime, parseTime } from '@internationalized/date';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import Tooltip, { TooltipTriggerWrapper } from '../ui/Tooltip';
import ErrorMessage from '../ui/atoms/ErrorMessage';

interface DateTimePickerProps {
    className?: string;
    dateProps?: React.ComponentProps<typeof DatePicker>;
    timeProps?: React.ComponentProps<typeof TimePicker>;
    onChange?: (value: string) => void;
    value?: string;
    errorMessage?: string;
    isDisabled?: boolean;
}

const DateTimePicker = ({
    className,
    dateProps,
    timeProps,
    onChange,
    value,
    errorMessage,
    isDisabled,
}: DateTimePickerProps) => {
    const [date, setDate] = useState(value ? dayjs(value).format('YYYY-MM-DD') : '');
    const [time, setTime] = useState(value ? dayjs(value).format('HH:mm') : '');

    const { data: user } = useUserQuery();
    const timezone = useMemo(() => user?.timezone || getLocalTimeZone(), [user]);

    const handleDateChange = useCallback(
        (newDate) => {
            const formattedDate = newDate.toString();
            const newDateTime = dayjs(
                `${dayjs(formattedDate).format('YYYY-MM-DD')} ${time}`,
            ).format('YYYY-MM-DD HH:mm');
            setDate(formattedDate);
            onChange?.(newDateTime);
        },
        [time, onChange],
    );

    const handleTimeChange = useCallback(
        (newTime) => {
            const formattedTime = newTime.toString();
            const newDateTime = dayjs(
                `${dayjs(date).format('YYYY-MM-DD')} ${formattedTime}`,
            ).format('YYYY-MM-DD HH:mm');
            setTime(formattedTime);
            onChange?.(newDateTime);
        },
        [date, onChange],
    );

    return (
        <InputDateTimeWrapper>
            <InputDateTimeInnerWrapper className={className}>
                <FullWidthDatePicker
                    isDisabled={isDisabled}
                    {...dateProps}
                    onChange={handleDateChange}
                    value={parseDateTime(date)}
                    hideCustomTimezone
                />
                <Separator $shouldBeIndented={!!dateProps?.label || !!timeProps?.label}>
                    <FormattedMessage defaultMessage="à" />
                </Separator>
                <TimePicker
                    isDisabled={isDisabled}
                    {...timeProps}
                    onChange={handleTimeChange}
                    value={parseTime(time)}
                />
            </InputDateTimeInnerWrapper>
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            <TimezoneWrapper>
                <Text variant="bodyS" color="--neutral500">
                    {timezone}
                </Text>
                <TooltipTrigger delay={0} closeDelay={0}>
                    <TooltipTriggerWrapper>
                        <TooltipIcon icon={icon({ name: 'circle-info', style: 'solid' })} />
                    </TooltipTriggerWrapper>
                    <Tooltip placement="bottom">
                        <Text fontWeight="--fw-semibold">
                            <FormattedMessage defaultMessage="La modification du fuseau horaire de publication est accessible depuis votre compte." />
                        </Text>
                    </Tooltip>
                </TooltipTrigger>
            </TimezoneWrapper>
        </InputDateTimeWrapper>
    );
};

const InputDateTimeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
`;
const InputDateTimeInnerWrapper = styled.div`
    display: flex;
    align-items: end;
    column-gap: 1rem;

    & > :first-child {
        flex: 1;
    }
`;
const FullWidthDatePicker = styled(DatePicker)`
    max-width: 100%;
`;
const TimezoneWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.25rem;
`;
const TooltipIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: var(--neutral500);
`;
const Separator = styled.span<{ $shouldBeIndented: boolean }>`
    font-size: var(--fs-body-m);
    font-weight: var(--fw-semibold);
    ${({ $shouldBeIndented }) => $shouldBeIndented && `padding-top: 29px;`}
    align-self: center;
`;

export default DateTimePicker;
