import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updatePodcastListeningLink } from '@/api';
import episodeListeningLinkKeys from '@/queries/episodeListeningLink/episodeListeningLinkKeys';
import camelcaseKeys from 'camelcase-keys';

export interface MutationFnProps {
    episodeId: string;
    key: string;
    url: string;
}

const mutationFn = async ({ episodeId, key, url }: MutationFnProps) => {
    const { data } = await updatePodcastListeningLink(episodeId, key, {
        url,
    });

    return camelcaseKeys(data, { deep: true });
};

const useUpdateEpisodeListeningLinkMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: (_, { episodeId }) => {
            queryClient.invalidateQueries({
                queryKey: episodeListeningLinkKeys.listByEpisodeId(episodeId),
            });
        },
    });
};

export default useUpdateEpisodeListeningLinkMutation;
