import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';

const useStore = () => {
    const { stores } = useContext(MobXProviderContext);

    return stores;
};

export default useStore;
