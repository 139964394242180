import Text from '@/components/ui/atoms/Text';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { ChannelGuestRow } from './ChannelGuestsTable';

interface ChannelGuestsPermissionCellProps extends ChannelGuestRow {}

const ChannelGuestsPermissionCell = ({
    userName,
    userAvatar,
    userEmail,
}: ChannelGuestsPermissionCellProps) => {
    return (
        <Cell>
            {userAvatar ? (
                <UserAvatar src={userAvatar} alt={userName ?? 'avatar'} />
            ) : (
                <AvatarPlaceholder>
                    <AvatarPlaceholderIcon
                        icon={icon({ name: 'user-astronaut', style: 'solid' })}
                    />
                </AvatarPlaceholder>
            )}
            <UserInformationWrapper>
                <Text numberOfLines={1} fontWeight="--fw-semibold">
                    {userName}
                </Text>
                <Text numberOfLines={1} color="--neutral500">
                    {userEmail}
                </Text>
            </UserInformationWrapper>
        </Cell>
    );
};

const UserAvatar = styled.img`
    display: none;
    width: 2rem;
    height: 2rem;
    border-radius: var(--r-full);
    object-fit: cover;
    flex-shrink: 0;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: block;
    }
`;
const AvatarPlaceholder = styled.div`
    display: none;
    width: 2rem;
    height: 2rem;
    border-radius: var(--r-full);
    background-color: var(--neutral50);

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: flex;
        align-items: end;
        justify-content: center;
        overflow: hidden;
        flex-shrink: 0;
    }
`;
const AvatarPlaceholderIcon = styled(FontAwesomeIcon)`
    width: 1.5rem;
    height: 1.5rem;
    color: var(--neutral500);
`;
const UserInformationWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 0;
    flex-grow: 1;
`;
const Cell = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.75rem;
`;

export default ChannelGuestsPermissionCell;
