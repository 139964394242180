import { useState } from 'react';
import { useParams, useHistory } from 'react-router';
import { useIntl, FormattedMessage } from 'react-intl';
import { SearchField, Input } from 'react-aria-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useEpisodeFiltersContext } from '@/context/EpisodeFiltersContext';
import { useDebounce } from '@/utils/hooks/useDebounceNew';
import Button from '@/components/Button';
import useAddEpisodeMutation from '@/queries/episode/useAddEpisodeMutation.hook';
import { useModalToastQueue } from '@/utils/hooks/useModalToastQueue.hook';
import styled from 'styled-components';

const PageHeader = () => {
    const { showId } = useParams<{ showId: string }>();
    const toast = useModalToastQueue();
    const history = useHistory();
    const intl = useIntl();
    const addEpisodeMutation = useAddEpisodeMutation();
    const { setSearch, search } = useEpisodeFiltersContext();
    const [searchValue, setSearchValue] = useState(search);

    const onChange = () => {
        setSearch(searchValue);
    };

    const generateEpisodeName = () => {
        return intl.formatMessage(
            { defaultMessage: 'Nouvel épisode du {date} {time}' },
            {
                date: intl.formatDate(new Date(), {
                    day: '2-digit',
                    month: '2-digit',
                }),
                time: intl.formatTime(new Date()),
            },
        );
    };

    const debouncedOnChange = useDebounce(onChange);

    return (
        <EpisodesHeader>
            <StyledSearchField
                value={searchValue}
                onChange={(value: string) => {
                    debouncedOnChange();
                    setSearchValue(value);
                }}
            >
                <SearchIconWrapper>
                    <FontAwesomeIcon icon={icon({ name: 'magnifying-glass', style: 'solid' })} />
                </SearchIconWrapper>
                <StyledInput
                    placeholder={intl.formatMessage({
                        defaultMessage: 'Rechercher un épisode...',
                    })}
                />
            </StyledSearchField>

            <Button
                onPress={() => {
                    addEpisodeMutation.mutate(
                        {
                            showId,
                            name: generateEpisodeName(),
                        },
                        {
                            onSuccess: (episode) => {
                                if (!episode?.id) return;
                                history.push(
                                    `/app/show/${showId}/workspace/episodes/${episode.id}/edit`,
                                );
                            },
                            onError: () => {
                                toast.alert();
                            },
                        },
                    );
                }}
                startIcon={<FontAwesomeIcon icon={icon({ name: 'pen-line', style: 'solid' })} />}
            >
                <FormattedMessage defaultMessage="Travailler sur un nouvel épisode" />
            </Button>
        </EpisodesHeader>
    );
};

const EpisodesHeader = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 0.5rem;
`;
const StyledSearchField = styled(SearchField)`
    flex-grow: 1;
    display: flex;
    align-items: center;
    background-color: var(--neutral100);
    border-radius: var(--r-s);
    padding-inline: 0.5rem;

    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        flex-grow: 0;
    }
`;
const StyledInput = styled(Input)`
    border: 0;
    height: 100%;
    padding-block: 0.75rem;
    background-color: transparent;
    min-width: 12rem;
`;
const SearchIconWrapper = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    padding-inline: 0.75rem;
    color: var(--neutral500);
`;

export default PageHeader;
