import usePlaylistsQuery from '@/queries/playlist/usePlaylistsQuery.hook';
import { Redirect, useParams } from 'react-router';
import PlaylistsEmptyState from '@/components/Playlists/PlaylistsEmptyState';
import Playlist from './Playlist';
import Spinner from '../ui/atoms/Spinner';

const Playlists = () => {
    const { showId, playlistId } = useParams<{ showId: string; playlistId?: string }>();
    const playlists = usePlaylistsQuery(showId);

    if (playlists.isError) {
        return <div>Error</div>; // TODO: Add error state
    }

    if (playlists.isSuccess) {
        if (playlists.data.length === 0) {
            return <PlaylistsEmptyState />;
        }

        if (!playlistId) {
            return <Redirect to={`/app/show/${showId}/playlists/${playlists.data[0].id}`} />;
        }

        return <Playlist />;
    }

    return <Spinner />;
};

export default Playlists;
