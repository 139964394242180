import statsKeys from '@queries/stats/statsKeys';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { fetchPeakTimePopularDays } from '@/api';
import { z } from 'zod';
import camelcaseKeys from 'camelcase-keys';

interface PeakTimePopularDaysQueryParams {
    showId: string;
    days: '7' | '15' | '30' | '90' | 'always';
}

const DaySchema = z.object({
    downloads: z.number(),
    weekDay: z.number(),
});

const DaysSchema = z.array(DaySchema);
export type Day = z.infer<typeof DaySchema>;

type Key = QueryFunctionContext<ReturnType<typeof statsKeys.popularDaysByShowId>>;

const queryFn = async ({ queryKey: [{ showId, days }] }: Key) => {
    const { data } = await fetchPeakTimePopularDays(showId, { days });

    return DaysSchema.parse(camelcaseKeys(data, { deep: true }));
};

const usePeakTimePopularDaysQuery = ({ showId, days }: PeakTimePopularDaysQueryParams) => {
    return useQuery({
        queryKey: statsKeys.popularDaysByShowId(showId, days),
        queryFn,
        enabled: !!showId && !!days,
    });
};

export default usePeakTimePopularDaysQuery;
