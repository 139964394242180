import { useMutation } from '@tanstack/react-query';
import { deleteShowImage } from '@/api';

const mutationFn = async (showId: string): Promise<void> => {
    return await deleteShowImage(showId);
};

const deleteShowImageMutation = () => {
    return useMutation({
        mutationFn,
    });
};

export default deleteShowImageMutation;
