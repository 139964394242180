import { useEffect, useCallback, useState } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { AnimatePresence, motion } from 'framer-motion';
import DeprecatedPaper from '../../ui/atoms/DeprecatedPaper';
import DeprecatedButton from '../../ui/atoms/DeprecatedButton';
import DeprecatedIcon from '../../ui/atoms/DeprecatedIcon';
import DeprecatedModal from '../../ui/molecules/DeprecatedModal';
import DeprecatedText from '../../ui/atoms/DeprecatedText';
import warning from '@public/icons/warning.svg';
import less from '@public/icons/less.svg';
import more from '@public/icons/more.svg';
import Tooltip from '../../ui/atoms/Tooltip';
import DeprecatedWhitePaper from '../../ui/atoms/DeprecatedWhitePaper';
import { connect } from '../decorators/connect';
import Spinner from '../../ui/atoms/Spinner';
import { priceValueToInt } from '../../../helpers';
import useNewsletterQuotaQuery from '@queries/quota/useNewsletterQuotaQuery.hook';
import IconInfo from '@ui/icons/IconInfo';
import Text from '@ui/atoms/Text';
import Alert from '@ui/atoms/Alert';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import { QUOTA_TYPES, SHOW_QUOTA_TYPES } from '@/utils/constants';
import useSubscriptionQuery from '@/queries/subscription/useSubscriptionQuery.hook';
import usePricesQuery from '@/queries/price/usePricesQuery.hook';

const animatedWarn = {
    hidden: { opacity: 0, height: 0, top: -20 },
    visible: { opacity: 1, height: 'max-content', top: 0 },
};

const enhance = connect(({ showStore, subscriptionStore, quotaStore }) => ({
    totalShowsActive: showStore.statusAllShows.activeShows.totalShowsActive,
    activeShowsQuotaMax: subscriptionStore.subscription.showsMax,
    extraShows: subscriptionStore.subscription.extraShows,
    addPricesQuota: (type, quota) => quotaStore.addPricesQuota(type, quota),
    setQuota: (currentQuota) => quotaStore.setNewQuota(currentQuota),
    isLoading: quotaStore.addPricesQuota.pending,
}));

const QuotaContentModal = ({
    isOpened,
    onClose,
    onOpenConfirmQuotasModal,
    totalShowsActive,
    activeShowsQuotaMax,
    extraShows,
    setQuota,
    addPricesQuota,
    isLoading,
    type,
}) => {
    const { data: subscription } = useSubscriptionQuery();
    const { data: prices } = usePricesQuery();

    const { data: newsletterQuota, isLoading: fetchNewsletterQuotaIsLoading } =
        useNewsletterQuotaQuery();

    const quotaType = {
        [QUOTA_TYPES.NEWSLETTER]: newsletterQuota?.maximum,
        activeShows: activeShowsQuotaMax,
    };

    const { data: user } = useUserQuery();

    const [newQuota, setNewQuota] = useState(quotaType[type]);

    const subscriptionPeriodicity = subscription?.yearly ? 'yearly' : 'monthly';

    const getQuotasType = () => {
        if (!newsletterQuota) return {};

        switch (type) {
            case QUOTA_TYPES.NEWSLETTER:
                return {
                    currentQuota: newsletterQuota.maximum,
                    step: newsletterQuota.step,
                    unitPrice:
                        prices?.[subscription?.currency]?.newsletter?.[subscriptionPeriodicity]
                            ?.base,
                    disableStep: newQuota === newsletterQuota.minimum,
                    disableButton: newQuota === quotaType[type],
                    warningTooltip: newQuota < quotaType[type],
                };
            case SHOW_QUOTA_TYPES.ACTIVE_SHOWS:
                return {
                    currentQuota: activeShowsQuotaMax,
                    step: 1,
                    unitPrice:
                        prices?.[subscription?.currency]?.[subscription.pricing]?.[
                            subscriptionPeriodicity
                        ]?.extra,
                    disableStep: newQuota === 1,
                    disableButton:
                        newQuota + extraShows < totalShowsActive || newQuota === quotaType[type],
                    warningTooltip: newQuota + extraShows < totalShowsActive,
                };
            default:
                return {};
        }
    };

    const { step, currentQuota, unitPrice, disableStep, disableButton, warningTooltip } =
        getQuotasType();

    useEffect(() => {
        setQuota(newQuota);
    }, [newQuota]);

    useEffect(() => {
        setNewQuota(quotaType[type]);
    }, [quotaType[type]]);

    const decrement = useCallback(() => {
        setNewQuota(newQuota - step);
    }, [newQuota, step]);

    const increment = useCallback(() => {
        setNewQuota(newQuota + step);
    }, [newQuota, step]);

    const price = (priceValueToInt(unitPrice) * (newQuota - currentQuota)) / step;

    const MESSAGES = {
        [QUOTA_TYPES.NEWSLETTER]: {
            description: (
                <FormattedMessage
                    defaultMessage="Vous pouvez modifier votre quota d’emails mensuels par tranche de {quotaStep} emails."
                    values={{ quotaStep: step }}
                />
            ),
            tooltipOffer: (
                <FormattedMessage
                    defaultMessage="Avec l’offre {offer}, vous bénéficiez d’un quota de"
                    values={{ offer: subscription?.pricing }}
                />
            ),
            tooltipQuota: (
                <FormattedMessage
                    defaultMessage="{quotaMinimum} emails par mois."
                    values={{ quotaMinimum: <FormattedNumber value={newsletterQuota?.minimum} /> }}
                />
            ),
            addingQuota: <FormattedMessage defaultMessage="Emails supplémentaires par mois" />,
            quotaPrice: (
                <FormattedMessage
                    defaultMessage="{price} / {currentSubscription}"
                    values={{
                        price: (
                            <FormattedNumber
                                value={price}
                                style="currency"
                                currency={user.currency}
                                currencyDisplay="narrowSymbol"
                                maximumFractionDigits={Number.isInteger(price) ? 0 : 2}
                            />
                        ),
                        currentSubscription: subscription.yearly ? (
                            <FormattedMessage defaultMessage="an" />
                        ) : (
                            <FormattedMessage defaultMessage="mois" />
                        ),
                    }}
                />
            ),
            animatedTooltip: (
                <FormattedMessage defaultMessage="En diminuant votre quota mensuel, votre newsletter risque de ne pas être envoyée à tous vos abonnés." />
            ),
            resumeNewQuota: (
                <FormattedMessage defaultMessage="Votre nouveau quota d'emails sera de" />
            ),
            newQuota: (
                <FormattedMessage defaultMessage="{newQuota} emails." values={{ newQuota }} />
            ),
        },
        activeShows: {
            description: (
                <FormattedMessage defaultMessage="Vous pouvez ajuster votre quota d'émissions actives à l'unité." />
            ),
            tooltipOffer: (
                <FormattedMessage defaultMessage="Vous bénéficiez actuellement d’un quota de" />
            ),
            tooltipQuota: (
                <FormattedMessage
                    defaultMessage="{currentQuota, plural, one {# émission active} other {# émissions actives}}."
                    values={{ currentQuota: activeShowsQuotaMax }}
                />
            ),
            addingQuota: <FormattedMessage defaultMessage="Emission active supplémentaire" />,
            quotaPrice: (
                <FormattedMessage
                    defaultMessage="{price} / {currentSubscription}"
                    values={{
                        price: (
                            <FormattedNumber
                                value={price}
                                style="currency"
                                currency={user.currency}
                                currencyDisplay="narrowSymbol"
                                maximumFractionDigits={Number.isInteger(price) ? 0 : 2}
                            />
                        ),
                        currentSubscription: subscription.yearly ? (
                            <FormattedMessage defaultMessage="an" />
                        ) : (
                            <FormattedMessage defaultMessage="mois" />
                        ),
                    }}
                />
            ),
            animatedTooltip: (
                <FormattedMessage
                    defaultMessage="Pour diminuer votre quota d'émissions actives, vous devez supprimer {quota, plural, one {# émission active} other {# émissions actives}}"
                    values={{ quota: totalShowsActive - (newQuota + extraShows) }}
                />
            ),
            resumeNewQuota: (
                <FormattedMessage defaultMessage="Votre nouveau quota d'émissions actives sera de" />
            ),
            newQuota: (
                <FormattedMessage
                    defaultMessage="{newQuota, plural, one {# émission active} other {# émissions actives}}."
                    values={{ newQuota }}
                />
            ),
        },
    };

    const submitPrice = async () => {
        await addPricesQuota(type, newQuota);
        onOpenConfirmQuotasModal();
    };

    const description = <DeprecatedText mb={15}>{MESSAGES[type].description}</DeprecatedText>;

    const tooltip = (
        <Alert variant="info" icon={<IconInfo />}>
            <Text as="span" variant="footnote">
                {MESSAGES[type].tooltipOffer}
            </Text>{' '}
            <Text as="span" variant="footnote" fontWeight="--fw-bold">
                {MESSAGES[type].tooltipQuota}
            </Text>
        </Alert>
    );

    const addingQuota = (
        <DeprecatedWhitePaper flex justify="space-between">
            <DeprecatedPaper m={15}>
                <DeprecatedText weight="bold">{MESSAGES[type].addingQuota}</DeprecatedText>
                <DeprecatedText>{MESSAGES[type].quotaPrice}</DeprecatedText>
            </DeprecatedPaper>
            <DeprecatedPaper flex align="center" w="auto" m={15}>
                <DeprecatedButton
                    disabled={disableStep}
                    onClick={decrement}
                    mr={20}
                    icon
                    accent="secondary"
                >
                    <DeprecatedIcon as={less} color="black" />
                </DeprecatedButton>
                <DeprecatedText
                    flex
                    justify="center"
                    minW={type === QUOTA_TYPES.NEWSLETTER ? 44 : 21}
                    weight="semibold"
                    size="big"
                >
                    <FormattedNumber value={newQuota - currentQuota} />
                </DeprecatedText>
                <DeprecatedButton onClick={increment} ml={20} icon accent="secondary">
                    <DeprecatedIcon as={more} color="black" />
                </DeprecatedButton>
            </DeprecatedPaper>
        </DeprecatedWhitePaper>
    );

    const animatedTooltip = (
        <AnimatePresence>
            {warningTooltip && (
                <motion.div
                    initial="hidden"
                    animate={newQuota < currentQuota ? 'visible' : 'hidden'}
                    exit="hidden"
                    variants={animatedWarn}
                >
                    <Tooltip type="error" light my={15} p={10} align="end">
                        <DeprecatedIcon as={warning} color="--alert" mr={8} />
                        <DeprecatedText color="--alert" size="small">
                            {MESSAGES[type].animatedTooltip}
                        </DeprecatedText>
                    </Tooltip>
                </motion.div>
            )}
        </AnimatePresence>
    );

    const newQuotaMessage = (
        <DeprecatedPaper flex my={15}>
            <DeprecatedText size="small">{MESSAGES[type].resumeNewQuota}</DeprecatedText>
            <DeprecatedText as="span" size="small" weight="bold" ml={5}>
                {MESSAGES[type].newQuota}
            </DeprecatedText>
        </DeprecatedPaper>
    );

    return (
        <DeprecatedModal
            maxW={605}
            w={605}
            title={<FormattedMessage defaultMessage="Modifier mon quota" />}
            onClose={onClose}
            isOpened={isOpened}
        >
            <>
                {description}
                {tooltip}
                {addingQuota}
                {animatedTooltip}
                {newQuotaMessage}
                <DeprecatedPaper flex justify="flex-end" mt={30}>
                    <DeprecatedButton type="button" mr={10} accent="secondary" onClick={onClose}>
                        <FormattedMessage defaultMessage="Annuler" />
                    </DeprecatedButton>
                    <DeprecatedButton
                        disabled={disableButton || isLoading || fetchNewsletterQuotaIsLoading}
                        onClick={() => submitPrice()}
                    >
                        {(isLoading || fetchNewsletterQuotaIsLoading) && <Spinner />}
                        <FormattedMessage defaultMessage="Modifier mon quota" />
                    </DeprecatedButton>
                </DeprecatedPaper>
            </>
        </DeprecatedModal>
    );
};

export default enhance(QuotaContentModal);
