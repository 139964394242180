import styled from 'styled-components';
import { Observer } from 'mobx-react';
import { reflex } from '@ui/reflex';

const DeprecatedEmptyInput = reflex(styled.input`
    height: 100%;
    color: var(--black);
    font-size: var(--fs-body);
    border: 0;
    background: transparent;
    ::placeholder {
        color: var(--neutral500);
    }
    &:disabled {
        pointer-events: none;
        -webkit-text-fill-color: var(--neutral200);
        color: var(--neutral200);
    }
`);

// See https://github.com/mobxjs/mobx-react/issues/594
const ObservedEmptyInput = (props) => (
    <Observer>{() => <DeprecatedEmptyInput {...props} />}</Observer>
);

export default ObservedEmptyInput;
