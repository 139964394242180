import { FormattedMessage } from 'react-intl';
import Button from '@/components/Button';
import styled from 'styled-components';
import Modal from '@/components/ui/atoms/Modal';
import Stack from '@/components/ui/layout/Stack';
import Text from '@/components/ui/atoms/Text';
import IconButton from '@/components/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

interface GuestRolesDetailsModalProps {
    isOpen: boolean;
    onOpenChange: (open: boolean) => void;
}

const GuestRolesDetailsModal = ({ isOpen, onOpenChange }: GuestRolesDetailsModalProps) => {
    return (
        <ModalStyled isOpen={isOpen} onOpenChange={onOpenChange} size="medium">
            <Header>
                <Text variant="headingM" fontWeight="--fw-bold">
                    <FormattedMessage defaultMessage="Détails des rôles" />
                </Text>
                <CloseButton
                    icon={<CloseIcon icon={icon({ name: 'xmark-large', style: 'solid' })} />}
                    onPress={() => onOpenChange(false)}
                    variant="ghost"
                    aria-label="Close modal"
                />
            </Header>
            <Stack $gap="1.5rem">
                <Role>
                    <RoleIcon icon={icon({ name: 'eye', style: 'solid' })} />
                    <RoleInner>
                        <Text variant="bodyL" fontWeight="--fw-semibold">
                            <FormattedMessage defaultMessage="Lecteur" />
                        </Text>
                        <Text color="--neutral500">
                            <FormattedMessage defaultMessage="Les lecteurs peuvent accéder aux épisodes et aux statistiques de l’émission, mais ne sont pas autorisés à éditer." />
                        </Text>
                    </RoleInner>
                </Role>
                <Role>
                    <RoleIcon icon={icon({ name: 'feather', style: 'solid' })} />
                    <RoleInner>
                        <Text variant="bodyL" fontWeight="--fw-semibold">
                            <FormattedMessage defaultMessage="Éditeur" />
                        </Text>
                        <Text color="--neutral500">
                            <FormattedMessage defaultMessage=" Les éditeurs peuvent ajouter et modifier les épisodes de l’émission, créer des clips vidéos, modifier le SmartPlayer et accéder aux campagnes manuelles." />
                        </Text>
                    </RoleInner>
                </Role>
                <Role>
                    <RoleIcon icon={icon({ name: 'screwdriver-wrench', style: 'solid' })} />
                    <RoleInner>
                        <Text variant="bodyL" fontWeight="--fw-semibold">
                            <FormattedMessage defaultMessage="Administrateur" />
                        </Text>
                        <Text color="--neutral500">
                            <FormattedMessage defaultMessage="Les administrateurs possèdent les mêmes droits que les éditeurs, et peuvent également modifier les rôles des utilisateurs, supprimer des épisodes, accéder à tous les outils de communication ainsi qu'à tous les paramètres de l’émission." />
                        </Text>
                    </RoleInner>
                </Role>
            </Stack>
            <Stack $gap="1.5rem" $align="center">
                <Separator />
                <Button onPress={() => onOpenChange(false)}>
                    <FormattedMessage defaultMessage="J'ai compris" />
                </Button>
            </Stack>
        </ModalStyled>
    );
};

const ModalStyled = styled(Modal)`
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 2rem;
`;
const Separator = styled.div`
    height: 1px;
    background-color: var(--neutral100);
    width: 100%;
`;
const Header = styled.div`
    display: flex;
    justify-content: space-between;
`;
const CloseIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--neutral500);
`;
const CloseButton = styled(IconButton)`
    padding: 0;
`;
const RoleIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    padding-top: 0.25rem;
`;
const Role = styled.div`
    display: flex;
    column-gap: 0.5rem;
`;
const RoleInner = styled.div`
    display: flex;
    flex-direction: column;
`;

export default GuestRolesDetailsModal;
