import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ShowGuestsInvitationDeleteModal from './ShowGuestsInvitationDeleteModal';
import { ShowGuestInvitation } from '@/queries/guest/useShowGuestInvitationsQuery.hook';
import styled from 'styled-components';
import IconButton from '@/components/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Text from '@/components/ui/atoms/Text';
import { IMG_PLACEHOLDER } from '@/shared/config/constants';

interface ShowGuestsInvitationProps {
    invitation: ShowGuestInvitation;
}

const ShowGuestsInvitation = ({ invitation }: ShowGuestsInvitationProps) => {
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const intl = useIntl();

    return (
        <>
            <Wrapper>
                <Img src={invitation.avatar ?? IMG_PLACEHOLDER} />
                <Email>
                    {`${invitation.email} `}
                    <FormattedMessage defaultMessage="(invitation envoyée)" />
                </Email>
                <DeleteButton
                    variant="danger"
                    icon={<ButtonIcon icon={icon({ name: 'trash', style: 'solid' })} />}
                    onPress={() => setIsDeleteModalOpen(true)}
                    aria-label={intl.formatMessage({ defaultMessage: 'Supprimer' })}
                />
            </Wrapper>
            <ShowGuestsInvitationDeleteModal
                invitation={invitation}
                isOpen={isDeleteModalOpen}
                onOpenChange={setIsDeleteModalOpen}
            />
        </>
    );
};

const Wrapper = styled.div`
    height: 4.75rem;
    border-bottom: 1px solid var(--neutral100);
    display: flex;
    align-items: center;
    column-gap: 1rem;
`;
const DeleteButton = styled(IconButton)`
    padding: 1rem;
    flex-shrink: 0;
`;
const ButtonIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const Img = styled.img`
    width: 2.5rem;
    height: 2.5rem;
    border-radius: var(--r-xs);
    flex-shrink: 0;
`;
const Email = styled(Text)`
    color: var(--neutral500);
    font-weight: var(--fw-semibold);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    min-width: 0;
    flex-grow: 1;
`;

export default ShowGuestsInvitation;
