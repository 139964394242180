import { useState } from 'react';
import { useParams } from 'react-router';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { ICON_LIST_CTA } from '../CallToAction';
import useCallToActionButtonsQuery from '@/queries/callToAction/useCallToActionButtonsQuery.hook';
import { Header, Menu, MenuItem, MenuTrigger, Popover } from 'react-aria-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Button from '@/components/Button';
import styled from 'styled-components';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import useWebsiteSettingsQuery from '@/queries/websiteSettings/useWebsiteSettingsQuery.hook';
import useSmartlinkSettingsQuery from '@/queries/smartlinkSetting/useSmartlinkSettingsQuery.hook';
import useShowNewsletterTemplateQuery from '@/queries/newsletter/useShowNewsletterTemplateQuery.hook';
import CallToActionAddModal from '../AddCallToActionModal';
import { CallToActionFormSchema } from '../CallToActionForm/useCallToActionFormSchema.hook';

const DEFAULT_CTA_MESSAGES = defineMessages({
    'callToAction.newsletter': { defaultMessage: "S'inscrire à ma newsletter" },
    'callToAction.podcastPage': { defaultMessage: 'Site Web' },
    'callToAction.smartlink': { defaultMessage: 'Smartlink' },
    'callToAction.crowdfunding': { defaultMessage: 'Soutenez-moi !' },
});

const CallToActionMenu = () => {
    const intl = useIntl();
    const { showId } = useParams<{ showId: string }>();
    const show = useShowQuery(showId);
    const websiteSettings = useWebsiteSettingsQuery({ showId });
    const smartlinkSettings = useSmartlinkSettingsQuery(showId);
    const newsletterTemplate = useShowNewsletterTemplateQuery(showId);
    const callToActionButtons = useCallToActionButtonsQuery({
        showId,
    });
    const hasError =
        callToActionButtons.isError ||
        show.isError ||
        websiteSettings.isError ||
        smartlinkSettings.isError;
    const isLoading =
        callToActionButtons.isLoading ||
        show.isLoading ||
        websiteSettings.isLoading ||
        smartlinkSettings.isLoading;

    const [isAddCTAModalOpen, setIsAddCTAModalOpen] = useState(false);
    const [ctaToAdd, setCtaToAdd] = useState<any | null>(null);

    if (hasError || isLoading) return null;

    const defaultCTAList: CallToActionFormSchema[] = [
        {
            url: websiteSettings.data.isPublished ? `${show.data.siteUrl}` : '',
            text: intl.formatMessage(DEFAULT_CTA_MESSAGES['callToAction.podcastPage']),
            type: 'podcast_page',
            locations: [],
        },
        {
            url: show.data.socialLinks.data.find((link) => link.key === 'monetization')?.url || '',
            text: intl.formatMessage(DEFAULT_CTA_MESSAGES['callToAction.crowdfunding']),
            type: 'crowdfunding',
            locations: [],
        },
        {
            url: smartlinkSettings.data.isPublished
                ? `${process.env.URL_SMARTLINKS_AUSHA}${show.data.slug}`
                : '',
            text: intl.formatMessage(DEFAULT_CTA_MESSAGES['callToAction.smartlink']),
            type: 'smartlink',
            locations: [],
        },
        {
            url: newsletterTemplate.data?.activated ? `${show.data.siteUrl}?s=1` : '',
            text: intl.formatMessage(DEFAULT_CTA_MESSAGES['callToAction.newsletter']),
            type: 'newsletter',
            locations: [],
        },
    ];

    const defaultCallToActionsAvailable = defaultCTAList.filter(
        (cta) => cta.url && !callToActionButtons.data?.find((c) => c.type === cta.type),
    );

    const handleOpenAddCTAModal = (cta: CallToActionFormSchema) => {
        setCtaToAdd(cta);
        setIsAddCTAModalOpen(true);
    };

    return (
        <>
            <MenuTrigger>
                <Button
                    aria-label="Menu"
                    startIcon={<FontAwesomeIcon icon={icon({ name: 'plus', style: 'solid' })} />}
                >
                    <FormattedMessage defaultMessage="Créer un bouton personnalisé" />
                </Button>
                <StyledPopover>
                    <StyledMenu>
                        <StyledMenuItem>
                            <StyledHeader>
                                <FormattedMessage defaultMessage="Accès direct" />
                            </StyledHeader>
                        </StyledMenuItem>
                        {defaultCallToActionsAvailable.map((item) => (
                            <StyledMenuItem
                                key={item.type}
                                onAction={() => {
                                    handleOpenAddCTAModal(item);
                                }}
                            >
                                <MenuItemInnerWrapper>
                                    <MenuItemTitleWrapper>
                                        {ICON_LIST_CTA[item.type]}
                                        <MenuItemTitle>{item.text}</MenuItemTitle>
                                    </MenuItemTitleWrapper>
                                    <Url>{item.url}</Url>
                                </MenuItemInnerWrapper>
                            </StyledMenuItem>
                        ))}
                        <StyledMenuItem
                            onAction={() => {
                                handleOpenAddCTAModal({
                                    text: '',
                                    url: '',
                                    locations: [],
                                    type: 'custom',
                                });
                            }}
                        >
                            <MenuItemInnerWrapper>
                                <NewButtonButton
                                    variant="ghost"
                                    size="small"
                                    startIcon={
                                        <FontAwesomeIcon
                                            icon={icon({ name: 'plus', style: 'solid' })}
                                        />
                                    }
                                >
                                    <FormattedMessage defaultMessage="Nouveau bouton" />
                                </NewButtonButton>
                            </MenuItemInnerWrapper>
                        </StyledMenuItem>
                    </StyledMenu>
                </StyledPopover>
            </MenuTrigger>
            <CallToActionAddModal
                isOpen={isAddCTAModalOpen}
                onOpenChange={setIsAddCTAModalOpen}
                cta={ctaToAdd}
            />
        </>
    );
};

const StyledPopover = styled(Popover)`
    background-color: var(--white);
    border-radius: var(--r-xs);
    box-shadow: var(--s-light);
`;
const StyledMenu = styled(Menu)`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;
const StyledMenuItem = styled(MenuItem)`
    cursor: pointer;
`;
const MenuItemInnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-block: 0.5rem;
    padding-inline: 0.75rem;

    &:hover {
        background-color: var(--neutral50);
    }
`;
const MenuItemTitleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: var(--fs-body-s);
`;
const MenuItemTitle = styled.span`
    font-size: var(--fs-body-m);
`;
const StyledHeader = styled(Header)`
    font-weight: var(--fw-semibold);
    font-size: var(--fs-body-s);
    color: var(--neutral500);
    padding-block: 0.5rem;
    padding-inline: 0.75rem;
`;
const Url = styled.span`
    font-size: var(--fs-body-s);
    color: var(--neutral500);
    padding-left: 1.25rem;
`;
const NewButtonButton = styled(Button)`
    width: max-content;
`;

export default CallToActionMenu;
