import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import EpisodeEdit from '@app/molecules/EpisodeEdit';
import { DEBUG_EPISODE_EDIT_FORM, ENABLE_EPISODE_AI, EPISODE_STATUS } from '@/utils/constants';
import styled from 'styled-components';
import { useResponsive } from '@hooks/useResponsive';
import SaveDraftEpisodeAndCloseButton from '@app/organisms/EpisodeEditForm/actions/SaveDraftEpisodeAndCloseButton';
import SaveAndCloseButton from '@app/organisms/EpisodeEditForm/actions/SaveAndCloseButton';
import CancelButton from '@app/organisms/EpisodeEditForm/actions/CancelButton';
import MoveToDraftButton from '@app/organisms/EpisodeEditForm/actions/MoveToDraftButton';
import PublishButton from '@app/organisms/EpisodeEditForm/actions/PublishButton';
import ScheduleButton from '@app/organisms/EpisodeEditForm/actions/ScheduleButton';
import Cluster from '@ui/layout/Cluster';
import { FormattedDate, FormattedMessage, FormattedTime, useIntl } from 'react-intl';
import getEpisodeEditFormSchema from '@app/organisms/EpisodeEditForm/schemas/episodeEditFormSchema';
import UnsavedFormModal from '@app/molecules/UnsavedFormModal';
import { DevTool } from '@hookform/devtools';
import { YOUTUBE_VIDEO_STATUS } from '@/utils/constants';
import { PsoCheckerContextProvider } from '@/context/PsoCheckerContext';
import Text from '@/components/ui/atoms/Text';
import { useEpisodeEditState } from '@/context/EpisodeEditContext';
import EpisodeEditTranscriptionProvider from '../../molecules/EpisodeEditTranscription/EpisodeEditTranscriptionProvider';
import EpisodeAIBubble from '@/components/EpisodeAI/EpisodeAIBubble';
import EpisodeAILoadingContextProvider from '@/components/EpisodeAI/contexts/EpisodeAILoadingContext';
import EpisodeAISettingsContextProvider from '@/components/EpisodeAI/contexts/EpisodeAISettingsContext';
import EpisodeAIFreeTrialModal from '@/components/EpisodeAI/EpisodeAIFreeTrialModal';
import useHasAccessToEpisodeAI from '@hooks/useHasAccessToEpisodeAI.hook';
import useChannelQuery from '@/queries/channel/useChannelQuery.hook';

const Container = styled.section`
    background-color: var(--neutral50);
    display: flex;
    flex-direction: column;
    height: 100%;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        background-color: var(--white);
    }
`;
const Header = styled.header`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;
    padding: 1rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        padding: 2rem;
    }
`;
const Content = styled.div`
    flex-grow: 1;
    min-height: 0;
    overflow-y: auto;
    padding: 0 1rem 1rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: flex;
        justify-content: center;
        background-color: var(--neutral50);
        border-radius: var(--r-l) var(--r-l) 0 0;
        margin-inline: 2rem;
        padding: 2rem;
    }
`;
const Footer = styled.footer`
    position: relative;
    background-color: var(--white);
    border-top: 1px solid var(--neutral100);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5rem 1rem;
    padding: 1rem;

    > * {
        flex-grow: 1;
    }

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: none;
    }
`;
const ResponsiveWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
        align-items: center;
        column-gap: 1rem;
    }
`;

const EpisodeEditForm = ({ episode, chapters }) => {
    const intl = useIntl();
    const channel = useChannelQuery();
    const { isMobileOrTablet } = useResponsive();
    const schema = getEpisodeEditFormSchema(intl);
    const isYoutubeVideoPendingOrProcessing =
        episode?.youtubeVideo?.data.state === YOUTUBE_VIDEO_STATUS.DRAFT ||
        episode?.youtubeVideo?.data.state === YOUTUBE_VIDEO_STATUS.PENDING ||
        episode?.youtubeVideo?.data.state === YOUTUBE_VIDEO_STATUS.PROCESSING;
    const [isNew] = useEpisodeEditState();
    const [isAiModalOpen, setIsAiModalOpen] = useState(false);

    const methods = useForm({
        mode: 'onBlur',
        values: {
            content: {
                name: episode.name,
                slug: episode.slug,
                useCustomUrl: episode.useCustomUrl,
                customUrl: episode.customUrl,
                htmlDescription: episode.htmlDescription,
                tags: episode.tags.map((tag) => tag.name),
                imageUrl: episode.imageUrl,
            },
            options: {
                seasonId: episode.seasonId,
                type: episode.type,
                isExplicit: episode.isExplicit,
                publishYoutube: !!isYoutubeVideoPendingOrProcessing,
                youtubeVideoTitle: episode?.youtubeVideo?.data.title || episode?.title,
                soundcloudPublish: episode.soundcloudPublish,
            },
            privacy: {
                privacy: episode.privacy,
                canDownload: episode.canDownload,
            },
            chapters: chapters,
        },
        resetOptions: {
            keepDirtyValues: true,
            keepErrors: true,
        },
        resolver: yupResolver(schema),
    });
    const isEpisodeDraft = episode.state === EPISODE_STATUS.DRAFT;
    const hasAudioFile = episode.files.length > 0;
    const hasAccessToEpisodeAI = useHasAccessToEpisodeAI({ showId: episode?.showId });
    const hasUsedAIFreeTrial = channel.data?.aiFreeTrialUsed === true;

    const {
        control,
        formState: { isDirty },
    } = methods;

    useEffect(() => {
        if (!ENABLE_EPISODE_AI || hasAccessToEpisodeAI || hasUsedAIFreeTrial) return;
        const isAiModalClosed = localStorage.getItem(`ai-free-trial-modal-closed`) === 'true';
        const isAudioFileUploaded =
            localStorage.getItem(`episode-${episode.id}-audio-file-uploaded`) === 'true';
        if (isAudioFileUploaded && hasAudioFile && !isAiModalClosed) {
            setIsAiModalOpen(true);
        }
    }, [hasAudioFile, hasAccessToEpisodeAI, hasUsedAIFreeTrial]);

    useEffect(() => {
        const hasGeneratedChapters =
            localStorage.getItem(`episode-ai-${episode.id}-generated-chapters`) === 'true';
        if (hasGeneratedChapters && chapters?.length === 0) {
            localStorage.removeItem(`episode-ai-${episode.id}-generated-chapters`);
        }
    }, []);

    return (
        <FormProvider {...methods}>
            <EpisodeAILoadingContextProvider>
                <EpisodeAISettingsContextProvider>
                    <PsoCheckerContextProvider>
                        <EpisodeEditTranscriptionProvider>
                            <Container>
                                <Header>
                                    <ResponsiveWrapper>
                                        <Cluster $gap="1rem" $wrap="nowrap">
                                            {isEpisodeDraft ? (
                                                <SaveDraftEpisodeAndCloseButton />
                                            ) : (
                                                <>
                                                    <CancelButton />
                                                    {!isMobileOrTablet && <MoveToDraftButton />}
                                                </>
                                            )}
                                        </Cluster>
                                        {!isNew && (
                                            <Text color="--neutral500">
                                                <FormattedMessage
                                                    defaultMessage="Dernière modification le {date} à {time}"
                                                    values={{
                                                        date: (
                                                            <FormattedDate
                                                                value={episode.updatedAt}
                                                            />
                                                        ),
                                                        time: (
                                                            <FormattedTime
                                                                value={episode.updatedAt}
                                                            />
                                                        ),
                                                    }}
                                                />
                                            </Text>
                                        )}
                                    </ResponsiveWrapper>
                                    {!isMobileOrTablet && (
                                        <Cluster $gap="1rem" $wrap="nowrap">
                                            {isEpisodeDraft ? (
                                                <>
                                                    <ScheduleButton />
                                                    <PublishButton />
                                                </>
                                            ) : (
                                                <SaveAndCloseButton />
                                            )}
                                        </Cluster>
                                    )}
                                </Header>
                                <Content>
                                    <EpisodeEdit episode={episode} />
                                </Content>
                                <Footer>
                                    <EpisodeAIBubble />
                                    {isEpisodeDraft ? (
                                        <>
                                            <ScheduleButton />
                                            <PublishButton />
                                        </>
                                    ) : (
                                        <>
                                            <MoveToDraftButton />
                                            <SaveAndCloseButton />
                                        </>
                                    )}
                                </Footer>
                                {ENABLE_EPISODE_AI && (
                                    <EpisodeAIFreeTrialModal
                                        isOpen={isAiModalOpen}
                                        onOpenChange={setIsAiModalOpen}
                                    />
                                )}
                            </Container>
                            <UnsavedFormModal shouldBlockNavigation={isDirty} />
                        </EpisodeEditTranscriptionProvider>
                    </PsoCheckerContextProvider>
                </EpisodeAISettingsContextProvider>
            </EpisodeAILoadingContextProvider>
            {DEBUG_EPISODE_EDIT_FORM && <DevTool control={control} placement="top-left" />}
        </FormProvider>
    );
};

export default EpisodeEditForm;
