import { useState } from 'react';
import { useResponsive } from '@/shared/hooks/useResponsive';
import posed, { PoseGroup } from 'react-pose';
import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import Desktop from '@ui/molecules/Desktop';
import MobileOrTablet from '@ui/molecules/MobileOrTablet';
import StatsDataSetEpisodesDropdown from './StatsDataSetEpisodesDropdown';
import DateRangePicker from '@ui/atoms/DateRangePicker';
import useRouterStore from '@/shared/hooks/useRouterStore';
import useCampaignStore from '@/shared/hooks/useCampaignStore';
import usePodcastStore from '@/shared/hooks/usePodcastStore';
import { useStatsDateSelectorContext } from '@/context/StatsDateSelectorContext';
import { parseDate } from '@internationalized/date';
import styled from 'styled-components';
import { getLocalTimeZone, today } from '@internationalized/date';
import useUserQuery from '@queries/user/useUserQuery.hook';
import { useParams } from 'react-router';
import useShowQuery from '@queries/show/useShowQuery.hook';

const transition = { duration: 250 };
const Title = posed(DeprecatedText)({
    enter: { opacity: 1, x: 0, transition },
    exit: { opacity: 0, x: -5, transition },
});

const TitleGroup = ({ currentItemName, tabTitle, titleTooltip, subTitle, tabId }) => {
    return (
        <DeprecatedPaper flex column auto align="start" ellipsis pr={20}>
            <MobileOrTablet>
                <DeprecatedText key={0} size="veryBig" weight="bold" mb={20}>
                    <FormattedMessage defaultMessage="Statistiques" />
                </DeprecatedText>
            </MobileOrTablet>
            <Desktop>
                <PoseGroup>
                    <DeprecatedPaper flex key={tabId} alignItems="baseline" column>
                        <Title flex weight="bold" size="veryBig">
                            {tabTitle}
                            {titleTooltip}
                        </Title>
                        <DeprecatedText key={0} mt={5} ellipsis w="100%">
                            {subTitle || currentItemName}
                        </DeprecatedText>
                    </DeprecatedPaper>
                </PoseGroup>
            </Desktop>
        </DeprecatedPaper>
    );
};

const StatsDataSetSelector = ({
    tabTitle,
    tabDescription,
    titleTooltip,
    tabId,
    noSelector,
    noPodcastSelector,
    subTitle,
    getGraphValues,
    ...props
}) => {
    const { showId } = useParams();
    const { data: show } = useShowQuery(showId);

    const showStartDate = show && new Date(show.createdAt);
    const {
        params: { itemType, itemId },
        query: { episode },
    } = useRouterStore();
    const { data: user } = useUserQuery();
    const timezone = user?.timezone || getLocalTimeZone();
    const { startDate, setStartDate, endDate, setEndDate } = useStatsDateSelectorContext();
    const { podcastsShrinked: episodes } = usePodcastStore();
    const campaign = itemType === 'campaign' && itemId && useCampaignStore().byId(itemId);
    const currentEpisodes = episodes?.filter((e) => episode?.split('_')?.includes(e.id.toString()));
    const episodeNames = currentEpisodes?.map((episode) => episode.name).join(', ');
    const currentItemName = campaign?.name || episodeNames || (
        <FormattedMessage defaultMessage="Tous les épisodes" />
    );
    const { isMobileOrTablet } = useResponsive();
    const now = today(timezone);
    const [hasError, setHasError] = useState(false);

    const onDatesChange = (startDate, endDate) => {
        const isLaterThanToday = parseDate(endDate).compare(now) > 0;
        if (isLaterThanToday) {
            setHasError(
                // intl.formatMessage(
                //     { defaultMessage: 'La date de fin ne peut pas ultérieure au {todayDate}' },
                //     {
                //         todayDate: <FormattedDate value={today.toString()} />,
                //     },
                // ),
                true,
            );
        } else setHasError(false);

        setStartDate(startDate);
        setEndDate(endDate);
    };

    return (
        <DeprecatedPaper
            mt={30}
            mb={20}
            z={noSelector ? 1 : 2}
            position="relative"
            column={isMobileOrTablet}
            flex
            {...props}
        >
            <TitleGroup
                currentItemName={tabDescription || currentItemName}
                tabTitle={tabTitle}
                subTitle={subTitle}
                titleTooltip={titleTooltip}
                tabId={tabId}
            />
            {!noSelector && (
                <SelectorsWrapper>
                    <StatsDataSetEpisodesDropdown
                        active={!noPodcastSelector}
                        getGraphValues={getGraphValues}
                    />
                    <DateRangePicker
                        value={{
                            start: parseDate(startDate),
                            end: parseDate(endDate),
                        }}
                        onChange={(dates) => onDatesChange(dates.start, dates.end)}
                        startDate={parseDate(dayjs(showStartDate).format('YYYY-MM-DD'))}
                        hideCustomTimezone
                        maxValue={now}
                        isInvalid={hasError}
                    />
                </SelectorsWrapper>
            )}
        </DeprecatedPaper>
    );
};

const SelectorsWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 0.625rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-wrap: nowrap;
    }
`;

export default StatsDataSetSelector;
