import { createRef, useState, useEffect } from 'react';
import play from '@public/icons/play.svg';
import { useParams } from 'react-router-dom';
import { connect } from '@/components/legacy/connect';
import { FormattedDate, FormattedMessage, FormattedNumber } from 'react-intl';
import styled from 'styled-components';
import { useResponsive } from '@/shared/hooks/useResponsive';
import {
    AreaChart,
    Area,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
    ReferenceDot,
} from 'recharts';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import DeprecatedIcon from '@ui/atoms/DeprecatedIcon';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import { compose, parseIntArray } from '@/helpers';
import { extractCssVariable } from '@/shared/utils/cssVariable';
import { useStatsDateSelectorContext } from '@/context/StatsDateSelectorContext';
import weekOfYear from 'dayjs/plugin/weekOfYear';

dayjs.extend(weekOfYear);
dayjs.extend(isBetween);

const enhance = compose(
    connect(({ podcastStore, listenersStatsStore, routerStore }) => ({
        maxPodcastDownload: listenersStatsStore.maxPodcastDownload,
        variationsFormatted: listenersStatsStore.variationsFormatted,
        stepMobile: listenersStatsStore.stepMobile,
        podcastsShrinked: podcastStore.podcastsShrinked.filter((episode) => episode.publishedAt),
        episodeQuery:
            parseIntArray(routerStore.query.episode)?.length > 0 &&
            parseIntArray(routerStore.query.episode),
    })),
);

const episodeFormatVariationStep = (episode, step, variation) => {
    const format = {
        days: dayjs(episode.publishedAt).format('DD/MM/YYYY'),
        weeks: `${variation?.translateWeeksXCoordinate} ${dayjs(episode.publishedAt).week()}`,
        months: dayjs(episode.publishedAt).format('MMMM YYYY'),
        years: dayjs(episode.publishedAt).format('YYYY'),
    };
    return format[step];
};

const customTooltip = ({ payload, step, stepFormat, isActive, episodesList, start, end }) => {
    if (!payload || !payload.length) return null;

    const data = payload[0].payload;

    let variationPercent;
    if (data.variation > 0) {
        variationPercent = `+${Math.round(data.variation)}%`;
    } else if (data.variation === 0) {
        variationPercent = `0%`;
    } else if (isNaN(data.variation)) {
        variationPercent = '';
    } else {
        variationPercent = `${Math.round(data.variation)}%`;
    }

    let variationColor;
    if (data.variation === 0) {
        variationColor = 'blocked';
    } else if (data.variation > 0) {
        variationColor = 'online';
    } else {
        variationColor = 'error';
    }

    const tooltipContentMessage = {
        days: <FormattedDate value={data.timestamp} day="2-digit" month="long" year="numeric" />,
        weeks: data.weeks,
        months: <FormattedDate value={data.timestamp} month="long" year="numeric" />,
        years: <FormattedDate value={data.timestamp} year="numeric" />,
    };

    const tooltipPublishedAt = episodesList
        .filter((episode) => {
            return dayjs(episode.publishedAt).isBetween(dayjs(start), dayjs(end));
        })
        .filter(
            (episode) => episodeFormatVariationStep(episode, stepFormat, data) === data[stepFormat],
        );

    const ToolTipPublishedContent = () => {
        if (tooltipPublishedAt.length < 6) {
            return tooltipPublishedAt.map((episodes) => (
                <DeprecatedText key={episodes.id} as="li" py={5} ellipsis>
                    • {episodes.name}
                </DeprecatedText>
            ));
        }
        return (
            <DeprecatedText pl={3}>
                <FormattedMessage
                    defaultMessage="{episodeLength} épisodes"
                    values={{ episodeLength: tooltipPublishedAt.length }}
                />
            </DeprecatedText>
        );
    };

    return (
        <>
            {isActive && tooltipPublishedAt.length > 0 ? (
                <DeprecatedPaper
                    pos="relative"
                    m={10}
                    background="white"
                    raise="normal"
                    maxW={200}
                    rounded
                >
                    <DeprecatedPaper background="--success50" flex>
                        <DeprecatedText px={10} py={5} color="--success">
                            {step === 'days' && (
                                <DeprecatedText as="span" pr={3} color="--success">
                                    <FormattedMessage defaultMessage="Publié le" />
                                </DeprecatedText>
                            )}
                            <DeprecatedText
                                as="span"
                                color="--success"
                                align="center"
                                weight="semibold"
                                capitalize
                            >
                                {tooltipContentMessage[stepFormat]}
                            </DeprecatedText>
                        </DeprecatedText>
                    </DeprecatedPaper>
                    <DeprecatedText as="ul" p={10}>
                        <ToolTipPublishedContent />
                    </DeprecatedText>
                </DeprecatedPaper>
            ) : (
                <DeprecatedPaper m={10} background="white" raise="normal" rounded overflowHide>
                    <DeprecatedPaper background="--neutral50" p={10}>
                        <DeprecatedText
                            align="center"
                            color="--neutral500"
                            weight="semibold"
                            firstLetterCapitalize
                        >
                            {tooltipContentMessage[stepFormat]}
                        </DeprecatedText>
                    </DeprecatedPaper>
                    <DeprecatedPaper p={10}>
                        <DeprecatedText align="center" weight="semibold">
                            <DeprecatedIcon as={play} size={10} mr={5} />
                            <FormattedNumber value={data.downloads} />
                            <DeprecatedText as="span" color={variationColor} ml={5}>
                                {variationPercent}
                            </DeprecatedText>
                        </DeprecatedText>
                    </DeprecatedPaper>
                </DeprecatedPaper>
            )}
        </>
    );
};

const Wrapper = styled.div`
    text {
        font-weight: var(--fw-normal);
        font-size: var(--fs-body);
    }
`;

function ListenersVariationGraphPlotLine({
    onHover,
    step,
    stepMobile,
    isLoading,
    variationsFormatted,
    podcastsShrinked,
    episodeQuery,
    maxPodcastDownload,
}) {
    const { startDate, endDate } = useStatsDateSelectorContext();
    const tooltipRef = createRef();
    const { ...params } = useParams();
    const { isMobileOrTablet } = useResponsive();
    const [hover, setHover] = useState(false);
    const [episodesList, setEpisodeList] = useState([]);
    const stepFormat = isMobileOrTablet ? stepMobile : step;
    const hasValues = variationsFormatted.some((v) => v.downloads > 0);

    useEffect(() => {
        if (episodeQuery)
            setEpisodeList(
                podcastsShrinked.filter((episode) => episodeQuery.includes(episode.id) && episode),
            );
        else setEpisodeList(podcastsShrinked);
    }, [episodeQuery, podcastsShrinked, variationsFormatted, step]);

    const CustomReferenceDot = (props) => (
        <svg
            x={props.cx - 10}
            y={props.cy - 10}
            height={20}
            width={20}
            viewBox="0 0 30 30"
            style={{ cursor: 'pointer' }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <filter id="a" x="-46.7%" y="-233.3%" width="193.3%" height="566.7%" />
            <linearGradient
                id="b"
                x1="-167.06"
                x2="-166.06"
                y1="350.26"
                y2="351.26"
                gradientTransform="matrix(80 0 0 -80 13365 28101)"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#2ED573" offset="0" />
                <stop stopColor="#39FF8B" offset="1" />
            </linearGradient>
            <circle fill="url(#b)" cx="15" cy="15" r="15" />
            <g transform="translate(7 7) scale(0.5)">
                <path
                    fill="#ffffff"
                    d="m29.8 0.1c-0.1-0.1-0.3-0.1-0.4-0.1l-29.1 13.4c-0.2 0.1-0.3 0.3-0.3 0.4 0 0.2 0.1 0.3 0.3 0.4l8.2 4c0.2 0.1 0.3 0.1 0.5 0l8-5.9-6.3 6.4c-0.1 0.1-0.1 0.2-0.1 0.3l0.6 8.1c0 0.2 0.1 0.3 0.3 0.4h0.1c0.1 0 0.3-0.1 0.3-0.2l4.4-5 5.4 2.6c0.1 0.1 0.3 0.1 0.4 0s0.2-0.2 0.2-0.3l7.6-24c0.1-0.2 0.1-0.4-0.1-0.5z"
                />
            </g>
        </svg>
    );
    return (
        <DeprecatedPaper>
            <Wrapper>
                <ResponsiveContainer width="100%" height={350}>
                    {isLoading && !variationsFormatted ? (
                        <AreaChart
                            opacity={0.5}
                            margin={{ top: 0, right: 35, bottom: 0, left: 60 }}
                        >
                            <CartesianGrid
                                stroke={extractCssVariable('--neutral100')}
                                vertical={false}
                            />
                            <XAxis height={30} padding={{ left: 20, right: 20 }} />
                            <YAxis
                                axisLine={false}
                                tickLine={false}
                                ticks={[0, 50000, 100000, 150000, 200000]}
                                width={35}
                            />
                        </AreaChart>
                    ) : (
                        <AreaChart
                            data={variationsFormatted}
                            margin={{
                                top: 0,
                                right: isMobileOrTablet ? 10 : 35,
                                bottom: 0,
                                left: isMobileOrTablet ? 10 : 35,
                            }}
                            onMouseMove={({ activePayload }) =>
                                onHover(
                                    activePayload && activePayload[0] && activePayload[0].payload,
                                )
                            }
                        >
                            <CartesianGrid
                                vertical={false}
                                stroke={extractCssVariable('--neutral100')}
                            />
                            <XAxis
                                dataKey={stepFormat}
                                maxBarThickness="100"
                                minTickGap={80}
                                tickMargin={5}
                                tickSize={5}
                                padding={{ left: 20, right: 20 }}
                            />
                            {hasValues && (
                                <YAxis
                                    height={30}
                                    tickLine={false}
                                    axisLine={false}
                                    ticks={
                                        maxPodcastDownload < 4 ? [maxPodcastDownload] : undefined
                                    }
                                />
                            )}
                            <Tooltip
                                content={customTooltip}
                                step={step}
                                stepFormat={stepFormat}
                                ref={tooltipRef}
                                cursor={{ fill: extractCssVariable('--neutral50') }}
                                podcastsShrinked={podcastsShrinked}
                                episodesList={episodesList}
                                params={params}
                                isActive={hover}
                                start={startDate}
                                end={endDate}
                            />
                            <defs>
                                <linearGradient id="Gradient1" x1="0" x2="0" y1="0" y2="1">
                                    <stop
                                        offset="0%"
                                        stopColor={extractCssVariable('--primary200')}
                                        stopOpacity={0.7}
                                    />
                                    <stop
                                        offset="100%"
                                        stopColor={extractCssVariable('--primary200')}
                                        stopOpacity={0.4}
                                    />
                                </linearGradient>
                            </defs>
                            <Area
                                dataKey="downloads"
                                fill="url(#Gradient1)"
                                stroke={extractCssVariable('--primary')}
                                activeDot={{ strokeWidth: 0, r: 5 }}
                                strokeWidth={2}
                            />
                            {params.itemType !== 'campaign' &&
                                variationsFormatted.map(
                                    (variation) =>
                                        episodesList
                                            .filter((episode) => {
                                                return dayjs(episode.publishedAt).isBetween(
                                                    dayjs(startDate),
                                                    dayjs(endDate),
                                                );
                                            })
                                            .map((episode) =>
                                                episodeFormatVariationStep(
                                                    episode,
                                                    stepFormat,
                                                    variation,
                                                ),
                                            )
                                            .find(
                                                (episodeFind) =>
                                                    episodeFind === variation[stepFormat],
                                            ) && (
                                            <ReferenceDot
                                                key={variation.id}
                                                x={variation[stepFormat]}
                                                y={0}
                                                shape={CustomReferenceDot}
                                            />
                                        ),
                                )}
                        </AreaChart>
                    )}
                </ResponsiveContainer>
            </Wrapper>
        </DeprecatedPaper>
    );
}

export default enhance(ListenersVariationGraphPlotLine);
