import { useState, useEffect } from 'react';
import { connect } from '@/components/legacy/connect';
import SucceededClipFormat from './SucceededClipFormat';
import LoadingClipFormat from './LoadingClipFormat';
import PendingClipFormat from './PendingClipFormat';
import ErrorClipFormat from './ErrorClipFormat';

const enhance = connect(({ podcastStore }) => ({
    fetchEpisode: podcastStore.fetchPodcast,
}));

const ClipFormat = ({ clipFormat, fetchEpisode, onEdit }) => {
    const [episode, setEpisode] = useState(null);
    useEffect(async () => {
        const data = await fetchEpisode(clipFormat.clip.episodeId);
        setEpisode(data);
    }, [clipFormat]);

    if (clipFormat.isPending) {
        return <PendingClipFormat clipFormat={clipFormat} episode={episode} onClick={onEdit} />;
    }
    if (clipFormat.isLoading) {
        return <LoadingClipFormat clipFormat={clipFormat} episode={episode} />;
    }
    if (clipFormat.succeeded) {
        return <SucceededClipFormat clipFormat={clipFormat} episode={episode} />;
    }
    if (!episode || clipFormat.failed) {
        return <ErrorClipFormat clipFormat={clipFormat} episode={episode} />;
    }

    return null;
};

export default enhance(ClipFormat);
