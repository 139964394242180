import Chart from 'react-apexcharts';

export interface PerformanceTrendChartProps {
    data: number[];
    width?: number | string;
    height?: number | string;
    trend: 'up' | 'down';
}

const PerformanceTrendChart = ({ data, width, height, trend }: PerformanceTrendChartProps) => {
    if (!data) data = [];
    if (!width) width = 160;
    if (!height) height = 80;

    const chartOptions = {
        options: {
            chart: {
                id: 'basic-bar',
                type: 'area',
                toolbar: { show: false },
                zoom: { enabled: false },
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'light',
                    type: 'vertical',
                    shadeIntensity: 0.4,
                    opacityFrom: 0.4,
                    opacityTo: 0,
                },
            },
            dataLabels: {
                enabled: false,
            },
            xaxis: {
                labels: {
                    show: false,
                },
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: false,
                },
            },
            yaxis: {
                labels: {
                    show: false,
                },
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: false,
                },
                reversed: true,
            },
            grid: {
                show: false,
            },
            stroke: {
                curve: 'smooth',
                lineCap: 'round',
                width: 2,
            },
            legend: {
                show: false,
            },
            tooltip: {
                enabled: false,
            },
            colors: [trend === 'up' ? '#47CC47' : '#FF264A'],
            plotOptions: {
                area: {
                    fillTo: 'end',
                },
            },
        },
        series: [
            {
                name: 'scores',
                data,
            },
        ],
    };

    return (
        <Chart
            options={chartOptions.options as any}
            series={chartOptions.series}
            type="area"
            width={width}
            height={height}
        />
    );
};

export default PerformanceTrendChart;
