import Cluster from '@/components/ui/layout/Cluster';
import { CAMPAIGN_STATUS } from '@/shared/config/constants';
import styled from 'styled-components';
import Sidebar from './Sidebar';
import PublishButton from './PublishButton';
import SaveButton from './SaveButton';
import PlayPauseButton from './PlayPauseButton';
import SaveDraftButton from './SaveDraftButton';
import CancelButton from './CancelButton';
import { useResponsive } from '@/shared/hooks/useResponsive';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import useCampaignFormSchema, { CampaignFormSchema } from './useFormSchema';
import Accordion from './Accordion';
import { Campaign } from '@/queries/campaign/useCampaignQuery';
import UnsavedFormModal from '@/components/unorganized/UnsavedFormModal';

interface CampaignFormProps {
    campaign: Campaign;
}

const CampaignForm = ({ campaign }: CampaignFormProps) => {
    const isDraft = campaign.state === CAMPAIGN_STATUS.DRAFT;
    const { isMobileOrTablet } = useResponsive();
    const schema = useCampaignFormSchema();
    const form = useForm<CampaignFormSchema>({
        mode: 'onBlur',
        values: {
            general: {
                name: campaign.name,
                type: campaign.type,
            },
            duration: {
                startsAt: campaign.startsAt,
                endsAt: campaign.endsAt,
                maxListens: campaign.maxListens,
            },
            episodes: {
                autoOnNewPodcasts: campaign.autoOnNewPodcasts,
                episodes: campaign.podcasts.map((podcast) => podcast.id),
            },
        },
        resetOptions: {
            keepDirtyValues: true,
            keepErrors: true,
        },
        resolver: zodResolver(schema),
    });
    const { isDirty } = form.formState;

    return (
        <FormProvider {...form}>
            <Container>
                <Header>
                    <ResponsiveWrapper>
                        {isDraft ? <SaveDraftButton /> : <CancelButton />}
                    </ResponsiveWrapper>
                    {!isMobileOrTablet && (
                        <Cluster $gap="1rem" $wrap="nowrap">
                            <PlayPauseButton />
                            {isDraft ? <PublishButton /> : <SaveButton />}
                        </Cluster>
                    )}
                </Header>
                <Content>
                    <ContentInner>
                        <Sidebar />
                        <AccordionWrapper>
                            <Accordion />
                        </AccordionWrapper>
                    </ContentInner>
                </Content>
                <Footer>
                    {isDraft ? (
                        <PublishButton />
                    ) : (
                        <>
                            <PlayPauseButton />
                            <SaveButton />
                        </>
                    )}
                </Footer>
            </Container>
            <UnsavedFormModal shouldBlockNavigation={isDirty} />
        </FormProvider>
    );
};

const Container = styled.section`
    background-color: var(--neutral50);
    display: flex;
    flex-direction: column;
    height: 100%;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        background-color: var(--white);
    }
`;
const Header = styled.header`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;
    padding: 1rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        padding: 2rem;
    }
`;
const Content = styled.div`
    flex-grow: 1;
    min-height: 0;
    overflow-y: auto;
    padding: 0 1rem 1rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: flex;
        justify-content: center;
        background-color: var(--neutral50);
        border-radius: var(--r-l) var(--r-l) 0 0;
        margin-inline: 2rem;
        padding: 2rem;
    }
`;
const Footer = styled.footer`
    position: relative;
    background-color: var(--white);
    border-top: 1px solid var(--neutral100);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5rem 1rem;
    padding: 1rem;

    > * {
        flex-grow: 1;
    }

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: none;
    }
`;
const ResponsiveWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
        align-items: center;
        column-gap: 1rem;
    }
`;
const ContentInner = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem 2rem;
    width: 100%;

    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        flex-direction: row;
        max-width: calc(var(--container-width) - 4rem);
    }
`;

const AccordionWrapper = styled.div`
    flex: 1;
`;

export default CampaignForm;
