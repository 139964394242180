import { FormattedMessage } from 'react-intl';
import Text from '@ui/atoms/Text';
import { STATUS_SHOWS } from '@/shared/config/statusShows';
import { USER_OPTIONS } from '@/shared/config/userOptions';
import Alert from '@ui/atoms/Alert';
import noUserMainIllustration from '@public/images/empty-state/illustration/users.png';
import noUserHeadIllustration from '@public/images/empty-state/users.svg?url';
import EmptyState from '@ui/molecules/EmptyState';
import { useGuard } from '@/shared/hooks/useGuard.hook';
import { GUARD_DISALLOW_REASONS } from '@/shared/config/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { extractCssVariable } from '@/shared/utils/cssVariable';
import styled from 'styled-components';
import ShowGuestsAddButton from '../ShowGuestsAddButton';

const ShowGuestsListNoContent = () => {
    const { allow, reason } = useGuard({
        disallowedShowStatuses: [STATUS_SHOWS.ARCHIVED_SHOWS],
        requiredUserOptions: [USER_OPTIONS.INVITATIONS],
    });

    const disallowMessageLookup = {
        [GUARD_DISALLOW_REASONS.INSUFFICIENT_SHOW_STATUS]: (
            <FormattedMessage defaultMessage="Vous ne pouvez pas inviter d'autres utilisateurs sur une émission archivée." />
        ),
        [GUARD_DISALLOW_REASONS.UNMET_USER_OPTIONS]: (
            <FormattedMessage defaultMessage="Vous n'avez pas les droits nécessaires pour inviter d'autres utilisateurs." />
        ),
    };

    return (
        <>
            <EmptyState
                illustration={noUserMainIllustration}
                illustrationType="image"
                illustrationBackground="--gradient-dawn"
            >
                <HeadIllustrationWrapper>
                    <HeadIllustration src={noUserHeadIllustration} />
                </HeadIllustrationWrapper>
                <LabelWrapper>
                    <Text variant="heading" fontWeight="--fw-bold">
                        <FormattedMessage defaultMessage="Invitez des utilisateurs" />
                    </Text>
                    <Text variant="body" fontWeight="--fw-normal" color="--neutral500">
                        <FormattedMessage defaultMessage="Gérez votre podcast en ajoutant d’autres membres de votre équipe 👥" />
                    </Text>
                </LabelWrapper>
                {!allow && (
                    <Alert
                        variant="alert"
                        icon={
                            <FontAwesomeIcon
                                icon={icon({ name: 'circle-info', style: 'solid' })}
                                color={extractCssVariable('--alert500')}
                                size="lg"
                            />
                        }
                    >
                        {disallowMessageLookup[reason] ?? (
                            <FormattedMessage defaultMessage="Une erreur est survenue" />
                        )}
                    </Alert>
                )}
                <ShowGuestsAddButton isDisabled={!allow}>
                    <FormattedMessage defaultMessage="Inviter des utilisateurs" />
                </ShowGuestsAddButton>
            </EmptyState>
        </>
    );
};

const HeadIllustrationWrapper = styled.div`
    width: 200px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const HeadIllustration = styled.img`
    min-width: 248px;
`;
const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    & > * {
        text-align: center;
    }
`;

export default ShowGuestsListNoContent;
