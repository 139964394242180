import { CompositeDecorator, ContentState, EditorState, Modifier } from 'draft-js';
import { useRef, useState } from 'react';
import DeprecatedText from '@ui/atoms/DeprecatedText';

function templateStrategy(contentBlock, callback) {
    const text = contentBlock.getText();
    const regExp = /{{(episode\.name|show\.name|episode\.tags)}}/g;
    let matchArr = regExp.exec(text);
    while (matchArr) {
        const start = matchArr.index;
        callback(start, start + matchArr[0].length);
        matchArr = regExp.exec(text);
    }
}

function Param(props) {
    return <DeprecatedText color="twitter" as="span" {...props} />;
}

export function useTemplate(field) {
    const decorator = new CompositeDecorator([{ strategy: templateStrategy, component: Param }]);
    const initialEditorState = EditorState.createWithContent(
        ContentState.createFromText(field.value),
        decorator,
    );
    const editorRef = useRef(null);
    const [editorState, setEditorState] = useState(initialEditorState);

    function onEditorChange(state) {
        setEditorState(state);
        field.setValue(state.getCurrentContent().getPlainText());
    }

    function addTemplate(tpl) {
        const selection = editorState.getSelection();
        const contentState = editorState.getCurrentContent();
        const ncs = Modifier.insertText(contentState, selection, `{{${tpl}}}`);
        const es = EditorState.push(editorState, ncs, 'insert-fragment');
        onEditorChange(es);
        if (editorRef.current) {
            setTimeout(() => editorRef.current.focus(), 0);
        }
    }

    return [editorState, onEditorChange, addTemplate, editorRef];
}
