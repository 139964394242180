import { useQuery } from '@tanstack/react-query';
import { fetchInsightsOfSocialMediaPosts } from '@/api';
import socialNetworkPostKeys from './socialNetworkPostKeys';
import camelcaseKeys from 'camelcase-keys';

const queryFn = async ({ queryKey: [{ postIds }] }) => {
    const { data } = await fetchInsightsOfSocialMediaPosts(postIds);

    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const useSocialNetworkPostsInsightsQuery = ({ postIds }, { enabled } = { enabled: true }) => {
    return useQuery({
        queryKey: socialNetworkPostKeys.insights({ postIds }),
        queryFn,
        enabled,
    });
};

export default useSocialNetworkPostsInsightsQuery;
