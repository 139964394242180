import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateChannelGuestShowRoles } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import guestKeys from './guestKeys';
import {
    ChannelGuestShowRole,
    ChannelGuestUserPermissionsSummary,
} from './useChannelGuestUserPermissionsSummary.hook';

export interface UpdateChannelGuestShowRolesMutationFnProps {
    channelId: number;
    userId: number;
    shows: {
        id: number;
        role: ChannelGuestShowRole;
    }[];
}

const mutationFn = async ({
    channelId,
    userId,
    shows,
}: UpdateChannelGuestShowRolesMutationFnProps): Promise<ChannelGuestUserPermissionsSummary> => {
    const { data } = await updateChannelGuestShowRoles({
        channelId: channelId,
        userId: userId,
        shows,
    });

    return camelcaseKeys(data as any, { deep: true });
};

const updateChannelGuestShowRolesMutation = () => {
    const queryclient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: (_, { channelId, userId }) => {
            queryclient.invalidateQueries({
                queryKey: guestKeys.userPermissionList(channelId, userId),
            });
            queryclient.invalidateQueries({
                queryKey: guestKeys.permissionListByChannelId(channelId),
            });
        },
    });
};

export default updateChannelGuestShowRolesMutation;
