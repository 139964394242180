import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import exportIcon from '@public/icons/external-link.svg';
import DeprecatedIcon from '@ui/atoms/DeprecatedIcon';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import Spinner from '@ui/atoms/Spinner';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import { useStatsDateSelectorContext } from '@/context/StatsDateSelectorContext';
import { useParams } from 'react-router';
import { fetchApplePodcastsStatsCsv } from '@/api';
import { download } from '@/shared/utils/file';
import { useApplePodcastsStatsContext } from '@/context/ApplePodcastsStatsContext';

function ApplePodcastsRankingExportButton() {
    const { showId } = useParams();
    const { category, precision } = useApplePodcastsStatsContext();
    const { startDate, endDate } = useStatsDateSelectorContext();

    const [isLoading, setIsLoading] = useState(false);

    const doExport = async () => {
        setIsLoading(true);
        const query = {
            start: startDate,
            end: endDate,
            step: precision,
        };
        try {
            const { axios, ...data } = await fetchApplePodcastsStatsCsv(showId, category, {
                ...query,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            });
            const filename = axios.headers['content-disposition'].match(/filename="(.+)"/)[1];
            download(Object.values(data).join(''), filename, 'txt');
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <DeprecatedPaper flex justify="flex-end" mt={15}>
            <DeprecatedText
                flex
                align="center"
                onClick={doExport}
                disabled={isLoading}
                color="--primary"
                weight="semibold"
            >
                {isLoading ? (
                    <Spinner color="--neutral100" mr={10} />
                ) : (
                    <DeprecatedIcon color="--primary" as={exportIcon} mr={10} />
                )}
                <FormattedMessage defaultMessage="Exporter en .csv" />
            </DeprecatedText>
        </DeprecatedPaper>
    );
}

export default ApplePodcastsRankingExportButton;
