import styled from 'styled-components';
import { motion } from 'framer-motion';
import { MAX_BACKGROUND_OPACITY } from '../../StatsGeolocalization.constants';

const StatsColorLegend = () => {
    return (
        <LegendWrapper initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <LegendGradient maxOpacity={MAX_BACKGROUND_OPACITY} />
            <LegendTicks>
                <LegendTick>100%</LegendTick>
                <LegendTick>75%</LegendTick>
                <LegendTick>50%</LegendTick>
                <LegendTick>25%</LegendTick>
                <LegendTick>0%</LegendTick>
            </LegendTicks>
        </LegendWrapper>
    );
};

const LegendWrapper = styled(motion.div)`
    display: flex;
    align-items: center;
    height: 12rem;
    column-gap: 0.25rem;

    & > * {
        height: 100%;
    }
`;

const LegendGradient = styled.div`
    width: 0.375rem;
    border-radius: var(--r-full);
    outline: 2px solid var(--white);
    background: ${(props) =>
        `linear-gradient(180deg, hsl(273 74% 43% / ${props.maxOpacity}) 10%, hsl(273 74% 43% / 0.1) 100%)`};
`;

const LegendTicks = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const LegendTick = styled.li`
    color: var(--neutral600);
`;

export default StatsColorLegend;
