import { useMemo } from 'react';
import ApexChart from 'react-apexcharts';
import { FormattedMessage, useIntl } from 'react-intl';
import { useResponsive } from '@/shared/hooks/useResponsive';
import Text from '@/components/ui/atoms/Text';
import styled from 'styled-components';
import { ApexOptions } from 'apexcharts';
import NoDataChartOverlay from '@/components/charts/NoData/NoDataChartOverlay';

interface PopularDaysChartProps {
    options: ApexOptions;
    series: number[];
}

const PopularDaysChart = ({ options, series }: PopularDaysChartProps) => {
    const { isMobileOrTablet } = useResponsive();
    const intl = useIntl();
    const hasData = series?.length > 0 && series.some((value) => value > 0);

    const config = useMemo(
        () => ({
            options: {
                chart: {
                    id: 'basic-bar',
                    type: 'bar' as 'bar',
                    toolbar: { show: false },
                    zoom: {
                        enabled: false,
                    },
                },
                yaxis: {
                    show: !isMobileOrTablet && series?.length > 0,
                    tickAmount: 5,
                    labels: {
                        show: series?.length > 0,
                    },
                },
                xaxis: {
                    show: true,
                    categories: options,
                    tickPlacement: 'on',
                    labels: {
                        style: {
                            fontSize: isMobileOrTablet ? '7px' : '12px',
                        },
                    },
                    axisTicks: {
                        show: !isMobileOrTablet,
                    },
                    axisBorder: {
                        show: !isMobileOrTablet,
                    },
                },
                fill: {
                    type: 'solid',
                    colors: ['#751CBF'],
                    opacity: [1],
                },
                plotOptions: {
                    bar: {
                        columnWidth: '25%',
                        distributed: false,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                tooltip: {
                    custom: ({ dataPointIndex }: { dataPointIndex: number }) => {
                        const day = options?.xaxis?.categories?.[dataPointIndex];
                        const downloads = series?.[dataPointIndex];
                        return `<div class="tooltip-ausha-apexChart">
                            <p class="tooltip-ausha-apexChart-day tooltip-ausha-apexChart-capitalize">
                                ${day}
                            </p>
                            <p class="tooltip-ausha-apexChart-listen">${intl.formatMessage(
                                {
                                    defaultMessage:
                                        '{count, plural, zero {# écoute} one {# écoute} other {# écoutes}}',
                                },
                                { count: downloads },
                            )}</p>
                        </div>`;
                    },
                },
                ...options,
            },
            series: [
                {
                    data: series,
                },
            ],
        }),
        [options, series, isMobileOrTablet],
    );

    return (
        <Wrapper>
            {hasData && (
                <Legend>
                    <LegendColor />
                    <Text>
                        <FormattedMessage defaultMessage="Nombre d’écoutes" />
                    </Text>
                </Legend>
            )}
            <ChartWrapper>
                {!hasData && <NoDataChartOverlay />}
                <ApexChart
                    options={config.options}
                    series={config.series}
                    type="bar"
                    width="100%"
                    height={300}
                />
            </ChartWrapper>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
`;
const Legend = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
`;
const LegendColor = styled.div`
    width: 0.625rem;
    height: 0.625rem;
    background-color: var(--primary);
    border-radius: var(--r-full);
`;
const ChartWrapper = styled.div`
    position: relative;
    .apexcharts-series path {
        stroke-linejoin: round;
        stroke-width: 4;
        stroke: var(--primary);
    }
`;

export default PopularDaysChart;
