import { computed } from 'mobx';
import dayjs from 'dayjs';
import Model from './Model';

class RankingModel extends Model {
    static default = () => ({
        timestamp: null,
        ranking: null,
    });

    static attributes = {
        timestamp: 'at',
        ranking: 'position',
    };

    @computed
    get date() {
        return new Date(this.timestamp * 1000);
    }

    @computed
    get dateLongFormat() {
        return dayjs(this.date).format('ddd ll');
    }
}

export default RankingModel;
