import styled, { css } from 'styled-components';
import Text from '../Text';

const VARIANTS = {
    INFO: 'info',
    SUCCESS: 'success',
    WARNING: 'warning',
    ALERT: 'alert',
    NEUTRAL: 'neutral',
};

interface BadgeProps {
    children?: React.ReactNode;
    icon?: React.ReactNode;
    variant?: typeof VARIANTS[keyof typeof VARIANTS];
}

const Badge = ({ children, icon, ...props }: BadgeProps) => {
    return (
        <Container variant={props.variant || VARIANTS.INFO}>
            {icon}
            <TextStyled variant="bodyS">{children}</TextStyled>
        </Container>
    );
};

const Container = styled.div<{ variant: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 0.25rem;
    border-radius: var(--r-s);
    padding: 0.5rem;
    background-color: var(--primary50);
    color: var(--primary);
    width: fit-content;
    font-size: 0.75rem;

    & > svg {
        color: inherit;
    }

    ${({ variant }) =>
        Object.values(VARIANTS).includes(variant) &&
        css`
            background-color: ${variant === VARIANTS.NEUTRAL
                ? `var(--neutral100)`
                : `var(--${variant}50)}`};
            color: var(--${variant}500);
        `}
`;
const TextStyled = styled(Text)`
    font-weight: var(--fw-semibold);
    color: inherit;
`;

export default Badge;
