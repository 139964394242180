import { Control, useController } from 'react-hook-form';
import ComboBox from './ComboBox';
import { Key } from 'react-aria';

interface ComboBoxControllerProps extends React.ComponentProps<typeof ComboBox> {
    control: Control<any>;
    name: string;
}

export const ComboBoxController = (props: ComboBoxControllerProps) => {
    const { control, name } = props;
    const { field } = useController({
        name,
        control,
    });

    return (
        <ComboBox
            {...props}
            label={props.label || null}
            isRequired={props.isRequired || false}
            selectedKey={field.value}
            isLoading={props.isLoading || false}
            icon={props.icon || null}
            inputHasImage={props.inputHasImage || false}
            onSelectionChange={(id: Key | null) => {
                props.onSelectionChange && props.onSelectionChange(id);
                field.onChange(id);
            }}
            onInputChange={(value: string) => {
                props.onInputChange && props.onInputChange(value);
            }}
            onLoadMore={() => {
                props.onLoadMore && props.onLoadMore();
            }}
            loadMoreEnabled={props.loadMoreEnabled || false}
            tooltip={props.tooltip || null}
        />
    );
};
