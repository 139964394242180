import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Stack from '@ui/layout/Stack';
import { FormattedMessage } from 'react-intl';
import peopleAndRobots from '@public/images/people_and_robots.webp';
import inLoveHearts from '@public/images/emoji/in-love-hearts.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Text from '@ui/atoms/Text';
import useQuery from '@/shared/hooks/useQuery';
import { PRICING_NAME } from '@/shared/config/pricing';
import { useResponsive } from '@/shared/hooks/useResponsive';
import { prefetchUserQuery } from '@queries/user/useUserQuery.hook';
import useSubscriptionQuery, {
    Subscription,
} from '@/queries/subscription/useSubscriptionQuery.hook';
import Button from '@/components/Button';
import { useHistory } from 'react-router';
import { notify } from './notifyGTM';
import usePricesQuery, { Currency, Offer } from '@/queries/price/usePricesQuery.hook';
import { usePricingContext } from '@/context/PricingContext';

const STRIPE_POLLING_RATE = 5000;

const StripeCheckoutSuccessPage = () => {
    const history = useHistory();
    const { isDesktop } = useResponsive();
    const query = useQuery();
    const offer = query.get('offer');
    const [alreadyAddedToGTM, setAlreadyAddedToGTM] = useState(false);
    const { currentSubscriptionCurrency, currentSubscriptionPricing } = usePricingContext();
    const { data: prices } = usePricesQuery();

    const pricesForCurrency = prices?.[currentSubscriptionCurrency as Currency];
    const pricesForCurrentPlan = pricesForCurrency?.[currentSubscriptionPricing as Offer];

    const { data: subscription, isLoading } = useSubscriptionQuery({
        options: {
            refetchInterval: (data: Subscription) => {
                return data?.id ? false : STRIPE_POLLING_RATE;
            },
        },
    });

    const refreshUserAndSubscription = async () => {
        await prefetchUserQuery();
    };

    useEffect(() => {
        if (!subscription?.id || alreadyAddedToGTM) return;
        refreshUserAndSubscription();
        notify(subscription, pricesForCurrentPlan);
        setAlreadyAddedToGTM(true);
    }, [subscription]);

    return (
        <Container>
            <Stack $gap="1rem" $align="center">
                <Image src={peopleAndRobots} />
                <Stack $gap="0.25rem" $align="center">
                    <Stack $align="center">
                        <Text
                            variant={isDesktop ? 'title' : 'heading'}
                            fontWeight="--fw-bold"
                            textAlign="center"
                        >
                            <FormattedMessage defaultMessage="Merci pour votre confiance" />
                            <EmojiInLoveHeart />
                        </Text>
                        <Text
                            variant={isDesktop ? 'title' : 'heading'}
                            fontWeight="--fw-bold"
                            textAlign="center"
                        >
                            <FormattedMessage defaultMessage="Vous êtes maintenant abonné à Ausha !" />
                        </Text>
                    </Stack>
                    <Text textAlign="center">
                        {offer ? (
                            <FormattedMessage
                                defaultMessage="Vous avez maintenant accès à toutes les fonctionnalités du plan <bold>{offer}</bold>."
                                values={{
                                    bold: (chunks: React.ReactNode) => (
                                        <Text as="span" fontWeight="--fw-bold">
                                            {chunks}
                                        </Text>
                                    ),
                                    offer: PRICING_NAME[offer],
                                }}
                            />
                        ) : null}
                    </Text>
                </Stack>
                <Button
                    onPress={async () => {
                        await refreshUserAndSubscription();
                        if (subscription?.id) {
                            history.push('/app/user/subscription?displaySurvey=true');
                        }
                    }}
                    isLoading={isLoading || !subscription?.id}
                    startIcon={
                        <FontAwesomeIcon icon={icon({ name: 'rocket-launch', style: 'solid' })} />
                    }
                >
                    <FormattedMessage defaultMessage="Accéder à Ausha" />
                </Button>
            </Stack>
        </Container>
    );
};

const Image = styled.img`
    width: 16rem;

    ${(props) => props.theme.mediaQueries.desktopAndUp} {
        width: 17.5rem;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    min-height: 100vh;
`;
const EmojiInLoveHeart = styled.img.attrs({
    src: inLoveHearts,
})`
    display: inline-block;
    height: 1.5rem;
    width: 1.5rem;
    vertical-align: middle;
    margin-left: 0.25rem;
`;

export default StripeCheckoutSuccessPage;
