import useChannelQuery from '@/queries/channel/useChannelQuery.hook';
import updateChannelGuestMainRoleMutation from '@/queries/guest/updateChannelGuestMainRoleMutation';
import updateChannelGuestShowRolesMutation from '@/queries/guest/updateChannelGuestShowRolesMutation';
import useChannelGuestUserPermissionsSummaryQuery from '@/queries/guest/useChannelGuestUserPermissionsSummary.hook';
import ChannelGuestsEditPermissionFormSkeleton from './ChannelGuestsEditPermissionFormSkeleton';
import { ChannelGuestsEditPermissionFormSchema } from './ChannelGuestsEditPermissionForm/useChannelGuestsEditPermissionFormSchema';
import ChannelGuestsEditPermissionForm from './ChannelGuestsEditPermissionForm';

interface ChannelGuestsEditPermissionProps {
    userId: number;
    onClose: () => void;
}

const ChannelGuestsEditPermission = ({ userId, onClose }: ChannelGuestsEditPermissionProps) => {
    const channel = useChannelQuery();
    const permissionsSummary = useChannelGuestUserPermissionsSummaryQuery({
        channelId: channel.data?.id,
        userId,
        options: {
            enabled: !!channel.data?.id,
        },
    });
    const updateShowRoles = updateChannelGuestShowRolesMutation();
    const updateMainRole = updateChannelGuestMainRoleMutation();

    const handleUpdatePermission = ({ shows, mainRole }: ChannelGuestsEditPermissionFormSchema) => {
        if (!channel.data?.id) return;
        const showsHaveSameRole = shows.every((show) => show.role === shows[0].role);

        /* If mainRole is not mixed and user has same role on all shows, update main role */
        if (showsHaveSameRole && mainRole !== 'mixed') {
            updateMainRole.mutate({
                channelId: channel.data?.id,
                userId,
                role: mainRole,
            });
            onClose();
            return;
        }

        /* If mainRole is mixed, update all show roles */
        updateShowRoles.mutate({
            channelId: channel.data?.id,
            userId,
            shows: shows.map(({ id, role }) => ({ id, role })),
        });
        onClose();
    };

    // TODO: Handle error state
    if (channel.isError || permissionsSummary.isError) {
        return <p>ERROR</p>;
    }

    if (channel.isLoading || permissionsSummary.isLoading) {
        return <ChannelGuestsEditPermissionFormSkeleton />;
    }

    return (
        <ChannelGuestsEditPermissionForm
            onSubmit={handleUpdatePermission}
            onClose={onClose}
            values={permissionsSummary.data}
        />
    );
};

export default ChannelGuestsEditPermission;
