import { FormattedMessage, useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import { InputTextController } from '@ui/atoms/InputText';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import useApplePodcastsReviewsFormSchema from './useApplePodcastsReviewsNotificationFormSchema.hook';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@ui/atoms/Button';
import { ToggleController } from '@ui/atoms/Toggle';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import useAppleReviewsNotificationsQuery from '@/queries/stats/useAppleReviewsNotificationsQuery.hook';
import { useParams } from 'react-router';
import ContentLoader from 'react-content-loader';
import createFallback from '@/components/unorganized/createFallback';
import useUpdateAppleReviewsNotificationsMutation from '@/queries/stats/useUpdateAppleReviewsNotificationsMutation.hook';

const Fallback = createFallback((props) => (
    <ContentLoader
        width={360}
        height={113}
        style={{ width: '100%', maxWidth: 360, height: 113 }}
        preserveAspectRatio="none"
        {...props}
    >
        {/* Notify toggle field */}
        <rect x="0" y="0" rx="5" ry="5" width="290" height="20" />
        {/* Email label */}
        <rect x="0" y="50" rx="5" ry="5" width="90" height="14" />
        {/* Email input */}
        <rect x="0" y="78" rx="5" ry="5" width="360" height="35" />
    </ContentLoader>
));

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    width: 100%;
`;
const ButtonsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    margin-top: auto;

    & > :first-child,
    > :last-child {
        align-self: stretch;
    }

    ${(p) => p.theme.mediaQueries.tabletAndUp} {
        flex-direction: row;
        justify-content: flex-end;
        column-gap: 0.5rem;
        margin-top: 0.5rem;

        & > :first-child,
        > :last-child {
            align-self: initial;
        }

        & > :first-child {
            order: 1;
        }
    }
`;

const ApplePodcastsReviewsForm = ({ onAfterSubmit }) => {
    const intl = useIntl();
    const { showId } = useParams();
    const { data: user } = useUserQuery();
    const { data: reviewNotifications, isLoading } = useAppleReviewsNotificationsQuery({ showId });

    // const isLoading = applePodcastsReviewsNotification?.update.pending;
    const schema = useApplePodcastsReviewsFormSchema();
    const { control, handleSubmit, formState, clearErrors, watch } = useForm({
        mode: 'onChange',
        defaultValues: {
            notifications_subscribed: reviewNotifications?.notificationsSubscribed || false,
            email_notified: reviewNotifications?.emailNotified || user?.email || '',
        },
        resolver: yupResolver(schema),
    });

    const updateReviewNotifications = useUpdateAppleReviewsNotificationsMutation();

    const watchNotifyEach = watch('notifications_subscribed');

    const onSubmit = async (formData) => {
        updateReviewNotifications.mutate(
            {
                showId,
                formData,
            },
            {
                onSuccess: () => {
                    onAfterSubmit();
                },
            },
        );
    };

    if (isLoading) {
        return <Fallback />;
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <ToggleController control={control} name="notifications_subscribed">
                <FormattedMessage defaultMessage="Recevoir un email à chaque nouvel avis" />
            </ToggleController>
            <InputTextController
                control={control}
                name="email_notified"
                type="email"
                label={<FormattedMessage defaultMessage="Adresse e-mail" />}
                placeholder={intl.formatMessage({ defaultMessage: 'neil.armstrong@nasa.com' })}
                errorMessage={formState.errors.email_notified?.message}
                onBlur={() => clearErrors('email_notified')}
                isDisabled={!watchNotifyEach}
            />
            <ButtonsWrapper>
                <Button
                    type="submit"
                    isLoading={isLoading}
                    isDisabled={!formState.isValid || !formState.isDirty || isLoading}
                >
                    <FormattedMessage defaultMessage="Enregistrer" />
                </Button>
                <Button variant="secondary" onPress={onAfterSubmit}>
                    <FormattedMessage defaultMessage="Annuler" />
                </Button>
            </ButtonsWrapper>
        </Form>
    );
};

export default observer(ApplePodcastsReviewsForm);
