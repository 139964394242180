import DragAndDrop from '../../organisms/DragAndDrop';
import PropTypes from 'prop-types';
import PlatformIcon from '@app/atoms/PlatformIcon';
import styled from 'styled-components';
import IconReorder from '@ui/icons/IconReorder';
import Toggle from '@ui/atoms/Toggle';
import { LISTENING_PLATFORMS_NAMES } from '@/utils/constants';

const ReorderableLinks = ({ reordered, listeningLinks }) => {
    const handleReorder = (data) => {
        const reorderedLinks = data.newList.map((link) => ({
            ...link,
            position: data.newList.findIndex((l) => l.id === link.id) + 1,
        }));
        reordered(reorderedLinks);
    };

    return (
        <>
            <DragAndDrop id="listeningLinks" list={listeningLinks} getData={handleReorder}>
                {({ item, isDragging }) => (
                    <ListeningLinkDraggableItem isDragging={isDragging}>
                        <IconReorder />
                        <DraggableItemInner>
                            {item.key ? (
                                <Left>
                                    <PlatformIcon platform={item.key} />
                                    <span>{LISTENING_PLATFORMS_NAMES[item.key]}</span>
                                </Left>
                            ) : (
                                <Placeholder />
                            )}
                            {!isDragging && (
                                <Toggle
                                    isSelected={!item.isHidden}
                                    onChange={(selected) => {
                                        reordered(
                                            listeningLinks.map((i) => {
                                                if (i.id === item.id)
                                                    return {
                                                        ...item,
                                                        isHidden: !selected,
                                                    };
                                                return i;
                                            }),
                                        );
                                    }}
                                    isDisabled={false}
                                />
                            )}
                        </DraggableItemInner>
                    </ListeningLinkDraggableItem>
                )}
            </DragAndDrop>
        </>
    );
};

const ListeningLinkDraggableItem = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;

    &:first-child {
        fill: var(--neutral500);
    }
`;

const DraggableItemInner = styled.div`
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--neutral50);
    padding-block: 0.5rem;
    padding-inline: 0.75rem;
    height: 3rem;
    border-radius: var(--r-xs);
`;

const Left = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
`;

const Placeholder = styled.div`
    height: 0.5rem;
    width: 6rem;
    border-radius: var(--r-xs);
    background: var(--neutral100);
`;

ReorderableLinks.propTypes = {
    show: PropTypes.string.isRequired,
    reordered: PropTypes.func.isRequired,
    listeningLinks: PropTypes.array,
};

export default ReorderableLinks;
