import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchAISocialPostSuggestion } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import aiKeys from './aiKeys';
import { z } from 'zod';
import { delayQuery } from '@/helpers';
import { AICustomPromptFormSchema } from '@/components/EpisodeAI/EpisodeAICustomPrompt/EpisodeAICustomPromptForm/useAICustomPromptFormSchema.hook';
import { decamelizeKeys } from 'humps';
import { SocialNetwork } from '@/shared/config/constants';

export const AISocialPostSuggestionsSchema = z.object({
    suggestion: z.string(),
});

type Key = QueryFunctionContext<ReturnType<typeof aiKeys.socialPostDetails>>;

export type AISocialPostSuggestions = z.infer<typeof AISocialPostSuggestionsSchema>;

interface DelayedQueryFnParams {
    queryContext: Key;
    delay: number;
}

const delayedQueryFn = ({
    queryContext,
    delay,
}: DelayedQueryFnParams): ReturnType<typeof queryFn> =>
    delayQuery({ callback: queryFn, delay })(queryContext);

const queryFn = async ({ queryKey: [{ episodeId, provider, settings }] }: Key) => {
    const { data } = await fetchAISocialPostSuggestion(
        episodeId,
        provider,
        decamelizeKeys(settings),
    );
    // TODO: Extract suggestions from data ?
    return AISocialPostSuggestionsSchema.parse(camelcaseKeys(data, { deep: true }));
};

interface AISocialPostSuggestionsQueryParams {
    episodeId: string;
    provider: SocialNetwork;
    settings: AICustomPromptFormSchema;
    delay?: number;
}

const useAISocialPostSuggestionsQuery = ({
    episodeId,
    provider,
    settings,
    delay = 0,
}: AISocialPostSuggestionsQueryParams) => {
    return useQuery({
        queryKey: aiKeys.socialPostDetails(episodeId, provider, settings),
        queryFn: (queryContext: Key) => delayedQueryFn({ queryContext, delay }),
        enabled: false,
    });
};

export default useAISocialPostSuggestionsQuery;
