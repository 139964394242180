import { useState, useMemo } from 'react';
import { useParams } from 'react-router';
import useQuery from '@/shared/hooks/useQuery';
import useListenersEpisodesRankingQuery from '@/queries/stats/useListenersEpisodesRankingQuery.hook';
import useCampaignListenersEpisodesRankingQuery from '@/queries/stats/useCampaignListenersEpisodesRankingQuery.hook';
import Table from './Table';
import { getLocalTimeZone } from '@internationalized/date';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import { useStatsDateSelectorContext } from '@/context/StatsDateSelectorContext';
import ServerPagination from '@/components/ui/ServerPagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const ListenersPodcastRankingTable = () => {
    const { showId } = useParams<{ showId: string }>();
    const query = useQuery();
    const episodes = query.get('episode');
    const campaignId = query.get('campaignId');
    const { startDate, endDate } = useStatsDateSelectorContext();
    const [page, setPage] = useState(1);
    const [order, setOrder] = useState<'asc' | 'desc'>('desc');

    const { data: user } = useUserQuery();
    const timezone = useMemo(() => user?.timezone || getLocalTimeZone(), [user]);

    const { data: listenersEpisodesRankings } = useListenersEpisodesRankingQuery({
        showId,
        start: startDate || '',
        end: endDate || '',
        order,
        page,
        perPage: 20,
        episodes: episodes ? episodes.split(',') : [],
        timezone,
        options: {
            enabled: !campaignId,
        },
    });

    const { data: campaignListenersEpisodesRankings } = useCampaignListenersEpisodesRankingQuery({
        campaignId: campaignId ?? '',
        start: startDate || '',
        end: endDate || '',
        order,
        page: 1,
        perPage: 10,
        episodes: episodes ? episodes.split(',') : [],
        timezone,
        options: {
            enabled: !!campaignId,
        },
    });

    const rankings = campaignId ? campaignListenersEpisodesRankings : listenersEpisodesRankings;

    if (!rankings || rankings.data.length === 0) {
        return (
            <ListenersPodcastRankingTableWrapper>
                <EmptyStateWrapper>
                    <FontAwesomeIcon icon={icon({ name: 'snooze', style: 'solid' })} />
                    <TitleAndSubtitleWrapper>
                        <Title>
                            <FormattedMessage defaultMessage="Vous n'avez pas encore d'écoute" />
                        </Title>
                        <Subtitle>
                            <FormattedMessage defaultMessage="Revenez consulter vos statistiques plus tard le temps que de nouveaux auditeurs découvrent votre podcast." />
                        </Subtitle>
                    </TitleAndSubtitleWrapper>
                </EmptyStateWrapper>
            </ListenersPodcastRankingTableWrapper>
        );
    }

    return (
        <ListenersPodcastRankingTableWrapper>
            <Table
                rows={rankings.data}
                sorting={[{ id: 'downloads', desc: order === 'desc' }]}
                setSorting={() => setOrder(order === 'desc' ? 'asc' : 'desc')}
            />
            {rankings?.pagination &&
                rankings?.data.length > 0 &&
                rankings?.pagination.totalPages > 1 && (
                    <ServerPagination
                        pagination={{
                            ...rankings.pagination,
                            perPage: 20,
                        }}
                        onGoToPage={(page: number) => setPage(page)}
                        onGoToFirstPage={() => setPage(1)}
                        onGoToLastPage={() => setPage(rankings.pagination.totalPages)}
                        onGoToPreviousPage={() => setPage(rankings.pagination.currentPage - 1)}
                        onGoToNextPage={() => setPage(rankings.pagination.currentPage + 1)}
                        options={{
                            goToFirstAndLastPageButtons: false,
                            showPaginationInfo: true,
                            showPageSelector: false,
                        }}
                    />
                )}
        </ListenersPodcastRankingTableWrapper>
    );
};

const ListenersPodcastRankingTableWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: var(--white);
    border-radius: var(--r-m);
    margin-top: 1rem;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    padding: 0.5rem;
`;
const EmptyStateWrapper = styled.div`
    height: 20rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: var(--neutral);
    gap: 1rem;

    & > :first-child {
        font-size: 2rem;
    }
`;
const TitleAndSubtitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const Title = styled.h2`
    font-size: var(--fs-body-m);
    font-weight: var(--fw-bold);
`;
const Subtitle = styled.p`
    max-width: 24rem;
    text-align: center;
`;

export default ListenersPodcastRankingTable;
