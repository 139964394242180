import { Redirect, Route, Switch, useParams } from 'react-router';
import Layout from './Layout';
import SocialMediaPage from '@/components/app/pages/SocialMediaPage';
import PlayerPage from '@/components/app/pages/PlayerPage';
import WebsiteSettings from '@/components/WebsiteSettings';
import SmartlinksSettings from '@/components/unorganized/SmartlinksSettings';
import Newsletter from '@/components/Newsletter';
import Trackers from '@/components/unorganized/Trackers';
import CallToActionPage from '@/components/unorganized/CallToActionPage';
import ExternalLinks from '@/components/unorganized/ExternalLinks';
import { useGuard } from '@/shared/hooks/useGuard.hook';
import { USER_ROLES } from '@/shared/config/constants';
import Clips from './Clips';
import Webhook from '@/components/unorganized/Webhook';
import Accounts from './Accounts';

const Communication = () => {
    const { showId } = useParams<{ showId: string }>();
    const roleGuard = useGuard({
        disallowedUserRoles: [USER_ROLES.VIEWER],
    });
    const showStatusGuard = useGuard({
        disallowedShowStatuses: ['archivedShows'],
    });

    if (roleGuard.allow === false) {
        return <Redirect to={`/app/show/${showId}/episodes`} />;
    }

    return (
        <Switch>
            <Route exact path="/app/show/:showId/communication/social-media">
                {showStatusGuard.allow === false ? (
                    <Redirect to={`/app/show/${showId}/communication/player`} />
                ) : (
                    <Layout>
                        <SocialMediaPage />
                    </Layout>
                )}
            </Route>
            <Route exact path="/app/show/:showId/communication/clip">
                {showStatusGuard.allow === false ? (
                    <Redirect to={`/app/show/${showId}/communication/player`} />
                ) : (
                    <Layout>
                        <Clips />
                    </Layout>
                )}
            </Route>
            <Route exact path="/app/show/:showId/communication/player/:type?/:id?">
                <Layout>
                    <PlayerPage />
                </Layout>
            </Route>
            <Route exact path="/app/show/:showId/communication/page">
                {showStatusGuard.allow === false ? (
                    <Redirect to={`/app/show/${showId}/communication/player`} />
                ) : (
                    <Layout>
                        <WebsiteSettings />
                    </Layout>
                )}
            </Route>
            <Route exact path="/app/show/:showId/communication/smartlink">
                {showStatusGuard.allow === false ? (
                    <Redirect to={`/app/show/${showId}/communication/player`} />
                ) : (
                    <Layout>
                        <SmartlinksSettings />
                    </Layout>
                )}
            </Route>
            <Route exact path="/app/show/:showId/communication/newsletter">
                {showStatusGuard.allow === false ? (
                    <Redirect to={`/app/show/${showId}/communication/player`} />
                ) : (
                    <Layout>
                        <Newsletter />
                    </Layout>
                )}
            </Route>
            <Route exact path="/app/show/:showId/communication/settings/accounts">
                {showStatusGuard.allow === false ? (
                    <Redirect to={`/app/show/${showId}/communication/player`} />
                ) : (
                    <Layout>
                        <Accounts />
                    </Layout>
                )}
            </Route>
            <Route exact path="/app/show/:showId/communication/settings/external-links">
                {showStatusGuard.allow === false ? (
                    <Redirect to={`/app/show/${showId}/communication/player`} />
                ) : (
                    <Layout>
                        <ExternalLinks />
                    </Layout>
                )}
            </Route>
            <Route exact path="/app/show/:showId/communication/settings/call-to-action">
                {showStatusGuard.allow === false ? (
                    <Redirect to={`/app/show/${showId}/communication/player`} />
                ) : (
                    <Layout>
                        <CallToActionPage />
                    </Layout>
                )}
            </Route>
            <Route exact path="/app/show/:showId/communication/settings/tracker">
                {showStatusGuard.allow === false ? (
                    <Redirect to={`/app/show/${showId}/communication/player`} />
                ) : (
                    <Layout>
                        <Trackers />
                    </Layout>
                )}
            </Route>
            <Route exact path="/app/show/:showId/communication/settings/webhook">
                {showStatusGuard.allow === false ? (
                    <Redirect to={`/app/show/${showId}/communication/player`} />
                ) : (
                    <Layout>
                        <Webhook />
                    </Layout>
                )}
            </Route>
        </Switch>
    );
};

export default Communication;
