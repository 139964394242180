import { FormattedMessage, FormattedNumber, defineMessages, useIntl } from 'react-intl';
import SharingProviderButton from '@ui/atoms/SharingProviderButton';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import DeprecatedIcon from '@ui/atoms/DeprecatedIcon';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import referralIcon from '@public/icons/emoji_referral.svg';
import giftIcon from '@public/icons/emoji_gift.svg';
import rocketIcon from '@public/icons/emoji_rocket.svg';
import starsIcon from '@public/icons/emoji_starsstruck.svg';
import sponsorshipHighFiveImage from '@public/images/sponsorship_high_five.png';
import sponsorshipSubscribeFrImage from '@public/images/sponsorship_subscribe_fr.png';
import sponsorshipSubscribeEnImage from '@public/images/sponsorship_subscribe_en.webp';
import sponsorshipGiftImage from '@public/images/sponsorship_gift.png';
import Flex from '@ui/layout/Flex';
import { PRICING } from '@/shared/config/pricing';
import Img from '@ui/atoms/Img';
import EmailSharingButton from '@ui/atoms/EmailSharingButton';
import { useResponsive } from '@/shared/hooks/useResponsive';
import styled from 'styled-components';
import InputText from '@ui/atoms/InputText/InputText';
import Text from '@ui/atoms/Text';
import Stack from '@ui/layout/Stack';
import Button from '@/components/ui/atoms/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Modal from '@/components/ui/molecules/Modal';
import { useOverlayTriggerState } from 'react-stately';
import CustomizeSponsorshipCodeForm from '../../unorganized/CustomizeSponsorshipCodeForm';
import useSponsorshipCodeQuery from '@/queries/sponsorshipCode/useSponsorshipCodeQuery.hook';
import Link from '@/components/ui/atoms/Link';
import ZebraIllustration from '@/components/ui/atoms/ZebraIllustration';
import ShiningDiamond from '@/components/ui/atoms/ShiningDiamond';
import { useContext } from 'react';
import { CurrentShow } from '@/context/CurrentShowContext';
import useSubscriptionQuery from '@/queries/subscription/useSubscriptionQuery.hook';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import { useHistory } from 'react-router';
import IconButton from '@/components/IconButton';

const sponsorMessages = defineMessages({
    sponsorEmailSubject: {
        defaultMessage: 'Jusqu’à 30€ de réduction sur ton abonnement Ausha ! 🚀',
    },
    sponsorEmailBody: {
        defaultMessage:
            'Hello,{break}Tu connais Ausha, la plateforme de diffusion et d’hébergement de podcasts ? Je t’offre une réduction sur ta première facture grâce à mon code de parrainage ! 😀{break}{break}{code}{break}{break}Pour en profiter : crée simplement ton compte et renseigne directement ton code au moment de ton inscription 🚀 Tu bénéficieras alors d’une réduction sur ta prochaine facture ! 💪 On dit merci qui ? “Merci Ausha !” 💜{break}{break}À bientôt !',
    },
    sponsorFacebookQuote: {
        defaultMessage:
            'Hello ! J’aimerais te faire découvrir Ausha ! C’est mon hébergeur de podcast, et j’en suis ravi•e ! Je t’ai choisi•e pour profiter d’une remise sur l’abonnement de ton choix avec le code {code}. Profites-en pour propulser ton podcast ! 🚀\nMerci @Ausha\n',
    },
    sponsorTwitterMessage: {
        defaultMessage:
            'Hello ! J’aimerais te faire découvrir Ausha ! C’est mon hébergeur de podcast, et j’en suis ravi•e ! Je t’ai choisi•e pour profiter d’une remise sur l’abonnement de ton choix avec le code {code}. Profites-en pour propulser ton podcast ! 🚀 \nMerci @Ausha_FR\n',
    },
});

const LinkSender = ({ intl, code, url, isDesktop }) => {
    const customizeCodeModalState = useOverlayTriggerState({});
    return (
        <>
            <Container>
                <Stack $gap="2rem">
                    <Stack $gap="0.25rem">
                        <Flex $align="center" $gap="0.5rem">
                            <DeprecatedIcon as={referralIcon} size={isDesktop ? 40 : 24} />
                            <Text fontWeight="--fw-bold" variant="title">
                                <FormattedMessage defaultMessage="Partagez votre code de parrainage" />
                            </Text>
                        </Flex>
                        <Text textAlign="left" color="--neutral500">
                            <FormattedMessage defaultMessage="Obtenez une réduction sur votre prochaine facture en parrainant un ami." />
                        </Text>
                    </Stack>
                    <LinkSenderSharingWrapper>
                        <LinkSenderSharingActions>
                            <SponsorshipCodeInput
                                value={code}
                                label={<FormattedMessage defaultMessage="Votre code à partager" />}
                                copyToClipboardEnabled
                            />
                            <EmailSharingButton
                                subject={intl.formatMessage(sponsorMessages.sponsorEmailSubject)}
                                body={intl.formatMessage(sponsorMessages.sponsorEmailBody, {
                                    code,
                                    break: '\n',
                                })}
                                url={url}
                            />
                            <SharingButton
                                provider="facebook"
                                customLabel={
                                    <FormattedMessage defaultMessage="Partager sur Facebook" />
                                }
                                url={url}
                                quote={intl.formatMessage(sponsorMessages.sponsorFacebookQuote, {
                                    code,
                                })}
                                isDesktop={isDesktop}
                            />
                            <SharingButton
                                provider="twitter"
                                customLabel={
                                    <FormattedMessage defaultMessage="Partager sur Twitter" />
                                }
                                url={url}
                                title={intl.formatMessage(sponsorMessages.sponsorTwitterMessage, {
                                    code,
                                })}
                                isDesktop={isDesktop}
                            />
                        </LinkSenderSharingActions>
                        <CustomizeCodeButton
                            onPress={customizeCodeModalState.open}
                            variant="secondary"
                            startIcon={
                                <FontAwesomeIcon
                                    icon={icon({ name: 'pen-to-square', style: 'solid' })}
                                />
                            }
                        >
                            <FormattedMessage defaultMessage="Personnaliser mon code" />
                        </CustomizeCodeButton>
                    </LinkSenderSharingWrapper>
                </Stack>
            </Container>
            {customizeCodeModalState.isOpen && (
                <Modal
                    isOpen={customizeCodeModalState.isOpen}
                    onClose={customizeCodeModalState.close}
                    title={<FormattedMessage defaultMessage="Personnaliser mon code" />}
                >
                    <CustomizeSponsorshipCodeForm
                        onCloseModal={customizeCodeModalState.close}
                        code={code}
                    />
                </Modal>
            )}
        </>
    );
};

const RewardPanel = ({ isDesktop, currency }) => (
    <Container>
        <DeprecatedText size={isDesktop ? 'veryBig' : 'upperBig'} weight="bold">
            <Flex $align="center">
                <DeprecatedIcon as={giftIcon} size={isDesktop ? 40 : 24} mr={isDesktop ? 16 : 10} />
                <FormattedMessage defaultMessage="Les récompenses" />
            </Flex>
        </DeprecatedText>

        <DeprecatedText mt={16}>
            <FormattedMessage defaultMessage="Bénéficiez d’une réduction sur votre prochaine facture en fonction de l’abonnement de votre filleul :" />
        </DeprecatedText>

        <RewardOffer
            mt={18}
            offer={PRICING.LAUNCH}
            price={
                <FormattedNumber
                    value={5}
                    style="currency"
                    currency={currency}
                    maximumFractionDigits={0}
                />
            }
        />
        <RewardOffer
            mt={18}
            offer={PRICING.BOOST}
            price={
                <FormattedNumber
                    value={10}
                    style="currency"
                    currency={currency}
                    maximumFractionDigits={0}
                />
            }
        />
        <RewardOffer
            mt={18}
            offer={PRICING.SUPERSONIC}
            price={
                <FormattedNumber
                    value={30}
                    style="currency"
                    currency={currency}
                    maximumFractionDigits={0}
                />
            }
        />

        <DeprecatedText
            as="span"
            mt={isDesktop ? 30 : 22}
            wrap
            weight="bold"
            css="line-height: 20px"
        >
            <FormattedMessage
                defaultMessage="Votre filleul bénéficie de la <underline>même réduction</underline> {emoji}"
                values={{
                    underline: (chunks) => (
                        <DeprecatedText
                            as="span"
                            weight="bold"
                            css={`
                                border-bottom: 2px solid;
                                border-color: var(--primary);
                                border-radius: var(--r-xxs);
                            `}
                        >
                            {chunks}
                        </DeprecatedText>
                    ),
                    emoji: (
                        <DeprecatedIcon as={starsIcon} size={20} css="vertical-align: middle;" />
                    ),
                }}
            />
        </DeprecatedText>
    </Container>
);

const RewardOffer = ({ offer, price, ...props }) => (
    <DeprecatedPaper {...props}>
        <DeprecatedPaper background="--primary" px={8} py={2} w="fit-content">
            <DeprecatedText color="white" weight="bold" size="big" capitalize>
                <FormattedMessage defaultMessage="{offer}" values={{ offer }} />
            </DeprecatedText>
        </DeprecatedPaper>

        <DeprecatedText flex align="baseline" mt={10}>
            <FormattedMessage
                defaultMessage="{price} de réduction"
                values={{
                    price: (
                        <DeprecatedText color="--primary" size="veryBig" weight="bold" mr={8}>
                            <FormattedMessage defaultMessage="{price}" values={{ price }} />
                        </DeprecatedText>
                    ),
                }}
            />
        </DeprecatedText>
    </DeprecatedPaper>
);

const HowItWorks = ({ isDesktop, isEnglish }) => (
    <Container>
        <DeprecatedText size={isDesktop ? 'veryBig' : 'upperBig'} weight="bold">
            <Flex $align="center">
                <DeprecatedIcon
                    as={rocketIcon}
                    size={isDesktop ? 40 : 24}
                    mr={isDesktop ? 16 : 10}
                />
                <FormattedMessage defaultMessage="Comment ça marche ?" />
            </Flex>
        </DeprecatedText>
        <DeprecatedPaper flex wrap={!isDesktop} gap={26} mt={isDesktop ? 65 : 16}>
            <StepPanel number={1} image={sponsorshipHighFiveImage} isDesktop={isDesktop}>
                <FormattedMessage
                    defaultMessage="{bold} de parrainage à votre futur filleul."
                    values={{
                        bold: (
                            <DeprecatedText as="span" weight="bold">
                                <FormattedMessage defaultMessage="Partagez votre code" />
                            </DeprecatedText>
                        ),
                    }}
                />
            </StepPanel>
            <StepPanel
                number={2}
                image={isEnglish ? sponsorshipSubscribeEnImage : sponsorshipSubscribeFrImage}
                isDesktop={isDesktop}
            >
                <FormattedMessage
                    defaultMessage="Votre filleul s’inscrit sur Ausha et utilise votre code {bold}."
                    values={{
                        bold: (
                            <DeprecatedText as="span" weight="bold">
                                <FormattedMessage defaultMessage="lors de son inscription" />
                            </DeprecatedText>
                        ),
                    }}
                />
            </StepPanel>
            <StepPanel number={3} image={sponsorshipGiftImage} isDesktop={isDesktop}>
                <FormattedMessage
                    defaultMessage="{bold} en fonction de l’abonnement de votre filleul 🎉"
                    values={{
                        bold: (
                            <DeprecatedText as="span" weight="bold">
                                <FormattedMessage defaultMessage="Vous êtes récompensé.e" />
                            </DeprecatedText>
                        ),
                    }}
                />
            </StepPanel>
        </DeprecatedPaper>
    </Container>
);

const StepPanel = ({ number, image, children, isDesktop }) => (
    <DeprecatedPaper>
        <DeprecatedPaper background="--neutral50" position="relative" width="fit-content">
            <DeprecatedPaper
                position="absolute"
                background="--primary"
                w={32}
                h={32}
                flex
                align="center"
                justify="center"
                style={{ bottom: 0, borderRadius: '50%' }}
            >
                <DeprecatedText color="white" weight="bold">
                    {number}
                </DeprecatedText>
            </DeprecatedPaper>
            <Img src={image} width={200} height={160} />
        </DeprecatedPaper>
        <DeprecatedText mt={isDesktop ? 24 : 16} css="line-height: 20px">
            {children}
        </DeprecatedText>
    </DeprecatedPaper>
);

const SponsorshipPage = () => {
    const intl = useIntl();
    const history = useHistory();
    const show = useContext(CurrentShow);
    const { data: user } = useUserQuery();
    const { data: subscription } = useSubscriptionQuery();
    const isSubscriptionActive = !!(show?.ownerPricing || subscription?.activated);

    const username = `${user?.firstname || ''} ${user?.lastname || ''}`;
    const isEnglish = user?.language === 'en' || true;

    const { isDesktop } = useResponsive();
    const { data } = useSponsorshipCodeQuery();
    const code = data?.code;

    return (
        <SponsorshipWrapper>
            <SponsorshipInnerWrapper>
                <DeprecatedPaper flex mb={isDesktop ? 45 : 16} align="center">
                    <GoBackButton
                        icon={
                            <FontAwesomeIcon icon={icon({ name: 'arrow-left', style: 'solid' })} />
                        }
                        aria-label="Go back"
                        onPress={() => history.goBack()}
                        size="small"
                        variant="tertiary"
                    />
                    <DeprecatedText size="veryBig" color="white" weight="bold" ml={16}>
                        <FormattedMessage defaultMessage="Parrainage" />
                    </DeprecatedText>
                </DeprecatedPaper>
                {isSubscriptionActive ? (
                    <Blocks>
                        <LinkSender
                            username={username}
                            code={code}
                            url="https://www.ausha.co"
                            intl={intl}
                            isDesktop={isDesktop}
                        />
                        <BlocksBottomWrapper>
                            <RewardPanel
                                isDesktop={isDesktop}
                                currency={subscription?.currency || 'USD'}
                            />
                            <HowItWorks isDesktop={isDesktop} isEnglish={isEnglish} />
                        </BlocksBottomWrapper>
                    </Blocks>
                ) : (
                    <SponsorshipNotActiveWrapper>
                        <SponsorshipNotActiveInnerWrapper>
                            <ZebraIllustration>
                                <ShiningDiamond />
                            </ZebraIllustration>
                            <Stack $gap="0.5rem">
                                <Text textAlign="center" variant="headingS" fontWeight="--fw-bold">
                                    <FormattedMessage defaultMessage="Le parrainage est réservé aux abonnés d’Ausha" />
                                </Text>
                                <Text textAlign="center" variant="bodyM" color="--neutral500">
                                    <FormattedMessage defaultMessage="Pour en profiter, abonnez-vous 🚀" />
                                </Text>
                            </Stack>
                            <Button
                                startIcon={
                                    <FontAwesomeIcon
                                        icon={icon({ name: 'rocket', style: 'solid' })}
                                    />
                                }
                                as={Link}
                                to={'/app/user/subscription/offers'}
                            >
                                <FormattedMessage defaultMessage="S'abonner" />
                            </Button>
                        </SponsorshipNotActiveInnerWrapper>
                    </SponsorshipNotActiveWrapper>
                )}
            </SponsorshipInnerWrapper>
        </SponsorshipWrapper>
    );
};

const SponsorshipWrapper = styled.div`
    min-height: 100%;
`;
const SponsorshipInnerWrapper = styled.div`
    max-width: var(--container-width);
    margin: auto;
    padding-block: 4rem;
    padding-inline: var(--container-padding-mobile);

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        padding-inline: var(--container-padding);
    }
`;
const SponsorshipCodeInput = styled(InputText)`
    flex: 1;
`;
const LinkSenderSharingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 1.5rem;
    border-radius: var(--r-xs);
    background-color: var(--neutral50);
`;
const LinkSenderSharingActions = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    align-items: flex-end;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
        column-gap: 0.5rem;
    }
`;
const SharingButton = styled(SharingProviderButton)`
    width: 100%;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        width: initial;
    }
`;
const CustomizeCodeButton = styled(Button)`
    align-self: start;

    ${({ theme }) => theme.mediaQueries.mobile} {
        align-self: stretch;
    }
`;
const SponsorshipNotActiveWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: start;
    background-color: var(--white);
    border-radius: var(--r-xs);
    padding: 2rem;
    width: 100%;
`;
const SponsorshipNotActiveInnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 2rem;
`;
const Container = styled.div`
    border-radius: var(--r-s);
    background-color: var(--white);
    padding: var(--container-padding-mobile);

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        padding: var(--container-padding);
    }
`;
const Blocks = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
`;
const BlocksBottomWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
        column-gap: 1rem;
    }
`;
const GoBackButton = styled(IconButton)`
    width: 2rem;
    height: 2rem;
    border-radius: var(--r-full);
    background-color: var(--white);
    color: var(--black);
    padding: 0.5rem;
`;
export default SponsorshipPage;
