import { useQuery } from '@tanstack/react-query';
import { fetchProviders } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import providerKeys from '@/queries/provider/providerKeys';

const transform = (data) => {
    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const queryFn = async ({ queryKey: [{ showId }] }) => {
    const { axios, ...rest } = await fetchProviders(showId);
    return transform(rest);
};

const useProvidersQuery = (showId) => {
    return useQuery({
        queryKey: providerKeys.listByShowId(showId),
        queryFn,
        enabled: !!showId,
    });
};

export default useProvidersQuery;
