import { FormattedMessage, useIntl } from 'react-intl';
import useUserStore from '@/utils/hooks/useUserStore';
import { observer } from 'mobx-react';
import { InputTextController } from '@ui/atoms/InputText';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import useDeleteAccountFormSchema from './useDeleteAccountFormSchema.hook';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@ui/atoms/Button';
import Text from '@ui/atoms/Text';
import useDeleteAccount from './useDeleteAccount.hook';
import { useModalToastQueue } from '@/utils/hooks/useModalToastQueue.hook';

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    height: 100%;
`;
const ButtonsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    margin-top: auto;

    & > :first-child,
    > :last-child {
        align-self: stretch;
    }

    ${(p) => p.theme.mediaQueries.tabletAndUp} {
        flex-direction: row;
        justify-content: flex-end;
        column-gap: 0.5rem;
        margin-top: auto;

        & > :first-child,
        > :last-child {
            align-self: initial;
        }

        & > :first-child {
            order: 1;
        }
    }
`;

const DeleteAccountForm = ({ onCloseModal }) => {
    const intl = useIntl();
    const { user } = useUserStore();
    const { mutate: deleteAccount, isLoading } = useDeleteAccount();
    const schema = useDeleteAccountFormSchema(user);
    const { control, handleSubmit, formState, clearErrors, setError } = useForm({
        mode: 'onChange',
        resolver: yupResolver(schema),
    });
    const toast = useModalToastQueue();

    const onSubmit = (formData) => {
        deleteAccount(formData, {
            onError: (error) => {
                if (error.response.status === 422) {
                    setError('password', {
                        type: '401',
                        message: intl.formatMessage({
                            defaultMessage: "Votre mot de passe n'est pas valide",
                        }),
                    });
                    toast.alert(
                        <FormattedMessage defaultMessage="Les modifications n’ont pas pu être enregistrées. Veuillez réessayer." />,
                    );
                }
            },
        });
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            {user.isUsingExternalLoginProvider ? (
                <Text>
                    <FormattedMessage defaultMessage="Souhaitez-vous supprimer votre compte Ausha ? 💔" />
                </Text>
            ) : (
                <InputTextController
                    control={control}
                    name="password"
                    type="password"
                    label={<FormattedMessage defaultMessage="Mot de passe" />}
                    placeholder={intl.formatMessage({ defaultMessage: '8 caractères minimum' })}
                    errorMessage={formState.errors.password?.message}
                    onBlur={() => clearErrors('password')}
                    isRequired
                />
            )}
            <ButtonsWrapper>
                <Button
                    type="submit"
                    isLoading={isLoading}
                    isDisabled={!formState.isValid || isLoading}
                >
                    <FormattedMessage defaultMessage="Supprimer mon compte" />
                </Button>
                <Button variant="secondary" onPress={onCloseModal}>
                    <FormattedMessage defaultMessage="Annuler" />
                </Button>
            </ButtonsWrapper>
        </Form>
    );
};

export default observer(DeleteAccountForm);
