import { lazy, Suspense, useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useWindowDimensions } from '@/shared/hooks/useWindowDimensions';
import { AuthRoute } from './legacy/authorisationRoutes';
import DefaultRoute from './legacy/DefaultRoute';
import LoginWithSharingProviderPage from './app/pages/LoginWithSharingProviderPage';
import DiscordPage from './app/pages/DiscordPage';
import Unsubscribe from './app/pages/Unsubscribe';
import Subscribe from './app/pages/Subscribe';
import OffersPage from './app/pages/OffersPage';
import EmailConfirmationPage from './app/pages/EmailConfirmationPage';
import StripeCheckoutSuccessPage from './app/pages/StripeCheckoutSuccessPage';
import Topbar from './unorganized/Topbar';
import FullPageLayout from './app/layout/Fullpage.layout';
import SponsorshipPage from './app/pages/SponsorshipPage';
import { OverlayProvider } from 'react-aria';
import PricingSuccessPage from '@app/pages/PricingSuccessPage';
import { StatsDateSelectorProvider } from '@/context/StatsDateSelectorContext';
import { USER_OPTIONS } from '@/shared/config/userOptions';
import { AudioPlayerContextProvider } from '@/context/AudioPlayerContext';
import ApplicationPage from './app/pages/ApplicationPage';
import Auth from '@/features/auth';
import useRefreshToken from '@/shared/hooks/useRefreshToken';

const NewShowPage = lazy(() => import('./app/pages/NewShowPage'));
const SongLibraryPage = lazy(() => import('./app/pages/SongLibraryPage'));
const ChannelPage = lazy(() => import('./app/pages/ChannelPage'));

import Responsive from '@/context/responsive.context';
import useAmplitudeLogLocationChangeEvent from '@/shared/hooks/useAmplitudeLogLocationChangeEvent.hook';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import ErrorPage from './app/pages/ErrorPage';
import MaintenancePage from './app/pages/MaintenancePage';
import useUserHasHostingAccess from '@/shared/hooks/useUserHasHostingAccess.hook';
import User from '@/features/user';
import AppMiddleware from '@/shared/components/AppMiddleware';
import LoginAs from '@/features/auth/components/Auth/LoginAs';
import MainLayout from '@app/layout/Main/Main.layout';
import NavigationMenu from '@/components/NavigationMenu';
import Footer from '@/components/Footer';
import { PricingContextProvider } from '@/context/PricingContext';
import Onboarding from '@/features/onboarding/components/Onboarding';
import { useIntercom } from 'react-use-intercom';

require('dayjs/locale/fr');

function App() {
    /**
     * Axios response interceptor to try to refresh accessToken on 401 Unauthorized responses.
     */
    useRefreshToken();
    const location = useLocation();
    const isLoginAsMode = location.pathname === '/loginas';

    useAmplitudeLogLocationChangeEvent();
    const { media, width } = useWindowDimensions();

    const { data: user } = useUserQuery({ enabled: !isLoginAsMode });
    const userHasHostingAccess = useUserHasHostingAccess({ enabled: !isLoginAsMode });
    const hasAccessToChannelPage = user?.options.includes(USER_OPTIONS.CHANNEL_PAGE);

    const locale = user?.language?.toLowerCase();
    const INTERCOM_APP_ID =
        locale === 'fr' ? process.env.INTERCOM_TOKEN_FR : process.env.INTERCOM_TOKEN_EN;
    const { boot } = useIntercom();

    useEffect(() => {
        if (!INTERCOM_APP_ID || !user || !user.isTrackedInIntercom) {
            return;
        }

        boot({
            userId: user.id.toString(),
            userHash: user.intercomToken,
            email: user.email,
            name: `${user.firstname} ${user.lastname}`,
        });
    }, [user, isLoginAsMode, INTERCOM_APP_ID]);

    if (isLoginAsMode) {
        return <LoginAs />;
    }

    return (
        <Responsive.Provider value={{ ...media, width }}>
            <OverlayProvider style={{ height: '100%' }}>
                <AudioPlayerContextProvider>
                    <StatsDateSelectorProvider>
                        <Switch>
                            <Route path="/error" exact component={ErrorPage} />
                            <Route path="/error/maintenance" component={MaintenancePage} />
                            <Route
                                path="/(login|register|email-verify|password-recovery|loginas|google-callback|facebook-callback|ausha-club-callback|password)"
                                component={Auth}
                            />
                            <Route
                                render={() => (
                                    <Suspense fallback={null}>
                                        <Switch>
                                            <AuthRoute path="/checkout-success">
                                                <PricingContextProvider>
                                                    <StripeCheckoutSuccessPage />
                                                </PricingContextProvider>
                                            </AuthRoute>
                                            <AuthRoute path="/app/subscription/offers">
                                                <PricingContextProvider>
                                                    <OffersPage />
                                                </PricingContextProvider>
                                            </AuthRoute>
                                            <AuthRoute
                                                path="/email-confirmation"
                                                component={EmailConfirmationPage}
                                            />
                                            <AuthRoute path="/discord" component={DiscordPage} />
                                            <Route path="/app/social/:provider">
                                                <LoginWithSharingProviderPage />
                                            </Route>
                                            <AuthRoute path="/app/user">
                                                <MainLayout
                                                    header={
                                                        <>
                                                            <Topbar />
                                                            <NavigationMenu />
                                                        </>
                                                    }
                                                    footer={<Footer />}
                                                >
                                                    <User />
                                                </MainLayout>
                                            </AuthRoute>
                                            {userHasHostingAccess && (
                                                <AuthRoute path="/app/sponsorship">
                                                    <FullPageLayout header={<Topbar />}>
                                                        <SponsorshipPage />
                                                    </FullPageLayout>
                                                </AuthRoute>
                                            )}
                                            <AuthRoute path="/app/show/new">
                                                <FullPageLayout header={<Topbar />}>
                                                    <NewShowPage />
                                                </FullPageLayout>
                                            </AuthRoute>
                                            <AuthRoute
                                                path="/app/show/:showId?/:tabMenu?"
                                                component={ApplicationPage}
                                            />
                                            {userHasHostingAccess && (
                                                <AuthRoute path="/app/song-library">
                                                    <FullPageLayout header={<Topbar />}>
                                                        <SongLibraryPage />
                                                    </FullPageLayout>
                                                </AuthRoute>
                                            )}
                                            {userHasHostingAccess && hasAccessToChannelPage && (
                                                <AuthRoute path="/app/channel/settings">
                                                    <FullPageLayout header={<Topbar />}>
                                                        <ChannelPage />
                                                    </FullPageLayout>
                                                </AuthRoute>
                                            )}
                                            <AuthRoute
                                                path="/app/onboarding"
                                                component={Onboarding}
                                            />
                                            <AuthRoute
                                                path="/app/pricing/success"
                                                component={PricingSuccessPage}
                                            />
                                            <AuthRoute path="/app">
                                                <AppMiddleware />
                                            </AuthRoute>
                                            <Route path="/unsubscribe" component={Unsubscribe} />
                                            <Route path="/subscribe" component={Subscribe} />
                                            <DefaultRoute to="/app" />
                                        </Switch>
                                    </Suspense>
                                )}
                            />
                        </Switch>
                    </StatsDateSelectorProvider>
                </AudioPlayerContextProvider>
            </OverlayProvider>
        </Responsive.Provider>
    );
}

export default App;
