import { useState, useEffect } from 'react';
import AushaLogo from '@ui/atoms/AushaLogo';
import PricingContext, { offerUpdatePayload, errorPayload } from './PricingContext';
import useSubscriptionQuery from '@/queries/subscription/useSubscriptionQuery.hook';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import { PRICING_CURRENCY } from '@/utils/pricing';
import usePricesQuery, { Currency, Offer } from '@/queries/price/usePricesQuery.hook';
import PricingOffers from './PricingOffers';
import PricingUpdateOfferModal from './PricingUpdateOfferModal';
import PricingErrorModal from './PricingErrorModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useHistory } from 'react-router';
import Button from '@ui/atoms/Button';
import useQuery from '@/utils/hooks/useQuery';
import { FormattedMessage } from 'react-intl';
import Dialog from '@ui/atoms/Dialog';
import Text from '@ui/atoms/Text';
import styled from 'styled-components';

const OffersPage = () => {
    const history = useHistory();
    const query = useQuery();
    const fromCheckout = query.get('fromCheckout') === 'true';
    const { data: subscription } = useSubscriptionQuery();
    const { data: user } = useUserQuery();
    const { data: prices } = usePricesQuery();
    const isCurrentSubscriptionActive = !!subscription?.activated;
    const isCurrentSubscriptionBilledYearly = !!subscription?.yearly;
    const currentSubscriptionCurrency: Currency | null =
        (subscription?.currency as Currency) || null;
    const currentSubscriptionPricing: Offer | null = subscription?.pricing || null;

    const [isYearlyPeriodicitySelected, setYearlyPeriodicitySelected] = useState(
        (subscription?.activated && isCurrentSubscriptionBilledYearly) ?? false,
    );
    const [pricingUpdateOfferModalIsOpen, setPricingUpdateOfferModalIsOpen] = useState(false);
    const [paymentValidationModalIsOpen, setPaymentValidationModalIsOpen] = useState(false);
    const [pricingErrorModalIsOpen, setPricingErrorModalIsOpen] = useState(false);

    const calculatedCurrency =
        subscription?.activated && currentSubscriptionCurrency
            ? currentSubscriptionCurrency
            : user?.country === 'FR'
            ? PRICING_CURRENCY.EUR
            : user?.country === 'GB'
            ? PRICING_CURRENCY.GBP
            : PRICING_CURRENCY.USD;

    const [selectedCurrency, setSelectedCurrency] = useState(calculatedCurrency as Currency);

    const [offerUpdatePayload, setOfferUpdatePayload] = useState<offerUpdatePayload | null>(null);
    const [errorPayload, setErrorPayload] = useState<errorPayload | null>(null);

    useEffect(() => {
        if (offerUpdatePayload) {
            setPricingUpdateOfferModalIsOpen(true);
        }
    }, [offerUpdatePayload]);

    useEffect(() => {
        if (errorPayload && errorPayload.error !== 'payment_validation_error') {
            setPricingUpdateOfferModalIsOpen(false);
            setPricingErrorModalIsOpen(true);
        }

        if (errorPayload && errorPayload.error === 'payment_validation_error') {
            setPricingUpdateOfferModalIsOpen(false);
            setPaymentValidationModalIsOpen(true);
        }
    }, [errorPayload]);

    const getPricesOfCurrencyAndProduct = (currency: Currency, product: Offer) => {
        if (!prices) {
            return null;
        }
        return prices?.[currency]?.[product] ?? null;
    };

    const pricesOfCurrentSubscriptionPricing = () => {
        if (!subscription || !currentSubscriptionCurrency || !currentSubscriptionPricing) {
            return null;
        }
        return getPricesOfCurrencyAndProduct(
            currentSubscriptionCurrency,
            currentSubscriptionPricing,
        );
    };

    return (
        <PricingContext.Provider
            value={{
                getPricesOfCurrencyAndProduct,
                pricesOfCurrentSubscriptionPricing,
                isCurrentSubscriptionActive,
                isCurrentSubscriptionBilledYearly,
                currentSubscriptionCurrency,
                currentSubscriptionPricing,
                isYearlyPeriodicitySelected,
                setYearlyPeriodicitySelected,
                selectedCurrency,
                setSelectedCurrency,
                offerUpdatePayload,
                setOfferUpdatePayload,
                errorPayload,
                setErrorPayload,
            }}
        >
            <OffersWrapper>
                <Header>
                    <StyledLogo isComplete isColored />
                    <BackButton
                        // @ts-ignore
                        onPress={() =>
                            fromCheckout
                                ? history.push('/app/user/subscription/offers')
                                : history.goBack()
                        }
                        startIcon={
                            <FontAwesomeIcon icon={icon({ name: 'xmark', style: 'solid' })} />
                        }
                    />
                </Header>
                <PricingOffers />
            </OffersWrapper>

            <PricingUpdateOfferModal
                isOpen={pricingUpdateOfferModalIsOpen}
                onOpenChange={() => {
                    setPricingUpdateOfferModalIsOpen(false);
                    setOfferUpdatePayload(null);
                }}
            />
            <PricingErrorModal
                isOpen={pricingErrorModalIsOpen}
                onOpenChange={() => {
                    setPricingErrorModalIsOpen(false);
                    setErrorPayload(null);
                }}
            />
            <Dialog
                isOpen={paymentValidationModalIsOpen}
                onOpenChange={() => {
                    setPaymentValidationModalIsOpen(false);
                    setErrorPayload(null);
                }}
                title={<FormattedMessage defaultMessage="Echec de la validation du paiement" />}
            >
                <Text>
                    <FormattedMessage defaultMessage="La vérification 3D Secure 2 de votre paiement a échoué. Veuillez contacter le support pour plus d'informations." />
                </Text>
            </Dialog>
        </PricingContext.Provider>
    );
};

const OffersWrapper = styled.div`
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;

    @media (min-width: 1024px) {
        gap: 4.5rem;
        padding-bottom: 6rem;
    }
`;
const Header = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1rem;
`;
const StyledLogo = styled(AushaLogo)`
    height: 1.5rem;
`;
const BackButton = styled(Button)`
    color: var(--black);
    background-color: var(--white);
    border: 1px solid var(--neutral500);
    border-radius: var(--r-full);
    box-shadow: none;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;

    &:hover {
        background-color: var(--neutral100);
    }
`;

export default OffersPage;
