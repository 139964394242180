import { useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { motion } from 'framer-motion';
import Stack from '@ui/layout/Stack';
import Cluster from '@ui/layout/Cluster';
import Text from '@ui/atoms/Text';
import IconEdit from '@ui/icons/IconEdit';
import HideFor from '@/components/unorganized/HideFor';
import ClipFormatMenu from './ClipFormatMenu/ClipFormatMenu';
import ClipTitleEditModal from './ClipTitleEditModal';
import { useOverlayTriggerState } from 'react-stately';
import useDroppable from '@/shared/hooks/useDroppable';
import useUpdateClipTitle from './SucceededClipFormat/useUpdateClipTitle.hook';
import IconButton from '@ui/atoms/IconButton';
import IconMoreHorizontal from '@ui/icons/IconMoreHorizontal';
import DeleteClipFormatModal from './DeleteClipFormatModal';

const EpisodeCover = styled.img.attrs({
    loading: 'lazy',
})`
    display: block;
    max-height: 1.5rem;
    max-width: 1.5rem;
    border-radius: var(--r-xs);
`;

const InnerCardWrapper = styled.div`
    position: relative;
    min-height: 152px;
    padding-block: 0.625rem;
    padding-inline: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--neutral500);
    background-color: var(--neutral50);
    border-radius: var(--r-xs);
    border: none;
    overflow: hidden;
    cursor: pointer;

    &:hover {
        color: var(--black);
        background-color: var(--neutral100);
    }
`;

const ClipFormatMenuButton = styled(IconButton)`
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    background-color: transparent;
`;

const ClipFormatEpisode = ({ episode }) => (
    <Cluster $gap="0.5rem" $align="center" $wrap="nowrap">
        {episode.imageUrl && <EpisodeCover src={episode.imageUrl} />}
        <Text color="--neutral500" numberOfLines={1}>
            {episode.name}
        </Text>
    </Cluster>
);

const PendingClipFormat = ({ clipFormat, episode, onClick }) => {
    const [isHovered, setIsHovered] = useState(false);
    let editClipTitleModal = useOverlayTriggerState({});
    let deleteClipModal = useOverlayTriggerState({});
    const [refMenu, isMenuOpened, openMenu] = useDroppable();
    const updateClipTitle = useUpdateClipTitle();

    const onOpenMenuHandler = (event) => {
        event.stopPropagation();
        openMenu();
    };

    const onOpenClipEditTitleModal = (event) => {
        event.stopPropagation();
        editClipTitleModal.open();
    };

    const onOpenDeleteModal = (event) => {
        event.stopPropagation();
        deleteClipModal.open();
    };

    const onEditClipTitle = (adminTitle) => {
        updateClipTitle.mutate(
            {
                clipId: clipFormat.clip.id,
                adminTitle,
            },
            {
                onSuccess: () => {
                    clipFormat.setAdminTitle(adminTitle);
                    editClipTitleModal.close();
                },
            },
        );
    };

    return (
        <>
            <Stack $gap="0.75rem">
                <InnerCardWrapper
                    as={motion.button}
                    onClick={() => onClick(episode.id, clipFormat)}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    <Stack $gap="0.5rem" $align="center">
                        <IconEdit size="1.5rem" color="--neutral500" />
                        <Text color="--neutral500" textAlign="center" fontWeight="--fw-semibold">
                            <FormattedMessage defaultMessage="Continuer l’édition" />
                        </Text>
                    </Stack>
                    {isHovered && (
                        <HideFor roles={['viewer']}>
                            <ClipFormatMenuButton
                                variant="ghost"
                                icon={<IconMoreHorizontal color="--neutral500" />}
                                onClick={onOpenMenuHandler}
                                background="transparent"
                                isRound
                            ></ClipFormatMenuButton>
                            <ClipFormatMenu
                                isOpen={isMenuOpened}
                                onOpenClipEditTitleModal={onOpenClipEditTitleModal}
                                onOpenDeleteModal={onOpenDeleteModal}
                                ref={refMenu}
                            />
                        </HideFor>
                    )}
                </InnerCardWrapper>
                <Stack $gap="0.25rem">
                    <Text numberOfLines={1} fontWeight="--fw-semibold">
                        {clipFormat.clip.adminTitle || clipFormat.clip.title}
                    </Text>
                    {episode && <ClipFormatEpisode episode={episode} />}
                </Stack>
            </Stack>
            {deleteClipModal.isOpen && (
                <DeleteClipFormatModal
                    clipFormat={clipFormat}
                    isOpen={deleteClipModal.isOpen}
                    onClose={deleteClipModal.close}
                />
            )}
            {editClipTitleModal.isOpen && (
                <ClipTitleEditModal
                    isOpen={editClipTitleModal.isOpen}
                    modalState={editClipTitleModal}
                    onClose={() => editClipTitleModal.close()}
                    value={clipFormat.clip.adminTitle || clipFormat.clip.title}
                    onEdit={onEditClipTitle}
                    clipFormat={clipFormat}
                    isDisabled={updateClipTitle.isLoading}
                />
            )}
        </>
    );
};

export default PendingClipFormat;
