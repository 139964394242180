import { useMutation } from '@tanstack/react-query';
import { deleteUserAvatar } from '@/api';
import camelcaseKeys from 'camelcase-keys';

const mutationFn = async () => {
    const { data } = await deleteUserAvatar();

    return camelcaseKeys(data as any, { deep: true });
};

const deleteUserAvatarMutation = () => {
    return useMutation({
        mutationFn,
        onSuccess: () => {
            // Queries invalidation is done in the saveProfileMutation
        },
    });
};

export default deleteUserAvatarMutation;
