import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchPageSettings } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import websiteSettingsKeys from './websiteSettingsKeys';
import { z } from 'zod';

export const WebsiteSettingsSchema = z.object({
    id: z.number(),
    color: z.string().optional().nullable(),
    displayTranscript: z.boolean(),
    coverPageUrl: z.string().optional().nullable(),
    coverSlUrl: z.string().optional().nullable(),
    featuredEpisodeType: z.string().optional().nullable(),
    featuredEpisodeId: z.number().optional().nullable(),
    isPublished: z.boolean(),
    multiShow: z.boolean(),
    showId: z.number(),
    facebookPixel: z.string().optional().nullable(),
    googleAnalytics: z.string().optional().nullable(),
    linkedinPixel: z.string().optional().nullable(),
    matomoAnalytics: z.string().optional().nullable(),
    recommendedShows: z.array(z.string()),
});

type InputProps = { showId: string };
type Key = QueryFunctionContext<ReturnType<typeof websiteSettingsKeys.allDetails>>;
export type WebsiteSettings = z.infer<typeof WebsiteSettingsSchema>;

const queryFn = async ({ queryKey: [{ showId }] }: Key) => {
    const { data } = await fetchPageSettings(showId);

    const formattedData = {
        ...data,
        recommendedShows: data.recommendedShows.data.map((show: any) => show.slug),
    };

    // TODO: Do camelcase transformation in Axios interceptor
    return WebsiteSettingsSchema.parse(camelcaseKeys(formattedData, { deep: true }));
};

const useWebsiteSettingsQuery = ({ showId }: InputProps) => {
    return useQuery({
        queryKey: websiteSettingsKeys.allDetails(showId),
        queryFn,
        enabled: !!showId,
    });
};

export default useWebsiteSettingsQuery;
