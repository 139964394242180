import { FormattedMessage } from 'react-intl';
import { STATUSES } from '@/models/Progress';
import Text from '@ui/atoms/Text';
import styled from 'styled-components';
import CircularProgress from '@ui/atoms/CircularProgress';

const messages = {
    [STATUSES.QUEUED]: <FormattedMessage defaultMessage="En attente…" />,
    [STATUSES.FAILED]: <FormattedMessage defaultMessage="Une erreur est survenue" />,
    [STATUSES.FINISHED]: <FormattedMessage defaultMessage="Terminée" />,
};

const textColors = {
    [STATUSES.QUEUED]: '--neutral500',
    [STATUSES.FAILED]: '--alert',
    [STATUSES.FINISHED]: '--primary',
    [STATUSES.EXECUTING]: '--black',
};
const progressBackgroundColors = {
    [STATUSES.QUEUED]: '--neutral100',
    [STATUSES.FAILED]: '--alert50',
    [STATUSES.EXECUTING]: '--primary50',
};
const progressColors = {
    [STATUSES.FAILED]: '--alert500',
    [STATUSES.EXECUTING]: '--primary',
};

function AudioFileJobsProgressTracker({ label, progress = {}, children }) {
    const isFinished = progress.status === STATUSES.FINISHED;

    return (
        <ResponsiveWrapper>
            {isFinished ? (
                <FinishedJobIconWrapper>{children}</FinishedJobIconWrapper>
            ) : (
                <CircularProgress
                    backgroundColor={progressBackgroundColors[progress.status]}
                    percentage={progress.percent || progress.percentage || 0}
                    progressColor={progressColors[progress.status]}
                    size={48}
                    strokeWidth={4}
                >
                    {children}
                </CircularProgress>
            )}
            <ProgressInfoWrapper>
                <Text
                    textAlign="center"
                    color={textColors[progress.status]}
                    fontWeight="--fw-semibold"
                >
                    {label}
                </Text>
                <Text textAlign="center" color="--neutral500">
                    {progress.status === STATUSES.EXECUTING
                        ? `${progress.percent || progress.percentage}%`
                        : messages[progress.status]}
                </Text>
            </ProgressInfoWrapper>
        </ResponsiveWrapper>
    );
}

const ResponsiveWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.75rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-direction: column;
        align-items: center;
        row-gap: 0.75rem;
    }
`;
const ProgressInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        text-align: center;
    }
`;
const FinishedJobIconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    border-radius: var(--r-full);
    background-color: var(--primary50);
`;

export default AudioFileJobsProgressTracker;
