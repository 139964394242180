import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { motion } from 'framer-motion';
import EpisodePreviewInformations from '../../unorganized/EpisodePreviewInformations';
import FetcherPodcast from '../../unorganized/FetcherPodcast';
import { connect } from '../../legacy/connect';
import FetcherStats from '../../unorganized/FetcherStats';
import EpisodePreviewListenerGraph from './EpisodePreviewListenerGraph';
import EpisodePreviewShare from '../../unorganized/EpisodePreviewShare';
import EpisodeUsefulLinks from '../../unorganized/EpisodeUsefulLinks';
import DeprecatedPaper from '../../ui/atoms/DeprecatedPaper';
import { useStatsDateSelectorContext } from '@/context/StatsDateSelectorContext';

const enhance = connect(() => ({}));

const EpisodePreview = () => {
    const { showId, podId } = useParams();
    const { startDate, endDate } = useStatsDateSelectorContext();
    const [tabValue, setTabValue] = useState('');

    const getGraphValues = {
        getTabValue: setTabValue,
        tabValue,
    };

    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, []);

    return (
        <DeprecatedPaper maxW={1200} m="auto" justify="center" py={35} px={20}>
            <FetcherPodcast id={podId}>
                <FetcherStats
                    showId={showId}
                    episodes={podId}
                    step="days"
                    start={startDate}
                    end={endDate}
                    optionsType={tabValue}
                >
                    {(isLoading) =>
                        !isLoading && (
                            <motion.div
                                initial={{ opacity: 0, y: 10 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 1, y: 0 }}
                            >
                                <EpisodePreviewInformations isLoading={isLoading} />
                                <EpisodePreviewListenerGraph
                                    isLoading={isLoading}
                                    getGraphValues={getGraphValues}
                                />
                                <EpisodeUsefulLinks
                                    isLoading={isLoading}
                                    title={<FormattedMessage defaultMessage="Liens utiles" />}
                                />
                                <EpisodePreviewShare isLoading={isLoading} />
                            </motion.div>
                        )
                    }
                </FetcherStats>
            </FetcherPodcast>
        </DeprecatedPaper>
    );
};

export default enhance(EpisodePreview);
