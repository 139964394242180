import { FC, useState } from 'react';
import { useParams } from 'react-router';
import DownloadsComparisonContent from '@app/molecules/DownloadsComparisonContent';
import DownloadsComparisonHeader from '@app/organisms/DownloadsComparisonHeader';
import useDownloadsComparisonQuery from '@queries/stats/useDownloadsComparisonQuery.hook';
import useStatsSelectedEpisodeIds from '@/utils/hooks/useStatsSelectedEpisodeIds';

const DownloadsComparison: FC = () => {
    const { showId } = useParams<{ showId: string }>();
    const selectedEpisodeIds = useStatsSelectedEpisodeIds();
    const [daysAfterPublication, setDaysAfterPublication] = useState<7 | 15 | 30 | 90>(7);

    const downloadsComparisonQuery = useDownloadsComparisonQuery({
        showId,
        daysAfterPublication,
        selectedEpisodeIds,
    });

    const hasDownloadsComparisonData =
        Array.isArray(downloadsComparisonQuery.data) && downloadsComparisonQuery.data.length > 0;

    return (
        <>
            <DownloadsComparisonHeader
                daysAfterPublication={daysAfterPublication}
                setDaysAfterPublication={setDaysAfterPublication}
                isActive={hasDownloadsComparisonData}
            />
            <DownloadsComparisonContent daysAfterPublication={daysAfterPublication} />
        </>
    );
};

export default DownloadsComparison;
