import { useParams } from 'react-router';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import DeprecatedSelect from '@ui/molecules/DeprecatedSelect';
import MenuItem from '@ui/atoms/MenuItem';
import useSeasonsQuery from '@queries/season/useSeasonsQuery.hook';
import useAddSeasonMutation from '@queries/season/useAddSeasonMutation.hook';
import { useQueryClient } from '@tanstack/react-query';
import seasonKeys from '@queries/season/seasonKeys';

const messages = defineMessages({
    season: { defaultMessage: 'Saison {season}' },
    noSeason: { defaultMessage: 'Non classé' },
});

export interface SelectSeasonsProps {
    field?: {
        value: string;
        setValue: (value: string) => void;
    };
    onChange: (value: string) => void;
    value: string;
}

const SelectSeasons = ({ field, onChange, value, ...props }: SelectSeasonsProps) => {
    const queryClient = useQueryClient();
    const intl = useIntl();
    const { showId }: { showId: string } = useParams();
    const {
        data: seasons,
        isLoading,
        isError,
        isRefetching,
        refetch,
    } = useSeasonsQuery({ showId });
    const addSeason = useAddSeasonMutation();

    const setSeasonId = field ? field.setValue : onChange;
    const seasonId = field ? field.value : value;

    const handleAddSeason = () => {
        addSeason.mutate(
            { showId },
            {
                onSuccess: async (season) => {
                    queryClient.invalidateQueries({
                        queryKey: seasonKeys.detailById(showId),
                    });
                    await refetch();
                    setSeasonId(season.id);
                },
            },
        );
    };

    if (isLoading || isError || isRefetching) {
        return null;
    }

    return (
        // @ts-ignore
        <DeprecatedSelect
            disabled={isLoading || addSeason.isLoading}
            label={<FormattedMessage defaultMessage="Saison" />}
            source={[
                { label: intl.formatMessage(messages.noSeason), value: null },
                ...seasons.map((s) => ({
                    label: intl.formatMessage(messages.season, { season: s.seasonNumber }),
                    value: s.id,
                })),
            ]}
            value={seasonId}
            onChange={setSeasonId}
            addButton={
                <>
                    <hr />
                    <MenuItem onClick={handleAddSeason}>
                        <FormattedMessage defaultMessage="+ Créer une saison" />
                    </MenuItem>
                </>
            }
            {...props}
        />
    );
};

export default SelectSeasons;
