import { FormattedMessage } from 'react-intl';
import DeprecatedPaper from '../../ui/atoms/DeprecatedPaper';
import DeprecatedButton from '../../ui/atoms/DeprecatedButton';
import DeprecatedIcon from '../../ui/atoms/DeprecatedIcon';
import barsMenu from '@public/icons/filter2.svg';
import arrow from '@public/icons/chevrondown.svg';
import DeprecatedText from '../../ui/atoms/DeprecatedText';
import VerticalDropContainer from '../../ui/atoms/VerticalDropContainer';
import ImgMenuItem from '../../ui/molecules/ImgMenuItem';
import useDroppable from '../../../utils/hooks/useDroppable';
import Desktop from '../../ui/molecules/Desktop';
import { useResponsive } from '../../../utils/hooks/useResponsive';

const currentTab = {
    7: <FormattedMessage defaultMessage="7 derniers jours" />,
    15: <FormattedMessage defaultMessage="15 derniers jours" />,
    30: <FormattedMessage defaultMessage="30 derniers jours" />,
    90: <FormattedMessage defaultMessage="90 derniers jours" />,
    always: <FormattedMessage defaultMessage="Depuis le début" />,
};

const currentTabFirstDays = {
    7: <FormattedMessage defaultMessage="7 premiers jours" />,
    15: <FormattedMessage defaultMessage="15 premiers jours" />,
    30: <FormattedMessage defaultMessage="30 premiers jours" />,
    90: <FormattedMessage defaultMessage="90 premiers jours" />,
    always: <FormattedMessage defaultMessage="Depuis le début" />,
};

const CurrentTabSelected = ({ firstDays, range }) => {
    if (firstDays) return currentTabFirstDays[range];
    return currentTab[range];
};

const DateRangeSelector = ({
    daysRange,
    setDaysRange,
    rangeDaysAvailable = [7, 15, 30, 90, 'always'],
    hideDaysRange = [],
    active = true,
    firstDays = false,
}) => {
    if (!active) return null;

    const [ref, opened, open] = useDroppable(false);
    const { isDesktop } = useResponsive();

    const SEVEN_DAYS = rangeDaysAvailable.includes(7) && !hideDaysRange.includes(7);
    const FIFTEEN_DAYS = rangeDaysAvailable.includes(15) && !hideDaysRange.includes(15);
    const THIRTY_DAYS = rangeDaysAvailable.includes(30) && !hideDaysRange.includes(30);
    const NINETY_DAYS = rangeDaysAvailable.includes(90) && !hideDaysRange.includes(90);
    const FROM_BEGINNING_DAYS =
        rangeDaysAvailable.includes('always') && !hideDaysRange.includes('always');

    return (
        <DeprecatedPaper flex justify={isDesktop && 'flex-end'} position="relative">
            <Desktop>
                <DeprecatedButton w="max-content" accent="secondary" onClick={open}>
                    <DeprecatedIcon as={barsMenu} />
                    <DeprecatedText ellipsis maxW="170px" auto>
                        <CurrentTabSelected firstDays={firstDays} range={daysRange} />
                    </DeprecatedText>
                </DeprecatedButton>
            </Desktop>
            <VerticalDropContainer
                isOpened={opened}
                x={null}
                xR={0}
                py={0}
                y={isDesktop && 39}
                rounded
                pos={null}
                w="max-content"
                ref={ref}
                currentTab={
                    <DeprecatedButton w="100%" accent="secondary" active mt={25}>
                        <DeprecatedText ellipsis maxW={170} auto>
                            <CurrentTabSelected firstDays={firstDays} range={daysRange} />
                        </DeprecatedText>
                        <DeprecatedIcon as={arrow} ml={10} mr={0} />
                    </DeprecatedButton>
                }
            >
                {SEVEN_DAYS && (
                    <ImgMenuItem
                        label={<CurrentTabSelected firstDays={firstDays} range={7} />}
                        border="--neutral50"
                        bb={1}
                        onClick={() => setDaysRange(7)}
                        active={daysRange === 7}
                        height={40}
                    />
                )}
                {FIFTEEN_DAYS && (
                    <ImgMenuItem
                        label={<CurrentTabSelected firstDays={firstDays} range={15} />}
                        border="--neutral50"
                        bb={1}
                        onClick={() => setDaysRange(15)}
                        active={daysRange === 15}
                        height={40}
                    />
                )}
                {THIRTY_DAYS && (
                    <ImgMenuItem
                        label={<CurrentTabSelected firstDays={firstDays} range={30} />}
                        border="--neutral50"
                        bb={1}
                        onClick={() => setDaysRange(30)}
                        active={daysRange === 30}
                        height={40}
                    />
                )}
                {NINETY_DAYS && (
                    <ImgMenuItem
                        label={<CurrentTabSelected firstDays={firstDays} range={90} />}
                        border="--neutral50"
                        bb={1}
                        onClick={() => setDaysRange(90)}
                        active={daysRange === 90}
                        height={40}
                    />
                )}
                {FROM_BEGINNING_DAYS && (
                    <ImgMenuItem
                        label={<CurrentTabSelected firstDays={firstDays} range="always" />}
                        border="--neutral50"
                        bb={1}
                        onClick={() => setDaysRange('always')}
                        active={daysRange === 'always'}
                        height={40}
                    />
                )}
            </VerticalDropContainer>
        </DeprecatedPaper>
    );
};

export default DateRangeSelector;
