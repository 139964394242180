import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import SourcesDevices from './SourcesDevices';
import SourcesPlatformsGraph from './SourcesPlatformsGraph';
import SourcesPlatformsTable from './SourcesPlatformsTable';
import { useAmplitudeLogEvent } from '@/shared/hooks/useAmplitude';
import SourcesOperatingSystems from './SourcesOperatingSystems';
import SourcesBrowsers from './SourcesBrowsers';
import SourcesBrowsersTable from './SourcesBrowsersTable';
import { USER_OPTIONS } from '@/shared/config/userOptions';
import useShowQuery from '@queries/show/useShowQuery.hook';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import EmptyState from '@ui/molecules/EmptyState';
import Text from '@ui/atoms/Text';
import ShiningDiamond from '@/components/ui/atoms/ShiningDiamond';
import ZebraIllustration from '@ui/atoms/ZebraIllustration';
import RouterLink from '@/components/Link/RouterLink';
import osBrowsersImage from '@public/images/limit_sources.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';

const StatsSources = () => {
    useAmplitudeLogEvent('Stats Sources Tab Viewed');
    const { showId } = useParams<{ showId: string }>();
    const { data: show } = useShowQuery(showId);
    const { data: user } = useUserQuery();

    const hasBrowsersSources = show
        ? show?.userOptions?.includes(USER_OPTIONS.SOURCES_OS_BROWSERS)
        : user
        ? user?.options?.includes(USER_OPTIONS.SOURCES_OS_BROWSERS)
        : undefined;

    return (
        <SourcesWrapper>
            <SourcesDevices />
            <SourcesPlatformsGraph />
            <SourcesPlatformsTable />

            {hasBrowsersSources ? (
                <>
                    <SourcesOperatingSystems />
                    <SourcesBrowsers />
                    <SourcesBrowsersTable />
                </>
            ) : (
                <EmptyStateOuterWrapper>
                    <Title>
                        <FormattedMessage defaultMessage="Systèmes d'exploitation et navigateurs" />
                    </Title>
                    <EmptyState illustration={osBrowsersImage} illustrationType="image">
                        <HeadIllustrationWrapper>
                            <ZebraIllustration>
                                <ShiningDiamond />
                            </ZebraIllustration>
                        </HeadIllustrationWrapper>
                        <LabelWrapper>
                            <Text variant="heading" fontWeight="--fw-bold">
                                <FormattedMessage defaultMessage="Passez à l'offre supérieure !" />
                            </Text>
                            <Text variant="body" fontWeight="--fw-normal" color="--neutral500">
                                <FormattedMessage defaultMessage="Pour en apprendre plus sur votre audience, abonnez-vous à l'offre Boost 🚀" />
                            </Text>
                        </LabelWrapper>
                        <RouterLink
                            to="/app/subscription/offers"
                            variant="button-primary"
                            startIcon={
                                <FontAwesomeIcon
                                    icon={icon({ name: 'rocket-launch', style: 'solid' })}
                                />
                            }
                        >
                            <FormattedMessage defaultMessage="Mettre à niveau mon offre" />
                        </RouterLink>
                    </EmptyState>
                </EmptyStateOuterWrapper>
            )}
        </SourcesWrapper>
    );
};

const SourcesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;
const Title = styled.h2`
    font-weight: var(--fw-bold);
    font-size: var(--fs-heading-m);
`;
const EmptyStateOuterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;
const HeadIllustrationWrapper = styled.div`
    width: 200px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    & > * {
        text-align: center;
    }
`;

export default StatsSources;
