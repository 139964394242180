import { useController } from 'react-hook-form';
import { default as RadioGroup } from '.';
import Text from '@ui/atoms/Text';

export const RadioGroupController = (props) => {
    const { control, name, onChange, onBlur, children, label, orientation, gap } = props;
    const { field } = useController({
        name,
        control,
    });
    return (
        <RadioGroup
            onChange={(value) => {
                const forwardedValue = !value ? null : value;
                onChange && onChange(forwardedValue);
                field.onChange(forwardedValue);
            }}
            onBlur={(...v) => {
                onBlur && onBlur(...v);
                field.onBlur(...v);
            }}
            name={field.name}
            label={label}
            value={field.value}
            orientation={orientation}
            gap={gap}
        >
            {children}
            {field.error && (
                <Text color="--alert" fontWeight="--fw-semibold">
                    {field.error}
                </Text>
            )}
        </RadioGroup>
    );
};
