import { useContext } from 'react';
import { ListBox, ListBoxItem, TimeFieldStateContext } from 'react-aria-components';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { CalendarDateTime } from '@internationalized/date';
import { useLocale } from 'react-aria';

interface TimeItem {
    name: string;
    id: string;
    value: CalendarDateTime;
}

const generateTimeItems = (locale: string) => {
    const times = [];
    const format = locale === 'en' ? 'hh:mm A' : 'HH:mm';
    const date = dayjs();
    const year = date.get('year');
    const month = date.get('month');
    const day = date.get('date');

    for (let hours = 0; hours < 24; hours++) {
        for (let minutes = 0; minutes < 60; minutes += 15) {
            const timeName = date.hour(hours).minute(minutes).format(format);

            const dateTime = new CalendarDateTime(year, month, day, hours, minutes);

            times.push({
                name: timeName,
                id: crypto.randomUUID(),
                value: dateTime,
            });
        }
    }

    return times;
};

const TimePickerList = () => {
    const { locale } = useLocale();
    const timeFieldState = useContext(TimeFieldStateContext);
    const timeItems = generateTimeItems(locale);

    const handleChange = (selectedItem: any) => {
        const time = timeItems.find((time) => time.id === selectedItem.currentKey);
        if (!time) return;
        timeFieldState.setValue(time.value);
    };

    return (
        <List selectionMode="single" onSelectionChange={handleChange} items={timeItems}>
            {(time: TimeItem) => <ListItem value={time.value}>{time.name}</ListItem>}
        </List>
    );
};

const List = styled(ListBox)`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 0.25rem;
    overflow-y: scroll;
    padding: 0.5rem;
`;
const ListItem = styled(ListBoxItem)`
    padding: 0.25rem 0.5rem;
    border-radius: var(--r-s);
    cursor: pointer;
    width: fit-content;

    &[data-focused] {
        outline: solid 2px var(--primary50);
    }

    &[data-hovered] {
        background-color: var(--neutral50);
    }
`;

export default TimePickerList;
