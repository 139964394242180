import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import { FormattedMessage } from 'react-intl';
import DateRangeSelector from '@/components/unorganized/DateRangeSelector';

interface DownloadsComparisonHeaderProps {
    daysAfterPublication: 7 | 15 | 30 | 90;
    setDaysAfterPublication: (value: 7 | 15 | 30 | 90) => void;
    isActive: boolean;
}

const DownloadsComparisonHeader = ({
    daysAfterPublication,
    setDaysAfterPublication,
    isActive,
}: DownloadsComparisonHeaderProps) => (
    <DeprecatedPaper mt={35} mb={20} flex justify="space-evently" align="flex-end" z={1}>
        <DeprecatedPaper>
            <DeprecatedText size="veryBig" weight="bold" mb={5}>
                <FormattedMessage defaultMessage="Comparaison de vos épisodes" />
            </DeprecatedText>
            <DeprecatedText>
                <FormattedMessage defaultMessage="Nombre d’écoutes par rapport au premier jour de publication, qui performe le mieux ?" />
            </DeprecatedText>
        </DeprecatedPaper>
        <DateRangeSelector
            daysRange={daysAfterPublication}
            setDaysRange={setDaysAfterPublication}
            hideDaysRange={['always']}
            active={isActive}
            firstDays
        />
    </DeprecatedPaper>
);

export default DownloadsComparisonHeader;
