import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import useSmartlinkSettingsFormSchema from './useSmartlinkSettingsFormSchema.hook';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@ui/atoms/Button';
import { ToggleController } from '@ui/atoms/Toggle';
import { CopyLinkButton, OpenLinkButton } from '../UsefulLinkButtons';
import Cluster from '@ui/layout/Cluster';
import Text from '@ui/atoms/Text';
import Stack from '@ui/layout/Stack';
import Link from '@ui/atoms/Link';
import { blendAlphaChannel } from '@/shared/utils/color';
import { useParams } from 'react-router-dom';
import { ColorPickerController } from '@ui/molecules/ColorPicker';
import IconLoader from '@ui/icons/IconLoader';
import useFetchDominantColor from './useFetchDominantColor.hook';
import IconCheckedCircle from '@ui/icons/IconCheckedCircle';
import ReorderableLinks from './ReorderableLinks';
import CustomBackground from '../CustomBackground';
import ReordableCallToActionButtons from './ReordableCallToActionButtons';
import { resolveColor } from '@/shared/utils/cssVariable';
import SparklesIcon from '@public/icons/sparkles-nebula.svg';
import UnsavedFormModal from '../UnsavedFormModal';
import useUpdateSmartlinkSettings from '@queries/smartlinkSetting/useUpdateSmartlinkSettings.hook';
import { useModalToastQueue } from '@/shared/hooks/useModalToastQueue.hook';
import { LINKS_NAMES } from '@/components/CallToAction';
import { sendAmplitudeLogEvent } from '@/shared/utils/amplitude';
import RouterLink from '@/components/Link/RouterLink';
import InputText from '@/components/ui/atoms/InputText';

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;

    ${(p) => p.theme.mediaQueries.tabletAndUp} {
        row-gap: 2.5rem;
    }
`;

const SubmitButton = styled(Button)`
    margin-top: 1rem;
    align-self: center;
`;

const SectionWrapper = styled.section`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`;
const SectionTitle = styled(Text)`
    width: 100%;
    padding-bottom: 0.25rem;
    border-bottom: solid 1px var(--neutral100);
    font-weight: var(--fw-bold);
`;
const GenerateColorsWrapper = styled.div`
    position: relative;
    background: var(--gradient-aurora);
    border-radius: 12px;
    padding: 1rem;
`;
const Sparkles = styled(SparklesIcon)`
    margin-top: 2px;
    width: 1rem;
    height: 1rem;
`;
const GenerateColorsButton = styled(Button)`
    width: 10.25rem;
    ${(p) => p.theme.mediaQueries.mobile} {
        width: 100%;
    }
`;
const ColorsGenerated = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.25rem;
    font-weight: bold;
    color: var(--primary);

    & span {
        display: inline-block;
    }
`;

const SmartlinkSettingsForm = ({ showSlug, defaultSettings, IsCTAFeatureEnabled }) => {
    const { showId } = useParams();
    const [colorsGenerated, setColorsGenerated] = useState(false);
    const toast = useModalToastQueue();
    const updateSettings = useUpdateSmartlinkSettings({
        showId,
        defaultSettings,
        ctaLocation: LINKS_NAMES.SMARTLINK,
        IsCTAFeatureEnabled,
    });
    const schema = useSmartlinkSettingsFormSchema();
    const { control, handleSubmit, formState, clearErrors, watch, setValue, reset } = useForm({
        mode: 'onChange',
        values: defaultSettings || {
            isPublished: true,
            accentColor: resolveColor('--primary'),
            backgroundColor: blendAlphaChannel(resolveColor('--primary'), 10),
            coverUrl: null,
            isPlayerEnabled: true,
            listeningLinks: [],
            ctaButtons: [],
        },
        resolver: yupResolver(schema),
    });
    const dominantColor = useFetchDominantColor(showId);
    const copyLink = process.env.URL_SMARTLINKS_AUSHA + showSlug;
    const links = watch('listeningLinks');
    const buttons = watch('ctaButtons');
    const isLoading = updateSettings.isLoading;

    const onSubmit = (formData) => {
        updateSettings.mutate(formData, {
            onError: () => toast.alert(),
            onSuccess: () => {
                toast.success();
                reset(formData);
            },
        });
    };

    useEffect(() => {
        if (!dominantColor.data || !colorsGenerated) return;
        sendAmplitudeLogEvent('Smartlinks Colors Generated');
        setValue('accentColor', dominantColor.data, { shouldDirty: true, shouldValidate: true });
        setValue('backgroundColor', blendAlphaChannel(dominantColor.data, 10), {
            shouldDirty: true,
            shouldValidate: true,
        });
    }, [dominantColor.data, colorsGenerated]);

    return (
        <>
            <UnsavedFormModal shouldBlockNavigation={formState.isDirty} />
            <Form
                onSubmit={handleSubmit((formData) => {
                    onSubmit(formData);
                })}
            >
                <SectionWrapper>
                    <ToggleController
                        onChange={(isChecked) => {
                            if (!isChecked) return;
                            sendAmplitudeLogEvent('Smartlink Activated');
                        }}
                        required
                        control={control}
                        name="isPublished"
                    >
                        <FormattedMessage defaultMessage="Activer le Smartlink" />
                    </ToggleController>
                    <Stack $gap="1rem">
                        <InputText
                            value={showSlug}
                            isReadOnly
                            prefix={process.env.URL_SMARTLINKS_AUSHA}
                            label={<FormattedMessage defaultMessage="Lien de votre Smartlink" />}
                            description={
                                <FormattedMessage
                                    defaultMessage="Ce lien peut-être modifié dans les {settings}"
                                    values={{
                                        settings: (
                                            <RouterLink
                                                to={`/app/show/${showId}/settings/info`}
                                                color="--primary"
                                            >
                                                <FormattedMessage defaultMessage="paramètres de votre émission" />
                                            </RouterLink>
                                        ),
                                    }}
                                />
                            }
                        />
                        <Cluster $justify="flex-end" $gap="0.5rem">
                            <CopyLinkButton link={copyLink} />
                            <OpenLinkButton link={copyLink} />
                        </Cluster>
                    </Stack>
                </SectionWrapper>

                <SectionWrapper>
                    <SectionTitle variant="heading">
                        <FormattedMessage defaultMessage="Apparence" />
                    </SectionTitle>
                    <GenerateColorsWrapper>
                        <Cluster $wrap="nowrap" $gap="0.75rem">
                            <Sparkles />
                            <Stack $gap="1rem">
                                <div>
                                    <Text fontWeight="--fw-semibold">
                                        <FormattedMessage defaultMessage="Trouvez les couleurs idéales pour votre Smartlink." />
                                    </Text>
                                    <Text color="--neutral500">
                                        <FormattedMessage defaultMessage="Grâce à votre pochette d’émission, l’IA générera les couleurs prédominantes de votre podcast." />
                                    </Text>
                                </div>

                                <Cluster $gap="1rem" $align="center">
                                    <GenerateColorsButton
                                        size="small"
                                        background="--gradient-nebula"
                                        color="--white"
                                        startIcon={dominantColor.isFetching && <IconLoader />}
                                        onPress={async () => {
                                            await dominantColor.refetch();
                                            !dominantColor.isFetching && setColorsGenerated(true);
                                        }}
                                        isDisabled={dominantColor.isFetching || colorsGenerated}
                                    >
                                        {!dominantColor.isFetching && (
                                            <FormattedMessage defaultMessage="Générer les couleurs" />
                                        )}
                                    </GenerateColorsButton>
                                    {colorsGenerated && (
                                        <ColorsGenerated>
                                            <IconCheckedCircle />
                                            <span>
                                                <FormattedMessage defaultMessage="Couleurs générées !" />
                                            </span>
                                        </ColorsGenerated>
                                    )}
                                </Cluster>
                            </Stack>
                        </Cluster>
                    </GenerateColorsWrapper>
                    <ColorPickerController
                        control={control}
                        name="accentColor"
                        label={<FormattedMessage defaultMessage="Couleur d'accentuation" />}
                        description={
                            <FormattedMessage defaultMessage="La couleur d’accentuation s’applique sur le lecteur audio et les liens de votre Smartlink" />
                        }
                        onBlur={() => {
                            setColorsGenerated(false);
                            clearErrors('accentColor');
                        }}
                    />
                    <ColorPickerController
                        control={control}
                        name="backgroundColor"
                        label={<FormattedMessage defaultMessage="Couleur d'arrière-plan" />}
                        description={
                            <FormattedMessage defaultMessage="La couleur d’arrière-plan s’applique derrière le contenu de votre Smartlink" />
                        }
                        onBlur={() => {
                            setColorsGenerated(false);
                            clearErrors('color');
                        }}
                    />
                    <CustomBackground
                        control={control}
                        name="coverUrl"
                        formatPage="cover_smartlink"
                        type={<FormattedMessage defaultMessage="Smartlink" />}
                        coverSize="1920*1080px"
                    />
                </SectionWrapper>

                <SectionWrapper>
                    <SectionTitle variant="heading">
                        <FormattedMessage defaultMessage="Contenu" />
                    </SectionTitle>
                    <Stack $gap="0.5rem">
                        <Stack>
                            <Text fontWeight="--fw-semibold">
                                <FormattedMessage defaultMessage="Plateformes d'écoute" />
                            </Text>
                            <Text color="--neutral500">
                                <FormattedMessage defaultMessage="Gérez l’affichage et l’ordre des plateformes d’écoute proposées" />
                            </Text>
                        </Stack>
                        <ReorderableLinks
                            show={showId}
                            listeningLinks={links}
                            reordered={(data) => {
                                setValue('listeningLinks', data, {
                                    shouldDirty: true,
                                    shouldValidate: true,
                                });
                            }}
                        />
                    </Stack>
                    <Stack $gap="0.5rem">
                        <Stack>
                            <Text fontWeight="--fw-semibold">
                                <FormattedMessage defaultMessage="Boutons personnalisés" />
                            </Text>
                            <Text color="--neutral500">
                                <FormattedMessage
                                    defaultMessage="Ces boutons seront affichés sur l’en-tête de votre Smartlink et peuvent être gérés via l’onglet {link} du menu Configuration"
                                    values={{
                                        link: (
                                            <Text
                                                as={Link}
                                                to={`/app/show/${showId}/communication/settings/call-to-action`}
                                                fontWeight="--fw-semibold"
                                                color="--primary"
                                            >
                                                Call-to-Action
                                            </Text>
                                        ),
                                    }}
                                />
                            </Text>
                        </Stack>
                        <ReordableCallToActionButtons
                            callToActionButtons={buttons}
                            location={LINKS_NAMES.SMARTLINK}
                            reordered={(data) => {
                                setValue('ctaButtons', data, {
                                    shouldDirty: true,
                                    shouldValidate: true,
                                });
                            }}
                        />
                    </Stack>
                    <Stack>
                        <Text fontWeight="--fw-semibold">
                            <FormattedMessage defaultMessage="Réseaux sociaux" />
                        </Text>
                        <Text color="--neutral500">
                            <FormattedMessage
                                defaultMessage="Vous pouvez ajouter et gérer la liste de vos réseaux sociaux depuis l’onglet {link} du menu Configuration"
                                values={{
                                    link: (
                                        <Text
                                            as={Link}
                                            to="settings/external-links"
                                            fontWeight="--fw-semibold"
                                            color="--primary"
                                        >
                                            <FormattedMessage defaultMessage="Liens externes" />
                                        </Text>
                                    ),
                                }}
                            />
                        </Text>
                    </Stack>
                </SectionWrapper>
                <SectionWrapper>
                    <SectionTitle variant="heading">
                        <FormattedMessage defaultMessage="Paramètres avancés" />
                    </SectionTitle>
                    <Stack $gap="0.5rem">
                        <Stack>
                            <Text fontWeight="--fw-semibold">Smartplayer</Text>
                            <Text variant="footnote" color="--neutral500">
                                <FormattedMessage defaultMessage="Choisissez si vous souhaitez afficher ou masquer le Smartplayer (lecteur audio intégré) sur les SmartLinks de vos épisodes" />
                            </Text>
                        </Stack>
                        <ToggleController required control={control} name="isPlayerEnabled">
                            <FormattedMessage defaultMessage="Afficher le Smartplayer" />
                        </ToggleController>
                    </Stack>
                </SectionWrapper>
                <SubmitButton type="submit" isDisabled={!formState.isDirty} isLoading={isLoading}>
                    <FormattedMessage defaultMessage="Enregistrer" />
                </SubmitButton>
            </Form>
        </>
    );
};

export default SmartlinkSettingsForm;
