import { FormattedMessage } from 'react-intl';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import RouterLink from '@/components/Link/RouterLink';
import styled from 'styled-components';

const TopbarPastDueWarningBand = () => {
    return (
        <TopbarPastDueWarningBandWrapper>
            <DeprecatedText color="white">
                <FormattedMessage defaultMessage="Vous avez actuellement une facture impayée." />
            </DeprecatedText>
            <BoldRouterLink to={`/app/user/subscription`}>
                <FormattedMessage defaultMessage="Consulter mes factures" />
            </BoldRouterLink>
        </TopbarPastDueWarningBandWrapper>
    );
};

const TopbarPastDueWarningBandWrapper = styled.div`
    height: 2rem;
    background: var(--info);
    display: flex;
    align-items: center;
    justify-content: center;
`;
const BoldRouterLink = styled(RouterLink)`
    font-weight: semibold;
`;

export default TopbarPastDueWarningBand;
