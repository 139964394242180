import { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { useDrop } from 'react-aria';
import Post from './Post';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { UI_VARIANTS } from '@/shared/config/constants';
import DialogAlert from '@ui/atoms/DialogAlert';
import Button from '@ui/atoms/Button';
import Text from '@ui/atoms/Text';
import useUpdateSocialNetworkCalendarPostDateMutation from '@/queries/socialNetworkPost/useUpdateSocialNetworkCalendarPostDateMutation.hook';
import { sendAmplitudeLogEvent } from '@/helpers';
import styled, { css } from 'styled-components';
dayjs.extend(isSameOrAfter);

const Posts = ({
    date,
    posts,
    showSeeMoreAt,
    setShowSeeMoreAt,
    postDetailsShown,
    setPostDetailsShown,
    isLastRow,
    startDate,
    endDate,
}) => {
    const { showId } = useParams();
    const [showTodayButTooLateWarningModal, setShowTodayButTooLateWarningModal] = useState(false);
    const updatePostDate = useUpdateSocialNetworkCalendarPostDateMutation({
        showId,
        startDate,
        endDate,
    });

    let ref = useRef(null);
    const isInThePast = (targetDate) => {
        return dayjs(targetDate).isBefore(dayjs());
    };
    const isTodayButHourIsPassed = (targetDate) => {
        const today = dayjs();
        const timeCurrent = dayjs().year(today.year()).month(today.month()).date(today.date());
        const timeTarget = dayjs(targetDate)
            .year(today.year())
            .month(today.month())
            .date(today.date());

        return (
            dayjs().isSame(dayjs(date), 'day') &&
            dayjs(timeTarget, 'HH:mm:ss').isBefore(dayjs(timeCurrent, 'HH:mm:ss'))
        );
    };
    let { dropProps, isDropTarget } = useDrop({
        ref,
        async onDrop(e) {
            let rawDroppedItem = await Promise.all(
                e.items
                    .filter((item) => item.kind === 'text' && item.types.has('plain/text'))
                    .map(async (item) => await item.getText('plain/text')),
            );
            const dropped = JSON.parse(rawDroppedItem[0]);

            if (isTodayButHourIsPassed(dropped.publishAt)) {
                setShowTodayButTooLateWarningModal(true);
                return false;
            }
            if (isInThePast(dropped.publishAt)) {
                return false;
            }

            const currentHour = dayjs(dropped.publishAt).add(1, 'hour').get('hour');
            const currentMinute = dayjs(dropped.publishAt).get('minute');
            const currentSecond = dayjs(dropped.publishAt).get('second');

            updatePostDate.mutate(
                {
                    source: dropped,
                    postId: dropped.id,
                    postPayload: {
                        type: dropped.type,
                        publish_at: dayjs(date)
                            .set('hour', currentHour)
                            .set('minute', currentMinute)
                            .set('second', currentSecond)
                            .format('YYYY-MM-DDTHH:mm:ss'),
                        state: 'publish_at',
                    },
                },
                {
                    onSuccess: () => {
                        sendAmplitudeLogEvent('Social Network Post Drag and Drop');
                    },
                    onError: (error) => {
                        throw error;
                    },
                    onSettled: () => {
                        setShowSeeMoreAt(null);
                    },
                },
            );
        },
    });

    const dayIsToday = dayjs(date).isSame(dayjs(), 'day');

    if (showSeeMoreAt) {
        return (
            <FullPostList isLastRow={isLastRow}>
                <FullPostListHeader>
                    <CloseButton onClick={() => setShowSeeMoreAt(null)}>
                        <CloseIcon icon={icon({ name: 'xmark', style: 'solid' })} size="lg" />
                    </CloseButton>
                    {dayIsToday && <DayIsToday />}
                    <CurrentDay isToday={dayIsToday}>{dayjs(date).format('D')}</CurrentDay>
                </FullPostListHeader>
                <FullPostListInner>
                    {posts.map((post) => (
                        <Post
                            key={post.id}
                            post={post}
                            postDetailsShown={postDetailsShown === post.id}
                            setPostDetailsShown={(id) => {
                                setPostDetailsShown(id);
                            }}
                            isLastRow={isLastRow}
                        />
                    ))}
                </FullPostListInner>
            </FullPostList>
        );
    }

    return (
        <>
            <PostsWrapper {...dropProps} role="button" tabIndex={0} ref={ref}>
                {isDropTarget && !isInThePast(date) ? (
                    <DropTarget />
                ) : (
                    <PostList>
                        {posts.length > 2 ? (
                            <>
                                <Post
                                    post={posts[0]}
                                    postDetailsShown={postDetailsShown === posts[0].id}
                                    setPostDetailsShown={(id) => {
                                        setPostDetailsShown(id);
                                    }}
                                    isLastRow={isLastRow}
                                />
                                <SeeMorePosts onClick={() => setShowSeeMoreAt(dayjs(date))}>
                                    <FormattedMessage
                                        defaultMessage="+{nb} autres"
                                        values={{
                                            nb: posts.length - 1,
                                        }}
                                    />
                                </SeeMorePosts>
                            </>
                        ) : (
                            posts.map((post) => (
                                <Post
                                    key={post.id}
                                    post={post}
                                    postDetailsShown={postDetailsShown === post.id}
                                    setPostDetailsShown={(id) => {
                                        setPostDetailsShown(id);
                                    }}
                                    isLastRow={isLastRow}
                                />
                            ))
                        )}
                    </PostList>
                )}
            </PostsWrapper>
            {showTodayButTooLateWarningModal && (
                <DialogAlert
                    isOpen={showTodayButTooLateWarningModal}
                    onOpenChange={() => setShowTodayButTooLateWarningModal(false)}
                    action={
                        <>
                            <Button onPress={() => setShowTodayButTooLateWarningModal(false)}>
                                <FormattedMessage defaultMessage="J'ai compris" />
                            </Button>
                        </>
                    }
                    variant={UI_VARIANTS.NEUTRAL}
                    title={
                        <FormattedMessage defaultMessage="Vous ne pouvez pas planifier une publication dans le passé" />
                    }
                >
                    <Text variant="bodyM" color="--neutral500" textAlign="center">
                        <FormattedMessage defaultMessage="L'heure de publication sélectionnée est déjà passée pour le jour choisi, choisissez une heure ultérieure et réessayez." />
                    </Text>
                </DialogAlert>
            )}
        </>
    );
};

const PostsWrapper = styled.div`
    position: relative;
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    list-style-type: none;
    padding: 0;
    margin: 0;
    color: var(--white);
    padding-inline: 0.5rem;
`;
const PostList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;
const DropTarget = styled.div`
    width: 100%;
    height: 2rem;
    border-radius: var(--r-s);
    border: 2px dashed var(--primary);
    background-color: var(--primary50);
`;
const SeeMorePosts = styled.button`
    border: none;
    width: 100%;
    height: 2rem;
    border-radius: var(--r-s);
    display: flex;
    align-items: center;
    background-color: var(--neutral100);
    color: var(--black);
    font-size: var(--fs-body-s);
    font-weight: var(--fw-semibold);
    padding-inline: 0.5rem;
    cursor: pointer;

    &:hover {
        opacity: 0.85;
    }
`;
const FullPostList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    position: absolute;
    left: -0.25rem;
    width: calc(100% + 0.5rem);
    background-color: var(--white);
    border-radius: var(--r-m);
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.05);
    padding-inline: 0.75rem;
    padding-block: 0.75rem;
    z-index: 10;

    ${({ isLastRow }) =>
        isLastRow
            ? css`
                  bottom: -0.25rem;
              `
            : css`
                  top: -0.25rem;
              `}
`;
const FullPostListHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
`;
const CloseButton = styled.button`
    border: none;
    background-color: transparent;
    cursor: pointer;
    border-radius: var(--r-s);
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: var(--neutral50);
    }
`;
const CloseIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
    font-size: 1rem;
`;
const DayIsToday = styled.div`
    flex-grow: 1;
    height: 0.25rem;
    background-color: var(--primary50);
    border-radius: var(--r-full);
`;
const CurrentDay = styled.div`
    font-size: var(--fs-body-l);
    font-weight: var(--fw-semibold);
    color: ${({ isToday }) => (isToday ? 'var(--primary)' : 'var(--black)')};
`;
const FullPostListInner = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    max-height: 16rem;
    overflow-y: auto;

    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;

    & > * {
        flex-shrink: 0;
        flex-grow: 0;
    }
`;

Posts.propTypes = {
    date: PropTypes.string,
    posts: PropTypes.arrayOf(PropTypes.object),
    showSeeMoreAt: PropTypes.string,
    setShowSeeMoreAt: PropTypes.func,
    postDetailsShown: PropTypes.string,
    setPostDetailsShown: PropTypes.func,
    isLastRow: PropTypes.bool,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
};

export default Posts;
