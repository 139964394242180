import ReviewSummary from '../ReviewSummary';
import ReviewSummaryNoApplePodcasts from '../ReviewSummaryNoApplePodcasts';
import styled from 'styled-components';

const Overview = () => {
    const average = 3.7;
    const reviews = {
        [5]: 10,
        [4]: 5,
        [3]: 3,
        [2]: 1,
        [1]: 0,
    };

    return (
        <ReviewSummaryWrapper>
            <ReviewSummary
                average={average}
                reviews={reviews}
                hasSeeAllReviewsButton={true}
                handleSeeAllReviews={() => {}}
            />
            <ReviewSummary
                average={0}
                reviews={[]}
                hasSeeAllReviewsButton={true}
                handleSeeAllReviews={() => {}}
            />
            <ReviewSummary
                average={0}
                reviews={[]}
                hasSeeAllReviewsButton={true}
                handleSeeAllReviews={() => {}}
                slottedEmptyState={<ReviewSummaryNoApplePodcasts />}
            />
        </ReviewSummaryWrapper>
    );
};

const ReviewSummaryWrapper = styled.section`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: var(--white);
    padding: 2rem;
    border-radius: var(--r-l);

    @media screen and (max-width: 64rem) {
        padding: 1rem;
    }
`;

export default Overview;
