import { useState } from 'react';
import { useParams } from 'react-router-dom';
import DateRangePicker from '@ui/atoms/DateRangePicker';
import { parseDate } from '@internationalized/date';
import { useStatsDateSelectorContext } from '@/context/StatsDateSelectorContext';
import dayjs from 'dayjs';
import { today, getLocalTimeZone } from '@internationalized/date';
import useUserQuery from '@queries/user/useUserQuery.hook';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import EpisodeAndCampaignFilter from './EpisodeAndCampaignFilter';
import styled from 'styled-components';

interface FiltersProps {
    episodeAndCampaignDisabled?: boolean;
    dateRangePickerDisabled?: boolean;
}

const Filters = ({ episodeAndCampaignDisabled, dateRangePickerDisabled }: FiltersProps) => {
    const { showId } = useParams<{ showId: string }>();
    const { startDate, setStartDate, endDate, setEndDate } = useStatsDateSelectorContext();
    const [hasError, setHasError] = useState(false);

    const { data: show } = useShowQuery(showId);
    const { data: user } = useUserQuery();
    const timezone = user?.timezone || getLocalTimeZone();
    const now = today(timezone);

    const onDatesChange = (startDate: string, endDate: string) => {
        const isLaterThanToday = parseDate(endDate).compare(now) > 0;
        if (isLaterThanToday) {
            setHasError(true);
        } else setHasError(false);

        setStartDate(startDate);
        setEndDate(endDate);
    };
    const dayJsShowStartDate = dayjs(show?.createdAt || '');
    const oneMonthAgo = dayjs().subtract(1, 'month');
    const parsedStartDate = startDate
        ? parseDate(startDate)
        : dayJsShowStartDate.isAfter(oneMonthAgo)
        ? parseDate(dayjs(dayJsShowStartDate).format('YYYY-MM-DD'))
        : parseDate(dayjs(oneMonthAgo).format('YYYY-MM-DD'));

    const showCreatedAt = parseDate(dayjs(show?.createdAt || '').format('YYYY-MM-DD'));

    return (
        <FiltersWrapper>
            {!episodeAndCampaignDisabled && <EpisodeAndCampaignFilter />}
            {!dateRangePickerDisabled && (
                <DateRangePicker
                    value={{
                        start: parsedStartDate,
                        end: parseDate(endDate || ''),
                    }}
                    onChange={(dates) => onDatesChange(dates.start, dates.end)}
                    startDate={showCreatedAt}
                    hideCustomTimezone
                    maxValue={now}
                    isInvalid={hasError}
                />
            )}
        </FiltersWrapper>
    );
};

const FiltersWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;

    @media (max-width: 550px) {
        width: 100%;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 1rem;

        & > * {
            width: 100%;
        }
    }
`;

export default Filters;
