import axios from 'axios';
import { buildQuery } from '../query';

let instance = null;
const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json, text/plain',
};

const instanceApi = async ({ query, customQuery }) => {
    const axiosInstance = getAxiosInstance();
    const { uri, method } = {
        uri: customQuery || query,
        method: 'GET',
    };
    const url = uri + buildQuery(query);
    const res = await axiosInstance.request({
        url,
        method,
        transformRequest: [
            (data, headers) => {
                delete headers.common.Authorization;
                return data;
            },
        ],
    });
    return res.data;
};

export const createPublicApi = () => ({
    getPlayerIframe: async (query) => instanceApi({ query, customQuery: '/iframe' }),
    getShowBySlug: async (query) => instanceApi({ query: `/v1/shows/slug/${query}` }),
});

function getAxiosInstance() {
    if (!instance) {
        instance = axios.create({ baseURL: process.env.API_PUBLIC, headers });
    }

    return instance;
}
