import Model from './Model';

class YoutubeQuotaModel extends Model {
    static attributes = {
        total: 'total',
        minimum: 'minimum',
        maximum: 'maximum',
        step: 'step',
    };
}

export default YoutubeQuotaModel;
