import { PRICING } from '@/utils/pricing';
import { ENABLE_PSO } from '@/utils/constants';
import { STATUS_SHOWS } from '@/utils/statusShows';
import useSubscriptionQuery from '@/queries/subscription/useSubscriptionQuery.hook';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import usePsoQuery from '@queries/pso/usePsoQuery.hook';
import dayjs from 'dayjs';
import type { AxiosError } from 'axios';

interface HasAccessToPsoProps {
    showId: string;
}

/**
 * This hook checks if the user has access to the PSO feature.
 * Now it's a pretty complex check, there's a lot of conditions to check.
 * But in a nutshell, the user has access to PSO if:
 * - The PSO feature is enabled (env)
 * - The authenticated user is the owner of the current show and he has a subscription that allows him to use PSO
 * - The authenticated user is not the owner of the current show and the ower of the show is allow to use PSO
 */
const useHasAccessToPso = ({ showId }: HasAccessToPsoProps): boolean => {
    const subscription = useSubscriptionQuery();
    const show = useShowQuery(showId);
    const user = useUserQuery();
    const psoConfiguration = usePsoQuery({ showId, enabled: ENABLE_PSO });

    if (
        !ENABLE_PSO ||
        psoConfiguration.isLoading ||
        show.isLoading ||
        show.isError ||
        subscription.isLoading ||
        subscription.isError ||
        (psoConfiguration.isError &&
            (psoConfiguration.error as AxiosError)?.response?.status !== 403 &&
            (psoConfiguration.error as AxiosError)?.response?.data?.message !==
                'Missing user option pso')
    ) {
        return false;
    }

    const isGuestShow = show.data && show.data.showStatus === STATUS_SHOWS.GUEST_SHOWS;
    const isFreemium = subscription.data.isFreemium;
    const isLaunch = subscription.data.pricing === PRICING.LAUNCH;
    const hasNoSubscription = subscription.data.endsAt && dayjs().isAfter(subscription.data.endsAt);
    const isEnterpriseWithoutPsoOption =
        subscription.data.pricing === PRICING.ENTERPRISE && !user?.data?.options.includes('pso');
    const unsufficientSubscription =
        isFreemium || isLaunch || hasNoSubscription || isEnterpriseWithoutPsoOption;

    const ownerIsLaunch = show.data.ownerPricing === PRICING.LAUNCH;
    const ownerIsEnterpriseWithoutPsoOption =
        show.data.ownerPricing === PRICING.ENTERPRISE && !show.data.userOptions.includes('pso');
    const ownerHasNoPsoOption = ownerIsLaunch || ownerIsEnterpriseWithoutPsoOption;

    return !((!isGuestShow && unsufficientSubscription) || (isGuestShow && ownerHasNoPsoOption));
};

export default useHasAccessToPso;
