import { useMutation } from '@tanstack/react-query';
import { addPlaylist } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import { PlaylistFormSchema } from '@/components/Playlists/Playlist/PlaylistForm/usePlaylistFormSchema.hook';
import { decamelizeKeys } from 'humps';
import { RawPlaylistEpisode } from './usePlaylistQuery.hook';
import { Episode } from '../episode/useEpisodeQuery.hook';

interface MutationFnProps {
    showId: string | number;
    playlist: PlaylistFormSchema;
}

type MutationResponse = Episode &
    {
        podcasts: {
            data: RawPlaylistEpisode[];
        };
    }[];

const mutationFn = async ({ showId, playlist }: MutationFnProps): Promise<MutationResponse> => {
    const { data } = await addPlaylist(showId, decamelizeKeys(playlist));

    return camelcaseKeys(data as any, { deep: true });
};

const addPlaylistMutation = () => {
    return useMutation({
        mutationFn,
    });
};

export default addPlaylistMutation;
