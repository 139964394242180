import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteShowLogo } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import { Show } from './useShowQuery.hook';
import showKeys from './showKeys';

export interface DeleteShowLogoMutationFnProps {
    showId: string;
    format: 'logo_dark' | 'logo_light' | 'favicon';
}

const mutationFn = async ({ showId, format }: DeleteShowLogoMutationFnProps): Promise<Show> => {
    const { data } = await deleteShowLogo(showId, format);

    return camelcaseKeys(data as any, { deep: true });
};

const deleteShowLogoMutation = () => {
    const queryclient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: (_, { showId }) => {
            queryclient.invalidateQueries({
                queryKey: showKeys.detailById(showId),
            });
        },
    });
};

export default deleteShowLogoMutation;
