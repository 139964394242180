import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { PRICING_NAME } from '@/shared/config/pricing';
import RouterLink from '@/components/Link/RouterLink';

const pricingVariantMatch = {
    supersonic: 'primary',
    boost: 'dusk',
    launch: 'dawn',
} as const;
export interface UpgradeBadgeProps {
    pricing?: 'supersonic' | 'boost' | 'launch';
    children?: React.ReactNode;
    variant?: 'dusk' | 'dawn' | 'primary' | 'nebula';
    className?: string;
}

const UpgradeBadge = ({ pricing, children, variant, className }: UpgradeBadgeProps) => {
    if (!pricing) {
        return (
            <Badge className={className} $variant={variant}>
                <DiamondIcon
                    icon={icon({
                        name: 'gem',
                        style: 'duotone',
                    })}
                />
                {children}
            </Badge>
        );
    }

    return (
        <BadgeRouterLink
            to="/app/user/subscription/offers"
            target="_blank"
            $variant={pricingVariantMatch[pricing]}
            startIcon={
                <DiamondIcon
                    icon={icon({
                        name: 'gem',
                        style: 'duotone',
                    })}
                />
            }
        >
            {PRICING_NAME[pricing]}
        </BadgeRouterLink>
    );
};

const variantStyleMatch = {
    dusk: css`
        background: var(--gradient-dusk);
        color: var(--primary);

        &::after {
            background-image: linear-gradient(
                -45deg,
                transparent 25%,
                rgba(255, 255, 255, 0.25),
                transparent 75%
            );
        }
    `,
    dawn: css`
        background: var(--gradient-dawn);
        color: var(--primary);

        &::after {
            background-image: linear-gradient(
                -45deg,
                transparent 25%,
                rgba(255, 255, 255, 0.25),
                transparent 75%
            );
        }
    `,
    primary: css`
        background: var(--gradient-primary);
        color: var(--white);

        &::after {
            background-image: linear-gradient(
                -45deg,
                transparent 25%,
                rgba(255, 255, 255, 0.5),
                transparent 75%
            );
        }
    `,
    nebula: css`
        background: var(--gradient-nebula);
        color: var(--white);

        &::after {
            background-image: linear-gradient(
                -45deg,
                transparent 25%,
                rgba(255, 255, 255, 0.5),
                transparent 75%
            );
        }
    `,
};

const badgeStyles = css<{ $variant: UpgradeBadgeProps['variant'] }>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    column-gap: 0.25rem;
    padding: 0.375rem;
    border-radius: var(--r-xs);
    width: fit-content;
    overflow: hidden;
    position: relative;
    transition: 0.25s;
    text-decoration: none;
    font-weight: var(--fw-bold);
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-repeat: no-repeat;
        transform: translateX(-100%);
        transition: 0s;
    }

    &:hover::after {
        transform: translateX(100%);
        transition: 1s ease;
    }

    ${({ $variant }) => $variant && variantStyleMatch[$variant]}
`;
const Badge = styled.div<{ $variant: UpgradeBadgeProps['variant'] }>`
    ${badgeStyles}
`;
const BadgeRouterLink = styled(RouterLink)<{ $variant: UpgradeBadgeProps['variant'] }>`
    ${badgeStyles}
`;

const DiamondIcon = styled(FontAwesomeIcon)`
    color: inherit;
    font-size: 0.5rem;
`;

export default UpgradeBadge;
