import { useMemo } from 'react';
import VirtualList from 'react-tiny-virtual-list';
import { FormattedMessage } from 'react-intl';
import { withSize } from 'react-sizeme';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import RankingVariationGraphTableColumn, {
    RankingVariationGraphTableColumnFallback,
} from './RankingVariationGraphTableColumn';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import DeprecatedWhitePaper from '@ui/atoms/DeprecatedWhitePaper';
import { useApplePodcastsStatsContext } from '@/context/ApplePodcastsStatsContext';
import { useStatsDateSelectorContext } from '@/context/StatsDateSelectorContext';
import { useParams } from 'react-router';
import dayjs from 'dayjs';
import useAppleRankingQuery from '@/queries/stats/useAppleRankingQuery.hook';
import { getLocalTimeZone } from '@internationalized/date';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import { APPLE_PODCASTS } from '@/shared/config/constants';

function RankingVariationGraphTable({ hoveredValue, scrollToValue, onHover, size }) {
    const { showId } = useParams();
    const { category, precision } = useApplePodcastsStatsContext();
    const { startDate, endDate } = useStatsDateSelectorContext();
    const { data: user } = useUserQuery();

    const { data: show } = useShowQuery(showId);
    const hasApplePodcastsListeningLink = show?.listeningLinks?.data?.some(
        (link) => link.key === APPLE_PODCASTS,
    );

    const timezone = useMemo(() => user?.timezone || getLocalTimeZone(), [user]);

    const { data: stats, isLoading } = useAppleRankingQuery({
        showId,
        categoryId: category,
        start: startDate,
        end: endDate,
        timezone,
        precision,
        options: {
            enabled: hasApplePodcastsListeningLink,
        },
    });

    const data =
        stats?.data.map((current, index) => {
            const date = dayjs.unix(current.at);
            return {
                ...current,
                date: new Date(current.at * 1000),
                dateShortFormat:
                    user.language === 'fr' ? date.format('DD/MM/YYYY') : date.format('M/D/YY'),
                dateLongFormat: date.format('ddd ll'),
                weekFormat: (
                    <FormattedMessage
                        defaultMessage={`Semaine {week}`}
                        values={{ week: date.isoWeek() }}
                    />
                ),
                monthShortFormat: date.format('MM/YYYY'),
                monthLongFormat: date.format('MMMM YYYY'),
                yearFormat: date.format('YYYY'),
                id: index,
                // Virtually set out of ranking values to ranking 400, to deliberately display a peak below the axis line of the graph
                ranking: current.position ? current.position : 400,
            };
        }) || [];

    let formatMatchingSelectedStep;
    switch (precision) {
        case 'days':
            formatMatchingSelectedStep = 'dateShortFormat';
            break;
        case 'weeks':
            formatMatchingSelectedStep = 'dateShortFormat';
            break;
        case 'months':
            formatMatchingSelectedStep = 'monthShortFormat';
            break;
        case 'years':
            formatMatchingSelectedStep = 'yearFormat';
            break;
        default:
            formatMatchingSelectedStep = 'dateShortFormat';
    }

    return (
        <DeprecatedWhitePaper flex overflowHide>
            <DeprecatedPaper height="165px" w={150} br={1} border="--neutral100">
                <DeprecatedPaper
                    height="49px"
                    py={15}
                    pl={20}
                    border="--neutral100"
                    background="--neutral50"
                >
                    <DeprecatedText color="--neutral500">
                        <FormattedMessage defaultMessage="Période" />
                    </DeprecatedText>
                </DeprecatedPaper>
                <DeprecatedPaper
                    height="50px"
                    py={15}
                    pl={20}
                    bt={1}
                    border="--neutral100"
                    background="white"
                >
                    <DeprecatedText weight="semibold">
                        <FormattedMessage defaultMessage="Classement" />
                    </DeprecatedText>
                </DeprecatedPaper>
                <DeprecatedPaper
                    height="50px"
                    py={15}
                    pl={20}
                    bt={1}
                    bb={1}
                    border="--neutral100"
                    background="white"
                >
                    <DeprecatedText weight="semibold">
                        <FormattedMessage defaultMessage="Variations" />
                    </DeprecatedText>
                </DeprecatedPaper>
            </DeprecatedPaper>
            <DeprecatedPaper w="auto" auto>
                {isLoading ? (
                    <VirtualList
                        height={165}
                        itemCount={10}
                        itemSize={115}
                        renderItem={({ index, style }) => (
                            <RankingVariationGraphTableColumnFallback key={index} style={style} />
                        )}
                        scrollDirection="horizontal"
                        width={size.width - 150}
                    />
                ) : (
                    <VirtualList
                        height={165}
                        itemCount={data.length}
                        itemSize={Math.max(115, (size.width - 150) / (data.length || 1))}
                        overscanCount={5}
                        renderItem={({ index, style }) => (
                            <RankingVariationGraphTableColumn
                                data={data[index]}
                                formatMatchingSelectedStep={formatMatchingSelectedStep}
                                hovered={(hoveredValue && hoveredValue.id) === data[index].id}
                                key={data[index].id}
                                onHover={onHover}
                                style={style}
                            />
                        )}
                        scrollDirection="horizontal"
                        scrollToAlignment="center"
                        scrollToIndex={scrollToValue && scrollToValue.id}
                        width={size.width - 150}
                    />
                )}
            </DeprecatedPaper>
        </DeprecatedWhitePaper>
    );
}

export default withSize()(RankingVariationGraphTable);
