import { FormattedMessage } from 'react-intl';
import useQuery from '@/shared/hooks/useQuery';
import { download } from '@/shared/utils/file';
import { useParams } from 'react-router';
import { fetchCampaignStatsCsv, fetchShowsSingleListenerCsv, fetchShowStatsCsv } from '@/api';
import Button from '@/components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useStatsDateSelectorContext } from '@/context/StatsDateSelectorContext';
import styled from 'styled-components';

interface ExportProps {
    isUniqueDownloads: boolean;
    precision: 'days' | 'weeks' | 'months' | 'years';
}

const Export = ({ isUniqueDownloads, precision }: ExportProps) => {
    const { showId } = useParams<{ showId: string }>();
    const query = useQuery();
    const campaignId = query.get('campaignId');
    const episodeQuery = query.get('episode');
    const { startDate, endDate } = useStatsDateSelectorContext();

    const doExport = async () => {
        const query = {
            start: startDate,
            end: endDate,
            step: precision,
            podcasts: episodeQuery && episodeQuery !== 'all' ? episodeQuery.split('_') : [],
        };

        let response;

        if (campaignId) {
            response = await fetchCampaignStatsCsv(campaignId, {
                ...query,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            });
        }

        if (isUniqueDownloads) {
            response = await fetchShowsSingleListenerCsv(showId, {
                ...query,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            });
        }

        if (!isUniqueDownloads) {
            response = await fetchShowStatsCsv(showId, {
                ...query,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            });
        }

        const { axios, ...data } = response;
        const filename = axios.headers['content-disposition'].match(/filename="(.+)"/)[1];
        download(Object.values(data).join(''), filename, 'txt');
    };

    return (
        <ExportWrapper>
            <Button
                onPress={doExport}
                variant="ghost"
                startIcon={
                    <FontAwesomeIcon
                        icon={icon({ name: 'arrow-up-right-from-square', style: 'solid' })}
                    />
                }
            >
                <FormattedMessage defaultMessage="Exporter en .csv" />
            </Button>
        </ExportWrapper>
    );
};

const ExportWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export default Export;
