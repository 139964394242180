import styled from 'styled-components';
import Text from '@ui/atoms/Text';
import DownloadsComparisonChartEpisodeMarker from '@/components/charts/DownloadsComparisonChart/DownloadsComparisonChartEpisodeMarker';

interface Episode {
    id: string;
    name: string;
    color: string;
}

interface DownloadsComparisonChartLegendProps {
    episodes: Episode[];
}

const DownloadsComparisonChartLegend = ({ episodes }: DownloadsComparisonChartLegendProps) => {
    if (episodes.length === 0) {
        return null;
    }

    return (
        <List>
            {episodes.map(({ id, name, color }) => (
                <ListItem key={id}>
                    <DownloadsComparisonChartEpisodeMarker color={color} size="0.75rem" />
                    <Text numberOfLines={1}>{name}</Text>
                </ListItem>
            ))}
        </List>
    );
};

const List = styled.ul`
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem 1.5rem;
    list-style: none;
    margin: 0;
    padding: 0;
`;

const ListItem = styled.li`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    max-width: min(20rem, 100%);
`;

export default DownloadsComparisonChartLegend;
