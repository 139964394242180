import Link from '@/components/ui/atoms/Link';
import Meter from '@/components/ui/atoms/Meter';
import Text from '@/components/ui/atoms/Text';
import Stack from '@/components/ui/layout/Stack';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import useInternalRouteMapping from '@/utils/hooks/useInternalRouteMapping.hook';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';

const EpisodeEditResumeQuotaAlert = () => {
    const { data: user } = useUserQuery();
    const hasReachedQuota = useMemo(
        () => user?.trialEpisodes >= user?.maximumTrialEpisodes,
        [user],
    );
    const offersLink = useInternalRouteMapping().link('user.offers');

    if (!user) return null;

    return (
        <Alert hasReachedQuota={hasReachedQuota}>
            <Stack $gap="0.5rem">
                <AlertTitle>
                    <Icon icon={icon({ name: 'circle-info', style: 'solid' })} />
                    <Title variant="bodyL">
                        {hasReachedQuota ? (
                            <FormattedMessage
                                defaultMessage="Vous avez déjà publié ou planifié {maxEpisodes, plural, one {# épisode} other {# épisodes}}"
                                values={{
                                    maxEpisodes: user?.maximumTrialEpisodes,
                                }}
                            />
                        ) : (
                            <FormattedMessage defaultMessage="Vous utilisez une offre Free" />
                        )}
                    </Title>
                </AlertTitle>
                <PublishedEpisodes>
                    <FormattedMessage
                        defaultMessage="{publishedEpisodes, plural, =0 {# / {maxEpisodes} épisode publié} one {# / {maxEpisodes} épisode publié} other {# / {maxEpisodes} épisodes publiés}}"
                        values={{
                            publishedEpisodes: user?.trialEpisodes,
                            maxEpisodes: user?.maximumTrialEpisodes,
                        }}
                    />
                </PublishedEpisodes>
                <Meter
                    color={hasReachedQuota ? '--alert500' : '--info500'}
                    bgColor={hasReachedQuota ? '--alert100' : '--info100'}
                    value={user?.trialEpisodes}
                    maxValue={user?.maximumTrialEpisodes}
                />
            </Stack>
            {hasReachedQuota && (
                <Text variant="bodyM" color="--neutral500">
                    <FormattedMessage
                        defaultMessage="Pour publier ou planifier plus d’épisodes, passez en illimité et <link>découvrez les offres Ausha</link> 🚀"
                        values={{
                            link: (chunks) => (
                                <OffersLink as={Link} target="_blank" to={offersLink}>
                                    {chunks}
                                </OffersLink>
                            ),
                        }}
                    />
                </Text>
            )}
        </Alert>
    );
};

const Alert = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    padding: 1.25rem 1rem;
    width: 100%;
    border-radius: var(--r-m);
    background-color: var(--info50);
    color: var(--info500);

    ${({ hasReachedQuota }) =>
        hasReachedQuota &&
        css`
            background-color: var(--alert50);
            color: var(--alert500);
        `}
`;
const AlertTitle = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
`;
const PublishedEpisodes = styled(Text)`
    color: inherit;
`;
const Title = styled.div`
    font-weight: var(--fw-semibold);
    color: inherit;
`;
const Icon = styled(FontAwesomeIcon)`
    color: inherit;
    font-size: 1rem;
`;
const OffersLink = styled.span`
    text-decoration: underline;
    font-weight: var(--fw-semibold);
    color: inherit;
`;

export default EpisodeEditResumeQuotaAlert;
