import {
    useEpisodeEditAudioFileMutation,
    useEpisodeEditAudioFileState,
} from '@/context/EpisodeEditContext';
import styled from 'styled-components';
import { useEpisodeBeingEdited } from '@/context/EpisodeFormContext';
import EpisodeEditResumeAudioFileUploading from '../EpisodeEditResumeAudioFileUploading';
import EpisodeEditResumeNoAudioFile from '../EpisodeEditResumeNoAudioFile';
import EpisodeEditResumeAudioFileUploaded from '../EpisodeEditResumeAudioFileUploaded';

const EpisodeEditResumeAudioFile = () => {
    const episodeEditAudioFileMutation = useEpisodeEditAudioFileMutation();
    const [uploadingAudioFile] = useEpisodeEditAudioFileState();
    const episodeBeingEdited = useEpisodeBeingEdited();
    const uploadedAudioFile = episodeBeingEdited?.files?.[0];

    if (episodeEditAudioFileMutation.isLoading || uploadingAudioFile) {
        return (
            <Container>
                <EpisodeEditResumeAudioFileUploading file={uploadingAudioFile} />
            </Container>
        );
    }

    if (uploadedAudioFile) {
        return (
            <Container>
                <EpisodeEditResumeAudioFileUploaded
                    file={uploadedAudioFile}
                    audioUrl={episodeBeingEdited.audioUrl}
                />
            </Container>
        );
    }

    return (
        <Container>
            <EpisodeEditResumeNoAudioFile />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    width: 100%;
`;

export default EpisodeEditResumeAudioFile;
