import RouterLink from '@/components/Link/RouterLink';
import Text from '@/components/ui/atoms/Text';
import Stack from '@/components/ui/layout/Stack';
import { ImportedEpisode } from '@/queries/rss/useImportedEpisodesQuery.hook';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import useSubscriptionQuery from '@/queries/subscription/useSubscriptionQuery.hook';
import { PRICING } from '@/shared/config/pricing';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import styled from 'styled-components';

interface EpisodesProps {
    isProcessing: boolean;
    importedEpisodes: ImportedEpisode[];
    counters: {
        pending: number;
        processing: number;
        done: number;
        error: number;
        total: number;
    };
}

const Episodes = ({ isProcessing, importedEpisodes, counters }: EpisodesProps) => {
    const { showId } = useParams<{ showId: string }>();
    const { data: subscription } = useSubscriptionQuery();
    const { data: show } = useShowQuery(showId);
    const importedEpisodesCount = importedEpisodes?.length || 0;
    const importedEpisodesWithErrorCount = counters.error;
    const hasErrors = importedEpisodesWithErrorCount > 0;
    const isFreemium = show?.ownerPricing === PRICING.FREEMIUM || subscription?.isFreemium;

    if (isProcessing) {
        return (
            <StepWrapper>
                <Stack $gap="0.25rem" $align="center">
                    <Text fontWeight="--fw-semibold">
                        {isFreemium ? (
                            <FormattedMessage defaultMessage="Nous importons vos 5 derniers épisodes" />
                        ) : (
                            <FormattedMessage defaultMessage="Nous importons vos épisodes" />
                        )}
                    </Text>
                    <Text color="--neutral500">
                        <FormattedMessage defaultMessage="Cela peut prendre quelques minutes." />
                    </Text>
                </Stack>
                <Text variant="bodyS" fontWeight="--fw-semibold" color="--primary500">
                    {counters.done} / {counters.total}
                </Text>
            </StepWrapper>
        );
    }

    if (hasErrors) {
        return (
            <StepWrapper>
                <Stack $gap="0.25rem" $align="center">
                    <Text fontWeight="--fw-semibold">
                        <FormattedMessage
                            defaultMessage={`Importation terminée avec {importedEpisodesWithErrorCount, plural,
                        one {# erreur}
                        other {# erreurs}
                    }.`}
                            values={{
                                importedEpisodesWithErrorCount,
                            }}
                        />
                    </Text>
                    <Text color="--neutral500">
                        <FormattedMessage
                            defaultMessage={`{importedEpisodesCount, plural,
                                one {Votre épisode a été importé, mais un problème a été détecté.}
                                other {Vos # épisodes ont été importés, mais des problèmes ont été détectés.}
                            }`}
                            values={{
                                importedEpisodesCount,
                            }}
                        />
                    </Text>
                </Stack>
                <RouterLink
                    to={`/app/show/${showId}/settings/rss-import`}
                    size="small"
                    variant="button-tertiary"
                >
                    <FormattedMessage defaultMessage="Vérifiez-les dès maintenant" />
                </RouterLink>
            </StepWrapper>
        );
    }

    return (
        <StepWrapper>
            <Stack $gap="0.25rem" $align="center">
                <Text fontWeight="--fw-semibold">
                    {isFreemium ? (
                        <FormattedMessage defaultMessage="Nous avons importé vos 5 derniers épisodes" />
                    ) : (
                        <FormattedMessage
                            defaultMessage={`{importedEpisodesCount, plural,
                                one {Nous avons importé votre épisode}
                                other {Nous avons importé vos # épisodes}
                            }`}
                            values={{
                                importedEpisodesCount: counters.total,
                            }}
                        />
                    )}
                </Text>
                <Text color="--neutral500">
                    {isFreemium ? (
                        <FormattedMessage defaultMessage="Pour accéder à tous vos épisodes, abonnez-vous maintenant !" />
                    ) : (
                        <FormattedMessage defaultMessage="Tous vos épisodes ont été importés avec succès." />
                    )}
                </Text>
            </Stack>
            <DoneWrapper>
                <CheckIcon icon={icon({ name: 'check', style: 'solid' })} />
                <Text fontWeight="--fw-semibold">
                    <FormattedMessage defaultMessage="Fait" />
                </Text>
            </DoneWrapper>
        </StepWrapper>
    );
};

const StepWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    color: var(--black);
    row-gap: 1.5rem;
`;
const DoneWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.25rem;
    color: var(--primary500);
`;
const CheckIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;

export default Episodes;
