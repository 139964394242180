import arrowDownIcon from '@public/icons/chevrondown.svg';
import arrowUpIcon from '@public/icons/arrow-up.svg';
import checkIcon from '@public/icons/check.svg';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { PoseGroup } from 'react-pose';
import DeprecatedIcon from '@ui/atoms/DeprecatedIcon';
import Menu from '@ui/atoms/Menu';
import MenuItem from '@ui/atoms/MenuItem';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import { connect } from '@/components/legacy/connect';
import { useDroppable } from '@/shared/hooks/useDroppable';
import {
    ALL_CATEGORIES_MESSAGE,
    CATEGORIES_MESSAGES,
} from '@/shared/services/categoryMessageService';

const Category = styled(MenuItem)`
    padding-left: ${(p) => (p.isParent ? '20px' : '30px')};

    &:first-child {
        padding-left: 10px;
    }
`;

const enhance = connect(({ applePodcastsStatsStore }) => {
    // Add "All categories" to categories list
    const categories = [
        {
            slug: 'all-categories',
            id: null,
        },
        ...applePodcastsStatsStore.applePodcastsAvailableCategories,
    ];

    return {
        categories,
        activeCategory: categories.find(
            (c) => c.id === applePodcastsStatsStore.applePodcastsCategoryId,
        ),
        setActiveCategory: applePodcastsStatsStore.setApplePodcastsCategoryId,
    };
});

function ApplePodcastsCategorySelector({
    categories,
    activeCategory,
    setActiveCategory,
    ...props
}) {
    const intl = useIntl();
    const [ref, opened, open] = useDroppable();

    if (activeCategory === undefined) {
        return null;
    }

    return (
        <DeprecatedPaper
            auto
            maxW={250}
            style={{ position: 'relative', flexBasis: 'content' }}
            {...props}
        >
            <DeprecatedPaper ref={ref} w="max-content" flex align="baseline" onClick={open}>
                <DeprecatedText color="--primary" style={{ userSelect: 'none' }}>
                    {activeCategory.slug === 'all-categories'
                        ? intl.formatMessage(ALL_CATEGORIES_MESSAGE)
                        : intl.formatMessage(CATEGORIES_MESSAGES[activeCategory.slug])}
                </DeprecatedText>
                <DeprecatedIcon
                    as={opened ? arrowUpIcon : arrowDownIcon}
                    size={8}
                    ml={5}
                    color="--primary"
                />
            </DeprecatedPaper>
            <PoseGroup>
                <Menu
                    key={0}
                    opened={opened}
                    anchor="up"
                    x={null}
                    xR={0}
                    y="calc(100% + 10px)"
                    w={250}
                    originX={0}
                >
                    {categories.map((category) => (
                        <Category
                            isParent={!category.parentId}
                            key={category.id}
                            onClick={() => setActiveCategory(category.id)}
                        >
                            <DeprecatedText
                                auto
                                ellipsis
                                maxW={200}
                                weight={activeCategory.id === category.id ? 'bold' : 'normal'}
                            >
                                {category.slug === 'all-categories'
                                    ? intl.formatMessage(ALL_CATEGORIES_MESSAGE)
                                    : intl.formatMessage(CATEGORIES_MESSAGES[category.slug])}
                            </DeprecatedText>
                            {activeCategory.id === category.id && (
                                <DeprecatedPaper as="span" ml={10} w={null}>
                                    <DeprecatedIcon as={checkIcon} size={12} color="--primary" />
                                </DeprecatedPaper>
                            )}
                        </Category>
                    ))}
                </Menu>
            </PoseGroup>
        </DeprecatedPaper>
    );
}

export default enhance(ApplePodcastsCategorySelector);
