import axios from 'axios';
import { buildQuery } from './query';
import Cookies from 'js-cookie';

const defaultHeaders = {
    'Content-Type': 'application/json',
    Accept: 'application/json, text/plain',
};

type Method = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
type Query = Record<string, any>;

interface RequestProps {
    method?: Method;
    basePath: string;
    target: string;
    body?: any;
    query?: Query;
    headers?: Record<string, string>;
    [key: string]: any;
}

export const request = async ({
    method,
    basePath,
    target,
    body,
    query,
    headers,
    ...rest
}: RequestProps) => {
    const { url, parsedMethod } = await parseApiUrl({ basePath, method, target, query });
    const accessToken = Cookies.get('token') || '';
    const isPublicAPI = process.env.API_PUBLIC === basePath;

    const requestHeaders = {
        ...defaultHeaders,
        ...headers,
        ...(isPublicAPI || !accessToken ? {} : { Authorization: `Bearer ${accessToken}` }),
    };
    const config = {
        url,
        method: method ?? parsedMethod ?? 'GET',
        data: body,
        headers: requestHeaders,
        ...rest,
    };

    const res = await axios.request(config);
    return { ...res.data, axios: res };
};

interface parseApiUrlProps {
    basePath: string;
    target: string;
    query?: Query;
    method?: Method;
}

export async function parseApiUrl({
    basePath,
    target,
    query,
}: parseApiUrlProps): Promise<{ url: string; parsedMethod?: Method }> {
    return { url: `${basePath}${target}${buildQuery(query)}` };
}
