import { useEffect, useRef } from 'react';
import { useSwitch } from './useSwitch';

const whiteList = ['BUTTON', 'INPUT'];
const blackList = ['DIV'];

export function useDroppable(closeOnSelect = true, initialState = false, manualClose = false) {
    const ref = useRef(null);
    const [opened, onOpen, close] = useSwitch(initialState);

    useEffect(() => {
        document.addEventListener('mouseup', handleClickOutside);
        return () => document.removeEventListener('mouseup', handleClickOutside);
    }, []);

    function handleClickOutside(event) {
        if (manualClose) return;

        const elementIsInContainer = ref.current && ref.current.contains(event.target);

        if (!elementIsInContainer) {
            setTimeout(close, 0);
            return;
        }

        if (!closeOnSelect) {
            return;
        }

        const isInWhitelist = whiteList.includes(event.target.tagName);
        const isInBlackList = blackList.includes(event.target.tagName);
        if (!isInWhitelist && !isInBlackList) {
            setTimeout(close, 0);
        }
    }

    function open() {
        setTimeout(() => !opened && onOpen(), 0);
    }

    return [ref, opened, open, close];
}

export default useDroppable;
