import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled, { css } from 'styled-components';

const Pagination = ({ currentPage, perPage, total, totalPages, handlePageChange }) => {
    const pages = (totalPages, currentPage) => {
        const range = []; // Array to hold the page numbers
        let startPage, endPage;

        // Determine the start and end page numbers
        if (totalPages <= 5) {
            // Case when total pages is 5 or less
            startPage = 1;
            endPage = totalPages;
        } else {
            // More than 5 total pages
            if (currentPage <= 3) {
                // Current page near the start
                startPage = 1;
                endPage = 5;
            } else if (currentPage + 2 >= totalPages) {
                // Current page near the end
                startPage = totalPages - 4;
                endPage = totalPages;
            } else {
                // Current page somewhat in the middle
                startPage = currentPage - 2;
                endPage = currentPage + 2;
            }
        }

        // Create the array of page numbers
        for (let i = startPage; i <= endPage; i++) {
            range.push(i);
        }

        return range;
    };

    return (
        <PaginationWrapper>
            <PaginationInfo>
                <FormattedMessage
                    defaultMessage="Résultats: {startElement} - {endElement} sur {totalElements}"
                    values={{
                        startElement: (currentPage - 1) * perPage + 1,
                        endElement: Math.min(currentPage * perPage, total),
                        totalElements: total,
                    }}
                />
            </PaginationInfo>
            <PaginationAction>
                <ChevronButton disabled={currentPage === 1}>
                    <FontAwesomeIcon icon={icon({ name: 'chevron-left', style: 'solid' })} />
                </ChevronButton>
                <PageButtonList>
                    {pages(totalPages, currentPage).map((_, i) => (
                        <PageButtonWrapper key={i}>
                            <PageButton
                                key={i}
                                onClick={() => handlePageChange(i + 1)}
                                isCurrentPage={i + 1 === currentPage}
                            >
                                {i + 1}
                            </PageButton>
                        </PageButtonWrapper>
                    ))}
                </PageButtonList>
                <ChevronButton disabled={currentPage === totalPages}>
                    <FontAwesomeIcon icon={icon({ name: 'chevron-right', style: 'solid' })} />
                </ChevronButton>
            </PaginationAction>
            <FlexDecoy />
        </PaginationWrapper>
    );
};

const PaginationWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const PaginationInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    color: var(--neutral500);
`;
const PaginationAction = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;
const FlexDecoy = styled.div`
    width: 8rem;
`;
const ButtonBase = styled.button`
    border: none;
    background: none;
    width: 2rem;
    height: 2rem;
    color: var(--black);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--fs-body-m);
    font-weight: var(--fw-semibold);
    border-radius: var(--r-s);
    cursor: pointer;

    &:hover {
        background-color: var(--neutral50);
    }
`;
const ChevronButton = styled(ButtonBase)`
    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    &:disabled:hover {
        background-color: transparent;
    }
`;
const PageButtonList = styled.ul`
    display: flex;
    align-items: center;
    gap: 0.25rem;
    list-style: none;
    margin: 0;
    padding: 0;
`;
const PageButtonWrapper = styled.li`
    width: 2rem;
    height: 2rem;
`;
const PageButton = styled(ButtonBase)`
    ${({ isCurrentPage }) =>
        isCurrentPage &&
        css`
            background-color: var(--primary50);
            color: var(--primary);
            &:hover {
                background-color: var(--primary100);
            }
        `}
`;

Pagination.propTypes = {
    currentPage: PropTypes.number,
    perPage: PropTypes.number,
    total: PropTypes.number,
    totalPages: PropTypes.number,
    maxPagesShown: PropTypes.number,
    handlePageChange: PropTypes.func,
};

export default Pagination;
