import Button from '@/components/Button';
import IconButton from '@/components/IconButton';
import Modal from '@/components/ui/atoms/Modal';
import Text from '@/components/ui/atoms/Text';
import Stack from '@/components/ui/layout/Stack';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import styled from 'styled-components';

interface SubscribeToPsoModalProps {
    isOpen: boolean;
    onOpenChange: (open: boolean) => void;
}

const SubscribeToPsoModal = ({ isOpen, onOpenChange }: SubscribeToPsoModalProps) => {
    const handleClose = () => onOpenChange(false);
    // TODO: Replace with dynamic prices
    const monthlyPriceInt = 12.5;
    // TODO: Use currency from context when available
    const currency = 'usd';

    return (
        <ModalStyled isOpen={isOpen} onOpenChange={onOpenChange} size="medium">
            <Header>
                <TitleWrapper>
                    <Text variant="headingM" fontWeight="--fw-bold">
                        <FormattedMessage defaultMessage="Souscrire au PSO Control Panel" />
                    </Text>
                    <Text color="--neutral500">
                        <FormattedMessage defaultMessage="Boostez la visibilité de tous vos podcasts avec des outils puissants et des données enrichies pour les propulser plus haut dans les classements Apple Podcasts et Spotify." />
                    </Text>
                </TitleWrapper>
                <IconButton
                    icon={<CloseIcon icon={icon({ name: 'xmark-large', style: 'solid' })} />}
                    onPress={handleClose}
                    variant="ghost"
                    aria-label="Close modal"
                    style={{ padding: 0 }}
                />
            </Header>
            <Stack $gap="0.25rem">
                <Text fontWeight="--fw-semibold">
                    <FormattedMessage defaultMessage="Le plan PSO Control Panel (PSO Control Panel + PSO Checker)" />
                </Text>
                <Text color="--neutral500">
                    <FormattedMessage
                        defaultMessage="{amount} /mois"
                        values={{
                            amount: (
                                <FormattedNumber
                                    value={monthlyPriceInt}
                                    style="currency"
                                    currency={currency}
                                    currencyDisplay="narrowSymbol"
                                    maximumFractionDigits={monthlyPriceInt % 1 === 0 ? 0 : 2}
                                />
                            ),
                        }}
                    />
                </Text>
            </Stack>
            <Actions>
                <Button variant="link-secondary" onPress={handleClose}>
                    <FormattedMessage defaultMessage="Annuler" />
                </Button>
                <Button>
                    <FormattedMessage defaultMessage="Souscrire au PSO Control Panel" />
                </Button>
            </Actions>
        </ModalStyled>
    );
};

const ModalStyled = styled(Modal)`
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 2rem;
`;
const Header = styled.div`
    display: flex;
    align-items: start;
    justify-content: space-between;
    column-gap: 2rem;

    & > button {
        flex-shrink: 0;
    }
`;
const CloseIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--neutral500);
`;
const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;
    flex-grow: 1;
`;
const Actions = styled.div`
    padding-top: 1.5rem;
    border-top: 1px solid var(--neutral100);
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export default SubscribeToPsoModal;
