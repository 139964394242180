import ProgressBar from '@ui/ProgressBar';
import styled from 'styled-components';

interface DifficultyScoreCellProps {
    score: number;
}

const DifficultyScoreCell = ({ score }: DifficultyScoreCellProps) => {
    return (
        <DifficultyScoreCellWrapper>
            <span>{score}</span>
            <ProgressBar
                score={Math.min(100, Math.max(5, score))}
                thresholds={[0, 31, 71]}
                isReversed={true}
            />
        </DifficultyScoreCellWrapper>
    );
};

const DifficultyScoreCellWrapper = styled.div`
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    & > nth-child(1) {
        flex: 1;
    }
`;

export default DifficultyScoreCell;
