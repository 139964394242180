import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchAppleShows } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import rssKeys from './rssKeys';
import { z } from 'zod';

export const ApplePodcastSearchResultSchema = z.object({
    appleUrl: z.string().optional(),
    author: z.string(),
    episodeCount: z.number(),
    image: z.string().nullable(),
    showTitle: z.string(),
    url: z.string().nullable(),
});
const ApplePodcastSearchResultsSchema = z.array(ApplePodcastSearchResultSchema);
export type ApplePodcastSearchResult = z.infer<typeof ApplePodcastSearchResultSchema>;
type Key = QueryFunctionContext<ReturnType<typeof rssKeys.searchApplePodcasts>>;
type InputProps = {
    searchQuery: string;
};

const queryFn = async ({ queryKey: [{ searchQuery }] }: Key) => {
    const { data } = await fetchAppleShows(searchQuery);
    return ApplePodcastSearchResultsSchema.parse(camelcaseKeys(data, { deep: true }));
};

interface Options {
    enabled?: boolean;
}

const useSearchApplePodcastsQuery = ({ searchQuery }: InputProps, options?: Options) => {
    return useQuery({
        queryKey: rssKeys.searchApplePodcasts(searchQuery),
        queryFn,
        enabled: !!searchQuery && searchQuery.length >= 3 && (options?.enabled ?? true),
    });
};

export default useSearchApplePodcastsQuery;
