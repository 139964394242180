export const PRICING = {
    FREEMIUM: 'freemium',
    LAUNCH: 'launch',
    BOOST: 'boost',
    ENTERPRISE: 'enterprise',
    SUPERSONIC: 'supersonic',
    BASIC: ['launch'],
    INTERMEDIATE: ['boost'],
    PREMIUM: ['enterprise', 'supersonic'],
};

const { LAUNCH, BOOST, ENTERPRISE, SUPERSONIC } = PRICING;

export const PRICING_BASIC = [LAUNCH];
export const PRICING_INTERMEDIATE = [...PRICING_BASIC, BOOST];
export const PRICING_PREMIUM = [...PRICING_INTERMEDIATE, ENTERPRISE, SUPERSONIC];

export const PRICING_NAME = {
    [PRICING.FREEMIUM]: 'Free',
    [PRICING.LAUNCH]: 'Launch',
    [PRICING.BOOST]: 'Boost',
    [PRICING.ENTERPRISE]: 'Enterprise',
    [PRICING.SUPERSONIC]: 'Supersonic',
};

export const PRICING_COMPARISON_URL_FR = 'https://www.ausha.co/fr/tarifs/#tableautarifs';
export const PRICING_COMPARISON_URL_EN = 'https://www.ausha.co/pricing/#tableautarifs';

export const PRICING_CURRENCY = {
    EUR: 'eur',
    USD: 'usd',
    GBP: 'gbp',
};
