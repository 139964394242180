import FormModelFactory from './factories/FormModelFactory';
import { isRequired, maxNumber, minLength } from './validators/validators';

export default FormModelFactory({
    fields: {
        name: {
            validators: [isRequired(), minLength(3)],
        },
        maxListens: {
            validators: [maxNumber(10000000)],
        },
    },
});
