interface QueryParams {
    start: string | null;
    end: string | null;
}

const statsKeys = {
    all: () => [{ entity: 'stats' }],
    downloads: (rawShowId: string, query: QueryParams) => [
        {
            ...statsKeys.all()[0],
            scope: 'downloads',
            showId: Number(rawShowId),
            query,
        },
    ],
    downloadsComparison: (
        rawShowId: string,
        daysAfterPublication: 7 | 15 | 30 | 90,
        episodeIds: number[],
    ) => [
        {
            ...statsKeys.all()[0],
            scope: 'comparison',
            showId: Number(rawShowId),
            daysAfterPublication,
            episodeIds,
        },
    ],
    genders: (rawShowId: string, query: QueryParams) => [
        {
            ...statsKeys.all()[0],
            scope: 'genders',
            showId: Number(rawShowId),
            query,
        },
    ],
    ages: (rawShowId: string, query: QueryParams) => [
        {
            ...statsKeys.all()[0],
            scope: 'ages',
            showId: Number(rawShowId),
            query,
        },
    ],
    agesExport: (rawShowId: string, query: QueryParams) => [
        {
            ...statsKeys.all()[0],
            scope: 'agesExport',
            showId: Number(rawShowId),
            query,
        },
    ],
    operatingSystemsByCampaignId: (campaignId: string, query: QueryParams) => [
        {
            ...statsKeys.all()[0],
            scope: 'operatingSystems',
            campaignId: Number(campaignId),
            query,
        },
    ],
    operatingSystemsByShowId: (showId: string, query: QueryParams) => [
        {
            ...statsKeys.all()[0],
            scope: 'operatingSystems',
            showId: Number(showId),
            query,
        },
    ],
    popularDaysByShowId: (showId: string, days: '7' | '15' | '30' | '90' | 'always') => [
        {
            ...statsKeys.all()[0],
            scope: 'popularDays',
            showId: Number(showId),
            days,
        },
    ],
    popularHoursByShowId: (showId: string, days: '7' | '15' | '30' | '90' | 'always') => [
        {
            ...statsKeys.all()[0],
            scope: 'popularHours',
            showId: Number(showId),
            days,
        },
    ],
    bestDayHourByShowId: (showId: string, days: '7' | '15' | '30' | '90' | 'always') => [
        {
            ...statsKeys.all()[0],
            scope: 'bestDayHour',
            showId: Number(showId),
            days,
        },
    ],
    heatmapByShowId: (showId: string, days: '7' | '15' | '30' | '90' | 'always') => [
        {
            ...statsKeys.all()[0],
            scope: 'heatmap',
            showId: Number(showId),
            days,
        },
    ],
};

export default statsKeys;
