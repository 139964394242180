import { FormattedMessage } from 'react-intl';
import { useStripe } from '@stripe/react-stripe-js';
import Button from '@/components/Button';
import RouterLink from '@/components/Link/RouterLink';
import useSubscriptionQuery from '@/queries/subscription/useSubscriptionQuery.hook';
import { useState } from 'react';

const PaymentValidationRequiredToast = () => {
    const stripe = useStripe();

    const [paymentIsValidating, setPaymentIsValidating] = useState(false);
    const { data: subscription } = useSubscriptionQuery();

    const validatePayment = async () => {
        if (!stripe || !subscription?.intentSecret) return;
        setPaymentIsValidating(true);
        await stripe.confirmCardPayment(subscription.intentSecret);
        setPaymentIsValidating(false);
    };

    return (
        <div>
            {subscription?.intentSecret !== undefined ? (
                <>
                    <FormattedMessage
                        defaultMessage="Veuillez <link>valider votre moyen de paiement</link>."
                        values={{
                            link: (chunks: React.ReactNode) => {
                                return (
                                    <Button
                                        onPress={validatePayment}
                                        isLoading={paymentIsValidating}
                                        variant="link-primary"
                                    >
                                        {chunks}
                                    </Button>
                                );
                            },
                        }}
                    />
                </>
            ) : (
                <>
                    <FormattedMessage
                        defaultMessage="La validation 3D Secure 2 a échoué. Veuillez <link>mettre à jour</link> vos informations de paiement."
                        values={{
                            link: (chunks: React.ReactNode) => {
                                return (
                                    <RouterLink to={`/app/user/subscription`}>{chunks}</RouterLink>
                                );
                            },
                        }}
                    />
                </>
            )}
        </div>
    );
};

export default PaymentValidationRequiredToast;
