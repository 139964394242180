import { css } from 'styled-components';
import PropTypes from 'prop-types';
import { computeCssValue } from '../../../../shared/utils/css';

/**
 * See https://chakra-ui.com/docs/features/style-props
 */
const gridMixin = css`
    grid-gap: ${(props) => computeCssValue(props.$gridGap || props.$gap)};
    grid-row-gap: ${(props) => computeCssValue(props.$gridRowGap || props.$rowGap)};
    grid-column-gap: ${(props) => computeCssValue(props.$gridColumnGap || props.$columnGap)};
    grid-column: ${(props) => props.$gridColumn ?? props.$column};
    grid-row: ${(props) => props.$gridRow ?? props.$row};
    grid-area: ${(props) => props.$gridArea ?? props.$area};
    grid-auto-flow: ${(props) => props.$gridAutoFlow ?? props.$autoFlow};
    grid-auto-rows: ${(props) => props.$gridAutoRows ?? props.$autoRows};
    grid-auto-columns: ${(props) => props.$gridAutoColumns ?? props.$autoColumns};
    grid-column-start: ${(props) => props.$gridColumnStart ?? props.$columnStart};
    grid-column-end: ${(props) => props.$gridColumnEnd ?? props.$columnEnd};
    grid-row-start: ${(props) => props.$gridRowStart ?? props.$rowStart};
    grid-row-end: ${(props) => props.$gridRowEnd ?? props.$rowEnd};
    grid-template: ${(props) => props.$gridTemplate ?? props.$template};
    grid-template-rows: ${(props) => props.$gridTemplateRows ?? props.$templateRows};
    grid-template-columns: ${(props) => props.$gridTemplateColumns ?? props.$templateColumns};
    grid-template-areas: ${(props) => props.$gridTemplateAreas ?? props.$templateAreas};
`;

export const gridPropTypes = {
    $gridGap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $gridRowGap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $gridColumnGap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $gridColumn: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $column: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Shortcut for gridColumn
    $gridRow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $row: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Shortcut for gridRow
    $gridArea: PropTypes.string,
    $area: PropTypes.string, // Shortcut for gridArea
    $gridAutoFlow: PropTypes.string,
    $autoFlow: PropTypes.string, // Shortcut for gridAutoFlow
    $gridAutoRows: PropTypes.string,
    $autoRows: PropTypes.string, // Shortcut for gridAutoRows
    $gridAutoColumns: PropTypes.string,
    $autoColumns: PropTypes.string, // Shortcut for gridAutoColumns
    $gridColumnStart: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $columnStart: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Shortcut for gridColumnStart
    $gridColumnEnd: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $columnEnd: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Shortcut for gridColumnEnd
    $gridRowStart: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $rowStart: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Shortcut for gridRowStart
    $gridRowEnd: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $rowEnd: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Shortcut for gridRowEnd
    $gridTemplate: PropTypes.string,
    $template: PropTypes.string, // Shortcut for gridTemplate
    $gridTemplateRows: PropTypes.string,
    $templateRows: PropTypes.string, // Shortcut for gridTemplateRows
    $gridTemplateColumns: PropTypes.string,
    $templateColumns: PropTypes.string, // Shortcut for gridTemplateColumns
    $gridTemplateAreas: PropTypes.string,
    $templateAreas: PropTypes.string, // Shortcut for gridTemplateAreas
};

export default gridMixin;
