import { FormattedMessage, useIntl } from 'react-intl';
import Alert from '@ui/atoms/Alert';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Text from '@ui/atoms/Text';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NoChaptersAlertIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
    font-size: 1.5rem;
`;

const NoChaptersAlertTutorialLink = styled.a`
    color: var(--neutral500);
    font-weight: var(--fw-semibold);
    text-decoration: underline;
`;

const NoChaptersAlert = () => {
    const intl = useIntl();

    return (
        <Alert
            variant="neutral"
            icon={<NoChaptersAlertIcon icon={icon({ name: 'circle-question', style: 'solid' })} />}
            description={
                <Text color="--neutral500">
                    <FormattedMessage
                        defaultMessage="Besoin d'aide ? <link>Voir le tutoriel</link>"
                        values={{
                            link: (chunks) => (
                                <NoChaptersAlertTutorialLink
                                    href={intl.formatMessage({
                                        defaultMessage:
                                            'https://aide.ausha.co/fr/articles/4929059-comment-utiliser-les-chapitres',
                                    })}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {chunks}
                                </NoChaptersAlertTutorialLink>
                            ),
                        }}
                    />
                </Text>
            }
        >
            <Text variant="bodyM" fontWeight="--fw-semibold">
                <FormattedMessage defaultMessage="Cliquez sur la piste audio ci-dessus pour ajouter un chapitre." />
            </Text>
        </Alert>
    );
};

export default NoChaptersAlert;
