import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { FORM_VALIDATION_MESSAGE } from '@/shared/services/validationMessageService';

const useEpisodePublicationDateFormSchema = () => {
    const intl = useIntl();

    return yup.object({
        publishedAt: yup.string().required(intl.formatMessage(FORM_VALIDATION_MESSAGE.required)),
    });
};

export default useEpisodePublicationDateFormSchema;
