import { useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDrag, DragPreview } from 'react-aria';
import { SOCIAL_NETWORK, SOCIAL_NETWORK_NAME } from '@/utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import PostDetails from '@/components//PostDetails';
import { Dialog, Popover } from 'react-aria-components';
import { sendAmplitudeLogEvent } from '@/helpers';
import styled, { css } from 'styled-components';

const Post = ({ post, postDetailsShown, setPostDetailsShown }) => {
    let preview = useRef(null);
    let { dragProps, isDragging } = useDrag({
        preview,
        getItems() {
            return [{ 'plain/text': JSON.stringify(post) }];
        },
    });
    const popoverTriggerRef = useRef(null);

    const platformLookup = {
        [SOCIAL_NETWORK.FACEBOOK]: {
            icon: <PlatformIcon icon={icon({ name: 'facebook', style: 'brands' })} />,
            color: '--facebook',
            name: SOCIAL_NETWORK_NAME[SOCIAL_NETWORK.FACEBOOK],
        },
        [SOCIAL_NETWORK.INSTAGRAM]: {
            icon: <PlatformIcon icon={icon({ name: 'instagram', style: 'brands' })} />,
            color: '--instagram',
            name: SOCIAL_NETWORK_NAME[SOCIAL_NETWORK.INSTAGRAM],
        },
        [SOCIAL_NETWORK.TWITTER]: {
            icon: <PlatformIcon icon={icon({ name: 'x-twitter', style: 'brands' })} />,
            color: '--twitter',
            name: SOCIAL_NETWORK_NAME[SOCIAL_NETWORK.TWITTER],
        },
        [SOCIAL_NETWORK.LINKEDIN]: {
            icon: <PlatformIcon icon={icon({ name: 'linkedin', style: 'brands' })} />,
            color: '--linkedin',
            name: SOCIAL_NETWORK_NAME[SOCIAL_NETWORK.LINKEDIN],
        },
    };

    if (!post.isMovable) {
        return (
            <>
                <PostWrapper
                    color={platformLookup[post.provider].color}
                    onClick={() => {
                        sendAmplitudeLogEvent('Social Network Post Preview Calendar view');
                        setPostDetailsShown(post.id);
                    }}
                    active={postDetailsShown}
                    ref={popoverTriggerRef}
                >
                    <PostInner>
                        <IconWrapper>{platformLookup[post.provider].icon}</IconWrapper>
                        {post.title ? (
                            <TitleWrapper>{post.title}</TitleWrapper>
                        ) : (
                            <EmptyContent color={platformLookup[post.provider].color}>
                                <FormattedMessage defaultMessage="Post vide" />
                            </EmptyContent>
                        )}
                    </PostInner>
                </PostWrapper>
                <PopoverStyled
                    triggerRef={popoverTriggerRef}
                    isOpen={!!postDetailsShown}
                    onOpenChange={setPostDetailsShown}
                    placement="right top"
                >
                    <Dialog>
                        <PostDetails
                            id={post.id}
                            platformLookup={platformLookup}
                            onHide={() => setPostDetailsShown(null)}
                        />
                    </Dialog>
                </PopoverStyled>
            </>
        );
    }

    return (
        <>
            <PostWrapper
                {...dragProps}
                tabIndex={0}
                isDragging={isDragging}
                color={platformLookup[post.provider].color}
                onClick={() => setPostDetailsShown(post.id)}
                active={postDetailsShown}
                ref={popoverTriggerRef}
            >
                {isDragging ? (
                    ''
                ) : (
                    <PostInner>
                        <IconWrapper>{platformLookup[post.provider].icon}</IconWrapper>
                        {post.title ? (
                            <TitleWrapper>{post.title}</TitleWrapper>
                        ) : (
                            <EmptyContent color={platformLookup[post.provider].color}>
                                <FormattedMessage defaultMessage="Post vide" />
                            </EmptyContent>
                        )}
                    </PostInner>
                )}
            </PostWrapper>
            <DragPreview ref={preview}>
                {() => (
                    <Preview color={platformLookup[post.provider].color}>
                        <PostInner>
                            <IconWrapper>{platformLookup[post.provider].icon}</IconWrapper>
                            {post.title ? (
                                <TitleWrapper>{post.title}</TitleWrapper>
                            ) : (
                                <EmptyContent color={platformLookup[post.provider].color}>
                                    <FormattedMessage defaultMessage="Post vide" />
                                </EmptyContent>
                            )}
                        </PostInner>
                    </Preview>
                )}
            </DragPreview>
            <PopoverStyled
                triggerRef={popoverTriggerRef}
                isOpen={!!postDetailsShown}
                onOpenChange={setPostDetailsShown}
                placement="right top"
            >
                <Dialog>
                    <PostDetails
                        id={post.id}
                        platformLookup={platformLookup}
                        onHide={() => setPostDetailsShown(null)}
                    />
                </Dialog>
            </PopoverStyled>
        </>
    );
};

const PostBase = css`
    width: 100%;
    height: 2rem;
    border-radius: var(--r-s);
    padding: 0.5rem;
    display: flex;
    align-items: center;
    column-gap: 0.25rem;
    font-size: var(--fs-body-s);
    font-weight: var(--fw-semibold);
    line-height: var(--fs-body-s);
    background-color: ${({ color }) => color && `var(${color}50)`};
    color: ${({ color }) => color && `var(${color})`};
    cursor: pointer;

    &:hover {
        opacity: 0.85;
    }
`;
const PostWrapper = styled.div`
    border: none;
    ${PostBase};

    ${({ active }) =>
        active &&
        css`
            background-color: ${({ color }) => color && `var(${color})`};
            color: var(--white);
        `};

    ${({ isDragging }) =>
        isDragging &&
        css`
            background-color: var(--neutral50);
        `};
`;
const Preview = styled.div`
    ${PostBase};
    transform: rotate(-5deg);
`;
const PostInner = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    min-width: 0;
`;
const IconWrapper = styled.div`
    width: 0.75rem;
    height: 0.75rem;
    flex-shrink: 0;
`;
const TitleWrapper = styled.span`
    flex-grow: 1;
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
const EmptyContent = styled(TitleWrapper)`
    font-style: italic;
`;
const PopoverStyled = styled(Popover)`
    width: 20rem;
    background-color: var(--white);
    border-radius: var(--r-l);
    box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.1);
    color: var(--black);
    max-height: fit-content !important;
`;
const PlatformIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;

Post.propTypes = {
    post: PropTypes.object,
    setCurrentlyDraggedItem: PropTypes.func,
    postDetailsShown: PropTypes.bool,
    setPostDetailsShown: PropTypes.func,
};

export default Post;
