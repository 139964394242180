import { useMutation, useQueryClient } from '@tanstack/react-query';
import { unlinkSharing } from '@/api';
import providerKeys from './providerKeys';

interface MutationFnProps {
    showId: string;
    provider: string;
}

const mutationFn = async ({ showId, provider }: MutationFnProps): Promise<void> => {
    return unlinkSharing(showId, provider);
};

export const useUnlinkProviderMutation = () => {
    const queryClient = useQueryClient();

    return useMutation(mutationFn, {
        onSuccess: (_, { showId }: { showId: string }) => {
            // Update the type of the first parameter
            queryClient.invalidateQueries({
                queryKey: providerKeys.listByShowId(showId),
            });
            queryClient.invalidateQueries({
                queryKey: providerKeys.allDetails(),
            });
        },
    });
};

export default useUnlinkProviderMutation;
