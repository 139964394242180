import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table';
import styled from 'styled-components';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import usePersonalAccessTokensQuery, {
    PersonalAccessToken,
} from '@/queries/personalAccessToken/usePersonalAccessTokensQuery.hook';
import Button from '@/components/Button';
import deletePersonalAccessTokenMutation from '@/queries/personalAccessToken/deletePersonalAccessTokenMutation';
import Text from '@/components/ui/atoms/Text';
import PersonalAccessTokenTableSkeleton from './PersonalAccessTokenTableSkeleton';

const PersonalAccessTokenTable = () => {
    const intl = useIntl();
    const { data, isError } = usePersonalAccessTokensQuery();
    const deleteToken = deletePersonalAccessTokenMutation();
    const columnHelper = createColumnHelper<PersonalAccessToken>();
    const columns = [
        columnHelper.accessor('name', {
            header: (header) => (
                <TableHeader>
                    {intl.formatMessage({
                        defaultMessage: 'Nom',
                    })}
                    <SortIcon
                        $isVisible={!!header.column.getIsSorted()}
                        icon={icon({ name: 'arrow-up', style: 'solid' })}
                        flip={header.column.getIsSorted() === 'asc' ? 'vertical' : undefined}
                    />
                </TableHeader>
            ),
            cell: (info) => info.getValue(),
            enableSorting: true,
        }),
        columnHelper.accessor('createdAt', {
            header: (header) => (
                <TableHeader>
                    {intl.formatMessage({
                        defaultMessage: 'Créé le',
                    })}
                    <SortIcon
                        $isVisible={!!header.column.getIsSorted()}
                        icon={icon({ name: 'arrow-up', style: 'solid' })}
                        flip={header.column.getIsSorted() === 'asc' ? 'vertical' : undefined}
                    />
                </TableHeader>
            ),
            cell: (info) => <FormattedDate value={info.getValue()} />,
            enableSorting: true,
        }),
        columnHelper.accessor('expiresAt', {
            header: (header) => (
                <TableHeader>
                    {intl.formatMessage({
                        defaultMessage: 'Expire le',
                    })}
                    <SortIcon
                        $isVisible={!!header.column.getIsSorted()}
                        icon={icon({ name: 'arrow-up', style: 'solid' })}
                        flip={header.column.getIsSorted() === 'asc' ? 'vertical' : undefined}
                    />
                </TableHeader>
            ),
            cell: (info) => <FormattedDate value={info.getValue()} />,
            enableSorting: true,
        }),
        columnHelper.display({
            id: 'actions',
            cell: (info) => (
                <Button
                    onPress={() => {
                        deleteToken.mutate(info.row.original.id);
                    }}
                    variant="tertiary"
                >
                    <FormattedMessage defaultMessage="Révoquer l'accès" />
                </Button>
            ),
            maxSize: 140,
        }),
    ];

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    if (isError) {
        return (
            <Text>
                <FormattedMessage defaultMessage="Oups, une erreur est survenue." />
            </Text>
        );
    }

    if (data) {
        return (
            <ScrollableWrapper>
                <Table>
                    <Thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <Tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <Th
                                        key={header.id}
                                        style={{
                                            width:
                                                header.getSize() !== 150
                                                    ? `${header.getSize()}px`
                                                    : 'auto',
                                        }}
                                        onClick={header.column.getToggleSortingHandler()}
                                        $isSortable={header.column.getCanSort()}
                                    >
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                  header.column.columnDef.header,
                                                  header.getContext(),
                                              )}
                                    </Th>
                                ))}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody>
                        {table.getRowModel().rows.map((row) => (
                            <Tr key={row.id}>
                                {row.getVisibleCells().map((cell) => (
                                    <Td key={cell.id}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </Td>
                                ))}
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </ScrollableWrapper>
        );
    }

    return <PersonalAccessTokenTableSkeleton />;
};
const ScrollableWrapper = styled.div`
    max-height: 27.5rem;
    overflow-y: auto;
`;
const Table = styled.table`
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
`;
const Td = styled.td`
    height: 3.5rem;
    padding-inline: 1rem;

    &:last-of-type {
        padding-inline: 0;
    }
`;
const Tr = styled.tr``;
const Th = styled.th<{ $isSortable?: boolean }>`
    padding-inline: 1rem;
    text-align: left;

    ${({ $isSortable }) =>
        $isSortable &&
        `
        cursor: pointer;
    `}

    &:last-of-type {
        padding-inline: 0;
    }
`;
const Tbody = styled.tbody`
    ${Tr} {
        border-bottom: 1px solid var(--neutral100);
    }
`;
const Thead = styled.thead`
    position: sticky;
    top: 0;
    width: 100%;
    background-color: var(--neutral50);
    height: 3rem;
    z-index: 1;

    ${Tr} > ${Th}:first-of-type {
        border-top-left-radius: var(--r-m);
        border-bottom-left-radius: var(--r-m);
    }
    ${Tr} > ${Th}:last-of-type {
        border-top-right-radius: var(--r-m);
        border-bottom-right-radius: var(--r-m);
    }
`;
const SortIcon = styled(FontAwesomeIcon)<{ $isVisible?: boolean }>`
    color: var(--neutral500);
    width: 0.75rem;
    height: 0.75rem;
    display: none;

    ${({ $isVisible }) =>
        $isVisible &&
        `
        display: block;
    `}
`;
const TableHeader = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.25rem;

    &:hover {
        ${SortIcon} {
            display: block;
        }
    }
`;

export default PersonalAccessTokenTable;
