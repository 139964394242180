import jioSaavn from '@public/images/platforms/JioSaavn.png';
import ShortListFallback from '@/components/unorganized/ShortListFallback';
import BroadcastCard from '../../BroadcastCard';
import { JIO_SAAVN, LISTENING_PLATFORMS_NAMES } from '@/shared/config/constants';
import { useParams } from 'react-router-dom';
import useShowQuery from '@queries/show/useShowQuery.hook';

const JioSaavnBroadcast = () => {
    const { showId } = useParams<{ showId: string }>();
    const { data: show, isLoading } = useShowQuery(showId);
    const url = show?.listeningLinks?.data.find((link) => link.key === JIO_SAAVN)?.url;

    if (isLoading) return <ShortListFallback />;

    return (
        <BroadcastCard
            platformKey={JIO_SAAVN}
            name={LISTENING_PLATFORMS_NAMES[JIO_SAAVN]}
            logo={jioSaavn}
            url={url}
        />
    );
};

export default JioSaavnBroadcast;
