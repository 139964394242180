import { useEffect, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import useEpisodeQuery from '@queries/episode/useEpisodeQuery.hook';
import useUserQuery from '@queries/user/useUserQuery.hook';
import useSubscriptionQuery from '@queries/subscription/useSubscriptionQuery.hook';
import NonHostingEpisodeForm from '@/components/NonHostingEpisodeForm';
import EpisodeEditFormContext from '@/context/EpisodeFormContext';
import useQuery from '@/shared/hooks/useQuery';
import NonHostingEpisodeFormLoader from './NonHostingEpisodeFormLoader';
import EpisodeEditFormPageSkeleton from '../EpisodeEditFormPage/EpisodeEditFormPageSkeleton';
import useChaptersQuery from '@/queries/chapter/useChaptersQuery.hook';
import useEpisodeMessagesQuery from '@/queries/episode/useEpisodeMessagesQuery';

const NonHostingEpisodeFormPage = () => {
    const { episodeId } = useParams<{ episodeId: string }>();
    const episodeQuery = useEpisodeQuery({ episodeId, config: { staleTime: 0 } });
    const chaptersQuery = useChaptersQuery({ episodeId });
    const messagesQuery = useEpisodeMessagesQuery(episodeId);
    const userQuery = useUserQuery();
    const subscriptionQuery = useSubscriptionQuery();
    const LOADER_TIMEOUT = 3000;
    const isError =
        episodeQuery.isError ||
        userQuery.isError ||
        subscriptionQuery.isError ||
        messagesQuery.isError;
    const hasData =
        !!episodeQuery.data && !!userQuery.data && !!subscriptionQuery.data && !!messagesQuery.data;
    const query = useQuery();
    const hasLoader = query.get('loader') === 'true';
    const [isLoading, setIsLoading] = useState(hasLoader);
    const history = useHistory();

    // Fake loading state
    useEffect(() => {
        if (!hasLoader) return;
        const timeout = setTimeout(() => {
            setIsLoading(false);
            query.delete('loader');
            query.delete('name');
            history.replace({ search: query.toString() });
        }, LOADER_TIMEOUT);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    if (isLoading) {
        return <NonHostingEpisodeFormLoader />;
    }

    if (isError) {
        return <Redirect to="/error" />;
    }

    if (hasData && episodeQuery.isFetchedAfterMount && episodeQuery.data) {
        return (
            <EpisodeEditFormContext episode={episodeQuery.data}>
                <NonHostingEpisodeForm
                    chapters={chaptersQuery.data ?? []}
                    messages={messagesQuery.data}
                />
            </EpisodeEditFormContext>
        );
    }

    // TODO Update this to more specific skeleton
    return <EpisodeEditFormPageSkeleton />;
};

export default NonHostingEpisodeFormPage;
