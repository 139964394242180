import styled, { css, keyframes } from 'styled-components';

const NavigationMenuSkeleton = () => {
    return (
        <NavigationMenu>
            <NavigationMenuShowSelect>
                <NavigationMenuImage />
                <NavigationMenuShow>
                    <NavigationMenuShowName />
                    <NavigationMenuShowState />
                </NavigationMenuShow>
            </NavigationMenuShowSelect>
            <Navigation>
                <NavigationItem>
                    <NavigationItemIcon />
                    <NavigationItemText />
                </NavigationItem>
                <NavigationItem>
                    <NavigationItemIcon />
                    <NavigationItemText />
                </NavigationItem>
                <NavigationItem>
                    <NavigationItemIcon />
                    <NavigationItemText />
                </NavigationItem>
                <NavigationItem>
                    <NavigationItemIcon />
                    <NavigationItemText />
                </NavigationItem>
                <NavigationItem>
                    <NavigationItemIcon />
                    <NavigationItemText />
                </NavigationItem>
                <NavigationItem>
                    <NavigationItemIcon />
                    <NavigationItemText />
                </NavigationItem>
            </Navigation>
            <InvisibleBlock />
        </NavigationMenu>
    );
};

const shimmer = keyframes`
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
`;

const shimmering = css`
    background: var(--neutral50);
    background-image: linear-gradient(
        to right,
        var(--neutral50) 0%,
        #edeef1 20%,
        var(--neutral50) 40%,
        var(--neutral50) 100%
    );
    background-repeat: no-repeat;
    background-size: 50rem 6.5rem;
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${shimmer};
    animation-timing-function: linear;
`;
const NavigationMenu = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    background-color: var(--white);
    height: 4rem;
    display: flex;
    padding-inline: 1rem;
    justify-content: space-between;
`;
const NavigationMenuShowSelect = styled.div`
    display: flex;
    align-items: center;
    column-gap: 1rem;
    max-width: 280px;
    flex-grow: 1;
`;
const NavigationMenuShow = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    flex-grow: 1;
`;
const NavigationMenuImage = styled.div`
    width: 2.5rem;
    height: 2.5rem;
    border-radius: var(--r-m);
    ${shimmering};
`;
const NavigationMenuShowName = styled.div`
    display: none;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: block;
        flex-grow: 1;
        height: 0.5rem;
        background-color: var(--neutral50);
        border-radius: var(--r-xs);
        ${shimmering};
    }
`;
const NavigationMenuShowState = styled.div`
    display: none;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: block;
        width: 5rem;
        height: 0.5rem;
        background-color: var(--neutral50);
        border-radius: var(--r-xs);
        ${shimmering};
    }
`;
const NavigationItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 0.25rem;
`;
const NavigationItemIcon = styled.div`
    width: 1.5rem;
    height: 1.5rem;
    background-color: var(--neutral50);
    border-radius: var(--r-xs);
    ${shimmering};
`;
const NavigationItemText = styled.div`
    display: none;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: block;
        width: 5rem;
        height: 0.5rem;
        background-color: var(--neutral50);
        border-radius: var(--r-xs);
        ${shimmering};
    }
`;
const Navigation = styled.div`
    display: flex;
    align-items: center;
    column-gap: 1.5rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        column-gap: 2rem;
    }
`;
const InvisibleBlock = styled.div`
    display: none;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: block;
        flex-grow: 1;
        align-self: stretch;
        max-width: 280px;
    }
`;

export default NavigationMenuSkeleton;
