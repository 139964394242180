import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import DeprecatedModal from '@ui/molecules/DeprecatedModal';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import DeprecatedButton from '@ui/atoms/DeprecatedButton';
import Spinner from '@ui/atoms/Spinner';
import useInternalRouteMapping from '@hooks/useInternalRouteMapping.hook';
import { CurrentShow } from '@/context/CurrentShowContext';
import useDeleteShowMutation from '@/queries/show/useDeleteShowMutation.hook';
import type { Show } from '@queries/show/useShowQuery.hook';
import styled from 'styled-components';

interface ShowDeleteModalProps {
    onClose: () => void;
    isOpened: boolean;
    show: Show | null;
}

const ShowDeleteModal = ({ onClose, isOpened, show }: ShowDeleteModalProps) => {
    const deleteShow = useDeleteShowMutation();
    const { push } = useInternalRouteMapping();
    const currentShow = useContext(CurrentShow);

    const onDelete = async () => {
        if (!show?.id) return;
        // Delete show
        deleteShow.mutate(
            { showId: `${show?.id}` },
            {
                onSuccess: () => {
                    // Remove this show from the current show context
                    currentShow?.setShowId && currentShow.setShowId('');
                    // Redirect to shows list
                    push('show.all');
                },
            },
        );
    };

    if (!show) {
        return null;
    }

    return (
        <DeprecatedModal
            isOpened={isOpened}
            title={<FormattedMessage defaultMessage="Supprimer mon émission" />}
            onClose={onClose}
        >
            <ModalContentWrapper>
                <ModalContentInnerWrapper>
                    <DeprecatedText mb={20}>
                        <FormattedMessage defaultMessage="Souhaitez-vous supprimer votre émission ?" />
                    </DeprecatedText>
                    <DeprecatedText>
                        <FormattedMessage defaultMessage="Cette action est irréversible, et toutes les données seront effacées." />
                    </DeprecatedText>
                </ModalContentInnerWrapper>
                <ActionWrapper>
                    <DeprecatedButton accent="secondary" onClick={onClose}>
                        <FormattedMessage defaultMessage="Annuler" />
                    </DeprecatedButton>
                    <DeprecatedButton ml={10} disabled={deleteShow.isLoading} onClick={onDelete}>
                        {deleteShow.isLoading && <Spinner />}
                        <FormattedMessage defaultMessage="Supprimer mon émission" />
                    </DeprecatedButton>
                </ActionWrapper>
            </ModalContentWrapper>
        </DeprecatedModal>
    );
};

const ModalContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
`;
const ModalContentInnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
const ActionWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export default ShowDeleteModal;
