import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateEmail } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import { EmailFormSchema } from '@/components/unorganized/ProfileEdit/ProfileForm/EmailForm/schema';
import userKeys from './userKeys';

const mutationFn = async (formData: EmailFormSchema) => {
    const { data } = await updateEmail(formData);

    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

export default function updateEmailMutation() {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: userKeys.detail() });
        },
    });
}
