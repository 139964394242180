import Button from '@/components/Button';
import RouterLink from '@/components/Link/RouterLink';
import Modal from '@ui/atoms/Modal';
import Text from '@ui/atoms/Text';
import Stack from '@ui/layout/Stack';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import useHasTutorial from '@/shared/hooks/useHasTutorial.hook';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AMAZON_MUSIC, ListeningPlatform } from '@/shared/config/constants';
import AmazonCustomConfirmModal from './AmazonCustomConfirmModal';
import useBroadcastMutation from '@queries/show/useBroadcastMutation.hook';
import styled from 'styled-components';

interface ConfirmModalProps {
    platformKey: ListeningPlatform;
    platformName: string;
    isOpen: boolean;
    onOpenChange: (isOpen: boolean) => void;
}

const ConfirmModal = ({ platformKey, platformName, isOpen, onOpenChange }: ConfirmModalProps) => {
    const { showId } = useParams<{ showId: string }>();
    const platformHasTutorial = useHasTutorial(platformKey);
    const broadcast = useBroadcastMutation();

    /**
     * Amazon Music has a custom broadcast logic.
     * We need to display a different modal for this platform specifically.
     */
    if (platformKey === AMAZON_MUSIC) {
        return <AmazonCustomConfirmModal isOpen={isOpen} onOpenChange={onOpenChange} />;
    }

    return (
        <ModalStyled isOpen={isOpen} onOpenChange={onOpenChange} size="small">
            <Header>
                <Title>
                    <FormattedMessage
                        defaultMessage="Souhaitez-vous diffuser sur {broadcastName} ?"
                        values={{ broadcastName: platformName }}
                    />
                </Title>
                <CloseButton
                    startIcon={<FontAwesomeIcon icon={icon({ name: 'xmark', style: 'solid' })} />}
                    onPress={() => onOpenChange(false)}
                    variant="ghost"
                    aria-label="Close modal"
                />
            </Header>

            <Stack $gap="0.25rem">
                <Text>
                    {platformHasTutorial ? (
                        <FormattedMessage
                            defaultMessage="Pour diffuser votre émission sur {broadcastName}, suivez simplement le tutoriel que nous vous avons préparé."
                            values={{
                                broadcastName: platformName,
                            }}
                        />
                    ) : (
                        <FormattedMessage defaultMessage="Vous apparaîtrez sur la plateforme d’ici quelques heures." />
                    )}
                </Text>
                <Text>
                    <FormattedMessage
                        defaultMessage="Votre podcast est déjà sur {broadcastName} ? {action}"
                        values={{
                            broadcastName: platformName,
                            action: (
                                <QuickBroadcastLink
                                    to={`/app/show/${showId}/settings/broadcast/platform/${platformKey}`}
                                    $color={`--${platformKey}`}
                                >
                                    <FormattedMessage defaultMessage="Cliquez-ici" />
                                </QuickBroadcastLink>
                            ),
                        }}
                    />
                </Text>
            </Stack>
            <Actions>
                {platformHasTutorial ? (
                    <BroadcastLink
                        to={`/app/show/${showId}/settings/broadcast/platform/${platformKey}?startWithTutorial=true`}
                        variant="button-primary"
                        $color={`--${platformKey}`}
                    >
                        <FormattedMessage defaultMessage="Commencer le tutoriel" />
                    </BroadcastLink>
                ) : (
                    <BroadcastButton
                        onPress={() => {
                            broadcast.mutate({
                                showId,
                                platform: platformKey,
                            });
                        }}
                        $color={`--${platformKey}`}
                        isLoading={broadcast.isLoading}
                    >
                        <FormattedMessage
                            defaultMessage="Diffuser sur {broadcastName}"
                            values={{ broadcastName: platformName }}
                        />
                    </BroadcastButton>
                )}

                <Button variant="link-secondary" onPress={() => onOpenChange(false)}>
                    <FormattedMessage defaultMessage="Annuler" />
                </Button>
            </Actions>
        </ModalStyled>
    );
};

const ModalStyled = styled(Modal)`
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1.5rem;
`;
const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;
const Title = styled.h1`
    font-size: var(--fs-body-l);
`;
const CloseButton = styled(Button)`
    transition-duration: 0.2s;
    padding: 0.5rem;
    color: var(--neutral500);
    &:hover {
        background-color: var(--neutral50);
        color: var(--neutral700);
    }
`;
const Actions = styled.div`
    align-self: stretch;
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 1rem;

    & > :first-child {
        align-self: stretch;
    }
`;
const QuickBroadcastLink = styled(RouterLink)<{ $color: string }>`
    color: var(${(props) => props.$color});
`;
const BroadcastLink = styled(RouterLink)<{ $color: string }>`
    background-color: var(${(props) => props.$color}50);
    color: var(${(props) => props.$color});
    box-shadow: none;

    &:hover {
        background-color: var(${(props) => props.$color}100);
    }
`;
const BroadcastButton = styled(Button)<{ $color: string }>`
    background-color: var(${(props) => props.$color}50);
    color: var(${(props) => props.$color});
    box-shadow: none;

    &:hover {
        background-color: var(${(props) => props.$color}100);
    }
`;

export default ConfirmModal;
