import useUserQuery from '@queries/user/useUserQuery.hook';
import useHasPsoFullUserOption from './useHasPsoFullUserOption.hook';

interface useHasAccessToPsoCompetitorsProps {
    showId: string;
}

const useHasAccessToPsoKeywordsRanking = ({
    showId,
}: useHasAccessToPsoCompetitorsProps): boolean => {
    const { data: user } = useUserQuery();
    const hasPsoFull = useHasPsoFullUserOption({ showId });

    // This user option is only use during development
    // TODO: Remove this user option when feature is launched
    const featureIsVisible = user?.options?.includes('pso_keyword_rankings');

    return (featureIsVisible && hasPsoFull) || false;
};

export default useHasAccessToPsoKeywordsRanking;
