import { QueryFunctionContext, UseQueryResult, useQuery } from '@tanstack/react-query';
import { fetchPlaylists } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import playlistKeys from '@/queries/playlist/playlistKeys';

export interface Playlist {
    createdAt: string;
    htmlDescription: string;
    id: number;
    imageUrl: string | null;
    name: string;
    password: string | null;
    podcastsCount: number;
    privacy: string;
    publicId: string;
    showId: number;
    slug: string;
    updatedAt: string;
    url: string;
}

const transform = (data: unknown) => {
    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data as any, { deep: true });
};

const queryFn = async ({
    queryKey: [{ showId }],
}: QueryFunctionContext<ReturnType<typeof playlistKeys.listByShowId>>) => {
    const { data } = await fetchPlaylists(showId);

    return transform(data);
};

const usePlaylistsQuery = (showId: string): UseQueryResult<Playlist[]> => {
    return useQuery({
        queryKey: playlistKeys.listByShowId(showId),
        queryFn,
        enabled: !!showId,
    });
};

export default usePlaylistsQuery;
