import ContentLoader from 'react-content-loader';
import { connect } from '@app/decorators/connect';
import { FormattedMessage } from 'react-intl';
import ApplePodcastsCategorySelector from './ApplePodcastsCategorySelector';
import createFallback from '../fallbacks/createFallback';
import DeprecatedPaper from '../../ui/atoms/DeprecatedPaper';
import DeprecatedText from '../../ui/atoms/DeprecatedText';

const ApplePodcastsCategorySelectorFallback = createFallback((props) => (
    <DeprecatedPaper flex mt={5}>
        <ContentLoader width={100} height={20} style={{ width: 100, height: 20 }} {...props}>
            <rect x="0" y="0" rx="5" ry="5" width="100" height="14" />
        </ContentLoader>
    </DeprecatedPaper>
));

const enhance = connect(({ routerStore }) => {
    const show = routerStore.resolve('showId');
    return {
        name: show && show.name,
    };
});

function ApplePodcastsHeader({ isLoading, name }) {
    return (
        <>
            {isLoading || !name ? (
                <ApplePodcastsCategorySelectorFallback />
            ) : (
                <DeprecatedPaper flex wrap="wrap" mt={5} z={4} align="flex-end">
                    <DeprecatedText mr={5}>{`${name} -`}</DeprecatedText>
                    <ApplePodcastsCategorySelector />
                </DeprecatedPaper>
            )}
            <DeprecatedText color="--neutral500" mt={10} mb={25}>
                <FormattedMessage defaultMessage="Les statistiques iTunes concernent uniquement les podcasts présents au moins une fois dans le classement Apple Podcasts, qui est limité à 200 podcasts par catégorie." />
            </DeprecatedText>
        </>
    );
}

ApplePodcastsHeader.defaultProps = {
    isLoading: false,
};

export default enhance(ApplePodcastsHeader);
