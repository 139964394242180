import posed, { PoseGroup } from 'react-pose';
import { FormattedMessage } from 'react-intl';
import { useStripe } from '@stripe/react-stripe-js';
import Link from '@ui/atoms/Link';
import DeprecatedButton from '@ui/atoms/DeprecatedButton';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import { connect } from '@/components/legacy/connect';
import Spinner from '@ui/atoms/Spinner';

const Band = posed(DeprecatedPaper)({
    enter: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -200 },
});

const Filler = () => <DeprecatedPaper w={30} />;

const enhance = connect(({ routerStore, subscriptionStore }) => {
    const subscription = subscriptionStore.subscription;
    return {
        subscription,
        validatePayment: (stripe) =>
            subscriptionStore.handlePaymentValidation(subscription, stripe),
        isPaymentValidationLoading: subscriptionStore.confirmStripePayment.pending,
        updatePaymentMethodLink: routerStore.link('user.edit.show.tab', { tab: 'subscription' }),
    };
});

const Content = ({
    subscription,
    validatePayment,
    isPaymentValidationLoading,
    updatePaymentMethodLink,
}) => {
    const stripe = useStripe();

    return (
        <PoseGroup>
            <Band key={0} y={null} yR={25} pos="fixed" flex justify="center">
                <DeprecatedPaper
                    p={5}
                    w="fit-content"
                    maxW="95%"
                    background="--alert"
                    raise="normal"
                    align="center"
                    justify="center"
                    rounded
                    flex
                    cssAnim
                    wrap="wrap"
                >
                    {subscription?.intentSecret !== undefined ? (
                        <>
                            <DeprecatedText
                                align="center"
                                as="span"
                                color="white"
                                weight="semibold"
                                m={5}
                            >
                                <FormattedMessage defaultMessage="Veuillez valider votre moyen de paiement." />
                            </DeprecatedText>
                            <DeprecatedButton
                                onClick={() => validatePayment(stripe)}
                                background="white"
                                color="--alert"
                                m={5}
                            >
                                {isPaymentValidationLoading ? (
                                    <Spinner size={20} color="--alert" />
                                ) : (
                                    <Filler />
                                )}
                                <FormattedMessage defaultMessage="Commencer la validation" />
                                <Filler />
                            </DeprecatedButton>
                        </>
                    ) : (
                        <>
                            <DeprecatedText
                                align="center"
                                as="span"
                                color="white"
                                weight="semibold"
                                m={5}
                            >
                                <FormattedMessage defaultMessage="La validation 3D Secure 2 a échoué. Veuillez mettre à jour vos informations de paiement." />
                            </DeprecatedText>
                            <DeprecatedButton
                                as={Link}
                                to={updatePaymentMethodLink}
                                background="white"
                                color="--alert"
                                m={5}
                            >
                                <FormattedMessage defaultMessage="Mettre à jour" />
                            </DeprecatedButton>
                        </>
                    )}
                </DeprecatedPaper>
            </Band>
        </PoseGroup>
    );
};

const EnhancedContent = enhance(Content);

const paymentValidationRequiredBandEnhancer = connect(({ subscriptionStore }) => {
    const subscription = subscriptionStore.subscription;
    return { subscription };
});

function PaymentValidationRequiredBand({ subscription }) {
    if (subscription?.incomplete) {
        return <EnhancedContent />;
    }

    return null;
}

export default paymentValidationRequiredBandEnhancer(PaymentValidationRequiredBand);
