import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateYoutubeBroadcastSettings } from '@/api';

export default function useUpdateYoutubeBroadcastSettings(showId) {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (formData) => {
            if (!showId) return;
            return updateYoutubeBroadcastSettings(showId, formData);
        },
        /**
         * Always refetch after error or success:
         */
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['shows.youtube.show', showId] });
        },
    });
}
