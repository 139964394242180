import { computed } from 'mobx';
import Model from './Model';
import { apiCall } from '../components/app/decorators/api';
import { date, initial } from './resolvers';
import PostPodcastModel from './PostPodcastModel';
import PostClipModel from './PostClipModel';

class SharingTemplateModel extends Model {
    static default = ({ autoSharingStore }) => ({
        pageId: autoSharingStore.facebookPages[0] ? autoSharingStore.facebookPages[0].id : null,
        userId: autoSharingStore.instagramAccount?.id || null,
        accountId: autoSharingStore.linkedinAccount?.id || null,
        type: '',
        title: '',
        text: '',
        clipFormatId: null,
        imageUrl: null,
        name: '',
        provider: '',
        podcast: {},
        externalUrl: '',
        activated: false,
        clip: {},
        state: '',
        publishAt: '',
        publishedAt: '',
        createdAt: '',
    });

    static attributes = {
        id: 'id',
        pageId: 'page_id',
        userId: 'user_id',
        accountId: 'account_id',
        type: 'type',
        clipFormatId: 'clip_format_id',
        imageUrl: 'image_url',
        title: 'title',
        text: initial('message_tpl', ''),
        name: 'account',
        provider: 'provider',
        podcast: {
            fromApi: {
                key: 'podcast',
                resolve: (x, store) => new PostPodcastModel(store, x),
            },
        },
        clip: {
            fromApi: {
                key: 'clip',
                resolve: (x, store) => new PostClipModel(store, x),
            },
        },
        externalUrl: 'external_url',
        sharedUrl: 'shared_url',
        activated: 'activated',
        status: 'state',
        publishAt: date('publish_at'),
        publishedAt: date('published_at'),
        createdAt: date('created_at'),
    };

    @computed
    get resolvedText() {
        const resolvedTemplate = this.text.replace(
            /{{(.*?)}}/g,
            (_, value) =>
                ({
                    'show.name': this.state.routerStore.resolve('showId')?.name || '',
                    'episode.name': this.state.routerStore.resolve('podId')?.name || '',
                    'episode.tags':
                        this.state.routerStore
                            .resolve('podId')
                            ?.tags.map((t) => `#${t.name}`)
                            .join(' ') || '',
                }[value]),
        );

        return resolvedTemplate.trim();
    }

    resolvedTextWithEpisode(episode) {
        const resolvedTemplate = this.text.replace(
            /{{(.*?)}}/g,
            (_, value) =>
                ({
                    'show.name': this.state.routerStore.resolve('showId')?.name || '',
                    'episode.name': episode?.name || '',
                    'episode.tags': episode?.tags.map((t) => `#${t.name}`).join(' ') || '',
                }[value]),
        );

        return resolvedTemplate.trim();
    }

    @apiCall
    updateShowFacebookTemplate(showId, formData) {
        return this.store.updateShowTemplate(this, 'facebook', showId, formData);
    }

    @apiCall
    updateShowTwitterTemplate(showId, formData) {
        return this.store.updateShowTemplate(this, 'twitter', showId, formData);
    }

    @apiCall
    updateShowLinkedinTemplate(showId, formData) {
        return this.store.updateShowTemplate(this, 'linkedin', showId, formData);
    }

    @apiCall
    updateShowInstagramTemplate(showId, formData) {
        return this.store.updateShowTemplate(this, 'instagram', showId, formData);
    }

    @apiCall
    updateShowNewsletterTemplate(showId, formData) {
        return this.store.updateShowTemplate(this, 'newsletter', showId, formData);
    }

    @apiCall
    updatePodcastNewsletter(podId, formData) {
        return this.store.updatePodcastNewsletterTemplate(this, podId, formData);
    }

    @apiCall
    unlinkFacebook(showId) {
        return this.store.unlinkSharing(showId, 'facebook');
    }

    @apiCall
    unlinkTwitter(showId) {
        return this.store.unlinkSharing(showId, 'twitter');
    }

    @apiCall
    unlinkLinkedin(showId) {
        return this.store.unlinkSharing(showId, 'linkedin');
    }

    @apiCall
    unlinkInstagram(showId) {
        return this.store.unlinkSharing(showId, 'instagram');
    }
}

export default SharingTemplateModel;
