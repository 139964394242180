import styled from 'styled-components';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import Text from '@/components/ui/atoms/Text';
import { LINKS_NAMES } from '@/components/CallToAction';

const CTADragAndDropItem = ({ item, isDragging }) => {
    const ICON_LIST_CTA = {
        [LINKS_NAMES.PAGE_PODCAST]: <LinkIcon icon={icon({ name: 'globe', style: 'regular' })} />,
        [LINKS_NAMES.SMARTLINK]: <LinkIcon icon={icon({ name: 'link', style: 'solid' })} />,
        [LINKS_NAMES.NEWSLETTER]: (
            <LinkIcon icon={icon({ name: 'envelope-open-text', style: 'solid' })} />
        ),
        [LINKS_NAMES.CROWDFUNDING]: <LinkIcon icon={icon({ name: 'heart', style: 'regular' })} />,
        [LINKS_NAMES.CUSTOM]: (
            <LinkIcon icon={icon({ name: 'arrow-up-right-from-square', style: 'solid' })} />
        ),
    };

    return (
        <DraggableItem $isDragging={isDragging}>
            <DraggableItemIcon icon={icon({ name: 'grip-lines', style: 'solid' })} />
            <Divider />
            <DraggableItemContent>
                <DraggableItemName>
                    {ICON_LIST_CTA[item.type]}
                    <Text fontWeight="--fw-semibold">{item.text}</Text>
                </DraggableItemName>
                <DraggableItemUrl numberOfLines={1}>{item.url}</DraggableItemUrl>
            </DraggableItemContent>
        </DraggableItem>
    );
};

const DraggableItem = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.75rem;
    padding: 0.75rem;
    border-radius: var(--r-s);
    background: var(--white);
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.05);
    transition: all 0.2s;

    ${({ $isDragging }) => $isDragging && `transform: rotate(5deg);`}

    &:hover {
        > svg {
            fill: var(--neutral500);
        }
    }
`;
const DraggableItemIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--neutral500);
`;
const Divider = styled.hr`
    width: 1px;
    align-self: stretch;
    background-color: var(--neutral100);
`;
const DraggableItemContent = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;
    transition: all 0.2s;
    min-width: 0;
`;
const DraggableItemName = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
`;
const LinkIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const DraggableItemUrl = styled(Text)`
    color: var(--neutral500);
    min-width: 0;
    flex-grow: 1;
`;

CTADragAndDropItem.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.number,
        text: PropTypes.string,
        url: PropTypes.string,
        type: PropTypes.string,
    }).isRequired,
    isDragging: PropTypes.bool.isRequired,
};

export default CTADragAndDropItem;
