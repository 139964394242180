import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import mapKeys from 'lodash.mapkeys';
import camelCase from 'lodash.camelcase';
import Spinner from '@ui/atoms/Spinner';
import { CLIP_FORMATS, IMG_PLACEHOLDER } from '@/shared/config/constants';
import AushaLogo from '@ui/atoms/AushaLogo';
import useShowQuery from '@queries/show/useShowQuery.hook';

const StyledLogo = styled(motion(AushaLogo))`
    fill: white;
`;

const transition = { type: 'spring', bounce: 0 };

const DEFAULT_HEIGHT = 700;
const config = {
    square: {
        ratio: 1,
        cover: {
            partial: {
                size: 490 / DEFAULT_HEIGHT,
                position: {
                    left: 105 / DEFAULT_HEIGHT,
                    top: 210 / DEFAULT_HEIGHT,
                },
            },
            full: {
                size: DEFAULT_HEIGHT / DEFAULT_HEIGHT,
                position: {
                    left: 0 / DEFAULT_HEIGHT,
                    top: 0 / DEFAULT_HEIGHT,
                },
            },
        },
        logo: {
            size: {
                height: 30 / DEFAULT_HEIGHT,
                width: 670 / DEFAULT_HEIGHT,
            },
            position: {
                left: 15 / DEFAULT_HEIGHT,
                top: 15 / DEFAULT_HEIGHT,
            },
        },
        caption: {
            size: 640 / DEFAULT_HEIGHT,
            font: {
                size: 24 / DEFAULT_HEIGHT,
                weight: 'normal',
            },
            position: {
                left: 30 / DEFAULT_HEIGHT,
                top: 50 / DEFAULT_HEIGHT,
            },
        },
        title: {
            size: 640 / DEFAULT_HEIGHT,
            font: {
                size: 36 / DEFAULT_HEIGHT,
                weight: 'semibold',
            },
            position: {
                left: 30 / DEFAULT_HEIGHT,
                top: 90 / DEFAULT_HEIGHT,
            },
            maxLines: 2,
        },
        transcription: {
            size: 670 / DEFAULT_HEIGHT,
            font: {
                size: 36 / DEFAULT_HEIGHT,
                weight: 'semibold',
            },
            position: {
                bottom: 30 / DEFAULT_HEIGHT,
            },
        },
    },
    wide: {
        ratio: 1334 / DEFAULT_HEIGHT,
        cover: {
            partial: {
                size: 610 / DEFAULT_HEIGHT,
                position: {
                    left: 679 / DEFAULT_HEIGHT,
                    top: 45 / DEFAULT_HEIGHT,
                },
            },
            full: {
                size: 1334 / DEFAULT_HEIGHT,
                position: {
                    left: 0 / DEFAULT_HEIGHT,
                    top: -317 / DEFAULT_HEIGHT,
                },
            },
        },
        logo: {
            size: {
                height: 30 / DEFAULT_HEIGHT,
                width: 610 / DEFAULT_HEIGHT,
            },
            position: {
                left: 25 / DEFAULT_HEIGHT,
                top: 25 / DEFAULT_HEIGHT,
            },
        },
        caption: {
            size: 609 / DEFAULT_HEIGHT,
            font: {
                size: 30 / DEFAULT_HEIGHT,
                weight: 'normal',
            },
            position: {
                left: 25 / DEFAULT_HEIGHT,
                top: 105 / DEFAULT_HEIGHT,
            },
        },
        title: {
            size: 609 / DEFAULT_HEIGHT,
            font: {
                size: 60 / DEFAULT_HEIGHT,
                weight: 'semibold',
            },
            position: {
                left: 25 / DEFAULT_HEIGHT,
                top: 140 / DEFAULT_HEIGHT,
            },
            maxLines: 3,
        },
        transcription: {
            size: 1300 / DEFAULT_HEIGHT,
            font: {
                size: 36 / DEFAULT_HEIGHT,
                weight: 'semibold',
            },
            position: {
                bottom: 30 / DEFAULT_HEIGHT,
            },
        },
    },
    story: {
        ratio: 394 / DEFAULT_HEIGHT,
        cover: {
            partial: {
                size: 354 / DEFAULT_HEIGHT,
                position: {
                    left: 20 / DEFAULT_HEIGHT,
                    top: 230 / DEFAULT_HEIGHT,
                },
            },
            full: {
                size: DEFAULT_HEIGHT / DEFAULT_HEIGHT,
                position: {
                    left: -153 / DEFAULT_HEIGHT,
                    top: 0 / DEFAULT_HEIGHT,
                },
            },
        },
        logo: {
            size: {
                height: 30 / DEFAULT_HEIGHT,
                width: 354 / DEFAULT_HEIGHT,
            },
            position: {
                left: 20 / DEFAULT_HEIGHT,
                top: 50 / DEFAULT_HEIGHT,
            },
        },
        caption: {
            size: 300 / DEFAULT_HEIGHT,
            font: {
                size: 19 / DEFAULT_HEIGHT,
                weight: 'normal',
            },
            position: {
                left: 20 / DEFAULT_HEIGHT,
                top: 75 / DEFAULT_HEIGHT,
            },
        },
        title: {
            size: 354 / DEFAULT_HEIGHT,
            font: {
                size: 29 / DEFAULT_HEIGHT,
                weight: 'semibold',
            },
            position: {
                left: 20 / DEFAULT_HEIGHT,
                top: 100 / DEFAULT_HEIGHT,
            },
            maxLines: 2,
        },
        transcription: {
            size: (394 - 2 * 15) / DEFAULT_HEIGHT,
            font: {
                size: 20 / DEFAULT_HEIGHT,
                weight: 'semibold',
            },
            position: {
                bottom: 30 / DEFAULT_HEIGHT,
            },
        },
    },
};

const Container = styled(motion.div)`
    border-radius: var(--r-xs);
    height: ${(props) => props.height};
    opacity: ${(props) => (props.disabled ? 0.5 : 1)};
    overflow: hidden;
    position: relative;
    flex-shrink: 0;
`;

const Background = styled.div`
    background-image: url(${(props) => props.url});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(20px);
    height: 200%;
    left: -50%;
    position: absolute;
    top: -50%;
    width: 200%;
`;

const Overlay = styled.div`
    background: #000000;
    height: 100%;
    opacity: 0.4;
    position: absolute;
    width: 100%;
`;

const Cover = ({ clip, format, url, height }) => (
    <motion.img
        animate={
            clip.formats.find((item) => item.format === format)?.fullCover
                ? `${format}Full`
                : `${format}Partial`
        }
        css="position: absolute; max-width: initial; object-fit: cover;"
        initial={false}
        src={url}
        transition={transition}
        variants={{
            squarePartial: {
                height: `calc(${height} * ${config.square.cover.partial.size})`,
                left: `calc(${height} * ${config.square.cover.partial.position.left})`,
                top: `calc(${height} * ${config.square.cover.partial.position.top})`,
                width: `calc(${height} * ${config.square.cover.partial.size})`,
            },
            squareFull: {
                height: `calc(${height} * ${config.square.cover.full.size})`,
                left: `calc(${height} * ${config.square.cover.full.position.left})`,
                top: `calc(${height} * ${config.square.cover.full.position.top})`,
                width: `calc(${height} * ${config.square.cover.full.size})`,
            },
            widePartial: {
                height: `calc(${height} * ${config.wide.cover.partial.size})`,
                left: `calc(${height} * ${config.wide.cover.partial.position.left})`,
                top: `calc(${height} * ${config.wide.cover.partial.position.top})`,
                width: `calc(${height} * ${config.wide.cover.partial.size})`,
            },
            wideFull: {
                left: 0,
                top: 0,
                height: 'initial',
                width: `100%`,
            },
            storyPartial: {
                height: `calc(${height} * ${config.story.cover.partial.size})`,
                left: `calc(${height} * ${config.story.cover.partial.position.left})`,
                top: `calc(${height} * ${config.story.cover.partial.position.top})`,
                width: `calc(${height} * ${config.story.cover.partial.size})`,
            },
            storyFull: {
                height: `calc(${height} * ${config.story.cover.full.size})`,
                left: `calc(${height} * ${config.story.cover.full.position.left})`,
                top: `calc(${height} * ${config.story.cover.full.position.top})`,
                width: `calc(${height} * ${config.story.cover.full.size})`,
            },
        }}
    />
);

const LogoContainer = ({ format, height, children }) => {
    return (
        <motion.div
            css={`
                position: absolute;
                display: flex;
                justify-content: ${format === CLIP_FORMATS.LANDSCAPE ? 'flex-start' : 'flex-end'};
            `}
            initial={false}
            animate={format}
            transition={transition}
            variants={{
                square: {
                    height: `calc(${height} * ${config.square.logo.size.height})`,
                    top: `calc(${height} * ${config.square.logo.position.top})`,
                    left: `calc(${height} * ${config.square.logo.position.left})`,
                    width: `calc(${height} * ${config.square.logo.size.width})`,
                },
                wide: {
                    height: `calc(${height} * ${config.wide.logo.size.height})`,
                    top: `calc(${height} * ${config.wide.logo.position.top})`,
                    left: `calc(${height} * ${config.wide.logo.position.left})`,
                    width: `calc(${height} * ${config.wide.logo.size.width})`,
                },
                story: {
                    height: `calc(${height} * ${config.story.logo.size.height})`,
                    top: `calc(${height} * ${config.story.logo.position.top})`,
                    left: `calc(${height} * ${config.story.logo.position.left})`,
                    width: `calc(${height} * ${config.story.logo.size.width})`,
                },
            }}
        >
            {children}
        </motion.div>
    );
};

const Logo = ({ clip, format, isWhiteLabel, customLogoUrl, height }) => {
    if (!isWhiteLabel) {
        return (
            <LogoContainer clip={clip} format={format} height={height}>
                <StyledLogo />
            </LogoContainer>
        );
    }
    if (customLogoUrl) {
        return (
            <LogoContainer clip={clip} format={format} height={height}>
                <motion.img src={customLogoUrl} css="height: 100%;" layout />
            </LogoContainer>
        );
    }
    return null;
};

const TopWave = ({ color, height }) => (
    <motion.svg
        viewBox="0 0 700 136"
        preserveAspectRatio="none"
        initial={{ fill: color }}
        animate={{ fill: color }}
        transition={transition}
        css={`
            position: absolute;
            bottom: 0;
            height: calc(${height} * (136 / ${DEFAULT_HEIGHT}));
            width: 100%;
        `}
    >
        <path
            d="m864.071 401.401c-68.188 16.117-129.5 27.393-198.734 38.048-23.87 3.673-60.454 6.967-84.623 7.045-63.95.209-127.553-11.243-190.182-22.546-38.905-7.022-77.773-14.3-116.436-22.547v283.184h589.975z"
            transform="matrix(1.18649 0 0 .478825 -325.2126 -191.796)"
        />
    </motion.svg>
);

const MiddleWave = ({ color, height }) => (
    <motion.svg
        viewBox="0 0 700 195"
        preserveAspectRatio="none"
        initial={{ fill: color }}
        animate={{ fill: color }}
        transition={transition}
        css={`
            position: absolute;
            bottom: 0;
            height: calc(${height} * (195 / ${DEFAULT_HEIGHT}));
            width: 100%;
            opacity: 0.55;
        `}
    >
        <path
            d="m852.353 477.88c-15.024 9.568-30.484 22.356-49.281 23.418-30.362 1.716-59.808-9.566-90.211-6.261-11.261 1.224-32.541 7.592-43.296 10.838-39.046 11.783-67.817 21.395-107.227 31.959-17.566 4.709-31.423 7.636-49.747 6.79-30.551-1.409-58.934-17.134-85.443-30.346-20.959-10.445-43.561-26.524-67.986-29.25-29.462-3.289-58.04 12.738-81.531 28.506v155.925h574.722z"
            transform="matrix(1.21798 0 0 1.01786 -338.1493 -486.4146)"
        />
    </motion.svg>
);

const BottomWave = ({ color, height }) => (
    <motion.svg
        viewBox="0 0 700 188"
        preserveAspectRatio="none"
        initial={{ fill: color }}
        animate={{ fill: color }}
        transition={transition}
        css={`
            position: absolute;
            bottom: 0;
            height: calc(${height} * (188 / ${DEFAULT_HEIGHT}));
            width: 100%;
            opacity: 0.6;
        `}
    >
        <path
            d="m980.814 524.401c-22.542-11.574-45.65-24.953-71.427-27.818-24.037-2.671-47.576 5.644-70.519 10.97-47.007 10.913-94.12 22.575-142.225 27.696-39.07 4.159-74.494-17.505-108.5-33.271-16.145-7.485-32.844-16.154-51.058-17.125-29.058-1.55-46.796 27.912-74.065 32.699-13.921 2.444-22.324 2.351-36.401 2.139-26.346-.398-53.208-.033-79.033-6.292-19.737-4.784-37.091-11.593-57.099-15.373v204.583h690.327z"
            transform="matrix(1.01401 0 0 .86197 -294.55763 -417.628)"
        />
    </motion.svg>
);

const Waveform = ({ color, height }) => (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <BottomWave color={color} height={height} />
        <MiddleWave color={color} height={height} />
        <TopWave color={color} height={height} />
    </motion.div>
);

const Caption = ({ clip, format, height, children }) => (
    <motion.p
        css={`
            position: absolute;
            line-height: 1.25;
            overflow: hidden;
            // Display caption on single line with ellipsis
            white-space: nowrap;
            text-overflow: ellipsis;
        `}
        initial={false}
        transition={transition}
        animate={{
            top: `calc(${height} * ${config[format].caption.position.top})`,
            left: `calc(${height} * ${config[format].caption.position.left})`,
            width: `calc(${height} * ${config[format].caption.size})`,
            fontSize: `calc(${height} * ${config[format].caption.font.size})`,
            fontWeight: `${config[format].caption.font.weight}`,
            color: clip.titleColor,
        }}
    >
        {children}
    </motion.p>
);

const Title = ({ clip, format, height, children }) => (
    <motion.p
        css={`
            position: absolute;
            line-height: 1.25;
            overflow: hidden;
            // Display X lines of the title (https://css-tricks.com/line-clampin/)
            display: -webkit-box;
            -webkit-box-orient: vertical;
        `}
        initial={false}
        transition={transition}
        animate={{
            top: `calc(${height} * ${config[format].title.position.top})`,
            left: `calc(${height} * ${config[format].title.position.left})`,
            width: `calc(${height} * ${config[format].title.size})`,
            fontSize: `calc(${height} * ${config[format].title.font.size})`,
            fontWeight: `${config[format].title.font.weight}`,
            color: clip.titleColor,
            // Display X lines of the title (https://css-tricks.com/line-clampin/)
            webkitLineClamp: config[format].title.maxLines,
        }}
    >
        {children}
    </motion.p>
);

const Titles = ({ clip, format, height }) => (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <Caption clip={clip} format={format} height={height}>
            {clip.caption}
        </Caption>
        <Title clip={clip} format={format} height={height}>
            {clip.title}
        </Title>
    </motion.div>
);

const Transcription = ({ format, transcription, height }) => {
    const firstSubtitle = transcription?.subtitles[0]?.subtitle;

    if (!firstSubtitle) {
        return null;
    }

    return (
        <motion.div
            css={`
                position: absolute;
                width: 100%;
                display: flex;
                align-items: flex-end;
                justify-content: center;
            `}
            initial={false}
            animate={format}
            transition={transition}
            variants={{
                square: {
                    bottom: `calc(${height} * ${config.square.transcription.position.bottom})`,
                },
                wide: {
                    bottom: `calc(${height} * ${config.wide.transcription.position.bottom})`,
                },
                story: {
                    bottom: `calc(${height} * ${config.story.transcription.position.bottom})`,
                },
            }}
        >
            <motion.div
                css={`
                    background: rgba(0, 0, 0, 0.5);
                    padding: calc(${height} * (10 / ${DEFAULT_HEIGHT}))
                        calc(${height} * (15 / ${DEFAULT_HEIGHT}));
                `}
                initial={false}
                animate={format}
                transition={transition}
                variants={{
                    square: {
                        maxWidth: `calc(${height} * ${config.square.transcription.size})`,
                    },
                    wide: {
                        maxWidth: `calc(${height} * ${config.wide.transcription.size})`,
                    },
                    story: {
                        maxWidth: `calc(${height} * ${config.story.transcription.size})`,
                    },
                }}
            >
                <motion.p
                    css={`
                        color: #ffffff;
                        line-height: 1.1;
                        text-align: center;
                        overflow: hidden;
                        // Display 2 lines of transcription (https://css-tricks.com/line-clampin/)
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    `}
                    initial={false}
                    animate={format}
                    transition={transition}
                    variants={{
                        square: {
                            fontSize: `calc(${height} * ${config.square.transcription.font.size})`,
                            fontWeight: `${config.square.transcription.font.weight}`,
                        },
                        wide: {
                            fontSize: `calc(${height} * ${config.wide.transcription.font.size})`,
                            fontWeight: `${config.wide.transcription.font.weight}`,
                        },
                        story: {
                            fontSize: `calc(${height} * ${config.story.transcription.font.size})`,
                            fontWeight: `${config.story.transcription.font.weight}`,
                        },
                    }}
                >
                    {firstSubtitle}
                </motion.p>
            </motion.div>
        </motion.div>
    );
};

const Loading = () => (
    <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        css={`
            position: absolute;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(0, 0, 0, 0.5);
        `}
    >
        <Spinner size={80} />
    </motion.div>
);

const ClipPreview = ({
    clip,
    format,
    transcription,
    episodeImageUrl,
    height = `${DEFAULT_HEIGHT}px`,
    disabled,
    isLoading,
}) => {
    const { showId } = useParams();
    const { data: show } = useShowQuery(showId);

    const showImageUrl = show?.imageUrl;
    const isWhiteLabel = show?.whiteLabel;
    const darkLogoUrl = show?.darkLogoUrl;
    const lightLogoUrl = show?.lightLogoUrl;

    clip = mapKeys(clip, (value, key) => camelCase(key));
    // Convert to camelCase keys of clip formats
    clip.formats = clip.formats
        ? clip.formats.map((item) => mapKeys(item, (value, key) => camelCase(key)))
        : [];

    const customLogoUrl = darkLogoUrl || lightLogoUrl;
    const coverUrl =
        clip.formats
            .find((item) => item.format === format)
            ?.images?.find((image) => image.format === 'original')?.url ||
        episodeImageUrl ||
        showImageUrl ||
        IMG_PLACEHOLDER;

    return (
        <Container
            height={height}
            initial={false}
            animate={format}
            variants={{
                square: { width: `calc(${height} * ${config.square.ratio})` },
                wide: { width: `calc(${height} * ${config.wide.ratio})` },
                story: { width: `calc(${height} * ${config.story.ratio})` },
            }}
            transition={transition}
            disabled={disabled}
        >
            <Background url={coverUrl} />
            <Overlay />
            <Cover clip={clip} format={format} url={coverUrl} height={height} />
            <Logo
                clip={clip}
                format={format}
                isWhiteLabel={isWhiteLabel}
                customLogoUrl={customLogoUrl}
                height={height}
            />
            <AnimatePresence initial={false}>
                {clip.showWaveform && (
                    <Waveform key="waveform" color={clip.color} height={height} />
                )}
                {clip.showTitles && (
                    <Titles key="titles" clip={clip} format={format} height={height} />
                )}
                {clip.transcript && (
                    <Transcription
                        key="transcription"
                        clip={clip}
                        format={format}
                        transcription={transcription}
                        height={height}
                    />
                )}
                {isLoading && <Loading key="loading" />}
            </AnimatePresence>
        </Container>
    );
};

export default ClipPreview;
