import { useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import EpisodePreviewInformations from '../../unorganized/EpisodePreviewInformations';
import useListenersStatsQuery from '@/queries/stats/useListenersStatsQuery.hook';
import EpisodePreviewListenerGraph from './EpisodePreviewListenerGraph';
import EpisodePreviewShare from '../../unorganized/EpisodePreviewShare';
import EpisodeUsefulLinks from '../../unorganized/EpisodeUsefulLinks';
import DeprecatedPaper from '../../ui/atoms/DeprecatedPaper';
import { useStatsDateSelectorContext } from '@/context/StatsDateSelectorContext';
import { getLocalTimeZone } from '@internationalized/date';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import RouterLink from '@/components/Link/RouterLink';
import styled from 'styled-components';

const EpisodePreview = () => {
    const { showId, episodeId } = useParams();
    const { startDate, endDate } = useStatsDateSelectorContext();
    const { data: user } = useUserQuery();
    const timezone = useMemo(() => user?.timezone || getLocalTimeZone(), [user]);

    const { data: stats, isLoading } = useListenersStatsQuery({
        showId,
        start: startDate || '',
        end: endDate || '',
        precision: 'days',
        episodes: [episodeId],
        timezone,
    });

    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, []);

    return (
        <DeprecatedPaper maxW={1200} m="auto" justify="center" py={35} px={20}>
            <EpisodePreviewWrapper>
                <EpisodePreviewInformations isLoading={isLoading} />
                <GraphOuterWrapper>
                    <GraphOuterHeader>
                        <Title>
                            <FormattedMessage defaultMessage="Auditeurs" />
                        </Title>
                        <RouterLink
                            to={`/app/show/${showId}/stats/listeners?episode=${episodeId}`}
                            variant="button-tertiary"
                        >
                            <FormattedMessage defaultMessage="Voir toutes les statistiques" />
                        </RouterLink>
                    </GraphOuterHeader>
                    <EpisodePreviewListenerGraph stats={stats?.data || []} />
                </GraphOuterWrapper>
                <EpisodeUsefulLinks
                    isLoading={isLoading}
                    title={<FormattedMessage defaultMessage="Liens utiles" />}
                />
                <EpisodePreviewShare isLoading={isLoading} />
            </EpisodePreviewWrapper>
        </DeprecatedPaper>
    );
};

const EpisodePreviewWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;
const GraphOuterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;
const GraphOuterHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const Title = styled.h2`
    font-size: var(--fs-heading-m);
`;

export default EpisodePreview;
