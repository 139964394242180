import { useMemo } from 'react';
import { connect } from '@app/decorators/connect';
import { FormattedMessage } from 'react-intl';
import { useResponsive } from '@/utils/hooks/useResponsive';
import { useParams } from 'react-router-dom';
import PaperGrid from '../../ui/atoms/PaperGrid';
import DevicesFallback from '../fallbacks/DevicesFallback';
import Variation from './Variation';
import FetcherOverallStats from '../fetchers/stats/FetcherOverallStats';
import styled from 'styled-components';

const enhance = connect(({ listenersStatsStore }) => ({
    vars: listenersStatsStore.overall,
}));

const responsiveData = (responsive) => {
    if (responsive.isMobile)
        return { column: 5, scrollSnapType: 'x mandatory', overflowX: 'scroll', h: 100 };
    if (responsive.isTablet) return { w: '100%', column: 3, h: 'max-content', py: 20, rowGap: 20 };
    return { w: '100%', column: 5, h: 'max-content' };
};

const ListenersStatsOveralls = ({ vars }) => {
    const { isMobile, isTablet } = useResponsive();
    const { showId, itemType, itemId } = useParams();
    const resp = responsiveData({ isMobile, isTablet });
    useMemo(() => responsiveData({ isMobile, isTablet }), [isMobile, isTablet]);

    return (
        <ListenersStatsOverallsWrapper>
            <FetcherOverallStats
                showId={showId}
                itemType={itemType}
                itemId={itemId}
                fallback={DevicesFallback}
            >
                {(isLoading) =>
                    !isLoading &&
                    vars && (
                        <>
                            <PaperGrid align="center" {...resp}>
                                <Variation
                                    value={vars.today}
                                    average={vars.averageDays}
                                    step="today"
                                >
                                    <FormattedMessage defaultMessage="aujourd'hui" />
                                </Variation>
                                <Variation
                                    value={vars.lastWeek}
                                    average={vars.averageWeeks}
                                    step="lastWeek"
                                >
                                    <FormattedMessage defaultMessage="7 derniers jours" />
                                </Variation>
                                <Variation
                                    value={vars.lastMonth}
                                    average={vars.averageMonths}
                                    step="lastMonth"
                                >
                                    <FormattedMessage defaultMessage="30 derniers jours" />
                                </Variation>
                                <Variation value={vars.beforeLastMonth}>
                                    <FormattedMessage
                                        defaultMessage="{count} jours d'avant"
                                        values={{ count: 30 }}
                                    />
                                </Variation>
                                <Variation
                                    value={vars.fromStart}
                                    average={vars.averagePodcasts}
                                    step="fromStart"
                                >
                                    <FormattedMessage defaultMessage="depuis le début" />
                                </Variation>
                            </PaperGrid>
                        </>
                    )
                }
            </FetcherOverallStats>
        </ListenersStatsOverallsWrapper>
    );
};

const ListenersStatsOverallsWrapper = styled.div`
    position: relative;
    display: flex;
    min-height: 90px;
    align-items: center;
    flex-wrap: wrap;
    z-index: 3;
    padding: 2rem;
    border-radius: var(--r-l);
    background-color: var(--white);
`;

export default enhance(ListenersStatsOveralls);
