import { FormattedMessage, useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import { InputTextController } from '@ui/atoms/InputText';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { GUEST_USER_ROLES, USER_ROLES } from '@/utils/constants';
import useChannelGuestsInvitationFormSchema, {
    ChannelGuestsInvitationFormSchema,
} from './useChannelGuestsInvitationFormSchema.hook';
import { zodResolver } from '@hookform/resolvers/zod';
import { SelectController } from '@/components/ui/atoms/Select';
import ListBoxItem from '@/components/ui/atoms/ListBoxItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Button from '@/components/Button';
import addChannelGuestInvitationMutation from '@/queries/guest/addChannelGuestInvitationMutation';
import { Show } from '@/queries/show/useShowQuery.hook';
import MultipleShowsSelect from '@/components/MultipleShowsSelect';
import GuestRolesTooltip from '@/components/GuestRolesTooltip';

interface ChannelGuestsInvitationFormProps {
    onSubmit: (formData: ChannelGuestsInvitationFormSchema) => void;
    onClose: () => void;
    shows: Show[];
}

const ChannelGuestsInvitationForm = ({
    onSubmit,
    onClose,
    shows,
}: ChannelGuestsInvitationFormProps) => {
    const intl = useIntl();
    const schema = useChannelGuestsInvitationFormSchema();
    const { control, handleSubmit, formState } = useForm<ChannelGuestsInvitationFormSchema>({
        mode: 'onBlur',
        defaultValues: {
            email: '',
            role: USER_ROLES.VIEWER,
            message: '',
            shows: [],
        },
        resolver: zodResolver(schema),
    });
    const roleItems = Object.values({
        [GUEST_USER_ROLES.ADMIN]: {
            id: 'admin',
            name: intl.formatMessage({
                defaultMessage: 'Admin',
            }),
            icon: <RoleItemIcon icon={icon({ name: 'screwdriver-wrench', style: 'solid' })} />,
        },
        [GUEST_USER_ROLES.EDITOR]: {
            id: 'editor',
            name: intl.formatMessage({
                defaultMessage: 'Éditeur',
            }),
            icon: <RoleItemIcon icon={icon({ name: 'feather', style: 'solid' })} />,
        },
        [GUEST_USER_ROLES.VIEWER]: {
            id: 'viewer',
            name: intl.formatMessage({
                defaultMessage: 'Lecteur',
            }),
            icon: <RoleItemIcon icon={icon({ name: 'eye', style: 'solid' })} />,
        },
    });
    const showItems = shows
        .map((show) => ({
            id: show.id,
            name: show.name,
            image: show.imageUrl,
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
    const addInvitation = addChannelGuestInvitationMutation();

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <InputTextController
                control={control}
                name="email"
                label={<FormattedMessage defaultMessage="Adresse e-mail" />}
                placeholder={intl.formatMessage({ defaultMessage: 'neil.armstrong@nasa.com' })}
                isRequired={true}
            />
            <MultipleShowsSelect
                name="shows"
                control={control}
                items={showItems}
                isRequired={true}
            />
            <RoleSelect
                name="role"
                control={control}
                items={roleItems}
                label={<FormattedMessage defaultMessage="Rôle" />}
                isRequired={true}
                tooltip={<GuestRolesTooltip />}
            >
                {(item) => <ListBoxItem {...item} />}
            </RoleSelect>
            <InputTextController
                elementType="textarea"
                minHeight="152px"
                maxLength={400}
                control={control}
                name="message"
                label={<FormattedMessage defaultMessage="Message" />}
                placeholder={intl.formatMessage({ defaultMessage: 'Saisir votre message...' })}
                isOptional
            />
            <Divider />
            <Actions>
                <CancelButton variant="ghost" onPress={onClose}>
                    <FormattedMessage defaultMessage="Annuler" />
                </CancelButton>
                <Button
                    type="submit"
                    isDisabled={!formState.isDirty}
                    isLoading={addInvitation.isLoading}
                >
                    <FormattedMessage defaultMessage="Envoyer l'invitation" />
                </Button>
            </Actions>
        </Form>
    );
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    height: 100%;
`;
const Actions = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 1rem;
`;
const CancelButton = styled(Button)`
    color: var(--neutral500);
`;
const RoleItemIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
`;
const RoleSelect = styled(SelectController)`
    max-width: 100%;
    width: 100%;
`;
const Divider = styled.div`
    height: 1px;
    background-color: var(--neutral100);
    margin-top: 1rem;
    margin-bottom: 0.5rem;
`;

export default observer(ChannelGuestsInvitationForm);
