import { useParams, useHistory } from 'react-router-dom';
import routes from '@/routes.json';

const useInternalRouteMapping = () => {
    const history = useHistory();
    const params = useParams();

    const formatRouteParams = (routeName, args) => {
        const routeParams = { ...params, ...args };
        return routes[routeName].replace(/:(\w+)/gi, (_, e) => routeParams[e]);
    };

    const push = (routeName, args = {}) => {
        const route = formatRouteParams(routeName, args);
        history.push(route);
    };

    const replace = (routeName, args = {}) => {
        const route = formatRouteParams(routeName, args);
        history.replace(route);
    };

    const goBack = () => history.goBack();

    return { push, replace, link: formatRouteParams, goBack };
};

export default useInternalRouteMapping;
