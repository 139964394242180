import { useState } from 'react';
import copyToClipboard from 'copy-to-clipboard';

export function useCopy(): [boolean, (text: string) => void] {
    const [copied, setCopied] = useState<boolean>(false);

    function copy(text: string): void {
        setCopied(true);
        copyToClipboard(text);
        setTimeout(() => setCopied(false), 2000);
    }

    return [copied, copy];
}
