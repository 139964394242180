import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { fetchTranscriptShowQuota } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import { QUOTA_TYPES } from '@/shared/config/constants';
import quotaKeys from './quotaKeys';
import { TranscriptQuotaSchema } from './useTranscriptQuotaQuery.hook';

type Key = QueryFunctionContext<ReturnType<typeof quotaKeys.detailByShowAndType>>;

const queryFn = async ({ queryKey: [{ showId }] }: Key) => {
    const { data } = await fetchTranscriptShowQuota(showId);

    return TranscriptQuotaSchema.parse(
        camelcaseKeys(
            {
                maximum: data.maximum / 60,
                minimum: data.minimum / 60,
                step: data.step / 60,
                total: data.total / 60,
            },
            { deep: true },
        ),
    );
};

const useTranscriptShowQuotaQuery = (showId: string) => {
    return useQuery({
        queryKey: quotaKeys.detailByShowAndType(
            QUOTA_TYPES.TRANSCRIPT as keyof typeof QUOTA_TYPES,
            showId,
        ),
        queryFn,
        staleTime: 0,
    });
};

export default useTranscriptShowQuotaQuery;
