import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import Text from '../ui/atoms/Text';
import { FormattedMessage } from 'react-intl';
import { ICheckboxListItem } from './CheckboxListItem';

interface ImagePlaceholderProps {
    selectedItems: number[];
    items: ICheckboxListItem[];
}

const ImagePlaceholder = ({ selectedItems = [], items }: ImagePlaceholderProps) => {
    const allItemsSelected = items.length === selectedItems.length;

    if (allItemsSelected) {
        return (
            <PlaceholderWrapper>
                <PlaceholderIcon icon={icon({ name: 'rectangle-history', style: 'solid' })} />
                <FormattedMessage defaultMessage="Toutes les émissions" />
            </PlaceholderWrapper>
        );
    }

    const selectedItem =
        selectedItems.length === 1 ? items.find((item) => item.id === selectedItems[0]) : undefined;
    const noItemsSelected = selectedItems.length === 0;

    if (noItemsSelected) {
        return (
            <Text color="--neutral500">
                <FormattedMessage defaultMessage="Sélectionnez une ou plusieurs émissions" />
            </Text>
        );
    }

    if (selectedItem) {
        return (
            <PlaceholderWrapper>
                <PlaceholderImg src={selectedItem.image as string} />
                <Text>{selectedItem.name}</Text>
            </PlaceholderWrapper>
        );
    }

    return (
        <PlaceholderWrapper>
            <PlaceholderIcon icon={icon({ name: 'rectangle-history', style: 'solid' })} />
            <Text>
                <FormattedMessage
                    defaultMessage="{showsCount} émissions"
                    values={{
                        showsCount: selectedItems.length,
                    }}
                />
            </Text>
        </PlaceholderWrapper>
    );
};

const PlaceholderIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: var(--neutral500);
`;
const PlaceholderWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
`;
const PlaceholderImg = styled.img`
    width: 1.5rem;
    height: 1.5rem;
    border-radius: var(--r-xs);
    object-fit: cover;
`;

export default ImagePlaceholder;
