import { FormattedMessage } from 'react-intl';
import Modal from '@ui/molecules/Modal';
import DeleteAccountForm from '@app/organisms/DeleteAccountForm';

const DeleteAccountModal = ({ onClose, isOpen }) => (
    <Modal
        isOpen={isOpen}
        onClose={onClose}
        title={<FormattedMessage defaultMessage="Suppression du compte" />}
    >
        <DeleteAccountForm onClose={onClose} />
    </Modal>
);

export default DeleteAccountModal;
