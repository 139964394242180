import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Button from '@/components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import useImportedEpisodesQuery, {
    ImportedEpisode,
} from '@queries/rss/useImportedEpisodesQuery.hook';
import { useParams } from 'react-router';
import ServerPagination from '@ui/ServerPagination';
import useRetryAllImportMutation from '@queries/rss/useRetryAllImportMutation.hook';
import Datatable from './Datatable';
import { useBodyToastQueue } from '@/shared/hooks/useBodyToastQueue.hook';

const EPISODE_POLLING_RATE = 5000;

const ImportedEpisodes = () => {
    const { showId } = useParams<{ showId: string }>();
    const toast = useBodyToastQueue();

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(20);

    const {
        data: imported,
        isLoading,
        isError,
        refetch: refetchImportedEpisodes,
    } = useImportedEpisodesQuery({
        showId,
        pagination: { page, perPage },
        config: {
            refetchInterval: (data: any) => {
                return data?.importedEpisodes?.some((importedEpisode: ImportedEpisode) =>
                    ['processing', 'pending'].includes(importedEpisode.state),
                )
                    ? EPISODE_POLLING_RATE
                    : false;
            },
        },
    });

    const retryAllImports = useRetryAllImportMutation();
    const errorsCount = imported?.counters?.error ?? 0;

    const headerMessageValues = {
        imports: (
            <FormattedMessage
                defaultMessage="{count} {count, plural, one {import} other {imports}}"
                values={{ count: imported?.importedEpisodes?.length }}
            />
        ),
        errors: (
            <FormattedMessage
                defaultMessage="{count} {count, plural, one {erreur} other {erreurs}}"
                values={{ count: errorsCount }}
            />
        ),
    };

    if (isLoading || isError) {
        // TODO: Handle the error better
        return null;
    }

    return (
        <ImportedEpisodesWrapper>
            <Header>
                <Title>
                    <FormattedMessage defaultMessage="Épisodes importés" />
                </Title>

                <EpisodeNb>
                    {errorsCount > 0 ? (
                        <HeadingErrors>
                            <ErrorText>
                                <ImportsCount>
                                    <FormattedMessage
                                        defaultMessage="{count} {count, plural, one {import} other {imports}}"
                                        values={{ count: imported?.importedEpisodes?.length }}
                                    />
                                </ImportsCount>
                                <ImportErrorCount>
                                    <FormattedMessage
                                        defaultMessage="dont {count} {count, plural, one {erreur} other {erreurs}}"
                                        values={{ count: errorsCount }}
                                    />
                                </ImportErrorCount>
                            </ErrorText>
                            <Button
                                onPress={() => {
                                    retryAllImports.mutateAsync(
                                        {
                                            showId,
                                            episodeIds: imported?.importedEpisodes
                                                .filter((episode) => episode.state === 'error')
                                                .map((episode) => episode.id),
                                        },
                                        {
                                            onSuccess: () => {
                                                toast.success(
                                                    <FormattedMessage defaultMessage="L'import des épisodes en erreur a bien été relancé. Si l'erreur persiste, n'hésitez pas à contacter notre support. 🙏" />,
                                                );
                                            },
                                            onSettled: () => {
                                                refetchImportedEpisodes();
                                            },
                                        },
                                    );
                                }}
                                variant="danger"
                                isLoading={retryAllImports.isLoading}
                                startIcon={
                                    <RetryIcon
                                        icon={icon({
                                            name: 'arrows-rotate',
                                            style: 'solid',
                                        })}
                                    />
                                }
                                size="small"
                            >
                                <FormattedMessage defaultMessage="Rééssayer pour tous" />
                            </Button>
                        </HeadingErrors>
                    ) : (
                        <ImportsCount>
                            <FormattedMessage
                                defaultMessage="{imports}"
                                values={headerMessageValues}
                            />
                        </ImportsCount>
                    )}
                </EpisodeNb>
            </Header>
            <DatatableWrapper>
                <Datatable
                    rows={imported?.importedEpisodes}
                    triggerRefetch={() => {
                        refetchImportedEpisodes();
                    }}
                />
            </DatatableWrapper>
            <ServerPagination
                pagination={imported.pagination}
                onGoToPage={(page: number) => setPage(page)}
                onGoToFirstPage={() => setPage(1)}
                onGoToLastPage={() => setPage(imported.pagination.totalPages)}
                onGoToPreviousPage={() => setPage(imported.pagination.currentPage - 1)}
                onGoToNextPage={() => setPage(imported.pagination.currentPage + 1)}
                onChangePerPage={(perPage: number) => setPerPage(perPage)}
                options={{
                    goToFirstAndLastPageButtons: false,
                    showPaginationInfo: true,
                    showPageSelector: false,
                }}
            />
        </ImportedEpisodesWrapper>
    );
};

const ImportedEpisodesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;
const DatatableWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: 1rem;
`;
const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const Title = styled.h2`
    font-size: var(--fs-heading-s);
    font-weight: var(--fw-bold);
`;
const EpisodeNb = styled.div``;
const RetryIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: var(--alert500);
`;
const HeadingErrors = styled.div`
    display: flex;
    align-items: center;
    column-gap: 1rem;
`;
const ErrorText = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
`;
const ImportsCount = styled.span`
    font-weight: var(--fw-semibold);
`;
const ImportErrorCount = styled.span`
    font-weight: var(--fw-semibold);
    color: var(--alert);
`;

export default ImportedEpisodes;
