import ContentLoader from 'react-content-loader';
import createFallback from './createFallback';

function RingFallback({ theme, ...props }) {
    return (
        <>
            <svg
                width="200"
                height="200"
                style={{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                }}
            >
                <circle cx={100} cy={100} r={80} fill="var(--white)" />
            </svg>
            <ContentLoader
                width="100"
                height="100"
                {...props}
                style={{ width: '100%', height: '100%' }}
            >
                <circle cx={50} cy={50} r={50} />
            </ContentLoader>
        </>
    );
}

export default createFallback(RingFallback);
