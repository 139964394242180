import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { connect } from '@app/decorators/connect';
import DeprecatedText from '../../ui/atoms/DeprecatedText';
import RingChartWithLegend from '../../ui/organisms/RingChartWithLegend';
import { compose } from '../../../helpers';
import FetcherEpisodesTopRanking from '../fetchers/stats/FetcherEpisodesTopRanking';
import DeprecatedPaper from '../../ui/atoms/DeprecatedPaper';
import { useStatsDateSelectorContext } from '@/context/StatsDateSelectorContext';

const messages = defineMessages({
    'other.episodes': { defaultMessage: 'Autres épisodes' },
});

const enhance = compose(
    injectIntl,
    connect(({ routerStore, listenersStatsStore }) => ({
        showId: routerStore.params.showId,
        itemType: routerStore.params.itemType,
        itemId: routerStore.params.itemId,
        ranks: listenersStatsStore.podcastRanksPercent,
        episodeQuery: routerStore.query.episode,
    })),
);

function ListenersPodcastRanking({ intl, showId, itemType, itemId, ranks, episodeQuery }) {
    const { startDate, endDate } = useStatsDateSelectorContext();
    return (
        <>
            <DeprecatedPaper mt={30} mb={20}>
                <DeprecatedText weight="bold" size="veryBig" mb={5}>
                    <FormattedMessage defaultMessage="Classement des épisodes" />
                </DeprecatedText>
                <DeprecatedText>
                    <FormattedMessage defaultMessage="Quels épisodes ont été les plus écoutés ?" />
                </DeprecatedText>
            </DeprecatedPaper>
            <FetcherEpisodesTopRanking
                showId={showId}
                itemType={itemType}
                itemId={itemId}
                start={startDate}
                end={endDate}
                episodes={episodeQuery}
            >
                {(isLoading) => (
                    <RingChartWithLegend
                        isLoading={isLoading}
                        source={ranks.map((rank, i) => ({
                            value: rank.downloads,
                            percent: rank.percentage,
                            key: i,
                            name: rank.name,
                            label:
                                rank.name === 'others'
                                    ? intl.formatMessage(messages['other.episodes'])
                                    : rank.name,
                        }))}
                        py={50}
                        px={30}
                    />
                )}
            </FetcherEpisodesTopRanking>
        </>
    );
}

export default enhance(ListenersPodcastRanking);
