/* eslint-disable react/no-children-prop */
import { Route, useRouteMatch, Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import CommunicationTabs from '../../unorganized/CommunicationTabs';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import backIcon from '@public/icons/arrow-left.svg';
import DeprecatedIcon from '@ui/atoms/DeprecatedIcon';
import RouteTransition from '@ui/organisms/RouteTransition';
import Cluster from '@ui/layout/Cluster';
import Flex from '@ui/layout/Flex';
import { STATUS_SHOWS } from '@/shared/config/statusShows';
import ExternalLinks from '@/components/unorganized/ExternalLinks';
import Trackers from '@/components/unorganized/Trackers';
import ShowClips from '@/components/unorganized/ShowClips';
import PlayerPage from './PlayerPage';
import WebsiteSettings from '@/components/WebsiteSettings';
import SmartlinksSettings from '@/components/unorganized/SmartlinksSettings';
import AutoSharing from '@/components/unorganized/AutoSharing';
import Webhook from '@/components/unorganized/Webhook';
import CallToActionPage from '@/components/unorganized/CallToActionPage';
import SocialMediaPage from './SocialMediaPage';
import styled from 'styled-components';
import {
    COMMUNICATION_TABS,
    SETTINGS_TABS,
    GUARD_DISALLOW_REASONS,
    USER_ROLES,
} from '@/shared/config/constants';
import CommunicationTabItemAccess from '@/components/unorganized/CommunicationTabItemAccess/CommunicationTabItemAccess';
import Text from '@ui/atoms/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useGuard } from '@/shared/hooks/useGuard.hook';
import { PRICING } from '@/shared/config/pricing';
import { useParams } from 'react-router';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import Newsletter from '@/components/Newsletter';

const ConfigurationTabContent = styled(Text)`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--neutral500);
    font-weight: var(--fw-semibold);
    &:hover {
        color: var(--neutral600);
    }
`;

const CommunicationPage = () => {
    const { showId } = useParams();
    const { data: show } = useShowQuery(showId);

    const showRole = show?.userRole;
    const archived = show?.showStatus === STATUS_SHOWS.ARCHIVED_SHOWS;

    const { path, url } = useRouteMatch();
    const guard = useGuard({
        disallowedPlans: [PRICING.FREEMIUM, PRICING.LAUNCH, PRICING.BOOST],
    });

    const communicationTabs = {
        [COMMUNICATION_TABS.SOCIAL_MEDIA]: {
            title: <FormattedMessage defaultMessage="Réseaux sociaux" />,
            content: <SocialMediaPage />,
            icon: (
                <FontAwesomeIcon icon={icon({ name: 'message-heart', style: 'solid' })} size="md" />
            ),
            disableOnArchived: true,
            disabledFor: [USER_ROLES.VIEWER],
        },
        [COMMUNICATION_TABS.CLIP]: {
            title: <FormattedMessage defaultMessage="Clips vidéo" />,
            content: <ShowClips />,
            icon: (
                <FontAwesomeIcon icon={icon({ name: 'clapperboard', style: 'solid' })} size="md" />
            ),
            disableOnArchived: true,
            disabledFor: [],
        },
        [COMMUNICATION_TABS.PLAYER]: {
            title: <FormattedMessage defaultMessage="Smartplayer" />,
            content: <PlayerPage />,
            icon: (
                <FontAwesomeIcon
                    icon={icon({ name: 'waveform-lines', style: 'solid' })}
                    size="md"
                />
            ),
            disableOnArchived: false,
            disabledFor: [USER_ROLES.VIEWER],
        },
        [COMMUNICATION_TABS.PAGE]: {
            title: <FormattedMessage defaultMessage="Site Web" />,
            content: <WebsiteSettings />,
            icon: <FontAwesomeIcon icon={icon({ name: 'globe', style: 'regular' })} size="md" />,
            disableOnArchived: true,
            disabledFor: [USER_ROLES.VIEWER, USER_ROLES.EDITOR],
        },
        [COMMUNICATION_TABS.SMARTLINK]: {
            title: <FormattedMessage defaultMessage="Smartlink" />,
            content: <SmartlinksSettings />,
            icon: <FontAwesomeIcon icon={icon({ name: 'link', style: 'solid' })} size="md" />,
            disableOnArchived: true,
            disabledFor: [USER_ROLES.VIEWER, USER_ROLES.EDITOR],
        },
        [COMMUNICATION_TABS.NEWSLETTER]: {
            title: <FormattedMessage defaultMessage="Newsletter" />,
            content: <Newsletter />,
            icon: (
                <FontAwesomeIcon
                    icon={icon({ name: 'envelope-open-text', style: 'solid' })}
                    size="md"
                />
            ),
            disableOnArchived: true,
            disabledFor: [USER_ROLES.VIEWER, USER_ROLES.EDITOR],
        },
    };

    const settingTabs = {
        [SETTINGS_TABS.AUTO_SHARING]: {
            title: <FormattedMessage defaultMessage="Partage automatique" />,
            content: <AutoSharing />,
            icon: <FontAwesomeIcon icon={icon({ name: 'repeat', style: 'solid' })} size="md" />,
            disableOnArchived: true,
            disabledFor: [USER_ROLES.VIEWER, USER_ROLES.EDITOR],
        },
        [SETTINGS_TABS.EXTERNAL_LINKS]: {
            title: <FormattedMessage defaultMessage="Liens externes" />,
            content: <ExternalLinks />,
            icon: (
                <FontAwesomeIcon icon={icon({ name: 'share-nodes', style: 'solid' })} size="md" />
            ),
            disableOnArchived: true,
            disabledFor: [USER_ROLES.VIEWER, USER_ROLES.EDITOR],
        },
        [SETTINGS_TABS.CALL_TO_ACTION]: {
            title: (
                <TitleWrapper>
                    <FormattedMessage defaultMessage="Call-to-Action" />
                    {!guard.allow && <DiamondIcon icon={icon({ name: 'gem', style: 'duotone' })} />}
                </TitleWrapper>
            ),
            content: <CallToActionPage />,
            icon: (
                <FontAwesomeIcon
                    icon={icon({ name: 'bullseye-pointer', style: 'solid' })}
                    size="md"
                />
            ),
            disableOnArchived: true,
            disabledFor: [USER_ROLES.VIEWER],
        },
        [SETTINGS_TABS.TRACKER]: {
            title: <FormattedMessage defaultMessage="Trackers" />,
            content: <Trackers />,
            icon: (
                <FontAwesomeIcon
                    icon={icon({ name: 'brackets-curly', style: 'solid' })}
                    size="md"
                />
            ),
            disableOnArchived: true,
            disabledFor: [USER_ROLES.VIEWER, USER_ROLES.EDITOR],
        },
        [SETTINGS_TABS.WEBHOOK]: {
            title: (
                <TitleWrapper>
                    <FormattedMessage defaultMessage="Webhook" />
                    {!guard.allow && <DiamondIcon icon={icon({ name: 'gem', style: 'duotone' })} />}
                </TitleWrapper>
            ),
            content: <Webhook />,
            icon: <FontAwesomeIcon icon={icon({ name: 'webhook', style: 'solid' })} size="md" />,
            disableOnArchived: true,
            disabledFor: [USER_ROLES.VIEWER, USER_ROLES.EDITOR],
        },
    };

    return (
        <Route
            exact
            path={[`${path}/:tab`, `${path}/:tab/:type/:id`, `${path}/settings/:tab`]}
            children={({ match }) => (
                <CommunicationPageWrapper>
                    <RouteTransition exact path={[`${path}/:tab`, `${path}/:tab/:type/:id`]}>
                        <DeprecatedText weight="bold" size="veryBig">
                            <FormattedMessage defaultMessage="Communication" />
                        </DeprecatedText>
                        <Flex $justify="space-between" $align="center" $mt={24} $mb={14}>
                            <CommunicationTabs
                                tabs={communicationTabs}
                                pageUrl={url}
                                archived={archived}
                                showRole={showRole}
                            />
                            <CommunicationTabItemAccess
                                tab={COMMUNICATION_TABS.SETTINGS}
                                as={Link}
                                to={
                                    !archived &&
                                    !settingTabs[SETTINGS_TABS.AUTO_SHARING].disabledFor.includes(
                                        showRole,
                                    ) &&
                                    `/app/show/${showId}/communication/settings/${SETTINGS_TABS.AUTO_SHARING}`
                                }
                                disallowedUserRoles={[USER_ROLES.VIEWER, USER_ROLES.EDITOR]}
                                tooltip={{
                                    [GUARD_DISALLOW_REASONS.INSUFFICIENT_ROLE]: (
                                        <Text>
                                            <FormattedMessage
                                                defaultMessage="Vous devez être administrateur{break}de l'émission pour accéder à{break}cette fonctionnalité."
                                                values={{ break: <br /> }}
                                            />
                                        </Text>
                                    ),
                                }}
                                disallowAccess={archived}
                            >
                                <ConfigurationTabContent>
                                    <FontAwesomeIcon
                                        icon={icon({ name: 'gear', style: 'solid' })}
                                        size="lg"
                                    />
                                    <FormattedMessage defaultMessage="Configuration" />
                                </ConfigurationTabContent>
                            </CommunicationTabItemAccess>
                        </Flex>
                        {communicationTabs[match.params.tab]?.content}
                    </RouteTransition>

                    <RouteTransition exact path={`${path}/settings/:tab`}>
                        <Cluster $gap={8} $align="center">
                            <DeprecatedPaper
                                as={Link}
                                to={`/app/show/${showId}/communication/${COMMUNICATION_TABS.SOCIAL_MEDIA}`}
                                width={32}
                                height={32}
                                css={`
                                    border: 1px solid var(--neutral200);
                                    border-radius: var(--r-full);
                                `}
                                background="white"
                                flex
                                justify="center"
                                align="center"
                            >
                                <DeprecatedIcon as={backIcon} size={16} />
                            </DeprecatedPaper>
                            <DeprecatedText weight="bold" size="veryBig">
                                <FormattedMessage defaultMessage="Configuration" />
                            </DeprecatedText>
                        </Cluster>

                        <Flex justify="space-between" align="baseline" $mt={24} $mb={14}>
                            <CommunicationTabs
                                tabs={settingTabs}
                                pageUrl={`${url}/settings`}
                                archived={archived}
                                showRole={showRole}
                            />
                        </Flex>
                        {settingTabs[match.params.tab]?.content}
                    </RouteTransition>
                </CommunicationPageWrapper>
            )}
        />
    );
};

const CommunicationPageWrapper = styled.div`
    width: 100%;
    height: max-content;
    max-width: var(--container-width);
    margin-inline: auto;
    margin-block: 2rem;
    padding-bottom: 4rem;
    padding-inline: var(--container-padding);

    ${({ theme }) => theme.mediaQueries.mobile} {
        margin-block: 1rem;
        padding-inline: var(--container-padding-mobile);
    }
`;
const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;
const DiamondIcon = styled(FontAwesomeIcon)`
    color: var(--primary);
`;

export default CommunicationPage;
