import { InputTextController } from '@/components/ui/atoms/InputText';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import Alert from '@ui/atoms/Alert';
import Text from '@ui/atoms/Text';
import styled from 'styled-components';
import { Control, FieldPath } from 'react-hook-form';
import { AISettingsSchema } from '@/components/AISettings/AISettingsForm/useAISettingsSchema.hook';

const labels = defineMessages({
    title: { defaultMessage: "Titre de l'épisode" },
    description: { defaultMessage: "Description de l'épisode" },
    tags: { defaultMessage: "Tags de l'épisode" },
    newsletter: { defaultMessage: "Newsletter de l'épisode" },
    smm: { defaultMessage: 'Post sur les réseaux sociaux' },
});

const alertBulletPoints = {
    title: defineMessages({
        first: { defaultMessage: 'Formuler comme une question.' },
        second: { defaultMessage: 'Inclure les noms des intervenants.' },
        third: { defaultMessage: 'Inclure un chiffre clé.' },
    }),
    description: defineMessages({
        first: { defaultMessage: 'Commencer par une question.' },
        second: { defaultMessage: 'Présenter les orateurs.' },
        third: { defaultMessage: "Inclure une citation tirée de l'épisode." },
    }),
    tags: defineMessages({
        first: { defaultMessage: 'Inclure des mots-clés relatifs au thème' },
        second: { defaultMessage: 'Inclure les noms des intervenants.' },
        third: { defaultMessage: 'Utiliser des mots-clés composés de plusieurs mots.' },
    }),
    newsletter: defineMessages({
        first: { defaultMessage: 'Commencer le contenu par une question.' },
        second: { defaultMessage: 'Remercier les intervenants à la fin.' },
        third: { defaultMessage: "Inclure une citation tirée de l'épisode." },
    }),
    smm: defineMessages({
        first: { defaultMessage: 'Partager un extrait inspirant du dernier épisode.' },
        second: { defaultMessage: "Terminer par un appel à l'action engageant." },
        third: { defaultMessage: 'Inclure un chiffre clé.' },
    }),
};

interface AISettingsFormInputProps {
    name: FieldPath<AISettingsSchema>;
    control: Control<AISettingsSchema>;
}

const AISettingsFormInput = ({ name, control }: AISettingsFormInputProps) => {
    const intl = useIntl();
    return (
        <CustomPromptSetting>
            <InputTextController
                name={name}
                elementType="textarea"
                control={control}
                label={intl.formatMessage(labels[name])}
                placeholder={intl.formatMessage({
                    defaultMessage: 'Saisissez vos instructions personnalisées',
                })}
                minHeight="100px"
                maxLength={200}
            />
            <Alert
                variant="neutral"
                defaultIcon
                description={
                    <List>
                        <ListItem>
                            <Text>
                                <FormattedMessage {...alertBulletPoints[name].first} />
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <FormattedMessage {...alertBulletPoints[name].second} />
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text>
                                <FormattedMessage {...alertBulletPoints[name].third} />
                            </Text>
                        </ListItem>
                    </List>
                }
            >
                <Text fontWeight="--fw-semibold">
                    <FormattedMessage defaultMessage="Besoin d'inspiration pour vos instructions ? Voici quelques idées pour commencer !" />
                </Text>
            </Alert>
        </CustomPromptSetting>
    );
};

const CustomPromptSetting = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;
const List = styled.ul`
    list-style-type: none;
    padding-inline: 0.5rem;
`;
const ListItem = styled.li`
    display: list-item;
    list-style-type: '- ';
    color: var(--neutral700);
`;

export default AISettingsFormInput;
