import { LinkedinShowTemplate } from '@/queries/showTemplate/useLinkedinShowTemplateQuery';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import useLinkedinTemplateFormSchema, { LinkedinTemplateFormSchema } from './useFormSchema';
import { zodResolver } from '@hookform/resolvers/zod';
import TemplateEditor from '../../TemplateEditor';
import Button from '@/components/Button';

interface TemplateFormProps {
    template?: LinkedinShowTemplate;
    isLoading: boolean;
    onSubmit: (formData: LinkedinTemplateFormSchema) => void;
}

const TemplateForm = ({ template, onSubmit, isLoading }: TemplateFormProps) => {
    const schema = useLinkedinTemplateFormSchema();
    const { watch, setValue, handleSubmit } = useForm<LinkedinTemplateFormSchema>({
        mode: 'onBlur',
        resolver: zodResolver(schema),
        values: {
            messageTpl: template?.messageTpl ?? '',
        },
    });
    const messageTpl = watch('messageTpl');

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <TemplateEditor
                label={<FormattedMessage defaultMessage="Texte prédéfini" />}
                description={
                    <FormattedMessage defaultMessage="Lorsque vous configurerez un nouveau post, ce texte sera déjà saisi. Vous pourrez toujours le modifier par la suite." />
                }
                value={messageTpl}
                onChange={(value) =>
                    setValue('messageTpl', value, { shouldValidate: true, shouldDirty: true })
                }
            />
            <SubmitButton type="submit" isLoading={isLoading}>
                <FormattedMessage defaultMessage="Enregistrer" />
            </SubmitButton>
        </Form>
    );
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
`;
const SubmitButton = styled(Button)`
    margin-top: 1rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        align-self: center;
    }
`;

export default TemplateForm;
