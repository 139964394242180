import { UseQueryResult, useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchPlayerSettings } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import playerSettingsKeys from './playerSettingsKeys';

export interface PlayerSettings {
    showId: number;
    color?: string;
    autoPlay?: boolean;
    episodeList?: boolean;
    showSwitch?: boolean;
    playNext?: boolean;
    sizeUnit?: 'percent' | 'pixel';
    width?: number;
    height?: number | null;
    format?: 'auto' | 'horizontal' | 'vertical';
    startAt?: number;
    darkMode?: boolean;
    createdAt?: string;
    updatedAt?: string;
}

const queryFn = async ({
    queryKey: [{ showId }],
}: QueryFunctionContext<ReturnType<typeof playerSettingsKeys.detailByShowId>>) => {
    const { data } = await fetchPlayerSettings(showId);

    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const usePlayerSettingsQuery = (showId: string): UseQueryResult<PlayerSettings> => {
    return useQuery({
        queryKey: playerSettingsKeys.detailByShowId(showId),
        queryFn,
        enabled: !!showId,
    });
};

export default usePlayerSettingsQuery;
