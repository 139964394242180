import { FormattedMessage } from 'react-intl';
import Dialog from '@/components/ui/atoms/Dialog';
import Button from '@/components/ui/atoms/Button';
import Text from '@/components/ui/atoms/Text';
import { ShowGuestPermission } from '@/queries/guest/useShowGuestPermissionsQuery.hook';
import deleteShowGuestPermissionMutation from '@/queries/guest/deleteShowGuestPermissionMutation';

interface ShowGuestsPermissionDeleteModalProps {
    isOpen: boolean;
    onOpenChange: (open: boolean) => void;
    permission: ShowGuestPermission;
}

const ShowGuestsPermissionDeleteModal = ({
    isOpen,
    onOpenChange,
    permission,
}: ShowGuestsPermissionDeleteModalProps) => {
    const deletePermission = deleteShowGuestPermissionMutation();

    const handleDelete = () => {
        deletePermission.mutate(permission.id, {
            onSuccess: () => {
                onOpenChange(false);
            },
        });
    };

    return (
        <Dialog
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            title={<FormattedMessage defaultMessage="Supprimer un utilisateur" />}
            size="medium"
            action={
                <>
                    <Button variant="secondary" onPress={() => onOpenChange(false)}>
                        <FormattedMessage defaultMessage="Annuler" />
                    </Button>
                    <Button onPress={handleDelete} isLoading={deletePermission.isLoading}>
                        <FormattedMessage defaultMessage="Supprimer l'utilisateur" />
                    </Button>
                </>
            }
        >
            <Text>
                <FormattedMessage
                    defaultMessage="Souhaitez-vous supprimer {name} de la liste des utilisateurs ?"
                    values={{
                        name: (
                            <Text as="span" fontWeight="--fw-semibold">
                                {permission.userName}
                            </Text>
                        ),
                    }}
                />
            </Text>
        </Dialog>
    );
};

export default ShowGuestsPermissionDeleteModal;
