import { Route } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import CampaignsList from '@app/organisms/CampaignsList';
import HideFor from '@app/atoms/HideFor';
import { useAmplitudeLogEvent } from '@hooks/useAmplitude';
import { USER_OPTIONS } from '@/utils/userOptions';
import { useRouteMatch } from 'react-router-dom';
import Link from '@ui/atoms/Link';
import emptyStateMainIllustration from '@public/images/empty-state/illustration/monetization.mp4';
import Text from '@ui/atoms/Text';
import ZebraIllustration from '@ui/atoms/ZebraIllustration';
import laptop from '@public/images/emoji/laptop.png';
import Button from '@ui/atoms/Button';
import EmptyState from '@ui/molecules/EmptyState';
import ShiningDiamond from '@ui/atoms/ShiningDiamond';
import IconRocket from '@ui/icons/IconRocket';
import { connect } from '@app/decorators/connect';
import { useParams } from 'react-router-dom';
import { USER_ROLES } from '@/utils/constants';
import styled from 'styled-components';
import CampaignEdit from '@app/organisms/CampaignEdit';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import Stack from '@/components/ui/layout/Stack';
import { useResponsive } from '@/utils/hooks/useResponsive';
import imageMonetizationBlocked from '@public/images/illu_monetization_blocked.png';

const MobileBlockingScreen = () => {
    return (
        <Stack $gap="2rem">
            <Text variant="title" fontWeight="--fw-bold">
                <FormattedMessage defaultMessage="Monétisation manuelle" />
            </Text>
            <MobileBlockingScreenMessageBlock>
                <ZebraIllustration foreground={laptop} />
                <Text variant="headingS" fontWeight="--fw-bold" textAlign="center">
                    <FormattedMessage defaultMessage="Cette fonctionnalité n'est pas disponible sur mobile." />
                </Text>
            </MobileBlockingScreenMessageBlock>
        </Stack>
    );
};

const enhance = connect(({ routerStore }) => ({
    upgradeOfferLink: routerStore.link(
        routerStore.params.showId ? 'user.edit.offers' : 'user.offers',
    ),
}));

function MonetizePageManual({ upgradeOfferLink }) {
    useAmplitudeLogEvent('Monetization Section Viewed');
    const user = useUserQuery();
    const { showId } = useParams();
    const show = useShowQuery(showId);
    const match = useRouteMatch();
    const { isMobile } = useResponsive();

    if (user?.data?.activated === false && show?.data?.userRole === USER_ROLES.VIEWER) {
        return (
            <EmptyStateOuterWrapper>
                <Text fontWeight="--fw-bold" variant="title">
                    <FormattedMessage defaultMessage="Monétisation manuelle" />
                </Text>
                <EmptyState
                    illustration={emptyStateMainIllustration}
                    illustrationType="video"
                    illustrationAlign="right"
                    illustrationBackground="--gradient-sunny"
                >
                    <HeadIllustrationWrapper>
                        <ZebraIllustration>
                            <ShiningDiamond />
                        </ZebraIllustration>
                    </HeadIllustrationWrapper>
                    <LabelWrapper>
                        <Text variant="heading" fontWeight="--fw-bold">
                            <FormattedMessage defaultMessage="Abonnez-vous !" />
                        </Text>
                        <Text variant="body" fontWeight="--fw-normal" color="--neutral500">
                            <FormattedMessage defaultMessage="Pour débloquer la monétisation de votre podcast, abonnez-vous 🚀" />
                        </Text>
                    </LabelWrapper>
                    <Button startIcon={<IconRocket />} as={Link} to={upgradeOfferLink}>
                        <FormattedMessage defaultMessage="S'abonner" />
                    </Button>
                </EmptyState>
            </EmptyStateOuterWrapper>
        );
    }

    return (
        <HideFor
            userOptions={USER_OPTIONS.MANUAL_CAMPAIGNS}
            lockedMessage={
                <EmptyStateOuterWrapper>
                    <Text fontWeight="--fw-bold" variant="title">
                        <FormattedMessage defaultMessage="Monétisation manuelle" />
                    </Text>
                    <EmptyState
                        illustration={emptyStateMainIllustration}
                        illustrationType="video"
                        illustrationAlign="right"
                        illustrationBackground="--gradient-sunny"
                    >
                        <HeadIllustrationWrapper>
                            <ZebraIllustration>
                                <ShiningDiamond />
                            </ZebraIllustration>
                        </HeadIllustrationWrapper>
                        <LabelWrapper>
                            <Text variant="heading" fontWeight="--fw-bold">
                                <FormattedMessage defaultMessage="Passez à l'offre supérieure !" />
                            </Text>
                            <Text variant="body" fontWeight="--fw-normal" color="--neutral500">
                                <FormattedMessage defaultMessage="Pour débloquer la monétisation de votre podcast, abonnez-vous à l'offre Boost ou Supersonic 🚀" />
                            </Text>
                        </LabelWrapper>
                        <Button startIcon={<IconRocket />} as={Link} to={upgradeOfferLink}>
                            <FormattedMessage defaultMessage="Mettre à niveau mon offre" />
                        </Button>
                    </EmptyState>
                </EmptyStateOuterWrapper>
            }
        >
            {isMobile ? (
                <MobileBlockingScreen />
            ) : (
                <>
                    <CampaignsList />
                    <Route path={`${match.path}/:campaignId`} component={CampaignEdit} />
                </>
            )}
        </HideFor>
    );
}

const EmptyStateOuterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

const HeadIllustrationWrapper = styled.div`
    width: 200px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    & > * {
        text-align: center;
    }
`;

const MobileBlockingScreenMessageBlock = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 2rem;
    padding: 2rem;
    border-radius: var(--r-l);
    height: 22.0625rem;
    background-color: var(--white);
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 35%, #fff 70%),
        url(${imageMonetizationBlocked});
    background-repeat: no-repeat;
    background-size: 16rem 16rem;
    background-position: bottom -5.25rem center;
`;

export default enhance(MonetizePageManual);
