import { FormattedMessage, useIntl } from 'react-intl';
import DeprecatedText from '../../ui/atoms/DeprecatedText';
import { connect } from '../decorators/connect';
import FetcherPeakTimePopularDays from '../fetchers/stats/FetcherPeakTimePopularDays';
import ShortListFallback from '../fallbacks/ShortListFallback';
import BarGraph, { STATS_NAME } from '../../ui/molecules/BarGraph';
import { useResponsive } from '../../../utils/hooks/useResponsive';
import styled, { css } from 'styled-components';

const enhance = connect(({ peakTimeStatsStore }) => ({
    popularDays: peakTimeStatsStore.popularDays,
    daysRangePeakTime: peakTimeStatsStore.daysRangePeakTime,
}));

function PeakTimePopularDays({
    popularDays,
    daysRangePeakTime,
    messageLaunchTime,
    match,
    isNoData,
}) {
    const intl = useIntl();
    const { isMobileOrTablet } = useResponsive();
    return (
        <>
            <DeprecatedText size="veryBig" weight="bold" mb={5}>
                <FormattedMessage defaultMessage="Jours les plus populaires" />
            </DeprecatedText>
            <DeprecatedText>
                {daysRangePeakTime === 'always' ? (
                    <FormattedMessage defaultMessage="Vos écoutes selon les jours depuis le début" />
                ) : (
                    <FormattedMessage
                        defaultMessage="Vos écoutes selon les jours sur les {messageLaunchTime}"
                        values={{ messageLaunchTime }}
                    />
                )}
            </DeprecatedText>
            <PeakTimePopularDayWrapper isFlex={isNoData}>
                <FetcherPeakTimePopularDays
                    showId={match.params.showId}
                    days={daysRangePeakTime}
                    fallback={ShortListFallback}
                >
                    {(isLoading) =>
                        !isLoading && (
                            <>
                                <BarGraph
                                    options={
                                        isMobileOrTablet
                                            ? popularDays.weekDayResponsive
                                            : popularDays.weekDay
                                    }
                                    series={popularDays.downloads.map((download, index) => ({
                                        x: index + 1,
                                        y: download,
                                        tooltipMessage: intl.formatMessage(
                                            {
                                                defaultMessage:
                                                    '{count, plural, zero {# écoute} one {# écoute} other {# écoutes}}',
                                            },
                                            { count: download },
                                        ),
                                    }))}
                                    isNoData={isNoData}
                                    statName={STATS_NAME.LISTENERS}
                                    capitalize
                                />
                            </>
                        )
                    }
                </FetcherPeakTimePopularDays>
            </PeakTimePopularDayWrapper>
        </>
    );
}

const PeakTimePopularDayWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding);
    margin-block: 2rem;
    min-height: 150px;
    gap: 1rem ${({ theme }) => theme.mediaQueries.mobile} {
        padding: var(--container-padding-mobile);
    }

    ${({ isFlex }) =>
        isFlex &&
        css`
            display: flex;
            align-items: center;
            justify-content: center;
        `}
`;

export default enhance(PeakTimePopularDays);
