import SucceededClipFormat from './SucceededClipFormat';
import LoadingClipFormat from './LoadingClipFormat';
import PendingClipFormat from './PendingClipFormat';
import ErrorClipFormat from './ErrorClipFormat';
import useEpisodeQuery from '@/queries/episode/useEpisodeQuery.hook';
import useShowClipFormatQuery from '@/queries/clipFormat/useClipFormatQuery';
import ClipFormatSkeleton from './ClipFormatSkeleton';
import { CLIP_STATUS } from '@/shared/config/constants';

const ClipFormat = ({ clipFormat, onEdit }) => {
    const episodeQuery = useEpisodeQuery({ episodeId: clipFormat.clip.episodeId });
    const clipFormatQuery = useShowClipFormatQuery(clipFormat.id, {
        enabled: clipFormat.state === CLIP_STATUS.LOADING,
        refetchInterval: (data) => {
            if (!data) return false;
            if (data.state !== CLIP_STATUS.LOADING) return false;
            return 5000;
        },
    });
    const state = clipFormatQuery.data?.state || clipFormat.state;
    const clipFormatData = clipFormatQuery.data || clipFormat;

    if (episodeQuery.isLoading) {
        return <ClipFormatSkeleton />;
    }

    if (state === CLIP_STATUS.LOADING) {
        return <LoadingClipFormat clipFormat={clipFormatData} episode={episodeQuery.data} />;
    }
    if (state === CLIP_STATUS.PENDING) {
        return (
            <PendingClipFormat
                clipFormat={clipFormatData}
                episode={episodeQuery.data}
                onClick={onEdit}
            />
        );
    }
    if (state === CLIP_STATUS.DONE) {
        return <SucceededClipFormat clipFormat={clipFormatData} episode={episodeQuery.data} />;
    }
    if (!episodeQuery.data || state === CLIP_STATUS.ERROR) {
        return <ErrorClipFormat clipFormat={clipFormatData} episode={episodeQuery.data} />;
    }

    return null;
};

export default ClipFormat;
