import { useState, useEffect } from 'react';

const usePreventBodyScroll = (prevent = true) => {
    const [prevOverflow, setPrevOverflow] = useState('');
    useEffect(() => {
        if (prevent) {
            setPrevOverflow(document.body.style.overflow);
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = prevOverflow;
        }
        return () => {
            document.body.style.overflow = prevOverflow;
        };
    }, [prevent]);
};

export default usePreventBodyScroll;
