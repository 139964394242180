import { useContext } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Dialog from '@/components/ui/atoms/Dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Alert from '@ui/atoms/Alert';
import Stack from '@ui/layout/Stack';
import Text from '@ui/atoms/Text';
import Button from '@ui/atoms/Button';
import PricingContext from '../PricingContext';
import { PRICING_NAME } from '@/utils/pricing';
import Link from '@ui/atoms/Link';
import useInternalRouteMapping from '@hooks/useInternalRouteMapping.hook';
import useChannelQuery from '@/queries/channel/useChannelQuery.hook';
import useArchivedShowsQuery from '@/queries/show/useArchivedShowsQuery.hook';
import { NUMBER_OF_ARCHIVED_SHOWS } from '@/utils/constants';
import Spinner from '@ui/atoms/Spinner';
// @ts-ignore
import { IntercomAPI } from 'react-intercom';

export interface PricingErrorModalProps {
    isOpen: boolean;
    onOpenChange: () => void;
}

const PricingErrorModal = ({ isOpen, onOpenChange }: PricingErrorModalProps) => {
    const { errorPayload } = useContext(PricingContext);
    const { link } = useInternalRouteMapping();
    const { data: channel } = useChannelQuery();
    const { data: archivedShows } = useArchivedShowsQuery(channel && channel.id);

    if (!errorPayload?.error?.response?.status) {
        return null;
    }

    const maximumArchivedShowsError = (
        <Stack $gap="0.25rem">
            <Text variant="headingM" fontWeight="--fw-bold" textAlign="center">
                <FormattedMessage
                    defaultMessage="Votre nombre d'émissions archivées dépasse la limite de l'offre {offer} ({shows}/{maxShows})"
                    values={{
                        offer: PRICING_NAME[errorPayload?.offer],
                        shows: archivedShows?.length,
                        maxShows: NUMBER_OF_ARCHIVED_SHOWS[errorPayload?.offer],
                    }}
                />
            </Text>
            <Text
                variant="bodyM"
                fontWeight="--fw-semibold"
                textAlign="center"
                color="--neutral500"
            >
                <FormattedMessage
                    defaultMessage="Veuillez en supprimer au moins <strong>{value}</strong> depuis le <link>sélecteur d'émission</link> pour continuer"
                    values={{
                        link: (chunk: React.ReactNode) => (
                            <Text
                                // @ts-ignore
                                as={Link}
                                to={link('show.all')}
                                color="--primary"
                                fontWeight="--fw-bold"
                            >
                                {chunk}
                            </Text>
                        ),
                        strong: (chunk: React.ReactNode) => <strong>{chunk}</strong>,
                        value:
                            archivedShows?.length - NUMBER_OF_ARCHIVED_SHOWS[errorPayload?.offer],
                    }}
                />
            </Text>
        </Stack>
    );

    const billingError = (
        <Stack $gap="0.25rem">
            <Text variant="headingM" fontWeight="--fw-bold" textAlign="center">
                <FormattedMessage defaultMessage="Oups, il semble que vous ayez une erreur de facturation." />
            </Text>
            <Text
                variant="bodyM"
                fontWeight="--fw-semibold"
                textAlign="center"
                color="--neutral500"
            >
                <FormattedMessage
                    defaultMessage="Veuillez résoudre ce problème dans votre espace <link>Abonnement</link> avant de sélectionner une nouvelle offre. N'hésitez pas à <intercom>contacter notre équipe support</intercom> si besoin. 💌"
                    values={{
                        link: (chunk: React.ReactNode) => (
                            <Text
                                // @ts-ignore
                                as={Link}
                                to={link('user.edit.show.tab', { tab: 'subscription' })}
                                color="--primary"
                                fontWeight="--fw-bold"
                            >
                                {chunk}
                            </Text>
                        ),
                        intercom: (chunk: React.ReactNode) => (
                            <Text
                                // @ts-ignore
                                as={Link}
                                onClick={() => IntercomAPI('show')}
                                color="--primary"
                                fontWeight="--fw-bold"
                            >
                                {chunk}
                            </Text>
                        ),
                    }}
                />
            </Text>
        </Stack>
    );

    return (
        <Dialog isOpen={isOpen} onOpenChange={onOpenChange} isDismissable={false} size="small">
            {!archivedShows ? (
                <SpinnerWrapper>
                    <Spinner size={32} />
                </SpinnerWrapper>
            ) : (
                <Stack $gap="1.5rem" $align="center">
                    <IconAlert
                        variant="alert"
                        icon={
                            <FontAwesomeIcon
                                icon={icon({ name: 'circle-exclamation', style: 'solid' })}
                                size="xl"
                            />
                        }
                    />
                    {errorPayload.error.response.status === 422
                        ? maximumArchivedShowsError
                        : billingError}
                    {/* @ts-ignore */}
                    <CloseButton variant="secondary" onPress={() => onOpenChange(false)}>
                        <FormattedMessage defaultMessage="Retour" />
                    </CloseButton>
                </Stack>
            )}
        </Dialog>
    );
};

const SpinnerWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

const IconAlert = styled(Alert)`
    color: var(--alert500);
    width: max-content;
`;

const CloseButton = styled(Button)`
    width: 100%;
`;

export default PricingErrorModal;
