import { FormattedMessage, useIntl } from 'react-intl';
import Cluster from '@ui/layout/Cluster';
import Text from '@ui/atoms/Text';
import EpisodeEditTooltip from '@/components/unorganized/EpisodeEditTooltip';
import styled from 'styled-components';

const LabelTooltipLink = styled.a`
    color: var(--white);
    text-decoration: underline;
`;

const ChaptersLabel = () => {
    const intl = useIntl();

    return (
        <Cluster $align="center" $gap="0.25rem">
            <Text variant="bodyM" fontWeight="--fw-semibold">
                <FormattedMessage defaultMessage="Chapitres" />
            </Text>
            <EpisodeEditTooltip>
                <FormattedMessage
                    defaultMessage="Les chapitres permettent de décomposer vos épisodes pour guider vos auditeur.ices dans votre épisode. <link>Voir le tutoriel</link>"
                    values={{
                        link: (chunks) => (
                            <LabelTooltipLink
                                href={intl.formatMessage({
                                    defaultMessage:
                                        'https://aide.ausha.co/fr/articles/4929059-comment-utiliser-les-chapitres',
                                })}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {chunks}
                            </LabelTooltipLink>
                        ),
                    }}
                />
            </EpisodeEditTooltip>
        </Cluster>
    );
};

export default ChaptersLabel;
