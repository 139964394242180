import { useQuery } from '@tanstack/react-query';
import { fetchEpisodeTranscriptStatus } from '@/api';
import { EPISODE_TRANSCRIPT_STATUS } from '@/shared/config/constants';
import camelcaseKeys from 'camelcase-keys';

const queryFn = async ({ queryKey: [{ episodeId }] }) => {
    const { data } = await fetchEpisodeTranscriptStatus(episodeId);

    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const useEpisodeTranscriptStatusQuery = (episodeId) => {
    return useQuery({
        queryKey: [{ entity: 'episodeTranscriptStatus', episodeId: Number(episodeId) }],
        queryFn,
        enabled: !!episodeId,
        retry: false,
        refetchInterval: (data) => {
            if (!data || data.status !== EPISODE_TRANSCRIPT_STATUS.IN_PROGRESS) return false;
            return 1000 * 2; // 2 seconds
        },
    });
};

export default useEpisodeTranscriptStatusQuery;
