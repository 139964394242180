import autobind from 'autobind-decorator';
import routes from '../routes';
import { buildQuery, parseQuery } from '@/api/query';

const resolvers = {
    podId: (id, { podcastStore }) => podcastStore.byId(id),
    playlistId: (id, { playlistStore }) => playlistStore.firstPlaylistOrById(id),
    campaignId: (id, { campaignStore }) => campaignStore.byId(id),
};

@autobind
class RouterStore {
    constructor(history, match, location, state) {
        this.history = history;
        this.match = match;
        this.location = location;
        this.state = state;
    }

    link(routeName, args = {}, query = {}) {
        const params = { ...this.match.params, ...args };
        const url = routes[routeName].replace(/:(\w+)/gi, (_, e) => params[e] || '');
        return url + buildQuery(query);
    }

    push(routeName, args = {}) {
        const params = { ...this.match.params, ...args };
        this.history.push(routes[routeName].replace(/:(\w+)/gi, (_, e) => params[e]));
    }

    replace(routeName, args = {}) {
        const params = { ...this.match.params, ...args };
        this.history.replace(routes[routeName].replace(/:(\w+)/gi, (_, e) => params[e]));
    }

    goBack(...args) {
        this.history.goBack(...args);
    }

    resolve(paramName) {
        const paramValue = this.params[paramName] || 0;
        return resolvers[paramName](paramValue, this.state);
    }

    async redirectAfterLogin() {
        this.history.push('/app');
    }

    get defaultRedirect() {
        return this.location.state?.referrer || '/app';
    }

    get query() {
        return parseQuery(this.location.search);
    }

    get params() {
        return this.match.params;
    }

    get locationPath() {
        return this.location.pathname;
    }

    get path() {
        return this.match.path;
    }
}

export default RouterStore;
