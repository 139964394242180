import { useQuery } from '@tanstack/react-query';
import userKeys from './userKeys';
import { z } from 'zod';

export const geolocalizationSchema = z.object({
    geo: z
        .object({
            city: z.string(),
            country: z.object({
                code: z.string(),
                name: z.string(),
            }),
            subdivision: z.object({
                code: z.string(),
                name: z.string(),
            }),
            timezone: z.string(),
            latitude: z.number(),
            longitude: z.number(),
            postalCode: z.string(),
        })
        .nullable(),
});
export type Geolocalization = z.infer<typeof geolocalizationSchema>;

const queryFn = async () => {
    const res = await fetch(`${window.location.origin}/user-geolocalization`, {
        headers: {
            Accept: 'application/json',
        },
    });
    let data = {
        geo: {
            city: 'Paris',
            country: {
                code: 'FR',
                name: 'France',
            },
            subdivision: {
                code: 'IDF',
                name: 'Île-de-France',
            },
            timezone: 'Europe/Paris',
            latitude: 48.8534,
            longitude: 2.3488,
            postalCode: '75000',
        },
    };

    try {
        data = await res.json();
    } catch (e) {
        console.error(e);
    }

    return geolocalizationSchema.parse(data);
};

const useUserGeolocalizationQuery = () => {
    return useQuery({
        queryKey: userKeys.geolocalization(),
        queryFn,
    });
};

export default useUserGeolocalizationQuery;
