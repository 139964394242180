import { Children, cloneElement } from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import DeprecatedPaper from '../../atoms/DeprecatedPaper';
import DeprecatedText from '../../atoms/DeprecatedText';
import { round } from '@/shared/utils/number';
import { resolveColor } from '@/shared/utils/cssVariable';

const transition = {
    duration: 0.25,
};

const Svg = styled.svg`
    width: 100%;
    height: 100%;
    transform: rotate(-90deg);
`;

const Circle = styled.circle.attrs((p) => ({
    cx: 0,
    cy: 0,
    fill: resolveColor(p.color),
}))`
    pointer-events: none;
`;

const Label = styled(motion(DeprecatedText))`
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    pointer-events: none;
`;

const labelVariants = {
    hidden: { opacity: 0, transition },
    visible: { opacity: 1, transition },
    exit: { opacity: 0, transition },
};

function RingChart({ children, onHover = () => null, onExit, activeKey }) {
    const childrenArr = Children.toArray(children);
    const total = childrenArr.reduce((sum, c) => c.props.value + sum, 0);
    const propsMap = childrenArr.reduce(
        ([props, start], c) => {
            const percent = c.props.value / total;
            return [
                {
                    ...props,
                    [c.key]: {
                        start,
                        percent,
                        displayedpercentage: c.props.percent,
                        active: c.key === `.$${activeKey}`,
                        onMouseEnter: () => onHover(c.key.substr(2)),
                        onMouseLeave: onExit,
                    },
                },
                start + percent,
            ];
        },
        [{}, 0],
    )[0];

    const activeProps = propsMap[`.$${activeKey}`];

    return (
        <DeprecatedPaper pos="relative" height="100%">
            <Svg viewBox="-1.05 -1.05 2.1 2.1">
                {Children.map(children, (c) => cloneElement(c, propsMap[`.$${c.key}`]))}
                <Circle r={0.75} color="--white200" />
                <Circle r={0.65} color="white" />
            </Svg>
            <AnimatePresence>
                {activeProps && (
                    <Label
                        key={activeProps.percent}
                        weight="bold"
                        size="veryBig"
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        variants={labelVariants}
                    >
                        {`${round(activeProps.displayedpercentage)} %`}
                    </Label>
                )}
            </AnimatePresence>
        </DeprecatedPaper>
    );
}

export default RingChart;
