import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ComboBoxController } from '@ui/atoms/ComboBox';
import ListBoxItem from '@ui/atoms/ListBoxItem';
import useListenableEpisodesQuery from '@/queries/episode/useListenableEpisodesQuery.hook';
import { useIntl } from 'react-intl';
import { Collection, Header, Section } from 'react-aria-components';
import { PlayerFormSchema } from '../../usePlayerFormSchema.hook';

import { Control, useFormContext } from 'react-hook-form';
interface PlayerFormSelectEpisodeProps {
    control: Control<PlayerFormSchema>;
    name: string;
    label: string | JSX.Element;
}

const PlayerFormSelectEpisode = (props: PlayerFormSelectEpisodeProps) => {
    const { setValue, watch } = useFormContext<PlayerFormSchema>();
    const { showId } = useParams<{ showId: string }>();
    const episodes = useListenableEpisodesQuery(showId);
    const intl = useIntl();
    const items = useMemo(() => {
        const formattedEpisodes =
            episodes.data?.map((episode) => ({
                id: episode.publicId,
                name: episode.name,
            })) || [];

        return [
            {
                id: 'latest',
                name: intl.formatMessage({
                    defaultMessage: "Toujours afficher l'épisode le plus récent",
                }),
                hasSeparator: true,
            },
            {
                name: intl.formatMessage({ defaultMessage: 'Épisode spécifique' }),
                children: formattedEpisodes,
            },
        ];
    }, [episodes.data]);
    const formSelectedEpisode = watch('episodeId');

    useEffect(() => {
        if (formSelectedEpisode || items.length === 0) return;
        const lastEpisode = items?.[1]?.children?.[0];
        setValue('episodeId', lastEpisode?.id ?? 'latest');
    }, [items, formSelectedEpisode]);

    return (
        <ComboBox {...props} items={items} isLoading={episodes.isLoading}>
            {/* @ts-ignore */}
            {(item) => {
                if (item.children)
                    return (
                        <Section id={item.name}>
                            <ComboBoxListHeader>{item.name}</ComboBoxListHeader>
                            <Collection items={item.children}>
                                {/* @ts-ignore */}
                                {(item) => <ListBoxItem {...item} />}
                            </Collection>
                        </Section>
                    );
                return <ListBoxItem {...item} />;
            }}
        </ComboBox>
    );
};

const ComboBox = styled(ComboBoxController)`
    max-width: 100%;
`;
const ComboBoxListHeader = styled(Header)`
    font-size: var(--fs-body-s);
    line-height: var(--lh-body-s);
    color: var(--neutral500);
    padding: 0.25rem 0.5rem;
    margin-bottom: 0.5rem;
`;

export default PlayerFormSelectEpisode;
