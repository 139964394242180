import { useQuery } from '@tanstack/react-query';
import { fetchCountries } from '@/api';
import countryKeys from '@/queries/country/countryKeys';
import camelcaseKeys from 'camelcase-keys';
import { z } from 'zod';

export const CountrySchema = z.object({
    name: z.string(),
    code: z.string(),
});
const CountriesSchema = z.array(CountrySchema);
export type Country = z.infer<typeof CountrySchema>;

const queryFn = async () => {
    const { data } = await fetchCountries();

    // TODO: Do camelcase transformation in Axios interceptor
    return CountriesSchema.parse(camelcaseKeys(data, { deep: true }));
};

const useCountriesQuery = () => {
    return useQuery({
        queryKey: countryKeys.list(),
        queryFn,
        staleTime: Infinity,
    });
};

export default useCountriesQuery;
