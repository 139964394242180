import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useFormContext } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Rule from '../Rule';
import usePsoQuery from '@queries/pso/usePsoQuery.hook';
import styled from 'styled-components';
import { usePsoCheckerContext } from '@/components/NonHostingEpisodeForm/contexts/NonHostingPsoCheckerContext';

interface TagsRulesProps {
    setScore: (score: number) => void;
}

const TagsRules = ({ setScore }: TagsRulesProps) => {
    const { showId }: { showId: string } = useParams();
    const psoConfiguration = usePsoQuery({ showId, enabled: true });
    const { targetKeyword } = usePsoCheckerContext();
    const { watch } = useFormContext();
    const tags = watch('content.tags');

    if (psoConfiguration.isLoading || psoConfiguration.isError) {
        return null;
    }

    const getPonderatedCompletion = (score: number, ponderatedThresholds: number[]): number => {
        if (score > ponderatedThresholds.length - 1) {
            return ponderatedThresholds[ponderatedThresholds.length - 1];
        }
        if (score < 0) {
            return ponderatedThresholds[0];
        }
        return ponderatedThresholds[score];
    };

    const targetKeywordRuleCompletion = tags.includes(targetKeyword) ? 100 : 0;
    const psoKeywordsFoundInTags = tags.filter((k: string) =>
        psoConfiguration.data.keywords.includes(k),
    ).length;
    const threePSOKeywordsRuleCompletion = getPonderatedCompletion(
        psoKeywordsFoundInTags,
        [0, 50, 75, 100],
    );
    const fiveTagsRuleCompletion = getPonderatedCompletion(tags.length, [0, 50, 50, 75, 75, 100]);

    useEffect(() => {
        setScore(
            Math.round(
                (targetKeywordRuleCompletion +
                    threePSOKeywordsRuleCompletion +
                    fiveTagsRuleCompletion) /
                    3,
            ),
        );
    }, [
        setScore,
        targetKeywordRuleCompletion,
        threePSOKeywordsRuleCompletion,
        fiveTagsRuleCompletion,
    ]);

    return (
        <Wrapper>
            <TitleWrapper>
                <FontAwesomeIcon icon={icon({ name: 'hashtag', style: 'solid' })} />
                <FormattedMessage defaultMessage="Tags" />
            </TitleWrapper>
            <RulesWrapper>
                <Rule
                    label={<FormattedMessage defaultMessage="Inclure le mot-clé principal" />}
                    requiresTargetKeyword={true}
                    missingTargetKeyword={!targetKeyword}
                    completion={targetKeywordRuleCompletion}
                />
                <Rule
                    label={
                        <FormattedMessage defaultMessage="Inclure au moins 3 mots-clés de votre PSO Control Panel" />
                    }
                    requiresTargetKeyword={true}
                    completion={threePSOKeywordsRuleCompletion}
                    thresholds={[0, 33, 100]}
                />
                <Rule
                    label={<FormattedMessage defaultMessage="Ajouter au moins 5 tags" />}
                    requiresTargetKeyword={true}
                    completion={fiveTagsRuleCompletion}
                    thresholds={[0, 20, 100]}
                />
            </RulesWrapper>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;
const RulesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;
const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: var(--fs-m);
    font-weight: var(--fw-semibold);
    color: var(--black);
`;

export default TagsRules;
