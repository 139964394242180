import { forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import { mergeProps, useButton, useFocusRing, useHover } from 'react-aria';
import Text from '../Text';
import Flex from '../../layout/Flex';
import PrimaryButton from './PrimaryButton';
import SecondaryButton from './SecondaryButton';
import GhostButton from './GhostButton';
import IconLoader from '../../icons/IconLoader';

const variants = {
    primary: PrimaryButton,
    secondary: SecondaryButton,
    ghost: GhostButton,
};

const BaseButton = forwardRef((props, forwardedRef) => {
    const {
        as,
        to,
        replace,
        htmlFor,
        variant,
        size,
        background,
        color,
        startIcon,
        endIcon,
        isRound,
        isDisabled: isDisabledProp,
        isLoading,
        loadingIcon,
        children,
        className,
        type,
    } = props;
    const isDisabled = isDisabledProp || isLoading; // Button should be disabled if loading
    const Component = variants[variant];

    const innerRef = useRef();
    const ref = forwardedRef || innerRef;

    const { buttonProps, isPressed } = useButton(
        { ...props, isDisabled, elementType: as ?? 'button' },
        ref,
    );
    const { focusProps, isFocusVisible } = useFocusRing({ ...props, isDisabled });
    const { hoverProps, isHovered } = useHover({ ...props, isDisabled });

    return (
        <Component
            {...mergeProps(buttonProps, focusProps, hoverProps)}
            as={as}
            to={to} // Used by react-router for internal navigation
            replace={replace} // Used by react-router for internal navigation
            htmlFor={htmlFor} // Used by Uploader component
            ref={ref}
            type={type}
            className={className}
            $size={size}
            $background={background}
            $color={color}
            $isRound={isRound}
            $isDisabled={isDisabled}
            $isFocusVisible={isFocusVisible}
            $isHovered={isHovered}
            $isPressed={isPressed}
            $isLoading={isLoading}
        >
            {startIcon}
            {children && (
                <Text as="span" variant="body" className="button-text" textAlign="start">
                    {children}
                </Text>
            )}
            {endIcon}

            {isLoading && (
                <Flex
                    $position="absolute"
                    $inset="0"
                    $align="center"
                    $justify="center"
                    $background="transparent"
                    className="loading-container"
                >
                    {loadingIcon}
                </Flex>
            )}
        </Component>
    );
});

BaseButton.displayName = 'BaseButton';

BaseButton.propTypes = {
    variant: PropTypes.oneOf(['primary', 'secondary', 'ghost']),
    size: PropTypes.oneOf(['medium', 'mediumIcon', 'small', 'smallIcon']),
    background: PropTypes.string,
    color: PropTypes.string,
    startIcon: PropTypes.element,
    endIcon: PropTypes.element,
    isRound: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    loadingIcon: PropTypes.element,
    type: PropTypes.oneOf(['button', 'submit']),
    as: PropTypes.elementType,
    to: PropTypes.string,
    replace: PropTypes.bool,
    htmlFor: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
};

BaseButton.defaultProps = {
    variant: 'primary',
    loadingIcon: <IconLoader />,
    type: 'button',
};

export default BaseButton;
