import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const Benefits = () => {
    return (
        <BenefitsList>
            <Benefit>
                <IconWrapper>
                    <Icon icon={icon({ name: 'cloud', style: 'solid' })} />
                </IconWrapper>
                <BenefitContent>
                    <BenefitTitle>
                        <FormattedMessage defaultMessage="Hébergement illimité" />
                    </BenefitTitle>
                    <BenefitDescription>
                        <FormattedMessage defaultMessage="Téléchargez d'innombrables heures d'audio chaque mois." />
                    </BenefitDescription>
                </BenefitContent>
            </Benefit>
            <Benefit>
                <IconWrapper>
                    <Icon icon={icon({ name: 'rectangle-history', style: 'solid' })} />
                </IconWrapper>
                <BenefitContent>
                    <BenefitTitle>
                        <FormattedMessage defaultMessage="Nombre d'épisodes illimité" />
                    </BenefitTitle>
                    <BenefitDescription>
                        <FormattedMessage defaultMessage="Ajoutez autant d'épisodes que vous le souhaitez chaque mois." />
                    </BenefitDescription>
                </BenefitContent>
            </Benefit>
            <Benefit>
                <IconWrapper>
                    <Icon icon={icon({ name: 'headphones', style: 'solid' })} />
                </IconWrapper>
                <BenefitContent>
                    <BenefitTitle>
                        <FormattedMessage defaultMessage="Téléchargements illimités" />
                    </BenefitTitle>
                    <BenefitDescription>
                        <FormattedMessage defaultMessage="Observez votre audience grandir sans limite." />
                    </BenefitDescription>
                </BenefitContent>
            </Benefit>
            <Benefit>
                <IconWrapper>
                    <Icon icon={icon({ name: 'calendar-clock', style: 'solid' })} />
                </IconWrapper>
                <BenefitContent>
                    <BenefitTitle>
                        <FormattedMessage defaultMessage="Programmation des épisodes" />
                    </BenefitTitle>
                    <BenefitDescription>
                        <FormattedMessage defaultMessage="Publiez vos nouveaux épisodes instantanément ou programmez-les quand vous le souhaitez." />
                    </BenefitDescription>
                </BenefitContent>
            </Benefit>
            <Benefit>
                <IconWrapper>
                    <Icon icon={icon({ name: 'bookmark', style: 'solid' })} />
                </IconWrapper>
                <BenefitContent>
                    <BenefitTitle>
                        <FormattedMessage defaultMessage="Structurez vos épisodes en chapitres" />
                    </BenefitTitle>
                    <BenefitDescription>
                        <FormattedMessage defaultMessage="Ajoutez différents chapitres à vos épisodes pour faciliter l'écoute de votre émission." />
                    </BenefitDescription>
                </BenefitContent>
            </Benefit>
            <Benefit>
                <IconWrapper>
                    <Icon icon={icon({ name: 'lock-keyhole', style: 'solid' })} />
                </IconWrapper>
                <BenefitContent>
                    <BenefitTitle>
                        <FormattedMessage defaultMessage="Protégez votre contenu" />
                    </BenefitTitle>
                    <BenefitDescription>
                        <FormattedMessage defaultMessage="Mettez à jour les paramètres de confidentialité de votre épisode pour contrôler où il peut apparaître et qui peut le regarder." />
                    </BenefitDescription>
                </BenefitContent>
            </Benefit>
            <Benefit>
                <IconWrapper>
                    <Icon icon={icon({ name: 'link', style: 'solid' })} />
                </IconWrapper>
                <BenefitContent>
                    <BenefitTitle>
                        <FormattedMessage defaultMessage="Lien privé" />
                    </BenefitTitle>
                    <BenefitDescription>
                        <FormattedMessage defaultMessage="Partagez des épisodes exclusifs avec votre public grâce à un lien unique." />
                    </BenefitDescription>
                </BenefitContent>
            </Benefit>
            <Benefit>
                <IconWrapper>
                    <Icon icon={icon({ name: 'star', style: 'solid' })} />
                </IconWrapper>
                <BenefitContent>
                    <BenefitTitle>
                        <FormattedMessage defaultMessage="Partager un contenu exclusif" />
                    </BenefitTitle>
                    <BenefitDescription>
                        <FormattedMessage defaultMessage="Faites découvrir à votre public une avant-première ou un épisode bonus et rapprochez-vous de votre communauté." />
                    </BenefitDescription>
                </BenefitContent>
            </Benefit>
            <Benefit>
                <IconWrapper>
                    <Icon icon={icon({ name: 'album-collection', style: 'solid' })} />
                </IconWrapper>
                <BenefitContent>
                    <BenefitTitle>
                        <FormattedMessage defaultMessage="Créez des playlists personnalisées" />
                    </BenefitTitle>
                    <BenefitDescription>
                        <FormattedMessage defaultMessage="Classez vos épisodes par thème et permettez à vos abonnés d'avoir un accès plus organisé à votre émission." />
                    </BenefitDescription>
                </BenefitContent>
            </Benefit>
        </BenefitsList>
    );
};

const Icon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
`;
const IconWrapper = styled.div`
    background-color: var(--primary50);
    color: var(--primary500);
    border-radius: var(--r-s);
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
`;
const BenefitsList = styled.ul`
    list-style: none;
    padding-left: 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1.5rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        grid-template-columns: 1fr 1fr;
        grid-gap: 2rem;
    }
`;
const Benefit = styled.li`
    display: flex;
    align-items: start;
    column-gap: 0.75rem;
`;
const BenefitContent = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;
    flex-grow: 1;
`;
const BenefitTitle = styled.div`
    font-size: var(--fs-body-l);
    font-weight: var(--fw-semibold);
`;
const BenefitDescription = styled.div`
    color: var(--neutral500);
`;

export default Benefits;
