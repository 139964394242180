import { useEffect, useState, useRef } from 'react';
import AudioEditor from 'waveform';

const AudioWaveform = ({
    audioUrl,
    waveformDataUrl,
    start,
    end,
    onInit,
    onPlay,
    onPause,
    onTimeUpdate,
}) => {
    const [audioEditor, setAudioEditor] = useState(null);
    const segmentViewWaveformRef = useRef();

    const initAudioEditor = () => {
        const options = {
            audioUrl,
            waveformUrl: waveformDataUrl,
            segment: { start, end },
            segmentView: {
                mountPoint: segmentViewWaveformRef.current,
                height: 64,
                padding: 5,
            },
            theme: {
                background: '#ffffff',
                waveform: '#BCB8BF',
                cursor: '#751CBF',
                cursorFont: '#fff',
            },
        };

        if (audioEditor) {
            audioEditor.removeAllListeners();
        }

        const newAudioEditor = new AudioEditor(options);
        newAudioEditor.addListener('player.play', onPlay);
        newAudioEditor.addListener('player.pause', onPause);
        newAudioEditor.addListener('player.update', ({ time }) =>
            onTimeUpdate(Math.abs(time - start)),
        );
        setAudioEditor(newAudioEditor);
        onInit(newAudioEditor);

        return () => {
            // Cleaning
            newAudioEditor.destroy();
        };
    };

    useEffect(() => initAudioEditor(), [audioUrl, waveformDataUrl]);

    return <div ref={segmentViewWaveformRef} />;
};

export default AudioWaveform;
