import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    SortDirection,
    useReactTable,
} from '@tanstack/react-table';
import { FormattedMessage } from 'react-intl';
import type { ListenersEpisodesRanking } from '@/queries/stats/useListenersEpisodesRankingQuery.hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { formatDuration } from '@/shared/utils/duration';
import { TooltipTrigger } from 'react-aria-components';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';
import styled, { css } from 'styled-components';

interface TableProps {
    rows: ListenersEpisodesRanking[];
    sorting: { id: string; desc: boolean }[];
    setSorting: (sorting: { id: string; desc: boolean }[]) => void;
}

const Table = ({ rows, sorting, setSorting }: TableProps) => {
    const columnHelper = createColumnHelper<ListenersEpisodesRanking>();
    const defaultColumns = [
        columnHelper.accessor('name', {
            minSize: 600,
            header: () => <FormattedMessage defaultMessage="Titre" />,
            cell: (info) => {
                const value = info.getValue();
                return <EpisodeTitle>{value}</EpisodeTitle>;
            },
            enableSorting: false,
            meta: {
                align: 'left',
            },
        }),
        columnHelper.accessor('downloads', {
            minSize: 200,
            header: () => <FormattedMessage defaultMessage="Nombre d'écoutes" />,
            meta: {
                align: 'right',
            },
        }),
        columnHelper.accessor('averageListeningTime', {
            minSize: 200,
            enableSorting: false,
            header: () => (
                <TooltipTrigger delay={0} closeDelay={0}>
                    <TooltipTriggerWrapper>
                        <HeaderWrapper>
                            <FormattedMessage defaultMessage="Durée d’écoute moyenne" />
                            <FontAwesomeIcon icon={icon({ name: 'info-circle', style: 'solid' })} />
                        </HeaderWrapper>
                    </TooltipTriggerWrapper>
                    <Tooltip placement="top">
                        <FormattedMessage defaultMessage="La durée d’écoute moyenne concerne uniquement la plateforme Deezer pour le moment." />
                    </Tooltip>
                </TooltipTrigger>
            ),
            cell: (info) => {
                const value = info.getValue();
                if (!value || value === '-') return value;
                return formatDuration(value, {
                    initialValue: true,
                });
            },
            meta: {
                align: 'right',
            },
        }),
        columnHelper.accessor('averageCompletionRate', {
            minSize: 200,
            enableSorting: false,
            header: () => (
                <TooltipTrigger delay={0} closeDelay={0}>
                    <TooltipTriggerWrapper>
                        <HeaderWrapper>
                            <FormattedMessage defaultMessage="Taux de complétion" />
                            <FontAwesomeIcon icon={icon({ name: 'info-circle', style: 'solid' })} />
                        </HeaderWrapper>
                    </TooltipTriggerWrapper>
                    <Tooltip placement="top">
                        <FormattedMessage defaultMessage="Le taux de completion concerne uniquement la plateforme Deezer pour le moment." />
                    </Tooltip>
                </TooltipTrigger>
            ),
            cell: (info) => {
                const value = info.getValue();
                return !value || value === '-' ? value : `${value}%`;
            },
            meta: {
                align: 'right',
            },
        }),
    ];

    const table = useReactTable({
        data: rows,
        columns: defaultColumns,
        getCoreRowModel: getCoreRowModel(),
        enableColumnResizing: false,
        manualSorting: true,
        state: {
            sorting,
        },
        onSortingChange: setSorting,
        initialState: {
            columnPinning: {
                left: ['name'],
            },
        },
    });

    const sortIconRender = (isSorted: false | SortDirection) => {
        return isSorted === 'asc' ? (
            <SortIcon
                className="sort-icon"
                icon={icon({ name: 'arrow-up', style: 'solid' })}
                size="lg"
            />
        ) : (
            <SortIcon
                className="sort-icon"
                icon={icon({ name: 'arrow-down', style: 'solid' })}
                size="lg"
            />
        );
    };

    return (
        <TableElement>
            <Thead>
                {table.getHeaderGroups().map((headerGroup) => (
                    <Tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) =>
                            header.isPlaceholder ? null : (
                                <Th
                                    key={header.id}
                                    sortable={header.column.getCanSort()}
                                    isSorted={header.column.getIsSorted() !== false}
                                    onClick={header.column.getToggleSortingHandler()}
                                >
                                    <ThContent
                                        sortable={header.column.getCanSort()}
                                        onClick={header.column.getToggleSortingHandler()}
                                    >
                                        {flexRender(
                                            header.column.columnDef.header,
                                            header.getContext(),
                                        )}

                                        {sortIconRender(header.column.getIsSorted())}
                                    </ThContent>
                                </Th>
                            ),
                        )}
                    </Tr>
                ))}
            </Thead>
            <Tbody>
                {table.getRowModel().rows.map((row) => (
                    <Tr key={row.id}>
                        {row.getVisibleCells().map((cell) => (
                            <Td
                                key={cell.id}
                                style={{
                                    width: `${cell.column.getSize()}px`,
                                    maxWidth: '100%',
                                }}
                                align={cell.column.columnDef.meta?.align || 'left'}
                            >
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </Td>
                        ))}
                    </Tr>
                ))}
            </Tbody>
        </TableElement>
    );
};

const TableElement = styled.table`
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;
`;
const Thead = styled.thead``;
const Td = styled.td<{ align: 'left' | 'right' }>`
    padding: 1rem;
    text-align: ${(props) => props.align};
`;
const Tr = styled.tr`
    & > ${Td}:not(:last-of-type) {
        border-right: 1px solid var(--neutral100);
    }
`;
const Th = styled.th<{ sortable: boolean; isSorted: boolean }>`
    padding: 1rem;
    text-align: left;

    ${(props) =>
        props.sortable &&
        css`
            cursor: pointer;
        `};

    .sort-icon {
        margin-left: 0.5rem;
        visibility: ${(props) => (props.isSorted ? 'visible' : 'hidden')};
    }

    &:hover .sort-icon {
        visibility: ${(props) => (props.sortable ? 'visible' : 'hidden')};
    }

    @media (min-width: 768px) {
        white-space: nowrap;
    }

    &:first-of-type {
        border-top-left-radius: var(--r-m);
        border-bottom-left-radius: var(--r-m);
    }
    &:last-of-type {
        border-top-right-radius: var(--r-m);
        border-bottom-right-radius: var(--r-m);
    }
`;
const ThContent = styled.div<{ sortable: boolean }>`
    display: inline-flex;
    align-items: center;
    column-gap: 0.5rem;
    transition-duration: 0.2s;
    width: 100%;
    align-self: stretch;
    font-weight: var(--fw-semibold);

    ${(props) =>
        props.sortable &&
        css`
            cursor: pointer;
        `}
`;
const Tbody = styled.tbody`
    & > ${Tr}:not(:last-of-type) {
        border-bottom: 1px solid var(--neutral100);
    }
`;
const SortIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
    font-size: 0.75rem;
`;
const HeaderWrapper = styled.div`
    display: inline-flex;
    align-items: center;
    column-gap: 0.25rem;
    color: var(--black);

    & > svg {
        color: var(--neutral);
    }
`;
const EpisodeTitle = styled.div`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 40rem;
    min-width: 0;

    @media (max-width: 768px) {
        max-width: 18rem;
    }
`;

export default Table;
