import { PRICING } from '@/utils/pricing';

export const GEO_JSON_BASE_CDN_URI = 'https://cdn-app-ausha.b-cdn.net/maps';
export const COUNTRY_LAYER = 'countries';
export const GENERIC_REGION_LAYER = 'regions';
export const CITY_LAYER = 'cities';
export const ALL_REGION_LAYERS = [
    'be-regions',
    'ca-regions',
    'ch-regions',
    'de-regions',
    'es-regions',
    'fr-regions',
    'it-regions',
    'pt-regions',
    'uk-regions',
    'us-regions',
];
export const REGION_PARENT_COUNTRY_LOOKUP = {
    'be-regions': '2802361',
    'ca-regions': '6251999',
    'ch-regions': '2658434',
    'de-regions': '2921044',
    'es-regions': '2510769',
    'fr-regions': '3017382',
    'it-regions': '3175395',
    'pt-regions': '2264397',
    'uk-regions': '2635167',
    'us-regions': '6252001',
};
export const ALL_LAYERS = [...ALL_REGION_LAYERS, COUNTRY_LAYER, CITY_LAYER];

export const MAX_BACKGROUND_OPACITY = 0.7;
export const DEFAULT_MAP_CENTER = [1.71, 46.71]; // 🇫🇷 🥖
export const DEFAULT_ZOOM_LEVEL = 2;
export const MAX_ZOOM_LEVEL_COUNTRIES = 3;
export const MIN_ZOOM_LEVEL_REGIONS = 3;
export const MAX_ZOOM_LEVEL_REGIONS = 5;
export const MIN_ZOOM_LEVEL_POINTS = 5;
export const PLAN_MAX_ZOOM_LOOKUP = {
    [PRICING.LAUNCH]: 2.9,
    [PRICING.BOOST]: 4.9,
    [PRICING.SUPERSONIC]: Infinity,
    [PRICING.ENTERPRISE]: Infinity,
};
export const PROJECTION_MODES = {
    MERCATOR: 'mercator',
    GLOBE: 'globe',
};
export const MAPBOX_MAP_STYLE_URI = 'mapbox://styles/product-ausha/cl7or9x93002f15ry341g9p6q';
export const UNKNOWN_REGION_CODE = 'ZZZ';
export const UNKNOWN_CITY_NAME = 'Unknown';
