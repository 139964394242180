import styled from 'styled-components';

function getFirstDefined(...args) {
    for (let i = 0; i < args.length; i += 1) {
        if (args[i] !== undefined) return args[i];
    }
    return undefined;
}

export const reflex = (Component) =>
    styled(Component)((p) => ({
        transition: p.cssAnim ? `all ${p.cssAnim}s` || 'all 0.5s' : undefined,
        display: p.flex ? 'flex' : p.display || undefined,
        flexDirection: p.column || p.flexDirection ? p.flexDirection || 'column' : undefined,
        alignItems: p.alignItems || p.align || undefined,
        alignSelf: p.alignSelf || undefined,
        justifyContent: p.justify || undefined,
        justifySelf: p.justifySelf || undefined,
        flex: p.auto || p.flexCustom ? p.flexCustom || '1 1 auto' : undefined,
        flexWrap: p.wrap ? 'wrap' : p.wrap,
        flexShrink: p.flexShrink,
        overflow: p.overflow,
        overflowX: p.overflowX,
        overflowY: p.overflowY,
        scrollSnapType: p.scrollSnapType,
        scrollSnapAlign: p.scrollSnapAlign,
        opacity: p.opacity,
        whiteSpace: (p.noWrap && 'nowrap') || p.whiteSpace,
        textAlign: p.textAlign,
        fontSize: p.fontSize,
        boxSizing: p.boxSizing,
        textTransform: (p.capitalize && 'capitalize') || p.textTransform,
        lineHeight: p.lineHeight,
        cursor: (p.cursor ? p.cursor : undefined) || (p.pointer ? 'pointer' : undefined),
        listStyle: p.listStyle,

        position: p.pos || p.position,
        left: getFirstDefined(p.x, p.left),
        right: getFirstDefined(p.xR, p.right),
        top: getFirstDefined(p.y, p.top),
        bottom: getFirstDefined(p.yR, p.bottom),
        zIndex: p.z,

        minWidth: p.minW,
        maxHeight: p.maxH,
        minHeight: p.minH,
        maxWidth: p.maxW,
        width: p.width || p.w || p.size,
        height: p.height || p.h || p.size,

        paddingTop: getFirstDefined(p.pt, p.py, p.p),
        paddingBottom: getFirstDefined(p.pb, p.py, p.p),
        paddingLeft: getFirstDefined(p.pl, p.px, p.p),
        paddingRight: getFirstDefined(p.pr, p.px, p.p),

        marginTop: getFirstDefined(p.mt, p.my, p.m),
        marginBottom: getFirstDefined(p.mb, p.my, p.m),
        marginLeft: getFirstDefined(p.ml, p.mx, p.m),
        marginRight: getFirstDefined(p.mr, p.mx, p.m),

        gap: p.gap,
    }));
