import { useRef, useState } from 'react';
import CheckBoxList from './CheckboxList';
import ComboBoxDropdown from '@/components/ui/atoms/ComboBox/ComboBoxDropdown';
import styled from 'styled-components';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { Control, FieldPath, useController } from 'react-hook-form';
import Label from '@/components/ui/atoms/Label';
import { Selection } from 'react-aria-components';
import CheckboxListItem, { ICheckboxListItem } from './CheckboxListItem';
import ErrorMessage from '@/components/ui/atoms/ErrorMessage';
import ImagePlaceholder from './ImagePlaceholder';

export interface MutipleShowsSelectProps {
    className?: string;
    control: Control<any>;
    name: FieldPath<any>;
    items: ICheckboxListItem[];
    isRequired?: boolean;
}

const MutipleShowsSelect = ({
    control,
    name,
    className,
    items,
    isRequired,
}: MutipleShowsSelectProps) => {
    const triggerRef = useRef<HTMLDivElement>(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { field, fieldState } = useController({
        name,
        control,
    });
    const hasError = !!fieldState.error;
    const value = field.value as number[];

    const handleOpenDropdown = () => {
        setIsDropdownOpen(true);
    };

    const handleCloseDropdown = () => {
        setIsDropdownOpen(false);
    };

    const handleSelectionChange = (keys: Selection) => {
        const keysArray = Array.from(keys);
        const newKeys = keys === 'all' ? items.map((item) => item.id) : keysArray;
        field.onChange(newKeys);
    };

    const handleSelectAll = () => {
        const allItemsSelected = items.length === value.length;
        const someItemsSelected = value.length > 0 && !allItemsSelected;

        if (someItemsSelected || allItemsSelected) {
            field.onChange([]);
            return;
        }

        const allKeys = items.map((item) => item.id);
        field.onChange(allKeys);
    };

    return (
        <SelectWrapper className={className}>
            <Label isRequired={isRequired}>
                <FormattedMessage defaultMessage="Émissions attribuées" />
            </Label>
            <FakeInput
                ref={triggerRef}
                onFocus={handleOpenDropdown}
                onClick={handleOpenDropdown}
                $hasError={hasError}
            >
                <ImagePlaceholder items={items} selectedItems={value} />
                <ChevronIcon
                    icon={icon({
                        name: 'chevron-down',
                        style: 'solid',
                    })}
                />
            </FakeInput>
            {hasError && <ErrorMessage>{fieldState.error?.message}</ErrorMessage>}
            <ComboBoxDropdown
                shouldFlip={false}
                placement="bottom"
                offset={4}
                triggerRef={triggerRef}
                isOpen={isDropdownOpen}
                onOpenChange={handleCloseDropdown}
            >
                <CheckBoxList
                    selectedKeys={new Set(value)}
                    selectionMode="multiple"
                    onSelectionChange={handleSelectionChange}
                    onSelectAll={handleSelectAll}
                    items={items}
                >
                    {(item) => <CheckboxListItem {...item} />}
                </CheckBoxList>
            </ComboBoxDropdown>
        </SelectWrapper>
    );
};

const SelectWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
`;
const FakeInput = styled.div<{ $hasError: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 2.5rem;
    padding: 0.625rem 0.75rem;
    border-radius: var(--r-s);
    border: 1px solid var(--neutral200);
    cursor: pointer;

    &:focus-within {
        border-color: var(--primary500);
        box-shadow: 0px 0px 0px 4px rgba(117, 28, 191, 0.1);
    }

    ${({ $hasError }) =>
        $hasError &&
        `
        border-color: var(--alert500);
    `}
`;
const ChevronIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;

export default MutipleShowsSelect;
