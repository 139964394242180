import { Component } from 'react';
import { Prompt } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import DeprecatedModal from '@ui/molecules/DeprecatedModal';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import DeprecatedButton from '@ui/atoms/DeprecatedButton';
import Spinner from '@ui/atoms/Spinner';

// Based upon https://kamranicus.com/posts/2018-07-26-react-router-custom-transition-ui

class PreventFormOnTransition extends Component {
    state = { open: false };
    __trigger = Symbol.for(`__PreventTransitionPrompt_${crypto.randomUUID()}`);

    static defaultProps = {
        routes: [],
    };

    componentDidMount() {
        window[this.__trigger] = this.show;
    }

    componentWillUnmount() {
        delete window[this.__trigger];
    }

    render() {
        const { when, isLoading } = this.props;
        return (
            <>
                <Prompt when={when} message={this.handleTransition} />
                <DeprecatedModal
                    isOpened={this.state.open}
                    onClose={this.close}
                    title={<FormattedMessage defaultMessage="Enregistrer les modifications" />}
                >
                    <DeprecatedText mb={30}>
                        <FormattedMessage defaultMessage="Souhaitez-vous enregistrer les modifications effectuées ?" />
                    </DeprecatedText>
                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <DeprecatedButton accent="secondary" mr={10} onClick={this.notSave}>
                            <FormattedMessage defaultMessage="Ne pas enregistrer" />
                        </DeprecatedButton>
                        <DeprecatedButton onClick={this.save} disabled={isLoading}>
                            {isLoading && <Spinner />}
                            <FormattedMessage defaultMessage="Enregistrer" />
                        </DeprecatedButton>
                    </div>
                </DeprecatedModal>
            </>
        );
    }

    close = () => this.setState({ open: false }, () => this.allowTransitionCallback(false));

    save = async () => {
        if (!this.props.valid) {
            this.close();
            return;
        }
        try {
            await this.props.onSubmit();
            this.allowTransitionCallback(true);
        } catch (e) {
            //
        }
        this.close();
    };

    notSave = () => {
        this.allowTransitionCallback(true);
    };

    show = (allowTransitionCallback) => {
        this.allowTransitionCallback = allowTransitionCallback;
        this.setState({ open: true });
    };

    handleTransition = (location) => {
        const isAllowed = this.props.routes.reduce(
            (acc, route) => acc || location.pathname.match(new RegExp(`^${route}`)),
            false,
        );
        if (isAllowed) {
            return true;
        }
        return Symbol.keyFor(this.__trigger);
    };
}

export default PreventFormOnTransition;
