import { FormattedMessage } from 'react-intl';
import HideFor from '@app/atoms/HideFor';
import { STATUS_SHOWS } from '@/utils/statusShows';
import { USER_OPTIONS } from '@/utils/userOptions';
import styled from 'styled-components';
import Text from '@/components/ui/atoms/Text';
import { useState } from 'react';
import ShowGuestsAddButton from '../ShowGuestsAddButton';
import Button from '@/components/Button';
import GuestRolesDetailsModal from '@/components/GuestRolesDetailsModal';

const ShowGuestsHeader = () => {
    const [isRoleDetailsModalOpen, setIsRoleDetailsModalOpen] = useState(false);

    return (
        <UsersHeaderWrapper>
            <Description>
                <FormattedMessage
                    defaultMessage="Les autres utilisateurs peuvent, selon leur rôle, consulter ou gérer certaines données de l’émission telles que les épisodes, statistiques, etc. <button>Détail des rôles</button>"
                    values={{
                        button: (chunks: string) => (
                            <Button
                                variant="link-primary"
                                onPress={() => setIsRoleDetailsModalOpen(true)}
                            >
                                {chunks}
                            </Button>
                        ),
                    }}
                />
            </Description>
            <HideFor
                userOptions={USER_OPTIONS.INVITATIONS}
                showStatus={[STATUS_SHOWS.ARCHIVED_SHOWS]}
            >
                <DesktopShowGuestsAddButton>
                    <FormattedMessage defaultMessage="Ajouter un utilisateur" />
                </DesktopShowGuestsAddButton>
                <MobileShowGuestsAddButton />
            </HideFor>

            <GuestRolesDetailsModal
                isOpen={isRoleDetailsModalOpen}
                onOpenChange={setIsRoleDetailsModalOpen}
            />
        </UsersHeaderWrapper>
    );
};

const UsersHeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
`;
const Description = styled(Text)`
    color: var(--neutral500);
    max-width: 550px;
`;
const DesktopShowGuestsAddButton = styled(ShowGuestsAddButton)`
    display: none;

    ${(p) => p.theme.mediaQueries.desktopAndUp} {
        display: inline-flex;
    }
`;
const MobileShowGuestsAddButton = styled(ShowGuestsAddButton)`
    position: fixed;
    bottom: 1.25rem;
    right: 1.5rem;
    border-radius: var(--r-full);
    width: 3.5rem;
    height: 3.5rem;

    ${(p) => p.theme.mediaQueries.desktopAndUp} {
        display: none;
    }
`;

export default ShowGuestsHeader;
