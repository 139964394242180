import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addRssImport, deleteRssImport } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import rssKeys from './rssKeys';

export interface MutationFnProps {
    isEdit: boolean;
    showId: string;
    payload: { url: string };
}

const mutationFn = async ({ isEdit, showId, payload }: MutationFnProps) => {
    if (isEdit) {
        await deleteRssImport(showId);
    }
    const { data } = await addRssImport(showId, payload);
    return camelcaseKeys(data, { deep: true });
};

const useAddOrUpdateRssUrlMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: (_) => {
            queryClient.invalidateQueries({
                queryKey: rssKeys.all(),
            });
        },
    });
};

export default useAddOrUpdateRssUrlMutation;
