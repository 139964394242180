import { css } from 'styled-components';
import { linkDisabledStyles, linkFocusStyles, linkLayoutStyles } from '../base';

export const primaryLinkStyles = css`
    ${linkLayoutStyles}
    ${linkFocusStyles}
    ${linkDisabledStyles}

    font-weight: var(--fw-semibold);
    color: var(--primary500);
    text-decoration: none;

    &[data-hovered] {
        color: var(--primary600);
    }
`;
