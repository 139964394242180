import { useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FormattedMessage } from 'react-intl';
import Text from '@ui/atoms/Text';
import Card from '@/features/onboarding/components/Onboarding/Card';
import Button from '@/components/Button';
import useSubscriptionQuery from '@queries/subscription/useSubscriptionQuery.hook';
import useShowsQuery from '@queries/show/useShowsQuery.hook';
import HostedShowsQuotaModal from '@/shared/components/HostedShowsQuotaModal';
import FreemiumModal from '@/features/onboarding/components/Onboarding/FreemiumModal';

const ImportShow = () => {
    const history = useHistory();
    const { data: shows } = useShowsQuery();
    const { data: subscription } = useSubscriptionQuery();
    const isFreemium = subscription?.isFreemium;
    const [isHostedShowsQuotaModalOpen, setIsHostedShowsQuotaModalOpen] = useState(false);
    const [isFreemiumModalOpen, setIsFreemiumModalOpen] = useState(false);
    const activeShowAllowance = (subscription?.showsMax || 0) + (subscription?.extraShows || 0);
    const currentActiveShows =
        shows?.shows?.filter((show: any) => !show.archived && show.isOwner).length || 0;

    return (
        <Wrapper>
            <SubHeader>
                <Text variant="headingL" fontWeight="--fw-bold">
                    <FormattedMessage defaultMessage="Souhaitez-vous héberger votre podcast sur Ausha ou conserver votre hébergeur actuel ?" />
                </Text>
                <Text color="--neutral500">
                    <FormattedMessage defaultMessage="Hébergez votre podcast sur Ausha pour gérer votre podcast en une seule et même plateforme ou gardez votre hébergeur actuel tout en accédant à nos outils marketing !" />
                </Text>
            </SubHeader>
            <Cards>
                <Card
                    image={<CreateIcon icon={icon({ name: 'cloud-arrow-down', style: 'solid' })} />}
                    title={<FormattedMessage defaultMessage="Héberger sur Ausha" />}
                    description={
                        <FormattedMessage defaultMessage="Importez facilement votre podcast sur Ausha et bénéficiez d'un hébergement illimité et de puissants outils marketing." />
                    }
                    to="/app/onboarding/import/hosting"
                    onClick={(e) => {
                        if (currentActiveShows >= activeShowAllowance) {
                            e.preventDefault();
                            isFreemium
                                ? setIsFreemiumModalOpen(true)
                                : setIsHostedShowsQuotaModalOpen(true);
                        }
                    }}
                />
                <Card
                    image={<CreateIcon icon={icon({ name: 'signal-stream', style: 'solid' })} />}
                    title={<FormattedMessage defaultMessage="Garder mon hébergeur actuel" />}
                    description={
                        <FormattedMessage defaultMessage="Conservez votre hébergeur actuel et profitez des outils marketing d'Ausha pour faire grandir votre podcast." />
                    }
                    to="/app/onboarding/import/nonHosting"
                />
            </Cards>
            <BackButton
                variant="link-secondary"
                startIcon={<ArrowIcon icon={icon({ name: 'arrow-left', style: 'solid' })} />}
                onPress={() => history.goBack()}
            >
                <FormattedMessage id="goBack" defaultMessage="Retour" />
            </BackButton>
            <HostedShowsQuotaModal
                isOpen={isHostedShowsQuotaModalOpen}
                onOpenChange={setIsHostedShowsQuotaModalOpen}
            />
            <FreemiumModal isOpen={isFreemiumModalOpen} onOpenChange={setIsFreemiumModalOpen} />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
`;
const SubHeader = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;
const Cards = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;
const CreateIcon = styled(FontAwesomeIcon)`
    width: 1.5rem;
    height: 1.5rem;
`;
const BackButton = styled(Button)`
    margin: auto;
`;
const ArrowIcon = styled(FontAwesomeIcon)`
    margin-right: 0.25rem;
`;

export default ImportShow;
