import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import objectSupport from 'dayjs/plugin/objectSupport';
import styled from 'styled-components';
import { useParams } from 'react-router';
import useQuery from '@/shared/hooks/useQuery';
import Text from '@/components/ui/atoms/Text';
import PopularHoursChart from './PopularHoursChart';
import usePeakTimePopularHoursQuery from '@/queries/stats/usePeakTimePopularHoursQuery.hook';
import { LAUNCH_TIME_MESSAGE } from '../PeakTime';
import ShortListFallback from '@/components/unorganized/ShortListFallback';

dayjs.extend(objectSupport);

const X_AXIS_CATEGORIES = [
    '00 H',
    '01 H',
    '02 H',
    '03 H',
    '04 H',
    '05 H',
    '06 H',
    '07 H',
    '08 H',
    '09 H',
    '10 H',
    '11 H',
    '12 H',
    '13 H',
    '14 H',
    '15 H',
    '16 H',
    '17 H',
    '18 H',
    '19 H',
    '20 H',
    '21 H',
    '22 H',
    '23 H',
];

const PopularHours = () => {
    const { showId } = useParams<{ showId: string }>();
    const query = useQuery();
    const daysRange = (query.get('days') as '7' | '15' | '30' | '90' | 'always') ?? 'always';
    const popularHoursQuery = usePeakTimePopularHoursQuery({ showId, days: daysRange });

    if (popularHoursQuery.data) {
        const series = popularHoursQuery.data.map((day) => day.downloads);
        // Use empty array if all values are 0 to avoid rendering bars in the chart
        const formattedSeries = series.some((unit) => unit > 0) ? series : [];

        return (
            <Wrapper>
                <TextWrapper>
                    <Text variant="title" fontWeight="--fw-bold">
                        <FormattedMessage defaultMessage="Heures les plus populaires" />
                    </Text>
                    <Text>
                        {daysRange === 'always' ? (
                            <FormattedMessage defaultMessage="Vos écoutes selon les heures depuis le début" />
                        ) : (
                            <FormattedMessage
                                defaultMessage="Vos écoutes selon les jours sur les {messageLaunchTime}"
                                values={{ messageLaunchTime: LAUNCH_TIME_MESSAGE[daysRange] }}
                            />
                        )}
                    </Text>
                </TextWrapper>
                <ChartContainer>
                    <PopularHoursChart
                        options={{ xaxis: { categories: X_AXIS_CATEGORIES } }}
                        series={formattedSeries}
                    />
                </ChartContainer>
            </Wrapper>
        );
    }

    return <ShortListFallback />; // TODO: Improve loading state
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
`;
const ChartContainer = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding-mobile);

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        padding: var(--container-padding);
    }
`;
const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export default PopularHours;
