import { useState } from 'react';
import Button from '@/components/Button';
import ExternalLink from '@/components/Link/ExternalLink';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import useSubscriptionQuery from '@/queries/subscription/useSubscriptionQuery.hook';
import { USER_ROLES } from '@/shared/config/constants';
import { PRICING } from '@/shared/config/pricing';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TooltipTrigger } from 'react-aria-components';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import styled from 'styled-components';
import EpisodeAISubscriptionModal from '../EpisodeAISubscriptionModal';
import useChannelQuery from '@/queries/channel/useChannelQuery.hook';
import EpisodeAIFreeTrialModal from '../EpisodeAIFreeTrialModal';

interface EpisodeAIUnauthorizedStateActionProps {
    testId: string;
    onAction?: () => void;
}

const EpisodeAIUnauthorizedStateAction = ({
    testId,
    onAction,
}: EpisodeAIUnauthorizedStateActionProps) => {
    const { showId, episodeId } = useParams<{ showId: string; episodeId: string }>();
    const channel = useChannelQuery();
    const show = useShowQuery(showId);
    const subscription = useSubscriptionQuery();
    const isFreemium = subscription.data?.isFreemium;
    const isPro = subscription.data?.pricing === PRICING.ENTERPRISE;
    const isGuest = show.data?.userRole !== USER_ROLES.OWNER;
    const hasUsedFreeTrial = channel.data?.aiFreeTrialUsed;
    const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);
    const [isFreeTrialModalOpen, setIsFreeTrialModalOpen] = useState(false);

    if (isGuest) {
        return (
            <TooltipTrigger delay={0} closeDelay={0}>
                <ButtonTooltipTriggerWrapper>
                    <Button
                        isDisabled
                        variant="magic"
                        startIcon={<GemIcon icon={icon({ name: 'gem', style: 'duotone' })} />}
                        data-testid={`episode-ai-${testId}-unlock-guest-button`}
                    >
                        <FormattedMessage defaultMessage="Débloquer la puissance de AI" />
                    </Button>
                </ButtonTooltipTriggerWrapper>
                <Tooltip placement="top">
                    <FormattedMessage defaultMessage="Seul le propriétaire du compte peut activer Ausha Inteligence." />
                </Tooltip>
            </TooltipTrigger>
        );
    }

    // Ensure that the user has not already used the free trial and
    // that we are not in the context of a episode creation
    if (!hasUsedFreeTrial && !!episodeId) {
        return (
            <>
                <Button
                    startIcon={<GemIcon icon={icon({ name: 'sparkles', style: 'duotone' })} />}
                    variant="magic"
                    data-testid="episode-ai-free-trial-button"
                    onPress={() => setIsFreeTrialModalOpen(true)}
                >
                    <FormattedMessage defaultMessage="Essayez AI gratuitement" />
                </Button>
                <EpisodeAIFreeTrialModal
                    isOpen={isFreeTrialModalOpen}
                    onOpenChange={setIsFreeTrialModalOpen}
                />
            </>
        );
    }

    if (isFreemium) {
        return (
            <TooltipTrigger delay={0} closeDelay={0}>
                <ButtonTooltipTriggerWrapper>
                    <Button
                        isDisabled
                        variant="magic"
                        startIcon={<GemIcon icon={icon({ name: 'gem', style: 'duotone' })} />}
                        data-testid={`episode-ai-${testId}-unlock-freemium-button`}
                    >
                        <FormattedMessage defaultMessage="Débloquer la puissance de AI" />
                    </Button>
                </ButtonTooltipTriggerWrapper>
                <Tooltip placement="top">
                    <FormattedMessage defaultMessage="Le déblocage d'Ausha Intelligence est réservé aux abonnés." />
                </Tooltip>
            </TooltipTrigger>
        );
    }

    if (isPro) {
        return (
            <ExternalLink
                href="mailto:sales@ausha.co"
                variant="button-magic"
                startIcon={<GemIcon icon={icon({ name: 'gem', style: 'duotone' })} />}
                data-testid={`episode-ai-${testId}-unlock-pro-button`}
            >
                <FormattedMessage defaultMessage="Contactez nous" />
            </ExternalLink>
        );
    }

    return (
        <>
            <Button
                onPress={() => setIsSubscriptionModalOpen(true)}
                startIcon={<GemIcon icon={icon({ name: 'gem', style: 'duotone' })} />}
                variant="magic"
                data-testid={`episode-ai-${testId}-unlock-button`}
            >
                <FormattedMessage defaultMessage="Débloquer la puissance de AI" />
            </Button>
            <EpisodeAISubscriptionModal
                isOpen={isSubscriptionModalOpen}
                onOpenChange={setIsSubscriptionModalOpen}
                onSubmit={onAction}
            />
        </>
    );
};

const GemIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: var(--white);
`;
const ButtonTooltipTriggerWrapper = styled(TooltipTriggerWrapper)`
    align-self: stretch;
    & > button {
        width: 100%;
    }
`;

export default EpisodeAIUnauthorizedStateAction;
