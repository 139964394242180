import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useModalToastQueue } from '@/shared/hooks/useModalToastQueue.hook';
import { useHistory } from 'react-router';

const EmailConfirmationPage = () => {
    const history = useHistory();
    const toast = useModalToastQueue();

    useEffect(() => {
        history.push('/app');
        toast.success(<FormattedMessage defaultMessage="Votre adresse e-mail est validée !" />);
    }, []);

    return null;
};

export default EmailConfirmationPage;
