import { useEffect, useState } from 'react';
import EpisodeAISuggestionsPanel from '../EpisodeAISuggestionsPanel';
import { FormattedMessage } from 'react-intl';
import { useQueryClient } from '@tanstack/react-query';
import aiKeys from '@/queries/ai/aiKeys';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router';
import { useEpisodeAILoading } from '@/components/NonHostingEpisodeForm/contexts/NonHostingEpisodeAILoadingContext';
import { useEpisodeAISettings } from '@/components/NonHostingEpisodeForm/contexts/NonHostingEpisodeAISettingsContext';
import { sendAmplitudeLogEvent } from '@/helpers';
import useAINewsletterTitleSuggestionsQuery from '@/queries/ai/useAINewsletterTitleSuggestionQuery.hook';

const EpisodeAINewsletterTitleSuggestionPanel = () => {
    const { episodeId } = useParams<{ episodeId: string }>();
    const [settings] = useEpisodeAISettings();
    const newsletterSettings = settings.get('newsletter') || {
        customPrompt: '',
        saveAsDefault: false,
    };
    const newsletterTitleSuggestionsQuery = useAINewsletterTitleSuggestionsQuery({
        episodeId,
        settings: newsletterSettings,
        delay: 2000,
    });
    const [state] = useEpisodeAILoading();
    const isLoading = state.get('newsletter-title') || false;
    const queryClient = useQueryClient();
    const { setValue } = useFormContext();
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (isLoading) {
            setIsOpen(true);
        }
    }, [isLoading]);

    useEffect(() => {
        // If the query is successful and the panel displayed, set the value of the form field
        if (newsletterTitleSuggestionsQuery.data && newsletterTitleSuggestionsQuery.isSuccess) {
            setValue('newsletter.title', newsletterTitleSuggestionsQuery.data, {
                shouldDirty: true,
            });
        }
    }, [newsletterTitleSuggestionsQuery.data, newsletterTitleSuggestionsQuery.isSuccess]);

    if (!isOpen) return null;

    const handleClose = () => {
        // Reset the suggestions query when the panel is closed
        queryClient.resetQueries({ queryKey: aiKeys.newsletterTitle() });
        setIsOpen(false);
    };

    const handleDislike = () => {
        sendAmplitudeLogEvent('AI Dislike', {
            field: 'newsletter',
            custom_prompt: newsletterSettings.customPrompt || '',
            suggestions: newsletterTitleSuggestionsQuery.data || '',
        });
    };

    return (
        <EpisodeAISuggestionsPanel
            title={<FormattedMessage defaultMessage="Votre newsletter est prête !" />}
            type="newsletter-title"
            isLoading={isLoading}
            onClose={handleClose}
            data-testid="episode-ai-newsletter-suggestions-panel"
            onDislike={handleDislike}
        />
    );
};

export default EpisodeAINewsletterTitleSuggestionPanel;
