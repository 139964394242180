import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Text from '@ui/atoms/Text';
import Button from '@/components/Button';
import useAgesExportQuery from '@/queries/stats/useAgesExportQuery.hook';
import { useStatsDateSelectorContext } from '@/context/StatsDateSelectorContext';
import { useParams } from 'react-router';
import { download } from '@/helpers';

const AudienceAgeExport = () => {
    const { showId } = useParams<{ showId: string }>();
    const { startDate, endDate } = useStatsDateSelectorContext();
    const { data: exportedData } = useAgesExportQuery({
        showId,
        query: { start: startDate, end: endDate },
    });

    const handleExport = () => download(exportedData?.ages, exportedData?.filename, 'txt');

    return (
        <Export
            variant="ghost"
            startIcon={
                <FontAwesomeIcon
                    icon={icon({ name: 'arrow-up-right-from-square', style: 'solid' })}
                />
            }
            onPress={handleExport}
        >
            <Text fontWeight="--fw-semibold">
                <FormattedMessage defaultMessage="Exporter en .csv" />
            </Text>
        </Export>
    );
};

const Export = styled(Button)`
    display: flex;
    margin-left: auto;
    padding-top: 1rem;
    padding-bottom: 2rem;
`;

export default AudienceAgeExport;
