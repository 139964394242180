import { useQuery } from '@tanstack/react-query';
import { fetchPosts } from '@/api';
import socialNetworkPostKeys from '@/queries/socialNetworkPost/socialNetworkPostKeys';
import camelcaseKeys from 'camelcase-keys';

const transform = (data) => {
    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const queryFn = async ({ queryKey: [{ showId, currentPage, perPage }] }) => {
    const res = await fetchPosts(showId, currentPage, perPage);
    const { data, meta } = res;
    return transform({ posts: data, meta });
};

const useSocialNetworkPostsQuery = (
    { showId, currentPage, perPage },
    { enabled } = { enabled: true },
) => {
    return useQuery({
        queryKey: socialNetworkPostKeys.list({ showId, currentPage, perPage }),
        queryFn,
        enabled,
    });
};

export default useSocialNetworkPostsQuery;
