import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import UsePortal from '../UsePortal';

const Portal = ({ id, children }) => {
    const target = UsePortal(id);
    useEffect(() => {
        const body = document.getElementsByTagName('body');
        body[0].style.overflowY = 'clip';
        return () => {
            body[0].style.overflowY = 'auto';
        };
    });
    return createPortal(children, target);
};

export default Portal;
