import styled, { css } from 'styled-components';

const SidebarList = styled.ul<{ breaksAt?: string }>`
    padding-left: 0;
    display: flex;
    align-items: flex-start;
    gap: 1.5rem;
    overflow-x: auto;

    ${({ breaksAt }) =>
        breaksAt &&
        css`
            @media screen and (min-width: ${breaksAt}) {
                flex-direction: column;
                gap: 1rem;
                overflow-x: auto;

                -ms-overflow-style: none;
                scrollbar-width: none;

                &::-webkit-scrollbar {
                    display: none;
                }
            }
        `}
`;

export default SidebarList;
