import { IntlProvider as ReactIntlProvider } from 'react-intl';
import { observer } from 'mobx-react';
import messagesFr from '../../../lang/fr.json';
import messagesEn from '../../../lang/en.json';
import { I18nProvider } from 'react-aria';
import useUserLanguage from '@/shared/hooks/useUserLanguage';

const loadLocaleData = (locale: string) => {
    // TODO: Improve using dynamic imports
    switch (locale) {
        case 'en':
            return messagesEn;
        default:
            return messagesFr;
    }
};

interface IntlProviderProps {
    children: React.ReactNode;
}

const IntlProvider = observer(({ children }: IntlProviderProps) => {
    const userLanguage = useUserLanguage();
    const messages = loadLocaleData(userLanguage);

    return (
        <ReactIntlProvider locale={userLanguage} defaultLocale="fr" messages={messages}>
            <I18nProvider locale={userLanguage}>{children}</I18nProvider>
        </ReactIntlProvider>
    );
});

export default IntlProvider;
