import { action, computed, extendObservable } from 'mobx';
import Field from './Field';

function unflatten(data) {
    const result = {};
    for (const i in data) {
        const keys = i.split('.');
        keys.reduce(
            (r, e, j) =>
                r[e] ||
                (r[e] = isNaN(Number(keys[j + 1])) ? (keys.length - 1 == j ? data[i] : {}) : []),
            result,
        );
    }
    return result;
}

export default class Form {
    fields = {};

    constructor(
        fieldsObj,
        {
            onlyDiff = true,
            warnIfNotSaved = true,
            autosave = false,
            checkIfChanged = true,
            checkValidity = true,
            transformPointsToKey = true,
            allowedRoutes,
        } = {},
    ) {
        this.onlyDiff = onlyDiff;
        this.warnIfNotSaved = warnIfNotSaved;
        this.autosave = autosave;
        this.allowedRoutes = allowedRoutes;
        this.checkIfChanged = checkIfChanged;
        this.checkValidity = checkValidity && !autosave;
        this.transformPointsToKey = transformPointsToKey;

        extendObservable(
            this.fields,
            Object.entries(fieldsObj).reduce(
                (acc, [name, field]) => ({
                    ...acc,
                    [name]: new Field(name, field, this),
                }),
                {},
            ),
        );
    }

    @computed
    get isValid() {
        if (!this.checkValidity) {
            return true;
        }
        return Object.values(this.fields).reduce((valid, field) => valid && !field.error, true);
    }

    @action.bound
    resetChanged() {
        Object.values(this.fields).forEach((field) => {
            field.hasChanged = false;
        });
    }

    @action.bound
    forceCanSubmit() {
        Object.values(this.fields).forEach((field) => {
            field.hasChanged = true;
        });
    }

    @action.bound
    reset() {
        Object.values(this.fields).forEach((field) => field.reset());
    }

    @action
    validate() {
        Object.values(this.fields).forEach((field) => field.validate());
    }

    @action
    errorFromApi(error) {
        if (!error.response) return;

        const { fails, errors } = error.response.data;

        Object.values(this.fields).forEach((field) => {
            field.error = field.defaultError;
        });

        if (!fails || !errors) return;

        // Fails may not exactly match fields and need to be processed to eventually find a parent field
        const enrichedFails = this.enrichFails(fails);

        Object.values(this.fields).forEach((field) => {
            field.errorFromApi(enrichedFails);
        });
    }

    @computed
    get hasChanged() {
        if (!this.checkIfChanged) {
            return true;
        }

        const hasChanged = Object.values(this.fields).reduce((acc, field) => {
            let result = field.hasChanged;
            if (field.value && field.value.length > 0 && field.value[0].hasChanged !== undefined) {
                result = field.value.reduce(
                    (prevState, payload) => payload.hasChanged || prevState,
                    result,
                );
            }
            return result || acc;
        }, false);

        return hasChanged;
    }

    @computed.struct
    get json() {
        const values = Object.values(this.fields).reduce((acc, field) => {
            if (!field.hasChanged && this.onlyDiff) {
                return acc;
            }
            return Object.assign(acc, field.json);
        }, {});

        if (this.transformPointsToKey) {
            return unflatten(values);
        }
        return values;
    }

    enrichFails(fails) {
        const fieldsNames = [];

        Object.values(this.fields).forEach((field) => {
            fieldsNames.push(field.mapping);

            // Fields can have subfields that need to be checked
            if (
                field.value &&
                field.value.map &&
                field.value.length > 0 &&
                field.value[0].errorFromApi
            ) {
                field.value.forEach((f) => fieldsNames.push(f.mapping));
            }
        });

        return Object.entries(fails).reduce((failures, [key, value]) => {
            // Copy the current fail
            failures[key] = value;

            // Check if the current fail key is already associated to a field
            if (!fieldsNames.includes(key)) {
                // Find failures associated to an indexed field, for example "tags.1" or "recommendedShows.0"
                const match = key.match(/(\w+)\.\d+/);
                if (match) {
                    // Extract fail prefix. For example, "tags" is the prefix of the "tags.1" failure
                    const prefix = match[1];

                    // If the current fail doesn't match a specific field but match a potential parent field, associate the current fail to the parent field.
                    // For example, if no field is found for fail "tags.1", copy the fail but for field "tags"
                    if (!fieldsNames.includes(key) && fieldsNames.includes(prefix)) {
                        failures[prefix] = value;
                    }
                }
            }

            return failures;
        }, {});
    }
}
