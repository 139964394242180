import { APPLE_PODCASTS, SPOTIFY } from '@/utils/constants';
import styled, { css, keyframes } from 'styled-components';

const DatatableSkeleton = () => {
    return (
        <SkeletonWrapper>
            <PlatformRow>
                <EmptyCell />
                <ApplePodcastsCell>
                    <ApplePodcastsInner color={APPLE_PODCASTS} />
                </ApplePodcastsCell>
                <SpotifyCell>
                    <SpotifyInner color={SPOTIFY} />
                </SpotifyCell>
                <EmptyCell />
            </PlatformRow>
            <HeadRow>
                <HeadCell>
                    <Line />
                </HeadCell>
                <HeadCell>
                    <SplitLine>
                        <Chunk />
                        <Chunk />
                        <Chunk />
                    </SplitLine>
                </HeadCell>
                <HeadCell>
                    <SplitLine>
                        <Chunk />
                        <Chunk />
                        <Chunk />
                    </SplitLine>
                </HeadCell>
                <HeadCell>
                    <Line />
                </HeadCell>
            </HeadRow>
            {Array.from({ length: 10 }).map((_, index) => (
                <Row key={index}>
                    <Cell>
                        <Line />
                    </Cell>
                    <Cell>
                        <SplitLine>
                            <Chunk />
                            <Chunk />
                            <Chunk />
                        </SplitLine>
                    </Cell>
                    <Cell>
                        <SplitLine>
                            <Chunk />
                            <Chunk />
                            <Chunk />
                        </SplitLine>
                    </Cell>
                    <Cell>
                        <Dots>
                            <Dot />
                            <Dot />
                            <Dot />
                        </Dots>
                    </Cell>
                </Row>
            ))}
        </SkeletonWrapper>
    );
};

const shimmer = keyframes`
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
`;
const shimmering = css`
    background: var(--neutral50);
    background-image: linear-gradient(
        to right,
        var(--neutral50) 0%,
        #edeef1 20%,
        var(--neutral50) 40%,
        var(--neutral50) 100%
    );
    background-repeat: no-repeat;
    background-size: 800px 104px;
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${shimmer};
    animation-timing-function: linear;
`;
const SkeletonWrapper = styled.table`
    width: 100%;
    display: table;
    border-collapse: collapse;
    table-layout: fixed;

    & td {
        border: 1px solid var(--neutral100);
    }

    & tr:first-of-type td {
        border: none;
    }

    & tr:nth-of-type(0n + 2) td {
        border-top: none;
    }

    & tr:last-of-type td {
        border-bottom: none;
    }

    & td:first-child {
        border-left: none;
    }

    & td:last-child {
        border-right: none;
    }
`;
const BaseRow = css``;
const baseCell = css`
    padding-block: 1.5rem;
    padding-inline: 1rem;
`;
const EmptyCell = styled.td``;
const PlatformRow = styled.tr`
    ${BaseRow};
`;
const Line = styled.div`
    height: 0.5rem;
    width: 50%;
    border-radius: var(--r-full);
    background-color: var(--neutral200);
    ${shimmering};
`;
const ApplePodcastsCell = styled.td`
    padding: 0.75rem;
    width: 350px;
`;
const PlatformInnerBase = css`
    background-color: ${({ color }) => `var(--${color}50)`};
    height: 2.5rem;
    width: 100%;
    border-radius: var(--r-s);
`;
const ApplePodcastsInner = styled.div`
    ${PlatformInnerBase};
`;
const SpotifyInner = styled.div`
    ${PlatformInnerBase};
`;
const SpotifyCell = styled.td`
    padding: 0.75rem;
    width: 350px;
`;
const HeadRow = styled.tr`
    ${BaseRow};
`;
const HeadCell = styled.td`
    ${baseCell};
`;
const Row = styled.tr`
    ${BaseRow};
`;
const Cell = styled.td`
    ${baseCell};
`;
const SplitLine = styled.ul`
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 4rem;
`;
const Chunk = styled.li`
    height: 0.5rem;
    flex: 1;
    border-radius: var(--r-full);
    background-color: var(--neutral100);
    ${shimmering};
`;
const Dots = styled.ul`
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;
const Dot = styled.li`
    height: 0.5rem;
    width: 1rem;
    border-radius: var(--r-full);
    background-color: var(--neutral100);
    ${shimmering};
`;

export default DatatableSkeleton;
