import Model from './Model';

class SharingAccountModel extends Model {
    static attributes = {
        name: 'name',
        username: 'username',
        profilePictureUrl: 'profile_picture_url',
    };
}

export default SharingAccountModel;
