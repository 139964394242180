const showKeys = {
    all: () => [{ entity: 'show' }],
    allLists: () => [{ ...showKeys.all()[0], scope: 'list' }],
    list: ({ page = 1, perPage = 20, sort = null, transformer = null } = {}) => [
        { ...showKeys.allLists()[0], page, perPage, sort, transformer },
    ],
    allDetails: () => [{ ...showKeys.all()[0], scope: 'detail' }],
    detailById: (rawShowId: string) => [{ ...showKeys.allDetails()[0], showId: Number(rawShowId) }],
};

export default showKeys;
