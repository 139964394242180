const playlistKeys = {
    all: () => [{ entity: 'playlist' }],
    allLists: () => [{ ...playlistKeys.all()[0], scope: 'list' }],
    listByShowId: (rawShowId: string | number) => [
        { ...playlistKeys.allLists()[0], showId: Number(rawShowId) },
    ],
    allDetails: () => [{ ...playlistKeys.all()[0], scope: 'detail' }],
    detailById: (rawPlaylistId: string | number) => [
        { ...playlistKeys.allDetails()[0], playlistId: Number(rawPlaylistId) },
    ],
};

export default playlistKeys;
