import { useEffect, useRef, useState } from 'react';
import { useSelectState, Item, useSearchFieldState } from 'react-stately';
import {
    useButton,
    useSelect,
    HiddenSelect,
    useOverlay,
    FocusScope,
    DismissButton,
    useListBox,
    useOption,
    mergeProps,
    useFocusRing,
    useHover,
    useSearchField,
} from 'react-aria';
import Button from '@ui/atoms/Button';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import CheckIcon from '@public/icons/check.svg';
import ChevronDownIcon from '@public/icons/chevrondown.svg';
import SearchIcon from '@public/icons/search.svg';
import CloseIcon from '@public/icons/close.svg';
import { connect } from '../decorators/connect';
import IconLoader from '@ui/icons/IconLoader';

const ListItem = styled.li`
    background: ${(props) => (props.isFocused ? 'var(--neutral50)' : 'var(--white)')};
    color: ${(props) => (props.isSelected ? 'var(--primary)' : 'var(--black)')};
    font-weight: ${(props) => (props.isSelected ? 'var(--fw-semibold)' : 'var(--fw-normal)')};
    padding: 0.375rem 0.75rem;
    display: flex;
    gap: 0.75rem;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    outline: none;
`;

const StyledCheckIcon = styled(CheckIcon)`
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    flex-shrink: 0;
    fill: var(--primary);
`;

const Option = ({ item, state }) => {
    const ref = useRef();
    const { optionProps, isSelected, isFocused } = useOption({ key: item.key }, state, ref);

    return (
        <ListItem {...optionProps} ref={ref} isFocused={isFocused} isSelected={isSelected}>
            {item.rendered}
            {isSelected && <StyledCheckIcon aria-hidden="true" />}
        </ListItem>
    );
};

const List = styled.ul`
    max-height: 300px;
    overflow: auto;
    list-style: none;
    padding: 0;
    padding-bottom: 0.375rem;
    outline: none;
`;

const ListBox = (props) => {
    const ref = useRef();
    const { listBoxRef = ref, state } = props;
    const { listBoxProps } = useListBox(props, state, listBoxRef);

    return (
        <List {...listBoxProps} ref={listBoxRef}>
            {[...state.collection].map((item) => (
                <Option key={item.key} item={item} state={state} />
            ))}
        </List>
    );
};

const PopoverContainer = styled.div`
    position: absolute;
    top: 100%;
    width: 100%;
    background: var(--white);
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: var(--r-xs);
    margin-top: 0.25rem;
    overflow: hidden;
`;

const Popover = (props) => {
    const ref = useRef();
    const { popoverRef = ref, isOpen, onClose, children } = props;

    const { overlayProps } = useOverlay(
        {
            isOpen,
            onClose,
            shouldCloseOnBlur: false,
            isDismissable: true,
        },
        popoverRef,
    );

    return (
        <FocusScope restoreFocus>
            <PopoverContainer {...overlayProps} ref={popoverRef}>
                {children}
                <DismissButton onDismiss={onClose} />
            </PopoverContainer>
        </FocusScope>
    );
};

const StyledChevronDownIcon = styled(ChevronDownIcon).withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) =>
        !['isFocusVisible'].includes(prop) && defaultValidatorFn(prop),
})`
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    flex-shrink: 0;
    fill: ${(props) => (props.isFocusVisible ? 'var(--primary)' : 'var(--black)')};
`;

const Episode = styled.span`
    display: flex;
    align-items: center;
    gap: 0.75rem;
    min-height: 2.5rem;
    min-width: 0;
`;

const EpisodeCover = styled.img`
    width: 2.5rem;
    height: 2.5rem;
    border-radius: var(--r-xs);
    flex-shrink: 0;
`;

const EpisodeName = styled.span`
    font-size: var(--fs-body);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

const LoadMoreWrapper = styled.div`
    padding-block: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const NoEpisode = () => (
    <Episode>
        <EpisodeName>
            <FormattedMessage defaultMessage="Sélectionnez un épisode" />
        </EpisodeName>
    </Episode>
);

const StyledSearchIcon = styled(SearchIcon)`
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    flex-shrink: 0;
    fill: var(--neutral500);
`;

const StyledCloseIcon = styled(CloseIcon)`
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    flex-shrink: 0;
    fill: var(--black);
`;

const BaseButton = styled.button`
    appearance: none;
    border: none;
    outline: none;
    background: none;
`;

const ClearButton = (props) => {
    const ref = useRef();
    const { buttonProps } = useButton(props, ref);

    return (
        <BaseButton {...buttonProps} ref={ref}>
            <StyledCloseIcon />
        </BaseButton>
    );
};

const SearchFieldContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background: var(--white);
    padding: 1rem 0.75rem 0.625rem;
`;

const SearchFieldInput = styled.input`
    flex-grow: 1;
    min-width: 0;
    font-size: 0.875rem;
    border: none;
`;

const SearchField = (props) => {
    const state = useSearchFieldState(props);
    const ref = useRef();
    const { inputProps, clearButtonProps } = useSearchField(props, state, ref);

    return (
        <SearchFieldContainer>
            <StyledSearchIcon />
            <FormattedMessage defaultMessage="Rechercher un épisode…">
                {(placeholder) => (
                    <SearchFieldInput
                        {...inputProps}
                        ref={ref}
                        placeholder={placeholder.join('')}
                    />
                )}
            </FormattedMessage>
            {state.value !== '' && <ClearButton {...clearButtonProps} />}
        </SearchFieldContainer>
    );
};

const SelectContainer = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    gap: 0.5rem;
`;

const SelectLabel = styled.label`
    display: block;
    text-align: left;
    font-size: var(--fs-body);
    color: var(--black);
    font-weight: var(--fw-semibold);
`;

const SelectButton = styled.button`
    appearance: none;
    background: var(--white);
    border: 1px solid;
    border-color: ${(props) => {
        if (props.isFocusVisible) {
            return 'var(--primary)';
        }
        if (props.isHovered) {
            return 'var(--neutral500)';
        }
        return 'var(--neutral200)';
    }};
    padding: calc(0.75rem - 1px);
    outline: none;
    border-radius: var(--r-xs);
    display: flex;
    gap: 0.75rem;
    align-items: center;
    justify-content: space-between;
    font-size: var(--fs-body);
    color: var(--black);

    &:hover {
        cursor: pointer;
    }
`;

const SelectValue = styled.span`
    min-width: 0;
    color: var(--black);
`;

const Select = (props) => {
    const state = useSelectState(props);
    const ref = useRef();
    const { labelProps, triggerProps, valueProps, menuProps } = useSelect(props, state, ref);
    const { buttonProps } = useButton(triggerProps, ref);
    const { focusProps, isFocusVisible } = useFocusRing();
    const { hoverProps, isHovered } = useHover({});

    useEffect(() => {
        const firstItemKey = state.collection.getFirstKey();
        if (firstItemKey && !state.isOpen) {
            state.setSelectedKey(firstItemKey);
        }
    }, [props.items]);

    return (
        <SelectContainer>
            <SelectLabel {...labelProps}>{props.label}</SelectLabel>
            <HiddenSelect state={state} triggerRef={ref} label={props.label} name={props.name} />
            <SelectButton
                {...mergeProps(buttonProps, focusProps, hoverProps)}
                ref={ref}
                isFocusVisible={isFocusVisible}
                isHovered={isHovered}
            >
                <SelectValue {...valueProps}>
                    {state.selectedItem ? state.selectedItem.rendered : <NoEpisode />}
                </SelectValue>
                <StyledChevronDownIcon isFocusVisible={isFocusVisible} aria-hidden="true" />
            </SelectButton>
            {state.isOpen && (
                <Popover isOpen={state.isOpen} onClose={state.close}>
                    <SearchField
                        value={props.searchQuery}
                        onChange={props.onChangeSearch}
                        label={<FormattedMessage defaultMessage="Rechercher un épisode" />}
                    />
                    <ListBox {...menuProps} state={state} />
                    {props.showFetchMore && (
                        <LoadMoreWrapper>
                            <Button
                                variant="ghost"
                                color="--primary"
                                onPress={() => !props.fetchIsLoading && props.fetchMore()}
                            >
                                {props.fetchIsLoading ? (
                                    <IconLoader />
                                ) : (
                                    <FormattedMessage defaultMessage="Charger plus d'épisodes" />
                                )}
                            </Button>
                        </LoadMoreWrapper>
                    )}
                </Popover>
            )}
        </SelectContainer>
    );
};

const enhance = connect(({ podcastStore }) => ({
    fetchShrinkedEpisodes: podcastStore.fetchShrinkedEpisodes,
}));

const SelectEpisode = ({ fetchShrinkedEpisodes, label, withClip, onChange }) => {
    const { showId } = useParams();
    const [episodes, setEpisodes] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPagination, setCurrentPagination] = useState({ perPage: 20, currentPage: 1 });
    const [fetchIsLoading, setFetchIsLoading] = useState(false);

    const searchEpisodes = async (query, currentPage, isLoadMore = false) => {
        setFetchIsLoading(true);
        const { episodes: newEpisodes, pagination } = await fetchShrinkedEpisodes(
            showId,
            query,
            currentPage,
            currentPagination.perPage,
            withClip,
        );

        setCurrentPagination(pagination);
        isLoadMore ? setEpisodes([...episodes, ...newEpisodes]) : setEpisodes([...newEpisodes]);
        setFetchIsLoading(false);
    };

    useEffect(() => {
        searchEpisodes('', currentPagination.currentPage);
    }, [showId]);

    const fetchMoreEpisodes = () => {
        if (currentPagination.currentPage === currentPagination.totalPages) return;
        searchEpisodes('', currentPagination.currentPage + 1, true);
    };

    return (
        <Select
            label={label}
            items={episodes}
            isLoading={fetchShrinkedEpisodes.pending}
            onChangeSearch={(query) => {
                setSearchQuery(query);
                setEpisodes([]);
                searchEpisodes(query, 1);
            }}
            searchQuery={searchQuery}
            onSelectionChange={(id) =>
                onChange(episodes.find((episode) => episode.id === id) ?? null)
            }
            fetchMore={fetchMoreEpisodes}
            showFetchMore={currentPagination.currentPage < currentPagination.totalPages}
            fetchIsLoading={fetchIsLoading}
        >
            {(item) => (
                <Item textValue={item.id}>
                    <Episode>
                        <EpisodeCover src={item.imageUrl} alt="" />
                        <EpisodeName>{item.name}</EpisodeName>
                    </Episode>
                </Item>
            )}
        </Select>
    );
};

export default enhance(SelectEpisode);
