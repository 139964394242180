import { useRef, useState, useMemo } from 'react';
import ContentLoader from 'react-content-loader';
import { connect } from '@/components/legacy/connect';
import { FormattedMessage } from 'react-intl';
import { useResponsive } from '@/shared/hooks/useResponsive';
import createFallback from '@/components/unorganized/createFallback';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import PaperGrid from '@ui/atoms/PaperGrid';
import Ranking from '@/components/unorganized/Ranking';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import Text from '@ui/atoms/Text';

const BEST_RANKING_TOOLTIP_WIDTH = 150;

const OverallItem = ({ item, ...respOverallItem }) => (
    <>
        {item.tooltip}
        <DeprecatedPaper
            align="center"
            column
            flex
            m={10}
            onMouseLeave={item.onMouseLeave}
            onMouseMove={item.onMouseMove}
            style={{ flexShrink: 0 }}
            w={respOverallItem.rowSize}
            scrollSnapAlign={respOverallItem.scrollSnapAlign}
        >
            {item.value && item.value.ranking ? (
                <Ranking value={item.value.ranking} variation={item.value.variation} />
            ) : (
                <DeprecatedText size="veryBig" weight="semibold">
                    -
                </DeprecatedText>
            )}
            <DeprecatedText mt={-5} color="--neutral500">
                {item.label}
            </DeprecatedText>
        </DeprecatedPaper>
    </>
);

const GraphHeaderItemFallback = createFallback((props) => (
    <ContentLoader height={52} width={52} style={{ height: 52, width: 52, margin: 10 }} {...props}>
        <circle cx="26" cy="26" r="26" />
    </ContentLoader>
));

const GraphHeaderFallback = createFallback(() => (
    <>
        <GraphHeaderItemFallback />
        <GraphHeaderItemFallback />
        <GraphHeaderItemFallback />
        <GraphHeaderItemFallback />
        <GraphHeaderItemFallback />
    </>
));

const responsiveData = (responsive) => {
    if (responsive.isMobile)
        return { column: 5, scrollSnapType: 'x mandatory', overflowX: 'scroll', h: '100%', py: 10 };
    if (responsive.isTablet) return { w: '100%', column: 3, h: 'max-content', py: 20, rowGap: 20 };
    return { w: '100%', column: 5, h: 'max-content' };
};

const responsiveDataOverallItem = (responsive) => {
    if (responsive.isMobile) return { scrollSnapAlign: 'center', rowSize: '40vw', text: 30 };
    if (responsive.isTablet) return { text: 'veryBig' };
    return { scrollSnapAlign: 'center', rowSize: '100%', text: 'veryBig' };
};

const enhance = connect(({ applePodcastsStatsStore }) => ({
    overall: applePodcastsStatsStore.applePodcastsRankingOverall,
}));

function RankingVariationGraphHeader({ isLoading, overall }) {
    const ref = useRef(null);
    const [{ x, y }, setPosition] = useState({});
    const { isMobile, isTablet } = useResponsive();

    const resp = responsiveData({ isMobile, isTablet });
    const respOverallItem = responsiveDataOverallItem({ isMobile, isTablet });

    useMemo(
        () => responsiveData({ isMobile, isTablet }),
        responsiveDataOverallItem({ isMobile, isTablet }),
        [isMobile, isTablet],
    );

    const dataset = [
        {
            key: 0,
            label: <FormattedMessage defaultMessage="aujourd’hui" />,
            value: overall.today,
        },
        {
            key: 1,
            label: <FormattedMessage defaultMessage="7 derniers jours" />,
            value: overall.lastWeek,
        },
        {
            key: 2,
            label: <FormattedMessage defaultMessage="30 derniers jours" />,
            value: overall.lastMonth,
        },
        {
            key: 3,
            label: <FormattedMessage defaultMessage="30 jours d’avant" />,
            value: overall.beforeLastMonth,
        },
        {
            key: 4,
            label: <FormattedMessage defaultMessage="meilleure place" />,
            value: overall.bestRanking,
            onMouseMove: (event) => {
                const { left } = ref.current.getBoundingClientRect();
                setPosition({
                    x:
                        Math.min(
                            event.clientX,
                            window.innerWidth - BEST_RANKING_TOOLTIP_WIDTH / 2,
                        ) - left,
                    y: event.clientY,
                });
            },
            onMouseLeave: () => setPosition({}),
            tooltip:
                overall.bestRanking && overall.bestRanking.ranking && x && y ? (
                    <DeprecatedPaper
                        background="white"
                        overflowHide
                        pos="absolute"
                        raise="normal"
                        rounded
                        w={BEST_RANKING_TOOLTIP_WIDTH}
                        x={x}
                        y={y}
                        z={2}
                    >
                        <DeprecatedPaper px={15} py={10} background="--neutral50">
                            <Text align="center" color="--neutral500" fontWeight="--fw-semibold">
                                {overall.bestRanking.dateLongFormat}
                            </Text>
                        </DeprecatedPaper>
                        <DeprecatedPaper flex align="center" justify="center" p={10}>
                            <Ranking value={overall.bestRanking.ranking} size="normal" />
                        </DeprecatedPaper>
                    </DeprecatedPaper>
                ) : null,
        },
    ];

    return (
        <DeprecatedPaper
            flex
            align="center"
            justify="space-around"
            wrap="wrap"
            p={isMobile ? 0 : 10}
            bb={1}
            border="--neutral100"
            z={1}
            ref={ref}
        >
            {isLoading ? (
                <GraphHeaderFallback />
            ) : (
                <>
                    <PaperGrid noWrap {...resp}>
                        {dataset.map((item) => (
                            <OverallItem key={item.key} item={item} {...respOverallItem} />
                        ))}
                    </PaperGrid>
                </>
            )}
        </DeprecatedPaper>
    );
}

RankingVariationGraphHeader.defaultProps = {
    isLoading: false,
};

export default enhance(RankingVariationGraphHeader);
