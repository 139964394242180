import { useQuery } from '@tanstack/react-query';
import { fetchYoutubeQuota } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import quotaKeys from './quotaKeys';
import { z } from 'zod';

export const QuotaSchema = z.object({
    maximum: z.number(),
    minimum: z.number(),
    step: z.number(),
    total: z.number(),
});
export type Quota = z.infer<typeof QuotaSchema>;

const queryFn = async () => {
    const { data } = await fetchYoutubeQuota();

    // TODO: Do camelcase transformation in Axios interceptor
    return QuotaSchema.parse(camelcaseKeys(data, { deep: true }));
};

const useYoutubeQuotaQuery = () => {
    return useQuery({
        queryKey: quotaKeys.detailByType('YOUTUBE'),
        queryFn,
    });
};

export default useYoutubeQuotaQuery;
