import { useMemo, useState } from 'react';
import Color from 'color';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Stack from '@ui/layout/Stack';
import IconPlay from '@ui/icons/IconPlay';
import Cluster from '@ui/layout/Cluster';
import Text from '@ui/atoms/Text';
import { CLIP_FORMATS } from '@/shared/config/constants';
import { ClipFormat } from '@/queries/clipFormat/types';
import { Episode } from '@/queries/episode/useEpisodeQuery.hook';
import QrCodeModal from './QrCodeModal';
import Menu from '../Menu';

interface ClipFormatSuccessProps {
    clipFormat: ClipFormat;
    episode: Episode;
}

const ClipFormatSuccess = ({ clipFormat, episode }: ClipFormatSuccessProps) => {
    const [isQrCodeModalOpen, setIsQrCodeModalOpen] = useState(false);

    // Compute background color based on clip color
    const bgColor = useMemo(() => {
        try {
            return Color(clipFormat.clip.color).alpha(0.07).string();
        } catch (error) {
            // Unable to parse color
            return null;
        }
    }, [clipFormat.clip.color]);

    const formatMatch: { [key in ClipFormat['format']]: JSX.Element } = {
        [CLIP_FORMATS.SQUARE]: <FormattedMessage defaultMessage="Carré" />,
        [CLIP_FORMATS.STORY]: <FormattedMessage defaultMessage="Story" />,
        [CLIP_FORMATS.LANDSCAPE]: <FormattedMessage defaultMessage="Paysage" />,
    };

    return (
        <Stack $gap="0.75rem">
            <CoverWrapper $bgColor={bgColor}>
                {clipFormat.previewUrl && (
                    <ClipCover
                        src={clipFormat.previewUrl}
                        variants={{
                            hover: {
                                filter: 'blur(30px)',
                            },
                            idle: {
                                filter: 'blur(0px)',
                            },
                        }}
                    />
                )}
                <ClipFormatLabelContainer
                    variants={{
                        hover: {
                            opacity: 0,
                        },
                        idle: {
                            opacity: 1,
                        },
                    }}
                >
                    <Text color="white" variant="footnote" numberOfLines={1}>
                        {formatMatch[clipFormat.format]}
                    </Text>
                </ClipFormatLabelContainer>
                <ClipDurationLabelContainer
                    variants={{
                        hover: {
                            opacity: 0,
                        },
                        idle: {
                            opacity: 1,
                        },
                    }}
                >
                    <Text color="white" variant="footnote" numberOfLines={1}>
                        <FormattedMessage
                            defaultMessage="{seconds} sec"
                            values={{ seconds: Math.round(clipFormat.clip.duration) }}
                        />
                    </Text>
                </ClipDurationLabelContainer>
                <Overlay>
                    <OverlayInner onClick={() => setIsQrCodeModalOpen(true)}>
                        <ClipOpenButtonStyled>
                            <IconPlay color="white" />
                        </ClipOpenButtonStyled>
                    </OverlayInner>
                    <Menu clipFormat={clipFormat} />
                </Overlay>
            </CoverWrapper>
            <Stack $gap="0.25rem">
                <Text numberOfLines={1} fontWeight="--fw-semibold">
                    {clipFormat.clip.adminTitle || clipFormat.clip.title}
                </Text>
                {episode && (
                    <Cluster $gap="0.5rem" $align="center" $wrap="nowrap">
                        {episode.imageUrl && <EpisodeCover src={episode.imageUrl} />}
                        <Text color="--neutral500" numberOfLines={1}>
                            {episode.name}
                        </Text>
                    </Cluster>
                )}
            </Stack>
            <QrCodeModal
                clipFormat={clipFormat}
                isOpen={isQrCodeModalOpen}
                onOpenChange={setIsQrCodeModalOpen}
            />
        </Stack>
    );
};

const ClipCover = styled(motion.img).attrs({
    loading: 'lazy',
})`
    display: block;
    max-height: 120px;
    max-width: 100%;
    border-radius: var(--r-xs);
    transition: filter 250ms;
`;
const EpisodeCover = styled.img.attrs({
    loading: 'lazy',
})`
    display: block;
    max-height: 1.5rem;
    max-width: 1.5rem;
    border-radius: var(--r-xs);
`;
const ClipOpenButtonStyled = styled(motion.button)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    background-color: var(--white200);
    border-radius: var(--r-full);
    border: none;
    cursor: pointer;
    &:hover {
        background-color: var(--white300);
    }
`;
const ClipLabel = styled(motion.div)`
    position: absolute;
    padding: 2px 4px;
    background-color: rgba(38, 33, 43, 0.75);
    border-radius: var(--r-full);
`;
const ClipDurationLabelContainer = styled(ClipLabel)`
    bottom: 0.5rem;
    right: 0.5rem;
`;
const ClipFormatLabelContainer = styled(ClipLabel)`
    bottom: 0.5rem;
    left: 0.5rem;
`;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 250ms;
    pointer-events: none;
`;
const OverlayInner = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    width: 100%;
    height: 100%;
`;
const CoverWrapper = styled.div<{ $bgColor: string | null }>`
    border-radius: var(--r-xs);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    min-height: 152px;
    ${({ $bgColor }) => $bgColor && `background-color: ${$bgColor};`}

    &:hover {
        & > ${ClipCover} {
            filter: blur(30px);
        }
        & > ${Overlay} {
            opacity: 1;
            pointer-events: all;
        }

        ${ClipLabel} {
            display: none;
        }
    }
`;

export default ClipFormatSuccess;
