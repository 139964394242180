import { useMutation } from '@tanstack/react-query';
import { updateNewsletterQuota } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import quotaKeys from './quotaKeys';
import { useQueryClient } from '@tanstack/react-query';

interface MutationFnProps {
    quantity: number;
}

const mutationFn = async ({ quantity }: MutationFnProps) => {
    const { data } = await updateNewsletterQuota({ quantity });
    return camelcaseKeys(data, { deep: true });
};

const useUpdateNewsletterQuotaMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: (_) => {
            queryClient.invalidateQueries({
                queryKey: quotaKeys.detailByType('NEWSLETTER'),
            });
        },
    });
};

export default useUpdateNewsletterQuotaMutation;
