import Text from '@ui/atoms/Text';
import { TooltipTrigger } from 'react-aria-components';
import Tooltip, { TooltipTriggerWrapper } from '../../Tooltip';
import { FormattedMessage } from 'react-intl';

const MaxLength = ({ currentValue, max, ...otherProps }) => {
    return (
        <TooltipTrigger delay={0} closeDelay={0}>
            <TooltipTriggerWrapper>
                <Text
                    color={currentValue > max ? '--alert500' : '--neutral500'}
                    variant="footnote"
                    textAlign="end"
                    fontWeight="--fw-semibold"
                    {...otherProps}
                >
                    {`${currentValue}/${max}`}
                </Text>
            </TooltipTriggerWrapper>
            <Tooltip placement="top">
                <FormattedMessage defaultMessage="Le compteur inclut les caractères de mise en forme de votre texte (balises HTML pour le gras, l'italique, l'ajout de liens etc.)." />
            </Tooltip>
        </TooltipTrigger>
    );
};

export default MaxLength;
