import { useHistory, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Text from '@/components/ui/atoms/Text';
import { PRICING } from '@/utils/pricing';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import UiKitTooltip from '@/components/ui/atoms/UiKitTooltip';
import styled, { css } from 'styled-components';
import UpgradeBadge from '@ui/atoms/UpgradeBadge';
import { Button } from 'react-aria-components';

const PlatformCard = ({
    platformName,
    platformIcon,
    platformSkeleton,
    color,
    onSelect,
    disabled,
    unauthorized,
    isConnected,
}) => {
    const { showId } = useParams();
    const history = useHistory();
    const disabledState = disabled || unauthorized;

    const handlePlatformSelect = () => {
        if (disabledState) return;
        if (!isConnected) {
            history.push(`/app/show/${showId}/communication/settings/auto-sharing`);
        }
        if (isConnected) {
            onSelect();
            return;
        }
    };

    return (
        <CardWrapper disabled={disabledState} onPress={handlePlatformSelect}>
            <PlatformIllustrationWrapper color={disabledState ? '--neutral50' : `${color}50`}>
                <PlatformSkeletonWrapper
                    skeletonIllustration={platformSkeleton}
                    isDisabled={disabledState}
                />
            </PlatformIllustrationWrapper>
            <PlatformNameWrapper>
                <PlatformIconWrapper color={disabledState ? '--neutral500' : color}>
                    {platformIcon}
                </PlatformIconWrapper>
                <PlatformName color={disabledState ? '--neutral500' : color}>
                    {platformName}
                </PlatformName>
                {disabled && (
                    <UiKitTooltip
                        variant="dark"
                        position="top"
                        content={
                            <FormattedMessage
                                defaultMessage="Oups, le partage via {providerName} est actuellement indisponible. Nos équipes font le nécessaire pour réparer cela au plus vite. 💪 Merci de réessayer plus tard. 💜"
                                values={{
                                    providerName: platformName,
                                }}
                            />
                        }
                    >
                        <DisabledTag>
                            <FontAwesomeIcon
                                icon={icon({ name: 'wifi-slash', style: 'solid' })}
                                size="sm"
                            />
                            <FormattedMessage defaultMessage="Actuellement indispo." />
                        </DisabledTag>
                    </UiKitTooltip>
                )}
                {unauthorized && <UpgradeBadge pricing={PRICING.BOOST} />}
            </PlatformNameWrapper>
        </CardWrapper>
    );
};

const CardWrapper = styled(Button)`
    background: none;
    border: none;

    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    transition-duration: 0.2s;
    background: var(--white);
    border-radius: var(--r-m);
    padding: 0.75rem;

    ${(p) => p.theme.mediaQueries.desktopLargeAndUp} {
        ${({ disabled }) =>
            !disabled &&
            css`
                cursor: pointer;
                &:hover {
                    transition-duration: 0.2s;
                    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.05);
                }
            `}
    }
`;
const PlatformIllustrationWrapper = styled.div`
    align-self: stretch;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ color }) => `var(${color})`};
    padding: 1rem;
    padding-top: 0;
    margin-inline: auto;
    border-radius: var(--r-s);
`;
const PlatformSkeletonWrapper = styled.div`
    width: 10rem;
    min-height: 5rem;
    background-image: url(${({ skeletonIllustration }) => skeletonIllustration});
    background-repeat: no-repeat;
    background-size: 10rem auto;
    background-position: center bottom;

    ${({ isDisabled }) =>
        isDisabled &&
        css`
            filter: grayscale(100%);
        `}
`;
const PlatformNameWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    padding-bottom: 0.75rem;
`;
const PlatformIconWrapper = styled.div`
    color: ${({ color }) => `var(${color})`};
    font-size: 1.5rem;
`;
const PlatformName = styled(Text)`
    color: ${({ color }) => `var(${color})`};
    font-size: var(--fs-body-m);
    font-weight: var(--fw-bold);
`;
const Tag = css`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    padding-block: 0.25rem;
    padding-inline: 0.5rem;
    border-radius: var(--r-xs);
    font-weight: var(--fw-bold);
    font-size: var(--fs-body-s);
`;
const DisabledTag = styled.div`
    ${Tag};
    color: var(--neutral500);
    background: var(--neutral100);
`;

PlatformCard.propTypes = {
    id: PropTypes.number,
    platformId: PropTypes.string,
    platformName: PropTypes.string,
    platformIcon: PropTypes.node,
    platformSkeleton: PropTypes.node,
    color: PropTypes.string,
    onSelect: PropTypes.func,
    disabled: PropTypes.bool,
    unauthorized: PropTypes.bool,
    isConnected: PropTypes.bool,
};

export default PlatformCard;
