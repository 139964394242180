import EmptyState from '@ui/molecules/EmptyState';
import PsoLanding from '@public/images/empty-state/illustration/pso.mp4';
import TelescopeIllustration from '@public/images/telescope.png';
import Text from '@/components/ui/atoms/Text';
import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Alert from '@/components/ui/atoms/Alert';
import { extractCssVariable } from '@/shared/utils/cssVariable';
import { useParams } from 'react-router-dom';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';
import { TooltipTrigger } from 'react-aria-components';
import Button from '@/components/Button';
import { sendAmplitudeLogEvent } from '@/shared/utils/amplitude';
import styled from 'styled-components';
import RouterLink from '@/components/Link/RouterLink';

export interface LandingProps {
    onGoToNextStep: () => void;
    keywordQuota: any;
    userHasWriteAccess: boolean;
}

const Landing = ({ onGoToNextStep, keywordQuota, userHasWriteAccess }: LandingProps) => {
    const intl = useIntl();
    const { showId }: { showId: string } = useParams();
    const { data: show } = useShowQuery(showId);
    const quotaReached = keywordQuota?.data?.reached;

    return (
        <EmptyState
            illustration={PsoLanding}
            illustrationType="video"
            illustrationAlign="right"
            illustrationBackground="--gradient-dawn"
        >
            <HeadIllustrationWrapper>
                <Telescope src={TelescopeIllustration} />
            </HeadIllustrationWrapper>
            <LabelWrapper>
                <Text variant="heading" fontWeight="--fw-bold">
                    <FormattedMessage defaultMessage="Boostez votre visibilité sur les plateformes d'écoute" />
                </Text>
                <Text variant="body" fontWeight="--fw-normal" color="--neutral500">
                    <FormattedMessage defaultMessage="Découvrez le PSO Control Panel d’Ausha, un outil complet pour analyser les performances de votre podcast dans les résultats de recherche sur les mots-clés de votre choix." />
                </Text>
            </LabelWrapper>
            {quotaReached && (
                <Alert
                    variant="alert"
                    icon={
                        <FontAwesomeIcon
                            icon={icon({ name: 'circle-exclamation', style: 'solid' })}
                            color={extractCssVariable('--alert500')}
                            size="xl"
                        />
                    }
                    description={
                        <Text color="--neutral700">
                            <FormattedMessage
                                defaultMessage="Vous avez atteint le nombre de mots-clés maximum sur vos émissions actives. Besoin de plus de mots-clés ? <link>Passez à l'offre supérieure</link> 🚀"
                                values={{
                                    link: (chunks: React.ReactNode) => (
                                        <RouterLink to="/app/user/subscription/offers">
                                            {chunks}
                                        </RouterLink>
                                    ),
                                }}
                            />
                        </Text>
                    }
                >
                    <Text fontWeight="--fw-semibold">
                        <FormattedMessage defaultMessage="Le quota de mots-clés pour votre compte est atteint" />
                    </Text>
                </Alert>
            )}
            <TooltipTrigger delay={0} closeDelay={0}>
                <TooltipTriggerWrapper>
                    <ActionWrapper>
                        <Button
                            onPress={() => {
                                if (quotaReached || !userHasWriteAccess || show?.archived) return;
                                onGoToNextStep();
                            }}
                            isDisabled={quotaReached || !userHasWriteAccess || show?.archived}
                        >
                            <FormattedMessage defaultMessage="Configurer dès maintenant" />
                        </Button>
                        <Button
                            variant="secondary"
                            onPress={() => {
                                sendAmplitudeLogEvent('Discover PSO');
                                const youtubeVideo = intl.formatMessage({
                                    defaultMessage: 'https://www.youtube.com/watch?v=oNfs8uSlzgw',
                                });
                                window.open(youtubeVideo, '_blank');
                            }}
                            endIcon={
                                <FontAwesomeIcon
                                    icon={icon({ name: 'arrow-up-right', style: 'solid' })}
                                    size="sm"
                                />
                            }
                        >
                            <FormattedMessage defaultMessage="Découvrir PSO" />
                        </Button>
                    </ActionWrapper>
                </TooltipTriggerWrapper>
                <Tooltip placement="top" passthrough={userHasWriteAccess}>
                    <Text>
                        <FormattedMessage defaultMessage="Vous devez être propriétaire de l'émission pour pouvoir configurer le PSO Control Panel." />
                    </Text>
                </Tooltip>
            </TooltipTrigger>
        </EmptyState>
    );
};

const HeadIllustrationWrapper = styled.div`
    width: 200px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const Telescope = styled.img`
    width: 8rem;
    height: auto;
`;
const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    & > * {
        text-align: center;
    }
`;
const ActionWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
`;

export default Landing;
