import { useGuard } from '@/shared/hooks/useGuard.hook';
import RouterLink from '@/components/Link/RouterLink';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';
import { TooltipTrigger } from 'react-aria-components';
import type { GuardDisallowReason } from '@/shared/config/constants';
import styled, { css } from 'styled-components';
import { useResponsive } from '@/shared/hooks/useResponsive';

interface NavButtonAccessProps {
    tooltip?: Record<string, React.ReactNode>; // TODO What is this shit ?
    disallowedUserRoles?: string[];
    disallowedShowStatuses?: string[];
    blockedSubscriptionAuthorized?: boolean;
    noShow?: boolean;
    disabled?: boolean;
    active?: boolean;
    to?: string;
    children?: React.ReactNode;
}

const NavButtonAccess = ({
    tooltip,
    disallowedUserRoles,
    disallowedShowStatuses,
    blockedSubscriptionAuthorized,
    noShow,
    disabled,
    active,
    to,
    children,
}: NavButtonAccessProps) => {
    const { allow, reason } = useGuard({
        disallowedUserRoles,
        blockedSubscriptionAuthorized,
        disallowedShowStatuses,
        disallowAccess: disabled,
    });

    const { isMobile } = useResponsive();

    // RAC Tooltip doesn't work on mobile devices (UX issue)
    if (isMobile) {
        return (
            <NavRouterLink
                to={to || ''}
                $active={active || false}
                $disabled={disabled || !allow || false}
            >
                {children}
            </NavRouterLink>
        );
    }

    let tooltipContent = tooltip?.[reason as GuardDisallowReason];

    return (
        <TooltipTrigger delay={0} closeDelay={0} isDisabled={allow || !tooltip || noShow}>
            <TooltipTriggerWrapper>
                <NavRouterLink
                    to={to || ''}
                    $active={active || false}
                    $disabled={disabled || !allow || false}
                >
                    {children}
                </NavRouterLink>
            </TooltipTriggerWrapper>
            <Tooltip placement="top" passthrough={false}>
                {tooltipContent}
            </Tooltip>
        </TooltipTrigger>
    );
};

const NavRouterLink = styled(RouterLink)<{ $active: boolean; $disabled: boolean }>`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 0;
    text-decoration: none;

    flex-basis: 7.5rem;
    align-self: stretch;
    height: 100%;
    background: var(--white);
    color: var(--neutral500);
    transition: all 0.3s;

    &:hover {
        color: var(--primary);
    }

    ${({ $active }) =>
        $active &&
        css`
            color: var(--primary);
            &:after {
                content: '';
                width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                height: 2px;
                background: var(--primary);
                transition: all 0.3s;
                transform-origin: 0 100%;
            }
        `};

    ${({ $disabled }) =>
        $disabled &&
        css`
            pointer-events: none;
            opacity: 0.5;
        `};

    @media (max-width: 1100px) {
        & > p {
            display: none;
        }
    }
`;

export default NavButtonAccess;
