import useSubscriptionQuery from '@queries/subscription/useSubscriptionQuery.hook';
import useShowQuery from '@queries/show/useShowQuery.hook';
import usePsoQuery from '@queries/pso/usePsoQuery.hook';
import type { AxiosError } from 'axios';
import useHasPsoBetaUserOption from '@/shared/hooks/useHasPsoBetaUserOption.hook';
import useHasPsoFullUserOption from './useHasPsoFullUserOption.hook';

interface HasAccessToPsoProps {
    showId: string;
}

const useHasAccessToPso = ({ showId }: HasAccessToPsoProps): boolean => {
    const subscription = useSubscriptionQuery();
    const show = useShowQuery(showId);
    const psoConfiguration = usePsoQuery({ showId, enabled: true });

    const hasPsoBetaUserOption = useHasPsoBetaUserOption({ showId });
    const hasPsoFullUserOption = useHasPsoFullUserOption({ showId });

    if (
        psoConfiguration.isLoading ||
        show.isLoading ||
        show.isError ||
        subscription.isLoading ||
        subscription.isError ||
        (psoConfiguration.isError &&
            (psoConfiguration.error as AxiosError)?.response?.status !== 403 &&
            (psoConfiguration.error as AxiosError)?.response?.data?.message !==
                'Missing user option pso')
    ) {
        return false;
    }

    return hasPsoBetaUserOption || hasPsoFullUserOption || false;
};

export default useHasAccessToPso;
