import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import useSubscriptionQuery from '@queries/subscription/useSubscriptionQuery.hook';
import useUserQuery from '@queries/user/useUserQuery.hook';
import { SURVEY_TYPE } from '@/components/unorganized/SubscribeSurveyModal/SubscribeSurveyForm';
import SubscribeSurveyModal from '@/components/unorganized/SubscribeSurveyModal';
import { useOverlayTriggerState } from 'react-stately';
import useQuery from '@/shared/hooks/useQuery';
import styled from 'styled-components';
import Text from '@/components/ui/atoms/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Cluster from '@/components/ui/layout/Cluster';
import Billing from './Billing';
import Offers from './Offers';
import { useHistory } from 'react-router';
import Stack from '@/components/ui/layout/Stack';

const Subscription = () => {
    const { data: subscription } = useSubscriptionQuery();
    const { data: user } = useUserQuery();
    let subscribeSurveyModalState = useOverlayTriggerState({});
    const query = useQuery();
    const isSubscriptionActive = !!subscription?.activated && !!user?.pricing;
    const displaySurvey =
        query.get('displaySurvey') === 'true' &&
        isSubscriptionActive &&
        subscribeSurveyModalState.isOpen;
    const history = useHistory();

    useEffect(() => {
        const subscribeSurveyModalShouldBeOpened =
            user &&
            user.surveys &&
            !user.surveys.includes(SURVEY_TYPE) && // Hide survey if user already answered
            !user.guest && // Hide survey for guest users
            user.pricing !== 'enterprise'; // Hide survey for accounts created for enterprise clients
        if (subscribeSurveyModalShouldBeOpened) {
            subscribeSurveyModalState.open();
        }
    }, [user]);

    if (!subscription) return null;

    return (
        <>
            <SubscriptionWrapper>
                <Header>
                    <Cluster $gap="0.75rem" $align="center">
                        <TitleIcon icon={icon({ name: 'credit-card', style: 'solid' })} />
                        <Text variant="headingM" fontWeight="--fw-bold">
                            <FormattedMessage defaultMessage="Abonnements" />
                        </Text>
                    </Cluster>
                    <Text color="--neutral500">
                        <FormattedMessage defaultMessage="Gérez vos abonnements, vos informations de paiement et vos quotas." />
                    </Text>
                </Header>
                <Stack $gap="2rem">
                    {!subscription.isFreemium && <Billing />}
                    <Offers />
                </Stack>
            </SubscriptionWrapper>
            {displaySurvey && (
                <SubscribeSurveyModal
                    isOpened={subscribeSurveyModalState.isOpen}
                    onClose={() => {
                        subscribeSurveyModalState.close();
                        history.replace(window.location.pathname);
                    }}
                />
            )}
        </>
    );
};

const SubscriptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: var(--container-width);
    width: 100%;
    row-gap: 4rem;
`;
const Header = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding-mobile);
    display: flex;
    flex-direction: column;
    row-gap: 0.75rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        padding: var(--container-padding);
    }
`;
const TitleIcon = styled(FontAwesomeIcon)`
    width: 1.25rem;
    height: 1.25rem;
`;

export default Subscription;
