import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchShowOverallStats } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import statsKeys from './statsKeys';
import { z } from 'zod';

export const OverallStatsSchema = z.object({
    avgDays: z.number(),
    avgMonths: z.number(),
    avgPodcasts: z.number(),
    avgWeeks: z.number(),
    beforeLastMonth: z.number(),
    last7Days: z.number(),
    lastMonth: z.object({
        downloads: z.number(),
        variation: z.number(),
    }),
    today: z.number(),
    totalDownloads: z.number(),
});
export type OverallStats = z.infer<typeof OverallStatsSchema>;
type Key = QueryFunctionContext<ReturnType<typeof statsKeys.listenersOverall>>;
type InputProps = {
    showId: string;
    timezone: string;
    options?: {
        enabled: boolean;
    };
};

const queryFn = async ({ queryKey: [{ showId, timezone }] }: Key) => {
    const { data } = await fetchShowOverallStats(showId, { timezone });
    return OverallStatsSchema.parse(camelcaseKeys(data, { deep: true }));
};

const useListenersOverallQuery = ({ showId, timezone, options }: InputProps) => {
    return useQuery({
        queryKey: statsKeys.listenersOverall(showId, timezone),
        queryFn,
        enabled: options?.enabled || true,
    });
};

export default useListenersOverallQuery;
