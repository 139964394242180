import { FormattedMessage } from 'react-intl';
import ResignSubscriptionForm from './ResignSubscriptionForm';
import Dialog from '@ui/atoms/Dialog';

export interface ResignSubscriptionModalProps {
    isOpen: boolean;
    onOpenChange: (status: boolean) => void;
}

const ResignSubscriptionModal = ({ isOpen, onOpenChange }: ResignSubscriptionModalProps) => (
    <Dialog
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        title={<FormattedMessage defaultMessage="Résilier mon abonnement" />}
        size="small"
    >
        <ResignSubscriptionForm onAfterSubmit={() => onOpenChange(false)} />
    </Dialog>
);

export default ResignSubscriptionModal;
