import playerFm from '@public/images/platforms/PlayerFM.png';
import ShortListFallback from '@app/fallbacks/ShortListFallback';
import BroadcastCard from '../../BroadcastCard';
import { LISTENING_PLATFORMS_NAMES, PLAYER_FM } from '@/utils/constants';
import { useParams } from 'react-router-dom';
import useShowQuery from '@/queries/show/useShowQuery.hook';

const PlayerFmBroadcast = () => {
    const { showId } = useParams<{ showId: string }>();
    const { data: show, isLoading } = useShowQuery(showId);
    const url = show?.listeningLinks?.data.find((link) => link.key === PLAYER_FM)?.url;

    if (isLoading) return <ShortListFallback />;

    return (
        <BroadcastCard
            platformKey={PLAYER_FM}
            name={LISTENING_PLATFORMS_NAMES[PLAYER_FM]}
            logo={playerFm}
            url={url}
        />
    );
};

export default PlayerFmBroadcast;
