import { useRef, useEffect, useState } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { DeprecatedText } from '@ui/atoms/DeprecatedText';
import AutoTextarea from '../../AutoTextarea';
import { secondsToDuration } from '@/helpers';
import Stack from '@ui/layout/Stack';
import Cluster from '@ui/layout/Cluster';
import { CLIP_TRANSCRIPTION_MAXIMUM_RECOMMENDED_LENGTH } from '@/shared/config/constants';

const Transcript = ({ transcript, onChange, active }) => {
    const [charactersCount, setCharactersCount] = useState(transcript.subtitle?.length ?? 0);
    const ref = useRef(null);

    useEffect(() => {
        if (active && ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [active]);

    const onInternalChange = (value) => {
        onChange(value);
        setCharactersCount(value?.length ?? 0);
    };

    return (
        <Stack $gap="0.5rem" ref={ref}>
            <Cluster $gap="0.5rem" $justify="space-between">
                <DeprecatedText weight="semibold" color={active ? '#72238E' : '#ACA5A5'}>
                    <FormattedMessage
                        defaultMessage="{start} à {end}"
                        values={{
                            start: secondsToDuration(transcript.start),
                            end: secondsToDuration(transcript.end),
                        }}
                    />
                </DeprecatedText>
                <DeprecatedText
                    weight="semibold"
                    color={
                        charactersCount > CLIP_TRANSCRIPTION_MAXIMUM_RECOMMENDED_LENGTH
                            ? '--alert'
                            : '--neutral500'
                    }
                    css="font-size: 0.75rem; line-height: 1.5em;"
                >
                    <FormattedNumber value={charactersCount} />
                    {'/'}
                    <FormattedNumber value={CLIP_TRANSCRIPTION_MAXIMUM_RECOMMENDED_LENGTH} />
                </DeprecatedText>
            </Cluster>
            <AutoTextarea onChange={onInternalChange} active={active}>
                {transcript.subtitle}
            </AutoTextarea>
        </Stack>
    );
};

export default Transcript;
