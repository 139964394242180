import { useMemo } from 'react';
import { observer } from 'mobx-react';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import { useResponsive } from '@/utils/hooks/useResponsive';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import Column from '../../ui/atoms/Column';
import DeprecatedPaper from '../../ui/atoms/DeprecatedPaper';
import TooltipHover from '../../ui/smarties/TooltipHover';

const responsiveData = (responsive) => {
    if (responsive.isMobile)
        return {
            scrollSnapAlign: 'center',
            rowSize: '40vw',
            text: 30,
        };

    if (responsive.isTablet) return { text: 'veryBig' };
    return {
        scrollSnapAlign: 'center',
        rowSize: '100%',
        text: 'veryBig',
    };
};

const VariationAverage = ({ step, average = 0 }) => {
    const eachMomentRange = {
        today: <FormattedMessage defaultMessage="Chaque jour" />,
        lastWeek: <FormattedMessage defaultMessage="Chaque semaine" />,
        lastMonth: <FormattedMessage defaultMessage="Chaque mois" />,
    };
    const MESSAGES_STEP_TOOLTIPS_AVERAGE = {
        standardMessage: (
            <FormattedMessage
                defaultMessage="{eachMomentRange}, votre émission est écoutée {average} fois en moyenne."
                values={{
                    average: <FormattedNumber value={average} />,
                    eachMomentRange: eachMomentRange[step],
                }}
            />
        ),
        fromStart: (
            <FormattedMessage
                defaultMessage="En moyenne, vos épisodes sont écoutés {average} fois."
                values={{ average: <FormattedNumber value={average} /> }}
            />
        ),
    };

    if (!step) return null;
    const Average = () => (
        <DeprecatedPaper
            cursor="default"
            mt={10}
            py={2}
            px={10}
            background="--neutral50"
            radius={50}
            w="max-content"
        >
            <DeprecatedText as="span" mr={5}>
                <FormattedNumber value={average} />
            </DeprecatedText>
            <DeprecatedText as="span">
                <FormattedMessage defaultMessage="en moy." />
            </DeprecatedText>
        </DeprecatedPaper>
    );

    return (
        <TooltipHover arrow="top" target={<Average />}>
            <DeprecatedText>
                {step === 'fromStart'
                    ? MESSAGES_STEP_TOOLTIPS_AVERAGE.fromStart
                    : MESSAGES_STEP_TOOLTIPS_AVERAGE.standardMessage}
            </DeprecatedText>
        </TooltipHover>
    );
};

const VariationText = (props) => {
    const { ml, as, weight } = props;
    if (!props.variation) return null;

    const WrapperComponent = ({ color, children }) => (
        <DeprecatedText ml={ml} as={as} weight={weight} color={color}>
            {children}
        </DeprecatedText>
    );

    if (Math.sign(props.variation) === -1)
        return (
            <WrapperComponent color="--alert">
                <FormattedNumber value={props.variation} style="percent" />
            </WrapperComponent>
        );
    return (
        <WrapperComponent color="--success">
            +<FormattedNumber value={props.variation} style="percent" />
        </WrapperComponent>
    );
};

const VariationNumberColors = VariationText;

function Variation({ value, average, step, children }) {
    const { isMobile, isTablet } = useResponsive();
    const resp = responsiveData({ isMobile, isTablet });
    useMemo(() => responsiveData({ isMobile, isTablet }), [isMobile, isTablet]);

    return (
        <Column
            align="center"
            column
            mx={10}
            w={resp.rowSize}
            scrollSnapAlign={resp.scrollSnapAlign}
            nowrap
            h="100%"
            justify="flex-start"
        >
            <DeprecatedText size={resp.text} weight="semibold" align="center">
                <FormattedNumber value={typeof value === 'object' ? value.downloads : value} />
                <VariationNumberColors
                    variation={Math.round(value.variation) / 100}
                    ml={5}
                    as="span"
                    weight="semibold"
                />
            </DeprecatedText>
            <DeprecatedText>{children}</DeprecatedText>
            <VariationAverage average={average} step={step} />
        </Column>
    );
}

export default observer(Variation);
