import statsKeys from '@queries/stats/statsKeys';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { fetchPeakTime } from '@/api';
import { z } from 'zod';
import camelcaseKeys from 'camelcase-keys';

interface PeakTimeHeatmapQueryParams {
    showId: string;
    days: '7' | '15' | '30' | '90' | 'always';
}

const CategorySchema = z.object({
    from: z.number(),
    to: z.number(),
});

const HeatmapItemSchema = z.object({
    dayHour: z.number(),
    weekDay: z.number(),
    downloads: z.number(),
});

const HeatmapSchema = z.object({
    categories: z.array(CategorySchema),
    mapData: z.array(HeatmapItemSchema),
    maxDownloads: z.number(),
});

export type PeakTimeHeatmap = z.infer<typeof HeatmapSchema>;

type Key = QueryFunctionContext<ReturnType<typeof statsKeys.heatmapByShowId>>;

const queryFn = async ({ queryKey: [{ showId, days }] }: Key) => {
    const { data } = await fetchPeakTime(showId, { days });
    return HeatmapSchema.parse(camelcaseKeys(data, { deep: true }));
};

const usePeakTimeHeatmapQuery = ({ showId, days }: PeakTimeHeatmapQueryParams) => {
    return useQuery({
        queryKey: statsKeys.heatmapByShowId(showId, days),
        queryFn,
        enabled: !!showId && !!days,
    });
};

export default usePeakTimeHeatmapQuery;
