import { useState, useEffect } from 'react';
import IpGeolocalizationMap from './IpGeolocalizationMap';
import IpGeolocalizationTable from './IpGeolocalizationTable';
import { useAmplitudeLogEvent } from '@/shared/hooks/useAmplitude';
import Filters from '../Filters';
import { PRICING } from '@/shared/config/pricing';
import Spinner from '@ui/atoms/Spinner';
import { GEO_JSON_BASE_CDN_URI, ALL_REGION_LAYERS } from './Geolocalization.constants';
import GeolocalizationErrorState from './GeolocalizationErrorState';
import { FormattedMessage } from 'react-intl';
import Alert from '@ui/atoms/Alert';
import IconInfo from '@ui/icons/IconInfo';
import dayjs from 'dayjs';
import TARGET_COUNTRIES_LOOKUP from '@public/geojson/countries.json';
import { useParams } from 'react-router-dom';
import { useStatsDateSelectorContext } from '@/context/StatsDateSelectorContext';
import { useCountryGeoStats } from './useCountryGeoStats.hook';
import { useRegionGeoStats } from './useRegionGeoStats.hook';
import { useCityGeoStats } from './useCityGeoStats.hook';
import useQuery from '@/shared/hooks/useQuery';
import useShowQuery from '@queries/show/useShowQuery.hook';
import styled from 'styled-components';
import { USER_ROLES } from '@/shared/config/constants';
import useUserQuery from '@/queries/user/useUserQuery.hook';

function Geolocalization() {
    useAmplitudeLogEvent('Stats Location Tab Viewed');
    const { showId } = useParams();
    const query = useQuery();
    const episodeQuery = query.get('episode');
    const campaignId = query.get('campaignId');
    const show = useShowQuery(showId);
    const { startDate, endDate } = useStatsDateSelectorContext();
    const { data: user, isLoading: userIsLoading } = useUserQuery();
    const [currentFeature, setCurrentFeature] = useState(null);
    const [visibleFeatures, setVisibleFeatures] = useState([]);
    const [currentLayerLevel, setCurrentLayerLevel] = useState('countries');
    const [regionGeoJSON, setRegionGeoJSON] = useState({});
    const [referencePricing, setReferencePricing] = useState(PRICING.LAUNCH);

    const userCountry = TARGET_COUNTRIES_LOOKUP[user.countryCode];
    const userCountryCoordinates = userCountry ? [userCountry.lng, userCountry.lat] : null;

    useEffect(() => {
        setReferencePricing(
            show?.data?.userRole === USER_ROLES.OWNER ? user?.pricing : show?.data?.ownerPricing,
        );
    }, [show?.data, user, setReferencePricing]);

    const countryGeoStats = useCountryGeoStats({
        showId,
        start: startDate,
        end: endDate,
        episodes: Array.isArray(episodeQuery) && episodeQuery?.split('_'),
        campaign: campaignId ?? null,
        currentLayerLevel,
    });

    const regionGeoStats = useRegionGeoStats({
        showId,
        start: startDate,
        end: endDate,
        episodes: Array.isArray(episodeQuery) && episodeQuery?.split('_'),
        campaign: campaignId ?? null,
        country: currentFeature?.parentId,
        currentLayerLevel,
    });

    const cityGeoStats = useCityGeoStats({
        showId,
        start: startDate,
        end: endDate,
        episodes: Array.isArray(episodeQuery) && episodeQuery?.split('_'),
        campaign: campaignId ?? null,
        region: currentFeature?.properties?.id,
        currentLayerLevel,
        plan: referencePricing,
    });

    useEffect(() => {
        if (!referencePricing || referencePricing === PRICING.LAUNCH) return;
        const fetchStats = async () => {
            let regionJSON = {};
            await Promise.all(
                ALL_REGION_LAYERS.map(async (region) => {
                    const res = await fetch(`${GEO_JSON_BASE_CDN_URI}/${region}.json`);
                    const json = await res.json();
                    regionJSON = { ...regionJSON, [region]: json };
                }),
            );

            setRegionGeoJSON(regionJSON);
        };

        fetchStats();
    }, [referencePricing]);

    const mapIsReady =
        referencePricing === PRICING.LAUNCH ||
        Object.keys(regionGeoJSON).length === ALL_REGION_LAYERS.length;

    const geoStatsError =
        (currentLayerLevel === 'countries' && countryGeoStats.isError) ||
        (currentLayerLevel === 'regions' && regionGeoStats.isError) ||
        (currentLayerLevel === 'cities' && cityGeoStats.isError);

    if (countryGeoStats.isLoading || show.isLoading || userIsLoading) {
        return (
            <LoadingWrapper>
                <Spinner />
            </LoadingWrapper>
        );
    }

    if (geoStatsError)
        return <GeolocalizationErrorState onRetry={() => countryGeoStats.refetch()} />;

    const tableData = currentLayerLevel === 'countries' ? countryGeoStats.data : visibleFeatures;

    const regionsOrCitiesGeoStatsLoading =
        (regionGeoStats.isLoading && regionGeoStats.isFetching) ||
        (cityGeoStats.isLoading && cityGeoStats.isFetching);

    return (
        <GeolocalizationWrapper>
            <OuterHeader>
                <TitleAndDescription>
                    <Title>
                        <FormattedMessage defaultMessage="Localisation" />
                    </Title>
                    <Description>
                        <FormattedMessage defaultMessage="Voici un aperçu des appareils sur lesquels on vous écoute." />
                    </Description>
                </TitleAndDescription>
                <Filters />
            </OuterHeader>
            {dayjs(startDate).isBefore('2023-01-01') && (
                <Alert variant="info" icon={<IconInfo />}>
                    <FormattedMessage defaultMessage="L’historique du détail par région et par ville n’est comptabilisé que depuis le 1er janvier 2023" />
                </Alert>
            )}
            {mapIsReady && (
                <IpGeolocalizationMap
                    visibleFeatures={visibleFeatures}
                    setVisibleFeatures={setVisibleFeatures}
                    currentLayerLevel={currentLayerLevel}
                    setCurrentLayerLevel={setCurrentLayerLevel}
                    subscriptionPlan={referencePricing || PRICING.LAUNCH}
                    regionGeoJSON={regionGeoJSON}
                    countryGeoStats={countryGeoStats.data}
                    regionGeoStats={regionGeoStats.data}
                    cityGeoStats={cityGeoStats.data}
                    currentFeature={currentFeature}
                    setCurrentFeature={setCurrentFeature}
                    isLoading={regionsOrCitiesGeoStatsLoading}
                    userCountryCoordinates={userCountryCoordinates}
                />
            )}
            <IpGeolocalizationTable
                data={tableData}
                currentLayerLevel={currentLayerLevel}
                isLoading={regionsOrCitiesGeoStatsLoading}
                startDate={startDate}
                endDate={endDate}
            />
        </GeolocalizationWrapper>
    );
}

const LoadingWrapper = styled.div`
    width: 100%;
    height: 700px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--white);
    margin-top: 1rem;
`;

const GeolocalizationWrapper = styled.div`
    padding-bottom: 4rem;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`;
const OuterHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;
const TitleAndDescription = styled.div``;
const Title = styled.h2`
    font-size: var(--fs-heading-m);
`;
const Description = styled.p``;

export default Geolocalization;
