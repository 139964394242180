import Model from './Model';

class PostPodcastModel extends Model {
    static default = () => ({
        id: '',
        name: '',
        imageUrl: '',
        status: '',
    });

    static attributes = {
        id: 'id',
        name: 'name',
        imageUrl: 'image_url',
        status: 'state',
    };
}

export default PostPodcastModel;
