import { FormattedMessage } from 'react-intl';
import Stack from '@ui/layout/Stack';
import Text from '@ui/atoms/Text';
import { BackgroundUploaderController } from '@ui/molecules/BackgroundUploader';
import { useController } from 'react-hook-form';

function CustomBackground({ formatPage, type, coverSize, control, name, ...props }) {
    const { fieldState } = useController({
        name,
        control,
    });

    return (
        <Stack $gap="1rem">
            <Stack $gap="0.5rem">
                <Stack $gap="0.25rem">
                    <Text fontWeight="--fw-semibold">
                        <FormattedMessage defaultMessage="Image d'arrière-plan" />
                    </Text>
                    <Text color="--neutral500">
                        <FormattedMessage
                            defaultMessage="Ce fond sera appliqué à l'en-tête de votre {type}."
                            values={{ type }}
                        />
                    </Text>
                </Stack>
                <BackgroundUploaderController
                    align="flex-start"
                    objectFit="cover"
                    formatPage={formatPage}
                    control={control}
                    name={name}
                    {...props}
                />
            </Stack>
            <Text color="--neutral500">
                <FormattedMessage
                    defaultMessage="Taille idéale : {coverSize} {linebreak} Format : JPG ou PNG {linebreak} Poids max : 10 Mo"
                    values={{ coverSize, linebreak: <br /> }}
                />
            </Text>
            {fieldState.error?.message && <Text color="--alert">{fieldState.error.message}</Text>}
        </Stack>
    );
}

export default CustomBackground;
