import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import Link from '../../atoms/Link';
import { connect } from '../../../legacy/connect';
import { useDebounce } from '@/shared/hooks/useDebounce';
import { useResponsive } from '@/shared/hooks/useResponsive';
import ImgMenuItem from '../ImgMenuItem';
import useCampaignsQuery from '@/queries/campaign/useCampaignsQuery';
import Button from '@/components/Button';
import Stack from '../../layout/Stack';
import useCampaignQuery from '@/queries/campaign/useCampaignQuery';

const ItemsList = ({ currentItemType, currentItemId, itemType, items, link, onClose }) =>
    items.map((item) => (
        <ImgMenuItem
            key={item.id}
            as={Link}
            to={link('stats.item', { itemType, itemId: item.id })}
            active={currentItemType === itemType && currentItemId === `${item.id}`}
            label={item.name}
            border="--neutral500"
            bb={1}
            height={40}
            onClick={onClose}
        />
    ));

const enhance = connect(({ routerStore }) => ({
    showId: routerStore.params.showId,
    link: routerStore.link,
}));

const ItemListDropDownCampaigns = ({ searchBar: SearchBar, showId, link, onClose }) => {
    const { itemType, itemId } = useParams();

    const [campaignSearchValue, setCampaignSearchValue] = useState('');
    const [page, setPage] = useState(1);
    const debouncedCampaignSearchValue = useDebounce(campaignSearchValue, 500);
    const { data: campaigns, isLoading: campaignsAreLoading } = useCampaignsQuery({
        showId,
        query: debouncedCampaignSearchValue,
        pagination: { page, perPage: 20 },
    });
    const { data: campaign } = useCampaignQuery(itemId, {
        enabled: itemType === 'campaign',
    });

    const campaignsList = [
        ...(campaigns?.campaigns ?? []),
        ...(campaign && !campaigns?.campaigns?.some((c) => c.id === campaign.id) ? [campaign] : []),
    ];

    const hasMoreItems = campaigns?.pagination?.totalPages > campaigns?.pagination?.currentPage;

    const { isDesktop } = useResponsive();

    if (campaignsAreLoading) {
        return null;
    }

    const handleLoadMore = () => {
        setPage((page) => page + 1);
    };

    return (
        <>
            <FormattedMessage defaultMessage="Rechercher une campagne…">
                {(placeholder) => (
                    <SearchBar
                        placeholder={placeholder.join('')}
                        value={campaignSearchValue}
                        setValue={setCampaignSearchValue}
                    />
                )}
            </FormattedMessage>
            <Stack $gap="0.5rem">
                <ItemsList
                    isDesktop={isDesktop}
                    currentItemId={itemId}
                    currentItemType={itemType}
                    itemType="campaign"
                    items={campaignsList}
                    link={link}
                    onClose={onClose}
                />
                {hasMoreItems && (
                    <Button variant="ghost" onPress={handleLoadMore}>
                        <FormattedMessage defaultMessage="Charger plus" />
                    </Button>
                )}
            </Stack>
        </>
    );
};

export default enhance(ItemListDropDownCampaigns);
