import { forwardRef } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import { reflex } from '@ui/reflex';
import DeprecatedText from '../../atoms/DeprecatedText';
import DeprecatedInput from '../../atoms/DeprecatedInput';
import DeprecatedPaper from '../../atoms/DeprecatedPaper';
import DeprecatedEmptyInput from '../../atoms/DeprecatedEmptyInput';

const Wrapper = reflex(
    styled.div`
        display: flex;
        flex-direction: column;
        position: relative;
        label {
            margin-bottom: 8px;
        }
        .input-wrapper {
            position: relative;
            display: flex;
            > div {
                input {
                    width: 100%;
                    &:invalid {
                        box-shadow: none;
                    }
                }
                span {
                    right: 8px;
                    bottom: 8px;
                }
                ${({ left }) =>
                    left &&
                    css`
                        svg {
                            position: absolute;
                            left: 10px;
                            top: calc(50%);
                            transform: translateY(-50%);
                            fill: var(--neutral500);
                        }
                        input {
                            padding-left: 30px !important;
                        }
                    `};
                ${({ right }) =>
                    right &&
                    css`
                        div,
                        button,
                        svg,
                        span {
                            position: absolute;
                            right: 10px;
                            top: calc(50%);
                            transform: translateY(-50%);
                            fill: var(--neutral500);
                        }
                    `};
            }
        }
        .max-label {
            margin-left: 5px;
        }
        .small-input {
            width: 150px;
        }
    `,
);

const DeprecatedTextInput = forwardRef(
    (
        {
            prefix,
            label,
            description,
            nowrap,
            tooltip,
            leftIcon,
            leftIconOutSideInput,
            rightIcon,
            rightIconOutSideInput,
            required,
            readOnly,
            isValid,
            hasError,
            errorMessage,
            optional,
            field = {},
            type,
            maxLength,
            textOnRightLabel,
            onChange,
            value,
            edit,
            smallInput,
            hideError,
            noAsterisk,
            mx,
            my,
            ml,
            mt,
            mb,
            mr,
            m,
            z,
            w = '100%',
            ...props
        },
        innerRef,
    ) => {
        const max = field.maxLength || maxLength;
        const isRequired = field.isRequired || required;

        const getInputColor = () => {
            if (field.error || hasError) return '--alert';
            if (isValid) return '--success';
            return undefined;
        };

        return (
            <Wrapper
                left={leftIcon}
                right={rightIcon}
                mx={mx}
                my={my}
                ml={ml}
                mt={mt}
                mb={mb}
                mr={mr}
                m={m}
                w={w}
            >
                <InnerWrapper>
                    {label && (
                        <>
                            <DeprecatedText
                                flex
                                align="left"
                                weight="semibold"
                                nowrap={nowrap}
                                as="label"
                                htmlFor={props.name}
                            >
                                {label}
                                {isRequired && !noAsterisk && (
                                    <DeprecatedText
                                        as="span"
                                        weight="semibold"
                                        color="--primary"
                                        ml={5}
                                    >
                                        *
                                    </DeprecatedText>
                                )}
                                {optional && (
                                    <DeprecatedText as="span" ml={5}>
                                        <FormattedMessage defaultMessage="(facultatif)" />
                                    </DeprecatedText>
                                )}
                                {tooltip}
                            </DeprecatedText>
                            {max && (
                                <DeprecatedText
                                    as="span"
                                    align="right"
                                    weight="semibold"
                                    color="--neutral500"
                                    size="small"
                                >
                                    {(value || field.value || '').length} / {max}
                                </DeprecatedText>
                            )}
                            {textOnRightLabel && (
                                <DeprecatedText
                                    as="span"
                                    align="right"
                                    weight="semibold"
                                    color="--neutral500"
                                    size="small"
                                >
                                    {textOnRightLabel}
                                </DeprecatedText>
                            )}
                            {edit}
                        </>
                    )}
                </InnerWrapper>
                {description && (
                    <DeprecatedText color="--neutral500" mt="-4px" mb={8}>
                        {description}
                    </DeprecatedText>
                )}
                <div className={`input-wrapper ${smallInput && 'small-input'}`}>
                    {prefix ? (
                        <DeprecatedInput px={0} py={0} as="div" flex accent={props.accent}>
                            {({ onFocus, onBlur }) => (
                                <>
                                    <DeprecatedPaper
                                        px={10}
                                        height="100%"
                                        w="fit-content"
                                        background="--neutral50"
                                        radRight={false}
                                        rounded
                                        flex
                                        align="center"
                                        nowrap
                                    >
                                        <DeprecatedText
                                            color={props.disabled ? '--neutral500' : '--black'}
                                            as="span"
                                        >
                                            {prefix}
                                        </DeprecatedText>
                                    </DeprecatedPaper>
                                    <DeprecatedEmptyInput
                                        ml={5}
                                        required={isRequired}
                                        onFocus={onFocus}
                                        onBlur={onBlur}
                                        type={field.type || type}
                                        value={value || field.value || ''}
                                        onChange={(e) =>
                                            (onChange || field.setValue)(e.target.value)
                                        }
                                        readOnly={readOnly || (!onChange && !field.setValue)}
                                        {...props}
                                    />
                                </>
                            )}
                        </DeprecatedInput>
                    ) : (
                        <>
                            {leftIconOutSideInput}
                            <DeprecatedPaper w={w} maxW={props.maxW} z={z}>
                                {leftIcon}
                                <DeprecatedInput
                                    color={getInputColor()}
                                    ref={innerRef}
                                    required={isRequired}
                                    onChange={(e) => (onChange || field.setValue)(e.target.value)}
                                    value={value || field.value || ''}
                                    type={field.type || type}
                                    maxLength={max}
                                    min={field.min || props.min}
                                    max={field.max || props.max}
                                    readOnly={readOnly || (!onChange && !field.setValue)}
                                    {...props}
                                />
                                {rightIcon}
                            </DeprecatedPaper>
                            {rightIconOutSideInput}
                        </>
                    )}
                </div>
                {field.error && !hideError && (
                    <DeprecatedText
                        mt={5}
                        pos="absolute"
                        y="100%"
                        color="--alert"
                        size="small"
                        nowrap
                        class="deprecated-input-error"
                    >
                        {field.error}
                    </DeprecatedText>
                )}
                {hasError && (
                    <DeprecatedText
                        mt={5}
                        pos="absolute"
                        y="100%"
                        color="--alert"
                        size="small"
                        nowrap
                    >
                        {errorMessage}
                    </DeprecatedText>
                )}
            </Wrapper>
        );
    },
);

const InnerWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

DeprecatedTextInput.displayName = 'DeprecatedTextInput';

DeprecatedTextInput.propTypes = {
    label: PropTypes.string.isRequired,
    prefix: PropTypes.string,
    as: PropTypes.oneOf(['input', 'textarea']),
    required: PropTypes.bool,
    readOnly: PropTypes.bool,
    hasError: PropTypes.bool,
    errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    isValid: PropTypes.bool,
    leftIcon: PropTypes.node,
    rightIcon: PropTypes.node,
    field: PropTypes.shape({
        value: PropTypes.string,
        setValue: PropTypes.func,
        errorMessage: PropTypes.string,
    }),
    value: PropTypes.string,
    maxLength: PropTypes.number,
    rows: PropTypes.number,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    ellipsis: PropTypes.bool,
    nowrap: PropTypes.bool,
    noAsterisk: PropTypes.bool,
    description: PropTypes.node,
    tooltip: PropTypes.node,
    leftIconOutSideInput: PropTypes.node,
    rightIconOutSideInput: PropTypes.node,
    optional: PropTypes.bool,
    type: PropTypes.string,
    textOnRightLabel: PropTypes.string,
    edit: PropTypes.node,
    smallInput: PropTypes.bool,
    hideError: PropTypes.bool,
    mx: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    my: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ml: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    mt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    mb: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    mr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    m: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    z: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    w: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    accent: PropTypes.string,
    maxW: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

DeprecatedTextInput.defaultProps = {
    as: 'input',
    nowrap: false,
    prefix: undefined,
    field: undefined,
    maxLength: undefined,
    value: undefined,
    onChange: null,
    rows: undefined,
    required: false,
    readOnly: false,
    leftIcon: undefined,
    rightIcon: undefined,
    disabled: undefined,
    ellipsis: undefined,
};

DeprecatedTextInput.displayName = 'DeprecatedTextInput';

export default observer(DeprecatedTextInput);
