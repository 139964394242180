import { TooltipTrigger } from 'react-aria-components';
import Tooltip, { TooltipTriggerWrapper } from '@ui/Tooltip';
import Text from '@ui/atoms/Text';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

const HostedMention = () => {
    const intl = useIntl();
    return (
        <Wrapper>
            <TooltipTrigger delay={0} closeDelay={0}>
                <TooltipTriggerWrapper>
                    <Text color="--neutral500">
                        <FormattedMessage
                            defaultMessage="Hébergé par Ausha. Visitez {link} pour plus d'informations."
                            values={{
                                link: (
                                    <LinkButton
                                        href={intl.formatMessage({
                                            defaultMessage:
                                                'https://www.ausha.co/fr/politique-de-confidentialite/',
                                        })}
                                        target="_blank"
                                    >
                                        <FormattedMessage defaultMessage="ausha.co/fr/politique-de-confidentialite" />
                                    </LinkButton>
                                ),
                            }}
                        />
                    </Text>
                </TooltipTriggerWrapper>
                <Tooltip placement="top">
                    <Text>
                        <FormattedMessage defaultMessage="L’écoute de podcast hébergé sur Ausha implique la collecte de certaines informations personnelles des auditeurs tout en garantissant leur anonymat. Cette mention est donc automatiquement ajoutée à la description de votre épisode à des fins légales." />
                    </Text>
                </Tooltip>
            </TooltipTrigger>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: fit-content;
`;

const LinkButton = styled.a`
    padding: 0;
    color: var(--neutral500);
    font-weight: var(--fw-semibold);
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;

export default HostedMention;
