import RouterLink from '@/components/Link/RouterLink';
import Sidebar from '@/shared/components/Sidebar';
import SidebarItem from '@/shared/components/Sidebar/Item/Item';
import SidebarSection from '@/shared/components/Sidebar/Section/Section';
import SectionTitle from '@/shared/components/Sidebar/Section/Title/Title';
import { FormattedMessage } from 'react-intl';
import { useLocation, useParams } from 'react-router';
import styled from 'styled-components';

/**
 * This is a temporary solution to hide the competitors empty state for non-enterprise PSO beta users.
 * TODO: Remove when "Ausha for all" goes live
 */
import { PRICING } from '@/shared/config/pricing';
import { STATUS_SHOWS } from '@/shared/config/statusShows';
import useSubscriptionQuery from '@/queries/subscription/useSubscriptionQuery.hook';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import useHasAccessToPsoLiveSearch from '@/shared/hooks/useHasAccessToPsoLiveSearch.hook';
import useHasAccessToPsoCompetitors from '@/shared/hooks/useHasAccessToPsoCompetitors.hook';
import useHasAccessToPsoKeywordsRanking from '@/shared/hooks/useHasAccessToPsoKeywordsRanking.hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ENABLE_PSO_COMPETITORS } from '@/shared/config/constants';
import { TooltipTrigger } from 'react-aria-components';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';

interface NavProps {
    userHasWriteAccess: boolean;
}

const Nav = ({ userHasWriteAccess }: NavProps) => {
    const { showId } = useParams<{ showId: string }>();
    const location = useLocation();
    const currentSegment = location.pathname.split('/').pop();
    const basePath = `/app/show/${showId}/pso`;

    const hasAccessToPsoLiveSearch = useHasAccessToPsoLiveSearch({ showId });
    const hasAccessToPsoCompetitors = useHasAccessToPsoCompetitors({ showId });
    const hasAccessToPsoKeywordsRanking = useHasAccessToPsoKeywordsRanking({ showId });

    /**
     * This is a temporary solution to hide the competitors empty state for non-enterprise PSO beta users.
     * TODO: Remove when "Ausha for all" goes live
     */
    const show = useShowQuery(showId);
    const isGuestShow = show?.data?.showStatus === STATUS_SHOWS.GUEST_SHOWS;
    const subscription = useSubscriptionQuery();
    const isEnterprise = isGuestShow
        ? show?.data?.ownerPricing === PRICING.ENTERPRISE
        : subscription?.data?.pricing === PRICING.ENTERPRISE;

    return (
        <Sidebar>
            <SidebarSection>
                <SectionTitle>
                    <FormattedMessage defaultMessage="Analytiques" />
                </SectionTitle>
                <CustomSidebarItem isActive={currentSegment === 'overview'}>
                    <NavItemIcon icon={icon({ name: 'grid-2', style: 'solid' })} />
                    <NavRouterLink to={`${basePath}/overview`}>
                        <FormattedMessage defaultMessage="Vue d'ensemble" />
                    </NavRouterLink>
                </CustomSidebarItem>
                <CustomSidebarItem isActive={currentSegment === 'details'}>
                    <NavItemIcon icon={icon({ name: 'arrow-trend-up', style: 'solid' })} />
                    <NavRouterLink to={`${basePath}/details`}>
                        <FormattedMessage defaultMessage="Suivi de mots-clés" />
                    </NavRouterLink>
                </CustomSidebarItem>
                {(isEnterprise || hasAccessToPsoKeywordsRanking) && (
                    <CustomSidebarItem isActive={currentSegment === 'ranking'}>
                        <NavItemIcon icon={icon({ name: 'check-circle', style: 'solid' })} />
                        <NavRouterLink to={`${basePath}/ranking`}>
                            <FormattedMessage id="pso.ranking" defaultMessage="Classement" />
                        </NavRouterLink>
                        {!hasAccessToPsoKeywordsRanking && (
                            <DiamondIcon icon={icon({ name: 'gem', style: 'duotone' })} />
                        )}
                    </CustomSidebarItem>
                )}
                {/*
                 * This is a temporary solution to hide the competitors empty state for non-enterprise PSO beta users.
                 * TODO: Remove when "Ausha for all" goes live
                 */}
                {ENABLE_PSO_COMPETITORS && (isEnterprise || hasAccessToPsoCompetitors) && (
                    <CustomSidebarItem isActive={currentSegment === 'competitors'}>
                        <NavItemIcon icon={icon({ name: 'users', style: 'solid' })} />
                        <NavRouterLink to={`${basePath}/competitors`}>
                            <FormattedMessage defaultMessage="Compétiteurs" />
                        </NavRouterLink>
                        {!hasAccessToPsoCompetitors && (
                            <DiamondIcon icon={icon({ name: 'gem', style: 'duotone' })} />
                        )}
                    </CustomSidebarItem>
                )}
                {(isEnterprise || hasAccessToPsoCompetitors) && (
                    <CustomSidebarItem isActive={currentSegment === 'livesearch'}>
                        <NavItemIcon icon={icon({ name: 'magnifying-glass', style: 'solid' })} />
                        <NavRouterLink to={`${basePath}/livesearch`}>
                            <FormattedMessage defaultMessage="Live Search" />
                        </NavRouterLink>
                        {!hasAccessToPsoLiveSearch && (
                            <DiamondIcon icon={icon({ name: 'gem', style: 'duotone' })} />
                        )}
                    </CustomSidebarItem>
                )}
            </SidebarSection>
            <SidebarSection>
                <SectionTitle>
                    <FormattedMessage defaultMessage="Paramètres" />
                </SectionTitle>
                <CustomSidebarItem isActive={currentSegment === 'keywords'}>
                    <NavItemIcon icon={icon({ name: 'gear', style: 'solid' })} />
                    <TooltipTrigger delay={0} closeDelay={0} isDisabled={userHasWriteAccess}>
                        <TooltipTriggerWrapper>
                            <NavRouterLink to={`${basePath}/keywords`}>
                                <FormattedMessage defaultMessage="Gérer les mots-clés" />
                            </NavRouterLink>
                            <Tooltip placement="end">
                                <FormattedMessage defaultMessage="Vous devez être propriétaire de l'émission pour pouvoir configurer le PSO Control Panel." />
                            </Tooltip>
                        </TooltipTriggerWrapper>
                    </TooltipTrigger>
                </CustomSidebarItem>
            </SidebarSection>
        </Sidebar>
    );
};

const CustomSidebarItem = styled(SidebarItem)`
    &:not([data-active='true']) svg {
        color: var(--neutral);
    }
`;
const NavRouterLink = styled(RouterLink)`
    color: inherit;
`;
const NavItemIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
`;
const DiamondIcon = styled(NavItemIcon)`
    color: var(--primary);
`;

export default Nav;
