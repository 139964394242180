import NewsletterPreview from './NewsletterPreview';
import styled from 'styled-components';
import { useAmplitudeLogEvent } from '@/shared/hooks/useAmplitude';
import NewsletterSettings from './NewsletterSettings';

const Newsletter = () => {
    useAmplitudeLogEvent('Newsletter Tab Viewed');
    return (
        <NewsletterWrapper>
            <NewsletterSettings />
            <NewsletterPreview />
        </NewsletterWrapper>
    );
};

const NewsletterWrapper = styled.section`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

export default Newsletter;
