import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { USER_OPTIONS } from '@/shared/config/userOptions';
import styled from 'styled-components';
import { useParams } from 'react-router';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import { ToggleController } from '@/components/ui/atoms/Toggle';
import { InputImgUploaderController } from '@/components/ui/molecules/InputImgUploader';
import { InputTextController } from '@/components/ui/atoms/InputText';
import Text from '@/components/ui/atoms/Text';
import useAushaProFormSchema, { AushaProFormSchema } from './useFormSchema.hook';
import { zodResolver } from '@hookform/resolvers/zod';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import Button from '@/components/Button';
import Stack from '@/components/ui/layout/Stack';
import UnsavedFormModal from '@/components/unorganized/UnsavedFormModal';

interface AushaProFormProps {
    onSubmit: (data: any) => void;
    isLoading: boolean;
    defaultValues: AushaProFormSchema;
}

const AushaProForm = ({ onSubmit, isLoading, defaultValues }: AushaProFormProps) => {
    const { showId } = useParams<{ showId: string }>();
    const { data: show } = useShowQuery(showId);
    const { data: user } = useUserQuery();
    const hasAccessToWhiteLabel = show?.userOptions.includes(USER_OPTIONS.WHITE_LABEL);
    const hasAccessToCustomLogo = show?.userOptions.includes(USER_OPTIONS.CUSTOMIZABLE_LOGO);
    const schema = useAushaProFormSchema(user?.language);
    const { handleSubmit, control, watch, formState } = useForm<AushaProFormSchema>({
        mode: 'onBlur',
        values: defaultValues,
        resolver: zodResolver(schema),
    });
    const hasCustomLogo = watch('hasCustomLogo');

    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                {hasAccessToWhiteLabel && (
                    <Stack $gap="0.5rem">
                        <Text fontWeight="--fw-semibold">
                            <FormattedMessage defaultMessage="Marque blanche" />
                        </Text>
                        <ToggleController name="whiteLabel" control={control}>
                            <FormattedMessage defaultMessage="Masquer le logo Ausha sur votre Smartplayer, Smartlink et Site web Ausha." />
                        </ToggleController>
                    </Stack>
                )}
                {hasAccessToCustomLogo && (
                    <>
                        <Stack $gap="0.5rem">
                            <Text fontWeight="--fw-semibold">
                                <FormattedMessage defaultMessage="Logo personnalisé" />
                            </Text>
                            <ToggleController name="hasCustomLogo" control={control}>
                                <FormattedMessage defaultMessage="Ajouter votre propre logo sur votre Smartplayer, Smartlink et Site web Ausha." />
                            </ToggleController>
                        </Stack>
                        {hasCustomLogo && (
                            <>
                                <ResponsiveWrapper>
                                    <InputImgUploaderController
                                        name="darkLogo"
                                        control={control}
                                        label={
                                            <FormattedMessage defaultMessage="Votre logo de couleur foncée" />
                                        }
                                        description={
                                            <Text color="--neutral500">
                                                <FormattedMessage
                                                    defaultMessage="Taile idéale: 400x120px{break}Format: JPG ou PNG{break}Poids max: 10Mo"
                                                    values={{ break: <br /> }}
                                                />
                                            </Text>
                                        }
                                        accept=".png, .jpg, .jpeg"
                                        dimensions={{ minWidth: 400, minHeight: 120 }}
                                    />
                                    <InputImgUploaderController
                                        name="lightLogo"
                                        control={control}
                                        label={
                                            <FormattedMessage defaultMessage="Votre logo de couleur claire" />
                                        }
                                        description={
                                            <Text color="--neutral500">
                                                <FormattedMessage
                                                    defaultMessage="Taile idéale: 400x120px{break}Format: JPG ou PNG{break}Poids max: 10Mo"
                                                    values={{ break: <br /> }}
                                                />
                                            </Text>
                                        }
                                        accept=".png, .jpg, .jpeg"
                                        dimensions={{ minWidth: 400, minHeight: 120 }}
                                    />
                                </ResponsiveWrapper>
                                <InputImgUploaderController
                                    name="favicon"
                                    control={control}
                                    label={<FormattedMessage defaultMessage="Votre favicon" />}
                                    description={
                                        <Text color="--neutral500">
                                            <FormattedMessage
                                                defaultMessage="Taile idéale: 32x32px{break}Format: JPG ou PNG{break}Poids max: 10Mo"
                                                values={{ break: <br /> }}
                                            />
                                        </Text>
                                    }
                                    accept=".png, .jpg, .jpeg"
                                    dimensions={{ minWidth: 32, minHeight: 32 }}
                                />
                            </>
                        )}
                        <InputTextController
                            name="logoLink"
                            label={
                                <FormattedMessage defaultMessage="Lien personnalisé pour votre logo" />
                            }
                            control={control}
                        />
                    </>
                )}
                <SubmitButton isLoading={isLoading} type="submit">
                    <FormattedMessage defaultMessage="Enregistrer" />
                </SubmitButton>
            </Form>
            <UnsavedFormModal shouldBlockNavigation={formState.isDirty} />
        </>
    );
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`;
const ResponsiveWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;

    ${(p) => p.theme.mediaQueries.desktopLargeAndUp} {
        flex-direction: row;

        & > * {
            flex: 1;
        }
    }
`;
const SubmitButton = styled(Button)`
    margin-top: var(--sp-m);
    align-self: center;
`;

export default AushaProForm;
