import Stack from '@/components/ui/layout/Stack';
import NonHostingEpisodeFormContentNameField from './NonHostingEpisodeFormContentNameField';
import NonHostingEpisodeFormContentDescriptionField from './NonHostingEpisodeFormContentDescriptionField';
import NonHostingEpisodeFormContentTagsField from './NonHostingEpisodeFormContentTagsField';
import NextStepButton from '../NonHostingEpisodeFormAccordion/NextStepButton';

const NonHostingEpisodeFormContent = () => {
    return (
        <Stack $gap="2rem">
            <NonHostingEpisodeFormContentNameField />
            <NonHostingEpisodeFormContentDescriptionField />
            <NonHostingEpisodeFormContentTagsField />
            <NextStepButton />
        </Stack>
    );
};

export default NonHostingEpisodeFormContent;
