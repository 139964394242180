import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';

interface TunnelStepperProps {
    currentStep: number;
    stepCount: number;
}

const TunnelStepper = ({ currentStep, stepCount }: TunnelStepperProps) => {
    return (
        <TunnelStepperWrapper>
            {Array.from({ length: stepCount }, (_, i) => (
                <Fragment key={i}>
                    {i !== 0 && <BarLink $isActive={i <= currentStep - 1} />}
                    <TunnelStep $isActive={i <= currentStep - 1}>
                        {i < currentStep - 1 ? (
                            <FontAwesomeIcon
                                icon={icon({ name: 'circle-check', style: 'solid' })}
                            />
                        ) : (
                            <FontAwesomeIcon icon={icon({ name: 'circle', style: 'regular' })} />
                        )}
                    </TunnelStep>
                </Fragment>
            ))}
        </TunnelStepperWrapper>
    );
};

const TunnelStepperWrapper = styled.ul`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    list-style: none;
    gap: 1rem;
`;
const TunnelStep = styled.li<{ $isActive: boolean }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${({ $isActive }) => ($isActive ? 'var(--primary)' : 'var(--neutral100)')};
`;
const BarLink = styled.div<{ $isActive: boolean }>`
    flex-grow: 1;
    height: 0.125rem;
    background-color: ${({ $isActive }) => ($isActive ? 'var(--primary)' : 'var(--neutral100)')};
`;

TunnelStepper.propTypes = {
    currentStep: PropTypes.number.isRequired,
    stepCount: PropTypes.number.isRequired,
};

export default TunnelStepper;
