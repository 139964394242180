import { FormattedMessage } from 'react-intl';
import IconButton from '@/components/IconButton';
import Modal from '@ui/atoms/Modal';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@/components/Button';
import styled from 'styled-components';

interface ConfirmUnBroadcastSpotifyModalProps {
    handleUnbroadcastSpotify: () => void;
    isOpen: boolean;
    onClose: () => void;
}

const ConfirmUnBroadcastSpotifyModal = ({
    handleUnbroadcastSpotify,
    isOpen,
    onClose,
}: ConfirmUnBroadcastSpotifyModalProps) => {
    return (
        <ModalStyled isOpen={isOpen} onOpenChange={onClose} size="small">
            <Header>
                <Title>
                    <FormattedMessage defaultMessage="Ne plus diffuser sur Spotify" />
                </Title>
                <IconButton
                    icon={<CloseIcon icon={icon({ name: 'xmark-large', style: 'solid' })} />}
                    onPress={onClose}
                    variant="ghost"
                    aria-label="Close modal"
                    style={{ padding: 0 }}
                />
            </Header>
            <ContentWrapper>
                <FormattedMessage defaultMessage="Votre émission sera supprimée de la plateforme Spotify." />
            </ContentWrapper>
            <Actions>
                <Button variant="link-secondary" onPress={onClose}>
                    <FormattedMessage defaultMessage="Annuler" />
                </Button>
                <Button onPress={handleUnbroadcastSpotify} variant="danger">
                    <FormattedMessage defaultMessage="Arrêter la diffusion" />
                </Button>
            </Actions>
        </ModalStyled>
    );
};

const ModalStyled = styled(Modal)`
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 2rem;
`;
const Header = styled.div`
    display: flex;
    justify-content: space-between;
`;
const Title = styled.h1`
    font-size: var(--fs-heading-s);
`;
const CloseIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--neutral500);
`;
const ContentWrapper = styled.div`
    width: 100%;
    flex: 1;
`;
const Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
`;

export default ConfirmUnBroadcastSpotifyModal;
