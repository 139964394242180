import { FormattedMessage } from 'react-intl';
import aushaClubLoginIllustration from '@public/images/club_ausha_illustration.jpg';
import { useResponsive } from '@/shared/hooks/useResponsive';
import aushaClubLogo from '@public/images/ausha-club-logo.png';
import Img from '@ui/atoms/Img';
import AushaClubLoginForm from '@/components/unorganized/AushaClubLoginForm';
import Text from '@ui/atoms/Text';
import styled from 'styled-components';

const Container = styled.div`
    background-color: var(--white);
    display: flex;
    align-items: start;
    justify-content: center;
    column-gap: 1rem;
    height: 100vh;
    width: 100vw;
    padding: 2rem 1rem;

    ${({ theme }) => theme.mediaQueries.tabletAndUp} {
        padding: 2rem;
    }
    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        & > :first-child,
        & > :last-child {
            width: 50%;
        }
    }
`;
const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        align-self: center;
    }
`;
const InnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    row-gap: 2rem;
    max-width: 400px;
`;
const Illustration = styled(Img)`
    width: 100%;
    height: 100%;
    border-radius: var(--r-xl);
`;
const Logo = styled(Img)`
    width: 100%;
    height: 100%;
    max-width: 140px;
    max-height: 64px;
`;

const AushaClubLoginPage = () => {
    const { isDesktopLarge } = useResponsive();

    return (
        <Container>
            <Wrapper>
                <InnerWrapper>
                    <Logo src={aushaClubLogo} />
                    <Text variant="headingXL" fontWeight="--fw-bold">
                        <FormattedMessage
                            defaultMessage="Bienvenue sur le Club Ausha ! 👋"
                            values={{ break: <br /> }}
                        />
                    </Text>
                    <AushaClubLoginForm />
                </InnerWrapper>
            </Wrapper>
            {isDesktopLarge && <Illustration src={aushaClubLoginIllustration} />}
        </Container>
    );
};

export default AushaClubLoginPage;
