import PropTypes from 'prop-types';
import PlaylistForm from '../PlaylistForm';
import { observer } from 'mobx-react';
import usePlaylistStore from '@hooks/usePlaylistStore.hook';
import { useParams, useHistory } from 'react-router-dom';
import PlaylistModel from '@/models/PlaylistModel';
import useShowStore from '@hooks/useShowStore';
import { USER_OPTIONS } from '@/utils/userOptions';

// TODO: Use queries and mutations to retrieve and update data
const PlaylistEdit = ({ onClose, playlistId }) => {
    const { showId } = useParams();
    const playlistStore = usePlaylistStore();
    const show = useShowStore().byId(showId);
    const history = useHistory();
    const isNew = !playlistId;
    const playlist = isNew ? new PlaylistModel(playlistStore) : playlistStore.byId(playlistId);
    const playlistLink = show && `${show.siteUrl}/playlist/`;
    const isLoading = isNew ? playlist.create.pending : playlist.update.pending;
    const hasAccessToPlaylistConfidentiality =
        show && show.allows(USER_OPTIONS.PLAYLISTS_CONFIDENTIALITY);

    const onAfterSubmit = () => {
        if (isNew) history.push(`/app/show/${showId}/playlists/${playlist.id}`);
        onClose();
    };
    const onSubmit = async (formData) => {
        if (isNew) await playlist.create(formData);
        else await playlist.update(formData);
        onAfterSubmit();
    };

    return (
        <PlaylistForm
            playlist={playlist}
            playlistLink={playlistLink}
            onSubmit={onSubmit}
            onAfterSubmit={onAfterSubmit}
            onClose={onClose}
            isLoading={isLoading}
            isNew={isNew}
            hasAccessToPlaylistConfidentiality={hasAccessToPlaylistConfidentiality}
        />
    );
};

PlaylistEdit.propTypes = {
    playlistId: PropTypes.string,
    onClose: PropTypes.func,
};

export default observer(PlaylistEdit);
