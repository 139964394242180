import HideFor from '@app/atoms/HideFor';
import { USER_OPTIONS } from '@/utils/userOptions';
import noWebhookMainIllustration from '@public/images/empty-state/illustration/webhooks.png';
import Text from '@ui/atoms/Text';
import ZebraIllustration from '@ui/atoms/ZebraIllustration';
import { FormattedMessage } from 'react-intl';
import EmptyState from '@ui/molecules/EmptyState';
import ShiningDiamond from '@ui/atoms/ShiningDiamond';
import IconRocket from '@ui/icons/IconRocket';
import styled from 'styled-components';
import { useAmplitudeLogEvent } from '@hooks/useAmplitude';
import useWebhooksQuery from '@/queries/webhook/useWebhooksQuery.hook';
import { useParams } from 'react-router-dom';
import ShortListFallback from '../fallbacks/ShortListFallback';
import WebhooksForm from '../molecules/WebhooksForm';
import useUpdateWebhooksMutation from '@/queries/webhook/useUpdateWebhooksMutation.hook';
import { useBodyToastQueue } from '@/utils/hooks/useBodyToastQueue.hook';
import ExternalLink from '@/components/Link/ExternalLink';
import RouterLink from '@/components/Link/RouterLink';

const Webhook = () => {
    useAmplitudeLogEvent('Communication Settings Accessed');
    const { showId }: { showId: string } = useParams();
    const { data: webhooks, isLoading } = useWebhooksQuery(showId);
    const updateWebhooks = useUpdateWebhooksMutation();
    const formattedWebhooks = webhooks?.map((webhook) => ({ id: webhook.id, url: webhook.url }));
    const toast = useBodyToastQueue();
    const upgradeOfferLink = '/app/user/subscription/offers';

    const handleUpdateWebhooks = (payload: { urls: { id: string | number; url: string }[] }) => {
        updateWebhooks.mutate(
            {
                showId,
                payload: payload.urls.map((p) => ({ id: p.id === '' ? null : +p.id, url: p.url })),
            },
            {
                onSuccess: () => toast.success(),
            },
        );
    };

    if (isLoading) return <ShortListFallback />;

    return (
        // @ts-ignore
        <HideFor
            // @ts-ignore
            userOptions={USER_OPTIONS.WEBHOOK}
            lockedMessage={
                <EmptyState
                    illustration={noWebhookMainIllustration}
                    illustrationType="image"
                    illustrationAlign="right"
                    illustrationBackground="--gradient-dawn"
                >
                    <HeadIllustrationWrapper>
                        <ZebraIllustration>
                            <ShiningDiamond />
                        </ZebraIllustration>
                    </HeadIllustrationWrapper>
                    <LabelWrapper>
                        <Text variant="heading" fontWeight="--fw-bold">
                            <FormattedMessage defaultMessage="Passez à l'offre supérieure !" />
                        </Text>
                        <Text variant="body" fontWeight="--fw-normal" color="--neutral500">
                            <FormattedMessage defaultMessage="Pour débloquer l'ajout de Webhook personnalisés, abonnez-vous à l'offre Supersonic 🚀" />
                        </Text>
                    </LabelWrapper>
                    <RouterLink
                        variant="button-primary"
                        to={upgradeOfferLink}
                        startIcon={<IconRocket />}
                    >
                        <FormattedMessage defaultMessage="Mettre à niveau mon offre" />
                    </RouterLink>
                </EmptyState>
            }
        >
            <WebhooksWrapper>
                <WebhooksInnerWrapper>
                    <Text color="--neutral500">
                        <FormattedMessage
                            defaultMessage="Les webhooks permettent aux applications de communiquer automatiquement entre elles. Vous pouvez ainsi recevoir des notifications lorsque vous publiez un épisode. {learnMore}"
                            values={{
                                learnMore: (
                                    <ExternalLink
                                        href="https://help.ausha.co/fr/articles/2958248-qu-est-ce-qu-un-webhook-et-comment-l-utiliser"
                                        target="_blank"
                                    >
                                        <FormattedMessage defaultMessage="En savoir plus" />
                                    </ExternalLink>
                                ),
                            }}
                        />
                    </Text>
                    <WebhooksForm
                        onSubmit={handleUpdateWebhooks}
                        isLoading={updateWebhooks.isLoading}
                        webhooks={formattedWebhooks}
                    />
                </WebhooksInnerWrapper>
            </WebhooksWrapper>
        </HideFor>
    );
};

const HeadIllustrationWrapper = styled.div`
    width: 200px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    & > * {
        text-align: center;
    }
`;
const WebhooksWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding-mobile);
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: var(--container-width);

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        padding: var(--container-padding);
    }
`;
const WebhooksInnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    max-width: 680px;
`;

export default Webhook;
