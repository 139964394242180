import RouterLink from '@/components/Link/RouterLink';
import Text from '@/components/ui/atoms/Text';
import Stack from '@/components/ui/layout/Stack';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import type { Platform } from '@/api/pso/types';
import styled from 'styled-components';

interface NoBroadcastProps {
    platform: Platform;
}

const PLATFORM_LABELS = {
    spotify: 'Spotify',
    itunes: 'Apple Podcasts',
};

const NoBroadcast = ({ platform }: NoBroadcastProps) => {
    const { showId } = useParams<{ showId: string }>();

    return (
        <Wrapper>
            <Table>
                <Thead>
                    <Tr>
                        <Th>
                            <FormattedMessage defaultMessage="Mot-clé" />
                        </Th>
                        <Th $width="8.625rem">
                            <FormattedMessage defaultMessage="Classement" />
                        </Th>
                        <Th $width="8.625rem">
                            <FormattedMessage defaultMessage="Vol. de Recherche" />
                        </Th>
                        <Th $width="8.625rem">
                            <FormattedMessage defaultMessage="Difficulté" />
                        </Th>
                        <Th $width="5rem">
                            <FormattedMessage defaultMessage="Suivre" />
                        </Th>
                        <Th $width="7rem">
                            <FormattedMessage defaultMessage="Live Search" />
                        </Th>
                    </Tr>
                </Thead>
                <Tbody>
                    <Tr>
                        <Td>
                            <Line $width="25%" />
                        </Td>
                        <Td>
                            <Line $width="50%" />
                        </Td>
                        <Td>
                            <Line $width="50%" />
                        </Td>
                        <Td>
                            <Line $width="50%" />
                        </Td>
                        <Td>
                            <Circle />
                        </Td>
                        <Td>
                            <Circle />
                        </Td>
                    </Tr>
                    <Tr>
                        <Td>
                            <Line $width="35%" />
                        </Td>
                        <Td>
                            <Line $width="50%" />
                        </Td>
                        <Td>
                            <Line $width="50%" />
                        </Td>
                        <Td>
                            <Line $width="50%" />
                        </Td>
                        <Td>
                            <Circle />
                        </Td>
                        <Td>
                            <Circle />
                        </Td>
                    </Tr>
                    <Tr>
                        <Td>
                            <Line $width="25%" />
                        </Td>
                        <Td>
                            <Line $width="50%" />
                        </Td>
                        <Td>
                            <Line $width="50%" />
                        </Td>
                        <Td>
                            <Line $width="50%" />
                        </Td>
                        <Td>
                            <Circle />
                        </Td>
                        <Td>
                            <Circle />
                        </Td>
                    </Tr>
                    <Tr>
                        <Td>
                            <Line $width="25%" />
                        </Td>
                        <Td>
                            <Line $width="50%" />
                        </Td>
                        <Td>
                            <Line $width="50%" />
                        </Td>
                        <Td>
                            <Line $width="50%" />
                        </Td>
                        <Td>
                            <Circle />
                        </Td>
                        <Td>
                            <Circle />
                        </Td>
                    </Tr>
                    <Tr>
                        <Td>
                            <Line $width="50%" />
                        </Td>
                        <Td>
                            <Line $width="50%" />
                        </Td>
                        <Td>
                            <Line $width="50%" />
                        </Td>
                        <Td>
                            <Line $width="50%" />
                        </Td>
                        <Td>
                            <Circle />
                        </Td>
                        <Td>
                            <Circle />
                        </Td>
                    </Tr>
                    {platform && (
                        <Overlay>
                            <Icon icon={icon({ name: 'signal-stream-slash', style: 'solid' })} />
                            <Stack $gap="0.25rem" $align="center">
                                <Text variant="bodyL" fontWeight="--fw-bold">
                                    <FormattedMessage
                                        defaultMessage="Votre lien {platform} est manquant."
                                        values={{ platform: PLATFORM_LABELS[platform] }}
                                    />
                                </Text>
                                <Text color="--neutral500">
                                    <FormattedMessage
                                        defaultMessage="<link>Ajoutez votre lien d'écoute dans vos paramètres</link> pour accéder au classement par mots-clés."
                                        values={{
                                            link: (chunks: string) => (
                                                <RouterLink
                                                    to={`/app/show/${showId}/settings/broadcast`}
                                                >
                                                    {chunks}
                                                </RouterLink>
                                            ),
                                        }}
                                    />
                                </Text>
                            </Stack>
                        </Overlay>
                    )}
                </Tbody>
            </Table>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: 0.5rem;
`;

const Table = styled.table`
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
`;

const Th = styled.th<{ $width?: string }>`
    padding-inline: 1rem;
    text-align: left;
    width: ${({ $width }) => $width || 'auto'};
    white-space: nowrap;
    border: none;

    &:first-of-type {
        border-top-left-radius: var(--r-m);
        border-bottom-left-radius: var(--r-m);
    }

    &:last-of-type {
        border-top-right-radius: var(--r-m);
        border-bottom-right-radius: var(--r-m);
    }
`;

const Td = styled.td`
    height: 3.5rem;
    padding-inline: 1rem;
    border-top: none;
    border-bottom: 1px solid var(--neutral50);
    border-left: 1px solid var(--neutral50);

    &:first-child {
        border-left: none;
    }

    &:last-child {
        border-right: none;
    }
`;

const Tr = styled.tr`
    height: 3.5rem;

    &:last-child > td {
        border-bottom: none;
    }
`;

const Thead = styled.thead`
    position: sticky;
    top: 0;
    width: 100%;
    background-color: var(--neutral50);
    height: 3rem;
    z-index: 1;
    border-radius: var(--r-m);
    border: none;
`;

const Tbody = styled.tbody`
    position: relative;

    tr td {
        &:first-child {
            border-left: none;
        }
        &:last-child {
        }
    }
`;

const Line = styled.div<{ $width?: string }>`
    border-radius: var(--r-full);
    background-color: var(--neutral50);
    width: ${({ $width }) => $width || '100%'};
    height: 0.75rem;
`;

const Circle = styled.div`
    border-radius: var(--r-full);
    background-color: var(--neutral50);
    width: 0.75rem;
    height: 0.75rem;
    margin-inline: auto;
`;

const Icon = styled(FontAwesomeIcon)`
    width: 1.5rem;
    height: 1.5rem;
`;

const Overlay = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
    border-radius: var(--r-l);
    padding: var(--sp-l);
    background: rgba(255, 255, 255, 0.75); // Fallback if gradient is not supported
    background: radial-gradient(rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0));
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: max-content;
    height: max-content;
    text-align: center;
`;
export default NoBroadcast;
