import Button from '@/components/Button';
import RouterLink from '@/components/Link/RouterLink';
import Modal from '@/components/ui/atoms/Modal';
import Text from '@/components/ui/atoms/Text';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import useSubscriptionQuery from '@/queries/subscription/useSubscriptionQuery.hook';
import { CLIP_QUOTA } from '@/shared/config/constants';
import { PRICING_NAME } from '@/shared/config/pricing';
import { getHigherPricing } from '@/shared/services/pricingService';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { useParams } from 'react-router';
import styled from 'styled-components';

interface QuotaReachedModalProps {
    isOpen: boolean;
    onOpenChange: (open: boolean) => void;
    monthlyClips: number;
}

const QuotaReachedModal = ({ isOpen, onOpenChange, monthlyClips }: QuotaReachedModalProps) => {
    const { showId } = useParams<{ showId: string }>();
    const { data: show } = useShowQuery(showId);
    const { data: subscription } = useSubscriptionQuery();
    const currentPricing = show?.ownerPricing || subscription?.pricing;
    const higherPricing = getHigherPricing(currentPricing);
    const higherPricingName = higherPricing ? PRICING_NAME[higherPricing] : '';
    const higherPricingMonthlyClips = higherPricing ? CLIP_QUOTA[higherPricing] : 0;

    const handleClose = () => onOpenChange(false);

    return (
        <ModalStyled isOpen={isOpen} onOpenChange={onOpenChange} size="small">
            <IconWrapper>
                <WarningIcon icon={icon({ name: 'triangle-exclamation', style: 'solid' })} />
            </IconWrapper>
            <Text variant="headingS" fontWeight="--fw-bold" textAlign="center">
                <FormattedMessage defaultMessage="Vous n’avez plus de crédit…" />
            </Text>
            <Text color="--neutral500" textAlign="center">
                {higherPricing ? (
                    <FormattedMessage
                        defaultMessage="Vous pouvez attendre le mois prochain pour obtenir {monthlyClips} crédits, ou passer à l’offre {higherPricingName} pour bénéficier de {higherPricingMonthlyClips} crédits par mois."
                        values={{
                            monthlyClips: <FormattedNumber value={monthlyClips} />,
                            higherPricingName,
                            higherPricingMonthlyClips: (
                                <FormattedNumber value={higherPricingMonthlyClips} />
                            ),
                        }}
                    />
                ) : (
                    <FormattedMessage
                        defaultMessage="Il va falloir attendre le mois prochain pour obtenir {monthlyClips} crédits…"
                        values={{ monthlyClips: <FormattedNumber value={monthlyClips} /> }}
                    />
                )}
            </Text>
            <Actions>
                {higherPricing ? (
                    <>
                        <RouterLink variant="button-primary" to="/app/subscription/offers">
                            <FormattedMessage
                                defaultMessage="Passer en offre {higherPricingName}"
                                values={{ higherPricingName }}
                            />
                        </RouterLink>
                        <Button variant="link-secondary" onPress={handleClose}>
                            <FormattedMessage defaultMessage="Annuler" />
                        </Button>
                    </>
                ) : (
                    <Button onPress={handleClose}>
                        <FormattedMessage defaultMessage="Compris !" />
                    </Button>
                )}
            </Actions>
        </ModalStyled>
    );
};

const ModalStyled = styled(Modal)`
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1.5rem;
`;
const WarningIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
`;
const IconWrapper = styled.div`
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--warning50);
    color: var(--warning500);
    border-radius: var(--r-s);
`;
const Actions = styled.div`
    align-self: stretch;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    & > :last-child {
        align-self: center;
    }
`;

export default QuotaReachedModal;
