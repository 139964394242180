import { useMemo } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { extractCssVariable } from '@/shared/utils/cssVariable';
import NoDataChartOverlay from '@/components/charts/NoData/NoDataChartOverlay';
import DownloadsComparisonChartTooltip from '@/components/charts/DownloadsComparisonChart/DownloadsComparisonChartTooltip';

interface MouseMoveParams {
    dataPointIndex: number;
    seriesIndex: number;
}

interface DownloadsComparisonChartProps {
    series?: ApexAxisChartSeries;
    onMouseMove?: (params: MouseMoveParams) => void;
    isLoading?: boolean;
}

const getChartOptions = (
    isLoading: boolean,
    hasData: boolean,
    onMouseMove?: DownloadsComparisonChartProps['onMouseMove'],
): ApexOptions => {
    const chartFontFamily = "'ProximaNova', sans-serif";
    const chartForeColor = extractCssVariable('--black');
    const chartBorderColor = extractCssVariable('--neutral100');
    const chartFontSize = extractCssVariable('--fs-body');
    const chartNeutralColor = extractCssVariable('--neutral300');

    const baseOptions: ApexOptions = {
        chart: {
            fontFamily: chartFontFamily,
            foreColor: chartForeColor,
            events: {
                mouseMove: (_event: MouseEvent, _chart?: any, options?: any) => {
                    if (options) {
                        onMouseMove?.(options);
                    }
                },
                mouseLeave: (_event: MouseEvent, _chart?: any, options?: any) => {
                    if (options) {
                        onMouseMove?.({ ...options, dataPointIndex: -1 });
                    }
                },
            },
            parentHeightOffset: 0,
            toolbar: { show: false },
            zoom: { enabled: false },
        },
        grid: { borderColor: chartBorderColor },
        legend: { show: false },
        stroke: { curve: 'smooth', width: 2 },
        tooltip: {
            style: { fontSize: chartFontSize },
            custom: (props) => {
                const tooltipNode = document.createElement('div');
                // @ts-expect-error
                ReactDOM.render(<DownloadsComparisonChartTooltip {...props} />, tooltipNode);
                return tooltipNode.innerHTML;
            },
        },
        xaxis: {
            tickAmount: 7,
            labels: { rotate: 0, hideOverlappingLabels: true, style: { fontSize: chartFontSize } },
            tooltip: { enabled: false },
        },
        yaxis: {
            forceNiceScale: true,
            labels: { style: { fontSize: chartFontSize } },
        },
    };

    if (isLoading || !hasData) {
        return {
            ...baseOptions,
            chart: {
                ...baseOptions.chart,
                foreColor: chartNeutralColor,
                events: {}, // Remove events
            },
            xaxis: {
                ...baseOptions.xaxis,
                axisBorder: { ...baseOptions.xaxis?.axisBorder, color: chartBorderColor },
            },
            yaxis: { ...baseOptions.yaxis, tickAmount: 4, min: 0, max: 200000 },
        };
    }

    return baseOptions;
};

const DownloadsComparisonChart = ({
    series = [],
    onMouseMove,
    isLoading = false,
}: DownloadsComparisonChartProps) => {
    const hasData = series.length > 0;

    const chartOptions = useMemo(
        () => getChartOptions(isLoading, hasData, onMouseMove),
        [isLoading, hasData, onMouseMove],
    );

    const chartSeries = isLoading ? [] : series; // Prevent displaying data if the chart is loading

    return (
        <Container>
            <ApexChart type="line" options={chartOptions} series={chartSeries} height="350px" />
            {!isLoading && !hasData && <NoDataChartOverlay />}
        </Container>
    );
};

const Container = styled.div`
    position: relative;
`;

const ApexChart = styled(Chart)`
    .apexcharts-tooltip,
    .apexcharts-tooltip.apexcharts-theme-light,
    .apexcharts-tooltip.apexcharts-theme-dark {
        min-width: 10rem;
        padding-bottom: 0.5rem;
        background: var(--white);

        & .apexcharts-tooltip-title {
            text-align: center;
            background: var(--neutral50);
            color: var(--neutral500);
            padding: 0.625rem 1.25rem;
            border-bottom: none;
            margin-bottom: 0.5rem;
        }

        & .apexcharts-tooltip-series-group {
            padding: 0 1.25rem;
        }
    }
`;

export default DownloadsComparisonChart;
