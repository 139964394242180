import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { fetchLanguages } from '@/api';
import languageKeys from '@/queries/language/languageKeys';
import camelcaseKeys from 'camelcase-keys';

export interface Language {
    code: string;
    id: number;
    isFeatured: boolean;
    name: string;
    nativeName: string;
}

const transform = (data: unknown) => {
    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data as any, { deep: true });
};

const queryFn = async () => {
    const { data } = await fetchLanguages();

    return transform(data);
};

const useLanguagesQuery = (): UseQueryResult<Language[]> => {
    return useQuery({
        queryKey: languageKeys.list(),
        queryFn,
        staleTime: Infinity,
    });
};

export default useLanguagesQuery;
