import { useIntl } from 'react-intl';
import { z } from 'zod';
import { FORM_VALIDATION_MESSAGE } from '@/shared/services/validationMessageService';

const useCallToActionFormSchema = () => {
    const intl = useIntl();
    return z.object({
        text: z
            .string({
                required_error: intl.formatMessage(FORM_VALIDATION_MESSAGE.required),
            })
            .min(1, intl.formatMessage(FORM_VALIDATION_MESSAGE.required))
            .max(40, intl.formatMessage(FORM_VALIDATION_MESSAGE.maxLength, { max: 40 })),
        url: z.string().url(intl.formatMessage(FORM_VALIDATION_MESSAGE.isUrl)),
        locations: z
            .array(z.enum(['podcast_page', 'smartlink', 'newsletter', 'player']), {
                required_error: intl.formatMessage(FORM_VALIDATION_MESSAGE.required),
            })
            .min(1, intl.formatMessage(FORM_VALIDATION_MESSAGE.required)),
        type: z.enum([
            'custom',
            'newsletter',
            'player',
            'podcast_page',
            'smartlink',
            'crowdfunding',
        ]),
    });
};

export type CallToActionFormSchema = z.infer<ReturnType<typeof useCallToActionFormSchema>>;
export default useCallToActionFormSchema;
