import { FormattedMessage } from 'react-intl';

const keysToText = {
    email: <FormattedMessage defaultMessage="Cette adresse e-mail" />,
    slug: <FormattedMessage defaultMessage="Ce permalien" />,
    current_password: <FormattedMessage defaultMessage="du mot de passe actuel" />,
};

export default {
    isRequired: () => <FormattedMessage defaultMessage="Ce champ est requis" />,
    invalidRssLink: () => <FormattedMessage defaultMessage="Le lien RSS fourni n'est pas valide" />,
    differentFrom: (key) =>
        key ? (
            <FormattedMessage
                defaultMessage="Ce champ doit être différent {param}"
                values={{ param: keysToText[key] }}
            />
        ) : (
            <FormattedMessage defaultMessage="Les deux champs ne peuvent être identiques" />
        ),
    isEmail: () => (
        <FormattedMessage defaultMessage="Ce champ doit être une adresse e-mail valide" />
    ),
    isUrl: () => <FormattedMessage defaultMessage="Ce champ doit contenir une url valide" />,
    isUnique: (key) => (
        <FormattedMessage
            defaultMessage="{param} existe déjà"
            values={{ param: keysToText[key] }}
        />
    ),
    minNumber: (min) => (
        <FormattedMessage defaultMessage="Doit être supérieur à {min}" values={{ min }} />
    ),
    maxNumber: (max) => (
        <FormattedMessage defaultMessage="Doit être inférieur à {max}" values={{ max }} />
    ),
    minLength: (min) => (
        <FormattedMessage
            defaultMessage="Ce champ doit contenir au moins {min} caractères"
            values={{ min }}
        />
    ),
    maxLength: (max, key) =>
        key === 'file' ? (
            <FormattedMessage
                defaultMessage="Le fichier doit peser moins de {max} Mo"
                values={{ max: max / 1000 }}
            />
        ) : (
            <FormattedMessage
                defaultMessage="Ce champ doit contenir moins de {max} caractères"
                values={{ max }}
            />
        ),
    invalidPassword: () => (
        <FormattedMessage defaultMessage="Votre mot de passe n'est pas valide" />
    ),
    passwordsNotMatching: () => (
        <FormattedMessage defaultMessage="Les mots de passe ne correspondent pas" />
    ),
    exists: () => <FormattedMessage defaultMessage="Cette émission n'existe pas" />,
    default: () => <FormattedMessage defaultMessage="Ce champ est incorrect" />,
    invalidVat: () => <FormattedMessage defaultMessage="Ce numéro de TVA est invalide" />,
    alphaDash: () => (
        <FormattedMessage defaultMessage="Ce champ ne doit contenir que des lettres, des chiffres ou des tirets" />
    ),
    minDimension: (minWidth, minHeight) => (
        <FormattedMessage
            defaultMessage="L'image doit avoir une dimension minimale de {minWidth}x{minHeight}"
            values={{ minWidth, minHeight }}
        />
    ),
    disposableEmailBlocked: () => (
        <FormattedMessage defaultMessage="Votre adresse mail n’est pas supportée. Nous vous invitons à utiliser une autre adresse ou à nous contacter." />
    ),
    requiredMime: (acceptedMimes) => (
        <FormattedMessage
            defaultMessage="Le fichier doit être de type: {acceptedMimes}"
            values={{ acceptedMimes: acceptedMimes.join(', ') }}
        />
    ),
    ratioRule: () => <FormattedMessage defaultMessage="Le ratio de l'image est incorrect" />,
    fileMustBeImage: () => <FormattedMessage defaultMessage="Le fichier doit être une image" />,

    acceptedMimesImgUpload: (provider, currentFormat) => (
        <FormattedMessage
            defaultMessage="Le format de votre image {currentFormat} n’est pas conforme. Dû à des limitations imposées par {provider}, nous ne pouvons pas poster votre image si celle-ci ne respecte pas un format de .jpg ou .png."
            values={{
                currentFormat: currentFormat,
                provider: provider,
            }}
        />
    ),
};
