import { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';

interface StepperProps {
    currentStep: number;
    stepCount: number;
    color?: string;
    labels?: React.ReactNode[];
}

const Stepper = ({ currentStep, stepCount, color, labels }: StepperProps) => {
    return (
        <StepperWrapper>
            {Array.from({ length: stepCount }, (_, i) => (
                <Fragment key={i}>
                    {i !== 0 && (
                        <BarLink $isActive={i <= currentStep} $color={color || '--primary'} />
                    )}
                    <Step $isActive={i <= currentStep} $color={color || '--primary'}>
                        {i < currentStep - 1 ? (
                            <StepIcon icon={icon({ name: 'circle-check', style: 'solid' })} />
                        ) : (
                            <StepIcon icon={icon({ name: 'circle', style: 'regular' })} />
                        )}
                        {labels && labels[i] && <LabelWrapper>{labels[i]}</LabelWrapper>}
                    </Step>
                </Fragment>
            ))}
        </StepperWrapper>
    );
};

const StepperWrapper = styled.ul`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    list-style: none;
    gap: 1rem;

    @media (max-width: 1096px) {
        gap: 0.5rem;
        flex-direction: column;
    }
`;
const Step = styled.li<{ $isActive: boolean; $color: string }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    color: ${({ $color }) => `var(${$color})`};

    @media (max-width: 1096px) {
        flex-direction: row;
    }
`;
const BarLink = styled.div<{ $isActive: boolean; $color: string }>`
    flex-grow: 1;
    height: 0.125rem;
    background-color: ${({ $color }) => `var(${$color})`};
    margin-top: 0.5rem;

    @media (max-width: 1096px) {
        width: 0.125rem;
        height: 0.75rem;
        margin-top: 0;
        margin-left: 0.45rem;
    }
`;
const StepIcon = styled(FontAwesomeIcon)`
    font-size: 1rem;
`;
const LabelWrapper = styled.div`
    color: var(--black);
`;

export default Stepper;
