import { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import DropdownMenu from '@/components/ui/molecules/DropdownMenu';
import Button from '@/components/Button';
// @ts-ignore
import Spotify from '@public/images/platforms/Spotify.svg?url';
// @ts-ignore
import ApplePodcast from '@public/images/platforms/ApplePodcasts.svg?url';

export interface PsoStoreSelectProps {
    currentPlatform: string;
    setCurrentPlatform: (store: string) => void;
}

const PsoPlatformSelect = ({ currentPlatform, setCurrentPlatform }: PsoStoreSelectProps) => {
    const [opened, setOpened] = useState(false);

    const items = [
        {
            id: 'apple_podcasts',
            label: <PlatformName>Apple Podcasts</PlatformName>,
            icon: <PlatformIcon src={ApplePodcast} alt="ApplePodcast" />,
            onClick: () => {
                setCurrentPlatform('apple_podcasts');
                setOpened(false);
            },
        },
        {
            id: 'spotify',
            label: <PlatformName>Spotify</PlatformName>,
            icon: <PlatformIcon src={Spotify} alt="Spotify" />,
            onClick: () => {
                setCurrentPlatform('spotify');
                setOpened(false);
            },
        },
    ];

    return (
        <DropdownMenu
            // @ts-ignore
            menuItems={items}
            direction="left"
            opened={opened}
            onToggle={() => {
                setOpened(!opened);
            }}
            onClose={() => setOpened(false)}
        >
            <DropdownButton
                variant="tertiary"
                startIcon={items?.find((item) => item.id === currentPlatform)?.icon}
                endIcon={
                    <FontAwesomeIcon
                        icon={icon({ name: 'chevron-down', style: 'solid' })}
                        size="xs"
                    />
                }
            >
                {items?.find((item) => item.id === currentPlatform)?.label}
            </DropdownButton>
        </DropdownMenu>
    );
};

const DropdownButton = styled(Button)`
    width: 15rem;
    justify-content: flex-start;
    color: var(--neutral500);
    font-weight: var(--fw-normal);

    & > span {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }

    & > svg {
        margin-left: auto;
    }
`;
const PlatformIcon = styled.img`
    width: 1rem;
    height: 1rem;
`;
const PlatformName = styled.span`
    color: var(--black);
`;

export default PsoPlatformSelect;
