import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { useStatsDateSelectorContext } from '@/context/StatsDateSelectorContext';
import useGendersQuery from '@queries/stats/useGendersQuery.hook';
import { useParams } from 'react-router';
import AudienceGendersChart from './AudienceGendersChart';
import DevicesFallback from '@/components/unorganized/DevicesFallback';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';
import { TooltipTrigger } from 'react-aria-components';
import Filters from '../../Filters';

const AudienceGenders = () => {
    const { showId } = useParams<{ showId: string }>();
    const { startDate, endDate } = useStatsDateSelectorContext();
    const { data: genders } = useGendersQuery({
        showId,
        query: { start: startDate, end: endDate },
    });

    if (genders) {
        return (
            <>
                <OuterHeader>
                    <TitleAndDescription>
                        <Title>
                            <FormattedMessage defaultMessage="Audience" />
                            <TooltipTrigger delay={0} closeDelay={0}>
                                <TooltipTriggerWrapper>
                                    <TooltipIcon
                                        icon={icon({ name: 'info-circle', style: 'solid' })}
                                    />
                                </TooltipTriggerWrapper>
                                <Tooltip placement="right">
                                    <FormattedMessage
                                        defaultMessage="L'audience concerne uniquement la{linebreak}plateforme Deezer pour le moment."
                                        values={{ linebreak: <br /> }}
                                    />
                                </Tooltip>
                            </TooltipTrigger>
                        </Title>
                        <Description>
                            <FormattedMessage defaultMessage="Quelle est la répartition de votre audience par genre (Deezer uniquement) ?" />
                        </Description>
                    </TitleAndDescription>
                    <Filters episodeAndCampaignDisabled={true} />
                </OuterHeader>
                <AudienceGendersChart genders={genders} />
            </>
        );
    }

    return <DevicesFallback count={3} />;
};

const TooltipIcon = styled(FontAwesomeIcon)`
    font-size: 0.75rem;
    color: var(--neutral500);
    margin-bottom: 0.125rem;
`;
const OuterHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @media (max-width: 775px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
    }
`;
const TitleAndDescription = styled.div``;
const Title = styled.h2`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: var(--fs-heading-m);
`;
const Description = styled.p``;

export default AudienceGenders;
