import { z } from 'zod';
import { ChannelGuestRoleSchema } from '@/queries/guest/useChannelGuestPermissionsQuery.hook';
import { ChannelGuestShowRoleSchema } from '@/queries/guest/useChannelGuestUserPermissionsSummary.hook';

const useChannelGuestsEditPermissionFormSchema = () => {
    return z.object({
        mainRole: ChannelGuestRoleSchema,
        shows: z
            .array(
                z.object({
                    id: z.number(),
                    name: z.string(),
                    image: z.string().nullable(),
                    role: ChannelGuestShowRoleSchema,
                }),
            )
            .refine((shows) => shows.some((show) => show.role !== 'none')),
    });
};

export type ChannelGuestsEditPermissionFormSchema = z.infer<
    ReturnType<typeof useChannelGuestsEditPermissionFormSchema>
>;

export default useChannelGuestsEditPermissionFormSchema;
