import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import DeprecatedModal from '../../ui/molecules/DeprecatedModal';
import DeprecatedButton from '../../ui/atoms/DeprecatedButton';
import DeprecatedText from '../../ui/atoms/DeprecatedText';
import { connect } from '../decorators/connect';
import DeprecatedPaper from '../../ui/atoms/DeprecatedPaper';
import ImgMenuItem from '../../ui/molecules/ImgMenuItem';
import Spinner from '../../ui/atoms/Spinner';

const enhance = connect(({ routerStore, showStore }, { isEpisodePreviewPage }) => ({
    shows: showStore.shows.filter((s) => s.id !== parseInt(routerStore.params.showId, 10)),
    link: routerStore,
    isEpisodePreviewPage,
}));

function MovePodcastsModal({
    onClose,
    onMove,
    moving,
    count,
    isOpened,
    shows,
    link,
    isRedirect = false,
}) {
    const [showId, setShowId] = useState(null);

    const submitMove = async () => {
        await onMove(showId);
        onClose();
        if (isRedirect) link.push('menu.episodes');
    };

    return (
        <DeprecatedModal
            title={
                <FormattedMessage
                    defaultMessage="Où voulez-vous déplacer {count, plural, one {l'épisode} other {les épisodes}} ?"
                    values={{ count }}
                />
            }
            onClose={onClose}
            isOpened={isOpened}
        >
            <DeprecatedText>
                <FormattedMessage
                    defaultMessage="Sélectionnez l'émission vers laquelle vous souhaitez déplacer {count, plural, one {votre épisode} other {vos épisodes}}."
                    values={{ count }}
                />
            </DeprecatedText>
            <DeprecatedPaper my={30} overflowY="scroll" maxH={250}>
                {shows.map((s) => (
                    <ImgMenuItem
                        key={s.id}
                        label={s.name}
                        img={s.imageUrl}
                        active={s.id === showId}
                        onClick={() => setShowId(s.id)}
                    />
                ))}
            </DeprecatedPaper>
            <DeprecatedPaper flex justify="flex-end">
                <DeprecatedButton accent="secondary" mr={10} onClick={onClose}>
                    <FormattedMessage defaultMessage="Annuler" />
                </DeprecatedButton>
                <DeprecatedButton onClick={() => submitMove()} disabled={moving || !showId}>
                    {moving && <Spinner />}
                    <FormattedMessage
                        defaultMessage="Déplacer {count, plural, one {l'épisode} other {les épisodes}}"
                        values={{ count }}
                    />
                </DeprecatedButton>
            </DeprecatedPaper>
        </DeprecatedModal>
    );
}

export default enhance(MovePodcastsModal);
