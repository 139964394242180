import { useState } from 'react';
import trash from '@public/icons/trash.svg';
import { FormattedMessage } from 'react-intl';
import Img from '@/components/ui/atoms/Img';
import DeprecatedText from '@/components/ui/atoms/DeprecatedText';
import DeprecatedButton from '@/components/ui/atoms/DeprecatedButton';
import DeprecatedIcon from '@/components/ui/atoms/DeprecatedIcon';
import ShowGuestsInvitationDeleteModal from './ShowGuestsInvitationDeleteModal';
import { ShowGuestInvitation } from '@/queries/guest/useShowGuestInvitationsQuery.hook';
import styled from 'styled-components';

interface ShowGuestsInvitationProps {
    invitation: ShowGuestInvitation;
}

const ShowGuestsInvitation = ({ invitation }: ShowGuestsInvitationProps) => {
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    return (
        <>
            <Wrapper>
                <div>
                    <Img circle mr={15} size={40} src={invitation.avatar} />
                </div>
                <DeprecatedText weight="semibold" size="big" color="--neutral500" ellipsis auto>
                    {`${invitation.email} `}
                    <FormattedMessage defaultMessage="(invitation envoyée)" />
                </DeprecatedText>
                <DeprecatedButton accent="rounded" onClick={() => setIsDeleteModalOpen(true)} icon>
                    <DeprecatedIcon as={trash} />
                </DeprecatedButton>
            </Wrapper>
            <ShowGuestsInvitationDeleteModal
                invitation={invitation}
                isOpen={isDeleteModalOpen}
                onOpenChange={setIsDeleteModalOpen}
            />
        </>
    );
};

const Wrapper = styled.div`
    height: 4.75rem;
    border-bottom: 1px solid var(--neutral100);
    display: flex;
    align-items: center;
    column-gap: 1rem;
`;

export default ShowGuestsInvitation;
