import { useFormContext } from 'react-hook-form';
import { InputTextController } from '@ui/atoms/InputText';
import { FormattedMessage } from 'react-intl';

const SelectedChapterUrlField = ({ index }) => {
    const { control } = useFormContext();

    return (
        <InputTextController
            name={`chapters.${index}.url`}
            control={control}
            label={<FormattedMessage defaultMessage="Lien du chapitre" />}
        />
    );
};

export default SelectedChapterUrlField;
