import { useState } from 'react';
import {
    ListeningPlatform,
    AUTOMATIC_BROADCAST_PLATFORMS,
    ONE_CLICK_BROADCAST_PLATFORMS,
    SOUNDCLOUD,
    YOUTUBE,
} from '@/shared/config/constants';
import { FormattedMessage } from 'react-intl';
import BroadcastButton from '../../../BroadcastButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import RouterLink from '@/components/Link/RouterLink';
import { useHistory, useParams } from 'react-router';
import Tooltip, { TooltipTriggerWrapper } from '@ui/Tooltip';
import { TooltipTrigger } from 'react-aria-components';
import useShowQuery from '@queries/show/useShowQuery.hook';
import ConfirmModal from './ConfirmModal';
import BroadcastSoundcloudLoginModal from '../../../BroadcastSoundcloudLoginModal';
import BroadcastYoutubeLoginModal from '../../../BroadcastYoutubeLoginModal';
import { useQueryClient } from '@tanstack/react-query';
import styled from 'styled-components';

interface DisconnectedProps {
    platformKey: ListeningPlatform;
    name: string;
    logo: string;
    pendingMessage?: React.ReactNode;
    tooltip?: React.ReactNode;
    isDisabled?: boolean;
}

const Disconnected = ({
    platformKey,
    name,
    logo,
    pendingMessage,
    isDisabled,
    tooltip,
}: DisconnectedProps) => {
    const { showId } = useParams<{ showId: string }>();
    const queryClient = useQueryClient();
    const { data: show } = useShowQuery(showId);
    const history = useHistory();

    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

    const isShowPmp = show?.hostedOnAusha === false;
    const shouldDisplaySettingsIcon = AUTOMATIC_BROADCAST_PLATFORMS.includes(platformKey as any);
    const shouldDisplayOneClickLabel =
        !isShowPmp && ONE_CLICK_BROADCAST_PLATFORMS.includes(platformKey as any);
    return (
        <>
            <CardWrapper>
                <LogoWrapper>
                    <Logo src={logo} />
                </LogoWrapper>
                {shouldDisplaySettingsIcon && (
                    <SettingsButton
                        to={`/app/show/${showId}/settings/broadcast/platform/${platformKey}`}
                        startIcon={
                            <FontAwesomeIcon icon={icon({ name: 'gear', style: 'solid' })} />
                        }
                    />
                )}

                {shouldDisplayOneClickLabel && (
                    <TooltipTrigger delay={0} closeDelay={0}>
                        <PillWrapper>
                            <TooltipTriggerWrapper>
                                <Pill>
                                    <FontAwesomeIcon
                                        icon={icon({ name: 'bolt', style: 'solid' })}
                                    />
                                    <FormattedMessage defaultMessage="1 clic" />
                                </Pill>
                            </TooltipTriggerWrapper>
                        </PillWrapper>
                        <Tooltip placement="top">
                            <FormattedMessage
                                defaultMessage="Publiez automatiquement votre podcast sur {break}{platform} en un seul clic, en appuyant{break}sur le bouton « Diffuser » ci-dessous"
                                values={{ break: <br />, platform: name }}
                            />
                        </Tooltip>
                    </TooltipTrigger>
                )}

                <ContentWrapper>
                    <PlatformName>{name}</PlatformName>
                    {pendingMessage ? (
                        <PendingMessage>{pendingMessage}</PendingMessage>
                    ) : (
                        <BroadcastButton
                            platformKey={platformKey}
                            isLoading={false}
                            handleBroadcastSetting={() => {
                                if (isShowPmp) {
                                    history.push(
                                        `/app/show/${showId}/settings/broadcast/platform/${platformKey}`,
                                    );
                                    return;
                                }
                                setIsConfirmModalOpen(true);
                            }}
                            isDisabled={isDisabled}
                            tooltip={tooltip}
                        />
                    )}
                </ContentWrapper>
            </CardWrapper>

            {platformKey === SOUNDCLOUD ? (
                <BroadcastSoundcloudLoginModal
                    isOpen={isConfirmModalOpen}
                    onClose={() => setIsConfirmModalOpen(false)}
                    onSuccess={() => {
                        setIsConfirmModalOpen(false);
                        setTimeout(() => {
                            queryClient.invalidateQueries({
                                queryKey: ['shows.soundcloud.show', showId],
                            });
                            window.location.reload();
                        }, 1000);
                    }}
                />
            ) : platformKey === YOUTUBE ? (
                <BroadcastYoutubeLoginModal
                    isOpen={isConfirmModalOpen}
                    onClose={() => setIsConfirmModalOpen(false)}
                    onSuccess={() => {
                        setIsConfirmModalOpen(false);
                        setTimeout(() => {
                            queryClient.invalidateQueries({
                                queryKey: ['shows.youtube.show', showId],
                            });
                            window.location.reload();
                        }, 1000);
                    }}
                />
            ) : (
                <ConfirmModal
                    platformKey={platformKey}
                    platformName={name}
                    isOpen={isConfirmModalOpen}
                    onOpenChange={setIsConfirmModalOpen}
                />
            )}
        </>
    );
};

const CardWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--white);
    gap: 1rem;
    padding: 1rem;
    border-radius: var(--r-s);
`;
const LogoWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-full);
    border: 1px solid var(--neutral50);
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -2.5rem;
`;
const Logo = styled.img`
    width: 1.5rem;
    height: 1.5rem;
`;
const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
`;
const PlatformName = styled.span`
    font-weight: var(--fw-semibold);
`;
const PendingMessage = styled.span`
    color: var(--neutral);
    font-size: var(--fs-body-s);
    text-align: center;
`;
const SettingsButton = styled(RouterLink)`
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    transition-duration: 0.25s;
    color: var(--neutral);
    padding: 0.5rem;

    &:hover {
        color: var(--black);
        background-color: var(--neutral50);
    }
`;
const PillWrapper = styled.div`
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
`;
const Pill = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
    background-color: var(--primary50);
    color: var(--primary);
    padding-inline: 0.5rem;
    padding-block: 0.25rem;
    border-radius: var(--r-full);
    font-weight: var(--fw-semibold);
    font-size: var(--fs-body-s);
`;

export default Disconnected;
