import { connect } from '@/components/legacy/connect';
import { FormattedMessage } from 'react-intl';
import exportIcon from '@public/icons/external-link.svg';
import DeprecatedIcon from '@ui/atoms/DeprecatedIcon';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import Spinner from '@ui/atoms/Spinner';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import { useStatsDateSelectorContext } from '@/context/StatsDateSelectorContext';

const enhance = connect(({ routerStore, applePodcastsStatsStore }, { step }) => {
    const { showId } = routerStore.params;
    const { applePodcastsCategoryId: categoryId } = applePodcastsStatsStore;
    const { startDate, endDate } = useStatsDateSelectorContext();

    return {
        fetchCsv: () =>
            applePodcastsStatsStore.getApplePodcastsRankingCsv(showId, categoryId, {
                start: startDate,
                end: endDate,
                step,
            }),
        isLoading: applePodcastsStatsStore.getApplePodcastsRankingCsv.pending,
    };
});

function ApplePodcastsRankingExportButton({ fetchCsv, isLoading }) {
    return (
        <DeprecatedPaper flex justify="flex-end" mt={15}>
            <DeprecatedText
                flex
                align="center"
                onClick={fetchCsv}
                disabled={isLoading}
                color="--primary"
                weight="semibold"
            >
                {isLoading ? (
                    <Spinner color="--neutral100" mr={10} />
                ) : (
                    <DeprecatedIcon color="--primary" as={exportIcon} mr={10} />
                )}
                <FormattedMessage defaultMessage="Exporter en .csv" />
            </DeprecatedText>
        </DeprecatedPaper>
    );
}

export default enhance(ApplePodcastsRankingExportButton);
