import { useQuery } from '@tanstack/react-query';
import { psoKeywordQuota } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import psoKeys from './psoKeys';

const queryFn = async ({ queryKey: [{ showId }] }) => {
    const { data } = await psoKeywordQuota(showId);

    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const useKeywordQuotaQuery = ({ showId }) => {
    return useQuery({
        queryKey: psoKeys.keywordQuota(showId),
        queryFn,
        enabled: !!showId,
        retry: false,
    });
};

export default useKeywordQuotaQuery;
