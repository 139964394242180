import { useMemo, useState } from 'react';
import Color from 'color';
import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { useSwitch } from '@hooks/useSwitch';
import Stack from '@ui/layout/Stack';
import Flex from '@ui/layout/Flex';
import { DeprecatedText } from '@ui/atoms/DeprecatedText';
import Text from '@ui/atoms/Text';
import Cluster from '@ui/layout/Cluster';
import DeprecatedModal from '@ui/molecules/DeprecatedModal';
import DeprecatedIcon from '@ui/atoms/DeprecatedIcon';
import DeprecatedButton from '@ui/atoms/DeprecatedButton';
import ButtonAccess from '@app/atoms/ButtonAccess';
import Menu from '@ui/atoms/Menu';
import MenuItem from '@ui/atoms/MenuItem';
import useDroppable from '@/utils/hooks/useDroppable';
import IconMore from '@public/icons/dotted.svg';
import IconTrash from '@public/icons/trash.svg';
import HideFor from '@app/atoms/HideFor';
import IconError from '@ui/icons/IconError';

const Pill = styled.div`
    display: flex;
    align-items: center
    justify-content: center;
    column-gap: 0.25rem;
    background: var(--alert50);
    color: var(--alert);
    border-radius: var(--r-full);
    padding-inline: 0.5rem;
    padding-block: 0.25rem;
    flex-shrink: 0;
`;

const OpenMenuButton = ({ onClick }) => (
    <ButtonAccess
        accent="ghost"
        pos="absolute"
        icon
        xR={6}
        y={6}
        onClick={onClick}
        css="background: transparent"
    >
        <DeprecatedIcon as={IconMore} color="white" />
    </ButtonAccess>
);

const ClipFormatEpisode = ({ episode }) => (
    <Cluster $gap="0.5rem" $align="center" $wrap="nowrap">
        <DeprecatedText color="--neutral500" ellipsis>
            {episode.name}
        </DeprecatedText>
    </Cluster>
);

const DeleteClipFormatModal = ({ clipFormat, isOpened, onClose }) => {
    if (!clipFormat) {
        return null;
    }

    return (
        <DeprecatedModal
            isOpened={isOpened}
            onClose={onClose}
            title={<FormattedMessage defaultMessage="Souhaitez-vous supprimer ce clip ?" />}
            maxW={400}
        >
            <Stack $gap="2.5rem">
                <DeprecatedText>
                    <FormattedMessage defaultMessage="Cette action est irréversible." />
                </DeprecatedText>
                <Cluster $gap="0.5rem" $justify="flex-end">
                    <DeprecatedButton accent="secondary" onClick={onClose} w="max-content">
                        <FormattedMessage defaultMessage="Annuler" />
                    </DeprecatedButton>
                    <DeprecatedButton
                        onClick={() => clipFormat.delete().then(onClose)}
                        w="max-content"
                    >
                        <FormattedMessage defaultMessage="Supprimer" />
                    </DeprecatedButton>
                </Cluster>
            </Stack>
        </DeprecatedModal>
    );
};

const ErrorClipFormat = ({ clipFormat, episode }) => {
    const [isHovered, setHovered] = useState(false);
    const [_, openSelectedModal] = useSwitch(false);
    const [isDeleteModalOpened, openDeleteModal, closeDeleteModal] = useSwitch(false);
    const [refMenu, isMenuOpened, openMenu] = useDroppable();

    const openMenuHandler = (event) => {
        event.stopPropagation();
        openMenu();
    };

    const openDeleteModalHandler = (event) => {
        event.stopPropagation();
        openDeleteModal();
    };

    // Compute background color based on clip color
    const bgColor = useMemo(() => {
        try {
            return Color(clipFormat.clip.color).alpha(0.07).string();
        } catch (error) {
            // Unable to parse color
            return null;
        }
    }, [clipFormat.clip.color]);

    return (
        <Stack $gap="0.75rem">
            <Flex
                $pos="relative"
                $minH={152}
                $px="0.625rem"
                $py="1rem"
                $align="center"
                $justify="center"
                $bgColor={bgColor}
                $borderRadius="--r-xs"
                $overflow="hidden"
                as={motion.div}
                initial="idle"
                animate={isHovered ? 'hover' : 'idle'}
                onHoverStart={() => setHovered(true)}
                onHoverEnd={() => setHovered(false)}
            >
                <Text variant="footnote">
                    <FormattedMessage defaultMessage="Votre clip n'a pas pu être généré. N'hésitez pas à le re-générer ou à nous contacter pour que l'on puisse résoudre le problème rapidement 🙂" />
                </Text>
                <AnimatePresence>
                    {isHovered && (
                        <Flex
                            key="clip-hover-container"
                            $pos="absolute"
                            $top={0}
                            $right={0}
                            $bottom={0}
                            $left={0}
                            $bgColor="rgba(0, 0, 0, 0.5)"
                            $align="center"
                            $justify="center"
                            as={motion.div}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            onClick={openSelectedModal}
                            $cursor="pointer"
                        >
                            <HideFor roles={['viewer']}>
                                <OpenMenuButton onClick={openMenuHandler} />
                                <Menu
                                    ref={refMenu}
                                    opened={isMenuOpened}
                                    overflowHide
                                    css="
                                        left: auto;
                                        right: 6px;
                                        top: 50px;
                                        bottom: auto;
                                        width: auto;
                                        min-width: 10rem;
                                    "
                                >
                                    <MenuItem
                                        onClick={openDeleteModalHandler}
                                        css="
                                            height: auto;
                                            padding: 0.75rem;
                                        "
                                    >
                                        <DeprecatedIcon as={IconTrash} />
                                        <FormattedMessage defaultMessage="Supprimer" />
                                    </MenuItem>
                                </Menu>
                            </HideFor>
                        </Flex>
                    )}
                </AnimatePresence>
            </Flex>
            <Stack $gap="0.25rem">
                <Cluster $gap="0.5rem" $wrap="no-wrap">
                    <Text numberOfLines={1} fontWeight="--fw-semibold">
                        {clipFormat.clip.adminTitle || clipFormat.clip.title}
                    </Text>
                    <Pill>
                        <IconError />
                        <FormattedMessage defaultMessage="Erreur" />
                    </Pill>
                </Cluster>
                {episode && <ClipFormatEpisode episode={episode} />}
            </Stack>
            <DeleteClipFormatModal
                clipFormat={clipFormat}
                isOpened={isDeleteModalOpened}
                onClose={closeDeleteModal}
            />
        </Stack>
    );
};

export default ErrorClipFormat;
