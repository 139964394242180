import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import SharingProviderButton from '@ui/atoms/SharingProviderButton';
import Text from '@ui/atoms/Text';
import Success from '@public/images/success.svg';
import UnlistedIcon from '@public/images/confirm-unlisted.svg';
import PrivateIcon from '@public/images/confirm-private.svg';
import Cluster from '@ui/layout/Cluster';
import Stack from '@ui/layout/Stack';
import AdditionalActions from './AdditionalActions';
import styled from 'styled-components';
import { DEPRECATED_ORIGINAL_PLATFORMS } from '@/shared/config/constants';
import { useParams } from 'react-router-dom';
import SubscribeSurveyModal from '@/components/unorganized/SubscribeSurveyModal';
import { useOverlayTriggerState } from 'react-stately';
import useUserQuery from '@queries/user/useUserQuery.hook';
import useSubscriptionQuery from '@queries/subscription/useSubscriptionQuery.hook';
import useEpisodeQuery from '@queries/episode/useEpisodeQuery.hook';
import useShowQuery from '@queries/show/useShowQuery.hook';

const IconWrapper = styled(Cluster)`
    & > * {
        width: 6rem;
        height: auto;
    }
`;

const Container = styled(Stack)`
    row-gap: 1rem;
    width: 100%;
    max-width: 600px;
`;

const messagePrivacyCongratulation = {
    public: (
        <FormattedMessage
            defaultMessage="Votre épisode a bien été publié. {linebreak} Partagez-le dès à présent au monde entier 🌎"
            values={{
                linebreak: <br />,
            }}
        />
    ),
    publicNoBroadcast: (
        <FormattedMessage
            defaultMessage="Votre épisode a bien été publié. {linebreak} Il est maintenant temps de passer à la diffusion 🚀"
            values={{
                linebreak: <br />,
            }}
        />
    ),
    unlisted: (
        <FormattedMessage
            defaultMessage="Votre épisode a bien été publié en non-listé.{linebreak}Partagez-le en toute discrétion avec qui vous le souhaitez 🤫"
            values={{
                linebreak: <br />,
            }}
        />
    ),
    private: (
        <FormattedMessage
            defaultMessage="Votre épisode a bien été publié en privé.{linebreak}Gardez-le juste pour vous 🤗"
            values={{
                linebreak: <br />,
            }}
        />
    ),
};

const ContentButtonShare = ({ episodeSmartlinkUrl }) => [
    <SharingProviderButton key="facebook" url={episodeSmartlinkUrl} provider="facebook" />,
    <SharingProviderButton key="twitter" url={episodeSmartlinkUrl} provider="twitter" />,
    <SharingProviderButton key="linkedin" url={episodeSmartlinkUrl} provider="linkedin" />,
    <SharingProviderButton key="whatsapp" url={episodeSmartlinkUrl} provider="whatsapp" />,
];

const ContentAutoSharing = ({ episodeSmartlinkUrl, isActive }) => {
    if (!isActive) return null;
    return (
        <Stack $gap="0.5rem">
            <Text variant="body" fontWeight="--fw-bold" textAlign="center" color="--neutral500">
                <FormattedMessage defaultMessage="Ou partagez directement le lien d’écoute sur" />
            </Text>
            <Cluster $justify="center" $gap="0.75rem">
                <ContentButtonShare episodeSmartlinkUrl={episodeSmartlinkUrl} />
            </Cluster>
        </Stack>
    );
};

const iconMatch = {
    public: <Success />,
    unlisted: <UnlistedIcon />,
    private: <PrivateIcon />,
};

function CongratulateEpisodeSummaryActive() {
    const { showId, episodeId } = useParams();
    const { data: episode } = useEpisodeQuery({ episodeId });
    const { data: user } = useUserQuery();
    const { data: subscription } = useSubscriptionQuery();
    const { data: show } = useShowQuery(showId);
    let subscribeSurveyModalState = useOverlayTriggerState({});
    const hasFilledSubscribeSurvey = !!user?.surveys?.find((survey) => survey === 'subscribe');
    useEffect(() => !hasFilledSubscribeSurvey && subscribeSurveyModalState.open(), []);
    const isSubscriptionActive = !!subscription?.activated && !!user?.pricing;
    const isSubscribeSurveyDisplayed =
        !hasFilledSubscribeSurvey && isSubscriptionActive && subscribeSurveyModalState.isOpen;
    const isBroadcast =
        show &&
        Object.keys(show?.listeningLinks).some(
            (link) => DEPRECATED_ORIGINAL_PLATFORMS.includes(link) && show?.listeningLinks[link],
        );
    const isPublic = episode?.privacy === 'public';

    return (
        <>
            <Container>
                <IconWrapper $align="center" $justify="center">
                    {iconMatch[episode?.privacy]}
                </IconWrapper>
                <Stack $gap="0.5rem">
                    <Text variant="title" fontWeight="--fw-bold" textAlign="center">
                        <FormattedMessage defaultMessage="Félicitations !" />
                    </Text>
                    <Text variant="body" textAlign="center">
                        {
                            messagePrivacyCongratulation[
                                isBroadcast ? episode?.privacy : 'publicNoBroadcast'
                            ]
                        }
                    </Text>
                </Stack>
                {isPublic && <AdditionalActions isBroadcast={isBroadcast} />}
                {isBroadcast && (
                    <ContentAutoSharing
                        isActive={isPublic}
                        episodeSmartlinkUrl={episode?.smartlinkUrl}
                    />
                )}
            </Container>
            {isSubscribeSurveyDisplayed && (
                <SubscribeSurveyModal
                    isOpened={subscribeSurveyModalState.isOpen}
                    onClose={subscribeSurveyModalState.close}
                />
            )}
        </>
    );
}

export default CongratulateEpisodeSummaryActive;
