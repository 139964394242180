import styled from 'styled-components';
import Text from '@/components/ui/atoms/Text';
import DownloadsComparisonChartEpisodeMarker from '@/components/charts/DownloadsComparisonChart/DownloadsComparisonChartEpisodeMarker';

interface DownloadsComparisonChartTooltipProps {
    series: number[][];
    dataPointIndex: number;
    w: {
        globals: {
            categoryLabels: string[];
            seriesColors: string[];
            seriesNames: string[];
        };
    };
}

const DownloadsComparisonChartTooltip = ({
    series,
    dataPointIndex,
    w,
}: DownloadsComparisonChartTooltipProps) => {
    return (
        <>
            <Header>
                <Text variant="bodyM" color="--neutral500" textAlign="center">
                    {w.globals.categoryLabels[dataPointIndex]}
                </Text>
            </Header>
            <Content>
                {series.map((data, index) => (
                    <Episode key={index}>
                        <DownloadsComparisonChartEpisodeMarker
                            color={w.globals.seriesColors[index]}
                            size="0.5rem"
                        />
                        <div>
                            <Text variant="bodyM" fontWeight="--fw-semibold">
                                {data[dataPointIndex]}
                            </Text>
                            <EpisodeName variant="bodyS" color="--neutral500" numberOfLines={1}>
                                {w.globals.seriesNames[index]}
                            </EpisodeName>
                        </div>
                    </Episode>
                ))}
            </Content>
        </>
    );
};

const Header = styled.div`
    background-color: var(--neutral50);
    padding: 0.625rem;
`;

const Content = styled.div`
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;

const Episode = styled.div`
    display: flex;
    gap: 0.375rem;
    align-items: baseline;
`;

const EpisodeName = styled(Text)`
    max-width: 10rem;
`;

export default DownloadsComparisonChartTooltip;
