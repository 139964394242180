import Model from './Model';

class TagModel extends Model {
    static attributes = {
        id: 'id',
        name: 'name',
        slug: 'slug',
    };
}

export default TagModel;
