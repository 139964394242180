import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Button from '@/components/Button';
import AISettingsFormInput from './AISettingsFormInput';
import useAISettingsFormSchema, { AISettingsSchema } from './useAISettingsSchema.hook';

interface AISettingsFormProps {
    onSubmit: (data: any) => void;
    isLoading: boolean;
    defaultValues: AISettingsSchema;
}

const AISettingsForm = ({ onSubmit, isLoading, defaultValues }: AISettingsFormProps) => {
    const schema = useAISettingsFormSchema();
    const { handleSubmit, control, formState } = useForm<AISettingsSchema>({
        mode: 'onBlur',
        values: defaultValues,
        resolver: zodResolver(schema),
    });

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <AISettingsFormInput name="title" control={control} />
            <AISettingsFormInput name="description" control={control} />
            <AISettingsFormInput name="tags" control={control} />
            <AISettingsFormInput name="newsletter" control={control} />
            <AISettingsFormInput name="smm" control={control} />
            <SubmitButton
                type="submit"
                isLoading={isLoading}
                isDisabled={!formState.isDirty || !formState.isValid}
            >
                <FormattedMessage defaultMessage="Enregistrer" />
            </SubmitButton>
        </Form>
    );
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
`;
const SubmitButton = styled(Button)`
    width: fit-content;
`;

export default AISettingsForm;
