import NewsletterSettings from '@app/organisms/NewsletterSettings';
import NewsletterPreview from '@app/molecules/NewsletterPreview';
import styled from 'styled-components';
import { useAmplitudeLogEvent } from '@hooks/useAmplitude';

const CommunicationNewsletterSettings = () => {
    useAmplitudeLogEvent('Newsletter Tab Viewed');
    return (
        <CommunicationNewsletterSettingsWrapper>
            <NewsletterSettings />
            <NewsletterPreview />
        </CommunicationNewsletterSettingsWrapper>
    );
};

const CommunicationNewsletterSettingsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

export default CommunicationNewsletterSettings;
