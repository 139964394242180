import styled from 'styled-components';
import { reflex } from '@ui/reflex';
import { resolveColor } from '@/shared/utils/cssVariable';

const PaperGrid = styled.div`
    display: grid;
    grid-template-columns: ${(p) =>
        p.idealCellWidth && !p.customColumn
            ? `repeat(auto-fit, minmax(${p.idealCellWidth}, 1fr))`
            : `repeat(${p.column}, 1fr)`};
    grid-template-columns: ${(p) => p.customColumn};
    grid-gap: ${(p) => p.gap}px;
    row-gap: ${(p) => p.rowGap && `${p.rowGap}px`};
    border: solid ${(p) => resolveColor(p.border)};
    border-top-width: ${(p) => p.bt || p.bY}px;
    border-right-width: ${(p) => p.br || p.bX}px;
    border-bottom-width: ${(p) => p.bb || p.bY}px;
    border-left-width: ${(p) => p.bl || p.bX}px;
    cursor: ${(p) => p.cursor && 'pointer'};
`;

PaperGrid.defaultProps = {
    column: 3,
    background: 'white',
    rounded: 0,
    border: undefined,
    cursor: undefined,
    gap: 0,
    rowGap: undefined,
    bt: 0,
    br: 0,
    bb: 0,
    bl: 0,
    bY: 0,
    bX: 0,
};

export default reflex(PaperGrid);
