import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchClipTranscriptionProgress } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import clipKeys from './clipKeys';
import { z } from 'zod';

interface Job {
    status: 'finished' | 'failed' | 'executing' | 'queued';
    progress_percentage: number;
    result: unknown | null;
}
interface Jobs {
    transcriptpodcastclip: Job;
}

export const ClipTranscriptionJobSchema = z.object({
    progressPercentage: z.number(),
    result: z.any().nullable(),
    status: z.enum(['finished', 'failed', 'executing', 'queued']),
    isFinished: z.boolean(),
    isFailed: z.boolean(),
    isExecuting: z.boolean(),
    isQueued: z.boolean(),
});
export type ClipTranscriptionJob = z.infer<typeof ClipTranscriptionJobSchema>;
type Key = QueryFunctionContext<ReturnType<typeof clipKeys.transcriptionProgressByClipId>>;

const queryFn = async ({ queryKey: [{ clipId }] }: Key) => {
    const { data } = await fetchClipTranscriptionProgress(clipId);
    return data;
};

interface InputProps {
    clipId: string | number;
    enabled?: boolean;
}

const useClipTranscriptionProgressQuery = ({ clipId, enabled = false }: InputProps) => {
    return useQuery({
        queryKey: clipKeys.transcriptionProgressByClipId(clipId),
        queryFn,
        select: (data: Jobs) => {
            const { transcriptpodcastclip } = data;
            const formattedData = {
                isFinished: transcriptpodcastclip.status === 'finished',
                isFailed: transcriptpodcastclip.status === 'failed',
                isExecuting: transcriptpodcastclip.status === 'executing',
                isQueued: transcriptpodcastclip.status === 'queued',
                ...transcriptpodcastclip,
            };
            return ClipTranscriptionJobSchema.parse(camelcaseKeys(formattedData, { deep: true }));
        },
        refetchInterval: (data) => {
            if (data?.isFinished || data?.isFailed) {
                return false;
            }

            return 2000;
        },
        enabled,
    });
};

export default useClipTranscriptionProgressQuery;
