import { Redirect, Switch, useRouteMatch } from 'react-router-dom';
import EpisodeAudioFileUploadPage from '@app/pages/EpisodeAudioFileUploadPage';
import EpisodeEditFormPage from '@app/pages/EpisodeEditFormPage/EpisodeEditFormPage';
import { EpisodeEditContext } from '@/context/EpisodeEditContext';
import { USER_ROLES } from '@/utils/constants';
import NoPermissionRoute from '../atoms/NoPermissionRoute';
import { STATUS_SHOWS } from '@/utils/statusShows';

const EpisodeEditPage = () => {
    const { path } = useRouteMatch();

    return (
        <EpisodeEditContext>
            <Switch>
                <NoPermissionRoute
                    roles={[USER_ROLES.VIEWER]}
                    showStatus={[STATUS_SHOWS.ARCHIVED_SHOWS]}
                    path={`${path}/upload`}
                >
                    <EpisodeAudioFileUploadPage />
                </NoPermissionRoute>
                <NoPermissionRoute
                    roles={[USER_ROLES.VIEWER]}
                    showStatus={[STATUS_SHOWS.ARCHIVED_SHOWS]}
                    path={`${path}/:episodeId(\\d+)`}
                >
                    <EpisodeEditFormPage />
                </NoPermissionRoute>
                <Redirect to="/app/show/:showId/episodes" />
            </Switch>
        </EpisodeEditContext>
    );
};

export default EpisodeEditPage;
