import IconButton from '@/components/IconButton';
import Text from '@/components/ui/atoms/Text';
import { Playlist } from '@/queries/playlist/usePlaylistsQuery.hook';
import { IMG_PLACEHOLDER } from '@/shared/config/constants';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MenuTrigger, Popover } from 'react-aria-components';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import PlaylistListItemMenu from '../PlaylistListItemMenu';

interface MobileSelectedPlaylistProps {
    playlist: Playlist;
    onEdit: (playlist: Playlist) => void;
    onDelete: (playlist: Playlist) => void;
    onOpenDropdown: () => void;
}

const MobileSelectedPlaylist = ({
    playlist,
    onEdit,
    onDelete,
    onOpenDropdown,
}: MobileSelectedPlaylistProps) => {
    return (
        <Container>
            <TriggerButton onClick={onOpenDropdown}>
                <Image src={playlist.imageUrl ?? IMG_PLACEHOLDER} alt={playlist.name} />
                <Info>
                    <Title>{playlist.name}</Title>
                    <Text>
                        <FormattedMessage
                            defaultMessage="{count, plural, =0 {Aucun épisode} one {# épisode} other {# épisodes}}"
                            values={{ count: playlist.podcastsCount }}
                        />
                    </Text>
                </Info>
            </TriggerButton>
            <MenuTrigger>
                <MenuTriggerButton
                    variant="ghost"
                    aria-label="Playlist episode menu trigger"
                    icon={<EllipsisIcon icon={icon({ name: 'ellipsis', style: 'solid' })} />}
                />
                <Popover placement="bottom right">
                    <PlaylistListItemMenu playlist={playlist} onEdit={onEdit} onDelete={onDelete} />
                </Popover>
            </MenuTrigger>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    column-gap: 0.5rem;
    background-color: var(--white);
    cursor: pointer;
    padding-block: 0.75rem;
    padding-inline: 1rem;
    transition-duration: 0.2s;
    border-radius: var(--r-m);

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: none;
    }
`;
const Image = styled.img`
    object-fit: cover;
    height: 3rem;
    width: 3rem;
    border-radius: var(--r-s);
`;
const Info = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 0;
    flex-grow: 1;
    text-align: left;
`;
const Title = styled(Text)`
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: var(--fw-semibold);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
`;
const MenuTriggerButton = styled(IconButton)`
    flex-shrink: 0;
    padding: 0;
    align-self: center;
`;
const EllipsisIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--neutral500);
`;
const TriggerButton = styled.button`
    background: none;
    border: none;
    padding: 0;
    display: flex;
    column-gap: 0.5rem;
    min-width: 0;
    flex-grow: 1;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: none;
    }
`;

export default MobileSelectedPlaylist;
