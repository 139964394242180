import { FormattedMessage } from 'react-intl';
import Telescope from '@public/images/empty_search.svg';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import DeprecatedText from '@ui/atoms/DeprecatedText';

function NoResultCampaigns({ search }) {
    return (
        <DeprecatedPaper
            border="--neutral100"
            height={260}
            bt={1}
            align="center"
            justify="center"
            flex
            column
        >
            <Telescope width={95} />
            <DeprecatedText size="big" mt={15}>
                <FormattedMessage defaultMessage="Aucun résultat trouvé pour" />
            </DeprecatedText>
            <DeprecatedText size="big" weight="semibold" mt={5}>
                « {search} »
            </DeprecatedText>
        </DeprecatedPaper>
    );
}

export default NoResultCampaigns;
