import { FormattedMessage, useIntl } from 'react-intl';
import { useResponsive } from '@/shared/hooks/useResponsive';
import Button from '@ui/atoms/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import type { Table, PaginationState } from '@tanstack/react-table';
import type { GeolocalizationRecord } from './IpGeolocalizationTable';
import styled, { css } from 'styled-components';

export interface PaginationProps {
    table: Table<GeolocalizationRecord>;
    pagination: PaginationState;
    options?: {
        goToFirstAndLastPageButtons?: boolean;
        showPaginationInfo?: boolean;
        showPageSelector?: boolean;
    };
    onExport: () => void;
}

const Pagination = ({
    table,
    pagination,
    options = {
        goToFirstAndLastPageButtons: false,
        showPaginationInfo: true,
        showPageSelector: true,
    },
    onExport,
}: PaginationProps) => {
    const intl = useIntl();
    const { isMobileOrTablet } = useResponsive();

    const pageNumbers = (current: number) => {
        const total = table.getPageCount();
        let currentPage = current > total - 1 ? total - 1 : current < 0 ? 0 : current;
        let result = [];
        const pageShown = 7;
        const shownOnEitherSide = Math.floor(pageShown / 2);
        result.push(currentPage);
        for (let i = 1; i < shownOnEitherSide + 1; i++) {
            currentPage - i >= 0 && result.push(currentPage - i);
            currentPage + i < total && result.push(currentPage + i);
        }
        return result.sort((a, b) => a - b);
    };

    return (
        <PaginationWrapper>
            <Pages>
                {options.showPaginationInfo && (
                    <PageInfo>
                        <FormattedMessage
                            defaultMessage="Page <strong>{currentPage} sur {totalPages}</strong> <totalLinesContainer>({totalLines, plural, one {# ligne} other {# lignes}})</totalLinesContainer>"
                            values={{
                                strong: (chunks: string) => <strong>{chunks}</strong>,
                                totalLinesContainer: (chunks: string) => (
                                    <TotalLines>{chunks}</TotalLines>
                                ),
                                currentPage: table.getState().pagination.pageIndex + 1,
                                totalPages: table.getPageCount(),
                                totalLines: table.getRowModel().rows.length,
                            }}
                        />
                    </PageInfo>
                )}
                {options.showPageSelector && (
                    <PageNbSelector
                        value={table.getState().pagination.pageSize}
                        onChange={(e) => {
                            table.setPageSize(Number(e.target.value));
                        }}
                    >
                        {[15, 30, 40, 50].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                {intl.formatMessage({ defaultMessage: 'Montrer' })} {pageSize}
                            </option>
                        ))}
                    </PageNbSelector>
                )}
            </Pages>
            <PaginationButtonsWrapper>
                {options?.goToFirstAndLastPageButtons && (
                    <PaginationButton
                        onClick={() => table.setPageIndex(0)}
                        disabled={!table.getCanPreviousPage()}
                    >
                        {isMobileOrTablet ? (
                            <FormattedMessage defaultMessage="Première" />
                        ) : (
                            <FormattedMessage defaultMessage="Première page" />
                        )}
                    </PaginationButton>
                )}
                <ArrowPaginationButton
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                >
                    <FontAwesomeIcon icon={icon({ name: 'chevron-left', style: 'solid' })} />
                </ArrowPaginationButton>
                {pageNumbers(pagination.pageIndex).map((item) => (
                    <PaginationButton
                        $active={pagination.pageIndex === item}
                        onClick={() => table.setPageIndex(item)}
                        key={item}
                    >
                        {item + 1}
                    </PaginationButton>
                ))}
                <ArrowPaginationButton
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                >
                    <FontAwesomeIcon icon={icon({ name: 'chevron-right', style: 'solid' })} />
                </ArrowPaginationButton>
                {options?.goToFirstAndLastPageButtons && (
                    <PaginationButton
                        onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                        disabled={!table.getCanNextPage()}
                    >
                        {isMobileOrTablet ? (
                            <FormattedMessage defaultMessage="Dernière" />
                        ) : (
                            <FormattedMessage defaultMessage="Dernière page" />
                        )}
                    </PaginationButton>
                )}
            </PaginationButtonsWrapper>
            <ExportWrapper>
                {/* @ts-ignore */}
                <ExportButton
                    variant="ghost"
                    onPress={onExport}
                    startIcon={
                        <FontAwesomeIcon
                            icon={icon({ name: 'arrow-up-right-from-square', style: 'solid' })}
                        />
                    }
                >
                    <FormattedMessage defaultMessage="Exporter en .csv" />
                </ExportButton>
            </ExportWrapper>
        </PaginationWrapper>
    );
};

const PaginationWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    & > * {
        flex-basis: 50%;
        flex-grow: 1;
        flex-shrink: 0;
    }

    ${(props) => props.theme.mediaQueries.mobile} {
        row-gap: 1rem;
        & > * {
            justify-content: center;
        }
    }
`;
const Pages = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
`;
const PageInfo = styled.span``;
const PageNbSelector = styled.select`
    background: none;
    border-radius: var(--r-xs);
    border: 1px solid var(--neutral500);
    color: var(--neutral500);
    padding-inline: 0.5rem;
`;
const TotalLines = styled.span`
    color: var(--neutral500);
`;
const PaginationButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 0.25rem;
    width: 100%;

    & > * {
        flex-grow: 0;
        flex-shrink: 0;
    }

    ${(props) => props.theme.mediaQueries.mobile} {
        justify-content: center;
    }
`;
const PaginationButton = styled.button<{ $active?: boolean }>`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.5rem;
    min-width: 1.5rem;
    background: var(--white);
    color: var(--neutral500);
    border: none;
    border-radius: var(--r-xs);
    font-weight: var(--fw-semibold);

    ${(props) =>
        props.$active &&
        css`
            background: var(--primary);
            color: var(--white);
        `}

    &:hover {
        opacity: 0.7;
    }
`;
const ArrowPaginationButton = styled(PaginationButton)`
    transition-duration: 0.2s;
    background: none;
    color: var(--neutral500);

    &:hover {
        background: var(--neutral100);
        color: var(--black);
    }
`;
const ExportWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;
const ExportButton = styled(Button)`
    color: var(--primary);
    &:hover {
        text-decoration: underline;
    }
`;

export default Pagination;
