import { createFetcher } from '../Fetcher';

export default createFetcher(
    ({ listenersStatsStore }, { showId, itemType, itemId, start, end, episodes }) => ({
        fetch: () =>
            listenersStatsStore.fetchEpisodesTopRanking(showId, itemType, itemId, {
                start,
                end,
                podcasts: episodes,
            }),
        isLoading: listenersStatsStore.fetchEpisodesTopRanking.pending,
        input: [showId, itemType, itemId, start, end, episodes],
    }),
);
