import * as React from 'react';
import { useAuthContext } from '@/context/AuthContext';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import { IntercomProvider as ReactUseIntercomProvider } from 'react-use-intercom';

interface IntercomProviderProps {
    children: React.ReactNode;
}

export const IntercomProvider = ({ children }: IntercomProviderProps) => {
    const { isAuthenticatedAs } = useAuthContext();
    const { data: user } = useUserQuery({ enabled: !isAuthenticatedAs });
    const locale = user?.language?.toLowerCase();
    const APP_ID = locale === 'fr' ? process.env.INTERCOM_TOKEN_FR : process.env.INTERCOM_TOKEN_EN;

    return <ReactUseIntercomProvider appId={APP_ID}>{children}</ReactUseIntercomProvider>;
};
