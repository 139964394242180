import { formatDateForApiUTC, formatDateForApi } from '@/helpers';

export const nullable = (key) => ({
    fromApi: {
        key,
        resolve: (x) => x || null,
    },
});

export const initial = (key, init) => ({
    fromApi: {
        key,
        resolve: (x, store) => {
            if (x) return x;
            if (typeof init === 'function') return init(store.state);
            return init;
        },
    },
});

export const dateUTC = (key) => ({
    fromApi: {
        key,
        resolve: (x) => (x ? new Date(x) : null),
    },
    toApi: {
        key,
        resolve: (x) => (!x ? x : formatDateForApiUTC(x)),
    },
});

export const date = (key) => ({
    fromApi: {
        key,
        resolve: (x) => (x ? new Date(x) : null),
    },
    toApi: {
        key,
        resolve: (x) => (!x ? x : formatDateForApi(x)),
    },
});

export const array = (fromKey, Model, modelStore) => ({
    fromApi: {
        key: fromKey,
        resolve: (xs, store) =>
            xs.data.map((x) => new Model(modelStore ? store.state[modelStore] : store, x)),
    },
});

export const first = (key, Model, modelStore) => ({
    fromApi: {
        key,
        resolve(xs, store) {
            if (!xs || !xs.data || xs.data.length === 0) {
                return null;
            }
            const st = modelStore ? store.state[modelStore] : store;
            return new Model(st, xs.data[0]);
        },
    },
});

export const variation = (key, Model, propertyName) => ({
    fromApi: {
        key,
        resolve(apiData, store) {
            const data =
                typeof apiData === 'number' ? { [propertyName]: apiData, variation: 0 } : apiData;
            return new Model(store, data);
        },
    },
});

export const nested = (key, nestedKey) => ({
    fromApi: {
        key,
        resolve: (x) => (x ? x[nestedKey] : null),
    },
    toApi: {
        key: [key, nestedKey].join('.'),
        resolve: (x) => x,
    },
});

export const noDataReplaceBy = (key, replaceBy) => ({
    fromApi: {
        key,
        resolve: (data) => data || replaceBy,
    },
});
