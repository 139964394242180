import { useEffect, useState } from 'react';
import { useWindowPopup } from './useWindowPopup';
import AuthStore from '@/stores/AuthStore';

export function useLinkLoginProviderWithPopup(provider, login) {
    const [url, setUrl] = useState(null);
    const [win, onOpen] = useWindowPopup(url, login);
    useEffect(() => {
        AuthStore.findProviderUrl(provider).then(setUrl);
    }, []);

    return [!!win, onOpen];
}
