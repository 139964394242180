import {
    Label as RACLabel,
    Text as RACText,
    LabelProps as RACLabelProps,
} from 'react-aria-components';
import styled, { css } from 'styled-components';
import Text from '../Text';
import { FormattedMessage } from 'react-intl';

export interface LabelProps extends RACLabelProps {
    isRequired?: boolean;
    isOptional?: boolean;
    description?: string;
    tooltip?: React.ReactNode;
}

const Label = ({ children, isOptional, description, tooltip, className, ...props }: LabelProps) => {
    if (!children) return null;
    const { isRequired, ...rest } = props;

    return (
        <LabelWrapper className={className}>
            <LabelTooltipWrapper>
                <LabelStyled {...rest} $isRequired={props.isRequired}>
                    {children}
                    {props.isRequired && (
                        <Text as="span" fontWeight="--fw-semibold" color="--alert500">
                            *
                        </Text>
                    )}
                    {isOptional && (
                        <OptionalLabel as="span">
                            <FormattedMessage defaultMessage="(facultatif)" />
                        </OptionalLabel>
                    )}
                </LabelStyled>
                {tooltip}
            </LabelTooltipWrapper>
            {description && <Description slot="description">{description}</Description>}
        </LabelWrapper>
    );
};

const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const LabelTooltipWrapper = styled.div`
    display: flex;
    column-gap: 0.25rem;
    align-items: center;
    width: 100%;
`;
const LabelStyled = styled(RACLabel)<{ $isRequired?: boolean }>`
    font-size: var(--fs-body-m);
    font-weight: var(--fw-semibold);

    ${(p) =>
        p.$isRequired &&
        css`
            display: flex;
            justify-content: start;
            align-items: start;
            column-gap: 0.25rem;
        `}
`;
const OptionalLabel = styled(Text)`
    padding-left: 0.25rem;
`;
const Description = styled(RACText)`
    font-size: 12px;
    color: var(--neutral500);
`;

export default Label;
