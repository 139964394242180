import {
    ListeningPlatform,
    SPOTIFY,
    DEEZER,
    PODCAST_ADDICT,
    POCKET_CASTS,
    CASTBOX,
    OVERCAST,
    CASTRO,
    YOUTUBE,
    SOUNDCLOUD,
    AMAZON_MUSIC,
    PODCHASER,
    LISTEN_NOTES,
    PODCAST_INDEX,
    APPLE_PODCASTS,
} from '@/shared/config/constants';

const platformsWithoutTutorial: ListeningPlatform[] = [
    SPOTIFY,
    DEEZER,
    PODCAST_ADDICT,
    POCKET_CASTS,
    CASTBOX,
    OVERCAST,
    CASTRO,
    YOUTUBE,
    SOUNDCLOUD,
    AMAZON_MUSIC,
    PODCHASER,
    LISTEN_NOTES,
    PODCAST_INDEX,
    APPLE_PODCASTS,
];

const useHasTutorial = (platform: ListeningPlatform) => {
    return !platformsWithoutTutorial.includes(platform);
};

export default useHasTutorial;
