import { useState, createContext, useContext } from 'react';

// Step 1: Create the context
const EditedPostContext = createContext();

// Step 2: Create a Context Provider Component
const EditedPostProvider = ({ children }) => {
    const [editedPost, setEditedPost] = useState(null);

    return (
        <EditedPostContext.Provider value={{ editedPost, setEditedPost }}>
            {children}
        </EditedPostContext.Provider>
    );
};

// Step 3: Create a custom hook to consume the context
const useEditedPostContext = () => {
    const context = useContext(EditedPostContext);
    if (context === undefined) {
        throw new Error('useEditedPostContext must be used within an EditedPostProvider');
    }
    return context;
};

// Export the Provider and the custom hook
export { EditedPostProvider, useEditedPostContext };
