export const TIME_UNIT = {
    HOUR: 'hours',
    MINUTE: 'minutes',
    SECOND: 'seconds',
    MILLISECOND: 'milliseconds',
};

const DEFAULT_MIN_HOUR = 0;
const DEFAULT_MIN_MINUTE = 0;
const DEFAULT_MIN_SECOND = 0;
const DEFAULT_MIN_MILLISECOND = 0;

const DEFAULT_MAX_HOUR = 23;
const DEFAULT_MAX_MINUTE = 59;
const DEFAULT_MAX_SECOND = 59;
const DEFAULT_MAX_MILLISECOND = 999;

const TIME_UNIT_METADATA = {
    [TIME_UNIT.HOUR]: {
        max: DEFAULT_MAX_HOUR,
        min: DEFAULT_MIN_HOUR,
    },
    [TIME_UNIT.MINUTE]: {
        max: DEFAULT_MAX_MINUTE,
        min: DEFAULT_MIN_MINUTE,
    },
    [TIME_UNIT.SECOND]: {
        max: DEFAULT_MAX_SECOND,
        min: DEFAULT_MIN_SECOND,
    },
    [TIME_UNIT.MILLISECOND]: {
        max: DEFAULT_MAX_MILLISECOND,
        min: DEFAULT_MIN_MILLISECOND,
    },
};

export const getTimeUnitMax = (unit) => TIME_UNIT_METADATA[unit].max;

export const getTimeUnitMin = (unit) => TIME_UNIT_METADATA[unit].min;

export const isTimeUnitValid = (unit, time) => {
    return (
        time != null &&
        !Number.isNaN(time) &&
        getTimeUnitMin(unit) <= time &&
        time <= getTimeUnitMax(unit)
    );
};

export function wrapTimeAtUnit(unit, time) {
    const max = getTimeUnitMax(unit);
    const min = getTimeUnitMin(unit);

    if (time > max) {
        return max;
    }
    if (time < min) {
        return min;
    }
    return time;
}
