import { z } from 'zod';
import { useIntl } from 'react-intl';
import { FORM_VALIDATION_MESSAGE } from '@/shared/services/validationMessageService';

const useClipTranscriptionSelectionSchema = () => {
    const intl = useIntl();

    return z.object({
        lang: z.string().min(1, intl.formatMessage(FORM_VALIDATION_MESSAGE.required)),
        transcript: z.boolean(),
    });
};

export type ClipTranscriptionSelectionSchema = z.infer<
    ReturnType<typeof useClipTranscriptionSelectionSchema>
>;

export default useClipTranscriptionSelectionSchema;
