import { useFormContext } from 'react-hook-form';
import EpisodeChaptersSelectorList from './EpisodeChaptersSelectorList';

const EpisodeEditFormChaptersList = ({ selectedChapterId, onSelectChapter, onDeleteChapter }) => {
    const { watch } = useFormContext();

    const chapters = watch('chapters', []);
    const sortedChapters = chapters.toSorted(
        (chapter1, chapter2) => chapter1.startTime - chapter2.startTime,
    );

    return (
        <EpisodeChaptersSelectorList
            chapters={sortedChapters}
            selectedChapterId={selectedChapterId}
            onSelectChapter={onSelectChapter}
            onDeleteChapter={onDeleteChapter}
        />
    );
};

export default EpisodeEditFormChaptersList;
