import { useEffect } from 'react';

function DiscordPage() {
    useEffect(() => {
        window.location = process.env.DISCORD_INVITE;
    }, []);
    return null;
}

export default DiscordPage;
