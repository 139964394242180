import PropTypes from 'prop-types';
import styled from 'styled-components';

const CircularProgress = ({
    size,
    strokeWidth,
    percentage,
    backgroundColor,
    progressColor,
    children,
    className,
    radius: radiusProp,
}) => {
    const percentageNormalized = percentage < 0 ? 0 : percentage > 100 ? 100 : percentage;
    const radius = radiusProp ?? size / 2 - strokeWidth;
    const dashArray = radius * Math.PI * 2;
    const dashOffset = dashArray - dashArray * Math.max(0.01, percentageNormalized / 100);

    return (
        <CircularProgressWrapper size={size}>
            <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} className={className}>
                <CircleBackground
                    cx={size / 2}
                    cy={size / 2}
                    r={radius}
                    strokeWidth={`${strokeWidth}px`}
                    backgroundColor={backgroundColor}
                />
                <CircleProgress
                    cx={size / 2}
                    cy={size / 2}
                    r={radius}
                    size={size}
                    strokeWidth={`${strokeWidth}px`}
                    dashArray={dashArray}
                    dashOffset={dashOffset}
                    transform={`rotate(-90 ${size / 2} ${size / 2})`}
                    progressColor={progressColor}
                />
            </svg>
            <CentralContent>{children}</CentralContent>
        </CircularProgressWrapper>
    );
};

CircularProgress.propTypes = {
    size: PropTypes.number,
    strokeWidth: PropTypes.number,
    percentage: PropTypes.number,
    backgroundColor: PropTypes.string,
    progressColor: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    radius: PropTypes.number,
};

CircularProgress.defaultProps = {
    size: 200,
    strokeWidth: 10,
    percentage: 75,
    backgroundColor: '--neutral100',
    progressColor: '--primary',
};

const CircularProgressWrapper = styled.div`
    position: relative;
    flex-shrink: 0;
    flex-grow: 0;
    width: ${(p) => p.size}px;
    height: ${(p) => p.size}px;

    & > * {
        position: absolute;
        top: 0;
        left: 0;
    }
`;
const CircleBackground = styled.circle`
    fill: none;
    stroke: ${(p) => `var(${p.backgroundColor})`};
`;
const CircleProgress = styled.circle`
    transition-duration: 0.4s;
    transition-timing-function: var(--spring-easing);
    fill: none;
    stroke: ${(p) => `var(${p.progressColor})`};
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: ${(props) => props.dashArray};
    stroke-dashoffset: ${(props) => props.dashOffset};
`;
const CentralContent = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default CircularProgress;
