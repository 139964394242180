import { FORM_VALIDATION_MESSAGE } from '@/utils/validationMessage';
import { useIntl } from 'react-intl';
import { z } from 'zod';

const useBroadcastSettingsFormSchema = () => {
    const intl = useIntl();

    return z.object({
        link: z.string().url(intl.formatMessage(FORM_VALIDATION_MESSAGE.isUrl)),
    });
};

export type BroadcastSettingsSchema = z.infer<ReturnType<typeof useBroadcastSettingsFormSchema>>;

export default useBroadcastSettingsFormSchema;
