import Button from '@/components/Button';
import RouterLink from '@/components/Link/RouterLink';
import Modal from '@ui/atoms/Modal';
import Text from '@ui/atoms/Text';
import Stack from '@ui/layout/Stack';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import useHasTutorial from '@/shared/hooks/useHasTutorial.hook';
import { AMAZON_MUSIC, ListeningPlatform } from '@/shared/config/constants';
import AmazonCustomConfirmModal from './AmazonCustomConfirmModal';
import useBroadcastMutation from '@queries/show/useBroadcastMutation.hook';
import styled from 'styled-components';

interface ConfirmModalProps {
    platformKey: ListeningPlatform;
    platformName: string;
    isOpen: boolean;
    onOpenChange: (isOpen: boolean) => void;
    logo: string;
}

const ConfirmModal = ({
    platformKey,
    platformName,
    isOpen,
    onOpenChange,
    logo,
}: ConfirmModalProps) => {
    const { showId } = useParams<{ showId: string }>();
    const platformHasTutorial = useHasTutorial(platformKey);
    const broadcast = useBroadcastMutation();

    /**
     * Amazon Music has a custom broadcast logic.
     * We need to display a different modal for this platform specifically.
     */
    if (platformKey === AMAZON_MUSIC) {
        return <AmazonCustomConfirmModal logo={logo} isOpen={isOpen} onOpenChange={onOpenChange} />;
    }

    return (
        <ModalStyled isOpen={isOpen} onOpenChange={onOpenChange} size="small">
            <LogoWrapper $color={`--${platformKey}`}>
                <Logo src={logo} />
            </LogoWrapper>
            <Stack $gap="0.25rem">
                <Text variant="headingS" fontWeight="--fw-bold" textAlign="center">
                    <FormattedMessage
                        defaultMessage="Souhaitez-vous diffuser sur {broadcastName} ?"
                        values={{ broadcastName: platformName }}
                    />
                </Text>
                <Stack $gap="0.25rem">
                    <Text color="--neutral500" textAlign="center">
                        {platformHasTutorial ? (
                            <FormattedMessage
                                defaultMessage="Pour diffuser votre émission sur {broadcastName}, suivez simplement le tutoriel que nous vous avons préparé."
                                values={{
                                    broadcastName: platformName,
                                }}
                            />
                        ) : (
                            <FormattedMessage defaultMessage="Vous apparaîtrez sur la plateforme d’ici quelques heures." />
                        )}
                    </Text>
                    <Text color="--neutral500" textAlign="center">
                        <FormattedMessage
                            defaultMessage="Votre podcast est déjà sur {broadcastName} ? {action}"
                            values={{
                                broadcastName: platformName,
                                action: (
                                    <QuickBroadcastLink
                                        to={`/app/show/${showId}/settings/broadcast/platform/${platformKey}`}
                                        $color={`--${platformKey}`}
                                    >
                                        <FormattedMessage defaultMessage="Cliquez-ici" />
                                    </QuickBroadcastLink>
                                ),
                            }}
                        />
                    </Text>
                </Stack>
            </Stack>

            <Actions>
                {platformHasTutorial ? (
                    <BroadcastLink
                        to={`/app/show/${showId}/settings/broadcast/platform/${platformKey}?startWithTutorial=true`}
                        variant="button-primary"
                        $color={`--${platformKey}`}
                    >
                        <FormattedMessage defaultMessage="Commencer le tutoriel" />
                    </BroadcastLink>
                ) : (
                    <BroadcastButton
                        onPress={() => {
                            broadcast.mutate({
                                showId,
                                platform: platformKey,
                            });
                        }}
                        $color={`--${platformKey}`}
                        isLoading={broadcast.isLoading}
                    >
                        <FormattedMessage
                            defaultMessage="Diffuser sur {broadcastName}"
                            values={{ broadcastName: platformName }}
                        />
                    </BroadcastButton>
                )}

                <Button variant="link-secondary" onPress={() => onOpenChange(false)}>
                    <FormattedMessage defaultMessage="Annuler" />
                </Button>
            </Actions>
        </ModalStyled>
    );
};

const ModalStyled = styled(Modal)`
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1.5rem;
`;
const Logo = styled.img`
    width: 1rem;
    height: 1rem;
`;
const LogoWrapper = styled.div<{ $color: string }>`
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(${(props) => props.$color}50);
    color: var(${(props) => props.$color});
    border-radius: var(--r-s);
`;
const Actions = styled.div`
    align-self: stretch;
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 1rem;

    & > :first-child {
        align-self: stretch;
    }
`;
const QuickBroadcastLink = styled(RouterLink)<{ $color: string }>`
    color: var(${(props) => props.$color});
`;
const BroadcastLink = styled(RouterLink)<{ $color: string }>`
    background-color: var(${(props) => props.$color}50);
    color: var(${(props) => props.$color});
    box-shadow: none;

    &:hover {
        background-color: var(${(props) => props.$color}100);
    }
`;
const BroadcastButton = styled(Button)<{ $color: string }>`
    background-color: var(${(props) => props.$color}50);
    color: var(${(props) => props.$color});
    box-shadow: none;

    &:hover {
        background-color: var(${(props) => props.$color}100);
    }
`;

export default ConfirmModal;
