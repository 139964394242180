import ShortListFallback from '@/components/unorganized/ShortListFallback';
import BroadcastCard from '../../BroadcastCard';
import podchaser from '@public/images/platforms/Podchaser.png';
import { LISTENING_PLATFORMS_NAMES, PODCHASER } from '@/shared/config/constants';
import { useParams } from 'react-router-dom';
import useShowQuery from '@queries/show/useShowQuery.hook';

const PodchaserBroadcast = () => {
    const { showId } = useParams<{ showId: string }>();
    const { data: show, isLoading } = useShowQuery(showId);
    const url = show?.listeningLinks?.data.find((link) => link.key === PODCHASER)?.url;

    if (isLoading) return <ShortListFallback />;

    return (
        <BroadcastCard
            platformKey={PODCHASER}
            name={LISTENING_PLATFORMS_NAMES[PODCHASER]}
            logo={podchaser}
            url={url}
        />
    );
};

export default PodchaserBroadcast;
