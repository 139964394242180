import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Button from '@ui/atoms/Button';
import Text from '@ui/atoms/Text';
import StripeBadge from '@public/images/stripe-badge.svg?url';
import ChargebeeBadge from '@public/images/chargebee-badge.svg?url';
import useSubscriptionQuery from '@queries/subscription/useSubscriptionQuery.hook';
import { PRICING } from '@/shared/config/pricing';
import { getStripeCustomerPortalSession } from '@/api';
import { useModalToastQueue } from '@/shared/hooks/useModalToastQueue.hook';
import styled from 'styled-components';

const BILLING_PROVIDERS = {
    STRIPE: 'stripe',
    CHARGEBEE: 'chargebee',
};

const Payment = () => {
    const toast = useModalToastQueue();
    const { data } = useSubscriptionQuery();
    const provider =
        data?.pricing === PRICING.ENTERPRISE
            ? BILLING_PROVIDERS.CHARGEBEE
            : BILLING_PROVIDERS.STRIPE;

    const createStripePortalSession = async () => {
        try {
            const stripeCustomerPortalSession = await getStripeCustomerPortalSession();
            const { url } = stripeCustomerPortalSession;
            window.location.href = url;
        } catch (error) {
            toast.alert(
                <FormattedMessage defaultMessage="Une erreur inattendue est survenue. Merci de réessayer plus tard." />,
            );
        }
    };

    return (
        <PaymentWrapper>
            <MainContent>
                <BoxTitleWrapper>
                    <Card icon={icon({ name: 'credit-card', style: 'solid' })} />
                    <BoxTitle>
                        <FormattedMessage defaultMessage="Paiement et facturation" />
                    </BoxTitle>
                </BoxTitleWrapper>
                <TextContent>
                    <FormattedMessage defaultMessage="Retrouvez l'historique de vos factures, modifiez vos informations de facturation (adresse, numéro de TVA, etc) et mettez à jour votre moyen de paiement depuis votre espace de paiement Stripe" />
                </TextContent>
            </MainContent>
            <ActionWrapper>
                {provider === BILLING_PROVIDERS.CHARGEBEE ? (
                    // @ts-ignore
                    <Button
                        as="a"
                        href="https://ausha.chargebeeportal.com/portal/v2/login?forward=portal_main"
                        target="_blank"
                        endIcon={
                            <FontAwesomeIcon
                                icon={icon({ name: 'arrow-up-right', style: 'solid' })}
                            />
                        }
                    >
                        <FormattedMessage defaultMessage="Mon espace de facturation" />
                    </Button>
                ) : (
                    // @ts-ignore
                    <Button
                        onPress={createStripePortalSession}
                        endIcon={
                            <FontAwesomeIcon
                                icon={icon({ name: 'arrow-up-right', style: 'solid' })}
                            />
                        }
                    >
                        <FormattedMessage defaultMessage="Mon espace de facturation" />
                    </Button>
                )}
                <img
                    src={provider === BILLING_PROVIDERS.CHARGEBEE ? ChargebeeBadge : StripeBadge}
                />
            </ActionWrapper>
        </PaymentWrapper>
    );
};

const PaymentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3.5rem;
    background-color: var(--white);
    border-radius: var(--r-l);
    min-height: 15rem;
    padding: 1.5rem;

    @media (max-width: 1134px) {
        gap: 1.5rem;
    }
`;
const MainContent = styled.div`
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
`;
const BoxTitleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;
const Card = styled(FontAwesomeIcon)`
    font-size: 1rem;
    color: var(--primary);
`;
const BoxTitle = styled.div`
    font-weight: var(--fw-semibold);
`;
const TextContent = styled(Text)`
    color: var(--neutral500);
`;
const ActionWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1134px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
    }
`;

export default Payment;
