import useCallToActionButtonsQuery from '@/queries/callToAction/useCallToActionButtonsQuery.hook';
import useShowNewsletterTemplateQuery from '@/queries/newsletter/useShowNewsletterTemplateQuery.hook';
import { useParams } from 'react-router';
import styled from 'styled-components';
import NewsletterForm from '../NewsletterForm';
import { NewsletterFormSchema } from '../NewsletterForm/useNewsletterFormSchema';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import useSaveNewsletterSettingsMutation from './useSaveNewsletterSettings';
import { useBodyToastQueue } from '@/shared/hooks/useBodyToastQueue.hook';
import NewsletterFormSkeleton from '../NewsletterForm/NewsletterFormSkeleton';
import { USER_OPTIONS } from '@/shared/config/userOptions';

const NewsletterSettings = () => {
    const { showId } = useParams<{ showId: string }>();
    const settings = useShowNewsletterTemplateQuery(showId);
    const callToActions = useCallToActionButtonsQuery({
        showId,
        type: 'newsletter',
    });
    const show = useShowQuery(showId);
    const saveNewsletterTemplate = useSaveNewsletterSettingsMutation();
    const isError = show.isError;
    const isSuccess = show.isSuccess;
    const toast = useBodyToastQueue();

    if (isError) return <div>Error...</div>;

    if (isSuccess) {
        const values = {
            activated: settings.data?.activated || false,
            callToActions: callToActions.data || [],
        };
        const websiteUrl = show.data.siteUrl || '';
        const hasAccessToCTA = show.data.userOptions.includes(USER_OPTIONS.CTA_BUTTONS);
        const handleUpdateNewsletter = (data: NewsletterFormSchema) => {
            saveNewsletterTemplate.mutate(
                {
                    showId,
                    settings: data,
                },
                {
                    onSuccess: () => {
                        toast.success();
                    },
                    onError: () => {
                        toast.alert();
                    },
                },
            );
        };

        return (
            <NewsletterSettingsWrapper>
                <NewsletterSettingsInnerWrapper>
                    <NewsletterForm
                        isLoading={saveNewsletterTemplate.isLoading}
                        onSubmit={handleUpdateNewsletter}
                        values={values}
                        websiteUrl={websiteUrl}
                        hasAccessToCTA={hasAccessToCTA}
                    />
                </NewsletterSettingsInnerWrapper>
            </NewsletterSettingsWrapper>
        );
    }

    return (
        <NewsletterSettingsWrapper>
            <NewsletterSettingsInnerWrapper>
                <NewsletterFormSkeleton />
            </NewsletterSettingsInnerWrapper>
        </NewsletterSettingsWrapper>
    );
};

const NewsletterSettingsWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding);

    ${({ theme }) => theme.mediaQueries.mobile} {
        padding: var(--container-padding-mobile);
    }
`;
const NewsletterSettingsInnerWrapper = styled.div`
    max-width: 680px;
    margin-inline: auto;
`;

export default NewsletterSettings;
