import { useMutation } from '@tanstack/react-query';
import { updatePlaylistImage } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import { Episode } from '../episode/useEpisodeQuery.hook';
import { RawPlaylistEpisode } from './usePlaylistQuery.hook';

interface MutationFnProps {
    playlistId: string;
    image: File;
}

type MutationResponse = Episode &
    {
        podcasts: {
            data: RawPlaylistEpisode[];
        };
    }[];

const mutationFn = async ({ playlistId, image }: MutationFnProps): Promise<MutationResponse> => {
    const { data } = await updatePlaylistImage(playlistId, image);

    return camelcaseKeys(data as any, { deep: true });
};

const updatePlaylistImageMutation = () => {
    return useMutation({
        mutationFn,
    });
};

export default updatePlaylistImageMutation;
