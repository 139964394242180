import useEpisodesMinimalQuery from '@queries/episode/useEpisodesMinimalQuery.hook';

const useMostRecentPublishedEpisodes = (showId: string) => {
    const episodesMinimal = useEpisodesMinimalQuery({ showId });
    if (!episodesMinimal?.data?.episodes || episodesMinimal?.data?.episodes.length === 0) return [];

    const sortByElement = (episodes: unknown[], eleKey: string): unknown[] => {
        return episodes
            .filter((item) => item[eleKey])
            .sort((itemA, itemB) => itemB[eleKey] - itemA[eleKey]);
    };
    return sortByElement(episodesMinimal?.data?.episodes, 'publishedAt').slice(0, 3);
};

export default useMostRecentPublishedEpisodes;
