import { roundToNearestHalf } from '@/shared/utils/number';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';

interface RatingCellProps {
    rating: number;
    uniqueKey: string;
}

const RatingCell = ({ uniqueKey, rating }: RatingCellProps) => {
    const roundedRating = roundToNearestHalf(rating);
    return (
        <RatingCellWrapper>
            <RatingText>{rating}</RatingText>
            <StarsWrapper>
                {[...Array(5)].map((_, index) => {
                    const starNumber = index + 1;
                    const starType =
                        roundedRating >= starNumber
                            ? 'full'
                            : roundedRating >= starNumber - 0.5
                            ? 'half'
                            : 'empty';

                    if (starType === 'empty') {
                        return (
                            <EmptyStar
                                key={`${uniqueKey}-${index}`}
                                icon={icon({ name: 'star' })}
                            />
                        );
                    }

                    if (starType === 'half') {
                        return (
                            <HalfStarWrapper key={`${uniqueKey}-${index}`}>
                                <HalfStar icon={icon({ name: 'star-half' })} />
                                <StarBackground icon={icon({ name: 'star' })} />
                            </HalfStarWrapper>
                        );
                    }

                    return <Star key={`${uniqueKey}-${index}`} icon={icon({ name: 'star' })} />;
                })}
            </StarsWrapper>
        </RatingCellWrapper>
    );
};

const RatingCellWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;
const StarsWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
    margin-top: -0.2rem;

    @media (max-width: 1470px) {
        display: none;
    }
`;
const EmptyStar = styled(FontAwesomeIcon)`
    color: #ffe8b5;
`;
const Star = styled(FontAwesomeIcon)`
    color: #ffc747;
`;
const HalfStarWrapper = styled.div`
    position: relative;
    width: 14px;
    height: 14px;
`;
const HalfStar = styled(FontAwesomeIcon)`
    position: absolute;
    top: 0;
    left: 0;
    color: #ffc747;
    z-index: 2;
`;
const StarBackground = styled(FontAwesomeIcon)`
    position: absolute;
    top: 0;
    left: 0;
    color: #ffe8b5;
`;
const RatingText = styled.span`
    display: inline-block;
    width: 1.5rem;
    font-size: var(--fs-body-m);
`;

export default RatingCell;
