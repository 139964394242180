import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Tooltip, { TooltipTriggerWrapper } from '@ui/Tooltip';
import { TooltipTrigger } from 'react-aria-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useModalToastQueue } from '@/shared/hooks/useModalToastQueue.hook';
import useKeywordQuotaQuery from '@queries/pso/useKeywordQuotaQuery.hook';
import useFollowKeywordMutation from '@queries/psoChecker/useFollowKeywordMutation.hook';
import styled, { css } from 'styled-components';

interface SuggestedKeywordProps {
    keyword: {
        id: string;
        label: string;
    };
    isActive: boolean;
    isFollowed: boolean;
}

const SuggestedKeyword = ({ keyword, isActive, isFollowed }: SuggestedKeywordProps) => {
    const { showId }: { showId: string } = useParams();
    const { data: keywordQuota } = useKeywordQuotaQuery({ showId });
    const toast = useModalToastQueue();
    const followKeyword = useFollowKeywordMutation();

    const handleFollow = (keyword: { id: string; label: string }) => {
        if (keywordQuota.reached) {
            toast.alert(
                <FormattedMessage defaultMessage="Vous avez atteint la limite de votre quota de mots-clés. Pour en ajouter d’avantage, libérez de l’espace depuis votre PSO Control Panel." />,
            );
            return;
        }
        followKeyword.mutate(
            { showId, keyword: keyword.label },
            {
                onSuccess: () => {
                    toast.success(
                        <FormattedMessage
                            defaultMessage={`Le mot-clé "{keyword}" a bien été ajouté à votre liste de mots-clés du PSO Control Panel. 👌`}
                            values={{
                                keyword: keyword.label,
                            }}
                        />,
                    );
                },
            },
        );
    };

    return (
        <TooltipTrigger delay={0} closeDelay={0}>
            <TooltipTriggerWrapper>
                <Wrapper isActive={isActive}>{keyword.label}</Wrapper>
            </TooltipTriggerWrapper>
            <Tooltip placement="top" variant="light">
                {isFollowed ? (
                    <Followed>
                        <FontAwesomeIcon icon={icon({ name: 'check', style: 'solid' })} />
                        <FormattedMessage defaultMessage="Vous suivez ce mot-clé" />
                    </Followed>
                ) : (
                    <FollowButton onClick={() => handleFollow(keyword)}>
                        <FontAwesomeIcon icon={icon({ name: 'plus', style: 'solid' })} />
                        <FormattedMessage defaultMessage="Suivre ce mot-clé" />
                    </FollowButton>
                )}
            </Tooltip>
        </TooltipTrigger>
    );
};

const Wrapper = styled.button<{ isActive: boolean }>`
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    font-size: var(--fs-body-s);
    font-weight: var(--fw-semibold);
    border-radius: var(--r-s);
    color: var(--neutral500);
    background-color: var(--neutral50);
    cursor: pointer;
    border: none;
    &:hover {
        background-color: var(--neutral100);
    }

    ${(props) =>
        props.isActive &&
        css`
            color: var(--primary);
            background-color: var(--primary50);
            &:hover {
                background-color: var(--primary100);
            }
        `}
`;
const Followed = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
    color: var(--neutral500);
`;
const FollowButton = styled.button`
    border: none;
    background-color: var(--primary50);
    color: var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    border-radius: var(--r-s);
    padding-block: 0.5rem;
    padding-inline: 0.75rem;
    font-size: var(--fs-body-s);
    font-weight: var(--fw-semibold);
    cursor: pointer;
`;

export default SuggestedKeyword;
