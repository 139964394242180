import ShowPlaceholderImage from '@public/images/default_cover.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Link from '@ui/atoms/Link';
import styled from 'styled-components';

interface ImgMenuDropdownSkeletonProps {
    label: string | React.ReactNode;
}

const ImgMenuDropdownSkeleton = ({ label }: ImgMenuDropdownSkeletonProps) => {
    return (
        <ShowMenu to={`/app/show/all`}>
            <ShowCoverWrapper>
                <ShowCover src={ShowPlaceholderImage} />
            </ShowCoverWrapper>
            <Right>
                <TextWrapper>
                    <ShowName>{label}</ShowName>
                </TextWrapper>

                <DownIcon icon={icon({ name: 'chevron-down', style: 'solid' })} size="xs" />
            </Right>
        </ShowMenu>
    );
};

const ShowMenu = styled(Link)`
    display: flex;
    align-items: center;
    align-self: stretch;
    z-index: 1;
    max-width: 280px;
    cursor: pointer;
    width: initial;
    color: var(--neutral500);
    font-weight: var(--fw-semibold);
    text-decoration: none;

    &:hover {
        background: var(--neutral50);
    }
`;
const ShowCoverWrapper = styled.div`
    align-self: stretch;
    padding-inline: 1rem;
    padding-block: 0.5rem;
    flex-shrink: 0;
`;
const ShowCover = styled.img`
    height: 100%;
    width: auto;
    max-width: 3rem;
    aspect-ratio: 1/1;
    border-radius: var(--r-m);
`;
const Right = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    max-width: 200px;
    padding-right: 0.5rem;
    ${({ theme }) => theme.mediaQueries.mobile} {
        display: none;
    }
`;
const TextWrapper = styled.div``;
const ShowName = styled.div`
    font-weight: var(--fw-semibold);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: calc(200px - 2rem);
`;
const DownIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
`;

export default ImgMenuDropdownSkeleton;
