import Text from '@ui/atoms/Text';
import styled from 'styled-components';

const TooltipContent = ({ series, dataPointIndex, w }) => {
    return (
        <TooltipContentWrapper>
            <Data>{series[0][dataPointIndex]}</Data>
            <Text>{w.globals.categoryLabels[dataPointIndex]}</Text>
        </TooltipContentWrapper>
    );
};

const TooltipContentWrapper = styled.div`
    background-color: var(--black);
    color: var(--neutral500);
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: var(--fs-body-s);
    color: var(--white);
`;
const Data = styled(Text)`
    font-weight: var(--fw-bold);
`;

export default TooltipContent;
