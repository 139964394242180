import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import RouterLink from '@/components/Link/RouterLink';

const CancelButton = () => {
    const { showId } = useParams();
    return (
        <RouterLink to={`/app/show/${showId}/episodes`} variant="button-tertiary">
            <FormattedMessage defaultMessage="Annuler" />
        </RouterLink>
    );
};

export default CancelButton;
