import { z } from 'zod';
import { FORM_VALIDATION_MESSAGE } from '@/shared/services/validationMessageService';
import { useIntl } from 'react-intl';

const useAICustomPromptFormSchema = () => {
    const intl = useIntl();
    return z.object({
        customPrompt: z
            .string()
            .max(200, intl.formatMessage(FORM_VALIDATION_MESSAGE.maxLength, { max: 200 }))
            .optional(),
        saveAsDefault: z.boolean().optional(),
    });
};

export type AICustomPromptFormSchema = z.infer<ReturnType<typeof useAICustomPromptFormSchema>>;

export default useAICustomPromptFormSchema;
