import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import useSoundcloudBroadcastSettingsFormSchema from './useSoundcloudBroadcastSettingsFormSchema.hook';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@ui/atoms/Button';
import { ToggleController } from '@ui/atoms/Toggle';
import Stack from '@ui/layout/Stack';
import Text from '@ui/atoms/Text';

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 2.5rem;
    width: 100%;
`;
const SubmitButton = styled(Button)`
    margin-top: 0.5rem;
    align-self: center;
`;

const CategoryWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;

    ${(p) => p.theme.mediaQueries.tabletAndUp} {
        row-gap: 2rem;
    }
`;
const CategoryTitle = styled(Text)`
    width: 100%;
    padding-bottom: 0.25rem;
    border-bottom: solid 1px var(--neutral100);
`;

const SoundcloudBroadcastSettingsForm = ({ onSubmit, settings, isLoading }) => {
    const schema = useSoundcloudBroadcastSettingsFormSchema();
    const { control, handleSubmit, formState } = useForm({
        mode: 'onChange',
        defaultValues: settings || {
            feedable: false,
            downloadable: false,
        },
        resolver: yupResolver(schema),
    });

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <CategoryWrapper>
                <CategoryTitle variant="heading" fontWeight="--fw-bold">
                    <FormattedMessage defaultMessage="Paramètres" />
                </CategoryTitle>
                <Stack $gap="0.5rem">
                    <Text fontWeight="--fw-semibold">
                        <FormattedMessage defaultMessage="Téléchargements directs" />
                    </Text>
                    <ToggleController control={control} name="downloadable">
                        <FormattedMessage defaultMessage="Votre épisode sera téléchargeable depuis l'interface SoundCloud" />
                    </ToggleController>
                </Stack>
                <Stack $gap="0.5rem">
                    <Text fontWeight="--fw-semibold">
                        <FormattedMessage defaultMessage="Inclure dans le flux RSS SoundCloud" />
                    </Text>
                    <ToggleController control={control} name="feedable">
                        <FormattedMessage defaultMessage="Votre épisode sera présent dans le flux RSS de votre émission sur SoundCloud" />
                    </ToggleController>
                </Stack>
            </CategoryWrapper>
            <SubmitButton
                type="submit"
                isDisabled={!formState.isValid || !formState.isDirty || isLoading}
                isLoading={isLoading}
            >
                <FormattedMessage defaultMessage="Enregistrer" />
            </SubmitButton>
        </Form>
    );
};

export default observer(SoundcloudBroadcastSettingsForm);
