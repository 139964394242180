import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import Stack from '@ui/layout/Stack';
import Text from '@ui/atoms/Text';
import EmptyState from '@ui/molecules/EmptyState';
import ZebraIllustration from '@ui/atoms/ZebraIllustration';
import microphoneEmoji from '@public/images/emoji/studio_microphone.png';
import noEpisodeMainIllustration from '@public/images/empty-state/illustration/video_clips.mp4';
// @ts-ignore
import noClipHeadIllustration from '@public/images/empty-state/video_clips.svg?url';
import Alert from '@ui/atoms/Alert';
import IconAdd from '@ui/icons/IconAdd';
import { USER_ROLES } from '@/shared/config/constants';
import { useGuard } from '@/shared/hooks/useGuard.hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { extractCssVariable } from '@/shared/utils/cssVariable';
import { GUARD_DISALLOW_REASONS } from '@/shared/config/constants';
import styled from 'styled-components';
import { useAmplitudeLogEvent } from '@/shared/hooks/useAmplitude';
import { useModalToastQueue } from '@/shared/hooks/useModalToastQueue.hook';
import useShowQuery from '@queries/show/useShowQuery.hook';
import useShowClipFormatsQuery from '@/queries/clipFormat/useShowClipFormatsQuery.hook';
import ServerPagination from '@/components/ui/ServerPagination';
import useClipQuotaQuery from '@/queries/clip/useClipQuotaQuery';
import createClipMutation from '@/queries/clip/createClipMutation';
import Grid from './Grid';
import RouterLink from '@/components/Link/RouterLink';
import Button from '@/components/Button';
import NewClipModal from './NewClipModal';
import Cluster from '@/components/ui/layout/Cluster';
import { TooltipTrigger } from 'react-aria-components';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';
import Skeleton from './Skeleton';
import QuotaReachedModal from './QuotaReachedModal';

const Clips = () => {
    useAmplitudeLogEvent('Video Clip Tab Viewed');
    const history = useHistory();
    const { allow, reason } = useGuard({
        disallowedUserRoles: [USER_ROLES.VIEWER],
    });
    const { showId } = useParams<{ showId: string }>();
    const [isQuotaReachedModalOpen, setIsQuotaReachedModalOpen] = useState(false);
    const [isNewClipModalOpen, setIsNewClipModalOpen] = useState(false);
    const toast = useModalToastQueue();
    const { data: show } = useShowQuery(showId);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(16);
    const clipFormats = useShowClipFormatsQuery({
        showId,
        pagination: {
            page,
            perPage,
        },
    });
    const { data: clipQuota } = useClipQuotaQuery(showId);
    const disallowMessageLookup = {
        [GUARD_DISALLOW_REASONS.INSUFFICIENT_ROLE]: (
            <FormattedMessage defaultMessage="Vous devez être éditeur ou administrateur de l'émission pour créer un clip vidéo." />
        ),
    };
    const createClip = createClipMutation();
    const intl = useIntl();

    if (show && clipFormats.data && clipQuota) {
        const remainingClipsCount = Math.max(Math.floor(clipQuota.maximum - clipQuota.total), 0);

        const newClipHandler = () => {
            if (remainingClipsCount > 0) {
                setIsNewClipModalOpen(true);
            } else {
                setIsQuotaReachedModalOpen(true);
            }
        };

        const createClipHandler = async (episodeId: number) => {
            const title = intl.formatMessage(
                { defaultMessage: 'Clip du {date} à {time}' },
                {
                    date: intl.formatDate(new Date(), {
                        day: '2-digit',
                        month: '2-digit',
                    }),
                    time: intl.formatTime(new Date()),
                },
            );
            const newClip = {
                episodeId,
                start: 0,
                end: 30,
                duration: 30,
                transcript: false,
                title: '',
                caption: '',
                adminTitle: title,
            };

            createClip.mutate(
                {
                    episodeId,
                    clip: newClip,
                },
                {
                    onSuccess: (clip) => {
                        history.push(`/app/show/${showId}/communication/clip/${clip.id}`);
                    },
                    onError: () => {
                        toast.alert();
                    },
                },
            );
        };

        if (show.podcastsCount < 1) {
            return (
                <EmptyState
                    illustration={noEpisodeMainIllustration}
                    illustrationType="video"
                    illustrationAlign="right"
                    illustrationBackground="--gradient-dawn"
                >
                    <HeadIllustrationWrapper>
                        <ZebraIllustration foreground={microphoneEmoji} />
                    </HeadIllustrationWrapper>
                    <LabelWrapper>
                        <Text variant="heading" fontWeight="--fw-bold">
                            <FormattedMessage defaultMessage="Commencez par ajouter votre premier épisode" />
                        </Text>
                        <Text variant="body" fontWeight="--fw-normal" color="--neutral500">
                            <FormattedMessage
                                defaultMessage="Débloquez la création de clips vidéo en <link>créant votre premier épisode</link> 🔥"
                                values={{
                                    link: (chunks: string) => (
                                        <RouterLink to={`/app/show/${showId}/episodes`}>
                                            {chunks}
                                        </RouterLink>
                                    ),
                                }}
                            />
                        </Text>
                    </LabelWrapper>
                </EmptyState>
            );
        }

        if (clipFormats.data.clipFormats.length === 0) {
            return (
                <>
                    <EmptyState
                        illustration={noEpisodeMainIllustration}
                        illustrationType="video"
                        illustrationAlign="right"
                        illustrationBackground="--gradient-dawn"
                    >
                        <HeadIllustrationWrapper>
                            <HeadIllustration src={noClipHeadIllustration} />
                        </HeadIllustrationWrapper>
                        <LabelWrapper>
                            <Text variant="heading" fontWeight="--fw-bold">
                                <FormattedMessage defaultMessage="Créez votre premier clip vidéo" />
                            </Text>
                            <Text variant="body" fontWeight="--fw-normal" color="--neutral500">
                                <FormattedMessage defaultMessage="Créez une courte vidéo contenant un extrait audio de votre épisode et publiez la sur vos réseaux sociaux pour maximiser votre visibilité 🎬" />
                            </Text>
                        </LabelWrapper>
                        {!allow && (
                            <Alert
                                variant="alert"
                                icon={
                                    <FontAwesomeIcon
                                        icon={icon({ name: 'circle-info', style: 'solid' })}
                                        color={extractCssVariable('--alert500')}
                                        size="lg"
                                    />
                                }
                            >
                                {disallowMessageLookup[reason] ?? (
                                    <FormattedMessage defaultMessage="Une erreur est survenue" />
                                )}
                            </Alert>
                        )}
                        <Button
                            startIcon={<AddIcon icon={icon({ name: 'add', style: 'solid' })} />}
                            isDisabled={!allow}
                            onPress={newClipHandler}
                        >
                            <FormattedMessage defaultMessage="Créer un clip vidéo" />
                        </Button>
                    </EmptyState>
                    <QuotaReachedModal
                        isOpen={isQuotaReachedModalOpen}
                        onOpenChange={setIsQuotaReachedModalOpen}
                        monthlyClips={clipQuota.maximum ?? 0}
                    />
                    <NewClipModal
                        isOpen={isNewClipModalOpen}
                        onOpenChange={setIsNewClipModalOpen}
                        onSubmit={createClipHandler}
                        isLoading={createClip.isLoading}
                    />
                </>
            );
        }

        return (
            <>
                <Stack $gap="2rem">
                    <ClipsWrapper>
                        <Header>
                            <Text variant="subheading" fontWeight="--fw-semibold">
                                <FormattedMessage
                                    defaultMessage="{clipsCount, plural, =0 {Aucun clip} one {# clip vidéo} other {# clips vidéo}}"
                                    values={{ clipsCount: clipFormats.data?.pagination?.total }}
                                />
                            </Text>
                            <QuotaAndActionWrapper>
                                <Cluster $gap="0.5rem" $flexWrap="nowrap">
                                    <Cluster $gap="0.25rem" $flexWrap="nowrap" $align="center">
                                        <Text>
                                            <FormattedMessage defaultMessage="Crédits clip restants ce mois-ci :" />
                                        </Text>
                                        <Text fontWeight="--fw-semibold">
                                            <FormattedNumber value={remainingClipsCount} />
                                        </Text>
                                    </Cluster>
                                    <TooltipTrigger delay={0} closeDelay={0}>
                                        <TooltipTriggerWrapper>
                                            <TooltipIcon
                                                icon={icon({
                                                    name: 'circle-info',
                                                    style: 'solid',
                                                })}
                                            />
                                        </TooltipTriggerWrapper>
                                        <Tooltip placement="top">
                                            <FormattedMessage
                                                defaultMessage="Chaque 1er du mois, vous obtenez {monthlyClips} crédits clips. Les crédits non-utilisés en fin de mois sont perdus."
                                                values={{
                                                    monthlyClips: (
                                                        <FormattedNumber
                                                            value={clipQuota.maximum ?? 0}
                                                        />
                                                    ),
                                                }}
                                            />
                                        </Tooltip>
                                    </TooltipTrigger>
                                </Cluster>
                                <Button
                                    startIcon={<IconAdd />}
                                    isDisabled={!allow}
                                    onPress={newClipHandler}
                                >
                                    <FormattedMessage defaultMessage="Créer un clip" />
                                </Button>
                            </QuotaAndActionWrapper>
                        </Header>
                        <Grid clipFormats={clipFormats.data?.clipFormats} />
                    </ClipsWrapper>
                    {clipFormats.data?.pagination &&
                        clipFormats.data?.clipFormats.length > 0 &&
                        clipFormats.data?.pagination.totalPages > 1 && (
                            <ServerPagination
                                pagination={clipFormats.data?.pagination}
                                onGoToPage={(page) => setPage(page)}
                                onGoToFirstPage={() => setPage(1)}
                                onGoToLastPage={() =>
                                    setPage(clipFormats.data?.pagination.totalPages)
                                }
                                onGoToPreviousPage={() =>
                                    setPage(clipFormats.data?.pagination.currentPage - 1)
                                }
                                onGoToNextPage={() =>
                                    setPage(clipFormats.data?.pagination.currentPage + 1)
                                }
                                onChangePerPage={(perPage) => setPerPage(perPage)}
                                options={{
                                    goToFirstAndLastPageButtons: false,
                                    showPaginationInfo: true,
                                    showPageSelector: false,
                                }}
                            />
                        )}
                </Stack>
                <QuotaReachedModal
                    isOpen={isQuotaReachedModalOpen}
                    onOpenChange={setIsQuotaReachedModalOpen}
                    monthlyClips={clipQuota.maximum ?? 0}
                />
                <NewClipModal
                    isOpen={isNewClipModalOpen}
                    onOpenChange={setIsNewClipModalOpen}
                    onSubmit={createClipHandler}
                    isLoading={createClip.isLoading}
                />
            </>
        );
    }

    return (
        <ClipsWrapper>
            <Skeleton />
        </ClipsWrapper>
    );
};

const HeadIllustrationWrapper = styled.div`
    width: 200px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const HeadIllustration = styled.img`
    min-width: 248px;
`;
const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    & > * {
        text-align: center;
    }
`;
const ClipsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: 1rem;
`;
const AddIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const Header = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
        align-items: center;
        column-gap: 2rem;
        justify-content: space-between;
    }
`;
const QuotaAndActionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
        align-items: center;
        column-gap: 1rem;
    }
`;
const TooltipIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: var(--neutral500);
`;

export default Clips;
