import { useCallback, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import TranscriptionEditor from '@/components/unorganized/TranscriptionEditor/TranscriptionEditor';
import TranscriptionPlayer from '@/components/unorganized/TranscriptionPlayer/TranscriptionPlayer';
import Text from '@ui/atoms/Text';
import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import useUpdateEpisodeTranscriptMutation from '@queries/episodeTranscript/useUpdateEpisodeTranscriptMutation.hook';
import {
    useConvertTiptapContentToPlainText,
    convertTiptapContentToTranscript,
} from '@/components/unorganized/TranscriptionEditor/transcriptConverter';
import { useSpeakers } from '@/components/unorganized/TranscriptionEditor/TranscriptionSpeakersProvider';
import useTranscriptionEditor from '@/components/unorganized/TranscriptionEditor/useTranscriptionEditor';
import { TooltipTrigger } from 'react-aria-components';
import Tooltip, { TooltipTriggerWrapper } from '@ui/Tooltip';
import Button from '@/components/Button';
import { useResponsive } from '@/shared/hooks/useResponsive';
import { useQueryClient } from '@tanstack/react-query';
import aiKeys from '@queries/ai/aiKeys';
import { useCopy } from '@/shared/hooks/useCopy';
import IconButton from '@ui/atoms/IconButton';
import EpisodeEditTranscriptionMenu from '@/components/unorganized/EpisodeEditTranscription/EpisodeEditTranscriptionMenu';
import { useBodyToastQueue } from '@/shared/hooks/useBodyToastQueue.hook';

const Transcription = ({ transcription, episodeId, audioUrl, waveformDataUrl, onClose }) => {
    const intl = useIntl();
    const { speakers } = useSpeakers();
    const updateTranscript = useUpdateEpisodeTranscriptMutation();
    const [currentTime, setCurrentTime] = useState(0);
    const [cursorTimeCode, setCursorTimeCode] = useState(null);
    const { isDesktop } = useResponsive();
    const editor = useTranscriptionEditor({ transcription });
    const queryClient = useQueryClient();
    const [copied, onCopy] = useCopy();
    const toast = useBodyToastQueue();

    const handlePlay = () => {
        const { head: cursorPosition } = editor.state.selection;
        const node = editor.state.doc.resolve(cursorPosition).parent;
        if (node.type.name === 'word') setCursorTimeCode(node.attrs.start);
    };

    const handleUpdateTranscript = useCallback(() => {
        if (!editor) {
            onClose();
            return;
        }
        const speakersArray = Array.from(speakers.entries()).map(([id, name]) => ({ id, name }));
        updateTranscript.mutate(
            {
                episodeId,
                transcript: {
                    data: convertTiptapContentToTranscript(editor.getJSON(), speakersArray),
                },
            },
            {
                onSuccess: () => {
                    // Because AI summary is regenerated after the transcript is updated
                    // chapters can be outdated and new suggestions could be fetched
                    localStorage.removeItem(`episode-ai-${episodeId}-generated-chapters`);
                    queryClient.resetQueries({ queryKey: aiKeys.all() });
                    toast.success();
                },
            },
        );
    }, [episodeId, onClose, updateTranscript]);

    const handleCopy = () => {
        return useConvertTiptapContentToPlainText(editor?.getJSON(), speakers, intl);
    };

    return (
        <>
            <Header>
                {isDesktop ? (
                    <BackButton
                        variant="link-secondary"
                        startIcon={
                            <ArrowIcon icon={icon({ name: 'arrow-left', style: 'solid' })} />
                        }
                        onPress={onClose}
                    >
                        <FormattedMessage defaultMessage="Retour à l'épisode" />
                    </BackButton>
                ) : (
                    <IconButton
                        aria-label="back-button"
                        variant="ghost"
                        icon={<ArrowIcon icon={icon({ name: 'arrow-left', style: 'solid' })} />}
                        onPress={onClose}
                    />
                )}
                <Caption>
                    <CaptionIndicator />
                    <Text>
                        <FormattedMessage defaultMessage="Mots avec une certitude plus faible" />
                    </Text>
                    <TooltipTrigger delay={0} closeDelay={0}>
                        <TooltipTriggerWrapper>
                            <TooltipIcon icon={icon({ name: 'circle-info', style: 'solid' })} />
                        </TooltipTriggerWrapper>
                        <Tooltip placement="bottom">
                            <Text fontWeight="--fw-semibold">
                                <FormattedMessage defaultMessage="Lorsque l'IA n'est pas sûre d'un mot, celui-ci est affiché en orange pour signaler une possible erreur." />
                            </Text>
                        </Tooltip>
                    </TooltipTrigger>
                </Caption>
                <HeaderButtons>
                    <Button
                        variant="secondary"
                        startIcon={
                            <FontAwesomeIcon icon={icon({ name: 'copy', style: 'solid' })} />
                        }
                        onPress={() => onCopy(handleCopy())}
                    >
                        {copied ? (
                            <FormattedMessage defaultMessage="Copié ! 👍" />
                        ) : isDesktop ? (
                            <FormattedMessage defaultMessage="Copier tout le contenu" />
                        ) : (
                            <FormattedMessage defaultMessage="Copier" />
                        )}
                    </Button>
                    <Button
                        startIcon={
                            <FontAwesomeIcon icon={icon({ name: 'check', style: 'solid' })} />
                        }
                        onPress={handleUpdateTranscript}
                        isLoading={updateTranscript.isLoading}
                    >
                        {isDesktop ? (
                            <FormattedMessage defaultMessage="Enregistrer les modifications" />
                        ) : (
                            <FormattedMessage defaultMessage="Enregistrer" />
                        )}
                    </Button>
                    <EpisodeEditTranscriptionMenu
                        menuIcon={
                            <EllipsisIcon
                                icon={icon({ name: 'ellipsis-vertical', style: 'solid' })}
                            />
                        }
                        objectToSaveBeforeExport={{
                            transcription: editor?.getJSON(),
                            speakers: speakers,
                        }}
                    />
                </HeaderButtons>
            </Header>
            <Body>
                <Content>
                    <TranscriptionEditor editor={editor} currentTime={currentTime} />
                </Content>
                <TranscriptionPlayer
                    audioUrl={audioUrl}
                    waveformDataUrl={waveformDataUrl}
                    cursorTimeCode={cursorTimeCode}
                    onCurrentTimeChange={setCurrentTime}
                    onPlay={handlePlay}
                />
            </Body>
        </>
    );
};

const Header = styled.header`
    display: flex;
    justify-content: space-between;
    column-gap: 2rem;
    padding: 1rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        padding: 2rem;
    }
`;
const Body = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    height: calc(100vh - 8rem);

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        padding: 2rem;
    }
`;
const Content = styled.div`
    flex-grow: 1;
    overflow: auto;
    scroll-behavior: smooth;
    align-self: center;

    @media (prefers-reduced-motion: reduce) {
        scroll-behavior: auto;
    }

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        padding-inline-start: 12rem;
        max-width: var(--container-width);
    }
`;
const HeaderButtons = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
`;
const BackButton = styled(Button)`
    border: none;
`;
const ArrowIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
`;
const TooltipIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: var(--neutral500);
`;
const Caption = styled.div`
    display: none;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: flex;
        align-items: center;
        column-gap: 0.5rem;
        margin-right: auto;
    }
`;
const CaptionIndicator = styled.div`
    width: 0.5rem;
    height: 0.5rem;
    background: var(--warning500);
    border-radius: var(--r-full);
`;
const EllipsisIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--neutral500);
`;

Transcription.propTypes = {
    transcription: PropTypes.object,
    episodeId: PropTypes.number,
    audioUrl: PropTypes.string,
    waveformDataUrl: PropTypes.string,
    onClose: PropTypes.func,
};

export default Transcription;
