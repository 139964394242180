import { PROJECTION_MODES } from '../../Geolocalization.constants';
import styled, { css } from 'styled-components';

const Selector = styled.div`
    display: flex;
    outline: 2px solid var(--white);
    border-radius: var(--r-s);
`;

const IconWrapper = styled.div`
    padding: 0.5rem;

    & > svg {
        width: 16px;
        height: 16px;
    }
`;

const SelectorButton = styled.button`
    padding: 0;
    cursor: pointer;
    background-color: var(--neutral50);
    border: 0;

    & ${IconWrapper} {
        color: var(--neutral500);
    }

    &:hover ${IconWrapper} {
        color: var(--primary);
    }

    ${(p) =>
        p.active &&
        css`
            border: 0;

            & ${IconWrapper} {
                background-color: var(--primary);
                color: var(--white);
            }

            &:hover ${IconWrapper} {
                color: var(--white);
            }

            &:first-of-type ${IconWrapper} {
                border-radius: 8px 0 0 8px;
            }

            &:last-of-type ${IconWrapper} {
                border-radius: 0 8px 8px 0;
            }
        `}

    &:first-of-type {
        border-radius: 8px 0 0 8px;
    }

    &:last-of-type {
        border-radius: 0 8px 8px 0;
    }
`;

const MercatorMapIcon = () => (
    <IconWrapper>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z"
            />
        </svg>
    </IconWrapper>
);

const GlobeMapIcon = () => (
    <IconWrapper>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M20.893 13.393l-1.135-1.135a2.252 2.252 0 01-.421-.585l-1.08-2.16a.414.414 0 00-.663-.107.827.827 0 01-.812.21l-1.273-.363a.89.89 0 00-.738 1.595l.587.39c.59.395.674 1.23.172 1.732l-.2.2c-.212.212-.33.498-.33.796v.41c0 .409-.11.809-.32 1.158l-1.315 2.191a2.11 2.11 0 01-1.81 1.025 1.055 1.055 0 01-1.055-1.055v-1.172c0-.92-.56-1.747-1.414-2.089l-.655-.261a2.25 2.25 0 01-1.383-2.46l.007-.042a2.25 2.25 0 01.29-.787l.09-.15a2.25 2.25 0 012.37-1.048l1.178.236a1.125 1.125 0 001.302-.795l.208-.73a1.125 1.125 0 00-.578-1.315l-.665-.332-.091.091a2.25 2.25 0 01-1.591.659h-.18c-.249 0-.487.1-.662.274a.931.931 0 01-1.458-1.137l1.411-2.353a2.25 2.25 0 00.286-.76m11.928 9.869A9 9 0 008.965 3.525m11.928 9.868A9 9 0 118.965 3.525"
            />
        </svg>
    </IconWrapper>
);

const ProjectionSelector = ({ activeProjection, setActiveProjection }) => {
    return (
        <Selector>
            <SelectorButton
                active={activeProjection === PROJECTION_MODES.MERCATOR}
                onClick={() => setActiveProjection(PROJECTION_MODES.MERCATOR)}
            >
                <MercatorMapIcon />
            </SelectorButton>
            <SelectorButton
                active={activeProjection === PROJECTION_MODES.GLOBE}
                onClick={() => setActiveProjection(PROJECTION_MODES.GLOBE)}
            >
                <GlobeMapIcon />
            </SelectorButton>
        </Selector>
    );
};

export default ProjectionSelector;
