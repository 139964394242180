import ContentLoader from 'react-content-loader';
import createFallback from '../fallbacks/createFallback';

const FallbackClipFormat = createFallback((props) => (
    <ContentLoader
        width={500}
        height={212}
        style={{ width: '100%', maxWidth: 500, height: 212 }}
        preserveAspectRatio="none"
        {...props}
    >
        {/* Clip cover */}
        <rect x="0" y="0" rx="5" ry="5" width="500" height="152" />
        {/* Clip title */}
        <rect x="0" y="164" rx="5" ry="5" width="250" height="20" />
        {/* Episode title */}
        <rect x="0" y="190" rx="5" ry="5" width="350" height="20" />
    </ContentLoader>
));

export default FallbackClipFormat;
