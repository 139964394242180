import { FormattedMessage } from 'react-intl';
import ImgMenuDropdown from './ImgMenuDropdown';
import Skeleton from './ImgMenuDropdownSkeleton';
import useShowQuery from '@queries/show/useShowQuery.hook';
import useShowsQuery from '@queries/show/useShowsQuery.hook';

interface ShowsMenuDropdownProps {
    showId: string;
}

const ShowsMenuDropdown = ({ showId }: ShowsMenuDropdownProps) => {
    const { data: show } = useShowQuery(showId);
    const { data: shows } = useShowsQuery();

    if (shows?.shows?.length === 0) {
        return <Skeleton label={<FormattedMessage defaultMessage="Ajouter une émission" />} />;
    }

    if (!show) {
        return <Skeleton label={<FormattedMessage defaultMessage="Sélectionner une émission" />} />;
    }

    return show ? <ImgMenuDropdown show={show} /> : null;
};

export default ShowsMenuDropdown;
