import styled from 'styled-components';
import AushaLogo from '@ui/atoms/AushaLogo';
import FormStepper from '@/shared/components/FormStepper';
import { useStepperState } from '@/features/onboarding/contexts/StepperStateContext';

const Header = () => {
    const { steps } = useStepperState();
    return (
        <Wrapper>
            <Logo isComplete isColored />
            <StepperWrapper>
                <FormStepper steps={steps} />
            </StepperWrapper>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;
const Logo = styled(AushaLogo)`
    height: 2rem;
`;
const StepperWrapper = styled.div`
    width: 9rem;
`;

export default Header;
