import statsKeys from '@queries/stats/statsKeys';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { fetchPeakTimePopularHours } from '@/api';
import { z } from 'zod';
import camelcaseKeys from 'camelcase-keys';

interface PeakTimePopularHoursQueryParams {
    showId: string;
    days: '7' | '15' | '30' | '90' | 'always';
}

const HourSchema = z.object({
    downloads: z.number(),
    dayHour: z.number(),
});

const HoursSchema = z.array(HourSchema);
export type Hour = z.infer<typeof HourSchema>;

type Key = QueryFunctionContext<ReturnType<typeof statsKeys.popularHoursByShowId>>;

const queryFn = async ({ queryKey: [{ showId, days }] }: Key) => {
    const { data } = await fetchPeakTimePopularHours(showId, { days });

    return HoursSchema.parse(camelcaseKeys(data, { deep: true }));
};

const usePeakTimePopularHoursQuery = ({ showId, days }: PeakTimePopularHoursQueryParams) => {
    return useQuery({
        queryKey: statsKeys.popularHoursByShowId(showId, days),
        queryFn,
        enabled: !!showId,
    });
};

export default usePeakTimePopularHoursQuery;
