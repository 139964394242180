import Stack from '@ui/layout/Stack';
import { USER_OPTIONS } from '@/utils/userOptions';
import EpisodeEditFormContentNameField from '@app/organisms/EpisodeEditForm/EpisodeEditFormContent/EpisodeEditFormContentNameField';
import EpisodeEditFormContentSlugField from '@app/organisms/EpisodeEditForm/EpisodeEditFormContent/EpisodeEditFormContentSlugField';
import EpisodeEditFormContentCustomUrlField from '@app/organisms/EpisodeEditForm/EpisodeEditFormContent/EpisodeEditFormContentCustomUrlField';
import EpisodeEditFormContentDescriptionField from '@app/organisms/EpisodeEditForm/EpisodeEditFormContent/EpisodeEditFormContentDescriptionField';
import EpisodeEditFormContentTagsField from '@app/organisms/EpisodeEditForm/EpisodeEditFormContent/EpisodeEditFormContentTagsField';
import EpisodeEditFormContentImageField from '@app/organisms/EpisodeEditForm/EpisodeEditFormContent/EpisodeEditFormContentImageField';
import { useEpisodeBeingEdited } from '@app/organisms/EpisodeEditForm/EpisodeEditFormContext';
import NextStepButton from '@app/organisms/EpisodeEditForm/NextStepButton';

const ExperimentalEpisodeEditFormContent = () => {
    const episode = useEpisodeBeingEdited();

    return (
        <Stack $gap="2rem">
            <EpisodeEditFormContentNameField />
            <EpisodeEditFormContentSlugField prefix={episode?.show?.siteUrl || ''} />
            <EpisodeEditFormContentCustomUrlField
                isAllowed={episode?.show?.userOptions.includes(USER_OPTIONS.PODCASTS_CUSTOM_URL)}
            />
            <EpisodeEditFormContentDescriptionField />
            <EpisodeEditFormContentTagsField />
            <EpisodeEditFormContentImageField />
            <NextStepButton />
        </Stack>
    );
};

export default ExperimentalEpisodeEditFormContent;
