import { FormattedMessage } from 'react-intl';
import useShowStore from '@/utils/hooks/useShowStore';
import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import useYoutubeBroadcastSettingsFormSchema from './useYoutubeBroadcastSettingsFormSchema.hook';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@ui/atoms/Button';
import { ToggleController } from '@ui/atoms/Toggle';
import Stack from '@ui/layout/Stack';
import { ColorPickerController } from '@ui/molecules/ColorPicker';
import YoutubePreview from '@ui/molecules/YoutubePreview';
import { RadioGroupController } from '@ui/molecules/RadioGroup';
import InputRadio from '@ui/atoms/InputRadio';
import Text from '@ui/atoms/Text';
import { SelectController } from '@ui/molecules/Select';
import { useParams } from 'react-router-dom';
import { resolveColor } from '@/utils/cssVariables';
import UnsavedFormModal from '@app/molecules/UnsavedFormModal/UnsavedFormModal';

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 2.5rem;
    width: 100%;
`;
const SubmitButton = styled(Button)`
    margin-top: 0.5rem;
    align-self: center;
`;

const CategoryWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;

    ${(p) => p.theme.mediaQueries.tabletAndUp} {
        row-gap: 2rem;
    }
`;
const CategoryTitle = styled(Text)`
    width: 100%;
    padding-bottom: 0.25rem;
    border-bottom: solid 1px var(--neutral100);
`;
const AppearanceInnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;

    ${(p) => p.theme.mediaQueries.tabletAndUp} {
        flex-direction: row;
        & > * {
            flex: 1;
        }
    }
`;

const SelectYoutubeCategory = styled(SelectController)`
    max-width: 360px;
`;

const YoutubeBroadcastSettingsForm = ({ onSubmit, settings, categoryOptions, isLoading }) => {
    const { showId } = useParams();
    const show = showId && useShowStore().byId(showId);
    const schema = useYoutubeBroadcastSettingsFormSchema();
    const { control, handleSubmit, formState, clearErrors, setValue, watch, reset } = useForm({
        mode: 'onChange',
        defaultValues: settings || {
            category_id: '',
            privacy: 'public',
            notify_subscribers: true,
            embeddable: true,
            public_stats_viewable: true,
            for_kids: false,
            color: resolveColor('--primary'),
        },
        resolver: yupResolver(schema),
    });
    const color = watch('color');

    return (
        <>
            <UnsavedFormModal shouldBlockNavigation={formState.isDirty} />
            <Form onSubmit={handleSubmit((formData) => onSubmit(formData, reset))}>
                <CategoryWrapper>
                    <CategoryTitle variant="heading" fontWeight="--fw-bold">
                        <FormattedMessage defaultMessage="Apparence" />
                    </CategoryTitle>
                    <AppearanceInnerWrapper>
                        <ColorPickerController
                            control={control}
                            name="color"
                            label={<FormattedMessage defaultMessage="Couleur principale" />}
                            onBlur={() => clearErrors('color')}
                        />
                        <Stack $gap="0.5rem">
                            <Text fontWeight="--fw-semibold">
                                <FormattedMessage defaultMessage="Prévisualisation" />
                            </Text>
                            <YoutubePreview
                                backgroundImageUrl={show.imageUrl}
                                customLogoUrl={show.darkLogoUrl || show.lightLogoUrl}
                                displayAushaLogo={!show.isWhiteLabel}
                                showImageUrl={show.imageUrl}
                                showName={show.name}
                                waveformColor={color}
                            />
                        </Stack>
                    </AppearanceInnerWrapper>
                </CategoryWrapper>
                <CategoryWrapper>
                    <CategoryTitle variant="heading" fontWeight="--fw-bold">
                        <FormattedMessage defaultMessage="Paramètres" />
                    </CategoryTitle>
                    <RadioGroupController
                        control={control}
                        name="privacy"
                        label={<FormattedMessage defaultMessage="Statut" />}
                        onChange={() => setValue('privacy', '', { shouldDirty: true })}
                        orientation="horizontal"
                        gap="2rem"
                    >
                        <InputRadio value="public">
                            <FormattedMessage defaultMessage="Public" />
                        </InputRadio>
                        <InputRadio value="private">
                            <FormattedMessage defaultMessage="Privé" />
                        </InputRadio>
                        <InputRadio value="unlisted">
                            <FormattedMessage defaultMessage="Non répertorié" />
                        </InputRadio>
                    </RadioGroupController>
                    <SelectYoutubeCategory
                        control={control}
                        name="category_id"
                        label={<FormattedMessage defaultMessage="Catégorie YouTube" />}
                        onBlur={() => clearErrors('category_id')}
                        options={categoryOptions}
                        isRequired
                    />
                    <Stack $gap="2rem">
                        <Stack $gap="0.5rem">
                            <Text fontWeight="--fw-semibold">
                                <FormattedMessage defaultMessage="Notifier vos abonnés" />
                            </Text>
                            <ToggleController control={control} name="notify_subscribers">
                                <FormattedMessage defaultMessage="Vos abonnés reçoivent une notification dès qu’un nouvel épisode est publié sur YouTube." />
                            </ToggleController>
                        </Stack>
                        <Stack $gap="0.5rem">
                            <Text fontWeight="--fw-semibold">
                                <FormattedMessage defaultMessage="Code embed disponible" />
                            </Text>
                            <ToggleController control={control} name="embeddable">
                                <FormattedMessage defaultMessage="Votre vidéo pourra être intégrée sur d’autres sites grâce au code embed." />
                            </ToggleController>
                        </Stack>
                        <Stack $gap="0.5rem">
                            <Text fontWeight="--fw-semibold">
                                <FormattedMessage defaultMessage="Statistiques publiques" />
                            </Text>
                            <ToggleController control={control} name="public_stats_viewable">
                                <FormattedMessage defaultMessage="Les nombres de vues et de « j’aime » de votre vidéo seront visibles par tout le monde." />
                            </ToggleController>
                        </Stack>
                        <Stack $gap="0.5rem">
                            <Text fontWeight="--fw-semibold">
                                <FormattedMessage defaultMessage="YouTube pour enfant" />
                            </Text>
                            <ToggleController control={control} name="for_kids">
                                <Text>
                                    <FormattedMessage
                                        defaultMessage="Vos podcasts sont conçus pour les enfants. {learnMore}"
                                        values={{
                                            learnMore: (
                                                <Text
                                                    fontWeight="--fw-semibold"
                                                    color="--primary"
                                                    target="_blank"
                                                    as="a"
                                                    href="https://support.google.com/youtube/answer/9528076"
                                                >
                                                    <FormattedMessage defaultMessage="En savoir plus" />
                                                </Text>
                                            ),
                                        }}
                                    />
                                </Text>
                            </ToggleController>
                        </Stack>
                    </Stack>
                </CategoryWrapper>
                <SubmitButton
                    type="submit"
                    isDisabled={!formState.isValid || !formState.isDirty || isLoading}
                    isLoading={isLoading}
                >
                    <FormattedMessage defaultMessage="Enregistrer" />
                </SubmitButton>
            </Form>
        </>
    );
};

export default observer(YoutubeBroadcastSettingsForm);
