import { useParams } from 'react-router-dom';
import spotify from '@public/images/platforms/Spotify.png';
import BroadcastCard from '../../BroadcastCard';
import ShortListFallback from '@/components/unorganized/ShortListFallback';
import { LISTENING_PLATFORMS_NAMES, SPOTIFY } from '@/shared/config/constants';
import useShowQuery from '@queries/show/useShowQuery.hook';

const SpotifyBroadcast = () => {
    const { showId } = useParams<{ showId: string }>();
    const { data: show, isLoading } = useShowQuery(showId);
    const url = show?.listeningLinks?.data.find((link) => link.key === SPOTIFY)?.url;

    if (isLoading) return <ShortListFallback />;

    return (
        <BroadcastCard
            platformKey={SPOTIFY}
            name={LISTENING_PLATFORMS_NAMES[SPOTIFY]}
            logo={spotify}
            url={url}
        />
    );
};

export default SpotifyBroadcast;
