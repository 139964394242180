import { FormattedMessage } from 'react-intl';

export function commonMessages(message, params) {
    const idealSizeImg = {
        background: (
            <FormattedMessage
                defaultMessage="Taille idéale : 1500*1800px {lineBreak}Format : JPG ou PNG {lineBreak}Poids max : 10 Mo"
                values={{
                    lineBreak: <br />,
                }}
            />
        ),
        imageMobile: (
            <FormattedMessage
                defaultMessage="Taille idéale : 1400*1400px {lineBreak}Taille min : 400*400px {lineBreak}Poids max : 10 Mo"
                values={{
                    lineBreak: <br />,
                }}
            />
        ),
        image: (
            <FormattedMessage
                defaultMessage="Taille idéale : 1400*1400px {lineBreak}Taille min : 400*400px {lineBreak}Format : JPG ou PNG {lineBreak}Poids max : 10 Mo"
                values={{
                    lineBreak: <br />,
                }}
            />
        ),
        favicon: (
            <>
                <FormattedMessage
                    defaultMessage="Taille idéale : 32*32px {lineBreak}Format : JPG ou PNG {lineBreak}Poids max : 10 Mo"
                    values={{ lineBreak: <br /> }}
                />
            </>
        ),
        logo: (
            <FormattedMessage
                defaultMessage="Taille idéale : 400*120px {lineBreak}Format : JPG ou PNG {lineBreak}Poids max : 10 Mo"
                values={{ lineBreak: <br /> }}
            />
        ),
        uploadImage: <FormattedMessage defaultMessage="Importer une image" />,
        deletedImage: <FormattedMessage defaultMessage="Supprimer l'image" />,
    };

    const messagesStatus = {
        online: <FormattedMessage defaultMessage="Public" />,
        published: <FormattedMessage defaultMessage="Publié" />,
        blocked: <FormattedMessage defaultMessage="Bloqué" />,
        planned: <FormattedMessage defaultMessage="Planifié" />,
        scheduled: <FormattedMessage defaultMessage="Planifié" />,
        draft: <FormattedMessage defaultMessage="Brouillon" />,
        private: <FormattedMessage defaultMessage="Privé" />,
        unlisted: <FormattedMessage defaultMessage="Non-listé" />,
        ended: <FormattedMessage defaultMessage="Terminé" />,
        done: <FormattedMessage defaultMessage="Publié" />,
        publish_at: <FormattedMessage defaultMessage="Planifié" />,
        error: <FormattedMessage defaultMessage="Erreur" />,
        pending: <FormattedMessage defaultMessage="En attente..." />,
        processing: <FormattedMessage defaultMessage="En cours" />,
    };

    const messagePrivacy = {
        public: <FormattedMessage defaultMessage="Public" />,
        online: <FormattedMessage defaultMessage="En ligne" />,
        unlisted: <FormattedMessage defaultMessage="Non-listé" />,
        private: <FormattedMessage defaultMessage="Privé" />,
    };

    const clipCover = {
        format: <FormattedMessage defaultMessage="Format : JPG ou PNG" />,
        square: <FormattedMessage defaultMessage="Taille idéale : 1400*1400px" />,
        story: <FormattedMessage defaultMessage="Taille idéale : 1400*2600px" />,
        wide: <FormattedMessage defaultMessage="Taille idéale : 1400*800px" />,
    };

    const messageChoice = {
        idealSizeImg: idealSizeImg[params],
        status: messagesStatus[params],
        privacy: messagePrivacy[params],
        clipCover: clipCover[params],
    };

    return messageChoice[message];
}
