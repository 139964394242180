import VirtualList from 'react-tiny-virtual-list';
import { FormattedMessage } from 'react-intl';
import { withSize } from 'react-sizeme';
import DeprecatedPaper from '../../ui/atoms/DeprecatedPaper';
import RankingVariationGraphTableColumn, {
    RankingVariationGraphTableColumnFallback,
} from './RankingVariationGraphTableColumn';
import DeprecatedText from '../../ui/atoms/DeprecatedText';
import DeprecatedWhitePaper from '../../ui/atoms/DeprecatedWhitePaper';
import { connect } from '../decorators/connect';

const enhance = connect(({ applePodcastsStatsStore }) => ({
    data: applePodcastsStatsStore.applePodcastsRankingVariationsData,
    selectedStep: applePodcastsStatsStore.stepMobileApplePodcasts,
}));

function RankingVariationGraphTable({
    data,
    selectedStep,
    hoveredValue,
    scrollToValue,
    onHover,
    isLoading,
    size,
}) {
    let formatMatchingSelectedStep;
    switch (selectedStep) {
        case 'days':
            formatMatchingSelectedStep = 'dateShortFormat';
            break;
        case 'weeks':
            formatMatchingSelectedStep = 'dateShortFormat';
            break;
        case 'months':
            formatMatchingSelectedStep = 'monthShortFormat';
            break;
        case 'years':
            formatMatchingSelectedStep = 'yearFormat';
            break;
        default:
            formatMatchingSelectedStep = 'dateShortFormat';
    }

    return (
        <DeprecatedWhitePaper flex overflowHide>
            <DeprecatedPaper height="165px" w={150} br={1} border="--neutral100">
                <DeprecatedPaper
                    height="49px"
                    py={15}
                    pl={20}
                    border="--neutral100"
                    background="--neutral50"
                >
                    <DeprecatedText color="--neutral500">
                        <FormattedMessage defaultMessage="Période" />
                    </DeprecatedText>
                </DeprecatedPaper>
                <DeprecatedPaper
                    height="50px"
                    py={15}
                    pl={20}
                    bt={1}
                    border="--neutral100"
                    background="white"
                >
                    <DeprecatedText weight="semibold">
                        <FormattedMessage defaultMessage="Classement" />
                    </DeprecatedText>
                </DeprecatedPaper>
                <DeprecatedPaper
                    height="50px"
                    py={15}
                    pl={20}
                    bt={1}
                    bb={1}
                    border="--neutral100"
                    background="white"
                >
                    <DeprecatedText weight="semibold">
                        <FormattedMessage defaultMessage="Variations" />
                    </DeprecatedText>
                </DeprecatedPaper>
            </DeprecatedPaper>
            <DeprecatedPaper w="auto" auto>
                {isLoading ? (
                    <VirtualList
                        height={165}
                        itemCount={10}
                        itemSize={115}
                        renderItem={({ index, style }) => (
                            <RankingVariationGraphTableColumnFallback key={index} style={style} />
                        )}
                        scrollDirection="horizontal"
                        width={size.width - 150}
                    />
                ) : (
                    <VirtualList
                        height={165}
                        itemCount={data.length}
                        itemSize={Math.max(115, (size.width - 150) / (data.length || 1))}
                        overscanCount={5}
                        renderItem={({ index, style }) => (
                            <RankingVariationGraphTableColumn
                                data={data[index]}
                                formatMatchingSelectedStep={formatMatchingSelectedStep}
                                hovered={(hoveredValue && hoveredValue.id) === data[index].id}
                                key={data[index].id}
                                onHover={onHover}
                                style={style}
                            />
                        )}
                        scrollDirection="horizontal"
                        scrollToAlignment="center"
                        scrollToIndex={scrollToValue && scrollToValue.id}
                        width={size.width - 150}
                    />
                )}
            </DeprecatedPaper>
        </DeprecatedWhitePaper>
    );
}

export default withSize()(enhance(RankingVariationGraphTable));
