import { useEffect, useState } from 'react';
import EpisodeAISuggestionsPanel from '../EpisodeAISuggestionsPanel';
import { FormattedMessage } from 'react-intl';
import { useQueryClient } from '@tanstack/react-query';
import aiKeys from '@/queries/ai/aiKeys';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router';
import { useEpisodeAILoading } from '@/components/NonHostingEpisodeForm/contexts/NonHostingEpisodeAILoadingContext';
import { useEpisodeAISettings } from '@/components/NonHostingEpisodeForm/contexts/NonHostingEpisodeAISettingsContext';
import { sendAmplitudeLogEvent } from '@/helpers';
import useAISocialPostsSuggestionsQuery from '@/queries/ai/useAISocialPostsSuggestionsQuery';
import { NonHostingEpisodeFormSchema } from '../../useNonHostingEpisodeFormSchema.hook';

interface EpisodeAIFacebookSuggestionPanelProps {
    setLoadingContent: (content: string) => void;
}

const EpisodeAIFacebookSuggestionPanel = ({
    setLoadingContent,
}: EpisodeAIFacebookSuggestionPanelProps) => {
    const { episodeId } = useParams<{ episodeId: string }>();
    const [settings] = useEpisodeAISettings();
    const smmSettings = settings.get('smm') || {
        customPrompt: '',
        saveAsDefault: false,
    };
    const smmSuggestionsQuery = useAISocialPostsSuggestionsQuery({
        episodeId,
        settings: smmSettings,
        socialNetworks: ['facebook', 'twitter', 'instagram', 'linkedin'],
        delay: 2000,
    });
    const facebookSuggestionsQuery = useAISocialPostsSuggestionsQuery({
        episodeId,
        settings: smmSettings,
        socialNetworks: ['facebook'],
        delay: 2000,
    });
    const [state] = useEpisodeAILoading();
    const isLoading = state.get('smm-facebook') || false;
    const queryClient = useQueryClient();
    const { setValue } = useFormContext<NonHostingEpisodeFormSchema>();
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (isLoading) {
            setIsOpen(true);
        }
    }, [isLoading]);

    useEffect(() => {
        // If the query is successful and the panel displayed, set the value of the form field
        if (facebookSuggestionsQuery.data && facebookSuggestionsQuery.isSuccess) {
            setLoadingContent(facebookSuggestionsQuery.data.suggestions.facebook || '');
            setValue('socialPosts.facebook', facebookSuggestionsQuery.data.suggestions.facebook, {
                shouldDirty: true,
            });
        }
    }, [facebookSuggestionsQuery.data, facebookSuggestionsQuery.isSuccess]);

    useEffect(() => {
        // If the query is successful and the panel displayed, set the value of the form field
        if (smmSuggestionsQuery.data && smmSuggestionsQuery.isSuccess) {
            setLoadingContent(smmSuggestionsQuery.data.suggestions.facebook || '');
            setValue('socialPosts.facebook', smmSuggestionsQuery.data.suggestions.facebook, {
                shouldDirty: true,
            });
        }
    }, [smmSuggestionsQuery.data, smmSuggestionsQuery.isSuccess]);

    if (!isOpen) return null;

    const handleClose = () => {
        // Reset the suggestions query when the panel is closed
        queryClient.resetQueries({
            queryKey: aiKeys.socialPostsDetails(episodeId, smmSettings, ['facebook']),
        });
        setIsOpen(false);
    };

    const handleDislike = () => {
        sendAmplitudeLogEvent('AI Dislike', {
            field: 'smm-facebook',
            custom_prompt: smmSettings.customPrompt || '',
            suggestions:
                smmSuggestionsQuery.data?.suggestions.facebook ||
                facebookSuggestionsQuery.data?.suggestions.facebook ||
                '',
        });
    };

    return (
        <EpisodeAISuggestionsPanel
            title={
                <FormattedMessage
                    defaultMessage="Votre post {socialNetwork} est prêt !"
                    values={{ socialNetwork: 'Facebook' }}
                />
            }
            type="smm-facebook"
            isLoading={isLoading}
            onClose={handleClose}
            data-testid="episode-ai-smm-facebook-suggestions-panel"
            onDislike={handleDislike}
        />
    );
};

export default EpisodeAIFacebookSuggestionPanel;
