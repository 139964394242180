import { useFormContext } from 'react-hook-form';
import { ClipFormSchema } from '../../schemas/useFormSchema';
import Alert from '@/components/ui/atoms/Alert';
import Text from '@/components/ui/atoms/Text';
import { FormattedMessage } from 'react-intl';
import { ToggleController } from '@/components/ui/atoms/Toggle';
import { ColorPickerController } from '@/components/ui/molecules/ColorPicker';
import { InputTextController } from '@/components/ui/atoms/InputText';
import styled from 'styled-components';

const Form = () => {
    const form = useFormContext<ClipFormSchema>();
    const { showWaveform, showTitles } = form.watch('customization');

    return (
        <Wrapper>
            <Alert variant="info" defaultIcon>
                <Text fontWeight="--fw-semibold">
                    <FormattedMessage defaultMessage="Les options de personnalisation s’appliquent à tous les formats de clips." />
                </Text>
            </Alert>
            <ToggleController name="customization.showWaveform" control={form.control}>
                <FormattedMessage defaultMessage="Afficher la waveform" />
            </ToggleController>
            <ColorPickerController
                control={form.control}
                name="customization.color"
                label={<FormattedMessage defaultMessage="Couleur de la waveform" />}
                isDisabled={!showWaveform}
            />
            <ToggleController name="customization.showTitles" control={form.control}>
                <FormattedMessage defaultMessage="Afficher les titres" />
            </ToggleController>
            <ColorPickerController
                control={form.control}
                name="customization.titleColor"
                label={<FormattedMessage defaultMessage="Couleur des titres" />}
                isDisabled={!showTitles}
            />
            <InputTextController
                name="customization.title"
                label={<FormattedMessage defaultMessage="Titre principal" />}
                control={form.control}
            />
            <InputTextController
                name="customization.caption"
                label={<FormattedMessage defaultMessage="Titre secondaire" />}
                control={form.control}
            />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`;

export default Form;
