import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { reflex } from '@ui/reflex';
import { resolveColor } from '@/shared/utils/cssVariable';

const raiseLookup = {
    normal: 'var(--s-light)',
    smooth: 'var(--s-medium)',
    hard: 'var(--s-heavy)',
};

/**
 * DEPRECATED
 *
 * Please use `ui/layout/Box` component instead.
 */
const DeprecatedPaper = styled.div.withConfig({
    shouldForwardProp: (p, defaultValidatorFn) =>
        !['z', 'x', 'y', 'radius', 'height', 'width', 'display'].includes(p) &&
        defaultValidatorFn(p),
})`
    text-decoration: none;
    ${(p) => p.ellipsis && 'min-width: 0'};
    position: ${(p) => p.position};
    overflow: ${(p) => p.overflowHide && 'hidden'};
    box-shadow: ${(p) => raiseLookup[p.raise]};

    ${(p) => p.onClick && 'cursor: pointer'};
    ${(p) => p.nowrap && 'white-space: nowrap'};

    ${(p) =>
        p.background &&
        css`
            background: ${(p) => resolveColor(p.background)};
        `}

    ${({ backgroundImage }) =>
        backgroundImage &&
        css`
            background-image: url(${backgroundImage});
            background-size: ${(p) => p.backgroundSize || 'cover'};
            background-position: center center;
            background-repeat: no-repeat;
        `};
    ${({ rounded, radLeft, radRight, radius }) =>
        (rounded || radius) &&
        css`
            border-radius: ${radLeft || radius ? radius || 5 : 0}px
                ${radRight || radius ? radius || 5 : 0}px ${radRight || radius ? radius || 5 : 0}px
                ${radLeft || radius ? radius || 5 : 0}px;
        `};
    &:hover {
        background: ${(props) => resolveColor(props.hover)};
    }
`;

DeprecatedPaper.propTypes = {
    z: PropTypes.number,
    x: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    y: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    w: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    radLeft: PropTypes.bool,
    radRight: PropTypes.bool,
    rounded: PropTypes.bool,
    raise: PropTypes.string,
    overflowHide: PropTypes.bool,
    background: PropTypes.string,
    backgroundImage: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    nowrap: PropTypes.bool,
};

DeprecatedPaper.defaultProps = {
    z: null,
    x: null,
    y: null,
    w: '100%',
    radLeft: true,
    radRight: true,
    rounded: false,
    raise: undefined,
    overflowHide: false,
    background: undefined,
    backgroundImage: undefined,
    height: undefined,
    nowrap: undefined,
};

export default reflex(DeprecatedPaper);
