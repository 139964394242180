import Model from './Model';

class NewsletterQuotaModel extends Model {
    static attributes = {
        maximum: 'maximum',
        minimum: 'minimum',
        price: 'price',
        step: 'step',
        total: 'total',
    };
}

export default NewsletterQuotaModel;
