import QuotaContentModal from '../organisms/QuotaContentModal';
import QuotaConfirmModal from '../organisms/QuotaConfirmModal';
import { useSwitch } from '@hooks/useSwitch';
import FetcherPrices from '../fetchers/FetcherPrices';

const QuotasModal = ({ type, openedQuotasModal, onCloseQuotasModal }) => {
    const [openedConfirmQuotasModal, onOpenConfirmQuotasModal, onCloseConfirmQuotasModal] =
        useSwitch(false);
    const switchModal = () => {
        onCloseQuotasModal();
        onOpenConfirmQuotasModal();
    };

    const CONTENT_MODAL = openedQuotasModal ? 'QUOTA_ADDED_MODAL' : 'QUOTA_CONFIRM_MODAL';

    const QUOTA_MODAL_STEP = {
        QUOTA_ADDED_MODAL: (
            <QuotaContentModal
                type={type}
                isOpened={openedQuotasModal}
                onClose={onCloseQuotasModal}
                onOpenConfirmQuotasModal={switchModal}
            />
        ),
        QUOTA_CONFIRM_MODAL: (
            <QuotaConfirmModal
                type={type}
                isOpened={openedConfirmQuotasModal}
                onClose={onCloseConfirmQuotasModal}
            />
        ),
    };

    return <FetcherPrices>{QUOTA_MODAL_STEP[CONTENT_MODAL]}</FetcherPrices>;
};

export default QuotasModal;
