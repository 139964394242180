import { useQuery } from '@tanstack/react-query';
import { psoRankings } from '@/api';
import camelcaseKeys from 'camelcase-keys';

const queryFn = async ({ queryKey: [{ showId, currentStore }] }) => {
    const { data } = await psoRankings(showId, currentStore);

    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const useRankingsQuery = ({ showId: rawShowId, currentStore }) => {
    return useQuery({
        queryKey: [{ entity: 'pso-stats-rankings', showId: Number(rawShowId), currentStore }],
        queryFn,
    });
};

export default useRankingsQuery;
