import ContentLoader from 'react-content-loader';
import { FormattedMessage } from 'react-intl';
import ApplePodcastsReviewsNotificationModal from './ApplePodcastsReviewsNotificationModal';
import bellIcon from '@public/icons/bell.svg';
import DeprecatedButton from '@ui/atoms/DeprecatedButton';
import createFallback from '@/components/unorganized/createFallback';
import FetcherApplePodcastsReviews from '@/components/unorganized/FetcherApplePodcastsReviews';
import DeprecatedIcon from '@ui/atoms/DeprecatedIcon';
import Pagination from '@ui/molecules/Pagination';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import ReviewsList from './ReviewsList';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import { connect } from '@/components/legacy/connect';
import { useOverlayTriggerState } from 'react-stately';

const ReviewsCountFallback = createFallback((props) => (
    <ContentLoader width={100} height={20} style={{ width: 100, height: 20 }} {...props}>
        <rect x="0" y="0" rx="5" ry="5" width="100" height="14" />
    </ContentLoader>
));

const enhance = connect(({ routerStore, applePodcastsStatsStore }) => ({
    showId: routerStore.params.showId,
    reviews: applePodcastsStatsStore.applePodcastsReviews,
    pagination: applePodcastsStatsStore.applePodcastsReviewsPagination,
}));

function ApplePodcastsReviews({ showId, reviews, pagination }) {
    let ApplePodcastsReviewsNotificationModalState = useOverlayTriggerState({});
    return (
        <>
            <FetcherApplePodcastsReviews showId={showId} page={pagination.currentPage}>
                {(isLoading) => (
                    <>
                        {ApplePodcastsReviewsNotificationModalState.isOpen && (
                            <ApplePodcastsReviewsNotificationModal
                                isOpen={ApplePodcastsReviewsNotificationModalState.isOpen}
                                onClose={ApplePodcastsReviewsNotificationModalState.close}
                            />
                        )}

                        <DeprecatedPaper flex align="center" wrap="wrap" mt={15} mb={20}>
                            <DeprecatedPaper flex column auto w="auto" mt={20} mr={20}>
                                <DeprecatedText mb={5} size="veryBig" weight="bold">
                                    <FormattedMessage defaultMessage="Avis des utilisateurs" />
                                </DeprecatedText>
                                {isLoading ? (
                                    <ReviewsCountFallback />
                                ) : (
                                    <DeprecatedText>
                                        <FormattedMessage
                                            defaultMessage={`{total, plural, =0 {Aucun avis} one {# avis} other {# avis}}`}
                                            values={{ total: pagination.total || 0 }}
                                        />
                                    </DeprecatedText>
                                )}
                            </DeprecatedPaper>
                            <DeprecatedButton
                                onClick={ApplePodcastsReviewsNotificationModalState.open}
                                mt={20}
                                background="secondary"
                            >
                                <DeprecatedIcon
                                    as={bellIcon}
                                    height={13}
                                    width={15}
                                    style={{ flexShrink: 0 }}
                                />
                                <FormattedMessage defaultMessage="Paramètres des alertes" />
                            </DeprecatedButton>
                        </DeprecatedPaper>

                        <ReviewsList reviews={reviews} isLoading={isLoading} />

                        {!isLoading && (
                            <Pagination
                                my={25}
                                pagination={pagination}
                                plural={
                                    <FormattedMessage
                                        defaultMessage={`{total, plural, one {avis} other {avis}}`}
                                        values={{ total: pagination.total }}
                                    />
                                }
                                singular={
                                    <FormattedMessage
                                        defaultMessage={`{total, plural, one {avis} other {avis}}`}
                                        values={{ total: pagination.total }}
                                    />
                                }
                            />
                        )}
                    </>
                )}
            </FetcherApplePodcastsReviews>
        </>
    );
}

export default enhance(ApplePodcastsReviews);
