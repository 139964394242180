import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import Alert from '@ui/atoms/Alert';
// import Button from '@ui/atoms/Button';
import RouterLink from '@/components/Link/RouterLink';
import Text from '@ui/atoms/Text';
import dayjs from 'dayjs';
import styled from 'styled-components';

interface closedAtRecord {
    showId: string;
    closedAt: number;
}
export interface SuggestedKeywordsAlertProps {
    onClose: () => void;
    indexedKeywordsCount: number;
}

const SuggestedKeywordsAlert = ({ onClose, indexedKeywordsCount }: SuggestedKeywordsAlertProps) => {
    const { showId }: { showId: string } = useParams();
    const handleClose = (): void => {
        // Pull the records from local storage & parse them into an array
        const rawClosedAtRecords =
            window.localStorage.getItem('psoSuggestedKeywordsAlertClosedAt') || '[]';
        const closedAtRecords = JSON.parse(rawClosedAtRecords);

        const newClosedAtRecords = [
            ...closedAtRecords.filter((record: closedAtRecord) => record.showId !== showId),
            {
                showId,
                closedAt: dayjs().unix(),
            },
        ];

        // Write local storage variable with current timestamp
        window.localStorage.setItem(
            'psoSuggestedKeywordsAlertClosedAt',
            JSON.stringify(newClosedAtRecords),
        );
        onClose();
    };

    return (
        <Alert
            variant="robot"
            description={
                // @ts-ignore
                <Text>
                    <FormattedMessage
                        defaultMessage="{keywordCount, plural, one {J'ai identifié que votre podcast apparait dans les résultats de recherche pour # mot-clé dont vous ne suivez pas encore l'évolution.} other {J'ai identifié que votre podcast apparait dans les résultats de recherche pour # mots-clés dont vous ne suivez pas encore l'évolution.}}"
                        values={{
                            keywordCount: Math.min(indexedKeywordsCount, 20),
                        }}
                    />
                </Text>
            }
            closable
            onClose={handleClose}
            action={
                <ActionButtonWrapper>
                    <RouterLink to={`/app/show/${showId}/pso/keywords`} variant="button-primary">
                        <FormattedMessage defaultMessage="Ajouter les nouveaux mot-clés" />
                    </RouterLink>

                    {/* @ts-ignore */}
                    {/* <Button size="small" background="--primary" onPress={onEditKeywords}>
                        <FormattedMessage defaultMessage="Ajouter les nouveaux mot-clés" />
                    </Button> */}
                </ActionButtonWrapper>
            }
        >
            {/* @ts-ignore */}
            <Text fontWeight="--fw-bold">
                <FormattedMessage defaultMessage="Pssst... Votre émission est référencée sur des mots-clés qui sont absents de votre liste. 😉" />
            </Text>
        </Alert>
    );
};

const ActionButtonWrapper = styled.div`
    padding-block: 0.75rem;
    display: flex;
    align-items: center;
`;

export default SuggestedKeywordsAlert;
