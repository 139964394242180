import Link from '../Link';
import DeprecatedText from '../DeprecatedText';

const MobileTabsItem = ({ to, children, isSelected, item, onClick }) => (
    <DeprecatedText as="li" py={10}>
        <DeprecatedText
            size="big"
            as={Link}
            to={to}
            color={isSelected ? '--neutral500' : '--black'}
            key={item}
            onClick={onClick}
        >
            {children}
        </DeprecatedText>
    </DeprecatedText>
);

export default MobileTabsItem;
