import { useContext } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import CheckContext from './CheckContext';
import CheckIcon from './CheckIcon';
import { extractCssVariable } from '@/shared/utils/cssVariable';

const Container = styled.span`
    display: flex;
    gap: 0.5rem;
    align-items: flex-start;
`;

const Label = styled(motion.span)`
    display: block;
    font-size: var(--fs-body);
`;

const CheckLabel = ({ children, ...otherProps }) => {
    const { isCompleted, isLoading } = useContext(CheckContext);

    return (
        <Container {...otherProps}>
            <CheckIcon isCompleted={isCompleted} isLoading={isLoading} />
            {children ? (
                <Label
                    initial={false}
                    animate={{
                        color:
                            isLoading && !isCompleted
                                ? extractCssVariable('--info')
                                : extractCssVariable('--black'),
                    }}
                >
                    {children}
                </Label>
            ) : null}
        </Container>
    );
};

export default CheckLabel;
