import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useMeter } from 'react-aria';

const Meter = (props) => {
    const {
        value,
        minValue,
        maxValue,
        lowPercentValue,
        highPercentValue,
        lowRangeColor,
        mediumRangeColor,
        highRangeColor,
        height,
        width,
        color,
        bgColor,
    } = props;
    const { meterProps } = useMeter(props);

    // Calculate the width of the progress bar as a percentage
    const percentage = (value - minValue) / (maxValue - minValue);
    const barWidth = `${Math.max(0, Math.round(percentage * 100))}%`;
    const computedColor =
        color ||
        (percentage <= lowPercentValue
            ? lowRangeColor
            : percentage >= highPercentValue
            ? highRangeColor
            : mediumRangeColor);

    return (
        <div {...meterProps}>
            <DefaultBar bgColor={bgColor} height={height} width={width}>
                <ProgressBar color={computedColor} width={barWidth} />
            </DefaultBar>
        </div>
    );
};

const DefaultBar = styled.div`
    height: ${(p) => p.height};
    width: ${(p) => p.width};
    overflow: hidden;
    background-color: var(--neutral100);
    border-radius: var(--r-full);

    ${({ bgColor }) => bgColor && `background-color: var(${bgColor});`}
`;
const ProgressBar = styled.div`
    height: 100%;
    width: ${(p) => p.width};
    background-color: ${(p) => `var(${p.color})`};
    border-radius: var(--r-full);
`;

Meter.propTypes = {
    value: PropTypes.number.isRequired, // The meter's current value.
    minValue: PropTypes.number, // The meter's minimum value.
    maxValue: PropTypes.number, // The meter's maximum value.
    lowPercentValue: PropTypes.number, // The maximum percent value to consider value to be low.
    highPercentValue: PropTypes.number, // The minimum percent value to consider value to be high.
    lowRangeColor: PropTypes.string, // The color associated to the low range.
    mediumRangeColor: PropTypes.string, // The color associated to the medium range.
    highRangeColor: PropTypes.string, // The color associated to the high range.
    height: PropTypes.string, // The height of the meter bar.
    width: PropTypes.string, // The width of the meter bar.
    color: PropTypes.string, // The color of the meter bar.
    bgColor: PropTypes.string, // The background color of the meter bar.
};

Meter.defaultProps = {
    minValue: 0,
    maxValue: 100,
    lowPercentValue: 0.75,
    highPercentValue: 1.0,
    lowRangeColor: '--success',
    mediumRangeColor: '--warning',
    highRangeColor: '--alert',
    height: '0.5rem',
    width: 'auto',
};

export default Meter;
