import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchImportedPodcasts } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import rssKeys from './rssKeys';
import { z } from 'zod';

export interface useImportedEpisodesQueryProps {
    showId: string;
    pagination?: {
        page: number;
        perPage: number;
    };
    state?: 'pending' | 'processing' | 'done' | 'error';
    config?: any;
}

export const ImportedEpisodeSchema = z.object({
    id: z.number(),
    publishedAt: z.string(),
    state: z.enum(['pending', 'processing', 'done', 'error']),
    title: z.string(),
});
const ImportedEpisodesSchema = z.array(ImportedEpisodeSchema);
const EpisodePaginationSchema = z.object({
    currentPage: z.number(),
    totalPages: z.number(),
    total: z.number(),
    perPage: z.number(),
    count: z.number(),
});
const CountersSchema = z.object({
    pending: z.number(),
    processing: z.number(),
    done: z.number(),
    error: z.number(),
    total: z.number(),
});
const ImportedEpisodeResponseSchema = z.object({
    importedEpisodes: ImportedEpisodesSchema,
    pagination: EpisodePaginationSchema,
    counters: CountersSchema,
});

export type ImportedEpisode = z.infer<typeof ImportedEpisodeSchema>;

type Key = QueryFunctionContext<ReturnType<typeof rssKeys.episodes>>;

const queryFn = async ({ queryKey: [{ showId, state, page, perPage }] }: Key) => {
    const { data, meta } = await fetchImportedPodcasts(showId, { state, page, per_page: perPage });

    const { data: camelcaseData, meta: camelcaseMeta } = camelcaseKeys(
        { data, meta },
        { deep: true },
    );

    const formattedData = {
        importedEpisodes: camelcaseData,
        pagination: {
            currentPage: camelcaseMeta?.pagination?.currentPage || 0,
            totalPages: camelcaseMeta?.pagination?.totalPages || 0,
            total: camelcaseMeta?.pagination?.total || 0,
            perPage: camelcaseMeta?.pagination?.perPage || 0,
            count: camelcaseMeta?.pagination?.count || 0,
        },
        counters: camelcaseMeta?.counters,
    };

    return ImportedEpisodeResponseSchema.parse(formattedData);
};

const useImportedEpisodesQuery = ({
    showId,
    pagination: { page, perPage } = { page: 1, perPage: 20 },
    state,
    config,
}: useImportedEpisodesQueryProps) => {
    return useQuery({
        queryKey: rssKeys.episodes(showId, state, page, perPage),
        queryFn,
        enabled: !!showId, //config?.enabled === false ?? !!showId,
        refetchOnWindowFocus: config?.refetchOnWindowFocus ?? false,
        refetchInterval: config?.refetchInterval ?? false,
    });
};

export default useImportedEpisodesQuery;
