import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchShowStats } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import statsKeys from './statsKeys';
import { z } from 'zod';

export const StatsSchema = z.object({
    sumDownloads: z.number(),
    mapData: z.array(
        z.object({
            at: z.number(),
            downloads: z.number(),
            variation: z.number().nullable(),
        }),
    ),
});
export const StatsMetaSchema = z.object({
    step: z.string(),
});
const StatsResponseSchema = z.object({
    data: StatsSchema,
    meta: StatsMetaSchema,
});
export type Stats = z.infer<typeof StatsSchema>;
type Key = QueryFunctionContext<ReturnType<typeof statsKeys.listenersStats>>;
type InputProps = {
    showId: string;
    timezone: string;
    episodes: string[];
    start: string;
    end: string;
    precision: string;
    options?: {
        enabled?: boolean;
    };
};

const queryFn = async ({
    queryKey: [{ showId, timezone, episodes, start, end, precision }],
}: Key) => {
    const response = await fetchShowStats(showId, {
        timezone,
        podcasts: episodes,
        start,
        end,
        step: precision,
    });
    return StatsResponseSchema.parse(camelcaseKeys(response, { deep: true }));
};

const useListenersStatsQuery = ({
    showId,
    timezone,
    episodes,
    start,
    end,
    precision,
    options,
}: InputProps) => {
    return useQuery({
        queryKey: statsKeys.listenersStats(showId, timezone, episodes, start, end, precision),
        queryFn,
        enabled: options?.enabled || true,
    });
};

export default useListenersStatsQuery;
