import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import PsoAverageRankingGraph from './PsoAverageRankingGraph';
import useRankingsQuery from './useRankingsQuery.hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import UiKitTooltip from '@ui/atoms/UiKitTooltip';
import { APPLE_PODCASTS, SPOTIFY } from '@/utils/constants';
import LegendItem from './LegendItem';
import Text from '@ui/atoms/Text';
import { FormattedMessage } from 'react-intl';
import PsoAverageRankingSkeleton from './PsoAverageRankingSkeleton';
import styled from 'styled-components';

const PsoAverageRanking = ({ daysRemaining, currentStore, hasSpotifyData, hasAppleData }) => {
    const { showId } = useParams();
    const keywordRanking = useRankingsQuery({ showId, currentStore });

    if (keywordRanking.isLoading || keywordRanking.isError) return <PsoAverageRankingSkeleton />;
    return (
        <AverageRankingWrapper>
            <TitleWrapper>
                <Title>
                    <FormattedMessage defaultMessage="Position moyenne" />
                </Title>
                <UiKitTooltip
                    variant="dark"
                    content={
                        <FormattedMessage defaultMessage="L’évolution de la position moyenne de votre podcast par plateforme d’écoute sur les 12 dernières semaines" />
                    }
                    position="top"
                >
                    <HelpIcon icon={icon({ name: 'circle-info', style: 'solid' })} />
                </UiKitTooltip>
            </TitleWrapper>
            <Legend>
                {hasSpotifyData && (
                    <LegendItem
                        platform={SPOTIFY}
                        position={Math.round(keywordRanking.data.spotifyAverage)}
                        trend={keywordRanking.data.spotifyTrend}
                        rankDiff={Math.round(keywordRanking.data.spotifyRankDiff)}
                    />
                )}
                {hasAppleData && (
                    <LegendItem
                        platform={APPLE_PODCASTS}
                        position={Math.round(keywordRanking.data.applePodcastsAverage)}
                        trend={keywordRanking.data.applePodcastsTrend}
                        rankDiff={Math.round(keywordRanking.data.applePodcastsRankDiff)}
                    />
                )}
            </Legend>
            <PsoAverageRankingGraph
                dates={keywordRanking.data.dates}
                dataSpotify={keywordRanking.data.spotify}
                dataApple={keywordRanking.data.applePodcasts}
                daysRemaining={daysRemaining}
                globalAverage={keywordRanking.data.global}
                hasSpotifyData={hasSpotifyData}
                hasAppleData={hasAppleData}
            />
        </AverageRankingWrapper>
    );
};

const AverageRankingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1.5rem;
`;
const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
`;
const Title = styled(Text)`
    font-weight: var(--fw-bold);
    font-size: var(--fs-body-m);
`;
const HelpIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
`;
const Legend = styled.div`
    display: flex;
    align-items: center;
    gap: 2rem;
`;

PsoAverageRanking.propTypes = {
    daysRemaining: PropTypes.number,
    currentStore: PropTypes.string,
    hasSpotifyData: PropTypes.bool,
    hasAppleData: PropTypes.bool,
};

export default PsoAverageRanking;
