import { useMutation } from '@tanstack/react-query';
import { uploadUserAvatar } from '@/api';
import camelcaseKeys from 'camelcase-keys';

const transform = (data: unknown) => {
    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data as any, { deep: true });
};

const mutationFn = async (file: File) => {
    const { data } = await uploadUserAvatar(file);
    return transform(data);
};

const uploadUserAvatarMutation = () => {
    return useMutation({
        mutationFn,
        onSuccess: () => {
            // Queries invalidation is done in the saveProfileMutation
        },
    });
};

export default uploadUserAvatarMutation;
