import { fetchSponsorshipCode } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import { useQuery } from '@tanstack/react-query';
import sponsorshipCodeKeys from '@/queries/sponsorshipCode/sponsorshipCodeKeys';

const queryFn = async () => {
    const { data } = await fetchSponsorshipCode();

    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const useSponsorshipCodeQuery = () => {
    return useQuery({
        queryKey: sponsorshipCodeKeys.all(),
        queryFn,
    });
};

export default useSponsorshipCodeQuery;
