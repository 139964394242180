import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import Checkbox from '@/components/ui/Checkbox';
import { useEpisodeSelectContext } from '@/context/EpisodeSelectContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Button from '@/components/Button';
import { useParams } from 'react-router';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import { USER_ROLES } from '@/shared/config/constants';
import MoveEpisodesModal from '@/components/unorganized/MoveEpisodesModal';
import { useSwitch } from '@/shared/hooks/useSwitch';
import useDeleteAllEpisodesMutation from '@/queries/episode/useDeleteAllEpisodesMutation.hook';
import useMoveAllEpisodesMutation from '@/queries/episode/useMoveAllEpisodesMutation.hook';
import useAssignAllEpisodesToSeasonMutation from '@/queries/episode/useAssignAllEpisodesToSeasonMutation.hook';
import styled from 'styled-components';
import useDeleteMultipleEpisodesMutation from '@/queries/episode/useDeleteMultipleEpisodesMutation.hook';
import useMoveMultipleEpisodesMutation from '@/queries/episode/useMoveMultipleEpisodesMutation.hook';
import useAssignMultipleEpisodesToSeasonMutation from '@/queries/episode/useAssignMultipleEpisodesToSeasonMutation.hook';
import { useModalToastQueue } from '@/shared/hooks/useModalToastQueue.hook';
import CategorizeEpisodesModal from './CategorizeEpisodesModal';
import DeleteEpisodesModal from './DeleteEpisodesModal';

interface SelectionToolbarProps {
    hasSeveralPages: boolean;
    totalEpisodeCount: number;
    episodesPerPage: number;
}

const SelectionToolbar = ({
    hasSeveralPages,
    totalEpisodeCount,
    episodesPerPage,
}: SelectionToolbarProps) => {
    const { showId } = useParams<{ showId: string }>();
    const { data: show } = useShowQuery(showId);
    const toast = useModalToastQueue();
    const { allSelectedOnPage, allSelected, toggleAllOnPage, selected, toggleAll, resetSelection } =
        useEpisodeSelectContext();
    const [episodeCount, setEpisodeCount] = useState(selected?.length || 0);
    const [hasSelectedAtLeastOne, setHasSelectedAtLeastOne] = useState(
        allSelected || selected.length > 0,
    );

    useEffect(() => {
        if (allSelected) {
            setEpisodeCount(totalEpisodeCount);
            return;
        }
        if (allSelectedOnPage) {
            setEpisodeCount(episodesPerPage);
            return;
        }
        setEpisodeCount(selected?.length || 0);
    }, [selected, allSelected, allSelectedOnPage]);

    useEffect(() => {
        if (!allSelected && !allSelectedOnPage && selected.length === 0) {
            setHasSelectedAtLeastOne(false);
            return;
        }
        setHasSelectedAtLeastOne(true);
    }, [selected, allSelected, allSelectedOnPage]);

    const deleteMultipleEpisodes = useDeleteMultipleEpisodesMutation();
    const deleteAllEpisodes = useDeleteAllEpisodesMutation();
    const moveMultipleEpisodes = useMoveMultipleEpisodesMutation();
    const moveAllEpisodes = useMoveAllEpisodesMutation();
    const assignMultipleEpisodesToSeason = useAssignMultipleEpisodesToSeasonMutation();
    const assignAllEpisodesToSeason = useAssignAllEpisodesToSeasonMutation();

    const [moveOpened, openMove, closeMove] = useSwitch(false);
    const [isCategorizeModalOpen, setIsCategorizeModalOpen] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

    const userRole = show?.userRole;
    const userHasWriteAccess = ![USER_ROLES.VIEWER, USER_ROLES.EDITOR].includes(
        userRole as 'viewer' | 'editor',
    );
    const showIsArchived = show?.archived;

    if (userRole === USER_ROLES.VIEWER) return null;

    const handleDeleteEpisodes = () => {
        if (allSelected) {
            deleteAllEpisodes.mutate(
                { showId },
                {
                    onSuccess: () => {
                        setDeleteModalOpen(false);
                        resetSelection();
                        toast.success(
                            <FormattedMessage defaultMessage="Tous les épisodes ont bien été supprimés." />,
                        );
                    },
                    onError: () => {
                        toast.alert();
                    },
                },
            );

            return;
        }

        if (!selected || selected.length === 0) return;

        deleteMultipleEpisodes.mutate(
            { episodeIds: selected },
            {
                onSuccess: () => {
                    setDeleteModalOpen(false);
                    resetSelection();
                    toast.success(
                        <FormattedMessage
                            defaultMessage="{episodeCount, plural, one {L'épisode a bien été supprimé} other {Les épisodes ont bien été supprimés}}."
                            values={{ episodeCount }}
                        />,
                    );
                },
                onError: () => {
                    toast.alert();
                },
            },
        );
    };
    return (
        <>
            <SelectionToolbarWrapper>
                <SelectCheckbox
                    id="selectAll"
                    $hasSelectedAtLeastOne={hasSelectedAtLeastOne}
                    isSelected={allSelected || allSelectedOnPage}
                    onChange={toggleAllOnPage}
                />
                {hasSelectedAtLeastOne ? (
                    <ActionWrapper>
                        {userHasWriteAccess && (
                            <ActionButton
                                variant="ghost"
                                onPress={openMove}
                                startIcon={
                                    moveAllEpisodes.isLoading || moveMultipleEpisodes.isLoading ? (
                                        <FontAwesomeIcon
                                            icon={icon({
                                                name: 'spinner-third',
                                                style: 'solid',
                                            })}
                                            spin
                                        />
                                    ) : (
                                        <FontAwesomeIcon
                                            icon={icon({
                                                name: 'arrow-turn-left-up',
                                                style: 'solid',
                                            })}
                                        />
                                    )
                                }
                            >
                                <FormattedMessage defaultMessage="Déplacer" />
                            </ActionButton>
                        )}
                        {!showIsArchived && (
                            <ActionButton
                                variant="ghost"
                                onPress={() => setIsCategorizeModalOpen(true)}
                                startIcon={
                                    <FontAwesomeIcon
                                        icon={icon({ name: 'album-collection', style: 'solid' })}
                                    />
                                }
                            >
                                <FormattedMessage defaultMessage="Catégoriser" />
                            </ActionButton>
                        )}
                        {userHasWriteAccess && (
                            <DestructiveActionButton
                                variant="ghost"
                                onPress={() => setDeleteModalOpen(true)}
                                startIcon={
                                    deleteAllEpisodes.isLoading ||
                                    deleteMultipleEpisodes.isLoading ? (
                                        <FontAwesomeIcon
                                            icon={icon({ name: 'spinner-third', style: 'solid' })}
                                            spin
                                        />
                                    ) : (
                                        <FontAwesomeIcon
                                            icon={icon({ name: 'trash', style: 'solid' })}
                                        />
                                    )
                                }
                            >
                                <FormattedMessage defaultMessage="Supprimer" />
                            </DestructiveActionButton>
                        )}
                    </ActionWrapper>
                ) : (
                    <Label htmlFor="selectAll">
                        <FormattedMessage defaultMessage="Tout sélectionner" />
                    </Label>
                )}
                {hasSeveralPages &&
                    (allSelectedOnPage || allSelected) &&
                    (allSelected ? (
                        <ToggleAllOuterWrapper>
                            <ToggleAllWrapper>
                                <FormattedMessage
                                    defaultMessage="{count, plural, one {L’{countText} de votre émission est sélectionné} other {Les {countText} de votre émission sont sélectionnés}}."
                                    values={{
                                        countText: (
                                            <strong>
                                                <FormattedMessage
                                                    defaultMessage="{count, plural, one {épisode} other {# épisodes}}"
                                                    values={{ count: totalEpisodeCount }}
                                                />
                                            </strong>
                                        ),
                                        count: totalEpisodeCount,
                                    }}
                                />
                            </ToggleAllWrapper>
                            <ToggleButton variant="ghost" onPress={toggleAll}>
                                <FormattedMessage defaultMessage="Réinitialiser la sélection" />
                            </ToggleButton>
                        </ToggleAllOuterWrapper>
                    ) : (
                        <ToggleAllOuterWrapper>
                            <ToggleAllWrapper>
                                <FormattedMessage
                                    defaultMessage="{count, plural, one {L’{countText} de la page est sélectionné} other {Les {countText} de la page sont sélectionnés}}."
                                    values={{
                                        countText: (
                                            <strong>
                                                <FormattedMessage
                                                    defaultMessage="{count, plural, one {épisode} other {# épisodes}}"
                                                    values={{ count: episodesPerPage }}
                                                />
                                            </strong>
                                        ),
                                        count: episodesPerPage,
                                    }}
                                />
                            </ToggleAllWrapper>
                            <ToggleButton variant="ghost" onPress={toggleAll}>
                                <FormattedMessage
                                    defaultMessage="Sélectionner {count, plural, one {l'épisode} other {les # épisodes}} de l'émission"
                                    values={{ count: totalEpisodeCount }}
                                />
                            </ToggleButton>
                        </ToggleAllOuterWrapper>
                    ))}
            </SelectionToolbarWrapper>
            <CategorizeEpisodesModal
                isOpen={isCategorizeModalOpen}
                onOpenChange={setIsCategorizeModalOpen}
                onAssign={(newSeasonId: string) => {
                    const formattedSeasonId = newSeasonId === 'none' ? null : newSeasonId;

                    if (allSelected) {
                        assignAllEpisodesToSeason.mutate(
                            { showId, newSeasonId: formattedSeasonId },
                            {
                                onSettled: () => {
                                    setIsCategorizeModalOpen(false);
                                    resetSelection();
                                    toast.success(
                                        <FormattedMessage defaultMessage="Tous les épisodes ont bien été catégorisés." />,
                                    );
                                },
                            },
                        );

                        return;
                    }

                    assignMultipleEpisodesToSeason.mutate(
                        { episodeIds: selected, newSeasonId: formattedSeasonId },
                        {
                            onSettled: () => {
                                setIsCategorizeModalOpen(false);
                                resetSelection();
                                toast.success(
                                    <FormattedMessage
                                        defaultMessage="{episodeCount, plural, one {L'épisode a bien été catégorisé} other {Les épisodes ont bien été catégorisés}}."
                                        values={{ episodeCount }}
                                    />,
                                );
                            },
                        },
                    );
                }}
                isLoading={
                    assignAllEpisodesToSeason.isLoading || assignMultipleEpisodesToSeason.isLoading
                }
            />
            <MoveEpisodesModal
                isOpened={moveOpened}
                onClose={closeMove}
                count={episodeCount}
                onMove={(newShowId: string) => {
                    if (allSelected) {
                        moveAllEpisodes.mutate(
                            { showId, newShowId },
                            {
                                onSettled: () => {
                                    closeMove();
                                    resetSelection();
                                    toast.success(
                                        <FormattedMessage defaultMessage="Tous les épisodes ont bien été déplacés." />,
                                    );
                                },
                            },
                        );

                        return;
                    }

                    if (!selected || selected.length === 0) return;

                    moveMultipleEpisodes.mutate(
                        { episodeIds: selected, newShowId },
                        {
                            onSettled: () => {
                                setDeleteModalOpen(false);
                                resetSelection();
                                toast.success(
                                    <FormattedMessage
                                        defaultMessage="{episodeCount, plural, one {L'épisode a bien été déplacé} other {Les épisodes ont bien été déplacés}}."
                                        values={{ episodeCount }}
                                    />,
                                );
                            },
                        },
                    );
                }}
                moving={moveAllEpisodes.isLoading || moveMultipleEpisodes.isLoading}
            />
            <DeleteEpisodesModal
                isOpen={isDeleteModalOpen}
                onOpenChange={setDeleteModalOpen}
                isLoading={deleteAllEpisodes.isLoading || deleteMultipleEpisodes.isLoading}
                onDelete={handleDeleteEpisodes}
            />
        </>
    );
};

const SelectionToolbarWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.75rem;
    min-height: 1.5rem;
    flex-wrap: wrap;
    padding-inline: 1.5rem;
`;
const SelectCheckbox = styled(Checkbox)<{ $hasSelectedAtLeastOne: boolean }>``;
const ActionWrapper = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
`;
const ActionButton = styled(Button)`
    color: var(--primary);
    padding: 0;
`;
const DestructiveActionButton = styled(ActionButton)`
    color: var(--alert);
`;
const ToggleAllWrapper = styled.span``;
const ToggleAllOuterWrapper = styled.div`
    display: flex;
    gap: 0.5rem;
    align-items: center;

    flex-direction: column;

    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        flex-direction: row;
    }
`;
const ToggleButton = styled(Button)`
    padding: 0;
`;
const Label = styled.label`
    color: var(--neutral500);
`;

export default SelectionToolbar;
