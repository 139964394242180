import { useState } from 'react';
import { ListeningPlatform } from '@/utils/constants';
import { useIntl, FormattedMessage } from 'react-intl';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import { LISTENING_PLATFORMS_NAMES, BROADCAST_PLATFORMS_GUIDE_LINKS } from '@/utils/constants';
import PlatformIcon from '@app/atoms/PlatformIcon';
import InputText from '@ui/atoms/InputText';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';
import { TooltipTrigger } from 'react-aria-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';

interface ListeningLinkInputProps {
    index: number;
    platformKey: ListeningPlatform;
    listeningLinks: Record<ListeningPlatform, string>;
    setListeningLinks: (listeningLinks: Record<ListeningPlatform, string>) => void;
}

const ListeningLinkInput = ({
    index,
    platformKey,
    listeningLinks,
    setListeningLinks,
}: ListeningLinkInputProps) => {
    const intl = useIntl();
    const { data: user } = useUserQuery();

    const [urlInputState, setUrlInputState] = useState<'valid' | 'errors'>(
        '' as 'valid' | 'errors',
    );

    const supportedUserLanguage = ['en', 'fr'].includes(user?.language as string)
        ? user?.language
        : 'en';
    const guideLinksForPlatform =
        BROADCAST_PLATFORMS_GUIDE_LINKS[
            platformKey as keyof typeof BROADCAST_PLATFORMS_GUIDE_LINKS
        ];
    const guideLink = guideLinksForPlatform
        ? guideLinksForPlatform[supportedUserLanguage as keyof typeof guideLinksForPlatform]
        : null;
    const platformName = LISTENING_PLATFORMS_NAMES[platformKey];

    const checkUrl = (value: string): boolean => {
        const expression =
            /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
        const urlRegex = new RegExp(expression);
        return urlRegex.test(value);
    };
    const onChangeListeningLink = (value: string, platformKey: ListeningPlatform) => {
        const newListeningLinks = { ...listeningLinks, [platformKey]: value };
        setListeningLinks(newListeningLinks);
    };
    const handleChange = (value: string) => {
        setUrlInputState(checkUrl(value) ? 'valid' : 'errors');
        onChangeListeningLink(value, platformKey);
    };

    return (
        <InputWrapper $hasErrors={urlInputState === 'errors'}>
            <PlatformIconWrapper>
                <PlatformIcon platform={platformKey} />
            </PlatformIconWrapper>
            <InputText
                autoFocus={index === 0}
                label={platformName}
                tooltip={
                    guideLink && (
                        <TooltipTrigger delay={0} closeDelay={0} isDisabled={!guideLink}>
                            <TooltipTriggerWrapper>
                                <HelpIcon icon={icon({ name: 'info-circle', style: 'solid' })} />
                            </TooltipTriggerWrapper>
                            <Tooltip placement="top">
                                <FormattedMessage
                                    defaultMessage="Vous ne trouvez pas votre lien d’écoute {broadcastName} ? {consultGuide}"
                                    values={{
                                        broadcastName: platformName,
                                        consultGuide: (
                                            <WhiteLink href={guideLink} target="_blank">
                                                <FormattedMessage defaultMessage="Accédez à notre guide" />
                                            </WhiteLink>
                                        ),
                                    }}
                                />
                            </Tooltip>
                        </TooltipTrigger>
                    )
                }
                value={listeningLinks[platformKey]}
                onChange={(value) => handleChange(value)}
                placeholder={intl.formatMessage(
                    { defaultMessage: "Lien d'écoute {platform}" },
                    { platform: LISTENING_PLATFORMS_NAMES[platformKey] },
                )}
                inputState={urlInputState}
                // @ts-ignore
                errorMessage={
                    urlInputState === 'errors' ? (
                        <FormattedMessage defaultMessage="Votre saisie ne correspond pas à une URL valide" />
                    ) : null
                }
            />
        </InputWrapper>
    );
};

const InputWrapper = styled.div<{ $hasErrors: boolean }>`
    display: flex;
    align-items: ${({ $hasErrors }) => ($hasErrors ? 'center' : 'end')};
    gap: 0.5rem;

    & > :last-child {
        flex: 1;
    }
`;
const HelpIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
`;
const PlatformIconWrapper = styled.div`
    padding: 0.5rem;
    border-radius: var(--r-xs);
    background-color: var(--neutral50);

    & > img {
        display: block;
        width: 1.5rem;
        height: 1.5rem;
    }
`;
const WhiteLink = styled.a`
    color: inherit;
    font-weight: inherit;
    font-size: inherit;
    text-decoration: underline;
`;

export default ListeningLinkInput;
