import Text from '@/components/ui/atoms/Text';
import { CHANNEL_GUEST_USER_ROLES, ChannelGuestRole } from '@/utils/constants';
import { FormattedMessage, defineMessages } from 'react-intl';

interface ChannelGuestsRoleCellProps {
    role: ChannelGuestRole;
}

const roleLabels = defineMessages({
    [CHANNEL_GUEST_USER_ROLES.ADMIN]: {
        defaultMessage: 'Administrateur',
    },
    [CHANNEL_GUEST_USER_ROLES.EDITOR]: {
        defaultMessage: 'Éditeur',
    },
    [CHANNEL_GUEST_USER_ROLES.VIEWER]: {
        defaultMessage: 'Lecteur',
    },
    [CHANNEL_GUEST_USER_ROLES.MIXED]: {
        defaultMessage: 'Mixte',
    },
});

const ChannelGuestsRoleCell = ({ role }: ChannelGuestsRoleCellProps) => {
    return (
        <Text>
            <FormattedMessage {...roleLabels[role]} />
        </Text>
    );
};

export default ChannelGuestsRoleCell;
