import { GUEST_USER_ROLES } from '@/shared/config/constants';
import { defineMessages, useIntl } from 'react-intl';
import ChannelGuestsRoleSelect from '../ChannelGuestsRoleSelect';
import { useFormContext } from 'react-hook-form';
import { ChannelGuestsEditPermissionFormSchema } from '../useChannelGuestsEditPermissionFormSchema';

const messages = defineMessages({
    admin: { defaultMessage: 'Admin' },
    editor: { defaultMessage: 'Éditeur' },
    viewer: { defaultMessage: 'Lecteur' },
    none: { defaultMessage: 'Aucune permission' },
});

interface ChannelGuestsEditPermissionRoleCellProps {
    index: number;
}

const ChannelGuestsEditPermissionRoleCell = ({
    index,
}: ChannelGuestsEditPermissionRoleCellProps) => {
    const intl = useIntl();

    const roles = [...Object.values(GUEST_USER_ROLES), 'none'] as const;
    const roleItems = roles.map((role) => ({
        id: role,
        name: intl.formatMessage(messages[role]),
    }));
    const { control } = useFormContext<ChannelGuestsEditPermissionFormSchema>();

    return (
        <ChannelGuestsRoleSelect name={`shows.${index}.role`} items={roleItems} control={control} />
    );
};

export default ChannelGuestsEditPermissionRoleCell;
