import { useMutation, useQueryClient } from '@tanstack/react-query';
import { PlaylistFormSchema } from '../PlaylistForm/usePlaylistFormSchema.hook';
import updatePlaylistImageMutation from '@/queries/playlist/updatePlaylistImageMutation';
import deletePlaylistImageMutation from '@/queries/playlist/deletePlaylistImageMutation';
import playlistKeys from '@/queries/playlist/playlistKeys';
import updatePlaylistMutation from '@/queries/playlist/updatePlaylistMutation';

interface savePlaylistMutationnProps {
    playlistId: string;
    data: PlaylistFormSchema;
}

const savePlaylistMutation = (showId: string) => {
    const queryClient = useQueryClient();
    const updatePlaylist = updatePlaylistMutation();
    const updateImage = updatePlaylistImageMutation();
    const deleteImage = deletePlaylistImageMutation();

    return useMutation({
        mutationFn: ({ playlistId, data }: savePlaylistMutationnProps) => {
            const { image, ...playlist } = data;
            const promises = [];

            if (image && image instanceof File) {
                const imagePromise = updateImage.mutateAsync({
                    playlistId,
                    image: image,
                });
                promises.push(imagePromise);
            }

            if (image === null) {
                const imagePromise = deleteImage.mutateAsync(playlistId);
                promises.push(imagePromise);
            }

            if (Object.keys(playlist).length > 0) {
                const playerSettingsPromise = updatePlaylist.mutateAsync({
                    playlistId,
                    playlist,
                });
                promises.push(playerSettingsPromise);
            }

            // Waiting for all promises to be fulfilled or rejected
            return Promise.all(promises);
        },
        onSuccess: (_) => {
            queryClient.invalidateQueries({ queryKey: playlistKeys.listByShowId(showId) });
        },
    });
};

export default savePlaylistMutation;
