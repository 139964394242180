const clipFormatKeys = {
    all: () => [{ entity: 'clipFormat' }],
    allLists: () => [{ ...clipFormatKeys.all()[0], scope: 'list' }],
    listByShowId: (
        rawShowId: string,
        {
            page = 1,
            perPage = 15,
        }: {
            page?: number;
            perPage?: number;
        },
    ) => [
        {
            ...clipFormatKeys.allLists()[0],
            showId: Number(rawShowId),
            page,
            perPage,
        },
    ],
    listByEpisodeId: (rawEpisodeId: string) => [
        {
            ...clipFormatKeys.allLists()[0],
            episodeId: Number(rawEpisodeId),
        },
    ],
    allDetails: () => [{ ...clipFormatKeys.all()[0], scope: 'detail' }],
    detailById: (rawClipFormatId: string | number) => [
        { ...clipFormatKeys.allDetails()[0], clipFormatId: Number(rawClipFormatId) },
    ],
};

export default clipFormatKeys;
