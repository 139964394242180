import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchShowSourcePlatformsLeaderboard } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import statsKeys from './statsKeys';
import { z } from 'zod';

export const PlatformSchema = z.object({
    category: z.string(),
    count: z.number(),
    percentage: z.number(),
});
export const PlatformsSchema = z.array(PlatformSchema);
export type Platform = z.infer<typeof PlatformSchema>;
type Key = QueryFunctionContext<ReturnType<typeof statsKeys.sourcePlatformsLeaderboard>>;
type InputProps = {
    showId: string;
    episodes: string[];
    start: string;
    end: string;
    timezone: string;
    options?: {
        enabled?: boolean;
    };
};

const queryFn = async ({ queryKey: [{ showId, episodes, start, end, timezone }] }: Key) => {
    const { data } = await fetchShowSourcePlatformsLeaderboard(showId, {
        podcasts: episodes,
        timezone,
        start,
        end,
    });
    return PlatformsSchema.parse(camelcaseKeys(data, { deep: true }));
};

const useSourcePlatformsLeaderboardQuery = ({
    showId,
    episodes,
    start,
    end,
    timezone,
    options,
}: InputProps) => {
    return useQuery({
        queryKey: statsKeys.sourcePlatformsLeaderboard(showId, episodes, start, end, timezone),
        queryFn,
        enabled: options?.enabled || true,
    });
};

export default useSourcePlatformsLeaderboardQuery;
