import PropTypes from 'prop-types';
import SemiCircularProgress from '@ui/molecules/SemiCircularProgress';
import SemiCircularTrend from '@ui/molecules/SemiCircularTrend';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import UiKitTooltip from '@ui/atoms/UiKitTooltip';
import Text from '@ui/atoms/Text';
import { FormattedMessage } from 'react-intl';
import PsoVisibilityScoreSkeleton from './PsoVisibilityScoreSkeleton';
import styled, { css } from 'styled-components';

const PsoVisibilityScore = ({ score, trend, rankDiff, isLoading }) => {
    if (isLoading) return <PsoVisibilityScoreSkeleton />;
    return (
        <VisibilityScoreWrapper>
            <TitleWrapper>
                <Title>
                    <FormattedMessage defaultMessage="Score de visibilité" />
                </Title>
                <UiKitTooltip
                    variant="dark"
                    content={
                        <FormattedMessage defaultMessage="Le score de visibilité mesure la popularité de votre podcast en prenant en compte votre position moyenne sur chaque mot-clé et le nombre de recherches effectuées par les auditeurs sur les 7 derniers jours" />
                    }
                    position="top"
                >
                    <HelpIcon icon={icon({ name: 'circle-info', style: 'solid' })} />
                </UiKitTooltip>
            </TitleWrapper>
            <SemiCircularProgress percentage={score} size={250}>
                <SemiCircularTrend
                    figure={Math.round(score)}
                    trend={trend}
                    rankDiff={Math.round(rankDiff)}
                />
            </SemiCircularProgress>
            <TrendWrapper>
                <TrendIconWrapper trend={trend} noData={!score}>
                    {!score ? (
                        <TrendIcon icon={icon({ name: 'hyphen', style: 'solid' })} />
                    ) : ['up', 'new'].indexOf(trend) > -1 ? (
                        <TrendIcon icon={icon({ name: 'arrow-trend-up', style: 'solid' })} />
                    ) : ['down', 'exited'].indexOf(trend) > -1 ? (
                        <TrendIcon
                            icon={icon({
                                name: 'arrow-trend-down',
                                style: 'solid',
                            })}
                        />
                    ) : null}
                </TrendIconWrapper>
                <TrendText>
                    {!score ? (
                        <FormattedMessage defaultMessage="Aucune donnée de visibilité n’est disponible pour le moment." />
                    ) : ['up', 'new'].indexOf(trend) > -1 ? (
                        <FormattedMessage defaultMessage="Félicitations 🎉 Votre visibilité sur les plateformes a progressé comparé à la semaine dernière" />
                    ) : ['down', 'exited'].indexOf(trend) > -1 ? (
                        <FormattedMessage defaultMessage="Il semble que votre visibilité sur ces mots-clés a baissé comparé à la semaine dernière 🥺" />
                    ) : (
                        <FormattedMessage defaultMessage="Il semblerait que votre score de visibilité n’a pas bougé comparé à la semaine dernière" />
                    )}
                </TrendText>
            </TrendWrapper>
        </VisibilityScoreWrapper>
    );
};

const VisibilityScoreWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem;
`;
const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
`;
const Title = styled(Text)`
    font-weight: var(--fw-bold);
    font-size: var(--fs-body-m);
`;
const HelpIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
`;
const TrendWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.75rem;
`;
const TrendIconWrapper = styled.div`
    height: 2.5rem;
    width: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--r-s);
    flex-shrink: 0;

    ${({ trend }) =>
        ['up', 'new'].indexOf(trend) > -1 &&
        css`
            color: var(--success);
            background-color: var(--success50);
        `}
    ${({ trend }) =>
        ['down', 'exited'].indexOf(trend) > -1 &&
        css`
            color: var(--alert);
            background-color: var(--alert50);
        `}
    ${({ noData, trend }) =>
        (noData || trend === 'neutral') &&
        css`
            color: var(--neutral);
            background-color: var(--neutral50);
        `}
`;
const TrendIcon = styled(FontAwesomeIcon)`
    color: inherit;
`;
const TrendText = styled(Text)`
    color: var(--neutral500);
    font-size: var(--fs-body-s);
`;

PsoVisibilityScore.propTypes = {
    score: PropTypes.number,
    trend: PropTypes.oneOf(['up', 'down', 'neutral', 'new', 'exited']),
    rankDiff: PropTypes.number,
    isLoading: PropTypes.bool,
};

export default PsoVisibilityScore;
