import styled, { css } from 'styled-components';

const FormSectionSkeleton = () => {
    return (
        <FormSectionSkeletonWrapper>
            <ContentWrapper>
                <ContentTitle />
                <ContentSubTitle />
                <ContentTiles>
                    <ContentTile>
                        <ContentTileInner />
                    </ContentTile>
                    <ContentTile>
                        <ContentTileInner />
                    </ContentTile>
                    <ContentTile>
                        <ContentTileInner />
                    </ContentTile>
                    <ContentTile>
                        <ContentTileInner />
                    </ContentTile>
                </ContentTiles>
            </ContentWrapper>
            <TextWrapper>
                <TextTitle />
                <TextSubTitle />
                <TextTiles>
                    <EmojiTile />
                    <IATile />
                </TextTiles>
                <TextBlock />
                <CharCount />
            </TextWrapper>
            <CheckGroup>
                <Check>
                    <CheckElement />
                    <CheckTitle />
                </Check>
                <Check>
                    <CheckElement />
                    <CheckTitle />
                </Check>
            </CheckGroup>
            <ActionWrapper>
                <ActionButton />
                <ActionButton />
                <ActionButtonSmall />
            </ActionWrapper>
        </FormSectionSkeletonWrapper>
    );
};

const BarBase = css`
    height: 0.75rem;
    border-radius: var(--r-full);
`;
const FormSectionSkeletonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;
const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;
const ContentTitle = styled.div`
    ${BarBase};
    width: 10rem;
    background-color: var(--neutral100);
`;
const ContentSubTitle = styled.div`
    ${BarBase};
    width: 20rem;
    background-color: var(--neutral50);
`;
const ContentTiles = styled.div`
    display: flex;
    gap: 0.5rem;
    align-items: center;
`;
const ContentTile = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-block: 0.75rem;
    padding-inline: 1.25rem;
    border-radius: var(--r-s);
    border: 1px solid var(--neutral100);
`;
const ContentTileInner = styled.div`
    ${BarBase};
    width: 5rem;
    background-color: var(--neutral100);
`;
const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;
const TextTitle = styled.div`
    ${BarBase};
    width: 4rem;
    background-color: var(--neutral100);
`;
const TextSubTitle = styled.div`
    ${BarBase};
    width: 13rem;
    background-color: var(--neutral50);
`;
const TextTiles = styled.div`
    display: flex;
    gap: 0.5rem;
    align-items: center;
`;
const EmojiTile = styled.div`
    width: 1.5rem;
    height: 1.5rem;
    background-color: var(--neutral50);
    border-radius: var(--r-s);
`;
const IATile = styled.div`
    width: 10rem;
    height: 1.5rem;
    background-color: var(--neutral200);
    border-radius: var(--r-s);
`;
const TextBlock = styled.div`
    width: 100%;
    height: 9rem;
    border-radius: var(--r-s);
    border: 1px solid var(--neutral100);
`;
const CharCount = styled.div`
    ${BarBase};
    align-self: flex-end;
    width: 2rem;
    background-color: var(--neutral50);
`;
const CheckGroup = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;
const Check = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;
const CheckElement = styled.div`
    width: 1rem;
    height: 1rem;
    border-radius: var(--r-full);
    background-color: var(--neutral200);
`;
const CheckTitle = styled.div`
    ${BarBase};
    width: 10rem;
    background-color: var(--neutral100);
`;
const ActionWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    justify-content: flex-end;
`;
const ActionButton = styled.div`
    width: 10rem;
    height: 2.5rem;
    border-radius: var(--r-s);
    background-color: var(--neutral100);
`;
const ActionButtonSmall = styled.div`
    width: 2.5rem;
    height: 2.5rem;
    border-radius: var(--r-s);
    background-color: var(--neutral100);
`;

export default FormSectionSkeleton;
