import { useParams } from 'react-router-dom';
import { ENABLE_PSO } from '@/utils/constants';
import usePsoQuery from '@queries/pso/usePsoQuery.hook';
import EmptyState from './EmptyState';
import useHasAccessToPso from '@/utils/hooks/useHasAccessToPso.hook';
import TargetKeyword from './TargetKeyword';
import Rules from './Rules';
import Score from './Score';
import SuggestedKeywords from './SuggestedKeywords';
import styled from 'styled-components';

const PsoCheckerTab = () => {
    const { showId }: { showId: string; episodeId: string } = useParams();
    const hasAccessToPso = useHasAccessToPso({ showId });
    const psoConfiguration = usePsoQuery({ showId, enabled: ENABLE_PSO });

    if (!hasAccessToPso) {
        return <EmptyState reason="unavailable" />;
    }

    if (!psoConfiguration.data.fullyConfigured) {
        return <EmptyState reason="unconfigured" />;
    }

    return (
        <Wrapper>
            <TargetKeyword />
            <ScoreAndRulesWrapper>
                <Score />
                <Sepatator />
                <Rules />
            </ScoreAndRulesWrapper>
            <SuggestedKeywords />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;
const ScoreAndRulesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    border-radius: var(--r-m);
    background-color: var(--white);
`;
const Sepatator = styled.div`
    height: 1px;
    background-color: var(--neutral100);
    margin: 1rem 0;
`;

export default PsoCheckerTab;
