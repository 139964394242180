import { FormattedMessage, useIntl } from 'react-intl';
import RingChartWithLegend from '@/components/charts/RingChartWithLegend';
import { useStatsDateSelectorContext } from '@/context/StatsDateSelectorContext';
import { useParams } from 'react-router';
import useQuery from '@/shared/hooks/useQuery';
import useSourcePlatformsLeaderboardQuery from '@/queries/stats/useSourcePlatformsLeaderboardQuery.hook';
import styled from 'styled-components';

function SourcesPlatformsGraph() {
    const intl = useIntl();
    const { showId } = useParams();
    const query = useQuery();
    const episodes = query.get('episode');
    const campaignId = query.get('campaignId');
    const { startDate, endDate } = useStatsDateSelectorContext();

    const { data: platforms, isLoading } = useSourcePlatformsLeaderboardQuery({
        showId,
        start: startDate,
        end: endDate,
        episodes: episodes && episodes !== 'all' ? episodes.split('_') : [],
        options: { enabled: !campaignId },
    });

    return (
        <>
            <OuterHeader>
                <TitleAndDescription>
                    <Title>
                        <FormattedMessage defaultMessage="Plateformes" />
                    </Title>
                    <Description>
                        <FormattedMessage defaultMessage="Voyons sur quelles plateformes vous avez le plus de succès." />
                    </Description>
                </TitleAndDescription>
            </OuterHeader>

            {platforms && (
                <RingChartWithLegend
                    title={
                        <FormattedMessage defaultMessage="Parts d'écoute selon les plateformes" />
                    }
                    isLoading={isLoading}
                    source={platforms.map((platform) => ({
                        label:
                            platform.category === 'other'
                                ? intl.formatMessage({ defaultMessage: 'Autres' })
                                : platform.category,
                        key: platform.category,
                        name: platform.category,
                        value: platform.percentage,
                        percent: platform.percentage,
                    }))}
                    py={50}
                    px={30}
                    mt={25}
                />
            )}
        </>
    );
}

const OuterHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;
const TitleAndDescription = styled.div``;
const Title = styled.h2`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: var(--fs-heading-m);
`;
const Description = styled.p``;

export default SourcesPlatformsGraph;
