import { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import useEpisodesInfiniteQuery from '@/queries/episode/useEpisodesInfiniteQuery.hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';
import ComboBox from '@/components/ui/atoms/ComboBox';
import ListBoxItem from '@ui/atoms/ListBoxItem';
import { useDebounce } from '@/shared/hooks/useDebounce';

const ItemImage = ({ image }) => {
    if (image && typeof image === 'string') {
        return <Image src={image} />;
    }

    return (
        <ImagePlaceholder>
            <Icon
                icon={icon({
                    name: 'pen-ruler',
                    style: 'solid',
                })}
            />
        </ImagePlaceholder>
    );
};

const SelectEpisode = ({ selectedEpisode, setSelectedEpisode }) => {
    const perPage = 20;
    const { showId } = useParams();
    const [searchQuery, setSearchQuery] = useState('');
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const query = useDebounce(searchQuery, 500);
    const episodes = useEpisodesInfiniteQuery({ showId, query, perPage });
    const items = useMemo(
        () =>
            episodes?.data?.pages
                ?.reduce((acc, page) => acc.concat(page.episodes), [])
                ?.map((episode) => ({
                    id: episode.id,
                    name: episode.name,
                    image: episode.imageUrl,
                })) || [],
        [episodes?.data?.pages],
    );
    const selectedEpisodeImage = useMemo(
        () => items?.find((item) => item.id === selectedEpisode)?.image,
        [selectedEpisode],
    );
    const lastPage = episodes?.data?.pages.slice(-1)[0];

    // Set the last episode created as selected when
    // the component is mounted and the list is loaded
    useEffect(() => {
        if (!selectedEpisode && items.length > 0 && isInitialLoad) {
            setSelectedEpisode(items[0].id);
        }
    }, [selectedEpisode, items, isInitialLoad]);

    const handleFetchMoreEpisodes = () => {
        if (!episodes?.hasNextPage || episodes?.isFetchingNextPage || episodes?.isFetching) {
            return;
        }
        episodes?.fetchNextPage();
    };

    return (
        <ComboBoxStyled
            name="episode"
            selectedKey={selectedEpisode}
            items={items}
            isLoading={episodes.isLoading}
            icon={selectedEpisode ? <ItemImage image={selectedEpisodeImage} /> : null}
            inputHasImage
            onSelectionChange={(id) => setSelectedEpisode(id)}
            onInputChange={(value) => {
                // Do not set the search query when the component is mounted
                // in order to show the complete list of episodes
                if (!isInitialLoad) {
                    setSearchQuery(value);
                }
                setIsInitialLoad(false);
            }}
            onLoadMore={handleFetchMoreEpisodes}
            loadMoreEnabled={lastPage?.pagination?.currentPage < lastPage?.pagination?.totalPages}
        >
            {(item) => <ListBoxItem {...item} />}
        </ComboBoxStyled>
    );
};

const Icon = styled(FontAwesomeIcon)`
    width: 1.25rem;
    height: 1.25rem;
`;
const ImagePlaceholder = styled.div`
    width: 2.5rem;
    height: 2.5rem;
    border-radius: var(--r-s);
    background-color: var(--neutral100);
    display: flex;
    align-items: center;
    justify-content: center;

    & > ${Icon} {
        width: 1rem;
        height: 1rem;
        color: var(--neutral500);
    }
`;
const Image = styled.img`
    width: 2.5rem;
    height: 2.5rem;
    border-radius: var(--r-s);
`;
const ComboBoxStyled = styled(ComboBox)`
    max-width: 100%;
`;

SelectEpisode.propTypes = {
    selectedEpisode: PropTypes.object,
    setSelectedEpisode: PropTypes.func.isRequired,
};

export default SelectEpisode;
