import { FormattedMessage } from 'react-intl';
import InputText from '@/components/ui/atoms/InputText';
import { useParams } from 'react-router-dom';
import useRssSettingsQuery from '@/queries/rssSettings/useRssSettingsQuery.hook';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import RouterLink from '@/components/Link/RouterLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';

const BroadcastRssImport = () => {
    const { showId } = useParams<{ showId: string }>();
    const { data: show, isError: showHasError, isLoading: showIsLoading } = useShowQuery(showId);
    const {
        data: rssSettings,
        isError: rssSettingsHasError,
        isLoading: rssSettingsIsLoading,
    } = useRssSettingsQuery(showId);
    const isShowPmp = show?.hostedOnAusha === false;

    if (showHasError || rssSettingsHasError || showIsLoading || rssSettingsIsLoading) {
        return (
            <LoadingWrapper>
                <FontAwesomeIcon icon={icon({ name: 'spinner-third', style: 'solid' })} spin />
            </LoadingWrapper>
        );
    }

    return (
        <BroadcastRssImportOuterWrapper>
            <MobileTitle>
                <FormattedMessage defaultMessage="Paramètres" />
            </MobileTitle>
            <BroadcastRssImportWrapper>
                <TitleAndDescriptionWrapper>
                    <DesktopTitle>
                        {isShowPmp ? (
                            <FormattedMessage defaultMessage="Configuration" />
                        ) : (
                            <FormattedMessage defaultMessage="Diffusion" />
                        )}
                    </DesktopTitle>
                    <Description data-testid="broadcast-page-heading">
                        <FormattedMessage defaultMessage="Diffusez manuellement votre émission sur ces plateformes d’écoute." />
                    </Description>
                </TitleAndDescriptionWrapper>
                <InputText
                    name="rssLink"
                    copyToClipboardEnabled
                    value={show?.rssLink}
                    label={<FormattedMessage defaultMessage="Lien du flux RSS de votre émission" />}
                    isDisabled={true}
                    description={
                        <FormattedMessage
                            defaultMessage="Votre flux RSS permet à votre podcast d'apparaître sur les plateformes d’écoute. Il est lié à l’adresse email {mail}. {linebreak}Pour modifier ses paramètres, rendez-vous sur la page <link>Export RSS</link>."
                            values={{
                                mail: (
                                    <Email>
                                        {rssSettings.data?.ownerEmail || show?.ownerEmail}
                                    </Email>
                                ),
                                linebreak: <br />,
                                link: (chunks: React.ReactNode) => (
                                    <RouterLink to={`/app/show/${showId}/settings/rss-export`}>
                                        {chunks}
                                    </RouterLink>
                                ),
                            }}
                        />
                    }
                />
            </BroadcastRssImportWrapper>
        </BroadcastRssImportOuterWrapper>
    );
};

const BroadcastRssImportOuterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;
const BroadcastRssImportWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: 1.5rem 1rem;
    max-width: 780px;

    ${(p) => p.theme.mediaQueries.tabletAndUp} {
        padding: 2rem 2.5rem;
    }
`;
const LoadingWrapper = styled.div`
    width: 100%;
    min-height: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const TitleAndDescriptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
const Description = styled.p`
    color: var(--neutral);
`;
const DesktopTitle = styled.h1`
    font-size: var(--fs-heading-m);
    display: none;
    @media (min-width: 768px) {
        display: block;
    }
`;
const MobileTitle = styled.h1`
    font-size: var(--fs-heading-m);
    display: block;
    @media (min-width: 768px) {
        display: none;
    }
`;
const Email = styled.span`
    font-weight: var(--fw-bold);
`;

export default BroadcastRssImport;
