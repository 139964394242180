import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { InputTextController } from '@ui/atoms/InputText';
import EpisodeEditTooltip from '@app/molecules/EpisodeEditTooltip';

const EpisodeEditFormNewsletterTitleField = () => {
    const intl = useIntl();
    const { control } = useFormContext();

    return (
        <InputTextController
            name="title"
            control={control}
            label={<FormattedMessage defaultMessage="Objet de la newsletter" />}
            placeholder={intl.formatMessage({
                defaultMessage: 'Ajoutez un titre à votre newsletter',
            })}
            isRequired
            tooltip={
                <EpisodeEditTooltip>
                    <FormattedMessage defaultMessage="Envoyez une Newsletter personnalisée à vos auditeurs les plus fidèles sur Ausha." />
                </EpisodeEditTooltip>
            }
        />
    );
};

export default EpisodeEditFormNewsletterTitleField;
