import { computed } from 'mobx';
import Model from './Model';

class ApplePodcastsSummaryModel extends Model {
    static default = () => ({
        averageRating: null,
        ratingsCount: null,
        reviewsCount: null,
        currentRanking: null,
        averageRanking: null,
        oldestRankingTimestamp: null,
    });

    static attributes = {
        averageRating: 'note',
        ratingsCount: 'nbNotes',
        reviewsCount: 'reviews_number',
        currentRanking: 'actual_position',
        averageRanking: 'average_position',
        oldestRankingTimestamp: 'oldest_position',
    };

    @computed
    get oldestRankingDate() {
        if (this.oldestRankingTimestamp) {
            return new Date(this.oldestRankingTimestamp * 1000);
        }

        return null;
    }
}

export default ApplePodcastsSummaryModel;
