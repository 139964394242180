import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from '@/components/legacy/connect';
import StatsDataSetSelector from './StatsDataSetSelector';
import ShortListFallback from '@/components/unorganized/ShortListFallback';
import FetcherBrowsersLeaderboard from '@/components/unorganized/FetcherBrowsersLeaderboard';
import { compose } from '@/helpers';
import { useStatsDateSelectorContext } from '@/context/StatsDateSelectorContext';
import RingChartWithLegend from '@/components/charts/RingChartWithLegend';

const enhance = compose(
    injectIntl,
    connect(({ sourcesStatsStore, routerStore }) => ({
        browsersTop: sourcesStatsStore.browsersTop,
        showId: routerStore.params.showId,
        itemType: routerStore.params.itemType,
        itemId: routerStore.params.itemId,
    })),
);

const SourcesBrowsers = ({ showId, itemType, itemId, browsersTop, intl }) => {
    const { startDate, endDate } = useStatsDateSelectorContext();

    return (
        <>
            <StatsDataSetSelector
                tabTitle={<FormattedMessage defaultMessage="Navigateurs" />}
                tabDescription={
                    <FormattedMessage defaultMessage="Voici les navigateurs les plus utilisés pour vous écouter" />
                }
                tabId="sources"
                noSelector
            />
            <FetcherBrowsersLeaderboard
                showId={showId}
                start={startDate}
                end={endDate}
                itemType={itemType}
                itemId={itemId}
                fallback={ShortListFallback}
            >
                {(isLoading) =>
                    !isLoading &&
                    browsersTop && (
                        <RingChartWithLegend
                            title={
                                <FormattedMessage defaultMessage="Parts d'écoute selon les navigateurs" />
                            }
                            isLoading={isLoading}
                            source={browsersTop.map((browser) => ({
                                label:
                                    browser.name === 'other'
                                        ? intl.formatMessage({ defaultMessage: 'Autres' })
                                        : browser.name,
                                key: browser.name,
                                name: browser.name,
                                value: browser.percentage,
                                percent: browser.percentage,
                            }))}
                            py={50}
                            px={30}
                            mt={25}
                        />
                    )
                }
            </FetcherBrowsersLeaderboard>
        </>
    );
};

export default enhance(SourcesBrowsers);
