import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createClip } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import clipKeys from './clipKeys';
import { Clip } from './useClipQuery';
import { decamelizeKeys } from 'humps';

export interface MutationFnProps {
    episodeId: string | number;
    clip: {
        episodeId: string | number;
        title: string;
        start: number;
        end: number;
        duration: number;
        transcript: boolean;
        caption: string;
        adminTitle: string;
    };
}

const mutationFn = async ({ episodeId, clip }: MutationFnProps): Promise<Clip> => {
    const { data } = await createClip(episodeId, decamelizeKeys(clip));
    return camelcaseKeys(data as any, { deep: true });
};

const createClipMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: clipKeys.allLists() });
        },
    });
};

export default createClipMutation;
