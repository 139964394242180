import { FormattedMessage } from 'react-intl';
import DeprecatedTextInput from '../../../../ui/molecules/DeprecatedTextInput';
import ColorPicker from '../../../../ui/molecules/ColorPicker';
import Stack from '../../../../ui/layout/Stack';
import DeprecatedText from '../../../../ui/atoms/DeprecatedText';
import DeprecatedToggle from '../../../../ui/atoms/DeprecatedToggle';
import DeprecatedAlert from '../../../../ui/molecules/DeprecatedAlert';

const ClipOptions = ({ values, multiFormats, setFieldValue }) => (
    <Stack $gap="2rem">
        <Stack $gap="1.5rem" $alignItems="flex-start">
            {multiFormats && (
                <DeprecatedAlert type="info">
                    <FormattedMessage
                        defaultMessage="Les options de personnalisation s’appliquent à {bold}."
                        values={{
                            bold: (
                                <strong>
                                    <FormattedMessage defaultMessage="tous les formats de clip" />
                                </strong>
                            ),
                        }}
                    />
                </DeprecatedAlert>
            )}
            <DeprecatedText as="label" weight="semibold">
                <DeprecatedToggle
                    checked={values.show_waveform}
                    onChange={() => setFieldValue('show_waveform', !values.show_waveform)}
                />
                <FormattedMessage defaultMessage="Afficher la waveform" />
            </DeprecatedText>
            <ColorPicker
                value={values.color}
                disabled={!values.show_waveform}
                onChange={(color) => setFieldValue('color', color)}
                w={160}
                label={<FormattedMessage defaultMessage="Couleur de la waveform" />}
            />
        </Stack>
        <Stack $gap="1.5rem">
            <DeprecatedText as="label" weight="semibold">
                <DeprecatedToggle
                    checked={values.show_titles}
                    onChange={() => setFieldValue('show_titles', !values.show_titles)}
                />
                <FormattedMessage defaultMessage="Afficher les titres" />
            </DeprecatedText>
            <ColorPicker
                value={values.title_color}
                disabled={!values.show_titles}
                onChange={(titleColor) => setFieldValue('title_color', titleColor)}
                w={160}
                label={<FormattedMessage defaultMessage="Couleur des titres" />}
            />
            <DeprecatedTextInput
                value={values.title}
                onChange={(title) => setFieldValue('title', title)}
                label={<FormattedMessage defaultMessage="Titre principal" />}
            />
            <DeprecatedTextInput
                value={values.caption}
                onChange={(caption) => setFieldValue('caption', caption)}
                label={<FormattedMessage defaultMessage="Titre secondaire" />}
            />
        </Stack>
    </Stack>
);

export default ClipOptions;
