import { FormattedMessage } from 'react-intl';
import { useAmplitudeLogEvent } from '@hooks/useAmplitude';
import EditEmailForm from './EditEmailForm';
import Modal from '@ui/molecules/Modal';

function EditEmailModal({ isOpen, onClose }) {
    useAmplitudeLogEvent('show edit password');

    return (
        <Modal
            title={<FormattedMessage defaultMessage="Modifier votre e-mail" />}
            onClose={onClose}
            isOpen={isOpen}
        >
            <EditEmailForm onAfterSubmit={onClose} />
        </Modal>
    );
}

export default EditEmailModal;
