import { Episode } from '@/queries/episode/useEpisodeQuery.hook';
import styled from 'styled-components';
import useEpisodeAudioFileJobsQuery from '@/queries/episode/useEpisodeAudioFileJobsQuery';
import Form from './Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { EPISODE_JOB_NAME } from '@/shared/config/constants';
import Progress from './Progress';

interface AudioSampleProps {
    episode: Episode;
}

const AudioSample = ({ episode }: AudioSampleProps) => {
    const isEpisodeJobsFetchingEnabled = !episode.waveformUrl;
    const { data: episodeJobsProgress } = useEpisodeAudioFileJobsQuery({
        episodeId: episode.id,
        enabled: isEpisodeJobsFetchingEnabled,
    });

    if (
        isEpisodeJobsFetchingEnabled &&
        episodeJobsProgress?.[EPISODE_JOB_NAME.GENERATE_WAVEFORM] &&
        !episodeJobsProgress?.[EPISODE_JOB_NAME.GENERATE_WAVEFORM]?.isFinished
    ) {
        return <Progress progress={episodeJobsProgress} />;
    }

    if (isEpisodeJobsFetchingEnabled) {
        return (
            <SpinnerWrapper>
                <SpinnerIcon icon={icon({ name: 'spinner-third', style: 'solid' })} spin />
            </SpinnerWrapper>
        );
    }

    return <Form episode={episode} />;
};

const SpinnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
`;
const SpinnerIcon = styled(FontAwesomeIcon)`
    width: 4rem;
    height: 4rem;
    color: var(--neutral100);
`;
export default AudioSample;
