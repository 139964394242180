import { FormattedMessage } from 'react-intl';
import Dialog from '@/components/ui/atoms/Dialog';
import ShowGuestsInvitationForm from '../ShowGuestsInvitationForm';

interface ShowGuestsInvitationModalProps {
    isOpen: boolean;
    onOpenChange: (open: boolean) => void;
    onPermissionError: () => void;
}

const ShowGuestsInvitationModal = ({
    isOpen,
    onOpenChange,
    onPermissionError,
}: ShowGuestsInvitationModalProps) => {
    return (
        <Dialog
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            title={<FormattedMessage defaultMessage="Ajouter un utilisateur" />}
            size="medium"
        >
            <ShowGuestsInvitationForm
                onSuccess={() => onOpenChange(false)}
                onPermissionError={onPermissionError}
            />
        </Dialog>
    );
};

export default ShowGuestsInvitationModal;
