import { useState } from 'react';
import Button from '@/components/Button';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';
import useUpdateShowTemplateMutation from '@/queries/showTemplate/useUpdateShowTemplateMutation.hook';
import useFetchSocialRedirectUrlMutation from '@/queries/socialNetworkPlatform/useFetchSocialRedirectUrlMutation.hook';
import useLoginOnMediaPlatformMutation from '@/queries/socialNetworkPlatform/useLoginOnMediaPlatformMutation.hook';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import { LINKEDIN, SOCIAL_NETWORK_NAME } from '@/shared/config/constants';
import { useBodyToastQueue } from '@/shared/hooks/useBodyToastQueue.hook';
import { useWindowPopupSocialMediaManager } from '@/shared/hooks/useWindowPopupSocialMediaManager';
import { isProviderEnabled } from '@/shared/services/providerService';
import { TooltipTrigger } from 'react-aria-components';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import styled from 'styled-components';
import AccountsModal from './AccountsModal';
import { LinkedinAccount } from '@/queries/provider/useLinkedinProviderQuery';
import providerKeys from '@/queries/provider/providerKeys';
import { useQueryClient } from '@tanstack/react-query';

const LoginButton = () => {
    const { showId } = useParams<{ showId: string }>();
    const { data: user } = useUserQuery();
    const loginOnMediaPlatform = useLoginOnMediaPlatformMutation();
    const toast = useBodyToastQueue();
    const fetchProviderRedirectUrl = useFetchSocialRedirectUrlMutation();
    const updateShowTemplate = useUpdateShowTemplateMutation();
    const [accounts, setAccounts] = useState<LinkedinAccount[]>([]);
    const [isAccountsModalOpen, setIsAccountsModalOpen] = useState(false);
    const queryClient = useQueryClient();
    // eslint-disable-next-line no-unused-vars
    const [_, openSocialNetworkOauthLogin] = useWindowPopupSocialMediaManager((e) => {
        if (e.data.provider !== LINKEDIN) return;
        loginOnMediaPlatform.mutate(
            { showId, platform: LINKEDIN, callbackData: e.data },
            {
                onSuccess: (data) => {
                    if (data.length === 1) {
                        handleUpdateShowTemplate(data[0].id);
                        return;
                    }
                    if (data.length > 1) {
                        setAccounts(data);
                        setIsAccountsModalOpen(true);
                        return;
                    }
                },
                onError: () => {
                    toast.alert(
                        <FormattedMessage defaultMessage="Oups, une erreur est survenue. Veuillez réessayer" />,
                    );
                },
            },
        );
    });

    const handleUpdateShowTemplate = (accountId: string) => {
        updateShowTemplate.mutate(
            {
                showId,
                provider: LINKEDIN,
                payload: {
                    params: {
                        accountId,
                    },
                },
            },
            {
                onSuccess: () => {
                    queryClient.invalidateQueries({ queryKey: providerKeys.listByShowId(showId) });
                    toast.success(
                        <FormattedMessage
                            defaultMessage="Votre compte {platform} est maintenant lié à Ausha. 🥳"
                            values={{
                                platform: SOCIAL_NETWORK_NAME[LINKEDIN],
                            }}
                        />,
                    );
                },
            },
        );
    };

    const handleLogin = () => {
        fetchProviderRedirectUrl.mutate(
            { platformId: LINKEDIN },
            {
                onSuccess: (data) => {
                    if (!data.loginPageUrl) return;
                    openSocialNetworkOauthLogin?.(data.loginPageUrl);
                },
            },
        );
    };

    if (!user) return null;

    const isDisabled = !isProviderEnabled(LINKEDIN, user);

    return (
        <>
            <TooltipTrigger delay={0} closeDelay={0} isDisabled={!isDisabled}>
                <TooltipTriggerWrapper>
                    <LinkedinButton
                        isDisabled={isDisabled}
                        isLoading={loginOnMediaPlatform.isLoading}
                        onPress={handleLogin}
                    >
                        <FormattedMessage
                            defaultMessage="Se connecter à {provider}"
                            values={{
                                provider: SOCIAL_NETWORK_NAME[LINKEDIN],
                            }}
                        />
                    </LinkedinButton>
                </TooltipTriggerWrapper>
                <Tooltip placement="bottom">
                    <FormattedMessage
                        defaultMessage="Oups, le partage via {providerName} est actuellement indisponible. Nos équipes font le nécessaire pour réparer cela au plus vite. 💪 Merci de réessayer plus tard. 💜"
                        values={{
                            providerName: SOCIAL_NETWORK_NAME[LINKEDIN],
                        }}
                    />
                </Tooltip>
            </TooltipTrigger>
            <AccountsModal
                isOpen={isAccountsModalOpen}
                onOpenChange={setIsAccountsModalOpen}
                accounts={accounts}
                onSubmit={(id) => {
                    handleUpdateShowTemplate(id);
                    setIsAccountsModalOpen(false);
                }}
            />
        </>
    );
};

const LinkedinButton = styled(Button)`
    background-color: var(--linkedin);

    &:hover {
        background-color: var(--linkedin);
    }
`;

export default LoginButton;
