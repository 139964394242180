import AccordionItem from '@ui/atoms/AccordionItem';
import EpisodeEditAccordionItem from '@/components/unorganized/EpisodeEditAccordionItem';
import { FormattedMessage } from 'react-intl';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';
import Accordion from '@ui/atoms/Accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormContext } from 'react-hook-form';
import NonHostingEpisodeFormContent from '../NonHostingEpisodeFormContent';
import { useEpisodeBeingEdited } from '@/context/EpisodeFormContext';
import { useEpisodeEditAudioFileMutation } from '../contexts/NonHostingEpisodeFileUploadContext';
import { useEpisodeAILoading } from '../contexts/NonHostingEpisodeAILoadingContext';
import ChaptersState from './ChaptersState';
import NonHostingEpisodeFormChapters from '../NonHostingEpisodeFormChapters/NonHostingEpisodeFormChapters';
import NonHostingEpisodeFormNewsletter from '../NonHostingEpisodeFormNewsletter/NonHostingEpisodeFormNewsletter';
import NonHostingEpisodeFormSocialPosts from '../NonHostingEpisodeFormSocialPosts';

const EpisodeEditFormAccordion = () => {
    const {
        formState: { errors },
    } = useFormContext();
    const episode = useEpisodeBeingEdited();
    const episodeEditAudioFileMutation = useEpisodeEditAudioFileMutation();
    const hasAudioFile = (episode?.files?.length ?? 0) > 0;
    const isUploadingAudioFile = episodeEditAudioFileMutation.isLoading;
    const [state] = useEpisodeAILoading();
    const isLoading = state.get('chapters') || false;
    const isChaptersEnabled = !isUploadingAudioFile && hasAudioFile && !isLoading;

    return (
        <EpisodeAccordion defaultIndex={0}>
            <AccordionItem index={0}>
                <EpisodeEditAccordionItem
                    label={<FormattedMessage defaultMessage="Contenu" />}
                    icon={
                        <AccordionItemIcon
                            icon={icon({ name: 'album-collection', style: 'solid' })}
                        />
                    }
                    errorsCount={errors.content ? Object.keys(errors.content).length : 0}
                    localStorageKey="content"
                >
                    <NonHostingEpisodeFormContent />
                </EpisodeEditAccordionItem>
            </AccordionItem>
            <AccordionItem index={1} isDisabled={!isChaptersEnabled}>
                <EpisodeEditAccordionItem
                    label={<FormattedMessage defaultMessage="Chapitres" />}
                    icon={<AccordionItemIcon icon={icon({ name: 'bookmark', style: 'solid' })} />}
                    errorsCount={errors.chapters ? Object.keys(errors.chapters).length : 0}
                    localStorageKey="chapters"
                    customContent={
                        <ChaptersState
                            hasAudioFile={hasAudioFile}
                            isUploadingAudioFile={isUploadingAudioFile}
                            isFetchingChaptersSuggestions={isLoading}
                        />
                    }
                >
                    <NonHostingEpisodeFormChapters />
                </EpisodeEditAccordionItem>
            </AccordionItem>
            <AccordionItem index={2}>
                <EpisodeEditAccordionItem
                    label={<FormattedMessage defaultMessage="Newsletter" />}
                    icon={
                        <AccordionItemIcon
                            icon={icon({ name: 'envelope-open-text', style: 'solid' })}
                        />
                    }
                    errorsCount={errors.newsletter ? Object.keys(errors.newsletter).length : 0}
                    localStorageKey="newsletter"
                    data-testid="episode-accordion-newsletter"
                >
                    <NonHostingEpisodeFormNewsletter />
                </EpisodeEditAccordionItem>
            </AccordionItem>
            <AccordionItem index={3}>
                <EpisodeEditAccordionItem
                    label={<FormattedMessage id="socialPosts" defaultMessage="Réseaux sociaux" />}
                    icon={
                        <AccordionItemIcon icon={icon({ name: 'message-heart', style: 'solid' })} />
                    }
                    errorsCount={errors.content ? Object.keys(errors.content).length : 0}
                    localStorageKey="content"
                >
                    <NonHostingEpisodeFormSocialPosts />
                </EpisodeEditAccordionItem>
            </AccordionItem>
        </EpisodeAccordion>
    );
};

const AccordionItemIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--neutral500);
`;
const EpisodeAccordion = styled(Accordion)`
    flex-grow: 1;
    min-width: 0;

    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        padding-bottom: 2rem;
    }
`;

export default EpisodeEditFormAccordion;
