import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchChannelGuestPermissions } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import guestKeys from './guestKeys';
import { z } from 'zod';
import { CHANNEL_GUEST_USER_ROLES } from '@/shared/config/constants';

export const ChannelGuestRoleSchema = z.enum([
    CHANNEL_GUEST_USER_ROLES.ADMIN,
    CHANNEL_GUEST_USER_ROLES.EDITOR,
    CHANNEL_GUEST_USER_ROLES.VIEWER,
    CHANNEL_GUEST_USER_ROLES.MIXED,
] as const);

export const ChannelGuestPermissionSchema = z.object({
    role: ChannelGuestRoleSchema,
    userId: z.number(),
    userAvatar: z.string().nullable(),
    userEmail: z.string().nullable(),
    userName: z.string().nullable(),
});

const ChannelGuestPermissionsSchema = z.array(ChannelGuestPermissionSchema);

type Key = QueryFunctionContext<ReturnType<typeof guestKeys.permissionListByChannelId>>;

export type ChannelGuestPermission = z.infer<typeof ChannelGuestPermissionSchema>;

const queryFn = async ({ queryKey: [{ channelId }] }: Key) => {
    const { data } = await fetchChannelGuestPermissions(channelId);
    return ChannelGuestPermissionsSchema.parse(camelcaseKeys(data, { deep: true }));
};

interface OptionProps {
    enabled?: boolean;
}

const useChannelGuestPermissionsQuery = (
    channelId: number | undefined,
    options: OptionProps = {},
) => {
    return useQuery({
        queryKey: guestKeys.permissionListByChannelId(channelId),
        queryFn,
        ...options,
    });
};

export default useChannelGuestPermissionsQuery;
