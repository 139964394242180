import { useState } from 'react';
import SelectNative from '@ui/molecules/SelectNative';
import useDroppable from '@/shared/hooks/useDroppable';
import MobileOrTablet from '@ui/molecules/MobileOrTablet';
import Desktop from '@ui/molecules/Desktop';
import MenuItem from '@ui/atoms/MenuItem';
import Menu from '@ui/atoms/Menu';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import Text from '@ui/atoms/Text';
import Button from '@ui/atoms/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import Uploader from '@ui/smarties/Uploader';
import IconImage from '@ui/icons/IconImage';
import IconUpload from '@ui/icons/IconUpload';
import Cluster from '@ui/layout/Cluster';
import styled from 'styled-components';
import Stack from '@ui/layout/Stack';
import { SOCIAL_NETWORK } from '@/shared/config/constants';
import { messageParsers, messagesImgUploadError } from '@/forms/validators/apiValidation';
import OutsideClickHandler from 'react-outside-click-handler';
import { useBodyToastQueue } from '@/shared/hooks/useBodyToastQueue.hook';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import uploadSocialNetworkPostImageMutation from '@/queries/socialNetworkPost/uploadSocialNetworkPostImageMutation';

const ShareButtonImageWrapper = styled.div`
    position: relative;
    width: 100%;

    ${(p) => p.theme.mediaQueries.desktopAndUp} {
        width: auto;
    }
`;
const UploadWrapperLabel = styled.label`
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
`;
const ShareMenu = styled(Menu)`
    display: inline-block;
    width: 400px;
    left: 0;
    top: calc(100% + 0.5rem);
`;

const ShareButtonImage = ({ type, onSelectContent, post, provider, cover = null }) => {
    const intl = useIntl();
    const toast = useBodyToastQueue();
    const { data: user } = useUserQuery();
    const [ref, dropdownOpened, openDropdown, closeDropdown] = useDroppable(false, false, true);
    const [mobileSelectClose, setMobileSelectClose] = useState(false);
    const uploadPostImage = uploadSocialNetworkPostImageMutation();

    const uploadConstraintsMatch = {
        [SOCIAL_NETWORK.INSTAGRAM]: (
            <FormattedMessage defaultMessage="Ratio compris entre 4:5 et 1.91:1" />
        ),
        [SOCIAL_NETWORK.FACEBOOK]: (
            <FormattedMessage defaultMessage="Taille min. : 200*200px ; Poids max. : 8mo" />
        ),
        [SOCIAL_NETWORK.LINKEDIN]: (
            <FormattedMessage defaultMessage="Taille min. : 552*276px ; Poids max. : 5mo" />
        ),
        [SOCIAL_NETWORK.TWITTER]: <FormattedMessage defaultMessage="Poids max. : 5mo" />,
    };

    const handleUpload = async (file) => {
        closeDropdown();
        setMobileSelectClose(true);

        const img = new Image();
        img.src = URL.createObjectURL(new File([file], 'pic'));

        uploadPostImage.mutate(
            { postId: post.id, file },
            {
                onSuccess: (data) => {
                    onSelectContent(type, null, {
                        url: data.url,
                        name: intl.formatMessage({ defaultMessage: 'Image personnalisée' }),
                    });
                },
                onError: (error) => {
                    Object.entries(error?.response?.data?.fails?.file)?.forEach((fail) => {
                        const [key, values] = fail;
                        const parsedValue = key === 'Mimes' ? [values] : values;
                        const denominator = user?.language === 'fr' ? 1000 * 1000 : 1024 * 1024;
                        const currentValues = {
                            weight: (file.size / denominator).toFixed(1),
                            width: img.width,
                            height: img.height,
                            format: file.name.split('.')[1],
                        };

                        if (key in messageParsers) {
                            toast.alert(
                                <FormattedMessage
                                    defaultMessage="{message}"
                                    values={{
                                        message: messagesImgUploadError[key](
                                            provider,
                                            currentValues,
                                            ...parsedValue,
                                        ),
                                    }}
                                />,
                            );
                        }
                    });
                },
                onSettled: () => {
                    setMobileSelectClose(false);
                },
            },
        );
    };

    const mobileButtons = [
        <DeprecatedPaper key="mob1" flex align="center" my={12} mx={16}>
            <Uploader accept="image/*" onChange={(file) => handleUpload(file)}>
                <UploadWrapperLabel>
                    <Stack>
                        <Cluster $align="center" $gap="0.5rem">
                            <IconUpload
                                style={{
                                    fill: 'var(--neutral500)',
                                    width: '18px',
                                    height: '18px',
                                }}
                            />
                            <FormattedMessage defaultMessage="Importer une image personnalisée" />
                        </Cluster>
                        <div style={{ marginLeft: '1.6rem' }}>
                            <Text variant="footnote" color="--neutral500">
                                {uploadConstraintsMatch[provider]}
                            </Text>
                        </div>
                    </Stack>
                </UploadWrapperLabel>
            </Uploader>
        </DeprecatedPaper>,
    ];

    cover &&
        mobileButtons.push(
            <DeprecatedPaper
                flex
                align="center"
                my={12}
                mx={16}
                onClick={() =>
                    onSelectContent(type, null, {
                        url: cover,
                        name: intl.formatMessage({ defaultMessage: 'Illustration de l’épisode' }),
                        isCover: true,
                    })
                }
            >
                <Cluster $align="center" $gap="0.5rem">
                    <IconImage
                        style={{ fill: 'var(--neutral500)', width: '18px', height: '18px' }}
                    />
                    <FormattedMessage defaultMessage="Illustration de l’épisode" />
                </Cluster>
            </DeprecatedPaper>,
        );

    return (
        <ShareButtonImageWrapper>
            <Desktop>
                <OutsideClickHandler
                    onOutsideClick={() => {
                        closeDropdown();
                    }}
                >
                    <TriggerMenuButton
                        variant="secondary"
                        isDisabled={type.disabled}
                        onClick={openDropdown}
                        isLoading={uploadPostImage.isLoading}
                        startIcon={type.icon}
                    >
                        {type.label}
                    </TriggerMenuButton>
                    <ShareMenu dropdown anchor="up" ref={ref} opened={dropdownOpened}>
                        <MenuItem h={53}>
                            <Uploader accept="image/*" onChange={(file) => handleUpload(file)}>
                                <UploadWrapperLabel>
                                    <Stack>
                                        <Cluster $align="center" $gap="0.5rem">
                                            <IconUpload
                                                style={{
                                                    fill: 'var(--neutral500)',
                                                    width: '18px',
                                                    height: '18px',
                                                }}
                                            />
                                            <FormattedMessage defaultMessage="Importer une image personnalisée" />
                                        </Cluster>
                                        <div style={{ marginLeft: '1.6rem' }}>
                                            <Text variant="footnote" color="--neutral500">
                                                {uploadConstraintsMatch[provider]}
                                            </Text>
                                        </div>
                                    </Stack>
                                </UploadWrapperLabel>
                            </Uploader>
                        </MenuItem>
                        {cover && (
                            <MenuItem
                                h={53}
                                onClick={() =>
                                    onSelectContent(type, null, {
                                        url: cover,
                                        name: intl.formatMessage({
                                            defaultMessage: 'Illustration de l’épisode',
                                        }),
                                        isCover: true,
                                    })
                                }
                            >
                                <Cluster $align="center" $gap="0.5rem">
                                    <IconImage
                                        style={{
                                            fill: 'var(--neutral500)',
                                            width: '18px',
                                            height: '18px',
                                        }}
                                    />
                                    <FormattedMessage defaultMessage="Illustration de l’épisode" />
                                </Cluster>
                            </MenuItem>
                        )}
                    </ShareMenu>
                </OutsideClickHandler>
            </Desktop>
            <MobileOrTablet>
                <SelectNative
                    disabled={type.disabled}
                    manualClosing={true}
                    withClosingHeader={true}
                    close={mobileSelectClose}
                    label={
                        <>
                            {type.icon}
                            <DeprecatedText weight="semibold" ml={8}>
                                {type.label}
                            </DeprecatedText>
                        </>
                    }
                    source={mobileButtons}
                />
            </MobileOrTablet>
        </ShareButtonImageWrapper>
    );
};

const TriggerMenuButton = styled(Button)`
    border-radius: var(--r-xs);
`;

export default ShareButtonImage;
