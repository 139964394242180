import PropTypes from 'prop-types';
import ProgressBar from '@ui/ProgressBar';
import styled from 'styled-components';

interface PsoSearchVolumeCellProps {
    score: number;
}

const PsoSearchVolumeCell = ({ score }: PsoSearchVolumeCellProps) => {
    return (
        <SearchVolumeWrapper>
            <span>{score}</span>
            <ProgressBar score={Math.min(100, Math.max(5, score))} thresholds={[0, 21, 61]} />
        </SearchVolumeWrapper>
    );
};

const SearchVolumeWrapper = styled.div`
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    & > nth-child(1) {
        flex: 1;
    }
`;

PsoSearchVolumeCell.propTypes = {
    score: PropTypes.oneOf([1, 2, 3]).isRequired,
};

export default PsoSearchVolumeCell;
