import Footer from '@/components/Footer';
import UnexpectedError from '@/components/UnexpectedError';
import styled from 'styled-components';

const ErrorBoundaryFallback = () => {
    return (
        <Container>
            <Main>
                <UnexpectedError />
            </Main>
            <Footer />
        </Container>
    );
};

const Container = styled.div`
    width: 100%;
    min-height: 100%;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    padding-bottom: 1rem;
    padding-inline: 1rem;
`;
const Main = styled.main`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
`;

export default ErrorBoundaryFallback;
