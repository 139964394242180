import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { checkImportRedirection } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import rssKeys from './rssKeys';
import { z } from 'zod';

export const FeedRedirectStatusSchema = z.object({
    redirected: z.boolean(),
});
export type FeedRedirectStatus = z.infer<typeof FeedRedirectStatusSchema>;
type Key = QueryFunctionContext<ReturnType<typeof rssKeys.redirectStatus>>;
type InputProps = {
    showId: string;
};

const queryFn = async ({ queryKey: [{ showId }] }: Key) => {
    const { data } = await checkImportRedirection(showId);
    return FeedRedirectStatusSchema.parse(camelcaseKeys(data, { deep: true }));
};

const useCheckFeedIsRedirectedQuery = ({ showId }: InputProps) => {
    return useQuery({
        queryKey: rssKeys.redirectStatus(showId),
        queryFn,
    });
};

export default useCheckFeedIsRedirectedQuery;
