import ApplePodcastsRankingVariationModel from './ApplePodcastsRankingVariationModel';
import Model from './Model';
import RankingModel from './RankingModel';
import { variation } from './resolvers';

class ApplePodcastsRankingOverallModel extends Model {
    static default = () => ({
        today: null,
        lastWeek: null,
        lastMonth: null,
        beforeLastMonth: null,
        bestRanking: null,
    });

    static attributes = {
        today: variation('today', ApplePodcastsRankingVariationModel, 'position'),
        lastWeek: variation('last_7_days', ApplePodcastsRankingVariationModel, 'position'),
        lastMonth: variation('last_30_days', ApplePodcastsRankingVariationModel, 'position'),
        beforeLastMonth: variation(
            'before_last_30_days',
            ApplePodcastsRankingVariationModel,
            'position',
        ),
        bestRanking: {
            fromApi: {
                key: 'best_position',
                resolve(data, store) {
                    if (data) {
                        return new RankingModel(store, {
                            position: data.best_pos,
                            at: data.best_pos_timestamp,
                        });
                    }
                    return new RankingModel(store);
                },
            },
        },
    };
}

export default ApplePodcastsRankingOverallModel;
