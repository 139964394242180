import { FormattedMessage, FormattedNumber } from 'react-intl';
import { DeprecatedText } from '@ui/atoms/DeprecatedText';
import Cluster from '@ui/layout/Cluster';
import DeprecatedIcon from '@ui/atoms/DeprecatedIcon';
import TooltipHover from '@ui/smarties/TooltipHover';
import HelpIcon from '@public/icons/help.svg';
import Spinner from '@ui/atoms/Spinner';

const ClipQuota = ({ remainingClipsCount, quota, isLoading }) => {
    return (
        <Cluster $gap="0.5rem" $flexWrap="nowrap">
            <Cluster $gap="0.25rem" $flexWrap="nowrap" $align="center">
                <DeprecatedText>
                    <FormattedMessage defaultMessage="Crédits clip restants ce mois-ci :" />
                </DeprecatedText>
                {isLoading ? (
                    <Spinner color="--black" />
                ) : (
                    <DeprecatedText
                        as="strong"
                        weight="bold"
                        color={remainingClipsCount > 0 ? 'black' : 'error'}
                    >
                        <FormattedNumber value={remainingClipsCount} />
                    </DeprecatedText>
                )}
            </Cluster>
            <TooltipHover
                arrow="right"
                target={<DeprecatedIcon as={HelpIcon} size="1.25rem" color="--neutral200" />}
            >
                <DeprecatedText>
                    <FormattedMessage
                        defaultMessage="Chaque 1er du mois, vous obtenez {monthlyClips} crédits clips. Les crédits non-utilisés en fin de mois sont perdus."
                        values={{ monthlyClips: <FormattedNumber value={quota.maximum ?? 0} /> }}
                    />
                </DeprecatedText>
            </TooltipHover>
        </Cluster>
    );
};

export default ClipQuota;
