import ErrorMessage from '@/components/ui/atoms/ErrorMessage';
import Label from '@/components/ui/atoms/Label';
import { RadioGroup, RadioGroupProps } from 'react-aria-components';
import styled from 'styled-components';

interface RadioCardGroupProps extends RadioGroupProps {
    children: React.ReactNode;
    label?: React.ReactNode | string;
    isRequired?: boolean;
    isOptional?: boolean;
    description?: React.ReactNode | string;
    tooltip?: React.ReactNode;
    errorMessage?: React.ReactNode;
}

const RadioCardGroup = ({
    children,
    label,
    isRequired,
    isOptional,
    description,
    tooltip,
    errorMessage,
    ...props
}: RadioCardGroupProps) => {
    const labelProps = {
        isRequired,
        isOptional,
        description,
        tooltip,
    };

    return (
        <Wrapper>
            {label && <Label {...labelProps}>{label}</Label>}
            <CardGroup {...props} $orientation={props.orientation}>
                {children}
            </CardGroup>
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </Wrapper>
    );
};

const CardGroup = styled(RadioGroup)<{ $orientation?: 'horizontal' | 'vertical' }>`
    display: flex;
    flex-direction: column;
    gap: 1rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
    }

    ${({ $orientation }) => $orientation === 'horizontal' && `flex-direction: row !important;`}
    ${({ $orientation }) => $orientation === 'vertical' && `flex-direction: column !important;`}
`;
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
`;

export default RadioCardGroup;
