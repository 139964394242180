import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateChapters } from '@/api';
import { decamelizeKeys } from 'humps';
import chapterKeys from './chapterKeys';
import camelcaseKeys from 'camelcase-keys';
import { NonHostingEpisodeFormSchema } from '@/components/NonHostingEpisodeForm/useNonHostingEpisodeFormSchema.hook';
import { Optional } from '@/shared/types/optional';

type ChapterWithOptionalId = Optional<NonHostingEpisodeFormSchema['chapters'][number], 'id'>;

export interface MutationFnProps {
    episodeId: string;
    chapters: ChapterWithOptionalId[]; // OR hosting chapter schema (once it's available)
}

const mutationFn = async ({ episodeId, chapters }: MutationFnProps) => {
    const { data } = await updateChapters(episodeId, decamelizeKeys(chapters));

    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};
const useUpdateChaptersMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn,
        onSuccess: (_, { episodeId }) => {
            queryClient.invalidateQueries({
                queryKey: chapterKeys.listByEpisodeId(episodeId),
                exact: true,
            });
        },
    });
};

export default useUpdateChaptersMutation;
