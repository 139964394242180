import { computed } from 'mobx';
import Model from './Model';

class ApplePodcastsRankingVariationModel extends Model {
    static default = () => ({
        timestamp: null,
        ranking: null,
        variation: null,
    });

    static attributes = {
        timestamp: 'at',
        ranking: 'position',
        variation: 'variation',
    };

    @computed
    get date() {
        return new Date(this.timestamp * 1000);
    }
}

export default ApplePodcastsRankingVariationModel;
