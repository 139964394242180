import PropTypes from 'prop-types';
import styled from 'styled-components';
import { reflex } from '@ui/reflex';
import shapes from './shapes';
import { resolveColor } from '@/shared/utils/cssVariable';

const DeprecatedButton = styled.button.withConfig({
    shouldForwardProp: (p, defaultValidatorFn) =>
        !['color', 'x', 'y', 'z', 'size'].includes(p) && defaultValidatorFn(p),
})`
    ${shapes};
    ${({ active, background }) =>
        active && `background: ${() => resolveColor(`${background}200`)};`};
    // By default, disabled button prevent mouse event to fire.
    // pointer-events disable this behaviour and allow parent to catch mouseenter and mouseleave events.
    // https://github.com/whatwg/html/issues/2368
    ${(props) => props.disabled && `pointer-events: none;`}
`;

DeprecatedButton.propTypes = {
    accent: PropTypes.oneOf([
        'primary',
        'primarySmall',
        'secondary',
        'secondarySmall',
        'rounded',
        'roundedSmall',
        'ghost',
        'ghostSmall',
        'ripple',
    ]),
    type: PropTypes.string,
    background: PropTypes.string,
    icon: PropTypes.bool,
    rounded: PropTypes.bool,
    fullWidth: PropTypes.bool,
    floating: PropTypes.bool,
    big: PropTypes.bool,
};

DeprecatedButton.defaultProps = {
    accent: 'primary',
    type: 'button',
    background: undefined,
    icon: false,
    rounded: false,
    fullWidth: false,
    floating: false,
    big: false,
};

export default reflex(DeprecatedButton);
