import Text from '@/components/ui/atoms/Text';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { ChannelGuestRow } from './ChannelGuestsTable';
import { FormattedMessage } from 'react-intl';

interface ChannelGuestsInvitationCellProps extends ChannelGuestRow {}

const ChannelGuestsInvitationCell = ({ userName }: ChannelGuestsInvitationCellProps) => {
    return (
        <Cell>
            <AvatarPlaceholder>
                <AvatarPlaceholderIcon icon={icon({ name: 'user-astronaut', style: 'solid' })} />
            </AvatarPlaceholder>
            <UserInformationWrapper>
                <Text numberOfLines={1} fontWeight="--fw-semibold" color="--neutral500">
                    {userName}
                </Text>
                <Text numberOfLines={1} color="--neutral500">
                    <FormattedMessage defaultMessage="Invitation envoyée (en attente)" />
                </Text>
            </UserInformationWrapper>
        </Cell>
    );
};

const AvatarPlaceholder = styled.div`
    display: none;
    width: 2rem;
    height: 2rem;
    border-radius: var(--r-full);
    background-color: var(--neutral50);

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: flex;
        align-items: end;
        justify-content: center;
        overflow: hidden;
        flex-shrink: 0;
    }
`;
const AvatarPlaceholderIcon = styled(FontAwesomeIcon)`
    width: 1.5rem;
    height: 1.5rem;
    color: var(--neutral500);
`;
const UserInformationWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 0;
    flex-grow: 1;
`;
const Cell = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.75rem;
`;

export default ChannelGuestsInvitationCell;
