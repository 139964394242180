import { FormattedMessage } from 'react-intl';
import EpisodesNav from '../EpisodesNav';

import styled from 'styled-components';

interface EpisodesLayoutProps {
    children: React.ReactNode;
}

const EpisodesLayout = ({ children }: EpisodesLayoutProps) => {
    return (
        <EpisodesLayoutWrapper>
            <Sidebar>
                <Title>
                    <FormattedMessage defaultMessage="Episodes" />
                </Title>
                <EpisodesNav />
            </Sidebar>
            <ContentWrapper>{children}</ContentWrapper>
        </EpisodesLayoutWrapper>
    );
};
const EpisodesLayoutWrapper = styled.div`
    width: 100%;
    max-width: var(--container-width);
    padding-inline: var(--container-padding);
    gap: 2rem;
    margin-block: 2rem;
    justify-content: center;

    @media (min-width: 1215px) {
        display: flex;
    }
`;
const ContentWrapper = styled.div`
    display: flex;
    height: max-content;
    flex-direction: column;
    gap: 1rem;
    flex: 1;
`;
const Sidebar = styled.aside`
    display: flex;
    width: 100%;
    gap: 1rem;
    margin-bottom: 1rem;
    justify-content: space-between;

    @media (min-width: 1215px) {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 0;
        width: 15rem;
        height: 20rem;
        position: sticky;
        top: 8.5rem;
        left: 0;
    }
`;
const Title = styled.div`
    font-weight: var(--fw-bold);
    font-size: var(--fs-heading-l);
`;

export default EpisodesLayout;
