import { useState, useMemo } from 'react';
import HeatmapChart from 'react-apexcharts';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useResponsive } from '../../../../utils/hooks/useResponsive';
import DeprecatedText from '../../atoms/DeprecatedText';
import dayjs from 'dayjs';

const dayWeekdFormatddd = defineMessages({
    0: { defaultMessage: 'lun.' },
    1: { defaultMessage: 'mar.' },
    2: { defaultMessage: 'mer.' },
    3: { defaultMessage: 'jeu.' },
    4: { defaultMessage: 'ven.' },
    5: { defaultMessage: 'sam.' },
    6: { defaultMessage: 'dim.' },
});

const dayWeekdFormatdddd = defineMessages({
    0: { defaultMessage: 'Lundi' },
    1: { defaultMessage: 'Mardi' },
    2: { defaultMessage: 'Mercredi' },
    3: { defaultMessage: 'Jeudi' },
    4: { defaultMessage: 'Vendredi' },
    5: { defaultMessage: 'Samedi' },
    6: { defaultMessage: 'Dimanche' },
});

const dayWeekdInital = defineMessages({
    0: { defaultMessage: 'L' },
    1: { defaultMessage: 'M' },
    2: { defaultMessage: 'M' },
    3: { defaultMessage: 'J' },
    4: { defaultMessage: 'V' },
    5: { defaultMessage: 'S' },
    6: { defaultMessage: 'D' },
});

// IF YOU NEED TO CUSTOMIZE THESE COLORS
// ADD PROPS COLORS ON HEATMAPGRAPH COMPONENT
// ex: colors={['#F9F7FA', '#F0EDF2', '#F1E8F9', '#C8A4E5', '#751CBF']}
const colorsRange = ['#F9F7FA', '#F0EDF2', '#F1E8F9', '#C8A4E5', '#751CBF'];

const generatedData = (mapData, nHours, isMobileOrTablet, intl) => {
    let dataDay = [];
    let series = [];
    let i = 0;
    let j = 0;
    while (i < mapData.length) {
        series = [
            ...series,
            {
                x: dayjs(mapData[i].dayHour).format('H'),
                y: mapData[i].downloads,
                tooltipMessage: intl.formatMessage(
                    {
                        defaultMessage:
                            '{count, plural, zero {# écoute} one {# écoute} other {# écoutes}}',
                    },
                    { count: mapData[i].downloads },
                ),
            },
        ];
        if (dayjs(mapData[i].dayHour).format('H') === nHours.toString()) {
            dataDay = [
                ...dataDay,
                {
                    name: isMobileOrTablet
                        ? intl.formatMessage(dayWeekdInital[j])
                        : intl.formatMessage(dayWeekdFormatddd[j]),
                    datedddd: intl.formatMessage(dayWeekdFormatdddd[j]),
                    data: [...series],
                },
            ];
            j += 1;
            series = [];
        }
        i += 1;
    }
    return dataDay.reverse();
};

const generatedColorRange = (categories, colorCustom) => {
    let colorRange = [];
    categories.forEach((cat, j) => {
        colorRange = [
            ...colorRange,
            {
                from: cat.from,
                to: cat.to,
                color: colorCustom ? colorCustom[j] : colorsRange[j],
            },
        ];
    });
    return colorRange;
};

const HeatmapGraph = ({ options, series, maxDownloads, colors, w, h, numberHours, isNoData }) => {
    const intl = useIntl();
    if (!series && !options) return null;
    const [data, setData] = useState();
    const [colorRange, setColorRange] = useState();
    const { isMobileOrTablet } = useResponsive();

    useMemo(() => {
        setData(generatedData(series, numberHours, isMobileOrTablet, intl));
        setColorRange(generatedColorRange(options, colors));
    }, [options, series, isMobileOrTablet, isNoData]);

    const optionsDefault = {
        chart: {
            toolbar: {
                show: false,
            },
            offsetX: isMobileOrTablet ? -20 : 0,
        },
        xaxis: {
            tooltip: {
                enabled: false,
            },
            labels: {
                rotate: -45,
                rotateAlways: isMobileOrTablet,
                trim: false,
                style: {
                    fontSize: isMobileOrTablet ? 8 : 12,
                },
                formatter: (value) => {
                    const dateFrom = dayjs().hour(value);
                    return intl.formatDate(dateFrom, {
                        hour: 'numeric',
                    });
                },
            },
            axisTicks: {
                show: false,
            },
            axisBorder: {
                show: false,
            },
            tickPlacement: 'on',
            offsetY: isMobileOrTablet ? -10 : 0,
        },
        yaxis: {
            labels: {
                style: {
                    fontSize: isMobileOrTablet ? 14 : 12,
                },
            },
        },
        grid: {
            show: true,
            xaxis: {
                lines: {
                    show: false,
                },
            },
            yaxis: {
                lines: {
                    show: false,
                },
            },
        },

        legend: {
            show: false,
        },
        dataLabels: {
            enabled: false,
        },
        plotOptions: {
            heatmap: {
                enableShades: false,
                radius: 5,
                colorScale: {
                    ranges: colorRange,
                    min: 0,
                    max: maxDownloads,
                },
            },
        },
        tooltip: {
            followCursor: true,
            custom: ({ seriesIndex, dataPointIndex }) => {
                const hourOfDay = dayjs().hour(data[seriesIndex].data[dataPointIndex].x);
                const formattedHourOfDay = intl.formatDate(hourOfDay, {
                    hour: 'numeric',
                });
                return `<div class="tooltip-ausha-apexChart">
                        <p class="tooltip-ausha-apexChart-day">${data[seriesIndex].datedddd} ${formattedHourOfDay}</p>
                        <p class="tooltip-ausha-apexChart-listen">${data[seriesIndex].data[dataPointIndex].tooltipMessage}</p>
                    </div>`;
            },
        },
    };

    if (isNoData) {
        return (
            <DeprecatedText weight="bold" align="center" size="big">
                <FormattedMessage defaultMessage="Aucune donnée disponible" />
            </DeprecatedText>
        );
    }

    return (
        <HeatmapChart options={optionsDefault} series={data} type="heatmap" width={w} height={h} />
    );
};

HeatmapGraph.propTypes = {
    options: PropTypes.array,
    series: PropTypes.array,
    maxDownloads: PropTypes.number,
    colors: PropTypes.array,
    isNoData: PropTypes.bool,
    w: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    h: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    numberHours: PropTypes.number,
};

HeatmapGraph.defaultProps = {
    w: '100%',
    h: '330',
    numberHours: 23,
};

export default HeatmapGraph;
