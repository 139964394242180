import { z } from 'zod';

const useLiveSearchFormSchema = () => {
    return z.object({
        keyword: z.string(),
        platform: z.enum(['itunes', 'spotify']),
        mode: z.enum(['episode', 'show', 'popular']),
        store: z.string(),
    });
};

export type LiveSearchForm = z.infer<ReturnType<typeof useLiveSearchFormSchema>>;
export default useLiveSearchFormSchema;
