import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Text from '@ui/atoms/Text';
import QuotaCard from '../QuotaCard';
import ActiveShowsQuotaModal from '@/components/unorganized/ActiveShowsQuotaModal';
import ActiveShowsQuotaConfirmationModal from '@/components/unorganized/ActiveShowsQuotaConfirmationModal';
import useShowsQuery from '@queries/show/useShowsQuery.hook';
import useSubscriptionQuery from '@queries/subscription/useSubscriptionQuery.hook';
import ExternalLink from '@/components/Link/ExternalLink';
import useUpdateShowQuotaMutation from '@queries/quota/useUpdateShowQuotaMutation.hook';
import { useBodyToastQueue } from '@/shared/hooks/useBodyToastQueue.hook';
import styled from 'styled-components';
import { useHistory } from 'react-router';

const ActiveShowsQuota = () => {
    const history = useHistory();
    const intl = useIntl();
    const toast = useBodyToastQueue();
    const [quota, setQuota] = useState<null | number>(null);
    const [isQuotaModalOpen, setIsQuotaModalOpen] = useState(false);
    const [isQuotaConfirmationModalOpen, setIsQuotaConfirmationModalOpen] = useState(false);
    const shows = useShowsQuery();
    const subscription = useSubscriptionQuery();
    const updateShowQuota = useUpdateShowQuotaMutation();

    const activeShowAllowance = subscription?.data?.showsMax;
    const currentActiveShows = shows?.data?.shows?.filter(
        (show: any) => !show.archived && show.isOwner,
    ).length;

    const isFreemium = subscription?.data?.isFreemium;
    const actionLabel = isFreemium ? (
        <FormattedMessage defaultMessage="Changer d'offre" />
    ) : (
        <FormattedMessage defaultMessage="Modifier le quota" />
    );

    const onSubmit = (quota: number) => {
        if (!quota) return;
        updateShowQuota.mutate(
            { quantity: quota },
            {
                onSuccess: () => {
                    toast.success(
                        <FormattedMessage defaultMessage="Votre quota d'émissions a été mis à jour" />,
                    );
                },
                onError: () => {
                    toast.alert(
                        <FormattedMessage defaultMessage="Une erreur est survenue et votre quota n'a pas été modifié, veuillez réessayer plus tard" />,
                    );
                },
                onSettled: () => {
                    setIsQuotaConfirmationModalOpen(false);
                },
            },
        );
    };

    return (
        <>
            <QuotaCard
                titleIcon={<FontAwesomeIcon icon={icon({ name: 'podcast', style: 'solid' })} />}
                title={<FormattedMessage defaultMessage="Émissions actives" />}
                currentValue={currentActiveShows || 0}
                maxValue={activeShowAllowance || 0}
                valueUnit={
                    <FormattedMessage
                        defaultMessage="{count, plural, =0 {émission} one {émission} other {émissions}}"
                        values={{ count: activeShowAllowance }}
                    />
                }
                actionLabel={actionLabel}
                onActionTriggered={() =>
                    isFreemium
                        ? history.push('/app/subscription/offers')
                        : setIsQuotaModalOpen(true)
                }
                infoTooltipContent={
                    <Text>
                        <FormattedMessage
                            defaultMessage="Vous avez {count, plural, =0 {émission active inclue} one {# émission active inclue} other {# émissions actives inclues}} dans votre offre. <link>En savoir plus sur les émissions actives</link>"
                            values={{
                                count: activeShowAllowance,
                                link: (chunks: React.ReactNode) => (
                                    <StyledExternalLink
                                        href={intl.formatMessage({
                                            defaultMessage:
                                                'https://help.ausha.co/fr/articles/2097513-comment-ajouter-une-emission-active-supplementaire-a-mon-compte-ausha',
                                        })}
                                        target="_blank"
                                    >
                                        {chunks}
                                    </StyledExternalLink>
                                ),
                            }}
                        />
                    </Text>
                }
            />
            <ActiveShowsQuotaModal
                isOpen={isQuotaModalOpen}
                onOpenChange={setIsQuotaModalOpen}
                currentActiveShows={currentActiveShows}
                activeShowAllowance={activeShowAllowance}
                onSubmit={({ quota }: { quota: number }) => {
                    setIsQuotaModalOpen(false);
                    setQuota(quota);
                    setIsQuotaConfirmationModalOpen(true);
                }}
            />
            {quota && (
                <ActiveShowsQuotaConfirmationModal
                    isOpen={isQuotaConfirmationModalOpen}
                    onOpenChange={setIsQuotaConfirmationModalOpen}
                    isLoading={updateShowQuota.isLoading}
                    onSubmit={onSubmit}
                    quantity={quota}
                />
            )}
        </>
    );
};

const StyledExternalLink = styled(ExternalLink)`
    color: inherit;
    font-size: inherit;
    text-decoration: underline;
    font-weight: var(--fw-semibold);

    &:hover {
        color: inherit;
    }
    &:active {
        color: inherit;
    }
    &:visited {
        color: inherit;
    }
`;

export default ActiveShowsQuota;
