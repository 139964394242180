import { computed, runInAction } from 'mobx';
import * as Sentry from '@sentry/react';
import * as amplitude from '@amplitude/analytics-browser';
import Store from './Store';
import { fetchChannel, fetchUser, deleteAccount } from '@/api';
import UserModel from '../models/UserModel';
import { apiCall } from '@/components/legacy/api';

class UserStore extends Store {
    static observables = {
        user: null,
    };

    // UI

    @computed
    get locale() {
        return this.user ? this.user.locale : navigator.language.split('-')[0];
    }

    // API

    @apiCall
    async _internalFetchUser() {
        if (!this.state.authStore.isLoggedIn) return;
        const [userData, channel] = await Promise.all([fetchUser(), this.fetchChannel()]);

        runInAction(() => {
            this.user = new UserModel(this, userData?.data);
            Sentry.setUser({
                id: this.user.id,
                username: this.user.fullName,
                email: this.user.email,
            });
            amplitude.setUserId(this.user.id);
            const identify = new amplitude.Identify();
            identify
                .set('lang', this.user.languageCode)
                .set('pricing', this.user.pricing)
                .set('active', this.user.activated);
            amplitude.identify(identify);
            this.user.channelId = channel.id;
            this.user.channelPublicId = channel.public_id;
        });

        await this.state.subscriptionStore.fetchSubscription();
    }

    // A fullscreen loading screen appears when calling this function to block UI during user data fetching
    @apiCall
    async fetchUser() {
        return this._internalFetchUser();
    }

    @apiCall
    async refreshUser() {
        if (this.user) {
            // If user have an active session, refresh tokens in background
            return this._internalFetchUser();
        } else {
            // Fallback to fullscreen loading screen
            return this.fetchUser();
        }
    }

    @apiCall
    async fetchChannel() {
        const channel = await fetchChannel();
        if (!channel) {
            return null;
        }
        return channel.data;
    }

    @apiCall
    deleteAccount(formData) {
        return deleteAccount(formData);
    }
}

export default UserStore;
