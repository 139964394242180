import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useFormContext } from 'react-hook-form';
import aiKeys from '@queries/ai/aiKeys';
import { useQueryClient } from '@tanstack/react-query';
import { useAccordionItem } from '@ui/atoms/AccordionItem/useAccordionItem.hook';
import { useParams } from 'react-router';
import { useEpisodeAILoading } from '@/components/NonHostingEpisodeForm/contexts/NonHostingEpisodeAILoadingContext';
import useAIDescriptionSuggestionsQuery from '@queries/ai/useAIDescriptionSuggestionQuery.hook';
import { useEpisodeAISettings } from '@/components/NonHostingEpisodeForm/contexts/NonHostingEpisodeAISettingsContext';
import { sendAmplitudeLogEvent } from '@/helpers';
import EpisodeAISuggestionsPanel from '../EpisodeAISuggestionsPanel';

interface EpisodeAIDescriptionSuggestionPanelProps {
    setLoadingContent: (content: string) => void;
}
const EpisodeAIDescriptionSuggestionsPanel = ({
    setLoadingContent,
}: EpisodeAIDescriptionSuggestionPanelProps) => {
    const { episodeId } = useParams<{ episodeId: string }>();
    const [settings] = useEpisodeAISettings();
    const descriptionSettings = settings.get('description') || {
        customPrompt: '',
        saveAsDefault: false,
    };
    const descriptionSuggestionsQuery = useAIDescriptionSuggestionsQuery({
        episodeId,
        settings: descriptionSettings,
        delay: 2000,
    });
    const [state] = useEpisodeAILoading();
    const isLoading = state.get('description') || false;
    const queryClient = useQueryClient();
    const { open, isExpanded } = useAccordionItem();
    const { setValue } = useFormContext();

    if (open && !isExpanded && isLoading) {
        open();
    }

    useEffect(() => {
        // If the query is successful and the panel displayed, set the value of the form field
        if (descriptionSuggestionsQuery.data && descriptionSuggestionsQuery.isSuccess) {
            const formattedString = descriptionSuggestionsQuery.data.suggestion.replace(
                /\n+/g,
                '<p></p>',
            );
            setLoadingContent(formattedString);
            setValue('content.htmlDescription', formattedString, {
                shouldDirty: true,
            });
        }
    }, [descriptionSuggestionsQuery.data, descriptionSuggestionsQuery.isSuccess]);

    // If the query is not fetching and there is no data, suggestions panel should not be displayed
    if (!descriptionSuggestionsQuery.data && !isLoading) return null;

    const handleClose = () => {
        return queryClient.resetQueries({ queryKey: aiKeys.description() });
    };

    const handleDislike = () => {
        sendAmplitudeLogEvent('AI Dislike', {
            field: 'description',
            custom_prompt: descriptionSettings.customPrompt || '',
            suggestions: descriptionSuggestionsQuery.data?.suggestion,
        });
    };

    return (
        <EpisodeAISuggestionsPanel
            title={<FormattedMessage defaultMessage="Votre description est prête !" />}
            type="description"
            isLoading={isLoading}
            onClose={handleClose}
            data-testid="episode-ai-description-suggestions-panel"
            onDislike={handleDislike}
        />
    );
};

export default EpisodeAIDescriptionSuggestionsPanel;
