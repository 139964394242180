import { useFormContext } from 'react-hook-form';
import { InputTextController } from '@ui/atoms/InputText';
import { FormattedMessage } from 'react-intl';

const SelectedChapterTitleField = ({ index }) => {
    const { control } = useFormContext();

    return (
        <InputTextController
            name={`chapters.${index}.title`}
            control={control}
            label={<FormattedMessage defaultMessage="Titre du chapitre" />}
            isRequired
        />
    );
};

export default SelectedChapterTitleField;
