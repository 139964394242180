import { ENABLE_PSO_LIVE_SEARCH } from '../config/constants';
import useHasPsoFullUserOption from './useHasPsoFullUserOption.hook';

interface useHasAccessToPsoLiveSearchProps {
    showId: string;
}

const useHasAccessToPsoLiveSearch = ({ showId }: useHasAccessToPsoLiveSearchProps): boolean => {
    const hasPsoFull = useHasPsoFullUserOption({ showId });
    return (ENABLE_PSO_LIVE_SEARCH && hasPsoFull) || false;
};

export default useHasAccessToPsoLiveSearch;
