import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchPodcast } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import episodeKeys from '@/queries/episode/episodeKeys';
import Cookie from 'js-cookie';
import { z } from 'zod';

export const EpisodeSchema = z.object({
    id: z.number(),
    guid: z.string().nullable().optional(),
    youtubeVideo: z
        .object({
            data: z.object({
                id: z.number().nullable().optional(),
                podcastId: z.number().optional(),
                state: z.enum(['pending', 'processing', 'done', 'error', 'draft']).optional(),
                title: z.string().optional(),
                youtubeUrl: z.string().nullable().optional(),
                createdAt: z.string().optional(),
                updatedAt: z.string().optional(),
            }),
        })
        .optional(),
    waveformUrl: z.string().nullable().optional(),
    updatedAt: z.string().nullable().optional(),
    type: z.enum(['full', 'bonus', 'trailer']).optional(),
    tags: z
        .array(
            z.object({
                id: z.number().nullable().optional(),
                slug: z.string().nullable().optional(),
                name: z.string().nullable().optional(),
            }),
        )
        .optional(),
    state: z.enum(['draft', 'blocked', 'scheduled', 'active']),
    smartlinkUrl: z.string().nullable().optional(),
    slug: z.string().nullable().optional(),
    psoScore: z
        .object({
            data: z
                .object({
                    score: z.number(),
                })
                .nullable()
                .optional(),
        })
        .nullable()
        .optional(), // TODO Rename when API is updated
    show: z
        .object({
            archived: z.boolean().nullable().optional(),
            author: z.string().nullable().optional(),
            campaignCount: z.number().nullable().optional(),
            canComment: z.boolean().nullable().optional(),
            category: z.object({
                data: z.object({
                    id: z.number(),
                    parentId: z.number().nullable().optional(),
                    slug: z.string().nullable().optional(),
                    name: z.string().nullable().optional(),
                    appleId: z.number().nullable().optional(),
                }),
            }),
            categoryId: z.number().nullable().optional(),
            channelId: z.number().nullable().optional(),
            clipColor: z.string().nullable().optional(),
            createdAt: z.string().nullable().optional(),
            darkLogoUrl: z.string().nullable().optional(),
            description: z.string().nullable().optional(),
            downloadsCount: z.number().nullable().optional(),
            duration: z.number().nullable().optional(),
            faviconUrl: z.string().nullable().optional(),
            hasPublicActivePodcasts: z.boolean().nullable().optional(),
            htmlDescription: z.string().nullable().optional(),
            id: z.number(),
            imageUrl: z.string().nullable().optional(),
            language: z.object({
                data: z.object({
                    id: z.number().nullable().optional(),
                    code: z.string().nullable().optional(),
                    name: z.string().nullable().optional(),
                    nativeName: z.string().nullable().optional(),
                    isFeatured: z.boolean().nullable().optional(),
                }),
            }),
            languageId: z.number().nullable().optional(),
            lightLogoUrl: z.string().nullable().optional(),
            listeningLinks: z.object({
                data: z.array(
                    z.object({
                        key: z.string().nullable().optional(),
                        url: z.string().nullable().optional(),
                    }),
                ),
            }),
            logoLink: z.string().nullable().optional(),
            name: z.string().nullable().optional(),
            ownerEmail: z.string().nullable().optional(),
            podcastsCount: z.number().nullable().optional(),
            podcastsUpdated: z.string().nullable().optional(),
            publicCounts: z.boolean().nullable().optional(),
            publicId: z.string().nullable().optional(),
            rssLink: z.string().nullable().optional(),
            secondaryCategoryId: z.number().nullable().optional(),
            siteUrl: z.string().nullable().optional(),
            slug: z.string().nullable().optional(),
            socialLinks: z.object({
                data: z.array(
                    z.object({
                        key: z.string().nullable().optional(),
                        url: z.string().nullable().optional(),
                    }),
                ),
            }),
            tags: z.object({
                data: z.array(
                    z.object({
                        id: z.number().nullable().optional(),
                        slug: z.string().nullable().optional(),
                        name: z.string().nullable().optional(),
                    }),
                ),
            }),
            type: z.string().nullable().optional(),
            updatedAt: z.string().nullable().optional(),
            userOptions: z.array(z.string()),
            userRole: z.string().nullable().optional(),
            whiteLabel: z.boolean().nullable().optional(),
        })
        .optional(),
    siteUrl: z.string().nullable().optional(),
    showId: z.number().nullable().optional(),
    seasonId: z.number().nullable().optional(),
    publishedAt: z.string().nullable().optional(),
    publicId: z.string().nullable().optional(),
    privacy: z.enum(['public', 'unlisted', 'private']),
    name: z.string().nullable().optional(),
    isExplicit: z.boolean().nullable().optional(),
    description: z.string().nullable().optional(),
    htmlDescription: z.string().nullable().optional(),
    hasCustomSlug: z.boolean().nullable().optional(),
    files: z
        .array(
            z.object({
                duration: z.number().nullable().optional(),
                format: z.string().nullable().optional(),
                key: z.string().nullable().optional(),
                mimeType: z.string().nullable().optional(),
                originalName: z.string().nullable().optional(),
                size: z.number().nullable().optional(),
            }),
        )
        .optional(),
    duration: z.number().nullable().optional(),
    downloadsCount: z.number().nullable().optional(),
    createdAt: z.string().nullable().optional(),
    canDownload: z.boolean().nullable().optional(),
    audioUrl: z.string().nullable().optional(),
    audioUrlInternal: z.string().nullable().optional(),
    customUrl: z.string().nullable().optional(),
    imageUrl: z.string().nullable().optional(),
    soundcloudPublish: z.boolean().nullable().optional(),
    soundcloudUrl: z.string().nullable().optional(),
    useCustomUrl: z.boolean().nullable().optional(),
    videoUrls: z.array(z.string()).nullable().optional(),
    aiFreeTrialEnabled: z.boolean(),
});
type InputProps = {
    episodeId: string;
    config: {
        staleTime?: number;
        enabled?: boolean;
    };
};
type Key = QueryFunctionContext<ReturnType<typeof episodeKeys.detailById>>;
export type Episode = z.infer<typeof EpisodeSchema>;

const queryFn = async ({ queryKey: [{ episodeId }] }: Key) => {
    const { data } = await fetchPodcast(episodeId);

    // TODO: Do camelcase transformation in Axios interceptor
    const transformedData = camelcaseKeys(data, { deep: true });
    const token = Cookie.get('token');

    let audioUrlInternal;

    try {
        const url = new URL(transformedData.audioUrlInternal);
        if (!token) return;
        url.searchParams.append('token', token);
        audioUrlInternal = url.toString();
    } catch (error) {
        audioUrlInternal = transformedData.audioUrl;
    }

    const formattedData = {
        ...transformedData,
        audioUrlInternal,
        tags: transformedData.tags.data,
        files: transformedData.files.data,
        show: transformedData.show.data,
    };

    return EpisodeSchema.parse(formattedData);
};

const useEpisodeQuery = ({ episodeId, config }: InputProps) => {
    return useQuery({
        queryKey: episodeKeys.detailById(episodeId),
        queryFn,
        enabled: !!episodeId,
        ...config,
    });
};

export default useEpisodeQuery;
