import { FormattedMessage } from 'react-intl';
import PeakTimeBest from '../molecules/PeakTimeBest';
import PeakTimeCurrentMoment from '../molecules/PeakTimeCurrentMoment';
import PeakTimePopularDays from '../molecules/PeakTimePopularDays';
import PeakTimePopularHours from '../molecules/PeakTimePopularHours';
import HideFor from '../atoms/HideFor';
import { connect } from '../decorators/connect';
import { useAmplitudeLogEvent } from '@hooks/useAmplitude';
import StatsTabTitle from '../organisms/StatsTabTitle';
import { TABS_STATS_NAME } from '../pages/StatsPage';
import { USER_OPTIONS } from '@/utils/userOptions';
import Text from '@ui/atoms/Text';
import EmptyState from '@ui/molecules/EmptyState';
import noPeaktimeIllustration from '@public/images/empty-state/illustration/peaktime.mp4';
import ZebraIllustration from '@ui/atoms/ZebraIllustration';
import ShiningDiamond from '@ui/atoms/ShiningDiamond';
import Button from '@ui/atoms/Button';
import IconRocket from '@ui/icons/IconRocket';
import Link from '@ui/atoms/Link';
import styled from 'styled-components';

const currentMessageLaunchTme = {
    7: <FormattedMessage defaultMessage="7 derniers jours" />,
    15: <FormattedMessage defaultMessage="15 derniers jours" />,
    30: <FormattedMessage defaultMessage="30 derniers jours" />,
    90: <FormattedMessage defaultMessage="90 derniers jours" />,
    always: <FormattedMessage defaultMessage="depuis le début" />,
};

const EmptyStateOuterWrapper = styled.div`
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

const HeadIllustrationWrapper = styled.div`
    width: 200px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    & > * {
        text-align: center;
    }
`;

const enhance = connect(({ routerStore, peakTimeStatsStore }) => ({
    daysRangePeakTime: peakTimeStatsStore.daysRangePeakTime,
    upgradeOfferLink: routerStore.link(
        routerStore.params.showId ? 'user.edit.offers' : 'user.offers',
    ),
}));

function PeakTime({ daysRangePeakTime, upgradeOfferLink }) {
    useAmplitudeLogEvent('Stats PeakTime Tab Viewed');
    return (
        <>
            <StatsTabTitle
                tabTitle={<FormattedMessage defaultMessage="PeakTime" />}
                tabId={TABS_STATS_NAME.PEAKTIME}
            />
            <HideFor
                userOptions={USER_OPTIONS.PEAKTIME}
                lockedMessage={
                    <EmptyStateOuterWrapper>
                        <EmptyState
                            illustration={noPeaktimeIllustration}
                            illustrationType="video"
                            illustrationAlign="right"
                            illustrationBackground="--gradient-dusk"
                        >
                            <HeadIllustrationWrapper>
                                <ZebraIllustration>
                                    <ShiningDiamond />
                                </ZebraIllustration>
                            </HeadIllustrationWrapper>
                            <LabelWrapper>
                                <Text variant="heading" fontWeight="--fw-bold">
                                    <FormattedMessage defaultMessage="Passez à l'offre supérieure !" />
                                </Text>
                                <Text variant="body" fontWeight="--fw-normal" color="--neutral500">
                                    <FormattedMessage defaultMessage="Pour débloquer le Peaktime et en savoir plus sur les meilleurs moments pour publier vos épisodes, abonnez-vous à l'offre Boost ou Supersonic 🚀" />
                                </Text>
                            </LabelWrapper>
                            <Button startIcon={<IconRocket />} as={Link} to={upgradeOfferLink}>
                                <FormattedMessage defaultMessage="Mettre à niveau mon offre" />
                            </Button>
                        </EmptyState>
                    </EmptyStateOuterWrapper>
                }
            >
                <PeakTimeBest
                    messageLaunchTime={currentMessageLaunchTme[daysRangePeakTime]}
                    isNoData={false}
                />
                <PeakTimeCurrentMoment
                    messageLaunchTime={currentMessageLaunchTme[daysRangePeakTime]}
                    isNoData={false}
                />
                <PeakTimePopularDays
                    messageLaunchTime={currentMessageLaunchTme[daysRangePeakTime]}
                    isNoData={false}
                />
                <PeakTimePopularHours
                    messageLaunchTime={currentMessageLaunchTme[daysRangePeakTime]}
                    isNoData={false}
                />
            </HideFor>
        </>
    );
}

export default enhance(PeakTime);
