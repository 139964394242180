import IconButton from '@/components/IconButton';
import Modal from '@/components/ui/atoms/Modal';
import Text from '@/components/ui/atoms/Text';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import Stack from '@/components/ui/layout/Stack';
import { ClipFormat } from '@/queries/clipFormat/types';
import { sendAmplitudeLogEvent } from '@/shared/utils/amplitude';
import QRCode from 'qrcode.react';
import { TooltipTrigger } from 'react-aria-components';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';
import ExternalLink from '@/components/Link/ExternalLink';
import Cluster from '@/components/ui/layout/Cluster';

interface QrCodeModalProps {
    isOpen: boolean;
    onOpenChange: (open: boolean) => void;
    clipFormat: ClipFormat;
}

const QrCodeModal = ({ isOpen, onOpenChange, clipFormat }: QrCodeModalProps) => {
    const intl = useIntl();

    if (!clipFormat.url) {
        return null;
    }

    if (isOpen) {
        sendAmplitudeLogEvent('Video Clip Viewed');
    }

    const handleClose = () => onOpenChange(false);

    return (
        <ModalStyled isOpen={isOpen} onOpenChange={onOpenChange} size="medium">
            <Header>
                <Text variant="headingM" fontWeight="--fw-bold">
                    {clipFormat.clip.adminTitle || clipFormat.clip.title}
                </Text>
                <IconButton
                    icon={<CloseIcon icon={icon({ name: 'xmark-large', style: 'solid' })} />}
                    onPress={handleClose}
                    variant="ghost"
                    aria-label="Close modal"
                    style={{ padding: 0 }}
                />
            </Header>
            <ContentWrapper>
                <Video controls poster={clipFormat.previewUrl}>
                    <source src={clipFormat.url} type="video/mp4" />
                </Video>
                <RightWrapper>
                    <QrCodeWrapper>
                        <Cluster $gap="0.25rem" $align="center">
                            <Text>
                                <FormattedMessage defaultMessage="Partager sur mobile" />
                            </Text>
                            <TooltipTrigger delay={0} closeDelay={0}>
                                <TooltipTriggerWrapper>
                                    <TooltipIcon
                                        icon={icon({ name: 'circle-info', style: 'solid' })}
                                    />
                                </TooltipTriggerWrapper>
                                <Tooltip placement="top">
                                    <FormattedMessage
                                        defaultMessage="Scannez ce QR Code pour partager directement le clip où vous le souhaitez. <link>Visionnez le tutoriel</link>"
                                        values={{
                                            link: (chunks: string) => (
                                                <HelpLink
                                                    target="_blank"
                                                    href={intl.formatMessage({
                                                        defaultMessage:
                                                            'https://aide.ausha.co/fr/articles/5159114-comment-creer-personnaliser-et-telecharger-le-clip-video-d-un-episode',
                                                    })}
                                                >
                                                    {chunks}
                                                </HelpLink>
                                            ),
                                        }}
                                    />
                                </Tooltip>
                            </TooltipTrigger>
                        </Cluster>
                        <QRCode value={clipFormat.url} renderAs="svg" size={150} />
                    </QrCodeWrapper>
                    <ExternalLink
                        startIcon={
                            <DownloadIcon
                                icon={icon({ name: 'arrow-down-to-line', style: 'solid' })}
                            />
                        }
                        variant="button-primary"
                        href={clipFormat.url}
                        target="_blank"
                        onPress={() => sendAmplitudeLogEvent('Video Clip Downloaded')}
                    >
                        <FormattedMessage defaultMessage="Télécharger la vidéo" />
                    </ExternalLink>
                </RightWrapper>
            </ContentWrapper>
        </ModalStyled>
    );
};

const ModalStyled = styled(Modal)`
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 2rem;
    overflow-y: auto;
`;
const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 1rem;
`;
const CloseIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--neutral500);
`;
const Video = styled.video`
    width: 100%;
    max-width: 320px;
    border-radius: var(--r-xs);
    display: block;
    align-self: center;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        align-self: flex-start;
    }
`;
const QrCodeWrapper = styled.div`
    display: none;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: flex;
        flex-direction: column;
        row-gap: 0.5rem;
    }
`;
const TooltipIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: var(--neutral500);
`;
const DownloadIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const ContentWrapper = styled(Stack)`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
        column-gap: 2rem;
    }
`;
const RightWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    justify-content: space-between;
`;
const HelpLink = styled.a`
    font-size: inherit;
    color: inherit;
    font-weight: var(--fw-bold);
    text-decoration: underline;
`;

export default QrCodeModal;
