import { FormattedMessage } from 'react-intl';
import { PRICING } from '@/utils/pricing';
import Text from '@ui/atoms/Text';
import { SOUNDCLOUD } from '@/utils/constants';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useParams } from 'react-router';
import useSoundcloudProviderQuery from '@/queries/provider/useSoundcloudProviderQuery.hook';
import RouterLink from '@/components/Link/RouterLink';
import BroadcastButton from '../../BroadcastButton';
import BroadcastSoundcloudLoginModal from '../../BroadcastSoundcloudLoginModal';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import { USER_OPTIONS } from '@/utils/userOptions';
import styled from 'styled-components';

const SoundcloudBroadcastButton = () => {
    const { showId } = useParams<{ showId: string }>();
    const { data: show } = useShowQuery(showId);
    const {
        data: soundcloudAccount,
        isError,
        isLoading: soundcloudAccountIsLoading,
    } = useSoundcloudProviderQuery({ showId });
    const [showSoundcloudLoginModal, setShowSoundcloudLoginModal] = useState(false);
    const hasASoundcloudAccount = soundcloudAccount && !isError;
    const broadcastUrl = hasASoundcloudAccount ? soundcloudAccount.name : undefined;
    const hasSoundcloudUserOption = show?.userOptions.includes(USER_OPTIONS.SOUNDCLOUD);

    /**
     * We found a broadcast URL associated with the user's soundcloud account.
     * We display a link to the configuration page, the broadcast has already been set up.
     */
    if (broadcastUrl) {
        return (
            <RouterLink
                to={`/app/show/${showId}/settings/broadcast/platform/${SOUNDCLOUD}`}
                variant="button-tertiary"
                startIcon={<SoundcloudIcon icon={icon({ name: 'soundcloud', style: 'brands' })} />}
            >
                <FormattedMessage defaultMessage="Configurer" /> SoundCloud
            </RouterLink>
        );
    }

    /**
     * No URL found, we display a "connect" button.
     */
    return (
        <>
            <BroadcastButton
                platformKey={SOUNDCLOUD}
                handleBroadcastSetting={() => setShowSoundcloudLoginModal(true)}
                isLoading={soundcloudAccountIsLoading}
                customLabel={<FormattedMessage defaultMessage="Se connecter à SoundCloud" />}
                isDisabled={!hasSoundcloudUserOption}
                tooltip={
                    <FormattedMessage
                        defaultMessage="Pour diffuser votre podcast sur Soundcloud, vous devez disposer d’une offre {offerIntermediate} ou {offerPremium}."
                        values={{
                            offerIntermediate: (
                                <Text as="span" textTransform="capitalize">
                                    {PRICING.BOOST}
                                </Text>
                            ),
                            offerPremium: (
                                <Text as="span" textTransform="capitalize">
                                    {PRICING.SUPERSONIC}
                                </Text>
                            ),
                        }}
                    />
                }
            />
            <BroadcastSoundcloudLoginModal
                isOpen={showSoundcloudLoginModal}
                onClose={() => setShowSoundcloudLoginModal(false)}
                onSuccess={() => {
                    setShowSoundcloudLoginModal(false);
                    window.location.reload();
                }}
            />
        </>
    );
};

const SoundcloudIcon = styled(FontAwesomeIcon)`
    color: var(--youtube);
    font-size: 1.25rem;
`;

export default SoundcloudBroadcastButton;
