import { computed, observable } from 'mobx';
import dayjs from 'dayjs';
import Model from './Model';
import { apiCall } from '@/components/legacy/api';
require('dayjs/locale/fr');
require('dayjs/locale/en');

class UserModel extends Model {
    @observable channelId;
    @observable channelPublicId;

    static attributes = {
        id: 'id',
        archivedShowsMax: 'archived_shows_max',
        canArchiveShows: 'can_archive_shows',
        avatar: 'avatar',
        firstName: 'firstname',
        lastName: 'lastname',
        email: 'email',
        countryCode: 'country',
        timezoneName: 'timezone',
        showsCount: 'shows_count',
        intercomToken: 'intercom_token',
        isTrackedInIntercom: 'is_tracked_in_intercom',
        languageCode: {
            fromApi: {
                key: 'language',
                resolve: (l) => {
                    dayjs.locale(l.toLowerCase());
                    return l;
                },
            },
        },
        pricing: 'pricing',
        guest: 'guest',
        activatedShowsMax: 'shows_max',
        surveysAnswered: 'surveys',
        // True if user account is created via a Facebook Login or Google Login for example
        isUsingExternalLoginProvider: 'external',
        userOptions: 'options',
        activated: 'activated',
        emailVerifiedAt: 'email_verified_at',
        canHostOnAusha: 'can_host_on_ausha',
    };

    @apiCall
    delete(formData) {
        return this.store.deleteAccount(formData);
    }

    @computed
    get fullName() {
        return [this.firstName, this.lastName].join(' ');
    }

    @computed
    get locale() {
        return this.languageCode.toLowerCase();
    }

    @computed
    get timezone() {
        return this.store.state.timezoneStore.byName(this.timezoneName);
    }

    @computed
    get hasShows() {
        return this.showsCount > 0;
    }

    can(option) {
        return this.userOptions?.includes(option);
    }
}

export default UserModel;
