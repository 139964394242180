import { FormattedMessage } from 'react-intl';
import Text from '@ui/atoms/Text';
import Button from '@/components/Button';
import mainIllustration from '@public/images/empty-state/illustration/distribution.mp4';
import EmptyState from '@ui/molecules/EmptyState';
import ZebraIllustration from '@ui/atoms/ZebraIllustration';
import loveLetterEmoji from '@public/images/emoji/love_letter.png';
import RouterLink from '@/components/Link/RouterLink';
import styled from 'styled-components';
import { useModalToastQueue } from '@/shared/hooks/useModalToastQueue.hook';
import useUserQuery from '@queries/user/useUserQuery.hook';
import useResendVerificationEmailMutation from '@queries/auth/useResendVerificationEmailMutation.hook';

const BroadcastEmailVerificationRequired = () => {
    const { data: user } = useUserQuery();
    const toast = useModalToastQueue();
    const resendVerificationEmail = useResendVerificationEmailMutation();

    return (
        <EmptyStateOuterWrapper>
            <Text fontWeight="--fw-bold" variant="title">
                <FormattedMessage defaultMessage="Diffusion" />
            </Text>
            <EmptyState illustration={mainIllustration} illustrationType="video">
                <HeadIllustrationWrapper>
                    <ZebraIllustration foreground={loveLetterEmoji} />
                </HeadIllustrationWrapper>
                <LabelWrapper>
                    <Text variant="heading" fontWeight="--fw-bold">
                        <FormattedMessage defaultMessage="Pour diffuser votre émission, confirmez votre e-mail" />
                    </Text>
                    <Text
                        variant="body"
                        fontWeight="--fw-normal"
                        color="--neutral500"
                        textAlign="center"
                    >
                        <FormattedMessage
                            defaultMessage="Nous avons envoyé un mail de confirmation à l’adresse <strong>{email}</strong> (<link>modifier</link>)."
                            values={{
                                strong: (chunks: React.ReactNode) => <strong>{chunks}</strong>,
                                email: user?.email,
                                link: (chunks: React.ReactNode) => (
                                    <CustomLink to="/app/user/profile">{chunks}</CustomLink>
                                ),
                            }}
                        />
                    </Text>
                </LabelWrapper>
                <Text color="--neutral500" textAlign="center">
                    <FormattedMessage
                        defaultMessage="Vous n’avez pas reçu d’email ? {resend}"
                        values={{
                            resend: (
                                <ResendButton
                                    variant="ghost"
                                    isLoading={resendVerificationEmail.isLoading}
                                    onPress={() => {
                                        resendVerificationEmail.mutate(undefined, {
                                            onSuccess: () => {
                                                toast.success(
                                                    <FormattedMessage defaultMessage="L’email de confirmation a bien été renvoyé" />,
                                                );
                                            },
                                            onError: () => {
                                                toast.alert(
                                                    <FormattedMessage defaultMessage="L’email de confirmation n’a pas pu être renvoyé" />,
                                                );
                                            },
                                        });
                                    }}
                                >
                                    <FormattedMessage defaultMessage="Renvoyer" />
                                </ResendButton>
                            ),
                        }}
                    />
                </Text>
            </EmptyState>
        </EmptyStateOuterWrapper>
    );
};

const EmptyStateOuterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;
const HeadIllustrationWrapper = styled.div`
    width: 200px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    & > * {
        text-align: center;
    }
`;
const ResendButton = styled(Button)`
    padding: 0;
`;
const CustomLink = styled(RouterLink)`
    text-decoration: underline;
    color: var(--neutral500);
    font-weight: var(--fw-semibold);
`;

export default BroadcastEmailVerificationRequired;
