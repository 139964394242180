import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import PsoDatatable from './PsoDatatable';
import SuggestedKeywordsAlert from './SuggestedKeywordsAlert';
import Header from './Header';
import useIndexedKeywordsQuery from '@/queries/pso/useIndexedKeywordsQuery.hook';
import dayjs from 'dayjs';
import { useAmplitudeLogEvent } from '@/shared/hooks/useAmplitude';
import styled from 'styled-components';
import PsoStoreSelect from './Header/PsoStoreSelect';
import { sendAmplitudeLogEvent } from '@/shared/utils/amplitude';
import PsoStoreEditModal from './Header/PsoStoreEditModal';
import PsoPlatformSelect from './Header/PsoPlatformSelect';

interface PsoKeywordTrackingProps {
    currentStore: string;
    setCurrentStore: (store: string) => void;
    currentPlatform: string;
    setCurrentPlatform: (store: string) => void;
    hasSpotifyData: boolean;
    hasAppleData: boolean;
    userHasWriteAccess: boolean;
    stores: { name: string; alpha2Code: string }[];
}

const PsoKeywordTracking = ({
    currentStore,
    setCurrentStore,
    currentPlatform,
    setCurrentPlatform,
    hasSpotifyData,
    hasAppleData,
    userHasWriteAccess,
    stores,
}: PsoKeywordTrackingProps) => {
    useAmplitudeLogEvent('PSO Keyword Tracking Viewed');
    const { showId }: { showId: string } = useParams();
    const [localShouldDisplayAlert, setLocalShouldDisplayAlert] = useState(false);
    const [showStoreEditModal, setShowStoreEditModal] = useState(false);

    const indexedKeywords = useIndexedKeywordsQuery({ showId, currentStore });

    const shouldDisplayAlert = () => {
        // Get local storage variable
        const rawClosedAt = window.localStorage.getItem('psoSuggestedKeywordsAlertClosedAt');
        // If does not exist -> return true
        if (!rawClosedAt) return true;
        const closedAt = JSON.parse(rawClosedAt);

        const currentShowIdRecord = closedAt.find(
            (record: { showId: string; closedAt: string }) => record.showId === showId,
        )?.closedAt;
        if (!currentShowIdRecord) return true;

        // If exists, parse timestamp and compare with current time
        const difference = dayjs.unix(Number(currentShowIdRecord)).diff(dayjs(), 'day');
        // If difference is greater than 30 days, return true
        if (Math.abs(difference) > 30) return true;

        // Else return false
        return false;
    };

    useEffect(() => {
        if (!indexedKeywords?.data) return;
        setLocalShouldDisplayAlert(shouldDisplayAlert() && indexedKeywords.data.length > 0);
    }, [indexedKeywords?.data]);

    return (
        <PsoDatatableOuterWrapper>
            <Header
                currentStore={currentStore}
                hasSpotifyData={hasSpotifyData}
                hasAppleData={hasAppleData}
            >
                {localShouldDisplayAlert && (
                    <SuggestedKeywordsAlert
                        onClose={() => setLocalShouldDisplayAlert(false)}
                        indexedKeywordsCount={indexedKeywords?.data?.length || 0}
                    />
                )}
                <Filters>
                    <PsoPlatformSelect
                        currentPlatform={currentPlatform}
                        setCurrentPlatform={setCurrentPlatform}
                    />
                    <PsoStoreSelect
                        currentStore={currentStore}
                        stores={stores?.map((store) => store.alpha2Code) || []}
                        setCurrentStore={(store) => {
                            sendAmplitudeLogEvent('PSO Country Switched');
                            setCurrentStore(store);
                        }}
                        editStores={() => setShowStoreEditModal(true)}
                        userHasWriteAccess={userHasWriteAccess}
                    />
                </Filters>
            </Header>
            <PsoDatatableWrapper>
                {currentStore && (
                    <PsoDatatable
                        currentStore={currentStore}
                        currentPlatform={currentPlatform}
                        hasSpotifyData={hasSpotifyData}
                        hasAppleData={hasAppleData}
                    />
                )}
            </PsoDatatableWrapper>
            {showStoreEditModal && (
                <PsoStoreEditModal
                    isOpen={showStoreEditModal}
                    onClose={() => setShowStoreEditModal(false)}
                    existingStores={stores}
                />
            )}
        </PsoDatatableOuterWrapper>
    );
};

const PsoDatatableOuterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;
const PsoDatatableWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    ${({ theme }) => theme.mediaQueries.mobile} {
        padding: var(--container-padding-mobile);
    }
`;
const Filters = styled.div`
    display: flex;
    gap: 0.5rem;
`;

export default PsoKeywordTracking;
