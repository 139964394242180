import { YOUTUBE } from '@/shared/config/constants';

const categoryKeys = {
    all: () => [{ entity: 'category' }],
    allLists: () => [{ ...categoryKeys.all()[0], scope: 'list' }],
    listForYoutube: () => [{ ...categoryKeys.allLists()[0], context: YOUTUBE }],
    listForRss: () => [{ ...categoryKeys.allLists()[0], context: 'rss' }],
};

export default categoryKeys;
