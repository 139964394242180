import { computed } from 'mobx';
import Model from './Model';

class CategoryModel extends Model {
    static attributes = {
        id: 'id',
        name: 'name',
        slug: 'slug',
        isParent: {
            toApi: { key: null },
            fromApi: {
                key: 'subcategories',
                resolve: (cat) => !!cat,
            },
        },
        parentId: 'parent_id',
    };

    @computed
    get parent() {
        if (this.parentId) {
            return this.state.categoryStore.byId(this.parentId);
        }

        return null;
    }
}

export default CategoryModel;
