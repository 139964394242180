import { useState } from 'react';
import Button from '@ui/atoms/Button';
import Text from '@ui/atoms/Text';
import { FormattedMessage } from 'react-intl';
import { Prompt } from 'react-router-dom';
import useRouteGuard from '@hooks/useRouteGuard.hook';
import styled from 'styled-components';
import DialogAlert from '@/components/ui/atoms/DialogAlert';
import { UI_VARIANTS } from '@/utils/constants';

const UnsavedFormModal = ({ shouldBlockNavigation }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const { handleConfirmNavigation, handleBlockedNavigation } = useRouteGuard({
        shouldBlockNavigation,
        onOpenModal: () => setModalIsOpen(true),
        onCloseModal: () => setModalIsOpen(false),
    });

    return (
        <>
            <Prompt when={shouldBlockNavigation} message={handleBlockedNavigation} />
            <DialogAlert
                isOpen={modalIsOpen}
                onOpenChange={setModalIsOpen}
                action={
                    <>
                        <Button onPress={handleConfirmNavigation}>
                            <FormattedMessage defaultMessage="Quitter quand même" />
                        </Button>
                        <CancelButton variant="ghost" onPress={() => setModalIsOpen(false)}>
                            <FormattedMessage defaultMessage="Annuler" />
                        </CancelButton>
                    </>
                }
                variant={UI_VARIANTS.WARNING}
                title={
                    <FormattedMessage defaultMessage="Êtes-vous sûr de vouloir quitter sans enregistrer ?" />
                }
            >
                <Text variant="bodyM" color="--neutral500" textAlign="center">
                    <FormattedMessage defaultMessage="Les modifications apportées seront perdues." />
                </Text>
            </DialogAlert>
        </>
    );
};

const CancelButton = styled(Button)`
    color: var(--neutral500);
    padding: 0;
`;

export default UnsavedFormModal;
