import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Link from '@ui/atoms/Link';
import ItemEpisodesCheckBox from '@ui/molecules/ItemEpisodesCheckBox';
import { connect } from '@app/decorators/connect';
import { useResponsive } from '@/utils/hooks/useResponsive';
import DeprecatedWhitePaper from '@ui/atoms/DeprecatedWhitePaper';
import DeprecatedButton from '@ui/atoms/DeprecatedButton';
import useStatsSelectableEpisodes from '@/utils/hooks/useStatsSelectableEpisodes.hook';
import useMostRecentPublishedEpisodes from '@/utils/hooks/useMostRecentPublishedEpisodes.hook';

const enhance = connect(({ podcastStore, routerStore }) => ({
    fetchShrinkedEpisodes: podcastStore.fetchShrinkedEpisodes,
    link: routerStore.link,
}));

const ItemListDropDownEpisodesCheckbox = ({
    searchBar: SearchBar,
    fetchShrinkedEpisodes,
    link,
    onClose,
    disabledOptions,
}) => {
    const { showId } = useParams();
    const { isMobileOrTablet } = useResponsive();
    const sortMostRecentEpisodes = useMostRecentPublishedEpisodes(showId);
    const [episodes, setEpisodes] = useState([]);
    const [episodeSearchValue, setEpisodeSearchValue] = useState('');
    const [currentPagination, setCurrentPagination] = useState({ perPage: 20, currentPage: 1 });
    const [fetchIsLoading, setFetchIsLoading] = useState(false);
    const [episodesStatsList, setEpisodesStatsList] = useState('');
    const numberOfEpisodesSelectable = useStatsSelectableEpisodes();

    const searchEpisodes = async (query, currentPage, isLoadMore = false) => {
        setFetchIsLoading(true);
        const { episodes: newEpisodes, pagination } = await fetchShrinkedEpisodes(
            showId,
            query,
            currentPage,
            currentPagination.perPage,
            null,
            'active',
        );

        setCurrentPagination(pagination);
        isLoadMore ? setEpisodes([...episodes, ...newEpisodes]) : setEpisodes([...newEpisodes]);
        setFetchIsLoading(false);
    };

    useEffect(() => {
        searchEpisodes('', currentPagination.currentPage);
    }, [showId]);

    const fetchMoreEpisodes = () => {
        if (currentPagination.currentPage === currentPagination.totalPages) return;
        searchEpisodes('', currentPagination.currentPage + 1, true);
    };

    return (
        <>
            <FormattedMessage defaultMessage="Rechercher un épisode…">
                {(placeholder) => (
                    <SearchBar
                        placeholder={placeholder.join('')}
                        value={episodeSearchValue}
                        setValue={(query) => {
                            setEpisodeSearchValue(query);
                            setEpisodes([]);
                            searchEpisodes(query, 1);
                        }}
                    />
                )}
            </FormattedMessage>
            <ItemEpisodesCheckBox
                episodesList={episodes}
                setEpisodesStatsList={setEpisodesStatsList}
                quotaSelectedEpisodes={numberOfEpisodesSelectable}
                optionsSelect={sortMostRecentEpisodes}
                disabled={disabledOptions}
                isLoading={fetchIsLoading}
                showFetchMore={currentPagination.currentPage < currentPagination.totalPages}
                fetchIsLoading={fetchIsLoading}
                fetchMore={fetchMoreEpisodes}
            />
            <DeprecatedWhitePaper
                bt={1}
                p={15}
                flex
                justify="flex-end"
                pos={isMobileOrTablet ? 'fixed' : 'sticky'}
                flexDirection={isMobileOrTablet && 'column-reverse'}
                gap={10}
                bottom={0}
                x={0}
                y={null}
                rounded={false}
            >
                <DeprecatedButton
                    accent="secondarySmall"
                    onClick={onClose}
                    fullWidth={isMobileOrTablet}
                >
                    <FormattedMessage defaultMessage="Annuler" />
                </DeprecatedButton>
                <DeprecatedButton
                    to={
                        episodesStatsList
                            ? link('stats.query', {
                                  queryKeys: 'episode',
                                  queryValues: episodesStatsList,
                              })
                            : link('stats.show')
                    }
                    as={Link}
                    onClick={onClose}
                    accent="primarySmall"
                    fullWidth={isMobileOrTablet}
                >
                    <FormattedMessage defaultMessage="Appliquer" />
                </DeprecatedButton>
            </DeprecatedWhitePaper>
        </>
    );
};

export default enhance(ItemListDropDownEpisodesCheckbox);
