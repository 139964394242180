import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';

interface AIPreferencesIconProps {
    primaryColor?: string;
    secondaryColor?: string;
}

const AIPreferencesIcon = ({ primaryColor, secondaryColor }: AIPreferencesIconProps) => (
    <Icon>
        <FeatherIcon icon={icon({ name: 'feather', style: 'solid' })} $color={primaryColor} />
        <SparkleNumberOneIcon
            icon={icon({ name: 'sparkle', style: 'solid' })}
            $color={secondaryColor}
        />
        <SparkleNumberTwoIcon
            icon={icon({ name: 'sparkle', style: 'solid' })}
            $color={secondaryColor}
        />
    </Icon>
);

const Icon = styled.div`
    position: relative;
    width: 0.75rem;
    height: 0.75rem;
`;

const FeatherIcon = styled(FontAwesomeIcon)`
    position: absolute;
    top: 0;
    left: 0;
    width: 0.75rem;
    height: 0.75rem;
    color: ${(props) => `var(--${props.$color})`};
`;

const SparkleNumberOneIcon = styled(FontAwesomeIcon)`
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 4px;
    opacity: 0.5;
    color: ${(props) => `var(--${props.$color})`};
`;
const SparkleNumberTwoIcon = styled(FontAwesomeIcon)`
    position: absolute;
    bottom: 0;
    right: 0;
    width: 3px;
    height: 3px;
    opacity: 0.5;
    color: ${(props) => `var(--${props.$color})`};
`;

export default AIPreferencesIcon;
