import { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Observer } from 'mobx-react';
import { useResponsive } from '@/shared/hooks/useResponsive';
import { reflex } from '@ui/reflex';
import DeprecatedText from '../DeprecatedText';
import { resolveColor } from '@/shared/utils/cssVariable';

const fallbackCss = css`
    ${(p) =>
        p.focused
            ? css`
                  border: 1px solid var(--primary);
              `
            : css`
                  &:hover {
                      border: 1px solid var(--neutral500);
                  }
              `}
    ${({ readonly }) =>
        readonly &&
        css`
            background: var(--neutral50);
            color: var(--black);
        `}
    ${({ disabled }) =>
        disabled &&
        css`
            pointer-events: none;
            color: var(--neutral500);
        `}
`;

const inputCss = css`
    ::placeholder {
        color: var(--neutral500);
    }
    &:hover {
        border: 1px solid var(--neutral500);
    }
    &:active,
    &:focus {
        border: 1px solid var(--primary);
    }
    &[readonly] {
        background: var(--neutral50);
        color: var(--black);
    }
    &:disabled {
        pointer-events: none;
        color: var(--neutral300);
    }
`;

const InputInput = reflex(styled.input.withConfig({
    shouldForwardProp: (p, defaultValidatorFn) =>
        !['height', 'width', 'color', 'wrap'].includes(p) && defaultValidatorFn(p),
})`
    border-radius: var(--r-xs);
    width: 100%;
    height: auto;
    min-height: 40px;
    color: var(--black);
    font-size: ${(p) => (p.isMobileOrTablet ? 'var(--fs-subheading);' : 'var(--fs-body);')};
    border: 1px solid ${(props) => resolveColor(props.color)};
    background: white;
    -webkit-appearance: none;
    ${(p) => (p.as === 'input' || p.as === 'textarea' || !p.as ? inputCss : fallbackCss)}
    ${(p) =>
        p.as === 'textarea' &&
        css`
            max-width: 100%;
            min-width: 100%;
            min-height: 100px;
            max-height: 500px;
        `}
`);

const DeprecatedInput = forwardRef(({ label, description, ...props }, ref) => {
    const [focused, setFocus] = useState(false);
    const onFocus = () => setFocus(true);
    const onBlur = () => setFocus(false);
    const { isMobileOrTablet } = useResponsive();

    return (
        <>
            {label && <DeprecatedText mb={8}>{label}</DeprecatedText>}
            {description && (
                <DeprecatedText color="--neutral500" mt="-4px" mb={8}>
                    {description}
                </DeprecatedText>
            )}
            <Observer>
                {() => (
                    <InputInput
                        isMobileOrTablet={isMobileOrTablet}
                        focused={focused}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        ref={ref}
                        {...props}
                    >
                        {props.children && props.children({ onFocus, onBlur })}
                    </InputInput>
                )}
            </Observer>
        </>
    );
});

DeprecatedInput.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    accent: PropTypes.oneOf(['primary', 'secondary']),
    label: PropTypes.string,
    description: PropTypes.string,
    children: PropTypes.oneOf([PropTypes.node, PropTypes.func]),
};

DeprecatedInput.defaultProps = {
    px: 10,
    py: 8,
    height: 40,
    color: '--neutral200',
    value: undefined,
    onChange: null,
    required: false,
    accent: 'primary',
};

DeprecatedInput.displayName = 'DeprecatedInput';

export default DeprecatedInput;
