import { FormattedMessage } from 'react-intl';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import DeprecatedButton from '@ui/atoms/DeprecatedButton';
import { connect } from '@/components/legacy/connect';
import FetcherShowTemplate from '@/components/unorganized/FetcherShowTemplate';
import ShortListFallback from '@/components/unorganized/ShortListFallback';
import Form from '@/components/unorganized/Form';
import Spinner from '@ui/atoms/Spinner';
import TemplateEditor from './TemplateEditor';
import ShowSharingTemplateForm from '../../forms/ShowSharingTemplateForm';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import useDeleteShowTemplateMutation from '@queries/showTemplate/useDeleteShowTemplateMutation.hook';

const enhance = connect(({ routerStore, autoSharingStore }) => {
    const template = autoSharingStore.instagramShowTemplate;
    return {
        showId: routerStore.params.showId,
        template,
        onSubmit: (formData) =>
            template.updateShowInstagramTemplate(routerStore.params.showId, formData),
        isLoading: template && template.updateShowInstagramTemplate.pending,
    };
});

function InstagramTemplateShow({ onSubmit, isLoading, template, showId, headerProvider, account }) {
    const deleteShowTemplate = useDeleteShowTemplateMutation();
    return (
        <>
            <FetcherShowTemplate fallback={ShortListFallback} provider="instagram" showId={showId}>
                {(isFetching) =>
                    !isFetching &&
                    template && (
                        <Form form={() => ShowSharingTemplateForm(template)} onSubmit={onSubmit}>
                            {({ fields }) => (
                                <>
                                    <DeprecatedPaper flex justify="space-between">
                                        {headerProvider}
                                        <DeprecatedPaper flex align="flex-end" column gap={4}>
                                            {account && (
                                                <DeprecatedText weight="semibold">
                                                    @{account.username}
                                                </DeprecatedText>
                                            )}
                                            <DeprecatedText
                                                weight="semibold"
                                                color="--primary"
                                                onClick={() => {
                                                    deleteShowTemplate.mutate({
                                                        showId,
                                                        provider: 'instagram',
                                                    });
                                                }}
                                                disabled={template.unlinkInstagram.pending}
                                            >
                                                <FormattedMessage defaultMessage="Délier le compte" />
                                            </DeprecatedText>
                                        </DeprecatedPaper>
                                    </DeprecatedPaper>
                                    <TemplateEditor field={fields.text} />
                                    <DeprecatedButton
                                        mt={30}
                                        mx="auto"
                                        type="submit"
                                        disabled={isLoading}
                                        background="instagram"
                                    >
                                        {isLoading && <Spinner />}
                                        <FormattedMessage defaultMessage="Enregistrer" />
                                    </DeprecatedButton>
                                </>
                            )}
                        </Form>
                    )
                }
            </FetcherShowTemplate>
        </>
    );
}

export default enhance(InstagramTemplateShow);
