import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteFile } from '@/api';
import episodeKeys from '@/queries/episode/episodeKeys';
import camelcaseKeys from 'camelcase-keys';

export interface MutationFnProps {
    episodeId: string;
}

const mutationFn = async ({ episodeId }: MutationFnProps) => {
    const { data } = await deleteFile(episodeId);
    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const useDeleteEpisodeAudioFileMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: (_, { episodeId }) => {
            queryClient.invalidateQueries({
                queryKey: episodeKeys.detailById(episodeId),
            });
        },
    });
};

export default useDeleteEpisodeAudioFileMutation;
