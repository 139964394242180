import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Button from '@/components/Button';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import useOverviewQuery from '@queries/pso/useOverviewQuery.hook';
import dayjs from 'dayjs';
import COUNTRIES_LOOKUP from '@public/geojson/countries.json';
import PsoStoreEditModal from './PsoStoreEditModal';
import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import PsoStoreSelect from './PsoStoreSelect';
import { sendAmplitudeLogEvent } from '@/helpers';
import { APPLE_PODCASTS, SPOTIFY, LISTENING_PLATFORMS_NAMES } from '@/utils/constants';
import Alert from '@ui/atoms/Alert';
import Text from '@ui/atoms/Text';
import styled from 'styled-components';
import ExternalLink from '@/components/Link/ExternalLink';

interface HeaderProps {
    currentStore: string;
    userHasWriteAccess: boolean;
    stores: { name: string; alpha2Code: string }[];
    setCurrentStore: (store: string) => void;
    hasAppleData: boolean;
    hasSpotifyData: boolean;
    children?: React.ReactNode;
}

const Header = ({
    currentStore,
    userHasWriteAccess,
    stores,
    setCurrentStore,
    hasAppleData,
    hasSpotifyData,
    children,
}: HeaderProps) => {
    const { showId }: { showId: string } = useParams();
    const intl = useIntl();
    const show = useShowQuery(showId);
    const overview = useOverviewQuery(
        { showId, currentStore, doesExport: true },
        { enabled: false },
    );
    const [showStoreEditModal, setShowStoreEditModal] = useState(false);

    const handleExport = () => {
        overview.refetch();
    };

    const getPlatforms = () => {
        if (hasAppleData && !hasSpotifyData)
            return {
                on: LISTENING_PLATFORMS_NAMES[APPLE_PODCASTS],
                missing: LISTENING_PLATFORMS_NAMES[SPOTIFY],
            };
        if (hasSpotifyData && !hasAppleData)
            return {
                on: LISTENING_PLATFORMS_NAMES[SPOTIFY],
                missing: LISTENING_PLATFORMS_NAMES[APPLE_PODCASTS],
            };
        return null;
    };

    useEffect(() => {
        if (overview.data) {
            const blobData = overview.data as BlobPart[];
            const url = window.URL.createObjectURL(new Blob(blobData));
            const link = document.createElement('a');
            link.href = url;
            const fileName = `${dayjs().format('MMDDYY')}-${show?.data?.slug}-${COUNTRIES_LOOKUP[
                currentStore as keyof typeof COUNTRIES_LOOKUP
            ]?.label.toLowerCase()}-pso-export.csv`;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        }
    }, [overview?.data]);

    return (
        <>
            {showStoreEditModal && (
                <PsoStoreEditModal
                    isOpen={showStoreEditModal}
                    onClose={() => setShowStoreEditModal(false)}
                    existingStores={stores}
                />
            )}
            <HeaderWrapper>
                <HeaderInnerWrapper>
                    <TitleAndDescription>
                        <TitleWrapper>
                            <TitleIcon icon={icon({ name: 'arrow-trend-up', style: 'solid' })} />
                            <Text variant="headingM" fontWeight="--fw-bold">
                                <FormattedMessage defaultMessage="Suivi des mots-clés" />
                            </Text>
                        </TitleWrapper>
                        <Text color="--neutral500">
                            <FormattedMessage
                                defaultMessage="Le suivi des mots-clés révèle l'évolution en temps réel de vos classements sur les plateformes d'écoute. Surveillez facilement votre progression et restez en tête de la performance de vos mots-clés. <link>En savoir plus.</link>"
                                values={{
                                    link: (chunks: string) => (
                                        <DescriptionLink
                                            href={intl.formatMessage({
                                                defaultMessage:
                                                    'https://help.ausha.co/fr/articles/8517435-le-lexique-du-pso-podcast-search-optimisation',
                                            })}
                                            target="_blank"
                                        >
                                            {chunks}
                                        </DescriptionLink>
                                    ),
                                }}
                            />
                        </Text>
                    </TitleAndDescription>
                    <ActionWrapper>
                        <ButtonWrapper>
                            <PsoStoreSelect
                                currentStore={currentStore}
                                stores={stores?.map((store) => store.alpha2Code) || []}
                                setCurrentStore={(store) => {
                                    sendAmplitudeLogEvent('PSO Country Switched');
                                    setCurrentStore(store);
                                }}
                                editStores={() => setShowStoreEditModal(true)}
                                userHasWriteAccess={userHasWriteAccess}
                            />
                            <Button
                                variant="tertiary"
                                startIcon={
                                    overview.isFetching ? (
                                        <ExportLoadingIcon
                                            icon={icon({
                                                name: 'spinner-third',
                                                style: 'solid',
                                            })}
                                            spin
                                            color="inherit"
                                            size="sm"
                                        />
                                    ) : (
                                        <FontAwesomeIcon
                                            icon={icon({
                                                name: 'arrow-down-to-bracket',
                                                style: 'solid',
                                            })}
                                            color="inherit"
                                            size="sm"
                                        />
                                    )
                                }
                                onPress={handleExport}
                            >
                                <FormattedMessage defaultMessage="Exporter" />
                            </Button>
                        </ButtonWrapper>
                    </ActionWrapper>
                </HeaderInnerWrapper>
                {(!hasSpotifyData || !hasAppleData) && (
                    <Alert
                        variant="info"
                        description={
                            <Text color="--neutral500">
                                <FormattedMessage
                                    defaultMessage="Pour des résultats plus complets et pertinents, diffusez votre podcast également sur la plateforme d'écoute {platform}. 🎧"
                                    values={{
                                        platform: getPlatforms()?.missing || '',
                                    }}
                                />
                            </Text>
                        }
                        icon={
                            <InfoIcon
                                icon={icon({ name: 'circle-info', style: 'solid' })}
                                size="lg"
                            />
                        }
                    >
                        <Text fontWeight="--fw-bold">
                            <FormattedMessage
                                defaultMessage="Votre podcast n’est diffusé que sur {platform}"
                                values={{
                                    platform: getPlatforms()?.on || '',
                                }}
                            />
                        </Text>
                    </Alert>
                )}
                {children}
            </HeaderWrapper>
        </>
    );
};

const HeaderWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding);
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;
const HeaderInnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    gap: 1rem;
`;
const TitleAndDescription = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    flex: 1;
`;
const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.75rem;
`;
const TitleIcon = styled(FontAwesomeIcon)`
    width: 1.25rem;
    height: 1.25rem;
`;
const DescriptionLink = styled(ExternalLink)`
    color: inherit;
    text-decoration: underline;
    font-weight: inherit;

    &[data-hovered='true'] {
        color: inherit;
    }
`;
const ActionWrapper = styled.div``;
const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 1.5rem;
`;
const ExportLoadingIcon = styled(FontAwesomeIcon)`
    color: var(--primary);
`;
const InfoIcon = styled(FontAwesomeIcon)`
    color: var(--info500);
`;

export default Header;
