import FileUploaded from './FileUploaded';
import NoFile from './NoFile';
import styled from 'styled-components';
import AudioFileUploadLoader from '@/components/ui/AudioFileUploadLoader';
import useCampaignQuery from '@/queries/campaign/useCampaignQuery';
import { useParams } from 'react-router';
import uploadCampaignAudioFileMutation from '@/queries/campaign/uploadCampaignAudioFileMutation';
import { useFormContext } from 'react-hook-form';
import { CampaignFormSchema } from '../../useFormSchema';
import Alert from '@/components/ui/atoms/Alert';
import { FormattedMessage } from 'react-intl';
import Text from '@/components/ui/atoms/Text';

const AudioFile = () => {
    const { campaignId } = useParams<{ campaignId: string }>();
    const { data: campaign } = useCampaignQuery(campaignId);
    const uploadedFile = campaign?.files?.[0];
    const uploadFile = uploadCampaignAudioFileMutation();
    const { watch } = useFormContext<CampaignFormSchema>();
    const type = watch('general.type');
    const isHostRead = type === 'hostread';

    if (isHostRead) {
        return (
            <Alert variant="info" defaultIcon>
                <Text fontWeight="--fw-semibold">
                    <FormattedMessage defaultMessage="Vous avez choisi l'option host-read pour votre campagne, aucun fichier audio n'est donc nécessaire." />
                </Text>
            </Alert>
        );
    }

    if (uploadFile.isLoading) {
        return (
            <Container>
                <AudioFileUploadLoader file={null} />
            </Container>
        );
    }

    if (uploadedFile) {
        return (
            <Container>
                <FileUploaded
                    onUpload={uploadFile.mutate}
                    file={uploadedFile}
                    audioUrl={campaign.audioUrl}
                />
            </Container>
        );
    }

    return (
        <Container>
            <NoFile onUpload={uploadFile.mutate} />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    width: 100%;
`;

export default AudioFile;
