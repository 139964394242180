import { useRef, useState } from 'react';
import { useTextField } from 'react-aria';
import IconCheckedCircle from '@ui/icons/IconCheckedCircle';
import IconError from '@ui/icons/IconError';
import IconCopy from '../../icons/IconCopy';
import IconButton from '../IconButton';
import { FormattedMessage, useIntl } from 'react-intl';
import { motion, AnimatePresence } from 'framer-motion';
import Text from '../Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import ErrorMessage from '../ErrorMessage';
import styled, { css } from 'styled-components';

interface InputTextProps {
    elementType?: 'input' | 'textarea';
    label?: string | React.ReactNode;
    description?: string | React.ReactNode;
    isDisabled?: boolean;
    isReadOnly?: boolean;
    inputState?: 'pristine' | 'touched' | 'valid' | 'errors';
    isRequired?: boolean;
    pattern?: string;
    minLength?: number;
    maxLength?: number;
    errorMessage?: string | React.ReactNode;
    autoFocus?: boolean;
    placeholder?: string | React.ReactNode;
    value?: string;
    defaultValue?: string;
    id?: string;
    excludeFromTabOrder?: boolean;
    autoComplete?: 'on' | 'off';
    name?: string;
    type?: 'text' | 'search' | 'url' | 'tel' | 'email' | 'password';
    inputMode?: 'none' | 'text' | 'search' | 'url' | 'tel' | 'email' | 'numeric' | 'decimal';
    characterOverflowBehavior?: 'block' | 'error';
    copyToClipboardEnabled?: boolean;
    emptyButtonEnabled?: boolean;
    inputIcon?: React.ReactNode;
    inputIconBackgroundColor?: string;
    onChange?: (value: string) => void;
    onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onCopy?: (event: React.ClipboardEvent<HTMLInputElement>) => void;
    onCut?: (event: React.ClipboardEvent<HTMLInputElement>) => void;
    onPaste?: (event: React.ClipboardEvent<HTMLInputElement>) => void;
    onCompositionStart?: (event: React.CompositionEvent<HTMLInputElement>) => void;
    onCompositionEnd?: (event: React.CompositionEvent<HTMLInputElement>) => void;
    onCompositionUpdate?: (event: React.CompositionEvent<HTMLInputElement>) => void;
    onSelect?: (event: React.SyntheticEvent<HTMLInputElement>) => void;
    onBeforeInput?: (event: React.FormEvent<HTMLInputElement>) => void;
    onInput?: (event: React.FormEvent<HTMLInputElement>) => void;
    onCopyToClipboard?: () => void;
    tooltip?: React.ReactNode;
    isOptional?: boolean;
    minHeight?: string;
    prefix?: string;
    className?: string;
    customButton?: React.ReactNode;
    uppercase?: boolean;
    min?: number;
    max?: number;
}

const InputText = (props: InputTextProps) => {
    const intl = useIntl();
    let { label, min, max } = props;
    let ref = useRef<HTMLInputElement>();
    // @ts-ignore
    let { labelProps, inputProps, descriptionProps, errorMessageProps } = useTextField(props, ref);
    const isValid = props.inputState === 'valid';
    const hasErrors = props.inputState === 'errors';
    const [hasCopiedToClipboard, setHasCopiedToClipboard] = useState(false);
    const prefixRef = useRef<HTMLInputElement>(null);
    const withIcon =
        isValid || hasErrors || props.copyToClipboardEnabled || props.emptyButtonEnabled;
    const prefixWidth = prefixRef.current?.getBoundingClientRect().width;

    const handleCopyToClipboard = async () => {
        const value = ref.current?.value;
        const copiedValue = props.prefix
            ? `${props.prefix}${props.prefix.endsWith('/') ? '' : '/'}${value}`
            : value;
        if (!copiedValue) return;
        await navigator.clipboard.writeText(copiedValue);
        setHasCopiedToClipboard(true);
        props.onCopyToClipboard && props.onCopyToClipboard();
    };

    return (
        <InputWrapper className={props.className}>
            {props.label && (
                <OuterLabelWrapper>
                    <LabelWrapper>
                        <LabelTooltipWrapper>
                            <Label isRequired={props.isRequired} {...labelProps}>
                                {label}
                                {props.isRequired && (
                                    <Text as="span" fontWeight="--fw-semibold" color="--alert500">
                                        *
                                    </Text>
                                )}
                                {props.isOptional && (
                                    <OptionalLabel>
                                        <FormattedMessage defaultMessage="(facultatif)" />
                                    </OptionalLabel>
                                )}
                            </Label>
                            {props.tooltip}
                        </LabelTooltipWrapper>
                        {props.description && (
                            <Description {...descriptionProps}>{props.description}</Description>
                        )}
                    </LabelWrapper>
                    {props.maxLength && (
                        <CharacterCount
                            hasOverflowed={(props.value?.length ?? 0) > props.maxLength || false}
                        >
                            {props.value?.length || 0}/{props.maxLength}
                        </CharacterCount>
                    )}
                    {props.customButton}
                </OuterLabelWrapper>
            )}
            <InputInnerWrapper>
                {props.inputIcon && (
                    <InputIconWrapper backgroundColor={props.inputIconBackgroundColor}>
                        {props.inputIcon}
                    </InputIconWrapper>
                )}
                {props.prefix && (
                    <InputPrefixWrapper ref={prefixRef}>
                        {/* @ts-ignore */}
                        <Text numberOfLines={1} style={{ maxWidth: '300px' }} color="--neutral500">
                            {props.prefix}
                        </Text>
                    </InputPrefixWrapper>
                )}
                {/* @ts-ignore */}
                <Input
                    ref={ref}
                    {...inputProps}
                    minLength={props.characterOverflowBehavior === 'error' ? null : props.minLength}
                    maxLength={props.characterOverflowBehavior === 'error' ? null : props.maxLength}
                    as={props.elementType}
                    minHeight={props.minHeight}
                    isValid={isValid}
                    hasErrors={hasErrors}
                    hasIcon={!!props.inputIcon}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        props.onChange && props.onChange(event.target.value)
                    }
                    onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) =>
                        props.onKeyUp && props.onKeyUp(event)
                    }
                    onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
                        props.onKeyDown && props.onKeyDown(event)
                    }
                    onFocus={(event: React.FocusEvent<HTMLInputElement>) =>
                        props.onFocus && props.onFocus(event)
                    }
                    onBlur={(event: React.FocusEvent<HTMLInputElement>) =>
                        props.onBlur && props.onBlur(event)
                    }
                    onCopy={(event: React.ClipboardEvent<HTMLInputElement>) => {
                        props.onCopy && props.onCopy(event);
                        setHasCopiedToClipboard(true);
                    }}
                    onCut={(event: React.ClipboardEvent<HTMLInputElement>) =>
                        props.onCut && props.onCut(event)
                    }
                    onPaste={(event: React.ClipboardEvent<HTMLInputElement>) =>
                        props.onPaste && props.onPaste(event)
                    }
                    onCompositionStart={(event: React.CompositionEvent<HTMLInputElement>) =>
                        props.onCompositionStart && props.onCompositionStart(event)
                    }
                    onCompositionEnd={(event: React.CompositionEvent<HTMLInputElement>) =>
                        props.onCompositionEnd && props.onCompositionEnd(event)
                    }
                    onCompositionUpdate={(event: React.CompositionEvent<HTMLInputElement>) =>
                        props.onCompositionUpdate && props.onCompositionUpdate(event)
                    }
                    onSelect={(event: React.SyntheticEvent<HTMLInputElement>) =>
                        props.onSelect && props.onSelect(event)
                    }
                    onBeforeInput={(event: React.FormEvent<HTMLInputElement>) =>
                        props.onBeforeInput && props.onBeforeInput(event)
                    }
                    onInput={(event: React.FormEvent<HTMLInputElement>) =>
                        props.onInput && props.onInput(event)
                    }
                    prefixWidth={prefixWidth}
                    uppercase={props.uppercase}
                    min={min}
                    max={max}
                />

                <AnimatePresence>
                    {hasCopiedToClipboard && (
                        <CopiedToClipboard
                            initial={{ opacity: 1 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ delay: 1 }}
                            onAnimationComplete={() => setHasCopiedToClipboard(false)}
                        >
                            <FormattedMessage defaultMessage="Copié ! 👍" />
                        </CopiedToClipboard>
                    )}
                </AnimatePresence>

                {withIcon && (
                    <IconWrapper>
                        {isValid ? (
                            <IconValid />
                        ) : hasErrors ? (
                            <IconInvalid />
                        ) : props.copyToClipboardEnabled ? (
                            <CopyIconButton
                                onPress={handleCopyToClipboard}
                                icon={<IconCopyToClipboard />}
                                aria-label={intl.formatMessage({
                                    defaultMessage: 'Copier dans le presse-papier',
                                })}
                            />
                        ) : props.emptyButtonEnabled ? (
                            <EmptyInputButton
                                onPress={() => props.onChange && props.onChange('')}
                                icon={
                                    <IconClose
                                        icon={icon({ name: 'circle-xmark', style: 'solid' })}
                                    />
                                }
                                aria-label={intl.formatMessage({
                                    defaultMessage: 'Effacer le champ',
                                })}
                            />
                        ) : null}
                    </IconWrapper>
                )}
            </InputInnerWrapper>
            {props.errorMessage && (
                <ErrorMessage {...errorMessageProps}>{props.errorMessage}</ErrorMessage>
            )}
        </InputWrapper>
    );
};

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    width: 100%;
`;
const OuterLabelWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;

const CharacterCount = styled.span<{ hasOverflowed: boolean }>`
    font-size: 12px;
    color: ${(p) => (p.hasOverflowed ? 'var(--alert)' : 'var(--neutral500)')};
    font-weight: var(--fw-semibold);
`;

const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

const LabelTooltipWrapper = styled.div`
    display: flex;
    column-gap: 0.25rem;
    align-items: center;
    width: 100%;
`;

const Label = styled.label<{ isRequired?: boolean }>`
    font-size: var(--fs-body);
    font-weight: var(--fw-semibold);
    ${(p) =>
        p.isRequired &&
        css`
            display: flex;
            justify-content: start;
            align-items: start;
            column-gap: 0.25rem;
        `}
`;

const Description = styled.div`
    font-size: var(--fs-body);
    color: var(--neutral500);
`;

const InputInnerWrapper = styled.div`
    position: relative;
    width: 100%;
`;

const InputPrefixWrapper = styled.div`
    position: absolute;
    left: 0.25rem;
    top: 0.25rem;
    height: calc(100% - 0.5rem);
    padding: 0.375rem 0.5rem;
    background-color: var(--neutral100);
    border-radius: var(--r-xs);
    max-width: 150px;

    ${({ theme }) => theme.mediaQueries.tabletAndUp} {
        max-width: 300px;
    }
`;

const InputIconWrapper = styled.div<{ backgroundColor?: string }>`
    position: absolute;
    left: 0.25rem;
    top: 0.25rem;
    height: calc(100% - 0.5rem);
    width: calc(40px - 0.5rem);
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${(props) => props.backgroundColor ?? 'var(--neutral50)'};
    border-radius: var(--r-xs);

    & svg {
        display: block;
    }
`;

const Input = styled.input<{
    isValid: boolean;
    hasErrors: boolean;
    hasIcon: boolean;
    prefixWidth: number;
    minHeight: string;
    uppercase: boolean;
    as: 'input' | 'textarea';
}>`
    transition-duration: 0.2s;
    width: 100%;
    height: 40px;
    border-radius: var(--r-s);
    border: 1px solid var(--neutral200);
    padding-block: 10px;
    padding-inline: 12px;

    &:hover {
        transition-duration: 0.2s;
        border-color: var(--neutral400);
    }

    &:focus {
        transition-duration: 0.2s;
        border-color: var(--primary);
        box-shadow: var(--s-primary-light);
    }

    &:read-only {
        background: var(--neutral50);
        border-color: var(--neutral200);
    }

    &:disabled {
        cursor: not-allowed;
        color: var(--neutral500);
        background: var(--white);
    }

    ${(p) =>
        p.isValid &&
        css`
            padding-right: 2.5rem;
            border-color: var(--success);
        `}

    ${(p) =>
        p.hasErrors &&
        css`
            padding-right: 2.5rem;
            border-color: var(--alert);
        `}

    ${(p) =>
        p.hasIcon &&
        css`
            padding-left: 2.5rem;
        `}

    ${(p) =>
        p.prefixWidth &&
        css`
            padding-left: calc(${p.prefixWidth}px + 0.75rem);
        `}

    ${(p) =>
        p.as === 'textarea' &&
        p.minHeight &&
        css`
            min-height: ${p.minHeight};
        `}

        ${({ uppercase }) =>
        uppercase &&
        css`
            text-transform: uppercase;
        `}
`;

const OptionalLabel = styled.span`
    padding-left: 0.25rem;
    font-size: var(--fs-body-m);
    font-weight: var(--fw-normal);
    color: var(--neutral500);
`;

const CopiedToClipboard = styled(motion.span)`
    display: inline-flex;
    align-items: center;
    padding-inline: 1rem;
    position: absolute;
    left: 1px;
    top: 1px;
    border-radius: var(--r-xs);
    background: white;
    height: calc(100% - 2px);
    width: calc(100% - 2px);
`;

const IconWrapper = styled.div`
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 30px;
    padding-inline: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;

    & svg {
        display: block;
    }
`;

const IconValid = styled(IconCheckedCircle)`
    color: var(--success);
`;

const IconInvalid = styled(IconError)`
    color: var(--alert);
`;

const CopyIconButton = styled(IconButton)`
    display: block;
    padding: 0;
    background: none;
    box-shadow: none;
`;
const IconCopyToClipboard = styled(IconCopy)`
    color: var(--neutral500);
`;
const EmptyInputButton = styled(IconButton)`
    display: block;
    padding: 0;
    background: none;
    box-shadow: none;
    font-size: 1rem;
`;
const IconClose = styled(FontAwesomeIcon)`
    color: var(--neutral500);
`;

export default InputText;
