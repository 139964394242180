import ReactPropTypes from 'prop-types';
import { PropTypes } from 'mobx-react';
import createFallback from '@/components/unorganized/createFallback';
import Review, { ReviewFallback } from './Review';
import ReviewModel from '../../models/ReviewModel';
import styled, { css } from 'styled-components';
import NoDataChart from '@/components/charts/NoData/NoDataChart';
import { FormattedMessage } from 'react-intl';

const ReviewsListFallback = createFallback(() => (
    <>
        <ReviewFallback />
        <ReviewFallback />
        <ReviewFallback />
    </>
));

function ReviewsList({ reviews, isLoading }) {
    let content;
    if (isLoading) {
        content = <ReviewsListFallback />;
    } else if (reviews.length === 0) {
        content = (
            <NoDataChartWrapper>
                <NoDataChart
                    title={<FormattedMessage defaultMessage="Vous n'avez pas encore d'avis" />}
                    message={
                        <FormattedMessage defaultMessage="Maximisez la visibilité de votre podcast et encouragez votre audience à partager leurs avis." />
                    }
                />
            </NoDataChartWrapper>
        );
    } else {
        content = (
            <ReviewsListWrapper>
                {reviews.map((review, index) => (
                    // Reviews have no ID and the list is never reordered or filtered,
                    // it is safe to disable ESLint no-array-index-key rule here.
                    // eslint-disable-next-line react/no-array-index-key
                    <Review key={index} review={review} />
                ))}
            </ReviewsListWrapper>
        );
    }

    return content;
}

const WrapperBase = css`
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding);
    margin-block: 2rem;
    gap: 1rem ${({ theme }) => theme.mediaQueries.mobile} {
        padding: var(--container-padding-mobile);
    }
`;
const NoDataChartWrapper = styled.div`
    ${WrapperBase};
    padding: 1rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        padding: 4rem;
    }
`;
const ReviewsListWrapper = styled.div`
    ${WrapperBase};
`;

ReviewsList.propTypes = {
    reviews: PropTypes.arrayOrObservableArrayOf(ReactPropTypes.instanceOf(ReviewModel)).isRequired,
    isLoading: ReactPropTypes.bool,
};

ReviewsList.defaultProps = {
    isLoading: false,
};

export default ReviewsList;
