import ListBoxItem, { ListBoxItem as ListBoxItemType } from '@/components/ui/atoms/ListBoxItem';
import {
    Button as RACButton,
    Select as RACSelect,
    ListBox as RACListBox,
    SelectValue,
    Key,
} from 'react-aria-components';
import React from 'react';
import styled from 'styled-components';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SelectDropdown from '@/components/ui/atoms/Select/SelectDropdown';
import { Control, FieldPath, useController } from 'react-hook-form';
import { ChannelGuestsEditPermissionFormSchema } from './useChannelGuestsEditPermissionFormSchema';

interface ChannelGuestsRoleSelectProps extends React.ComponentProps<typeof RACSelect> {
    control: Control<ChannelGuestsEditPermissionFormSchema>;
    name: FieldPath<ChannelGuestsEditPermissionFormSchema>;
    items: ListBoxItemType[];
}

const ChannelGuestsRoleSelect = ({ control, items, ...props }: ChannelGuestsRoleSelectProps) => {
    const { field } = useController<ChannelGuestsEditPermissionFormSchema>({
        name: props.name,
        control,
    });

    return (
        <SelectWrapper
            {...props}
            selectedKey={field.value as Key}
            onSelectionChange={(id: Key) => {
                props.onSelectionChange?.(id);
                field.onChange(id);
            }}
            aria-label="Select role"
            aria-labelledby="Select role"
        >
            <InputButton>
                <InputSelectValue />
                <ChevronIcon icon={icon({ name: 'chevron-down', style: 'solid' })} />
            </InputButton>
            <Dropdown shouldFlip={false} placement="bottom" offset={4}>
                <ListBox items={items}>{(item) => <ListBoxItem {...item} />}</ListBox>
            </Dropdown>
        </SelectWrapper>
    );
};

const InputButton = styled(RACButton)`
    border-radius: var(--r-s);
    border: none;
    background-color: var(--white);
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    padding: 0;
`;
const InputSelectValue = styled(SelectValue)`
    flex: 1;
    text-align: left;

    .ListBoxItem__inner {
        padding: 0;
    }
`;
const SelectWrapper = styled(RACSelect)`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    cursor: pointer;

    &[data-disabled] {
        opacity: 0.5;
    }

    &[data-invalid] {
        color: var(--alert500);
    }
`;
const ChevronIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: var(--black);
`;
const ListBox = styled(RACListBox)`
    display: flex;
    flex-direction: column;
`;
const Dropdown = styled(SelectDropdown)`
    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        max-width: fit-content;
    }
`;

export default ChannelGuestsRoleSelect;
