import styled from 'styled-components';
import Button from '@/components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FormattedMessage } from 'react-intl';

interface EpisodeEditFormNewsletterPreviewButtonProps extends React.ComponentProps<typeof Button> {}

const EpisodeEditFormNewsletterPreviewButton = (
    props: EpisodeEditFormNewsletterPreviewButtonProps,
) => {
    return (
        <Button
            variant="secondary"
            startIcon={<PreviewButtonIcon icon={icon({ name: 'eye', style: 'solid' })} />}
            {...props}
        >
            <FormattedMessage defaultMessage="Aperçu de la newsletter" />
        </Button>
    );
};

const PreviewButtonIcon = styled(FontAwesomeIcon)`
    color: inherit;
`;

export default EpisodeEditFormNewsletterPreviewButton;
