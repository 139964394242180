import { useMemo, useState } from 'react';
import IconButton from '@/components/IconButton';
import { useDebounce } from '@/shared/hooks/useDebounce';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input, SearchField } from 'react-aria-components';
import styled from 'styled-components';
import { useParams } from 'react-router';
import useEpisodesInfiniteQuery from '@/queries/episode/useEpisodesInfiniteQuery.hook';
import { FormattedMessage, useIntl } from 'react-intl';
import Text from '@/components/ui/atoms/Text';
import Button from '@/components/Button';
import { Episode } from '@/queries/episode/useEpisodesQuery.hook';
import EpisodesList from './EpisodesList';
import Modal from '@/components/ui/atoms/Modal';
import { useFormContext } from 'react-hook-form';
import { CampaignFormSchema } from '../../../useFormSchema';

interface EpisodesModalProps {
    isOpen: boolean;
    onOpenChange: (open: boolean) => void;
}

const EpisodesModal = ({ isOpen, onOpenChange }: EpisodesModalProps) => {
    const { showId } = useParams<{ showId: string; playlistId: string }>();
    const perPage = 20;
    const [searchQuery, setSearchQuery] = useState('');
    const query = useDebounce(searchQuery, 500);
    const episodes = useEpisodesInfiniteQuery({ showId, query, perPage });
    const intl = useIntl();
    const { setValue, watch } = useFormContext<CampaignFormSchema>();
    const defaultSelectedEpisodes = watch('episodes.episodes');
    const [selectedEpisodes, setSelectedEpisodes] = useState<number[]>(defaultSelectedEpisodes);
    const items = useMemo(
        () =>
            episodes?.data?.pages
                ?.reduce((acc, page) => acc.concat(page.episodes), [])
                ?.map((episode: Episode) => ({
                    id: episode.id,
                    name: episode.name,
                    image: episode.imageUrl,
                })) || [],
        [episodes?.data?.pages],
    );

    const lastPage = episodes?.data?.pages.slice(-1)[0];
    const isLoadMoreEnabled =
        (lastPage?.pagination?.currentPage ?? 0) < (lastPage?.pagination?.totalPages ?? 0);

    const handleFetchMoreEpisodes = () => {
        if (!episodes?.hasNextPage || episodes?.isFetchingNextPage || episodes?.isFetching) {
            return;
        }
        episodes?.fetchNextPage();
    };

    const handleSelectEpisode = (id: number) => {
        setSelectedEpisodes((prev) => {
            if (prev.includes(id)) {
                return prev.filter((item) => item !== id);
            }
            return [...prev, id];
        });
    };

    const handleClose = () => onOpenChange(false);

    const handleToggleAllEpisodes = () => {
        if (selectedEpisodes.length === items.length) {
            setSelectedEpisodes([]);
            return;
        }
        setSelectedEpisodes(items.map((item) => item.id));
    };

    const onSubmit = () => {
        setValue('episodes.episodes', selectedEpisodes, {
            shouldValidate: true,
            shouldDirty: true,
        });

        handleClose();
    };

    return (
        <ModalStyled isOpen={isOpen} onOpenChange={onOpenChange} size="medium">
            <Header>
                <Text variant="headingM" fontWeight="--fw-bold">
                    <FormattedMessage defaultMessage="Appliquer la campagne" />
                </Text>
                <IconButton
                    icon={<CloseIcon icon={icon({ name: 'xmark-large', style: 'solid' })} />}
                    onPress={handleClose}
                    variant="ghost"
                    aria-label="Close modal"
                    style={{ padding: 0 }}
                />
            </Header>
            <ScrollableWrapper>
                <StyledSearchField
                    value={searchQuery}
                    onChange={(value: string) => {
                        setSearchQuery(value);
                    }}
                >
                    <SearchIconWrapper>
                        <FontAwesomeIcon
                            icon={icon({ name: 'magnifying-glass', style: 'solid' })}
                        />
                    </SearchIconWrapper>
                    <StyledInput
                        placeholder={intl.formatMessage({
                            defaultMessage: 'Rechercher un épisode...',
                        })}
                        autoComplete="off"
                    />
                    {searchQuery.length > 0 && (
                        <ResetSearchButton
                            icon={<ClearIcon icon={icon({ name: 'xmark', style: 'solid' })} />}
                            variant="ghost"
                            aria-label="Clear search"
                        />
                    )}
                </StyledSearchField>
                {items.length > 0 && (
                    <ToggleAllButton variant="ghost" onPress={handleToggleAllEpisodes}>
                        <FormattedMessage defaultMessage="Tout sélectionner" />
                    </ToggleAllButton>
                )}
                <EpisodesList
                    episodes={items}
                    onSelect={handleSelectEpisode}
                    selectedEpisodes={selectedEpisodes}
                />
                {isLoadMoreEnabled && (
                    <LoadMoreButton onPress={handleFetchMoreEpisodes} variant="ghost">
                        <FormattedMessage defaultMessage="Charger plus" />
                    </LoadMoreButton>
                )}
                <Footer>
                    {selectedEpisodes.length > 0 && (
                        <Text fontWeight="--fw-semibold">
                            <FormattedMessage
                                defaultMessage="Vous allez appliquer la campagne à {count, plural, one {# épisode} other {# épisodes}}."
                                values={{ count: selectedEpisodes.length }}
                            />
                        </Text>
                    )}
                    <Actions>
                        <Button variant="tertiary" onPress={handleClose}>
                            <FormattedMessage defaultMessage="Annuler" />
                        </Button>
                        <Button onPress={onSubmit}>
                            <FormattedMessage defaultMessage="Enregistrer" />
                        </Button>
                    </Actions>
                </Footer>
            </ScrollableWrapper>
        </ModalStyled>
    );
};

const ScrollableWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    width: 100%;
    flex: 1;
    min-height: 0;
    overflow-y: auto;
`;
const StyledSearchField = styled(SearchField)`
    transition-duration: 0.2s;
    flex-grow: 1;
    display: flex;
    align-items: center;
    background-color: var(--neutral100);
    border-radius: var(--r-s);
    padding-inline: 0.5rem;
    border: 1px solid transparent;

    &:focus-within {
        border: 1px solid var(--primary);
        outline: 2px solid var(--primary50);
        background-color: var(--white);
    }

    @media (min-width: 1215px) {
        flex-grow: 0;
    }
`;
const StyledInput = styled(Input)`
    border: 0;
    height: 100%;
    padding-block: 0.75rem;
    background-color: transparent;
    min-width: 12rem;
    width: 100%;
`;
const SearchIconWrapper = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    padding-inline: 0.75rem;
    color: var(--neutral500);
`;
const ResetSearchButton = styled(IconButton)`
    transition-duration: 0.2s;
    color: var(--neutral500);
    border-radius: var(--r-s);
    height: 1.75rem;
    width: 1.75rem;
    cursor: pointer;
    padding: 0;
    &:hover {
        background-color: var(--neutral200);
        color: var(--black);
    }
`;
const ClearIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: inherit;
`;
const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 1rem;
`;
const Actions = styled.div`
    display: flex;
    column-gap: 0.5rem;
    margin-left: auto;
`;
const LoadMoreButton = styled(Button)`
    padding: 0;
`;
const ModalStyled = styled(Modal)`
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 2rem;
`;
const Header = styled.div`
    display: flex;
    justify-content: space-between;
`;
const CloseIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--neutral500);
`;
const ToggleAllButton = styled(Button)`
    padding: 0;
    align-self: flex-start;
`;

export default EpisodesModal;
