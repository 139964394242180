import { z } from 'zod';

const useLinkedinTemplateFormSchema = () => {
    return z.object({
        messageTpl: z.string(),
    });
};

export type LinkedinTemplateFormSchema = z.infer<ReturnType<typeof useLinkedinTemplateFormSchema>>;

export default useLinkedinTemplateFormSchema;
