import { useMutation } from '@tanstack/react-query';
import { updateShowTemplate } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import { decamelizeKeys } from 'humps';
import { NewsletterFormSchema } from '@/components/Newsletter/NewsletterForm/useNewsletterFormSchema';
import { ShowNewsletterTemplate } from './useShowNewsletterTemplateQuery.hook';

interface UpdateShowNewsletterTemplateMutationFnProps {
    showId: string;
    template: Omit<NewsletterFormSchema, 'callToActions'>;
}

const mutationFn = async ({
    showId,
    template,
}: UpdateShowNewsletterTemplateMutationFnProps): Promise<ShowNewsletterTemplate> => {
    const { data } = await updateShowTemplate(showId, 'newsletter', {
        params: decamelizeKeys(template),
    });

    return camelcaseKeys(data as any, { deep: true });
};

const updateShowNewsletterTemplateMutation = () => {
    return useMutation({
        mutationFn,
    });
};

export default updateShowNewsletterTemplateMutation;
