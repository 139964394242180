import Color from 'color';

export const isDark = (color: string) => {
    try {
        return Color(color).isDark();
    } catch (error) {
        // Unable to parse input as a color
        return false;
    }
};

export const isLight = (color: string) => {
    try {
        return Color(color).isLight();
    } catch (error) {
        // Unable to parse input as a color
        return false;
    }
};

/**
 * Given an input hex code and a desired alpha level, compute the blend mode for this color.
 * Returns a hex code representing the input color with the desired alpha level baked-in, blended on a white background.
 */
export const blendAlphaChannel = (baseColor: string, desiredAlpha: number = 10) => {
    return Color('rgb(255, 255, 255)')
        .mix(Color(baseColor), desiredAlpha / 100)
        .hex();
};
