import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import newsletterKeys from '@queries/newsletter/newsletterKeys';
import { fetchNewsletterExportSubscribersCsv } from '@/api';
import { z } from 'zod';

const NewsletterExportSubscribersSchema = z.object({
    filename: z.string(),
    subscribers: z.string(),
});

type Key = QueryFunctionContext<ReturnType<typeof newsletterKeys.exportSubscribersByShowId>>;

const queryFn = async ({ queryKey: [{ showId }] }: Key) => {
    const { axios, ...data } = await fetchNewsletterExportSubscribersCsv(showId);
    const filename = axios.headers['content-disposition'].match(/filename="(.+)"/)[1];

    const formattedData = {
        filename: filename,
        subscribers: Object.values(data).join(''),
    };

    return NewsletterExportSubscribersSchema.parse(formattedData);
};
const useNewsletterExportSubscribersCsvQuery = (showId: string) =>
    useQuery({
        queryKey: newsletterKeys.exportSubscribersByShowId(showId),
        queryFn,
    });

export default useNewsletterExportSubscribersCsvQuery;
