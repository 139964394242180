import { Editor, findChildren } from '@tiptap/react';

/**
 * Deletes the speaker attribute for all paragraphs that have a specific speaker value.
 */
const deleteSpeakerForAllParagraphs = (editor: Editor, speaker: number) => {
    const transaction = editor.state.tr;
    const doc = editor.state.doc;

    let hasChanges = false;

    findChildren(
        doc,
        (node) => node.type.name === 'paragraph' && node.attrs.speaker === speaker,
    ).forEach(({ pos }) => {
        const updatedAttrs = { ...doc.nodeAt(pos)!.attrs, speaker: null };
        transaction.setNodeMarkup(pos, null, updatedAttrs);

        hasChanges = true;
    });

    if (hasChanges) {
        editor.view.dispatch(transaction);
    }
};

export default deleteSpeakerForAllParagraphs;
