import { FormattedMessage } from 'react-intl';
import aushaClubLoginIllustration from '@public/images/club_ausha_illustration.jpg';
import aushaClubLogo from '@public/images/ausha-club-logo.png';
import Form from './Form';
import GoogleButton from '@/shared/components/GoogleButton';
import FacebookButton from '@/shared/components/FacebookButton';
import { useEffect } from 'react';
import useQuery from '@/shared/hooks/useQuery';
import { requestAuthorizationCode } from '@/api';
import styled from 'styled-components';
import { useAuthContext } from '@/context/AuthContext';

const AushaClubCallback = () => {
    const params = useQuery();
    const { isAuthenticated } = useAuthContext();
    const clientId = params.get('client_id');
    const redirectUri = params.get('redirect_uri');
    const responseType = params.get('response_type');
    const scope = params.get('scope');

    const requestCode = async () => {
        // Request API to retrieve a validation code associated to this authentication
        const res = await requestAuthorizationCode({
            client_id: clientId,
            redirect_uri: redirectUri,
            response_type: responseType,
            scope,
        });

        return res?.data?.code;
    };

    const redirectToCircle = (code: string) => {
        if (!code) return;
        // Redirect to Circle with given code
        window.location.assign(`${redirectUri}?code=${code}`);
    };

    const circleLogin = async () => {
        const code = await requestCode();
        redirectToCircle(code);
    };

    useEffect(() => {
        if (!isAuthenticated) return;
        circleLogin();
    }, [clientId, redirectUri, responseType, scope]);

    if (isAuthenticated) return null;

    return (
        <LoginWrapper>
            <Left>
                <InnerLeft>
                    <Header>
                        <Logo src={aushaClubLogo} />
                        <Title data-testid="login-heading">
                            <FormattedMessage defaultMessage="Bienvenue sur le Club Ausha ! 👋" />
                        </Title>
                    </Header>
                    <>
                        <ThirdPartyLoginWrapper>
                            <GoogleButton
                                label={
                                    <FormattedMessage defaultMessage="Se connecter avec Google" />
                                }
                            />
                            <FacebookButton
                                label={
                                    <FormattedMessage defaultMessage="Se connecter avec Facebook" />
                                }
                                rounded={true}
                            />
                        </ThirdPartyLoginWrapper>
                        <Separator>
                            <Line />
                            <FormattedMessage defaultMessage="Ou connectez-vous par email" />
                            <Line />
                        </Separator>
                    </>
                    <Form
                        onAuthenticate={() => {
                            circleLogin();
                        }}
                    />
                </InnerLeft>
            </Left>
            <Right>
                <InstructionsInnerWrapper>
                    <Illustration src={aushaClubLoginIllustration} />
                </InstructionsInnerWrapper>
            </Right>
        </LoginWrapper>
    );
};

const LoginWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding: 2rem;
    min-height: 100%;
    background-color: var(--white);
    & > * {
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 50%;
    }
`;
const Container = styled.div`
    align-self: stretch;
    padding: 1.5rem;
    overflow: hidden;
    max-width: 100%;
    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        padding-inline: 4rem;
        padding-top: 4rem;
        padding-bottom: 0;
    }
`;
const Left = styled(Container)`
    display: flex;
    justify-content: center;
    align-items: center;
`;
const InnerLeft = styled.div`
    width: 100%;
    max-width: 28rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;
const Header = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;
const Logo = styled.img`
    width: 140px;
    height: auto;
`;
const Right = styled(Container)`
    display: none;
    background: var(--gradient-aurora);
    border-radius: var(--r-xl);
    padding: 0;
    @media (min-width: 900px) {
        display: block;
    }
`;

const InstructionsInnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;
const Title = styled.h1`
    font-size: var(--fs-heading-m);
`;
const Illustration = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: var(--r-xl);
`;
const ThirdPartyLoginWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;
const Separator = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    color: var(--neutral);
`;
const Line = styled.span`
    height: 1px;
    background-color: var(--neutral100);
    flex: 1;
`;

export default AushaClubCallback;
