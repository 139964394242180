import { computed } from 'mobx';
import Model from './Model';
import { apiCall } from '@/components/legacy/api';
import { date } from './resolvers';
import { CLIP_FORMATS, CLIP_STATUS } from '@/shared/config/constants';

class ClipModel extends Model {
    static default = ({ routerStore }) => ({
        episodeId: routerStore.params.podId,
        start: 0,
        end: 30,
        duration: 30,
        transcript: true,
        title: '',
        caption: '',
    });

    static attributes = {
        id: 'id',
        episodeId: 'podcast_id',
        step: 'step',
        start: 'start',
        end: 'end',
        duration: 'duration',
        transcript: 'transcript',
        adminTitle: 'admin_title',
        formats: {
            fromApi: {
                key: 'formats',
                resolve: (formats) =>
                    formats
                        ? formats.data.map((format) => ({ ...format, images: format.images.data }))
                        : [],
            },
            toApi: {
                key: 'formats',
                resolve: (formats) => (formats ? formats.map((format) => ({ ...format })) : []),
            },
        },
        color: 'color',
        title: 'title',
        caption: 'caption',
        // status: 'state',
        // url: 'url',
        // imageUrl: 'image_url',
        // previewUrl: 'preview_url',
        lang: 'lang',
        createdAt: date('created_at'),
        // fullCover: 'full_cover',
        showTitles: 'show_titles',
        showWaveform: 'show_waveform',
        titleColor: 'title_color',
    };

    /* ---- States ---- */

    @computed
    get isPending() {
        return this.formats.every((format) => format.state === CLIP_STATUS.PENDING);
    }

    @computed
    get isLoading() {
        return this.status === CLIP_STATUS.LOADING;
    }

    @computed
    get succeeded() {
        return this.status === CLIP_STATUS.DONE;
    }

    @computed
    get failed() {
        return this.status === CLIP_STATUS.ERROR;
    }

    @apiCall
    updateOrCreate(formData) {
        return this.store.updateOrCreateClip(this, formData);
    }

    @apiCall
    refresh() {
        return this.store.fetchClip(this.id);
    }

    @apiCall
    generate() {
        return this.store.generateClip(this);
    }

    @apiCall
    delete() {
        return this.store.deleteClip(this);
    }

    @computed
    get episode() {
        return this.state.podcastStore.byId(this.episodeId);
    }

    @computed
    get sortedFormats() {
        const formats = [];
        const squareFormat = this.formats.find((item) => item.format === CLIP_FORMATS.SQUARE);
        const storyFormat = this.formats.find((item) => item.format === CLIP_FORMATS.STORY);
        const landscapeFormat = this.formats.find((item) => item.format === CLIP_FORMATS.LANDSCAPE);

        if (squareFormat) {
            formats.push(squareFormat);
        }
        if (storyFormat) {
            formats.push(storyFormat);
        }
        if (landscapeFormat) {
            formats.push(landscapeFormat);
        }

        return formats;
    }

    setAdminTitle(title) {
        this.adminTitle = title;
    }
}

export default ClipModel;
