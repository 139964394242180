import useShowQuery from '@queries/show/useShowQuery.hook';
import { ENABLE_EPISODE_AI, USER_ROLES } from '../config/constants';
import useUserQuery from '@queries/user/useUserQuery.hook';
import { USER_OPTIONS } from '../config/userOptions';

interface HasAccessToEpisodeAIProps {
    showId: string;
}

const useHasAccessToEpisodeAI = ({ showId }: HasAccessToEpisodeAIProps) => {
    const { data: show } = useShowQuery(showId);
    const { data: user } = useUserQuery();

    const guestWithAccess =
        show?.userRole !== USER_ROLES.OWNER &&
        show?.userRole !== USER_ROLES.VIEWER &&
        show?.userOptions.includes(USER_OPTIONS.EPISODE_AI);
    const ownerWithAccess =
        show?.userRole === USER_ROLES.OWNER && user?.options.includes(USER_OPTIONS.EPISODE_AI);
    const hasEpisodeAIUserOption = guestWithAccess || ownerWithAccess;

    return ENABLE_EPISODE_AI && hasEpisodeAIUserOption;
};

export default useHasAccessToEpisodeAI;
