import * as yup from 'yup';

const useSoundcloudBroadcastSettingsFormSchema = () => {
    return yup.object({
        feedable: yup.bool(),
        downloadable: yup.bool(),
    });
};

export default useSoundcloudBroadcastSettingsFormSchema;
