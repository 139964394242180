import PropTypes from 'prop-types';
import DialogAlert from '@ui/atoms/DialogAlert';
import Button from '@ui/atoms/Button';
import { FormattedMessage } from 'react-intl';
import Text from '@ui/atoms/Text';
import { UI_VARIANTS } from '@/shared/config/constants';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import useTranscriptShowQuotaQuery from '@queries/quota/useTranscriptShowQuotaQuery.hook';
import { useParams } from 'react-router';

const EpisodeEditTranscriptionGenerateModal = ({
    isOpen,
    onOpenChange,
    isLoading,
    handleGenerateTranscript,
    duration,
}) => {
    const { showId } = useParams();
    const { data: quota } = useTranscriptShowQuotaQuery(showId);
    const remainingTime = Math.floor(quota?.maximum - quota?.total);

    return (
        <DialogAlert
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            action={
                <>
                    <Button isLoading={isLoading} onPress={handleGenerateTranscript}>
                        <FormattedMessage defaultMessage="Générer la transcription" />
                    </Button>
                    <CancelButton variant="ghost" onPress={() => onOpenChange(false)}>
                        <FormattedMessage defaultMessage="Annuler" />
                    </CancelButton>
                </>
            }
            title={
                <FormattedMessage defaultMessage="Souhaitez-vous générer la transcription de cet épisode ?" />
            }
            icon={<Icon icon={icon({ name: 'subtitles', style: 'solid' })} />}
            variant={UI_VARIANTS.PRIMARY}
        >
            <Text variant="bodyM" color="--neutral500" textAlign="center">
                <FormattedMessage
                    defaultMessage="{deductedTime, plural, 
                        =1 {La génération déduira {deductedTime} minute}
                        other {La génération déduira {deductedTime} minutes}
                        } sur votre temps de transcription restant de {remainingTime, plural, 
                            =1 {# minute}
                            other {# minutes}
                        }."
                    values={{ deductedTime: duration, remainingTime }}
                />
            </Text>
        </DialogAlert>
    );
};

const CancelButton = styled(Button)`
    color: var(--neutral500);
    padding: 0;
`;
const Icon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--primary500);
`;

EpisodeEditTranscriptionGenerateModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onOpenChange: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    handleGenerateTranscript: PropTypes.func.isRequired,
    duration: PropTypes.string.isRequired,
};

EpisodeEditTranscriptionGenerateModal.defaultProps = {
    isOpen: false,
    isLoading: false,
    onOpenChange: () => {},
    handleGenerateTranscript: () => {},
};

export default EpisodeEditTranscriptionGenerateModal;
