import { useMutation, useQueryClient } from '@tanstack/react-query';
import useUpdateEpisodeMutation from '@queries/episode/useUpdateEpisodeMutation.hook';
import { DEBUG_EPISODE_EDIT_FORM_SAVE } from '@/shared/config/constants';
import episodeKeys from '@queries/episode/episodeKeys';
import youtubeVideoKeys from '@queries/youtubeVideo/youtubeVideoKeys';
import aiKeys from '@/queries/ai/aiKeys';
import { NonHostingEpisodeFormSchema } from './useNonHostingEpisodeFormSchema.hook';
import useUpdateChaptersMutation from '@/queries/chapter/useUpdateChaptersMutation.hook';
import useAddChapterImageMutation from '@/queries/chapter/useAddChapterImageMutation.hook';
import useDeleteChapterImageMutation from '@/queries/chapter/useDeleteChapterImageMutation.hook';
import addEpisodeMessagesMutation from '@/queries/episode/updateEpisodeMessagesMutation';

const debug = (...args) => {
    if (DEBUG_EPISODE_EDIT_FORM_SAVE) {
        console.log(...args);
    }
};

type EpisodeData = NonHostingEpisodeFormSchema['content'];
interface Messages {
    newsletter?: NonHostingEpisodeFormSchema['newsletter'];
    socialPosts?: NonHostingEpisodeFormSchema['socialPosts'];
}

interface SaveNonHostingEpisodeMutationProps {
    episodeId: string;
    episode: EpisodeData;
    chapters?: NonHostingEpisodeFormSchema['chapters'];
    chaptersIdsRequiringImageDeletion: number[];
    messages?: Messages;
}

const saveNonHostingEpisodeMutation = () => {
    const queryClient = useQueryClient();
    const updateEpisodeMutation = useUpdateEpisodeMutation();
    const updateChaptersMutation = useUpdateChaptersMutation();
    const addChapterImageMutation = useAddChapterImageMutation();
    const deleteChapterImageMutation = useDeleteChapterImageMutation();
    const addMessagesMutation = addEpisodeMessagesMutation();

    return useMutation({
        mutationFn: async ({
            episodeId,
            episode,
            chapters,
            chaptersIdsRequiringImageDeletion,
            messages,
        }: SaveNonHostingEpisodeMutationProps) => {
            if (episode && Object.keys(episode).length > 0) {
                debug(`[Episode form] Saving episode ${episodeId}…`);

                await updateEpisodeMutation.mutateAsync(
                    {
                        episodeId,
                        episode: {
                            ...episode,
                            name: episode.name || undefined, // Allows to save form event if name is an empty string
                        },
                    },
                    {
                        onSuccess: (data, variables, context) => {
                            debug(`[Episode form] Episode ${episodeId} saved with success!`, {
                                data,
                                variables,
                                context,
                            });
                        },
                        onError: (error, variables, context) => {
                            debug(`[Episode form] Failed to save episode ${episodeId}`, {
                                error,
                                variables,
                                context,
                            });
                        },
                    },
                );
            }

            if (chapters) {
                const sortedChapters = chapters
                    .slice()
                    .sort((chapter1, chapter2) => chapter1.startTime - chapter2.startTime);

                debug(`[Episode form] Saving chapters for episode ${episodeId}…`);
                await updateChaptersMutation.mutateAsync(
                    {
                        episodeId,
                        chapters: sortedChapters.map(({ id, ...chapterWithoutId }) => {
                            // If the chapter have an ID generated by the front end, we remove it from the request
                            if (`${id}`.startsWith('local-chapter')) {
                                return chapterWithoutId;
                            }

                            // The chapter already have a back end ID, and we should send it in the request
                            return {
                                id,
                                ...chapterWithoutId,
                            };
                        }),
                    },
                    {
                        onSuccess: async (data, variables, context) => {
                            debug(
                                `[Episode form] Chapters for episode ${episodeId} saved with success!`,
                                { data, variables, context },
                            );
                            // Chapters are saved but new images are not saved yet.
                            // To upload an image we need a chapter ID. If the image to upload is associated to a new chapter,
                            // we need a mapping between the front end generated chapter ID (local-chapter-XXX) and the back end ID.
                            //
                            // Example of mapping :
                            // 1234                 -> 1234 (chapter already exist and was fetch by API)
                            // 5678                 -> 5678 (chapter already exist and was fetch by API)
                            // local-chapter-192837 -> 9046 (new chapter saved in the updateEpisodeChaptersMutation)
                            const mapChapterIds = new Map();
                            sortedChapters.forEach(({ id: localId }, index) => {
                                mapChapterIds.set(localId, data[index]?.id);
                            });

                            const uploadPromises: Promise<any>[] = [];
                            sortedChapters.forEach((chapter) => {
                                // Searching chapters ID mapping to get back end chapter ID
                                const chapterId = mapChapterIds.get(chapter.id);

                                if (chapter.imageUrl instanceof File) {
                                    debug(`[Episode form] Saving image for chapter ${chapterId}…`);
                                    const promise = addChapterImageMutation.mutateAsync({
                                        chapterId,
                                        image: chapter.imageUrl,
                                        episodeId,
                                    });
                                    uploadPromises.push(promise);
                                }
                            });

                            const deletePromises: Promise<any>[] = [];
                            // Delete chapter images if necessary
                            chaptersIdsRequiringImageDeletion.forEach((chapterId) => {
                                debug(`[Episode form] Deleting image for chapter ${chapterId}…`);
                                const promise = deleteChapterImageMutation.mutateAsync({
                                    chapterId,
                                    episodeId,
                                });
                                deletePromises.push(promise);
                            });

                            await Promise.all([...uploadPromises, ...deletePromises]);
                        },
                        onError: (error, variables, context) => {
                            debug(
                                `[Episode form] Failed to save chapters for episode ${episodeId}`,
                                { error, variables, context },
                            );
                        },
                    },
                );
            }

            if (messages) {
                const { newsletter, ...socialPosts } = messages;
                debug(
                    `[Episode form] Saving newsletter and social posts for episode ${episodeId}…`,
                );
                await addMessagesMutation.mutateAsync({
                    episodeId,
                    messages: {
                        ...socialPosts,
                        newsletter,
                    },
                });
            }
        },
        onSuccess: (_, { episodeId }) => {
            debug(`[Episode form] Invalidating query for episode ${episodeId}`);
            queryClient.resetQueries({ queryKey: aiKeys.all() });
            queryClient.invalidateQueries({ queryKey: episodeKeys.allLists() });
            queryClient.invalidateQueries({
                queryKey: youtubeVideoKeys.detailById(episodeId),
            });
            queryClient.invalidateQueries({ queryKey: episodeKeys.messagesByEpisodeId(episodeId) });
            return queryClient.invalidateQueries({
                queryKey: episodeKeys.detailById(episodeId),
            });
        },
    });
};

export default saveNonHostingEpisodeMutation;
