import { FC } from 'react';
import styled from 'styled-components';
import NoDataChart from '@/components/charts/NoData/NoDataChart';

interface NoDataChartOverlayProps {
    title?: string | React.ReactNode;
    message?: string | React.ReactNode;
}

const NoDataChartOverlay: FC<NoDataChartOverlayProps> = ({ title, message }) => (
    <Container>
        <Wrapper>
            <NoDataChart title={title} message={message} />
        </Wrapper>
    </Container>
);

const Container = styled.div`
    position: absolute;
    inset: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
`;

const Wrapper = styled.div`
    border-radius: var(--r-l);
    padding: var(--sp-l);
    background: rgba(255, 255, 255, 0.75); // Fallback if gradient is not supported
    background: radial-gradient(rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0));
`;

export default NoDataChartOverlay;
