import statsKeys from '@queries/stats/statsKeys';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { fetchPeakTimeBest } from '@/api';
import { z } from 'zod';
import camelcaseKeys from 'camelcase-keys';

interface PeakTimeBestDayHourQueryParams {
    showId: string;
    days: '7' | '15' | '30' | '90' | 'always';
}

const BestDayHourSchema = z.object({
    bestDayHour: z.number(),
    bestWeekDay: z.number(),
});

export type BestDayHour = z.infer<typeof BestDayHourSchema>;

type Key = QueryFunctionContext<ReturnType<typeof statsKeys.bestDayHourByShowId>>;

const queryFn = async ({ queryKey: [{ showId, days }] }: Key) => {
    const { data } = await fetchPeakTimeBest(showId, { days });

    return BestDayHourSchema.parse(camelcaseKeys(data, { deep: true }));
};

const usePeakTimeBestDayHourQuery = ({ showId, days }: PeakTimeBestDayHourQueryParams) => {
    return useQuery({
        queryKey: statsKeys.bestDayHourByShowId(showId, days),
        queryFn,
        enabled: !!showId && !!days,
    });
};

export default usePeakTimeBestDayHourQuery;
