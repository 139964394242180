import ExternalLink from '@/components/Link/ExternalLink';
import Text from '@/components/ui/atoms/Text';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Form from './Form';
import Stack from '@/components/ui/layout/Stack';
import useRssSettingsQuery from '@/queries/rssSettings/useRssSettingsQuery.hook';
import { useParams } from 'react-router';
import useUpdateRssSettingsMutation from '@/queries/rssSettings/useUpdateRssSettingsMutation.hook';
import { ApplePodcastsTokenFormSchema } from './Form/useFormSchema';
import { useBodyToastQueue } from '@/shared/hooks/useBodyToastQueue.hook';

const ApplePodcastsToken = () => {
    const { showId } = useParams<{ showId: string }>();
    const { data: rssSettings } = useRssSettingsQuery(showId);
    const updateRssSettings = useUpdateRssSettingsMutation();
    const toast = useBodyToastQueue();

    const handleUpdateToken = ({ token }: ApplePodcastsTokenFormSchema) => {
        updateRssSettings.mutate(
            {
                showId,
                payload: {
                    appleClaimToken: token,
                },
            },
            {
                onSuccess: () => {
                    toast.success(
                        <FormattedMessage defaultMessage="Le jeton Apple Podcasts a été intégré avec succès à votre flux RSS." />,
                    );
                },
                onError: () => {
                    toast.alert();
                },
            },
        );
    };

    return (
        <Wrapper>
            <Stack $gap="0.5rem">
                <Text variant="headingS" fontWeight="--fw-bold">
                    <FormattedMessage defaultMessage="Revendiquez votre podcast sur Apple Podcasts" />
                </Text>
                <Text color="--neutral500">
                    <FormattedMessage
                        defaultMessage="Accédez à des statistiques plus détaillées sur Apple Podcasts en ajoutant le jeton associé à votre podcast dans votre <link>tableau de bord Apple Podcasts Connect.</link>"
                        values={{
                            link: (chunks: string) => (
                                <ExternalLink
                                    href="https://podcastsconnect.apple.com/my-podcasts"
                                    target="_blank"
                                >
                                    {chunks}
                                </ExternalLink>
                            ),
                        }}
                    />
                </Text>
            </Stack>
            <Form
                token={rssSettings?.appleClaimToken}
                onSubmit={handleUpdateToken}
                isLoading={updateRssSettings.isLoading}
            />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`;

export default ApplePodcastsToken;
