import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { FORM_VALIDATION_MESSAGE } from '@/utils/validationMessage';

const useEditEmailFormSchema = () => {
    const intl = useIntl();

    return yup.object({
        email: yup
            .string()
            .email(intl.formatMessage(FORM_VALIDATION_MESSAGE.isEmail))
            .required(intl.formatMessage(FORM_VALIDATION_MESSAGE.required)),
        password: yup
            .string()
            .min(8, intl.formatMessage(FORM_VALIDATION_MESSAGE.minLength, { min: 8 }))
            .required(intl.formatMessage(FORM_VALIDATION_MESSAGE.required)),
    });
};

export default useEditEmailFormSchema;
