import { FormattedMessage } from 'react-intl';
import Modal from '@ui/molecules/Modal';
import PlaylistEdit from './PlaylistEdit';

const EditPlaylistModal = ({ isOpen, onClose, playlistId }) => (
    <Modal
        isOpen={isOpen}
        onClose={onClose}
        title={
            !playlistId ? (
                <FormattedMessage defaultMessage="Nouvelle playlist" />
            ) : (
                <FormattedMessage defaultMessage="Modifier la playlist" />
            )
        }
        size="medium"
    >
        <PlaylistEdit playlistId={playlistId} onClose={onClose} />
    </Modal>
);

export default EditPlaylistModal;
