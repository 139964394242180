import { FormattedDate, FormattedMessage, IntlProvider } from 'react-intl';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import Img from '@ui/atoms/Img';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import Tag from '@ui/molecules/Tag';
import HideFor from '@/components/unorganized/HideFor';
import ListFormFallback from '@/components/unorganized/ListFormFallback';
import { STATUS_SHOWS } from '@/shared/config/statusShows';
import Text from '@ui/atoms/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useParams } from 'react-router-dom';
import { USER_OPTIONS } from '@/shared/config/userOptions';
import useShowQuery from '@queries/show/useShowQuery.hook';
import translationsEn from '../../../../lang/en.json';
import translationsFr from '../../../../lang/fr.json';
import { useAudioPlayerContext } from '@/context/AudioPlayerContext';
import RouterLink from '@/components/Link/RouterLink';

import Button from '@/components/Button';
import ContextualMenu from './ContextualMenu';
import { sendAmplitudeLogEvent } from '@/shared/utils/amplitude';
import { formatDuration } from '@/shared/utils/duration';
import useEpisodeQuery from '@/queries/episode/useEpisodeQuery.hook';
import { EPISODE_STATUS } from '@/shared/config/constants';
import { EPISODE_PRIVACY } from '@/shared/config/constants';
import Badge from '@/components/ui/atoms/Badge';

const Loading = () => (
    <Container>
        <ListFormFallback />
    </Container>
);

const privacyLookup = {
    [EPISODE_PRIVACY.PRIVATE]: {
        children: <FormattedMessage defaultMessage="Privé" />,
        variant: 'alert',
        icon: <FontAwesomeIcon icon={icon({ name: 'eye-slash', style: 'solid' })} />,
    },
    [EPISODE_PRIVACY.UNLISTED]: {
        children: <FormattedMessage defaultMessage="Non-listé" />,
        variant: 'warning',
        icon: <FontAwesomeIcon icon={icon({ name: 'link-slash', style: 'solid' })} />,
    },
    [EPISODE_PRIVACY.PUBLIC]: {
        children: <FormattedMessage defaultMessage="Public" />,
        variant: 'success',
        icon: <FontAwesomeIcon icon={icon({ name: 'eye', style: 'solid' })} />,
    },
};

const EpisodePreviewInformations = ({ isLoading }) => {
    const { showId, episodeId } = useParams();

    const { data: episode } = useEpisodeQuery({ episodeId });
    const { data: show } = useShowQuery(showId);
    const showLocale = show?.language?.data.code;
    const isShowHosted = show?.hostedOnAusha === true;
    const { audioIsPlaying, play, pause } = useAudioPlayerContext();

    if (isLoading) return <Loading />;

    if (!episode)
        return (
            <Text>
                <FormattedMessage defaultMessage="Oh, il semblerait qu’il y ait un souci…" />
            </Text>
        );

    return (
        <>
            <DeprecatedPaper flex align="center" mb={20} z={1} position="relative">
                <RouterLink to={`/app/show/${showId}/episodes`} variant="button-tertiary">
                    <FontAwesomeIcon icon={icon({ name: 'arrow-left', style: 'solid' })} />
                </RouterLink>
                <DeprecatedText ml={10} weight="bold" size="veryBig" w="100%" ellipsis>
                    {episode.name}
                </DeprecatedText>
                <ActionWrapper>
                    <HideFor roles={['viewer']} showStatus={[STATUS_SHOWS.ARCHIVED_SHOWS]}>
                        <RouterLink
                            to={`/app/show/${showId}/episodes/edit/${episodeId}`}
                            variant="button-tertiary"
                            onClick={() => {
                                sendAmplitudeLogEvent('Edit Form Episode', {
                                    is_existing_episode: episode.state === EPISODE_STATUS.DRAFT,
                                });
                            }}
                        >
                            <FontAwesomeIcon icon={icon({ name: 'pen', style: 'solid' })} />
                            <FormattedMessage defaultMessage="Modifier" />
                        </RouterLink>
                    </HideFor>
                    <ContextualMenu episode={episode} />
                </ActionWrapper>
            </DeprecatedPaper>
            <Container>
                <DeprecatedPaper flex>
                    <DeprecatedPaper w="max-content">
                        <Img bordered size={300} src={episode.imageUrl} />
                        <DeprecatedPaper mt={20} flex justify="space-between">
                            <Button
                                onPress={() => {
                                    audioIsPlaying
                                        ? pause()
                                        : play({
                                              name: episode.name,
                                              url: episode.audioUrl,
                                          });
                                }}
                                variant="ghost"
                                startIcon={
                                    audioIsPlaying ? (
                                        <FontAwesomeIcon
                                            icon={icon({ name: 'pause', style: 'solid' })}
                                        />
                                    ) : (
                                        <FontAwesomeIcon
                                            icon={icon({ name: 'play', style: 'solid' })}
                                        />
                                    )
                                }
                            >
                                <FormattedMessage defaultMessage="Écouter l'épisode" />
                            </Button>
                            <DeprecatedText color="--neutral500" ellipsis>
                                {formatDuration(episode.duration)}
                            </DeprecatedText>
                        </DeprecatedPaper>
                    </DeprecatedPaper>

                    <DeprecatedPaper ml={35}>
                        <DeprecatedPaper align="center" mb={20}>
                            <DeprecatedPaper flex align="center" mb={20}>
                                <Badge {...privacyLookup[episode?.privacy]} />
                                <DeprecatedText color="--neutral500" ml={15} ellipsis>
                                    <FormattedMessage
                                        defaultMessage="Publié le {date}"
                                        values={{
                                            date: <FormattedDate value={episode.publishedAt} />,
                                        }}
                                    />
                                </DeprecatedText>
                            </DeprecatedPaper>
                        </DeprecatedPaper>

                        <Description>
                            <div>{ReactHtmlParser(episode.htmlDescription)}</div>
                            {!show?.userOptions.includes(USER_OPTIONS.NO_HOST_MENTION) &&
                                isShowHosted && (
                                    <IntlProvider
                                        locale={showLocale}
                                        messages={
                                            showLocale === 'fr' ? translationsFr : translationsEn
                                        }
                                    >
                                        <HostedMention>
                                            <FormattedMessage
                                                defaultMessage="Hébergé par Ausha. Visitez {link} pour plus d'informations."
                                                values={{
                                                    link: (
                                                        <LinkButton
                                                            href={
                                                                showLocale === 'fr'
                                                                    ? 'https://www.ausha.co/fr/politique-de-confidentialite/'
                                                                    : 'https://www.ausha.co/privacy-policy/'
                                                            }
                                                            target="_blank"
                                                        >
                                                            <FormattedMessage defaultMessage="ausha.co/fr/politique-de-confidentialite" />
                                                        </LinkButton>
                                                    ),
                                                }}
                                            />
                                        </HostedMention>
                                    </IntlProvider>
                                )}
                        </Description>
                        <DeprecatedPaper flex wrap>
                            {episode.tags.map((tag, i) => (
                                <Tag key={i} noClose>
                                    {tag.name}
                                </Tag>
                            ))}
                        </DeprecatedPaper>
                    </DeprecatedPaper>
                </DeprecatedPaper>
            </Container>
        </>
    );
};

const ActionWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;
const Description = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;

    a {
        color: var(--primary);
        text-decoration: none;
    }
`;
const HostedMention = styled.div`
    a {
        text-decoration: underline;
        color: var(--black);
    }
`;
const LinkButton = styled.a`
    padding: 0;
`;
const Container = styled.div`
    background-color: var(--white);
    padding: var(--container-padding-mobile);
    border-radius: var(--r-m);

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        padding: var(--container-padding);
    }
`;

export default EpisodePreviewInformations;
