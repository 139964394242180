import ContentLoader from 'react-content-loader';
import PropTypes from 'prop-types';
import starIcon from '@public/icons/star.svg';
import styled from 'styled-components';
import { FormattedDate, FormattedMessage } from 'react-intl';
import createFallback from '../fallbacks/createFallback';
import DeprecatedIcon from '../../ui/atoms/DeprecatedIcon';
import DeprecatedPaper from '../../ui/atoms/DeprecatedPaper';
import ReviewModel from '../../../models/ReviewModel';
import DeprecatedText from '../../ui/atoms/DeprecatedText';

const StyledPaper = styled(DeprecatedPaper)`
    &:last-child {
        border-bottom: 0;
    }
`;

const ReviewFallback = createFallback((props) => (
    <StyledPaper p={35} bb={1} border="--neutral100">
        <ContentLoader
            width={800}
            height={89}
            style={{ width: '100%', maxWidth: 800, height: 89 }}
            preserveAspectRatio="none"
            {...props}
        >
            {/* Title and rating */}
            <rect x="0" y="0" rx="5" ry="5" width="250" height="17" />
            {/* Author and date */}
            <rect x="0" y="25" rx="5" ry="5" width="200" height="13" />
            {/* Message on two lines */}
            <rect x="0" y="55" rx="5" ry="5" width="800" height="14" />
            <rect x="0" y="75" rx="5" ry="5" width="600" height="14" />
        </ContentLoader>
    </StyledPaper>
));

function Review({ review, ...props }) {
    let stars = [];

    // Compute number of stars required to display review rating
    for (let i = 1; i <= review.rating; i += 1) {
        stars = [
            ...stars,
            <DeprecatedIcon as={starIcon} key={i} size={14} mr={3} color="blocked" />,
        ];
    }

    return (
        <StyledPaper flex column p={35} bb={1} border="--neutral100" {...props}>
            <DeprecatedText style={{ display: 'inline-flex', flexWrap: 'wrap' }}>
                {review.title && (
                    <DeprecatedText
                        as="span"
                        mr={10}
                        size="big"
                        weight="semibold"
                        style={{ overflowWrap: 'anywhere' }}
                    >
                        {review.title}
                    </DeprecatedText>
                )}
                {review.rating && (
                    <DeprecatedText
                        as="span"
                        size="big"
                        weight="semibold"
                        style={{ whiteSpace: 'nowrap' }}
                    >
                        {stars}
                    </DeprecatedText>
                )}
            </DeprecatedText>

            <DeprecatedText size="small">
                {review.author && (
                    <>
                        <FormattedMessage defaultMessage="de" />
                        <DeprecatedText
                            as="span"
                            size="small"
                            weight="semibold"
                            style={{ overflowWrap: 'anywhere' }}
                        >
                            {` ${review.author}`}
                        </DeprecatedText>
                    </>
                )}
                {review.date && (
                    <DeprecatedText as="span" size="small" color="--neutral500">
                        {review.author && ' - '}
                        <FormattedDate
                            value={review.date}
                            day="2-digit"
                            month="short"
                            year="numeric"
                        />
                    </DeprecatedText>
                )}
            </DeprecatedText>

            {review.message && (
                <DeprecatedText mt={10} style={{ overflowWrap: 'anywhere' }}>
                    {review.message}
                </DeprecatedText>
            )}
        </StyledPaper>
    );
}

Review.propTypes = {
    review: PropTypes.instanceOf(ReviewModel).isRequired,
};

export { Review as default, ReviewFallback };
