import { FormattedMessage } from 'react-intl';
import EditPlaylistModal from '../organisms/EditPlaylistModal';
import { STATUS_SHOWS } from '@/utils/statusShows';
import noPlaylistMainIllustration from '@public/images/empty-state/illustration/playlists.png';
import noPlaylistHeadIllustration from '@public/images/empty-state/playlists.svg?url';
import EmptyState from '@ui/molecules/EmptyState';
import IconAdd from '@ui/icons/IconAdd';
import Button from '@ui/atoms/Button';
import Text from '@ui/atoms/Text';
import { useGuard } from '@/utils/hooks/useGuard.hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { extractCssVariable } from '@/utils/cssVariables';
import { GUARD_DISALLOW_REASONS } from '@/utils/constants';
import { USER_ROLES } from '@/utils/constants';
import styled from 'styled-components';
import { useOverlayTriggerState } from 'react-stately';
import Alert from '@ui/atoms/Alert/Alert';

function NoPlaylist() {
    const { allow, reason } = useGuard({
        disallowedShowStatuses: [STATUS_SHOWS.ARCHIVED_SHOWS],
        disallowedUserRoles: [USER_ROLES.VIEWER],
    });
    const editPlaylistModal = useOverlayTriggerState({});
    const disallowMessageLookup = {
        [GUARD_DISALLOW_REASONS.INSUFFICIENT_SHOW_STATUS]: (
            <FormattedMessage defaultMessage="L'ajout d'une nouvelle playlist n'est pas possible pour une émission archivée." />
        ),
    };

    return (
        <EmptyStateOuterWrapper>
            <Text fontWeight="--fw-bold" variant="title">
                <FormattedMessage defaultMessage="Mes playlists" />
            </Text>
            <EmptyState
                illustration={noPlaylistMainIllustration}
                illustrationType="image"
                illustrationAlign="right"
                illustrationBackground="--gradient-dawn"
            >
                <HeadIllustrationWrapper>
                    <HeadIllustration src={noPlaylistHeadIllustration} alt="PLaylists" />
                </HeadIllustrationWrapper>
                <LabelWrapper>
                    <Text variant="heading" fontWeight="--fw-bold">
                        <FormattedMessage defaultMessage="Créez votre première playlist" />
                    </Text>
                    <Text variant="body" fontWeight="--fw-normal" color="--neutral500">
                        <FormattedMessage defaultMessage="Organisez vos épisodes en différentes listes de lecture ▶️" />
                    </Text>
                </LabelWrapper>
                {!allow && (
                    <Alert
                        variant="alert"
                        icon={
                            <FontAwesomeIcon
                                icon={icon({ name: 'circle-info', style: 'solid' })}
                                color={extractCssVariable('--alert500')}
                                size="lg"
                            />
                        }
                    >
                        {disallowMessageLookup[reason] ?? (
                            <FormattedMessage defaultMessage="Une erreur est survenue" />
                        )}
                    </Alert>
                )}
                <Button
                    startIcon={<IconAdd />}
                    isDisabled={!allow}
                    onClick={editPlaylistModal.open}
                >
                    <FormattedMessage defaultMessage="Créer une playlist" />
                </Button>
            </EmptyState>
            {editPlaylistModal.isOpen && (
                <EditPlaylistModal
                    isOpen={editPlaylistModal.isOpen}
                    onClose={editPlaylistModal.close}
                />
            )}
        </EmptyStateOuterWrapper>
    );
}

const EmptyStateOuterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;
const HeadIllustrationWrapper = styled.div`
    width: 200px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const HeadIllustration = styled.img`
    min-width: 248px;
`;
const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    & > * {
        text-align: center;
    }
`;

export default NoPlaylist;
