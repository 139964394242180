import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { FORM_VALIDATION_MESSAGE } from '@/shared/services/validationMessageService';

const useCustomizeSponsorshipCodeFormSchema = () => {
    const intl = useIntl();
    const nameRegex = /^[A-Za-z0-9]*$/;

    return yup.object({
        code: yup
            .string()
            .matches(
                nameRegex,
                intl.formatMessage({
                    defaultMessage: 'Ce champ ne doit contenir que des lettres ou des chiffres',
                }),
            )
            .min(3, intl.formatMessage(FORM_VALIDATION_MESSAGE.minLength, { min: 3 }))
            .max(96, intl.formatMessage(FORM_VALIDATION_MESSAGE.maxLength, { max: 96 }))
            .required(intl.formatMessage(FORM_VALIDATION_MESSAGE.required)),
    });
};

export default useCustomizeSponsorshipCodeFormSchema;
