import styled, { css } from 'styled-components';
import typographyMixin from '@/utils/styleProps/typography';
import Link from '@ui/atoms/Link';
import { resolveColor } from '@/utils/cssVariables';

type Variant =
    | 'largeTitle'
    | 'title'
    | 'heading'
    | 'subheading'
    | 'body'
    | 'footnote'
    | 'headingXL'
    | 'headingL'
    | 'headingM'
    | 'headingS'
    | 'bodyL'
    | 'bodyM'
    | 'bodyS';
type FontWeight = '--fw-normal' | '--fw-semibold' | '--fw-bold';

interface TextProps {
    variant?: Variant;
    fontWeight?: FontWeight;
    textAlign?: string;
    wordBreak?: string;
    overflowWrap?: string;
    textTransform?: string;
    color?: string;
    numberOfLines?: number;
    fontStyle?: string;
    textOverflow?: string;
    whiteSpace?: string;
    children: React.ReactNode;
    className?: string;
    as?: 'p' | 'span' | 'b' | 'i';
}

interface TextBaseProps {
    $fontSize?: string;
    $lineHeight?: string;
    $fontWeight?: FontWeight;
    $textAlign?: string;
    $wordBreak?: string;
    $overflowWrap?: string;
    $textTransform?: string;
    $color?: string;
    $numberOfLines?: number;
    $fontStyle?: string;
    $textOverflow?: string;
    $whiteSpace?: string;
    as?: string;
}

const truncatedOnSingleLineCss = css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const truncatedOnMultipleLinesCss = css<TextBaseProps>`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${(props) => props.$numberOfLines};
`;

const TextBase = styled.p<TextBaseProps>`
    ${typographyMixin};
    color: ${(props) => resolveColor(props.$color)};
    ${(p) =>
        ['a', Link].includes(p.as ?? '') &&
        `
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    `};
    ${(props) => {
        if (typeof props.$numberOfLines !== 'number') {
            return null;
        }

        switch (props.$numberOfLines) {
            case 0:
            case 1:
                return truncatedOnSingleLineCss;
            default:
                return truncatedOnMultipleLinesCss;
        }
    }}
`;

const variantLookup = {
    largeTitle: {
        fontSize: 'var(--fs-large-title)',
        lineHeight: 'var(--lh-large-title)',
    },
    title: {
        fontSize: 'var(--fs-title)',
        lineHeight: 'var(--lh-title)',
    },
    heading: {
        fontSize: 'var(--fs-heading)',
        lineHeight: 'var(--lh-heading)',
    },
    subheading: {
        fontSize: 'var(--fs-subheading)',
        lineHeight: 'var(--lh-subheading)',
    },
    body: {
        fontSize: 'var(--fs-body)',
        lineHeight: 'var(--lh-body)',
    },
    footnote: {
        fontSize: 'var(--fs-footnote)',
        lineHeight: 'var(--lh-footnote)',
    },
    headingXL: {
        fontSize: 'var(--fs-heading-xl)',
        lineHeight: 'var(--lh-heading-xl)',
    },
    headingL: {
        fontSize: 'var(--fs-heading-l)',
        lineHeight: 'var(--lh-heading-l)',
    },
    headingM: {
        fontSize: 'var(--fs-heading-m)',
        lineHeight: 'var(--lh-heading-m)',
    },
    headingS: {
        fontSize: 'var(--fs-heading-s)',
        lineHeight: 'var(--lh-heading-s)',
    },
    bodyL: {
        fontSize: 'var(--fs-body-l)',
        lineHeight: 'var(--lh-body-l)',
    },
    bodyM: {
        fontSize: 'var(--fs-body-m)',
        lineHeight: 'var(--lh-body-m)',
    },
    bodyS: {
        fontSize: 'var(--fs-body-s)',
        lineHeight: 'var(--lh-body-s)',
    },
};

const Text = ({
    variant = 'body',
    fontWeight = '--fw-normal',
    textAlign,
    fontStyle,
    wordBreak,
    overflowWrap,
    textOverflow,
    textTransform,
    whiteSpace,
    color,
    numberOfLines,
    ...otherProps
}: TextProps) => (
    <TextBase
        $fontSize={variantLookup[variant].fontSize}
        $lineHeight={variantLookup[variant].lineHeight}
        $fontWeight={fontWeight}
        $textAlign={textAlign}
        $fontStyle={fontStyle}
        $wordBreak={wordBreak}
        $overflowWrap={overflowWrap}
        $textOverflow={textOverflow}
        $textTransform={textTransform}
        $whiteSpace={whiteSpace}
        $color={color}
        $numberOfLines={numberOfLines}
        {...otherProps}
    />
);

export default Text;
