import { forwardRef } from 'react';
import TooltipHover from '../../ui/smarties/TooltipHover';
import { connect } from './connect';

const enhance = connect(
    (
        { routerStore, subscriptionStore, userStore },
        {
            roles = [],
            offers = [],
            pricing = [],
            disabled = false,
            userOptions = [],
            showStatus = [],
            authorizedForBlockedSubscription = false,
        },
    ) => {
        const showId = routerStore.params.showId;
        const show = routerStore.resolve('showId');
        const sub = subscriptionStore.subscription;
        const user = userStore.user;

        // The reason prop is the key used in the tooltipSwitch (the reason why the component is disabled)
        if (show && roles.includes(show.userRole)) return { disabled: true, reason: 'role' };
        if (show && showStatus.includes(show.showStatus))
            return { disabled: true, reason: 'showStatus' };
        if (user && user.pricing === 'enterprise' && user.activated === false)
            return { disabled: true, reason: 'subscription' };
        if (!authorizedForBlockedSubscription && sub && sub.isBlockedForShow(showId))
            return { disabled: true, reason: 'subscription' };
        if (sub && (offers.includes(sub.pricing) || pricing.includes(sub.pricing)))
            return { disabled: true, reason: 'pricing' };
        if (
            user &&
            userOptions.length > 0 &&
            !(user.can(userOptions) || show?.allows(userOptions))
        ) {
            return { disabled: true, reason: 'userOptions' };
        }
        if (disabled) return { disabled: true, reason: 'default' };
        return { disabled: false };
    },
);

function makeAccess(Component) {
    const ComponentAccess = forwardRef(
        // reason and props.disabled are already connected to the store. The other props shall be provided by the user
        (
            {
                tooltip,
                tooltipSwitch = {},
                tooltipArrow = 'top',
                roles,
                offers,
                pricing,
                userOptions,
                reason,
                ...props
            },
            ref,
        ) => {
            const Tooltip = tooltip || tooltipSwitch[reason];
            if (Tooltip && props.disabled) {
                return (
                    <TooltipHover arrow={tooltipArrow} target={<Component ref={ref} {...props} />}>
                        {Tooltip}
                    </TooltipHover>
                );
            }
            return <Component ref={ref} {...props} />;
        },
    );

    ComponentAccess.displayName = `Access(${Component.displayName || Component.name})`;

    return enhance(ComponentAccess);
}

export default makeAccess;
