import { useState, useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from '../decorators/connect';
import StatsDataSetSelector from './StatsDataSetSelector';
import ShortListFallback from '../fallbacks/ShortListFallback';
import BarGraph, { STATS_NAME } from '../../ui/molecules/BarGraph';
import { compose } from '../../../helpers';
import FetcherSystems from '../fetchers/FetcherSystems';
import makeRenderStatsData from '../decorators/makeRenderStatsData';
import { useStatsDateSelectorContext } from '@/context/StatsDateSelectorContext';
import styled from 'styled-components';

const enhance = compose(
    injectIntl,
    connect(({ sourcesStatsStore, routerStore }) => ({
        operatingSystems: sourcesStatsStore.operatingSystems,
        showId: routerStore.params.showId,
        itemType: routerStore.params.itemType,
        itemId: routerStore.params.itemId,
        operatingSystemsHasData: sourcesStatsStore.sourceStatsHasData.operatingSystemsHasData,
    })),
);

const BarOperatingSystemGraphRenderData = makeRenderStatsData(({ axis, operatingSystemsList }) => (
    <BarGraphWrapper>
        <BarGraph options={axis} series={operatingSystemsList} statName={STATS_NAME.OS} />
    </BarGraphWrapper>
));

const SourcesOperatingSystems = ({
    showId,
    itemType,
    itemId,
    operatingSystems,
    intl,
    operatingSystemsHasData,
}) => {
    const { startDate, endDate } = useStatsDateSelectorContext();
    const [operatingSystemsList, setOperatingSystemsList] = useState([]);
    const axis = operatingSystems.map((operatingSystem) =>
        operatingSystem.system === 'other'
            ? intl.formatMessage({ defaultMessage: 'Autres' })
            : operatingSystem.system,
    );

    useEffect(() => {
        setOperatingSystemsList(operatingSystems?.map((system) => system.percentage));
        return () => setOperatingSystemsList([]);
    }, [operatingSystems]);

    return (
        <>
            <StatsDataSetSelector
                tabTitle={<FormattedMessage defaultMessage="Systèmes d'exploitation" />}
                tabDescription={
                    <FormattedMessage defaultMessage="Quels sont les systèmes d'exploitation les plus appréciés de vos auditeurs ?" />
                }
                tabId="sources"
                noSelector
            />
            <FetcherSystems
                showId={showId}
                start={startDate}
                end={endDate}
                itemType={itemType}
                itemId={itemId}
                fallback={ShortListFallback}
            >
                {(isLoading) =>
                    !isLoading && (
                        <BarOperatingSystemGraphRenderData
                            axis={axis}
                            operatingSystemsList={operatingSystemsList}
                            makeRenderStatsDataHasData={operatingSystemsHasData}
                        />
                    )
                }
            </FetcherSystems>
        </>
    );
};

const BarGraphWrapper = styled.div`
    min-height: 150px;
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding);
    margin-block: 2rem;

    ${({ theme }) => theme.mediaQueries.mobile} {
        padding: var(--container-padding-mobile);
    }
`;

export default enhance(SourcesOperatingSystems);
