import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { psoCompetitors } from '@/api';
import psoKeys from './psoKeys';
import { z } from 'zod';
import type { Platform, Mode } from '@/api/pso/types';

export const CompetitorSchema = z.object({
    type: z.string(),
    id: z.string(),
    rank: z.number(),
    name: z.string(),
    author: z.string(),
    link: z.string(),
    cover: z.string(),
    isSelf: z.boolean(),
});
export const CompetitorsRowSchema = z.object({
    keyword: z.string(),
    isLoading: z.boolean(),
    ranking: z.number(),
    competitors: z.array(CompetitorSchema),
});
export const CompetitorsRowsSchema = z.array(CompetitorsRowSchema);

type InputProps = {
    showId: string;
    platform: Platform;
    store: string;
    mode: Mode;
};
type Key = QueryFunctionContext<ReturnType<typeof psoKeys.competitors>>;
export type CompetitorsRows = z.infer<typeof CompetitorsRowsSchema>;
export type CompetitorsRow = z.infer<typeof CompetitorsRowSchema>;
export type Competitor = z.infer<typeof CompetitorSchema>;

const queryFn = async ({ queryKey: [{ showId, platform, store, mode }] }: Key) => {
    const { data } = await psoCompetitors(showId, platform, store, mode);
    return CompetitorsRowsSchema.parse(data);
};

const usePsoCompetitorsQuery = (
    { showId, platform, store, mode }: InputProps,
    { enabled } = { enabled: true },
) => {
    return useQuery({
        queryKey: psoKeys.competitors(showId, platform, store, mode),
        queryFn,
        enabled,
    });
};

export default usePsoCompetitorsQuery;
