import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import useCustomizeSponsorshipCodeFormSchema from './useCustomizeSponsorshipCodeFormSchema';
import { useForm } from 'react-hook-form';
import { InputTextController } from '@/components/ui/atoms/InputText';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import Button from '@/components/ui/atoms/Button';
import useUpdateSponsorshipCodeMutation from '@/queries/sponsorshipCode/useUpdateSponsorshipCodeMutation.hook';

const CustomizeSponsorshipCodeForm = ({ code, onCloseModal }) => {
    const intl = useIntl();
    const schema = useCustomizeSponsorshipCodeFormSchema();
    const { control, handleSubmit, formState, setError } = useForm({
        mode: 'onChange',
        defaultValues: {
            code: code || '',
        },
        resolver: yupResolver(schema),
    });
    const { isValid, isDirty } = formState;
    const updateCode = useUpdateSponsorshipCodeMutation();

    const onSubmit = ({ code }) => {
        const uppercaseCode = code.toUpperCase();
        updateCode.mutate(
            { code: uppercaseCode },
            {
                onSuccess: () => onCloseModal(),
                onError: (error) => {
                    if (error.response?.data?.fails?.code?.Unique) {
                        setError('code', {
                            type: 'unique',
                            message: intl.formatMessage({
                                defaultMessage:
                                    "Oups, ce code de parrainage existe déjà 🙃 Merci d'en choisir un différent.",
                            }),
                        });
                        return;
                    }
                    setError('root.serverError', {
                        type: '500',
                        message: intl.formatMessage({ defaultMessage: 'Une erreur est survenue' }),
                    });
                },
            },
        );
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <InputTextController
                control={control}
                label={<FormattedMessage defaultMessage="Code" />}
                name="code"
                uppercase
            />
            <ButtonsWrapper>
                <Button
                    type="submit"
                    isLoading={updateCode.isLoading}
                    isDisabled={!isValid || !isDirty}
                >
                    <FormattedMessage defaultMessage="Enregistrer" />
                </Button>
                <Button variant="secondary" onPress={onCloseModal}>
                    <FormattedMessage defaultMessage="Annuler" />
                </Button>
            </ButtonsWrapper>
        </Form>
    );
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 2.5rem;
`;
const ButtonsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;

    & > :first-child,
    > :last-child {
        align-self: stretch;
    }

    ${(p) => p.theme.mediaQueries.tabletAndUp} {
        flex-direction: row;
        justify-content: flex-end;
        column-gap: 0.5rem;

        & > :first-child,
        > :last-child {
            align-self: initial;
        }

        & > :first-child {
            order: 1;
        }
    }
`;

CustomizeSponsorshipCodeForm.propTypes = {
    code: PropTypes.string,
    onCloseModal: PropTypes.func.isRequired,
};

export default CustomizeSponsorshipCodeForm;
