import Stack from '@ui/layout/Stack';
import Text from '@ui/atoms/Text';
import { FormattedMessage } from 'react-intl';
import Img from '@ui/atoms/Img';
import imageMonetizationBlocked from '@public/images/illu_monetization_blocked.png';
import styled from 'styled-components';
import { useResponsive } from '@/utils/hooks/useResponsive';
import Button from '@ui/atoms/Button';
import AushaXPodcornImage from '@public/images/aushaxpodcorn.png';
import IconOpenLink from '@ui/icons/IconOpenLink';
import IconCheckedCircle from '@ui/icons/IconCheckedCircle';
import Modal from '@ui/molecules/Modal';
import { useSwitch } from '@hooks/useSwitch';
import { useParams } from 'react-router-dom';
import useMonetizationPlatformQuery from '@/queries/monetizationPlatform/useMonetizationPlatformQuery.hook';
import { PODCORN } from '@/utils/constants';
import useAddPodcornMonetizationMutation from '@/queries/monetizationPlatform/useAddPodcornMonetizationMutation.hook';
import useDeletePodcornMonetizationMutation from '@/queries/monetizationPlatform/useDeletePodcornMonetizationMutation.hook';
import Spinner from '@ui/atoms/Spinner';
import EmptyState from '@ui/molecules/EmptyState';
import emptyStateMainIllustration from '@public/images/piggy.png';
import ZebraIllustration from '@ui/atoms/ZebraIllustration';
import ShiningDiamond from '@ui/atoms/ShiningDiamond';
import IconRocket from '@ui/icons/IconRocket';
import Link from '@ui/atoms/Link';
import { sendAmplitudeLogEvent } from '@/helpers';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import { useModalToastQueue } from '@/utils/hooks/useModalToastQueue.hook';

const PODCORN_LINK = 'https://pdcn.co/e/https://podcorn.com/';
const PODCORN_SUPPORT_MAIL = 'mailto:support@podcorn.com';

const ERROR_MESSAGE_LOOKUP = {
    'The user is not the owner of the show.': (
        <FormattedMessage defaultMessage="Seuls les propriétaires de l'émission peuvent activer la monétisation." />
    ),
    'The user has not been activated yet.': (
        <FormattedMessage defaultMessage="L'émission est déjà monétisée sur cette plateforme ou sur une autre." />
    ),
};

const CampaignSponsorshipEditPage = () => {
    const { isMobile } = useResponsive();
    const { showId } = useParams();
    const user = useUserQuery();
    const [modalLinkOpened, openLinkModal, closeLinkModal] = useSwitch(false);
    const [modalUnlinkOpened, openUnlinkModal, closeUnlinkModal] = useSwitch(false);
    const { data: monetizationPlatformQueryData, isLoading } = useMonetizationPlatformQuery(showId);
    const addPodcornToMonetization = useAddPodcornMonetizationMutation();
    const deletePodcornToMonetization = useDeletePodcornMonetizationMutation();
    const toast = useModalToastQueue();

    if (user?.data?.activated === false) {
        return (
            <EmptyStateOuterWrapper>
                <Text fontWeight="--fw-bold" variant="title">
                    <FormattedMessage defaultMessage="Partenariat" />
                </Text>
                <EmptyState
                    illustration={emptyStateMainIllustration}
                    illustrationType="image"
                    illustrationAlign="center"
                    illustrationBackground="--gradient-sunny"
                >
                    <HeadIllustrationWrapper>
                        <ZebraIllustration>
                            <ShiningDiamond />
                        </ZebraIllustration>
                    </HeadIllustrationWrapper>
                    <LabelWrapper>
                        <Text variant="heading" fontWeight="--fw-bold">
                            <FormattedMessage defaultMessage="Abonnez-vous !" />
                        </Text>
                        <Text variant="body" fontWeight="--fw-normal" color="--neutral500">
                            <FormattedMessage defaultMessage="Pour débloquer la monétisation de votre podcast, abonnez-vous 🚀" />
                        </Text>
                    </LabelWrapper>
                    <Button
                        startIcon={<IconRocket />}
                        as={Link}
                        to={'/app/user/subscription/offers'}
                    >
                        <FormattedMessage defaultMessage="S'abonner" />
                    </Button>
                </EmptyState>
            </EmptyStateOuterWrapper>
        );
    }

    const handleErrorMessages = (error) => {
        const message =
            error?.response?.status === 422 ? (
                <FormattedMessage defaultMessage="L'émission est déjà monétisée sur cette plateforme ou sur une autre." />
            ) : (
                ERROR_MESSAGE_LOOKUP[error?.response?.data?.message] ?? (
                    <FormattedMessage defaultMessage="L'émission n'a pas pu être monétisée sur cette plateforme." />
                )
            );
        toast.alert(message);
    };

    const handleLinkPodcorn = () => {
        sendAmplitudeLogEvent('Automatic Monetization Setup Started');
        addPodcornToMonetization.mutate(
            { showId },
            {
                onError: handleErrorMessages,
            },
        );
        closeLinkModal();
    };

    const handleUnlinkPodcorn = () => {
        deletePodcornToMonetization.mutate(
            { showId },
            {
                onError: handleErrorMessages,
            },
        );
        closeUnlinkModal();
    };

    const activatePodcorn = (
        <Stack $gap="2rem">
            <Img src={AushaXPodcornImage} w={286} h={32} />
            <Stack $gap="1rem">
                <Text variant="heading" fontWeight="--fw-bold">
                    <FormattedMessage defaultMessage="Trouvez des sponsors et monétisez votre podcast avec Podcorn" />
                </Text>
                <Text>
                    <FormattedMessage
                        defaultMessage="Gagnez de l'argent en incluant des publicités natives dans votre podcast, selon vos conditions.{linebreak}{linebreak}Que le podcast soit votre passion ou votre métier, <link>Podcorn</link> est le moyen le plus facile de gagner de l'argent grâce à des accords de contenu de marque. Parcourez les opportunités, proposez-vous aux marques, fixez vos tarifs et conservez votre liberté créative.{linebreak}{linebreak}Trouver des sponsors n'a jamais été aussi simple ! Rejoignez les 75 000+ podcasteurs sur Podcorn."
                        values={{
                            linebreak: <br />,
                            link: (chunks) => (
                                <Text
                                    as="a"
                                    fontWeight="--fw-semibold"
                                    color="--primary"
                                    href={PODCORN_LINK}
                                    target="_blank"
                                    onClick={() =>
                                        sendAmplitudeLogEvent(
                                            'Redirection to Podcorn corporate website',
                                        )
                                    }
                                >
                                    {chunks}
                                </Text>
                            ),
                        }}
                    />
                </Text>
            </Stack>
            <Wrapper>
                <Button onPress={openLinkModal}>
                    <FormattedMessage defaultMessage="Activer Podcorn" />
                </Button>
            </Wrapper>
            <Text variant="footnote" color="--neutral500">
                <FormattedMessage
                    defaultMessage="Si vous avez des questions ou si vous avez besoin d'aide pour la monétisation, n'hésitez pas à <link>contacter Podcorn</link>."
                    values={{
                        link: (chunks) => (
                            <Text
                                as="a"
                                variant="footnote"
                                fontWeight="--fw-semibold"
                                color="--primary"
                                href={PODCORN_SUPPORT_MAIL}
                            >
                                {chunks}
                            </Text>
                        ),
                    }}
                />
            </Text>
        </Stack>
    );

    const podcornActivated = (
        <Stack $gap="2rem">
            <Img src={AushaXPodcornImage} w={286} h={32} />
            <Stack $gap="1rem">
                <IconCheckedCircle color="--success" size="2rem" />
                <Stack $gap="0.5rem">
                    <Text variant="heading" fontWeight="--fw-bold">
                        <FormattedMessage defaultMessage="Podcorn est activé" />
                    </Text>
                    <Text color="--neutral500">
                        <FormattedMessage defaultMessage="Consultez vos statistiques de monétisation en vous connectant à votre tableau de bord Podcorn." />
                    </Text>
                </Stack>
            </Stack>
            <Stack $gap="1.5rem">
                <Wrapper>
                    <Button
                        as="a"
                        href="https://app.podcorn.com/dashboard"
                        target="_blank"
                        startIcon={<IconOpenLink />}
                    >
                        <FormattedMessage defaultMessage="Accéder à Podcorn" />
                    </Button>
                </Wrapper>
                <Text
                    fontWeight="--fw-semibold"
                    color="--alert500"
                    style={{ cursor: 'pointer' }}
                    onClick={openUnlinkModal}
                >
                    <FormattedMessage defaultMessage="Délier Podcorn" />
                </Text>
            </Stack>
            <Text variant="footnote" color="--neutral500">
                <FormattedMessage
                    defaultMessage="Si vous avez des questions ou si vous avez besoin d'aide pour la monétisation, n'hésitez pas à <link>contacter Podcorn</link>."
                    values={{
                        link: (chunks) => (
                            <Text
                                as="a"
                                variant="footnote"
                                fontWeight="--fw-semibold"
                                color="--primary"
                                href="mailto:support@podcorn.com"
                            >
                                {chunks}
                            </Text>
                        ),
                    }}
                />
            </Text>
        </Stack>
    );

    const linkModal = (
        <Modal
            isOpen={modalLinkOpened}
            onClose={closeLinkModal}
            title={<FormattedMessage defaultMessage="Activer Podcorn" />}
            action={
                <>
                    <Button variant="secondary" onClick={closeLinkModal}>
                        <FormattedMessage defaultMessage="Annuler" />
                    </Button>
                    <Button background="--primary" onClick={handleLinkPodcorn}>
                        <FormattedMessage defaultMessage="Activer Podcorn" />
                    </Button>
                </>
            }
        >
            <Text>
                <FormattedMessage
                    defaultMessage="Podcorn a besoin de collecter des données sur vos nombre d'écoutes pour vous aider à trouver des sponsors et à monétiser votre émission.{linebreak}{linebreak}En continuant, vous autorisez Podcorn à collecter ces données. Une fois autorisé, créez un compte sur Podcorn pour commencer à gagner de l'argent !"
                    values={{ linebreak: <br /> }}
                />
            </Text>
        </Modal>
    );

    const unlinkModal = (
        <Modal
            isOpen={modalUnlinkOpened}
            onClose={closeUnlinkModal}
            title={<FormattedMessage defaultMessage="Délier Podcorn" />}
            action={
                <>
                    <Button variant="secondary" onClick={closeUnlinkModal}>
                        <FormattedMessage defaultMessage="Annuler" />
                    </Button>
                    <Button background="--alert500" onClick={handleUnlinkPodcorn}>
                        <FormattedMessage defaultMessage="Délier Podcorn" />
                    </Button>
                </>
            }
        >
            <Text>
                <FormattedMessage defaultMessage="Souhaitez-vous rompre le lien avec Podcorn ? Votre émission ne sera plus monétisée mais votre compte Podcorn restera actif." />
            </Text>
        </Modal>
    );

    return (
        <>
            <Stack $gap="1.5rem">
                <Text variant="title" fontWeight="--fw-bold">
                    <FormattedMessage defaultMessage="Partenariat" />
                </Text>
                <OuterWrapper>
                    <Wrapper>
                        <LeftBlock>
                            {isLoading ? (
                                <Spinner />
                            ) : monetizationPlatformQueryData?.monetizationPlatform === PODCORN ? (
                                podcornActivated
                            ) : (
                                activatePodcorn
                            )}
                        </LeftBlock>
                        <RightBlock>
                            <ImgWrapper>
                                <Img
                                    src={imageMonetizationBlocked}
                                    maxW={isMobile ? 256 : 384}
                                    maxH={isMobile ? 256 : 384}
                                />
                            </ImgWrapper>
                        </RightBlock>
                    </Wrapper>
                </OuterWrapper>
            </Stack>
            {modalUnlinkOpened && unlinkModal}
            {modalLinkOpened && linkModal}
        </>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${({ theme }) => theme.mediaQueries.tabletAndUp} {
        flex-direction: row;
        align-self: stretch;
        justify-content: flex-start;
    }
`;

const OuterWrapper = styled.div`
    background: var(--white);
    border-radius: var(--r-l);
`;

const Container = styled.div`
    display: flex;
    flex: 1 1 0;
    justify-content: center;
    align-items: center;
`;

const LeftBlock = styled(Container)`
    padding: 1.5rem 1rem 1rem 1rem;

    ${({ theme }) => theme.mediaQueries.tabletAndUp} {
        padding: 2rem 2.5rem;
    }
`;

const RightBlock = styled(Container)`
    padding: 1rem 1rem;
    align-items: stretch;
`;

const ImgWrapper = styled(Container)`
    padding: 1.5rem 1rem;
    background: var(--gradient-sunny);
    border-radius: var(--r-l);
`;

const EmptyStateOuterWrapper = styled.div`
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

const HeadIllustrationWrapper = styled.div`
    width: 200px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    & > * {
        text-align: center;
    }
`;

export default CampaignSponsorshipEditPage;
