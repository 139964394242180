import { FormattedMessage } from 'react-intl';
import Button from '@/components/Button';
import AddRssImportModal from '@/components/unorganized/AddRssImportModal';
import { useOverlayTriggerState } from 'react-stately';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';

const EmptyRssImport = () => {
    let rssImportModalState = useOverlayTriggerState({});

    return (
        <>
            {rssImportModalState.isOpen && (
                <AddRssImportModal
                    isOpen={rssImportModalState.isOpen}
                    onClose={rssImportModalState.close}
                    isEdit={false}
                />
            )}
            <EmptyRssImportWrapper>
                <RssIcon icon={icon({ name: 'square-rss', style: 'solid' })} />
                <Title>
                    <FormattedMessage defaultMessage="Vous n'avez pas ajouté de lien RSS" />
                </Title>
                <Button onPress={rssImportModalState.open}>
                    <FormattedMessage defaultMessage="Ajouter un lien RSS" />
                </Button>
            </EmptyRssImportWrapper>
        </>
    );
};

const EmptyRssImportWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding);
    min-height: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    ${({ theme }) => theme.mediaQueries.mobile} {
        padding: var(--container-padding-mobile);
    }
`;
const RssIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
    font-size: 2rem;
`;
const Title = styled.h1`
    font-size: var(--fs-body-l);
    font-weight: var(--fw-semibold);
`;

export default EmptyRssImport;
