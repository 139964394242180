import styled from 'styled-components';
import PropTypes from 'prop-types';

const Grid = styled.div`
    display: grid;
    gap: ${(p) => p.gap};
    grid-gap: ${(p) => p.gap}; /* Backward compatibility */
    row-gap: ${(p) => p.rowGap};
    grid-row-gap: ${(p) => p.rowGap}; /* Backward compatibility */
    column-gap: ${(p) => p.columnGap};
    grid-column-gap: ${(p) => p.columnGap}; /* Backward compatibility */
    grid-template-columns: ${(p) =>
        p.customColumn ? p.customColumn : `repeat(auto-fit, minmax(${p.idealCellWidth}, 1fr))`};

    /* If browser supports min() CSS function, use it to allow cells to be smaller */
    /* than the ideal width by filling 100% of the available space */
    @supports (width: min(${(p) => p.idealCellWidth}, 100%)) {
        grid-template-columns: repeat(auto-fit, minmax(min(${(p) => p.idealCellWidth}, 100%), 1fr));
    }
`;

Grid.propTypes = {
    idealCellWidth: PropTypes.string.isRequired, // Ideal cell width. Cells are larger when columns are stretched to fit parent width. Cells are smaller in single column layout when column must shrink to fit parent.
    gap: PropTypes.string, // Gaps between rows and columns
    rowGap: PropTypes.string, // Gaps between rows
    columnGap: PropTypes.string, // Gaps between columns
};

Grid.defaultProps = {
    gap: '1rem',
};

export default Grid;
