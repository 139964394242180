import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PageHeader from './PageHeader';
// @ts-ignore
import noEpisodeHeadIllustration from '@public/images/empty-state/episodes.svg?url';
import EmptyState from './EmptyState';
import EpisodesNonHostingLayout from '../EpisodesNonHostingLayout';
import { useEpisodeFiltersContext } from '@/context/EpisodeFiltersContext';
import { useParams } from 'react-router';
import useRssFeedQuery from '@/queries/rss/useRssFeedQuery.hook';
import useEpisodesQuery from '@/queries/episode/useEpisodesQuery.hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Episodes from './Episodes';
import ServerPagination from '@/components/ui/ServerPagination';
import { EpisodeSelectContextProvider } from '@/context/EpisodeSelectContext';
import styled from 'styled-components';
import useImportedEpisodesQuery, {
    ImportedEpisode,
} from '@/queries/rss/useImportedEpisodesQuery.hook';
import NoFeed from './NoFeed';
import useHasAccessToAushaForAll from '@/shared/hooks/useHasAccessToAushaForAll.hook';

const EPISODE_POLLING_RATE = 5000;

const NonHostingPublishedEpisodes = () => {
    const { showId } = useParams<{ showId: string }>();

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);

    const { search } = useEpisodeFiltersContext();
    const { data: rssFeed, isLoading: rssFeedIsLoading } = useRssFeedQuery({ showId });
    const { data: imported } = useImportedEpisodesQuery({
        showId,
        config: {
            refetchInterval: (data: any) => {
                return data?.importedEpisodes?.some((importedEpisode: ImportedEpisode) =>
                    ['processing', 'pending'].includes(importedEpisode.state),
                )
                    ? EPISODE_POLLING_RATE
                    : false;
            },
        },
    });
    const currentShowIsImported = !!rssFeed;
    const { data: episodes, isLoading: episodesAreLoading } = useEpisodesQuery({
        showId,
        pagination: { page, perPage },
        q: search,
        filters: {
            seasons: [],
            statuses: ['active'],
        },
        config: {
            refetchInterval: () =>
                currentShowIsImported &&
                imported?.importedEpisodes?.some((importedEpisode: ImportedEpisode) =>
                    ['processing', 'pending'].includes(importedEpisode.state),
                )
                    ? EPISODE_POLLING_RATE
                    : false,
        },
    });

    const hasAccessToAushaForAll = useHasAccessToAushaForAll();

    // TODO: Update check when backend is ready
    if (hasAccessToAushaForAll) {
        return (
            <EpisodesNonHostingLayout>
                <NoFeed />
            </EpisodesNonHostingLayout>
        );
    }

    if (rssFeedIsLoading || episodesAreLoading) {
        return (
            <EpisodesNonHostingLayout>
                <LoadingWrapper>
                    <FontAwesomeIcon icon={icon({ name: 'spinner-third', style: 'solid' })} spin />
                </LoadingWrapper>
            </EpisodesNonHostingLayout>
        );
    }

    if ((!episodes || episodes?.episodes?.length === 0) && !search) {
        return (
            <EpisodesNonHostingLayout>
                <EmptyState />
            </EpisodesNonHostingLayout>
        );
    }
    return (
        <EpisodesNonHostingLayout>
            <EpisodesPageWrapper>
                <PageHeader />
                {episodes && episodes?.episodes?.length > 0 ? (
                    <EpisodeSelectContextProvider>
                        <Episodes episodes={episodes.episodes} />
                    </EpisodeSelectContextProvider>
                ) : (
                    <ResultsWrapper>
                        <DefaultStateWrapper>
                            <SearchIcon icon={icon({ name: 'magnifying-glass', style: 'solid' })} />
                            <DefaultStateTitleWrapper>
                                <DefaultStateTitle>
                                    <FormattedMessage defaultMessage="Aucun résultat trouvé pour les filtres appliqués" />
                                </DefaultStateTitle>
                            </DefaultStateTitleWrapper>
                        </DefaultStateWrapper>
                    </ResultsWrapper>
                )}
                {episodes?.pagination && episodes?.episodes.length > 0 && (
                    <ServerPagination
                        pagination={episodes.pagination}
                        onGoToPage={(page: number) => setPage(page)}
                        onGoToFirstPage={() => setPage(1)}
                        onGoToLastPage={() => setPage(episodes.pagination.totalPages)}
                        onGoToPreviousPage={() => setPage(episodes.pagination.currentPage - 1)}
                        onGoToNextPage={() => setPage(episodes.pagination.currentPage + 1)}
                        onChangePerPage={(perPage: number) => setPerPage(perPage)}
                        options={{
                            goToFirstAndLastPageButtons: true,
                            showPaginationInfo: true,
                            showPageSelector: false,
                        }}
                    />
                )}
            </EpisodesPageWrapper>
        </EpisodesNonHostingLayout>
    );
};

const EpisodesPageWrapper = styled.div``;
const LoadingWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary);
    padding-block: 6rem;
    margin-top: 4rem;
`;
const ResultsWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding);
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-block: 1.5rem;
`;
const DefaultStateWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    color: var(--neutral500);
    width: 100%;
    max-width: 24rem;
    margin-inline: auto;
    padding-block: 2rem;
`;
const SearchIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
    font-size: 1.5rem;
`;
const DefaultStateTitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.25rem;
`;
const DefaultStateTitle = styled.h2`
    font-weight: var(--fw-semibold);
    font-size: var(--fs-body-s);
`;

export default NonHostingPublishedEpisodes;
