import { forwardRef } from 'react';
import { observer } from 'mobx-react';
import DeprecatedToggle from '../DeprecatedToggle';

const ToggleField = forwardRef(({ field, onChange, checked, ...props }, ref) => {
    const check = field ? field.value : checked;
    const toggle = () => {
        field && field.setValue(!field.value);
        onChange && onChange(!check);
    };

    return <DeprecatedToggle onChange={toggle} checked={check} {...props} ref={ref} />;
});

ToggleField.displayName = 'ToggleField';

export default observer(ToggleField);
