import { useParams } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { createContext, useState } from 'react';
import Modal from '@/components/ui/atoms/Modal';
import SelectBroadcastPlatformsStep from './SelectBroadcastPlatformsStep';
import ListeningLinksStep from './ListeningLinksStep';
import Button from '@/components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';
import { ListeningPlatform } from '@/utils/constants';

export const BroadcastMultiplePlatformsFormContext = createContext(null);

const stepsTitle = [
    <FormattedMessage
        key="step1"
        defaultMessage="Votre émission est déjà sur certaines plateformes ?"
    />,
    <FormattedMessage
        key="step2"
        defaultMessage="Saisissez les liens d’écoute de votre émission"
    />,
];

interface BroadcastMultiplePlatformsModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const BroadcastMultiplePlatformsModal = ({
    isOpen,
    onClose,
}: BroadcastMultiplePlatformsModalProps) => {
    const { showId } = useParams<{ showId: string }>();

    const [currentStep, setCurrentStep] = useState(0);
    const [selectedPlatforms, setSelectedPlatforms] = useState<ListeningPlatform[]>([]);

    const markAsDisplayed = () => {
        const exists = !!sessionStorage.getItem(
            `hasDisplayedBroadcastMultiplePlatformsModal-${showId}`,
        );

        if (exists) {
            return;
        }

        sessionStorage.setItem(`hasDisplayedBroadcastMultiplePlatformsModal-${showId}`, 'true');
    };

    const handleClose = () => {
        markAsDisplayed();
        onClose();
    };

    const nextStep = () => {
        if (currentStep === 1) return;
        setCurrentStep((prev) => prev + 1);
    };
    const previousStep = () => {
        if (currentStep === 0) return;
        setCurrentStep((prev) => prev - 1);
    };
    return (
        <ModalStyled isOpen={isOpen} onOpenChange={handleClose} size="medium">
            <Header>
                <Title>{stepsTitle[currentStep]}</Title>
                <CloseButton
                    startIcon={<FontAwesomeIcon icon={icon({ name: 'xmark', style: 'solid' })} />}
                    onPress={handleClose}
                    variant="ghost"
                    aria-label="Close modal"
                />
            </Header>

            {currentStep === 0 ? (
                <SelectBroadcastPlatformsStep
                    selectedPlatforms={selectedPlatforms}
                    setSelectedPlatforms={setSelectedPlatforms}
                    onNextStep={nextStep}
                    onClose={handleClose}
                />
            ) : (
                <ListeningLinksStep
                    selectedPlatforms={selectedPlatforms}
                    onPreviousStep={previousStep}
                    onClose={handleClose}
                />
            )}
        </ModalStyled>
    );
};

const ModalStyled = styled(Modal)`
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1.5rem;
`;
const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;
const Title = styled.h1`
    font-size: var(--fs-body-l);
`;
const CloseButton = styled(Button)`
    transition-duration: 0.2s;
    padding: 0.5rem;
    color: var(--neutral500);
    &:hover {
        background-color: var(--neutral50);
        color: var(--neutral700);
    }
`;

export default BroadcastMultiplePlatformsModal;
