import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { SelectController } from '@ui/molecules/Select';
import { InputTextController } from '@ui/atoms/InputText';
import InputText from '@ui/atoms/InputText/InputText';
import { useResponsive } from '@/shared/hooks/useResponsive';
import { ImgUploaderController } from '@ui/molecules/ImgUploader';
import UnsavedFormModal from '@/components/unorganized/UnsavedFormModal';
import EditEmailModal from './EditEmailModal';
import { ProfileFormSchema, useProfileFormSchema } from './schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { User } from '@queries/user/useUserQuery.hook';
import { Language } from '@queries/language/useLanguagesQuery.hook';
import { Country } from '@queries/country/useCountriesQuery.hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Button from '@/components/Button';
import EditPasswordModal from './EditPasswordModal';

interface ProfileFormProps {
    languages: Language[];
    countries: Country[];
    timezones: { id: string; name: string }[];
    onSubmit: (formData: ProfileFormSchema) => void;
    user: User;
    isLoading: boolean;
}

const ProfileForm = ({
    languages,
    countries,
    timezones,
    onSubmit,
    user,
    isLoading,
}: ProfileFormProps) => {
    const intl = useIntl();
    const { isMobileOrTablet } = useResponsive();
    const schema = useProfileFormSchema(user.language);
    const form = useForm<ProfileFormSchema>({
        mode: 'onChange',
        values: {
            firstname: user.firstname || '',
            lastname: user.lastname || '',
            language: user.language || '',
            country: user.country || '',
            timezone: user.timezone || '',
            avatar: user.avatar || '',
        },
        resolver: zodResolver(schema),
    });
    const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
    const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);

    const { isValid, isDirty } = form.formState;

    return (
        <>
            <UnsavedFormModal shouldBlockNavigation={isDirty} />
            <Form onSubmit={form.handleSubmit(onSubmit)}>
                <InnerWrapper>
                    <ImgUploaderController
                        control={form.control}
                        name="avatar"
                        label={<FormattedMessage defaultMessage="Photo de profil" />}
                        horizontal={isMobileOrTablet}
                        circle
                        w={isMobileOrTablet ? '96px' : '160px'}
                        h={isMobileOrTablet ? '96px' : '160px'}
                        handleDeleteFile={() =>
                            form.setValue('avatar', null, { shouldDirty: true })
                        }
                    />
                    <FieldsWrapper>
                        <ResponsiveWrapper>
                            <InputTextController
                                control={form.control}
                                name="firstname"
                                label={<FormattedMessage defaultMessage="Prénom" />}
                                placeholder={intl.formatMessage({
                                    defaultMessage: 'Thomas',
                                })}
                            />
                            <InputTextController
                                control={form.control}
                                name="lastname"
                                label={<FormattedMessage defaultMessage="Nom" />}
                                placeholder={intl.formatMessage({
                                    defaultMessage: 'Pesquet',
                                })}
                            />
                        </ResponsiveWrapper>
                        <SelectController
                            control={form.control}
                            name="country"
                            label={<FormattedMessage defaultMessage="Pays" />}
                            options={countries.map((country) => ({
                                id: country.code,
                                label: country.name,
                            }))}
                        />
                        <SelectController
                            control={form.control}
                            name="language"
                            label={<FormattedMessage defaultMessage="Langue" />}
                            options={languages.map((language) => ({
                                id: language.code,
                                label: language.nativeName,
                            }))}
                        />
                        <SelectController
                            control={form.control}
                            name="timezone"
                            label={<FormattedMessage defaultMessage="Fuseau horaire" />}
                            options={timezones.map((timezone) => ({
                                id: timezone.id,
                                label: timezone.name,
                            }))}
                        />
                        <InputText
                            label={<FormattedMessage defaultMessage="Adresse email" />}
                            value={user?.email}
                            isDisabled
                            tooltip={
                                <EditButton
                                    variant="ghost"
                                    onPress={() => setIsEmailModalOpen(true)}
                                    startIcon={
                                        <EditButtonIcon
                                            icon={icon({ name: 'pen', style: 'solid' })}
                                        />
                                    }
                                >
                                    <FormattedMessage defaultMessage="Modifier" />
                                </EditButton>
                            }
                        />
                        <InputText
                            label={<FormattedMessage defaultMessage="Mot de passe" />}
                            value="********"
                            isDisabled
                            tooltip={
                                <EditButton
                                    variant="ghost"
                                    onPress={() => setIsPasswordModalOpen(true)}
                                    startIcon={
                                        <EditButtonIcon
                                            icon={icon({ name: 'pen', style: 'solid' })}
                                        />
                                    }
                                >
                                    <FormattedMessage defaultMessage="Modifier" />
                                </EditButton>
                            }
                        />
                    </FieldsWrapper>
                </InnerWrapper>
                <SubmitButton type="submit" isLoading={isLoading} isDisabled={!isDirty || !isValid}>
                    <FormattedMessage defaultMessage="Enregistrer" />
                </SubmitButton>
            </Form>
            <EditPasswordModal isOpen={isPasswordModalOpen} onOpenChange={setIsPasswordModalOpen} />
            <EditEmailModal isOpen={isEmailModalOpen} onOpenChange={setIsEmailModalOpen} />
        </>
    );
};

const SubmitButton = styled(Button)`
    margin-top: 1rem;
    align-self: center;
`;
const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`;
const InnerWrapper = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;

    ${(p) => p.theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
        column-gap: 2.75rem;
    }
`;
const ResponsiveWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;

    ${(p) => p.theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
        column-gap: 1rem;
    }
`;
const FieldsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;

    ${(p) => p.theme.mediaQueries.desktopAndUp} {
        flex: 1;
    }
`;
const EditButton = styled(Button)`
    padding: 0;
    margin-left: auto;
`;
const EditButtonIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: var(--primary);
`;

export default ProfileForm;
