import { MenuItem as AriaMenuItem, MenuItemProps } from 'react-aria-components';
import styled from 'styled-components';

export interface MenuDropdownItemProps extends MenuItemProps {
    children: React.ReactNode;
    color?: string;
}

const MenuDropdownItem = ({ children, color, ...props }: MenuDropdownItemProps) => {
    return (
        <MenuItem {...props} $color={color}>
            {children}
        </MenuItem>
    );
};

const MenuItem = styled(AriaMenuItem)<{ $color?: string }>`
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    padding: 0.25rem 0.5rem;
    border-radius: var(--r-s);
    background-color: transparent;
    color: var(--black);
    cursor: pointer;
    ${({ $color }) => $color && `color: var(${$color});`}
    text-decoration: none;

    &[data-hovered] {
        background-color: var(--neutral50);
    }
`;

export default MenuDropdownItem;
