import { isValidElement } from 'react';
import {
    ListBoxItem as AriaListBoxItem,
    Text,
    Separator as AriaSeparator,
} from 'react-aria-components';
import type { ListBoxItemProps as AriaListBoxItemProps, Key } from 'react-aria-components';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon as iconFA } from '@fortawesome/fontawesome-svg-core/import.macro';

const ListBoxItemImage = ({ image }: { image: ListBoxItem['image'] }) => {
    if (image === undefined) return null;

    if (typeof image === 'string' && image.length > 0) {
        return <Image src={image} />;
    }
    if (isValidElement(image)) {
        return image;
    }

    return (
        <ImagePlaceholder>
            <Icon
                icon={iconFA({
                    name: 'pen-ruler',
                    style: 'solid',
                })}
            />
        </ImagePlaceholder>
    );
};
export interface ListBoxItem {
    id: Key;
    name: string;
    image?: string | React.ReactNode | null;
    icon?: React.ReactNode;
    isSelectableCategory?: boolean;
    isIndented?: boolean;
    description?: string | React.ReactNode;
    [key: string]: any;
}

type ListBoxItemProps = Omit<ListBoxItem, 'id'> & AriaListBoxItemProps<ListBoxItem>;

const ListBoxItem = ({
    children,
    description,
    name,
    image,
    icon,
    isSelectableCategory = false,
    isIndented = false,
    ...props
}: ListBoxItemProps) => {
    return (
        <>
            <ListItem {...props} textValue={name}>
                {children ||
                    (({ isSelected, isDisabled }) => (
                        <>
                            <ListItemInner className="ListBoxItem__inner">
                                {icon}
                                <ListBoxItemImage image={image} />
                                <ListItemLabelWrapper>
                                    <ListItemLabel
                                        slot="label"
                                        $isSelectableCategory={isSelectableCategory}
                                        $isIndented={isIndented}
                                    >
                                        {name}
                                    </ListItemLabel>
                                    <ListItemDescription slot="description">
                                        {description}
                                    </ListItemDescription>
                                </ListItemLabelWrapper>
                                {isSelected && !isDisabled && (
                                    <SelectedIcon
                                        icon={iconFA({
                                            name: 'check',
                                            style: 'solid',
                                        })}
                                    />
                                )}
                                {isDisabled && (
                                    <DisabledIcon
                                        icon={iconFA({
                                            name: 'lock-keyhole',
                                            style: 'solid',
                                        })}
                                    />
                                )}
                            </ListItemInner>
                        </>
                    ))}
            </ListItem>
            {props.hasSeparator && <Separator />}
        </>
    );
};

const ListItemLabel = styled(Text)<{ $isSelectableCategory: boolean; $isIndented: boolean }>`
    font-size: var(--fs-body-m);
    line-height: var(--lh-body-m);
    color: inherit;
    font-weight: ${({ $isSelectableCategory }) => $isSelectableCategory && `var(--fw-bold)`};
    padding-left: ${({ $isIndented, $isSelectableCategory }) => {
        return $isIndented && !$isSelectableCategory && `1rem`;
    }};
`;
const ListItemDescription = styled(Text)`
    font-size: var(--fs-body-s);
    line-height: var(--lh-body-s);
    color: var(--neutral500);
`;
const ListItemInner = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.75rem;
    padding: 0.375rem 0.5rem;
    border-radius: var(--r-s);
    color: var(--black);
    cursor: pointer;
`;
const ListItem = styled(AriaListBoxItem)`
    display: flex;
    flex-direction: column;

    &[data-hovered='true'] > ${ListItemInner} {
        background-color: var(--neutral50);
    }
    &[data-selected='true'] > ${ListItemInner} {
        background-color: var(--primary50);
        color: var(--primary);
    }
    &[data-disabled='true'] > ${ListItemInner} {
        opacity: 0.5;
    }
`;

const ListItemLabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;
const Image = styled.img`
    width: 2.5rem;
    height: 2.5rem;
    border-radius: var(--r-s);
`;
const Icon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const ImagePlaceholder = styled.div`
    width: 2.5rem;
    height: 2.5rem;
    border-radius: var(--r-s);
    background-color: var(--neutral100);
    display: flex;
    align-items: center;
    justify-content: center;

    & > ${Icon} {
        width: 1rem;
        height: 1rem;
        color: var(--neutral500);
    }
`;

const SelectedIcon = styled(Icon)`
    color: var(--primary);
`;
const DisabledIcon = styled(Icon)`
    color: var(--neutral500);
`;
const Separator = styled(AriaSeparator)`
    height: 1px;
    border-radius: var(--r-full);
    background-color: var(--neutral100);
    width: calc(100% - 1rem);
    margin-block: 0.5rem;
    align-self: center;
    pointer-events: none;
`;

export default ListBoxItem;
