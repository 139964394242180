import PropTypes from 'prop-types';
import { cloneElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon as faicon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Text from '@ui/atoms/Text';
import styled, { css } from 'styled-components';

export const CARD_SELECT_VARIANTS = {
    SMALL: 'small',
    LARGE: 'large',
};

const CardSelectInput = ({
    title,
    description,
    icon,
    isDisabled,
    isSelected,
    id,
    variant,
    ...props
}) => {
    const name = (
        <Text
            color={isDisabled ? '--neutral200' : '--black'}
            variant="body"
            fontWeight="--fw-semibold"
        >
            {title}
        </Text>
    );
    return (
        <Label
            isSelected={isSelected}
            isDisabled={isDisabled}
            hasIcon={!!icon}
            hasDescription={!!description}
            variant={variant}
        >
            <RadioWrapper isSelected={isSelected}>
                {isSelected ? (
                    <FontAwesomeIcon
                        icon={faicon({ name: 'circle-dot', style: 'solid' })}
                        size="lg"
                    />
                ) : (
                    <FontAwesomeIcon
                        icon={faicon({ name: 'circle', style: 'regular' })}
                        size="lg"
                    />
                )}
            </RadioWrapper>
            {icon}
            {description ? (
                <InnerWrapper variant={variant} isSelected={isSelected}>
                    {name}
                    <Description
                        isSmall={variant === CARD_SELECT_VARIANTS.SMALL}
                        isDisabled={isDisabled}
                        numberOfLines={3}
                    >
                        {description}
                    </Description>
                </InnerWrapper>
            ) : (
                name
            )}
            <Input
                variant={variant}
                checked={isSelected}
                type="radio"
                id={id}
                disabled={isDisabled}
                {...props}
            />
        </Label>
    );
};

const CardSelect = ({ tooltip, ...props }) => {
    if (!tooltip) return <CardSelectInput {...props} />;

    return cloneElement(tooltip, null, <CardSelectInput {...props} />);
};

const Label = styled.label`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 1.5rem;
    width: 100%;
    padding: 1.5rem 1rem;
    border: 1px solid;
    border-color: var(--neutral200);
    border-radius: var(--r-s);
    background-color: var(--white);
    transition: all 250ms;
    text-align: center;
    cursor: pointer;
    flex: 1;
    &:focus-within {
        box-shadow: var(--s-primary-light);
    }
    &:hover {
        border-color: var(--neutral400);
    }

    ${(p) =>
        p.isDisabled &&
        css`
            cursor: not-allowed;
            pointer-events: none;
            &:focus-within {
                box-shadow: unset;
            }
            ${p.hasIcon &&
            `& > svg {
                fill: var(--neutral200);
            }`}
        `}

    ${(p) =>
        p.isSelected &&
        css`
            border-color: var(--primary);
            background-color: var(--primary50);
            &:hover {
                border-color: var(--primary);
            }
            ${p.hasIcon &&
            `& > svg {
                    fill: var(--primary);
                }`}
        `}

        ${(p) =>
        p.variant === CARD_SELECT_VARIANTS.SMALL &&
        css`
            flex-direction: row;
            justify-content: initial;
            align-items: start;
            column-gap: 1rem;
            padding: 1rem;
        `}
`;
const InnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;
    flex: 1;

    color: ${({ isSelected }) => (isSelected ? 'var(--primary)' : 'inherit')};
    ${(p) => p.variant === CARD_SELECT_VARIANTS.SMALL && 'align-items: flex-start;'}
`;
const Input = styled.input`
    display: none;
    ${(p) =>
        p.variant === CARD_SELECT_VARIANTS.LARGE &&
        css`
            position: absolute;
            top: 1rem;
            right: 1rem;
        `}
`;
const Description = styled(Text)`
    color: ${({ isDisabled }) => (isDisabled ? 'var(--neutral200)' : 'var(--neutral500)')};
    font-weight: var(--fw-large);
    ${({ isSmall }) =>
        isSmall &&
        css`
            text-align: left;
        `}
`;
const RadioWrapper = styled.div`
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 1rem;
    width: 1rem;
    height: 1rem;
    color: ${({ isSelected }) => (isSelected ? 'var(--primary)' : 'var(--neutral500)')};
`;

CardSelect.propTypes = {
    title: PropTypes.node,
    id: PropTypes.string,
    value: PropTypes.string.isRequired,
    name: PropTypes.string,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    icon: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
    isSelected: PropTypes.bool,
    isDisabled: PropTypes.bool,
    onChange: PropTypes.func,
    tooltip: PropTypes.node,
    variant: PropTypes.oneOf(Object.values(CARD_SELECT_VARIANTS)),
};

CardSelect.defaultProps = {
    id: crypto.randomUUID(),
    isSelected: false,
    isDisabled: false,
    variant: CARD_SELECT_VARIANTS.LARGE,
    tooltip: null,
    onChange: () => {},
};

export default CardSelect;
