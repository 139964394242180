import { z } from 'zod';

const useXTemplateFormSchema = () => {
    return z.object({
        messageTpl: z.string(),
    });
};

export type XTemplateFormSchema = z.infer<ReturnType<typeof useXTemplateFormSchema>>;

export default useXTemplateFormSchema;
