import { useEffect, useState } from 'react';
import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import PreventFormOnTransition from './PreventFormOnTransition';
import FormErrorBoundary from '@/components/legacy/FormErrorBoundary';
import { useFocusOnMount } from '@/shared/hooks/useFocusOnMount';
import { useModalToastQueue } from '@/shared/hooks/useModalToastQueue.hook';

function Form({
    form,
    onSubmit,
    onBeforeSubmit,
    onAfterSubmit,
    onChange,
    children,
    inputs = [],
    noToast = false,
    sync,
    forceCanSubmit,
    ...props
}) {
    const [formCreated, setForm] = useState(null);
    const refFocusMount = useFocusOnMount([formCreated]);
    const refreshFormWithProps = () => setForm(form());
    const toast = useModalToastQueue();

    useEffect(refreshFormWithProps, inputs);
    useEffect(() => {
        if (!sync || !formCreated) return;
        Object.entries(sync).forEach(([key, val]) => {
            const field = formCreated.fields[key];
            if (field.value !== val) {
                formCreated.fields[key].setValue(val);
            }
        });
    }, [sync]);
    useEffect(() => {
        if (!onChange) return;
        autorun(() => {
            if (formCreated) {
                onChange(formCreated.json);
            }
        });
    }, [formCreated]);

    async function submit(e) {
        if (e) e.preventDefault();

        // Allow a PUT on submit from a different form
        if (forceCanSubmit) formCreated.forceCanSubmit();

        if (!formCreated.isValid || !formCreated.hasChanged) return;

        try {
            if (onBeforeSubmit) {
                const proceed = await onBeforeSubmit(formCreated);
                if (typeof proceed === 'boolean' && !proceed) return;
            }

            const promise = onSubmit(formCreated.json, formCreated);
            if (!promise || !promise.then) return;

            await promise;
            if (formCreated.autosave) {
                formCreated.resetChanged();
            } else {
                refreshFormWithProps();
            }
            if (onAfterSubmit) {
                onAfterSubmit(formCreated);
            }
            if (!formCreated.autosave && !noToast && !onAfterSubmit) {
                toast.success();
            }
        } catch (error) {
            if (!formCreated.autosave && !noToast && !onAfterSubmit) {
                toast.alert();
            }
            formCreated.errorFromApi(error);
            throw error;
        }
    }

    useEffect(() => {
        if (!formCreated || !formCreated.autosave) return undefined;
        return autorun(() => submit(null, formCreated, formCreated.json), { delay: 2000 });
    }, [formCreated]);

    if (!formCreated) {
        return <DeprecatedPaper as="form" w="100%" {...props} />;
    }

    const childProps = Object.assign(formCreated, {
        isLoading: onSubmit.pending,
        submit,
        refFocusMount,
    });
    return (
        <>
            <DeprecatedPaper as="form" w="100%" onSubmit={submit} {...props}>
                {formCreated.warnIfNotSaved && (
                    <PreventFormOnTransition
                        routes={formCreated.allowedRoutes}
                        when={formCreated.hasChanged}
                        onSubmit={submit}
                        valid={formCreated.isValid}
                        isLoading={onSubmit.pending}
                    />
                )}
                {children(childProps)}
            </DeprecatedPaper>
        </>
    );
}

const SubscribedForm = observer(Form);

const FormComponent = (props) => (
    <FormErrorBoundary>
        <SubscribedForm {...props} />
    </FormErrorBoundary>
);

export default FormComponent;
