import Stack from '@ui/layout/Stack';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import PlayerFormSelectPlaylist from './PlayerFormSelectPlaylist';
import PlayerFormSelectEpisode from './PlayerFormSelectEpisode';
import { ToggleController } from '@ui/atoms/Toggle';
import { useFormContext } from 'react-hook-form';
import { CardSelectListController } from '@ui/molecules/CardSelectList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';
import CardSelect from '@ui/atoms/CardSelect';
import Alert from '@ui/atoms/Alert';
import Text from '@ui/atoms/Text';
import { PlayerFormSchema } from '../../usePlayerFormSchema.hook';
import { InputDurationController } from '@ui/atoms/InputDuration';
import RouterLink from '@/components/Link/RouterLink';
import { useParams } from 'react-router';

const TYPE_ALERT_MESSAGES = defineMessages({
    show: {
        defaultMessage:
            'En mode « Émission », le Smartplayer affiche tous les épisodes de votre émission du plus récent au plus ancien.',
    },
    episode: {
        defaultMessage: 'En mode « Épisode », le Smartplayer affiche l’épisode de votre choix.',
    },
    playlist: {
        defaultMessage: 'En mode « Playlist », le Smartplayer affiche la playlist de votre choix.',
    },
}) as Record<PlayerFormSchema['type'], { defaultMessage: string }>;

interface PlayerFormContentProps {
    noPlaylists: boolean;
}

const PlayerFormContent = ({ noPlaylists }: PlayerFormContentProps) => {
    const { showId } = useParams<{ showId: string }>();
    const intl = useIntl();
    const { control, watch } = useFormContext<PlayerFormSchema>();
    const type = watch('type');

    return (
        // @ts-ignore
        <Stack $gap="2rem">
            {/* @ts-ignore */}
            <Stack $gap="1rem">
                <CardSelectTypeList
                    name="type"
                    label={<FormattedMessage defaultMessage="Type" />}
                    control={control}
                >
                    {/* @ts-ignore */}
                    <CardSelect
                        value="show"
                        title={<FormattedMessage defaultMessage="Émission" />}
                        icon={<CardSelectIcon icon={icon({ name: 'podcast', style: 'duotone' })} />}
                    />
                    {/* @ts-ignore */}
                    <CardSelect
                        value="episode"
                        title={<FormattedMessage defaultMessage="Épisode" />}
                        icon={
                            <CardSelectIcon
                                icon={icon({ name: 'microphone-lines', style: 'duotone' })}
                            />
                        }
                    />
                    {/* @ts-ignore */}
                    <CardSelect
                        value="playlist"
                        title={<FormattedMessage defaultMessage="Playlist" />}
                        icon={
                            <CardSelectIcon
                                icon={icon({ name: 'album-collection', style: 'duotone' })}
                            />
                        }
                    />
                </CardSelectTypeList>
                {type === 'playlist' && noPlaylists ? (
                    <Alert
                        variant="info"
                        defaultIcon
                        action={
                            <RouterLink
                                to={`/app/show/${showId}/playlists`}
                                variant="button-primary"
                            >
                                <FormattedMessage defaultMessage="Créer une playlist" />
                            </RouterLink>
                        }
                    >
                        {/* @ts-ignore */}
                        <Text fontWeight="--fw-semibold">
                            <FormattedMessage defaultMessage="Vous n’avez pour le moment créé aucune playlist." />
                        </Text>
                    </Alert>
                ) : (
                    <Alert variant="neutral" defaultIcon>
                        {/* @ts-ignore */}
                        <Text fontWeight="--fw-semibold">
                            {intl.formatMessage(TYPE_ALERT_MESSAGES[type])}
                        </Text>
                    </Alert>
                )}
            </Stack>
            {type === 'playlist' ? (
                <PlayerFormSelectPlaylist
                    name="playlistSlug"
                    control={control}
                    label={<FormattedMessage defaultMessage="Playlist affichée" />}
                    isDisabled={noPlaylists}
                />
            ) : type === 'episode' ? (
                <PlayerFormSelectEpisode
                    name="episodeId"
                    control={control}
                    label={<FormattedMessage defaultMessage="Épisode affiché" />}
                />
            ) : null}
            {type === 'episode' && (
                <InputDurationController
                    name="startAt"
                    control={control}
                    label={<FormattedMessage defaultMessage="Démarrer l'épisode à" />}
                />
            )}
            {/* @ts-ignore */}
            <Stack $gap="1.5rem">
                {type === 'episode' && (
                    <ToggleController name="episodeList" control={control}>
                        <FormattedMessage defaultMessage="Afficher les autres épisodes de l’émission" />
                    </ToggleController>
                )}
                <ToggleController name="showSwitch" control={control} isDisabled={noPlaylists}>
                    <FormattedMessage defaultMessage="Autoriser la navigation entre les émissions" />
                </ToggleController>
            </Stack>
        </Stack>
    );
};

const CardSelectIcon = styled(FontAwesomeIcon)`
    width: 1.5rem;
    height: 1.5rem;
    color: var(--primary);
`;
const CardSelectTypeList = styled(CardSelectListController)`
    column-gap: 0.75rem;
`;

export default PlayerFormContent;
