import { FC, useState, createContext, useContext, ReactNode } from 'react';

interface TEpisodeSelectContext {
    allSelectedOnPage: boolean;
    allSelected: boolean;
    selected: string[];
    isSelected: (id: string) => boolean;
    toggleSelection: (id: string) => void;
    toggleAllOnPage: () => void;
    toggleAll: () => void;
    setEpisodes: (episodes: string[]) => void;
    resetSelection: () => void;
    resetPageSelection: () => void;
}

const EpisodeSelectContext = createContext<TEpisodeSelectContext | undefined>(undefined);

interface EpisodeSelectContextProviderProps {
    children: ReactNode;
}

export const EpisodeSelectContextProvider: FC<EpisodeSelectContextProviderProps> = ({
    children,
}) => {
    const [episodes, setEpisodes] = useState<string[]>([]);
    const [selected, setSelected] = useState<string[]>([]);
    const [allSelected, setAllSelected] = useState(false);
    const [allSelectedOnPage, setAllSelectedOnPage] = useState(false);

    const isSelected = (id: string) => selected.includes(id);
    const toggleSelection = (id: string) => {
        if (isSelected(id)) {
            setSelected(selected.filter((selectedId) => selectedId !== id));
            setAllSelectedOnPage(false);
            setAllSelected(false);
        } else {
            setSelected([...selected, id]);
            if (selected.length === episodes.length - 1) {
                setAllSelectedOnPage(true);
            }
        }
    };
    const toggleAllOnPage = () => {
        if (allSelectedOnPage) {
            setSelected([]);
        } else {
            setSelected(episodes);
        }
        setAllSelectedOnPage(!allSelectedOnPage);
    };
    const toggleAll = () => {
        setAllSelected(!allSelected);
    };
    const resetSelection = () => {
        setSelected([]);
        setAllSelected(false);
        setAllSelectedOnPage(false);
    };
    const resetPageSelection = () => {
        setSelected([]);
        setAllSelectedOnPage(false);
    };

    const contextValue = {
        allSelectedOnPage,
        allSelected,
        selected,
        isSelected,
        toggleSelection,
        toggleAll,
        toggleAllOnPage,
        setEpisodes,
        resetSelection,
        resetPageSelection,
    };

    return (
        <EpisodeSelectContext.Provider value={contextValue}>
            {children}
        </EpisodeSelectContext.Provider>
    );
};

export const useEpisodeSelectContext = () => {
    const context = useContext(EpisodeSelectContext);
    if (context === undefined) {
        throw new Error(
            'useEpisodeSelectContext must be used within a EpisodeSelectContextProvider',
        );
    }
    return context;
};
