import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Button from '@ui/atoms/Button';
import Text from '@ui/atoms/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { extractCssVariable } from '@/shared/utils/cssVariable';
import Alert from '@ui/atoms/Alert';
import Stack from '@ui/layout/Stack';
import Dialog from '@ui/atoms/Dialog';

function CampaignPauseModal({ isOpen, onOpenChange, campaign, isPaused, onAfterPause }) {
    const onSubmit = async () => {
        if (isPaused) await campaign.publish();
        else {
            await campaign.pause();
            onAfterPause && onAfterPause();
        }
        onOpenChange(false);
    };
    const isLoading = isPaused ? campaign.pause.pending : campaign.publish.pending;

    return (
        <Dialog
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            title={
                isPaused ? (
                    <FormattedMessage defaultMessage="Republier cette campagne" />
                ) : (
                    <FormattedMessage defaultMessage="Mettre la campagne en pause" />
                )
            }
            size="small"
            action={
                <>
                    <Button onPress={() => onOpenChange(false)} variant="secondary">
                        <FormattedMessage defaultMessage="Annuler" />
                    </Button>
                    <Button onPress={onSubmit} isLoading={isLoading}>
                        {isPaused ? (
                            <FormattedMessage defaultMessage="Republier la campagne" />
                        ) : (
                            <FormattedMessage defaultMessage="Mettre en pause" />
                        )}
                    </Button>
                </>
            }
        >
            {isPaused ? (
                <Text>
                    <FormattedMessage defaultMessage="Souhaitez-vous vraiment remettre en ligne cette campagne ?" />
                </Text>
            ) : (
                <Stack $gap="1rem">
                    <Text>
                        <FormattedMessage defaultMessage="Êtes-vous sûr de vouloir mettre en pause cette campagne ?" />
                    </Text>
                    <Alert
                        variant="info"
                        icon={
                            <AlertIcon
                                icon={icon({ name: 'circle-info', style: 'solid' })}
                                color={extractCssVariable('--info500')}
                                size="xl"
                            />
                        }
                    >
                        <Text fontWeight="--fw-semibold">
                            <FormattedMessage defaultMessage="La campagne sera toujours disponible sur les épisodes déjà téléchargés par vos auditeurs." />
                        </Text>
                    </Alert>
                </Stack>
            )}
        </Dialog>
    );
}

const AlertIcon = styled(FontAwesomeIcon)`
    color: var(--info500);
`;

export default CampaignPauseModal;
