import { useState, useEffect } from 'react';
import MobileOrTablet from '@ui/molecules/MobileOrTablet';
import Desktop from '@ui/molecules/Desktop';
import Img from '@ui/atoms/Img';
import Text from '@ui/atoms/Text';
import Uploader from '@ui/smarties/Uploader';
import Stack from '@ui/layout/Stack';
import Cluster from '@ui/layout/Cluster';
import IconEdit from '@ui/icons/IconEdit';
import IconDelete from '@ui/icons/IconDelete';
import IconButton from '@ui/atoms/IconButton';
import styled from 'styled-components';
import { messageParsers } from '@/forms/validators/apiValidation';
import { FormattedMessage } from 'react-intl';
import { checkImgDimensions, checkImgFormat, checkImgSize } from '@/shared/utils/file';
import { commonMessages } from '@/shared/services/commonMessageService';
import useUserQuery from '@/queries/user/useUserQuery.hook';

const BorderedIconButton = styled(IconButton)`
    border: 1px solid var(--neutral200);
`;

const LabelWrapper = styled.div`
    align-self: flex-start;
`;

const UploadButton = styled.div`
    border: 1px solid var(--neutral200);
    border-radius: var(--r-full);
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    padding: 0.55rem;
`;

const OrientationWrapper = ({ horizontal, children, ...props }) => (
    <>
        {horizontal ? (
            <Cluster $gap="1rem" $align="stretch">
                {children}
            </Cluster>
        ) : (
            <Stack $gap="1rem" $align={props.align || 'center'}>
                {children}
            </Stack>
        )}
    </>
);

const MessageImg = ({ horizontal, error }) => (
    <Stack $gap="0.5rem">
        {error && (
            <Text variant="footnote" color="--alert" textAlign={!horizontal && 'center'}>
                {error}
            </Text>
        )}
        <Text variant="footnote" color="--neutral500" textAlign={!horizontal && 'center'}>
            <MobileOrTablet>{commonMessages('idealSizeImg', 'imageMobile')}</MobileOrTablet>
            <Desktop>{commonMessages('idealSizeImg', 'image')}</Desktop>
        </Text>
    </Stack>
);

function ImgUploader({
    field,
    value,
    onUpload,
    customUploadButton,
    circle,
    message: CustomMessage,
    label = null,
    horizontal,
    contain = false,
    dimensions = {},
    errorMessage,
    onChange,
    handleDeleteFile,
    ...props
}) {
    const IMG_DIMENSIONS = { width: 400, height: 400, ...dimensions };
    const [src, setSrc] = useState(field?.value || value || null);
    const { data: user } = useUserQuery();
    const [error, setError] = useState(null);

    // Updates the image with the one coming from the API
    useEffect(() => {
        if (field && typeof field.value === 'string') {
            setSrc(field.value);
        }
        if (value) {
            setSrc(value);
        }
    }, [field?.value, value]);

    const handleChange = (file) => {
        onChange && onChange(file);
        const reader = new FileReader();
        const img = new Image();
        reader.onload = () => setSrc(reader.result);

        const imageHasGoodFormat = checkImgFormat(file);

        const imageHasGoodSize = checkImgSize(file, user?.language, 10);

        !imageHasGoodFormat
            ? setError(
                  <FormattedMessage
                      defaultMessage="{message}"
                      values={{ message: messageParsers.Mimes(['jpg', 'png']) }}
                  />,
              )
            : !imageHasGoodSize
            ? setError(
                  <FormattedMessage
                      defaultMessage="{message}"
                      values={{ message: messageParsers.Max(10000, 'file') }}
                  />,
              )
            : (img.src = URL.createObjectURL(new File([file], 'pic')));

        img.onload = () => {
            const { width, height } = IMG_DIMENSIONS;
            const imageHasGoodDimensions = checkImgDimensions(img, {
                minWidth: width,
                minHeight: height,
            });

            if (file && imageHasGoodDimensions) {
                reader.readAsDataURL(file);
                field && field.setValue(file);
                onUpload && onUpload(file);
                setError(null);
            } else {
                setError(
                    <FormattedMessage
                        defaultMessage="{message}"
                        values={{
                            message: messageParsers.Dimensions(
                                `minWidth=${width}`,
                                `minHeight=${height}`,
                            ),
                        }}
                    />,
                );
            }
        };
    };

    function deleteFile() {
        setSrc(undefined);
        field?.setValue(null);
        handleDeleteFile && handleDeleteFile();
    }

    return (
        <Stack $align={!horizontal && 'center'} $gap="1rem">
            {label && (
                <LabelWrapper>
                    <Text fontWeight="--fw-semibold">{label}</Text>
                </LabelWrapper>
            )}
            <OrientationWrapper horizontal={horizontal} {...props}>
                <Img
                    bordered
                    circle={circle}
                    src={src}
                    style={{ objectFit: contain && 'contain' }}
                    {...props}
                />

                <Stack $align={!horizontal && 'center'} $justify="space-between" $gap="1rem">
                    <Cluster $gap="0.5rem">
                        <Uploader accept="image/*" name={props.name} onChange={handleChange}>
                            <UploadButton role="button" data-testid="image-input-upload-button">
                                <IconEdit />
                            </UploadButton>
                        </Uploader>
                        <BorderedIconButton
                            variant="ghost"
                            icon={<IconDelete />}
                            aria-label="Delete image"
                            onClick={deleteFile}
                            color="black"
                            data-testid="image-input-delete-button"
                        />
                    </Cluster>

                    {CustomMessage || (
                        <MessageImg
                            horizontal={horizontal}
                            error={errorMessage || field?.error || error}
                        />
                    )}
                </Stack>
            </OrientationWrapper>
        </Stack>
    );
}

export default ImgUploader;
