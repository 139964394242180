import EditEmailModal from '@app/organisms/EditEmailModal';
import EditPasswordModal from '@app/organisms/EditPasswordModal';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@ui/atoms/Button';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useOverlayTriggerState } from 'react-stately';
import styled from 'styled-components';
import useProfileFormSchema from './useProfileFormSchema.hook';
import { SelectController } from '@ui/molecules/Select';
import { InputTextController } from '@ui/atoms/InputText';
import InputText from '@ui/atoms/InputText/InputText';
import { useResponsive } from '@/utils/hooks/useResponsive';
import IconEdit from '@ui/icons/IconEdit';
import { ImgUploaderController } from '@ui/molecules/ImgUploader';
import UnsavedFormModal from '@app/molecules/UnsavedFormModal';

const SubmitButton = styled(Button)`
    margin-top: 1rem;
    align-self: center;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`;

const InnerWrapper = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;

    ${(p) => p.theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
        column-gap: 2.75rem;
    }
`;

const ResponsiveWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;

    ${(p) => p.theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
        column-gap: 1rem;
    }
`;

const FieldsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;

    ${(p) => p.theme.mediaQueries.desktopAndUp} {
        flex: 1;
    }
`;

const EditButton = styled(Button)`
    padding: 0;
    margin-left: auto;
    &:hover > span {
        text-decoration: underline;
    }
`;

const ProfileForm = ({ languages, countries, timezones, onSubmit, user, isLoading }) => {
    const intl = useIntl();
    const { isMobileOrTablet } = useResponsive();
    const schema = useProfileFormSchema({ languages, countries, timezones, locale: user.locale });
    const { control, handleSubmit, formState, clearErrors, watch, setValue, reset } = useForm({
        mode: 'onChange',
        defaultValues: {
            firstname: user.firstName || '',
            lastname: user.lastName || '',
            language: user.languageCode || '',
            country: user.countryCode || '',
            timezone: user.timezoneName || '',
            avatar: user.avatar || '',
        },
        resolver: yupResolver(schema),
    });

    let editPasswordModalState = useOverlayTriggerState({});
    let editEmailModalState = useOverlayTriggerState({});

    watch(); // Hack to get formState to next tick

    return (
        <>
            <UnsavedFormModal shouldBlockNavigation={formState.isDirty} />
            <Form onSubmit={handleSubmit((formData) => onSubmit(formData, reset))}>
                <InnerWrapper>
                    <ImgUploaderController
                        control={control}
                        name="avatar"
                        label={<FormattedMessage defaultMessage="Photo de profil" />}
                        horizontal={isMobileOrTablet}
                        circle
                        w={isMobileOrTablet ? '96px' : '160px'}
                        h={isMobileOrTablet ? '96px' : '160px'}
                        handleDeleteFile={() => setValue('avatar', null, { shouldDirty: true })}
                    />
                    <FieldsWrapper>
                        <ResponsiveWrapper>
                            <InputTextController
                                control={control}
                                name="firstname"
                                label={<FormattedMessage defaultMessage="Prénom" />}
                                placeholder={intl.formatMessage({
                                    defaultMessage: 'Thomas',
                                })}
                                errorMessage={formState.errors.firstname?.message}
                                onBlur={() => clearErrors('firstname')}
                            />
                            <InputTextController
                                control={control}
                                name="lastname"
                                label={<FormattedMessage defaultMessage="Nom" />}
                                placeholder={intl.formatMessage({
                                    defaultMessage: 'Pesquet',
                                })}
                                errorMessage={formState.errors.lastname?.message}
                                onBlur={() => clearErrors('lastname')}
                            />
                        </ResponsiveWrapper>
                        <SelectController
                            control={control}
                            name="country"
                            label={<FormattedMessage defaultMessage="Pays" />}
                            errorMessage={formState.errors.country?.message}
                            onBlur={() => clearErrors('country')}
                            options={countries.map((country) => ({
                                id: country.code,
                                label: country.name,
                            }))}
                        />
                        <SelectController
                            control={control}
                            name="language"
                            label={<FormattedMessage defaultMessage="Langue" />}
                            errorMessage={formState.errors.language?.message}
                            onBlur={() => clearErrors('language')}
                            options={languages.map((language) => ({
                                id: language.code,
                                label: language.nativeName,
                            }))}
                        />
                        <SelectController
                            control={control}
                            name="timezone"
                            label={<FormattedMessage defaultMessage="Fuseau horaire" />}
                            errorMessage={formState.errors.timezone?.message}
                            onBlur={() => clearErrors('timezone')}
                            options={timezones.map((timezone) => ({
                                id: timezone.id,
                                label: timezone.name,
                            }))}
                        />
                        <InputText
                            label={<FormattedMessage defaultMessage="Adresse email" />}
                            value={user?.email}
                            isDisabled
                            tooltip={
                                <EditButton
                                    variant="ghost"
                                    onPress={editEmailModalState.open}
                                    color="--primary"
                                    fontWeight="--fw-semibold"
                                    startIcon={<IconEdit color="--primary" />}
                                >
                                    <FormattedMessage defaultMessage="Modifier" />
                                </EditButton>
                            }
                        />
                        <InputText
                            label={<FormattedMessage defaultMessage="Mot de passe" />}
                            value="********"
                            isDisabled
                            tooltip={
                                <EditButton
                                    variant="ghost"
                                    onPress={editPasswordModalState.open}
                                    color="--primary"
                                    fontWeight="--fw-semibold"
                                    startIcon={<IconEdit color="--primary" />}
                                >
                                    <FormattedMessage defaultMessage="Modifier" />
                                </EditButton>
                            }
                        />
                    </FieldsWrapper>
                </InnerWrapper>
                <SubmitButton
                    type="submit"
                    isLoading={isLoading}
                    isDisabled={!formState.isDirty || !formState.isValid || isLoading}
                >
                    <FormattedMessage defaultMessage="Enregistrer" />
                </SubmitButton>
            </Form>
            {editPasswordModalState.isOpen && (
                <EditPasswordModal
                    isOpen={editPasswordModalState.isOpen}
                    onClose={editPasswordModalState.close}
                />
            )}
            {editEmailModalState.isOpen && (
                <EditEmailModal
                    isOpen={editEmailModalState.isOpen}
                    onClose={editEmailModalState.close}
                />
            )}
        </>
    );
};

export default ProfileForm;
