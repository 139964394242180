import Model from './Model';

class OverallStatsModel extends Model {
    static attributes = {
        today: 'today',
        lastWeek: 'last7days',
        lastMonth: 'lastMonth',
        beforeLastMonth: 'beforeLastMonth',
        fromStart: 'totalDownloads',
        averageDays: 'avg_days',
        averageWeeks: 'avg_weeks',
        averageMonths: 'avg_months',
        averagePodcasts: 'avg_podcasts',
    };
}

export default OverallStatsModel;
