import { css } from 'styled-components';
import PropTypes from 'prop-types';
import { resolveColor } from '@/shared/utils/cssVariable';

/**
 * See https://chakra-ui.com/docs/features/style-props
 */
const backgroundMixin = css`
    background: ${(props) =>
        props.$background ? resolveColor(props.$background) : props.$bg && resolveColor(props.$bg)};
    background-color: ${(props) =>
        props.$backgroundColor
            ? resolveColor(props.$backgroundColor)
            : props.$bgColor && resolveColor(props.$bgColor)};
    background-image: ${(props) => props.$backgroundImage ?? props.$bgImage};
    background-size: ${(props) => props.$backgroundSize ?? props.$bgSize};
    background-position: ${(props) => props.$backgroundPosition ?? props.$bgPosition};
    background-repeat: ${(props) => props.$backgroundRepeat ?? props.$bgRepeat};
    background-attachment: ${(props) => props.$backgroundAttachment ?? props.$bgAttachment};
    background-clip: ${(props) => props.$backgroundClip ?? props.$bgClip};
`;

export const backgroundPropTypes = {
    $background: PropTypes.string,
    $bg: PropTypes.string, // Shortcut for background
    $backgroundColor: PropTypes.string,
    $bgColor: PropTypes.string, // Shortcut for backgroundColor
    $backgroundImage: PropTypes.string,
    $bgImage: PropTypes.string, // Shortcut for backgroundImage
    $backgroundSize: PropTypes.string,
    $bgSize: PropTypes.string, // Shortcut for backgroundSize
    $backgroundPosition: PropTypes.string,
    $bgPosition: PropTypes.string, // Shortcut for backgroundPosition
    $backgroundRepeat: PropTypes.string,
    $bgRepeat: PropTypes.string, // Shortcut for backgroundRepeat
    $backgroundAttachment: PropTypes.string,
    $bgAttachment: PropTypes.string, // Shortcut for backgroundAttachment
    $backgroundClip: PropTypes.string,
    $bgClip: PropTypes.string, // Shortcut for backgroundClip
};

export default backgroundMixin;
