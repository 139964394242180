import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';

const PsoEvolutionCell = ({ trend, rankDiff, missingData }) => {
    const trendLookup = {
        up: {
            icon: <TrendIcon icon={icon({ name: 'circle-up', style: 'solid' })} color="inherit" />,
            sign: '+',
            color: '--success',
        },
        down: {
            icon: (
                <TrendIcon icon={icon({ name: 'circle-down', style: 'solid' })} color="inherit" />
            ),
            sign: '',
            color: '--alert',
        },
        neutral: {
            icon: (
                <TrendIcon icon={icon({ name: 'circle-right', style: 'solid' })} color="inherit" />
            ),
            sign: '',
            color: '--neutral',
        },
        new: {
            icon: <TrendIcon icon={icon({ name: 'circle-up', style: 'solid' })} color="inherit" />,
            sign: '',
            color: '--success',
        },
        exited: {
            icon: (
                <TrendIcon icon={icon({ name: 'circle-down', style: 'solid' })} color="inherit" />
            ),
            sign: '',
            color: '--alert',
        },
    };

    if (missingData && trend === 'neutral') return <Dash />;
    return (
        <EvolutionCellWrapper color={trendLookup[trend].color}>
            {trendLookup[trend].icon}
            <RankDiff>
                {trendLookup[trend].sign}
                {trend === 'new' ? (
                    <FormattedMessage defaultMessage="Gagné" />
                ) : trend === 'exited' ? (
                    <FormattedMessage defaultMessage="Perdu" />
                ) : (
                    rankDiff
                )}
            </RankDiff>
        </EvolutionCellWrapper>
    );
};

const EvolutionCellWrapper = styled.div`
    display: inline-flex;
    gap: 0.25rem;
    color: ${({ color }) => `var(${color})`};
    background-color: ${({ color }) => `var(${color}50)`};
    border-radius: var(--r-s);
    padding-inline: 0.5rem;
    padding-block: 0.25rem;
    align-items: center;
    justify-content: center;
    font-weight: var(--fw-semibold);
`;
const TrendIcon = styled(FontAwesomeIcon)`
    font-size: 0.75rem;
`;
const RankDiff = styled.div``;
const Dash = styled.div`
    width: 1rem;
    height: 2px;
    background-color: var(--neutral500);
    border-radius: var(--r-full);
`;

PsoEvolutionCell.propTypes = {
    trend: PropTypes.oneOf(['positive', 'negative', 'neutral', 'new', 'exited']).isRequired,
    rankDiff: PropTypes.number,
    missingData: PropTypes.bool,
};

PsoEvolutionCell.defaultProps = {
    rankDiff: 0,
};

export default PsoEvolutionCell;
