import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useAmplitudeLogEvent } from '@/shared/hooks/useAmplitude';
import RouterLink from '@/components/Link/RouterLink';
import emptyStateMainIllustration from '@public/images/empty-state/illustration/users.png';
import Text from '@ui/atoms/Text';
import ZebraIllustration from '@ui/atoms/ZebraIllustration';
import Button from '@ui/atoms/Button';
import EmptyState from '@ui/molecules/EmptyState';
import ShiningDiamond from '@ui/atoms/ShiningDiamond';
import IconRocket from '@ui/icons/IconRocket';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { PRICING } from '@/shared/config/pricing';
import useSubscriptionQuery from '@/queries/subscription/useSubscriptionQuery.hook';
import MenuDropdown from '@ui/atoms/MenuDropdown';
import MenuDropdownItem from '@ui/atoms/MenuDropdown/MenuDropdownItem';
import { DialogTrigger, Popover, Button as AriaButton } from 'react-aria-components';
import exportChannelGuestsMutation from '@queries/guest/exportChannelGuestsMutation';
import exportShowGuestsMutation from '@queries/guest/exportShowGuestsMutation';
import useChannelQuery from '@/queries/channel/useChannelQuery.hook';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import { USER_OPTIONS } from '@/shared/config/userOptions';
import styled from 'styled-components';
import ShowGuestsList from './ShowGuestsList';
import ShowGuestsHeader from './ShowGuestsHeader';
import HideFor from '@/components/unorganized/HideFor';

const ShowGuests = () => {
    useAmplitudeLogEvent('Users Settings Viewed');
    const { showId } = useParams<{ showId: string }>();
    const { data: subscription } = useSubscriptionQuery();
    const user = useUserQuery();
    const channel = useChannelQuery();

    const exportChannelGuests = exportChannelGuestsMutation();
    const exportShowGuests = exportShowGuestsMutation();

    const downloadDataToCsv = (data: string[], filename: string) => {
        const url = window.URL.createObjectURL(new Blob(data));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    const allowedToExport = user?.data?.options?.includes(USER_OPTIONS.GUEST_USERS_EXPORT);

    return (
        <>
            <HideFor
                // @ts-ignore
                userOptions={USER_OPTIONS.INVITATIONS}
                lockedMessage={
                    <EmptyStateOuterWrapper>
                        <Text fontWeight="--fw-bold" variant="title">
                            <FormattedMessage defaultMessage="Utilisateurs" />
                        </Text>
                        <EmptyState
                            illustration={emptyStateMainIllustration}
                            illustrationType="image"
                            illustrationAlign="right"
                            illustrationBackground="--gradient-dawn"
                        >
                            <HeadIllustrationWrapper>
                                <ZebraIllustration>
                                    <ShiningDiamond />
                                </ZebraIllustration>
                            </HeadIllustrationWrapper>
                            <LabelWrapper>
                                <Text variant="heading" fontWeight="--fw-bold">
                                    <FormattedMessage defaultMessage="Passez à l'offre supérieure !" />
                                </Text>
                                <Text variant="body" fontWeight="--fw-normal" color="--neutral500">
                                    <FormattedMessage defaultMessage="Pour avoir la possibilité de gérer votre podcast en équipe, abonnez-vous à l’offre Boost ou Supersonic 🚀" />
                                </Text>
                            </LabelWrapper>
                            <RouterLink
                                to="/app/user/subscription/offers"
                                variant="button-primary"
                                startIcon={<IconRocket />}
                            >
                                <FormattedMessage defaultMessage="Mettre à niveau mon offre" />
                            </RouterLink>
                        </EmptyState>
                    </EmptyStateOuterWrapper>
                }
            >
                <>
                    <HeaderOuterWrapper>
                        <TitleWrapper>
                            <Text fontWeight="--fw-bold" variant="headingM">
                                <FormattedMessage defaultMessage="Utilisateurs" />
                            </Text>
                            {subscription?.pricing === PRICING.ENTERPRISE && allowedToExport && (
                                <DialogTrigger>
                                    {/* @ts-ignore */}
                                    <ExportButton
                                        as={Button}
                                        variant="ghost"
                                        startIcon={
                                            <FontAwesomeIcon
                                                icon={icon({
                                                    name: 'arrow-down-to-bracket',
                                                    style: 'solid',
                                                })}
                                            />
                                        }
                                    >
                                        <FormattedMessage defaultMessage="Export" />
                                    </ExportButton>
                                    <Popover placement="bottom right">
                                        <ExportMenuDropdown
                                            onAction={(
                                                exportType:
                                                    | 'export-show-guest-users'
                                                    | 'export-account-guest-users',
                                            ) => {
                                                if (exportType === 'export-show-guest-users') {
                                                    exportShowGuests.mutate(
                                                        { showId },
                                                        {
                                                            onSuccess: (data) => {
                                                                downloadDataToCsv(
                                                                    data.guests,
                                                                    data.filename,
                                                                );
                                                            },
                                                        },
                                                    );
                                                    return;
                                                }

                                                exportChannelGuests.mutate(
                                                    {
                                                        channelId: `${channel?.data?.id}`,
                                                    },
                                                    {
                                                        onSuccess: (data) => {
                                                            downloadDataToCsv(
                                                                data.guests,
                                                                data.filename,
                                                            );
                                                        },
                                                    },
                                                );
                                            }}
                                        >
                                            {/* @ts-ignore */}
                                            <MenuDropdownItem id="export-show-guest-users">
                                                <MenuDropdownItemIcon
                                                    icon={icon({ name: 'podcast', style: 'solid' })}
                                                />
                                                <FormattedMessage defaultMessage="Cette émission (.csv)" />
                                            </MenuDropdownItem>
                                            {/* @ts-ignore */}
                                            <MenuDropdownItem id="export-account-guest-users">
                                                <MenuDropdownItemIcon
                                                    icon={icon({
                                                        name: 'rectangle-history',
                                                        style: 'solid',
                                                    })}
                                                />
                                                <FormattedMessage defaultMessage="Toutes les émissions (.csv)" />
                                            </MenuDropdownItem>
                                        </ExportMenuDropdown>
                                    </Popover>
                                </DialogTrigger>
                            )}
                        </TitleWrapper>
                        <ShowGuestsHeader />
                    </HeaderOuterWrapper>
                    <ShowGuestsList />
                </>
            </HideFor>
        </>
    );
};

const EmptyStateOuterWrapper = styled.div`
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;
const HeadIllustrationWrapper = styled.div`
    width: 200px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    & > * {
        text-align: center;
    }
`;
const HeaderOuterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;
const TitleWrapper = styled.div`
    display: flex;
    row-gap: 0.25rem;
    justify-content: space-between;
    align-items: center;
`;
const ExportButton = styled(AriaButton)`
    color: var(--neutral500);
`;
const ExportMenuDropdown = styled(MenuDropdown)`
    max-width: fit-content;
`;
const MenuDropdownItemIcon = styled(FontAwesomeIcon)`
    color: var(--black);
`;

export default ShowGuests;
