import { z } from 'zod';
import { useIntl } from 'react-intl';
import { FORM_VALIDATION_MESSAGE } from '@/utils/validationMessage';
import { GUEST_USER_ROLES } from '@/utils/constants';

const useChannelGuestsInvitationFormSchema = () => {
    const intl = useIntl();

    return z.object({
        email: z
            .string()
            .email({
                message: intl.formatMessage(FORM_VALIDATION_MESSAGE.isEmail),
            })
            .min(1, intl.formatMessage(FORM_VALIDATION_MESSAGE.required)),
        role: z.enum(
            [GUEST_USER_ROLES.ADMIN, GUEST_USER_ROLES.EDITOR, GUEST_USER_ROLES.VIEWER] as const,
            {
                required_error: intl.formatMessage(FORM_VALIDATION_MESSAGE.required),
            },
        ),
        message: z
            .string()
            .max(400, {
                message: intl.formatMessage(FORM_VALIDATION_MESSAGE.maxLength, { max: 400 }),
            })
            .optional()
            .nullable(),
        shows: z.array(z.number()).min(1, {
            message: intl.formatMessage(FORM_VALIDATION_MESSAGE.required),
        }),
    });
};

export type ChannelGuestsInvitationFormSchema = z.infer<
    ReturnType<typeof useChannelGuestsInvitationFormSchema>
>;

export default useChannelGuestsInvitationFormSchema;
