import { FormattedMessage, useIntl } from 'react-intl';
import StatsDataSetSelector from '../StatsDataSetSelector';
import { useStatsDateSelectorContext } from '@/context/StatsDateSelectorContext';
import styled from 'styled-components';
import { useParams } from 'react-router';
import useCampaignOperatingSystemsQuery from '@/queries/stats/useCampaignOperatingSystemsQuery.hook';
import useShowOperatingSystemsQuery from '@/queries/stats/useShowOperatingSystemsQuery.hook';
import SourceOperatingSystemsChart from './SourcesOperatingSystemsChart';
import ShortListFallback from '../ShortListFallback';

const SourcesOperatingSystems = () => {
    const intl = useIntl();
    const { startDate, endDate } = useStatsDateSelectorContext();
    const { showId, itemType, itemId } = useParams<{
        showId: string;
        itemType: string;
        itemId: string;
    }>();
    const campaignOperatingSystems = useCampaignOperatingSystemsQuery({
        campaignId: itemId,
        query: { start: startDate, end: endDate },
        options: { enabled: itemType === 'campaign' && !!itemId },
    });
    const showOperatingSystems = useShowOperatingSystemsQuery({
        showId,
        query: { start: startDate, end: endDate },
        options: { enabled: itemType !== 'campaign' && !!showId },
    });
    const operatingSystems =
        itemType === 'campaign' ? campaignOperatingSystems.data : showOperatingSystems.data;
    const emptyStateAxis = [
        'Android',
        'Linux',
        'Windows',
        'iOS',
        'Mac',
        intl.formatMessage({ defaultMessage: 'Autres' }),
    ];

    if (operatingSystems) {
        const series = operatingSystems.map((system) => system.percentage);
        const dataAxis = operatingSystems.map((system) => {
            if (system.osName === 'Other') {
                return intl.formatMessage({ defaultMessage: 'Autres' });
            }
            return system.osName;
        });
        const xAxisCategories = operatingSystems.length > 0 ? dataAxis : emptyStateAxis;

        return (
            <>
                <StatsDataSetSelector
                    tabTitle={<FormattedMessage defaultMessage="Systèmes d'exploitation" />}
                    tabDescription={
                        <FormattedMessage defaultMessage="Quels sont les systèmes d'exploitation les plus appréciés de vos auditeurs ?" />
                    }
                    tabId="sources"
                    noSelector
                />
                <BarGraphWrapper>
                    <SourceOperatingSystemsChart
                        options={{ xaxis: { categories: xAxisCategories } }}
                        series={series}
                    />
                </BarGraphWrapper>
            </>
        );
    }

    return <ShortListFallback />; // TODO: Handle loading state
};

const BarGraphWrapper = styled.div`
    min-height: 150px;
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding);
    margin-block: 2rem;

    ${({ theme }) => theme.mediaQueries.mobile} {
        padding: var(--container-padding-mobile);
    }
`;

export default SourcesOperatingSystems;
