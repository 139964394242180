import { FormattedMessage } from 'react-intl';
import TableArrow from '@ui/atoms/TableArrow';
import { useToggle } from '@/shared/hooks/useToggle';
import DeprecatedWhitePaper from '@ui/atoms/DeprecatedWhitePaper';
import PaperGrid from '@ui/atoms/PaperGrid';
import Column from '@ui/atoms/Column';
import { round } from '@/shared/utils/number';
import SourcesExportButton from '../SourcesExportButton';
import ServerPagination from '@ui/ServerPagination';
import { useStatsDateSelectorContext } from '@/context/StatsDateSelectorContext';
import NoDataChart from '@/components/charts/NoData/NoDataChart';
import styled from 'styled-components';
import { useParams } from 'react-router';
import useQuery from '@/shared/hooks/useQuery';
import { useState } from 'react';
import useSourcePlatformsQuery from '@/queries/stats/useSourcePlatformsQuery.hook';
import useCampaignSourcePlatformsQuery from '@/queries/stats/useCampaignSourcePlatformsQuery.hook';

function SourcesPlatformsTable() {
    const { showId } = useParams();
    const query = useQuery();
    const episodes = query.get('episode');
    const campaignId = query.get('campaignId');
    const { startDate, endDate } = useStatsDateSelectorContext();
    const [order, toggleOrder] = useToggle('desc', 'asc');
    const [page, setPage] = useState(1);

    const { data: showPlatforms } = useSourcePlatformsQuery({
        showId,
        episodes: episodes && episodes !== 'all' ? episodes.split('_') : [],
        start: startDate,
        end: endDate,
        order,
        page,
        options: {
            enabled: !campaignId,
        },
    });
    const { data: campaignPlatforms } = useCampaignSourcePlatformsQuery({
        campaignId,
        episodes: episodes && episodes !== 'all' ? episodes.split('_') : [],
        start: startDate,
        end: endDate,
        order,
        page,
        options: {
            enabled: !!campaignId,
        },
    });

    const platforms = campaignId ? campaignPlatforms : showPlatforms;

    return (
        <>
            <DeprecatedWhitePaper my={25}>
                <PaperGrid column={4} py={8} px={20}>
                    <Column startColumn={1} endColumn={3} color="--neutral500">
                        <FormattedMessage defaultMessage="Plateforme" />
                    </Column>
                    <Column
                        startColumn={3}
                        endColumn={4}
                        justify="flex-end"
                        color="--neutral500"
                        onClick={toggleOrder}
                    >
                        <FormattedMessage defaultMessage="Écoutes" />
                        <TableArrow order={order} ml={10} />
                    </Column>
                    <Column startColumn={4} endColumn={5} color="--neutral500" justify="flex-end">
                        <FormattedMessage defaultMessage="Pourcentage" />
                    </Column>
                </PaperGrid>
                {platforms?.data && platforms?.data?.length ? (
                    platforms.data.map((p) => (
                        <PaperGrid
                            column={4}
                            py={15}
                            px={20}
                            key={p.category}
                            bt={1}
                            border="--neutral100"
                        >
                            <Column startColumn={1} endColumn={3} ellipsis>
                                {p.category}
                            </Column>
                            <Column startColumn={3} endColumn={4} justify="flex-end">
                                {p.count}
                            </Column>
                            <Column startColumn={4} endColumn={5} justify="flex-end">
                                {`${round(p.percentage)}%`}
                            </Column>
                        </PaperGrid>
                    ))
                ) : (
                    <NoDataChartWrapper>
                        <NoDataChart />
                    </NoDataChartWrapper>
                )}
            </DeprecatedWhitePaper>
            {platforms?.pagination && platforms?.pagination?.totalPages >= 2 && (
                <ServerPagination
                    pagination={platforms.pagination}
                    onGoToPage={(page) => setPage(page)}
                    onGoToFirstPage={() => setPage(1)}
                    onGoToLastPage={() => setPage(platforms.pagination.totalPages)}
                    onGoToPreviousPage={() => setPage(platforms.pagination.currentPage - 1)}
                    onGoToNextPage={() => setPage(platforms.pagination.currentPage + 1)}
                    options={{
                        goToFirstAndLastPageButtons: false,
                        showPaginationInfo: true,
                        showPageSelector: false,
                    }}
                >
                    <SourcesExportButton />
                </ServerPagination>
            )}
        </>
    );
}

const NoDataChartWrapper = styled.div`
    padding: 1rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        padding: 4rem;
    }
`;

export default SourcesPlatformsTable;
