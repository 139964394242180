import { FORM_VALIDATION_MESSAGE } from '@/shared/services/validationMessageService';
import { useIntl } from 'react-intl';
import { z } from 'zod';

const useHostingSettingsFormSchema = () => {
    const intl = useIntl();

    return z.object({
        subtitle: z.string().max(255).nullable(),
        website: z
            .string()
            .nullable()
            .refine((value) => !value || z.string().url().safeParse(value).success, {
                message: intl.formatMessage(FORM_VALIDATION_MESSAGE.isUrl),
            }),
        copyright: z.string().nullable(),
        ownerName: z.string().nullable(),
        ownerEmail: z
            .string()
            .nullable()
            .refine((value) => !value || z.string().email().safeParse(value).success, {
                message: intl.formatMessage(FORM_VALIDATION_MESSAGE.isEmail),
            }),
        isExplicit: z.boolean(),
        isLocked: z.boolean(),
        hasTracking: z.boolean(),
    });
};

export type HostingSettingsFormSchema = z.infer<ReturnType<typeof useHostingSettingsFormSchema>>;

export default useHostingSettingsFormSchema;
