import { useIntl } from 'react-intl';
import { z } from 'zod';
import { FORM_VALIDATION_MESSAGE } from '@/shared/services/validationMessageService';
import { checkImgSize } from '@/helpers';

interface useWebsiteSettingsFormSchemaProps {
    locale?: string;
}

const useWebsiteSettingsFormSchema = (
    { locale }: useWebsiteSettingsFormSchemaProps = { locale: 'en' },
) => {
    const intl = useIntl();
    const acceptedMimes = ['image/jpg', 'image/jpeg', 'image/png'];

    return z.object({
        isPublished: z.boolean().optional().nullable(),
        isMultiShowEnabled: z.boolean().optional().nullable(),
        displayTranscript: z.boolean().optional().nullable(),
        useTwitter: z.boolean().optional().nullable(),
        twitterAccount: z.string().optional().nullable(),
        recommendedShows: z
            .array(z.object({ value: z.string() }))
            .optional()
            .nullable(),
        googleAnalytics: z.string().max(15).optional().nullable(),
        facebookPixel: z.string().max(16).optional().nullable(),
        color: z.string().optional().nullable(),
        cover: z
            .string()
            .or(
                z
                    .instanceof(File)
                    .refine(
                        (file: File) => {
                            if (file === null) return true;
                            return acceptedMimes.includes(file.type);
                        },
                        {
                            message: intl.formatMessage(FORM_VALIDATION_MESSAGE.acceptedMimes, {
                                acceptedMimes,
                            }) as string,
                        },
                    )
                    .refine(
                        (file: File) => {
                            if (typeof file === 'string' || file === null) return true;
                            return checkImgSize(file, locale, 10);
                        },
                        {
                            message: intl.formatMessage(FORM_VALIDATION_MESSAGE.imgMaxSize, {
                                max: 10,
                            }) as string,
                        },
                    ),
            )
            .optional()
            .nullable(),
        featuredEpisode: z.string().or(z.number()),
    });
};

export default useWebsiteSettingsFormSchema;
