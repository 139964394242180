import { useParams } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { Menu, MenuItem, MenuTrigger, Popover } from 'react-aria-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Button from '@/components/Button';
import styled from 'styled-components';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import { USER_ROLES } from '@/utils/constants';
import { STATUS_SHOWS } from '@/utils/statusShows';
import type { Episode } from '@/queries/episode/useEpisodesQuery.hook';
import useDuplicateEpisodeMutation from '@/queries/episode/useDuplicateEpisodeMutation.hook';
import { useSwitch } from '@hooks/useSwitch';
import DeletePodcastsModal from '@app/organisms/DeletePodcastsModal';
import useDeleteEpisodeMutation from '@/queries/episode/useDeleteEpisodeMutation.hook';

interface ContextualMenuProps {
    episode: Episode;
}

const ContextualMenu = ({ episode }: ContextualMenuProps) => {
    const { showId } = useParams<{ showId: string }>();
    const { data: show } = useShowQuery(showId);
    const duplicateEpisode = useDuplicateEpisodeMutation();
    const deleteEpisode = useDeleteEpisodeMutation();

    const [isDeleteOpened, onDeleteOpen, onDeleteClose] = useSwitch(false);

    const userHasWriteAccess =
        show &&
        ![USER_ROLES.VIEWER, USER_ROLES.EDITOR].includes(show.userRole as 'viewer' | 'editor');
    const notViewerNotArchived =
        show &&
        !['viewer'].includes(show.userRole) &&
        ![STATUS_SHOWS.ARCHIVED_SHOWS].includes(
            show.showStatus as typeof STATUS_SHOWS.ARCHIVED_SHOWS,
        );

    return (
        <>
            <MenuTrigger>
                <MenuButton
                    aria-label="Menu"
                    variant="ghost"
                    startIcon={
                        <FontAwesomeIcon
                            icon={icon({ name: 'ellipsis', style: 'solid' })}
                            fixedWidth
                        />
                    }
                />
                <StyledPopover>
                    <StyledMenu>
                        {notViewerNotArchived && (
                            <StyledMenuItem
                                onAction={() =>
                                    episode?.id &&
                                    duplicateEpisode.mutate({ episodeId: `${episode.id}` })
                                }
                            >
                                <FontAwesomeIcon icon={icon({ name: 'copy', style: 'solid' })} />
                                <FormattedMessage defaultMessage="Dupliquer" />
                            </StyledMenuItem>
                        )}
                        {userHasWriteAccess && (
                            <DestructiveMenuItem onAction={onDeleteOpen}>
                                <FontAwesomeIcon icon={icon({ name: 'trash', style: 'solid' })} />
                                <FormattedMessage defaultMessage="Supprimer" />
                            </DestructiveMenuItem>
                        )}
                    </StyledMenu>
                </StyledPopover>
            </MenuTrigger>
            <DeletePodcastsModal
                isOpened={isDeleteOpened}
                onClose={onDeleteClose}
                count={1}
                deleting={deleteEpisode.isLoading}
                onDelete={() => {
                    if (!episode?.id) return;
                    deleteEpisode.mutate(
                        { episodeId: `${episode.id}` },
                        {
                            onSettled: () => {
                                onDeleteClose();
                            },
                        },
                    );
                }}
                isRedirect={false}
            />
        </>
    );
};

const MenuButton = styled(Button)`
    transition-duration: 0.2s;
    color: var(--neutral500);
    font-size: 1rem;
    padding: 0;

    &:hover {
        color: var(--neutral600);
    }
`;
const StyledPopover = styled(Popover)`
    background-color: var(--white);
    border-radius: var(--r-xs);
    box-shadow: var(--s-light);
    padding: 0.5rem;
`;
const StyledMenu = styled(Menu)`
    min-width: 10rem;
`;
const StyledMenuItem = styled(MenuItem)`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding-block: 0.5rem;
    padding-inline: 0.75rem;
    border-radius: var(--r-xs);
    cursor: pointer;
    color: var(--black);
    text-decoration: none;
    &:hover {
        background-color: var(--neutral50);
    }
    &[data-disabled='true']:hover {
        background-color: var(--white);
    }
    &[data-disabled='true'] {
        color: var(--neutral200);
        cursor: not-allowed;
    }
`;
const DestructiveMenuItem = styled(StyledMenuItem)`
    color: var(--alert);

    &:hover {
        background-color: var(--alert50);
    }
    &[data-disabled='true']:hover {
        background-color: var(--white);
    }
    &[data-disabled='true'] {
        color: var(--neutral200);
        cursor: not-allowed;
    }
`;

export default ContextualMenu;
