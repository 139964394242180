import RouterLink from '@/components/Link/RouterLink';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';

const CancelButton = () => {
    const { showId } = useParams<{ showId: string }>();

    return (
        <RouterLink variant="button-tertiary" to={`/app/show/${showId}/monetize/manual`}>
            <FormattedMessage defaultMessage="Annuler" />
        </RouterLink>
    );
};

export default CancelButton;
