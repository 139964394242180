import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import VirtualList from 'react-tiny-virtual-list';
import DeprecatedButton from '../../ui/atoms/DeprecatedButton';
import SelectableCampaignEpisode from '../molecules/SelectableCampaignEpisode';
import { connect } from '../decorators/connect';
import CampaignEpisodesSelectionCount from '../molecules/CampaignEpisodesSelectionCount';
import { useArray } from '../../../utils/hooks/useArray';
import { useOnClose } from '../../../utils/hooks/useOnClose';
import DeprecatedPaper from '../../ui/atoms/DeprecatedPaper';
import SearchBar from '../../ui/molecules/SearchBar';
import DeprecatedText from '../../ui/atoms/DeprecatedText';
import ShortListFallback from '../fallbacks/ShortListFallback';
import styled from 'styled-components';
import Button from '@ui/atoms/Button';
import IconLoader from '@ui/icons/IconLoader';
import Dialog from '@ui/atoms/Dialog';

const LoadMoreWrapper = styled.div`
    padding-block: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const enhance = connect(({ routerStore, podcastStore }) => ({
    showId: routerStore.params.showId,
    fetchShrinkedEpisodes: podcastStore.fetchShrinkedEpisodes,
}));

function CampaignEpisodesModal({ isOpen, onOpenChange, showId, field, fetchShrinkedEpisodes }) {
    const selectedEpisodes = useArray(field.value);
    const [episodes, setEpisodes] = useState([]);
    const [currentPagination, setCurrentPagination] = useState({ perPage: 20, currentPage: 1 });
    const [fetchIsLoading, setFetchIsLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    useOnClose(selectedEpisodes.reset, isOpen);

    function toggleSelectAll() {
        if (selectedEpisodes.array.length === episodes.length) {
            selectedEpisodes.setArray([]);
        } else {
            selectedEpisodes.setArray(episodes.map((episode) => episode.id));
        }
    }

    function toggleSelection(episodeId) {
        if (selectedEpisodes.array.includes(episodeId)) {
            selectedEpisodes.remove(episodeId);
        } else {
            selectedEpisodes.push(episodeId);
        }
    }

    function onSubmit() {
        field.setValue(selectedEpisodes.array);
        onOpenChange(false);
    }

    const searchEpisodes = async (query, currentPage, isLoadMore = false) => {
        setFetchIsLoading(true);
        const { episodes: newEpisodes, pagination } = await fetchShrinkedEpisodes(
            showId,
            query,
            currentPage,
            currentPagination.perPage,
        );

        setCurrentPagination(pagination);
        isLoadMore ? setEpisodes([...episodes, ...newEpisodes]) : setEpisodes([...newEpisodes]);
        setFetchIsLoading(false);
    };

    useEffect(() => {
        searchEpisodes('', currentPagination.currentPage);
    }, [showId]);

    const fetchMoreEpisodes = () => {
        if (currentPagination.currentPage === currentPagination.totalPages) return;
        searchEpisodes('', currentPagination.currentPage + 1, true);
    };

    return (
        <Dialog
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            title={<FormattedMessage defaultMessage="Appliquer la campagne" />}
            size="large"
        >
            <DeprecatedPaper flex column align="center" mb={60}>
                <DeprecatedPaper maxW={670}>
                    <DeprecatedPaper flex align="center" justify="space-between">
                        <FormattedMessage defaultMessage="Rechercher un épisode...">
                            {(placeholder) => (
                                <SearchBar
                                    value={searchQuery}
                                    onChange={(query) => {
                                        setSearchQuery(query);
                                        setEpisodes([]);
                                        searchEpisodes(query, 1);
                                    }}
                                    placeholder={placeholder.join('')}
                                />
                            )}
                        </FormattedMessage>
                        <DeprecatedText
                            ml={35}
                            weight="semibold"
                            color="--primary"
                            onClick={toggleSelectAll}
                        >
                            <FormattedMessage defaultMessage="Tout sélectionner" />
                        </DeprecatedText>
                    </DeprecatedPaper>
                    <DeprecatedPaper mt={20} height={500}>
                        {fetchIsLoading ? (
                            <ShortListFallback />
                        ) : (
                            <VirtualList
                                width="100%"
                                height={500}
                                itemCount={episodes.length}
                                itemSize={85}
                                renderItem={({ index, style }) => (
                                    <div key={index} style={style}>
                                        <SelectableCampaignEpisode
                                            episode={episodes[index]}
                                            isSelected={selectedEpisodes.array.includes(
                                                episodes[index].id,
                                            )}
                                            toggleSelection={() =>
                                                toggleSelection(episodes[index].id)
                                            }
                                        />
                                    </div>
                                )}
                            />
                        )}

                        {currentPagination.currentPage < currentPagination.totalPages && (
                            <LoadMoreWrapper>
                                <Button
                                    variant="ghost"
                                    color="--primary"
                                    onPress={() => !fetchIsLoading && fetchMoreEpisodes()}
                                >
                                    {fetchIsLoading ? (
                                        <IconLoader />
                                    ) : (
                                        <FormattedMessage defaultMessage="Charger plus d'épisodes" />
                                    )}
                                </Button>
                            </LoadMoreWrapper>
                        )}
                    </DeprecatedPaper>
                </DeprecatedPaper>
            </DeprecatedPaper>
            <DeprecatedPaper flex>
                <CampaignEpisodesSelectionCount />
                <DeprecatedButton accent="secondary" mr={10} onClick={() => onOpenChange(false)}>
                    <FormattedMessage defaultMessage="Annuler" />
                </DeprecatedButton>
                <DeprecatedButton onClick={onSubmit}>
                    <FormattedMessage defaultMessage="Enregistrer" />
                </DeprecatedButton>
            </DeprecatedPaper>
        </Dialog>
    );
}

export default enhance(CampaignEpisodesModal);
