import DeprecatedWhitePaper from '@ui/atoms/DeprecatedWhitePaper';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import Portal from '@ui/molecules/Portal';
import DeprecatedIcon from '@ui/atoms/DeprecatedIcon';
import Desktop from '@ui/molecules/Desktop';
import MobileOrTablet from '@ui/molecules/MobileOrTablet';
import useDroppable from '@/utils/hooks/useDroppable';
import arrowDown from '@public/icons/chevrondown.svg';
import VerticalDropContainer from '../../ui/atoms/VerticalDropContainer';
import PlaylistLine from './PlaylistLine';

function PlaylistMenuDropdown({ img, label, children, playlist, isFirst, ...props }) {
    const [ref, opened, open] = useDroppable();
    const [refPlaylist, openedPlaylist, openPlaylist] = useDroppable();
    if (!playlist) {
        return null;
    }
    return (
        <>
            <Desktop>
                <DeprecatedWhitePaper overflowHide={false}>{children}</DeprecatedWhitePaper>
            </Desktop>
            <MobileOrTablet>
                <DeprecatedPaper z={1} ref={ref} {...props}>
                    <DeprecatedWhitePaper flex align="center">
                        <PlaylistLine onClick={open} key={playlist.id} playlist={playlist} />
                        <DeprecatedPaper
                            ref={refPlaylist}
                            py={20}
                            w="max-content"
                            onClick={openPlaylist}
                        >
                            <DeprecatedIcon mx={15} size={11} as={arrowDown} color="--neutral500" />
                        </DeprecatedPaper>
                    </DeprecatedWhitePaper>
                    {openedPlaylist && (
                        <Portal id="playlistMenuDropdown">
                            <DeprecatedWhitePaper
                                w="90vw"
                                z="9999"
                                pos="fixed"
                                y="50%"
                                x="50%"
                                style={{ transform: 'translate(-50%, -50%)' }}
                            >
                                {children[0].map((playlist, i) => (
                                    <DeprecatedPaper key={i}>{playlist}</DeprecatedPaper>
                                ))}
                            </DeprecatedWhitePaper>
                        </Portal>
                    )}
                    <VerticalDropContainer w="100%" isOpened={opened}>
                        {children}
                    </VerticalDropContainer>
                </DeprecatedPaper>
            </MobileOrTablet>
        </>
    );
}

PlaylistMenuDropdown.defaultProps = {
    img: '',
    label: '',
};

export default PlaylistMenuDropdown;
