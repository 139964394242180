import styled, { css, keyframes } from 'styled-components';
import Stack from '@ui/layout/Stack';
import Cluster from '@ui/layout/Cluster';

const CallToActionFormSkeleton = () => {
    return (
        <Wrapper>
            {/* Skeleton for "Button text" input */}
            <Stack $gap="0.5rem">
                <Line $w="8rem" $h="1rem" />
                <Block $w="100%" $h="2.5rem" />
            </Stack>

            {/* Skeleton for "Button URL" input */}
            <Stack $gap="0.5rem">
                <Line $w="8rem" $h="1rem" />
                <Block $w="100%" $h="2.5rem" />
            </Stack>

            {/* Skeleton for the checkbox group */}
            <Stack $gap="1rem">
                <Line $w="12rem" $h="1rem" />
                <Stack $gap="0.75rem">
                    <CheckboxSkeleton />
                    <CheckboxSkeleton />
                    <CheckboxSkeleton />
                    <CheckboxSkeleton />
                </Stack>
            </Stack>

            {/* Skeleton for the buttons */}
            <Cluster $gap="1rem" $align="center" $justify="flex-end">
                <ButtonBlock $w="6rem" $h="2.5rem" />
                <ButtonBlock $w="8rem" $h="2.5rem" />
            </Cluster>
        </Wrapper>
    );
};

// Skeleton helper for checkboxes
const CheckboxSkeleton = () => (
    <Cluster $gap="0.75rem" $align="center">
        <Block $w="1.25rem" $h="1.25rem" />
        <Line $w="6rem" $h="1rem" />
    </Cluster>
);

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    max-width: 42.5rem;
    width: 100%;
`;

const shimmer = keyframes`
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
`;

const shimmering = css`
    background: var(--neutral50);
    background-image: linear-gradient(
        to right,
        var(--neutral50) 0%,
        #edeef1 20%,
        var(--neutral50) 40%,
        var(--neutral50) 100%
    );
    background-repeat: no-repeat;
    background-size: 50rem 6.5rem;
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${shimmer};
    animation-timing-function: linear;
`;

const Line = styled.div<{ $w?: string; $h?: string }>`
    border-radius: var(--r-full);
    background-color: var(--neutral100);
    ${({ $w }) => $w && `width: ${$w};`}
    ${({ $h }) => $h && `height: ${$h};`}
    ${shimmering};
`;

const Block = styled.div<{ $w?: string; $h?: string }>`
    border-radius: var(--r-s);
    background-color: var(--neutral100);
    ${({ $w }) => $w && `width: ${$w};`}
    ${({ $h }) => $h && `height: ${$h};`}
    ${shimmering};
`;

const ButtonBlock = styled(Block)`
    margin-top: 1.5rem;
`;

export default CallToActionFormSkeleton;
