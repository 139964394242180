import { useContext, useRef } from 'react';
import styled, { css } from 'styled-components';
import { mergeProps, useRadio, useFocusRing, useHover, VisuallyHidden } from 'react-aria';
import Text from '@ui/atoms/Text';
import { RadioContext } from '@ui/molecules/RadioGroup';

interface InputRadioProps {
    isDisabled?: boolean;
    children: React.ReactNode;
    value: any;
    className?: string;
}

const InputRadio = ({ className, ...props }: InputRadioProps) => {
    const { children, value } = props;
    const state = useContext(RadioContext);
    const ref = useRef(null);
    const { inputProps, isDisabled } = useRadio(props, state, ref);
    const { hoverProps, isHovered } = useHover(props);
    const { focusProps } = useFocusRing();
    const isSelected = state?.selectedValue === value;

    return (
        <StyledLabel isDisabled={isDisabled} className={className}>
            <VisuallyHidden>
                <input {...mergeProps(inputProps, focusProps)} ref={ref} />
            </VisuallyHidden>
            <StyledRadio isSelected={isSelected} isHovered={isHovered} {...hoverProps} />
            {children}
        </StyledLabel>
    );
};

const StyledLabel = styled(Text).attrs({ forwardedAs: 'label' })<{ isDisabled: boolean }>`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    position: relative; // Use this because of VisuallyHidden styles causing issues with the input position
    cursor: pointer;

    ${(props) =>
        props.isDisabled &&
        css`
            opacity: 0.5;
            pointer-events: none;
        `};
`;

const StyledRadio = styled.div<{ isHovered: boolean; isSelected: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 1rem;
    height: 1rem;
    border-radius: var(--r-full);
    border: 1px solid var(--neutral300);
    cursor: pointer;

    ${(props) =>
        props.isHovered &&
        css`
            border-color: var(--neutral500);
        `}
    ${(props) =>
        props.isSelected &&
        css`
            position: relative;

            &::after {
                content: '';
                background: var(--gradient-primary);
                height: 0.625rem;
                width: 0.625rem;
                border-radius: var(--r-full);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            border: 1px solid var(--primary);

            ${props.isHovered &&
            css`
                opacity: 0.85;
            `}
        `}
`;

export default InputRadio;
