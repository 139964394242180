import Button from '@/components/Button';
import Modal from '@/components/ui/atoms/Modal';
import Text from '@/components/ui/atoms/Text';
import Stack from '@/components/ui/layout/Stack';
import aiVideo from '@public/videos/ai.mp4';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import EpisodeAIUnauthorizedStateAction from '../EpisodeAIUnauthorizedState/EpisodeAIUnauthorizedStateAction';

interface EpisodeAISubscriptionInfoModalProps {
    isOpen: boolean;
    onOpenChange: (open: boolean) => void;
}

const EpisodeAISubscriptionInfoModal = ({
    isOpen,
    onOpenChange,
}: EpisodeAISubscriptionInfoModalProps) => {
    const handleClose = () => onOpenChange(false);

    return (
        <ModalStyled
            data-testid="episode-ai-subscription-modal"
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            size="small"
        >
            <Video muted autoPlay loop playsInline>
                <source src={aiVideo} type="video/mp4" />
            </Video>
            <Stack $gap="0.25rem">
                <Text variant="headingM" fontWeight="--fw-bold" textAlign="center">
                    <FormattedMessage defaultMessage="Optimisez votre podcast sans effort grâce à Ausha Intelligence" />
                </Text>
                <Text color="--neutral500" textAlign="center">
                    <FormattedMessage defaultMessage="Ajoutez dès maintenant Ausha Intelligence à n'importe quelle offre payante pour profiter des fonctionnalités d'AI + 60 minutes additionnelles de transcription par mois !" />
                </Text>
            </Stack>
            <Actions>
                <EpisodeAIUnauthorizedStateAction onAction={handleClose} />
                <Button
                    data-testid="episode-ai-subscription-modal-close-button"
                    variant="link-secondary"
                    onPress={handleClose}
                >
                    <FormattedMessage defaultMessage="Peut-être plus tard" />
                </Button>
            </Actions>
        </ModalStyled>
    );
};

const ModalStyled = styled(Modal)`
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1.5rem;
`;
const Video = styled.video`
    display: block;
    border-radius: var(--r-m);
    width: 100%;
    height: 10rem;
    object-fit: cover;
`;
const Actions = styled.div`
    align-self: stretch;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    & > :last-child {
        align-self: center;
    }
`;
export default EpisodeAISubscriptionInfoModal;
