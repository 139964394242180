import { FormattedMessage } from 'react-intl';
import EmptyState from '@ui/molecules/EmptyState';
import PropTypes from 'prop-types';
import Text from '@ui/atoms/Text';
import Button from '@ui/atoms/Button';
import noPostMainIllustration from '@public/images/empty-state/illustration/calendarView.mp4';
import noPostHeadIllustration from '@public/images/empty-state/social_networks.svg?url';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';

const NoPostEmptyState = ({ handleNewPost }) => {
    return (
        <EmptyState illustration={noPostMainIllustration} illustrationType="video">
            <HeadIllustrationWrapper>
                <HeadIllustration src={noPostHeadIllustration} />
            </HeadIllustrationWrapper>
            <LabelWrapper>
                <Text variant="heading" fontWeight="--fw-bold">
                    <FormattedMessage defaultMessage="Créez votre premier post" />
                </Text>
                <Text variant="body" fontWeight="--fw-normal" color="--neutral500">
                    <FormattedMessage defaultMessage="Planifiez et partagez des posts pour promouvoir votre podcast 📣" />
                </Text>
            </LabelWrapper>
            <SocialMediaButtonWrapper>
                <Button
                    onPress={() => handleNewPost()}
                    startIcon={
                        <FontAwesomeIcon icon={icon({ name: 'plus', style: 'solid' })} size="sm" />
                    }
                    data-testid="empty-state-create-post-button"
                >
                    <FormattedMessage defaultMessage="Créer un post" />
                </Button>
            </SocialMediaButtonWrapper>
        </EmptyState>
    );
};

const HeadIllustrationWrapper = styled.div`
    width: 200px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const HeadIllustration = styled.img`
    min-width: 248px;
`;
const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    & > * {
        text-align: center;
    }
`;
const SocialMediaButtonWrapper = styled.div`
    position: relative;
`;

NoPostEmptyState.propTypes = {
    handleNewPost: PropTypes.func.isRequired,
};

export default NoPostEmptyState;
