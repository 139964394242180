import RssExportBlock from '../organisms/RssExportBlock';
import RssExportConfiguration from '../organisms/RssExportConfiguration';
import { useAmplitudeLogEvent } from '@hooks/useAmplitude';

function RssExport() {
    useAmplitudeLogEvent('show RSS export');

    return (
        <>
            <RssExportBlock />
            <RssExportConfiguration />
        </>
    );
}

export default RssExport;
