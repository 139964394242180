import { useState } from 'react';
import Button from '@/components/Button';
import { mediumButtonStyles } from '@/components/Button/styles/sizes/medium';
import { smallButtonStyles } from '@/components/Button/styles/sizes/small';
import Text from '@/components/ui/atoms/Text';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';
import usePlaylistQuery from '@/queries/playlist/usePlaylistQuery.hook';
import usePlaylistsQuery from '@/queries/playlist/usePlaylistsQuery.hook';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import { USER_ROLES } from '@/shared/config/constants';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TooltipTrigger } from 'react-aria-components';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import styled from 'styled-components';
import AddEpisodesToPlaylistModal from './AddEpisodesToPlaylistModal';

const PlaylistHeader = () => {
    const { showId, playlistId } = useParams<{ showId: string; playlistId: string }>();
    const playlists = usePlaylistsQuery(showId);
    const playlist = usePlaylistQuery(playlistId);
    const playlistHasEpisodes = (playlist.data?.episodes ?? []).length > 0;
    const show = useShowQuery(showId);
    const isShowArchived = show.data?.archived ?? false;
    const isGuestViewer = show.data?.userRole === USER_ROLES.VIEWER;
    const isCTADisabled = !show.data || isShowArchived || isGuestViewer;
    const [isEpisodesModalOpen, setIsEpisodesModalOpen] = useState(false);

    return (
        <>
            <Wrapper>
                <Left>
                    <Icon icon={icon({ name: 'folders', style: 'solid' })} />
                    <Text>
                        <FormattedMessage
                            defaultMessage="{count, plural,
                            =0 {Aucune playlist}
                            one {# playlist}
                            other {# playlists}
                        }"
                            values={{ count: playlists.data?.length }}
                        />
                    </Text>
                </Left>
                {playlistHasEpisodes && (
                    <TooltipTrigger delay={0} closeDelay={0} isDisabled={!isGuestViewer}>
                        <TooltipTriggerWrapper>
                            <AddEpisodeToPlaylistButton
                                isDisabled={isCTADisabled}
                                onPress={() => setIsEpisodesModalOpen(true)}
                                startIcon={
                                    <ButtonIcon icon={icon({ name: 'plus', style: 'solid' })} />
                                }
                                $variant="primary"
                            >
                                <FormattedMessage defaultMessage="Ajouter à la playlist" />
                            </AddEpisodeToPlaylistButton>
                        </TooltipTriggerWrapper>
                        <Tooltip placement="top">
                            <FormattedMessage defaultMessage="Vous devez être éditeur ou administrateur de l'émission pour ajouter un épisode à une playlist." />
                        </Tooltip>
                    </TooltipTrigger>
                )}
            </Wrapper>
            <AddEpisodesToPlaylistModal
                isOpen={isEpisodesModalOpen}
                onOpenChange={setIsEpisodesModalOpen}
            />
        </>
    );
};

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 1rem;
    height: 2.5rem;
`;
const Left = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.25rem;
`;
const Icon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--neutral500);
`;
const ButtonIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--white);
`;
const AddEpisodeToPlaylistButton = styled(Button)`
    ${smallButtonStyles};

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        ${mediumButtonStyles};
    }
`;

export default PlaylistHeader;
