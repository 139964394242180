import Model from './Model';

class BrowserModel extends Model {
    static attributes = {
        name: 'client_name',
        count: 'count',
        percentage: 'percentage',
    };
}

export default BrowserModel;
