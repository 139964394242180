import { ToastQueue, useToastQueue } from '@react-stately/toast';
import ToastRegion from '@ui/atoms/ToastRegion';
import ReactDOM from 'react-dom';

export const bodyToastQueue = new ToastQueue({
    maxVisibleToasts: 8,
    // hasExitAnimation: true,
});
export const modalToastQueue = new ToastQueue({
    maxVisibleToasts: 8,
    // hasExitAnimation: true,
});

const GlobalToastRegions = (props) => {
    const globalState = useToastQueue(bodyToastQueue);
    const modalState = useToastQueue(modalToastQueue);
    const modalContainerElement = document.getElementById('modal-root');

    const regions = [
        {
            state: globalState,
            container: document.body,
        },
        {
            state: modalState,
            container: modalContainerElement,
        },
    ];

    return regions.map((region) => {
        const { state, container } = region;
        return state.visibleToasts.length > 0
            ? ReactDOM.createPortal(<ToastRegion {...props} state={state} />, container)
            : null;
    });
};

export default GlobalToastRegions;
