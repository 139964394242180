import Model from './Model';

class ClipTranscriptModel extends Model {
    static attributes = {
        start: 'start',
        end: 'end',
        subtitle: 'subtitle',
    };
}

export default ClipTranscriptModel;
