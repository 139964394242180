import RouterLink from '@/components/Link/RouterLink';
import Text from '@/components/ui/atoms/Text';
import usePsoQuery from '@/queries/pso/usePsoQuery.hook';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import styled, { css } from 'styled-components';

const STATUS_LABELS = {
    LOW: <FormattedMessage defaultMessage="Faible" />,
    MEDIUM: <FormattedMessage defaultMessage="Moyen" />,
    HIGH: <FormattedMessage defaultMessage="Élevé" />,
};
const SEARCH_VOLUME_STATUS = {
    LOW: { color: '--alert', label: STATUS_LABELS.LOW },
    MEDIUM: { color: '--warning', label: STATUS_LABELS.MEDIUM },
    HIGH: { color: '--success', label: STATUS_LABELS.HIGH },
};
const DIFFICULTY_SCORE_STATUS = {
    LOW: { color: '--success', label: STATUS_LABELS.LOW },
    MEDIUM: { color: '--warning', label: STATUS_LABELS.MEDIUM },
    HIGH: { color: '--alert', label: STATUS_LABELS.HIGH },
};

const getSearchVolumeStatus = (value: number) => {
    if (value < 33) return SEARCH_VOLUME_STATUS.LOW;
    if (value < 66) return SEARCH_VOLUME_STATUS.MEDIUM;
    return SEARCH_VOLUME_STATUS.HIGH;
};
const getDifficultyScoreStatus = (value: number) => {
    if (value > 66) return DIFFICULTY_SCORE_STATUS.HIGH;
    if (value < 33) return DIFFICULTY_SCORE_STATUS.LOW;
    return DIFFICULTY_SCORE_STATUS.MEDIUM;
};

interface KeywordMetadataProps {
    searchVolume: number | null;
    difficultyScore: number | null;
    keyword: string;
}

const KeywordMetadata = ({ searchVolume, difficultyScore, keyword }: KeywordMetadataProps) => {
    const { showId }: { showId: string } = useParams();
    const psoConfiguration = usePsoQuery({ showId, enabled: true });
    const searchVolumeStatus = getSearchVolumeStatus(searchVolume ?? 0);
    const difficultyScoreStatus = getDifficultyScoreStatus(difficultyScore ?? 0);
    const hasNodata = !searchVolume && !difficultyScore;

    if (hasNodata) {
        const platform =
            psoConfiguration.data?.listeningPlatforms?.[0] === 'apple_podcasts'
                ? 'itunes'
                : 'spotify';
        const store = psoConfiguration.data?.stores?.data?.[0]?.alpha2Code;
        const isMissingValues = !platform || !store || !keyword;
        const liveSearchLink = `/app/show/${showId}/pso/livesearch?keyword=${keyword}&platform=${platform}&mode=show&store=${store}`;

        return (
            <Wrapper>
                <EmptyState>
                    <Text color="--neutral500">
                        <FormattedMessage defaultMessage="Aucune donnée n'est actuellement disponible" />
                    </Text>
                    {!isMissingValues && (
                        <RouterLink
                            to={liveSearchLink}
                            target="_blank"
                            endIcon={
                                <LinkIcon icon={icon({ name: 'arrow-up-right', style: 'solid' })} />
                            }
                        >
                            <FormattedMessage defaultMessage="Lancer l'analyse" />
                        </RouterLink>
                    )}
                </EmptyState>
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            <Metadata $isNull={!searchVolume} $color={searchVolumeStatus.color}>
                <Text variant="bodyS" color="--neutral500">
                    <FormattedMessage defaultMessage="Vol. de recherche" />
                </Text>
                {!searchVolume ? (
                    <Text variant="bodyS" fontWeight="--fw-semibold">
                        <FormattedMessage defaultMessage="En cours de calcul" />
                    </Text>
                ) : (
                    <Text variant="bodyS" fontWeight="--fw-semibold">
                        {searchVolumeStatus.label} ({searchVolume}%)
                    </Text>
                )}
            </Metadata>
            <Metadata $isNull={!difficultyScore} $color={difficultyScoreStatus.color}>
                <Text variant="bodyS" color="--neutral500">
                    <FormattedMessage defaultMessage="Difficulté" />
                </Text>
                {!difficultyScore ? (
                    <Text variant="bodyS" fontWeight="--fw-semibold">
                        <FormattedMessage defaultMessage="En cours de calcul" />
                    </Text>
                ) : (
                    <Text variant="bodyS" fontWeight="--fw-semibold">
                        {difficultyScoreStatus.label} ({difficultyScore}%)
                    </Text>
                )}
            </Metadata>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    column-gap: 0.5rem;
    border-radius: var(--r-s);
    width: 14.5rem;
`;
const Metadata = styled.div<{ $color: string; $isNull: boolean }>`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    border-radius: var(--r-xs);
    text-align: left;

    ${({ $isNull, $color }) =>
        !$isNull &&
        css`
            color: var(${$color});
            background-color: var(${$color}50);
        `}
`;
const EmptyState = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 0.25rem;
    text-align: center;
    font-size: var(--fs-body-s);
    width: 100%;
`;
const LinkIcon = styled(FontAwesomeIcon)`
    width: 0.5rem;
    color: var(--primary);
`;

export default KeywordMetadata;
