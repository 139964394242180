import styled from 'styled-components';
import Text from '@ui/atoms/Text';
import { resolveColor } from '@/shared/utils/cssVariable';

function Legend({ color, label, value }) {
    return (
        <LegendWrapper>
            <Left>
                <Chip color={color} />
                <Label>{label}</Label>
            </Left>
            <Text fontWeight="--fw-semibold" size="heading">
                {value}
            </Text>
        </LegendWrapper>
    );
}

const LegendWrapper = styled.li`
    list-style: none;
    background: var(--white);
    border: 1px solid ${(props) => (props.active ? 'var(--primary)' : 'var(--neutral200)')};
    padding-inline: 1rem;
    height: 3rem;
    border-radius: var(--r-xs);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-basis: calc(100% - 0.25rem);

    ${(props) => props.theme.mediaQueries.desktopAndUp} {
        flex-basis: calc(50% - 0.25rem);
    }
`;

const Left = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Chip = styled.div`
    border-radius: var(--r-full);
    width: 10px;
    min-width: 10px;
    height: 10px;
    min-height: 10px;
    margin-right: 10px;
    background: ${(p) => resolveColor(p.color)};
`;

const Label = styled.span`
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 40px;
`;

export default Legend;
