import { useState } from 'react';

export function useArray(initialArray) {
    const [array, setArray] = useState(initialArray);

    function push(elt) {
        setArray([...array, elt]);
    }

    function remove(elt) {
        setArray(array.filter((x) => x !== elt));
    }

    function reset() {
        setArray(initialArray);
    }

    return { array, setArray, push, remove, reset };
}
