import { useFormContext } from 'react-hook-form';
import { InputDurationController } from '@ui/atoms/InputDuration';
import { FormattedMessage } from 'react-intl';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';
import IconButton from '@ui/atoms/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PlayPauseButton = styled(IconButton)`
    padding: 0;
`;

const PlayPauseButtonIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--black);
`;

const SelectedChapterStartTimeField = ({
    index,
    setAudioPlayerCursorPosition,
    setAudioPlayerCursorPositionHasChanged,
    isAudioPlaying,
    playAudio,
}) => {
    const { control, getValues } = useFormContext();

    const onPlay = () => {
        const chapterStartTime = getValues(`chapters.${index}.startTime`);
        setAudioPlayerCursorPosition(chapterStartTime);
        setAudioPlayerCursorPositionHasChanged((hasChanged) => !hasChanged);
        if (!isAudioPlaying) {
            playAudio();
        }
    };

    return (
        <InputDurationController
            name={`chapters.${index}.startTime`}
            control={control}
            label={<FormattedMessage defaultMessage="Début du chapitre" />}
            endIcon={
                <PlayPauseButton
                    onPress={onPlay}
                    variant="ghost"
                    aria-label="PlayButton"
                    icon={
                        <PlayPauseButtonIcon
                            icon={icon({
                                name: 'play',
                                style: 'solid',
                            })}
                        />
                    }
                />
            }
        />
    );
};

export default SelectedChapterStartTimeField;
