import { FormattedMessage } from 'react-intl';
import { TooltipTrigger } from 'react-aria-components';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Stack from '@/components/ui/layout/Stack';
import Text from '@/components/ui/atoms/Text';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Placement } from 'react-aria';

interface GuestRolesTooltipProps {
    placement?: Placement;
}

const GuestRolesTooltip = ({ placement }: GuestRolesTooltipProps) => (
    <TooltipTrigger delay={0} closeDelay={0}>
        <TooltipTriggerWrapper>
            <TooltipIcon icon={icon({ name: 'circle-info', style: 'solid' })} />
        </TooltipTriggerWrapper>
        <ScrollableTooltip placement={placement || 'top'}>
            <Roles>
                <Stack>
                    <Text variant="bodyL" fontWeight="--fw-semibold">
                        <FormattedMessage defaultMessage="Lecteur" />
                    </Text>
                    <Text>
                        <FormattedMessage defaultMessage="Les lecteurs peuvent accéder aux épisodes et aux statistiques de l’émission, mais ne sont pas autorisés à éditer." />
                    </Text>
                </Stack>
                <Stack>
                    <Text variant="bodyL" fontWeight="--fw-semibold">
                        <FormattedMessage defaultMessage="Éditeur" />
                    </Text>
                    <Text>
                        <FormattedMessage defaultMessage=" Les éditeurs peuvent ajouter et modifier les épisodes de l’émission, créer des clips vidéos, modifier le SmartPlayer et accéder aux campagnes manuelles." />
                    </Text>
                </Stack>
                <Stack>
                    <Text variant="bodyL" fontWeight="--fw-semibold">
                        <FormattedMessage defaultMessage="Administrateur" />
                    </Text>
                    <Text>
                        <FormattedMessage defaultMessage="Les administrateurs possèdent les mêmes droits que les éditeurs, et peuvent également modifier les rôles des utilisateurs, supprimer des épisodes, accéder à tous les outils de communication ainsi qu'à tous les paramètres de l’émission." />
                    </Text>
                </Stack>
            </Roles>
        </ScrollableTooltip>
    </TooltipTrigger>
);

const TooltipIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: var(--neutral500);
`;
const Roles = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    text-align: left;
`;
const ScrollableTooltip = styled(Tooltip)`
    overflow-y: auto;
`;

export default GuestRolesTooltip;
