import { ChannelGuestInvitation } from '@/queries/guest/useChannelGuestInvitationsQuery.hook';
import { ChannelGuestPermission } from '@/queries/guest/useChannelGuestPermissionsQuery.hook';
import {
    SortingFn,
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table';
import { useMemo } from 'react';
import ChannelGuestsInvitationCell from './ChannelGuestsInvitationCell';
import ChannelGuestsPermissionCell from './ChannelGuestsPermissionCell';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import ChannelGuestsActionCell from './ChannelGuestsActionCell';
import ChannelGuestsRoleCell from './ChannelGuestsRoleCell';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { CHANNEL_GUEST_USER_ROLES } from '@/shared/config/constants';

interface ChannelGuestsTableProps {
    permissions: ChannelGuestPermission[];
    invitations: ChannelGuestInvitation[];
}

export type ChannelGuestRow = Omit<ChannelGuestPermission, 'userId'> & {
    userId: number | null;
    [key: string]: any;
};

const ChannelGuestsTable = ({ permissions, invitations }: ChannelGuestsTableProps) => {
    const intl = useIntl();
    const data = useMemo(() => {
        const formattedInvitations = invitations.map((invitation) => ({
            userName: invitation.email,
            role: invitation.role,
            userId: null,
            userAvatar: null,
            userEmail: null,
        }));
        return [...permissions, ...formattedInvitations];
    }, [permissions, invitations]);

    const roleSortOrder = [
        CHANNEL_GUEST_USER_ROLES.ADMIN,
        CHANNEL_GUEST_USER_ROLES.EDITOR,
        CHANNEL_GUEST_USER_ROLES.VIEWER,
        CHANNEL_GUEST_USER_ROLES.MIXED,
    ];

    const sortRoles: SortingFn<ChannelGuestRow> = (rowA, rowB, columnId) => {
        const roleA = rowA.original[columnId];
        const roleB = rowB.original[columnId];

        return roleSortOrder.indexOf(roleA) - roleSortOrder.indexOf(roleB);
    };

    const columnHelper = createColumnHelper<ChannelGuestRow>();
    const columns = [
        columnHelper.accessor('userName', {
            header: (header) => (
                <TableHeader>
                    {intl.formatMessage({
                        defaultMessage: 'Utilisateurs',
                    })}
                    <SortIcon
                        $isVisible={!!header.column.getIsSorted()}
                        icon={icon({ name: 'arrow-up', style: 'solid' })}
                        flip={header.column.getIsSorted() === 'asc' ? 'vertical' : undefined}
                    />
                </TableHeader>
            ),
            cell: (info) => {
                if (!info.row.original.userId) {
                    return <ChannelGuestsInvitationCell {...info.row.original} />;
                }

                return <ChannelGuestsPermissionCell {...info.row.original} />;
            },
            enableSorting: true,
        }),
        columnHelper.accessor('role', {
            header: (header) => (
                <TableHeader>
                    {intl.formatMessage({
                        defaultMessage: 'Rôles',
                    })}
                    <SortIcon
                        $isVisible={!!header.column.getIsSorted()}
                        icon={icon({ name: 'arrow-up', style: 'solid' })}
                        flip={header.column.getIsSorted() === 'asc' ? 'vertical' : undefined}
                    />
                </TableHeader>
            ),
            cell: (info) => <ChannelGuestsRoleCell role={info.row.original.role} />,
            size: 160,
            enableSorting: true,
            sortingFn: sortRoles,
        }),
        columnHelper.display({
            id: 'actions',
            cell: (info) => <ChannelGuestsActionCell {...info.row.original} />,
            size: 44,
        }),
    ];

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        initialState: {
            sorting: [{ id: 'role', desc: false }],
        },
    });

    return (
        <Table>
            <Thead>
                {table.getHeaderGroups().map((headerGroup) => (
                    <Tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                            <Th
                                key={header.id}
                                style={{
                                    width:
                                        header.getSize() !== 150 ? `${header.getSize()}px` : 'auto',
                                }}
                                onClick={header.column.getToggleSortingHandler()}
                                $isSortable={header.column.getCanSort()}
                            >
                                {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                          header.column.columnDef.header,
                                          header.getContext(),
                                      )}
                            </Th>
                        ))}
                    </Tr>
                ))}
            </Thead>
            <Tbody>
                {table.getRowModel().rows.map((row) => (
                    <Tr key={row.id}>
                        {row.getVisibleCells().map((cell) => (
                            <Td key={cell.id}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </Td>
                        ))}
                    </Tr>
                ))}
            </Tbody>
        </Table>
    );
};
const Table = styled.table`
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
`;
const Td = styled.td`
    height: 3.5rem;
    padding-inline: 1rem;
`;
const Tr = styled.tr``;
const Th = styled.th<{ $isSortable?: boolean }>`
    padding-inline: 1rem;
    text-align: left;

    ${({ $isSortable }) =>
        $isSortable &&
        `
        cursor: pointer;
    `}
`;
const Tbody = styled.tbody`
    ${Tr} {
        border-bottom: 1px solid var(--neutral100);
    }
`;
const Thead = styled.thead`
    position: sticky;
    top: 0;
    width: 100%;
    background-color: var(--neutral50);
    height: 3rem;
    z-index: 1;

    ${Tr} > ${Th}:first-of-type {
        border-top-left-radius: var(--r-m);
        border-bottom-left-radius: var(--r-m);
    }
    ${Tr} > ${Th}:last-of-type {
        border-top-right-radius: var(--r-m);
        border-bottom-right-radius: var(--r-m);
    }
`;
const SortIcon = styled(FontAwesomeIcon)<{ $isVisible?: boolean }>`
    color: var(--neutral500);
    width: 0.75rem;
    height: 0.75rem;
    display: none;

    ${({ $isVisible }) =>
        $isVisible &&
        `
        display: block;
    `}
`;
const TableHeader = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.25rem;

    &:hover {
        ${SortIcon} {
            display: block;
        }
    }
`;

export default ChannelGuestsTable;
