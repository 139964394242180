import { useRef } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import ErrorMessage from '@/components/ui/atoms/ErrorMessage';
import { FileTrigger, Button as RACButton, DropZone as RACDropZone } from 'react-aria-components';
import { IMG_TYPES } from '@/utils/constants';
import { dropZoneStateStyles, dropZoneStyles } from '../styles';
import { DropUploaderProps } from '../types';
import { DropEvent, FileDropItem } from 'react-aria';

const DropImgUploader = ({
    onChange,
    hasError,
    errorMessage,
    children,
    className,
    allowsMultiple = false,
    acceptedFileTypes = Array.from(IMG_TYPES),
    defaultCamera,
    isDisabled = false,
    value,
    ...props
}: DropUploaderProps) => {
    const triggerButtonRef = useRef<HTMLButtonElement>(null);
    const imageSource =
        typeof value === 'string'
            ? value
            : value && value.length > 0
            ? URL.createObjectURL(value[0])
            : '';

    const handleChange = (files: File[]) => {
        if (!files || files.length < 1) {
            onChange?.(null);
            return;
        }
        onChange?.(files);
    };

    const handleDrop = async (e: DropEvent) => {
        let files = e.items.filter((file) => file.kind === 'file') as FileDropItem[];
        const resolvedFiles = await Promise.all(files.map((file) => file.getFile()));
        handleChange(resolvedFiles);
    };

    const handleSelectFiles = (e: FileList | null) => {
        let files = e ? Array.from(e) : [];
        handleChange(files);
    };

    const handleTriggerInput = () => {
        triggerButtonRef.current?.click();
    };

    return (
        <DropZone
            {...props}
            isDisabled={isDisabled || (!!value && !hasError)}
            $hasError={hasError}
            className={className}
            onDrop={handleDrop}
        >
            <DropZoneInner
                onClick={() => {
                    if (value && !hasError) return;
                    handleTriggerInput();
                }}
            >
                {value && !hasError ? (
                    <PreviewWrapper>
                        <CloseButton onPress={() => onChange?.(null)}>
                            <CrossIcon icon={icon({ name: 'xmark', style: 'solid' })} />
                        </CloseButton>
                        <PreviewImg src={imageSource} alt="Preview" />
                        <PreviewOverlayWrapper>
                            <PreviewOverlay onClick={handleTriggerInput}>
                                <PreviewOverlayIcon
                                    icon={icon({ name: 'pen-line', style: 'solid' })}
                                />
                            </PreviewOverlay>
                        </PreviewOverlayWrapper>
                    </PreviewWrapper>
                ) : (
                    <>
                        {children}
                        {hasError && <ErrorMessage hasIcon>{errorMessage}</ErrorMessage>}
                    </>
                )}
            </DropZoneInner>
            <FileTrigger
                onSelect={handleSelectFiles}
                allowsMultiple={allowsMultiple}
                acceptedFileTypes={acceptedFileTypes}
                defaultCamera={defaultCamera}
            >
                <HiddenTriggerButton isDisabled={isDisabled} ref={triggerButtonRef} />
            </FileTrigger>
        </DropZone>
    );
};

const DropZone = styled(RACDropZone)`
    ${dropZoneStyles}
    ${dropZoneStateStyles}
`;
const HiddenTriggerButton = styled(RACButton)`
    display: none;
`;
const DropZoneInner = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    justify-content: center;
    align-items: center;
`;
const PreviewOverlayIcon = styled(FontAwesomeIcon)`
    width: 1.5rem;
    height: 1.5rem;
    color: var(--white);
`;
const PreviewOverlay = styled.div`
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    border-radius: var(--r-m);
    justify-content: center;
    align-items: center;
    display: none;
    cursor: pointer;
`;
const PreviewOverlayWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--r-m);

    &:hover ${PreviewOverlay} {
        display: flex;
    }
`;
const PreviewWrapper = styled.div`
    width: 100%;
    height: 100%;
    max-width: 20rem;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.1);
    border-radius: var(--r-m);
    position: relative;
`;
const PreviewImg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: var(--r-m);
`;
const CloseButton = styled(RACButton)`
    border: none;
    padding: 0;
    width: 1.25rem;
    height: 1.25rem;
    background-color: var(--alert500);
    border-radius: var(--r-full);
    cursor: pointer;
    position: absolute;
    right: -0.625rem;
    top: -0.625rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
`;
const CrossIcon = styled(FontAwesomeIcon)`
    color: var(--white);
    width: 0.75rem;
    height: 0.75rem;
`;

export default DropImgUploader;
