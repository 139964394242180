import useShowsQuery, {
    MinimalShow,
    MinimalShows,
    MinimalShowsSchema,
} from '@queries/show/useShowsQuery.hook';

const useArchivedShowsQuery = () => {
    return useShowsQuery({
        select: (data: MinimalShows) => {
            const resultData = {
                shows: data?.shows.filter((show: MinimalShow) => show.archived),
                pagination: data?.pagination,
            };

            return MinimalShowsSchema.parse(resultData);
        },
    });
};

export default useArchivedShowsQuery;
