import { createContext, useEffect, useState } from 'react';

interface AccordionItemProps {
    children: React.ReactNode;
    isExpanded?: boolean;
    onToggle?: (index?: number) => void;
    index?: number;
    prevIndex?: number;
    isDisabled?: boolean;
    onDisabled?: (index: number, isDisabled: boolean) => void;
}

export const AccordionItemContext = createContext<{
    isExpanded?: boolean;
    onToggle?: (index?: number) => void;
    open?: () => void;
    close?: () => void;
    index?: number;
    hasClosed?: boolean;
    isDisabled?: boolean;
}>({});

const AccordionItem = ({
    children,
    isExpanded,
    onToggle,
    index,
    prevIndex,
    isDisabled,
    onDisabled,
}: AccordionItemProps) => {
    const [hasClosed, setHasClosed] = useState(false);

    const isExpandedD = isExpanded || false;
    const isDisabledD = isDisabled || false;
    const onToggleD = onToggle || (() => {});
    const handleOpen = () => {
        if (isExpandedD) return;
        onToggleD(index);
    };
    const handleClose = () => {
        if (!isExpandedD) return;
        onToggleD(index);
    };

    useEffect(() => {
        if (!onDisabled || !index) return;
        onDisabled(index, isDisabledD);
    }, [isDisabledD]);

    useEffect(() => {
        if (prevIndex !== index || isExpanded) {
            if (hasClosed) setHasClosed(false);
            return;
        }
        setHasClosed(true);
    }, [prevIndex, isExpanded]);

    return (
        <AccordionItemContext.Provider
            value={{
                isExpanded: isExpandedD,
                onToggle: onToggleD,
                open: handleOpen,
                close: handleClose,
                index,
                hasClosed,
                isDisabled: isDisabledD,
            }}
        >
            {children}
        </AccordionItemContext.Provider>
    );
};

export default AccordionItem;
