import { z } from 'zod';
import { useIntl } from 'react-intl';
import { FORM_VALIDATION_MESSAGE } from '@/shared/services/validationMessageService';

const TYPE_VALUES = ['show', 'episode', 'playlist'] as const;
const FORMAT_VALUES = ['auto', 'horizontal', 'vertical'] as const;
const SIZE_UNIT_VALUES = ['pixel', 'percent'] as const;

const usePlayerFormSchema = () => {
    const intl = useIntl();

    return z
        .object({
            type: z.enum(TYPE_VALUES).refine((val) => TYPE_VALUES.includes(val), {
                message: intl.formatMessage(FORM_VALIDATION_MESSAGE.required),
            }),
            episodeId: z.string().optional(),
            playlistSlug: z.string().optional(),
            startAt: z.coerce
                .number()
                .min(0, intl.formatMessage(FORM_VALIDATION_MESSAGE.min, { min: 0 }))
                .optional(),
            episodeList: z.boolean({
                required_error: intl.formatMessage(FORM_VALIDATION_MESSAGE.required),
            }),
            showSwitch: z.boolean({
                required_error: intl.formatMessage(FORM_VALIDATION_MESSAGE.required),
            }),
            color: z.string({
                required_error: intl.formatMessage(FORM_VALIDATION_MESSAGE.required),
            }),
            darkMode: z.boolean({
                required_error: intl.formatMessage(FORM_VALIDATION_MESSAGE.required),
            }),
            format: z.enum(FORMAT_VALUES, {
                required_error: intl.formatMessage(FORM_VALIDATION_MESSAGE.required),
            }),
            sizeUnit: z.enum(SIZE_UNIT_VALUES).optional(),
            width: z.coerce.number(),
            callToActionButtons: z.array(z.any()),
        })
        .superRefine((schema, ctx) => {
            const { type, episodeId, playlistSlug, sizeUnit, width, format, startAt } = schema;

            if (type === 'episode' && !episodeId) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: intl.formatMessage(FORM_VALIDATION_MESSAGE.required),
                    path: ['episodeId'],
                });
            }
            if (type === 'playlist' && !playlistSlug) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: intl.formatMessage(FORM_VALIDATION_MESSAGE.required),
                    path: ['playlistSlug'],
                });
            }
            if (type === 'episode' && startAt === undefined) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: intl.formatMessage(FORM_VALIDATION_MESSAGE.required),
                    path: ['startAt'],
                });
            }
            if (format !== 'auto' && width) {
                if (sizeUnit === 'pixel' && width < 250) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: intl.formatMessage({
                            defaultMessage: 'La largeur minimale est de 250px',
                        }),
                        path: ['width'],
                    });
                }
                if (sizeUnit === 'percent' && (width < 0 || width > 100)) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message:
                            width < 0
                                ? intl.formatMessage({
                                      defaultMessage: 'La largeur minimale est de 0%',
                                  })
                                : intl.formatMessage({
                                      defaultMessage: 'La largeur maximale est de 100%',
                                  }),
                        path: ['width'],
                    });
                }
            }
            return true;
        });
};

export type PlayerFormSchema = z.infer<ReturnType<typeof usePlayerFormSchema>>;

export default usePlayerFormSchema;
