import { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import useRssFeedQuery from '@queries/rss/useRssFeedQuery.hook';
import { InputTextController } from '@ui/atoms/InputText';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import useRssImportFormSchema from './useRssImportFormSchema.hook';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@/components/Button';
import { useParams } from 'react-router-dom';
import Cluster from '@ui/layout/Cluster';
import Stack from '@ui/layout/Stack';
import Img from '@ui/atoms/Img';
import Text from '@ui/atoms/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Alert from '@ui/atoms/Alert';
import { useModalToastQueue } from '@/shared/hooks/useModalToastQueue.hook';
import useCheckRssFeedMutation from '@queries/rss/useCheckRssFeedMutation.hook';
import useRssInfoQuery from '@queries/rss/useRssInfoQuery.hook';
import useAddOrUpdateRssUrlMutation from '@queries/rss/useAddOrUpdateRssUrlMutation.hook';

const IMPORT_ERROR_LOOKUP = {
    'Demo users cannot import episodes that are already in Ausha': 'freemium',
    'Rss feed is locked': 'locked',
};

interface RssImportFormProps {
    onAfterSubmit: () => void;
    isEdit: boolean;
}

const RssImportForm = ({ onAfterSubmit, isEdit }: RssImportFormProps) => {
    const { showId } = useParams<{ showId: string }>();
    const { data: rssFeed } = useRssFeedQuery({ showId, config: { enabled: isEdit } });
    const [isCheckingUrl, setIsCheckingUrl] = useState(false);
    const [hasCheckedUrl, setHasCheckedUrl] = useState(false);
    const [importCount, setImportCount] = useState(-1);
    const schema = useRssImportFormSchema();
    const { control, handleSubmit, formState, clearErrors, getValues, resetField, watch } = useForm(
        {
            mode: 'onChange',
            defaultValues: {
                url: !isEdit ? '' : rssFeed?.url || '',
            },
            resolver: yupResolver(schema),
        },
    );

    const checkRssFeed = useCheckRssFeedMutation();
    const addOrUpdateRssUrl = useAddOrUpdateRssUrlMutation();

    const ref = useRef(null);
    const [error, setError] = useState(null);
    const toast = useModalToastQueue();

    const { data: rssInfo, isFetching: rssInfoAreFetching } = useRssInfoQuery({
        payload: { url: watch('url') },
    });

    const checkUrl = async () => {
        const { url } = getValues();
        if (!url) return;
        setError(null);
        setIsCheckingUrl(true);
        checkRssFeed.mutate(
            {
                showId,
                payload: { url },
            },
            {
                onSuccess: (data) => {
                    setImportCount(data.new);
                    setHasCheckedUrl(true);
                },
                onSettled: () => {
                    setIsCheckingUrl(false);
                },
            },
        );
    };

    const handleRssImportError = (error: any) => {
        const errorMessage = error?.response?.data?.message;

        setError(errorMessage);
        if (IMPORT_ERROR_LOOKUP[errorMessage as keyof typeof IMPORT_ERROR_LOOKUP] !== 'locked')
            return;
        toast.alert(
            <FormattedMessage defaultMessage="Votre flux RSS est pour l'instant verrouillé et ne peut être importé sur Ausha. Veuillez contacter votre hébergeur actuel et demander de déverrouiller la balise '<podcast:locked>' 🔓." />,
        );
    };

    const onSubmit = (payload: any) => {
        addOrUpdateRssUrl.mutate(
            {
                isEdit,
                showId,
                payload: { url: payload.url },
            },
            {
                onSuccess: () => {
                    onAfterSubmit();
                },
                onError: handleRssImportError,
            },
        );
    };

    const getInputIcon = () => {
        if (!hasCheckedUrl) return null;
        if (checkRssFeed.isSuccess) {
            return <SuccessIcon icon={icon({ name: 'circle-check', style: 'solid' })} />;
        }
        return <ErrorIcon icon={icon({ name: 'circle-exclamation', style: 'solid' })} />;
    };

    const resetUrl = async () => {
        resetField('url');
        setHasCheckedUrl(false);
    };

    const ErrorAlert = IMPORT_ERROR_LOOKUP[error as unknown as keyof typeof IMPORT_ERROR_LOOKUP] ===
        'freemium' && (
        <Alert
            variant="warning"
            icon={<WarningIcon icon={icon({ name: 'triangle-exclamation', style: 'solid' })} />}
        >
            <Text>
                <FormattedMessage defaultMessage="Vous ne pouvez pas importer à nouveau ces contenus en version gratuite. Merci de vous abonner pour poursuivre l'import de votre émission 🚀" />
            </Text>
        </Alert>
    );

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <InputTextController
                ref={ref}
                control={control}
                name="url"
                label={<FormattedMessage defaultMessage="Lien RSS de l'émission à importer" />}
                errorMessage={
                    hasCheckedUrl && checkRssFeed.isError ? (
                        <FormattedMessage defaultMessage="Le lien RSS est incorrect. Veuillez le modifier." />
                    ) : (
                        formState.errors.url?.message
                    )
                }
                onBlur={() => clearErrors('url')}
                autoFocus
                isDisabled={isCheckingUrl}
                inputIcon={getInputIcon()}
            />
            {!hasCheckedUrl && (
                <VerifyLinkButton
                    isDisabled={isCheckingUrl || !formState.isValid}
                    onPress={checkUrl}
                    variant="secondary"
                >
                    <FormattedMessage defaultMessage="Vérifier le lien RSS" />
                </VerifyLinkButton>
            )}
            {rssInfoAreFetching ? (
                <LoadingWrapper>
                    <FontAwesomeIcon icon={icon({ name: 'spinner-third', style: 'solid' })} spin />
                </LoadingWrapper>
            ) : rssInfo && hasCheckedUrl ? (
                <RssImportInfoWrapper>
                    <Cluster $align="center" $gap="1.25rem">
                        <Img size={100} src={rssInfo?.image} bordered />
                        <Stack $gap="0.25rem">
                            <Text variant="subheading" fontWeight="--fw-semibold">
                                {rssInfo?.showTitle}
                            </Text>
                            <Text>
                                {importCount === 0 ? (
                                    <FormattedMessage defaultMessage="Aucun épisode à importer" />
                                ) : (
                                    <FormattedMessage
                                        defaultMessage="{count, plural, one {# épisode} other {# épisodes}} à importer"
                                        values={{ count: importCount }}
                                    />
                                )}
                            </Text>
                        </Stack>
                    </Cluster>
                    <Button onPress={resetUrl} variant="secondary">
                        <FormattedMessage defaultMessage="Modifier le lien RSS" />
                    </Button>
                </RssImportInfoWrapper>
            ) : null}
            {error && ErrorAlert}
            <ButtonsWrapper>
                <Button
                    type="submit"
                    isLoading={addOrUpdateRssUrl.isLoading}
                    isDisabled={!hasCheckedUrl || addOrUpdateRssUrl.isLoading}
                >
                    {isEdit ? (
                        <FormattedMessage defaultMessage="Modifier l'émission" />
                    ) : (
                        <FormattedMessage defaultMessage="Importer l'émission" />
                    )}
                </Button>
                <Button variant="ghost" onPress={onAfterSubmit}>
                    <FormattedMessage defaultMessage="Annuler" />
                </Button>
            </ButtonsWrapper>
        </Form>
    );
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    height: 100%;
`;
const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    margin-top: auto;

    & > :first-child,
    > :last-child {
        align-self: stretch;
    }

    ${(p) => p.theme.mediaQueries.tabletAndUp} {
        flex-direction: row;
        justify-content: flex-end;
        column-gap: 0.5rem;
        margin-top: 0.5rem;

        & > :first-child,
        > :last-child {
            align-self: initial;
        }

        & > :first-child {
            order: 1;
        }
    }
`;
const VerifyLinkButton = styled(Button)`
    align-self: flex-end;
`;
const RssImportInfoWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
`;
const SuccessIcon = styled(FontAwesomeIcon)`
    color: var(--success);
`;
const WarningIcon = styled(FontAwesomeIcon)`
    color: var(--warning);
`;
const ErrorIcon = styled(FontAwesomeIcon)`
    color: var(--alert);
`;

const LoadingWrapper = styled.div`
    height: 5rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--neutral);
`;

export default RssImportForm;
