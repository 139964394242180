import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addPodcastListeningLink } from '@/api';
import episodeListeningLinkKeys from '@/queries/episodeListeningLink/episodeListeningLinkKeys';
import camelcaseKeys from 'camelcase-keys';

export interface MutationFnProps {
    episodeId: string;
    key: string;
    url: string;
}

const mutationFn = async ({ episodeId, key, url }: MutationFnProps) => {
    const { data } = await addPodcastListeningLink(episodeId, {
        key,
        url,
    });

    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const useAddEpisodeListeningLinkMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: (_, { episodeId }) => {
            queryClient.invalidateQueries({
                queryKey: episodeListeningLinkKeys.listByEpisodeId(episodeId),
            });
        },
    });
};

export default useAddEpisodeListeningLinkMutation;
