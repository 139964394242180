import { z } from 'zod';

const useFacebookTemplateFormSchema = () => {
    return z.object({
        pageId: z.preprocess((val) => Number(val), z.number().int()),
        messageTpl: z.string(),
    });
};

export type FacebookTemplateFormSchema = z.infer<ReturnType<typeof useFacebookTemplateFormSchema>>;

export default useFacebookTemplateFormSchema;
