import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    SortDirection,
    useReactTable,
} from '@tanstack/react-table';
import TargetCell from './TargetCell';
import type { Mode } from '@/api/pso/types';
import RankCell from './RankCell';
import LinkCell from './LinkCell';
import RatingCell from './RatingCell';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import type { LiveSearchItems } from '@queries/pso/usePsoLiveSearchQuery.hook';
import styled, { css } from 'styled-components';
import { TooltipTrigger } from 'react-aria-components';
import Tooltip, { TooltipTriggerWrapper } from '@ui/Tooltip';

interface Show {
    id: string;
    title: string;
    thumbnail: string;
}

interface Episode {
    id: string;
    title: string;
}

interface PSOLiveSearchResult {
    type: 'SHOW' | 'EPISODE';
    show: Show;
    episode: Episode | null;
    rank: number;
    target: { show: Show; episode?: Episode; isOwn: boolean } | { show: Show; isOwn: boolean };
    author: string;
    rating: number;
    reviewCount: number;
    link: string;
    ownedByShow: boolean;
}

interface DatatableProps {
    rows: LiveSearchItems;
    mode: Mode;
}

const Datatable = ({ rows, mode }: DatatableProps) => {
    const [sorting, setSorting] = useState([{ id: 'rank', desc: false }]);

    const getTargetHeaderTooltipContent = () => {
        if (mode === 'popular') {
            return (
                <FormattedMessage defaultMessage='Nom des émissions ou des épisodes positionnés sur ce mot-clé dans la rubrique "Les plus populaires".' />
            );
        }
        if (mode === 'show') {
            return (
                <FormattedMessage defaultMessage="Nom des émissions positionnées sur ce mot-clé." />
            );
        }
        if (mode === 'episode') {
            return (
                <FormattedMessage defaultMessage="Nom des épisodes positionnés sur ce mot-clé." />
            );
        }
        return null;
    };

    const headerTooltipContentLookup = {
        rank: <FormattedMessage defaultMessage="Position du podcast sur ce mot-clé ce jour." />,
        target: getTargetHeaderTooltipContent(),
        author: <FormattedMessage defaultMessage="Nom de l'auteur ou autrice du podcast." />,
        rating: <FormattedMessage defaultMessage="Note de l'émission sur Apple Podcasts." />,
        reviewCount: (
            <FormattedMessage defaultMessage="Nombre d'avis de l'émission sur Apple Podcasts." />
        ),
    };

    const columnHelper = createColumnHelper<PSOLiveSearchResult>();
    const columns = [
        columnHelper.accessor('rank', {
            cell: (info) => <RankCell rank={info.getValue()} />,
            header: () => <FormattedMessage defaultMessage="Classement" />,
        }),
        columnHelper.accessor('target', {
            cell: (info) => <TargetCell {...info.getValue()} />,
            header: () => {
                if (mode === 'popular') {
                    return <FormattedMessage defaultMessage="Les plus populaires" />;
                }
                if (mode === 'show') {
                    return <FormattedMessage defaultMessage="Emissions" />;
                }
                if (mode === 'episode') {
                    return <FormattedMessage defaultMessage="Episodes" />;
                }
                return null;
            },
        }),
        columnHelper.accessor('author', {
            cell: (info) => <AuthorCell>{info.getValue()}</AuthorCell>,
            header: () => <FormattedMessage defaultMessage="Auteur" />,
        }),
        columnHelper.accessor('rating', {
            cell: (info) => {
                const noValue = info.getValue() === -1;
                return noValue ? (
                    <FontAwesomeIcon icon={icon({ name: 'dash', style: 'solid' })} />
                ) : (
                    <RatingCell uniqueKey={info.row.original.link} rating={info.getValue()} />
                );
            },
            header: () => <FormattedMessage defaultMessage="Note" />,
        }),
        columnHelper.accessor('reviewCount', {
            cell: (info) => {
                const noValue = info.getValue() === -1;
                return (
                    <span>
                        {noValue ? (
                            <FontAwesomeIcon icon={icon({ name: 'dash', style: 'solid' })} />
                        ) : (
                            info.getValue()
                        )}
                    </span>
                );
            },
            header: () => <FormattedMessage defaultMessage="Nbr. d'avis" />,
        }),
        columnHelper.accessor('link', {
            cell: (info) => <LinkCell to={info.getValue()} />,
            header: () => null,
            enableSorting: false,
        }),
    ];

    const table = useReactTable({
        data: rows.map((row, index) => {
            return {
                ...row,
                rank: index + 1,
                target:
                    row.type === 'EPISODE'
                        ? { show: row.show, episode: row?.episode, isOwn: row.ownedByShow }
                        : { show: row.show, isOwn: row.ownedByShow },
                author: row.author,
                rating: row.rating,
                reviewCount: row.reviewCount,
                link: row.link,
            };
        }),
        columns,
        state: {
            sorting,
        },
        enableSortingRemoval: false,
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        getCoreRowModel: getCoreRowModel(),
    });

    const sortIconRender = (isSorted: false | SortDirection) => {
        return isSorted === 'asc' ? (
            <SortIcon icon={icon({ name: 'arrow-up', style: 'solid' })} size="lg" />
        ) : (
            <SortIcon icon={icon({ name: 'arrow-down', style: 'solid' })} size="lg" />
        );
    };

    return (
        <Table>
            <Thead>
                {table.getHeaderGroups().map((headerGroup) => (
                    <Tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) =>
                            header.isPlaceholder ? null : (
                                <Th
                                    key={header.id}
                                    sortable={header.column.getCanSort()}
                                    isSorted={header.column.getIsSorted() !== false}
                                    onClick={header.column.getToggleSortingHandler()}
                                >
                                    <TooltipTrigger delay={0} closeDelay={0}>
                                        <TableHeaderTooltipTriggerWrapper>
                                            <ThContent
                                                sortable={header.column.getCanSort()}
                                                onClick={header.column.getToggleSortingHandler()}
                                            >
                                                {flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext(),
                                                )}

                                                {sortIconRender(header.column.getIsSorted())}
                                            </ThContent>
                                        </TableHeaderTooltipTriggerWrapper>
                                        {headerTooltipContentLookup[
                                            header.id as keyof typeof headerTooltipContentLookup
                                        ] && (
                                            <Tooltip placement="top">
                                                {headerTooltipContentLookup[
                                                    header.id as keyof typeof headerTooltipContentLookup
                                                ] || null}
                                            </Tooltip>
                                        )}
                                    </TooltipTrigger>
                                </Th>
                            ),
                        )}
                    </Tr>
                ))}
            </Thead>
            <Tbody>
                {table.getRowModel().rows.map((row) => (
                    <Tr key={row.id}>
                        {row.getVisibleCells().map((cell) => (
                            <Td key={cell.id}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </Td>
                        ))}
                    </Tr>
                ))}
            </Tbody>
        </Table>
    );
};

const Table = styled.table`
    border-collapse: collapse;
`;
const Thead = styled.thead``;
const Td = styled.td`
    &:not(:last-of-type) {
        padding: 1rem;
    }
`;
const Tr = styled.tr`
    & > ${Td}:not(:last-of-type) {
        border-right: 1px solid var(--neutral100);
    }
`;
const Th = styled.th<{ sortable: boolean; isSorted: boolean }>`
    background-color: var(--neutral50);
    padding: 1rem;
    text-align: left;

    ${(props) =>
        props.sortable &&
        css`
            cursor: pointer;
        `};

    svg {
        visibility: ${(props) => (props.isSorted ? 'visible' : 'hidden')};
    }

    &:hover svg {
        visibility: ${(props) => (props.sortable ? 'visible' : 'hidden')};
    }

    @media (min-width: 768px) {
        white-space: nowrap;
    }

    &:first-of-type {
        border-top-left-radius: var(--r-m);
        border-bottom-left-radius: var(--r-m);
    }
    &:last-of-type {
        border-top-right-radius: var(--r-m);
        border-bottom-right-radius: var(--r-m);
    }
`;
const ThContent = styled.div<{ sortable: boolean }>`
    display: inline-flex;
    align-items: center;
    column-gap: 0.5rem;
    transition-duration: 0.2s;
    width: 100%;
    align-self: stretch;
    font-weight: var(--fw-semibold);

    ${(props) =>
        props.sortable &&
        css`
            cursor: pointer;
        `}
`;
const TableHeaderTooltipTriggerWrapper = styled(TooltipTriggerWrapper)`
    color: var(--neutral500);
`;
const Tbody = styled.tbody`
    & > ${Tr}:not(:last-of-type) {
        border-bottom: 1px solid var(--neutral100);
    }
`;
const SortIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
    font-size: 0.75rem;
`;
const AuthorCell = styled.div`
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export default Datatable;
