import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import newsletterKeys from '@queries/newsletter/newsletterKeys';
import { fetchNewsletterSubscribers } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import { z } from 'zod';

const NewsletterSubscriberSchema = z.object({
    email: z.string(),
});

const SubscribersPaginationSchema = z.object({
    count: z.number(),
    currentPage: z.number(),
    perPage: z.number(),
    total: z.number(),
    totalPages: z.number(),
});

const NewsletterSubscribersResponseSchema = z.object({
    subscribers: z.array(NewsletterSubscriberSchema),
    pagination: SubscribersPaginationSchema,
});

type QueryProps = {
    showId: string;
    page?: number;
    perPage?: number;
};

type Key = QueryFunctionContext<ReturnType<typeof newsletterKeys.subscribersByShowId>>;

const queryFn = async ({ queryKey: [{ showId, page, perPage }] }: Key) => {
    const { data, meta } = await fetchNewsletterSubscribers(showId, page, perPage);
    const { data: camelcaseData, meta: camelcaseMeta } = camelcaseKeys(
        { data, meta },
        { deep: true },
    );

    const formattedData = {
        subscribers: camelcaseData,
        pagination: camelcaseMeta.pagination,
    };

    return NewsletterSubscribersResponseSchema.parse(formattedData);
};
const useNewsletterSubscribersQueryHook = ({ showId, page, perPage }: QueryProps) =>
    useQuery({
        queryKey: newsletterKeys.subscribersByShowId(showId, page, perPage),
        queryFn,
    });

export default useNewsletterSubscribersQueryHook;
