import { Route, Switch, useRouteMatch } from 'react-router-dom';
import posed, { PoseGroup } from 'react-pose';
import { FormattedMessage } from 'react-intl';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import MonetizePageManual from './MonetizePageManual';
import HorizontalTabSlide from '@ui/molecules/HorizontalTabSlide';
import { DeprecatedNoPermissionRoute } from '@app/routes/authorisationRoutes';
import useRouterStore from '@/utils/hooks/useRouterStore';
import CampaignAutoEditPage from './CampaignAutoEditPage';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import CampaignSponsorshipEditPage from '@app/pages/CampaignSponsorshipEditPage';
import { USER_ROLES } from '@/utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useGuard } from '@/utils/hooks/useGuard.hook';
import { PRICING } from '@/utils/pricing';

const transition = {
    duration: 250,
};

const RouteContainer = posed(DeprecatedPaper)({
    enter: { opacity: 1, y: 0, transition },
    exit: { opacity: 0, y: -20, transition },
});

const TABS_NAME = {
    SPONSORSHIP: 'sponsorship',
    AUTO: 'auto',
    MANUAL: 'manual',
};

const MonetizePage = () => {
    const match = useRouteMatch();
    const { link } = useRouterStore();
    const isFrench = useRouterStore().resolve('showId')?.languageId === 47;
    const freemiumGuard = useGuard({
        disallowedPlans: [PRICING.FREEMIUM],
    });
    const launchGuard = useGuard({
        disallowedPlans: [PRICING.FREEMIUM, PRICING.LAUNCH],
    });

    const FRENCH_TABS_TITLES = {
        [TABS_NAME.AUTO]: (
            <TitleWrapper>
                <FormattedMessage defaultMessage="Automatique" />
                {!freemiumGuard.allow && (
                    <DiamondIcon icon={icon({ name: 'gem', style: 'duotone' })} />
                )}
            </TitleWrapper>
        ),
        [TABS_NAME.MANUAL]: (
            <TitleWrapper>
                <FormattedMessage defaultMessage="Manuelle" />
                {!launchGuard.allow && (
                    <DiamondIcon icon={icon({ name: 'gem', style: 'duotone' })} />
                )}
            </TitleWrapper>
        ),
    };

    const INTER_TABS_TITLES = {
        [TABS_NAME.SPONSORSHIP]: <FormattedMessage defaultMessage="Partenariat" />,
        [TABS_NAME.MANUAL]: (
            <TitleWrapper>
                <FormattedMessage defaultMessage="Manuelle" />
                {!launchGuard.allow && (
                    <DiamondIcon icon={icon({ name: 'gem', style: 'duotone' })} />
                )}
            </TitleWrapper>
        ),
    };

    return (
        <Route
            path="/app/show/:showId/monetize/:tab"
            render={({ location, match: { params } }) => (
                <MonetizePageWrapper>
                    <HorizontalTabSlide
                        tabsTitles={isFrench ? FRENCH_TABS_TITLES : INTER_TABS_TITLES}
                        tabsLockedTooltip={{
                            [TABS_NAME.AUTO]: (
                                <FormattedMessage defaultMessage="Seuls les propriétaires de l’émission ont accès à la monétisation auto" />
                            ),
                        }}
                        tooltipArrow="top"
                        roles={[USER_ROLES.VIEWER, USER_ROLES.EDITOR, USER_ROLES.ADMIN]}
                        pageUrl={match.url}
                        mt={25}
                    />
                    <PoseGroup>
                        <RouteContainer z={-1} key={params.tab}>
                            <Switch location={location}>
                                {isFrench ? (
                                    <DeprecatedNoPermissionRoute
                                        roles={[
                                            USER_ROLES.VIEWER,
                                            USER_ROLES.EDITOR,
                                            USER_ROLES.ADMIN,
                                        ]}
                                        path={`${match.path}/auto`}
                                        component={CampaignAutoEditPage}
                                        redirect={link('menu.monetize.manual')}
                                    />
                                ) : (
                                    <DeprecatedNoPermissionRoute
                                        roles={[
                                            USER_ROLES.VIEWER,
                                            USER_ROLES.EDITOR,
                                            USER_ROLES.ADMIN,
                                        ]}
                                        path={`${match.path}/sponsorship`}
                                        component={CampaignSponsorshipEditPage}
                                        redirect={link('menu.monetize.manual')}
                                    />
                                )}
                                <Route
                                    path={`${match.path}/manual`}
                                    component={MonetizePageManual}
                                />
                            </Switch>
                        </RouteContainer>
                    </PoseGroup>
                </MonetizePageWrapper>
            )}
        />
    );
};

const MonetizePageWrapper = styled.div`
    width: 100%;
    max-width: var(--container-width);
    margin-inline: auto;
    padding-inline: var(--container-padding);

    & > :first-child {
        margin-bottom: 1.5rem;
    }

    ${({ theme }) => theme.mediaQueries.mobile} {
        padding-inline: var(--container-padding-mobile);
    }
`;
const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;
const DiamondIcon = styled(FontAwesomeIcon)`
    color: var(--primary);
`;

export default observer(MonetizePage);
