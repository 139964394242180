import { Control, useController } from 'react-hook-form';
import InputImgUploader from '.';

export interface InputImgUploaderControllerProps
    extends Omit<React.ComponentProps<typeof InputImgUploader>, 'value'> {
    control: Control<any>;
    name: string;
}

export const InputImgUploaderController = (props: InputImgUploaderControllerProps) => {
    const { control, name, onChange, ...rest } = props;
    const { field, fieldState } = useController({
        name,
        control,
    });

    return (
        <InputImgUploader
            onChange={(value) => {
                const forwardedValue = !value ? null : value;
                onChange && onChange(forwardedValue);
                field.onChange(forwardedValue);
            }}
            value={field.value}
            errorMessage={fieldState.error?.message}
            {...rest}
        />
    );
};
