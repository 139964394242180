import { useFormContext } from 'react-hook-form';
import { InputImgUploaderController } from '@ui/molecules/InputImgUploader';
import { FormattedMessage } from 'react-intl';
import Text from '@ui/atoms/Text';

const SelectedChapterImageField = ({ index }) => {
    const { control } = useFormContext();

    return (
        <InputImgUploaderController
            name={`chapters.${index}.imageUrl`}
            control={control}
            label={<FormattedMessage defaultMessage="Couverture du chapitre" />}
            description={
                <Text color="--neutral500">
                    <FormattedMessage
                        defaultMessage=".JPG, ou .PNG, jusqu’à 10Mo{break}400 x 400px min. (1400 x 1400px recommandé)"
                        values={{ break: <br /> }}
                    />
                </Text>
            }
            accept=".png, .jpg, .jpeg"
        />
    );
};

export default SelectedChapterImageField;
