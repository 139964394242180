import { z } from 'zod';
import { useIntl } from 'react-intl';
import { FORM_VALIDATION_MESSAGE } from '@/shared/services/validationMessageService';

const useCampaignFormSchema = () => {
    const intl = useIntl();

    return z.object({
        general: z.object({
            name: z
                .string({
                    required_error: intl.formatMessage(FORM_VALIDATION_MESSAGE.required),
                })
                .min(3, {
                    message: intl.formatMessage(FORM_VALIDATION_MESSAGE.minLength, { min: 3 }),
                }),
            type: z.union([z.literal('preroll'), z.literal('postroll'), z.literal('hostread')]),
        }),
        duration: z.object({
            startsAt: z.string().nullable(),
            endsAt: z.string().nullable(),
            maxListens: z.coerce
                .number()
                .int()
                .max(10000000, {
                    message: intl.formatMessage(FORM_VALIDATION_MESSAGE.max, { max: 10000000 }),
                })
                .nullable(),
        }),
        episodes: z.object({
            autoOnNewPodcasts: z.boolean(),
            episodes: z.array(z.number()),
        }),
    });
};

export type CampaignFormSchema = z.infer<ReturnType<typeof useCampaignFormSchema>>;

export default useCampaignFormSchema;
