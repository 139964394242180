import { Route, Switch } from 'react-router-dom';
import posed, { PoseGroup } from 'react-pose';
import ProfileEdit from '../organisms/ProfileEdit/ProfileEdit';
import { useAmplitudeLogEvent } from '@hooks/useAmplitude';

const transition = { duration: 250 };

const RouteContainer = posed.div({
    preEnter: { opacity: 0, x: (p) => (p.left ? 100 : -100), transition },
    enter: { opacity: 1, x: 0, transition },
    exit: { opacity: 0, x: (p) => (p.left ? -100 : 100), transition },
});

function Profile() {
    useAmplitudeLogEvent('show profile');

    return (
        <>
            <Route
                path="/app/user/profile/:page?"
                render={({ location, match }) => {
                    const left = !!match.params.page;
                    return (
                        <PoseGroup preEnterPose="preEnter" left={left}>
                            <RouteContainer key={match.params.page || 0}>
                                <Switch location={location}>
                                    <Route exact path="/app/user/profile" component={ProfileEdit} />
                                </Switch>
                            </RouteContainer>
                        </PoseGroup>
                    );
                }}
            />
        </>
    );
}

export default Profile;
