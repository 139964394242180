import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useQueryClient } from '@tanstack/react-query';
import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Tooltip, { TooltipTriggerWrapper } from '@ui/Tooltip';
import { TooltipTrigger } from 'react-aria-components';
import Button from '@ui/atoms/Button';
import InputText from '@ui/atoms/InputText';
import { usePsoCheckerContext } from '@/context/PsoCheckerContext';
import useSaveTargetKeywordMutation from '@queries/psoChecker/useSaveTargetKeywordMutation.hook';
import useDeleteTargetKeywordMutation from '@queries/psoChecker/useDeleteTargetKeywordMutation.hook';
import { useModalToastQueue } from '@/shared/hooks/useModalToastQueue.hook';
import useTargetKeywordQuery from '@queries/psoChecker/useTargetKeywordQuery.hook';
import psoCheckerKeys from '@queries/psoChecker/psoCheckerKeys';
import styled from 'styled-components';

const KEYWORD_MAX_LENGTH = 100;

const TargetKeyword = () => {
    const intl = useIntl();
    const toast = useModalToastQueue();
    const queryClient = useQueryClient();
    const { episodeId }: { episodeId: string } = useParams();
    const targetKeyword = useTargetKeywordQuery({ episodeId });
    const { setTargetKeyword } = usePsoCheckerContext();
    const [keyword, setKeyword] = useState('');
    const [displayMode, setDisplayMode] = useState(false);
    const [hasError, setHasError] = useState(false);
    const saveTargetKeyword = useSaveTargetKeywordMutation();
    const deleteTargetKeyword = useDeleteTargetKeywordMutation();

    useEffect(() => {
        if (targetKeyword?.data?.keyword) {
            setDisplayMode(true);
            setKeyword(targetKeyword?.data.keyword);
            setTargetKeyword(targetKeyword?.data.keyword);
        }
    }, [targetKeyword?.data]);

    useEffect(() => {
        setHasError(keyword.length > KEYWORD_MAX_LENGTH);
    }, [keyword]);

    const submitTargetKeyword = () => {
        if (!keyword) {
            deleteTargetKeyword.mutate(
                { episodeId },
                {
                    onSuccess: () => {
                        setKeyword('');
                        setTargetKeyword(null);
                        setDisplayMode(false);
                    },
                    onSettled: () => {
                        queryClient.removeQueries(psoCheckerKeys.targetKeyword(episodeId));
                    },
                },
            );
            return;
        }
        if (keyword.length > KEYWORD_MAX_LENGTH || keyword.length < 1) return;
        saveTargetKeyword.mutate(
            { episodeId, keyword: keyword.toLocaleLowerCase() },
            {
                onSuccess: () => {
                    setTargetKeyword(keyword);
                    setDisplayMode(true);
                },
                onError: () => {
                    toast.alert(
                        <FormattedMessage defaultMessage="L'enregistrement de votre mot-clé principal n'a pas pu être effectué, veuillez réessayer." />,
                    );
                },
            },
        );
    };

    return (
        <Wrapper id="target-keyword">
            <HeaderAndDescriptionWrapper>
                <HeaderWrapper>
                    <TitleWrapper>
                        <TitleIcon icon={icon({ name: 'bullseye-arrow', style: 'solid' })} />
                        <Title>
                            <FormattedMessage defaultMessage="Mot-clé principal" />
                        </Title>
                        <TooltipTrigger delay={0} closeDelay={0}>
                            <TooltipTriggerWrapper>
                                <HelpIcon icon={icon({ name: 'info-circle', style: 'solid' })} />
                            </TooltipTriggerWrapper>
                            <Tooltip placement="top">
                                <FormattedMessage
                                    defaultMessage="Renseignez le mot-clé principal qui décrit le mieux votre épisode. C'est ce que les auditeurs rechercheront. <link>En savoir plus sur le choix du bon mot-clé principal</link>."
                                    values={{
                                        link: (chunks: React.ReactNode) => (
                                            <TooltipLink
                                                href={intl.formatMessage({
                                                    defaultMessage:
                                                        'https://help.ausha.co/fr/articles/10057828-comment-utiliser-le-pso-checker-afin-d-optimiser-le-referencement-de-chacun-de-mes-episodes',
                                                })}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {chunks}
                                            </TooltipLink>
                                        ),
                                    }}
                                />
                            </Tooltip>
                        </TooltipTrigger>
                    </TitleWrapper>
                    {displayMode && !targetKeyword.isLoading && (
                        // @ts-ignore
                        <SmolButton
                            onPress={() => setDisplayMode(false)}
                            variant="ghost"
                            color="--primary"
                        >
                            <FormattedMessage defaultMessage="Modifier" />
                        </SmolButton>
                    )}
                </HeaderWrapper>
                <Description>
                    <FormattedMessage defaultMessage="Choisissez le mot-clé principal sur lequel vous souhaitez optimiser la visibilité de votre épisode." />
                </Description>
            </HeaderAndDescriptionWrapper>
            {targetKeyword.isLoading ? (
                <LoadingWrapper>
                    <FontAwesomeIcon icon={icon({ name: 'spinner-third', style: 'solid' })} spin />
                </LoadingWrapper>
            ) : displayMode ? (
                <Keyword>
                    <FontAwesomeIcon icon={icon({ name: 'hashtag', style: 'solid' })} />
                    <KeywordText>{targetKeyword?.data?.keyword}</KeywordText>
                </Keyword>
            ) : (
                <FormWrapper>
                    <InputText
                        onChange={(value: string) => setKeyword(value)}
                        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                            if ([','].includes(event.key)) {
                                event.preventDefault();
                                return;
                            }
                            if (event.key === 'Enter') {
                                submitTargetKeyword();
                            }
                        }}
                        value={keyword}
                        placeholder={intl.formatMessage({
                            defaultMessage: 'Renseignez votre mot-clé principal',
                        })}
                        errorMessage={
                            hasError ? (
                                <FormattedMessage
                                    defaultMessage="Le mot-clé ne peut pas dépasser {max} caractères"
                                    values={{ max: KEYWORD_MAX_LENGTH }}
                                />
                            ) : null
                        }
                    />
                    {/* @ts-ignore */}
                    <Button
                        onPress={() => {
                            submitTargetKeyword();
                        }}
                        isLoading={saveTargetKeyword.isLoading}
                        isDisabled={hasError}
                    >
                        {!targetKeyword?.data?.keyword ? (
                            <FormattedMessage defaultMessage="Ajouter" />
                        ) : (
                            <FormattedMessage defaultMessage="Enregistrer" />
                        )}
                    </Button>
                </FormWrapper>
            )}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    border-radius: var(--r-m);
    background-color: var(--white);
    gap: 1rem;
`;
const HeaderAndDescriptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;
const SmolButton = styled(Button)`
    padding-block: 0;
`;
const TitleIcon = styled(FontAwesomeIcon)`
    color: var(--primary);
`;
const Title = styled.div`
    font-size: var(--fs-body-l);
    font-weight: var(--fw-semibold);
`;
const HelpIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
`;
const Description = styled.p`
    color: var(--neutral500);
`;
const Keyword = styled.div`
    width: max-content;
    display: inline-flex;
    align-items: center;
    gap: 0.25rem;
    padding: 0.5rem;
    font-size: var(--fs-body-s);
    font-weight: var(--fw-semibold);
    color: var(--primary);
    background-color: var(--primary50);
    border-radius: var(--r-s);
`;
const KeywordText = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 20rem;
`;
const FormWrapper = styled.div`
    display: flex;
    gap: 0.5rem;
    align-items: flex-start;
`;
const LoadingWrapper = styled.div`
    padding-block: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--primary);
`;
const TooltipLink = styled.a`
    color: inherit;
`;

export default TargetKeyword;
