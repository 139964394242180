import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateApplePodcastsReviewsNotification } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import statsKeys from './statsKeys';

export interface MutationFnProps {
    showId: number;
    formData: {
        emailNotified: string;
        notificationsSubscribed: boolean;
    };
}

const mutationFn = async ({ showId, formData }: MutationFnProps) => {
    const { data } = await updateApplePodcastsReviewsNotification(showId, formData);
    return camelcaseKeys(data, { deep: true });
};

const useUpdateAppleReviewsNotificationsMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({
                queryKey: statsKeys.appleReviewsNotifications(`${variables.showId}`),
            });
        },
    });
};

export default useUpdateAppleReviewsNotificationsMutation;
