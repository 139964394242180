import useQuery from '@/utils/hooks/useQuery';
import type { Platform, Mode } from '@/api/pso/types';
import Header from './Header';
import Form from './Form';
import Results from './Results';
import Kpis from './Kpis';
import { useAmplitudeLogEvent } from '@hooks/useAmplitude';

import styled from 'styled-components';

const PsoLiveSearch = () => {
    useAmplitudeLogEvent('PSO Live Search Viewed');

    const query = useQuery();
    const keyword: string = query.get('keyword') || '';
    const platform: Platform = query.get('platform') as Platform;
    const store: string = query.get('store') || '';
    const mode: Mode = query.get('mode') as Mode;

    return (
        <LiveSearchWrapper>
            <Header
                searchParams={{
                    keyword,
                    platform,
                    store,
                    mode,
                }}
            >
                <Form />
            </Header>

            {keyword && (
                <Kpis
                    searchParams={{
                        keyword,
                        platform,
                        store,
                        mode,
                    }}
                />
            )}
            <Results
                searchParams={{
                    keyword,
                    platform,
                    store,
                    mode,
                }}
            />
        </LiveSearchWrapper>
    );
};

const LiveSearchWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

export default PsoLiveSearch;
