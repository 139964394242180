import { sendAmplitudeLogEvent } from '@/helpers';
import { useEffect } from 'react';
import { AIChaptersSuggestions } from '@/queries/ai/useAIChaptersSuggestionsQuery.hook';
import { AITitleSuggestions } from '@/queries/ai/useAITitleSuggestionsQuery.hook';
import { AIDescriptionSuggestions } from '@/queries/ai/useAIDescriptionSuggestionQuery.hook';
import { AITagsSuggestions } from '@/queries/ai/useAITagsSuggestionsQuery.hook';
import { AISettingsMapItem } from '../../contexts/NonHostingEpisodeAISettingsContext';
import { AISocialPostsSuggestions } from '@/queries/ai/useAISocialPostsSuggestionsQuery';
import { AINewsletterTitleSuggestions } from '@/queries/ai/useAINewsletterTitleSuggestionQuery.hook';
import { AINewsletterMessageSuggestions } from '@/queries/ai/useAINewsletterMessageSuggestionQuery.hook';

interface AIGenerationLoggerProps {
    suggestions:
        | AITitleSuggestions['suggestions']
        | AIDescriptionSuggestions['suggestion']
        | AITagsSuggestions['suggestions']
        | AIChaptersSuggestions['suggestions']
        | AISocialPostsSuggestions['suggestions']
        | AINewsletterTitleSuggestions
        | AINewsletterMessageSuggestions
        | undefined;
    field: AISettingsMapItem | 'chapters';
    episodeId: string;
    prompt?: string;
    isFetching: boolean;
}

export const useAIGenerationLogger = ({
    suggestions,
    field,
    episodeId,
    prompt,
    isFetching,
}: AIGenerationLoggerProps) => {
    useEffect(() => {
        if (suggestions && !isFetching) {
            const isRegenerated =
                localStorage.getItem(`episode-ai-${episodeId}-regenerated-${field}`) === 'true';
            if (!isRegenerated) {
                sendAmplitudeLogEvent('AI Generation', {
                    field,
                    suggestions,
                    prompt,
                });
            }
        }
    }, [suggestions, isFetching]);
};
