import styled from 'styled-components';
// @ts-ignore
import Square from '@public/images/clip-edit-format-square.svg?url';
// @ts-ignore
import SquareSelected from '@public/images/clip-edit-format-square-selected.svg?url';
// @ts-ignore
import Story from '@public/images/clip-edit-format-story.svg?url';
// @ts-ignore
import StorySelected from '@public/images/clip-edit-format-story-selected.svg?url';
// @ts-ignore
import Wide from '@public/images/clip-edit-format-landscape.svg?url';
// @ts-ignore
import WideSelected from '@public/images/clip-edit-format-landscape-selected.svg?url';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Text from '@/components/ui/atoms/Text';
import { FormattedMessage } from 'react-intl';

const getFormatImages = (type: 'square' | 'story' | 'wide') => {
    const formats = {
        square: [Square, SquareSelected],
        story: [Story, StorySelected],
        wide: [Wide, WideSelected],
    };

    return formats[type];
};

interface FormatProps {
    format: 'square' | 'story' | 'wide';
    isSelected: boolean;
    children: React.ReactNode;
    isDisabled: boolean;
    onSelect: (format: 'square' | 'story' | 'wide') => void;
}

const Format = ({ format, isSelected, onSelect, children, isDisabled }: FormatProps) => {
    const [image, selectedImage] = getFormatImages(format);

    const handleSelect = () => {
        if (isDisabled) return;
        onSelect(format);
    };

    return (
        <Wrapper $isDisabled={isDisabled} onClick={handleSelect} $format={format}>
            <Cover $isSelected={isSelected} src={isSelected ? selectedImage : image} />
            {children}
            {isSelected ? (
                <CheckIcon icon={icon({ name: 'check-circle', style: 'solid' })} />
            ) : (
                <Text color="--primary">
                    <FormattedMessage defaultMessage="1 crédit supplémentaire" />
                </Text>
            )}
        </Wrapper>
    );
};

const Wrapper = styled.div<{ $format: FormatProps['format']; $isDisabled: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
    width: auto;
    aspect-ratio: ${({ $format }) => {
        switch ($format) {
            case 'square':
                return '1 / 1';
            case 'story':
                return '9 / 16';
            case 'wide':
                return '16 / 9';
        }
    }};

    ${({ $isDisabled }) =>
        $isDisabled &&
        `pointer-events: none;
        opacity: 0.5;`};
`;
const Cover = styled.img<{ $isSelected: boolean }>`
    height: 280px;
    width: auto;
    object-fit: contain;
    display: block;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: var(--r-s);
    transition: border-color 0.2s;

    ${({ $isSelected }) =>
        !$isSelected &&
        `&:hover {
        border-color: var(--neutral500);
    }`};
`;
const CheckIcon = styled(FontAwesomeIcon)`
    width: 1.25rem;
    height: 1.25rem;
    color: var(--primary500);
`;

export default Format;
