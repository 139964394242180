import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import Listeners from './Listeners';
import Sources from './Sources';
import Geolocalization from './Geolocalization';
import Audience from './Audience';
import PeakTime from './PeakTime';
import ApplePodcasts from './ApplePodcasts';
import { useAmplitudeLogEvent } from '@/shared/hooks/useAmplitude';
import Layout from './Layout';

const Stats = () => {
    useAmplitudeLogEvent('Stats Section Viewed');
    const { showId } = useParams<{ showId: string }>();

    return (
        <Switch>
            <Route path="/app/show/:showId/stats/listeners">
                <Layout>
                    <Listeners />
                </Layout>
            </Route>
            <Route path="/app/show/:showId/stats/sources">
                <Layout>
                    <Sources />
                </Layout>
            </Route>

            <Route path="/app/show/:showId/stats/geoloc">
                <Layout>
                    <Geolocalization />
                </Layout>
            </Route>
            <Route path="/app/show/:showId/stats/audience">
                <Layout>
                    <Audience />
                </Layout>
            </Route>
            <Route path="/app/show/:showId/stats/peaktime">
                <Layout>
                    <PeakTime />
                </Layout>
            </Route>
            <Route path="/app/show/:showId/stats/apple-podcasts">
                <Layout>
                    <ApplePodcasts />
                </Layout>
            </Route>
            <Redirect to={`/app/show/${showId}/stats/listeners`} />
        </Switch>
    );
};

export default Stats;
