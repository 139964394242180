import Button from '@/components/Button';
import Modal from '@ui/atoms/Modal';
import Text from '@ui/atoms/Text';
import Stack from '@ui/layout/Stack';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import { AMAZON_MUSIC } from '@/shared/config/constants';
import ExternalLink from '@/components/Link/ExternalLink';
import useBroadcastMutation from '@queries/show/useBroadcastMutation.hook';
import { AxiosError } from 'axios';
import { useModalToastQueue } from '@/shared/hooks/useModalToastQueue.hook';
import styled from 'styled-components';

interface AmazonCustomConfirmModalProps {
    isOpen: boolean;
    onOpenChange: (isOpen: boolean) => void;
    logo: string;
}

const AmazonCustomConfirmModal = ({
    isOpen,
    onOpenChange,
    logo,
}: AmazonCustomConfirmModalProps) => {
    const { showId } = useParams<{ showId: string }>();
    const toast = useModalToastQueue();
    const broadcast = useBroadcastMutation();

    const platformKey = AMAZON_MUSIC;
    const platformName = 'Amazon Music';

    return (
        <ModalStyled isOpen={isOpen} onOpenChange={onOpenChange} size="small">
            <LogoWrapper $color={`--${platformKey}`}>
                <Logo src={logo} />
            </LogoWrapper>
            <Stack $gap="0.25rem">
                <Text variant="headingS" fontWeight="--fw-bold" textAlign="center">
                    <FormattedMessage
                        defaultMessage="Souhaitez-vous diffuser sur {broadcastName} ?"
                        values={{ broadcastName: platformName }}
                    />
                </Text>
                <Stack $gap="0.25rem">
                    <Text color="--neutral500" textAlign="center">
                        <FormattedMessage defaultMessage="Vous apparaîtrez sur la plateforme d’ici quelques heures." />
                    </Text>
                    <AmazonTermsAndConditionsLink
                        href="https://podcasters.amazon.com/terms-of-service"
                        target="_blank"
                        $color={`--${platformKey}`}
                    >
                        <FormattedMessage defaultMessage="Consulter les conditions générales d'utilisation d'Amazon" />
                    </AmazonTermsAndConditionsLink>
                </Stack>
            </Stack>
            <Actions>
                <BroadcastButton
                    onPress={() => {
                        broadcast.mutate(
                            {
                                showId,
                                platform: AMAZON_MUSIC,
                            },
                            {
                                onError: (error) => {
                                    const typedError = error as AxiosError;
                                    if (typedError.response?.status !== 400) {
                                        toast.alert();
                                        return;
                                    }

                                    const messages: Record<string, React.ReactNode> = {
                                        'Podcast does not fit requirements': (
                                            <FormattedMessage defaultMessage="Vous devez avoir un d'épisode publié" />
                                        ),
                                        'RSS feed already submitted to Amazon': (
                                            <FormattedMessage defaultMessage="Votre émission a déjà été soumis sur Amazon Music" />
                                        ),
                                    };
                                    const errorMessage: string = typedError.response.data.message;
                                    toast.alert(
                                        <FormattedMessage
                                            defaultMessage="{message}"
                                            values={{ message: messages[errorMessage] }}
                                        />,
                                    );
                                },
                                onSuccess: () => {
                                    onOpenChange(false);
                                },
                            },
                        );
                    }}
                    isLoading={broadcast.isLoading}
                    $color={`--${platformKey}`}
                >
                    <FormattedMessage
                        defaultMessage="Diffuser sur {broadcastName}"
                        values={{ broadcastName: platformName }}
                    />
                </BroadcastButton>
                <Button variant="link-secondary" onPress={() => onOpenChange(false)}>
                    <FormattedMessage defaultMessage="Annuler" />
                </Button>
            </Actions>
        </ModalStyled>
    );
};

const Logo = styled.img`
    width: 1rem;
    height: 1rem;
`;
const LogoWrapper = styled.div<{ $color: string }>`
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(${(props) => props.$color}50);
    color: var(${(props) => props.$color});
    border-radius: var(--r-s);
`;
const ModalStyled = styled(Modal)`
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1.5rem;
`;
const Actions = styled.div`
    align-self: stretch;
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 1rem;

    & > :first-child {
        align-self: stretch;
    }
`;
const BroadcastButton = styled(Button)<{ $color: string }>`
    background-color: var(${(props) => props.$color}50);
    color: var(${(props) => props.$color});
    box-shadow: none;

    &:hover {
        background-color: var(${(props) => props.$color}100);
    }
`;
const AmazonTermsAndConditionsLink = styled(ExternalLink)<{ $color: string }>`
    color: var(${(props) => props.$color});
    align-self: center;

    &:hover {
        color: var(${(props) => props.$color});
    }
`;

export default AmazonCustomConfirmModal;
