import ContentLoader from 'react-content-loader';
import createFallback from './createFallback';

function DevicesFallback({ theme, count = 5, ...props }) {
    return (
        <ContentLoader
            width="100%"
            height="100%"
            {...props}
            style={{ width: '100%', height: '90px' }}
        >
            {new Array(count).fill(0).map((_, x) => (
                <circle key={x} cx={`${((x + 1) * 100) / (count + 1)}%`} cy={50} r={30} />
            ))}
        </ContentLoader>
    );
}

export default createFallback(DevicesFallback);
