import { useMutation, useQueryClient } from '@tanstack/react-query';
import updateShowMutation from '@/queries/show/updateShowMutation.hook';
import uploadShowLogoMutation from '@/queries/show/uploadShowLogoMutation';
import deleteShowLogoMutation from '@/queries/show/deleteShowLogoMutation';
import showKeys from '@/queries/show/showKeys';
import { AushaProFormSchema } from './Form/useFormSchema.hook';

interface UpdateAushaProShowSettingsMutationProps {
    showId: string;
    data: Omit<AushaProFormSchema, 'hasCustomLogo'>;
}

const updateAushaProShowSettingsMutation = () => {
    const queryClient = useQueryClient();
    const updateShow = updateShowMutation();
    const uploadShowLogo = uploadShowLogoMutation();
    const deleteShowLogo = deleteShowLogoMutation();

    return useMutation({
        mutationFn: ({ showId, data }: UpdateAushaProShowSettingsMutationProps) => {
            const { darkLogo, lightLogo, favicon, ...settings } = data;
            const promises = [];

            if (darkLogo && darkLogo instanceof File) {
                const darkLogoPromise = uploadShowLogo.mutateAsync({
                    showId,
                    logo: { file: darkLogo, format: 'logo_dark' },
                });
                promises.push(darkLogoPromise);
            }

            if (lightLogo && lightLogo instanceof File) {
                const lightLogoPromise = uploadShowLogo.mutateAsync({
                    showId,
                    logo: { file: lightLogo, format: 'logo_light' },
                });
                promises.push(lightLogoPromise);
            }

            if (favicon && favicon instanceof File) {
                const faviconPromise = uploadShowLogo.mutateAsync({
                    showId,
                    logo: { file: favicon, format: 'favicon' },
                });
                promises.push(faviconPromise);
            }

            if (darkLogo === null) {
                const darkLogoPromise = deleteShowLogo.mutateAsync({
                    showId,
                    format: 'logo_dark',
                });
                promises.push(darkLogoPromise);
            }

            if (lightLogo === null) {
                const lightLogoPromise = deleteShowLogo.mutateAsync({
                    showId,
                    format: 'logo_light',
                });
                promises.push(lightLogoPromise);
            }

            if (favicon === null) {
                const faviconPromise = deleteShowLogo.mutateAsync({
                    showId,
                    format: 'favicon',
                });
                promises.push(faviconPromise);
            }

            if (Object.keys(settings).length > 0) {
                const playerSettingsPromise = updateShow.mutateAsync({
                    showId,
                    show: settings,
                });
                promises.push(playerSettingsPromise);
            }

            // Waiting for all promises to be fulfilled or rejected
            return Promise.allSettled(promises);
        },
        onSuccess: (_, { showId }) => {
            queryClient.invalidateQueries({ queryKey: showKeys.detailById(showId) });
        },
    });
};

export default updateAushaProShowSettingsMutation;
