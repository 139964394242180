import { USER_ROLES } from '@/shared/config/constants';
import useSubscriptionQuery from '@/queries/subscription/useSubscriptionQuery.hook';
import { useEpisodeBeingEdited } from '@/context/EpisodeFormContext';
import styled from 'styled-components';
import useHasAccessToTranscription from '@/shared/hooks/useHasAccessToTranscription.hook';
import { useParams } from 'react-router';
import QuotaAlert from './QuotaAlert';
import EpisodeResume from './EpisodeResume';
import AudioFile from './AudioFile';
import Transcription from './Transcription';

const InformationTab = () => {
    const { showId } = useParams<{ showId: string }>();
    const { data: subscription } = useSubscriptionQuery({ staleTime: 0 });
    const episode = useEpisodeBeingEdited();
    const hasAccessToTranscription = useHasAccessToTranscription({ showId });

    return (
        <StickyContainer>
            {subscription?.isFreemium && episode?.show?.userRole === USER_ROLES.OWNER && (
                <QuotaAlert />
            )}
            <Container>
                <EpisodeResume />
                <Divider />
                <AudioFile />
            </Container>
            {hasAccessToTranscription && <Transcription />}
        </StickyContainer>
    );
};

const StickyContainer = styled.div`
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
`;
const Container = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
`;

const Divider = styled.div`
    height: 1px;
    align-self: stretch;
    background-color: var(--neutral100);
    border-radius: var(--r-full);
`;

export default InformationTab;
