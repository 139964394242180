import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Text from '@ui/atoms/Text';
import useEpisodeQuery from '@queries/episode/useEpisodeQuery.hook';
import useShowSocialPlatformQuery from '@queries/socialNetworkPlatform/useShowSocialPlatformQuery.hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FACEBOOK } from '@/shared/config/constants';
import CreateOrUpdatePostForm from './CreateOrUpdatePostForm';
import EpisodeSkeleton from './EpisodeSkeleton';
import PlatformSkeleton from './PlatformSkeleton';
import FormSectionSkeleton from './FormSectionSkeleton';
import styled from 'styled-components';
import { SOCIAL_NETWORK } from '@/shared/config/constants';

const platformLookup = {
    [SOCIAL_NETWORK.FACEBOOK]: {
        icon: (
            <FontAwesomeIcon
                icon={icon({ name: 'facebook', style: 'brands' })}
                color="inherit"
                size="xl"
            />
        ),
        color: '--facebook',
    },
    [SOCIAL_NETWORK.INSTAGRAM]: {
        icon: (
            <FontAwesomeIcon
                icon={icon({ name: 'instagram', style: 'brands' })}
                color="inherit"
                size="xl"
            />
        ),
        color: '--instagram',
    },
    [SOCIAL_NETWORK.TWITTER]: {
        icon: (
            <FontAwesomeIcon
                icon={icon({ name: 'x-twitter', style: 'brands' })}
                color="inherit"
                size="xl"
            />
        ),
        color: '--twitter',
    },
    [SOCIAL_NETWORK.LINKEDIN]: {
        icon: (
            <FontAwesomeIcon
                icon={icon({ name: 'linkedin', style: 'brands' })}
                color="inherit"
                size="xl"
            />
        ),
        color: '--linkedin',
    },
};

const CreateOrUpdatePost = ({ post, platformId, episodeId, close }) => {
    const { showId } = useParams();
    const episode = useEpisodeQuery({ episodeId });
    const platform = useShowSocialPlatformQuery({ showId, platformId });

    const platformMessageLookup = {
        instagram: <FormattedMessage defaultMessage="Compte Instagram" />,
        facebook: <FormattedMessage defaultMessage="Page Facebook" />,
        twitter: <FormattedMessage defaultMessage="Compte X (Twitter)" />,
        linkedin: <FormattedMessage defaultMessage="Page LinkedIn" />,
    };
    const accountName =
        platformId === FACEBOOK ? platform?.data?.account?.name : platform?.data?.account?.username;

    return (
        <CreateOrUpdatePostWrapper>
            {episode.isLoading || episode.isError ? (
                <EpisodeSkeleton />
            ) : (
                <EpisodeWrapper>
                    {episode.data.imageUrl ? (
                        <EpisodeCover src={episode.data.imageUrl} />
                    ) : (
                        <PlaceholderImage>
                            <FontAwesomeIcon
                                icon={icon({
                                    name: 'pen-ruler',
                                    style: 'solid',
                                })}
                            />
                        </PlaceholderImage>
                    )}
                    <EpisodeTitle>{episode.data.name}</EpisodeTitle>
                </EpisodeWrapper>
            )}

            {platform.isLoading || platform.isError ? (
                <PlatformSkeleton />
            ) : (
                <PlatformWrapper>
                    <PlatformTitle>{platformMessageLookup[platform.platformId]}</PlatformTitle>
                    <PlatformInnerWrapper>
                        <PlatformAccountImageWrapper>
                            {platform?.data?.account?.profilePictureUrl ? (
                                <PlatformAccountImage
                                    src={platform.data.account.profilePictureUrl}
                                />
                            ) : (
                                <PlaceholderPlatformAccountImage>
                                    <FontAwesomeIcon
                                        icon={icon({
                                            name: 'user',
                                            style: 'solid',
                                        })}
                                    />
                                </PlaceholderPlatformAccountImage>
                            )}
                            <PlatformIconWrapper color={platformLookup[post.provider].color}>
                                {platformLookup[post.provider].icon}
                            </PlatformIconWrapper>
                        </PlatformAccountImageWrapper>
                        <PlatformAccountNameWrapper>
                            <PlatformAccountName>{accountName}</PlatformAccountName>
                            <PlatformAccountUnique>@{accountName}</PlatformAccountUnique>
                        </PlatformAccountNameWrapper>
                    </PlatformInnerWrapper>
                </PlatformWrapper>
            )}
            {episode?.data ? (
                <CreateOrUpdatePostForm
                    post={post}
                    platformId={platformId}
                    episode={episode.data}
                    close={close}
                />
            ) : (
                <FormSectionSkeleton />
            )}
        </CreateOrUpdatePostWrapper>
    );
};

const CreateOrUpdatePostWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
`;
const EpisodeWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;
const EpisodeCover = styled.img`
    width: 1.5rem;
    height: 1.5rem;
    border-radius: var(--r-s);
`;
const PlaceholderImage = styled.div`
    width: 1.5rem;
    height: 1.5rem;
    border-radius: var(--r-s);
    background-color: var(--neutral50);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem;
    color: var(--neutral300);
`;
const EpisodeTitle = styled(Text)`
    font-size: var(--fs-body-m);
    color: var(--neutral500);
`;
const PlatformWrapper = styled.div``;
const PlatformTitle = styled(Text)``;
const PlatformInnerWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.75rem;
`;
const PlatformAccountImageWrapper = styled.div`
    position: relative;
`;
const PlatformAccountImage = styled.img`
    width: 2.5rem;
    height: 2.5rem;
    border-radius: var(--r-s);
`;
const PlaceholderPlatformAccountImage = styled.div`
    width: 2.5rem;
    height: 2.5rem;
    border-radius: var(--r-s);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    background-color: var(--neutral50);
    color: var(--neutral500);
`;
const PlatformIconWrapper = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 1rem;
    height: 1rem;
    border-top-right-radius: var(--r-s);
    background-color: var(--white);
    display: flex;
    align-items: flex-end;
    padding: 1px;
    font-size: 0.5rem;

    ${({ color }) => color && `color: var(${color});`}
`;
const PlatformAccountNameWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
const PlatformAccountName = styled(Text)`
    font-weight: var(--fw-semibold);
`;
const PlatformAccountUnique = styled(Text)`
    color: var(--neutral500);
`;

CreateOrUpdatePost.propTypes = {
    post: PropTypes.object,
    platformId: PropTypes.string,
    episodeId: PropTypes.string,
    close: PropTypes.func,
};

export default CreateOrUpdatePost;
