import { useMutation, useQueryClient } from '@tanstack/react-query';
import useUpdatePlayerSettingsMutation from '@/queries/playerSettings/useUpdatePlayerSettingsMutation.hook';
import useUpdateCallToActionButtonsPositionMutation from '@/queries/callToAction/useUpdateCallToActionButtonsPositionMutation.hook';
import playerSettingsKeys from '@/queries/playerSettings/playerSettingsKeys';
import { PlayerFormSchema } from '../../molecules/PlayerForm/usePlayerFormSchema.hook';
import { LINKS_NAMES } from '@/components/CallToAction';

const useSavePlayerSettingsFormMutation = () => {
    const queryClient = useQueryClient();
    const updatePlayerSettings = useUpdatePlayerSettingsMutation();
    const updateCallToActionButtonsPosition = useUpdateCallToActionButtonsPositionMutation();

    return useMutation({
        mutationFn: ({
            showId,
            settings: data,
        }: {
            showId: string;
            settings: Omit<PlayerFormSchema, 'type'>;
        }) => {
            const { callToActionButtons, ...settings } = data;
            const promises = [];

            if (settings && Object.keys(settings).length > 0) {
                const playerSettingsPromise = updatePlayerSettings.mutateAsync({
                    showId,
                    settings,
                });
                promises.push(playerSettingsPromise);
            }

            if (callToActionButtons && callToActionButtons.length > 0) {
                const updateCallToActionButtonsPromise =
                    updateCallToActionButtonsPosition.mutateAsync({
                        showId,
                        location: LINKS_NAMES.PLAYER,
                        callToActionButtons,
                    });
                promises.push(updateCallToActionButtonsPromise);
            }

            // Waiting for all promises to be fulfilled or rejected
            return Promise.allSettled(promises);
        },
        onSuccess: (_, { showId }) => {
            queryClient.invalidateQueries({ queryKey: playerSettingsKeys.detailByShowId(showId) });
        },
    });
};

export default useSavePlayerSettingsFormMutation;
