import { FormattedMessage } from 'react-intl';
import Link from '@ui/atoms/Link';
import DeprecatedButton from '@ui/atoms/DeprecatedButton';
import DeprecatedIcon from '@ui/atoms/DeprecatedIcon';
import open from '@public/icons/external-link.svg';
import copy from '@public/icons/duplicate.svg';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import { useCopy } from '@/shared/hooks/useCopy';
import Text from '@ui/atoms/Text';
import Button from '@ui/atoms/Button';

export const OpenLinkButton = ({ link, isActive = true }) => {
    if (!link || !isActive) return null;
    return (
        <DeprecatedButton as="a" href={link} target="_blank" accent="roundedSmall">
            <DeprecatedIcon as={open} />
            <DeprecatedText weight="semibold">
                <FormattedMessage defaultMessage="Ouvrir" />
            </DeprecatedText>
        </DeprecatedButton>
    );
};

export const CopyLinkButton = ({ link }) => {
    const [copied, onCopy] = useCopy();
    if (!link) return null;
    return (
        <Button
            isRound
            variant="secondary"
            size="small"
            onClick={() => onCopy(link)}
            startIcon={<DeprecatedIcon as={copy} />}
        >
            <Text fontWeight="--fw-semibold">
                {copied ? (
                    <FormattedMessage defaultMessage={'Lien copié'} />
                ) : (
                    <FormattedMessage defaultMessage="Copier le lien" />
                )}
            </Text>
        </Button>
    );
};

export const TabLinkButton = ({ to, message, isActive = true }) => {
    if (!isActive) return null;
    return (
        <DeprecatedButton accent="roundedSmall" as={Link} px={15} to={to}>
            <DeprecatedIcon as={open} />
            <DeprecatedText weight="semibold">{message}</DeprecatedText>
        </DeprecatedButton>
    );
};
