import IllustrationClip from '@public/images/illustration-clip.svg';
import IllustrationSocialNetworkStats from '@public/images/illustration-social-network-stats.svg';
import IllustrationBroadcast from '@public/images/illustration-broadcast.svg';
import Stack from '@ui/layout/Stack';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import IconClip from '@ui/icons/IconClip';
import IconFire from '@ui/icons/IconFire';
import { useResponsive } from '@/shared/hooks/useResponsive';
import IconRocket from '@ui/icons/IconRocket';
import RouterLink from '@/components/Link/RouterLink';
import { useParams } from 'react-router';

const PublicNoBroadcastContent = () => {
    const { showId } = useParams();
    return (
        <Card>
            <IllustrationBroadcast />
            <RouterLink
                to={`/app/show/${showId}/settings/broadcast`}
                variant="button-primary"
                startIcon={<IconRocket />}
            >
                <FormattedMessage defaultMessage="Diffuser sur les plateformes" />
            </RouterLink>
        </Card>
    );
};

const PublicBroadcastContent = () => {
    const { isMobile } = useResponsive();
    const { showId } = useParams();

    return (
        <>
            {!isMobile && (
                <Card>
                    <IllustrationClip />
                    <RouterLink
                        to={`/app/show/${showId}/communication/clip`}
                        variant="button-primary"
                        startIcon={<IconClip />}
                    >
                        <FormattedMessage defaultMessage="Créer un clip vidéo" />
                    </RouterLink>
                </Card>
            )}
            <Card>
                <IllustrationSocialNetworkStats />
                <RouterLink
                    to={`/app/show/${showId}/communication/social-media`}
                    variant="button-primary"
                    startIcon={<IconFire />}
                >
                    <FormattedMessage defaultMessage="Poster sur les réseaux sociaux" />
                </RouterLink>
            </Card>
        </>
    );
};

const AdditionalActions = ({ isBroadcast }) => {
    return (
        <ActionsWrapper>
            {isBroadcast ? <PublicBroadcastContent /> : <PublicNoBroadcastContent />}
        </ActionsWrapper>
    );
};

const ActionsWrapper = styled.div`
    display: flex;
    column-gap: 0.5rem;
    justify-content: center;
    width: 100%;
    margin: auto;
`;
const Card = styled(Stack)`
    display: flex;
    align-items: center;
    justify-content: center;
    row-gap: 1.5rem;
    flex-basis: 100%;
    flex-grow: 1;
    align-self: stretch;
    max-width: 296px;
    ${(p) => p.theme.mediaQueries.tabletAndUp} {
        padding: 1.5rem 1rem 1rem 1rem;
        border: 1px solid var(--neutral200);
        border-radius: var(--r-xs);
    }
`;

export default AdditionalActions;
