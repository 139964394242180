import { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useOverlayTriggerState } from 'react-stately';
import { FormattedMessage } from 'react-intl';
import Modal from '@ui/molecules/Modal';
import LinkForm from '@ui/atoms/InputRichText/LinkForm';
import Text from '@ui/atoms/Text';
import Placeholder from '@tiptap/extension-placeholder';
import { CharacterCount } from '@tiptap/extension-character-count';
import MaxLength from '@ui/molecules/MaxLength';
import PropTypes from 'prop-types';
import { LinkExtension } from './LinkExtension';
import Label from '../Label';
import OptionButton from './OptionButton';
import EmojiPickerButton from './EmojiPickerButton';
import LoadingExtension from '@/components/SocialMediaHeader/AddPostModal/CreateOrUpdatePost/CreateOrUpdatePostForm/SocialMediaRichTextEditor/LoadingExtension';
import { useLoadContent } from './useLoadContent.hook';
import Tooltip from '@/components/ui/Tooltip';
import { TooltipTrigger } from 'react-aria-components';

const InputRichText = ({
    value,
    label,
    description,
    placeholder,
    tooltip,
    charactersLimit,
    isDisabled,
    options,
    onChange,
    errorMessage,
    onBlur,
    isInvalid,
    customButton,
    'data-testid': dataTestId,
    isLoading,
    onLoadingEnd,
    loadingContent,
    htmlLinksDisabled,
    htmlLinksDisabledReason,
    onChangePlainText,
}) => {
    const linkModalState = useOverlayTriggerState({});
    const hasOptions = Object.values(options).some((option) => option === true);
    let extensions = [
        StarterKit.configure({
            bold: options.bold,
            italic: options.italic,
            bulletList: options.bulletList,
            orderedList: options.orderedList,
            blockquote: false,
            codeBlock: false,
            heading: false,
            horizontalRule: false,
            code: false,
            strike: false,
        }),
        LoadingExtension,
    ];

    if (options.link) {
        extensions = [...extensions, LinkExtension];
    }

    if (placeholder) {
        extensions = [
            ...extensions,
            Placeholder.configure({
                placeholder,
            }),
        ];
    }

    if (charactersLimit) {
        extensions = [
            ...extensions,
            CharacterCount.configure({
                limit: null,
            }),
        ];
    }

    const content = value?.replace(/<p><br><\/p>/g, '<p></p>');

    const editor = useEditor({
        extensions,
        content,
        onUpdate: ({ editor }) => {
            if (!onChange || isLoading) return;
            onChange(editor.getHTML());
            onChangePlainText?.(editor.getText());
        },
        parseOptions: {
            preserveWhitespace: true, // Preserve whitespace, but normalize newlines to spaces
        },
    });

    useEffect(() => {
        if (!editor) return;
        // When isLoading, editor should be read-only
        editor.setEditable(!isLoading);
    }, [isLoading]);

    useLoadContent({ editor, isLoading, onLoadingEnd, loadingContent });

    if (!editor) {
        return null;
    }

    if (isDisabled) {
        editor.setEditable(false);
    }

    const setLink = (data) => {
        // cancelled
        if (data === null) {
            return;
        }
        // empty
        if (data.link === '') {
            editor.chain().focus().extendMarkRange('link').unsetLink().run();
            return;
        }
        // update link
        editor.chain().focus().extendMarkRange('link').setLink({ href: data.link }).run();
        linkModalState.close();
    };

    return (
        <Wrapper>
            <LabelWrapper>
                <Label isRequired={false} description={description} tooltip={tooltip}>
                    {label}
                </Label>
                {charactersLimit && (
                    <MaxLength
                        currentValue={editor.isEmpty ? 0 : editor.getHTML().length}
                        max={charactersLimit}
                    />
                )}
            </LabelWrapper>
            <InputWrapper $isInvalid={isInvalid} $isDisabled={isDisabled}>
                <InputHeader>
                    {hasOptions && (
                        <Options>
                            {options.bold && (
                                <OptionButton
                                    variant="ghost"
                                    icon={
                                        <OptionIcon
                                            icon={icon({
                                                name: 'bold',
                                                style: 'solid',
                                            })}
                                        />
                                    }
                                    onPress={() => editor.chain().focus().toggleBold().run()}
                                    $isActive={editor.isActive('bold')}
                                    isDisabled={isDisabled}
                                    data-testid="rich-text-input-bold-button"
                                />
                            )}
                            {options.italic && (
                                <OptionButton
                                    variant="ghost"
                                    icon={
                                        <OptionIcon
                                            icon={icon({
                                                name: 'italic',
                                                style: 'solid',
                                            })}
                                        />
                                    }
                                    onPress={() => editor.chain().focus().toggleItalic().run()}
                                    $isActive={editor.isActive('italic')}
                                    isDisabled={isDisabled}
                                />
                            )}
                            {options.link && (
                                <>
                                    <TooltipTrigger
                                        delay={0}
                                        closeDelay={0}
                                        isDisabled={!htmlLinksDisabled || !htmlLinksDisabledReason}
                                    >
                                        <OptionButton
                                            variant="ghost"
                                            icon={
                                                <OptionIcon
                                                    icon={icon({
                                                        name: 'link-simple',
                                                        style: 'solid',
                                                    })}
                                                />
                                            }
                                            onPress={linkModalState.open}
                                            $isActive={editor.isActive('link')}
                                            isDisabled={isDisabled || htmlLinksDisabled}
                                        />
                                        <Tooltip placement="top">{htmlLinksDisabledReason}</Tooltip>
                                    </TooltipTrigger>
                                    <OptionButton
                                        variant="ghost"
                                        icon={
                                            <OptionIcon
                                                icon={icon({
                                                    name: 'link-simple-slash',
                                                    style: 'solid',
                                                })}
                                            />
                                        }
                                        onPress={() => editor.chain().focus().unsetLink().run()}
                                        $isActive={!editor.isActive('link')}
                                        isDisabled={isDisabled || !editor.isActive('link')}
                                    />
                                </>
                            )}
                            {options.bulletList && (
                                <OptionButton
                                    variant="ghost"
                                    icon={
                                        <OptionIcon
                                            icon={icon({
                                                name: 'list-ul',
                                                style: 'solid',
                                            })}
                                        />
                                    }
                                    onPress={() => editor.chain().focus().toggleBulletList().run()}
                                    $isActive={editor.isActive('bulletList')}
                                    isDisabled={isDisabled}
                                />
                            )}
                            {options.orderedList && (
                                <OptionButton
                                    variant="ghost"
                                    icon={
                                        <OptionIcon
                                            icon={icon({
                                                name: 'list-ol',
                                                style: 'solid',
                                            })}
                                        />
                                    }
                                    onPress={() => editor.chain().focus().toggleOrderedList().run()}
                                    $isActive={editor.isActive('orderedList')}
                                    isDisabled={isDisabled}
                                />
                            )}
                            {options.emojis && (
                                <EmojiPickerButton editor={editor} isDisabled={isDisabled} />
                            )}
                        </Options>
                    )}
                    {customButton}
                </InputHeader>
                <Divider />
                <StyledEditorContent
                    editor={editor}
                    onBlur={(event) => onBlur(event)}
                    role="textbox"
                    data-testid={dataTestId}
                />
            </InputWrapper>
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            {linkModalState.isOpen && (
                <Modal
                    title={<FormattedMessage defaultMessage="Lien" />}
                    onClose={linkModalState.close}
                    isOpen={linkModalState.isOpen}
                >
                    <LinkForm
                        onSubmit={setLink}
                        onCancel={linkModalState.close}
                        link={editor?.getAttributes('link')?.href}
                    />
                </Modal>
            )}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
`;
const InputWrapper = styled.div`
    display: flex;
    padding: 0.75rem;
    flex-direction: column;
    row-gap: 0.75rem;
    border-radius: var(--r-s);
    border: 1px solid var(--neutral200);
    background-color: var(--white);

    &:focus-within {
        border: 1px solid var(--primary);
        box-shadow: 0px 0px 0px 4px rgba(117, 28, 191, 0.1);
    }

    ${({ $isInvalid }) =>
        $isInvalid &&
        css`
            border-color: var(--alert);

            &:focus-within {
                box-shadow: 0px 0px 0px 4px rgba(255, 38, 74, 0.1);
                border-color: var(--alert);
            }
        `}

    ${({ $isDisabled }) =>
        $isDisabled &&
        css`
            opacity: 0.5;
            pointer-events: none;
        `}
`;
const InputHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 0.5rem;
`;
const LabelWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const Options = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
`;
const OptionIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const StyledEditorContent = styled(EditorContent)`
    .ProseMirror {
        min-height: 7.5rem;
        max-height: 19.75rem;
        overflow-y: auto;
        font-size: var(--fs-body);
        line-height: var(--lh-body);
    }

    .ProseMirror:hover {
        border-color: var(--neutral500);
    }

    .ProseMirror:focus,
    .ProseMirror:focus-within,
    .ProseMirror-focused {
        border-color: var(--primary);
    }

    .ProseMirror p.is-editor-empty:first-child::before {
        color: var(--neutral500);
        content: attr(data-placeholder);
        float: left;
        height: 0;
        pointer-events: none;
    }
`;
const ErrorMessage = styled(Text)`
    color: var(--alert);
    font-size: var(--fs-body-s);
    font-weight: var(--fw-semibold);
`;
const Divider = styled.div`
    height: 1px;
    background-color: var(--neutral200);
    align-self: stretch;
    border-radius: var(--r-full);
`;

InputRichText.propTypes = {
    value: PropTypes.string,
    label: PropTypes.node,
    description: PropTypes.string,
    placeholder: PropTypes.string,
    tooltip: PropTypes.element,
    charactersLimit: PropTypes.number,
    isDisabled: PropTypes.bool,
    isInvalid: PropTypes.bool,
    options: PropTypes.shape({
        bold: PropTypes.bool,
        italic: PropTypes.bool,
        link: PropTypes.bool,
        bulletList: PropTypes.bool,
        orderedList: PropTypes.bool,
        emojis: PropTypes.bool,
    }),
    onChange: PropTypes.func,
    hasError: PropTypes.bool,
    errorMessage: PropTypes.string,
    onBlur: PropTypes.func,
    customButton: PropTypes.node,
    'data-testid': PropTypes.string,
    isLoading: PropTypes.bool,
    onChangePlainText: PropTypes.func,
    onLoadingEnd: PropTypes.func,
    loadingContent: PropTypes.string,
    htmlLinksDisabled: PropTypes.bool,
    htmlLinksDisabledReason: PropTypes.node,
};

InputRichText.defaultProps = {
    value: undefined,
    label: undefined,
    description: undefined,
    placeholder: undefined,
    tooltip: undefined,
    charactersLimit: undefined,
    isDisabled: false,
    isInvalid: false,
    options: {
        bold: true,
        italic: true,
        link: true,
        bulletList: true,
        orderedList: true,
        emojis: true,
    },
    isLoading: false,
    onChange: () => {},
    onBlur: () => {},
    onLoadingEnd: () => {},
    hasError: false,
    loadingContent: '',
    htmlLinksDisabled: false,
    htmlLinksDisabledReason: null,
};

export default InputRichText;
