import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateShowListeningLinks } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import showKeys from './showKeys';
import { ListeningPlatform } from '@/utils/constants';

export interface MutationFnProps {
    showId: string;
    platform: ListeningPlatform;
}

const mutationFn = async ({ showId, platform }: MutationFnProps) => {
    const { data } = await updateShowListeningLinks(showId, { [platform]: null });
    return camelcaseKeys(data, { deep: true });
};

const useBroadcastMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({
                queryKey: showKeys.detailById(variables.showId),
            });
        },
    });
};

export default useBroadcastMutation;
