import Alert from '@ui/atoms/Alert';
import Text from '@ui/atoms/Text';
import { FormattedMessage } from 'react-intl';
import Link from '@ui/atoms/Link';
import useInternalRouteMapping from '@/shared/hooks/useInternalRouteMapping.hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';
import { Button } from 'react-aria-components';
import Cluster from '@ui/layout/Cluster';
import Stack from '@ui/layout/Stack';
import { useEpisodeBeingEdited } from '@/context/EpisodeFormContext';
import { useEffect, useState } from 'react';

const EpisodeEditFormContentTagsAlert = () => {
    const { link } = useInternalRouteMapping();
    const episode = useEpisodeBeingEdited();
    const hasTags = (episode?.tags && episode.tags.length > 0) || false;
    const isClosed =
        (episode?.show?.id &&
            localStorage.getItem(`episode-form-${episode.show.id}-tags-alert`) === 'false') ||
        false;
    const [showAlert, setShowAlert] = useState(!hasTags && !isClosed);

    useEffect(() => {
        if (showAlert || !episode?.show?.id) return;
        localStorage.setItem(`episode-form-${episode.show.id}-tags-alert`, 'false');
    }, [showAlert]);

    if (!showAlert) return null;

    return (
        <Alert variant="robot">
            <Cluster $gap="0.75rem" $wrap="nowrap" $justify="space-between">
                <Stack>
                    <Text fontWeight="--fw-semibold">
                        <FormattedMessage defaultMessage="Gagnez du temps avec les tags prédéfinis !" />
                    </Text>
                    <Text color="--neutral700">
                        <FormattedMessage
                            defaultMessage="Rendez-vous dans les <link>paramètres de votre émission</link> pour définir des tags qui seront ajoutés automatiquement à chaque nouvelle création d'épisode. 🚀"
                            values={{
                                link: (chunks: React.ReactNode) => (
                                    <ShowInfoLinkWrapper>
                                        <ShowInfoLink to={link('menu.settings')} target="_blank">
                                            {chunks}
                                        </ShowInfoLink>
                                        <ShowInfoIcon
                                            icon={icon({
                                                name: 'arrow-up-right',
                                                style: 'regular',
                                            })}
                                        />
                                    </ShowInfoLinkWrapper>
                                ),
                            }}
                        />
                    </Text>
                </Stack>
                <CloseButton onPress={() => setShowAlert(false)}>
                    <CloseButtonIcon icon={icon({ name: 'xmark', style: 'solid' })} />
                </CloseButton>
            </Cluster>
        </Alert>
    );
};

const ShowInfoLinkWrapper = styled.span`
    color: inherit;
    display: inline-flex;
    align-items: center;
    column-gap: 0.125rem;
    width: fit-content;
`;
const ShowInfoLink = styled(Link)`
    color: inherit;
`;
const ShowInfoIcon = styled(FontAwesomeIcon)`
    color: inherit;
    width: 0.75rem;
    height: 0.75rem;
`;
const CloseButton = styled(Button)`
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    width: 1rem;
    height: 1rem;
    flex-shrink: 0;
`;
const CloseButtonIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
    width: inherit;
    height: inherit;
`;

export default EpisodeEditFormContentTagsAlert;
