import Model from './Model';

class LinkedInAccountModel extends Model {
    static attributes = {
        username: 'username',
        name: 'name',
        id: 'id',
        profilePictureUrl: 'profile_picture_url',
        type: 'type',
    };
}

export default LinkedInAccountModel;
