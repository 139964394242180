import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import more from '@public/icons/dotted.svg';
import { PoseGroup } from 'react-pose';
import MobileOrTablet from '@ui/molecules/MobileOrTablet';
import Link from '@ui/atoms/Link';
import DeprecatedButton from '@ui/atoms/DeprecatedButton';
import DeprecatedIcon from '@ui/atoms/DeprecatedIcon';
import MenuItem from '@ui/atoms/MenuItem';
import Menu from '@ui/atoms/Menu';
import { useSwitch } from '@hooks/useSwitch';
import DeleteCampaignModal from '../organisms/DeleteCampaignModal';
import useRouterStore from '@hooks/useRouterStore';
import { observer } from 'mobx-react';
import { CAMPAIGN_STATUS } from '@/utils/constants';
import IconPause from '@ui/icons/IconPause';
import IconPlay from '@ui/icons/IconPlay';
import CampaignPauseModal from './CampaignPauseModal';
import styled from 'styled-components';
import IconStatistics from '@ui/icons/IconStatistics';
import IconDelete from '@ui/icons/IconDelete';
import IconEdit from '@ui/icons/IconEdit';

const MenuStyled = styled(Menu)`
    min-width: 10rem;
`;

function CampaignLineMenu({ campaign, ref, opened, open }) {
    const { link } = useRouterStore();
    const [deleteModalOpened, onDeleteModalOpen, onDeleteModalClose] = useSwitch(false);
    const [isPauseModalOpen, setIsPauseModalOpen] = useState(false);
    const isActive = campaign.status === CAMPAIGN_STATUS.ACTIVE;
    const isPaused = campaign.status === CAMPAIGN_STATUS.PAUSED;
    const isFinished = campaign.status === CAMPAIGN_STATUS.FINISHED;

    return (
        <div style={{ position: 'relative' }}>
            <DeprecatedButton onClick={open} active={opened} accent="rounded" icon>
                <DeprecatedIcon as={more} size={14} />
            </DeprecatedButton>
            <PoseGroup>
                <MenuStyled key={0} ref={ref} opened={opened}>
                    {isActive && (
                        <MenuItem onClick={() => setIsPauseModalOpen(true)}>
                            <IconPause color="--neutral500" />
                            <FormattedMessage defaultMessage="Mettre en pause" />
                        </MenuItem>
                    )}
                    {isPaused && (
                        <MenuItem onClick={() => setIsPauseModalOpen(true)}>
                            <IconPlay color="--neutral500" />
                            <FormattedMessage defaultMessage="Republier" />
                        </MenuItem>
                    )}
                    <MenuItem
                        as={Link}
                        to={link('stats.item', {
                            itemType: 'campaign',
                            itemId: campaign.id,
                            tab: 'listeners',
                        })}
                    >
                        <IconStatistics color="--neutral500" />
                        <FormattedMessage defaultMessage="Statistiques" />
                    </MenuItem>
                    {!isFinished && (
                        <MobileOrTablet>
                            <MenuItem
                                as={Link}
                                to={link('menu.monetize.edit', { campaignId: campaign.id })}
                            >
                                <IconEdit color="--neutral500" />
                                <FormattedMessage defaultMessage="Modifier" />
                            </MenuItem>
                        </MobileOrTablet>
                    )}
                    <MenuItem onClick={onDeleteModalOpen}>
                        <IconDelete color="--neutral500" />
                        <FormattedMessage defaultMessage="Supprimer" />
                    </MenuItem>
                </MenuStyled>
            </PoseGroup>
            <CampaignPauseModal
                campaign={campaign}
                isPaused={isPaused}
                isOpen={isPauseModalOpen}
                onOpenChange={setIsPauseModalOpen}
            />
            <DeleteCampaignModal
                count={1}
                onSubmit={campaign.delete}
                isLoading={campaign.delete.pending}
                isOpened={deleteModalOpened}
                onClose={onDeleteModalClose}
            />
        </div>
    );
}

export default observer(CampaignLineMenu);
