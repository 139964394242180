import { useRef, useState } from 'react';
import usePlaylistsQuery, { Playlist } from '@/queries/playlist/usePlaylistsQuery.hook';
import { useParams } from 'react-router';
import styled from 'styled-components';
import PlaylistListItem from './PlaylistListItem';
import { Button as RACButton } from 'react-aria-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FormattedMessage } from 'react-intl';
import AddPlaylistModal from '../AddPlaylistModal';
import EditPlaylistModal from '../EditPlaylistModal';
import DeletePlaylistModal from '../DeletePlaylistModal';
import PlaylistListSkeleton from './PlaylistListSkeleton';
import SelectDropdown from '@/components/ui/atoms/Select/SelectDropdown';
import MobileSelectedPlaylist from './MobileSelectedPlaylist';

const PlaylistList = () => {
    const { showId, playlistId } = useParams<{ showId: string; playlistId: string }>();
    const playlists = usePlaylistsQuery(showId);
    const [isAddPlaylistModalOpen, setIsAddPlaylistModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [playlist, setPlaylist] = useState<Playlist | null>(null);
    const [isMobileDropdownOpen, setMobileDropdownOpen] = useState(false);
    const triggerRef = useRef(null);

    const handleOpenEditModal = (playlist: Playlist) => {
        setPlaylist(playlist);
        setIsEditModalOpen(true);
    };

    const handleOpenDeleteModal = (playlist: Playlist) => {
        setPlaylist(playlist);
        setIsDeleteModalOpen(true);
    };

    if (playlists.isSuccess) {
        const currentPlaylist = playlists.data.find((p) => p.id.toString() === playlistId);

        return (
            <>
                <DesktopList>
                    <NewPlaylistButtonWrapper aria-label="Create new playlist">
                        <NewPlaylistButton onPress={() => setIsAddPlaylistModalOpen(true)}>
                            <NewPlaylistButtonIconWrapper>
                                <NewPlaylistButtonIcon
                                    icon={icon({ name: 'plus', style: 'solid' })}
                                />
                            </NewPlaylistButtonIconWrapper>
                            <FormattedMessage defaultMessage="Nouvelle playlist" />
                        </NewPlaylistButton>
                    </NewPlaylistButtonWrapper>
                    {playlists.data.map((playlist) => (
                        <PlaylistListItem
                            key={playlist.id}
                            playlist={playlist}
                            onEdit={handleOpenEditModal}
                            onDelete={handleOpenDeleteModal}
                        />
                    ))}
                </DesktopList>
                {currentPlaylist && (
                    <>
                        <MobileSelectedPlaylist
                            key={currentPlaylist?.id}
                            playlist={currentPlaylist}
                            onEdit={handleOpenEditModal}
                            onDelete={handleOpenDeleteModal}
                            onOpenDropdown={() => {
                                setMobileDropdownOpen(true);
                            }}
                        />
                        <MobilePlaylistsDropdown
                            triggerRef={triggerRef}
                            isOpen={isMobileDropdownOpen}
                            onOpenChange={setMobileDropdownOpen}
                        >
                            <MobileList>
                                <NewPlaylistButtonWrapper aria-label="Create new playlist">
                                    <NewPlaylistButton
                                        onPress={() => {
                                            setMobileDropdownOpen(false);
                                            setIsAddPlaylistModalOpen(true);
                                        }}
                                    >
                                        <NewPlaylistButtonIconWrapper>
                                            <NewPlaylistButtonIcon
                                                icon={icon({ name: 'plus', style: 'solid' })}
                                            />
                                        </NewPlaylistButtonIconWrapper>
                                        <FormattedMessage defaultMessage="Nouvelle playlist" />
                                    </NewPlaylistButton>
                                </NewPlaylistButtonWrapper>

                                {playlists.data.map((playlist) => (
                                    <PlaylistListItem
                                        key={playlist.id}
                                        playlist={playlist}
                                        onEdit={handleOpenEditModal}
                                        onDelete={handleOpenDeleteModal}
                                        onClick={() => setMobileDropdownOpen(false)}
                                    />
                                ))}
                            </MobileList>
                        </MobilePlaylistsDropdown>
                    </>
                )}
                <AddPlaylistModal
                    isOpen={isAddPlaylistModalOpen}
                    onOpenChange={setIsAddPlaylistModalOpen}
                />
                <EditPlaylistModal
                    isOpen={isEditModalOpen}
                    onOpenChange={setIsEditModalOpen}
                    playlist={playlist}
                />
                <DeletePlaylistModal
                    isOpen={isDeleteModalOpen}
                    onOpenChange={setIsDeleteModalOpen}
                    playlist={playlist}
                />
            </>
        );
    }

    return <PlaylistListSkeleton />;
};

const List = styled.ul`
    list-style: none;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: var(--white);
    border-radius: var(--r-m);

    & > li:first-of-type {
        border-top-left-radius: var(--r-s);
        border-top-right-radius: var(--r-s);
    }
    & > li:last-of-type {
        border-bottom-left-radius: var(--r-s);
        border-bottom-right-radius: var(--r-s);
    }
`;
const DesktopList = styled(List)`
    display: none;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: flex;
    }
`;
const MobileList = styled(List)`
    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: none;
    }
`;
const NewPlaylistButtonWrapper = styled.li`
    background-color: var(--white);
`;
const NewPlaylistButton = styled(RACButton)`
    cursor: pointer;
    padding: 0.75rem;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    width: 100%;
    font-weight: var(--fw-semibold);
    color: var(--primary500);
`;
const NewPlaylistButtonIconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    background-color: var(--primary50);
    border-radius: var(--r-s);
`;
const NewPlaylistButtonIcon = styled(FontAwesomeIcon)`
    color: var(--primary500);
    width: 1rem;
    height: 1rem;
`;
const MobilePlaylistsDropdown = styled(SelectDropdown)`
    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: none;
    }
`;

export default PlaylistList;
