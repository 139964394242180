import Text from '@/components/ui/atoms/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';
import PsoMetadataTooltip from './PsoMetadataTooltip';
import { TooltipTrigger, Button as AriaButton } from 'react-aria-components';

interface PsoMetadataCellProps {
    occurrences: {
        podcastsDescriptions: number;
        podcastsTitles: number;
        showDescription: number;
        showTitle: number;
    };
    keyword: string;
    isLoading: boolean;
}

const PsoMetadataCell = ({ occurrences, keyword, isLoading }: PsoMetadataCellProps) => {
    const total = Object.values(occurrences)?.reduce(
        (total, currentValue) => total + currentValue,
        0,
    );
    if (isLoading) return <Dash />;
    return (
        <TooltipTrigger delay={0} closeDelay={0}>
            <MetadataWrapper>
                <Metadata>{total}</Metadata>
                {total > 0 ? (
                    <Icon icon={icon({ name: 'magnifying-glass', style: 'solid' })} />
                ) : (
                    <WarningIcon icon={icon({ name: 'triangle-exclamation', style: 'solid' })} />
                )}
            </MetadataWrapper>
            <PsoMetadataTooltip occurrences={occurrences} total={total} keyword={keyword} />
        </TooltipTrigger>
    );
};

const MetadataWrapper = styled(AriaButton)`
    display: inline-flex;
    align-items: center;
    justify-content: start;
    column-gap: 0.25rem;
    padding: 0;
    height: 100%;
    width: 100%;
    background: none;
    border: none;
`;
const Icon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
    width: 0.75rem;
    height: 0.75rem;
`;
const WarningIcon = styled(Icon)`
    color: var(--warning500);
`;
const Metadata = styled(Text)`
    min-width: 1rem;
`;
const Dash = styled.div`
    width: 0.75rem;
    height: 2px;
    background-color: var(--neutral300);
    border-radius: var(--r-full);
`;

export default PsoMetadataCell;
