import { useFormContext } from 'react-hook-form';
import { InputRichTextController } from '@ui/atoms/InputRichText';
import { FormattedMessage, useIntl } from 'react-intl';
import { USER_OPTIONS } from '@/shared/config/userOptions';
import Stack from '@ui/layout/Stack';
import HostedMention from '@/components/HostedMention/HostedMention';
import { useEpisodeBeingEdited } from '@/context/EpisodeFormContext';
import Alert from '@ui/atoms/Alert';
import Text from '@ui/atoms/Text';
import RouterLink from '@/components/Link/RouterLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import EpisodeAIDescriptionSuggestionsPanel from '@/components/EpisodeAI/EpisodeAIDescriptionSuggestionsPanel';
import { useAccordionItem } from '@ui/atoms/AccordionItem/useAccordionItem.hook';
import {
    EPISODE_AI_LOADING_ACTIONS,
    useEpisodeAILoading,
} from '@/components/EpisodeAI/contexts/EpisodeAILoadingContext';
import useSubscriptionQuery from '@queries/subscription/useSubscriptionQuery.hook';
import { PRICING } from '@/shared/config/pricing';
import { useParams } from 'react-router';
import useShowQuery from '@queries/show/useShowQuery.hook';

const EpisodeEditFormContentDescriptionField = () => {
    const { showId } = useParams<{ showId: string }>();
    const show = useShowQuery(showId);
    const { data: subscription } = useSubscriptionQuery();
    const episode = useEpisodeBeingEdited();
    const { control, watch } = useFormContext();
    const intl = useIntl();
    const description = watch('content.htmlDescription');
    const hasFooterSignatureAlert =
        localStorage.getItem(`episode-form-${episode?.id}-description-footer-alert`) !== 'false' &&
        (!description || description.length < 1);
    const [hasAlert, setHasAlert] = useState(hasFooterSignatureAlert);
    const [loadingContent, setLoadingContent] = useState('');
    const { isExpanded } = useAccordionItem();
    const [state, dispatch] = useEpisodeAILoading();
    const isLoading = state.get('description') || false;
    const hasHostedMention = !episode?.show?.userOptions.includes(USER_OPTIONS.NO_HOST_MENTION);

    // Stop wysiwyg loading animation when the accordion is closed
    useEffect(() => {
        if (!isExpanded) {
            handleStopLoading();
        }
    }, [isExpanded]);

    const handleStopLoading = () => {
        dispatch({
            type: EPISODE_AI_LOADING_ACTIONS.STOP,
            payload: 'description',
        });
        setLoadingContent('');
    };

    const handleCloseFooterSignatureAlert = () => {
        localStorage.setItem(`episode-form-${episode?.id}-description-footer-alert`, 'false');
        setHasAlert(false);
    };

    return (
        <Stack $gap="0.5rem">
            <div>
                <InputRichTextController
                    name="content.htmlDescription"
                    control={control}
                    label={<FormattedMessage defaultMessage="Description" />}
                    placeholder={intl.formatMessage({
                        defaultMessage: "Suscitez l'envie à vos auditeurs d’écouter votre épisode",
                    })}
                    charactersLimit={3900}
                    data-testid="new-episode-description-input"
                    isLoading={isLoading}
                    onLoadingEnd={handleStopLoading}
                    loadingContent={loadingContent}
                    htmlLinksDisabled={
                        show?.data?.ownerPricing
                            ? show?.data?.ownerPricing === PRICING.FREEMIUM
                            : subscription?.isFreemium
                    }
                    htmlLinksDisabledReason={
                        <FormattedMessage defaultMessage="L'ajout de liens n'est disponible que pour les offres payantes. 🙏 " />
                    }
                />
                <EpisodeAIDescriptionSuggestionsPanel setLoadingContent={setLoadingContent} />
            </div>
            {hasHostedMention && <HostedMention />}
            {hasAlert && (
                <Alert
                    variant="robot"
                    closable={true}
                    onClose={handleCloseFooterSignatureAlert}
                    description={
                        <Text color="--neutral700">
                            <FormattedMessage
                                defaultMessage="Rendez-vous dans les <link>paramètres de votre émission</link> pour définir une signature de votre description qui sera automatiquement ajoutée à chaque création d'épisode. 🚀"
                                values={{
                                    link: (chunks: React.ReactNode) => (
                                        <AlertLink
                                            to={`/app/show/${episode?.showId}/settings/info`}
                                            target="_blank"
                                            endIcon={
                                                <LinkIcon
                                                    icon={icon({
                                                        name: 'arrow-up-right',
                                                        style: 'regular',
                                                    })}
                                                />
                                            }
                                        >
                                            {chunks}
                                        </AlertLink>
                                    ),
                                }}
                            />
                        </Text>
                    }
                >
                    <Text fontWeight="--fw-semibold">
                        <FormattedMessage defaultMessage="Gagnez du temps avec la signature de description !" />
                    </Text>
                </Alert>
            )}
        </Stack>
    );
};

const LinkIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const AlertLink = styled(RouterLink)`
    color: inherit;
    text-decoration: underline;
    column-gap: 0.25rem;
    font-weight: var(--fw-normal);
`;

export default EpisodeEditFormContentDescriptionField;
