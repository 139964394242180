import { useIntl } from 'react-intl';
import {
    ALL_CATEGORIES_MESSAGE,
    CATEGORIES_MESSAGES,
} from '@/shared/services/categoryMessageService';
import Button from '@/components/Button';
import { Menu, MenuItem, MenuTrigger, Popover } from 'react-aria-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useApplePodcastsStatsContext } from '@/context/ApplePodcastsStatsContext';
import styled from 'styled-components';
import useAppleCategoriesQuery from '@/queries/stats/useAppleCategoriesQuery.hook';
import { useParams } from 'react-router';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import { APPLE_PODCASTS } from '@/shared/config/constants';

function ApplePodcastsCategorySelector() {
    const { showId } = useParams();
    const intl = useIntl();
    const { category: categoryId, setCategory: setCategoryId } = useApplePodcastsStatsContext();
    const { data: show } = useShowQuery(showId);
    const hasApplePodcastsListeningLink = show?.listeningLinks?.data?.some(
        (link) => link.key === APPLE_PODCASTS && link.url,
    );

    const { data: categories } = useAppleCategoriesQuery({
        showId,
        options: { enabled: hasApplePodcastsListeningLink },
    });

    const category = !categoryId
        ? {
              id: null,
              slug: 'all-categories',
          }
        : categories?.find((c) => c.id === categoryId);

    const isSelected = (categoryId) => {
        return category.id === categoryId;
    };

    if (!category || !categories) {
        return null;
    }

    return (
        <MenuTrigger>
            <NoPaddingButton
                variant="ghost"
                aria-label="Menu"
                endIcon={<FontAwesomeIcon icon={icon({ name: 'chevron-down', style: 'solid' })} />}
            >
                {category.slug === 'all-categories'
                    ? intl.formatMessage(ALL_CATEGORIES_MESSAGE)
                    : intl.formatMessage(CATEGORIES_MESSAGES[category.slug])}
            </NoPaddingButton>
            <Popover>
                <StyledMenu>
                    <StyledMenuItem
                        key={null}
                        $isParent={false}
                        onAction={() => setCategoryId(null)}
                    >
                        <CategoryName $isSelected={isSelected(null)}>
                            {intl.formatMessage(ALL_CATEGORIES_MESSAGE)}
                        </CategoryName>

                        {isSelected(null) && (
                            <FontAwesomeIcon icon={icon({ name: 'check', style: 'solid' })} />
                        )}
                    </StyledMenuItem>
                    {categories.map((category) => (
                        <StyledMenuItem
                            key={category.id}
                            $isParent={'isParent' in category}
                            onAction={() => setCategoryId(category.id)}
                        >
                            <CategoryName $isSelected={isSelected(category.id)}>
                                {category.slug === 'all-categories'
                                    ? intl.formatMessage(ALL_CATEGORIES_MESSAGE)
                                    : intl.formatMessage(CATEGORIES_MESSAGES[category.slug])}
                            </CategoryName>

                            {isSelected(category.id) && (
                                <FontAwesomeIcon icon={icon({ name: 'check', style: 'solid' })} />
                            )}
                        </StyledMenuItem>
                    ))}
                </StyledMenu>
            </Popover>
        </MenuTrigger>
    );
}

const NoPaddingButton = styled(Button)`
    padding: 0;
    column-gap: 0.25rem;

    & > svg {
        font-size: 0.75rem;
    }
`;
const StyledMenu = styled(Menu)`
    min-width: 10rem;
    background-color: var(--white);
    box-shadow: var(--s-m);
    border-radius: var(--r-m);
`;
const StyledMenuItem = styled(MenuItem)`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding-block: 0.5rem;
    padding-inline: 0.75rem;
    border-radius: var(--r-xs);
    cursor: pointer;
    color: var(--black);
    text-decoration: none;

    ${(props) =>
        props.$isParent &&
        `
        padding-inline-start: 1.5rem;
    `}
    &:hover {
        background-color: var(--neutral50);
    }
    &[data-disabled='true']:hover {
        background-color: var(--white);
    }
    &[data-disabled='true'] {
        color: var(--neutral200);
        cursor: not-allowed;
    }
    &:first-of-type {
        border-top-left-radius: var(--r-m);
        border-top-right-radius: var(--r-m);
    }
    &:last-of-type {
        border-bottom-left-radius: var(--r-m);
        border-bottom-right-radius: var(--r-m);
    }
`;
const CategoryName = styled.span`
    font-weight: ${(props) => (props.$isSelected ? 'var(--fw-bold)' : 'var(--fw-normal)')};
`;

export default ApplePodcastsCategorySelector;
