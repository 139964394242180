import { useState, useEffect, useRef, isValidElement } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Button from '@ui/atoms/Button';
import Text from '@ui/atoms/Text';
import IconChevronDown from '@ui/icons/IconChevronDown';
import IconChevronUp from '@ui/icons/IconChevronUp';
import IconLocked from '@ui/icons/IconLocked';
import IconChecked from '@ui/icons/IconChecked';
import { FormattedMessage, useIntl } from 'react-intl';
import { useResponsive } from '@/utils/hooks/useResponsive';
import styled, { css } from 'styled-components';
import OutsideClickHandler from 'react-outside-click-handler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { motion, AnimatePresence } from 'framer-motion';
import { makeBackgroundUnresponsive, restoreBackgroundResponsiveness } from '@/utils/modal';

const Select = (props) => {
    const {
        options,
        optionsTitle,
        optionsTitleSeparator,
        label,
        description,
        isDisabled,
        isReadOnly,
        validationState,
        isRequired,
        isOptional,
        isLoading,
        errorMessage,
        placeholder,
        value,
        id,
        name,
        inputImage,
        onChange,
        onBlur,
        fetchMoreOptionsEnabled,
        onFetchMoreOptions,
        isNullable,
        nullOptionCustomTitle,
        maxOptionsHeight,
        isSearchable,
        helpTooltip,
        customButton,
        onSearchInputChange,
    } = props;

    const { isMobileOrTablet } = useResponsive();
    const intl = useIntl();
    const searchInputRef = useRef(null);

    const [open, setOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);
    const [filteringEnabled, setFilteringEnabled] = useState(false);

    useEffect(() => {
        if (!value) return;
        const defaultOption = findDefaultOption(options);
        selectOption(defaultOption);
    }, []);

    const findDefaultOption = (options) => {
        let defaultOption;

        const findOption = (option) => {
            if (option.id === value || option.id === value.id) {
                defaultOption = option;
                return;
            }
            if (option.options && option.options.length > 0) {
                option.options.forEach(findOption);
            }
        };

        options.forEach(findOption);

        return defaultOption;
    };

    const openOptions = () => {
        if (isMobileOrTablet) {
            makeBackgroundUnresponsive();
            searchInputRef.current.blur();
        }
        setOpen(true);
    };

    const closeOptions = () => {
        if (isMobileOrTablet) {
            restoreBackgroundResponsiveness();
        }
        setOpen(false);
        onBlur();
    };

    const selectOption = (option, event) => {
        if (!option || option.isLocked || option.isSelectable === false) return;
        onChange(option, event);
        setSelectedOption(option);
        setSearchQuery(option?.label || '');
        closeOptions();
    };

    const filterOption = (option) => {
        if (!isSearchable || isMobileOrTablet || !filteringEnabled) return true;
        return (
            option.label.toLowerCase().includes(searchQuery?.toLowerCase()) ||
            (option.options &&
                option.options.find((o) =>
                    o.label.toLowerCase().includes(searchQuery?.toLowerCase()),
                ))
        );
    };

    const isSelected = (option) => option.id === selectedOption?.id;

    const renderOption = (option, isNested = false) => (
        <Option
            key={option.id}
            {...option}
            isNested={isNested}
            onClick={(event) => {
                selectOption(option, event);
            }}
        >
            <OptionInnerWrapper isLocked={option.isLocked}>
                <OptionMainContent>
                    {option.image && typeof option.image === 'string' && (
                        <OptionImg src={option.image} />
                    )}
                    {option.image && isValidElement(option.image) && option.image}
                    <OptionText>
                        <Text
                            variant="body"
                            fontWeight={isNested ? '--fw-normal' : '--fw-semibold'}
                        >
                            {option.label}
                        </Text>
                        <OptionDescription variant="footnote">
                            {option.description}
                        </OptionDescription>
                    </OptionText>
                </OptionMainContent>
                {isSelected(option) && <IconChecked />}
                {option.isLocked && <IconLocked />}
            </OptionInnerWrapper>
        </Option>
    );

    const optionsTemplate = [
        ...(isNullable
            ? [
                  <Option
                      key="null-option"
                      onClick={(event) =>
                          selectOption(
                              {
                                  id: null,
                                  label: nullOptionCustomTitle ?? '--',
                                  description: null,
                                  isLocked: false,
                                  isSelectable: true,
                              },
                              event,
                          )
                      }
                      isNullOption={true}
                  >
                      <OptionInnerWrapper>
                          <OptionMainContent>
                              <OptionText>
                                  <Text variant="body">{nullOptionCustomTitle ?? '--'}</Text>
                              </OptionText>
                          </OptionMainContent>
                      </OptionInnerWrapper>
                  </Option>,
              ]
            : []),
        ...options.filter(filterOption).map((option) => {
            return option.options
                ? [
                      renderOption(option),
                      ...option.options
                          .filter(filterOption)
                          .map((nestedOption) => renderOption(nestedOption, true)),
                  ]
                : renderOption(option);
        }),
    ];

    const optionsTitleTemplate = optionsTitle && (
        <OptionTitle withSeparator={optionsTitleSeparator}>{optionsTitle}</OptionTitle>
    );

    const optionLoadMore = fetchMoreOptionsEnabled && (
        <FetchMore onClick={() => onFetchMoreOptions()}>
            <FormattedMessage defaultMessage="Charger plus" />
        </FetchMore>
    );

    return (
        <SelectWrapper id={id} name={name} className={props.className}>
            <LabelWrapper>
                <Label>
                    {label}
                    {isRequired && (
                        <Text as="span" fontWeight="--fw-semibold" color="--primary">
                            *
                        </Text>
                    )}
                    {isOptional && (
                        <Text as="span">
                            <FormattedMessage defaultMessage="(facultatif)" />
                        </Text>
                    )}
                    {helpTooltip && helpTooltip}
                </Label>
                {description && <Description>{description}</Description>}
            </LabelWrapper>
            <OutsideClickHandler onOutsideClick={() => closeOptions()} disabled={isMobileOrTablet}>
                <SearchElement>
                    <InputWrapper disabled={false}>
                        {inputImage && typeof inputImage === 'string' && (
                            <InputImage src={inputImage} />
                        )}
                        {inputImage && isValidElement(inputImage) && (
                            <InputImageWrapper>{inputImage}</InputImageWrapper>
                        )}

                        <Input
                            ref={searchInputRef}
                            onFocus={() => {
                                if (searchQuery) setFilteringEnabled(false);
                                openOptions();
                            }}
                            onClick={() => {
                                searchInputRef.current.select();
                            }}
                            onChange={(event) => {
                                onSearchInputChange(event);
                                setSearchQuery(event?.target?.value || '');
                            }}
                            onKeyDown={() => {
                                setFilteringEnabled(true);
                            }}
                            onBlur={() => {
                                searchQuery.length === 0 &&
                                    setSearchQuery(selectedOption?.label || '');
                                onBlur();
                            }}
                            value={searchQuery}
                            disabled={isDisabled}
                            readOnly={isReadOnly}
                            placeholder={
                                placeholder || intl.formatMessage({ defaultMessage: 'Aucune' })
                            }
                            description={description}
                            hasImage={!!inputImage}
                            isValid={validationState === 'valid'}
                            hasErrors={validationState === 'invalid'}
                        />
                        {isLoading ? (
                            <IsLoadingWrapper>
                                <FontAwesomeIcon
                                    icon={icon({ name: 'spinner-third', style: 'solid' })}
                                    spin
                                    size="md"
                                />
                            </IsLoadingWrapper>
                        ) : (
                            <DropdownButton
                                onClick={() => (open ? closeOptions() : openOptions())}
                                variant="ghost"
                                color="--black"
                            >
                                {open ? <IconChevronUp /> : <IconChevronDown />}
                            </DropdownButton>
                        )}
                    </InputWrapper>{' '}
                </SearchElement>
                {isMobileOrTablet ? (
                    ReactDOM.createPortal(
                        <AnimatePresence>
                            {open && (
                                <MobileOptionsWrapper
                                    role="dialog"
                                    aria-modal="true"
                                    onClick={(event) => {
                                        if (event.target !== event.currentTarget) return;
                                        closeOptions();
                                    }}
                                >
                                    <MobileOptions
                                        initial={{ opacity: 0, y: 40 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        exit={{ opacity: 0 }}
                                        transition={{ duration: 0.25 }}
                                    >
                                        {optionsTitleTemplate}
                                        {optionsTemplate}
                                        {optionLoadMore}
                                        {customButton}
                                    </MobileOptions>
                                </MobileOptionsWrapper>
                            )}
                        </AnimatePresence>,
                        document.querySelector('#modal-root'),
                    )
                ) : (
                    <AnimatePresence>
                        {open && (
                            <OptionsWrapper
                                maxOptionsHeight={maxOptionsHeight}
                                initial={{ opacity: 0, y: -5 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.25 }}
                            >
                                {optionsTitleTemplate}
                                {optionsTemplate}
                                {optionLoadMore}
                                {customButton}
                            </OptionsWrapper>
                        )}
                    </AnimatePresence>
                )}
            </OutsideClickHandler>
            {errorMessage && <Text color="--alert">{errorMessage}</Text>}
        </SelectWrapper>
    );
};

const SelectWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    width: 100%;
`;

const SearchElement = styled.div``;
const OptionsWrapper = styled(motion.ul)`
    position: absolute;
    top: calc(100% + 0.25rem);
    left: 0;
    width: 100%;
    max-height: ${(props) => props.maxOptionsHeight || '16rem'};
    overflow-y: auto;
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: white;
    border-radius: var(--r-xs);
    z-index: 5;
    box-shadow: var(--s-light);
`;
const OptionTitle = styled.li`
    width: 100%;
    ${(props) =>
        props.withSeparator &&
        css`
            border-bottom: 1px solid var(--neutral50);
        `}
`;
const Option = styled.li`
    padding: 0.75rem;
    cursor: pointer;

    &:first-of-type {
        border-radius: var(--r-xs) var(--r-xs) 0 0;
    }

    &:last-of-type {
        border-radius: 0 0 var(--r-xs) var(--r-xs);
    }

    ${(props) =>
        props.isNested &&
        css`
            padding-left: 2rem;
        `}

    ${(props) =>
        props.isNullOption &&
        css`
            color: var(--neutral500);
        `}

    ${(props) =>
        props.isSelectable === false
            ? css`
                  cursor: default;
                  &:hover {
                      background-color: none;
                  }
              `
            : css`
                  &:hover {
                      background-color: var(--neutral50);
                  }
              `}

    ${(props) => props.theme.mediaQueries.mobile} {
        &:hover {
            background-color: none;
        }
    }

    ${(props) =>
        props.isLocked &&
        css`
            cursor: not-allowed;
        `}
`;
const FetchMore = styled.li`
    color: var(--primary);
    font-weight: var(--fw-semibold);
    padding: 0.75rem;
    cursor: pointer;
`;
const OptionInnerWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${(p) =>
        p.isLocked &&
        css`
            opacity: 0.5;
            color: var(--neutral500)
            cursor: not-allowed;
        `}
`;
const OptionMainContent = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
`;
const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
const Label = styled.label`
    display: inline-flex;
    align-items: center;
    column-gap: 0.25rem;
    font-size: var(--fs-body);
    font-weight: var(--fw-semibold);

    & > *,
    & svg {
        display: block;
    }
`;
const Description = styled.div`
    font-size: 12px;
    color: var(--neutral500);
`;
const InputWrapper = styled.div`
    position: relative;
    background: white;
    input {
        cursor: pointer;
        background-color: transparent;
        z-index: 1;
        position: relative;
    }
    svg {
        position: absolute;
        right: 10px;
        top: calc(50%);
        transform: translateY(-50%);
        fill: ${(p) => p.disabled && 'var(--neutral500)'};
        z-index: 0;
    }
    input:focus + svg {
        fill: var(--primary);
    }
`;
const IsLoadingWrapper = styled.div`
    position: absolute;
    right: 0.75rem;
    top: 50%;
    width: 1rem;
    height: 1rem;
    color: var(--primary);
    z-index: 1000;
    transform: translateY(calc(-50% + 0.25rem));
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        position: static;
        right: 0;
        top: 0;
    }
`;
const InputImageBase = css`
    position: absolute;
    width: 2.5rem;
    height: auto;
    aspect-ratio: 1/1;
    top: 50%;
    left: 0.75rem;
    transform: translateY(-50%);
    border-radius: var(--r-xs);
`;
const InputImage = styled.img`
    ${InputImageBase};
`;
const InputImageWrapper = styled.div`
    ${InputImageBase};
    display: flex;
    align-items: center;
    justify-content: center;

    & > img {
        min-width: 2.5rem;
        height: auto;
    }
`;
const DropdownButton = styled(Button)`
    position: absolute;
    right: 0;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
`;
const MobileOptionsWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    padding: 0.5rem;
    background: hsl(0 0% 0%/0.7);
    z-index: 1001;
`;
const MobileOptions = styled(motion.ul)`
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    max-height: 90vh;
    background: white;
    border-radius: var(--r-l) var(--r-l) 0 0;
    position: fixed;
    bottom: 0;
    left: 0;
    overflow-y: auto;
`;
const Input = styled.input`
    transition-duration: 0.2s;
    width: 100%;
    min-height: 40px;
    border-radius: var(--r-xs);
    border: 1px solid var(--neutral200);
    padding-block: 8px;
    padding-inline: 12px;
    font-size: var(--fs-body);

    &:hover {
        transition-duration: 0.2s;
        border-color: var(--neutral400);
    }

    &:focus {
        transition-duration: 0.2s;
        border-color: var(--primary);
    }

    &:read-only {
        background: var(--neutral50);
        border-color: var(--neutral200);
    }

    &:disabled {
        cursor: not-allowed;
        color: var(--neutral500);
        background: var(--white);
    }

    ${(p) =>
        p.hasImage &&
        css`
            min-height: 64px;
            padding-left: 3.75rem;
        `}

    ${(p) =>
        p.isValid &&
        css`
            padding-right: 2.5rem;
            border-color: var(--success);
        `}

    ${(p) =>
        p.hasErrors &&
        css`
            padding-right: 2.5rem;
            border-color: var(--alert);
        `}

    ${(p) =>
        p.hasIcon &&
        css`
            padding-left: 2.5rem;
        `}
`;
const OptionImg = styled.img`
    width: 2.5rem;
    aspect-ratio: 1/1;
    border-radius: var(--r-xs);
`;
const OptionText = styled.div``;
const OptionDescription = styled(Text)`
    color: var(--neutral500);
`;

Select.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            isSecondLevel: PropTypes.string,
            label: PropTypes.string,
            description: PropTypes.string,
            image: PropTypes.node,
            isLocked: PropTypes.bool,
        }),
    ),
    optionsTitle: PropTypes.node,
    optionsTitleSeparator: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    description: PropTypes.string,
    isDisabled: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    validationState: PropTypes.oneOf(['valid', 'invalid']),
    isRequired: PropTypes.bool,
    isOptional: PropTypes.bool,
    isLoading: PropTypes.bool,
    errorMessage: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    initialValue: PropTypes.any,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    inputImage: PropTypes.node,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    fetchMoreOptionsEnabled: PropTypes.bool,
    onFetchMoreOptions: PropTypes.func,
    isNullable: PropTypes.bool,
    nullOptionCustomTitle: PropTypes.string,
    maxOptionsHeight: PropTypes.string,
    isSearchable: PropTypes.bool,
    helpTooltip: PropTypes.node,
    className: PropTypes.string,
    customButton: PropTypes.node,
    onSearchInputChange: PropTypes.func,
};

Select.defaultProps = {
    options: [],
    optionsTitle: null,
    optionsTitleSeparator: false,
    label: '',
    description: '',
    isDisabled: false,
    isReadOnly: false,
    validationState: null,
    isRequired: false,
    isOptional: false,
    isLoading: false,
    errorMessage: '',
    placeholder: '',
    value: '',
    initialValue: '',
    id: crypto.randomUUID(),
    name: crypto.randomUUID(),
    inputImage: null,
    onChange: () => {},
    onBlur: () => {},
    fetchMoreOptionsEnabled: false,
    onFetchMoreOptions: () => {},
    isNullable: true,
    isSearchable: true,
    onSearchInputChange: () => {},
};

export default Select;
