import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';

const LoginWithSharingProviderPage = () => {
    const { provider } = useParams();
    const paramObj = useMemo(() => {
        const params = new URLSearchParams(window.location.search);
        let obj = {};
        for (const key of params.keys()) {
            obj[key] = params.get(key);
        }
        return obj;
    }, [window.location.search]);

    useEffect(() => {
        if (!paramObj || !provider) return;
        window.opener.postMessage({ ...paramObj, provider }, window.location.origin);
        window.close();
    }, [paramObj, provider]);

    return null;
};

export default LoginWithSharingProviderPage;
