import { Genders } from '@/queries/stats/useGendersQuery.hook';
import Gender, { GENDER_TYPES } from './Gender';
import styled from 'styled-components';

interface AudienceGendersChartProps {
    genders: Genders;
}

const AudienceGendersChart = ({ genders }: AudienceGendersChartProps) => {
    return (
        <GenderGraphWrapper>
            <Gender
                type={GENDER_TYPES.FEMININE}
                percent={
                    genders?.find((value) => value.gender === GENDER_TYPES.FEMININE)?.percentage
                }
            />
            <Gender
                type={GENDER_TYPES.MASCULINE}
                percent={
                    genders?.find((value) => value.gender === GENDER_TYPES.MASCULINE)?.percentage
                }
            />
            <Gender
                type={GENDER_TYPES.OTHER}
                percent={genders?.find((value) => value.gender === GENDER_TYPES.OTHER)?.percentage}
            />
        </GenderGraphWrapper>
    );
};

const GenderGraphWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: var(--container-padding);
    background-color: var(--white);
    gap: 1rem;
    border-radius: var(--r-l);
    overflow-x: auto;

    ${({ theme }) => theme.mediaQueries.mobile} {
        justify-content: flex-start;
        padding: var(--container-padding-mobile);
    }
`;

export default AudienceGendersChart;
