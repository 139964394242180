const seasonKeys = {
    all: () => [{ entity: 'season' }],
    allLists: () => [{ ...seasonKeys.all()[0], scope: 'list' }],
    listByShowId: (rawShowId: string) => [
        { ...seasonKeys.allLists()[0], showId: Number(rawShowId) },
    ],
    allDetails: () => [{ ...seasonKeys.all()[0], scope: 'detail' }],
    detailById: (rawShowId: string, rawSeasonId?: string) => [
        { ...seasonKeys.allDetails()[0], showId: Number(rawShowId), seasonId: Number(rawSeasonId) },
    ],
};

export default seasonKeys;
