import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import { connect } from '@app/decorators/connect';
import DeprecatedButton from '../../ui/atoms/DeprecatedButton';
import DeprecatedPaper from '../../ui/atoms/DeprecatedPaper';
import Spinner from '../../ui/atoms/Spinner';
import styled from 'styled-components';
import Dialog from '@/components/ui/atoms/Dialog';

const enhance = connect(({ routerStore }, { onOpenChange }) => {
    const campaign = routerStore.resolve('campaignId');
    if (!campaign) {
        return {};
    }
    return {
        startDate: campaign.startsAt,
        endDate: campaign.endsAt,
        selectedEpisodesCount: 0,
        isLoading: campaign.publish.pending,
        onSubmit: () => campaign.publish().then(() => onOpenChange(false)),
    };
});

function CampaignPublicationModal({
    isOpen,
    onOpenChange,
    selectedEpisodesCount,
    startDate,
    endDate,
    isLoading,
    onSubmit,
}) {
    return (
        <Dialog
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            title={<FormattedMessage defaultMessage="Publier la campagne" />}
            size="medium"
        >
            <ModalContentWrapper>
                <ModalContentInnerWrapper>
                    <DeprecatedText>
                        <FormattedMessage defaultMessage="Souhaitez-vous publier la campagne ?" />
                    </DeprecatedText>
                    {selectedEpisodesCount > 0 && (
                        <DeprecatedText weight="semibold">
                            <FormattedMessage
                                defaultMessage="Celle-ci s'appliquera à {count, plural, one {un épisode} other {# épisodes}}"
                                values={{ count: selectedEpisodesCount }}
                            />
                        </DeprecatedText>
                    )}
                    <DeprecatedPaper p={20} mt={10} background="--neutral50">
                        <DeprecatedText mb={10} weight="semibold">
                            <FormattedMessage defaultMessage="Commence le" />
                        </DeprecatedText>
                        <DeprecatedText color="--neutral500">
                            <FormattedMessage
                                defaultMessage="{date} à {time}"
                                values={{
                                    date: <FormattedDate value={startDate} />,
                                    time: <FormattedTime value={startDate} />,
                                }}
                            />
                        </DeprecatedText>
                        {endDate && (
                            <>
                                <DeprecatedText mb={10} mt={30} weight="semibold">
                                    <FormattedMessage defaultMessage="Termine le" />
                                </DeprecatedText>
                                <DeprecatedText color="--neutral500">
                                    <FormattedMessage
                                        defaultMessage="{date} à {time}"
                                        values={{
                                            date: <FormattedDate value={endDate} />,
                                            time: <FormattedTime value={endDate} />,
                                        }}
                                    />
                                </DeprecatedText>
                            </>
                        )}
                    </DeprecatedPaper>
                </ModalContentInnerWrapper>
                <ActionWrapper>
                    <DeprecatedButton
                        mr={10}
                        accent="secondary"
                        onClick={() => onOpenChange(false)}
                    >
                        <FormattedMessage defaultMessage="Annuler" />
                    </DeprecatedButton>
                    <DeprecatedButton onClick={onSubmit} disabled={isLoading}>
                        {isLoading && <Spinner />}
                        <FormattedMessage defaultMessage="Publier la campagne" />
                    </DeprecatedButton>
                </ActionWrapper>
            </ModalContentWrapper>
        </Dialog>
    );
}

const ModalContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
`;
const ModalContentInnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
const ActionWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;
export default enhance(CampaignPublicationModal);
