import Modal from '@/components/ui/atoms/Modal';
import Text from '@/components/ui/atoms/Text';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Button from '@/components/Button';
import IconButton from '@/components/IconButton';
import { useEpisodeBeingEdited } from '@/context/EpisodeFormContext';
import NonHostingEpisodeSummary from '../NonHostingEpisodeSummary/NonHostingEpisodeSummary';
import { useFormContext } from 'react-hook-form';
import {
    filterFalsyValues,
    formatSecondsDuration,
    getDirtyValues,
    jsonToCSV,
    sendAmplitudeLogEvent,
} from '@/helpers';
import getSaveDraftEpisodeAndCloseSchema from '@/components/unorganized/EpisodeEditForm/schemas/saveDraftEpisodeAndCloseSchema';
import { useBodyToastQueue } from '@/shared/hooks/useBodyToastQueue.hook';
import { useHistory } from 'react-router';
import saveNonHostingEpisodeMutation from '../saveNonHostingEpisodeMutation';
import { getChaptersIdsRequiringImageDeletion } from '../helpers/getChaptersIdsRequiringImageDeletion';
import { NonHostingEpisodeFormSchema } from '../useNonHostingEpisodeFormSchema.hook';
import dayjs from 'dayjs';
import useUserQuery from '@/queries/user/useUserQuery.hook';

interface NonHostingEpisodeSummaryModalProps {
    isOpen: boolean;
    onOpenChange: (value: boolean) => void;
}

const NonHostingEpisodeSummaryModal = ({
    isOpen,
    onOpenChange,
}: NonHostingEpisodeSummaryModalProps) => {
    const intl = useIntl();
    const { push } = useHistory();
    const episode = useEpisodeBeingEdited();
    const {
        getValues,
        reset,
        formState: { dirtyFields },
    } = useFormContext<NonHostingEpisodeFormSchema>();
    const saveEpisodeMutation = saveNonHostingEpisodeMutation();
    const schema = getSaveDraftEpisodeAndCloseSchema(intl);
    const formData = getValues();
    const isSchemaValid = schema.isValidSync(formData);
    const isValid = isSchemaValid;
    const toast = useBodyToastQueue();
    const userQuery = useUserQuery();
    const userLocale = userQuery.data?.language ?? 'en';

    const handleClose = () => onOpenChange(false);

    const handleExport = () => {
        const { content, chapters, newsletter, socialPosts } = getValues();
        const flattenedData = {
            ...content,
            chapters: chapters.map((chapter) => {
                return `(${formatSecondsDuration(chapter.startTime)}) - ${chapter.title}`;
            }),
            newsletter_title: newsletter?.title,
            newsletter_message: newsletter?.message,
            ...socialPosts,
        };
        const nonEmptyData = filterFalsyValues(flattenedData);
        const csvData = jsonToCSV([nonEmptyData]);
        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;

        const fileName = intl.formatMessage(
            { defaultMessage: '{date}-{showSlug}-{episodeSlug}-metadonnees.csv' },
            {
                date: dayjs().format(userLocale === 'fr' ? 'DDMMYY' : 'YYMMDD'),
                showSlug: episode?.show?.slug,
                episodeSlug: episode?.slug,
            },
        );
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        sendAmplitudeLogEvent('Export Summary Episode');
    };

    const handleSave = async () => {
        if (!episode) return;

        const dirtyValues = getDirtyValues(dirtyFields, formData);
        const chaptersIdsRequiringImageDeletion = getChaptersIdsRequiringImageDeletion(
            dirtyFields,
            formData.chapters,
        );
        const { content } = dirtyValues;
        const chapters = dirtyFields.chapters ? formData.chapters : undefined;
        const hasDirtyMessages = dirtyFields.newsletter || dirtyFields.socialPosts;
        const messages = hasDirtyMessages
            ? {
                  newsletter: formData.newsletter,
                  ...formData.socialPosts,
              }
            : undefined;

        try {
            await saveEpisodeMutation.mutateAsync(
                {
                    episodeId: String(episode.id),
                    episode: { ...content },
                    chapters,
                    chaptersIdsRequiringImageDeletion,
                    messages,
                },
                {
                    onSuccess: () => {
                        reset();
                        push(`/app/show/${episode?.showId}/workspace`);
                    },
                },
            );
        } catch (error) {
            toast.alert();
        }
    };

    return (
        <ModalStyled size="medium" isOpen={isOpen} onOpenChange={onOpenChange}>
            <ModalHeader>
                <Text variant="headingM" fontWeight="--fw-bold">
                    <FormattedMessage defaultMessage="Aperçu" />
                </Text>
                <CloseButton
                    aria-label="Close"
                    icon={<CloseIcon icon={icon({ name: 'xmark-large', style: 'solid' })} />}
                    onPress={handleClose}
                    variant="ghost"
                    size="small"
                />
            </ModalHeader>
            <NonHostingEpisodeSummary />
            <ActionsWrapper>
                <ActionsInnerWrapper>
                    <Button
                        isLoading={saveEpisodeMutation.isLoading}
                        isDisabled={!isValid}
                        onPress={handleSave}
                        startIcon={<ActionIcon icon={icon({ name: 'check', style: 'solid' })} />}
                    >
                        <FormattedMessage defaultMessage="Enregistrer et fermer" />
                    </Button>
                    <Button
                        onPress={handleExport}
                        variant="secondary"
                        startIcon={<ActionIcon icon={icon({ name: 'upload', style: 'solid' })} />}
                    >
                        <FormattedMessage defaultMessage="Exporter" />
                    </Button>
                </ActionsInnerWrapper>
                <CancelButton variant="ghost" onPress={handleClose}>
                    <FormattedMessage defaultMessage="Retour" />
                </CancelButton>
            </ActionsWrapper>
        </ModalStyled>
    );
};

const ModalStyled = styled(Modal)`
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`;
const ModalHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const CloseIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
    width: 1rem;
    height: 1rem;
`;
const CloseButton = styled(IconButton)`
    padding: 0;
`;
const ActionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
        column-gap: 1rem;
        justify-content: space-between;
    }
`;
const ActionIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const CancelButton = styled(Button)`
    padding: 0;
    color: var(--neutral500);

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        order: -1;
    }
`;
const ActionsInnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
        column-gap: 1rem;

        & > :first-child {
            order: 1;
        }
    }
`;

export default NonHostingEpisodeSummaryModal;
