import { flexRender, Table } from '@tanstack/react-table';
import { DownloadsComparisonData } from '@/components/unorganized/DownloadsComparisonContent';
import { DownloadsComparisonTableTd as Td } from '@/components/tables/DownloadsComparisonTable/DownloadsComparisonTableCommon';
import styled from 'styled-components';

interface DownloadsComparisonTableBodyProps {
    table: Table<DownloadsComparisonData>;
    highlightedColumnIndex?: number;
}

const DownloadsComparisonTableBody = ({
    table,
    highlightedColumnIndex,
}: DownloadsComparisonTableBodyProps) => {
    return (
        <Tbody>
            {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                    {row.getVisibleCells().map((cell, index) => {
                        const { column } = cell;

                        return (
                            <Td
                                key={cell.id}
                                column={column}
                                isHighlighted={
                                    highlightedColumnIndex
                                        ? index === highlightedColumnIndex + 1
                                        : false
                                }
                            >
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </Td>
                        );
                    })}
                </tr>
            ))}
        </Tbody>
    );
};

const Tbody = styled.tbody`
    background: var(--white);
`;

export default DownloadsComparisonTableBody;
