import { useEffect, useState } from 'react';
import { useAmplitudeLogEvent } from '@/shared/hooks/useAmplitude';
import { useHistory, useParams } from 'react-router';
import useCampaignsQuery from '@/queries/campaign/useCampaignsQuery';
import Header from './Header';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Campaigns from './Campaigns';
import { useSelectionContext } from '@/features/Campaigns/services/SelectionContext';
import SelectionToolbar from './SelectionToolbar';
import { USER_ROLES } from '@/shared/config/constants';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import Text from '@/components/ui/atoms/Text';
import ShiningDiamond from '@/components/ui/atoms/ShiningDiamond';
import ZebraIllustration from '@/components/ui/atoms/ZebraIllustration';
import { FormattedMessage, useIntl } from 'react-intl';
import EmptyState from '@/components/ui/molecules/EmptyState';
import RouterLink from '@/components/Link/RouterLink';
import { USER_OPTIONS } from '@/shared/config/userOptions';
import emptyStateMainIllustration from '@public/images/empty-state/illustration/monetization.mp4';
import noCampaignMainIllustration from '@public/images/empty-state/illustration/monetization.mp4';
import noCampaignHeadIllustration from '@public/images/empty-state/monetization.svg?url';
import addCampaignMutation from '@/queries/campaign/addCampaignMutation';
import Button from '@/components/Button';
import dayjs from 'dayjs';
import { useQueryClient } from '@tanstack/react-query';
import campaignKeys from '@/queries/campaign/campaignKeys';
import ServerPagination from '@/components/ui/ServerPagination';

const ManualCampaignsPage = () => {
    useAmplitudeLogEvent('Monetization Manual Tab Viewed');
    const history = useHistory();
    const intl = useIntl();
    const { showId } = useParams<{ showId: string }>();
    const { data: show } = useShowQuery(showId);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(20);
    const [search, setSearch] = useState('');
    const addCampaign = addCampaignMutation();
    const { setCampaigns, resetPageSelection } = useSelectionContext();
    const { data: campaigns, isLoading: campaignsAreLoading } = useCampaignsQuery({
        showId,
        pagination: { page, perPage },
        query: search,
    });
    const queryClient = useQueryClient();

    useEffect(() => {
        resetPageSelection();
    }, [campaigns?.pagination?.currentPage]);

    useEffect(() => {
        if (!campaigns) return;
        setCampaigns(campaigns?.campaigns?.map((campaign) => `${campaign.id}`));
    }, [campaigns, setCampaigns]);

    useEffect(() => {
        resetPageSelection();
    }, [campaigns?.pagination?.currentPage]);

    useEffect(() => {
        if (!campaigns) return;
        setCampaigns(campaigns?.campaigns?.map((campaign) => `${campaign.id}`));
    }, [campaigns, setCampaigns]);

    const handleSearchChange = (value: string) => {
        setSearch(value);
        setPage(1);
    };

    if (show?.userRole === USER_ROLES.VIEWER) {
        return (
            <EmptyState
                illustration={emptyStateMainIllustration}
                illustrationType="video"
                illustrationAlign="right"
                illustrationBackground="--gradient-sunny"
            >
                <HeadIllustrationWrapper>
                    <ZebraIllustration>
                        <ShiningDiamond />
                    </ZebraIllustration>
                </HeadIllustrationWrapper>
                <LabelWrapper>
                    <Text variant="heading" fontWeight="--fw-bold">
                        <FormattedMessage defaultMessage="Abonnez-vous !" />
                    </Text>
                    <Text variant="body" fontWeight="--fw-normal" color="--neutral500">
                        <FormattedMessage defaultMessage="Pour débloquer la monétisation de votre podcast, abonnez-vous 🚀" />
                    </Text>
                </LabelWrapper>
                <RouterLink
                    startIcon={<RocketIcon icon={icon({ name: 'rocket', style: 'solid' })} />}
                    to="/app/subscription/offers"
                >
                    <FormattedMessage defaultMessage="S'abonner" />
                </RouterLink>
            </EmptyState>
        );
    }

    if (!show?.userOptions?.includes(USER_OPTIONS.MANUAL_CAMPAIGNS)) {
        return (
            <EmptyState
                illustration={emptyStateMainIllustration}
                illustrationType="video"
                illustrationAlign="right"
                illustrationBackground="--gradient-sunny"
            >
                <HeadIllustrationWrapper>
                    <ZebraIllustration>
                        <ShiningDiamond />
                    </ZebraIllustration>
                </HeadIllustrationWrapper>
                <LabelWrapper>
                    <Text variant="heading" fontWeight="--fw-bold">
                        <FormattedMessage defaultMessage="Passez à l'offre supérieure !" />
                    </Text>
                    <Text variant="body" fontWeight="--fw-normal" color="--neutral500">
                        <FormattedMessage defaultMessage="Pour débloquer la monétisation de votre podcast, abonnez-vous à l'offre Boost ou Supersonic 🚀" />
                    </Text>
                </LabelWrapper>
                <RouterLink
                    startIcon={<RocketIcon icon={icon({ name: 'rocket', style: 'solid' })} />}
                    to="/app/subscription/offers"
                    variant="button-primary"
                >
                    <FormattedMessage defaultMessage="Mettre à niveau mon offre" />
                </RouterLink>
            </EmptyState>
        );
    }

    if (campaigns?.campaigns.length === 0) {
        const handleAddCampaign = () => {
            addCampaign.mutate(
                {
                    showId,
                    campaign: {
                        showId,
                        type: 'preroll',
                        name: intl.formatMessage({ defaultMessage: 'Nouvelle campagne' }),
                        startsAt: new Date(),
                        endsAt: dayjs().add(1, 'd').toDate(),
                    },
                },
                {
                    onSuccess: (campaign) => {
                        history.push(`/app/show/${showId}/monetize/manual/${campaign.id}`);
                        queryClient.invalidateQueries({ queryKey: campaignKeys.allLists() });
                    },
                },
            );
        };

        return (
            <EmptyState
                illustration={noCampaignMainIllustration}
                illustrationType="video"
                illustrationAlign="right"
                illustrationBackground="--gradient-sunny"
            >
                <HeadIllustrationWrapper>
                    <HeadIllustration src={noCampaignHeadIllustration} />
                </HeadIllustrationWrapper>
                <LabelWrapper>
                    <Text variant="heading" fontWeight="--fw-bold">
                        <FormattedMessage defaultMessage="Créez votre première campagne" />
                    </Text>
                    <Text variant="body" fontWeight="--fw-normal" color="--neutral500">
                        <FormattedMessage defaultMessage="Gérez vos annonces publicitaires sur les épisodes de votre podcast 💰" />
                    </Text>
                </LabelWrapper>
                <Button
                    startIcon={<AddIcon icon={icon({ name: 'add', style: 'solid' })} />}
                    onPress={handleAddCampaign}
                >
                    <FormattedMessage defaultMessage="Créer une campagne" />
                </Button>
            </EmptyState>
        );
    }

    return (
        <Wrapper>
            <Header
                search={search}
                onSearchChange={handleSearchChange}
                campaignsCount={campaigns?.pagination?.total ?? 0}
            />

            {campaignsAreLoading ? (
                <LoadingWrapper>
                    <FontAwesomeIcon icon={icon({ name: 'spinner-third', style: 'solid' })} spin />
                </LoadingWrapper>
            ) : (
                <ListWrapper>
                    <SelectionToolbar
                        hasSeveralPages={(campaigns?.pagination?.totalPages ?? 0) > 1}
                        totalCampaignCount={campaigns?.pagination?.total || 0}
                        campaignsPerPage={campaigns?.pagination?.count || 0}
                    />
                    <Campaigns campaigns={campaigns?.campaigns ?? []} />
                </ListWrapper>
            )}
            {campaigns?.pagination &&
                campaigns?.campaigns.length > 0 &&
                campaigns?.pagination.totalPages > 1 && (
                    <ServerPagination
                        pagination={campaigns.pagination}
                        onGoToPage={(page) => setPage(page)}
                        onGoToFirstPage={() => setPage(1)}
                        onGoToLastPage={() => setPage(campaigns?.pagination.totalPages)}
                        onGoToPreviousPage={() => setPage(campaigns?.pagination.currentPage - 1)}
                        onGoToNextPage={() => setPage(campaigns?.pagination.currentPage + 1)}
                        onChangePerPage={(perPage) => setPerPage(perPage)}
                        options={{
                            goToFirstAndLastPageButtons: false,
                            showPaginationInfo: true,
                            showPageSelector: false,
                        }}
                    />
                )}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`;
const LoadingWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary);
    padding-block: 6rem;
`;
const ListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
`;
const HeadIllustrationWrapper = styled.div`
    width: 200px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    & > * {
        text-align: center;
    }
`;
const RocketIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const HeadIllustration = styled.img`
    min-width: 248px;
`;
const AddIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;

export default ManualCampaignsPage;
