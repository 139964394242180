import { useEffect } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import Stack from '@ui/layout/Stack';
import Flex from '@ui/layout/Flex';
import { DeprecatedText } from '@ui/atoms/DeprecatedText';
import Cluster from '@ui/layout/Cluster';
import Spinner from '@ui/atoms/Spinner';
import { useSubscribe } from '@hooks/useSubscribe';
import { connect } from '@app/decorators/connect';
import Text from '@ui/atoms/Text';

const EpisodeCover = styled.img.attrs({
    loading: 'lazy',
})`
    display: block;
    max-height: 1.5rem;
    max-width: 1.5rem;
    border-radius: var(--r-xs);
`;

const ClipFormatEpisode = ({ episode }) => (
    <Cluster $gap="0.5rem" $align="center" $wrap="nowrap">
        {episode.imageUrl && <EpisodeCover src={episode.imageUrl} />}
        <DeprecatedText color="--neutral500" ellipsis>
            {episode.name}
        </DeprecatedText>
    </Cluster>
);

const enhance = connect(({ clipStore }) => ({
    fetchClipQuota: clipStore.fetchClipQuota,
}));

const LoadingClipFormat = ({ clipFormat, episode, fetchClipQuota }) => {
    const { showId } = useParams();
    // Fetch current clip every 5 seconds
    useSubscribe(
        () => {
            clipFormat.refresh();
        },
        [clipFormat],
        5000,
    );
    // Fetch clips quota after clip generation
    useEffect(
        () => () => {
            fetchClipQuota(showId);
        },
        [showId],
    );

    return (
        <Stack $gap="0.75rem">
            <Flex
                $pos="relative"
                $minH={152}
                $px="0.625rem"
                $py="1rem"
                $align="center"
                $justify="center"
                $bgColor="--neutral50"
                $borderRadius="--r-xs"
                $overflow="hidden"
            >
                <Stack $gap="0.5rem" $align="center">
                    <Spinner color="--neutral500" size={24} />
                    <DeprecatedText color="--neutral500" align="center">
                        <FormattedMessage defaultMessage="En cours de génération…" />
                    </DeprecatedText>
                </Stack>
            </Flex>
            <Stack $gap="0.25rem">
                <DeprecatedText weight="semibold">
                    <Text numberOfLines={1} fontWeight="--fw-semibold">
                        {clipFormat.clip.adminTitle || clipFormat.clip.title}
                    </Text>
                </DeprecatedText>
                {episode && <ClipFormatEpisode episode={episode} />}
            </Stack>
        </Stack>
    );
};

export default enhance(LoadingClipFormat);
