import { useEffect } from 'react';
import { connect } from '@/components/legacy/connect';

const enhance = connect(({ authStore, routerStore }) => ({
    onMount: () => authStore.loginAs(routerStore.query.t),
}));

function LoginAsPage({ onMount }) {
    useEffect(() => {
        onMount();
    }, []);
    return null;
}

export default enhance(LoginAsPage);
