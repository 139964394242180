import styled from 'styled-components';
import Modal from '@ui/atoms/Modal';
import Text from '@/components/ui/atoms/Text';
import IconButton from '@/components/IconButton';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { AICustomPromptFormSchema } from '@/components/EpisodeAI/EpisodeAICustomPrompt/EpisodeAICustomPromptForm/useAICustomPromptFormSchema.hook';
import EpisodeAICustomPromptForm from '@/components/EpisodeAI/EpisodeAICustomPrompt/EpisodeAICustomPromptForm';

interface SocialMediaAICustomPromptModalProps {
    isOpen: boolean;
    onOpenChange: (open: boolean) => void;
    settings: AICustomPromptFormSchema;
    onSubmit: (data: AICustomPromptFormSchema) => void;
}
const SocialMediaAICustomPromptModal = ({
    isOpen,
    onOpenChange,
    settings,
    onSubmit,
}: SocialMediaAICustomPromptModalProps) => {
    const onClose = () => onOpenChange(false);

    return (
        <AIModal variant="ai" size="medium" isOpen={isOpen} onOpenChange={onOpenChange}>
            <Header>
                <HeaderText>
                    <Text variant="headingM" fontWeight="--fw-bold">
                        <FormattedMessage defaultMessage="Définir les préférences de AI pour le post" />
                    </Text>
                    <Text color="--neutral500">
                        <FormattedMessage defaultMessage="Personnalisez la façon dont AI génère les posts de vos épisodes en lui donnant des instructions spécifiques." />
                    </Text>
                </HeaderText>
                <CloseButton
                    icon={<CloseIcon icon={icon({ name: 'xmark-large', style: 'solid' })} />}
                    onPress={onClose}
                    variant="ghost"
                    aria-label="Close modal"
                />
            </Header>
            <EpisodeAICustomPromptForm
                onClose={onClose}
                onSubmit={onSubmit}
                type="smm"
                values={settings}
                isFreeTrial={false}
            />
        </AIModal>
    );
};

const AIModal = styled(Modal)`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    padding: 1.5rem;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 2rem;
`;
const HeaderText = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
`;
const CloseButton = styled(IconButton)`
    align-self: flex-start;
    padding: 0;
`;
const CloseIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--neutral500);
`;

export default SocialMediaAICustomPromptModal;
