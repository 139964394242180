import { FormattedMessage } from 'react-intl';
import EmptyState from '@ui/molecules/EmptyState';
import Text from '@ui/atoms/Text';
import Link from '@ui/atoms/Link';
import noEpisodeMainIllustration from '@public/images/empty-state/illustration/calendarView.mp4';
import ZebraIllustration from '@ui/atoms/ZebraIllustration';
import controlKnobs from '@public/images/emoji/control_knobs.png';
import useInternalRouteMapping from '@/utils/hooks/useInternalRouteMapping.hook';
import styled from 'styled-components';

const NoEpisodeEmptyState = () => {
    const episodeLink = useInternalRouteMapping().link('menu.episodes');
    return (
        <EmptyState illustration={noEpisodeMainIllustration} illustrationType="video">
            <HeadIllustrationWrapper>
                <ZebraIllustration foreground={controlKnobs} />
            </HeadIllustrationWrapper>
            <LabelWrapper>
                <Text variant="heading" fontWeight="--fw-bold">
                    <FormattedMessage defaultMessage="Commencez par ajouter votre premier épisode" />
                </Text>
                <Text variant="body" fontWeight="--fw-normal" color="--neutral500">
                    <FormattedMessage
                        defaultMessage="Débloquez le partage sur les réseaux sociaux en <link>créant votre premier épisode</link> 🔥"
                        values={{
                            link: (chunks) => (
                                <Text
                                    as={Link}
                                    to={episodeLink}
                                    color="--primary"
                                    fontWeight="--fw-bold"
                                >
                                    {chunks}
                                </Text>
                            ),
                        }}
                    />
                </Text>
            </LabelWrapper>
        </EmptyState>
    );
};

const HeadIllustrationWrapper = styled.div`
    width: 200px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    & > * {
        text-align: center;
    }
`;

export default NoEpisodeEmptyState;
