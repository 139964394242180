import { css } from 'styled-components';
import PropTypes from 'prop-types';
import { computeCssValue } from '../../../../shared/utils/css';

/**
 * See https://chakra-ui.com/docs/features/style-props
 */
const layoutMixin = css`
    width: ${(props) => computeCssValue(props.$width ?? props.$w)};
    height: ${(props) => computeCssValue(props.$height ?? props.$h)};
    min-width: ${(props) => computeCssValue(props.$minWidth ?? props.$minW)};
    max-width: ${(props) => computeCssValue(props.$maxWidth ?? props.$maxW)};
    min-height: ${(props) => computeCssValue(props.$minHeight ?? props.$minH)};
    max-height: ${(props) => computeCssValue(props.$maxHeight ?? props.$maxH)};
    display: ${(props) => props.$display ?? props.$d};
    ${(props) =>
        props.$boxSize !== null &&
        props.$boxSize !== undefined &&
        css`
            width: ${computeCssValue(props.$boxSize)};
            height: ${computeCssValue(props.$boxSize)};
        `}
    vertical-align: ${(props) => props.$verticalAlign};
    overflow: ${(props) => props.$overflow};
    overflow-x: ${(props) => props.$overflowX};
    overflow-y: ${(props) => props.$overflowY};
    box-sizing: ${(props) => props.$boxSizing};
    float: ${(props) => props.$float};
    object-fit: ${(props) => props.$objectFit};
    object-position: ${(props) => props.$objectPosition};
    visibility: ${(props) => props.$visibility};
    isolation: ${(props) => props.$isolation};
`;

export const layoutPropTypes = {
    $width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $w: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Shortcut for width
    $height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $h: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Shortcut for height
    $minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $minW: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Shortcut for minWidth
    $maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $maxW: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Shortcut for maxWidth
    $minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $minH: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Shortcut for minHeight
    $maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $maxH: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Shortcut for maxHeight
    $display: PropTypes.string,
    $d: PropTypes.string, // Shortcut for display
    $boxSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $verticalAlign: PropTypes.string,
    $overflow: PropTypes.string,
    $overflowX: PropTypes.string,
    $overflowY: PropTypes.string,
    $boxSizing: PropTypes.string,
    $float: PropTypes.string,
    $objectFit: PropTypes.string,
    $objectPosition: PropTypes.string,
    $visibility: PropTypes.string,
    $isolation: PropTypes.string,
};

export default layoutMixin;
