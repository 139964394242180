import Stack from '@ui/layout/Stack';
import { FormattedMessage, useIntl } from 'react-intl';
import { InputTextController } from '@ui/atoms/InputText';
import useLoginFormSchema, { LoginForm } from './useLoginFormSchema.hook';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import Button from '@/components/Button';
import RouterLink from '@/components/Link/RouterLink';
import useLoginMutation from '@queries/auth/useLoginMutation.hook';
import useQuery from '@/shared/hooks/useQuery';
import { AxiosError } from 'axios';
import styled from 'styled-components';

const Form = () => {
    const intl = useIntl();
    const params = useQuery();
    const schema = useLoginFormSchema();
    const loginMutation = useLoginMutation();

    const isInvitation = params.get('email') && params.get('show') && params.get('token');
    const defaultEmail = params.get('email');
    // Replace spaces by + in email to avoid issues with email validation
    const safeDefaultEmail = defaultEmail?.replace(/ /g, '+');

    const { control, handleSubmit, formState, setError } = useForm({
        defaultValues: {
            email: safeDefaultEmail || '',
            password: '',
        },
        resolver: zodResolver(schema),
    });

    const onSubmit = (payload: LoginForm) => {
        loginMutation.mutate(
            {
                formData: payload,
                invitationToken: params.get('token'),
                invitationShow: params.get('show'),
            },
            {
                onError: (error) => {
                    const typedError = error as AxiosError;
                    if (typedError.response?.status === 422) {
                        setError('email', {
                            type: '422',
                            message: intl.formatMessage({
                                defaultMessage:
                                    'Les identifiants que vous avez rentrés sont incorrects',
                            }),
                        });
                    }
                },
            },
        );
    };

    return (
        <FormElement onSubmit={handleSubmit(onSubmit)}>
            <Stack $gap="2rem">
                <InputTextController
                    control={control}
                    name="email"
                    label={<FormattedMessage defaultMessage="Adresse e-mail" />}
                    placeholder={intl.formatMessage({
                        defaultMessage: 'thomas.pesquet@esa.com',
                    })}
                    errorMessage={formState.errors.email?.message}
                    isReadOnly={!!isInvitation}
                    data-testid="login-email-input"
                    autoFocus
                />
                <InputTextController
                    control={control}
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    label={<FormattedMessage defaultMessage="Mot de passe" />}
                    placeholder={intl.formatMessage({ defaultMessage: '8 caractères minimum' })}
                    errorMessage={formState.errors.password?.message}
                    data-testid="login-password-input"
                    customButton={
                        <StyledLink to="/password-recovery" tabIndex={-1}>
                            <FormattedMessage defaultMessage="Mot de passe oublié ?" />
                        </StyledLink>
                    }
                />
                <Button
                    type="submit"
                    isLoading={loginMutation.isLoading}
                    data-testid="login-submit"
                >
                    <FormattedMessage defaultMessage="Se connecter" />
                </Button>
            </Stack>
        </FormElement>
    );
};

const FormElement = styled.form`
    display: flex;
    flex-direction: column;
`;
const StyledLink = styled(RouterLink)`
    color: var(--primary);
    text-decoration: none;
    font-weight: var(--fw-semibold);

    &:hover {
        text-decoration: underline;
    }
`;

export default Form;
