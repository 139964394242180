import { useQuery } from '@tanstack/react-query';
import { fetchCalendarCommunicationEvents } from '@/api';
import socialNetworkPostKeys from '@/queries/socialNetworkPost/socialNetworkPostKeys';
import camelcaseKeys from 'camelcase-keys';

const transform = (data) => {
    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const queryFn = async ({ queryKey: [{ showId, startDate, endDate }] }) => {
    const { data } = await fetchCalendarCommunicationEvents(showId, startDate, endDate);

    return transform(data);
};

const useSocialNetworkCalendarPostQuery = ({ showId, startDate, endDate }) => {
    return useQuery({
        queryKey: socialNetworkPostKeys.listCalendar({ showId, startDate, endDate }),
        queryFn,
        enabled: !!showId && !!startDate && !!endDate,
    });
};

export default useSocialNetworkCalendarPostQuery;
