import { useState } from 'react';
import add from '@public/icons/add.svg';
import { FormattedMessage } from 'react-intl';
import DeprecatedIcon from '@ui/atoms/DeprecatedIcon';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import EditPlaylistModal from '../organisms/EditPlaylistModal';
import Text from '@ui/atoms/Text';
import { useOverlayTriggerState } from 'react-stately';

function NewPlaylistButton() {
    const [hovered, onHover] = useState(false);
    const editPlaylistModal = useOverlayTriggerState({});

    return (
        <>
            {editPlaylistModal.isOpen && (
                <EditPlaylistModal
                    isOpen={editPlaylistModal.isOpen}
                    onClose={editPlaylistModal.close}
                />
            )}
            <DeprecatedPaper
                height={75}
                px={15}
                background={hovered ? '--primary50' : 'white'}
                onMouseEnter={() => onHover(true)}
                onMouseLeave={() => onHover(false)}
                align="center"
                onClick={editPlaylistModal.open}
                flex
            >
                <DeprecatedPaper
                    w={50}
                    height={50}
                    background={hovered ? undefined : '--primary50'}
                    justify="center"
                    align="center"
                    mr={10}
                    flex
                    rounded
                >
                    <DeprecatedIcon as={add} color="--primary" size={14} />
                </DeprecatedPaper>
                <Text fontWeight="--fw-semibold" color="--primary">
                    <FormattedMessage defaultMessage="Nouvelle playlist" />
                </Text>
            </DeprecatedPaper>
        </>
    );
}

export default NewPlaylistButton;
