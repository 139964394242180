import styled, { css, keyframes } from 'styled-components';
import Stack from '../ui/layout/Stack';

const NonHostingEpisodePreviewSkeleton = () => {
    return (
        <Wrapper>
            <Header>
                <TitleWrapper>
                    <Circle $w="2rem" $h="2rem" />
                    <Line $w="10rem" $h="1rem" />
                </TitleWrapper>
                <ButtonBlock />
            </Header>
            <EpisodeDetails>
                <EpisodeCoverWrapper>
                    <ImageBlock />
                    <MobileEpisodeDate>
                        <Block $w="1rem" $h="1rem" />
                        <Line $w="5rem" $h="0.5rem" />
                    </MobileEpisodeDate>
                </EpisodeCoverWrapper>
                <EpisodeDetailsInner>
                    <DesktopEpisodeDate>
                        <Block $w="1rem" $h="1rem" />
                        <Line $w="5rem" $h="0.5rem" />
                    </DesktopEpisodeDate>
                    <Stack $gap="1rem">
                        <Line $w="100%" $h="0.5rem" />
                        <Line $w="100%" $h="0.5rem" />
                        <Line $w="70%" $h="0.5rem" />
                        <Line $w="100%" $h="0.5rem" />
                        <Line $w="100%" $h="0.5rem" />
                        <Line $w="80%" $h="0.5rem" />
                        <Line $w="100%" $h="0.5rem" />
                    </Stack>
                    <EpisodeTags>
                        <Line $w="3rem" $h="1rem" />
                        <Line $w="4.5rem" $h="1rem" />
                        <Line $w="3.5rem" $h="1rem" />
                    </EpisodeTags>
                </EpisodeDetailsInner>
            </EpisodeDetails>
        </Wrapper>
    );
};

const shimmer = keyframes`
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
`;
const shimmering = css`
    background: var(--neutral50);
    background-image: linear-gradient(
        to right,
        var(--neutral50) 0%,
        #edeef1 20%,
        var(--neutral50) 40%,
        var(--neutral50) 100%
    );
    background-repeat: no-repeat;
    background-size: 50rem 6.5rem;
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${shimmer};
    animation-timing-function: linear;
`;
const Line = styled.div<{ $w?: string; $h?: string; $maxW?: string }>`
    border-radius: var(--r-full);
    background-color: var(--neutral100);
    ${({ $w }) => $w && `width: ${$w};`}
    ${({ $h }) => $h && `height: ${$h};`}
    ${({ $maxW }) => $maxW && `max-width: ${$maxW};`}
    ${shimmering};
`;
const Block = styled.div<{ $w?: string; $h?: string }>`
    border-radius: var(--r-s);
    background-color: var(--neutral100);
    ${({ $w }) => $w && `width: ${$w};`}
    ${({ $h }) => $h && `height: ${$h};`}
    ${shimmering};
`;
const Circle = styled(Block)`
    border-radius: var(--r-full);
`;
const ImageBlock = styled(Block)`
    width: 6rem;
    height: 6rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        width: 17.5rem;
        height: 17.5rem;
    }
`;
const Wrapper = styled.div`
    width: 100%;
    max-width: var(--large-container-width);
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
`;
const Header = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        column-gap: 1rem;
    }
`;
const TitleWrapper = styled.div`
    display: flex;
    column-gap: 0.5rem;
    align-items: center;
`;
const EpisodeDetails = styled.div`
    background-color: var(--white);
    padding: 2rem;
    border-radius: var(--r-l);
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
        column-gap: 1.5rem;
    }
`;
const EpisodeDate = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.25rem;
    color: var(--neutral500);
`;
const MobileEpisodeDate = styled(EpisodeDate)`
    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: none;
    }
`;
const DesktopEpisodeDate = styled(EpisodeDate)`
    display: none;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: flex;
    }
`;
const EpisodeCoverWrapper = styled.div`
    display: flex;
    align-items: start;
    column-gap: 1rem;
    flex-shrink: 0;
`;
const EpisodeTags = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
`;
const EpisodeDetailsInner = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-grow: 1;
    }
`;
const ButtonBlock = styled(Block)`
    width: 100%;
    height: 2.5rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        width: 8rem;
    }
`;

export default NonHostingEpisodePreviewSkeleton;
