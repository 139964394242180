import { FormattedMessage } from 'react-intl';
import EpisodesNav from '../EpisodesNav';
import styled from 'styled-components';
import { useLocation } from 'react-router';

interface EpisodesLayoutProps {
    children: React.ReactNode;
}

const EpisodesLayout = ({ children }: EpisodesLayoutProps) => {
    const location = useLocation();

    return (
        <EpisodesLayoutWrapper>
            <InnerWrapper>
                <Sidebar>
                    <Title data-testid="episodes-layout-title">
                        {location.pathname.includes('playlist') ? (
                            <FormattedMessage defaultMessage="Playlists" />
                        ) : (
                            <FormattedMessage defaultMessage="Episodes" />
                        )}
                    </Title>
                    <EpisodesNav />
                </Sidebar>
                <ContentWrapper>{children}</ContentWrapper>
            </InnerWrapper>
        </EpisodesLayoutWrapper>
    );
};
const EpisodesLayoutWrapper = styled.div`
    width: 100%;
    padding-inline: var(--container-padding-mobile);
    margin-block: 2rem;
    display: flex;
    justify-content: center;

    ${({ theme }) => theme.mediaQueries.desktopXLAndUp} {
        padding-inline: var(--container-padding);
    }
`;
const InnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    max-width: var(--container-width);
    width: 100%;

    ${({ theme }) => theme.mediaQueries.desktopXLAndUp} {
        flex-direction: row;
    }
`;
const ContentWrapper = styled.div`
    display: flex;
    height: max-content;
    flex-direction: column;
    gap: 1rem;
    flex-grow: 1;
    min-width: 0;
`;
const Sidebar = styled.aside`
    display: flex;
    flex-direction: column;
    height: fit-content;
    gap: 1rem;

    ${({ theme }) => theme.mediaQueries.desktopXLAndUp} {
        width: 15rem;
        gap: 2rem;
        position: sticky;
        top: 8.5rem;
        left: 0;
        flex-shrink: 0;
    }
`;
const Title = styled.div`
    font-weight: var(--fw-bold);
    font-size: var(--fs-heading-l);
`;

export default EpisodesLayout;
