import { cloneElement } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const FullPageLayout = ({ header, children, footer }) => {
    return (
        <FullPageLayoutWrapper>
            <Header>{cloneElement(header, { noBackground: true })}</Header>
            <Content>{children}</Content>
            <Footer>{footer}</Footer>
        </FullPageLayoutWrapper>
    );
};

FullPageLayout.propTypes = {
    header: PropTypes.node,
    children: PropTypes.node,
    footer: PropTypes.node,
};

FullPageLayout.defaultProps = {
    header: null,
    children: null,
    footer: null,
};

const FullPageLayoutWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100%;
    background: var(--gradient-primary);
`;

const Header = styled.header``;

const Content = styled.div`
    height: 100%;
`;

const Footer = styled.footer``;

export default FullPageLayout;
