import { useState } from 'react';
import { Episode } from '@/queries/episode/useEpisodeQuery.hook';
import { useFormContext } from 'react-hook-form';
import { addQueryParam } from '@/shared/utils/url';
import { useAuthContext } from '@/context/AuthContext';
import AudioWaveform from './AudioWaveform';
import styled from 'styled-components';
import IconButton from '@/components/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Cluster from '@/components/ui/layout/Cluster';
import { formatTime } from '@/shared/utils/time';
import Text from '@/components/ui/atoms/Text';
import { InputDurationController } from '@/components/ui/atoms/InputDuration';
import { FormattedMessage } from 'react-intl';
import Stack from '@/components/ui/layout/Stack';
import Button from '@/components/Button';
import { ClipFormSchema } from '../../schemas/useFormSchema';
import { CLIP_MAX_LENGTH, CLIP_MIN_LENGTH } from '@/shared/config/constants';

interface ClipAudioSampleFormProps {
    episode: Episode;
}

const ClipAudioSampleForm = ({ episode }: ClipAudioSampleFormProps) => {
    const { accessToken } = useAuthContext();
    const form = useFormContext<ClipFormSchema>();
    const { start, end, duration } = form.watch('audioSample');

    const [isPlaying, setIsPlaying] = useState(false);
    const [goBackToStart, setGoBackToStart] = useState(false);
    const [cursorPosition, setCursorPosition] = useState<number>(start);
    const [cursorPositionHasChanged, setCursorPositionHasChanged] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [fileDuration, setFileDuration] = useState();

    const handlePlayPause = () => {
        setIsPlaying((isPlaying) => !isPlaying);
    };

    const handleGoBackToStart = () => {
        setGoBackToStart(true);
        if (!isPlaying) {
            setIsPlaying(true);
        }
        setGoBackToStart(false);
    };

    const handleDurationChange = (newDuration: number) => {
        form.setValue('audioSample.end', start + newDuration, {
            shouldDirty: true,
            shouldValidate: true,
        });
    };

    const handleRegionUpdate = (newStart: number, newEnd: number) => {
        form.setValue('audioSample.start', newStart, { shouldDirty: true, shouldValidate: true });
        form.setValue('audioSample.end', newEnd, { shouldDirty: true, shouldValidate: true });
        form.setValue('audioSample.duration', newEnd - newStart, {
            shouldDirty: true,
            shouldValidate: true,
        });
    };

    const handleStartUpdateWithCurrentTime = () => {
        const newStart = currentTime;
        let newEnd;

        if (end - newStart > CLIP_MAX_LENGTH) {
            newEnd = newStart + CLIP_MAX_LENGTH;
        } else if (newStart > end) {
            newEnd = Math.min(episode.duration, newStart + duration);
        }

        form.setValue('audioSample.start', newStart, {
            shouldDirty: true,
            shouldValidate: true,
        });

        if (newEnd) {
            form.setValue('audioSample.end', newEnd, { shouldDirty: true, shouldValidate: true });
        }

        form.setValue('audioSample.duration', (newEnd ?? end) - newStart, {
            shouldDirty: true,
            shouldValidate: true,
        });
    };

    const handleEndUpdateWithCurrentTime = () => {
        const newEnd = currentTime;
        let newStart;

        if (newEnd - start > CLIP_MAX_LENGTH) {
            newStart = newEnd - CLIP_MAX_LENGTH;
        } else if (newEnd < start) {
            newStart = Math.max(0, newEnd - duration);
        }

        form.setValue('audioSample.end', newEnd, { shouldDirty: true, shouldValidate: true });

        if (newStart) {
            form.setValue('audioSample.start', newStart, {
                shouldDirty: true,
                shouldValidate: true,
            });
        }

        form.setValue('audioSample.duration', newEnd - (newStart ?? start), {
            shouldDirty: true,
            shouldValidate: true,
        });
    };

    const handlePlayOnTimeCode = (timecode: number) => {
        setCursorPosition(timecode);
        setCursorPositionHasChanged((prev) => !prev);
        if (!isPlaying) {
            setIsPlaying(true);
        }
    };

    return (
        <Form>
            {/* TODO: Type Waveform */}
            {/* @ts-ignore */}
            <AudioWaveform
                audioUrl={addQueryParam(episode.audioUrlInternal, 'token', accessToken)}
                waveformDataUrl={episode.waveformUrl}
                isPlaying={isPlaying}
                onGoBackToStart={goBackToStart}
                duration={fileDuration}
                onDurationChange={setFileDuration}
                onCurrentTimeChange={setCurrentTime}
                cursorPosition={cursorPosition}
                cursorPositionHasChanged={cursorPositionHasChanged}
                regionStart={start}
                regionEnd={end}
                onRegionChange={handleRegionUpdate}
            />
            <ControlsAndInputs>
                <Controls>
                    <PlayPauseButton
                        aria-label={isPlaying ? 'Pause' : 'Play'}
                        icon={
                            isPlaying ? (
                                <PlayPauseIcon icon={icon({ name: 'pause', style: 'solid' })} />
                            ) : (
                                <PlayPauseIcon icon={icon({ name: 'play', style: 'solid' })} />
                            )
                        }
                        onPress={handlePlayPause}
                    />
                    <GoBackToStartButton
                        variant="tertiary"
                        aria-label="Go back to start"
                        icon={
                            <GoBackToStartIcon
                                icon={icon({ name: 'backward-step', style: 'solid' })}
                            />
                        }
                        onPress={handleGoBackToStart}
                    />
                    <Cluster $gap="0.5rem">
                        <TimeCodeText>{formatTime(currentTime, 'milliseconds')}</TimeCodeText>
                        <TimeCodeText>/</TimeCodeText>
                        <TimeCodeText>{formatTime(episode.duration, 'milliseconds')}</TimeCodeText>
                    </Cluster>
                </Controls>
                <Inputs>
                    <Stack $gap="0.5rem">
                        <InputDurationController
                            name="audioSample.start"
                            control={form.control}
                            label={<FormattedMessage defaultMessage="Début" />}
                            max={episode.duration}
                            startIcon={
                                <InputPlayPauseButton
                                    onPress={() => handlePlayOnTimeCode(start)}
                                    variant="ghost"
                                    aria-label="Play"
                                    icon={
                                        <InputPlayPauseIcon
                                            icon={icon({
                                                name: 'play',
                                                style: 'solid',
                                            })}
                                        />
                                    }
                                />
                            }
                            showMilliseconds
                        />
                        <Button
                            onPress={handleStartUpdateWithCurrentTime}
                            variant="link-primary"
                            startIcon={
                                <SetterIcon icon={icon({ name: 'stopwatch', style: 'regular' })} />
                            }
                        >
                            <FormattedMessage defaultMessage="Définir" />
                        </Button>
                    </Stack>
                    <Stack $gap="0.5rem">
                        <InputDurationController
                            name="audioSample.end"
                            control={form.control}
                            label={<FormattedMessage defaultMessage="Termine" />}
                            max={CLIP_MAX_LENGTH}
                            min={CLIP_MIN_LENGTH}
                            startIcon={
                                <InputPlayPauseButton
                                    onPress={() => handlePlayOnTimeCode(end)}
                                    variant="ghost"
                                    aria-label="Play"
                                    icon={
                                        <InputPlayPauseIcon
                                            icon={icon({
                                                name: 'play',
                                                style: 'solid',
                                            })}
                                        />
                                    }
                                />
                            }
                            showMilliseconds
                        />
                        <Button
                            onPress={handleEndUpdateWithCurrentTime}
                            variant="link-primary"
                            startIcon={
                                <SetterIcon icon={icon({ name: 'stopwatch', style: 'regular' })} />
                            }
                        >
                            <FormattedMessage defaultMessage="Définir" />
                        </Button>
                    </Stack>
                    <InputDurationController
                        name="audioSample.duration"
                        control={form.control}
                        label={<FormattedMessage defaultMessage="Durée totale" />}
                        max={CLIP_MAX_LENGTH}
                        onChange={handleDurationChange}
                        startIcon={
                            <ClockIcon
                                icon={icon({
                                    name: 'clock',
                                    style: 'regular',
                                })}
                            />
                        }
                        showMilliseconds
                    />
                </Inputs>
            </ControlsAndInputs>
        </Form>
    );
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    width: 100%;
`;
const PlayPauseButton = styled(IconButton)`
    border-radius: var(--r-full);
    padding: 0.625rem;
    width: 2.5rem;
    height: 2.5rem;
`;
const PlayPauseIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const GoBackToStartIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const GoBackToStartButton = styled(IconButton)`
    border-radius: var(--r-full);
    width: 2rem;
    height: 2rem;
    padding: 0.375rem;
`;
const TimeCodeText = styled(Text)`
    font-variant-numeric: tabular-nums;
    color: var(--neutral500);
`;
const Controls = styled.div`
    display: flex;
    align-items: center;
    column-gap: 1rem;
`;
const ControlsAndInputs = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        column-gap: 1.5rem;
    }
`;
const Inputs = styled.div`
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
`;
const InputPlayPauseButton = styled(IconButton)`
    padding: 0;
`;
const InputPlayPauseIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--primary500);
`;
const ClockIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--neutral500);
`;
const SetterIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
`;

export default ClipAudioSampleForm;
