import { useQuery } from '@tanstack/react-query';
import { fetchNewsletterQuota } from '@/api';
import quotaKeys from '@/queries/quota/quotaKeys';
import camelcaseKeys from 'camelcase-keys';
import { z } from 'zod';

export const QuotaSchema = z.object({
    current: z.number(),
    remaining: z.number(),
    maximum: z.number(),
    minimum: z.number(),
    step: z.number(),
    total: z.number(),
});
export type Quota = z.infer<typeof QuotaSchema>;

const queryFn = async () => {
    const { data } = await fetchNewsletterQuota();
    // TODO: Do camelcase transformation in Axios interceptor
    const transformedData = camelcaseKeys(data, { deep: true });

    const formattedData = {
        current: transformedData.total,
        maximum: transformedData.maximum,
        remaining: Math.max(transformedData.maximum - transformedData.total, 0),
        ...transformedData,
    };

    return QuotaSchema.parse(formattedData);
};

const useNewsletterQuotaQuery = () => {
    return useQuery({
        queryKey: quotaKeys.detailByType('NEWSLETTER'),
        queryFn,
    });
};

export default useNewsletterQuotaQuery;
