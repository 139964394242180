import { useQuery } from '@tanstack/react-query';
import { fetchTranscriptQuota } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import { QUOTA_TYPES } from '@/shared/config/constants';
import quotaKeys from './quotaKeys';
import { z } from 'zod';

export const TranscriptQuotaSchema = z.object({
    maximum: z.number(),
    minimum: z.number(),
    step: z.number(),
    total: z.number(),
});

export type TranscriptQuota = z.infer<typeof TranscriptQuotaSchema>;

const queryFn = async () => {
    const { data } = await fetchTranscriptQuota();

    return TranscriptQuotaSchema.parse(
        camelcaseKeys(
            {
                maximum: data.maximum / 60,
                minimum: data.minimum / 60,
                step: data.step / 60,
                total: data.total / 60,
            },
            { deep: true },
        ),
    );
};

const useTranscriptQuotaQuery = () => {
    return useQuery({
        queryKey: quotaKeys.detailByType(QUOTA_TYPES.TRANSCRIPT as keyof typeof QUOTA_TYPES),
        queryFn,
        staleTime: 0,
    });
};

export default useTranscriptQuotaQuery;
