import { Menu, MenuItem, MenuTrigger, Popover } from 'react-aria-components';
import RouterLink from '@/components/Link/RouterLink';
import Button from '@/components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router';

interface NavItem {
    route: string;
    icon: React.ReactNode;
    label: React.ReactNode;
}

interface MobileNavProps {
    navItems: NavItem[];
}

const MobileNav = ({ navItems }: MobileNavProps) => {
    const history = useHistory();
    const urlScope = (item: NavItem): string => {
        return item.route.split('/')[item.route.split('/').length - 1];
    };
    const activeItem = navItems.find((item) => window.location.pathname.includes(urlScope(item)));
    return (
        <MobileNavWrapper>
            <MenuTrigger>
                <NavButton
                    variant="ghost"
                    startIcon={activeItem?.icon}
                    endIcon={
                        <FontAwesomeIcon icon={icon({ name: 'chevron-down', style: 'solid' })} />
                    }
                    aria-label="Menu"
                >
                    {activeItem?.label}
                </NavButton>
                <StyledPopover>
                    <Menu>
                        {navItems
                            .filter((item) => !window.location.pathname.includes(urlScope(item)))
                            .map((item, index) => (
                                <StyledMenuItem
                                    key={index}
                                    onAction={() => {
                                        history.push(item.route);
                                    }}
                                >
                                    {item.icon}
                                    <StyledRouterLink
                                        to={item.route}
                                        isActive={location.pathname.includes(urlScope(item))}
                                    >
                                        {item.label}
                                    </StyledRouterLink>
                                </StyledMenuItem>
                            ))}
                    </Menu>
                </StyledPopover>
            </MenuTrigger>
        </MobileNavWrapper>
    );
};

const MobileNavWrapper = styled.nav`
    display: flex;
    align-items: center;

    @media (min-width: 1215px) {
        display: none;
    }
`;
const NavButton = styled(Button)`
    padding: 0;
    height: 2rem;
`;
const StyledPopover = styled(Popover)`
    background-color: var(--white);
    box-shadow: var(--s-light);
    border-radius: var(--r-s);
`;
const StyledMenuItem = styled(MenuItem)`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding-block: 0.75rem;
    padding-inline: 1rem;
`;
const StyledRouterLink = styled(RouterLink)<{ isActive: boolean }>`
    transition-duration: 0.2s;
    color: var(--black);

    ${({ isActive }) =>
        isActive &&
        css`
            color: var(--primary);
        `};

    &:hover {
        transition-duration: 0.2s;
        opacity: 0.8;
    }
`;

export default MobileNav;
