import { FormattedMessage, useIntl } from 'react-intl';
import Text from '@ui/atoms/Text';
import DialogAlert from '@/components/ui/atoms/DialogAlert';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Button from '@/components/Button';
import Alert from '@/components/ui/atoms/Alert';
import useSoundcloudLogin from '@/utils/hooks/useSoundcloudLogin';

interface BroadcastSoundcloudLoginModalProps {
    onSuccess: () => void;
    isOpen: boolean;
    onClose: () => void;
}

const BroadcastSoundcloudLoginModal = ({
    onSuccess,
    isOpen,
    onClose,
}: BroadcastSoundcloudLoginModalProps) => {
    const intl = useIntl();
    const handleLogin = useSoundcloudLogin({ onSuccess, onError: () => onClose() });

    return (
        <DialogAlert
            isOpen={isOpen}
            onOpenChange={onClose}
            action={
                <>
                    <Button onPress={handleLogin}>
                        <FormattedMessage defaultMessage="Diffuser sur SoundCloud" />
                    </Button>
                    <CancelButton variant="ghost" onPress={onClose}>
                        <FormattedMessage defaultMessage="Annuler" />
                    </CancelButton>
                </>
            }
            icon={<ProviderIcon icon={icon({ name: 'soundcloud', style: 'brands' })} />}
            iconBgColor={'var(--soundcloud-light)'}
            title={
                <FormattedMessage defaultMessage="Souhaitez-vous diffuser votre émission sur SoundCloud ?" />
            }
        >
            <SoundcloudAlert
                variant="info"
                icon={<InfoIcon icon={icon({ name: 'circle-info', style: 'solid' })} />}
                description={
                    <Text color="--neutral700" textAlign="left">
                        <FormattedMessage
                            defaultMessage="Seuls vos futurs épisodes seront envoyés, il faudra envoyer vous-même les épisodes publiés avant la diffusion automatique. <link>En savoir plus</link>"
                            values={{
                                link: (chunks: React.ReactNode) => (
                                    <UnderlinedLink
                                        href={intl.formatMessage({
                                            defaultMessage:
                                                'https://help.ausha.co/fr/articles/5512035-comment-diffuser-les-episodes-de-mon-podcast-sur-soundcloud-depuis-ausha#h_cacb64d073',
                                        })}
                                        target="_blank"
                                    >
                                        {chunks}
                                    </UnderlinedLink>
                                ),
                            }}
                        />
                    </Text>
                }
            >
                <Text fontWeight="--fw-semibold" textAlign="left">
                    <FormattedMessage defaultMessage="À propos de la diffusion sur SoundCloud" />
                </Text>
            </SoundcloudAlert>
        </DialogAlert>
    );
};

const ProviderIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--youtube);
`;
const UnderlinedLink = styled.a`
    color: var(--neutral700);
    text-decoration: underline;
    font-size: var(--fs-body-m);
`;
const InfoIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
`;
const SoundcloudAlert = styled(Alert)`
    margin-top: 1.25rem;
`;
const CancelButton = styled(Button)`
    color: var(--neutral500);
`;

export default BroadcastSoundcloudLoginModal;
