import { useQuery } from '@tanstack/react-query';
import { fetchYoutubeCategories } from '@/api';

const useFetchYoutubeCategories = () => {
    return useQuery({
        queryKey: ['youtube.categories.index'],
        queryFn: async () => {
            const { data } = await fetchYoutubeCategories();
            return data;
        },
        staleTime: 1000 * 60 * 20,
    });
};

export default useFetchYoutubeCategories;
