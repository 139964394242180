import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { psoOverview } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import psoKeys from './psoKeys';
import { isNumber } from '@/utils/number';
import { z } from 'zod';

export const OverviewRowSchema = z.object({
    currentPositionApple: z.number(),
    currentPositionSpotify: z.number(),
    isLoading: z.boolean(),
    keyword: z.string(),
    occurrences: z.object({
        podcastsDescriptions: z.number(),
        podcastsTitles: z.number(),
        showDescription: z.number(),
        showTitle: z.number(),
    }),
    previousPositionApple: z.number(),
    previousPositionSpotify: z.number(),
    previousResultsDate: z.string(),
    rankDiffApple: z.number(),
    rankDiffSpotify: z.number(),
    searchVolume: z.number().optional().nullable(), // TODO This will be deprecated soon™️
    searchVolumev2: z.number().optional().nullable(),
    difficultyScore: z.number().optional().nullable(),
    trendApple: z.string(),
    trendSpotify: z.string(),
});
export const OverviewSchema = z.array(OverviewRowSchema);
type InputProps = {
    showId: string;
    currentStore: string;
    doesExport: boolean;
};
type Key = QueryFunctionContext<ReturnType<typeof psoKeys.statsOverview>>;
export type OverviewRow = z.infer<typeof OverviewRowSchema>;

const queryFn = async ({ queryKey: [{ showId, currentStore, doesExport }] }: Key) => {
    const response = await psoOverview(showId, currentStore, doesExport);

    if (doesExport) {
        const formattedResponse = Object.values(response);
        return formattedResponse.slice(0, formattedResponse.length - 1);
    }
    try {
        OverviewSchema.parse(camelcaseKeys(response.data, { deep: true }));
    } catch (error) {
        if (error instanceof z.ZodError) {
            console.log(error.issues);
        }
    }

    const formattedData = response.data.map((row: any) => {
        return {
            ...row,
            searchVolume:
                isNumber(row?.search_volume_v2) || isNumber(row?.search_volume)
                    ? Math.round(row?.search_volume_v2 || row?.search_volume || 0)
                    : null,
            difficultyScore: isNumber(row?.difficulty_score)
                ? Math.round(row?.difficulty_score || 0)
                : null,
        };
    });

    // TODO: Do camelcase transformation in Axios interceptor
    return OverviewSchema.parse(camelcaseKeys(formattedData, { deep: true }));
};

const useOverviewQuery = (
    { showId, currentStore, doesExport = false }: InputProps,
    { enabled } = { enabled: true },
) => {
    return useQuery({
        queryKey: psoKeys.statsOverview(showId, currentStore, doesExport),
        queryFn,
        enabled,
    });
};

export default useOverviewQuery;
