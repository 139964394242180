import PropTypes from 'prop-types';
import TunnelStepper from '../TunnelStepper';
import Text from '@ui/atoms/Text';
import Button from '@/components/Button';
import PsoLanding from '@public/images/empty-state/illustration/pso-landing.png';
import { FormattedMessage } from 'react-intl';
// @ts-ignore
import { IntercomAPI } from 'react-intercom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';

interface ConfigurationErrorProps {
    onRetry: () => void;
}

const ConfigurationError = ({ onRetry }: ConfigurationErrorProps) => {
    return (
        <ConfigurationErrorWrapper>
            <Left>
                <TunnelStepper currentStep={3} stepCount={3} />
                <Content>
                    <IconWrapper>
                        <FontAwesomeIcon
                            icon={icon({
                                name: 'circle-exclamation',
                                style: 'solid',
                            })}
                        />
                    </IconWrapper>
                    <TitleWrapper>
                        <Title>
                            <FormattedMessage defaultMessage="Une erreur est survenue" />
                        </Title>
                        <SubTitle>
                            <FormattedMessage defaultMessage="Veuillez réessayer ultérieurement" />
                        </SubTitle>
                    </TitleWrapper>
                    <ActionWrapper>
                        <Button onPress={onRetry}>
                            <FormattedMessage defaultMessage="Réessayer" />
                        </Button>
                        <ContactWrapper>
                            <FormattedMessage
                                defaultMessage="Besoin d’aide ? <link>Contactez-nous</link>"
                                values={{
                                    link: (chunks: React.ReactNode) => (
                                        <LinkButton
                                            variant="ghost"
                                            onPress={() => IntercomAPI('show')}
                                        >
                                            {chunks}
                                        </LinkButton>
                                    ),
                                }}
                            />
                        </ContactWrapper>
                    </ActionWrapper>
                </Content>
            </Left>
            <IllustrationBlock>
                <IllustrationImage src={PsoLanding} />
            </IllustrationBlock>
        </ConfigurationErrorWrapper>
    );
};

const ConfigurationErrorWrapper = styled.div`
    height: 27rem;
    display: flex;
    gap: 3rem;
    & > * {
        flex: 1;
        align-self: stretch;
    }
`;
const Left = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5rem;
`;
const IllustrationBlock = styled.div`
    border-radius: var(--r-l);
    display: flex;
    max-width: 560px;
    min-width: 50%;
    margin-inline: auto;
    background: var(--gradient-dusk);
    padding-block: 2rem;
    padding-left: 2rem;
    align-items: center;
    justify-content: flex-end;
`;
const IllustrationImage = styled.img`
    border-radius: var(--r-l) 0 0 var(--r-l);
    width: calc(100% - 2rem);
`;
const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
`;
const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    background-color: var(--alert50);
    color: var(--alert);
    border-radius: var(--r-s);
    font-size: 1rem;
`;
const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
`;
const Title = styled(Text)`
    font-size: var(--fs-heading-s);
    font-weight: var(--fw-bold);
`;
const SubTitle = styled(Text)`
    color: var(--neutral500);
`;
const ActionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
`;
const ContactWrapper = styled.div``;
const LinkButton = styled(Button)`
    padding: 0;
`;

ConfigurationError.propTypes = {
    onRetry: PropTypes.func.isRequired,
};

export default ConfigurationError;
