import Button from '@/components/Button';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components';
import useAudioSampleFormSchema from '../schemas/useAudioSampleSchema';
import { Episode } from '@/queries/episode/useEpisodeQuery.hook';
import { useFormContext } from 'react-hook-form';
import { ClipFormSchema } from '../schemas/useFormSchema';
import updateClipMutation from '@/queries/clip/updateClipMutation';
import { Clip } from '@/queries/clip/useClipQuery';
import { CLIP_FORM_STEPS } from '../Form';
import useClipTranscriptionSelectionSchema from '../schemas/useTranscriptionSelectionSchema';
import useClipTranscriptionSchema from '../schemas/useTranscriptionSchema';
import updateClipTranscriptionMutation from '@/queries/clip/updateClipTranscriptionMutation';
import useClipFormatsSchema from '../schemas/useFormatsSchema';
import { useQueryClient } from '@tanstack/react-query';
import clipFormatKeys from '@/queries/clipFormat/clipFormatKeys';
import useClipCustomizationFormSchema from '../schemas/useCustomizationSchema';
import generateClipMutation from '@/queries/clip/generateClipMutation';
import clipKeys from '@/queries/clip/clipKeys';
import { useBodyToastQueue } from '@/shared/hooks/useBodyToastQueue.hook';
import useClipTranscriptionProgressQuery from '@/queries/clip/useClipTranscriptionProgressQuery';

interface FooterProps {
    episode: Episode;
    clip: Clip;
}

const Footer = ({ clip, episode }: FooterProps) => {
    const { showId, clipId } = useParams<{ showId: string; clipId: string }>();
    const history = useHistory();
    const form = useFormContext<ClipFormSchema>();
    const { audioSample, transcriptionSelection, transcription, formats, customization } =
        form.watch();

    const toast = useBodyToastQueue();
    const queryClient = useQueryClient();
    const updateClip = updateClipMutation();
    const updateTranscription = updateClipTranscriptionMutation();
    const generateClip = generateClipMutation();
    const { data: transcriptionProgress } = useClipTranscriptionProgressQuery({
        clipId: clipId,
        enabled: !!clipId && clip.step === CLIP_FORM_STEPS.TRANSCRIPTION,
    });

    const audioSampleSchema = useAudioSampleFormSchema(episode.duration);
    const audioSampleValidation =
        clip.step === CLIP_FORM_STEPS.AUDIO_SAMPLE_SELECTION
            ? audioSampleSchema.safeParse(audioSample)
            : false;

    const transcriptionSelectionSchema = useClipTranscriptionSelectionSchema();
    const transcriptionSelectionValidation =
        clip.step === CLIP_FORM_STEPS.TRANSCRIPTION_SELECTION
            ? transcriptionSelectionSchema.safeParse(transcriptionSelection)
            : false;

    const transcriptionSchema = useClipTranscriptionSchema();
    const transcriptionValidation =
        clip.step === CLIP_FORM_STEPS.TRANSCRIPTION
            ? transcriptionSchema.safeParse(transcription)
            : false;

    const formatsSchema = useClipFormatsSchema();
    const formatsValidation =
        clip.step === CLIP_FORM_STEPS.FORMAT_SELECTION ? formatsSchema.safeParse(formats) : false;

    const customizationSchema = useClipCustomizationFormSchema();
    const customizationValidation =
        clip.step === CLIP_FORM_STEPS.CUSTOMIZATION
            ? customizationSchema.safeParse(customization)
            : false;

    const isLoading =
        updateClip.isLoading || updateTranscription.isLoading || generateClip.isLoading;
    const isCurrentStepError =
        (clip.step === CLIP_FORM_STEPS.AUDIO_SAMPLE_SELECTION &&
            audioSampleValidation &&
            !audioSampleValidation.success) ||
        (clip.step === CLIP_FORM_STEPS.TRANSCRIPTION_SELECTION &&
            transcriptionSelectionValidation &&
            !transcriptionSelectionValidation.success) ||
        (clip.step === CLIP_FORM_STEPS.TRANSCRIPTION &&
            transcriptionValidation &&
            !transcriptionValidation.success) ||
        (clip.step === CLIP_FORM_STEPS.TRANSCRIPTION && !transcriptionProgress?.isFinished) ||
        (clip.step === CLIP_FORM_STEPS.FORMAT_SELECTION &&
            formatsValidation &&
            !formatsValidation.success) ||
        (clip.step === CLIP_FORM_STEPS.CUSTOMIZATION &&
            customizationValidation &&
            !customizationValidation.success);

    const saveStepHandler = () => {
        switch (clip.step) {
            case CLIP_FORM_STEPS.AUDIO_SAMPLE_SELECTION:
                if (!audioSampleValidation || !audioSampleValidation.success) {
                    return;
                }
                updateClip.mutate(
                    {
                        clipId,
                        clip: {
                            ...audioSample,
                            step: CLIP_FORM_STEPS.TRANSCRIPTION_SELECTION,
                        },
                    },
                    {
                        onSuccess: () => {
                            queryClient.invalidateQueries({
                                queryKey: clipKeys.transcriptionProgressByClipId(clipId),
                            });
                            queryClient.invalidateQueries({
                                queryKey: clipKeys.transcriptionByClipId(clipId),
                            });
                        },
                        onError: () => {
                            toast.alert();
                        },
                    },
                );
                break;
            case CLIP_FORM_STEPS.TRANSCRIPTION_SELECTION:
                if (
                    !transcriptionSelectionValidation ||
                    !transcriptionSelectionValidation.success
                ) {
                    return;
                }
                updateClip.mutate(
                    {
                        clipId,
                        clip: {
                            ...transcriptionSelection,
                            step: transcriptionSelection.transcript
                                ? CLIP_FORM_STEPS.TRANSCRIPTION
                                : CLIP_FORM_STEPS.FORMAT_SELECTION,
                        },
                    },
                    {
                        onSuccess: () => {
                            queryClient.invalidateQueries({
                                queryKey: clipKeys.transcriptionProgressByClipId(clipId),
                            });
                        },
                        onError: () => {
                            toast.alert();
                        },
                    },
                );
                break;
            case CLIP_FORM_STEPS.TRANSCRIPTION:
                if (!transcriptionValidation || !transcriptionValidation.success) {
                    return;
                }
                updateTranscription.mutate(
                    {
                        clipId,
                        transcription,
                    },
                    {
                        onSuccess: () => {
                            updateClip.mutate({
                                clipId,
                                clip: {
                                    step: CLIP_FORM_STEPS.FORMAT_SELECTION,
                                },
                            });
                        },
                        onError: () => {
                            toast.alert();
                        },
                    },
                );
                break;
            case CLIP_FORM_STEPS.FORMAT_SELECTION:
                if (!formatsValidation || !formatsValidation.success) {
                    return;
                }
                updateClip.mutate(
                    {
                        clipId,
                        clip: {
                            formats,
                            step: CLIP_FORM_STEPS.CUSTOMIZATION,
                        },
                    },
                    {
                        onSuccess: () => {
                            queryClient.invalidateQueries({ queryKey: clipFormatKeys.all() });
                        },
                        onError: () => {
                            toast.alert();
                        },
                    },
                );
                break;
            case CLIP_FORM_STEPS.CUSTOMIZATION:
                if (!customizationValidation || !customizationValidation.success) {
                    return;
                }
                updateClip.mutate(
                    {
                        clipId,
                        clip: {
                            ...customization,
                        },
                    },
                    {
                        onSuccess: () => {
                            generateClip.mutate(clipId, {
                                onSuccess: () => {
                                    queryClient.invalidateQueries({
                                        queryKey: clipKeys.quotaByShowId(showId),
                                    });
                                    toast.success(
                                        <FormattedMessage defaultMessage="Le clip est en cours de génération." />,
                                    );
                                    history.push(`/app/show/${showId}/communication/clip`);
                                },
                                onError: () => {
                                    toast.alert();
                                },
                            });
                        },
                    },
                );
                break;
            default:
                break;
        }
    };

    const previousStepHandler = () => {
        switch (clip.step) {
            case CLIP_FORM_STEPS.TRANSCRIPTION_SELECTION:
                updateClip.mutate({
                    clipId,
                    clip: {
                        step: CLIP_FORM_STEPS.AUDIO_SAMPLE_SELECTION,
                    },
                });
                break;
            case CLIP_FORM_STEPS.TRANSCRIPTION:
                updateClip.mutate({
                    clipId,
                    clip: {
                        step: CLIP_FORM_STEPS.TRANSCRIPTION_SELECTION,
                    },
                });
                break;
            case CLIP_FORM_STEPS.FORMAT_SELECTION:
                updateClip.mutate({
                    clipId,
                    clip: {
                        step: transcriptionSelection.transcript
                            ? CLIP_FORM_STEPS.TRANSCRIPTION
                            : CLIP_FORM_STEPS.TRANSCRIPTION_SELECTION,
                    },
                });
                break;
            case CLIP_FORM_STEPS.CUSTOMIZATION:
                updateClip.mutate({
                    clipId,
                    clip: {
                        step: CLIP_FORM_STEPS.FORMAT_SELECTION,
                    },
                });
                break;
            default:
                break;
        }
    };

    const continueLaterHandler = () => {
        switch (clip.step) {
            case CLIP_FORM_STEPS.AUDIO_SAMPLE_SELECTION:
                updateClip.mutate(
                    {
                        clipId,
                        clip: audioSample,
                    },
                    {
                        onSettled: () => {
                            history.push(`/app/show/${showId}/communication/clip`);
                        },
                        onSuccess: () => {
                            queryClient.invalidateQueries({ queryKey: clipFormatKeys.all() });
                            queryClient.invalidateQueries({
                                queryKey: clipKeys.transcriptionProgressByClipId(clipId),
                            });
                            queryClient.invalidateQueries({
                                queryKey: clipKeys.transcriptionByClipId(clipId),
                            });
                        },
                        onError: () => {
                            toast.alert();
                        },
                    },
                );
                break;
            case CLIP_FORM_STEPS.TRANSCRIPTION_SELECTION:
                updateClip.mutate(
                    {
                        clipId,
                        clip: transcriptionSelection,
                    },
                    {
                        onSettled: () => {
                            history.push(`/app/show/${showId}/communication/clip`);
                        },
                        onError: () => {
                            toast.alert();
                        },
                    },
                );
                break;
            case CLIP_FORM_STEPS.TRANSCRIPTION:
                updateTranscription.mutate(
                    {
                        clipId,
                        transcription,
                    },
                    {
                        onSettled: () => {
                            history.push(`/app/show/${showId}/communication/clip`);
                        },
                        onError: () => {
                            toast.alert();
                        },
                    },
                );
                break;
            case CLIP_FORM_STEPS.FORMAT_SELECTION:
                updateClip.mutate(
                    {
                        clipId,
                        clip: {
                            formats,
                        },
                    },
                    {
                        onSettled: () => {
                            history.push(`/app/show/${showId}/communication/clip`);
                        },
                        onSuccess: () => {
                            queryClient.invalidateQueries({ queryKey: clipFormatKeys.all() });
                        },
                        onError: () => {
                            toast.alert();
                        },
                    },
                );
                break;
            case CLIP_FORM_STEPS.CUSTOMIZATION:
                updateClip.mutate(
                    {
                        clipId,
                        clip: {
                            ...customization,
                        },
                    },
                    {
                        onSettled: () => {
                            history.push(`/app/show/${showId}/communication/clip`);
                        },
                        onSuccess: () => {
                            queryClient.invalidateQueries({ queryKey: clipFormatKeys.all() });
                        },
                        onError: () => {
                            toast.alert();
                        },
                    },
                );
                break;
            default:
                break;
        }
    };

    return (
        <Container>
            <Button
                variant="tertiary"
                onPress={continueLaterHandler}
                isLoading={updateClip.isLoading}
            >
                <FormattedMessage defaultMessage="Continuer plus tard" />
            </Button>
            <Navigation>
                {clip.step !== CLIP_FORM_STEPS.AUDIO_SAMPLE_SELECTION && (
                    <Button
                        isDisabled={isLoading}
                        onPress={previousStepHandler}
                        startIcon={
                            <PreviousIcon icon={icon({ name: 'arrow-left', style: 'solid' })} />
                        }
                        variant="link-secondary"
                    >
                        <FormattedMessage defaultMessage="Précédent" />
                    </Button>
                )}
                <Button
                    isDisabled={!episode.waveformUrl || isCurrentStepError}
                    onPress={saveStepHandler}
                    isLoading={isLoading}
                >
                    {clip.step === CLIP_FORM_STEPS.CUSTOMIZATION ? (
                        <FormattedMessage defaultMessage="Générer le clip" />
                    ) : (
                        <FormattedMessage defaultMessage="Suivant" />
                    )}
                </Button>
            </Navigation>
        </Container>
    );
};

const Container = styled.footer`
    position: relative;
    background-color: var(--white);
    border-top: 1px solid var(--neutral100);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    padding: 1rem;

    & > :first-child {
        max-width: fit-content;
    }

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        padding: 2rem;
        border-top: none;
    }
`;
const PreviousIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const Navigation = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 1rem;
`;

export default Footer;
