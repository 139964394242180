import styled from 'styled-components';
import EpisodeEditResumeAudioFile from '@app/molecules/EpisodeEditResumeAudioFile';
import { useSeparator } from 'react-aria';
import EpisodeEditResumeInfo from '../EpisodeEditResumeInfo';

const Container = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
`;

const HorizontalLine = styled.div`
    height: 1px;
    background-color: var(--neutral100);
    border-radius: var(--r-full);
`;

const Separator = (props) => {
    const { separatorProps } = useSeparator(props);

    return <HorizontalLine {...separatorProps} />;
};

const EpisodeEditResume = () => {
    return (
        <Container>
            <EpisodeEditResumeInfo />
            <Separator />
            <EpisodeEditResumeAudioFile />
        </Container>
    );
};

export default EpisodeEditResume;
