import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchShowGuestsInvitations } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import guestKeys from './guestKeys';
import { z } from 'zod';

export const ShowGuestInvitationSchema = z.object({
    avatar: z.string().nullable(),
    email: z.string(),
    id: z.number(),
});

const ShowGuestInvitationsSchema = z.array(ShowGuestInvitationSchema);

type Key = QueryFunctionContext<ReturnType<typeof guestKeys.invitationListByShowId>>;

export type ShowGuestInvitation = z.infer<typeof ShowGuestInvitationSchema>;

const queryFn = async ({ queryKey: [{ showId }] }: Key) => {
    const { data } = await fetchShowGuestsInvitations(showId);
    return ShowGuestInvitationsSchema.parse(camelcaseKeys(data, { deep: true }));
};

const useShowGuestInvitationsQuery = (showId: string | number) => {
    return useQuery({
        queryKey: guestKeys.invitationListByShowId(showId),
        queryFn,
        enabled: !!showId,
    });
};

export default useShowGuestInvitationsQuery;
