import { createFetcher } from './Fetcher';

const FetcherEpisodesRanking = createFetcher(
    (
        { listenersStatsStore },
        { showId, itemType, itemId, start, end, order, page, perPage = 10, episodes },
    ) => ({
        fetch: () =>
            listenersStatsStore.fetchEpisodesRanking(showId, itemType, itemId, {
                start,
                end,
                order,
                page,
                perPage,
                podcasts: episodes,
            }),
        isLoading: listenersStatsStore.fetchEpisodesRanking.pending,
        input: [showId, itemType, itemId, start, end, order, page, perPage, episodes],
    }),
);

FetcherEpisodesRanking.defaultProps = {
    page: 1,
};

export default FetcherEpisodesRanking;
