import { useState } from 'react';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import IconButton from '@/components/IconButton';

interface CopyButtonProps {
    valueToCopy: string;
    onCopy?: () => void;
}

const CopyButton = ({ valueToCopy, onCopy }: CopyButtonProps) => {
    const [isCopied, setIsCopied] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(valueToCopy).then(
            () => {
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                    onCopy?.();
                }, 2000);
            },
            (err) => {
                console.error('Failed to copy text: ', err);
            },
        );
    };

    return (
        <StyledButton
            onPress={handleCopy}
            variant="ghost"
            icon={
                isCopied ? (
                    <CheckIcon icon={icon({ name: 'check-circle', style: 'solid' })} />
                ) : (
                    <CopyIcon icon={icon({ name: 'copy', style: 'solid' })} />
                )
            }
            aria-label="Copy"
        />
    );
};

const CopyIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: var(--black);
`;
const CheckIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: var(--black);
`;
const StyledButton = styled(IconButton)`
    align-self: flex-end;
    padding: 0;
`;

export default CopyButton;
