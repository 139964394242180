import ReactDOM from 'react-dom';
import { useMemo } from 'react';
import ApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { extractCssVariable } from '@/shared/utils/cssVariable';
import CustomTooltip from './CustomTooltip';
import dayjs from 'dayjs';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

interface MouseMoveParams {
    dataPointIndex: number;
    seriesIndex: number;
}

interface GraphProps {
    series: ApexAxisChartSeries;
    onMouseMove: (data: MouseMoveParams) => void;
}

const Graph = ({ series, onMouseMove }: GraphProps) => {
    const intl = useIntl();

    const getChartOptions = (onMouseMove?: any): ApexOptions => {
        const chartFontFamily = "'ProximaNova', sans-serif";
        const chartForeColor = extractCssVariable('--black');
        const chartPrimary = extractCssVariable('--primary');
        const chartBorderColor = extractCssVariable('--neutral100');
        const chartFontSize = extractCssVariable('--fs-body');

        const baseOptions: ApexOptions = {
            chart: {
                fontFamily: chartFontFamily,
                foreColor: chartForeColor,
                events: {
                    mouseMove: (_event: MouseEvent, _chart?: any, options?: any) => {
                        if (options) {
                            const hoveredSeriesIndex = options.seriesIndex;

                            if (hoveredSeriesIndex === 1) {
                                const hoveredValue = (
                                    series?.[1]?.data?.[options.dataPointIndex] as { x: number }
                                )?.x;
                                const index = series[0].data.findIndex(
                                    (item) => (item as { x: number })?.x === hoveredValue,
                                );
                                onMouseMove?.(index);
                                return;
                            }
                            onMouseMove?.(options);
                        }
                    },
                    mouseLeave: (_event: MouseEvent, _chart?: any, options?: any) => {
                        if (options) {
                            onMouseMove?.({ ...options, dataPointIndex: -1 });
                        }
                    },
                },
                parentHeightOffset: 0,
                toolbar: { show: false },
                zoom: { enabled: false },
            },
            grid: { borderColor: chartBorderColor },
            legend: { show: false },
            dataLabels: {
                enabled: false,
            },
            fill: {
                type: 'solid',
                colors: [chartPrimary, 'transparent'],
                opacity: [0.1, 0],
            },
            markers: {
                size: [4, 8],
                colors: ['transparent', chartPrimary],
                strokeColors: ['transparent', chartPrimary],
            },
            stroke: {
                curve: 'smooth',
                width: [2, 0],
                colors: [chartPrimary, 'transparent'],
            },
            tooltip: {
                shared: false,
                style: { fontSize: chartFontSize },
                custom: (props) => {
                    const tooltipNode = document.createElement('div');
                    // @ts-expect-error
                    ReactDOM.render(<CustomTooltip {...props} />, tooltipNode);
                    return tooltipNode.innerHTML;
                },
                fixed: {
                    enabled: false,
                },
                followCursor: true,
                onDatasetHover: {
                    highlightDataSeries: true,
                },
            },
            xaxis: {
                type: 'datetime',
                tickAmount: 10,
                labels: {
                    rotate: 0,
                    hideOverlappingLabels: true,
                    style: { fontSize: chartFontSize },
                    formatter: (value) => {
                        const parsed = dayjs.unix((+value || 0) / 1000);
                        if (parsed.isValid()) {
                            const iso = parsed.toISOString();
                            return intl.formatDate(iso, {
                                month: 'numeric',
                                day: 'numeric',
                                year: 'numeric',
                            });
                        }
                        return '';
                    },
                },
                tooltip: { enabled: false },
            },
            yaxis: {
                stepSize: 25,
                forceNiceScale: true,
                labels: { style: { fontSize: chartFontSize } },
            },
        };

        return baseOptions;
    };

    if (!series) {
        return null;
    }

    const chartOptions = useMemo(() => getChartOptions(onMouseMove), [onMouseMove]);

    return (
        <GraphWrapper>
            <ApexChart
                options={chartOptions}
                series={series}
                type="area"
                width="100%"
                height={350}
            />
        </GraphWrapper>
    );
};

const GraphWrapper = styled.div``;

export default Graph;
