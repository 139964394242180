import { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { EditorContent, Extensions, useEditor } from '@tiptap/react';
import Text from '@ui/atoms/Text';
import Placeholder from '@tiptap/extension-placeholder';
import { CharacterCount } from '@tiptap/extension-character-count';
import MaxLength from '@ui/molecules/MaxLength';
import Label from '../atoms/Label';
import { useLoadContent } from '../atoms/InputRichText/useLoadContent.hook';
import LoadingExtension from './LoadingExtension';
import StarterKit from '@tiptap/starter-kit';

interface InputRichPlainTextProps {
    value: string;
    label?: string | React.ReactNode;
    description?: string;
    placeholder?: string;
    tooltip?: React.ReactNode;
    charactersLimit?: number;
    isDisabled?: boolean;
    onChange?: (value: string) => void;
    errorMessage?: string;
    onBlur?: (event: React.FocusEvent<HTMLDivElement>) => void;
    isInvalid?: boolean;
    'data-testid'?: string;
    isLoading?: boolean;
    onLoadingEnd?: () => void;
    loadingContent?: string;
}

const InputRichPlainText = ({
    value,
    label,
    description,
    placeholder,
    tooltip,
    charactersLimit,
    isDisabled = false,
    onChange,
    errorMessage,
    onBlur,
    isInvalid = false,
    'data-testid': dataTestId,
    isLoading = false,
    onLoadingEnd,
    loadingContent,
}: InputRichPlainTextProps) => {
    let extensions: Extensions = [
        StarterKit.configure({
            bold: false,
            italic: false,
            bulletList: false,
            orderedList: false,
            blockquote: false,
            codeBlock: false,
            heading: false,
            horizontalRule: false,
            code: false,
            strike: false,
        }),
        LoadingExtension,
    ];

    if (placeholder) {
        extensions = [
            ...extensions,
            Placeholder.configure({
                placeholder,
            }),
        ];
    }

    if (charactersLimit) {
        extensions = [
            ...extensions,
            CharacterCount.configure({
                limit: null,
            }),
        ];
    }

    const editor = useEditor({
        extensions,
        content: value,
        onUpdate: ({ editor }) => {
            if (!onChange || isLoading) return;
            onChange?.(editor.getText());
        },
        parseOptions: {
            preserveWhitespace: true, // Preserve whitespace, but normalize newlines to spaces
        },
    });

    useEffect(() => {
        if (!editor) return;
        // When isLoading, editor should be read-only
        editor.setEditable(!isLoading);
    }, [isLoading]);

    useLoadContent({ editor, isLoading, onLoadingEnd, loadingContent });

    if (!editor) {
        return null;
    }

    if (isDisabled) {
        editor.setEditable(false);
    }

    return (
        <Wrapper>
            <LabelWrapper>
                <Label isRequired={false} description={description} tooltip={tooltip}>
                    {label}
                </Label>
                {charactersLimit && (
                    <MaxLength currentValue={editor.getText().length} max={charactersLimit} />
                )}
            </LabelWrapper>
            <InputWrapper $isInvalid={isInvalid} $isDisabled={isDisabled}>
                <StyledEditorContent
                    editor={editor}
                    onBlur={onBlur}
                    role="textbox"
                    data-testid={dataTestId}
                />
            </InputWrapper>
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
`;
const InputWrapper = styled.div<{ $isInvalid: boolean; $isDisabled: boolean }>`
    display: flex;
    padding: 0.75rem;
    flex-direction: column;
    row-gap: 0.75rem;
    border-radius: var(--r-s);
    border: 1px solid var(--neutral200);
    background-color: var(--white);

    &:focus-within {
        border: 1px solid var(--primary);
        box-shadow: 0px 0px 0px 4px rgba(117, 28, 191, 0.1);
    }

    ${({ $isInvalid }) =>
        $isInvalid &&
        css`
            border-color: var(--alert);

            &:focus-within {
                box-shadow: 0px 0px 0px 4px rgba(255, 38, 74, 0.1);
                border-color: var(--alert);
            }
        `}

    ${({ $isDisabled }) =>
        $isDisabled &&
        css`
            opacity: 0.5;
            pointer-events: none;
        `}
`;
const LabelWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const StyledEditorContent = styled(EditorContent)`
    .ProseMirror {
        min-height: 7.5rem;
        max-height: 19.75rem;
        overflow-y: auto;
        font-size: var(--fs-body);
        line-height: var(--lh-body);
    }

    .ProseMirror:hover {
        border-color: var(--neutral500);
    }

    .ProseMirror:focus,
    .ProseMirror:focus-within,
    .ProseMirror-focused {
        border-color: var(--primary);
    }

    .ProseMirror p.is-editor-empty:first-child::before {
        color: var(--neutral500);
        content: attr(data-placeholder);
        float: left;
        height: 0;
        pointer-events: none;
    }
`;
const ErrorMessage = styled(Text)`
    color: var(--alert);
    font-size: var(--fs-body-s);
    font-weight: var(--fw-semibold);
`;

export default InputRichPlainText;
