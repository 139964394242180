import { action, computed, observable } from 'mobx';
import TagModel from './TagModel';
import FileModel from './FileModel';
import Model from './Model';
import { date, first, nullable } from './resolvers';
import { apiCall } from '@app/decorators/api';
import ClipModel from './ClipModel';
import { EPISODE_PRIVACY, EPISODE_STATUS } from '@/utils/constants';

class PodcastModel extends Model {
    @observable isSelected = false;
    @observable prevFile;
    @observable isPublishYouTube = undefined; // Hack to refresh the toggle for publishModal, and see youtube Icon on it. Do because, Object key 'YoutubeVideo' is not always present on response Api
    @observable isWysiwygLinkOpen = false; // To fixed the submit behavior, on component EpisodeEditHeader

    static attributes = {
        id: 'id',
        playlistPodcastId: 'playlistPodcastId', // TODO: This is a hack, this id is artificially added in the playlistStore
        showId: 'show_id',
        publicId: 'public_id',
        seasonId: nullable('season_id'),
        audioUrlPrivate: 'audio_url',
        _audioUrlInternal: 'audio_url_internal',
        waveformUrl: 'waveform_url',
        siteUrl: 'site_url',
        smartlinkUrl: 'smartlink_url',
        imageUrlPrivate: 'image_url',
        autoSharing: 'auto_sharing',
        canDownload: 'can_download',
        description: 'description',
        downloadsCount: 'downloads_count',
        duration: 'duration',
        guid: 'guid',
        htmlDescription: 'html_description',
        isExplicit: 'is_explicit',
        name: 'name',
        privacy: 'privacy',
        slug: 'slug',
        statut: 'state',
        type: 'type',
        viewsCount: 'views_count',
        tags: {
            fromApi: {
                key: 'tags',
                resolve: (xs) => xs.data.map((x) => new TagModel(this, x)),
            },
            toApi: {
                key: 'tags',
                resolve: (xs) => xs.map((x) => x.name),
            },
        },
        soundcloudPublish: 'soundcloud_publish',
        soundcloudUrl: 'soundcloud_url',
        publishYoutube: 'publish_youtube', // Write only
        youtubeVideo: 'youtubeVideo',
        audioFile: first('files', FileModel),
        publishedAt: date('published_at'),
        updatedAt: date('updated_at'),
        createdAt: date('created_at'),
        useCustomUrl: 'use_custom_url',
        customUrl: 'custom_url',
    };

    static stateToStatus = {
        published: 'online',
        blocked: 'blocked',
        scheduled: 'planned',
        draft: 'draft',
        active: 'online',
    };

    // We don't want to update the audio file if it is uploading !!!
    updateData(data) {
        const audioFile = this.audioFile;
        super.updateData(data);
        if (audioFile && audioFile.isUploading) {
            this.audioFile = audioFile;
        }
    }

    createClip() {
        const clip = new ClipModel(this.state.clipStore);
        clip.episodeId = this.id;
        return clip;
    }

    @computed
    get clips() {
        return this.state.clipStore.clips.filter((clip) => clip.episodeId === this.id);
    }

    @computed
    get isListenable() {
        return !this.isDraft && this.audioUrl && this.privacy === EPISODE_PRIVACY.PUBLIC;
    }

    @computed
    get isDraft() {
        return this.statut === EPISODE_STATUS.DRAFT;
    }

    @computed
    get isScheduled() {
        return this.statut === EPISODE_STATUS.SCHEDULED;
    }

    @computed
    get isOnline() {
        return this.statut === EPISODE_STATUS.ONLINE;
    }

    @computed
    get isPrivate() {
        return this.privacy === EPISODE_PRIVACY.PRIVATE;
    }

    @computed
    get isUnlisted() {
        return this.privacy === EPISODE_PRIVACY.UNLISTED;
    }

    @computed
    get isPublic() {
        return this.privacy === EPISODE_PRIVACY.PUBLIC;
    }

    @computed
    get imageUrl() {
        return this.imageUrlPrivate || (this.show && this.show.imageUrl);
    }

    @computed
    get audioUrl() {
        return this.audioUrlPrivate;
    }

    @computed
    get audioUrlInternal() {
        const { token } = this.state.authStore;

        try {
            const url = new URL(this._audioUrlInternal);
            url.searchParams.append('token', token);

            return url.toString();
        } catch (error) {
            // URL is not valid, fallback to audioUrl
            return this.audioUrl;
        }
    }

    @computed
    get status() {
        return PodcastModel.stateToStatus[this.statut];
    }

    @computed
    get show() {
        return this.state.showStore.byId(this.showId);
    }

    @computed
    get getSiteUrl() {
        return this.useCustomUrl ? this.customUrl : this.siteUrl;
    }

    @computed
    get facebookPodcastTemplate() {
        return this.state.autoSharingStore.facebookPodcastTemplate;
    }

    @computed
    get twitterPodcastTemplate() {
        return this.state.autoSharingStore.twitterPodcastTemplate;
    }

    @computed
    get linkedinPodcastTemplate() {
        return this.state.autoSharingStore.linkedinPodcastTemplate;
    }

    @computed
    get instagramPodcastTemplate() {
        return this.state.autoSharingStore.instagramPodcastTemplate;
    }

    // --- API

    @apiCall
    deleteFromPlaylist(playlist) {
        return this.state.playlistStore.removePodcastFromPlaylist(playlist, this);
    }

    // --- UI
    @action.bound
    setIsPublishYouTube(youtubeVideo) {
        this.isPublishYouTube = youtubeVideo;
    }

    @action.bound
    setWysiwygLinkOpen(bool) {
        this.isWysiwygLinkOpen = bool;
    }
}

export default PodcastModel;
