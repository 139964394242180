import soundcloud from '@public/images/platforms/SoundCloud.png';
import { FormattedMessage } from 'react-intl';
import BroadcastCard from '../../BroadcastCard';
import CardFallback from '@app/fallbacks/CardFallback';
import { USER_OPTIONS } from '@/utils/userOptions';
import { PRICING } from '@/utils/pricing';
import { LISTENING_PLATFORMS_NAMES, SOUNDCLOUD } from '@/utils/constants';
import { useParams } from 'react-router-dom';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import useSoundcloudProviderQuery from '@/queries/provider/useSoundcloudProviderQuery.hook';
import RouterLink from '@/components/Link/RouterLink';
import styled from 'styled-components';

const SoundcloudBroadcast = () => {
    const { showId } = useParams<{ showId: string }>();
    const { data: show, isLoading: showIsLoading } = useShowQuery(showId);
    const {
        data: soundcloudAccount,
        isError,
        isLoading: soundcloudAccountIsLoading,
    } = useSoundcloudProviderQuery({ showId });

    const hasASoundcloudAccount = soundcloudAccount && !isError;
    const url = hasASoundcloudAccount ? soundcloudAccount?.url ?? undefined : undefined;
    const hasSoundcloudUserOption = show?.userOptions?.includes(USER_OPTIONS.SOUNDCLOUD);

    if (soundcloudAccountIsLoading || showIsLoading) {
        return <CardFallback />;
    }

    return (
        <BroadcastCard
            platformKey={SOUNDCLOUD}
            name={LISTENING_PLATFORMS_NAMES[SOUNDCLOUD]}
            logo={soundcloud}
            url={url}
            isDisabled={!hasSoundcloudUserOption}
            tooltip={
                <TooltipWrapper>
                    <FormattedMessage
                        defaultMessage="Pour diffuser votre podcast sur Soundcloud, vous devez disposer d’une offre {offerIntermediate} ou {offerPremium}."
                        values={{
                            offerIntermediate: <Capitalized>{PRICING.BOOST}</Capitalized>,
                            offerPremium: <Capitalized>{PRICING.SUPERSONIC}</Capitalized>,
                        }}
                    />
                    <WhiteRouterLink to="/app/user/subscription/offers">
                        <FormattedMessage defaultMessage="Mettre à jour mon offre" />
                    </WhiteRouterLink>
                </TooltipWrapper>
            }
        />
    );
};

const TooltipWrapper = styled.div``;
const Capitalized = styled.span`
    display: inline-block;
    text-transform: capitalize;
`;
const WhiteRouterLink = styled(RouterLink)`
    color: var(--white);
    font-weight: bold;
    font-size: var(--fs-body-s);
    text-decoration: underline;
`;

export default SoundcloudBroadcast;
