import { useContext } from 'react';
import styled from 'styled-components';
import StepContext from '../../molecules/Step/StepContext';

const getConnectorColor = ({ active, completed }) => {
    if (completed || active) return '#2ED573';
    return '#EEEDFD';
};

const Container = styled.div`
    flex: 1 1 auto;
    position: absolute;
    top: calc(24px / 2); // Half of StepIcon size
    left: calc(-50% + 20px);
    right: calc(50% + 20px);
`;

const Line = styled.span`
    display: block;
    border-color: ${(props) => getConnectorColor(props)};
    border-top-style: solid;
    border-top-width: 1px;
`;

// IMPROVE Refactor component to add an animation like a progress bar
const StepConnector = (props) => {
    const { active, completed } = useContext(StepContext);

    return (
        <Container {...props}>
            <Line active={active} completed={completed} />
        </Container>
    );
};

export default StepConnector;
