import { useParams } from 'react-router';
import EpisodeAICustomPromptForm from './EpisodeAICustomPromptForm';
import { AICustomPromptFormSchema } from './EpisodeAICustomPromptForm/useAICustomPromptFormSchema.hook';
import {
    AISettingsMapItem,
    useEpisodeAISettings,
} from '@/components/NonHostingEpisodeForm/contexts/NonHostingEpisodeAISettingsContext';
import useHasAccessToEpisodeAI from '@/shared/hooks/useHasAccessToEpisodeAI.hook';
import useEpisodeQuery from '@/queries/episode/useEpisodeQuery.hook';

interface EpisodeAICustomPromptProps {
    onClose: () => void;
    type: AISettingsMapItem;
}

const EpisodeAICustomPrompt = ({ onClose, type }: EpisodeAICustomPromptProps) => {
    const { episodeId, showId } = useParams<{ episodeId: string; showId: string }>();
    const [settings, setSettings] = useEpisodeAISettings();
    const forwardedType = type.startsWith('newsletter')
        ? 'newsletter'
        : type.startsWith('smm')
        ? 'smm'
        : type;
    const defaultSettings = settings.get(forwardedType) || {
        customPrompt: '',
        saveAsDefault: false,
    };
    const hasAccessToAI = useHasAccessToEpisodeAI({ showId });
    const episode = useEpisodeQuery({ episodeId, config: { enabled: !!episodeId } });
    const isFreeTrial = episode.data?.aiFreeTrialEnabled && !hasAccessToAI;

    const handleSessionStorage = (type: AISettingsMapItem, data: AICustomPromptFormSchema) => {
        sessionStorage.setItem(`episode-${episodeId}-ai-${type}-settings`, JSON.stringify(data));
    };

    const handleSubmit = (data: AICustomPromptFormSchema) => {
        switch (type) {
            case 'title':
                handleSessionStorage('title', data);
                setSettings('title', data);
                break;
            case 'description':
                handleSessionStorage('description', data);
                setSettings('description', data);
                break;
            case 'tags':
                handleSessionStorage('tags', data);
                setSettings('tags', data);
                break;
            case 'newsletter':
                handleSessionStorage('newsletter', data);
                setSettings('newsletter', data);
                break;
            case 'newsletter-title':
                handleSessionStorage('newsletter', data);
                setSettings('newsletter-title', data);
                break;
            case 'newsletter-message':
                handleSessionStorage('newsletter', data);
                setSettings('newsletter-message', data);
                break;
            case 'smm':
                handleSessionStorage('smm', data);
                setSettings('smm', data);
                break;
            case 'smm-facebook':
                handleSessionStorage('smm', data);
                setSettings('smm-facebook', data);
                break;
            case 'smm-twitter':
                handleSessionStorage('smm', data);
                setSettings('smm-twitter', data);
                break;
            case 'smm-instagram':
                handleSessionStorage('smm', data);
                setSettings('smm-instagram', data);
                break;
            case 'smm-linkedin':
                handleSessionStorage('smm', data);
                setSettings('smm-linkedin', data);
                break;
            default:
                break;
        }
        onClose();
    };

    return (
        <EpisodeAICustomPromptForm
            onClose={onClose}
            onSubmit={handleSubmit}
            type={type}
            values={defaultSettings}
            isFreeTrial={isFreeTrial}
        />
    );
};

export default EpisodeAICustomPrompt;
