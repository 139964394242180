import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchCampaignSourceDevices } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import statsKeys from './statsKeys';
import { z } from 'zod';

export const DeviceSchema = z.object({
    count: z.number(),
    device: z.string(),
    percentage: z.number(),
});
export const DevicesSchema = z.array(DeviceSchema);
export type Device = z.infer<typeof DeviceSchema>;
type Key = QueryFunctionContext<ReturnType<typeof statsKeys.campaignSourceDevices>>;
type InputProps = {
    campaignId: string;
    episodes: string[];
    start: string;
    end: string;
    timezone: string;
    options?: {
        enabled?: boolean;
    };
};

const queryFn = async ({ queryKey: [{ campaignId, episodes, start, end, timezone }] }: Key) => {
    const { data } = await fetchCampaignSourceDevices(campaignId, {
        podcasts: episodes,
        timezone,
        start,
        end,
    });
    return DevicesSchema.parse(camelcaseKeys(data, { deep: true }));
};

const useCampaignSourceDevicesQuery = ({
    campaignId,
    episodes,
    start,
    end,
    timezone,
    options,
}: InputProps) => {
    return useQuery({
        queryKey: statsKeys.campaignSourceDevices(campaignId, episodes, start, end, timezone),
        queryFn,
        enabled: options?.enabled || true,
    });
};

export default useCampaignSourceDevicesQuery;
