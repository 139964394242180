import { cloneElement } from 'react';
import PropTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled, { css } from 'styled-components';

const DropdownMenu = ({ menuItems, onToggle, onClose, opened, direction, children }) => {
    const ToggleButton = () => {
        return children ? (
            cloneElement(children, { onPress: () => onToggle() })
        ) : (
            <CustomIconButton type="button" onClick={() => onToggle()}>
                <FontAwesomeIcon icon={icon({ name: 'ellipsis', style: 'solid' })} size="sm" />
            </CustomIconButton>
        );
    };

    return (
        <DropdownMenuWrapper>
            <ToggleButton />
            {opened && menuItems && (
                <OutsideClickHandler
                    onOutsideClick={() => {
                        if (onClose) {
                            onClose();
                        }
                    }}
                >
                    <Menu direction={direction}>
                        {menuItems.map((menuItem, index) =>
                            menuItem === 'separator' ? (
                                <Separator key={`separator-${index}`} />
                            ) : menuItem === 'empty' ? null : (
                                <MenuItem
                                    key={menuItem.id}
                                    onClick={() => {
                                        menuItem.onClick?.();
                                    }}
                                    color={menuItem?.color}
                                >
                                    {menuItem.icon}
                                    {menuItem.label}
                                </MenuItem>
                            ),
                        )}
                    </Menu>
                </OutsideClickHandler>
            )}
        </DropdownMenuWrapper>
    );
};

const DropdownMenuWrapper = styled.div`
    position: relative;
`;
const CustomIconButton = styled.button`
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--r-full);
    border: none;
    cursor: pointer;
    background: none;
    padding: 0;
    margin: 0;
    color: var(--neutral500);

    &:hover {
        background: var(--neutral100);
    }
`;
const Menu = styled.ul`
    position: absolute;
    top: 2.5rem;
    min-width: 10rem;
    border-radius: var(--r-s);
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.05);
    background-color: var(--white);
    z-index: 100;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    list-style: none;
    margin: 0;

    ${(p) =>
        p.direction === 'right' &&
        css`
            left: 0;
        `}

    ${(p) =>
        p.direction === 'left' &&
        css`
            right: 0;
        `}
`;
const MenuItem = styled.li`
    width: 100%;
    background: white;
    padding: 0.25rem 0.5rem;
    cursor: pointer;
    border-radius: var(--r-s);
    font-size: var(--fs-body-m);
    font-weight: var(--fw-normal);
    display: flex;
    align-items: center;
    gap: 0.5rem;

    ${({ color }) => css`
        color: ${color ? `var(${color})` : 'var(--black)'};
        &:hover {
            background-color: ${color ? `var(${color}50)` : 'var(--neutral50)'};
        }
    `}
`;
const Separator = styled.li`
    width: 100%;
    height: 1px;
    background-color: var(--neutral100);
`;

DropdownMenu.propTypes = {
    menuItems: PropTypes.arrayOf(
        PropTypes.objectOf({
            id: PropTypes.string.isRequired,
            label: PropTypes.node.isRequired,
            onClick: PropTypes.func.isRequired,
            color: PropTypes.string,
        }),
    ),
    onToggle: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    opened: PropTypes.bool.isRequired,
    direction: PropTypes.oneOf(['left', 'right']),
    children: PropTypes.node,
};

DropdownMenu.defaultProps = {
    direction: 'left',
    menuItems: [],
};

export default DropdownMenu;
