import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchCampaign } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import campaignKeys from './campaignKeys';
import { z } from 'zod';

// TODO: use schema from useCampaignsQuery.ts ???
export const CampaignSchema = z.object({
    audioUrl: z.string().url().nullable(),
    autoOnNewPodcasts: z.boolean(),
    createdAt: z.string(),
    downloadsCount: z.number().int().nonnegative(),
    endsAt: z.string(),
    id: z.number().int(),
    maxListens: z.number().int().nonnegative(),
    files: z
        .array(
            z.object({
                duration: z.number().nullable().optional(),
                format: z.string().nullable().optional(),
                key: z.string().nullable().optional(),
                mimeType: z.string().nullable().optional(),
            }),
        )
        .optional(),
    name: z.string(),
    showId: z.number().int(),
    startsAt: z.string(),
    state: z.string(), // TODO: use enum
    type: z.union([z.literal('preroll'), z.literal('postroll'), z.literal('hostread')]),
    updatedAt: z.string(),
    podcasts: z.array(
        z.object({
            audioUrl: z.string().url().nullable(),
            createdAt: z.string().nullable(),
            id: z.number().int(),
            imageUrl: z.string().nullable(),
            name: z.string(),
            publicId: z.string().nullable(),
            publishedAt: z.string().nullable(),
            state: z.string(),
        }),
    ),
});

export type Campaign = z.infer<typeof CampaignSchema>;
export type CampaignFile = NonNullable<Campaign['files']>[number];
type Key = QueryFunctionContext<ReturnType<typeof campaignKeys.detailById>>;

const queryFn = async ({ queryKey: [{ campaignId }] }: Key) => {
    const { data } = await fetchCampaign(campaignId);
    const formattedData = {
        ...data,
        files: data.files.data,
        podcasts: data.podcasts.data,
    };
    return CampaignSchema.parse(camelcaseKeys(formattedData, { deep: true }));
};

interface Options {
    enabled?: boolean;
}

const useCampaignQuery = (campaignId: string | number, options?: Options) => {
    return useQuery({
        queryKey: campaignKeys.detailById(campaignId),
        queryFn,
        enabled: !!campaignId,
        ...options,
    });
};

export default useCampaignQuery;
