import { useAmplitudeLogEvent } from '@/shared/hooks/useAmplitude';
import CardsHosting from './Cards/HostingBroadcastCards';
import CardsNonHosting from './Cards/NonHostingBroadcastCards';
import BroadcastSetup from './BroadcastSetup';
import Platform from './Platform';
import BroadcastEmailVerificationRequired from './BroadcastEmailVerificationRequired';
import { Route, Switch } from 'react-router';

const Broadcast = () => {
    useAmplitudeLogEvent('Show Broadcast Settings Viewed');

    return (
        <Switch>
            <Route exact path="/app/show/:showId/settings/broadcast/email-verification">
                <BroadcastEmailVerificationRequired />
            </Route>
            <Route exact path="/app/show/:showId/settings/broadcast/setup">
                <BroadcastSetup />
            </Route>
            <Route path="/app/show/:showId/settings/broadcast/platform/:platform">
                <Platform />
            </Route>
            <Route exact path="/app/show/:showId/settings/broadcast/non-hosting">
                <CardsNonHosting />
            </Route>
            <Route exact path="/app/show/:showId/settings/broadcast">
                <CardsHosting />
            </Route>
        </Switch>
    );
};

export default Broadcast;
