import PropTypes from 'prop-types';
import PsoVisibilityScoreHistoryGraph from './PsoVisibilityScoreHistoryGraph';
import PsoVisibilityScoreHistorySkeleton from './PsoVisibilityScoreHistorySkeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import UiKitTooltip from '@ui/atoms/UiKitTooltip';
import Text from '@ui/atoms/Text';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const PsoVisibilityScoreHistory = ({ isLoading, data }) => {
    if (isLoading) return <PsoVisibilityScoreHistorySkeleton />;

    const dates = data?.map((item) => item.date) || [];
    const scores = data?.map((item) => Math.round(item.score)) || [];

    return (
        <PsoVisibilityScoreHistoryWrapper>
            <TitleWrapper>
                <Title>
                    <FormattedMessage defaultMessage="Historique de visibilité" />
                </Title>
                <UiKitTooltip
                    variant="dark"
                    content={
                        <FormattedMessage defaultMessage="L’évolution de votre score de visibilité sur les 12 dernières semaines" />
                    }
                    position="top"
                >
                    <HelpIcon icon={icon({ name: 'circle-info', style: 'solid' })} />
                </UiKitTooltip>
            </TitleWrapper>
            <PsoVisibilityScoreHistoryGraph dates={dates} scores={scores} />
        </PsoVisibilityScoreHistoryWrapper>
    );
};

const PsoVisibilityScoreHistoryWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1.5rem;
`;
const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
`;
const Title = styled(Text)`
    font-weight: var(--fw-bold);
    font-size: var(--fs-body-m);
`;
const HelpIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
`;

PsoVisibilityScoreHistory.propTypes = {
    isLoading: PropTypes.bool,
    data: PropTypes.array,
};

export default PsoVisibilityScoreHistory;
