import { CircleFormFallback } from '@app/fallbacks/CircleFormFallback';
import styled from 'styled-components';
import useUserStore from '@hooks/useUserStore';
import ProfileForm from '@app/molecules/ProfileForm';
import { observer } from 'mobx-react';
import useCountriesQuery from '@/queries/country/useCountriesQuery.hook';
import useLanguagesQuery from '@/queries/language/useLanguagesQuery.hook';
import useTimezonesQuery from '@/queries/timezone/useTimezonesQuery.hook';
import { useQueryClient } from '@tanstack/react-query';
import userKeys from '@/queries/user/userKeys';
import { useModalToastQueue } from '@/utils/hooks/useModalToastQueue.hook';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;

    ${(p) => p.theme.mediaQueries.tabletAndUp} {
        row-gap: 2rem;
    }
`;

const ContentContainer = styled(Container)`
    display: flex;
    flex-direction: column;
    row-gap: 1.25rem;
    padding: 1.5rem 1rem;
    background-color: var(--white);
    border-radius: var(--r-l);

    ${(p) => p.theme.mediaQueries.tabletAndUp} {
        padding: 2rem 2.5rem;
    }
`;

function ProfileEdit() {
    const { user, updateProfile } = useUserStore();
    const languages = useLanguagesQuery();
    const timezones = useTimezonesQuery();
    const countries = useCountriesQuery();
    const toast = useModalToastQueue();
    const formattedLanguages =
        languages.data && languages.data.filter((language) => ['fr', 'en'].includes(language.code));
    const formattedTimezones =
        timezones.data &&
        Object.keys(timezones?.data).reduce((acc, key) => {
            acc.push({ id: key, name: timezones?.data[key] });
            return acc;
        }, []);
    const queryClient = useQueryClient();

    const onSubmit = (formData, reset) => {
        updateProfile(formData)
            .then(({ data }) => {
                user.updateData({ ...data, shows_count: user.showsCount });
                queryClient.invalidateQueries({ queryKey: userKeys.detail() });
                toast.success();
                reset(formData);
            })
            .catch(() => toast.alert());
    };

    return (
        <Container>
            <ContentContainer>
                {!user || languages.isLoading || timezones.isLoading || countries.isLoading ? (
                    <CircleFormFallback />
                ) : (
                    <ProfileForm
                        languages={formattedLanguages}
                        countries={countries.data}
                        timezones={formattedTimezones}
                        user={user}
                        onSubmit={onSubmit}
                        isLoading={updateProfile.pending}
                    />
                )}
            </ContentContainer>
        </Container>
    );
}

export default observer(ProfileEdit);
