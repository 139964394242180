import { useEffect } from 'react';
import Stack from '@/components/ui/layout/Stack';
import NewsletterTitleField from './NewsletterTitleField';
import NewsletterMessageField from './NewsletterMessageField';
import NextStepButton from '../NonHostingEpisodeFormAccordion/NextStepButton';
import { useEpisodeAILoading } from '../contexts/NonHostingEpisodeAILoadingContext';
import { useAccordionItem } from '@/components/ui/atoms/AccordionItem/useAccordionItem.hook';

const NonHostingEpisodeFormNewsletter = () => {
    const [state] = useEpisodeAILoading();
    const isLoading = state.get('newsletter-title') || state.get('newsletter-message') || false;
    const { open, isExpanded } = useAccordionItem();

    useEffect(() => {
        if (open && !isExpanded && isLoading) {
            open();
        }
    }, [isLoading, open, isExpanded]);

    return (
        <Stack $gap="2rem">
            <NewsletterTitleField />
            <NewsletterMessageField />
            <NextStepButton />
        </Stack>
    );
};

export default NonHostingEpisodeFormNewsletter;
