import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Stack from '@ui/layout/Stack';
import Text from '@ui/atoms/Text';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Button from '@/components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from '@ui/atoms/Modal';
import useShowHasHostingAccess from '@/shared/hooks/useShowHasHostingAccess.hook';
import { useParams } from 'react-router';

interface DeleteShowModalProps {
    isOpen: boolean;
    onOpenChange: (isOpen: boolean) => void;
    isLoading: boolean;
    onDelete: () => void;
}

const DeleteShowModal = ({ isOpen, onOpenChange, onDelete, isLoading }: DeleteShowModalProps) => {
    const { showId } = useParams<{ showId: string }>();
    const isShowHosted = useShowHasHostingAccess(showId);

    const handleClose = () => onOpenChange(false);

    return (
        <ModalStyled isOpen={isOpen} onOpenChange={onOpenChange} size="small">
            <IconWrapper>
                <TrashIcon icon={icon({ name: 'trash', style: 'solid' })} />
            </IconWrapper>
            <Stack $gap="0.25rem">
                {isShowHosted ? (
                    <Text variant="headingS" fontWeight="--fw-bold" textAlign="center">
                        <FormattedMessage defaultMessage="Êtes-vous sûr de vouloir supprimer cette émission ?" />
                    </Text>
                ) : (
                    <Text variant="headingS" fontWeight="--fw-bold" textAlign="center">
                        <FormattedMessage defaultMessage="Souhaitez-vous vraiment supprimer la connexion de votre émission à Ausha ?" />
                    </Text>
                )}
                {isShowHosted ? (
                    <Text color="--neutral500" textAlign="center">
                        <FormattedMessage defaultMessage="Cette action est irréversible, et toutes les données seront effacées." />
                    </Text>
                ) : (
                    <Text color="--neutral500" textAlign="center">
                        <FormattedMessage defaultMessage="Cette action est irréversible : toutes les données créées sur Ausha pour cette émission seront définitivement perdues." />
                    </Text>
                )}
            </Stack>
            <Actions>
                <Button variant="danger" onPress={onDelete} isLoading={isLoading}>
                    {isShowHosted ? (
                        <FormattedMessage defaultMessage="Supprimer" />
                    ) : (
                        <FormattedMessage defaultMessage="Supprimer la connexion" />
                    )}
                </Button>
                <Button variant="link-secondary" onPress={handleClose}>
                    <FormattedMessage defaultMessage="Annuler" />
                </Button>
            </Actions>
        </ModalStyled>
    );
};

const ModalStyled = styled(Modal)`
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1.5rem;
`;
const TrashIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
`;
const IconWrapper = styled.div`
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--alert50);
    color: var(--alert500);
    border-radius: var(--r-s);
`;
const Actions = styled.div`
    align-self: stretch;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    & > :last-child {
        align-self: center;
    }
`;

export default DeleteShowModal;
