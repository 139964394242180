import { GridList, GridListProps } from 'react-aria-components';
import { ICheckboxListItem } from './CheckboxListItem';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Checkbox from '@/components/ui/Checkbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

interface CheckboxListProps extends GridListProps<ICheckboxListItem> {
    items: ICheckboxListItem[];
    onSelectAll?: () => void;
}

const CheckboxList = ({ children, onSelectAll, ...props }: CheckboxListProps) => {
    const selectedKeys = Array.from(props.selectedKeys ?? []);
    const allItemsSelected = props.items.length === selectedKeys.length;
    const someItemsSelected = selectedKeys.length > 0 && !allItemsSelected;

    return (
        <ListWrapper>
            <SelectAllRow>
                <Checkbox
                    isSelected={allItemsSelected || someItemsSelected}
                    icon={
                        someItemsSelected ? (
                            <CheckboxIcon
                                icon={icon({ name: 'minus', style: 'solid' })}
                                fixedWidth
                                size="xs"
                            />
                        ) : undefined
                    }
                    onChange={onSelectAll}
                >
                    <FormattedMessage defaultMessage="Tout sélectionner" />
                </Checkbox>
            </SelectAllRow>
            <Divider />
            <List {...props}>{children}</List>
        </ListWrapper>
    );
};

const ListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;
`;
const SelectAllRow = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    padding-inline: 0.5rem;
    height: 2rem;
    cursor: pointer;
    align-self: flex-start;
`;
const List = styled(GridList)`
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;
`;
const Divider = styled.div`
    height: 1px;
    background-color: var(--neutral100);
    border-radius: var(--r-full);
    margin-block: 0.5rem;
`;
const CheckboxIcon = styled(FontAwesomeIcon)`
    color: var(--white);
`;

export default CheckboxList;
