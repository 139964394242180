import { useState } from 'react';
import { useIntl, FormattedMessage, FormattedDateParts, FormattedTime } from 'react-intl';
import { SearchField, Input } from 'react-aria-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useEpisodeFiltersContext } from '@/context/EpisodeFiltersContext';
import Button from '@/components/Button';
import { useDebounce } from '@/utils/hooks/useDebounceNew';
import useRefreshRssFeedMutation from '@/queries/rss/useRefreshRssFeedMutation.hook';
import useRssFeedQuery from '@/queries/rss/useRssFeedQuery.hook';
import { useParams } from 'react-router';
import styled from 'styled-components';

const PageHeader = () => {
    const { showId } = useParams<{ showId: string }>();
    const intl = useIntl();
    const { setSearch, search } = useEpisodeFiltersContext();
    const [searchValue, setSearchValue] = useState(search);
    const { data: feed } = useRssFeedQuery({ showId });
    const refreshFeed = useRefreshRssFeedMutation();

    const onChange = () => {
        setSearch(searchValue);
    };

    const debouncedOnChange = useDebounce(onChange);

    return (
        <EpisodesHeader>
            <StyledSearchField
                value={searchValue}
                onChange={(value: string) => {
                    debouncedOnChange();
                    setSearchValue(value);
                }}
            >
                <SearchIconWrapper>
                    <FontAwesomeIcon icon={icon({ name: 'magnifying-glass', style: 'solid' })} />
                </SearchIconWrapper>
                <StyledInput
                    placeholder={intl.formatMessage({
                        defaultMessage: 'Rechercher un épisode...',
                    })}
                />
            </StyledSearchField>
            <SyncWrapper>
                <FormattedMessage
                    defaultMessage="Mis à jour {date} à {time}."
                    values={{
                        date: (
                            <FormattedDateParts
                                value={feed?.syncedAt || new Date()}
                                day="2-digit"
                                month="long"
                                year="numeric"
                            >
                                {(parts) => (
                                    <>
                                        {parts[0].value}
                                        {parts[1].value}
                                        {parts[2].value}
                                    </>
                                )}
                            </FormattedDateParts>
                        ),
                        time: <FormattedTime value={feed?.syncedAt || new Date()} />,
                    }}
                />
                <PaddinglessButton
                    variant="ghost"
                    startIcon={
                        <FontAwesomeIcon
                            icon={icon({ name: 'arrows-rotate', style: 'solid' })}
                            spin={refreshFeed.isLoading}
                        />
                    }
                    onPress={() => {
                        refreshFeed.mutate({ showId });
                    }}
                >
                    <FormattedMessage defaultMessage="Rafraichir" />
                </PaddinglessButton>
            </SyncWrapper>
        </EpisodesHeader>
    );
};

const EpisodesHeader = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 0.5rem;
`;
const StyledSearchField = styled(SearchField)`
    flex-grow: 1;
    display: flex;
    align-items: center;
    background-color: var(--neutral100);
    border-radius: var(--r-s);
    padding-inline: 0.5rem;

    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        flex-grow: 0;
    }
`;
const StyledInput = styled(Input)`
    border: 0;
    height: 100%;
    padding-block: 0.75rem;
    background-color: transparent;
    min-width: 12rem;
`;
const SearchIconWrapper = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    padding-inline: 0.75rem;
    color: var(--neutral500);
`;
const SyncWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.75rem;
    color: var(--neutral500);
`;
const PaddinglessButton = styled(Button)`
    padding: 0;
`;

export default PageHeader;
