import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteClipFormatImage } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import clipFormatKeys from './clipFormatKeys';

const mutationFn = async (clipFormatId: string | number) => {
    const { data } = await deleteClipFormatImage(clipFormatId);

    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const deleteClipFormatImageMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: (_, clipFormatId) => {
            queryClient.invalidateQueries(clipFormatKeys.detailById(clipFormatId));
        },
    });
};

export default deleteClipFormatImageMutation;
