import { connect } from '../decorators/connect';
import { useLinkLoginProviderWithPopup } from '../../../utils/hooks/useLinkLoginProviderWithPopup';

const enhance = connect(({ authStore }) => ({
    loginWith: authStore.loginWith,
}));

function ProviderLoginButton({
    provider,
    loginWith,
    component: Component,
    dataLayerEvent,
    ...props
}) {
    const [win, onOpen] = useLinkLoginProviderWithPopup(provider, (e) =>
        loginWith(e.data, dataLayerEvent, provider),
    );
    return <Component isDisabled={!!win} onPress={onOpen} {...props} />;
}

export default enhance(ProviderLoginButton);
