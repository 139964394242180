import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { FORM_VALIDATION_MESSAGE } from '@/utils/validationMessage';

const useYoutubeBroadcastSettingsFormSchema = () => {
    const intl = useIntl();

    return yup.object({
        category_id: yup.string().required(intl.formatMessage(FORM_VALIDATION_MESSAGE.required)),
        privacy: yup.string(),
        notify_subscribers: yup.bool(),
        embeddable: yup.bool(),
        public_stats_viewable: yup.bool(),
        for_kids: yup.bool(),
        color: yup.string(),
    });
};

export default useYoutubeBroadcastSettingsFormSchema;
