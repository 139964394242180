import { FormattedMessage } from 'react-intl';
import UnsubscribeSurveyForm from './UnsubscribeSurveyForm';
import Dialog from '@ui/atoms/Dialog';

export interface UnsubscribeSurveyModalProps {
    isOpen: boolean;
    onOpenChange: (status: boolean) => void;
    onAfterSubmit: () => void;
}

const UnsubscribeSurveyModal = ({
    isOpen,
    onOpenChange,
    onAfterSubmit,
}: UnsubscribeSurveyModalProps) => {
    return (
        <Dialog
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            title={<FormattedMessage defaultMessage="Nous sommes tristes de vous voir partir 😢" />}
            size="medium"
        >
            <UnsubscribeSurveyForm
                onAfterSubmit={onAfterSubmit}
                onClose={() => onOpenChange(false)}
            />
        </Dialog>
    );
};

export default UnsubscribeSurveyModal;
