import styled, { css, keyframes } from 'styled-components';

const PsoTopSkeleton = () => {
    return (
        <TopSkeletonWrapper>
            <TitleBar />
            <Content>
                <Left>
                    <Circle />
                    <Bars>
                        <TopBar />
                        <BottomBar />
                    </Bars>
                </Left>
                <Graph>
                    <LeftGraphBar />
                    <MidGraphBar />
                    <RightGraphBar />
                </Graph>
            </Content>
        </TopSkeletonWrapper>
    );
};

const shimmer = keyframes`
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
`;
const shimmering = css`
    background: var(--neutral50);
    background-image: linear-gradient(
        to right,
        var(--neutral50) 0%,
        #edeef1 20%,
        var(--neutral50) 40%,
        var(--neutral50) 100%
    );
    background-repeat: no-repeat;
    background-size: 800px 104px;
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${shimmer};
    animation-timing-function: linear;
`;
const TopSkeletonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1.5rem;
`;
const TitleBar = styled.div`
    width: 7.5rem;
    height: 0.75rem;
    background-color: var(--neutral100);
    border-radius: var(--r-s);
    ${shimmering};
`;
const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const Left = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;
const Circle = styled.div`
    width: 2.5rem;
    height: 2.5rem;
    border-radius: var(--r-full);
    border: 6px solid var(--neutral100);
`;
const Bars = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    & > * {
        border-radius: var(--r-s);
        height: 0.5rem;
    }
`;
const TopBar = styled.div`
    width: 7.5rem;
    background-color: var(--neutral100);
    ${shimmering};
`;
const BottomBar = styled.div`
    width: 2.5rem;
    background-color: var(--neutral50);
    ${shimmering};
`;
const Graph = styled.div`
    display: flex;
    align-items: flex-end;
    gap: 0.5rem;

    & > * {
        width: 1rem;
        background-color: var(--neutral100);
        border-radius: var(--r-xs);
    }
`;
const LeftGraphBar = styled.div`
    height: 0.5rem;
    ${shimmering};
`;
const MidGraphBar = styled.div`
    height: 2.5rem;
    ${shimmering};
`;
const RightGraphBar = styled.div`
    height: 1.5rem;
    ${shimmering};
`;

export default PsoTopSkeleton;
