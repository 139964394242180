import { useResponsive } from '@/shared/hooks/useResponsive';
import { useFormContext } from 'react-hook-form';
import CardSelect, { CARD_SELECT_VARIANTS } from '@ui/atoms/CardSelect';
import Stack from '@ui/layout/Stack';
import Cluster from '@ui/layout/Cluster';
import Text from '@ui/atoms/Text';
import { FormattedMessage } from 'react-intl';
import EpisodeEditTooltip from '@/components/unorganized/EpisodeEditTooltip';
import { EPISODE_PRIVACY } from '@/shared/config/constants';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import UiKitTooltip from '@ui/atoms/UiKitTooltip';
import { PRICING } from '@/shared/config/pricing';
import RouterLink from '@/components/Link/RouterLink';
import styled from 'styled-components';
import { CardSelectListController } from '@ui/molecules/CardSelectList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const UpgradeForUnlistedOptionTooltip = ({ children, passthrough }) => (
    <FlexTooltip
        variant="dark"
        content={
            <Stack $gap="0.5rem" $align="center">
                <Text fontWeight="--fw-semibold">
                    <FormattedMessage
                        defaultMessage="Pour publier votre épisode en non-listé, vous devez bénéficier d'une offre {offer}."
                        values={{
                            offer: (
                                <Text
                                    as="span"
                                    color="--white"
                                    fontWeight="--fw-bold"
                                    textTransform="capitalize"
                                >
                                    {PRICING.BOOST}
                                </Text>
                            ),
                        }}
                    />
                </Text>
                <RouterLink to="/app/user/subscription/offers">
                    <FormattedMessage defaultMessage="Mettre à jour mon offre" />
                </RouterLink>
            </Stack>
        }
        passthrough={passthrough}
        position="top"
    >
        {children}
    </FlexTooltip>
);

const EpisodeEditFormPrivacyPrivacyField = ({ isUnlistedAllowed = false }) => {
    const { isDesktopLarge } = useResponsive();
    const { control } = useFormContext();

    const cardSelectVariant = isDesktopLarge
        ? CARD_SELECT_VARIANTS.LARGE
        : CARD_SELECT_VARIANTS.SMALL;

    return (
        <Stack $gap="0.5rem">
            <Cluster $gap="0.25rem" $align="center">
                <Text variant="bodyM" fontWeight="--fw-semibold">
                    <FormattedMessage defaultMessage="Visibilité" />
                </Text>
                <EpisodeEditTooltip>
                    <FormattedMessage defaultMessage="Vous pouvez gérer la confidentialité de votre épisode et savoir ainsi quelles seront les personnes pouvant le voir." />
                </EpisodeEditTooltip>
            </Cluster>
            <StyledCardSelectListController name="privacy.privacy" control={control}>
                <CardSelect
                    value={EPISODE_PRIVACY.PUBLIC}
                    variant={cardSelectVariant}
                    title={<FormattedMessage defaultMessage="Public" />}
                    description={
                        <FormattedMessage defaultMessage="Votre épisode sera visible par tout le monde." />
                    }
                    icon={<CardSelectIcon icon={icon({ name: 'eye', style: 'duotone' })} />}
                />
                <CardSelect
                    value={EPISODE_PRIVACY.PRIVATE}
                    variant={cardSelectVariant}
                    title={<FormattedMessage defaultMessage="Privé" />}
                    description={
                        <FormattedMessage defaultMessage="Vous seul pourrez accéder à votre épisode." />
                    }
                    icon={<CardSelectIcon icon={icon({ name: 'eye-slash', style: 'duotone' })} />}
                />
                <CardSelect
                    value={EPISODE_PRIVACY.UNLISTED}
                    variant={cardSelectVariant}
                    title={<FormattedMessage defaultMessage="Non-listé" />}
                    description={
                        <FormattedMessage defaultMessage="Votre épisode sera accessible aux personnes disposant du lien." />
                    }
                    icon={<CardSelectIcon icon={icon({ name: 'link', style: 'duotone' })} />}
                    isDisabled={!isUnlistedAllowed}
                    tooltip={<UpgradeForUnlistedOptionTooltip passthrough={isUnlistedAllowed} />}
                />
            </StyledCardSelectListController>
        </Stack>
    );
};

const StyledCardSelectListController = styled(CardSelectListController)`
    flex-direction: column;

    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        flex-direction: row;
    }
`;
const CardSelectIcon = styled(FontAwesomeIcon)`
    color: var(--primary);
    width: 1.5rem;
    height: 1.5rem;
`;
const FlexTooltip = styled(UiKitTooltip)`
    flex: 1;
    height: auto;
    width: auto;
`;

export default EpisodeEditFormPrivacyPrivacyField;
