import { action, observable } from 'mobx';
import axios from 'axios';
import Model from './Model';
import { nullable } from './resolvers';

class FileModel extends Model {
    @observable uploadProgress = 1;
    @observable isUploading = false;
    cancelSource;

    static attributes = {
        name: 'key',
        duration: nullable('duration'),
        mimeType: 'mime_type',
        format: 'format',
    };

    get cancelToken() {
        return this.cancelSource.token;
    }

    @action.bound
    onProgress({ loaded, total }) {
        this.uploadProgress = (0.9 * loaded) / total;
    }

    @action
    startUpload(file) {
        this.uploadProgress = 0;
        this.name = file.name;
        this.mimeType = file.type;
        this.isUploading = true;
        this.cancelSource = axios.CancelToken.source();
        this.rawFileData = new FormData();
        this.rawFileData.append('file', file);
    }

    updateData(data) {
        super.updateData(data);
        this.endUpload();
    }

    @action
    endUpload() {
        this.uploadProgress = 1;
        this.isUploading = false;
    }

    @action.bound
    cancel() {
        try {
            this.uploadProgress = 0;
            this.isUploading = false;
            this.cancelSource.cancel();
        } catch (e) {
            // Error
        }
    }
}

export default FileModel;
