import ReactDOM from 'react-dom';
import { useMemo } from 'react';
import ApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { extractCssVariable } from '@/shared/utils/cssVariable';
import CustomTooltip from './CustomTooltip';
import styled from 'styled-components';

interface MouseMoveParams {
    dataPointIndex: number;
    seriesIndex: number;
}

const getChartOptions = (onMouseMove?: any): ApexOptions => {
    const chartFontFamily = "'ProximaNova', sans-serif";
    const chartForeColor = extractCssVariable('--black');
    const chartPrimary = extractCssVariable('--primary');
    const chartBorderColor = extractCssVariable('--neutral100');
    const chartFontSize = extractCssVariable('--fs-body');

    const baseOptions: ApexOptions = {
        chart: {
            fontFamily: chartFontFamily,
            foreColor: chartForeColor,
            events: {
                mouseMove: (_event: MouseEvent, _chart?: any, options?: any) => {
                    if (options) {
                        onMouseMove?.(options);
                    }
                },
                mouseLeave: (_event: MouseEvent, _chart?: any, options?: any) => {
                    if (options) {
                        onMouseMove?.({ ...options, dataPointIndex: -1 });
                    }
                },
            },
            parentHeightOffset: 0,
            toolbar: { show: false },
            zoom: { enabled: false },
        },
        grid: { borderColor: chartBorderColor },
        legend: { show: false },
        dataLabels: {
            enabled: false,
        },
        fill: {
            type: 'solid',
            colors: [chartPrimary],
            opacity: [0.1],
        },
        stroke: { curve: 'smooth', width: 2 },
        tooltip: {
            style: { fontSize: chartFontSize },
            custom: (props) => {
                const tooltipNode = document.createElement('div');
                // @ts-expect-error
                ReactDOM.render(<CustomTooltip {...props} />, tooltipNode);
                return tooltipNode.innerHTML;
            },
        },
        xaxis: {
            tickAmount: 10,
            labels: { rotate: 0, hideOverlappingLabels: true, style: { fontSize: chartFontSize } },
            tooltip: { enabled: false },
        },
        yaxis: {
            stepSize: 25,
            forceNiceScale: true,
            labels: { style: { fontSize: chartFontSize } },
        },
    };

    return baseOptions;
};

interface GraphProps {
    variations: ApexAxisChartSeries;
    onMouseMove: (data: MouseMoveParams) => void;
}

const Graph = ({ variations, onMouseMove }: GraphProps) => {
    if (!variations) {
        return null;
    }

    const chartOptions = useMemo(() => getChartOptions(onMouseMove), [onMouseMove]);

    return (
        <GraphWrapper>
            <ApexChart
                options={chartOptions}
                series={variations}
                type="area"
                width="100%"
                height={350}
            />
        </GraphWrapper>
    );
};

const GraphWrapper = styled.div``;

export default Graph;
