import { useEffect, useMemo, useRef } from 'react';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { DownloadsComparisonData } from '@app/molecules/DownloadsComparisonContent';
import styled from 'styled-components';
import Text from '@ui/atoms/Text';
import DownloadsComparisonTableHead from '@/components/tables/DownloadsComparisonTable/DownloadsComparisonTableHead';
import DownloadsComparisonTableBody from '@/components/tables/DownloadsComparisonTable/DownloadsComparisonTableBody';
import DownloadsComparisonTableBodyLoading from '@/components/tables/DownloadsComparisonTable/DownloadsComparisonTableBodyLoading';
import DownloadsComparisonTableBodyNoData from '@/components/tables/DownloadsComparisonTable/DownloadsComparisonTableBodyNoData';
import { IntlShape, useIntl } from 'react-intl';

const getPlaceholderData = (intl: IntlShape, numberOfDays: number = 7) => {
    const data = Array.from({ length: numberOfDays }, (_, index) => ({
        x: intl.formatMessage({ defaultMessage: 'Jour {day}' }, { day: index + 1 }),
        y: 0,
    }));

    return [
        {
            id: 'placeholder',
            name: 'placeholder',
            data,
            color: '#9F40FF',
        },
    ];
};

interface DownloadsComparisonTableProps {
    data?: DownloadsComparisonData[];
    isLoading?: boolean;
    highlightedColumnIndex?: number;
}

const DownloadsComparisonTable = ({
    data = [],
    highlightedColumnIndex,
    isLoading = false,
}: DownloadsComparisonTableProps) => {
    const intl = useIntl();
    const hasData = data.length > 0;
    const tableRef = useRef();

    const tableData = useMemo(
        () => (isLoading || !hasData ? getPlaceholderData(intl) : data),
        [data, isLoading, hasData],
    );

    const columnHelper = createColumnHelper<DownloadsComparisonData>();

    const defaultColumns = [
        columnHelper.accessor('name', {
            cell: (info) => (
                <EpisodeNameCell>
                    <EpisodeMarker color={info.row.original.color} />
                    <EpisodeName numberOfLines={1}>{info.renderValue()}</EpisodeName>
                </EpisodeNameCell>
            ),
            header: () => null,
            minSize: 200,
        }),
        ...tableData[0].data.map((value, index) => {
            return columnHelper.accessor((row) => row.data[index].y, {
                id: `downloads-${index}`,
                cell: (info) => (
                    <Text as="span" textAlign="center" numberOfLines={1}>
                        {info.getValue()}
                    </Text>
                ),
                header: () => <Text color="--neutral500">{value.x}</Text>,
                size: 100,
                minSize: 100,
            });
        }),
    ];

    const table = useReactTable({
        data: tableData,
        columns: defaultColumns,
        getCoreRowModel: getCoreRowModel(),
        enableColumnResizing: false,
        initialState: {
            columnPinning: {
                left: ['name'],
            },
        },
    });

    useEffect(() => {
        if (highlightedColumnIndex === -1) {
            return;
        }

        if (tableRef.current) {
            const columnElement = tableRef.current.querySelector(
                `th[data-column-id='downloads-${highlightedColumnIndex}']`,
            );
            if (columnElement) {
                columnElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }
        }
    }, [highlightedColumnIndex]);

    return (
        <Container>
            <Table ref={tableRef}>
                <DownloadsComparisonTableHead
                    table={table}
                    highlightedColumnIndex={highlightedColumnIndex}
                />
                {isLoading ? (
                    <DownloadsComparisonTableBodyLoading table={table} />
                ) : hasData ? (
                    <DownloadsComparisonTableBody
                        table={table}
                        highlightedColumnIndex={highlightedColumnIndex}
                    />
                ) : (
                    <DownloadsComparisonTableBodyNoData table={table} />
                )}
            </Table>
        </Container>
    );
};

const Container = styled.div`
    width: 100%;
    overflow-x: scroll;
    position: relative;
    border-radius: var(--r-s);
`;

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;

    th,
    td {
        border: 1px solid var(--neutral100);
    }

    tr:first-child th {
        border-top: none;
    }
    tr:last-child td {
        border-bottom: none;
    }
    tr th:first-child,
    tr td:first-child {
        border-left: none;
    }
    tr th:last-child,
    tr td:last-child {
        border-right: none;
    }
`;

const EpisodeNameCell = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.75rem;
    align-items: center;
`;

const EpisodeMarker = styled.div<{ color: string }>`
    background-color: ${(props) => props.color};
    border-radius: 50%;
    flex-shrink: 0;
    height: 0.75rem;
    width: 0.75rem;
`;

const EpisodeName = styled(Text).attrs({ forwardedAs: 'span' })`
    // max-width: 12.5rem;
`;

export default DownloadsComparisonTable;
