import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchAllShrinkedEpisodes } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import episodeKeys from '@/queries/episode/episodeKeys';
import { z } from 'zod';

export const EpisodeSchema = z.object({
    id: z.number(),
    state: z
        .literal('draft')
        .or(z.literal('blocked'))
        .or(z.literal('scheduled'))
        .or(z.literal('active')),
    publishedAt: z.string().nullable(),
    publicId: z.string(),
    name: z.string(),
    createdAt: z.string(),
    audioUrl: z.string().nullable(),
    imageUrl: z.string().nullable(),
});
const EpisodesSchema = z.array(EpisodeSchema);
const EpisodePaginationSchema = z.object({
    currentPage: z.number(),
    totalPages: z.number(),
    totalItems: z.number().optional(),
    itemsPerPage: z.number().optional(),
});
const EpisodeResponseSchema = z.object({
    episodes: EpisodesSchema,
    pagination: EpisodePaginationSchema,
});
type InputProps = {
    showId: string;
    page?: number;
    perPage?: number;
    searchQuery?: string;
    withClip?: boolean;
    episodeStatus?: string | null;
    options?: {
        enabled?: boolean;
    };
};
type Key = QueryFunctionContext<ReturnType<typeof episodeKeys.listMinimalByShowId>>;
export type Episode = z.infer<typeof EpisodeSchema>;

const queryFn = async ({
    queryKey: [{ showId, page, perPage, searchQuery, withClip, episodeStatus }],
}: Key) => {
    const { data, meta } = await fetchAllShrinkedEpisodes(
        showId,
        searchQuery,
        page,
        perPage,
        withClip,
        episodeStatus,
    );

    // TODO: Do camelcase transformation in Axios interceptor
    const { data: camelcaseData, meta: camelcaseMeta } = camelcaseKeys(
        { data, meta },
        { deep: true },
    );

    const formattedData = {
        episodes: camelcaseData,
        pagination: camelcaseMeta.pagination,
    };

    return EpisodeResponseSchema.parse(formattedData);
};

const useEpisodesMinimalQuery = (
    { showId, page, perPage, searchQuery, withClip, episodeStatus, options }: InputProps = {
        showId: '',
        page: 1,
        perPage: 20,
        searchQuery: '',
        withClip: false,
        episodeStatus: null,
        options: undefined,
    },
) => {
    return useQuery({
        queryKey: episodeKeys.listMinimalByShowId(
            showId,
            page,
            perPage,
            searchQuery,
            withClip,
            episodeStatus,
        ),
        queryFn,
        enabled: !!showId && (options?.enabled ?? true),
    });
};

export default useEpisodesMinimalQuery;
