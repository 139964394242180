import type { ListBoxItem } from '@/components/ui/atoms/ListBoxItem';
import Text from '@/components/ui/atoms/Text';
import Stack from '@/components/ui/layout/Stack';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

interface ShowProps {
    show: ListBoxItem;
}

const Show = ({ show }: ShowProps) => {
    return (
        <Container>
            <Cover src={show.image as string} alt={show.name} />
            <Information>
                <Stack>
                    <Text variant="bodyL" fontWeight="--fw-bold">
                        {show.name}
                    </Text>
                    <Text color="--primary500">{show.description}</Text>
                </Stack>
                <EpisodesCount>
                    <EpisodesCountIcon icon={icon({ name: 'rectangle-history', style: 'solid' })} />
                    <Text>
                        <FormattedMessage
                            defaultMessage="{episodesCount} vidéos"
                            values={{
                                episodesCount: show.episodesCount,
                            }}
                        />
                    </Text>
                </EpisodesCount>
            </Information>
        </Container>
    );
};

const Container = styled.div`
    padding: 1rem;
    background-color: var(--neutral50);
    border-radius: var(--r-l);
    display: flex;
    align-items: center;
    column-gap: 1rem;
    margin-block: 0.5rem;
`;
const Cover = styled.img`
    width: 5rem;
    height: 5rem;
    border-radius: var(--r-m);
    flex-shrink: 0;
    object-fit: cover;
`;
const Information = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
`;
const EpisodesCount = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    color: var(--neutral500);
`;
const EpisodesCountIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
export default Show;
