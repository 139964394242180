import { useMemo } from 'react';
import PropTypes from 'prop-types';
import useShowQuery from '@queries/show/useShowQuery.hook';
import useSubscriptionQuery from '@queries/subscription/useSubscriptionQuery.hook';
import { Route, Redirect } from 'react-router-dom';
import { STATUS_SHOWS } from '@/shared/config/statusShows';
import { USER_ROLES } from '@/shared/config/constants';
import { useParams } from 'react-router-dom';

const NoPermissionRoute = ({
    roles,
    pricing,
    showStatus,
    authorizedForBlockedSubscription = false,
    redirect,
    ...props
}) => {
    const { showId } = useParams();
    const { data: show } = useShowQuery(showId);
    const { data: subscription } = useSubscriptionQuery();

    /* Compute the show status based on user role, archiving, etc. */
    const computedShowStatus = useMemo(() => {
        if (!show?.userRole === USER_ROLES.OWNER) return STATUS_SHOWS.GUEST_SHOWS;
        if (show?.archived) return STATUS_SHOWS.ARCHIVED_SHOWS;
        return STATUS_SHOWS.ACTIVE_SHOWS;
    }, [show]);

    /* Conditions determining whether access is blocked based on different factors */
    const isBlocked = useMemo(() => {
        return (
            /*
                1. Check if the user's role is included in the allowed roles for the show
                (show must exist, and the user's role must match one of the allowed roles)
            */
            (show && roles.includes(show.userRole)) ||
            /*
                2. Check if the subscription pricing is included in the allowed pricing options
                (subscription must exist, and the pricing must match one of the allowed pricing options)
            */
            (subscription && pricing.includes(subscription.pricing)) ||
            /*
                3. Check if the show's status is included in the allowed show statuses
                (show must exist, and the computed status must match one of the allowed show statuses)
            */
            (show && showStatus.includes(computedShowStatus))
        );
    }, [show, subscription, roles, pricing, showStatus, computedShowStatus]);

    /* Redirect if access is blocked and the user is not authorized for the blocked subscription */
    if (isBlocked && !authorizedForBlockedSubscription) {
        return <Redirect to={`/app/show/${showId}/episodes`} replace />;
    }

    return <Route {...props} />;
};

NoPermissionRoute.propTypes = {
    roles: PropTypes.arrayOf(PropTypes.string),
    pricing: PropTypes.arrayOf(PropTypes.string),
    showStatus: PropTypes.arrayOf(PropTypes.string),
    authorizedForBlockedSubscription: PropTypes.bool,
    redirect: PropTypes.string,
};

NoPermissionRoute.defaultProps = {
    roles: [],
    pricing: [],
    showStatus: [],
    authorizedForBlockedSubscription: false,
    redirect: undefined,
};

export default NoPermissionRoute;
