import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import objectSupport from 'dayjs/plugin/objectSupport';
import isoWeek from 'dayjs/plugin/isoWeek';
import styled from 'styled-components';
import { useParams } from 'react-router';
import useQuery from '@/shared/hooks/useQuery';
import Text from '@/components/ui/atoms/Text';
import { LAUNCH_TIME_MESSAGE } from '../PeakTime';
import HeatmapChart from './HeatmapChart';
import usePeakTimeHeatmapQuery from '@/queries/stats/usePeakTimeHeatmapQuery.hook';
import HeatmapChartLegend from './HeatmapChartLegend';
import ShortListFallback from '@/components/unorganized/ShortListFallback';

dayjs.extend(objectSupport);
dayjs.extend(isoWeek);

const Heatmap = () => {
    const { showId } = useParams<{ showId: string }>();
    const query = useQuery();
    const daysRange = (query.get('days') as '7' | '15' | '30' | '90' | 'always') ?? 'always';
    const { data: heatmap } = usePeakTimeHeatmapQuery({ showId, days: daysRange });

    if (heatmap) {
        const series = heatmap.mapData.map((item) => ({
            downloads: item.downloads,
            dayHour: dayjs(dayjs().hour(item.dayHour)),
            weekdDay: dayjs(dayjs().isoWeekday(item.weekDay)).format('ddd'),
        }));
        const categories = heatmap.categories;

        return (
            <Wrapper>
                <TextWrapper>
                    <Text variant="title" fontWeight="--fw-bold">
                        <FormattedMessage defaultMessage="Votre audience selon les moments" />
                    </Text>
                    <Text>
                        {daysRange === 'always' ? (
                            <FormattedMessage defaultMessage="Vos écoutes selon les jours et heures depuis le début" />
                        ) : (
                            <FormattedMessage
                                defaultMessage="Vos écoutes selon les jours et heures sur les {messageLaunchTime}"
                                values={{ messageLaunchTime: LAUNCH_TIME_MESSAGE[daysRange] }}
                            />
                        )}
                    </Text>
                </TextWrapper>
                <ChartContainer>
                    <HeatmapChart
                        categories={categories}
                        series={series}
                        maxDownloads={heatmap.maxDownloads}
                    />
                    <HeatmapChartLegend categories={categories} />
                </ChartContainer>
            </Wrapper>
        );
    }

    return <ShortListFallback />; // TODO: Improve loading state
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
`;
const ChartContainer = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding-mobile);

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        padding: var(--container-padding);
    }
`;
const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export default Heatmap;
