import { useEffect, useRef } from 'react';

/**
 * @see https://github.com/streamich/react-use
 */
const useInterval = (callback, delay) => {
    const savedCallback = useRef(() => {});

    useEffect(() => {
        savedCallback.current = callback;
    });

    useEffect(() => {
        if (delay !== null && delay !== undefined && !isNaN(delay)) {
            const interval = setInterval(() => savedCallback.current(), delay || 0);
            return () => clearInterval(interval);
        }

        return undefined;
    }, [delay]);
};

export default useInterval;
