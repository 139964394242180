import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Stack from '@/components/ui/layout/Stack';
import Text from '@/components/ui/atoms/Text';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Button from '@/components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from '@/components/ui/atoms/Modal';

interface PauseCampaignModalProps {
    isOpen: boolean;
    onOpenChange: (open: boolean) => void;
    onPlayPause: () => void;
    isLoading: boolean;
    isPaused: boolean;
}
const PauseCampaignModal = ({
    isOpen,
    onOpenChange,
    onPlayPause,
    isLoading,
    isPaused,
}: PauseCampaignModalProps) => {
    const handleClose = () => onOpenChange(false);

    return (
        <ModalStyled isOpen={isOpen} onOpenChange={onOpenChange} size="small">
            <IconWrapper $color={isPaused ? '--success' : '--warning'}>
                {isPaused ? (
                    <Icon icon={icon({ name: 'play', style: 'solid' })} />
                ) : (
                    <Icon icon={icon({ name: 'pause', style: 'solid' })} />
                )}
            </IconWrapper>
            <Stack $gap="0.25rem">
                <Text variant="headingS" fontWeight="--fw-bold" textAlign="center">
                    {isPaused ? (
                        <FormattedMessage defaultMessage="Souhaitez-vous vraiment remettre en ligne cette campagne ?" />
                    ) : (
                        <FormattedMessage defaultMessage="Souhaitez-vous vraiment mettre en pause cette campagne ?" />
                    )}
                </Text>
                {!isPaused && (
                    <Text color="--neutral500" textAlign="center">
                        <FormattedMessage defaultMessage="La campagne sera toujours disponible sur les épisodes déjà téléchargés par vos auditeurs." />
                    </Text>
                )}
            </Stack>
            <Actions>
                <Button onPress={onPlayPause} isLoading={isLoading}>
                    {isPaused ? (
                        <FormattedMessage defaultMessage="Republier la campagne" />
                    ) : (
                        <FormattedMessage defaultMessage="Mettre en pause" />
                    )}
                </Button>
                <Button variant="link-secondary" onPress={handleClose}>
                    <FormattedMessage defaultMessage="Annuler" />
                </Button>
            </Actions>
        </ModalStyled>
    );
};

const ModalStyled = styled(Modal)`
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1.5rem;
`;
const Icon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
`;
const IconWrapper = styled.div<{ $color?: string }>`
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(${({ $color }) => `${$color}50`});
    color: var(${({ $color }) => $color});
    border-radius: var(--r-s);
`;
const Actions = styled.div`
    align-self: stretch;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    & > :last-child {
        align-self: center;
    }
`;

export default PauseCampaignModal;
