import { css } from 'styled-components';
import PropTypes from 'prop-types';

/**
 * See https://chakra-ui.com/docs/features/style-props
 */
const transitionMixin = css`
    transition: ${(props) => props.$transition};
    transition-delay: ${(props) => props.$transitionDelay};
    animation: ${(props) => props.$animation};
    transition-duration: ${(props) => props.$transitionDuration};
    transition-property: ${(props) => props.$transitionProperty};
    transition-timing-function: ${(props) => props.$transitionTimingFunction};
`;

export const transitionPropTypes = {
    $transition: PropTypes.string,
    $transitionDelay: PropTypes.string,
    $animation: PropTypes.string,
    $transitionDuration: PropTypes.string,
    $transitionProperty: PropTypes.string,
    $transitionTimingFunction: PropTypes.string,
};

export default transitionMixin;
