import { createFetcher } from '../Fetcher';

const FetcherApplePodcastsReviews = createFetcher(
    ({ applePodcastsStatsStore }, { showId, page }) => ({
        fetch: () => applePodcastsStatsStore.fetchApplePodcastsReviews(showId, page),
        isLoading: applePodcastsStatsStore.fetchApplePodcastsReviews.pending,
        input: [showId, page],
    }),
);

FetcherApplePodcastsReviews.defaultProps = {
    page: 1,
};

export default FetcherApplePodcastsReviews;
