import useShowQuery from '@queries/show/useShowQuery.hook';
import { USER_ROLES } from '../config/constants';
import useUserQuery from '@queries/user/useUserQuery.hook';
import { USER_OPTIONS } from '../config/userOptions';

interface HasAccessToTranscriptionProps {
    showId: string;
}

const useHasAccessToTranscription = ({ showId }: HasAccessToTranscriptionProps) => {
    const { data: show } = useShowQuery(showId);
    const { data: user } = useUserQuery();

    const guestWithAccess =
        show?.userRole === USER_ROLES.ADMIN &&
        show?.userOptions.includes(USER_OPTIONS.TRANSCRIPTION);
    const ownerWithAccess =
        show?.userRole === USER_ROLES.OWNER && user?.options.includes(USER_OPTIONS.TRANSCRIPTION);
    const hasTranscriptionUserOption = guestWithAccess || ownerWithAccess;

    return hasTranscriptionUserOption;
};

export default useHasAccessToTranscription;
