import { useMutation, useQueryClient } from '@tanstack/react-query';
import camelcaseKeys from 'camelcase-keys';
import { deletePersonalAccessToken } from '@/api';
import personalAccessTokenKeys from './personalAccessTokenKeys';

const mutationFn = async (tokenId: string): Promise<void> => {
    const { data } = await deletePersonalAccessToken(tokenId);

    return camelcaseKeys(data as any, { deep: true });
};

const deletePersonalAccessTokenMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: personalAccessTokenKeys.list() });
        },
    });
};

export default deletePersonalAccessTokenMutation;
