import { FormattedMessage } from 'react-intl';
import SharingProviderButton from '@ui/atoms/SharingProviderButton';
import ListFormFallback from '@/components/unorganized/ListFormFallback';
import { useParams } from 'react-router-dom';
import useEpisodeQuery from '@queries/episode/useEpisodeQuery.hook';
import styled from 'styled-components';
import Text from '../ui/atoms/Text';
import Stack from '../ui/layout/Stack';

interface EpisodePreviewShareProps {
    isLoading: boolean;
}

const EpisodePreviewShare = ({ isLoading }: EpisodePreviewShareProps) => {
    const { episodeId } = useParams<{ episodeId: string }>();
    const { data: episode } = useEpisodeQuery({ episodeId: episodeId, config: { enabled: true } });
    const episodeSmartlinkUrl = episode?.smartlinkUrl;

    if (isLoading)
        return (
            <Container>
                <ListFormFallback />
            </Container>
        );

    if (episode?.privacy !== 'public') return null;

    return (
        <Wrapper>
            <Text fontWeight="--fw-bold" variant="headingM">
                <FormattedMessage defaultMessage="Partage" />
            </Text>
            <Container>
                <Stack $align="center" $gap="1rem">
                    <Text textAlign="center" fontWeight="--fw-semibold">
                        <FormattedMessage defaultMessage="Partager l'épisode sur" />
                    </Text>
                    <Providers>
                        {/* @ts-ignore */}
                        <SharingProviderButton provider="facebook" url={episodeSmartlinkUrl} />
                        {/* @ts-ignore */}
                        <SharingProviderButton provider="twitter" url={episodeSmartlinkUrl} />
                        {/* @ts-ignore */}
                        <SharingProviderButton provider="linkedin" url={episodeSmartlinkUrl} />
                        {/* @ts-ignore */}
                        <SharingProviderButton provider="whatsapp" url={episodeSmartlinkUrl} />
                    </Providers>
                </Stack>
            </Container>
        </Wrapper>
    );
};
const Container = styled.div`
    padding: var(--container-padding-mobile);
    background-color: var(--white);
    border-radius: var(--r-s);

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        padding: var(--container-padding);
    }
`;
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
`;
const Providers = styled.div`
    display: flex;
    gap: 0.5rem;
`;
export default EpisodePreviewShare;
