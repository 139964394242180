import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { reflex } from '@ui/reflex';
import { resolveColor } from '@/shared/utils/cssVariable';

function appendArrow(background) {
    const ArrowTop = css`
        &:after {
            border-width: 4px;
            left: calc(50% - 4px);
            border-color: transparent transparent ${background} transparent;
        }

        &:before {
            border-width: 5.5px;
            left: calc(50% - 5.5px);
            border-color: transparent transparent ${(p) => resolveColor(p.border)} transparent;
        }
    `;
    const ArrowBottom = css`
        &:after {
            border-width: 4px;
            left: calc(50% - 4px);
            bottom: -8px;
            border-color: ${background} transparent transparent transparent;
        }

        &:before {
            border-width: 5.5px;
            left: calc(50% - 5.5px);
            bottom: -11px;
            border-color: ${(p) => resolveColor(p.border)} transparent transparent transparent;
        }
    `;
    const ArrowLeft = css`
        &:after {
            border-width: 4px;
            top: calc(50% - 4px);
            left: -8px;
            border-color: transparent ${background} transparent transparent;
        }

        &:before {
            border-width: 4.5px;
            top: calc(50% - 4.5px);
            left: -10px;
            border-color: transparent ${(p) => resolveColor(p.border)} transparent transparent;
        }
    `;
    const ArrowRight = css`
        &:after {
            border-width: 4px;
            top: calc(50% - 4px);
            border-color: transparent transparent transparent ${background};
        }

        &:before {
            border-width: 5.5px;
            top: calc(50% - 5.5px);
            border-color: transparent transparent transparent ${(p) => resolveColor(p.border)};
        }
    `;

    return css`
        position: relative;
        box-shadow: 0 2px 4px rgba(210, 210, 210, 0.5);

        &:after,
        &:before {
            content: '';
            display: block;
            position: absolute;
            left: 100%;
            width: 0;
            height: 0;
            border-style: solid;
            bottom: 100%;
        }

        ${(p) => p.arrow === 'right' && ArrowRight};
        ${(p) => p.arrow === 'left' && ArrowLeft};
        ${(p) => p.arrow === 'top' && ArrowTop};
        ${(p) => p.arrow === 'bottom' && ArrowBottom};
    `;
}

const Common = styled.div`
    border-radius: var(--r-xs);
    white-space: normal;
    border: ${(p) => (p.border ? 1 : 0)}px solid ${(p) => resolveColor(p.border)};
`;

const TooltipHelp = styled(Common)`
    padding: 15px;
    background: var(--white);
    max-width: 310px;
    ${(p) => p.arrow && appendArrow('var(--white)')};
`;

const TooltipInfo = styled(Common)`
    padding: 15px;
    height: fit-content;
    background-color: var(--info50);
    display: flex;
    align-items: center;
    ${(p) => p.arrow && appendArrow('var(--info)')};
`;

const TooltipSuccess = styled(TooltipInfo)`
    padding: 8px 10px;
    height: 34px;
    background-color: var(--success50);
    ${(p) => p.arrow && appendArrow('var(--success)')};
`;

const TooltipError = styled(TooltipSuccess)`
    height: fit-content;
    background-color: var(--alert50);
    ${(p) => p.arrow && appendArrow('var(--alert)')};
`;

const TooltipWarning = styled(TooltipSuccess)`
    height: fit-content;
    background-color: var(--warning50);
    ${(p) => p.arrow && appendArrow('var(--warning)')};
`;

const mapping = {
    help: TooltipHelp,
    info: TooltipInfo,
    online: TooltipSuccess,
    warning: TooltipWarning,
    error: TooltipError,
};

function Tooltip({ type, ...props }) {
    const TooltipComponent = mapping[type];
    return <TooltipComponent {...props} />;
}

Tooltip.propTypes = {
    type: PropTypes.oneOf(['help', 'info', 'online', 'error', 'warning']).isRequired,
    arrow: PropTypes.oneOf(['top', 'left', 'bottom', 'right']),
};

Tooltip.defaultProps = {
    arrow: undefined,
};

export default reflex(Tooltip);
