import MenuDropdown from '@/components/ui/atoms/MenuDropdown';
import MenuDropdownItem from '@/components/ui/atoms/MenuDropdown/MenuDropdownItem';
import { Playlist } from '@/queries/playlist/usePlaylistsQuery.hook';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';
import styled from 'styled-components';

interface PlaylistListItemMenuProps {
    playlist: Playlist;
    onEdit: (playlist: Playlist) => void;
    onDelete: (playlist: Playlist) => void;
}

const PlaylistListItemMenu = ({ playlist, onEdit, onDelete }: PlaylistListItemMenuProps) => {
    const history = useHistory();

    if (!playlist) return null;

    return (
        <ExportMenuDropdown>
            <MenuDropdownItem id="edit" onAction={() => onEdit(playlist)}>
                <MenuDropdownItemIcon icon={icon({ name: 'pen', style: 'solid' })} />
                <FormattedMessage defaultMessage="Modifier" />
            </MenuDropdownItem>
            <MenuDropdownItem id="website" href={playlist.url} target="_blank">
                <MenuDropdownItemIcon
                    icon={icon({
                        name: 'link',
                        style: 'solid',
                    })}
                />
                <FormattedMessage defaultMessage="Site Web" />
            </MenuDropdownItem>
            <MenuDropdownItem
                id="player"
                onAction={() =>
                    history.push(
                        `/app/show/${playlist.showId}/communication/player/playlists/${playlist.slug}`,
                    )
                }
            >
                <MenuDropdownItemIcon
                    icon={icon({
                        name: 'circle-play',
                        style: 'solid',
                    })}
                />
                <FormattedMessage defaultMessage="Smartplayer" />
            </MenuDropdownItem>
            <MenuDropdownItem color="--alert500" id="delete" onAction={() => onDelete(playlist)}>
                <TrashIcon
                    icon={icon({
                        name: 'trash',
                        style: 'solid',
                    })}
                />
                <FormattedMessage defaultMessage="Supprimer" />
            </MenuDropdownItem>
        </ExportMenuDropdown>
    );
};

const ExportMenuDropdown = styled(MenuDropdown)`
    max-width: fit-content;
`;
const MenuDropdownItemIcon = styled(FontAwesomeIcon)`
    color: var(--black);
    width: 0.75rem;
    height: 0.75rem;
`;
const TrashIcon = styled(MenuDropdownItemIcon)`
    color: var(--alert500);
`;

export default PlaylistListItemMenu;
