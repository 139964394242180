import Model from './Model';

class SoundcloudAccountModel extends Model {
    static attributes = {
        name: 'name',
        avatar: 'avatar',
        url: 'url',
        id: 'id',
    };
}

export default SoundcloudAccountModel;
