import { FormattedMessage } from 'react-intl';
import type { ImportedEpisode as ImportedEpisodeT } from '@queries/rss/useImportedEpisodesQuery.hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Checkbox from '@/components/ui/Checkbox';
import ContextualMenu from './ContextualMenu';
import styled from 'styled-components';

interface EpisodeProps {
    episode: ImportedEpisodeT;
}

const Episode = ({ episode }: EpisodeProps) => {
    return (
        <EpisodeWrapper>
            <Decoy />
            <EpisodeInnerWrapper>
                <Left>
                    <CoverWrapper>
                        <Cover src="https://image.ausha.co/default_cover.png" alt="Default cover" />
                    </CoverWrapper>
                    <LeftTextWrapper>
                        <EpisodeTitle>{episode.title}</EpisodeTitle>
                    </LeftTextWrapper>
                </Left>
                <Right>
                    <ErrorStatus>
                        <StatusIcon icon={icon({ name: 'circle-exclamation', style: 'solid' })} />
                        <FormattedMessage defaultMessage="Erreur" />
                    </ErrorStatus>
                    <ButtonDecoy />
                    <ContextualMenu episode={episode} />
                </Right>
            </EpisodeInnerWrapper>
        </EpisodeWrapper>
    );
};

const SelectCheckbox = styled(Checkbox)<{ $hasSelectedAtLeastOne: boolean }>`
    visibility: visible;
    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        visibility: hidden;
    }
    ${({ $hasSelectedAtLeastOne }) => $hasSelectedAtLeastOne && `visibility: visible;`}
`;
const Decoy = styled.div`
    width: 1rem;
`;
const EpisodeWrapper = styled.li`
    height: 4.5rem;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.75rem;

    &:hover ${SelectCheckbox} {
        visibility: visible;
    }
`;
const EpisodeInnerWrapper = styled.div`
    align-self: stretch;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    padding: 0.75rem;
    border-radius: var(--r-xs);
    background-color: var(--white);
    min-width: 0;
`;
const CoverWrapper = styled.div`
    width: 3rem;
    height: 3rem;
`;
const Cover = styled.img`
    border-radius: var(--r-xs);
`;
const Left = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex: 1;
    min-width: 0;
`;
const LeftTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    flex: 1;
    min-width: 0;
`;
const EpisodeTitle = styled.div`
    font-weight: var(--fw-semibold);
    flex: 1;

    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
const Right = styled.div`
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    gap: 0.5rem;
    align-items: center;
`;
const ErrorStatus = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--alert);
    background-color: var(--alert50);
    padding-block: 0.25rem;
    padding-inline: 0.5rem;
    border-radius: var(--r-xs);
`;
const StatusIcon = styled(FontAwesomeIcon)`
    font-size: 0.75rem;
`;
const ButtonDecoy = styled.div`
    width: 2.25rem;
`;

export default Episode;
