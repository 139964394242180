import { useContext } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Stack from '@ui/layout/Stack';
import Cluster from '@ui/layout/Cluster';
import Spinner from '@ui/atoms/Spinner';
import PricingContext from '../PricingContext';
import { PRICING, PRICING_NAME } from '@/shared/config/pricing';
import { useStripe } from '@stripe/react-stripe-js';
import Dialog from '@/components/ui/atoms/Dialog';
import Text from '@ui/atoms/Text';
import { icon as iconFA } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@ui/atoms/Button';
import useSubscriptionQuery from '@/queries/subscription/useSubscriptionQuery.hook';
import useSwitchSubscriptionMutation from '@/queries/subscription/useSwitchSubscriptionMutation.hook';
import { SUBSCRIPTION_ACTION } from '@/shared/config/constants';
import { sendAmplitudeLogEvent } from '@/helpers';
import { useQueryClient } from '@tanstack/react-query';
import subscriptionKeys from '@/queries/subscription/subscriptionKeys';
import userKeys from '@/queries/user/userKeys';
import quotaKeys from '@/queries/quota/quotaKeys';
import { useHistory } from 'react-router';
import { useModalToastQueue } from '@/shared/hooks/useModalToastQueue.hook';

const LOST_FEATURE_LIST = {
    BoostToLaunch: {
        left: [
            <FormattedMessage
                key="boost-youtube"
                defaultMessage="La diffusion de votre podcast en vidéo sur Youtube en quelques clics"
            />,
            <FormattedMessage
                key="boost-instagram"
                defaultMessage="Le partage de vos posts sur Instagram et LinkedIn"
            />,
            <FormattedMessage
                key="boost-stats"
                defaultMessage="Des statistiques avancées pour mieux connaitre votre audience (auditeurs uniques, meilleure heure de publication avec PeakTime, etc.)"
            />,
            <FormattedMessage
                key="boost-customlisteninglink"
                defaultMessage="La personnalisation de vos liens d’écoute"
            />,
        ],
        right: [
            <FormattedMessage
                key="boost-videoteaser"
                defaultMessage="La création de 12 Teasers vidéo chaque mois avec le Clip Vidéo"
            />,
            <FormattedMessage
                key="boost-monetisation"
                defaultMessage="Les campagnes manuelles de monétisation"
            />,
            <FormattedMessage key="boost-archived" defaultMessage="3 émissions archivées" />,
            <FormattedMessage
                key="boost-multiuser"
                defaultMessage="La possibilité de gérer votre podcast en équipe avec jusqu’à 3 utilisateurs"
            />,
        ],
    },
    SupersonicToLaunch: {
        left: [
            <FormattedMessage
                key="supersonic-youtube"
                defaultMessage="La diffusion de votre podcast en vidéo sur Youtube en quelques clics"
            />,
            <FormattedMessage
                key="supersonic-instagram"
                defaultMessage="Le partage de vos posts sur Instagram et LinkedIn"
            />,
            <FormattedMessage
                key="supersonic-privateplaylist"
                defaultMessage="La possibilité de créer des listes d’épisodes confidentielles avec les Playlists Protégées"
            />,
            <FormattedMessage
                key="supersonic-api"
                defaultMessage="L’utilisation de notre API sécurisée"
            />,
            <FormattedMessage
                key="supersonic-stats"
                defaultMessage="Des statistiques avancées pour mieux comprendre votre audience (auditeurs uniques, complétion de vos épisodes, répartition de vos auditeurs par ville, etc.)"
            />,
        ],
        right: [
            <FormattedMessage
                key="supersonic-cta"
                defaultMessage="L’ajout de boutons personnalisés sur vos outils de communication Ausha (Siteweb, Smartlink & Smartplayer) pour capitaliser sur votre audience"
            />,
            <FormattedMessage
                key="supersonic-webhooks"
                defaultMessage="La création de Webhooks personnalisés pour automatiser la gestion de votre podcast"
            />,
            <FormattedMessage
                key="supersonic-videoteaser"
                defaultMessage="La création de 24 Teasers vidéo chaque mois avec le Clip Vidéo"
            />,
            <FormattedMessage key="supersonic-archived" defaultMessage="5 émissions archivées" />,
            <FormattedMessage
                key="supersonic-multiuser"
                defaultMessage="La possibilité de gérer votre podcast en équipe avec jusqu’à 5 utilisateurs"
            />,
        ],
    },
    SupersonicToBoost: {
        left: [
            <FormattedMessage
                key="stob-privateplaylist"
                defaultMessage="La possibilité de créer des listes d’épisodes confidentielles avec les Playlists Protégées"
            />,
            <FormattedMessage
                key="stob-api"
                defaultMessage="L’utilisation de notre API sécurisée"
            />,
            <FormattedMessage
                key="stob-webhooks"
                defaultMessage="La création de Webhooks personnalisés pour automatiser la gestion de votre podcast"
            />,
            <FormattedMessage
                key="stob-cta"
                defaultMessage="L’ajout de boutons personnalisés sur vos outils de communication Ausha (Siteweb, Smartlink & Smartplayer) pour capitaliser sur votre audience"
            />,
        ],
        right: [
            <FormattedMessage
                key="stob-stats"
                defaultMessage="Des statistiques avancées pour mieux comprendre votre audience (complétion de vos épisodes, durée moyenne d’écoute, répartition de vos auditeurs par ville, etc.)"
            />,
            <FormattedMessage
                key="stob-videoteaser"
                defaultMessage="La création de 24 Teasers vidéo chaque mois avec le Clip Vidéo"
            />,
            <FormattedMessage key="stob-archived" defaultMessage="5 émissions archivées" />,
            <FormattedMessage
                key="stob-multiuser"
                defaultMessage="La possibilité de gérer votre podcast en équipe avec jusqu’à 5 utilisateurs"
            />,
        ],
    },
};

export interface PricingUpdateOfferModalProps {
    isOpen: boolean;
    onOpenChange: (status: boolean) => void;
}

const PricingUpdateOfferModal = ({ isOpen, onOpenChange }: PricingUpdateOfferModalProps) => {
    const stripe = useStripe();
    const { data: subscription } = useSubscriptionQuery();
    const { offerUpdatePayload, setErrorPayload } = useContext(PricingContext);
    const switchSubscription = useSwitchSubscriptionMutation();
    const queryClient = useQueryClient();
    const history = useHistory();
    const toast = useModalToastQueue();

    const getFeatureListContent = () => {
        if (offerUpdatePayload?.action !== SUBSCRIPTION_ACTION.DOWNGRADE) return;
        if (subscription?.pricing === PRICING.SUPERSONIC) {
            return offerUpdatePayload?.offer === PRICING.BOOST
                ? LOST_FEATURE_LIST.SupersonicToBoost
                : LOST_FEATURE_LIST.SupersonicToLaunch;
        }
        return LOST_FEATURE_LIST.BoostToLaunch;
    };

    const onUpdateOffer = async () => {
        if (!offerUpdatePayload) return;

        switchSubscription.mutate(
            { yearly: offerUpdatePayload?.isBilledYearly, price: offerUpdatePayload?.offer },
            {
                onSuccess: (data) => {
                    onOpenChange(false);
                    if (
                        offerUpdatePayload.offer &&
                        offerUpdatePayload.action === SUBSCRIPTION_ACTION.PERIODICITY_UPDATE
                    ) {
                        toast.success(
                            <FormattedMessage
                                defaultMessage="C'est fait ! Vous bénéficiez désormais de l'offre {offerName} {periodicity}."
                                values={{
                                    offerName: PRICING_NAME[offerUpdatePayload.offer],
                                    periodicity: data?.yearly ? (
                                        <FormattedMessage defaultMessage="annuelle" />
                                    ) : (
                                        <FormattedMessage defaultMessage="mensuelle" />
                                    ),
                                }}
                            />,
                        );
                    } else
                        history.push(
                            `/app/pricing/success?offer=${offerUpdatePayload.offer}&upgrade=${
                                offerUpdatePayload.action === SUBSCRIPTION_ACTION.UPGRADE
                            }`,
                        );
                },
                onError: async (error: any) => {
                    // Quota exceeded
                    if (error.response?.status === 422) {
                        setErrorPayload({
                            ...offerUpdatePayload,
                            error,
                        });
                        return;
                    }

                    // If 3D Secure 2 is required, response status should be 402 Payment Required
                    if (error.response?.status !== 402) {
                        throw error;
                    }

                    if (
                        error?.response?.data?.client_secret === undefined &&
                        error?.response?.data?.message !== undefined
                    ) {
                        throw error;
                    }

                    if (!stripe) return;

                    const { error: errorPaymentValidation } = await stripe.confirmCardPayment(
                        error?.response?.data?.client_secret,
                    );

                    if (errorPaymentValidation) {
                        setErrorPayload({
                            ...offerUpdatePayload,
                            error: 'payment_validation_error',
                        });
                        return;
                    }
                },
                onSettled: () => {
                    sendAmplitudeLogEvent('Payment Page Viewed');

                    queryClient.invalidateQueries(subscriptionKeys.all());
                    queryClient.invalidateQueries(userKeys.all());
                    queryClient.invalidateQueries(quotaKeys.all());
                },
            },
        );
    };

    if (!offerUpdatePayload?.offer) return null;

    const UpgradeOrPeriodicityUpdateModal = (
        <Dialog
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            title={
                <Text variant="headingM" fontWeight="--fw-bold">
                    <FormattedMessage defaultMessage="Confirmer le changement ?" />
                </Text>
            }
            size="small"
        >
            <Stack $gap="2.5rem">
                <Text>
                    <FormattedMessage
                        defaultMessage="Vous bénéficierez de l’offre <strong>{offer} {periodicity}</strong>."
                        values={{
                            strong: (chunks: React.ReactNode) => <strong>{chunks}</strong>,
                            offer: PRICING_NAME[offerUpdatePayload?.offer],
                            periodicity: offerUpdatePayload?.isBilledYearly ? (
                                <FormattedMessage defaultMessage="annuelle" />
                            ) : (
                                <FormattedMessage defaultMessage="mensuelle" />
                            ),
                        }}
                    />
                </Text>
                <Cluster $gap="0.5rem" $justify="flex-end" $wrap="nowrap">
                    {/* @ts-ignore */}
                    <Button variant="secondary" onPress={() => onOpenChange(false)}>
                        <FormattedMessage defaultMessage="Annuler" />
                    </Button>
                    {/* @ts-ignore */}
                    <Button
                        disabled={switchSubscription.isLoading}
                        onPress={onUpdateOffer}
                        startIcon={switchSubscription.isLoading && <Spinner color="--white" />}
                    >
                        <FormattedMessage defaultMessage="Confirmer" />
                    </Button>
                </Cluster>
            </Stack>
        </Dialog>
    );

    const DowngradeModal = (
        <Dialog
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            size="medium"
            title={
                <Text variant="headingM" fontWeight="--fw-bold">
                    <FormattedMessage
                        defaultMessage="Basculer vers l'offre {offer}"
                        values={{ offer: PRICING_NAME[offerUpdatePayload?.offer] }}
                    />
                </Text>
            }
        >
            <Stack $gap="1.5rem">
                <FeatureList>
                    <Text variant="headingS" fontWeight="--fw-semibold">
                        <FormattedMessage defaultMessage="Vous perdrez l’accès à..." />
                    </Text>
                    <Columns>
                        <Stack $gap="1rem">
                            {getFeatureListContent()?.left.map((item, index) => (
                                <Cluster key={index} $gap="0.5rem" $wrap="nowrap">
                                    <CircleMinusIcon
                                        icon={iconFA({ name: 'circle-minus', style: 'solid' })}
                                    />
                                    <Text>{item}</Text>
                                </Cluster>
                            ))}
                        </Stack>
                        <Stack $gap="1rem">
                            {getFeatureListContent()?.right.map((item) => (
                                <Cluster key={`${item}`} $gap="0.5rem" $wrap="nowrap">
                                    <CircleMinusIcon
                                        icon={iconFA({ name: 'circle-minus', style: 'solid' })}
                                    />
                                    <Text>{item}</Text>
                                </Cluster>
                            ))}
                        </Stack>
                    </Columns>
                </FeatureList>
                <ActionButtons>
                    <Text
                        fontWeight="--fw-semibold"
                        color="--neutral500"
                        // @ts-ignore
                        onClick={() => onOpenChange(false)}
                        textAlign="center"
                        style={{ cursor: 'pointer' }}
                    >
                        <FormattedMessage defaultMessage="Conserver mon offre" />
                    </Text>
                    {/* @ts-ignore */}
                    <Button
                        disabled={switchSubscription.isLoading}
                        onPress={onUpdateOffer}
                        startIcon={switchSubscription.isLoading && <Spinner color="--white" />}
                    >
                        <FormattedMessage
                            defaultMessage="Basculer vers l'offre {offer}"
                            values={{ offer: PRICING_NAME[offerUpdatePayload?.offer] }}
                        />
                    </Button>
                </ActionButtons>
            </Stack>
        </Dialog>
    );

    return (
        <>
            {offerUpdatePayload?.action === SUBSCRIPTION_ACTION.DOWNGRADE
                ? DowngradeModal
                : UpgradeOrPeriodicityUpdateModal}
        </>
    );
};

const FeatureList = styled(Stack)`
    background: var(--warning50);
    border-radius: var(--r-m);
    padding: 1.5rem;
    gap: 1rem;
`;

const Columns = styled.div`
    display: grid;
    flex-direction: column;
    gap: 1rem;

    ${(p) => p.theme.mediaQueries.tabletAndUp} {
        flex-direction: row;
        grid-template-columns: 1fr 1fr;
    }
`;

const CircleMinusIcon = styled(FontAwesomeIcon)`
    color: var(--warning500);
    padding: 0.25rem;
`;

const ActionButtons = styled.div`
    display: flex;
    flex-direction: column-reverse;
    align-items: stretch;
    gap: 1rem;

    ${(p) => p.theme.mediaQueries.tabletAndUp} {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
`;

export default PricingUpdateOfferModal;
