import ShortListFallback from '@/components/unorganized/ShortListFallback';
import BroadcastCard from '../../BroadcastCard';
import listenNotes from '@public/images/platforms/ListenNotes.png';
import { LISTENING_PLATFORMS_NAMES, LISTEN_NOTES } from '@/shared/config/constants';
import { useParams } from 'react-router-dom';
import useShowQuery from '@queries/show/useShowQuery.hook';

const ListenNotesBroadcast = () => {
    const { showId } = useParams<{ showId: string }>();
    const { data: show, isLoading } = useShowQuery(showId);
    const url = show?.listeningLinks?.data.find((link) => link.key === LISTEN_NOTES)?.url;

    if (isLoading) return <ShortListFallback />;

    return (
        <BroadcastCard
            platformKey={LISTEN_NOTES}
            name={LISTENING_PLATFORMS_NAMES[LISTEN_NOTES]}
            logo={listenNotes}
            url={url}
        />
    );
};

export default ListenNotesBroadcast;
