import { useParams } from 'react-router';
import { FormattedMessage } from 'react-intl';
import SeasonLine from '@/components/unorganized/SeasonLine';
import { useAmplitudeLogEvent } from '@/shared/hooks/useAmplitude';
import useSeasonsQuery from '@queries/season/useSeasonsQuery.hook';
import useAddSeasonMutation from '@queries/season/useAddSeasonMutation.hook';
import { useQueryClient } from '@tanstack/react-query';
import seasonKeys from '@queries/season/seasonKeys';
import useDeleteSeasonMutation from '@queries/season/useDeleteSeasonMutation.hook';
import styled from 'styled-components';
import Text from '../ui/atoms/Text';
import Button from '../Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Stack from '../ui/layout/Stack';

const Seasons = () => {
    useAmplitudeLogEvent('Seasons Settings Viewed');

    const queryClient = useQueryClient();
    const { showId }: { showId: string } = useParams();
    const seasons = useSeasonsQuery({ showId });
    const addSeason = useAddSeasonMutation();
    const deleteSeason = useDeleteSeasonMutation();

    const handleAddSeason = () => {
        addSeason.mutate(
            { showId },
            {
                onSuccess: async () => {
                    queryClient.invalidateQueries({
                        queryKey: seasonKeys.detailById(showId),
                    });
                    await seasons.refetch();
                },
            },
        );
    };

    const handleDelete = (seasonId: string) => {
        deleteSeason.mutate(
            { seasonId },
            {
                onSuccess: async () => {
                    queryClient.invalidateQueries({
                        queryKey: seasonKeys.detailById(showId),
                    });
                    await seasons.refetch();
                },
            },
        );
    };

    if (seasons.isLoading || seasons.isRefetching || seasons.isError) {
        return null;
    }

    return (
        <>
            <Wrapper>
                <Header>
                    <Stack>
                        <Text variant="headingL" fontWeight="--fw-bold">
                            <FormattedMessage defaultMessage="Saisons" />
                        </Text>
                        <Text color="--neutral500">
                            <FormattedMessage defaultMessage="Classez vos épisodes dans des saisons pour une meilleure organisation." />
                        </Text>
                    </Stack>
                    <Button
                        onPress={handleAddSeason}
                        isDisabled={addSeason.isLoading}
                        isLoading={addSeason.isLoading}
                        startIcon={<AddIcon icon={icon({ name: 'plus', style: 'solid' })} />}
                    >
                        <FormattedMessage defaultMessage="Ajouter une saison" />
                    </Button>
                </Header>
                <Table>
                    <TableHeader>
                        <Text color="--neutral500">
                            <FormattedMessage defaultMessage="Saison" />
                        </Text>
                        <Text color="--neutral500">
                            <FormattedMessage defaultMessage="Nombre d’épisodes" />
                        </Text>
                    </TableHeader>
                    {seasons.data.map((season, index) => (
                        <SeasonLine
                            key={season.id}
                            season={season}
                            canDelete={index === 0}
                            onDelete={(id: string) => handleDelete(id)}
                        />
                    ))}
                </Table>
            </Wrapper>
        </>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;
const Header = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
        align-items: center;

        & > :first-child {
            flex-grow: 1;
        }

        & > :last-child {
            flex-shrink: 0;
        }
    }
`;
const Table = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: var(--r-s);
    overflow: hidden;
    background-color: var(--white);
`;
const TableHeader = styled.div`
    display: flex;
    padding: 1rem;

    & > * {
        flex: 1;
    }
`;
const AddIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;

export default Seasons;
