import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Collapse from '@ui/molecules/Collapse';
import { connect } from '@/components/legacy/connect';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import CampaignEpisodesModal from './CampaignEpisodesModal';
import ToggleField from '@ui/atoms/ToggleField';

const enhance = connect((_, { fields }) => ({
    selectionCount: fields?.podcasts?.value?.length || 0,
}));

function CampaignEpisodes({ selectionCount, fields, ...props }) {
    const [isEpisodesModalOpen, setIsEpisodesModalOpen] = useState(false);

    return (
        <Collapse title={<FormattedMessage defaultMessage="Épisodes" />} {...props}>
            <DeprecatedText weight="semibold" mb={5}>
                <FormattedMessage defaultMessage="Appliquer la campagne automatiquement" />
            </DeprecatedText>
            <DeprecatedText as="label">
                <ToggleField field={fields.auto} />
                <FormattedMessage defaultMessage="La campagne s’appliquera automatiquement aux nouveaux épisodes créés." />
            </DeprecatedText>
            <DeprecatedText weight="semibold" mt={30} mb={10}>
                <FormattedMessage defaultMessage="La campagne s’applique à" />
            </DeprecatedText>
            <DeprecatedText
                weight="semibold"
                color="--primary"
                onClick={() => setIsEpisodesModalOpen(true)}
            >
                {selectionCount > 0 ? (
                    <FormattedMessage
                        defaultMessage="{count, plural, one {# épisode sélectionné} other {# épisodes sélectionnés}}"
                        values={{ count: selectionCount }}
                    />
                ) : (
                    <FormattedMessage defaultMessage="Sélectionner les épisodes" />
                )}
            </DeprecatedText>
            <CampaignEpisodesModal
                isOpen={isEpisodesModalOpen}
                onOpenChange={setIsEpisodesModalOpen}
                field={fields.podcasts}
            />
        </Collapse>
    );
}

export default enhance(CampaignEpisodes);
