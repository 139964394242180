import type { Stats } from '@queries/stats/useListenersStatsQuery.hook';
import GraphHeader from './GraphHeader';
import Graph from './Graph';
import { extractCssVariable } from '@/shared/utils/cssVariable';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import styled from 'styled-components';

interface EpisodePreviewListenerGraphProps {
    stats: Stats;
}

const EpisodePreviewListenerGraph = ({ stats }: EpisodePreviewListenerGraphProps) => {
    const intl = useIntl();
    const chartPrimary = extractCssVariable('--primary');

    const getSeries = (v: { at: number; downloads: number; variation: number | null }) => {
        const parsed = dayjs.unix(v.at);
        let xValue = '';
        let longDate = '';
        if (parsed.isValid()) {
            const iso = parsed.toISOString();
            xValue = intl.formatDate(iso, {
                month: 'numeric',
                day: 'numeric',
                year: 'numeric',
            });
            longDate = intl.formatDate(iso, {
                month: 'long',
                day: 'numeric',
                year: 'numeric',
            });
        }
        return {
            x: xValue,
            y: v.downloads,
            variation: v.variation,
            longDate,
        };
    };

    if (!stats) return null;

    return (
        <GraphWrapper>
            <GraphHeader stats={stats} />
            <Graph
                variations={[
                    {
                        name: intl.formatMessage({ defaultMessage: 'Écoutes' }),
                        data: stats?.mapData?.map((v) => getSeries(v)),
                        color: chartPrimary,
                    },
                ]}
            />
        </GraphWrapper>
    );
};

const GraphWrapper = styled.div`
    background-color: var(--white);
    padding: 1rem;
    border-radius: var(--r-m);
`;

export default EpisodePreviewListenerGraph;
