import { FormattedMessage, useIntl } from 'react-intl';
import DeleteSeasonModal from './DeleteSeasonModal';
import Column from '@ui/atoms/Column';
import PaperGrid from '@ui/atoms/PaperGrid';
import { useOverlayTriggerState } from 'react-stately';
import styled from 'styled-components';
import IconButton from '../IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

function SeasonLine({ season, canDelete, onDelete, ...props }) {
    let deleteSeasonModalState = useOverlayTriggerState({});
    const intl = useIntl();

    return (
        <PaperGrid border="--neutral100" bt={1} column={4} radBottom={5} p={16} {...props}>
            <Column startColumn={1} endColumn={3}>
                <FormattedMessage
                    defaultMessage="Saison {number}"
                    values={{
                        number: season.seasonNumber,
                    }}
                />
            </Column>
            <Column startColumn={3} endColumn={4}>
                {season.podcastsCount}
            </Column>
            <Column startColumn={4} endColumn={5} thick align="right" justify="flex-end">
                <DeleteButton
                    variant="danger"
                    icon={<ButtonIcon icon={icon({ name: 'trash', style: 'solid' })} />}
                    onPress={() => deleteSeasonModalState.open()}
                    aria-label={intl.formatMessage({ defaultMessage: 'Supprimer' })}
                    isDisabled={!canDelete}
                />
            </Column>
            {deleteSeasonModalState.isOpen && (
                <DeleteSeasonModal
                    isOpened={deleteSeasonModalState.isOpen}
                    onClose={deleteSeasonModalState.close}
                    season={season}
                    onDelete={() => onDelete(season.id)}
                />
            )}
        </PaperGrid>
    );
}

const DeleteButton = styled(IconButton)`
    padding: 1rem;
    flex-shrink: 0;
`;
const ButtonIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;

export default SeasonLine;
