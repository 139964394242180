import { Control, FieldValues, useController } from 'react-hook-form';
import { default as Select } from '.';
import type { SelectProps } from '.';
import { Key } from 'react-aria';

interface SelectControllerProps extends SelectProps {
    control: Control<FieldValues>;
}

export const SelectController = ({ control, ...props }: SelectControllerProps) => {
    const { field } = useController<FieldValues>({
        name: props.name as string,
        control,
    });

    return (
        <Select
            {...props}
            label={props.label || null}
            selectedKey={field.value}
            onSelectionChange={(id: Key) => {
                props.onSelectionChange?.(id);
                field.onChange(id);
            }}
            tooltip={props.tooltip || null}
        />
    );
};
