import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updatePost } from '@/api';
import socialNetworkPostKeys from '@/queries/socialNetworkPost/socialNetworkPostKeys';
import camelcaseKeys from 'camelcase-keys';
import dayjs from 'dayjs';

const transform = (data) => {
    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const mutationFn = async ({ postId, postPayload }) => {
    const { data } = await updatePost(postId, postPayload);
    return transform(data);
};

const useUpdateSocialNetworkCalendarPostDateMutation = ({ showId, startDate, endDate }) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onMutate: async (payload) => {
            // Get the source & target dates, and format them to match the "days" object returned by the API
            const sourceDate = dayjs(payload.source.publishAt).format('YYYYMMDD');
            const targetDate = dayjs(payload.postPayload.publish_at).format('YYYYMMDD');
            // Alias the list query key, cause it's pretty damn long
            const key = socialNetworkPostKeys.listCalendar({ showId, startDate, endDate });
            // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries(key);
            // Get the "old" state from the query cache, we'll use this as a base to apply our optimistic update
            const previousPosts = queryClient.getQueryData(key);

            /**
             * Build a new version of the cached object for the post list including the updated post
             * @param {*} old The current version of TSQuery cache data related to the post list
             * @returns The updated cache object
             */
            const generatedOptimisticResponse = (old) => {
                const sourceDay = {
                    ...old.days[sourceDate],
                    messages: old.days[sourceDate].messages.filter((m) => m.id !== payload.postId),
                };
                const targetDay = {
                    ...old.days[targetDate],
                    messages: [
                        ...old.days[targetDate].messages.filter((m) => m.id !== payload.postId),
                        {
                            ...payload.source,
                            date: dayjs(payload.postPayload.publish_at).format('YYYY-MM-DD'),
                            publishAt: dayjs(payload.postPayload.publish_at).format(
                                'YYYY-MM-DD HH:mm:ss',
                            ),
                        },
                    ],
                };

                const {
                    [sourceDate]: ommitedSource,
                    [targetDate]: ommitedTarget,
                    ...rest
                } = old.days;
                const optimisticResponse = {
                    days: {
                        ...rest,
                        [sourceDate]: sourceDay,
                        [targetDate]: targetDay,
                    },
                };
                return optimisticResponse;
            };
            // Optimistically updating the cache with the generated cache object
            queryClient.setQueryData(key, (old) => {
                return generatedOptimisticResponse(old);
            });
            return generatedOptimisticResponse(previousPosts);
        },
        onSettled: (post) => {
            // When the mutation finishes, invalidate and refetch
            queryClient.invalidateQueries({ queryKey: socialNetworkPostKeys.allLists() });
            queryClient.invalidateQueries({ queryKey: socialNetworkPostKeys.detailById(post.id) });
        },
    });
};

export default useUpdateSocialNetworkCalendarPostDateMutation;
