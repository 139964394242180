import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import camelcaseKeys from 'camelcase-keys';
import { psoCheckerSuggestedKeywords } from '@/api';
import { z } from 'zod';
import psoCheckerKeys from './psoCheckerKeys';

interface RawAIKeyword {
    label: string;
    rank: number;
    search_volume: number | null;
    difficulty_score: number | null;
}
export interface useAIKeywordsProps {
    episodeId: string;
    enabled?: boolean;
}

export const AIKeywordSchema = z.object({
    id: z.string(),
    label: z.string(),
    searchVolume: z.number().nullable(),
    difficultyScore: z.number().nullable(),
});

const AIKeywordsSchema = z.array(AIKeywordSchema);
export type AIKeyword = z.infer<typeof AIKeywordSchema>;

type Key = QueryFunctionContext<ReturnType<typeof psoCheckerKeys.aiKeywords>>;

const queryFn = async ({ queryKey: [{ episodeId }] }: Key) => {
    const { data } = await psoCheckerSuggestedKeywords(episodeId);
    const formattedData = data.map((item: RawAIKeyword) => ({
        id: crypto.randomUUID(),
        label: item.label.toLocaleLowerCase(),
        searchVolume: item.search_volume,
        difficultyScore: item.difficulty_score,
    }));

    // TODO: Do camelcase transformation in Axios interceptor
    return AIKeywordsSchema.parse(camelcaseKeys(formattedData, { deep: true }));
};

const useAIKeywords = ({ episodeId, enabled }: useAIKeywordsProps) => {
    return useQuery({
        queryKey: psoCheckerKeys.aiKeywords(episodeId),
        queryFn,
        enabled: !!episodeId && enabled,
    });
};

export default useAIKeywords;
