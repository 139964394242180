import { useContext, useEffect } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import getNewsletterSchema from '@/components/unorganized/EpisodeEditForm/schemas/newsletterSchema';
import { useIntl } from 'react-intl';
import EpisodeEditFormNewsletterEnabledField from '@/components/unorganized/EpisodeEditForm/EpisodeEditFormNewsletter/EpisodeEditFormNewsletterEnabledField';
import Stack from '@ui/layout/Stack';
import EpisodeEditFormNewsletterTitleField from '@/components/unorganized/EpisodeEditForm/EpisodeEditFormNewsletter/EpisodeEditFormNewsletterTitleField';
import EpisodeEditFormNewsletterMessageField from '@/components/unorganized/EpisodeEditForm/EpisodeEditFormNewsletter/EpisodeEditFormNewsletterMessageField';
import NextStepButton from '@/components/unorganized/EpisodeEditForm/NextStepButton';
import useUpdateEpisodeNewsletterTemplateMutation from '@queries/newsletter/useUpdateEpisodeNewsletterTemplateMutation.hook';
import { useEpisodeBeingEdited } from '@/context/EpisodeFormContext';
import { debounce } from 'lodash';
import styled from 'styled-components';
import EpisodeEditFormNewsletterPreviewButton from '@/components/unorganized/EpisodeEditForm/EpisodeEditFormNewsletter/EpisodeEditFormNewsletterPreviewButton';
import useEpisodeNewsletterPreviewQuery from '@queries/episodeNewsletterForm/useEpisodeNewsletterPreviewQuery.hook';
import { useOverlayTriggerState } from 'react-stately';
import Modal from '@ui/molecules/Modal';
import { EpisodeEditFormNewsletterErrorsCountContext } from '@/components/unorganized/EpisodeEditForm/contexts/EpisodeEditFormNewsletterErrorsCountContext';
import EpisodeEditFormNewsletterImageField from './EpisodeEditFormNewsletterImageField';
import useUserQuery from '@queries/user/useUserQuery.hook';
import updateNewsletterImageMutation from '@queries/newsletter/updateNewsletterImageMutation.hook';
import deleteNewsletterImageMutation from '@queries/newsletter/deleteNewsletterImageMutation.hook';
import { useEpisodeAILoading } from '@/components/EpisodeAI/contexts/EpisodeAILoadingContext';
import { useAccordionItem } from '@ui/atoms/AccordionItem/useAccordionItem.hook';

const Actions = styled.div`
    display: flex;
    justify-content: flex-end;
    column-gap: 0.5rem;
    align-items: center;

    ${(props) => props.theme.mediaQueries.tabletAndUp} {
        justify-content: space-between;
    }
`;

const NewsletterPreview = styled.div`
    height: 100%;
    overflow-y: auto;
`;

// TODO: Missing quota warning and quota update ?
const EpisodeEditFormNewsletterForm = ({ template }) => {
    const intl = useIntl();
    const { watch: watchEpisodeForm } = useFormContext();
    const { data: user } = useUserQuery();
    const locale = user?.language?.toLowerCase();
    const episode = useEpisodeBeingEdited();
    const episodeName = watchEpisodeForm('content.name');
    const schema = getNewsletterSchema(intl, locale);
    const previewModalState = useOverlayTriggerState({});
    const episodeNewsletterPreviewQuery = useEpisodeNewsletterPreviewQuery(episode.id, episodeName);
    const updateEpisodeNewsletterTemplateMutation = useUpdateEpisodeNewsletterTemplateMutation();
    const updateNewsletterImage = updateNewsletterImageMutation();
    const deleteNewsletterImage = deleteNewsletterImageMutation();
    const { setNewsletterErrorsCount } = useContext(EpisodeEditFormNewsletterErrorsCountContext);
    const [state] = useEpisodeAILoading();
    const isAILoading = state.get('newsletter-title') || state.get('newsletter-message') || false;
    const { open, isExpanded } = useAccordionItem();

    const methods = useForm({
        mode: 'onBlur',
        values: {
            activated: template.activated,
            title: template.title,
            messageTpl: template.messageTpl,
            file: template.imageUrl,
        },
        resetOptions: {
            keepDirtyValues: true,
            keepErrors: true,
        },
        resolver: yupResolver(schema),
    });

    const {
        watch,
        handleSubmit,
        formState: { errors },
    } = methods;

    // Pull form errors count up to the accordion
    useEffect(() => {
        setNewsletterErrorsCount(Object.keys(errors).length);
    }, [errors]);

    // Auto-submit form when an input value change
    useEffect(() => {
        const subscription = watch(() => {
            handleSubmit(onSubmit)();
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    /* Enable newsletter and open accordion panel on AI suggestions fetching */
    useEffect(() => {
        if (open && !isExpanded && isAILoading) {
            methods.setValue('activated', true);
            open();
        }
    }, [isAILoading]);

    // Save episode newsletter template
    const onSubmit = debounce((data) => {
        const { file, ...rest } = data;

        if (file === null && file !== template.imageUrl) {
            deleteNewsletterImage.mutate(template.id);
        }

        if (file && file !== template.imageUrl) {
            updateNewsletterImage.mutate({
                messageId: template.id,
                file: file?.[0] || null,
            });
        }

        updateEpisodeNewsletterTemplateMutation.mutate({
            episodeId: episode.id,
            template: rest,
        });
    }, 500);

    // Save episode newsletter template and fetch preview of the newsletter
    const onPreview = async () => {
        await handleSubmit(onSubmit)();
        await episodeNewsletterPreviewQuery.refetch();
        previewModalState.open();
    };

    const isNewsletterEnabled = watch('activated');

    return (
        <FormProvider {...methods}>
            <Stack $gap="2rem">
                <EpisodeEditFormNewsletterEnabledField />
                {isNewsletterEnabled ? (
                    <>
                        <EpisodeEditFormNewsletterTitleField />
                        <EpisodeEditFormNewsletterMessageField />
                        <EpisodeEditFormNewsletterImageField />
                        <Actions>
                            <EpisodeEditFormNewsletterPreviewButton onPress={onPreview} />
                            <NextStepButton />
                        </Actions>
                    </>
                ) : (
                    <NextStepButton />
                )}
            </Stack>
            {previewModalState.isOpen && (
                <Modal
                    size="medium"
                    isOpen={previewModalState.isOpen}
                    onClose={previewModalState.close}
                >
                    <NewsletterPreview
                        dangerouslySetInnerHTML={{ __html: episodeNewsletterPreviewQuery.data }}
                    />
                </Modal>
            )}
        </FormProvider>
    );
};

export default EpisodeEditFormNewsletterForm;
