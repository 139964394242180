import Button from '@ui/atoms/Button';
import Text from '@ui/atoms/Text';
import Stack from '@ui/layout/Stack';
import Modal from '@ui/molecules/Modal';
import { FormattedMessage } from 'react-intl';

const DeleteClipFormatModal = ({ onDelete, isOpen, onClose }) => {
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={<FormattedMessage defaultMessage="Souhaitez-vous supprimer ce clip ?" />}
            action={
                <>
                    <Button variant="secondary" onPress={onClose}>
                        <FormattedMessage defaultMessage="Annuler" />
                    </Button>
                    <Button onPress={onDelete}>
                        <FormattedMessage defaultMessage="Supprimer" />
                    </Button>
                </>
            }
        >
            <Stack $gap="2.5rem">
                <Text>
                    <FormattedMessage defaultMessage="Cette action est irréversible." />
                </Text>
            </Stack>
        </Modal>
    );
};

export default DeleteClipFormatModal;
