import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const Benefits = () => {
    return (
        <BenefitsList>
            <Benefit>
                <IconWrapper>
                    <Icon icon={icon({ name: 'arrow-trend-up', style: 'solid' })} />
                </IconWrapper>
                <BenefitContent>
                    <BenefitTitle>
                        <FormattedMessage defaultMessage="Suivez votre classement sur Apple Podcasts et Spotify" />
                    </BenefitTitle>
                    <BenefitDescription>
                        <FormattedMessage defaultMessage="Suivez l'évolution de votre classement au fil du temps, avec des vues distinctes pour chaque mot-clé et chaque plateforme." />
                    </BenefitDescription>
                </BenefitContent>
            </Benefit>
            <Benefit>
                <IconWrapper>
                    <Icon icon={icon({ name: 'hashtag', style: 'solid' })} />
                </IconWrapper>
                <BenefitContent>
                    <BenefitTitle>
                        <FormattedMessage defaultMessage="Évaluer le potentiel et la concurrence des mots-clés" />
                    </BenefitTitle>
                    <BenefitDescription>
                        <FormattedMessage defaultMessage="Suivez la popularité des mots-clés avec le volume de recherche et évaluez les difficultés de classement avec le score de difficulté." />
                    </BenefitDescription>
                </BenefitContent>
            </Benefit>
            <Benefit>
                <IconWrapper>
                    <Icon icon={icon({ name: 'user-group', style: 'solid' })} />
                </IconWrapper>
                <BenefitContent>
                    <BenefitTitle>
                        <FormattedMessage defaultMessage="Analyser vos concurrents" />
                    </BenefitTitle>
                    <BenefitDescription>
                        <FormattedMessage defaultMessage="Accédez aux 100 premiers résultats de recherche pour chaque mot-clé ciblé et comparez votre classement à celui de vos concurrents en temps réel." />
                    </BenefitDescription>
                </BenefitContent>
            </Benefit>
            <Benefit>
                <IconWrapper>
                    <Icon icon={icon({ name: 'chart-fft', style: 'solid' })} />
                </IconWrapper>
                <BenefitContent>
                    <BenefitTitle>
                        <FormattedMessage defaultMessage="Optimisez vos métadonnées d'exposition avec PSO Checker" />
                    </BenefitTitle>
                    <BenefitDescription>
                        <FormattedMessage defaultMessage="Suivez la liste de recommandations du PSO Checker pour optimiser vos titres, descriptions et tags afin d'obtenir une visibilité maximale." />
                    </BenefitDescription>
                </BenefitContent>
            </Benefit>
            <Benefit>
                <IconWrapper>
                    <Icon icon={icon({ name: 'circle-check', style: 'solid' })} />
                </IconWrapper>
                <BenefitContent>
                    <BenefitTitle>
                        <FormattedMessage defaultMessage="Améliorez votre visibilité grâce aux suggestions de mots-clés de l'IA" />
                    </BenefitTitle>
                    <BenefitDescription>
                        <FormattedMessage defaultMessage="Identifiez de nouvelles opportunités grâce à des suggestions de mots-clés remontées par notre IA." />
                    </BenefitDescription>
                </BenefitContent>
            </Benefit>
            <Benefit>
                <IconWrapper>
                    <Icon icon={icon({ name: 'earth-americas', style: 'solid' })} />
                </IconWrapper>
                <BenefitContent>
                    <BenefitTitle>
                        <FormattedMessage defaultMessage="Obtenez une vue d'ensemble de vos données" />
                    </BenefitTitle>
                    <BenefitDescription>
                        <FormattedMessage defaultMessage="Trouvez toutes les tendances et les changements de position en un seul endroit, et suivez les progrès de votre visibilité en un coup d'œil." />
                    </BenefitDescription>
                </BenefitContent>
            </Benefit>
            <Benefit>
                <IconWrapper>
                    <Icon icon={icon({ name: 'sparkles', style: 'solid' })} />
                </IconWrapper>
                <BenefitContent>
                    <BenefitTitle>
                        <FormattedMessage defaultMessage="Suivez vos performances dans plusieurs pays" />
                    </BenefitTitle>
                    <BenefitDescription>
                        <FormattedMessage defaultMessage="Adaptez votre stratégie PSO en fonction des performances géographiques pour une pertinence locale et une découvrabilité mondiale." />
                    </BenefitDescription>
                </BenefitContent>
            </Benefit>
        </BenefitsList>
    );
};

const Icon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
`;
const IconWrapper = styled.div`
    background-color: var(--primary50);
    color: var(--primary500);
    border-radius: var(--r-s);
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
`;
const BenefitsList = styled.ul`
    list-style: none;
    padding-left: 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1.5rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        grid-template-columns: 1fr 1fr;
        grid-gap: 2rem;
    }
`;
const Benefit = styled.li`
    display: flex;
    align-items: start;
    column-gap: 0.75rem;
`;
const BenefitContent = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;
    flex-grow: 1;
`;
const BenefitTitle = styled.div`
    font-size: var(--fs-body-l);
    font-weight: var(--fw-semibold);
`;
const BenefitDescription = styled.div`
    color: var(--neutral500);
`;

export default Benefits;
