import { useIntl } from 'react-intl';
import { MAX_AUDIO_FILE_SIZE } from '@/shared/config/constants';
import { checkAudioFileFormat, checkAudioFileSize } from '@/helpers';
import { z } from 'zod';
import { User } from '@/queries/user/useUserQuery.hook';

const useNonHostingEpisodeAudioFileSchema = (locale: User['language'] = 'en') => {
    const intl = useIntl();

    return z.object({
        audioFile: z
            .array(z.instanceof(File))
            .refine(
                (file) => {
                    if (Array.isArray(file)) {
                        return checkAudioFileFormat(file?.[0]);
                    }
                    return checkAudioFileFormat(file);
                },
                {
                    message: intl.formatMessage({
                        defaultMessage:
                            'Votre fichier doit être au format .MP3, .WAV, .M4A, .OGG ou .FLAC',
                    }) as string,
                },
            )
            .refine(
                (file) => {
                    if (Array.isArray(file)) {
                        return checkAudioFileSize(file?.[0], locale);
                    }
                    return checkAudioFileSize(file, locale);
                },
                {
                    message: intl.formatMessage(
                        { defaultMessage: 'Votre fichier doit peser au maximum {max}Mo' },
                        { max: MAX_AUDIO_FILE_SIZE / 1000000 },
                    ) as string,
                },
            )
            .nullable(),
    });
};

export type NonHostingEpisodeAudioFileSchema = z.infer<
    ReturnType<typeof useNonHostingEpisodeAudioFileSchema>
>;

export type NonHostingEpisodeAudioFile = NonHostingEpisodeAudioFileSchema['audioFile'];

export default useNonHostingEpisodeAudioFileSchema;
