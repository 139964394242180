import Model from './Model';
import { apiCall } from '@/components/legacy/api';

class ApplePodcastsReviewsNotificationModel extends Model {
    static default = () => ({
        showId: '',
        email: '',
        notifyEach: false,
    });

    static attributes = {
        showId: {
            fromApi: { key: 'show_id', resolve: (x) => x },
            toApi: { key: null },
        },
        email: 'email_notified',
        notifyEach: 'notifications_subscribed',
    };

    @apiCall
    update(formData) {
        return this.store.updateApplePodcastsReviewsNotification(this.showId, formData);
    }
}

export default ApplePodcastsReviewsNotificationModel;
