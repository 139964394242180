import { Table } from '@tanstack/react-table';
import { DownloadsComparisonData } from '@app/molecules/DownloadsComparisonContent';
import styled from 'styled-components';
import NoDataChartOverlay from '@/components/charts/NoData/NoDataChartOverlay';

interface DownloadsComparisonTableBodyNoDataProps {
    table: Table<DownloadsComparisonData>;
}

const DownloadsComparisonTableBodyNoData = ({ table }: DownloadsComparisonTableBodyNoDataProps) => {
    return (
        <Tbody>
            {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                        return <td key={cell.id} />;
                    })}
                </tr>
            ))}
            <NoDataChartOverlay />
        </Tbody>
    );
};

const Tbody = styled.tbody`
    position: relative;
    background: var(--white);
    height: 14.5rem;

    tr td {
        border: none;
    }
`;

export default DownloadsComparisonTableBodyNoData;
