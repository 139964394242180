import { css } from 'styled-components';

export const dropZoneStyles = css`
    border: 2px dashed var(--neutral100);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--r-l);
    padding: 2rem;
`;

export const dropZoneStateStyles = css<{ $hasError: boolean }>`
    cursor: pointer;

    &[data-disabled] {
        cursor: initial;
    }

    &[data-hovered]:not([data-disabled]) {
        background-color: var(--primary50);
        border-color: var(--primary500);
    }

    &[data-drop-target]:not([data-disabled]) {
        background-color: var(--primary50);
        border-color: var(--primary500);
    }

    ${({ $hasError }) =>
        $hasError &&
        css`
            border-color: var(--alert100);
        `}
`;
