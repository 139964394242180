import { useParams } from 'react-router';
import { FormattedMessage } from 'react-intl';
import Text from '@ui/atoms/Text';
import WebsiteSettingsForm from './WebsiteSettingsForm';
import useWebsiteSettingsQuery from '@/queries/websiteSettings/useWebsiteSettingsQuery.hook';
import { useAmplitudeLogEvent } from '@/shared/hooks/useAmplitude';
import styled from 'styled-components';

const WebsiteSettings = () => {
    useAmplitudeLogEvent('Website Tab Viewed');
    const { showId }: { showId: string } = useParams();
    const settings = useWebsiteSettingsQuery({ showId });

    if (settings.isLoading || settings.isError) return null;
    const recommendedShows = settings.data?.recommendedShows.map((show) => ({ value: show }));
    const defaultValues = {
        isPublished: settings.data?.isPublished,
        isMultiShowEnabled: settings.data?.multiShow,
        displayTranscript: settings.data?.displayTranscript,
        useTwitter: !!settings.data?.twitterAccount,
        twitterAccount: settings.data?.twitterAccount,
        recommendedShows: recommendedShows?.length > 0 ? recommendedShows : [{ value: '' }],
        color: settings.data?.color,
        cover: settings.data?.coverPageUrl,
        featuredEpisode:
            settings.data?.featuredEpisodeType === 'specific'
                ? settings.data?.featuredEpisodeId
                : settings.data?.featuredEpisodeType === null
                ? 'none'
                : 'latest',
    };

    return (
        <PagePodcastSettingsWrapper>
            <PagePodcastSettingsInnerWrapper>
                <Text color="--neutral500">
                    <FormattedMessage defaultMessage="Le Site Web permet de présenter votre émission via un simple lien. Les visiteurs pourront lire vos épisodes, voir vos listes de lecture et bien plus." />
                </Text>
                <WebsiteSettingsForm defaultValues={defaultValues} />
            </PagePodcastSettingsInnerWrapper>
        </PagePodcastSettingsWrapper>
    );
};

const PagePodcastSettingsWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding);

    ${({ theme }) => theme.mediaQueries.mobile} {
        padding: var(--container-padding-mobile);
    }
`;
const PagePodcastSettingsInnerWrapper = styled.div`
    max-width: 680px;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export default WebsiteSettings;
