import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import Checkbox from '@/components/ui/Checkbox';
import { useEpisodeSelectContext } from '@/context/EpisodeSelectContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Button from '@/components/Button';
import { useParams } from 'react-router';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import { USER_ROLES } from '@/utils/constants';
import MovePodcastsModal from '@app/organisms/MovePodcastsModal';
import DeletePodcastsModal from '@app/organisms/DeletePodcastsModal';
import { useSwitch } from '@hooks/useSwitch';
import useDeleteAllEpisodesMutation from '@/queries/episode/useDeleteAllEpisodesMutation.hook';
import useMoveAllEpisodesMutation from '@/queries/episode/useMoveAllEpisodesMutation.hook';
import styled from 'styled-components';

interface SelectionToolbarProps {
    hasSeveralPages: boolean;
    totalEpisodeCount: number;
    episodesPerPage: number;
}

const SelectionToolbar = ({
    hasSeveralPages,
    totalEpisodeCount,
    episodesPerPage,
}: SelectionToolbarProps) => {
    const { showId } = useParams<{ showId: string }>();
    const { data: show } = useShowQuery(showId);
    const { allSelectedOnPage, allSelected, toggleAllOnPage, selected, toggleAll } =
        useEpisodeSelectContext();
    const [episodeCount, setEpisodeCount] = useState(selected?.length || 0);
    const [hasSelectedAtLeastOne, setHasSelectedAtLeastOne] = useState(
        allSelected || selected.length > 0,
    );

    useEffect(() => {
        if (allSelected) {
            setEpisodeCount(totalEpisodeCount);
            return;
        }
        if (allSelectedOnPage) {
            setEpisodeCount(episodesPerPage);
            return;
        }
        setEpisodeCount(selected?.length || 0);
    }, [selected, allSelected, allSelectedOnPage]);

    useEffect(() => {
        if (!allSelected && !allSelectedOnPage && selected.length === 0) {
            setHasSelectedAtLeastOne(false);
            return;
        }
        setHasSelectedAtLeastOne(true);
    }, [selected, allSelected, allSelectedOnPage]);

    const deleteAllEpisodes = useDeleteAllEpisodesMutation();
    const moveAllEpisodes = useMoveAllEpisodesMutation();

    const [moveOpened, openMove, closeMove] = useSwitch(false);
    const [deleteOpened, openDelete, closeDelete] = useSwitch(false);

    const userRole = show?.userRole;
    const userHasWriteAccess = ![USER_ROLES.VIEWER, USER_ROLES.EDITOR].includes(
        userRole as 'viewer' | 'editor',
    );

    if (userRole === USER_ROLES.VIEWER) return null;

    return (
        <>
            <SelectionToolbarWrapper>
                <SelectCheckbox
                    id="selectAll"
                    $hasSelectedAtLeastOne={hasSelectedAtLeastOne}
                    isSelected={allSelectedOnPage}
                    onChange={toggleAllOnPage}
                />
                {hasSelectedAtLeastOne ? (
                    <ActionWrapper>
                        {userHasWriteAccess && (
                            <ActionButton
                                variant="ghost"
                                onPress={openMove}
                                startIcon={
                                    <FontAwesomeIcon
                                        icon={icon({ name: 'arrow-turn-left-up', style: 'solid' })}
                                    />
                                }
                            >
                                <FormattedMessage defaultMessage="Déplacer" />
                            </ActionButton>
                        )}
                        {userHasWriteAccess && (
                            <DestructiveActionButton
                                variant="ghost"
                                onPress={openDelete}
                                startIcon={
                                    <FontAwesomeIcon
                                        icon={icon({ name: 'trash', style: 'solid' })}
                                    />
                                }
                            >
                                <FormattedMessage defaultMessage="Supprimer" />
                            </DestructiveActionButton>
                        )}
                    </ActionWrapper>
                ) : (
                    <Label htmlFor="selectAll">
                        <FormattedMessage defaultMessage="Tout sélectionner" />
                    </Label>
                )}
                {hasSeveralPages &&
                    allSelectedOnPage &&
                    (allSelected ? (
                        <ToggleAllOuterWrapper>
                            <ToggleAllWrapper>
                                <FormattedMessage
                                    defaultMessage="{count, plural, one {L’{countText} de votre émission est sélectionné} other {Les {countText} de votre émission sont sélectionnés}}."
                                    values={{
                                        countText: (
                                            <strong>
                                                <FormattedMessage
                                                    defaultMessage="{count, plural, one {épisode} other {# épisodes}}"
                                                    values={{ count: totalEpisodeCount }}
                                                />
                                            </strong>
                                        ),
                                        count: totalEpisodeCount,
                                    }}
                                />
                            </ToggleAllWrapper>
                            <ToggleButton variant="ghost" onPress={toggleAll}>
                                <FormattedMessage defaultMessage="Réinitialiser la sélection" />
                            </ToggleButton>
                        </ToggleAllOuterWrapper>
                    ) : (
                        <ToggleAllOuterWrapper>
                            <ToggleAllWrapper>
                                <FormattedMessage
                                    defaultMessage="{count, plural, one {L’{countText} de la page est sélectionné} other {Les {countText} de la page sont sélectionnés}}."
                                    values={{
                                        countText: (
                                            <strong>
                                                <FormattedMessage
                                                    defaultMessage="{count, plural, one {épisode} other {# épisodes}}"
                                                    values={{ count: episodesPerPage }}
                                                />
                                            </strong>
                                        ),
                                        count: episodesPerPage,
                                    }}
                                />
                            </ToggleAllWrapper>
                            <ToggleButton variant="ghost" onPress={toggleAll}>
                                <FormattedMessage
                                    defaultMessage="Sélectionner {count, plural, one {l'épisode} other {les # épisodes}} de l'émission"
                                    values={{ count: totalEpisodeCount }}
                                />
                            </ToggleButton>
                        </ToggleAllOuterWrapper>
                    ))}
            </SelectionToolbarWrapper>
            <MovePodcastsModal
                isOpened={moveOpened}
                onClose={closeMove}
                count={episodeCount}
                onMove={(newShowId: string) => {
                    moveAllEpisodes.mutate(
                        { showId, newShowId },
                        {
                            onSettled: () => {
                                closeMove();
                            },
                        },
                    );
                }}
                moving={moveAllEpisodes.isLoading}
            />
            <DeletePodcastsModal
                isOpened={deleteOpened}
                onClose={closeDelete}
                count={episodeCount}
                onDelete={() => {
                    deleteAllEpisodes.mutate(
                        { showId },
                        {
                            onSettled: () => {
                                closeDelete();
                            },
                        },
                    );
                }}
                deleting={deleteAllEpisodes.isLoading}
            />
        </>
    );
};

const SelectionToolbarWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.75rem;
    min-height: 1.5rem;
    flex-wrap: wrap;
    padding-inline: 1.5rem;
`;
const SelectCheckbox = styled(Checkbox)<{ $hasSelectedAtLeastOne: boolean }>``;
const ActionWrapper = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
`;
const ActionButton = styled(Button)`
    color: var(--primary);
    padding: 0;
`;
const DestructiveActionButton = styled(ActionButton)`
    color: var(--alert);
`;
const ToggleAllWrapper = styled.span``;
const ToggleAllOuterWrapper = styled.div`
    display: flex;
    gap: 0.5rem;
    align-items: center;

    flex-direction: column;

    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        flex-direction: row;
    }
`;
const ToggleButton = styled(Button)`
    padding: 0;
`;
const Label = styled.label`
    color: var(--neutral500);
`;

export default SelectionToolbar;
