import { FormattedMessage, useIntl } from 'react-intl';
import { useStatsDateSelectorContext } from '@/context/StatsDateSelectorContext';
import styled from 'styled-components';
import { useParams } from 'react-router';
import useCampaignOperatingSystemsQuery from '@/queries/stats/useCampaignOperatingSystemsQuery.hook';
import useShowOperatingSystemsQuery from '@/queries/stats/useShowOperatingSystemsQuery.hook';
import SourceOperatingSystemsChart from './SourcesOperatingSystemsChart';
import ShortListFallback from '@/components/unorganized/ShortListFallback';
import useQuery from '@/shared/hooks/useQuery';

const SourcesOperatingSystems = () => {
    const intl = useIntl();
    const { startDate, endDate } = useStatsDateSelectorContext();
    const { showId } = useParams<{
        showId: string;
    }>();
    const query = useQuery();
    const campaignId = query.get('campaignId');
    const campaignOperatingSystems = useCampaignOperatingSystemsQuery({
        campaignId: campaignId || '',
        query: { start: startDate, end: endDate },
        options: { enabled: !!campaignId },
    });
    const showOperatingSystems = useShowOperatingSystemsQuery({
        showId,
        query: { start: startDate, end: endDate },
        options: { enabled: !campaignId && !!showId },
    });
    const operatingSystems = campaignId ? campaignOperatingSystems.data : showOperatingSystems.data;
    const emptyStateAxis = [
        'Android',
        'Linux',
        'Windows',
        'iOS',
        'Mac',
        intl.formatMessage({ defaultMessage: 'Autres' }),
    ];

    if (operatingSystems) {
        const series = operatingSystems.map((system) => system.percentage);
        const dataAxis = operatingSystems.map((system) => {
            if (system.osName === 'Other') {
                return intl.formatMessage({ defaultMessage: 'Autres' });
            }
            return system.osName;
        });
        const xAxisCategories = operatingSystems.length > 0 ? dataAxis : emptyStateAxis;

        return (
            <>
                <OuterHeader>
                    <TitleAndDescription>
                        <Title>
                            <FormattedMessage defaultMessage="Systèmes d'exploitation" />
                        </Title>
                        <Description>
                            <FormattedMessage defaultMessage="Quels sont les systèmes d'exploitation les plus appréciés de vos auditeurs ?" />
                        </Description>
                    </TitleAndDescription>
                </OuterHeader>
                <BarGraphWrapper>
                    <SourceOperatingSystemsChart
                        options={{ xaxis: { categories: xAxisCategories } }}
                        series={series}
                    />
                </BarGraphWrapper>
            </>
        );
    }

    return <ShortListFallback />; // TODO: Handle loading state
};

const BarGraphWrapper = styled.div`
    min-height: 150px;
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding);

    ${({ theme }) => theme.mediaQueries.mobile} {
        padding: var(--container-padding-mobile);
    }
`;
const OuterHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;
const TitleAndDescription = styled.div``;
const Title = styled.h2`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: var(--fs-heading-m);
`;
const Description = styled.p``;

export default SourcesOperatingSystems;
