import { useEffect, useState } from 'react';
import Text from '@/components/ui/atoms/Text';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import RouterLink from '@/components/Link/RouterLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Button from '@/components/Button';
import useSearchApplePodcastsQuery from '@/queries/rss/useSearchApplePodcastsQuery.hook';
import { Key } from 'react-aria';
import type { ListBoxItem as IListBoxItem } from '@/components/ui/atoms/ListBoxItem';
import { IMG_PLACEHOLDER } from '@/shared/config/constants';
import ComboBox from '@/components/ui/atoms/ComboBox';
import ListBoxItem from '@/components/ui/atoms/ListBoxItem';
import Alert from '@/components/ui/atoms/Alert';
import Show from './Show';
import ErrorMessage from '@/components/ui/atoms/ErrorMessage';
import useImportRssShowMutation from '@/queries/rss/useImportRssShowMutation.hook';
import useChannelQuery from '@/queries/channel/useChannelQuery.hook';
import { useHistory } from 'react-router';
import { useBodyToastQueue } from '@/shared/hooks/useBodyToastQueue.hook';

const YOUTUBE_CHANNEL_ID_REGEX =
    /^https:\/\/www\.youtube\.com\/(?:@[\w-]+|channel\/[A-Za-z0-9_-]{24})\/?$/;

const Youtube = () => {
    const [show, setShow] = useState<IListBoxItem | null>(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [validationError, setValidationError] = useState<string | null>(null);
    const [shouldDisplayErrorMessage, setShouldDisplayErrorMessage] = useState(false);
    const { data: applePodcastResults, isFetching: applePodcastsResultsAreLoading } =
        useSearchApplePodcastsQuery({ searchQuery }, { enabled: !validationError });
    const intl = useIntl();
    const importShow = useImportRssShowMutation();
    const { data: channel } = useChannelQuery();
    const history = useHistory();
    const toast = useBodyToastQueue();

    const results =
        applePodcastResults?.map((result) => ({
            id: crypto.randomUUID(),
            name: result.showTitle,
            image: result.image ?? IMG_PLACEHOLDER,
            description: result.author,
            episodesCount: result.episodeCount,
            url: result.url,
        })) || [];

    const handleSearchYoutubePodcast = (query: string) => {
        setValidationError(null);
        setShouldDisplayErrorMessage(false);
        setShow(null);
        handleYoutubelinkValidation(query);
        setInputValue(query);
        setSearchQuery(query);
    };

    const handleShowSelect = (showId: Key | null) => {
        const show = results?.find((result) => result.id === showId);
        if (!show) return;
        setShow(show);
    };

    const handleImport = () => {
        if (!channel || !show) return;
        importShow.mutate(
            {
                channelId: `${channel.id}`,
                payload: {
                    url: show.url,
                },
            },
            {
                onSuccess: (data) => {
                    history.replace(`/app/onboarding/youtube/success?showId=${data.id}`);
                },
                onError: () => {
                    toast.alert(
                        <FormattedMessage defaultMessage="Oups, il semblerait que nous rencontrons un souci avec votre flux RSS. Contactez-nous pour que l'on puisse vous aider ! 💜" />,
                    );
                },
            },
        );
    };

    const handleBlur = () => {
        if (show) return;
        const isValid = handleYoutubelinkValidation(inputValue);
        setShouldDisplayErrorMessage(!isValid);
    };

    const handleYoutubelinkValidation = (link: string) => {
        if (!link) return true;

        const isValid = YOUTUBE_CHANNEL_ID_REGEX.test(link);

        if (!isValid) {
            setValidationError(
                isValid
                    ? null
                    : intl.formatMessage({
                          defaultMessage:
                              'Oups, ce lien ne semble pas correspondre à une chaîne YouTube valide. Merci de réessayer en utilisant ce format : https://www.youtube.com/@votre-identifiant-de-chaîne.',
                      }),
            );
        }

        return isValid;
    };

    const handleReset = () => {
        setShow(null);
        setSearchQuery('');
        setInputValue('');
    };

    useEffect(() => {
        if (show) {
            setInputValue(show.name);
        }
    }, [show]);

    return (
        <Wrapper>
            <Header>
                <Text variant="headingL" fontWeight="--fw-bold">
                    <FormattedMessage defaultMessage="C'est parti pour l'import de votre podcast de YouTube vers Ausha !" />
                </Text>
                <Text color="--neutral500">
                    <FormattedMessage defaultMessage="Partagez-nous l'url de votre chaine YouTube, et nous convertirons vos vidéos en épisodes audio pour générer un flux RSS." />
                </Text>
            </Header>
            <Container>
                <FullWidthComboBox
                    items={results}
                    label={<FormattedMessage defaultMessage="Votre podcast sur YouTube" />}
                    onInputChange={handleSearchYoutubePodcast}
                    onSelectionChange={handleShowSelect}
                    isLoading={applePodcastsResultsAreLoading}
                    allowsEmptyCollection={!!searchQuery}
                    menuTrigger="focus"
                    autoFocus={true}
                    inputValue={inputValue}
                    renderEmptyState={() => (
                        <NoResultsWrapper>
                            <FormattedMessage
                                defaultMessage="Aucun résultat trouvé pour «{searchQuery}»"
                                values={{ searchQuery }}
                            />
                        </NoResultsWrapper>
                    )}
                    placeholder={intl.formatMessage({
                        defaultMessage: "Entrez l'URL de votre chaîne YouTube",
                    })}
                    selectedKey={show?.id}
                    hideIconButton
                    showResetButton
                    onReset={handleReset}
                    onBlur={handleBlur}
                >
                    {(item: IListBoxItem) => <ListBoxItem {...item} />}
                </FullWidthComboBox>
                {validationError && shouldDisplayErrorMessage && (
                    <ErrorMessage>{validationError}</ErrorMessage>
                )}
                {!!show && <Show show={show} />}
                <Alert variant="robot">
                    <Text fontWeight="--fw-semibold">
                        <FormattedMessage defaultMessage="Une fois la synchronisation activée, toutes vos futures vidéos publiées sur Youtube seront automatiquement converties en audio et hébergées sur Ausha !" />
                    </Text>
                </Alert>
                {!!show && (
                    <Disclaimer>
                        <FormattedMessage defaultMessage="En validant, vous confirmez que vous possédez les droits de ce podcast." />
                    </Disclaimer>
                )}
            </Container>
            <Actions $hasMany={!!show}>
                <RouterLink
                    startIcon={<LinkIcon icon={icon({ name: 'arrow-left', style: 'solid' })} />}
                    variant="secondary"
                    to="/app/onboarding"
                >
                    <FormattedMessage id="goBack" defaultMessage="Retour" />
                </RouterLink>
                {!!show && (
                    <Button
                        endIcon={<ArrowIcon icon={icon({ name: 'arrow-right', style: 'solid' })} />}
                        onPress={handleImport}
                        isLoading={importShow.isLoading}
                    >
                        <FormattedMessage defaultMessage="Importez votre podcast YouTube" />
                    </Button>
                )}
            </Actions>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;

    & > :last-child {
        align-self: center;
    }
`;
const Header = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;
`;
const Container = styled.div`
    padding: 2rem;
    background-color: var(--white);
    border-radius: var(--r-l);
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
`;
const LinkIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const ArrowIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const Actions = styled.div<{ $hasMany: boolean }>`
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 1rem;
    justify-content: ${({ $hasMany }) => ($hasMany ? 'space-between' : 'center')};
`;
const Disclaimer = styled.div`
    color: var(--neutral500);
    font-size: var(--fs-vody-m);
    margin-top: 1.5rem;
`;
const FullWidthComboBox = styled(ComboBox)`
    max-width: 100%;
`;
const NoResultsWrapper = styled.div`
    padding: 0.5rem;
    color: var(--neutral);
`;

export default Youtube;
