import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchApplePodcastsReviewsNotification } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import statsKeys from './statsKeys';
import { z } from 'zod';

export const AppleReviewNotificationSchema = z.object({
    emailNotified: z.string().nullable(),
    notificationsSubscribed: z.boolean().nullable(),
    showId: z.number(),
});
export type AppleReviewNotification = z.infer<typeof AppleReviewNotificationSchema>;
type Key = QueryFunctionContext<ReturnType<typeof statsKeys.appleReviewsNotifications>>;
type InputProps = {
    showId: string;
};

const queryFn = async ({ queryKey: [{ showId }] }: Key) => {
    const { data } = await fetchApplePodcastsReviewsNotification(showId);
    return AppleReviewNotificationSchema.parse(camelcaseKeys(data, { deep: true }));
};

const useAppleReviewsNotificationsQuery = ({ showId }: InputProps) => {
    return useQuery({
        queryKey: statsKeys.appleReviewsNotifications(showId),
        queryFn,
    });
};

export default useAppleReviewsNotificationsQuery;
