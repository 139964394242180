import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchRssInfo } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import rssKeys from './rssKeys';
import { z } from 'zod';

export const RssInfoSchema = z.object({
    author: z.string(),
    episodeCount: z.number(),
    image: z.string().nullable(),
    showTitle: z.string(),
    url: z.string(),
});
export type RssInfo = z.infer<typeof RssInfoSchema>;
type Key = QueryFunctionContext<ReturnType<typeof rssKeys.rssInfo>>;
type InputProps = {
    payload: { url: string };
};

const queryFn = async ({ queryKey: [{ payload }] }: Key) => {
    const { data } = await fetchRssInfo(payload);
    return RssInfoSchema.parse(camelcaseKeys(data, { deep: true }));
};

const useRssInfoQuery = ({ payload }: InputProps) => {
    return useQuery({
        queryKey: rssKeys.rssInfo(payload),
        queryFn,
        enabled: !!payload.url,
    });
};

export default useRssInfoQuery;
