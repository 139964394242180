import { FormattedMessage } from 'react-intl';
import Center from '@ui/layout/Center';
import Text from '@/components/ui/atoms/Text';

const Error = () => (
    <Center>
        <Text textAlign="center">
            <FormattedMessage defaultMessage="Une erreur s'est produite lors de la transcription de votre clip." />
        </Text>
    </Center>
);

export default Error;
