import { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { MenuTrigger, Popover } from 'react-aria-components';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import { CAMPAIGN_STATUS, USER_ROLES } from '@/shared/config/constants';
import styled from 'styled-components';
import MenuDropdown from '@/components/ui/atoms/MenuDropdown';
import MenuDropdownItem from '@/components/ui/atoms/MenuDropdown/MenuDropdownItem';
import IconButton from '@/components/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useBodyToastQueue } from '@/shared/hooks/useBodyToastQueue.hook';
import type { Campaign } from '@/queries/campaign/useCampaignsQuery';
import DeleteModal from './DeleteModal';
import deleteCampaignMutation from '@/queries/campaign/deleteCampaignMutation';
import updateCampaignMutation from '@/queries/campaign/updateCampaignMutation';
import PauseModal from '../../../../../PauseModal';
import { useQueryClient } from '@tanstack/react-query';
import campaignKeys from '@/queries/campaign/campaignKeys';

interface ContextualMenuProps {
    campaign: Campaign;
}

const ContextualMenu = ({ campaign }: ContextualMenuProps) => {
    const history = useHistory();
    const { showId } = useParams<{ showId: string }>();
    const { data: show } = useShowQuery(showId);
    const toast = useBodyToastQueue();
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isPauseModalOpen, setIsPauseModalOpen] = useState(false);
    const deleteCampaign = deleteCampaignMutation();
    const updateCampaign = updateCampaignMutation();
    const userHasWriteAccess =
        show &&
        ![USER_ROLES.VIEWER, USER_ROLES.EDITOR].includes(show.userRole as 'viewer' | 'editor');
    const campaignIsActive = campaign?.state === CAMPAIGN_STATUS.ACTIVE;
    const campaignIsPaused = campaign?.state === CAMPAIGN_STATUS.PAUSED;
    const queryClient = useQueryClient();

    const handleDeleteCampaign = () => {
        if (!campaign?.id) return;
        deleteCampaign.mutate(`${campaign.id}`, {
            onSuccess: () => {
                queryClient.invalidateQueries({ queryKey: campaignKeys.allLists() });
                setIsDeleteModalOpen(false);
            },
            onError: () => {
                toast.alert();
            },
        });
    };

    const handlePlayPause = () => {
        if (!campaign?.id) return;
        updateCampaign.mutate(
            {
                campaignId: campaign.id,
                campaign: {
                    state: campaignIsPaused ? CAMPAIGN_STATUS.ACTIVE : CAMPAIGN_STATUS.PAUSED,
                },
            },
            {
                onSuccess: () => {
                    queryClient.invalidateQueries({ queryKey: campaignKeys.allLists() });
                    setIsPauseModalOpen(false);
                },
                onError: () => {
                    toast.alert();
                },
            },
        );
    };

    return (
        <>
            <MenuTrigger>
                <TriggerButton
                    aria-label="Menu"
                    variant="ghost"
                    icon={<EllipsisIcon icon={icon({ name: 'ellipsis', style: 'solid' })} />}
                />
                <PopoverStyled placement="bottom end">
                    <MenuDropdownStyled>
                        {campaign && campaignIsActive && (
                            <MenuDropdownItem onAction={() => setIsPauseModalOpen(true)}>
                                <MenuItemIcon icon={icon({ name: 'pause', style: 'solid' })} />
                                <FormattedMessage defaultMessage="Mettre en pause" />
                            </MenuDropdownItem>
                        )}
                        {campaign && campaignIsPaused && (
                            <MenuDropdownItem onAction={() => setIsPauseModalOpen(true)}>
                                <MenuItemIcon icon={icon({ name: 'play', style: 'solid' })} />
                                <FormattedMessage defaultMessage="Republier" />
                            </MenuDropdownItem>
                        )}
                        <MenuDropdownItem
                            onAction={() => {
                                campaign?.id &&
                                    history.push(
                                        `/app/show/${showId}/stats/campaign/${campaign.id}/listeners`,
                                    );
                            }}
                        >
                            <MenuItemIcon icon={icon({ name: 'chart-simple', style: 'solid' })} />
                            <FormattedMessage defaultMessage="Statistiques" />
                        </MenuDropdownItem>
                        {userHasWriteAccess && (
                            <MenuDropdownItem
                                onAction={() => setIsDeleteModalOpen(true)}
                                color="--alert"
                            >
                                <MenuItemIcon icon={icon({ name: 'trash', style: 'solid' })} />
                                <FormattedMessage defaultMessage="Supprimer" />
                            </MenuDropdownItem>
                        )}
                    </MenuDropdownStyled>
                </PopoverStyled>
            </MenuTrigger>
            <PauseModal
                isOpen={isPauseModalOpen}
                onOpenChange={setIsPauseModalOpen}
                onPlayPause={handlePlayPause}
                isLoading={updateCampaign.isLoading}
                isPaused={campaignIsPaused}
            />
            <DeleteModal
                isOpen={isDeleteModalOpen}
                onOpenChange={setIsDeleteModalOpen}
                isLoading={deleteCampaign.isLoading}
                onDelete={handleDeleteCampaign}
            />
        </>
    );
};

const TriggerButton = styled(IconButton)`
    padding: 0;
    transition-duration: 0.2s;
    color: var(--neutral500);

    &:hover {
        color: var(--neutral600);
    }
`;
const EllipsisIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
`;
const MenuItemIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const PopoverStyled = styled(Popover)`
    width: 100%;
    max-width: fit-content;
    min-width: 10rem;
    z-index: 1 !important;
`;
const MenuDropdownStyled = styled(MenuDropdown)`
    max-width: fit-content;
    min-width: 10rem;
`;

export default ContextualMenu;
