import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Text from '@ui/atoms/Text';
import QuotaCard from '../QuotaCard';
import useSubscriptionQuery from '@queries/subscription/useSubscriptionQuery.hook';
import useInternalRouteMapping from '@/shared/hooks/useInternalRouteMapping.hook';
import useTranscriptQuotaQuery from '@queries/quota/useTranscriptQuotaQuery.hook';
import TranscriptionEditQuotaConfirmationModal from '@/components/unorganized/TranscriptionEditQuotaConfirmationModal';
import TranscriptionEditQuotaModal from '@/components/unorganized/TranscriptionEditQuotaModal';
import useUpdateTranscriptQuotaMutation from '@queries/quota/useUpdateTranscriptQuotaMutation.hook';
import ExternalLink from '@/components/Link/ExternalLink';
import { useBodyToastQueue } from '@/shared/hooks/useBodyToastQueue.hook';
import styled from 'styled-components';

const TranscriptionMinutesQuota = () => {
    const intl = useIntl();
    const toast = useBodyToastQueue();
    const { push } = useInternalRouteMapping();
    const subscription = useSubscriptionQuery();
    const transcriptionQuota = useTranscriptQuotaQuery();
    const [isQuotaModalOpen, setIsQuotaModalOpen] = useState(false);
    const [isQuotaConfirmationModalOpen, setIsQuotaConfirmationModalOpen] = useState(false);
    const updateQuota = useUpdateTranscriptQuotaMutation();
    const [quotaQuantity, setQuotaQuantity] = useState<number | null>(null);

    const isFreemium = subscription?.data?.isFreemium;
    const actionLabel = isFreemium ? (
        <FormattedMessage defaultMessage="Changer d'offre" />
    ) : (
        <FormattedMessage defaultMessage="Modifier la limite" />
    );

    const handleUpdateQuota = (quantity: number) => {
        updateQuota.mutate(quantity, {
            onSuccess: () => {
                toast.success(
                    <FormattedMessage defaultMessage="Votre quota de minutes de transcription a été mis à jour" />,
                );
                setQuotaQuantity(null);
                setIsQuotaConfirmationModalOpen(false);
            },
            onError: () => {
                toast.alert(
                    <FormattedMessage defaultMessage="Une erreur est survenue et votre quota n'a pas été modifié, veuillez réessayer plus tard" />,
                );
            },
        });
    };

    const handleConfirmQuota = (quantity: number) => {
        setQuotaQuantity(quantity);
        setIsQuotaModalOpen(false);
        setIsQuotaConfirmationModalOpen(true);
    };

    return (
        <>
            <QuotaCard
                titleIcon={<FontAwesomeIcon icon={icon({ name: 'subtitles', style: 'solid' })} />}
                title={<FormattedMessage defaultMessage="Transcription" />}
                currentValue={Math.floor(transcriptionQuota?.data?.total || 0)}
                maxValue={transcriptionQuota?.data?.maximum || 0}
                valueUnit={<FormattedMessage defaultMessage="min" />}
                actionLabel={actionLabel}
                onActionTriggered={() => (isFreemium ? push('offers') : setIsQuotaModalOpen(true))}
                infoTooltipContent={
                    <Text>
                        <FormattedMessage
                            defaultMessage="Votre quota de temps de transcription est réinitialisé chaque 1er du mois. <link>En savoir plus sur la transcription</link>"
                            values={{
                                link: (chunks: string) => (
                                    <StyledExternalLink
                                        href={intl.formatMessage({
                                            defaultMessage:
                                                'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
                                        })}
                                        target="_blank"
                                    >
                                        {chunks}
                                    </StyledExternalLink>
                                ),
                            }}
                        />
                    </Text>
                }
            />
            <TranscriptionEditQuotaConfirmationModal
                isOpen={isQuotaConfirmationModalOpen}
                onOpenChange={setIsQuotaConfirmationModalOpen}
                isLoading={updateQuota.isLoading}
                onSubmit={handleUpdateQuota}
                quantity={quotaQuantity || 0}
            />
            <TranscriptionEditQuotaModal
                isOpen={isQuotaModalOpen}
                onOpenChange={setIsQuotaModalOpen}
                onConfirm={handleConfirmQuota}
            />
        </>
    );
};

const StyledExternalLink = styled(ExternalLink)`
    color: inherit;
    font-size: inherit;
    text-decoration: underline;
    font-weight: var(--fw-semibold);

    &:hover {
        color: inherit;
    }
    &:active {
        color: inherit;
    }
    &:visited {
        color: inherit;
    }
`;

export default TranscriptionMinutesQuota;
