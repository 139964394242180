import Dialog from '@ui/atoms/Dialog';
import { FormattedMessage } from 'react-intl';
import TranscriptionSpeakerForm from './TranscriptionSpeakerForm';
import { TranscriptionSpeakerFormSchema } from './TranscriptionSpeakerForm/useTranscriptionSpeakerFormSchema.hook';

interface TranscriptionAddSpeakerModalProps {
    isOpen: boolean;
    onOpenChange: (isOpen: boolean) => void;
    onSubmit: (data: TranscriptionSpeakerFormSchema) => void;
}

const TranscriptionAddSpeakerModal = ({
    isOpen,
    onOpenChange,
    onSubmit,
}: TranscriptionAddSpeakerModalProps) => {
    return (
        // @ts-ignore
        <Dialog
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            title={<FormattedMessage defaultMessage="Ajouter un orateur" />}
            size="small"
        >
            <TranscriptionSpeakerForm onSubmit={onSubmit} onClose={() => onOpenChange(false)} />
        </Dialog>
    );
};

export default TranscriptionAddSpeakerModal;
