import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import styled from 'styled-components';
import useDownloadsComparisonQuery from '@queries/stats/useDownloadsComparisonQuery.hook';
import useSubscriptionQuery from '@queries/subscription/useSubscriptionQuery.hook';
import useStatsSelectedEpisodeIds from '@/shared/hooks/useStatsSelectedEpisodeIds';
import DownloadsComparisonChart from '@/components/charts/DownloadsComparisonChart/DownloadsComparisonChart';
import DownloadsComparisonTable from '@/components/tables/DownloadsComparisonTable/DownloadsComparisonTable';
import DownloadsComparisonChartLegend from '@/components/charts/DownloadsComparisonChart/DownloadsComparisonChartLegend';
import { PRICING } from '@/shared/config/pricing';
import { ENTERPRISE_GRAPH_COLORS, GRAPH_COLORS } from '@/shared/config/constants';

interface Item {
    x: string;
    y: number;
}

export interface DownloadsComparisonData {
    name: string;
    data: Item[];
    color: string;
}

interface DownloadsComparisonContentProps {
    daysAfterPublication: 7 | 15 | 30 | 90;
}

const DownloadsComparisonContent = ({ daysAfterPublication }: DownloadsComparisonContentProps) => {
    const intl = useIntl();
    const { showId } = useParams<{ showId: string }>();
    const selectedEpisodeIds = useStatsSelectedEpisodeIds();

    const subscriptionQuery = useSubscriptionQuery();
    const downloadsComparisonQuery = useDownloadsComparisonQuery({
        showId,
        daysAfterPublication,
        selectedEpisodeIds,
    });

    const episodeColors =
        subscriptionQuery.data?.pricing === PRICING.ENTERPRISE
            ? ENTERPRISE_GRAPH_COLORS
            : GRAPH_COLORS;

    const [highlightedColumnIndex, setHighlightedColumnIndex] = useState<number | undefined>();

    const handleChartMouseMove = useCallback(({ dataPointIndex }) => {
        setHighlightedColumnIndex(dataPointIndex >= 0 ? dataPointIndex : undefined);
    }, []);

    if (downloadsComparisonQuery.data) {
        const series = downloadsComparisonQuery.data.map((item, index) => ({
            id: item.episodeId,
            name: item.episodeName,
            data: item.downloadsPerDay.map((value) => ({
                x: intl.formatMessage({ defaultMessage: 'Jour {day}' }, { day: value.day }),
                y: value.downloads,
            })),
            color: episodeColors[index] ?? episodeColors[episodeColors.length - 1],
        }));

        return (
            <Container>
                <ChartContainer>
                    <DownloadsComparisonChartLegend episodes={series} />
                    <DownloadsComparisonChart series={series} onMouseMove={handleChartMouseMove} />
                </ChartContainer>
                <DownloadsComparisonTable
                    data={series}
                    highlightedColumnIndex={highlightedColumnIndex}
                />
            </Container>
        );
    }

    if (downloadsComparisonQuery.isError) {
        // TODO: Handle error properly
        return <div>An error has occurred</div>;
    }

    return (
        <Container>
            <ChartContainer>
                <DownloadsComparisonChart isLoading />
            </ChartContainer>
            <DownloadsComparisonTable isLoading />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
`;

const ChartContainer = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding);
    display: flex;
    flex-direction: column;
    gap: 2rem;

    ${({ theme }) => theme.mediaQueries.mobile} {
        padding: var(--container-padding-mobile);
    }
`;

export default DownloadsComparisonContent;
