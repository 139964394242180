import { useQuery, UseQueryResult, QueryFunctionContext } from '@tanstack/react-query';
import camelcaseKeys from 'camelcase-keys';
import { psoIndexedKeywords } from '@/api';
import psoKeys from './psoKeys';

export interface IndexedKeywordsQueryProps {
    showId: string;
    currentStore: string;
}

export interface IndexedKeyword {
    currentPositionApple: number;
    currentPositionSpotify: number;
    label: string;
    rank: number;
}

const queryFn = async ({
    queryKey: [{ showId }],
}: QueryFunctionContext<ReturnType<typeof psoKeys.indexedKeywordsList>>) => {
    const { data } = await psoIndexedKeywords(showId);

    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const useIndexedKeywordsQuery = ({
    showId,
    currentStore,
}: IndexedKeywordsQueryProps): UseQueryResult<IndexedKeyword[]> => {
    return useQuery({
        queryKey: psoKeys.indexedKeywordsList(showId),
        queryFn,
        staleTime: 0,
        enabled: !!showId && !!currentStore,
    });
};

export default useIndexedKeywordsQuery;
