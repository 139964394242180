import useUserQuery from '@/queries/user/useUserQuery.hook';

const useHasAccessToAushaForAll = (): boolean => {
    const { data: user } = useUserQuery();
    const featureIsVisible = user?.options?.includes('enable_ausha_for_all');

    return featureIsVisible || false;
};

export default useHasAccessToAushaForAll;
