import useShowQuery from '@queries/show/useShowQuery.hook';
import ShowEditForm from './ShowEditForm';
import { useParams } from 'react-router';
import useLanguagesQuery from '@queries/language/useLanguagesQuery.hook';
import useCategoriesQuery from '@queries/category/useCategoriesQuery.hook';
import useUserQuery from '@queries/user/useUserQuery.hook';
import { useAmplitudeLogEvent } from '@/shared/hooks/useAmplitude';
import ShowEditSkeleton from './ShowEditSkeleton';
import useCategoryItems from '@/shared/hooks/useCategoryItems.hook';

const ShowEdit = () => {
    useAmplitudeLogEvent('show edit show');
    const { showId } = useParams<{ showId: string }>();
    const show = useShowQuery(showId);
    const languages = useLanguagesQuery();
    const categories = useCategoriesQuery();
    const user = useUserQuery();

    const hasData = show.data && languages.data && categories.data && user.data;
    const hasError = show.isError || languages.isError || categories.isError || user.isError;

    if (hasData) {
        const languageItems = languages.data
            .sort((a, b) => (a.isFeatured ? -1 : b.isFeatured ? 1 : 0))
            .map((language) => ({
                id: language.id,
                name: language.nativeName,
            }));
        const categoriesItems = useCategoryItems(categories.data);
        const isShowPmp = show.data.hostedOnAusha === false;
        const values = {
            name: show.data.name,
            author: show.data.author || `${user.data.firstname} ${user.data.lastname}`,
            htmlDescription: show.data.htmlDescription,
            imageUrl: show.data.imageUrl,
            languageId: show.data.languageId,
            categoryId: show.data.categoryId,
            secondaryCategoryId: show.data.secondaryCategoryId,
            slug: show.data.slug,
            descriptionFooter: show.data.descriptionFooter,
            tags: show.data.tags.data.map((tag) => tag.name),
            type: show.data.type,
            publicCounts: show.data.publicCounts,
        };

        return (
            <ShowEditForm
                values={values}
                languages={languageItems}
                categories={categoriesItems}
                isShowPmp={isShowPmp}
                userLocale={user.data.language}
            />
        );
    }

    if (hasError) return <div>Error</div>;

    return <ShowEditSkeleton />;
};

export default ShowEdit;
