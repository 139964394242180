import { useState, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import ApplePodcastsRankingExportButton from './ApplePodcastsRankingExportButton';
import Desktop from '@ui/molecules/Desktop';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import RankingVariationGraphHeader from './RankingVariationGraphHeader';
import RankingVariationGraphPlotLine from '@/components/unorganized/RankingVariationGraphPlotLine';
import RankingVariationGraphTable from './RankingVariationGraphTable';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import { extractCssVariable } from '@/shared/utils/cssVariable';
import styled from 'styled-components';
import { useParams } from 'react-router';
import { useApplePodcastsStatsContext } from '@/context/ApplePodcastsStatsContext';
import { getLocalTimeZone } from '@internationalized/date';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import useAppleRankingOverallQuery from '@/queries/stats/useAppleRankingOverallQuery.hook';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import { APPLE_PODCASTS } from '@/shared/config/constants';

const RankingVariationGraphLegend = ({ step, setStep }) => (
    <DeprecatedPaper flex align="center" justify="space-around" wrap="wrap" px={35}>
        <DeprecatedPaper flex align="center" auto w="auto" mt={35}>
            <DeprecatedPaper
                background={extractCssVariable('--primary')}
                height={10}
                mr={10}
                rounded
                w={10}
                minW={10}
            />
            <DeprecatedText auto>
                <FormattedMessage defaultMessage="Classement moyen" />
            </DeprecatedText>
        </DeprecatedPaper>
        <Desktop>
            <DeprecatedPaper flex w="auto" mt={35}>
                <DeprecatedText
                    onClick={() => setStep('days')}
                    color={step === 'days' ? '--black' : '--neutral500'}
                    mr={25}
                >
                    <FormattedMessage defaultMessage="Jour" />
                </DeprecatedText>
                <DeprecatedText
                    onClick={() => setStep('weeks')}
                    color={step === 'weeks' ? '--black' : '--neutral500'}
                    mr={25}
                >
                    <FormattedMessage defaultMessage="Semaine" />
                </DeprecatedText>
                <DeprecatedText
                    onClick={() => setStep('months')}
                    color={step === 'months' ? '--black' : '--neutral500'}
                    mr={25}
                >
                    <FormattedMessage defaultMessage="Mois" />
                </DeprecatedText>
                <DeprecatedText
                    onClick={() => setStep('years')}
                    color={step === 'years' ? '--black' : '--neutral500'}
                >
                    <FormattedMessage defaultMessage="Année" />
                </DeprecatedText>
            </DeprecatedPaper>
        </Desktop>
    </DeprecatedPaper>
);

function ApplePodcastsRanking() {
    const { showId } = useParams();
    const { category, precision, setPrecision } = useApplePodcastsStatsContext();

    const { data: user } = useUserQuery();
    const timezone = useMemo(() => user?.timezone || getLocalTimeZone(), [user]);

    const { data: show } = useShowQuery(showId);
    const hasApplePodcastsListeningLink = show?.listeningLinks?.data?.some(
        (link) => link.key === APPLE_PODCASTS,
    );

    const { data: overall } = useAppleRankingOverallQuery({
        showId,
        categoryId: category,
        timezone,
        options: {
            enabled: hasApplePodcastsListeningLink,
        },
    });

    const [hoveredValue, setHoveredValue] = useState(null);
    const [scrollToValue, setScrollToValue] = useState(null);

    // Determine if the show have already been in the ranking of the category
    const noBestRankingData = overall && overall.rankingDetails.bestPosition === null;
    const bestPositionNeverInRanking =
        overall &&
        overall.rankingDetails.bestPosition &&
        overall.rankingDetails.bestPosition.bestPos === null;
    const neverInRanking = !!(noBestRankingData || bestPositionNeverInRanking);

    function scroll(value) {
        setScrollToValue(value);
        setHoveredValue(value);
    }

    return (
        <>
            <ApplePodcastsRankingWrapper>
                <RankingVariationGraphHeader />
                {!neverInRanking && (
                    <RankingVariationGraphLegend step={precision} setStep={setPrecision} />
                )}
                <RankingVariationGraphPlotLine categoryId={category} onHover={scroll} />
            </ApplePodcastsRankingWrapper>
            <Desktop>
                <RankingVariationGraphTable
                    hoveredValue={hoveredValue}
                    onHover={(data) => setHoveredValue(data)}
                    scrollToValue={scrollToValue}
                />
                <ApplePodcastsRankingExportButton step={precision} />
            </Desktop>
        </>
    );
}

const ApplePodcastsRankingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding);
    margin-block: 2rem;
    gap: 1rem ${({ theme }) => theme.mediaQueries.mobile} {
        padding: var(--container-padding-mobile);
    }
`;

export default ApplePodcastsRanking;
