import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import camelcaseKeys from 'camelcase-keys';
import psoKeys from './psoKeys';
import { getCountryISO3 } from '@/shared/services/countryService';
import type { Geolocalization } from '@/queries/user/useUserGeolocalizationQuery.hook';
import { z } from 'zod';

export const KeywordSuggestionSchema = z.object({
    id: z.string(),
    keyword: z.string(),
});
export const KeywordSuggestionsSchema = z.array(KeywordSuggestionSchema);
export type KeywordSuggestion = z.infer<typeof KeywordSuggestionSchema>;
type Key = QueryFunctionContext<ReturnType<typeof psoKeys.liveSearchKeywordSuggestions>>;
type InputProps = {
    showId: string;
    search: string;
    store: string;
    userGeolocalization?: Geolocalization;
};

const fetchSuggestions = async (
    search: string,
    store: string,
    userGeolocalization?: Geolocalization,
) => {
    if (!search || !store) return;
    const countryCode = userGeolocalization?.geo?.country?.code;
    let meilisearchKey = process.env.MEILISEARCH_API_KEY;
    if (countryCode && ['CA', 'US'].includes(countryCode)) {
        meilisearchKey = process.env.MEILISEARCH_API_KEY_NA;
    }
    const index = `keywords-${getCountryISO3(store)}`;
    const req = await fetch(`https://edge.meilisearch.com/indexes/${index}/search`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${meilisearchKey}`,
        },
        body: JSON.stringify({
            q: search,
            limit: 8,
        }),
    });
    return await req.json();
};

const queryFn = async ({ queryKey: [{ search, store, userGeolocalization }] }: Key) => {
    const suggestions = await fetchSuggestions(search, store, userGeolocalization);
    if (!suggestions) return [];
    return KeywordSuggestionsSchema.parse(camelcaseKeys(suggestions.hits, { deep: true }));
};

const usePsoLiveSearchKeywordSuggestions = ({
    showId,
    search,
    store,
    userGeolocalization,
}: InputProps) => {
    return useQuery({
        queryKey: psoKeys.liveSearchKeywordSuggestions(showId, search, store, userGeolocalization),
        queryFn,
        enabled: !!search && !!store && !!userGeolocalization,
    });
};

export default usePsoLiveSearchKeywordSuggestions;
