import { css } from 'styled-components';
import PropTypes from 'prop-types';

/**
 * See https://chakra-ui.com/docs/features/style-props
 */
const typographyMixin = css`
    font-size: ${(props) => props.$fontSize};
    font-weight: var(${(props) => props.$fontWeight});
    line-height: ${(props) => props.$lineHeight};
    text-align: ${(props) => props.$textAlign};
    font-style: ${(props) => props.$fontStyle};
    word-break: ${(props) => props.$wordBreak};
    overflow-wrap: ${(props) => props.$overflowWrap};
    text-overflow: ${(props) => props.$textOverflow};
    text-transform: ${(props) => props.$textTransform};
    white-space: ${(props) => props.$whiteSpace};
`;

export const typographyPropTypes = {
    $fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $lineHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $textAlign: PropTypes.string,
    $fontStyle: PropTypes.string,
    $wordBreak: PropTypes.string,
    $overflowWrap: PropTypes.string,
    $textOverflow: PropTypes.string,
    $textTransform: PropTypes.string,
    $whiteSpace: PropTypes.string,
};

export default typographyMixin;
