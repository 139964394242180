import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@ui/atoms/Button';

const AddButton = styled(Button)`
    background-color: var(--primary50);
    color: var(--primary);
    box-shadow: none;
`;

const AddChapterButtonIcon = styled(FontAwesomeIcon)`
    color: inherit;
`;

const AddChapterButton = ({ onPress }) => {
    return (
        <AddButton
            startIcon={
                <AddChapterButtonIcon
                    icon={icon({
                        name: 'add',
                        style: 'solid',
                    })}
                />
            }
            onPress={onPress}
        >
            <FormattedMessage defaultMessage="Ajouter un chapitre" />
        </AddButton>
    );
};

export default AddChapterButton;
