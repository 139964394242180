import IconButton from '@/components/IconButton';
import Modal from '@/components/ui/atoms/Modal';
import Text from '@/components/ui/atoms/Text';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Benefits from './Benefits';

interface BenefitsModalProps {
    isOpen: boolean;
    onOpenChange: (open: boolean) => void;
}

const BenefitsModal = ({ isOpen, onOpenChange }: BenefitsModalProps) => {
    const handleClose = () => onOpenChange(false);

    return (
        <ModalStyled isOpen={isOpen} onOpenChange={onOpenChange} size="medium">
            <Header>
                <TitleWrapper>
                    <Text variant="headingM" fontWeight="--fw-bold">
                        <FormattedMessage defaultMessage="PSO Control Panel" />
                    </Text>
                    <Text color="--neutral500">
                        <FormattedMessage defaultMessage="Identifiez les mots-clés à fort impact, optimisez les données des épisodes et suivez les classements pour surpasser vos concurrents grâce à notre boîte à outils complète d'optimisation de référencement de podcasts." />
                    </Text>
                </TitleWrapper>
                <IconButton
                    icon={<CloseIcon icon={icon({ name: 'xmark-large', style: 'solid' })} />}
                    onPress={handleClose}
                    variant="ghost"
                    aria-label="Close modal"
                    style={{ padding: 0 }}
                />
            </Header>
            <ScrollableWrapper>
                <Benefits />
            </ScrollableWrapper>
        </ModalStyled>
    );
};

const ModalStyled = styled(Modal)`
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 2rem;
`;
const Header = styled.div`
    display: flex;
    align-items: start;
    justify-content: space-between;
    column-gap: 2rem;

    & > button {
        flex-shrink: 0;
    }
`;
const CloseIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--neutral500);
`;
const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;
    flex-grow: 1;
`;
const ScrollableWrapper = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: auto;
`;

export default BenefitsModal;
