import { useMutation, useQueryClient } from '@tanstack/react-query';
import { assignAllPodcasts } from '@/api';
import episodeKeys from '@/queries/episode/episodeKeys';
import camelcaseKeys from 'camelcase-keys';

export interface MutationFnProps {
    showId: string;
    newSeasonId: string | null;
}

const mutationFn = async ({ showId, newSeasonId }: MutationFnProps) => {
    const { data } = await assignAllPodcasts(showId, { newSeasonId });

    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const useAssignAllEpisodesToSeasonMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: episodeKeys.allLists(),
            });
        },
    });
};

export default useAssignAllEpisodesToSeasonMutation;
