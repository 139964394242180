// import Active from './Active';
import NotActive from './NotActive';

// TODO: Retrieve data from API
const Pso = () => {
    // return <Active />;
    return <NotActive />;
};

export default Pso;
