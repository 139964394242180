import { CircleFormFallback } from '@/components/unorganized/CircleFormFallback';
import styled from 'styled-components';
import useCountriesQuery from '@queries/country/useCountriesQuery.hook';
import useLanguagesQuery from '@queries/language/useLanguagesQuery.hook';
import useTimezonesQuery from '@queries/timezone/useTimezonesQuery.hook';
import { useModalToastQueue } from '@/shared/hooks/useModalToastQueue.hook';
import useUserQuery from '@queries/user/useUserQuery.hook';
import { ProfileFormSchema } from './ProfileForm/schema';
import saveProfileMutation from './saveProfileMutation';
import ProfileForm from './ProfileForm';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;

    ${(p) => p.theme.mediaQueries.tabletAndUp} {
        row-gap: 2rem;
    }
`;

const ContentContainer = styled(Container)`
    display: flex;
    flex-direction: column;
    row-gap: 1.25rem;
    padding: 1.5rem 1rem;
    background-color: var(--white);
    border-radius: var(--r-l);

    ${(p) => p.theme.mediaQueries.tabletAndUp} {
        padding: 2rem 2.5rem;
    }
`;

function ProfileEdit() {
    const userQuery = useUserQuery();
    const languagesQuery = useLanguagesQuery();
    const timezonesQuery = useTimezonesQuery();
    const countriesQuery = useCountriesQuery();
    const toast = useModalToastQueue();
    const saveProfile = saveProfileMutation();

    const hasData =
        userQuery.isSuccess &&
        languagesQuery.isSuccess &&
        timezonesQuery.isSuccess &&
        countriesQuery.isSuccess;
    const isError =
        userQuery.isError ||
        languagesQuery.isError ||
        timezonesQuery.isError ||
        countriesQuery.isError;

    if (isError) {
        return <div>ERROR</div>;
    }

    if (hasData) {
        const formattedLanguages =
            languagesQuery.data &&
            languagesQuery.data.filter((language) => ['fr', 'en'].includes(language.code));
        const formattedTimezones =
            timezonesQuery.data &&
            Object.keys(timezonesQuery.data).reduce<{ id: string; name: string }[]>((acc, key) => {
                acc.push({ id: key, name: timezonesQuery.data[key] });
                return acc;
            }, []);

        const onSubmit = (formData: ProfileFormSchema) => {
            saveProfile.mutate(formData, {
                onSuccess: () => {
                    toast.success();
                },
                onError: () => {
                    toast.alert();
                },
            });
        };

        return (
            <Container>
                <ContentContainer>
                    <ProfileForm
                        languages={formattedLanguages}
                        countries={countriesQuery.data}
                        timezones={formattedTimezones}
                        user={userQuery.data}
                        onSubmit={onSubmit}
                        isLoading={saveProfile.isLoading}
                    />
                </ContentContainer>
            </Container>
        );
    }

    return <CircleFormFallback theme={undefined} />;
}

export default ProfileEdit;
