import { useRef, useState } from 'react';
import Modal from '@ui/molecules/Modal';
import { FormattedMessage } from 'react-intl';
import Cluster from '@ui/layout/Cluster';
import Button from '@ui/atoms/Button';
import InputText from '@ui/atoms/InputText';

const ClipTitleEditModal = ({ isOpen, onClose, onEdit, value, isDisabled }) => {
    const [title, setTitle] = useState(value);
    const ref = useRef(null);
    return (
        <Modal
            title={<FormattedMessage defaultMessage="Renommer le clip" />}
            action={
                <Cluster $gap="0.5rem">
                    <Button variant="secondary" onClick={onClose}>
                        <FormattedMessage defaultMessage="Annuler" />
                    </Button>
                    <Button
                        variant="primary"
                        isDisabled={isDisabled || !title}
                        onClick={() => onEdit(title)}
                    >
                        <FormattedMessage defaultMessage="Enregistrer" />
                    </Button>
                </Cluster>
            }
            isOpen={isOpen}
            onClose={() => onClose()}
        >
            <InputText
                ref={ref}
                autoFocus
                label={<FormattedMessage defaultMessage="Nom du clip" />}
                value={title}
                onChange={(value) => setTitle(value)}
                maxLength={100}
                characterOverflowBehavior="block"
                onKeyDown={(event) => {
                    if (event.target.value.length === 0) return;
                    event.key === 'Enter' && onEdit(title);
                }}
            />
        </Modal>
    );
};

export default ClipTitleEditModal;
