import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import camelcaseKeys from 'camelcase-keys';
import { z } from 'zod';
import psoCheckerKeys from './psoCheckerKeys';
import { psoCheckerSuggestedTargetKeywords } from '@/api';

export const AITargetKeywordSchema = z.object({
    label: z.string(),
    searchVolume: z.number().nullable(),
    difficultyScore: z.number().nullable(),
});

const AITargetKeywordsSchema = z.array(AITargetKeywordSchema);
export type AITargetKeyword = z.infer<typeof AITargetKeywordSchema>;

type Key = QueryFunctionContext<ReturnType<typeof psoCheckerKeys.aiKeywords>>;

const queryFn = async ({ queryKey: [{ episodeId }] }: Key) => {
    const { data } = await psoCheckerSuggestedTargetKeywords(episodeId);

    return AITargetKeywordsSchema.parse(camelcaseKeys(data, { deep: true }));
};

interface useAITargetKeywordsProps {
    episodeId: string;
    enabled?: boolean;
}

const useAITargetKeywords = ({ episodeId, enabled }: useAITargetKeywordsProps) => {
    return useQuery({
        queryKey: psoCheckerKeys.aiTargetKeywords(episodeId),
        queryFn,
        enabled: !!episodeId && enabled,
    });
};

export default useAITargetKeywords;
