import PropTypes from 'prop-types';
import Box from '../../layout/Box';

const Icon = ({
    size,
    width,
    w,
    height,
    h,
    color,
    fill,
    stroke,
    'aria-label': ariaLabel,
    ...otherProps
}) => {
    const a11yProps = ariaLabel
        ? { 'aria-label': ariaLabel, role: 'graphics-symbol' }
        : { 'aria-hidden': true };

    return (
        <Box
            {...a11yProps}
            $display="inline-block"
            $boxSize={size}
            $width={width}
            $w={w}
            $height={height}
            $h={h}
            $color={color}
            $fill={fill}
            $stroke={stroke}
            $flexShrink={0}
            {...otherProps}
        />
    );
};

Icon.propTypes = {
    'aria-label': PropTypes.string,
    size: PropTypes.string,
    width: PropTypes.string,
    w: PropTypes.string, // Shortcut for width
    height: PropTypes.string,
    h: PropTypes.string, // Shortcut for height
    color: PropTypes.string,
    fill: PropTypes.string,
    stroke: PropTypes.string,
};

Icon.defaultProps = {
    w: '1.25rem',
    h: '1.25rem',
    fill: 'currentColor',
};

export default Icon;
