import { css } from 'styled-components';
import PropTypes from 'prop-types';
import { resolveColor } from '@/utils/cssVariables';

/**
 * See https://chakra-ui.com/docs/features/style-props
 */
const colorMixin = css`
    color: ${(props) => resolveColor(props.$color)};
    fill: ${(props) => resolveColor(props.$fill)};
    stroke: ${(props) => resolveColor(props.$stroke)};
`;

export const colorPropTypes = {
    $color: PropTypes.string,
    $fill: PropTypes.string,
    $stroke: PropTypes.string,
};

export default colorMixin;
