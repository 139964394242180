import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FormattedMessage } from 'react-intl';
import ApplePodcastsLogo from '@public/images/platforms/ApplePodcasts.svg?url';
import SpotifyLogo from '@public/images/platforms/Spotify.svg?url';
import { SPOTIFY, APPLE_PODCASTS } from '@/shared/config/constants';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';
import { TooltipTrigger } from 'react-aria-components';
import PsoRankingCardSkeleton from '../PsoRankingCardSkeleton';
import PerformanceTrendChart from '@/components/ui/molecules/PerformanceTrendChart';
import styled, { css } from 'styled-components';

const PsoKeywordTrend = ({ keyword, platform, trend, points, chartData, isLoading }) => {
    const platformLookup = {
        [SPOTIFY]: SpotifyLogo,
        apple_podcasts: ApplePodcastsLogo,
    };

    if (isLoading) return <PsoRankingCardSkeleton />;

    return (
        <KeywordTrendWrapper>
            <Left>
                <BlockIconWrapper trend={trend}>
                    {['up', 'new'].indexOf(trend) > -1 ? (
                        <FontAwesomeIcon
                            icon={icon({ name: 'arrow-up-wide-short', style: 'solid' })}
                        />
                    ) : (
                        <FontAwesomeIcon
                            icon={icon({ name: 'arrow-down-wide-short', style: 'solid' })}
                        />
                    )}
                </BlockIconWrapper>

                <KeywordWrapper>
                    <Title>
                        {['up', 'new'].indexOf(trend) > -1 ? (
                            <FormattedMessage defaultMessage="Meilleure progression" />
                        ) : (
                            <FormattedMessage defaultMessage="Tendance à la baisse" />
                        )}
                        <TooltipTrigger delay={0} closeDelay={0}>
                            <TooltipTriggerWrapper>
                                <InfoIcon icon={icon({ name: 'circle-info', style: 'solid' })} />
                            </TooltipTriggerWrapper>
                            <Tooltip placement="top">
                                {['up', 'new'].indexOf(trend) > -1 ? (
                                    <FormattedMessage defaultMessage="Le mot-clé qui a connu la plus forte progression sur les 7 derniers jours" />
                                ) : (
                                    <FormattedMessage defaultMessage="Le mot-clé qui a connu la plus forte baisse sur les 7 derniers jours" />
                                )}
                            </Tooltip>
                        </TooltipTrigger>
                    </Title>
                    {keyword ? (
                        <Keyword>
                            <KeywordLabel>{keyword}</KeywordLabel>
                            <Trend trend={trend}>
                                {['up', 'new'].indexOf(trend) > -1 ? (
                                    <TrendIcon
                                        icon={icon({ name: 'arrow-trend-up', style: 'solid' })}
                                    />
                                ) : (
                                    <TrendIcon
                                        icon={icon({ name: 'arrow-trend-down', style: 'solid' })}
                                    />
                                )}
                                {['up', 'new'].indexOf(trend) > -1 ? '+' : ''}
                                {points}
                            </Trend>
                            <PlatformIcon src={platformLookup[platform]} />
                        </Keyword>
                    ) : (
                        <EmptyState />
                    )}
                </KeywordWrapper>
            </Left>
            {keyword && (
                <GraphWrapper>
                    <PerformanceTrendChart data={chartData} width={110} height={80} trend={trend} />
                </GraphWrapper>
            )}
        </KeywordTrendWrapper>
    );
};

const KeywordTrendWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    gap: 1rem;
`;
const Left = styled.div`
    display: flex;
    align-items: center;
    gap: 0.75rem;
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 0;
`;
const BlockIconWrapper = styled.div`
    font-size: 1rem;
    border-radius: var(--r-s);
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    ${({ trend }) =>
        ['up', 'new'].indexOf(trend) > -1 &&
        css`
            color: var(--success);
            background-color: var(--success50);
        `};
    ${({ trend }) =>
        ['down', 'exited'].indexOf(trend) > -1 &&
        css`
            color: var(--alert);
            background-color: var(--alert50);
        `};
`;
const KeywordWrapper = styled.div`
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 0;
`;
const Title = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
    color: var(--neutral500);
    font-size: var(--fs-body-m);

    & > *:last-child {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;
const InfoIcon = styled(FontAwesomeIcon)`
    font-size: 0.75rem;
    color: var(--neutral500);
`;
const TrendIcon = styled(FontAwesomeIcon)`
    font-size: 0.75rem;
`;
const Keyword = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-weight: var(--fw-bold);
    width: 100%;
`;
const KeywordLabel = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    min-width: 0;
`;
const EmptyState = styled.div`
    width: 0.75rem;
    height: 0.125rem;
    background-color: var(--neutral500);
    border-radius: var(--r-full);
    margin-block: 0.5rem;
`;
const Trend = styled.div`
    display: flex;
    flex-shrink: 0;
    align-items: center;
    gap: 0.25rem;
    color: ${({ trend }) =>
        ['up', 'new'].indexOf(trend) > -1 ? 'var(--success)' : 'var(--alert)'};
`;
const PlatformIcon = styled.img`
    width: 0.75rem;
    height: auto;
`;
const GraphWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
`;

PsoKeywordTrend.propTypes = {
    trend: PropTypes.oneOf(['up', 'down']).isRequired,
    keyword: PropTypes.string,
    platform: PropTypes.oneOf([SPOTIFY, APPLE_PODCASTS]),
    points: PropTypes.number,
    chartData: PropTypes.array,
    isLoading: PropTypes.bool,
};

PsoKeywordTrend.defaultProps = {
    points: 0,
    isLoading: false,
};

export default PsoKeywordTrend;
