import { useMutation, useQueryClient } from '@tanstack/react-query';
import deleteCampaignMutation from './deleteCampaignMutation';
import campaignKeys from './campaignKeys';

export interface MutationFnProps {
    campaignIds: string[];
}

const useDeleteMultipleCampaignsMutation = () => {
    const queryClient = useQueryClient();
    const deleteCampaign = deleteCampaignMutation();

    return useMutation({
        mutationFn: ({ campaignIds }: MutationFnProps) => {
            const promises: Promise<any>[] = [];

            campaignIds.forEach((campaignId) => {
                promises.push(deleteCampaign.mutateAsync(campaignId));
            });

            return Promise.allSettled(promises);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: campaignKeys.allLists(),
            });
        },
    });
};

export default useDeleteMultipleCampaignsMutation;
