import { useQuery } from '@tanstack/react-query';
import { fetchTrackers } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import trackersKeys from '@/components/unorganized/Trackers/trackersKeys';

const queryFn = async ({ queryKey: [{ showId }] }) => {
    const { data } = await fetchTrackers(showId);

    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const useTrackersQuery = (showId) => {
    return useQuery({
        queryKey: trackersKeys.listByShowId(showId),
        queryFn,
        enabled: !!showId,
    });
};

export default useTrackersQuery;
