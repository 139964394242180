import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchClipFormatsOfPodcast } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import clipFormatKeys from './clipFormatKeys';
import { z } from 'zod';
import { ClipFormatRaw } from './types';

export const ClipFormatSchema = z.object({
    id: z.number(),
    createdAt: z.string().nullable(),
    format: z.string(),
    fullCover: z.boolean(),
    images: z.array(
        z.object({
            format: z.string(),
            url: z.string(),
        }),
    ),
    previewUrl: z.string().nullable(),
    state: z.string(),
    updatedAt: z.string().nullable(),
    url: z.string().nullable(),
    clip: z.object({
        adminTitle: z.string().nullable(),
        caption: z.string().nullable(),
        color: z.string().nullable(),
        createdAt: z.string().nullable(),
        duration: z.number().nullable(),
        end: z.number().nullable(),
        id: z.number().nullable(),
        lang: z.string().nullable(),
        podcastId: z.number().nullable(),
        showTitles: z.boolean().nullable(),
        showWaveform: z.boolean().nullable(),
        start: z.number().nullable(),
        step: z.string().nullable(),
        title: z.string().nullable(),
        titleColor: z.string().nullable(),
        transcript: z.boolean().nullable(),
        updatedAt: z.string().nullable(),
    }),
});

const ClipFormatsSchema = z.array(ClipFormatSchema);

type Key = QueryFunctionContext<ReturnType<typeof clipFormatKeys.listByEpisodeId>>;
export type ClipFormat = z.infer<typeof ClipFormatSchema>;

const queryFn = async ({ queryKey: [{ episodeId }] }: Key) => {
    const { data } = await fetchClipFormatsOfPodcast(episodeId);

    const formattedData = data.map((clipFormat: ClipFormatRaw) => {
        const { podcast_video_clip, images, ...rest } = clipFormat;
        return {
            ...rest,
            images: images.data,
            clip: podcast_video_clip.data,
        };
    });

    // TODO: Do camelcase transformation in Axios interceptor
    return ClipFormatsSchema.parse(camelcaseKeys(formattedData, { deep: true }));
};

interface UseEpisodeClipFormatsQueryProps {
    episodeId: string;
    options?: {
        staleTime?: number;
    };
}

const useEpisodeClipFormatsQuery = ({ episodeId, options }: UseEpisodeClipFormatsQueryProps) => {
    return useQuery({
        queryKey: clipFormatKeys.listByEpisodeId(episodeId),
        queryFn,
        enabled: !!episodeId,
        ...options,
    });
};

export default useEpisodeClipFormatsQuery;
