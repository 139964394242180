import { Redirect, Switch, useParams, useRouteMatch } from 'react-router-dom';
import EpisodeAudioFileUploadPage from '@app/pages/EpisodeAudioFileUploadPage';
import EpisodeEditFormPage from '@app/pages/EpisodeEditFormPage/EpisodeEditFormPage';
import { EpisodeEditContext } from '@/context/EpisodeEditContext';
import { USER_ROLES } from '@/shared/config/constants';
import NoPermissionRoute from '../../unorganized/NoPermissionRoute';
import { STATUS_SHOWS } from '@/shared/config/statusShows';

const EpisodeEditPage = () => {
    const { path } = useRouteMatch();
    const { showId } = useParams();
    return (
        <EpisodeEditContext>
            <Switch>
                <NoPermissionRoute
                    roles={[USER_ROLES.VIEWER]}
                    showStatus={[STATUS_SHOWS.ARCHIVED_SHOWS]}
                    path={`${path}/upload`}
                >
                    <EpisodeAudioFileUploadPage />
                </NoPermissionRoute>
                <NoPermissionRoute
                    roles={[USER_ROLES.VIEWER]}
                    showStatus={[STATUS_SHOWS.ARCHIVED_SHOWS]}
                    path={`${path}/:episodeId(\\d+)`}
                >
                    <EpisodeEditFormPage />
                </NoPermissionRoute>
                <Redirect to={`/app/show/${showId}/episodes`} />
            </Switch>
        </EpisodeEditContext>
    );
};

export default EpisodeEditPage;
