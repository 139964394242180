import styled from 'styled-components';
import Text from '@ui/atoms/Text';
import { FormattedMessage } from 'react-intl';
import CircularProgress from '@ui/atoms/CircularProgress';
import Cluster from '@ui/layout/Cluster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import UiKitTooltip from '@ui/atoms/UiKitTooltip';
import TranscriptionQuotaSkeleton from './TranscriptionQuotaSkeleton';
import useTranscriptShowQuotaQuery from '@/queries/quota/useTranscriptShowQuotaQuery.hook';
import { useParams } from 'react-router-dom';

const Container = styled.div`
    padding: 1rem;
    display: flex;
    align-items: center;
    column-gap: 1rem;
    border-radius: var(--r-m);
    background-color: ${({ $color }) => `var(${$color}50)`};
    color: ${({ $color }) => `var(${$color})`};
`;
const TimeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;
const InfoIcon = styled(FontAwesomeIcon)`
    width: 0.5rem;
    height: 0.5rem;
    color: inherit;
`;

const TranscriptionQuota = () => {
    const { showId } = useParams();
    const { data: quota, isLoading } = useTranscriptShowQuotaQuery(showId);

    if (isLoading) return <TranscriptionQuotaSkeleton />;

    const remainingQuotaTime = Math.floor(quota?.maximum - quota?.total);
    const remainingQuotaPercentage = ((quota?.maximum - quota?.total) / quota?.maximum) * 100;
    const quotaRangeColor = remainingQuotaPercentage < 25 ? '--alert' : '--primary';

    return (
        <Container $color={quotaRangeColor}>
            <TimeWrapper>
                <Cluster $gap="0.25rem" $align="center">
                    <Text fontWeight="--fw-semibold" color="inherit">
                        <FormattedMessage defaultMessage="Temps de transcription restant" />
                    </Text>
                    <UiKitTooltip
                        position="top"
                        variant="dark"
                        content={
                            <FormattedMessage defaultMessage="Votre quota de temps de transcription est réinitialisé chaque 1er du mois." />
                        }
                    >
                        <InfoIcon
                            icon={icon({
                                name: 'circle-info',
                                style: 'solid',
                            })}
                        />
                    </UiKitTooltip>
                </Cluster>
                {/* TODO: Get actual data */}
                <Text variant="headingM" fontWeight="--fw-bold" color="--black">
                    {remainingQuotaTime} min
                </Text>
            </TimeWrapper>
            {/* TODO: Get actual data */}
            <CircularProgress
                progressColor={quotaRangeColor}
                backgroundColor={`${quotaRangeColor}100`}
                size={48}
                strokeWidth={12}
                percentage={remainingQuotaPercentage}
                radius={18}
            />
        </Container>
    );
};

export default TranscriptionQuota;
