import { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { MenuTrigger, Popover } from 'react-aria-components';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import { EPISODE_PRIVACY, EPISODE_STATUS, USER_ROLES } from '@/shared/config/constants';
import { STATUS_SHOWS } from '@/shared/config/statusShows';
import type { Episode } from '@/queries/episode/useEpisodesQuery.hook';
import useWebsiteSettingsQuery from '@/queries/websiteSettings/useWebsiteSettingsQuery.hook';
import useSmartlinkSettingsQuery from '@/queries/smartlinkSetting/useSmartlinkSettingsQuery.hook';
import useDuplicateEpisodeMutation from '@/queries/episode/useDuplicateEpisodeMutation.hook';
import { useSwitch } from '@/shared/hooks/useSwitch';
import MoveEpisodesModal from '@/components/unorganized/MoveEpisodesModal';
import useDeleteEpisodeMutation from '@/queries/episode/useDeleteEpisodeMutation.hook';
import useMoveEpisodeMutation from '@/queries/episode/useMoveEpisodeMutation.hook';
import styled from 'styled-components';
import MenuDropdown from '@/components/ui/atoms/MenuDropdown';
import MenuDropdownItem from '@/components/ui/atoms/MenuDropdown/MenuDropdownItem';
import IconButton from '@/components/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useBodyToastQueue } from '@/shared/hooks/useBodyToastQueue.hook';
import DeleteEpisodeModal from '@/components/DeleteEpisodeModal';

interface ContextualMenuProps {
    episode: Episode;
}

const isMobile = window.matchMedia('(max-width: 1024px)').matches;

const ContextualMenu = ({ episode }: ContextualMenuProps) => {
    const history = useHistory();
    const { showId } = useParams<{ showId: string }>();
    const { data: show } = useShowQuery(showId);
    const { data: websiteSettings } = useWebsiteSettingsQuery({ showId });
    const { data: smartlinkSettings } = useSmartlinkSettingsQuery(showId);
    const duplicateEpisode = useDuplicateEpisodeMutation();
    const deleteEpisode = useDeleteEpisodeMutation();
    const moveEpisode = useMoveEpisodeMutation();
    const toast = useBodyToastQueue();
    const [isMoveOpened, onMoveOpen, onMoveClose] = useSwitch(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const userHasWriteAccess =
        show &&
        ![USER_ROLES.VIEWER, USER_ROLES.EDITOR].includes(show.userRole as 'viewer' | 'editor');
    const notViewerNotArchived =
        show &&
        !['viewer'].includes(show.userRole) &&
        ![STATUS_SHOWS.ARCHIVED_SHOWS].includes(
            show.showStatus as typeof STATUS_SHOWS.ARCHIVED_SHOWS,
        );
    const episodeIsOnline = episode?.state === EPISODE_STATUS.ONLINE;
    const episodeIsDraft = episode?.state === EPISODE_STATUS.DRAFT;
    const episodeIsScheduled = episode?.state === EPISODE_STATUS.SCHEDULED;
    const episodeIsPrivate = episode?.privacy === EPISODE_PRIVACY.PRIVATE;

    const handleDeleteEpisode = () => {
        if (!episode?.id) return;
        deleteEpisode.mutate(
            { episodeId: `${episode.id}` },
            {
                onSuccess: () => {
                    setIsDeleteModalOpen(false);
                },
                onError: () => {
                    toast.alert();
                },
            },
        );
    };

    return (
        <>
            <MenuTrigger>
                <TriggerButton
                    aria-label="Menu"
                    variant="ghost"
                    icon={<EllipsisIcon icon={icon({ name: 'ellipsis', style: 'solid' })} />}
                />
                <PopoverStyled placement="bottom end">
                    <MenuDropdownStyled>
                        {isMobile && userHasWriteAccess && (
                            <MenuDropdownItem
                                onAction={() => {
                                    episode?.id &&
                                        history.push(
                                            `/app/show/${showId}/episodes/edit/${episode.id}`,
                                        );
                                }}
                            >
                                <MenuItemIcon icon={icon({ name: 'pen-line', style: 'solid' })} />
                                <FormattedMessage defaultMessage="Modifier" />
                            </MenuDropdownItem>
                        )}
                        {episode && !episodeIsPrivate && episodeIsOnline && (
                            <MenuDropdownItem
                                onAction={() => {
                                    episode?.id &&
                                        history.push(
                                            `/app/show/${showId}/stats/listeners?episode=${episode.id}`,
                                        );
                                }}
                            >
                                <MenuItemIcon
                                    icon={icon({ name: 'chart-simple', style: 'solid' })}
                                />
                                <FormattedMessage defaultMessage="Statistiques" />
                            </MenuDropdownItem>
                        )}
                        {episode && !episodeIsPrivate && !episodeIsDraft && (
                            <MenuDropdownItem
                                onAction={() => {
                                    episode?.publicId &&
                                        history.push(
                                            `/app/show/${showId}/communication/player/episodes/${episode.publicId}`,
                                        );
                                }}
                            >
                                <MenuItemIcon
                                    icon={icon({ name: 'waveform-lines', style: 'solid' })}
                                />
                                <FormattedMessage defaultMessage="Smartplayer" />
                            </MenuDropdownItem>
                        )}
                        {episode &&
                            !episodeIsPrivate &&
                            !episodeIsDraft &&
                            websiteSettings?.isPublished &&
                            episode?.siteUrl && (
                                <MenuDropdownItem href={episode.siteUrl} target="_blank">
                                    <MenuItemIcon icon={icon({ name: 'globe', style: 'solid' })} />
                                    <FormattedMessage defaultMessage="Site Web" />
                                </MenuDropdownItem>
                            )}
                        {episode &&
                            !episodeIsPrivate &&
                            smartlinkSettings?.isPublished &&
                            episode?.smartlinkUrl && (
                                <MenuDropdownItem href={episode.smartlinkUrl} target="_blank">
                                    <MenuItemIcon icon={icon({ name: 'link', style: 'solid' })} />
                                    {episodeIsDraft || episodeIsScheduled ? (
                                        <FormattedMessage defaultMessage="Futur lien du Smartlink" />
                                    ) : (
                                        <FormattedMessage defaultMessage="Smartlink" />
                                    )}
                                </MenuDropdownItem>
                            )}
                        {episode?.audioUrl && (
                            <MenuDropdownItem href={episode.audioUrl} target="_blank">
                                <MenuItemIcon icon={icon({ name: 'download', style: 'solid' })} />
                                <FormattedMessage defaultMessage="Télécharger" />
                            </MenuDropdownItem>
                        )}
                        {notViewerNotArchived && (
                            <MenuDropdownItem
                                onAction={() =>
                                    episode?.id &&
                                    duplicateEpisode.mutate(
                                        { episodeId: `${episode.id}` },
                                        {
                                            onSuccess: (newEpisode) => {
                                                if (newEpisode.id) {
                                                    history.push(
                                                        `/app/show/${showId}/episodes/edit/${newEpisode.id}`,
                                                    );
                                                }
                                            },
                                        },
                                    )
                                }
                            >
                                <MenuItemIcon icon={icon({ name: 'copy', style: 'solid' })} />
                                <FormattedMessage defaultMessage="Dupliquer" />
                            </MenuDropdownItem>
                        )}
                        {userHasWriteAccess && (
                            <MenuDropdownItem onAction={onMoveOpen}>
                                <MenuItemIcon
                                    icon={icon({ name: 'arrow-turn-left-up', style: 'solid' })}
                                />
                                <FormattedMessage defaultMessage="Déplacer" />
                            </MenuDropdownItem>
                        )}
                        {userHasWriteAccess && (
                            <MenuDropdownItem
                                onAction={() => setIsDeleteModalOpen(true)}
                                color="--alert"
                            >
                                <MenuItemIcon icon={icon({ name: 'trash', style: 'solid' })} />
                                <FormattedMessage defaultMessage="Supprimer" />
                            </MenuDropdownItem>
                        )}
                    </MenuDropdownStyled>
                </PopoverStyled>
            </MenuTrigger>
            <MoveEpisodesModal
                isOpened={isMoveOpened}
                onClose={onMoveClose}
                count={1}
                moving={false}
                onMove={(newShowId: string) => {
                    if (!episode?.id || !newShowId) return;
                    moveEpisode.mutate(
                        { episodeId: `${episode.id}`, newShowId },
                        {
                            onSettled: () => {
                                onMoveClose();
                            },
                        },
                    );
                }}
            />
            <DeleteEpisodeModal
                isOpen={isDeleteModalOpen}
                onOpenChange={setIsDeleteModalOpen}
                isLoading={deleteEpisode.isLoading}
                onDelete={handleDeleteEpisode}
            />
        </>
    );
};

const TriggerButton = styled(IconButton)`
    padding: 0;
    transition-duration: 0.2s;
    color: var(--neutral500);

    &:hover {
        color: var(--neutral600);
    }
`;
const EllipsisIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
`;
const MenuItemIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const PopoverStyled = styled(Popover)`
    width: 100%;
    max-width: fit-content;
    min-width: 10rem;
    z-index: 1 !important;
`;
const MenuDropdownStyled = styled(MenuDropdown)`
    max-width: fit-content;
    min-width: 10rem;
`;

export default ContextualMenu;
