import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addShowGuestInvitation } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import { ShowGuestInvitation } from './useShowGuestInvitationsQuery.hook';
import guestKeys from './guestKeys';
import { ShowGuestsInvitationFormSchema } from '@/components/ShowGuests/ShowGuestsInvitationForm/useShowGuestsInvitationFormSchema.hook';
import showKeys from '../show/showKeys';

export interface MutationFnProps {
    showId: string | number;
    invitation: ShowGuestsInvitationFormSchema;
}

const mutationFn = async ({
    showId,
    invitation,
}: MutationFnProps): Promise<ShowGuestInvitation> => {
    const { data } = await addShowGuestInvitation(String(showId), invitation);

    return camelcaseKeys(data as any, { deep: true });
};

const addShowGuestInvitationMutation = () => {
    const queryclient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: (_, { showId }) => {
            queryclient.invalidateQueries({
                queryKey: guestKeys.listByShowId(showId),
            });
            // Invalidate show detail query to update the channelCanInvite field
            queryclient.invalidateQueries({
                queryKey: showKeys.detailById(String(showId)),
            });
        },
    });
};

export default addShowGuestInvitationMutation;
