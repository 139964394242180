import ListBoxItem from '@/components/ui/atoms/ListBoxItem';
import Select from '@/components/ui/atoms/Select';
import { Key } from 'react-aria';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

interface DaysRangeSelectProps {
    value: 7 | 15 | 30 | 90;
    onChange: (value: 7 | 15 | 30 | 90) => void;
    isDisabled: boolean;
}

const DaysRangeSelect = ({ value, onChange, isDisabled }: DaysRangeSelectProps) => {
    const intl = useIntl();
    const options = [
        { id: '7', name: intl.formatMessage({ defaultMessage: '7 premiers jours' }) },
        { id: '15', name: intl.formatMessage({ defaultMessage: '15 premiers jours' }) },
        { id: '30', name: intl.formatMessage({ defaultMessage: '30 premiers jours' }) },
        { id: '90', name: intl.formatMessage({ defaultMessage: '90 premiers jours' }) },
    ];

    const onChangeValue = (value: Key) => {
        if (!value) return;
        const numValue = Number(value);
        if (isNaN(numValue)) return;
        onChange(numValue as 7 | 15 | 30 | 90);
    };

    return (
        <StyledSelect
            items={options}
            defaultSelectedKey={String(value)}
            onSelectionChange={onChangeValue}
            isDisabled={isDisabled}
        >
            {(item) => <ListBoxItem {...item} />}
        </StyledSelect>
    );
};

const StyledSelect = styled(Select)`
    max-width: 10rem;
`;

export default DaysRangeSelect;
