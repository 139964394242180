import Spinner from '@/components/ui/atoms/Spinner';
import Text from '@/components/ui/atoms/Text';
import Cluster from '@/components/ui/layout/Cluster';
import { FormattedMessage } from 'react-intl';

const FileImporting = () => (
    <Cluster $gap="1rem" $align="center">
        <Spinner size={32} />
        <Text color="--neutral500" numberOfLines={1}>
            <FormattedMessage defaultMessage="En cours de téléchargement" />
        </Text>
    </Cluster>
);

export default FileImporting;
