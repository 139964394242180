import styled from 'styled-components';
import paddingMixin, { paddingPropTypes } from './padding';
import colorMixin, { colorPropTypes } from './color';
import typographyMixin, { typographyPropTypes } from './typography';
import layoutMixin, { layoutPropTypes } from './layout';
import backgroundMixin, { backgroundPropTypes } from './background';
import flexMixin, { flexPropTypes } from './flex';
import gridMixin, { gridPropTypes } from './grid';
import borderMixin, { borderPropTypes } from './border';
import positionMixin, { positionPropTypes } from './position';
import effectMixin, { effectPropTypes } from './effect';
import interactivityMixin, { interactivityPropTypes } from './interactivity';
import transformMixin, { transformPropTypes } from './transform';
import textDecorationMixin, { textDecorationPropTypes } from './text-decoration';
import transitionMixin, { transitionPropTypes } from './transition';
import marginMixin, { marginPropTypes } from './margin';

/**
 * Box is the most abstract component on top of which other layout components are built.
 * By default, it renders a div element.
 *
 * References:
 * https://chakra-ui.com/docs/layout/box
 */
const Box = styled.div`
    ${backgroundMixin};
    ${borderMixin};
    ${colorMixin};
    ${effectMixin};
    ${flexMixin};
    ${gridMixin};
    ${interactivityMixin};
    ${layoutMixin};
    ${marginMixin};
    ${paddingMixin};
    ${positionMixin};
    ${textDecorationMixin};
    ${transformMixin};
    ${transitionMixin};
    ${typographyMixin};
`;

Box.propTypes = {
    ...backgroundPropTypes,
    ...borderPropTypes,
    ...colorPropTypes,
    ...effectPropTypes,
    ...flexPropTypes,
    ...gridPropTypes,
    ...interactivityPropTypes,
    ...layoutPropTypes,
    ...marginPropTypes,
    ...paddingPropTypes,
    ...positionPropTypes,
    ...textDecorationPropTypes,
    ...transformPropTypes,
    ...transitionPropTypes,
    ...typographyPropTypes,
};

export default Box;
