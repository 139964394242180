import { forwardRef } from 'react';
import Portal from '@ui/molecules/Portal';
import MobileOrTablet from '@ui/molecules/MobileOrTablet';
import Desktop from '@ui/molecules/Desktop';
import DeprecatedPaper from '../DeprecatedPaper';
import styled from 'styled-components';

const MenuWrapper = styled.ul`
    position: absolute;
    right: 1rem;
    top: 4rem;
    z-index: 9999;
    box-shadow: var(--s-light);
    border-radius: var(--r-xs);
    background: var(--white);
    padding: 0;
    margin: 0;
`;

const Menu = forwardRef(({ children, opened, className }) => (
    <>
        <MobileOrTablet>
            {opened && (
                <Portal>
                    <DeprecatedPaper
                        w="90vw"
                        pos="fixed"
                        y="50%"
                        x="50%"
                        py={10}
                        z={9999}
                        background="white"
                        style={{ transform: 'translate(-50%, -50%)' }}
                        rounded
                    >
                        {children}
                    </DeprecatedPaper>
                </Portal>
            )}
        </MobileOrTablet>
        <Desktop>{opened && <MenuWrapper className={className}>{children}</MenuWrapper>}</Desktop>
    </>
));

Menu.displayName = 'Menu';

export default Menu;
