import { useQuery, QueryFunctionContext, UseQueryOptions } from '@tanstack/react-query';
import { fetchClipFormatsOfPodcast } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import clipFormatKeys from './clipFormatKeys';
import { z } from 'zod';

export const ClipFormatSchemaRaw = z.object({
    id: z.number(),
    createdAt: z.string(),
    format: z.string(),
    fullCover: z.boolean(),
    images: z.object({
        data: z.array(
            z.object({
                format: z.string(),
                url: z.string(),
            }),
        ),
    }),
    previewUrl: z.string().nullable(),
    state: z.string(),
    updatedAt: z.string(),
    url: z.string().nullable(),
    podcast_video_clip: z.object({
        data: z.array(
            z.object({
                adminTitle: z.string(),
                caption: z.string(),
                color: z.string(),
                createdAt: z.string(),
                duration: z.number(),
                end: z.number(),
                id: z.number(),
                lang: z.string(),
                podcastId: z.number(),
                showTitles: z.boolean(),
                showWaveform: z.boolean(),
                start: z.number(),
                step: z.string(),
                title: z.string(),
                titleColor: z.string(),
                transcript: z.boolean(),
                updatedAt: z.string(),
            }),
        ),
    }),
});

export const ClipFormatSchema = z.object({
    id: z.number(),
    createdAt: z.string(),
    format: z.string(),
    fullCover: z.boolean(),
    images: z.array(
        z.object({
            format: z.string(),
            url: z.string(),
        }),
    ),
    previewUrl: z.string().nullable(),
    state: z.string(),
    updatedAt: z.string(),
    url: z.string().nullable(),
    clip: z.object({
        adminTitle: z.string(),
        caption: z.string(),
        color: z.string(),
        createdAt: z.string(),
        duration: z.number(),
        end: z.number(),
        id: z.number(),
        lang: z.string(),
        podcastId: z.number(),
        showTitles: z.boolean(),
        showWaveform: z.boolean(),
        start: z.number(),
        step: z.string(),
        title: z.string(),
        titleColor: z.string(),
        transcript: z.boolean(),
        updatedAt: z.string(),
    }),
});

const ClipFormatsSchema = z.array(ClipFormatSchema);

type Key = QueryFunctionContext<ReturnType<typeof clipFormatKeys.listByEpisodeId>>;
type ClipFormatRaw = z.infer<typeof ClipFormatSchemaRaw>;
export type ClipFormat = z.infer<typeof ClipFormatSchema>;

const queryFn = async ({ queryKey: [{ episodeId }] }: Key) => {
    const { data } = await fetchClipFormatsOfPodcast(episodeId);

    const formattedData = data.map((clipFormat: ClipFormatRaw) => {
        const { podcast_video_clip, images, ...rest } = clipFormat;
        return {
            ...rest,
            images: images.data,
            clip: podcast_video_clip.data,
        };
    });

    // TODO: Do camelcase transformation in Axios interceptor
    return ClipFormatsSchema.parse(camelcaseKeys(formattedData, { deep: true }));
};

interface UseEpisodeClipFormatsQueryProps {
    episodeId: string;
    options?: {
        staleTime?: number;
    };
}

const useEpisodeClipFormatsQuery = ({ episodeId, options }: UseEpisodeClipFormatsQueryProps) => {
    return useQuery({
        queryKey: clipFormatKeys.listByEpisodeId(episodeId),
        queryFn,
        enabled: !!episodeId,
        ...options,
    });
};

export default useEpisodeClipFormatsQuery;
