import Text from '@ui/atoms/Text';
import styled from 'styled-components';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const GENDER_TYPES = {
    FEMININE: 'F',
    MASCULINE: 'M',
    OTHER: 'U',
};

interface GenderProps {
    type: string;
    percent?: number;
}

const Gender = ({ type, percent }: GenderProps) => (
    <GenderWrapper>
        <GenderCircleIcon $backgroundColor={GENDER[type].backgroundColor}>
            {GENDER[type].icon}
        </GenderCircleIcon>
        <DataWrapper>
            <Text fontWeight="--fw-semibold" variant="title">
                {percent ? `${percent}%` : '-'}
            </Text>
            <Text as="span" color="--neutral500">
                {GENDER[type].label}
            </Text>
        </DataWrapper>
    </GenderWrapper>
);

const GenderWrapper = styled.div`
    display: flex;
    gap: 0.5rem;
`;

const DataWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const GenderCircleIcon = styled.div<{ $backgroundColor: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3.75rem;
    width: 3.75rem;
    border-radius: var(--r-full);
    background-color: ${(props) => props.$backgroundColor};
`;

const GenderIcon = styled(FontAwesomeIcon)<{ $color: string }>`
    height: 2rem;
    width: 2rem;
    color: ${(props) => props.$color};
`;

const GENDER = {
    [GENDER_TYPES.FEMININE]: {
        icon: <GenderIcon icon={icon({ name: 'venus', style: 'solid' })} $color="#22DBF4" />,
        label: <FormattedMessage defaultMessage="femmes" />,
        backgroundColor: '#E9FBFE',
    },
    [GENDER_TYPES.MASCULINE]: {
        icon: <GenderIcon icon={icon({ name: 'mars', style: 'solid' })} $color="#266FFF" />,
        label: <FormattedMessage defaultMessage="hommes" />,
        backgroundColor: '#E9F1FF',
    },
    [GENDER_TYPES.OTHER]: {
        icon: <GenderIcon icon={icon({ name: 'ellipsis', style: 'solid' })} $color="#751CBF" />,
        label: <FormattedMessage defaultMessage="autres" />,
        backgroundColor: '#F1E8F9',
    },
};

export default Gender;
