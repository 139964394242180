import { useRef, useEffect } from 'react';
import AushaHeader from './AushaHeader';
import { connect } from '../decorators/connect';
import TopbarWarningBand from '../molecules/TopbarWarningBand';
import TopbarPastDueWarningBand from '../molecules/TopbarPastDueWarningBand';
import TopbarEndedBand from '../molecules/TopbarEndedBand';
import TopbarEnterpriseAccountDeactivatedBand from '../molecules/TopbarEnterpriseAccountDeactivatedBand';
import TopbarUnpaidInvoiceWarningBand from '../molecules/TopbarUnpaidInvoiceWarningBand';
import styled from 'styled-components';

const enhance = connect(({ userStore, subscriptionStore }) => ({
    user: userStore.user,
    showWarning: subscriptionStore.subscription && subscriptionStore.subscription.onGrace,
    pastDue: subscriptionStore.subscription && subscriptionStore.subscription.pastDue,
    hasUnpaidInvoices:
        subscriptionStore.subscription &&
        subscriptionStore.subscription?.hasUnpaidInvoices === true,
    ended: subscriptionStore.subscription && subscriptionStore.subscription.ended,
}));

const Topbar = ({
    showWarning,
    pastDue,
    hasUnpaidInvoices,
    ended,
    noBackground,
    user,
    setTopbarHeight,
}) => {
    if (!user) return null;
    const enterpriseAccountDeactivated = user.pricing === 'enterprise' && user.activated === false;

    const topbarRef = useRef(null);

    useEffect(() => {
        setTopbarHeight(topbarRef.current.clientHeight);
    }, [topbarRef, ended, showWarning, enterpriseAccountDeactivated, pastDue]);

    return (
        <TopbarWrapper ref={topbarRef}>
            {ended && <TopbarEndedBand key={0} />}
            {showWarning && <TopbarWarningBand key={0} />}
            {enterpriseAccountDeactivated && <TopbarEnterpriseAccountDeactivatedBand />}
            {pastDue && <TopbarPastDueWarningBand key={0} />}
            {hasUnpaidInvoices && <TopbarUnpaidInvoiceWarningBand key={0} />}
            <AushaHeader noBackground={noBackground} />
        </TopbarWrapper>
    );
};

Topbar.defaultProps = {
    setTopbarHeight: () => {},
};
const TopbarWrapper = styled.div`
    display: flex;
    flex-direction: column;
    z-index: 1;
    position: relative;
`;

export default enhance(Topbar);
