import { useEffect, useState } from 'react';
import Stack from '@/components/ui/layout/Stack';
import FacebookField from './FacebookField';
import TwitterField from './TwitterField';
import InstagramField from './InstagramField';
import LinkedinField from './LinkedinField';
import { AISocialNetwork } from '@/queries/ai/useAISocialPostsSuggestionsQuery';
import {
    EPISODE_AI_LOADING_ACTIONS,
    useEpisodeAILoading,
} from '../contexts/NonHostingEpisodeAILoadingContext';
import { useAccordionItem } from '@/components/ui/atoms/AccordionItem/useAccordionItem.hook';

const NonHostingEpisodeFormSocialPosts = () => {
    const [loadedPosts, setLoadedPosts] = useState<AISocialNetwork[]>([]);
    const [state, dispatch] = useEpisodeAILoading();
    const isLoading = state.get('smm') || false;
    const { open, isExpanded } = useAccordionItem();
    const isOnePostLoading =
        state.get('smm-facebook') ||
        state.get('smm-twitter') ||
        state.get('smm-instagram') ||
        state.get('smm-linkedin') ||
        false;

    useEffect(() => {
        if (open && !isExpanded && (isLoading || isOnePostLoading)) {
            open();
        }
    }, [isLoading]);

    /* 
        The following useEffect hook is used to stop the smm global loading animation 
        when all the social posts are loaded.
    */
    useEffect(() => {
        if (
            isLoading &&
            (['facebook', 'twitter', 'instagram', 'linkedin'] as AISocialNetwork[]).every(
                (network) => loadedPosts.includes(network),
            )
        ) {
            dispatch({
                type: EPISODE_AI_LOADING_ACTIONS.STOP,
                payload: 'smm',
            });
            setLoadedPosts([]);
        }
    }, [loadedPosts]);

    const onLoadingEnd = (network: AISocialNetwork) => {
        if (!isLoading) return;
        setLoadedPosts((prevPosts) => {
            if (prevPosts.includes(network)) return prevPosts;
            return [...prevPosts, network];
        });
    };

    return (
        <Stack $gap="2rem">
            <FacebookField onLoadingEnd={onLoadingEnd} />
            <TwitterField onLoadingEnd={onLoadingEnd} />
            <InstagramField onLoadingEnd={onLoadingEnd} />
            <LinkedinField onLoadingEnd={onLoadingEnd} />
        </Stack>
    );
};

export default NonHostingEpisodeFormSocialPosts;
