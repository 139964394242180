import FacebookSummary from './FacebookSummary';
import InstagramSummary from './InstagramSummary';
import LinkedinSummary from './LinkedinSummary';
import TwitterSummary from './TwitterSummary';

const SocialPostsSummary = () => {
    return (
        <>
            <FacebookSummary />
            <TwitterSummary />
            <InstagramSummary />
            <LinkedinSummary />
        </>
    );
};

export default SocialPostsSummary;
