import styled from 'styled-components';
import { useParams } from 'react-router';
import useEpisodeQuery from '@/queries/episode/useEpisodeQuery.hook';
import NonHostingEpisodePreview from '@/components/NonHostingEpisodePreview';
import NonHostingEpisodePreviewSkeleton from '@/components/NonHostingEpisodePreview/NonHostingEpisodePreviewSkeleton';

const NonHostingEpisodePreviewPage = () => {
    const { episodeId } = useParams<{ episodeId: string }>();
    const episode = useEpisodeQuery({ episodeId });

    if (episode.isError) {
        return <div>ERROR</div>;
    }

    if (episode.data) {
        return (
            <Wrapper>
                <NonHostingEpisodePreview episode={episode.data} />
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            <NonHostingEpisodePreviewSkeleton />
        </Wrapper>
    );
};

const Wrapper = styled.section`
    padding-block: 2rem;
    padding-inline: 1rem;
    display: flex;
    justify-content: center;
`;

export default NonHostingEpisodePreviewPage;
