import { FormattedMessage } from 'react-intl';
import HideFor from '@app/atoms/HideFor';
import Alert from '@ui/atoms/Alert';
import IconInfo from '@ui/icons/IconInfo';
import noWebhookMainIllustration from '@public/images/empty-state/illustration/audience.png';
import Text from '@ui/atoms/Text';
import ZebraIllustration from '@ui/atoms/ZebraIllustration';
import EmptyState from '@ui/molecules/EmptyState';
import ShiningDiamond from '@ui/atoms/ShiningDiamond';
import styled from 'styled-components';
import { useAmplitudeLogEvent } from '@hooks/useAmplitude';
import GendersGraph from '@app/pages/StatsAudiencePage/GendersGraph';
import AgesGraph from '@app/pages/StatsAudiencePage/AgesGraph';
import { useParams } from 'react-router';
import useShowQuery from '@queries/show/useShowQuery.hook';
import { DEEZER } from '@/utils/constants';
import RouterLink from '@/components/Link/RouterLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { USER_OPTIONS } from '@/utils/userOptions';

const StatsAudience = () => {
    useAmplitudeLogEvent('Stats Audience Tab Viewed');

    const { showId } = useParams<{ showId: string }>();
    const { data: show, isLoading, isError } = useShowQuery(showId);

    if (isLoading || isError) return null;

    const hasDeezerData = show.listeningLinks.data.findIndex((link) => link.key === DEEZER) > -1;

    return (
        <HideFor
            // @ts-ignore
            userOptions={USER_OPTIONS.STATS_DEEZER}
            lockedMessage={
                <EmptyStateOuterWrapper>
                    <Text fontWeight="--fw-bold" variant="title">
                        <FormattedMessage defaultMessage="Audience" />
                    </Text>
                    <EmptyState
                        illustration={noWebhookMainIllustration}
                        illustrationType="image"
                        illustrationAlign="right"
                    >
                        <HeadIllustrationWrapper>
                            <ZebraIllustration>
                                <ShiningDiamond />
                            </ZebraIllustration>
                        </HeadIllustrationWrapper>
                        <LabelWrapper>
                            <Text variant="heading" fontWeight="--fw-bold">
                                <FormattedMessage defaultMessage="Passez à l'offre supérieure !" />
                            </Text>
                            <Text variant="body" fontWeight="--fw-normal" color="--neutral500">
                                <FormattedMessage defaultMessage="Pour en apprendre plus sur votre audience, abonnez-vous à l'offre Supersonic 🚀" />
                            </Text>
                        </LabelWrapper>
                        <RouterLink
                            variant="button-primary"
                            startIcon={
                                <EmptyStateButtonIcon
                                    icon={icon({ name: 'rocket-launch', style: 'solid' })}
                                />
                            }
                            to="/app/subscription/offers"
                        >
                            <FormattedMessage defaultMessage="Mettre à niveau mon offre" />
                        </RouterLink>
                    </EmptyState>
                </EmptyStateOuterWrapper>
            }
        >
            {!hasDeezerData && (
                <AlertStyled variant="info" icon={<IconInfo />}>
                    <Text>
                        <FormattedMessage
                            defaultMessage="Votre podcast doit être présent sur Deezer pour consulter les données démographiques de votre audience. {deezer}"
                            values={{
                                deezer: (
                                    <DeezerLink to={`/app/show/${showId}/settings/broadcast`}>
                                        <FormattedMessage defaultMessage="Diffuser sur Deezer" />
                                    </DeezerLink>
                                ),
                            }}
                        />
                    </Text>
                </AlertStyled>
            )}
            <GendersGraph />
            <AgesGraph />
        </HideFor>
    );
};

const AlertStyled = styled(Alert)`
    margin-top: 30px;
`;

const DeezerLink = styled(RouterLink)`
    text-decoration: underline;
    color: var(--black);
    font-weight: var(--fw-bold);
`;

const EmptyStateOuterWrapper = styled.div`
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

const EmptyStateButtonIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;

const HeadIllustrationWrapper = styled.div`
    width: 200px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    & > * {
        text-align: center;
    }
`;

export default StatsAudience;
