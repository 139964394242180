import Model from './Model';
import { array } from './resolvers';
import ClipTranscriptModel from './ClipTranscriptModel';

class ClipTranscriptionModel extends Model {
    static attributes = {
        start: 'start',
        end: 'end',
        subtitles: array('subtitles', ClipTranscriptModel, 'clipStore'),
    };
}

export default ClipTranscriptionModel;
