const guestKeys = {
    all: () => [{ entity: 'guest' }],
    allLists: () => [{ ...guestKeys.all()[0], scope: 'list' }],
    list: () => [{ ...guestKeys.allLists()[0] }],
    listByShowId: (rawShowId: string | number) => [
        { ...guestKeys.list()[0], showId: String(rawShowId) },
    ],
    listByChannelId: (rawChannelId: number | undefined) => [
        { ...guestKeys.list()[0], channelId: String(rawChannelId) },
    ],
    invitationList: () => [{ ...guestKeys.list()[0], subscope: 'invitation' }],
    permissionList: () => [{ ...guestKeys.list()[0], subscope: 'permission' }],
    invitationListByShowId: (rawShowId: string | number) => [
        { ...guestKeys.invitationList()[0], showId: String(rawShowId) },
    ],
    invitationListByChannelId: (rawChannelId: number | undefined) => [
        { ...guestKeys.invitationList()[0], channelId: String(rawChannelId) },
    ],
    permissionListByShowId: (rawShowId: string | number) => [
        { ...guestKeys.permissionList()[0], showId: String(rawShowId) },
    ],
    permissionListByChannelId: (rawChannelId: number | undefined) => [
        { ...guestKeys.permissionList()[0], channelId: String(rawChannelId) },
    ],
    userPermissionList: (rawChannelId: number | undefined, rawUserId: number) => [
        {
            ...guestKeys.listByChannelId(rawChannelId)[0],
            subscope: 'summary',
            userId: String(rawUserId),
        },
    ],
    exportAccount: (rawChannelId: string | number) => [
        { ...guestKeys.list()[0], subscope: 'exportAccount', channelId: String(rawChannelId) },
    ],
    exportShow: (rawShowId: string | number) => [
        { ...guestKeys.list()[0], subscope: 'exportShow', showId: String(rawShowId) },
    ],
};

export default guestKeys;
