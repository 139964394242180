import { FC, useState, useEffect, createContext, useContext, ReactNode } from 'react';

interface TBroadcastContext {
    settingsEnabledPlatform: string;
    setSettingsEnabledPlatform: (platform: string) => void;
}

const BroadcastContext = createContext<TBroadcastContext | undefined>(undefined);

interface BroadcastContextProviderProps {
    children: ReactNode;
}

export const BroadcastContextProvider: FC<BroadcastContextProviderProps> = ({ children }) => {
    const [settingsEnabledPlatform, setSettingsEnabledPlatform] = useState<string>('');

    useEffect(() => {
        console.log(settingsEnabledPlatform);
    }, [settingsEnabledPlatform]);

    const contextValue = {
        settingsEnabledPlatform,
        setSettingsEnabledPlatform,
    };

    return <BroadcastContext.Provider value={contextValue}>{children}</BroadcastContext.Provider>;
};

export const useBroadcastContext = () => {
    const context = useContext(BroadcastContext);
    if (context === undefined) {
        throw new Error('useBroadcastContext must be used within a BroadcastContextProvider');
    }
    return context;
};
