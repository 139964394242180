import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useResponsive } from '@/shared/hooks/useResponsive';
import { useParams } from 'react-router-dom';
import PaperGrid from '@ui/atoms/PaperGrid';
import Variation from './Variation';
import useQuery from '@/shared/hooks/useQuery';
import useListenersOverallQuery from '@queries/stats/useListenersOverallQuery.hook';
import { getLocalTimeZone } from '@internationalized/date';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import styled from 'styled-components';

const responsiveData = (responsive: { isMobile: boolean; isTablet: boolean }) => {
    if (responsive.isMobile)
        return { column: 5, scrollSnapType: 'x mandatory', overflowX: 'scroll', h: 100 };
    if (responsive.isTablet) return { w: '100%', column: 3, h: 'max-content', py: 20, rowGap: 20 };
    return { w: '100%', column: 5, h: 'max-content' };
};

const ListenersStatsOveralls = () => {
    const { isMobile, isTablet } = useResponsive();
    const { showId } = useParams<{ showId: string }>();
    const { data: user } = useUserQuery();
    const query = useQuery();
    const campaignId = query.get('campaignId');
    const resp = responsiveData({ isMobile, isTablet });
    useMemo(() => responsiveData({ isMobile, isTablet }), [isMobile, isTablet]);
    const timezone = useMemo(() => user?.timezone || getLocalTimeZone(), [user]);

    const { data: showOverall } = useListenersOverallQuery({
        showId,
        timezone,
        options: {
            enabled: !campaignId,
        },
    });

    const overallStats = campaignId ? null : showOverall;

    if (!overallStats) return null;
    return (
        <ListenersStatsOverallsWrapper>
            <>
                <PaperGrid align="center" {...resp}>
                    <Variation
                        value={overallStats.today}
                        average={overallStats.avgDays}
                        step="today"
                    >
                        <FormattedMessage defaultMessage="aujourd'hui" />
                    </Variation>
                    <Variation
                        value={overallStats.last7Days}
                        average={overallStats.avgWeeks}
                        step="lastWeek"
                    >
                        <FormattedMessage defaultMessage="7 derniers jours" />
                    </Variation>
                    <Variation
                        value={overallStats.lastMonth}
                        average={overallStats.avgMonths}
                        step="lastMonth"
                    >
                        <FormattedMessage defaultMessage="30 derniers jours" />
                    </Variation>
                    <Variation value={overallStats.beforeLastMonth}>
                        <FormattedMessage
                            defaultMessage="{count} jours d'avant"
                            values={{ count: 30 }}
                        />
                    </Variation>
                    <Variation
                        value={overallStats.totalDownloads}
                        average={overallStats.avgPodcasts}
                        step="fromStart"
                    >
                        <FormattedMessage defaultMessage="depuis le début" />
                    </Variation>
                </PaperGrid>
            </>
        </ListenersStatsOverallsWrapper>
    );
};

const ListenersStatsOverallsWrapper = styled.div`
    position: relative;
    display: flex;
    min-height: 90px;
    align-items: center;
    flex-wrap: wrap;
    z-index: 3;
    padding: 2rem;
    border-radius: var(--r-l);
    background-color: var(--white);
`;

export default ListenersStatsOveralls;
