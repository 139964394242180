import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import ProgressCircle from '../ProgressCircle';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import { STATUSES } from '@/models/Progress';

const messages = {
    [STATUSES.QUEUED]: <FormattedMessage defaultMessage="En attente…" />,
    [STATUSES.FAILED]: <FormattedMessage defaultMessage="Une erreur est survenue" />,
    [STATUSES.FINISHED]: <FormattedMessage defaultMessage="Terminée" />,
    [STATUSES.EXECUTING]: <FormattedMessage defaultMessage="En cours…" />,
};

const colors = {
    [STATUSES.QUEUED]: '--neutral500',
    [STATUSES.FAILED]: '--alert',
    [STATUSES.FINISHED]: '--success',
    [STATUSES.EXECUTING]: '--black',
};

function ProgressTracker({ label, progressObj = {} }) {
    return (
        <DeprecatedPaper flex column align="center">
            <ProgressCircle
                color={progressObj.hasFailed || progressObj.isFailed ? 'error' : undefined}
                disabled={progressObj.isQueued}
                progress={(progressObj.percent || progressObj.percentage || 0) / 100}
            />
            <DeprecatedText
                align="center"
                color={colors[progressObj.status]}
                my={5}
                weight="semibold"
            >
                {label}
            </DeprecatedText>
            <DeprecatedText align="center" color={colors[progressObj.status]}>
                {messages[progressObj.status]}
            </DeprecatedText>
        </DeprecatedPaper>
    );
}

export default observer(ProgressTracker);
