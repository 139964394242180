import { useMemo } from 'react';
import Desktop from '@public/images/stats_desktop.svg';
import Mobile from '@public/images/stats_mobile.svg';
import Tablet from '@public/images/stats_tablette.svg';
import Watch from '@public/images/stats_watch.svg';
import TV from '@public/images/stats_tv.svg';
import Speaker from '@public/images/stats_enceinte.svg';
import { connect } from '@app/decorators/connect';
import { useResponsive } from '@/utils/hooks/useResponsive';
import { FormattedMessage } from 'react-intl';
import DeprecatedText from '../../ui/atoms/DeprecatedText';
import DevicesFallback from '../fallbacks/DevicesFallback';
import DeprecatedIcon from '../../ui/atoms/DeprecatedIcon';
import PaperGrid from '../../ui/atoms/PaperGrid';
import Column from '../../ui/atoms/Column';
import { round } from '../../../helpers';
import DeprecatedPaper from '../../ui/atoms/DeprecatedPaper';
import FetcherSourceDevices from '../fetchers/stats/FetcherSourceDevices';
import StatsDataSetSelector from './StatsDataSetSelector';
import { TABS_STATS_NAME } from '../pages/StatsPage';
import { useStatsDateSelectorContext } from '@/context/StatsDateSelectorContext';
import styled from 'styled-components';

const logos = {
    tablet: Tablet,
    desktop: Desktop,
    tv: TV,
    speaker: Speaker,
    smartphone: Mobile,
    'portable media player': Mobile,
    car_browser: Mobile,
    console: Mobile,
    camera: Mobile,
    watch: Watch,
};

const messages = {
    tablet: <FormattedMessage defaultMessage="tablette" />,
    desktop: <FormattedMessage defaultMessage="bureau" />,
    tv: <FormattedMessage defaultMessage="télévision" />,
    speaker: <FormattedMessage defaultMessage="enceinte" />,
    smartphone: <FormattedMessage defaultMessage="mobile" />,
    'portable media player': <FormattedMessage defaultMessage="baladeur" />,
    car_browser: <FormattedMessage defaultMessage="voiture" />,
    console: <FormattedMessage defaultMessage="console" />,
    camera: <FormattedMessage defaultMessage="camera" />,
    watch: <FormattedMessage defaultMessage="montre" />,
};

const enhance = connect(({ sourcesStatsStore, routerStore }) => ({
    devices: sourcesStatsStore.devices,
    showId: routerStore.params.showId,
    itemType: routerStore.params.itemType,
    itemId: routerStore.params.itemId,
    episodeQuery: routerStore.query.episode,
}));

const responsiveData = (responsive) => {
    if (responsive.isMobile)
        return {
            deviceSize: 50,
            rowSize: '45vw',
            column: 5,
            text: 20,
            scrollSnapType: 'x mandatory',
            scrollSnapAlign: 'center',
        };

    if (responsive.isTablet)
        return {
            deviceSize: 50,
            rowSize: 'initial',
            column: 3,
            text: 'veryBig',
        };
    return {
        deviceSize: 65,
        rowSize: 'initial',
        column: 5,
        text: 'veryBig',
    };
};
function SourcesDevices({ devices, showId, itemType, itemId, episodeQuery }) {
    const { startDate, endDate } = useStatsDateSelectorContext();
    const { isMobileOrTablet, isTabletOrDesktop, isMobile, isTablet } = useResponsive();

    const resp = responsiveData({ isMobileOrTablet, isTabletOrDesktop, isMobile, isTablet });

    useMemo(
        () => responsiveData({ isMobileOrTablet, isTabletOrDesktop, isMobile, isTablet }),
        [isMobileOrTablet, isTabletOrDesktop, isMobile, devices.length],
    );

    return (
        <>
            <StatsDataSetSelector
                tabTitle={<FormattedMessage defaultMessage="Sources" />}
                subTitle={
                    <FormattedMessage defaultMessage="Voici un aperçu des appareils sur lesquels on vous écoute." />
                }
                tabId={TABS_STATS_NAME.SOURCES}
                z={2}
            />
            <SourcesDevicesWrapper>
                <FetcherSourceDevices
                    showId={showId}
                    itemType={itemType}
                    itemId={itemId}
                    start={startDate}
                    end={endDate}
                    fallback={DevicesFallback}
                    episodes={episodeQuery}
                >
                    {devices.length === 0 ? (
                        <DeprecatedText weight="semibold" size="big">
                            <FormattedMessage defaultMessage="Aucune donnée disponible" />
                        </DeprecatedText>
                    ) : (
                        <PaperGrid
                            column={devices.length}
                            rowGap={isMobileOrTablet && 20}
                            px={isMobile ? 0 : 35}
                            py={isMobileOrTablet && 20}
                            w="100%"
                            gap={isMobile ? 0 : 10}
                            overflowX="auto"
                            scrollSnapType={resp.scrollSnapType}
                        >
                            {devices.map((device) => (
                                <Column
                                    key={device.name}
                                    justify={isMobileOrTablet && 'center'}
                                    w={resp.rowSize}
                                    scrollSnapAlign={resp.scrollSnapAlign}
                                    justifySelf="center"
                                >
                                    <DeprecatedIcon
                                        size={resp.deviceSize}
                                        as={logos[device.name]}
                                    />
                                    <DeprecatedPaper
                                        flex
                                        justify="center"
                                        ml={10}
                                        column
                                        w="max-content"
                                    >
                                        <DeprecatedText
                                            mb={-5}
                                            weight="semibold"
                                            fontSize={resp.text}
                                        >
                                            {device.percent < 1
                                                ? '< 1%'
                                                : `${round(device.percent)}%`}
                                        </DeprecatedText>
                                        {isTabletOrDesktop && (
                                            <DeprecatedText
                                                size={isMobileOrTablet && 'small'}
                                                color="--neutral500"
                                            >
                                                {messages[device.name]}
                                            </DeprecatedText>
                                        )}
                                    </DeprecatedPaper>
                                </Column>
                            ))}
                        </PaperGrid>
                    )}
                </FetcherSourceDevices>
            </SourcesDevicesWrapper>
        </>
    );
}

const SourcesDevicesWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding);
    min-height: 6rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;

    ${({ theme }) => theme.mediaQueries.mobile} {
        padding: var(--container-padding-mobile);
    }
`;

export default enhance(SourcesDevices);
