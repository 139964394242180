import styled from 'styled-components';
import PropTypes from 'prop-types';
import DeprecatedWhitePaper from '../DeprecatedWhitePaper';

const DropContainer = styled(DeprecatedWhitePaper).attrs(() => ({
    radLeft: true,
    radRight: true,
    background: 'white',
    w: '100%',
    raise: 'normal',
}))`
    border-width: 0;
    border-color: var(--primary);
    padding: 7px 0;
    width: 100%;
    z-index: ${(p) => p.z};
    max-height: 200px;
    overflow: auto;
    position: absolute;
    border-radius: ${(p) => (p.bordered ? 'var(--r-xs)' : '0')};
`;

DropContainer.propTypes = {
    bordered: PropTypes.bool,
    z: PropTypes.number,
};

DropContainer.defaultProps = {
    bordered: false,
    z: 10,
};

export default DropContainer;
