export const trim = (string, maxLength) => {
    if (string.length <= maxLength) {
        return string;
    }

    // Find the last space before the maximum length.
    const lastSpaceIndex = string.lastIndexOf(' ', maxLength - 1);

    // If there is no space before the maximum length, return a substring of the string up to the maximum length.
    if (lastSpaceIndex === -1) {
        return string.substring(0, maxLength);
    }

    // Return a substring of the string up to the last space before the maximum length.
    return string.substring(0, lastSpaceIndex);
};
