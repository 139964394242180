import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import Modal from '@ui/molecules/Modal';
import ApplePodcastsReviewsNotificationForm from './ApplePodcastsReviewsNotificationForm';
import useApplePodcastsStatsStore from '@/shared/hooks/useApplePodcastsStatsStore.hook';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import ContentLoader from 'react-content-loader';
import createFallback from '@/components/unorganized/createFallback';

const Fallback = createFallback((props) => (
    <ContentLoader
        width={360}
        height={113}
        style={{ width: '100%', maxWidth: 360, height: 113 }}
        preserveAspectRatio="none"
        {...props}
    >
        {/* Notify toggle field */}
        <rect x="0" y="0" rx="5" ry="5" width="290" height="20" />
        {/* Email label */}
        <rect x="0" y="50" rx="5" ry="5" width="90" height="14" />
        {/* Email input */}
        <rect x="0" y="78" rx="5" ry="5" width="360" height="35" />
    </ContentLoader>
));

function ApplePodcastsReviewsNotificationModal({ isOpen, onClose }) {
    const { showId } = useParams();
    const { fetchApplePodcastsReviewsNotification } = useApplePodcastsStatsStore();
    useEffect(() => showId && fetchApplePodcastsReviewsNotification(showId), [showId]);
    const isLoading = fetchApplePodcastsReviewsNotification.pending;

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={<FormattedMessage defaultMessage="Paramètres des alertes" />}
        >
            {isLoading ? (
                <Fallback />
            ) : (
                <ApplePodcastsReviewsNotificationForm onAfterSubmit={onClose} />
            )}
        </Modal>
    );
}

export default observer(ApplePodcastsReviewsNotificationModal);
