import Dialog from '@/components/ui/atoms/Dialog';
import { FormattedMessage } from 'react-intl';
import TranscriptionSpeakerForm from './TranscriptionSpeakerForm';
import { TranscriptionSpeakerFormSchema } from './TranscriptionSpeakerForm/useTranscriptionSpeakerFormSchema.hook';

interface TranscriptionEditSpeakerModalProps {
    isOpen: boolean;
    onOpenChange: (isOpen: boolean) => void;
    onSubmit: (data: TranscriptionSpeakerFormSchema) => void;
    onDelete?: () => void;
    speakerName: TranscriptionSpeakerFormSchema['name'];
}

const TranscriptionEditSpeakerModal = ({
    isOpen,
    onOpenChange,
    onSubmit,
    onDelete,
    speakerName,
}: TranscriptionEditSpeakerModalProps) => {
    return (
        // @ts-ignore
        <Dialog
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            title={<FormattedMessage defaultMessage="Modifier un orateur" />}
            size="small"
        >
            <TranscriptionSpeakerForm
                speakerName={speakerName}
                onSubmit={onSubmit}
                onClose={() => onOpenChange(false)}
                onDelete={onDelete}
            />
        </Dialog>
    );
};

export default TranscriptionEditSpeakerModal;
