import { useState } from 'react';
import Button from '@/components/Button';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import ShowGuestsInvitationModal from '../ShowGuestsInvitationModal';
import { useParams } from 'react-router';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import GuestsQuotaReachedModal from '@/components/GuestsQuotaReachedModal';

const ShowGuestsAddButton = (props: React.ComponentProps<typeof Button>) => {
    const { showId } = useParams<{ showId: string }>();
    const { data: show } = useShowQuery(showId);
    const [isInvitationModalOpen, setInvitationModalOpen] = useState(false);
    const [isQuotaReachedModalOpen, setIsQuotaReachedModalOpen] = useState(false);

    const handleOpenInvitationModal = () => {
        if (show?.channelCanInvite === false) {
            setIsQuotaReachedModalOpen(true);
            return;
        }
        setInvitationModalOpen(true);
    };

    return (
        <>
            <Button
                {...props}
                onPress={handleOpenInvitationModal}
                startIcon={<AddIcon icon={icon({ name: 'plus', style: 'solid' })} />}
            />
            <ShowGuestsInvitationModal
                isOpen={isInvitationModalOpen}
                onOpenChange={setInvitationModalOpen}
                onPermissionError={() => {
                    setInvitationModalOpen(false);
                    setIsQuotaReachedModalOpen(true);
                }}
            />
            <GuestsQuotaReachedModal
                isOpen={isQuotaReachedModalOpen}
                onOpenChange={setIsQuotaReachedModalOpen}
            />
        </>
    );
};

const AddIcon = styled(FontAwesomeIcon)`
    color: var(--white);
    width: 1rem;
    height: 1rem;
`;

export default ShowGuestsAddButton;
