import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { REASON_OPTIONS, CHANGE_PROVIDER_REASON_OPTIONS } from './UnsubscribeSurveyForm';
import { FORM_VALIDATION_MESSAGE } from '@/utils/validationMessage';

export const useUnsubscribeSurveySchema = () => {
    const intl = useIntl();

    return yup.object({
        reason: yup
            .string()
            .oneOf(REASON_OPTIONS, intl.formatMessage(FORM_VALIDATION_MESSAGE.invalid))
            .required(intl.formatMessage(FORM_VALIDATION_MESSAGE.required)),
        change_provider_reason: yup.string().when('reason', {
            is: 'provider',
            then: (schema) =>
                schema
                    .oneOf(
                        CHANGE_PROVIDER_REASON_OPTIONS,
                        intl.formatMessage(FORM_VALIDATION_MESSAGE.invalid),
                    )
                    .required(intl.formatMessage(FORM_VALIDATION_MESSAGE.required)),
        }),
        last_word: yup.string(),
    });
};
