import Button from '@/components/Button';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import FeedRedirectionModal from './FeedRedirectionModal';
import Text from '@/components/ui/atoms/Text';
import Stack from '@/components/ui/layout/Stack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useParams } from 'react-router';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import { PRICING } from '@/shared/config/pricing';
import RouterLink from '@/components/Link/RouterLink';
import useSubscriptionQuery from '@/queries/subscription/useSubscriptionQuery.hook';

interface RedirectFeedProps {
    isDisabled: boolean;
    isDone: boolean;
}

const RedirectFeed = ({ isDisabled, isDone }: RedirectFeedProps) => {
    const { showId } = useParams<{ showId: string }>();
    const { data: subscription } = useSubscriptionQuery();
    const { data: show } = useShowQuery(showId);
    const isFreemium = show?.ownerPricing === PRICING.FREEMIUM || subscription?.isFreemium;
    const [isRedirectFeedModalOpen, setIsFeedRedirectionModalOpen] = useState(false);

    return (
        <>
            <StepWrapper>
                <Stack $gap="0.25rem" $align="center">
                    <Text
                        color={isFreemium || isDisabled ? '--neutral500' : '--black'}
                        fontWeight="--fw-semibold"
                    >
                        <FormattedMessage defaultMessage="Redirigez votre flux RSS" />
                    </Text>
                    <Text color="--neutral500">
                        <FormattedMessage defaultMessage="Redirigez dès maintenant votre flux RSS pour garantir à votre audience un accès sans interruption à votre émission via votre nouveau flux." />
                    </Text>
                </Stack>
                {isFreemium ? (
                    <RouterLink
                        startIcon={
                            <ButtonIcon icon={icon({ name: 'rocket-launch', style: 'solid' })} />
                        }
                        size="small"
                        variant="button-primary"
                        to={`/app/user/subscription/offers`}
                    >
                        <FormattedMessage defaultMessage="S'abonner" />
                    </RouterLink>
                ) : isDone ? (
                    <DoneWrapper>
                        <CheckIcon icon={icon({ name: 'check', style: 'solid' })} />
                        <Text fontWeight="--fw-semibold">
                            <FormattedMessage defaultMessage="Fait" />
                        </Text>
                    </DoneWrapper>
                ) : (
                    <Button
                        isDisabled={isDisabled}
                        startIcon={<ButtonIcon icon={icon({ name: 'rss', style: 'solid' })} />}
                        size="small"
                        onPress={() => setIsFeedRedirectionModalOpen(true)}
                    >
                        <FormattedMessage
                            defaultMessage="Rediriger maintenant
"
                        />
                    </Button>
                )}
            </StepWrapper>
            <FeedRedirectionModal
                isOpen={isRedirectFeedModalOpen}
                onOpenChange={setIsFeedRedirectionModalOpen}
            />
        </>
    );
};

const StepWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 1rem;
    text-align: center;
    color: var(--black);
`;
const ButtonIcon = styled(FontAwesomeIcon)`
    width: 0.5rem;
    height: 0.5rem;
`;
const DoneWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.25rem;
    color: var(--primary500);
`;
const CheckIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;

export default RedirectFeed;
