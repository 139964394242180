import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { MAX_AUDIO_FILE_SIZE } from '@/utils/constants';
import { checkAudioFileFormat } from '@/helpers';

const useEpisodeAudioFileSchema = () => {
    const intl = useIntl();
    return yup.object({
        audioFile: yup
            .mixed()
            .required(
                intl.formatMessage({
                    defaultMessage: 'Un fichier audio est requis',
                }),
            )
            .test(
                'fileFormat',
                intl.formatMessage({
                    defaultMessage:
                        'Votre fichier doit être au format .MP3, .WAV, .M4A, .OGG ou .FLAC',
                }),
                (audioFile) =>
                    checkAudioFileFormat(audioFile.length > 0 ? audioFile?.[0] : audioFile),
            )
            .test(
                'fileSize',
                intl.formatMessage(
                    { defaultMessage: 'Votre fichier doit peser au maximum {max}Mo' },
                    { max: MAX_AUDIO_FILE_SIZE / 1000000 },
                ),
                (audioFile) =>
                    audioFile.length > 0
                        ? audioFile?.[0]?.size
                        : audioFile?.size <= MAX_AUDIO_FILE_SIZE,
            ),
    });
};

export default useEpisodeAudioFileSchema;
