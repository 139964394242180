import { useMutation, useQueryClient } from '@tanstack/react-query';
import sponsorshipCodeKeys from './sponsorshipCodeKeys';
import { updateSponsorshipCode } from '@/api';

const mutationFn = async (formData) => {
    const { data } = await updateSponsorshipCode(formData);
    return data;
};

const useUpdateSponsorshipCodeMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: () =>
            queryClient.invalidateQueries({
                queryKey: sponsorshipCodeKeys.all(),
            }),
    });
};

export default useUpdateSponsorshipCodeMutation;
