import { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';
import posed, { PoseGroup } from 'react-pose';
import { reflex } from '@ui/reflex';
import close from '@public/icons/close.svg';
import { observer } from 'mobx-react';
import DeprecatedText from '../../atoms/DeprecatedText';
import DeprecatedIcon from '../../atoms/DeprecatedIcon';
import { computeCssValue } from '@/utils/css';

const modalRoot = document.getElementById('modal-root');

const transition = { duration: 200 };

const Wrapper = reflex(
    styled(
        posed.div({
            enter: { opacity: 1, y: 0, transition },
            exit: { opacity: 0, y: 100, transition },
        }),
    )`
        ${(p) =>
            p.fullscreen &&
            css`
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 100%;
            `}
        ${(p) =>
            !p.fullscreen &&
            css`
                padding: 10px 0;
            `}
        max-height: 100%;
        max-width: ${(p) => (p.fullscreen ? '100%' : computeCssValue(p.maxW))};
        border-radius: var(--r-xs);
        z-index: 10;
    `,
);

const Header = styled.div`
    ${(p) =>
        p.fullscreen &&
        css`
            flex: 0 0 57px;
        `}
    height: 57px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    border-top-left-radius: var(--r-xs);
    border-top-right-radius: var(--r-xs);
    background-color: var(--neutral50);
    h2 {
        flex: 1;
    }

    svg {
        &:hover {
            fill: var(--black);
        }
        fill: var(--neutral500);
        transition: all 250ms;
        cursor: pointer;
    }
`;

const Content = reflex(styled.div`
    ${(p) =>
        p.fullscreen &&
        css`
            flex: 1;
        `}
    padding: 20px;
    background-color: var(--white);
    ${(p) => p.rounded && 'border-radius: var(--r-xs)'};
    border-bottom-left-radius: var(--r-xs);
    border-bottom-right-radius: var(--r-xs);
    line-height: 1.5;
`);

@observer
class DeprecatedModal extends Component {
    constructor() {
        super();
        this.el = document.createElement('div');
        this.el.id = 'modal-background';
    }

    static propTypes = {
        isOpened: PropTypes.bool.isRequired,
        title: PropTypes.node,
        onClose: PropTypes.func,
        maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
            .isRequired,
        fullscreen: PropTypes.bool,
        contentProps: PropTypes.object,
    };

    static defaultProps = {
        title: undefined,
        maxWidth: '95%',
        fullscreen: false,
        onClose: () => {},
    };

    componentDidMount() {
        this.handleOpen();
    }

    componentDidUpdate(prevProps) {
        if (this.props.isOpened !== prevProps.isOpened) {
            this.handleOpen();
            if (!this.props.isOpened) {
                this.handleClose();
            }
        }
    }

    componentWillUnmount() {
        this.handleClose();
    }

    handleOpen() {
        if (this.props.isOpened) {
            modalRoot.appendChild(this.el);
            document.documentElement.style.overflowY = 'hidden';
        }
    }

    handleClose() {
        setTimeout(() => {
            if (modalRoot.contains(this.el)) {
                modalRoot.removeChild(this.el);
                document.documentElement.style.overflowY = 'scroll';
            }
        }, 200);
    }

    render() {
        const { title, children, onClose, isOpened, contentProps, ...props } = this.props;

        return ReactDOM.createPortal(
            <PoseGroup>
                {isOpened && (
                    <Wrapper key={0} onClick={(e) => e.stopPropagation()} {...props}>
                        {title && (
                            <Header fullscreen={props.fullscreen}>
                                <DeprecatedText as="h2" weight="bold" size="big">
                                    {title}
                                </DeprecatedText>
                                {onClose && <DeprecatedIcon as={close} onClick={onClose} ml={10} />}
                            </Header>
                        )}
                        <Content fullscreen={props.fullscreen} rounded={!title} {...contentProps}>
                            {children}
                        </Content>
                    </Wrapper>
                )}
            </PoseGroup>,
            this.el,
        );
    }
}

export default DeprecatedModal;
