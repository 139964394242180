import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addChannelGuestInvitation } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import guestKeys from './guestKeys';
import { ChannelGuestsInvitationFormSchema } from '@/components/ChannelGuests/ChannelGuestsInvitationForm/useChannelGuestsInvitationFormSchema.hook';

export interface AddChannelGuestInvitationMutationFnProps {
    channelId: number;
    invitation: ChannelGuestsInvitationFormSchema;
}

const mutationFn = async ({
    channelId,
    invitation,
}: AddChannelGuestInvitationMutationFnProps): Promise<void> => {
    const { data } = await addChannelGuestInvitation({ channelId, invitation });

    return camelcaseKeys(data as any, { deep: true });
};

const addChannelGuestInvitationMutation = () => {
    const queryclient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: (_, { channelId }) => {
            queryclient.invalidateQueries({
                queryKey: guestKeys.listByChannelId(channelId),
            });
        },
    });
};

export default addChannelGuestInvitationMutation;
