import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Stack from '@ui/layout/Stack';
import Text from '@ui/atoms/Text';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Button from '@/components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from '@ui/atoms/Modal';

interface ConfirmationModalProps {
    isOpen: boolean;
    onOpenChange: (isOpen: boolean) => void;
    onAccept: () => void;
    isLoading: boolean;
}

const ConfirmationModal = ({
    isOpen,
    onOpenChange,
    onAccept,
    isLoading,
}: ConfirmationModalProps) => {
    const handleClose = () => onOpenChange(false);

    return (
        <ModalStyled isOpen={isOpen} onOpenChange={onOpenChange} size="small">
            <IconWrapper>
                <TrashIcon icon={icon({ name: 'heart-crack', style: 'solid' })} />
            </IconWrapper>
            <Stack $gap="0.25rem">
                <Text variant="headingS" fontWeight="--fw-bold" textAlign="center">
                    <FormattedMessage defaultMessage="Confirmez-vous la suppression de votre compte ?" />
                </Text>
                <Text color="--neutral500" textAlign="center">
                    <FormattedMessage defaultMessage="Cette action est irréversible, et toutes vos émissions, épisodes et données associées seront perdues." />
                </Text>
            </Stack>
            <Actions>
                <Button variant="danger" onPress={onAccept} isLoading={isLoading}>
                    <FormattedMessage defaultMessage="Supprimer le compte" />
                </Button>
                <Button variant="link-secondary" onPress={handleClose}>
                    <FormattedMessage defaultMessage="Annuler" />
                </Button>
            </Actions>
        </ModalStyled>
    );
};

const ModalStyled = styled(Modal)`
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1.5rem;
`;
const TrashIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
`;
const IconWrapper = styled.div`
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--alert50);
    color: var(--alert500);
    border-radius: var(--r-s);
`;
const Actions = styled.div`
    align-self: stretch;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    & > :last-child {
        align-self: center;
    }
`;

export default ConfirmationModal;
