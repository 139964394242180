import castbox from '@public/images/platforms/Castbox.png';
import { FormattedMessage } from 'react-intl';
import ShortListFallback from '@/components/unorganized/ShortListFallback';
import BroadcastCard from '../../BroadcastCard';
import Text from '@ui/atoms/Text';
import { LISTENING_PLATFORMS_NAMES, CASTBOX } from '@/shared/config/constants';
import { useParams } from 'react-router-dom';
import useShowQuery from '@queries/show/useShowQuery.hook';

const CastboxBroadcast = () => {
    const { showId } = useParams<{ showId: string }>();
    const { data: show, isLoading } = useShowQuery(showId);
    const url = show?.listeningLinks?.data.find((link) => link.key === CASTBOX)?.url;

    if (isLoading) return <ShortListFallback />;

    return (
        <BroadcastCard
            platformKey={CASTBOX}
            name={LISTENING_PLATFORMS_NAMES[CASTBOX]}
            logo={castbox}
            url={url}
            pendingMessage={
                <FormattedMessage
                    defaultMessage="Diffusez votre émission sur {platform} pour qu’elle soit en ligne sur cette plateforme."
                    values={{
                        platform: (
                            <Text as="span" fontWeight="--fw-bold" variant="footnote">
                                <FormattedMessage defaultMessage="Apple Podcasts" />
                            </Text>
                        ),
                    }}
                />
            }
        />
    );
};

export default CastboxBroadcast;
