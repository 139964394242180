import styled from 'styled-components';
import Text from '../../../ui/atoms/Text';
import { FormattedMessage, useIntl } from 'react-intl';
import Stack from '@/components/ui/layout/Stack';
import Button from '@/components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import noEpisodeIllustration from '@public/images/empty-state/episodes.svg?url';
import AddEpisodeToPlaylistModal from '../AddEpisodeToPlaylistModal';
import { useState } from 'react';

interface PlaylistEpisodesEmptyStateProps {
    playlistName: string;
}

const PlaylistEpisodesEmptyState = ({ playlistName }: PlaylistEpisodesEmptyStateProps) => {
    const intl = useIntl();
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            <Container>
                <IllustrationWrapper>
                    <Illustration
                        src={noEpisodeIllustration}
                        alt={intl.formatMessage({ defaultMessage: "Liste d'épisodes" })}
                    />
                </IllustrationWrapper>
                <Stack $gap="0.5rem" $align="center">
                    <Text variant="headingS" fontWeight="--fw-bold">
                        <FormattedMessage defaultMessage="Ajoutez le premier épisode à votre playlist" />
                    </Text>
                    <Text color="--neutral500">
                        <FormattedMessage
                            defaultMessage="Votre playlist {playlistName} est prête. Il ne vous reste plus qu'à y inclure vos épisodes. 🎧"
                            values={{
                                playlistName: <PlaylistName>{playlistName}</PlaylistName>,
                            }}
                        />
                    </Text>
                </Stack>
                <Button
                    onPress={() => setIsModalOpen(true)}
                    startIcon={<AddIcon icon={icon({ name: 'plus', style: 'solid' })} />}
                >
                    <FormattedMessage defaultMessage="Ajouter à la playlist" />
                </Button>
            </Container>
            <AddEpisodeToPlaylistModal
                isOpened={isModalOpen}
                onClose={() => setIsModalOpen(false)}
            />
        </>
    );
};

const Container = styled.div`
    display: flex;
    padding: 4rem 2rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 2rem;
    background-color: var(--white);
    border-radius: var(--r-l);
`;
const AddIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: var(--white);
`;
const IllustrationWrapper = styled.div`
    width: 12.5rem;
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const Illustration = styled.img`
    min-width: 248px;
`;
const PlaylistName = styled.span`
    font-weight: var(--fw-semibold);
`;

export default PlaylistEpisodesEmptyState;
