import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import RouterLink from '@/components/Link/RouterLink';

const Footer = () => {
    const intl = useIntl();
    return (
        <Wrapper>
            <Menu>
                <MenuItem>
                    <UserAccountLink to="/app/user/profile">
                        <FormattedMessage defaultMessage="Compte" />
                    </UserAccountLink>
                </MenuItem>
                <MenuItem>
                    <MenuLink
                        href={intl.formatMessage({
                            defaultMessage: 'https://www.ausha.co/fr/mentions-legales/',
                        })}
                        target="_BLANK"
                    >
                        <FormattedMessage defaultMessage="Mentions Légales" />
                    </MenuLink>
                </MenuItem>
                <MenuItem>
                    <MenuLink
                        href={intl.formatMessage({
                            defaultMessage: 'https://www.ausha.co/fr/politique-de-confidentialite/',
                        })}
                        target="_BLANK"
                    >
                        <FormattedMessage defaultMessage="Politique de confidentialité" />
                    </MenuLink>
                </MenuItem>
                <MenuItem>
                    <MenuLink
                        href={intl.formatMessage({
                            defaultMessage: 'https://www.ausha.co/fr/nos-cgu/',
                        })}
                        target="_BLANK"
                    >
                        <FormattedMessage defaultMessage="CGU" />
                    </MenuLink>
                </MenuItem>
                <MenuItem>
                    <MenuLink
                        href={intl.formatMessage({
                            defaultMessage: 'https://www.ausha.co/fr/nos-cga/',
                        })}
                        target="_BLANK"
                    >
                        <FormattedMessage defaultMessage="CGA" />
                    </MenuLink>
                </MenuItem>
                <MenuItem>
                    <MenuLink
                        href={intl.formatMessage({
                            defaultMessage: 'https://status.ausha.co/',
                        })}
                        target="_BLANK"
                    >
                        <FormattedMessage defaultMessage="Statut" />
                    </MenuLink>
                </MenuItem>
            </Menu>
        </Wrapper>
    );
};

const Wrapper = styled.footer``;
const Menu = styled.ul`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    list-style-type: none;
    margin: 0;
    padding: 0;

    @media (max-width: 768px) {
        flex-wrap: wrap;
    }
`;
const MenuItem = styled.li`
    flex-shrink: 0;
    color: var(--neutral500);
    @media (max-width: 768px) {
        font-size: var(--fs-body-xs);
    }
`;
const MenuLink = styled.a`
    text-decoration: none;
    font-weight: var(--fs-normal);
    font-size: var(--fs-body-s);
    color: inherit;
    &:hover {
        color: var(--neutral700);
    }
`;
const UserAccountLink = styled(RouterLink)`
    text-decoration: none;
    font-weight: var(--fx-normal);
    font-size: var(--fs-body-s);
    color: inherit;
    &:hover {
        color: var(--neutral700);
    }
    @media (max-width: 768px) {
        font-size: var(--fs-body-xs);
    }
`;

export default Footer;
