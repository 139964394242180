import { useFormContext } from 'react-hook-form';
import PublishOnYoutubeToggle from './PublishOnYoutubeToggle';

const EpisodeEditFormOptionsPublishOnYoutubeField = ({ isAllowed = false }) => {
    const { control } = useFormContext();

    if (!isAllowed) {
        return null;
    }

    return <PublishOnYoutubeToggle name="options.publishYoutube" control={control} />;
};

export default EpisodeEditFormOptionsPublishOnYoutubeField;
