import styled, { keyframes } from 'styled-components';

const blinkAnimation = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const Cursor = styled.span`
    animation: ${blinkAnimation} 0.5s ease-in-out alternate infinite;
    background: var(--neutral200);
    border-radius: var(--r-full);
    display: inline-block;
    height: 1rem;
    width: 0.0625rem;
`;

const waveAnimation = keyframes`
  0%, 50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10%);
  }
`;

const Dot = styled.path`
    animation: ${waveAnimation} 0.5s ease-in-out alternate infinite;
    fill: var(--primary500);

    &:nth-child(1) {
        animation-delay: 0s;
    }

    &:nth-child(2) {
        animation-delay: 0.1s;
    }

    &:nth-child(3) {
        animation-delay: 0.2s;
    }
`;

const DotsContainer = styled.svg`
    width: 0.75rem;
    height: 0.75rem;
`;

const LoadingContainer = styled.span`
    display: inline-block;
    padding: 0.25rem 0.5rem;
    border-radius: var(--r-xs);
    background: var(--primary50);
`;

const Container = styled.span`
    display: inline-flex;
    gap: 0.25rem;
    align-items: center;
    flex-wrap: nowrap;
    vertical-align: middle;
`;

const Loader = () => {
    return (
        <Container>
            <Cursor />
            <LoadingContainer>
                <DotsContainer viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
                    <Dot d="m0 256c0-30.721 25.279-56 56-56s56 25.279 56 56-25.279 56-56 56-56-25.279-56-56z" />
                    <Dot d="m160 256c0-30.721 25.279-56 56-56s56 25.279 56 56-25.279 56-56 56-56-25.279-56-56z" />
                    <Dot d="m376 200c30.721 0 56 25.279 56 56s-25.279 56-56 56-56-25.279-56-56 25.279-56 56-56z" />
                </DotsContainer>
            </LoadingContainer>
        </Container>
    );
};

export default Loader;
