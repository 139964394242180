import styled from 'styled-components';
import CopyButton from './CopyButton';

interface SnippetProps {
    children: React.ReactNode;
    valueToCopy: string;
    onCopy?: () => void;
}

const Snippet = ({ children, valueToCopy, onCopy }: SnippetProps) => {
    return (
        <Container>
            <Content>{children}</Content>
            <CopyButton valueToCopy={valueToCopy} onCopy={onCopy} />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    column-gap: 0.25rem;
    padding: 0.75rem;
    background-color: var(--neutral50);
    border-radius: var(--r-s);
    max-height: 16rem;
    overflow-y: auto;

    & > :last-child {
        flex-shrink: 0;
        align-self: start;
        position: sticky;
        top: 0;
    }
`;
const Content = styled.div`
    flex-grow: 1;
`;

export default Snippet;
