import { FormattedMessage, useIntl } from 'react-intl';
import Text from '@ui/atoms/Text';

const MessageProvider = ({ provider, isEpisode }) => {
    const messageType = isEpisode ? (
        <FormattedMessage defaultMessage="épisode" />
    ) : (
        <FormattedMessage defaultMessage="podcast" />
    );
    const intl = useIntl();

    const MESSAGE_PROVIDER = {
        facebook: (
            <FormattedMessage
                defaultMessage="Partagez votre {messageType} sur votre page Facebook."
                values={{ messageType }}
            />
        ),
        twitter: (
            <FormattedMessage
                defaultMessage="Partagez votre {messageType} sur votre feed X (Twitter)."
                values={{ messageType }}
            />
        ),
        linkedin: (
            <FormattedMessage
                defaultMessage="Partagez votre {messageType} sur votre compte LinkedIn."
                values={{ messageType }}
            />
        ),
        instagram: (
            <FormattedMessage
                defaultMessage="Partagez votre {messageType} sur votre {strong}. {lineBreak}{readMoreLink}"
                values={{
                    lineBreak: <br />,
                    strong: (
                        <Text as="span" fontWeight="--fw-bold">
                            <FormattedMessage defaultMessage="compte Instagram Business" />
                        </Text>
                    ),
                    readMoreLink: (
                        <Text
                            color="--instagram"
                            fontWeight="--fw-bold"
                            as="a"
                            href={intl.formatMessage({
                                defaultMessage:
                                    'https://help.ausha.co/fr/articles/5963614-comment-lier-mes-reseaux-sociaux-a-ausha',
                            })}
                            target="_blank"
                        >
                            <FormattedMessage defaultMessage="En savoir plus" />
                        </Text>
                    ),
                    messageType,
                }}
            />
        ),
    };
    return MESSAGE_PROVIDER[provider];
};

const MessageProviderLogin = ({ isEpisode, provider }) => (
    <Text style={{ marginTop: '0.5rem', maxWidth: 500 }}>
        <MessageProvider provider={provider} isEpisode={isEpisode} />
    </Text>
);

export default MessageProviderLogin;
