import {
    ONE_CLICK_BROADCAST_PLATFORMS,
    APPLE_PODCASTS,
    ListeningPlatform,
} from '@/utils/constants';
import Connected from './Connected';
import ConnectionRequested from './ConnectionRequested';
import Disconnected from './Disconnected';

interface BroadcastCardProps {
    platformKey: ListeningPlatform;
    name: string;
    logo: string;
    url?: string;
    pendingMessage?: React.ReactNode;
    tooltip?: React.ReactNode;
    isDisabled?: boolean;
    onSuccessSoundcloud?: () => void;
    onSuccessYoutube?: () => void;
}

const BroadcastCard = ({
    platformKey,
    name,
    logo,
    url,
    pendingMessage,
    tooltip,
    isDisabled,
}: BroadcastCardProps) => {
    const REQUEST_SENT_PLATFORMS = [...ONE_CLICK_BROADCAST_PLATFORMS, APPLE_PODCASTS];
    /**
     * Pending request means we get the platform back from the API with an URL set to "null".
     */
    const hasSentRequest = REQUEST_SENT_PLATFORMS.includes(platformKey as any) && url === null;

    /**
     * If the platform has a URL, it means it's connected.
     * We'll display a card with a input field to copy the URL.
     */
    if (url) {
        return <Connected platformKey={platformKey} name={name} logo={logo} url={url} />;
    }

    /**
     * If the platform has a pending request, we'll display a card with a pending message.
     */
    if (hasSentRequest) {
        return <ConnectionRequested platformKey={platformKey} name={name} logo={logo} />;
    }

    /**
     * Otherwise, the platform is disconnected.
     * We'll display a card with a button to connect.
     */
    return (
        <Disconnected
            platformKey={platformKey}
            logo={logo}
            name={name}
            pendingMessage={pendingMessage}
            tooltip={tooltip}
            isDisabled={isDisabled}
        />
    );
};

export default BroadcastCard;
