import usePlayerSettingsQuery from '@/queries/playerSettings/usePlayerSettingsQuery.hook';
import { useParams } from 'react-router';
import ShortListFallback from '../../fallbacks/ShortListFallback';
import useListenableEpisodesQuery from '@/queries/episode/useListenableEpisodesQuery.hook';
import PlayerListenableEpisodesEmptyState from './PlayerListenableEpisodesEmptyState';
import PlayerForm from '../../molecules/PlayerForm';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import { USER_ROLES } from '@/utils/constants';
import useSavePlayerSettingsFormMutation from './useSavePlayerSettingsMutation.hook';
import useCallToActionButtonsQuery, {
    CtaType,
} from '@/queries/callToAction/useCallToActionButtonsQuery.hook';
import { PlayerFormSchema } from '../../molecules/PlayerForm/usePlayerFormSchema.hook';
import { sendAmplitudeLogEvent } from '@/helpers';
import { useBodyToastQueue } from '@/utils/hooks/useBodyToastQueue.hook';
import { LINKS_NAMES } from '@/components/CallToAction';

const Player = () => {
    const { showId } = useParams<{ showId: string }>();
    const show = useShowQuery(showId);
    const settings = usePlayerSettingsQuery(showId);
    const listenableEpisodes = useListenableEpisodesQuery(showId);
    const isGranted =
        show.data?.userRole === USER_ROLES.OWNER || show.data?.userRole === USER_ROLES.ADMIN;
    const callToActionButtons = useCallToActionButtonsQuery({
        showId,
        type: LINKS_NAMES.PLAYER as CtaType,
    });
    const savePlayerSettings = useSavePlayerSettingsFormMutation();
    const toast = useBodyToastQueue();

    const onSubmit = (formData: PlayerFormSchema) => {
        const { type, ...settings } = formData;
        savePlayerSettings.mutate(
            {
                showId,
                settings,
            },
            {
                onSuccess: () => {
                    toast.success();
                },
            },
        );
        sendAmplitudeLogEvent('Smartplayer Updated');
    };

    if (settings.isLoading || callToActionButtons.isLoading || listenableEpisodes.isLoading)
        return <ShortListFallback />;

    if (listenableEpisodes.data?.length === 0) return <PlayerListenableEpisodesEmptyState />;

    return (
        <PlayerForm
            onSubmit={onSubmit}
            isLoading={savePlayerSettings.isLoading}
            isGranted={isGranted}
            settings={settings.data}
            callToActionButtons={callToActionButtons?.data || []}
        />
    );
};

export default Player;
