import styled from 'styled-components';
import PsoScoreSummary from './PsoScoreSummary';
import TranscriptionSummary from './TranscriptionSummary';
import Stack from '@/components/ui/layout/Stack';
import SummaryAccordion from './SummaryAccordion';

const NonHostingEpisodeSummary = () => {
    return (
        <ScrollableWrapper>
            <Stack $gap="0.5rem">
                <PsoScoreSummary />
                <TranscriptionSummary />
            </Stack>
            <SummaryAccordion />
        </ScrollableWrapper>
    );
};

const ScrollableWrapper = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: auto;
`;

export default NonHostingEpisodeSummary;
