import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteImportedEpisode } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import rssKeys from './rssKeys';

export interface MutationFnProps {
    showId: string;
    episodeId: string;
}

const mutationFn = async ({ episodeId }: MutationFnProps) => {
    const { data } = await deleteImportedEpisode(episodeId);
    return camelcaseKeys(data, { deep: true });
};

const useDeleteImportedEpisodeMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({
                queryKey: rssKeys.episodes(variables.showId, 'error'),
            });
        },
    });
};

export default useDeleteImportedEpisodeMutation;
