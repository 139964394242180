import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchSeasons } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import seasonKeys from '@/queries/season/seasonKeys';
import { z } from 'zod';

export const SeasonSchema = z.object({
    id: z.number().nullable().optional(),
    podcastsCount: z.number().nullable().optional(),
    seasonNumber: z.number().nullable().optional(),
    showId: z.number().nullable().optional(),
});
export type Season = z.infer<typeof SeasonSchema>;
type Key = QueryFunctionContext<ReturnType<typeof seasonKeys.detailById>>;
type InputProps = {
    showId: string;
    seasonId?: string;
};

const queryFn = async ({ queryKey: [{ showId, seasonId }] }: Key) => {
    const { data } = await fetchSeasons(showId);
    const season = {
        ...data.find((s: any) => s.id === seasonId),
        episodesCount: data.podcasts_count,
    };

    return SeasonSchema.parse(camelcaseKeys(season, { deep: true }));
};

const useSeasonQuery = ({ showId, seasonId }: InputProps) => {
    return useQuery({
        queryKey: seasonKeys.detailById(showId, seasonId),
        queryFn,
        enabled: !!showId && !!seasonId,
    });
};

export default useSeasonQuery;
