import { useState, useCallback } from 'react';
import { useResponsive } from './useResponsive';

export default function useGetBoundingClientRect() {
    const [boundingClientRect, setBoundingClientRect] = useState(0);
    const { width } = useResponsive();
    const refBoundingClientRect = useCallback(
        (node) => {
            if (node !== null) setBoundingClientRect(node.getBoundingClientRect());
        },
        [width],
    );
    return [boundingClientRect, refBoundingClientRect];
}
