import { useState, useCallback, useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import useGetBoundingClientRect from '@/shared/hooks/useGetBoundingClientRect';

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const DragAndDrop = ({ children, id, list, getData, className }) => {
    if (!list) return null;
    if (list.length === 1) return children({ item: list[0] });

    const [dragItems, setDragItems] = useState(list);
    const [_, refDragAndDrop] = useGetBoundingClientRect();

    const onDragEnd = useCallback(
        (result) => {
            if (!result.destination) return;
            const items = reorder(dragItems, result.source.index, result.destination.index);
            setDragItems(items);
            getData({ hasChanged: true, newList: items });
        },
        [dragItems],
    );

    useEffect(() => setDragItems(list), [list]);

    // Reset current Data
    useEffect(() => () => getData({ hasChanged: false, newList: [] }), []);

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={id}>
                {(provided) => (
                    <DeprecatedPaper ref={refDragAndDrop}>
                        <DeprecatedPaper
                            my={8}
                            gap={8}
                            flex
                            column
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className={className}
                        >
                            {dragItems.map((item, i) => (
                                <Draggable key={item.id} draggableId={item.id.toString()} index={i}>
                                    {/* eslint-disable-next-line no-shadow */}
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            {children({ item, isDragging: snapshot.isDragging })}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </DeprecatedPaper>
                    </DeprecatedPaper>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default DragAndDrop;
