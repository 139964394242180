import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { PRICING, PRICING_NAME } from '@/shared/config/pricing';
import RocketIllustration from '@public/images/rocket.webp';
import GroundIllustration from '@public/images/ground.webp';
import RouterLink from '@/components/Link/RouterLink';
import Text from '@ui/atoms/Text';
import styled from 'styled-components';

const FreemiumPlan = () => {
    return (
        <FreemiumPlanWrapper>
            <MainContent>
                <BoxTitleWrapper>
                    <RocketIcon icon={icon({ name: 'rocket-launch', style: 'solid' })} />
                    <BoxTitle>
                        <FormattedMessage defaultMessage="Offre" />
                    </BoxTitle>
                </BoxTitleWrapper>
                <PlanTextWrapper>
                    <PlanName>{PRICING_NAME[PRICING.FREEMIUM] || ''}</PlanName>
                    <PlanPrice>
                        <FormattedMessage defaultMessage="Gratuit" />
                    </PlanPrice>
                </PlanTextWrapper>
                <ActionWrapper>
                    <RouterLink
                        to="/app/subscription/offers"
                        variant="button-primary"
                        data-testid="subscription-page-subscribe-button"
                    >
                        <FormattedMessage defaultMessage="S'abonner" />
                    </RouterLink>
                </ActionWrapper>
            </MainContent>
            <IllustrationWrapper>
                <Rocket />
                <IllustrationTextWrapper>
                    <IllustrationTitle>
                        <FormattedMessage defaultMessage="Passez en illimité !" />
                    </IllustrationTitle>
                    <IllustrationSubTitle>
                        <FormattedMessage defaultMessage="Diffusez, communiquez, analysez et monétisez votre podcast dans une seule plateforme." />
                    </IllustrationSubTitle>
                </IllustrationTextWrapper>
                <Ground />
            </IllustrationWrapper>
        </FreemiumPlanWrapper>
    );
};

const FreemiumPlanWrapper = styled.div`
    display: flex;
    background-color: var(--white);
    border-radius: var(--r-l);
    min-height: 15rem;
    padding: 1rem;
`;
const MainContent = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.5rem;
    padding: 1rem;
`;
const BoxTitleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;
const RocketIcon = styled(FontAwesomeIcon)`
    font-size: 1rem;
    color: var(--primary);
`;
const BoxTitle = styled.div`
    font-weight: var(--fw-semibold);
`;
const PlanTextWrapper = styled.div``;
const PlanName = styled.div`
    font-size: var(--fs-heading-xl);
    font-weight: var(--fw-bold);
`;
const PlanPrice = styled.div`
    color: var(--neutral500);
    font-size: var(--fs-heading-s);
`;
const ActionWrapper = styled.div``;
const IllustrationWrapper = styled.div`
    display: none;

    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 30rem;
    height: 20rem;
    position: relative;
    background: var(--gradient-dawn);
    border-radius: var(--r-m);
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: flex;
    }
`;
const Rocket = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-image: url(${RocketIllustration});
    background-size: 25%;
    background-repeat: no-repeat;
    background-position: top -10rem center;
    border-top-right-radius: var(--r-l);
`;
const Ground = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 4rem;
    background-image: url(${GroundIllustration});
    background-size: 120%;
    background-repeat: no-repeat;
    background-position: bottom;
`;
const IllustrationTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    margin-top: 5rem;
    gap: 0.25rem;
`;
const IllustrationTitle = styled(Text)`
    font-size: var(--fs-heading-m);
    font-weight: var(--fw-bold);
`;
const IllustrationSubTitle = styled(Text)`
    text-align: center;
`;

export default FreemiumPlan;
