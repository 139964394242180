import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchChapters } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import chapterKeys from '@/queries/chapter/chapterKeys';
import { z } from 'zod';

export const ChapterSchema = z.object({
    id: z.number(),
    imageUrl: z.string().nullable().optional(),
    startTime: z.number().nullable().optional(),
    title: z.string().nullable().optional(),
    url: z.string().nullable().optional(),
    locations: z.array(z.string()).optional(),
    showId: z.number().nullable().optional(),
    text: z.string().nullable().optional(),
    type: z.string().nullable().optional(),
});
const ChaptersSchema = z.array(ChapterSchema);

type InputProps = {
    episodeId: string;
    config?: {
        enabled: boolean;
    };
};
type Key = QueryFunctionContext<ReturnType<typeof chapterKeys.listByEpisodeId>>;
export type Chapter = z.infer<typeof ChapterSchema>;

const queryFn = async ({ queryKey: [{ episodeId }] }: Key) => {
    const { data } = await fetchChapters(episodeId);
    // TODO: Do camelcase transformation in Axios interceptor
    return ChaptersSchema.parse(camelcaseKeys(data, { deep: true }));
};

const useChaptersQuery = ({ episodeId, config }: InputProps) => {
    return useQuery({
        queryKey: chapterKeys.listByEpisodeId(episodeId),
        queryFn,
        enabled: !!episodeId,
        ...config,
    });
};

export default useChaptersQuery;
