import tunein from '@public/images/platforms/TuneIn.png';
import ShortListFallback from '@/components/unorganized/ShortListFallback';
import BroadcastCard from '../../BroadcastCard';
import { LISTENING_PLATFORMS_NAMES, TUNEIN } from '@/shared/config/constants';
import { useParams } from 'react-router-dom';
import useShowQuery from '@queries/show/useShowQuery.hook';

const TuneinBroadcast = () => {
    const { showId } = useParams<{ showId: string }>();
    const { data: show, isLoading } = useShowQuery(showId);
    const url = show?.listeningLinks?.data.find((link) => link.key === TUNEIN)?.url;

    if (isLoading) return <ShortListFallback />;

    return (
        <BroadcastCard
            platformKey={TUNEIN}
            name={LISTENING_PLATFORMS_NAMES[TUNEIN]}
            logo={tunein}
            url={url}
        />
    );
};

export default TuneinBroadcast;
