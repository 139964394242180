import { useEffect } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { connect } from '../decorators/connect';

function AuthorizationRoute({
    onMount,
    ignore = false,
    isBlocked,
    redirect,
    defaultRedirect,
    extend: Extend = Route,
    ...props
}) {
    const location = useLocation();

    useEffect(() => {
        if (onMount) onMount();
    }, []);

    if (isBlocked && !ignore) {
        return (
            <Redirect
                to={{
                    pathname: redirect || defaultRedirect,
                    state: { referrer: location.pathname },
                }}
            />
        );
    }
    return <Extend {...props} />;
}

export const createAuthorizer = (predicate) => connect(predicate)(AuthorizationRoute);
