import { useState, useEffect } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import BarGraph, { STATS_NAME } from '@ui/molecules/BarGraph';
import { download } from '@/helpers';
import { useStatsDateSelectorContext } from '@/context/StatsDateSelectorContext';
import { useParams } from 'react-router';
import useAgesQuery from '@queries/stats/useAgesQuery.hook';
import useAgesExportQuery from '@queries/stats/useAgesExportQuery.hook';
import styled from 'styled-components';
import StatsDataSetSelector from '@app/organisms/StatsDataSetSelector';
import ShortListFallback from '@app/fallbacks/ShortListFallback';
import makeRenderStatsData from '@app/decorators/makeRenderStatsData';
import AgesTable from '@app/pages/StatsAudiencePage/AgesGraph/AgesTable';
import AgesExport from '@app/pages/StatsAudiencePage/AgesGraph/AgesExport';

const axisNames = defineMessages({
    'age.lessThan18YearsOld': { defaultMessage: 'Moins de 18 ans' },
    'age.between18And25YearsOld': { defaultMessage: '18-25 ans' },
    'age.between26And35YearsOld': { defaultMessage: '26-35 ans' },
    'age.between36And45YearsOld': { defaultMessage: '36-45 ans' },
    'age.between46And55YearsOld': { defaultMessage: '46-55 ans' },
    'age.moreThan55YearsOld': { defaultMessage: 'Plus de 55 ans' },
    'stats.other': { defaultMessage: 'Autres' },
});

interface BarAgeGraphRenderDataProps {
    axis: string[];
    agesPercentage: number[];
}

const BarAgeGraphRenderData = makeRenderStatsData(
    ({ axis, agesPercentage }: BarAgeGraphRenderDataProps) => (
        <GraphWrapper>
            <BarGraph options={axis} series={agesPercentage} statName={STATS_NAME.AGES} />
        </GraphWrapper>
    ),
);

const AgesGraph = () => {
    const intl = useIntl();
    const { showId } = useParams<{ showId: string }>();
    const { startDate, endDate } = useStatsDateSelectorContext();
    const { data: ages, isLoading } = useAgesQuery({
        showId,
        query: { start: startDate, end: endDate },
    });
    const [agesPercentage, setAgesPercentage] = useState<number[]>([]);
    const axis = Object.keys(axisNames).map((name) => intl.formatMessage(axisNames[name]));

    const { data: exportedData } = useAgesExportQuery({
        showId,
        query: { start: startDate, end: endDate },
    });

    useEffect(() => {
        if (!ages) return;
        setAgesPercentage(ages?.map((age) => age.percentage));
    }, [ages]);

    return (
        <>
            <StatsDataSetSelector
                tabTitle={<FormattedMessage defaultMessage="Âge" />}
                subTitle={
                    <FormattedMessage defaultMessage="Quel est l'âge de votre audience (Deezer uniquement) ?" />
                }
                tabId="audience"
                noSelector
            />
            {isLoading ? (
                <ShortListFallback />
            ) : (
                <BarAgeGraphRenderData
                    axis={axis}
                    agesPercentage={agesPercentage}
                    makeRenderStatsDataHasData={ages}
                />
            )}
            <AgesTable ages={axis} values={agesPercentage} />
            <AgesExport
                exportCsv={() => download(exportedData?.ages, exportedData?.filename, 'txt')}
            />
        </>
    );
};

const GraphWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-xs);
    padding: 2rem;
    margin-top: 1.5rem;
    margin-bottom: 2rem;
`;

export default AgesGraph;
