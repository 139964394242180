import { useMutation } from '@tanstack/react-query';
import { uploadMessageImage } from '@/api';
import camelcaseKeys from 'camelcase-keys';

export interface UpdateNewsletterImageMutationFnProps {
    messageId: number;
    file: File;
}

const mutationFn = async ({
    messageId,
    file,
}: UpdateNewsletterImageMutationFnProps): Promise<any> => {
    const { data } = await uploadMessageImage(messageId, file);

    return camelcaseKeys(data as any, { deep: true });
};

const updateNewsletterImageMutation = () => {
    return useMutation({
        mutationFn,
    });
};

export default updateNewsletterImageMutation;
