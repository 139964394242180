import { useState } from 'react';
import Text from '@ui/atoms/Text';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Cluster from '@ui/layout/Cluster';
import Img from '@ui/atoms/Img';
import { IMG_PLACEHOLDER, IMG_TYPES } from '@/shared/config/constants';
import { useEffect } from 'react';
import { FileTrigger } from 'react-aria-components';
import Button from '@/components/Button';
import Label from '../../atoms/Label';
import { FORM_VALIDATION_MESSAGE } from '@/shared/services/validationMessageService';
import { checkImgFormat, checkImgSize, experimentalCheckImgDimensions } from '@/shared/utils/file';
import useUserQuery from '@/queries/user/useUserQuery.hook';

interface InputImgUploaderProps {
    label: React.ReactNode;
    description?: React.ReactNode | string;
    objectFit?: string;
    tooltip?: React.ReactNode;
    value: File | string | null;
    errorMessage?: string;
    onChange?: (file: File | string | null) => void;
    onDeleteFile?: () => void;
    className?: string;
    accept?: string[];
    dimensions?: { minWidth: number; minHeight: number };
}

const InputImgUploader = ({
    label,
    description,
    objectFit = 'cover',
    tooltip,
    value,
    errorMessage,
    onChange,
    onDeleteFile,
    className,
    accept = [...IMG_TYPES],
    dimensions = { minWidth: 400, minHeight: 400 },
}: InputImgUploaderProps) => {
    const intl = useIntl();
    const { data: user } = useUserQuery();
    const locale = user?.language?.toLowerCase();
    const [src, setSrc] = useState(value || null);
    const [error, setError] = useState<string | React.ReactNode | null>(null);
    const hasErrors = !!errorMessage || !!error;
    const hasSrc = !!src;

    const handleChange = async (file: File | string | null) => {
        if (!file) {
            setSrc(null);
            return;
        }
        if (typeof file === 'string') {
            setSrc(file);
            return;
        }
        const isValid = await handleValidation(file);
        if (!isValid) {
            return;
        }
        const fileURL = URL.createObjectURL(file);
        setSrc(fileURL);
    };

    const handleSelect = async (e: FileList | null) => {
        if (!e) {
            setSrc(null);
            return;
        }

        let files = Array.from(e);
        const file = files?.[0];
        const isValid = await handleValidation(file);

        if (!isValid) {
            return;
        }
        onChange?.(file);
    };

    const handleDeleteFile = () => {
        onChange && onChange(null);
        setSrc(null);
        onDeleteFile?.();
    };

    const handleValidation = async (file: File) => {
        const imageHasGoodFormat = checkImgFormat(file);

        if (!imageHasGoodFormat) {
            setError(
                intl.formatMessage(FORM_VALIDATION_MESSAGE.acceptedMimes, {
                    acceptedMimes: accept,
                }),
            );
            return false;
        }

        const imageHasGoodSize = checkImgSize(file, locale, 10);

        if (!imageHasGoodSize) {
            setError(intl.formatMessage(FORM_VALIDATION_MESSAGE.imgMaxSize, { max: 10 }));
            return false;
        }

        const imageHasGoodDimensions = await experimentalCheckImgDimensions(file, dimensions);

        if (!imageHasGoodDimensions) {
            setError(intl.formatMessage(FORM_VALIDATION_MESSAGE.imgMinDimensions, dimensions));
            return false;
        }
        setError(null);
        return true;
    };

    useEffect(() => {
        handleChange(value);
    }, [value]);

    return (
        <Wrapper className={className}>
            <Cluster $gap="1rem">
                <ImgPreview src={src || IMG_PLACEHOLDER} objectFit={objectFit} />
                <UploaderWrapper>
                    <Label tooltip={tooltip} description={description}>
                        {label}
                    </Label>
                    <ButtonsWrapper>
                        <FileTrigger acceptedFileTypes={accept} onSelect={handleSelect}>
                            <Button
                                size="small"
                                startIcon={
                                    hasSrc ? (
                                        <EditIcon
                                            icon={icon({
                                                name: 'pen-line',
                                                style: 'solid',
                                            })}
                                        />
                                    ) : (
                                        <EditIcon
                                            icon={icon({
                                                name: 'arrow-up-from-line',
                                                style: 'solid',
                                            })}
                                        />
                                    )
                                }
                                variant="tertiary"
                            >
                                {hasSrc ? (
                                    <FormattedMessage defaultMessage="Modifier" />
                                ) : (
                                    <FormattedMessage defaultMessage="Ajouter" />
                                )}
                            </Button>
                        </FileTrigger>
                        {hasSrc && (
                            <DeleteButton
                                onPress={handleDeleteFile}
                                variant="ghost"
                                size="small"
                                startIcon={
                                    <TrashIcon
                                        icon={icon({
                                            name: 'trash',
                                            style: 'solid',
                                        })}
                                    />
                                }
                            >
                                <FormattedMessage defaultMessage="Supprimer" />
                            </DeleteButton>
                        )}
                    </ButtonsWrapper>
                </UploaderWrapper>
            </Cluster>
            {hasErrors && <Text color="--alert500">{error || errorMessage}</Text>}
        </Wrapper>
    );
};

const ImgPreview = styled(Img)`
    border-radius: 0.75rem;
    width: 6rem;
    height: 6rem;

    ${({ theme }) => theme.mediaQueries.tabletAndUp} {
        width: 10rem;
        height: 10rem;
    }
`;
const UploaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    flex: 1;
`;
const DeleteButton = styled(Button)`
    color: var(--alert500);
    padding: 0;
`;
const ButtonIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const TrashIcon = styled(ButtonIcon)`
    color: var(--alert500);
`;
const EditIcon = styled(ButtonIcon)`
    color: var(--black);
`;
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
`;
const ButtonsWrapper = styled.div`
    display: flex;
    column-gap: 0.5rem;

    ${({ theme }) => theme.mediaQueries.tabletAndUp} {
        column-gap: 1rem;
    }
`;

export default InputImgUploader;
