import { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import posed from 'react-pose';
import { reflex } from '@ui/reflex';
import arrowDown from '@public/icons/chevrondown.svg';
import checkRounded from '@public/icons/checkRounded.svg';
import lock from '@public/icons/locked.svg';
import DeprecatedText from '../../atoms/DeprecatedText';
import DeprecatedIcon from '../../atoms/DeprecatedIcon';
import { FormattedMessage } from 'react-intl';

const Wrapper = styled.div`
    width: 100%;
    background: var(--white);
    border-radius: var(--r-xs);
    position: relative;
    transition: all 250ms;

    z-index: ${(p) => (p.active ? 2 : 1)};
`;

const headerStyled = {
    active: css`
        background: var(--gradient-primary);
    `,
    normal: css`
        background: var(--white);
    `,
};

const Header = styled.header`
    position: relative;
    z-index: 0;
    display: flex;
    cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
    align-items: center;
    justify-content: space-between;
    min-height: 3.75rem;
    padding: 1rem 2rem;
    border-radius: var(--r-xs) var(--r-xs) 0 0;
    ${(p) => (p.active ? headerStyled.active : headerStyled.normal)};
    > header {
        border-top-left-radius: var(--r-xs);
        border-top-right-radius: var(--r-xs);
    }
    button {
        height: 35px;
        min-width: 0;
        padding-left: 15px;
        padding-right: 15px;
        z-index: 1;
    }
`;

const NumberSpan = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: var(--fw-bold);
    color: ${(p) => (p.active ? 'var(--white)' : 'var(--black)')};
    width: 30px;
    height: 30px;
    border: 1px solid ${(p) => (p.active ? 'var(--white)' : 'var(--black)')};
    border-radius: var(--r-full);
`;

const CollapseTitleWrapper = styled.div`
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    row-gap: 0.5rem;
`;

const CollapseTitleInnerWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

const CollapseTitleLeft = styled.div`
    display: flex;
    align-items: center;
`;

const transition = (p) => ({ ease: 'linear', duration: p.h / 2 });
const enterStyle = { opacity: 1, transition };

const Content = styled(
    posed.section({
        open: { height: (p) => p.h, ...enterStyle },
        auto: { height: 'auto', ...enterStyle },
        closed: { height: 0, opacity: 0, transition },
    }),
)`
    position: relative;
    ${(p) =>
        !p.active &&
        css`
            overflow: hidden;
        `};
    background: var(--white);
    border: 2px solid var(--primary);
    border-top: 0;
    border-bottom-left-radius: var(--r-xs);
    border-bottom-right-radius: var(--r-xs);
    padding: ${(p) => p.p}px;
    height: auto;
    > div {
        position: relative;
        z-index: 0;
    }
`;

function Collapse({
    number,
    title,
    icon,
    checked,
    active,
    button,
    children,
    onClick,
    disabled,
    disabledContent,
    activeButton,
    allow = true,
    padding = 16,
    ...props
}) {
    const ref = useRef();
    const [height, setHeight] = useState('auto');
    const [complete, setComplete] = useState(false);
    useEffect(() => setHeight(ref.current?.clientHeight), []);
    useEffect(() => {
        if (active) setComplete(false);
    }, [active, disabled, allow]);

    const buttonHeader =
        active && activeButton ? (
            button
        ) : (
            <DeprecatedIcon as={arrowDown} size="20px" color="--neutral500" />
        );

    return (
        <Wrapper active={active} {...props}>
            <Header
                onClick={!active && !disabled ? onClick : undefined}
                active={active}
                disabled={disabled}
            >
                <CollapseTitleWrapper>
                    <CollapseTitleInnerWrapper>
                        <CollapseTitleLeft>
                            {number &&
                                (checked ? (
                                    <DeprecatedIcon
                                        as={checkRounded}
                                        size="30px"
                                        color="--success"
                                    />
                                ) : (
                                    <NumberSpan active={active}>{number}</NumberSpan>
                                ))}
                            <DeprecatedText
                                as="b"
                                ml={10}
                                color={active ? 'white' : 'black'}
                                weight="semibold"
                                size="big"
                            >
                                {title}
                            </DeprecatedText>
                            {icon && (
                                <DeprecatedIcon
                                    as={icon}
                                    size={24}
                                    ml={5}
                                    color={active ? 'white' : 'black'}
                                />
                            )}
                            {!allow && (
                                <DeprecatedIcon
                                    as={lock}
                                    size={24}
                                    ml={5}
                                    color={active ? 'white' : 'black'}
                                />
                            )}
                        </CollapseTitleLeft>
                        {disabled && disabledContent ? (
                            <DeprecatedText color="--neutral500">
                                <FormattedMessage defaultMessage="Désactivée" />
                            </DeprecatedText>
                        ) : (
                            buttonHeader
                        )}
                    </CollapseTitleInnerWrapper>
                    {disabled && disabledContent && disabledContent}
                </CollapseTitleWrapper>
            </Header>

            <Content
                h={height + 60}
                active={active}
                allow={allow}
                p={active && allow ? padding : 0}
                onPoseComplete={() => setComplete(true)}
                pose={(complete && active && 'auto') || (active && 'open') || 'closed'}
            >
                <div ref={ref}>{children}</div>
            </Content>
        </Wrapper>
    );
}

Collapse.propTypes = {
    number: PropTypes.number,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    checked: PropTypes.bool,
    button: PropTypes.element,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
    icon: PropTypes.node,
    active: PropTypes.bool,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    disabledContent: PropTypes.node,
    activeButton: PropTypes.bool,
    allow: PropTypes.bool,
    padding: PropTypes.number,
};

Collapse.defaultProps = {
    number: undefined,
    active: false,
    checked: false,
    button: undefined,
    title: undefined,
};

export default reflex(Collapse);
