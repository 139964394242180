import { AnimatePresence } from 'framer-motion';
import { Switch, useLocation } from 'react-router-dom';

const AnimatedRoutes = ({ exitBeforeEnter = true, initial = false, children }) => {
    const { key } = useLocation();
    return (
        <AnimatePresence exitBeforeEnter={exitBeforeEnter} initial={initial}>
            <Switch key={key}>{children}</Switch>
        </AnimatePresence>
    );
};

export default AnimatedRoutes;
