import {
    APPLE_PODCASTS,
    GAANA,
    I_HEART_RADIO,
    JIO_SAAVN,
    PANDORA,
    PLAYER_FM,
    TUNEIN,
    type ListeningPlatform,
} from '@/utils/constants';
import useUserQuery from '@/queries/user/useUserQuery.hook';

type TutorialSupportedLanguage = 'en' | 'fr';

const tutorialUrlLookup: Record<string, Record<TutorialSupportedLanguage, string>> = {
    [APPLE_PODCASTS]: {
        fr: 'sFORxRta',
        en: 'Ox5teLd5',
    },
    [TUNEIN]: {
        fr: 'i1jBlP',
        en: 'm0iQEZmz',
    },
    [I_HEART_RADIO]: {
        fr: 'fJd8otWL',
        en: 'fJd8otWL',
    },
    [PANDORA]: {
        fr: 'yFob55p9',
        en: 'yFob55p9',
    },
    [PLAYER_FM]: {
        fr: 'auSlL6dZ',
        en: 'auSlL6dZ',
    },
    [JIO_SAAVN]: {
        fr: 'pXebzRrn',
        en: 'pXebzRrn',
    },
    [GAANA]: {
        fr: 'v6ZQiE9U',
        en: 'v6ZQiE9U',
    },
};

interface TutorialProps {
    platformKey: ListeningPlatform;
}

const useTutorial = ({ platformKey }: TutorialProps) => {
    const { data: user } = useUserQuery();

    if (!tutorialUrlLookup[platformKey]) return;

    const supportedUserLanguage: TutorialSupportedLanguage = ['en', 'fr'].includes(
        user?.language as string,
    )
        ? (user?.language as TutorialSupportedLanguage)
        : 'en';

    const typeformId = tutorialUrlLookup[platformKey][supportedUserLanguage || 'en'];

    return { typeformId };
};

export default useTutorial;
