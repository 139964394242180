import Text from '@/components/ui/atoms/Text';
import Checkbox from '@/components/ui/Checkbox';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import { IMG_PLACEHOLDER } from '@/shared/config/constants';
import { useParams } from 'react-router';
import styled from 'styled-components';

interface Episode {
    id: number;
    name: string;
    imageUrl?: string;
}

interface EpisodesListProps {
    episodes: Episode[];
    onSelect: (id: number) => void;
    selectedEpisodes: number[];
}

const EpisodesList = ({ episodes, selectedEpisodes, onSelect }: EpisodesListProps) => {
    const { showId } = useParams<{ showId: string }>();
    const show = useShowQuery(showId);

    if (episodes.length > 0) {
        return (
            <List>
                {episodes.map((episode) => (
                    <Episode
                        onClick={() => onSelect(episode.id)}
                        key={episode.id}
                        $isSelected={selectedEpisodes.includes(episode.id)}
                    >
                        <Checkbox isSelected={selectedEpisodes.includes(episode.id)} />
                        <Image
                            src={episode.imageUrl ?? show.data?.imageUrl ?? IMG_PLACEHOLDER}
                            alt={episode.name}
                        />
                        <Text fontWeight="--fw-semibold">{episode.name}</Text>
                    </Episode>
                ))}
            </List>
        );
    }

    return null;
};

const List = styled.ul`
    list-style-type: none;
    padding: 0;
    width: 100%;
    max-height: 500px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;
`;
const Episode = styled.li<{ $isSelected: boolean }>`
    transition-duration: 0.2s;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    border-radius: var(--r-m);
    background-color: var(--white);
    padding-block: 0.75rem;
    padding-inline: 1rem;
    min-width: 0;

    &:not(:last-child) {
        border-bottom: 1px solid var(--neutral100);
    }

    &:hover {
        z-index: 2;
        box-shadow: var(--s-whatever);
    }

    &:not(:last-child):hover {
        border-bottom: 1px solid transparent;
    }

    ${({ $isSelected }) => $isSelected && `background-color: var(--primary50);`}
`;
const Image = styled.img`
    width: 2.5rem;
    height: 2.5rem;
    border-radius: var(--r-s);
`;

export default EpisodesList;
