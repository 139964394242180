import { useQuery } from '@tanstack/react-query';
import { fetchLanguages } from '@/api';
import languageKeys from '@/queries/language/languageKeys';
import camelcaseKeys from 'camelcase-keys';
import { z } from 'zod';

const LanguageSchema = z.object({
    code: z.string(),
    id: z.number(),
    isFeatured: z.boolean(),
    name: z.string(),
    nativeName: z.string(),
});
const LanguagesSchema = z.array(LanguageSchema);

export type Language = z.infer<typeof LanguageSchema>;

const queryFn = async () => {
    const { data } = await fetchLanguages();

    return LanguagesSchema.parse(camelcaseKeys(data, { deep: true }));
};

const useLanguagesQuery = () =>
    useQuery({
        queryKey: languageKeys.list(),
        queryFn,
        staleTime: Infinity,
    });

export default useLanguagesQuery;
