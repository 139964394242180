import { action } from 'mobx';
import UserStore from './UserStore';
import AuthStore from './AuthStore';
import SubscriptionStore from './SubscriptionStore';
import PodcastStore from './PodcastStore';
import CampaignStore from './CampaignStore';
import ClipStore from './ClipStore';
import AutoSharingStore from './AutoSharingStore';
import ListenersStatsStore from './statistics/ListenersStatsStore';
import SourcesStatsStore from './statistics/SoucesStatsStore';
import ApplePodcastsStatsStore from './statistics/ApplePodcastsStatsStore';
import ClipFormatStore from './ClipFormatStore';

class RootStore {
    authStore = new AuthStore(this);
    userStore = new UserStore(this);
    podcastStore = new PodcastStore(this);
    subscriptionStore = new SubscriptionStore(this);
    campaignStore = new CampaignStore(this);
    autoSharingStore = new AutoSharingStore(this);
    clipStore = new ClipStore(this);
    clipFormatStore = new ClipFormatStore(this);
    listenersStatsStore = new ListenersStatsStore(this);
    sourcesStatsStore = new SourcesStatsStore(this);
    applePodcastsStatsStore = new ApplePodcastsStatsStore(this);

    @action
    reset() {
        Object.values(this).forEach((store) => store.reset && store.reset());
    }
}

export default new RootStore();
