import { computed } from 'mobx';
import Model from './Model';

export const STATUSES = {
    QUEUED: 'queued',
    EXECUTING: 'executing',
    FINISHED: 'finished',
    FAILED: 'failed',
};

class Progress extends Model {
    static attributes = {
        status: 'status',
        percent: 'progress_percentage',
    };

    @computed
    get isDone() {
        return this.status === STATUSES.FINISHED;
    }

    @computed
    get isQueued() {
        return this.status === STATUSES.QUEUED;
    }

    @computed
    get hasFailed() {
        return this.status === STATUSES.FAILED;
    }
}

export default Progress;
