import { FormattedMessage } from 'react-intl';
import DeprecatedWhitePaper from '@ui/atoms/DeprecatedWhitePaper';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import SharingProviderButton from '@ui/atoms/SharingProviderButton';
import ListFormFallback from '@/components/unorganized/ListFormFallback';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import useEpisodeQuery from '@queries/episode/useEpisodeQuery.hook';

const Loading = () => (
    <DeprecatedWhitePaper p={30}>
        <ListFormFallback />
    </DeprecatedWhitePaper>
);

const EpisodePreviewShare = ({ isLoading }) => {
    const { episodeId } = useParams();
    const { data: episode } = useEpisodeQuery({ episodeId: episodeId, config: { enabled: true } });
    const episodeSmartlinkUrl = episode?.smartlinkUrl;
    if (isLoading) return <Loading />;
    if (episode?.privacy !== 'public') return null;

    return (
        <>
            <DeprecatedText weight="bold" size="veryBig" mt={35} mb={20}>
                <FormattedMessage defaultMessage="Partage" />
            </DeprecatedText>
            <DeprecatedWhitePaper p={25}>
                <DeprecatedPaper flex align="center" column>
                    <DeprecatedText textAlign="center" mb={20} weight="bold" size="big">
                        <FormattedMessage defaultMessage="Partager l'épisode sur" />
                    </DeprecatedText>
                    <DeprecatedPaper w="max-content" flex gap={5}>
                        <SharingProviderButton provider="facebook" url={episodeSmartlinkUrl} />
                        <SharingProviderButton provider="twitter" url={episodeSmartlinkUrl} />
                        <SharingProviderButton provider="linkedin" url={episodeSmartlinkUrl} />
                        <SharingProviderButton provider="whatsapp" url={episodeSmartlinkUrl} />
                    </DeprecatedPaper>
                </DeprecatedPaper>
            </DeprecatedWhitePaper>
        </>
    );
};

export default observer(EpisodePreviewShare);
