import AverageRankingGraphSkeleton from '@public/images/pso-graph-skeleton.svg?url';
import styled, { css, keyframes } from 'styled-components';

const PsoAverageRankingSkeleton = () => {
    return (
        <AverageRankingSkeletonWrapper>
            <TitleBar />
            <Legend>
                <LegendItem>
                    <Icon />
                    <Bars>
                        <BarTop />
                        <BarBottom />
                    </Bars>
                </LegendItem>
                <LegendItem>
                    <Icon />
                    <Bars>
                        <BarTop />
                        <BarBottom />
                    </Bars>
                </LegendItem>
            </Legend>
            <GraphSkeleton src={AverageRankingGraphSkeleton} />
        </AverageRankingSkeletonWrapper>
    );
};

const shimmer = keyframes`
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
`;
const shimmering = css`
    background: var(--neutral50);
    background-image: linear-gradient(
        to right,
        var(--neutral50) 0%,
        #edeef1 20%,
        var(--neutral50) 40%,
        var(--neutral50) 100%
    );
    background-repeat: no-repeat;
    background-size: 800px 104px;
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${shimmer};
    animation-timing-function: linear;
`;
const AverageRankingSkeletonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1.5rem;
`;
const TitleBar = styled.div`
    width: 10rem;
    height: 0.75rem;
    background-color: var(--neutral100);
    border-radius: var(--r-full);
    ${shimmering};
`;
const Legend = styled.ul`
    display: flex;
    align-items: center;
    gap: 1rem;
    list-style: none;
    padding: 0;
    margin: 0;
`;
const LegendItem = styled.li`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;
const Icon = styled.div`
    width: 2.5rem;
    height: 2.5rem;
    border-radius: var(--r-s);
    background-color: var(--neutral100);
    ${shimmering};
`;
const Bars = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;
const BarTop = styled.div`
    width: 7.5rem;
    height: 0.5rem;
    background-color: var(--neutral100);
    border-radius: var(--r-full);
    ${shimmering};
`;
const BarBottom = styled.div`
    width: 2.5rem;
    height: 0.5rem;
    background-color: var(--neutral50);
    border-radius: var(--r-full);
    ${shimmering};
`;
const GraphSkeleton = styled.img`
    width: 100%;
`;

export default PsoAverageRankingSkeleton;
