import { useState } from 'react';
import PropTypes from 'prop-types';
import Text from '@ui/atoms/Text';
import styled from 'styled-components';

const Bars = ({ values }) => {
    const [hoveredBar, setHoveredBar] = useState(null);
    const normalizedValues = values.map((v) => {
        return {
            ...v,
            normalized: Math.max((v.value / Math.max(...values.map((va) => va.value))) * 100, 20),
        };
    });
    return (
        <BarsWrapper>
            <BarsList>
                {normalizedValues.map((nv, index) => (
                    <Bar
                        key={index}
                        height={nv.normalized}
                        onMouseEnter={() => {
                            setHoveredBar({ data: nv, index });
                        }}
                        onMouseLeave={() => {
                            setHoveredBar(null);
                        }}
                    />
                ))}
            </BarsList>
            {hoveredBar && (
                <Tooltip offset={hoveredBar.index}>
                    <Value>{hoveredBar.data.value}</Value>
                    <Period>{hoveredBar.data.period}</Period>
                </Tooltip>
            )}
        </BarsWrapper>
    );
};

const BarsWrapper = styled.div`
    position: relative;
    margin: 0;
    height: 100%;
    width: 100%;
`;
const BarsList = styled.ul`
    display: flex;
    align-items: flex-end;
    gap: 0.5rem;
    list-style: none;
    padding: 0;
    height: 100%;
    width: 100%;
`;
const Bar = styled.li`
    width: 1rem;
    height: ${({ height }) => height}%;
    background-color: var(--primary100);
    border-radius: var(--r-xs);

    &:hover {
        transition-duration: 0.2s;
        background-color: var(--primary);
    }
`;
const Tooltip = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(
        calc(-50% + 0.5rem + ${({ offset }) => offset * 1.5}rem),
        calc(-100% - 1rem)
    );
    background-color: var(--black);
    border-radius: var(--r-s);
    padding: 0.5rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    color: var(--white);

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 40%) rotate(45deg);
        width: 1rem;
        height: 1rem;
        background-color: var(--black);
        border-radius: var(--r-xs);
    }
`;
const Value = styled(Text)`
    font-weight: var(--fw-semibold);
`;
const Period = styled(Text)`
    white-space: nowrap;
`;

Bars.propTypes = {
    values: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.number,
            period: PropTypes.string,
        }),
    ).isRequired,
};

export default Bars;
