import Color from 'color';

export const isDark = (color: string) => {
    try {
        return Color(color).isDark();
    } catch (error) {
        // Unable to parse input as a color
        return false;
    }
};

export const isLight = (color: string) => {
    try {
        return Color(color).isLight();
    } catch (error) {
        // Unable to parse input as a color
        return false;
    }
};

/**
 * Given an input hex code and a desired alpha level, compute the blend mode for this color.
 * Returns a hex code representing the input color with the desired alpha level baked-in, blended on a white background.
 */
export const blendAlphaChannel = (baseColor: string, desiredAlpha: number = 10) => {
    return Color('rgb(255, 255, 255)')
        .mix(Color(baseColor), desiredAlpha / 100)
        .hex();
};

/**
 * Returns a color code based on the given percentage and order.
 *
 * @param percentage - The percentage value to evaluate.
 * @param order - The order in which to evaluate the percentage.
 *                'asc' for ascending (default), where '--success' is near 100 and '--alert' is near 0.
 *                'dsc' for descending, where '--success' is near 0 and '--alert' is near 100.
 * @returns A string representing the color code.
 */
export const getColorByPercentage = (percentage: number, order: 'asc' | 'dsc' = 'asc') => {
    if (order === 'dsc') {
        if (percentage > 75) return '--alert';
        if (percentage > 50) return '--warning';
        return '--success';
    }
    if (percentage < 25) return '--alert';
    if (percentage < 50) return '--warning';
    return '--success';
};
