import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { connect } from '../../../decorators/connect';
import TranscriptionProgress from './TranscriptionProgress';
import TranscriptionEdit from './TranscriptionEdit';

const enhance = connect(({ clipStore }) => {
    const transcriptionIsDone =
        (clipStore.transcriptionProgress &&
            clipStore.transcriptionProgress.transcription &&
            clipStore.transcriptionProgress.transcription.isDone) ||
        false;

    return {
        transcriptionIsDone,
        resetTranscriptionProgress: clipStore.resetTranscriptionProgress,
    };
});

const Transcription = ({ episode, transcriptionIsDone, resetTranscriptionProgress, ...other }) => {
    const { values, setFieldValue } = useFormikContext();

    useEffect(() => () => resetTranscriptionProgress(), []);

    if (!transcriptionIsDone) {
        return (
            <div {...other}>
                <TranscriptionProgress clipId={values.id} />
            </div>
        );
    }

    return (
        <div {...other}>
            <TranscriptionEdit episode={episode} values={values} setFieldValue={setFieldValue} />
        </div>
    );
};

export default enhance(Transcription);
