import { useState } from 'react';
import styled from 'styled-components';
import { ChannelGuestRow } from './ChannelGuestsTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FormattedMessage } from 'react-intl';
import MenuDropdown from '@/components/ui/atoms/MenuDropdown';
import { DialogTrigger, Popover } from 'react-aria-components';
import IconButton from '@/components/IconButton';
import ChannelGuestsEditPermissionModal from '../ChannelGuestsEditPermissionModal';
import ChannelGuestsDeletePermissionModal from '../ChannelGuestsDeletePermissionModal';
import deleteChannelGuestPermissionMutation from '@/queries/guest/deleteChannelGuestPermissionMutation';
import useChannelQuery from '@/queries/channel/useChannelQuery.hook';
import deleteChannelGuestInvitationMutation from '@/queries/guest/deleteChannelGuestInvitationMutation';

interface ChannelGuestsActionCellProps extends ChannelGuestRow {}

type MenuAction = 'edit' | 'delete';

const ChannelGuestsActionCell = (props: ChannelGuestsActionCellProps) => {
    const { data: channel } = useChannelQuery();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const isInvitation = !props.userId;
    const deletePermission = deleteChannelGuestPermissionMutation();
    const deleteInvitation = deleteChannelGuestInvitationMutation();

    const menuItems = [
        {
            id: 'edit',
            icon: <MenuItemIcon icon={icon({ name: 'pen-line', style: 'solid' })} />,
            name: <FormattedMessage defaultMessage="Modifier" />,
        },
        {
            id: 'delete',
            icon: <MenuItemIcon icon={icon({ name: 'trash', style: 'solid' })} />,
            name: <FormattedMessage defaultMessage="Supprimer" />,
            color: '--alert',
        },
    ];
    const items = isInvitation ? menuItems.filter((item) => item.id !== 'edit') : menuItems;

    const handleDelete = () => {
        if (!channel?.id) return;

        if (isInvitation) {
            deleteInvitation.mutate({ channelId: channel.id, email: props.userName as string });
            setIsDeleteModalOpen(false);
            return;
        }

        deletePermission.mutate({ channelId: channel.id, userId: props.userId as number });
        setIsDeleteModalOpen(false);
    };

    const menuItemsHandler = (action: MenuAction) => {
        switch (action) {
            case 'edit':
                setIsMenuOpen(false);
                setIsEditModalOpen(true);
                break;
            case 'delete':
                setIsMenuOpen(false);
                setIsDeleteModalOpen(true);
                break;
            default:
                break;
        }
    };

    return (
        <Cell>
            <DialogTrigger>
                <TriggerButton
                    aria-label="Menu"
                    variant="ghost"
                    icon={<MenuIcon icon={icon({ name: 'ellipsis', style: 'solid' })} />}
                    onPress={() => setIsMenuOpen(true)}
                />
                <Popover isOpen={isMenuOpen} onOpenChange={setIsMenuOpen} placement="bottom end">
                    {/* @ts-ignore */}
                    <ActionMenuDropdown items={items} onAction={menuItemsHandler} />
                </Popover>
            </DialogTrigger>
            <ChannelGuestsEditPermissionModal
                isOpen={isEditModalOpen}
                onOpenChange={setIsEditModalOpen}
                userId={props.userId as number}
            />
            <ChannelGuestsDeletePermissionModal
                isOpen={isDeleteModalOpen}
                onOpenChange={setIsDeleteModalOpen}
                onDelete={handleDelete}
                isLoading={deletePermission.isLoading}
            />
        </Cell>
    );
};

const Cell = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 0.75rem;
`;
const MenuItemIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: inherit;
`;
const MenuIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: var(--neutral500);
`;
const TriggerButton = styled(IconButton)`
    padding: 0;
`;
const ActionMenuDropdown = styled(MenuDropdown)`
    width: 10rem;
`;

export default ChannelGuestsActionCell;
