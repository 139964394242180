import PropTypes from 'prop-types';
import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    WhatsappShareButton,
} from 'react-share';
import FacebookIcon from '@public/images/social/facebook-rounded.svg';
import XIcon from '@public/images/social/x-rounded.svg';
import LinkedInIcon from '@public/images/social/linkedin.svg';
import WhatsAppIcon from '@public/images/social/whatsapp.svg';
import Icon from '../Icon';
import styled from 'styled-components';
import Color from 'color';
import { resolveColor } from '@/shared/utils/cssVariable';

const ButtonInner = styled.div`
    color: white;
    background: ${(p) => resolveColor(p.background)};
    box-shadow: 0 2px 12px ${(p) => Color(resolveColor(p.background)).alpha(0.2).string()};
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 0.5rem;
    border-radius: var(--r-xs);
    cursor: pointer;
    padding: 0.5rem;
    font-weight: var(--fw-bold);
`;

const ProviderButton = {
    facebook: {
        sharing: FacebookShareButton,
        icon: FacebookIcon,
        label: 'Facebook',
    },
    twitter: {
        sharing: TwitterShareButton,
        icon: XIcon,
        label: 'X (Twitter)',
    },
    linkedin: {
        sharing: LinkedinShareButton,
        icon: LinkedInIcon,
        label: 'LinkedIn',
    },
    whatsapp: {
        sharing: WhatsappShareButton,
        icon: WhatsAppIcon,
        label: 'WhatsApp',
    },
};

const SharingProviderButton = ({ provider, url, quote, title, customLabel, ...props }) => {
    const ProviderShare = ProviderButton[provider]?.sharing;

    return (
        <ProviderShare url={url} quote={quote} title={title} {...props}>
            <ButtonInner background={`--${provider}`}>
                <Icon as={ProviderButton[provider]?.icon} size={20} />
                {customLabel}
            </ButtonInner>
        </ProviderShare>
    );
};

SharingProviderButton.propTypes = {
    url: PropTypes.string.isRequired,
    provider: PropTypes.oneOf(['facebook', 'twitter', 'linkedin', 'whatsapp']).isRequired,
    customLabel: PropTypes.string,
    quote: PropTypes.string,
    title: PropTypes.string,
};

export default SharingProviderButton;
