import { useState, useMemo } from 'react';
import BarChart from 'react-apexcharts';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useResponsive } from '../../../../utils/hooks/useResponsive';
import DeprecatedText from '../../atoms/DeprecatedText';
import DeprecatedPaper from '../../atoms/DeprecatedPaper';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';

export const STATS_NAME = {
    LISTENERS: 'LISTENERS',
    AGES: 'AGES',
    OS: 'OS',
};

const MESSAGES = {
    AGES: {
        title: <FormattedMessage defaultMessage="Part d'écoute selon l'âge de vos auditeurs" />,
        tooltipSingle: <FormattedMessage defaultMessage="%" />,
        tooltipPlural: <FormattedMessage defaultMessage="%" />,
    },
    LISTENERS: {
        title: <FormattedMessage defaultMessage="Nombre d’écoutes" />,
        tooltipSingle: <FormattedMessage defaultMessage="écoute" />,
        tooltipPlural: <FormattedMessage defaultMessage="écoutes" />,
    },
    OS: {
        title: <FormattedMessage defaultMessage="Part d'écoute selon le système d'exploitation" />,
        tooltipSingle: <FormattedMessage defaultMessage="%" />,
        tooltipPlural: <FormattedMessage defaultMessage="%" />,
    },
};

const dataBar = ({
    intl,
    options,
    series,
    columnWidth,
    fontSize,
    isMobileOrTablet,
    capitalize,
}) => ({
    options: {
        chart: {
            id: 'basic-bar',
            type: 'bar',
            toolbar: { show: false },
            zoom: {
                enabled: false,
            },
        },
        xaxis: {
            categories: options,
            tickPlacement: 'on',
            axisTicks: {
                show: !isMobileOrTablet,
            },
            axisBorder: {
                show: !isMobileOrTablet,
            },
            labels: {
                style: {
                    fontSize: fontSize || 13,
                    cssClass: capitalize && 'tooltip-ausha-apexChart-capitalize',
                },
                formatter: (value) => {
                    let date = null;
                    /**
                     * For some shitty reason the dayjs date "value" is cast as a string on Safari so we have to reinstanciate
                     * a full date from "value" to avoid issue on this god-forsaken browser.
                     */
                    if (typeof value === 'string') {
                        // Safari
                        date = dayjs().hour(value).subtract(1, 'hour');
                    } else {
                        date = dayjs(value);
                    }
                    return intl.formatDate(date, {
                        hour: 'numeric',
                    });
                },
            },
        },
        yaxis: {
            show: !isMobileOrTablet,
        },
        labels: {
            style: {
                fontSize: isMobileOrTablet ? '7px' : '12px',
            },
        },
        fill: {
            type: 'solid',
            colors: '#751CBF',
            opacity: [1],
        },
        plotOptions: {
            bar: {
                columnWidth,
                distributed: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        tooltip: {
            custom: ({ dataPointIndex }) => {
                const hourOfDay = dayjs(options[dataPointIndex]);
                const formattedHourOfDay = intl.formatDate(hourOfDay, {
                    hour: 'numeric',
                });
                return `<div class="tooltip-ausha-apexChart">
                    <p class="tooltip-ausha-apexChart-day ${
                        capitalize && 'tooltip-ausha-apexChart-capitalize'
                    }">
                        ${formattedHourOfDay}
                    </p>
                    ${
                        series[dataPointIndex].tooltipMessage
                            ? `<p class="tooltip-ausha-apexChart-listen">${series[dataPointIndex].tooltipMessage}</p>`
                            : `<p class="tooltip-ausha-apexChart-listen">${series[dataPointIndex]} %</p>`
                    }
                </div>`;
            },
        },
    },
    series: [
        {
            data: series,
        },
    ],
});

const PeakHourBarGraph = ({
    options,
    series,
    columnWidth,
    w,
    h,
    type = 'bar',
    fontSize,
    isNoData,
    statName,
    capitalize,
}) => {
    const intl = useIntl();
    if (isNoData) {
        return (
            <DeprecatedText weight="semibold" align="center" size="big">
                <FormattedMessage defaultMessage="Aucune donnée disponible" />
            </DeprecatedText>
        );
    }

    const [data, setData] = useState({ options, series, columnWidth });
    const { isMobileOrTablet } = useResponsive();

    useMemo(() => {
        setData(
            dataBar({
                intl,
                options,
                series,
                columnWidth,
                fontSize,
                isMobileOrTablet,
                statName,
                capitalize,
            }),
        );
        return () => setData([]);
    }, [options, series]);

    return (
        <>
            <DeprecatedPaper flex align="center" mb={20}>
                <DeprecatedPaper
                    as="span"
                    background="--primary"
                    w={10}
                    h={10}
                    mr={10}
                    style={{ borderRadius: '50%' }}
                />
                <DeprecatedText>{MESSAGES[statName].title}</DeprecatedText>
            </DeprecatedPaper>
            <div className="custom-ausha-barChart">
                <BarChart
                    options={data.options}
                    series={data.series}
                    type={type}
                    width={w}
                    height={h}
                />
            </div>
        </>
    );
};

PeakHourBarGraph.propTypes = {
    options: PropTypes.arrayOf(PropTypes.any),
    w: PropTypes.string,
    h: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.string,
    columnWidth: PropTypes.string,
    series: PropTypes.arrayOf(PropTypes.any),
    fontSize: PropTypes.number,
    isNoData: PropTypes.bool,
    statName: PropTypes.string,
    capitalize: PropTypes.bool,
};

PeakHourBarGraph.defaultProps = {
    w: '100%',
    h: '330',
    type: 'bar',
    columnWidth: '25%',
};

export default PeakHourBarGraph;
