interface UploadReportData {
    fileSize: number;
    duration: number;
}

const BASE_URI = process.env.API_STATS_UPLOAD;

export const sendUploadReport = (data: UploadReportData) => {
    return fetch(`${BASE_URI}/send-data`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        body: JSON.stringify(data),
    });
};
