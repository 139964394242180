import { FormattedMessage, useIntl } from 'react-intl';
import useUserStore from '@/utils/hooks/useUserStore';
import { observer } from 'mobx-react';
import { InputTextController } from '@ui/atoms/InputText';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import useEditEmailFormSchema from './useEditEmailFormSchema.hook';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@ui/atoms/Button';
import { useModalToastQueue } from '@/utils/hooks/useModalToastQueue.hook';

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    height: 100%;
`;
const ButtonsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    margin-top: auto;

    & > :first-child,
    > :last-child {
        align-self: stretch;
    }

    ${(p) => p.theme.mediaQueries.tabletAndUp} {
        flex-direction: row;
        justify-content: flex-end;
        column-gap: 0.5rem;
        margin-top: 0.5rem;

        & > :first-child,
        > :last-child {
            align-self: initial;
        }

        & > :first-child {
            order: 1;
        }
    }
`;

const EditEmailForm = ({ onAfterSubmit }) => {
    const intl = useIntl();
    const { user, updateEmail } = useUserStore();
    const isLoading = updateEmail.pending;
    const schema = useEditEmailFormSchema();
    const { control, handleSubmit, formState, clearErrors } = useForm({
        mode: 'onChange',
        defaultValues: {
            email: user?.email || '',
            password: '',
        },
        resolver: yupResolver(schema),
    });
    const toast = useModalToastQueue();

    const onSubmit = (formData) => {
        updateEmail(formData)
            .then(({ data }) => {
                user.updateData(data);
                toast.success(
                    <FormattedMessage defaultMessage="Votre e-mail a bien été modifié 👌" />,
                ),
                    onAfterSubmit();
            })
            .catch(() => {
                toast.alert(
                    <FormattedMessage defaultMessage="Oups, il semblerait qu'une erreur soit survenue lors de la modification de votre e-mail" />,
                );
            });
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <InputTextController
                control={control}
                name="email"
                label={<FormattedMessage defaultMessage="Adresse e-mail" />}
                placeholder={intl.formatMessage({ defaultMessage: 'thomas.pesquet@esa.com' })}
                errorMessage={formState.errors.email?.message}
                onBlur={() => clearErrors('email')}
                isRequired
                autoFocus
            />
            <InputTextController
                control={control}
                name="password"
                type="password"
                label={<FormattedMessage defaultMessage="Mot de passe" />}
                placeholder={intl.formatMessage({ defaultMessage: '8 caractères minimum' })}
                errorMessage={formState.errors.password?.message}
                onBlur={() => clearErrors('password')}
                isRequired
            />
            <ButtonsWrapper>
                <Button
                    type="submit"
                    isLoading={isLoading}
                    isDisabled={!formState.isValid || isLoading}
                >
                    <FormattedMessage defaultMessage="Enregistrer" />
                </Button>
                <Button variant="secondary" onPress={onAfterSubmit}>
                    <FormattedMessage defaultMessage="Annuler" />
                </Button>
            </ButtonsWrapper>
        </Form>
    );
};

export default observer(EditEmailForm);
