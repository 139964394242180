import { useQuery } from '@tanstack/react-query';
import { fetchPodcastNewsletterTemplate } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import newsletterKeys from '@queries/newsletter/newsletterKeys';

const queryFn = async ({ queryKey: [{ episodeId }] }) => {
    const { data } = await fetchPodcastNewsletterTemplate(episodeId);

    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const useEpisodeNewsletterTemplateQuery = (episodeId) => {
    return useQuery({
        queryKey: newsletterKeys.detailByEpisodeId(episodeId),
        queryFn,
        enabled: !!episodeId,
    });
};

export default useEpisodeNewsletterTemplateQuery;
