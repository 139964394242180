const channelKeys = {
    all: () => [{ entity: 'episode' }],
    allLists: () => [{ ...channelKeys.all()[0], scope: 'list' }],
    allDetails: () => [{ ...channelKeys.all()[0], scope: 'detail' }],
    showsById: (channelId: number | undefined) => [
        { ...channelKeys.allDetails()[0], channelId: channelId, subscope: 'shows' },
    ],
    settingsById: (channelId: number | undefined) => [
        { ...channelKeys.allDetails()[0], channelId: channelId, subscope: 'settings' },
    ],
    settingsBySlug: (slug: string) => [
        { ...channelKeys.allDetails()[0], slug: slug, subscope: 'settings' },
    ],
};

export default channelKeys;
