import { Item, useSingleSelectListState } from 'react-stately';
import Cluster from '@ui/layout/Cluster';
import Stack from '@ui/layout/Stack';
import { DeprecatedText } from '@ui/atoms/DeprecatedText';
import { FormattedMessage } from 'react-intl';
import { useRef } from 'react';
import { useListBox, useOption } from 'react-aria';
import styled from 'styled-components';

const StyledOption = styled.li`
    background: ${(props) => (props.isFocused ? 'var(--neutral50)' : 'var(--white)')};
    color: ${(props) => (props.isSelected ? 'var(--primary)' : 'var(--dark)')};
    font-weight: ${(props) => (props.isSelected ? 'var(--fw-semibold)' : 'var(--fw-normal)')};
    padding: 0.375rem 0.75rem;
    display: flex;
    gap: 0.75rem;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    outline: none;
`;

const Option = ({ item, state }) => {
    const ref = useRef();
    const { optionProps, isSelected, isFocused } = useOption({ key: item.key }, state, ref);

    return (
        <StyledOption {...optionProps} ref={ref} isSelected={isSelected} isFocused={isFocused}>
            {item.rendered}
        </StyledOption>
    );
};

const List = styled.ul`
    max-height: 300px;
    overflow: auto;
    list-style: none;
    padding: 0.375rem 0;
    outline: none;
    width: 20rem;
`;

const ListBox = (props) => {
    const state = useSingleSelectListState(props);

    const ref = useRef();
    const { listBoxRef = ref } = props;
    const { listBoxProps } = useListBox(props, state, listBoxRef);

    return (
        <List {...listBoxProps} ref={listBoxRef}>
            {[...state.collection].map((item) => (
                <Option key={item.key} item={item} state={state} />
            ))}
        </List>
    );
};

const UserPicture = styled.img`
    width: 2.5rem;
    height: 2.5rem;
    border-radius: var(--r-full);
    flex-shrink: 0;
`;

const UserName = styled.span`
    font-size: var(--fs-body);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

const UserHandle = styled.span`
    color: var(--neutral500);
    font-size: 0.75rem;
    font-weight: var(--fw-semibold);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

const MentionsListWrapper = styled.div`
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: var(--white);
    border-radius: var(--r-xs);
    overflow: hidden;
`;
const NoResultWrapper = styled.div`
    padding: 0.75rem;
`;

const MentionsList = ({ items, command, query }) => {
    // No request to API is made until query is at least 3 characters long, prevent showing empty list
    if (query.length < 3) {
        return null;
    }

    return (
        <MentionsListWrapper>
            {items?.length > 0 ? (
                <ListBox items={items} onSelectionChange={(key) => command({ id: key })}>
                    {(item) => (
                        <Item textValue={item.mention_name}>
                            <Cluster
                                $gap="0.75rem"
                                $align="center"
                                $wrap="nowrap"
                                css="max-width: 100%;"
                            >
                                <UserPicture src={item.profile_picture} alt="" />
                                <Stack css="min-width: 0;">
                                    <UserName>{item.full_name}</UserName>
                                    <UserHandle>@{item.mention_name}</UserHandle>
                                </Stack>
                            </Cluster>
                        </Item>
                    )}
                </ListBox>
            ) : (
                <NoResultWrapper>
                    <DeprecatedText>
                        <FormattedMessage
                            defaultMessage="Aucun résultat pour {query}"
                            values={{
                                query: (
                                    <DeprecatedText as="span" weight="bold">
                                        @{query}
                                    </DeprecatedText>
                                ),
                            }}
                        />
                    </DeprecatedText>
                </NoResultWrapper>
            )}
        </MentionsListWrapper>
    );
};

export default MentionsList;
