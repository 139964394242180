import { FormattedMessage } from 'react-intl';
import type { ImportedEpisode as ImportedEpisodeT } from '@queries/rss/useImportedEpisodesQuery.hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import ContextualMenu from './ContextualMenu';
import Checkbox from '@/components/ui/Checkbox';
import { useEpisodeSelectContext } from '@/context/EpisodeSelectContext';
import styled from 'styled-components';

interface EpisodeProps {
    episode: ImportedEpisodeT;
}

const Episode = ({ episode }: EpisodeProps) => {
    const { allSelected, allSelectedOnPage, isSelected, toggleSelection } =
        useEpisodeSelectContext();

    return (
        <EpisodeWrapper>
            <Checkbox
                isSelected={allSelected || allSelectedOnPage || isSelected(`${episode.id}`)}
                onChange={() => episode?.id && toggleSelection(`${episode.id}`)}
            />
            <EpisodeInnerWrapper>
                <Left>
                    <CoverWrapper>
                        <Cover src="https://image.ausha.co/default_cover.png" alt="Default cover" />
                    </CoverWrapper>
                    <LeftTextWrapper>
                        <EpisodeTitle>{episode.title}</EpisodeTitle>
                    </LeftTextWrapper>
                </Left>
                <Right>
                    <StatusWrapper>
                        <Status>
                            <StatusIcon
                                icon={icon({ name: 'circle-exclamation', style: 'solid' })}
                            />
                            <FormattedMessage defaultMessage="Erreur" />
                        </Status>
                    </StatusWrapper>
                    <ButtonDecoy />
                    <ContextualMenu episode={episode} />
                </Right>
            </EpisodeInnerWrapper>
        </EpisodeWrapper>
    );
};

const EpisodeWrapper = styled.li`
    transition-duration: 0.2s;
    height: 4.5rem;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    border-radius: var(--r-m);
    background-color: var(--white);
    padding-block: 0.75rem;
    padding-inline: 1rem;
    min-width: 0;

    &:not(:last-child) {
        border-bottom: 1px solid var(--neutral100);
    }

    &:hover {
        z-index: 2;
        box-shadow: var(--s-whatever);
    }

    &:not(:last-child):hover {
        border-bottom: 1px solid transparent;
    }
`;
const EpisodeInnerWrapper = styled.div`
    align-self: stretch;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    min-width: 0;
`;
const CoverWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    flex-shrink: 0;
`;
const Cover = styled.img`
    position: absolute;
    left: 0;
    top: 0;
    border-radius: var(--r-s);
`;
const Left = styled.div`
    display: flex;
    align-items: center;
    gap: 0.75rem;
    flex: 1;
    min-width: 0;
`;
const EpisodeTitle = styled.div`
    font-weight: var(--fw-semibold);
    font-size: var(--fs-body-l);
    flex: 1;

    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: 1450px) {
        max-width: 600px;
    }

    &:hover {
        text-decoration: underline;
    }
`;
const LeftTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    flex: 1;
    min-width: 0;

    &:hover ${EpisodeTitle} {
        text-decoration: underline;
    }
`;
const Right = styled.div`
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    gap: 0.5rem;
    align-items: center;
`;
const ButtonDecoy = styled.div`
    width: 2.25rem;
`;
const StatusWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 6.25rem;
`;
const Status = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--alert);
    background-color: var(--alert50);
    padding: 0.5rem;
    border-radius: var(--r-s);
    font-weight: var(--fw-semibold);
`;
const StatusIcon = styled(FontAwesomeIcon)`
    font-size: 0.75rem;
    color: var(--alert);
`;

export default Episode;
