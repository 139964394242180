import { useQuery } from '@tanstack/react-query';
import { fetchClipSupportedLanguages } from '@/api';
import clipKeys from './clipKeys';
import { z } from 'zod';

export const Schema = z.record(z.string(), z.string());
export type ClipTranscriptionSupportedLanguages = z.infer<typeof Schema>;

const queryFn = async () => {
    const { data } = await fetchClipSupportedLanguages();
    return Schema.parse(data);
};

const useClipTranscriptionSupportedLanguagesQuery = () => {
    return useQuery({
        queryKey: clipKeys.transcriptionSupportedLanguages(),
        queryFn,
        staleTime: Infinity,
    });
};

export default useClipTranscriptionSupportedLanguagesQuery;
