import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import { InputTextController } from '@ui/atoms/InputText';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { GUEST_USER_ROLES, USER_ROLES } from '@/utils/constants';
import useShowGuestsInvitationFormSchema, {
    ShowGuestsInvitationFormSchema,
} from './useShowGuestsInvitationFormSchema.hook';
import { zodResolver } from '@hookform/resolvers/zod';
import addShowGuestInvitationMutation from '@/queries/guest/addShowGuestInvitationMutation';
import { useParams } from 'react-router-dom';
import { SelectController } from '@/components/ui/atoms/Select';
import ListBoxItem from '@/components/ui/atoms/ListBoxItem';
import { AxiosError } from 'axios';
import Button from '@/components/Button';
import GuestRolesTooltip from '@/components/GuestRolesTooltip';

const roleLabels = defineMessages({
    viewer: { defaultMessage: 'Lecteur' },
    editor: { defaultMessage: 'Éditeur' },
    admin: { defaultMessage: 'Admin' },
});

interface ShowGuestsInvitationFormProps {
    onSuccess: () => void;
    onPermissionError: () => void;
}

const ShowGuestsInvitationForm = ({
    onSuccess,
    onPermissionError,
}: ShowGuestsInvitationFormProps) => {
    const { showId } = useParams<{ showId: string }>();
    const intl = useIntl();
    const schema = useShowGuestsInvitationFormSchema();
    const { control, handleSubmit } = useForm<ShowGuestsInvitationFormSchema>({
        mode: 'onBlur',
        defaultValues: {
            email: '',
            role: USER_ROLES.VIEWER,
            message: '',
        },
        resolver: zodResolver(schema),
    });
    const roleItems = Object.values(GUEST_USER_ROLES).map((role) => ({
        id: role,
        name: intl.formatMessage(roleLabels[role]),
    }));
    const addInvitation = addShowGuestInvitationMutation();

    const onSubmit = (formData: ShowGuestsInvitationFormSchema) => {
        addInvitation.mutate(
            { showId, invitation: formData },
            {
                onSuccess,
                onError: (error: unknown) => {
                    if ((error as AxiosError)?.response?.status === 400) {
                        onPermissionError();
                    }
                },
            },
        );
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <ResponsiveWrapper>
                <InputTextController
                    control={control}
                    name="email"
                    label={<FormattedMessage defaultMessage="Adresse e-mail" />}
                    placeholder={intl.formatMessage({ defaultMessage: 'neil.armstrong@nasa.com' })}
                    isRequired
                />
                <SelectController
                    name="role"
                    control={control}
                    items={roleItems}
                    label={<FormattedMessage defaultMessage="Rôle" />}
                    isRequired
                    tooltip={<GuestRolesTooltip />}
                >
                    {(item) => <ListBoxItem {...item} />}
                </SelectController>
            </ResponsiveWrapper>
            <InputTextController
                elementType="textarea"
                minHeight="152px"
                maxLength={400}
                control={control}
                name="message"
                label={<FormattedMessage defaultMessage="Votre message" />}
                placeholder={intl.formatMessage({ defaultMessage: 'Saisir votre message...' })}
                isOptional
            />
            <ButtonsWrapper>
                <Button type="submit" isLoading={addInvitation.isLoading}>
                    <FormattedMessage defaultMessage="Envoyer l'invitation" />
                </Button>
                <Button variant="link-secondary" onPress={onSuccess}>
                    <FormattedMessage defaultMessage="Annuler" />
                </Button>
            </ButtonsWrapper>
        </Form>
    );
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    height: 100%;

    ${(p) => p.theme.mediaQueries.tabletAndUp} {
        row-gap: 2rem;
    }
`;
const ResponsiveWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;

    ${(p) => p.theme.mediaQueries.tabletAndUp} {
        flex-direction: row;
        column-gap: 1.25rem;

        & > :nth-child(1),
        & > :nth-child(2) {
            flex: 1;
        }
    }
`;
const ButtonsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    margin-top: auto;

    & > :first-child,
    > :last-child {
        align-self: stretch;
    }

    ${(p) => p.theme.mediaQueries.tabletAndUp} {
        flex-direction: row;
        justify-content: space-between;
        column-gap: 0.5rem;
        margin-top: 0.5rem;

        & > :first-child,
        > :last-child {
            align-self: initial;
        }

        & > :first-child {
            order: 1;
        }
    }
`;

export default observer(ShowGuestsInvitationForm);
