import { useMutation, useQueryClient } from '@tanstack/react-query';
import { resignSubscription } from '@/api';

export default function useResignSubscription(subscriptionId) {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (formData) => {
            return resignSubscription(formData);
        },
        onMutate: async (data) => {
            /**
             * Cancel any outgoing refetches
             * (so they don't overwrite our optimistic update)
             */
            await queryClient.cancelQueries({ queryKey: ['subscriptions.show', subscriptionId] });

            // Snapshot the previous value
            const previousState = queryClient.getQueryData(['subscriptions.show', subscriptionId]);

            // Optimistically update to the new value
            queryClient.setQueryData(['subscriptions.show', subscriptionId], () => data.platforms);

            // Return a context object with the snapshotted value
            return { previousState };
        },
        /**
         * If the mutation fails,
         * use the context returned from onMutate to roll back
         */
        onError: (err, data, context) => {
            queryClient.setQueryData(['subscriptions.show', subscriptionId], context.previousState);
        },
        /**
         * Always refetch after error or success:
         */
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['subscriptions.show', subscriptionId] });
        },
    });
}
