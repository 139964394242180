import Model from './Model';
import { dateUTC } from './resolvers';

class ReviewModel extends Model {
    static attributes = {
        author: 'author',
        date: dateUTC('timestamp'),
        message: 'description',
        rating: 'rating',
        title: 'title',
    };
}

export default ReviewModel;
