import { useParams } from 'react-router-dom';
import useShowListeningLinksQuery from '@queries/showListeningLink/useShowListeningLinksQuery.hook';
import Spinner from '@/components/ui/atoms/Spinner';
import { SPOTIFY, APPLE_PODCASTS } from '@/utils/constants';
import styled from 'styled-components';

interface ConfigurationCurrentStepProps {
    configurationStates: {
        [key: string]: string;
    };
    configurationStateLookup: {
        [key: string]: React.ReactElement;
    };
    configurationCurrentState: string;
}

const ConfigurationCurrentStep = ({
    configurationStates,
    configurationStateLookup,
    configurationCurrentState,
}: ConfigurationCurrentStepProps) => {
    const { showId } = useParams<{ showId: string }>();
    const listeningLinks = useShowListeningLinksQuery(showId);

    if (listeningLinks.isLoading || listeningLinks.isError) {
        return (
            <LoadingWrapper>
                <Spinner />
            </LoadingWrapper>
        );
    }
    const showEmptyState =
        !listeningLinks.data?.map((l: any) => l.key).includes(APPLE_PODCASTS) &&
        !listeningLinks.data?.map((l: any) => l.key).includes(SPOTIFY);
    if (showEmptyState) return configurationStateLookup[configurationStates.EMPTY];
    return configurationStateLookup[configurationCurrentState || configurationStates.LANDING];
};

const LoadingWrapper = styled.div`
    width: 100%;
    height: 25rem;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default ConfigurationCurrentStep;
