import IconButton from '@/components/IconButton';
import Modal from '@ui/atoms/Modal';
import Text from '@ui/atoms/Text';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

interface FeedbackModalProps {
    isOpen: boolean;
    onOpenChange: (open: boolean) => void;
}

const FeedbackModal = ({ isOpen, onOpenChange }: FeedbackModalProps) => {
    const intl = useIntl();

    const handleClose = () => onOpenChange(false);

    return (
        <ModalStyled isOpen={isOpen} onOpenChange={onOpenChange} size="large">
            <Header>
                <Text variant="headingM" fontWeight="--fw-bold">
                    <FormattedMessage defaultMessage="Feedback" />
                </Text>
                <IconButton
                    icon={<CloseIcon icon={icon({ name: 'xmark-large', style: 'solid' })} />}
                    onPress={handleClose}
                    variant="ghost"
                    aria-label="Close modal"
                    style={{ padding: 0 }}
                />
            </Header>
            <iframe
                src={intl.formatMessage({
                    defaultMessage:
                        'https://feedback.userreport.com/3b9d5527-427b-41f3-b5e6-26c3ab996e1b#ideas/popular',
                })}
                style={{ border: 0, width: '100%' }}
                title="Feedback"
                height={620}
                width="100%"
            />
        </ModalStyled>
    );
};

const ModalStyled = styled(Modal)`
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 2rem;
    overflow-y: auto;
`;
const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 1rem;
`;
const CloseIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--neutral500);
`;

export default FeedbackModal;
