import { FormattedMessage } from 'react-intl';
import Text from '@ui/atoms/Text';
import noUserMainIllustration from '@public/images/empty-state/illustration/users.png';
import noUserHeadIllustration from '@public/images/empty-state/users.svg?url';
import EmptyState from '@ui/molecules/EmptyState';
import ChannelGuestsAddButton from './ChannelGuestsAddButton';
import styled from 'styled-components';

const ChannelGuestsEmptyState = () => {
    return (
        <>
            <EmptyState
                illustration={noUserMainIllustration}
                illustrationType="image"
                illustrationBackground="--gradient-dawn"
                illustrationAlign="right"
            >
                <HeadIllustrationWrapper>
                    <HeadIllustration src={noUserHeadIllustration} />
                </HeadIllustrationWrapper>
                <LabelWrapper>
                    <Text variant="heading" fontWeight="--fw-bold">
                        <FormattedMessage defaultMessage="Invitez des utilisateurs" />
                    </Text>
                    <Text variant="body" fontWeight="--fw-normal" color="--neutral500">
                        <FormattedMessage defaultMessage="Ajoutez d’autres membres de votre équipe pour vous aider à gérer vos podcasts 👥" />
                    </Text>
                </LabelWrapper>
                <ChannelGuestsAddButton>
                    <FormattedMessage defaultMessage="Inviter des utilisateurs" />
                </ChannelGuestsAddButton>
            </EmptyState>
        </>
    );
};

const HeadIllustrationWrapper = styled.div`
    width: 200px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const HeadIllustration = styled.img`
    min-width: 248px;
`;
const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    & > * {
        text-align: center;
    }
`;

export default ChannelGuestsEmptyState;
