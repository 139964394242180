import { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import Tabs from '../smarties/Tabs';
import AnimatedRoutes from '../organisms/AnimatedRoutes';
import DefaultRoute from '../../app/routes/DefaultRoute';
import styled from 'styled-components';

const TemplateTabsFixed = ({
    sectionTitle,
    tabs,
    children,
    routeTabs,
    tabsTitle,
    defaultRoute,
}) => {
    const { url } = useRouteMatch();
    useEffect(() => window.scrollTo({ top: 0 }), [url]);

    return (
        <>
            <Tabs
                sectionTitle={sectionTitle}
                w="max-content"
                y={190}
                z={1}
                x={0}
                pl={20}
                route={routeTabs}
                flex
                column
                h="max-content"
                titles={tabsTitle}
            >
                {tabs.props.children}
            </Tabs>
            <AnimatedRoutes initial>
                <RouteInnerWrapper>{children}</RouteInnerWrapper>
            </AnimatedRoutes>
            {defaultRoute && <DefaultRoute to={defaultRoute} />}
        </>
    );
};

const RouteInnerWrapper = styled.div`
    --ideal-size: calc(var(--container-width) - 14rem);
    width: clamp(var(--ideal-size), 100%, var(--ideal-size));

    ${({ theme }) => theme.mediaQueries.mobile} {
        width: 100%;
    }
`;

export default TemplateTabsFixed;
