import styled, { css } from 'styled-components';
import Link from '../Link';

const NavButton = styled(Link)`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 120px;
    height: 100%;
    border: 0;
    text-decoration: none;

    background: var(--white);
    color: var(--neutral500);
    transition: all 0.3s;

    &:after {
        content: '';
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 2px;
        transform: scaleY(0);
        background: var(--primary);
        transition: all 0.3s;
        transform-origin: 0 100%;
    }

    &:hover {
        color: var(--primary);
    }

    ${({ active }) =>
        active &&
        css`
            color: var(--primary);

            &:after {
                transform: scaleY(1);
            }
        `}
    ${({ disabled }) =>
        disabled &&
        css`
            pointer-events: none;
            opacity: 0.5;
        `}

    @media (max-width: 1100px) {
        & > p {
            display: none;
        }
    }
`;

export default NavButton;
