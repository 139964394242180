import { useState } from 'react';
import styled from 'styled-components';
import throttle from 'lodash.throttle';
import { Field, FieldArray } from 'formik';
import { FormattedMessage } from 'react-intl';
import Center from '@ui/layout/Center';
import Transcript from './Transcript';
import FetcherClipTranscription from '@app/fetchers/FetcherClipTranscription';
import AudioWaveform from './AudioWaveform';
import Cluster from '@ui/layout/Cluster';
import DeprecatedAlert from '@ui/molecules/DeprecatedAlert';
import PlayPauseButton from './PlayPauseButton';
import { useSwitch } from '@hooks/useSwitch';
import Spinner from '@ui/atoms/Spinner';
import TranscriptionError from './TranscriptionError';
import Stack from '@ui/layout/Stack';

const ScrollableStack = styled(Stack)`
    height: 20.5rem;
    max-height: 20.5rem;
    overflow-y: auto;
    padding-left: 1rem;
    padding-right: 1rem;
`;

const TranscriptsFields = ({ values, setFieldValue, currentPlayTime }) => (
    <FieldArray
        name="transcription.subtitles"
        render={() => (
            <ScrollableStack $gap="1.25rem">
                {values.transcription?.subtitles.map((subtitle, index) => (
                    <div key={index}>
                        <Field name={`transcription.subtitles[${index}]`}>
                            {({ field }) => (
                                <Transcript
                                    transcript={field.value}
                                    onChange={(newSubtitle) =>
                                        setFieldValue(`${field.name}.subtitle`, newSubtitle)
                                    }
                                    active={
                                        field.value.start <= currentPlayTime &&
                                        currentPlayTime <= field.value.end
                                    }
                                />
                            )}
                        </Field>
                    </div>
                ))}
            </ScrollableStack>
        )}
    />
);

const TranscriptionEdit = ({ episode, values, setFieldValue }) => {
    const [currentPlayTime, setCurrentPlayTime] = useState(values.start);
    const [isPlaying, play, pause] = useSwitch();
    const [audioEditor, setAudioEditor] = useState(null);

    const handlePlayPause = () => {
        if (audioEditor) {
            audioEditor.player.playOrPauseSegment();
        }
    };

    const handleTimeUpdate = (time) => {
        setCurrentPlayTime(time);
    };
    const handleTimeUpdateThrottled = throttle(handleTimeUpdate, 250);

    return (
        <FetcherClipTranscription clipId={values.id}>
            {(isLoading) =>
                isLoading ? (
                    <Center intrinsic>
                        <Spinner />
                    </Center>
                ) : (
                    <>
                        {values.transcription ? (
                            <Center max="calc(600px + 2 * 1.25rem + 2 * 3rem)">
                                <Cluster $gap="0.25rem" $wrap="nowrap">
                                    <PlayPauseButton
                                        isPlaying={isPlaying}
                                        onClick={handlePlayPause}
                                        style={{ marginTop: '1.25rem' }}
                                    />
                                    <Stack $gap="1rem" css="flex-grow: 1;">
                                        <div style={{ marginLeft: '1rem', marginRight: '1rem' }}>
                                            <AudioWaveform
                                                audioUrl={episode.audioUrlInternal}
                                                waveformDataUrl={episode.waveformUrl}
                                                start={values.start}
                                                end={values.end}
                                                onInit={(newAudioEditor) =>
                                                    setAudioEditor(newAudioEditor)
                                                }
                                                onPlay={play}
                                                onPause={pause}
                                                onTimeUpdate={handleTimeUpdateThrottled}
                                            />
                                        </div>
                                        <DeprecatedAlert
                                            type="info"
                                            style={{ marginLeft: '1rem', marginRight: '1rem' }}
                                        >
                                            <FormattedMessage defaultMessage="Chaque champ de texte est modifiable pour vous permettre d'effectuer d'éventuelles corrections. Nous recommandons de ne pas ajouter ou supprimer des mots car les minutages ne sont pas modifiables." />
                                        </DeprecatedAlert>
                                        <TranscriptsFields
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            currentPlayTime={currentPlayTime}
                                        />
                                    </Stack>
                                    {/* Placeholder div to compensate play/pause button and keep everything centered */}
                                    <div style={{ height: '3rem', width: '3rem' }} />
                                </Cluster>
                            </Center>
                        ) : (
                            <TranscriptionError />
                        )}
                    </>
                )
            }
        </FetcherClipTranscription>
    );
};

export default TranscriptionEdit;
