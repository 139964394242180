import { SOCIAL_NETWORK_NAME, INSTAGRAM } from '@/shared/config/constants';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Text from '@/components/ui/atoms/Text';
import LoginButton from './LoginButton';
import Button from '@/components/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import useDeleteShowTemplateMutation from '@/queries/showTemplate/useDeleteShowTemplateMutation.hook';
import { useParams } from 'react-router';
import Stack from '@/components/ui/layout/Stack';
import TemplateForm from './TemplateForm';
import useInstagramShowTemplateQuery from '@/queries/showTemplate/useInstagramShowTemplateQuery';
import useUpdateShowTemplateMutation from '@/queries/showTemplate/useUpdateShowTemplateMutation.hook';
import { useBodyToastQueue } from '@/shared/hooks/useBodyToastQueue.hook';
import { InstagramTemplateFormSchema } from './TemplateForm/useFormSchema';
import { InstagramProvider } from '@/queries/provider/useInstagramProviderQuery';
import ExternalLink from '@/components/Link/ExternalLink';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import { USER_OPTIONS } from '@/shared/config/userOptions';
import UpgradeBadge from '@/components/ui/atoms/UpgradeBadge';
import RouterLink from '@/components/Link/RouterLink';

interface InstagramProps {
    provider: InstagramProvider | null;
}

const Instagram = ({ provider }: InstagramProps) => {
    const { showId } = useParams<{ showId: string }>();
    const { data: show } = useShowQuery(showId);
    const { data: template } = useInstagramShowTemplateQuery(showId);
    const deleteShowTemplate = useDeleteShowTemplateMutation();
    const updateShowTemplate = useUpdateShowTemplateMutation();
    const toast = useBodyToastQueue();
    const intl = useIntl();
    const hasAccessToInstagram =
        show?.userOptions?.includes(USER_OPTIONS.AUTOSHARING_INSTAGRAM) ?? false;

    const handleUnlink = () => {
        deleteShowTemplate.mutate({
            showId,
            provider: INSTAGRAM,
        });
    };

    const handleUpdate = (formData: InstagramTemplateFormSchema) => {
        const { messageTpl } = formData;
        updateShowTemplate.mutate(
            {
                showId,
                provider: INSTAGRAM,
                payload: {
                    params: { userId: provider?.account?.id },
                    messageTpl,
                },
            },
            {
                onSuccess: () => {
                    toast.success();
                },
                onError: () => {
                    toast.alert();
                },
            },
        );
    };

    return (
        <Container>
            <Header $isConnected={!!provider}>
                <Stack $gap="0.5rem">
                    <Provider>
                        <ProviderIcon icon={icon({ name: 'instagram', style: 'brands' })} />
                        <Text fontWeight="--fw-semibold" textTransform="capitalize">
                            {SOCIAL_NETWORK_NAME[INSTAGRAM]}
                        </Text>
                        {!hasAccessToInstagram && <UpgradeBadge pricing="boost" />}
                    </Provider>
                    {!provider && (
                        <Text>
                            <FormattedMessage
                                defaultMessage="Partagez votre podcast sur votre <strong>compte Instagram Business</strong>. {lineBreak}{readMoreLink}"
                                values={{
                                    lineBreak: <br />,
                                    strong: (chunks: string) => (
                                        <Text as="span" fontWeight="--fw-semibold">
                                            {chunks}
                                        </Text>
                                    ),
                                    readMoreLink: (
                                        <HelpLink
                                            href={intl.formatMessage({
                                                defaultMessage:
                                                    'https://help.ausha.co/fr/articles/5963614-comment-lier-mes-reseaux-sociaux-a-ausha#h_a0643053e2',
                                            })}
                                            target="_blank"
                                        >
                                            <FormattedMessage defaultMessage="En savoir plus" />
                                        </HelpLink>
                                    ),
                                }}
                            />
                        </Text>
                    )}
                </Stack>
                {provider ? (
                    <AccountWrapper>
                        <Text fontWeight="--fw-semibold">@{provider.account.username}</Text>
                        <Button
                            variant="link-primary"
                            onPress={handleUnlink}
                            isLoading={deleteShowTemplate.isLoading}
                        >
                            <FormattedMessage defaultMessage="Délier le compte" />
                        </Button>
                    </AccountWrapper>
                ) : hasAccessToInstagram ? (
                    <LoginButton />
                ) : (
                    <RouterLink
                        to="/app/subscription/offers"
                        variant="button-primary"
                        startIcon={
                            <RocketIcon icon={icon({ name: 'rocket-launch', style: 'solid' })} />
                        }
                    >
                        <FormattedMessage defaultMessage="Passez en offre Boost" />
                    </RouterLink>
                )}
            </Header>
            {provider && (
                <TemplateForm
                    onSubmit={handleUpdate}
                    template={template}
                    isLoading={updateShowTemplate.isLoading}
                />
            )}
        </Container>
    );
};

const Header = styled.div<{ $isConnected: boolean }>`
    display: flex;
    gap: 1rem;

    ${({ $isConnected }) =>
        $isConnected &&
        css`
            justify-content: space-between;
            align-items: center;
        `}

    ${({ $isConnected }) =>
        !$isConnected &&
        css`
            flex-direction: column;

            & > :last-child,
            & > :last-child:not(a) > :first-child {
                width: 100%;
            }
        `}

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        justify-content: space-between;
        align-items: center;
        flex-direction: row;

        & > :last-child,
        & > :last-child:not(a) > :first-child {
            width: 13rem;
        }
    }
`;
const Provider = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex-shrink: 0;
`;
const ProviderIcon = styled(FontAwesomeIcon)`
    width: 1.5rem;
    height: 1.5rem;
    color: var(--instagram);
`;
const Container = styled.div`
    background-color: var(--neutral50);
    border-radius: var(--r-s);
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
`;
const AccountWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
`;
const HelpLink = styled(ExternalLink)`
    color: var(--instagram);

    &:hover {
        color: var(--instagram);
        text-decoration: underline;
    }
`;
const RocketIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;

export default Instagram;
