import type { Campaign as CampaignT } from '@/queries/campaign/useCampaignsQuery';
import styled from 'styled-components';
import Campaign from './Campaign';

interface CampaignsProps {
    campaigns: CampaignT[];
}

const Campaigns = ({ campaigns }: CampaignsProps) => {
    return (
        <List>
            {campaigns.map((campaign) => (
                <Campaign key={campaign.id} campaign={campaign} />
            ))}
        </List>
    );
};

const List = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    padding: 0.5rem;
    border-radius: var(--r-l);
`;

export default Campaigns;
