import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
    updateSmartlinkSettings,
    updateCallToActionButtonsPositions,
    deleteShowLogo,
    uploadBackgroundCover,
} from '@/api';
import { decamelizeKeys } from 'humps';
import showListeningLinkKeys from '@queries/showListeningLink/showListeningLinkKeys';
import smartlinkSettingKeys from '@/queries/smartlinkSetting/smartlinkSettingKeys';
import callToActionKeys from '@/queries/callToAction/callToActionKeys';

interface ListeningLink {
    key: string;
    url: string;
    id: number;
    isHidden: boolean;
    position: number;
}

interface CTAButton {
    id: number;
    locations: string[];
    show_id: number;
    text: string;
    type: string;
    url: string;
}

interface SmartlinkSettings {
    accentColor: string;
    backgroundColor: string;
    coverUrl: string;
    isPlayerEnabled: boolean;
    isPublished: boolean;
    listeningLinks: ListeningLink[];
    ctaButtons: CTAButton[];
}

export interface UpdateSettingsProps {
    showId: string;
    defaultSettings: SmartlinkSettings;
    ctaLocation: string;
    IsCTAFeatureEnabled: boolean;
}

export interface MutationFnProps {
    ctaButtons: CTAButton[];
    coverUrl: string;
}

export default function useUpdateSmartlinkSettings({
    showId,
    defaultSettings,
    ctaLocation,
    IsCTAFeatureEnabled,
}: UpdateSettingsProps) {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ ctaButtons, coverUrl: file, ...data }: MutationFnProps) => {
            const promises = [];

            promises.push(updateSmartlinkSettings(showId, decamelizeKeys(data)));

            if (IsCTAFeatureEnabled && ctaButtons.length > 0) {
                promises.push(
                    updateCallToActionButtonsPositions(showId, {
                        location: ctaLocation,
                        ids: ctaButtons.map((cta) => cta.id),
                    }),
                );
            }

            if (file !== null && file !== defaultSettings.coverUrl) {
                promises.push(uploadBackgroundCover(showId, file, 'cover_smartlink'));
            } else if (defaultSettings.coverUrl !== null && file === null) {
                promises.push(deleteShowLogo(showId, 'cover_smartlink'));
            }

            return Promise.allSettled(promises);
        },
        onMutate: async () => {
            /**
             * Cancel any outgoing refetches
             * (so they don't overwrite our optimistic update)
             */
            await queryClient.cancelQueries({
                queryKey: smartlinkSettingKeys.detailByShowId(showId),
            });
            await queryClient.cancelQueries({
                queryKey: showListeningLinkKeys.listByShowId(showId),
            });
            await queryClient.cancelQueries({
                queryKey: ['shows.ctaButtons.index', showId, ctaLocation],
            });

            // Snapshot the previous value
            const settingsPreviousState = queryClient.getQueryData(
                smartlinkSettingKeys.detailByShowId(showId),
            );
            const listeningLinksPreviousState = queryClient.getQueryData(
                showListeningLinkKeys.listByShowId(showId),
            );
            const callToActionButtonsPreviousState = queryClient.getQueryData([
                'shows.ctaButtons.index',
                showId,
                ctaLocation,
            ]);

            // Return a context object with the snapshotted value
            return {
                settingsPreviousState,
                listeningLinksPreviousState,
                callToActionButtonsPreviousState,
            };
        },
        /**
         * If the mutation fails,
         * use the context returned from onMutate to roll back
         */
        onError: (_, __, context) => {
            queryClient.setQueryData(
                smartlinkSettingKeys.detailByShowId(showId),
                context?.settingsPreviousState,
            );
            queryClient.setQueryData(
                showListeningLinkKeys.listByShowId(showId),
                context?.listeningLinksPreviousState,
            );
            queryClient.setQueryData(
                ['shows.ctaButtons.index', showId, ctaLocation],
                context?.callToActionButtonsPreviousState,
            );
        },
        /**
         * Always refetch after error or success:
         */
        onSettled: () => {
            queryClient.invalidateQueries({
                queryKey: smartlinkSettingKeys.detailByShowId(showId),
            });
            queryClient.invalidateQueries({
                queryKey: showListeningLinkKeys.listByShowId(showId),
            });
            queryClient.invalidateQueries({
                queryKey: ['shows.ctaButtons.index', showId, ctaLocation],
            });
            queryClient.invalidateQueries({
                queryKey: callToActionKeys.detailByShowId(showId),
            });
        },
    });
}
