import { useState } from 'react';
import PropTypes from 'prop-types';
import { useCalendarGrid, useLocale } from 'react-aria';
import { getWeeksInMonth } from '@internationalized/date';
import CalendarCell from './CalendarCell';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import styled from 'styled-components';

dayjs.extend(localeData);

const CalendarGrid = ({ state, messages, startDate, endDate, ...props }) => {
    let { gridProps, headerProps, weekDays } = useCalendarGrid(
        { ...props, weekdayStyle: 'short' },
        state,
    );
    const { locale } = useLocale();
    let weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale);

    const [showSeeMoreAt, setShowSeeMoreAt] = useState(null);
    const [postDetailsShown, setPostDetailsShown] = useState(null);

    return (
        <CalendarGridWrapper {...gridProps}>
            <thead {...headerProps}>
                <tr>
                    {weekDays.map((day, index) => (
                        <Th key={index}>{day}</Th>
                    ))}
                </tr>
            </thead>
            <Tbody>
                {[...new Array(weeksInMonth).keys()].map((weekIndex) => (
                    <Tr key={weekIndex}>
                        {state
                            .getDatesInWeek(weekIndex)
                            .map((date) => ({
                                date,
                                messages: messages[dayjs(date).format('YYYYMMDD')]?.messages || [],
                            }))
                            .map((item, i) =>
                                item ? (
                                    <CalendarCell
                                        key={i}
                                        state={state}
                                        date={item.date}
                                        posts={item.messages}
                                        showSeeMoreAt={
                                            showSeeMoreAt &&
                                            dayjs(showSeeMoreAt).isSame(dayjs(item.date), 'day')
                                        }
                                        setShowSeeMoreAt={(date) => setShowSeeMoreAt(date)}
                                        postDetailsShown={postDetailsShown}
                                        setPostDetailsShown={(postId) => {
                                            setPostDetailsShown(postId);
                                        }}
                                        isLastRow={weekIndex === weeksInMonth - 1}
                                        startDate={startDate}
                                        endDate={endDate}
                                    />
                                ) : (
                                    <td key={i} />
                                ),
                            )}
                    </Tr>
                ))}
            </Tbody>
        </CalendarGridWrapper>
    );
};

const CalendarGridWrapper = styled.table`
    table-layout: fixed;
    width: 100%;
    height: 100%;
    border-collapse: collapse;

    & tbody {
        outline: 1px solid var(--neutral100);
        border-radius: var(--r-m);
    }
    & tr:nth-of-type(0n + 1) {
        border-radius: var(--r-m) var(--r-m) 0 0;
    }
    & tr:nth-of-type(0n + 1) > td:first-of-type,
    & tr:nth-of-type(0n + 1) > td:first-of-type > * {
        border-top-left-radius: var(--r-m);
    }
    & tr:nth-of-type(0n + 1) > td:last-of-type,
    & tr:nth-of-type(0n + 1) > td:last-of-type > * {
        border-top-right-radius: var(--r-m);
    }
    & tr:last-of-type > td:first-of-type,
    & tr:last-of-type > td:first-of-type > * {
        border-bottom-left-radius: var(--r-m);
    }
    & tr:last-of-type > td:last-of-type,
    & tr:last-of-type > td:last-of-type > * {
        border-bottom-right-radius: var(--r-m);
    }
`;
const Tbody = styled.tbody`
    border-radius: var(--r-m);
`;
const Tr = styled.tr`
    border-radius: var(--r-m);
`;
const Th = styled.th`
    color: var(--neutral500);
    font-weight: var(--fw-semibold);
    font-size: var(--fs-body-m);
    padding: 0.5rem;
`;

CalendarGrid.propTypes = {
    state: PropTypes.object,
    messages: PropTypes.arrayOf(PropTypes.object),
    startDate: PropTypes.string,
    endDate: PropTypes.string,
};

export default CalendarGrid;
