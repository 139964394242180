import { useParams } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Button from '@/components/Button';
import useRetryImportMutation from '@queries/rss/useRetryImportMutation.hook';
import { useBodyToastQueue } from '@/shared/hooks/useBodyToastQueue.hook';
import styled from 'styled-components';

interface StatusCellProps {
    id: number;
    state: string;
    triggerRefetch: () => void;
}

const stateColors = {
    done: '--success',
    error: '--alert',
    pending: '--neutral500',
    processing: '--black',
};
const stateIcons = {
    error: <FontAwesomeIcon icon={icon({ name: 'triangle-exclamation', style: 'solid' })} />,
    processing: <FontAwesomeIcon icon={icon({ name: 'spinner-third', style: 'solid' })} spin />,
};
const stateMessages = {
    done: <FormattedMessage defaultMessage="Importé" />,
    error: <FormattedMessage defaultMessage="Erreur" />,
    pending: <FormattedMessage defaultMessage="En attente..." />,
    processing: <FormattedMessage defaultMessage="En cours" />,
};

const StatusCell = ({ id, state, triggerRefetch }: StatusCellProps) => {
    const { showId } = useParams<{ showId: string }>();
    const toast = useBodyToastQueue();
    const retryImport = useRetryImportMutation();
    return (
        <StatusCellWrapper $color={stateColors[state as keyof typeof stateColors]}>
            <Status>
                {stateIcons[state as keyof typeof stateIcons]}
                {stateMessages[state as keyof typeof stateMessages]}
            </Status>
            {state === 'error' && (
                <Button
                    onPress={() => {
                        retryImport.mutate(
                            {
                                episodeId: `${id}`,
                                showId: `${showId}`,
                            },
                            {
                                onSuccess: () => {
                                    toast.success(
                                        <FormattedMessage defaultMessage="L'import des épisodes en erreur a bien été relancé. Si l'erreur persiste, n'hésitez pas à contacter notre support. 🙏" />,
                                    );
                                },
                                onSettled: () => triggerRefetch(),
                            },
                        );
                    }}
                    isLoading={retryImport.isLoading}
                    variant="danger"
                    startIcon={
                        <RetryIcon
                            icon={icon({
                                name: 'arrows-rotate',
                                style: 'solid',
                            })}
                        />
                    }
                >
                    <FormattedMessage defaultMessage="Réessayer" />
                </Button>
            )}
        </StatusCellWrapper>
    );
};

const StatusCellWrapper = styled.div<{ $color: string }>`
    display: flex;
    align-items: center;
    gap: 1.25rem;
    color: var(${(props) => props.$color});
    font-weight: var(--fw-semibold);
`;
const Status = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
`;
const RetryIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: var(--alert500);
`;

export default StatusCell;
