import styled from 'styled-components';

interface ProgressBarProps {
    score: number;
    thresholds?: [number, number, number];
    isReversed?: boolean;
    staticColor?: string;
    preciseRender?: boolean;
}

const colors = ['--alert', '--warning', '--success'];

const ProgressBar = ({
    score,
    thresholds,
    isReversed,
    staticColor,
    preciseRender,
}: ProgressBarProps) => {
    const t = thresholds || [0, 33, 66];
    const orderedColors = isReversed ? colors.slice().reverse() : colors;
    let closestThreshold = t[0];

    t.some((threshold, index) => {
        if (score < threshold) {
            closestThreshold = t[index - 1];
            return true;
        }
        closestThreshold = t[index];
    });

    const color = staticColor || orderedColors[t.indexOf(closestThreshold)];
    return (
        <Track>
            {/* If we get lower than 12% width, we loose the rounded corners, so we have to cheat a little bit :) */}
            <Progress progress={preciseRender ? score : Math.max(12, score)} color={color} />
        </Track>
    );
};

const Track = styled.div`
    width: 100%;
    height: 0.5rem;
    border-radius: var(--r-full);
    background-color: var(--neutral100);
`;
const Progress = styled.div<{ progress: number; color: string }>`
    height: 100%;
    border-radius: var(--r-full);

    ${({ progress }) => `width: ${progress}%;`}
    ${({ color }) => `background-color: var(${color});`}
`;

export default ProgressBar;
