import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import objectSupport from 'dayjs/plugin/objectSupport';

dayjs.extend(utc);
dayjs.extend(objectSupport);

export const getDatesFromPreviousMonth = (date: Date): Date[] => {
    let dates = [] as Date[];
    let currentMonthDayjs = dayjs(date);

    // Get the first day of the current month
    let firstDayCurrentMonth = currentMonthDayjs.startOf('month');
    let dayOfWeek = firstDayCurrentMonth.day();

    // If the first day of the month is Sunday, no days from the previous month are shown
    if (dayOfWeek === 0) {
        return dates;
    }

    // Calculate the dates from the previous month
    for (let i = 0; i < dayOfWeek; i++) {
        // Calculate the date to be pushed into the array
        let dateToAdd = firstDayCurrentMonth.subtract(dayOfWeek - i, 'day').toDate();
        dates.push(dateToAdd);
    }

    return dates;
};

export const getDatesFromNextMonth = (date: Date): Date[] => {
    let dates = [] as Date[];
    let currentMonthDayjs = dayjs(date);

    // Get the last day of the current month
    let lastDayCurrentMonth = currentMonthDayjs.endOf('month');
    let dayOfWeek = lastDayCurrentMonth.day();

    // If the last day of the month is Saturday, no days from the next month are shown
    if (dayOfWeek === 6) {
        return dates;
    }

    // Calculate the dates from the next month
    for (let i = 1; i <= 7 - dayOfWeek; i++) {
        // Calculate the date to be pushed into the array
        let dateToAdd = lastDayCurrentMonth.add(i, 'day').toDate();
        dates.push(dateToAdd);
    }

    return dates;
};

export const dateIsInTheFuture = (date: Date): boolean => {
    if (!date) return false;
    return dayjs(date).isAfter(dayjs());
};

export function formatDateForApi(date) {
    if (!date) return null;
    return dayjs(date).format('YYYY-MM-DD HH:mm:ss');
}

export function formatDateForApiUTC(date) {
    if (!date) return null;

    return dayjs(date).utc().format('YYYY-MM-DD HH:mm:ss');
}

// to normalize the input type=date we need to format it
// source: https://developer.mozilla.org/fr/docs/Web/HTML/Element/Input/date#valeur
export const formatDateInput = (dataValue) =>
    dayjs({
        year: dataValue.getFullYear(),
        month: dataValue.getMonth(),
        date: dataValue.getDate(),
    }).format('YYYY-MM-DD');
