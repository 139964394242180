import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Text from '@/components/ui/atoms/Text';
import { FormattedMessage } from 'react-intl';
import RouterLink from '@/components/Link/RouterLink';
import { useParams } from 'react-router';

const NoFeed = () => {
    const { showId } = useParams<{ showId: string }>();
    return (
        <Container>
            <MessageWrapper>
                <Icon icon={icon({ name: 'signal-stream-slash', style: 'solid' })} />
                <Message>
                    <Text variant="bodyL" fontWeight="--fw-bold">
                        <FormattedMessage defaultMessage="Votre flux RSS n'est pas encore redirigé." />
                    </Text>
                    <Text>
                        <FormattedMessage
                            defaultMessage="Pour récupérer tous les épisodes de votre émission, veuillez <link> rediriger votre flux RSS vers votre nouvel hébergeur</link>."
                            values={{
                                link: (chunks: React.ReactNode) => (
                                    <RouterLink to={`/app/show/${showId}/settings/rss-export`}>
                                        {chunks}
                                    </RouterLink>
                                ),
                            }}
                        />
                    </Text>
                </Message>
            </MessageWrapper>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding-mobile);
    height: 25rem;

    ${(props) => props.theme.mediaQueries.desktopAndUp} {
        padding: var(--container-padding);
    }
`;
const MessageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
    color: var(--neutral500);
`;
const Message = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 0.25rem;
`;
const Icon = styled(FontAwesomeIcon)`
    width: 1.5rem;
    height: 1.5rem;
`;

export default NoFeed;
