import Model from './Model';

class PeakTimePopularHoursModel extends Model {
    static attributes = {
        dayHour: 'day_hour',
        downloads: 'downloads',
    };
}

export default PeakTimePopularHoursModel;
