import { Redirect, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { USER_ROLES } from '@/shared/config/constants';
import NonHostingEpisodeAudioFileUploadPage from '../NonHostingEpisodeAudioFileUploadPage';
import NonHostingEpisodeFormPage from '../NonHostingEpisodeFormPage/NonHostingEpisodeFormPage';
import NoPermissionRoute from '../../../unorganized/NoPermissionRoute';
import { NonHostingEpisodeFileUploadContextProvider } from '@/components/NonHostingEpisodeForm/contexts/NonHostingEpisodeFileUploadContext';

const NonHostingEpisodeFormPages = () => {
    const { path } = useRouteMatch();
    const { showId } = useParams<{ showId: string }>();

    return (
        <NonHostingEpisodeFileUploadContextProvider>
            <Switch>
                <NoPermissionRoute exact roles={[USER_ROLES.VIEWER]} path={`${path}/upload`}>
                    <NonHostingEpisodeAudioFileUploadPage />
                </NoPermissionRoute>
                <NoPermissionRoute roles={[USER_ROLES.VIEWER]} path={`${path}/:episodeId(\\d+)`}>
                    <NonHostingEpisodeFormPage />
                </NoPermissionRoute>
                <Redirect to={`/app/show/${showId}/workspace`} />
            </Switch>
        </NonHostingEpisodeFileUploadContextProvider>
    );
};

export default NonHostingEpisodeFormPages;
