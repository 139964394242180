import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';

interface KpiProps {
    title: React.ReactNode;
    isLoading: boolean;
    children: React.ReactNode;
}

const Kpi = ({ title, isLoading, children }: KpiProps) => {
    if (isLoading) {
        return (
            <KpiWrapper>
                <LoadingIcon icon={icon({ name: 'spinner-third', style: 'solid' })} spin />
            </KpiWrapper>
        );
    }

    return (
        <KpiWrapper>
            <Title>{title}</Title>
            <ValueWrapper>{children}</ValueWrapper>
        </KpiWrapper>
    );
};

const KpiWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
`;
const Title = styled.span`
    color: var(--neutral500);
`;
const ValueWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;
const LoadingIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
`;

export default Kpi;
