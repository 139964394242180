import { useMutation } from '@tanstack/react-query';
import { login } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import { decamelizeKeys } from 'humps';
import { z } from 'zod';

const LoginResponseSchema = z.object({
    accessToken: z.string(),
    expiresIn: z.number(),
    refreshToken: z.string(),
    tokenType: z.string(),
});
export type LoginResponse = z.infer<typeof LoginResponseSchema>;

interface MutationFnProps {
    formData: {
        email: string;
        password: string;
    };
}

const mutationFn = async ({ formData }: MutationFnProps) => {
    const data = await login(
        decamelizeKeys({
            ...formData,
        }),
    );

    return LoginResponseSchema.parse(camelcaseKeys(data, { deep: true }));
};

const useClubLoginMutation = () => {
    return useMutation({
        mutationFn,
    });
};

export default useClubLoginMutation;
