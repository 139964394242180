import useChannelQuery from '@/queries/channel/useChannelQuery.hook';
import ChannelGuestsInvitationForm from './ChannelGuestsInvitationForm';
import addChannelGuestInvitationMutation from '@/queries/guest/addChannelGuestInvitationMutation';
import { AxiosError } from 'axios';
import { ChannelGuestsInvitationFormSchema } from './ChannelGuestsInvitationForm/useChannelGuestsInvitationFormSchema.hook';
import useActiveShowsQuery from '@/queries/show/useActiveShowsQuery.hook';
import ChannelGuestsInvitationFormSkeleton from './ChannelGuestsInvitationFormSkeleton';

interface ChannelGuestsInvitationProps {
    onPermissionError: () => void;
    onClose: () => void;
}

const ChannelGuestsInvitation = ({ onPermissionError, onClose }: ChannelGuestsInvitationProps) => {
    const channel = useChannelQuery();
    const shows = useActiveShowsQuery();
    const addInvitation = addChannelGuestInvitationMutation();

    const handleInvite = (formData: ChannelGuestsInvitationFormSchema) => {
        if (!channel.data?.id) return;

        addInvitation.mutate(
            { channelId: channel.data.id, invitation: formData },
            {
                onSuccess: onClose,
                onError: (error: unknown) => {
                    if ((error as AxiosError)?.response?.status === 400) {
                        onPermissionError();
                    }
                },
            },
        );
    };

    // TODO: Handle error state
    if (channel.isError || shows.isError) {
        return <p>ERROR</p>;
    }

    if (channel.isLoading || shows.isLoading) {
        return <ChannelGuestsInvitationFormSkeleton />;
    }

    return (
        <ChannelGuestsInvitationForm
            onSubmit={handleInvite}
            onClose={onClose}
            shows={shows.data.shows}
        />
    );
};

export default ChannelGuestsInvitation;
