import { useIntl } from 'react-intl';
import { useFormContext } from 'react-hook-form';
import { useState } from 'react';
import AudioWaveform from '@/components/unorganized/ClipEditWizard/AudioSampleSelection/AudioWaveform';
import IconButton from '@ui/atoms/IconButton';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import IconSkipPrevious from '@ui/icons/IconSkipPrevious';
import Cluster from '@ui/layout/Cluster';
import { formatTime } from '@/helpers';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Text from '@ui/atoms/Text';
import Stack from '@ui/layout/Stack';
import { useAccordionItem } from '@ui/atoms/AccordionItem/useAccordionItem.hook';
import AddChapterButton from './AddChapterButton';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;

    ${(p) => p.theme.mediaQueries.desktopLargeAndUp} {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
`;

const PlayPauseIcon = styled(FontAwesomeIcon)`
    width: 1.25rem;
    height: 1.25rem;
    color: var(--white);
`;

const TimeCodeText = styled(Text)`
    font-variant-numeric: tabular-nums;
`;

const PlayPauseButton = ({ isPlaying, pause, play }) => {
    const intl = useIntl();

    const onPress = () => {
        if (isPlaying) {
            pause();
        } else {
            play();
        }
    };

    return (
        <IconButton
            aria-label={
                isPlaying
                    ? intl.formatMessage({ defaultMessage: 'Pause' })
                    : intl.formatMessage({ defaultMessage: 'Lecture' })
            }
            icon={
                isPlaying ? (
                    <PlayPauseIcon
                        icon={icon({
                            name: 'pause',
                            style: 'solid',
                        })}
                    />
                ) : (
                    <PlayPauseIcon
                        icon={icon({
                            name: 'play',
                            style: 'solid',
                        })}
                    />
                )
            }
            onPress={onPress}
            isRound
        />
    );
};

const BackwardButton = ({ onPress }) => {
    const intl = useIntl();

    return (
        <IconButton
            aria-label={intl.formatMessage({ defaultMessage: 'Retour au début' })}
            variant="secondary"
            icon={<IconSkipPrevious />}
            onClick={onPress}
            isRound
            size="small"
        />
    );
};

const ChaptersAudioPlayer = ({
    audioUrl,
    waveformUrl,
    selectedChapterId,
    onSelectChapter,
    onAddChapter,
    onUpdateChapterStartTime,
    cursorPosition,
    cursorPositionHasChanged,
    isPlaying,
    play,
    pause,
}) => {
    const intl = useIntl();
    const { watch } = useFormContext();
    const { isExpanded } = useAccordionItem();

    const [goBackToStart, setGoBackToStart] = useState(false);
    const [audioFileDuration, setAudioFileDuration] = useState();
    const [currentTime, setCurrentTime] = useState(0);

    const chapters = watch('chapters', []);
    const sortedChapters = chapters
        .toSorted((chapter1, chapter2) => chapter1.startTime - chapter2.startTime)
        .map((chapter, index) => ({ ...chapter, number: index + 1 }));

    const handleAddChapter = () => {
        const newChapterStartTime = currentTime;
        const closestChapter = sortedChapters
            .toReversed()
            // Going through the chapters in reverse order, find the first one to begin before the current time
            .find((chapter) => chapter.startTime <= newChapterStartTime);
        const newChapterNumber = closestChapter ? closestChapter.number + 1 : 1;
        const newChapterId = `local-chapter-${crypto.randomUUID()}`;
        const newChapter = {
            id: newChapterId,
            title: intl.formatMessage(
                { defaultMessage: 'Chapitre {chapterNumber}' },
                { chapterNumber: newChapterNumber },
            ),
            startTime: newChapterStartTime,
            imageUrl: null,
            url: null,
        };

        onAddChapter(newChapter);
    };

    const handleGoBackToStart = () => {
        setGoBackToStart(!goBackToStart);
        if (!isPlaying) play();
    };

    return (
        <Stack $gap="1rem">
            {/* Wavesurfer needs the chapters accordion item to be opened to draw the canvas */}
            {isExpanded && (
                <AudioWaveform
                    audioUrl={audioUrl}
                    waveformDataUrl={waveformUrl}
                    isPlaying={isPlaying}
                    onGoBackToStart={goBackToStart}
                    duration={audioFileDuration}
                    onDurationChange={setAudioFileDuration}
                    onCurrentTimeChange={setCurrentTime}
                    cursorPosition={cursorPosition}
                    cursorPositionHasChanged={cursorPositionHasChanged}
                    chapters={sortedChapters}
                    selectedChapter={selectedChapterId}
                    onChapterSelect={onSelectChapter}
                    onChapterUpdate={onUpdateChapterStartTime}
                    noRegion
                />
            )}
            <Container>
                <Cluster $gap="1rem" $align="center">
                    <PlayPauseButton isPlaying={isPlaying} play={play} pause={pause} />
                    <BackwardButton onPress={handleGoBackToStart} />
                    <Cluster $gap="0.5rem">
                        <TimeCodeText>{formatTime(currentTime, 'milliseconds')}</TimeCodeText>
                        <TimeCodeText color="--neutral500">/</TimeCodeText>
                        <TimeCodeText color="--neutral500">
                            {formatTime(audioFileDuration, 'milliseconds')}
                        </TimeCodeText>
                    </Cluster>
                </Cluster>
                <AddChapterButton onPress={handleAddChapter} />
            </Container>
        </Stack>
    );
};

export default ChaptersAudioPlayer;
