import { css } from 'styled-components';
import PropTypes from 'prop-types';
import { computeCssValue } from '../css';
import { resolveColor } from '@/utils/cssVariables';

/**
 * See https://chakra-ui.com/docs/features/style-props
 */
const borderMixin = css`
    border: ${(props) => props.$border};
    border-width: ${(props) => computeCssValue(props.$borderWidth)};
    border-style: ${(props) => props.$borderStyle};
    border-color: ${(props) => resolveColor(props.$borderColor)};

    /* Top */
    border-top: ${(props) => resolveColor(props.$borderTop)};
    border-top-width: ${(props) => computeCssValue(props.$borderTopWidth)};
    border-top-style: ${(props) => props.$borderTopStyle};
    border-top-color: ${(props) => resolveColor(props.$borderTopColor)};

    /* Right / End */
    border-right: ${(props) => resolveColor(props.$borderRight)};
    border-inline-end: ${(props) => resolveColor(props.$borderEnd)};
    border-right-width: ${(props) => computeCssValue(props.$borderRightWidth)};
    border-inline-end-width: ${(props) => computeCssValue(props.$borderEndWidth)};
    border-right-style: ${(props) => props.$borderRightStyle};
    border-inline-end-style: ${(props) => props.$borderEndStyle};
    border-right-color: ${(props) => resolveColor(props.$borderRightColor)};
    border-inline-end-color: ${(props) => resolveColor(props.$borderEndColor)};

    /* Bottom */
    border-bottom: ${(props) => resolveColor(props.$borderBottom)};
    border-bottom-width: ${(props) => computeCssValue(props.$borderBottomWidth)};
    border-bottom-style: ${(props) => props.$borderBottomStyle};
    border-bottom-color: ${(props) => resolveColor(props.$borderBottomColor)};

    /* Left / Start */
    border-left: ${(props) => resolveColor(props.$borderLeft)};
    border-inline-start: ${(props) => resolveColor(props.$borderStart)};
    border-left-width: ${(props) => computeCssValue(props.$borderLeftWidth)};
    border-inline-start-width: ${(props) => computeCssValue(props.$borderStartWidth)};
    border-left-style: ${(props) => props.$borderLeftStyle};
    border-inline-start-style: ${(props) => props.$borderStartStyle};
    border-left-color: ${(props) => resolveColor(props.$borderLeftColor)};
    border-inline-start-color: ${(props) => resolveColor(props.$borderStartColor)};

    ${(props) =>
        props.$borderX !== null &&
        props.$borderX !== undefined &&
        css`
            border-left: ${(props) => `var(${props.$borderX})`};
            border-right: ${(props) => `var(${props.$borderX})`};
        `};
    ${(props) =>
        props.$borderY !== null &&
        props.$borderY !== undefined &&
        css`
            border-top: ${(props) => `var(${props.$borderY})`};
            border-bottom: ${(props) => `var(${props.$borderY})`};
        `};

    /* Radius */
    border-radius: ${(props) => `var(${props.$borderRadius})`};
    border-top-left-radius: ${(props) => `var(${props.$borderTopLeftRadius})`};
    border-top-right-radius: ${(props) => `var(${props.$borderTopRightRadius})`};
    border-bottom-right-radius: ${(props) => `var(${props.$borderBottomRightRadius})`};
    border-bottom-left-radius: ${(props) => `var(${props.$borderBottomLeftRadius})`};

    ${(props) =>
        props.$borderTopRadius !== null &&
        props.$borderTopRadius !== undefined &&
        css`
            border-top-left-radius: ${(props) => `var(${props.$borderTopRadius})`};
            border-top-right-radius: ${(props) => `var(${props.$borderTopRadius})`};
        `};
    ${(props) =>
        props.$borderRightRadius !== null &&
        props.$borderRightRadius !== undefined &&
        css`
            border-top-right-radius: ${(props) => `var(${props.$borderRightRadius})`};
            border-bottom-right-radius: ${(props) => `var(${props.$borderRightRadius})`};
        `};
    ${(props) =>
        props.$borderBottomRadius !== null &&
        props.$borderBottomRadius !== undefined &&
        css`
            border-bottom-left-radius: ${(props) => `var(${props.$borderBottomRadius})`};
            border-bottom-right-radius: ${(props) => `var(${props.$borderBottomRadius})`};
        `};
    ${(props) =>
        props.$borderLeftRadius !== null &&
        props.$borderLeftRadius !== undefined &&
        css`
            border-top-left-radius: ${(props) => `var(${props.$borderLeftRadius})`};
            border-bottom-left-radius: ${(props) => `var(${props.$borderLeftRadius})`};
        `};
`;

export const borderPropTypes = {
    $border: PropTypes.string,
    $borderWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $borderStyle: PropTypes.string,
    $borderColor: PropTypes.string,
    // Top
    $borderTop: PropTypes.string,
    $borderTopWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $borderTopStyle: PropTypes.string,
    $borderTopColor: PropTypes.string,
    // Right / End
    $borderRight: PropTypes.string,
    $borderEnd: PropTypes.string,
    $borderRightWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $borderEndWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $borderRightStyle: PropTypes.string,
    $borderEndStyle: PropTypes.string,
    $borderRightColor: PropTypes.string,
    $borderEndColor: PropTypes.string,
    // Bottom
    $borderBottom: PropTypes.string,
    $borderBottomWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $borderBottomStyle: PropTypes.string,
    $borderBottomColor: PropTypes.string,
    // Left / Start
    $borderLeft: PropTypes.string,
    $borderStart: PropTypes.string,
    $borderLeftWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $borderStartWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $borderLeftStyle: PropTypes.string,
    $borderStartStyle: PropTypes.string,
    $borderLeftColor: PropTypes.string,
    $borderStartColor: PropTypes.string,

    $borderX: PropTypes.string,
    $borderY: PropTypes.string,

    // Radius
    $borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $borderTopLeftRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $borderTopRightRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $borderBottomRightRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $borderBottomLeftRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $borderTopRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $borderRightRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $borderBottomRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $borderLeftRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default borderMixin;
