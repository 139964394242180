import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteShowListeningLink } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import showKeys from './showKeys';

export interface MutationFnProps {
    showId: string;
    platform: string;
}

const mutationFn = async ({ showId, platform }: MutationFnProps) => {
    const { data } = await deleteShowListeningLink(showId, platform);
    return camelcaseKeys(data, { deep: true });
};

const useDeleteShowListeningLinkMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries({
                queryKey: showKeys.detailById(variables.showId),
            });
        },
    });
};

export default useDeleteShowListeningLinkMutation;
