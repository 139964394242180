import { z } from 'zod';

const useClipTranscriptionSchema = () => {
    return z.object({
        end: z.number(),
        start: z.number(),
        subtitles: z.array(
            z.object({
                start: z.number(),
                end: z.number(),
                subtitle: z.string(),
            }),
        ),
    });
};

export type ClipTranscriptionSchema = z.infer<ReturnType<typeof useClipTranscriptionSchema>>;

export default useClipTranscriptionSchema;
