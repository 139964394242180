import { FormattedMessage, useIntl } from 'react-intl';
import { Controller, useFormContext } from 'react-hook-form';
import Cluster from '@ui/layout/Cluster';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import EpisodeEditTooltip from '@app/molecules/EpisodeEditTooltip';
import UpgradeBadge from '@ui/atoms/UpgradeBadge';
import { PRICING } from '@/utils/pricing';
import Stack from '@ui/layout/Stack';
import Text from '@ui/atoms/Text';
import { InputTextController } from '@ui/atoms/InputText';
import styled from 'styled-components';
import Button from '@/components/Button';
import IconButton from '@ui/atoms/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';

const EpisodeEditFormContentCustomUrlField = ({ isAllowed = false }) => {
    const intl = useIntl();
    const { control, watch, unregister } = useFormContext();

    const useCustomUrl = watch('content.useCustomUrl');

    useEffect(() => {
        if (!useCustomUrl) {
            // If useCustomUrl field is false, customUrl field data should not be included in data sent to the API
            // customUrl field is unregistered to remove data
            unregister('content.customUrl');
        }
    }, [useCustomUrl]);

    if (!useCustomUrl) {
        return (
            <Cluster $align="center" $gap="0.5rem" $wrap="nowrap">
                <Cluster $align="center" $gap="0.25rem" $wrap="nowrap">
                    <Controller
                        name="content.useCustomUrl"
                        control={control}
                        render={({ field: { onChange } }) => (
                            <AddCustomUrlButton
                                onPress={() => onChange(true)}
                                isDisabled={!isAllowed}
                                variant="ghost"
                                startIcon={
                                    <AddCustomUrlButtonIcon
                                        icon={icon({
                                            name: 'add',
                                            style: 'solid',
                                        })}
                                    />
                                }
                            >
                                <FormattedMessage defaultMessage="Ajouter un lien personnalisé" />
                            </AddCustomUrlButton>
                        )}
                    />
                    <EpisodeEditTooltip>
                        <FormattedMessage defaultMessage="Ce lien remplacera le lien Ausha sur votre Smartlink, Smartplayer, Newsletter, ainsi que lors de partages sur les réseaux sociaux." />
                    </EpisodeEditTooltip>
                </Cluster>
                {!isAllowed && <UpgradeBadge pricing={PRICING.BOOST} />}
            </Cluster>
        );
    }

    return (
        <Stack $gap="0.5rem">
            <Cluster $align="center" $gap="0.25rem" $wrap="nowrap">
                <Text fontWeight="--fw-semibold">
                    <FormattedMessage defaultMessage="Lien de l'épisode personnalisé" />
                </Text>
                <EpisodeEditTooltip>
                    <FormattedMessage defaultMessage="Ce lien remplacera le lien Ausha sur votre Smartlink, Smartplayer, Newsletter, ainsi que lors de partages sur les réseaux sociaux." />
                </EpisodeEditTooltip>
            </Cluster>
            <Cluster $align="center" $justify="space-between" $gap="1rem" $wrap="nowrap">
                <InputTextController
                    name="content.customUrl"
                    control={control}
                    placeholder={intl.formatMessage({
                        defaultMessage: 'Saisissez votre lien personnalisé',
                    })}
                />
                <Controller
                    name="content.useCustomUrl"
                    control={control}
                    render={({ field: { onChange } }) => (
                        <RemoveCustomUrlButton onPress={() => onChange(false)}>
                            <RemoveCustomUrlButtonIcon
                                icon={icon({
                                    name: 'trash',
                                    style: 'solid',
                                })}
                            />
                        </RemoveCustomUrlButton>
                    )}
                />
            </Cluster>
        </Stack>
    );
};

const AddCustomUrlButton = styled(Button)<{ isDisabled: boolean }>`
    color: var(--primary);
    padding: 0;
    ${(props) => props.isDisabled && 'color: var(--neutral500);'}
`;

const AddCustomUrlButtonIcon = styled(FontAwesomeIcon)`
    color: inherit;
    width: 0.75rem;
    height: 0.75rem;
`;

const RemoveCustomUrlButton = styled(IconButton)`
    display: block;
    padding: 0;
    background: none;
    box-shadow: none;
`;

const RemoveCustomUrlButtonIcon = styled(FontAwesomeIcon)`
    color: var(--alert500);
    width: 1rem;
    height: 1rem;
`;

export default EpisodeEditFormContentCustomUrlField;
