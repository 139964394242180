import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { useLocation, useParams } from 'react-router';
import Sidebar from '@/shared/components/Sidebar';
import SidebarItem from '@/shared/components/Sidebar/Item/Item';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RouterLink from '@/components/Link/RouterLink';
import { useGuard } from '@/shared/hooks/useGuard.hook';
import { PRICING } from '@/shared/config/pricing';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import { USER_ROLES } from '@/shared/config/constants';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';
import { TooltipTrigger } from 'react-aria-components';

interface CampaignsLayoutProps {
    children: React.ReactNode;
}

const CampaignsLayout = ({ children }: CampaignsLayoutProps) => {
    const location = useLocation();
    const { showId }: { showId: string } = useParams();
    const { data: show } = useShowQuery(showId);
    const isShowFrench = show?.languageId === 47;

    const freemiumGuard = useGuard({
        disallowedPlans: [PRICING.FREEMIUM],
    });
    const launchGuard = useGuard({
        disallowedPlans: [PRICING.FREEMIUM, PRICING.LAUNCH],
    });
    const roleGuard = useGuard({
        disallowedUserRoles: [USER_ROLES.VIEWER, USER_ROLES.EDITOR, USER_ROLES.ADMIN],
    });

    return (
        <CampaignsLayoutWrapper>
            <SidebarWrapper>
                <Title>
                    {location.pathname.includes('auto') ? (
                        isShowFrench ? (
                            <FormattedMessage defaultMessage="Automatique" />
                        ) : (
                            <FormattedMessage defaultMessage="Partenariat" />
                        )
                    ) : (
                        <FormattedMessage defaultMessage="Manuelle" />
                    )}
                </Title>
                <Sidebar>
                    <TooltipTrigger delay={0} closeDelay={0} isDisabled={roleGuard.allow}>
                        <TooltipTriggerWrapper>
                            <SidebarItem
                                path={`/app/show/${showId}/monetize/auto`}
                                isDisabled={!roleGuard.allow}
                            >
                                <SidebarIcon
                                    icon={icon({ name: 'money-bill-transfer', style: 'solid' })}
                                />
                                <StyledRouterLink to={`/app/show/${showId}/monetize/auto`}>
                                    {isShowFrench ? (
                                        <FormattedMessage defaultMessage="Automatique" />
                                    ) : (
                                        <FormattedMessage defaultMessage="Partenariat" />
                                    )}
                                </StyledRouterLink>
                                {!freemiumGuard.allow && (
                                    <DiamondIcon icon={icon({ name: 'gem', style: 'duotone' })} />
                                )}
                            </SidebarItem>
                        </TooltipTriggerWrapper>
                        <Tooltip placement="top">
                            <FormattedMessage defaultMessage="Seul le propriétaire de l'émission peut accéder à la monétisation automatique." />
                        </Tooltip>
                    </TooltipTrigger>
                    <SidebarItem path={`/app/show/${showId}/monetize/manual`}>
                        <SidebarIcon icon={icon({ name: 'hand-holding-dollar', style: 'solid' })} />
                        <StyledRouterLink to={`/app/show/${showId}/monetize/manual`}>
                            <FormattedMessage defaultMessage="Manuelle" />
                        </StyledRouterLink>
                        {!launchGuard.allow && (
                            <DiamondIcon icon={icon({ name: 'gem', style: 'duotone' })} />
                        )}
                    </SidebarItem>
                </Sidebar>
            </SidebarWrapper>
            <ContentWrapper>{children}</ContentWrapper>
        </CampaignsLayoutWrapper>
    );
};
const CampaignsLayoutWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    width: 100%;
    padding-inline: var(--container-padding-mobile);
    margin-block: 2rem;
    justify-content: center;
    max-width: var(--container-width);

    @media (min-width: 75rem) {
        flex-direction: row;
        padding-inline: var(--container-padding);
    }
`;
const ContentWrapper = styled.div`
    display: flex;
    height: max-content;
    flex-direction: column;
    gap: 1rem;
    flex: 1;

    @media (min-width: 75rem) {
        max-width: calc(100% - 17rem); // 15rem sidebar width + 2rem gap
    }
`;
const SidebarWrapper = styled.aside`
    display: flex;
    row-gap: 1rem;
    flex-direction: column;
    width: 100%;

    @media (min-width: 75rem) {
        row-gap: 2rem;
        max-width: 15rem;
        position: sticky;
        top: 8.5rem;
        flex-shrink: 0;
    }
`;
const Title = styled.h2`
    font-weight: var(--fw-bold);
    font-size: var(--fs-heading-l);
`;
const SidebarIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
`;
const StyledRouterLink = styled(RouterLink)`
    transition: color 0.2s;
    color: inherit;
`;
const DiamondIcon = styled(FontAwesomeIcon)`
    color: var(--primary);
    width: 0.75rem;
    height: 0.75rem;
`;

export default CampaignsLayout;
