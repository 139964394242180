import { connect } from '@/components/legacy/connect';
import { FormattedMessage } from 'react-intl';
import DeprecatedIcon from '@ui/atoms/DeprecatedIcon';
import Spinner from '@ui/atoms/Spinner';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import Desktop from '@ui/molecules/Desktop';
import exportIcon from '@public/icons/external-link.svg';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import { useStatsDateSelectorContext } from '@/context/StatsDateSelectorContext';

const enhance = connect(({ sourcesStatsStore, routerStore }) => {
    const { showId, itemType, itemId } = routerStore.params;
    const { startDate, endDate } = useStatsDateSelectorContext();
    return {
        fetchCsv: () =>
            sourcesStatsStore.downloadSourcesCsv(showId, itemType, itemId, {
                start: startDate,
                end: endDate,
                podcasts: routerStore.query.episode,
            }),
        isLoading: sourcesStatsStore.downloadSourcesCsv.pending,
    };
});

function ListenersExportButton({ fetchCsv, isLoading, ...props }) {
    return (
        <Desktop>
            <DeprecatedPaper flex justify="flex-end" {...props}>
                <DeprecatedText
                    color="--primary"
                    weight="semibold"
                    onClick={fetchCsv}
                    disabled={isLoading}
                    flex
                    align="center"
                >
                    {isLoading ? (
                        <Spinner color="--primary100" mr={10} />
                    ) : (
                        <DeprecatedIcon as={exportIcon} color="--primary" mr={10} />
                    )}
                    <FormattedMessage defaultMessage="Exporter en .csv" />
                </DeprecatedText>
            </DeprecatedPaper>
        </Desktop>
    );
}

export default enhance(ListenersExportButton);
