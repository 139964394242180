import { connect } from '@/components/legacy/connect';
import NoResultCampaigns from './NoResultCampaigns';
import CampaignLine from './CampaignLine';

const enhance = connect(({ campaignStore }, { search }) => ({
    noResult: search !== '' && campaignStore.campaigns.length === 0,
    campaigns: campaignStore.campaigns,
}));

function CampaignLines({ search, noResult, campaigns }) {
    if (noResult) {
        return <NoResultCampaigns search={search} />;
    }
    return campaigns.map((campaign) => <CampaignLine key={campaign.id} campaign={campaign} />);
}

export default enhance(CampaignLines);
