import { Children, cloneElement } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 1.5rem;
`;

const Checklist = ({ children, ...otherProps }) => {
    const childrenArray = Children.toArray(children);
    const checks = childrenArray.map((check) => cloneElement(check, { ...check.props }));

    return <Container {...otherProps}>{checks}</Container>;
};

Checklist.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Checklist;
