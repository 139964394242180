import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import Checkbox from '@/components/ui/Checkbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Button from '@/components/Button';
import { useParams } from 'react-router';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import { USER_ROLES } from '@/shared/config/constants';
import styled from 'styled-components';
import { useModalToastQueue } from '@/shared/hooks/useModalToastQueue.hook';
import DeleteCampaignsModal from './DeleteCampaignsModal';
import { useSelectionContext } from '@/features/Campaigns/services/SelectionContext';
import useDeleteMultipleCampaignsMutation from '@/queries/campaign/deleteMultipleCampaignsMutation';

interface SelectionToolbarProps {
    hasSeveralPages: boolean;
    totalCampaignCount: number;
    campaignsPerPage: number;
}

const SelectionToolbar = ({
    hasSeveralPages,
    totalCampaignCount,
    campaignsPerPage,
}: SelectionToolbarProps) => {
    const { showId } = useParams<{ showId: string }>();
    const { data: show } = useShowQuery(showId);
    const toast = useModalToastQueue();
    const { allSelectedOnPage, allSelected, toggleAllOnPage, selected, toggleAll, resetSelection } =
        useSelectionContext();
    const [campaignCount, setCampaignCount] = useState(selected?.length || 0);
    const [hasSelectedAtLeastOne, setHasSelectedAtLeastOne] = useState(
        allSelected || selected.length > 0,
    );

    useEffect(() => {
        if (allSelected) {
            setCampaignCount(totalCampaignCount);
            return;
        }
        if (allSelectedOnPage) {
            setCampaignCount(campaignsPerPage);
            return;
        }
        setCampaignCount(selected?.length || 0);
    }, [selected, allSelected, allSelectedOnPage]);

    useEffect(() => {
        if (!allSelected && !allSelectedOnPage && selected.length === 0) {
            setHasSelectedAtLeastOne(false);
            return;
        }
        setHasSelectedAtLeastOne(true);
    }, [selected, allSelected, allSelectedOnPage]);

    const deleteMultipleCampaigns = useDeleteMultipleCampaignsMutation();

    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

    const userRole = show?.userRole;
    const userHasWriteAccess = ![USER_ROLES.VIEWER, USER_ROLES.EDITOR].includes(
        userRole as 'viewer' | 'editor',
    );
    const showIsArchived = show?.archived;

    if (userRole === USER_ROLES.VIEWER || showIsArchived) return null;

    const handleDeleteCampaigns = () => {
        if (!selected || selected.length === 0) return;

        deleteMultipleCampaigns.mutate(
            { campaignIds: selected },
            {
                onSuccess: () => {
                    setDeleteModalOpen(false);
                    resetSelection();
                    toast.success(
                        <FormattedMessage
                            defaultMessage="{campaignCount, plural, one {La campagne a bien été supprimée} other {Les campagnes ont bien été supprimées}}."
                            values={{ campaignCount }}
                        />,
                    );
                },
                onError: () => {
                    toast.alert();
                },
            },
        );
    };

    console.log(selected);

    return (
        <>
            <SelectionToolbarWrapper>
                <SelectCheckbox
                    id="selectAll"
                    $hasSelectedAtLeastOne={hasSelectedAtLeastOne}
                    isSelected={allSelected || allSelectedOnPage}
                    onChange={toggleAllOnPage}
                />
                {hasSelectedAtLeastOne ? (
                    <ActionWrapper>
                        {userHasWriteAccess && (
                            <DestructiveActionButton
                                variant="ghost"
                                onPress={() => setDeleteModalOpen(true)}
                                startIcon={
                                    deleteMultipleCampaigns.isLoading ? (
                                        <FontAwesomeIcon
                                            icon={icon({ name: 'spinner-third', style: 'solid' })}
                                            spin
                                        />
                                    ) : (
                                        <FontAwesomeIcon
                                            icon={icon({ name: 'trash', style: 'solid' })}
                                        />
                                    )
                                }
                            >
                                <FormattedMessage defaultMessage="Supprimer" />
                            </DestructiveActionButton>
                        )}
                    </ActionWrapper>
                ) : (
                    <Label htmlFor="selectAll">
                        <FormattedMessage defaultMessage="Tout sélectionner" />
                    </Label>
                )}
                {hasSeveralPages &&
                    (allSelectedOnPage || allSelected) &&
                    (allSelected ? (
                        <ToggleAllOuterWrapper>
                            <ToggleAllWrapper>
                                <FormattedMessage
                                    defaultMessage="{count, plural, one {La {countText} de votre émission est sélectionnée} other {Les {countText} de votre émission sont sélectionnées}}."
                                    values={{
                                        countText: (
                                            <strong>
                                                <FormattedMessage
                                                    defaultMessage="{count, plural, one {campagne} other {# campagnes}}"
                                                    values={{ count: totalCampaignCount }}
                                                />
                                            </strong>
                                        ),
                                        count: totalCampaignCount,
                                    }}
                                />
                            </ToggleAllWrapper>
                            <ToggleButton variant="ghost" onPress={toggleAll}>
                                <FormattedMessage defaultMessage="Réinitialiser la sélection" />
                            </ToggleButton>
                        </ToggleAllOuterWrapper>
                    ) : (
                        <ToggleAllOuterWrapper>
                            <ToggleAllWrapper>
                                <FormattedMessage
                                    defaultMessage="{count, plural, one {La {countText} de la page est sélectionnée} other {Les {countText} de la page sont sélectionnées}}."
                                    values={{
                                        countText: (
                                            <strong>
                                                <FormattedMessage
                                                    defaultMessage="{count, plural, one {campagne} other {# campagnes}}"
                                                    values={{ count: campaignsPerPage }}
                                                />
                                            </strong>
                                        ),
                                        count: campaignsPerPage,
                                    }}
                                />
                            </ToggleAllWrapper>
                            <ToggleButton variant="ghost" onPress={toggleAll}>
                                <FormattedMessage
                                    defaultMessage="Sélectionner {count, plural, one {la campagne} other {les # campagnes}} de l'émission"
                                    values={{ count: totalCampaignCount }}
                                />
                            </ToggleButton>
                        </ToggleAllOuterWrapper>
                    ))}
            </SelectionToolbarWrapper>
            <DeleteCampaignsModal
                isOpen={isDeleteModalOpen}
                onOpenChange={setDeleteModalOpen}
                isLoading={deleteMultipleCampaigns.isLoading}
                onDelete={handleDeleteCampaigns}
            />
        </>
    );
};

const SelectionToolbarWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.75rem;
    min-height: 1.5rem;
    flex-wrap: wrap;
    padding-inline: 1.5rem;
`;
const SelectCheckbox = styled(Checkbox)<{ $hasSelectedAtLeastOne: boolean }>``;
const ActionWrapper = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
`;
const ActionButton = styled(Button)`
    color: var(--primary);
    padding: 0;
`;
const DestructiveActionButton = styled(ActionButton)`
    color: var(--alert);
`;
const ToggleAllWrapper = styled.span``;
const ToggleAllOuterWrapper = styled.div`
    display: flex;
    gap: 0.5rem;
    align-items: center;

    flex-direction: column;

    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        flex-direction: row;
    }
`;
const ToggleButton = styled(Button)`
    padding: 0;
`;
const Label = styled.label`
    color: var(--neutral500);
`;

export default SelectionToolbar;
