import { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import mapboxgl from 'mapbox-gl';
import { multiPolygon } from '@turf/helpers';
import bbox from '@turf/bbox';
import 'mapbox-gl/dist/mapbox-gl.css';
import ProjectionSelector from './ProjectionSelector';
import PopupContent from './PopupContent';
import IncentiveModal from './IncentiveModal';
import StatsColorLegend from './StatsColorLegend';
import styled, { css } from 'styled-components';
import { AnimatePresence } from 'framer-motion';
import { useResponsive } from '@/utils/hooks/useResponsive';
import { resolveColor } from '@/utils/cssVariables';
import Spinner from '@ui/atoms/Spinner';
import Breadcrumb from './Breadcrumb';
import { PRICING } from '@/utils/pricing';
import { useIntl } from 'react-intl';
import {
    MAX_BACKGROUND_OPACITY,
    DEFAULT_MAP_CENTER,
    DEFAULT_ZOOM_LEVEL,
    MAX_ZOOM_LEVEL_COUNTRIES,
    MIN_ZOOM_LEVEL_REGIONS,
    MIN_ZOOM_LEVEL_POINTS,
    PLAN_MAX_ZOOM_LOOKUP,
    REGION_PARENT_COUNTRY_LOOKUP,
    COUNTRY_LAYER,
    ALL_REGION_LAYERS,
    GENERIC_REGION_LAYER,
    CITY_LAYER,
    ALL_LAYERS,
    MAX_ZOOM_LEVEL_REGIONS,
    MAPBOX_MAP_STYLE_URI,
    UNKNOWN_REGION_CODE,
    UNKNOWN_CITY_NAME,
} from '../StatsGeolocalization.constants';

mapboxgl.accessToken = process.env.MAPBOX_PUBLIC_API_KEY;

/**
 * Initializes the Popup
 */
const popup = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: false,
    closeOnMove: true,
    className: 'mapbox-gl-popup',
});

const IpGeolocalizationMap = ({
    setVisibleFeatures,
    currentLayerLevel,
    setCurrentLayerLevel,
    subscriptionPlan,
    regionGeoJSON,
    countryGeoStats,
    regionGeoStats,
    currentFeature,
    setCurrentFeature,
    cityGeoStats,
    isLoading,
    userCountryCoordinates,
}) => {
    const intl = useIntl();
    const { isMobileOrTablet } = useResponsive();
    const mapContainerRef = useRef(null);
    const [plan] = useState(subscriptionPlan);
    const [showIncentiveModal, setShowIncentiveModal] = useState(false);
    const [map, setMap] = useState(null);
    const [activeProjection, setActiveProjection] = useState('globe');
    const [breadcrumb, setBreadcrumb] = useState({
        country: null,
        region: null,
    });
    const [unknownRegion, setUnknownRegion] = useState({
        coordinates: [0, 0],
        downloads: 0,
        entity: {
            id: UNKNOWN_REGION_CODE,
            label: intl.formatMessage({ defaultMessage: 'Inconnu' }),
        },
        percentage: 0,
        weight: 0,
    });
    const [unknownCity, setUnknownCity] = useState({
        coordinates: [0, 0],
        downloads: 0,
        entity: {
            id: UNKNOWN_REGION_CODE,
            label: intl.formatMessage({ defaultMessage: 'Inconnu' }),
        },
        percentage: 0,
        weight: 0,
    });
    const biggestCountryCoords = countryGeoStats.find((country) => country.weight === 1);

    /**
     * Append the Popup with a react-rendered component
     */
    const renderPopup = (map, position, label, downloads, weight = null) => {
        const popupNode = document.createElement('div');
        ReactDOM.render(
            <PopupContent label={label} downloads={downloads} weight={weight} />,
            popupNode,
        );

        popup.setLngLat(position).setDOMContent(popupNode).addTo(map);
    };

    /**
     * Update the breadcrumb component with the current feature and its parent (country)
     * @param {*} feature The current feature
     * @param {*} key country | region
     * @returns void
     */
    const updateBreadcrumb = (feature, key) => {
        const layer = feature?.layer?.id;
        const featureName =
            layer === COUNTRY_LAYER ? feature?.properties.name_en : feature?.properties.name;
        let newBreadcrumb = { ...breadcrumb };
        if (key === 'country')
            newBreadcrumb = { country: { label: featureName, coordinates: null }, region: null };
        if (key === 'region') {
            if (!regionGeoStats || !countryGeoStats) return;
            const country = countryGeoStats.find(
                (c) => c.id === feature?.properties?.parentCountry,
            );
            newBreadcrumb = {
                country: { label: country?.entity?.label, coordinates: country?.coordinates },
                region: { label: featureName },
            };
        }
        setBreadcrumb(newBreadcrumb);
    };

    /**
     * Generate color gradient array based on statistical weight of downloads for the polygons of the target layer
     * @param {*} dataSource The statistics JSON source
     * @param {*} lookupKey The matching key between the statistics JSON source and the GeoJSON polygon
     * @returns An array of alternating polygon key and stats weight
     */
    const interpolateFeatureOpacity = (dataSource, lookupKey) => {
        if (!dataSource) return [];
        const maxDownloads = dataSource?.find((entity) => entity.weight === 1)?.downloads;
        const matchExpression = ['match', ['get', lookupKey]];
        for (const row of dataSource) {
            matchExpression.push(
                row['code'],
                Math.min(MAX_BACKGROUND_OPACITY, parseFloat(row['downloads']) / maxDownloads),
            );
        }
        matchExpression.push(0);
        return matchExpression;
    };

    /**
     * Interpolate downloads into geoip markers size
     * @returns A mapboxGL Expression used to define geoip-marker's size relative to their statistical weight
     */
    const interpolateMarkerSize = (features) => {
        const maxDownloads = Math.max(...features.map((point) => point.properties.downloads));
        const size = [
            'max',
            4,
            ['min', 70, ['/', ['*', 40, ['to-number', ['get', 'downloads'], 0]], maxDownloads]],
        ];
        return size;
    };

    /**
     * Get statistics data to display when hovering a feature.
     * @param {*} dataSource The statistics JSON source
     * @param {*} targetFeature The feature currently hovered for which we'll fetch statistics
     * @param {*} targetLayer The layer currently visible on screen for which we'll fetch statistics
     * @returns Data we'll pipe into the <PopupContent /> component for display.
     */
    const extractStatistics = (dataSource, targetFeature, targetLayer) => {
        const targetEntity =
            targetLayer === COUNTRY_LAYER
                ? targetFeature.properties.iso_3166_1
                : targetFeature.properties.id;
        const label =
            targetLayer === COUNTRY_LAYER
                ? targetFeature.properties.name_en
                : targetFeature.properties.name;

        let downloads = 0;
        let weight = 0;
        let percentage = 0;
        let stats;
        if (targetLayer === CITY_LAYER) {
            stats = dataSource.features?.find((f) => f.id === targetEntity)?.properties;
        } else if (targetLayer === COUNTRY_LAYER) {
            stats = dataSource?.find((f) => f.code === targetEntity);
        } else {
            stats = dataSource?.find((f) => f.id === targetEntity);
        }

        downloads = stats?.downloads || 0;
        weight = stats?.weight || 0;
        percentage = stats?.percentage || 0;

        return {
            entity: {
                id: targetEntity,
                label,
            },
            downloads,
            weight,
            percentage,
            coordinates: targetFeature.geometry?.coordinates || null,
        };
    };

    /**
     * In case of coordinates matching a multiPolygon pattern (aka feature with islands and stuff 🏝️)
     * we pick the largest sub-set of coordinates (aka the mainland part of the target feature)
     * so that the bounding box can be correctly calculated.
     * @param {*} coordinates The *potentially* broken coordinates
     * @returns The normalized coordinates
     */
    const normalizeCoordinates = (coordinates) => {
        if (coordinates[0].length <= 2) return coordinates;
        // Target geometry has more than 2 sets of coordinates, there's presumably islands in the mix
        // We'll pick the largest set of coordinates that *should* be the mainland part
        const largestChildArray = coordinates[0].reduce(
            (acc, childArray) => {
                return childArray[0].length > acc[0]?.length || 0 ? childArray : acc;
            },
            [[]],
        );
        return [largestChildArray];
    };

    /**
     * Turn a bunch of coordinates into a lit bounding box ✨📦✨.
     * @param {*} coordinates An array of arrays of arrays of coordinates 😵‍💫 (mainly because of islands and stuff)
     * @returns A bounding box matching those coordinates
     */
    const coordinatesToBoundingBox = (coordinates) => {
        const normalizedCoordinates = normalizeCoordinates(coordinates);
        // Feed our multiPolygon coordinates, nom nom nom nom.
        const polygonizedCoordinates = multiPolygon(normalizedCoordinates);

        // Find the bounding box of the multiPolygon
        const boundingBox = bbox(polygonizedCoordinates);
        return boundingBox;
    };

    /**
     * Set the visible features states that feeds the <IpGeolocalizationTable /> component
     * @param {*} map The mapboxGL map instance
     * @param {*} features An array of features
     * @returns void
     */
    const computeVisibleFeatures = (map, features) => {
        if (!features) return;
        const zoomLayer = getZoomLevel(map);

        setVisibleFeatures([
            ...features
                .filter((feature) => feature.layer.id.endsWith(zoomLayer))
                .filter(
                    (value, index, self) =>
                        index === self.findIndex((t) => t.properties.id === value.properties.id),
                )
                .map((feature) => {
                    return feature.layer.id === CITY_LAYER
                        ? {
                              entity: {
                                  id: feature.properties.id,
                                  label: feature.properties.name,
                              },
                              downloads: feature.properties.downloads,
                              percentage: feature.properties.percentage,
                              coordinates: feature.geometry?.coordinates || null,
                          }
                        : { ...extractStatistics(regionGeoStats, feature, feature.layer.id) };
                }),
            zoomLayer === CITY_LAYER ? unknownCity : unknownRegion,
        ]);
    };

    /**
     * Set the current zoom 🔍 layer state and return it
     * @param {*} map The global map object
     * @returns The current layer COUNTRY_LAYER | GENERIC_REGION_LAYER | CITY_LAYER
     */
    const getZoomLevel = (map) => {
        const currentZoomLevel = map.getZoom();
        setShowIncentiveModal(currentZoomLevel === PLAN_MAX_ZOOM_LOOKUP[plan]);
        const zoomLayer =
            currentZoomLevel > PLAN_MAX_ZOOM_LOOKUP[PRICING.BOOST]
                ? CITY_LAYER
                : currentZoomLevel > PLAN_MAX_ZOOM_LOOKUP[PRICING.LAUNCH]
                ? GENERIC_REGION_LAYER
                : COUNTRY_LAYER;
        setCurrentLayerLevel(zoomLayer);
        return zoomLayer;
    };

    /**
     * Fits the map to the provided coordinates bounds 👉🏼 👈🏼
     * @param {*} map The global map object
     * @param {*} coordinates An array of arrays of arrays of coordinates (😵‍💫) we'll feed our multipolygon with
     * @param {*} entityType The type of entity we're zooming on (COUNTRY_LAYER | GENERIC_REGION_LAYER | CITY_LAYER)
     * @returns null
     */
    const zoomOnFeature = (map, coordinates, entityType) => {
        // Locking the map max zoom level to the region level until the animation is done
        if (entityType === COUNTRY_LAYER && plan === PRICING.SUPERSONIC) {
            map.setMaxZoom(MAX_ZOOM_LEVEL_REGIONS - 0.1);
        }
        const boundingBox = coordinatesToBoundingBox(coordinates);
        // This is presumably the ocean 🐠, ignoring.
        if (boundingBox[0] > 180 || boundingBox[0] < -180) return;
        map.fitBounds(boundingBox);
        map.setMaxZoom(PLAN_MAX_ZOOM_LOOKUP[plan]);
    };

    const zoomBack = (map) => {
        /**
         * TODO: fitBounds / FlyTo the designated coordinates
         * Why can't I do it now ? Because when the map is zoomed to the region level,
         * the COUNTRY_LAYER layer is unloaded from memory and not available to be queried against...
         * I'd have to keep it loaded when zoomed on the region level
         * (like the region layer is kept loaded when zoomed on the city level)
         * But I don't want to keep it visible on the map, it's ugly, I'd have to somehow set it's opacity to 0.
         */
        map.flyTo({
            zoom: MAX_ZOOM_LEVEL_REGIONS - 0.1,
        });
    };

    /**
     * Everything will be initialized inside this useEffect
     * on dismount, it clears the map data.
     */
    useEffect(() => {
        const map = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: MAPBOX_MAP_STYLE_URI,
            center:
                biggestCountryCoords?.coordinates || userCountryCoordinates || DEFAULT_MAP_CENTER,
            zoom: DEFAULT_ZOOM_LEVEL,
            maxZoom: PLAN_MAX_ZOOM_LOOKUP[plan],
            cooperativeGestures: isMobileOrTablet,
        });

        map.on('load', () => {
            const layers = map.getStyle().layers;
            // Find the index of the first symbol layer in the map style.
            let firstSymbolId;
            for (const layer of layers) {
                if (layer.type === 'symbol') {
                    firstSymbolId = layer.id;
                    break;
                }
            }

            map.addControl(new mapboxgl.NavigationControl(), 'bottom-right');

            /**
             * Add data source for polygons (geoJSON files / mapbox country vector map URL)
             */
            map.addSource('country-boundaries', {
                type: 'vector',
                url: 'mapbox://mapbox.country-boundaries-v1',
            });

            ALL_REGION_LAYERS.forEach((region) => {
                map.addSource(region, {
                    type: 'geojson',
                    data: regionGeoJSON[region],
                });
            });

            map.addSource(CITY_LAYER, {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: [],
                },
            });

            /**
             * Add map layers from source data
             */
            map.addLayer(
                {
                    id: COUNTRY_LAYER,
                    type: 'fill',
                    source: 'country-boundaries',
                    'source-layer': 'country_boundaries',
                    layout: {},
                    paint: {
                        'fill-color': resolveColor('--primary'),
                        'fill-opacity': 0,
                    },
                    maxzoom: MAX_ZOOM_LEVEL_COUNTRIES,
                },
                firstSymbolId,
            );

            ALL_REGION_LAYERS.forEach((region) => {
                map.addLayer(
                    {
                        id: region,
                        type: 'fill',
                        source: region,
                        layout: {},
                        paint: {
                            'fill-color': resolveColor('--primary'),
                            'fill-opacity': 0,
                        },
                        minzoom: MIN_ZOOM_LEVEL_REGIONS,
                    },
                    firstSymbolId,
                );
            });

            map.addLayer(
                {
                    id: CITY_LAYER,
                    type: 'circle',
                    source: CITY_LAYER,
                    paint: {
                        'circle-color': resolveColor('--primary'),
                        'circle-opacity': 0.5,
                        'circle-radius': 1,
                        'circle-stroke-width': 2,
                        'circle-stroke-color': '#ffffff',
                    },
                    minzoom: MIN_ZOOM_LEVEL_POINTS,
                },
                firstSymbolId,
            );

            if (countryGeoStats && countryGeoStats.length > 0) {
                map.setPaintProperty(
                    COUNTRY_LAYER,
                    'fill-opacity',
                    interpolateFeatureOpacity(countryGeoStats, 'iso_3166_1'),
                );
            }

            /**
             * When the user moves / zooms, we reset the "visible features"
             */
            map.on('movestart', () => setVisibleFeatures([]));
            map.on('zoomstart', () => setVisibleFeatures([]));

            /**
             * When the mouse cursor enters a polygon within the COUNTRY_LAYER or GENERIC_REGION_LAYER layer
             * we show a popup with the downloads.
             **/
            map.on('mousemove', [COUNTRY_LAYER], (event) => {
                const layer = event.features[0].layer.id;
                if (!layer) return;
                const { entity, downloads, percentage } = extractStatistics(
                    countryGeoStats,
                    event.features[0],
                    layer,
                );
                renderPopup(map, event.lngLat.wrap(), entity.label, downloads, percentage);
            });

            /**
             * When the mouse cursor leaves any of the feature layers, we hide the popup.
             **/
            map.on('mouseleave', ALL_LAYERS, () => {
                map.getCanvas().style.cursor = '';
                popup.remove();
            });

            /**
             * When the user clicks on a country, we fit the bounding box to the target country borders
             */
            map.on('click', [COUNTRY_LAYER], (event) => {
                zoomOnFeature(map, event.features[0].geometry.coordinates, COUNTRY_LAYER);
            });

            /**
             * When the user clicks on a region, we fit the bounding box to the target region borders
             */
            map.on('click', [...ALL_REGION_LAYERS], (event) => {
                const features = map.queryRenderedFeatures(event.point, {
                    layers: [...ALL_REGION_LAYERS],
                });
                zoomOnFeature(map, [features[0].geometry.coordinates], GENERIC_REGION_LAYER);
            });

            /**
             * Show incentive modal to upgrade to a better plan when reaching the maximum zoom level
             */
            map.on('zoomend', () => getZoomLevel(map));

            /**
             * When the map is idling, query and set the current country / region
             */
            map.on('idle', () => {
                setBreadcrumb({ country: null, region: null });
                const center = map.project([map.getCenter().lng, map.getCenter().lat]);
                const feature = map.queryRenderedFeatures(center, {
                    layers: [...ALL_REGION_LAYERS, COUNTRY_LAYER],
                })[0];

                if (!feature) return;
                const layer = feature.layer.id;
                updateBreadcrumb(feature, 'country');
                setCurrentFeature({
                    ...feature,
                    name:
                        layer === COUNTRY_LAYER
                            ? feature?.properties.name_en
                            : feature?.properties.name,
                    parentId: REGION_PARENT_COUNTRY_LOOKUP[layer] || null,
                    featureId:
                        layer === COUNTRY_LAYER
                            ? feature?.properties.iso_3166_1
                            : feature?.properties.code,
                });
            });

            setMap(map);
        });

        // Clean up on unmount, should destroy all event listeners
        return () => map.remove();
    }, [plan, isMobileOrTablet]);

    /**
     * Re-renders the map when the user toggles a different geo-projection
     */
    useEffect(() => {
        if (!map) return;
        map.setProjection(activeProjection || 'globe');
    }, [map, activeProjection]);

    /**
     * Updates the map when the region-level geo-stats are loaded
     */
    useEffect(() => {
        if (!map || !regionGeoStats) return;
        /**
         * When region stats are loaded, we update the opacity of the GENERIC_REGION_LAYER layer
         */
        if (regionGeoStats && regionGeoStats.length > 0) {
            map.setPaintProperty(
                currentFeature.layer.id,
                'fill-opacity',
                interpolateFeatureOpacity(regionGeoStats, 'code'),
            );
        }

        const rawUnknownRegion = regionGeoStats?.data?.find(
            (region) => region.code === UNKNOWN_REGION_CODE,
        );
        setUnknownRegion({
            coordinates: [0, 0],
            downloads: rawUnknownRegion?.downloads,
            entity: {
                id: UNKNOWN_REGION_CODE,
                label: intl.formatMessage({ defaultMessage: 'Inconnu' }),
            },
            percentage: rawUnknownRegion?.percentage,
            weight: rawUnknownRegion?.weight,
        });

        /**
         * Handle the popup when the user hovers over a region / city
         */
        map.on('mousemove', [...ALL_REGION_LAYERS, CITY_LAYER], (event) => {
            const feature = event.features[0];
            const layer = event.features[0].layer.id;
            if (!layer) return;

            if (layer === CITY_LAYER) {
                const { name, downloads, percentage } = feature.properties;
                renderPopup(map, event.lngLat.wrap(), name, downloads, percentage);
            } else {
                const { entity, downloads, percentage } = extractStatistics(
                    regionGeoStats,
                    feature,
                    layer,
                );
                renderPopup(map, event.lngLat.wrap(), entity.label, downloads, percentage);
            }
        });

        /**
         * This scans the features shown on screen for the GENERIC_REGION_LAYER and CITY_LAYER layers
         * and format neatly those features with matching statistics
         * ready to be exported to the parent component.
         */
        map.on('idle', () => {
            setBreadcrumb({ country: null, region: null });
            const features = map.queryRenderedFeatures({
                layers: [...ALL_REGION_LAYERS, CITY_LAYER],
            });
            computeVisibleFeatures(map, features);

            const center = map.project([map.getCenter().lng, map.getCenter().lat]);
            const feature = map.queryRenderedFeatures(center, {
                layers: [...ALL_REGION_LAYERS],
            })[0];

            if (!feature) return;
            updateBreadcrumb(feature, 'region');
        });
    }, [map, regionGeoStats]);

    /**
     * Updates the map when the city-level geo-stats are loaded
     */
    useEffect(() => {
        if (!map || !cityGeoStats) return;

        const citiesSource = map?.getSource(CITY_LAYER);
        if (!citiesSource) return;
        citiesSource.setData(cityGeoStats);

        if (!cityGeoStats?.features?.length) return;

        const rawUnknownCity = cityGeoStats?.data?.features?.find(
            (city) => city?.properties?.name === UNKNOWN_CITY_NAME,
        );
        setUnknownCity({
            coordinates: [0, 0],
            downloads: rawUnknownCity?.properties?.downloads,
            entity: {
                id: UNKNOWN_REGION_CODE,
                label: intl.formatMessage({ defaultMessage: 'Inconnu' }),
            },
            percentage: rawUnknownCity?.properties?.percentage,
            weight: rawUnknownCity?.properties?.weight,
        });

        map.setPaintProperty(
            CITY_LAYER,
            'circle-radius',
            interpolateMarkerSize(cityGeoStats.features),
        );
    }, [map, cityGeoStats]);

    return (
        <IpGeolocalizationMapWrapper>
            {countryGeoStats && countryGeoStats.length > 0 && (
                <Breadcrumb
                    breadcrumb={breadcrumb}
                    onFlyTo={(coordinates) => zoomBack(map, coordinates)}
                />
            )}
            <MapWrapper>
                <MapElement ref={mapContainerRef} />
                <Overlay position="top-right" width="140px" height="60px">
                    <Toolbar>
                        {isLoading && (
                            <Loading>
                                <Spinner />
                            </Loading>
                        )}
                        <ProjectionSelector
                            activeProjection={activeProjection}
                            setActiveProjection={(projection) => setActiveProjection(projection)}
                        />
                    </Toolbar>
                </Overlay>
                {/* <CenterPointOverlay /> */}
                <AnimatePresence>
                    {showIncentiveModal && (
                        <Overlay position="top-left" height="max-content" width="auto">
                            <IncentiveModal
                                plan={plan}
                                onClose={() => setShowIncentiveModal(false)}
                            />
                        </Overlay>
                    )}
                </AnimatePresence>
                <AnimatePresence>
                    {currentLayerLevel !== CITY_LAYER && (
                        <LegendOverlay position="bottom-left" height="12rem" width="3rem">
                            <StatsColorLegend />
                        </LegendOverlay>
                    )}
                </AnimatePresence>
            </MapWrapper>
        </IpGeolocalizationMapWrapper>
    );
};

const IpGeolocalizationMapWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
`;

const MapWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 700px;
`;

const MapElement = styled.div`
    width: 100%;
    height: 100%;
`;

const Overlay = styled.div`
    position: absolute;
    z-index: 1;
    width: ${(props) => props.width || '100px'};
    height: ${(props) => props.height || '100px'};

    ${(props) =>
        props.position === 'top-right' &&
        css`
            top: 0;
            right: 0;
        `};
    ${(props) =>
        props.position === 'top-left' &&
        css`
            top: 0;
            left: 0;
        `};
    ${(props) =>
        props.position === 'bottom-right' &&
        css`
            bottom: calc(100% - ${(props) => props.height || '100px'} - 3rem);
            right: 0;
        `};
    ${(props) =>
        props.position === 'bottom-left' &&
        css`
            top: calc(100% - ${(props) => props.height || '100px'} - 3rem);
            left: 0;
        `};
`;

// eslint-disable-next-line
const CenterPointOverlay = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    background: red;
    border-radius: 20px;
`;

const LegendOverlay = styled(Overlay)`
    margin-left: 0.5rem;
`;

const Toolbar = styled.div`
    display: flex;
    padding: 0.5rem;
    height: 100%;
    align-items: center;
    justify-content: flex-end;

    & > * {
        flex-shrink: 0;
    }
`;

const Loading = styled.div`
    margin: 1rem;
`;

export default IpGeolocalizationMap;
