import { useQuery } from '@tanstack/react-query';
import { fetchCalendarCommunicationEventDraft } from '@/api';
import socialNetworkPostKeys from './socialNetworkPostKeys';
import camelcaseKeys from 'camelcase-keys';

const queryFn = async ({ queryKey: [{ showId }] }) => {
    const { data } = await fetchCalendarCommunicationEventDraft(showId);

    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const useSocialNetworkCalendarPostDraftQuery = ({ showId }) => {
    return useQuery({
        queryKey: socialNetworkPostKeys.listDraft({ showId }),
        queryFn,
        enabled: !!showId,
    });
};

export default useSocialNetworkCalendarPostDraftQuery;
