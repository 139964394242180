import { createApi } from '../callApi';
import { uploadImage } from '../uploadImage';
const contentApi = createApi('CONTENT');

export const fetchPlaylists = (show) => {
    return contentApi({ method: 'GET', target: `/v1/shows/${show}/playlists` });
};
export const fetchPlaylist = (playlist) => {
    return contentApi({ method: 'GET', target: `/v1/playlists/${playlist}` });
};
export const addPlaylist = (show, body) => {
    return contentApi({ method: 'POST', target: `/v1/shows/${show}/playlists`, body });
};
export const addPodcastToPlaylist = (playlist, podcast) => {
    return contentApi({ method: 'POST', target: `/v1/playlists/${playlist}/podcasts/${podcast}` });
};
export const removePodcastFromPlaylist = (playlist, playlistPodcast) => {
    return contentApi({
        method: 'DELETE',
        target: `/v1/playlists/${playlist}/podcasts/${playlistPodcast}`,
    });
};
export const deletePlaylistImage = (playlist) => {
    return contentApi({ method: 'DELETE', target: `/v1/playlists/${playlist}/image` });
};
export const deletePlaylist = (playlist) => {
    return contentApi({ method: 'DELETE', target: `/v1/playlists/${playlist}` });
};
export function updatePlaylistImage(playlist, image) {
    return uploadImage(`/v1/playlists/${playlist}/image`, image);
}
export const moveEpisodesPlaylist = (playlist, playlistPodcast, body) => {
    return contentApi({
        method: 'PUT',
        target: `/v1/playlists/${playlist}/podcasts/${playlistPodcast}`,
        body,
    });
};
export const updatePlaylist = (playlist, body) => {
    return contentApi({ method: 'PUT', target: `/v1/playlists/${playlist}`, body });
};
