import { z } from 'zod';
import { useIntl } from 'react-intl';
import { FORM_VALIDATION_MESSAGE } from '@/utils/validationMessage';
import { checkImgSize } from '@/helpers';

const useAushaProShowSettingsFormSchema = (locale: string | undefined = 'en') => {
    const intl = useIntl();
    const acceptedMimes = ['image/jpg', 'image/jpeg', 'image/png'];

    const fileSchema = z
        .union([
            z
                .instanceof(File)
                .refine((file: File) => acceptedMimes.includes(file.type), {
                    message: intl.formatMessage(FORM_VALIDATION_MESSAGE.acceptedMimes, {
                        acceptedMimes,
                    }) as string,
                })
                .refine((file: File) => checkImgSize(file, locale, 10), {
                    message: intl.formatMessage(FORM_VALIDATION_MESSAGE.imgMaxSize, {
                        max: 10,
                    }) as string,
                }),
            z.string(),
        ])
        .optional()
        .nullable();

    return z.object({
        whiteLabel: z.boolean().optional(),
        hasCustomLogo: z.boolean().optional(),
        darkLogo: fileSchema,
        lightLogo: fileSchema,
        favicon: fileSchema,
        logoLink: z
            .string()
            .url(intl.formatMessage(FORM_VALIDATION_MESSAGE.isUrl))
            .optional()
            .or(z.literal('')), // empty string is not a valid URL even with optional and nullable properties
    });
};

export type AushaProShowSettingsSchema = z.infer<
    ReturnType<typeof useAushaProShowSettingsFormSchema>
>;

export default useAushaProShowSettingsFormSchema;
