import { useController } from 'react-hook-form';
import { default as CardSelectList } from '.';
import Text from '@ui/atoms/Text';

export const CardSelectListController = (props) => {
    const { control, name, onChange, onBlur, children, ...rest } = props;
    const { field } = useController({
        name,
        control,
    });

    return (
        <>
            <CardSelectList
                onChange={(value) => {
                    onChange && onChange(value);
                    field.onChange(value);
                }}
                onBlur={(...v) => {
                    onBlur && onBlur(...v);
                    field.onBlur(...v);
                }}
                name={field.name}
                value={field.value}
                {...rest}
            >
                {children}
            </CardSelectList>
            {field.error && (
                <Text color="--alert" fontWeight="--fw-semibold">
                    {field.error}
                </Text>
            )}
        </>
    );
};
