import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SocialMediaCalendar from '@/components/SocialMediaCalendar';
import SocialMediaList from '@/components/SocialMediaList';
import SocialMediaHeader from '@/components/SocialMediaHeader';
import { useCalendar, useLocale } from 'react-aria';
import { useCalendarState } from 'react-stately';
import { createCalendar } from '@internationalized/date';
import useSocialNetworkPostsQuery from '@/queries/socialNetworkPost/useSocialNetworkPostsQuery.hook';
import useEpisodesQuery from '@/queries/episode/useEpisodesQuery.hook';
import { EditedPostProvider } from './useEditedPostContext.hook';
import NoPostEmptyState from './NoPostEmptyState';
import NoEpisodeEmptyState from './NoEpisodeEmptyState';
import { mediaQueries } from '@/styles/theme';
import { useAmplitudeLogEvent } from '@/shared/hooks/useAmplitude';
import styled from 'styled-components';

const SocialMediaPage = (props) => {
    useAmplitudeLogEvent('Communication Section Viewed');
    const { showId } = useParams();

    const isDesktop = window.matchMedia(
        mediaQueries.mediaQueries.desktopLargeAndUp.split('@media ')[1],
    ).matches;
    const [currentView, setCurrentView] = useState(isDesktop ? 'calendar' : 'list');
    const [currentPage, setCurrentPage] = useState(1);
    const [triggerCreatePost, setTriggerCreatePost] = useState(false);
    const [showNoPostEmptyState, setShowNoPostEmptyState] = useState(false);
    const perPage = 15;

    const listProps = {
        showId,
        currentPage,
        perPage,
    };
    const listOptions = {
        enabled: !!showId,
    };
    const listPosts = useSocialNetworkPostsQuery(listProps, listOptions);
    const episodes = useEpisodesQuery({ showId });

    const { locale } = useLocale();
    const state = useCalendarState({
        ...props,
        locale,
        createCalendar,
    });

    let { calendarProps, prevButtonProps, nextButtonProps, title } = useCalendar(props, state);
    const capitalizedTitle = title.charAt(0).toUpperCase() + title.slice(1);

    useEffect(() => {
        if (listPosts?.data?.posts?.length === 0) {
            setShowNoPostEmptyState(true);
        }
    }, [listPosts?.data?.posts]);

    useEffect(() => {
        if (triggerCreatePost) {
            setShowNoPostEmptyState(false);
        }
    }, [triggerCreatePost]);

    if (episodes?.data?.episodes?.length === 0) {
        return <NoEpisodeEmptyState />;
    }

    if (showNoPostEmptyState) {
        return <NoPostEmptyState handleNewPost={() => setTriggerCreatePost(true)} />;
    }

    return (
        <Wrapper>
            <EditedPostProvider>
                <SocialMediaHeader
                    prevButtonProps={prevButtonProps}
                    nextButtonProps={nextButtonProps}
                    title={capitalizedTitle}
                    currentView={currentView}
                    setCurrentView={setCurrentView}
                    postNb={listPosts?.data?.meta?.pagination?.total}
                    triggerCreatePost={triggerCreatePost}
                    postCreateTriggered={() => setTriggerCreatePost(false)}
                />
                {currentView === 'calendar' && (
                    <SocialMediaCalendar calendarProps={calendarProps} calendarState={state} />
                )}
                {currentView === 'list' && (
                    <SocialMediaList
                        posts={listPosts?.data?.posts}
                        pagination={listPosts?.data?.meta?.pagination}
                        handlePageChange={(page) => setCurrentPage(page)}
                    />
                )}
            </EditedPostProvider>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    background-color: var(--white);
    border-radius: var(--r-l);
    min-height: 50rem;
    padding: 1rem;

    ${(p) => p.theme.mediaQueries.desktopLargeAndUp} {
        padding: 2rem;
        padding-right: 1rem;
    }
`;

SocialMediaPage.propTypes = {};

export default SocialMediaPage;
