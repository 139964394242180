const showTemplateKeys = {
    all: () => [{ entity: 'showTemplate' }],
    allLists: () => [{ ...showTemplateKeys.all()[0], scope: 'list' }],
    listByShowId: (rawShowId: string | number) => [
        { ...showTemplateKeys.allLists()[0], showId: Number(rawShowId) },
    ],
    allDetails: () => [{ ...showTemplateKeys.all()[0], scope: 'detail' }],
    detailByProvider: (rawShowId: string | number, provider: string) => [
        { ...showTemplateKeys.allDetails()[0], showId: Number(rawShowId), provider },
    ],
};

export default showTemplateKeys;
