import { useFormContext } from 'react-hook-form';
import Stack from '@ui/layout/Stack';
import Text from '@ui/atoms/Text';
import { FormattedMessage } from 'react-intl';
import { ToggleController } from '@ui/atoms/Toggle';

const EpisodeEditFormNewsletterEnabledField = () => {
    const { control } = useFormContext();

    return (
        <Stack $gap="1rem">
            <Text variant="bodyM" fontWeight="--fw-semibold">
                <FormattedMessage defaultMessage="Activer la newsletter" />
            </Text>
            <ToggleController name="activated" control={control}>
                <FormattedMessage defaultMessage="Un e-mail sera envoyé aux utilisateurs abonnés à votre newsletter dès que votre épisode sera publié." />
            </ToggleController>
        </Stack>
    );
};

export default EpisodeEditFormNewsletterEnabledField;
