import { FormattedMessage } from 'react-intl';
import { useResponsive } from '@/utils/hooks/useResponsive';
import { useAmplitudeLogEvent } from '@hooks/useAmplitude';
import EditPasswordForm from './EditPasswordForm';
import Modal from '@ui/molecules/Modal';

function EditPasswordModal({ isOpen, onClose }) {
    useAmplitudeLogEvent('show edit password');

    const { isMobileOrTablet } = useResponsive();

    return (
        <Modal
            title={
                isMobileOrTablet ? (
                    <FormattedMessage defaultMessage="Mod. votre mot de passe" />
                ) : (
                    <FormattedMessage defaultMessage="Modifier votre mot de passe" />
                )
            }
            onClose={onClose}
            isOpen={isOpen}
        >
            <EditPasswordForm onAfterSubmit={onClose} />
        </Modal>
    );
}

export default EditPasswordModal;
