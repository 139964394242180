import Button from '@/components/Button';
import IconButton from '@/components/IconButton';
import Modal from '@/components/ui/atoms/Modal';
import Text from '@/components/ui/atoms/Text';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Stack from '@/components/ui/layout/Stack';
import { InstagramAccount } from '@/queries/provider/useInstagramProviderQuery';
import { IMG_PLACEHOLDER } from '@/shared/config/constants';

interface AccountsModalProps {
    isOpen: boolean;
    onOpenChange: (isOpen: boolean) => void;
    instagramAccounts: InstagramAccount[];
    onAccountSelect: (id: string) => void;
}
const AccountsModal = ({
    isOpen,
    onOpenChange,
    instagramAccounts,
    onAccountSelect,
}: AccountsModalProps) => {
    const handleClose = () => onOpenChange(false);

    return (
        <ModalStyled isOpen={isOpen} onOpenChange={onOpenChange} size="medium">
            <Header>
                <Text variant="headingM" fontWeight="--fw-bold">
                    <FormattedMessage defaultMessage="À quel compte Instagram souhaitez-vous vous connecter ?" />
                </Text>
                <IconButton
                    icon={<CloseIcon icon={icon({ name: 'xmark-large', style: 'solid' })} />}
                    onPress={handleClose}
                    variant="ghost"
                    aria-label="Close modal"
                    style={{ padding: 0 }}
                />
            </Header>
            <Stack $gap="1rem">
                <Description>
                    <FormattedMessage defaultMessage="Seuls les comptes business peuvent être connectés." />
                </Description>
                <Accounts>
                    {instagramAccounts?.map((account: InstagramAccount) => (
                        <Account key={account.id} onClick={() => onAccountSelect(account.id)}>
                            <Left>
                                <ProfilePicture
                                    src={account.profilePictureUrl ?? IMG_PLACEHOLDER}
                                    alt={account.username}
                                />
                                <Username>{account.username}</Username>
                            </Left>
                            <Button
                                className="connect-button"
                                variant="secondary"
                                onPress={() => onAccountSelect(account.id)}
                            >
                                <FormattedMessage defaultMessage="Connecter" />
                            </Button>
                        </Account>
                    ))}
                </Accounts>
            </Stack>
            <CancelButton variant="ghost" onPress={handleClose}>
                <FormattedMessage defaultMessage="Annuler" />
            </CancelButton>
        </ModalStyled>
    );
};

const ModalStyled = styled(Modal)`
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 2rem;
    overflow-y: auto;
`;
const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 1rem;
`;
const CloseIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--neutral500);
`;
const CancelButton = styled(Button)`
    padding: 0;
    color: var(--neutral500);
`;
const Description = styled.p`
    color: var(--neutral500);
`;
const Accounts = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.75rem;
    margin-block: 1rem;
`;
const Account = styled.li`
    animation-duration: 0.25s;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: var(--r-m);
    padding: 0.75rem;

    & > .connect-button {
        visibility: hidden;
    }
    &:hover {
        animation-duration: 0.25s;
        background-color: var(--neutral50);
    }
    &:hover > .connect-button {
        visibility: visible;
    }
`;
const Left = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;
const ProfilePicture = styled.img`
    width: 2rem;
    height: 2rem;
    border-radius: var(--r-full);
`;
const Username = styled.span`
    font-weight: var(--fw-semibold);
`;

export default AccountsModal;
