import { useParams } from 'react-router';
import { FormattedMessage } from 'react-intl';
import EmptyRssImport from './EmptyRssImport';
import ImportInfo from './ImportInfo';
import ImportedEpisodes from './ImportedEpisodes';
import { useAmplitudeLogEvent } from '@/shared/hooks/useAmplitude';
import useRssFeedQuery from '@queries/rss/useRssFeedQuery.hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';

const RssImport = () => {
    useAmplitudeLogEvent('Import Settings Viewed');
    const { showId } = useParams<{ showId: string }>();
    const { data: rssFeed, isLoading, isError } = useRssFeedQuery({ showId });

    /**
     * We specifically don't want to handle the error here.
     * Because a 404 means "no RSS feed" and we want to show the empty state
     */
    if (isLoading) {
        return (
            <LoadingWrapper>
                <FontAwesomeIcon icon={icon({ name: 'spinner-third', style: 'solid' })} spin />
            </LoadingWrapper>
        );
    }

    return (
        <RssImportWrapper>
            <TitleWrapper>
                <DesktopTitle>
                    <FormattedMessage defaultMessage="Import RSS" />
                </DesktopTitle>
                <MobileTitle>
                    <FormattedMessage defaultMessage="Paramètres" />
                </MobileTitle>
                <Subtitle>
                    <FormattedMessage defaultMessage="Si vous ajoutez un lien RSS pour importer une émission, tous ses épisodes seront importés ici." />
                </Subtitle>
            </TitleWrapper>
            {!rssFeed || isError ? (
                <EmptyRssImport />
            ) : (
                <RssImportContentWrapper>
                    <ImportInfo rssFeed={rssFeed} />
                    <ImportedEpisodes />
                </RssImportContentWrapper>
            )}
        </RssImportWrapper>
    );
};

const LoadingWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 10rem;
    background-color: var(--white);
    border-radius: var(--r-l);
    color: var(--neutral500);
`;
const RssImportWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;
const TitleWrapper = styled.div``;
const DesktopTitle = styled.h1`
    font-size: var(--fs-heading-m);
    display: none;
    @media (min-width: 768px) {
        display: block;
    }
`;
const MobileTitle = styled.h1`
    font-size: var(--fs-heading-m);
    display: block;
    @media (min-width: 768px) {
        display: none;
    }
`;
const Subtitle = styled.p`
    color: var(--neutral);
`;
const RssImportContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

export default RssImport;
