import posed, { PoseGroup } from 'react-pose';
import { FormattedMessage } from 'react-intl';
import DeprecatedPaper from '../../ui/atoms/DeprecatedPaper';
import DeprecatedText from '../../ui/atoms/DeprecatedText';
import Desktop from '../../ui/molecules/Desktop';
import MobileOrTablet from '../../ui/molecules/MobileOrTablet';
import DateRangeSelector from './DateRangeSelector';
import { useResponsive } from '../../../utils/hooks/useResponsive';
import { connect } from '../decorators/connect';
import HideFor from '../atoms/HideFor';
import { USER_OPTIONS } from '../../../utils/userOptions';

const transition = {
    duration: 250,
};

const Title = posed(DeprecatedText)({
    enter: { opacity: 1, x: 0, transition },
    exit: { opacity: 0, x: -5, transition },
});

const TitleGroup = ({ tabTitle }) => (
    <>
        <MobileOrTablet>
            <FormattedMessage defaultMessage="Statistiques" />
        </MobileOrTablet>
        <Desktop>{tabTitle}</Desktop>
    </>
);

const enhancePeakTimeSelector = connect(({ peakTimeStatsStore }) => ({
    daysRange: peakTimeStatsStore.daysRangePeakTime,
    setDaysRange: (daysRange) => peakTimeStatsStore.setDaysRangePeakTime(daysRange),
}));

const PeakTimeSelector = enhancePeakTimeSelector(DateRangeSelector);

const CustomSelector = ({ tabId }) => {
    // IF YOU NEED TO ADD A CUSTOM SELECTOR FOR AN OTHER FUTURE PAGE
    // PLACE IT HERE
    switch (tabId) {
        case 'peaktime':
            return (
                <HideFor userOptions={USER_OPTIONS.PEAKTIME}>
                    <PeakTimeSelector />
                </HideFor>
            );
        default:
            return null;
    }
};

function StatsTabTitle({ tabTitle, tabId, ...props }) {
    const { isDesktop } = useResponsive();

    return (
        <DeprecatedPaper flex={isDesktop} align="flex-end" z={1}>
            <DeprecatedPaper mt={25} flex {...props}>
                <PoseGroup>
                    <Title key={tabId} size="veryBig" weight="bold">
                        <TitleGroup tabTitle={tabTitle} />
                    </Title>
                </PoseGroup>
            </DeprecatedPaper>
            <CustomSelector tabId={tabId} />
        </DeprecatedPaper>
    );
}

export default StatsTabTitle;
