import { runInAction } from 'mobx';
import { createTransformer } from 'mobx-utils';
import { fetchAllShrinkedEpisodes, fetchPodcast } from '@/api';
import Store from './Store';
import PodcastModel from '../models/PodcastModel';
import { apiFetch } from '@/components/legacy/api';
import ShrinkedEpisodeModel from '../models/ShrinkedEpisodeModel';

class PodcastStore extends Store {
    static observables = {
        podcasts: [],
        podcastsShrinked: [],
        uploadingPodcasts: [],
        pagination: {},
        // UI state
        filtersMenuOpened: false,
        statusFilters: [],
        seasonFilters: [],

        allSelected: false,
        playingPodcast: null,
        playing: false,

        editAccordionIndex: 0,

        editDescriptionHasPassedCharacterLimit: false,
    };

    byId = createTransformer((podId) => this.podcasts.find((s) => `${s.id}` === `${podId}`));

    @apiFetch
    async fetchPodcast(podcastId) {
        if (podcastId === 'all') return;
        const { data } = await fetchPodcast(podcastId);
        const podcast = new PodcastModel(this, data);
        runInAction(() => {
            if (this.podcasts.find((p) => p.id === podcast.id)) return;
            this.podcasts.push(podcast);
        });

        return podcast;
    }

    @apiFetch
    async fetchShrinkedEpisodes(
        showId,
        searchQuery = '',
        page = 1,
        perPage = 20,
        withClip = null,
        episodeStatus = null,
    ) {
        const { data, meta } = await fetchAllShrinkedEpisodes(
            showId,
            searchQuery,
            page,
            perPage,
            withClip,
            episodeStatus,
        );
        runInAction(() => {
            this.podcastsShrinked = data.map((episode) => new ShrinkedEpisodeModel(this, episode));
        });

        return {
            episodes: data.map((episode) => new ShrinkedEpisodeModel(this, episode)) ?? [],
            pagination: {
                total: meta.pagination.total,
                count: meta.pagination.count,
                perPage: meta.pagination.per_page,
                currentPage: meta.pagination.current_page,
                totalPages: meta.pagination.total_pages,
            },
        };
    }
}

export default PodcastStore;
