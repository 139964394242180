import { useEffect, useState } from 'react';

export function useSubscribe(func, inputs = [], timeout = 5000) {
    const [refresh, setRefresh] = useState(true);

    useEffect(() => {
        func(...inputs);
        if (refresh) {
            const intervalId = setInterval(() => func(...inputs), timeout);
            return () => clearInterval(intervalId);
        }
        return undefined;
    }, [...inputs, refresh]);

    return () => {
        if (refresh) setRefresh(false);
    };
}
