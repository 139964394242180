import { Radio, RadioProps } from 'react-aria-components';
import styled, { css } from 'styled-components';

interface RadioCardProps extends RadioProps {
    size?: 'small' | 'large';
}

const RadioCard = ({ children, size, ...props }: RadioCardProps) => {
    return (
        <Input {...props} $size={size}>
            {children}
            <FakeRadio />
        </Input>
    );
};

const smallStyles = css`
    display: flex;
    align-items: center;
    column-gap: 1rem;
    padding: 1rem;
`;
const largeStyles = css`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    padding: 1.5rem;
    text-align: center;
`;
const FakeRadio = styled.div`
    width: 1rem;
    height: 1rem;
    border: 1px solid var(--neutral200);
    border-radius: var(--r-full);
    background-color: var(--white);

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        position: absolute;
        top: 1rem;
        right: 1rem;
    }
`;
const Input = styled(Radio)<{ $size?: 'small' | 'large' }>`
    background-color: var(--white);
    border-radius: var(--r-s);
    border: 1px solid var(--neutral200);
    cursor: pointer;
    position: relative;
    flex: 1;

    &:not([data-disabled]) {
        &[data-hovered] {
            border-color: var(--neutral300);
        }

        &[data-focused] {
            border-color: var(--primary500);
            outline: 4px solid var(--primary50);
        }

        &[data-selected] {
            background-color: var(--primary50);
        }
    }

    &[data-selected] {
        border-color: var(--primary500);

        & > ${FakeRadio} {
            border-width: 6px;
            border-color: var(--primary500);
        }
    }

    & > :first-child,
    & > :last-child {
        flex-shrink: 0;
    }

    & > :nth-child(2) {
        flex-grow: 1;
    }

    &[data-disabled] {
        opacity: 0.5;
        cursor: default;
    }

    ${smallStyles}

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        ${largeStyles}
    }

    ${({ $size }) => $size === 'small' && smallStyles}
    ${({ $size }) => $size === 'large' && largeStyles}
`;

export default RadioCard;
