import { FormattedMessage } from 'react-intl';
import Dialog from '@ui/atoms/Dialog';
import styled from 'styled-components';
import Button from '@ui/atoms/Button';
import RadioGroup from '@ui/molecules/RadioGroup';
import { useState } from 'react';
import InputRadio from '@ui/atoms/InputRadio';
import { useEpisodeTranscriptionFiles } from './EpisodeEditTranscriptionProvider';
import Stack from '@ui/layout/Stack';
import { TRANSCRIPTION_FILES_URL } from '@/shared/config/constants';
import dayjs from 'dayjs';
import { useEpisodeBeingEdited } from '@/context/EpisodeFormContext';
import PropTypes from 'prop-types';
import { sendAmplitudeLogEvent } from '@/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useBodyToastQueue } from '@/shared/hooks/useBodyToastQueue.hook';
import { convertTiptapContentToTranscript } from '@/components/unorganized/TranscriptionEditor/transcriptConverter';
import useUpdateEpisodeTranscriptMutation from '@queries/episodeTranscript/useUpdateEpisodeTranscriptMutation.hook';

const EpisodeEditTranscriptionExportModal = ({
    isOpen,
    onOpenChange,
    objectToSaveBeforeExport,
}) => {
    const files = useEpisodeTranscriptionFiles();
    const episode = useEpisodeBeingEdited();
    const [type, setType] = useState('txt');
    const toast = useBodyToastQueue();
    const updateTranscript = useUpdateEpisodeTranscriptMutation();
    const [isLoading, setIsLoading] = useState(false);

    /* 
        Download the transcription file based on the selected type.
        Note: The file is fetched from the server and then downloaded.
              This approach is used to modify the file name before downloading.
    */
    const exportFileDownload = async () => {
        try {
            const fileUrl = TRANSCRIPTION_FILES_URL + files?.[type];
            const date = dayjs().format('MMDDYY');
            const fileName = episode?.slug
                ? `${date}-${episode.slug}-transcription-ausha.${type}`
                : `${files?.[type]}.${type}`;

            const response = await fetch(fileUrl, {
                cache: 'no-cache',
            });

            if (!response.ok) throw new Error('An error occurred while downloading the file.');
            const blob = await response.blob();

            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            link.remove();

            window.URL.revokeObjectURL(url);
            sendAmplitudeLogEvent('Transcription Exported', { type });
            toast.success(
                <FormattedMessage defaultMessage="L'export de la transcription de votre épisode a bien été généré. 👌" />,
            );
            onOpenChange(false);
            setIsLoading(false);
        } catch (error) {
            console.error('Download failed', error);
            setIsLoading(false);
        }
    };
    const handleDownload = async () => {
        setIsLoading(true);
        if (objectToSaveBeforeExport) {
            const { transcription, speakers } = objectToSaveBeforeExport;
            const speakersArray = Array.from(speakers.entries()).map(([id, name]) => ({
                id,
                name,
            }));
            updateTranscript.mutate(
                {
                    episodeId: episode.id,
                    transcript: {
                        data: convertTiptapContentToTranscript(transcription, speakersArray),
                    },
                },
                {
                    onSuccess: () => exportFileDownload(),
                },
            );
        } else {
            await exportFileDownload();
        }
    };

    return (
        <Dialog
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            title={<FormattedMessage defaultMessage="Exporter la transcription" />}
            description={
                <FormattedMessage defaultMessage="Choisissez le format idéal pour exporter et réutiliser votre transcription." />
            }
            size="medium"
        >
            <Stack $gap="2rem">
                <RadioGroup defaultValue={type} onChange={setType} gap="1rem">
                    <InputRadio value="txt">
                        <FormattedMessage defaultMessage="Format texte brut (.txt)" />
                    </InputRadio>
                    <InputRadio value="json">
                        <FormattedMessage defaultMessage="Données structurées (.json)" />
                    </InputRadio>
                    <InputRadio value="srt">
                        <FormattedMessage defaultMessage="Sous-titres vidéo (.srt)" />
                    </InputRadio>
                    <InputRadio value="vtt">
                        <FormattedMessage defaultMessage="Sous-titres web (.vtt)" />
                    </InputRadio>
                </RadioGroup>
                <Actions>
                    <CancelButton onPress={() => onOpenChange(false)} variant="ghost">
                        <FormattedMessage defaultMessage="Annuler" />
                    </CancelButton>
                    <Button
                        startIcon={
                            <ExportIcon
                                icon={icon({ name: 'arrow-up-from-line', style: 'solid' })}
                            />
                        }
                        isLoading={isLoading}
                        isDisabled={!files}
                        onPress={handleDownload}
                    >
                        <FormattedMessage defaultMessage="Exporter" />
                    </Button>
                </Actions>
            </Stack>
        </Dialog>
    );
};

const Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 1.5rem;
    border-top: 1px solid var(--neutral100);
`;
const CancelButton = styled(Button)`
    padding: 0;
    color: var(--neutral500);
`;
const ExportIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--white);
`;

EpisodeEditTranscriptionExportModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onOpenChange: PropTypes.func.isRequired,
    objectToSaveBeforeExport: PropTypes.object,
};

export default EpisodeEditTranscriptionExportModal;
