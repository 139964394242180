import { PlayerIframeSettings } from './usePlayerIframeQuery.hook';

const playerIframeKeys = {
    all: () => [{ entity: 'playerIframe' }],
    allDetails: () => [{ ...playerIframeKeys.all()[0], scope: 'detail' }],
    detailBySettings: (settings: PlayerIframeSettings) => [
        { ...playerIframeKeys.allDetails()[0], settings },
    ],
};

export default playerIframeKeys;
