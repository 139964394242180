import { FormattedMessage } from 'react-intl';
import Dialog from '@/components/ui/atoms/Dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Alert from '@ui/atoms/Alert';
import Stack from '@ui/layout/Stack';
import Text from '@ui/atoms/Text';
import Button from '@/components/Button';
import { PRICING_NAME } from '@/shared/config/pricing';
import useArchivedShowsQuery from '@/queries/show/useArchivedShowsQuery.hook';
import { NUMBER_OF_ARCHIVED_SHOWS } from '@/shared/config/constants';
import Spinner from '@ui/atoms/Spinner';
import useSubscriptionQuery from '@queries/subscription/useSubscriptionQuery.hook';
import { getStripeCustomerPortalSession } from '@/api';
import { PRICING } from '@/shared/config/pricing';
import { usePricingContext } from '@/context/PricingContext';
import { useModalToastQueue } from '@/shared/hooks/useModalToastQueue.hook';
import styled from 'styled-components';
import ExternalLink from '@/components/Link/ExternalLink';
import RouterLink from '@/components/Link/RouterLink';
import { useIntercom } from 'react-use-intercom';

const BILLING_PROVIDERS = {
    STRIPE: 'stripe',
    CHARGEBEE: 'chargebee',
};

export interface PricingErrorModalProps {
    isOpen: boolean;
    onOpenChange: () => void;
}

const PricingErrorModal = ({ isOpen, onOpenChange }: PricingErrorModalProps) => {
    const toast = useModalToastQueue();
    const { errorPayload } = usePricingContext();
    const { data: archivedShows } = useArchivedShowsQuery();
    const intercom = useIntercom();

    const { data: subscription } = useSubscriptionQuery();
    const provider =
        subscription?.pricing === PRICING.ENTERPRISE
            ? BILLING_PROVIDERS.CHARGEBEE
            : BILLING_PROVIDERS.STRIPE;

    const createStripePortalSession = async () => {
        try {
            const stripeCustomerPortalSession = await getStripeCustomerPortalSession(true);
            const { url } = stripeCustomerPortalSession;
            window.location.href = url;
        } catch (error) {
            toast.alert(
                <FormattedMessage defaultMessage="Une erreur inattendue est survenue. Merci de réessayer plus tard." />,
            );
        }
    };

    if (!archivedShows || !errorPayload?.error?.response?.status) {
        return null;
    }

    const maximumArchivedShowsError = (
        <Stack $gap="0.25rem">
            <Text variant="headingM" fontWeight="--fw-bold" textAlign="center">
                <FormattedMessage
                    defaultMessage="Votre nombre d'émissions archivées dépasse la limite de l'offre {offer} ({shows}/{maxShows})"
                    values={{
                        offer: PRICING_NAME[errorPayload?.offer],
                        shows: archivedShows?.shows.length,
                        maxShows: NUMBER_OF_ARCHIVED_SHOWS[errorPayload?.offer],
                    }}
                />
            </Text>
            <Text
                variant="bodyM"
                fontWeight="--fw-semibold"
                textAlign="center"
                color="--neutral500"
            >
                <FormattedMessage
                    defaultMessage="Veuillez en supprimer au moins <strong>{value}</strong> depuis le <link>sélecteur d'émission</link> pour continuer"
                    values={{
                        link: (chunk: React.ReactNode) => (
                            <RouterLink to="/app">{chunk}</RouterLink>
                        ),
                        strong: (chunk: React.ReactNode) => <strong>{chunk}</strong>,
                        value:
                            archivedShows?.shows?.length -
                            NUMBER_OF_ARCHIVED_SHOWS[errorPayload?.offer],
                    }}
                />
            </Text>
        </Stack>
    );

    const billingError = (
        <Stack $gap="0.25rem">
            <Text variant="headingM" fontWeight="--fw-bold" textAlign="center">
                <FormattedMessage defaultMessage="Oups, il semble que vous ayez une erreur de facturation." />
            </Text>
            <Text
                variant="bodyM"
                fontWeight="--fw-semibold"
                textAlign="center"
                color="--neutral500"
            >
                <FormattedMessage
                    defaultMessage="Merci de résoudre ce problème dans votre espace d'abonnement avant de sélectionner une nouvelle offre. N'hésitez pas à <intercom>contacter</intercom> notre équipe de support si nécessaire."
                    values={{
                        intercom: (chunk: React.ReactNode) => (
                            <Button variant="link-primary" onPress={() => intercom.show()}>
                                {chunk}
                            </Button>
                        ),
                    }}
                />
            </Text>
        </Stack>
    );

    return (
        <Dialog
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            isDismissable={false}
            hasCloseButton={false}
            size="small"
        >
            {!archivedShows ? (
                <SpinnerWrapper>
                    <Spinner size={32} />
                </SpinnerWrapper>
            ) : (
                <Stack $gap="1.5rem" $align="center">
                    <IconAlert
                        variant="alert"
                        icon={
                            <FontAwesomeIcon
                                icon={icon({ name: 'money-check-dollar-pen', style: 'solid' })}
                                size="xl"
                            />
                        }
                    />
                    {errorPayload.error.response.status === 422
                        ? maximumArchivedShowsError
                        : billingError}
                    <ActionWrapper>
                        {provider === BILLING_PROVIDERS.CHARGEBEE ? (
                            <ExternalLink
                                variant="button-primary"
                                href="https://ausha.chargebeeportal.com/portal/v2/login?forward=portal_main"
                                target="_blank"
                                endIcon={
                                    <FontAwesomeIcon
                                        icon={icon({ name: 'arrow-up-right', style: 'solid' })}
                                    />
                                }
                            >
                                <FormattedMessage defaultMessage="Mon espace de facturation" />
                            </ExternalLink>
                        ) : (
                            <Button
                                onPress={createStripePortalSession}
                                endIcon={
                                    <FontAwesomeIcon
                                        icon={icon({ name: 'arrow-up-right', style: 'solid' })}
                                    />
                                }
                            >
                                <FormattedMessage defaultMessage="Mon espace de facturation" />
                            </Button>
                        )}
                        <CloseButton variant="ghost" onPress={onOpenChange}>
                            <FormattedMessage defaultMessage="Retour" />
                        </CloseButton>
                    </ActionWrapper>
                </Stack>
            )}
        </Dialog>
    );
};

const SpinnerWrapper = styled.div`
    display: flex;
    justify-content: center;
`;
const IconAlert = styled(Alert)`
    color: var(--alert500);
    width: max-content;
`;
const ActionWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > * {
        width: 100%;
    }
`;
const CloseButton = styled(Button)`
    color: var(--neutral);
`;

export default PricingErrorModal;
