import { Table } from '@tanstack/react-table';
import { DownloadsComparisonData } from '@/components/unorganized/DownloadsComparisonContent';
import styled from 'styled-components';
import NoDataChartOverlay from '@/components/charts/NoData/NoDataChartOverlay';
import { FormattedMessage } from 'react-intl';

interface DownloadsComparisonTableBodyNoDataProps {
    table: Table<DownloadsComparisonData>;
}

const DownloadsComparisonTableBodyNoData = ({ table }: DownloadsComparisonTableBodyNoDataProps) => {
    return (
        <Tbody>
            {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                        return <td key={cell.id} />;
                    })}
                </tr>
            ))}
            <NoDataChartOverlay
                title={<FormattedMessage defaultMessage="Vous n’avez pas encore d’écoute" />}
                message={
                    <FormattedMessage defaultMessage="Revenez consulter vos statistiques plus tard le temps que de nouveaux auditeurs découvrent votre podcast." />
                }
            />
        </Tbody>
    );
};

const Tbody = styled.tbody`
    position: relative;
    background: var(--white);
    height: 14.5rem;

    tr td {
        border: none;
    }
`;

export default DownloadsComparisonTableBodyNoData;
