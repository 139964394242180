import { useState, useEffect } from 'react';
import Confetti from 'react-dom-confetti';
import CongratulateEpisodeSummary from '../../unorganized/CongratulateEpisodeSummary';
import Cluster from '@ui/layout/Cluster';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import useEpisodeQuery from '@/queries/episode/useEpisodeQuery.hook';
import { EPISODE_STATUS } from '@/shared/config/constants';
import SummaryEpisode from '../../unorganized/SummaryEpisode';

const SummaryEpisodeWrapper = styled.div`
    max-width: 960px;
    padding: ${(p) => (p.theme.mediaQueries.mobile ? '1.5rem 1rem' : '2rem')};
    margin: auto;
`;

const Base = styled.div`
    background: white;
    border-radius: var(--r-xs);
`;

const CongratulateWrapper = styled(Base)`
    padding: ${(p) => (p.theme.mediaQueries.mobile ? '1.5rem 1rem' : '2rem')};
    margin-bottom: 25px;
`;

const config = {
    angle: '90',
    spread: '50',
    startVelocity: '42',
    elementCount: '50',
    dragFriction: '0.07',
    duration: '2500',
    stagger: '0',
    width: '12px',
    height: '5px',
    colors: ['#5352ED', '#2ED573', '#FF4757'],
};

const SummaryEpisodePage = () => {
    const { showId, episodeId } = useParams<{ showId: string; episodeId: string }>();
    const episode = useEpisodeQuery({ episodeId, config: { staleTime: 0 } });
    const [confetti, setConfetti] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (!episode.data || !episode.isFetchedAfterMount) return;
        setTimeout(() => setConfetti(true), 1000);
        return () => setConfetti(false);
    }, [episode.data, episode.isFetchedAfterMount]);

    if (!episode.data || !episode.isFetchedAfterMount) return null;

    if (episode.data.state === EPISODE_STATUS.DRAFT) {
        history.push(`/app/show/${showId}/episodes`);
    }

    return (
        <SummaryEpisodeWrapper>
            <CongratulateWrapper>
                <CongratulateEpisodeSummary />
            </CongratulateWrapper>
            <Cluster $align="center" $justify="center">
                {/* @ts-ignore */}
                <Confetti active={confetti} config={config} />
            </Cluster>
            <SummaryEpisode />
        </SummaryEpisodeWrapper>
    );
};

export default SummaryEpisodePage;
