import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { addShowPricesQuota } from '@/api';
import quotaKeys from './quotaKeys';
import { z } from 'zod';

export const QuotaSchema = z.object({
    cost: z.string(),
    total: z.string(),
});
type InputProps = { quantity: number };
type Key = QueryFunctionContext<ReturnType<typeof quotaKeys.subscriptionPricePreview>>;
export type Quota = z.infer<typeof QuotaSchema>;

const queryFn = async ({ queryKey: [{ quantity }] }: Key) => {
    const { data } = await addShowPricesQuota({ quantity });
    return QuotaSchema.parse(data);
};

const useSubscriptionPricePreviewQuery = ({ quantity }: InputProps) => {
    return useQuery({
        queryKey: quotaKeys.subscriptionPricePreview({ quantity }),
        queryFn,
    });
};

export default useSubscriptionPricePreviewQuery;
