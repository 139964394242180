import { FormattedMessage } from 'react-intl';
import useCallToActionFormSchema, {
    CallToActionFormSchema,
} from './useCallToActionFormSchema.hook';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { InputTextController } from '@/components/ui/atoms/InputText';
import Button from '@/components/Button';
import styled from 'styled-components';

import Checkbox from '@ui/Checkbox';
import { CheckboxGroupController } from '@ui/CheckboxGroup';
import { CtaType } from '@/queries/callToAction/useCallToActionButtonsQuery.hook';

interface CallToActionFormProps {
    onClose: () => void;
    values?: CallToActionFormSchema & { id: string; type: CtaType };
    defaultValues?: CallToActionFormSchema;
    onSubmit: (data: CallToActionFormSchema) => void;
    isLoading: boolean;
    isWebsiteEnabled: boolean;
    isSmartlinkEnabled: boolean;
    isNewsletterEnabled: boolean;
    isEdit?: boolean;
}

const CallToActionForm = ({
    onClose,
    values,
    defaultValues,
    onSubmit,
    isLoading,
    isWebsiteEnabled,
    isSmartlinkEnabled,
    isNewsletterEnabled,
    isEdit,
}: CallToActionFormProps) => {
    const schema = useCallToActionFormSchema();
    const type = defaultValues?.type;
    const { control, handleSubmit, formState } = useForm<CallToActionFormSchema>({
        mode: 'onBlur',
        resolver: zodResolver(schema),
        defaultValues,
    });
    const { isValid, isDirty } = formState;

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <FormSection>
                <InputTextController
                    control={control}
                    name="text"
                    label={<FormattedMessage defaultMessage="Texte du bouton" />}
                    isRequired={true}
                    maxLength={40}
                />
                <InputTextController
                    control={control}
                    name="url"
                    label={<FormattedMessage defaultMessage="URL du bouton" />}
                    isRequired={true}
                    isReadOnly={type !== 'custom'}
                />

                <CheckboxGroupWrapper>
                    <CheckboxGroupLabel>
                        <FormattedMessage defaultMessage="Ajouter le bouton sur" />
                    </CheckboxGroupLabel>
                    <CheckboxGroupController control={control} name="locations">
                        <Checkbox
                            value="podcast_page"
                            isDisabled={
                                !isWebsiteEnabled ||
                                type === 'podcast_page' ||
                                values?.type === 'podcast_page'
                            }
                        >
                            <FormattedMessage defaultMessage="Site Web" />
                        </Checkbox>
                        <Checkbox value="player">
                            <FormattedMessage defaultMessage="Smartplayer" />
                        </Checkbox>
                        <Checkbox
                            value="smartlink"
                            isDisabled={
                                !isSmartlinkEnabled ||
                                type === 'smartlink' ||
                                values?.type === 'smartlink'
                            }
                        >
                            <FormattedMessage defaultMessage="Smartlink" />
                        </Checkbox>
                        <Checkbox
                            value="newsletter"
                            isDisabled={
                                !isNewsletterEnabled ||
                                type === 'newsletter' ||
                                values?.type === 'newsletter'
                            }
                        >
                            <FormattedMessage defaultMessage="Newsletter" />
                        </Checkbox>
                    </CheckboxGroupController>
                </CheckboxGroupWrapper>
            </FormSection>
            <SubmitWrapper>
                <Button type="button" onPress={onClose} variant="ghost">
                    <FormattedMessage defaultMessage="Annuler" />
                </Button>
                <Button type="submit" isDisabled={!isValid || !isDirty} isLoading={isLoading}>
                    {isEdit ? (
                        <FormattedMessage defaultMessage="Modifier" />
                    ) : (
                        <FormattedMessage defaultMessage="Ajouter" />
                    )}
                </Button>
            </SubmitWrapper>
        </Form>
    );
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;
const FormSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;
const SubmitWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
`;
const CheckboxGroupWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
`;
const CheckboxGroupLabel = styled.span`
    font-weight: var(--fw-semibold);
`;

export default CallToActionForm;
