import { useState } from 'react';
import Button from '@/components/Button';
import Text from '@/components/ui/atoms/Text';
import Stack from '@/components/ui/layout/Stack';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import styled from 'styled-components';
import BenefitsModal from './BenefitsModal';
import useSubscriptionQuery from '@/queries/subscription/useSubscriptionQuery.hook';
import { PRICING } from '@/shared/config/pricing';
import ExternalLink from '@/components/Link/ExternalLink';
import RouterLink from '@/components/Link/RouterLink';

const NotActive = () => {
    const subscription = useSubscriptionQuery();
    const [isBenefitsModalOpen, setIsBenefitsModalOpen] = useState(false);
    // TODO: Replace with dynamic prices
    const yearlyPriceInt = 13;
    const monthlyPriceInt = 15;
    // TODO: Use currency from context when available
    const currency = 'usd';
    const isPro = subscription.data?.pricing === PRICING.ENTERPRISE;

    return (
        <>
            <Wrapper>
                <Left>
                    <TitleWrapper>
                        <Icon icon={icon({ name: 'cloud', style: 'duotone' })} />
                        <Text variant="headingM" fontWeight="--fw-bold">
                            <FormattedMessage defaultMessage="Hébergement" />
                        </Text>
                    </TitleWrapper>
                    <BulletList>
                        <Bullet>
                            <PlusIcon icon={icon({ name: 'plus', style: 'solid' })} />
                            <Text>
                                <FormattedMessage
                                    defaultMessage="Bénéficiez d'un stockage, d'épisodes publiés et de <b>téléchargements illimités</b>."
                                    values={{
                                        b: (chunks: string) => (
                                            <Text as="span" fontWeight="--fw-semibold">
                                                {chunks}
                                            </Text>
                                        ),
                                    }}
                                />
                            </Text>
                        </Bullet>
                        <Bullet>
                            <PlusIcon icon={icon({ name: 'plus', style: 'solid' })} />
                            <Text>
                                <FormattedMessage
                                    defaultMessage="Bénéficiez d'une diffusion simple et rapide sur <b>plus d'une dizaine de plateformes d'écoute de référence</b>."
                                    values={{
                                        b: (chunks: string) => (
                                            <Text as="span" fontWeight="--fw-semibold">
                                                {chunks}
                                            </Text>
                                        ),
                                    }}
                                />
                            </Text>
                        </Bullet>
                        <Bullet>
                            <PlusIcon icon={icon({ name: 'plus', style: 'solid' })} />
                            <Text>
                                <FormattedMessage
                                    defaultMessage="Découvrez tous les <b>outils marketing puissants d'Ausha</b> pour booster votre audience."
                                    values={{
                                        b: (chunks: string) => (
                                            <Text as="span" fontWeight="--fw-semibold">
                                                {chunks}
                                            </Text>
                                        ),
                                    }}
                                />
                            </Text>
                        </Bullet>
                        <MoreButton
                            variant="link-primary"
                            onPress={() => setIsBenefitsModalOpen(true)}
                        >
                            <FormattedMessage defaultMessage="Et plus encore" />
                        </MoreButton>
                    </BulletList>
                </Left>
                <Right>
                    <Stack $gap="0.25rem">
                        <Stack>
                            <Text fontWeight="--fw-semibold">
                                <FormattedMessage defaultMessage="À partir de" />
                            </Text>
                            <Text variant="headingL" fontWeight="--fw-bold">
                                <FormattedMessage
                                    defaultMessage="{amount} /mois"
                                    values={{
                                        amount: (
                                            <FormattedNumber
                                                value={yearlyPriceInt}
                                                style="currency"
                                                currency={currency}
                                                currencyDisplay="narrowSymbol"
                                                maximumFractionDigits={0}
                                            />
                                        ),
                                    }}
                                />
                            </Text>
                        </Stack>
                        <Text color="--neutral500">
                            <FormattedMessage
                                defaultMessage="Facturation annuelle ou {amount} par mois."
                                values={{
                                    amount: (
                                        <FormattedNumber
                                            value={monthlyPriceInt}
                                            style="currency"
                                            currency={currency}
                                            currencyDisplay="narrowSymbol"
                                            maximumFractionDigits={0}
                                        />
                                    ),
                                }}
                            />
                        </Text>
                    </Stack>
                    {isPro ? (
                        <ExternalLink
                            href="mailto:sales@ausha.co"
                            variant="button-primary"
                            startIcon={
                                <ButtonIcon icon={icon({ name: 'envelope', style: 'solid' })} />
                            }
                        >
                            <FormattedMessage defaultMessage="Nous contacter" />
                        </ExternalLink>
                    ) : (
                        <RouterLink
                            to="/app/subscription/offers"
                            variant="button-primary"
                            startIcon={
                                <ButtonIcon
                                    icon={icon({ name: 'rocket-launch', style: 'solid' })}
                                />
                            }
                        >
                            <FormattedMessage defaultMessage="Souscrire à l'hébergement" />
                        </RouterLink>
                    )}
                </Right>
            </Wrapper>
            <BenefitsModal isOpen={isBenefitsModalOpen} onOpenChange={setIsBenefitsModalOpen} />
        </>
    );
};

const Icon = styled(FontAwesomeIcon)`
    width: 1.25rem;
    height: 1.25rem;
    color: var(--primary);
`;
const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.75rem;
`;
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
    }
`;
const Left = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-grow: 1;
    }
`;
const Right = styled.div`
    padding: 1.5rem;
    border-radius: var(--r-m);
    background-color: var(--primary50);
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    min-width: 20rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-shrink: 0;
    }
`;
const PlusIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const BulletList = styled.ul`
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
`;
const Bullet = styled.li`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
`;
const MoreButton = styled(Button)`
    text-decoration: underline;
    padding-inline: 1.25rem 0;
    padding-block: 0 0;
    align-self: flex-start;
    font-weight: var(--fw-semibold);
`;
const ButtonIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;

export default NotActive;
