import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import Link from '@ui/atoms/Link';
import AushaClubButton from '@/components/unorganized/AushaClubButton';
import HideFor from '@/components/unorganized/HideFor';
import Cluster from '@ui/layout/Cluster';
import Text from '@ui/atoms/Text';
import UiKitTooltip from '@ui/atoms/UiKitTooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import UserDropdownMenu from './UserDropdownMenu';
import FeedbackModal from './FeedbackModal';
import OutsideClickHandler from 'react-outside-click-handler';
import useSubscriptionQuery from '@queries/subscription/useSubscriptionQuery.hook';
import { PRICING } from '@/shared/config/pricing';
import { useOverlayTriggerState } from 'react-stately';
import AushaClubModal from '@/components/unorganized/AushaClubModal';
import useUserQuery from '@queries/user/useUserQuery.hook';
import { useContext } from 'react';
import { CurrentShow } from '@/context/CurrentShowContext';
import { sendAmplitudeLogEvent } from '@/shared/utils/amplitude';

const AushaHeaderMenu = () => {
    const show = useContext(CurrentShow);
    const user = useUserQuery();
    const { data: subscription } = useSubscriptionQuery();
    const isSubscriptionActive = !!(show?.ownerPricing || subscription?.activated);

    const aushaClubModalState = useOverlayTriggerState({});
    const [dropdownMenuOpened, setDropdownMenuOpened] = useState(false);
    const [feedbackOpened, setFeedbackOpened] = useState();
    const hasUser = user && user.isLoading === false && user.isError === false;
    const pmpUser = user?.data?.canHostOnAusha === false;

    const handleOpenAushaClubModal = () => {
        if (isSubscriptionActive) return;
        aushaClubModalState.open();
    };

    return (
        <>
            <AushaHeaderMenuWrapper>
                <MenuIconsWraper>
                    <MobileWrapper>
                        {!pmpUser && <AushaClubButton onClick={handleOpenAushaClubModal} />}
                    </MobileWrapper>
                    <IconsWrapper>
                        {!pmpUser && (
                            <UiKitTooltip
                                variant="dark"
                                position="bottom"
                                content={
                                    <FormattedMessage defaultMessage="Bibliothèque musicale" />
                                }
                            >
                                <IconLinkWrapper to="/app/song-library">
                                    <FontAwesomeIcon
                                        icon={icon({
                                            name: 'list-music',
                                            style: 'duotone',
                                        })}
                                        size="1x"
                                    />
                                </IconLinkWrapper>
                            </UiKitTooltip>
                        )}
                        {!pmpUser && (
                            <HideFor condition={subscription?.activated}>
                                <UiKitTooltip
                                    variant="dark"
                                    position="bottom"
                                    content={<FormattedMessage defaultMessage="Parrainage" />}
                                >
                                    <IconLinkWrapper
                                        onClick={() =>
                                            sendAmplitudeLogEvent(
                                                'Clicked on Referral Program Icon',
                                            )
                                        }
                                        to="/app/sponsorship"
                                    >
                                        <FontAwesomeIcon
                                            icon={icon({
                                                name: 'gift',
                                                style: 'duotone',
                                            })}
                                            size="1x"
                                        />
                                    </IconLinkWrapper>
                                </UiKitTooltip>
                            </HideFor>
                        )}
                        <UiKitTooltip
                            variant="dark"
                            position="bottom"
                            content={<FormattedMessage defaultMessage="Nouveautés" />}
                        >
                            <IconLinkWrapper
                                as="a"
                                href="https://updates.ausha.co/"
                                target="_blank"
                            >
                                <FontAwesomeIcon
                                    icon={icon({
                                        name: 'rocket-launch',
                                        style: 'duotone',
                                    })}
                                    size="1x"
                                />
                            </IconLinkWrapper>
                        </UiKitTooltip>
                    </IconsWrapper>
                    <Separator />
                    <OutsideClickHandler onOutsideClick={() => setDropdownMenuOpened(false)}>
                        <Cluster $gap="0.5rem" $align="center" $wrap="nowrap">
                            <Cluster
                                $gap="0.25rem"
                                onClick={() => {
                                    setDropdownMenuOpened(!dropdownMenuOpened);
                                }}
                                $wrap="nowrap"
                                $align="center"
                                data-testid="dropdown-user-menu-link"
                            >
                                {subscription?.isFreemium && (
                                    <Badge>
                                        <Text as="span" fontWeight="--fw-bold">
                                            FREE
                                        </Text>
                                    </Badge>
                                )}
                                {subscription?.pricing === PRICING.ENTERPRISE && (
                                    <Badge>
                                        <Text as="span" fontWeight="--fw-bold">
                                            PRO
                                        </Text>
                                    </Badge>
                                )}
                                {hasUser ? (
                                    <StyledText color="white" align="center">
                                        {user.data.firstname}
                                    </StyledText>
                                ) : (
                                    <TextSkeleton />
                                )}
                                <Chevron
                                    icon={icon({
                                        name: 'chevron-down',
                                        style: 'solid',
                                    })}
                                    color="white"
                                    size="xs"
                                    $rotated={dropdownMenuOpened}
                                />
                            </Cluster>
                        </Cluster>
                        <UserDropdownMenu
                            visible={dropdownMenuOpened}
                            onClose={() => setDropdownMenuOpened(false)}
                            onOpenFeedback={() => setFeedbackOpened(true)}
                        />
                    </OutsideClickHandler>
                </MenuIconsWraper>
            </AushaHeaderMenuWrapper>
            <FeedbackModal isOpen={feedbackOpened} onOpenChange={setFeedbackOpened} />
            {aushaClubModalState.isOpen && (
                <AushaClubModal
                    isOpen={aushaClubModalState.isOpen}
                    onClose={aushaClubModalState.close}
                />
            )}
        </>
    );
};

const AushaHeaderMenuWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    padding-right: 1rem;
`;
const IconsWrapper = styled.div`
    display: none;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: flex;
        align-items: center;
        gap: 0.75rem;
        z-index: 1100;
    }
`;
const IconLinkWrapper = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
`;
const StyledText = styled(Text)`
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    cursor: pointer;
    font-weight: var(--fw-semibold);
`;
const MenuIconsWraper = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    column-gap: 0.75rem;
`;
const Separator = styled.div`
    width: 2px;
    height: 1.25rem;
    background: var(--neutral100);
    background: #897dca;
    display: none;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: block;
    }
`;
const TextSkeleton = styled.div`
    width: 6rem;
    height: 0.75rem;
    background-color: var(--white200);
    border-radius: var(--r-full);
    margin-block: 0.25rem;
`;
const Chevron = styled(FontAwesomeIcon)`
    transition-duration: 0.5s;
    transition-timing-function: var(--spring-easing);
    ${({ $rotated }) =>
        $rotated &&
        css`
            transform: rotate(-180deg);
        `}
`;

const Badge = styled.div`
    background: var(--white200);
    color: var(--white);
    padding: 0.3125rem 0.5rem;
    border-radius: var(--r-s);
    line-height: 0.875rem;
    cursor: pointer;
`;
const MobileWrapper = styled.div`
    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        display: none;
    }
`;

export default AushaHeaderMenu;
