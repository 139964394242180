import { FormattedMessage } from 'react-intl';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import DeprecatedSelect from '@ui/molecules/DeprecatedSelect';
import DeprecatedButton from '@ui/atoms/DeprecatedButton';
import { connect } from '@/components/legacy/connect';
import FetcherShowTemplate from '@/components/unorganized/FetcherShowTemplate';
import ShortListFallback from '@/components/unorganized/ShortListFallback';
import Form from '@/components/unorganized/Form';
import ShowSharingTemplateForm from '../../forms/ShowSharingTemplateForm';
import Spinner from '@ui/atoms/Spinner';
import TemplateEditor from './TemplateEditor';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import useDeleteShowTemplateMutation from '@queries/showTemplate/useDeleteShowTemplateMutation.hook';

const enhance = connect(({ routerStore, autoSharingStore }) => {
    const template = autoSharingStore.facebookShowTemplate;
    return {
        pageListSource: autoSharingStore.facebookPagesSource,
        showId: routerStore.params.showId,
        template,
        onSubmit: (formData) =>
            template.updateShowFacebookTemplate(routerStore.params.showId, formData),
        isLoading: template && template.updateShowFacebookTemplate.pending,
    };
});

function FacebookTemplateShow({
    onSubmit,
    isLoading,
    template,
    pageListSource,
    headerProvider,
    showId,
    account,
}) {
    const deleteShowTemplate = useDeleteShowTemplateMutation();
    return (
        <FetcherShowTemplate fallback={ShortListFallback} provider="facebook" showId={showId}>
            {(isFetching) =>
                !isFetching &&
                template && (
                    <Form form={() => ShowSharingTemplateForm(template)} onSubmit={onSubmit}>
                        {({ fields }) => (
                            <>
                                <DeprecatedPaper flex justify="space-between">
                                    {headerProvider}
                                    <DeprecatedPaper flex align="flex-end" column>
                                        {account && (
                                            <DeprecatedText weight="semibold">
                                                @{account.name}
                                            </DeprecatedText>
                                        )}
                                        <DeprecatedText
                                            weight="semibold"
                                            color="--primary"
                                            onClick={() => {
                                                deleteShowTemplate.mutate({
                                                    showId,
                                                    provider: 'facebook',
                                                });
                                            }}
                                            disabled={deleteShowTemplate.isLoading}
                                        >
                                            <FormattedMessage defaultMessage="Délier le compte" />
                                        </DeprecatedText>
                                    </DeprecatedPaper>
                                </DeprecatedPaper>
                                <DeprecatedSelect
                                    w={250}
                                    mb={30}
                                    field={fields.pageId}
                                    source={pageListSource}
                                    label={
                                        <FormattedMessage defaultMessage="Publier sur la page" />
                                    }
                                />
                                <TemplateEditor field={fields.text} />
                                <DeprecatedButton
                                    mt={30}
                                    mx="auto"
                                    type="submit"
                                    disabled={isLoading}
                                    background="facebook"
                                >
                                    {isLoading && <Spinner />}
                                    <FormattedMessage defaultMessage="Enregistrer" />
                                </DeprecatedButton>
                            </>
                        )}
                    </Form>
                )
            }
        </FetcherShowTemplate>
    );
}

export default enhance(FacebookTemplateShow);
