import { FormattedMessage } from 'react-intl';
import Text from '@ui/atoms/Text';
import ExternalLinksForm from './ExternalLinksForm';
import { useAmplitudeLogEvent } from '@/shared/hooks/useAmplitude';
import { useParams } from 'react-router-dom';
import { useUpdateSocialLinks } from './useUpdateSocialLinks.hook';
import styled from 'styled-components';
import useShowQuery from '@queries/show/useShowQuery.hook';
import { useModalToastQueue } from '@/shared/hooks/useModalToastQueue.hook';

function ExternalLinks() {
    useAmplitudeLogEvent('Communication Settings Accessed');
    useAmplitudeLogEvent('show links');
    const { showId } = useParams();
    const { data, error, isLoading } = useShowQuery(showId);
    const updateExternalLinks = useUpdateSocialLinks();
    useAmplitudeLogEvent('show links');
    const toast = useModalToastQueue();

    if (error) return <span>Error</span>;
    if (isLoading) return <span>Loading...</span>;

    return (
        <ExternalLinksWrapper>
            <InnerExternalLinksWrapper>
                <Text color="--neutral500">
                    <FormattedMessage defaultMessage="Configurez les liens ci-dessous pour qu'ils apparaissent sur votre Site Web et votre Smartlink." />
                </Text>
                <ExternalLinksForm
                    externalLinks={data.socialLinks.data.reduce(
                        (obj, cur) => ({ ...obj, [cur.key]: cur.url }),
                        {},
                    )}
                    onSubmit={(values) =>
                        updateExternalLinks.mutate(
                            {
                                showId,
                                externalLinks: values,
                            },
                            {
                                onError: () =>
                                    toast.alert(
                                        <FormattedMessage defaultMessage="Oups, les modifications n’ont pas été enregistrées. Veuillez réessayer." />,
                                    ),
                                onSuccess: () =>
                                    toast.success(
                                        <FormattedMessage defaultMessage="Les modifications ont bien été enregistrées." />,
                                    ),
                            },
                        )
                    }
                    isMutating={isLoading || updateExternalLinks.isLoading}
                />
            </InnerExternalLinksWrapper>
        </ExternalLinksWrapper>
    );
}

const ExternalLinksWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding);

    ${({ theme }) => theme.mediaQueries.mobile} {
        padding: var(--container-padding-mobile);
    }
`;
const InnerExternalLinksWrapper = styled.div`
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 2rem;

    ${(props) => props.theme.mediaQueries.tabletAndUp} {
        width: 60%;
        padding: 2rem;
    }
`;

export default ExternalLinks;
