import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchClipFormatsOfShow } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import clipFormatKeys from './clipFormatKeys';
import { z } from 'zod';
import { ClipFormatRaw } from './types';
import { ClipFormatSchema } from './ClipFormat.schema';

const PaginationSchema = z.object({
    currentPage: z.number(),
    totalPages: z.number(),
    total: z.number(),
    perPage: z.number(),
    count: z.number(),
});

const ClipFormatsResponseSchema = z.object({
    clipFormats: z.array(ClipFormatSchema),
    pagination: PaginationSchema,
});

type Key = QueryFunctionContext<ReturnType<typeof clipFormatKeys.listByShowId>>;

const queryFn = async ({ queryKey: [{ showId, page, perPage }] }: Key) => {
    const { data, meta }: { data: ClipFormatRaw[]; meta: any } = await fetchClipFormatsOfShow(
        showId,
        page,
        perPage,
    );

    const formattedData = {
        clipFormats: data.map((clipFormat: ClipFormatRaw) => {
            const { podcast_video_clip, images, ...rest } = clipFormat;
            const { podcast_id, ...clip } = podcast_video_clip.data;
            return {
                ...rest,
                images: images.data,
                clip: {
                    ...clip,
                    episodeId: podcast_id,
                },
            };
        }),
        pagination: {
            currentPage: meta?.pagination?.current_page || 0,
            totalPages: meta?.pagination?.total_pages || 0,
            total: meta?.pagination?.total || 0,
            perPage: meta?.pagination?.per_page || 0,
            count: meta?.pagination?.count || 0,
        },
    };

    return ClipFormatsResponseSchema.parse(camelcaseKeys(formattedData, { deep: true }));
};

interface InputProps {
    showId: string;
    pagination: {
        page: number;
        perPage: number;
    };
    options?: any;
}

const useShowClipFormatsQuery = ({
    showId,
    pagination: { page = 1, perPage = 20 },
    options,
}: InputProps) => {
    return useQuery({
        queryKey: clipFormatKeys.listByShowId(showId, { page, perPage }),
        queryFn,
        enabled: options?.enabled ?? !!showId,
        refetchOnWindowFocus: options?.enabled ?? false,
    });
};

export default useShowClipFormatsQuery;
