import styled from 'styled-components';
import Button from '@/components/Button';
import { useAccordion } from '@ui/atoms/Accordion/useAccordion.hook';
import { FormattedMessage } from 'react-intl';

const NextButton = styled(Button)`
    box-shadow: var(--s-primary);
    align-self: flex-end;
`;

const NextStepButton = () => {
    const { goToNextIndex } = useAccordion();

    const onPress = () => {
        goToNextIndex();
    };

    return (
        <NextButton onPress={onPress}>
            <FormattedMessage defaultMessage="Suivant" />
        </NextButton>
    );
};

export default NextStepButton;
