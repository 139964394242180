import { createContext, useState, useMemo, useEffect } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { AnimateSharedLayout } from 'framer-motion';
import Link from '../../atoms/Link';
import DeprecatedPaper from '../../atoms/DeprecatedPaper';
import TabItemAccess from '../../../app/atoms/TabItemAccess';
import Desktop from '../Desktop';
import MobileTabsAccess from '../../../app/atoms/MobileTabsAccess';

export const RouteHorizontalTabSlideContext = createContext(null);

const HorizontalTabSlide = ({
    tabsTitles,
    pageUrl,
    redirectTab = [],
    simpleSwitch,
    buttonResetStyles,
    getTabValue,
    active = true,
    tabsLockedTooltip,
    pricing,
    userOptions,
    roles,
    tooltipArrow = 'bottom',
    query,
    forceDesktop = false,
    onChange,
    ...props
}) => {
    const { params } = useRouteMatch();
    const { search } = useLocation();
    const [selectedRoute, setSelectedRoute] = useState(params.tab);
    const [selectedTab, setSelectedTab] = useState(Object.keys(tabsTitles)[0]);
    // if params has only two keys, the location URL is taken from show
    const isShowParams = Object.getOwnPropertyNames(params).length < 3 && !query;
    // params is remembered to improve the performance of the url
    const [currentParams, setCurrentParams] = useState({});
    useMemo(() => setCurrentParams(params), [params.itemType]);
    useMemo(() => setSelectedRoute(params.tab), [params.tab]);
    useEffect(() => {
        if (simpleSwitch && getTabValue) getTabValue(selectedTab);
    }, []);

    if (!active) return null;

    const getRouteUrl = (item) => {
        if (search) return `${pageUrl}/${item}${search}`;
        return `${pageUrl}/${currentParams.itemType}/${currentParams.itemId}/${item}`;
    };

    const getSelected = () => {
        const tabState = simpleSwitch ? 'switch' : 'routeLink';
        const tabSelector = {
            routeLink: { setterTab: (item) => setSelectedRoute(item), selected: selectedRoute },
            switch: {
                setterTab: (item) => {
                    setSelectedTab(item);
                    onChange && onChange(item);
                },
                selected: selectedTab,
            },
        };
        return tabSelector[tabState];
    };

    const { selected, setterTab } = getSelected();

    const handleSwitchTab = (item) => {
        setterTab(item);
        if (simpleSwitch && getTabValue) getTabValue(item);
    };

    const {
        m = !buttonResetStyles && 'auto',
        p,
        px,
        py,
        pt = !buttonResetStyles && 25,
        pr,
        pb,
        pl,
        justify = !buttonResetStyles && 'center',
        align,
    } = props;
    const stylized = { m, p, px, py, pt, pr, pb, pl, justify, align };

    const businessData = pricing || userOptions || roles;

    const DesktopMode = () => (
        <DeprecatedPaper flex align={align} {...stylized} w="max-content">
            <AnimateSharedLayout>
                <DeprecatedPaper
                    h={35}
                    flex
                    align="center"
                    background="--neutral100"
                    rounded
                    p={2}
                    pos="relative"
                >
                    {Object.keys(tabsTitles).map((item) => (
                        <TabItemAccess
                            key={item}
                            isSelected={selected === item}
                            onClick={() => handleSwitchTab(item)}
                            as={Link}
                            to={
                                isShowParams || redirectTab.includes(item)
                                    ? `${pageUrl}/${item}`
                                    : getRouteUrl(item)
                            }
                            simpleSwitch={simpleSwitch}
                            tabsLocked={
                                businessData && Object.keys(tabsLockedTooltip).includes(item)
                            }
                            tooltip={
                                businessData &&
                                Object.keys(tabsLockedTooltip).includes(item) &&
                                tabsLockedTooltip[item]
                            }
                            pricing={pricing}
                            userOptions={userOptions}
                            roles={roles}
                            tooltipArrow={tooltipArrow}
                        >
                            {tabsTitles[item]}
                        </TabItemAccess>
                    ))}
                </DeprecatedPaper>
            </AnimateSharedLayout>
        </DeprecatedPaper>
    );

    return (
        <>
            {forceDesktop ? (
                <DesktopMode />
            ) : (
                <>
                    <MobileTabsAccess
                        pageUrl={pageUrl}
                        redirectTab={redirectTab}
                        tabsTitles={tabsTitles}
                    />
                    <Desktop>
                        <DesktopMode />
                    </Desktop>
                </>
            )}
        </>
    );
};

export default HorizontalTabSlide;
