import { css, keyframes } from 'styled-components';
import { resolveColor } from '@/utils/cssVariables';

const basicStyles = css`
    display: flex;
    align-items: center;
    gap: 8px;
    width: max-content;
    border: 0;
    border-radius: var(--r-s);
    font-weight: var(--fw-bold);
    font-size: var(--fs-body);
    cursor: pointer;
    justify-content: center;
    text-decoration: initial;
    white-space: nowrap;
    transition: all 0.25s ease-in-out;
    &:disabled {
        cursor: default;
        opacity: 0.5;
    }
`;

// --- Commons styles ---
const commonsShapesStyles = {
    normal: css`
        height: 40px;
        padding: 10px 16px;
    `,
    small: css`
        height: 32px;
        padding: 6px 12px;
    `,
    big: css`
        height: 57px;
        min-width: 57px;
        svg {
            width: 32px;
            height: 32px;
        }
    `,
    icon: css`
        padding: 0;
        height: 40px;
        min-width: 40px;
        svg {
            width: 16px;
            height: 16px;
        }
    `,
    iconSmall: css`
        padding: 0;
        height: 32px;
        min-width: 32px;
        svg {
            min-width: 16px;
            min-height: 16px;
            width: 16px;
            height: 16px;
        }
    `,
    rounded: css`
        border-radius: var(--r-full);
    `,
    floating: css`
        position: fixed;
        bottom: 20px;
        right: 25px;
        z-index: 3;
        width: 56px;
        height: 56px;
    `,
    fullWidth: css`
        width: 100%;
    `,
    ripple: css`
        position: relative;
        z-index: 1;
        &:before,
        &:after {
            content: '';
            z-index: -1;
            position: absolute;
            width: 40px;
            height: 40px;
            border-radius: var(--r-full);
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) scale(1);
            background: var(--primary);
        }
    `,
};

// --- Primary Commons ---
const primaryStyles = css`
    color: var(--white);
    box-shadow: 0 5px 12px var(--primary100);
    background: var(--primary);
    &:disabled,
    &:hover {
        box-shadow: initial;
    }
    &:hover {
        filter: brightness(90%);
    }
    svg {
        fill: var(--white);
    }
`;

// --- Secondary Commons ---
const secondaryStyles = css`
    font-weight: var(--fw-semibold);
    color: var(--black);
    background: var(--white);
    border: 1px solid var(--neutral200);
    &:hover {
        background: ${(p) => !p.disabled && 'var(--neutral100)'};
    }
    svg {
        fill: var(--black);
    }
`;

// --- Ghost Commons ---
const ghostStyles = css`
    color: var(--white);
    background: var(--white200);
    &:hover {
        background: ${(p) => !p.disabled && 'var(--white300)'};
    }
    svg {
        fill: var(--white);
    }
`;

// --- Custom Styles  ---
// Use for Provider DeprecatedButton for example
// If you use background props, DeprecatedButton become custom and take its background color
// Then you can use color props too for change text color. You can also put theme colors light with [name]Light[number]
// By default if you use background props with light (e.g: background="[name]Light30"), hover will be set to 30 - 20 = Light10
const customStyles = css`
    color: ${(p) => resolveColor(p.color)};
    background: ${(p) => resolveColor(p.background)};
    box-shadow: ${(p) =>
        p.noBoxShadow ? 'initial' : `0 5px 12px ${resolveColor(`${p.background}100`)}`};
    &:hover {
        background: ${(p) =>
            !p.disabled && !p.background.match(/(Light)\d+/gm)
                ? resolveColor(`${p.background}700`)
                : resolveColor(`${p.background}200`)};
    }
    svg {
        fill: ${(p) => resolveColor(p.color)};
    }
`;

const custom = css`
    ${(p) => (p.background ? customStyles : undefined)};
`;

// --- Keyframes ---
const effectsKeyframes = {
    ripple: keyframes`
      from {
        transform: translate(-50%, -50%) scale(1);
        opacity: 0.5;
      }
      to {
        transform: translate(-50%, -50%) scale(2);
        opacity: 0;
      }
  `,
};

const primary = css`
    ${commonsShapesStyles.normal};
    ${primaryStyles};
    ${custom};
    ${(p) => p.icon && commonsShapesStyles.icon};
    ${(p) => p.rounded && commonsShapesStyles.rounded};
    ${(p) => p.fullWidth && commonsShapesStyles.fullWidth};
    ${(p) => p.floating && commonsShapesStyles.floating};
    ${(p) => p.big && commonsShapesStyles.big};
`;

const primarySmall = css`
    ${commonsShapesStyles.small};
    ${primaryStyles};
    ${custom};
    ${(p) => p.icon && commonsShapesStyles.iconSmall};
    ${(p) => p.rounded && commonsShapesStyles.rounded};
    ${(p) => p.fullWidth && commonsShapesStyles.fullWidth};
`;

const secondary = css`
    ${commonsShapesStyles.normal};
    ${secondaryStyles};
    ${(p) => p.icon && commonsShapesStyles.icon};
    ${(p) => p.fullWidth && commonsShapesStyles.fullWidth};
`;

const secondarySmall = css`
    ${commonsShapesStyles.small};
    ${secondaryStyles};
    ${(p) => p.icon && commonsShapesStyles.iconSmall};
    ${(p) => p.fullWidth && commonsShapesStyles.fullWidth};
`;

const rounded = css`
    ${commonsShapesStyles.normal};
    ${commonsShapesStyles.rounded};
    ${secondaryStyles};
    ${(p) => p.icon && commonsShapesStyles.icon};
`;

const roundedSmall = css`
    ${commonsShapesStyles.small};
    ${commonsShapesStyles.rounded};
    ${secondaryStyles};
    ${(p) => p.icon && commonsShapesStyles.iconSmall};
`;

const ghost = css`
    ${commonsShapesStyles.normal};
    ${commonsShapesStyles.rounded};
    ${ghostStyles};
    ${(p) => p.icon && commonsShapesStyles.icon};
`;

const ghostSmall = css`
    ${commonsShapesStyles.small};
    ${commonsShapesStyles.rounded};
    ${ghostStyles};
    ${(p) => p.icon && commonsShapesStyles.iconSmall};
`;

const ripple = css`
    ${primaryStyles};
    ${commonsShapesStyles.rounded};
    ${commonsShapesStyles.icon};
    ${commonsShapesStyles.ripple};
    &:before,
    &:after {
        animation: ${effectsKeyframes.ripple} 1500ms infinite;
    }
    &:after {
        animation-delay: 1s;
    }
`;

const accents = {
    primary,
    primarySmall,
    secondary,
    secondarySmall,
    rounded,
    roundedSmall,
    ghost,
    ghostSmall,
    ripple,
};

const shapes = css`
    ${basicStyles}
    ${(p) => accents[p.accent]}
`;

export default shapes;
