import { useEffect, useState } from 'react';
import { InputRichPlainTextController } from '@/components/ui/InputRichPlainText/InputRichPlainText.controller';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { NonHostingEpisodeFormSchema } from '../useNonHostingEpisodeFormSchema.hook';
import Cluster from '@/components/ui/layout/Cluster';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Stack from '@/components/ui/layout/Stack';
import EpisodeAIInstagramSuggestionPanel from '../NonHostingEpisodeAI/EpisodeAIInstagramSuggestionPanel';
import { useAccordionItem } from '@/components/ui/atoms/AccordionItem/useAccordionItem.hook';
import {
    EPISODE_AI_LOADING_ACTIONS,
    useEpisodeAILoading,
} from '../contexts/NonHostingEpisodeAILoadingContext';
import { AISocialNetwork } from '@/queries/ai/useAISocialPostsSuggestionsQuery';

interface InstagramFieldProps {
    onLoadingEnd: (network: AISocialNetwork) => void;
}

const InstagramField = ({ onLoadingEnd }: InstagramFieldProps) => {
    const { control } = useFormContext<NonHostingEpisodeFormSchema>();
    const intl = useIntl();
    const [loadingContent, setLoadingContent] = useState('');
    const { isExpanded } = useAccordionItem();
    const [state, dispatch] = useEpisodeAILoading();
    const isLoading = state.get('smm-instagram') || false;

    // Stop wysiwyg loading animation when the accordion is closed
    useEffect(() => {
        if (!isExpanded) {
            handleStopLoading();
        }
    }, [isExpanded]);

    const handleStopLoading = () => {
        dispatch({
            type: EPISODE_AI_LOADING_ACTIONS.STOP,
            payload: 'smm-instagram',
        });
        onLoadingEnd('instagram');
        setLoadingContent('');
    };

    return (
        <Stack>
            <InputRichPlainTextController
                name="socialPosts.instagram"
                control={control}
                label={
                    <Cluster $gap="0.25rem" $align="center">
                        <InstagramIcon icon={icon({ name: 'instagram', style: 'brands' })} />
                        Instagram
                    </Cluster>
                }
                placeholder={intl.formatMessage(
                    {
                        defaultMessage: 'Saisir le post {platform}',
                    },
                    {
                        platform: 'Instagram',
                    },
                )}
                isLoading={isLoading}
                charactersLimit={2200}
                onLoadingEnd={handleStopLoading}
                loadingContent={loadingContent}
            />
            <EpisodeAIInstagramSuggestionPanel setLoadingContent={setLoadingContent} />
        </Stack>
    );
};

const InstagramIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: var(--instagram);
`;

export default InstagramField;
