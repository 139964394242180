import { useParams } from 'react-router-dom';
import useFetchSoundcloudBroadcastSettings from './useFetchSoundcloudBroadcastSettings.hook';
import useUpdateSoundcloudBroadcastSettings from './useUpdateSoundcloudBroadcastSettings.hook';
import ListFormFallback from '@/components/unorganized/ListFormFallback';
import SoundcloudBroadcastSettingsForm from './SoundcloudBroadcastSettingsForm';
import { useModalToastQueue } from '@hooks/useModalToastQueue.hook';

const SoundcloudBroadcastSettings = () => {
    const { showId } = useParams();
    const settings = useFetchSoundcloudBroadcastSettings(showId);
    const updateSettings = useUpdateSoundcloudBroadcastSettings(showId);
    const toast = useModalToastQueue();

    const onSubmit = (formData) => {
        updateSettings.mutate(formData, {
            onSuccess: () => toast.success(),
            onError: () => toast.alert(),
        });
    };

    if (settings.isLoading) return <ListFormFallback />;

    return (
        <SoundcloudBroadcastSettingsForm
            settings={settings.data}
            onSubmit={onSubmit}
            isLoading={updateSettings.isLoading}
        />
    );
};

export default SoundcloudBroadcastSettings;
