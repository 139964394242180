import arrowDown from '@public/icons/chevrondown.svg';
import DeprecatedIcon from '../DeprecatedIcon';
import styled from 'styled-components';

const Arrow = styled.span`
    display: inline-block;
    transform: scaleY(${(props) => (props.order === 'asc' ? -1 : 1)});
    transition: transform 350ms;
`;

function TableArrow({ order, ...props }) {
    return (
        <Arrow order={order}>
            <DeprecatedIcon as={arrowDown} color="--neutral500" {...props} />
        </Arrow>
    );
}

export default TableArrow;
