import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';
import { statusValue } from '@/shared/services/episodeStateService';
import Badge from '@/components/ui/atoms/Badge';

interface StatusProps {
    status: 'draft' | 'blocked' | 'scheduled' | 'active';
    privacy: 'private' | 'public' | 'unlisted';
}

const Status = ({ status, privacy }: StatusProps) => {
    const s = statusValue({ status, privacy });
    return (
        <Badge
            icon={statusLookup[s as keyof typeof statusLookup].icon}
            variant={statusLookup[s as keyof typeof statusLookup].color}
        >
            {statusLookup[s as keyof typeof statusLookup].message}
        </Badge>
    );
};

const StatusIcon = styled(FontAwesomeIcon)`
    font-size: 0.75rem;
`;

const statusLookup = {
    blocked: {
        icon: <StatusIcon icon={icon({ name: 'circle-exclamation', style: 'solid' })} />,
        message: <FormattedMessage defaultMessage="Bloqué" />,
        hoverMessage: <FormattedMessage defaultMessage="Épisode bloqué" />,
        color: 'alert',
    },
    done: {
        icon: <StatusIcon icon={icon({ name: 'circle-check', style: 'solid' })} />,
        message: <FormattedMessage defaultMessage="Publié" />,
        hoverMessage: null,
        color: 'success',
    },
    draft: {
        icon: <StatusIcon icon={icon({ name: 'pen-line', style: 'solid' })} />,
        message: <FormattedMessage defaultMessage="Brouillon" />,
        hoverMessage: <FormattedMessage defaultMessage="Épisode en brouillon" />,
        color: 'neutral',
    },
    ended: {
        icon: <StatusIcon icon={icon({ name: 'circle-check', style: 'solid' })} />,
        message: <FormattedMessage defaultMessage="Terminé" />,
        hoverMessage: <FormattedMessage defaultMessage="Épisode terminé" />,
        color: 'primary',
    },
    error: {
        icon: <StatusIcon icon={icon({ name: 'circle-exclamation', style: 'solid' })} />,
        message: <FormattedMessage defaultMessage="Erreur" />,
        hoverMessage: null,
        color: 'alert',
    },
    online: {
        icon: <StatusIcon icon={icon({ name: 'eye', style: 'solid' })} />,
        message: <FormattedMessage defaultMessage="Public" />,
        hoverMessage: <FormattedMessage defaultMessage="Épisode en ligne" />,
        color: 'success',
    },
    planified: {
        icon: <StatusIcon icon={icon({ name: 'clock', style: 'regular' })} />,
        message: <FormattedMessage defaultMessage="Planifié" />,
        hoverMessage: <FormattedMessage defaultMessage="Épisode planifié" />,
        color: 'info',
    },
    planned: {
        icon: <StatusIcon icon={icon({ name: 'clock', style: 'solid' })} />,
        message: <FormattedMessage defaultMessage="Planifié" />,
        hoverMessage: <FormattedMessage defaultMessage="Épisode planifié" />,
        color: 'info',
    },
    private: {
        icon: <StatusIcon icon={icon({ name: 'eye-slash', style: 'solid' })} />,
        message: <FormattedMessage defaultMessage="Privé" />,
        hoverMessage: <FormattedMessage defaultMessage="Épisode privé" />,
        color: 'alert',
    },
    published: {
        icon: <StatusIcon icon={icon({ name: 'circle-check', style: 'solid' })} />,
        message: <FormattedMessage defaultMessage="Publié" />,
        hoverMessage: null,
        color: 'success',
    },
    publish_at: {
        icon: <StatusIcon icon={icon({ name: 'clock', style: 'solid' })} />,
        message: <FormattedMessage defaultMessage="Planifié" />,
        hoverMessage: null,
        color: 'info',
    },
    scheduled: {
        icon: <StatusIcon icon={icon({ name: 'clock', style: 'regular' })} />,
        message: <FormattedMessage defaultMessage="Planifié" />,
        hoverMessage: <FormattedMessage defaultMessage="Épisode planifié" />,
        color: 'info',
    },
    unlisted: {
        icon: <StatusIcon icon={icon({ name: 'link', style: 'solid' })} />,
        message: <FormattedMessage defaultMessage="Non-listé" />,
        hoverMessage: <FormattedMessage defaultMessage="Épisode non-listé" />,
        color: 'warning',
    },
};

export default Status;
