import { FormattedMessage } from 'react-intl';
import { withTheme } from 'styled-components';
import Collapse from '@ui/molecules/Collapse';
import { connect } from '../decorators/connect';
import AudioFileImporter from '@ui/molecules/AudioFileImporter';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import waveform from '@public/images/waveform.svg';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import { reflex } from '@ui/reflex';
import { MP3_AUDIO_TYPES } from '@/utils/constants';
import { extractCssVariable } from '@/utils/cssVariables';

const Waveform = reflex(waveform);

const enhance = connect(({ routerStore }) => {
    const campaign = routerStore.resolve('campaignId') || {};
    return {
        onUpload: campaign.uploadFile,
        file: campaign.audioFile,
        onDelete() {
            campaign.audioFile = undefined;
        },
    };
});

function CampaignAudio({ file, onUpload, onDelete, fields, theme, isImmutable, ...props }) {
    return (
        <Collapse title={<FormattedMessage defaultMessage="Fichier audio" />} {...props}>
            {fields.type.value === 'hostread' ? (
                <DeprecatedPaper flex>
                    <Waveform w={270} fill={extractCssVariable('--neutral200')} mr={20} />
                    <DeprecatedPaper>
                        <DeprecatedText weight="semibold" size="big" mb={10}>
                            <FormattedMessage defaultMessage="Aucun fichier requis." />
                        </DeprecatedText>
                        <DeprecatedText>
                            <FormattedMessage defaultMessage="Vous avez sélectionné l’option Host-read pour votre campagne." />
                        </DeprecatedText>
                        <DeprecatedText>
                            <FormattedMessage defaultMessage="Il n’y a donc pas de fichier audio à ajouter." />
                        </DeprecatedText>
                    </DeprecatedPaper>
                </DeprecatedPaper>
            ) : (
                <AudioFileImporter
                    file={file}
                    onUpload={onUpload}
                    onDelete={onDelete}
                    accept={MP3_AUDIO_TYPES}
                    maxSize={30000000}
                    isDisabled={isImmutable}
                    tooltip={
                        isImmutable && (
                            <FormattedMessage defaultMessage="Il n'est pas possible de modifier le fichier audio d'une campagne lorsque celle-ci est publiée." />
                        )
                    }
                />
            )}
        </Collapse>
    );
}

export default withTheme(enhance(CampaignAudio));
