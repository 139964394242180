import overcast from '@public/images/platforms/Overcast.png';
import { FormattedMessage } from 'react-intl';
import ShortListFallback from '@app/fallbacks/ShortListFallback';
import BroadcastCard from '../../BroadcastCard';
import Text from '@ui/atoms/Text';
import { LISTENING_PLATFORMS_NAMES, OVERCAST } from '@/utils/constants';
import { useParams } from 'react-router-dom';
import useShowQuery from '@/queries/show/useShowQuery.hook';

const OvercastBroadcast = () => {
    const { showId } = useParams<{ showId: string }>();
    const { data: show, isLoading } = useShowQuery(showId);
    const url = show?.listeningLinks?.data.find((link) => link.key === OVERCAST)?.url;

    if (isLoading) return <ShortListFallback />;

    return (
        <BroadcastCard
            platformKey={OVERCAST}
            name={LISTENING_PLATFORMS_NAMES[OVERCAST]}
            logo={overcast}
            url={url}
            pendingMessage={
                <FormattedMessage
                    defaultMessage="Diffusez votre émission sur {platform} pour qu’elle soit en ligne sur cette plateforme."
                    values={{
                        platform: (
                            <Text as="span" fontWeight="--fw-bold" variant="footnote">
                                <FormattedMessage defaultMessage="Apple Podcasts" />
                            </Text>
                        ),
                    }}
                />
            }
        />
    );
};

export default OvercastBroadcast;
