const isFloat = (n: any): boolean => {
    return Number(n) === n && n % 1 !== 0;
};

export const isNumber = (n: any): boolean => {
    return Number.isSafeInteger(n) || isFloat(n);
};

export const roundToNearestHalf = (n: number): number => {
    return Math.round(n * 2) / 2;
};
