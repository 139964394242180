import styled from 'styled-components';
import List from './List';

interface SidebarProps {
    children: React.ReactNode;
    className?: string;
    breaksAt?: string;
}

const Sidebar = ({ children, className, breaksAt }: SidebarProps) => {
    return (
        <Nav className={className}>
            <List breaksAt={breaksAt || '75rem'}>{children}</List>
        </Nav>
    );
};

const Nav = styled.nav``;

export default Sidebar;
