import styled from 'styled-components';

const EpisodeSkeleton = () => {
    return (
        <EpisodeSkeletonWrapper>
            <EpisodeSkeletonCover />
            <EpisodeSkeletonTitle />
        </EpisodeSkeletonWrapper>
    );
};

const EpisodeSkeletonWrapper = styled.div`
    display: flex;
    align-items: center;
    gap 0.5rem;
`;
const EpisodeSkeletonCover = styled.div`
    width: 1.5rem;
    height: 1.5rem;
    border-radius: var(--r-s);
    background-color: var(--neutral50);
`;
const EpisodeSkeletonTitle = styled.div`
    width: 10rem;
    height: 1rem;
    border-radius: var(--r-full);
    background-color: var(--neutral50);
`;

export default EpisodeSkeleton;
