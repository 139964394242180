import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import Text from '@ui/atoms/Text';
import { InputTextController } from '@ui/atoms/InputText';
import { RadioGroupController } from '@ui/molecules/RadioGroup';
import InputRadio from '@ui/atoms/InputRadio';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@ui/atoms/Button';
import { useSubmitSurvey } from '@/shared/hooks/useSubmitSurvey.hook';
import { useUnsubscribeSurveySchema } from './useUnsubscribeSurveySchema.hook';
import styled from 'styled-components';

const SURVEY_TYPE = 'unsubscribe';

const REASON_OPTIONS_MESSAGES = defineMessages({
    budget: { defaultMessage: 'Pour une raison de budget' },
    stop: { defaultMessage: 'J’arrête mon podcast' },
    technical_issues: { defaultMessage: 'Pour des soucis techniques' },
    provider: { defaultMessage: 'Pour changer d’hébergeur' },
    customer_service: { defaultMessage: 'Pour un mauvais service après-vente' },
    other: { defaultMessage: 'Autre raison' },
});
export const REASON_OPTIONS = Object.keys(REASON_OPTIONS_MESSAGES);

const CHANGE_PROVIDER_REASON_OPTIONS_MESSAGES = defineMessages({
    budget: { defaultMessage: 'Ils sont moins chers' },
    performance: { defaultMessage: 'Ils sont plus performants' },
    user_experience: { defaultMessage: 'Ils sont plus agréables à utiliser' },
    features: { defaultMessage: 'Ils m’offrent des fonctionnalités que vous n’avez pas' },
    offer: { defaultMessage: 'Ils m’ont fait une offre' },
    other: { defaultMessage: 'Autre' },
});
export const CHANGE_PROVIDER_REASON_OPTIONS = Object.keys(CHANGE_PROVIDER_REASON_OPTIONS_MESSAGES);

const UnsubscribeSurveyForm = ({ onClose, onAfterSubmit }) => {
    const intl = useIntl();
    const submitSurvey = useSubmitSurvey();

    const schema = useUnsubscribeSurveySchema();

    const { control, handleSubmit, formState, clearErrors, watch, setValue } = useForm({
        mode: 'onChange',
        defaultValues: {
            reason: '',
            change_provider_reason: '',
            last_word: '',
        },
        resolver: yupResolver(schema),
    });

    const onSubmit = (payload) => {
        submitSurvey.mutate(
            { type: SURVEY_TYPE, answers: payload },
            {
                onSuccess: () => onAfterSubmit(),
            },
        );
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Text>
                <FormattedMessage defaultMessage="Pouvez-vous nous expliquer la raison de votre départ ?" />
            </Text>
            <Text>
                <FormattedMessage defaultMessage="Chaque retour est important pour nous améliorer." />
            </Text>
            <RadioGroupController
                control={control}
                name="reason"
                label={<FormattedMessage defaultMessage="Pourquoi nous quittez-vous ?" />}
                onChange={() => {
                    setValue('change_provider_reason', '');
                }}
            >
                {REASON_OPTIONS.map((option) => (
                    <InputRadio key={option} value={option}>
                        {intl.formatMessage(REASON_OPTIONS_MESSAGES[option])}
                    </InputRadio>
                ))}
            </RadioGroupController>

            {watch('reason') === 'provider' && (
                <RadioGroupController
                    control={control}
                    name="change_provider_reason"
                    label={
                        <FormattedMessage defaultMessage="Pour quelle raison changez-vous d’hébergeur ?" />
                    }
                >
                    {CHANGE_PROVIDER_REASON_OPTIONS.map((option) => (
                        <InputRadio key={option} value={option}>
                            {intl.formatMessage(CHANGE_PROVIDER_REASON_OPTIONS_MESSAGES[option])}
                        </InputRadio>
                    ))}
                </RadioGroupController>
            )}

            <InputTextController
                control={control}
                name="last_word"
                placeholder={intl.formatMessage({
                    defaultMessage: 'Souhaitez-vous nous laisser un dernier petit mot ?',
                })}
                errorMessage={formState.errors.last_word?.message}
                onBlur={() => clearErrors('last_word')}
            />

            <Actions>
                <Button type="button" variant="ghost" color="--primary" onClick={onClose}>
                    <FormattedMessage defaultMessage="Oubliez, je ne veux plus résilier" />
                </Button>
                <Button
                    type="submit"
                    isDisabled={!formState.isDirty || submitSurvey.isLoading}
                    isLoading={submitSurvey.isLoading}
                >
                    <FormattedMessage defaultMessage="Continuer vers la résiliation" />
                </Button>
            </Actions>
        </Form>
    );
};

UnsubscribeSurveyForm.propTypes = {
    onClose: PropTypes.func.isRequired,
    onAfterSubmit: PropTypes.func.isRequired,
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 0.5rem;
`;
const Actions = styled.div``;

export default UnsubscribeSurveyForm;
