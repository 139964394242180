import Cluster from '@/components/ui/layout/Cluster';
import styled, { css, keyframes } from 'styled-components';

const EpisodeSkeleton = () => (
    <Episode>
        <Cluster $gap="1rem">
            <Image />
            <Title />
        </Cluster>
    </Episode>
);

const PlaylistEpisodesSkeleton = () => {
    return (
        <List>
            <EpisodeSkeleton />
            <EpisodeSkeleton />
            <EpisodeSkeleton />
            <EpisodeSkeleton />
            <EpisodeSkeleton />
        </List>
    );
};

const shimmer = keyframes`
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
`;

const shimmering = css`
    background: var(--neutral50);
    background-image: linear-gradient(
        to right,
        var(--neutral50) 0%,
        #edeef1 20%,
        var(--neutral50) 40%,
        var(--neutral50) 100%
    );
    background-repeat: no-repeat;
    background-size: 50rem 6.5rem;
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${shimmer};
    animation-timing-function: linear;
`;
const List = styled.ul`
    list-style: none;
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
`;
const Episode = styled.li`
    background-color: var(--white);
    height: 5rem;
    display: flex;
    align-items: center;
    column-gap: 1rem;
    padding: 1rem;

    &:first-child {
        border-top-left-radius: var(--r-m);
        border-top-right-radius: var(--r-m);
    }

    &:last-child {
        border-bottom-left-radius: var(--r-m);
        border-bottom-right-radius: var(--r-m);
    }

    &:not(:last-child) {
        border-bottom: 1px solid var(--neutral100);
    }
`;
const Image = styled.div`
    background-color: var(--neutral100);
    border-radius: var(--r-s);
    width: 2.5rem;
    height: 2.5rem;
    ${shimmering}
`;
const Title = styled.div`
    background-color: var(--neutral100);
    border-radius: var(--r-s);
    width: 15rem;
    height: 0.75rem;
    ${shimmering}
`;

export default PlaylistEpisodesSkeleton;
