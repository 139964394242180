import { isValidElement } from 'react';
import { GridListItem as AriaGridListItem } from 'react-aria-components';
import type { GridListItemProps as AriaGridListItemProps, Key } from 'react-aria-components';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon as iconFA } from '@fortawesome/fontawesome-svg-core/import.macro';
import Checkbox from '@/components/ui/Checkbox';

const GridListItemImage = ({ image }: { image: ICheckboxListItem['image'] }) => {
    if (image === undefined) return null;

    if (typeof image === 'string' && image.length > 0) {
        return <Image src={image} />;
    }
    if (isValidElement(image)) {
        return image;
    }

    return (
        <ImagePlaceholder>
            <Icon
                icon={iconFA({
                    name: 'pen-ruler',
                    style: 'solid',
                })}
            />
        </ImagePlaceholder>
    );
};
export interface ICheckboxListItem {
    id: Key;
    name: string;
    image?: string | React.ReactNode | null;
    icon?: React.ReactNode;
    [key: string]: any;
}

type CheckboxListItemProps = Omit<ICheckboxListItem, 'id'> &
    AriaGridListItemProps<ICheckboxListItem>;

const CheckboxListItem = ({
    children,
    description,
    name,
    image,
    icon,
    ...props
}: CheckboxListItemProps) => {
    return (
        <ListItem {...props} textValue={name}>
            {children || (
                <>
                    <Checkbox slot="selection" />
                    {icon}
                    <GridListItemImage image={image} />
                    <ListItemLabel>{name}</ListItemLabel>
                    <DisabledIcon
                        icon={iconFA({
                            name: 'lock-keyhole',
                            style: 'solid',
                        })}
                    />
                </>
            )}
        </ListItem>
    );
};

const Icon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const DisabledIcon = styled(Icon)`
    color: var(--neutral500);
    display: none;
    margin-left: auto;
`;
const ListItem = styled(AriaGridListItem)`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    padding-inline: 0.5rem;
    border-radius: var(--r-s);
    height: 2rem;
    cursor: pointer;

    &:hover {
        background-color: var(--neutral50);
    }
    &[data-selected] {
        background-color: var(--primary50);
    }
    &[data-disabled] {
        opacity: 0.5;

        & ${DisabledIcon} {
            display: block;
        }
    }
`;
const ListItemLabel = styled.span`
    font-size: var(--fs-body-m);
    line-height: var(--lh-body-m);
    color: var(--black);
`;
const Image = styled.img`
    width: 1.5rem;
    height: 1.5rem;
    border-radius: var(--r-s);
`;
const ImagePlaceholder = styled.div`
    width: 1.5rem;
    height: 1.5rem;
    border-radius: var(--r-s);
    background-color: var(--neutral100);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--neutral500);
`;

export default CheckboxListItem;
