import { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import Portal from '../Portal';
import DeprecatedIcon from '../../atoms/DeprecatedIcon';
import star from '@public/images/star.svg';
import rocket from '@public/images/rocketAward.svg';
import smoke from '@public/images/rocketSmoke.svg';
import message from '@public/images/rocketMessage.svg';

const move = keyframes`
  0% {
    transform: translate(50%, 100%);
  }
  10%{
    transform: translate(50%, -60%);
  }
  15% {
    top: 50%;
    transform: translate(50%, -50%);
  }
  90% {
    top: 45%;
    transform: translate(50%, -50%);
  }
  100% {
    top: -100%;
    transform: translate(50%, -50%);
  }
`;

const animGround = keyframes`
  90%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
`;

const Ground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: ${animGround} 4s ease-in-out forwards;
`;

const RingAnim = keyframes`
0%{
    transform: scale(0);
}
10%{
    transform: scale(1);
}
95%{
    transform: scale(1);
}
100%{
    transform: scale(0);
}
`;

const Ring = styled.div`
    width: 300px;
    height: 300px;
    border-radius: var(--r-full);
    background: radial-gradient(circle at bottom, #88027e, #270a57 75%);
    animation: ${RingAnim} 4s ease-in-out forwards;
    position: relative;
    overflow: hidden;
`;

const shakeIt = keyframes`
  0%{
    left: -1px;
    top: -1px;
  }
  100%{
    left: 1px;
    top: 1px;
  }
`;

const Rocket = styled(DeprecatedIcon)`
    animation: ${shakeIt} 0.1s ease-in-out infinite;
`;

const Move = styled.div`
    position: relative;
    top: 100%;
    animation: ${move} 3s 0.5s ease-in-out forwards;
    z-index: 1;
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
`;

const speed = keyframes`
      0%{top: 0}
      100%{top: 100%}
`;

const SpeedEffect = styled.div.attrs(({ left }) => ({
    style: {
        left,
    },
}))`
    height: 150px;
    width: 30px;
    background: red;
    border-radius: var(--r-full);
    background: linear-gradient(to top, #d000c6, transparent);
    animation: ${speed} 0.3s ease-in-out infinite;
    position: absolute;
    opacity: 0.1;
`;

const smokeAnim = keyframes`
  0%{
    transform: scale(1.5) translateY(-30px);
  }
  100% {
    transform: scale(1.5) translateY(20px);
  }
`;

const Smoke = styled(DeprecatedIcon)`
    opacity: 0.2;
    animation: ${smokeAnim} 0.2s linear infinite;
`;

const ControlSmokeAnim = keyframes`
  0%{
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const ControlSmoke = styled.div`
    animation: ${ControlSmokeAnim} 3s linear forwards;
`;

const starAnim = keyframes`
  0%{
    bottom: 150px;
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    bottom: -10px;
    transform: scale(1.5);
    opacity: 1;
  }
`;

const Star = styled(DeprecatedIcon).attrs(({ left }) => ({
    style: {
        left,
    },
}))`
    position: absolute;
    width: 10px;
    height: 10px;
    fill: #ffdfaa;
    animation: ${starAnim} 0.3s ease-in-out infinite;
`;

const animContainerShootingStar = keyframes` 
  0% {
      opacity: 1;
  }
  90% {
      opacity: 1;
  }
  100%{
      opacity: 0;
  }
`;

const ContainerShootingStar = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300px;
    height: 300px;
    transform-origin: center;
    transform: scale(1);
    animation: ${animContainerShootingStar} 2s 0.5s ease-out forwards;
`;

const animStar = (X, Y) => keyframes` 
      0%{
        top: 0;
        left: 0;
        transform: scale(0.${Y}) rotate(${X}deg);
        opacity: 0;
      }
      50%{
          top: ${Y}%;
          left: ${X}%;
          opacity: 1;
      }
      97%{
          top: ${Y}%;
          left: ${X}%;
          opacity: 1;
          transform: scale(1) rotate(${X}deg);
      }
      100%{
          top: ${Y}%;
          left: ${X}%;
          opacity: 0.${X};
          transform: scale(1.${Y}) rotate(${X}deg);
      }
`;

const ShootingStar = styled(DeprecatedIcon)`
    position: absolute;
    animation: ${(p) => animStar(p.X, p.Y)} 3s 1s ease-out forwards;
`;

const animMessage = keyframes` 
    0%{left: 50%;transform: rotate(0deg) translate(-50%, -50%) scale(0); opacity: 0;}
    20%{left: 50%;transform: rotate(0deg) translate(-50%, -50%) scale(1); opacity: 1;}
    100%{left: 50%;transform: rotate(0deg) translate(-50%, -50%) scale(1); opacity: 1;}
`;

const Message = styled(DeprecatedIcon)`
    position: absolute;
    opacity: 0;
    top: calc(50% + 120px);
    animation: ${animMessage} 1s 1s ease-out forwards;
`;

const Award = ({ children, name }) => {
    const [SOne, setSOne] = useState();
    const [close, setClose] = useState(true);
    const numberStar = 100;

    const [starCoord] = useState(containStars(numberStar));
    const numberOfEffect = chooseNumber(2);
    useEffect(() => {
        closeAward();
        const id = random();
        return () => clearInterval(id);
    }, []);

    function containStars(n) {
        const starsArray = [];
        for (let i = 0; i < n; i += 1) {
            const ptAngle = Math.random() * 2 * Math.PI;
            const ptRadiusSq = Math.random() * 125;
            const ptX = Math.round(ptRadiusSq * Math.cos(ptAngle));
            const ptY = Math.round(ptRadiusSq * Math.sin(ptAngle));
            starsArray.push({ x: ptX, y: ptY });
        }
        return starsArray;
    }

    function chooseNumber(n) {
        const numberArray = [];
        for (let i = 0; i < n; i += 1) {
            numberArray.push(i);
        }
        return numberArray;
    }

    function random() {
        return setInterval(() => {
            setSOne(`${Math.round(Math.random() * 100)}%`);
        }, 300);
    }

    function closeAward() {
        setTimeout(() => {
            setClose(false);
        }, 4500);
    }

    const size = 150;

    return (
        close && (
            <Portal id={name} z={9998}>
                <Ground>
                    <Message as={message} size={260} pos="absolute" z={6} />
                    <div style={{ transform: 'rotate(45deg)', zIndex: 5, position: 'relative' }}>
                        <Ring>
                            <Move size={size}>
                                <Rocket as={rocket} size={size} pos="absolute" z={1} />
                                <div style={{ position: 'absolute', bottom: -120 }}>
                                    <ControlSmoke>
                                        <Smoke as={smoke} size={size} z={1} />
                                    </ControlSmoke>
                                    <Star left={SOne} as={star} />
                                </div>
                            </Move>
                            <div>
                                {numberOfEffect.map((c) => (
                                    <SpeedEffect key={c} left={SOne} />
                                ))}
                            </div>
                        </Ring>
                    </div>
                    <ContainerShootingStar>
                        {starCoord.map((s) => (
                            <ShootingStar
                                Y={s.y}
                                X={s.x}
                                key={s.x * Math.PI}
                                as={star}
                                h={10}
                                w={10}
                                color="#FFDFAA"
                            />
                        ))}
                    </ContainerShootingStar>
                    {children}
                </Ground>
            </Portal>
        )
    );
};

export default Award;
