import { useParams } from 'react-router-dom';
import Stack from '@ui/layout/Stack';
import Text from '@ui/atoms/Text';
import { FormattedMessage } from 'react-intl';
import { ToggleController } from '@ui/atoms/Toggle';
import useSoundcloudProviderQuery from '@queries/provider/useSoundcloudProviderQuery.hook';
import Link from '@ui/atoms/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import useEpisodeQuery from '@queries/episode/useEpisodeQuery.hook';
import { EPISODE_STATUS } from '@/shared/config/constants';

const ToggleContent = ({ isSoundcloudAccountConnected, broadcastLink, hasBeenPublished }) => {
    if (!isSoundcloudAccountConnected) {
        return (
            <FormattedMessage
                defaultMessage="Pour publier votre épisode sur Soundcloud, connectez votre compte dans l’onglet {broadcast} de votre émission."
                values={{
                    broadcast: (
                        <BroadcastLink to={broadcastLink} target="_blank">
                            <FormattedMessage defaultMessage="Diffusion" />
                            <LinkIcon icon={icon({ name: 'arrow-up-right', style: 'solid' })} />
                        </BroadcastLink>
                    ),
                }}
            />
        );
    }
    if (hasBeenPublished)
        return (
            <FormattedMessage defaultMessage="Votre épisode sera également republié sur Soundcloud." />
        );
    return (
        <FormattedMessage defaultMessage="Votre épisode sera également publié sur Soundcloud." />
    );
};

const PublishOnSoundcloudToggle = (props) => {
    const { podId: episodeId, showId } = useParams();
    const { data: episode } = useEpisodeQuery({ episodeId });
    const { data: soundcloudAccount } = useSoundcloudProviderQuery({ showId });

    const broadcastLink = `/app/show/${showId}/settings/broadcast`;
    const isSoundcloudAccountConnected = !!soundcloudAccount;
    const isDisabled = !isSoundcloudAccountConnected;
    const hasBeenPublished = episode?.state === EPISODE_STATUS.ONLINE && episode?.soundcloudUrl;

    return (
        <Stack $gap="1rem">
            <Text variant="bodyM" fontWeight="--fw-semibold">
                {hasBeenPublished ? (
                    <FormattedMessage defaultMessage="Publier à nouveau sur Soundcloud" />
                ) : (
                    <FormattedMessage defaultMessage="Publier sur Soundcloud" />
                )}
            </Text>
            <ToggleController isDisabled={isDisabled} {...props}>
                <Text as="span">
                    <ToggleContent
                        hasBeenPublished={hasBeenPublished}
                        isSoundcloudAccountConnected={isSoundcloudAccountConnected}
                        broadcastLink={broadcastLink}
                    />
                </Text>
            </ToggleController>
        </Stack>
    );
};

const LinkIcon = styled(FontAwesomeIcon)`
    color: inherit;
    width: 0.75rem;
    height: 0.75rem;
`;
const BroadcastLink = styled(Link)`
    font-weight: var(--fw-semibold);
    color: var(--primary);
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;

export default PublishOnSoundcloudToggle;
