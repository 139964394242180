import useClipQuotaQuery from '@/queries/clip/useClipQuotaQuery';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router';
import { ClipFormSchema } from '../schemas/useFormSchema';
import styled from 'styled-components';
import Text from '@/components/ui/atoms/Text';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import Alert from '@/components/ui/atoms/Alert';
import Format from './Format/Format';

const Formats = () => {
    const { showId } = useParams<{ showId: string }>();
    const { data: quota } = useClipQuotaQuery(showId);
    const { watch, setValue } = useFormContext<ClipFormSchema>();
    const formats = watch('formats');

    const remainingClipsCount = quota ? Math.max(Math.floor(quota.maximum - quota.total), 0) : 0;
    const currentRemainingClipsCount = remainingClipsCount - formats.length;

    const isSquareSelected = formats.includes('square');
    const isStorySelected = formats.includes('story');
    const isWideSelected = formats.includes('wide');

    const handleSelectFormat = (format: 'square' | 'story' | 'wide') => {
        const newFormats = formats.includes(format)
            ? formats.filter((f) => f !== format)
            : [...formats, format];

        setValue('formats', newFormats, { shouldValidate: true, shouldDirty: true });
    };

    return (
        <Wrapper>
            <InnerWrapper>
                <QuotaWrapper>
                    <Text>
                        <FormattedMessage
                            defaultMessage="Crédit(s) clip restant(s) ce mois-ci : {clipsCount}"
                            values={{
                                clipsCount: (
                                    <Text
                                        as="span"
                                        fontWeight="--fw-semibold"
                                        color={currentRemainingClipsCount > 0 ? 'black' : 'error'}
                                    >
                                        <FormattedNumber value={currentRemainingClipsCount} />
                                    </Text>
                                ),
                            }}
                        />
                    </Text>
                    <Text color="--neutral500">
                        <FormattedMessage defaultMessage="Pas de panique, vous récupérez tous vos crédits clip chaque 1er du mois 😇" />
                    </Text>
                </QuotaWrapper>
                <Alert variant="info" defaultIcon>
                    <Text fontWeight="--fw-semibold">
                        <FormattedMessage defaultMessage="Attention : vous ne pourrez plus ajouter d'autres formats par la suite." />
                    </Text>
                </Alert>
                <FormatsWrapper>
                    <Format
                        format="square"
                        isSelected={isSquareSelected}
                        onSelect={handleSelectFormat}
                        isDisabled={!isSquareSelected && currentRemainingClipsCount <= 0}
                    >
                        <Text fontWeight="--fw-semibold">
                            <FormattedMessage defaultMessage="Carré (1:1)" />
                        </Text>
                    </Format>
                    <Format
                        format="story"
                        isSelected={isStorySelected}
                        onSelect={handleSelectFormat}
                        isDisabled={!isStorySelected && currentRemainingClipsCount <= 0}
                    >
                        <Text fontWeight="--fw-semibold">
                            <FormattedMessage defaultMessage="Story (9:16)" />
                        </Text>
                    </Format>
                    <Format
                        format="wide"
                        isSelected={isWideSelected}
                        onSelect={handleSelectFormat}
                        isDisabled={!isWideSelected && currentRemainingClipsCount <= 0}
                    >
                        <Text fontWeight="--fw-semibold">
                            <FormattedMessage defaultMessage="Paysage (16:9)" />
                        </Text>
                    </Format>
                </FormatsWrapper>
            </InnerWrapper>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;
const InnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
`;
const QuotaWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;
    text-align: center;
`;
const FormatsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    width: 100%;
    padding-bottom: 2rem;
`;

export default Formats;
