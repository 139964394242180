import styled from 'styled-components';

const TooltipContent = ({ series, dataPointIndex, w, averageTranslatedString }) => {
    return (
        <TooltipContentWrapper>
            <DataPointLabel>{w.globals.categoryLabels[dataPointIndex]}</DataPointLabel>
            <GlobalAverage>
                {averageTranslatedString}: <Pop>{series[0][dataPointIndex]}</Pop>
            </GlobalAverage>
            <Separator />
            <Series>
                {w.config.series[1] && (
                    <Serie>
                        <ColorDot color={w.config.colors[1]} />
                        {w.config.series[1].name}: <Pop>{series[1][dataPointIndex]}</Pop>
                    </Serie>
                )}
                {w.config.series[2] && (
                    <Serie>
                        <ColorDot color={w.config.colors[2]} />
                        {w.config.series[2].name}: <Pop>{series[2][dataPointIndex]}</Pop>
                    </Serie>
                )}
            </Series>
        </TooltipContentWrapper>
    );
};

const TooltipContentWrapper = styled.div`
    background-color: var(--black);
    color: var(--neutral500);
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: column;
    font-size: var(--fs-body-s);
`;
const DataPointLabel = styled.div`
    font-weight: var(--fw-semibold);
    color: var(--white);
`;
const GlobalAverage = styled.div`
    color: var(--neutral500);
`;
const Separator = styled.div`
    width: 100%;
    height: 1px;
    background-color: var(--neutral700);
    margin-inline: auto;
    margin-block: 0.25rem;
`;
const Series = styled.ul`
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    list-style: none;
`;
const Serie = styled.li`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--neutral500);
`;
const Pop = styled.span`
    color: var(--white);
    font-weight: var(--fw-semibold);
`;
const ColorDot = styled.div`
    width: 0.5rem;
    height: 0.5rem;
    border-radius: var(--r-xxs);
    background-color: ${({ color }) => color};
`;

export default TooltipContent;
