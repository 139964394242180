import styled from 'styled-components';

const SidebarList = styled.ul`
    padding-left: 0;
    display: flex;
    gap: 1.5rem;
    overflow-x: auto;

    @media (min-width: 64rem) {
        flex-direction: column;
        gap: 1rem;
        overflow-x: auto;

        &:-ms-overflow-style: none;
        &:scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }
`;

export default SidebarList;
