import { YOUTUBE, SOUNDCLOUD } from '@/shared/config/constants';

const providerKeys = {
    all: () => [{ entity: 'provider' }],
    allLists: () => [{ ...providerKeys.all()[0], scope: 'list' }],
    listByShowId: (rawShowId: string | number) => [
        { ...providerKeys.allLists()[0], showId: Number(rawShowId) },
    ],
    allDetails: () => [{ ...providerKeys.all()[0], scope: 'detail' }],
    detailForYoutube: (rawShowId: string | number) => [
        { ...providerKeys.allDetails()[0], showId: Number(rawShowId), provider: YOUTUBE },
    ],
    detailForSoundcloud: (rawShowId: string | number) => [
        { ...providerKeys.allDetails()[0], showId: Number(rawShowId), provider: SOUNDCLOUD },
    ],
};

export default providerKeys;
