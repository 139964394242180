import { useEffect } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import { useRouteMatch } from 'react-router-dom';

/**
 * Log an event on Amplitude
 *
 * @param {string} event - Event name
 * @param {Object} [properties={}] - Event properties
 * @param {boolean} [condition=true] - Additional condition to trigger the event
 */
export const useAmplitudeLogEvent = (event, properties = {}, condition = true) => {
    useEffect(() => {
        if (condition) {
            amplitude.track(event, properties);
        }
    }, [condition]);
};

/**
 * Sync user properties on Amplitude
 */
export const useAmplitudeSyncUserProperties = () => {
    const match = useRouteMatch('/app/show/:showId(\\d+)');

    if (match) {
        // Add showId to Amplitude user properties when required
        const identify = new amplitude.Identify();
        identify.set('show_id', match.params.showId);
        amplitude.identify(identify);
    } else {
        const identify = new amplitude.Identify();
        identify.unset('show_id');
        amplitude.identify(identify);
    }
};
