import { createRef } from 'react';
import styled from 'styled-components';
import SearchIcon from '@public/icons/search.svg';
import CrossIcon from '@public/icons/close.svg';
import DeprecatedInput from '@ui/atoms/DeprecatedInput';
import DeprecatedIcon from '@ui/atoms/DeprecatedIcon';

const SearchBarInput = styled(DeprecatedInput)`
    min-width: 100%;
    height: 100%;
    border-radius: var(--r-l);
    background: var(--white);
    &::placeholder {
        font-size: var(--fs-body);
        color: var(--neutral500);
    }
`;

const Wrapper = styled.div`
    position: relative;
    height: 40px;
    width: 100%;
    svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        fill: var(--neutral500);
    }
    svg:first-of-type {
        left: 12px;
    }
    svg:last-of-type {
        cursor: pointer;
        right: 12px;
    }
`;

function SearchBar({ onChange, value, field, ...props }) {
    const ref = createRef();
    const change = field ? field.setValue : onChange;
    const val = field ? field.value : value;

    function onCloseClick() {
        if (ref.current) {
            ref.current.focus();
        }
        if (field) {
            field.reset();
        } else {
            onChange('');
        }
    }

    return (
        <Wrapper>
            <DeprecatedIcon as={SearchIcon} size={15} />
            <SearchBarInput
                ref={ref}
                pl={32}
                height={35}
                onChange={(e) => change(e.target.value)}
                value={val || ''}
                {...props}
            />
            {val !== '' && <DeprecatedIcon as={CrossIcon} onClick={onCloseClick} size={15} />}
        </Wrapper>
    );
}

export default SearchBar;
