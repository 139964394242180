import { useEffect, useState } from 'react';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import EpisodeAIPanelField from './EpisodeAIPanelField';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import {
    EPISODE_AI_LOADING_ACTIONS,
    useEpisodeAILoading,
} from '@/components/NonHostingEpisodeForm/contexts/NonHostingEpisodeAILoadingContext';
import { useEpisodeAISettings } from '@/components/NonHostingEpisodeForm/contexts/NonHostingEpisodeAISettingsContext';
import useAISuggestionsError from '../utils/useAISuggestionsError.hook';
import useAISocialPostsSuggestionsQuery from '@/queries/ai/useAISocialPostsSuggestionsQuery';

interface EpisodeAISocialPostsFieldProps {
    generateButtonDataTestId: string;
}

const EpisodeAISocialPostsField = ({
    generateButtonDataTestId,
}: EpisodeAISocialPostsFieldProps) => {
    const { episodeId } = useParams<{ episodeId: string }>();
    const [settings] = useEpisodeAISettings();
    const smmSettings = settings.get('smm') || {
        customPrompt: '',
        saveAsDefault: false,
    };
    const smmSuggestionsQuery = useAISocialPostsSuggestionsQuery({
        episodeId,
        settings: smmSettings,
        socialNetworks: ['facebook', 'twitter', 'instagram', 'linkedin'],
        delay: 2000,
    });
    const facebookSuggestionsQuery = useAISocialPostsSuggestionsQuery({
        episodeId,
        settings: smmSettings,
        socialNetworks: ['facebook'],
        delay: 2000,
    });
    const twitterSuggestionsQuery = useAISocialPostsSuggestionsQuery({
        episodeId,
        settings: smmSettings,
        socialNetworks: ['twitter'],
        delay: 2000,
    });
    const instagramSuggestionsQuery = useAISocialPostsSuggestionsQuery({
        episodeId,
        settings: smmSettings,
        socialNetworks: ['instagram'],
        delay: 2000,
    });
    const linkedinSuggestionsQuery = useAISocialPostsSuggestionsQuery({
        episodeId,
        settings: smmSettings,
        socialNetworks: ['linkedin'],
        delay: 2000,
    });
    const [isGenerated, setIsGenerated] = useState(
        localStorage.getItem(`episode-ai-${episodeId}-generated-smm`) === 'true',
    );
    const [state, dispatch] = useEpisodeAILoading();
    const isLoading =
        state.get('smm') ||
        state.get('smm-facebook') ||
        state.get('smm-twitter') ||
        state.get('smm-instagram') ||
        state.get('smm-linkedin') ||
        false;

    useAISuggestionsError({
        isError: smmSuggestionsQuery.isError,
        episodeId,
        type: 'smm',
    });

    // Once the smm suggestions are fetched, set the generated flag to true
    useEffect(() => {
        const isFetching =
            smmSuggestionsQuery.isFetching ||
            facebookSuggestionsQuery.isFetching ||
            twitterSuggestionsQuery.isFetching ||
            instagramSuggestionsQuery.isFetching ||
            linkedinSuggestionsQuery.isFetching;
        const hasData =
            smmSuggestionsQuery.data ||
            facebookSuggestionsQuery.data ||
            twitterSuggestionsQuery.data ||
            instagramSuggestionsQuery.data ||
            linkedinSuggestionsQuery.data;

        if (hasData && !isFetching) {
            localStorage.setItem(`episode-ai-${episodeId}-generated-smm`, 'true');
            setIsGenerated(true);
        }
    }, [
        smmSuggestionsQuery.data,
        smmSuggestionsQuery.isFetching,
        facebookSuggestionsQuery.data,
        facebookSuggestionsQuery.isFetching,
        twitterSuggestionsQuery.data,
        twitterSuggestionsQuery.isFetching,
        instagramSuggestionsQuery.data,
        instagramSuggestionsQuery.isFetching,
        linkedinSuggestionsQuery.data,
        linkedinSuggestionsQuery.isFetching,
    ]);

    const handleGenerate = () => {
        dispatch({
            type: EPISODE_AI_LOADING_ACTIONS.START,
            payload: 'smm',
        });
        dispatch({
            type: EPISODE_AI_LOADING_ACTIONS.START,
            payload: 'smm-facebook',
        });
        dispatch({
            type: EPISODE_AI_LOADING_ACTIONS.START,
            payload: 'smm-twitter',
        });
        dispatch({
            type: EPISODE_AI_LOADING_ACTIONS.START,
            payload: 'smm-instagram',
        });
        dispatch({
            type: EPISODE_AI_LOADING_ACTIONS.START,
            payload: 'smm-linkedin',
        });
        smmSuggestionsQuery.refetch();
    };

    return (
        <EpisodeAIPanelField
            data-testid={generateButtonDataTestId}
            icon={<Icon icon={icon({ name: 'message-heart', style: 'solid' })} />}
            type="smm"
            title={<FormattedMessage defaultMessage="Posts pour les réseaux sociaux" />}
            description={
                <FormattedMessage defaultMessage="Un contenu engageant pour promouvoir votre épisode sur les réseaux sociaux." />
            }
            onAction={handleGenerate}
            isLoading={isLoading}
            isGenerated={isGenerated && !isLoading}
        />
    );
};

const Icon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--primary-magic);
`;

export default EpisodeAISocialPostsField;
