import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchClip } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import clipKeys from './clipKeys';
import { z } from 'zod';
import { ClipFormatSchema } from '../clipFormat/ClipFormat.schema';

export const ClipSchema = z.object({
    adminTitle: z.string().nullable(),
    caption: z.string().nullable(),
    color: z.string(),
    createdAt: z.string(),
    duration: z.number(),
    end: z.number(),
    id: z.number(),
    lang: z.string(),
    podcastId: z.number(),
    showTitles: z.boolean(),
    showWaveform: z.boolean(),
    formats: z.array(ClipFormatSchema.omit({ clip: true })),
    start: z.number().nullable(),
    step: z.string(),
    title: z.string().nullable(),
    titleColor: z.string(),
    transcript: z.boolean(),
    updatedAt: z.string(),
});

export type Clip = z.infer<typeof ClipSchema>;
type Key = QueryFunctionContext<ReturnType<typeof clipKeys.detailById>>;

const queryFn = async ({ queryKey: [{ clipId }] }: Key) => {
    const { data } = await fetchClip(clipId);

    const formattedData = {
        ...data,
        formats: data.formats.data.map((format: any) => ({
            ...format,
            images: format.images.data,
        })),
        step: data.step ?? 'audio_sample_selection',
    };
    return ClipSchema.parse(camelcaseKeys(formattedData, { deep: true }));
};

interface ConfigProps {
    staleTime?: number;
}

const useClipQuery = (clipId: string | number, config?: ConfigProps) => {
    return useQuery({
        queryKey: clipKeys.detailById(clipId),
        queryFn,
        ...config,
    });
};

export default useClipQuery;
