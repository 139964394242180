import AccordionItem from '@ui/atoms/AccordionItem';
import { FormattedMessage } from 'react-intl';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';
import Accordion from '@ui/atoms/Accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormContext } from 'react-hook-form';
import Item from './Item';
import General from './General';
import Duration from './Duration';
import Episodes from './Episodes';

const CampaignFormAccordion = () => {
    const {
        formState: { errors },
    } = useFormContext();

    return (
        <EpisodeAccordion defaultIndex={0}>
            <AccordionItem index={0}>
                <Item
                    label={<FormattedMessage defaultMessage="Informations générales" />}
                    icon={<AccordionItemIcon icon={icon({ name: 'info', style: 'solid' })} />}
                    errorsCount={errors.general ? Object.keys(errors.general).length : 0}
                    localStorageKey="general"
                >
                    <General />
                </Item>
            </AccordionItem>
            <AccordionItem index={2}>
                <Item
                    label={<FormattedMessage defaultMessage="Période de campagne" />}
                    icon={
                        <AccordionItemIcon
                            icon={icon({ name: 'calendar-lines', style: 'solid' })}
                        />
                    }
                    errorsCount={errors.duration ? Object.keys(errors.duration).length : 0}
                    localStorageKey="duration"
                >
                    <Duration />
                </Item>
            </AccordionItem>
            <AccordionItem index={3}>
                <Item
                    label={<FormattedMessage defaultMessage="Sélection des épisodes" />}
                    icon={
                        <AccordionItemIcon
                            icon={icon({ name: 'microphone-lines', style: 'solid' })}
                        />
                    }
                    errorsCount={errors.episodes ? Object.keys(errors.episodes).length : 0}
                    localStorageKey="episodes"
                >
                    <Episodes />
                </Item>
            </AccordionItem>
        </EpisodeAccordion>
    );
};

const AccordionItemIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--neutral500);
`;
const EpisodeAccordion = styled(Accordion)`
    flex-grow: 1;
    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        padding-bottom: 2rem;
    }
`;

export default CampaignFormAccordion;
