import Model from './Model';

class YoutubeAccountModel extends Model {
    static attributes = {
        name: 'name',
        avatar: 'avatar',
        channelUrl: 'channel_url',
    };
}

export default YoutubeAccountModel;
