import { useQuery } from '@tanstack/react-query';
import { fetchNewsletterPreview } from '@/api';

const transform = (response) => {
    const { axios } = response;

    return axios.request.responseText;
};

const queryFn = async ({ queryKey: [{ episodeId, episodeName }] }) => {
    const response = await fetchNewsletterPreview(episodeId, episodeName);

    return transform(response);
};

const useEpisodeNewsletterPreviewQuery = (episodeId, episodeName) => {
    return useQuery({
        queryKey: [{ entity: 'episodeNewsletterPreview', episodeId, episodeName }],
        queryFn,
        enabled: false,
    });
};

export default useEpisodeNewsletterPreviewQuery;
