import { useState } from 'react';
import Button from '@/components/Button';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';
import useUpdateShowTemplateMutation from '@/queries/showTemplate/useUpdateShowTemplateMutation.hook';
import useFetchSocialRedirectUrlMutation from '@/queries/socialNetworkPlatform/useFetchSocialRedirectUrlMutation.hook';
import useLoginOnMediaPlatformMutation from '@/queries/socialNetworkPlatform/useLoginOnMediaPlatformMutation.hook';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import { INSTAGRAM, SOCIAL_NETWORK_NAME } from '@/shared/config/constants';
import { useBodyToastQueue } from '@/shared/hooks/useBodyToastQueue.hook';
import { useWindowPopupSocialMediaManager } from '@/shared/hooks/useWindowPopupSocialMediaManager';
import { isProviderEnabled } from '@/shared/services/providerService';
import { TooltipTrigger } from 'react-aria-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router';
import styled from 'styled-components';
import AccountsModal from './AccountsModal';
import { InstagramAccount } from '@/queries/provider/useInstagramProviderQuery';
import { useQueryClient } from '@tanstack/react-query';
import providerKeys from '@/queries/provider/providerKeys';
import ExternalLink from '@/components/Link/ExternalLink';
import { AxiosError } from 'axios';

const LoginButton = () => {
    const { showId } = useParams<{ showId: string }>();
    const { data: user } = useUserQuery();
    const loginOnMediaPlatform = useLoginOnMediaPlatformMutation();
    const toast = useBodyToastQueue();
    const fetchProviderRedirectUrl = useFetchSocialRedirectUrlMutation();
    const updateShowTemplate = useUpdateShowTemplateMutation();
    const [accounts, setAccounts] = useState<InstagramAccount[]>([]);
    const [isAccountsModalOpen, setIsAccountsModalOpen] = useState(false);
    const queryClient = useQueryClient();
    const intl = useIntl();
    // eslint-disable-next-line no-unused-vars
    const [_, openSocialNetworkOauthLogin] = useWindowPopupSocialMediaManager((e) => {
        if (e.data.provider !== INSTAGRAM) return;
        loginOnMediaPlatform.mutate(
            { showId, platform: INSTAGRAM, callbackData: e.data },
            {
                onSuccess: (data) => {
                    if (data.length === 1) {
                        handleUpdateShowTemplate(data[0].id);
                        return;
                    }
                    if (data.length > 1) {
                        setAccounts(data);
                        setIsAccountsModalOpen(true);
                        return;
                    }
                },
                onError: (error) => {
                    if ((error as AxiosError)?.response?.status === 400) {
                        toast.alert(
                            <FormattedMessage
                                defaultMessage="Oups, le compte Instagram doit être un <strong>compte professionnel</strong> pour être lié à votre compte Ausha. <link>En savoir plus</link>."
                                values={{
                                    strong: (chunks: string) => <strong>{chunks}</strong>,
                                    link: (chunks: string) => (
                                        <ExternalLink
                                            href={intl.formatMessage({
                                                defaultMessage:
                                                    'https://help.ausha.co/fr/articles/5963614-comment-lier-mes-reseaux-sociaux-a-ausha#h_a0643053e2',
                                            })}
                                            target="_blank"
                                        >
                                            {chunks}
                                        </ExternalLink>
                                    ),
                                }}
                            />,
                        );
                        return;
                    }

                    toast.alert(
                        <FormattedMessage defaultMessage="Oups, une erreur est survenue. Veuillez réessayer" />,
                    );
                },
            },
        );
    });

    const handleUpdateShowTemplate = (userId: string) => {
        updateShowTemplate.mutate(
            {
                showId,
                provider: INSTAGRAM,
                payload: {
                    params: {
                        userId,
                    },
                },
            },
            {
                onSuccess: () => {
                    queryClient.invalidateQueries({ queryKey: providerKeys.listByShowId(showId) });
                    toast.success(
                        <FormattedMessage
                            defaultMessage="Votre compte {platform} est maintenant lié à Ausha. 🥳"
                            values={{
                                platform: SOCIAL_NETWORK_NAME[INSTAGRAM],
                            }}
                        />,
                    );
                },
            },
        );
    };

    const handleLogin = () => {
        fetchProviderRedirectUrl.mutate(
            { platformId: INSTAGRAM },
            {
                onSuccess: (data) => {
                    if (!data.loginPageUrl) return;
                    openSocialNetworkOauthLogin?.(data.loginPageUrl);
                },
            },
        );
    };

    if (!user) return null;

    const isDisabled = !isProviderEnabled(INSTAGRAM, user);

    return (
        <>
            <TooltipTrigger delay={0} closeDelay={0} isDisabled={!isDisabled}>
                <TooltipTriggerWrapper>
                    <InstagramButton
                        isDisabled={isDisabled}
                        isLoading={loginOnMediaPlatform.isLoading}
                        onPress={handleLogin}
                    >
                        <FormattedMessage
                            defaultMessage="Se connecter à {provider}"
                            values={{
                                provider: SOCIAL_NETWORK_NAME[INSTAGRAM],
                            }}
                        />
                    </InstagramButton>
                </TooltipTriggerWrapper>
                <Tooltip placement="bottom">
                    <FormattedMessage
                        defaultMessage="Oups, le partage via {providerName} est actuellement indisponible. Nos équipes font le nécessaire pour réparer cela au plus vite. 💪 Merci de réessayer plus tard. 💜"
                        values={{
                            providerName: SOCIAL_NETWORK_NAME[INSTAGRAM],
                        }}
                    />
                </Tooltip>
            </TooltipTrigger>
            <AccountsModal
                isOpen={isAccountsModalOpen}
                onOpenChange={setIsAccountsModalOpen}
                instagramAccounts={accounts}
                onAccountSelect={(id) => {
                    handleUpdateShowTemplate(id);
                    setIsAccountsModalOpen(false);
                }}
            />
        </>
    );
};

const InstagramButton = styled(Button)`
    background-color: var(--instagram);

    &:hover {
        background-color: var(--instagram);
    }
`;

export default LoginButton;
