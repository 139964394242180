import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Text from '@ui/atoms/Text';
import Stack from '@ui/layout/Stack';

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    background-color: var(--alert50);
    color: var(--alert);
    border-radius: var(--r-s);
    font-size: 1rem;
`;

const EpisodeEditFormNewsletterError = () => {
    return (
        <Stack $align="center" $justify="center" $gap="1rem">
            <IconWrapper>
                <FontAwesomeIcon
                    icon={icon({
                        name: 'circle-exclamation',
                        style: 'solid',
                    })}
                />
            </IconWrapper>
            <Stack $align="center" $gap="0.5rem">
                <Text variant="bodyL" fontWeight="--fw-bold" textAlign="center">
                    <FormattedMessage defaultMessage="Une erreur est survenue" />
                </Text>
                <Text textAlign="center" color="--neutral500">
                    <FormattedMessage defaultMessage="Veuillez réessayer ultérieurement" />
                </Text>
            </Stack>
        </Stack>
    );
};

export default EpisodeEditFormNewsletterError;
