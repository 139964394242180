import { useMutation, useQueryClient } from '@tanstack/react-query';
import { duplicate } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import episodeKeys from './episodeKeys';

export interface MutationFnProps {
    episodeId: string;
}

const mutationFn = async ({ episodeId }: MutationFnProps) => {
    const { data } = await duplicate(episodeId);
    return camelcaseKeys(data, { deep: true });
};

const useDuplicateEpisodeMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: episodeKeys.allLists(),
            });
        },
    });
};

export default useDuplicateEpisodeMutation;
