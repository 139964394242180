import posed from 'react-pose';
import styled, { keyframes } from 'styled-components';

const transition = {
    duration: 500,
    type: 'spring',
    damping: 12,

    boxShadow: {
        delay: 300,
    },
};

const Div = posed.div({
    enter: {
        scale: 1,
        rotate: 42,
        opacity: 1,
        transition,
    },
    exit: {
        scale: 0.5,
        rotate: 42,
        opacity: 0,
        transition,
    },
});

const MissileAnimation = keyframes`
  from {
    top: 200px;
  }
  to {
    top: -200px;
  }
`;

const Loader = styled(Div)`
    display: flex;
    justify-content: center;
    z-index: 5;
    overflow: hidden;
    position: absolute;
    border-radius: var(--r-full);
    width: 150px;
    height: 150px;
    top: calc(50% - 75px);
    left: calc(50% - 75px);
    background: var(--gradient-primary);
`;

const Missile = styled.div`
    position: relative;
    top: -400px;
    z-index: 6;
    border-radius: var(--r-l);
    background: var(--white);
    width: 20px;
    margin: 0 5px;
    height: 120px;
`;

const Missile1 = styled(Missile)`
    animation: ${MissileAnimation} 1.2s infinite;
    animation-delay: 400ms;
`;
const Missile2 = styled(Missile)`
    animation: ${MissileAnimation} 0.8s infinite;
    animation-delay: 50ms;
`;
const Missile3 = styled(Missile)`
    animation: ${MissileAnimation} 1s infinite;
    animation-delay: 100ms;
`;

const Comp = (props) => (
    <Loader {...props}>
        <Missile1 />
        <Missile2 />
        <Missile3 />
    </Loader>
);

export default Comp;
