import { action, computed, extendObservable } from 'mobx';

class Model {
    constructor(store, data) {
        this.store = store;
        extendObservable(
            this,
            Object.entries(this.constructor.attributes).reduce((acc, [key, val]) => {
                let field;
                if (!data) {
                    return { ...acc, [key]: this.constructor.default(store.state)[key] };
                }
                if (typeof val === 'object') {
                    field = val.fromApi.resolve(data[val.fromApi.key], store, data);
                } else {
                    field = data[val];
                }
                return { ...acc, [key]: field };
            }, {}),
        );
    }

    @action
    updateData(data) {
        Object.entries(this.constructor.attributes).forEach(([key, val]) => {
            let field;
            if (typeof val === 'object') {
                field = val.fromApi.resolve(data[val.fromApi.key], this.store, data);
            } else {
                field = data[val];
            }
            this[key] = field;
        });
    }

    @computed
    get apiObject() {
        return Object.entries(this.constructor.attributes).reduce((acc, [attr, val]) => {
            let mapping = val;
            let resolver;

            if (typeof val === 'object' && val.toApi) {
                const { key, resolve } = val.toApi;
                if (!key) {
                    return acc;
                }
                mapping = key;
                resolver = resolve;
            } else if (typeof val === 'object') {
                mapping = val.fromApi.key;
            }

            return {
                ...acc,
                [mapping]: resolver ? resolver(this[attr], this) : this[attr],
            };
        }, {});
    }

    @computed
    get state() {
        return this.store.state;
    }
}

export default Model;
