import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Text as AriaText } from 'react-aria-components';
import Cluster from '../../layout/Cluster';
import styled from 'styled-components';

interface ErrorMessageProps {
    children: React.ReactNode;
    hasIcon?: boolean;
    className?: string;
}

const ErrorMessage = ({ children, hasIcon = false, className }: ErrorMessageProps) => {
    return (
        <Cluster className={className} $align="center" $gap="0.25rem">
            {hasIcon && <ErrorIcon icon={icon({ name: 'exclamation-circle', style: 'solid' })} />}
            <TextError slot="errorMessage">{children}</TextError>
        </Cluster>
    );
};

const TextError = styled(AriaText)`
    color: var(--alert);
    font-size: 0.75rem;
`;
const ErrorIcon = styled(FontAwesomeIcon)`
    color: var(--alert500);
    width: 0.75rem;
    height: 0.75rem;
    cursor: pointer;
`;

export default ErrorMessage;
