import Text from '@ui/atoms/Text';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import envelopeIllustration from '@public/images/envelope.png';
import styled from 'styled-components';
import { useResponsive } from '@/shared/hooks/useResponsive';
import useInternalRouteMapping from '@/shared/hooks/useInternalRouteMapping.hook';
import ExternalLink from '@/components/Link/ExternalLink';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    align-items: start;
    padding: 1.5rem;
    background-color: var(--primary50);
    border-radius: var(--r-m);
    position: relative;
    overflow: hidden;

    & > p {
        max-width: 37.5rem;
        z-index: 1;
    }
`;

const Illustration = styled.img`
    position: absolute;
    top: 50%;
    right: -1rem;
    transform: translateY(-50%) rotate(-15deg);
    width: 6.8rem;
    height: auto;
    z-index: 0;
`;

const NewsletterDisabled = () => {
    const { isDesktopLarge } = useResponsive();
    const { link } = useInternalRouteMapping();
    const newsletterSettingsLink = link('communication', { tab: 'newsletter' });

    return (
        <Container>
            <Text variant="bodyL" fontWeight="--fw-semibold">
                <FormattedMessage defaultMessage="Activez la Newsletter de votre podcast depuis l'onglet Communication afin de notifier vos auditeurs par e-mail de la sortie de chaque nouvel épisode. 💌" />
            </Text>
            <ExternalLink
                href={newsletterSettingsLink}
                target="_blank"
                variant="primary"
                endIcon={
                    <FontAwesomeIcon icon={icon({ name: 'arrow-up-right', style: 'solid' })} />
                }
            >
                <FormattedMessage defaultMessage="Activer les newsletters" />
            </ExternalLink>
            {isDesktopLarge && <Illustration src={envelopeIllustration} />}
        </Container>
    );
};

export default NewsletterDisabled;
