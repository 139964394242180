import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@/components/Button';
import useCampaignQuery from '@/queries/campaign/useCampaignQuery';
import useQuery from '@/shared/hooks/useQuery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Dialog, DialogTrigger, Popover } from 'react-aria-components';
import { Tabs, TabList, TabPanel, Tab } from 'react-aria-components';
import CampaignsList from './CampaignsList';
import EpisodesList from './EpisodesList';
import styled from 'styled-components';

const EpisodeAndCampaignFilter = () => {
    const query = useQuery();
    const campaignId = query.get('campaignId');
    const episodes = query.get('episode');

    const [filtersOpen, setFiltersOpen] = useState(false);

    const { data: campaign } = useCampaignQuery(campaignId ?? '', {
        enabled: !!campaignId,
    });

    const buttonLabel = () => {
        if (campaignId && campaign) {
            return campaign.name || '';
        }

        if ((!episodes && !campaignId) || episodes === 'all') {
            return <FormattedMessage defaultMessage="Tous les épisodes" />;
        }

        return (
            <FormattedMessage
                defaultMessage="{count, plural, one {# épisode} other {# épisodes}}"
                values={{ count: episodes?.split('_')?.length ?? 0 }}
            />
        );
    };

    return (
        <DialogTrigger>
            <WhiteTertiaryButton
                variant="tertiary"
                endIcon={<FontAwesomeIcon icon={icon({ name: 'chevron-down', style: 'solid' })} />}
                onPress={() => setFiltersOpen(!filtersOpen)}
            >
                {buttonLabel()}
            </WhiteTertiaryButton>
            <Popover placement="bottom start" isOpen={filtersOpen} onOpenChange={setFiltersOpen}>
                <StyledDialog>
                    <DialogInnerWrapper>
                        <StyledTabs defaultSelectedKey={campaignId ? 'campaign' : 'episode'}>
                            <StyledTabList aria-label="Filter type">
                                <StyledTab id="episode">
                                    <FormattedMessage defaultMessage="Episodes" />
                                </StyledTab>
                                <StyledTab id="campaign">
                                    <FormattedMessage defaultMessage="Campagnes" />
                                </StyledTab>
                            </StyledTabList>
                            <TabPanel id="episode">
                                <EpisodesList onClose={() => setFiltersOpen(false)} />
                            </TabPanel>
                            <TabPanel id="campaign">
                                <CampaignsList onClose={() => setFiltersOpen(false)} />
                            </TabPanel>
                        </StyledTabs>
                    </DialogInnerWrapper>
                </StyledDialog>
            </Popover>
        </DialogTrigger>
    );
};

const WhiteTertiaryButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--white);
    width: 14rem;
    font-weight: var(--fw-normal);

    & > span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    & > svg {
        flex-shrink: 0;
        flex-basis: 1rem;
    }
`;
const StyledDialog = styled(Dialog)`
    padding: 1rem;
    background-color: var(--white);
    border-radius: var(--r-m);
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.05);
    width: 24rem;
`;
const DialogInnerWrapper = styled.div``;
const StyledTabList = styled(TabList)`
    display: inline-flex;
    align-items: center;
    background-color: var(--neutral100);
    border-radius: var(--r-s);
    padding: 0.25rem;
`;
const StyledTabs = styled(Tabs)`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;
const StyledTab = styled(Tab)`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-block: 0.25rem;
    padding-inline: 0.75rem;
    border-radius: var(--r-s);
    font-weight: var(--fw-semibold);

    &:not([data-selected]) {
        cursor: pointer;
    }
    &[data-selected] {
        background-color: var(--white);
    }
`;

export default EpisodeAndCampaignFilter;
