import { createApi } from '../callApi';
const psoApi = createApi('PSO');
import * as Types from './types';
import type { Platform, Mode } from '@/api/pso/types';

export const psoLiveSearch = (
    showId: string,
    keyword: string,
    platform: Platform,
    store: string,
    mode: Mode,
    format?: string,
): Promise<Types.LiveSearchResponse> => {
    return psoApi({
        method: 'GET',
        target: `/search?showId=${showId}&keyword=${keyword}&platform=${platform}&store=${store}&mode=${mode}`,
        headers: {
            'Content-Type': format ?? 'application/json',
            Accept: format ?? 'application/json',
        },
    });
};

export const psoCompetitors = (
    showId: string,
    platform: Platform,
    store: string,
    mode: Mode,
): Promise<Types.CompetitorsResponse> => {
    return psoApi({
        method: 'GET',
        target: `/competitors?showId=${showId}&platform=${platform}&store=${store}&mode=${mode}`,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    });
};

export const psoKeywordsRanking = (showId: string, platform: Platform, store: string) => {
    return psoApi({
        method: 'GET',
        target: `/keywords_ranking?showId=${showId}&platform=${platform}&store=${store}`,
    });
};
