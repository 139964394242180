import styled, { css, keyframes } from 'styled-components';
import Stack from '@ui/layout/Stack';
import Cluster from '@ui/layout/Cluster';

const Skeleton = () => {
    return (
        <Stack $gap="2.5rem">
            <Stack $gap="2rem">
                <ImageBlock $w="6rem" $h="6rem" />
                <Stack $gap="0.5rem">
                    <Line $w="5rem" $h="0.5rem" />
                    <LightBlock $w="100%" $h="2.5rem" />
                </Stack>
                <Stack $gap="0.5rem">
                    <Line $w="5rem" $h="0.5rem" />
                    <LightBlock $w="100%" $h="7.5rem" />
                </Stack>
                <Stack $gap="0.5rem">
                    <Line $w="5rem" $h="0.5rem" />
                    <LightBlock $w="100%" $h="2.5rem" />
                </Stack>
                <Stack $gap="0.5rem">
                    <Line $w="5rem" $h="0.5rem" />
                    <LightBlock $w="100%" $h="2.5rem" />
                </Stack>
                <Stack $gap="0.5rem">
                    <Line $w="5rem" $h="0.5rem" />
                    <LightBlock $w="100%" $h="2.5rem" />
                </Stack>
                <Stack $gap="0.5rem">
                    <Line $w="5rem" $h="0.5rem" />
                    <LightBlock $w="100%" $h="2.5rem" />
                </Stack>
                <Stack $gap="0.5rem">
                    <Line $w="5rem" $h="0.5rem" />
                    <LightBlock $w="100%" $h="2.5rem" />
                </Stack>
                <Stack $gap="0.5rem">
                    <Line $w="5rem" $h="0.5rem" />
                    <LightBlock $w="100%" $h="2.5rem" />
                </Stack>
                <Stack $gap="0.5rem">
                    <Line $w="5rem" $h="0.5rem" />
                    <TypeWrapper>
                        <LightBlock />
                        <LightBlock />
                    </TypeWrapper>
                </Stack>
                <Stack $gap="0.5rem">
                    <Line $w="5rem" $h="0.5rem" />
                    <Cluster $gap="0.5rem" $align="center">
                        <LightBlock $w="2.5rem" $h="1rem" />
                        <Line $w="5rem" $h="0.5rem" />
                    </Cluster>
                </Stack>
            </Stack>
            <ButtonBlock $w="10rem" $h="2.5rem" />
        </Stack>
    );
};

const shimmer = keyframes`
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
`;
const shimmering = css`
    background: var(--neutral50);
    background-image: linear-gradient(
        to right,
        var(--neutral50) 0%,
        #edeef1 20%,
        var(--neutral50) 40%,
        var(--neutral50) 100%
    );
    background-repeat: no-repeat;
    background-size: 50rem 6.5rem;
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${shimmer};
    animation-timing-function: linear;
`;
const Line = styled.div<{ $w?: string; $h?: string; $maxW?: string }>`
    border-radius: var(--r-full);
    background-color: var(--neutral100);
    ${({ $w }) => $w && `width: ${$w};`}
    ${({ $h }) => $h && `height: ${$h};`}
    ${({ $maxW }) => $maxW && `max-width: ${$maxW};`}
    ${shimmering};
`;
const Block = styled.div<{ $w?: string; $h?: string }>`
    border-radius: var(--r-s);
    background-color: var(--neutral100);
    ${({ $w }) => $w && `width: ${$w};`}
    ${({ $h }) => $h && `height: ${$h};`}
    ${shimmering};
`;
const LightBlock = styled(Block)<{ $w?: string; $h?: string }>`
    background-color: var(--neutral50);
`;
const ImageBlock = styled(Block)`
    width: 6rem;
    height: 6rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        width: 10rem;
        height: 10rem;
    }
`;
const TypeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.75rem;

    & > * {
        width: 100%;
        height: 2rem;
    }

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
        column-gap: 0.75rem;

        & > * {
            flex: 1;
            height: 8rem;
        }
    }
`;
const ButtonBlock = styled(Block)`
    align-self: center;
`;

export default Skeleton;
