import { useState, useEffect } from 'react';
import { Route, Redirect, Switch, useParams, useRouteMatch } from 'react-router-dom';
import PlaylistsListPage from './PlaylistsListPage';
import { connect } from '@app/decorators/connect';
import FetcherPlaylists from '@app/fetchers/FetcherPlaylists';
import NoPlaylist from '@app/molecules/NoPlaylist';
import ListFormFallback from '@app/fallbacks/ListFormFallback';
import { useAmplitudeLogEvent } from '@hooks/useAmplitude';
import EpisodesLayout from '@app/pages/EpisodesLayout';

const enhance = connect(({ playlistStore }) => ({
    playlists: playlistStore.playlists,
}));

const PlaylistsPage = ({ playlists }) => {
    useAmplitudeLogEvent('Episodes Playlists Tab Viewed');
    const { showId } = useParams();
    const { path } = useRouteMatch();

    const [firstPlaylistId, setFirstPlaylistId] = useState('');
    useEffect(() => setFirstPlaylistId(playlists[0]?.id), [playlists[0]?.id, showId]);
    return (
        <FetcherPlaylists showId={showId}>
            {(isLoading) => {
                return (
                    <EpisodesLayout>
                        {isLoading ? (
                            <ListFormFallback />
                        ) : (
                            <Switch>
                                <Route
                                    exact
                                    path={`${path}/:playlistId`}
                                    component={PlaylistsListPage}
                                />
                                {playlists.length === 0 || !firstPlaylistId ? (
                                    <Route
                                        exact
                                        path="/app/show/:showId/playlists"
                                        component={NoPlaylist}
                                    />
                                ) : (
                                    <Redirect to={`${path}/${firstPlaylistId}`} />
                                )}
                            </Switch>
                        )}
                    </EpisodesLayout>
                );
            }}
        </FetcherPlaylists>
    );
};

export default enhance(PlaylistsPage);
