import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { PROFILE_OPTIONS, FROM_OPTIONS } from './SubscribeSurveyForm';
import { FORM_VALIDATION_MESSAGE } from '@/utils/validationMessage';

export const useSubscribeSurveySchema = () => {
    const intl = useIntl();

    return yup.object({
        profile: yup
            .string()
            .oneOf(PROFILE_OPTIONS, intl.formatMessage(FORM_VALIDATION_MESSAGE.invalid))
            .required(intl.formatMessage(FORM_VALIDATION_MESSAGE.required)),
        from: yup
            .string()
            .oneOf(FROM_OPTIONS, intl.formatMessage(FORM_VALIDATION_MESSAGE.invalid))
            .required(intl.formatMessage(FORM_VALIDATION_MESSAGE.required)),
        from_other: yup.string(),
    });
};
