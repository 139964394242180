import { useMemo } from 'react';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import { useResponsive } from '@/shared/hooks/useResponsive';
import { FormattedNumber } from 'react-intl';
import Column from '@ui/atoms/Column';
import VariationText from './VariationText';
import VariationAverage from './VariationAverage';

const responsiveData = (responsive: { isMobile: boolean; isTablet: boolean }) => {
    if (responsive.isMobile)
        return {
            scrollSnapAlign: 'center',
            rowSize: '40vw',
            text: 30,
        };

    if (responsive.isTablet) return { text: 'veryBig' };
    return {
        scrollSnapAlign: 'center',
        rowSize: '100%',
        text: 'veryBig',
    };
};

interface VariationProps {
    value:
        | number
        | {
              downloads: number;
              variation: number;
          };
    average?: number;
    step?: string;
    children: any;
}

const Variation = ({ value, average, step, children }: VariationProps) => {
    const { isMobile, isTablet } = useResponsive();
    const resp = responsiveData({ isMobile, isTablet });
    useMemo(() => responsiveData({ isMobile, isTablet }), [isMobile, isTablet]);

    const variation = typeof value === 'object' ? value.variation : undefined;

    return (
        <Column
            align="center"
            column
            mx={10}
            w={resp.rowSize}
            scrollSnapAlign={resp.scrollSnapAlign}
            nowrap
            h="100%"
            justify="flex-start"
        >
            <DeprecatedText size={resp.text} weight="semibold" align="center">
                <FormattedNumber value={typeof value === 'object' ? value.downloads : value} />
                {!!variation && <VariationText variation={Math.round(variation) / 100} />}
            </DeprecatedText>
            <DeprecatedText>{children}</DeprecatedText>
            <VariationAverage average={average} step={step || ''} />
        </Column>
    );
};

export default Variation;
