import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useCopy } from '@/utils/hooks/useCopy';
import DeprecatedModal from '@ui/molecules/DeprecatedModal';
import Stack from '@ui/layout/Stack';
import Text from '@ui/atoms/Text';
import DeprecatedTextInput from '@ui/molecules/DeprecatedTextInput';
import DeprecatedButton from '@ui/atoms/DeprecatedButton';
import Tooltip from '@ui/atoms/Tooltip';
import Cluster from '@ui/layout/Cluster';
import copyIcon from '@public/icons/duplicate.svg';
import info from '@public/icons/info-full.svg';
import openLinkIcon from '@public/icons/external-link.svg';
import {
    HOSTING_PLATFORMS,
    HOSTING_PLATFORMS_HELP_LINKS,
    HOSTING_PLATFORMS_NAMES,
    RSS_FEED_REDIRECTION_TUTORIAL_URL_EN,
    RSS_FEED_REDIRECTION_TUTORIAL_URL_FR,
} from '@/utils/constants';
import { DeprecatedIcon } from '@ui/atoms/DeprecatedIcon';
import { sendAmplitudeLogEvent } from '@/helpers';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import { useParams } from 'react-router';
import useRssFeedQuery from '@/queries/rss/useRssFeedQuery.hook';
import useUserQuery from '@/queries/user/useUserQuery.hook';

const FeedRedirectionModal = ({ modalOpened, closeModal }) => {
    const { showId } = useParams();
    const intl = useIntl();
    const [copied, copy] = useCopy();
    const { data: show } = useShowQuery(showId);
    const { data: feed } = useRssFeedQuery(showId);
    const { data: user } = useUserQuery();

    const handleCopyRssLink = () => {
        if (!show || !feed) return;
        copy(show.rssLink);
        sendAmplitudeLogEvent('RSS Feed Link Copied', {
            show_id: show.id,
            show_name: show.name,
            show_category: show.category.data.name,
            number_episodes: show.podcastsCount,
            previous_hoster: feed.originalPlatform,
        });
    };

    const tutorialLink = useMemo(() => {
        if (feed?.originalPlatform && HOSTING_PLATFORMS_HELP_LINKS[feed.originalPlatform]) {
            return intl.formatMessage(HOSTING_PLATFORMS_HELP_LINKS[feed.originalPlatform]);
        }

        return user?.languageCode === 'fr'
            ? RSS_FEED_REDIRECTION_TUTORIAL_URL_FR
            : RSS_FEED_REDIRECTION_TUTORIAL_URL_EN;
    }, [feed, user]);

    return (
        <DeprecatedModal
            isOpened={modalOpened}
            title={<FormattedMessage defaultMessage="Redirigez votre flux RSS" />}
            onClose={closeModal}
            maxW={400}
        >
            <Stack $gap="2.5rem">
                <Stack $gap="1rem">
                    <Text>
                        <FormattedMessage defaultMessage="Copiez le lien de votre nouveau flux RSS ci-dessous :" />
                    </Text>
                    <DeprecatedTextInput
                        pr={30}
                        value={
                            copied
                                ? intl.formatMessage({ defaultMessage: 'Copié ! 👍' })
                                : show?.rssLink
                        }
                        rightIcon={
                            <DeprecatedIcon
                                as={copyIcon}
                                onClick={handleCopyRssLink}
                                color="--neutral500"
                                size="15px"
                            />
                        }
                    />
                    <Text>
                        {feed?.originalPlatform &&
                        HOSTING_PLATFORMS.includes(feed.originalPlatform) ? (
                            <FormattedMessage
                                defaultMessage="Il semblerait que votre émission provienne de {platform}. Suivez notre tutoriel pour la rediriger vers Ausha."
                                values={{
                                    platform: HOSTING_PLATFORMS_NAMES[feed.originalPlatform],
                                }}
                            />
                        ) : (
                            <FormattedMessage defaultMessage="Suivez ensuite notre tutoriel pour le rediriger vers Ausha." />
                        )}
                    </Text>
                    <Tooltip type="info">
                        <Cluster $gap={10} $wrap="no-wrap">
                            <DeprecatedIcon as={info} color="--info" size="1rem" />
                            <Text color="--info">
                                <FormattedMessage defaultMessage="Cette étape est requise pour finaliser l’import de votre émission sur Ausha." />
                            </Text>
                        </Cluster>
                    </Tooltip>
                </Stack>
                <Cluster $gap="0.5rem" $justifyContent="flex-end">
                    <DeprecatedButton accent="secondary" onClick={closeModal}>
                        <FormattedMessage defaultMessage="Annuler" />
                    </DeprecatedButton>
                    <DeprecatedButton as="a" href={tutorialLink} target="_blank">
                        <DeprecatedIcon as={openLinkIcon} size={15} />
                        <FormattedMessage defaultMessage="Suivre le tutoriel" />
                    </DeprecatedButton>
                </Cluster>
            </Stack>
        </DeprecatedModal>
    );
};

export default FeedRedirectionModal;
