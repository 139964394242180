import { useIntl } from 'react-intl';
import { z } from 'zod';
import { FORM_VALIDATION_MESSAGE } from '@/shared/services/validationMessageService';

export const usePasswordFormSchema = () => {
    const intl = useIntl();

    return z
        .object({
            currentPassword: z.string().min(8, {
                message: intl.formatMessage(FORM_VALIDATION_MESSAGE.minLength, { min: 8 }),
            }),
            newPassword: z.string().min(8, {
                message: intl.formatMessage(FORM_VALIDATION_MESSAGE.minLength, { min: 8 }),
            }),
            newPasswordConfirmation: z.string({
                required_error: intl.formatMessage(FORM_VALIDATION_MESSAGE.required),
            }),
        })
        .superRefine((schema, ctx) => {
            const { currentPassword, newPassword, newPasswordConfirmation } = schema;
            if (newPassword === currentPassword) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: intl.formatMessage(FORM_VALIDATION_MESSAGE.differentValue),
                    path: ['newPassword'],
                });
            }
            if (newPasswordConfirmation !== newPassword) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: intl.formatMessage(FORM_VALIDATION_MESSAGE.valuesDontMatch),
                    path: ['newPasswordConfirmation'],
                });
            }
            return true;
        });
};

export type PasswordFormSchema = z.infer<ReturnType<typeof usePasswordFormSchema>>;
