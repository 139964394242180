import { Menu as AriaMenu, MenuProps, SelectionMode } from 'react-aria-components';
import styled from 'styled-components';
import MenuDropdownItem, { MenuDropdownItemProps } from './MenuDropdownItem';

interface MenuDropdownProps extends MenuProps<any> {
    children?: React.ReactNode | ((icon: string, name: string, item: any) => React.ReactNode);
}

interface MenuDropdownInnerProps {
    icon: string | React.ReactNode;
    name: string | React.ReactNode;
    item: MenuDropdownItemProps;
}

const MenuDropdown = ({ children, ...props }: MenuDropdownProps) => {
    const defaultProps = {
        autoFocus: false,
        shouldFocusWrap: false,
        items: [],
        selectionMode: 'single' as SelectionMode,
        disallowEmptySelection: false,
        onAction: () => {},
        onClose: () => {},
        onSelectionChange: () => {},
    };

    return (
        <Menu {...defaultProps} {...props}>
            {children ||
                (({ icon, name, ...item }: MenuDropdownInnerProps) => (
                    <MenuDropdownItem {...item}>
                        {icon}
                        {name}
                    </MenuDropdownItem>
                ))}
        </Menu>
    );
};

const Menu = styled(AriaMenu)`
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;
    max-width: 10rem;
    border-radius: var(--r-m);
    background-color: var(--white);
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.05);
    padding: 0.5rem;
`;

export default MenuDropdown;
