import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchApplePodcastsRankingOverall } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import statsKeys from './statsKeys';
import { z } from 'zod';

export const RankingSchema = z.object({
    overallStats: z.object({
        note: z.number().nullable(),
        nbNotes: z.number().nullable(),
        reviewsNumber: z.number().nullable(),
        actualPosition: z.number().nullable(),
        averagePosition: z.number().nullable(),
        oldestPosition: z.number().nullable(),
        oldestDateWithData: z.number().nullable(),
    }),
    rankingDetails: z
        .object({
            today: z.number().nullable(),
            last7Days: z.number().nullable(),
            bestPosition: z
                .object({
                    bestPos: z.number().nullable(),
                    bestPosTimestamp: z.number().nullable(),
                })
                .nullable(),
            last30Days: z.object({
                position: z.number().nullable(),
                variation: z.number().nullable(),
            }),
            beforeLast30Days: z.number().nullable(),
        })
        .nullable(),
});
export type Ranking = z.infer<typeof RankingSchema>;
type Key = QueryFunctionContext<ReturnType<typeof statsKeys.appleRankingOverall>>;
type InputProps = {
    showId: string;
    categoryId: string;
    timezone: string;
    options?: {
        enabled?: boolean;
    };
};

const queryFn = async ({ queryKey: [{ showId, categoryId, timezone }] }: Key) => {
    const { data } = await fetchApplePodcastsRankingOverall(showId, categoryId, timezone);
    return RankingSchema.parse(camelcaseKeys(data, { deep: true }));
};

const useAppleRankingQuery = ({ showId, categoryId, timezone, options }: InputProps) => {
    return useQuery({
        queryKey: statsKeys.appleRankingOverall(showId, categoryId, timezone),
        queryFn,
        enabled: options?.enabled || true,
    });
};

export default useAppleRankingQuery;
