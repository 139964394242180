import { FormattedMessage } from 'react-intl';
import Text from '@ui/atoms/Text';
import Button from '@/components/Button';
import styled from 'styled-components';
import { UI_VARIANTS } from '@/shared/config/constants';
import DialogAlert from '@ui/atoms/DialogAlert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import useSubscriptionPricePreviewQuery from '@queries/quota/useSubscriptionPricePreviewQuery.hook';
import useSubscriptionQuery from '@queries/subscription/useSubscriptionQuery.hook';

interface ActiveShowsQuotaConfirmationModalProps {
    isOpen: boolean;
    onOpenChange: (isOpen: boolean) => void;
    isLoading: boolean;
    onSubmit: (quantity: number) => void;
    quantity: number;
}

const ActiveShowsQuotaConfirmationModal = ({
    isOpen,
    onOpenChange,
    isLoading,
    onSubmit,
    quantity,
}: ActiveShowsQuotaConfirmationModalProps) => {
    const { data: subscription } = useSubscriptionQuery();
    const pricePreview = useSubscriptionPricePreviewQuery({ quantity });

    return (
        <DialogAlert
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            action={
                <>
                    <Button
                        isLoading={isLoading || pricePreview.isLoading}
                        onPress={() => onSubmit(quantity)}
                    >
                        <FormattedMessage defaultMessage="Confirmer la modification" />
                    </Button>
                    <CancelButton onPress={() => onOpenChange(false)} variant="ghost">
                        <FormattedMessage defaultMessage="Annuler" />
                    </CancelButton>
                </>
            }
            variant={UI_VARIANTS.PRIMARY}
            icon={<TrashIcon icon={icon({ name: 'receipt', style: 'solid' })} />}
            title={
                <FormattedMessage defaultMessage="Êtes-vous sûr de vouloir modifier votre quota d'émissions actives ?" />
            }
        >
            {pricePreview.isLoading ? (
                <LoadingWrapper>
                    <LoadingBar width={70} />
                    <LoadingBar width={100} />
                    <LoadingBar width={50} />
                </LoadingWrapper>
            ) : (
                <Text variant="bodyM" color="--neutral500" textAlign="center">
                    <FormattedMessage
                        defaultMessage="En validant cette modification, le coût de votre abonnement sera maintenant de <b>{totalPrice} /{periodicity}</b>. Vous serez facturé de <b>{price}</b> dès maintenant."
                        values={{
                            price: pricePreview?.data?.cost,
                            totalPrice: pricePreview?.data?.total,
                            periodicity: subscription?.yearly ? (
                                <FormattedMessage defaultMessage="an" />
                            ) : (
                                <FormattedMessage defaultMessage="mois" />
                            ),
                            b: (chunks: React.ReactNode) => (
                                <Text as="span" fontWeight="--fw-semibold">
                                    {chunks}
                                </Text>
                            ),
                        }}
                    />
                </Text>
            )}
        </DialogAlert>
    );
};

const CancelButton = styled(Button)`
    color: var(--neutral500);
    padding: 0;
`;
const TrashIcon = styled(FontAwesomeIcon)`
    color: var(--primary);
    width: 1rem;
    height: 1rem;
`;
const LoadingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    margin-top: 0.75rem;
    align-items: center;
`;
const LoadingBar = styled.div<{ width: number }>`
    height: 0.5rem;
    width: ${({ width }) => width}%;
    background-color: var(--neutral100);
    border-radius: var(--r-full);
`;

export default ActiveShowsQuotaConfirmationModal;
