import styled, { css } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { connect } from '@/components/legacy/connect';
import { STEP_FORMAT_SELECTION_LABEL, STEP_TRANSCRIPTION_LABEL } from './index';
import Stack from '@ui/layout/Stack';
import DeprecatedAlert from '@ui/molecules/DeprecatedAlert';
import Button from '@ui/atoms/Button';

const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: ${(p) => (p.hasPreviousLabel ? '0.5rem' : '1rem')};

    ${(p) => p.theme.mediaQueries.tabletAndUp} {
        flex-direction: row;
        justify-content: flex-end;
        column-gap: 1rem;

        ${(p) =>
            p.hasPreviousLabel &&
            css`
                & button:nth-child(1) {
                    margin-right: auto;
                }
            `}
    }
`;

const enhance = connect(({ clipStore }, { formik, currentStep }) => {
    const isTranscriptionStep = currentStep.props.label === STEP_TRANSCRIPTION_LABEL;
    const transcriptionIsDone =
        (clipStore.transcriptionProgress &&
            clipStore.transcriptionProgress.transcription &&
            clipStore.transcriptionProgress.transcription.isDone) ||
        false;
    const transcription = formik.values.transcription;

    return {
        displayNextButton: isTranscriptionStep ? transcriptionIsDone && transcription : true,
    };
});

const ClipEditWizardFooter = ({
    currentStep,
    formik,
    previous,
    displayNextButton,
    handleContinueLater,
}) => (
    <Footer>
        {currentStep.props.label === STEP_FORMAT_SELECTION_LABEL && (
            <DeprecatedAlert type="info" css="align-self: flex-end">
                <FormattedMessage defaultMessage="Vous ne pourrez plus ajouter de nouveaux formats par la suite." />
            </DeprecatedAlert>
        )}
        <ButtonsWrapper hasPreviousLabel={currentStep.props.previousLabel}>
            {currentStep.props.previousLabel && (
                <Button variant="secondary" onPress={() => previous(formik.values)}>
                    {currentStep.props.previousLabel}
                </Button>
            )}
            <Button onPress={() => handleContinueLater(formik)} variant="secondary">
                <FormattedMessage defaultMessage="Continuer plus tard" />
            </Button>
            {currentStep.props.nextLabel && displayNextButton && (
                <Button type="submit" isDisabled={formik.isSubmitting || !formik.isValid}>
                    {currentStep.props.nextLabel}
                </Button>
            )}
        </ButtonsWrapper>
    </Footer>
);

const Footer = styled(Stack)`
    gap: 1rem;
    padding: 1.5rem;
    margin: -1.25rem;
    border-top: 1px solid var(--neutral100);
`;

export default enhance(ClipEditWizardFooter);
