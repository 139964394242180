import { createFetcher } from '../Fetcher';

const FetcherPeakTime = createFetcher(({ peakTimeStatsStore }, { showId, days }) => {
    return {
        fetch: () => peakTimeStatsStore.fetcherPeakTime(showId, { days }),
        isLoading: peakTimeStatsStore.fetcherPeakTime.pending,
        input: [showId, days],
    };
});

export default FetcherPeakTime;
