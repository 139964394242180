import { Node } from '@tiptap/core';

const CustomPhrase = Node.create({
    name: 'phrase',
    group: 'inline',
    inline: true,
    content: 'word+',

    addAttributes() {
        return {
            start: {
                default: null,
                rendered: false,
            },
            end: {
                default: null,
                rendered: false,
            },
            text: {
                default: null,
                rendered: false,
            },
            confidence: {
                default: null,
                rendered: false,
            },
            speaker: {
                default: null,
                rendered: false,
            },
            class: {
                default: 'phrase',
            },
        };
    },

    parseHTML() {
        return [{ tag: 'span' }];
    },

    renderHTML({ HTMLAttributes }) {
        return ['span', HTMLAttributes, 0];
    },
});

export default CustomPhrase;
