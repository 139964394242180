import styled from 'styled-components';
import { useContext, useRef } from 'react';
import { useRadio, VisuallyHidden } from 'react-aria';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import RadioTabContext from './RadioTabContext';

const BACKGROUND_LAYOUT_ID = 'background';

const Label = styled.label`
    cursor: ${(props) => (props.isGroupDisabled || props.isDisabled ? null : 'pointer')};
    font-size: var(--fs-body);
    font-weight: var(--fw-semibold);
    // Creates new stacking context to put animated background behind label
    // content without the need to define an arbitrary z-index value
    isolation: isolate;
    letter-spacing: 0.02em;
    line-height: 1.5;
    opacity: ${(props) => (props.isDisabled ? 0.5 : null)};
    padding: 0.25rem 0.75rem;
    position: relative;
    text-align: center;
`;

const Background = styled(motion.div)`
    background: var(--white);
    border-radius: var(--r-xs);
    inset: 0;
    position: absolute;
    // Put background behind label content
    z-index: -1;
`;

const RadioTab = (props) => {
    const { children, value, isDisabled } = props;
    const radioGroupState = useContext(RadioTabContext);
    const ref = useRef(null);
    const { inputProps } = useRadio(props, radioGroupState, ref);

    const isSelected = radioGroupState.selectedValue === value;
    const isGroupDisabled = radioGroupState.isDisabled;

    return (
        <Label isSelected={isSelected} isDisabled={isDisabled} isGroupDisabled={isGroupDisabled}>
            <VisuallyHidden>
                <input {...inputProps} ref={ref} />
            </VisuallyHidden>
            {isSelected && <Background layoutId={BACKGROUND_LAYOUT_ID} />}
            {children}
        </Label>
    );
};

RadioTab.propTypes = {
    value: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool,
    autoFocus: PropTypes.bool,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onFocusChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    onKeyUp: PropTypes.func,
    id: PropTypes.string,
    'aria-label': PropTypes.string,
    'aria-labelledby': PropTypes.string,
    'aria-describedby': PropTypes.string,
    'aria-details': PropTypes.string,
    children: PropTypes.node.isRequired,
};

RadioTab.defaultProps = {
    isDisabled: undefined,
    autoFocus: undefined,
    onFocus: undefined,
    onBlur: undefined,
    onFocusChange: undefined,
    onKeyDown: undefined,
    onKeyUp: undefined,
    id: undefined,
    'aria-label': undefined,
    'aria-labelledby': undefined,
    'aria-describedby': undefined,
    'aria-details': undefined,
};

export default RadioTab;
