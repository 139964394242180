import { useState } from 'react';
import styled from 'styled-components';
import PersonalAccessTokenForm from './PersonalAccessTokenForm';
import PersonalAccessTokenTable from './PersonalAccessTokenTable';
import Text from '../ui/atoms/Text';
import { FormattedMessage } from 'react-intl';
import Stack from '../ui/layout/Stack';
import ExternalLink from '../Link/ExternalLink';
import { PersonalAccessTokenSchema } from './PersonalAccessTokenForm/usePersonalAccessTokenFormSchema.hook';
import PersonalAccessTokenPreview from './PersonalAccessTokenPreview';
import addPersonalAccessTokenMutation from '@/queries/personalAccessToken/addPersonalAccessTokenMutation';

const PersonalAccessToken = () => {
    const [newToken, setNewToken] = useState<string | null>(null);
    const addPersonalAccessToken = addPersonalAccessTokenMutation();

    const handleAddToken = (data: PersonalAccessTokenSchema) => {
        const { expiresAt, ...rest } = data;
        const formattedDate = expiresAt.toString();
        const newData = { ...rest, expiresAt: formattedDate };

        addPersonalAccessToken.mutate(newData, {
            onSuccess: (data) => {
                setNewToken(data.accessToken);
            },
        });
    };

    return (
        <Wrapper>
            <NewTokenContainer>
                <Stack>
                    <Text fontWeight="--fw-bold">
                        <FormattedMessage defaultMessage="Jeton d’accès" />
                    </Text>
                    <Text color="--neutral500">
                        <FormattedMessage
                            defaultMessage="Vous pouvez générer un jeton d'accès pour chaque application ayant besoin d'accéder à Ausha. <link>En savoir plus</link>"
                            values={{
                                link: (chunks: string) => (
                                    <ExternalLink
                                        target="_blank"
                                        href="https://developers.ausha.co/"
                                    >
                                        {chunks}
                                    </ExternalLink>
                                ),
                            }}
                        />
                        <br />
                        <FormattedMessage defaultMessage="Pour donner un jeton d'accès à une application, saisissez son nom ci-dessous." />
                    </Text>
                </Stack>

                <PersonalAccessTokenForm onSubmit={handleAddToken} />
                {!!newToken && <PersonalAccessTokenPreview token={newToken} />}
            </NewTokenContainer>
            <Stack $gap="1rem">
                <Text variant="title" fontWeight="--fw-bold">
                    <FormattedMessage defaultMessage="Jetons d’accès actifs" />
                </Text>
                <Container>
                    <PersonalAccessTokenTable />
                </Container>
            </Stack>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
`;
const Container = styled.div`
    padding: 1rem;
    background-color: var(--white);
    border-radius: var(--r-l);
`;
const NewTokenContainer = styled(Container)`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`;

export default PersonalAccessToken;
