import { useQuery } from '@tanstack/react-query';
import { fetchAvailableStores } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import psoKeys from './psoKeys';
import { z } from 'zod';

export const AvailableStoreSchema = z.object({
    alpha2Code: z.string(),
    name: z.string(),
});
export const AvailableStoresSchema = z.array(AvailableStoreSchema);
export type AvailableStore = z.infer<typeof AvailableStoreSchema>;

const queryFn = async () => {
    const { data } = await fetchAvailableStores();
    return AvailableStoresSchema.parse(camelcaseKeys(data, { deep: true }));
};

const useAvailableStoresQuery = () => {
    return useQuery({
        queryKey: psoKeys.availableStores(),
        queryFn,
    });
};

export default useAvailableStoresQuery;
