import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
    TWITTER,
    EPISODE_STATUS,
    SOCIAL_NETWORK_POST_STATUS,
    SOCIAL_NETWORK_POST_TYPE,
} from '@/shared/config/constants';
import Text from '@ui/atoms/Text';
import Button from '@ui/atoms/Button';
import SocialMediaRichTextEditor from './SocialMediaRichTextEditor';
import SocialMediaContentTypeButtons from './SocialMediaContentTypeButtons';
import RadioGroup from '@ui/molecules/RadioGroup';
import InputRadio from '@ui/atoms/InputRadio';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import publishDuplicate from '@public/icons/publish-duplicate.svg?url';
import planDuplicate from '@public/icons/plan-duplicate.svg?url';
import useUpdateSocialNetworkPostMutation from '@/queries/socialNetworkPost/useUpdateSocialNetworkPostMutation.hook';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import { useEditedPostContext } from '@app/pages/SocialMediaPage/useEditedPostContext.hook';
import styled from 'styled-components';
import { DialogTrigger, Popover, Button as AriaButton } from 'react-aria-components';
import MenuDropdown from '@ui/atoms/MenuDropdown';
import MenuDropdownItem from '@ui/atoms/MenuDropdown/MenuDropdownItem';
import Stack from '@ui/layout/Stack';
import dayjs from 'dayjs';
import { useBodyToastQueue } from '@/shared/hooks/useBodyToastQueue.hook';
import DateTimePicker from '@/components/DateTimePicker';
import Alert from '@/components/ui/atoms/Alert';

const CreateOrUpdatePostForm = ({ post, platformId, episode, close }) => {
    const { showId } = useParams();
    const [postContentCharacterOverflow, setPostContentCharacterOverflow] = useState(false);
    const episodeIsDraftOrPlanned = [EPISODE_STATUS.DRAFT, EPISODE_STATUS.SCHEDULED].includes(
        episode.state,
    );
    const isPlannedForEpisodeReleasePost = SOCIAL_NETWORK_POST_STATUS.PUBLISH_NOW === post.state;
    const isPlannedPost = SOCIAL_NETWORK_POST_STATUS.PUBLISH_AT === post.state;
    const [planned, setPlanned] = useState(isPlannedPost);
    const [plannedForEpisodeRelease, setPlannedForEpisodeRelease] = useState(
        isPlannedForEpisodeReleasePost,
    );
    const isPlanned = useMemo(
        () => planned || plannedForEpisodeRelease,
        [planned, plannedForEpisodeRelease],
    );
    const [postType, setPostType] = useState(post.type);
    const [publishAt, setPublishAt] = useState(
        post.publishAt || dayjs().format('YYYY-MM-DDTHH:mm:ss'),
    );
    const [clipFormatId, setClipFormatId] = useState(post?.clipFormatId);
    const toast = useBodyToastQueue();
    const updatePost = useUpdateSocialNetworkPostMutation();
    const editPostContext = useEditedPostContext();

    const show = useShowQuery(showId);
    const resolvePostContentVariables = (content, show, episode) => {
        if (!content) return '';
        const resolvedTemplate = content.replace(
            /{{(.*?)}}/g,
            (_, value) =>
                ({
                    'show.name': show?.name || '',
                    'episode.name': episode?.name || '',
                    'episode.tags': episode?.tags.map((t) => `#${t.name}`).join(' ') || '',
                }[value]),
        );

        return resolvedTemplate.trim();
    };

    const resolvedContent = resolvePostContentVariables(post.messageTpl, show?.data, episode);
    const [postContentText, setPostContentText] = useState(resolvedContent);

    const submitForm = (state, publishAndDuplicate = false) => {
        updatePost.mutate(
            {
                postId: post.id,
                payload: {
                    clipFormatId,
                    messageTpl: postContentText,
                    type: postType,
                    state,
                    publishAt,
                },
            },
            {
                onSuccess: (post) => {
                    toast.success();
                    if (publishAndDuplicate) {
                        editPostContext.setEditedPost({ post, duplicate: true });
                        return;
                    }
                    close();
                },
                onError: () => {
                    console.error('error');
                },
            },
        );
    };

    const duplicateMenuHandler = (id) => {
        switch (id) {
            case 'duplicate':
                submitForm(
                    planned
                        ? SOCIAL_NETWORK_POST_STATUS.PUBLISH_AT
                        : SOCIAL_NETWORK_POST_STATUS.PUBLISH_NOW,
                    true,
                );
                break;
            default:
                break;
        }
    };

    return (
        <CreateOrUpdatePostFormWrapper>
            <FormItemWrapper>
                <FormItemTitleWrapper>
                    <FormItemTitle>
                        <FormattedMessage defaultMessage="Contenu à partager" />
                        <MandatoryIndicator>*</MandatoryIndicator>
                    </FormItemTitle>
                    <FormItemSubtitleWrapper>
                        <FormItemSubtitle>
                            <FormattedMessage defaultMessage="Choisissez le contenu que vous souhaitez partager dans votre post." />
                        </FormItemSubtitle>
                    </FormItemSubtitleWrapper>
                </FormItemTitleWrapper>
                <SocialMediaContentTypeButtons
                    post={post}
                    provider={platformId}
                    episode={episode}
                    onChangeType={(type) => setPostType(type)}
                    onChangeContent={(content) => setClipFormatId(content)}
                />
            </FormItemWrapper>
            <FormItemWrapper>
                <FormattedMessage defaultMessage="Écrivez quelque chose…">
                    {(placeholder) => (
                        <SocialMediaRichTextEditor
                            provider={platformId}
                            episodeId={episode.id}
                            value={postContentText}
                            onChange={(value) => setPostContentText(value)}
                            placeholder={placeholder.join('')}
                            availableHashtags={episode?.tags?.map((tag) => `#${tag.name}`)}
                            setHasPassedCharacterLimit={(passed) => {
                                setPostContentCharacterOverflow(passed);
                            }}
                            hasPassedCharacterLimit={postContentCharacterOverflow}
                            isPostingSmartlinkOnTwitter={
                                platformId === TWITTER &&
                                postType === SOCIAL_NETWORK_POST_TYPE.SMARTLINK
                            }
                            postId={post.id}
                        />
                    )}
                </FormattedMessage>
            </FormItemWrapper>
            <FormItemWrapper>
                <RadioGroup
                    defaultValue={isPlannedPost ? 'planned' : 'now'}
                    onChange={(value) => {
                        setPlanned(value === 'planned');
                        setPlannedForEpisodeRelease(episodeIsDraftOrPlanned && value === 'now');
                    }}
                    orientation="vertical"
                    gap="1rem"
                >
                    <InputRadio value="now">
                        {episodeIsDraftOrPlanned ? (
                            <FormattedMessage defaultMessage="Publier à la sortie" />
                        ) : (
                            <FormattedMessage defaultMessage="Publier ce post maintenant" />
                        )}
                    </InputRadio>
                    <InputRadio value="planned">
                        <FormattedMessage defaultMessage="Planifier ce post" />
                    </InputRadio>
                </RadioGroup>
                {planned && <DateTimePickerStyled value={publishAt} onChange={setPublishAt} />}
                {episodeIsDraftOrPlanned && (
                    <Alert
                        variant="info"
                        icon={<InfoIcon icon={icon({ name: 'circle-info', style: 'solid' })} />}
                    >
                        <Text fontWeight="--fw-semibold" textAlign="left">
                            <FormattedMessage defaultMessage="Assurez-vous que votre épisode soit en ligne et de visibilité publique ou non-listée lors de la publication du post." />
                        </Text>
                    </Alert>
                )}
            </FormItemWrapper>
            <ActionWrapper>
                <ActionInnerWrapper>
                    <Button
                        isDisabled={!postType || postContentCharacterOverflow}
                        onPress={() => {
                            submitForm(
                                planned
                                    ? SOCIAL_NETWORK_POST_STATUS.PUBLISH_AT
                                    : SOCIAL_NETWORK_POST_STATUS.PUBLISH_NOW,
                            );
                        }}
                        startIcon={
                            isPlanned ? (
                                <FontAwesomeIcon
                                    icon={icon({ name: 'calendar', style: 'solid' })}
                                    size="lg"
                                />
                            ) : (
                                <FontAwesomeIcon
                                    icon={icon({ name: 'paper-plane', style: 'solid' })}
                                    size="lg"
                                />
                            )
                        }
                    >
                        {isPlannedPost && planned ? (
                            <FormattedMessage defaultMessage="Enregistrer" />
                        ) : isPlanned ? (
                            <FormattedMessage defaultMessage="Planifier" />
                        ) : episodeIsDraftOrPlanned ? (
                            <FormattedMessage defaultMessage="Publier à la sortie" />
                        ) : (
                            <FormattedMessage defaultMessage="Publier" />
                        )}
                    </Button>
                    <DuplicateButtonMobile
                        isDisabled={!postType || postContentCharacterOverflow}
                        startIcon={
                            <FontAwesomeIcon
                                icon={icon({ name: 'chevron-up', style: 'solid' })}
                                size="md"
                            />
                        }
                        onPress={() => duplicateMenuHandler('duplicate')}
                    >
                        <FormattedMessage defaultMessage="Publier et dupliquer" />
                    </DuplicateButtonMobile>
                    <DialogTrigger>
                        <DuplicateButtonDesktop
                            as={Button}
                            isDisabled={!postType || postContentCharacterOverflow}
                            startIcon={
                                <FontAwesomeIcon
                                    icon={icon({ name: 'chevron-up', style: 'solid' })}
                                    size="md"
                                />
                            }
                        />
                        <Popover placement="top right">
                            <DuplicateMenuDropdown onAction={duplicateMenuHandler}>
                                <MenuDropdownItem id="duplicate">
                                    {isPlanned ? (
                                        <StackedIcon src={planDuplicate} />
                                    ) : (
                                        <StackedIcon src={publishDuplicate} />
                                    )}
                                    <Stack>
                                        <Text>
                                            {isPlanned ? (
                                                <FormattedMessage defaultMessage="Planifier et dupliquer" />
                                            ) : (
                                                <FormattedMessage defaultMessage="Publier et dupliquer" />
                                            )}
                                        </Text>
                                        <Text color="--neutral500">
                                            <FormattedMessage defaultMessage="Dupliquer ensuite ce post sur un autre réseau social." />
                                        </Text>
                                    </Stack>
                                </MenuDropdownItem>
                            </DuplicateMenuDropdown>
                        </Popover>
                    </DialogTrigger>
                </ActionInnerWrapper>
                <Button
                    variant="secondary"
                    isDisabled={postContentCharacterOverflow}
                    onPress={() => submitForm(SOCIAL_NETWORK_POST_STATUS.DRAFT)}
                >
                    {post && post.state !== SOCIAL_NETWORK_POST_STATUS.DRAFT ? (
                        <FormattedMessage defaultMessage="Passer en brouillon" />
                    ) : (
                        <FormattedMessage defaultMessage="Continuer plus tard" />
                    )}
                </Button>
            </ActionWrapper>
        </CreateOrUpdatePostFormWrapper>
    );
};

const CreateOrUpdatePostFormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;
const FormItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;
const FormItemTitleWrapper = styled.div``;
const FormItemTitle = styled(Text)`
    font-weight: var(--fw-semibold);
`;
const MandatoryIndicator = styled.span`
    color: var(--alert);
`;
const FormItemSubtitleWrapper = styled.div``;
const FormItemSubtitle = styled(Text)`
    color: var(--neutral500);
`;
const ActionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        column-gap: 0.5rem;
    }
`;
const ActionInnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
        align-items: center;
        column-gap: 0.25rem;
        order: 1;
    }
`;
const DuplicateMenuDropdown = styled(MenuDropdown)`
    max-width: fit-content;
`;
const StackedIcon = styled.img`
    width: 1.5rem;
    height: 1.5rem;
`;
const DateTimePickerStyled = styled(DateTimePicker)`
    max-width: 25rem;
`;
const DuplicateButtonMobile = styled(Button)`
    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: none;
    }
`;
const DuplicateButtonDesktop = styled(AriaButton)`
    display: none;
    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: initial;
    }
`;
const InfoIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
`;

CreateOrUpdatePostForm.propTypes = {
    platformId: PropTypes.string.isRequired,
    episode: PropTypes.object.isRequired,
    post: PropTypes.object,
    close: PropTypes.func,
};

export default CreateOrUpdatePostForm;
