import { useState } from 'react';
import { FormattedDate, FormattedMessage, FormattedNumber } from 'react-intl';
import Link from '@ui/atoms/Link';
import calendar from '@public/icons/calendar.svg';
import play from '@public/icons/play.svg';
import edit from '@public/icons/edit.svg';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import Status from '@ui/molecules/Status';
import DeprecatedButton from '@ui/atoms/DeprecatedButton';
import DeprecatedIcon from '@ui/atoms/DeprecatedIcon';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import { connect } from '../decorators/connect';
import CampaignLineMenu from './CampaignLineMenu';
import DeprecatedWhitePaper from '@ui/atoms/DeprecatedWhitePaper';
import Desktop from '@ui/molecules/Desktop';
import { useDroppable } from '@/utils/hooks/useDroppable';
import DeprecatedCheckbox from '@ui/atoms/DeprecatedCheckbox';
import { CAMPAIGN_STATUS } from '@/utils/constants';

const messages = {
    [CAMPAIGN_STATUS.ACTIVE]: <FormattedMessage defaultMessage="En ligne" />,
    [CAMPAIGN_STATUS.BLOCKED]: <FormattedMessage defaultMessage="Bloquée" />,
    [CAMPAIGN_STATUS.PLANIFIED]: <FormattedMessage defaultMessage="Planifiée" />,
    [CAMPAIGN_STATUS.DRAFT]: <FormattedMessage defaultMessage="Brouillon" />,
    [CAMPAIGN_STATUS.FINISHED]: <FormattedMessage defaultMessage="Terminée" />,
    [CAMPAIGN_STATUS.PAUSED]: <FormattedMessage defaultMessage="En pause" />,
};

const enhance = connect(({ routerStore }) => ({
    link: routerStore.link,
}));

function CampaignLine({ campaign, link }) {
    const [hovered, onHover] = useState(false);
    const [ref, opened, open] = useDroppable();
    const openedIndex = () => (opened ? 2 : 1);
    const isFinished = campaign.status === CAMPAIGN_STATUS.FINISHED;

    return (
        <DeprecatedWhitePaper
            p={15}
            onMouseEnter={() => onHover(true)}
            onMouseLeave={() => onHover(false)}
            align="center"
            raise={hovered ? 'smooth' : null}
            border="--neutral100"
            bt={1}
            flex
            rounded={false}
            overflowHide={false}
            z={hovered ? openedIndex() : 0}
        >
            <DeprecatedPaper w="max-content">
                <DeprecatedCheckbox
                    mr={15}
                    onChange={campaign.toggleSelection}
                    checked={campaign.isSelected}
                />
            </DeprecatedPaper>
            <DeprecatedPaper ellipsis auto>
                <DeprecatedText weight="semibold" size="big" mb={5} ellipsis pr={30}>
                    {campaign.name}
                </DeprecatedText>
                <DeprecatedPaper flex>
                    <DeprecatedText
                        flex
                        align="center"
                        size="small"
                        color="--neutral500"
                        mr={15}
                        nowrap
                        ellipsis
                    >
                        <DeprecatedIcon color="--neutral500" as={calendar} mr={5} />
                        {campaign.startsAt && campaign.endsAt && (
                            <FormattedMessage
                                defaultMessage="Du {from} au {to}"
                                values={{
                                    from: <FormattedDate value={campaign.startsAt} />,
                                    to: <FormattedDate value={campaign.endsAt} />,
                                }}
                            />
                        )}
                        {campaign.startsAt && !campaign.endsAt && (
                            <FormattedMessage
                                defaultMessage="Commence le {from}"
                                values={{
                                    from: <FormattedDate value={campaign.startsAt} />,
                                }}
                            />
                        )}
                        {!campaign.startsAt && campaign.endsAt && (
                            <FormattedMessage
                                defaultMessage="Se termine le {to}"
                                values={{
                                    to: <FormattedDate value={campaign.endsAt} />,
                                }}
                            />
                        )}
                    </DeprecatedText>
                    <Desktop>
                        <DeprecatedText
                            flex
                            align="center"
                            size="small"
                            color="--neutral500"
                            nowrap
                        >
                            <DeprecatedIcon color="--neutral500" as={play} mr={5} />
                            <FormattedNumber value={campaign.downloadsCount} />
                        </DeprecatedText>
                    </Desktop>
                </DeprecatedPaper>
            </DeprecatedPaper>
            <Desktop>
                <DeprecatedPaper mr={10} w="max-content">
                    <Status value={campaign.status} mr={15}>
                        {messages[campaign.status]}
                    </Status>
                </DeprecatedPaper>
                {!isFinished && (
                    <DeprecatedButton
                        accent="rounded"
                        mr={10}
                        as={Link}
                        to={link('menu.monetize.edit', { campaignId: campaign.id })}
                    >
                        <DeprecatedIcon as={edit} />
                        <FormattedMessage defaultMessage="Modifier" />
                    </DeprecatedButton>
                )}
            </Desktop>
            <CampaignLineMenu campaign={campaign} ref={ref} opened={opened} open={open} />
        </DeprecatedWhitePaper>
    );
}

export default enhance(CampaignLine);
