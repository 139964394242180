import { FormattedMessage, useIntl } from 'react-intl';
import dayjs from 'dayjs';
import objectSupport from 'dayjs/plugin/objectSupport';
import { useResponsive } from '../../../shared/hooks/useResponsive';
import DeprecatedIcon from '../../ui/atoms/DeprecatedIcon';
import rocket from '@public/images/rocket_launch_time.svg';
import styled from 'styled-components';
import { useParams } from 'react-router';
import useQuery from '@/shared/hooks/useQuery';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import usePeakTimeBestDayHourQuery from '@/queries/stats/usePeakTimeBestDayHourQuery.hook';
import Text from '@/components/ui/atoms/Text';
import { LAUNCH_TIME_MESSAGE } from '../PeakTime';
import ShortListFallback from '@/components/unorganized/ShortListFallback';

dayjs.extend(objectSupport);

const BestDayHour = () => {
    const { showId } = useParams<{ showId: string }>();
    const { isMobileOrTablet } = useResponsive();
    const intl = useIntl();
    const query = useQuery();
    const daysRange = (query.get('days') as '7' | '15' | '30' | '90' | 'always') ?? 'always';
    const bestDayHourQuery = usePeakTimeBestDayHourQuery({ showId, days: daysRange });
    const userQuery = useUserQuery();

    const WEEK_DAYS = [
        intl.formatMessage({ defaultMessage: 'Lundi' }),
        intl.formatMessage({ defaultMessage: 'Mardi' }),
        intl.formatMessage({ defaultMessage: 'Mercredi' }),
        intl.formatMessage({ defaultMessage: 'Jeudi' }),
        intl.formatMessage({ defaultMessage: 'Vendredi' }),
        intl.formatMessage({ defaultMessage: 'Samedi' }),
        intl.formatMessage({ defaultMessage: 'Dimanche' }),
    ];

    if (!bestDayHourQuery.data && !bestDayHourQuery.isLoading) {
        return (
            <PeakTimeBestWrapper>
                <DeprecatedIcon as={rocket} size={90} my={isMobileOrTablet ? 35 : undefined} />
                <TextWrapper>
                    <Text variant="title">
                        <Text variant="title">
                            <FormattedMessage defaultMessage="Vous n’avez pas encore assez d’écoute pour déterminer votre PeakTime" />
                        </Text>
                    </Text>
                    <Text variant="bodyL" color="--neutral500">
                        <FormattedMessage defaultMessage="Revenez consulter vos statistiques plus tard le temps que de nouveaux auditeurs découvrent votre podcast." />
                    </Text>
                </TextWrapper>
            </PeakTimeBestWrapper>
        );
    }

    if (bestDayHourQuery.data && userQuery.data) {
        const bestWeekDay = WEEK_DAYS[bestDayHourQuery.data.bestWeekDay - 1];
        const bestDayHour = dayjs(dayjs().hour(bestDayHourQuery.data.bestDayHour)).format('H');
        const userLocale = userQuery.data.language;

        return (
            <PeakTimeBestWrapper>
                <DeprecatedIcon as={rocket} size={90} my={isMobileOrTablet ? 35 : undefined} />
                <TextWrapper>
                    <Text variant="title">
                        <FormattedMessage
                            defaultMessage="Le <b>{bestWeekDay} à {bestDayHour}</b> est votre moment. 😎"
                            values={{
                                b: (chunks: string) => (
                                    <Text as="span" variant="title" fontWeight="--fw-bold">
                                        {chunks}
                                    </Text>
                                ),
                                bestWeekDay,
                                bestDayHour: intl.formatTime(dayjs({ hour: bestDayHour }), {
                                    hour12: userLocale === 'en',
                                    hour: 'numeric',
                                }),
                            }}
                        />
                    </Text>
                    <Text variant="headingS">
                        <FormattedMessage
                            defaultMessage="{isAlways} {messageLaunchTime},  le {bestWeekDay} à {bestDayHour} est le moment où vous êtes le plus écouté dans la semaine."
                            values={{
                                bestWeekDay,
                                bestDayHour: intl.formatTime(dayjs({ hour: bestDayHour }), {
                                    hour12: userLocale === 'en',
                                    hour: 'numeric',
                                }),
                                messageLaunchTime: LAUNCH_TIME_MESSAGE[daysRange],
                                isAlways:
                                    daysRange === 'always' ? (
                                        <FormattedMessage defaultMessage="Selon votre audience" />
                                    ) : (
                                        <FormattedMessage defaultMessage="Selon votre audience des" />
                                    ),
                            }}
                        />
                    </Text>
                </TextWrapper>
            </PeakTimeBestWrapper>
        );
    }

    return <ShortListFallback />; // TODO: Improve loading state
};

const PeakTimeBestWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding-mobile);
    display: flex;
    gap: 1rem;
    flex-direction: column;
    align-items: center;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        padding: var(--container-padding);
        flex-direction: row;
    }
`;
const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        text-align: left;
    }
`;

export default BestDayHour;
