import styled from 'styled-components';
import { DeprecatedText } from '../DeprecatedText';

const DraftContent = styled.div`
    .public-DraftEditor-content {
        min-height: 100px;
        max-height: 300px;
        overflow-y: auto;
        font-family: 'Open Sans', sans-serif;
        ${DeprecatedText} {
            font-family: 'Open Sans', sans-serif;
            color: var(--info);
        }
        ul {
            li {
                list-style-type: disc;
                div {
                    margin-bottom: 0;
                }
            }
            margin-left: 30px;
        }
        ol {
            li {
                list-style-type: decimal;
                div {
                    margin-bottom: 0;
                }
            }
            margin-left: 30px;
        }
        div {
            margin-bottom: 10px;
        }
    }
    .public-DraftStyleDefault-block {
        margin: 0;
    }
`;

export default DraftContent;
