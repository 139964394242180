import { css } from 'styled-components';

export const buttonResetStyles = css`
    background: none;
    border: none;
    padding: 0;
    border-radius: var(--r-s);
    cursor: pointer;
`;
export const buttonFontStyles = css`
    font-weight: var(--fw-bold);
    text-align: center;
`;
export const buttonTransitionStyles = css`
    transition-duration: 250ms;
    transition-timing-function: ease-out;
    transition-property: background-color, transform, border-color, box-shadow;
`;
export const buttonLoadingStyles = css`
    &[data-pending] > :not(.button-spinner-wrapper) {
        opacity: 0;
    }
`;
export const buttonDisabledStyles = css`
    &[data-disabled='true'] {
        pointer-events: none;
        opacity: 0.5;
        box-shadow: none;
    }
`;
export const buttonHoveredStyles = css`
    &[data-hovered] {
        box-shadow: none;
    }
`;
export const buttonPressedStyles = css`
    &[data-pressed] {
        transform: scale(0.975);
    }
`;
export const buttonFocusVisibleStyles = css`
    &[data-focus-visible] {
        box-shadow: 0px 0px 0px 4px rgba(117, 28, 191, 0.1);
    }
`;
export const buttonLayoutStyles = css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;

    & > .button-content {
        overflow-wrap: anywhere;
    }
`;
