import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updatePassword } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import userKeys from './userKeys';
import { PasswordFormSchema } from '@/components/unorganized/ProfileEdit/ProfileForm/PasswordForm/schema';
import { decamelizeKeys } from 'humps';

const mutationFn = async (formData: PasswordFormSchema) => {
    const { data } = await updatePassword(decamelizeKeys(formData));

    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

export default function updatePasswordMutation() {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: userKeys.detail() });
        },
    });
}
