import Text from '@/components/ui/atoms/Text';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { sendAmplitudeLogEvent } from '@/helpers';
import Snippet from '../Snippet';
import { NonHostingEpisodeFormSchema } from '../../useNonHostingEpisodeFormSchema.hook';

const TagsSummary = () => {
    const { getValues } = useFormContext<NonHostingEpisodeFormSchema>();
    const tags = getValues('content.tags');

    if (!tags.length) return null;

    const handleCopy = () => {
        sendAmplitudeLogEvent('Copy Episode Tags');
    };

    return (
        <Wrapper>
            <Text variant="bodyL" fontWeight="--fw-semibold">
                <FormattedMessage defaultMessage="Tags" />
            </Text>
            <Snippet onCopy={handleCopy} valueToCopy={tags.join(', ')}>
                <Tags>
                    {tags.map((tag: string) => (
                        <Tag key={tag}>{tag}</Tag>
                    ))}
                </Tags>
            </Snippet>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
`;
const Tag = styled.span`
    padding: 0.25rem 0.5rem;
    border-radius: var(--r-s);
    background-color: var(--neutral100);
    font-size: var(--fs-body-m);
    font-weight: var(--fw-semibold);
`;
const Tags = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
`;

export default TagsSummary;
