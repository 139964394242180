import PropTypes from 'prop-types';
import styled from 'styled-components';
import Cluster from '../../layout/Cluster';
import Text from '../../atoms/Text';
import IconInfo from '../../icons/IconInfo';
import IconError from '../../icons/IconError';
import IconWarning from '../../icons/IconWarning';

const TYPES = {
    INFO: 'info',
    WARNING: 'warning',
    ERROR: 'error',
};
const ALLOWED_TYPES = Object.values(TYPES);

const BaseContainer = (props) => (
    <Cluster $gap="0.5rem" $align="center" $wrap="nowrap" {...props} />
);

const Container = styled(BaseContainer)`
    border-radius: var(--r-xs);
    padding: 0.5rem;
`;

const Info = styled(Container)`
    color: var(--info);
    background-color: var(--info50);
`;

const Warning = styled(Container)`
    color: var(--warning);
    background-color: var(--warning50);
`;

const Error = styled(Container)`
    color: var(--alert);
    background-color: var(--alert50);
`;

const getAlertComponents = (type) => {
    const icons = {
        [TYPES.INFO]: [IconInfo, Info],
        [TYPES.WARNING]: [IconWarning, Warning],
        [TYPES.ERROR]: [IconError, Error],
    };

    return icons[type];
};

const DeprecatedAlert = ({ type, children, ...other }) => {
    const [AlertIcon, AlertComponent] = getAlertComponents(type);

    return (
        <AlertComponent type={type} {...other}>
            <AlertIcon />
            <Text as="span" variant="body">
                {children}
            </Text>
        </AlertComponent>
    );
};

DeprecatedAlert.propTypes = {
    type: PropTypes.oneOf(ALLOWED_TYPES).isRequired,
    children: PropTypes.node.isRequired,
};

export default DeprecatedAlert;
