import IconButton from '@/components/IconButton';
import useImportedEpisodesQuery from '@/queries/rss/useImportedEpisodesQuery.hook';
import useRssFeedQuery from '@/queries/rss/useRssFeedQuery.hook';
import useUpdateRssFeedMutation from '@/queries/rss/useUpdateRssFeedMutation.hook';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router';
import styled from 'styled-components';
import Rss from './Rss';
import Youtube from './Youtube';
import { YOUTUBE } from '@/shared/config/constants';
import useHasAccessToAushaForAll from '@/shared/hooks/useHasAccessToAushaForAll.hook';
import useHasAccessToYoutubeSync from '@/shared/hooks/useHasAccessToYoutubeSync.hook';

const EPISODE_POLLING_RATE = 5000;

const ImportBanner = () => {
    const { showId } = useParams<{ showId: string }>();
    const { data: rssFeed } = useRssFeedQuery({ showId });
    const { data: imported } = useImportedEpisodesQuery({
        showId,
        config: {
            refetchInterval: (data: any) => {
                if (data?.counters?.processing > 0 || data?.counters?.pending > 0) {
                    return EPISODE_POLLING_RATE;
                }
                return false;
            },
        },
    });
    const hideImportBanner = useUpdateRssFeedMutation();
    const hasAccessToAushaForAll = useHasAccessToAushaForAll();
    const hasAccessToYoutubeSync = useHasAccessToYoutubeSync();

    if (!imported || !rssFeed || rssFeed.isBannerHidden) return null;

    const hasErrors = imported.counters.error > 0;

    const handleHideBanner = () => {
        hideImportBanner.mutate({ showId, payload: { is_banner_hidden: true } });
    };

    if (hasAccessToAushaForAll && hasAccessToYoutubeSync && rssFeed.originalPlatform === YOUTUBE) {
        return (
            <YoutubeBanner $hasErrors={hasErrors}>
                <CloseButton
                    onPress={handleHideBanner}
                    variant="ghost"
                    aria-label="Close"
                    icon={<CloseIcon icon={icon({ name: 'xmark', style: 'solid' })} />}
                />
                <Youtube
                    importedEpisodes={imported.importedEpisodes}
                    counters={imported.counters}
                />
            </YoutubeBanner>
        );
    }

    return (
        <RssBanner>
            <CloseButton
                onPress={handleHideBanner}
                variant="ghost"
                aria-label="Close"
                icon={<CloseIcon icon={icon({ name: 'xmark', style: 'solid' })} />}
            />
            <Rss
                importedEpisodes={imported.importedEpisodes}
                counters={imported.counters}
                onComplete={handleHideBanner}
            />
        </RssBanner>
    );
};

const CloseButton = styled(IconButton)`
    position: absolute;
    padding: 0;
    color: var(--neutral500);
`;
const RssBanner = styled.div`
    border-radius: var(--r-l);
    background-color: var(--primary50);
    position: relative;

    & > ${CloseButton} {
        right: 1rem;
        top: 1rem;
    }
`;
const YoutubeBanner = styled.div<{ $hasErrors: boolean }>`
    border-radius: var(--r-l);
    background-color: ${({ $hasErrors }) => ($hasErrors ? 'var(--alert50)' : 'var(--primary50)')};
    position: relative;
    padding: 0.75rem;

    & > ${CloseButton} {
        right: 1rem;
        top: 0.75rem;
    }
`;
const CloseIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
`;

export default ImportBanner;
