import PropTypes from 'prop-types';
import BaseButton from '../BaseButton';

const IconButton = ({ size, icon, ...otherProps }) => (
    <BaseButton size={`${size}Icon`} startIcon={icon} {...otherProps} />
);

IconButton.propTypes = {
    variant: PropTypes.oneOf(['primary', 'secondary', 'ghost']),
    size: PropTypes.oneOf(['medium', 'small']),
    background: PropTypes.string,
    color: PropTypes.string,
    icon: PropTypes.element.isRequired,
    isRound: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    loadingIcon: PropTypes.element,
    'aria-label': PropTypes.string.isRequired,
};

IconButton.defaultProps = {
    size: 'medium',
};

export default IconButton;
