import { useContext } from 'react';
import { useResponsive } from '@/shared/hooks/useResponsive';
import Link from '../../atoms/Link';
import { connect } from '../../../legacy/connect';
import Tab from '../../atoms/Tab';
import { RouteContext } from '@ui/smarties/Tabs';

const enhance = connect(({ routerStore }) => ({
    activeTab: routerStore.params.tab,
    link: (route, tab) => routerStore.link(route, { tab }),
}));
function VerticalTab({ tab, activeTab, link, disabled, hideTab = false }) {
    const { route, titles } = useContext(RouteContext);
    const { isMobileOrTablet } = useResponsive();
    return (
        <Tab
            as={Link}
            to={link(route, tab)}
            active={tab === activeTab}
            disabled={disabled}
            size={isMobileOrTablet ? 'big' : 'normal'}
            responsive={isMobileOrTablet}
            display={disabled && hideTab ? 'none' : 'block'}
            weight={isMobileOrTablet ? 'semibold' : 'normal'}
            data-testid={`vertical-tab-${tab}-link`}
        >
            {titles[tab]}
        </Tab>
    );
}

export default enhance(VerticalTab);
