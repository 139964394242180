import Button from '@/components/Button';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';
import providerKeys from '@/queries/provider/providerKeys';
import useFetchSocialRedirectUrlMutation from '@/queries/socialNetworkPlatform/useFetchSocialRedirectUrlMutation.hook';
import useLoginOnMediaPlatformMutation from '@/queries/socialNetworkPlatform/useLoginOnMediaPlatformMutation.hook';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import { FACEBOOK, SOCIAL_NETWORK_NAME } from '@/shared/config/constants';
import { useBodyToastQueue } from '@/shared/hooks/useBodyToastQueue.hook';
import { useWindowPopupSocialMediaManager } from '@/shared/hooks/useWindowPopupSocialMediaManager';
import { isProviderEnabled } from '@/shared/services/providerService';
import { useQueryClient } from '@tanstack/react-query';
import { TooltipTrigger } from 'react-aria-components';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import styled from 'styled-components';

const LoginButton = () => {
    const { showId } = useParams<{ showId: string }>();
    const { data: user } = useUserQuery();
    const loginOnMediaPlatform = useLoginOnMediaPlatformMutation();
    const toast = useBodyToastQueue();
    const queryClient = useQueryClient();
    const fetchProviderRedirectUrl = useFetchSocialRedirectUrlMutation();
    // eslint-disable-next-line no-unused-vars
    const [_, openSocialNetworkOauthLogin] = useWindowPopupSocialMediaManager((e) => {
        if (e.data.provider !== FACEBOOK) return;
        loginOnMediaPlatform.mutate(
            { showId, platform: FACEBOOK, callbackData: e.data },
            {
                onSuccess: () => {
                    toast.success(
                        <FormattedMessage
                            defaultMessage="Votre compte {platform} est maintenant lié à Ausha. 🥳"
                            values={{
                                platform: SOCIAL_NETWORK_NAME[FACEBOOK],
                            }}
                        />,
                    );

                    queryClient.invalidateQueries({
                        queryKey: providerKeys.listByShowId(showId),
                    });
                },
                onError: () => {
                    toast.alert(
                        <FormattedMessage defaultMessage="Oups, une erreur est survenue. Veuillez réessayer" />,
                    );
                },
            },
        );
    });

    const handleLogin = () => {
        fetchProviderRedirectUrl.mutate(
            { platformId: FACEBOOK },
            {
                onSuccess: (data) => {
                    if (!data.loginPageUrl) return;
                    openSocialNetworkOauthLogin?.(data.loginPageUrl);
                },
            },
        );
    };

    if (!user) return null;

    const isDisabled = !isProviderEnabled(FACEBOOK, user);

    return (
        <TooltipTrigger delay={0} closeDelay={0} isDisabled={!isDisabled}>
            <TooltipTriggerWrapper>
                <FacebookButton
                    isDisabled={isDisabled}
                    isLoading={loginOnMediaPlatform.isLoading}
                    onPress={handleLogin}
                >
                    <FormattedMessage
                        defaultMessage="Se connecter à {provider}"
                        values={{
                            provider: SOCIAL_NETWORK_NAME[FACEBOOK],
                        }}
                    />
                </FacebookButton>
            </TooltipTriggerWrapper>
            <Tooltip placement="bottom">
                <FormattedMessage
                    defaultMessage="Oups, le partage via {providerName} est actuellement indisponible. Nos équipes font le nécessaire pour réparer cela au plus vite. 💪 Merci de réessayer plus tard. 💜"
                    values={{
                        providerName: SOCIAL_NETWORK_NAME[FACEBOOK],
                    }}
                />
            </Tooltip>
        </TooltipTrigger>
    );
};

const FacebookButton = styled(Button)`
    background-color: var(--facebook);

    &:hover {
        background-color: var(--facebook);
    }
`;

export default LoginButton;
