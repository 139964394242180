import { request } from '../api';

const basePath = process.env.API_CONTENT;

export const psoCheckerSuggestedKeywords = (episodeId) => {
    return request({
        method: 'GET',
        basePath,
        target: `/v1/podcasts/${episodeId}/ai_suggestions/keywords`,
    });
};

export const psoCheckerSuggestedTargetKeywords = (episodeId) => {
    return request({
        target: `/v1/podcasts/${episodeId}/ai_suggestions/target_keywords`,
        basePath,
        method: 'GET',
    });
};
