import { useIntl } from 'react-intl';
import { z } from 'zod';
import { FORM_VALIDATION_MESSAGE } from '@/shared/services/validationMessageService';

export const useEmailFormSchema = () => {
    const intl = useIntl();

    return z.object({
        email: z
            .string()
            .min(1, { message: intl.formatMessage(FORM_VALIDATION_MESSAGE.required) })
            .email({ message: intl.formatMessage(FORM_VALIDATION_MESSAGE.isEmail) }),
        password: z
            .string()
            .min(1, { message: intl.formatMessage(FORM_VALIDATION_MESSAGE.required) })
            .min(8, { message: intl.formatMessage(FORM_VALIDATION_MESSAGE.minLength, { min: 8 }) }),
    });
};

export type EmailFormSchema = z.infer<ReturnType<typeof useEmailFormSchema>>;
