import Button from '@/components/Button';
import IconButton from '@/components/IconButton';
import ExternalLink from '@/components/Link/ExternalLink';
import Modal from '@/components/ui/atoms/Modal';
import Text from '@/components/ui/atoms/Text';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import { sendAmplitudeLogEvent } from '@/shared/utils/amplitude';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Cookies from 'js-cookie';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import styled from 'styled-components';

interface ConnectToTargetspotModalProps {
    isOpen: boolean;
    onOpenChange: (open: boolean) => void;
}

const ConnectToTargetspotModal = ({ isOpen, onOpenChange }: ConnectToTargetspotModalProps) => {
    const { showId } = useParams<{ showId: string }>();
    const { data: user } = useUserQuery();
    const token = Cookies.get('token');

    const handleClose = () => onOpenChange(false);

    return (
        <ModalStyled isOpen={isOpen} onOpenChange={onOpenChange} size="medium">
            <Header>
                <Text variant="headingM" fontWeight="--fw-bold">
                    <FormattedMessage defaultMessage="Se connecter à Targetspot" />
                </Text>
                <IconButton
                    icon={<CloseIcon icon={icon({ name: 'xmark-large', style: 'solid' })} />}
                    onPress={handleClose}
                    variant="ghost"
                    aria-label="Close modal"
                    style={{ padding: 0 }}
                />
            </Header>
            <Text>
                <FormattedMessage defaultMessage="Pour ajouter votre émission à Targetspot, vous devez vous connecter à la plateforme, ou créer un compte si ce n’est pas encore fait." />
            </Text>
            <ActionWrapper>
                <TargetspotLink
                    variant="button-primary"
                    target="_blank"
                    href={`https://ausha.targetspot.com/signup?externalgroupid=${user?.id}&externalid=${showId}&token=${token}`}
                    onPress={() => sendAmplitudeLogEvent('Automatic Monetization Setup Started')}
                    startIcon={
                        <LinkIcon
                            icon={icon({ name: 'arrow-up-right-from-square', style: 'solid' })}
                        />
                    }
                >
                    <FormattedMessage defaultMessage="Aller sur Targetspot" />
                </TargetspotLink>
                <CancelButton variant="ghost" onPress={handleClose}>
                    <FormattedMessage defaultMessage="Annuler" />
                </CancelButton>
            </ActionWrapper>
        </ModalStyled>
    );
};

const ModalStyled = styled(Modal)`
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 2rem;
    overflow-y: auto;
`;
const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 1rem;
`;
const CloseIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--neutral500);
`;
const ActionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 1rem;

    ${({ theme }) => theme.mediaQueries.tabletAndUp} {
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        & > :first-child {
            order: 1;
        }
    }
`;
const CancelButton = styled(Button)`
    padding: 0;
    color: var(--neutral500);
`;
const TargetspotLink = styled(ExternalLink)`
    background-color: var(--whatsapp);

    &:hover {
        background-color: var(--whatsapp);
    }
`;
const LinkIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
`;

export default ConnectToTargetspotModal;
