import styled, { css } from 'styled-components';

const SidebarSection = styled.li<{ breaksAt?: string }>`
    display: flex;
    gap: 1rem;
    flex-shrink: 0;

    &:not(:last-child) {
        margin-bottom: 1rem;
    }

    ${({ breaksAt }) =>
        css`
            @media screen and (min-width: ${breaksAt || '75rem'}) {
                flex-direction: column;
            }
        `}
`;

export default SidebarSection;
