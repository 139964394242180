import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import SelectEpisode from './SelectEpisode';
import Modal from '@ui/molecules/Modal';
import Button from '@ui/atoms/Button';
import { observer } from 'mobx-react';
import useClipStore from '@/shared/hooks/useClipStore';
import Alert from '@ui/atoms/Alert';
import Text from '@ui/atoms/Text';
import IconWarning from '@ui/icons/IconWarning';
import Stack from '@ui/layout/Stack';

const SelectEpisodeForClipModal = ({ isOpen, onClose, onSubmit }) => {
    const { createClipForEpisode } = useClipStore();
    const [selectedEpisode, setSelectedEpisode] = useState(null);
    const hasAudioFile = !!selectedEpisode?.audioUrl;

    useEffect(() => {
        if (!isOpen) {
            setSelectedEpisode(null);
        }
    }, [isOpen]);

    const handleSubmit = () => {
        if (!selectedEpisode && !hasAudioFile) return;
        const clip = createClipForEpisode(selectedEpisode.id);
        onSubmit(selectedEpisode.id, clip);
        onClose();
    };

    const handleChange = (episode) => {
        setSelectedEpisode(episode);
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={<FormattedMessage defaultMessage="Nouveau clip" />}
            action={
                <>
                    <Button variant="secondary" onPress={onClose}>
                        <FormattedMessage defaultMessage="Annuler" />
                    </Button>
                    <Button isDisabled={!hasAudioFile} onPress={handleSubmit}>
                        <FormattedMessage defaultMessage="Continuer" />
                    </Button>
                </>
            }
            disableScroll
        >
            <Stack $gap="1rem">
                <SelectEpisode
                    label={
                        <FormattedMessage defaultMessage="Pour quel épisode souhaitez-vous créer un clip ?" />
                    }
                    onChange={handleChange}
                />
                {selectedEpisode && !hasAudioFile && (
                    <Alert
                        variant="warning"
                        icon={<IconWarning />}
                        description={
                            <Text as="span" color="--neutral500">
                                <FormattedMessage defaultMessage="Ajoutez un fichier audio à votre épisode pour pouvoir lui associer un clip vidéo" />
                            </Text>
                        }
                    >
                        <Text as="span" fontWeight="--fw-semibold">
                            <FormattedMessage defaultMessage="Un fichier audio est requis pour la création d'un clip vidéo" />
                        </Text>
                    </Alert>
                )}
            </Stack>
        </Modal>
    );
};

export default observer(SelectEpisodeForClipModal);
