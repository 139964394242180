import styled, { keyframes } from 'styled-components';

const CellLoading = () => <CellLoadingWrapper />;

const shimmer = keyframes`
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
`;

const CellLoadingWrapper = styled.div`
    width: 2.5rem;
    height: 0.5rem;
    background-color: var(--neutral100);
    border-radius: var(--r-full);

    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${shimmer};
    animation-timing-function: linear;

    background-color: var(--neutral50);
    background: linear-gradient(to right, #fafafa 8%, #f4f4f4 38%, #fafafa 54%);
    background-size: 1000px 640px;
`;

export default CellLoading;
