import { useMutation, useQueryClient } from '@tanstack/react-query';
import { psoSaveKeywords } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import psoKeys from './psoKeys';

export interface MutationFnProps {
    showId: string;
    keywords: string[];
    currentStore: string;
}

const transform = (data: unknown) => {
    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data as any, { deep: true });
};

const mutationFn = async ({ showId, keywords }: MutationFnProps) => {
    const { data } = await psoSaveKeywords(showId, { keywords });
    return transform(data);
};

const useSaveKeywordsMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: (_, { showId, currentStore }: { showId: string; currentStore: string }) => {
            queryClient.invalidateQueries(psoKeys.globalConfiguration(showId));
            queryClient.invalidateQueries(psoKeys.keywordQuota(showId));
            queryClient.invalidateQueries(psoKeys.statsOverview(showId, currentStore, false));
            queryClient.invalidateQueries(psoKeys.indexedKeywordsList(showId));
        },
    });
};

export default useSaveKeywordsMutation;
