import Button from '@/components/Button';
import Modal from '@/components/ui/atoms/Modal';
import Text from '@/components/ui/atoms/Text';
import Stack from '@/components/ui/layout/Stack';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AMAZON_MUSIC } from '@/utils/constants';
import ExternalLink from '@/components/Link/ExternalLink';
import useBroadcastMutation from '@/queries/show/useBroadcastMutation.hook';
import { AxiosError } from 'axios';
import { useModalToastQueue } from '@/utils/hooks/useModalToastQueue.hook';
import styled from 'styled-components';

interface AmazonCustomConfirmModalProps {
    isOpen: boolean;
    onOpenChange: (isOpen: boolean) => void;
}

const AmazonCustomConfirmModal = ({ isOpen, onOpenChange }: AmazonCustomConfirmModalProps) => {
    const { showId } = useParams<{ showId: string }>();
    const toast = useModalToastQueue();
    const broadcast = useBroadcastMutation();

    const platformKey = AMAZON_MUSIC;
    const platformName = 'Amazon Music';

    return (
        <ModalStyled isOpen={isOpen} onOpenChange={onOpenChange} size="small">
            <Header>
                <Title>
                    <FormattedMessage
                        defaultMessage="Souhaitez-vous diffuser sur {broadcastName} ?"
                        values={{ broadcastName: platformName }}
                    />
                </Title>
                <CloseButton
                    startIcon={<FontAwesomeIcon icon={icon({ name: 'xmark', style: 'solid' })} />}
                    onPress={() => onOpenChange(false)}
                    variant="ghost"
                    aria-label="Close modal"
                />
            </Header>

            <Stack $gap="0.25rem">
                <Text>
                    <FormattedMessage defaultMessage="Vous apparaîtrez sur la plateforme d’ici quelques heures." />
                </Text>
                <AmazonTermsAndConditionsLink
                    href="https://podcasters.amazon.com/terms-of-service"
                    target="_blank"
                    $color={`--${platformKey}`}
                >
                    <FormattedMessage defaultMessage="Consulter les conditions générales d'utilisation d'Amazon" />
                </AmazonTermsAndConditionsLink>
            </Stack>
            <Actions>
                <Button variant="link-secondary" onPress={() => onOpenChange(false)}>
                    <FormattedMessage defaultMessage="Annuler" />
                </Button>
                <BroadcastButton
                    onPress={() => {
                        broadcast.mutate(
                            {
                                showId,
                                platform: AMAZON_MUSIC,
                            },
                            {
                                onError: (error) => {
                                    const typedError = error as AxiosError;
                                    if (typedError.response?.status !== 400) {
                                        toast.alert();
                                        return;
                                    }

                                    const messages: Record<string, React.ReactNode> = {
                                        'Podcast does not fit requirements': (
                                            <FormattedMessage defaultMessage="Vous devez avoir un d'épisode publié" />
                                        ),
                                        'RSS feed already submitted to Amazon': (
                                            <FormattedMessage defaultMessage="Votre émission a déjà été soumis sur Amazon Music" />
                                        ),
                                    };
                                    const errorMessage: string = typedError.response.data.message;
                                    toast.alert(
                                        <FormattedMessage
                                            defaultMessage="{message}"
                                            values={{ message: messages[errorMessage] }}
                                        />,
                                    );
                                },
                                onSuccess: () => {
                                    onOpenChange(false);
                                },
                            },
                        );
                    }}
                    isLoading={broadcast.isLoading}
                    $color={`--${platformKey}`}
                >
                    <FormattedMessage
                        defaultMessage="Diffuser sur {broadcastName}"
                        values={{ broadcastName: platformName }}
                    />
                </BroadcastButton>
            </Actions>
        </ModalStyled>
    );
};

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;
const Title = styled.h1`
    font-size: var(--fs-body-l);
`;
const CloseButton = styled(Button)`
    transition-duration: 0.2s;
    padding: 0.5rem;
    color: var(--neutral500);
    &:hover {
        background-color: var(--neutral50);
        color: var(--neutral700);
    }
`;
const ModalStyled = styled(Modal)`
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1.5rem;
`;
const Actions = styled.div`
    align-self: stretch;
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 1rem;

    & > :last-child {
        align-self: stretch;
    }
`;
const BroadcastButton = styled(Button)<{ $color: string }>`
    background-color: var(${(props) => props.$color}50);
    color: var(${(props) => props.$color});
    box-shadow: none;

    &:hover {
        background-color: var(${(props) => props.$color}100);
    }
`;
const AmazonTermsAndConditionsLink = styled(ExternalLink)<{ $color: string }>`
    color: var(${(props) => props.$color});

    &:hover {
        color: var(${(props) => props.$color});
    }
`;

export default AmazonCustomConfirmModal;
