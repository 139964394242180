import { FORM_VALIDATION_MESSAGE } from '@/shared/services/validationMessageService';
import { useIntl } from 'react-intl';
import { z } from 'zod';

const useAISettingsFormSchema = () => {
    const intl = useIntl();
    const MAX_LENGTH = 200;

    return z.object({
        title: z.string().max(
            MAX_LENGTH,
            intl.formatMessage(FORM_VALIDATION_MESSAGE.maxLength, {
                max: MAX_LENGTH,
            }),
        ),
        description: z.string().max(
            MAX_LENGTH,
            intl.formatMessage(FORM_VALIDATION_MESSAGE.maxLength, {
                max: MAX_LENGTH,
            }),
        ),
        tags: z.string().max(
            MAX_LENGTH,
            intl.formatMessage(FORM_VALIDATION_MESSAGE.maxLength, {
                max: MAX_LENGTH,
            }),
        ),
        newsletter: z.string().max(
            MAX_LENGTH,
            intl.formatMessage(FORM_VALIDATION_MESSAGE.maxLength, {
                max: MAX_LENGTH,
            }),
        ),
        smm: z.string().max(
            MAX_LENGTH,
            intl.formatMessage(FORM_VALIDATION_MESSAGE.maxLength, {
                max: MAX_LENGTH,
            }),
        ),
    });
};

export type AISettingsSchema = z.infer<ReturnType<typeof useAISettingsFormSchema>>;

export default useAISettingsFormSchema;
