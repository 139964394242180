import { useState } from 'react';
import Button from '@ui/atoms/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FormattedMessage, useIntl } from 'react-intl';
import EpisodePublishModal from '@app/molecules/EpisodePublishModal';
import useInternalRouteMapping from '@hooks/useInternalRouteMapping.hook';
import { useFormContext } from 'react-hook-form';
import { useEpisodeBeingEdited } from '@app/organisms/EpisodeEditForm/EpisodeEditFormContext';
import getPublishSchema from '@app/organisms/EpisodeEditForm/schemas/publishSchema';
import { getDirtyValues } from '@/helpers';
import useSaveEpisodeFormMutation from '@app/organisms/EpisodeEditForm/helpers/useSaveEpisodeFormMutation';
import useSubscriptionQuery from '@queries/subscription/useSubscriptionQuery.hook';
import useUserQuery from '@queries/user/useUserQuery.hook';
import EpisodeEditFormActionsTooltipMessage from '@app/organisms/EpisodeEditForm/actions/EpisodeEditFormActionsTooltipMessage';
import { EPISODE_STATUS, USER_ROLES } from '@/utils/constants';
import userKeys from '@queries/user/userKeys';
import { useQueryClient } from '@tanstack/react-query';
import { useEpisodeEditAudioFileMutation } from '@/context/EpisodeEditContext';
import ExperimentalTooltip from '@ui/atoms/ExperimentalTooltip';
import { getChaptersIdsRequiringImageDeletion } from '@app/organisms/EpisodeEditForm/helpers/getChaptersIdsRequiringImageDeletion';
import { useParams } from 'react-router';
import showKeys from '@/queries/show/showKeys';

const PublishButton = ({ className }) => {
    const intl = useIntl();
    const { push } = useInternalRouteMapping();
    const queryClient = useQueryClient();
    const {
        watch,
        reset,
        formState: { dirtyFields },
    } = useFormContext();
    const saveEpisodeFormMutation = useSaveEpisodeFormMutation();
    const episodeEditAudioFileMutation = useEpisodeEditAudioFileMutation();
    const { showId } = useParams();
    const { data: subscription } = useSubscriptionQuery();
    const { data: user } = useUserQuery();

    const [isModalOpened, setModalOpened] = useState(false);

    const episodeBeingEdited = useEpisodeBeingEdited();
    const isScheduled = episodeBeingEdited?.state === EPISODE_STATUS.SCHEDULED;
    const schema = getPublishSchema(intl);
    const formData = watch();
    const isSchemaValid = schema.isValidSync(formData);
    const hasAudioFile =
        episodeBeingEdited.files.length > 0 && !episodeEditAudioFileMutation.isLoading;
    const isFreemiumQuotaReached =
        subscription.isFreemium &&
        episodeBeingEdited.show?.userRole === USER_ROLES.OWNER &&
        user.trialEpisodes >= user.maximumTrialEpisodes;
    const isValid = isSchemaValid && hasAudioFile && !isFreemiumQuotaReached;

    const onPress = () => {
        setModalOpened(true);
    };

    const handlePublishEpisode = async () => {
        const dirtyValues = getDirtyValues(dirtyFields, formData);
        const chaptersIdsRequiringImageDeletion = getChaptersIdsRequiringImageDeletion(
            dirtyFields,
            formData.chapters,
        );

        const { content, options, privacy } = dirtyValues;
        const episodeData = { ...content, ...options, ...privacy, state: EPISODE_STATUS.ONLINE };

        try {
            await saveEpisodeFormMutation.mutateAsync(
                {
                    episodeId: episodeBeingEdited.id,
                    episode: episodeData,
                    chapters: dirtyFields.chapters ? formData.chapters : null,
                    chaptersIdsRequiringImageDeletion,
                },
                {
                    onSuccess: () => {
                        reset();
                        if (subscription.isFreemium) {
                            queryClient.invalidateQueries({ queryKey: userKeys.detail() });
                        }
                        queryClient.invalidateQueries({ queryKey: showKeys.detailById(showId) });
                        push('experimental.episodes.recap');
                    },
                },
            );
        } catch (error) {
            // Handle error here ?
        }
    };

    return (
        <>
            <ExperimentalTooltip
                isDisabled={isValid}
                content={
                    <EpisodeEditFormActionsTooltipMessage
                        isFreemiumQuotaReached={isFreemiumQuotaReached}
                        hasAudioFile={hasAudioFile}
                        isSchemaValid={isSchemaValid}
                    />
                }
            >
                <Button
                    onPress={onPress}
                    isDisabled={!isValid}
                    startIcon={
                        isScheduled ? null : (
                            <FontAwesomeIcon
                                icon={icon({ name: 'rocket-launch', style: 'solid' })}
                            />
                        )
                    }
                    className={className}
                    data-testid="episode-form-publish-now-button"
                >
                    <FormattedMessage defaultMessage="Publier maintenant" />
                </Button>
            </ExperimentalTooltip>
            <EpisodePublishModal
                isOpen={isModalOpened}
                onOpenChange={setModalOpened}
                handlePublishEpisode={handlePublishEpisode}
                isLoading={saveEpisodeFormMutation.isLoading}
            />
        </>
    );
};

export default PublishButton;
