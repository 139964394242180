import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const centerTextCss = css`
    text-align: center;
`;

const guttersCss = css`
    padding-left: ${(props) => props.gutters};
    padding-right: ${(props) => props.gutters};
`;

const intrinsicCss = css`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Center = styled.div`
    // Gutters needs to grow out from the 60ch content threshold
    box-sizing: content-box;
    margin-left: auto;
    margin-right: auto;
    max-width: ${(props) => props.max};
    ${(props) => props.centerText && centerTextCss}
    ${(props) => props.gutters && guttersCss}
    ${(props) => props.intrinsic && intrinsicCss}
`;

Center.propTypes = {
    max: PropTypes.string, // A CSS max-width value
    centerText: PropTypes.bool, // Center align the text too
    gutters: PropTypes.string, // The minimum space on either side of the content
    intrinsic: PropTypes.bool, // Center child elements based on their content width
};

Center.defaultProps = {
    max: 'none',
    centerText: false,
    gutters: null,
    intrinsic: false,
};

export default Center;
