import Cluster from '@/components/ui/layout/Cluster';
import Stack from '@/components/ui/layout/Stack';
import styled, { css, keyframes } from 'styled-components';

const NewsletterFormSkeleton = () => {
    return (
        <SkeletonForm>
            {/* Section 1: Newsletter Subscription */}
            <SkeletonSection>
                <TitleSkeleton />
                <Stack $gap="0.5rem">
                    <SkeletonText width="10rem" />
                    <Cluster $gap="0.5rem" $align="center">
                        <ToggleSkeleton />
                        <SkeletonText width="15rem" />
                    </Cluster>
                </Stack>
                <Stack $gap="0.5rem">
                    <SkeletonText width="15rem" />
                    <Stack $gap="0.5rem">
                        <InputSkeleton />
                        <ActionsSkeleton>
                            <SkeletonButton width="6rem" />
                            <SkeletonButton width="5rem" />
                        </ActionsSkeleton>
                    </Stack>
                </Stack>
                <QuotaSkeleton />
            </SkeletonSection>

            {/* Section 2: Custom Buttons */}
            <SkeletonSection>
                <TitleSkeleton />
                <Stack $gap="0.5rem">
                    <SkeletonText width="12rem" />
                    <Stack $gap="0.25rem">
                        <SkeletonText width="30rem" />
                        <SkeletonText width="15rem" />
                    </Stack>
                </Stack>
                <Stack $gap="0.5rem">
                    <SkeletonCTA />
                    <SkeletonCTA />
                </Stack>
            </SkeletonSection>

            <SaveButtonSkeleton />
        </SkeletonForm>
    );
};

const shimmer = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`;

const shimmering = css`
    background: #f6f7f8;
    background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
    background-repeat: no-repeat;
    background-size: 800px 104px;
    display: inline-block;
    position: relative;
    animation-duration: 1.2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${shimmer};
    animation-timing-function: linear;
`;
const SkeletonForm = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;
const SkeletonSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;
const TitleSkeleton = styled.div`
    width: 10rem;
    height: 1.5rem;
    border-radius: 4px;
    ${shimmering};
`;
const ToggleSkeleton = styled.div`
    width: 3rem;
    height: 1.5rem;
    border-radius: 20px;
    ${shimmering};
`;
const SkeletonText = styled.div<{ width: string }>`
    width: 100%;
    max-width: ${({ width }) => width};
    height: 1rem;
    border-radius: 4px;
    ${shimmering};
`;
const InputSkeleton = styled.div`
    width: 100%;
    height: 2.5rem;
    border-radius: 0.5rem;
    ${shimmering};
`;
const ActionsSkeleton = styled.div`
    display: flex;
    justify-content: end;
    gap: 1rem;
`;
const SkeletonButton = styled.div<{ width: string }>`
    width: 100%;
    max-width: ${({ width }) => width};
    height: 2rem;
    border-radius: 0.5rem;
    ${shimmering};
`;
const QuotaSkeleton = styled.div`
    width: 100%;
    height: 11rem;
    border-radius: 0.75rem;
    ${shimmering};
`;
const SaveButtonSkeleton = styled.div`
    width: 8rem;
    height: 2.5rem;
    border-radius: 0.5rem;
    align-self: center;
    ${shimmering};
`;
const SkeletonCTA = styled.div`
    width: 100%;
    height: 4.25rem;
    border-radius: 0.5rem;
    ${shimmering};
`;

export default NewsletterFormSkeleton;
