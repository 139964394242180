import { connect } from '@app/decorators/connect';
import { FormattedMessage, injectIntl } from 'react-intl';
import RingChartWithLegend from '../../ui/organisms/RingChartWithLegend';
import { compose } from '../../../helpers';
import StatsDataSetSelector from './StatsDataSetSelector';
import FetcherSourcePlatformsLeaderboard from '../fetchers/stats/FetcherSourcePlatformsLeaderboard';
import { useStatsDateSelectorContext } from '@/context/StatsDateSelectorContext';

const enhance = compose(
    injectIntl,
    connect(({ sourcesStatsStore, routerStore }) => ({
        platforms: sourcesStatsStore.platformsTop,
        showId: routerStore.params.showId,
        itemType: routerStore.params.itemType,
        itemId: routerStore.params.itemId,
        episodeQuery: routerStore.query.episode,
    })),
);

function SourcesPlatformsGraph({ intl, platforms, showId, itemType, itemId, episodeQuery }) {
    const { startDate, endDate } = useStatsDateSelectorContext();

    return (
        <>
            <StatsDataSetSelector
                tabTitle={<FormattedMessage defaultMessage="Plateformes" />}
                tabDescription={
                    <FormattedMessage defaultMessage="Voyons sur quelles plateformes vous avez le plus de succès." />
                }
                tabId="sources"
                noSelector
            />
            <FetcherSourcePlatformsLeaderboard
                showId={showId}
                itemType={itemType}
                itemId={itemId}
                start={startDate}
                end={endDate}
                episodes={episodeQuery}
            >
                {(isLoading) => (
                    <RingChartWithLegend
                        title={
                            <FormattedMessage defaultMessage="Parts d'écoute selon les plateformes" />
                        }
                        isLoading={isLoading}
                        source={platforms.map((platform) => ({
                            label:
                                platform.category === 'other'
                                    ? intl.formatMessage({ defaultMessage: 'Autres' })
                                    : platform.category,
                            key: platform.category,
                            name: platform.category,
                            value: platform.percent,
                            percent: platform.percent,
                        }))}
                        py={50}
                        px={30}
                        mt={25}
                    />
                )}
            </FetcherSourcePlatformsLeaderboard>
        </>
    );
}

export default enhance(SourcesPlatformsGraph);
