import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { useResponsive } from '@/shared/hooks/useResponsive';
import Stack from '@ui/layout/Stack';
import RadioTabGroup from '@ui/atoms/RadioTab/RadioTabGroup';
import RadioTab from '@ui/atoms/RadioTab/RadioTab';
import GreenArrowIcon from '@public/icons/green-arrow.svg';
import Text from '@ui/atoms/Text';
import Cluster from '@ui/layout/Cluster';
import { PRICING_CURRENCY } from '@/shared/config/pricing';
import PricingContext from '../../PricingContext';
import styled from 'styled-components';

const MONTHLY = 'monthly';
const YEARLY = 'yearly';

const Periodicity = () => {
    const { isDesktop } = useResponsive();
    const { isYearlyPeriodicitySelected, setYearlyPeriodicitySelected } =
        useContext(PricingContext);

    return (
        // @ts-ignore
        <Stack $align="center" $gap={isDesktop ? null : '0.5rem'}>
            <FormattedMessage defaultMessage="Périodicité">
                {(label) => (
                    <RadioTabGroup
                        name="periodicity"
                        aria-label={label.join('')}
                        value={isYearlyPeriodicitySelected ? YEARLY : MONTHLY}
                        onChange={(selectedPeriodicity) =>
                            setYearlyPeriodicitySelected(selectedPeriodicity === YEARLY)
                        }
                    >
                        <RadioTab value={MONTHLY}>
                            <FormattedMessage defaultMessage="Mensuel" />
                        </RadioTab>
                        <RadioTab value={YEARLY}>
                            <FormattedMessage defaultMessage="Annuel" />
                        </RadioTab>
                        {isDesktop && (
                            <GreenArrowIcon
                                css={`
                                    height: 1rem;
                                    width: 1.125rem;
                                    position: absolute;
                                    left: calc(100% + 0.25rem);
                                    bottom: 0;
                                `}
                            />
                        )}
                    </RadioTabGroup>
                )}
            </FormattedMessage>
            <CheaperInYearly color="--success" fontWeight="--fw-semibold">
                <FormattedMessage defaultMessage="2 mois offerts en annuel" />
            </CheaperInYearly>
        </Stack>
    );
};

const Currency = () => {
    const { selectedCurrency, setSelectedCurrency } = useContext(PricingContext);

    return (
        <FormattedMessage defaultMessage="Devise">
            {(label) => (
                <RadioTabGroup
                    name="currency"
                    aria-label={label.join('')}
                    value={selectedCurrency}
                    onChange={setSelectedCurrency}
                >
                    <RadioTab value={PRICING_CURRENCY.EUR}>€</RadioTab>
                    <RadioTab value={PRICING_CURRENCY.USD}>$</RadioTab>
                    <RadioTab value={PRICING_CURRENCY.GBP}>£</RadioTab>
                </RadioTabGroup>
            )}
        </FormattedMessage>
    );
};

export interface PricingControlsProps {
    displayCurrency: boolean;
}

const PricingControls = ({ displayCurrency }: PricingControlsProps) => {
    const { isDesktop } = useResponsive();

    return (
        <Cluster $justify={displayCurrency ? 'space-between' : 'center'} $gap="1rem">
            {/* Placeholder used to center Periodicity. Should match Currency size. */}
            {displayCurrency && isDesktop && <Placeholder />}
            <Periodicity />
            {displayCurrency && <Currency />}
        </Cluster>
    );
};

const Placeholder = styled.div`
    width: 6.375rem;
`;
const CheaperInYearly = styled(Text)`
    left: calc(100% + 0.75rem);
    line-height: 1.5em;
    align-self: flex-start;

    @media (min-width: 768px) {
        position: relative;
    }
`;

export default PricingControls;
