import { useState } from 'react';
import useNewsletterQuotaQuery from '@queries/quota/useNewsletterQuotaQuery.hook';
import Envelope from '@public/images/envelope.png';
import CircularProgress from '@ui/atoms/CircularProgress';
import Text from '@ui/atoms/Text';
import Button from '@ui/atoms/Button';
import QuotasModal from '@app/molecules/QuotasModal';
import TooltipHover from '@ui/smarties/TooltipHover';
import HelpIcon from '@public/icons/help.svg';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import styled, { css } from 'styled-components';
import { PropTypes } from 'mobx-react';
import { useParams } from 'react-router-dom';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import { QUOTA_TYPES, USER_ROLES } from '@/utils/constants';
import UiKitTooltip from '@/components/ui/atoms/UiKitTooltip';
import Stack from '@/components/ui/layout/Stack';
import useSubscriptionQuery from '@/queries/subscription/useSubscriptionQuery.hook';

const Quota = ({ userIsEnterprise }) => {
    const [quotaModalShown, setQuotaModalShown] = useState(false);
    const { data, error, isLoading } = useNewsletterQuotaQuery();
    const { showId } = useParams();
    const { data: show } = useShowQuery(showId);
    const isOwner = show?.userRole === USER_ROLES.OWNER;
    const { data: subscription } = useSubscriptionQuery();
    const isFreemium = subscription?.isFreemium;

    if (error) return <span>Error</span>;
    if (isLoading) return <span>Loading...</span>;

    const quotaPercentage = (data.current / data.maximum) * 100;
    const percentageColorBrackets = [
        { nb: 65, progressColor: '--success', backgroundColor: '--success50' },
        { nb: 85, progressColor: '--warning', backgroundColor: '--warning50' },
        { nb: 100, progressColor: '--alert', backgroundColor: '--alert50' },
    ];

    const percentageBracket =
        percentageColorBrackets.find((p) => p.nb >= quotaPercentage) ||
        percentageColorBrackets[percentageColorBrackets.length - 1];

    const handleOpenQuotaModal = () => {
        if (!isOwner) return;
        setQuotaModalShown(true);
    };
    return (
        <>
            <QuotaWrapper>
                <QuotaDataWrapper>
                    <CircularProgress
                        backgroundColor={percentageBracket.backgroundColor}
                        progressColor={percentageBracket.progressColor}
                        size={125}
                        strokeWidth={18}
                        percentage={quotaPercentage}
                    >
                        <QuotaProgressNumber>
                            <Remaining variant="heading" fontWeight="--fw-bold">
                                <FormattedNumber value={data.current} />
                            </Remaining>
                            <Total variant="footnote" color="--neutral500">
                                /<FormattedNumber value={data.maximum} />
                            </Total>
                        </QuotaProgressNumber>
                    </CircularProgress>
                    <QuotaTextWrapper>
                        <Stack $gap="0.25rem">
                            <Title color="--neutral500">
                                <FormattedMessage defaultMessage="Quota d'e-mails mensuels" />
                                <TooltipHover arrow="bottom" target={<QuotaHelpIcon />}>
                                    <Text color="--black">
                                        <FormattedMessage defaultMessage="Quota de messages envoyés entre toutes vos émissions, réinitialisé chaque 1er du mois 💌" />
                                    </Text>
                                </TooltipHover>
                            </Title>
                            <RemainingEmails variant="heading" fontWeight="--fw-bold">
                                <FormattedNumber value={data.remaining} />{' '}
                                <FormattedMessage defaultMessage="emails restants" />
                            </RemainingEmails>
                        </Stack>
                        {!userIsEnterprise && (
                            <UiKitTooltip
                                passthrough={isOwner}
                                variant="dark"
                                position="top"
                                content={
                                    <FormattedMessage defaultMessage="Seuls les propriétaires de l’émission peuvent modifier le quota d'e-mails. 💌" />
                                }
                            >
                                <UpdateQuotaIncentive
                                    variant="ghost"
                                    isOwner={isOwner}
                                    onPress={handleOpenQuotaModal}
                                    isDisabled={isFreemium}
                                >
                                    <FormattedMessage defaultMessage="Modifier mon quota d'e-mails" />
                                </UpdateQuotaIncentive>
                            </UiKitTooltip>
                        )}
                    </QuotaTextWrapper>
                </QuotaDataWrapper>
                <ImageEnvelope src={Envelope} />
            </QuotaWrapper>
            <QuotasModal
                type={QUOTA_TYPES.NEWSLETTER}
                openedQuotasModal={quotaModalShown}
                onCloseQuotasModal={() => setQuotaModalShown(!quotaModalShown)}
            />
        </>
    );
};

Quota.propTypes = {
    userIsEnterprise: PropTypes.bool,
};

const QuotaWrapper = styled.div`
    width: 100%;
    border: 1px solid var(--neutral100);
    border-radius: var(--r-l);
    padding: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const QuotaDataWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    align-items: center;
    gap: 2rem;
    var(--mobile) {
        flex-direction: column;
    }
`;

const QuotaTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 0.5rem;
`;

const ImageEnvelope = styled.img`
    display: none;
    width: 5rem;
    height: auto;
    transform: rotate(-15deg);

    @media screen and (min-width: 1100px) {
        display: block;
    }
`;

const Title = styled(Text)`
    display: flex;
    align-items: center;
    column-gap: 0.25rem;
`;
const RemainingEmails = styled(Text)``;
const UpdateQuotaIncentive = styled(Button)`
    padding: 0;
    color: var(--primary);
    ${({ isOwner }) =>
        !isOwner &&
        css`
            pointer-events: none;
            color: var(--neutral500);
        `}
`;

const QuotaProgressNumber = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > span {
        display: inline-block;
    }
`;
const Remaining = styled(Text)``;
const Total = styled(Text)``;
const QuotaHelpIcon = styled(HelpIcon)`
    width: 1rem;
    height: auto;
    fill: var(--neutral500);
`;

export default Quota;
