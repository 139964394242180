import { useParams } from 'react-router';
import { FormattedMessage } from 'react-intl';
import add from '@public/icons/add.svg';
import { useResponsive } from '@/shared/hooks/useResponsive';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import DeprecatedButton from '@ui/atoms/DeprecatedButton';
import DeprecatedIcon from '@ui/atoms/DeprecatedIcon';
import Spinner from '@ui/atoms/Spinner';
import SeasonLine from '@/components/unorganized/SeasonLine';
import PaperGrid from '@ui/atoms/PaperGrid';
import Column from '@ui/atoms/Column';
import DeprecatedWhitePaper from '@ui/atoms/DeprecatedWhitePaper';
import { useAmplitudeLogEvent } from '@/shared/hooks/useAmplitude';
import useSubscriptionQuery from '@queries/subscription/useSubscriptionQuery.hook';
import useSeasonsQuery from '@queries/season/useSeasonsQuery.hook';
import useAddSeasonMutation from '@queries/season/useAddSeasonMutation.hook';
import { useQueryClient } from '@tanstack/react-query';
import seasonKeys from '@queries/season/seasonKeys';
import useDeleteSeasonMutation from '@queries/season/useDeleteSeasonMutation.hook';

const Seasons = () => {
    useAmplitudeLogEvent('Seasons Settings Viewed');

    const queryClient = useQueryClient();
    const { showId }: { showId: string } = useParams();
    const seasons = useSeasonsQuery({ showId });
    const addSeason = useAddSeasonMutation();
    const deleteSeason = useDeleteSeasonMutation();

    const { isMobileOrTablet, isDesktop } = useResponsive();
    const { data: subscription } = useSubscriptionQuery();

    const handleAddSeason = () => {
        addSeason.mutate(
            { showId },
            {
                onSuccess: async () => {
                    queryClient.invalidateQueries({
                        queryKey: seasonKeys.detailById(showId),
                    });
                    await seasons.refetch();
                },
            },
        );
    };

    const handleDelete = (seasonId: string) => {
        deleteSeason.mutate(
            { seasonId },
            {
                onSuccess: async () => {
                    queryClient.invalidateQueries({
                        queryKey: seasonKeys.detailById(showId),
                    });
                    await seasons.refetch();
                },
            },
        );
    };

    if (seasons.isLoading || seasons.isRefetching || seasons.isError) {
        return null;
    }

    return (
        <>
            <PaperGrid justify="space-between" align="flex-end" mb={20} flex={isMobileOrTablet}>
                <Column
                    flex
                    column
                    align="left"
                    w="100%"
                    startColumn={isDesktop && 1}
                    endColumn={isDesktop && 3}
                >
                    <DeprecatedText size="veryBig" weight="bold" mb={5}>
                        <FormattedMessage defaultMessage="Saisons" />
                    </DeprecatedText>
                    <DeprecatedText color="--neutral500">
                        <FormattedMessage defaultMessage="Classez vos épisodes dans des saisons pour une meilleure organisation." />
                    </DeprecatedText>
                </Column>
                <Column
                    startColumn={isDesktop && 3}
                    endColumn={isDesktop && 4}
                    justify={isDesktop && 'flex-end'}
                >
                    <DeprecatedButton
                        onClick={handleAddSeason}
                        disabled={addSeason.isLoading}
                        floating={isMobileOrTablet}
                        bottomOffset={subscription?.isFreemium}
                        icon={isMobileOrTablet}
                        rounded={isMobileOrTablet}
                    >
                        {addSeason.isLoading ? <Spinner /> : <DeprecatedIcon as={add} />}
                        {isDesktop && <FormattedMessage defaultMessage="Ajouter une saison" />}
                    </DeprecatedButton>
                </Column>
            </PaperGrid>
            <DeprecatedWhitePaper>
                <PaperGrid column={4} py={10} px={20}>
                    <Column startColumn={1} endColumn={3} color="--neutral500">
                        <FormattedMessage defaultMessage="Saison" />
                    </Column>
                    <Column startColumn={3} endColumn={5} color="--neutral500">
                        <FormattedMessage defaultMessage="Nombre d’épisodes" />
                    </Column>
                </PaperGrid>
                {seasons.data.map((season, index) => (
                    <SeasonLine
                        key={season.id}
                        season={season}
                        canDelete={index === 0}
                        onDelete={(id: string) => handleDelete(id)}
                    />
                ))}
            </DeprecatedWhitePaper>
        </>
    );
};

export default Seasons;
