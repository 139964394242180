import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { reflex } from '@ui/reflex';
import Link from '../Link';
import { resolveColor } from '@/utils/cssVariables';

const pointerCSS = css`
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`;

const disabledCSS = css`
    pointer-events: none;
    cursor: initial;
    color: ${(props) => resolveColor(`${props.color}50`)};
    &:hover {
        text-decoration: none;
    }
    & > a {
        pointer-events: auto;
    }
`;

const ellipsisCSS = css`
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const labelCSS = css`
    input {
        margin-right: 10px;
    }
`;

const sizeLookup = {
    giant: 'var(--fs-large-title)',
    veryBig: 'var(--fs-title)',
    subTitle: 'var(--fs-subtitle)',
    upperBig: 'var(--fs-heading)',
    big: 'var(--fs-subheading)',
    normal: 'var(--fs-body)',
    small: 'var(--fs-body)',
    verySmall: 'var(--fs-footnote)',
};

const weightLookup = {
    normal: 'var(--fw-normal)',
    semibold: 'var(--fw-semibold)',
    bold: 'var(--fw-bold)',
    extraBold: 800,
};

/**
 * DEPRECATED
 *
 * Please use `ui/atoms/Text` component instead.
 */
export const DeprecatedText = styled.p.withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) =>
        !['color', 'display'].includes(prop) && defaultValidatorFn(prop),
})`
    ${(p) => p.nowrap && 'white-space: nowrap'};
    ${(p) => p.ellipsis && ellipsisCSS};
    border: 0;
    background: ${(p) => (p.background ? p.background : 'transparent')};
    font-weight: ${(p) => weightLookup[p.weight]};
    font-style: ${(p) => p.fontStyle};
    font-size: ${(p) => sizeLookup[p.size] || `${p.size}px`};
    color: ${(props) => resolveColor(props.color)};
    text-decoration: ${(p) => (p.underline ? 'underline' : 'none')};
    text-align: ${(p) => p.align};
    display: ${(p) => (p.flex ? 'flex' : undefined)};
    ${(p) => (p.onClick || ['a', Link, 'button'].includes(p.as)) && pointerCSS};
    ${(p) => p.disabled && disabledCSS};
    ${(p) => p.as === 'label' && labelCSS};
    ${(p) => p.strike && 'text-decoration: line-through'};
    &:first-letter {
        ${(p) => p.firstLetterCapitalize && 'text-transform: uppercase'}
    }
    &:hover {
        color: ${(p) => p.colorHover && resolveColor(p.colorHover)};
    }
`;

DeprecatedText.propTypes = {
    align: PropTypes.string,
    size: PropTypes.oneOf([
        'giant',
        'veryBig',
        'littleBig',
        'big',
        'normal',
        'small',
        'verySmall',
        'tiny',
    ]),
    weight: PropTypes.oneOf(['bold', 'semibold', 'normal']),
    nowrap: PropTypes.bool,
    strike: PropTypes.bool,
    ellipsis: PropTypes.bool,
    color: PropTypes.string,
};

DeprecatedText.defaultProps = {
    align: 'left',
    size: 'normal',
    weight: 'normal',
    nowrap: undefined,
    strike: undefined,
    ellipsis: undefined,
    color: 'black',
};

/**
 * @component
 */
export default reflex(DeprecatedText);
