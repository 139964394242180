import Text from '@ui/atoms/Text';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import CreateNewShowForm from '@/features/onboarding/components/Onboarding/CreateNewShow/CreateNewShowForm';

const CreateNewShow = () => (
    <Wrapper>
        <SubHeader>
            <Text variant="headingL" fontWeight="--fw-bold">
                <FormattedMessage defaultMessage="Lançons-nous dans la création de votre podcast !" />
            </Text>
            <Text color="--neutral500">
                <FormattedMessage defaultMessage="Pas de panique, vous pourrez toujours modifier ces informations plus tard." />
            </Text>
        </SubHeader>
        <CreateNewShowForm />
    </Wrapper>
);

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
`;
const SubHeader = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;

export default CreateNewShow;
