import { useState } from 'react';
import { useHistory } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import Pagination from '@ui/molecules/Pagination';
import FetcherCampaigns from '@/components/unorganized/FetcherCampaigns';
import ShortListFallback from '@/components/unorganized/ShortListFallback';
import CampaignListHeader from '@/components/unorganized/CampaignListHeader';
import CampaignLines from '../CampaignLines';
import DeprecatedWhitePaper from '@ui/atoms/DeprecatedWhitePaper';
import { useParams } from 'react-router-dom';
import useCampaignStore from '@/shared/hooks/useCampaignStore';
import MonetizeHeader from '@/components/unorganized/MonetizeHeader';
import { useDebounce } from '@/shared/hooks/useDebounce';
import { observer } from 'mobx-react';
import Button from '@ui/atoms/Button';
import Text from '@ui/atoms/Text';
import noCampaignMainIllustration from '@public/images/empty-state/illustration/monetization.mp4';
import noCampaignHeadIllustration from '@public/images/empty-state/monetization.svg?url';
import EmptyState from '@ui/molecules/EmptyState';
import IconAdd from '@ui/icons/IconAdd';
import styled from 'styled-components';

function CampaignsList() {
    const history = useHistory();
    const intl = useIntl();
    const { showId } = useParams();
    const { campaigns, pagination, addCampaign, createNewCampaign } = useCampaignStore();
    const [search, onSearchChange] = useState('');
    const debouncedSearch = useDebounce(search, 500);
    const noCampaign = campaigns.length === 0;

    return (
        <FetcherCampaigns page={pagination.currentPage} search={debouncedSearch} showId={showId}>
            {(loading) => (
                <>
                    {loading ? (
                        <DeprecatedPaper w="80%" px={30}>
                            <ShortListFallback />
                        </DeprecatedPaper>
                    ) : (
                        <>
                            {!(noCampaign && search === '') && (
                                <MonetizeHeader
                                    search={debouncedSearch}
                                    onSearchChange={onSearchChange}
                                />
                            )}
                            {noCampaign && search === '' ? (
                                <EmptyStateOuterWrapper>
                                    <Text fontWeight="--fw-bold" variant="title">
                                        <FormattedMessage defaultMessage="Monétisation manuelle" />
                                    </Text>
                                    <EmptyState
                                        illustration={noCampaignMainIllustration}
                                        illustrationType="video"
                                        illustrationAlign="right"
                                        illustrationBackground="--gradient-sunny"
                                    >
                                        <HeadIllustrationWrapper>
                                            <HeadIllustration src={noCampaignHeadIllustration} />
                                        </HeadIllustrationWrapper>
                                        <LabelWrapper>
                                            <Text variant="heading" fontWeight="--fw-bold">
                                                <FormattedMessage defaultMessage="Créez votre première campagne" />
                                            </Text>
                                            <Text
                                                variant="body"
                                                fontWeight="--fw-normal"
                                                color="--neutral500"
                                            >
                                                <FormattedMessage defaultMessage="Gérez vos annonces publicitaires sur les épisodes de votre podcast 💰" />
                                            </Text>
                                        </LabelWrapper>
                                        <Button
                                            startIcon={<IconAdd />}
                                            onClick={async () => {
                                                const campaign = await addCampaign(
                                                    showId,
                                                    createNewCampaign(showId, intl),
                                                );
                                                history.push(
                                                    `/app/show/${showId}/monetize/manual/${campaign.id}`,
                                                );
                                            }}
                                        >
                                            <FormattedMessage defaultMessage="Créer une campagne" />
                                        </Button>
                                    </EmptyState>
                                </EmptyStateOuterWrapper>
                            ) : (
                                <>
                                    <DeprecatedWhitePaper overflowHide={false} position="relative">
                                        <CampaignListHeader />
                                        <CampaignLines search={debouncedSearch} />
                                    </DeprecatedWhitePaper>
                                    <Pagination
                                        my={20}
                                        pagination={pagination}
                                        singular={<FormattedMessage defaultMessage="campagne" />}
                                        plural={<FormattedMessage defaultMessage="campagnes" />}
                                    />
                                </>
                            )}
                        </>
                    )}
                </>
            )}
        </FetcherCampaigns>
    );
}

const EmptyStateOuterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;
const HeadIllustrationWrapper = styled.div`
    width: 200px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const HeadIllustration = styled.img`
    min-width: 248px;
`;
const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    & > * {
        text-align: center;
    }
`;

export default observer(CampaignsList);
