import { LOGIN_PROVIDER, SOCIAL_NETWORK } from '@/utils/constants';

export const isProviderEnabled = (provider, user) => {
    switch (provider) {
        case SOCIAL_NETWORK.FACEBOOK:
            if (
                process.env.OVERRIDE_ENABLE_PROVIDER_FACEBOOK_FOR_USER_ID !== undefined &&
                process.env.OVERRIDE_ENABLE_PROVIDER_FACEBOOK_FOR_USER_ID === `${user?.id}`
            ) {
                return true;
            }

            return process.env.ENABLE_PROVIDER_FACEBOOK === 'true';
        case SOCIAL_NETWORK.INSTAGRAM:
            if (
                process.env.OVERRIDE_ENABLE_PROVIDER_INSTAGRAM_FOR_USER_ID !== undefined &&
                process.env.OVERRIDE_ENABLE_PROVIDER_INSTAGRAM_FOR_USER_ID === `${user?.id}`
            ) {
                return true;
            }

            return process.env.ENABLE_PROVIDER_INSTAGRAM === 'true';
        case SOCIAL_NETWORK.LINKEDIN:
            if (
                process.env.OVERRIDE_ENABLE_PROVIDER_LINKEDIN_FOR_USER_ID !== undefined &&
                process.env.OVERRIDE_ENABLE_PROVIDER_LINKEDIN_FOR_USER_ID === `${user?.id}`
            ) {
                return true;
            }

            return process.env.ENABLE_PROVIDER_LINKEDIN === 'true';
        case SOCIAL_NETWORK.TWITTER:
            if (
                process.env.OVERRIDE_ENABLE_PROVIDER_TWITTER_FOR_USER_ID !== undefined &&
                process.env.OVERRIDE_ENABLE_PROVIDER_TWITTER_FOR_USER_ID === `${user?.id}`
            ) {
                return true;
            }

            return process.env.ENABLE_PROVIDER_TWITTER === 'true';
        default:
            return false;
    }
};

export const isLoginProviderEnabled = (provider) => {
    switch (provider) {
        case LOGIN_PROVIDER.GOOGLE:
            return process.env.ENABLE_LOGIN_PROVIDER_GOOGLE === 'true';
        case LOGIN_PROVIDER.FACEBOOK:
            return process.env.ENABLE_LOGIN_PROVIDER_FACEBOOK === 'true';
        default:
            return false;
    }
};
