import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useStatsDateSelectorContext } from '@/context/StatsDateSelectorContext';
import { useParams } from 'react-router';
import useAgesQuery from '@/queries/stats/useAgesQuery.hook';
import AudienceAgeTable from './AudienceAgeTable';
import AudienceAgeExport from './AudienceAgeExport';
import AudienceAgeChart from './AudienceAgeChart';
import Stack from '../ui/layout/Stack';
import StatsDataSetSelector from '../unorganized/StatsDataSetSelector';
import ShortListFallback from '../unorganized/ShortListFallback';

const X_AXIS_LABELS = defineMessages({
    'age.lessThan18YearsOld': { defaultMessage: 'Moins de 18 ans' },
    'age.between18And25YearsOld': { defaultMessage: '18-25 ans' },
    'age.between26And35YearsOld': { defaultMessage: '26-35 ans' },
    'age.between36And45YearsOld': { defaultMessage: '36-45 ans' },
    'age.between46And55YearsOld': { defaultMessage: '46-55 ans' },
    'age.moreThan55YearsOld': { defaultMessage: 'Plus de 55 ans' },
    'stats.other': { defaultMessage: 'Autres' },
});

const AudienceAge = () => {
    const intl = useIntl();
    const { startDate, endDate } = useStatsDateSelectorContext();
    const { showId } = useParams<{ showId: string }>();
    const { data: ages } = useAgesQuery({
        showId,
        query: { start: startDate, end: endDate },
    });

    if (ages) {
        const series = ages.map((age) => age.percentage);
        const xAxisCategories = (
            Object.keys(X_AXIS_LABELS) as Array<keyof typeof X_AXIS_LABELS>
        ).map((name) => intl.formatMessage(X_AXIS_LABELS[name]));

        return (
            <>
                <StatsDataSetSelector
                    tabTitle={<FormattedMessage defaultMessage="Âge" />}
                    subTitle={
                        <FormattedMessage defaultMessage="Quel est l'âge de votre audience (Deezer uniquement) ?" />
                    }
                    tabId="audience"
                    noSelector
                />
                <Stack $gap="1rem">
                    <AudienceAgeChart
                        options={{ xaxis: { categories: xAxisCategories } }}
                        series={series}
                    />
                    <AudienceAgeTable ages={xAxisCategories} values={series} />
                    <AudienceAgeExport />
                </Stack>
            </>
        );
    }

    return <ShortListFallback />; // TODO: Handle loading state
};

export default AudienceAge;
