import statsKeys from '@queries/stats/statsKeys';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { fetchAgesStats } from '@/api';
import { z } from 'zod';
import camelcaseKeys from 'camelcase-keys';

interface AgesQueryProps {
    start: string | null;
    end: string | null;
}

interface AgesQueryParams {
    showId: string;
    query: AgesQueryProps;
}

const AgeSchema = z.object({
    age: z.string(),
    count: z.number(),
    percentage: z.number(),
});

const AgesSchema = z.array(AgeSchema);
export type Ages = z.infer<typeof AgesSchema>;

type Key = QueryFunctionContext<ReturnType<typeof statsKeys.ages>>;

const queryFn = async ({ queryKey: [{ showId, query }] }: Key) => {
    const { data } = await fetchAgesStats(showId, query);

    return AgesSchema.parse(camelcaseKeys(data, { deep: true }));
};

const useAgesQuery = ({ showId, query }: AgesQueryParams) => {
    return useQuery({
        queryKey: statsKeys.ages(showId, query),
        queryFn,
        enabled: !!showId,
    });
};

export default useAgesQuery;
