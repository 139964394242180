import { Draggable } from 'react-beautiful-dnd';
import { FormattedDate, FormattedMessage } from 'react-intl';
import handle from '@public/icons/handle.svg';
import eye from '@public/icons/eye.svg';
import clock from '@public/icons/clock.svg';
import list from '@public/icons/filter.svg';
import download from '@public/icons/download.svg';
import calendar from '@public/icons/calendar.svg';
import close from '@public/icons/close.svg';
import eyeOff from '@public/icons/eye-off.svg';
import unlisted from '@public/icons/link.svg';
import { connect } from '@app/decorators/connect';
import MobileOrTablet from '@ui/molecules/MobileOrTablet';
import DeprecatedPaper from '../../ui/atoms/DeprecatedPaper';
import DeprecatedIcon from '../../ui/atoms/DeprecatedIcon';
import DeprecatedText from '../../ui/atoms/DeprecatedText';
import { formatDuration } from '../../../helpers';
import DeprecatedButton from '../../ui/atoms/DeprecatedButton';
import Img from '../../ui/atoms/Img';

import Spinner from '../../ui/atoms/Spinner';
import DeprecatedWhitePaper from '../../ui/atoms/DeprecatedWhitePaper';
import Desktop from '../../ui/molecules/Desktop';
import HideFor from '../atoms/HideFor';
import { STATUS_SHOWS } from '../../../utils/statusShows';
import { USER_ROLES } from '@/utils/constants';
import styled from 'styled-components';
import useSeasonQuery from '@/queries/season/useSeasonQuery.hook';
import { useParams } from 'react-router';
import useShowQuery from '@/queries/show/useShowQuery.hook';

const icons = {
    public: eye,
    private: eyeOff,
    scheduled: calendar,
    unlisted,
};

const enhance = connect(({ routerStore }, { podcast }) => {
    const playlist = routerStore.resolve('playlistId');
    return {
        onDeletePodcast: () => podcast.deleteFromPlaylist(playlist),
        isLoading: podcast.deleteFromPlaylist.pending,
    };
});

function PlaylistEpisodeLine({ onDeletePodcast, isLoading, podcast, index }) {
    const { showId } = useParams();
    const { data: season } = useSeasonQuery({ showId, seasonId: podcast.seasonId });
    const { data: show } = useShowQuery(showId);
    const userIsViewer = show?.userRole === USER_ROLES.VIEWER;

    return (
        <Draggable
            draggableId={podcast.playlistPodcastId.toString()}
            index={index}
            isDragDisabled={userIsViewer}
        >
            {(provided) => (
                <DeprecatedWhitePaper
                    background="white"
                    height={75}
                    px={15}
                    align="center"
                    border="--neutral100"
                    flex
                    bb={1}
                    rounded={false}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    {!userIsViewer && (
                        <div>
                            <DeprecatedIcon color="--neutral200" as={handle} mr={15} size={15} />
                        </div>
                    )}
                    <Img circle src={podcast.imageUrl} size={40} mr={15} />
                    <DeprecatedPaper ellipsis auto w={null}>
                        <DeprecatedText size="big" weight="semibold" ellipsis mb={5}>
                            {podcast.name}
                        </DeprecatedText>
                        <EpisodeMetadataWrapper>
                            <MobileOrTablet>
                                <DeprecatedText
                                    flex
                                    align="center"
                                    as="span"
                                    color="--neutral500"
                                    mr={20}
                                    ellipsis
                                >
                                    <DeprecatedIcon color="--neutral500" as={calendar} mr={5} />
                                    <FormattedMessage
                                        defaultMessage="Publié le {date}"
                                        values={{ date: <FormattedDate value={new Date()} /> }}
                                    />
                                </DeprecatedText>
                            </MobileOrTablet>
                            <Desktop>
                                <DeprecatedText
                                    flex
                                    align="center"
                                    as="span"
                                    color="--neutral500"
                                    mr={20}
                                    ellipsis
                                >
                                    <DeprecatedIcon color="--neutral500" as={download} mr={5} />
                                    {podcast.downloadsCount}
                                </DeprecatedText>
                            </Desktop>
                            <DeprecatedText
                                flex
                                align="center"
                                as="span"
                                color="--neutral500"
                                mr={20}
                                ellipsis
                            >
                                <DeprecatedIcon color="--neutral500" as={clock} mr={5} />
                                {formatDuration(podcast.duration)}
                            </DeprecatedText>
                            <Desktop>
                                {season && (
                                    <DeprecatedText
                                        flex
                                        align="center"
                                        as="span"
                                        color="--neutral500"
                                        ellipsis
                                    >
                                        <DeprecatedIcon color="--neutral500" as={list} mr={5} />
                                        <FormattedMessage
                                            defaultMessage="Saison {number}"
                                            values={{ number: season.seasonNumber }}
                                        />
                                    </DeprecatedText>
                                )}
                            </Desktop>
                        </EpisodeMetadataWrapper>
                    </DeprecatedPaper>
                    <div>
                        <DeprecatedIcon
                            as={icons[podcast.privacy]}
                            color="--neutral500"
                            size={15}
                            mr={25}
                        />
                    </div>
                    <HideFor showStatus={[STATUS_SHOWS.ARCHIVED_SHOWS]} roles={[USER_ROLES.VIEWER]}>
                        <DeprecatedButton
                            accent="rounded"
                            onClick={onDeletePodcast}
                            disabled={isLoading}
                            icon
                        >
                            {isLoading ? (
                                <Spinner color="--neutral500" />
                            ) : (
                                <DeprecatedIcon as={close} />
                            )}
                        </DeprecatedButton>
                    </HideFor>
                </DeprecatedWhitePaper>
            )}
        </Draggable>
    );
}

const EpisodeMetadataWrapper = styled.div`
    display: flex;
`;

export default enhance(PlaylistEpisodeLine);
