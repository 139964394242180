import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addPodcast } from '@/api';
import episodeKeys from '@/queries/episode/episodeKeys';
import showKeys from '@/queries/show/showKeys';
import camelcaseKeys from 'camelcase-keys';

export interface MutationFnProps {
    showId: string;
    name: string;
}

const mutationFn = async ({ showId, name }: MutationFnProps) => {
    const { data } = await addPodcast(showId, { name });
    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const useAddEpisodeMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: (_, { showId }) => {
            queryClient.invalidateQueries({ queryKey: episodeKeys.allLists() });
            queryClient.invalidateQueries({ queryKey: showKeys.detailById(showId) });
        },
    });
};

export default useAddEpisodeMutation;
