import { FC, useState, createContext, useContext, ReactNode } from 'react';

interface TEpisodeFiltersContext {
    filters: {
        seasons: string[];
        statuses: string[];
    };
    setFilters: (filters: { seasons: string[]; statuses: string[] }) => void;
    search: string;
    setSearch: (search: string) => void;
}

const EpisodeFiltersContext = createContext<TEpisodeFiltersContext | undefined>(undefined);

interface EpisodeFiltersContextProviderProps {
    children: ReactNode;
}

export const EpisodeFiltersContextProvider: FC<EpisodeFiltersContextProviderProps> = ({
    children,
}) => {
    const [filters, setFilters] = useState<{
        seasons: string[];
        statuses: string[];
    }>({
        seasons: [],
        statuses: [],
    });
    const [search, setSearch] = useState<string>('');
    const contextValue = {
        filters,
        setFilters,
        search,
        setSearch,
    };

    return (
        <EpisodeFiltersContext.Provider value={contextValue}>
            {children}
        </EpisodeFiltersContext.Provider>
    );
};

export const useEpisodeFiltersContext = () => {
    const context = useContext(EpisodeFiltersContext);
    if (context === undefined) {
        throw new Error(
            'useEpisodeFiltersContext must be used within a EpisodeFiltersContextProvider',
        );
    }
    return context;
};
