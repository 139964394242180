import { FormattedNumber } from 'react-intl';
import styled from 'styled-components';

interface VariationTextProps {
    variation: number;
}

const VariationText = (props: VariationTextProps) => {
    if (!props.variation) return null;
    if (Math.sign(props.variation) === -1)
        return (
            <TextElement color="--alert">
                <FormattedNumber value={props.variation} style="percent" />
            </TextElement>
        );
    return (
        <TextElement color="--success">
            +<FormattedNumber value={props.variation} style="percent" />
        </TextElement>
    );
};

const TextElement = styled.span<{ color: string }>`
    font-weight: var(--fw-semibold);
    font-size: var(--fs-body-m);
    margin-left: 5px;
    color: var(${({ color }) => color});
`;

export default VariationText;
