import { useState } from 'react';
import { Tabs as RATabs, TabList, Tab, TabPanel, Key } from 'react-aria-components';
import { motion } from 'framer-motion';
import styled from 'styled-components';

type Tab = {
    id: string;
    title: string | React.ReactNode;
    content: string | React.ReactNode;
    disabled?: boolean;
};

export interface TabsProps {
    tabs: Tab[];
}

const Tabs = ({ tabs }: TabsProps) => {
    const [selectedKey, setSelectedKey] = useState(0);

    const onSelectionChange = (key: Key) => {
        setSelectedKey(tabs.findIndex((tab) => tab.id === key));
    };

    return (
        <StyledTabs
            disabledKeys={tabs.filter((t) => t.disabled).map((t) => t.id)}
            onSelectionChange={onSelectionChange}
        >
            <TabsOuter>
                <TabsInner>
                    <Pill multiplier={selectedKey} nbOfItems={tabs.length} />
                    <StyledTabList>
                        {tabs.map((tab) => (
                            <>
                                <StyledTab key={tab.id} id={tab.id} nbOfItems={tabs.length}>
                                    {tab.title}
                                </StyledTab>
                            </>
                        ))}
                    </StyledTabList>
                </TabsInner>
            </TabsOuter>
            {tabs.map((tab) => (
                <TabPanel key={tab.id} id={tab.id}>
                    {tab.content}
                </TabPanel>
            ))}
        </StyledTabs>
    );
};

const StyledTabs = styled(RATabs)`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;
const TabsOuter = styled.div`
    position: relative;
    width: 100%;
    background-color: var(--neutral100);
    border-radius: var(--r-s);
    padding: 0.25rem;
`;
const TabsInner = styled.div`
    position: relative;
    width: calc(100% - 0.25rem);
`;
const Pill = styled(motion.div)<{ multiplier: number; nbOfItems: number }>`
    position: absolute;
    top: 50%;
    left: 0.25rem;
    width: ${({ nbOfItems }) => `calc(100% / ${nbOfItems} - 0.5rem)`};
    height: calc(100% - 0.25rem);
    z-index: 0;
    border-radius: var(--r-s);
    background-color: var(--white);
    transform: ${(props) => `translateX(calc(${props.multiplier * 100 * 1.07}%)) translateY(-50%)`};

    @media (prefers-reduced-motion: no-preference) {
        transition: transform 0.3s ease;
    }
`;
const StyledTabList = styled(TabList)`
    position: relative;
    width: 100%;
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
    z-index: 2;
`;
const StyledTab = styled(Tab)<{ nbOfItems: number }>`
    cursor: pointer;
    outline: none;
    transition: all 0.2s;
    color: var(--neutral500);
    height: 2rem;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: var(--fw-semibold);
    font-size: var(--fs-body-m);
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: ${({ nbOfItems }) => `calc(100% / ${nbOfItems})`};

    &[data-hovered],
    &[data-focused] {
        color: var(--neutral600);
    }

    &[data-selected] {
        color: var(--black);
    }

    &[data-disabled] {
        cursor: not-allowed;
        color: var(--neutral200);
        &[data-selected] {
            color: var(--neutral200);
        }
    }

    &[data-focus-visible] {
        color: var(--neutral600);
    }
`;

export default Tabs;
