import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteClipFormat } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import clipFormatKeys from './clipFormatKeys';

const mutationFn = async (clipFormatId: string) => {
    const { data } = await deleteClipFormat(clipFormatId);

    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const deleteClipFormatMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: clipFormatKeys.all(),
            });
        },
    });
};

export default deleteClipFormatMutation;
