import { FormattedMessage } from 'react-intl';
import ListFormFallback from '@app/fallbacks/ListFormFallback';
import Text from '@ui/atoms/Text';
import DeprecatedWhitePaper from '@ui/atoms/DeprecatedWhitePaper';
import styled from 'styled-components';
import useSmartlinkSettingsQuery from '@queries/smartlinkSetting/useSmartlinkSettingsQuery.hook';
import SmartlinkSettingsForm from '../SmartlinkSettingsForm';
import useShowListeningLinksQuery from '@queries/showListeningLink/useShowListeningLinksQuery.hook';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { USER_OPTIONS } from '@/utils/userOptions';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import { useAmplitudeLogEvent } from '@hooks/useAmplitude';
import useCallToActionButtonsQuery from '@/queries/callToAction/useCallToActionButtonsQuery.hook';
import { LINKS_NAMES } from '@/components/CallToAction';
import useShowQuery from '@/queries/show/useShowQuery.hook';

const SmartlinksSettings = () => {
    useAmplitudeLogEvent('Smartlink Tab Viewed');
    const { showId } = useParams();
    const { data: show } = useShowQuery(showId);
    const { data: user } = useUserQuery();
    const settings = useSmartlinkSettingsQuery(showId);
    const listeningLinks = useShowListeningLinksQuery(showId);
    const IsCTAFeatureEnabled = show
        ? show.userOptions.includes(USER_OPTIONS.CTA_BUTTONS)
        : user
        ? user.options.includes(USER_OPTIONS.CTA_BUTTONS)
        : false;
    const callToActionButtons = useCallToActionButtonsQuery({
        showId,
        type: LINKS_NAMES.SMARTLINK,
    });
    const defaultSettings = {
        ...settings.data,
        listeningLinks: listeningLinks.data,
        ctaButtons: callToActionButtons.data || [],
    };
    const isFetching =
        settings.isLoading ||
        listeningLinks.isLoading ||
        (IsCTAFeatureEnabled && callToActionButtons.isLoading) ||
        !show;

    return (
        <SmartlinkSettingsWrapper>
            <SmartlinkSettingsInnerWrapper>
                <Text color="--neutral500">
                    <FormattedMessage defaultMessage="Le Smartlink est un lien permettant à vos auditeurs d’accéder en un clic à leur application d'écoute favorite (Apple Podcasts, Spotify, etc.) pour écouter votre podcast." />
                </Text>
                {isFetching ? (
                    <DeprecatedWhitePaper p={30} mt={30}>
                        <ListFormFallback />
                    </DeprecatedWhitePaper>
                ) : (
                    <SmartlinkSettingsForm
                        defaultSettings={defaultSettings}
                        showSlug={show?.slug}
                        IsCTAFeatureEnabled={IsCTAFeatureEnabled}
                    />
                )}
            </SmartlinkSettingsInnerWrapper>
        </SmartlinkSettingsWrapper>
    );
};

const SmartlinkSettingsWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding);

    ${({ theme }) => theme.mediaQueries.mobile} {
        padding: var(--container-padding-mobile);
    }
`;
const SmartlinkSettingsInnerWrapper = styled.div`
    width: 100%;
    max-width: 680px;
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export default observer(SmartlinksSettings);
