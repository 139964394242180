import PropTypes from 'prop-types';
import { useCountdown } from '@/utils/hooks/useCountdown.hook';
import O5H4LookingUp from '@public/images/o5h4-lookup-up.png';
import { FormattedMessage } from 'react-intl';
import Text from '@ui/atoms/Text';
import styled from 'styled-components';

const PsoCountdown = ({ targetDate }) => {
    const [_, hours, minutes] = useCountdown(targetDate);
    const figureTypeLookup = {
        days: <FormattedMessage defaultMessage="jours" />,
        hours: <FormattedMessage defaultMessage="heures" />,
        minutes: <FormattedMessage defaultMessage="minutes" />,
    };
    const renderCountdownBlock = (source, type) => {
        return (
            <FigureWrapper>
                <FigureGroup>
                    {String(source)
                        .padStart(2, '0')
                        .split('')
                        .map((figure, index) => (
                            <Figure key={index}>{figure}</Figure>
                        ))}
                </FigureGroup>
                {figureTypeLookup[type]}
            </FigureWrapper>
        );
    };
    return (
        <CountdownWrapper>
            <Title>
                <FormattedMessage defaultMessage="L'historique de vos données sera disponible dans..." />
            </Title>
            <Countdown>
                {renderCountdownBlock(hours, 'hours')}
                <Separator>:</Separator>
                {renderCountdownBlock(minutes, 'minutes')}
            </Countdown>
            <O5H4Illustration src={O5H4LookingUp} />
        </CountdownWrapper>
    );
};

const CountdownWrapper = styled.div`
    position: relative;
    background: var(--gradient-dawn);
    padding: 1rem;
`;
const Title = styled(Text)`
    font-size: var(--fs-heading-s);
    font-weight: var(--fw-bold);
    text-align: center;
    margin-block: 1rem;
`;
const Countdown = styled.ul`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
    gap: 0.25rem;
    width: 100%;
`;
const FigureWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.25rem;
`;
const FigureGroup = styled.li`
    display: flex;
    align-items: center;
    gap: 0.25rem;
    height: 4rem;
`;
const Figure = styled.div`
    height: 4rem;
    padding-inline: 0.8rem;
    background-color: var(--white500);
    border-radius: var(--r-m);
    font-size: var(--fs-heading-m);
    font-weight: var(--fw-bold);
    display: flex;
    align-items: center;
    justify-content: center;
`;
const Separator = styled.li`
    font-size: var(--fs-heading-m);
    font-weight: var(--fw-bold);
    color: var(--neutral500);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.25rem;
    margin-bottom: 1.75rem;
`;
const O5H4Illustration = styled.img`
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-45%);
`;

PsoCountdown.propTypes = {
    targetDate: PropTypes.number.isRequired,
};

export default PsoCountdown;
