import EmptyState from '@ui/molecules/EmptyState';
import ZebraIllustration from '@ui/atoms/ZebraIllustration';
import controlKnobs from '@public/images/emoji/control_knobs.png';
import PsoIllustration from '@public/images/empty-state/illustration/pso.mp4';
import Text from '@/components/ui/atoms/Text';
import RouterLink from '@/components/Link/RouterLink';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import useInternalRouteMapping from '@/utils/hooks/useInternalRouteMapping.hook';
import Button from '@ui/atoms/Button';
import styled from 'styled-components';

const NoPlatforms = () => {
    const { showId }: { showId: string } = useParams();
    const { link } = useInternalRouteMapping();
    return (
        <EmptyState
            illustration={PsoIllustration}
            illustrationType="video"
            illustrationAlign="right"
            illustrationBackground="--gradient-dawn"
        >
            <HeadIllustrationWrapper>
                <ZebraIllustration foreground={controlKnobs} />
            </HeadIllustrationWrapper>
            <LabelWrapper>
                <Text variant="heading" fontWeight="--fw-bold">
                    <FormattedMessage defaultMessage="Diffusez votre podcast sur Apple Podcasts ou Spotify" />
                </Text>
                <Text variant="body" fontWeight="--fw-normal" color="--neutral500">
                    <FormattedMessage
                        defaultMessage="Débloquez l’optimisation du référencement de votre podcast <link>en le diffusant sur les plateformes d'écoute Apple Podcasts ou Spotify</link> ⚡️"
                        values={{
                            link: (chunks: React.ReactNode) => (
                                <RouterLink
                                    to={link('menu.settings.tab', { showId, tab: 'broadcast' })}
                                >
                                    {chunks}
                                </RouterLink>
                            ),
                        }}
                    />
                </Text>
                <ActionWrapper>
                    <SecondaryButton
                        as="a"
                        href="https://www.youtube.com/watch?v=KNX8V6oSW5s"
                        target="_blank"
                    >
                        <FormattedMessage defaultMessage="Découvrir PSO" />
                        <FontAwesomeIcon icon={icon({ name: 'arrow-up-right', style: 'solid' })} />
                    </SecondaryButton>
                </ActionWrapper>
            </LabelWrapper>
        </EmptyState>
    );
};

const HeadIllustrationWrapper = styled.div`
    width: 200px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    & > * {
        text-align: center;
    }
`;
const ActionWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-block: 1rem;
`;
const SecondaryButton = styled(Button)`
    background-color: var(--primary50);
    color: var(--primary500);
    padding: 0.625rem 1rem;
    text-decoration: none;
    font-weight: var(--fw-semibold);
    border-radius: var(--r-s);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    &:hover {
        opacity: 0.8;
    }
`;

export default NoPlatforms;
