import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import useRouterStore from '../../../shared/hooks/useRouterStore';
import { useModalToastQueue } from '@/shared/hooks/useModalToastQueue.hook';

const EmailConfirmationPage = () => {
    const { push } = useRouterStore();
    const toast = useModalToastQueue();

    useEffect(() => {
        push('show.all');
        toast.success(<FormattedMessage defaultMessage="Votre adresse e-mail est validée !" />);
    }, [push]);

    return null;
};

export default EmailConfirmationPage;
