import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchEpisodesRankingFromShow } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import statsKeys from './statsKeys';
import { z } from 'zod';

export const ListenersEpisodesRankingSchema = z.object({
    downloads: z.number(),
    name: z.string(),
    podcastId: z.number(),
    averageListeningTime: z.string().nullable().optional(),
    averageCompletionRate: z.string().nullable().optional(),
});
export const ListenersEpisodesRankingsSchema = z.array(ListenersEpisodesRankingSchema);
export type ListenersEpisodesRanking = z.infer<typeof ListenersEpisodesRankingSchema>;
const ListenersEpisodesRankingPaginationSchema = z.object({
    currentPage: z.number(),
    totalPages: z.number(),
    totalItems: z.number().optional(),
    itemsPerPage: z.number().optional(),
});
const ListenersEpisodesRankingResponseSchema = z.object({
    data: ListenersEpisodesRankingsSchema,
    pagination: ListenersEpisodesRankingPaginationSchema,
});
type Key = QueryFunctionContext<ReturnType<typeof statsKeys.listenersEpisodesRanking>>;
type InputProps = {
    showId: string;
    start: string;
    end: string;
    order: 'asc' | 'desc';
    page: number;
    perPage: number;
    episodes: string[];
    timezone: string;
    options?: {
        enabled?: boolean;
    };
};

const queryFn = async ({
    queryKey: [{ showId, start, end, order, page, perPage, episodes, timezone }],
}: Key) => {
    const response = await fetchEpisodesRankingFromShow(showId, {
        start,
        end,
        order,
        page,
        perPage,
        podcasts: episodes,
        timezone,
    });

    const fullData = response.data.map((item: any) => {
        return {
            ...item,
            averageListeningTime: item.avg_listening_time ? `${item.avg_listening_time}` : '-',
            averageCompletionRate: item.avg_completion_rate ? `${item.avg_completion_rate}` : '-',
        };
    });
    return ListenersEpisodesRankingResponseSchema.parse(
        camelcaseKeys({ data: fullData, pagination: response.pagination }, { deep: true }),
    );
};

const useListenersEpisodesRankingQuery = ({
    showId,
    start,
    end,
    order,
    page,
    perPage,
    episodes,
    timezone,
    options,
}: InputProps) => {
    return useQuery({
        queryKey: statsKeys.listenersEpisodesRanking(
            showId,
            start,
            end,
            order,
            page,
            perPage,
            episodes,
            timezone,
        ),
        queryFn,
        enabled: options?.enabled || true,
    });
};

export default useListenersEpisodesRankingQuery;
