import { Tooltip as AriaTooltip, TooltipTrigger, Button } from 'react-aria-components';
import { css, keyframes } from 'styled-components';
import styled from 'styled-components';

const positionMatch = {
    top: css`
        border-left: 0.5rem solid transparent;
        border-right: 0.5rem solid transparent;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
    `,
    bottom: css`
        border-left: 0.5rem solid transparent;
        border-right: 0.5rem solid transparent;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
    `,
    left: css`
        border-top: 0.5rem solid transparent;
        border-bottom: 0.5rem solid transparent;
        left: 100%;
        top: 50%;
        transform: translateY(-50%);
    `,
    right: css`
        border-top: 0.5rem solid transparent;
        border-bottom: 0.5rem solid transparent;
        right: 100%;
        top: 50%;
        transform: translateY(-50%);
    `,
};

const appendArrow = (position) => css`
    ::before,
    ::after {
        content: '';
        position: absolute;
        ${positionMatch[position]};
    }
    ::before {
        border-${position}: 0.25rem solid var(--black);
    }
    ::after {
        border-${position}: 0.25rem solid var(--black);
        margin-${position}: -1.5px;
        z-index: 100;
    }
`;

const scale = keyframes`
    from {
      transform: scale(0.9);
      opacity: 0;
    }
  
    to {
      transform: scale(1);
      opacity: 1;
    }
`;

const Tooltip = styled(AriaTooltip)`
    background-color: var(--black);
    max-width: 20rem;
    color: var(--white);
    font-weight: var(--fw-semibold);
    padding: 0.5rem;
    border-radius: var(--r-s);
    position: relative;

    &[data-placement='top'] {
        ${appendArrow('top')}
    }
    &[data-placement='bottom'] {
        ${appendArrow('bottom')}
    }
    &[data-placement='left'] {
        ${appendArrow('left')}
    }
    &[data-placement='right'] {
        ${appendArrow('right')}
    }

    &[data-entering] {
        animation: ${scale} 0.2s;
    }
`;
const HiddenButton = styled(Button)`
    border: none;
    background: none;
    padding: 0;
    display: flex;

    & > * {
        width: 100%;
    }
`;

const ExperimentalTooltip = ({ children, content, offset, placement, className, ...props }) => {
    return (
        <TooltipTrigger delay={0} closeDelay={0} {...props}>
            <HiddenButton className={className}>{children}</HiddenButton>
            <Tooltip offset={offset || 8} placement={placement}>
                {content}
            </Tooltip>
        </TooltipTrigger>
    );
};

export default ExperimentalTooltip;
