import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import RouterLink from '@/components/Link/RouterLink';
import {
    LISTENING_PLATFORMS_NAMES,
    ONE_CLICK_BROADCAST_PLATFORMS,
    ListeningPlatform,
    OneClickBroadcastPlatform,
} from '@/utils/constants';
import Alert from '@/components/ui/atoms/Alert';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import useDeleteShowListeningLinkMutation from '@/queries/show/useDeleteShowListeningLinkMutation.hook';
import { useOverlayTriggerState } from 'react-stately';
import useQuery from '@/utils/hooks/useQuery';
import useHasTutorial from '@hooks/useHasTutorial.hook';
import useTutorial from './useTutorial.hook';
import { SliderButton } from '@typeform/embed-react';
import BroadcastSettingTutorialEndModal from './BroadcastSettingTutorialEndModal';
import PlatformBroadcastSettingsForm from './PlatformBroadcastSettingsForm';
import styled from 'styled-components';

interface PlatformBroadcastSettingsProps {
    logo: string | null;
}

const PlatformBroadcastSettings = ({ logo }: PlatformBroadcastSettingsProps) => {
    const { showId, platform } = useParams<{ showId: string; platform: string }>();
    const query = useQuery();
    const startWithTutorial: boolean = !!query.get('startWithTutorial');
    const platformKey: ListeningPlatform = platform as ListeningPlatform;

    const history = useHistory();
    const tutorial = useTutorial({ platformKey });
    const hasTutorial: boolean = useHasTutorial(platformKey);

    const { data: show } = useShowQuery(showId);
    const deleteListeningLink = useDeleteShowListeningLinkMutation();

    const [tutorialEnabled, setTutorialEnabled] = useState(startWithTutorial);
    const [readonlyMode, setReadonlyMode] = useState(true);
    const [hasRequestedBroadcast, setHasRequestedBroadcast] = useState(false);
    let endTutorialModalState = useOverlayTriggerState({});

    const isShowPmp = show?.hostedOnAusha === false;
    const displayTutorial = hasTutorial && !isShowPmp;

    const isAOneClickPlatform = ONE_CLICK_BROADCAST_PLATFORMS.includes(
        platformKey as OneClickBroadcastPlatform,
    );

    useEffect(() => {
        if (!isAOneClickPlatform) return;
        const targetListeningLink = (show?.listeningLinks?.data ?? []).find(
            (link) => link.key === platform,
        );

        setHasRequestedBroadcast(!!targetListeningLink && targetListeningLink?.url === null);
    }, [show]);

    const platformName = LISTENING_PLATFORMS_NAMES[platformKey];

    const onAfterSubmitTutorial = async () => {
        endTutorialModalState.close();
        if (platformKey === 'itunes') {
            deleteListeningLink.mutate(
                {
                    showId,
                    platform: platformKey,
                },
                {
                    onSuccess: () => {
                        setHasRequestedBroadcast(false);
                        isShowPmp
                            ? history.push(`/app/show/${showId}/settings/broadcast/non-hosting`)
                            : history.push(`/app/show/${showId}/settings/broadcast`);
                    },
                },
            );
        }
    };

    return (
        <>
            {tutorial && tutorialEnabled && (
                <TypeformTutorial
                    id={tutorial.typeformId}
                    transitiveSearchParams={['rssLink']}
                    hidden={{
                        rssLink: show?.rssLink || '',
                    }}
                    // @ts-ignore - '' is not valid but I need to conditionnaly open the tutorial because the lib is buggy and does not work like it normally should
                    open={tutorialEnabled ? 'load' : ''}
                    onSubmit={() => {
                        setTutorialEnabled(false);
                        endTutorialModalState.open();
                    }}
                    onClose={() => {
                        setTutorialEnabled(false);
                        setTimeout(() => {
                            const body = document.querySelector('body');
                            if (body) {
                                body.style.overflow = 'initial';
                            }
                        }, 1);
                    }}
                />
            )}
            <SettingsOuterWrapper>
                <Header>
                    <BackLink
                        to={
                            isShowPmp
                                ? `/app/show/${showId}/settings/broadcast/non-hosting`
                                : `/app/show/${showId}/settings/broadcast`
                        }
                        variant="button-tertiary"
                    >
                        <FontAwesomeIcon icon={icon({ name: 'arrow-left', style: 'solid' })} />
                    </BackLink>
                    <Title>{platformName}</Title>
                </Header>
                <SettingsWrapper>
                    <PlatformWrapper>
                        {logo && <PlatformIcon src={logo} alt={platformName} />}
                        <PlatformName>{platformName}</PlatformName>
                    </PlatformWrapper>
                    {hasRequestedBroadcast && (
                        <Alert variant="robot">
                            <AlertText>
                                <FormattedMessage
                                    defaultMessage="Je suis en train de récupérer le lien d'écoute de votre podcast sur {platform} afin de l'ajouter automatiquement. Cela peut me prendre quelques heures ! ⏳"
                                    values={{ platform: platformName }}
                                />
                            </AlertText>
                        </Alert>
                    )}
                    <PlatformBroadcastSettingsForm
                        readonlyMode={readonlyMode}
                        setReadonlyMode={setReadonlyMode}
                        platformKey={platformKey}
                        platformName={platformName}
                        isAOneClickPlatform={isAOneClickPlatform}
                        setHasRequestedBroadcast={setHasRequestedBroadcast}
                    />
                </SettingsWrapper>
                {displayTutorial && (
                    <TriggerTutorialWrapper onClick={() => setTutorialEnabled(true)}>
                        <TriggerTutorialLeft>
                            <Pill>
                                <FormattedMessage defaultMessage="Tutoriel" />
                            </Pill>
                            <FormattedMessage
                                defaultMessage="Ajouter mon podcast sur {broadcastName}"
                                values={{
                                    broadcastName: platformName,
                                }}
                            />
                        </TriggerTutorialLeft>
                        <FontAwesomeIcon icon={icon({ name: 'chevron-right', style: 'solid' })} />
                    </TriggerTutorialWrapper>
                )}
            </SettingsOuterWrapper>
            {endTutorialModalState.isOpen && (
                <BroadcastSettingTutorialEndModal
                    isOpen={endTutorialModalState.isOpen}
                    onClose={endTutorialModalState.close}
                    platformKey={platformKey}
                    onAfterSubmitTutorial={onAfterSubmitTutorial}
                />
            )}
        </>
    );
};

const SettingsOuterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;
const Header = styled.div`
    display: flex;
    align-items: center;
    gap: 0.75rem;
`;
const BackLink = styled(RouterLink)`
    border-radius: var(--r-full);
    background-color: var(--white);
`;
const Title = styled.h1``;
const SettingsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: 2rem;
`;
const PlatformWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.75rem;
`;
const PlatformIcon = styled.img`
    width: 1.5rem;
    height: 1.5rem;
`;
const PlatformName = styled.h2`
    font-weight: var(--fw-semibold);
    font-size: var(--fs-body-l);
`;
const AlertText = styled.p`
    font-weight: var(--fw-semibold);
`;
const TriggerTutorialWrapper = styled.button`
    transition-duration: 0.2s;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    background-color: var(--white);
    box-shadow: none;
    color: var(--neutral);
    border: none;
    border-radius: var(--r-l);
    cursor: pointer;
    border: 1px solid transparent;

    &:hover {
        border: 1px solid var(--neutral100);
        color: var(--black);
    }
`;
const TriggerTutorialLeft = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    font-weight: var(--fw-semibold);
    color: var(--black);
`;
const Pill = styled.div`
    background-color: var(--primary50);
    color: var(--primary);
    padding: 0.5rem 1rem;
    border-radius: var(--r-m);
`;
const TypeformTutorial = styled(SliderButton)`
    display: none;
`;

export default PlatformBroadcastSettings;
