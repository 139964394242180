import { FormattedDate, FormattedMessage, IntlProvider } from 'react-intl';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import Link from '@ui/atoms/Link';
import DeprecatedButton from '@ui/atoms/DeprecatedButton';
import DeprecatedIcon from '@ui/atoms/DeprecatedIcon';
import DeprecatedWhitePaper from '@ui/atoms/DeprecatedWhitePaper';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import Img from '@ui/atoms/Img';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import { commonMessages, formatDuration, sendAmplitudeLogEvent } from '@/helpers';
import Status from '@ui/molecules/Status';
import Tag from '@ui/molecules/Tag';
import ButtonAccess from '@/components/unorganized/ButtonAccess';
import HideFor from '@/components/unorganized/HideFor';
import back from '@public/icons/arrow-left.svg';
import edit from '@public/icons/edit.svg';
import ListFormFallback from '@/components/unorganized/ListFormFallback';
import { STATUS_SHOWS } from '@/shared/config/statusShows';
import Text from '@ui/atoms/Text';
import useRouterStore from '@/shared/hooks/useRouterStore';
import usePodcastStore from '@/shared/hooks/usePodcastStore';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { USER_OPTIONS } from '@/shared/config/userOptions';
import Stack from '@ui/layout/Stack';
import useShowQuery from '@queries/show/useShowQuery.hook';
import translationsEn from '../../../../lang/en.json';
import translationsFr from '../../../../lang/fr.json';
import { useAudioPlayerContext } from '@/context/AudioPlayerContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

import Button from '@/components/Button';
import ContextualMenu from './ContextualMenu';

const PaperDescription = styled(DeprecatedText)`
    p {
        margin-bottom: 10px;
    }
    a {
        color: var(--primary);
        text-decoration: none;
    }
`;

const HostedMention = styled.div`
    a {
        text-decoration: underline;
        color: var(--black);
    }
`;

const LinkButton = styled.a`
    padding: 0;
`;

const Loading = () => (
    <DeprecatedWhitePaper p={30}>
        <ListFormFallback />
    </DeprecatedWhitePaper>
);

const EpisodePreviewInformations = ({ isLoading }) => {
    const { link } = useRouterStore();
    const { podId } = useParams();
    const podcast = usePodcastStore().byId(podId);
    const linkEpisodePage = link('menu.episodes');

    const { showId } = useParams();
    const { data: show } = useShowQuery(showId);
    const showLocale = show?.language?.data.code;
    const isShowHosted = show?.hostedOnAusha === true;

    const { audioIsPlaying, play, pause } = useAudioPlayerContext();

    if (isLoading) return <Loading />;

    if (!podcast)
        return (
            <Text>
                <FormattedMessage defaultMessage="Oh, il semblerait qu’il y ait un souci…" />
            </Text>
        );

    return (
        <>
            <DeprecatedPaper flex align="center" mb={20} z={1} position="relative">
                <DeprecatedButton as={Link} to={linkEpisodePage} accent="roundedSmall" icon>
                    <DeprecatedIcon as={back} />
                </DeprecatedButton>
                <DeprecatedText ml={10} weight="bold" size="veryBig" w="100%" ellipsis>
                    {podcast.name}
                </DeprecatedText>
                <DeprecatedPaper flex justify="flex-end" w="max-content">
                    <HideFor roles={['viewer']} showStatus={[STATUS_SHOWS.ARCHIVED_SHOWS]}>
                        <ButtonAccess
                            accent="rounded"
                            onClick={() => {
                                sendAmplitudeLogEvent('Edit Form Episode', {
                                    is_existing_episode: podcast.isDraft,
                                });
                            }}
                            as={Link}
                            to={link('experimental.episodes.edit', { episodeId: podcast.id })}
                            ml={15}
                            mr={10}
                        >
                            <DeprecatedIcon as={edit} />
                            <FormattedMessage defaultMessage="Modifier" />
                        </ButtonAccess>
                    </HideFor>
                    <ContextualMenu episode={podcast} />
                </DeprecatedPaper>
            </DeprecatedPaper>
            <DeprecatedWhitePaper p={35}>
                <DeprecatedPaper flex>
                    <DeprecatedPaper w="max-content">
                        <Img bordered size={300} src={podcast.imageUrl} />
                        <DeprecatedPaper mt={20} flex justify="space-between">
                            <Button
                                onPress={() => {
                                    audioIsPlaying
                                        ? pause()
                                        : play({
                                              name: podcast.name,
                                              url: podcast.audioUrl,
                                          });
                                }}
                                variant="ghost"
                                startIcon={
                                    audioIsPlaying ? (
                                        <FontAwesomeIcon
                                            icon={icon({ name: 'pause', style: 'solid' })}
                                        />
                                    ) : (
                                        <FontAwesomeIcon
                                            icon={icon({ name: 'play', style: 'solid' })}
                                        />
                                    )
                                }
                            >
                                <FormattedMessage defaultMessage="Écouter l'épisode" />
                            </Button>
                            <DeprecatedText color="--neutral500" ellipsis>
                                {formatDuration(podcast.duration)}
                            </DeprecatedText>
                        </DeprecatedPaper>
                    </DeprecatedPaper>

                    <DeprecatedPaper ml={35}>
                        <DeprecatedPaper align="center" mb={20}>
                            <DeprecatedPaper flex align="center" mb={20}>
                                <Status minW={100} value={podcast.privacy}>
                                    {commonMessages(
                                        'privacy',
                                        podcast.privacy === 'public' ? 'online' : podcast.privacy,
                                    )}
                                </Status>
                                <DeprecatedText color="--neutral500" ml={15} ellipsis>
                                    <FormattedMessage
                                        defaultMessage="Publié le {date}"
                                        values={{
                                            date: <FormattedDate value={podcast.publishedAt} />,
                                        }}
                                    />
                                </DeprecatedText>
                            </DeprecatedPaper>
                        </DeprecatedPaper>

                        <PaperDescription as="div" mb={20} minH={230}>
                            <Stack $gap="2rem">
                                {ReactHtmlParser(podcast.htmlDescription)}
                                {!show?.userOptions.includes(USER_OPTIONS.NO_HOST_MENTION) &&
                                    isShowHosted && (
                                        <IntlProvider
                                            locale={showLocale}
                                            messages={
                                                showLocale === 'fr'
                                                    ? translationsFr
                                                    : translationsEn
                                            }
                                        >
                                            <HostedMention>
                                                <FormattedMessage
                                                    defaultMessage="Hébergé par Ausha. Visitez {link} pour plus d'informations."
                                                    values={{
                                                        link: (
                                                            <LinkButton
                                                                href={
                                                                    showLocale === 'fr'
                                                                        ? 'https://www.ausha.co/fr/politique-de-confidentialite/'
                                                                        : 'https://www.ausha.co/privacy-policy/'
                                                                }
                                                                target="_blank"
                                                            >
                                                                <FormattedMessage defaultMessage="ausha.co/fr/politique-de-confidentialite" />
                                                            </LinkButton>
                                                        ),
                                                    }}
                                                />
                                            </HostedMention>
                                        </IntlProvider>
                                    )}
                            </Stack>
                        </PaperDescription>
                        <DeprecatedPaper flex wrap>
                            {podcast.tags.map((tag, i) => (
                                <Tag key={i} noClose>
                                    {tag.name}
                                </Tag>
                            ))}
                        </DeprecatedPaper>
                    </DeprecatedPaper>
                </DeprecatedPaper>
            </DeprecatedWhitePaper>
        </>
    );
};

export default observer(EpisodePreviewInformations);
