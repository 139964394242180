import ListBoxItem from '@/components/ui/atoms/ListBoxItem';
import Select from '@/components/ui/atoms/Select';
import useQuery from '@/shared/hooks/useQuery';
import { Key } from 'react-aria';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import styled from 'styled-components';

const DaysRangeSelect = () => {
    const intl = useIntl();
    const history = useHistory();
    const query = useQuery();
    const defaultValue = query.get('days') || 'always';
    const options = [
        { id: '7', name: intl.formatMessage({ defaultMessage: '7 derniers jours' }) },
        { id: '15', name: intl.formatMessage({ defaultMessage: '15 derniers jours' }) },
        { id: '30', name: intl.formatMessage({ defaultMessage: '30 derniers jours' }) },
        { id: '90', name: intl.formatMessage({ defaultMessage: '90 derniers jours' }) },
        { id: 'always', name: intl.formatMessage({ defaultMessage: 'Depuis le début' }) },
    ];

    const onChangeValue = (value: Key) => {
        if (!value) return;
        query.set('days', value.toString());
        history.push({ search: query.toString() });
    };

    return (
        <StyledSelect
            name="language"
            items={options}
            defaultSelectedKey={defaultValue}
            onSelectionChange={onChangeValue}
        >
            {(item) => <ListBoxItem {...item} />}
        </StyledSelect>
    );
};

const StyledSelect = styled(Select)`
    max-width: 10rem;
`;

export default DaysRangeSelect;
