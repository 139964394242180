import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { FORM_VALIDATION_MESSAGE } from '@/shared/services/validationMessageService';

const useTrackersFormSchema = () => {
    const intl = useIntl();

    return yup.object({
        googleAnalytics: yup
            .string()
            .max(15, intl.formatMessage(FORM_VALIDATION_MESSAGE.maxLength, { max: 15 }))
            .nullable(),
        facebookPixel: yup
            .string()
            .max(16, intl.formatMessage(FORM_VALIDATION_MESSAGE.maxLength, { max: 16 }))
            .nullable(),
        linkedinPixel: yup
            .string()
            .max(15, intl.formatMessage(FORM_VALIDATION_MESSAGE.maxLength, { max: 15 }))
            .nullable(),
    });
};

export default useTrackersFormSchema;
