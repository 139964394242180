import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateClip } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import clipKeys from './clipKeys';
import { decamelizeKeys } from 'humps';

export interface MutationFnProps {
    clipId: string | number;
    clip: any; // TODO: Type Clip entity
}

const mutationFn = async ({ clipId, clip }: MutationFnProps): Promise<any> => {
    const { data } = await updateClip(clipId, decamelizeKeys(clip));
    return camelcaseKeys(data as any, { deep: true });
};

const updateClipMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn,
        onSuccess: (_, { clipId }) => {
            queryClient.invalidateQueries(clipKeys.detailById(clipId));
        },
    });
};

export default updateClipMutation;
