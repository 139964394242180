import { useEffect, useContext } from 'react';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import { EpisodeFiltersContextProvider } from '@/context/EpisodeFiltersContext';
import EpisodesPage from './EpisodesPage';
import PlaylistsPage from './PlaylistsPage';
import MonetizePage from './MonetizePage';
import DeprecatedPaper from '../../ui/atoms/DeprecatedPaper';
import PaymentValidationRequiredBand from '../../unorganized/PaymentValidationRequiredBand';
import SettingsPage from './SettingsPage';
import StatsPage from './StatsPage';
import Topbar from '../../unorganized/Topbar';
import UserPage from './UserPage';
import PsoPage from './PsoPage';
import { ValidSubscriptionRoute } from '../../legacy/authorisationRoutes';
import { useAmplitudeSyncUserProperties } from '@/shared/hooks/useAmplitude';
import EpisodePreview from './EpisodePreview';
import CommunicationPage from './CommunicationPage';
import ClipsPage from './ClipsPage';
import MainLayout from '../layout/Main.layout';
import { CurrentShow } from '@/context/CurrentShowContext';
import SummaryEpisodePage from './SummaryEpisodePage';
import NonHostingPublishedEpisodes from './NonHostingPublishedEpisodes';
import NonHostingDraftEpisodes from './NonHostingDraftEpisodes';
import Footer from '@/components/Footer';
import { ENABLE_NON_HOSTING_EP_MGT } from '@/shared/config/constants';
import useShowsQuery from '@/queries/show/useShowsQuery.hook';
import NonHostingEpisodePreviewPage from './NonHostingEpisodePreviewPage';
import NavigationMenu from '@/components/NavigationMenu';
import NavigationMenuSkeleton from '@/components/NavigationMenu/NavigationMenuSkeleton';
import NonHostingEpisodeFormPages from './NonHostingEpisodeFormPages';
import EpisodeEditPage from './EpisodeEditPage';
import useShowQuery from '@/queries/show/useShowQuery.hook';

const ApplicationPage = () => {
    useAmplitudeSyncUserProperties();
    const { showId } = useParams<{ showId: string }>();
    const currentShow = useContext(CurrentShow);
    const { data: shows, isLoading: showsAreLoading } = useShowsQuery();
    const isShowAllowed = (showId: string) => {
        return showId && shows?.shows?.some((show) => String(show.id) === showId);
    };
    const show = useShowQuery(showId);
    const showHasHostingAccess = show.data?.hostedOnAusha === true;
    const hostingDefaultRoute =
        (shows?.shows ?? []).length > 0 ? `/app/show/${showId}/episodes` : `/app/show/new`;
    const nonHostingDefaultRoute = ENABLE_NON_HOSTING_EP_MGT
        ? `/app/show/${showId}/workspace`
        : `/app/show/${showId}/pso`;
    const defaultRoute = showHasHostingAccess ? hostingDefaultRoute : nonHostingDefaultRoute;

    useEffect(() => {
        /**
         * Upon navigating towards a "show" page, we snatch the showId from the URL and set it in the context.
         * That way, non-"show" pages can still access the current showId. (e.g. UserPage)
         *
         * The check for !!Number(showId) ensures no accidental overwrite of the showId
         * by _something_ named showId that's really a UserPage's tab identifer or something.
         */
        if (location.pathname.startsWith('/app/show/') && showId && !!Number(showId)) {
            currentShow?.setShowId(showId);
        }
    }, [location.pathname]);

    if (!!showId && !show.data) return null;

    if (!!showId && !!shows && !isShowAllowed(showId)) {
        return <Redirect to="/app/show/all" />;
    }

    return (
        <Switch>
            {ENABLE_NON_HOSTING_EP_MGT && !showHasHostingAccess && (
                <Route path="/app/show/:showId/workspace/episodes">
                    <NonHostingEpisodeFormPages />
                </Route>
            )}
            {showHasHostingAccess && (
                <Route path="/app/show/:showId/episodes/edit">
                    <EpisodeEditPage />
                </Route>
            )}
            <MainLayout
                header={
                    <>
                        <Topbar />
                        {showsAreLoading ? <NavigationMenuSkeleton /> : <NavigationMenu />}
                    </>
                }
                footer={<Footer />}
            >
                <DeprecatedPaper cssAnim position="relative">
                    <Switch>
                        <Route strict path="/app/user/" component={UserPage} />
                        <Route path="/app/show/:showId(\d+)/:tab?">
                            <Switch>
                                <Route path="/app/show/:showId/pso" component={PsoPage} />
                                <Route path="/app/show/:showId/settings" component={SettingsPage} />
                                {ENABLE_NON_HOSTING_EP_MGT && !showHasHostingAccess && (
                                    <Route exact path="/app/show/:showId/feed/:episodeId">
                                        <NonHostingEpisodePreviewPage />
                                    </Route>
                                )}
                                {ENABLE_NON_HOSTING_EP_MGT && !showHasHostingAccess && (
                                    <Route exact path="/app/show/:showId/feed">
                                        <EpisodeFiltersContextProvider>
                                            <NonHostingPublishedEpisodes />
                                        </EpisodeFiltersContextProvider>
                                    </Route>
                                )}
                                {ENABLE_NON_HOSTING_EP_MGT && !showHasHostingAccess && (
                                    <Route exact path="/app/show/:showId/workspace">
                                        <EpisodeFiltersContextProvider>
                                            <NonHostingDraftEpisodes />
                                        </EpisodeFiltersContextProvider>
                                    </Route>
                                )}
                                {showHasHostingAccess && (
                                    <Route exact path="/app/show/:showId/episodes/preview/:podId">
                                        <EpisodePreview />
                                    </Route>
                                )}
                                {showHasHostingAccess && (
                                    <Route exact path="/app/show/:showId/episodes/recap/:episodeId">
                                        <SummaryEpisodePage />
                                    </Route>
                                )}
                                {showHasHostingAccess && (
                                    <Route exact path="/app/show/:showId/episodes">
                                        <EpisodeFiltersContextProvider>
                                            <EpisodesPage />
                                        </EpisodeFiltersContextProvider>
                                    </Route>
                                )}
                                {showHasHostingAccess && (
                                    <ValidSubscriptionRoute
                                        strict
                                        path="/app/show/:showId/playlists/:playlistId?"
                                    >
                                        <PlaylistsPage />
                                    </ValidSubscriptionRoute>
                                )}
                                {showHasHostingAccess && (
                                    <ValidSubscriptionRoute
                                        path="/app/show/:showId/communication"
                                        component={CommunicationPage}
                                    />
                                )}
                                {showHasHostingAccess && (
                                    <Route path="/app/show/:showId/clips">
                                        <ClipsPage />
                                    </Route>
                                )}
                                {showHasHostingAccess && (
                                    <ValidSubscriptionRoute
                                        path="/app/show/:showId/stats"
                                        component={StatsPage}
                                    />
                                )}
                                {showHasHostingAccess && (
                                    <Route
                                        path="/app/show/:showId/monetize"
                                        component={MonetizePage}
                                    />
                                )}
                                <Route>
                                    <Redirect to={defaultRoute} />
                                </Route>
                            </Switch>
                        </Route>
                    </Switch>
                </DeprecatedPaper>
                <PaymentValidationRequiredBand />
            </MainLayout>
        </Switch>
    );
};

export default ApplicationPage;
