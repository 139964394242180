import { useState } from 'react';
import AccordionItem from '@ui/atoms/AccordionItem';
import EpisodeEditAccordionItem from '@/components/unorganized/EpisodeEditAccordionItem';
import { FormattedMessage } from 'react-intl';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';
import Accordion from '@ui/atoms/Accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EpisodeEditFormContent from '@/components/unorganized/EpisodeEditForm/EpisodeEditFormContent/EpisodeEditFormContent';
import EpisodeEditFormOptions from '@/components/unorganized/EpisodeEditForm/EpisodeEditFormOptions/EpisodeEditFormOptions';
import EpisodeEditFormPrivacy from '@/components/unorganized/EpisodeEditForm/EpisodeEditFormPrivacy/EpisodeEditFormPrivacy';
import EpisodeEditFormChapters from '@/components/unorganized/EpisodeEditForm/EpisodeEditFormChapters/EpisodeEditFormChapters';
import { useFormContext } from 'react-hook-form';
import { useEpisodeBeingEdited } from '@/context/EpisodeFormContext';
import { EPISODE_PRIVACY, EPISODE_STATUS } from '@/shared/config/constants';
import { useEpisodeEditAudioFileMutation } from '@/context/EpisodeEditContext';
import EpisodeEditFormNewsletter from '@/components/unorganized/EpisodeEditForm/EpisodeEditFormNewsletter/EpisodeEditFormNewsletter';
import EpisodeDeeplinkForm from '@/components/unorganized/EpisodeDeeplinkForm';
import { EpisodeEditFormNewsletterErrorsCountContext } from '@/components/unorganized/EpisodeEditForm/contexts/EpisodeEditFormNewsletterErrorsCountContext';
import ChaptersState from './ChaptersState';
import { useEpisodeAILoading } from '@/components/EpisodeAI/contexts/EpisodeAILoadingContext';

const EpisodeEditFormAccordion = () => {
    const episode = useEpisodeBeingEdited();
    const {
        formState: { errors },
    } = useFormContext();
    const episodeEditAudioFileMutation = useEpisodeEditAudioFileMutation();

    const hasAudioFile = episode.files.length > 0;
    const isUploadingAudioFile = episodeEditAudioFileMutation.isLoading;
    const [state] = useEpisodeAILoading();
    const isLoading = state.get('chapters') || false;

    const isNewsletterEnabled = episode.state !== EPISODE_STATUS.ONLINE;
    const isDeeplinkEnabled =
        episode.state === EPISODE_STATUS.ONLINE &&
        [EPISODE_PRIVACY.PUBLIC, EPISODE_PRIVACY.UNLISTED].includes(episode.privacy);
    const isChaptersEnabled = !isUploadingAudioFile && hasAudioFile && !isLoading;

    const [newsletterErrorsCount, setNewsletterErrorsCount] = useState(0);

    return (
        <EpisodeAccordion defaultIndex={0}>
            <AccordionItem>
                <EpisodeEditAccordionItem
                    label={<FormattedMessage defaultMessage="Contenu" />}
                    icon={
                        <AccordionItemIcon
                            icon={icon({ name: 'album-collection', style: 'solid' })}
                        />
                    }
                    errorsCount={errors.content ? Object.keys(errors.content).length : 0}
                    localStorageKey="content"
                >
                    <EpisodeEditFormContent />
                </EpisodeEditAccordionItem>
            </AccordionItem>
            <AccordionItem>
                <EpisodeEditAccordionItem
                    label={<FormattedMessage defaultMessage="Options" />}
                    icon={<AccordionItemIcon icon={icon({ name: 'sliders-up', style: 'solid' })} />}
                    errorsCount={errors.options ? Object.keys(errors.options).length : 0}
                    localStorageKey="options"
                >
                    <EpisodeEditFormOptions />
                </EpisodeEditAccordionItem>
            </AccordionItem>
            <AccordionItem>
                <EpisodeEditAccordionItem
                    label={<FormattedMessage defaultMessage="Confidentialité" />}
                    icon={
                        <AccordionItemIcon icon={icon({ name: 'lock-keyhole', style: 'solid' })} />
                    }
                    errorsCount={errors.privacy ? Object.keys(errors.privacy).length : 0}
                    localStorageKey="privacy"
                >
                    <EpisodeEditFormPrivacy />
                </EpisodeEditAccordionItem>
            </AccordionItem>
            <AccordionItem isDisabled={!isChaptersEnabled}>
                <EpisodeEditAccordionItem
                    label={<FormattedMessage defaultMessage="Chapitres" />}
                    icon={<AccordionItemIcon icon={icon({ name: 'bookmark', style: 'solid' })} />}
                    isOptional
                    errorsCount={errors.chapters ? Object.keys(errors.chapters).length : 0}
                    localStorageKey="chapters"
                    customContent={
                        <ChaptersState
                            hasAudioFile={hasAudioFile}
                            isUploadingAudioFile={isUploadingAudioFile}
                            isFetchingChaptersSuggestions={isLoading}
                        />
                    }
                >
                    <EpisodeEditFormChapters />
                </EpisodeEditAccordionItem>
            </AccordionItem>
            {isNewsletterEnabled && (
                <AccordionItem>
                    <EpisodeEditAccordionItem
                        label={<FormattedMessage defaultMessage="Newsletter" />}
                        icon={
                            <AccordionItemIcon
                                icon={icon({ name: 'envelope-open-text', style: 'solid' })}
                            />
                        }
                        isOptional
                        errorsCount={newsletterErrorsCount}
                        localStorageKey="newsletter"
                        data-testid="episode-accordion-newsletter"
                    >
                        <EpisodeEditFormNewsletterErrorsCountContext.Provider
                            value={{ setNewsletterErrorsCount }}
                        >
                            <EpisodeEditFormNewsletter />
                        </EpisodeEditFormNewsletterErrorsCountContext.Provider>
                    </EpisodeEditAccordionItem>
                </AccordionItem>
            )}
            {isDeeplinkEnabled && (
                <AccordionItem>
                    <EpisodeEditAccordionItem
                        label="Deeplink"
                        icon={<AccordionItemIcon icon={icon({ name: 'link', style: 'solid' })} />}
                        isOptional
                        // TODO Handle errorsCount
                        localStorageKey="deeplink"
                    >
                        <EpisodeDeeplinkForm />
                    </EpisodeEditAccordionItem>
                </AccordionItem>
            )}
        </EpisodeAccordion>
    );
};

const AccordionItemIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--neutral500);
`;
const EpisodeAccordion = styled(Accordion)`
    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        padding-bottom: 2rem;
    }
`;

export default EpisodeEditFormAccordion;
