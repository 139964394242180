import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import useSubscriptionQuery from '@/queries/subscription/useSubscriptionQuery.hook';
import { PRICING, PRICING_NAME } from '@/utils/pricing';
import Button from '@ui/atoms/Button';
import Link from '@ui/atoms/Link';
import useInternalRouteMapping from '@hooks/useInternalRouteMapping.hook';
import GroundIllustration from '@public/images/ground.webp';
import MicrophoneIllustration from '@public/images/microphone.webp';
import PlaneIllustration from '@public/images/plane.webp';
import SatelliteIllustration from '@public/images/satellite.webp';
import SuitcaseIllustration from '@public/images/suitcase.webp';
import { formatPrice } from '@/helpers';
import styled from 'styled-components';

const CurrentPlan = () => {
    const { data } = useSubscriptionQuery();
    const offersPage = useInternalRouteMapping().link('offers');

    const illustrationLookup = {
        [PRICING.LAUNCH]: {
            path: MicrophoneIllustration,
            tilt: 15,
            offsetRight: '1rem',
        },
        [PRICING.BOOST]: {
            path: PlaneIllustration,
            tilt: 10,
            offsetRight: '2rem',
            offsetTop: '0',
        },
        [PRICING.ENTERPRISE]: {
            path: SuitcaseIllustration,
            tilt: -15,
            offsetRight: '2rem',
        },
        [PRICING.SUPERSONIC]: {
            path: SatelliteIllustration,
            tilt: -10,
            offsetRight: '2rem',
        },
    };

    const userPricing = data?.pricing;
    const priceInt = data?.prices?.base ? data?.prices?.base.replace(/\D/g, '') : 0;
    const currency = data?.prices?.base.match(/[\$\€\£]/)?.[0] || '$';

    if (!data) return null;
    return (
        <CurrentPlanWrapper>
            <MainContent>
                <BoxTitleWrapper>
                    <Rocket icon={icon({ name: 'rocket-launch', style: 'solid' })} />
                    <BoxTitle>
                        <FormattedMessage defaultMessage="Offre" />
                    </BoxTitle>
                </BoxTitleWrapper>
                {userPricing && (
                    <PlanTextWrapper>
                        <PlanName>
                            {userPricing === PRICING.ENTERPRISE ? (
                                <FormattedMessage defaultMessage="Pro" />
                            ) : (
                                PRICING_NAME[userPricing] || ''
                            )}
                        </PlanName>
                        <PlanPrice>
                            {userPricing === PRICING.ENTERPRISE ? (
                                <FormattedMessage defaultMessage="Personnalisé" />
                            ) : (
                                <FormattedMessage
                                    defaultMessage="{price} /{periodicity}"
                                    values={{
                                        periodicity: data.yearly ? (
                                            <FormattedMessage defaultMessage="an" />
                                        ) : (
                                            <FormattedMessage defaultMessage="mois" />
                                        ),
                                        price: formatPrice(priceInt, currency),
                                    }}
                                />
                            )}
                        </PlanPrice>
                    </PlanTextWrapper>
                )}
                {userPricing !== PRICING.ENTERPRISE && (
                    <ActionWrapper>
                        {/* @ts-ignore */}
                        <Button as={Link} to={offersPage}>
                            <FormattedMessage defaultMessage="Changer d'offre" />
                        </Button>
                    </ActionWrapper>
                )}
            </MainContent>
            <IllustrationWrapper>
                {userPricing && (
                    <MainIllustration
                        illustration={illustrationLookup[userPricing].path || null}
                        tilt={illustrationLookup[userPricing].tilt}
                        offsetRight={illustrationLookup[userPricing].offsetRight}
                        offsetTop={illustrationLookup[userPricing].offsetTop}
                    />
                )}
                <GroundWrapper>
                    <Ground />
                </GroundWrapper>
            </IllustrationWrapper>
        </CurrentPlanWrapper>
    );
};

const CurrentPlanWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    background-color: var(--white);
    border-radius: var(--r-l);
    min-height: 15rem;
`;
const MainContent = styled.div`
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(50% - 3rem);
    padding-block: 1.5rem;
    padding-left: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
`;
const BoxTitleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;
const Rocket = styled(FontAwesomeIcon)`
    font-size: 1rem;
    color: var(--primary);
`;
const BoxTitle = styled.div`
    font-weight: var(--fw-semibold);
`;
const PlanTextWrapper = styled.div``;
const PlanName = styled.div`
    font-size: var(--fs-heading-xl);
    font-weight: var(--fw-bold);
`;
const PlanPrice = styled.div`
    color: var(--neutral500);
    font-size: var(--fs-heading-s);
`;
const ActionWrapper = styled.div``;
const IllustrationWrapper = styled.div`
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: 50%;
    position: relative;

    @media (max-width: 1000px) {
        display: none;
    }
`;
const MainIllustration = styled.div<{
    illustration: string | null;
    tilt: number;
    offsetRight: string;
    offsetTop?: string;
}>`
    position: absolute;
    top: ${({ offsetTop }) => offsetTop || '-1rem'};
    right: ${({ offsetRight }) => offsetRight || '0'};
    width: 9rem;
    height: 9rem;
    background-image: url(${({ illustration }) => illustration});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
    border-top-right-radius: var(--r-l);
    transform: rotate(${({ tilt }) => tilt}deg);
`;
const GroundWrapper = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 2rem;
    border-bottom-right-radius: var(--r-l);
    overflow: hidden;
`;
const Ground = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-image: url(${GroundIllustration});
    background-size: cover;
    background-position: top left;
`;

export default CurrentPlan;
