import { z } from 'zod';
import { useIntl } from 'react-intl';
import { FORM_VALIDATION_MESSAGE } from '@/shared/services/validationMessageService';
import { checkImgSize, experimentalCheckImgDimensions } from '@/shared/utils/file';

const useShowDetailsFormSchema = (locale = 'en') => {
    const intl = useIntl();
    const acceptedMimes = ['image/jpg', 'image/jpeg', 'image/png'];
    const slugRegex = /^[a-zA-Z0-9-À-ÿ]+$/;

    return z.object({
        name: z
            .string({
                required_error: intl.formatMessage(FORM_VALIDATION_MESSAGE.required),
            })
            .min(3, intl.formatMessage(FORM_VALIDATION_MESSAGE.minLength, { min: 3 }))
            .max(140, intl.formatMessage(FORM_VALIDATION_MESSAGE.maxLength, { max: 140 })),
        author: z
            .string()
            .max(255, intl.formatMessage(FORM_VALIDATION_MESSAGE.maxLength, { max: 255 })),
        htmlDescription: z
            .string()
            .max(3900, intl.formatMessage(FORM_VALIDATION_MESSAGE.maxLength, { max: 3900 }))
            .optional()
            .nullable(),
        imageUrl: z
            .union([
                z
                    .instanceof(File)
                    .refine((file: File) => acceptedMimes.includes(file.type), {
                        message: intl.formatMessage(FORM_VALIDATION_MESSAGE.acceptedMimes, {
                            acceptedMimes,
                        }) as string,
                    })
                    .refine((file: File) => checkImgSize(file, locale, 10), {
                        message: intl.formatMessage(FORM_VALIDATION_MESSAGE.imgMaxSize, {
                            max: 10,
                        }) as string,
                    })
                    .refine((file: File) => experimentalCheckImgDimensions(file), {
                        message: intl.formatMessage(FORM_VALIDATION_MESSAGE.imgMinDimensions, {
                            minWidth: 400,
                            minHeight: 400,
                        }) as string,
                    }),
                z.string(),
            ])
            .optional()
            .nullable(),
        languageId: z.number({
            required_error: intl.formatMessage(FORM_VALIDATION_MESSAGE.required),
        }),
        categoryId: z.number({
            required_error: intl.formatMessage(FORM_VALIDATION_MESSAGE.required),
        }),
        secondaryCategoryId: z.number().nullable(),
        slug: z
            .string()
            .regex(
                new RegExp(slugRegex),
                intl.formatMessage(FORM_VALIDATION_MESSAGE.noSpecialCharacters),
            )
            .min(1, intl.formatMessage(FORM_VALIDATION_MESSAGE.required)),
        descriptionFooter: z
            .string()
            .max(3900, intl.formatMessage(FORM_VALIDATION_MESSAGE.maxLength, { max: 3900 }))
            .optional()
            .nullable(),
        tags: z.array(z.string()),
        type: z.enum(['episodic', 'serial'] as const),
        publicCounts: z.boolean(),
    });
};

export type ShowDetailsFormSchema = z.infer<ReturnType<typeof useShowDetailsFormSchema>>;

export default useShowDetailsFormSchema;
