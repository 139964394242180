import { createApi } from '../callApi';
const contentApi = createApi('CONTENT');

export const fetchChapters = (podcast) => {
    return contentApi({ method: 'GET', target: `/v1/podcasts/${podcast}/chapters` });
};
export const experimentalUploadChapterImage = (chapterId, image) => {
    const body = new FormData();
    body.append('file', image);
    return contentApi({
        method: 'POST',
        target: `/v1/chapters/${chapterId}/image`,
        body,
    });
};
export const deleteChapterImage = (chapter) => {
    return contentApi({ method: 'DELETE', target: `/v1/chapters/${chapter}/image` });
};
export const updateChapters = (podcast, body) => {
    return contentApi({ method: 'POST', target: `/v1/podcasts/${podcast}/chapters`, body });
};
