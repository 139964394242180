import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateWebhooks } from '@/api';
import webhookKeys from './webhookKeys';
import camelcaseKeys from 'camelcase-keys';

interface MutationFnProps {
    showId: string;
    payload: {
        id: number | null;
        url: string;
    }[];
}

const mutationFn = async ({ showId, payload }: MutationFnProps) => {
    const { data } = await updateWebhooks(showId, payload);
    return camelcaseKeys(data, { deep: true });
};

const useUpdateWebhooksMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn,
        onSuccess: (_, { showId }) => {
            queryClient.invalidateQueries(webhookKeys.listByShowId(showId));
        },
    });
};

export default useUpdateWebhooksMutation;
