import Text from '@/components/ui/atoms/Text';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { formatSecondsDuration, sendAmplitudeLogEvent } from '@/helpers';
import { NonHostingEpisodeFormSchema } from '../../useNonHostingEpisodeFormSchema.hook';
import Snippet from '../Snippet';

const ChaptersSummary = () => {
    const { getValues } = useFormContext<NonHostingEpisodeFormSchema>();
    const chapters = getValues('chapters');
    const formattedChapters = chapters.map((chapter) => {
        return `(${formatSecondsDuration(chapter.startTime)}) - ${chapter.title}`;
    });

    if (!chapters.length) return null;

    const handleCopy = () => {
        sendAmplitudeLogEvent('Copy Episode Chapters');
    };

    return (
        <Wrapper>
            <Text variant="bodyL" fontWeight="--fw-semibold">
                <FormattedMessage defaultMessage="Chapitres" />
            </Text>
            <Snippet onCopy={handleCopy} valueToCopy={formattedChapters.join('\n')}>
                <Chapters>
                    {formattedChapters.map((chapter, index) => (
                        <Text key={index}>{chapter}</Text>
                    ))}
                </Chapters>
            </Snippet>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
`;
const Chapters = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;
`;

export default ChaptersSummary;
