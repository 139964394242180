import EpisodeEditResume from '@/components/unorganized/EpisodeEditResume';
import EpisodeEditResumeQuotaAlert from '@/components/unorganized/EpisodeEditResumeQuotaAlert';
import EpisodeEditTranscription from '@/components/unorganized/EpisodeEditTranscription';
import { USER_ROLES } from '@/shared/config/constants';
import useSubscriptionQuery from '@queries/subscription/useSubscriptionQuery.hook';
import { useEpisodeBeingEdited } from '@/context/EpisodeFormContext';
import styled from 'styled-components';
import useHasAccessToTranscription from '@/shared/hooks/useHasAccessToTranscription.hook';
import { useParams } from 'react-router';

const InformationsTab = () => {
    const { showId } = useParams<{ showId: string }>();
    const { data: subscription } = useSubscriptionQuery({ staleTime: 0 });
    const episode = useEpisodeBeingEdited();
    const hasAccessToTranscription = useHasAccessToTranscription({ showId });

    return (
        <StickyContainer>
            {subscription?.isFreemium && episode?.show?.userRole === USER_ROLES.OWNER && (
                <EpisodeEditResumeQuotaAlert />
            )}
            <EpisodeEditResume />
            {hasAccessToTranscription && <EpisodeEditTranscription />}
        </StickyContainer>
    );
};

const StickyContainer = styled.div`
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
`;

export default InformationsTab;
