import { action, computed, runInAction } from 'mobx';
import { FormattedMessage } from 'react-intl';
import Store from './Store';
import { apiCall, apiFetch } from '@/components/legacy/api';
import { fetchPrices, fetchSubscription, reactivateSubscription, resumeSubscription } from '@/api';
import SubscriptionModel from '../models/SubscriptionModel';
import { priceValueToInt } from '@/helpers';

class SubscriptionStore extends Store {
    static observables = {
        subscription: null,
        pricesByCurrency: null,
        isPaymentValidationErrorModalOpened: false,
    };

    /**
     * Returns prices for current subscription currency, pricing and periodicity
     *
     * @example
     * this.pricesOfCurrentSubscription;
     * { base: "684 £GB", extra: "240 £GB" }
     */
    @computed
    get pricesOfCurrentSubscription() {
        const currentSubscription = this.subscription;
        if (!currentSubscription) {
            return null;
        }
        if (!this.pricesOfCurrentSubscriptionPricing) {
            return null;
        }
        return currentSubscription.yearly
            ? this.pricesOfCurrentSubscriptionPricing.yearly
            : this.pricesOfCurrentSubscriptionPricing.monthly;
    }

    /**
     * Fetch and store prices for all currencies, pricing/options and periodicity
     */
    @apiFetch
    async fetchPrices() {
        const { axios, ...pricesByCurrency } = await fetchPrices();
        runInAction(() => {
            this.pricesByCurrency = pricesByCurrency;
        });
    }

    // API

    @apiFetch
    async fetchSubscription() {
        const { data } = await fetchSubscription();
        runInAction(() => {
            this.subscription = new SubscriptionModel(this, data);
        });
    }

    @apiCall
    async resume(subscription) {
        const { data } = await resumeSubscription();
        subscription.updateData(data);
    }

    @apiCall
    async reactivate(subscription, stripe) {
        try {
            const { data } = await reactivateSubscription();
            subscription.updateData(data);
        } catch (error) {
            // If 3D Secure 2 is required, response status should be 402 Payment Required
            if (error.response?.status !== 402) {
                throw error;
            }

            if (
                error?.response?.data?.client_secret === undefined &&
                error?.response?.data?.message !== undefined
            ) {
                throw new Error(error.response.data.message);
            }

            const { error: errorPaymentValidation } = await this.confirmStripePayment(
                error?.response?.data?.client_secret,
                stripe,
            );

            if (errorPaymentValidation) {
                this.showPaymentValidationErrorModal(true);
            } else {
                const { data } = await fetchSubscription();
                subscription.updateData(data);
            }
        }
    }

    async handlePaymentValidation(subscription, stripe) {
        if (subscription?.intentSecret) {
            const { error } = await this.confirmStripePayment(subscription.intentSecret, stripe);

            if (error) {
                this.showPaymentValidationErrorModal(true);
            }

            const { data } = await fetchSubscription();
            runInAction(() => {
                subscription.updateData(data);
            });
        }
    }

    @apiCall
    confirmStripePayment(intentSecret, stripe) {
        // confirmCardPayment function will open a popup to let user authenticate with 3D Secure 2
        // See https://stripe.com/docs/stripe-js/reference#stripe-confirm-card-payment
        return stripe.confirmCardPayment(intentSecret);
    }

    @action.bound
    addSubscriptionDataToGoogleTagManager(subscription) {
        const priceBaseAmount = priceValueToInt(subscription.priceBase);
        const priceExtraAmount = subscription.priceExtra
            ? priceValueToInt(subscription.priceExtra)
            : 0;
        const priceDiscountAmount = subscription.discount
            ? priceValueToInt(subscription.discount)
            : 0;
        const priceTotalAmount = priceBaseAmount + priceExtraAmount - priceDiscountAmount;

        // eslint-disable-next-line no-undef
        if (dataLayer) {
            // eslint-disable-next-line no-undef
            dataLayer.push({
                ecommerce: {
                    purchase: {
                        actionField: {
                            id: subscription.id,
                            revenue: `${priceTotalAmount}`,
                        },
                        products: [
                            {
                                name: subscription.subscriptionPricing,
                                price: this.pricesOfCurrentSubscription
                                    ? `${priceValueToInt(this.pricesOfCurrentSubscription.base)}`
                                    : '0',
                                quantity: 1,
                            },
                        ],
                    },
                },
                event: 'validatedTransaction',
            });
        }
    }

    @computed
    get getSubscription() {
        const paymentMethod = this?.state.subscriptionStore.subscription.yearly
            ? 'yearly'
            : 'monthly';
        return {
            offer: this?.state.subscriptionStore.subscription.subscriptionPricing,
            currentSubscription: paymentMethod,
            currentMethodPayment:
                paymentMethod === 'yearly' ? (
                    <FormattedMessage defaultMessage="an" />
                ) : (
                    <FormattedMessage defaultMessage="mois" />
                ),
        };
    }
}

export default SubscriptionStore;
