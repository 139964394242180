import { FormattedMessage } from 'react-intl';
import Text from '@ui/atoms/Text';
import { SALES_EMAIL } from '@/utils/constants';
import styled from 'styled-components';

function TopbarUnpaidInvoiceWarningBand() {
    return (
        <TopbarUnpaidInvoiceWarningBandWrapper>
            <Text color="white" textAlign="center">
                <FormattedMessage
                    defaultMessage="<p>Nous avons remarqué qu’une de vos factures est actuellement en attente de paiement. Afin de continuer pleinement de votre expérience, nous vous remercions de procéder à son règlement.</p> <p>N’hésitez pas à <link>nous contacter</link> si besoin 💌.</p>"
                    values={{
                        p: (chunks) => <p>{chunks}</p>,
                        link: (chunks) => (
                            <Text
                                as="a"
                                fontWeight="--fw-semibold"
                                color="--white"
                                href={`mailto:${SALES_EMAIL}`}
                                target="_blank"
                            >
                                {chunks}
                            </Text>
                        ),
                    }}
                />
            </Text>
        </TopbarUnpaidInvoiceWarningBandWrapper>
    );
}

const TopbarUnpaidInvoiceWarningBandWrapper = styled.div`
    min-height: 2rem;
    background: var(--info);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
`;

export default TopbarUnpaidInvoiceWarningBand;
