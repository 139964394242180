import { FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import useEmailNotificationSettingsFormSchema from './useEmailNotificationSettingsFormSchema.hook';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@ui/atoms/Button';
import { ToggleController } from '@ui/atoms/Toggle';

const EmailNotificationSettingsForm = ({ onSubmit, isLoading, defaultValues }) => {
    const schema = useEmailNotificationSettingsFormSchema();
    const { control, handleSubmit, formState } = useForm({
        mode: 'onChange',
        resolver: yupResolver(schema),
        defaultValues,
    });

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <ToggleController name="scheduledPodcast" control={control}>
                <FormattedMessage defaultMessage="Publication d’un épisode programmé" />
            </ToggleController>
            <ToggleController name="youtubeGenerated" control={control}>
                <FormattedMessage defaultMessage="Génération d’une vidéo YouTube" />
            </ToggleController>
            <SubmitButton type="submit" isLoading={isLoading} isDisabled={!formState.isDirty}>
                <FormattedMessage defaultMessage="Enregistrer" />
            </SubmitButton>
        </Form>
    );
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    height: 100%;
`;
const SubmitButton = styled(Button)`
    margin-top: 1rem;
    width: fit-content;
`;

export default EmailNotificationSettingsForm;
