import { useMutation } from '@tanstack/react-query';
import { deleteUploadedMessageImage } from '@/api';
import camelcaseKeys from 'camelcase-keys';

const mutationFn = async (messageId: number): Promise<any> => {
    const { data } = await deleteUploadedMessageImage(messageId);

    return camelcaseKeys(data as any, { deep: true });
};

const deleteNewsletterImageMutation = () => {
    return useMutation({
        mutationFn,
    });
};

export default deleteNewsletterImageMutation;
