import { QUOTA_TYPES } from '@/shared/config/constants';

const quotaKeys = {
    all: () => [{ entity: 'quota' }],
    allDetails: () => [{ ...quotaKeys.all()[0], scope: 'detail' }],
    detailByType: (type: keyof typeof QUOTA_TYPES) => [{ ...quotaKeys.allDetails()[0], type }],
    detailByShowAndType: (type: keyof typeof QUOTA_TYPES, rawShowId: string) => [
        { ...quotaKeys.detailByType(type)[0], showId: Number(rawShowId) },
    ],
    priceByType: (type: keyof typeof QUOTA_TYPES, quantity: number) => [
        { ...quotaKeys.detailByType(type)[0], scope: 'price', quantity },
    ],
    subscriptionPricePreview: ({ quantity }: { quantity: number }) => [
        { ...quotaKeys.all(), scope: 'subscriptionPricePreview', quantity },
    ],
    newsletterPricePreview: ({ quantity }: { quantity: number }) => [
        { ...quotaKeys.all(), scope: 'newsletterPricePreview', quantity },
    ],
};

export default quotaKeys;
