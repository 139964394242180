import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Text from '@ui/atoms/Text';
import QuotaCard from '../QuotaCard';
import useNewsletterQuotaQuery from '@queries/quota/useNewsletterQuotaQuery.hook';
import useSubscriptionQuery from '@/queries/subscription/useSubscriptionQuery.hook';
import useInternalRouteMapping from '@hooks/useInternalRouteMapping.hook';
import NewsletterQuotaModal from './NewsletterQuotaModal';
import NewsletterQuotaConfirmationModal from './NewsletterQuotaConfirmationModal';
import useUpdateNewsletterQuotaMutation from '@/queries/quota/useUpdateNewsletterQuotaMutation.hook';
import ExternalLink from '@/components/Link/ExternalLink';
import { useBodyToastQueue } from '@/utils/hooks/useBodyToastQueue.hook';
import styled from 'styled-components';

const NewsletterEmailsQuota = () => {
    const intl = useIntl();
    const toast = useBodyToastQueue();
    const { push } = useInternalRouteMapping();
    const subscription = useSubscriptionQuery();
    const [quota, setQuota] = useState<null | number>(null);
    const [isQuotaModalOpen, setIsQuotaModalOpen] = useState(false);
    const [isQuotaConfirmationModalOpen, setIsQuotaConfirmationModalOpen] = useState(false);
    const { data: newsletterQuota } = useNewsletterQuotaQuery();
    const updateNewsletterQuota = useUpdateNewsletterQuotaMutation();

    const isFreemium = subscription?.data?.isFreemium;
    const actionLabel = isFreemium ? (
        <FormattedMessage defaultMessage="Changer d'offre" />
    ) : (
        <FormattedMessage defaultMessage="Modifier la limite" />
    );

    const onSubmit = (quota: number) => {
        if (!quota) return;
        updateNewsletterQuota.mutate(
            { quantity: quota },
            {
                onSuccess: () => {
                    toast.success(
                        <FormattedMessage defaultMessage="Votre quota d'emails a été mis à jour" />,
                    );
                },
                onError: () => {
                    toast.alert(
                        <FormattedMessage defaultMessage="Une erreur est survenue et votre quota n'a pas été modifié, veuillez réessayer plus tard" />,
                    );
                },
                onSettled: () => {
                    setIsQuotaConfirmationModalOpen(false);
                },
            },
        );
    };

    return (
        <>
            <QuotaCard
                titleIcon={
                    <FontAwesomeIcon icon={icon({ name: 'envelope-open-text', style: 'solid' })} />
                }
                title={<FormattedMessage defaultMessage="Newsletter" />}
                currentValue={newsletterQuota?.current || 0}
                maxValue={newsletterQuota?.maximum || 0}
                valueUnit={<FormattedMessage defaultMessage="e-mails" />}
                actionLabel={actionLabel}
                onActionTriggered={() => (isFreemium ? push('offers') : setIsQuotaModalOpen(true))}
                infoTooltipContent={
                    <Text>
                        <FormattedMessage
                            defaultMessage="Votre quota d’emails envoyés à vos abonnés est réinitialisé chaque 1er du mois. <link>En savoir plus sur la Newsletter</link>"
                            values={{
                                link: (chunks: React.ReactNode) => (
                                    <StyledExternalLink
                                        href={intl.formatMessage({
                                            defaultMessage:
                                                'https://help.ausha.co/fr/articles/8061254-comment-envoyer-et-modifier-la-newsletter-d-un-episode',
                                        })}
                                        target="_blank"
                                    >
                                        {chunks}
                                    </StyledExternalLink>
                                ),
                            }}
                        />
                    </Text>
                }
            />
            <NewsletterQuotaModal
                isOpen={isQuotaModalOpen}
                onOpenChange={setIsQuotaModalOpen}
                newsletterQuota={newsletterQuota}
                onSubmit={({ quota }: { quota: number }) => {
                    setIsQuotaModalOpen(false);
                    setQuota(quota);
                    setIsQuotaConfirmationModalOpen(true);
                }}
            />
            {quota && (
                <NewsletterQuotaConfirmationModal
                    isOpen={isQuotaConfirmationModalOpen}
                    onOpenChange={setIsQuotaConfirmationModalOpen}
                    isLoading={false}
                    onSubmit={onSubmit}
                    quantity={quota}
                />
            )}
        </>
    );
};

const StyledExternalLink = styled(ExternalLink)`
    color: inherit;
    font-size: inherit;
    text-decoration: underline;
    font-weight: var(--fw-semibold);

    &:hover {
        color: inherit;
    }
    &:active {
        color: inherit;
    }
    &:visited {
        color: inherit;
    }
`;

export default NewsletterEmailsQuota;
