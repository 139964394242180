import { useFormContext } from 'react-hook-form';
import { InputImgUploaderController } from '@/components/ui/molecules/InputImgUploader';
import { FormattedMessage } from 'react-intl';
import EpisodeEditTooltip from '@/components/unorganized/EpisodeEditTooltip';
import Text from '@ui/atoms/Text';

const EpisodeEditFormContentImageField = () => {
    const { control } = useFormContext();

    return (
        <InputImgUploaderController
            name="content.imageUrl"
            control={control}
            label={<FormattedMessage defaultMessage="Pochette d'épisode" />}
            tooltip={
                <EpisodeEditTooltip>
                    <FormattedMessage defaultMessage="Ajouter une pochette différente de celle de l’émission spécifiquement pour cet épisode. En l’absence de pochette d'épisode, celle de l’émission sera utilisée par défaut." />
                </EpisodeEditTooltip>
            }
            description={
                <Text color="--neutral500">
                    <FormattedMessage
                        defaultMessage=".JPG, ou .PNG, jusqu’à 10Mo{break}400 x 400px min. (1400 x 1400px recommandé)"
                        values={{ break: <br /> }}
                    />
                </Text>
            }
        />
    );
};

export default EpisodeEditFormContentImageField;
