import { useController } from 'react-hook-form';
import ComboBox from '@ui/atoms/ComboBox/ComboBox';

// TODO: Update type once the ComboBox component is typed

interface ComboBoxControllerProps<T> {
    control: any;
    name: string;
    label?: string | React.ReactNode;
    onChange?: (value: string) => void;
    onBlur?: (value: string) => void;
    selectedKey?: string | number;
    defaultSelectedKey?: string | number;
    items?: T;
    defaultItems?: T[];
    isLoading?: boolean;
    isRequired?: boolean;
    icon?: string | React.ReactNode;
    inputHasImage?: boolean;
    onSelectionChange?: (id: string) => void;
    onInputChange?: (value: string) => void;
    onLoadMore?: () => void;
    onBlurFromSearchInput?: () => void;
    loadMoreEnabled?: boolean;
    tooltip?: string | React.ReactNode;
    children?: React.ReactNode | React.ReactNode[];
    [key: string]: any;
}

export const ComboBoxController = <T,>(props: ComboBoxControllerProps<T>) => {
    const { control, name } = props;
    const { field } = useController({
        name,
        control,
    });

    return (
        // @ts-ignore
        <ComboBox
            {...props}
            label={props.label || null}
            isRequired={props.isRequired || false}
            selectedKey={field.value}
            isLoading={props.isLoading || false}
            icon={props.icon || null}
            inputHasImage={props.inputHasImage || false}
            onSelectionChange={(id: string) => {
                props.onSelectionChange && props.onSelectionChange(id);
                field.onChange(id);
            }}
            onInputChange={(value: string) => {
                props.onInputChange && props.onInputChange(value);
            }}
            onLoadMore={() => {
                props.onLoadMore && props.onLoadMore();
            }}
            onBlurFromSearchInput={() =>
                props.onBlurFromSearchInput && props.onBlurFromSearchInput()
            }
            loadMoreEnabled={props.loadMoreEnabled || false}
            tooltip={props.tooltip || null}
        />
    );
};
