import { useState } from 'react';
import DropdownMenu from '@/components/ui/molecules/DropdownMenu';
import Button from '@/components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import ReactCountryFlag from 'react-country-flag';
import COUNTRIES_LOOKUP from '@public/geojson/countries.json';
import { FormattedMessage } from 'react-intl';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';
import { TooltipTrigger } from 'react-aria-components';
import Text from '@/components/ui/atoms/Text';
import styled, { css } from 'styled-components';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import { useParams } from 'react-router-dom';

export interface PsoStoreSelectProps {
    currentStore: string;
    stores: string[];
    setCurrentStore: (store: string) => void;
    editStores: () => void;
    userHasWriteAccess: boolean;
}

const PsoStoreSelect = ({
    currentStore,
    stores,
    setCurrentStore,
    editStores,
    userHasWriteAccess,
}: PsoStoreSelectProps) => {
    const [opened, setOpened] = useState(false);
    const { showId }: { showId: string } = useParams();
    const { data: show } = useShowQuery(showId);

    const items = [
        ...stores
            .filter((store) => store !== currentStore)
            .map((store) => ({
                id: store,
                label: (
                    <MenuItemInner>
                        <ReactCountryFlag countryCode={store} svg />
                        {COUNTRIES_LOOKUP[store as keyof typeof COUNTRIES_LOOKUP]?.label}
                    </MenuItemInner>
                ),
                onClick: () => {
                    setCurrentStore(store);
                    setOpened(false);
                },
            })),
        'empty',
        {
            id: 'manage',
            label: (
                <TooltipTrigger delay={0} closeDelay={0}>
                    <TooltipTriggerWrapper>
                        <MenuItemInner
                            isDisabled={!userHasWriteAccess || show?.archived}
                            onClick={() => {
                                if (!userHasWriteAccess || show?.archived) return;
                                editStores();
                                setOpened(false);
                            }}
                        >
                            <FontAwesomeIcon
                                icon={icon({ name: 'pen-to-square', style: 'solid' })}
                                color="inherit"
                                size="xs"
                            />
                            <Text>
                                <FormattedMessage defaultMessage="Modifier les pays" />
                            </Text>
                        </MenuItemInner>
                    </TooltipTriggerWrapper>
                    <Tooltip placement="bottom" passthrough={userHasWriteAccess}>
                        <Text>
                            <FormattedMessage defaultMessage="Vous devez être propriétaire de l'émission pour pouvoir configurer le PSO Control Panel." />
                        </Text>
                    </Tooltip>
                </TooltipTrigger>
            ),
            onClick: () => {
                if (!userHasWriteAccess || show?.archived) return;
                editStores();
                setOpened(false);
            },
        },
    ];

    return (
        <DropdownMenu
            // @ts-ignore
            menuItems={items}
            direction="left"
            opened={opened}
            onToggle={() => {
                setOpened(!opened);
            }}
            onClose={() => setOpened(false)}
        >
            {/* @ts-ignore */}
            <DropdownButton
                variant="tertiary"
                startIcon={<ReactCountryFlag countryCode={currentStore} svg />}
                endIcon={
                    <FontAwesomeIcon
                        icon={icon({ name: 'chevron-down', style: 'solid' })}
                        size="xs"
                    />
                }
            >
                <CountryName>
                    {COUNTRIES_LOOKUP[currentStore as keyof typeof COUNTRIES_LOOKUP]?.label}
                </CountryName>
            </DropdownButton>
        </DropdownMenu>
    );
};

const DropdownButton = styled(Button)`
    width: 15rem;
    justify-content: flex-start;
    color: var(--neutral500);
    font-weight: var(--fw-normal);

    & > span {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }

    & > svg {
        margin-left: auto;
    }
`;
const MenuItemInner = styled.div<{ isDisabled?: boolean }>`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: var(--fw-normal);

    ${({ isDisabled }) =>
        isDisabled &&
        css`
            color: var(--neutral300);
        `};
`;
const CountryName = styled.span`
    color: var(--black);
`;

export default PsoStoreSelect;
