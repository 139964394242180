import { useState, useMemo } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { useCycle } from 'framer-motion';
import Portal from '../Portal';
import DeprecatedIcon from '../../atoms/DeprecatedIcon';
import CloseBackIcon from '@public/icons/close.svg';
import ArrowIcon from '@public/icons/chevrondown.svg';
import DeprecatedWhitePaper from '../../atoms/DeprecatedWhitePaper';
import MobileOrTablet from '../MobileOrTablet';
import DeprecatedPaper from '../../atoms/DeprecatedPaper';
import DeprecatedText from '../../atoms/DeprecatedText';
import MobileTabsItem from '../../atoms/MobileTabsItem/MobileTabsItem';
import styled from 'styled-components';

const MobileTabs = ({ pageUrl, redirectTab = [], tabsTitles }) => {
    const { tab } = useParams();
    const { params } = useRouteMatch();
    const isShowParams = Object.getOwnPropertyNames(params).length < 3;
    const [open, opened] = useCycle(false, true);
    const [currentParams, setCurrentParams] = useState({});
    useMemo(() => setCurrentParams(params), [params.itemType]);

    console.log(tabsTitles, tab);

    return (
        <MobileOrTablet>
            <Tabs onClick={() => opened(1)}>
                <DeprecatedText size="big" color="--primary" weight="semibold" flex>
                    {tabsTitles?.[tab] || ''}
                    <DeprecatedIcon ml={5} as={ArrowIcon} color="--primary" />
                </DeprecatedText>
            </Tabs>
            {open && (
                <Portal id="mobileTabs" key="mobileTabs">
                    <DeprecatedWhitePaper
                        bb={1}
                        rounded={false}
                        w="100%"
                        pos="fixed"
                        y={0}
                        z={1001}
                        px={30}
                        py={20}
                        flex
                        align="center"
                    >
                        <DeprecatedIcon
                            as={CloseBackIcon}
                            onClick={() => opened(0)}
                            color="black"
                        />
                    </DeprecatedWhitePaper>
                    <DeprecatedPaper
                        key={0}
                        pos="fixed"
                        y={51}
                        x={0}
                        w="100vw"
                        h="100vh"
                        p={30}
                        z={1001}
                        background="white"
                        raise="normal"
                        overflowY="scroll"
                        pb={200}
                        as="ul"
                        css="list-style: none;"
                    >
                        {Object.keys(tabsTitles).map((tabLink) => (
                            <MobileTabsItem
                                isSelected={tabLink !== tab}
                                onClick={() => opened(0)}
                                key={tabLink}
                                to={
                                    isShowParams || redirectTab.includes(tabLink)
                                        ? `${pageUrl}/${tabLink}`
                                        : `${pageUrl}/${currentParams.itemType}/${currentParams.itemId}/${tabLink}`
                                }
                            >
                                {tabsTitles[tabLink]}
                            </MobileTabsItem>
                        ))}
                    </DeprecatedPaper>
                </Portal>
            )}
        </MobileOrTablet>
    );
};

const Tabs = styled.div`
    width: 100%;
    padding-top: 1rem;
    display: flex;
    justify-content: flex-end;
`;

export default MobileTabs;
