import { useState } from 'react';
import { Button, DialogTrigger, Popover } from 'react-aria-components';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import MenuDropdown from '@ui/atoms/MenuDropdown';
import MenuDropdownItem from '@ui/atoms/MenuDropdown/MenuDropdownItem';
import { FormattedMessage } from 'react-intl';
import Text from '@ui/atoms/Text';
import EpisodeEditTranscriptionDeleteModal from './EpisodeEditTranscriptionDeleteModal';
import useDeleteEpisodeTranscriptMutation from '@queries/episodeTranscript/useDeleteEpisodeTranscriptMutation.hook';
import { useParams } from 'react-router-dom';
import EpisodeEditTranscriptionExportModal from './EpisodeEditTranscriptionExportModal';
import UpgradeBadge from '@ui/atoms/UpgradeBadge';
import { PRICING } from '@/shared/config/pricing';
import useShowQuery from '@queries/show/useShowQuery.hook';
import { USER_ROLES } from '@/shared/config/constants';
import useSubscriptionQuery from '@queries/subscription/useSubscriptionQuery.hook';

const MENU_ACTIONS = {
    EXPORT: 'export',
    DELETE: 'delete',
};

const EpisodeEditTranscriptionMenu = ({ menuIcon, objectToSaveBeforeExport }) => {
    const { episodeId, showId } = useParams();
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isExportModalOpen, setIsExportModalOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const deleteEpisodeTranscription = useDeleteEpisodeTranscriptMutation();
    const { data: subscription } = useSubscriptionQuery();
    const { data: show } = useShowQuery(showId);
    const isExportDisabled = show?.userRole === USER_ROLES.OWNER && subscription?.isFreemium;

    const handleDeleteTranscription = () => {
        deleteEpisodeTranscription.mutate(episodeId, {
            onSuccess: () => {
                localStorage.removeItem(`episode-form-${episodeId}-transcription`);
                setIsDeleteModalOpen(false);
            },
        });
    };

    const menuActionHandler = (action) => {
        switch (action) {
            case MENU_ACTIONS.EXPORT:
                if (!isExportDisabled) {
                    setIsExportModalOpen(true);
                    setIsMenuOpen(false);
                }
                return;
            case MENU_ACTIONS.DELETE:
                setIsDeleteModalOpen(true);
                setIsMenuOpen(false);
                return;
            default:
                return;
        }
    };

    return (
        <>
            <DialogTrigger>
                <TriggerButton aria-label="Menu" onPress={() => setIsMenuOpen(true)}>
                    {menuIcon}
                </TriggerButton>
                <PopoverStyled
                    placement="bottom end"
                    isOpen={isMenuOpen}
                    onOpenChange={setIsMenuOpen}
                >
                    <MenuDropdown onAction={menuActionHandler}>
                        <ExportMenuDropdownItem
                            $isDisabled={isExportDisabled}
                            id={MENU_ACTIONS.EXPORT}
                        >
                            <MenuItemIcon
                                icon={icon({ name: 'arrow-up-from-line', style: 'solid' })}
                            />
                            <Text>
                                <FormattedMessage defaultMessage="Exporter" />
                            </Text>
                            {isExportDisabled && <UpgradeBadge pricing={PRICING.LAUNCH} />}
                        </ExportMenuDropdownItem>
                        <MenuDropdownItem id={MENU_ACTIONS.DELETE}>
                            <TrashIcon icon={icon({ name: 'trash', style: 'solid' })} />
                            <Text color="--alert">
                                <FormattedMessage defaultMessage="Supprimer" />
                            </Text>
                        </MenuDropdownItem>
                    </MenuDropdown>
                </PopoverStyled>
            </DialogTrigger>
            <EpisodeEditTranscriptionDeleteModal
                isOpen={isDeleteModalOpen}
                onOpenChange={setIsDeleteModalOpen}
                handleDelete={handleDeleteTranscription}
                isLoading={deleteEpisodeTranscription.isLoading}
            />
            <EpisodeEditTranscriptionExportModal
                isOpen={isExportModalOpen}
                onOpenChange={setIsExportModalOpen}
                objectToSaveBeforeExport={objectToSaveBeforeExport}
            />
        </>
    );
};

const TriggerButton = styled(Button)`
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
    border-radius: var(--r-full);
    width: 1.6rem;
    height: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: var(--neutral50);
    }
`;
const PopoverStyled = styled(Popover)`
    width: 100%;
    max-width: 10rem;
`;
const MenuItemIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const TrashIcon = styled(MenuItemIcon)`
    color: var(--alert);
`;
const ExportMenuDropdownItem = styled(MenuDropdownItem)`
    ${({ $isDisabled }) =>
        $isDisabled &&
        css`
            &[data-hovered] {
                background-color: transparent;
            }

            & > p,
            & > ${MenuItemIcon} {
                color: var(--neutral500);
                pointer-events: none;
            }
        `}
`;

export default EpisodeEditTranscriptionMenu;
