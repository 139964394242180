import { useState } from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';
import type { Episode as EpisodeT } from '@queries/episode/useEpisodesQuery.hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useParams } from 'react-router';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import { EPISODE_STATUS, USER_ROLES } from '@/utils/constants';
import RouterLink from '@/components/Link/RouterLink';
import { sendAmplitudeLogEvent } from '@/helpers';
import Button from '@/components/Button';
import { mediaQueries } from '@/styles/theme';
import { useAudioPlayerContext } from '@/context/AudioPlayerContext';
import CircularProgress from '@ui/atoms/CircularProgress';
import styled from 'styled-components';

interface EpisodeProps {
    episode: EpisodeT;
}

const isDesktop = window.matchMedia(
    mediaQueries.mediaQueries.desktopLargeAndUp.split('@media ')[1],
).matches;

const psoCheckerScore = Math.floor(Math.random() * (100 - 0 + 1) + 0);

const Episode = ({ episode }: EpisodeProps) => {
    const { showId } = useParams<{ showId: string }>();
    const { data: show } = useShowQuery(showId);
    const [hovered, setHovered] = useState(false);
    const episodeIsDraft = episode.state === EPISODE_STATUS.DRAFT;
    const episodeIsOnline = episode.state === EPISODE_STATUS.ONLINE;

    const userHasWriteAccess = show?.userRole !== USER_ROLES.VIEWER;

    const { currentlyPlaying, setCurrentlyPlaying, audioIsPlaying, play, pause } =
        useAudioPlayerContext();

    const MediaButton = (
        <PlayControlOverlay>
            {audioIsPlaying && currentlyPlaying?.url === episode.audioUrl ? (
                <PlayButton onPress={pause} variant="ghost">
                    <FontAwesomeIcon icon={icon({ name: 'pause', style: 'solid' })} />
                </PlayButton>
            ) : (
                <PlayButton
                    onPress={() => {
                        if (!episode?.audioUrl) return;

                        const media = {
                            name: episode.name,
                            url: episode.audioUrl,
                        };

                        setCurrentlyPlaying(media);
                        play(media);
                    }}
                    variant="ghost"
                >
                    <FontAwesomeIcon icon={icon({ name: 'play', style: 'solid' })} />
                </PlayButton>
            )}
        </PlayControlOverlay>
    );

    const CoverContent = episode.imageUrl ? (
        <Cover src={episode.imageUrl} alt={episode.name} />
    ) : show?.imageUrl ? (
        <Cover src={show.imageUrl} alt={show.name} />
    ) : (
        <Cover src="https://image.ausha.co/default_cover.png" alt="Default cover" />
    );

    const psoCheckerColor =
        psoCheckerScore > 70 ? '--success' : psoCheckerScore > 20 ? '--warning' : '--alert';

    return (
        <EpisodeWrapper onMouseOver={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
            <Left>
                <CoverWrapper>
                    {CoverContent}
                    {episode.audioUrl &&
                        (currentlyPlaying?.url === episode.audioUrl || hovered) &&
                        MediaButton}
                </CoverWrapper>
                <LeftTextWrapper>
                    {userHasWriteAccess ? (
                        <EditLinkTitle
                            to={
                                episodeIsOnline
                                    ? `/app/show/${showId}/episodes/preview/${episode.id}`
                                    : `/app/show/${showId}/episodes/edit/${episode.id}`
                            }
                            onClick={() => {
                                if (!episodeIsOnline) {
                                    sendAmplitudeLogEvent('edit form episode', {
                                        is_new_episode: episodeIsDraft,
                                    });
                                }
                            }}
                        >
                            <EpisodeTitle>{episode.name}</EpisodeTitle>
                        </EditLinkTitle>
                    ) : (
                        <EpisodeTitle>{episode.name}</EpisodeTitle>
                    )}
                    <EpisodeMetaData>
                        {!episodeIsDraft && (
                            <MetaData>
                                <FontAwesomeIcon
                                    icon={icon({ name: 'calendar', style: 'regular' })}
                                />
                                <FormattedDate value={episode?.publishedAt || ''} />
                            </MetaData>
                        )}
                    </EpisodeMetaData>
                </LeftTextWrapper>
            </Left>
            <Right>
                <PsoChecker $color={psoCheckerColor}>
                    <CircularProgress
                        progressColor={psoCheckerColor}
                        backgroundColor={`${psoCheckerColor}200`}
                        size={20}
                        strokeWidth={4}
                        percentage={psoCheckerScore}
                    />
                    <span>{psoCheckerScore}%</span>
                    <FormattedMessage defaultMessage="optimisé" />
                </PsoChecker>
                <RouterLink
                    to={`/app/show/${showId}/episodes/optimize/${episode.id}`}
                    variant="button-primary"
                    startIcon={
                        <FontAwesomeIcon icon={icon({ name: 'rocket-launch', style: 'solid' })} />
                    }
                >
                    {isDesktop ? <FormattedMessage defaultMessage="Optimiser" /> : null}
                </RouterLink>
            </Right>
        </EpisodeWrapper>
    );
};

const EpisodeWrapper = styled.li`
    transition-duration: 0.2s;
    height: 4.5rem;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    border-radius: var(--r-m);
    background-color: var(--white);
    padding-block: 0.75rem;
    padding-inline: 1rem;
    min-width: 0;

    &:not(:last-child) {
        border-bottom: 1px solid var(--neutral100);
    }

    &:hover {
        z-index: 2;
        box-shadow: var(--s-whatever);
    }

    &:not(:last-child):hover {
        border-bottom: 1px solid transparent;
    }
`;
const CoverWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    flex-shrink: 0;
`;
const Cover = styled.img`
    position: absolute;
    left: 0;
    top: 0;
    border-radius: var(--r-s);
`;
const Left = styled.div`
    display: flex;
    align-items: center;
    gap: 0.75rem;
    flex: 1;
    min-width: 0;
`;
const EditLinkTitle = styled(RouterLink)`
    color: var(--black);
    flex: 1;
`;
const EpisodeTitle = styled.div`
    font-weight: var(--fw-semibold);
    font-size: var(--fs-body-l);
    flex: 1;

    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: 1450px) {
        max-width: 600px;
    }

    &:hover {
        text-decoration: underline;
    }
`;
const LeftTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    flex: 1;
    min-width: 0;

    &:hover ${EpisodeTitle} {
        text-decoration: underline;
    }
`;
const EpisodeMetaData = styled.div`
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-size: var(--fs-body-m);
`;
const MetaData = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
    color: var(--neutral500);
`;
const Right = styled.div`
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    gap: 1rem;
    align-items: center;
`;
const PlayControlOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--r-s);
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
`;
const PlayButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
    color: var(--white);
`;
const PsoChecker = styled.div<{ $color: string }>`
    display: flex;
    align-items: center;
    font-weight: var(--fw-semibold);
    gap: 0.25rem;
    color: var(${({ $color }) => $color});
    background-color: var(${({ $color }) => $color}50);
    padding: 0.5rem;
    border-radius: var(--r-s);
`;

export default Episode;
