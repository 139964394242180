import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Text from '@ui/atoms/Text';
import NoDataChart from '@/components/charts/NoData/NoDataChart';

interface TableProps {
    ages: string[];
    values: number[];
}

const AudienceAgeTable = ({ ages, values }: TableProps) => (
    <TableWrapper>
        <TableHead>
            <HeaderFirstCell>
                <FormattedMessage defaultMessage="Tranche d'âge" />
            </HeaderFirstCell>
            <HeaderLastCell>
                <FormattedMessage defaultMessage="Pourcentage" />
            </HeaderLastCell>
        </TableHead>
        {values?.length > 0 ? (
            ages?.map((age, index) => (
                <TableLine key={age} $divider={index !== 0}>
                    <FirstCell>
                        <FirstCellValue>{age}</FirstCellValue>
                    </FirstCell>
                    <LastCell>
                        <LastCellValue>{values[index] ? `${values[index]}%` : '-'}</LastCellValue>
                    </LastCell>
                </TableLine>
            ))
        ) : (
            <NoDataChartWrapper>
                <NoDataChart />
            </NoDataChartWrapper>
        )}
    </TableWrapper>
);

const TableWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-xs);
    padding-block: 2rem;
    padding-inline: 2.5rem;
`;

const TableHead = styled.div`
    display: grid;
    grid-column: 3;
    padding-block: 0.5rem;
    padding-inline: 1rem;
    background-color: var(--neutral50);
    border-radius: var(--r-xs);
`;

const TableLine = styled.div<{ $divider: boolean }>`
    display: grid;
    grid-column: 3;
    padding: 1rem;
    border-top: ${(props) => props.$divider && '1px solid'};
    border-color: var(--neutral100);
`;

const FirstCell = styled.div`
    grid-column: 1/3;
`;

const LastCell = styled.div`
    grid-column: 3/4;
    display: flex;
    justify-content: flex-end;
    flex-wrap: nowrap;
`;

const HeaderFirstCell = styled(FirstCell)`
    color: var(--neutral500);
    font-weight: var(--fw-semibold);
`;

const HeaderLastCell = styled(LastCell)`
    color: var(--neutral500);
    font-weight: var(--fw-semibold);
`;

const FirstCellValue = styled(Text)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const LastCellValue = styled(Text)`
    display: flex;
    justify-content: flex-end;
`;

const NoDataChartWrapper = styled.div`
    padding: 1rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        padding: 4rem;
    }
`;

export default AudienceAgeTable;
