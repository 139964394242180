import { action, computed, runInAction } from 'mobx';
import dayjs from 'dayjs';
import Store from '../Store';
import { apiFetch } from '../../components/app/decorators/api';
import {
    fetchPeakTime,
    fetchPeakTimeBest,
    fetchPeakTimePopularDays,
    fetchPeakTimePopularHours,
} from '@/api';
import PeakTimeCurrentMomentsModel from '../../models/PeakTimeCurrentMomentsModel';
import PeakTimeBestModel from '../../models/PeakTimeBestModel';
import PeakTimePopularDaysModel from '../../models/PeakTimePopularDaysModel';
import PeakTimePopularHoursModel from '../../models/PeakTimePopularHoursModel';

class PeakTimeStatsStore extends Store {
    static observables = {
        launchTimeBest: {},
        launchTimeCurrentMoments: [],
        launchTimePopularDays: [],
        launchTimePopularHours: [],

        // UI STATE
        daysRangePeakTime: 'always',
    };

    @action
    setDaysRangePeakTime(step) {
        this.daysRangePeakTime = step;
    }

    @computed
    get currentMoments() {
        return this.launchTimeCurrentMoments;
    }

    @computed
    get bestDayLaunch() {
        const dayFormat = dayjs(dayjs().isoWeekday(this.launchTimeBest.bestWeekDay)).format('dddd');
        const hourFormat = dayjs(dayjs().hour(this.launchTimeBest.bestDayHour)).format('H');
        return {
            bestWeekDay: dayFormat,
            bestDayHour: hourFormat,
        };
    }

    @computed
    get popularDays() {
        return {
            weekDay: this.launchTimePopularDays.map((day) =>
                dayjs(dayjs().isoWeekday(day.weekDay)).format('dddd'),
            ),
            weekDayResponsive: this.launchTimePopularDays.map((day) =>
                dayjs(dayjs().isoWeekday(day.weekDay)).format('ddd'),
            ),
            downloads: this.launchTimePopularDays.map((day) => day.downloads),
            totalDownloads: this.launchTimePopularDays.reduce((a, c) => a + c.downloads, 0),
        };
    }

    @computed
    get popularHours() {
        return {
            dayHour: this.launchTimePopularHours.map((day) => dayjs(dayjs().hour(day.dayHour))),
            downloads: this.launchTimePopularHours.map((day) => day.downloads),
            totalDownloads: this.launchTimePopularHours.reduce((a, c) => a + c.downloads, 0),
        };
    }

    @apiFetch
    async fetchPeakTimeBest(showId, query) {
        const { data } = await fetchPeakTimeBest(showId, query);
        runInAction(() => {
            this.launchTimeBest = new PeakTimeBestModel(this, data);
        });
    }

    @apiFetch
    async fetcherPeakTime(showId, query) {
        const { data } = await fetchPeakTime(showId, query);
        runInAction(() => {
            this.launchTimeCurrentMoments = new PeakTimeCurrentMomentsModel(this, data);
        });
    }

    @apiFetch
    async fetchPeakTimePopularDays(showId, query) {
        const { data } = await fetchPeakTimePopularDays(showId, query);
        runInAction(() => {
            this.launchTimePopularDays = data.map((day) => new PeakTimePopularDaysModel(this, day));
        });
    }

    @apiFetch
    async fetchPeakTimePopularHours(showId, query) {
        const { data } = await fetchPeakTimePopularHours(showId, query);
        runInAction(() => {
            this.launchTimePopularHours = data.map(
                (hour) => new PeakTimePopularHoursModel(this, hour),
            );
        });
    }
}

export default PeakTimeStatsStore;
