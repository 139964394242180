import { action, computed, runInAction } from 'mobx';
import { FormattedMessage } from 'react-intl';
import Store from '../Store';
import { apiCall, apiFetch } from '@/components/legacy/api';
import CategoryModel from '../../models/CategoryModel';
import {
    fetchApplePodcastsAvailableCategories,
    fetchApplePodcastsRankingOverall,
    fetchApplePodcastsRankingStats,
    fetchApplePodcastsReviews,
    fetchApplePodcastsReviewsNotification,
    fetchApplePodcastsStatsCsv,
    updateApplePodcastsReviewsNotification,
} from '@/api';
import ApplePodcastsSummaryModel from '../../models/ApplePodcastsSummaryModel';
import ApplePodcastsRankingOverallModel from '../../models/ApplePodcastsRankingOverallModel';
import ApplePodcastsRankingVariationModel from '../../models/ApplePodcastsRankingVariationModel';
import ReviewModel from '../../models/ReviewModel';
import PaginationModel from '../../models/PaginationModel';
import ApplePodcastsReviewsNotificationModel from '../../models/ApplePodcastsReviewsNotificationModel';
import { download } from '@/helpers';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';

dayjs.extend(isoWeek);

class ApplePodcastsStatsStore extends Store {
    static observables = {
        applePodcastsSummary: {},
        applePodcastsRankingVariations: [],
        applePodcastsRankingOverall: {},
        applePodcastsReviews: [],
        applePodcastsReviewsPagination: {},
        applePodcastsReviewsNotification: {},
        applePodcastsAvailableCategories: [],

        // UI STATE
        waitApplePodcastsRankingOverallFetching: false,
        applePodcastsCategoryId: undefined,
        stepMobileApplePodcasts: '',
    };

    @action.bound
    setApplePodcastsCategoryId(categoryId) {
        this.applePodcastsCategoryId = categoryId;
        this.waitApplePodcastsRankingOverallFetching = true;
    }

    @computed
    get applePodcastsRankingVariationsData() {
        const languageCode = this.state.userStore.user?.languageCode;
        return this.applePodcastsRankingVariations.map((current, index) => {
            const date = dayjs(current.date);
            return {
                ...current,
                date: current.date,
                dateShortFormat:
                    languageCode === 'fr' ? date.format('DD/MM/YYYY') : date.format('M/D/YY'),
                dateLongFormat: date.format('ddd ll'),
                weekFormat: (
                    <FormattedMessage
                        defaultMessage={`Semaine {week}`}
                        values={{ week: date.isoWeek() }}
                    />
                ),
                monthShortFormat: date.format('MM/YYYY'),
                monthLongFormat: date.format('MMMM YYYY'),
                yearFormat: date.format('YYYY'),
                id: index,
                // Virtually set out of ranking values to ranking 400, to deliberately display a peak below the axis line of the graph
                ranking: current.ranking ? current.ranking : 400,
            };
        });
    }

    @apiFetch
    async fetchApplePodcastsAvailableCategories(show) {
        try {
            const { data } = await fetchApplePodcastsAvailableCategories(show.id);
            runInAction(() => {
                const categories = data.map((category) => new CategoryModel(this, category));
                this.applePodcastsAvailableCategories = categories;
                // If possible, set the show category as the default category for Apple Podcasts
                if (show && show.categoryId && categories.some((c) => c.id === show.categoryId)) {
                    this.applePodcastsCategoryId = show.categoryId;
                } else {
                    this.applePodcastsCategoryId = null;
                }
            });
        } catch (e) {
            runInAction(() => {
                this.applePodcastsAvailableCategories = [];
                this.applePodcastsCategoryId = null;
            });
        }
    }

    @apiFetch
    async fetchApplePodcastsRankingOverall(showId, categoryId) {
        try {
            const { data } = await fetchApplePodcastsRankingOverall(
                showId,
                categoryId,
                Intl.DateTimeFormat().resolvedOptions().timeZone,
            );
            runInAction(() => {
                this.applePodcastsSummary = new ApplePodcastsSummaryModel(this, data.overall_stats);
                this.applePodcastsRankingOverall = new ApplePodcastsRankingOverallModel(
                    this,
                    data.ranking_details,
                );
                this.waitApplePodcastsRankingOverallFetching = false;
            });
        } catch (e) {
            runInAction(() => {
                this.applePodcastsSummary = new ApplePodcastsSummaryModel(this);
                this.applePodcastsRankingOverall = new ApplePodcastsRankingOverallModel(this);
                this.waitApplePodcastsRankingOverallFetching = false;
            });
        }
    }

    @apiFetch
    async fetchApplePodcastsRankingStats(showId, categoryId, query) {
        try {
            const { data, meta } = await fetchApplePodcastsRankingStats(showId, categoryId, {
                ...query,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            });
            runInAction(() => {
                this.stepMobileApplePodcasts = meta.step;
                this.applePodcastsRankingVariations = data.map(
                    (variation) => new ApplePodcastsRankingVariationModel(this, variation),
                );
            });
        } catch (e) {
            runInAction(() => {
                this.stepMobileApplePodcasts = '';
                this.applePodcastsRankingVariations = [];
            });
        }
    }

    @apiFetch
    async fetchApplePodcastsReviews(showId, page) {
        try {
            const { data, meta } = await fetchApplePodcastsReviews(showId, page);
            runInAction(() => {
                this.applePodcastsReviews = data.map((review) => new ReviewModel(this, review));
                this.applePodcastsReviewsPagination = new PaginationModel(this, meta.pagination);
            });
        } catch (e) {
            runInAction(() => {
                this.applePodcastsReviews = [];
                this.applePodcastsReviewsPagination = {};
            });
        }
    }

    @apiFetch
    async fetchApplePodcastsReviewsNotification(showId) {
        try {
            const { data } = await fetchApplePodcastsReviewsNotification(showId);
            runInAction(() => {
                this.applePodcastsReviewsNotification = new ApplePodcastsReviewsNotificationModel(
                    this,
                    data,
                );
            });
        } catch (e) {
            runInAction(() => {
                this.applePodcastsReviewsNotification = new ApplePodcastsReviewsNotificationModel(
                    this,
                );
            });
        }
    }

    async updateApplePodcastsReviewsNotification(showId, formData) {
        try {
            const { data } = await updateApplePodcastsReviewsNotification(showId, formData);
            runInAction(() => {
                this.applePodcastsReviewsNotification = new ApplePodcastsReviewsNotificationModel(
                    this,
                    data,
                );
            });
        } catch (e) {
            runInAction(() => {
                this.applePodcastsReviewsNotification = new ApplePodcastsReviewsNotificationModel(
                    this,
                );
            });
        }
    }

    @apiCall
    async getApplePodcastsRankingCsv(showId, categoryId, query) {
        const { axios, ...data } = await fetchApplePodcastsStatsCsv(showId, categoryId, {
            ...query,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });
        const filename = axios.headers['content-disposition'].match(/filename="(.+)"/)[1];
        download(Object.values(data).join(''), filename, 'txt');
    }
}

export default ApplePodcastsStatsStore;
