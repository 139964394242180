import styled from 'styled-components';
import { useAccordionItem } from '../AccordionItem/useAccordionItem.hook';

interface AccordionButtonProps {
    children: React.ReactNode;
    onClick?: () => void;
}

const AccordionButton = ({ children, ...props }: AccordionButtonProps) => {
    const { onToggle, isDisabled } = useAccordionItem();

    const handleClick = () => {
        onToggle && onToggle();
        props?.onClick?.();
    };

    return (
        <NoStyleButton
            type="button"
            onClick={handleClick}
            isDisabled={isDisabled || false}
            {...props}
        >
            {children}
        </NoStyleButton>
    );
};

const NoStyleButton = styled.button<{ isDisabled: boolean }>`
    padding: 0;
    background: none;
    border: none;
    width: 100%;
    cursor: pointer;

    ${({ isDisabled }) => isDisabled && 'pointer-events: none;'}
`;

export default AccordionButton;
