import { useState, useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDebounce } from '@/shared/hooks/useDebounce';
import useCampaignsInfiniteQuery, {
    Campaign,
} from '@/queries/campaign/useCampaignsInfiniteQuery.hook';
import Button from '@/components/Button';
import useCampaignQuery from '@/queries/campaign/useCampaignQuery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { SearchField, Input, Button as RAButton, ListBox } from 'react-aria-components';
import useQuery from '@/shared/hooks/useQuery';
import styled from 'styled-components';
import ListBoxItem from '@/components/ui/atoms/ListBoxItem';

interface CampaignsListProps {
    onClose: () => void;
}

const CampaignsList = ({ onClose }: CampaignsListProps) => {
    const intl = useIntl();
    const location = useLocation();
    const history = useHistory();
    const { showId } = useParams<{ showId: string }>();
    const query = useQuery();
    const campaignId = query.get('campaignId');

    const [campaignSearchValue, setCampaignSearchValue] = useState('');
    const debouncedCampaignSearchValue = useDebounce(campaignSearchValue, 500);

    const campaigns = useCampaignsInfiniteQuery({
        showId,
        query: debouncedCampaignSearchValue,
        pagination: { page: 1, perPage: 10 },
    });
    const { data: campaign } = useCampaignQuery(campaignId ?? '', {
        enabled: !!campaignId,
    });

    const campaignInfiniteList = useMemo(
        () =>
            campaigns?.data?.pages
                ?.reduce((acc, page) => acc.concat(page.campaigns as Campaign[]), [] as Campaign[])
                ?.map((campaign) => ({
                    id: campaign.id,
                    name: campaign.name,
                })),
        [campaigns?.data?.pages],
    );

    const campaignsList = [
        ...(campaignInfiniteList ?? []),
        ...(campaign && !campaignInfiniteList?.some((c) => c.id === campaign.id) ? [campaign] : []),
    ];

    const hasMoreItems =
        (campaigns?.data?.pages[campaigns?.data?.pages.length - 1].pagination?.totalPages ?? 0) >
        (campaigns?.data?.pages[campaigns?.data?.pages.length - 1].pagination?.currentPage ?? 0);

    const handleLoadMore = () => {
        if (!campaigns?.hasNextPage || campaigns?.isFetchingNextPage || campaigns?.isFetching) {
            return;
        }
        campaigns?.fetchNextPage();
    };

    return (
        <CampaignsListWrapper>
            <CampaignSearchField>
                {campaigns.isLoading ? (
                    <SearchIcon icon={icon({ name: 'spinner-third', style: 'solid' })} spin />
                ) : (
                    <SearchIcon icon={icon({ name: 'magnifying-glass', style: 'solid' })} />
                )}
                <SearchInput
                    placeholder={intl.formatMessage({ defaultMessage: 'Rechercher une campagne…' })}
                    onChange={(e) => setCampaignSearchValue(e?.target?.value ?? '')}
                />
                {campaignSearchValue && (
                    <EraseSearchButton>
                        <FontAwesomeIcon icon={icon({ name: 'circle-xmark', style: 'solid' })} />
                    </EraseSearchButton>
                )}
            </CampaignSearchField>
            {campaigns.isLoading && campaignsList.length > 0 ? (
                <LoadingWrapper>
                    <FontAwesomeIcon icon={icon({ name: 'spinner-third', style: 'solid' })} spin />
                </LoadingWrapper>
            ) : (
                <ScrollableListBox
                    aria-label="Campaigns"
                    selectionMode="single"
                    selectedKeys={campaignId ? new Set([Number(campaignId)]) : new Set()}
                    onSelectionChange={(selected) => {
                        const selectedKey = selected as Set<string>;
                        const campaignId = selectedKey.values().next().value;
                        history.push(`${location.pathname}?campaignId=${campaignId}`);
                        onClose();
                    }}
                    items={campaignsList}
                >
                    {(item) => <ListBoxItem {...item} />}
                </ScrollableListBox>
            )}
            {hasMoreItems && (
                <LoadMoreButton variant="ghost" onPress={handleLoadMore}>
                    <FormattedMessage defaultMessage="Charger plus" />
                </LoadMoreButton>
            )}
        </CampaignsListWrapper>
    );
};

const CampaignsListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-height: 20rem;
`;
const CampaignSearchField = styled(SearchField)`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background-color: var(--neutral50);
    padding-block: 0.25rem;
    padding-inline: 0.5rem;
    border-radius: var(--r-s);
`;
const SearchIcon = styled(FontAwesomeIcon)`
    font-size: 0.75rem;
    color: var(--neutral500);
`;
const SearchInput = styled(Input)`
    flex: 1;
    border: none;
    background: none;
`;
const EraseSearchButton = styled(RAButton)`
    border: none;
    background: none;
    color: var(--neutral500);
`;
const ScrollableListBox = styled(ListBox)`
    overflow-y: auto;
    padding-right: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    flex: 1;
    min-height: 0;

    &::-webkit-scrollbar {
        width: 0.25rem;
    }

    &::-webkit-scrollbar-track {
        background: var(--neutral50);
    }

    &::-webkit-scrollbar-thumb {
        background: var(--neutral300);
        border-radius: var(--r-xs);
    }
`;
const LoadMoreButton = styled(Button)`
    &:hover {
        background-color: var(--primary50);
    }
`;
const LoadingWrapper = styled.div`
    width: 100%;
    height: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--neutral);
`;

export default CampaignsList;
