import { css } from 'styled-components';
import PropTypes from 'prop-types';

/**
 * See https://chakra-ui.com/docs/features/style-props
 */
const effectMixin = css`
    mix-blend-mode: ${(props) => props.$mixBlendMode ?? props.$blendMode};
    background-blend-mode: ${(props) => props.$backgroundBlendMode ?? props.$bgBlendMode};
    opacity: ${(props) => props.$opacity};
`;

export const effectPropTypes = {
    $mixBlendMode: PropTypes.string,
    $blendMode: PropTypes.string, // Shortcut for mixBlendMode
    $backgroundBlendMode: PropTypes.string,
    $bgBlendMode: PropTypes.string, // Shortcut for backgroundBlendMode
    $opacity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default effectMixin;
