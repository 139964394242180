import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateRssSettings } from '@/api';
import rssSettingsKeys from '@/queries/rssSettings/rssSettingsKeys';
import camelcaseKeys from 'camelcase-keys';
import { decamelizeKeys } from 'humps';
import { HostingSettingsFormSchema } from '@/features/settings/components/Settings/Hosting/Settings/Form/useFormSchema';

interface MutationFnProps {
    showId: string | number;
    payload: HostingSettingsFormSchema;
}

const mutationFn = async ({ showId, payload }: MutationFnProps) => {
    const { data } = await updateRssSettings(showId, decamelizeKeys(payload));

    return camelcaseKeys(data as any, { deep: true });
};

const useUpdateRssSettingsMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: (_, { showId }) => {
            queryClient.invalidateQueries({
                queryKey: rssSettingsKeys.allDetails(showId),
            });
        },
    });
};

export default useUpdateRssSettingsMutation;
