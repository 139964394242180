import { FormattedMessage } from 'react-intl';
import { uploadClipFormatImage } from '@/api';
import Uploader from '@ui/smarties/Uploader';
import DeprecatedButton from '@ui/atoms/DeprecatedButton';
import UpdateImageIcon from '@public/icons/camera.svg';
import { messageParsers } from '@/forms/validators/apiValidation';
import { useModalToastQueue } from '@/shared/hooks/useModalToastQueue.hook';

const UpdateImageButton = ({
    values,
    format,
    multiFormats,
    onUpdateImage,
    setIsUploadingImage,
}) => {
    const toast = useModalToastQueue();

    const handleUploadImage = async (file) => {
        setIsUploadingImage(format, true);
        try {
            const clipFormat = values.formats.find((item) => item.format === format);
            const { data } = await uploadClipFormatImage(clipFormat.id, file);
            onUpdateImage(format, data);
        } catch (error) {
            Object.entries(error?.response?.data?.fails?.file)?.forEach((fail) => {
                const [key, values] = fail;
                const parsedValue = key === 'Mimes' ? [values] : values;
                if (messageParsers[key]) {
                    toast.alert(
                        <FormattedMessage
                            defaultMessage="{message}"
                            values={{ message: messageParsers[key](...parsedValue) }}
                        />,
                    );
                }
            });
        } finally {
            setIsUploadingImage(format, false);
        }
    };

    return (
        <Uploader accept="image/*" onChange={handleUploadImage}>
            <DeprecatedButton accent="secondary">
                <UpdateImageIcon width={16} height={14} />
                {multiFormats ? (
                    <FormattedMessage defaultMessage="Modifier l’image de ce format" />
                ) : (
                    <FormattedMessage defaultMessage="Modifier l’image" />
                )}
            </DeprecatedButton>
        </Uploader>
    );
};

export default UpdateImageButton;
