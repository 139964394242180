import styled from 'styled-components';
import Button from '@/components/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import { MenuTrigger, Popover } from 'react-aria-components';
import MenuDropdown from '@/components/ui/atoms/MenuDropdown';
import MenuDropdownItem from '@/components/ui/atoms/MenuDropdown/MenuDropdownItem';
import Text from '@/components/ui/atoms/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { SOCIAL_NETWORK } from '@/shared/config/constants';
import { messageParsers, messagesImgUploadError } from '@/forms/validators/apiValidation';
import { useBodyToastQueue } from '@/shared/hooks/useBodyToastQueue.hook';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import uploadSocialNetworkPostImageMutation from '@/queries/socialNetworkPost/uploadSocialNetworkPostImageMutation';
import Stack from '@ui/layout/Stack';
import { useRef } from 'react';

interface ShareButtonImageProps {
    type: {
        name: string;
        disabled: boolean;
        label: string;
        icon: React.ReactNode;
        renderComponent: React.ReactNode;
        renderWhenSelected: (image: any) => any;
    };
    onSelectContent: (type: any, format: any, image: any) => void;
    post: any;
    provider: string;
    cover?: string | null;
}

const ShareButtonImage = ({
    type,
    onSelectContent,
    post,
    provider,
    cover = null,
}: ShareButtonImageProps) => {
    const intl = useIntl();
    const toast = useBodyToastQueue();
    const { data: user } = useUserQuery();
    const uploadPostImage = uploadSocialNetworkPostImageMutation();
    const fileInputRef = useRef<HTMLInputElement>(null);

    const uploadConstraintsMatch: Record<string, JSX.Element> = {
        [SOCIAL_NETWORK.INSTAGRAM]: (
            <FormattedMessage defaultMessage="Ratio compris entre 4:5 et 1.91:1" />
        ),
        [SOCIAL_NETWORK.FACEBOOK]: (
            <FormattedMessage defaultMessage="Taille min. : 200*200px ; Poids max. : 8mo" />
        ),
        [SOCIAL_NETWORK.LINKEDIN]: (
            <FormattedMessage defaultMessage="Taille min. : 552*276px ; Poids max. : 5mo" />
        ),
        [SOCIAL_NETWORK.TWITTER]: <FormattedMessage defaultMessage="Poids max. : 5mo" />,
    };

    const handleUpload = async (files: FileList | null) => {
        const file = files?.[0];
        if (!file) return;
        const img = new Image();
        img.src = URL.createObjectURL(new File([file], 'pic'));

        uploadPostImage.mutate(
            { postId: post.id, file },
            {
                onSuccess: (data) => {
                    onSelectContent(type, null, {
                        url: data.url,
                        name: intl.formatMessage({ defaultMessage: 'Image personnalisée' }),
                    });
                },
                onError: (error: any) => {
                    if (error?.response?.data?.fails?.file) {
                        Object.entries(error.response.data.fails.file).forEach((fail) => {
                            const [key, values] = fail;
                            const parsedValue = key === 'Mimes' ? [values] : values;
                            const denominator = user?.language === 'fr' ? 1000 * 1000 : 1024 * 1024;
                            const currentValues = {
                                weight: (file.size / denominator).toFixed(1),
                                width: img.width,
                                height: img.height,
                                format: file.name.split('.')[1],
                            };

                            if (key in messageParsers) {
                                toast.alert(
                                    <FormattedMessage
                                        defaultMessage="{message}"
                                        values={{
                                            message: messagesImgUploadError[
                                                key as keyof typeof messagesImgUploadError
                                            ](provider, currentValues, ...parsedValue),
                                        }}
                                    />,
                                );
                            }
                        });
                    }
                },
            },
        );
    };

    const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleUpload(e.target.files);
        // Reset the input value so the same file can be selected again
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    return (
        <MenuTrigger>
            <Button
                variant="tertiary"
                startIcon={<ImageIcon icon={icon({ name: 'image', style: 'solid' })} />}
                isDisabled={type.disabled}
                isLoading={uploadPostImage.isLoading}
            >
                <FormattedMessage defaultMessage="Image" />
            </Button>
            <PopoverStyled placement="bottom left">
                <MenuDropdownStyled>
                    <MenuDropdownItem
                        onAction={() => {
                            // Trigger the file input click when the menu item is clicked
                            fileInputRef.current?.click();
                        }}
                    >
                        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                            <UploadIcon icon={icon({ name: 'upload', style: 'solid' })} />
                            <Stack>
                                <FormattedMessage defaultMessage="Importer une image personnalisée" />
                                <Text variant="footnote" color="--neutral500">
                                    {
                                        uploadConstraintsMatch[
                                            provider as keyof typeof uploadConstraintsMatch
                                        ]
                                    }
                                </Text>
                            </Stack>
                        </div>
                    </MenuDropdownItem>
                    {cover && (
                        <MenuDropdownItem
                            onAction={() =>
                                onSelectContent(type, null, {
                                    url: cover,
                                    name: intl.formatMessage({
                                        defaultMessage: "Illustration de l'épisode",
                                    }),
                                    isCover: true,
                                })
                            }
                        >
                            <ImageIcon icon={icon({ name: 'image', style: 'solid' })} />
                            <FormattedMessage defaultMessage="Illustration de l'épisode" />
                        </MenuDropdownItem>
                    )}
                </MenuDropdownStyled>
            </PopoverStyled>
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                accept="image/*"
                onChange={handleFileInputChange}
            />
        </MenuTrigger>
    );
};

const PopoverStyled = styled(Popover)`
    width: 400px;
`;
const MenuDropdownStyled = styled(MenuDropdown)`
    max-width: 100%;
    box-shadow: var(--s-m);
    max-height: 10.5rem;
    height: fit-content;
    overflow-y: auto;
`;
const ImageIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const UploadIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    fill: var(--neutral500);
    margin-top: 0.25rem;
`;

export default ShareButtonImage;
