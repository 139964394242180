import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { InputTextController } from '@ui/atoms/InputText';
import { NonHostingEpisodeFormSchema } from '../useNonHostingEpisodeFormSchema.hook';
import Stack from '@/components/ui/layout/Stack';
import EpisodeAINewsletterTitleSuggestionPanel from '../NonHostingEpisodeAI/EpisodeAINewsletterTitleSuggestionPanel';

const NewsletterTitleField = () => {
    const intl = useIntl();
    const { control } = useFormContext<NonHostingEpisodeFormSchema>();

    return (
        <Stack>
            <InputTextController
                name="newsletter.title"
                control={control}
                label={<FormattedMessage defaultMessage="Objet de la newsletter" />}
                placeholder={intl.formatMessage({
                    defaultMessage: 'Ajoutez un titre à votre newsletter',
                })}
            />
            <EpisodeAINewsletterTitleSuggestionPanel />
        </Stack>
    );
};

export default NewsletterTitleField;
