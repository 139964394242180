import Text from '@/components/ui/atoms/Text';
import { useStatsDateSelectorContext } from '@/context/StatsDateSelectorContext';
import { FormattedDate, FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const LegendItem = () => {
    return (
        <LegendItemWrapper>
            <LegendCircle />
            <LegendLine />
        </LegendItemWrapper>
    );
};

const RingChartWithLegendSkeleton = () => {
    const { startDate, endDate } = useStatsDateSelectorContext();

    return (
        <Wrapper>
            <Ring xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240" fill="none">
                <path
                    d="M240 120C240 186.274 186.274 240 120 240C53.7258 240 0 186.274 0 120C0 53.7258 53.7258 0 120 0C186.274 0 240 53.7258 240 120ZM36 120C36 166.392 73.6081 204 120 204C166.392 204 204 166.392 204 120C204 73.6081 166.392 36 120 36C73.6081 36 36 73.6081 36 120Z"
                    fill="#F0EDF2"
                />
                <path
                    d="M112.5 121C112.5 120.203 113.156 119.5 114 119.5H126C126.797 119.5 127.5 120.203 127.5 121C127.5 121.844 126.797 122.5 126 122.5H114C113.156 122.5 112.5 121.844 112.5 121Z"
                    fill="#88848C"
                />
            </Ring>
            <RightBlock>
                {!!startDate && !!endDate && (
                    <Text textAlign="center" color="--neutral500">
                        <FormattedMessage
                            defaultMessage="Répartition des parts d’écoute du {fromDate} au {toDate}"
                            values={{
                                fromDate: (
                                    <FormattedDate
                                        value={startDate}
                                        day="2-digit"
                                        month="short"
                                        year="numeric"
                                    />
                                ),
                                toDate: (
                                    <FormattedDate
                                        value={endDate}
                                        day="2-digit"
                                        month="short"
                                        year="numeric"
                                    />
                                ),
                            }}
                        />
                    </Text>
                )}
                <Legend>
                    <LegendColumn>
                        <LegendItem />
                        <LegendItem />
                        <LegendItem />
                        <LegendItem />
                    </LegendColumn>
                    <LegendColumn>
                        <LegendItem />
                        <LegendItem />
                        <LegendItem />
                        <LegendItem />
                    </LegendColumn>
                </Legend>
            </RightBlock>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    align-items: center;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
        align-items: flex-start;
        column-gap: 4rem;
    }
`;
const Ring = styled.svg`
    /* width: 100%;
    height: 100%; */
    /* max-width: 240px;
    max-height: 240px; */
    flex-grow: 1;
    flex-basis: 30%;
`;
const LegendItemWrapper = styled.div`
    padding-inline: 0.75rem;
    border-radius: var(--r-m);
    background-color: var(--neutral50);
    height: 3rem;
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
`;
const LegendCircle = styled.div`
    width: 0.75rem;
    height: 0.75rem;
    background-color: var(--neutral200);
    border-radius: var(--r-full);
`;
const LegendLine = styled.div`
    width: 0.5rem;
    height: 2px;
    background-color: var(--neutral200);
    border-radius: var(--r-full);
`;
const RightBlock = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    flex-grow: 2;
    flex-basis: 70%;
`;
const Legend = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.75rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
        column-gap: 0.5rem;
    }
`;
const LegendColumn = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.75rem;
    flex-grow: 1;
`;

export default RingChartWithLegendSkeleton;
