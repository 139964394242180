import { css } from 'styled-components';
import PropTypes from 'prop-types';
import { computeCssValue } from '../css';

/**
 * See https://chakra-ui.com/docs/features/style-props
 */
const positionMixin = css`
    position: ${(props) => props.$position ?? props.$pos};
    z-index: ${(props) => props.$zIndex};
    top: ${(props) => computeCssValue(props.$top)};
    right: ${(props) => computeCssValue(props.$right)};
    bottom: ${(props) => computeCssValue(props.$bottom)};
    left: ${(props) => computeCssValue(props.$left)};
    inset: ${(props) => computeCssValue(props.$inset)};
`;

export const positionPropTypes = {
    $position: PropTypes.string,
    $pos: PropTypes.string, // Shortcut for position
    $zIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $top: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $right: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $bottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $left: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $inset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default positionMixin;
