import { useState } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import ListeningLink from './ListeningLink';
import { LISTENING_PLATFORMS_NAMES } from '@/shared/config/constants';
import Text from '@ui/atoms/Text';
import Button from '@ui/atoms/Button';
import styled from 'styled-components';
import NewLinkModal from './NewLinkModal';
import { useOverlayTriggerState } from 'react-stately';
import useShowListeningLinksQuery from '@queries/showListeningLink/useShowListeningLinksQuery.hook';
import useEpisodeListeningLinksQuery from '@queries/episodeListeningLink/useEpisodeListeningLinksQuery.hook';
import { useParams } from 'react-router-dom';
import Stack from '@ui/layout/Stack';
import { useAccordion } from '@ui/atoms/Accordion/useAccordion.hook';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useUserQuery from '@queries/user/useUserQuery.hook';

const helpLink = defineMessages({
    en: {
        defaultMessage:
            'https://help.ausha.co/en/articles/6908174-what-is-the-deeplink-and-how-can-i-use-it-for-the-smartlink-of-each-episode',
    },
    fr: {
        defaultMessage:
            'https://help.ausha.co/fr/articles/6908174-qu-est-ce-que-le-deeplink-et-comment-l-utiliser-pour-le-smartlink-de-chaque-episode',
    },
});

const EpisodeDeeplinkForm = () => {
    const { episodeId, showId } = useParams();
    const { data: user } = useUserQuery();
    const intl = useIntl();
    const {
        data: showListeningLinks,
        isLoading: showIsLoading,
        error: showHasError,
    } = useShowListeningLinksQuery(showId);
    const {
        data: episodeListeningLinks,
        isLoading: episodeIsLoading,
        error: episodeHasError,
    } = useEpisodeListeningLinksQuery({ episodeId });
    let modalState = useOverlayTriggerState({});
    const { goToNextIndex } = useAccordion();
    const [currentlyEditedLink, setCurrentlyEditedLink] = useState(null);
    const [updatedUrl, setUpdatedUrl] = useState(null);

    if (episodeHasError || showHasError) return null;
    if (episodeIsLoading || showIsLoading) return null;

    const eligiblePlatforms = showListeningLinks
        .filter((link) => episodeListeningLinks.every((l) => l.key !== link.key))
        .map((link) => ({
            name: LISTENING_PLATFORMS_NAMES[link.key],
            id: link.key,
        }))
        .sort((a, b) => {
            let textA = a.name.toUpperCase();
            let textB = b.name.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
        });

    const handleChange = () => {
        // TODO: dispatch a boolean to indicate that the listening links has been updated
    };

    return (
        <>
            <Stack $gap="2rem">
                <Text color="--neutral500">
                    <FormattedMessage
                        defaultMessage="Le Deeplink récupère automatiquement le lien spécifique de votre épisode sur vos applications d'écoute principales où il est disponible (Apple Podcast, Spotify & Youtube), afin de l'afficher sur vos outils communication Ausha. Si un lien vous semble erroné, vous pouvez néanmoins le corriger. <link>En apprendre plus</link>"
                        values={{
                            link: (chunks) => (
                                <CustomLink
                                    href={intl.formatMessage(helpLink[user?.locale || 'fr'])}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {chunks}
                                </CustomLink>
                            ),
                        }}
                    />
                </Text>
                {episodeListeningLinks.length > 0 && (
                    <ListeningLinks>
                        {episodeListeningLinks
                            .map((listeningLink) => ({
                                label: LISTENING_PLATFORMS_NAMES[listeningLink.key],
                                ...listeningLink,
                            }))
                            .sort((a, b) => {
                                let textA = a.label.toUpperCase();
                                let textB = b.label.toUpperCase();
                                return textA < textB ? -1 : textA > textB ? 1 : 0;
                            })
                            .map((listeningLink) => (
                                <ListeningLink
                                    key={listeningLink.key}
                                    link={listeningLink}
                                    episodeId={episodeId}
                                    currentlyEditedLink={currentlyEditedLink}
                                    setCurrentlyEditedLink={(link) => setCurrentlyEditedLink(link)}
                                    updatedUrl={updatedUrl}
                                    setUpdatedUrl={(url) => setUpdatedUrl(url)}
                                    onChange={handleChange}
                                />
                            ))}
                    </ListeningLinks>
                )}
                <ButtonsWrapper>
                    {eligiblePlatforms.length > 0 && (
                        <AddListeningLinkButton
                            variant="secondary"
                            startIcon={<AddIcon icon={icon({ name: 'add', style: 'solid' })} />}
                            onPress={() => {
                                setUpdatedUrl(null);
                                setCurrentlyEditedLink(null);
                                modalState.open();
                            }}
                        >
                            <FormattedMessage defaultMessage="Ajouter un lien" />
                        </AddListeningLinkButton>
                    )}
                    <NextButton onPress={goToNextIndex}>
                        <FormattedMessage defaultMessage="Suivant" />
                    </NextButton>
                </ButtonsWrapper>
            </Stack>
            {modalState.isOpen && (
                <NewLinkModal
                    modalState={modalState}
                    episodeId={episodeId}
                    eligiblePlatforms={eligiblePlatforms}
                    onClose={() => modalState.close()}
                    onLinkAdded={handleChange}
                />
            )}
        </>
    );
};

const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 0.5rem;
`;
const ListeningLinks = styled.ul`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    list-style: none;
    padding: 0;
`;
const AddListeningLinkButton = styled(Button)`
    background-color: var(--primary50);
    color: var(--primary);
    padding: 0.625rem 1rem;
    border: none;
`;
const NextButton = styled(Button)`
    box-shadow: var(--s-primary);
    align-self: flex-end;
`;
const AddIcon = styled(FontAwesomeIcon)`
    color: inherit;
`;
const CustomLink = styled.a`
    color: var(--primary);
    font-weight: var(--fw-semibold);
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;
export default EpisodeDeeplinkForm;
