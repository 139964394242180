import { motion } from 'framer-motion';
import styled from 'styled-components';
import makeAccess from '../decorators/makeAccess';
import DeprecatedText from '@ui/atoms/DeprecatedText';

const TabStyled = styled(DeprecatedText)`
    &:hover {
        text-decoration: none;
    }

    > * + * {
        margin-left: 8px;
        ${({ disabled }) =>
            disabled &&
            `
            color: var(--neutral300)`};
    }

    ${({ disabled }) =>
        disabled &&
        `
        & > svg {
            fill: var(--neutral300);
        }
    `}
`;

const spring = {
    type: 'spring',
    stiffness: 500,
    damping: 30,
};

const WrapperTab = ({ as, to, isSelected, onClick, children, disabled }) => (
    <TabStyled
        justify="center"
        onClick={onClick}
        pos="relative"
        weight="semibold"
        z={1}
        h="100%"
        flex
        align="center"
        pr={5}
        w="max-content"
        as={as}
        to={to}
        disabled={disabled}
    >
        {children}
        {isSelected && (
            <motion.div
                style={{
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: -1,
                    position: 'absolute',
                    marginLeft: 0,
                    height: '100%',
                    width: '100%',
                    borderBottom: '2px solid #751CBF',
                    borderRadius: '1px',
                }}
                layoutId="itemTab"
                initial
                transition={spring}
            />
        )}
    </TabStyled>
);

const AdvancedTab = (Component) => {
    const AdvancedTabInner = (props) => {
        const { simpleSwitch, as, to, isSelected, onClick, children, disabled } = props;
        if (simpleSwitch)
            return (
                <Component isSelected={isSelected} onClick={onClick} disabled={disabled}>
                    {children}
                </Component>
            );
        return (
            <Component
                as={as}
                to={to}
                isSelected={isSelected}
                onClick={onClick}
                disabled={disabled}
            >
                {children}
            </Component>
        );
    };
    return AdvancedTabInner;
};

const Tab = AdvancedTab(WrapperTab);

const CommunicationTabItem = ({
    children,
    as,
    to,
    onClick,
    isSelected,
    simpleSwitch = false,
    disabled,
}) => (
    <Tab
        as={as}
        to={to}
        isSelected={isSelected}
        onClick={onClick}
        simpleSwitch={simpleSwitch}
        disabled={disabled}
    >
        {children}
    </Tab>
);
export default makeAccess(CommunicationTabItem);
