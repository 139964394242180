import { Route } from 'react-router-dom';
import MountTransition from '../MountTransition';
import { Transition } from 'framer-motion';

interface RouteTransitionProps {
    children: React.ReactNode;
    exact?: boolean;
    path?: string;
    slide?: number;
    slideUp?: number;
    scaleUp?: number;
    transition?: Transition;
}

const RouteTransition = ({
    children,
    exact = false,
    path,
    slide = 0,
    slideUp = 0,
    scaleUp = 1,
    transition,
    ...rest
}: RouteTransitionProps) =>
    path ? (
        <Route exact={exact} path={path} {...rest}>
            <MountTransition
                slide={slide}
                slideUp={slideUp}
                scaleUp={scaleUp}
                transition={transition}
            >
                {children}
            </MountTransition>
        </Route>
    ) : (
        <MountTransition
            slide={slide}
            slideUp={slideUp}
            scaleUp={scaleUp}
            transition={transition}
            {...rest}
        >
            {children}
        </MountTransition>
    );
export default RouteTransition;
