import { useQuery } from '@tanstack/react-query';
import { fetchIpLocation } from '@/api';
import { z } from 'zod';

export const IpLocationSchema = z.object({
    ip: z.string().ip().optional(),
    query: z.string().ip().optional(),
    version: z.string().optional(),
    city: z.string().optional(),
    region: z.string().optional(),
    regionCode: z.string().length(2).optional(),
    regionName: z.string().optional(),
    countryCode: z.string().length(2).optional(),
    country: z.string().optional(),
    countryCodeIso3: z.string().length(3).optional(),
    countryName: z.string().optional(),
    countryCapital: z.string().optional(),
    countryTld: z.string().startsWith('.').optional(),
    continentCode: z.string().length(2).optional(),
    inEu: z.boolean().optional(),
    postal: z.string().optional(),
    zip: z.string().optional(),
    latitude: z.number().optional(),
    longitude: z.number().optional(),
    lat: z.number().optional(),
    lon: z.number().optional(),
    timezone: z.string().optional(),
    utcOffset: z.string().optional(),
    countryCallingCode: z.string().startsWith('+').optional(),
    currency: z.string().length(3).optional(),
    currencyName: z.string().optional(),
    languages: z.string().optional(),
    countryArea: z.number().optional(),
    countryPopulation: z.number().optional(),
    asn: z.string().optional(),
    as: z.string().optional(),
    isp: z.string().optional(),
    org: z.string().optional(),
    hostname: z.string().optional(),
    status: z.string().optional(),
});

export type IpLocation = z.infer<typeof IpLocationSchema>;

const queryFn = async () => {
    const data = await fetchIpLocation();

    return IpLocationSchema.parse(data);
};

const useIpLocationQuery = (config = {}) => {
    return useQuery({
        queryKey: ['ipLocation'],
        queryFn,
        ...config,
    });
};

export default useIpLocationQuery;
