import { useQuery } from '@tanstack/react-query';
import { psoGlobalConfiguration } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import psoKeys from './psoKeys';

const queryFn = async ({ queryKey: [{ showId }] }) => {
    const { data } = await psoGlobalConfiguration(showId);

    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const usePsoQuery = ({ showId, enabled }) => {
    return useQuery({
        queryKey: psoKeys.globalConfiguration(showId),
        queryFn,
        enabled,
    });
};

export default usePsoQuery;
