import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchEpisodesTopRankingFromShow } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import statsKeys from './statsKeys';
import { z } from 'zod';

export const TopRankingSchema = z.object({
    downloads: z.number(),
    name: z.string(),
    percentage: z.number(),
    podcastId: z.string().or(z.number()),
});
const TopRankingsSchema = z.array(TopRankingSchema);
export type TopRanking = z.infer<typeof TopRankingSchema>;
type Key = QueryFunctionContext<ReturnType<typeof statsKeys.listenersTopRanking>>;
type InputProps = {
    showId: string;
    timezone: string;
    episodes: string[];
    start: string;
    end: string;
    options?: {
        enabled?: boolean;
    };
};

const queryFn = async ({ queryKey: [{ showId, timezone, episodes, start, end }] }: Key) => {
    const { data } = await fetchEpisodesTopRankingFromShow(showId, {
        timezone,
        podcasts: episodes,
        start,
        end,
    });
    return TopRankingsSchema.parse(camelcaseKeys(data, { deep: true }));
};

const useListenersTopRankingQuery = ({
    showId,
    timezone,
    episodes,
    start,
    end,
    options,
}: InputProps) => {
    return useQuery({
        queryKey: statsKeys.listenersTopRanking(showId, timezone, episodes, start, end),
        queryFn,
        enabled: options?.enabled || true,
    });
};

export default useListenersTopRankingQuery;
