import { useEffect } from 'react';
import { useParams } from 'react-router';
import type { Episode as EpisodeT } from '@queries/episode/useEpisodesQuery.hook';
import SelectionToolbar from './SelectionToolbar';
import Episode from './Episode';
import ImportedEpisodeWithError from './ImportedEpisodeWithError';
import { useEpisodeSelectContext } from '@/context/EpisodeSelectContext';
import useImportedEpisodesQuery from '@/queries/rss/useImportedEpisodesQuery.hook';
import styled from 'styled-components';

interface EpisodesProps {
    episodes: EpisodeT[];
    pagination?: {
        currentPage: number;
        totalPages: number;
        total?: number;
        perPage?: number;
        count?: number;
    };
    currentShowIsImported: boolean;
}

const Episodes = ({ episodes, pagination, currentShowIsImported }: EpisodesProps) => {
    const { showId } = useParams<{ showId: string }>();
    const { setEpisodes, resetPageSelection } = useEpisodeSelectContext();
    const { data: importedEpisodesWithError } = useImportedEpisodesQuery({
        showId,
    });

    useEffect(() => {
        resetPageSelection();
    }, [pagination?.currentPage]);

    useEffect(() => {
        setEpisodes(episodes.map((episode) => `${episode.id}`));
    }, [episodes, setEpisodes]);

    return (
        <EpisodesWrapper>
            <SelectionToolbar
                hasSeveralPages={(pagination?.totalPages || 0) > 1}
                totalEpisodeCount={pagination?.total || 0}
                episodesPerPage={pagination?.count || 0}
            />
            <EpisodeList>
                {currentShowIsImported &&
                    importedEpisodesWithError?.importedEpisodes
                        ?.filter((episode) => episode.state === 'error')
                        ?.map((episode) => (
                            <ImportedEpisodeWithError key={episode.id} episode={episode} />
                        ))}
                {episodes.map((episode) => (
                    <Episode key={episode.id} episode={episode} />
                ))}
            </EpisodeList>
        </EpisodesWrapper>
    );
};

const EpisodesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;
const EpisodeList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    padding: 0.5rem;
    border-radius: var(--r-l);
`;

export default Episodes;
