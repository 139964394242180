import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import styled from 'styled-components';
import { isTimeUnitValid, TIME_UNIT, wrapTimeAtUnit } from '@/shared/utils/time';
import Stack from '@ui/layout/Stack';
import Text from '../Text';
import ErrorMessage from '../ErrorMessage';

dayjs.extend(duration);

type TimeUnitType = 'hours' | 'minutes' | 'seconds' | 'milliseconds';

const formatDuration = (duration: number, numberOfDefaultZero = 2): string =>
    `${duration}`.padStart(numberOfDefaultZero, '0');

export interface InputDurationProps {
    value: number;
    onChange: (seconds: number) => void;
    isDisabled?: boolean;
    label?: string | React.ReactNode;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    showMilliseconds?: boolean;
    max?: number;
    min?: number;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    errorMessage?: string;
}

const InputDuration: React.FC<InputDurationProps> = ({
    value,
    onChange,
    isDisabled,
    label,
    startIcon,
    endIcon,
    showMilliseconds,
    max,
    min,
    onBlur,
    errorMessage,
}) => {
    // Internal Duration computed based on value prop in seconds
    const [computedDuration, setComputedDuration] = useState(dayjs.duration(value, 'seconds'));
    const [hoursText, setHoursText] = useState(formatDuration(computedDuration.hours()));
    const [minutesText, setMinutesText] = useState(formatDuration(computedDuration.minutes()));
    const [secondsText, setSecondsText] = useState(formatDuration(computedDuration.seconds()));
    const [millisecondsText, setMillisecondsText] = useState(
        formatDuration(computedDuration.milliseconds(), 3),
    );

    useEffect(() => {
        setComputedDuration(dayjs.duration(value, 'seconds'));
    }, [value]);

    useEffect(() => {
        setHoursText(formatDuration(computedDuration.hours()));
        setMinutesText(formatDuration(computedDuration.minutes()));
        setSecondsText(formatDuration(computedDuration.seconds()));
        setMillisecondsText(formatDuration(Math.floor(computedDuration.milliseconds()), 3));
    }, [computedDuration]);

    const updateDuration = (duration: number, unit: TimeUnitType): void => {
        if (isTimeUnitValid(unit, duration)) {
            // Create a new duration with the updated unit
            const newDuration = dayjs.duration({
                milliseconds: computedDuration.milliseconds(),
                seconds: computedDuration.seconds(),
                minutes: computedDuration.minutes(),
                hours: computedDuration.hours(),
                [unit]: duration,
            });
            const newDurationInSeconds = newDuration.asSeconds();
            // Apply min/max constraints
            let adjustedDuration = newDuration;
            if (max && Number(max) && newDurationInSeconds > max) {
                adjustedDuration = dayjs.duration(max, 'seconds');
            } else if (min && Number(min) && newDurationInSeconds < min) {
                adjustedDuration = dayjs.duration(min, 'seconds');
            }

            setComputedDuration(adjustedDuration);
            onChange(adjustedDuration.asSeconds());

            // InputDuration is a controlled input but, due to time validation, onChange will not always change the value prop.
            // To prevent InputDuration to become out of sync (internal state keeps changing but value prop stay the same and
            // doesn't trigger a new duration computation), we force a computation of the internal duration.
            setComputedDuration(dayjs.duration(value, 'seconds'));
        } else {
            setComputedDuration(computedDuration);
        }
    };

    const shiftDuration = (unit: TimeUnitType, amount: number): void => {
        if (isDisabled) {
            return;
        }
        const newDuration = computedDuration.get(unit) + amount;
        updateDuration(wrapTimeAtUnit(unit, newDuration), unit);
    };

    const incrementDuration = (unit: TimeUnitType): void => shiftDuration(unit, 1);

    const decrementDuration = (unit: TimeUnitType): void => shiftDuration(unit, -1);

    const getInputBlurHandler =
        (unit: TimeUnitType) =>
        (event: React.FocusEvent<HTMLInputElement>): void => {
            const text = event.target.value;
            updateDuration(parseInt(text, 10), unit);
            onBlur?.(event);
        };

    const getInputChangeHandler =
        (unit: TimeUnitType) =>
        (event: React.ChangeEvent<HTMLInputElement>): void => {
            const text = event.target.value;
            switch (unit) {
                case TIME_UNIT.HOUR:
                    setHoursText(text);
                    break;
                case TIME_UNIT.MINUTE:
                    setMinutesText(text);
                    break;
                case TIME_UNIT.SECOND:
                    setSecondsText(text);
                    break;
                case TIME_UNIT.MILLISECOND:
                    setMillisecondsText(text);
                    break;
                default:
                    break;
            }
        };

    const inputFocusHandler = (event: React.FocusEvent<HTMLInputElement>): void => {
        event.currentTarget.select();
    };

    const getInputKeyDownHandler =
        (unit: TimeUnitType) =>
        (event: React.KeyboardEvent<HTMLInputElement>): void => {
            if (event.defaultPrevented) {
                return; // Do nothing if the event was already processed
            }

            switch (event.key) {
                case 'Down': // IE/Edge specific value
                case 'ArrowDown':
                    decrementDuration(unit);
                    break;
                case 'Up': // IE/Edge specific value
                case 'ArrowUp':
                    incrementDuration(unit);
                    break;
                case 'Enter':
                    event.currentTarget.blur();
                    break;
                default:
                    return; // Quit when this doesn't handle the key event.
            }

            // Cancel the default action to avoid it being handled twice
            event.preventDefault();
        };

    return (
        <Stack $gap="0.5rem">
            {label && <Text fontWeight="--fw-semibold">{label}</Text>}
            <InputWrapper isDisabled={isDisabled}>
                {startIcon}
                <UnitsWrapper>
                    <UnitInput
                        onBlur={getInputBlurHandler(TIME_UNIT.HOUR as TimeUnitType)}
                        onChange={getInputChangeHandler(TIME_UNIT.HOUR as TimeUnitType)}
                        onFocus={inputFocusHandler}
                        onKeyDown={getInputKeyDownHandler(TIME_UNIT.HOUR as TimeUnitType)}
                        value={hoursText}
                        disabled={isDisabled}
                    />
                    <Divider isDisabled={isDisabled}>:</Divider>
                    <UnitInput
                        onBlur={getInputBlurHandler(TIME_UNIT.MINUTE as TimeUnitType)}
                        onChange={getInputChangeHandler(TIME_UNIT.MINUTE as TimeUnitType)}
                        onFocus={inputFocusHandler}
                        onKeyDown={getInputKeyDownHandler(TIME_UNIT.MINUTE as TimeUnitType)}
                        value={minutesText}
                        disabled={isDisabled}
                    />
                    <Divider isDisabled={isDisabled}>:</Divider>
                    <UnitInput
                        onBlur={getInputBlurHandler(TIME_UNIT.SECOND as TimeUnitType)}
                        onChange={getInputChangeHandler(TIME_UNIT.SECOND as TimeUnitType)}
                        onFocus={inputFocusHandler}
                        onKeyDown={getInputKeyDownHandler(TIME_UNIT.SECOND as TimeUnitType)}
                        value={secondsText}
                        disabled={isDisabled}
                    />
                    {showMilliseconds && (
                        <>
                            <Divider isDisabled={isDisabled}>.</Divider>
                            <UnitInput
                                onBlur={getInputBlurHandler(TIME_UNIT.MILLISECOND as TimeUnitType)}
                                onChange={getInputChangeHandler(
                                    TIME_UNIT.MILLISECOND as TimeUnitType,
                                )}
                                onFocus={inputFocusHandler}
                                onKeyDown={getInputKeyDownHandler(
                                    TIME_UNIT.MILLISECOND as TimeUnitType,
                                )}
                                value={millisecondsText}
                                disabled={isDisabled}
                                size={3}
                                maxLength={3}
                                style={{ width: 'calc(3ch + 0.25rem)' }}
                            />
                        </>
                    )}
                </UnitsWrapper>
                <EndIconWrapper>{endIcon}</EndIconWrapper>
            </InputWrapper>
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </Stack>
    );
};

const InputWrapper = styled.div<{ isDisabled?: boolean }>`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    background: white;
    border: 1px solid var(--neutral200);
    border-radius: var(--r-xs);
    padding: 0.5rem;
    width: 100%;

    &:hover {
        border: 1px solid var(--neutral500);
    }

    &:active,
    &:focus-within {
        border: 1px solid var(--primary);
    }

    &:isdisabled {
        color: var(--neutral50);
    }
`;
const UnitsWrapper = styled.div`
    display: flex;
    align-items: flex-end;
`;
const UnitInput = styled.input.attrs(
    ({ size, maxLength }: { size?: number; maxLength?: number }) => ({
        size: size || 2,
        maxLength: maxLength || 2,
    }),
)`
    background: transparent;
    border: 0;
    border-radius: var(--r-xs);
    color: var(--black);
    font-size: var(--fs-body);
    outline: 0;
    padding: 0;
    text-align: center;
    width: calc(2ch + 0.25rem);

    &:isdisabled {
        color: var(--neutral50);
    }
`;
const EndIconWrapper = styled.div`
    display: flex;
    margin-left: auto;
`;
const Divider = styled.span<{ isDisabled?: boolean }>`
    color: ${(props) => (props.isDisabled ? 'var(--neutral500)' : 'var(--black)')};
    font-size: var(--fs-body);
    text-align: center;
`;

export default InputDuration;
