import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { fetchPlaylists } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import playlistKeys from '@/queries/playlist/playlistKeys';
import { z } from 'zod';

export const PlaylistSchema = z.object({
    createdAt: z.string().nullable(),
    htmlDescription: z.string().nullable(),
    id: z.number(),
    imageUrl: z.string().nullable(),
    name: z.string(),
    password: z.string().nullable(),
    podcastsCount: z.number(),
    privacy: z.enum(['private', 'unlisted', 'public']),
    publicId: z.string(),
    showId: z.number(),
    slug: z.string(),
    updatedAt: z.string().nullable(),
    url: z.string(),
});
const PlaylistsSchema = z.array(PlaylistSchema);

export type Playlist = z.infer<typeof PlaylistSchema>;
type Key = QueryFunctionContext<ReturnType<typeof playlistKeys.listByShowId>>;

const queryFn = async ({ queryKey: [{ showId }] }: Key) => {
    const { data } = await fetchPlaylists(showId);

    return PlaylistsSchema.parse(camelcaseKeys(data, { deep: true }));
};

const usePlaylistsQuery = (showId: string) => {
    return useQuery({
        queryKey: playlistKeys.listByShowId(showId),
        queryFn,
        enabled: !!showId,
    });
};

export default usePlaylistsQuery;
