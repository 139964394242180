import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchApplePodcastsReviews } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import statsKeys from './statsKeys';
import { z } from 'zod';

export const AppleReviewSchema = z.object({
    author: z.string(),
    description: z.string(),
    rating: z.number(),
    title: z.string(),
    timestamp: z.string(), // This is not a timestamp at all, it's a date in the format 2025-03-19 16:01:12
});
const AppleReviewsSchema = z.array(AppleReviewSchema);
const AppleReviewsPaginationSchema = z.object({
    total: z.number(),
    totalPages: z.number(),
    currentPage: z.number(),
});
const AppleReviewsResponseSchema = z.object({
    data: AppleReviewsSchema,
    meta: z.object({
        pagination: AppleReviewsPaginationSchema,
    }),
});
export type AppleReview = z.infer<typeof AppleReviewSchema>;
type Key = QueryFunctionContext<ReturnType<typeof statsKeys.appleReviews>>;
type InputProps = {
    showId: string;
    page: number;
};

const queryFn = async ({ queryKey: [{ showId, page }] }: Key) => {
    const response = await fetchApplePodcastsReviews(showId, page);
    return AppleReviewsResponseSchema.parse(camelcaseKeys(response, { deep: true }));
};

const useAppleReviewsQuery = ({ showId, page }: InputProps) => {
    return useQuery({
        queryKey: statsKeys.appleReviews(showId, page),
        queryFn,
    });
};

export default useAppleReviewsQuery;
