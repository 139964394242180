import { useRef } from 'react';
import { useToggleState } from 'react-stately';
import { useFocusRing, useSwitch, VisuallyHidden } from 'react-aria';
import Text from '../Text';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

interface ToggleProps {
    isDisabled?: boolean;
    isSelected?: boolean;
    onChange?: (value: boolean) => void;
    value?: string;
    name?: string;
    isReadOnly?: boolean;
    autoFocus?: boolean;
    onFocus?: () => void;
    onBlur?: () => void;
    onFocusChange?: () => void;
    onKeyDown?: () => void;
    onKeyUp?: () => void;
    id?: string;
    excludeFromTabOrder?: boolean;
    children?: React.ReactNode;
    label?: string | React.ReactNode;
}

const Toggle = (props: ToggleProps) => {
    let state = useToggleState(props);
    let ref = useRef<HTMLInputElement>(null);
    let { inputProps } = useSwitch(props, state, ref);
    let { isFocusVisible, focusProps } = useFocusRing();

    return (
        <StyledLabel isDisabled={props.isDisabled}>
            <VisuallyHidden>
                <input {...inputProps} {...focusProps} ref={ref} />
            </VisuallyHidden>
            <ToggleOuterWrapper>
                {props.label && <Label>{props.label}</Label>}
                <ToggleInnerWrapper>
                    <ToggleElement>
                        <ToggleBackground
                            $isSelected={state.isSelected}
                            isFocusVisible={isFocusVisible}
                        />
                        <ToggleHandle
                            $isSelected={state.isSelected}
                            layout
                            transition={{
                                type: 'spring',
                                stiffness: 700,
                                damping: 30,
                            }}
                        />
                    </ToggleElement>
                    {props.children}
                </ToggleInnerWrapper>
            </ToggleOuterWrapper>
        </StyledLabel>
    );
};

const StyledLabel = styled(Text).attrs({ forwardedAs: 'label' })<{ isDisabled?: boolean }>`
    display: flex;
    align-items: center;
    opacity: ${(p) => (p.isDisabled ? 0.5 : 1)};
    gap: 0.5rem;
    position: relative; // Use this because of VisuallyHidden styles causing issues with the input position
    cursor: ${(p) => (p.isDisabled ? 'not-allowed' : 'pointer')};
`;
const ToggleOuterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
`;
const ToggleInnerWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;
const ToggleElement = styled.div`
    width: 40px;
    position: relative;
`;
const ToggleBackground = styled.div<{ $isSelected: boolean; isFocusVisible: boolean }>`
    width: 40px;
    height: 20px;
    border-radius: var(--r-full);
    background: ${(p) => (p.$isSelected ? 'var(--gradient-primary)' : 'var(--neutral300)')};

    ${ToggleElement}:hover & {
        filter: brightness(85%);
    }

    ${(p) =>
        p.isFocusVisible &&
        css`
            box-shadow: 0 0 0 2px var(--primary);
        `}
`;
const ToggleHandle = styled(motion.div)<{ $isSelected: boolean }>`
    position: absolute;
    top: 2px;
    background: white;
    height: 16px;
    width: 16px;
    border-radius: var(--r-full);

    ${(p) =>
        !p.$isSelected &&
        css`
            left: 2px;
        `}

    ${(p) =>
        p.$isSelected &&
        css`
            right: 2px;
        `}
`;
const Label = styled.label`
    font-weight: var(--fw-semibold);
`;

export default Toggle;
