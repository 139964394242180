import { fetchCampaignMonetization } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import { useQuery } from '@tanstack/react-query';
import monetizationPlatformKeys from '@/queries/monetizationPlatform/monetizationPlatformKeys';

const queryFn = async ({ queryKey: [{ showId }] }) => {
    const { data } = await fetchCampaignMonetization(showId);

    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const useMonetizationPlatformQuery = (showId) => {
    return useQuery({
        queryKey: monetizationPlatformKeys.detailByShowId(showId),
        queryFn,
    });
};

export default useMonetizationPlatformQuery;
