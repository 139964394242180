import { z } from 'zod';

export const ClipFormatSchema = z.object({
    id: z.number(),
    createdAt: z.string().nullable(),
    format: z.enum(['square', 'story', 'wide']),
    fullCover: z.boolean(),
    images: z.array(
        z.object({
            format: z.string(),
            url: z.string(),
        }),
    ),
    previewUrl: z.string().nullable(),
    state: z.string(),
    updatedAt: z.string().nullable(),
    url: z.string().nullable(),
    clip: z.object({
        adminTitle: z.string().nullable(),
        caption: z.string().nullable(),
        color: z.string().nullable(),
        createdAt: z.string().nullable(),
        duration: z.number().nullable(),
        end: z.number().nullable(),
        id: z.number().nullable(),
        lang: z.string().nullable(),
        episodeId: z.number().nullable(),
        showTitles: z.boolean().nullable(),
        showWaveform: z.boolean().nullable(),
        start: z.number().nullable(),
        step: z.string().nullable(),
        title: z.string().nullable(),
        titleColor: z.string().nullable(),
        transcript: z.boolean().nullable(),
        updatedAt: z.string().nullable(),
    }),
});
