import { useState } from 'react';
import { Link as RRDLink } from 'react-router-dom';
import { LinkProps, RouterLinkProps } from '../types';
import { primaryLinkStyles } from '../styles/variants/primary';
import { secondaryLinkStyles } from '../styles/variants/secondary';
import { dangerLinkStyles } from '../styles/variants/danger';
import { smallLinkStyles } from '../styles/sizes/small';
import { mediumLinkStyles } from '../styles/sizes/medium';
import { largeLinkStyles } from '../styles/sizes/large';
import styled, { css } from 'styled-components';
import { primaryButtonStyles } from '@/components/Button/styles/variants/primary';
import { secondaryButtonStyles } from '@/components/Button/styles/variants/secondary';
import { tertiaryButtonStyles } from '@/components/Button/styles/variants/tertiary';
import { ghostButtonStyles } from '@/components/Button/styles/variants/ghost';
import { dangerButtonStyles } from '@/components/Button/styles/variants/danger';
import {
    MagicButtonParticle,
    MagicButtonParticles,
    MagicButtonParticlesWrapper,
    magicButtonStyles,
    useButtonParticles,
} from '@/components/Button/styles/variants/magic';
import { smallButtonStyles } from '@/components/Button/styles/sizes/small';
import { mediumButtonStyles } from '@/components/Button/styles/sizes/medium';
import { largeButtonStyles } from '@/components/Button/styles/sizes/large';

const RouterLink = ({
    children,
    variant = 'primary',
    size = 'medium',
    startIcon,
    endIcon,
    isDisabled = false,
    ...props
}: RouterLinkProps) => {
    const [isHovered, setIsHovered] = useState(false);
    const nbParticules = size === 'medium' ? 32 : size === 'large' ? 48 : 16;
    const particles = useButtonParticles({ isHovered, nbParticules });

    return (
        <BaseLink
            {...props}
            role="link"
            aria-disabled={isDisabled}
            data-disabled={isDisabled}
            $isLoading={false}
            $variant={variant}
            $size={size}
            onHoverStart={() => setIsHovered(true)}
            onHoverEnd={() => setIsHovered(false)}
        >
            {startIcon}
            {children}
            {endIcon}
            {variant === 'button-magic' && (
                <MagicButtonParticlesWrapper>
                    <MagicButtonParticles>
                        {particles.map(({ id, ...particle }) => (
                            <MagicButtonParticle key={id} {...particle} />
                        ))}
                    </MagicButtonParticles>
                </MagicButtonParticlesWrapper>
            )}
        </BaseLink>
    );
};

const variantStyles = {
    primary: primaryLinkStyles,
    secondary: secondaryLinkStyles,
    danger: dangerLinkStyles,
};
const sizeStyles = {
    small: smallLinkStyles,
    medium: mediumLinkStyles,
    large: largeLinkStyles,
};
const buttonVariantStyles = {
    'button-primary': primaryButtonStyles,
    'button-secondary': secondaryButtonStyles,
    'button-tertiary': tertiaryButtonStyles,
    'button-ghost': ghostButtonStyles,
    'button-danger': dangerButtonStyles,
    'button-magic': magicButtonStyles,
};
const buttonSizeStyles = {
    small: smallButtonStyles,
    medium: mediumButtonStyles,
    large: largeButtonStyles,
};
const buttonStyles = css<{
    $size: LinkProps['size'];
    $variant: LinkProps['variant'];
    $isLoading: boolean;
}>`
    ${({ $variant }) => buttonVariantStyles[$variant as keyof typeof buttonVariantStyles]}
    ${({ $size }) => buttonSizeStyles[$size as keyof typeof buttonSizeStyles]}
    text-decoration: none;
`;

const linkStyles = css<{ $size: LinkProps['size']; $variant: LinkProps['variant'] }>`
    ${({ $variant }) => variantStyles[$variant as keyof typeof variantStyles]}
    ${({ $size }) => sizeStyles[$size as keyof typeof sizeStyles]}
`;

const BaseLink = styled(RRDLink)<{
    $variant: LinkProps['variant'];
    $size: LinkProps['size'];
    $isLoading: boolean;
    onHoverStart?: () => void;
    onHoverEnd?: () => void;
}>`
    ${({ $variant }) => ($variant && $variant.startsWith('button-') ? buttonStyles : linkStyles)}
`;

export default RouterLink;
