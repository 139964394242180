import Zebra from '@public/images/zebra.svg?url';
import styled, { css } from 'styled-components';

interface ZebraIllustrationProps {
    foreground?: string;
    children?: React.ReactNode;
}

const ZebraIllustration = ({ foreground, children }: ZebraIllustrationProps) => {
    return (
        <ZebraIllustrationWrapper>
            <Background src={Zebra} />
            {foreground ? (
                <Foreground src={foreground} />
            ) : (
                children && <ChildrenWrapper>{children}</ChildrenWrapper>
            )}
        </ZebraIllustrationWrapper>
    );
};

const ZebraIllustrationWrapper = styled.div`
    position: relative;
    width: 4rem;
    aspect-ratio: 1/1;
`;

const Background = styled.img`
    width: 100%;
    height: 100%;
`;

const foregroundBase = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const Foreground = styled.img`
    ${foregroundBase};
    width: 2.5rem;
    height: 2.5rem;
`;

const ChildrenWrapper = styled.div`
    ${foregroundBase};
`;

export default ZebraIllustration;
