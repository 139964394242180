import { useMutation } from '@tanstack/react-query';
import { addSurvey } from '@/api';

export const useSubmitSurvey = () => {
    return useMutation({
        mutationFn: (data) => {
            return addSurvey(data);
        },
    });
};
