import { useMutation, useQueryClient } from '@tanstack/react-query';
import updateShowMutation from '@queries/show/updateShowMutation.hook';
import deleteShowImageMutation from '@queries/show/deleteShowImageMutation';
import showKeys from '@queries/show/showKeys';
import { ShowEditFormSchema } from './ShowEditForm/useShowEditFormSchema.hook';
import useUploadShowImageMutation from '@queries/show/useUploadShowImageMutation.hook';

interface saveShowEditMutationProps {
    showId: string;
    data: ShowEditFormSchema;
}

const saveShowEditMutation = () => {
    const queryClient = useQueryClient();
    const updateShow = updateShowMutation();
    const uploadShowImage = useUploadShowImageMutation();
    const deleteShowImage = deleteShowImageMutation();

    return useMutation({
        mutationFn: ({ showId, data }: saveShowEditMutationProps) => {
            const { imageUrl, ...settings } = data;
            const promises = [];

            if (imageUrl && imageUrl instanceof File) {
                const imageUrlPromise = uploadShowImage.mutateAsync({
                    showId: Number(showId),
                    image: imageUrl,
                });
                promises.push(imageUrlPromise);
            }

            if (imageUrl === null) {
                const imageUrlPromise = deleteShowImage.mutateAsync(showId);
                promises.push(imageUrlPromise);
            }

            if (Object.keys(settings).length > 0) {
                const playerSettingsPromise = updateShow.mutateAsync({
                    showId,
                    show: settings,
                });
                promises.push(playerSettingsPromise);
            }

            // Waiting for all promises to be fulfilled or rejected
            return Promise.all(promises);
        },
        onSuccess: (_, { showId }) => {
            queryClient.invalidateQueries({ queryKey: showKeys.detailById(showId) });
        },
    });
};

export default saveShowEditMutation;
