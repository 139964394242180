import { useMutation } from '@tanstack/react-query';
import { updatePlaylist } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import { PlaylistFormSchema } from '@/components/Playlists/Playlist/PlaylistForm/usePlaylistFormSchema.hook';

interface MutationFnProps {
    playlistId: string;
    playlist: PlaylistFormSchema;
}

const mutationFn = async ({ playlistId, playlist }: MutationFnProps) => {
    const { data } = await updatePlaylist(playlistId, playlist);

    return camelcaseKeys(data as any, { deep: true });
};

const updatePlaylistMutation = () => {
    return useMutation({
        mutationFn,
    });
};

export default updatePlaylistMutation;
