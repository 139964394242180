import { useMutation } from '@tanstack/react-query';
import { deleteUploadedMessageImage } from '@/api';
import camelcaseKeys from 'camelcase-keys';

const mutationFn = async (postId: number) => {
    const { data } = await deleteUploadedMessageImage(postId);
    return camelcaseKeys(data, { deep: true });
};

const deleteSocialNetworkPostImageMutation = () => {
    return useMutation({
        mutationFn,
        onSuccess: () => {
            // Query invalidation needed?
        },
    });
};

export default deleteSocialNetworkPostImageMutation;
