import { useQuery } from '@tanstack/react-query';
import { fetchEmailSettingsNotification } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import emailNotificationSettingsKeys from '@/queries/emailNotificationSettings/emailNotificationSettingsKeys';
import { z } from 'zod';

export const EmailNotificationSettingsSchema = z.object({
    scheduledPodcast: z.boolean(),
    youtubeGenerated: z.boolean(),
});
export type EmailNotificationSettings = z.infer<typeof EmailNotificationSettingsSchema>;

const queryFn = async () => {
    const { data } = await fetchEmailSettingsNotification();

    // TODO: Do camelcase transformation in Axios interceptor
    return EmailNotificationSettingsSchema.parse(camelcaseKeys(data, { deep: true }));
};

const useEmailNotificationSettingsQuery = () => {
    return useQuery({
        queryKey: emailNotificationSettingsKeys.detail(),
        queryFn,
    });
};

export default useEmailNotificationSettingsQuery;
