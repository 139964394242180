import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';
import { TooltipTrigger } from 'react-aria-components';
import TitleRules from './TitleRules';
import DescriptionRules from './DescriptionRules';
import TagsRules from './TagsRules';
import { usePsoCheckerContext } from '@/context/PsoCheckerContext';
import styled from 'styled-components';

const TargetKeyword = () => {
    const { setPsoCheckerScore } = usePsoCheckerContext();
    const [titleScore, setTitleScore] = useState<number>(0);
    const [descriptionScore, setDescriptionScore] = useState<number>(0);
    const [tagsScore, setTagsScore] = useState<number>(0);

    useEffect(() => {
        setPsoCheckerScore(Math.round((titleScore + descriptionScore + tagsScore) / 3));
    }, [titleScore, descriptionScore, tagsScore]);

    return (
        <Wrapper>
            <HeaderWrapper>
                <TitleAndDescriptionWrapper>
                    <TitleWrapper>
                        <TitleIcon icon={icon({ name: 'list-check', style: 'solid' })} />
                        <Title>
                            <FormattedMessage defaultMessage="Recommandations" />
                        </Title>
                        <TooltipTrigger delay={0} closeDelay={0}>
                            <TooltipTriggerWrapper>
                                <HelpIcon icon={icon({ name: 'info-circle', style: 'solid' })} />
                            </TooltipTriggerWrapper>
                            <Tooltip placement="top">
                                <FormattedMessage
                                    defaultMessage="Ces recommandations sont des pratiques PSO personnalisées conçues pour améliorer la visibilité de votre épisode dans les résultats de recherche. <link>En savoir plus sur ces conseils</link>."
                                    values={{
                                        link: (chunks: string) => (
                                            <TooltipLink
                                                href="http://www.koalastothemax.com/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {chunks}
                                            </TooltipLink>
                                        ),
                                    }}
                                />
                            </Tooltip>
                        </TooltipTrigger>
                    </TitleWrapper>
                    <Description>
                        <FormattedMessage defaultMessage="Appliquez les recommandations ci-dessous pour maximiser la visibilité de votre épisode dans les résultats de recherche sur les plateformes d'écoute." />
                    </Description>
                </TitleAndDescriptionWrapper>
            </HeaderWrapper>
            <TitleRules setScore={(score: number) => setTitleScore(score)} />
            <Separator />
            <DescriptionRules setScore={(score: number) => setDescriptionScore(score)} />
            <Separator />
            <TagsRules setScore={(score: number) => setTagsScore(score)} />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
`;
const TitleAndDescriptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;
const TitleIcon = styled(FontAwesomeIcon)`
    color: var(--primary);
`;
const Title = styled.div`
    font-size: var(--fs-body-l);
    font-weight: var(--fw-semibold);
`;
const HelpIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
`;
const Description = styled.p`
    color: var(--neutral500);
`;
const Separator = styled.div`
    width: 100%;
    height: 1px;
    background-color: var(--neutral100);
    margin-block: 1.5rem;
`;
const TooltipLink = styled.a`
    color: inherit;
`;

export default TargetKeyword;
