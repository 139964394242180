import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import useCreateNewShowFormSchema from '@/features/onboarding/components/Onboarding/CreateNewShow/CreateNewShowForm/useCreateNewShowFormSchema.hook';
import useUserQuery from '@queries/user/useUserQuery.hook';
import useLanguagesQuery from '@queries/language/useLanguagesQuery.hook';
import { useEffect, useState } from 'react';
import useCategoryItems from '@/shared/hooks/useCategoryItems.hook';
import useCategoriesQuery from '@queries/category/useCategoriesQuery.hook';
import useUploadShowImageMutation from '@queries/show/useUploadShowImageMutation.hook';
import useChannelQuery from '@queries/channel/useChannelQuery.hook';
import useCreateShowMutation from '@queries/show/useCreateShowMutation.hook';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { InputTextController } from '@ui/atoms/InputText';
import { InputRichTextController } from '@ui/atoms/InputRichText';
import { ComboBoxController } from '@/components/ui/atoms/ComboBox';
import ListBoxItem from '@ui/atoms/ListBoxItem';
import UiKitTooltip from '@ui/atoms/UiKitTooltip';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Collection, ListBoxSection } from 'react-aria-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useSubscriptionQuery from '@queries/subscription/useSubscriptionQuery.hook';
import Text from '@ui/atoms/Text';
import Alert from '@ui/atoms/Alert';
import Button from '@/components/Button';
import { InputImgUploaderController } from '@ui/molecules/InputImgUploader';

const CreateNewShowForm = () => {
    const intl = useIntl();
    const history = useHistory();
    const { data: user } = useUserQuery();
    const { data: channel } = useChannelQuery();
    const { data: categories, isLoading: categoryIsLoading } = useCategoriesQuery();
    const { data: languages } = useLanguagesQuery();
    const { data: subscription } = useSubscriptionQuery();
    const uploadImage = useUploadShowImageMutation();
    const createShow = useCreateShowMutation();

    const [displayTitleAlert, setDisplayTitleAlert] = useState(
        !localStorage.getItem('create-show-title-alert-closed'),
    );
    const [displayDescriptionAlert, setDisplayDescriptionAlert] = useState(
        !localStorage.getItem('create-show-description-alert-closed'),
    );

    const schema = useCreateNewShowFormSchema({ locale: user?.language });
    type CreateNewShowFormSchema = z.infer<typeof schema>;

    const categoryItems = useCategoryItems(categories);
    const languageItems = languages
        ?.sort((a, b) => (a.isFeatured ? -1 : b.isFeatured ? 1 : 0))
        .map((language) => ({
            id: language.id,
            name: language.nativeName,
        }));
    const defaultLanguage = languages?.find((language) => language.code === user?.language)?.id;

    const { control, handleSubmit, formState, setValue } = useForm<CreateNewShowFormSchema>({
        mode: 'onChange',
        defaultValues: {
            cover: null,
            name: '',
            htmlDescription: '',
            author: `${user?.firstname} ${user?.lastname}`,
            languageId: defaultLanguage,
            categoryId: undefined,
        },
        resolver: zodResolver(schema),
    });

    useEffect(() => {
        if (defaultLanguage) {
            setValue('languageId', defaultLanguage);
        }
    }, [defaultLanguage]);

    const handleCloseTitleAlert = () => {
        setDisplayTitleAlert(false);
        localStorage.setItem('create-show-title-alert-closed', 'true');
    };
    const handleCloseDescriptionAlert = () => {
        setDisplayDescriptionAlert(false);
        localStorage.setItem('create-show-description-alert-closed', 'true');
    };

    const onSubmit = (formData: CreateNewShowFormSchema) => {
        const channelId = channel?.id;

        if (!channelId) return;
        const { cover, ...createShowData } = formData;
        createShow.mutate(
            {
                channelId,
                show: createShowData,
            },
            {
                onSuccess: (data) => {
                    if (!formData.cover) {
                        history.replace(`/app/onboarding/create/success?showId=${data.id}`);
                        return;
                    }
                    uploadImage.mutate(
                        { showId: data.id, image: formData.cover },
                        {
                            onSuccess: () => {
                                history.replace(`/app/onboarding/create/success?showId=${data.id}`);
                            },
                        },
                    );
                },
            },
        );
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <FormWrapper>
                <InputImgUploaderController
                    control={control}
                    name="cover"
                    label={<FormattedMessage defaultMessage="Illustration du podcast" />}
                    description={
                        <Text color="--neutral500">
                            <FormattedMessage
                                defaultMessage=".JPG, ou .PNG, jusqu'à 10Mo{linebreak}Format carré, 400 x 400px min. (1400 x 1400px recommandé)"
                                values={{ linebreak: <br /> }}
                            />
                        </Text>
                    }
                />
                <InputWrapper>
                    <InputTextController
                        control={control}
                        name="name"
                        id="name"
                        label={<FormattedMessage defaultMessage="Titre de l'émission" />}
                        placeholder={intl.formatMessage({
                            defaultMessage: 'Quel est le nom de votre podcast ?',
                        })}
                        errorMessage={formState.errors.name?.message}
                        isRequired={true}
                        maxLength={140}
                        displayErrorIcon={false}
                    />
                    {displayTitleAlert && (
                        <Alert variant="robot" closable onClose={handleCloseTitleAlert}>
                            <Text fontWeight="--fw-semibold">
                                <FormattedMessage defaultMessage="Choisissez un nom percutant, aligné avec le contenu de votre podcast ! Assurez-vous qu’il ne soit pas déjà utilisé et ajoutez des mots-clés pour que vos auditeurs cibles vous trouvent facilement." />
                            </Text>
                        </Alert>
                    )}
                </InputWrapper>
                <InputWrapper>
                    <InputRichTextController
                        name="htmlDescription"
                        control={control}
                        label={<FormattedMessage defaultMessage="Description de l'émission" />}
                        placeholder={intl.formatMessage({
                            defaultMessage: 'De quoi parle votre podcast ?',
                        })}
                        charactersLimit={3900}
                        htmlLinksDisabled={subscription?.isFreemium}
                        htmlLinksDisabledReason={
                            <FormattedMessage defaultMessage="L'ajout de liens n'est disponible que pour les offres payantes. 🙏 " />
                        }
                    />
                    {displayDescriptionAlert && (
                        <Alert variant="robot" closable onClose={handleCloseDescriptionAlert}>
                            <Text fontWeight="--fw-semibold">
                                <FormattedMessage defaultMessage="Créez une présentation attrayante de votre podcast pour donner envie à de nouveaux auditeurs de vous découvrir et de s'abonner. Rédigez la description la plus longue possible et intégrez un maximum de mots-clés pour que votre audience cible vous découvre." />
                            </Text>
                        </Alert>
                    )}
                </InputWrapper>
                <InputTextController
                    control={control}
                    name="author"
                    id="author"
                    label={<FormattedMessage defaultMessage="Auteur" />}
                    errorMessage={formState.errors.author?.message}
                    isRequired={true}
                    maxLength={255}
                    displayErrorIcon={false}
                />
                <LanguageAndCategoryWrapper>
                    <ComboBoxController
                        control={control}
                        name="languageId"
                        label={<FormattedMessage defaultMessage="Langue" />}
                        defaultItems={languageItems || []}
                        isRequired={true}
                    >
                        {(item: any) => <ListBoxItem {...item} />}
                    </ComboBoxController>
                    <ComboBoxController
                        name="categoryId"
                        control={control}
                        defaultItems={categoryItems}
                        label={<FormattedMessage defaultMessage="Catégorie" />}
                        placeholder={intl.formatMessage({
                            defaultMessage: 'Choisissez une catégorie',
                        })}
                        tooltip={
                            <UiKitTooltip
                                content={
                                    <FormattedMessage defaultMessage="Les catégories aident vos auditeurs à trouver des contenus liés à un thème spécifique. La liste des catégories est définie par les plateformes d’écoute, y compris Apple Podcasts." />
                                }
                                passthrough={false}
                                className=""
                                position="top"
                                variant="dark"
                            >
                                <TooltipInfoIcon
                                    icon={icon({ name: 'circle-info', style: 'solid' })}
                                />
                            </UiKitTooltip>
                        }
                        isLoading={categoryIsLoading}
                        isRequired={true}
                    >
                        {/* @ts-ignore */}
                        {({ children, ...section }) => (
                            <ListBoxSection id={section.name}>
                                <ListBoxItem {...section} />
                                <Collection items={children}>
                                    {(item) => <ListBoxItem {...item} />}
                                </Collection>
                            </ListBoxSection>
                        )}
                    </ComboBoxController>
                </LanguageAndCategoryWrapper>
            </FormWrapper>
            <ActionWrapper>
                <Button
                    variant="link-secondary"
                    startIcon={<ArrowIcon icon={icon({ name: 'arrow-left', style: 'solid' })} />}
                    onPress={() => history.goBack()}
                >
                    <FormattedMessage id="goBack" defaultMessage="Retour" />
                </Button>
                <Button
                    variant="primary"
                    isDisabled={!formState.isValid}
                    isLoading={createShow.isLoading || uploadImage.isLoading}
                    endIcon={
                        <FontAwesomeIcon icon={icon({ name: 'arrow-right', style: 'solid' })} />
                    }
                    type="submit"
                >
                    <FormattedMessage defaultMessage="Créez votre podcast" />
                </Button>
            </ActionWrapper>
        </Form>
    );
};

const FormWrapper = styled.div`
    padding: 2rem;
    background: white;
    border-radius: var(--r-l);
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;
const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
`;
const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;
const LanguageAndCategoryWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 2rem;

    & > div {
        flex-grow: 1;
        max-width: none;
    }

    @media (max-width: 580px) {
        flex-direction: column;
    }
`;
const ActionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    ${(p) => p.theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
        justify-content: space-between;
    }
`;
const TooltipInfoIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
`;
const ArrowIcon = styled(FontAwesomeIcon)`
    margin-right: 0.25rem;
`;

export default CreateNewShowForm;
