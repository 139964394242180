import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import ShowGuests from './ShowGuests';
import { USER_OPTIONS } from '@/shared/config/userOptions';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import { useGuard } from '@/shared/hooks/useGuard.hook';
import { ENABLE_NON_HOSTING_EP_MGT, USER_ROLES } from '@/shared/config/constants';
import Layout from './Layout';
import Seasons from '@/components/unorganized/Seasons';
import RssExport from '@/components/unorganized/RssExport';
import RssImport from '@/components/unorganized/RssImport';
import ArchiveAndDelete from './ArchiveAndDelete';
import Broadcast from './Broadcast';
import AushaPro from './AushaPro';
import AI from './AI';
import Details from './Details';
import useHasAccessToAushaForAll from '@/shared/hooks/useHasAccessToAushaForAll.hook';
import Hosting from './Hosting';

const Settings = () => {
    const { showId } = useParams<{ showId: string }>();
    const { data: show } = useShowQuery(showId);
    const { data: user } = useUserQuery();

    const showHasHostingAccess = show?.hostedOnAusha === true;
    const hasAccessToWhiteLabel = show?.userOptions.includes(USER_OPTIONS.WHITE_LABEL);
    const hasAccessToCustomLogo = show?.userOptions.includes(USER_OPTIONS.CUSTOMIZABLE_LOGO);
    const hasAccessToAushaProSettings = hasAccessToWhiteLabel || hasAccessToCustomLogo;
    const hasAccessToUsersManagement =
        !user?.options?.includes(USER_OPTIONS.MULTI_USERS_MANAGEMENT) || user?.guest;
    const roleGuard = useGuard({
        disallowedUserRoles: [USER_ROLES.VIEWER, USER_ROLES.EDITOR],
    });
    const hostingDefaultRoute = `/app/show/${showId}/episodes`;
    const nonHostingDefaultRoute = ENABLE_NON_HOSTING_EP_MGT
        ? `/app/show/${showId}/workspace`
        : `/app/show/${showId}/pso`;
    const defaultRoute = showHasHostingAccess ? hostingDefaultRoute : nonHostingDefaultRoute;
    const hasAccessToAushaForAll = useHasAccessToAushaForAll();

    if (roleGuard.allow === false) {
        return <Redirect to={defaultRoute} />;
    }

    return (
        <Switch>
            <Route path="/app/show/:showId/settings/info">
                <Layout>
                    <Details />
                </Layout>
            </Route>
            <Route path="/app/show/:showId/settings/broadcast">
                <Layout>
                    <Broadcast />
                </Layout>
            </Route>
            {showHasHostingAccess && (
                <Route path="/app/show/:showId/settings/seasons">
                    <Layout>
                        {/* TODO: Refactor seasons */}
                        <Seasons />
                    </Layout>
                </Route>
            )}
            {hasAccessToUsersManagement && (
                <Route path="/app/show/:showId/settings/users">
                    <Layout>
                        <ShowGuests />
                    </Layout>
                </Route>
            )}
            {hasAccessToAushaProSettings && (
                <Route path="/app/show/:showId/settings/pro">
                    <Layout>
                        <AushaPro />
                    </Layout>
                </Route>
            )}
            <Route path="/app/show/:showId/settings/ai">
                <Layout>
                    <AI />
                </Layout>
            </Route>
            {(hasAccessToAushaForAll || showHasHostingAccess) && (
                <Route path="/app/show/:showId/settings/rss-export">
                    <Layout>{hasAccessToAushaForAll ? <Hosting /> : <RssExport />}</Layout>
                </Route>
            )}
            {showHasHostingAccess && (
                <Route path="/app/show/:showId/settings/rss-import">
                    <Layout>
                        {/* TODO: Refactor RSS import */}
                        <RssImport />
                    </Layout>
                </Route>
            )}
            <Route path="/app/show/:showId/settings/show-delete">
                <Layout>
                    <ArchiveAndDelete />
                </Layout>
            </Route>
        </Switch>
    );
};

export default Settings;
