import { useParams } from 'react-router-dom';
import itunes from '@public/images/platforms/ApplePodcasts.png';
import ShortListFallback from '@app/fallbacks/ShortListFallback';
import BroadcastCard from '../../BroadcastCard';
import { LISTENING_PLATFORMS_NAMES, APPLE_PODCASTS } from '@/utils/constants';
import useShowQuery from '@/queries/show/useShowQuery.hook';

const AppleBroadcast = () => {
    const { showId } = useParams<{ showId: string }>();
    const { data: show, isLoading } = useShowQuery(showId);
    const url = show?.listeningLinks?.data.find((link) => link.key === APPLE_PODCASTS)?.url;

    if (isLoading) return <ShortListFallback />;

    return (
        <BroadcastCard
            platformKey={APPLE_PODCASTS}
            name={LISTENING_PLATFORMS_NAMES[APPLE_PODCASTS]}
            logo={itunes}
            url={url}
        />
    );
};

export default AppleBroadcast;
