import styled, { css } from 'styled-components';

const PlatformSkeleton = () => {
    return (
        <PlatformSkeletonWrapper>
            <PlatformCover />
            <Right>
                <Top />
                <Bottom />
            </Right>
        </PlatformSkeletonWrapper>
    );
};

const PlatformSkeletonWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;
const PlatformCover = styled.div`
    width: 2.5rem;
    height: 2.5rem;
    border-radius: var(--r-s);
    background-color: var(--neutral50);
`;
const Right = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
`;
const BarBase = css`
    height: 0.75rem;
    border-radius: var(--r-full);
`;
const Top = styled.div`
    ${BarBase};
    width: 10rem;
    background-color: var(--neutral200);
`;
const Bottom = styled.div`
    ${BarBase};
    width: 5rem;
    background-color: var(--neutral100);
`;

export default PlatformSkeleton;
