import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import useNonHostingEpisodeAudioFileSchema, {
    NonHostingEpisodeAudioFileSchema,
} from '@/components/NonHostingEpisodeForm/useNonHostingEpisodeAudioFileSchema.hook';
import AudioFileInput from './AudioFileInput';
import { useQueryClient } from '@tanstack/react-query';
import uploadCampaignAudioFileMutation from '@/queries/campaign/uploadCampaignAudioFileMutation';
import campaignKeys from '@/queries/campaign/campaignKeys';

interface NoFileProps {
    onUpload: ReturnType<typeof uploadCampaignAudioFileMutation>['mutate'];
}

const NoFile = ({ onUpload }: NoFileProps) => {
    const intl = useIntl();
    const { campaignId } = useParams<{ campaignId: string }>();
    const schema = useNonHostingEpisodeAudioFileSchema();
    const queryClient = useQueryClient();

    const { control, watch, handleSubmit, setError } = useForm<NonHostingEpisodeAudioFileSchema>({
        mode: 'onChange',
        resolver: zodResolver(schema),
        defaultValues: {
            audioFile: null,
        },
    });

    useEffect(() => {
        const subscription = watch(() => {
            handleSubmit(onSubmit)();
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    const onSubmit = ({ audioFile }: NonHostingEpisodeAudioFileSchema) => {
        if (!audioFile || typeof audioFile === 'string') return;

        onUpload(
            { campaignId, file: audioFile[0] },
            {
                onSuccess: () => {
                    queryClient.invalidateQueries({
                        queryKey: campaignKeys.detailById(campaignId),
                    });
                },
                onError: () =>
                    setError('root.serverError', {
                        type: '400',
                        message: intl.formatMessage({ defaultMessage: 'Une erreur est survenue' }),
                    }),
            },
        );
    };

    return (
        <Controller
            control={control}
            name="audioFile"
            render={({
                field: { value, ...field },
                formState: { errors },
                fieldState: { error },
            }) => (
                <AudioFileInput
                    {...field}
                    error={error?.message ?? errors?.root?.serverError?.message}
                />
            )}
        />
    );
};

export default NoFile;
