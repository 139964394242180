import { useMutation } from '@tanstack/react-query';
import { loginSharingProvider } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import { SOCIAL_NETWORK } from '@/shared/config/constants';

const transform = (data) => {
    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const mutationFn = async ({ showId, platform, callbackData }) => {
    const { data } = await loginSharingProvider(showId, {
        provider: platform,
        query: callbackData,
    });
    // Allows to choose linkedin account on next step
    const maybeSeveralAccounts =
        [SOCIAL_NETWORK.LINKEDIN, SOCIAL_NETWORK.INSTAGRAM].indexOf(platform) !== -1;
    const response = maybeSeveralAccounts ? data : data[0];
    return transform(response);
};

const useLoginOnMediaPlatformMutation = () => {
    return useMutation({
        mutationFn,
    });
};

export default useLoginOnMediaPlatformMutation;
