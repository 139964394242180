import styled from 'styled-components';
import AushaHeaderMenu from './AushaHeaderMenu';
import AushaHeaderPlayer from './AushaHeaderPlayer';
import AushaLogo from '@ui/atoms/AushaLogo';

const AushaHeaderWrapper = styled.div`
    height: 2.5rem;
    padding-left: 1rem;
    background: ${(p) => (p.noBackground ? 'none' : 'var(--gradient-primary)')};
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const StyledLogo = styled(AushaLogo)`
    height: 1.25rem;
    width: 4.5rem;
    fill: var(--white);
`;

function AushaHeader({ noBackground }) {
    return (
        <AushaHeaderWrapper noBackground={noBackground}>
            <StyledLogo isComplete />
            <AushaHeaderPlayer />
            <AushaHeaderMenu />
        </AushaHeaderWrapper>
    );
}

export default AushaHeader;
