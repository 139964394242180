import { FormattedMessage, useIntl } from 'react-intl';
import dayjs from 'dayjs';
import objectSupport from 'dayjs/plugin/objectSupport';
import styled from 'styled-components';
import { useParams } from 'react-router';
import useQuery from '@/shared/hooks/useQuery';
import Text from '@/components/ui/atoms/Text';
import usePeakTimePopularDaysQuery from '@/queries/stats/usePeakTimePopularDaysQuery.hook';
import PopularDaysChart from './PopularDaysChart';
import { useResponsive } from '@/shared/hooks/useResponsive';
import { LAUNCH_TIME_MESSAGE } from '../PeakTime';
import ShortListFallback from '@/components/unorganized/ShortListFallback';

dayjs.extend(objectSupport);

const PopularDays = () => {
    const intl = useIntl();
    const { showId } = useParams<{ showId: string }>();
    const { isMobileOrTablet } = useResponsive();
    const query = useQuery();
    const daysRange = (query.get('days') as '7' | '15' | '30' | '90' | 'always') ?? 'always';
    const popularDaysQuery = usePeakTimePopularDaysQuery({ showId, days: daysRange });
    const X_AXIS_CATEGORIES_MOBILE = [
        intl.formatMessage({ id: 'week.monday.short', defaultMessage: 'Lun.' }),
        intl.formatMessage({ id: 'week.tuesday.short', defaultMessage: 'Mar.' }),
        intl.formatMessage({ id: 'week.wednesday.short', defaultMessage: 'Mer.' }),
        intl.formatMessage({ id: 'week.thursday.short', defaultMessage: 'Jeu.' }),
        intl.formatMessage({ id: 'week.friday.short', defaultMessage: 'Ven.' }),
        intl.formatMessage({ id: 'week.saturday.short', defaultMessage: 'Sam.' }),
        intl.formatMessage({ id: 'week.sunday.short', defaultMessage: 'Dim.' }),
    ];
    const X_AXIS_CATEGORIES_DESKTOP = [
        intl.formatMessage({ defaultMessage: 'Lundi' }),
        intl.formatMessage({ defaultMessage: 'Mardi' }),
        intl.formatMessage({ defaultMessage: 'Mercredi' }),
        intl.formatMessage({ defaultMessage: 'Jeudi' }),
        intl.formatMessage({ defaultMessage: 'Vendredi' }),
        intl.formatMessage({ defaultMessage: 'Samedi' }),
        intl.formatMessage({ defaultMessage: 'Dimanche' }),
    ];

    if (popularDaysQuery.data) {
        const xAxisCategories = isMobileOrTablet
            ? X_AXIS_CATEGORIES_MOBILE
            : X_AXIS_CATEGORIES_DESKTOP;
        const series = popularDaysQuery.data.map((day) => day.downloads);
        // Use empty array if all values are 0 to avoid rendering bars in the chart
        const formattedSeries = series.some((unit) => unit > 0) ? series : [];

        return (
            <Wrapper>
                <TextWrapper>
                    <Text variant="title" fontWeight="--fw-bold">
                        <FormattedMessage defaultMessage="Jours les plus populaires" />
                    </Text>
                    <Text>
                        {daysRange === 'always' ? (
                            <FormattedMessage defaultMessage="Vos écoutes selon les jours depuis le début" />
                        ) : (
                            <FormattedMessage
                                defaultMessage="Vos écoutes selon les jours sur les {messageLaunchTime}"
                                values={{ messageLaunchTime: LAUNCH_TIME_MESSAGE[daysRange] }}
                            />
                        )}
                    </Text>
                </TextWrapper>
                <ChartContainer>
                    <PopularDaysChart
                        options={{ xaxis: { categories: xAxisCategories } }}
                        series={formattedSeries}
                    />
                </ChartContainer>
            </Wrapper>
        );
    }

    return <ShortListFallback />; // TODO: Improve loading state
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
`;
const ChartContainer = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding-mobile);

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        padding: var(--container-padding);
    }
`;
const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export default PopularDays;
