import useShowQuery from '@/queries/show/useShowQuery.hook';
import { USER_OPTIONS } from '@/shared/config/userOptions';
import { useHistory, useParams } from 'react-router';
import PlaylistForm from '../PlaylistForm';
import { PlaylistFormSchema } from '../PlaylistForm/usePlaylistFormSchema.hook';
import saveNewPlaylistMutation from './saveNewPlaylistMutation';
import { AxiosError } from 'axios';
import { useBodyToastQueue } from '@/shared/hooks/useBodyToastQueue.hook';
import { FormattedMessage } from 'react-intl';

interface AddPlaylistProps {
    onClose: () => void;
}

const AddPlaylist = ({ onClose }: AddPlaylistProps) => {
    const { showId } = useParams<{ showId: string }>();
    const show = useShowQuery(showId);
    const history = useHistory();
    const saveNewPlaylist = saveNewPlaylistMutation();
    const toast = useBodyToastQueue();

    if (show.isSuccess) {
        const hasAccessToPlaylistConfidentiality =
            show.data?.userOptions?.includes(USER_OPTIONS.PLAYLISTS_CONFIDENTIALITY) ?? false;

        const onSubmit = ({ password, ...data }: PlaylistFormSchema) => {
            const formattedData = data.privacy === 'private' ? { ...data, password } : data;
            saveNewPlaylist.mutateAsync(
                {
                    showId,
                    data: formattedData,
                },
                {
                    onSuccess: (playlist) => {
                        history.push(`/app/show/${showId}/playlists/${playlist.id}`);
                        onClose();
                    },
                    onError: (error) => {
                        const err = error as AxiosError;
                        const hasFileError = !!err?.response?.data?.errors?.file;
                        if (err?.response?.status === 422 && hasFileError) {
                            toast.alert(
                                <FormattedMessage defaultMessage="Oups, une erreur est survenue lors de la sauvegarde de l'image de couverture de votre playlist" />,
                            );
                            onClose();
                            return;
                        }
                        toast.alert();
                    },
                },
            );
        };

        return (
            <PlaylistForm
                isLoading={saveNewPlaylist.isLoading}
                hasAccessToPlaylistConfidentiality={hasAccessToPlaylistConfidentiality}
                onClose={onClose}
                onSubmit={onSubmit}
            />
        );
    }

    return <div>Loading...</div>; // TODO: Add loading state
};

export default AddPlaylist;
