import { FormattedMessage } from 'react-intl';
// @ts-ignore
import emojiGrimacingIllu from '@public/images/illu_emoji_grimacing.svg?url';
import Text from '@ui/atoms/Text';
import Stack from '@ui/layout/Stack';
import Check from '@ui/organisms/Checklist/Check';
import CheckLabel from '@ui/organisms/Checklist/CheckLabel';
import Checklist from '@ui/organisms/Checklist/Checklist';
import styled from 'styled-components';
import YoutubeBroadcastButton from './YoutubeBroadcastButton';
import SoundcloudBroadcastButton from './SoundcloudBroadcastButton';
import IconInfo from '@ui/icons/IconInfo';
import Img from '@ui/atoms/Img';
import { useParams, Redirect } from 'react-router-dom';
import Alert from '@ui/atoms/Alert';
import RouterLink from '@/components/Link/RouterLink';
import useShowQuery from '@queries/show/useShowQuery.hook';
import useUserQuery from '@queries/user/useUserQuery.hook';

const BroadcastSetup = () => {
    const { showId } = useParams<{ showId: string }>();
    const { data: show, isLoading: showIsLoading } = useShowQuery(showId);
    const { data: user } = useUserQuery();

    const isShowPmp = show?.hostedOnAusha === false;

    const hasDescription = !!show?.description && show.description !== '\n\n';
    const hasCover = !!show?.imageUrl;
    const hasPublishedEpisode = show?.hasPublicActivePodcasts;
    const canBroadcast = show && hasDescription && hasCover && hasPublishedEpisode;

    if (showIsLoading) {
        return null;
    }

    if (!user?.emailVerifiedAt) {
        return <Redirect to={`/app/show/${showId}/settings/broadcast/email-verification`} />;
    }

    if (canBroadcast && isShowPmp) {
        return <Redirect to={`/app/show/${showId}/settings/broadcast/non-hosting`} />;
    }

    if (canBroadcast && !isShowPmp) {
        return <Redirect to={`/app/show/${showId}/settings/broadcast`} />;
    }

    return (
        <Wrapper>
            <BroadcastRequiredStepsWrapper>
                <Img src={emojiGrimacingIllu} size="4rem" />
                <Stack $gap="1.5rem">
                    <Stack $gap="0.5rem">
                        <Text variant="subheading" fontWeight="--fw-semibold">
                            <FormattedMessage defaultMessage="Vous y êtes presque !" />
                        </Text>
                        <Text variant="body" color="--neutral500">
                            <FormattedMessage defaultMessage="Complétez les 3 tâches ci-dessous pour diffuser votre émission sur les plateformes d’écoute." />
                        </Text>
                    </Stack>
                    <Checklist $gap="1.25rem">
                        <Check isCompleted={hasCover}>
                            <CheckLabel>
                                <FormattedMessage
                                    defaultMessage="Ajouter une image de couverture pour votre émission {link}"
                                    values={{
                                        link: (
                                            <RouterLink to={`/app/show/${showId}/settings/info`}>
                                                <FormattedMessage defaultMessage="C'est parti !" />
                                            </RouterLink>
                                        ),
                                    }}
                                />
                            </CheckLabel>
                        </Check>
                        <Check isCompleted={hasDescription}>
                            <CheckLabel>
                                <FormattedMessage
                                    defaultMessage="Ajouter une description pour votre émission {link}"
                                    values={{
                                        link: (
                                            <RouterLink to={`/app/show/${showId}/settings/info`}>
                                                <FormattedMessage defaultMessage="C'est parti !" />
                                            </RouterLink>
                                        ),
                                    }}
                                />
                            </CheckLabel>
                        </Check>
                        <Check isCompleted={hasPublishedEpisode}>
                            <CheckLabel>
                                <FormattedMessage
                                    defaultMessage="Avoir un épisode public en ligne {link}"
                                    values={{
                                        link: (
                                            <RouterLink to={`/app/show/${showId}/episodes`}>
                                                <FormattedMessage defaultMessage="C'est parti !" />
                                            </RouterLink>
                                        ),
                                    }}
                                />
                            </CheckLabel>
                        </Check>
                    </Checklist>
                    <Alert
                        variant="info"
                        icon={<IconInfo />}
                        description={
                            <Text color="--neutral500">
                                <FormattedMessage
                                    defaultMessage="Contrairement aux autres plateformes d’écoute, YouTube et SoundCloud n’utilisent
                                pas votre flux RSS pour diffuser vos épisodes. Vous pouvez donc vous connecter à
                                ces deux plateformes ci-dessous dès maintenant."
                                />
                            </Text>
                        }
                    >
                        <Text fontWeight="--fw-semibold">
                            <FormattedMessage defaultMessage="Vous souhaitez diffuser dès maintenant sur YouTube et Soundcloud ?" />
                        </Text>
                    </Alert>
                </Stack>
            </BroadcastRequiredStepsWrapper>
            <LoginToYoutubeSoundcloudWrapper>
                <Stack $gap="0.5rem">
                    <Text variant="subheading" fontWeight="--fw-semibold">
                        <FormattedMessage defaultMessage="Connectez vos comptes YouTube et SoundCloud" />
                    </Text>
                    <Text variant="body" color="--neutral500">
                        <FormattedMessage defaultMessage="En connectant vos comptes YouTube et SoundCloud, vos épisodes pourront être automatiquement publiés sur ces plateformes." />
                    </Text>
                </Stack>
                <LoginButtonsWrapper>
                    <YoutubeBroadcastButton />
                    <SoundcloudBroadcastButton />
                </LoginButtonsWrapper>
            </LoginToYoutubeSoundcloudWrapper>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
`;
const BroadcastRequiredStepsWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: 1.5rem 1rem;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;

    ${(p) => p.theme.mediaQueries.tabletAndUp} {
        padding: 2rem 2.5rem;
    }
`;
const LoginToYoutubeSoundcloudWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 1.5rem 1rem;

    ${(p) => p.theme.mediaQueries.tabletAndUp} {
        padding: 2rem 2.5rem;
    }
`;
const LoginButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    ${(p) => p.theme.mediaQueries.mobile} {
        & > button {
            width: 100%;
        }
    }
    ${(p) => p.theme.mediaQueries.tabletAndUp} {
        flex-direction: row;
    }
`;

export default BroadcastSetup;
