import SimpleGrid from '@ui/layout/SimpleGrid';
import type { ClipFormat as IClipFormat } from '@/queries/clipFormat/types';
import ClipFormat from './ClipFormat';

interface GridProps {
    clipFormats: IClipFormat[];
}

const Grid = ({ clipFormats }: GridProps) => {
    return (
        <SimpleGrid $idealChildWidth={250} $rowGap={32} $columnGap={36}>
            {clipFormats.map((clipFormat) => (
                <ClipFormat key={clipFormat.id} clipFormat={clipFormat} />
            ))}
        </SimpleGrid>
    );
};

export default Grid;
