import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Stack from '@ui/layout/Stack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Modal from '@/components/ui/molecules/Modal';
import Button from '@/components/ui/atoms/Button';
import Text from '@/components/ui/atoms/Text';
import styled from 'styled-components';
import Astronauts from '@public/images/astronauts.webp';
import useInternalRouteMapping from '@/utils/hooks/useInternalRouteMapping.hook';
import { useParams } from 'react-router-dom';
import { useResponsive } from '@/utils/hooks/useResponsive';

const AushaClubModal = ({ isOpen, onClose }) => {
    const { showId } = useParams();
    const { push } = useInternalRouteMapping();
    const { isMobile } = useResponsive();

    const handleRedirectToOffersPage = () => {
        push(showId ? 'user.edit.offers' : 'user.offers');
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={isMobile ? '' : 'small'}>
            <ModalInnerWrapper>
                <IllustrationBlock imagePath={Astronauts} />
                <Stack $gap="0.25rem">
                    <Text variant="headingM" fontWeight="--fw-bold">
                        <FormattedMessage defaultMessage="Le Club est réservé aux abonnés d'Ausha" />
                    </Text>
                    <Text color="--neutral500">
                        <FormattedMessage
                            defaultMessage="Le Club Ausha est un espace privé où les podcasteurs peuvent s’entraider, créer des partenariats et se rencontrer. On organise même des apéros dans toutes les villes de France ! Il donne aussi accès à des outils, des ressources et des événements exclusifs. Pour profiter de toute cette richesse et faire des connexions, <semibold>abonnez-vous !</semibold> 🚀"
                            values={{
                                semibold: (chunks) => (
                                    <Text as="span" color="--neutral500" fontWeight="--fw-semibold">
                                        {chunks}
                                    </Text>
                                ),
                            }}
                        />
                    </Text>
                </Stack>
                <SubscribeButton
                    onPress={handleRedirectToOffersPage}
                    startIcon={<FontAwesomeIcon icon={icon({ name: 'rocket', style: 'solid' })} />}
                >
                    <FormattedMessage defaultMessage="S'abonner" />
                </SubscribeButton>
            </ModalInnerWrapper>
        </Modal>
    );
};

const ModalInnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1.5rem;

    & > * {
        text-align: center;
    }
`;
const IllustrationBlock = styled.div`
    width: 100%;
    height: 12.5rem;
    background-image: url(${({ imagePath }) => imagePath});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    border-radius: var(--r-l);
`;
const SubscribeButton = styled(Button)`
    box-shadow: var(--s-primary);
`;

AushaClubModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default AushaClubModal;
