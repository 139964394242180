import { useEffect } from 'react';
import DeprecatedTextInput from '@ui/molecules/DeprecatedTextInput';
import { SketchPicker } from 'react-color';
import { observer } from 'mobx-react';
import posed, { PoseGroup } from 'react-pose';
import styled from 'styled-components';
import DeprecatedPaper from '../../atoms/DeprecatedPaper';
import { useSwitch } from '@hooks/useSwitch';

const transition = { duration: 250 };

const Picker = styled(SketchPicker)`
    .flexbox-fix {
        input {
            font-family: inherit;
        }
        font-family: inherit;
    }
`;

const PickerWrapper = posed(DeprecatedPaper)({
    enter: { opacity: 1, y: 0, transition },
    exit: { opacity: 0, y: -30, transition },
});

function ColorPicker({
    palette,
    ml,
    mt,
    mb,
    mr,
    mx,
    my,
    m,
    maxW,
    onChange,
    value,
    field,
    ...props
}) {
    const [opened, onOpen, onClose] = useSwitch();
    const onChangeColor = (value) => {
        field && field.setValue(value);
        onChange(value);
    };
    const color = field ? field.value : value;

    useEffect(() => {
        if (field && value) {
            field.setValue(value);
        }
    }, [value]);

    return (
        <DeprecatedPaper
            pos="relative"
            maxW={maxW}
            mx={mx}
            my={my}
            ml={ml}
            mt={mt}
            mb={mb}
            mr={mr}
            m={m}
        >
            <DeprecatedTextInput
                smallInput
                onClick={onOpen}
                rightIcon={
                    <DeprecatedPaper
                        height={20}
                        w={20}
                        x={null}
                        y={null}
                        rounded
                        background={color}
                        style={{ pointerEvents: 'none' }}
                    />
                }
                value={color}
                onChange={onChangeColor}
                {...props}
            />
            <PoseGroup>
                {opened && (
                    <PickerWrapper key={0} z={3} y={65} pos="absolute" w="auto">
                        <Picker
                            onChangeComplete={({ hex }) => onChangeColor(hex)}
                            color={color}
                            disableAlpha
                            presetColors={palette}
                        />
                    </PickerWrapper>
                )}
            </PoseGroup>
            {opened && (
                <DeprecatedPaper
                    pos="fixed"
                    x={0}
                    y={0}
                    xR={0}
                    yR={0}
                    w="100vw"
                    height="100vh"
                    onClick={onClose}
                />
            )}
        </DeprecatedPaper>
    );
}

ColorPicker.defaultProps = {
    palette: [
        '#D0021B',
        '#F5A623',
        '#F8E71C',
        '#8B572A',
        '#7ED321',
        '#417505',
        '#BD10E0',
        '#9013FE',
        '#4A90E2',
        '#000000',
        '#4A4A4A',
        '#9B9B9B',
        '#50E3C2',
        '#B8E986',
    ],
};

export default observer(ColorPicker);
