import { useState } from 'react';
import trash from '@public/icons/trash.svg';
import { defineMessages, useIntl } from 'react-intl';
import DeprecatedPaper from '@/components/ui/atoms/DeprecatedPaper';
import Img from '@/components/ui/atoms/Img';
import DeprecatedText from '@/components/ui/atoms/DeprecatedText';
import DeprecatedButton from '@/components/ui/atoms/DeprecatedButton';
import DeprecatedIcon from '@/components/ui/atoms/DeprecatedIcon';
import { ShowGuestPermission } from '@/queries/guest/useShowGuestPermissionsQuery.hook';
import { GUEST_USER_ROLES, GuestUserRole } from '@/utils/constants';
import styled from 'styled-components';
import ShowGuestsPermissionDeleteModal from './ShowGuestsPermissionDeleteModal';
import Select from '@/components/ui/atoms/Select';
import ListBoxItem from '@/components/ui/atoms/ListBoxItem';
import updateShowGuestPermissionMutation from '@/queries/guest/updateShowGuestPermissionMutation';
import { Key } from 'react-aria';

const messages = defineMessages({
    viewer: { defaultMessage: 'Lecteur' },
    editor: { defaultMessage: 'Éditeur' },
    admin: { defaultMessage: 'Admin' },
});

interface ShowGuestsPermissionProps {
    permission: ShowGuestPermission;
}

const ShowGuestsPermission = ({ permission }: ShowGuestsPermissionProps) => {
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const intl = useIntl();
    const roleItems = Object.values(GUEST_USER_ROLES).map((role) => ({
        id: role,
        name: intl.formatMessage(messages[role]),
    }));
    const updatePermission = updateShowGuestPermissionMutation();

    const handleUpdate = (key: Key) => {
        const role = key as GuestUserRole;
        updatePermission.mutate({ permissionId: permission.id, role });
    };

    return (
        <>
            <Wrapper>
                <Img circle size={40} src={permission.userAvatar} />
                <DeprecatedPaper ellipsis flex column auto>
                    <DeprecatedText weight="semibold" size="big" ellipsis>
                        {permission.userName}
                    </DeprecatedText>
                    <DeprecatedText color="--neutral500" ellipsis pr={20}>
                        {permission.userEmail}
                    </DeprecatedText>
                </DeprecatedPaper>
                <RoleSelect
                    defaultSelectedKey={permission.role}
                    onSelectionChange={handleUpdate}
                    items={roleItems}
                >
                    {(item) => <ListBoxItem {...item} />}
                </RoleSelect>
                <DeprecatedButton accent="rounded" onClick={() => setIsDeleteModalOpen(true)} icon>
                    <DeprecatedIcon as={trash} />
                </DeprecatedButton>
            </Wrapper>
            <ShowGuestsPermissionDeleteModal
                isOpen={isDeleteModalOpen}
                onOpenChange={setIsDeleteModalOpen}
                permission={permission}
            />
        </>
    );
};

const Wrapper = styled.div`
    height: 4.75rem;
    border-bottom: 1px solid var(--neutral100);
    display: flex;
    align-items: center;
    column-gap: 1rem;
`;
const RoleSelect = styled(Select)`
    max-width: 105px;
`;

export default ShowGuestsPermission;
