import styled from 'styled-components';

const SectionTitle = styled.h3`
    display: none;

    ${({ theme }) => theme.mediaQueries.desktopXLAndUp} {
        display: block;
        font-size: var(--fs-body-m);
        font-weight: var(--fw-normal);
        color: var(--neutral500);
    }
`;

export default SectionTitle;
