import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchApplePodcastsAvailableCategories } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import statsKeys from './statsKeys';
import { z } from 'zod';

export const AppleCategorySchema = z.object({
    id: z.number(),
    parentId: z.number().nullable(),
    slug: z.string(),
    name: z.string(),
    appleId: z.number(),
});
const AppleCategoriesSchema = z.array(AppleCategorySchema);
export type AppleCategory = z.infer<typeof AppleCategorySchema>;
type Key = QueryFunctionContext<ReturnType<typeof statsKeys.appleCategories>>;
type InputProps = {
    showId: string;
    options?: {
        enabled?: boolean;
    };
};

const queryFn = async ({ queryKey: [{ showId }] }: Key) => {
    const { data } = await fetchApplePodcastsAvailableCategories(showId);
    return AppleCategoriesSchema.parse(camelcaseKeys(data, { deep: true }));
};

const useAppleCategoriesQuery = ({ showId, options }: InputProps) => {
    return useQuery({
        queryKey: statsKeys.appleCategories(showId),
        queryFn,
        enabled: options?.enabled ?? true,
    });
};

export default useAppleCategoriesQuery;
