import { useParams } from 'react-router-dom';
import Stack from '@ui/layout/Stack';
import Text from '@ui/atoms/Text';
import { FormattedMessage } from 'react-intl';
import { ToggleController } from '@ui/atoms/Toggle';
import useYoutubeProviderQuery from '@queries/provider/useYoutubeProviderQuery.hook';
import useYoutubeQuotaQuery from '@queries/quota/useYoutubeQuotaQuery.hook';
import Link from '@ui/atoms/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Cluster from '@ui/layout/Cluster';
import EpisodeEditTooltip from '@/components/unorganized/EpisodeEditTooltip';
import { YOUTUBE_VIDEO_STATUS } from '@/shared/config/constants';
import { useEpisodeBeingEdited } from '@/context/EpisodeFormContext';

interface ToggleContentProps {
    isYoutubeAccountConnected: boolean;
    isVideoProcessing: boolean;
    isVideoDoneOrError: boolean;
    youtubeQuota?: {
        total: number;
        maximum: number;
        minimum: number;
        step: number;
    };
    broadcastLink: string;
}

const ToggleContent = ({
    isYoutubeAccountConnected,
    isVideoProcessing,
    isVideoDoneOrError,
    youtubeQuota,
    broadcastLink,
}: ToggleContentProps) => {
    if (!isYoutubeAccountConnected) {
        return (
            <FormattedMessage
                defaultMessage="Pour publier votre épisode sur YouTube, connectez votre compte dans l’onglet {broadcast} de votre émission."
                values={{
                    broadcast: (
                        <BroadcastLink to={broadcastLink} target="_blank">
                            <FormattedMessage defaultMessage="Diffusion" />
                            <LinkIcon icon={icon({ name: 'arrow-up-right', style: 'solid' })} />
                        </BroadcastLink>
                    ),
                }}
            />
        );
    }
    if (isVideoProcessing) {
        return (
            <Text as="span" color="--neutral500">
                <FormattedMessage defaultMessage="Vidéo en cours de génération…" />
            </Text>
        );
    }
    if (isVideoDoneOrError) {
        return <FormattedMessage defaultMessage="Republier l'épisode sur YouTube." />;
    }
    return (
        <FormattedMessage
            defaultMessage="Votre épisode sera également publié sur YouTube, dans la limite de {maximum} par jour. {quota}"
            values={{
                maximum: youtubeQuota?.maximum,
                quota: (
                    <Text as="span" color="--neutral500">
                        <FormattedMessage
                            defaultMessage="(quota actuel : {total}/{maximum})"
                            values={{
                                total: youtubeQuota?.total,
                                maximum: youtubeQuota?.maximum,
                            }}
                        />
                    </Text>
                ),
            }}
        />
    );
};

interface PublishOnYoutubeToggleProps {
    checked: boolean;
    onChange: (checked: boolean) => void;
}

const PublishOnYoutubeToggle = (props: PublishOnYoutubeToggleProps) => {
    const { showId }: { showId: string } = useParams();
    const episode = useEpisodeBeingEdited();
    const { data: youtubeAccount } = useYoutubeProviderQuery({ showId });
    const { data: youtubeQuota } = useYoutubeQuotaQuery();

    const broadcastLink = `/app/show/${showId}/settings/broadcast`;
    const isVideoDone = episode?.youtubeVideo?.data.state === YOUTUBE_VIDEO_STATUS.DONE;
    const isVideoError = episode?.youtubeVideo?.data.state === YOUTUBE_VIDEO_STATUS.ERROR;
    const isVideoDoneOrError = isVideoDone || isVideoError;
    const isVideoProcessing = episode?.youtubeVideo?.data.state === YOUTUBE_VIDEO_STATUS.PROCESSING;
    const isYoutubeAccountConnected = !!youtubeAccount;
    const isQuotaReached = youtubeQuota && youtubeQuota.total >= youtubeQuota.maximum;
    const isDisabled = !isYoutubeAccountConnected || isVideoProcessing || isQuotaReached;

    return (
        <Stack $gap="1rem">
            <Stack>
                <Cluster $gap="0.25rem" $align="center">
                    <Text variant="bodyM" fontWeight="--fw-semibold">
                        {episode?.youtubeVideo && isVideoDoneOrError ? (
                            <FormattedMessage defaultMessage="Publier à nouveau sur YouTube" />
                        ) : (
                            <FormattedMessage defaultMessage="Publier sur YouTube" />
                        )}
                    </Text>
                    <EpisodeEditTooltip>
                        <FormattedMessage defaultMessage="Une vidéo de votre épisode sera générée et publiée sur YouTube. Vous pouvez la personnaliser et la paramétrer dans l’onglet « Diffusion » de votre émission." />
                    </EpisodeEditTooltip>
                </Cluster>
                <Text variant="footnote" color="--neutral400">
                    <FormattedMessage defaultMessage="Une vidéo de votre épisode sera générée et publiée sur YouTube. Vous pouvez la personnaliser et la paramétrer dans l’onglet « Diffusion » de votre émission." />
                </Text>
            </Stack>
            {isVideoError && (
                <Text color="--alert500">
                    <FormattedMessage defaultMessage="Oups, il semblerait que nous avons rencontré un souci avec votre vidéo YouTube. Merci de réessayer 🙏 " />
                </Text>
            )}
            <ToggleController isDisabled={isDisabled} {...props}>
                <Text as="span">
                    <ToggleContent
                        isYoutubeAccountConnected={isYoutubeAccountConnected}
                        isVideoProcessing={isVideoProcessing}
                        isVideoDoneOrError={isVideoDoneOrError}
                        youtubeQuota={youtubeQuota}
                        broadcastLink={broadcastLink}
                    />
                </Text>
            </ToggleController>
        </Stack>
    );
};

const LinkIcon = styled(FontAwesomeIcon)`
    color: inherit;
    width: 0.75rem;
    height: 0.75rem;
`;
const BroadcastLink = styled(Link)`
    font-weight: var(--fw-semibold);
    color: var(--primary);
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;

export default PublishOnYoutubeToggle;
