import RouterLink from '@/components/Link/RouterLink';
import Text from '@/components/ui/atoms/Text';
import Stack from '@/components/ui/layout/Stack';
import { ImportedEpisode } from '@/queries/rss/useImportedEpisodesQuery.hook';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import useSubscriptionQuery from '@/queries/subscription/useSubscriptionQuery.hook';
import { PRICING } from '@/shared/config/pricing';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import styled from 'styled-components';

interface YoutubeProps {
    importedEpisodes: ImportedEpisode[];
    counters: {
        pending: number;
        processing: number;
        done: number;
        error: number;
        total: number;
    };
}

const Youtube = ({ importedEpisodes, counters }: YoutubeProps) => {
    const { showId } = useParams<{ showId: string }>();
    const { data: subscription } = useSubscriptionQuery();
    const { data: show } = useShowQuery(showId);
    const importedEpisodesCount = importedEpisodes?.length || 0;
    const importedEpisodesWithErrorCount = counters.error;
    const hasErrors = importedEpisodesWithErrorCount > 0;
    const isFreemium = show?.ownerPricing === PRICING.FREEMIUM || subscription?.isFreemium;
    const isProcessing = counters.processing > 0 || counters.pending > 0;

    if (isProcessing) {
        return (
            <StepWrapper>
                <SpinnerIcon icon={icon({ name: 'spinner-third', style: 'solid' })} spin />
                <Stack>
                    <Text fontWeight="--fw-semibold">
                        {isFreemium ? (
                            <FormattedMessage defaultMessage="Nous importons vos 5 dernières vidéos" />
                        ) : (
                            <FormattedMessage
                                defaultMessage="Nous importons vos vidéos ({done}/{total})"
                                values={{
                                    done: counters.done,
                                    total: counters.total,
                                }}
                            />
                        )}
                    </Text>
                    <Text color="--neutral500">
                        <FormattedMessage defaultMessage="Cela peut prendre quelques minutes." />
                    </Text>
                </Stack>
            </StepWrapper>
        );
    }

    if (hasErrors) {
        return (
            <StepWrapper>
                <ErrorIcon icon={icon({ name: 'circle-exclamation', style: 'solid' })} />
                <Stack>
                    <Text fontWeight="--fw-semibold">
                        <FormattedMessage
                            defaultMessage={`Importation terminée avec {importedEpisodesWithErrorCount, plural,
                        one {# erreur}
                        other {# erreurs}
                    }.`}
                            values={{
                                importedEpisodesWithErrorCount,
                            }}
                        />
                    </Text>
                    <Text color="--neutral500">
                        <FormattedMessage
                            defaultMessage={`{importedEpisodesCount, plural,
                                one {Votre vidéo a été importée, mais un problème a été détecté.}
                                other {Vos # vidéos ont été importées, mais des problèmes ont été détectés.}
                            }`}
                            values={{
                                importedEpisodesCount,
                            }}
                        />
                    </Text>
                </Stack>
                <StepAction>
                    <RouterLink
                        to={`/app/show/${showId}/settings/rss-import`}
                        size="small"
                        variant="button-tertiary"
                    >
                        <FormattedMessage defaultMessage="Vérifiez-les dès maintenant" />
                    </RouterLink>
                </StepAction>
            </StepWrapper>
        );
    }

    return (
        <StepWrapper>
            <CheckIcon icon={icon({ name: 'check-circle', style: 'solid' })} />
            <Stack>
                <Text fontWeight="--fw-semibold">
                    {isFreemium ? (
                        <FormattedMessage defaultMessage="Nous avons importé vos 5 dernières vidéos" />
                    ) : (
                        <FormattedMessage
                            defaultMessage={`{importedEpisodesCount, plural,
                                one {Nous avons importé votre vidéo}
                                other {Nous avons importé vos # vidéos}
                            }`}
                            values={{
                                importedEpisodesCount: counters.total,
                            }}
                        />
                    )}
                </Text>
                <Text color="--neutral500">
                    {isFreemium ? (
                        <FormattedMessage defaultMessage="Pour accéder à toutes vos anciennes vidéos et aux prochaines, abonnez-vous dès maintenant !" />
                    ) : (
                        <FormattedMessage defaultMessage="Toutes vos vidéos ont été importées avec succès et converties en épisodes audio." />
                    )}
                </Text>
            </Stack>
            {isFreemium && (
                <StepAction>
                    <RouterLink
                        startIcon={
                            <RocketIcon icon={icon({ name: 'rocket-launch', style: 'solid' })} />
                        }
                        size="small"
                        variant="button-primary"
                        to={`/app/user/subscription/offers`}
                    >
                        <FormattedMessage defaultMessage="S'abonner" />
                    </RouterLink>
                </StepAction>
            )}
        </StepWrapper>
    );
};

const StepWrapper = styled.div`
    display: flex;
    column-gap: 0.75rem;
`;
const SpinnerIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--primary500);
    margin-top: 0.25rem;
`;
const ErrorIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--alert500);
    margin-top: 0.125rem;
`;
const CheckIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--primary500);
    margin-top: 0.25rem;
`;
const StepAction = styled.div`
    margin-left: auto;
    padding-right: 1.75rem;
    align-self: center;
`;
const RocketIcon = styled(FontAwesomeIcon)`
    width: 0.5rem;
    height: 0.5rem;
`;

export default Youtube;
