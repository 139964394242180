import { Tooltip as AriaTooltip } from 'react-aria-components';
import styled from 'styled-components';
import Text from '@/components/ui/atoms/Text';
import { FormattedMessage } from 'react-intl';
import Stack from '@/components/ui/layout/Stack';
import Cluster from '@/components/ui/layout/Cluster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

interface PsoMetadataTooltipProps {
    occurrences: {
        podcastsDescriptions: number;
        podcastsTitles: number;
        showDescription: number;
        showTitle: number;
    };
    total: number;
    keyword: string;
}

const PsoMetadataTooltip = ({ occurrences, total, keyword }: PsoMetadataTooltipProps) => {
    return (
        <Tooltip placement="bottom end">
            <Stack $gap="0.25rem">
                <Text fontWeight="--fw-bold">
                    <FormattedMessage defaultMessage="Détails des métadonnées" />
                </Text>
                <Text variant="bodyS" color="--neutral500">
                    <FormattedMessage defaultMessage="Visualisez le nombre de fois où le mot-clé est utilisé dans l'ensemble de vos métadonnées." />
                </Text>
            </Stack>
            <KeywordBadge>#{keyword}</KeywordBadge>
            <Stack $gap="1rem">
                <Cluster $gap="1rem" $align="center">
                    <MetadataTitle>
                        <FormattedMessage defaultMessage="Titre de l’émission" />
                    </MetadataTitle>
                    <Metadata>{occurrences.showTitle}</Metadata>
                </Cluster>
                <Cluster $gap="1rem" $align="center">
                    <MetadataTitle>
                        <FormattedMessage defaultMessage="Description de l’émission" />
                    </MetadataTitle>
                    <Metadata>{occurrences.showDescription}</Metadata>
                </Cluster>
                <Cluster $gap="1rem" $align="center">
                    <MetadataTitle>
                        <FormattedMessage defaultMessage="Titres des épisodes" />
                    </MetadataTitle>
                    <Metadata>{occurrences.podcastsTitles}</Metadata>
                </Cluster>
                <Cluster $gap="1rem" $align="center">
                    <MetadataTitle>
                        <FormattedMessage defaultMessage="Descriptions des épisodes" />
                    </MetadataTitle>
                    <Metadata>{occurrences.podcastsDescriptions}</Metadata>
                </Cluster>
            </Stack>
            {total < 1 && (
                <WarningAlert>
                    <WarningIcon icon={icon({ name: 'triangle-exclamation', style: 'solid' })} />
                    <Text variant="bodyS" fontWeight="--fw-semibold">
                        <FormattedMessage defaultMessage="Ce mot-clé n'est jamais mentionné" />
                    </Text>
                </WarningAlert>
            )}
        </Tooltip>
    );
};

const Tooltip = styled(AriaTooltip)`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: 1.5rem;
    box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    max-width: 265px;
`;
const Metadata = styled(Text)`
    min-width: 1.5rem;
    font-size: var(--fs-body-s);
    line-height: var(--lh-body-s);
    color: var(--neutral500);
`;
const MetadataTitle = styled(Text)`
    font-size: var(--fs-body-s);
    font-weight: var(--fw-semibold);
    line-height: var(--lh-body-s);
    flex-grow: 1;
`;
const WarningAlert = styled.div`
    background-color: var(--warning50);
    color: var(--warning500);
    padding: 0.5rem;
    border-radius: var(--r-s);
    display: flex;
    align-items: center;
    column-gap: 0.25rem;
`;
const WarningIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: inherit;
`;
const KeywordBadge = styled(Text)`
    background-color: var(--primary50);
    color: var(--primary500);
    padding: 0.5rem;
    border-radius: var(--r-s);
    font-size: var(--fs-body-s);
    line-height: var(--lh-body-s);
    font-weight: var(--fw-semibold);
    width: fit-content;
`;

export default PsoMetadataTooltip;
