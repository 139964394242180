import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Text from '@ui/atoms/Text';
import { getStripeCustomerPortalSession } from '@/api';
import Button from '@/components/Button';
import { useModalToastQueue } from '@/shared/hooks/useModalToastQueue.hook';

const TopbarWarningBand = () => {
    const toast = useModalToastQueue();
    const createStripePortalSession = async () => {
        try {
            const stripeCustomerPortalSession = await getStripeCustomerPortalSession();
            const { url } = stripeCustomerPortalSession;
            window.location.href = url;
        } catch (error) {
            toast.alert(
                <FormattedMessage defaultMessage="Une erreur inattendue est survenue. Merci de réessayer plus tard." />,
            );
        }
    };

    return (
        <TopbarWarningBandWrapper>
            <Text color="white">
                <FormattedMessage defaultMessage="Votre abonnement est résilié. Pour profiter à nouveau des services d’Ausha, veuillez le réactiver." />
            </Text>
            <ReactivateLink variant="link-secondary" onPress={createStripePortalSession}>
                <FormattedMessage defaultMessage="Réactiver mon abonnement" />
            </ReactivateLink>
        </TopbarWarningBandWrapper>
    );
};

const TopbarWarningBandWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    height: 2rem;
    background: var(--info);
`;
const ReactivateLink = styled(Button)`
    color: var(--white);

    &:hover {
        color: var(--white);
    }
`;

export default TopbarWarningBand;
