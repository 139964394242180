import { FormattedMessage } from 'react-intl';
import DeprecatedModal from '@ui/molecules/DeprecatedModal';
import DeprecatedButton from '@ui/atoms/DeprecatedButton';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import DeprecatedText from '@ui/atoms/DeprecatedText';

const SubscriberNewsletterDeleteConfirmModal = ({ isOpened, onSubmit, onClose }) => (
    <DeprecatedModal
        maxW={400}
        w={400}
        isOpened={isOpened}
        title={<FormattedMessage defaultMessage="Supprimer un abonné" />}
    >
        <DeprecatedText>
            <FormattedMessage
                defaultMessage="Souhaitez-vous supprimer cet abonné ? {lineBreak}Votre newsletter ne lui sera plus envoyée."
                values={{ lineBreak: <br /> }}
            />
        </DeprecatedText>
        <DeprecatedPaper flex justify="flex-end" mt={30}>
            <DeprecatedButton type="button" mr={10} accent="secondary" onClick={onClose}>
                <FormattedMessage defaultMessage="Annuler" />
            </DeprecatedButton>
            <DeprecatedButton type="submit" onClick={onSubmit}>
                <FormattedMessage defaultMessage="Supprimer" />
            </DeprecatedButton>
        </DeprecatedPaper>
    </DeprecatedModal>
);

export default SubscriberNewsletterDeleteConfirmModal;
