export const computeCssValue = (value) => {
    if (typeof value === 'number') {
        // 0 is valid and is use as is
        if (value === 0) {
            return value;
        }

        // A number value is considered as pixel
        return `${value}px`;
    }

    return value;
};
