import { useMutation } from '@tanstack/react-query';
import { addShow } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import showKeys from './showKeys';
import { useQueryClient } from '@tanstack/react-query';
import { decamelizeKeys } from 'humps';

// NewShowManualFormSchema -> Type for show

interface MutationFnProps {
    channelId: number;
    show: {
        name: string;
        htmlDescription?: string | null;
        author: string;
        languageId: number;
        categoryId: number;
    };
}

const transform = (data: unknown) => {
    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data as any, { deep: true });
};

const mutationFn = async ({ channelId, show }: MutationFnProps) => {
    const { data } = await addShow(channelId, decamelizeKeys(show));
    return transform(data);
};

const useCreateShowMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: showKeys.allLists(),
            });
        },
    });
};

export default useCreateShowMutation;
