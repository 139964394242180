import styled from 'styled-components';
import DeprecatedPaper from '../DeprecatedPaper';
import { resolveColor } from '@/shared/utils/cssVariable';

/**
 * DEPRECATED
 *
 * Please DO NOT use this NOR `ui/atoms/Container` and ESPECIALLY NOT `ui/layout/Box` components.
 */
const DeprecatedWhitePaper = styled(DeprecatedPaper)`
    border-color: ${(p) => (p.borderColor ? p.borderColor : resolveColor(p.border))};
    border-width: ${(p) =>
        p.b || p.bt || p.br || p.bb || p.bl
            ? `${p.bt || p.b}px ${p.br || p.b}px ${p.bb || p.b}px ${p.bl || p.b}px`
            : '0px'};
    border-style: solid;
    border-radius: ${(p) =>
        p.rounded
            ? '5px'
            : `${p.radTL || p.radTop || p.radL || p.radius}px ${
                  p.radTR || p.radTop || p.radR || p.radius
              }px
        ${p.radBR || p.radBottom || p.radR || p.radius}px ${
                  p.radBL || p.radBottom || p.radL || p.radius
              }px`};
    &:hover {
        background: ${(p) => resolveColor(p.hover)};
    }
`;

DeprecatedWhitePaper.defaultProps = {
    w: '100%',
    background: 'white',
    border: '--neutral100',
    rounded: true,
    overflowHide: true,
    z: 0,
    radius: 0,
    radTL: 0,
    radTR: 0,
    radBR: 0,
    radBL: 0,
    radL: 0,
    radR: 0,
    radTop: 0,
    radBottom: 0,
    bt: 0,
    br: 0,
    bb: 0,
    bl: 0,
    b: 0,
    borderColor: undefined,
};

export default DeprecatedWhitePaper;
