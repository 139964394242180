import { useQuery } from '@tanstack/react-query';
import useCampaignStore from '@/shared/hooks/useCampaignStore';

export const useCampaign = (rawCampaignId, select = null) => {
    const { fetchCampaign } = useCampaignStore();

    return useQuery({
        queryKey: [{ entity: 'campaigns' }, Number(rawCampaignId)],
        queryFn: async () => {
            const campaign = await fetchCampaign(rawCampaignId);

            return campaign;
        },
        select,
    });
};
