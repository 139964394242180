import { useIntl } from 'react-intl';
import { MAX_AUDIO_FILE_SIZE, MP3_AUDIO_TYPES } from '@/shared/config/constants';
import { z } from 'zod';
import { User } from '@/queries/user/useUserQuery.hook';
import { checkAudioFileSize } from '@/shared/utils/file';

const useCampaignAudioFileFormSchema = (locale: User['language'] = 'en') => {
    const intl = useIntl();

    return z.object({
        audioFile: z
            .array(z.instanceof(File))
            .refine(
                (file) => {
                    if (Array.isArray(file)) {
                        return MP3_AUDIO_TYPES.includes(file?.[0]?.type);
                    }
                    return MP3_AUDIO_TYPES.includes(file.type);
                },
                {
                    message: intl.formatMessage({
                        defaultMessage: 'Votre fichier doit être au format .MP3',
                    }) as string,
                },
            )
            .refine(
                (file) => {
                    if (Array.isArray(file)) {
                        return checkAudioFileSize(file?.[0], locale);
                    }
                    return checkAudioFileSize(file, locale);
                },
                {
                    message: intl.formatMessage(
                        { defaultMessage: 'Votre fichier doit peser au maximum {max}Mo' },
                        { max: MAX_AUDIO_FILE_SIZE / 1000000 },
                    ) as string,
                },
            )
            .nullable(),
    });
};

export type CampaignAudioFileFormSchema = z.infer<
    ReturnType<typeof useCampaignAudioFileFormSchema>
>;

export type CampaignAudioFileForm = CampaignAudioFileFormSchema['audioFile'];

export default useCampaignAudioFileFormSchema;
