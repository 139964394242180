import { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
    FACEBOOK,
    TWITTER,
    INSTAGRAM,
    LINKEDIN,
    SOCIAL_NETWORK_POST_TYPE,
} from '@/utils/constants';
import Text from '@ui/atoms/Text';
import useDeleteSocialNetworkPostMutation from '@/queries/socialNetworkPost/useDeleteSocialNetworkPostMutation.hook';
import { useEditedPostContext } from '@app/pages/SocialMediaPage/useEditedPostContext.hook';
import styled, { css } from 'styled-components';
import Modal from '@ui/atoms/Modal';
import { Dialog, DialogTrigger, Popover, Button as AriaButton } from 'react-aria-components';
import PostDetails from '../../../PostDetails';
import SocialMediaPostDeleteModal from '../../../SocialMediaPostDeleteModal';
import { SOCIAL_NETWORK_NAME, SOCIAL_NETWORK_POST_STATUS } from '@/utils/constants';
import MenuDropdown from '@ui/atoms/MenuDropdown';
import smartlinkIllustration from '@public/images/post_smartlink_background.png';

const SidebarItem = ({ post, isDraft }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [postDetailsShownAt, setPostDetailsShownAt] = useState(null);
    const platformLookup = {
        [FACEBOOK]: <FacebookIcon icon={icon({ name: 'facebook', style: 'brands' })} />,
        [TWITTER]: <XIcon icon={icon({ name: 'x-twitter', style: 'brands' })} />,
        [INSTAGRAM]: <InstagramIcon icon={icon({ name: 'instagram', style: 'brands' })} />,
        [LINKEDIN]: <LinkedinIcon icon={icon({ name: 'linkedin', style: 'brands' })} />,
    };
    const editPostContext = useEditedPostContext();
    const deletePost = useDeleteSocialNetworkPostMutation();
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const isSmartlink = post?.type === SOCIAL_NETWORK_POST_TYPE.SMARTLINK;
    const postImage = isSmartlink
        ? smartlinkIllustration
        : post?.imageUrl || post?.podcast?.imageUrl;

    const dropdownItems = [
        {
            id: 'details',
            icon: <MenuItemIcon icon={icon({ name: 'eye', style: 'solid' })} />,
            name: <FormattedMessage defaultMessage="Détails" />,
        },
        {
            id: 'edit',
            icon: <MenuItemIcon icon={icon({ name: 'pen-line', style: 'solid' })} />,
            name: <FormattedMessage defaultMessage="Modifier" />,
        },
        {
            id: 'duplicate',
            icon: <MenuItemIcon icon={icon({ name: 'clone', style: 'solid' })} />,
            name: <FormattedMessage defaultMessage="Dupliquer" />,
        },
        {
            id: 'delete',
            icon: <MenuItemIcon icon={icon({ name: 'trash', style: 'solid' })} />,
            name: <FormattedMessage defaultMessage="Supprimer" />,
            color: '--alert',
        },
    ];
    const publishedDropdownItems = [
        {
            id: 'details',
            icon: <MenuItemIcon icon={icon({ name: 'eye', style: 'solid' })} />,
            name: <FormattedMessage defaultMessage="Détails" />,
        },
        {
            id: 'duplicate',
            icon: <MenuItemIcon icon={icon({ name: 'clone', style: 'solid' })} />,
            name: <FormattedMessage defaultMessage="Dupliquer" />,
        },
        {
            id: 'link',
            icon: <MenuItemIcon icon={icon({ name: 'arrow-up-right', style: 'solid' })} />,
            name: (
                <MenuItemLink href={post.externalUrl} target="_blank" rel="noreferrer">
                    <FormattedMessage
                        defaultMessage="Voir sur {provider}"
                        values={{ provider: SOCIAL_NETWORK_NAME[post.provider] }}
                    />
                </MenuItemLink>
            ),
        },
    ];
    const menuItems =
        post.state === SOCIAL_NETWORK_POST_STATUS.DONE ? publishedDropdownItems : dropdownItems;

    const handleDeletePost = () => {
        deletePost.mutate(
            { postId: post.id },
            {
                onSuccess: () => {
                    setIsDeleteModalOpen(false);
                },
            },
        );
    };

    const menuItemsHandler = (action) => {
        switch (action) {
            case 'details':
                setPostDetailsShownAt(post.id);
                setIsMenuOpen(false);
                break;
            case 'edit':
                editPostContext.setEditedPost({ post });
                setIsMenuOpen(false);
                break;
            case 'duplicate':
                editPostContext.setEditedPost({ post, duplicate: true });
                setIsMenuOpen(false);
                break;
            case 'delete':
                setIsDeleteModalOpen(true);
                setIsMenuOpen(false);
                break;
            default:
                break;
        }
    };

    return (
        <>
            <Item>
                <ItemCoverWrapper>
                    {postImage ? (
                        <ItemCover src={postImage} />
                    ) : (
                        <CoverPlaceholder>
                            <FontAwesomeIcon
                                icon={icon({
                                    name: 'pen-ruler',
                                    style: 'solid',
                                })}
                            />
                        </CoverPlaceholder>
                    )}
                    <ItemPlatformWrapper>{platformLookup[post.provider]}</ItemPlatformWrapper>
                </ItemCoverWrapper>
                <ItemContentWrapper>
                    {post.messageTpl ? (
                        <ItemContent isDraft={isDraft}>{post.messageTpl}</ItemContent>
                    ) : (
                        <EmptyContent>
                            <FormattedMessage defaultMessage="Post vide" />
                        </EmptyContent>
                    )}
                    {!isDraft && post.publishAt && (
                        <ItemDate>
                            <Text>
                                <FormattedDate value={post.publishAt} />
                            </Text>
                            <Text>
                                <FormattedMessage defaultMessage="à" />
                            </Text>
                            <Text>
                                <FormattedTime value={post.publishAt} />
                            </Text>
                        </ItemDate>
                    )}
                </ItemContentWrapper>
                <DialogTrigger>
                    <TriggerButton aria-label="Menu" onPress={() => setIsMenuOpen(true)}>
                        <TriggerIcon icon={icon({ name: 'ellipsis', style: 'solid' })} />
                    </TriggerButton>
                    <Popover
                        isOpen={isMenuOpen}
                        onOpenChange={setIsMenuOpen}
                        placement="bottom end"
                    >
                        <MenuDropdown items={menuItems} onAction={menuItemsHandler} />
                    </Popover>
                </DialogTrigger>
            </Item>
            <PostDetailsModal
                isOpen={postDetailsShownAt === post.id}
                onOpenChange={setPostDetailsShownAt}
            >
                <Dialog>
                    <PostDetails
                        id={post.id}
                        platformLookup={platformLookup}
                        onHide={() => setPostDetailsShownAt(null)}
                    />
                </Dialog>
            </PostDetailsModal>
            <SocialMediaPostDeleteModal
                isOpen={isDeleteModalOpen}
                onOpenChange={setIsDeleteModalOpen}
                isLoading={deletePost.isLoading}
                onDelete={handleDeletePost}
                provider={post.provider}
            />
        </>
    );
};

const Item = styled.li`
    background-color: var(--white);
    border-radius: var(--r-m);
    padding: 0.75rem;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.05);
    max-height: 4rem;
`;
const ItemCoverWrapper = styled.div`
    position: relative;
    width: 2.5rem;
    height: 2.5rem;
    flex-shrink: 0;
`;
const ItemCover = styled.img`
    width: 100%;
    height: 100%;
    border-radius: var(--r-s);
`;
const CoverPlaceholder = styled.div`
    width: 2.5rem;
    height: 2.5rem;
    border-radius: var(--r-s);
    background-color: var(--neutral50);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    color: var(--neutral300);
`;
const ItemPlatformWrapper = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 1rem;
    height: 1rem;
    border-top-right-radius: var(--r-s);
    background-color: var(--white);
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
`;
const ItemContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;
const ItemDate = styled.div`
    font-size: var(--fs-body-s);
    color: var(--neutral500);
    display: flex;
    align-items: center;
    gap: 0.25rem;
`;
const ItemContent = styled.div`
    font-size: var(--fs-body-m);
    font-weight: var(--fw-semibold);
    width: 10rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
const EmptyContent = styled.span`
    flex-grow: 1;
    color: var(--neutral500);
    font-style: italic;
    font-weight: var(--fw-semibold);
    font-size: var(--fs-body-m);
`;
const PlatformIconBase = css`
    font-size: 0.75rem;
`;
const FacebookIcon = styled(FontAwesomeIcon)`
    ${PlatformIconBase};
    color: var(--facebook);
`;
const XIcon = styled(FontAwesomeIcon)`
    ${PlatformIconBase};
    color: var(--twitter);
`;
const InstagramIcon = styled(FontAwesomeIcon)`
    ${PlatformIconBase};
    color: var(--instagram);
`;
const LinkedinIcon = styled(FontAwesomeIcon)`
    ${PlatformIconBase};
    color: var(--linkedin);
`;
const MenuItemIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: inherit;
`;
const MenuItemLink = styled.a`
    color: inherit;
    text-decoration: none;
`;
const PostDetailsModal = styled(Modal)`
    width: 20rem;
    box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.1);
    color: var(--black);
`;
const TriggerButton = styled(AriaButton)`
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
`;
const TriggerIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--neutral500);
`;

SidebarItem.propTypes = {
    isDraft: PropTypes.bool,
    post: PropTypes.objectOf({
        id: PropTypes.string,
        imageUrl: PropTypes.string,
        messageTpl: PropTypes.string,
        podcast: PropTypes.objectOf({
            id: PropTypes.string,
            imageUrl: PropTypes.string,
            name: PropTypes.string,
            state: PropTypes.string,
        }),
        provider: PropTypes.string,
        publishAt: PropTypes.date,
    }),
    showPostDropdown: PropTypes.shape(),
    setShowPostDropdown: PropTypes.func,
};

export default SidebarItem;
