import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { getEpisodeSlug } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import episodeSlugKeys from '@/queries/episodeSlug/episodeSlugKeys';
import { z } from 'zod';

export const EpisodeSlugSchema = z.object({
    name: z.string().nullable().optional(),
    slug: z.string().nullable().optional(),
});
type InputProps = {
    episodeId: string;
    name: string;
};
type Key = QueryFunctionContext<ReturnType<typeof episodeSlugKeys.detailByEpisodeId>>;
export type EpisodeSlug = z.infer<typeof EpisodeSlugSchema>;

const queryFn = async ({ queryKey: [{ episodeId, name }] }: Key) => {
    const { data } = await getEpisodeSlug(episodeId, encodeURIComponent(name));

    return EpisodeSlugSchema.parse(camelcaseKeys(data, { deep: true }));
};

const useEpisodeSlugQuery = ({ episodeId, name }: InputProps, config = {}) => {
    return useQuery({
        queryKey: episodeSlugKeys.detailByEpisodeId(episodeId, name),
        queryFn,
        ...config,
    });
};

export default useEpisodeSlugQuery;
