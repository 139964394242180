import ContentLoader from 'react-content-loader';
import createFallback from './createFallback';

const h = 13;

const mb1 = 8 + h;
const w1 = 200;
const w2 = 820;

const Rect = (props) => <rect x={0} rx={2} ry={2} width={w1} height={h} {...props} />;

function ShortListFallback({ theme, ...props }) {
    return (
        <ContentLoader
            height={45}
            width={840 - 2 * 35}
            style={{ height: '100%', width: '80%', padding: '20px' }}
            {...props}
        >
            <Rect y="0" />
            <Rect y={mb1} width={w2} />
        </ContentLoader>
    );
}

export default createFallback(ShortListFallback);
