import ChaptersSummary from './ChaptersSummary';
import DescriptionSummary from './DescriptionSummary';
import NameSummary from './NameSummary';
import TagsSummary from './TagsSummary';

const ContentSummary = () => {
    return (
        <>
            <NameSummary />
            <DescriptionSummary />
            <TagsSummary />
            <ChaptersSummary />
        </>
    );
};

export default ContentSummary;
