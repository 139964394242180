import React from 'react';
import { Control, FieldValues, useController } from 'react-hook-form';
import DropFileUploader from './DropFileUploader';

interface DropFileUploaderControllerProps extends React.ComponentProps<typeof DropFileUploader> {
    control: Control<FieldValues>;
    name: string;
}

export const DropFileUploaderController = (props: DropFileUploaderControllerProps) => {
    const { control, name, onChange, ...rest } = props;
    const { field, fieldState } = useController({
        name,
        control,
    });

    return (
        <DropFileUploader
            {...rest}
            onChange={(value) => {
                const forwardedValue = !value ? null : value;
                field.onChange(forwardedValue);
                onChange && onChange(forwardedValue);
            }}
            hasError={!!fieldState.error}
            errorMessage={fieldState.error?.message || ''}
        />
    );
};
