import { FormattedMessage } from 'react-intl';
import Stack from '@ui/layout/Stack';
import Text from '@ui/atoms/Text';
import { BackgroundUploaderController } from '@ui/molecules/BackgroundUploader';

function CustomBackground({ field, formatPage, type, coverSize, ...props }) {
    return (
        <Stack $gap="1rem">
            <Stack $gap="0.5rem">
                <Stack $gap="0.25rem">
                    <Text fontWeight="--fw-semibold">
                        <FormattedMessage defaultMessage="Image d'arrière-plan" />
                    </Text>
                    <Text color="--neutral500">
                        {formatPage === 'cover_smartlink' ? (
                            <FormattedMessage defaultMessage="L’image d’arrière-plan s’étend sur toute la page de votre Smartlink et remplace la couleur d’arrière-plan" />
                        ) : (
                            <FormattedMessage
                                defaultMessage="Ce fond sera appliqué à l'en-tête de votre {type}."
                                values={{ type }}
                            />
                        )}
                    </Text>
                </Stack>
                <BackgroundUploaderController
                    align="flex-start"
                    objectFit="cover"
                    formatPage={formatPage}
                    {...props}
                />
            </Stack>
            <Text color="--neutral500">
                <FormattedMessage
                    defaultMessage="Taille idéale : {coverSize} {linebreak} Format : JPG ou PNG {linebreak} Poids max : 10 Mo"
                    values={{ coverSize, linebreak: <br /> }}
                />
            </Text>
        </Stack>
    );
}

export default CustomBackground;
