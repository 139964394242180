import styled from 'styled-components';
import { FormProvider, useForm } from 'react-hook-form';
import PlayerFormControls from './PlayerFormControls';
import PlayerFormPreview from './PlayerFormPreview';
import Button from '@/components/ui/atoms/Button';
import { FormattedMessage } from 'react-intl';
import ExperimentalTooltip from '@/components/ui/atoms/ExperimentalTooltip';
import Text from '@/components/ui/atoms/Text';
import { PlayerSettings } from '@/queries/playerSettings/usePlayerSettingsQuery.hook';
import usePlayerFormSchema, { PlayerFormSchema } from './usePlayerFormSchema.hook';
import { zodResolver } from '@hookform/resolvers/zod';
import type { ActionButton } from '@/queries/callToAction/useCallToActionButtonsQuery.hook';
import { useParams } from 'react-router-dom';

interface PlayerFormProps {
    onSubmit: (formData: PlayerFormSchema) => void;
    settings: PlayerSettings | undefined;
    isGranted: boolean;
    isLoading: boolean;
    callToActionButtons: ActionButton[];
}

const PlayerForm = ({
    onSubmit,
    settings,
    isGranted,
    isLoading,
    callToActionButtons,
}: PlayerFormProps) => {
    const schema = usePlayerFormSchema();
    /* Default type and episode or playlist can be passed as url params */
    const { id, type } = useParams<{ id: string; type: 'episodes' | 'playlists' }>();
    const hasDefaultSelectedEpisode = type === 'episodes' && id;
    const hasDefaultSelectedPlaylist = type === 'playlists' && id;
    const defaultType = hasDefaultSelectedEpisode
        ? 'episode'
        : hasDefaultSelectedPlaylist
        ? 'playlist'
        : 'show';

    const methods = useForm<PlayerFormSchema>({
        mode: 'onBlur',
        resolver: zodResolver(schema),
        defaultValues: {
            sizeUnit: settings?.sizeUnit || 'percent',
            type: defaultType,
            episodeId: hasDefaultSelectedEpisode ? id : undefined,
            playlistSlug: hasDefaultSelectedPlaylist ? id : undefined,
            format: settings?.format || 'auto',
            color: settings?.color || '#751CBF',
            darkMode: settings?.darkMode || false,
            episodeList: settings?.episodeList || false,
            showSwitch: settings?.showSwitch || false,
            width: settings?.width || 100,
            startAt: settings?.startAt || 0,
            callToActionButtons: callToActionButtons || [],
        },
    });

    const {
        handleSubmit,
        formState: { dirtyFields },
    } = methods;

    const isDirty = Object.entries(dirtyFields)
        .filter(([fieldName]) =>
            [
                'format',
                'color',
                'darkMode',
                'episodeList',
                'showSwitch',
                'width',
                'startAt',
                'sizeUnit',
                'callToActionButtons',
            ].includes(fieldName),
        )
        .some(([_, isFieldDirty]) => isFieldDirty === true);

    return (
        <FormProvider {...methods}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <InputsWrapper>
                    <PlayerFormControls />
                    <Tooltip
                        isDisabled={isGranted}
                        content={
                            // @ts-ignore
                            <Text fontWeight="--fw-semibold">
                                <FormattedMessage defaultMessage="Vous devez être administrateur de l'émission pour accéder à cette fonctionnalité." />
                            </Text>
                        }
                    >
                        {/* @ts-ignore */}
                        <Button
                            isLoading={isLoading}
                            isDisabled={!isGranted || !isDirty}
                            type="submit"
                        >
                            <FormattedMessage defaultMessage="Enregistrer" />
                        </Button>
                    </Tooltip>
                </InputsWrapper>
                <PreviewWrapper>
                    <PlayerFormPreview />
                </PreviewWrapper>
            </Form>
        </FormProvider>
    );
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
        column-gap: 2rem;
    }
`;
const InputsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        max-width: 25rem;
        flex-basis: 25rem;
        flex-grow: 0;
        flex-shrink: 0;
    }
`;
const PreviewWrapper = styled.div`
    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex: 1;
        padding: 2rem;
        background-color: var(--neutral50);
        border-radius: var(--r-m);

        min-width: 0;
        flex-basis: 0;
        flex-shrink: 1;
        flex-grow: 1;
    }
`;
const Tooltip = styled(ExperimentalTooltip)`
    align-self: center;
`;

export default PlayerForm;
