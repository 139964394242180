import { ReactNode } from 'react';
import styled from 'styled-components';
import Header from '@/features/onboarding/components/Onboarding/Layout/Header';
import Footer from '@/features/onboarding/components/Onboarding/Layout/Footer';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router';
import Button from '@/components/Button';
import { FormattedMessage } from 'react-intl';
import useShowsQuery from '@queries/show/useShowsQuery.hook';

interface LayoutProps {
    children: ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
    const history = useHistory();
    const { data: shows } = useShowsQuery();
    const hasShow = (shows?.shows?.length || 0) > 0;
    return (
        <Wrapper>
            {hasShow && (
                <CloseButton
                    startIcon={<FontAwesomeIcon icon={icon({ name: 'xmark', style: 'solid' })} />}
                    aria-label="Close"
                    variant="link-secondary"
                    onPress={() => history.push('/')}
                >
                    <FormattedMessage defaultMessage="Fermer" />
                </CloseButton>
            )}
            <InnerWrapper>
                <Header />
                {children}
            </InnerWrapper>
            {!hasShow && <Footer />}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    row-gap: 4rem;
    margin: auto;
    padding-block: 2rem;
    padding-inline: 1rem;
    min-height: 100%;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        padding-top: 4rem;
    }
`;
const InnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    max-width: 37.5rem;
`;
const CloseButton = styled(Button)`
    position: absolute;
    top: 1rem;
    align-self: flex-start;
`;

export default Layout;
