import { useState } from 'react';
import Button from '@/components/Button';
import PauseModal from '@/features/Campaigns/components/PauseModal';
import updateCampaignMutation from '@/queries/campaign/updateCampaignMutation';
import useCampaignQuery from '@/queries/campaign/useCampaignQuery';
import { CAMPAIGN_STATUS } from '@/shared/config/constants';
import { useBodyToastQueue } from '@/shared/hooks/useBodyToastQueue.hook';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import styled from 'styled-components';

const PlayPauseButton = () => {
    const { campaignId } = useParams<{ campaignId: string }>();
    const campaignQuery = useCampaignQuery(campaignId);
    const isPaused = campaignQuery.data?.state === CAMPAIGN_STATUS.PAUSED;
    const isActive = campaignQuery.data?.state === CAMPAIGN_STATUS.ACTIVE;
    const updateCampaign = updateCampaignMutation();
    const toast = useBodyToastQueue();
    const [isPauseModalOpen, setIsPauseModalOpen] = useState(false);

    if (!isPaused && !isActive) return null;

    const handlePlayPauseCampaign = () => {
        updateCampaign.mutate(
            {
                campaignId,
                campaign: {
                    state: isPaused ? CAMPAIGN_STATUS.ACTIVE : CAMPAIGN_STATUS.PAUSED,
                },
            },
            {
                onSuccess: () => {
                    setIsPauseModalOpen(false);
                },
                onError: () => {
                    toast.alert();
                },
            },
        );
    };

    return (
        <>
            <Button
                variant="secondary"
                startIcon={
                    isPaused ? (
                        <Icon icon={icon({ name: 'play', style: 'solid' })} />
                    ) : (
                        <Icon icon={icon({ name: 'pause', style: 'solid' })} />
                    )
                }
                onPress={() => setIsPauseModalOpen(true)}
            >
                {isPaused ? (
                    <FormattedMessage defaultMessage="Republier" />
                ) : (
                    <FormattedMessage defaultMessage="Mettre en pause" />
                )}
            </Button>
            <PauseModal
                isOpen={isPauseModalOpen}
                onOpenChange={setIsPauseModalOpen}
                onPlayPause={handlePlayPauseCampaign}
                isLoading={updateCampaign.isLoading}
                isPaused={isPaused}
            />
        </>
    );
};

const Icon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;

export default PlayPauseButton;
