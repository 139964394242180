import { FC } from 'react';
import styled from 'styled-components';
import NoDataChart from '@/components/charts/NoData/NoDataChart';

const NoDataChartOverlay: FC = () => (
    <Container>
        <Wrapper>
            <NoDataChart />
        </Wrapper>
    </Container>
);

const Container = styled.div`
    position: absolute;
    inset: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 0 0.75rem 5rem;
`;

const Wrapper = styled.div`
    border-radius: var(--r-l);
    padding: var(--sp-l);
    background: rgba(255, 255, 255, 0.75); // Fallback if gradient is not supported
    background: radial-gradient(rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0));
`;

export default NoDataChartOverlay;
