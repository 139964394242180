import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import { useResponsive } from '@/utils/hooks/useResponsive';
import PlaylistLine from '@app/molecules/PlaylistLine';
import NewPlaylistButton from '@app/molecules/NewPlaylistButton';
import PlaylistEpisodesHeader from '@app/organisms/PlaylistEpisodesHeader';
import { connect } from '@app/decorators/connect';
import FetcherPlaylistPodcasts from '@app/fetchers/FetcherPlaylistPodcasts';
import PlaylistEpisodes from '@app/organisms/PlaylistEpisodes';
import HideFor from '@app/atoms/HideFor';
import PlaylistMenuDropdown from '@app/molecules/PlaylistMenuDropdown';
import { useAmplitudeLogEvent } from '@hooks/useAmplitude';
import { STATUS_SHOWS } from '@/utils/statusShows';
import styled from 'styled-components';

const enhance = connect(({ routerStore, playlistStore }) => ({
    playlists: playlistStore.playlists,
    playlistId: routerStore.resolve('playlistId')?.id,
    activePlaylist: routerStore.resolve('playlistId'),
}));

function PlaylistsListPage({ playlists, playlistId, activePlaylist }) {
    useAmplitudeLogEvent('Episodes Section Viewed');
    const { isMobileOrTablet, isDesktop } = useResponsive();

    return (
        <PlaylistsWrapper>
            <PlaylistEpisodesHeader
                playlistCount={playlists.length}
                episodeCount={activePlaylist?.podcastsCount ?? 0}
            />
            <DeprecatedPaper flex wrap z={1}>
                <DeprecatedPaper
                    z={1}
                    w={isMobileOrTablet ? '100%' : 275}
                    mr={isDesktop && 25}
                    mb={isMobileOrTablet && 50}
                    h="fit-content"
                    overflowHide={false}
                >
                    <PlaylistMenuDropdown playlist={activePlaylist}>
                        {playlists.map((playlist) => (
                            <PlaylistLine key={playlist.id} playlist={playlist} />
                        ))}
                        <HideFor roles={['viewer']} showStatus={[STATUS_SHOWS.ARCHIVED_SHOWS]}>
                            <NewPlaylistButton />
                        </HideFor>
                    </PlaylistMenuDropdown>
                </DeprecatedPaper>
                <DeprecatedPaper w={isMobileOrTablet ? '100%' : 'calc(100% - 300px)'}>
                    <FetcherPlaylistPodcasts playlistId={playlistId}>
                        {(isPodcastsFetching) => (
                            <PlaylistEpisodes isLoading={isPodcastsFetching} />
                        )}
                    </FetcherPlaylistPodcasts>
                </DeprecatedPaper>
            </DeprecatedPaper>
        </PlaylistsWrapper>
    );
}

const PlaylistsWrapper = styled.div``;

export default enhance(PlaylistsListPage);
