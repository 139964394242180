import { useState } from 'react';
import close from '@public/icons/close.svg';
import { FormattedDate, FormattedMessage } from 'react-intl';
import Link from '@ui/atoms/Link';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import DeprecatedButton from '@ui/atoms/DeprecatedButton';
import DeprecatedIcon from '@ui/atoms/DeprecatedIcon';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import Status from '@ui/molecules/Status';
import CampaignPauseModal from './CampaignPauseModal';
import Spinner from '@ui/atoms/Spinner';
import styled from 'styled-components';
import Cluster from '@ui/layout/Cluster';
import useRouterStore from '@/shared/hooks/useRouterStore';
import { observer } from 'mobx-react';
import { CAMPAIGN_STATUS } from '@/shared/config/constants';
import IconPlay from '@ui/icons/IconPlay';
import IconPause from '@ui/icons/IconPause';
import { useParams } from 'react-router-dom';

const CampaignEditHeaderOuterWrapper = styled.header`
    flex-shrink: 0;
    height: 5rem;
    width: 100%;
    background: var(--white);
`;

const CampaignEditHeaderInnerWrapper = styled(Cluster)`
    width: 100%;
    margin: auto;
    padding: 1rem;
    max-width: 960px;

    ${(p) => p.theme.mediaQueries.tabletAndUp} {
        max-width: none;
    }
`;

const statusMessages = {
    [CAMPAIGN_STATUS.ACTIVE]: <FormattedMessage defaultMessage="Campagne en ligne" />,
    [CAMPAIGN_STATUS.PLANIFIED]: <FormattedMessage defaultMessage="Campagne planifiée" />,
    [CAMPAIGN_STATUS.DRAFT]: <FormattedMessage defaultMessage="Campagne en brouillon" />,
    [CAMPAIGN_STATUS.BLOCKED]: <FormattedMessage defaultMessage="Campagne bloquée" />,
    [CAMPAIGN_STATUS.FINISHED]: <FormattedMessage defaultMessage="Campagne terminée" />,
    [CAMPAIGN_STATUS.PAUSED]: <FormattedMessage defaultMessage="Campagne en pause" />,
};

const CampaignEditHeader = ({ campaign, hasChanged }) => {
    const { showId } = useParams();
    const { link, push } = useRouterStore();
    const [isPauseModalOpen, setIsPauseModalOpen] = useState(false);
    const isLoading = campaign?.update.pending;
    const isPaused = campaign.status === CAMPAIGN_STATUS.PAUSED;

    const handleAfterPause = () => {
        setIsPauseModalOpen(false);
        push('menu.monetize.manual');
    };

    return (
        <>
            <CampaignEditHeaderOuterWrapper $justify="center" $align="center">
                <CampaignEditHeaderInnerWrapper
                    $justify="space-between"
                    $align="center"
                    $gap="1rem"
                    $wrap="no-wrap"
                >
                    <Status icon value={campaign?.status} mr={10}>
                        {statusMessages[campaign?.status]}
                    </Status>
                    <DeprecatedPaper ellipsis w="fit-content" auto>
                        {campaign && (
                            <>
                                <DeprecatedText weight="semibold" size="big" ellipsis maxW={500}>
                                    {campaign.name}
                                </DeprecatedText>
                                <DeprecatedText color="--neutral500">
                                    {campaign.startsAt && campaign.endsAt && (
                                        <FormattedMessage
                                            defaultMessage="Du {from} au {to}"
                                            values={{
                                                from: <FormattedDate value={campaign.startsAt} />,
                                                to: <FormattedDate value={campaign.endsAt} />,
                                            }}
                                        />
                                    )}
                                    {campaign.startsAt && !campaign.endsAt && (
                                        <FormattedMessage
                                            defaultMessage="Commence le {from}"
                                            values={{
                                                from: <FormattedDate value={campaign.startsAt} />,
                                            }}
                                        />
                                    )}
                                </DeprecatedText>
                            </>
                        )}
                    </DeprecatedPaper>

                    <Cluster $align="center" $gap="1rem">
                        <DeprecatedButton
                            accent="secondary"
                            mr={10}
                            onClick={() => setIsPauseModalOpen(true)}
                        >
                            {isPaused ? <IconPlay /> : <IconPause />}
                            {isPaused ? (
                                <FormattedMessage defaultMessage="Republier" />
                            ) : (
                                <FormattedMessage defaultMessage="Mettre en pause" />
                            )}
                        </DeprecatedButton>
                        <DeprecatedButton type="submit" disabled={isLoading || !hasChanged}>
                            {isLoading && <Spinner />}
                            <FormattedMessage defaultMessage="Enregistrer et fermer" />
                        </DeprecatedButton>

                        <DeprecatedButton
                            accent="rounded"
                            as={Link}
                            icon
                            to={link('menu.monetize.manual', { showId })}
                        >
                            <DeprecatedIcon as={close} color="black" />
                        </DeprecatedButton>
                    </Cluster>
                </CampaignEditHeaderInnerWrapper>
            </CampaignEditHeaderOuterWrapper>
            <CampaignPauseModal
                onAfterPause={handleAfterPause}
                campaign={campaign}
                isPaused={isPaused}
                isOpen={isPauseModalOpen}
                onOpenChange={setIsPauseModalOpen}
            />
        </>
    );
};

export default observer(CampaignEditHeader);
