import { useMutation } from '@tanstack/react-query';
import { checkImport } from '@/api';
import camelcaseKeys from 'camelcase-keys';

export interface MutationFnProps {
    showId: string;
    payload: { url: string };
}

const mutationFn = async ({ showId, payload }: MutationFnProps) => {
    const { data } = await checkImport(showId, payload);
    return camelcaseKeys(data, { deep: true });
};

const useCheckRssFeedMutation = () => {
    return useMutation({
        mutationFn,
    });
};

export default useCheckRssFeedMutation;
