import { useState, useEffect } from 'react';
import { useParams, useHistory, Route, Switch, Redirect } from 'react-router';
import Layout from './Layout';
import PsoKeywordTracking from './PsoKeywordTracking';
import PsoOverview from './PsoOverview';
import PsoConfigurationTunnel from './PsoConfigurationTunnel';
import PsoMobileBlockScreen from './PsoMobileBlockScreen';
import usePsoQuery from '@queries/pso/usePsoQuery.hook';
import PsoKeywords from './PsoKeywords';
import {
    APPLE_PODCASTS,
    SPOTIFY,
    USER_ROLES,
    ENABLE_PSO_COMPETITORS,
    ENABLE_PSO_LIVE_SEARCH,
} from '@/shared/config/constants';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import useSubscriptionQuery from '@/queries/subscription/useSubscriptionQuery.hook';
import PsoBlockingScreen from './PsoBlockingScreen';
import PsoLiveSearch from './PsoLiveSearch';
import PsoCompetitors from './PsoCompetitors';
import { STATUS_SHOWS } from '@/shared/config/statusShows';
import { useQueryClient } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import psoKeys from '@/queries/pso/psoKeys';
import useHasAccessToPso from '@/shared/hooks/useHasAccessToPso.hook';
import styled from 'styled-components';
import PsoKeywordsRanking from './PsoKeywordsRanking';
import useHasAccessToPsoKeywordsRanking from '@/shared/hooks/useHasAccessToPsoKeywordsRanking.hook';
import { PRICING } from '@/shared/config/pricing';
import useHasAccessToPsoCompetitors from '@/shared/hooks/useHasAccessToPsoCompetitors.hook';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Pso = () => {
    const { showId }: { showId: string } = useParams();
    const history = useHistory();
    const queryClient = useQueryClient();
    const subscription = useSubscriptionQuery();
    const show = useShowQuery(showId);
    const psoConfiguration = usePsoQuery({ showId, enabled: true });
    const hasAccessToPso = useHasAccessToPso({ showId });
    const hasAccessToPsoKeywordsRanking = useHasAccessToPsoKeywordsRanking({ showId });
    const [currentStore, setCurrentStore] = useState('');
    const [currentPlatform, setCurrentPlatform] = useState('');
    const [configurationEndLoading, setConfigurationEndLoading] = useState(false);
    const hasAccessToPsoCompetitors = useHasAccessToPsoCompetitors({ showId });

    useEffect(() => {
        if (psoConfiguration.data) {
            setCurrentStore(
                psoConfiguration.data.stores.data.length > 0 &&
                    psoConfiguration.data.stores.data[0].alpha2Code,
            );
            setCurrentPlatform(psoConfiguration.data.listeningPlatforms[0]);
        }
    }, [psoConfiguration?.data]);

    // TODO: Remove this and display loader ???
    if (
        psoConfiguration.isLoading ||
        show.isLoading ||
        show.isError ||
        subscription.isLoading ||
        subscription.isError ||
        (psoConfiguration.isError &&
            (psoConfiguration.error as AxiosError)?.response?.status !== 403 &&
            (psoConfiguration.error as AxiosError)?.response?.data !== 'missing user option')
    ) {
        return null;
    }

    const hasSpotifyData =
        show.data.listeningLinks.data.findIndex((link) => link.key === SPOTIFY) > -1;
    const hasAppleData =
        show.data.listeningLinks.data.findIndex((link) => link.key === APPLE_PODCASTS) > -1;

    const isGuestShow = show.data && show.data.showStatus === STATUS_SHOWS.GUEST_SHOWS;
    const userIsAdminGuest = show?.data?.userRole === USER_ROLES.ADMIN && isGuestShow;
    const userIsOwner = show?.data?.userRole === USER_ROLES.OWNER;
    const userHasWriteAccess = userIsOwner || userIsAdminGuest;

    /**
     * This is a temporary solution to hide the competitors empty state for non-enterprise PSO beta users.
     * TODO: Remove when "Ausha for all" goes live
     */
    const isEnterprise = isGuestShow
        ? show?.data?.ownerPricing === PRICING.ENTERPRISE
        : subscription?.data?.pricing === PRICING.ENTERPRISE;

    if (!hasAccessToPso) {
        return <PsoBlockingScreen />;
    }

    if (psoConfiguration.data) {
        return (
            <>
                <MobileWrapper>
                    <PsoMobileBlockScreen />
                </MobileWrapper>
                <Switch>
                    <Route path="/app/show/:showId/pso" exact>
                        <Redirect
                            to={
                                psoConfiguration.data?.fullyConfigured === false ||
                                configurationEndLoading
                                    ? `/app/show/${showId}/pso/setup`
                                    : `/app/show/${showId}/pso/overview`
                            }
                        />
                    </Route>
                    <Route path="/app/show/:showId/pso/setup">
                        <PsoConfigurationTunnel
                            keywordsSaved={psoConfiguration.data.keywordsSaved}
                            keywords={psoConfiguration.data.keywords}
                            onRedirectToOverview={() => {
                                queryClient.invalidateQueries({
                                    queryKey: psoKeys.globalConfiguration(showId),
                                });
                                history.push(`/app/show/${showId}/pso/overview`);
                            }}
                            setConfigurationEndLoading={(v) => setConfigurationEndLoading(v)}
                            userHasWriteAccess={userHasWriteAccess}
                            currentStore={currentStore}
                            isFullyConfigured={psoConfiguration.data.fullyConfigured}
                        />
                    </Route>
                    <Route path="/app/show/:showId/pso/overview">
                        {psoConfiguration.data?.fullyConfigured === false ? (
                            <Redirect to={`/app/show/${showId}/pso/setup`} />
                        ) : (
                            <Layout userHasWriteAccess={userHasWriteAccess}>
                                <PsoOverview
                                    globalConfiguration={psoConfiguration.data}
                                    currentStore={currentStore}
                                    setCurrentStore={setCurrentStore}
                                    hasSpotifyData={hasSpotifyData}
                                    hasAppleData={hasAppleData}
                                    userHasWriteAccess={userHasWriteAccess}
                                    stores={psoConfiguration.data.stores.data}
                                />
                            </Layout>
                        )}
                    </Route>
                    <Route path="/app/show/:showId/pso/details">
                        {psoConfiguration.data?.fullyConfigured === false ? (
                            <Redirect to={`/app/show/${showId}/pso/setup`} />
                        ) : (
                            <Layout userHasWriteAccess={userHasWriteAccess}>
                                <PsoKeywordTracking
                                    currentStore={currentStore}
                                    setCurrentStore={setCurrentStore}
                                    currentPlatform={currentPlatform}
                                    setCurrentPlatform={setCurrentPlatform}
                                    hasSpotifyData={hasSpotifyData}
                                    hasAppleData={hasAppleData}
                                    userHasWriteAccess={userHasWriteAccess}
                                    stores={psoConfiguration.data.stores.data}
                                />
                            </Layout>
                        )}
                    </Route>
                    {(isEnterprise || hasAccessToPsoKeywordsRanking) && (
                        <Route path="/app/show/:showId/pso/ranking">
                            {psoConfiguration.data?.fullyConfigured === false ? (
                                <Redirect to={`/app/show/${showId}/pso/setup`} />
                            ) : (
                                <Layout userHasWriteAccess={userHasWriteAccess}>
                                    <PsoKeywordsRanking />
                                </Layout>
                            )}
                        </Route>
                    )}
                    {/*
                     * This is a temporary solution to hide the competitors empty state for non-enterprise PSO beta users.
                     * TODO: Remove when "Ausha for all" goes live
                     */}
                    {ENABLE_PSO_COMPETITORS && (isEnterprise || hasAccessToPsoCompetitors) && (
                        <Route path="/app/show/:showId/pso/competitors">
                            {psoConfiguration.data?.fullyConfigured === false ? (
                                <Redirect to={`/app/show/${showId}/pso/setup`} />
                            ) : (
                                <Layout userHasWriteAccess={userHasWriteAccess}>
                                    <PsoCompetitors />
                                </Layout>
                            )}
                        </Route>
                    )}
                    {ENABLE_PSO_LIVE_SEARCH && (
                        <Route path="/app/show/:showId/pso/livesearch">
                            {psoConfiguration.data?.fullyConfigured === false ? (
                                <Redirect to={`/app/show/${showId}/pso/setup`} />
                            ) : (
                                <Layout userHasWriteAccess={userHasWriteAccess}>
                                    <PsoLiveSearch />
                                </Layout>
                            )}
                        </Route>
                    )}
                    <Route path="/app/show/:showId/pso/keywords">
                        {psoConfiguration.data?.fullyConfigured === false ? (
                            <Redirect to={`/app/show/${showId}/pso/setup`} />
                        ) : (
                            <Layout userHasWriteAccess={userHasWriteAccess}>
                                <PsoKeywords
                                    currentStore={currentStore}
                                    existingKeywords={psoConfiguration.data.keywords}
                                />
                            </Layout>
                        )}
                    </Route>
                </Switch>
            </>
        );
    }

    return (
        <LoadingWrapper>
            <FontAwesomeIcon icon={icon({ name: 'spinner-third', style: 'solid' })} spin />
        </LoadingWrapper>
    );
};

const MobileWrapper = styled.div`
    display: block;

    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        display: none;
    }
`;
const LoadingWrapper = styled.div`
    padding-block: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--primary);
`;

export default Pso;
