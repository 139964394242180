import { ENABLE_PSO_CHECKER } from '../config/constants';
import useHasPsoFullUserOption from './useHasPsoFullUserOption.hook';

interface HasAccessToPsoCheckerProps {
    showId: string;
}

const useHasAccessToPsoChecker = ({ showId }: HasAccessToPsoCheckerProps) => {
    const hasPsoFullUserOption = useHasPsoFullUserOption({ showId });
    return ENABLE_PSO_CHECKER && hasPsoFullUserOption;
};

export default useHasAccessToPsoChecker;
