import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateSoundcloudBroadcastSettings } from '@/api';

export default function useUpdateSoundcloudBroadcastSettings(showId) {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (formData) => {
            if (!showId) return;
            return updateSoundcloudBroadcastSettings(showId, formData);
        },
        /**
         * If the mutation fails,
         * use the context returned from onMutate to roll back
         */
        onError: (err, data, context) => {
            queryClient.setQueryData(['shows.soundcloud.show', showId], context.previousState);
        },
        /**
         * Always refetch after error or success:
         */
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['shows.soundcloud.show', showId] });
        },
    });
}
