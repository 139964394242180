import { useParams } from 'react-router';
import EpisodeAICustomPromptForm from './EpisodeAICustomPromptForm';
import { AICustomPromptFormSchema } from './EpisodeAICustomPromptForm/useAICustomPromptFormSchema.hook';
import {
    AISettingsMapItem,
    useEpisodeAISettings,
} from '@/components/EpisodeAI/contexts/EpisodeAISettingsContext';

interface EpisodeAICustomPromptProps {
    onClose: () => void;
    type: AISettingsMapItem;
}

const EpisodeAICustomPrompt = ({ onClose, type }: EpisodeAICustomPromptProps) => {
    const { episodeId } = useParams<{ episodeId: string }>();
    const [settings, setSettings] = useEpisodeAISettings();
    const defaultSettings = settings.get(type) || { customPrompt: '', saveAsDefault: false };

    const handleSessionStorage = (type: AISettingsMapItem, data: AICustomPromptFormSchema) => {
        sessionStorage.setItem(`episode-${episodeId}-ai-${type}-settings`, JSON.stringify(data));
    };

    const handleSubmit = (data: AICustomPromptFormSchema) => {
        switch (type) {
            case 'title':
                handleSessionStorage('title', data);
                setSettings('title', data);
                break;
            case 'description':
                handleSessionStorage('description', data);
                setSettings('description', data);
                break;
            case 'tags':
                handleSessionStorage('tags', data);
                setSettings('tags', data);
                break;
            case 'newsletter':
                handleSessionStorage('newsletter', data);
                setSettings('newsletter', data);
                break;
            default:
                break;
        }
        onClose();
    };

    return (
        <EpisodeAICustomPromptForm
            onClose={onClose}
            onSubmit={handleSubmit}
            type={type}
            values={defaultSettings}
        />
    );
};

export default EpisodeAICustomPrompt;
