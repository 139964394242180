import { useQuery } from '@tanstack/react-query';
import { fetchSmartlinkDominantColor } from '@/api';

export default function useFetchDominantColor(show) {
    return useQuery(
        ['shows.colors.show'],
        async () => {
            const { dominant } = await fetchSmartlinkDominantColor(show);
            return dominant;
        },
        {
            enabled: false,
        },
    );
}
