import { useState } from 'react';
import Button from '@/components/Button';
import IconButton from '@/components/IconButton';
import Modal from '@/components/ui/atoms/Modal';
import Text from '@/components/ui/atoms/Text';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import SelectSeasons from '@/components/unorganized/SelectSeasons';
import Stack from '@/components/ui/layout/Stack';
import { Key } from 'react-aria';

interface CategorizeEpisodesModalProps {
    isOpen: boolean;
    onOpenChange: (open: boolean) => void;
    onAssign: (seasonId: string) => void;
    isLoading: boolean;
}

const CategorizeEpisodesModal = ({
    isOpen,
    onOpenChange,
    onAssign,
    isLoading,
}: CategorizeEpisodesModalProps) => {
    const [seasonId, setSeasonId] = useState<Key>('');

    const handleClose = () => onOpenChange(false);

    return (
        <ModalStyled isOpen={isOpen} onOpenChange={onOpenChange} size="medium">
            <Header>
                <Text variant="headingM" fontWeight="--fw-bold">
                    <FormattedMessage defaultMessage="Dans quelle saison souhaitez-vous classer les épisodes ?" />
                </Text>
                <IconButton
                    icon={<CloseIcon icon={icon({ name: 'xmark-large', style: 'solid' })} />}
                    onPress={handleClose}
                    variant="ghost"
                    aria-label="Close modal"
                    style={{ padding: 0 }}
                />
            </Header>
            <Stack $gap="1rem">
                <Text>
                    <FormattedMessage defaultMessage="Sélectionnez la saison dans laquelle vous souhaitez classer les épisodes." />
                </Text>
                <SelectSeasons value={seasonId} onChange={setSeasonId} />
            </Stack>
            <ActionWrapper>
                <Button
                    onPress={() => {
                        if (!seasonId) return;
                        onAssign(String(seasonId));
                    }}
                    isLoading={isLoading}
                >
                    <FormattedMessage defaultMessage="Ajouter à la saison" />
                </Button>
                <CancelButton variant="ghost" onPress={handleClose}>
                    <FormattedMessage defaultMessage="Annuler" />
                </CancelButton>
            </ActionWrapper>
        </ModalStyled>
    );
};

const ModalStyled = styled(Modal)`
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 2rem;
    overflow-y: auto;
`;
const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 1rem;
`;
const CloseIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--neutral500);
`;
const ActionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 1rem;

    ${({ theme }) => theme.mediaQueries.tabletAndUp} {
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        & > :first-child {
            order: 1;
        }
    }
`;
const CancelButton = styled(Button)`
    padding: 0;
    color: var(--neutral500);
`;

export default CategorizeEpisodesModal;
