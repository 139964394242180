import { useParams } from 'react-router';
import SoundcloudBroadcastSetting from './SoundcloudBroadcastSettingsPage';
import PlatformBroadcastSettings from './PlatformBroadcastSettings';
import YoutubeBroadcastSetting from './YoutubeBroadcastSettingsPage';
import itunes from '@public/images/platforms/ApplePodcasts.png';
import deezer from '@public/images/platforms/Deezer.png';
import podcastAddict from '@public/images/platforms/PodcastAddict.png';
import spotify from '@public/images/platforms/Spotify.png';
import youtube from '@public/images/platforms/YouTube.png';
import tuneIn from '@public/images/platforms/TuneIn.png';
import soundcloud from '@public/images/platforms/SoundCloud.png';
import amazon from '@public/images/platforms/AmazonMusic.png';
import pocketCasts from '@public/images/platforms/PocketCasts.png';
import castbox from '@public/images/platforms/Castbox.png';
import overcast from '@public/images/platforms/Overcast.png';
import castro from '@public/images/platforms/Castro.png';
import podchaser from '@public/images/platforms/Podchaser.png';
import listenNotes from '@public/images/platforms/ListenNotes.png';
import podcastIndex from '@public/images/platforms/PodcastIndex.png';
import iHeartRadio from '@public/images/platforms/iHeart.png';
import pandora from '@public/images/platforms/Pandora.png';
import playerFm from '@public/images/platforms/PlayerFM.png';
import jioSaavn from '@public/images/platforms/JioSaavn.png';
import gaana from '@public/images/platforms/Gaana.png';
import {
    ListeningPlatform,
    YOUTUBE,
    APPLE_PODCASTS,
    TUNEIN,
    I_HEART_RADIO,
    PANDORA,
    PLAYER_FM,
    JIO_SAAVN,
    GAANA,
    RADIO_PUBLIC,
    SOUNDCLOUD,
    PODCAST_ADDICT,
    SPOTIFY,
    POCKET_CASTS,
    CASTBOX,
    OVERCAST,
    CASTRO,
    AMAZON_MUSIC,
    PODCHASER,
    LISTEN_NOTES,
    PODCAST_INDEX,
    DEEZER,
} from '@/utils/constants';

const logoLookup: Record<ListeningPlatform, string | null> = {
    [APPLE_PODCASTS]: itunes,
    [DEEZER]: deezer,
    [PODCAST_ADDICT]: podcastAddict,
    [SPOTIFY]: spotify,
    [YOUTUBE]: youtube,
    [TUNEIN]: tuneIn,
    [POCKET_CASTS]: pocketCasts,
    [CASTBOX]: castbox,
    [OVERCAST]: overcast,
    [CASTRO]: castro,
    [SOUNDCLOUD]: soundcloud,
    [AMAZON_MUSIC]: amazon,
    [PODCHASER]: podchaser,
    [LISTEN_NOTES]: listenNotes,
    [PODCAST_INDEX]: podcastIndex,
    [I_HEART_RADIO]: iHeartRadio,
    [PANDORA]: pandora,
    [PLAYER_FM]: playerFm,
    [JIO_SAAVN]: jioSaavn,
    [GAANA]: gaana,
    [RADIO_PUBLIC]: null,
};

const Platform = () => {
    const { platform } = useParams<{ showId: string; platform: string }>();
    const platformKey: ListeningPlatform = platform as ListeningPlatform;
    const logo = logoLookup[platformKey] || null;

    if (platformKey === YOUTUBE) {
        return <YoutubeBroadcastSetting logo={logo} />;
    }

    if (platformKey === SOUNDCLOUD) {
        return <SoundcloudBroadcastSetting logo={logo} />;
    }

    return <PlatformBroadcastSettings logo={logo} />;
};

export default Platform;
