import { createAuthorizer } from './createAuthorizer';

export const LogoutRoute = createAuthorizer(({ authStore }) => ({
    isBlocked: false,
    onMount: () => authStore.logout(),
}));

export const GuestRoute = createAuthorizer(({ authStore, routerStore }) => ({
    isBlocked: authStore.isLoggedIn && !routerStore.query.token,
    defaultRedirect: routerStore.defaultRedirect,
}));

export const AuthRoute = createAuthorizer(({ authStore }) => ({
    isBlocked: !authStore.isLoggedIn,
    defaultRedirect: '/login',
}));

export const ValidSubscriptionRoute = createAuthorizer(
    ({ authStore, subscriptionStore, routerStore }) => {
        return {
            isBlocked:
                authStore.isLoggedIn &&
                subscriptionStore.subscription &&
                subscriptionStore.subscription.isBlockedForShow(routerStore.params.showId),
            defaultRedirect: routerStore.params.showId
                ? routerStore.link('menu.episodes')
                : routerStore.defaultRedirect,
        };
    },
);

export const AccessRoute = createAuthorizer(({ authStore, routerStore }, { condition, to }) => {
    const { page, tab } = to;
    return {
        isBlocked: authStore.isLoggedIn && condition,
        defaultRedirect: routerStore.link(page, { tab }),
    };
});
