import { useEffect, useState } from 'react';
import { ColorPickerController } from '@/components/ui/molecules/ColorPicker';
import useFetchDominantColor from '../../../SmartlinkSettingsForm/useFetchDominantColor.hook';
import { useParams } from 'react-router';
import Stack from '@/components/ui/layout/Stack';
import { FormattedMessage } from 'react-intl';
import { useFormContext } from 'react-hook-form';
import { PlayerFormSchema } from '../../usePlayerFormSchema.hook';
import styled from 'styled-components';
import Text from '@/components/ui/atoms/Text';
import Button from '@/components/ui/atoms/Button';
import Icon from '@/components/ui/atoms/Icon';
import colorIcon from '@public/icons/smartplayer-color.svg';
import Cluster from '@/components/ui/layout/Cluster';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from '@tanstack/react-query';
import { sendAmplitudeLogEvent } from '@/helpers';

interface PlayerFormColorProps {
    isDisabled: boolean;
}

const PlayerFormColor = ({ isDisabled }: PlayerFormColorProps) => {
    const { showId } = useParams<{ showId: string }>();
    const dominantColor = useFetchDominantColor(showId);
    const { control, setValue } = useFormContext<PlayerFormSchema>();
    const [colorGenerated, setColorGenerated] = useState(false);
    const queryClient = useQueryClient();

    useEffect(() => {
        if (!dominantColor.data) return;
        sendAmplitudeLogEvent('Smartplayer Colors Generated');
        setValue('color', dominantColor.data, { shouldDirty: true, shouldValidate: true });
        setColorGenerated(true);
        /*  Reset the query cache to allow the user to generate 
            the same color again after modifying manually the color */
        queryClient.resetQueries({ queryKey: ['shows.colors.show'] });
    }, [dominantColor.data]);

    const handleFetchColor = () => {
        setColorGenerated(false);
        dominantColor.refetch();
    };

    return (
        // @ts-ignore
        <Stack $gap="0.5rem">
            <ColorPickerController
                control={control}
                name="color"
                label={<FormattedMessage defaultMessage="Couleur d'accentuation" />}
                isDisabled={isDisabled}
                onChange={() => setColorGenerated(false)}
            />
            <GenerateColor>
                {/* @ts-ignore */}
                <Icon as={colorIcon} size="1rem" />
                {/* @ts-ignore */}
                <Stack $gap="0.75rem">
                    <Stack>
                        {/* @ts-ignore */}
                        <Text fontWeight="--fw-semibold">
                            <FormattedMessage defaultMessage="Trouvez la couleur idéale pour votre Smartplayer" />
                        </Text>
                        {/* @ts-ignore */}
                        <Text color="--neutral700">
                            <FormattedMessage defaultMessage="Grâce à votre pochette d’émission, l’IA générera la couleur prédominante de votre podcast." />
                        </Text>
                    </Stack>
                    {/* @ts-ignore */}
                    <Cluster $gap="0.75rem" $align="center">
                        {/* @ts-ignore */}
                        <GenerateColorButton
                            isLoading={dominantColor.isFetching}
                            isDisabled={isDisabled || colorGenerated}
                            onPress={handleFetchColor}
                        >
                            <FormattedMessage defaultMessage="Générer la couleur" />
                        </GenerateColorButton>
                        {colorGenerated && (
                            // @ts-ignore
                            <Cluster $gap="0.25rem" $align="center">
                                <CheckIcon icon={icon({ name: 'check-circle', style: 'solid' })} />
                                {/* @ts-ignore */}
                                <Text fontWeight="--fw-semibold" color="--primary">
                                    <FormattedMessage defaultMessage="Couleur générée !" />
                                </Text>
                            </Cluster>
                        )}
                    </Cluster>
                </Stack>
            </GenerateColor>
        </Stack>
    );
};

const GenerateColor = styled.div`
    display: flex;
    column-gap: 0.75rem;
    padding: 0.75rem 1rem;
    border-radius: var(--r-m);
    background: var(--gradient-aurora);
`;
const GenerateColorButton = styled(Button)`
    padding: 0.5rem 0.75rem;
    align-self: flex-start;
    background: var(--gradient-nebula);
`;
const CheckIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: var(--primary);
`;

export default PlayerFormColor;
