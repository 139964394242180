import { useInfiniteQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchPodcasts } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import episodeKeys from '@/queries/episode/episodeKeys';
import { z } from 'zod';

interface RawEpisode {
    tags: { data: string[] };
    files: { data: any[] };
}
export const EpisodeSchema = z.object({
    id: z.number(),
    guid: z.string(),
    youtubeVideo: z
        .object({
            data: z.object({
                id: z.number(),
                podcastId: z.number().optional(),
                state: z.enum(['pending', 'processing', 'done', 'error']).optional(),
                youtubeUrl: z.string().nullable().optional(),
                createdAt: z.string().optional(),
                updatedAt: z.string().optional(),
            }),
        })
        .optional(),
    waveformUrl: z.string().nullable(),
    updatedAt: z.string(),
    type: z.enum(['full', 'bonus', 'trailer']).optional(),
    tags: z
        .array(
            z.object({
                id: z.number(),
                slug: z.string(),
                name: z.string(),
            }),
        )
        .optional(),
    state: z.enum(['draft', 'blocked', 'scheduled', 'active']),
    smartlinkUrl: z.string(),
    slug: z.string().nullable(),
    siteUrl: z.string(),
    showId: z.number(),
    seasonId: z.number().nullable(),
    publishedAt: z.string().nullable(),
    publicId: z.string(),
    privacy: z.enum(['public', 'unlisted', 'private']),
    name: z.string(),
    isExplicit: z.boolean(),
    description: z.string().nullable(),
    htmlDescription: z.string().nullable(),
    hasCustomSlug: z.boolean(),
    files: z
        .array(
            z.object({
                duration: z.number().optional(),
                format: z.string().optional(),
                key: z.string().optional(),
                mimeType: z.string().optional(),
                originalName: z.string().optional(),
                size: z.number().optional(),
            }),
        )
        .optional(),
    duration: z.number().nullable(),
    downloadsCount: z.number(),
    createdAt: z.string(),
    canDownload: z.boolean(),
    audioUrl: z.string().nullable().optional(),
    audioUrlInternal: z.string().nullable().optional(),
    customUrl: z.string().nullable().optional(),
    imageUrl: z.string().nullable().optional(),
    soundcloudPublish: z.boolean().nullable().optional(),
    soundcloudUrl: z.string().nullable().optional(),
    useCustomUrl: z.boolean().nullable().optional(),
    videoUrls: z.array(z.string()).nullable().optional(),
});
const EpisodesSchema = z.array(EpisodeSchema);
const EpisodePaginationSchema = z.object({
    currentPage: z.number(),
    totalPages: z.number(),
    totalItems: z.number().optional(),
    itemsPerPage: z.number().optional(),
});
const EpisodeResponseSchema = z.object({
    episodes: EpisodesSchema,
    pagination: EpisodePaginationSchema,
});

type InputProps = {
    showId: string;
    query: string;
    perPage: number;
    status?: string[] | null;
};
type Key = QueryFunctionContext<ReturnType<typeof episodeKeys.listByShowIdInfinite>>;
export type Episode = z.infer<typeof EpisodeSchema>;

const queryFn = async ({ queryKey: [{ showId, query, perPage, status }], pageParam = 1 }: Key) => {
    if (!status) status = [];
    const { data, meta } = await fetchPodcasts(showId, query, null, status, pageParam, perPage);

    // TODO: Do camelcase transformation in Axios interceptor
    const { data: camelcaseData, meta: camelcaseMeta } = camelcaseKeys(
        { data, meta },
        { deep: true },
    );

    const formattedData = {
        episodes: camelcaseData.map((episode: RawEpisode) => ({
            ...episode,
            tags: episode.tags.data,
            files: episode.files.data,
        })),
        pagination: camelcaseMeta.pagination,
    };

    return EpisodeResponseSchema.parse(formattedData);
};

const useEpisodesInfiniteQuery = ({ showId, query, perPage, status }: InputProps) => {
    return useInfiniteQuery({
        queryKey: episodeKeys.listByShowIdInfinite(showId, query, perPage, status),
        queryFn,
        enabled: !!showId,
        getNextPageParam: (lastPage) => {
            if (lastPage.pagination.currentPage === lastPage.pagination.totalPages) {
                return undefined;
            }
            return Math.min(lastPage.pagination.currentPage + 1, lastPage.pagination.totalPages);
        },
    });
};

export default useEpisodesInfiniteQuery;
