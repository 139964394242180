import { FormattedMessage } from 'react-intl';
import { connect } from '@app/decorators/connect';
import Link from '@ui/atoms/Link';
import CTADragAndDropItem from './CTADragAndDropItem';
import DragAndDrop from './DragAndDrop';
import TooltipHover from '@ui/smarties/TooltipHover';
import Text from '@ui/atoms/Text';
import Cluster from '@ui/layout/Cluster';
import Stack from '@ui/layout/Stack';
import IconHelp from '@ui/icons/IconHelp';

const enhance = connect(({ routerStore, callToActionStore }, { CTALocation }) => ({
    linkCallToAction: routerStore.link('menu.communication.settings', { tab: 'call-to-action' }),
    getLocationButtons: callToActionStore.getLocationButtons[CTALocation],
    setCurrentDragAndDropData: (dragAndDropData) =>
        callToActionStore.setCurrentDragAndDropData(dragAndDropData),
}));

const CTADragAndDropContent = ({
    CTALocation,
    getLocationButtons,
    linkCallToAction,
    setCurrentDragAndDropData,
    tooltipMessage,
}) => {
    return (
        <Stack $gap="0.5rem">
            <Stack>
                <Cluster as={Text} $gap="0.25rem" fontWeight="--fw-semibold">
                    <FormattedMessage defaultMessage="Boutons personnalisés" />
                    {tooltipMessage && (
                        <TooltipHover arrow="left" target={<IconHelp color="--neutral200" />}>
                            <Text>{tooltipMessage}</Text>
                        </TooltipHover>
                    )}
                </Cluster>
                <Text color="--neutral500">
                    <FormattedMessage
                        defaultMessage="Ces boutons seront affichés sur l’en-tête de votre Smartlink et peuvent être gérés via l’onglet {link} du menu Configuration"
                        values={{
                            link: (
                                <Text
                                    as={Link}
                                    to={linkCallToAction}
                                    fontWeight="--fw-semibold"
                                    color="--primary"
                                >
                                    Call-to-Action
                                </Text>
                            ),
                        }}
                    />
                </Text>
            </Stack>
            {getLocationButtons.length > 0 && (
                <DragAndDrop
                    id={CTALocation}
                    list={getLocationButtons}
                    getData={setCurrentDragAndDropData}
                >
                    {({ item, isDragging }) => (
                        <CTADragAndDropItem item={item} isDragging={isDragging} />
                    )}
                </DragAndDrop>
            )}
        </Stack>
    );
};

export default enhance(CTADragAndDropContent);
