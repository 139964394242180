import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { fetchRssSettings } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import rssSettingsKeys from './rssSettingsKeys';
import { z } from 'zod';

const RssSettingsSchema = z.object({
    appleClaimToken: z.string().nullable(),
    copyright: z.string().nullable(),
    isExplicit: z.boolean().nullable(),
    isLocked: z.boolean(),
    ownerEmail: z.string().email().nullable(),
    ownerName: z.string().nullable(),
    podtrac: z.boolean(),
    showId: z.number(),
    subtitle: z.string().nullable(),
    website: z.string().url().nullable(),
});

export type RssSettings = z.infer<typeof RssSettingsSchema>;

type Key = QueryFunctionContext<ReturnType<typeof rssSettingsKeys.allDetails>>;

const queryFn = async ({ queryKey: [{ showId }] }: Key) => {
    const { data } = await fetchRssSettings(showId);

    // TODO: Do camelcase transformation in Axios interceptor
    return RssSettingsSchema.parse(camelcaseKeys(data, { deep: true }));
};

const useRssSettingsQuery = (showId: string | number) => {
    return useQuery({
        queryKey: rssSettingsKeys.allDetails(showId),
        queryFn,
        enabled: !!showId,
    });
};

export default useRssSettingsQuery;
