import { FormattedMessage } from 'react-intl';
import RouterLink from '@/components/Link/RouterLink';
import styled from 'styled-components';

const TopbarPastDueWarningBand = () => {
    return (
        <TopbarPastDueWarningBandWrapper>
            <FormattedMessage defaultMessage="Vous avez actuellement une facture impayée." />
            <BoldRouterLink to="/app/user/subscription">
                <FormattedMessage defaultMessage="Consulter mes factures" />
            </BoldRouterLink>
        </TopbarPastDueWarningBandWrapper>
    );
};

const TopbarPastDueWarningBandWrapper = styled.div`
    height: 2rem;
    background: var(--info);
    display: flex;
    align-items: center;
    gap: 0.25rem;
    justify-content: center;
    color: var(--white);
`;
const BoldRouterLink = styled(RouterLink)`
    font-weight: semibold;
    color: var(--white);
    padding-top: 0.15rem;

    &:hover {
        text-decoration: underline;
        text-underline-offset: 0.25rem;
    }
`;

export default TopbarPastDueWarningBand;
