const socialNetworkPostKeys = {
    all: () => [{ entity: 'socialNetworkPost' }],
    allLists: () => [{ ...socialNetworkPostKeys.all()[0], scope: 'list' }],
    list: ({ showId, currentPage, perPage } = {}) => [
        { ...socialNetworkPostKeys.allLists()[0], showId, currentPage, perPage },
    ],
    listCalendar: ({ showId, startDate, endDate } = {}) => [
        { ...socialNetworkPostKeys.allLists()[0], showId, startDate, endDate },
    ],
    listDraft: ({ showId } = {}) => [
        { ...socialNetworkPostKeys.allLists()[0], showId, state: 'draft' },
    ],
    listScheduled: ({ showId } = {}) => [
        { ...socialNetworkPostKeys.allLists()[0], showId, state: 'scheduled' },
    ],
    allDetails: () => [{ ...socialNetworkPostKeys.all()[0], scope: 'detail' }],
    detailById: (messageId) => [{ ...socialNetworkPostKeys.allDetails()[0], messageId }],
    insights: ({ postIds } = {}) => [
        { ...socialNetworkPostKeys.all()[0], postIds, scope: 'insights' },
    ],
    postTemplate: ({ showId, platformId } = {}) => [
        { ...socialNetworkPostKeys.all()[0], showId, platformId, scope: 'template' },
    ],
};

export default socialNetworkPostKeys;
