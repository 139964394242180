import { useState } from 'react';
import EpisodeEditTranscriptionQuota from './EpisodeEditTranscriptionQuota';
import Button from '@ui/atoms/Button';
import { FormattedMessage } from 'react-intl';
import Text from '@ui/atoms/Text';
import Stack from '@ui/layout/Stack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';
import { useEpisodeBeingEdited } from '@/context/EpisodeFormContext';
import { useEpisodeEditAudioFileMutation } from '@/context/EpisodeEditContext';
import useGenerateEpisodeTranscriptMutation from '@queries/episodeTranscript/useGenerateEpisodeTranscriptMutation.hook';
import { useModalToastQueue } from '@/shared/hooks/useModalToastQueue.hook';
import {
    useEpisodeTranscription,
    useEpisodeTranscriptionStatus,
} from './EpisodeEditTranscriptionProvider';
import useSubscriptionQuery from '@queries/subscription/useSubscriptionQuery.hook';
import TranscriptionEditQuotaModal from '../TranscriptionEditQuotaModal/TranscriptionEditQuotaModal';
import useUpdateTranscriptQuotaMutation from '@queries/quota/useUpdateTranscriptQuotaMutation.hook';
import TranscriptionEditQuotaConfirmationModal from '../TranscriptionEditQuotaConfirmationModal';
import EpisodeEditTranscriptionGenerateModal from '../EpisodeEditTranscriptionGenerateModal';
import Alert from '@ui/atoms/Alert';
import {
    ENABLE_GENERATE_TRANSCRIPTION,
    EPISODE_TRANSCRIPT_STATUS,
    USER_ROLES,
} from '@/shared/config/constants';
import TranscriptionEditQuotaProModal from '../TranscriptionEditQuotaModal/TranscriptionEditQuotaProModal';
import { PRICING } from '@/shared/config/pricing';
import { useParams } from 'react-router-dom';
import useTranscriptShowQuotaQuery from '@queries/quota/useTranscriptShowQuotaQuery.hook';
import Tooltip, { TooltipTriggerWrapper } from '@ui/Tooltip';
import { TooltipTrigger } from 'react-aria-components';
import { sendAmplitudeLogEvent } from '@/shared/utils/amplitude';
import RouterLink from '@/components/Link/RouterLink';

const EpisodeEditTranscriptionGenerate = () => {
    const { showId } = useParams();
    const quota = useTranscriptShowQuotaQuery(showId);
    const { data: subscription } = useSubscriptionQuery();
    const episodeTranscription = useEpisodeTranscription();
    const episodeBeingEdited = useEpisodeBeingEdited();
    const episodeEditAudioFileMutation = useEpisodeEditAudioFileMutation();
    const generateTranscript = useGenerateEpisodeTranscriptMutation();
    const hasAudioFile =
        episodeBeingEdited.files.length > 0 && !episodeEditAudioFileMutation.isLoading;
    const [isGenerateModalOpen, setIsGenerateModalOpen] = useState(false);
    const [isQuotaModalOpen, setIsQuotaModalOpen] = useState(false);
    const [isQuotaConfirmationModalOpen, setIsQuotaConfirmationModalOpen] = useState(false);
    const toast = useModalToastQueue();
    const duration = Math.round(episodeBeingEdited.duration / 60); // Convert seconds to minutes
    const updateQuota = useUpdateTranscriptQuotaMutation();
    const [quotaQuantity, setQuotaQuantity] = useState(null);
    const transcriptionStatus = useEpisodeTranscriptionStatus();
    const isError = transcriptionStatus?.status === EPISODE_TRANSCRIPT_STATUS.ERROR;
    const insufficientQuota = Math.floor(quota.data?.maximum - quota.data?.total) < duration;
    const isOwner = episodeBeingEdited?.show?.userRole === USER_ROLES.OWNER;

    const handleGenerateTranscript = () => {
        sendAmplitudeLogEvent('Transcription Requested');
        generateTranscript.mutate(episodeBeingEdited.id, {
            onSuccess: () => {
                setIsGenerateModalOpen(false);
                /* Success message enabled */
                localStorage.setItem(`episode-form-${episodeBeingEdited.id}-transcription`, 'true');
                // Because AI summary is regenerated after the transcript is updated
                // chapters can be outdated and new suggestions could be fetched
                localStorage.removeItem(`episode-ai-${episodeBeingEdited.id}-generated-chapters`);
            },
            onError: (error) => {
                switch (error.response.status) {
                    case 400:
                        // TODO: Update message
                        toast.alert(
                            <FormattedMessage defaultMessage="Le fichier audio est invalide." />,
                        );
                        break;
                    case 402:
                        toast.alert(
                            <FormattedMessage defaultMessage="Oups... Il semble que vous ayez atteint votre limite de transcription. Augmentez votre limite et réessayez. 🚀" />,
                        );
                        break;
                    default:
                        toast.alert(
                            <FormattedMessage defaultMessage="Oups... Une erreur est survenue durant la génération de votre transcription" />,
                        );
                        break;
                }
            },
        });
    };

    const handleUpdateQuota = (quantity) => {
        if (insufficientQuota || !isOwner) return;
        updateQuota.mutate(quantity, {
            onSuccess: () => {
                setQuotaQuantity(null);
                setIsQuotaConfirmationModalOpen(false);
            },
        });
    };

    const handleConfirmQuota = (quantity) => {
        setQuotaQuantity(quantity);
        setIsQuotaModalOpen(false);
        setIsQuotaConfirmationModalOpen(true);
    };

    return (
        <>
            <Stack $gap="1.5rem">
                <EpisodeEditTranscriptionQuota />
                {isError && (
                    <Alert
                        variant="alert"
                        defaultIcon
                        description={
                            <Text color="--neutral700">
                                <FormattedMessage defaultMessage="Pas de panique, votre temps de transcription n’a pas été décompté, merci de réessayer ultérieurement. 🙏 " />
                            </Text>
                        }
                    >
                        <Text fontWeight="--fw-semibold">
                            <FormattedMessage defaultMessage="Oups... Une erreur est survenue durant la génération de votre transcription" />
                        </Text>
                    </Alert>
                )}
                <Stack $gap="1rem" $align="center">
                    {ENABLE_GENERATE_TRANSCRIPTION ? (
                        <TooltipTrigger
                            delay={0}
                            closeDelay={0}
                            isDisabled={hasAudioFile && !insufficientQuota}
                        >
                            <FullWidthTooltipTriggerWrapper>
                                <GenerateButton
                                    isDisabled={!hasAudioFile || insufficientQuota}
                                    isLoading={
                                        episodeEditAudioFileMutation.isLoading ||
                                        episodeTranscription.isLoading ||
                                        generateTranscript.isLoading ||
                                        updateQuota.isLoading
                                    }
                                    startIcon={
                                        isError ? (
                                            <FontAwesomeIcon
                                                icon={icon({
                                                    name: 'arrows-rotate',
                                                    style: 'solid',
                                                })}
                                            />
                                        ) : (
                                            <FontAwesomeIcon
                                                icon={icon({ name: 'bolt', style: 'solid' })}
                                            />
                                        )
                                    }
                                    onPress={() => setIsGenerateModalOpen(true)}
                                >
                                    {isError ? (
                                        <FormattedMessage defaultMessage="Réessayer" />
                                    ) : (
                                        <FormattedMessage defaultMessage="Générer la transcription" />
                                    )}
                                </GenerateButton>
                            </FullWidthTooltipTriggerWrapper>
                            <Tooltip placement="top">
                                <Text fontWeight="--fw-semibold">
                                    {insufficientQuota ? (
                                        <FormattedMessage defaultMessage="Vous ne disposez pas d'assez de temps de transcription pour pouvoir en générer une nouvelle." />
                                    ) : (
                                        <FormattedMessage defaultMessage="Un fichier audio est nécessaire pour pouvoir générer une transcription." />
                                    )}
                                </Text>
                            </Tooltip>
                        </TooltipTrigger>
                    ) : (
                        <Alert
                            variant="warning"
                            icon={
                                <ScrewdriverIcon
                                    icon={icon({ name: 'screwdriver-wrench', style: 'solid' })}
                                />
                            }
                            description={
                                <Text color="--neutral700">
                                    <FormattedMessage defaultMessage="Nos équipes font le nécessaire pour réparer cela au plus vite. Merci de réessayer plus tard. 🙏" />
                                </Text>
                            }
                        >
                            <Text fontWeight="--fw-semibold">
                                <FormattedMessage defaultMessage="Oups, la génération de la transcription est actuellement indisponible." />
                            </Text>
                        </Alert>
                    )}

                    <Text variant="bodyM" color="--neutral500">
                        {subscription?.isFreemium && isOwner ? (
                            <FormattedMessage
                                defaultMessage="Besoin de plus ? <link>S'abonner 🚀</link>"
                                values={{
                                    link: (chunks) => (
                                        <RouterLink
                                            to="/app/user/subscription/offers"
                                            target="_blank"
                                        >
                                            {chunks}
                                        </RouterLink>
                                    ),
                                }}
                            />
                        ) : (
                            <FormattedMessage
                                defaultMessage="Besoin de plus ? <button>Augmenter la limite</button>"
                                values={{
                                    button: (chunks) => (
                                        <TooltipTrigger
                                            delay={0}
                                            closeDelay={0}
                                            isDisabled={isOwner}
                                        >
                                            <TooltipTriggerWrapper>
                                                <IncreaseQuotaButton
                                                    isDisabled={!isOwner}
                                                    onPress={() => setIsQuotaModalOpen(true)}
                                                    variant="ghost"
                                                >
                                                    {chunks}
                                                </IncreaseQuotaButton>
                                            </TooltipTriggerWrapper>
                                            <Tooltip placement="top">
                                                <Text fontWeight="--fw-semibold">
                                                    <FormattedMessage defaultMessage="Seul le propriétaire de l'émission peut augmenter cette limite. 🙏" />
                                                </Text>
                                            </Tooltip>
                                        </TooltipTrigger>
                                    ),
                                }}
                            />
                        )}
                    </Text>
                </Stack>
            </Stack>
            <EpisodeEditTranscriptionGenerateModal
                isOpen={isGenerateModalOpen}
                onOpenChange={setIsGenerateModalOpen}
                handleGenerateTranscript={handleGenerateTranscript}
                isloading={generateTranscript.isLoading}
                duration={duration}
            />
            <TranscriptionEditQuotaConfirmationModal
                isOpen={isQuotaConfirmationModalOpen}
                onOpenChange={setIsQuotaConfirmationModalOpen}
                isLoading={updateQuota.isLoading}
                onSubmit={handleUpdateQuota}
                quantity={quotaQuantity}
            />
            {subscription?.pricing === PRICING.ENTERPRISE ? (
                <TranscriptionEditQuotaProModal
                    isOpen={isQuotaModalOpen}
                    onOpenChange={setIsQuotaModalOpen}
                />
            ) : (
                <TranscriptionEditQuotaModal
                    isOpen={isQuotaModalOpen}
                    onOpenChange={setIsQuotaModalOpen}
                    onConfirm={handleConfirmQuota}
                />
            )}
        </>
    );
};

const GenerateButton = styled(Button)`
    width: 100%;
`;
const FullWidthTooltipTriggerWrapper = styled(TooltipTriggerWrapper)`
    width: 100%;
`;
const IncreaseQuotaButton = styled(Button)`
    color: var(--primary);
    font-weight: var(--fw-semibold);
    padding: 0;
`;
const ScrewdriverIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--warning500);
`;

export default EpisodeEditTranscriptionGenerate;
