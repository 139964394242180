import { FormattedMessage } from 'react-intl';
import Center from '../../../../ui/layout/Center';
import { DeprecatedText } from '../../../../ui/atoms/DeprecatedText';

const TranscriptionError = () => (
    <Center>
        <DeprecatedText align="center">
            <FormattedMessage defaultMessage="Une erreur s'est produite lors de la transcription de votre clip." />
        </DeprecatedText>
    </Center>
);

export default TranscriptionError;
