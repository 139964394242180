import { FormattedMessage, useIntl } from 'react-intl';
import dayjs from 'dayjs';
import objectSupport from 'dayjs/plugin/objectSupport';
import { connect } from '../decorators/connect';
import { useResponsive } from '../../../utils/hooks/useResponsive';
import ShortListFallback from '../fallbacks/ShortListFallback';
import DeprecatedIcon from '../../ui/atoms/DeprecatedIcon';
import rocket from '@public/images/rocket_launch_time.svg';
import DeprecatedPaper from '../../ui/atoms/DeprecatedPaper';
import DeprecatedText from '../../ui/atoms/DeprecatedText';
import DeprecatedWhitePaper from '../../ui/atoms/DeprecatedWhitePaper';
import FetcherPeakTimeBest from '../fetchers/stats/FetcherPeakTimeBest';
import styled, { css } from 'styled-components';

dayjs.extend(objectSupport);

const enhance = connect(({ peakTimeStatsStore, userStore }) => ({
    bestWeekDay: peakTimeStatsStore.bestDayLaunch.bestWeekDay,
    bestDayHour: peakTimeStatsStore.bestDayLaunch.bestDayHour,
    daysRangePeakTime: peakTimeStatsStore.daysRangePeakTime,
    userLocale: userStore.locale,
}));

const PeakTimeBest = ({
    bestWeekDay,
    bestDayHour,
    daysRangePeakTime,
    messageLaunchTime,
    match,
    isNoData,
    userLocale,
}) => {
    const { isMobileOrTablet, isDesktop } = useResponsive();
    const intl = useIntl();

    if (isNoData) {
        return (
            <PeakTimeNoDataWrapper>
                <DeprecatedText weight="bold" size="big">
                    <FormattedMessage defaultMessage="Aucune donnée disponible" />
                </DeprecatedText>
            </PeakTimeNoDataWrapper>
        );
    }

    return (
        <FetcherPeakTimeBest showId={match.params.showId} days={daysRangePeakTime}>
            {(isLoading) =>
                isLoading ? (
                    <DeprecatedWhitePaper p={45} mt={20} mb={35} flex>
                        <ShortListFallback />
                    </DeprecatedWhitePaper>
                ) : (
                    <PeakTimeBestWrapper>
                        <DeprecatedIcon
                            as={rocket}
                            size={90}
                            my={isMobileOrTablet ? 35 : undefined}
                        />
                        <DeprecatedPaper
                            flex
                            column
                            ml={isDesktop && 45}
                            justify="center"
                            pos="relative"
                        >
                            <DeprecatedText size="veryBig" mb={isMobileOrTablet && 10}>
                                {userLocale === 'fr' && <FormattedMessage defaultMessage="Le" />}{' '}
                                <DeprecatedText size="veryBig" weight="bold" as="span">
                                    <FormattedMessage
                                        defaultMessage="{bestWeekDay} à {bestDayHour}"
                                        values={{
                                            bestWeekDay,
                                            bestDayHour: intl.formatTime(
                                                dayjs({ hour: bestDayHour }),
                                                {
                                                    hour12: userLocale === 'en',
                                                    hour: 'numeric',
                                                },
                                            ),
                                        }}
                                    />
                                </DeprecatedText>{' '}
                                <DeprecatedText size="veryBig" as="span">
                                    <FormattedMessage defaultMessage="est votre moment. 😎" />
                                </DeprecatedText>
                            </DeprecatedText>
                            <DeprecatedText size={isMobileOrTablet ? 'small' : 'normal'}>
                                <FormattedMessage
                                    defaultMessage="{isAlways} {messageLaunchTime},  le {bestWeekDay} à {bestDayHour} est le moment où vous êtes le plus écouté dans la semaine."
                                    values={{
                                        bestWeekDay,
                                        bestDayHour: intl.formatTime(dayjs({ hour: bestDayHour }), {
                                            hour12: userLocale === 'en',
                                            hour: 'numeric',
                                        }),
                                        messageLaunchTime,
                                        isAlways:
                                            daysRangePeakTime === 'always' ? (
                                                <FormattedMessage defaultMessage="Selon votre audience" />
                                            ) : (
                                                <FormattedMessage defaultMessage="Selon votre audience des" />
                                            ),
                                    }}
                                />
                            </DeprecatedText>
                        </DeprecatedPaper>
                    </PeakTimeBestWrapper>
                )
            }
        </FetcherPeakTimeBest>
    );
};

const PeakTimeWrapperBase = css`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding);
    gap: 1rem ${({ theme }) => theme.mediaQueries.mobile} {
        padding: var(--container-padding-mobile);
    }
`;

const PeakTimeNoDataWrapper = styled.div`
    ${PeakTimeWrapperBase};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const PeakTimeBestWrapper = styled.div`
    ${PeakTimeWrapperBase};
    margin-block: 2rem;
    display: flex;
    ${({ theme }) => theme.mediaQueries.mobile} {
        flex-direction: column;
        align-items: center;
    }
`;

export default enhance(PeakTimeBest);
