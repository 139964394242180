import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import noPlaylistMainIllustration from '@public/images/empty-state/illustration/playlists.png';
import noPlaylistHeadIllustration from '@public/images/empty-state/playlists.svg?url';
import EmptyState from '@ui/molecules/EmptyState';
import IconAdd from '@ui/icons/IconAdd';
import Text from '@ui/atoms/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { extractCssVariable } from '@/shared/utils/cssVariable';
import { USER_ROLES } from '@/shared/config/constants';
import styled from 'styled-components';
import Alert from '@ui/atoms/Alert/Alert';
import Button from '@/components/Button';
import { useParams } from 'react-router';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import AddPlaylistModal from '../Playlist/AddPlaylistModal';

// TODO: Alert or tooltip for guest viewers ?
function PlaylistsEmptyState() {
    const { showId } = useParams<{ showId: string }>();
    const show = useShowQuery(showId);
    const isShowArchived = show.data?.archived ?? false;
    const isGuestViewer = show.data?.userRole === USER_ROLES.VIEWER;
    const isCTADisabled = !show.data || isShowArchived || isGuestViewer;
    const [isAddPlaylistModalOpen, setIsAddPlaylistModalOpen] = useState(false);

    return (
        <>
            <EmptyState
                illustration={noPlaylistMainIllustration}
                illustrationType="image"
                illustrationAlign="right"
                illustrationBackground="--gradient-dawn"
            >
                <HeadIllustrationWrapper>
                    <HeadIllustration src={noPlaylistHeadIllustration} alt="PLaylists" />
                </HeadIllustrationWrapper>
                <LabelWrapper>
                    <Text variant="heading" fontWeight="--fw-bold">
                        <FormattedMessage defaultMessage="Créez votre première playlist" />
                    </Text>
                    <Text variant="body" fontWeight="--fw-normal" color="--neutral500">
                        <FormattedMessage defaultMessage="Organisez vos épisodes en différentes listes de lecture ▶️" />
                    </Text>
                </LabelWrapper>
                {isShowArchived && (
                    <Alert
                        variant="alert"
                        icon={
                            <FontAwesomeIcon
                                icon={icon({ name: 'circle-info', style: 'solid' })}
                                color={extractCssVariable('--alert500')}
                                size="lg"
                            />
                        }
                    >
                        <FormattedMessage defaultMessage="L'ajout d'une nouvelle playlist n'est pas possible pour une émission archivée." />
                    </Alert>
                )}
                <Button
                    onPress={() => setIsAddPlaylistModalOpen(true)}
                    startIcon={<IconAdd />}
                    isDisabled={isCTADisabled}
                >
                    <FormattedMessage defaultMessage="Créer une playlist" />
                </Button>
            </EmptyState>
            <AddPlaylistModal
                isOpen={isAddPlaylistModalOpen}
                onOpenChange={setIsAddPlaylistModalOpen}
            />
        </>
    );
}

const HeadIllustrationWrapper = styled.div`
    width: 200px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const HeadIllustration = styled.img`
    min-width: 248px;
`;
const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    & > * {
        text-align: center;
    }
`;

export default PlaylistsEmptyState;
