import { runInAction } from 'mobx';
import PageSettingsModel from '../models/PageSettingsModel';
import { fetchPageSettings } from '@/api';
import Store from './Store';
import { apiFetch } from '../components/app/decorators/api';

class PageSettingsStore extends Store {
    static observables = {
        settings: undefined,
        files: [],
    };

    @apiFetch
    async fetchSettings(showId) {
        try {
            const { data } = await fetchPageSettings(showId);
            runInAction(() => {
                this.settings = new PageSettingsModel(this, data);
            });
        } catch (error) {
            runInAction(() => {
                this.settings = new PageSettingsModel(this);
            });
        }
    }
}

export default PageSettingsStore;
