import { FormattedMessage } from 'react-intl';
import remove from '@public/icons/trash.svg';
import DeprecatedIcon from '@ui/atoms/DeprecatedIcon';
import DeprecatedButton from '@ui/atoms/DeprecatedButton';
import DeleteSeasonModal from './DeleteSeasonModal';
import Column from '@ui/atoms/Column';
import PaperGrid from '@ui/atoms/PaperGrid';
import { useOverlayTriggerState } from 'react-stately';

function SeasonLine({ season, canDelete, onDelete, ...props }) {
    let deleteSeasonModalState = useOverlayTriggerState({});
    return (
        <PaperGrid border="--neutral100" bt={1} column={4} radBottom={5} p={16} {...props}>
            <Column startColumn={1} endColumn={3}>
                <FormattedMessage
                    defaultMessage="Saison {number}"
                    values={{
                        number: season.seasonNumber,
                    }}
                />
            </Column>
            <Column startColumn={3} endColumn={4}>
                {season.podcastsCount}
            </Column>
            <Column startColumn={4} endColumn={5} thick align="right" justify="flex-end">
                <DeprecatedButton
                    icon
                    accent="rounded"
                    disabled={!canDelete}
                    onClick={deleteSeasonModalState.open}
                >
                    <DeprecatedIcon as={remove} />
                </DeprecatedButton>
            </Column>
            {deleteSeasonModalState.isOpen && (
                <DeleteSeasonModal
                    isOpened={deleteSeasonModalState.isOpen}
                    onClose={deleteSeasonModalState.close}
                    season={season}
                    onDelete={() => onDelete(season.id)}
                />
            )}
        </PaperGrid>
    );
}

export default SeasonLine;
