import { useMutation, useQueryClient } from '@tanstack/react-query';
import useMoveEpisodeMutation from './useMoveEpisodeMutation.hook';
import episodeKeys from '@/queries/episode/episodeKeys';
import showKeys from '../show/showKeys';

export interface MutationFnProps {
    episodeIds: string[];
    newShowId: string;
}

const useMoveMultipleEpisodesMutation = () => {
    const queryClient = useQueryClient();
    const moveEpisode = useMoveEpisodeMutation();

    return useMutation({
        mutationFn: ({ episodeIds, newShowId }: MutationFnProps) => {
            const promises: Promise<any>[] = [];

            episodeIds.forEach((episodeId) => {
                promises.push(moveEpisode.mutateAsync({ episodeId, newShowId }));
            });

            return Promise.allSettled(promises);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: episodeKeys.allLists(),
            });

            queryClient.invalidateQueries({
                queryKey: showKeys.all(),
            });
        },
    });
};

export default useMoveMultipleEpisodesMutation;
