import AccordionButton from '@/components/ui/atoms/AccordionButton';
import { useAccordionItem } from '@/components/ui/atoms/AccordionItem/useAccordionItem.hook';
import AccordionPanel from '@/components/ui/atoms/AccordionPanel';
import Text from '@/components/ui/atoms/Text';
import Cluster from '@/components/ui/layout/Cluster';
import { icon as iconFA } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components';

interface SummaryAccordionItemProps {
    children: React.ReactNode;
    label: React.ReactNode | string;
    icon: React.ReactNode;
}

const SummaryAccordionItem = ({ children, label, icon }: SummaryAccordionItemProps) => {
    const { isExpanded } = useAccordionItem();

    return (
        <AccordionItemInner>
            <AccordionButtonStyled>
                <AccordionHeader>
                    <Cluster $gap="0.75rem" $align="center">
                        {icon}
                        <Text variant="headingS" fontWeight="--fw-bold">
                            {label}
                        </Text>
                    </Cluster>
                    <Cluster $gap="1rem" $align="center">
                        <ChevronIcon
                            $isExpanded={isExpanded ?? false}
                            icon={iconFA({ name: 'chevron-down', style: 'solid' })}
                        />
                    </Cluster>
                </AccordionHeader>
            </AccordionButtonStyled>
            <AccordionPanel>
                <AccordionPanelContent>{children}</AccordionPanelContent>
            </AccordionPanel>
        </AccordionItemInner>
    );
};

const AccordionHeader = styled.div`
    width: 100%;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
`;
const AccordionPanelContent = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    height: 100%;
    width: 100%;
    padding-bottom: 2rem;
`;
const ChevronIcon = styled(FontAwesomeIcon)<{ $isExpanded: boolean }>`
    width: 1rem;
    height: 1rem;
    transition: transform 0.3s ease;
    color: var(--neutral500);

    ${({ $isExpanded }) =>
        $isExpanded &&
        css`
            transform: rotate(180deg);
        `}
`;
const AccordionItemInner = styled.div`
    border-bottom: 1px solid var(--neutral100);
`;
const AccordionButtonStyled = styled(AccordionButton)`
    padding-block: 2rem;
`;

export default SummaryAccordionItem;
