import { useMutation } from '@tanstack/react-query';
import { fetchProviderRedirectUrl } from '@/api';
import camelcaseKeys from 'camelcase-keys';

const transform = (data) => {
    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const mutationFn = async ({ platformId }) => {
    const { data } = await fetchProviderRedirectUrl(platformId);
    return transform(data);
};

const useFetchSocialRedirectUrlMutation = () => {
    return useMutation({
        mutationFn,
    });
};

export default useFetchSocialRedirectUrlMutation;
