import { FormattedMessage, useIntl } from 'react-intl';
import { useFormContext } from 'react-hook-form';
import { InputTextController } from '@ui/atoms/InputText';
import EpisodeEditTooltip from '@app/molecules/EpisodeEditTooltip';
import styled from 'styled-components';
import EpisodeAITitleSuggestionsPanel from '@/components/EpisodeAI/EpisodeAITitleSuggestionsPanel';
import Stack from '@/components/ui/layout/Stack';

const HelpLink = styled.a`
    color: var(--white);
    text-decoration: underline;
`;

const EpisodeEditFormContentNameField = () => {
    const intl = useIntl();
    const { control } = useFormContext();

    return (
        <Stack>
            <InputTextController
                name="content.name"
                control={control}
                label={<FormattedMessage defaultMessage="Titre" />}
                placeholder={intl.formatMessage({
                    defaultMessage: 'Le super titre de votre épisode',
                })}
                isRequired
                tooltip={
                    <EpisodeEditTooltip>
                        <FormattedMessage
                            defaultMessage="Choisissez un nom impactant qui révèle en quelques mots pourquoi il faut écouter votre épisode. <link>En savoir plus</link>"
                            values={{
                                link: (chunks: React.ReactNode) => (
                                    <HelpLink
                                        href={intl.formatMessage({
                                            defaultMessage:
                                                'https://www.ausha.co/fr/blog/nouveau-sublimez-les-descriptions-de-vos-podcasts/',
                                        })}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {chunks}
                                    </HelpLink>
                                ),
                            }}
                        />
                    </EpisodeEditTooltip>
                }
                data-testid="new-episode-name-input"
            />
            <EpisodeAITitleSuggestionsPanel />
        </Stack>
    );
};

export default EpisodeEditFormContentNameField;
