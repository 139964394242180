import { useMemo } from 'react';
import ApexChart from 'react-apexcharts';
import { defineMessages, useIntl } from 'react-intl';
import { useResponsive } from '@/shared/hooks/useResponsive';
import dayjs from 'dayjs';
import { PeakTimeHeatmap } from '@/queries/stats/usePeakTimeHeatmapQuery.hook';

const dayWeekdFormatddd = defineMessages({
    0: { defaultMessage: 'lun.' },
    1: { defaultMessage: 'mar.' },
    2: { defaultMessage: 'mer.' },
    3: { defaultMessage: 'jeu.' },
    4: { defaultMessage: 'ven.' },
    5: { defaultMessage: 'sam.' },
    6: { defaultMessage: 'dim.' },
});

const dayWeekdFormatdddd = defineMessages({
    0: { defaultMessage: 'Lundi' },
    1: { defaultMessage: 'Mardi' },
    2: { defaultMessage: 'Mercredi' },
    3: { defaultMessage: 'Jeudi' },
    4: { defaultMessage: 'Vendredi' },
    5: { defaultMessage: 'Samedi' },
    6: { defaultMessage: 'Dimanche' },
});

const dayWeekdInital = defineMessages({
    0: { defaultMessage: 'L' },
    1: { defaultMessage: 'M' },
    2: { defaultMessage: 'M' },
    3: { defaultMessage: 'J' },
    4: { defaultMessage: 'V' },
    5: { defaultMessage: 'S' },
    6: { defaultMessage: 'D' },
});

export const PEAKTIME_HEATMAP_COLORS_RANGE = [
    '#F9F7FA',
    '#F0EDF2',
    '#F1E8F9',
    '#C8A4E5',
    '#751CBF',
];

interface SeriesData {
    x: string;
    y: number;
    tooltipMessage: string;
}

interface SeriesDataDay {
    name: string;
    datedddd: string;
    data: SeriesData[];
}

interface HeatmapChartProps {
    categories: PeakTimeHeatmap['categories'];
    series: PeakTimeHeatmap['mapData'];
    maxDownloads: number;
}

const HeatmapChart = ({ categories, series, maxDownloads }: HeatmapChartProps) => {
    const intl = useIntl();
    const { isMobileOrTablet } = useResponsive();

    const data = useMemo(() => {
        const nHours = 23;
        let dataDay: SeriesDataDay[] = [];
        let formattedSeries: SeriesData[] = [];
        let i = 0;
        let j = 0;
        while (i < series.length) {
            formattedSeries = [
                ...formattedSeries,
                {
                    x: dayjs(series[i].dayHour).format('H'),
                    y: series[i].downloads,
                    tooltipMessage: intl.formatMessage(
                        {
                            defaultMessage:
                                '{count, plural, zero {# écoute} one {# écoute} other {# écoutes}}',
                        },
                        { count: series[i].downloads },
                    ),
                },
            ];
            if (dayjs(series[i].dayHour).format('H') === nHours.toString()) {
                dataDay = [
                    ...dataDay,
                    {
                        name: isMobileOrTablet
                            ? intl.formatMessage(dayWeekdInital[j as keyof typeof dayWeekdInital])
                            : intl.formatMessage(
                                  dayWeekdFormatddd[j as keyof typeof dayWeekdInital],
                              ),
                        datedddd: intl.formatMessage(
                            dayWeekdFormatdddd[j as keyof typeof dayWeekdInital],
                        ),
                        data: [...formattedSeries],
                    },
                ];
                j += 1;
                formattedSeries = [];
            }
            i += 1;
        }
        return dataDay.reverse();
    }, [series, isMobileOrTablet, intl]);

    const colorRange = useMemo(
        () =>
            categories.map((category, i) => ({
                ...category,
                color: PEAKTIME_HEATMAP_COLORS_RANGE[i],
            })),
        [categories],
    );

    const options = useMemo(
        () => ({
            chart: {
                toolbar: {
                    show: false,
                },
                offsetX: isMobileOrTablet ? -20 : 0,
            },
            xaxis: {
                tooltip: {
                    enabled: false,
                },
                labels: {
                    rotate: -45,
                    rotateAlways: isMobileOrTablet,
                    trim: false,
                    style: {
                        fontSize: isMobileOrTablet ? 8 : 12,
                    },
                    formatter: (value: string) => {
                        const dateFrom = dayjs().hour(Number(value));
                        return intl.formatDate(dateFrom.toDate(), {
                            hour: 'numeric',
                        });
                    },
                },
                axisTicks: {
                    show: false,
                },
                axisBorder: {
                    show: false,
                },
                tickPlacement: 'on',
                offsetY: isMobileOrTablet ? -10 : 0,
            },
            yaxis: {
                labels: {
                    style: {
                        fontSize: isMobileOrTablet ? 14 : 12,
                    },
                },
            },
            grid: {
                show: true,
                xaxis: {
                    lines: {
                        show: false,
                    },
                },
                yaxis: {
                    lines: {
                        show: false,
                    },
                },
            },

            legend: {
                show: false,
            },
            dataLabels: {
                enabled: false,
            },
            plotOptions: {
                heatmap: {
                    enableShades: false,
                    radius: 5,
                    colorScale: {
                        ranges: colorRange,
                        min: 0,
                        max: maxDownloads,
                    },
                },
            },
            tooltip: {
                followCursor: true,
                custom: ({
                    seriesIndex,
                    dataPointIndex,
                }: {
                    seriesIndex: number;
                    dataPointIndex: number;
                }) => {
                    const hourOfDay = dayjs().hour(
                        Number(data[seriesIndex].data[dataPointIndex].x),
                    );
                    const formattedHourOfDay = intl.formatDate(hourOfDay.toDate(), {
                        hour: 'numeric',
                    });
                    return `<div class="tooltip-ausha-apexChart">
                        <p class="tooltip-ausha-apexChart-day">${data[seriesIndex].datedddd} ${formattedHourOfDay}</p>
                        <p class="tooltip-ausha-apexChart-listen">${data[seriesIndex].data[dataPointIndex].tooltipMessage}</p>
                    </div>`;
                },
            },
        }),
        [colorRange, intl, data, isMobileOrTablet, maxDownloads],
    );

    return <ApexChart options={options} series={data} type="heatmap" width="100%" height="330" />;
};

export default HeatmapChart;
