import SelectNative from '@ui/molecules/SelectNative';
import useDroppable from '@/shared/hooks/useDroppable';
import MobileOrTablet from '@ui/molecules/MobileOrTablet';
import Desktop from '@ui/molecules/Desktop';
import MenuItem from '@ui/atoms/MenuItem';
import Menu from '@ui/atoms/Menu';
import { DeprecatedIcon } from '@ui/atoms/DeprecatedIcon';
import DeprecatedButton from '@ui/atoms/DeprecatedButton';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import Img from '@ui/atoms/Img';
import OutsideClickHandler from 'react-outside-click-handler';
import Text from '@ui/atoms/Text';
import styled from 'styled-components';

const ShareButtonClip = ({ type, onSelectContent, formats = [] }) => {
    const [ref, dropdownOpened, openDropdown, closeDropdown] = useDroppable();
    return (
        <>
            <Desktop>
                <OutsideClickHandler
                    onOutsideClick={() => {
                        closeDropdown();
                    }}
                >
                    <DeprecatedButton
                        h={40}
                        accent="rounded"
                        style={{ borderRadius: 4 }}
                        disabled={type.disabled}
                        onClick={openDropdown}
                    >
                        <DeprecatedIcon as={type.icon} size={20} />
                        <DeprecatedText weight="semibold" ml={8}>
                            {type.label}
                        </DeprecatedText>
                    </DeprecatedButton>
                    <ShareMenu dropdown anchor="up" ref={ref} opened={dropdownOpened}>
                        {formats.map((format) => (
                            <MenuItem
                                key={format.id}
                                h={53}
                                onClick={() => onSelectContent(type, format, null)}
                            >
                                <ImgWrapper>
                                    <Img src={format.previewUrl} maxH={40} maxW={40} radius={4} />
                                </ImgWrapper>
                                <Text numberOfLines={1} fontWeight="--fw-semibold">
                                    {format.clip.adminTitle || format.clip.title}
                                </Text>
                            </MenuItem>
                        ))}
                    </ShareMenu>
                </OutsideClickHandler>
            </Desktop>
            <MobileOrTablet>
                <SelectNative
                    disabled={type.disabled}
                    label={
                        <>
                            <DeprecatedIcon as={type.icon} size={20} />
                            <DeprecatedText weight="semibold" ml={8}>
                                {type.label}
                            </DeprecatedText>
                        </>
                    }
                    source={formats.map((format) => (
                        <DeprecatedPaper
                            key={format.id}
                            flex
                            align="center"
                            my={12}
                            mx={16}
                            onClick={() => onSelectContent(type, format, null)}
                        >
                            <ImgWrapper>
                                <Img src={format.previewUrl} maxH={40} maxW={40} radius={4} />
                            </ImgWrapper>
                            <Text numberOfLines={1} fontWeight="--fw-semibold">
                                {format.clip.adminTitle || format.clip.title}
                            </Text>
                        </DeprecatedPaper>
                    ))}
                />
            </MobileOrTablet>
        </>
    );
};

const ShareMenu = styled(Menu)`
    display: inline-block;
    width: 400px;
    max-height: 168px;
    left: 0;
    top: calc(100% + 0.5rem);
    overflow: scroll;
`;
const ImgWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--neutral50);
    border-radius: var(--r-xs);
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 0.75rem;
`;

export default ShareButtonClip;
