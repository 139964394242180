import { FormattedMessage, FormattedNumber } from 'react-intl';
import DeprecatedModal from '@ui/molecules/DeprecatedModal';
import { connect } from '@/components/legacy/connect';
import { PRICING_NAME } from '@/shared/config/pricing';
import { getHigherPricing } from '@/shared/services/pricingService';
import { CLIP_QUOTA } from '@/shared/config/constants';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import Cluster from '@ui/layout/Cluster';
import Link from '@ui/atoms/Link';
import DeprecatedIcon from '@ui/atoms/DeprecatedIcon';
import DeprecatedButton from '@ui/atoms/DeprecatedButton';
import IconRocket from '@public/icons/rocket.svg';
import EmojiPleading from '@public/icons/emoji_pleading.svg';
import styled from 'styled-components';
import useSubscriptionQuery from '@queries/subscription/useSubscriptionQuery.hook';
import { useParams } from 'react-router';
import useShowQuery from '@queries/show/useShowQuery.hook';

const enhance = connect(({ routerStore }) => ({
    upgradeLink: routerStore.link('user.edit.offers'),
}));

const NewClipRestrictedModal = ({ isOpened, onClose, monthlyClips, upgradeLink }) => {
    const { showId } = useParams();
    const { data: show } = useShowQuery(showId);
    const { data: subscription } = useSubscriptionQuery();
    const currentPricing = show?.ownerPricing || subscription?.pricing;
    const higherPricing = getHigherPricing(currentPricing);
    const higherPricingName = higherPricing ? PRICING_NAME[higherPricing] : '';
    const higherPricingMonthlyClips = higherPricing ? CLIP_QUOTA[higherPricing] : 0;

    return (
        <DeprecatedModal
            isOpened={isOpened}
            onClose={onClose}
            title={
                <Cluster $flexWrap="nowrap" $gap="0.25rem" $align="center">
                    <FormattedMessage defaultMessage="Vous n’avez plus de crédit…" />
                    <EmojiPleading style={{ height: '24px', width: '24px', cursor: 'auto' }} />
                </Cluster>
            }
        >
            <Wrapper>
                <DeprecatedText>
                    {higherPricing ? (
                        <FormattedMessage
                            defaultMessage="Vous pouvez attendre le mois prochain pour obtenir {monthlyClips} crédits, ou passer à l’offre {higherPricingName} pour bénéficier de {higherPricingMonthlyClips} crédits par mois."
                            values={{
                                monthlyClips: <FormattedNumber value={monthlyClips} />,
                                higherPricingName,
                                higherPricingMonthlyClips: (
                                    <FormattedNumber value={higherPricingMonthlyClips} />
                                ),
                            }}
                        />
                    ) : (
                        <FormattedMessage
                            defaultMessage="Il va falloir attendre le mois prochain pour obtenir {monthlyClips} crédits…"
                            values={{ monthlyClips: <FormattedNumber value={monthlyClips} /> }}
                        />
                    )}
                </DeprecatedText>
                <Cluster $gap="0.5rem" $justify="flex-end">
                    {higherPricing ? (
                        <>
                            <DeprecatedButton accent="secondary" onClick={onClose}>
                                <FormattedMessage defaultMessage="Annuler" />
                            </DeprecatedButton>
                            <DeprecatedButton as={Link} to={upgradeLink}>
                                <DeprecatedIcon as={IconRocket} size={20} color="white" />
                                <FormattedMessage
                                    defaultMessage="Passer en offre {higherPricingName}"
                                    values={{ higherPricingName }}
                                />
                            </DeprecatedButton>
                        </>
                    ) : (
                        <DeprecatedButton onClick={onClose}>
                            <FormattedMessage defaultMessage="Compris !" />
                        </DeprecatedButton>
                    )}
                </Cluster>
            </Wrapper>
        </DeprecatedModal>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2.5rem;
    max-width: 400px;
`;

export default enhance(NewClipRestrictedModal);
