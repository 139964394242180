import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteEpisodeTranscript } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import episodeTranscriptKeys from './episodeTranscriptKeys';
import aiKeys from '../ai/aiKeys';

const transform = (data) => {
    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

const mutationFn = async (episodeId) => {
    const { data } = await deleteEpisodeTranscript(episodeId);

    return transform(data);
};

const useDeleteEpisodeTranscriptMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: (_, episodeId) => {
            queryClient.invalidateQueries({
                queryKey: [{ entity: 'episodeTranscriptStatus', episodeId: Number(episodeId) }],
            });
            queryClient.resetQueries({
                queryKey: episodeTranscriptKeys.detailByEpisodeId(episodeId),
            });
            queryClient.invalidateQueries({
                queryKey: aiKeys.summaryDetails(episodeId),
            });
        },
    });
};

export default useDeleteEpisodeTranscriptMutation;
