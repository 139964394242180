import * as dayjs from 'dayjs';

export const getDatesFromPreviousMonth = (date: Date): Date[] => {
    let dates = [] as Date[];
    let currentMonthDayjs = dayjs(date);

    // Get the first day of the current month
    let firstDayCurrentMonth = currentMonthDayjs.startOf('month');
    let dayOfWeek = firstDayCurrentMonth.day();

    // If the first day of the month is Sunday, no days from the previous month are shown
    if (dayOfWeek === 0) {
        return dates;
    }

    // Calculate the dates from the previous month
    for (let i = 0; i < dayOfWeek; i++) {
        // Calculate the date to be pushed into the array
        let dateToAdd = firstDayCurrentMonth.subtract(dayOfWeek - i, 'day').toDate();
        dates.push(dateToAdd);
    }

    return dates;
};

export const getDatesFromNextMonth = (date: Date): Date[] => {
    let dates = [] as Date[];
    let currentMonthDayjs = dayjs(date);

    // Get the last day of the current month
    let lastDayCurrentMonth = currentMonthDayjs.endOf('month');
    let dayOfWeek = lastDayCurrentMonth.day();

    // If the last day of the month is Saturday, no days from the next month are shown
    if (dayOfWeek === 6) {
        return dates;
    }

    // Calculate the dates from the next month
    for (let i = 1; i <= 7 - dayOfWeek; i++) {
        // Calculate the date to be pushed into the array
        let dateToAdd = lastDayCurrentMonth.add(i, 'day').toDate();
        dates.push(dateToAdd);
    }

    return dates;
};

export const dateIsInTheFuture = (date: Date): boolean => {
    return dayjs(date).isAfter(dayjs());
};
