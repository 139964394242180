import { useState } from 'react';
import { useParams, useHistory } from 'react-router';
import { useIntl, FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { BROADCAST_PLATFORMS_GUIDE_LINKS, ListeningPlatform, SPOTIFY } from '@/utils/constants';
import { InputTextController } from '@/components/ui/atoms/InputText';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import useBroadcastSettingsFormSchema, {
    BroadcastSettingsSchema,
} from './useBroadcastSettingsFormSchema.hook';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';
import { TooltipTrigger } from 'react-aria-components';
import Button from '@/components/Button';
import ExternalLink from '@/components/Link/ExternalLink';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import Alert from '@/components/ui/atoms/Alert';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import Modal from '@/components/ui/atoms/Modal';
import Award from '@ui/molecules/Award';
import useUpdateShowListeningLinkMutation from '@/queries/show/useUpdateShowListeningLinkMutation.hook';
import ConfirmUnbroadcastSpotifyModal from './ConfirmUnbroadcastSpotifyModal';
import { useOverlayTriggerState } from 'react-stately';
import useDeleteShowListeningLinkMutation from '@/queries/show/useDeleteShowListeningLinkMutation.hook';
import styled from 'styled-components';

interface PlatformBroadcastSettingsFormProps {
    readonlyMode: boolean;
    setReadonlyMode: (value: boolean) => void;
    platformKey: ListeningPlatform;
    platformName: string;
    isAOneClickPlatform: boolean;
    setHasRequestedBroadcast: (value: boolean) => void;
}

const PlatformBroadcastSettingsForm = ({
    readonlyMode,
    setReadonlyMode,
    platformKey,
    platformName,
    isAOneClickPlatform,
    setHasRequestedBroadcast,
}: PlatformBroadcastSettingsFormProps) => {
    const { showId, platform } = useParams<{ showId: string; platform: string }>();
    const history = useHistory();
    const { data: show } = useShowQuery(showId);
    const { data: user } = useUserQuery();
    const intl = useIntl();
    const deleteListeningLink = useDeleteShowListeningLinkMutation();
    const updateListeningLink = useUpdateShowListeningLinkMutation();
    const schema = useBroadcastSettingsFormSchema();

    const [confirmModifyRss, setConfirmModifyRss] = useState(false);
    const [hasUpdatedListeningLink, setHasUpdatedListeningLink] = useState(false);
    let unbroadcastSpotifyModalstate = useOverlayTriggerState({});

    const isShowPmp = show?.hostedOnAusha === false;
    const supportedUserLanguage = ['en', 'fr'].includes(user?.language as string)
        ? user?.language
        : 'en';
    const platformListeningLinkUrl = show?.listeningLinks?.data?.find(
        (link) => link.key === platform,
    )?.url;

    const { handleSubmit, control, watch } = useForm<BroadcastSettingsSchema>({
        mode: 'onBlur',
        values: {
            link: platformListeningLinkUrl || '',
        },
        resolver: zodResolver(schema),
    });

    const guideLinksForPlatform =
        BROADCAST_PLATFORMS_GUIDE_LINKS[
            platformKey as keyof typeof BROADCAST_PLATFORMS_GUIDE_LINKS
        ];

    const guideLink = guideLinksForPlatform
        ? guideLinksForPlatform[supportedUserLanguage as keyof typeof guideLinksForPlatform]
        : null;

    const onSubmit = (payload: BroadcastSettingsSchema) => {
        updateListeningLink.mutate(
            {
                showId,
                payload: {
                    [platform]: payload.link,
                },
            },
            {
                onSuccess: () => {
                    setHasUpdatedListeningLink(true);
                    if (isAOneClickPlatform) {
                        setHasRequestedBroadcast(true);
                    }
                },
            },
        );

        setReadonlyMode(true);
    };

    return (
        <>
            {/* Ugly rocket animation */}
            {/* @ts-ignore */}
            {hasUpdatedListeningLink && !isShowPmp && <Award name="bravo" />}
            <Form onSubmit={handleSubmit(onSubmit)}>
                <InputTextController
                    name="link"
                    control={control}
                    isDisabled={readonlyMode}
                    label={
                        <LabelWrapper>
                            <FormattedMessage
                                defaultMessage="Lien d'écoute {broadcastName}"
                                values={{ broadcastName: platformName }}
                            />
                            {guideLink && (
                                <TooltipTrigger delay={0} closeDelay={0}>
                                    <TooltipTriggerWrapper>
                                        <HelpIcon
                                            icon={icon({
                                                name: 'info-circle',
                                                style: 'solid',
                                            })}
                                        />
                                    </TooltipTriggerWrapper>
                                    <Tooltip placement="top">
                                        <FormattedMessage
                                            defaultMessage="Vous ne trouvez pas votre lien d’écoute {broadcastName} ? {consultGuide}"
                                            values={{
                                                broadcastName: platformName,
                                                consultGuide: (
                                                    <WhiteLink href={guideLink} target="_blank">
                                                        <FormattedMessage defaultMessage="Accédez à notre guide" />
                                                    </WhiteLink>
                                                ),
                                            }}
                                        />
                                    </Tooltip>
                                </TooltipTrigger>
                            )}
                        </LabelWrapper>
                    }
                    placeholder={intl.formatMessage(
                        {
                            defaultMessage:
                                'Saisissez votre lien d’écoute {broadcastName} dès qu’il est disponible',
                        },
                        { broadcastName: platformName },
                    )}
                />
                <ControlsWrapper>
                    {readonlyMode ? (
                        <InnerControlsWrapper>
                            {!isShowPmp && platformKey === SPOTIFY && !!platformListeningLinkUrl ? (
                                <Button
                                    type="button"
                                    variant="danger"
                                    onPress={unbroadcastSpotifyModalstate.open}
                                >
                                    <FormattedMessage defaultMessage="Ne plus diffuser sur Spotify" />
                                </Button>
                            ) : (
                                <div />
                            )}

                            <InnerControlsWrapperRight>
                                {platformListeningLinkUrl && (
                                    <ExternalLink
                                        href={platformListeningLinkUrl}
                                        target="_blank"
                                        startIcon={
                                            <FontAwesomeIcon
                                                icon={icon({
                                                    name: 'arrow-up-right-from-square',
                                                    style: 'solid',
                                                })}
                                            />
                                        }
                                    >
                                        <FormattedMessage defaultMessage="Ouvrir le lien" />
                                    </ExternalLink>
                                )}
                                <Button
                                    type="button"
                                    variant="secondary"
                                    startIcon={
                                        <FontAwesomeIcon
                                            icon={icon({ name: 'pen', style: 'solid' })}
                                        />
                                    }
                                    onPress={() => {
                                        const link = watch('link');
                                        if (isShowPmp || !link) {
                                            setReadonlyMode(false);
                                            return;
                                        }
                                        setConfirmModifyRss(true);
                                    }}
                                >
                                    <FormattedMessage defaultMessage="Modifier" />
                                </Button>
                            </InnerControlsWrapperRight>
                        </InnerControlsWrapper>
                    ) : (
                        <>
                            <CancelButton
                                type="submit"
                                variant="ghost"
                                onPress={() => setReadonlyMode(true)}
                            >
                                <FormattedMessage defaultMessage="Annuler" />
                            </CancelButton>
                            <Button type="submit">
                                <FormattedMessage defaultMessage="Enregistrer" />
                            </Button>
                        </>
                    )}
                </ControlsWrapper>
            </Form>
            <ModalStyled isOpen={confirmModifyRss} onOpenChange={setConfirmModifyRss} size="small">
                <IconWrapper>
                    <WarningIcon icon={icon({ name: 'triangle-exclamation', style: 'solid' })} />
                </IconWrapper>
                <FormattedMessage defaultMessage="Voulez-vous vraiment modifier ce lien d'écoute ?" />
                <Alert variant="info" defaultIcon>
                    <AlertText>
                        <FormattedMessage
                            defaultMessage="Les liens disponibles par défaut sur Ausha sont fonctionnels. <strong>Sauf cas exceptionnel (problème technique) il ne faut pas les modifier ou tenter de les personnaliser.</strong>"
                            values={{
                                strong: (chunks: React.ReactNode) => <strong>{chunks}</strong>,
                            }}
                        />
                    </AlertText>
                </Alert>
                <ConfirmModalActions>
                    <Button
                        onPress={() => {
                            setConfirmModifyRss(false);
                            setReadonlyMode(false);
                        }}
                        startIcon={<FontAwesomeIcon icon={icon({ name: 'pen', style: 'solid' })} />}
                    >
                        <FormattedMessage defaultMessage="Continuer" />
                    </Button>
                    <Button
                        variant="link-secondary"
                        onPress={() => {
                            setConfirmModifyRss(false);
                            setReadonlyMode(true);
                        }}
                    >
                        <FormattedMessage defaultMessage="Annuler" />
                    </Button>
                </ConfirmModalActions>
            </ModalStyled>
            {unbroadcastSpotifyModalstate.isOpen && (
                <ConfirmUnbroadcastSpotifyModal
                    handleUnbroadcastSpotify={() => {
                        deleteListeningLink.mutate(
                            {
                                showId,
                                platform: platformKey,
                            },
                            {
                                onSuccess: () => {
                                    unbroadcastSpotifyModalstate.close();
                                    setHasRequestedBroadcast(false);
                                    history.push(`/app/show/${showId}/settings/broadcast`);
                                },
                            },
                        );
                    }}
                    onClose={unbroadcastSpotifyModalstate.close}
                    isOpen={unbroadcastSpotifyModalstate.isOpen}
                />
            )}
        </>
    );
};

const InnerControlsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;
const InnerControlsWrapperRight = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

const LabelWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;
const HelpIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
`;
const WhiteLink = styled.a`
    color: inherit;
    font-weight: inherit;
    font-size: inherit;
`;
const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
`;
const ControlsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
`;
const CancelButton = styled(Button)`
    color: var(--neutral);
`;
const ModalStyled = styled(Modal)`
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1.5rem;
`;
const WarningIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
`;
const IconWrapper = styled.div`
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--warning50);
    color: var(--warning500);
    border-radius: var(--r-s);
`;
const ConfirmModalActions = styled.div`
    align-self: stretch;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    & > :last-child {
        align-self: center;
    }
`;
const AlertText = styled.p`
    font-weight: var(--fw-semibold);
`;

export default PlatformBroadcastSettingsForm;
