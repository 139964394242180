import messages from './validationMessages';

export const messageParsers = {
    Required: messages.isRequired,
    Exists: messages.exists,
    Url: messages.isUrl,
    Unique: messages.isUnique,
    Different: messages.differentFrom,
    Max: messages.maxLength,
    RsslinkRule: messages.invalidRssLink,
    CurrentPassword: messages.invalidPassword,
    Confirmed: messages.passwordsNotMatching,
    Dimensions: (minW, minH) => messages.minDimension(minW.split('=')[1], minH.split('=')[1]),
    VatNumber: messages.invalidVat,
    AlphaDash: messages.alphaDash,
    Email: messages.isEmail,
    Indisposable: messages.disposableEmailBlocked,
    Mimes: (acceptedMimes) => messages.requiredMime(acceptedMimes),
    ImageRatioRule: messages.ratioRule,
    Image: messages.fileMustBeImage,
};

export const messagesImgUploadError = {
    Mimes: (provider, currentValues) =>
        messages.acceptedMimesImgUpload(provider, currentValues.format),
    ImageRatioRule: messages.ratioRule,
    Image: messages.fileMustBeImage,
};
