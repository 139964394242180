import { useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import NoPlatforms from './NoPlatforms';
import Landing from './Landing';
import StoreConfiguration from './StoreConfiguration';
import KeywordConfiguration from './KeywordConfiguration';
import ConfigurationEndLoader from './ConfigurationEndLoader';
import ConfigurationError from './ConfigurationError';
import Spinner from '@/components/ui/atoms/Spinner';
import useKeywordQuotaQuery from '@queries/pso/useKeywordQuotaQuery.hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useAmplitudeLogEvent } from '@hooks/useAmplitude';
import ConfigurationCurrentStep from './ConfigurationCurrentStep';
import styled from 'styled-components';

const CONFIGURATION_STATES = {
    EMPTY: 'EMPTY',
    LANDING: 'LANDING',
    KEYWORDS: 'KEYWORDS',
    STORES: 'STORES',
    LOADING: 'LOADING',
    ERROR: 'ERROR',
};

export interface PsoConfigurationTunnelProps {
    onRedirectToOverview: () => void;
    keywordsSaved: boolean;
    keywords: string[];
    setConfigurationEndLoading: (loading: boolean) => void;
    userHasWriteAccess: boolean;
    currentStore: string;
    isFullyConfigured: boolean;
}

const PsoConfigurationTunnel = ({
    onRedirectToOverview,
    keywordsSaved,
    keywords,
    setConfigurationEndLoading,
    userHasWriteAccess,
    currentStore,
    isFullyConfigured,
}: PsoConfigurationTunnelProps) => {
    useAmplitudeLogEvent('PSO Setup Viewed');
    const { showId }: { showId: string } = useParams();
    const keywordQuota = useKeywordQuotaQuery({ showId });

    const [configurationCurrentState, setConfigurationCurrentState] = useState(
        keywordsSaved ? CONFIGURATION_STATES.STORES : CONFIGURATION_STATES.LANDING,
    );

    const endConfiguration = () => {
        onRedirectToOverview();
    };

    const configurationStateLookup = {
        [CONFIGURATION_STATES.EMPTY]: <NoPlatforms />,
        [CONFIGURATION_STATES.LANDING]: (
            <Landing
                onGoToNextStep={() => setConfigurationCurrentState(CONFIGURATION_STATES.KEYWORDS)}
                keywordQuota={keywordQuota}
                userHasWriteAccess={userHasWriteAccess}
            />
        ),
        [CONFIGURATION_STATES.KEYWORDS]: (
            <KeywordConfiguration
                isEditMode={false}
                existingKeywords={keywords}
                keywordQuota={keywordQuota}
                onKeywordsSubmitted={() => {
                    setConfigurationCurrentState(CONFIGURATION_STATES.STORES);
                }}
                currentStore={currentStore}
            />
        ),
        [CONFIGURATION_STATES.STORES]: (
            <StoreConfiguration
                onStoresSubmitted={() => {
                    setConfigurationEndLoading(true);
                    setConfigurationCurrentState(CONFIGURATION_STATES.LOADING);
                }}
            />
        ),
        [CONFIGURATION_STATES.LOADING]: (
            <ConfigurationEndLoader
                onLoadingEnd={() => {
                    setConfigurationEndLoading(false);
                    endConfiguration();
                }}
            />
        ),
        [CONFIGURATION_STATES.ERROR]: (
            <ConfigurationError
                onRetry={() => setConfigurationCurrentState(CONFIGURATION_STATES.KEYWORDS)}
            />
        ),
    };

    useAmplitudeLogEvent('PSO Setup Keywords Viewed');
    useAmplitudeLogEvent('PSO Setup Country Viewed');

    if (isFullyConfigured) {
        return <Redirect to={`/app/show/${showId}/pso/overview`} />;
    }

    if (keywordQuota.isLoading) {
        return (
            <LoadingWrapper>
                <Spinner />
            </LoadingWrapper>
        );
    }

    return (
        <PsoConfigurationTunnelOuterWrapper>
            <TitleWrapper>
                <Title>
                    <FormattedMessage defaultMessage="Référencement" />
                </Title>
                <SubTitle>PSO Control Panel</SubTitle>
                <BetaTag>
                    <FontAwesomeIcon icon={icon({ name: 'flask', style: 'solid' })} size="lg" />
                    <FormattedMessage defaultMessage="Bêta publique" />
                </BetaTag>
            </TitleWrapper>

            <Content>
                <ConfigurationWrapper>
                    <ConfigurationCurrentStep
                        configurationStates={CONFIGURATION_STATES}
                        configurationStateLookup={configurationStateLookup}
                        configurationCurrentState={configurationCurrentState}
                    />
                </ConfigurationWrapper>
            </Content>
        </PsoConfigurationTunnelOuterWrapper>
    );
};

const BetaTag = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    font-weight: var(--fw-semibold);
    font-size: var(--fs-body-s);
    background-color: var(--warning50);
    color: var(--warning);
    padding-block: 0.5rem;
    padding-inline: 0.75rem;
    border-radius: var(--r-s);
`;
const PsoConfigurationTunnelOuterWrapper = styled.div`
    width: 100%;
    height: max-content;
    max-width: var(--container-width);
    margin-inline: auto;
    margin-block: 2rem;
    padding-inline: var(--container-padding);
    display: flex;
    flex-direction: column;
    gap: 2rem;

    ${({ theme }) => theme.mediaQueries.mobile} {
        margin-block: 1rem;
        padding-inline: var(--container-padding-mobile);
    }
`;
const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;
const Title = styled.div`
    font-weight: var(--fw-bold);
    font-size: var(--fs-heading-m);
`;
const SubTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: var(--fw-semibold);
    font-size: var(--fs-body-s);
    background-color: var(--primary50);
    color: var(--primary);
    padding-block: 0.5rem;
    padding-inline: 0.75rem;
    border-radius: var(--r-s);
`;
const ConfigurationWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: 1rem;
`;
const LoadingWrapper = styled.div`
    width: 100%;
    height: 25rem;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;

export default PsoConfigurationTunnel;
