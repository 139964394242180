import styled from 'styled-components';
import Text from '@ui/atoms/Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import UiKitTooltip from '@ui/atoms/UiKitTooltip';
import { FormattedMessage } from 'react-intl';
import TranscriptionGenerate from './TranscriptionGenerate';
import TranscriptionLoading from './TranscriptionLoading';
import TranscriptionSuccess from './TranscriptionSuccess';
import TranscriptionMenu from './TranscriptionMenu';
import Cluster from '@ui/layout/Cluster';
import { EPISODE_TRANSCRIPT_STATUS } from '@/shared/config/constants';
import {
    useNonHostingEpisodeTranscription,
    useNonHostingEpisodeTranscriptionStatus,
} from '@/components/NonHostingEpisodeForm/contexts/NonHostingTranscriptionContext';

const TranscriptionContent = () => {
    const transcription = useNonHostingEpisodeTranscription();
    const transcriptionStatus = useNonHostingEpisodeTranscriptionStatus();

    if (!transcriptionStatus && !transcription) {
        return <div>ERROR</div>;
    }

    /* Loading state */
    if (transcriptionStatus?.status === EPISODE_TRANSCRIPT_STATUS.IN_PROGRESS) {
        return <TranscriptionLoading />;
    }

    /* Success states */
    if (
        transcription?.data &&
        (transcriptionStatus?.status === EPISODE_TRANSCRIPT_STATUS.UPDATED ||
            transcriptionStatus?.status === EPISODE_TRANSCRIPT_STATUS.DONE)
    ) {
        return <TranscriptionSuccess transcription={transcription} />;
    }

    /* Error and no transcription states */
    return <TranscriptionGenerate />;
};

const Transcription = () => {
    const transcription = useNonHostingEpisodeTranscription();
    const transcriptionStatus = useNonHostingEpisodeTranscriptionStatus();
    const isError = transcriptionStatus?.status === EPISODE_TRANSCRIPT_STATUS.ERROR;

    return (
        <Container>
            <Header>
                <Cluster $gap="0.5rem" $align="center" $wrap="no-wrap">
                    <HeaderIcon
                        icon={icon({ name: 'subtitles', style: 'solid' })}
                        color="inherit"
                    />
                    <TitleWrapper>
                        <Text variant="bodyL" fontWeight="--fw-semibold">
                            Transcription
                        </Text>
                        <UiKitTooltip
                            variant="dark"
                            position="top"
                            content={
                                <FormattedMessage defaultMessage="La transcription convertit votre épisode en texte, renforçant ainsi son référencement SEO et son accessibilité." />
                            }
                        >
                            <InfoIcon icon={icon({ name: 'circle-info', style: 'solid' })} />
                        </UiKitTooltip>
                    </TitleWrapper>
                </Cluster>
                {transcription?.data && !isError && (
                    <TranscriptionMenu
                        menuIcon={
                            <EllipsisIcon icon={icon({ name: 'ellipsis', style: 'solid' })} />
                        }
                    />
                )}
            </Header>
            <TranscriptionContent />
        </Container>
    );
};

const Container = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
`;
const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 0.5rem;
`;
const HeaderIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--primary500);
`;
const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.25rem;
`;
const InfoIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: var(--neutral500);
`;
const EllipsisIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--neutral500);
`;

export default Transcription;
