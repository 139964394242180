import Stack from '@/components/ui/layout/Stack';
import styled, { css, keyframes } from 'styled-components';

const ClipFormatSkeleton = () => {
    return (
        <Container>
            <Cover />
            <Stack $gap="0.25rem">
                <Line $w="6rem" $h="0.5rem" />
                <LightLine $w="10rem" $h="0.5rem" />
            </Stack>
        </Container>
    );
};

const shimmer = keyframes`
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
`;
const shimmering = css`
    background: var(--neutral50);
    background-image: linear-gradient(
        to right,
        var(--neutral50) 0%,
        #edeef1 20%,
        var(--neutral50) 40%,
        var(--neutral50) 100%
    );
    background-repeat: no-repeat;
    background-size: 50rem 152px;
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${shimmer};
    animation-timing-function: linear;
`;
const Line = styled.div<{ $w?: string; $h?: string; $maxW?: string }>`
    border-radius: var(--r-full);
    background-color: var(--neutral100);
    ${({ $w }) => $w && `width: ${$w};`}
    ${({ $h }) => $h && `height: ${$h};`}
    ${({ $maxW }) => $maxW && `max-width: ${$maxW};`}
    ${shimmering};
`;
const LightLine = styled(Line)`
    background-color: var(--neutral50);
`;
const Cover = styled.div`
    width: 100%;
    border-radius: var(--r-xs);
    background-color: var(--neutral100);
    ${shimmering};
    height: 152px;
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.75rem;
`;

export default ClipFormatSkeleton;
