import useShowQuery from '@/queries/show/useShowQuery.hook';
import { ENABLE_TRANSCRIPTION, USER_ROLES } from '../constants';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import { USER_OPTIONS } from '../userOptions';

interface HasAccessToTranscriptionProps {
    showId: string;
}

const useHasAccessToTranscription = ({ showId }: HasAccessToTranscriptionProps) => {
    const { data: show } = useShowQuery(showId);
    const { data: user } = useUserQuery();

    const guestWithAccess =
        show?.userRole === USER_ROLES.ADMIN &&
        show?.userOptions.includes(USER_OPTIONS.TRANSCRIPTION);
    const ownerWithAccess =
        show?.userRole === USER_ROLES.OWNER && user?.options.includes(USER_OPTIONS.TRANSCRIPTION);
    const hasTranscriptionUserOption = guestWithAccess || ownerWithAccess;

    return ENABLE_TRANSCRIPTION && hasTranscriptionUserOption;
};

export default useHasAccessToTranscription;
