import PropTypes from 'prop-types';
import Text from '@ui/atoms/Text';
import Button from '@ui/atoms/Button';
import { FormattedMessage } from 'react-intl';
import IconWarning from '@ui/icons/IconWarning';
import { IntercomAPI } from 'react-intercom';
import styled from 'styled-components';

const GeolocalizationErrorState = ({ onRetry }) => {
    return (
        <ErrorStateWrapper>
            <ErrorSubSection>
                <ErrorStateIconWrapper>
                    <IconWarning />
                </ErrorStateIconWrapper>
                <Text variant="heading" fontWeight="--fw-bold">
                    <FormattedMessage defaultMessage="Une erreur est survenue" />
                </Text>
            </ErrorSubSection>
            <ErrorSubSection>
                <Button onClick={onRetry}>
                    <FormattedMessage defaultMessage="Réessayer" />
                </Button>

                <Text variant="body" fontWeight="--fw-normal">
                    <FormattedMessage
                        defaultMessage="Besoin d'aide ? <button>Contactez-nous</button>"
                        values={{
                            button: (chunks) => (
                                <ActionButton
                                    variant="ghost"
                                    color="--primary"
                                    onPress={() => IntercomAPI('show')}
                                >
                                    {chunks}
                                </ActionButton>
                            ),
                        }}
                    />
                </Text>
            </ErrorSubSection>
        </ErrorStateWrapper>
    );
};

GeolocalizationErrorState.propTypes = {
    onRetry: PropTypes.func,
};

const ErrorStateWrapper = styled.div`
    width: 100%;
    background: var(--white);
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding-block: 2rem;
`;

const ErrorStateIconWrapper = styled.div`
    height: 3rem;
    width: 3rem;
    border-radius: var(--r-s);
    background: var(--alert50);
    color: var(--alert);
    display: flex;
    align-items: center;
    justify-content: center;
`;

const ErrorSubSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
`;

const ActionButton = styled(Button)`
    padding: 0;
`;

export default GeolocalizationErrorState;
