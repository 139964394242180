import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Button from '@/components/Button';
import dayjs from 'dayjs';
import usePsoLiveSearchQuery from '@queries/pso/usePsoLiveSearchQuery.hook';
import { getCountryISO3 } from '@/shared/services/countryService';
import type { Platform, Mode } from '@/api/pso/types';
import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { sendAmplitudeLogEvent } from '@/helpers';
import Text from '@/components/ui/atoms/Text';
import ExternalLink from '@/components/Link/ExternalLink';
import styled from 'styled-components';

interface HeaderProps {
    children?: React.ReactNode;
    searchParams: {
        keyword: string;
        platform: Platform;
        store: string;
        mode: Mode;
    };
}

const Header = ({ children, searchParams }: HeaderProps) => {
    const { showId }: { showId: string } = useParams();
    const { keyword, platform, store, mode } = searchParams;
    const intl = useIntl();

    const liveSearch = usePsoLiveSearchQuery(
        {
            showId,
            keyword,
            platform,
            store: getCountryISO3(store),
            mode,
            format: 'text/csv',
        },
        { enabled: false },
    );

    const handleExport = () => {
        sendAmplitudeLogEvent('PSO Live Search Exported');
        liveSearch.refetch();
    };

    useEffect(() => {
        if (liveSearch.data) {
            const url = window.URL.createObjectURL(
                new Blob([Object.values(liveSearch.data).slice(0, -1).join('')], {
                    type: 'text/csv',
                }),
            );
            const link = document.createElement('a');
            link.href = url;
            const platform = searchParams.platform === 'itunes' ? 'apple_podcasts' : 'spotify';
            const fileName = `${dayjs().format('YYMMDD')}-${platform}-${searchParams.mode}-${
                searchParams.keyword
            }.csv`;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        }
    }, [liveSearch?.data]);

    return (
        <HeaderWrapper>
            <HeaderInnerWrapper>
                <TitleAndDescription>
                    <TitleWrapper>
                        <TitleIcon icon={icon({ name: 'magnifying-glass', style: 'solid' })} />
                        <Text variant="headingM" fontWeight="--fw-bold">
                            <FormattedMessage defaultMessage="Live Search" />
                        </Text>
                    </TitleWrapper>
                    <Text color="--neutral500">
                        <FormattedMessage
                            defaultMessage="Faites l'expérience de recherches en temps réel qui reflètent exactement les résultats qu'un public verrait sur les plateformes d'écoute. Comprenez comment les podcasts se classent pour n'importe quel mot-clé et obtenez des informations précieuses sur la concurrence. <link>En savoir plus.</link>"
                            values={{
                                link: (chunks: string) => (
                                    <DescriptionLink
                                        href={intl.formatMessage({
                                            defaultMessage:
                                                'https://help.ausha.co/fr/articles/10058858-pso-control-panel-comment-utiliser-la-live-search-pour-connaitre-en-temps-reel-le-classement-de-mes-concurrents-sur-mes-mots-cles',
                                        })}
                                        target="_blank"
                                    >
                                        {chunks}
                                    </DescriptionLink>
                                ),
                            }}
                        />
                    </Text>
                </TitleAndDescription>
                <ActionWrapper>
                    <ButtonWrapper>
                        <Button
                            variant="tertiary"
                            isDisabled={!searchParams?.keyword}
                            startIcon={
                                liveSearch.isFetching ? (
                                    <ExportLoadingIcon
                                        icon={icon({
                                            name: 'spinner-third',
                                            style: 'solid',
                                        })}
                                        spin
                                        color="inherit"
                                        size="sm"
                                    />
                                ) : (
                                    <FontAwesomeIcon
                                        icon={icon({
                                            name: 'arrow-down-to-bracket',
                                            style: 'solid',
                                        })}
                                        color="inherit"
                                        size="sm"
                                    />
                                )
                            }
                            onPress={handleExport}
                        >
                            <FormattedMessage defaultMessage="Exporter" />
                        </Button>
                    </ButtonWrapper>
                </ActionWrapper>
            </HeaderInnerWrapper>
            {children}
        </HeaderWrapper>
    );
};

const HeaderWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding);
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;
const HeaderInnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    gap: 1rem;
`;
const TitleAndDescription = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    flex: 1;
`;
const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.75rem;
`;
const TitleIcon = styled(FontAwesomeIcon)`
    width: 1.25rem;
    height: 1.25rem;
`;
const ActionWrapper = styled.div``;
const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 1.5rem;
`;
const ExportLoadingIcon = styled(FontAwesomeIcon)`
    color: var(--primary);
`;
const DescriptionLink = styled(ExternalLink)`
    color: inherit;
    text-decoration: underline;
    font-weight: inherit;

    &[data-hovered='true'] {
        color: inherit;
    }
`;

export default Header;
