import { useMutation, useQueryClient } from '@tanstack/react-query';
import useDeleteEpisodeMutation from './useDeleteEpisodeMutation.hook';
import episodeKeys from '@/queries/episode/episodeKeys';
import showKeys from '../show/showKeys';

export interface MutationFnProps {
    episodeIds: string[];
}

const useDeleteMultipleEpisodesMutation = () => {
    const queryClient = useQueryClient();
    const deleteEpisode = useDeleteEpisodeMutation();

    return useMutation({
        mutationFn: ({ episodeIds }: MutationFnProps) => {
            const promises: Promise<any>[] = [];

            episodeIds.forEach((episodeId) => {
                promises.push(deleteEpisode.mutateAsync({ episodeId }));
            });

            return Promise.allSettled(promises);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: episodeKeys.allLists(),
            });

            queryClient.invalidateQueries({
                queryKey: showKeys.all(),
            });
        },
    });
};

export default useDeleteMultipleEpisodesMutation;
