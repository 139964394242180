import { createContext, useContext, useState, ReactNode } from 'react';
import useAddEpisodeAudioFileMutation from '@/queries/episode/useAddEpisodeAudioFileMutation.hook';

interface NonHostingEpisodeFileUploadContext {
    audioFileMutation: ReturnType<typeof useAddEpisodeAudioFileMutation>;
    audioFileState: [File | null, React.Dispatch<React.SetStateAction<File | null>>];
    episodeState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}

const Context = createContext<NonHostingEpisodeFileUploadContext | null>(null);

interface NonHostingEpisodeFileUploadContextProviderProps {
    children: ReactNode;
}

/**
 * Context dedicated to episode creation.
 * Accessible from audio file upload and episode edit form and used to pass audio file data
 * (file name, file size, upload progress, etc) from upload page and episode edit form.
 */
export const NonHostingEpisodeFileUploadContextProvider = ({
    children,
}: NonHostingEpisodeFileUploadContextProviderProps) => {
    const audioFileState = useState<File | null>(null);
    // Keep track of new episode to display or not the updated at message
    const episodeState = useState<boolean>(false);
    const addEpisodeAudioFileMutation = useAddEpisodeAudioFileMutation();

    return (
        <Context.Provider
            value={{
                audioFileMutation: addEpisodeAudioFileMutation,
                audioFileState,
                episodeState,
            }}
        >
            {children}
        </Context.Provider>
    );
};

export const useEpisodeEditAudioFileMutation = () => {
    const context = useContext(Context);

    if (!context) {
        throw new Error(
            'useEpisodeEditAudioFileMutation must be used within a NonHostingEpisodeFileUploadContextProvider',
        );
    }

    return context.audioFileMutation;
};

export const useEpisodeEditAudioFileState = () => {
    const context = useContext(Context);

    if (!context) {
        throw new Error(
            'useEpisodeEditAudioFileState must be used within a NonHostingEpisodeFileUploadContextProvider',
        );
    }

    return context.audioFileState;
};

export const useEpisodeEditState = () => {
    const context = useContext(Context);

    if (!context) {
        throw new Error(
            'useEpisodeEditState must be used within a NonHostingEpisodeFileUploadContextProvider',
        );
    }

    return context.episodeState;
};
