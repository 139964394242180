import { useParams, useLocation } from 'react-router';
import { FormattedMessage } from 'react-intl';
import RouterLink from '@/components/Link/RouterLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import MobileNav from '@/components/ui/MobileNav';
import styled, { css } from 'styled-components';

const EpisodesNav = () => {
    const { showId }: { showId: string } = useParams();
    const location = useLocation();

    const navItems = [
        {
            route: `/app/show/${showId}/episodes`,
            icon: <SectionIcon icon={icon({ name: 'microphone-lines', style: 'solid' })} />,
            label: <FormattedMessage defaultMessage="Épisodes" />,
        },
        {
            route: `/app/show/${showId}/playlists`,
            icon: <SectionIcon icon={icon({ name: 'album-collection', style: 'solid' })} />,
            label: <FormattedMessage defaultMessage="Playlists" />,
        },
    ];

    return (
        <>
            <DesktopNavWrapper>
                <Section>
                    {navItems.map((item, index) => (
                        <SectionItem
                            key={index}
                            $isActive={location.pathname.includes(
                                item.route.split('/')[item.route.split('/').length - 1],
                            )}
                        >
                            {item.icon}
                            <StyledRouterLink
                                to={item.route}
                                $isActive={location.pathname.includes(
                                    item.route.split('/')[item.route.split('/').length - 1],
                                )}
                            >
                                {item.label}
                            </StyledRouterLink>
                        </SectionItem>
                    ))}
                </Section>
            </DesktopNavWrapper>
            <MobileNav navItems={navItems} />
        </>
    );
};

const DesktopNavWrapper = styled.nav`
    flex-direction: column;
    justify-content: center;
    gap: 1rem;

    display: none;

    @media (min-width: 1215px) {
        display: flex;
    }
`;
const Section = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;
const SectionItem = styled.div<{ $isActive: boolean; isDisabled?: boolean }>`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    /* 0.5rem + 0.25rem from indicator */
    padding-left: 0.725rem;

    ${({ $isActive }) =>
        $isActive &&
        css`
            color: var(--primary);
        `};

    @media (min-width: 1215px) {
        ${({ $isActive }) =>
            $isActive &&
            css`
                padding-left: 0;
                &:before {
                    content: '';
                    width: 0.25rem;
                    height: 1rem;
                    border-radius: var(--r-full);
                    background-color: var(--primary);
                }
            `};
    }

    ${({ isDisabled }) =>
        isDisabled &&
        css`
            color: var(--neutral300);
        `};
`;
const StyledRouterLink = styled(RouterLink)<{ $isActive: boolean }>`
    transition-duration: 0.2s;
    color: var(--black);

    ${({ $isActive }) =>
        $isActive &&
        css`
            color: var(--primary);
        `};

    &:hover {
        transition-duration: 0.2s;
        opacity: 0.8;
    }
`;
const SectionIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
`;

export default EpisodesNav;
