import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';

interface RankCellProps {
    rank: number;
}

const RankCell = ({ rank }: RankCellProps) => {
    return (
        <RankCellWrapper>
            {rank <= 3 && rank > 0 && (
                <AwardIcon rank={rank} icon={icon({ name: 'award', style: 'solid' })} />
            )}
            {rank > 0 ? rank : <DashIcon icon={icon({ name: 'dash', style: 'solid' })} />}
        </RankCellWrapper>
    );
};

const RankCellWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
`;
const AwardIcon = styled(FontAwesomeIcon)<{ rank: number }>`
    ${({ rank }) => rank === 1 && `color: var(--decorativeD);`}
    ${({ rank }) => rank === 2 && `color: var(--neutral300);`}
    ${({ rank }) => rank > 2 && `color: var(--decorativeC);`}
`;
const DashIcon = styled(FontAwesomeIcon)`
    color: var(--neutral300);
`;

export default RankCell;
