import Text from '@/components/ui/atoms/Text';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { sendAmplitudeLogEvent } from '@/helpers';
import Snippet from '../Snippet';
import Cluster from '@/components/ui/layout/Cluster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { NonHostingEpisodeFormSchema } from '../../useNonHostingEpisodeFormSchema.hook';

const LinkedinSummary = () => {
    const { getValues } = useFormContext<NonHostingEpisodeFormSchema>();
    const post = getValues('socialPosts.linkedin');

    if (!post) return null;

    const handleCopy = () => {
        sendAmplitudeLogEvent('Copy Episode LinkedIn Post');
    };

    return (
        <Wrapper>
            <Cluster $gap="0.5rem" $align="center">
                <LinkedinIcon icon={icon({ name: 'linkedin', style: 'brands' })} />
                <Text variant="bodyL" fontWeight="--fw-semibold">
                    Linkedin
                </Text>
            </Cluster>
            <Snippet onCopy={handleCopy} valueToCopy={post}>
                <Post>{post}</Post>
            </Snippet>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
`;
const LinkedinIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: var(--linkedin);
`;
const Post = styled(Text)`
    white-space: pre-wrap;
`;

export default LinkedinSummary;
