import * as yup from 'yup';
import getEpisodeEditFormSchema from '@app/organisms/EpisodeEditForm/schemas/episodeEditFormSchema';

const getMoveToDraftSchema = (intl) => {
    const baseSchema = getEpisodeEditFormSchema(intl);

    return baseSchema.shape({
        content: yup.reach(baseSchema, 'content').shape({
            name: yup.reach(baseSchema, 'content.name').notRequired(),
            slug: yup.reach(baseSchema, 'content.slug').notRequired(),
            htmlDescription: yup.string().notRequired(),
        }),
    });
};

export default getMoveToDraftSchema;
