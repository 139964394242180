import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';
import useAITargetKeywords from '@/queries/psoChecker/useAITargetKeywordsQuery.hook';
import { TooltipTrigger } from 'react-aria-components';
import { useParams } from 'react-router';
import styled from 'styled-components';
import useEpisodeTranscriptStatusQuery from '@/queries/episodeTranscript/useEpisodeTranscriptStatusQuery.hook';
import Alert from '@/components/ui/atoms/Alert';
import Text from '@/components/ui/atoms/Text';
import { FormattedMessage } from 'react-intl';
import useAISummaryQuery from '@/queries/ai/useAISummaryQuery.hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { EPISODE_TRANSCRIPT_STATUS } from '@/shared/config/constants';
import KeywordMetadata from '@/components/ui/KeywordMetadata';

interface KeywordSuggestionsProps {
    onSelect: (keyword: string) => void;
}

const KeywordSuggestions = ({ onSelect }: KeywordSuggestionsProps) => {
    const { episodeId } = useParams<{ episodeId: string }>();
    const transcriptionStatus = useEpisodeTranscriptStatusQuery(episodeId);
    const hasTranscription =
        transcriptionStatus.data &&
        transcriptionStatus.data.status === EPISODE_TRANSCRIPT_STATUS.DONE;
    const isTranscriptionInProgress =
        transcriptionStatus.data?.status === EPISODE_TRANSCRIPT_STATUS.IN_PROGRESS;
    const summary = useAISummaryQuery({
        episodeId,
        options: {
            enabled: !!episodeId && hasTranscription,
        },
    });
    const keywords = useAITargetKeywords({
        episodeId,
        enabled: hasTranscription && summary.isSuccess,
    });

    if (isTranscriptionInProgress || summary.isFetching) {
        return (
            <Alert
                icon={<SpinnerIcon icon={icon({ name: 'spinner-third', style: 'regular' })} spin />}
                variant="neutral"
                description={
                    <FormattedMessage defaultMessage="En cours de génération de suggestions pour votre mot-clé principal..." />
                }
            >
                <Text fontWeight="--fw-semibold">
                    <FormattedMessage defaultMessage="Veuillez patienter pendant la transcription de votre épisode." />
                </Text>
            </Alert>
        );
    }

    if (!hasTranscription) {
        return (
            <Alert variant="robot">
                <Text fontWeight="--fw-semibold">
                    <FormattedMessage defaultMessage="Générer la transcription de votre épisode pour obtenir des suggestions de mots-clés principaux !" />
                </Text>
            </Alert>
        );
    }

    if (keywords.data && hasTranscription && summary.isSuccess) {
        return (
            <List>
                {keywords.data.map((keyword) => (
                    <TooltipTrigger delay={0} closeDelay={0} key={keyword.label}>
                        <TooltipTriggerWrapper>
                            <Keyword onClick={() => onSelect(keyword.label.toLowerCase())}>
                                {keyword.label}
                            </Keyword>
                        </TooltipTriggerWrapper>
                        <Tooltip placement="top" variant="light">
                            <KeywordMetadata
                                keyword={keyword.label}
                                searchVolume={keyword.searchVolume}
                                difficultyScore={keyword.difficultyScore}
                            />
                        </Tooltip>
                    </TooltipTrigger>
                ))}
            </List>
        );
    }

    return null;
};

const List = styled.ul`
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    list-style: none;
    padding-left: 0;
`;
const Keyword = styled.li`
    padding: 0.5rem;
    border-radius: var(--r-s);
    background: var(--primary50);
    font-size: var(--fs-body-s);
    font-weight: var(--fw-semibold);
    color: var(--primary);
    cursor: pointer;
    text-transform: lowercase;
`;
const SpinnerIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--black);
`;

export default KeywordSuggestions;
