import { FormattedMessage } from 'react-intl';
import Text from '@ui/atoms/Text';
import DaysRangeSelect from './DaysRangeSelect';
import BestDayHour from './BestDayHour';
import PopularDays from './PopularDays';
import styled from 'styled-components';
import PopularHours from './PopularHours/PopularHours';
import Heatmap from './Heatmap';

export const LAUNCH_TIME_MESSAGE = {
    7: <FormattedMessage defaultMessage="7 derniers jours" />,
    15: <FormattedMessage defaultMessage="15 derniers jours" />,
    30: <FormattedMessage defaultMessage="30 derniers jours" />,
    90: <FormattedMessage defaultMessage="90 derniers jours" />,
    always: <FormattedMessage defaultMessage="depuis le début" />,
} as const;

const PeakTime = () => {
    return (
        <Wrapper>
            <Header>
                <Text variant="title" fontWeight="--fw-bold">
                    <FormattedMessage defaultMessage="PeakTime" />
                </Text>
                <DaysRangeSelect />
            </Header>
            <BestDayHour />
            <Heatmap />
            <PopularDays />
            <PopularHours />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`;
const Header = styled.div`
    display: flex;
    align-items: center;
    column-gap: 1rem;

    & > :first-child {
        flex-grow: 1;
    }
`;

export default PeakTime;
