import pandora from '@public/images/platforms/Pandora.png';
import ShortListFallback from '@app/fallbacks/ShortListFallback';
import BroadcastCard from '../../BroadcastCard';
import { LISTENING_PLATFORMS_NAMES, PANDORA } from '@/utils/constants';
import { useParams } from 'react-router-dom';
import useShowQuery from '@/queries/show/useShowQuery.hook';

const PandoraBroadcast = () => {
    const { showId } = useParams<{ showId: string }>();
    const { data: show, isLoading } = useShowQuery(showId);
    const url = show?.listeningLinks?.data.find((link) => link.key === PANDORA)?.url;

    if (isLoading) return <ShortListFallback />;

    return (
        <BroadcastCard
            platformKey={PANDORA}
            name={LISTENING_PLATFORMS_NAMES[PANDORA]}
            logo={pandora}
            url={url}
        />
    );
};

export default PandoraBroadcast;
