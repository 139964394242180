import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchPodcasts } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import episodeKeys from '@/queries/episode/episodeKeys';
import { z } from 'zod';

interface RawEpisode {
    tags: { data: string[] };
    files: { data: any[] };
}
export const EpisodeSchema = z.object({
    id: z.number(),
    guid: z.string(),
    youtubeVideo: z
        .object({
            data: z.object({
                id: z.number(),
                podcastId: z.number().optional(),
                state: z.enum(['pending', 'processing', 'done', 'error']).optional(),
                youtubeUrl: z.string().nullable().optional(),
                createdAt: z.string().optional(),
                updatedAt: z.string().optional(),
            }),
        })
        .optional(),
    waveformUrl: z.string().nullable(),
    updatedAt: z.string(),
    type: z.enum(['full', 'bonus', 'trailer']).optional(),
    tags: z
        .array(
            z.object({
                id: z.number(),
                slug: z.string(),
                name: z.string(),
            }),
        )
        .optional(),
    state: z.enum(['draft', 'blocked', 'scheduled', 'active']),
    smartlinkUrl: z.string(),
    slug: z.string().nullable(),
    siteUrl: z.string(),
    showId: z.number(),
    seasonId: z.number().nullable(),
    publishedAt: z.string().nullable(),
    publicId: z.string(),
    privacy: z.enum(['public', 'unlisted', 'private']),
    name: z.string(),
    isExplicit: z.boolean(),
    description: z.string().nullable(),
    htmlDescription: z.string().nullable(),
    hasCustomSlug: z.boolean(),
    files: z
        .array(
            z.object({
                duration: z.number().optional(),
                format: z.string().optional(),
                key: z.string().optional(),
                mimeType: z.string().optional(),
                originalName: z.string().optional(),
                size: z.number().optional(),
            }),
        )
        .optional(),
    duration: z.number().nullable(),
    downloadsCount: z.number(),
    createdAt: z.string(),
    canDownload: z.boolean(),
    audioUrl: z.string().nullable().optional(),
    audioUrlInternal: z.string().nullable().optional(),
    customUrl: z.string().nullable().optional(),
    imageUrl: z.string().nullable().optional(),
    soundcloudPublish: z.boolean().nullable().optional(),
    soundcloudUrl: z.string().nullable().optional(),
    useCustomUrl: z.boolean().nullable().optional(),
    videoUrls: z.array(z.string()).nullable().optional(),
    psoScore: z
        .object({
            data: z
                .object({
                    score: z.number(),
                })
                .nullable()
                .optional(),
        })
        .nullable()
        .optional(), // TODO Rename when API is updated
});
const EpisodesSchema = z.array(EpisodeSchema);
const EpisodePaginationSchema = z.object({
    currentPage: z.number(),
    totalPages: z.number(),
    total: z.number(),
    perPage: z.number(),
    count: z.number(),
});
const EpisodeResponseSchema = z.object({
    episodes: EpisodesSchema,
    pagination: EpisodePaginationSchema,
});
type InputProps = {
    showId: string;
    pagination: {
        page: number;
        perPage: number;
    };
    q?: string;
    filters?: {
        seasons: string[];
        statuses: string[];
    };
    config?: any;
};
type Key = QueryFunctionContext<ReturnType<typeof episodeKeys.listByShowId>>;
export type Episode = z.infer<typeof EpisodeSchema>;

const queryFn = async ({ queryKey: [{ showId, page, perPage, q, filters }] }: Key) => {
    const { data, meta }: { data: any; meta: any } = await fetchPodcasts(
        showId,
        q,
        filters.seasons,
        filters.statuses,
        page,
        perPage,
    );

    // TODO: Do camelcase transformation in Axios interceptor
    const { data: camelcaseData, meta: camelcaseMeta } = camelcaseKeys(
        { data, meta },
        { deep: true },
    );

    const formattedData = {
        episodes: camelcaseData.map((episode: RawEpisode) => ({
            ...episode,
            tags: episode.tags.data,
            files: episode.files.data,
        })),
        pagination: {
            currentPage: camelcaseMeta?.pagination?.currentPage || 0,
            totalPages: camelcaseMeta?.pagination?.totalPages || 0,
            total: camelcaseMeta?.pagination?.total || 0,
            perPage: camelcaseMeta?.pagination?.perPage || 0,
            count: camelcaseMeta?.pagination?.count || 0,
        },
    };

    return EpisodeResponseSchema.parse(formattedData);
};

const useEpisodesQuery = ({
    showId,
    pagination: { page, perPage } = { page: 1, perPage: 20 },
    q,
    filters,
    config,
}: InputProps) => {
    return useQuery({
        queryKey: episodeKeys.listByShowId(showId, { page, perPage, q, filters }),
        queryFn,
        enabled: config?.enabled ?? !!showId,
        refetchOnWindowFocus: config?.enabled ?? false,
        refetchInterval: config?.refetchInterval ?? false,
    });
};

export default useEpisodesQuery;
