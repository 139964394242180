import { createApi } from '../callApi';
import { uploadImage } from '../uploadImage';
const contentApi = createApi('CONTENT');

export const fetchShows = (channel) => {
    return contentApi({
        method: 'GET',
        target: '/v1/shows/granted',
        vars: { channel },
        query: { per_page: 200 },
    });
};
export const fetchMinimalShows = () => {
    return contentApi({
        method: 'GET',
        target: '/v1/shows/granted',
        query: { transformer: 'minimal', per_page: 200 },
    });
};
export const fetchShow = (show) => {
    return contentApi({ method: 'GET', target: `/v1/shows/${show}` });
};
export const importShow = (channel, body) => {
    return contentApi({ method: 'POST', target: `/v1/channels/${channel}/rss/imports`, body });
};
export const addShow = (channel, body) => {
    return contentApi({ method: 'POST', target: `/v1/channels/${channel}/shows`, body });
};
export const updateShow = (show, body) => {
    return contentApi({ method: 'PUT', target: `/v1/shows/${show}`, body });
};
export const deleteShow = (show) => {
    return contentApi({ method: 'DELETE', target: `/v1/shows/${show}` });
};
export function uploadShowImage(show, image) {
    return uploadImage(`/v1/shows/${show}/image`, image);
}
export function uploadShowLogo(show, logo, format) {
    const body = new FormData();
    body.append('file', logo);
    body.append('format', format);
    return contentApi({ method: 'POST', target: `/v1/shows/${show}/logo`, body });
}
export function uploadBackgroundCover(show, backgroundCover, format) {
    const body = new FormData();
    body.append('file', backgroundCover);
    body.append('format', format);
    return contentApi({ method: 'POST', target: `/v1/shows/${show}/logo`, body });
}
export const checkImport = (show, query) => {
    return contentApi({ method: 'GET', target: `/v1/shows/${show}/rss/import/check`, query });
};
export const checkImportRedirection = (showId) => {
    return contentApi({
        method: 'GET',
        target: `/v1/shows/${showId}/rss/import/check-redirection`,
    });
};
export const deleteShowImage = (show) => {
    return contentApi({ method: 'DELETE', target: `/v1/shows/${show}/image` });
};
export const deleteShowLogo = (show, format) => {
    return contentApi({ method: 'DELETE', target: `/v1/shows/${show}/logo`, body: { format } });
};
export const updateShowListeningLinks = (show, body) => {
    return contentApi({ method: 'PUT', target: `/v1/shows/${show}/listening_links`, body });
};
export const updateShowSocialLinks = (show, body) => {
    return contentApi({ method: 'PUT', target: `/v1/shows/${show}/social_links`, body });
};
export const deleteShowListeningLink = (show, platform) => {
    return contentApi({
        method: 'DELETE',
        target: `/v1/shows/${show}/listening_links/${platform}`,
    });
};
export const fetchShowListeningLinks = (show) => {
    return contentApi({ method: 'GET', target: `/v1/shows/${show}/listening_links` });
};
export const switchShowQuotaReached = (showFrom, showTo, body) => {
    return contentApi({
        method: 'POST',
        target: `/v1/shows/${showFrom}/switch/${showTo}`,
        body,
    });
};
