import { useRef } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import useQuery from '@/shared/hooks/useQuery';
import { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { InputTextController } from '@/components/ui/atoms/InputText';
import { SelectController } from '@ui/atoms/Select/Select.controller';
import { ComboBoxController } from '@ui/atoms/ComboBox/ComboBox.controller';
import ListBoxItem from '@ui/atoms/ListBoxItem';
import useLiveSearchFormSchema, { LiveSearchForm } from './useLiveSearchFormSchema.hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Button from '@/components/Button';
// @ts-ignore
import Spotify from '@public/images/platforms/Spotify.svg?url';
// @ts-ignore
import ApplePodcast from '@public/images/platforms/ApplePodcasts.svg?url';
import useAvailableStoresQuery from '@queries/pso/useAvailableStoresQuery.hook';
import type { Mode, Platform } from '@/api/pso/types';
import ReactCountryFlag from 'react-country-flag';
import usePsoQuery from '@/queries/pso/usePsoQuery.hook';
import { sendAmplitudeLogEvent } from '@/helpers';
import OutsideClickHandler from 'react-outside-click-handler';
import usePsoLiveSearchKeywordSuggestions, {
    KeywordSuggestion as TKeywordSuggestion,
} from '@/queries/pso/usePsoLiveSearchKeywordSuggestions.hook';
import styled from 'styled-components';
import useUserGeolocalizationQuery from '@/queries/user/useUserGeolocalizationQuery.hook';
import { APPLE_PODCASTS } from '@/shared/config/constants';

type Store = {
    name: string;
    alpha2Code: string;
};

type StoreWithImage = { id: string; name: string; image: React.ReactNode };

const Form = () => {
    const { showId } = useParams<{ showId: string }>();
    const history = useHistory();
    const location = useLocation();
    const query = useQuery();
    const [deduplicatedKeywordSuggestions, setDeduplicatedKeywordSuggestions] = useState<
        TKeywordSuggestion[]
    >([]);
    const [storeSearch, setStoreSearch] = useState('');
    const [suggestionsAreOpen, setSuggestionsAreOpen] = useState(false);
    let triggerRef = useRef(null);

    const intl = useIntl();
    const schema = useLiveSearchFormSchema();
    const availableStores = useAvailableStoresQuery();
    const psoQuery = usePsoQuery({ showId, enabled: true });

    const defaultKeyword: string = query.get('keyword') as string;
    const defaultPlatform: Platform = query.get('platform') as Platform;
    const defaultMode: Mode = query.get('mode') as Mode;
    const defaultStore: string = query.get('store') as string;

    const [search, setSearch] = useState(defaultKeyword ?? '');

    const psoStores = psoQuery?.data?.stores?.data.map(
        (store: { alpha2Code: string }) => store.alpha2Code,
    );

    const { control, handleSubmit, watch, setValue } = useForm<LiveSearchForm>({
        mode: 'onChange',
        resolver: zodResolver(schema),
        defaultValues: {
            keyword: defaultKeyword ?? '',
            platform: defaultPlatform ?? APPLE_PODCASTS,
            mode: defaultMode ?? 'show',
            store: defaultStore ?? psoStores[0] ?? 'US',
        },
    });
    const [modes, setModes] = useState([
        {
            id: 'popular',
            name: intl.formatMessage({ defaultMessage: 'Les plus populaires' }),
        },
        {
            id: 'show',
            name: intl.formatMessage({ defaultMessage: 'Emissions' }),
        },
        {
            id: 'episode',
            name: intl.formatMessage({ defaultMessage: 'Episodes' }),
        },
    ]);

    const platform = watch('platform');
    const mode = watch('mode');

    useEffect(() => {
        if (platform === 'spotify') {
            if (mode === 'popular') {
                setValue('mode', 'show');
            }
            setModes([
                {
                    id: 'show',
                    name: intl.formatMessage({ defaultMessage: 'Emissions' }),
                },
                {
                    id: 'episode',
                    name: intl.formatMessage({ defaultMessage: 'Episodes' }),
                },
            ]);
        } else {
            setModes([
                {
                    id: 'popular',
                    name: intl.formatMessage({ defaultMessage: 'Les plus populaires' }),
                },
                {
                    id: 'show',
                    name: intl.formatMessage({ defaultMessage: 'Emissions' }),
                },
                {
                    id: 'episode',
                    name: intl.formatMessage({ defaultMessage: 'Episodes' }),
                },
            ]);
        }
    }, [platform, mode]);

    useEffect(() => {
        if (!watch('store')) return;
        sendAmplitudeLogEvent('PSO Live Search Country Switched');
    }, [watch('store')]);

    const onSubmit = (formData: LiveSearchForm) => {
        setSuggestionsAreOpen(false);
        sendAmplitudeLogEvent('PSO Live Search Requested', {
            keyword: formData.keyword,
            platform: formData.platform,
            mode: formData.mode,
            store: formData.store,
        });
        history.push(
            `${location.pathname}?keyword=${formData.keyword}&platform=${formData.platform}&mode=${formData.mode}&store=${formData.store}`,
        );
    };

    const { data: userGeolocalization } = useUserGeolocalizationQuery();
    const { data: keywordSuggestions } = usePsoLiveSearchKeywordSuggestions({
        showId,
        search,
        store: watch('store'),
        userGeolocalization,
    });

    useEffect(() => {
        if (!keywordSuggestions) return;
        setDeduplicatedKeywordSuggestions(
            keywordSuggestions
                ?.filter((suggestion) => {
                    return suggestion.keyword.toLowerCase() !== search.toLowerCase();
                })
                .slice(0, 4),
        );
    }, [keywordSuggestions, search]);

    return (
        <FormElement onSubmit={handleSubmit(onSubmit)}>
            <FormSection>
                <SearchFormItemWrapper>
                    <OutsideClickHandler onOutsideClick={() => setSuggestionsAreOpen(false)}>
                        <SearchKeywordInputWrapper>
                            <InputTextController
                                ref={triggerRef}
                                control={control}
                                name="keyword"
                                isRequired={false}
                                type="search"
                                inputMode="search"
                                value={search}
                                onChange={(value: string) => setSearch(value)}
                                onFocus={() => setSuggestionsAreOpen(true)}
                                inputIcon={
                                    <FontAwesomeIcon
                                        icon={icon({ name: 'magnifying-glass', style: 'solid' })}
                                    />
                                }
                                onlyLowerCase={true}
                                forbiddenCharactersRegex={/[,]/g}
                                aria-label="Mot-clé"
                            />

                            {search.length > 0 && suggestionsAreOpen && (
                                <SuggestionsWrapper>
                                    <KeywordSuggestions>
                                        <KeywordSuggestion
                                            key="current"
                                            id="current"
                                            onClick={() => {
                                                setValue('keyword', search);
                                                setSuggestionsAreOpen(false);
                                            }}
                                        >
                                            {search}
                                        </KeywordSuggestion>
                                        {deduplicatedKeywordSuggestions?.map(
                                            (suggestion: TKeywordSuggestion) => (
                                                <KeywordSuggestion
                                                    key={suggestion.id}
                                                    id={suggestion.id}
                                                    onClick={() => {
                                                        setValue('keyword', suggestion.keyword);
                                                        setSearch(suggestion.keyword);
                                                        setSuggestionsAreOpen(false);
                                                    }}
                                                >
                                                    {suggestion.keyword}
                                                </KeywordSuggestion>
                                            ),
                                        )}
                                    </KeywordSuggestions>
                                </SuggestionsWrapper>
                            )}
                        </SearchKeywordInputWrapper>
                    </OutsideClickHandler>
                </SearchFormItemWrapper>
                <FormItemWrapper>
                    <SelectController
                        name="platform"
                        // @ts-ignore
                        control={control}
                        items={[
                            {
                                id: 'itunes',
                                name: 'Apple Podcasts',
                                image: <PlatformIcon src={ApplePodcast} alt="ApplePodcast" />,
                            },
                            {
                                id: 'spotify',
                                name: 'Spotify',
                                image: <PlatformIcon src={Spotify} alt="Spotify" />,
                            },
                        ]}
                    >
                        {(item: { id: string; name: string; image: React.ReactNode }) => (
                            <ListBoxItem {...item} aria-label={item.name} />
                        )}
                    </SelectController>
                </FormItemWrapper>
                <FormItemWrapper>
                    {/* @ts-ignore */}
                    <SelectController name="mode" control={control} items={modes}>
                        {(item: { id: string; name: string }) => (
                            <ListBoxItem {...item} aria-label={item.name} />
                        )}
                    </SelectController>
                </FormItemWrapper>
                <FormItemWrapper>
                    <ComboBoxController
                        name="store"
                        control={control}
                        onInputChange={(value: string) => setStoreSearch(value)}
                        items={availableStores?.data
                            ?.map((store: Store) => ({
                                id: store.alpha2Code,
                                name: store.name,
                                image: <ReactCountryFlag countryCode={store.alpha2Code} svg />,
                            }))
                            ?.sort((a: StoreWithImage, b: StoreWithImage) => {
                                const isAFavorite = psoStores.includes(a.id);
                                const isBFavorite = psoStores.includes(b.id);
                                if (isAFavorite && isBFavorite) {
                                    return a.name.localeCompare(b.name);
                                }
                                if (!isAFavorite && !isBFavorite) {
                                    return a.name.localeCompare(b.name);
                                }

                                return isAFavorite ? -1 : 1;
                            })
                            .filter((store: StoreWithImage) => {
                                if (
                                    availableStores?.data?.find((s: Store) => {
                                        return s.alpha2Code === watch('store');
                                    })
                                ) {
                                    return true;
                                }
                                return store.name.toLowerCase().includes(storeSearch.toLowerCase());
                            })}
                    >
                        {(item: StoreWithImage) => <ListBoxItem aria-label={item.name} {...item} />}
                    </ComboBoxController>
                </FormItemWrapper>
            </FormSection>
            <FormSection>
                <Button type="submit" isDisabled={watch('keyword').length === 0}>
                    <FormattedMessage defaultMessage="Rechercher" />
                </Button>
            </FormSection>
        </FormElement>
    );
};

const FormElement = styled.form`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;
const FormSection = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
`;
const FormItemWrapper = styled.div`
    flex: 1;
    flex-basis: 12.5rem;
    display: flex;
    gap: 1rem;
`;
const SearchFormItemWrapper = styled(FormItemWrapper)`
    flex: 1;
    flex-basis: 24rem;

    & > * {
        width: 100%;
    }
`;
const SearchKeywordInputWrapper = styled.div`
    position: relative;
    flex: 1;
`;
const PlatformIcon = styled.img`
    width: 1rem;
    height: 1rem;
`;
const SuggestionsWrapper = styled.div`
    position: absolute;
    top: calc(100% + 0.5rem);
    background-color: var(--white);
    box-shadow: var(--s-m);
    z-index: 2;
    width: 100%;
    border-radius: var(--r-s);
    padding: 0.5rem;
`;
const KeywordSuggestions = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    list-style: none;
    margin: 0;
    padding: 0;
`;
const KeywordSuggestion = styled.li`
    transition-duration: 0.2s;
    padding: 0.5rem;
    width: 100%;
    border-radius: var(--r-s);
    cursor: pointer;

    &:hover {
        background-color: var(--neutral50);
    }
`;

export default Form;
