import { useParams, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import RouterLink from '@/components/Link/RouterLink';
import Sidebar from '@/shared/components/Sidebar';
import SidebarItem from '@/shared/components/Sidebar/Item/Item';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useGuard } from '@/shared/hooks/useGuard.hook';
import { PRICING } from '@/shared/config/pricing';
import styled from 'styled-components';
import useQuery from '@/shared/hooks/useQuery';
import { buildQuery } from '@/api/query';

interface NavProps {
    breaksAt?: string;
}

const Nav = ({ breaksAt }: NavProps) => {
    const location = useLocation();
    const currentSegment = location.pathname.split('/').pop();
    const { showId } = useParams<{ showId: string; tab: string }>();
    const basePath = `/app/show/${showId}/stats`;

    const query = useQuery();
    const campaignId = query.get('campaignId');
    const episode = query.get('episode');
    const builtQuery = buildQuery({ campaignId, episode });

    const guardRequiresSupersonic = useGuard({
        disallowedPlans: [PRICING.FREEMIUM, PRICING.LAUNCH, PRICING.BOOST],
    });
    const guardRequiresBoost = useGuard({
        disallowedPlans: [PRICING.FREEMIUM, PRICING.LAUNCH],
    });

    return (
        <Sidebar breaksAt={breaksAt}>
            <CustomSidebarItem isActive={currentSegment === 'listeners'} breaksAt={breaksAt}>
                <NavRouterLink to={`${basePath}/listeners${builtQuery}`}>
                    <FontAwesomeIcon icon={icon({ name: 'play', style: 'solid' })} />
                    <FormattedMessage defaultMessage="Écoutes" />
                </NavRouterLink>
            </CustomSidebarItem>
            <CustomSidebarItem isActive={currentSegment === 'sources'} breaksAt={breaksAt}>
                <NavRouterLink to={`${basePath}/sources${builtQuery}`}>
                    <FontAwesomeIcon icon={icon({ name: 'laptop-mobile', style: 'solid' })} />
                    <FormattedMessage defaultMessage="Sources" />
                </NavRouterLink>
            </CustomSidebarItem>
            <CustomSidebarItem isActive={currentSegment === 'geoloc'} breaksAt={breaksAt}>
                <NavRouterLink to={`${basePath}/geoloc${builtQuery}`}>
                    <FontAwesomeIcon icon={icon({ name: 'earth-europe', style: 'solid' })} />
                    <FormattedMessage defaultMessage="Localisation" />
                </NavRouterLink>
            </CustomSidebarItem>
            <CustomSidebarItem isActive={currentSegment === 'audience'} breaksAt={breaksAt}>
                <NavRouterLink to={`${basePath}/audience`}>
                    <FontAwesomeIcon icon={icon({ name: 'user-group-simple', style: 'solid' })} />
                    <FormattedMessage defaultMessage="Audience" />
                    {!guardRequiresSupersonic.allow && (
                        <DiamondIcon icon={icon({ name: 'gem', style: 'duotone' })} />
                    )}
                </NavRouterLink>
            </CustomSidebarItem>
            <CustomSidebarItem isActive={currentSegment === 'peaktime'} breaksAt={breaksAt}>
                <NavRouterLink to={`${basePath}/peaktime`}>
                    <FontAwesomeIcon icon={icon({ name: 'calendar-range', style: 'solid' })} />
                    <FormattedMessage defaultMessage="PeakTime" />
                    {!guardRequiresBoost.allow && (
                        <DiamondIcon icon={icon({ name: 'gem', style: 'duotone' })} />
                    )}
                </NavRouterLink>
            </CustomSidebarItem>
            <CustomSidebarItem isActive={currentSegment === 'apple-podcasts'} breaksAt={breaksAt}>
                <NavRouterLink to={`${basePath}/apple-podcasts`}>
                    <FontAwesomeIcon icon={icon({ name: 'podcast', style: 'solid' })} />
                    <FormattedMessage defaultMessage="Apple Podcasts" />
                    {!guardRequiresBoost.allow && (
                        <DiamondIcon icon={icon({ name: 'gem', style: 'duotone' })} />
                    )}
                </NavRouterLink>
            </CustomSidebarItem>
        </Sidebar>
    );
};

const NavRouterLink = styled(RouterLink)`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: inherit;
`;
const DiamondIcon = styled(FontAwesomeIcon)`
    color: var(--primary);
`;
const CustomSidebarItem = styled(SidebarItem)`
    &:not([data-active='true']) svg {
        color: var(--neutral);
    }
    &:hover svg {
        color: var(--primary);
    }

    & svg${DiamondIcon} {
        color: var(--primary);
    }
`;

export default Nav;
