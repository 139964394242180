import { useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useUserStore from '@/utils/hooks/useUserStore';
import { observer } from 'mobx-react';
import { InputTextController } from '@ui/atoms/InputText';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import useEditPasswordFormSchema from './useEditPasswordFormSchema.hook';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@ui/atoms/Button';
import { useModalToastQueue } from '@/utils/hooks/useModalToastQueue.hook';

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    height: 100%;
`;
const ButtonsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    margin-top: auto;

    & > :first-child,
    > :last-child {
        align-self: stretch;
    }

    ${(p) => p.theme.mediaQueries.tabletAndUp} {
        flex-direction: row;
        justify-content: flex-end;
        column-gap: 0.5rem;
        margin-top: 0.5rem;

        & > :first-child,
        > :last-child {
            align-self: initial;
        }

        & > :first-child {
            order: 1;
        }
    }
`;

const EditPasswordForm = ({ onAfterSubmit }) => {
    const intl = useIntl();
    const { updatePassword } = useUserStore();
    const isLoading = updatePassword.pending;
    const schema = useEditPasswordFormSchema();
    const { control, handleSubmit, formState, clearErrors } = useForm({
        mode: 'onChange',
        resolver: yupResolver(schema),
    });
    const ref = useRef(null);
    const toast = useModalToastQueue();

    const onSubmit = (formData) => {
        updatePassword(formData)
            .then((_) => {
                toast.success(
                    <FormattedMessage defaultMessage="Votre mot de passe a bien été modifié 👌" />,
                );
                onAfterSubmit();
            })
            .catch(() => {
                toast.alert(
                    <FormattedMessage defaultMessage="Oups, il semblerait qu'une erreur soit survenue lors de la modification de votre mot de passe" />,
                );
            });
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <InputTextController
                ref={ref}
                control={control}
                name="current_password"
                type="password"
                label={<FormattedMessage defaultMessage="Mot de passe actuel" />}
                placeholder={intl.formatMessage({ defaultMessage: '8 caractères minimum' })}
                errorMessage={formState.errors.current_password?.message}
                onBlur={() => clearErrors('current_password')}
                isRequired
                autoFocus
            />
            <InputTextController
                control={control}
                name="new_password"
                type="password"
                label={<FormattedMessage defaultMessage="Nouveau mot de passe" />}
                placeholder={intl.formatMessage({ defaultMessage: '8 caractères minimum' })}
                errorMessage={formState.errors.new_password?.message}
                onBlur={() => clearErrors('new_password')}
                isRequired
            />
            <InputTextController
                control={control}
                name="new_password_confirmation"
                type="password"
                label={<FormattedMessage defaultMessage="Confirmation du nouveau mot de passe" />}
                placeholder={intl.formatMessage({ defaultMessage: '8 caractères minimum' })}
                errorMessage={formState.errors.new_password_confirmation?.message}
                onBlur={() => clearErrors('new_password_confirmation')}
                isRequired
            />
            <ButtonsWrapper>
                <Button
                    type="submit"
                    isLoading={isLoading}
                    isDisabled={!formState.isValid || isLoading}
                >
                    <FormattedMessage defaultMessage="Enregistrer" />
                </Button>
                <Button variant="secondary" onPress={onAfterSubmit}>
                    <FormattedMessage defaultMessage="Annuler" />
                </Button>
            </ButtonsWrapper>
        </Form>
    );
};

export default observer(EditPasswordForm);
