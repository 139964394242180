import { useState } from 'react';
import Text from '@/components/ui/atoms/Text';
import { ToggleController } from '@/components/ui/atoms/Toggle';
import Stack from '@/components/ui/layout/Stack';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { CampaignFormSchema } from '../../useFormSchema';
import Button from '@/components/Button';
import EpisodesModal from './EpisodesModal';
import styled from 'styled-components';
import Alert from '@/components/ui/atoms/Alert';

const Episodes = () => {
    const { control, watch } = useFormContext<CampaignFormSchema>();
    const [isEpisodesModalOpen, setIsEpisodesModalOpen] = useState(false);
    const episodes = watch('episodes.episodes');

    return (
        <>
            <Stack $gap="2rem">
                <Stack $gap="0.5rem">
                    <Text fontWeight="--fw-semibold">
                        <FormattedMessage defaultMessage="Appliquer la campagne automatiquement" />
                    </Text>
                    <ToggleController name="episodes.autoOnNewPodcasts" control={control}>
                        <FormattedMessage defaultMessage=" La campagne s'appliquera automatiquement aux nouveaux épisodes publiés." />
                    </ToggleController>
                </Stack>
                <SelectEpisodesButton variant="ghost" onPress={() => setIsEpisodesModalOpen(true)}>
                    {episodes.length > 0 ? (
                        <FormattedMessage
                            defaultMessage="{count, plural, one {# épisode sélectionné} other {# épisodes sélectionnés}}"
                            values={{ count: episodes.length }}
                        />
                    ) : (
                        <FormattedMessage defaultMessage="Sélectionner les épisodes" />
                    )}
                </SelectEpisodesButton>
                <Alert variant="info" defaultIcon>
                    <Text fontWeight="--fw-semibold">
                        <FormattedMessage defaultMessage="Si un même épisode est sélectionné dans plusieurs campagnes en ligne simultanément, une seule campagne sera diffusée aléatoirement lors de la lecture par les auditeurs." />
                    </Text>
                </Alert>
            </Stack>
            <EpisodesModal isOpen={isEpisodesModalOpen} onOpenChange={setIsEpisodesModalOpen} />
        </>
    );
};

const SelectEpisodesButton = styled(Button)`
    align-self: flex-start;
    padding: 0;
`;

export default Episodes;
