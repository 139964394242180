import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { useParams } from 'react-router';
import usePsoKeywordsRankingQuery, {
    KeywordsRanking,
} from '@queries/psoKeywordsRanking/usePsoKeywordsRankingQuery.hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Datatable from './Datatable';
import type { Platform } from '@/api/pso/types';
import { getCountryISO3 } from '@/shared/services/countryService';
import NoBroadcast from './NoBroadcast';

interface ResultsProps {
    searchParams: {
        platform: Platform;
        store: string;
    };
}

const Results = ({ searchParams }: ResultsProps) => {
    const { showId } = useParams<{ showId: string }>();
    const { platform, store } = searchParams;
    const enabled = !!showId && !!platform && !!store;
    const {
        data: keywordsRankingResults,
        isLoading,
        isError,
    } = usePsoKeywordsRankingQuery(
        {
            showId,
            platform,
            store: getCountryISO3(store),
        },
        { enabled },
    );

    if (enabled && isLoading) {
        return (
            <NoResultsWrapper>
                <LoaderWrapper>
                    <FontAwesomeIcon icon={icon({ name: 'spinner-third', style: 'solid' })} spin />
                </LoaderWrapper>
            </NoResultsWrapper>
        );
    }

    if (isError) {
        return <NoBroadcast platform={platform} />;
    }

    if (!keywordsRankingResults || keywordsRankingResults?.length === 0) {
        return (
            <NoResultsWrapper>
                <DefaultStateWrapper>
                    <SearchIcon icon={icon({ name: 'zzz', style: 'solid' })} />
                    <DefaultStateTitleWrapper>
                        <DefaultStateTitle>
                            <FormattedMessage defaultMessage="Aucun résultat" />
                        </DefaultStateTitle>
                        <DefaultStateTitleSubtitle>
                            <FormattedMessage defaultMessage="Il semble que votre podcast ne remonte sur aucun mot-clé pour le moment. Ne vous découragez pas, les résultats finiront par arriver !" />
                        </DefaultStateTitleSubtitle>
                    </DefaultStateTitleWrapper>
                </DefaultStateWrapper>
            </NoResultsWrapper>
        );
    }

    if (keywordsRankingResults) {
        return (
            <ResultsWrapper>
                <Datatable rows={keywordsRankingResults as KeywordsRanking} />
            </ResultsWrapper>
        );
    }

    return null;
};

const ResultsWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;
const NoResultsWrapper = styled(ResultsWrapper)`
    padding: 4rem;
`;
const DefaultStateWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    color: var(--neutral500);
    width: 100%;
    max-width: 24rem;
    margin-inline: auto;
    padding-block: 2rem;
`;
const SearchIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
    font-size: 1.5rem;
`;
const DefaultStateTitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.25rem;
`;
const DefaultStateTitle = styled.h2`
    font-weight: var(--fw-semibold);
    font-size: var(--fs-body-s);
`;
const DefaultStateTitleSubtitle = styled.h3`
    font-weight: var(--fw-normal);
    font-size: var(--fs-body-s);
    text-align: center;
`;
const LoaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding-block: 2rem;
    color: var(--primary);
`;

export default Results;
