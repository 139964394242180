import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { useResponsive } from '@/shared/hooks/useResponsive';
import { connect } from '@/components/legacy/connect';
import ClipFormatsGrid from './ClipFormatsGrid';
import Stack from '@ui/layout/Stack';
import ClipQuota from '@/components/unorganized/ClipQuota';
import Cluster from '@ui/layout/Cluster';
import NewClipRestrictedModal from './NewClipRestrictedModal';
import { useSwitch } from '@/shared/hooks/useSwitch';
import Link from '@ui/atoms/Link';
import SelectEpisodeForClipModal from './SelectEpisodeForClipModal';
import ClipEditWizardModal from './EpisodeEditClip/ClipEditWizardModal';
import Text from '@ui/atoms/Text';
import OnlyDesktop from '@public/images/illustration_only_desktop.png';
import EmptyState from '@ui/molecules/EmptyState';
import ZebraIllustration from '@ui/atoms/ZebraIllustration';
import microphoneEmoji from '@public/images/emoji/studio_microphone.png';
import noEpisodeMainIllustration from '@public/images/empty-state/illustration/video_clips.mp4';
import noClipHeadIllustration from '@public/images/empty-state/video_clips.svg?url';
import { IntercomAPI } from 'react-intercom';
import { useOverlayTriggerState } from 'react-stately';
import Alert from '@ui/atoms/Alert';
import Button from '@ui/atoms/Button';
import IconAdd from '@ui/icons/IconAdd';
import { USER_ROLES } from '@/shared/config/constants';
import { useGuard } from '@/shared/hooks/useGuard.hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { extractCssVariable } from '@/shared/utils/cssVariable';
import { GUARD_DISALLOW_REASONS } from '@/shared/config/constants';
import styled from 'styled-components';
import { useAmplitudeLogEvent } from '@/shared/hooks/useAmplitude';
import { useModalToastQueue } from '@/shared/hooks/useModalToastQueue.hook';
import useShowQuery from '@queries/show/useShowQuery.hook';
import useShowClipFormatsQuery from '@/queries/clipFormat/useShowClipFormatsQuery.hook';
import ServerPagination from '../ui/ServerPagination';

const Card = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 0.75rem;
    padding: 1.5rem;
    text-align: center;
`;

const Illustration = styled.img`
    width: 6rem;
    height: auto;
`;

const enhance = connect(({ routerStore, clipStore, podcastStore }) => ({
    fetchEpisode: podcastStore.fetchPodcast,
    fetchClip: clipStore.fetchClip,
    fetchClipQuota: clipStore.fetchClipQuota,
    clipQuota: clipStore.quota,
    episodeLink: routerStore.link('menu.episodes'),
}));

const ShowClips = ({ fetchEpisode, fetchClip, fetchClipQuota, clipQuota, episodeLink }) => {
    useAmplitudeLogEvent('Video Clip Tab Viewed');
    const { allow, reason } = useGuard({
        disallowedUserRoles: [USER_ROLES.VIEWER],
    });
    const { isMobile } = useResponsive();
    const intl = useIntl();
    const { showId } = useParams();
    const [clipWizardConfig, setClipWizardConfig] = useState({ episodeId: null, clip: null });
    let clipEditWizardModalState = useOverlayTriggerState({});
    const [
        isNewClipRestrictedModalOpened,
        openNewClipRestrictedModal,
        closeNewClipRestrictedModal,
    ] = useSwitch(false);
    const toast = useModalToastQueue();
    const { data: show } = useShowQuery(showId);
    const showHasEpisodes = show?.podcastsCount > 0;
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(16);
    const clipFormats = useShowClipFormatsQuery({
        showId,
        pagination: {
            page,
            perPage,
        },
    });
    let selectEpisodeForClipModalState = useOverlayTriggerState({});

    const disallowMessageLookup = {
        [GUARD_DISALLOW_REASONS.INSUFFICIENT_ROLE]: (
            <FormattedMessage defaultMessage="Vous devez être éditeur ou administrateur de l'émission pour créer un clip vidéo." />
        ),
    };

    useEffect(async () => {
        fetchClipQuota(showId);
    }, [showId]);

    useEffect(() => {
        IntercomAPI('update', {
            hide_default_launcher: clipEditWizardModalState.isOpen,
        });
    }, [clipEditWizardModalState.isOpen]);

    const isLoading = showHasEpisodes.pending || clipFormats.isLoading;

    // Fallback to 0 if quota is missing or computation from quota is weird
    const remainingClipsCount = useMemo(
        () => (clipQuota ? Math.max(Math.floor(clipQuota.maximum - clipQuota.total), 0) : 0),
        [clipQuota],
    );

    const newClipHandler = () => {
        if (remainingClipsCount > 0) {
            selectEpisodeForClipModalState.open();
        } else {
            openNewClipRestrictedModal();
        }
    };

    const editClipFormatHandler = async (episodeId, clipFormat) => {
        const clip = await fetchClip(clipFormat.clip.id);
        setClipWizardConfig({ episodeId, clip });
        await fetchEpisode(episodeId);
        clipEditWizardModalState.open();
    };

    const editClipHandler = async (episodeId, clip = null) => {
        setClipWizardConfig({ episodeId, clip });
        await fetchEpisode(episodeId);
        clipEditWizardModalState.open();
    };

    const closeEditWizardModalHandler = () => {
        clipEditWizardModalState.close();
    };

    const clipGenerateHandler = () => {
        fetchClipQuota(showId);
        toast.success(<FormattedMessage defaultMessage="Le clip est en cours de génération." />);
    };

    if (isMobile)
        return (
            <Card>
                <Illustration
                    src={OnlyDesktop}
                    alt={intl.formatMessage({
                        defaultMessage: 'Seulement disponible sur ordinateur',
                    })}
                />

                <Text variant="heading" fontWeight="--fw-bold">
                    <FormattedMessage defaultMessage="Bientôt disponible sur mobile..." />
                </Text>
                <Text variant="subheading" color="--neutral500">
                    <FormattedMessage defaultMessage="Les clips vidéos peuvent uniquement être crées et gérés sur ordinateur pour le moment." />
                </Text>
            </Card>
        );

    if (isLoading) {
        return (
            <ShowClipsWrapper>
                <ClipFormatsGrid isLoading={true} />
            </ShowClipsWrapper>
        );
    }

    if (!showHasEpisodes) {
        return (
            <EmptyState
                illustration={noEpisodeMainIllustration}
                illustrationType="video"
                illustrationAlign="right"
                illustrationBackground="--gradient-dawn"
            >
                <HeadIllustrationWrapper>
                    <ZebraIllustration foreground={microphoneEmoji} />
                </HeadIllustrationWrapper>
                <LabelWrapper>
                    <Text variant="heading" fontWeight="--fw-bold">
                        <FormattedMessage defaultMessage="Commencez par ajouter votre premier épisode" />
                    </Text>
                    <Text variant="body" fontWeight="--fw-normal" color="--neutral500">
                        <FormattedMessage
                            defaultMessage="Débloquez la création de clips vidéo en <link>créant votre premier épisode</link> 🔥"
                            values={{
                                link: (chunks) => (
                                    <Text
                                        as={Link}
                                        to={episodeLink}
                                        color="--primary"
                                        fontWeight="--fw-bold"
                                    >
                                        {chunks}
                                    </Text>
                                ),
                            }}
                        />
                    </Text>
                </LabelWrapper>
            </EmptyState>
        );
    }

    if (!clipFormats.data || clipFormats.data?.clipFormats?.length === 0) {
        return (
            <>
                <EmptyState
                    illustration={noEpisodeMainIllustration}
                    illustrationType="video"
                    illustrationAlign="right"
                    illustrationBackground="--gradient-dawn"
                >
                    <HeadIllustrationWrapper>
                        <HeadIllustration src={noClipHeadIllustration} />
                    </HeadIllustrationWrapper>
                    <LabelWrapper>
                        <Text variant="heading" fontWeight="--fw-bold">
                            <FormattedMessage defaultMessage="Créez votre premier clip vidéo" />
                        </Text>
                        <Text variant="body" fontWeight="--fw-normal" color="--neutral500">
                            <FormattedMessage defaultMessage="Créez une courte vidéo contenant un extrait audio de votre épisode et publiez la sur vos réseaux sociaux pour maximiser votre visibilité 🎬" />
                        </Text>
                    </LabelWrapper>
                    {!allow && (
                        <Alert
                            variant="alert"
                            icon={
                                <FontAwesomeIcon
                                    icon={icon({ name: 'circle-info', style: 'solid' })}
                                    color={extractCssVariable('--alert500')}
                                    size="lg"
                                />
                            }
                        >
                            {disallowMessageLookup[reason] ?? (
                                <FormattedMessage defaultMessage="Une erreur est survenue" />
                            )}
                        </Alert>
                    )}
                    <Button startIcon={<IconAdd />} isDisabled={!allow} onClick={newClipHandler}>
                        <FormattedMessage defaultMessage="Créer un clip vidéo" />
                    </Button>
                </EmptyState>
                <NewClipRestrictedModal
                    isOpened={isNewClipRestrictedModalOpened}
                    onClose={closeNewClipRestrictedModal}
                    monthlyClips={clipQuota.maximum ?? 0}
                />
                {selectEpisodeForClipModalState.isOpen && (
                    <SelectEpisodeForClipModal
                        isOpen={selectEpisodeForClipModalState.isOpen}
                        onClose={selectEpisodeForClipModalState.close}
                        onSubmit={editClipHandler}
                    />
                )}
                <ClipEditWizardModal
                    {...clipWizardConfig}
                    isOpened={clipEditWizardModalState.isOpen}
                    onClose={closeEditWizardModalHandler}
                    onClipGenerate={clipGenerateHandler}
                />
            </>
        );
    }

    return (
        <>
            <Stack $gap="2rem">
                <ShowClipsWrapper>
                    <Stack $gap="2rem">
                        <Cluster
                            $gap="2rem"
                            $rowGap="1rem"
                            $align="center"
                            $justify="space-between"
                        >
                            <Text variant="subheading" fontWeight="--fw-semibold">
                                <FormattedMessage
                                    defaultMessage="{clipsCount, plural, =0 {Aucun clip} one {# clip vidéo} other {# clips vidéo}}"
                                    values={{ clipsCount: clipFormats.data?.pagination?.total }}
                                />
                            </Text>
                            <Cluster $gap="2rem" $rowGap="1rem" $align="center" $justify="flex-end">
                                <ClipQuota
                                    remainingClipsCount={remainingClipsCount}
                                    quota={clipQuota}
                                    isLoading={fetchClipQuota.pending}
                                />
                                <Button
                                    startIcon={<IconAdd />}
                                    isDisabled={!allow}
                                    onClick={newClipHandler}
                                >
                                    <FormattedMessage defaultMessage="Créer un clip" />
                                </Button>
                            </Cluster>
                        </Cluster>
                        <ClipFormatsGrid
                            clipFormats={clipFormats.data?.clipFormats}
                            onEditClipFormat={editClipFormatHandler}
                        />
                    </Stack>
                </ShowClipsWrapper>
                {clipFormats.data?.pagination &&
                    clipFormats.data?.clipFormats.length > 0 &&
                    clipFormats.data?.pagination.totalPages > 1 && (
                        <ServerPagination
                            pagination={clipFormats.data?.pagination}
                            onGoToPage={(page) => setPage(page)}
                            onGoToFirstPage={() => setPage(1)}
                            onGoToLastPage={() => setPage(clipFormats.data?.pagination.totalPages)}
                            onGoToPreviousPage={() =>
                                setPage(clipFormats.data?.pagination.currentPage - 1)
                            }
                            onGoToNextPage={() =>
                                setPage(clipFormats.data?.pagination.currentPage + 1)
                            }
                            onChangePerPage={(perPage) => setPerPage(perPage)}
                            options={{
                                goToFirstAndLastPageButtons: false,
                                showPaginationInfo: true,
                                showPageSelector: false,
                            }}
                        />
                    )}
            </Stack>
            <NewClipRestrictedModal
                isOpened={isNewClipRestrictedModalOpened}
                onClose={closeNewClipRestrictedModal}
                monthlyClips={clipQuota.maximum ?? 0}
            />
            {selectEpisodeForClipModalState.isOpen && (
                <SelectEpisodeForClipModal
                    isOpen={selectEpisodeForClipModalState.isOpen}
                    onClose={selectEpisodeForClipModalState.close}
                    onSubmit={editClipHandler}
                />
            )}
            <ClipEditWizardModal
                {...clipWizardConfig}
                isOpened={clipEditWizardModalState.isOpen}
                onClose={closeEditWizardModalHandler}
                onClipGenerate={clipGenerateHandler}
            />
        </>
    );
};

const HeadIllustrationWrapper = styled.div`
    width: 200px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const HeadIllustration = styled.img`
    min-width: 248px;
`;
const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    & > * {
        text-align: center;
    }
`;
const ShowClipsWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: 1rem;
`;

export default enhance(ShowClips);
