import Button from '@/components/Button';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import NonHostingEpisodeSummaryModal from '../NonHostingEpisodeSummaryModal';

interface SummarizeButtonProps {
    className?: string;
}

const SummarizeButton = ({ className }: SummarizeButtonProps) => {
    const [isSummaryModalOpen, setIsSummaryModalOpen] = useState(false);

    return (
        <>
            <Button
                onPress={() => setIsSummaryModalOpen(true)}
                className={className}
                startIcon={
                    <SummaryIcon
                        icon={icon({
                            name: 'arrow-up-from-line',
                            style: 'solid',
                        })}
                    />
                }
            >
                <FormattedMessage defaultMessage="Aperçu" id="optimize.summary" />
            </Button>
            <NonHostingEpisodeSummaryModal
                isOpen={isSummaryModalOpen}
                onOpenChange={setIsSummaryModalOpen}
            />
        </>
    );
};

const SummaryIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: var(--white);
`;

export default SummarizeButton;
