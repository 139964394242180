import PropTypes from 'prop-types';
import { SPOTIFY, APPLE_PODCASTS, LISTENING_PLATFORMS_NAMES } from '@/utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import SpotifyIcon from '@public/images/platforms/Spotify.svg?url';
import Text from '@ui/atoms/Text';
import ApplePodcastsIcon from '@public/images/platforms/ApplePodcasts.svg?url';
import styled from 'styled-components';

const LegendItem = ({ platform, position, trend, rankDiff }) => {
    return (
        <LegendItemWrapper>
            <PlatformIconWrapper platform={platform}>
                <PlatformIcon src={platform === SPOTIFY ? SpotifyIcon : ApplePodcastsIcon} />
            </PlatformIconWrapper>
            <Right>
                <PlatformName>{LISTENING_PLATFORMS_NAMES[platform]}</PlatformName>
                {position || position === 0 ? (
                    <Trend>
                        <Position>{position}</Position>
                        <TrendInnerWrapper trend={trend}>
                            {['up', 'new'].indexOf(trend) > -1 ? (
                                <TrendIcon
                                    icon={icon({ name: 'arrow-trend-up', style: 'solid' })}
                                    trend={trend}
                                    color="inherit"
                                />
                            ) : ['down', 'exited'].indexOf(trend) > -1 ? (
                                <TrendIcon
                                    icon={icon({ name: 'arrow-trend-down', style: 'solid' })}
                                    trend={trend}
                                    color="inherit"
                                />
                            ) : null}
                            <RankDiff>
                                {['up', 'new'].indexOf(trend) > -1
                                    ? `+${rankDiff}`
                                    : ['down', 'exited'].indexOf(trend) > -1
                                    ? `${rankDiff}`
                                    : null}
                            </RankDiff>
                        </TrendInnerWrapper>
                    </Trend>
                ) : (
                    <EmptyState />
                )}
            </Right>
        </LegendItemWrapper>
    );
};

const LegendItemWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;
const PlatformIconWrapper = styled.div`
    width: 2.5rem;
    height: 2.5rem;
    border-radius: var(--r-s);
    background-color: ${({ platform }) =>
        platform === SPOTIFY ? `var(--${SPOTIFY}50)` : `var(--${APPLE_PODCASTS}50)`};
    display: flex;
    align-items: center;
    justify-content: center;
`;
const PlatformIcon = styled.img`
    width: 1rem;
    height: auto;
`;
const Right = styled.div`
    display: flex;
    flex-direction: column;
`;
const PlatformName = styled(Text)`
    color: var(--neutral500);
`;
const Trend = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;
const Position = styled.div`
    font-weight: var(--fw-bold);
`;
const TrendInnerWrapper = styled.div`
    color: ${({ trend }) =>
        ['up', 'new'].indexOf(trend) > -1
            ? 'var(--success)'
            : ['down', 'exited'].indexOf(trend) > -1
            ? 'var(--alert)'
            : 'var(--neutral500)'};
    display: flex;
    align-items: center;
    gap: 0.25rem;
`;
const TrendIcon = styled(FontAwesomeIcon)``;
const RankDiff = styled(Text)`
    font-weight: var(--fw-semibold);
`;
const EmptyState = styled.div`
    width: 1rem;
    height: 2px;
    background-color: var(--neutral500);
    border-radius: var(--r-full);
    margin-block: 0.5rem;
`;

LegendItem.propTypes = {
    platform: PropTypes.oneOf([SPOTIFY, APPLE_PODCASTS]),
    position: PropTypes.number,
    trend: PropTypes.oneOf(['up', 'down', 'neutral']),
    rankDiff: PropTypes.number,
};

export default LegendItem;
