import { FormattedMessage } from 'react-intl';
import { useCopy } from '@/shared/hooks/useCopy';
import Text from '@ui/atoms/Text';
import Button from '@/components/Button';
import ExternalLink from '@/components/Link/ExternalLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

export const OpenLinkButton = ({ link, isActive = true }) => {
    if (!link || !isActive) return null;
    return (
        <ExternalLink
            href={link}
            target="_blank"
            startIcon={
                <FontAwesomeIcon
                    icon={icon({ name: 'arrow-up-right-from-square', style: 'solid' })}
                />
            }
            variant="button-tertiary"
        >
            <FormattedMessage defaultMessage="Ouvrir" />
        </ExternalLink>
    );
};

export const CopyLinkButton = ({ link }) => {
    const [copied, onCopy] = useCopy();
    if (!link) return null;
    return (
        <Button
            variant="tertiary"
            onPress={() => onCopy(link)}
            startIcon={<FontAwesomeIcon icon={icon({ name: 'copy', style: 'solid' })} />}
        >
            <Text fontWeight="--fw-semibold">
                {copied ? (
                    <FormattedMessage defaultMessage={'Lien copié'} />
                ) : (
                    <FormattedMessage defaultMessage="Copier le lien" />
                )}
            </Text>
        </Button>
    );
};
