import Text from '@/components/ui/atoms/Text';
import styled from 'styled-components';

interface ChannelGuestsEditPermissionShowCellProps {
    name: string;
    image: string | null;
}

const ChannelGuestsEditPermissionShowCell = ({
    name,
    image,
}: ChannelGuestsEditPermissionShowCellProps) => {
    return (
        <Cell>
            <Image src={image ?? ''} alt={name} />
            <Text numberOfLines={1} fontWeight="--fw-semibold">
                {name}
            </Text>
        </Cell>
    );
};

const Image = styled.img`
    width: 2rem;
    height: 2rem;
    border-radius: var(--r-s);
    object-fit: cover;
    display: none;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: block;
    }
`;
const Cell = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.75rem;
`;

export default ChannelGuestsEditPermissionShowCell;
