import { FormattedMessage } from 'react-intl';
import DeprecatedIcon from '@ui/atoms/DeprecatedIcon';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import { useResponsive } from '@/utils/hooks/useResponsive';
import { useSwitch } from '@hooks/useSwitch';
import folder from '@public/icons/folder.svg';
import add from '@public/icons/add.svg';
import AddEpisodeToPlaylistModal from './AddEpisodeToPlaylistModal';
import DeprecatedPaper from '../../ui/atoms/DeprecatedPaper';
import ButtonAccess from '../atoms/ButtonAccess';
import HideFor from '../atoms/HideFor';
import { STATUS_SHOWS } from '@/utils/statusShows';
import useSubscriptionQuery from '@/queries/subscription/useSubscriptionQuery.hook';

function PlaylistEpisodesHeader({ playlistCount, episodeCount }) {
    const [opened, onOpen, onClose] = useSwitch(false);
    const { isMobileOrTablet, isDesktop } = useResponsive();
    const { data: subscription } = useSubscriptionQuery();
    return (
        <>
            <DeprecatedPaper flex align="center">
                <DeprecatedText weight="bold" size="veryBig">
                    <FormattedMessage defaultMessage="Mes playlists" />
                </DeprecatedText>
            </DeprecatedPaper>
            <AddEpisodeToPlaylistModal isOpened={opened} onClose={onClose} />
            <DeprecatedPaper height={40} align="center" justify="space-between" mb={20} flex z={1}>
                <DeprecatedText flex align="center">
                    <DeprecatedIcon as={folder} color="--neutral500" mr={5} />
                    <FormattedMessage
                        defaultMessage="{count, plural,
                            =0 {Aucune playlist}
                            one {# playlist}
                            other {# playlists}
                        }"
                        values={{ count: playlistCount }}
                    />
                </DeprecatedText>
                <HideFor showStatus={[STATUS_SHOWS.ARCHIVED_SHOWS]} condition={episodeCount > 0}>
                    {playlistCount !== 0 && (
                        <ButtonAccess
                            roles={['viewer']}
                            tooltip={
                                <DeprecatedText>
                                    <FormattedMessage defaultMessage="Vous devez être éditeur ou administrateur de l'émission pour ajouter un épisode à une playlist." />
                                </DeprecatedText>
                            }
                            onClick={onOpen}
                            floating={isMobileOrTablet}
                            icon={isMobileOrTablet}
                            bottomOffset={subscription?.isFreemium}
                        >
                            <DeprecatedIcon as={add} />
                            {isDesktop && (
                                <FormattedMessage defaultMessage="Ajouter à la playlist" />
                            )}
                        </ButtonAccess>
                    )}
                </HideFor>
            </DeprecatedPaper>
        </>
    );
}

export default PlaylistEpisodesHeader;
