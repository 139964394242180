import { useState, useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Text from '@ui/atoms/Text';
import AppleBroadcast from '../Platform/AppleBroadcast';
import SpotifyBroadcast from '../Platform/SpotifyBroadcast';
import useShowQuery from '@queries/show/useShowQuery.hook';
import useUserQuery from '@queries/user/useUserQuery.hook';
import useRssFeedQuery from '@queries/rss/useRssFeedQuery.hook';
import BroadcastMultiplePlatformsModal from '../BroadcastMultiplePlatformsModal';
import styled from 'styled-components';

const NonHostingBroadcastCards = () => {
    const { showId } = useParams<{ showId: string }>();
    const { data: show } = useShowQuery(showId);
    const { data: rssFeed } = useRssFeedQuery({ showId });
    const { data: user } = useUserQuery();

    const currentShowIsImported = !!rssFeed;
    const hasListeningLinks = (show?.listeningLinks?.data?.length || 0) > 0;

    const hasDisplayedMultiplePlatformsModalBefore = !!sessionStorage.getItem(
        `hasDisplayedBroadcastMultiplePlatformsModal-${showId}`,
    );

    const [isBroadcastMultiplePlatformsModalOpen, setBroadcastMultiplePlatformsModalIsOpen] =
        useState(
            !hasDisplayedMultiplePlatformsModalBefore &&
                !hasListeningLinks &&
                currentShowIsImported,
        );

    useEffect(() => {
        setBroadcastMultiplePlatformsModalIsOpen(
            !hasDisplayedMultiplePlatformsModalBefore &&
                !hasListeningLinks &&
                currentShowIsImported,
        );
    }, [hasListeningLinks, currentShowIsImported]);

    if (user && !user.emailVerifiedAt) {
        return <Redirect to={`/app/show/${showId}/settings/broadcast/email-verification`} />;
    }

    return (
        <>
            <BroadcastCards>
                <Header>
                    <Text variant="title" fontWeight="--fw-bold">
                        <FormattedMessage
                            id="settings.podcastApps"
                            defaultMessage="Plateformes d'écoute"
                        />
                    </Text>
                    <Text variant="body" color="--neutral500">
                        <FormattedMessage defaultMessage="Renseigner le lien d'écoute des plateformes d'écoute. 🎧" />
                    </Text>
                </Header>
                <Cards>
                    <AppleBroadcast />
                    <SpotifyBroadcast />
                </Cards>
            </BroadcastCards>
            <BroadcastMultiplePlatformsModal
                isOpen={isBroadcastMultiplePlatformsModalOpen}
                onClose={() => setBroadcastMultiplePlatformsModalIsOpen(false)}
            />
        </>
    );
};
const BroadcastCards = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3rem;
`;
const Header = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 1.5rem 1rem;

    ${(p) => p.theme.mediaQueries.tabletAndUp} {
        padding: 2rem 2.5rem;
    }
`;
const Cards = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
`;

export default NonHostingBroadcastCards;
