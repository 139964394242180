import { useMutation } from '@tanstack/react-query';
import { updateProfile } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import { ProfileFormSchema } from '@/components/unorganized/ProfileEdit/ProfileForm/schema';

const mutationFn = async (formData: ProfileFormSchema) => {
    const { data } = await updateProfile(formData);

    // TODO: Do camelcase transformation in Axios interceptor
    return camelcaseKeys(data, { deep: true });
};

export default function updateUserMutation() {
    return useMutation({
        mutationFn,
        onSuccess: () => {
            // Queries invalidation is done in the saveProfileMutation
        },
    });
}
