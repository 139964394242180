import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import Link from '../../atoms/Link';
import FetcherCampaigns from '../../../unorganized/FetcherCampaigns';
import { connect } from '../../../legacy/connect';
import { useDebounce } from '@/shared/hooks/useDebounce';
import { useResponsive } from '@/shared/hooks/useResponsive';
import ImgMenuItem from '../ImgMenuItem';

const ItemsList = ({ currentItemType, currentItemId, itemType, items, link, onClose }) =>
    items.map((item) => (
        <ImgMenuItem
            key={item.id}
            as={Link}
            to={link('stats.item', { itemType, itemId: item.id })}
            active={currentItemType === itemType && currentItemId === `${item.id}`}
            label={item.name}
            border="--neutral500"
            bb={1}
            height={40}
            onClick={onClose}
        />
    ));

const enhance = connect(({ campaignStore, routerStore }) => ({
    campaigns: campaignStore.campaigns,
    showId: routerStore.params.showId,
    link: routerStore.link,
    fetchCampaigns: campaignStore.fetchCampaigns,
}));

const ItemListDropDownCampaigns = ({ searchBar: SearchBar, showId, campaigns, link, onClose }) => {
    const { itemType, itemId } = useParams();

    const [campaignSearchValue, setCampaignSearchValue] = useState('');
    const debouncedCampaignSearchValue = useDebounce(campaignSearchValue, 500);

    const { isDesktop } = useResponsive();

    return (
        <FetcherCampaigns showId={showId} search={debouncedCampaignSearchValue}>
            {(isLoadingCampaigns) =>
                !isLoadingCampaigns && (
                    <>
                        <FormattedMessage defaultMessage="Rechercher une campagne…">
                            {(placeholder) => (
                                <SearchBar
                                    placeholder={placeholder.join('')}
                                    value={campaignSearchValue}
                                    setValue={setCampaignSearchValue}
                                />
                            )}
                        </FormattedMessage>
                        <ItemsList
                            isDesktop={isDesktop}
                            currentItemId={itemId}
                            currentItemType={itemType}
                            itemType="campaign"
                            items={campaigns}
                            link={link}
                            onClose={onClose}
                        />
                    </>
                )
            }
        </FetcherCampaigns>
    );
};

export default enhance(ItemListDropDownCampaigns);
