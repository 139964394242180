import { FormattedMessage } from 'react-intl';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import { connect } from '@/components/legacy/connect';
import { useStripe } from '@stripe/react-stripe-js';
import styled from 'styled-components';

const enhance = connect(({ subscriptionStore }) => ({
    onResume: (stripe) => subscriptionStore.subscription.reactivate(stripe),
}));

function TopbarWarningBand({ onResume }) {
    const stripe = useStripe();

    return (
        <TopbarEndedBandWrapper>
            <DeprecatedText color="white">
                <FormattedMessage defaultMessage="Votre abonnement est résilié." />{' '}
                <FormattedMessage defaultMessage="Pour profiter à nouveau des services d’Ausha, veuillez le réactiver." />
            </DeprecatedText>
            <DeprecatedText
                disabled={onResume.pending}
                ml={20}
                weight="semibold"
                as="span"
                color="white"
                onClick={() => onResume(stripe)}
            >
                <FormattedMessage defaultMessage="Réactiver mon abonnement" />
            </DeprecatedText>
        </TopbarEndedBandWrapper>
    );
}

const TopbarEndedBandWrapper = styled.div`
    height: 2rem;
    background: var(--info);
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default enhance(TopbarWarningBand);
