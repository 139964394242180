const clipFormatKeys = {
    all: () => [{ entity: 'clipFormat' }],
    allLists: () => [{ ...clipFormatKeys.all()[0], scope: 'list' }],
    listByShowId: (rawShowId: string) => [
        {
            ...clipFormatKeys.allLists()[0],
            showId: Number(rawShowId),
        },
    ],
    listByEpisodeId: (rawEpisodeId: string) => [
        {
            ...clipFormatKeys.allLists()[0],
            episodeId: Number(rawEpisodeId),
        },
    ],
    allDetails: () => [{ ...clipFormatKeys.all()[0], scope: 'detail' }],
    detailById: (rawEpisodeId: string) => [
        { ...clipFormatKeys.allDetails()[0], clipFormatId: Number(rawEpisodeId) },
    ],
};

export default clipFormatKeys;
