import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateClip } from '@/api';

export default function useUpdateClipTitle() {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ clipId, adminTitle }) => {
            return updateClip(clipId, { admin_title: adminTitle });
        },
        onMutate: async (data) => {
            /**
             * Cancel any outgoing refetches
             * (so they don't overwrite our optimistic update)
             */
            await queryClient.cancelQueries({ queryKey: ['shows.clip_formats.index'] });

            // Snapshot the previous value
            const previousState = queryClient.getQueryData(['shows.clip_formats.index']);

            // Optimistically update to the new value
            queryClient.setQueryData(['shows.clip_formats.index'], () => data);

            // Return a context object with the snapshotted value
            return { previousState };
        },
        /**
         * If the mutation fails,
         * use the context returned from onMutate to roll back
         */
        onError: (err, data, context) => {
            queryClient.setQueryData(['shows.clip_formats.index'], context.previousState);
        },
        /**
         * Always refetch after error or success:
         */
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['shows.clip_formats.index'] });
        },
    });
}
