import { createApi } from '../callApi';
const contentApi = createApi('CONTENT');

export const fetchUser = () => {
    return contentApi({ method: 'GET', target: '/user' });
};
export const deleteAccount = (body) => {
    return contentApi({ method: 'DELETE', target: '/user', body });
};
export const fetchEmailSettingsNotification = () => {
    return contentApi({ method: 'GET', target: '/v1/user/alerts' });
};
export const updateEmailSettingsNotification = (body) => {
    return contentApi({ method: 'PUT', target: '/v1/user/alerts', body });
};
export const fetchLanguages = () => {
    return contentApi({ method: 'GET', target: '/v1/languages' });
};
