import PropTypes from 'prop-types';
import styled from 'styled-components';
import CheckRounded from '@public/icons/checkRounded.svg';

const StyledText = styled.text`
    fill: ${(props) => (props.active ? '#FFFFFF' : '#5352ED')};
    font-size: var(--fs-body);
    font-weight: var(--fw-semibold);
`;

const StyledCircle = styled.circle`
    fill: ${(props) => (props.active ? '#5352ED' : '#EEEDFD')};
`;

// IMPROVE Refactor component to add a flip animation between regular and checked icons
const StepIcon = (props) => {
    const { active = false, completed = false, icon } = props;

    if (typeof icon === 'number' || typeof icon === 'string') {
        if (completed) {
            return <CheckRounded width="24" height="24" style={{ display: 'block' }} />;
        }

        return (
            <svg viewBox="0 0 24 24" width="24" height="24" style={{ display: 'block' }}>
                <StyledCircle active={active} cx="12" cy="12" r="12" />
                <StyledText active={active} x="12" y="16" textAnchor="middle">
                    {icon}
                </StyledText>
            </svg>
        );
    }

    return icon;
};

StepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

export default StepIcon;
