import * as yup from 'yup';
import { checkImgDimensions, checkImgFormat, checkImgSize, getPlainText } from '@/helpers';
import { FORM_VALIDATION_MESSAGE } from '@/utils/validationMessage';

const newsletterMaxCharacters = 4000;
const IMG_DIMENSIONS = { minWidth: 640, minHeight: 360 };
const acceptedMimes = ['jpg', 'png'];

const getNewsletterSchema = (intl, locale) => {
    return yup.object({
        activated: yup.boolean(),
        title: yup.string().when('activated', {
            is: true,
            then: (schema) => schema.required(intl.formatMessage(FORM_VALIDATION_MESSAGE.required)),
            otherwise: (schema) => schema.notRequired(),
        }),
        messageTpl: yup
            .string()
            .test({
                name: 'max-characters',
                message: intl.formatMessage(FORM_VALIDATION_MESSAGE.maxLength, {
                    max: newsletterMaxCharacters,
                }),
                skipAbsent: true,
                test: (value) => getPlainText(value).length <= newsletterMaxCharacters,
            })
            .notRequired(),
        file: yup
            .mixed()
            .nullable()
            .test(
                'image format',
                intl.formatMessage(FORM_VALIDATION_MESSAGE.acceptedMimes, { acceptedMimes }),
                (file) => {
                    if (typeof file === 'string' || file === null) return true;
                    return checkImgFormat(file?.[0]);
                },
            )
            .test(
                'image size',
                intl.formatMessage(FORM_VALIDATION_MESSAGE.imgMaxSize, { max: 3 }),
                (file) => {
                    if (typeof file === 'string' || file === null) return true;
                    return checkImgSize(file?.[0], locale, 3);
                },
            )
            .test(
                'image dimensions',
                intl.formatMessage(FORM_VALIDATION_MESSAGE.imgMinDimensions, IMG_DIMENSIONS),
                async (file) => {
                    if (typeof file === 'string' || file === null) return true;
                    const img = new Image();
                    img.src = URL.createObjectURL(new File([file?.[0]], 'pic'));
                    return new Promise((resolve) => {
                        img.onload = () => {
                            const isValid = checkImgDimensions(img, IMG_DIMENSIONS);
                            resolve(isValid);
                        };
                    });
                },
            ),
    });
};

export default getNewsletterSchema;
