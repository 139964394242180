import Text from '@ui/atoms/Text';
import EmptyState from '@ui/molecules/EmptyState';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import ZebraIllustration from '@ui/atoms/ZebraIllustration';
import microphoneEmoji from '@public/images/emoji/studio_microphone.png';
import noEpisodeMainIllustration from '@public/images/empty-state/illustration/smartplayer.png';
import RouterLink from '@/components/Link/RouterLink';
import { useParams } from 'react-router';

const PlayerListenableEpisodesEmptyState = () => {
    const { showId } = useParams<{ showId: string }>();
    return (
        <EmptyState
            illustration={noEpisodeMainIllustration}
            illustrationType="image"
            illustrationAlign="right"
            illustrationBackground="--gradient-night"
        >
            <HeadIllustrationWrapper>
                {/* @ts-ignore */}
                <ZebraIllustration foreground={microphoneEmoji} />
            </HeadIllustrationWrapper>
            <LabelWrapper>
                {/* @ts-ignore */}
                <Text variant="heading" fontWeight="--fw-bold">
                    <FormattedMessage defaultMessage="Commencez par ajouter votre premier épisode" />
                </Text>
                {/* @ts-ignore */}
                <Text variant="body" fontWeight="--fw-normal" color="--neutral500">
                    <FormattedMessage
                        defaultMessage="Débloquez la configuration de votre Smartplayer en <link>publiant votre premier épisode</link> 🔥"
                        values={{
                            link: (chunks: string) => (
                                <RouterLink to={`/app/show/${showId}/episodes`}>
                                    {chunks}
                                </RouterLink>
                            ),
                        }}
                    />
                </Text>
            </LabelWrapper>
        </EmptyState>
    );
};

const HeadIllustrationWrapper = styled.div`
    width: 200px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const LabelWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    & > * {
        text-align: center;
    }
`;

export default PlayerListenableEpisodesEmptyState;
