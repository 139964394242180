import { useState } from 'react';
import Button from '@/components/Button';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { CampaignFormSchema } from '../useFormSchema';
import updateCampaignMutation from '@/queries/campaign/updateCampaignMutation';
import { useHistory, useParams } from 'react-router';
import useCampaignQuery from '@/queries/campaign/useCampaignQuery';
import { CAMPAIGN_STATUS } from '@/shared/config/constants';
import { useBodyToastQueue } from '@/shared/hooks/useBodyToastQueue.hook';
import PublishModal from './PublishModal';
import { TooltipTrigger } from 'react-aria-components';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';

const PublishButton = () => {
    const { campaignId, showId } = useParams<{ campaignId: string; showId: string }>();
    const { data: campaign } = useCampaignQuery(campaignId);
    const { formState, handleSubmit, reset, watch } = useFormContext<CampaignFormSchema>();
    const { isValid } = formState;
    const updateCampaign = updateCampaignMutation();
    const history = useHistory();
    const isDraft = campaign?.state === CAMPAIGN_STATUS.DRAFT;
    const toast = useBodyToastQueue();
    const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
    const hasAudioFile = !!campaign?.files?.[0];
    const campaignType = watch('general.type');
    const isDisabled = !isValid || (campaignType !== 'hostread' && !hasAudioFile);
    const startsAt = watch('duration.startsAt');
    const endsAt = watch('duration.endsAt');

    if (!isDraft) return null;

    const handlePublishCampaign = handleSubmit((formData: CampaignFormSchema) => {
        const {
            general,
            duration: { maxListens, ...duration },
            episodes,
        } = formData;
        const { episodes: podcasts, autoOnNewPodcasts } = episodes;
        const data = {
            state: 'active' as 'active',
            ...general,
            ...duration,
            maxListens: maxListens || null,
            podcasts,
            autoOnNewPodcasts,
        };
        updateCampaign.mutate(
            { campaignId, campaign: data },
            {
                onSuccess: () => {
                    reset(formData);
                    history.push(`/app/show/${showId}/monetize/manual`);
                },
                onError: () => {
                    toast.alert();
                },
            },
        );
    });

    return (
        <>
            <TooltipTrigger delay={0} closeDelay={0} isDisabled={!isDisabled}>
                <TooltipTriggerWrapperStyled>
                    <Button
                        onPress={() => setIsPublishModalOpen(true)}
                        isDisabled={isDisabled}
                        startIcon={<Icon icon={icon({ name: 'rocket-launch', style: 'solid' })} />}
                        isLoading={updateCampaign.isLoading}
                    >
                        <FormattedMessage defaultMessage="Publier maintenant" />
                    </Button>
                </TooltipTriggerWrapperStyled>
                <Tooltip placement="bottom">
                    {!hasAudioFile ? (
                        <FormattedMessage defaultMessage="Un fichier audio est requis pour publier la campagne" />
                    ) : (
                        <FormattedMessage defaultMessage="Le formulaire contient des erreurs." />
                    )}
                </Tooltip>
            </TooltipTrigger>
            <PublishModal
                isOpen={isPublishModalOpen}
                onOpenChange={setIsPublishModalOpen}
                onPublish={handlePublishCampaign}
                isLoading={updateCampaign.isLoading}
                startsAt={startsAt}
                endsAt={endsAt}
            />
        </>
    );
};

const Icon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const TooltipTriggerWrapperStyled = styled(TooltipTriggerWrapper)`
    & > * {
        width: 100%;
    }
`;

export default PublishButton;
