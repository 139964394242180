import { useForm } from 'react-hook-form';
import usePersonalAccessTokenFormSchema, {
    PersonalAccessTokenSchema,
} from './usePersonalAccessTokenFormSchema.hook';
import { zodResolver } from '@hookform/resolvers/zod';
import styled from 'styled-components';
import { InputTextController } from '@/components/ui/atoms/InputText';
import { FormattedMessage, useIntl } from 'react-intl';
import { DatePickerController } from '@/components/ui/atoms/DatePicker';
import Button from '@/components/Button';
import dayjs from 'dayjs';
import { parseDate } from '@internationalized/date';

interface PersonalAccessTokenFormProps {
    onSubmit: (data: PersonalAccessTokenSchema) => void;
}

const PersonalAccessTokenForm = ({ onSubmit }: PersonalAccessTokenFormProps) => {
    const intl = useIntl();
    const schema = usePersonalAccessTokenFormSchema();
    const todayDatePlusOneYear = dayjs().add(1, 'year').format('YYYY-MM-DD');
    const { control, handleSubmit, formState } = useForm<PersonalAccessTokenSchema>({
        mode: 'onBlur',
        resolver: zodResolver(schema),
        defaultValues: {
            name: '',
            expiresAt: parseDate(todayDatePlusOneYear),
        },
    });
    const minDate = parseDate(dayjs().add(1, 'day').format('YYYY-MM-DD'));

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <InputGroup>
                <InputTextController
                    name="name"
                    control={control}
                    label={<FormattedMessage defaultMessage="Ajouter un jeton d’accès" />}
                    placeholder={intl.formatMessage({
                        defaultMessage: 'Nom de l’application',
                    })}
                />
                <DatePickerController
                    name="expiresAt"
                    control={control}
                    label={<FormattedMessage defaultMessage="Date d'expiration" />}
                    minValue={minDate}
                    hideCustomTimezone
                />
            </InputGroup>
            <SubmitButton type="submit" isDisabled={!formState.isDirty || !formState.isValid}>
                <FormattedMessage defaultMessage="Ajouter" />
            </SubmitButton>
        </Form>
    );
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`;
const InputGroup = styled.form`
    display: flex;
    align-items: center;
    column-gap: 1rem;

    & > :first-child {
        flex-grow: 1;
    }

    & > :last-child {
        flex-shrink: 0;
    }
`;
const SubmitButton = styled(Button)`
    align-self: flex-start;
`;

export default PersonalAccessTokenForm;
