import { FormattedMessage } from 'react-intl';
import Center from '@ui/layout/Center';
import Spinner from '@ui/atoms/Spinner';
import Stack from '@ui/layout/Stack';
import Text from '@/components/ui/atoms/Text';

const Progress = () => (
    <Center max="max-content">
        <Stack $gap="1.75rem" $align="center">
            <Spinner />
            <Stack>
                <Text fontWeight="--fw-bold" textAlign="center">
                    <FormattedMessage defaultMessage="Votre clip est en cours de transcription." />
                </Text>
                <Text textAlign="center">
                    <FormattedMessage defaultMessage="Cela peut prendre quelques minutes, alors pas de panique :)" />
                </Text>
            </Stack>
        </Stack>
    </Center>
);

export default Progress;
