import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Text from '@ui/atoms/Text';
import Stack from '@ui/layout/Stack';
import Button from '@ui/atoms/Button';
import { useHistory } from 'react-router-dom';
import Confetti from 'react-dom-confetti';
import { PRICING, PRICING_NAME } from '@/utils/pricing';
import useQuery from '@hooks/useQuery';
import Link from '@ui/atoms/Link';
import launchImage from '@public/images/plans/launch.png';
import boostImage from '@public/images/plans/boost.png';
import supersonicImage from '@public/images/plans/supersonic.png';
import enterpriseImage from '@public/images/plans/enterprise.png';
import Img from '@ui/atoms/Img';
import useShowsQuery from '@/queries/show/useShowsQuery.hook';

const leftConfettiConfig = {
    angle: '70',
    spread: '40',
    elementCount: '300',
    dragFriction: '0.04',
    duration: '4000',
    stagger: '1',
    width: '12px',
    height: '5px',
    colors: ['#FFC747', '#FF6E47', '#F52FFB', '#3A25A5', '#751CBF'],
};

const rightConfettiConfig = {
    angle: '110',
    spread: '40',
    elementCount: '300',
    dragFriction: '0.04',
    duration: '4000',
    stagger: '1',
    width: '12px',
    height: '5px',
    colors: ['#FFC747', '#FF6E47', '#F52FFB', '#3A25A5', '#751CBF'],
};

const offersImage = {
    [PRICING.LAUNCH]: launchImage,
    [PRICING.BOOST]: boostImage,
    [PRICING.SUPERSONIC]: supersonicImage,
    [PRICING.ENTERPRISE]: enterpriseImage,
};

const PricingSuccessPage = () => {
    const history = useHistory();
    const offer = useQuery().get('offer');
    const isUpgrade = useQuery().get('upgrade') === 'true';
    const showsQueryResult = useShowsQuery();
    const dashboardLink =
        showsQueryResult?.data?.shows?.length > 0
            ? `/app/show/${showsQueryResult.data.shows[0].id}/episodes`
            : '/app/show/new';
    const [confetti, setConfetti] = useState(false);

    useEffect(() => {
        setTimeout(() => setConfetti(true), 1000);
        return () => setConfetti(false);
    }, []);

    return (
        <Container>
            <Content>
                <Img size="7.5rem" src={offersImage[offer]} />
                <Text variant="headingXL" fontWeight="--fw-bold" textAlign="center">
                    <FormattedMessage
                        defaultMessage="<upgrade>Félicitations ! </upgrade>Vous êtes désormais sur le plan {offer}<upgrade> 🚀</upgrade>"
                        values={{
                            upgrade: (chunk) => isUpgrade && chunk,
                            offer: (
                                <Text
                                    as="span"
                                    variant="headingXL"
                                    fontWeight="--fw-bold"
                                    color="--primary"
                                >
                                    {PRICING_NAME[offer]}
                                </Text>
                            ),
                        }}
                    />
                </Text>
                <DashboardButton onPress={() => history.replace(dashboardLink)}>
                    <FormattedMessage defaultMessage="Accéder au tableau de bord" />
                </DashboardButton>
            </Content>
            {isUpgrade && (
                <>
                    <Confetti
                        active={confetti}
                        config={leftConfettiConfig}
                        css="position: absolute !important; bottom: 0; left:0;"
                    />
                    <Confetti
                        active={confetti}
                        config={rightConfettiConfig}
                        css="position: absolute !important; bottom: 0; right:0;"
                    />
                </>
            )}
            <Footer>
                <Text variant="bodyS" color="--neutral500">
                    <FormattedMessage
                        defaultMessage="L’accès à votre abonnement et votre facturation est disponible dans votre <strong>espace compte</strong>."
                        values={{
                            strong: (chunk) => (
                                <Text
                                    as={Link}
                                    variant="bodyS"
                                    fontWeight="--fw-semibold"
                                    color="--primary"
                                    onClick={() => history.replace('/app/user/subscription')}
                                >
                                    {chunk}
                                </Text>
                            ),
                        }}
                    />
                </Text>
            </Footer>
        </Container>
    );
};

const Container = styled.div`
    position: relative;
    display: grid;
    width: 100%;
    height: 100%;
    background: var(--white);
    overflow: hidden;
`;

const Content = styled(Stack)`
    max-width: 50rem;
    justify-self: center;
    align-self: center;
    align-items: center;
    gap: 2rem;
`;

const Footer = styled.div`
    position: fixed;
    bottom: 2rem;
    width: 100%;
    text-align: center;
`;

const DashboardButton = styled(Button)`
    width: 15.625rem;
`;

export default PricingSuccessPage;
