import useCampaignQuery from '@/queries/campaign/useCampaignQuery';
import { useParams } from 'react-router';
import styled from 'styled-components';
import Status from './Status';
import Text from '@/components/ui/atoms/Text';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { useFormContext } from 'react-hook-form';
import { CampaignFormSchema } from '../useFormSchema';
import Stack from '@/components/ui/layout/Stack';
import AudioFile from './AudioFile';

const Sidebar = () => {
    const { campaignId } = useParams<{ campaignId: string; showId: string }>();
    const campaignQuery = useCampaignQuery(campaignId);
    const { watch } = useFormContext<CampaignFormSchema>();
    const name = watch('general.name');
    const startsAt = watch('duration.startsAt');
    const endsAt = watch('duration.endsAt');

    return (
        <Container>
            <Status status={campaignQuery.data?.state ?? ''} />
            <Stack $gap="0.25rem">
                <Text variant="bodyL" fontWeight="--fw-bold">
                    {name}
                </Text>
                <Text color="--neutral500">
                    {startsAt && endsAt && (
                        <FormattedMessage
                            defaultMessage="Du {from} au {to}"
                            values={{
                                from: <FormattedDate value={startsAt} />,
                                to: <FormattedDate value={endsAt} />,
                            }}
                        />
                    )}
                    {startsAt && !endsAt && (
                        <FormattedMessage
                            defaultMessage="Commence le {from}"
                            values={{
                                from: <FormattedDate value={startsAt} />,
                            }}
                        />
                    )}
                </Text>
            </Stack>
            <Divider />
            <AudioFile />
        </Container>
    );
};

const Container = styled.aside`
    flex: 1;
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: 1.5rem;
    height: fit-content;

    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        max-width: 25rem;
        position: sticky;
        top: 0;
    }
`;
const Divider = styled.div`
    height: 1px;
    align-self: stretch;
    background-color: var(--neutral100);
    border-radius: var(--r-full);
`;

export default Sidebar;
