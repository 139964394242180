import styled from 'styled-components';
import PropTypes from 'prop-types';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import DeprecatedPaper from '../../atoms/DeprecatedPaper';
import { resolveColor } from '@/shared/utils/cssVariable';

const Circle = styled.circle`
    stroke-width: 2px;
    stroke: ${(p) => (p.color ? resolveColor(p.color) : 'var(--primary)')};
    fill: transparent;
    transition: all 250ms;
    stroke-linecap: round;
    position: absolute;
    top: 0;
`;

const Svg = styled.svg`
    transform: rotate(-90deg);
    overflow: visible;
`;

const Percent = styled(DeprecatedPaper)`
    left: calc(50% + 1px) !important;
    top: calc(50%) !important;
    transform: translate(-50%, -50%);
`;

const PercentSign = styled.sup`
    font-size: 6px;
`;

// TODO: Use CircularProgress.js to create a CircularProgressWithLabel
// https://material-ui.com/components/progress/#circular-with-label

function ProgressCircle({ progress, hasError, ...props }) {
    const radius = 20;
    const length = 2 * Math.PI * radius;
    return (
        <DeprecatedPaper pos="relative" height={42} w={42} {...props}>
            <Svg xmlns="http://www.w3.org/2000/svg" height="42" width="42" viewbox="0 0 42 42">
                <Circle
                    cx="22"
                    cy="22"
                    r={radius}
                    color="--neutral50"
                    strokeDasharray={length}
                    strokeDashoffset={0}
                />
                <Circle
                    cx="22"
                    cy="22"
                    r={radius}
                    color={
                        (hasError && '--alert') || (progress === 1 && '--success') || '--primary'
                    }
                    strokeDasharray={length}
                    strokeDashoffset={(1 - progress) * length}
                />
            </Svg>
            <Percent align="center" justify="center" pos="absolute" height="100%" flex>
                {hasError ? (
                    <DeprecatedText color="--alert">X</DeprecatedText>
                ) : (
                    <DeprecatedText size="small">
                        {Math.round(progress * 100)}
                        <PercentSign>%</PercentSign>
                    </DeprecatedText>
                )}
            </Percent>
        </DeprecatedPaper>
    );
}

ProgressCircle.propTypes = {
    progress: PropTypes.number,
    hasError: PropTypes.bool,
};

export default ProgressCircle;
