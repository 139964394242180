import { useEpisodeBeingEdited } from '@/context/EpisodeFormContext';
import { FormattedMessage, useIntl } from 'react-intl';
import getSaveDraftEpisodeAndCloseSchema from '@/components/unorganized/EpisodeEditForm/schemas/saveDraftEpisodeAndCloseSchema';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import Button from '@ui/atoms/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import ExperimentalTooltip from '@ui/atoms/ExperimentalTooltip';
import { getDirtyValues } from '@/helpers';
import useSaveEpisodeFormMutation from '@/components/unorganized/EpisodeEditForm/helpers/useSaveEpisodeFormMutation';
import useInternalRouteMapping from '@/shared/hooks/useInternalRouteMapping.hook';
import EpisodeEditFormActionsTooltipMessage from '@/components/unorganized/EpisodeEditForm/actions/EpisodeEditFormActionsTooltipMessage';
import { getChaptersIdsRequiringImageDeletion } from '@/components/unorganized/EpisodeEditForm/helpers/getChaptersIdsRequiringImageDeletion';

const TransparentButton = styled(Button)`
    background: transparent;
`;

const SaveDraftEpisodeAndCloseButton = () => {
    const intl = useIntl();
    const { push } = useInternalRouteMapping();
    const {
        watch,
        reset,
        formState: { dirtyFields },
    } = useFormContext();
    const saveEpisodeFormMutation = useSaveEpisodeFormMutation();

    const episodeBeingEdited = useEpisodeBeingEdited();
    const schema = getSaveDraftEpisodeAndCloseSchema(intl);
    const formData = watch();
    const isSchemaValid = schema.isValidSync(formData);
    const isValid = isSchemaValid;

    const onPress = async () => {
        const dirtyValues = getDirtyValues(dirtyFields, formData);
        const chaptersIdsRequiringImageDeletion = getChaptersIdsRequiringImageDeletion(
            dirtyFields,
            formData.chapters,
        );

        const { content, options, privacy } = dirtyValues;
        const episodeData = {
            ...content,
            ...options,
            ...privacy,
            publish_youtube: formData.options.publishYoutube,
            youtube_video_title: formData.options.youtubeVideoTitle,
        };

        try {
            await saveEpisodeFormMutation.mutateAsync(
                {
                    episodeId: episodeBeingEdited.id,
                    episode: episodeData,
                    chapters: dirtyFields.chapters ? formData.chapters : null,
                    chaptersIdsRequiringImageDeletion,
                },
                {
                    onSuccess: () => {
                        reset();
                        push('menu.episodes');
                    },
                },
            );
        } catch (error) {
            // Handle error here ?
        }
    };

    return (
        <ExperimentalTooltip
            isDisabled={isValid}
            content={<EpisodeEditFormActionsTooltipMessage isSchemaValid={isSchemaValid} />}
        >
            <TransparentButton
                variant="secondary"
                onPress={onPress}
                isDisabled={!isValid}
                isLoading={saveEpisodeFormMutation.isLoading}
                startIcon={
                    <FontAwesomeIcon
                        icon={icon({ name: 'check', style: 'regular' })}
                        color="inherit"
                    />
                }
            >
                <FormattedMessage defaultMessage="Enregistrer et fermer" />
            </TransparentButton>
        </ExperimentalTooltip>
    );
};

export default SaveDraftEpisodeAndCloseButton;
