import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { ImgUploaderController } from '@ui/molecules/ImgUploader';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { useResponsive } from '@/utils/hooks/useResponsive';
import { InputTextController } from '@ui/atoms/InputText';
import Button from '@ui/atoms/Button';
import Text from '@ui/atoms/Text';
import { RadioGroupController } from '@ui/molecules/RadioGroup';
import InputRadio from '@ui/atoms/InputRadio/InputRadio';
import Stack from '@ui/layout/Stack';
import Cluster from '@ui/layout/Cluster';
import usePlaylistFormSchema from './usePlaylistFormSchema.hook';
import { yupResolver } from '@hookform/resolvers/yup';
import useUserStore from '@hooks/useUserStore';
import { PRICING } from '@/utils/pricing';
import UpgradeBadge from '@ui/atoms/UpgradeBadge';
import { EPISODE_PRIVACY } from '@/utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { PLAYLIST_PRIVACY } from '@/utils/constants';
import ExperimentalTooltip from '@ui/atoms/ExperimentalTooltip';
import { InputRichTextController } from '@ui/atoms/InputRichText';

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`;
const InnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
        column-gap: 2rem;
    }
`;
const FieldsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-grow: 1;
    }
`;
const ButtonsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    margin-top: auto;

    & > :first-child,
    > :last-child {
        align-self: stretch;
    }

    ${(p) => p.theme.mediaQueries.tabletAndUp} {
        flex-direction: row;
        justify-content: flex-end;
        column-gap: 0.5rem;
        margin-top: 0.5rem;

        & > :first-child,
        > :last-child {
            align-self: initial;
        }

        & > :first-child {
            order: 1;
        }
    }
`;

const PlaylistForm = ({
    isNew,
    onSubmit,
    playlist,
    playlistLink,
    onClose,
    isLoading,
    hasAccessToPlaylistConfidentiality,
}) => {
    const intl = useIntl();
    const { locale } = useUserStore();
    const schema = usePlaylistFormSchema({
        locale,
        isPrivacyOptionEnabled: hasAccessToPlaylistConfidentiality,
        isNew,
    });
    const defaultPassword =
        hasAccessToPlaylistConfidentiality && playlist?.password
            ? { password: playlist?.password }
            : {};
    const { control, handleSubmit, formState, setValue, watch, setError } = useForm({
        mode: 'onChange',
        defaultValues: {
            ...defaultPassword,
            privacy: playlist?.privacy || EPISODE_PRIVACY.PUBLIC,
            file: playlist?.imageUrl || null,
            name: playlist?.name || '',
            html_description: playlist?.description || '',
            slug: playlist?.slug,
        },
        resolver: yupResolver(schema),
    });
    const { isDesktop } = useResponsive();
    const { isDirty, isValid } = formState;
    const privacy = watch('privacy');
    const isPrivate = hasAccessToPlaylistConfidentiality && privacy === PLAYLIST_PRIVACY.PRIVATE;

    const handleSubmitForm = async (formData) => {
        try {
            await onSubmit(formData);
            onClose();
        } catch (error) {
            if (error?.response?.status === 422) {
                if (!error?.response?.data?.errors?.slug) return;
                setError('slug', {
                    message: intl.formatMessage({
                        defaultMessage: 'Ce permalien est déjà utilisé',
                    }),
                });
            }
        }
    };

    return (
        <Form onSubmit={handleSubmit(handleSubmitForm)}>
            <InnerWrapper>
                <ImgUploaderController
                    control={control}
                    name="file"
                    horizontal={!isDesktop}
                    handleDeleteFile={() => setValue('file', null, { shouldDirty: true })}
                    w={160}
                />
                <FieldsWrapper>
                    <InputTextController
                        control={control}
                        name="name"
                        placeholder={intl.formatMessage({
                            defaultMessage: 'Veuillez saisir le titre de la playlist',
                        })}
                        label={<FormattedMessage defaultMessage="Titre de la playlist" />}
                        isRequired
                    />
                    {!isNew && (
                        <InputTextController
                            control={control}
                            name="slug"
                            label={<FormattedMessage defaultMessage="Permalien" />}
                            prefix={playlistLink}
                            isRequired
                        />
                    )}
                    <InputRichTextController
                        name="html_description"
                        control={control}
                        label={<FormattedMessage defaultMessage="Description" />}
                        charactersLimit={4000}
                    />
                    <RadioGroupController
                        control={control}
                        name="privacy"
                        label={<FormattedMessage defaultMessage="Confidentialité" />}
                        gap="1.25rem"
                    >
                        <InputRadio value="public">
                            <FormattedMessage defaultMessage="Publique (visible par tout le monde)" />
                        </InputRadio>
                        <Cluster $gap="0.5rem" $align="center">
                            <InputRadio
                                isDisabled={!hasAccessToPlaylistConfidentiality}
                                value="unlisted"
                            >
                                <FormattedMessage defaultMessage="Non-listée (non visible sur le Site Web)" />
                            </InputRadio>
                            {!hasAccessToPlaylistConfidentiality && (
                                <UpgradeBadge pricing={PRICING.SUPERSONIC} />
                            )}
                        </Cluster>
                        <Stack $gap="0.5rem">
                            <Cluster $gap="0.5rem" $align="center">
                                <InputRadio
                                    isDisabled={!hasAccessToPlaylistConfidentiality}
                                    value="private"
                                >
                                    <FormattedMessage defaultMessage="Privée (protégée par un mot de passe)" />
                                    {hasAccessToPlaylistConfidentiality && (
                                        <ExperimentalTooltip
                                            placement="end"
                                            content={
                                                <Text fontWeight="--fw-semibold">
                                                    <FormattedMessage defaultMessage="Votre playlist n’apparaitra pas sur votre Site Web, et sera protégée par un mot de passe que vous définissez." />
                                                </Text>
                                            }
                                        >
                                            <HelpIcon
                                                icon={icon({
                                                    name: 'circle-question',
                                                    style: 'solid',
                                                })}
                                            />
                                        </ExperimentalTooltip>
                                    )}
                                </InputRadio>
                                {!hasAccessToPlaylistConfidentiality && (
                                    <UpgradeBadge pricing={PRICING.SUPERSONIC} />
                                )}
                            </Cluster>
                            {isPrivate && (
                                <InputTextController
                                    control={control}
                                    name="password"
                                    placeholder={intl.formatMessage({
                                        defaultMessage: 'Saisir un mot de passe',
                                    })}
                                />
                            )}
                        </Stack>
                    </RadioGroupController>
                </FieldsWrapper>
            </InnerWrapper>
            <ButtonsWrapper>
                <Button type="submit" isDisabled={!isValid || !isDirty} isLoading={isLoading}>
                    {isNew ? (
                        <FormattedMessage defaultMessage="Créer la playlist" />
                    ) : (
                        <FormattedMessage defaultMessage="Modifier la playlist" />
                    )}
                </Button>
                <Button variant="secondary" onPress={onClose}>
                    <FormattedMessage defaultMessage="Annuler" />
                </Button>
            </ButtonsWrapper>
        </Form>
    );
};

const HelpIcon = styled(FontAwesomeIcon)`
    color: var(--primary500);
    font-size: 1rem;
`;

PlaylistForm.propTypes = {
    isNew: PropTypes.bool,
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
    playlistLink: PropTypes.string,
    playlist: PropTypes.object,
    isLoading: PropTypes.bool,
    hasAccessToPlaylistConfidentiality: PropTypes.bool,
};

export default PlaylistForm;
