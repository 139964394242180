import { computed, runInAction } from 'mobx';
import * as Sentry from '@sentry/react';
import * as amplitude from '@amplitude/analytics-browser';
import Store from './Store';
import {
    deleteUserAvatar,
    fetchChannel,
    fetchUser,
    updateEmail,
    updatePassword,
    updateProfile,
    uploadUserAvatar,
    deleteAccount,
} from '@/api';
import UserModel from '../models/UserModel';
import { apiCall } from '../components/app/decorators/api';
import { ENABLE_USER_TOKEN_REFRESH_IN_BACKGROUND } from '@/utils/constants';

class UserStore extends Store {
    static observables = {
        user: null,
    };

    // UI

    @computed
    get locale() {
        return this.user ? this.user.locale : navigator.language.split('-')[0];
    }

    // API

    @apiCall
    async _internalFetchUser() {
        if (!this.state.authStore.isLoggedIn) return;
        const [userData, channel] = await Promise.all([fetchUser(), this.fetchChannel()]);

        runInAction(() => {
            this.user = new UserModel(this, userData?.data);
            Sentry.setUser({
                id: this.user.id,
                username: this.user.fullName,
                email: this.user.email,
            });
            amplitude.setUserId(this.user.id);
            const identify = new amplitude.Identify();
            identify
                .set('lang', this.user.languageCode)
                .set('pricing', this.user.pricing)
                .set('active', this.user.activated);
            amplitude.identify(identify);
            this.user.channelId = channel.id;
            this.user.channelPublicId = channel.public_id;
        });

        await this.state.subscriptionStore.fetchSubscription();
    }

    // A fullscreen loading screen appears when calling this function to block UI during user data fetching
    @apiCall
    async fetchUser() {
        return this._internalFetchUser();
    }

    @apiCall
    async refreshUser() {
        if (!ENABLE_USER_TOKEN_REFRESH_IN_BACKGROUND) {
            return this.fetchUser();
        }

        if (this.user) {
            // If user have an active session, refresh tokens in background
            return this._internalFetchUser();
        } else {
            // Fallback to fullscreen loading screen
            return this.fetchUser();
        }
    }

    @apiCall
    async fetchChannel() {
        const channel = await fetchChannel();
        if (!channel) {
            return null;
        }
        return channel.data;
    }

    @apiCall
    async updateProfile({ avatar, ...formData }) {
        if (avatar && typeof avatar !== 'string') {
            const { data } = await uploadUserAvatar(avatar);
            this.user.updateData(data);
        }
        if (avatar === null) {
            await deleteUserAvatar();
            runInAction(() => {
                this.user.avatar = null;
            });
        }
        if (Object.keys(formData).length < 1) return;
        return updateProfile(formData);
    }

    @apiCall
    updateEmail(formData) {
        return updateEmail(formData);
    }

    @apiCall
    updatePassword(formData) {
        return updatePassword(formData);
    }

    @apiCall
    deleteAccount(formData) {
        return deleteAccount(formData);
    }
}

export default UserStore;
