import { useQuery } from '@tanstack/react-query';
import { fetchEpisodeJobsProgress } from '@/api';
import { EPISODE_JOB_STATUS } from '@/shared/config/constants';
import episodeKeys from './episodeKeys';

interface EpisodeAudioFileImportJobQueryParams {
    episodeId: string | number;
    options?: { enabled: boolean };
}

const useEpisodeAudioFileImportJobQuery = ({
    episodeId,
    options,
}: EpisodeAudioFileImportJobQueryParams) => {
    return useQuery({
        queryKey: episodeKeys.audioFileImportJobByEpisodeId(episodeId),
        queryFn: async () => {
            const { data } = await fetchEpisodeJobsProgress(episodeId);
            return data;
        },
        select: (data) => {
            const audioFileImportJob = data?.importaudiofile;

            if (!audioFileImportJob) {
                return null;
            }

            const transformedData = {
                percentage: audioFileImportJob.progress_percentage,
                result: audioFileImportJob.result,
                status: audioFileImportJob.status,
                isQueued: audioFileImportJob.status === EPISODE_JOB_STATUS.QUEUED,
                isExecuting: audioFileImportJob.status === EPISODE_JOB_STATUS.EXECUTING,
                isFinished: audioFileImportJob.status === EPISODE_JOB_STATUS.FINISHED,
                isFailed: audioFileImportJob.status === EPISODE_JOB_STATUS.FAILED,
            };

            return transformedData;
        },
        refetchInterval: (data) => {
            if (!data) return false;

            if (data?.isFinished || data?.isFailed) {
                return false;
            }

            return 2000;
        },
        ...options,
    });
};

export default useEpisodeAudioFileImportJobQuery;
