import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import CalendarGrid from './CalendarGrid';
import CalendarSidebar from './CalendarSidebar';
import useSocialNetworkCalendarPostQuery from '@/queries/socialNetworkPost/useSocialNetworkCalendarPostQuery.hook';
import CalendarSkeleton from './CalendarSkeleton';
import dayjs from 'dayjs';
import objectSupport from 'dayjs/plugin/objectSupport';
import { getDatesFromPreviousMonth, getDatesFromNextMonth } from '@/utils/dates';
import styled from 'styled-components';
dayjs.extend(objectSupport);

const SocialMediaCalendar = ({ calendarProps, calendarState }) => {
    const { showId } = useParams();

    const datesFromPreviousMonth = getDatesFromPreviousMonth(
        dayjs(calendarState.visibleRange.start),
    ).map((date) => dayjs(date).format('YYYY-MM-DD'));

    const datesFromNextMonth = getDatesFromNextMonth(dayjs(calendarState.visibleRange.start)).map(
        (date) => dayjs(date).format('YYYY-MM-DD'),
    );

    const startDate =
        datesFromPreviousMonth[0] ?? dayjs(calendarState.visibleRange.start).format('YYYY-MM-DD');
    const endDate =
        datesFromNextMonth[datesFromNextMonth.length - 1] ??
        dayjs(calendarState.visibleRange.end).format('YYYY-MM-DD');

    const socialMediaPosts = useSocialNetworkCalendarPostQuery({ showId, startDate, endDate });

    const calendarIsLoading = socialMediaPosts.isLoading || socialMediaPosts.isError;

    return (
        <CalendarWrapper>
            {calendarIsLoading ? (
                <CalendarSkeleton />
            ) : (
                <Calendar {...calendarProps}>
                    <CalendarGrid
                        state={calendarState}
                        messages={socialMediaPosts.data?.days}
                        startDate={startDate}
                        endDate={endDate}
                    />
                </Calendar>
            )}
            <CalendarSidebar />
        </CalendarWrapper>
    );
};

const CalendarWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;
const Calendar = styled.div`
    height: 100%;
`;

SocialMediaCalendar.propTypes = {
    calendarProps: PropTypes.object,
    calendarState: PropTypes.object,
    messages: PropTypes.array,
};

export default SocialMediaCalendar;
