import { useEffect, useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Text from '../Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import IconButton from '../IconButton';
import { motion } from 'framer-motion';
import { UI_VARIANTS } from '@/utils/constants';
import { useToast } from '@react-aria/toast';

const animationProps = {
    initial: { opacity: 0, y: 100 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 100 },
    transition: { type: 'spring', stiffness: 400, damping: 24, mass: 1 },
};

/* 
    TODO: Handle progressbar pause on hover 
    Note: difficulty to store and reuse remaining time after hover) 
*/
const Toast = ({ state, ...props }) => {
    const [progress, setProgress] = useState(0);
    const hasProgressBar = useMemo(
        () => props.toast.timeout && !!props.toast.timer && props.toast.progressBar,
        [props.toast.timeout, props.toast.timer, props.toast.progressBar],
    );
    let ref = useRef(null);
    let { toastProps, titleProps, closeButtonProps } = useToast(props, state, ref);
    const { timer, timeout } = props.toast;

    useEffect(() => {
        let animationFrameId;

        if (!hasProgressBar) return;

        const updateProgress = () => {
            const currentTime = Date.now();
            const elapsedTime = currentTime - timer?.startTime;
            const remainingTime = Math.max(0, timeout - elapsedTime);

            setProgress(remainingTime);

            if (remainingTime === 0) {
                cancelAnimationFrame(animationFrameId);
                return;
            }

            animationFrameId = requestAnimationFrame(updateProgress);
        };

        animationFrameId = requestAnimationFrame(updateProgress);

        return () => {
            if (animationFrameId) {
                cancelAnimationFrame(animationFrameId);
            }
        };
    }, [hasProgressBar, timer?.startTime, timeout]);

    return (
        <Container {...animationProps} {...toastProps} ref={ref}>
            <InnerWrapper hasProgressBar={hasProgressBar}>
                {props.toast.icon}
                <Text {...titleProps} variant="bodyM" fontWeight="--fw-semibold">
                    {props.toast.content}
                </Text>
                <IconButton
                    {...closeButtonProps}
                    variant="ghost"
                    icon={<CloseIcon icon={icon({ name: 'xmark', style: 'solid' })} />}
                />
            </InnerWrapper>
            {hasProgressBar && (
                <Progress variant={props.toast.variant} max={timeout} value={progress} />
            )}
        </Container>
    );
};

const Container = styled(motion.li)`
    display: flex;
    flex-direction: column;
    column-gap: 0.5rem;
    width: max-content;
    max-width: 100%;
    border-radius: var(--r-m);
    background-color: var(--white);
    box-shadow: var(--s-m);
    overflow: hidden;

    ${({ theme }) => theme.mediaQueries.tabletAndUp} {
        max-width: 45rem;
    }
`;
const InnerWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    padding: 0.75rem;

    & > p {
        flex: 1;
    }

    ${({ hasProgressBar }) =>
        hasProgressBar &&
        css`
            padding-bottom: 0.5;
        `}
`;
const CloseIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--neutral500);
`;
const Progress = styled.progress`
    height: 0.25rem;
    width: 100%;
    margin-top: auto;
    border-radius: var(--r-full);

    &::-webkit-progress-bar {
        background-color: var(--primary50);
    }
    &::-webkit-progress-value {
        background-color: var(--primary);
        border-radius: var(--r-full);
    }
    &::-moz-progress-bar {
        background-color: var(--primary);
        border-radius: var(--r-full);
    }

    ${({ variant }) =>
        Object.values(UI_VARIANTS).includes(variant) &&
        css`
            &::-webkit-progress-bar {
                background-color: ${variant === UI_VARIANTS.NEUTRAL
                    ? `var(--neutral100)`
                    : `var(--${variant}50)}`};
            }
            &::-webkit-progress-value {
                background-color: ${`var(--${variant})`};
            }
            &::-moz-progress-bar {
                background-color: ${`var(--${variant})`};
            }
        `}
`;

Toast.propTypes = {
    toast: PropTypes.shape({
        icon: PropTypes.node,
        content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
        variant: PropTypes.oneOf(Object.values(UI_VARIANTS)),
        timeout: PropTypes.number,
        timer: PropTypes.shape({
            callback: PropTypes.func.isRequired,
            remaining: PropTypes.number.isRequired,
            startTime: PropTypes.number.isRequired,
            timerId: PropTypes.number,
        }),
        progressBar: PropTypes.bool,
    }),
    state: PropTypes.shape({}).isRequired,
};

Toast.defaultProps = {
    toast: {
        progressBar: false,
    },
};

export default Toast;
