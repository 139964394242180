import { useMutation, useQueryClient } from '@tanstack/react-query';
import monetizationPlatformKeys from './monetizationPlatformKeys';
import { addPodcornOnShow } from '@/api';

const mutationFn = async ({ showId }) => {
    const { data } = await addPodcornOnShow(showId);
    return data;
};

const useAddPodcornMonetizationMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: (data, { showId }) =>
            queryClient.invalidateQueries({
                queryKey: monetizationPlatformKeys.detailByShowId(showId),
            }),
    });
};

export default useAddPodcornMonetizationMutation;
