import { useQuery } from '@tanstack/react-query';
import { fetchGeoStats } from '@/api';
import { PRICING } from '@/utils/pricing';
import dayjs from 'dayjs';

export const useCityGeoStats = ({
    showId,
    start,
    end,
    episodes,
    campaign,
    region,
    currentLayerLevel,
    plan,
}) => {
    return useQuery({
        queryKey: ['stats', 'geostats', 'cities', showId, start, end, episodes, campaign, region],
        queryFn: async () => {
            const data = await fetchGeoStats(showId, {
                /**
                 * Test values
                 * start: dayjs('12-25-2020', 'MM-DD-YYYY').format('YYYY-MM-DD'),
                 * end: dayjs('03-29-2023', 'MM-DD-YYYY').format('YYYY-MM-DD'),
                 */
                start: dayjs(start).format('YYYY-MM-DD'),
                end: dayjs(end).format('YYYY-MM-DD'),
                podcasts: episodes,
                campaign,
                group_by: 'city',
                region,
            });

            if (!data.citiesData?.features) return [];

            const totalDownloads = data.citiesData.features.reduce(
                (acc, city) => acc + parseInt(city.properties.downloads, 10),
                0,
            );

            return {
                ...data.citiesData,
                features: data.citiesData.features.map((city) => ({
                    ...city,
                    properties: {
                        ...city.properties,
                        weight: city.weight || 0,
                        percentage: (
                            (parseInt(city.properties.downloads, 10) / totalDownloads) *
                            100
                        ).toFixed(2),
                    },
                })),
            };
        },
        enabled:
            !!region &&
            currentLayerLevel !== 'countries' &&
            [PRICING.SUPERSONIC, PRICING.ENTERPRISE].includes(plan),
    });
};
