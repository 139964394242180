import { useContext } from 'react';
import Responsive from '@/context/responsive.context';

const MobileOrTablet = ({ children }) => {
    const { isMobileOrTablet } = useContext(Responsive);
    if (!isMobileOrTablet) return null;
    return <>{children}</>;
};

export default MobileOrTablet;
