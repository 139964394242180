import { useState } from 'react';
import useRouterStore from '@hooks/useRouterStore';
import { useCampaign } from './useCampaign.hook';
import { useParams } from 'react-router-dom';
import { useAmplitudeLogEvent } from '@hooks/useAmplitude';
import { observer } from 'mobx-react';
import { CAMPAIGN_STATUS } from '@/utils/constants';
import Modal from '@/components/ui/atoms/Modal';
import styled from 'styled-components';
import CampaignEditForm from '../CampaignEditForm';

const CampaignEdit = () => {
    const routerStore = useRouterStore();
    const { campaignId, showId } = useParams();
    const { data: campaign } = useCampaign(campaignId);
    useAmplitudeLogEvent('show form campaign', {}, !!campaignId);
    const isNew = campaign?.status === CAMPAIGN_STATUS.DRAFT;
    const onSubmit = campaign?.update;
    const onAfterSubmit = () => {
        if (isNew) return;
        routerStore.push('menu.monetize.manual', { showId });
    };
    const [isModalOpen, setIsModalOpen] = useState(!!campaignId);

    return (
        <>
            <FullPageModal isOpen={isModalOpen} onOpenChange={setIsModalOpen}>
                {campaign && (
                    <CampaignEditForm
                        isNew={isNew}
                        onSubmit={onSubmit}
                        onAfterSubmit={onAfterSubmit}
                        campaign={campaign}
                    />
                )}
            </FullPageModal>
        </>
    );
};

const FullPageModal = styled(Modal)`
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    background: white;
    max-width: 100%;
    border-radius: 0;
    overflow-y: auto;
`;

export default observer(CampaignEdit);
