import useShowQuery from '@/queries/show/useShowQuery.hook';
import useUserQuery from '@/queries/user/useUserQuery.hook';
import { ENABLE_PSO_CHECKER, USER_ROLES, UserRole } from '../constants';
import { USER_OPTIONS } from '../userOptions';

interface HasAccessToPsoCheckerProps {
    showId: string;
    mode: 'read+write' | 'read';
}

const useHasAccessToPsoChecker = ({ showId, mode }: HasAccessToPsoCheckerProps) => {
    const { data: show } = useShowQuery(showId);
    const { data: user } = useUserQuery();

    const allGuestRoles: Partial<UserRole>[] = [
        USER_ROLES.VIEWER,
        USER_ROLES.EDITOR,
        USER_ROLES.ADMIN,
    ];

    const guestRoleCheck =
        show &&
        show?.userRole &&
        (mode === 'read+write'
            ? show?.userRole === USER_ROLES.ADMIN
            : allGuestRoles.includes(show.userRole as UserRole));

    const guestWithAccess = guestRoleCheck && show?.userOptions.includes(USER_OPTIONS.PSO_CHECKER);
    const ownerWithAccess =
        show?.userRole === USER_ROLES.OWNER && user?.options.includes(USER_OPTIONS.PSO_CHECKER);
    const hasPsoCheckerUserOption = guestWithAccess || ownerWithAccess;

    return ENABLE_PSO_CHECKER && hasPsoCheckerUserOption;
};

export default useHasAccessToPsoChecker;
