import { FormattedMessage } from 'react-intl';
import Modal from '@ui/molecules/Modal';
import Cluster from '@ui/layout/Cluster';
import DeprecatedButton from '@ui/atoms/DeprecatedButton';
import Spinner from '@ui/atoms/Spinner';
import { DeprecatedText } from '@ui/atoms/DeprecatedText';
import Stack from '@ui/layout/Stack';

const ContinueLaterModal = ({ isOpened, onClose, onContinueLater, isLoading = false }) => (
    <>
        {isOpened && (
            <Modal
                isOpen={isOpened}
                onClose={onClose}
                size="xs"
                title={
                    <FormattedMessage defaultMessage="Souhaitez-vous quitter l’édition du clip ?" />
                }
                action={
                    <Cluster $gap="0.5rem" $justify="flex-end">
                        <DeprecatedButton
                            accent="secondary"
                            mr={10}
                            onClick={onClose}
                            disabled={isLoading}
                        >
                            <FormattedMessage defaultMessage="Annuler" />
                        </DeprecatedButton>
                        <DeprecatedButton onClick={onContinueLater} disabled={isLoading}>
                            {isLoading && <Spinner />}
                            <FormattedMessage defaultMessage="Quitter l’édition" />
                        </DeprecatedButton>
                    </Cluster>
                }
            >
                <Stack $gap="1.75rem">
                    <DeprecatedText>
                        <FormattedMessage defaultMessage="Vous pourrez reprendre l'édition où vous vous étiez arrêté." />
                    </DeprecatedText>
                </Stack>
            </Modal>
        )}
    </>
);

export default ContinueLaterModal;
