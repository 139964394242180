import styled from 'styled-components';
import { reflex } from '@ui/reflex';

const DeprecatedToggle = styled.input.attrs({
    type: 'checkbox',
})`
    position: relative;
    cursor: pointer;
    vertical-align: bottom;
    min-width: 35px;
    height: 18px;
    border-radius: var(--r-m);
    appearance: none;
    background: var(--neutral500);
    transition: left 300ms;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: var(--r-m);
        opacity: 0;
        transform-origin: 0 0;
        background: var(--gradient-primary);
        transition: all 350ms;
    }

    &:after {
        content: '';
        position: absolute;
        width: 14px;
        height: 14px;
        border-radius: var(--r-full);
        top: 50%;
        left: 2px;
        background: var(--white);
        transform: translateY(-50%);
        transition: left 300ms;
    }

    &:checked {
        &:before {
            opacity: 1;
        }
        &:after {
            left: calc(100% - 16px);
        }
    }

    &:disabled {
        pointer-events: none;
        opacity: 0.5;
    }
`;

export default reflex(DeprecatedToggle);
