import styled from 'styled-components';
import { useState } from 'react';
import EpisodeAIBubblePopover from './EpisodeAIBubblePopover';
import { ENABLE_EPISODE_AI } from '@/shared/config/constants';
import IconButton from '@/components/IconButton';
import NebulaSparklesIcon from '@/components/ui/NebulaSparklesIcon';

const EpisodeAIBubble = () => {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    if (!ENABLE_EPISODE_AI) return null;

    return (
        <>
            {!isPopoverOpen && (
                <AIButton
                    icon={<Sparkles />}
                    onPress={() => setIsPopoverOpen(true)}
                    aria-label="Open AI Panel"
                    data-testid="episode-ai-bubble-button"
                />
            )}
            <EpisodeAIBubblePopover isOpen={isPopoverOpen} onOpenChange={setIsPopoverOpen} />
        </>
    );
};

const AIButton = styled(IconButton)`
    position: fixed;
    bottom: 5rem;
    right: 1rem;
    width: 4rem;
    height: 4rem;
    background: var(--gradient-aurora);
    border-radius: var(--r-full);
    z-index: 100;
    padding: 0;

    ${(props) => props.theme.mediaQueries.desktopAndUp} {
        display: none;
    }
`;

const Sparkles = styled(NebulaSparklesIcon)`
    width: 1.5rem;
    height: 1.5rem;
`;

export default EpisodeAIBubble;
