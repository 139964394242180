import { useEffect, useState } from 'react';
import EpisodeAISuggestionsPanel from '../EpisodeAISuggestionsPanel';
import { FormattedMessage } from 'react-intl';
import { useQueryClient } from '@tanstack/react-query';
import aiKeys from '@/queries/ai/aiKeys';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router';
import { sendAmplitudeLogEvent } from '@/helpers';
import useAINewsletterMessageSuggestionsQuery from '@/queries/ai/useAINewsletterMessageSuggestionQuery.hook';
import { useEpisodeAISettings } from '../contexts/EpisodeAISettingsContext';
import { useEpisodeAILoading } from '../contexts/EpisodeAILoadingContext';

interface EpisodeAINewsletterSuggestionPanelProps {
    setLoadingContent: (content: string) => void;
}

const EpisodeAINewsletterSuggestionPanel = ({
    setLoadingContent,
}: EpisodeAINewsletterSuggestionPanelProps) => {
    const { episodeId } = useParams<{ episodeId: string }>();
    const [settings] = useEpisodeAISettings();
    const newsletterSettings = settings.get('newsletter') || {
        customPrompt: '',
        saveAsDefault: false,
    };
    const newsletterMessageSuggestionsQuery = useAINewsletterMessageSuggestionsQuery({
        episodeId,
        settings: newsletterSettings,
        delay: 2000,
    });
    const [state] = useEpisodeAILoading();
    const isLoading = state.get('newsletter-message') || false;
    const queryClient = useQueryClient();
    const { setValue } = useFormContext();
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (isLoading) {
            setIsOpen(true);
        }
    }, [isLoading]);

    useEffect(() => {
        // If the query is successful and the panel displayed, set the value of the form field
        if (newsletterMessageSuggestionsQuery.data && newsletterMessageSuggestionsQuery.isSuccess) {
            const suggestion = newsletterMessageSuggestionsQuery.data || '';
            const formattedString = suggestion.replace(/\n+/g, '<p></p>');
            setLoadingContent(formattedString);
            setValue('messageTpl', formattedString, {
                shouldDirty: true,
            });
        }
    }, [newsletterMessageSuggestionsQuery.data, newsletterMessageSuggestionsQuery.isSuccess]);

    if (!isOpen) return null;

    const handleClose = () => {
        // Reset the suggestions query when the panel is closed
        queryClient.resetQueries({ queryKey: aiKeys.newsletterMessage() });
        setIsOpen(false);
    };

    const handleDislike = () => {
        sendAmplitudeLogEvent('AI Dislike', {
            field: 'newsletter',
            custom_prompt: newsletterSettings.customPrompt || '',
            suggestions: newsletterMessageSuggestionsQuery.data || '',
        });
    };

    return (
        <EpisodeAISuggestionsPanel
            title={<FormattedMessage defaultMessage="Votre newsletter est prête !" />}
            type="newsletter-message"
            isLoading={isLoading}
            onClose={handleClose}
            data-testid="episode-ai-newsletter-suggestions-panel"
            onDislike={handleDislike}
        />
    );
};

export default EpisodeAINewsletterSuggestionPanel;
