import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import UiKitTooltip from '@ui/atoms/UiKitTooltip';
import Text from '@ui/atoms/Text';
import CircularProgress from '@ui/atoms/CircularProgress';
import { FormattedMessage } from 'react-intl';
import Bars from './Bars';
import PsoTopSkeleton from './PsoTopSkeleton';
import dayjs from 'dayjs';
import styled from 'styled-components';

const PsoTop = ({ top, nbKeywordsInTop, totalKeywords, trend, rankDiff, history, isLoading }) => {
    if (isLoading) return <PsoTopSkeleton />;

    const formatPeriod = (period) => {
        const start = dayjs(period.start);
        const end = dayjs(period.end);

        if (start.month() === end.month()) return `${start.format('DD')}-${end.format('DD MMM')}`;
        return `${start.format('DD MMM')}-${end.format('DD MMM')}`;
    };
    return (
        <TopWrapper>
            <TitleWrapper>
                <Title>Top {top}</Title>
                <Tooltip
                    variant="dark"
                    content={
                        <FormattedMessage
                            defaultMessage="Le nombre de mots-clés pour lesquels votre podcast apparaît dans le {top} premiers résultats de recherche sur les 7 derniers jours."
                            values={{
                                top,
                            }}
                        />
                    }
                    position="top"
                >
                    <HelpIcon icon={icon({ name: 'circle-info', style: 'solid' })} />
                </Tooltip>
            </TitleWrapper>
            <ContentWrapper>
                <CurrentStateWrapper>
                    <CircularProgress
                        size={60}
                        strokeWidth={6}
                        percentage={(nbKeywordsInTop / totalKeywords) * 100}
                    />
                    <CurrentStateInnerWrapper>
                        <FormattedMessage defaultMessage="Nbr. mots-clés" />
                        {nbKeywordsInTop ? (
                            <Trend>
                                <NbKeywords>{nbKeywordsInTop}</NbKeywords>
                                <TrendInnerWrapper trend={trend}>
                                    {['up', 'new'].indexOf(trend) > -1 ? (
                                        <TrendIcon
                                            icon={icon({ name: 'arrow-trend-up', style: 'solid' })}
                                            trend={trend}
                                        />
                                    ) : ['down', 'exited'].indexOf(trend) > -1 ? (
                                        <TrendIcon
                                            icon={icon({
                                                name: 'arrow-trend-down',
                                                style: 'solid',
                                            })}
                                            trend={trend}
                                        />
                                    ) : null}
                                    <RankDiff trend={trend}>
                                        {['up', 'new'].indexOf(trend) > -1
                                            ? `+${rankDiff}`
                                            : ['down', 'exited'].indexOf(trend) > -1
                                            ? `${rankDiff}`
                                            : null}
                                    </RankDiff>
                                </TrendInnerWrapper>
                            </Trend>
                        ) : (
                            <EmptyState />
                        )}
                    </CurrentStateInnerWrapper>
                </CurrentStateWrapper>
                {nbKeywordsInTop ? (
                    <ChartWrapper>
                        <Bars
                            values={history.map((item) => ({
                                value: item.keywordsCount,
                                period: formatPeriod(item.timePeriod),
                            }))}
                        />
                    </ChartWrapper>
                ) : null}
            </ContentWrapper>
        </TopWrapper>
    );
};

const TopWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1.5rem;
`;
const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
`;
const Title = styled(Text)`
    font-weight: var(--fw-bold);
    font-size: var(--fs-body-m);
`;
const HelpIcon = styled(FontAwesomeIcon)`
    color: var(--neutral500);
`;
const ContentWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const CurrentStateWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex-shrink: 0;
    flex-basis: 12rem;
`;
const CurrentStateInnerWrapper = styled.div`
    font-size: var(--fs-body-m);
`;
const Trend = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;
const NbKeywords = styled.div`
    font-weight: var(--fw-bold);
`;
const TrendInnerWrapper = styled.div`
    color: ${({ trend }) =>
        ['up', 'new'].indexOf(trend) > -1
            ? 'var(--success)'
            : ['down', 'exited'].indexOf(trend) > -1
            ? 'var(--alert)'
            : 'var(--neutral500)'};
    display: flex;
    align-items: center;
    gap: 0.25rem;
`;
const TrendIcon = styled(FontAwesomeIcon)``;
const RankDiff = styled(Text)`
    font-weight: var(--fw-semibold);
`;
const EmptyState = styled.div`
    width: 1rem;
    height: 2px;
    background-color: var(--neutral500);
    border-radius: var(--r-full);
    margin-block: 0.5rem;
`;
const ChartWrapper = styled.div`
    align-self: stretch;
    flex-grow: 0;
    flex-shrink: 0;
    position: relative;
`;
const Tooltip = styled(UiKitTooltip)`
    z-index: 10;
`;

PsoTop.propTypes = {
    top: PropTypes.oneOf([3, 20, 100]),
    nbKeywordsInTop: PropTypes.number,
    totalKeywords: PropTypes.number,
    trend: PropTypes.oneOf(['up', 'down', 'neutral']),
    rankDiff: PropTypes.number,
    history: PropTypes.array,
    isLoading: PropTypes.bool,
};

export default PsoTop;
