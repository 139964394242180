import { useState } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import { observer } from 'mobx-react';
import record from '@public/icons/record.svg';
import waveform from '@public/images/waveform.svg';
import download from '@public/icons/download.svg';
import clock from '@public/icons/clock.svg';
import calendar from '@public/icons/calendar.svg';
import posed, { PoseGroup } from 'react-pose';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import { reflex } from '@ui/reflex';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import Uploader from '@ui/smarties/Uploader';
import Button from '@ui/atoms/Button';
import IconUpload from '@ui/icons/IconUpload';
import { formatDuration } from '@/helpers';
import DeprecatedIcon from '@ui/atoms/DeprecatedIcon';
import ProgressCircle from '@ui/molecules/ProgressCircle';
import { ACCEPTED_AUDIO_TYPES, ANY_AUDIO_TYPE } from '@/utils/constants';
import { extractCssVariable } from '@/utils/cssVariables';
import IconError from '@ui/icons/IconError';
import { MAX_AUDIO_FILE_SIZE } from '@/utils/constants';
import UiKitTooltip from '@ui/atoms/UiKitTooltip';

const AudioFileImporterWrapper = styled.div`
    display: flex;
    flex-direction: column;

    ${(p) => p.theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
    }
`;
const ErrorMessage = styled.span`
    display: flex;
    align-items: center;
    color: var(--alert);
    font-weight: var(--fw-semibold);
    column-gap: 0.25rem;
    margin-bottom: 0.5rem;
`;
const NoPaddingButton = styled(Button)`
    padding: 0;

    ${(p) =>
        !p.isDisabled &&
        css`
            &:hover {
                span {
                    text-decoration: underline;
                }
            }
        `}
`;

const transition = { duration: 250 };

const AnimatedPaper = posed(DeprecatedPaper)({
    enter: { opacity: 1, x: 0, transition },
    exit: { opacity: 0, x: 50, transition },
});

const Waveform = reflex(waveform);

function AudioFileImporter({
    date,
    downloadCount,
    file,
    onUpload,
    onFileUpdate,
    onDelete,
    maxSize,
    accept,
    audioUrl,
    isDisabled,
    tooltip,
    message,
}) {
    const hasFile = !!file;
    const [error, setError] = useState(null);

    function isFileTypeAccepted(newFile) {
        return accept.includes(ANY_AUDIO_TYPE) || accept.includes(newFile.type);
    }

    async function onFileChange(newFile) {
        if (!isFileTypeAccepted(newFile)) {
            setError(<FormattedMessage defaultMessage="Ce format de fichier n'est pas supporté" />);
        } else if (maxSize && newFile.size > maxSize) {
            setError(
                <FormattedMessage
                    defaultMessage="Le fichier que vous avez fourni est trop volumineux (taille maximale: {max} Mo)"
                    values={{ max: maxSize / 1000000 }}
                />,
            );
        } else {
            setError(null);
            await onUpload(newFile);
            onFileUpdate && onFileUpdate();
        }
    }

    return (
        <AudioFileImporterWrapper>
            <Waveform
                w={270}
                fill={
                    hasFile ? extractCssVariable('--primary') : extractCssVariable('--neutral200')
                }
                mr={20}
                cssAnim
            />
            <PoseGroup animateOnMount>
                {hasFile ? (
                    <AnimatedPaper key={0}>
                        <DeprecatedText weight="semibold" mb={5}>
                            {file.name}
                        </DeprecatedText>
                        <FileMetadataWrapper>
                            {date && (
                                <DeprecatedText flex align="center" color="--neutral500" mr={25}>
                                    <DeprecatedIcon as={calendar} mr={5} color="--neutral500" />
                                    <FormattedDate value={date} />
                                </DeprecatedText>
                            )}
                            {downloadCount > 0 && (
                                <DeprecatedText flex align="center" color="--neutral500" mr={25}>
                                    <DeprecatedIcon as={download} mr={5} color="--neutral500" />
                                    {downloadCount}
                                </DeprecatedText>
                            )}
                            {file.duration && (
                                <DeprecatedText flex align="center" color="--neutral500">
                                    <DeprecatedIcon as={clock} mr={5} color="--neutral500" />
                                    {formatDuration(file.duration)}
                                </DeprecatedText>
                            )}
                        </FileMetadataWrapper>
                        {error && (
                            <ErrorMessage>
                                <IconError /> {error}
                            </ErrorMessage>
                        )}
                        <PoseGroup>
                            {file && file.isUploading ? (
                                <AnimatedPaper key={0} flex column>
                                    <DeprecatedText color="--neutral500" mb={15}>
                                        <FormattedMessage defaultMessage="Importation en cours..." />
                                    </DeprecatedText>
                                    <ProgressCircle progress={file.uploadProgress} />
                                    {/* <DeprecatedText color="--primary" ml={10} onClick={onCancel}> */}
                                    {/*    <FormattedMessage defaultMessage="Annuler l'envoi" /> */}
                                    {/* </DeprecatedText> */}
                                </AnimatedPaper>
                            ) : (
                                <AnimatedPaper key={1} mt={20} flex align="center">
                                    {audioUrl && (
                                        <>
                                            <DeprecatedText
                                                as="a"
                                                href={audioUrl}
                                                target="_blank"
                                                color="--primary"
                                                weight="semibold"
                                            >
                                                <FormattedMessage defaultMessage="Télécharger" />
                                            </DeprecatedText>
                                            <DeprecatedIcon
                                                as={record}
                                                size="4px"
                                                mx={10}
                                                color="--neutral200"
                                            />
                                        </>
                                    )}
                                    <UiKitTooltip
                                        passthrough={!tooltip}
                                        variant="dark"
                                        position="top"
                                        content={tooltip}
                                    >
                                        <Uploader
                                            forwardedAs="label"
                                            accept={accept}
                                            onChange={onFileChange}
                                        >
                                            <NoPaddingButton
                                                color="--primary"
                                                variant="ghost"
                                                isDisabled={isDisabled}
                                            >
                                                <FormattedMessage defaultMessage="Changer le fichier" />
                                            </NoPaddingButton>
                                        </Uploader>
                                    </UiKitTooltip>
                                    <DeprecatedIcon
                                        as={record}
                                        size="4px"
                                        mx={10}
                                        color="--neutral200"
                                    />
                                    <UiKitTooltip
                                        passthrough={!tooltip}
                                        variant="dark"
                                        position="top"
                                        content={tooltip}
                                    >
                                        <NoPaddingButton
                                            color="--primary"
                                            variant="ghost"
                                            onPress={() => onDelete()}
                                            isDisabled={isDisabled}
                                        >
                                            <FormattedMessage defaultMessage="Supprimer" />
                                        </NoPaddingButton>
                                    </UiKitTooltip>
                                </AnimatedPaper>
                            )}
                        </PoseGroup>
                    </AnimatedPaper>
                ) : (
                    <AnimatedPaper key={1}>
                        <DeprecatedText weight="semibold" mb={5}>
                            <FormattedMessage defaultMessage="Aucun fichier choisi" />
                        </DeprecatedText>
                        {error && (
                            <ErrorMessage>
                                <IconError /> {error}
                            </ErrorMessage>
                        )}
                        <Uploader accept={accept} onChange={onFileChange}>
                            <Button mb={15} startIcon={<IconUpload />}>
                                <FormattedMessage defaultMessage="Importer un fichier audio" />
                            </Button>
                        </Uploader>
                        <DeprecatedText color="--neutral500">
                            {message || (
                                <FormattedMessage
                                    defaultMessage="Poids max. : {size} mo {dot} Fichier .mp3"
                                    values={{
                                        size: MAX_AUDIO_FILE_SIZE / 1000000,
                                        dot: (
                                            <DeprecatedIcon
                                                as={record}
                                                size="4px"
                                                mx={10}
                                                verticalAlign="middle"
                                                color="--neutral200"
                                                css="display: inline-block;"
                                            />
                                        ),
                                    }}
                                />
                            )}
                        </DeprecatedText>
                    </AnimatedPaper>
                )}
            </PoseGroup>
        </AudioFileImporterWrapper>
    );
}

const FileMetadataWrapper = styled.div`
    display: flex;
`;

AudioFileImporter.defaultProps = {
    accept: ACCEPTED_AUDIO_TYPES,
    isDisabled: false,
};

export default observer(AudioFileImporter);
