interface QueryParams {
    start: string | null;
    end: string | null;
}

const statsKeys = {
    all: () => [{ entity: 'stats' }],
    downloads: (rawShowId: string, query: QueryParams) => [
        {
            ...statsKeys.all()[0],
            scope: 'downloads',
            showId: Number(rawShowId),
            query,
        },
    ],
    downloadsComparison: (
        rawShowId: string,
        daysAfterPublication: 7 | 15 | 30 | 90,
        episodeIds: number[],
    ) => [
        {
            ...statsKeys.all()[0],
            scope: 'comparison',
            showId: Number(rawShowId),
            daysAfterPublication,
            episodeIds,
        },
    ],
    genders: (rawShowId: string, query: QueryParams) => [
        {
            ...statsKeys.all()[0],
            scope: 'genders',
            showId: Number(rawShowId),
            query,
        },
    ],
    ages: (rawShowId: string, query: QueryParams) => [
        {
            ...statsKeys.all()[0],
            scope: 'ages',
            showId: Number(rawShowId),
            query,
        },
    ],
    agesExport: (rawShowId: string, query: QueryParams) => [
        {
            ...statsKeys.all()[0],
            scope: 'agesExport',
            showId: Number(rawShowId),
            query,
        },
    ],
};

export default statsKeys;
