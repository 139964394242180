import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const IconSkipBackwardFifteen = () => (
    <Wrapper>
        <IconBackwardRotate icon={icon({ name: 'rotate-left', style: 'solid' })} />
        <IconBackwardOne icon={icon({ name: '1', style: 'solid' })} />
        <IconBackwardFive icon={icon({ name: '5', style: 'solid' })} />
    </Wrapper>
);

const Wrapper = styled.div`
    position: relative;
    color: var(--neutral500);
    margin-top: 0.25rem;
`;

const IconBackwardRotate = styled(FontAwesomeIcon)`
    font-size: 1rem;
`;

const IconBackwardOne = styled(FontAwesomeIcon)`
    position: absolute;
    bottom: 5px;
    left: 0;
    width: 6px;
    height: 0.5rem;
    background: var(--neutral50);
`;

const IconBackwardFive = styled(FontAwesomeIcon)`
    position: absolute;
    bottom: 5px;
    left: 6px;
    width: 6px;
    height: 0.5rem;
    background: var(--neutral50);
`;

export default IconSkipBackwardFifteen;
