import styled, { css } from 'styled-components';
import WithoutTranscriptIllustration from '@public/images/clip-edit-without-transcript.svg';
import WithTranscriptIllustration from '@public/images/clip-edit-with-transcript.svg';
import Stack from '@/components/ui/layout/Stack';
import Text from '@/components/ui/atoms/Text';
import { FormattedMessage } from 'react-intl';
import { useFormContext } from 'react-hook-form';
import useClipTranscriptionSupportedLanguagesQuery from '@/queries/clip/useClipTranscriptionSupportedLanguagesQuery';
import { ComboBoxController } from '@/components/ui/atoms/ComboBox';
import ListBoxItem, { ListBoxItem as IListBoxItem } from '@/components/ui/atoms/ListBoxItem';
import { ClipFormSchema } from '../schemas/useFormSchema';

const TranscriptionSelection = () => {
    const form = useFormContext<ClipFormSchema>();
    const { transcript } = form.watch('transcriptionSelection');
    const { data: supportedLanguages, isLoading } = useClipTranscriptionSupportedLanguagesQuery();
    const languagesItems =
        Object.entries(supportedLanguages ?? {})?.map(([key, value]) => ({
            id: key,
            name: value,
        })) || [];

    const handleDurationChange = (isTranscript: boolean) => {
        form.setValue('transcriptionSelection.transcript', isTranscript, {
            shouldDirty: true,
            shouldValidate: true,
        });
    };

    return (
        <Wrapper>
            <Card $isActive={!transcript} onClick={() => handleDurationChange(false)}>
                <WithoutTranscriptIllustration />
                <Stack $gap="0.5rem">
                    <Text variant="bodyL" fontWeight="--fw-semibold">
                        <FormattedMessage defaultMessage="Sans transcription" />
                    </Text>
                    <Text color="--neutral500">
                        <FormattedMessage defaultMessage="Le clip vidéo classique avec deux titres personnalisables." />
                    </Text>
                </Stack>
            </Card>
            <Stack $gap="1rem">
                <Card $isActive={transcript} onClick={() => handleDurationChange(true)}>
                    <WithTranscriptIllustration />
                    <Stack $gap="0.5rem">
                        <Text variant="bodyL" fontWeight="--fw-semibold">
                            <FormattedMessage defaultMessage="Avec transcription" />
                        </Text>
                        <Text color="--neutral500">
                            <FormattedMessage defaultMessage="Le clip vidéo avec transcription automatique et modifiable." />
                        </Text>
                    </Stack>
                </Card>
                {transcript && (
                    // @ts-ignore
                    <ComboBoxController
                        control={form.control}
                        name="transcriptionSelection.lang"
                        defaultItems={languagesItems}
                        label={<FormattedMessage defaultMessage="Langue" />}
                        isLoading={isLoading}
                    >
                        {(item: IListBoxItem) => <ListBoxItem {...item} />}
                    </ComboBoxController>
                )}
            </Stack>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    width: 100%;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
        justify-content: center;
        column-gap: 2rem;
    }
`;
const Card = styled.div<{ $isActive: boolean }>`
    cursor: pointer;
    display: flex;
    gap: 1rem;
    background-color: var(--white);
    border-radius: var(--r-s);
    padding: 1rem;
    text-align: left;
    border: solid 1px transparent;
    transition: border-color 0.2s, background-color 0.2s;

    & > svg {
        flex-shrink: 0;
        width: 6rem;

        ${({ theme }) => theme.mediaQueries.desktopAndUp} {
            width: 12rem;
        }
    }

    & > :last-child {
        flex-grow: 1;
    }

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-direction: column;
        text-align: center;
        align-items: center;
        height: fit-content;
        padding: 2rem;
        max-width: 20rem;
    }

    ${({ $isActive }) =>
        $isActive
            ? css`
                  background-color: var(--primary50);
                  border-color: var(--primary500);
              `
            : css`
                  &:hover {
                      border-color: var(--neutral200);
                  }
              `}
`;

export default TranscriptionSelection;
