import { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Button from '../Button';
import InputText from '../InputText';
import Stack from '@ui/layout/Stack';
import { useEffect } from 'react';

const ProtectedInputText = ({ onSave, value, isLoading, ...props }) => {
    const [isEditable, setIsEditable] = useState(false);
    const [newValue, setNewValue] = useState(value);

    useEffect(() => {
        setNewValue(value);
    }, [value]);

    const handleSave = () => {
        onSave && onSave(newValue);
        setIsEditable(false);
    };

    const onCancel = () => {
        setNewValue(value);
        setIsEditable(false);
    };

    const handleChange = (value) => {
        setNewValue(value);
    };

    return (
        <Stack $gap="1rem">
            <InputText
                {...props}
                value={newValue}
                onChange={handleChange}
                isReadOnly={!isEditable}
                customButton={
                    !isEditable && (
                        <NoPaddingButton
                            color="--primary"
                            variant="ghost"
                            onPress={() => setIsEditable(true)}
                        >
                            <FormattedMessage defaultMessage="Modifier" />
                        </NoPaddingButton>
                    )
                }
            />
            {isEditable && (
                <Wrapper>
                    <Line
                        viewBox="0 0 659 18"
                        preserveAspectRatio="none"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0.999987 0.999971L0.999987 8.99878C0.999987 13.4175 4.58244 16.9994 9.00118 16.9988L658 16.902"
                            stroke="#F0EDF2"
                            strokeWidth="2"
                            strokeLinecap="round"
                        />
                    </Line>
                    <EditButtonsWrapper>
                        <NoPaddingButton
                            isDisabled={isLoading}
                            onPress={onCancel}
                            color="--neutral500"
                            variant="ghost"
                        >
                            <FormattedMessage defaultMessage="Annuler" />
                        </NoPaddingButton>
                        <SaveButton isLoading={isLoading} onPress={handleSave}>
                            <FormattedMessage defaultMessage="Enregistrer" />
                        </SaveButton>
                    </EditButtonsWrapper>
                </Wrapper>
            )}
        </Stack>
    );
};

const Wrapper = styled.div`
    padding-left: 0.5rem;
    display: flex;
    align-items: center;
    column-gap: 1rem;
    width: 100%;
`;
const SaveButton = styled(Button)`
    box-shadow: var(--s-primary);
`;
const EditButtonsWrapper = styled.div`
    display: flex;
    justify-content: end;
    column-gap: 1rem;
`;
const NoPaddingButton = styled(Button)`
    padding: 0;
    overflow: visible;
`;
const Line = styled.svg`
    flex: 1;
    height: 1rem;
`;

ProtectedInputText.propTypes = {
    onSave: PropTypes.func,
    value: PropTypes.string,
    isLoading: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
ProtectedInputText.defaultProps = {
    onSave: () => {},
    isLoading: false,
};

export default ProtectedInputText;
