import styled, { keyframes } from 'styled-components';
import { motion } from 'framer-motion';
import CheckedCircleIcon from '@public/icons/checkedcircle.svg';
import CircleIcon from '@public/icons/circle.svg';
import LoaderIcon from '@public/icons/loader.svg';

const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

const CompletedIcon = styled(CheckedCircleIcon)`
    height: 1.25rem;
    width: 1.25rem;
    flex-shrink: 0;
    fill: var(--success);
`;

const LoadingIcon = styled(LoaderIcon)`
    height: 1.25rem;
    width: 1.25rem;
    flex-shrink: 0;
    fill: var(--info);
    animation: ${rotate} 600ms linear infinite;
`;

const Icon = styled(motion(CircleIcon))`
    height: 1.25rem;
    width: 1.25rem;
    flex-shrink: 0;
    fill: var(--neutral500);
`;

const CheckIcon = ({ isLoading, isCompleted }) => {
    if (isCompleted) {
        return <CompletedIcon />;
    }
    if (isLoading) {
        return <LoadingIcon />;
    }

    return <Icon />;
};

export default CheckIcon;
