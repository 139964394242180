import { FormattedMessage } from 'react-intl';
import DeprecatedModal from '../../ui/molecules/DeprecatedModal';
import DeprecatedButton from '../../ui/atoms/DeprecatedButton';
import DeprecatedText from '../../ui/atoms/DeprecatedText';
import { connect } from '../decorators/connect';
import Spinner from '../../ui/atoms/Spinner';
import styled from 'styled-components';

const enhance = connect(({ routerStore }) => ({
    link: routerStore,
}));

function DeletePodcastsModal({
    isOpened,
    onClose,
    onDelete,
    count,
    deleting,
    isRedirect = false,
    link,
}) {
    const submitDelete = async () => {
        await onDelete();
        onClose();
        if (isRedirect) link.push('menu.episodes');
    };
    return (
        <DeprecatedModal
            w={550}
            title={
                <FormattedMessage defaultMessage="Êtes-vous sûr de vouloir supprimer les épisodes ?" />
            }
            isOpened={isOpened}
            onClose={onClose}
        >
            <ModalContentWrapper>
                <DeprecatedText>
                    <FormattedMessage
                        defaultMessage="{count, plural, one {L'épisode sera supprimé} other {# épisodes seront supprimés}} définitivement de votre émission et de toutes les autres plateformes où il a été publié. Cette action est irréversible."
                        values={{ count }}
                    />
                </DeprecatedText>
                <ActionWrapper>
                    <DeprecatedButton accent="secondary" mr={10} onClick={onClose}>
                        <FormattedMessage defaultMessage="Annuler" />
                    </DeprecatedButton>
                    <DeprecatedButton onClick={() => submitDelete()} disabled={deleting}>
                        {deleting && <Spinner />}
                        <FormattedMessage
                            defaultMessage="Supprimer {count, plural, one {l'épisode} other {les épisodes}}"
                            values={{ count }}
                        />
                    </DeprecatedButton>
                </ActionWrapper>
            </ModalContentWrapper>
        </DeprecatedModal>
    );
}

const ModalContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
`;
const ActionWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export default enhance(DeletePodcastsModal);
