import { useRef } from 'react';
import styled from 'styled-components';
import ErrorMessage from '@/components/ui/atoms/ErrorMessage';
import { dropZoneStateStyles, dropZoneStyles } from '../styles';
import { DropUploaderProps } from '../types';
import { Button as RACButton, FileTrigger, DropZone as RACDropZone } from 'react-aria-components';
import { DropEvent, FileDropItem } from 'react-aria';

const DropFileUploader = ({
    onChange,
    hasError,
    errorMessage,
    children,
    className,
    allowsMultiple = false,
    acceptedFileTypes,
    defaultCamera,
    isDisabled = false,
    ...props
}: DropUploaderProps) => {
    const triggerButtonRef = useRef<HTMLButtonElement>(null);

    const handleChange = (files: File[]) => {
        if (!files || files.length < 1) {
            onChange?.(null);
            return;
        }
        onChange?.(files);
    };

    const handleDrop = async (e: DropEvent) => {
        let files = e.items.filter((file) => file.kind === 'file') as FileDropItem[];
        const resolvedFiles = await Promise.all(files.map((file) => file.getFile()));
        handleChange(resolvedFiles);
        props.onDrop?.(e);
    };

    const handleSelectFiles = (e: FileList | null) => {
        let files = e ? Array.from(e) : [];
        handleChange(files);
        props.onSelect?.(e);
    };

    const handleTriggerInput = () => {
        triggerButtonRef.current?.click();
    };

    return (
        <DropZone
            {...props}
            isDisabled={isDisabled}
            $hasError={hasError}
            className={className}
            onDrop={handleDrop}
        >
            <DropZoneInner onClick={handleTriggerInput}>
                {children}
                {hasError && <ErrorMessage hasIcon>{errorMessage}</ErrorMessage>}
            </DropZoneInner>
            <FileTrigger
                onSelect={handleSelectFiles}
                allowsMultiple={allowsMultiple}
                acceptedFileTypes={acceptedFileTypes}
                defaultCamera={defaultCamera}
            >
                <HiddenTriggerButton isDisabled={isDisabled} ref={triggerButtonRef} />
            </FileTrigger>
        </DropZone>
    );
};

const DropZone = styled(RACDropZone)`
    ${dropZoneStyles}
    ${dropZoneStateStyles}
`;
const HiddenTriggerButton = styled(RACButton)`
    display: none;
`;
const DropZoneInner = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
    justify-content: center;
    align-items: center;
`;
export default DropFileUploader;
