import showKeys from '@/queries/show/showKeys';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { fetchMinimalShows } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import { z } from 'zod';

const MinimalShowSchema = z.object({
    id: z.number(),
    name: z.string(),
    imageUrl: z.string().optional().nullable(),
    archived: z.boolean(),
    isOwner: z.boolean(),
});

const MinimalShowsPaginationSchema = z.object({
    count: z.number(),
    currentPage: z.number(),
    perPage: z.number(),
    total: z.number(),
    totalPages: z.number(),
});

export const MinimalShowsSchema = z.object({
    shows: z.array(MinimalShowSchema),
    pagination: MinimalShowsPaginationSchema,
});

export type MinimalShow = z.infer<typeof MinimalShowSchema>;
export type MinimalShows = z.infer<typeof MinimalShowsSchema>;

const queryFn = async () => {
    const { data, meta } = await fetchMinimalShows();
    const transformedData = camelcaseKeys({ data, meta }, { deep: true });

    const resultData = {
        shows: transformedData.data,
        pagination: transformedData.meta.pagination,
    };

    // TODO: Do camelcase transformation in Ax ios interceptor
    return MinimalShowsSchema.parse(resultData);
};

interface ShowsQueryProps {
    select?: (data: MinimalShows) => MinimalShows;
}

const useShowsQuery = (options?: ShowsQueryProps): UseQueryResult<MinimalShows> =>
    useQuery({
        queryKey: showKeys.list(),
        queryFn,
        ...options,
    });

export default useShowsQuery;
