import styled, { css } from 'styled-components';
import { reflex } from '@ui/reflex';
import DeprecatedText from '../DeprecatedText';
import { computeCssValue } from '@/utils/css';

const MenuItem = styled(DeprecatedText)`
    display: flex;
    align-items: center;
    height: ${(p) => (p.height || p.h ? computeCssValue(p.height || p.h) : '2.25rem')};
    text-decoration: none;
    list-style-type: none;
    font-weight: var(--fw-normal);
    font-size: var(--fs-body);
    border-radius: var(--r-xs);
    color: var(--black);
    cursor: pointer;
    padding-left: ${(p) => p.pl || '12px'};
    padding-right: 12px;
    background: transparent;
    gap: 10px;
    ${(p) =>
        p.active &&
        css`
            background: var(--neutral50);
        `};

    &:hover {
        background: var(--neutral50);
        text-decoration: none;
    }

    svg {
        fill: ${(p) => (p.color ? p.color : 'var(--neutral500)')};
        width: 14px;
        height: 14px;
    }
`;

MenuItem.defaultProps = {
    as: 'li',
};

export default reflex(MenuItem);
