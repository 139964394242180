import { useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import IconButton from '@/components/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MenuTrigger, Popover } from 'react-aria-components';
import MenuDropdown from '@/components/ui/atoms/MenuDropdown';
import { useParams } from 'react-router';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import { USER_ROLES } from '@/shared/config/constants';
import MenuDropdownItem from '@/components/ui/atoms/MenuDropdown/MenuDropdownItem';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import DeleteClipModal from '../DeleteModal/DeleteModal';
import deleteClipFormatMutation from '@/queries/clipFormat/deleteClipFormatMutation';
import EditTitleModal from '../../../EditTitleModal';
import updateClipMutation from '@/queries/clip/updateClipMutation';
import { useQueryClient } from '@tanstack/react-query';
import { ClipFormat } from '@/queries/clipFormat/types';
import clipFormatKeys from '@/queries/clipFormat/clipFormatKeys';
import { useBodyToastQueue } from '@/shared/hooks/useBodyToastQueue.hook';

interface ClipFormatMenuProps {
    isEditDisabled?: boolean;
    className?: string;
    clipFormat?: ClipFormat;
}

const ClipFormatMenu = ({ isEditDisabled = false, className, clipFormat }: ClipFormatMenuProps) => {
    const { showId } = useParams<{ showId: string }>();
    const { data: show } = useShowQuery(showId);
    const userHasWriteAccess = show?.userRole !== USER_ROLES.VIEWER;
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isEditTitleModalOpen, setIsEditTitleModalOpen] = useState(false);
    const deleteClipFormat = deleteClipFormatMutation();
    const updateClip = updateClipMutation();
    const queryClient = useQueryClient();
    const toast = useBodyToastQueue();

    if (!userHasWriteAccess) return null;

    const handleDelete = () => {
        if (!clipFormat) return;
        deleteClipFormat.mutate(clipFormat.id, {
            onSuccess: () => {
                setIsDeleteModalOpen(false);
            },
            onError: () => {
                toast.alert(
                    <FormattedMessage defaultMessage="Oups, une erreur est survenue. Veuillez réessayer" />,
                );
            },
        });
    };

    const handleEditClipTitle = (adminTitle: string) => {
        if (!clipFormat) return;
        updateClip.mutate(
            {
                clipId: clipFormat.clip.id,
                clip: { adminTitle },
            },
            {
                onSuccess: () => {
                    queryClient.invalidateQueries({
                        queryKey: clipFormatKeys.allLists(),
                    });
                    setIsEditTitleModalOpen(false);
                },
                onError: () => {
                    toast.alert(
                        <FormattedMessage defaultMessage="Oups, une erreur est survenue. Veuillez réessayer" />,
                    );
                },
            },
        );
    };

    return (
        <>
            <MenuTrigger>
                <TriggerButton
                    aria-label="Menu"
                    variant="ghost"
                    icon={<EllipsisIcon icon={icon({ name: 'ellipsis', style: 'solid' })} />}
                    className={className}
                />
                <PopoverStyled placement="bottom end">
                    <MenuDropdownStyled>
                        {!isEditDisabled && !!clipFormat && (
                            <MenuDropdownItem onAction={() => setIsEditTitleModalOpen(true)}>
                                <MenuItemIcon icon={icon({ name: 'pen', style: 'solid' })} />
                                <FormattedMessage defaultMessage="Renommer" />
                            </MenuDropdownItem>
                        )}
                        <MenuDropdownItem
                            onAction={() => setIsDeleteModalOpen(true)}
                            color="--alert"
                        >
                            <MenuItemIcon icon={icon({ name: 'trash', style: 'solid' })} />
                            <FormattedMessage defaultMessage="Supprimer" />
                        </MenuDropdownItem>
                    </MenuDropdownStyled>
                </PopoverStyled>
            </MenuTrigger>
            <EditTitleModal
                isOpen={isEditTitleModalOpen}
                onOpenChange={setIsEditTitleModalOpen}
                onEdit={handleEditClipTitle}
                isLoading={updateClip.isLoading}
                value={clipFormat?.clip?.adminTitle || clipFormat?.clip?.title}
            />
            <DeleteClipModal
                isOpen={isDeleteModalOpen}
                onOpenChange={setIsDeleteModalOpen}
                onDelete={handleDelete}
                isLoading={deleteClipFormat.isLoading}
            />
        </>
    );
};

const TriggerButton = styled(IconButton)`
    padding: 0;
    transition-duration: 0.2s;
    color: var(--neutral500);
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: var(--white);
`;
const EllipsisIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: inherit;
`;
const MenuItemIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
`;
const PopoverStyled = styled(Popover)`
    width: 100%;
    max-width: fit-content;
    min-width: 10rem;
    z-index: 1 !important;
`;
const MenuDropdownStyled = styled(MenuDropdown)`
    max-width: fit-content;
    min-width: 10rem;
`;

export default ClipFormatMenu;
