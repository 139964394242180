import { FormattedMessage } from 'react-intl';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import { useParams } from 'react-router-dom';
import RssExportConfigurationForm from './RssExportConfigurationForm';
import useRssSettingsQuery from '@queries/rssSettings/useRssSettingsQuery.hook';
import styled from 'styled-components';

const RssExportConfiguration = () => {
    const { showId } = useParams();
    const rssSettings = useRssSettingsQuery(showId);

    if (rssSettings.error) return <span>Error</span>;
    if (rssSettings.isLoading) return <span>Loading...</span>;

    return (
        <>
            <DeprecatedText size="veryBig" weight="bold" mb={20} mt={35}>
                <FormattedMessage defaultMessage="Configuration" />
            </DeprecatedText>
            <Container>
                {rssSettings.data && (
                    <RssExportConfigurationForm
                        rssSettings={rssSettings.data}
                        key={rssSettings.fetchStatus}
                    />
                )}
            </Container>
        </>
    );
};

const Container = styled.div`
    background-color: var(--white);
    padding: var(--container-padding-mobile);
    border-radius: var(--r-l);

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        padding: var(--container-padding);
    }
`;

export default RssExportConfiguration;
