import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { extractCssVariable } from '@/utils/cssVariables';
import styled from 'styled-components';
import useCallToActionButtonsQuery from '@/queries/callToAction/useCallToActionButtonsQuery.hook';
import { useParams } from 'react-router';
import FirstCallToAction from './FirstCallToAction';
import CallToActionList from './CallToActionList';
import CallToActionMenu from './CallToActionMenu';
import CallToActionSkeleton from './CallToActionSkeleton';

export const LINKS_NAMES = {
    PAGE_PODCAST: 'podcast_page',
    CROWDFUNDING: 'crowdfunding',
    SMARTLINK: 'smartlink',
    NEWSLETTER: 'newsletter',
    PLAYER: 'player',
    CUSTOM: 'custom',
};

export const LOCATIONS_LABEL = {
    [LINKS_NAMES.PAGE_PODCAST]: <FormattedMessage defaultMessage="Site Web" />,
    [LINKS_NAMES.SMARTLINK]: <FormattedMessage defaultMessage="Smartlink" />,
    [LINKS_NAMES.NEWSLETTER]: <FormattedMessage defaultMessage="Newsletter" />,
    [LINKS_NAMES.PLAYER]: <FormattedMessage defaultMessage="Smartplayer" />,
};

export const BASICS_CHECKBOX = {
    [LINKS_NAMES.PAGE_PODCAST]: {
        label: LOCATIONS_LABEL[LINKS_NAMES.PAGE_PODCAST],
        value: LINKS_NAMES.PAGE_PODCAST,
    },
    [LINKS_NAMES.PLAYER]: { label: LOCATIONS_LABEL[LINKS_NAMES.PLAYER], value: LINKS_NAMES.PLAYER },
    [LINKS_NAMES.SMARTLINK]: {
        label: LOCATIONS_LABEL[LINKS_NAMES.SMARTLINK],
        value: LINKS_NAMES.SMARTLINK,
    },
    [LINKS_NAMES.NEWSLETTER]: {
        label: LOCATIONS_LABEL[LINKS_NAMES.NEWSLETTER],
        value: LINKS_NAMES.NEWSLETTER,
    },
};

export const ICON_LIST_CTA = {
    [LINKS_NAMES.PAGE_PODCAST]: (
        <FontAwesomeIcon
            icon={icon({ name: 'globe', style: 'regular' })}
            color={extractCssVariable('--neutral500')}
        />
    ),
    [LINKS_NAMES.SMARTLINK]: (
        <FontAwesomeIcon
            icon={icon({ name: 'link', style: 'solid' })}
            color={extractCssVariable('--neutral500')}
        />
    ),
    [LINKS_NAMES.NEWSLETTER]: (
        <FontAwesomeIcon
            icon={icon({ name: 'envelope-open-text', style: 'solid' })}
            color={extractCssVariable('--neutral500')}
        />
    ),
    [LINKS_NAMES.CROWDFUNDING]: (
        <FontAwesomeIcon
            icon={icon({ name: 'heart', style: 'regular' })}
            color={extractCssVariable('--neutral500')}
        />
    ),
    [LINKS_NAMES.CUSTOM]: (
        <FontAwesomeIcon
            icon={icon({ name: 'arrow-up-right-from-square', style: 'solid' })}
            color={extractCssVariable('--neutral500')}
        />
    ),
};

const CallToAction = () => {
    const { showId } = useParams<{ showId: string }>();
    const callToActionButtons = useCallToActionButtonsQuery({
        showId,
    });

    if (callToActionButtons.isError) {
        return <div>Error</div>; // TODO: Add an error state
    }

    if (callToActionButtons.data) {
        if (callToActionButtons.data.length === 0) {
            return (
                <Container>
                    <FirstCallToAction />
                </Container>
            );
        }

        return (
            <Container>
                <CTAInnerWrapper>
                    <CallToActionList callToActions={callToActionButtons.data} />
                    <CallToActionMenu />
                </CTAInnerWrapper>
            </Container>
        );
    }

    return (
        <Container>
            <CTAInnerWrapper>
                <CallToActionSkeleton />;
            </CTAInnerWrapper>
        </Container>
    );
};

const Container = styled.div`
    width: 100%;
    max-width: var(--container-width);
    display: flex;
    flex-direction: column;
    align-items: center;
    column-gap: 0.5rem;
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding);

    ${({ theme }) => theme.mediaQueries.mobile} {
        padding: var(--container-padding-mobile);
    }
`;
const CTAInnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    max-width: 600px;
    width: 100%;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        & > button {
            align-self: start;
        }
    }
`;

export default CallToAction;
