import useUserQuery from '@queries/user/useUserQuery.hook';
import { getBrowserLanguage } from '@/shared/utils/i18n';

const useUserLanguage = () => {
    const { data: user } = useUserQuery();
    const userLanguage = user?.language;
    const browserLanguage = getBrowserLanguage();

    return userLanguage ?? browserLanguage;
};

export default useUserLanguage;
