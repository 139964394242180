import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import DeprecatedModal from '../../ui/molecules/DeprecatedModal';
import DeprecatedText from '../../ui/atoms/DeprecatedText';
import DeprecatedButton from '../../ui/atoms/DeprecatedButton';
import SelectSeasons from '../molecules/SelectSeasons';
import Spinner from '../../ui/atoms/Spinner';
import styled from 'styled-components';

function SeasonPodcastsModal({ onClose, isOpened, onAssign, assigning }) {
    const [seasonId, setSeasonId] = useState(null);
    return (
        <DeprecatedModal
            w={550}
            title={
                <FormattedMessage defaultMessage="Dans quelle saison souhaitez-vous classer les épisodes ?" />
            }
            isOpened={isOpened}
            onClose={onClose}
        >
            <DeprecatedText>
                <FormattedMessage defaultMessage="Sélectionnez la saison dans laquelle vous souhaitez classer les épisodes." />
            </DeprecatedText>

            <SeasonsWrapper>
                <SelectSeasons value={seasonId} onChange={setSeasonId} />
            </SeasonsWrapper>
            <ActionWrapper>
                <DeprecatedButton accent="secondary" mr={10} onClick={onClose}>
                    <FormattedMessage defaultMessage="Annuler" />
                </DeprecatedButton>
                <DeprecatedButton onClick={() => onAssign(seasonId)} disabled={assigning}>
                    {assigning && <Spinner />}
                    <FormattedMessage defaultMessage="Ajouter à la saison" />
                </DeprecatedButton>
            </ActionWrapper>
        </DeprecatedModal>
    );
}

const ActionWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;
const SeasonsWrapper = styled.div`
    margin-block: 1.5rem;
    display: flex;
    justify-content: center;
`;

export default SeasonPodcastsModal;
