import { useParams } from 'react-router';
import useQuery from '@/shared/hooks/useQuery';
import type { Platform, Mode } from '@/api/pso/types';
import Header from './Header';
import Form from './Form';
import Results from './Results';
import { useAmplitudeLogEvent } from '@/shared/hooks/useAmplitude';
import useHasAccessToPsoCompetitors from '@/shared/hooks/useHasAccessToPsoCompetitors.hook';
import EmptyState from '../EmptyState';
import styled from 'styled-components';

const PsoCompetitors = () => {
    useAmplitudeLogEvent('PSO Competitors Viewed');

    const { showId }: { showId: string } = useParams();
    const query = useQuery();
    const platform: Platform = query.get('platform') as Platform;
    const store: string = query.get('store') || '';
    const mode: Mode = query.get('mode') as Mode;

    const hasAccessToPsoCompetitors = useHasAccessToPsoCompetitors({ showId });

    if (!hasAccessToPsoCompetitors) {
        return <EmptyState />;
    }

    return (
        <PsoCompetitorsWrapper>
            <Header>
                <Form />
            </Header>
            <Results
                searchParams={{
                    platform,
                    store,
                    mode,
                }}
            />
        </PsoCompetitorsWrapper>
    );
};

const PsoCompetitorsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

export default PsoCompetitors;
