import posed, { PoseGroup } from 'react-pose';
import { FormattedMessage } from 'react-intl';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import DeprecatedText from '@ui/atoms/DeprecatedText';
import { useSwitch } from '@/shared/hooks/useSwitch';
import DeleteCampaignModal from '@/components/unorganized/DeleteCampaignModal';
import { connect } from '@/components/legacy/connect';
import DeprecatedCheckbox from '@ui/atoms/DeprecatedCheckbox';

const Labels = posed(DeprecatedPaper)({
    enter: { opacity: 1, y: 0, transition: { duration: 250 } },
    exit: { opacity: 0, y: -20, transition: { duration: 250, y: { delay: 50 } } },
});

const enhance = connect(({ campaignStore }) => ({
    allSelected: campaignStore.areAllCampaignsSelected,
    toggleAll: campaignStore.toggleAllCampaigns,
    isOneSelected: campaignStore.isOneCampaignSelected,
    count: campaignStore.selectedCampaigns.length,
    isPauseLoading: campaignStore.pauseSelection.pending,
    onPause: campaignStore.pauseSelection,
    onDelete: campaignStore.deleteSelection,
    noCampaign: campaignStore.campaigns.length === 0,
}));

function CampaignListHeader({
    allSelected,
    toggleAll,
    isOneSelected,
    count,
    onDelete,
    noCampaign,
}) {
    const [isDeleteOpened, onDeleteOpen, onDeleteClose] = useSwitch(false);
    return (
        <DeprecatedPaper height={40} px={15} align="center" flex>
            {!noCampaign && <DeprecatedCheckbox checked={allSelected} onChange={toggleAll} />}
            <PoseGroup>
                {isOneSelected && (
                    <Labels key={0} flex>
                        <DeprecatedText
                            color="--primary"
                            weight="semibold"
                            ml={35}
                            onClick={onDeleteOpen}
                        >
                            <FormattedMessage defaultMessage="Supprimer" />
                        </DeprecatedText>
                    </Labels>
                )}
            </PoseGroup>
            <DeleteCampaignModal
                count={count}
                onSubmit={onDelete}
                isLoading={onDelete.pending}
                isOpened={isDeleteOpened}
                onClose={onDeleteClose}
            />
        </DeprecatedPaper>
    );
}

export default enhance(CampaignListHeader);
