import { useMutation, useQueryClient } from '@tanstack/react-query';
import { uploadShowLogo } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import { Show } from './useShowQuery.hook';
import showKeys from './showKeys';

interface ShowLogoInput {
    file: File;
    format: 'logo_dark' | 'logo_light' | 'favicon';
}

export interface UploadShowLogoMutationFnProps {
    showId: string;
    logo?: ShowLogoInput;
}

const mutationFn = async ({ showId, logo }: UploadShowLogoMutationFnProps): Promise<Show> => {
    const { data } = await uploadShowLogo(showId, logo?.file, logo?.format);

    return camelcaseKeys(data as any, { deep: true });
};

const uploadShowLogoMutation = () => {
    const queryclient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: (_, { showId }) => {
            queryclient.invalidateQueries({
                queryKey: showKeys.detailById(showId),
            });
        },
    });
};

export default uploadShowLogoMutation;
