import { FormattedMessage } from 'react-intl';
// @ts-ignore
import { IntercomAPI } from 'react-intercom';
import { ListeningPlatform } from '@/utils/constants';
import IconButton from '@/components/IconButton';
import Modal from '@/components/ui/atoms/Modal';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@/components/Button';
import styled from 'styled-components';

const customContent: Record<string, Record<string, React.ReactNode>> = {
    itunes: {
        title: (
            <FormattedMessage defaultMessage="Avez-vous appliqué vos modifications sur Apple Podcasts ?" />
        ),
        content: (
            <FormattedMessage defaultMessage="Afin que nous puissions optimiser l’affichage de votre émission sur Apple Podcasts, dites nous si vous avez bien effectués toutes les manipulations demandées." />
        ),
        action: <FormattedMessage defaultMessage="Oui, c'est fait !" />,
    },
};

interface BroadcastSettingTutorialEndModalProps {
    isOpen: boolean;
    onClose: () => void;
    platformKey: ListeningPlatform;
    onAfterSubmitTutorial: () => void;
}

const BroadcastSettingTutorialEndModal = ({
    isOpen,
    onClose,
    platformKey,
    onAfterSubmitTutorial,
}: BroadcastSettingTutorialEndModalProps) => {
    return (
        <ModalStyled isOpen={isOpen} onOpenChange={onClose} size="medium">
            <Header>
                <Title>
                    {customContent[platformKey]?.title || (
                        <FormattedMessage defaultMessage="Avez-vous réalisé toutes les étapes du tutoriel ?" />
                    )}
                </Title>
                <IconButton
                    icon={<CloseIcon icon={icon({ name: 'xmark-large', style: 'solid' })} />}
                    onPress={onClose}
                    variant="ghost"
                    aria-label="Close modal"
                    style={{ padding: 0 }}
                />
            </Header>
            <ContentWrapper>
                {customContent[platformKey]?.content || (
                    <FormattedMessage defaultMessage="Une fois le tutoriel terminé, il ne vous reste plus qu’à ajouter manuellement votre lien d’écoute sur Ausha." />
                )}
                <FormattedMessage
                    defaultMessage="Vous avez besoin d'aide ? {action}"
                    values={{
                        action: (
                            <ContactUsButton
                                variant="ghost"
                                $color={`--${platformKey}`}
                                onPress={() => IntercomAPI('show')}
                            >
                                <FormattedMessage defaultMessage="Contactez-nous" />
                            </ContactUsButton>
                        ),
                    }}
                />
            </ContentWrapper>
            <Actions>
                <Button variant="link-secondary" onPress={onClose}>
                    <FormattedMessage defaultMessage="Plus tard" />
                </Button>
                <SubmitButton onPress={onAfterSubmitTutorial} $color={`--${platformKey}`}>
                    {customContent[platformKey]?.action || (
                        <FormattedMessage defaultMessage="Oui, ajouter mon lien d'écoute" />
                    )}
                </SubmitButton>
            </Actions>
        </ModalStyled>
    );
};

const ModalStyled = styled(Modal)`
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 2rem;
`;
const Header = styled.div`
    display: flex;
    justify-content: space-between;
`;
const Title = styled.h1`
    font-size: var(--fs-heading-s);
`;
const CloseIcon = styled(FontAwesomeIcon)`
    width: 1rem;
    height: 1rem;
    color: var(--neutral500);
`;
const ContentWrapper = styled.div`
    width: 100%;
    flex: 1;
`;
const Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
`;
const ContactUsButton = styled(Button)<{ $color: string }>`
    color: var(${(props) => props.$color});
    padding: 0;
`;
const SubmitButton = styled(Button)<{ $color: string }>`
    color: var(${(props) => props.$color});
    background: var(${(props) => props.$color}50);
    box-shadow: none;

    &:hover {
        background: var(${(props) => props.$color}100);
    }
`;

export default BroadcastSettingTutorialEndModal;
