import { FormattedMessage } from 'react-intl';
import { useAmplitudeLogEvent } from '@hooks/useAmplitude';
import styled, { css, keyframes } from 'styled-components';
import useEmailNotificationSettingsQuery from '@/queries/emailNotificationSettings/useEmailNotificationSettingsQuery.hook';
import useUpdateEmailNotificationSettingsMutation from '@/queries/emailNotificationSettings/useUpdateEmailNotificationSettingsMutation.hook';
import Text from '@ui/atoms/Text';
import Stack from '@ui/layout/Stack';
import { useModalToastQueue } from '@/utils/hooks/useModalToastQueue.hook';
import EmailNotificationSettingsForm from '../molecules/EmailNotificationSettingsForm';

function EmailNotification() {
    useAmplitudeLogEvent('Notifications Viewed');
    const { data: settings, isLoading } = useEmailNotificationSettingsQuery();
    const updateSettings = useUpdateEmailNotificationSettingsMutation();
    const toast = useModalToastQueue();

    const handleSubmit = (formData) => {
        updateSettings.mutate(formData, {
            onSuccess: () => {
                toast.success();
            },
            onError: () => {
                toast.alert();
            },
        });
    };

    return (
        <Stack $gap="2rem">
            {isLoading ? (
                <EmailNotificationWrapperSkeleton />
            ) : (
                <EmailNotificationWrapper>
                    <Text color="--neutral500">
                        <FormattedMessage defaultMessage="Paramétrez vos options de notifications pour recevoir une alerte par e-mail lors de ces évènements :" />
                    </Text>

                    <EmailNotificationSettingsForm
                        onSubmit={handleSubmit}
                        isLoading={updateSettings.isLoading}
                        defaultValues={settings}
                    />
                </EmailNotificationWrapper>
            )}
        </Stack>
    );
}

const EmailNotificationWrapper = styled.div`
    background-color: var(--white);
    border-radius: var(--r-l);
    padding: var(--container-padding);
    display: flex;
    flex-direction: column;
    row-gap: 2rem;

    ${({ theme }) => theme.mediaQueries.mobile} {
        padding: var(--container-padding-mobile);
    }
`;
const shimmer = keyframes`
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
`;
const shimmering = css`
    background: var(--neutral50);
    background-image: linear-gradient(
        to right,
        var(--neutral50) 0%,
        #edeef1 20%,
        var(--neutral50) 40%,
        var(--neutral50) 100%
    );
    background-repeat: no-repeat;
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${shimmer};
    animation-timing-function: linear;
`;
const EmailNotificationWrapperSkeleton = styled.div`
    background-color: var(--neutral50);
    border-radius: var(--r-l);
    width: 100%;
    height: 17rem;
    ${shimmering};
`;

export default EmailNotification;
