import Text from '@/components/ui/atoms/Text';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { sendAmplitudeLogEvent } from '@/helpers';
import Snippet from '../Snippet';
import { NonHostingEpisodeFormSchema } from '../../useNonHostingEpisodeFormSchema.hook';

const TitleSummary = () => {
    const { getValues } = useFormContext<NonHostingEpisodeFormSchema>();
    const message = getValues('newsletter.title');

    if (!message) return null;

    const handleCopy = () => {
        sendAmplitudeLogEvent('Copy Newsletter Title');
    };

    return (
        <Wrapper>
            <Text variant="bodyL" fontWeight="--fw-semibold">
                <FormattedMessage defaultMessage="Titre" />
            </Text>
            <Snippet onCopy={handleCopy} valueToCopy={message}>
                <Text>{message}</Text>
            </Snippet>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
`;

export default TitleSummary;
