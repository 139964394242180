import { createGlobalStyle } from 'styled-components';
import { MEDIA_QUERIES } from '@/shared/config/breakpoints';
import {
    AMAZON_MUSIC,
    APPLE_PODCASTS,
    CASTBOX,
    CASTRO,
    DEEZER,
    GAANA,
    I_HEART_RADIO,
    JIO_SAAVN,
    LISTEN_NOTES,
    OVERCAST,
    PANDORA,
    PLAYER_FM,
    POCKET_CASTS,
    PODCAST_ADDICT,
    PODCAST_INDEX,
    PODCHASER,
    SOUNDCLOUD,
    SPOTIFY,
    TUNEIN,
    YOUTUBE,
} from '@/shared/config/constants';

export const mediaQueries = {
    mediaQueries: {
        mobile: MEDIA_QUERIES.MOBILE,
        mobileAndTablet: MEDIA_QUERIES.MOBILE_AND_TABLET,
        tabletAndUp: MEDIA_QUERIES.TABLET_AND_UP,
        desktopAndUp: MEDIA_QUERIES.DESKTOP_AND_UP,
        desktopLargeAndUp: MEDIA_QUERIES.DESKTOP_LARGE_AND_UP,
    },
};

export const Theme = createGlobalStyle`
    :root {
        /* Radii */
        --r-none: 0;
        --r-xxs: 0.125rem;
        --r-xs: 0.25rem;
        --r-s: 0.5rem;
        --r-m: 0.75rem;
        --r-l: 1rem;
        --r-xl: 2rem;
        --r-full: 9999px;

        /* Font Weights */
        --fw-normal: 400;
        --fw-semibold: 600;
        --fw-bold: 700;

        /* Font Sizes */
        --fs-large-title: 2.25rem;
        --fs-title: 1.625rem;
        --fs-subtitle: 1.3rem;
        --fs-heading: 1.125rem;
        --fs-subheading: 1rem;
        --fs-body: 0.875rem;
        --fs-footnote: 0.75rem;

        --fs-heading-xl: 2.5rem;
        --fs-heading-l: 2rem;
        --fs-heading-m: 1.5rem;
        --fs-heading-s: 1.25rem;
        --fs-body-l: 1rem;
        --fs-body-m: 0.875rem;
        --fs-body-s: 0.75rem;
        --fs-body-xs: 0.625rem;

        /* Line Heights */
        --lh-large-title: 1.5em;
        --lh-title: 1.5em;
        --lh-heading: 1.5em;
        --lh-subheading: 1.5em;
        --lh-body: 1.43em;
        --lh-footnote: 1.5em;

        --lh-heading-xl: 3rem;
        --lh-heading-l: 2.5rem;
        --lh-heading-m: 2rem;
        --lh-heading-s: 1.75rem;
        --lh-body-l: 1.5rem;
        --lh-body-m: 1.25rem;
        --lh-body-s: 1rem;

       /* Letter Spacings - DEPRECATED TBR*/
       --ls-large-title: normal;
       --ls-title: normal;
       --ls-heading: normal;
       --ls-subheading: 0.01em;
       --ls-body: 0.02em;
       --ls-footnote: 0.02em;

        /* Shadows */
        --s-m: 0px 4px 40px rgba(0, 0, 0, 0.1);
        --s-light: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
        --s-medium: 0 2px 25px -8px rgb(0, 0, 0, 0.1);
        --s-heavy: 0 0 6px 0 rgba(0, 0, 0, 0.15);
        --s-whatever: 0px 4px 24px 0px rgba(0, 0, 0, 0.05);
        --s-primary-light: 0px 0px 0px 4px rgba(241, 232, 249, 1);
        --s-primary: 0px 4px 16px 0px rgba(117, 28, 191, 0.3);
        --s-aurora:  0px 4px 24px 0px rgba(255, 128, 255, 0.20);

        /* Borders */
        --b-base: 1px solid var(--neutral100);

        /* Spacing */
        --sp-xxs: 0.25rem;
        --sp-xs: 0.5rem;
        --sp-s: 0.75rem;
        --sp-m: 1rem;
        --sp-l: 1.5rem;
        --sp-xl: 2rem;
        --sp-xxl: 4rem;

        /* Fixed container width */
        --small-container-width: 1264px;
        --large-container-width: 1920px;
        --container-width: var(--large-container-width);
        --container-padding: 2rem;
        --container-padding-mobile: 1rem;

        /* Easing functions */
        --bounce-easing: linear(
            0, 0.0157, 0.0625, 0.1407, 0.25 18.18%, 0.5625, 1 36.36%, 0.8907, 0.8125,
            0.7657, 0.75, 0.7657, 0.8125, 0.8905, 1, 0.9532, 0.9375, 0.9531, 1, 0.9844, 1
        );
        --spring-easing: linear(
            0, 0.009, 0.035 2.1%, 0.141, 0.281 6.7%, 0.723 12.9%, 0.938 16.7%, 1.017,
            1.077, 1.121, 1.149 24.3%, 1.159, 1.163, 1.161, 1.154 29.9%, 1.129 32.8%,
            1.051 39.6%, 1.017 43.1%, 0.991, 0.977 51%, 0.974 53.8%, 0.975 57.1%,
            0.997 69.8%, 1.003 76.9%, 1.004 83.8%, 1
          );
        --elastic-easing: linear(
            0, 0.218 2.1%, 0.862 6.5%, 1.114, 1.296 10.7%, 1.346, 1.37 12.9%, 1.373,
            1.364 14.5%, 1.315 16.2%, 1.032 21.8%, 0.941 24%, 0.891 25.9%, 0.877,
            0.869 27.8%, 0.87, 0.882 30.7%, 0.907 32.4%, 0.981 36.4%, 1.012 38.3%, 1.036,
            1.046 42.7% 44.1%, 1.042 45.7%, 0.996 53.3%, 0.988, 0.984 57.5%, 0.985 60.7%,
            1.001 68.1%, 1.006 72.2%, 0.998 86.7%, 1
        );

        /* Colors */
        --primary50: #F1E8F9;
        --primary100: #E3D2F2;
        --primary200: #C8A4E5;
        --primary300: #AC77D9;
        --primary400: #9149CC;
        --primary500: #751CBF;
        --primary600: #5E1699;
        --primary700: #461173;
        --primary: var(--primary500);
        --primary-alpha-base: 273 74% 43%;
        --primary-magic: #AF40FF;

        --decorativeA: #3A25A5;
        --decorativeB: #F52FFB;
        --decorativeC: #FF6E47;
        --decorativeD: #FFC747;

        --info50: #E9F1FF;
        --info100: #D4E2FF;
        --info200: #A8C5FF;
        --info300: #7DA9FF;
        --info400: #518CFF;
        --info500: #266FFF;
        --info600: #1E59CC;
        --info700: #174399;
        --info: var(--info500);

        --success50: #EDFAED;
        --success100: #DAF5DA;
        --success200: #B5EBB5;
        --success300: #91E091;
        --success400: #6CD66C;
        --success500: #47CC47;
        --success600: #39A339;
        --success700: #2B7A2B;
        --success: var(--success500);

        --warning50: #FFF4E9;
        --warning100: #FFE9D4;
        --warning200: #FFD4A8;
        --warning300: #FFBE7D;
        --warning400: #FFA951;
        --warning500: #FF9326;
        --warning600: #CC761E;
        --warning700: #995817;
        --warning: var(--warning500);

        --alert50: #FFE9ED;
        --alert100: #FFD4DB;
        --alert200: #FFA8B7;
        --alert300: #FF7D92;
        --alert400: #FF516E;
        --alert500: #FF264A;
        --alert600: #CC1E3B;
        --alert700: #99172C;
        --alert: var(--alert500);

        --white-base: 0 100% 100%;
        --white50: hsl(var(--white-base) / 0.05);
        --white75: hsl(var(--white-base) / 0.075);
        --white100: hsl(var(--white-base) / 0.10);
        --white200: hsl(var(--white-base) / 0.20);
        --white300: hsl(var(--white-base) / 0.30);
        --white400: hsl(var(--white-base) / 0.40);
        --white500: hsl(var(--white-base) / 0.50);
        --white600: hsl(var(--white-base) / 0.60);
        --white700: hsl(var(--white-base) / 0.70);
        --white: hsl(var(--white-base));

        --black-base: 285 6% 14%;
        --black50: hsl(var(--black-base) / 0.05);
        --black100: hsl(var(--black-base) / 0.10);
        --black200: hsl(var(--black-base) / 0.20);
        --black300: hsl(var(--black-base) / 0.30);
        --black400: hsl(var(--black-base) / 0.40);
        --black500: hsl(var(--black-base) / 0.50);
        --black600: hsl(var(--black-base) / 0.60);
        --black700: hsl(var(--black-base) / 0.70);
        --black: hsl(var(--black-base));

        --neutral50: #F9F7FA;
        --neutral100: #F0EDF2;
        --neutral200: #D6D2D9;
        --neutral300: #BCB8BF;
        --neutral400: #A29DA6;
        --neutral500: #88848C;
        --neutral600: #6F6B73;
        --neutral700: #565259;
        --neutral-1000: #FFF;
        --neutral-white: var(--neutral-1000);
        --neutral: var(--neutral500);

        /* Colors - vendors & gradients */
        --discord: #5865F2;
        --facebook: #1877F2;
        --facebook50: #E6F0FF;
        --instagram: #FF0069;
        --instagram50: #FFE5F0;
        --linkedin: #0A66C2;
        --linkedin50: #E6F0F9;
        --messenger: #0695FF;
        --tiktok: #000000;
        --twitch: #9147FF;
        --twitter: #000000;
        --twitter50: #E5E5E5;
        --whatsapp: #25D366;
        --stripe: #635BFF;

        --google-login: #4285F4;

        --capterra: #FFE2BD;
        --trust-pilot: #B4FBE8;

        --${AMAZON_MUSIC}: #4FC5DF;
        --${AMAZON_MUSIC}50: #4FC5DF1A;
        --${AMAZON_MUSIC}100: #4FC5DF33;
        --${APPLE_PODCASTS}: #A945E3;
        --${APPLE_PODCASTS}50: #A945E31A;
        --${APPLE_PODCASTS}100: #A945E333;
        --${APPLE_PODCASTS}300: #A945E380;
        --${CASTBOX}: #F55B23;
        --${CASTBOX}50: #F55B231A;
        --${CASTBOX}100: #F55B2333;
        --${CASTRO}: #00B265;
        --${CASTRO}50: #00B2651A;
        --${CASTRO}100: #00B26533;
        --${DEEZER}: #EF5466;
        --${DEEZER}50: #EF54661A;
        --${DEEZER}100: #EF546633;
        --${GAANA}: #E72C30;
        --${GAANA}50: #E72C301A;
        --${GAANA}100: #E72C3033;
        --${I_HEART_RADIO}: #C6002B;
        --${I_HEART_RADIO}50: #C6002B1A;
        --${I_HEART_RADIO}100: #C6002B33;
        --${JIO_SAAVN}: #1ECCB0;
        --${JIO_SAAVN}50: #1ECCB01A;
        --${JIO_SAAVN}100: #1ECCB033;
        --${LISTEN_NOTES}: #B82F00;
        --${LISTEN_NOTES}50: #B82F001A;
        --${LISTEN_NOTES}100: #B82F0033;
        --${OVERCAST}: #FC7E0F;
        --${OVERCAST}50: #FC7E0F1A;
        --${OVERCAST}100: #FC7E0F33;
        --${PANDORA}: #2299F8;
        --${PANDORA}50: #2299F81A;
        --${PANDORA}100: #2299F833;
        --${PLAYER_FM}: #C8122A;
        --${PLAYER_FM}50: #C8122A1A;
        --${PLAYER_FM}100: #C8122A33;
        --${POCKET_CASTS}: #F43E37;
        --${POCKET_CASTS}50: #F43E371A;
        --${POCKET_CASTS}100: #F43E3733;
        --${PODCAST_ADDICT}: #F4842D;
        --${PODCAST_ADDICT}50: #F4842D1A;
        --${PODCAST_ADDICT}100: #F4842D33;
        --${PODCAST_INDEX}: #F90000;
        --${PODCAST_INDEX}50: #F900001A;
        --${PODCAST_INDEX}100: #F9000033;
        --${PODCHASER}: #FF3382;
        --${PODCHASER}50: #FF33821A;
        --${PODCHASER}100: #FF338233;
        --${SOUNDCLOUD}: #FF5500;
        --${SOUNDCLOUD}50: #FF55001A;
        --${SOUNDCLOUD}100: #FF550033;
        --${SOUNDCLOUD}-light: #FFEEE5;
        --${SPOTIFY}: #1ED760;
        --${SPOTIFY}50: #1ED7601A;
        --${SPOTIFY}100: #1ED76033;
        --${SPOTIFY}300: #1ED76080;
        --${TUNEIN}: #1C203C;
        --${TUNEIN}50: #1C203C1A;
        --${TUNEIN}100: #1C203C33;
        --${YOUTUBE}: #FF0000;
        --${YOUTUBE}50: #FF00001A;
        --${YOUTUBE}100: #FF000033;
        --${YOUTUBE}-light: #FFE5E5;

        --gradient-vendor-${AMAZON_MUSIC}: linear-gradient(45deg, var(--${AMAZON_MUSIC}) 0%, #4FC5DF 100%);
        --gradient-vendor-${APPLE_PODCASTS}: linear-gradient(45deg, var(--${APPLE_PODCASTS}) 0%, #D156F6 100%);
        --gradient-vendor-${CASTBOX}: linear-gradient(45deg, var(--${CASTBOX}) 0%, #FF9C46 100%);
        --gradient-vendor-${CASTRO}: linear-gradient(45deg, var(--${CASTRO}) 0%, #00CEBC 100%);
        --gradient-vendor-${DEEZER}: linear-gradient(45deg, var(--${DEEZER}) 0%, #E83A4E 100%);
        --gradient-vendor-${GAANA}: linear-gradient(45deg, var(--${GAANA}) 0%, #E72C30 100%);
        --gradient-vendor-${I_HEART_RADIO}: linear-gradient(45deg, var(--${I_HEART_RADIO}) 0%, #B21A16 100%);
        --gradient-vendor-${JIO_SAAVN}: linear-gradient(45deg, var(--${JIO_SAAVN}) 0%, #38CEB9 100%);
        --gradient-vendor-${LISTEN_NOTES}: linear-gradient(45deg, var(--${LISTEN_NOTES}) 0%, #D12424 100%);
        --gradient-vendor-${OVERCAST}: linear-gradient(45deg, var(--${OVERCAST}) 0%, #FD776A 100%);
        --gradient-vendor-${PANDORA}: linear-gradient(45deg, var(--${PANDORA}) 0%, #3542C6 100%);
        --gradient-vendor-${PLAYER_FM}: linear-gradient(45deg, var(--${PLAYER_FM}) 0%, #DF304A 100%);
        --gradient-vendor-${POCKET_CASTS}: linear-gradient(45deg, var(--${POCKET_CASTS}) 0%, #FD776A 100%);
        --gradient-vendor-${PODCAST_ADDICT}: linear-gradient(45deg, var(--${PODCAST_ADDICT}) 0%, #FFD137 100%);
        --gradient-vendor-${PODCAST_INDEX}: linear-gradient(45deg, var(--${PODCAST_INDEX}) 0%, #AA0000 100%);
        --gradient-vendor-${PODCHASER}: linear-gradient(45deg, var(--${PODCHASER}) 0%, #70258D 100%);
        --gradient-vendor-${SOUNDCLOUD}: linear-gradient(45deg, var(--${SOUNDCLOUD}) 0%, #ff9e55 100%);
        --gradient-vendor-${SPOTIFY}: linear-gradient(45deg, var(--${SPOTIFY}) 0%, #00DC4D 100%);
        --gradient-vendor-${TUNEIN}: linear-gradient(45deg, var(--${TUNEIN}) 0%, #4D459C 100%);
        --gradient-vendor-${YOUTUBE}: linear-gradient(45deg, var(--${YOUTUBE}) 0%, #FD776A 100%);

        /* Gradients */
        --gradient-primary: linear-gradient(94deg, var(--primary) 0%, var(--decorativeA));
        --gradient-night: linear-gradient(#EFE8FF, #AEB6FF);
        --gradient-dusk: linear-gradient(0deg, #F5DFFF 0%, #DFDAFF 100%);
        --gradient-dawn: linear-gradient(#F3D9FC, #FFEAE4);
        --gradient-aurora: linear-gradient(135deg, #E5F6FF 0%, #EAE5FF 50%, #FFE5FF 100%);
        --gradient-nebula-opacity: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 20%, #FFF 80%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(135deg, #E5F6FF 0%, #EAE5FF 10%, #FFE5FF 20%, #FFE5FF 80%, #EAE5FF 90%, #E5F6FF 100%);
        --gradient-nebula: linear-gradient(125deg,
            #66CBFF 6.18%, #69C9FF 10.29%, #6DC7FF 13.98%, #71C4FF 17.4%, #75C1FF 20.69%,
            #7BBDFF 24.03%, #81B9FF 27.56%, #89B4FF 31.44%, #92AEFF 35.83%, #9CA7FF 40.88%,
            #A8A0FF 46.75%, #B597FF 53.6%, #C48DFF 61.57%, #D681FF 70.83%, #E974FF 81.53%, #F6F 93.82%);
        --gradient-sunny: linear-gradient(#FFF5D3, #FFEEEA);
        --gradient-capterra: linear-gradient(90deg, #F79D29, #FABC6B);
        --gradient-trust-pilot: linear-gradient(90deg, #03B982, #00DCB1);
    }
`;
