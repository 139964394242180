import PropTypes from 'prop-types';
import { Redirect, Route, withRouter } from 'react-router-dom';

function DefaultRoute({ to, match, ...props }) {
    const redirect = to.replace(/:(\w+)/gi, (_, key) => match.params[key]);
    return <Route {...props} render={() => <Redirect to={redirect} push={false} />} />;
}

DefaultRoute.propTypes = {
    to: PropTypes.string,
    match: PropTypes.object.isRequired,
};

DefaultRoute.defaultProps = {
    path: '*',
    to: undefined,
};

export default withRouter(DefaultRoute);
