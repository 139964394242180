import useShowGuestInvitationsQuery from '@/queries/guest/useShowGuestInvitationsQuery.hook';
import { useParams } from 'react-router-dom';
import useShowGuestPermissionsQuery from '@/queries/guest/useShowGuestPermissionsQuery.hook';
import styled from 'styled-components';
import ShowGuestsPermission from './ShowGuestsPermission';
import ShowGuestsInvitation from './ShowGuestsInvitation';
import ShowGuestsListNoContent from './ShowGuestsListNoContent';
import ShortListFallback from '@/components/app/fallbacks/ShortListFallback';

const ShowGuestsList = () => {
    const { showId } = useParams<{ showId: string }>();
    const invitations = useShowGuestInvitationsQuery(showId);
    const permissions = useShowGuestPermissionsQuery(showId);
    const noUsers = invitations.data?.length === 0 && permissions.data?.length === 0;

    if (invitations.isLoading || permissions.isLoading) {
        return (
            <Container>
                <ShortListFallback />
            </Container>
        );
    }

    if (noUsers) {
        return <ShowGuestsListNoContent />;
    }

    return (
        <Container>
            {permissions.data?.map((p) => (
                <ShowGuestsPermission key={p.id} permission={p} />
            ))}
            {invitations.data?.map((i) => (
                <ShowGuestsInvitation key={i.id} invitation={i} />
            ))}
        </Container>
    );
};

const Container = styled.div`
    background-color: var(--white);
    padding: 0.5rem;
    border-radius: var(--r-s);
`;

export default ShowGuestsList;
