import styled, { css } from 'styled-components';
import { resolveColor } from '@/utils/cssVariables';

function getCoordinatesForPercent(percent) {
    const arcLength = 2 * Math.PI * percent;
    const x = Math.cos(arcLength);
    const y = Math.sin(arcLength);

    return [x, y];
}

/*
 * On firefox, using transforms on a path is prohibited.
 * Therefore we wrap the path with a group and apply the transforms on that group.
 */
const G = styled.g`
    transition: all 0.25s;
    fill: ${(p) => resolveColor(p.color)};
    ${(p) =>
        p.active
            ? css`
                  opacity: 0.8;
                  transform: scale(1.03);
              `
            : css`
                  opacity: 1;
                  transform: scale(1);
              `}
`;

function Ring({ color, active, ...p }) {
    const largeArcFlag = p.percent > 0.5 ? 1 : 0;
    const end = p.start + p.percent;

    const [startX, startY] = getCoordinatesForPercent(p.start);
    const [endX, endY] = getCoordinatesForPercent(end);

    return (
        <G active={active} color={color}>
            <path
                d={[
                    `M ${startX} ${startY}`, // Move
                    `A 1 1 0 ${largeArcFlag} 1 ${endX} ${endY}`, // Arc
                    `L 0 0`, // Line
                ].join(' ')}
                {...p}
            />
        </G>
    );
}

export default Ring;
