import Cluster from '@ui/layout/Cluster';
import styled, { css, keyframes } from 'styled-components';

const Skeleton = () => {
    return (
        <Container>
            <Header>
                <HeaderBlockLeft />
                <HeaderBlockRight />
            </Header>
            <Content>
                <ContentInnerWrapper></ContentInnerWrapper>
            </Content>
            <Footer>
                <FooterLightBlock />
                <Cluster $gap="0.5rem" $align="center">
                    <FooterBlock />
                    <FooterBlock />
                </Cluster>
            </Footer>
        </Container>
    );
};

const shimmer = keyframes`
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
`;
const shimmering = css`
    background: var(--neutral50);
    background-image: linear-gradient(
        to right,
        var(--neutral50) 0%,
        #edeef1 20%,
        var(--neutral50) 40%,
        var(--neutral50) 100%
    );
    background-repeat: no-repeat;
    background-size: 50rem 6.5rem;
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${shimmer};
    animation-timing-function: linear;
`;
const Container = styled.section`
    background-color: var(--neutral50);
    display: flex;
    flex-direction: column;
    height: 100%;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        background-color: var(--white);
    }
`;
const Block = styled.div`
    border-radius: var(--r-s);
    background-color: var(--neutral100);
    ${({ w }) => w && `width: ${w};`}
    ${({ h }) => h && `height: ${h};`}
    ${shimmering};
`;
const HeaderBlockLeft = styled(Block)`
    width: 10rem;
    height: 1rem;
`;
const HeaderBlockRight = styled(Block)`
    background-color: var(--neutral50);
    width: 25rem;
    height: 2rem;
`;
const Header = styled.header`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: var(--white);

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        padding: 2rem;
    }
`;
const ContentInnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    width: 100%;
    max-width: var(--container-width);

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        flex-direction: row;
        column-gap: 2rem;
    }
`;
const Content = styled.div`
    flex-grow: 1;
    min-height: 0;
    overflow-y: hidden;
    padding: 0 1rem 1rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: flex;
        justify-content: center;
        background-color: var(--neutral50);
        border-radius: var(--r-l) var(--r-l) 0 0;
        margin-inline: 2rem;
        padding: 2rem;
    }
`;
const FooterBlock = styled(Block)`
    height: 2rem;
    width: 4rem;
`;
const FooterLightBlock = styled(FooterBlock)`
    background-color: var(--neutral100);
    width: 6rem;
    height: 2.5rem;
`;
const Footer = styled.footer`
    background-color: var(--white);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem 1rem;
    padding: 1rem;

    & > :first-child {
        background-color: var(--neutral50);
    }

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        padding: 2rem;
    }
`;

export default Skeleton;
