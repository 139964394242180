import { useMutation } from '@tanstack/react-query';
import { updateShowQuota } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import quotaKeys from './quotaKeys';
import subscriptionKeys from '../subscription/subscriptionKeys';
import showKeys from '../show/showKeys';
import { useQueryClient } from '@tanstack/react-query';

interface MutationFnProps {
    quantity: number;
}

const mutationFn = async ({ quantity }: MutationFnProps) => {
    const { data } = await updateShowQuota({ quantity });
    return camelcaseKeys(data, { deep: true });
};

const useUpdateShowQuotaMutation = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn,
        onSuccess: (_) => {
            queryClient.invalidateQueries({
                queryKey: quotaKeys.all(),
            });
            queryClient.invalidateQueries({
                queryKey: subscriptionKeys.all(),
            });
            queryClient.invalidateQueries({
                queryKey: showKeys.all(),
            });
        },
    });
};

export default useUpdateShowQuotaMutation;
