import { computed } from 'mobx';
import Model from './Model';
import { dateUTC } from './resolvers';
import { apiCall } from '../components/app/decorators/api';

class PlaylistModel extends Model {
    static default = (state) => ({
        description: '',
        name: '',
        imageUrl: null,
        privacy: 'public',
        password: null,
        showId: state.routerStore.params.showId,
        url: null,
    });

    static attributes = {
        id: 'id',
        description: 'html_description',
        imageUrl: 'image_url',
        name: 'name',
        podcastsCount: 'podcasts_count',
        publicId: 'public_id',
        showId: 'show_id',
        slug: 'slug',
        privacy: 'privacy',
        password: 'password',
        updatedAt: dateUTC('updated_at'),
        createdAt: dateUTC('created_at'),
        url: 'url',
    };

    @computed
    get show() {
        return this.state.showStore.byId(this.showId);
    }

    @apiCall
    addEpisodes(episodeIds) {
        return this.store.addEpisodesToPlaylist(this, episodeIds);
    }

    @apiCall
    moveEpisode(source, destination) {
        return this.store.moveEpisodesPlaylist(this, source, destination);
    }

    @apiCall
    deleteEpisode(episode) {
        return this.store.removePodcastFromPlaylist(this, episode);
    }

    @apiCall
    create(formData) {
        return this.store.addPlaylist(this, formData);
    }

    @apiCall
    delete() {
        return this.store.deletePlaylist(this);
    }

    @apiCall
    update(formData) {
        return this.store.updatePlaylist(this, formData);
    }
}

export default PlaylistModel;
