import { createFetcher } from './Fetcher';

export default createFetcher(
    ({ sourcesStatsStore }, { showId, itemType, itemId, start, end, episodes }) => ({
        fetch: () =>
            sourcesStatsStore.fetchSourceDevices(showId, itemType, itemId, {
                start,
                end,
                podcasts: episodes,
            }),
        isLoading: sourcesStatsStore.fetchSourceDevices.pending,
        input: [showId, itemType, itemId, start, end, episodes],
    }),
);
