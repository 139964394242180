const episodeTranscriptKeys = {
    all: () => [{ entity: 'episodeTranscript' }],
    allLists: () => [{ ...episodeTranscriptKeys.all()[0], scope: 'list' }],
    listByShowId: (
        showId,
        {
            q,
            page,
            perPage,
            sort,
            transformer,
            seasonsIds,
            states,
            publishedDate,
            hasClip,
            isListenable,
        } = {},
    ) => [
        {
            ...episodeTranscriptKeys.allLists()[0],
            showId,
            q,
            page,
            perPage,
            sort,
            transformer,
            seasonsIds,
            states,
            publishedDate,
            hasClip,
            isListenable,
        },
    ],
    allDetails: () => [{ ...episodeTranscriptKeys.all()[0], scope: 'detail' }],
    detailByEpisodeId: (rawEpisodeId) => [
        { ...episodeTranscriptKeys.allDetails()[0], episodeId: Number(rawEpisodeId) },
    ],
};

export default episodeTranscriptKeys;
