import DeprecatedText from '@ui/atoms/DeprecatedText';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import DeprecatedPaper from '@ui/atoms/DeprecatedPaper';
import { TooltipTrigger } from 'react-aria-components';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';

interface VariationAverageProps {
    step: string;
    average?: number;
}

const VariationAverage = ({ step, average = 0 }: VariationAverageProps) => {
    const eachMomentRange = {
        today: <FormattedMessage defaultMessage="Chaque jour" />,
        lastWeek: <FormattedMessage defaultMessage="Chaque semaine" />,
        lastMonth: <FormattedMessage defaultMessage="Chaque mois" />,
    };
    const MESSAGES_STEP_TOOLTIPS_AVERAGE = {
        standardMessage: (
            <FormattedMessage
                defaultMessage="{eachMomentRange}, votre émission est écoutée {average} fois en moyenne."
                values={{
                    average: <FormattedNumber value={average} />,
                    eachMomentRange: eachMomentRange[step as keyof typeof eachMomentRange],
                }}
            />
        ),
        fromStart: (
            <FormattedMessage
                defaultMessage="En moyenne, vos épisodes sont écoutés {average} fois."
                values={{ average: <FormattedNumber value={average} /> }}
            />
        ),
    };

    if (!step) return null;

    return (
        <TooltipTrigger delay={0} closeDelay={0}>
            <TooltipTriggerWrapper>
                <DeprecatedPaper
                    cursor="default"
                    mt={10}
                    py={2}
                    px={10}
                    background="--neutral50"
                    radius={50}
                    w="max-content"
                >
                    <DeprecatedText as="span" mr={5}>
                        <FormattedNumber value={average} />
                    </DeprecatedText>
                    <DeprecatedText as="span">
                        <FormattedMessage defaultMessage="en moy." />
                    </DeprecatedText>
                </DeprecatedPaper>
            </TooltipTriggerWrapper>
            <Tooltip placement="bottom">
                {step === 'fromStart'
                    ? MESSAGES_STEP_TOOLTIPS_AVERAGE.fromStart
                    : MESSAGES_STEP_TOOLTIPS_AVERAGE.standardMessage}
            </Tooltip>
        </TooltipTrigger>
    );
};

export default VariationAverage;
