import type { Stats } from '@queries/stats/useListenersStatsQuery.hook';
import { FormattedMessage, FormattedNumber, FormattedDate } from 'react-intl';
import dayjs from 'dayjs';
import { useStatsDateSelectorContext } from '@/context/StatsDateSelectorContext';
import styled from 'styled-components';

interface GraphHeaderProps {
    stats: Stats;
}

const GraphHeader = ({ stats }: GraphHeaderProps) => {
    const { startDate, endDate } = useStatsDateSelectorContext();
    const isSameDate = dayjs(dayjs(startDate).format('YYYY-MM-DD')).isSame(
        dayjs(endDate).format('YYYY-MM-DD'),
    );

    return (
        <GraphHeaderWrapper>
            <TopSection>
                <TitleAndDate>
                    <Title>
                        <FormattedNumber value={stats?.sumDownloads ?? 0} />{' '}
                        <FormattedMessage
                            defaultMessage="{totalListening, plural, =0 {écoute} one{écoute} other {écoutes}}"
                            values={{
                                totalListening: stats?.sumDownloads ?? 0,
                            }}
                        />
                    </Title>
                    <DateLabel>
                        {isSameDate ? (
                            <FormattedMessage
                                defaultMessage="Le {date}"
                                values={{
                                    date: (
                                        <FormattedDate
                                            value={startDate ?? ''}
                                            year="numeric"
                                            month="long"
                                            day="numeric"
                                        />
                                    ),
                                }}
                            />
                        ) : (
                            <FormattedMessage
                                defaultMessage="Du {from} au {to}"
                                values={{
                                    from: (
                                        <FormattedDate
                                            value={startDate ?? ''}
                                            year="numeric"
                                            month="long"
                                            day="numeric"
                                        />
                                    ),
                                    to: (
                                        <FormattedDate
                                            value={endDate ?? ''}
                                            year="numeric"
                                            month="long"
                                            day="numeric"
                                        />
                                    ),
                                }}
                            />
                        )}
                    </DateLabel>
                </TitleAndDate>
            </TopSection>
            <Legend>
                <Dot />
                <FormattedMessage defaultMessage="Nombre d'écoutes" />
            </Legend>
        </GraphHeaderWrapper>
    );
};

const GraphHeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
`;
const TopSection = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
`;
const TitleAndDate = styled.div`
    display: flex;
    align-items: flex-end;
    gap: 0.5rem;
`;
const DateLabel = styled.p`
    color: var(--neutral);
`;
const Title = styled.div`
    font-size: var(--fs-heading-m);
    font-weight: var(--fw-semibold);
    line-height: 1.5rem;
`;
const Legend = styled.div`
    display: inline-flex;
    gap: 0.5rem;
    align-items: center;
    font-weight: var(--fw-semibold);
`;
const Dot = styled.div`
    width: 0.75rem;
    height: 0.75rem;
    background-color: var(--primary);
    border-radius: var(--r-full);
    margin-bottom: 0.15rem;
`;

export default GraphHeader;
