import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchRssImport } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import rssKeys from './rssKeys';
import { z } from 'zod';

export interface useRssFeedQueryProps {
    showId: string;
    config?: {
        enabled?: boolean;
    };
}
export const RSSFeedSchema = z.object({
    auto: z.boolean(),
    id: z.number(),
    isBannerHidden: z.boolean(),
    originalPlatform: z.string().nullable(),
    showId: z.number(),
    syncedAt: z.string().nullable().optional(), // TODO: Remove optional when API is implemented
    url: z.string(),
});
export type RssFeed = z.infer<typeof RSSFeedSchema>;

type Key = QueryFunctionContext<ReturnType<typeof rssKeys.feed>>;

const queryFn = async ({ queryKey: [{ showId }] }: Key) => {
    const { data } = await fetchRssImport(showId);

    // TODO: Do camelcase transformation in Axios interceptor
    return RSSFeedSchema.parse(camelcaseKeys(data, { deep: true }));
};

const useRssFeedQuery = ({ showId, config }: useRssFeedQueryProps) => {
    return useQuery({
        queryKey: rssKeys.feed(showId),
        queryFn,
        refetchOnWindowFocus: false,
        enabled: config?.enabled !== false,
    });
};

export default useRssFeedQuery;
