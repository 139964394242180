import EpisodeAILoader from '@/components/EpisodeAI/EpisodeAILoader';
import Spinner from '@/components/ui/atoms/Spinner';
import Text from '@/components/ui/atoms/Text';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

interface ChaptersStateProps {
    hasAudioFile: boolean;
    isUploadingAudioFile: boolean;
    isFetchingChaptersSuggestions: boolean;
}

const ChaptersState = ({
    hasAudioFile,
    isUploadingAudioFile,
    isFetchingChaptersSuggestions,
}: ChaptersStateProps) => {
    if (isUploadingAudioFile) {
        return <Spinner size={24} />;
    }

    if (!hasAudioFile) {
        return (
            <NoAudioFileAlert>
                <NoAudioFileAlertIcon icon={icon({ name: 'circle-info', style: 'solid' })} />
                <Text color="--info500" fontWeight="--fw-semibold">
                    <FormattedMessage defaultMessage="Un fichier audio est requis" />
                </Text>
            </NoAudioFileAlert>
        );
    }

    if (isFetchingChaptersSuggestions) {
        return (
            <EpisodeAILoader data-testid="episode-ai-accordion-chapters-loading">
                <FormattedMessage defaultMessage="AI est entrain de générer" />
            </EpisodeAILoader>
        );
    }

    return null;
};

const NoAudioFileAlert = styled.div`
    width: fit-content;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    column-gap: 0.25rem;
    background-color: var(--info50);
    border-radius: var(--r-s);
`;
const NoAudioFileAlertIcon = styled(FontAwesomeIcon)`
    color: var(--info500);
    width: 0.75rem;
    height: 0.75rem;
`;

export default ChaptersState;
