import { useState } from 'react';
import PropTypes from 'prop-types';
import SocialMediaPost from './SocialMediaPost';
import useSocialNetworkPostsInsightsQuery from '@/queries/socialNetworkPost/useSocialNetworkPostsInsightsQuery.hook';
import ListSkeleton from './ListSkeleton';
import Pagination from './Pagination';
import styled from 'styled-components';

const SocialMediaList = ({ posts, pagination, handlePageChange }) => {
    const [dropdownOpenedAtId, setDropdownOpenedAtId] = useState(null);
    const [postDetailsShownAt, setPostDetailsShownAt] = useState(null);

    const insights = useSocialNetworkPostsInsightsQuery(
        { postIds: posts?.map((post) => post.id) },
        { enabled: !!posts },
    );

    if (!posts) return <ListSkeleton />;
    return (
        <SocialMediaListWrapper>
            <Posts>
                {posts.map((post) => (
                    <SocialMediaPost
                        key={post.id}
                        post={post}
                        insightsLoading={insights.isLoading}
                        insights={insights?.data?.find((i) => i.messageId === post.id)}
                        dropdownOpenedAtId={dropdownOpenedAtId}
                        setDropdownOpenedAtId={(id) => setDropdownOpenedAtId(id)}
                        postDetailsShownAt={postDetailsShownAt}
                        setPostDetailsShownAt={(id) => setPostDetailsShownAt(id)}
                    />
                ))}
            </Posts>
            <PaginationWrapper>
                <Pagination {...pagination} handlePageChange={(page) => handlePageChange(page)} />
            </PaginationWrapper>
        </SocialMediaListWrapper>
    );
};

const SocialMediaListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`;
const Posts = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;
const PaginationWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

SocialMediaList.propTypes = {
    posts: PropTypes.arrayOf({
        clipFormatId: PropTypes.string,
        createdAt: PropTypes.date,
        externalUrl: PropTypes.string,
        id: PropTypes.string,
        messageTpl: PropTypes.string,
        podcast: PropTypes.objectOf({
            id: PropTypes.string,
            imageUrl: PropTypes.string,
            name: PropTypes.string,
            state: PropTypes.string,
        }),
        provider: PropTypes.string,
        publishAt: PropTypes.date,
        publishedAt: PropTypes.date,
        state: PropTypes.string,
        type: PropTypes.string,
        userId: PropTypes.string,
    }),
    pagination: PropTypes.objectOf({
        count: PropTypes.number,
        currentPage: PropTypes.number,
        perPage: PropTypes.number,
        total: PropTypes.number,
        totalPages: PropTypes.number,
    }),
    handlePageChange: PropTypes.func,
};

export default SocialMediaList;
