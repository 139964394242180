import { FormattedMessage } from 'react-intl';
import { useSubscribe } from '@/shared/hooks/useSubscribe';
import Center from '@ui/layout/Center';
import { DeprecatedText } from '@ui/atoms/DeprecatedText';
import { connect } from '@/components/legacy/connect';
import Spinner from '@ui/atoms/Spinner';
import Stack from '@ui/layout/Stack';

const enhance = connect(({ clipStore }) => ({
    fetchTranscriptionProgress: clipStore.fetchTranscriptionProgress,
}));

const TranscriptionProgress = ({ clipId, fetchTranscriptionProgress }) => {
    useSubscribe(fetchTranscriptionProgress, [clipId], 5000);

    return (
        <Center max="max-content">
            <Stack $gap="1.75rem" $align="center">
                <Spinner />
                <Stack>
                    <DeprecatedText weight="bold" align="center">
                        <FormattedMessage defaultMessage="Votre clip est en cours de transcription." />
                    </DeprecatedText>
                    <DeprecatedText align="center">
                        <FormattedMessage defaultMessage="Cela peut prendre quelques minutes, alors pas de panique :)" />
                    </DeprecatedText>
                </Stack>
            </Stack>
        </Center>
    );
};

export default enhance(TranscriptionProgress);
