import Badge from '@/components/ui/atoms/Badge';
import CircularProgress from '@/components/ui/atoms/CircularProgress';
import { getColorByPercentage } from '@/shared/utils/color';
import { FormattedMessage } from 'react-intl';

interface PsoScoreProps {
    score: number;
}

const PsoScore = ({ score }: PsoScoreProps) => {
    const color = getColorByPercentage(score);
    const variant = color.slice(2);

    return (
        <Badge
            icon={
                <CircularProgress
                    size={12}
                    strokeWidth={2}
                    percentage={score}
                    backgroundColor={color === '--success' ? '--success100' : `${color}100`}
                    progressColor={color}
                />
            }
            variant={variant}
        >
            <FormattedMessage defaultMessage="{score}% optimisé" values={{ score }} />
        </Badge>
    );
};

export default PsoScore;
