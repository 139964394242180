import PropTypes from 'prop-types';
import IconChevronRight from '@ui/icons/IconChevronRight';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';

const Breadcrumb = ({ breadcrumb, onFlyTo }) => {
    const intl = useIntl();
    let breadcrumbArray = Object.entries(breadcrumb).filter(([_, value]) => !!value);
    return (
        <BreadcrumbWrapper aria-label={intl.formatMessage({ defaultMessage: 'Fil d’Ariane' })}>
            {breadcrumbArray.map(([layer, value], index) => (
                <Crumb
                    key={layer}
                    active={index === breadcrumbArray.length - 1}
                    onClick={() => {
                        if (!value?.coordinates) return;
                        onFlyTo(value.coordinates);
                    }}
                >
                    {value.label}
                    {index < breadcrumbArray.length - 1 && <Chevron />}
                </Crumb>
            ))}
        </BreadcrumbWrapper>
    );
};

Breadcrumb.propTypes = {
    breadcrumb: PropTypes.shape({
        country: PropTypes.shape({
            label: PropTypes.string,
            coordinates: PropTypes.arrayOf(PropTypes.number),
        }),
        region: PropTypes.shape({
            label: PropTypes.string,
        }),
    }),
    onFlyTo: PropTypes.func,
};

const BreadcrumbWrapper = styled.ul`
    list-style: none;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
`;

const Crumb = styled.li`
    transition-duration: 0.2s;
    display: flex;
    align-items: center;
    color: var(--neutral500);
    cursor: pointer;
    font-weight: var(--fw-semibold);

    &:hover {
        transition-duration: 0.2s;
        color: var(--primary);
    }

    ${(props) =>
        props.active &&
        css`
            color: var(--black);
            cursor: default;

            &:hover {
                color: var(--black);
            }
        `}
`;

const Chevron = styled(IconChevronRight)`
    font-size: 0.8rem;
    color: var(--neutral300);
`;

export default Breadcrumb;
