import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    getPaginationRowModel,
    useReactTable,
    createColumnHelper,
} from '@tanstack/react-table';
import type { SortDirection } from '@tanstack/react-table';
import IconCaretDown from '@ui/icons/IconCaretDown';
import ReactCountryFlag from 'react-country-flag';
import Pagination from './Pagination';
import Spinner from '@ui/atoms/Spinner/Spinner';
import { jsonToCSV } from '@/helpers';
import dayjs from 'dayjs';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import styled, { css } from 'styled-components';

export interface IpGeolocalizationTableProps {
    data: any;
    currentLayerLevel: string;
    isLoading: boolean;
    startDate: string;
    endDate: string;
}

export type GeolocalizationEntity = 'countries' | 'regions' | 'cities';

export interface GeolocalizationRecord {
    code?: string;
    coordinates: any;
    downloads: number;
    entity: {
        id: string;
        label: string;
    };
    id?: string;
    percentage: number;
    weight: number;
}

interface ExportableGeolocalizationRecord {
    entity: string;
    downloads: number;
    percentage: number;
}

const IpGeolocalizationTable = ({
    data,
    currentLayerLevel,
    isLoading,
    startDate,
    endDate,
}: IpGeolocalizationTableProps) => {
    const intl = useIntl();
    const { showId }: { showId: string } = useParams();
    const { data: show } = useShowQuery(showId);
    const [sorting, setSorting] = useState([{ id: 'downloads', desc: true }]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 15,
    });

    const layerLevelLookup = {
        countries: intl.formatMessage({ defaultMessage: 'Pays' }),
        regions: intl.formatMessage({ defaultMessage: 'Régions / Etats' }),
        cities: intl.formatMessage({ defaultMessage: 'Villes' }),
    };

    const exportEntityNameLookup = {
        countries: intl.formatMessage({ defaultMessage: 'pays' }),
        regions: intl.formatMessage({ defaultMessage: 'regions' }),
        cities: intl.formatMessage({ defaultMessage: 'villes' }),
    };

    const columnHelper = createColumnHelper<GeolocalizationRecord>();

    const columns = [
        columnHelper.accessor('entity', {
            sortingFn: (rowA, rowB) => {
                return rowA?.original?.entity?.label.localeCompare(rowB?.original?.entity?.label);
            },
            cell: (info) => (
                <CountryWrapper>
                    {currentLayerLevel === 'countries' && (
                        <ReactCountryFlag countryCode={info.getValue().id} svg />
                    )}
                    <span>{info.getValue()?.label || ''}</span>
                </CountryWrapper>
            ),
            header: layerLevelLookup[currentLayerLevel as GeolocalizationEntity],
            size: Number.MAX_SAFE_INTEGER,
        }),
        columnHelper.accessor('downloads', {
            cell: (info) => intl.formatNumber(info.getValue() || 0),
            header: intl.formatMessage({ defaultMessage: 'Écoutes' }),
        }),
        columnHelper.accessor('percentage', {
            cell: (info) =>
                intl.formatNumber(info.getValue() || 0, {
                    style: 'unit',
                    unit: 'percent',
                    unitDisplay: 'narrow',
                }),
            header: intl.formatMessage({ defaultMessage: 'Pourcentage' }),
        }),
    ];

    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
            pagination,
        },
        onSortingChange: setSorting,
        onPaginationChange: setPagination,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        debugTable: false,
    });

    const sortIconRender = (isSorted?: false | SortDirection) => {
        return !isSorted ? null : isSorted === 'asc' ? <IconCaretDown /> : <IconCaretUp />;
    };

    const onExport = () => {
        const csvData = jsonToCSV(
            data.map((record: GeolocalizationRecord): ExportableGeolocalizationRecord => {
                return {
                    entity: record.entity.label,
                    downloads: record.downloads,
                    percentage: record.percentage,
                };
            }),
            [
                layerLevelLookup[currentLayerLevel as GeolocalizationEntity],
                intl.formatMessage({ defaultMessage: 'Écoutes' }),
                intl.formatMessage({ defaultMessage: 'Pourcentage' }),
            ],
        );
        const formattedStartDate = dayjs(startDate).format('YYYY-MM-DD');
        const formattedEndDate = dayjs(endDate).format('YYYY-MM-DD');
        const showSlug = show?.slug;
        const statTranslated = intl.formatMessage({ defaultMessage: 'statistiques' });
        const entityTranslated = exportEntityNameLookup[currentLayerLevel as GeolocalizationEntity];
        const filename = `${formattedStartDate}-to-${formattedEndDate}-${showSlug}-${statTranslated}-${entityTranslated}.csv`;
        downloadDataToCsv(csvData, filename);
    };

    const downloadDataToCsv = (data: string, fileName: string) => {
        const url = window.URL.createObjectURL(
            new Blob([data], {
                type: 'text/csv',
            }),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
    };

    if (isLoading) {
        return (
            <NoDataWrapper>
                <Spinner />
            </NoDataWrapper>
        );
    }

    if (!data || data.length === 0) {
        return (
            <NoDataWrapper>
                <strong>
                    <FormattedMessage defaultMessage="Pas de données" />
                </strong>
            </NoDataWrapper>
        );
    }

    return (
        <TableWrapper>
            <Table>
                <Thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <Tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => {
                                return (
                                    <Th
                                        key={header.id}
                                        colSpan={header.colSpan}
                                        style={{ width: header.getSize() }}
                                    >
                                        {header.isPlaceholder ? null : (
                                            <ThContent
                                                onClick={header.column.getToggleSortingHandler()}
                                                sortable={header.column.getCanSort()}
                                            >
                                                {flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext(),
                                                )}

                                                {sortIconRender(header.column.getIsSorted())}
                                            </ThContent>
                                        )}
                                    </Th>
                                );
                            })}
                        </Tr>
                    ))}
                </Thead>
                <Tbody>
                    {table.getRowModel().rows.map((row) => {
                        return (
                            <Tr key={row.id}>
                                {row.getVisibleCells().map((cell) => {
                                    return (
                                        <Td key={cell.id} style={{ width: cell.column.getSize() }}>
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext(),
                                            )}
                                        </Td>
                                    );
                                })}
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>
            <Pagination table={table} pagination={pagination} onExport={onExport} />
        </TableWrapper>
    );
};

const TableWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
`;
const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    background-color: var(--white);
    border-radius: var(--r-xs);
`;
const Thead = styled.thead`
    border-bottom: 1px solid var(--neutral200);
`;
const Th = styled.th`
    text-align: left;
    color: var(--neutral500);
    font-weight: var(--fw-normal);
    padding-block: 0.5rem;
    padding-inline: 1rem;
`;
const ThContent = styled.div<{ sortable?: boolean }>`
    display: inline-flex;
    align-items: center;
    column-gap: 0.5rem;
    transition-duration: 0.2s;
    ${(props) =>
        props.sortable &&
        css`
            cursor: pointer;
        `}

    &:hover {
        transition-duration: 0.2s;
        opacity: 0.7;
    }
`;
const Tbody = styled.tbody``;
const Tr = styled.tr`
    &:not(:last-of-type) {
        border-bottom: 1px solid var(--neutral200);
    }
`;
const Td = styled.td`
    padding: 1rem;
    padding-right: 2rem;
`;
const IconCaretUp = styled(IconCaretDown)`
    transform: rotate(180deg);
`;
const NoDataWrapper = styled.div`
    width: 100%;
    background-color: var(--white);
    border-radius: var(--r-l);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-block: 2rem;
`;
const CountryWrapper = styled.div`
    font-weight: var(--fw-bold);
    display: flex;
    align-items: center;
    column-gap: 0.5rem;

    & > * {
        display: inline-block;
        line-height: 1.5rem;
    }
`;

export default IpGeolocalizationTable;
