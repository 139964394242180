import { css } from 'styled-components';
import PropTypes from 'prop-types';
import { computeCssValue } from '../../../../shared/utils/css';
import { resolveColor } from '@/shared/utils/cssVariable';

/**
 * See https://chakra-ui.com/docs/features/style-props
 */
const textDecorationMixin = css`
    text-decoration-color: ${(props) => resolveColor(props.$textDecorationColor)};
    text-decoration: ${(props) => props.$textDecoration};
    text-decoration-line: ${(props) => props.$textDecorationLine};
    text-decoration-style: ${(props) => props.$textDecorationStyle};
    text-underline-offset: ${(props) => computeCssValue(props.$textUnderlineOffset)};
`;

export const textDecorationPropTypes = {
    $textDecorationColor: PropTypes.string,
    $textDecoration: PropTypes.string,
    $textDecorationLine: PropTypes.string,
    $textDecorationStyle: PropTypes.string,
    $textUnderlineOffset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    $textShadow: PropTypes.string,
};

export default textDecorationMixin;
