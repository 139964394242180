import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchApplePodcastsRankingStats } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import statsKeys from './statsKeys';
import { z } from 'zod';

export const StatSchema = z.object({
    at: z.number(),
    position: z.number().nullable(),
    variation: z.number().nullable(),
});
const StatsSchema = z.array(StatSchema);
const StatsResponseSchema = z.object({
    data: StatsSchema,
    meta: z.object({
        step: z.string(),
    }),
});
export type Stat = z.infer<typeof StatSchema>;
type Key = QueryFunctionContext<ReturnType<typeof statsKeys.appleRanking>>;
type InputProps = {
    showId: string;
    categoryId: string;
    start: string;
    end: string;
    precision: string;
    timezone: string;
    options?: {
        enabled?: boolean;
    };
};

const queryFn = async ({
    queryKey: [{ showId, categoryId, start, end, timezone, precision }],
}: Key) => {
    const response = await fetchApplePodcastsRankingStats(showId, categoryId, {
        start,
        end,
        step: precision,
        timezone,
    });
    return StatsResponseSchema.parse(camelcaseKeys(response, { deep: true }));
};

const useAppleRankingQuery = ({
    showId,
    categoryId,
    start,
    end,
    precision,
    timezone,
    options,
}: InputProps) => {
    return useQuery({
        queryKey: statsKeys.appleRanking(showId, categoryId, start, end, timezone, precision),
        queryFn,
        enabled: options?.enabled || true,
    });
};

export default useAppleRankingQuery;
