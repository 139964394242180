import ContentLoader from 'react-content-loader';
import createFallback from './createFallback';

const h = 13;

const mb1 = 8 + h;
const mb2 = 30 + mb1;

const w1 = 200;
const w2 = 820;

const Rect = (props) => <rect x={0} rx={2} ry={2} width={w1} height={h} {...props} />;

function ListFormFallback({ theme, ...props }) {
    return (
        <ContentLoader
            height={350}
            width={840 - 4 * 35}
            style={{ padding: '20px', width: '100%' }}
            {...props}
        >
            <Rect y="0" />
            <Rect y={mb1} width={w2} />

            <Rect y={mb1 + mb2} />
            <Rect y={2 * mb1 + mb2} width={w2} />

            <Rect y={2 * mb1 + 2 * mb2} />
            <Rect y={3 * mb1 + 2 * mb2} width={w2} />

            <Rect y={3 * mb1 + 3 * mb2} />
            <Rect y={4 * mb1 + 3 * mb2} width={w2} />

            <Rect y={4 * mb1 + 4 * mb2} />
            <Rect y={5 * mb1 + 4 * mb2} width={w2} />
        </ContentLoader>
    );
}

export default createFallback(ListFormFallback);
