import { useIntl } from 'react-intl';
import { z } from 'zod';
import { FORM_VALIDATION_MESSAGE } from '@/shared/services/validationMessageService';
import { checkImgDimensions, checkImgSize } from '@/shared/utils/file';

interface useNewShowManualFormSchemaProps {
    locale?: string;
}

const useCreateNewShowFormSchema = (
    { locale }: useNewShowManualFormSchemaProps = { locale: 'en' },
) => {
    const intl = useIntl();
    const acceptedMimes = ['image/jpg', 'image/jpeg', 'image/png'];
    const IMG_DIMENSIONS = { minWidth: 400, minHeight: 400 };

    return z.object({
        cover: z
            .instanceof(File)
            .refine(
                (file: File) => {
                    if (file === null) return true;
                    return acceptedMimes.includes(file.type);
                },
                {
                    message: intl.formatMessage(FORM_VALIDATION_MESSAGE.acceptedMimes, {
                        acceptedMimes,
                    }) as string,
                },
            )
            .refine(
                (file: File) => {
                    if (typeof file === 'string' || file === null) return true;
                    return checkImgSize(file, locale, 10);
                },
                {
                    message: intl.formatMessage(FORM_VALIDATION_MESSAGE.imgMaxSize, {
                        max: 10,
                    }) as string,
                },
            )
            .refine(
                async (file: File) => {
                    if (typeof file === 'string' || file === null) return true;
                    const img = new Image();
                    img.src = URL.createObjectURL(new File([file], 'pic'));
                    return new Promise<boolean>((resolve) => {
                        img.onload = () => {
                            const isValid = checkImgDimensions(img, IMG_DIMENSIONS) || false;
                            resolve(isValid);
                        };
                    });
                },
                {
                    message: intl.formatMessage(
                        FORM_VALIDATION_MESSAGE.imgMinDimensions,
                        IMG_DIMENSIONS,
                    ) as string,
                },
            )
            .optional()
            .nullable(),
        name: z
            .string({
                required_error: intl.formatMessage(FORM_VALIDATION_MESSAGE.required),
            })
            .min(3, { message: intl.formatMessage(FORM_VALIDATION_MESSAGE.minLength, { min: 3 }) })
            .max(140, {
                message: intl.formatMessage(FORM_VALIDATION_MESSAGE.maxLength, { max: 140 }),
            }),
        htmlDescription: z
            .string({
                required_error: intl.formatMessage(FORM_VALIDATION_MESSAGE.required),
            })
            .max(3900, {
                message: intl.formatMessage(FORM_VALIDATION_MESSAGE.maxLength, { max: 3900 }),
            })
            .optional()
            .nullable(),
        author: z
            .string({
                required_error: intl.formatMessage(FORM_VALIDATION_MESSAGE.required),
            })
            .min(1, { message: intl.formatMessage(FORM_VALIDATION_MESSAGE.required) })
            .max(255, {
                message: intl.formatMessage(FORM_VALIDATION_MESSAGE.maxLength, { max: 255 }),
            }),
        languageId: z.number({
            required_error: intl.formatMessage(FORM_VALIDATION_MESSAGE.required),
        }),
        categoryId: z.number({
            required_error: intl.formatMessage(FORM_VALIDATION_MESSAGE.required),
        }),
    });
};

export default useCreateNewShowFormSchema;
