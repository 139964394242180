import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { FORM_VALIDATION_MESSAGE } from '@/shared/services/validationMessageService';

const useSmartlinkSettingsFormSchema = () => {
    const intl = useIntl();

    return yup.object({
        isPublished: yup.bool().required(intl.formatMessage(FORM_VALIDATION_MESSAGE.required)),
        accentColor: yup.string().required(intl.formatMessage(FORM_VALIDATION_MESSAGE.required)),
        backgroundColor: yup
            .string()
            .required(intl.formatMessage(FORM_VALIDATION_MESSAGE.required)),
        coverUrl: yup.mixed().nullable(),
        isPlayerEnabled: yup.bool().required(intl.formatMessage(FORM_VALIDATION_MESSAGE.required)),
        listeningLinks: yup
            .array()
            .of(
                yup.object().shape({
                    id: yup.number(),
                    isHidden: yup.boolean(),
                    key: yup.string(),
                    position: yup.number(),
                    // url: yup.string().url(),
                }),
            )
            .default([]),
        ctaButtons: yup
            .array()
            .of(
                yup.object().shape({
                    id: yup.number(),
                    locations: yup.array().of(yup.string()),
                    text: yup.string(),
                    showId: yup.number(),
                    type: yup.string(),
                    url: yup.string().url(),
                }),
            )
            .default([]),
    });
};

export default useSmartlinkSettingsFormSchema;
