import { Clip } from '@/queries/clip/useClipQuery';
import styled from 'styled-components';
import Header from './Header';
import Footer from './Footer';
import { Episode } from '@/queries/episode/useEpisodeQuery.hook';
import AudioSample from './AudioSample';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import useClipFormSchema, { ClipFormSchema } from './schemas/useFormSchema';
import Transcription from './Transcription';
import TranscriptionSelection from './TranscriptionSelection';
import useClipTranscriptionQuery from '@/queries/clip/useClipTranscriptionQuery';
import Formats from './Formats';
import Customization from './Customization';

export const AUDIO_SAMPLE_SELECTION_STEP_LABEL = 'audio_sample_selection';
export const TRANSCRIPTION_SELECTION_STEP_LABEL = 'transcription_selection';
export const TRANSCRIPTION_STEP_LABEL = 'transcription';
export const FORMAT_SELECTION_STEP_LABEL = 'format_selection';
export const CUSTOMIZATION_STEP_LABEL = 'customization';

export const CLIP_FORM_STEPS = {
    AUDIO_SAMPLE_SELECTION: 'audio_sample_selection',
    TRANSCRIPTION_SELECTION: 'transcription_selection',
    TRANSCRIPTION: 'transcription',
    FORMAT_SELECTION: 'format_selection',
    CUSTOMIZATION: 'customization',
};

interface ClipFormProps {
    clip: Clip;
    episode: Episode;
}

const ClipForm = ({ clip, episode }: ClipFormProps) => {
    const schema = useClipFormSchema(episode.duration);
    const { data: transcription } = useClipTranscriptionQuery(clip.id);
    const form = useForm<ClipFormSchema>({
        mode: 'onBlur',
        resolver: zodResolver(schema),
        values: {
            audioSample: {
                start: clip.start ?? 0,
                end: clip.end ?? 30,
                duration: clip.duration ?? 30,
            },
            transcriptionSelection: {
                transcript: clip.transcript ?? false,
                lang: clip.lang ?? 'fr-FR',
            },
            transcription: {
                start: transcription?.start ?? 0,
                end: transcription?.end ?? 30,
                subtitles: transcription?.subtitles ?? [],
            },
            formats: clip.formats.map((format) => format.format) ?? [],
            customization: {
                showWaveform: clip.showWaveform ?? true,
                color: clip.color ?? '#751CBF',
                showTitles: clip.showTitles ?? true,
                titleColor: clip.titleColor ?? '#FFFFFF',
                title: clip.title ?? '',
                caption: clip.caption ?? '',
            },
        },
    });

    const steps = {
        [CLIP_FORM_STEPS.AUDIO_SAMPLE_SELECTION]: <AudioSample episode={episode} />,
        [CLIP_FORM_STEPS.TRANSCRIPTION_SELECTION]: <TranscriptionSelection />,
        [CLIP_FORM_STEPS.TRANSCRIPTION]: <Transcription episode={episode} />,
        [CLIP_FORM_STEPS.FORMAT_SELECTION]: <Formats />,
        [CLIP_FORM_STEPS.CUSTOMIZATION]: <Customization episode={episode} clip={clip} />,
    };

    return (
        <FormProvider {...form}>
            <Container>
                <Header clip={clip} />
                <Content>
                    <ContentInner>{steps[clip.step]}</ContentInner>
                </Content>
                <Footer clip={clip} episode={episode} />
            </Container>
        </FormProvider>
    );
};

const Container = styled.section`
    background-color: var(--neutral50);
    display: flex;
    flex-direction: column;
    height: 100%;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        background-color: var(--white);
    }
`;
const Content = styled.div`
    flex-grow: 1;
    min-height: 0;
    overflow-y: auto;
    padding: 1rem;

    ${({ theme }) => theme.mediaQueries.desktopAndUp} {
        display: flex;
        justify-content: center;
        background-color: var(--neutral50);
        border-radius: var(--r-l) var(--r-l) 0 0;
        margin-inline: 2rem;
        padding: 2rem;
    }
`;
const ContentInner = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem 2rem;
    width: 100%;

    ${({ theme }) => theme.mediaQueries.desktopLargeAndUp} {
        flex-direction: row;
        max-width: calc(var(--container-width) - 4rem);
    }
`;

export default ClipForm;
