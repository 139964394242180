import styled from 'styled-components';

const SidebarSection = styled.li`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    &:not(:last-child) {
        margin-bottom: 1rem;
    }
`;

export default SidebarSection;
