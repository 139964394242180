import styled from 'styled-components';
import Text from '@ui/atoms/Text';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '@ui/atoms/Button';
import aushaProBackground from '@public/images/background-ausha-pro.png';
import aushaProIllustration from '@public/images/illustration-ausha-pro.png';
import AushaProLogo from '@ui/atoms/AushaProLogo';

const Container = styled.div`
    padding: 2.75rem 3.125rem;
    border-radius: 1.5rem;
    background: #120028 url(${aushaProBackground}) left center / cover no-repeat;

    ${(props) => props.theme.mediaQueries.desktopAndUp} {
        background: url(${aushaProIllustration}) right bottom / auto 100% no-repeat,
            #120028 url(${aushaProBackground}) left center / cover no-repeat;
    }
`;

const InlineAushaProLogo = styled(AushaProLogo)`
    display: inline;
    max-height: 1.25rem;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    ${(props) => props.theme.mediaQueries.desktopAndUp} {
        max-width: min(42rem, 60%);
        align-items: flex-start;
    }
`;

const Wording = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;

const Title = styled(Text)`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5rem;
    align-items: center;
`;

const AushaProButton = styled(Button)`
    background: var(--white100);
    color: var(--white);
    padding: 0.75rem 1.5rem;

    /* Gradient border */
    border-width: 0;
    border-left: 1px solid hsl(var(--white-base) / 1);
    border-right: 1px solid hsl(var(--white-base) / 0);
    background-image: linear-gradient(90deg, hsl(var(--white-base) / 1), hsl(var(--white-base) / 0)),
        linear-gradient(90deg, hsl(var(--white-base) / 1), hsl(var(--white-base) / 0));
    background-size: 100% 1px;
    background-position: 0 0, 0 100%;
    background-repeat: no-repeat;

    > .button-text {
        font-weight: var(--fw-bold);
        font-size: var(--fs-body-l);
        line-height: var(--lh-body-l);
    }
`;

const AushaPro = () => {
    const intl = useIntl();

    return (
        <Container>
            <Content>
                <Wording>
                    <Title variant="headingS" fontWeight="--fw-bold" color="--white">
                        <FormattedMessage
                            defaultMessage="Découvrez {aushaProLogo} pour les Podcasts de Marques"
                            values={{
                                aushaProLogo: <InlineAushaProLogo />,
                            }}
                        />
                    </Title>
                    <Text variant="bodyL" color="--white700">
                        <FormattedMessage defaultMessage="Accédez à un accompagnement sur-mesure et des fonctionnalités exclusives créées pour répondre aux besoins des entreprises, agences et collectivités." />
                    </Text>
                </Wording>
                {/* @ts-ignore */}
                <AushaProButton
                    forwardedAs="a"
                    href={intl.formatMessage({
                        defaultMessage:
                            'https://www.ausha.co/fr/pro?utm_source=app&utm_medium=referral&utm_campaign=Page_pricing',
                    })}
                    target="_blank"
                    variant="secondary"
                >
                    <FormattedMessage defaultMessage="En savoir plus" />
                </AushaProButton>
            </Content>
        </Container>
    );
};

export default AushaPro;
