import AushaProShowSettings from '@/components/AushaProShowSettings';
import Text from '@/components/ui/atoms/Text';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const AushaProShowSettingsPage = () => {
    return (
        <Container>
            <Text variant="title" fontWeight="--fw-bold">
                <FormattedMessage defaultMessage="Ausha Pro" />
            </Text>
            <AushaProShowSettings />
        </Container>
    );
};

const Container = styled.div`
    padding: var(--container-padding-mobile);
    background-color: var(--white);
    border-radius: var(--r-xs);
    display: flex;
    flex-direction: column;
    row-gap: var(--sp-xl);

    ${(props) => props.theme.mediaQueries.desktopAndUp} {
        padding: var(--container-padding);
    }
`;

export default AushaProShowSettingsPage;
