import styled from 'styled-components';

const PsoKeywordsRanking = () => {
    return <PsoKeywordsRankingWrapper />;
};

const PsoKeywordsRankingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

export default PsoKeywordsRanking;
