import { QUOTA_TYPES } from '@/shared/config/constants';
import { createApi } from '../callApi';
const contentApi = createApi('CONTENT');

export const fetchNewsletterQuota = () => {
    return contentApi({ method: 'GET', target: `/v1/me/quotas/newsletter` });
};
export const fetchTranscriptQuota = () => {
    return contentApi({ method: 'GET', target: `/v1/me/quotas/transcript_seconds` });
};
export const fetchTranscriptShowQuota = (showId) => {
    return contentApi({
        method: 'GET',
        target: `/v1/shows/${showId}/channel/quotas/transcript_seconds`,
    });
};
export const fetchAiGeneratedSocialPostQuota = () => {
    return contentApi({ method: 'GET', target: `/v1/me/quotas/ai_tokens` });
};
export const fetchYoutubeQuota = () => {
    return contentApi({ method: 'GET', target: `/v1/me/quotas/youtube` });
};
export const updateNewsletterQuota = (body) => {
    return contentApi({
        method: 'PUT',
        target: '/v1/me/quotas',
        body: {
            ...body,
            type: QUOTA_TYPES.NEWSLETTER,
        },
    });
};
export const updateTranscriptQuota = (quantity) => {
    return contentApi({
        method: 'PUT',
        target: '/v1/me/quotas',
        body: {
            quantity,
            type: QUOTA_TYPES.TRANSCRIPT,
        },
    });
};
export const fetchNewsletterQuotaPrice = (body) => {
    return contentApi({
        method: 'POST',
        target: '/v1/me/quotas/price',
        body: {
            ...body,
            type: QUOTA_TYPES.NEWSLETTER,
        },
    });
};
export const fetchTranscriptQuotaPrice = (quantity) => {
    return contentApi({
        method: 'POST',
        target: '/v1/me/quotas/price',
        body: {
            quantity,
            type: QUOTA_TYPES.TRANSCRIPT,
        },
    });
};
export const fetchClipQuotaOfShow = (showId) => {
    return contentApi({ method: 'GET', target: `/v1/shows/${showId}/quotas/clips` });
};
