import { useFormContext } from 'react-hook-form';
import { CampaignFormSchema } from '../../useFormSchema';
import { InputTextController } from '@/components/ui/atoms/InputText';
import { FormattedMessage } from 'react-intl';
import { DateTimePickerController } from '@/components/DateTimePicker/DateTimePicker.controller';
import { CAMPAIGN_STATUS } from '@/shared/config/constants';
import useCampaignQuery from '@/queries/campaign/useCampaignQuery';
import { useParams } from 'react-router';
import Stack from '@/components/ui/layout/Stack';
import styled from 'styled-components';
import dayjs from 'dayjs';

const Duration = () => {
    const { campaignId } = useParams<{ campaignId: string }>();
    const { control, watch } = useFormContext<CampaignFormSchema>();
    const { data: campaign } = useCampaignQuery(campaignId);
    const isActive =
        campaign?.state === CAMPAIGN_STATUS.PAUSED ||
        campaign?.state === CAMPAIGN_STATUS.ACTIVE ||
        campaign?.state === CAMPAIGN_STATUS.PLANIFIED;

    const startsAt = watch('duration.startsAt');

    return (
        <Stack $gap="2rem">
            <DateTimePickerController
                control={control}
                name="duration.startsAt"
                dateProps={{
                    label: <FormattedMessage defaultMessage="Commence le" />,
                }}
                timeProps={{}}
                isDisabled={isActive}
            />
            <DateTimePickerController
                control={control}
                name="duration.endsAt"
                dateProps={{
                    label: <FormattedMessage defaultMessage="Termine le" />,
                    isDateUnavailable: (date) => {
                        return dayjs(date).isBefore(dayjs(startsAt));
                    },
                }}
                timeProps={{}}
                isDisabled={isActive}
            />
            <InputTextControllerStyled
                control={control}
                type="number"
                name="maxListens"
                label={<FormattedMessage defaultMessage="Nombre d'écoutes maximum" />}
                isOptional
                min={0}
                max={10000000}
            />
        </Stack>
    );
};

const InputTextControllerStyled = styled(InputTextController)`
    max-width: 16rem;
`;

export default Duration;
