import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { fetchClipFormatsOfPodcast } from '@/api';
import camelcaseKeys from 'camelcase-keys';
import clipFormatKeys from './clipFormatKeys';
import { z } from 'zod';
import { ClipFormatRaw } from './types';
import { ClipFormatSchema } from './ClipFormat.schema';

const ClipFormatsSchema = z.array(ClipFormatSchema);

type Key = QueryFunctionContext<ReturnType<typeof clipFormatKeys.listByEpisodeId>>;

const queryFn = async ({ queryKey: [{ episodeId }] }: Key) => {
    const { data } = await fetchClipFormatsOfPodcast(episodeId);

    const formattedData = data.map((clipFormat: ClipFormatRaw) => {
        const { podcast_video_clip, images, ...rest } = clipFormat;
        const { podcast_id, ...clip } = podcast_video_clip.data;
        return {
            ...rest,
            images: images.data,
            clip: {
                ...clip,
                episodeId: podcast_id,
            },
        };
    });

    // TODO: Do camelcase transformation in Axios interceptor
    return ClipFormatsSchema.parse(camelcaseKeys(formattedData, { deep: true }));
};

interface UseEpisodeClipFormatsQueryProps {
    episodeId: string;
    options?: {
        staleTime?: number;
    };
}

const useEpisodeClipFormatsQuery = ({ episodeId, options }: UseEpisodeClipFormatsQueryProps) => {
    return useQuery({
        queryKey: clipFormatKeys.listByEpisodeId(episodeId),
        queryFn,
        enabled: !!episodeId,
        ...options,
    });
};

export default useEpisodeClipFormatsQuery;
