import styled from 'styled-components';
import { computeCssValue } from '@/shared/utils/css';

interface ClusterProps {
    $gap?: string | number;
    $rowGap?: string | number;
    $columnGap?: string | number;
    $alignItems?: string;
    $align?: string;
    $justifyContent?: string;
    $justify?: string;
    $flexWrap?: string;
    $wrap?: string;
    className?: string;
}

/**
 * Cluster is a layout component that adds a defined space between its children.
 * It wraps its children automatically if there isn't enough space to fit any more in the same row.
 *
 * Example:
 * <Cluster>
 *     <h2>Some text</h2>
 *     <img src="path/to/some/image.svg" />
 *     <p>More text</p>
 * </Cluster>
 *
 * References:
 * https://every-layout.dev/layouts/cluster/
 * https://chakra-ui.com/docs/layout/wrap
 * https://seek-oss.github.io/braid-design-system/components/Inline
 */
const Cluster = styled.div<ClusterProps>`
    display: flex;
    flex-direction: row;
    gap: ${(props) => computeCssValue(props.$gap)};
    row-gap: ${(props) => computeCssValue(props.$rowGap)};
    column-gap: ${(props) => computeCssValue(props.$columnGap)};
    align-items: ${(props) => props.$alignItems ?? props.$align ?? 'flex-start'};
    justify-content: ${(props) => props.$justifyContent ?? props.$justify};
    flex-wrap: ${(props) => props.$flexWrap ?? props.$wrap ?? 'wrap'};
`;

export default Cluster;
