import { useState } from 'react';
import { useParams, useHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FormattedMessage, useIntl } from 'react-intl';
import EpisodePublishModal from '@/components/unorganized/EpisodePublishModal';
import { useFormContext } from 'react-hook-form';
import { useEpisodeBeingEdited } from '@/context/EpisodeFormContext';
import getPublishSchema from '@/components/unorganized/EpisodeEditForm/schemas/publishSchema';
import { getDirtyValues } from '@/helpers';
import useSaveEpisodeFormMutation from '@/components/unorganized/EpisodeEditForm/helpers/useSaveEpisodeFormMutation';
import useSubscriptionQuery from '@queries/subscription/useSubscriptionQuery.hook';
import useUserQuery from '@queries/user/useUserQuery.hook';
import EpisodeEditFormActionsTooltipMessage from '@/components/unorganized/EpisodeEditForm/actions/EpisodeEditFormActionsTooltipMessage';
import { EPISODE_STATUS, USER_ROLES } from '@/shared/config/constants';
import userKeys from '@queries/user/userKeys';
import { useQueryClient } from '@tanstack/react-query';
import { useEpisodeEditAudioFileMutation } from '@/context/EpisodeEditContext';
import { getChaptersIdsRequiringImageDeletion } from '@/components/unorganized/EpisodeEditForm/helpers/getChaptersIdsRequiringImageDeletion';
import showKeys from '@queries/show/showKeys';
import { TooltipTrigger } from 'react-aria-components';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';
import Button from '@/components/Button';

const PublishButton = ({ className }) => {
    const history = useHistory();
    const intl = useIntl();
    const queryClient = useQueryClient();
    const {
        watch,
        reset,
        formState: { dirtyFields },
    } = useFormContext();
    const saveEpisodeFormMutation = useSaveEpisodeFormMutation();
    const episodeEditAudioFileMutation = useEpisodeEditAudioFileMutation();
    const { showId } = useParams();
    const { data: subscription } = useSubscriptionQuery();
    const { data: user } = useUserQuery();

    const [isModalOpened, setModalOpened] = useState(false);

    const episodeBeingEdited = useEpisodeBeingEdited();
    const isScheduled = episodeBeingEdited?.state === EPISODE_STATUS.SCHEDULED;
    const schema = getPublishSchema(intl);
    const formData = watch();
    const isSchemaValid = schema.isValidSync(formData);
    const hasAudioFile =
        episodeBeingEdited.files.length > 0 && !episodeEditAudioFileMutation.isLoading;
    const isFreemiumQuotaReached =
        subscription.isFreemium &&
        episodeBeingEdited.show?.userRole === USER_ROLES.OWNER &&
        user.trialEpisodes >= user.maximumTrialEpisodes;
    const isValid = isSchemaValid && hasAudioFile && !isFreemiumQuotaReached;

    const onPress = () => {
        setModalOpened(true);
    };

    const handlePublishEpisode = async () => {
        const dirtyValues = getDirtyValues(dirtyFields, formData);
        const chaptersIdsRequiringImageDeletion = getChaptersIdsRequiringImageDeletion(
            dirtyFields,
            formData.chapters,
        );

        const { content, options, privacy } = dirtyValues;
        const episodeData = { ...content, ...options, ...privacy, state: EPISODE_STATUS.ONLINE };

        try {
            await saveEpisodeFormMutation.mutateAsync(
                {
                    episodeId: episodeBeingEdited.id,
                    episode: episodeData,
                    chapters: dirtyFields.chapters ? formData.chapters : null,
                    chaptersIdsRequiringImageDeletion,
                },
                {
                    onSuccess: () => {
                        reset();
                        if (subscription.isFreemium) {
                            queryClient.invalidateQueries({ queryKey: userKeys.detail() });
                        }
                        queryClient.invalidateQueries({ queryKey: showKeys.detailById(showId) });
                        history.push(
                            `/app/show/${episodeBeingEdited.showId}/episodes/recap/${episodeBeingEdited.id}`,
                        );
                    },
                },
            );
        } catch (error) {
            // Handle error here ?
        }
    };

    return (
        <>
            <TooltipTrigger delay={0} closeDelay={0} isDisabled={isValid}>
                <TooltipTriggerWrapper>
                    <Button
                        onPress={onPress}
                        isDisabled={!isValid}
                        startIcon={
                            isScheduled ? null : (
                                <FontAwesomeIcon
                                    icon={icon({ name: 'rocket-launch', style: 'solid' })}
                                />
                            )
                        }
                        className={className}
                        data-testid="episode-form-publish-now-button"
                    >
                        <FormattedMessage defaultMessage="Publier maintenant" />
                    </Button>
                </TooltipTriggerWrapper>
                <Tooltip placement="bottom">
                    <EpisodeEditFormActionsTooltipMessage
                        isFreemiumQuotaReached={isFreemiumQuotaReached}
                        hasAudioFile={hasAudioFile}
                        isSchemaValid={isSchemaValid}
                    />
                </Tooltip>
            </TooltipTrigger>
            <EpisodePublishModal
                isOpen={isModalOpened}
                onOpenChange={setModalOpened}
                handlePublishEpisode={handlePublishEpisode}
                isLoading={saveEpisodeFormMutation.isLoading}
            />
        </>
    );
};

export default PublishButton;
