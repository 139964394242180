import { FormattedMessage } from 'react-intl';
// @ts-ignore
import EpisodeIllustration from '@public/images/empty-state/episodes.svg?url';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import styled from 'styled-components';
import RouterLink from '@/components/Link/RouterLink';
import { useParams } from 'react-router';
import { TooltipTrigger } from 'react-aria-components';
import Tooltip, { TooltipTriggerWrapper } from '@/components/ui/Tooltip';
import useShowQuery from '@/queries/show/useShowQuery.hook';
import { USER_ROLES } from '@/shared/config/constants';

const EmptyState = () => {
    const { showId } = useParams<{ showId: string }>();
    const showQuery = useShowQuery(showId);
    const userHasWriteAccess = showQuery.data?.userRole !== USER_ROLES.VIEWER;

    return (
        <EmptyStateWrapper>
            <Head>
                <Illustration src={EpisodeIllustration} />
                <TextWrapper>
                    <Title>
                        <FormattedMessage defaultMessage="Travailler sur un nouvel épisode" />
                    </Title>
                    <Subtitle>
                        <FormattedMessage defaultMessage="Optimisez votre prochain épisode 🚀 " />
                    </Subtitle>
                </TextWrapper>
            </Head>
            <TooltipTrigger delay={0} closeDelay={0} isDisabled={userHasWriteAccess}>
                <TooltipTriggerWrapper>
                    <RouterLink
                        isDisabled={!userHasWriteAccess}
                        to={`/app/show/${showId}/workspace/episodes/upload`}
                        variant="button-primary"
                        startIcon={
                            <FontAwesomeIcon icon={icon({ name: 'plus', style: 'solid' })} />
                        }
                    >
                        <FormattedMessage defaultMessage="Travailler sur un épisode" />
                    </RouterLink>
                </TooltipTriggerWrapper>
                <Tooltip placement="top">
                    <FormattedMessage defaultMessage="L'ajout ou la modification des épisodes n'est pas disponible en tant qu'invité Lecteur sur l'émission." />
                </Tooltip>
            </TooltipTrigger>
        </EmptyStateWrapper>
    );
};

const EmptyStateWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 2rem;
    background-color: var(--white);
    border-radius: var(--r-l);
`;
const Head = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
`;
const Illustration = styled.img`
    width: 16rem;
    height: auto;
`;
const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
`;
const Title = styled.h2`
    font-size: var(--fs-heading-s);
    font-weight: var(--fw-bold);
`;
const Subtitle = styled.span`
    color: var(--neutral500);
`;

export default EmptyState;
