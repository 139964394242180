import Button from '@/components/ui/atoms/Button';
import { InputTextController } from '@/components/ui/atoms/InputText';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { zodResolver } from '@hookform/resolvers/zod';
import styled from 'styled-components';
import useTranscriptionSpeakerFormSchema, {
    TranscriptionSpeakerFormSchema,
} from './useTranscriptionSpeakerFormSchema.hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useSpeakers } from '@app/molecules/TranscriptionEditor/TranscriptionSpeakersProvider';

interface TranscriptionSpeakerFormProps {
    onClose: () => void;
    onSubmit: (data: TranscriptionSpeakerFormSchema) => void;
    onDelete?: () => void;
    speakerName?: TranscriptionSpeakerFormSchema['name'];
}

const TranscriptionSpeakerForm = ({
    onSubmit,
    speakerName,
    onClose,
    onDelete,
}: TranscriptionSpeakerFormProps) => {
    const { speakers } = useSpeakers();
    const speakersNames = Array.from(speakers.values());
    const schema = useTranscriptionSpeakerFormSchema(speakersNames);
    const { control, formState, handleSubmit } = useForm<TranscriptionSpeakerFormSchema>({
        mode: 'onBlur',
        resolver: zodResolver(schema),
        defaultValues: {
            name: speakerName ?? '',
        },
    });
    const { isDirty } = formState;
    const isEdit = !!speakerName;

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <InputTextController
                name="name"
                label={<FormattedMessage id="speaker.name" defaultMessage="Nom" />}
                control={control}
            />
            {/* @ts-ignore */}
            <Actions>
                {isEdit ? (
                    // @ts-ignore
                    <DeleteButton
                        startIcon={<TrashIcon icon={icon({ name: 'trash', style: 'solid' })} />}
                        variant="ghost"
                        onPress={onDelete}
                    >
                        <FormattedMessage defaultMessage="Supprimer l'orateur" />
                    </DeleteButton>
                ) : (
                    // @ts-ignore
                    <CancelButton variant="ghost" onPress={onClose}>
                        <FormattedMessage defaultMessage="Annuler" />
                    </CancelButton>
                )}
                {/* @ts-ignore */}
                <Button type="submit" isDisabled={!isDirty}>
                    {isEdit ? (
                        <FormattedMessage defaultMessage="Enregistrer" />
                    ) : (
                        <FormattedMessage defaultMessage="Ajouter" />
                    )}
                </Button>
            </Actions>
        </Form>
    );
};

const Form = styled.form`
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
`;
const CancelButton = styled(Button)`
    color: var(--neutral500);
    padding: 0;
`;
const DeleteButton = styled(Button)`
    color: var(--alert);
    padding: 0;
`;
const Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 0.5rem;
`;
const TrashIcon = styled(FontAwesomeIcon)`
    width: 0.75rem;
    height: 0.75rem;
    color: var(--alert);
`;

export default TranscriptionSpeakerForm;
