import { createApi } from '../callApi';
const contentApi = createApi('CONTENT');

export const fetchSeasons = (show) => {
    return contentApi({ method: 'GET', target: `/v1/shows/${show}/seasons` });
};
export const addSeason = (show) => {
    return contentApi({ method: 'POST', target: `/v1/shows/${show}/seasons` });
};
export const deleteSeason = (season) => {
    return contentApi({ method: 'DELETE', target: `/v1/seasons/${season}` });
};
