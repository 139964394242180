import { Control, useController } from 'react-hook-form';
import { default as DateTimePicker } from '.';
import TimePicker from '../ui/atoms/TimePicker';
import DatePicker from '../ui/atoms/DatePicker';

interface DateTimePickerControllerProps extends React.ComponentProps<typeof DateTimePicker> {
    control: Control<any>;
    dateProps: React.ComponentProps<typeof DatePicker>;
    timeProps: React.ComponentProps<typeof TimePicker>;
    name: string;
}

export const DateTimePickerController = (props: DateTimePickerControllerProps) => {
    const { control, onChange, name, ...rest } = props;
    const { field, fieldState } = useController({
        name,
        control,
    });

    return (
        <DateTimePicker
            onChange={(value) => {
                onChange?.(value);
                field.onChange(value);
            }}
            value={field.value}
            errorMessage={fieldState.error?.message}
            {...rest}
        />
    );
};
