import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Stack from '@ui/layout/Stack';
import Cluster from '@ui/layout/Cluster';
import Text from '@ui/atoms/Text';
import Menu from './Menu';
import { ClipFormat } from '@/queries/clipFormat/types';
import { Episode } from '@/queries/episode/useEpisodeQuery.hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
interface ClipFormatErrorProps {
    clipFormat: ClipFormat;
    episode?: Episode;
}

const ClipFormatError = ({ clipFormat, episode }: ClipFormatErrorProps) => (
    <Stack $gap="0.75rem">
        <InnerCardWrapper>
            <Stack $gap="0.5rem" $align="center">
                <ErrorIcon icon={icon({ name: 'circle-exclamation', style: 'solid' })} />
                <Text color="--neutral500" textAlign="center" fontWeight="--fw-semibold">
                    <FormattedMessage defaultMessage="Oups, il semble y avoir eu un souci sur la génération de votre clip vidéo. 🤯" />
                </Text>
            </Stack>
            <MenuStyled isEditDisabled clipFormat={clipFormat} />
        </InnerCardWrapper>
        <Stack $gap="0.25rem">
            <Text numberOfLines={1} fontWeight="--fw-semibold">
                {clipFormat.clip.adminTitle || clipFormat.clip.title}
            </Text>
            {episode && (
                <Cluster $gap="0.5rem" $align="center" $wrap="nowrap">
                    {episode.imageUrl && <EpisodeCover src={episode.imageUrl} />}
                    <Text color="--neutral500" numberOfLines={1}>
                        {episode.name}
                    </Text>
                </Cluster>
            )}
        </Stack>
    </Stack>
);

const EpisodeCover = styled.img.attrs({
    loading: 'lazy',
})`
    display: block;
    max-height: 1.5rem;
    max-width: 1.5rem;
    border-radius: var(--r-xs);
`;
const MenuStyled = styled(Menu)`
    opacity: 0;
    transition: opacity 250ms;
    pointer-events: none;
    color: var(--neutral500);
`;
const InnerCardWrapper = styled.button`
    position: relative;
    min-height: 152px;
    padding-block: 0.625rem;
    padding-inline: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--neutral500);
    background-color: var(--neutral50);
    border-radius: var(--r-xs);
    border: none;
    overflow: hidden;
    cursor: pointer;

    &:hover {
        color: var(--black);
        background-color: var(--neutral100);

        & > ${MenuStyled} {
            opacity: 1;
            pointer-events: all;
        }
    }
`;
const ErrorIcon = styled(FontAwesomeIcon)`
    color: var(--alert500);
    width: 1.5rem;
    height: 1.5rem;
`;

export default ClipFormatError;
